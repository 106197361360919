<template>
  <div class="input-group-row">
    <el-row :gutter="12" class="values">
      <el-col :span="24 / fields.length" v-for="col in fields" :key="col.id">
        <input-with-label
          v-bind="col"
          :value="value[col.id]"
          @input="updateCol($event, col.id)"
        />
      </el-col>
    </el-row>
    <el-button
      icon="el-icon-delete"
      circle
      size="mini"
      @click="deleteRow"
      v-if="!main"
      :disabled="length === 1"
    />
  </div>
</template>

<script>
export default {
  props: {
    fields: Array,
    value: Object,
    main: Boolean,
    length: Number
  },
  methods: {
    updateCol(val, id) {
      this.$emit('input', { ...this.value, [id]: val })
    },
    deleteRow() {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./InputGroupRow.scss";
</style>
