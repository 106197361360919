<template>
  <el-form
    ref="form"
    :model="form"
    :hide-required-asterisk="true"
    label-width="180px"
    size="mini"
  >
    <el-form-item>
      <span class="h6 mb-0">
        Send new request
      </span>
    </el-form-item>
    <el-form-item>
      <span slot="label" class="undo-label-styles right-part-for-label">
        VIN
      </span>
      <span>{{ vin }}</span>
    </el-form-item>
    <el-form-item>
      <el-checkbox
        v-model="form.checked"
        style="text-transform: none"
        class="mb-3"
      >
        I AGREE*: Fee $1
      </el-checkbox>
      <p style="word-break: break-word;">
        * The information above is provided by independent 3rd parties.
        GlassBiller provides access to these resources to help users obtain
        potentially helpful information for business purposes. Please use this
        information as a guide and reference and not final verdict for what
        parts are required for proper installation, nor which parts are
        appropriate to bill customers or insurance companies.
        <br/>
      </p>
    </el-form-item>
    <el-form-item>
      <el-button
        class="mb-2 mr-2"
        type="primary"
        size="medium"
        icon="el-icon-search"
        :disabled="!form.checked"
        :loading="loading"
        @click="handleSubmit"
      >
        Search
      </el-button>
      <el-button
        plain
        class="ml-0"
        size="medium"
        :disabled="loading"
        @click="close"
      >
        Close
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { get, sync } from 'vuex-pathify'

export default {
  data: () => ({
    form: {
      checked: false
    },
  }),
  watch: {
    visible: function (value) {
      if (value) {
        this.getDetails()
      } else {
        this.clear()
        this.$refs.form.clearValidate()
      }
    },
  },
  computed: {
    visible: get('rightPart/isOpen'),
    jobId: get('rightPart/jobId'),
    vin: get('rightPart/vin'),
    loading: sync('rightPart/loading'),
  },
  methods: {
    close: function() {
      this.$store.dispatch('rightPart/close')
    },
    getDetails: function() {
      this.$store.dispatch('rightPart/getDetails', {
        field: this.$job.job.status === 'draft' ? 'jobDraftId' : 'jobId',
        value: this.$job.job.id
      })
    },
    clear () {
      this.form.checked = false
    },
    async handleSubmit () {
      if (this.vin.length !== 17) {
        this.$message.error(
          'Please check the VIN number, it has to be 17 characters long.'
        )
      } else {
        try {
            this.loading = true
            this.$store.commit('rightPart/SET_DETAILS', null)
            await this.$store.dispatch('rightPart/search', {
              ...this.form,
              shopId: this.$job.job.shop_id,
              jobId: this.$job.job.status !== 'draft' ? this.$job.job.id : null,
              jobDraftId: this.$job.job.status === 'draft'
                ? this.$job.job.id
                : null
            })
            await this.getDetails()
            this.loading = false
        } catch {
          this.loading = false
        }
      }
    }
  }
}
</script>
