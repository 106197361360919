var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VehicleCard", {
    attrs: { lossNotice: _vm.lossNotice, schema: _vm.schema },
    on: {
      "update:lossNotice": function($event) {
        _vm.lossNotice = $event
      },
      "update:loss-notice": function($event) {
        _vm.lossNotice = $event
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }