import { make } from 'vuex-pathify'

const getDefaultState = () => ({
  visible: false,
  loading: false,
  ticketVisible: false,
  ticketId: null,
  data: [],
  table: {
    sortBy: 'id',
    sortDesc: true,
    openCount: 0,
    closedCount: 0,
    filters: {
      showClosed: false,
      showOnlyUser: true
    }
  },
  openTicketCount: 0
})

const state = getDefaultState();

const mutations = {
  ...make.mutations(state),
  SET_SORT_DESC(state, payload) {
    state.table.sortDesc = payload === 'descending'
  },
  RESET_STATE(state) {
    Object.assign(state, { ...getDefaultState(), visible: state.visible })
  },
  RESET_DATA(state) {
    // Object.assign(state, {...state, table: (getDefaultState()).table})
    Object.assign(state, { ...state, data: [], totalRows: 0 })
  }
}

const getters = {}

const actions = {
  open({ commit }) {
    commit('SET_VISIBLE', true)
  },
  openTicket({ commit }, id) {
    commit('SET_TICKET_ID', id)
    commit('SET_TICKET_VISIBLE', true)
  },
  close({ commit }) {
    commit('SET_VISIBLE', false)
  },
  resetState({ commit }) {
    commit('RESET_STATE')
  },
  async getOpenTicketCount({ commit }) {
    const { data } = await this._vm.$unum.support().get(`/ticket/counts/open`, {params: {status: ['Open', 'Escalated']}})
    commit('SET_OPEN_TICKET_COUNT', data.count)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}