var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-space-y-3" }, [
    _c(
      "div",
      { staticClass: "t-flex t-px-8 t-space-x-6" },
      [
        _c(
          "el-form-item",
          {
            attrs: {
              prop: "color",
              label: "Color",
              rules: { required: true, message: "Catalog color is required" }
            }
          },
          [
            _c("el-color-picker", {
              ref: "test",
              staticClass: "t-flex",
              attrs: { value: _vm.color, predefine: _vm.predefinedColors },
              on: {
                change: function($event) {
                  return _vm.$emit("update:color", $event)
                },
                "active-change": _vm.activeColorChanged
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "t-flex t-px-8 t-space-x-6" },
      [
        _c(
          "el-form-item",
          {
            attrs: {
              prop: "number",
              label: "Name",
              rules: {
                required: true,
                message: "Name is required",
                trigger: "blur"
              }
            }
          },
          [
            _c("el-input", {
              attrs: { value: _vm.number },
              on: {
                input: function($event) {
                  return _vm.$emit("update:number", $event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "t-flex t-px-8 t-space-x-6" },
      [
        _c(
          "el-form-item",
          { attrs: { prop: "description", label: "Description" } },
          [
            _c("el-input", {
              attrs: { value: _vm.description },
              on: {
                input: function($event) {
                  return _vm.$emit("update:description", $event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }