var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "600px",
        "append-to-body": "",
        visible: _vm.visible,
        "custom-class": "shopping-cart-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-xl t-text-gray-800",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("Order details")]
      ),
      _c(
        "div",
        {
          staticClass: "t-p-4",
          staticStyle: { "max-height": "600px", "overflow-y": "auto" }
        },
        [
          _vm.loading
            ? _c(
                "div",
                {
                  staticClass: "t-p-12 t-text-center t-text-3xl t-text-blue-600"
                },
                [_c("i", { staticClass: "el-icon-loading" })]
              )
            : _c(
                "div",
                [
                  _vm.order
                    ? _c(
                        "div",
                        { staticClass: "t-flex t-break-normal t-mb-6 t-mx-3" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "t-w-40 t-text-gray-500 t-mr-2 t-text-right t-space-y-1"
                            },
                            [
                              _c("div", [_vm._v("Account number")]),
                              _c("div", [_vm._v("Order number")]),
                              _c("div", [_vm._v("Bill to name")]),
                              _c("div", [_vm._v("Bill to address")]),
                              _c("div", [_vm._v("Ship method")]),
                              _c("div", [_vm._v("Ship date")]),
                              _c("div", [_vm._v("Ship to name")]),
                              _c("div", [_vm._v("Ship to address")]),
                              _c("div", [_vm._v("Order subtotal")]),
                              _c("div", [_vm._v("Shipping cost")]),
                              _c("div", [_vm._v("Tax")]),
                              _c("div", [_vm._v("Order total")]),
                              _vm.order.comments
                                ? _c("div", [_vm._v("Order comments")])
                                : _vm._e()
                            ]
                          ),
                          _c("div", { staticClass: "t-space-y-1" }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.order.accountNumber))
                            ]),
                            _c("div", [_vm._v(_vm._s(_vm.order.orderNumber))]),
                            _c("div", [_vm._v(_vm._s(_vm.order.billToName))]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.order.billToAddress))
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.order.shipViaCode === "DEL"
                                    ? "Delivery"
                                    : "Will Call"
                                )
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.formatShipDate(_vm.order.shipDate))
                              )
                            ]),
                            _c("div", [_vm._v(_vm._s(_vm.order.shipToName))]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.order.shipToAddress))
                            ]),
                            _c("div", [
                              _vm._v("$" + _vm._s(_vm.order.orderSubtotal))
                            ]),
                            _c("div", [
                              _vm._v("$" + _vm._s(_vm.order.shippingCost))
                            ]),
                            _c("div", [_vm._v("$" + _vm._s(_vm.order.tax))]),
                            _c("div", [
                              _vm._v("$" + _vm._s(_vm.order.orderTotal))
                            ]),
                            _vm.order.comments
                              ? _c("div", [_vm._v(_vm._s(_vm.order.comments))])
                              : _vm._e()
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass:
                          "t-px-3 t-py-2 t-rounded t-border t-border-solid\n              t-border-gray-400 t-bg-gray-100 t-mb-2"
                      },
                      [
                        _c("div", { staticClass: "t-flex" }, [
                          _c("div", { staticClass: "t-flex-grow" }, [
                            _c("div", { staticClass: "t-flex t-text-base" }, [
                              _c("div", { staticClass: "t-mr-1 t-font-bold" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.item_number) +
                                    " " +
                                    _vm._s(item.item_prefix) +
                                    " "
                                )
                              ])
                            ])
                          ]),
                          _c("div", [
                            _c("div", { staticClass: "t-flex" }, [
                              _c("div", [_vm._v(_vm._s(item.quantity))]),
                              _c("div", { staticClass: "t-mx-2" }, [
                                _vm._v("x")
                              ]),
                              _c("div", { staticClass: "t-font-bold" }, [
                                _vm._v(
                                  " $" +
                                    _vm._s(
                                      Math.round(item.item_price * 100) / 100
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ])
                        ]),
                        item.item_description
                          ? _c(
                              "div",
                              [
                                _c("el-divider", { staticClass: "t-my-2" }),
                                _c("div", { staticClass: "t-flex" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "t-text-gray-600 t-text-sm t-mr-3 t-break-normal"
                                    },
                                    [_vm._v(" Description: ")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "t-break-normal" },
                                    [_vm._v(_vm._s(item.item_description))]
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  })
                ],
                2
              )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }