<template>
  <div
    class="calendar-job-card"
    :class="{ hidden: item.hidden }"
    :style="cardStyle"
    @click="card.action"
    @dragstart="itemDrag(item)"
    @dragend="itemDrag()"
  >
    <div v-if="color" class="left-border" :style="{ background: color }"></div>
    <div class="content">
      <span>
        {{ card.title }}
      </span>
      <span class="extra" v-if="card.extra" :style="cardStyle">
        {{ card.extra }}
      </span>
    </div>
    <v-icon :name="card.icon" class="icon" scale="0.7" :style="cardStyle" />
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { getCardTextColor } from '@/scripts/helpers/scheduler.helpers'
import * as R from 'ramda'
import actions from '@/store/modules/scheduler_v3/types'
import { getJobTimeStringWithMinutes } from '@/scripts/helpers/job.helpers'

const icons = {
  start: 'play',
  pickup: 'cog',
  end: 'stop',
  dropoff: 'briefcase'
}

export default {
  props: ['item', 'color'],
  computed: {
    card() {
      return {
        title: R.cond([
          [R.prop('job'), R.path(['job', 'customer_name'])],
          [R.prop('tech'), () => 'Tech Home'],
          [R.prop('shop'), R.path(['shop', 'name'])],
          [R.prop('vendor'), R.path(['vendor', 'name'])]
        ])(this.item),
        icon: icons[this.item.type],
        extra: R.cond([
          [R.prop('job'), R.pipe(R.prop('job'), getJobTimeStringWithMinutes)]
        ])(this.item),
        action: this[this.item.type] || R.always
      }
    },
    isSolidCardView: get('sch_v3/isSolidCardView'),
    textColor() {
      return getCardTextColor(this.color)
    },
    cardStyle() {
      return this.isSolidCardView
        ? { color: `${this.textColor} !important`, background: this.color }
        : {}
    }
  },
  methods: {
    itemDrag(item) {
      this.$root.$emit('dragging-item', item)
    },
    getJobDetails: call(actions.getJobDetails),
    openApptModal() {
      this.$root.$emit('open-appt-modal', this.item)
    },
    dropoff() {
      if (this.item.job?.id) this.getJobDetails(this.item.job?.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./styles/CalendarJobCard.scss";
</style>
