<template>
  <div class="sidebar-button-list accordion accordianSidebar">
    <div v-for="(category, index) in options" :key="index" class="panel list-group">
      <a href="#" data-toggle="collapse" class="list-group-item primary" @click="collapseMenu(index)">
        <span>{{index}}</span>
        <span v-if="selectedTitle && selectedTitle.length > 0 && isChildSelected(index)" class="selectedIndicator">{{selectedTitle}}</span>
      </a>
      <div
        class="panel list-group collapse"
        :id="'categoryContainer-' + index.replace(/\s+/g, '')"
      >
        <a
          v-for="option in category.subs"
          :key="option.value"
          class="list-group-item secondary"
          :class="{active: option.value === selected}"
          href="javascript:void(0)"
          @click="selectItem(option)"
        >
          {{ option.text }}
           <span v-if="option.text === 'Accounting Packages'" :class="{'badge': true, 'badge-primary': option.value !== selected, 'badge-secondary': option.value === selected}">Beta!</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  data() {
    return {
      open: true
    }
  },
  props: ['options', 'selected'],
  mounted () {
    let optionKeys = Object.keys(this.options)
    if (optionKeys.length === 1) {
      $('#categoryContainer-' + optionKeys[0].replace(/\s+/g, '')).collapse('show')
    }
  },
  methods: {
    selectItem(value) {
      this.$emit('update:selected', value.value)
    },
    collapseMenu (index) {
      $('#categoryContainer-' + index.replace(/\s+/g, '')).collapse('show')
      let optionKeys = Object.keys(this.options)
      for (var i = 0; i < optionKeys.length; i++) {
        if (optionKeys[i] !== index) {
          $('#categoryContainer-' + optionKeys[i].replace(/\s+/g, '')).collapse('hide')
        }
      }
    },
    isChildSelected (index) {
      if (this.options[index]) {
        for (var j = 0; j < this.options[index].subs.length; j++) {
          if (this.options[index].subs[j].value === this.selected) {
            return true
          }
        }
      }
      return false
    }
  },
  computed: {
    selectedTitle () {
      let optionKeys = Object.keys(this.options)
      for (var i = 0; i < optionKeys.length; i++) {
        for (var j = 0; j < this.options[optionKeys[i]].subs.length; j++) {
          if (this.options[optionKeys[i]].subs[j].value === this.selected) {
            return this.options[optionKeys[i]].subs[j].text
          }
        }
      }
      return ''
    }
  },
  watch: {
    selected () {
      let temp = ''
      let optionKeys = Object.keys(this.options)
      for (var i = 0; i < optionKeys.length; i++) {
        for (var j = 0; j < this.options[optionKeys[i]].subs.length; j++) {
          if (this.options[optionKeys[i]].subs[j].value === this.selected) {
            temp = optionKeys[i]
          }
        }
      }
      var self = this
      if (temp !== '') {
        setTimeout(function () {
          self.collapseMenu(temp)
        }, 10)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.selectedIndicator {
  background-color:#299fd7;
  color: white;
  border-radius: 5px;
  padding: 2px 5px;
  margin-left: 5px;
}
.accordianSidebar {
  text-align: left;
}
</style>
