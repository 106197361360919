<template>
  <md-circle-button
    :icon="
      selected
        ? 'check_box'
        : indeterminate
        ? 'indeterminate_check_box'
        : 'check_box_outline_blank'
    "
    outline
    :primary="selected"
    size="normal"
    :disabled="locked || disabled"
    @click="set"
  />
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  props: ['value', 'locked', 'disabled', 'parts', 'indeterminate'],
  computed: {
    selected() {
      return !!this.value
    },
    selectedParts: sync('jobDetails/parts@selected')
  },
  methods: {
    set(v) {
      this.$emit('input', v)
    }
  }
}
</script>

<style>
</style>
