<template>
  <div class="consumer-dialog-edi">
    <el-switch
      :active-value="1"
      :inactive-value="0"
      v-model="capable"
      active-text="EDI Capable"
      inactive-text="Disabled"
    />
    <input-with-label
      :select="{ options: partnerOptions, byId: true }"
      label="Trading Partner"
      v-model="partner"
      size="normal"
    />
    <input-with-label
      class='program-select'
      :select="{
        options: filteredProgramOptions,
        byId: true,
        valueKey: 'program_id',
        filterMethod: searchPrograms,
      }"
      label="Trading Partner Program ID"
      v-model="program"
      size="normal"
      :disabled="disabled"
    >
      <div
        slot="option"
        slot-scope="{ item }"
        class="consumer-dialog-program-select"
      >
        {{ item.name }} <span>ID: {{ item.program_id }}</span>
      </div>
      <div slot='prefix' class='program-id' v-if='program'>{{program}}</div>
    </input-with-label>
  </div>
</template>

<script>
import { ediConfig } from '@/constants/edi.config'
import * as R from 'ramda'
import { filterEdiProgramOptions } from '@/scripts/helpers/edi.helpers'

export default {
  props: ['value'],
  data() {
    return {
      partnerOptions: Object.values(ediConfig),
      programOptions: [],
      programFilter: ''
    }
  },
  computed: {
    edi: {
      get() {
        return this.value?.edi || {}
      },
      set(edi) {
        this.$emit('input', { ...this.value, edi })
      }
    },
    capable: {
      get() {
        return this.value?.edi?.capable || 0
      },
      set(v) {
        if (v) {
          this.edi = { capable: 1 }
        } else {
          this.edi = null
        }
      }
    },
    partner: {
      get() {
        return this.edi?.trading_partner || null
      },
      set(trading_partner) {
        this.edi = { capable: 1, trading_partner, partner_program_id: null }
      }
    },
    program: {
      get() {
        return this.edi?.partner_program_id || null
      },
      set(partner_program_id) {
        this.edi = {
          ...this.edi,
          partner_program_id,
          program_name: this.programOptions.find(
            R.propEq('program_id', partner_program_id)
          )?.name
        }
      }
    },
    disabled() {
      return !ediConfig[this.partner]?.programs
    },
    filteredProgramOptions() {
      return filterEdiProgramOptions((this.programFilter?.toUpperCase() || ''))(this.programOptions)
    }
  },
  methods: {
    async fetchProgramIds() {
      try {
        const { data } = await this.$unum
          .job()
          .get(`/edi/partners/${this.partner}`)
        this.programOptions = data
      } catch {
        this.programOptions = []
      }
    },
    searchPrograms(filter) {
      this.programFilter = filter
    }
  },
  watch: {
    partner(v) {
      this.fetchProgramIds()
    }
  },
  mounted() {
    this.fetchProgramIds()
  }
}
</script>

<style lang="scss">
.consumer-dialog-edi {
  padding: $padding-lg;
  .input-with-label {
    margin-top: $padding-md;
  }
}
.consumer-dialog-program-select {
  span {
    font-size: $font-sm;
    color: $grayscale-7;
    margin-left: $padding-xs;
  }
}
  .program-select .el-input__inner {
    padding-left: 50px !important; 
  }
</style>
