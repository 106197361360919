var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-font-sans t-px-6 t-py-5 t-border t-border-solid t-border-gray-400 t-rounded-md t-mt-4\n    t-break-normal"
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "t-text-gray-800 t-text-lg t-mb-1 t-font-medium" },
          [_vm._v(" Form type ")]
        ),
        _c("div", { staticClass: "t-text-gray-700 t-text-sm" }, [
          _vm._v(" Select a form type. ")
        ]),
        _c("div", { staticClass: "t-flex t-mt-5 t-text-xs t-text-blue-800" }, [
          _c(
            "div",
            { staticClass: "t-w-1/2 t-pr-6" },
            [
              _c(
                "el-radio",
                {
                  staticClass: "t-normal-case t-font-normal t-bg-white",
                  attrs: { label: "overlay", border: "" },
                  model: {
                    value: _vm.formType,
                    callback: function($$v) {
                      _vm.formType = $$v
                    },
                    expression: "formType"
                  }
                },
                [_vm._v(" Overlay ")]
              ),
              _c("div", { staticClass: "t-mt-2 t-italic" }, [
                _vm._v(
                  " Form will be placed in a side or a pop-up modal by your choice. "
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "t-w-1/2 t-pr-6" },
            [
              _c(
                "el-radio",
                {
                  staticClass: "t-normal-case t-font-normal t-bg-white",
                  attrs: { label: "embedded", border: "" },
                  model: {
                    value: _vm.formType,
                    callback: function($$v) {
                      _vm.formType = $$v
                    },
                    expression: "formType"
                  }
                },
                [_vm._v(" Embedded ")]
              ),
              _c("div", { staticClass: "t-mt-2 t-italic" }, [
                _vm._v(" Form will be embedded in your page. ")
              ])
            ],
            1
          )
        ])
      ]),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "t-text-gray-800 t-text-lg t-mb-1 t-font-medium" },
        [_vm._v(" Form fields ")]
      ),
      _c(
        "div",
        { staticClass: "t-text-gray-700 t-text-sm" },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.fields } },
            [
              _c("el-table-column", {
                attrs: { width: "55" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function(scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: { indeterminate: _vm.someFieldsSelected },
                          model: {
                            value: _vm.allFieldsSelected,
                            callback: function($$v) {
                              _vm.allFieldsSelected = $$v
                            },
                            expression: "allFieldsSelected"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            disabled: ["gb_first_name", "gb_phone"].includes(
                              scope.row.id
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.handleShowFieldChange(
                                $event,
                                scope.row
                              )
                            }
                          },
                          model: {
                            value: scope.row.show,
                            callback: function($$v) {
                              _vm.$set(scope.row, "show", $$v)
                            },
                            expression: "scope.row.show"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { property: "name", label: "Field", width: "120" }
              }),
              _c("el-table-column", {
                attrs: { prop: "required", label: "Required", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            disabled: ["gb_first_name", "gb_phone"].includes(
                              scope.row.id
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.handleRequiredFieldChange(
                                $event,
                                scope.row
                              )
                            }
                          },
                          model: {
                            value: scope.row.required,
                            callback: function($$v) {
                              _vm.$set(scope.row, "required", $$v)
                            },
                            expression: "scope.row.required"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.formType === "embedded"
        ? _c(
            "div",
            [
              _c("el-divider"),
              _c(
                "div",
                {
                  staticClass: "t-text-gray-800 t-text-lg t-mb-1 t-font-medium"
                },
                [_vm._v(" Submit button text ")]
              ),
              _c("div", { staticClass: "t-text-gray-700 t-text-sm" }, [
                _vm._v(
                  ' Enter text you want on the submit button (defaults to "Get a quote") '
                )
              ]),
              _c(
                "div",
                { staticClass: "t-flex t-mt-5 t-text-xs t-text-blue-800" },
                [
                  _c(
                    "div",
                    { staticClass: "t-w-1/2 t-pr-6" },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.submitText,
                          callback: function($$v) {
                            _vm.submitText = $$v
                          },
                          expression: "submitText"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c("el-divider"),
              _c(
                "div",
                {
                  staticClass: "t-text-gray-800 t-text-lg t-mb-1 t-font-medium"
                },
                [_vm._v(" Redirect Url ")]
              ),
              _c("div", { staticClass: "t-text-gray-700 t-text-sm" }, [
                _vm._v(
                  " Website to direct customers to after they complete the form (Optional) "
                )
              ]),
              _c(
                "div",
                { staticClass: "t-flex t-mt-5 t-text-xs t-text-blue-800" },
                [
                  _c(
                    "div",
                    { staticClass: "t-w-1/2 t-pr-6" },
                    [
                      _c(
                        "el-input",
                        {
                          model: {
                            value: _vm.redirectUrl,
                            callback: function($$v) {
                              _vm.redirectUrl = $$v
                            },
                            expression: "redirectUrl"
                          }
                        },
                        [
                          _c("template", { slot: "prepend" }, [
                            _vm._v("https://")
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("el-divider"),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "t-text-gray-800 t-text-lg t-mb-1 t-font-medium"
                  },
                  [_vm._v(" Scripts for your website ")]
                ),
                _c("div", { staticClass: "t-text-gray-700 t-text-sm" }, [
                  _vm._v(
                    " Click on the text below to copy the script to clipboard. Paste it into your website in any place you want. The width of the form is dependant on the parent element or the wrapper. The script can be places into a <div> element which would dictate the position of it. "
                  )
                ]),
                _c(
                  "div",
                  {
                    ref: "script",
                    staticClass: "t-mt-10 t-cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.copy($event, "script")
                      }
                    }
                  },
                  [
                    _c("pre", { staticClass: "t-text-blue-700 t-mb-0" }, [
                      _vm._v(
                        '            <iframe\n              id="gb-iframe"\n              width="100%"\n              height="100%"\n              frameborder="0"\n              scrolling="no"\n              marginheight="0"\n              marginwidth="0">\n            </iframe>\n          '
                      )
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "t-text-gray-700 t-text-sm" }, [
                _vm._v(
                  " Click on the text below to copy the script to clipboard. Add it to your HTML app by pasting it before </body> tag. "
                )
              ]),
              _c(
                "div",
                {
                  ref: "script",
                  staticClass: "t-mt-10 t-cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.copy($event, "script")
                    }
                  }
                },
                [
                  _c("pre", { staticClass: "t-text-blue-700 t-mb-0" }, [
                    _vm._v(
                      "        <script>\n          document.addEventListener(\"DOMContentLoaded\", () => {\n            const src = 'https://web-form.glassbiller.com/iframe.html' +\n              `?shopId=" +
                        _vm._s(_vm.shopId) +
                        "` +\n              `&submitText=" +
                        _vm._s(encodeURIComponent(_vm.submitText)) +
                        "` +\n              `&fields=" +
                        _vm._s(encodeURIComponent(JSON.stringify(_vm.fields))) +
                        "` +\n              `&url=${window.location.href}` +\n              `&redirectUrl=" +
                        _vm._s("https://" + _vm.redirectUrl) +
                        "`\n            const iframe = document.getElementById('gb-iframe');\n            iframe.src = src\n          })\n        </script>\n      "
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.formType === "overlay"
        ? _c(
            "div",
            [
              _c("el-divider"),
              _c(
                "div",
                {
                  staticClass: "t-text-gray-800 t-text-lg t-mb-1 t-font-medium"
                },
                [_vm._v(" Button type ")]
              ),
              _c("div", { staticClass: "t-text-gray-700 t-text-sm" }, [
                _vm._v(" You can select a button type for opening the form. ")
              ]),
              _c(
                "div",
                { staticClass: "t-flex t-mt-5 t-text-xs t-text-blue-800" },
                [
                  _c(
                    "div",
                    { staticClass: "t-w-1/2 t-pr-6" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "t-normal-case t-font-normal t-bg-white",
                          attrs: { label: "fixed", border: "" },
                          model: {
                            value: _vm.buttonType,
                            callback: function($$v) {
                              _vm.buttonType = $$v
                            },
                            expression: "buttonType"
                          }
                        },
                        [_vm._v(" Floating button ")]
                      ),
                      _c("div", { staticClass: "t-mt-2 t-italic" }, [
                        _vm._v(
                          " With this option the button will be sticky in one of the bottom corners of the screen (you can choose which one). "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "t-w-1/2 t-pr-6" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "t-normal-case t-font-normal t-bg-white",
                          attrs: { label: "regular", border: "" },
                          model: {
                            value: _vm.buttonType,
                            callback: function($$v) {
                              _vm.buttonType = $$v
                            },
                            expression: "buttonType"
                          }
                        },
                        [_vm._v(" Regular button ")]
                      ),
                      _c("div", { staticClass: "t-mt-2 t-italic" }, [
                        _vm._v(
                          " With this option you can add a button to any place of your HTML code, use already existing button or even multiple buttons. "
                        )
                      ])
                    ],
                    1
                  )
                ]
              ),
              _vm.buttonType !== ""
                ? _c(
                    "div",
                    [
                      _c("el-divider"),
                      _vm.buttonType === "fixed"
                        ? _c(
                            "div",
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-text-gray-800 t-text-lg t-mb-1 t-font-medium"
                                  },
                                  [_vm._v(" Button position ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "t-text-gray-700 t-text-sm" },
                                  [
                                    _vm._v(
                                      " You can select a button position, where you want it to appear on your website. "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-flex t-mt-5 t-text-xs t-text-blue-800"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "t-w-1/2 t-pr-6" },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass:
                                              "t-normal-case t-font-normal t-bg-white",
                                            attrs: {
                                              label: "left",
                                              border: ""
                                            },
                                            model: {
                                              value: _vm.buttonPosition,
                                              callback: function($$v) {
                                                _vm.buttonPosition = $$v
                                              },
                                              expression: "buttonPosition"
                                            }
                                          },
                                          [_vm._v(" Left side ")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "t-mt-2 t-italic" },
                                          [
                                            _vm._v(
                                              " With this option the button will be sticky in the lower left corner of the screen. "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "t-w-1/2 t-pr-6" },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass:
                                              "t-normal-case t-font-normal t-bg-white",
                                            attrs: {
                                              label: "right",
                                              border: ""
                                            },
                                            model: {
                                              value: _vm.buttonPosition,
                                              callback: function($$v) {
                                                _vm.buttonPosition = $$v
                                              },
                                              expression: "buttonPosition"
                                            }
                                          },
                                          [_vm._v(" Right side ")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "t-mt-2 t-italic" },
                                          [
                                            _vm._v(
                                              " With this option the button will be sticky in the lower right corner of the screen. "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _c("el-divider"),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-text-gray-800 t-text-lg t-mb-1 t-font-medium"
                                  },
                                  [_vm._v(" Button colors ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "t-text-gray-700 t-text-sm" },
                                  [
                                    _vm._v(
                                      " You can choose your own color for the button background, border and text. Enter hex for desired colors. "
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "t-flex t-mt-5" }, [
                                  _c("div", { staticClass: "t-w-1/2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "t-flex t-items-center t-mb-1"
                                      },
                                      [
                                        _c("el-color-picker", {
                                          model: {
                                            value: _vm.buttonBgColor,
                                            callback: function($$v) {
                                              _vm.buttonBgColor = $$v
                                            },
                                            expression: "buttonBgColor"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "t-text-gray-700 t-text-sm t-ml-2"
                                          },
                                          [_vm._v("Background color")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "t-flex t-items-center t-mb-1"
                                      },
                                      [
                                        _c("el-color-picker", {
                                          model: {
                                            value: _vm.buttonBorderColor,
                                            callback: function($$v) {
                                              _vm.buttonBorderColor = $$v
                                            },
                                            expression: "buttonBorderColor"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "t-text-gray-700 t-text-sm t-ml-2"
                                          },
                                          [_vm._v("Border color")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "t-flex t-items-center t-mb-1"
                                      },
                                      [
                                        _c("el-color-picker", {
                                          model: {
                                            value: _vm.buttonTextColor,
                                            callback: function($$v) {
                                              _vm.buttonTextColor = $$v
                                            },
                                            expression: "buttonTextColor"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "t-text-gray-700 t-text-sm t-ml-2"
                                          },
                                          [_vm._v("Text color")]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "t-w-1/2 t-flex t-items-center"
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "t-rounded-full t-px-6 t-py-3 t-text-sm t-outline-none\n                 t-uppercase t-cursor-default",
                                          style: [
                                            {
                                              "background-color":
                                                _vm.buttonBgColor
                                            },
                                            {
                                              border:
                                                "1px solid " +
                                                _vm.buttonBorderColor
                                            },
                                            { color: _vm.buttonTextColor }
                                          ]
                                        },
                                        [_vm._v(" Get a quote ")]
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("el-divider")
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-text-gray-800 t-text-lg t-mb-1 t-font-medium"
                          },
                          [_vm._v(" Form style ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "t-text-gray-700 t-text-sm" },
                          [
                            _vm._v(
                              " Choose between side modal opening or a pop-up modal for your quick quote form. For both styles the form will remain the same on mobile devices. "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-flex t-mt-5 t-text-xs t-text-blue-800"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "t-w-1/2 t-pr-6" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass:
                                      "t-normal-case t-font-normal t-bg-white",
                                    attrs: { label: "dialog", border: "" },
                                    model: {
                                      value: _vm.modalPosition,
                                      callback: function($$v) {
                                        _vm.modalPosition = $$v
                                      },
                                      expression: "modalPosition"
                                    }
                                  },
                                  [_vm._v(" Pop-up ")]
                                ),
                                _c("div", { staticClass: "t-mt-2 t-italic" }, [
                                  _vm._v(
                                    " With this option the modal will open as a pop-up in the middle of the screen. "
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "t-w-1/2 t-pr-6" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass:
                                      "t-normal-case t-font-normal t-bg-white",
                                    attrs: { label: "drawer", border: "" },
                                    model: {
                                      value: _vm.modalPosition,
                                      callback: function($$v) {
                                        _vm.modalPosition = $$v
                                      },
                                      expression: "modalPosition"
                                    }
                                  },
                                  [_vm._v(" Side modal ")]
                                ),
                                _c("div", { staticClass: "t-mt-2 t-italic" }, [
                                  _vm._v(
                                    " With this option the modal will open from the right side of the screen. "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _c("el-divider"),
                      _c(
                        "div",
                        {
                          staticClass:
                            "t-text-gray-800 t-text-lg t-mb-1 t-font-medium"
                        },
                        [_vm._v(" Submit button text ")]
                      ),
                      _c("div", { staticClass: "t-text-gray-700 t-text-sm" }, [
                        _vm._v(
                          ' Enter a text you want on the submit button (defaults to "Get a quote") '
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "t-flex t-mt-5 t-text-xs t-text-blue-800"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "t-w-1/2 t-pr-6" },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.submitText,
                                  callback: function($$v) {
                                    _vm.submitText = $$v
                                  },
                                  expression: "submitText"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c("el-divider"),
                      _c(
                        "div",
                        {
                          staticClass:
                            "t-text-gray-800 t-text-lg t-mb-1 t-font-medium"
                        },
                        [_vm._v(" Redirect Url ")]
                      ),
                      _c("div", { staticClass: "t-text-gray-700 t-text-sm" }, [
                        _vm._v(
                          " Website to direct customers to after they complete the form (Optional) "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "t-flex t-mt-5 t-text-xs t-text-blue-800"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "t-w-1/2 t-pr-6" },
                            [
                              _c(
                                "el-input",
                                {
                                  model: {
                                    value: _vm.redirectUrl,
                                    callback: function($$v) {
                                      _vm.redirectUrl = $$v
                                    },
                                    expression: "redirectUrl"
                                  }
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("https://")
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("el-divider"),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-text-gray-800 t-text-lg t-mb-1 t-font-medium"
                          },
                          [_vm._v(" Script for your website ")]
                        ),
                        _vm.buttonType === "regular"
                          ? _c("div", { staticClass: "t-mb-8" }, [
                              _c(
                                "div",
                                { staticClass: "t-text-gray-700 t-text-sm" },
                                [
                                  _vm._v(
                                    " Click on the text below to copy the script to clipboard. Add it to any desired place in your HTML code and style it any way you wish (or just copy a class name and add it to one or many existing buttons on your website). "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  ref: "button",
                                  staticClass:
                                    "t-mt-3 t-text-blue-700 t-cursor-pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.copy($event, "button")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    ' <button class="gb-get-quote-button" >Get a quote</button> '
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "t-text-gray-700 t-text-sm" },
                          [
                            _vm._v(
                              " Click on the text below to copy the script to clipboard. Add it to your HTML app by pasting it before </body> tag. "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            ref: "script",
                            staticClass: "t-mt-10 t-cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.copy($event, "script")
                              }
                            }
                          },
                          [
                            _c(
                              "pre",
                              { staticClass: "t-text-blue-700 t-mb-0" },
                              [
                                _vm._v(
                                  '            <script>\n              document.addEventListener("DOMContentLoaded", () => {\n                const src = "https://web-form.glassbiller.com/index.js";\n                let script = document.createElement("script");\n                script.setAttribute("src", `${src}?${Date.now()}`);\n                script.setAttribute("shop-id", "' +
                                    _vm._s(_vm.shopId) +
                                    '");\n                script.setAttribute("button-type", "' +
                                    _vm._s(_vm.buttonType) +
                                    '");\n                script.setAttribute("button-position", "' +
                                    _vm._s(_vm.buttonPosition) +
                                    '");\n                script.setAttribute("button-bg-color", "' +
                                    _vm._s(_vm.buttonBgColor) +
                                    '");\n                script.setAttribute("button-border-color", "' +
                                    _vm._s(_vm.buttonBorderColor) +
                                    '");\n                script.setAttribute("button-text-color", "' +
                                    _vm._s(_vm.buttonTextColor) +
                                    '");\n                script.setAttribute("modal-position", "' +
                                    _vm._s(_vm.modalPosition) +
                                    '");\n                script.setAttribute("submit-text", "' +
                                    _vm._s(_vm.submitText) +
                                    '");\n                script.setAttribute("redirect-url", "' +
                                    _vm._s("https://" + _vm.redirectUrl) +
                                    '");\n                script.setAttribute("fields", "' +
                                    _vm._s(
                                      encodeURIComponent(
                                        JSON.stringify(_vm.fields)
                                      )
                                    ) +
                                    '");\n                document.body.appendChild(script);\n              })\n            </script>\n          '
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }