export const searchSettings = [
  {
    label: 'Job ID',
    key: 'job_id'
  },
  {
    label: 'Customer ID',
    key: 'customer_id'
  },
  {
    label: 'Name',
    key: 'name'
  },
  {
    label: 'Company Name',
    key: 'company_name'
  },
  {
    label: 'Phone',
    key: 'phone'
  },
  {
    label: 'Address',
    key: 'address'
  },
  {
    label: 'Ref #',
    key: 'referral_number'
  },
  {
    label: 'Internal #',
    key: 'internal_number'
  },
  {
    label: 'RO #',
    key: 'requisition_order_number'
  },
  {
    label: 'PO #',
    key: 'purchase_order_number'
  },
  {
    label: 'Stock #',
    key: 'stock_number'
  },
  {
    label: 'Vehicle',
    key: 'veh'
  },
  {
    label: 'Unit #',
    key: 'unit_number'
  },
  {
    label: 'Plate #',
    key: 'plate_number'
  }
]
