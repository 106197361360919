<template>
    <div :id="location === 'side' ? (open ? 'sidebar-open-column' : 'sidebar-closed-column') : ''">
      <div v-bind:class="[location == 'side' ? 'd-lg-inline d-none' : 'd-inline d-lg-none']" >
          <b-row v-if="location === 'side'" style="margin:0px;" v-show="!open" class="mt-1">
            <div  style="text-align: left;">
              <div @click='openButtonClicked()' style="background-color:red;" class="sidebar-btn open-it"><i class="fa fa-angle-double-up"></i> OPEN</div>
            </div>
          </b-row>
          <b-row v-show="open" class="mt-3">
            <div v-if="location === 'side'" class="btn-wrap">
              <div @click='closeButtonClicked()' style="background-color:red;" class="sidebar-btn close-it"><i class="fa fa-angle-double-left"></i> Close</div>
            </div>
            <div :class="{'btn-wrap':location === 'side', 'col-6':location === 'top', 'col-md-4':location === 'top'}">
              <div id="text-messages-button" @click="openTextMessagesDialog">
                <el-badge
                  :value="unreadTextMessageThreadsCount"
                  :max="99"
                  :hidden="unreadTextMessageThreadsCount === 0 ? true : false"
                  type="primary"
                  id="unread-threads-badge">
                    <span v-if="$store.state.user.has_unread_text_message" id="new-thread-badge">new</span>
                    <img src="../../assets/img/sms-bubble.svg">
                </el-badge>
              </div>
            </div>
            <div :class="{'btn-wrap':location === 'side', 'col-6':location === 'top', 'col-md-4':location === 'top'}">
              <div @click='supportClicked' class="btn-small-square btn-gray mb-1 btn-text-error">
                {{'Support '}}<span :id='"chatnow"+location'>
                </span>
              </div>
            </div>
            <div :class="{'btn-wrap':location === 'side', 'col-6':location === 'top', 'col-md-4':location === 'top'}">
              <a @click.prevent='rejectedDialogOpen' class="btn-square btn-gray mb-1 btn-text-error" href="javascript:void(0)"><span v-if="$store.state.userEdiRejectedJobCount > 0" class="notification right red">{{ computedRejectedInvoicesCount }}</span><font-awesome-icon :icon="faTimes"/> Rejected</a>
            </div>
            <div v-if='permissionCheck(permissions.SCHEDULER, $store.state)' :class="{'btn-wrap':location === 'side', 'col-6':location === 'top', 'col-md-4':location === 'top'}">
              <a @click.prevent="openSchedulerDialog" v-b-toggle="location === 'top' ? 'navbarCollapse' : ''" class="btn-square btn-gray mb-1 btn-text-warning" href="javascript:void(0)"><span v-if="$store.state.userTechsideCount > 0" class="notification right green">{{ computedTechsideCount }}</span><font-awesome-icon :icon="faCalendar"/> Scheduler</a>
            </div>
            <div :class="{'btn-wrap':location === 'side', 'col-6':location === 'top', 'col-md-4':location === 'top'}">
              <a @click.prevent='quotesDialogOpen' class="btn-square btn-gray mb-1 btn-text-gray btn-text-blue" href="javascript:void(0)">
                <span v-if="$store.state.userQuoteCount > 0" class="notification right yellow">{{ computedQuotesCount }}</span>
                <span v-if="$store.state.userFollowupQuoteCount > 0" class="notification bottom yellow">Follow Up</span>
                <font-awesome-icon :icon="faBolt"/> Quotes</a>
            </div>
            <div :class="{'btn-wrap':location === 'side', 'col-6':location === 'top', 'col-md-4':location === 'top'}">
              <a @click.prevent="openMobileDialog" v-b-toggle="location === 'top' ? 'navbarCollapse' : ''" class="btn-square btn-gray mb-1 btn-text-success" href="javascript:void(0)">
                <span v-if="$store.state.userSalesideJobCount > 0" class="notification right blue">{{ computedSalesideJobCount }}</span>
                <span v-if="$store.state.userSalesideUnreadMessageCount > 0" class="notification left light-blue"><i class='el-icon-message' style='font-size: 16px;'></i></span>
                <font-awesome-icon :icon="faMobile"/> Mobile
              </a>
            </div>
            <div :class="{'btn-wrap':location === 'side', 'col-6':location === 'top', 'col-md-4':location === 'top'}">
              <a v-b-modal="'faxInboxModal'" v-b-toggle="location === 'top' ? 'navbarCollapse' : ''" class="btn-square btn-gray mb-1 btn-text-dark-blue" href="javascript:void(0)"><span v-if="$store.state.unreadFaxCount > 0" class="notification right white">{{ computedUnreadFaxCount }}</span><font-awesome-icon :icon="faFax"/> Fax</a>
            </div>
            <div v-if='showGlaxis' :class="{'btn-wrap':location === 'side', 'col-6':location === 'top', 'col-md-4':location === 'top'}">
              <a v-b-modal="'glaxisModal'" v-b-toggle="location === 'top' ? 'navbarCollapse' : ''" class="btn-square btn-gray mb-1 btn-text-purple" href="javascript:void(0)"><span v-if="$store.state.glaxisWorkAssignmentsCount > 0" class="notification right orange">{{ computedGlaxisWorkAssignmentsCount }}</span><font-awesome-icon :icon="faComments"/> Glaxis</a>
            </div>
          </b-row>
      </div>
      <mobile-inbox-dialog :parentVisible.sync='mobileInboxVisible' />
    </div>
</template>

<script>
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes'
import faCalendar from '@fortawesome/fontawesome-free-solid/faCalendar'
import faBolt from '@fortawesome/fontawesome-free-solid/faBolt'
import faMobile from '@fortawesome/fontawesome-free-solid/faMobile'
import faFax from '@fortawesome/fontawesome-free-solid/faFax'
import faComments from '@fortawesome/fontawesome-free-solid/faComments'
import MobileInboxDialog from '@/components/modals/MobileInboxDialog.vue'
import moment from 'moment'
import { permissions, permissionCheck, permissionHumanReadable } from '@/scripts/helpers/permissions.helpers'
import { schedulerNamespacedMutations } from '@/store/modules/scheduler/scheduler.types'
import { call, get } from 'vuex-pathify'
const SIDEBAR_OPENED_COOKIE = 'SIDEBAR_OPENED'

export default {
  data () {
    return {
      users: [],
      open: true,
      permissions,
      mobileInboxVisible: false
    }
  },
  props: [
    'location'
  ],
  mounted () {
    if (this.location === 'side') {
      if (this.$cookies.get(SIDEBAR_OPENED_COOKIE) + '' === 'false') {
        this.open = false
      } else {
        this.open = true
      }

      this.$store.commit(schedulerNamespacedMutations.closeSchedulerDialog)
      if (this.$route.path === '/jobs' &&
      (this.$store.state.userEdiRejectedJobCount > 0 ||
        this.$store.state.userEdiQueuedJobCount > 0 ||
        this.$store.state.userQuoteCount > 0 ||
        this.$store.state.userTechsideCount > 0 ||
        this.$store.state.userSalesideJobCount > 0 ||
        this.$store.state.userSalesideUnreadMessageCount > 0 ||
        this.$store.state.unreadFaxCount > 0 ||
        this.$store.state.glaxisWorkAssignmentsCount > 0)) {
        return
      }

      setTimeout(() => {
        if (this.$store.state.user) {
          this.updateUsersArray([this.$store.state.user])
          this.$store.dispatch('updateUserEdiRejectedJobCount')
          this.$store.dispatch('updateUserEdiQueuedJobCount')
          this.$store.dispatch('updateUserQuoteCount')
          this.$store.dispatch('updateUserTechsideCount')
          this.$store.dispatch('updateUserSalesideCounts')
          this.$store.dispatch('updateUnreadFaxCount')
          this.$store.dispatch('updateGlaxisWorkAssignmentsCount')
          this.$store.dispatch('updateAccountingPackages')
        }
      }, 10000)
    }
  },
  methods: {
    rejectedDialogOpen: call('rejectedInvoices/open'),
    quotesDialogOpen: call('quotes/open'),
    permissionCheck,
    updateUsersArray (users) {
      if (users) {
        for (var i = 0; i < users.length; i++) {
          var alreadyExists = false
          for (var j = 0; j < this.users.length; j++) {
            if (users[i].id === this.users[j].id) {
              alreadyExists = true
              break
            }
          }
          if (!alreadyExists) {
            this.users.push(users[i])
          }
        }
      }
    },
    openSchedulerDialog (e) {
      e.preventDefault()
      this.$store.commit(schedulerNamespacedMutations.openSchedulerDialog)
    },
    getNotificationDisplayCount (count, limit) {
      if (count <= 0) {
        return ''
      } else if (count > limit) {
        return limit + '+'
      } else {
        return '' + count
      }
    },
    supportClicked () {
      var wleft = (screen.width - 500 - 32) / 2
      var wtop = (screen.height - 500 - 96) / 2
      var date = moment().format('MM/DD/YYYY hh:mm:ss A')
      var temp = 'left=' + wleft + ',top=' + wtop + ',width=500,height=500,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,copyhistory=no,resizable=yes'
      window.open('http://www.livehelpnow.net/lhn/TicketsVisitor.aspx?ms=&sent=0&lhnid=31631&did=45398&via=&time=' + encodeURIComponent(date), 'popUpWindow', temp)
    },
    openTextMessagesDialog: call('twilio/textMessages/open'),
    closeButtonClicked () {
      this.open = false
      this.$cookies.set(SIDEBAR_OPENED_COOKIE, false, '30d')
    },
    openButtonClicked () {
      this.open = true
      this.$cookies.set(SIDEBAR_OPENED_COOKIE, true, '30d')
    },
    openMobileDialog () {
      this.mobileInboxVisible = true
    }
  },
  computed: {
    computedRejectedInvoicesCount () {
      return this.getNotificationDisplayCount(this.$store.state.userEdiRejectedJobCount, 999)
    },
    computedTechsideCount () {
      return this.getNotificationDisplayCount(this.$store.state.userTechsideCount, 999)
    },
    computedSalesideJobCount () {
      return this.getNotificationDisplayCount(this.$store.state.userSalesideJobCount, 999)
    },
    computedSalesideUnreadMessageCount () {
      return this.getNotificationDisplayCount(this.$store.state.userSalesideUnreadMessageCount, 999)
    },
    computedUnreadFaxCount () {
      return this.getNotificationDisplayCount(this.$store.state.unreadFaxCount, 999)
    },
    computedQuotesCount () {
      return this.getNotificationDisplayCount(this.$store.state.userQuoteCount, 999)
    },
    computedGlaxisWorkAssignmentsCount () {
      return this.getNotificationDisplayCount(this.$store.state.glaxisWorkAssignmentsCount, 999)
    },
    faTimes () {
      return faTimes
    },
    faCalendar () {
      return faCalendar
    },
    faBolt () {
      return faBolt
    },
    faMobile () {
      return faMobile
    },
    faFax () {
      return faFax
    },
    faComments () {
      return faComments
    },
    showGlaxis () {
      var tempShops = this.$store.state.shops
      for (var i = 0; i < tempShops.length; i++) {
        if (tempShops[i].show_glaxis_inbox === '1' || tempShops[i].show_glaxis_inbox === 1) return true
      }
      return false
    },
    unreadTextMessageThreadsCount: get('twilio/textMessages/unreadThreadsCount')
  },
  components: {
    FontAwesomeIcon,
    MobileInboxDialog
  }
}
</script>

<style lang="scss">
.with-top-bar {
  margin-top: 48px;
}
.giant-letter {
  margin-bottom: 0%;
}
#lhnchatimg {
  height: 10px !important;
}
#lhnContainerDone {
  display: inline-block;
}
#text-messages-button {
  margin: 0 auto;
  width: 70%;
  padding-bottom: 70%;
  border: 1px solid #606266;
  border-radius: 50%;
  padding: 15px 10px 8px 10px;
  background-color: rgb(244, 244, 245);
  cursor: pointer;
  z-index: 1000000;
  &:hover {
    background-color: rgb(233, 233, 235);
  }
  img {
    width: 90%;
  }
}
#unread-threads-badge {
  sup {
    top: -8px;
    right: 10px;
    min-width: 18px;
    padding-bottom: 18px;
  }
}
#new-thread-badge {
  position: absolute;
  bottom: -12px;
  right: -22px;
  color: #fff;
  background-color: #E6A23C;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 0 6px 2px;
  font-size: 10px;
}

.sidebar-btn {
  z-index: 10 !important;
  left: 0px !important;
  padding: 5px 12px;
  border-radius: 5px 5px 0 0;
  border-color: #cdcbce;
  font-weight: bold;
  cursor:pointer;
  font-size: 12px;
  color: #666666;
  text-align: center;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,d3d3d3+100 */
  background: rgb(255,255,255); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(211,211,211,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(211,211,211,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(211,211,211,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d3d3d3',GradientType=0 ); /* IE6-9 */
  &:hover {
    text-decoration: none;
    color: black;
  }
  &.open-it {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform-origin: bottom left;
    position: absolute !important;
  }
  &.close-it {
    transform: rotate(0deg);
    width: 85%;
  }
}

#sidebar-open-column {
  width: 100px;
  padding: 10px;
  flex-shrink: 0;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

#sidebar-closed-column {
  width: 2%;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

</style>
