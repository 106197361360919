import { call } from 'vuex-pathify'

export const TextActionsMixin = {
  methods: {
    setTextMessageInvoiceJobId: call('twilio/textMessages/jobInvoice/setJobId'),
    setTextMessageInvoiceDocumentLink: call(
      'twilio/textMessages/jobInvoice/setDocumentLink'
    ),
    setTextMessageInvoiceShopId: call(
      'twilio/textMessages/jobInvoice/setShopId'
    ),
    setTextMessageInvoiceJobStatus: call(
      'twilio/textMessages/jobInvoice/setJobStatus'
    ),
    openTextMessageInvoice: call('twilio/textMessages/jobInvoice/open'),
    setTextMessageSignatureJobId: call(
      'twilio/textMessages/jobSignature/setJobId'
    ),
    setTextMessageSignatureSignatureLink: call(
      'twilio/textMessages/jobSignature/setSignatureLink'
    ),
    setTextMessageSignatureSalesideJobId: call(
      'twilio/textMessages/jobSignature/setSalesideJobId'
    ),
    setTextMessageSignatureShopId: call(
      'twilio/textMessages/jobSignature/setShopId'
    ),
    openTextMessageSignature: call('twilio/textMessages/jobSignature/open'),
    setTextMessagePaymentJobId: call('twilio/textMessages/jobPayment/setJobId'),
    setTextMessagePaymentShopId: call('twilio/textMessages/jobPayment/setShopId'),
    setTextMessagePaymentBalanceDetails: call('twilio/textMessages/jobPayment/setBalanceDetails'),
    // setTextMessagePaymentJobStatus: call('twilio/textMessages/jobPayment/setJobStatus'),
    setTextMessagePaymentConsumersWithJob: call('twilio/textMessages/jobPayment/setConsumersWithJob'),
    openTextMessagePayment: call('twilio/textMessages/jobPayment/open')
  }
}
