var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-mb-3 t-pr-4" }, [
    _c("div", { staticClass: "t-font-medium t-text-gray-400 t-text-xs" }, [
      _vm._v(" " + _vm._s(_vm.label) + " ")
    ]),
    _vm.value
      ? _c(
          "span",
          {
            staticClass: "t-text-gray-600 t-break-normal",
            class: {
              "t-font-bold": _vm.label === "Item number" || _vm.label === "Name"
            }
          },
          [_vm._v(" " + _vm._s(_vm.value) + " ")]
        )
      : _c("span", { staticClass: "t-font-light t-italic t-text-gray-600" }, [
          _vm._v(" - ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }