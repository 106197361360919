var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.title !== "" ? _c("label", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      _c("Multiselect", {
        attrs: {
          options: _vm.objects,
          multiple: true,
          "close-on-select": false,
          "clear-on-select": false,
          "hide-selected": false,
          "preserve-search": true,
          placeholder: "",
          label: "name",
          limit: 4,
          "limit-text": _vm.maxString,
          "track-by":
            _vm.trackBy && _vm.trackBy.length > 0 ? _vm.trackBy : "id",
          "group-values": "csrs",
          "group-label": "name",
          "preselect-first": false,
          selectLabel: "Press enter/click to select",
          deselectLabel: "Press enter/click to deselect"
        },
        on: { select: _vm.optionSelected, input: _vm.inputEvent },
        model: {
          value: _vm.values,
          callback: function($$v) {
            _vm.values = $$v
          },
          expression: "values"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }