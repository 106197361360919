// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".data-tab-permission-dialog .permissions-container {\n  border: 2px solid lightgray;\n  border-radius: 5px;\n}\n.data-tab-permission-dialog .overlay {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  bottom: 0px;\n  right: 0px;\n  background-color: rgba(252, 252, 252, 0.8);\n  z-index: 100;\n}\n.data-tab-permission-dialog .overlay-text {\n  text-align: center;\n  font-weight: bold;\n  font-size: 24px;\n  white-space: pre-wrap;\n  padding: 40px 20px;\n  word-break: keep-all;\n}\n.data-tab-permission-dialog .close-dialog-overlay {\n  cursor: pointer;\n  position: absolute;\n  right: 21px;\n  top: 21px;\n}", "",{"version":3,"sources":["/app/src/components/modals/DataTabPermissionsDialog.vue"],"names":[],"mappings":"AAkGE;EACE,2BAAA;EACA,kBAAA;AAtFJ;AAwFE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;EACA,0CAAA;EACA,YAAA;AAtFJ;AAwFE;EACE,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,qBAAA;EACA,kBAAA;EACA,oBAAA;AAtFJ;AAwFE;EACE,eAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;AAtFJ","file":"DataTabPermissionsDialog.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.data-tab-permission-dialog .permissions-container {\n  border: 2px solid lightgray;\n  border-radius: 5px;\n}\n.data-tab-permission-dialog .overlay {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  bottom: 0px;\n  right: 0px;\n  background-color: rgba(252, 252, 252, 0.8);\n  z-index: 100;\n}\n.data-tab-permission-dialog .overlay-text {\n  text-align: center;\n  font-weight: bold;\n  font-size: 24px;\n  white-space: pre-wrap;\n  padding: 40px 20px;\n  word-break: keep-all;\n}\n.data-tab-permission-dialog .close-dialog-overlay {\n  cursor: pointer;\n  position: absolute;\n  right: 21px;\n  top: 21px;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
