<template>
  <el-select 
    v-model='localSelectedShop'
    value-key='name'
    filterable
    style='width: 400px'
    >
    <el-option
      v-for="shop in data"
      :key='shop.id'
      :label='shop.name'
      :value='shop'>
      <shop-option :shop="shop" />
    </el-option>
  </el-select>
</template>

<script>
import ShopOption from './ShopOption'
import { sync, call, get } from 'vuex-pathify'

export default {
  components: { ShopOption },
  data () {
    return {
      data: this.$store.state.shops
    }
  },
  methods: {
    changeShop: call('rejectedInvoices/changeShop')
  },
  computed: {
    selectedShop: get('rejectedInvoices/selectedShop'),
    localSelectedShop: {
      get() {
        return this.selectedShop
      },
      set (val) {
        this.changeShop(val)
      }
    }
  },
  watch: {

  }
}
</script>

<style>
</style>
