<template>
  <el-dialog
    append-to-body
    :visible="isOpened"
    :before-close="close"
    custom-class="notifications-settings-dialog">

    <div slot="title" class="d-flex align-items-center notifications-settings-title">

      <div>Notifications Settings</div>

      <!-- LOADERS -->
      <transition name="status-fade" :duration="1000">
        <div class="status-saving d-flex" v-if="status == 'saving'">
          <div class="icon"><i class="el-icon-loading"></i></div>
          <div class="text">Saving...</div>
        </div>
      </transition>
      <transition name="status-fade" :duration="1000">
        <div class="status-saved d-flex" v-if="status == 'saved'">
          <div class="icon"><i class="el-icon-check"></i></div>
          <div class="text">Saved!</div>
        </div>
      </transition>

    </div>

    <div
      class="notifications-settings-body"
      v-if="settings_loaded && settings_events_loaded"
    >
      <settings-general
        class="settings-general"
        :data="settings"
      />

      <div class="settings-events">
        <el-tabs class="settings-events-tabs" :value="defaultTab">
          <el-tab-pane
            v-for="(tab_layout, index) in layout"
            :label="tab_layout.title"
            :name="tab_layout.title"
            :key="index"
          >
            <ul class="list-events">
              <settings-event
                v-for="(event_layout, event_index) in tab_layout.events"
                :key="event_index"
                :title="event_layout.title"
                :description="event_layout.description"
                :permitted="$role.can(event_layout.permission)"
                :item="settings_events[event_layout.key]"
              />
            </ul>
          </el-tab-pane>

        </el-tabs>
      </div>
    </div>
    <div v-loading="true" v-else />

  </el-dialog>
</template>

<script>
import { layout } from '@/store/modules/notifications-settings/layout';
import { mapState, mapActions } from 'vuex';
import SettingsEvent from './SettingsEvent';
import SettingsGeneral from './SettingsGeneral';

export default {
  data() {
    return {
      defaultTab: layout[0].title,
      layout: layout
    }
  },

  computed: {
    ...mapState('notificationsSettings', [
      'isOpened',
      'status',
      'settings',
      'settings_loaded',
      'settings_events',
      'settings_events_loaded'
    ]),
  },

  methods: {
    ...mapActions('notificationsSettings', ['close', 'load']),

    /**
     * Go through all events and mark those that permitted
     */
    getLayoutAllowed() {
      const allowed = layout.map(tab => {
        tab.events = tab.events.map(event => {
          event.allowed = this.$role.can(event.permission);
          return event;
        });

        return tab;
      });
      return allowed;
    }
  },

  components: {
    SettingsGeneral,
    SettingsEvent
  },
}
</script>

<style lang="scss">
.notifications-settings-dialog { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  .notifications-settings-dialog { font-family: 'Inter var', sans-serif; }
}

@media only screen and (max-width: 420px)  {
  .notifications-settings-dialog {
    width: 95% !important;
  }
}

.notifications-settings-dialog {
  width: 75%;
  padding: 16px 24px 16px 24px;

  .el-dialog__headerbtn i {
    font-weight: 900;
    color: black;
  }

  .notifications-settings-title {
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    // TRANSITION FOR SAVER
    .status-fade-enter-active, .status-fade-leave-active {
      transition: opacity .5s;
    }
    .status-fade-enter, .status-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
    .status-saving, .status-saved {
      position: absolute;
      right: calc(50% - 50px);
      width: 100px;

      gap: 9px;

      .icon > i {
        font-size: 21px;
      }

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
    }
    .status-saving {
      color: #B45309;
    }
    .status-saved {
      color: #158D53;
    }
  }

  .el-dialog__header {
    padding: 0px;

    .el-dialog__close {
      margin-top: 12px;
    }
  }

  .el-dialog__body {
    min-height: 100px;
    padding: 0px;
  }

  .notifications-settings-body {
    .settings-general {
      margin-top: 8px;
      border-top: 1px solid #D1D5DB;
    }

    .settings-events {
      margin-top: 8px;

      .settings-events-tabs {

        .el-tabs__header {
          margin-bottom: 8px;
        }

        .el-tabs__active-bar {
          background-color: #0F59D7;
        }
        .el-tabs__item {
          min-width: 110px;
          text-align: center;
          padding: 0px;
          color: gray;
          height: 44px;
        }
        .el-tabs__item.is-active {
          color: #0F59D7;
        }
      }

      .list-events {
        list-style: none;
        padding: 0px;
      }
    }
  }
}
</style>
