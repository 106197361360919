<template>
  <el-dialog
    center
    append-to-body
    :close-on-click-modal="false"
    :visible.sync="visible"
    :before-close="handleClose"
    title="Get Your Own Personalized Number"
    top='5vh'
    custom-class="text-ad-dialog">
    <div class='center' style='font-size: large; white-space: wrap;'>
      <p style='word-break: keep-all;'>
        You are currently using the GlassBiller default texting number. You can continue to use this number, but you are missing out
        on all GlassBiller can do in regards to your shop's communication. Check out just a few of the great new features available with our texting packages:
      </p>
      <ul>
        <li style='word-break: keep-all;'>
          Receive text messages from customers, technicians, sales reps, and anybody else you’d like right from GlassBiller.
        </li>
        <li style='word-break: keep-all;'>
          Search your database to find customers and others and link the text message thread to that customer, tech, or sales rep.
        </li>
        <li style='word-break: keep-all;'>
          Have multiple numbers. You may want one number that you send scheduling messages from, while you may want a different number 
          that you text customers review links to your business, etc.
        </li>
        <li style='word-break: keep-all;'>
          Send confirmation texts quickly and easily to your customers so they can confirm your repair/replacement appointments with a simple reply, allowing
          you to see quickly on the job screen/scheduler who has confirmed.
        </li>
      </ul>
      <p style='word-break: keep-all;'>
        The GlassBiller texting feature will need to be turned on so if you would like to use this feature or to request a demonstration please contact support at 
        <a href="mailto:support@glassbiller.com">
          support@glassbiller.com
        </a> 
        and mention you want to try the texting feature.
      </p>
    </div>
    <br/>
    <div class='texting-ad-image'>
    </div>
  </el-dialog>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'

export default {
  computed: {
    visible: sync('twilio/textMessages/ad/visible'),
    referral: get('twilio/textMessages/ad/referral')
  },
  watch: {
  },
  methods: {
    openTextMessageInvoiceDialog: call('twilio/textMessages/jobInvoice/open'),
    openTextMessagePaymentDialog: call('twilio/textMessages/jobPayment/open'),
    openTextMessageSignatureDialog: call('twilio/textMessages/jobSignature/open'),
    openTssInviteDialog: call('twilio/textMessages/tssInvite/open'),
    setTextMessageAdReferral: call('twilio/textMessages/ad/setReferral'),
    setSelectAdDialogVisible: call('twilio/textMessages/ad/setSelectAdDialogVisible'),
    handleClose () {
      this.visible = false
      if (this.referral === 'job-invoice') {
        this.openTextMessageInvoiceDialog()
      }
      if (this.referral === 'job-payment') {
        this.openTextMessagePaymentDialog()
      }
      if (this.referral === 'job-signature') {
        this.openTextMessageSignatureDialog()
      }
      if (this.referral === 'tss-invite') {
        this.openTssInviteDialog()
      }
      this.setTextMessageAdReferral(null)
    }
  }
}
</script>

<style>
  .text-ad-dialog {
    @apply t-font-sans;
  }
</style>