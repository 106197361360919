// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".glaxis-credentials-form .el-checkbox__label {\n  display: none !important;\n}\n.glaxis-credentials-form .el-checkbox__inner, .glaxis-credentials-form .el-radio__inner {\n  --tw-border-opacity: 1;\n  border-color: rgba(107, 114, 128, var(--tw-border-opacity));\n}\n.glaxis-credentials-form .el-radio__label {\n  display: none !important;\n}", "",{"version":3,"sources":["/app/src/components/Vendors/Glaxis/CredentialsForm.vue","/app/<no source>","CredentialsForm.vue"],"names":[],"mappings":"AAmSE;EACE,wBAAA;AAvRJ;ACbA;EAAA,sBAAA;EAAA,2DAAA;ACiBA;AF0RE;EACE,wBAAA;AAzRJ","file":"CredentialsForm.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.glaxis-credentials-form .el-checkbox__label {\n  display: none !important;\n}\n.glaxis-credentials-form .el-checkbox__inner, .glaxis-credentials-form .el-radio__inner {\n  @apply t-border-gray-500;\n}\n.glaxis-credentials-form .el-radio__label {\n  display: none !important;\n}",null,":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.glaxis-credentials-form .el-checkbox__label {\n  display: none !important;\n}\n.glaxis-credentials-form .el-checkbox__inner, .glaxis-credentials-form .el-radio__inner {\n  --tw-border-opacity: 1;\n  border-color: rgba(107, 114, 128, var(--tw-border-opacity));\n}\n.glaxis-credentials-form .el-radio__label {\n  display: none !important;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
