var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasPhoneNumber
    ? _c(
        "div",
        { staticClass: "job-confirmation-details" },
        [
          _vm.info.type === "default" && _vm.schedulerJobsTimers[_vm.job.id]
            ? _c("confirmation-countdown", {
                attrs: { jobId: _vm.job.id, scheduler: true },
                on: { openConfirmationDialog: _vm.openConfirmationDialog }
              })
            : _c(
                "div",
                {
                  staticClass: "confirmation-button",
                  class: ((_obj = {}), (_obj[_vm.info.type] = true), _obj),
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.openConfirmationDialog($event)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "material-icons",
                    class:
                      ((_obj$1 = {}), (_obj$1[_vm.info.icon] = true), _obj$1)
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.info.text))])
                ]
              ),
          _vm.info.date
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    content: _vm.info.date,
                    placement: "right",
                    effect: "light"
                  }
                },
                [
                  _c("span", { staticClass: "confimation-time" }, [
                    _vm._v(_vm._s(_vm.info.time) + " ago")
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }