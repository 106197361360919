<template>
  <div class="select-parent">
    <el-popover
      v-if="accountType === 'Clearing Account'"
      placement="top-start"
      width="275"
      trigger="hover">
      <div style="white-space:pre-wrap; text-align:start;">{{'A bank account must be chosen as\na clearing account for adjusting entries.'}}</div>
      <span class="select-label special-content-label" slot="reference">*{{label}}</span>
    </el-popover>

    <span v-else class="select-label">{{label}}</span>

    <el-select
      v-model="selected"
      :filterable="true"
      placeholder="Select"
      @change="updateShopDefaultQboAccountHandler"
      :disabled="disabled"
      :loading="loading"
      popper-class="qbo-mapping-popper"
    >
      <el-option
        v-if="accountType.includes('Adjustment') || accountType === 'Clearing Account'"
        key="System Default"
        label="No Adjusting Entry"
        value="System Default">
      </el-option>
      <el-option
        v-else
        key="System Default"
        label="System Default"
        value="System Default">
      </el-option>
      <el-option
        class="qbo-mapping-option"
        v-for="item in options"
        :key="item.Id"
        :label="item.Name"
        :value="item.Id">
          <quick-books-account-option v-bind="item" />
      </el-option>
    </el-select>
  </div>
</template>

<script>
import QuickBooksAccountOption from './QuickBooksAccountOption.vue'
import { mapActions, mapState } from 'pinia'
import { useQboStore, gbExportItems } from '@/components/modals/QuickBooksAccountMappingDialog/qbo.store.js'

export default {
  components: {
    QuickBooksAccountOption
  },

  props: {
    accountType: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      options: [],
      selected: 'System Default',
      loading: false
    }
  },

  computed: {
    ...mapState(useQboStore, ['shopId', 'defaults']),

    selectedAccount () {
      return this.options.filter(o => o.Id === this.selected)[0]
    },

    label () {
      if (this.accountType.includes('Payment')) {
        return this.accountType.replace(' Payment', '')
      }

      if (this.accountType.includes('Adjustment')) {
        return this.accountType.replace(' Adjustment', '')
      }

      return this.accountType
    }
  },

  async created() {
    this.initialize()
  },

  methods: {
    ...mapActions(useQboStore, [
      'updateShopQboMapping',
      'getShopAccountsFromQbo',
      'deleteShopQboMapping'
    ]),

    async initialize () {
      try {
        this.loading = true
        const accounts = await this.getShopAccountsFromQbo(this.shopId, this.accountType)
        this.options = accounts || []

        const accountDefault = this.defaults.filter(
          account => account.gb_export_item === gbExportItems[this.accountType]
        )
        // Todo: handle default not found in qbo
        if (accountDefault && accountDefault.length > 0) {
          this.selected = accountDefault[0].qbo_account_id.toString()
        } else {
          this.selected = 'System Default'
        }
      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.loading = false
      }
    },

    async updateShopDefaultQboAccountHandler (val) {
      if (val === 'System Default') {
        // delete mapping for this type
        try {
          await this.deleteShopQboMapping(this.shopId, gbExportItems[this.accountType])
        } catch (e) {
          this.$message.error(e.message)
        }
      } else {
        // udpate mapping for this type
        try {
          await this.updateShopQboMapping(
            this.shopId,
            {
              gb_export_item: gbExportItems[this.accountType],
              qbo_account_id: val,
              qbo_account_name: this.selectedAccount.Name
            }
          )
        } catch (e) {
          this.$message.error(e.message)
        }
      }
    }
  },

  watch: {
    shopId: function (val, oldVal) {
      if (val && val !== oldVal) {
        this.initialize()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .select-label {
    display: inline-block;
    width: 150px;
  }
  .special-content-label {
    display: inline-block;
    width: 150px;
    color: #8492a6;
    font-style: italic;
  }
  .el-select-dropdown__item.qbo-mapping-option {
    height: 75px;
  }
  .select-parent {
    white-space: nowrap;
  }
</style>

<style lang="scss">
  .qbo-mapping-popper {
    @media screen and (max-width: 503px) {
      max-width: 100vw !important;
      min-width: 100vw !important;
    }
  }
</style>
