import firebase from 'firebase/app'
import 'firebase/firestore'
import Vue from 'vue'
import firebaseConfig from '../constants/firebase.config'
import { get, sync } from 'vuex-pathify'
import * as R from 'ramda'

let instance

firebase.initializeApp(firebaseConfig)

export const useTechLocations = store => {
  if (instance) return instance

  instance = new Vue({
    data() {
      return {
        collection: null,
        subscriptions: []
      }
    },
    computed: {
      techs: get('sch_v3/techsideUsernames'),
      locations: sync('sch_v3/techsideLocations')
    },
    async created() {
      this.$store = store
      this.collection = firebase.firestore().collection('location')
      this.subscriptions = []
    },
    watch: {
      techs(techs, oldTechs) {
        if (!R.equals(techs, oldTechs)) {
          techs.forEach(username => {
            if (username && !this.subscriptions[username]) {
              this.subscriptions[username] = this.collection
                .doc(username)
                .onSnapshot(snapshot => {
                  this.locations = {
                    ...this.locations,
                    [username]: snapshot.data()
                  }
                })
            }
          })
        }
      }
    },
    beforeDestroy() {
      R.forEach(unsubscribe => {
        unsubscribe()
      })
      this.subscriptions = {}
    }
  })

  return instance
}

export default {
  install(Vue, options) {
    Vue.prototype.$store = options
    Vue.prototype.$techLocations = useTechLocations(options)
  }
}
