<template>
  <div class="scheduler-date-filter">
    <div class="ui-input-label">Date:</div>
    <el-date-picker
      v-model="week"
      type="week"
      placeholder="Pick a week"
      format="week of MMM d"
      :clearable="false"
    />
  </div>
</template>

<script>
import { startOfWeek } from 'date-fns'

export default {
  props: ['value'],
  computed: {
    week: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  mounted() {
    this.$emit('input', startOfWeek(new Date()))
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/SchedulerDateFilter';
</style>

<style lang="scss">
.date-picker {
  input {
    border: 1px solid #e8e8e8;
    box-shadow: none;
    height: 40px;
  }
}
</style>
