import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class NagsLicense extends ObjectTracker {
  id = -1
  user_id = -1
  status = 'Unreported'
  anniversary_date = ''
  termination_date = ''
  nags_license_type_id = 1 // US default
  nags_license_type = null
  next_renewal_date= ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getNagsLicensesDetails (callbackFunction, errorCallback) {
    Api({ url: `/api/nags_licenses_details` }).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  // GET ALL SALE SOURCES BY USER + FILTERS
  static getNagsLicensesTable (sortBy, sortDesc, loadPosition, nagsLicenseIdFilter, nagsLicenseStatusFilter, nagsLicenseUsernameFilter,
    nagsLicenseRenewalMonth, nagsLicenseRenewalYear, nagsLicenseIncludeOnOrBeforeDate, nagsLicenseAnniversaryMonth, nagsLicenseAnniversaryYear,
    nagsLicenseAnniversaryIncludeOnOrBeforeDate, nagsLicenseIncludeAllUnreported, successCallback, errorCallback) {
    Api({url: '/api/nags_licenses?sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&loadPosition=' + encodeURIComponent(loadPosition) + '&nagsLicenseIdFilter=' + encodeURIComponent(nagsLicenseIdFilter) +
      '&nagsLicenseStatusFilter=' + encodeURIComponent(nagsLicenseStatusFilter) + '&nagsLicenseUsernameFilter=' + encodeURIComponent(nagsLicenseUsernameFilter) +
      '&nagsLicenseRenewalMonth=' + encodeURIComponent(nagsLicenseRenewalMonth) + '&nagsLicenseRenewalYear=' + encodeURIComponent(nagsLicenseRenewalYear) + 
      '&nagsLicenseIncludeOnOrBeforeDate=' + encodeURIComponent(nagsLicenseIncludeOnOrBeforeDate) +
      '&nagsLicenseAnniversaryMonth=' + encodeURIComponent(nagsLicenseAnniversaryMonth) + '&nagsLicenseAnniversaryYear=' + encodeURIComponent(nagsLicenseAnniversaryYear) + 
      '&nagsLicenseAnniversaryIncludeOnOrBeforeDate=' + encodeURIComponent(nagsLicenseAnniversaryIncludeOnOrBeforeDate) + '&nagsLicenseIncludeAllUnreported=' + encodeURIComponent(nagsLicenseIncludeAllUnreported)
    }).then(res => {
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  static createUpdateNagsLicense (nagsLicenseId, userId,
    status, anniversary_date, next_renewal_date, termination_date, renewalHistory, nags_license_type_id, renewalHistoryDeleteIds, callbackFunction, errorCallbackFunction = null) {
    if (!nagsLicenseId || nagsLicenseId <= 0) {
      nagsLicenseId = -1
    }
    var nagsLicense = {}
    nagsLicense['id'] = nagsLicenseId
    nagsLicense['userId'] = userId
    nagsLicense['status'] = status
    nagsLicense['anniversary_date'] = anniversary_date
    nagsLicense['next_renewal_date'] = next_renewal_date
    nagsLicense['termination_date'] = termination_date
    nagsLicense['renewalHistory'] = renewalHistory
    nagsLicense['renewalHistoryDeleteIds'] = renewalHistoryDeleteIds
    nagsLicense['nags_license_type_id'] = nags_license_type_id

    return Api.post(`/api/nags_licenses/${nagsLicenseId}`, nagsLicense).then(res => {
      callbackFunction(res.data.nagsLicenseId)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static renewNagsLicenses (renewDate, nextRenewalDate, renewalIds, callbackFunction, errorCallbackFunction = null) {
    var data = {}
    data['renewDate'] = renewDate
    data['nextRenewalDate'] = nextRenewalDate
    data['renewalIds'] = renewalIds

    return Api.post(`/api/nags_license_renewal`, data).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getNagsLicense (nagsLicenseId, callbackFunction, errorCallback) {
    Api({ url: `/api/nags_license/${nagsLicenseId}` }).then(res => {
      callbackFunction(res.data.nagsLicense, res.data.renewalHistory, res.data.selectedUser)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  static getUserNagsLicense (userId, callbackFunction, errorCallback = null) {
    Api({ url: `/api/nags_license?userId=` + encodeURIComponent(userId) }).then(res => {
      console.log('got response: ', res.data.nagsLicense)
      callbackFunction(res.data.nagsLicense)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  deleteNagsLicense (successCallbackFunction, errorCallbackFunction = null) {
    if (!this.id || this.id <= 0) {
      successCallbackFunction()
    }
    return Api.delete(`/api/nags_license/${this.id}`).then(res => {
      if (res.data && res.data.success && res.data.success === 'deleted') {
        successCallbackFunction()
      } else {
        errorCallbackFunction(null)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
