<template>
  <div
    class="claim-status-tag"
    :style="{ background: statusColor }"
    :class="{ small, 'pointer-tag': openDialog }"
    @click="onClick"
  >
   {{includeClaimStatusText ? 'Claim: ' : ''}} {{ status }}
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'

const claimStatusesTitle = {
	CLAIM_STATUS_NEW: 'created',
	CLAIM_STATUS_COVERAGE: 'coverage response received',
	CLAIM_STATUS_PENDING: 'loss notice sent',
	CLAIM_STATUS_SUCCESS: 'acknowledge sent',
	CLAIM_STATUS_ERROR: 'error occured',
}

export default {
  props: { job: Object, includeClaimStatusText: Boolean, small: Boolean, openDialog: Boolean },
  computed: {
    jobClaimStatuses: sync('glaxis/jobClaimStatuses'),
    status() {
      return claimStatusesTitle[this.job.glaxisClaim.result.status]
    },
    statusColor() {
      if (this.status === 'CLAIM_STATUS_SUCCESS') {
        return 'green'
      } else if (this.status === 'CLAIM_STATUS_ERROR') {
        return 'red'
      } else {
        return 'orange'
      }
    }
  },
  methods: {
    onClick() {
      if (this.openDialog) {
        window.dispatchEvent(new CustomEvent('showReviewClaimDialog', { detail: this.$job.glaxisClaim }));
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pointer-tag {
  cursor: pointer;
}
.claim-status-tag {
  color: #fff;
  text-align: center;
  padding: 0 $padding-xs;
  border-radius: 4px;
  font-size: $font-md;
  line-height: 20px;
  margin: 0 $padding-xs;
  text-transform: uppercase;
  font-weight: 600;
  display:inline-block;
  &.small {
    font-size: $font-sm;
    font-weight: 400;
  }
}
</style>
