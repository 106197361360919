import { error } from 'jquery'
import clover from 'remote-pay-cloud'
import sdk from 'remote-pay-cloud-api'
import cfg from '@/config'

let status
let cloverConnector
let defaultCloverConnectorListener

const init = async (cloverCredentials, deviceCredentials, connectedCallback) => {
  try {
    if (cloverConnector) {
      connectDispose()
    }
    const cloudConfigurationBuilder = new clover.WebSocketCloudCloverDeviceConfigurationBuilder(
      cfg.clover.clientId,
      deviceCredentials.clover_id,
      cloverCredentials.clover_merchant_id,
      cloverCredentials.clover_access_token
    )
    const cloudConfiguration = cloudConfigurationBuilder
      // .setForceConnect(true)
      .setCloverServer(cfg.clover.serverUrl)
      .setFriendlyId(deviceCredentials?.name || ('GlassBiller 1' + Date.now()))
      .build()

    var builderConfiguration = {}
    builderConfiguration[clover.CloverConnectorFactoryBuilder.FACTORY_VERSION] = clover.CloverConnectorFactoryBuilder.VERSION_12;
    var cloverConnectorFactory = clover.CloverConnectorFactoryBuilder.createICloverConnectorFactory(builderConfiguration)
    cloverConnector = cloverConnectorFactory.createICloverConnector(cloudConfiguration)
    defaultCloverConnectorListener = Object.assign({}, clover.remotepay.ICloverConnectorListener.prototype, {
      onDeviceReady: function (merchantInfo) {
        status = 'device ready'
        sendMessageToDevice('Connecting to GlassBiller', 3000)
        connectedCallback(merchantInfo)
      },
      onDeviceDisconnected: function (response) {
        status = 'device disconnected'
      },

      onDeviceConnected: function (response) {
        status = 'device connected'
      },
      onDeviceError: (response) => {
        console.log('on error', response)
        status = 'device error'
      },
      onDisplayReceiptOptionsResponse(response, response2) {
        console.log('receipt thingy', response, response2)
      },
      onPrintJobStatusResponse(response) {
        console.log('print job status', response)
      },
      onPrintPaymentReceipt(message, message2) {
        console.log('print payment receipt', message, message2)
      }
    })
    cloverConnector.addCloverConnectorListener(defaultCloverConnectorListener)
    setDisposalHandler()
    cloverConnector.initializeConnection()
  } catch (err) {
    console.log('here is some error', err)
  }
}

const createSale = (amount, externalId, successCallback, errorCallback) => {
  var saleListener = Object.assign({}, defaultCloverConnectorListener, {
    onSaleResponse: function (response) {
      if (!response.success) {
        errorCallback(response)
        // cloverConnector.showWelcomeScreen()
        return
      }
      successCallback(response)
    },
 
    onConfirmPaymentRequest: function (request) {
      for (var i = 0; i < request.getChallenges().length; i++) {
        // Boolean of whether the app is resolving the last challenge in the Challenges array
        var isLastChallenge = i === request.getChallenges().length - 1;
  
        if (confirm(request.getChallenges()[i].getMessage())) {
          if (isLastChallenge) {
            cloverConnector.acceptPayment(request.getPayment());
          }
        } else {
          cloverConnector.rejectPayment(request.getPayment(), request.getChallenges()[i]);
          return;
        }
      }
    },
  })
 
  cloverConnector.addCloverConnectorListener(saleListener);

  var saleRequest = new sdk.remotepay.SaleRequest();
  saleRequest.setExternalId(clover.CloverID.getNewId());
  saleRequest.setExternalReferenceId(externalId);
  saleRequest.setAmount(amount);
  saleRequest.setAutoAcceptSignature(true);
  
  cloverConnector.sale(saleRequest);
}

const setDisposalHandler = () => {
  window.onbeforeunload = () => {
    console.log('unloading', cloverConnector, this)
    try {
      if (cloverConnector) {
        cancelSale()
      }
    } catch (e) {
      console.error(e)
    }
  }
}

const cancelSale = () => {
  try {
    cloverConnector.resetDevice()
    cloverConnector.dispose()
    cloverConnector = null
  } catch (e) {
    console.log(e)
  }
}

const connectDispose = () => {
  if (cloverConnector) {
    cloverConnector.dispose()
    cloverConnector = null
  }
  // remove on before unload handler
  window.onbeforeunload = () => {}
}

const sendMessageToDevice = (msg, time) => {
  cloverConnector.showMessage(msg)
  setTimeout(() => cloverConnector.showWelcomeScreen(), time)
}

export const CloverDeviceService = Object.freeze({
  init,
  createSale,
  cancelSale,
  connectDispose
})