var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("md-circle-button", {
    attrs: {
      icon: _vm.selected
        ? "check_box"
        : _vm.indeterminate
        ? "indeterminate_check_box"
        : "check_box_outline_blank",
      outline: "",
      primary: _vm.selected,
      size: "normal",
      disabled: _vm.locked || _vm.disabled
    },
    on: { click: _vm.set }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }