var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consumer-notes" },
    [
      _c("input-with-label", {
        attrs: { type: "textarea", options: { rows: 8 }, label: "Notes" },
        model: {
          value: _vm.notes,
          callback: function($$v) {
            _vm.notes = $$v
          },
          expression: "notes"
        }
      }),
      _c("input-with-label", {
        staticClass: "auto-popup",
        attrs: {
          type: "textarea",
          options: { rows: 3 },
          label: "Auto popup note",
          disabled: !_vm.autoPopup
        },
        model: {
          value: _vm.autoPopupMessage,
          callback: function($$v) {
            _vm.autoPopupMessage = $$v
          },
          expression: "autoPopupMessage"
        }
      }),
      _c(
        "gb-checkbox",
        {
          model: {
            value: _vm.autoPopup,
            callback: function($$v) {
              _vm.autoPopup = $$v
            },
            expression: "autoPopup"
          }
        },
        [_vm._v("Auto popup")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }