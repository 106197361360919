const sgcAddress = {
  address: "",
  city: "Columbus",
  full_address: "Columbus, OH 43218",
  google_place_id: "ChIJ6zh5pSKKOIgRgoVNGR-gGhM",
  lat: 40.0151339,
  lng: -82.9246084,
  po_box: 1,
  state: "OH",
  unit: "182277",
  zip: "43218"
};

const sgcEmail = {
  email: "invoicingfax@safelite.com"
};

const sgcPhone = {
  number: "(614) 602-2120"
};

export const sgcFields = {
  address: sgcAddress,
  addresses: [sgcAddress],
  email: sgcEmail,
  emails: [sgcEmail],
  website: "sgc.safelite.com",
  phone: sgcPhone,
  phones: [sgcPhone]
};

const lynxAddress = {
  address: "6351 Bayshore Rd",
  city: "North Fort Myers",
  full_address: "6351 Bayshore Rd, North Fort Myers, FL 33917",
  google_place_id: "ChIJ3cwYc85p24gRUjHN3CI1LX0",
  lat: 26.697866,
  lng: -81.853935,
  po_box: 0,
  state: "FL",
  unit: "18",
  zip: "33917"
};

const lynxPhone = {
  number: "(239) 479-6000"
};

export const lynxFields = {
  address: lynxAddress,
  addresses: [lynxAddress],
  website: "www.lynxservices.com",
  phone: lynxPhone,
  phones: [lynxPhone],
  fax: null,
  email: null,
  emails: []
};
