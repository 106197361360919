import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Insurancefleet extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  name = ''
  phone = ''
  fax = ''
  email = ''
  address_city = ''
  address_state = ''
  address_zip = ''
  notes = ''
  edi_capable = 0
  edi_quest_program_id = ''
  edi_safelite_program_id = ''
  tax_exempt = 0
  auto_add_adhesive = 1
  po_required = 0
  discount = 0
  oem_discount = 0
  labor_flat = 0
  labor_hourly = 0
  kit_standard = 0
  kit_high = 0
  kit_fast = 0
  chip_first = 0
  chip_additional = 0
  modified = -1
  web = ''
  shop_id = -1
  address = ''
  edi_trading_partner = ''
  kit_charge_type_standard = 'each'
  kit_charge_type_high = 'each'
  kit_charge_type_fast = 'each'
  moulding_discount = 0
  cash_customer = 0
  auto_popup = 0
  auto_popup_message = ''
  display_phone = 0
  export_as_customer = 0
  recal_safelite_dynamic = 0
  recal_safelite_static = 0
  recal_safelite_dual = 0
  recal_lynx_rtl_dynamic = 0
  recal_lynx_rtl_static = 0
  recal_lynx_rtl_both = 0
  recal_lynx_dlr_dynamic = 0
  recal_lynx_dlr_static = 0
  recal_lynx_dlr_both = 0
  default_invoice_tier = null

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, callbackFunction) {
    Api({url: `/api/shop/${shopId}/insurancefleet`}).then(res => {
      // 
      var objects = []
      res.data.forEach(function (val, index) {
        var insurancefleet = new Insurancefleet(val)
        objects.push(insurancefleet)
      })
      callbackFunction(objects)
    })
  }

  // GET ALL INSURANCE FLEETS BY USER + FILTERS
  static getInsuranceFleetsByUserShops (page, limit, sortBy, sortDesc, insurancefleetIdFilter, insurancefleetNameFilter,
    insurancefleetShopNameFilter, insurancefleetEmailFilter, insurancefleetPhoneFilter, successCallback, errorCallback) {
    Api({url: `/api/userinsurancefleets?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&insurancefleetIdFilter=' + encodeURIComponent(insurancefleetIdFilter) + '&insurancefleetNameFilter=' + encodeURIComponent(insurancefleetNameFilter) +
      '&insurancefleetShopNameFilter=' + encodeURIComponent(insurancefleetShopNameFilter) + '&insurancefleetEmailFilter=' + encodeURIComponent(insurancefleetEmailFilter) +
      '&insurancefleetPhoneFilter=' + encodeURIComponent(insurancefleetPhoneFilter)
    }).then(res => {
      
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  createUpdateInsuranceFleet (shopId, callbackFunction, errorCallbackFunction = null) {
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    var data = {
      'insurancefleet': this
    }
    return Api.post(`/api/shop/${this.shop_id}/insurancefleet/${this.id}`, data).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
