var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingJobVehicleData,
          expression: "loadingJobVehicleData"
        }
      ],
      staticClass: "panel-container"
    },
    [
      !_vm.isSinglePartSearchModal
        ? _c(
            "div",
            {
              staticClass: "panel-header",
              attrs: {
                "data-toggle": "collapse",
                "data-target": "#panel-part-search",
                "aria-expanded": "true"
              }
            },
            [
              !_vm.isMobile
                ? _c("div", { staticClass: "t-flex" }, [
                    _c(
                      "div",
                      { staticClass: "t-flex-grow" },
                      [
                        _vm._v(" Catalog "),
                        _vm._l(_vm.user.nags_license, function(license, index) {
                          return _c(
                            "span",
                            { key: index },
                            [
                              _c("flag", {
                                attrs: {
                                  squared: false,
                                  iso: license.nags_license_type.flag_iso
                                }
                              })
                            ],
                            1
                          )
                        })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "t-flex t-normal-case t-font-sans",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.orderingStatusDialogVisible = true
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "t-text-purple-700" }, [
                          _vm._v(" Live Ordering Available ")
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-rounded-full t-bg-purple-600 t-ml-2 t-px-2 t-text-gray-200"
                          },
                          [_vm._v(" Beta ")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "none",
                          color: "darkviolet",
                          "font-style": "italic",
                          "margin-left": "10px"
                        },
                        attrs: { id: "accordion-total-invoice-toggler" }
                      },
                      [
                        _vm._v(
                          " Total Invoice: $" + _vm._s(_vm.totalToDisplay) + " "
                        )
                      ]
                    )
                  ])
                : _c(
                    "div",
                    [
                      _vm._v("1 Catalog "),
                      _vm._l(_vm.user.nags_license, function(license, index) {
                        return _c(
                          "span",
                          { key: index },
                          [
                            _c("flag", {
                              attrs: {
                                squared: false,
                                iso: license.nags_license_type.flag_iso
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "none",
                            color: "darkviolet",
                            "font-style": "italic",
                            "font-size": "10px"
                          },
                          attrs: { id: "accordion-total-invoice-toggler" }
                        },
                        [
                          _vm._v(
                            "Total Invoice: $" + _vm._s(_vm.totalToDisplay)
                          )
                        ]
                      )
                    ],
                    2
                  )
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "show", attrs: { id: "panel-part-search" } }, [
        _c(
          "div",
          {
            staticClass: "panel-body",
            staticStyle: { "padding-top": "0px !important" }
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "row" }, [
              !_vm.isSinglePartSearchModal
                ? _c("div", { staticClass: "col-lg-12" }, [
                    _c("div", { staticClass: "form-row mt-0" }, [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.nagsPartsPartition.length > 0,
                              expression: "nagsPartsPartition.length > 0"
                            }
                          ],
                          class: {
                            label: true,
                            "label-primary":
                              this.selectedSection == "partition",
                            "label-gray": this.selectedSection != "partition",
                            "label-sm": true
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedSection = "partition"
                              _vm.showJustInventory = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.selectedSection == "partition",
                                expression:
                                  "this.selectedSection == 'partition'"
                              }
                            ],
                            staticClass: "fa fa-check"
                          }),
                          _vm._v(" Partition")
                        ]
                      ),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.nagsPartsWindshield.length > 0,
                              expression: "nagsPartsWindshield.length > 0"
                            }
                          ],
                          class: {
                            label: true,
                            "label-primary":
                              this.selectedSection == "windshield",
                            "label-gray": this.selectedSection != "windshield",
                            "label-sm": true
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedSection = "windshield"
                              _vm.showJustInventory = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.selectedSection == "windshield",
                                expression:
                                  "this.selectedSection == 'windshield'"
                              }
                            ],
                            staticClass: "fa fa-check"
                          }),
                          _vm._v(" Windshield")
                        ]
                      ),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.nagsPartsSide.length > 0,
                              expression: "nagsPartsSide.length > 0"
                            }
                          ],
                          class: {
                            label: true,
                            "label-primary": this.selectedSection == "side",
                            "label-gray": this.selectedSection != "side",
                            "label-sm": true
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedSection = "side"
                              _vm.showJustInventory = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.selectedSection == "side",
                                expression: "this.selectedSection == 'side'"
                              }
                            ],
                            staticClass: "fa fa-check"
                          }),
                          _vm._v(" Side")
                        ]
                      ),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.nagsPartsDoor.length > 0,
                              expression: "nagsPartsDoor.length > 0"
                            }
                          ],
                          class: {
                            label: true,
                            "label-primary": this.selectedSection == "door",
                            "label-gray": this.selectedSection != "door",
                            "label-sm": true
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedSection = "door"
                              _vm.showJustInventory = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.selectedSection == "door",
                                expression: "this.selectedSection == 'door'"
                              }
                            ],
                            staticClass: "fa fa-check"
                          }),
                          _vm._v(" Door")
                        ]
                      ),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.nagsPartsRoof.length > 0,
                              expression: "nagsPartsRoof.length > 0"
                            }
                          ],
                          class: {
                            label: true,
                            "label-primary": this.selectedSection == "roof",
                            "label-gray": this.selectedSection != "roof",
                            "label-sm": true
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedSection = "roof"
                              _vm.showJustInventory = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.selectedSection == "roof",
                                expression: "this.selectedSection == 'roof'"
                              }
                            ],
                            staticClass: "fa fa-check"
                          }),
                          _vm._v(" Roof")
                        ]
                      ),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.nagsPartsVent.length > 0,
                              expression: "nagsPartsVent.length > 0"
                            }
                          ],
                          class: {
                            label: true,
                            "label-primary": this.selectedSection == "vent",
                            "label-gray": this.selectedSection != "vent",
                            "label-sm": true
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedSection = "vent"
                              _vm.showJustInventory = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.selectedSection == "vent",
                                expression: "this.selectedSection == 'vent'"
                              }
                            ],
                            staticClass: "fa fa-check"
                          }),
                          _vm._v(" Vent")
                        ]
                      ),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.nagsPartsQuarter.length > 0,
                              expression: "nagsPartsQuarter.length > 0"
                            }
                          ],
                          class: {
                            label: true,
                            "label-primary": this.selectedSection == "quarter",
                            "label-gray": this.selectedSection != "quarter",
                            "label-sm": true
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedSection = "quarter"
                              _vm.showJustInventory = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.selectedSection == "quarter",
                                expression: "this.selectedSection == 'quarter'"
                              }
                            ],
                            staticClass: "fa fa-check"
                          }),
                          _vm._v(" Quarter")
                        ]
                      ),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.nagsPartsBack.length > 0,
                              expression: "nagsPartsBack.length > 0"
                            }
                          ],
                          class: {
                            label: true,
                            "label-primary":
                              this.selectedSection == "backWindow",
                            "label-gray": this.selectedSection != "backWindow",
                            "label-sm": true
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedSection = "backWindow"
                              _vm.showJustInventory = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.selectedSection == "backWindow",
                                expression:
                                  "this.selectedSection == 'backWindow'"
                              }
                            ],
                            staticClass: "fa fa-check"
                          }),
                          _vm._v(" Back Window")
                        ]
                      ),
                      _c(
                        "a",
                        {
                          class: {
                            label: true,
                            "label-primary": this.selectedSection == "search",
                            "label-gray": this.selectedSection != "search",
                            "label-sm": true
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedSection = "search"
                              _vm.showJustInventory = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.selectedSection == "search",
                                expression: "this.selectedSection == 'search'"
                              }
                            ],
                            staticClass: "fa fa-check",
                            on: {
                              click: function($event) {
                                _vm.alert("click 1")
                                this.selectedSection = "search"
                              }
                            }
                          }),
                          _vm._v(" Search")
                        ]
                      ),
                      _c(
                        "a",
                        {
                          class: {
                            label: true,
                            "label-primary": _vm.showJustInventory,
                            "label-gray": !_vm.showJustInventory,
                            "label-sm": true
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.showJustInventory = !_vm.showJustInventory
                              _vm.selectedSection = ""
                            }
                          }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showJustInventory,
                                expression: "showJustInventory"
                              }
                            ],
                            staticClass: "fa fa-check"
                          }),
                          _vm._v(" Inventory")
                        ]
                      ),
                      _vm.showLoadNagsButton
                        ? _c(
                            "a",
                            {
                              class: {
                                label: true,
                                "label-primary": true,
                                "label-sm": true,
                                "label-active-green": true
                              },
                              staticStyle: { "font-weight": "bold" },
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.triggerNagsSearch()
                                }
                              }
                            },
                            [_vm._v(" Load Nags")]
                          )
                        : _vm._e()
                    ]),
                    _vm.showJustInventory
                      ? _c("div", { staticClass: "mt-3" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.inventorySearchText,
                                expression: "inventorySearchText"
                              }
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "Search Inventory"
                            },
                            domProps: { value: _vm.inventorySearchText },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.inventorySearchText = $event.target.value
                              }
                            }
                          })
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.selectedSection == "search"
                ? _c("div", { staticClass: "col-lg-12" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-9" },
                        [
                          _c("label", { attrs: { for: "#" } }, [
                            _vm._v("Search Parts:")
                          ]),
                          _c("Autocomplete", {
                            ref: "partSearchAutocomplete",
                            attrs: {
                              smallInput: true,
                              dataSet: _vm.nagsSearchAutoCompleteResults,
                              filterInternally: false,
                              inLoading: _vm.loadingResults,
                              searchValue: _vm.searchText,
                              placeholder: "(3-char. min)",
                              autoCompleteHeight: 500,
                              showSpinner: true,
                              closeOnClick: true,
                              inInputRef: "inputRef"
                            },
                            on: {
                              selected: _vm.selectSearchAutoComplete,
                              "update:searchValue": function($event) {
                                _vm.searchText = $event
                              },
                              "update:search-value": function($event) {
                                _vm.searchText = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-group col-md-3" }, [
                        _c("label"),
                        _c("input", {
                          staticClass:
                            "form-control form-control-sm btn btn-sm btn-gray",
                          attrs: { type: "button", value: "Clear" },
                          on: {
                            click: function($event) {
                              return _vm.clearButtonPressed()
                            }
                          }
                        })
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ]
        ),
        !_vm.showJustInventory
          ? _c("div", [
              this.selectedSection !== "search"
                ? _c(
                    "div",
                    { ref: "partResultsArea", staticClass: "scrollable-wrap" },
                    _vm._l(_vm.activeParts, function(part, topIndex) {
                      return _c("PartResult", {
                        key: topIndex,
                        attrs: {
                          isSinglePartSearchModal: _vm.isSinglePartSearchModal,
                          part: part,
                          shopId: _vm.shopId,
                          topIndex: topIndex,
                          popupPartDetails: _vm.popupPartDetails,
                          edi_locked: _vm.edi_locked,
                          insurancefleetAutoAddAdhesive:
                            _vm.insurancefleetAutoAddAdhesive,
                          glassGraphics: _vm.glassGraphics,
                          inventoryParts: _vm.inventoryParts,
                          pricelevels: _vm.pricelevels,
                          selectedPriceLevel: _vm.selectedPriceLevel,
                          job: _vm.job,
                          showJustInventory: _vm.showJustInventory,
                          shopParts: _vm.shopParts,
                          trends: _vm.trends,
                          inventoryItems: _vm.inventoryItems,
                          shop: _vm.shop
                        },
                        on: {
                          "update:popupPartDetails": function($event) {
                            _vm.popupPartDetails = $event
                          },
                          "update:popup-part-details": function($event) {
                            _vm.popupPartDetails = $event
                          }
                        }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              this.selectedSection === "search"
                ? _c(
                    "div",
                    {
                      ref: "searchResultsArea",
                      staticClass: "scrollable-wrap",
                      attrs: { id: "searchResultsArea" }
                    },
                    [
                      _vm.activeParts["Partition"] &&
                      _vm.activeParts["Partition"].length > 0
                        ? [
                            _vm._m(1),
                            _c(
                              "div",
                              {
                                staticClass: "collapse partResultCollapse show",
                                attrs: { id: "partitionSearchResults" }
                              },
                              _vm._l(_vm.activeParts["Partition"], function(
                                part,
                                topIndex
                              ) {
                                return _c("PartResult", {
                                  key: topIndex + "-partition",
                                  attrs: {
                                    isSinglePartSearchModal:
                                      _vm.isSinglePartSearchModal,
                                    part: part,
                                    shopId: _vm.shopId,
                                    topIndex: topIndex + "-partition",
                                    popupPartDetails: _vm.popupPartDetails,
                                    edi_locked: _vm.edi_locked,
                                    insurancefleetAutoAddAdhesive:
                                      _vm.insurancefleetAutoAddAdhesive,
                                    glassGraphics: _vm.glassGraphics,
                                    inventoryParts: _vm.inventoryParts,
                                    pricelevels: _vm.pricelevels,
                                    selectedPriceLevel: _vm.selectedPriceLevel,
                                    job: _vm.job,
                                    showJustInventory: _vm.showJustInventory,
                                    shopParts: _vm.shopParts,
                                    trends: _vm.trends,
                                    inventoryItems: _vm.inventoryItems,
                                    shop: _vm.shop
                                  },
                                  on: {
                                    "update:popupPartDetails": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    },
                                    "update:popup-part-details": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.activeParts["Windshield"] &&
                      _vm.activeParts["Windshield"].length > 0
                        ? [
                            _vm._m(2),
                            _c(
                              "div",
                              {
                                staticClass: "collapse partResultCollapse show",
                                attrs: { id: "windshieldSearchResults" }
                              },
                              _vm._l(_vm.activeParts["Windshield"], function(
                                part,
                                topIndex
                              ) {
                                return _c("PartResult", {
                                  key: topIndex + "-windshield",
                                  attrs: {
                                    isSinglePartSearchModal:
                                      _vm.isSinglePartSearchModal,
                                    part: part,
                                    shopId: _vm.shopId,
                                    topIndex: topIndex + "-windshield",
                                    popupPartDetails: _vm.popupPartDetails,
                                    edi_locked: _vm.edi_locked,
                                    insurancefleetAutoAddAdhesive:
                                      _vm.insurancefleetAutoAddAdhesive,
                                    glassGraphics: _vm.glassGraphics,
                                    inventoryParts: _vm.inventoryParts,
                                    pricelevels: _vm.pricelevels,
                                    selectedPriceLevel: _vm.selectedPriceLevel,
                                    job: _vm.job,
                                    showJustInventory: _vm.showJustInventory,
                                    shopParts: _vm.shopParts,
                                    trends: _vm.trends,
                                    inventoryItems: _vm.inventoryItems,
                                    shop: _vm.shop
                                  },
                                  on: {
                                    "update:popupPartDetails": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    },
                                    "update:popup-part-details": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.activeParts["Side"] &&
                      _vm.activeParts["Side"].length > 0
                        ? [
                            _vm._m(3),
                            _c(
                              "div",
                              {
                                staticClass: "collapse partResultCollapseshow",
                                attrs: { id: "sideSearchResults" }
                              },
                              _vm._l(_vm.activeParts["Side"], function(
                                part,
                                topIndex
                              ) {
                                return _c("PartResult", {
                                  key: topIndex + "-side",
                                  attrs: {
                                    isSinglePartSearchModal:
                                      _vm.isSinglePartSearchModal,
                                    part: part,
                                    shopId: _vm.shopId,
                                    topIndex: topIndex + "-side",
                                    popupPartDetails: _vm.popupPartDetails,
                                    edi_locked: _vm.edi_locked,
                                    insurancefleetAutoAddAdhesive:
                                      _vm.insurancefleetAutoAddAdhesive,
                                    glassGraphics: _vm.glassGraphics,
                                    inventoryParts: _vm.inventoryParts,
                                    pricelevels: _vm.pricelevels,
                                    selectedPriceLevel: _vm.selectedPriceLevel,
                                    job: _vm.job,
                                    showJustInventory: _vm.showJustInventory,
                                    shopParts: _vm.shopParts,
                                    trends: _vm.trends,
                                    inventoryItems: _vm.inventoryItems,
                                    shop: _vm.shop
                                  },
                                  on: {
                                    "update:popupPartDetails": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    },
                                    "update:popup-part-details": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.activeParts["Door"] &&
                      _vm.activeParts["Door"].length > 0
                        ? [
                            _vm._m(4),
                            _c(
                              "div",
                              {
                                staticClass: "collapse partResultCollapse show",
                                attrs: { id: "doorSearchResults" }
                              },
                              _vm._l(_vm.activeParts["Door"], function(
                                part,
                                topIndex
                              ) {
                                return _c("PartResult", {
                                  key: topIndex + "-door",
                                  attrs: {
                                    isSinglePartSearchModal:
                                      _vm.isSinglePartSearchModal,
                                    part: part,
                                    shopId: _vm.shopId,
                                    topIndex: topIndex + "-door",
                                    popupPartDetails: _vm.popupPartDetails,
                                    edi_locked: _vm.edi_locked,
                                    insurancefleetAutoAddAdhesive:
                                      _vm.insurancefleetAutoAddAdhesive,
                                    glassGraphics: _vm.glassGraphics,
                                    inventoryParts: _vm.inventoryParts,
                                    pricelevels: _vm.pricelevels,
                                    selectedPriceLevel: _vm.selectedPriceLevel,
                                    job: _vm.job,
                                    showJustInventory: _vm.showJustInventory,
                                    shopParts: _vm.shopParts,
                                    trends: _vm.trends,
                                    inventoryItems: _vm.inventoryItems,
                                    shop: _vm.shop
                                  },
                                  on: {
                                    "update:popupPartDetails": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    },
                                    "update:popup-part-details": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.activeParts["Roof"] &&
                      _vm.activeParts["Roof"].length > 0
                        ? [
                            _vm._m(5),
                            _c(
                              "div",
                              {
                                staticClass: "collapse partResultCollapse show",
                                attrs: { id: "roofSearchResults" }
                              },
                              _vm._l(_vm.activeParts["Roof"], function(
                                part,
                                topIndex
                              ) {
                                return _c("PartResult", {
                                  key: topIndex + "-roof",
                                  attrs: {
                                    isSinglePartSearchModal:
                                      _vm.isSinglePartSearchModal,
                                    part: part,
                                    shopId: _vm.shopId,
                                    topIndex: topIndex + "-roof",
                                    popupPartDetails: _vm.popupPartDetails,
                                    edi_locked: _vm.edi_locked,
                                    insurancefleetAutoAddAdhesive:
                                      _vm.insurancefleetAutoAddAdhesive,
                                    glassGraphics: _vm.glassGraphics,
                                    inventoryParts: _vm.inventoryParts,
                                    pricelevels: _vm.pricelevels,
                                    selectedPriceLevel: _vm.selectedPriceLevel,
                                    job: _vm.job,
                                    showJustInventory: _vm.showJustInventory,
                                    shopParts: _vm.shopParts,
                                    trends: _vm.trends,
                                    inventoryItems: _vm.inventoryItems,
                                    shop: _vm.shop
                                  },
                                  on: {
                                    "update:popupPartDetails": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    },
                                    "update:popup-part-details": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.activeParts["Vent"] &&
                      _vm.activeParts["Vent"].length > 0
                        ? [
                            _vm._m(6),
                            _c(
                              "div",
                              {
                                staticClass: "collapse partResultCollapse show",
                                attrs: { id: "ventSearchResults" }
                              },
                              _vm._l(_vm.activeParts["Vent"], function(
                                part,
                                topIndex
                              ) {
                                return _c("PartResult", {
                                  key: topIndex + "-vent",
                                  attrs: {
                                    isSinglePartSearchModal:
                                      _vm.isSinglePartSearchModal,
                                    part: part,
                                    shopId: _vm.shopId,
                                    topIndex: topIndex + "-vent",
                                    popupPartDetails: _vm.popupPartDetails,
                                    edi_locked: _vm.edi_locked,
                                    insurancefleetAutoAddAdhesive:
                                      _vm.insurancefleetAutoAddAdhesive,
                                    glassGraphics: _vm.glassGraphics,
                                    inventoryParts: _vm.inventoryParts,
                                    pricelevels: _vm.pricelevels,
                                    selectedPriceLevel: _vm.selectedPriceLevel,
                                    job: _vm.job,
                                    showJustInventory: _vm.showJustInventory,
                                    shopParts: _vm.shopParts,
                                    trends: _vm.trends,
                                    inventoryItems: _vm.inventoryItems,
                                    shop: _vm.shop
                                  },
                                  on: {
                                    "update:popupPartDetails": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    },
                                    "update:popup-part-details": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.activeParts["Quarter"] &&
                      _vm.activeParts["Quarter"].length > 0
                        ? [
                            _vm._m(7),
                            _c(
                              "div",
                              {
                                staticClass: "collapse partResultCollapse show",
                                attrs: { id: "quarterSearchResults" }
                              },
                              _vm._l(_vm.activeParts["Quarter"], function(
                                part,
                                topIndex
                              ) {
                                return _c("PartResult", {
                                  key: topIndex + "-quarter",
                                  attrs: {
                                    isSinglePartSearchModal:
                                      _vm.isSinglePartSearchModal,
                                    part: part,
                                    shopId: _vm.shopId,
                                    topIndex: topIndex + "-quarter",
                                    popupPartDetails: _vm.popupPartDetails,
                                    edi_locked: _vm.edi_locked,
                                    insurancefleetAutoAddAdhesive:
                                      _vm.insurancefleetAutoAddAdhesive,
                                    glassGraphics: _vm.glassGraphics,
                                    inventoryParts: _vm.inventoryParts,
                                    pricelevels: _vm.pricelevels,
                                    selectedPriceLevel: _vm.selectedPriceLevel,
                                    job: _vm.job,
                                    showJustInventory: _vm.showJustInventory,
                                    shopParts: _vm.shopParts,
                                    trends: _vm.trends,
                                    inventoryItems: _vm.inventoryItems,
                                    shop: _vm.shop
                                  },
                                  on: {
                                    "update:popupPartDetails": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    },
                                    "update:popup-part-details": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.activeParts["Back"] &&
                      _vm.activeParts["Back"].length > 0
                        ? [
                            _vm._m(8),
                            _c(
                              "div",
                              {
                                staticClass: "collapse partResultCollapse show",
                                attrs: { id: "backSearchResults" }
                              },
                              _vm._l(_vm.activeParts["Back"], function(
                                part,
                                topIndex
                              ) {
                                return _c("PartResult", {
                                  key: topIndex + "-back",
                                  attrs: {
                                    isSinglePartSearchModal:
                                      _vm.isSinglePartSearchModal,
                                    part: part,
                                    shopId: _vm.shopId,
                                    topIndex: topIndex + "-back",
                                    popupPartDetails: _vm.popupPartDetails,
                                    edi_locked: _vm.edi_locked,
                                    insurancefleetAutoAddAdhesive:
                                      _vm.insurancefleetAutoAddAdhesive,
                                    glassGraphics: _vm.glassGraphics,
                                    inventoryParts: _vm.inventoryParts,
                                    pricelevels: _vm.pricelevels,
                                    selectedPriceLevel: _vm.selectedPriceLevel,
                                    job: _vm.job,
                                    showJustInventory: _vm.showJustInventory,
                                    shopParts: _vm.shopParts,
                                    trends: _vm.trends,
                                    inventoryItems: _vm.inventoryItems,
                                    shop: _vm.shop
                                  },
                                  on: {
                                    "update:popupPartDetails": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    },
                                    "update:popup-part-details": function(
                                      $event
                                    ) {
                                      _vm.popupPartDetails = $event
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.showJustInventory
          ? _c(
              "div",
              {
                ref: "inventoryResultsArea",
                staticClass: "scrollable-wrap",
                attrs: { id: "searchResultsArea" }
              },
              [
                _vm._l(_vm.inventoryInStock, function(part, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "cell-sub-header cell-search-header",
                      attrs: {
                        "data-toggle": "collapse",
                        "data-parent": "#searchResultsArea"
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-lg-4" }, [
                          _c("h5", { staticStyle: { color: "white" } }, [
                            _vm._v(_vm._s(part.part_number))
                          ])
                        ]),
                        _c("div", { staticClass: "col-lg-2" }, [
                          _vm._v(" Quantity: ")
                        ]),
                        _c("div", { staticClass: "col-lg-2" }, [
                          _c("p", [_vm._v(_vm._s(part.quantity))])
                        ]),
                        _c("div", { staticClass: "col-lg-2" }, [
                          _vm._v(" Avg Cost: ")
                        ]),
                        _c("div", { staticClass: "col-lg-2" }, [
                          _c("p", [_vm._v("$" + _vm._s(part.avg_cost))])
                        ])
                      ])
                    ]
                  )
                })
              ],
              2
            )
          : _vm._e()
      ]),
      !_vm.isSinglePartSearchModal
        ? _c("NagsDetailsDialog", {
            attrs: {
              shopId: _vm.shopId,
              job: _vm.job,
              popupPartDetails: _vm.popupPartDetails,
              glassGraphics: _vm.glassGraphics,
              partInventory: _vm.partInventory,
              inventoryDetails: _vm.popupPartInventoryDetails
            },
            on: {
              "update:partInventory": function($event) {
                _vm.partInventory = $event
              },
              "update:part-inventory": function($event) {
                _vm.partInventory = $event
              }
            }
          })
        : _vm._e(),
      _c("OrderingStatusDialog", {
        attrs: {
          visible: _vm.orderingStatusDialogVisible,
          mygrant: _vm.shop ? _vm.shop.mygrant_account_id : null,
          pgw: _vm.vendorCredentials.filter(function(i) {
            return i.vendor === "pgw"
          }),
          igc: _vm.shop ? _vm.shop.igc_account_id : null,
          pilkington: _vm.shop ? _vm.shop.pilkington_username : null,
          american: _vm.vendorCredentials.filter(function(i) {
            return i.vendor === "american"
          })
        },
        on: {
          close: function($event) {
            _vm.orderingStatusDialogVisible = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 nags-update" }, [
        _c("span", [_vm._v("Last updated: January 11th, 2021")]),
        _c("span", { staticClass: "float-right" }, [
          _vm._v("Next Update: May 10th, 2021")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cell-sub-header cell-search-header",
        attrs: {
          "data-toggle": "collapse",
          "data-target": "#partitionSearchResults",
          "data-parent": "#searchResultsArea"
        }
      },
      [_c("h5", [_vm._v("Partition")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cell-sub-header cell-search-header",
        attrs: {
          "data-toggle": "collapse",
          "data-target": "#windshieldSearchResults",
          "data-parent": "#searchResultsArea"
        }
      },
      [_c("h5", [_vm._v("Windshield")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cell-sub-header cell-search-header",
        attrs: {
          "data-toggle": "collapse",
          "data-target": "#sideSearchResults",
          "data-parent": "#searchResultsArea"
        }
      },
      [_c("h5", [_vm._v("Side")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cell-sub-header cell-search-header",
        attrs: {
          "data-toggle": "collapse",
          "data-target": "#doorSearchResults",
          "data-parent": "#searchResultsArea"
        }
      },
      [_c("h5", [_vm._v("Door")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cell-sub-header cell-search-header",
        attrs: {
          "data-toggle": "collapse",
          "data-target": "#roofSearchResults",
          "data-parent": "#searchResultsArea"
        }
      },
      [_c("h5", [_vm._v("Roof")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cell-sub-header cell-search-header",
        attrs: {
          "data-toggle": "collapse",
          "data-target": "#ventSearchResults",
          "data-parent": "#searchResultsArea"
        }
      },
      [_c("h5", [_vm._v("Vent")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cell-sub-header cell-search-header",
        attrs: {
          "data-toggle": "collapse",
          "data-target": "#quarterSearchResults",
          "data-parent": "#searchResultsArea"
        }
      },
      [_c("h5", [_vm._v("Quarter")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cell-sub-header cell-search-header",
        attrs: {
          "data-toggle": "collapse",
          "data-target": "#backSearchResults",
          "data-parent": "#searchResultsArea"
        }
      },
      [_c("h5", [_vm._v("Back")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }