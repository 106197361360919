<template>
  <el-table
    stripe
    v-loading="loading"
    :data="data"
    @sort-change='sortChange'
    class='rejected-table'
    v-infinite-scroll="handleScroll"
    :infinite-scroll-disabled="!getMoreData"
    :infinite-scroll-distance="0">
    <el-table-column
      prop="id"
      label="Job Id"
      sortable="custom"
      class-name='job-id-column'>
      <template slot="header" slot-scope="scope">
        <div @click.stop>
          <el-input v-model='idFilter' placeholder='Invoice #'/>
        </div>
      </template>
      <template slot-scope="scope">
        <div>
          <a :href="'/jobs/' + scope.row.id" target='_blank'>{{ scope.row.id }}</a>
        </div>
        <gb-tag style='display: inline-block;' :hex="statusObject(scope.row.job_status).color">{{statusObject(scope.row.job_status).label}}</gb-tag>
      </template>
    </el-table-column>
    <el-table-column
      prop="referral"
      label="Referral"
      sortable="custom"
      >
      <template slot="header" slot-scope="scope">
        <div @click.stop>
          <el-input v-model='referralFilter' placeholder='Referral #'/>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="customer_name"
      label="Customer"
      sortable="custom"
      >
      <template slot="header" slot-scope="scope">
        <div @click.stop>
          <el-input v-model='customerNameFilter' placeholder='Customer'/>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="amount"
      label="Amount"
      sortable="custom"
      >
      <template slot="header" slot-scope="scope">
        <div @click.stop>
          <el-input v-model='amountFilter' placeholder='Amount'/>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="trading_partner"
      label="Trading Partner"
      sortable="custom"
      >
      <template slot="header" slot-scope="scope">
        <div @click.stop>
          <el-input v-model='tradingPartnerFilter' placeholder='Trading Partner'/>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop='tags'
      label="Tags"
    >
      <template slot-scope='scope'>
        <el-dropdown class='testing' @command='addTag'>
          <a style="margin-left:5px;" href="javascript:void(0)" class="rejectedDialogActionButton-sm label label-gray label-sm">
            Add +
          </a>
          <el-dropdown-menu class='rejected-dropdown-menu' slot="dropdown">
            <tag-dropdown-item v-for='tag in shopTags' :key='tag.id' :tag='tag' :jobId='scope.row.id' :jobtags='scope.row.tags'/>
          </el-dropdown-menu>
        </el-dropdown>
        <div v-if="scope.row.tags">
          <span v-for="(tag, index) in scope.row.tags" :key="index"
            class="tag-sm" v-bind:style="{ 'color': blackOrWhite(tag.color), 'background': tag.color }">
            {{tag.text}} <span style='cursor: pointer;' @click='deleteTag({job_id: scope.row.id, job_tag_id: tag.id})' >X</span>
          </span>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="Status Details"
    >
    <template slot-scope="scope">
      <a style="margin-left:5px;" href="javascript:void(0)" class="rejectedDialogActionButton-sm label label-gray label-sm" @click='openEdiData(scope.row)'>
        Show Details
      </a>
    </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import { jobStatusConfig } from '@/constants/job-details.config'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import TagDropdownItem from './TagDropdownItem'

export default {
  components: {TagDropdownItem},
  data () {
    return {
      timeout: null
    }
  },
  computed: {
    idFilter: sync('rejectedInvoices/table@filters.id'),
    referralFilter: sync('rejectedInvoices/table@filters.referral'),
    customerNameFilter: sync('rejectedInvoices/table@filters.customerName'),
    amountFilter: sync('rejectedInvoices/table@filters.amount'),
    tradingPartnerFilter: sync('rejectedInvoices/table@filters.tradingPartner'),
    filterObject: get('rejectedInvoices/table@filters'),
    data: get('rejectedInvoices/data'),
    loading: get('rejectedInvoices/loading'),
    totalRows: get('rejectedInvoices/totalRows'),
    shopTags: get('rejectedInvoices/shopTags'),
    getMoreData () {
      return this.data?.length < this.totalRows
    },
  },
  methods: {
    getData: call('rejectedInvoices/getData'),
    getDataNextSet: call('rejectedInvoices/getDataNextSet'),
    updatedFilters: call('rejectedInvoices/updatedFilters'),
    addTag: call('rejectedInvoices/addJobTag'),
    deleteTag: call('rejectedInvoices/deleteJobTag'), 
    openEdiData: call('rejectedInvoices/openEdiData'),
    changeSort: call('rejectedInvoices/changeSort'),
    sortChange (stuff) {
      this.changeSort(stuff)
    },
    handleScroll () {
      this.getDataNextSet()
    },
    statusObject (status) {
      return jobStatusConfig[status] || ''
    }
  },
  watch: {
    filterObject: {
      handler: function () {
        this.updatedFilters()
      },
      deep: true
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style lang='scss' scoped>
@import './RejectedInvoices.scss';
</style>