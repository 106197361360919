var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-row", { staticClass: "dialog-header-text" }, [
        _vm._v(" Orginal Purchase Order: ")
      ]),
      _c("br"),
      _c(
        "el-row",
        { staticClass: "dialog-body-text" },
        [
          _c("el-col", { attrs: { span: 10 } }, [_vm._v(" Id: ")]),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(" " + _vm._s(_vm.purchaseOrderInfo.internal_id) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "dialog-body-text" },
        [
          _c("el-col", { attrs: { span: 10 } }, [_vm._v(" Cost: ")]),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(" $" + _vm._s(_vm.partData.cost) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "dialog-body-text" },
        [
          _c("el-col", { attrs: { span: 10 } }, [_vm._v(" Vendor: ")]),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(
              " " + _vm._s(_vm.vendorInfo ? _vm.vendorInfo.name : null) + " "
            )
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "dialog-body-text" },
        [
          _c("el-col", { attrs: { span: 10 } }, [
            _vm._v(" Available Inventory: ")
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(" " + _vm._s(_vm.inventoryData.qty) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "dialog-body-text" },
        [
          _c("el-col", { attrs: { span: 10 } }, [_vm._v(" PO Date: ")]),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(" " + _vm._s(_vm.poDate) + " ")
          ])
        ],
        1
      ),
      _c("br"),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-document", type: "primary" },
              on: { click: _vm.poButtonPressed }
            },
            [_vm._v("View Purchase Order")]
          )
        ],
        1
      ),
      _c("el-divider"),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }