<template>
  <div>
    <clover-select
      :value.sync="deviceToAdd"
      :options="options"
    />
    <el-button
      size="mini"
      type="primary"
      plain
      icon="el-icon-plus"
      :disabled="!deviceToAdd"
      @click="$emit('add', deviceToAdd)"
    >
      add
    </el-button>
    <el-button
      size="mini"
      type="danger"
      icon="el-icon-delete"
      @click="$emit('cancel')"
    >
      cancel
    </el-button>
  </div>
</template>

<script>
import CloverSelect from '@/components/Clover/CloverSelect.vue'

export default {
  props: ['options'],
  data () {
    return {
      deviceToAdd: null
    }
  },
  components: {
    CloverSelect
  }
}
</script>

<style>

</style>