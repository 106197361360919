<template>
  <div>
    <div>
      <Upload />

      <DocumentLine
        v-for="i in data.documents"
        :key="i.id"
        :document="i"
        @preview="preview(i)"
        @createAvatar="imageCropDialog = i.url"
      />
    </div>

    <ImageCropDialog
      v-model="imageCropDialog"
      @cropped="setAvatar"
    />

    <FilePreviewDialog
      v-model="previewDialog.visible"
      :doc="previewDialog.file"
      :dateFunc="formatPreviewDate"
      :deleteFunc="deleteDocumentHandler"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import Upload from './Upload.vue'
import DocumentLine from './DocumentLine.vue'
import ImageCropDialog from '@/components/modals/ImageCropDialog.vue'
import FilePreviewDialog from '@/components/modals/FilePreviewDialog/Index.vue'

export default {
  components: { Upload, DocumentLine, ImageCropDialog, FilePreviewDialog },

  data() {
    return {
      previewDialog: {
        visible: false,
        file: null
      },
      imageCropDialog: null
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data'])
  },

  methods: {
    ...mapActions(useVendorStore, ['deleteDocument', 'updateAvatar']),

    formatPreviewDate(date) {
      return moment(date).format('lll')
    },

    preview(file) {
      this.previewDialog.file = {
        id: file.id,
        document_name: file.document_name,
        url: file.url,
        created: file.created_at,
        document_type: file.document_tag_code,
        document_type_name: file.document_tag_name,
        color: file.document_tag_color
      }
      this.previewDialog.visible = true
    },

    async deleteDocumentHandler({ id }) {
      try {
        await this.deleteDocument(id)
        this.$message.success('Document deleted successfully')
      } catch (err) {
        this.$message.error(err)
      }
    },

    async setAvatar(img) {
      try {
        await this.updateAvatar(img)
        this.$message.success('Avatar updated')
      } catch (err) {
        this.$message.error(err)
      }
    }
  }
}
</script>
