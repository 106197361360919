<template>
  <div>
    <b-modal
      lazy
      title="Job Not Found"
      header-bg-variant='primary'
      header-text-variant='light'
      size="lg"
      id="failedJobNavModal">
      <div fluid id="failedJobNavBody">
        <p>{{message}}</p>
      </div>
      <div slot="modal-footer" class="w-100">
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="closeModal()">
          Ok
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default {
  props: ['message'],
  methods: {
    closeModal: function () {
      this.$root.$emit('bv::hide::modal', 'failedJobNavModal')
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped>
#failedJobNavBody {
  color: black;
}
</style>
