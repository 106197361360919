var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        _vm._l(_vm.tabs, function(item) {
          return _c(
            "el-tab-pane",
            { key: item.key, attrs: { name: item.key, lazy: "" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(" " + _vm._s(item.label) + " "),
                _vm.errors[item.key]
                  ? _c("i", {
                      staticClass: "el-icon-warning-outline error-tab-icon"
                    })
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "tab-container" },
                [
                  _c(item.component, {
                    ref: "tabs",
                    refInFor: true,
                    tag: "component",
                    model: {
                      value: _vm.data,
                      callback: function($$v) {
                        _vm.data = $$v
                      },
                      expression: "data"
                    }
                  })
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }