import gql from 'graphql-tag'
import { ADDRESS_FRAGMENT } from '../fragments/address.fragment'
import { PART_FRAGMENT } from '../fragments/part.fragment'
import { RATES_FRAGMENT } from '../fragments/rates.fragment'
import { TAG_FRAGMENT } from '../fragments/tag.fragment'
import { TAX_FRAGMENT } from '../fragments/tax.fragment'
import { TIMESPAN_FRAGMENT } from '../fragments/timespan.fragment'

export const SHOP_PIECES_QUERIES = {
  timespan: gql`
    query shopTimespans($shop_id: ID!) {
      shopTimespans(shop_id: $shop_id) {
        ...InstallTimeSpanFields
      }
    }
    ${TIMESPAN_FRAGMENT}
  `,
  tax: gql`
    query shopTaxes($shop_id: ID!, $disabled: Boolean) {
      shopTaxes(shop_id: $shop_id, disabled: $disabled) {
        ...TaxFields
      }
    }
    ${TAX_FRAGMENT}
  `,
  tag: gql`
    query shopTags($shop_id: ID!) {
      shopTags(shop_id: $shop_id) {
        ...TagFields
      }
    }
    ${TAG_FRAGMENT}
  `,
  pricelevels: gql`
    query shopPricelevels($shop_id: ID!) {
      shopPricelevels(shop_id: $shop_id) {
        id
        name
      }
    }
  `,
  parts: gql`
    query shopParts($shop_id: ID!) {
      shopParts(shop_id: $shop_id) {
        ...PartFields
      }
    }
    ${PART_FRAGMENT}
  `,
  rates: gql`
    query shopRates($shop_id: ID!) {
      shopRates(shop_id: $shop_id) {
        ...RatesFields
      }
    }
    ${RATES_FRAGMENT}
  `,
  install_locations: gql`
    query shopInstallLocations($shop_id: ID!) {
      shopInstallLocations(shop_id: $shop_id) {
        id
        name
        address_id
        status
        address {
          ...AddressFields
        }
      }
    }
    ${ADDRESS_FRAGMENT}
  `,
  bays: gql`
    query shopBays($shop_id: ID!) {
      shopBays(shop_id: $shop_id) {
        id
        name
        color
        status
      }
    }
  `,
  causes_of_loss: gql`
    query shopCausesOfLoss($shop_id: ID!) {
      shopCausesOfLoss(shop_id: $shop_id) {
        id
        name
      }
    }
  `
}
