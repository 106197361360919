var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-pb-4" },
    [
      _c(
        "el-table",
        {
          staticClass: "t-w-full",
          attrs: {
            data: _vm.parts,
            size: "small",
            "show-header": false,
            "empty-text": "No parts found"
          }
        },
        [
          _c("el-table-column", {
            attrs: { width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "t-text-indigo-800" }, [
                      _c("span", [_vm._v(_vm._s(scope.row.Sku))])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "locationName", width: "150" }
          }),
          _c("el-table-column", { attrs: { width: "100" } }),
          _c("el-table-column"),
          _c("el-table-column", {
            attrs: { prop: "QuantityAvailable", width: "80" }
          }),
          _c("el-table-column", {
            attrs: { width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" $" + _vm._s(scope.row.UnitPrice) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.QuantityAvailable > 0
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "t-cursor-pointer t-text-purple-600 t-px-2 t-pt-1 t-pb-0\n                 t-border t-border-solid t-border-purple-400 t-rounded-full\n                 t-bg-white hover:t-text-white hover:t-border-purple-600\n                 hover:t-bg-purple-600",
                            on: {
                              click: function($event) {
                                return _vm.add(scope.row)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-shopping-cart-2 t-text-base"
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("add-part-to-cart", {
        attrs: {
          visible: _vm.addPartToCartOpen,
          part: _vm.selectedPart,
          vendor: _vm.vendor,
          shopId: _vm.shopId,
          jobId: _vm.jobId,
          status: _vm.status,
          warehouse: _vm.warehouse,
          username: _vm.username
        },
        on: {
          "refresh-data": function($event) {
            return _vm.$emit("refresh-data")
          },
          close: function($event) {
            _vm.addPartToCartOpen = false
          },
          addAndLinkPart: function($event) {
            return _vm.addAndLinkPart($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }