var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["vc-hue", _vm.directionClass] }, [
    _c(
      "div",
      {
        ref: "container",
        staticClass: "vc-hue-container",
        on: {
          mousedown: _vm.handleMouseDown,
          touchmove: _vm.handleChange,
          touchstart: _vm.handleChange
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "vc-hue-pointer",
            style: { top: _vm.pointerTop, left: _vm.pointerLeft }
          },
          [
            _c("div", {
              staticClass: "vc-hue-picker",
              attrs: { role: "huePicker" }
            })
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }