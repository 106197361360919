import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import User from '@/scripts/objects/user'
import { GetCurrentDateTimeString } from '@/scripts/helpers/helpers.js'
import { format } from 'date-fns'

export default class Note extends ObjectTracker {
  id = -1
  created = -1
  modified = -1
  note_text = ''
  note_sequence = -1
  job_id = -1
  user_id = -1
  note_date = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  getUserForNote (users) {
    for (var i = 0; i < users.length; i++) {
      if (users[i].id === this.user_id) {
        return users[i]
      }
    }
    return null
  }

  getUserNameForNote (users) {
    var user = this.getUserForNote(users)
    return user ? user.username : 'Missing User'
  }

  getUserStyleForNote (users) {
    var user = this.getUserForNote(users)
    var style = {
      color: user && user.color && user.color.length > 0 ? user.color : 'black'
    }
    return style
  }

  static createNewNote (user, jobId, noteText, notes) {
    var note = new Note()
    note.note_text = noteText
    var position = 0
    
    
    if (notes.length > 0) {
      var largestNote_Sequence = 0
      for (var i = 0; i < notes.length; i++) {
        if (notes[i].note_sequence > largestNote_Sequence) {
          largestNote_Sequence = notes[i].note_sequence
        }
      }
      position = largestNote_Sequence + 1
    }

    
    note.note_sequence = position
    note.job_id = jobId
    note.user_id = user.id
    note.note_date = format(new Date(), 'YYYY-DD-MM hh:mm A')
    return note
  }

  static getAll (shopId, jobId, callbackFunction, errorCallbackFunction = null) {
    
    Api({ url: `/api/shop/${shopId}/job/${jobId}/note` }).then(res => {
      
      var results = {}
      results['users'] = []
      results['notes'] = []
      if (res.data) {
        if (res.data.users) {
          res.data.users.forEach(function (val) {
            results['users'].push(new User(val))
          })
        }
        if (res.data.notes) {
          res.data.notes.forEach(function (val) {
            results['notes'].push(new Note(val))
          })
        }
      }
      
      callbackFunction(results)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  createUpdateNote (shopId, job_id, callbackFunction, errorCallbackFunction = null) {
    
    if (!this.id || this.id <= 0) {
      this.job_id = job_id
      
      this.id = -1
    }
    
    return Api.post(`/api/shop/${shopId}/job/${this.job_id}/note/${this.id}`, this).then(res => {
      
      
      callbackFunction(new Note(res.data))
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  deleteNote (shopId, successCallbackFunction, errorCallbackFunction = null) {
    
    
    if (!this.id || this.id <= 0) {
      successCallbackFunction()
    }
    return Api.delete(`/api/shop/${shopId}/job/${this.job_id}/note/${this.id}`, this).then(res => {
      
      
      successCallbackFunction()
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
