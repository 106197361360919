<template>
  <el-dialog
    :visible="visible"
    :title="(currentRates && currentRates.name) ? currentRates.name : 'New Nags Pricing'"
    @close="visible = false"
    @open="setCurrentRate()"
    width="550px"
    class="shop-rates-dialog"
    :modal-append-to-body="true"
    append-to-body>
    <div class="shop-rates">
      <el-row :gutter="20">
        <el-col :span="16">
          <input-with-label
            label="Name"
            v-model="currentRates.name"
            size="medium"
            :error="showNameError ? 'Name is required' : null"
          />
        </el-col>
        <el-col :span="8">
          <el-switch
            active-text="Shop Default"
            :value="current_rate_is_shop_default"
            @input="current_rate_is_shop_default = +$event"
          />
        </el-col>
      </el-row>
      <rates-form v-model="currentRates" />
    </div>
    <div slot="footer">
      <el-row>
        <!-- <el-col :span="4" class="shop-rates-dialog-footer-button-holder-left">
          <el-button v-if="!originalRateIsShopDefault && currentRates && currentRates.id" class="red-button" @click="deleteShopRate">Delete</el-button>
        </el-col> -->
        <el-col id="right" class="shop-rates-dialog-footer-button-holder-right">
          <el-button @click="visible = false">Cancel</el-button>
          <el-button
            @click="save(false)"
            :loading="saving"
            :disabled="!currentRates.name">
            Save
          </el-button>
          <el-button
            v-if="!autoApply"
            @click="save(true)"
            type="primary"
            :loading="savingAndApplying"
            :disabled="!currentRates.name">
            Save and apply
          </el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import RatesForm from '@/components/Helpers/RatesForm'
import * as R from 'ramda'

import { SHOP_PIECES_QUERIES } from '@/apollo/queries/shop-pieces.query'
import {
  CREATE_UPDATE_SHOP_RATES_MUTATION,
  DELETE_SHOP_RATES_MUTATION
} from '@/apollo/mutation/rates.mutation'

export default {
  components: { RatesForm },
  props: ['value', 'rate', 'shopRates', 'shop_id', 'applyRateMethod', 'autoApply', 'defaultName'],
  data() {
    return {
      currentRates: {},
      saving: false,
      savingAndApplying: false,
      updateShopDefault: false
    }
  },
  computed: {
    showNameError() {
      return !this.currentRates?.name || this.currentRates?.name?.length <= 0
    },
    visible: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    current_rate_is_shop_default: {
      get() {
        var isDefaultRate = this.getIsShopDefaultRate(this.currentRates.id)
        if (this.updateShopDefault) {
          isDefaultRate = !isDefaultRate
        }
        return isDefaultRate
      },
      set(v) {
        this.updateShopDefault = !this.updateShopDefault
      }
    },
    originalRateIsShopDefault() {
      return this.getIsShopDefaultRate(this.rate?.id)
    }
  },
  methods: {
    getIsShopDefaultRate(rate_id) {
      var isDefaultRate = false
      for (var i = 0; i < this.$store.state.shops.length; i++) {
        if (this.$store.state.shops[i].id === this.shop_id) {
          isDefaultRate = rate_id === this.$store.state.shops[i].default_rates
          i = this.$store.state.shops.length
        }
      }
      return isDefaultRate
    },
    async createUpdateShopRates(rates, apply) {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_UPDATE_SHOP_RATES_MUTATION,
        variables: {
          shop_id: this.shop_id,
          rates
        },
        update: (_, { data }) => {
          const newRates = data.createUpdateShopRates
          if (!rates.id) {
            _.writeQuery({
              query: SHOP_PIECES_QUERIES.rates,
              variables: {
                shop_id: this.shop_id
              },
              data: {
                shopRates: R.append(newRates)(this.shopRates)
              }
            })
          }
          if (apply || this.autoApply) {
            this.applyRateMethod(newRates)
            this.$root.$emit('check-rate-change', newRates)
          }
        }
      })

      if (this.updateShopDefault) {
        var isCurrentShopDefaultRate = this.getIsShopDefaultRate(this.currentRates.id)
        var rateIdToSet = (isCurrentShopDefaultRate ? -1 : data.createUpdateShopRates.id)
        await this.$unum.job().post(
            `/shop/${this.shop_id}/default_rate/${rateIdToSet}`)

        const tempShops = R.clone(this.$store.state.shops)
        for (var i = 0; i < tempShops.length; i++) {
          if (tempShops[i].id === this.shop_id) {
            tempShops[i].default_rates = (rateIdToSet === -1) ? null : rateIdToSet
            i = tempShops.length
          }
        }

        this.$store.dispatch('setShops', tempShops)
      }

      this.$message({
        type: 'success',
        message: rates.id ? 'Rates Created' : 'Rates Updated'
      })
    },
    async deleteShopRates(id) {
      const { cache } = this.$apollo.getClient()
      cache.modify({
        fields: {
          shopRates: v => {
            return R.pipe(R.reject(R.propEq('__ref', `Rates:${id}`)))(v)
          }
        }
      })
      await this.$apollo.mutate({
        mutation: DELETE_SHOP_RATES_MUTATION,
        variables: {
          id,
          shop_id: this.shop_id
        }
      })
      this.$message({ type: 'success', message: 'Rates Deleted' })
    },
    deleteShopRate() {
      if (this.currentRates && this.currentRates.id && !this.saving && !this.savingAndApplying) {
        if (confirm('Are you sure you want to delete this rate?')) {
          this.deleteShopRates(this.currentRates.id)
          this.visible = false
          this.applyRateMethod(null)
        }
      }
    },
    async save(applyToJob) {
      const self = this

      if (applyToJob) {
        self.savingAndApplying = true
      } else {
        self.saving = true
      }
      self.timeout = setTimeout(async function () {
        await self.createUpdateShopRates(R.omit(['nameToSetTo'], self.currentRates), applyToJob)
        self.saving = false
        self.savingAndApplying = false
        self.visible = false
        self.currentRates = {}
      }, 200)
    },
    setCurrentRate() {
      this.updateShopDefault = false
      if (this.rate && this.rate.id && this.rate.customConsumerRate) {
        this.currentRates = R.clone(this.rate)
        delete this.currentRates.customConsumerRate
        delete this.currentRates.id
        this.currentRates.name = this.rate.nameToSetTo
      } else {
        var defaultRate = {}
        if (this.defaultName) {
          defaultRate.name = this.defaultName
        }
        this.currentRates = R.clone(
          (this.rate && this.rate.id) ? R.find(R.propEq('id', this.rate.id))(this.shopRates) : defaultRate
        )
      }
    }
  },
  watch: {
    rate(v) {
      this.setCurrentRate()
    }
  }
}
</script>

<style lang="scss" scoped>
.red-button {
  background-color: red;
  color:white;
}
</style>

<style lang="scss" >
.shop-rates {
  padding-top: $padding-md;
}

.shop-rates-dialog .el-dialog__body {
  padding-top: 0px;
}

.shop-rates-dialog-footer-button-holder-left {
  text-align: left;
}
.shop-rates-dialog-footer-button-holder-right {
  text-align: right;
}
</style>
