var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "unsavedDialog",
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
        id: "unsavedDialog",
        size: "lg",
        title: "Unsaved Changes Detected",
        "header-bg-variant": "primary",
        "header-text-variant": "light"
      }
    },
    [
      _c("div", { staticClass: "form-row" }, [
        _vm._v(
          " There are unsaved changes detected on this page. If you leave you may lose them. "
        )
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c(
            "a",
            {
              staticStyle: { "text-decoration": "underline" },
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.proceed }
            },
            [_vm._v("Leave Without Saving")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary float-right",
              attrs: { type: "button", size: "lg", variant: "primary" },
              on: { click: _vm.stay }
            },
            [_vm._v("Stay")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }