<template>
  <div class="consumer-notes">
    <input-with-label
      type="textarea"
      :options="{ rows: 8 }"
      label="Notes"
      v-model="notes"
    />
    <input-with-label
      class="auto-popup"
      type="textarea"
      :options="{ rows: 3 }"
      label="Auto popup note"
      v-model="autoPopupMessage"
      :disabled="!autoPopup"
    />
    <gb-checkbox v-model="autoPopup">Auto popup</gb-checkbox>
  </div>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    notes: {
      get() {
        return this.value?.notes
      },
      set(notes) {
        this.$emit('input', { ...this.value, notes })
      }
    },
    autoPopupMessage: {
      get() {
        return this.value?.auto_popup_message
      },
      set(auto_popup_message) {
        this.$emit('input', { ...this.value, auto_popup_message })
      }
    },
    autoPopup: {
      get() {
        return !!this.value?.auto_popup
      },
      set(auto_popup) {
        this.$emit('input', { ...this.value, auto_popup: +auto_popup })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.consumer-notes {
  padding-top: $padding-xxs;
  .auto-popup {
    margin-top: $padding-md;
  }
}
</style>
