<template>
  <div
    v-loading="imagesLoaded !== totalImages"
    class="t-flex-grow t-flex t-justify-end t-overflow-y-hidden">
    <div
      v-if="chatIsOpen"
      ref="messages"
      @scroll="handleScroll"
      class="t-flex-grow t-overflow-y-auto t-py-2 t-space-y-2 t-flex
             t-flex-col"
    >
      <infinite-loading
        ref="infiniteLoader"
        class="t-pb-3"
        direction="top"
        spinner="spiral"
        @infinite="load"
      >
        <div slot="no-more">
          <div class="t-text-left t-mb-3 t-mt-8 t-px-4">
            <div class="t-text-xl t-font-medium t-mb-3 t-text-gray-800">
              <i
                v-if="activeChannel.friendlyName === 'direct'"
                class="el-icon-user"
              />
              <i v-else-if="activeChannel.isPrivate" class="el-icon-lock"></i>
              <span v-else>#</span>
              <span class="t-ml-1">{{ channelName }}</span>
            </div>
            <div class="t-text-base t-text-gray-700 t-break-normal">
              <div v-if="activeChannel.friendlyName === 'direct'">
                <div v-if="channelName === username">
                  <span class="t-font-medium">This is your space.</span> Draft
                  messages, list your to-dos, or keep links and files handy.
                </div>
                <div v-else>
                  This is the very beginning of your direct message history with
                  <span class="t-font-medium">{{ channelName }}</span>. Only the
                  two of you are in this conversation, and no one else can join it.
                </div>
              </div>
              <div v-else>
                User <span class="t-font-medium">
                  {{ activeChannel.createdBy }}
                </span>
                created this {{ activeChannel.isPrivate ? 'private' : 'public' }}
                channel on {{ channelCreationDate }}. This is the very beginning of the
                <span class="t-font-medium">
                  <i v-if="activeChannel.isPrivate" class="el-icon-lock"></i>
                  <span v-else>#</span>
                  <span class="t-ml-1">{{ activeChannel.uniqueName }}</span>
                </span>
                channel.
              </div>
            </div>
          </div>
        </div>
      </infinite-loading>
      <div
        v-for="(item, index) in messages"
        :key="item.sid"
      >
        <MessageDate
          v-if="!index"
          :date="formatMessageDate(item.state.timestamp)"
        />
        <MessageDate
          v-else-if="getMessageDate(index)"
          :date="getMessageDate(index)"
        />
        <Message
          :message="item"
          @scrollDown="scrollDownByPixels($event)"
        />
      </div>
      <div class="t-italic t-text-xs">
        <div
          :class="{ 't-hidden': typingMembers.length === 0 }"
          class="t-mt-1 t-pl-4"
        >
          <span>{{ typingMembers.join(', ') }}</span>
          <span class="t-ml-1">{{ typingMembers.length > 1 ? 'are' : 'is' }}</span>
          <span class="t-ml-1">typing</span>
        </div>
      </div>
    </div>
    <div
      v-if="scrollButtonIsVisible"
      @click="scrollDown"
      id="scroll-to-chat-bottom-button"
      class="t-absolute t-flex t-justify-center t-items-center t-text-blue-500
             t-text-lg t-cursor-pointer t-rounded-full t-border t-border-solid
             t-border-blue-500 t-w-10 t-h-10 t-bg-white hover:t-bg-blue-200
             t-shadow"
    >
      <i class="el-icon-arrow-down"></i>
    </div>
    <ViewImageDialog/>
  </div>
</template>

<script>
import moment from 'moment'
import Message from './Message'
import MessageDate from './MessageDate'
import ViewImageDialog from './ViewImageDialog'
import InfiniteLoading from 'vue-infinite-loading'
import { ChatService } from '@/services/ChatService'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  data: () => ({
    messages: [],
    paginator: null,
    imagesLoaded: 0,
    scrollButtonIsVisible: false
  }),
  computed: {
    chatIsOpen: function() {
      return this.$store.state.chat.isOpen
    },
    activeChannel: function() {
      return this.$store.state.chat.activeChannel
    },
    newMessage: function() {
      return this.$store.state.chat.newMessage
    },
    channelCreationDate: function() {
      return moment(this.activeChannel.dateCreated).format('MMMM Do, YYYY')
    },
    typingMembers: function() {
      return this.$store.getters[chatTypes.namespace + chatTypes.getters.getTypingMembers]
    },
    scrollBodyDown: function() {
      return this.$store.state.chat.scrollBodyDown
    },
    totalImages: function() {
      return this.messages.filter(i => {
        return i.media && i.media.contentType.split('/')[0] === 'image'
      }).length
    },
    username: function() {
      return this.$store.state.user.username
    },
    channelName: function() {
      if (this.activeChannel.friendlyName !== 'direct') {
        return this.activeChannel.uniqueName
      }
      if (this.activeChannel.uniqueName.split('-')[0] === this.username) {
        return this.activeChannel.uniqueName.split('-')[1]
      }
      return this.activeChannel.uniqueName.split('-')[0]
    }
  },
  watch: {
    chatIsOpen: function(value) {
      if (!value) {
        this.reset()
      }
    },
    activeChannel: function() {
      this.reset()
    },
    newMessage: function(message) {
      if (message) {
        if (message.channel.uniqueName === this.activeChannel.uniqueName) {
          this.messages.push(message)
          this.scrollDown()
        }
        this.unsetNewMessage()
      }
    },
    typingMembers: function() {
      if (
        this.$refs.messages.scrollHeight -
        this.$refs.messages.clientHeight -
        this.$refs.messages.scrollTop < 20
      ) {
        this.scrollDown()
      }
    },
    scrollBodyDown: function(value) {
      if (value) {
        this.scrollDown()
        this.$store.commit(
          chatTypes.namespace + chatTypes.mutations.setScrollBodyDown,
          false
        )
      }
    }
  },
  methods: {
    reset: function() {
      this.messages = []
      this.paginator = null
      this.imagesLoaded = 0
      this.scrollDown()
      this.$refs.infiniteLoader.stateChanger.reset()
    },
    load: async function ($state) {
      if (!this.paginator) {
        this.paginator = await ChatService.getMessages()
        if (
          !this.messages.length ||
          (this.messages.length && !this.paginator.items.find(i => i.sid === this.messages[0].sid))
        ) {
          this.messages.unshift(...this.paginator.items)
        }
        await $state.loaded()
      } else {
        if (this.paginator.hasPrevPage) {
          this.paginator = await this.paginator.prevPage()
          if (
            !this.messages.length ||
            (this.messages.length && !this.paginator.items.find(i => i.sid === this.messages[0].sid))
          ) {
            this.messages.unshift(...this.paginator.items)
          }
          $state.loaded()
        } else {
          $state.complete()
          this.scrollDown()
        }
      }
    },
    scrollDown: function() {
      this.$nextTick(() => {
        if (this.$refs.messages) {
          this.$refs.messages.firstChild.style = 'margin-top: auto;'
          this.$refs.messages.lastChild.scrollIntoView()
        }
      })
    },
    unsetNewMessage: function() {
      return this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.setNewMessage, null
      )
    },
    getMessageDate: function(messageIndex) {
      const previousMessageDate = this.formatMessageDate(this.messages[messageIndex - 1].state.timestamp)
      const lastMessageDate = this.formatMessageDate(this.messages[messageIndex].state.timestamp)
      return lastMessageDate === previousMessageDate ? null : lastMessageDate
    },
    formatMessageDate: function(timestamp) {
      const today = moment().startOf('day')
      if (moment(timestamp).isSame(today, 'd')) {
        return 'Today'
      }
      const yesterday = moment().subtract(1, 'days').startOf('day')
      if (moment(timestamp).isSame(yesterday, 'd')) {
        return 'Yesterday'
      }
      const currentYear = moment().format('YYYY')
      const messageYear = moment(timestamp).format('YYYY')
      let dateFormat = 'dddd, MMMM Do'
      if (messageYear !== currentYear) {
        dateFormat += ', YYYY'
      }
      return moment(timestamp).format(dateFormat)
    },
    scrollDownByPixels: function(pixels) {
      this.imagesLoaded++
      this.$refs.messages.scrollBy(0, pixels)
    },
    handleScroll: function(event) {
      this.scrollButtonIsVisible = event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop > 100
    }
  },
  components: {
    Message,
    MessageDate,
    InfiniteLoading,
    ViewImageDialog
  }
}
</script>

<style lang="scss" scoped>
#scroll-to-chat-bottom-button {
  bottom: 130px;
  right: 70px;
}
</style>
