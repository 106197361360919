var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "t-font-sans",
      attrs: {
        visible: _vm.visible,
        "append-to-body": "",
        "before-close": _vm.close,
        width: "500px"
      }
    },
    [
      _vm.part
        ? _c(
            "span",
            {
              staticClass: "t-text-lg t-text-gray-800",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _vm._v(" Add part "),
              _c("span", { staticClass: "t-font-medium t-text-purple-800" }, [
                _vm._v(" " + _vm._s(_vm.partName) + " ")
              ])
            ]
          )
        : _vm._e(),
      _vm.optionMessage
        ? _c("el-alert", {
            staticClass: "t-mb-5",
            attrs: { title: _vm.optionMessage, type: "error", closable: false }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "t-flex t-flex-col" },
        [
          _c(
            "el-radio",
            {
              staticClass: "t-normal-case t-mb-4 t-font-normal",
              attrs: { label: "toCart" },
              model: {
                value: _vm.option,
                callback: function($$v) {
                  _vm.option = $$v
                },
                expression: "option"
              }
            },
            [_vm._v(" Just add to cart ")]
          ),
          _vm.addToCartAndPartsList
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    disabled: _vm.status !== "draft",
                    content: "publish draft before sourcing parts",
                    placement: "left",
                    effect: "light"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      staticClass: "t-normal-case t-font-normal",
                      attrs: {
                        disabled: _vm.status === "draft",
                        label: "toCartAndToParts"
                      },
                      model: {
                        value: _vm.option,
                        callback: function($$v) {
                          _vm.option = $$v
                        },
                        expression: "option"
                      }
                    },
                    [_vm._v(" Add to cart and to parts list ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.partsLoading
            ? _c(
                "div",
                {
                  staticClass: "t-p-12 t-text-center t-text-3xl t-text-blue-600"
                },
                [_c("i", { staticClass: "el-icon-loading" })]
              )
            : _c("div", [
                _vm.jobParts.length
                  ? _c(
                      "div",
                      [
                        _c("el-divider"),
                        _c("p", { staticClass: "t-text-gray-600 t-italic" }, [
                          _vm._v(
                            " Or add to cart and connect to an item from parts list "
                          )
                        ]),
                        _vm._l(_vm.jobParts, function(p) {
                          return _c(
                            "el-radio",
                            {
                              key: p.id,
                              staticClass:
                                "t-border t-border-solid t-border-blue-300 t-rounded-md t-px-4\n                  t-py-3 t-flex t-items-center t-cursor-pointer t-normal-case\n                  t-font-normal hover:t-bg-blue-100 t-mb-2 t-w-full",
                              class: {
                                "t-bg-gray-100":
                                  p.american_order_item_id !== null ||
                                  p.glaxis_order_item_id !== null ||
                                  p.pilkington_order_item_id !== null ||
                                  p.nielsen_moller_order_item_id !== null,
                                "hover:t-bg-gray-200":
                                  p.american_order_item_id !== null ||
                                  p.glaxis_order_item_id !== null ||
                                  p.pilkington_order_item_id !== null ||
                                  p.nielsen_moller_order_item_id !== null,
                                "t-cursor-not-allowed":
                                  p.american_order_item_id !== null ||
                                  p.glaxis_order_item_id !== null ||
                                  p.pilkington_order_item_id !== null ||
                                  p.nielsen_moller_order_item_id !== null
                              },
                              attrs: {
                                label: p.id,
                                disabled:
                                  p.american_order_item_id !== null ||
                                  p.glaxis_order_item_id !== null ||
                                  p.pilkington_order_item_id !== null ||
                                  p.nielsen_moller_order_item_id !== null
                              },
                              model: {
                                value: _vm.option,
                                callback: function($$v) {
                                  _vm.option = $$v
                                },
                                expression: "option"
                              }
                            },
                            [
                              _c("div", { staticClass: "t-ml-1" }, [
                                _c(
                                  "div",
                                  { staticClass: "t-text-gray-700 t-mb-1" },
                                  [_vm._v(_vm._s(p.part_number))]
                                ),
                                _c("div", { staticClass: "t-text-gray-500" }, [
                                  _vm._v(_vm._s(p.description))
                                ])
                              ])
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t-text-right t-mt-3" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitting, type: "primary", plain: "" },
              on: { click: _vm.add }
            },
            [_vm._v(" Add ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }