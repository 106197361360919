<template>
  <el-dialog
    center
    append-to-body
    :close-on-click-modal="false"
    title="Text message confirmation"
    :visible.sync="visible"
    custom-class="text-message-confirmation-dialog">
    <el-timeline v-if="jobConfirmationHistory.length > 0" style="margin: 10px 0 0 100px;">
      <el-timeline-item
        v-for="(activity, index) in jobConfirmationHistory"
        :key="index"
        :icon="activity.icon"
        :type="activity.type"
        :size="activity.size"
        :timestamp="activity.timestamp">
        {{ activity.name }}
      </el-timeline-item>
    </el-timeline>
    <manual-status-update
      :formVisible="manualStatusUpdateVisible"
      @toggleForm="manualStatusUpdateVisible = !manualStatusUpdateVisible"/>
    <el-divider/>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :hide-required-asterisk="true"
      label-width="140px"
      size="small">
      <el-form-item prop="shopPhoneNumber">
        <span slot="label" class="undo-label-styles">Shop phone</span>
        <el-select
          placeholder="Select shop phone number"
          v-model="form.shopPhoneNumber"
          style="width: 100%">
          <el-option
            v-for="item in shopPhoneNumbers"
            :label="generateLabel(item)"
            :key="item.id"
            :value="item.phone_number">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="customerPhoneNumber">
        <span slot="label" class="undo-label-styles">Customer phone</span>
        <el-select
          placeholder="Select customer phone number"
          v-model="form.customerPhoneNumber"
          style="width: 100%">
          <el-option
            v-for="(item, key) in customerPhoneNumbers"
            :label="formatPhone(item.phone_number)"
            :key="key"
            :value="item.phone_number">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="selectedTemplate">
        <span slot="label" class="undo-label-styles">Template</span>
        <el-select
          clearable
          placeholder="Select text message template (optional)"
          v-model="selectedTemplateId"
          style="width: 100%">
          <el-option
            v-for="item in templates"
            :label="item.name"
            :key="item.id"
            :value="item.id">
          </el-option>
        </el-select>
        or <a href="javascript:void(0)" @click="manageTemplates">Manage text message templates</a>
      </el-form-item>
      <el-form-item prop="body">
        <span slot="label" class="undo-label-styles">Template body</span>
        <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 10}"
          placeholder="Template body"
          v-model="form.body"
        />
      </el-form-item>
      <el-form-item style="word-break: break-word;">
        <p class="mt-3"><span style="color: #F56C6C;">NOTE:</span> text "Please reply YES to confirm or reply NO to
          change" will be automatically added in the end of the message.</p>
      </el-form-item>
      <el-form-item>
        <el-button
          class="mb-2 mr-2"
          type="primary"
          @click="handleSubmit"
          :loading="loading">
          Send
        </el-button>
        <el-button
          class="ml-0"
          plain
          @click="visible = false">
          Close
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import Job from '@/scripts/objects/job'
import ManualStatusUpdate from './ManualStatusUpdate'
import TwilioPhoneNumber from '@/scripts/objects/twilio_phone_number'
import actions from '@/store/modules/scheduler_v2/types'
import actionsV3 from '@/store/modules/scheduler_v3/types'
import { formatPhone } from '@/scripts/helpers/twilio.helpers'
import { buildTextMessageBody } from '@/scripts/helpers/job_confirmation.helpers'

export default {
  data: () => ({
    form: {
      shopPhoneNumber: '',
      customerPhoneNumber: '',
      body: ''
    },
    rules: {
      shopPhoneNumber: { required: true, message: 'Please select shop phone number', trigger: 'change' },
      customerPhoneNumber: { required: true, message: 'Please select customer phone number', trigger: 'change' },
      body: { required: true, message: 'Please enter a template body', trigger: 'change' }
    },
    selectedTemplateId: '',
    templates: [],
    shopPhoneNumbers: [],
    customerPhoneNumbers: [],
    loading: false,
    manualStatusUpdateVisible: false
  }),
  computed: {
    jobId: get('twilio/textMessages/jobConfirmation/jobId'),
    job: get('twilio/textMessages/jobConfirmation/job'),
    shopId: get('twilio/textMessages/jobConfirmation/shopId'),
    visible: sync('twilio/textMessages/jobConfirmation/visible'),
    jobConfirmationHistory: get('twilio/textMessages/jobConfirmation/formattedHistory'),
    confirmationWasSentBefore: get('twilio/textMessages/jobConfirmation/confirmationWasSentBefore')
  },
  mounted() {
    this.clearJobConfirmationHistory()
    this.getTemplates()
    this.getShopPhoneNumbers()
    this.getCustomerPhoneNumbers()
    this.getJobConfirmationHistory(this.jobId)
  },
  watch: {
    selectedTemplateId: async function (value) {
      if (value !== '') {
        const template = this.templates.filter((template) => template.id === value)[0]
        const textMessageBody = await buildTextMessageBody(this.job, template)

        this.form.body = textMessageBody
      } else {
        this.form.body = ''
      }
    }
  },
  methods: {
    setJobConfirmationId: call('twilio/textMessages/jobConfirmation/setJobId'),
    getJobConfirmationHistory: call('twilio/textMessages/jobConfirmation/getHistory'),
    clearJobConfirmationHistory: call('twilio/textMessages/jobConfirmation/clearHistory'),
    openTextMessageTemplatesDialog: call('twilio/textMessages/templates/open'),
    setTextMessageTemplatesReferral: call('twilio/textMessages/templates/setReferral'),
    addStatusToConfirmationHistory: call(actions.addStatusToConfirmationHistory),
    updateScheduler: call(actionsV3.getSchedulerData),
    updateSchedulerJobDetails: call(actionsV3.getJobDetails),
    formatPhone,
    manageTemplates () {
      this.visible = false
      this.openTextMessageTemplatesDialog()
      this.setTextMessageTemplatesReferral('job-confirmation')
    },
    clearForm () {
      this.selectedTemplateId = ''
      this.form.shopPhoneNumber = ''
      this.form.customerPhoneNumber = ''
      this.form.body = ''
    },
    async handleSubmit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          if (this.confirmationWasSentBefore) {
            await this.$messageBox.confirm(
              'You already sent a confirmation text message before for this job. Are you sure you want to send another one ?',
              'Warning',
              {
                type: 'warning',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                beforeClose: async (action, instance, done) => {
                  if (action === 'confirm') {
                    this.send()
                  }
                  done()
                }
              }
            )
          } else {
            this.send()
          }
        }
      } catch (err) {
        if (err.error === 'The message From/To pair violates a blacklist rule.') {
          this.$message.warning('Customer previously replied STOP to this number, so this message can\'t be delivered.')
        }
        this.loading = false
      }
    },
    async send () {
      const { data } = await this.$unum.confirmation()
        .post('/confirmation/send/manual', {
          jobId: this.jobId,
          body: this.form.body,
          shopPhoneNumber: this.form.shopPhoneNumber,
          customerPhoneNumber: this.form.customerPhoneNumber
        })

      this.loading = false

      if (data.result === 'success') {
        this.visible = false
        this.getJobConfirmationHistory(this.jobId)
        this.addStatusToConfirmationHistory(data.history)

        // update scheduler if open
        if (this.$store.state.scheduler?.isOpen) {
          this.updateScheduler()
        }

        // update scheduler details drawer if open
        if (this.jobId && this.$store.state.sch_v3?.jobDetails?.id === this.jobId) {
          this.updateSchedulerJobDetails(this.jobId)
        }

        this.$message.success('Job confirmation successfully sent.')
      } else {
        this.$message.error('Something went wrong.')
      }
    },
    async getTemplates () {
      try {
        const {data} = await this.$unum.job().get(`textMessageTemplate/allByType/sms?shopId=${this.shopId}&type=1`)
        this.templates = data || []
        
        if (this.templates.length === 1) {
          this.selectedTemplateId = this.templates[0].id
          return
        }
        
        this.templates.forEach(template => {
          if (template.default === 1) {
            this.selectedTemplateId = template.id
          }
        })
      } catch {
        this.templates = []
      }
    },
    async getShopPhoneNumbers () {
      const shopPhoneNumbers = await TwilioPhoneNumber.getForJobConfirmation(this.shopId)
      let defaultNumber = ''
      shopPhoneNumbers.forEach(phoneNumber => {
        if (phoneNumber.default) {
          defaultNumber = phoneNumber.phone_number
        }
      })
      if (defaultNumber === '' && shopPhoneNumbers.length > 0) {
        defaultNumber = shopPhoneNumbers[0].phone_number
      }
      this.shopPhoneNumbers = shopPhoneNumbers
      this.form.shopPhoneNumber = defaultNumber
    },
    async getCustomerPhoneNumbers () {
      this.customerPhoneNumbers = await Job.getCustomerPhoneNumbers(this.jobId)
      this.customerPhoneNumbers.forEach(phone => {
        if (phone.default === 1) {
          this.form.customerPhoneNumber = phone.phone_number
        }
      })
    },
    generateLabel (i) {
      const number = `${i.phone_number.substr(0, 3)}-${i.phone_number.substr(3, 3)}-${i.phone_number.substr(6)}`
      let label = `${number} - ${i.name}`
      if (Object.prototype.hasOwnProperty.call(i, 'type')) {
        label += ` ( ${i.type} )`
      }
      return label
    }
  },
  components: {
    ManualStatusUpdate
  }
}
</script>

<style>
  .text-message-confirmation-dialog {
    @apply t-font-sans;
  }
</style>
