<template>
  <div>
    <select
      id="install_location"
      v-model="location"
      class="form-control form-control-sm"
    >
      <option value="">Choose...</option>
      <option v-for="loc in options" :key="loc.id" :value="loc.id">
        {{ loc.name }}
      </option>
    </select>
    <install-location-dialog
      :shopId="shopId"
      @new-location="onNewLocation"
      :editLocation="currentSelected"
    />
  </div>
</template>

<script>
import InstallLocationDialog from '@/components/modals/InstallLocationDialog'
import Api from '@/services/Api'
import * as R from 'ramda'

export default {
  components: { InstallLocationDialog },
  props: ['value', 'shopId', 'caLocation'],
  data: function() {
    return {
      options: []
    }
  },
  computed: {
    location: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
        const current = this.options.find(R.propEq('id', v))
        this.$emit('update-address', R.prop('g_address')(current))
      }
    },
    currentSelected() {
      return this.value && this.options.find(R.propEq('id', this.value))
    }
  },
  methods: {
    async getInstallLocations() {
      const { data } = await Api.get(`/api/install_location/${this.shopId}`)
      this.options = [...data, ...(this.caLocation ? [this.caLocation] : [])]
    },
    onNewLocation(location) {
      this.$emit('update-address', location.g_address)
      this.addOrUpdateOption(location)
      this.location = location.id
    },
    addOrUpdateOption(option) {
      const index = this.options.findIndex(R.eqProps('id', option))
      if (index >= 0) {
        this.options = R.update(index, option)(this.options)
      } else {
        this.options = R.append(option)(this.options)
      }
    }
  },
  mounted() {
    this.getInstallLocations()
  }
}
</script>
