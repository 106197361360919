<template>
  <el-popover
    placement="right"
    trigger="click"
    width="400"
    v-model="visible"
  >
    <div v-loading="loading" >
      <el-button @click="disconnectTech">Disconnect Technician</el-button>
    </div>
    <el-button slot="reference" size="mini" type="success" plain>Connected</el-button>
  </el-popover>
</template>

<script>
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import { mapActions } from 'pinia'

export default {
  data() {
    return {
      loading: false,
      visible: false
    }
  },

  methods: {
    ...mapActions(useVendorStore, ['disconnectTechnician']),
    async disconnectTech () {
      this.loading = true
      try {
        await this.disconnectTechnician()
        this.$message.success('Tech disconnected')
      } catch (e) {
        this.$message.error(e.message)
      }
      this.visible = false
      this.loading = false
    }
  }
}
</script>
