var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "global-search-dialog-container",
      on: {
        click: _vm.onClose,
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.next($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.prev($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.enter($event)
          }
        ]
      }
    },
    [
      _c(
        "transition",
        {
          attrs: {
            appear: "",
            "enter-active-class": "animate__animated animate__fadeInDown",
            "leave-active-class": "animate__animated animate__fadeOutUp",
            mode: "out-in"
          },
          on: { "after-leave": _vm.close }
        },
        [
          _vm.show
            ? _c(
                "div",
                {
                  staticClass: "global-search-dialog",
                  style: {
                    "max-width":
                      _vm.results.jobs &&
                      _vm.results.jobs.length > 0 &&
                      _vm.results.consumers &&
                      _vm.results.consumers.length > 0
                        ? "1200px"
                        : "700px"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    },
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "global-search-top" },
                    [
                      _c("i", { staticClass: "el-icon-search" }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.query,
                            expression: "query"
                          }
                        ],
                        ref: "searchbar",
                        staticClass: "global-search-dialog-input",
                        attrs: { placeholder: "Search anything" },
                        domProps: { value: _vm.query },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.query = $event.target.value
                            },
                            _vm.initialSearch
                          ]
                        }
                      }),
                      _c("md-circle-button", {
                        staticClass: "search-settings-button",
                        attrs: { icon: "tune", primary: _vm.showSettings },
                        on: {
                          click: function($event) {
                            _vm.showSettings = !_vm.showSettings
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "close-search",
                          on: { click: _vm.onClose }
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      )
                    ],
                    1
                  ),
                  _c("global-search-settings", {
                    attrs: { display: _vm.showSettings }
                  }),
                  _vm.loading
                    ? _c("global-search-loading")
                    : _c("global-search-results", {
                        ref: "results",
                        attrs: {
                          results: _vm.results,
                          "show-recent": _vm.showRecent,
                          noMoreJobResults: _vm.noMoreJobResults,
                          noMoreConsumerResults: _vm.noMoreConsumerResults,
                          noMoreSalesideJobResults: _vm.noMoreSalesideJobResults
                        },
                        on: { select: _vm.onSelect, loadMore: _vm.loadMore }
                      })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }