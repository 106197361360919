var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.type === "phone"
      ? _c(
          "div",
          { staticClass: "input-group mt-1 mt-lg-0" },
          [
            _c("Phone", {
              attrs: {
                isGroupInput: true,
                value: _vm.inputValue,
                isSaveNotFormatted: true
              },
              on: {
                "update:value": function($event) {
                  _vm.inputValue = $event
                }
              }
            }),
            _c("div", { staticClass: "input-group-append append-delete" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-gray btn-sm",
                  attrs: {
                    type: "button",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  },
                  on: { click: _vm.removeItem }
                },
                [_c("span", [_vm._v("X")])]
              )
            ])
          ],
          1
        )
      : _vm._e(),
    _vm.type === "email"
      ? _c(
          "div",
          { staticClass: "input-group mt-1 mt-lg-0" },
          [
            _c("Email", {
              attrs: { isGroupInput: true, value: _vm.inputValue },
              on: {
                "update:value": function($event) {
                  _vm.inputValue = $event
                }
              }
            }),
            _c("div", { staticClass: "input-group-append append-delete" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-gray btn-sm",
                  attrs: {
                    type: "button",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  },
                  on: { click: _vm.removeItem }
                },
                [_c("span", [_vm._v("X")])]
              )
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }