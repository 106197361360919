import debounce from 'lodash.debounce'
import * as R from 'ramda'

export const mergeSpec = R.curry(function mergeSpec(spec, value) {
  return R.converge(R.merge, [R.identity, R.applySpec(spec)])(value)
})

export const updateOrAppendValueInList = (list, value) => {
  const index = R.findIndex(R.propEq('id', value.id))(list)
  if (index >= 0) {
    return R.update(index, value, list)
  }
  return R.append(value, list)
}

export const removeValueFromListById = (list, id) => {
  return R.reject(R.propEq('id', id))(list)
}

export const mapIndexed = R.addIndex(R.map)

export const invoke = name => R.invoker(0, name)

export const propNot = prop => R.pipe(R.prop(prop), R.not)

export const propNotEq = (prop, val) => R.pipe(R.propEq(prop, val), R.not)

export const propOrProp = (first, second) =>
  R.ifElse(R.prop(first), R.prop(first), R.prop(second))

export const mergeArray = R.lift(R.merge)
