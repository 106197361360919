<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import { schedulerNamespace } from '@/store/modules/scheduler/scheduler.types'
import { Notification } from 'element-ui'
import { getJobOrWaypointAddress } from '@/scripts/helpers/job.helpers'
const COOKIE = 'map-swap-notificaion'

export default {
  computed: {
    ...mapGetters(schedulerNamespace, ['geometryErrors'])
  },
  mounted () {
    Notification.closeAll()
    // if (!this.$cookies.get(COOKIE)) {
    //   Notification.info({
    //     title: 'New feature',
    //     message: 'You can now change order of jobs directly on the map. Simply drag any marker onto another one',
    //     duration: 0,
    //     onClose: this.setCookie
    //   })
    // }
  },
  methods: {
    setCookie () {
      this.$cookies.set(COOKIE, true, '30d')
    },
    getErrorMessage (tech, index) {
      const { error, name, jobsAndWaypoints } = tech
      const { status, geocoded_waypoints } = error
      const message = { duration: 0 }
      if (status === 'ZERO_RESULTS') {
        message.title = `Routing error: ${name}`
        message.message = 'Couldn\'t generate route. Please check addresses and waypoints'
      } else if (status === 'NOT_FOUND') {
        message.title = `Geocoding error: ${name}`
        message.message = 'Error geocoding following addresses: <br/>'
        message.dangerouslyUseHTMLString = true
        geocoded_waypoints.forEach(({ geocoder_status }, index) => {
          if (geocoder_status !== 'OK') {
            message.message += getJobOrWaypointAddress(jobsAndWaypoints[index]) + '<br/>'
          }
        })
      } else {
        message.message = error.error_message
      }
      setTimeout(() => {
        Notification.error(message)
      }, index * 100)
    }
  },
  watch: {
    geometryErrors (errors) {
      Notification.closeAll()
      errors.forEach(this.getErrorMessage)
    }
  },
  destroyed () {
    Notification.closeAll()
  }
}
</script>
