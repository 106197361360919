var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "autocomplete",
      class: { open: _vm.openSuggestion, "input-group": true },
      staticStyle: { position: "relative" }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.internalSearchValue,
            expression: "internalSearchValue"
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: true,
            expression: "true"
          }
        ],
        staticClass: "form-control quick-search autocomplete-off",
        attrs: {
          type: "text",
          readonly: _vm.readonly,
          placeholder: "Global Search...",
          autocomplete: "false"
        },
        domProps: { value: _vm.internalSearchValue },
        on: {
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.enter($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              return _vm.down($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              return _vm.up($event)
            }
          ],
          focus: _vm.focused,
          blur: _vm.reset,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.internalSearchValue = $event.target.value
          }
        }
      }),
      _vm._m(0),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabs.length || _vm.loadingQuickSearch,
              expression: "tabs.length || loadingQuickSearch"
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingQuickSearch,
              expression: "loadingQuickSearch"
            }
          ],
          ref: "autocompleteResults",
          staticClass: "autocomplete-results",
          class: { autocompleteResultsStyled: !_vm.isMobile },
          style: {
            "max-height": "400px",
            "min-height": "400px",
            "margin-top": "35px"
          }
        },
        [
          _c(
            "el-tabs",
            {
              staticStyle: { color: "black" },
              model: {
                value: _vm.currentTab,
                callback: function($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab"
              }
            },
            _vm._l(_vm.tabs, function(item) {
              return _c(
                "el-tab-pane",
                { key: item.key, attrs: { name: item.key, lazy: "" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" " + _vm._s(item.label) + " "),
                    _c(
                      "span",
                      {
                        staticClass: "tab-count",
                        style: "background:" + _vm.getTabColor(item.key) + ";"
                      },
                      [_vm._v(_vm._s(item.count))]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "tab-container" },
                    [
                      _c(item.component, {
                        ref: "tabs",
                        refInFor: true,
                        tag: "component",
                        attrs: { loading: false },
                        on: { selected: _vm.selected },
                        model: {
                          value: item.data,
                          callback: function($$v) {
                            _vm.$set(item, "data", $$v)
                          },
                          expression: "item.data"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      !_vm.userConsumer
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.recentJobsToDisplay &&
                    _vm.recentJobsToDisplay.length > 0 &&
                    _vm.showUserRecentJobs,
                  expression:
                    "recentJobsToDisplay && recentJobsToDisplay.length > 0 && showUserRecentJobs"
                }
              ],
              ref: "userRecentJobsResults",
              staticClass: "autocomplete-results",
              class: { autocompleteResultsStyled: !_vm.isMobile },
              style: {
                "max-height": "400px",
                "margin-top": "35px",
                color: "black"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "row",
                  staticStyle: { width: "100%", cursor: "pointer" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.activateRow($event)
                    }
                  }
                },
                [
                  _vm._m(1),
                  _c("div", { staticClass: "col-3" }, [
                    _c("span", [_vm._v("Jobs:")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.recentJobCount,
                          expression: "recentJobCount"
                        }
                      ],
                      staticStyle: { width: "30%" },
                      attrs: { type: "number", min: "0", max: "30" },
                      domProps: { value: _vm.recentJobCount },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.recentJobCount = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-1" }, [
                    _c(
                      "a",
                      {
                        staticStyle: { "text-decoration": "none" },
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.disableUserRecentJobs }
                      },
                      [_vm._v("Disable")]
                    )
                  ])
                ]
              ),
              _c(
                "ul",
                { staticStyle: { width: "100%" } },
                [
                  _vm._l(_vm.recentJobsToDisplay, function(recentJob, index) {
                    return [
                      _c(
                        "li",
                        {
                          key: index,
                          ref: "recentJob:" + index,
                          refInFor: true,
                          staticClass: "autocomplete-result",
                          class: { "is-active-recent": _vm.isActive(index) },
                          on: {
                            mouseover: function($event) {
                              return _vm.mouseOver(index)
                            },
                            mousedown: function($event) {
                              return _vm.suggestionClick(index)
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              class: { "white-text": _vm.isActive(index) },
                              staticStyle: { "text-decoration": "none" },
                              attrs: { href: "javascript:void(0)" }
                            },
                            [
                              recentJob.job.customer_type === "individual" ||
                              recentJob.job.customer_type === null
                                ? _c("span", [
                                    _c(
                                      "strong",
                                      {
                                        class: {
                                          "qs-job-color": true,
                                          "label-job-invoice":
                                            recentJob.job.status === "invoice",
                                          "label-job-workOrder":
                                            recentJob.job.status ===
                                            "workOrder",
                                          "label-job-quote":
                                            recentJob.job.status === "quote",
                                          "label-job-warranty":
                                            recentJob.job.status ===
                                              "warranty" ||
                                            recentJob.job.status ===
                                              "warrantyComplete",
                                          "label-job-void":
                                            recentJob.job.status === "void"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(recentJob.job.id) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    recentJob.job.customer_first_name &&
                                    recentJob.job.customer_first_name.length > 0
                                      ? _c(
                                          "span",
                                          { staticClass: "customerName" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                recentJob.job
                                                  .customer_first_name
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    recentJob.job.customer_middle_name &&
                                    recentJob.job.customer_middle_name.length >
                                      0
                                      ? _c(
                                          "span",
                                          { staticClass: "customerName" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  recentJob.job
                                                    .customer_middle_name
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    recentJob.job.customer_last_name &&
                                    recentJob.job.customer_last_name.length > 0
                                      ? _c(
                                          "span",
                                          { staticClass: "customerName" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  recentJob.job
                                                    .customer_last_name
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(recentJob.job.referral_number)
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(recentJob.job.internal_number)
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "customerNumber" },
                                      [
                                        _vm._v(
                                          _vm._s(recentJob.job.customer_phone1)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "customerNumber" },
                                      [
                                        _vm._v(
                                          _vm._s(recentJob.job.customer_phone2)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(recentJob.job.unit_number))
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(recentJob.job.policy_number)
                                      )
                                    ])
                                  ])
                                : _c("span", [
                                    _c(
                                      "strong",
                                      {
                                        class: {
                                          "qs-job-color": true,
                                          "label-job-invoice":
                                            recentJob.job.status === "invoice",
                                          "label-job-workOrder":
                                            recentJob.job.status ===
                                            "workOrder",
                                          "label-job-quote":
                                            recentJob.job.status === "quote",
                                          "label-job-warranty":
                                            recentJob.job.status ===
                                              "warranty" ||
                                            recentJob.job.status ===
                                              "warrantyComplete",
                                          "label-job-void":
                                            recentJob.job.status === "void"
                                        }
                                      },
                                      [_vm._v(_vm._s(recentJob.job.id))]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(recentJob.job.ca.name))
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(recentJob.job.referral_number)
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(recentJob.job.internal_number)
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(recentJob.job.ca.phone1))
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(recentJob.job.ca.phone2))
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(recentJob.job.unit_number))
                                    ])
                                  ])
                            ]
                          )
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm.userConsumer
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.userConsumer === true &&
                    _vm.recentJobsToDisplay &&
                    _vm.recentJobsToDisplay.length > 0 &&
                    _vm.showUserRecentJobs,
                  expression:
                    "userConsumer === true && recentJobsToDisplay && recentJobsToDisplay.length > 0 && showUserRecentJobs"
                }
              ],
              ref: "userRecentJobsResultsConsumer",
              staticClass: "autocomplete-results",
              class: { autocompleteResultsStyled: !_vm.isMobile },
              style: {
                "max-height": "400px",
                "margin-top": "35px",
                color: "black"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "row",
                  staticStyle: { width: "100%", cursor: "pointer" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.activateRow($event)
                    }
                  }
                },
                [
                  _vm._m(2),
                  _c("div", { staticClass: "col-3" }, [
                    _c("span", [_vm._v("Jobs:")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.recentJobCount,
                          expression: "recentJobCount"
                        }
                      ],
                      staticStyle: { width: "30%" },
                      attrs: { type: "number", min: "0", max: "30" },
                      domProps: { value: _vm.recentJobCount },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.recentJobCount = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-1" }, [
                    _c(
                      "a",
                      {
                        staticStyle: { "text-decoration": "none" },
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.disableUserRecentJobs }
                      },
                      [_vm._v("Disable")]
                    )
                  ])
                ]
              ),
              _c(
                "ul",
                { staticStyle: { width: "100%" } },
                [
                  _vm._l(_vm.recentJobsToDisplay, function(recentJob, index) {
                    return [
                      _c(
                        "li",
                        {
                          key: index,
                          ref: "recentJob:" + index,
                          refInFor: true,
                          staticClass: "autocomplete-result",
                          class: { "is-active-recent": _vm.isActive(index) },
                          on: {
                            mouseover: function($event) {
                              return _vm.mouseOver(index)
                            },
                            mousedown: function($event) {
                              return _vm.suggestionClick(index)
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              class: { "white-text": _vm.isActive(index) },
                              staticStyle: { "text-decoration": "none" },
                              attrs: { href: "javascript:void(0)" }
                            },
                            [
                              recentJob.job.consumer &&
                              !recentJob.job.consumer.is_commercial
                                ? _c("span", [
                                    _c(
                                      "strong",
                                      {
                                        class: {
                                          "qs-job-color": true,
                                          "label-job-invoice":
                                            recentJob.job.status === "invoice",
                                          "label-job-workOrder":
                                            recentJob.job.status ===
                                            "workOrder",
                                          "label-job-quote":
                                            recentJob.job.status === "quote",
                                          "label-job-warranty":
                                            recentJob.job.status ===
                                              "warranty" ||
                                            recentJob.job.status ===
                                              "warrantyComplete",
                                          "label-job-void":
                                            recentJob.job.status === "void"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(recentJob.job_id) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    recentJob.job.consumer.full_name.first &&
                                    recentJob.job.consumer.full_name.first
                                      .length > 0
                                      ? _c(
                                          "span",
                                          { staticClass: "customerName" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                recentJob.job.consumer.full_name
                                                  .first
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    recentJob.job.consumer.full_name.middle &&
                                    recentJob.job.consumer.full_name.middle
                                      .length > 0
                                      ? _c(
                                          "span",
                                          { staticClass: "customerName" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  recentJob.job.consumer
                                                    .full_name.middle
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    recentJob.job.consumer.full_name.last &&
                                    recentJob.job.consumer.full_name.last
                                      .length > 0
                                      ? _c(
                                          "span",
                                          { staticClass: "customerName" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  recentJob.job.consumer
                                                    .full_name.last
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(recentJob.job.referral_number)
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(recentJob.job.internal_number)
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "customerNumber" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            recentJob.job.consumer.phone.number
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(recentJob.job.unit_number))
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(recentJob.job.policy_number)
                                      )
                                    ])
                                  ])
                                : _c("span", [
                                    _c(
                                      "strong",
                                      {
                                        class: {
                                          "qs-job-color": true,
                                          "label-job-invoice":
                                            recentJob.job.status === "invoice",
                                          "label-job-workOrder":
                                            recentJob.job.status ===
                                            "workOrder",
                                          "label-job-quote":
                                            recentJob.job.status === "quote",
                                          "label-job-warranty":
                                            recentJob.job.status ===
                                              "warranty" ||
                                            recentJob.job.status ===
                                              "warrantyComplete",
                                          "label-job-void":
                                            recentJob.job.status === "void"
                                        }
                                      },
                                      [_vm._v(_vm._s(recentJob.job.id))]
                                    ),
                                    recentJob.job.consumer
                                      ? _c(
                                          "span",
                                          { staticClass: "qs-separator mx-1" },
                                          [_vm._v("|")]
                                        )
                                      : _vm._e(),
                                    recentJob.job.consumer
                                      ? _c(
                                          "span",
                                          { staticClass: "customerName" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                recentJob.job.consumer
                                                  .company_name
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(recentJob.job.referral_number)
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(recentJob.job.internal_number)
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    recentJob.job.consumer
                                      ? _c(
                                          "span",
                                          { staticClass: "customerNumber" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                recentJob.job.consumer.phone
                                                  .number
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "qs-separator mx-1" },
                                      [_vm._v("|")]
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(recentJob.job.unit_number))
                                    ])
                                  ])
                            ]
                          )
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _c("consumer-dialog", {
        attrs: { consumer: {}, shop_id: 4, focus: "" },
        on: { "on-save": _vm.onCreateNew },
        model: {
          value: _vm.showConsumerDialog,
          callback: function($$v) {
            _vm.showConsumerDialog = $$v
          },
          expression: "showConsumerDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-btn" }, [
      _c("button", { staticClass: "btn btn-gray", attrs: { type: "button" } }, [
        _c("i", { staticClass: "fa fa-search" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-8" }, [
      _c("span", { staticStyle: { "text-decoration": "underline" } }, [
        _vm._v("Recent Jobs")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-8" }, [
      _c("span", { staticStyle: { "text-decoration": "underline" } }, [
        _vm._v("Recent Jobs")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }