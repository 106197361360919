var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "route-entry-part-pickup-list" },
    _vm._l(_vm.partList, function(parts, job_id) {
      return _c("li", { key: job_id }, [
        _c("strong", [_vm._v(" " + _vm._s(job_id) + " ")]),
        _c(
          "ul",
          _vm._l(parts, function(part) {
            return _c("li", { key: part.id }, [
              _vm._v(" " + _vm._s(part.part_number) + " ")
            ])
          }),
          0
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }