var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.inventoryLoading
        ? _c("LoadingSpinner")
        : _c(
            "div",
            [
              _c("div", { staticClass: "t-flex t-items-center t-h-8" }, [
                _c(
                  "div",
                  { staticClass: "t-flex-grow t-text-gray-500 t-mr-2 t-w-40" },
                  [_vm._v("Total Available")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "t-w-12 t-text-center",
                    class: {
                      "t-text-blue-500 hover:t-underline t-cursor-pointer":
                        _vm.available.length > 0
                    },
                    on: {
                      click: function($event) {
                        return _vm.showDetails("Total Available", _vm.available)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.available.length) + " ")]
                )
              ]),
              _c("div", { staticClass: "t-flex t-items-center t-h-8" }, [
                _c(
                  "div",
                  { staticClass: "t-flex-grow t-text-gray-500 t-mr-2 t-w-40" },
                  [_vm._v("Unreceived PO")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "t-w-12 t-text-center",
                    class: {
                      "t-text-blue-500 hover:t-underline t-cursor-pointer":
                        _vm.onPurchaseOrder.length > 0
                    },
                    on: {
                      click: function($event) {
                        return _vm.showDetails(
                          "Total on Purchase",
                          _vm.onPurchaseOrder
                        )
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.onPurchaseOrder.length) + " ")]
                )
              ]),
              _c("div", { staticClass: "t-flex t-items-center t-h-8" }, [
                _c(
                  "div",
                  { staticClass: "t-flex-grow t-text-gray-500 t-mr-2 t-w-40" },
                  [_vm._v("Allocated Units")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "t-w-12 t-text-center",
                    class: {
                      "t-text-blue-500 hover:t-underline t-cursor-pointer":
                        _vm.openJobInventory.length > 0
                    },
                    on: {
                      click: function($event) {
                        return _vm.showDetails(
                          "Total Allocated Units",
                          _vm.openJobInventory
                        )
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.openJobInventory.length) + " ")]
                )
              ]),
              _c(
                "el-button",
                {
                  staticClass: "t-mt-2",
                  attrs: { plain: "", type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.adjustmentDialogIsOpen = true
                    }
                  }
                },
                [_vm._v(" Adjust inventory ")]
              )
            ],
            1
          ),
      _c("DetailsDialog", {
        attrs: {
          visible: _vm.detailsDialog.isOpen,
          title: _vm.detailsDialog.title,
          units: _vm.detailsDialog.units,
          shop: _vm.shop
        },
        on: {
          "update:visible": function($event) {
            return _vm.$set(_vm.detailsDialog, "isOpen", $event)
          }
        }
      }),
      _c("AdjustmentDialog", {
        attrs: {
          visible: _vm.adjustmentDialogIsOpen,
          itemId: _vm.itemId,
          shopId: _vm.shopId
        },
        on: {
          "update:visible": function($event) {
            _vm.adjustmentDialogIsOpen = $event
          },
          adjusted: _vm.getInventory
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }