import * as R from 'ramda'
import { parse, endOfDay, isPast } from 'date-fns'
import {
  parseDateToString,
  parseTime,
  parseTimeWithMinutes,
  parseTimeDate,
  formatToHour
} from './date.helpers'
import Document from '../objects/document'
import { mergeSpec } from './ramda.helpers'
import moment from 'moment'
import { sumByProp } from '@/scripts/helpers/totals.helpers'

export const getScheduledDate = R.prop('scheduled_date')
export const getScheduledStartTime = R.prop('scheduled_time_start')
export const getScheduledEndTime = R.prop('scheduled_time_end')
export const getTechId = R.prop('tech_id')
export const getInstallNotes = R.prop('install_notes')
export const getTechSideSent = R.propEq('techside_sent', 'sent')
export const getPartsOnBackorder = R.propEq('parts_on_backorder', '1')
export const getPendingInspection = R.propEq('pending_inspection', '1')
export const getPendingSchedule = R.propEq('pending_schedule', '1')
export const getCustomerCancel = R.propEq('customer_cancel', '1')
export const getCustomerReschedule = R.propEq('customer_reschedule', '1')
export const getTechnicianNotes = R.prop('technician_notes')
export const getSalesrepNotes = R.prop('salesrep_notes')

export const isInvalidDate = R.anyPass([
  R.isNil,
  R.isEmpty,
  R.equals('0000-00-00')
])

export const isJobUnscheduled = R.pipe(getScheduledDate, isInvalidDate)

export const isJobScheduled = R.pipe(isJobUnscheduled, R.not)

export const isInvalidTime = R.anyPass([
  R.isNil,
  R.isEmpty,
  R.equals('00:00:00')
])

export const isValidTime = R.pipe(isInvalidTime, R.not)

export const isJobTimeUnscheduled = R.anyPass([
  R.pipe(getScheduledStartTime, isInvalidTime),
  R.pipe(getScheduledEndTime, isInvalidTime)
])

export const isJobTimeScheduled = R.pipe(isJobTimeUnscheduled, R.not)

export const isDateInPast = R.pipe(parse, endOfDay, isPast)

// Check if job is in past (before start of day)
export const isJobInPast = R.allPass([
  isJobScheduled,
  R.pipe(getScheduledDate, isDateInPast)
])

export const isJobUnassigned = R.pipe(
  getTechId,
  R.anyPass([R.isNil, R.gte(0)])
)

export const checkJobBoolByProp = prop =>
  R.pipe(R.prop(prop), R.anyPass([R.equals('1'), R.equals(1)]))

export const combineStringValues = (values, delimeter) =>
  R.pipe(
    R.pick(values),
    R.values,
    R.filter(R.length),
    R.join(delimeter || ' ')
  )
/**
 * @argument job - job object
 * @returns commercial account name or full combined customer name
 */
export const getJobCardTitle = R.cond([
  [R.propEq('customer_type', 'commercial'), R.prop('commercialaccount_name')],
  [
    R.propEq('customer_type', 'individual'),
    combineStringValues([
      'customer_first_name',
      'customer_middle_name',
      'customer_last_name'
    ])
  ]
])

export const isJobDateAndTimeUnsceduled = R.allPass([
  isJobUnscheduled,
  isJobTimeUnscheduled
])

export const isJobDateAndTimeSceduled = R.pipe(
  isJobDateAndTimeUnsceduled,
  R.not
)

export const getJobDateString = R.cond([
  [isJobScheduled, R.pipe(getScheduledDate, parseDateToString)]
])

/**
 * @argument job
 * @returns formatted start and end time "11am to 2pm"
 */
export const getJobTimeString = R.cond([
  [
    isJobTimeScheduled,
    R.pipe(
      R.pick(['scheduled_time_start', 'scheduled_time_end']),
      R.values,
      R.map(parseTime),
      R.join(' - ')
    )
  ]
])
/**
 * @argument job
 * @returns formatted start and end time "11:00am - 2:30pm"
 */
export const getJobTimeStringWithMinutes = R.cond([
  [
    isJobTimeScheduled,
    R.pipe(
      R.pick(['scheduled_time_start', 'scheduled_time_end']),
      R.values,
      R.map(parseTimeWithMinutes),
      R.join(' - ')
    )
  ]
])

export const getJobDateAndTimeString = job =>
  `${getJobDateString(job)} - ${getJobTimeString(job)}`

/**
 * @argument job - job object
 * @returns "No Scheduled Date" label or date and time if exist
 */
export const getJobDateTime = R.cond([
  [isJobDateAndTimeUnsceduled, R.always('No Date Scheduled')],
  [isJobTimeUnscheduled, getJobDateString],
  [isJobUnscheduled, getJobTimeString],
  [R.T, getJobDateAndTimeString]
])

export const belongsToTech = techId => job =>
  R.is(Array)(techId)
    ? R.contains(job.tech_id)(techId)
    : R.propEq('tech_id', techId)(job)

export const scheduledOnDate = date => R.propEq('scheduled_date', date)

export const getAutoVehicleInfo = R.cond([
  [
    R.pipe(R.propEq('vehicle_year', '0'), R.not),
    combineStringValues([
      'vehicle_year',
      'vehicle_make_text',
      'vehicle_model_text',
      'vehicle_sub_model_text',
      'vehicle_style_text'
    ])
  ]
])

export const getManualVehicleInfo = R.cond([
  [
    R.pipe(R.propEq('vehicle_year_manual', '0'), R.not),
    combineStringValues([
      'vehicle_year_manual',
      'vehicle_make_manual',
      'vehicle_model_manual',
      'vehicle_sub_model_manual',
      'vehicle_style_manual'
    ])
  ]
])

export const getVehicleString = job => {
  return R.cond([
    [R.propEq('manual_vehicle_entry', 1), getManualVehicleInfo],
    [R.T, getAutoVehicleInfo]
  ])(job)
}

export const getJobShopName = R.prop('shop_name')

export const getJobLocationString = combineStringValues(
  ['install_address_city', 'install_address_state'],
  ', '
)

export const getJobAddressLocationWithZip = R.pipe(
  R.juxt([getJobLocationString, R.prop('install_address_zip')]),
  R.join(' ')
)

export const getJobAddressArray = R.pipe(
  R.juxt([
    R.prop('install_address_line1'),
    R.prop('install_address_line2'),
    getJobAddressLocationWithZip
  ]),
  R.filter(R.length)
)

export const getJobOrWaypointAddress = R.cond([
  [R.prop('address'), R.prop('address')],
  [R.T, R.pipe(getJobAddressArray)]
])

export const getJobPhone = R.cond([
  [R.propEq('customer_type', 'commercial'), R.prop('commercialaccount_phone1')],
  [R.propEq('customer_type', 'individual'), R.prop(['customer_phone1'])]
])

export const getJobInstallPhone = R.prop(['contact_phone1'])

export const getPartsArray = R.pipe(
  R.propOr([], 'parts'),
  R.map(R.prop('part_number'))
)

export const getSchedulerPartsArray = R.pipe(
  R.propOr([], 'parts'),
  R.map(R.props(['part_number', 'description']))
)

const getJobTags = R.prop('jobtags')

const getJobNotes = R.prop('notes')

const isPropNotEmpty = prop => R.pipe(R.prop(prop), R.isEmpty, R.not)

const hasCrossStreets = R.anyPass([
  isPropNotEmpty('install_major_street1'),
  isPropNotEmpty('install_major_street2')
])

const getCrossStreets = R.pipe(
  R.pick(['install_major_street1', 'install_major_street2']),
  R.values,
  R.filter(R.pipe(R.isEmpty, R.not)),
  R.join(' / ')
)

const average = R.converge(R.divide, [R.sum, R.length])

export const getJobMidTime = R.pipe(
  R.pick(['scheduled_time_end', 'scheduled_time_start']),
  R.map(R.pipe(parseTimeDate, formatToHour)),
  R.values,
  R.filter(R.lt(0)),
  average
)

export const calculateJobTimeType = R.cond([
  [Number.isNaN, R.always('time not selected')],
  [R.gte(10.5), R.always('morning')],
  [R.gte(13.5), R.always('mid day')],
  [R.T, R.always('afternoon')]
])

export const sortJobsByMidTime = R.groupBy(calculateJobTimeType)

export const getJobMidTimes = R.pipe(
  R.map(getJobMidTime),
  sortJobsByMidTime,
  R.map(R.length),
  R.pickAll(['morning', 'mid day', 'afternoon', 'time not selected'])
)

export const addJobMidTimes = R.map(
  mergeSpec({
    midTime: R.pipe(getJobMidTime, calculateJobTimeType)
  })
)

export const getJobContextCounts = R.pipe(
  R.countBy(({ install_context }) => install_context || 'mobile'),
  R.tap(console.log),
  R.map(R.or(0)),
  R.tap(console.log),
  mergeSpec({ all: R.pipe(R.values, R.sum) })
)

export const getConfirmationDetails = function(obj) {
  const t = obj.job_confirmation_history
  if (t.length > 0) {
    return t[t.length - 1]
  }
  return null
}

export const getDueDateFromInvoiceTier = function(tier, invoiceDate) {
  let days = 0
  let date = null
  if (tier.includes('net')) {
    days = parseInt(tier.split('net')[1])
    date = moment(invoiceDate, 'YYYY-MM-DD')
      .add(days, 'days')
      .format('YYYY-MM-DD') // invoice date should already be passed in as proper timezone, so no need for timezone here
  } else if (tier === 'dor') {
    date = invoiceDate
  }
  return date
}

export const checkForPastDue = function(dueDate, balance, deductible) {
  return balance > 0 || deductible > 0
    ? moment(dueDate, 'YYYY-MM-DD').isBefore(
      moment(new Date(), 'YYYY-MM-DD'),
      'day'
    )
    : false
}

export class JobWrapper {
  constructor(job) {
    this.job = job
  }

  getTitle = () => this.job && getJobCardTitle(this.job);

  getDate = () => this.job && getJobDateString(this.job);

  getTime = () => this.job && getJobTimeString(this.job);

  getVehicle = () => this.job && getVehicleString(this.job);

  getShop = () => this.job && getJobShopName(this.job);

  getLocation = () => this.job && getJobLocationString(this.job);

  getPhone = () => this.job && getJobPhone(this.job);

  getInstallPhone = () => this.job && getJobInstallPhone(this.job);

  getAddress = () => this.job && getJobAddressArray(this.job);

  getParts = () => this.job && getPartsArray(this.job);

  getSchedulerParts = () => this.job && getSchedulerPartsArray(this.job);

  getInstallNotes = () => this.job && getInstallNotes(this.job);

  getTechSideSent = () => this.job && getTechSideSent(this.job);

  getPartsOnBackorder = () => this.job && getPartsOnBackorder(this.job);

  getPendingInspection = () => this.job && getPendingInspection(this.job);

  getPendingSchedule = () => this.job && getPendingSchedule(this.job);

  getCustomerCancel = () => this.job && getCustomerCancel(this.job);

  getCustomerReschedule = () => this.job && getCustomerReschedule(this.job);

  getTechnicianNotes = () => this.job && getTechnicianNotes(this.job);

  getSalesrepNotes = () => this.job && getSalesrepNotes(this.job);

  getScheduledStartTime = () => this.job && getScheduledStartTime(this.job);

  getScheduledEndTime = () => this.job && getScheduledEndTime(this.job);

  getTechId = () => R.prop('tech_id')(this.job);

  getScheduledDate = () => getScheduledDate(this.job);

  getScheduledStartTime = () => getScheduledStartTime(this.job);

  getScheduledEndTime = () => getScheduledEndTime(this.job);

  getTags = () => getJobTags(this.job);

  getDocuments = () => Document.getDocumentTags(this.job.documents);

  getNotes = () => getJobNotes(this.job);

  hasCrossStreets = () => hasCrossStreets(this.job);

  getCrossStreets = () => getCrossStreets(this.job);
}

export class JobWrapperV2 {
  constructor(job) {
    Object.assign(this, job)
  }

  getTitle = () => R.prop('customer_name')(this);
  getDate = () => getJobDateString(this);
  getTime = () => getJobTimeString(this);
  getTimeWithMinutes = () => getJobTimeStringWithMinutes(this);
  getVehicle = () => R.prop('vehicle')(this);
  getShop = () => R.prop('shop_name')(this);
  getLocation = () => R.prop('city_state')(this);
  getPhone = () => R.prop('customer_phone1')(this);
  getInstallPhone = () => R.prop('contact_phone1')(this);
  getAddress = () => R.juxt([R.prop('address'), R.prop('city_state')])(this);
  getParts = () => getPartsArray(this);
  getSchedulerParts = () => getSchedulerPartsArray(this);
  getInstallNotes = () => R.prop('install_notes')(this);
  getTechSideSent = () => getTechSideSent(this);
  getPartsOnBackorder = () => R.prop('parts_on_backorder')(this);
  getVendors = () => {
    const vendors = []
    if (this.csr_data) {
      vendors.push({...this.csr_data, color: '#00897b'})
    }
    if (this.salesource_data) {
      vendors.push({...this.salesource_data, color: '#f0ad4e'})
    }
    if (this.salesrep_data) {
      vendors.push({...this.salesrep_data, color: '#299fd7'})
    }
    if (this.location_data) {
      vendors.push({...this.location_data, color: '#c4c4c4'})
    }
    return vendors
  };
  getPendingInspection = () => R.prop('pending_inspection')(this);
  getPendingSchedule = () => R.prop('pending_schedule')(this);
  getCustomerCancel = () => R.prop('customer_cancel')(this);
  isInShop = () => R.propEq('install_context', 'inShop')(this);
  getCustomerReschedule = () => R.prop('customer_reschedule')(this);
  getTechnicianNotes = () => R.prop('technician_notes')(this);
  getCommision = () => {
    // this will have to change
    const techs = this.vendor?.filter(v => v.gb_vendor_type_id === 4) || []
    let tType = 'flat'
    let tValue = 0

    if (techs.length) {
      tType = techs[0]?.commission_flat_or_percent || 'flat'
      tValue = techs[0]?.commission || 0
    }

    const temp = {
      type: tType, //R.path(['vendor', 'commission_flat_or_percent']),
      value: tValue, // R.prop('tech_commission'),
      total: this.total_after_taxes || 0, // R.prop('total_after_taxes'),
      subtotal: this.total_subtotal || 0, // R.prop('total_subtotal'),
      rebate: this.customer_rebate || 0, // R.prop('customer_rebate'),
      totalPartsCost: R.pipe(
        R.prop('parts'),
        sumByProp('cost')
      )(this),
      otherCosts: this.other_costs || 0, // R.prop('other_costs')
    }

    return temp;
  }

  getSalesrepNotes = () => R.prop('salesrep_notes')(this);
  // getTechId = () => R.prop('tech_id')(this.job)
  getScheduledDate = () => R.prop('scheduled_date')(this);
  getScheduledStartTime = () => R.prop('scheduled_time_start')(this);
  getScheduledEndTime = () => R.prop('scheduled_time_end')(this);
  getTags = () => R.prop('jobtags')(this);
  getDocuments = () => Document.getDocumentTags(this.documents);
  getNotes = () => R.prop('notes')(this);
  // hasCrossStreets = () => hasCrossStreets(this.job)
  getCrossStreets = () => R.pathOr(null, ['install_g_address', 'extra'])(this);
  getCustomerId = () => R.prop('customer_id')(this);
  getConfirmationDetails = () => getConfirmationDetails(this);
  getMidTime = () => R.pipe(getJobMidTime, calculateJobTimeType)(this);
  getTags = () => R.propOr([], 'jobtags')(this);
  getInstallLocationName = () =>
    R.pathOr('', ['install_location', 'name'])(this);
}
