var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isDragover || _vm.items.length,
          expression: "isDragover || items.length"
        }
      ],
      staticClass: "calendar-tech-job-card-list",
      class: { "no-drop": !_vm.enabled }
    },
    [
      _c("div", { staticClass: "job-group-title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c(
        "draggable",
        {
          attrs: { options: _vm.draggableOptions },
          on: { change: _vm.log, end: _vm.onEnd },
          model: {
            value: _vm.itemsList,
            callback: function($$v) {
              _vm.itemsList = $$v
            },
            expression: "itemsList"
          }
        },
        _vm._l(_vm.itemsList, function(item) {
          return _c("calendar-job-card", {
            key: item.id,
            attrs: { item: item, color: _vm.color(item, _vm.tech) }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }