<template>
  <el-dialog
    :visible.sync='visible'
    width="600px"
    destroy-on-close
    append-to-body
  >
  <template slot="title">
    <div v-if='ediData' style="font-size:22px; font-weight:bold" class="form-group col-6" :style="{'color': ediStatusColor(ediData.edi_status || '')}">
      {{ediData.edi_status || ''}}
    </div>
  </template>
    <div v-if='ediData' style="margin-top:10px" class="row">
      <div class="form-group col-12" v-html="ediData.edi_message || ''">
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import { ediStatusColor } from '@/scripts/helpers/rejected.helpers'
export default {
  computed: {
    visible: sync('rejectedInvoices/ediDataVisible'),
    ediData: get('rejectedInvoices/ediData')
  },
  methods: {
    ediStatusColor
  }
}
</script>

<style>

</style>