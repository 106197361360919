// import colors from '../assets/scss/_variables.scss'

import colors from './colors'

export const jobStatusConfig = {
  invoice: {
    label: 'Invoice',
    color: colors.jadeGreen,
    supplementalColor: colors.supplementalInvoiceGreen,
    dateKey: 'invoice_date',
    messageKey: 'customer_invoice_message'
  },
  workOrder: {
    label: 'Work Order',
    color: colors.airForceBlue,
    dateKey: 'work_order_date',
    messageKey: 'customer_workorder_message'
  },
  void: {
    label: 'Void',
    color: colors.crimsonRed,
    dateKey: 'void_date'
  },
  quote: {
    label: 'Quote',
    color: colors.royalPurple,
    dateKey: 'quote_date',
    messageKey: 'customer_quote_message'
  },
  warranty: {
    label: 'Warranty',
    color: colors.goldOrange,
    dateKey: 'warranty_date'
  },
  warrantyComplete: {
    label: 'W. Complete',
    color: colors.goldOrange,
    dateKey: 'warranty_complete_date'
  },
  creditMemo: {
    label: 'Credit Memo',
    color: colors.teaGreen,
    dateKey: 'credit_memo_date'
  },
  draft: {
    label: 'Draft',
    color: '#414141'
  },
  salesidejob: {
    label: 'Mobile Job',
    color: 'orange'
  },
  recal: {
    label: 'Recal',
    color: '#9834eb',
    dateKey: 'recal_date'
  },
  recalComplete: {
    label: 'Recal Complete',
    color: '#9834eb',
    dateKey: 'recal_complete_date'
  },
  callBack: {
    label: 'Call Back',
    color: '#34cdeb',
    dateKey: 'call_back_date'
  },
  callBackComplete: {
    label: 'Call Back Complete',
    color: '#34cdeb',
    dateKey: 'call_back_complete_date'
  },
  rAndI: {
    label: 'R & I',
    color: '#04cc94',
    dateKey: 'r_and_i_date'
  },
  rAndIComplete: {
    label: 'R & I Complete',
    color: '#04cc94',
    dateKey: 'r_and_i_complete_date'
  },
  tint: {
    label: 'Tint',
    color: '#735130',
    dateKey: 'tint_date'
  },
  tintComplete: {
    label: 'Tint Complete',
    color: '#735130',
    dateKey: 'tint_complete_date'
  },
  pickUp: {
    label: 'Pick Up',
    color: '#004646',
    dateKey: 'pick_up_date'
  },
  pickUpComplete: {
    label: 'Pick Up Complete',
    color: '#004646',
    dateKey: 'pick_up_complete_date'
  },
  dropOff: {
    label: 'Drop Off',
    color: '#3a0046',
    dateKey: 'drop_off_date'
  },
  dropOffComplete: {
    label: 'Drop Off Complete',
    color: '#3a0046',
    dateKey: 'drop_off_complete_date'
  }
}

export const getJobStatusConfig = (current) => {
  const returnObj = {}
  switch (current) {
    case 'draft':
    case 'quote':
      returnObj['quote'] = jobStatusConfig['quote']
    case 'workOrder':
      returnObj['workOrder'] = jobStatusConfig['workOrder']
    case 'invoice':
      returnObj['invoice'] = jobStatusConfig['invoice']
      break;
    case 'warranty':
      returnObj['warranty'] = jobStatusConfig['warranty']
    case 'warrantyComplete':
      returnObj['warrantyComplete'] = jobStatusConfig['warrantyComplete']
      break;
    case 'recal':
      returnObj['recal'] = jobStatusConfig['recal']
    case 'recalComplete':
      returnObj['recalComplete'] = jobStatusConfig['recalComplete']
      break;
    case 'callBack':
      returnObj['callBack'] = jobStatusConfig['callBack']
    case 'callBackComplete':
      returnObj['callBackComplete'] = jobStatusConfig['callBackComplete']
      break;
    case 'rAndI':
      returnObj['rAndI'] = jobStatusConfig['rAndI']
    case 'rAndIComplete':
      returnObj['rAndIComplete'] = jobStatusConfig['rAndIComplete']
      break;
    case 'tint':
      returnObj['tint'] = jobStatusConfig['tint']
    case 'tintComplete':
      returnObj['tintComplete'] = jobStatusConfig['tintComplete']
      break;
    case 'pickUp':
      returnObj['pickUp'] = jobStatusConfig['pickUp']
    case 'pickUpComplete':
      returnObj['pickUpComplete'] = jobStatusConfig['pickUpComplete']
      break;
    case 'dropOff':
      returnObj['dropOff'] = jobStatusConfig['dropOff']
    case 'dropOffComplete':
      returnObj['dropOffComplete'] = jobStatusConfig['dropOffComplete']
      break;
    default:
      break;
  }
  returnObj['void'] = jobStatusConfig['void']
  return returnObj
}

export const adminStatusConfig = (current) => {
  const standard = ['draft', 'quote', 'workOrder', 'invoice']
  const warranty = ['warranty', 'warrantyComplete']
  const recal = ['recal', 'recalComplete']
  const callBack = ['callBack', 'callBackComplete']
  const rAndI = ['rAndI', 'rAndIComplete']
  const tint = ['tint', 'tintComplete']
  const pickUp = ['pickUp', 'pickUpComplete']
  const dropOff = ['dropOff', 'dropOffComplete']
  if (standard.includes(current)) {
    return {
      quote: jobStatusConfig.quote,
      workOrder: jobStatusConfig.workOrder,
      invoice: jobStatusConfig.invoice,
      void: jobStatusConfig.void
    }
  }

  if (warranty.includes(current)) {
    return {
      warranty: jobStatusConfig.warranty,
      warrantyComplete: jobStatusConfig.warrantyComplete,
      void: jobStatusConfig.void
    }
  }

  if (recal.includes(current)) {
    return {
      recal: jobStatusConfig.recal,
      recalComplete: jobStatusConfig.recalComplete,
      void: jobStatusConfig.void
    }
  }

  if (callBack.includes(current)) {
    return {
      callBack: jobStatusConfig.callBack,
      callBackComplete: jobStatusConfig.callBackComplete,
      void: jobStatusConfig.void
    }
  }

  if (rAndI.includes(current)) {
    return {
      rAndI: jobStatusConfig.rAndI,
      rAndIComplete: jobStatusConfig.rAndIComplete,
      void: jobStatusConfig.void
    }
  }

  if (tint.includes(current)) {
    return {
      tint: jobStatusConfig.tint,
      tintComplete: jobStatusConfig.tintComplete,
      void: jobStatusConfig.void
    }
  }

  if (pickUp.includes(current)) {
    return {
      pickUp: jobStatusConfig.pickUp,
      pickUpComplete: jobStatusConfig.pickUpComplete,
      void: jobStatusConfig.void
    }
  }

  if (dropOff.includes(current)) {
    return {
      dropOff: jobStatusConfig.dropOff,
      dropOffComplete: jobStatusConfig.dropOffComplete,
      void: jobStatusConfig.void
    }
  }

  return {}
}

export const accountManagerStatusConfig = (current) => {
  const standard = ['draft', 'quote', 'workOrder', 'invoice']

  if (standard.includes(current)) {
    return {
      quote: jobStatusConfig.quote,
      workOrder: jobStatusConfig.workOrder,
      invoice: jobStatusConfig.invoice,
      void: jobStatusConfig.void
    }
  } else {
    const returnObj = {}
    switch (current) {
      case 'warranty':
        returnObj.warranty = jobStatusConfig.warranty
      case 'warrantyComplete':
        returnObj.warrantyComplete = jobStatusConfig.warrantyComplete
        break;
      case 'recal':
        returnObj.recal = jobStatusConfig.recal
      case 'recalComplete':
        returnObj.recalComplete = jobStatusConfig.recalComplete
        break;
      case 'callBack':
        returnObj.callBack = jobStatusConfig.callBack
      case 'callBackComplete':
        returnObj.callBackComplete = jobStatusConfig.callBackComplete
        break;
      case 'rAndI':
        returnObj.rAndI = jobStatusConfig.rAndI
      case 'rAndIComplete':
        returnObj.rAndIComplete = jobStatusConfig.rAndIComplete
        break;
      case 'tint':
        returnObj.tint = jobStatusConfig.tint
      case 'tintComplete':
        returnObj.tintComplete = jobStatusConfig.tintComplete
        break;
      case 'pickUp':
        returnObj['pickUp'] = jobStatusConfig['pickUp']
      case 'pickUpComplete':
        returnObj['pickUpComplete'] = jobStatusConfig['pickUpComplete']
        break;
      case 'dropOff':
        returnObj['dropOff'] = jobStatusConfig['dropOff']
      case 'dropOffComplete':
        returnObj['dropOffComplete'] = jobStatusConfig['dropOffComplete']
        break;
      default:
        break;
    }
    returnObj.void = jobStatusConfig.void
    return returnObj
  }
}
