var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "scheduler-container", attrs: { fluid: "" } },
    [
      _c("scheduler-header"),
      _vm.isListView
        ? _c("scheduler-body", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ]
          })
        : _c("scheduler-map", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ]
          }),
      _c("scheduler-job-details"),
      _c("scheduler-route-entry-details"),
      _c("tech-modal"),
      _c("scheduler-pdf-dialog", { attrs: { tech: _vm.pdfTech } }),
      _c("scheduler-waypoints-modal"),
      _c("scheduler-notifications")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }