<template>
  <el-dialog
    append-to-body
    top="80px"
    width="685px"
    custom-class="qbo-sync-dialog"
    :visible="visible"
    :before-close="close"
    :close-on-click-modal="false"
    @open="initialize"
  >
    <div class="t-flex-grow t-min-w-0">
      <Header />

      <Body
        :month.sync="month"
        :shopOptions="shopsWithSameQbo"
        :shops.sync="shops"
        @refresh="compareInvoices"
      />
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useQboSyncStore } from '@/components/modals/QuickBooksSyncDialog/qboSync.store.js'
import Header from './Header.vue'
import Body from './Body.vue'
import moment from 'moment'

export default {
  components: {
    Header,
    Body
  },

  data() {
    return {
      month: '',
      shops: [],
      inGlassBiller: [],
      notInGlassBiller: []
    }
  },

  computed: {
    ...mapState(useQboSyncStore, ['shop', 'fetching', 'visible', 'saving']),
    shopsWithSameQbo () {
      return this.$store.state.shops.filter(shop => shop.qbo_realm_id === this.shop.qbo_realm_id)
    }
  },

  watch: {
    month (val, oldVal) {
      if (val && val !== oldVal && !this.fetching) {
        this.compareInvoices()
      }
    },
    shops (val, oldVal) {
      if (val?.length > 0 && val !== oldVal && !this.fetching) {
        this.compareInvoices()
      }
    }
  },

  methods: {
    ...mapActions(useQboSyncStore, [
      'compareQboInvoices',
      'compareQboInvoice',
      'close'
    ]),
    initialize () {
      this.month = Date.now()
      this.shops = this.shopsWithSameQbo.map(shop => shop.id)
    },
    getMonthDateRange () {
      const temp = new Date(this.month)
      const month = temp.getMonth()
      const year = temp.getFullYear()
      const startDate = moment([year, month]).format('YYYY-MM-DD')
      const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD')

      return { start: startDate, end: endDate }
    },
    compareInvoices: async function () {
      const dates = this.getMonthDateRange()
      await this.compareQboInvoices(this.shops, dates)
    }
  }
}
</script>

<style lang="scss">
.el-dialog.qbo-sync-dialog {
  @apply t-font-sans t-rounded-xl;
  .el-dialog__header {
    @apply t-hidden;
  }

  .el-dialog__body {
    @apply t-flex t-p-0 t-overflow-y-hidden
  }

  .el-form-item {
    @apply t-mb-0;
  }

  .el-form-item__content {
    @apply t-leading-none;
  }
}
</style>
