var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pdf-type-select t-flex t-justify-between t-flex-wrap",
      class: {
        "t-flex-col": _vm.wrap,
        "t-items-end": _vm.end,
        "t-items-center": !_vm.end
      }
    },
    [
      _vm.template == null
        ? _c(
            "el-radio-group",
            {
              staticClass: "t-mr-2",
              attrs: { value: _vm.copy },
              on: {
                input: function($event) {
                  return _vm.$emit("update:copy", $event)
                }
              }
            },
            [
              _c(
                "el-radio",
                { style: _vm.style, attrs: { label: "withPrices" } },
                [_vm._v("With Prices")]
              ),
              _c(
                "el-radio",
                { style: _vm.style, attrs: { label: "noPrices" } },
                [_vm._v("No Prices")]
              ),
              _c(
                "el-radio",
                { style: _vm.style, attrs: { label: "customerCopy" } },
                [_vm._v("Customer Copy")]
              ),
              _c(
                "el-radio",
                { style: _vm.style, attrs: { label: "customerWithPrices" } },
                [_vm._v("Customer with prices")]
              ),
              _c(
                "el-radio",
                { style: _vm.style, attrs: { label: "insuranceCopy" } },
                [_vm._v("Insurance Copy")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: _vm.pdfDefault !== _vm.copy ? 18 : 24 } },
            [
              _vm.template == "select"
                ? _c(
                    "el-select",
                    {
                      attrs: { placeholder: "PDF type", size: "mini" },
                      on: {
                        input: function($event) {
                          return _vm.$emit("update:copy", $event)
                        }
                      },
                      model: {
                        value: _vm.selectedCopy,
                        callback: function($$v) {
                          _vm.selectedCopy = $$v
                        },
                        expression: "selectedCopy"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            _vm.pdfDefault === item.id
                              ? item.name + " (Default)"
                              : item.name,
                          value: item.id
                        }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.template === "select" &&
          _vm.pdfDefault !== _vm.copy &&
          _vm.userHasPermission &&
          _vm.allowSetDefault
            ? _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.makeDefault(_vm.selectedCopy)
                        }
                      }
                    },
                    [_vm._v(" Make default ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }