<template>
  <div class="t-flex-grow">
    <Channel v-if="view === 'view'"/>
    <Welcome v-if="view === 'welcome'"/>
    <BrowseChannels v-if="view === 'list'"/>
    <CreateChannel v-if="view === 'create'"/>
    <StartDirectChannel v-if="view === 'startDirectChannel'"/>
  </div>
</template>

<script>
import Welcome from './Welcome'
import Channel from './Channel/Index'
import CreateChannel from './CreateChannel'
import BrowseChannels from './BrowseChannels'
import StartDirectChannel from './StartDirectChannel'

export default {
  computed: {
    view: function() {
      return this.$store.state.chat.view
    }
  },
  components: {
    Channel,
    Welcome,
    CreateChannel,
    BrowseChannels,
    StartDirectChannel
  }
}
</script>
