<template>
  <el-dialog
    append-to-body
    width="700px"
    :visible.sync="localVisible"
    class="notification-setting-permissions-dialog"
    title="Notification Setting Permissions"
    @open="open"
    @close="close"
  >
    <span v-if="displayOverlay" class="overlay">
      <div @click="localVisible = false" class="close-dialog-overlay">
        <v-icon name="times" scale="1.1" />
      </div>
      <div class="overlay-text">The general notifications permission must be checked for these permissions to take effect</div>
    </span>
    <div class="permissions-container p-2">
      <div v-for="(category, index) in config" :key="`test-${index}`">
        <el-checkbox :indeterminate="indeterminates[category.type]" v-model="checkAlls[category.type]" @change="handleAllSettings($event, category.options, category.type)">
          <span style="font-weight:bold;">{{category.label}}</span>
        </el-checkbox>
        <div style="margin: 5px 0;"></div>
        <el-checkbox-group v-model="checkedNotificationSettings" @change="handleCheckedNotificationSettings(category.options, category.type)">
          <el-checkbox v-for="setting in category.options" :label="setting.value" :key="setting.value">{{setting.label}}</el-checkbox>
        </el-checkbox-group>
        <hr/>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :disabled="saving" @click="save">Save</el-button>
      <el-button style="background-color:black; color:white;" :disabled="saving" @click="localVisible = false">Cancel</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as R from 'ramda'
import User from '@/scripts/objects/user'
import {notificationPermissionsConfigSchema, notificationSettingPermissionIds} from '../../constants/notification-setting-permissions.config.js'

export default {
  props: ['visible', 'userId', 'userPermissions'],
  data () {
    return {
      config: notificationPermissionsConfigSchema,
      idsConfig: notificationSettingPermissionIds,
      displayOverlay: false,
      saving: false,
      checkedNotificationSettings: [],
      indeterminates: {
        job: false,
        scheduling: false,
        export: false,
        communications: false
      },
      checkAlls: {
        job: false,
        scheduling: false,
        export: false,
        communications: false
      }
    }
  },
  computed: {
    localVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    open() {
      // display overlay if needed
      if (!this.userPermissions.hasOwnProperty('notificationsettings')) {
        this.displayOverlay = true
      }
      var self = this
      // check boxes for individual settings
      this.checkedNotificationSettings = [...Object.keys(this.userPermissions)]
      // determine check alls and indeterminates
      for (var i = 0; i < self.config.length; i++) {
        let category = self.config[i]
        let confirmedChecked = []
        for (var j = 0; j < category.options.length; j++) {
          let tempIndex = self.checkedNotificationSettings.indexOf(category.options[j].value)
          if (tempIndex !== -1) {
            confirmedChecked.push(category.options[j].value)
          }
        }
        if (confirmedChecked.length === category.options.length) {
          self.checkAlls[category.type] = true
        } else if (confirmedChecked.length > 0) {
          self.indeterminates[category.type] = true
        }
      }
    },
    close() {
      this.displayOverlay = false
    },
    async save() {
      this.saving = true
      let newPermissions = []
      let settingKeys = Object.keys(this.idsConfig)
      for(var i = 0; i < settingKeys.length; i++) {
        if (this.checkedNotificationSettings.includes(settingKeys[i])) {
          newPermissions.push({name: settingKeys[i], role_id: this.idsConfig[settingKeys[i]]})
        }
      }
      const res = await User.updateNotificationSettingPermissions({userId: this.userId, permissions: newPermissions})
      this.saving = false
      if(res === 'success') {
        this.$message.success('Notification setting permissions updated')
      } else {
        this.$message.error('Something went wrong')
      }
      this.$emit('refreshPermissions')
      this.localVisible = false
    },
    handleAllSettings(val, settings, type) {
      // for 'check all' option in each category
      if (val) {
        for (var i = 0; i < settings.length; i++) {
          if (!this.checkedNotificationSettings.includes(settings[i].value)) {
            this.checkedNotificationSettings.push(settings[i].value)
          }
        }
      } else {
        this.checkedNotificationSettings = R.pipe(
          R.reject(R.contains(R.__, R.map(R.prop('value'))(settings)))
        )(this.checkedNotificationSettings)
      }
      this.indeterminates[type] = false
    },
    handlecheckedNotificationSettings(options, type) {
      // for checking individual settings
      let checkedCount = 0
      for (var i = 0; i < options.length; i++) {
        if (this.checkedNotificationSettings.includes(options[i].value)) {
          checkedCount++
        }
      }
      this.checkAlls[type] = checkedCount === options.length;
      this.indeterminates[type] = checkedCount > 0 && checkedCount < options.length;
    }
  }
}
</script>

<style lang='scss'>
.notification-setting-permissions-dialog {
  .permissions-container {
    border: 2px solid lightgray;
    border-radius: 5px;
  }
  .overlay {
    position:absolute;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    background-color:rgba(252, 252, 252, 0.8);
    z-index: 100;
  }
  .overlay-text {
    margin:30% 10%;
    text-align:center;
    font-weight:bold;
    font-size:24px;
    white-space:pre-wrap;
  }
  .close-dialog-overlay {
    cursor: pointer;
    position: absolute;
    right: 21px;
    top: 21px;
  }
}
</style>
