import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Tag extends ObjectTracker {
  id = -1
  created = -1
  modified = -1
  status = 'active'
  text = ''
  color = '#000000'
  details = ''
  default_tag = 0
  shop_id = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, callbackFunction, errorCallbackFunction = null) {
    Api({ url: `/api/shop/${shopId}/tag` }).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new Tag(val))
      })
      callbackFunction(objects)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
  createUpdateTag (shopId, replicateAcrossAllShops, callbackFunction, errorCallbackFunction = null) {
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    var dataObject = {}
    dataObject['tag'] = this
    dataObject['replicateAcrossAllShops'] = replicateAcrossAllShops
    return Api.post(`/api/shop/${this.shop_id}/tag/${this.id}`, dataObject).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
