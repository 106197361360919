<template>
  <b-alert :variant="variant" dismissible :show="showAlert" @dismissed="showAlert=false">
    <strong>{{title}}</strong> {{message}}
  </b-alert>
</template>

<script>
export default {
  data () {
    return {
      showAlert: false,
      title: '',
      message: '',
      variant: ''
    }
  },
  beforeDestroy: function () {
    this.$root.$off('showGlobalAlert', this.showGlobalAlertListener)
  },
  created () {
    this.$root.$on('showGlobalAlert', this.showGlobalAlertListener)
  },
  methods: {
    showGlobalAlertListener (title, message, variant = 'danger') {
      this.title = title
      this.message = message
      this.showAlert = true
      this.variant = variant
    }
  }
}
</script>

<style>

</style>
