<template>
  <el-table
    stripe
    size="small"
    row-class-name="t-cursor-pointer"
    highlight-current-row
    :data="data"
    @row-click="selected"
  >
    <el-table-column prop="searchLabel">
      <template slot-scope="props">
        <i class='fa fa-circle' :style='{"color": vendorColorMapping[props.row.type] || "#B103FC"}'/>&nbsp;{{props.row.searchLabel}}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {vendorColorMapping} from '@/scripts/helpers/gb_vendor.helpers.js'
export default {
  props: {
    data: Array
  },

  computed: {
    vendorColorMapping () {
      return vendorColorMapping
    }
  },

  methods: {
    selected(v) {
      this.$emit('selected', v)
    }
  }
}
</script>

<style>

</style>