import { checkColor } from './geometry.helpers'
// import { getTechSideSent } from './job.helpers'
import * as R from 'ramda'
import { shedulerColors } from '@/constants/sheduler.configs'
import SchedulerMapIcon from '@/components/SchedulerV3/helpers/map-icons/SchedulerMapIcon'

import Vue from 'vue'
import { mergeSpec } from './ramda.helpers'

/* global google */

export const decodePolylinePath = route => {
  if (google) {
    return R.pipe(
      R.prop('polyline'),
      google.maps.geometry.encoding.decodePath
    )(route)
  }
}

export const getPolylineOptions = route => ({
  path: decodePolylinePath(route),
  id: route.id,
  options: {
    strokeColor: checkColor(
      R.propOr(shedulerColors.default, 'color')(route)
    ),
    strokeOpacity: 1,
    strokeWeight: 4
  }
})

export const getPolylines = R.pipe(
  R.prop('data'),
  R.values,
  R.filter(R.allPass([R.prop('polyline'), R.complement(R.prop('hidden'))])),
  R.map(getPolylineOptions)
)

const getJobAddress = R.path(['job', 'install_g_address'])

const getJobLatLng = R.pipe(
  getJobAddress,
  R.when(R.identity, R.pick(['lat', 'lng']))
)

const getJobState = R.pipe(
  getJobAddress,
  R.when(R.identity, R.prop('state'))
)

const getEntryState = R.cond([[R.prop('job'), getJobState]])

const getEntryLocation = R.cond([[R.prop('job'), getJobLatLng]])

// create a constructor from a Vue component
const LocationIconConstructor = Vue.extend(SchedulerMapIcon)

export const getLocationIcon = (route, index, route_entry) => {
  const success = R.path(['job', 'techside_sent'])(route_entry)
  const fillColor = R.prop('color')(route)
  const count = route.id !== 1 && index + 1
  const iconComponent = new LocationIconConstructor({
    propsData: { fillColor, count, type: route_entry.type, success }
  })
  iconComponent.$mount()
  const iconDom = iconComponent.$el
  const iconString = new XMLSerializer().serializeToString(iconDom)
  return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString)
}

const mapIndexed = R.addIndex(R.map)
export const getRouteMarkers = route =>
  R.pipe(
    R.prop('route_entries'),
    R.filter(getEntryLocation),
    mapIndexed((v, idx) =>
      mergeSpec({
        // type: getMarkerType,
        position: getEntryLocation,
        icon: {
          url: () => getLocationIcon(route, idx, v),
          anchor: () => ({ x: 12, y: 29 })
        }
      })(v)
    )
  )(route)

export const getRouteStates = R.pipe(
  R.values,
  R.map(R.prop('route_entries')),
  R.unnest,
  R.map(getEntryState),
  R.uniq,
  R.filter(R.identity)
)
export const getUnmappedJobs = R.pipe(
  R.prop('data'),
  R.values,
  R.map(v =>
    R.pipe(
      R.prop('route_entries'),
      R.filter(R.prop('job')),
      R.map(R.merge({ color: R.path(['tech', 'color'])(v) }))
    )(v)
  ),
  R.unnest,
  R.filter(
    R.pipe(
      getEntryState,
      R.not
    )
  )
)

export const getStateBounds = state =>
  R.pipe(
    R.values,
    R.filter(
      R.pipe(
        R.prop('route_entries'),
        R.any(
          R.pipe(
            getEntryState,
            R.equals(state)
          )
        )
      )
    ),
    R.map(R.prop('bounds')),
    R.reduce(
      (totalBounds, bounds) =>
        bounds
          ? totalBounds.union(
            new google.maps.LatLngBounds(bounds.southwest, bounds.northeast)
          )
          : totalBounds,
      new google.maps.LatLngBounds()
    )
  )
