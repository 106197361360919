<template>
  <div class="gb-form" v-focusout="focusout" ref="ref">
    <el-row
      class="gb-form-row"
      v-for="(row, idx) in schema"
      :key="idx"
      :gutter="16"
    >
      <el-divider v-if="row.divider" content-position="left">
        <span v-if="row.divider !== true">{{ row.divider }}</span>
      </el-divider>
      <el-col
        :key="col.id"
        v-for="(col) in row"
        :span="24 / row.length"
        v-else
      >
        <input-group v-if="col.type === 'group'" v-bind="col" v-model="model" />
        <el-switch
          v-else-if="col.type === 'switch'"
          :value="getValue(col)"
          @input="setValue($event, col)"
          v-bind="col.props"
        />
        <el-checkbox
          v-else-if="col.type === 'checkbox'"
          v-bind="checkboxAttributes(col)"
          border
          size="mini"
          :class="{ full: col.full }"
          :value="getValue(col)"
          @input="setValue($event, col)"
          :disabled="col.disabled"
        >
          {{ col.label }}
        </el-checkbox>
        <form-table
          v-else-if="col.type === 'table'"
          :cols="col.cols"
          :data="col.data"
        />
        <vue-editor
          v-else-if="col.type === 'wysiwyg'"
          :value="getValue(col)"
          :editorOptions="col.editorOptions"
          @input="setValue($event, col)"
          @selection-change="col.onSelectionChange"
          @focus="col.onFocus"
        />
        <input-with-label
          v-else
          v-bind="col"
          :value="getValue(col)"
          @input="setValue($event, col)"
          @change="setValue($event, col)"
          :disabled="col.disabled && col.disabled(model)"
          @focus="onFocus"
          @blur="onBlur($event, col)"
          :size="size"
          :error="getError(col)"
          :valid="getValid(col)"
          :classes="col.classes ? col.classes(model) : ''"
          :readOnly="col.readOnly && col.readOnly(model)"
          ref="inputs"
          lazy
        >
          <v-icon
            :name="col.icon"
            size="md"
            slot="prefix"
            v-if="col.icon"
            class="icon"
          />
        </input-with-label>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as R from "ramda";
import FormTable from "./FormTable/Index.vue";
import Schema from "async-validator";
import { VueEditor, Quill } from "vue2-editor";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";

var fonts = Quill.import('formats/font');
fonts.whitelist = ['arial', 'courier', 'dancing', 'garamond', 'times', 'trebuchet', 'verdana'];
var alignStyle = Quill.import('attributors/style/align');

Quill.register(
  fonts,
  true
)

Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji
  },
  true
);

Quill.register(alignStyle, true);

export default {
  data() {
    return {
      touched: {},
      errors: {},
    };
  },
  components: {
    FormTable,
    VueEditor
  },
  props: {
    value: Object,
    schema: {
      type: Array,
      required: true,
    },
    values: Object,
    size: String,
    rules: Object,
    errorFields: Array,
    autoFocus: Boolean,
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    async focusout() {
      this.$emit("focusout", await this.validateAll());
    },
    checkboxAttributes(col) {
      var attributes = {};
      if ("true_label" in col) {
        attributes["true-label"] = col.true_label;
      }
      if ("false_label" in col) {
        attributes["false-label"] = col.false_label;
      }
      return attributes;
    },
    focusout() {
      setTimeout(async () => {
        if (this.$refs.ref) {
          // focusing out of component, save if it's filled up
          if (!this.$refs.ref.contains(document.activeElement)) {
            if (await this.validateAll()) {
              this.$emit("focusout", this.model);
            } else {
              this.$emit("clickaway");
            }
          }
        }
      }, 100);
    },
    getValue(col) {
      return col.noKey ? this.model : R.path(R.split(".")(col.id))(this.model);
    },
    setValue(value, col) {
      if (col.noKey) {
        this.model = value;
      } else {
        const lens = R.pipe(
          R.split("."),
          R.map(R.unless(isNaN, (v) => +v)),
          R.lensPath
        )(col.id);
        this.model = R.set(lens, value)(this.model);
      }
      if (this.touched[col.id]) {
        this.validateField(col, value);
      }
    },
    submit() {
      return this.model;
    },
    onSelectionChange(event) {
      this.$emit("selection-change", event);
    },
    onFocus(event) {
      this.$emit("focus", event);
    },
    onBlur(value, col) {
      this.validateField(col, this.getValue(col));
      this.touched = { ...this.touched, [col.id]: true };
      this.$emit("blur", { value, key: col.id });
    },
    async validateField(col, val) {
      const value = val || this.getValue(col);
      if (col.rule) {
        const validator = new Schema({ [col.id]: col.rule });
        try {
          await validator.validate(
            { [col.id]: value },
            { suppressWarning: true }
          );
          this.errors = R.dissoc(col.id, this.errors);
        } catch (error) {
          this.errors = {
            ...this.errors,
            [col.id]: R.path(["fields", col.id, 0, "message"])(error),
          };
        }
      }
    },
    async validateAll() {
      await Promise.all(
        R.pipe(R.flatten, R.map(this.validateField))(this.schema)
      );
      return R.isEmpty(this.errors);
    },
    resetForm() {
      this.errors = {};
      this.touched = {};
    },
    getError(field) {
      return this.errors[field.id];
    },
    getValid(field) {
      if (this.touched[field.id] && !this.getError(field)) {
        return !!this.isRequired(field) || !!this.getValue(field);
      }
    },
    isRequired(field) {
      return R.pipe(
        R.propOr([], "rule"),
        R.of,
        R.flatten,
        R.find(R.prop("required")),
        (v) => !!v
      )(field);
    },
  },
  watch: {
    values(data) {
      this.model = R.clone(data);
    },
  },
  mounted() {
    if (this.autoFocus) {
      try {
        this.$refs.inputs[0].$refs.inputRef.focus();
      } catch {}
    }
  },
};
</script>

<style lang="scss" scoped>
.gb-form {
  .gb-form-row {
    margin-bottom: $padding-md;
    .el-checkbox.is-bordered {
      background: #fff;
      &.full {
        width: 100%;
      }
    }
  }
  .el-divider--horizontal {
    margin: 12px 0;
  }
  .el-checkbox__label {
    color: #7d8087;
  }
}
</style>

<style lang="scss">
  .gb-form-row {
    .ql-editor {
      word-break: keep-all;
    }
  }
</style>

<style lang='scss'>
/* Set droplist names - -item is the currently selected font, -label is the font's appearance in the droplist*/
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='']::before
{
  content: 'Default';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times']::before
{
  content: 'Times New Roman';
  font-family: 'Times New Roman', Times, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before
{
  content: 'Arial';
  font-family: Arial, Helvetica, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='garamond']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='garamond']::before
{
  content: 'Garamond';
  font-family: 'Garamond', serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='dancing']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='dancing']::before
{
  content: 'Dancing Script';
  font-family: 'Dancing Script', cursive;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='courier']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='courier']::before
{
  content: 'Courier New';
  font-family: 'Courier New', monospace;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='verdana']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before
{
  content: 'Verdana';
  font-family: 'Verdana', Helvetica, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='trebuchet']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='trebuchet']::before
{
  content: 'Trebuchet MS';
  font-family: 'Trebuchet MS', Helvetica, sans-serif;
}

/****************************************************
Set the font-family content used for the HTML content.
*****************************************************/
.ql-font-arial {
  font-family: Arial, Helvetica, sans-serif;
}

.ql-font-times {
  font-family: 'Times New Roman', Times, serif;
}

.ql-font-dancing {
  font-family: "Dancing Script", cursive;
}

.ql-font-verdana {
  font-family: "Verdana", Helvetica, sans-serif;
}

.ql-font-trebuchet {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

.ql-font-garamond {
  font-family: Garamond, serif;
}

.ql-font-courier {
  font-family: "Courier New", monospace;
}

.ql-snow .ql-picker.ql-font {
  width: 175px !important;
}
</style>
