<template>
  <div
    class="gb-tag"
    :class="{
      [color]: color,
      outline,
      'tag-hidden': hidden,
      disabled,
      'no-margin': noMargin,
      small,
    }"
    :style="{ color: hex }"
  >
    <span>
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    outline: Boolean,
    hidden: Boolean,
    disabled: Boolean,
    hex: String,
    noMargin: Boolean,
    small: Boolean
  }
}
</script>

<style lang="scss" scoped>
.gb-tag {
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  padding: 0 $padding-xs;
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: #bcbcbc;
  margin-left: $padding-xs;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  cursor: default;
  white-space: nowrap;
  &.no-margin {
    margin-left: 0;
  }
  &.green {
    color: $jade-green;
  }
  &.blue {
    color: $air-force-blue;
  }
  &.orange {
    color: $gold-orange;
  }
  &.dark {
    color: $grayscale-8;
  }
  &.dark {
    color: $grayscale-8;
  }
  &.sky-blue {
    color: $sky-blue;
  }
  &.red {
    color: $crimson-red;
  }
  &.gold-orange {
    color: $gold-orange;
  }
  &.purple {
    color: $royal-purple;
  }
  &.teal {
    color: $forest-teal;
  }
  &.burnt-orange {
    color: $burnt-orange;
  }
  &.silver {
    color: $grayscale-6;
  }
  &.light-orange {
    color: $light-orange;
  }
  &.brown {
    color: $brown;
  }
  &.call-back-blue {
    color: $call-back-blue;
  }
  &.r-and-i-teal {
    color: $r-and-i-teal;
  }
  &.recal-purple {
    color: $recal-purple;
  }
  &.tint-brown {
    color: $tint-brown;
  }
  &.pick-up-blue {
    color: $pick-up-blue;
  }
  &.drop-off-purple {
    color: $drop-off-purple;
  }
  &.call-back {
    color: $call-back-blue;
  }
  &.r-and-i {
    color: $r-and-i-teal;
  }
  &.recal {
    color: $recal-purple;
  }
  &.tint {
    color: $tint-brown;
  }
  &.pick-up {
    color: $pick-up-blue;
  }
  &.drop-off {
    color: $drop-off-purple;
  }
  &.supplemental-invoice-green {
    color: $supplemental-invoice-green;
  }
  background: currentColor;
  border: 1px solid currentColor;
  span {
    color: white;
  }
  &.outline {
    span {
      color: currentColor;
    }
    background: #fff;
  }
  &:not(.disabled) {
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 3px 0px currentColor;
    }
  }
  &.gb-tag {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
  &.tag-hidden {
    font-size: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
}
</style>
