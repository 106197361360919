var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pdf-option-card",
      class: {
        "not-visible": !!!_vm.option.visible
      },
      style: _vm.cardStyle,
      on: { click: _vm.card.action }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.snakeCaseToTitle(_vm.card.title)))]),
      _c(
        "span",
        { staticClass: "float-right" },
        [
          _c("el-checkbox", {
            model: {
              value: _vm.isChecked,
              callback: function($$v) {
                _vm.isChecked = $$v
              },
              expression: "isChecked"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }