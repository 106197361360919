import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Jobtax extends ObjectTracker {
  id = -1
  created = -1
  shop_tax_id = -1
  job_id = -1
  modified = -1
  tax_co_account_id = -1
  amount = 0

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static createTemporaryJobTax (id, shopTaxId, jobId) {
    var jobtax = new Jobtax()

    jobtax.id = id
    jobtax.shop_tax_id = shopTaxId
    jobtax.job_id = jobId

    return jobtax
  }

  static getJobTaxes (shopId, jobId, callbackFunction) {
    
    Api({ url: `/api/shop/${shopId}/jobtax/${jobId}` }).then(res => {
      
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new Jobtax(val))
      })
      
      callbackFunction(objects)
    })
  }

  createUpdateJobTax (shopId, callbackFunction, errorCallbackFunction = null) {
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }

    // if (job) {
    //   this.job_id = job.id
    // }

    return Api.post(`/api/shop/${this.shop_id}/jobtax/${this.job_id}`, this).then(res => {
      
      
      callbackFunction(res.data)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  // createUpdateJobTaxBasedOnTax (shopId, shopTax, job, callbackFunction, errorCallbackFunction = null) {
  //   
  //   if (!this.id || this.id <= 0) {
  //     this.shop_id = shopId
  //     this.id = -1
  //     this.shop_tax_id = shopTax.id
  //     this.job_id = job.id
  //   }

  //   return Api.post(`/api/shop/${this.shop_id}/jobtax`, this).then(res => {
  //     
  //     
  //     callbackFunction(res.data)
  //   }, function (error) {
  //     
  //     if (errorCallbackFunction) {
  //       errorCallbackFunction(error)
  //     }
  //   })
  // }

  deleteJobTax (callbackFunction, errorCallbackFunction = null) {
    if (this._is_saved) {
      
      if (!this.id || this.id <= 0) {
        callbackFunction()
      }
      return Api.delete(`/api/shop/${this.shop_id}/tax/${this.id}`, this).then(res => {
        
        
        callbackFunction()
      }, function (error) {
        
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      })
    }
  }
}
