<template>
  <div class="t-flex">
    <div class="t-flex-grow">
      <div class="t-text-gray-400 t-mt-2">Address</div>

      <el-form
        :model="form"
        ref="form"
        class="t-space-y-6"
        @submit.prevent.native
      >
        <div
          v-for="(i, index) in form.addresses"
          :key="index"
          class="t-flex t-items-center t-space-x-3 first:t-mt-6"
        >
          <el-form-item
            :prop="'addresses.' + index + '.address'"
            :rules="{
              required: true,
              trigger: 'blur'
            }"
            :show-message="false"
          >
            <address-input
              v-model="form.addresses[index].address"
              class="vendor-profile-address"
              :with-delivery-instructions="false"
              @change="setAddress(index, $event)"
            />
          </el-form-item>

          <el-tooltip content="Remove Address" placement="top">
            <i
              class="material-icons-outline md-cancel t-text-base t-text-red-400 t-cursor-pointer"
              @click="deleteAddressHandler(index)"
            />
          </el-tooltip>

          <el-tooltip
            v-if="i.id"
            :content="
              i.id === data.g_address_id
                ? 'Primary Address'
                : 'Set as Primary Address'
            "
            placement="top"
          >
            <i
              :class="
                i.id === data.g_address_id
                  ? 'md-radio_button_checked t-text-blue-500'
                  : 'md-radio_button_unchecked t-text-gray-400'
              "
              class="material-icons-outline t-text-base t-cursor-pointer"
              @click="setPrimaryAddressHandler(i.id)"
            />
          </el-tooltip>
        </div>
      </el-form>
    </div>

    <div class="t-flex t-flex-col t-justify-end">
      <el-button
        plain
        icon="el-icon-circle-plus-outline"
        @click="addAddress"
      >
        Add
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import AddressInput from '@/components/Helpers/AddressInput.vue'

export default {
  components: { AddressInput },

  data() {
    return {
      form: {
        addresses: []
      }
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data'])
  },

  mounted() {
    this.form.addresses = this.data.addresses.map((i) => {
      return {
        id: i.id,
        address: cloneDeep(i)
      }
    })
    this.$nextTick(async () => {
      try {
        await this.$refs.form.validate()
      } catch {
      }
    })
  },

  methods: {
    ...mapActions(
      useVendorStore,
      ['createAddress', 'updateAddress', 'deleteAddress', 'setPrimaryAddress']
    ),

    addAddress() {
      this.form.addresses.push({
        id: null,
        address: null
      })
    },

    setAddress(index, address) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const addressId = this.form.addresses[index].id
          try {
            if (addressId) {
              await this.updateAddress(addressId, address)
            } else {
              const addressId = await this.createAddress(address)

              this.form.addresses[index].id = addressId

              if (this.form.addresses.length === 1) {
                await this.setPrimaryAddressHandler(addressId)
              }
            }
          } catch (e) {
            this.$message.error(e.message)
          }
        }
      })
    },

    async deleteAddressHandler(index) {
      const addressId = this.form.addresses[index].id
      if (addressId) {
        try {
          await this.deleteAddress(addressId)
        } catch (e) {
          this.$message.error(e.message)
        }
      }

      Vue.delete(this.form.addresses, index)
    },

    async setPrimaryAddressHandler(id) {
      try {
        await this.setPrimaryAddress(id)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
.vendor-profile-address {
  .address-unit {
    .el-input__prefix {
      @apply t-mt-3;
    }
  }
}
</style>
