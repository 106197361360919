var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-preview-actions" },
    [
      _c(
        "el-button",
        {
          attrs: { size: "mini", icon: "las la-download" },
          on: { click: _vm.dl }
        },
        [_vm._v(" Download ")]
      ),
      _c(
        "el-button",
        {
          attrs: { size: "mini", icon: "las la-external-link-alt" },
          on: { click: _vm.open }
        },
        [_vm._v(" Open in new tab ")]
      ),
      !_vm.noDelete
        ? _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                icon: "las la-download",
                type: "danger",
                plain: "",
                loading: _vm.deleting
              },
              on: { click: _vm.deleteItem }
            },
            [_vm._v(" Delete ")]
          )
        : _vm._e(),
      _c("a", { ref: "open", attrs: { href: _vm.doc.url, target: "_blank" } }),
      _c("a", {
        ref: "dl",
        attrs: { href: "" + _vm.doc.url + _vm.dlParam, download: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }