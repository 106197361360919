<template>
  <div>
    <div class="performance-chart-bars">
      <div
        class="performance-chart-bar"
        :style="{ width: getWidth(bar.count) }"
        v-for="bar in bars"
        :key="bar.status"
      >
        <div
          class="performance-chart-bar-value"
          :style="{ background: bar.color }"
        ></div>
      </div>
    </div>
    <div class="performance-chart-labels">
      <div class="performance-chart-label" v-for="bar in bars" :key="bar.label">
        <i class="material-icons md-lens md-18" :style="{ color: bar.color }" />
        <div class="performance-chart-label-text">
          {{ millify(bar.count, { precision: 0, lowercase: true }) }}
        </div>

        <div>
          {{ bar.status }}
        </div>
        <div class="performance-chart-label-percent">
          {{ getWidth(bar.count) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import millify from 'millify'
import { statusColor } from '@/constants/support-ticket.config.js'
import CollapseTopTransVue from '../../../Helpers/transitions/CollapseTopTrans.vue'

export default {
  props: ['chartData'],
  data() {
    return {
      sum: 0
    }
  },
  computed: {
    bars() {
    if (!this.chartData) return []
      const returnData = []
      for (var i = 0; i < this.chartData.length; i++) {
        returnData.push({
          count: this.chartData[i].count,
          status: this.chartData[i].status,
          color: statusColor(this.chartData[i].status)
        })
        this.sum += this.chartData[i].count
      }

      return returnData.sort((a, b) => a.status < b.status && 1 || -1)
    }
  },
  methods: {
    getWidth(value) {
      return `${Math.round(this.chartData ? (value / this.sum) * 100 : 33)}%`
    },
    millify
  },
  watch: {
    chartData() {
      this.sum = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.performance-chart-bars {
  height: 24px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  margin: $padding-xs 0;
  .performance-chart-bar {
    padding-left: 2px;
    .performance-chart-bar-value {
      width: 100%;
      height: 100%;
    }
  }
}
.performance-chart-label {
  display: flex;
  align-items: center;
  & + .performance-chart-label {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .performance-chart-label-text {
    font-size: 18px;
    line-height: 24px;
    min-width: 55px;
    margin: 0 $padding-md;
  }
  .performance-chart-label-percent {
    margin-left: auto;
  }
}
</style>
