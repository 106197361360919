const state = {
  isOpen: false,
  filters: {
    cardView: 0,
    date: new Date(),
    shops: [],
    view: 'list',
    rowView: false,
    warehouses: {
      mygrant: true,
      pilk: true,
      pgw: true,
      igc: true,
      service: true,
      other: true
    },
    solidCards: false,
    weekOffset: 0,
    calendarSort: 'techs',
    showTotals: false,
    dayCounters: {
      morning: true,
      'mid day': true,
      afternoon: true,
      'time not selected': true
    },
    jobConfirmationDetailsVisible: false,
    context: 'all'
  },
  activeJobListFilter: {
    label: 'Unscheduled',
    value: 'unscheduled'
  },
  data: {},
  techs: [],
  bays: [],
  jobDetails: null,
  geometry: [],
  showUnmapped: false,
  loading: false,
  bounds: null,
  warehouses: [],
  draggingShopId: null,
  editTech: null,
  waypointLoading: false,
  jobDetailsLoading: false,
  count: null,
  dataToUpdate: {},
  techTotals: {}
}

export default state
