var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-divider", { staticClass: "t-my-6" }),
      _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
        _vm._v("Short Code")
      ]),
      _c("el-input", {
        attrs: { placeholder: "Enter Short Code" },
        on: { change: _vm.updateShortCodeHandler },
        model: {
          value: _vm.short_code,
          callback: function($$v) {
            _vm.short_code = $$v
          },
          expression: "short_code"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }