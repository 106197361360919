<template>
  <div class="edi-lock-container">
    <slot />
    <div class="edi-lock-icon">
      <i class="material-icons md-lock" />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.edi-lock-container {
  position: relative;
  pointer-events: none;
  .edi-lock-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    right: -6px;
    top: -6px;
    color: $watermelon-pink;
    border: 1px solid $watermelon-pink;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 12px;
    }
  }
}
</style>
