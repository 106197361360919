<template>
  <el-dialog
    width="600px"
    append-to-body
    :visible.sync="visible"
    custom-class="shopping-cart-dialog">
    <span slot="title" class="t-text-xl t-text-gray-800">Order details</span>
    <div
      style="max-height: 600px; overflow-y: auto;"
      class="t-p-4">
      <div v-if="loading" class="t-p-12 t-text-center t-text-3xl t-text-blue-600">
        <i class="el-icon-loading" />
      </div>
      <div v-else>
        <div v-if="order" class="t-flex t-break-normal t-mb-6 t-mx-3">
          <div class="t-w-40 t-text-gray-500 t-mr-2 t-text-right t-space-y-1">
            <div>Account number</div>
            <div>Order number</div>
            <div>Bill to name</div>
            <div>Bill to address</div>
            <div>Ship method</div>
            <div>Ship date</div>
            <div>Ship to name</div>
            <div>Ship to address</div>
            <div>Order subtotal</div>
            <div>Shipping cost</div>
            <div>Tax</div>
            <div>Order total</div>
            <div v-if="order.comments">Order comments</div>
          </div>
          <div class="t-space-y-1">
            <div>{{ order.accountNumber }}</div>
            <div>{{ order.orderNumber }}</div>
            <div>{{ order.billToName }}</div>
            <div>{{ order.billToAddress }}</div>
            <div>{{ order.shipViaCode === 'DEL' ? 'Delivery' : 'Will Call' }}</div>
            <div>{{ formatShipDate(order.shipDate) }}</div>
            <div>{{ order.shipToName }}</div>
            <div>{{ order.shipToAddress }}</div>
            <div>${{ order.orderSubtotal }}</div>
            <div>${{ order.shippingCost }}</div>
            <div>${{ order.tax }}</div>
            <div>${{ order.orderTotal }}</div>
            <div v-if="order.comments">{{ order.comments }}</div>
          </div>
        </div>
        <div
          v-for="item in items"
          :key="item.id"
          class="t-px-3 t-py-2 t-rounded t-border t-border-solid
                t-border-gray-400 t-bg-gray-100 t-mb-2">
          <div class="t-flex">
            <div class="t-flex-grow">
              <div class="t-flex t-text-base">
                <div class="t-mr-1 t-font-bold">
                  {{ item.item_number }} {{ item.item_prefix }}
                </div>
              </div>
            </div>
            <div>
              <div class="t-flex">
                <div>{{ item.quantity }}</div>
                <div class="t-mx-2">x</div>
                <div class="t-font-bold">
                  ${{ Math.round(item.item_price * 100) / 100 }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.item_description">
            <el-divider class="t-my-2" />
            <div class="t-flex">
              <span class="t-text-gray-600 t-text-sm t-mr-3 t-break-normal">
                Description:
              </span>
              <span class="t-break-normal">{{ item.item_description }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import {
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'

export default {
  computed: {
    order() {
      return this.$store.state.shoppingCart.orders.orderDetails
    },
    items() {
      return this.$store.state.shoppingCart.orders.orderItems
    },
    loading() {
      return this.$store.state.shoppingCart.orders.orderItemsFetching
    },
    visible: {
      get() {
        return this.$store.state.shoppingCart.orders.americanOrderDetailsIsOpen
      },
      set(value) {
        return this.$store.commit(
          shoppingCartMutations.orders.setAmericanOrderDetailsIsOpen,
          value
        )
      }
    }
  },
  methods: {
    formatShipDate(dt) {
      return moment(dt).format('MMM Do, YYYY')
    }
  }
}
</script>
