<template>
  <el-dialog
    append-to-body
    width="1000px"
    :visible="visible"
    custom-class="acknowledge-alert"
    :show-close="false"
    top="20px"
  >
    <div v-if='title' slot='title'>
      <h3>{{title}}</h3>
    </div>
    <div class='message-container' ref='outter' @scroll='handleScroll'>
      <span v-for='(message, index) in messages' :key='"alert-messages-"+index'>
        <component :is="message"/>
      </span>
    </div>
    <div slot='footer'>
      <span v-if='!scrolledToBottom' class='please-scroll'>Please scroll to the bottom of Terms to Accept</span>
      <el-button
        :disabled="!scrolledToBottom"
        @click='$emit("acknowledged", 1)'
      >
        {{button_text}} 
      </el-button>
      <el-button v-if='!required' @click='visible = false'>Cancel</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ['title', 'messages', 'visible', 'required', 'button_text'],
  data() {
    return {
      scrolledToBottom: false
    }
  },
  methods: {
    handleScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        this.scrolledToBottom = true
      }
    },
  },
  mounted() {
    var self = this
    this.$nextTick(() => {
      self.scrolledToBottom = (self.$refs.outter ? self.$refs.outter.scrollWidth : null) === 
        (self.$refs.outter ? self.$refs.outter.offsetWidth : null)
    })
  }
}
</script>

<style lang="scss" scoped>
.message-container {
  max-height: 65vh;
  overflow: auto;
  & ::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: $scheduler-border-color;
  }
}

.acknowledge-alert {
  max-width: 100vw;
}
.please-scroll {
  margin-right: 15px;
  font-size: 14px;
}
</style>