var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "t-flex t-items-center t-my-6" },
        [
          _c(
            "div",
            {
              staticClass:
                "t-flex t-flex-grow t-space-x-6 t-items-center t-cursor-pointer",
              on: {
                click: function($event) {
                  return _vm.$emit("preview")
                }
              }
            },
            [
              _vm.isImage
                ? _c(
                    "div",
                    {
                      staticClass:
                        "t-flex t-items-center t-justify-center t-h-10 t-w-10\n            t-rounded-md t-bg-orange-100 t-text-orange-600"
                    },
                    [
                      _c("i", {
                        staticClass:
                          "material-icons-outline md-image t-text-2xl t-font-light"
                      })
                    ]
                  )
                : _vm.isVideo
                ? _c(
                    "div",
                    {
                      staticClass:
                        "t-flex t-items-center t-justify-center t-h-10 t-w-10\n            t-rounded-md t-bg-red-100 t-text-red-600"
                    },
                    [
                      _c("i", {
                        staticClass:
                          "material-icons-outline md-videocam t-text-2xl t-font-light"
                      })
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "t-flex t-items-center t-justify-center t-h-10 t-w-10\n            t-rounded-md t-bg-blue-100 t-text-blue-600"
                    },
                    [
                      _c("i", {
                        staticClass:
                          "material-icons-outline md-assignment t-text-2xl t-font-light"
                      })
                    ]
                  ),
              _c("div", { staticClass: "t-flex t-flex-grow" }, [
                _c("div", { staticClass: "t-flex t-flex-col t-flex-grow" }, [
                  _c("div", { staticClass: "t-text-gray-700" }, [
                    _vm._v(_vm._s(_vm.document.document_name))
                  ]),
                  _c("div", { staticClass: "t-text-gray-300" }, [
                    _vm._v(" " + _vm._s(_vm.listDate) + " "),
                    _vm.document.created_by_name
                      ? _c("span", [
                          _vm._v(
                            " by " + _vm._s(_vm.document.created_by_name) + " "
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass: "t-flex t-items-center t-flex-shrink-0 t-w-48"
                  },
                  [
                    _c("div", {
                      staticClass: "t-w-2 t-h-2 t-rounded-full",
                      style: "background: " + _vm.document.document_tag_color
                    }),
                    _c("div", { staticClass: "t-ml-2" }, [
                      _vm._v(_vm._s(_vm.document.document_tag_name) + ",")
                    ]),
                    _c("div", { staticClass: "t-pl-2" }, [
                      _vm._v(_vm._s(_vm.size))
                    ])
                  ]
                )
              ])
            ]
          ),
          _c(
            "el-dropdown",
            { staticClass: "t-shrink-0", attrs: { trigger: "click" } },
            [
              _c("i", {
                staticClass:
                  "material-icons-outline md-menu el-dropdown-link t-mx-3\n          t-text-base t-text-gray-500 t-cursor-pointer"
              }),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "div",
                      {
                        staticClass: "t-flex t-items-center",
                        on: { click: _vm.openInNewTab }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "material-icons-outline md-dynamic_feed t-text-gray-400 t-text-base"
                        }),
                        _c("div", { staticClass: "t-ml-2" }, [
                          _vm._v("Open in new tab")
                        ])
                      ]
                    )
                  ]),
                  _c("el-dropdown-item", [
                    _c(
                      "div",
                      {
                        staticClass: "t-flex t-items-center",
                        on: { click: _vm.download }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "material-icons-outline md-cloud_download t-text-blue-400 t-text-base"
                        }),
                        _c("div", { staticClass: "t-ml-2" }, [
                          _vm._v("Download")
                        ])
                      ]
                    )
                  ]),
                  _vm.isImage
                    ? _c("el-dropdown-item", [
                        _c(
                          "div",
                          {
                            staticClass: "t-flex t-items-center",
                            on: {
                              click: function($event) {
                                return _vm.$emit("createAvatar")
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "material-icons-outline md-account_circle t-text-orange-400 t-text-base"
                            }),
                            _c("div", { staticClass: "t-ml-2" }, [
                              _vm._v("Create avatar")
                            ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _c("el-dropdown-item", [
                    _c(
                      "div",
                      {
                        staticClass: "t-flex t-items-center",
                        on: { click: _vm.remove }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "material-icons-outline md-delete t-text-red-400 t-text-base"
                        }),
                        _c("div", { staticClass: "t-ml-2" }, [_vm._v("Delete")])
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }