var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "edit-text-confirmation-dialog",
      attrs: {
        center: "",
        "append-to-body": "",
        "close-on-click-modal": false,
        title:
          _vm.template === null ? "Create a template" : "Update a template",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        width: _vm.form.type_id === 8 ? "45%" : "70%"
      }
    },
    [
      _c(
        "el-row",
        [
          _vm.form.type_id !== 8
            ? _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-tabs",
                    { attrs: { "tab-position": "left" } },
                    [
                      _c("el-tab-pane", { attrs: { label: "CUSTOMER" } }, [
                        _c("p", [
                          _vm._v("Company name: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{customer company name}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("First: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{customer first}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Last: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{customer last}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Phone: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{customer phone}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Email: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{customer email}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Address: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{customer address}")]
                          )
                        ])
                      ]),
                      _c("el-tab-pane", { attrs: { label: "BILL-TO" } }, [
                        _c("p", [
                          _vm._v("Company name: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{bill-to company name}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("First: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{bill-to first}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Last: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{bill-to last}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Phone: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{bill-to phone}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Email: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{bill-to email}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Address: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{bill-to address}")]
                          )
                        ])
                      ]),
                      _c("el-tab-pane", { attrs: { label: "SCHEDULE" } }, [
                        _c("p", [
                          _vm._v("Date: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{schedule date}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Day: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{schedule day}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Start time: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{schedule start time}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("End time: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{schedule end time}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Phone: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{schedule contact phone}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Contact name: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{schedule contact name}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Address: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{schedule customer address}")]
                          )
                        ])
                      ]),
                      _c("el-tab-pane", { attrs: { label: "BILLING INFO" } }, [
                        _c("p", [
                          _vm._v("Job number: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{job-id #}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Referral: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{referral #}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Policy number: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{policy #}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Loss date: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{loss date}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Dispatch date: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{dispatch date}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Install date: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{install date}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Deductible: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{deductible $}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("PO number: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{PO #}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("RO number: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{RO #}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("DOT number: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{DOT #}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Lot number: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{lot #}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Stock number: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{stock #}")]
                          )
                        ])
                      ]),
                      _c("el-tab-pane", { attrs: { label: "VEHICLE" } }, [
                        _c("p", [
                          _vm._v("Year: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{veh year}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Make: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{veh make}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Model: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{veh model}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Style: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{veh style}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Unit number: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{veh unit #}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Plate number: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{veh plate #}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("VIN number: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{veh vin #}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("License plate: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{veh license plate}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Mileage: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{veh mileage}")]
                          )
                        ])
                      ]),
                      _c("el-tab-pane", { attrs: { label: "TOTALS" } }, [
                        _c("p", [
                          _vm._v("Invoice total: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{job total $}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Balance due customer: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{customer balance $}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Balance due bill to: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{bill to balance $}")]
                          )
                        ])
                      ]),
                      _c("el-tab-pane", { attrs: { label: "VENDORS" } }, [
                        _c("p", [
                          _vm._v("Csr name: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{csr name}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Sale source name: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{sale source name}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Sales rep name: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{sales rep name}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Tech name: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{tech name}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Location name: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{location name}")]
                          )
                        ])
                      ]),
                      _c("el-tab-pane", { attrs: { label: "SHOP" } }, [
                        _c("p", [
                          _vm._v("Name: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{shop name}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Email: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{shop email}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Address: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{shop address}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Address city: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{shop address city}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Address state: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{shop address state}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Address ZIP: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{shop address zip}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Full address: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{shop full address}")]
                          )
                        ]),
                        _c("p", [
                          _vm._v("Logo: "),
                          _c(
                            "span",
                            {
                              staticClass: "text-message-template-variable",
                              on: { click: _vm.copyVariable }
                            },
                            [_vm._v("{shop logo}")]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: _vm.form.type_id === 8 ? 24 : 16 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "hide-required-asterisk": true,
                    "label-width": "140px",
                    size: "medium"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Name")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "Enter a name" },
                        on: {
                          focus: function($event) {
                            return _vm.focusTextarea(false)
                          }
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "type_id" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Type")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "Select a type" },
                          on: {
                            focus: function($event) {
                              return _vm.focusTextarea(false)
                            }
                          },
                          model: {
                            value: _vm.form.type_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type_id", $$v)
                            },
                            expression: "form.type_id"
                          }
                        },
                        _vm._l(_vm.types, function(type) {
                          return _c("el-option", {
                            key: type.id,
                            attrs: { label: type.name, value: type.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "shop_id" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Shop")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "Select a shop"
                          },
                          on: {
                            focus: function($event) {
                              return _vm.focusTextarea(false)
                            }
                          },
                          model: {
                            value: _vm.form.shop_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "shop_id", $$v)
                            },
                            expression: "form.shop_id"
                          }
                        },
                        _vm._l(_vm.shops, function(shop) {
                          return _c("el-option", {
                            key: shop.id,
                            attrs: { label: shop.name, value: shop.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "subject" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Subject")]
                      ),
                      _c("el-input", {
                        key: _vm.forceUpdate,
                        ref: "templateSubject",
                        attrs: { placeholder: "Enter a subject" },
                        on: {
                          focus: function($event) {
                            return _vm.focusSubject(true)
                          }
                        },
                        model: {
                          value: _vm.form.subject,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "subject", $$v)
                          },
                          expression: "form.subject"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "body" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Template body")]
                      ),
                      _c("vue-editor", {
                        ref: "templateTextarea",
                        attrs: {
                          placeholder: "Enter a template body",
                          editorToolbar: _vm.editorToolbar
                        },
                        on: {
                          "selection-change": _vm.onBodySelectionChange,
                          focus: _vm.onBodyFocus
                        },
                        model: {
                          value: _vm.form.body,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "body", $$v)
                          },
                          expression: "form.body"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "default" } },
                    [
                      _c("el-checkbox", {
                        staticStyle: { "text-transform": "none" },
                        attrs: {
                          border: "",
                          label: "Make default for the shop"
                        },
                        on: {
                          focus: function($event) {
                            return _vm.focusTextarea(false)
                          }
                        },
                        model: {
                          value: _vm.form.default,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "default", $$v)
                          },
                          expression: "form.default"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.form.type_id === 6
                    ? _c(
                        "el-form-item",
                        { staticStyle: { "word-break": "break-word" } },
                        [
                          _c("p", { staticClass: "mt-3" }, [
                            _c("span", { staticStyle: { color: "#F56C6C" } }, [
                              _vm._v("NOTE:")
                            ]),
                            _vm._v(
                              ' that text "Please reply YES to confirm or reply NO to change" will be automatically added in the end of the message.'
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mb-2 mr-2",
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.handleSubmit }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.template === null ? "Create" : "Update"
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml-0",
                          attrs: { plain: "" },
                          on: { click: _vm.handleClose }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }