var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "120px",
        width: "900px",
        "custom-class": "edit-warehouse-entity-dialog",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-gray-700 t-text-lg",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-document" }),
          _c("span", { staticClass: "t-ml-3" }, [_vm._v("Create Return")])
        ]
      ),
      _c(
        "div",
        { staticClass: "t-pt-6" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "t-mx-6",
              attrs: {
                model: _vm.form,
                "label-position": "top",
                size: "small",
                "hide-required-asterisk": ""
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "reference" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("Reference")]
                  ),
                  _c("el-input", {
                    attrs: { placeholder: "Enter Reference" },
                    model: {
                      value: _vm.form.reference,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "reference", $$v)
                      },
                      expression: "form.reference"
                    }
                  })
                ],
                1
              ),
              _vm._l(_vm.poLines, function(line, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "t-flex t-border t-border-solid t-border-gray-200 t-rounded-md t-px-4\n            t-py-2 t-shadow t-bg-gray-50 t-mb-4"
                    },
                    [
                      _c("div", { staticClass: "t-w-1/3" }, [
                        _c(
                          "div",
                          { staticClass: "t-text-gray-500 t-text-xs t-h-5" },
                          [_vm._v("Part #")]
                        ),
                        _c("div", { staticClass: "t-mt-1" }, [
                          _vm._v(_vm._s(line.itemName))
                        ])
                      ]),
                      _c("div", { staticClass: "t-w-1/3" }, [
                        _c(
                          "div",
                          { staticClass: "t-text-gray-500 t-text-xs t-h-5" },
                          [_vm._v("Total Qty")]
                        ),
                        _c("div", { staticClass: "t-mt-1" }, [
                          _vm._v(_vm._s(line.quantity))
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "t-w-1/3" },
                        [
                          _c(
                            "div",
                            { staticClass: "t-text-gray-500 t-text-xs t-h-5" },
                            [_vm._v("Return")]
                          ),
                          line.returnedCount > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "t-flex t-flex-col t-items-start t-space-y-2"
                                },
                                _vm._l(line.returns, function(ret, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "t-px-2 t-rounded t-opacity-80 t-text-white\n                  t-cursor-pointer t-bg-orange-400",
                                      on: {
                                        click: function($event) {
                                          return _vm.openReturn(ret.id)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " RTN " + _vm._s(ret.internalId) + " "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _c(
                                "el-form-item",
                                {
                                  staticClass: "t-mb-0",
                                  attrs: { "label-width": "0" }
                                },
                                [
                                  _c("el-checkbox", {
                                    attrs: {
                                      size: "large",
                                      checked: _vm.form.purchaseOrderLineNumbers.includes(
                                        line.purchaseOrderLineNumber
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changed({
                                          poLineNumber:
                                            line.purchaseOrderLineNumber,
                                          value: $event
                                        })
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ]
                  )
                ])
              }),
              _vm.form.purchaseOrderLineNumbers.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "dropOffDate" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "undo-label-styles",
                              attrs: { slot: "label" },
                              slot: "label"
                            },
                            [_vm._v("Drop off date")]
                          ),
                          _c("el-date-picker", {
                            staticClass: "t-w-72",
                            attrs: {
                              type: "date",
                              format: "MM/dd/yyyy",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "Pick a date"
                            },
                            model: {
                              value: _vm.form.dropOffDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "dropOffDate", $$v)
                              },
                              expression: "form.dropOffDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "returnStatusId" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "undo-label-styles",
                              attrs: { slot: "label" },
                              slot: "label"
                            },
                            [_vm._v("Status")]
                          ),
                          _c(
                            "el-select",
                            {
                              staticClass: "t-w-72",
                              attrs: { filterable: "" },
                              model: {
                                value: _vm.form.returnStatusId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "returnStatusId", $$v)
                                },
                                expression: "form.returnStatusId"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Opened", value: 1 }
                              }),
                              _c("el-option", {
                                attrs: { label: "Confirmed", value: 2 }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "note" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "undo-label-styles",
                              attrs: { slot: "label" },
                              slot: "label"
                            },
                            [_vm._v("Notes")]
                          ),
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "Enter notes",
                              rows: 3
                            },
                            model: {
                              value: _vm.form.note,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "note", $$v)
                              },
                              expression: "form.note"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "t-py-3 t-px-6 t-flex t-justify-between t-border-0 t-border-t t-border-solid"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "small" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus",
                    loading: _vm.submitting,
                    disabled: _vm.form.purchaseOrderLineNumbers.length === 0
                  },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v(" Create ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }