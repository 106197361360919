import Api from '@/services/Api'

export default class TwilioPhoneNumber {
  static async get (shopId) {
    const res = await Api.get(`/api/shops/${shopId}/twilioPhoneNumbers`)
    return res
  }

  static async getForUser () {
    const res = await Api.get('/api/twilioPhoneNumbers/getForUser')
    return res
  }

  static async getOne (phoneNumberId) {
    const { data } = await Api.get(`/api/twilioPhoneNumbers/${phoneNumberId}`)
    return data
  }

  static async store (shopId, data) {
    const res = await Api.post(`/api/shops/${shopId}/twilioPhoneNumbers`, data)
    return res
  }

  static async update (phoneNumberId, data) {
    const res = await Api.put(`/api/twilioPhoneNumbers/${phoneNumberId}`, data)
    return res
  }

  static async destroy (phoneNumberId) {
    const res = await Api.delete(`/api/twilioPhoneNumbers/${phoneNumberId}`)
    return res
  }

  static async getForJobConfirmation (shopId) {
    const { data } = await Api.post('/api/twilioPhoneNumbers/getForJobConfirmation', { shopId: shopId })
    return data
  }

  static async getForJobMessaging (shopId) {
    const { data } = await Api.post('/api/twilioPhoneNumbers/getForJobMessaging', { shopId })
    return data
  }
}
