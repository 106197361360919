import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import * as R from 'ramda'

export default class Customer extends ObjectTracker {
  id = -1
  shop_id = -1
  first_name = ''
  last_name = ''
  middle_name = ''
  phone1 = ''
  phone2 = ''
  email = ''
  address = ''
  city = ''
  state = ''
  zip = ''
  notes = ''
  created = -1
  modified = -1
  flagged_customer = 0
  g_address = null

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static tableSearch (shopId, searchValue = null, callbackFunction) {
    if (searchValue == null || searchValue.length === 0) {
      
      return
    }

    Api({url: `/api/shop/${shopId}/search/table/${searchValue}`}).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(val)
      })
      callbackFunction(objects)
    })
  }

  static getAll (shopId, phoneSearch, searchValue = null, callbackFunction) {
    if (searchValue == null || searchValue.length === 0) {
      
      return
    }
    
    

    Api({url: `/api/shop/${shopId}/search/` + (phoneSearch ? 'phone' : 'all') + `/${searchValue}`}).then(res => {
      var objects = []
      
      res.data.forEach(function (val, index) {
        var customer = new Customer(val)
        objects.push(customer)
      })
      callbackFunction(objects)
    })
  }

  static getCustomerJobs (shopId, customerId, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/shop/${shopId}/customerJobs/${customerId}`}).then(res => {
      var jobs = []
      res.data.forEach(function (val, index) {
        jobs.push(val)
      })
      callbackFunction(jobs)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  createUpdateCustomer (shopId, callbackFunction, errorCallbackFunction = null) {
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    if (!this.shop_id || this.shop_id <= 0) {
      this.shop_id = shopId
    }
    return Api.post(`/api/shop/${this.shop_id}/customer/${this.id}`, this).then(res => {
      
      
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getCustomerEmail (shopId, customerId, callbackFunction) {
    return Api.get(`/api/shop/${shopId}/customeremail/${customerId}`).then(res => {
      callbackFunction(res.data)
    })
  }

  static getShopCustomersByUserId (limit, offset, callbackFunction) {
    Api({url: `/api/customers/${limit}/${offset}`}).then(res => {
      callbackFunction(res)
    })
  }

  static async getById (id) {
    const res = await Api.get(`/api/customers/${id}`)
    return res
  }

  async checkGetAddress() {
    try {
      const hasAddressFields = R.pipe(R.pick(['address', 'city', 'state', 'zip']), R.isEmpty, R.not)(this)
      if (hasAddressFields && !this.g_address && this.id >= 0) {
        const { data = {} } = await this.getById(this.id)
        this.g_address = data.g_address
        return data.g_address
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
}
