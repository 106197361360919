import { formatToDb } from '@/scripts/helpers/date.helpers'
import {
  scheduleJobFilterMap,
  filterTechJobs,
  filterJobsByDate,
  getCombinedTechList,
  getTechState,
  getTechWaypoints,
  getTechWaypointList,
  getDateTechWaypoints,
  getTechNameMap
} from '@/scripts/helpers/scheduler.helpers'
import { getRouteStates, getUnmappedLocations, getColoredIconUrl } from '@/scripts/helpers/geometry.helpers'
import * as R from 'ramda'
import { filterApptsByDate, filterTechAppts } from '@/scripts/helpers/appointment.helpers'
import { getWeekDates, filterAndSortCalendarJobs } from '@/scripts/helpers/calendar.helpers'

const schedulerGetters = {
  workOrders: R.pipe(
    R.prop('jobs'),
    R.filter(R.propEq('status', 'workOrder'))
  ),
  filteredJobList: (state, getters) => scheduleJobFilterMap[state.activeJobListFilter.value](getters.workOrders),
  getCurrentFilterTitle: R.path(['activeJobListFilter', 'value']),
  jobFilterCount: state => filter => scheduleJobFilterMap[filter](state.jobs).length,
  activeTechs: state => state.techs && state.techs.filter(R.propEq('status', 'active')),
  techsList: (state, getters, rootState, rootGetters) =>
    rootGetters.getUserCombineTechsValue ? getCombinedTechList(getters.activeTechs) : getters.activeTechs,
  dateFilteredJobList: state => filterJobsByDate(formatToDb(state.filters.date))(state.jobs),
  techJobs: (state, getters) => techId => filterTechJobs(techId)(getters.dateFilteredJobList),
  getTech: state => techId => R.find(R.propEq('id', techId))(state.techs),
  getJobDetailsId: R.path(['jobDetails', 'id']),
  getJobById: state => id => R.find(R.propEq('id', id))(state.jobs),
  getTechById: state => id => R.find(R.propEq('id', id))(state.techs),
  getJobDetailsInstallNotes: R.path(['jobDetails', 'extra', 'install_notes']),
  getShopFiltersString: R.pipe(
    R.path(['filters', 'shops']),
    R.join(',')
  ),
  isListView: R.pathEq(['filters', 'view'], 'list'),
  visibleGeometry: state => state.geometry.filter(({ hidden, error }) => !hidden && !error),
  routeStates: state => getRouteStates(state.geometry),
  warehouseFilter: R.path(['filters', 'warehouses']),
  filteredWarehouses: state =>
    R.pipe(
      R.prop('warehouses'),
      R.filter(({ type }) => R.path(['filters', 'warehouses', type], state))
    )(state),
  isRowView: R.path(['filters', 'rowView']),
  hasData: state => !R.isEmpty(state.techs),
  techState: state => id => getTechState(id)(state),
  techWaypoints: state => id => getTechWaypoints(id)(state),
  techWaypointList: state => id => getTechWaypointList(id)(state),
  dateFilter: R.path(['filters', 'date']),
  dateFilteredWaypointsList: state => id => getDateTechWaypoints(+id, state.filters.date)(state),
  geometryErrors: R.pipe(
    R.prop('geometry'),
    R.filter(R.prop('error'))
  ),
  dateFilteredApptsList: state => filterApptsByDate(state.filters.date)(state.appointments),
  techAppts: (state, getters) => techId => filterTechAppts(techId)(getters.dateFilteredApptsList),
  isSolidCardView: R.path(['filters', 'solidCards']),
  getUnmappedLocations: state => getUnmappedLocations(state),
  techItemsListLength: (state, getters) => id =>
    [...getters.techJobs(id), ...getters.dateFilteredWaypointsList(id), ...getters.techAppts(id)].length,
  isDayView: R.pathEq(['filters', 'cardView'], 3),
  weekDates: (state, getters) => offset => getWeekDates(getters.dateFilter, offset),
  weekOffset: R.path(['filters', 'weekOffset']),
  calendarDayJobs: state => date => filterAndSortCalendarJobs(state, formatToDb(date)),
  techNameMap: state => getTechNameMap(state),
  mapTechsideUsernames: R.pipe(
    R.prop('geometry'),
    R.map(R.prop('techside_username'))
  ),
  techIconUrls: R.pipe(
    R.prop('geometry'),
    R.reduce(
      (values, { techside_username, color }) => ({ ...values, [techside_username]: getColoredIconUrl(color) }),
      {}
    )
  )
}

export default schedulerGetters
