<template>
  <div v-if="value" class="t-flex t-items-baseline t-mb-2">
    <div class="t-w-48 t-text-right t-text-gray-500 t-text-xs">
      {{ name }}
    </div>
    <div
      class="t-text-gray-700 t-ml-4"
      :class="{
        't-font-bold': bold,
        'link': link
      }"
      @click="$emit('click')"
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: String,
    bold: Boolean,
    link: Boolean
  }
}
</script>

<style lang="scss" scoped>
 .link {
    color: #409eff !important;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }
  .link:hover {
    color: #0077f3 !important;
    text-decoration: underline;
  }
</style>
