<template>
  <div class="vendor-sales-rep-settings">
    <div>
      <el-divider class="t-my-6" />

      <el-checkbox
        v-model="hide_sent_ssm_jobs"
        @change="updateHideSentSSMJobsHandler"
      >
        Hide sent ssm jobs
      </el-checkbox>
    </div>

    <div>
      <el-divider class="t-my-6" />

      <el-checkbox
        v-model="salesidejob_start_on_send"
        @change="updateSalesidejobStartOnSendHandler"
      >
        Automatically start SSM jobs when sent
      </el-checkbox>
    </div>

    <div>
      <el-divider class="t-my-6" />

      <div class="t-text-gray-400 t-mb-4">SSM Allowed Shops</div>

      <el-select
        v-model="ssm_allowed_shop_ids"
        multiple
        placeholder="Select SSM Allowed Shops"
        @change="updateSSMAllowedShopsHandler"
      >
        <el-option
          v-for="i in shops"
          :key="i.id"
          :label="i.name"
          :value="i.id"
        />
      </el-select>
    </div>

    <div>
      <el-divider class="t-my-6" />

      <div class="t-text-gray-400 t-mb-4">Default Location</div>

      <el-select
        v-model="default_location_id"
        clearable
        filterable
        placeholder="Select Default Location"
        @change="updateDefaultLocationHandler"
      >
        <el-option
          v-for="i in locations"
          :key="i.id"
          :label="i.name"
          :value="i.id"
          :disabled="i.is_disabled === 1"
        />
      </el-select>
    </div>

    <div>
      <el-divider class="t-my-6" />

      <div class="t-text-gray-400 t-mb-4">Locations</div>

      <el-transfer
        v-model="location_ids"
        filterable
        filter-placeholder="Filter by name"
        :data="locationsForMultiselect"
        :titles="['Available', 'Connected']"
        @change="updateLocationsHandler"
      />
    </div>

    <div>
      <el-divider class="t-my-6" />

      <div class="t-text-gray-400 t-mb-4">Commercial Accounts</div>

      <el-transfer
        v-model="commercial_account_ids"
        filterable
        filter-placeholder="Filter by name"
        :data="commercialAccountsForMultiselect"
        :titles="['Available', 'Connected']"
        @change="updateCommercialAccountsHandler"
      />
    </div>

    <div>
      <el-divider class="t-my-6" />

      <div class="t-text-gray-400 t-mb-4">Salesources</div>

      <el-transfer
        v-model="sale_source_ids"
        filterable
        filter-placeholder="Filter by name"
        :data="saleSourcesForMultiselect"
        :titles="['Available', 'Connected']"
        @change="updateSaleSourcesHandler"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { VendorsAPI } from '@/services/VendorsAPI'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  props: {
    shops: Array
  },

  data() {
    return {
      hide_sent_ssm_jobs: false,
      ssm_allowed_shop_ids: [],

      default_location_id: null,
      locations: [],
      location_ids: [],

      commercial_accounts: [],
      commercial_account_ids: [],

      sale_sources: [],
      sale_source_ids: [],

      salesidejob_start_on_send: false
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data']),

    locationsForMultiselect() {
      return this.locations.map((i) => ({
        key: i.id,
        label: i.name,
        disabled: i.is_disabled === 1
      }))
    },

    commercialAccountsForMultiselect() {
      return this.commercial_accounts.map((i) => ({
        key: i.id,
        label: i.company_name
      }))
    },

    saleSourcesForMultiselect() {
      return this.sale_sources.map((i) => ({
        key: i.id,
        label: i.name,
        disabled: i.is_disabled === 1
      }))
    }
  },

  async created() {
    this.hide_sent_ssm_jobs = !!this.data.hide_sent_ssm_jobs
    this.ssm_allowed_shop_ids = this.data.ssm_allowed_shop_ids
    this.default_location_id = this.data.default_location_id
    this.location_ids = this.data.location_ids
    this.commercial_account_ids = this.data.commercial_account_ids
    this.sale_source_ids = this.data.sale_source_ids
    this.salesidejob_start_on_send = !!this.data.salesidejob_start_on_send

    const orgId = this.data.organization_id

    this.locations = await VendorsAPI.get(
      `organization/${orgId}/locations/dictionary`
    )

    const { data } = await this.$unum.consumer()
      .get('organization/ca', {
        params: { organization_id: orgId }
      })
    this.commercial_accounts = data

    this.sale_sources = await VendorsAPI.get(
      `organization/${orgId}/sale-sources/dictionary`
    )
  },

  methods: {
    ...mapActions(useVendorStore, [
      'updateHideSentSSMJobs',
      'updateSSMAllowedShops',
      'updateDefaultLocation',
      'updateCommercialAccounts',
      'updateLocations',
      'updateSaleSources',
      'updateSalesidejobStartOnSend'
    ]),

    async updateHideSentSSMJobsHandler(val) {
      try {
        await this.updateHideSentSSMJobs(val)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateSalesidejobStartOnSendHandler(val) {
      try {
        await this.updateSalesidejobStartOnSend(val)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateSSMAllowedShopsHandler(val) {
      try {
        await this.updateSSMAllowedShops(val)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateDefaultLocationHandler(val) {
      try {
        await this.updateDefaultLocation(val)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateCommercialAccountsHandler(val) {
      try {
        await this.updateCommercialAccounts(val)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateLocationsHandler(val) {
      try {
        await this.updateLocations(val)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateSaleSourcesHandler(val) {
      try {
        await this.updateSaleSources(val)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
.vendor-sales-rep-settings {
  .el-transfer {
    @apply t-flex t-items-center;

    .el-transfer-panel {
      @apply t-flex-grow t-rounded-lg;

      .el-transfer-panel__header {
        .el-checkbox__label {
          @apply t-text-gray-600;
        }
      }

      .el-checkbox__label {
        @apply t-normal-case t-font-normal;
      }
    }

    .el-transfer__buttons {
      @apply t-px-3;

      .el-button {
        @apply t-p-3;
      }
    }
  }
}
</style>
