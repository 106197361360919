var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            border: "",
            size: "mini",
            "empty-text": "No devices found",
            data: _vm.devices
          }
        },
        [
          _c("el-table-column", { attrs: { prop: "name", label: "Name" } }),
          _c("el-table-column", {
            attrs: { prop: "paired", label: "Connected" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.paired
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              size: "mini",
                              effect: "dark"
                            }
                          },
                          [_vm._v(" Yes ")]
                        )
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "warning",
                              size: "mini",
                              effect: "dark"
                            }
                          },
                          [_vm._v(" Pending ")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "paired_at", label: "Paired At" }
          }),
          _c("el-table-column", {
            attrs: { label: "Actions", width: "175" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "t-flex" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Remove this device",
                              placement: "top",
                              "open-delay": 300
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                type: "danger",
                                icon: "el-icon-delete",
                                plain: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleDelete(scope.row)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _vm._v("Your Square devices "),
      _c("span", { staticClass: "badge badge-primary" }, [_vm._v("Beta!")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }