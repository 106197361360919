var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex-grow" },
    [
      _vm.view === "view" ? _c("Channel") : _vm._e(),
      _vm.view === "welcome" ? _c("Welcome") : _vm._e(),
      _vm.view === "list" ? _c("BrowseChannels") : _vm._e(),
      _vm.view === "create" ? _c("CreateChannel") : _vm._e(),
      _vm.view === "startDirectChannel" ? _c("StartDirectChannel") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }