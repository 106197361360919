import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Role extends ObjectTracker {
  id = -1
  name = ''
  comments = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getRoles (callbackFunction) {
    Api({ url: `/api/userroles` }).then(res => {
      var temp = []
      res.data.forEach(item => {
        temp.push(new Role(item))
      })
      callbackFunction(temp)
    })
  }
}
