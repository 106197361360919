<template>
  <InfoCard :schema="schema" :dataObject.sync="lossNotice" />
</template>

<script>
import InfoCard from '@/components/modals/GlaxisDialogs/claim/components/InfoCard'
import { applyMediaRules } from '@/scripts/helpers/media.helpers'

export default {
  components: { InfoCard },
  props: ['lossNotice', 'updateSchemaLabelsWithMatchingAndSubrogation', 'customer', 'fieldsDisabled'],
  computed: {
    rowSpanOne() {
      return applyMediaRules({ lg: 24, md: 24, sm: 24, xs: 24 })
    },
    rowSpanTwo() {
      return applyMediaRules({ lg: 12, md: 12, sm: 12, xs: 12 })
    },
    rowSpanThree() {
      return applyMediaRules({ lg: 8, md: 8, sm: 12, xs: 12 })
    },
    rowSpanFour() {
      return applyMediaRules({ lg: 6, md: 6, sm: 12, xs: 12 })
    },
    schema() {
      var schema = {
        title: 'Insured Customer',
        icon: 'person_outline',
        subTitleLabel: this.lossNotice.insured_first_name + ' ' + this.lossNotice.insured_last_name,
        subTitleMatchText: this.customer?.name?.first + ' ' + this.customer?.name?.last,
        fields: [[
          {
            lossNoticeDefault: this.customer?.phones.length > 0 ? this.customer?.phones[0].number : '',
            label: 'Phone Number',
            id: 'insured_phone_number',
            editable: false,
            span: this.rowSpanTwo
          },
          {
            lossNoticeDefault: this.customer?.phones.length > 1 ? this.customer?.phones[1].number : '',
            label: 'Alternate Phone Number',
            id: 'insured_alternate_phone_number',
            editable: false,
            span: this.rowSpanTwo
          }
        ],
        [
          {
            lossNoticeDefault: this.customer?.address?.address,
            lossNoticeLimit: 25,
            label: 'Street Address',
            id: 'insured_street_address',
            editable: false,
            span: this.rowSpanFour
          },
          {
            lossNoticeDefault: this.customer?.address?.city,
            lossNoticeLimit: 20,
            label: 'City',
            id: 'insured_city',
            editable: false,
            span: this.rowSpanFour
          },
          {
            lossNoticeDefault: this.customer?.address?.state,
            label: 'State/Province',
            id: 'insured_state_province',
            editable: false,
            span: this.rowSpanFour
          },
          {
            lossNoticeDefault: this.customer?.address?.zip,
            lossNoticeLimit: 5,
            label: 'Postal Code',
            id: 'insured_postal_code',
            editable: false,
            span: this.rowSpanFour
          }
        ]
        ]
      }
      return this.updateSchemaLabelsWithMatchingAndSubrogation(schema)
    }
  }
}
</script>

<style>

</style>
