var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-date-filter" },
    [
      _c("div", { staticClass: "ui-input-label" }, [_vm._v("Date:")]),
      _c("el-date-picker", {
        attrs: {
          type: "date",
          placeholder: "Pick a day",
          "picker-options": _vm.pickerOptions,
          clearable: false
        },
        model: {
          value: _vm.date,
          callback: function($$v) {
            _vm.date = $$v
          },
          expression: "date"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }