import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import Jobtax from '@/scripts/objects/jobtax'
// import { UtilityMixin } from '@/scripts/helpers/utilities'

export default class Tax extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  name = ''
  percentage = 0.0
  for_materials = 0
  for_labor = 0
  default_tax = 0
  shop_id = 0
  colorado_tax = 0
  tax_type = ''
  tax_co_account_id = -1
  modified = -1

  _is_saved = false
  _tax_zip_codes = []

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
    if ('_tax_zip_codes' in initialObject) {
      this._tax_zip_codes = JSON.parse(JSON.stringify(initialObject['_tax_zip_codes']))
      this['objectTrackerInitial']['_tax_zip_codes'] = JSON.parse(JSON.stringify(initialObject['_tax_zip_codes']))
    }
  }

  setInitialTaxZipCodes (taxZipCodes) {
    this._tax_zip_codes = JSON.parse(JSON.stringify(taxZipCodes))
    this['objectTrackerInitial']['_tax_zip_codes'] = JSON.parse(JSON.stringify(taxZipCodes))
  }

  taxZipCodesCleanCheck () {
    var originalCount = this['objectTrackerInitial']['_tax_zip_codes'] ? this['objectTrackerInitial']['_tax_zip_codes'].length : 0
    var currentCount = this._tax_zip_codes ? this._tax_zip_codes.length : 0
    if (originalCount !== currentCount) {
      return false
    }

    if (originalCount === 0) {
      return true
    }

    // check if new tax zip codes were created
    for (var i = 0; i < this._tax_zip_codes.length; i++) {
      if (this._tax_zip_codes[i].id <= 0) {
        return false
      }
    }

    // check if existing tax zip codes were deleted
    for (i = 0; i < this['objectTrackerInitial']['_tax_zip_codes'].length; i++) {
      var found = false
      for (var j = 0; j < this._tax_zip_codes.length; j++) {
        if (this['objectTrackerInitial']['_tax_zip_codes'][i].id === this._tax_zip_codes[j].id) {
          found = true
        }
      }
      if (!found) {
        return false
      }
    }
    return true
  }

  static getAll (shopId, callbackFunction) {
    Api({ url: `/api/shop/${shopId}/tax` }).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new Tax(val))
      })
      callbackFunction(objects)
    })
  }
  static getTaxesByUserShops (callbackFunction) {
    Api({url: `/api/usertaxes`}).then(res => {
      var taxes = []
      res.data.forEach(function (val, index) {
        taxes.push(val)
      })
      callbackFunction(taxes)
    })
  }
  static createUpdateColoradoTax (shopId, taxObject, jobId, callbackFunction) {
    Api.post(`/api/shop/${shopId}/cto/${jobId}`, taxObject).then(res => {
      var shopTaxObjects = []
      res.data.shopTaxes.forEach(function (val, index) {
        shopTaxObjects.push(new Tax(val))
      })
      var jobTaxObjects = []
      res.data.jobTaxes.forEach(function (val, index) {
        jobTaxObjects.push(new Jobtax(val))
      })
      callbackFunction({jobTaxes: jobTaxObjects, shopTaxes: shopTaxObjects})
    })
  }
  createUpdateTax (shopId, callbackFunction, errorCallbackFunction = null) {
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    var self = this
    return Api.post(`/api/shop/${this.shop_id}/tax/${this.id}`, this).then(res => {
      self.setInitial(self)
      self.setInitialTaxZipCodes(res.data._tax_zip_codes)
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
