import gql from 'graphql-tag'
import { GB_VENDOR_FRAGMENT } from '../fragments/gb-vendor.fragment'

export const CREATE_GB_VENDOR_MUTATION = gql`
  mutation createGbVendor($gb_vendor: GbVendorInput!) {
    createGbVendor(gb_vendor: $gb_vendor) {
      ...GbVendorFields
    }
  }
  ${GB_VENDOR_FRAGMENT}
`

export const UPDATE_GB_VENDOR_MUTATION = gql`
  mutation updateGbVendor($gb_vendor_id: ID!, $gb_vendor: GbVendorInput!, $overrideVendorUsernameId: ID) {
    updateGbVendor(gb_vendor_id: $gb_vendor_id, gb_vendor: $gb_vendor, overrideVendorUsernameId: $overrideVendorUsernameId) {
      ...GbVendorFields
    }
  }
  ${GB_VENDOR_FRAGMENT}
`
