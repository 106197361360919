var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "120px",
        width: "900px",
        "custom-class": "edit-warehouse-entity-dialog",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-gray-700 t-text-lg",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-search" }),
          _c("span", { staticClass: "t-ml-3" }, [_vm._v(" Item Search ")])
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "t-px-6 t-py-4 t-border-0 t-border-b t-border-solid\n      t-border-gray-200 t-flex t-justify-between t-items-center"
        },
        [
          _c("el-input", {
            staticClass: "t-w-72",
            attrs: {
              clearable: "",
              size: "small",
              placeholder: "Enter search string (minimum 4 char)"
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _vm.search.length >= 4
            ? _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-refresh" },
                  on: { click: _vm.refreshResults }
                },
                [_vm._v(" Refresh results ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.search && _vm.search.length >= 4
        ? _c("div", { staticClass: "t-flex t-max-h-[800px]" }, [
            _c(
              "div",
              {
                staticClass:
                  "t-w-1/2 t-pt-4 t-pb-8 t-border-0 t-border-r t-border-solid\n        t-border-gray-200"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "t-text-base t-text-gray-600 t-pb-2 t-text-center"
                  },
                  [_vm._v(" Organization Results ")]
                ),
                _vm.itemsFetching
                  ? _c("div", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: true,
                          expression: "true"
                        }
                      ],
                      staticClass: "t-h-16"
                    })
                  : !_vm.items.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "t-text-gray-400 t-italic t-text-center t-py-2"
                      },
                      [
                        _c("div", [_vm._v("No items found")]),
                        _c(
                          "el-button",
                          {
                            staticClass: "t-mt-4",
                            attrs: { size: "mini", icon: "el-icon-plus" },
                            on: {
                              click: function($event) {
                                _vm.createItemDialogVisible = true
                              }
                            }
                          },
                          [_vm._v(" Create an item ")]
                        )
                      ],
                      1
                    )
                  : _c(
                      "el-scrollbar",
                      {
                        staticClass: "t-h-full",
                        attrs: {
                          "wrap-class": "t-overflow-x-hidden",
                          "view-class": "t-space-y-2 t-mb-6 t-px-6"
                        }
                      },
                      _vm._l(_vm.items, function(i) {
                        return _c(
                          "div",
                          {
                            key: i.id,
                            staticClass:
                              "t-border t-border-solid t-border-gray-200 t-shadow t-rounded\n            t-px-4 t-py-2 t-flex t-items-center t-space-x-4"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "t-flex-shrink-0 t-text-blue-400"
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "el-icon-setting t-cursor-pointer",
                                  on: {
                                    click: function($event) {
                                      _vm.itemProfileId = i.id
                                    }
                                  }
                                })
                              ]
                            ),
                            _c("div", { staticClass: "t-flex-grow" }, [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.highlightSearch(i.number)
                                  )
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "t-text-xs t-text-gray-400" },
                                [_vm._v(_vm._s(i.description))]
                              )
                            ]),
                            _c("div", { staticClass: "t-flex-shrink-0" }, [
                              _vm._v("$" + _vm._s(i.listPrice))
                            ]),
                            _c(
                              "div",
                              { staticClass: "t-flex-shrink-0" },
                              [
                                _c("el-button", {
                                  attrs: {
                                    circle: "",
                                    size: "mini",
                                    icon: "el-icon-plus"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectItem(i)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      0
                    )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "t-w-1/2 t-pt-4 t-pb-8" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "t-text-base t-text-gray-600 t-pb-2 t-text-center"
                  },
                  [_vm._v(" NAGS Glass Results ")]
                ),
                _vm.nagsFetching
                  ? _c("div", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: true,
                          expression: "true"
                        }
                      ],
                      staticClass: "t-h-16"
                    })
                  : !Object.keys(_vm.nagsGlass).length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "t-text-gray-400 t-italic t-text-center t-py-2"
                      },
                      [_vm._v(" No glass found ")]
                    )
                  : _c(
                      "el-scrollbar",
                      {
                        staticClass: "t-h-full",
                        attrs: {
                          "wrap-class": "t-overflow-x-hidden",
                          "view-class": "t-space-y-4 t-mb-6"
                        }
                      },
                      _vm._l(_vm.nagsGlass, function(value, key) {
                        return _c(
                          "div",
                          { key: key, staticClass: "t-space-y-2 t-px-6" },
                          [
                            _c("div", { staticClass: "t-text-gray-500" }, [
                              _vm._v(_vm._s(key))
                            ]),
                            _vm._l(value, function(i) {
                              return _c(
                                "div",
                                {
                                  key: i.id,
                                  staticClass:
                                    "t-border t-border-solid t-border-gray-200 t-shadow\n              t-rounded t-px-4 t-py-2 t-flex t-items-center t-space-x-4"
                                },
                                [
                                  _c("div", { staticClass: "t-flex-grow" }, [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.highlightSearch(i.number)
                                        )
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "t-text-xs t-text-gray-400"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(i.positionName) +
                                            " " +
                                            _vm._s(i.sideName) +
                                            " " +
                                            _vm._s(i.glassColor) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "t-flex-shrink-0" },
                                    [_vm._v("$" + _vm._s(i.price))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "t-flex-shrink-0" },
                                    [
                                      _c("el-button", {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName:
                                              "v-loading.fullscreen.lock",
                                            value: _vm.nagsItemSelecting,
                                            expression: "nagsItemSelecting",
                                            modifiers: {
                                              fullscreen: true,
                                              lock: true
                                            }
                                          }
                                        ],
                                        attrs: {
                                          circle: "",
                                          size: "mini",
                                          icon: "el-icon-plus"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectNagsItem(i)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      }),
                      0
                    )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("ItemProfileDialog", {
        attrs: {
          id: _vm.itemProfileId,
          "is-catalog": false,
          "shop-id": _vm.shopId
        },
        on: {
          closed: function($event) {
            _vm.itemProfileId = null
          }
        }
      }),
      _c("CreateItemDialog", {
        attrs: {
          visible: _vm.createItemDialogVisible,
          "is-catalog": false,
          "parent-id": null,
          "organization-id": _vm.shop.organization_id
        },
        on: {
          "update:visible": function($event) {
            _vm.createItemDialogVisible = $event
          },
          created: _vm.onItemCreated
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }