<template>
  <div class="t-mt-4">
    <div class="t-mb-2 t-flex t-items-center t-pl-6 t-pr-4">
      <i class="el-icon-document-copy t-text-base"></i>
      <div class="t-flex-grow t-ml-3 t-cursor-default">Channels</div>
      <i
        @click="createChannel"
        class="el-icon-plus t-cursor-pointer t-p-1 hover:t-bg-gray-300
               t-rounded t-text-base"
      ></i>
    </div>
    <div
      v-if="loading"
      v-loading="loading"
      class="t-h-12 t-mt-8"
      element-loading-background="#F7FAFC"
    ></div>
    <div v-else>
      <div
        v-for="i in channels"
        :key="i.sid"
        class="t-pl-12 t-py-1 t-pr-3 t-cursor-pointer t-flex"
        @click="open(i.sid)"
        :class="[
          { 't-bg-blue-500 t-text-blue-100': isSelected(i.sid) },
          { 'hover:t-bg-gray-300': !isSelected(i.sid) }
        ]"
      >
        <div class="t-w-6 t-text-center t-flex-shrink-0">
          <i v-if="i.isPrivate" class="el-icon-lock"></i>
          <span v-else>#</span>
        </div>
        <div
          class="t-flex-grow t-ml-1"
          :class="{ 't-mr-8': unreadMessagesCount[i.uniqueName] < 1 }">
          {{ i.uniqueName }}
        </div>
        <div
          v-if="unreadMessagesCount[i.uniqueName] > 0"
          class="t-flex-shrink-0 t-ml-3"
        >
          <div
            class="t-flex t-items-center t-justify-center t-h-5 t-w-5 t-text-xs
                   t-rounded-full t-bg-indigo-600 t-text-indigo-100">
            {{ unreadMessagesCount[i.uniqueName] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChatService } from '@/services/ChatService'
import { chatViews } from '@/constants/chat.config'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  computed: {
    visible: function() {
      return this.$store.state.chat.isOpen
    },
    activeChannel: function() {
      return this.$store.state.chat.activeChannel
    },
    view: function() {
      return this.$store.state.chat.view
    },
    userChannels: function() {
      return this.$store.state.chat.userChannels
    },
    unreadMessagesCount: function() {
      return this.$store.state.chat.unreadMessagesCount
    },
    loading: function() {
      return this.$store.state.chat.userChannelsLoading
    },
    channels: function() {
      return this.userChannels.filter(i => i.friendlyName !== 'direct')
    }
  },
  watch: {
    visible: async function(value) {
      if (value && this.activeChannel) {
        const channel = await ChatService.getChannelBySid(this.activeChannel.sid)
        this.openChannel(channel)
      }
    }
  },
  methods: {
    isSelected: function(sid) {
      return this.view === chatViews.view && this.activeChannel && sid === this.activeChannel.sid
    },
    createChannel: function() {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.changeView,
        chatViews.create
      )
    },
    openChannel: function(channel) {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.openChannel,
        channel
      )
    },
    open: async function(sid) {
      if (!this.isSelected(sid)) {
        const channel = await ChatService.getChannelBySid(sid)
        this.openChannel(channel)
      }
    }
  }
}
</script>
