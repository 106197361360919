<template>
  <el-popover placement="top" trigger="hover" v-model="visible">
    <div>
      <template v-if="location">
        Clocked in: <b>{{ clockIn }}</b> <br />
        Updated: <b>{{ updated }}</b>
      </template>
      <template v-else-if="userdata">
        Last clock in:
        <b>{{
          userdata.last_clock_in ? humanDateTime(userdata.last_clock_in) : '-'
        }}</b>
        <br />
        Last clock out:
        <b>{{
          userdata.last_clock_out ? humanDateTime(userdata.last_clock_out) : '-'
        }}</b>
        <br />
      </template>
    </div>
    <div
      v-if="location"
      class="tech-location-indicator"
      :class="{ clickable: $listeners.click }"
      :style="{ color }"
      slot="reference"
      @click="onClick"
    />

    <div
      v-else-if="username"
      class="tech-location-indicator offline"
      slot="reference"
    />
  </el-popover>
</template>

<script>
import {
  getCurrentClock,
  humanTimeDistance,
  humanDateTime
} from '@/scripts/helpers/date.helpers'

export default {
  data() {
    return {
      visible: false,
      clockIn: null
    }
  },
  props: ['username', 'color', 'userdata'],
  computed: {
    location() {
      return this.$techLocations.locations[this.username]
    },
    updated() {
      return (
        this.location && humanTimeDistance(this.location.timestamp.toDate())
      )
    }
  },
  watch: {
    visible(visible) {
      if (visible && this.location) {
        if (this.location.last_clock_in) {
          this.clock = setInterval(() => {
            this.clockIn = getCurrentClock(
              this.location.last_clock_in.toDate
                ? this.location.last_clock_in.toDate()
                : this.location.last_clock_in
            )
          }, 1000)
        }
      } else {
        this.clock && clearInterval(this.clock)
      }
    }
  },
  methods: {
    humanDateTime(date) {
      return humanDateTime(date)
    },
    onClick(e) {
      e.stopPropagation()
      e.preventDefault()
      this.$emit('click', this.location)
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes blink {
  from {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.5);
  }
  75% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}
.tech-location-indicator {
  position: relative;
  width: 30px;
  height: 30px;
  position: relative;
  &.clickable {
    cursor: pointer;
  }
  &:before {
    width: 14px;
    height: 14px;
    position: absolute;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: currentColor;
    content: '';
  }
  /* overflow: auto; */
  &:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: currentColor;
    transform-origin: center;
    opacity: 50%;
    animation: 3s blink ease infinite;
  }
  &.offline {
    &:before {
      background-color: $scheduler-border-color-hover;
    }
    &:after {
      display: none;
      animation: none;
    }
  }
}
</style>
