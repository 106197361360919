var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "scheduler-job-card",
      class: {
        active: _vm.isActive,
        solid: _vm.solidCards,
        faded: _vm.isFaded
      },
      style: _vm.cardStyle,
      on: {
        click: function($event) {
          return _vm.viewJobDetails(_vm.jobObj.id)
        }
      }
    },
    [
      _vm.job.status === "warranty"
        ? _c(
            "span",
            { staticClass: "notification left orange", attrs: { id: "afdds" } },
            [_vm._v("W")]
          )
        : _vm._e(),
      _vm.job.status === "recal"
        ? _c(
            "span",
            { staticClass: "notification left purple", attrs: { id: "afddt" } },
            [_vm._v("R")]
          )
        : _vm._e(),
      _vm.job.status === "callBack"
        ? _c(
            "span",
            {
              staticClass: "notification left light-blue",
              attrs: { id: "afddu" }
            },
            [_vm._v("C")]
          )
        : _vm._e(),
      _vm.job.status === "rAndI"
        ? _c(
            "span",
            { staticClass: "notification left teal", attrs: { id: "afddv" } },
            [_vm._v(_vm._s("R&I"))]
          )
        : _vm._e(),
      _vm.job.status === "tint"
        ? _c(
            "span",
            { staticClass: "notification left brown", attrs: { id: "afddw" } },
            [_vm._v("T")]
          )
        : _vm._e(),
      _vm.job.status === "pickUp"
        ? _c(
            "span",
            {
              staticClass: "notification left dark-blue",
              attrs: { id: "afddx" }
            },
            [_vm._v("P")]
          )
        : _vm._e(),
      _vm.job.status === "dropOff"
        ? _c(
            "span",
            {
              staticClass: "notification left dark-purple",
              attrs: { id: "afddy" }
            },
            [_vm._v("D")]
          )
        : _vm._e(),
      _vm.color
        ? _c("div", {
            staticClass: "left-border",
            style: { background: _vm.color }
          })
        : _vm._e(),
      _vm.job.customer_cancel == "1"
        ? _c("div", { attrs: { id: "watermark-cancel" } }, [_vm._v("CANCEL")])
        : _vm._e(),
      _vm.job.customer_reschedule == "1"
        ? _c("div", { attrs: { id: "watermark-reschedule" } }, [
            _vm._v(" RESCHEDULE ")
          ])
        : _vm._e(),
      _c("div"),
      _vm.showClose
        ? _c(
            "div",
            { staticClass: "invoice-job", on: { click: _vm.saveInvoice } },
            [
              _c("v-icon", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    value: "Save job as invoice",
                    expression: "'Save job as invoice'",
                    modifiers: { hover: true }
                  }
                ],
                attrs: { name: "times", scale: "0.9" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "icon-container",
          class: { "has-techside-sent": _vm.showClose }
        },
        _vm._l(_vm.icons, function(icon) {
          return _c(
            "v-icon",
            _vm._b(
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    value: icon.title,
                    expression: "icon.title",
                    modifiers: { hover: true }
                  }
                ],
                key: icon.name
              },
              "v-icon",
              icon,
              false
            )
          )
        }),
        1
      ),
      _c("job-card-info-block", {
        attrs: { icon: "user-tie", text: _vm.jobObj.getTitle(), strong: true }
      }),
      _vm.isExpanded &&
      _vm.jobObj.getVehicle() &&
      _vm.jobObj.getVehicle().trim() !== "0"
        ? _c("job-card-info-block", {
            attrs: { icon: "car", text: _vm.jobObj.getVehicle(), noLower: true }
          })
        : _vm._e(),
      _vm.isExpanded && !_vm.isBay
        ? _c("job-card-info-block", {
            attrs: { icon: "building", text: _vm.jobObj.getShop() }
          })
        : _vm._e(),
      _vm.isFull && !_vm.isBay
        ? _c("job-card-info-block", {
            attrs: {
              icon: "map-marker-alt",
              text: _vm.jobObj.getLocation(),
              noLower: true
            }
          })
        : _vm._e(),
      _vm.isFull && !_vm.isBay
        ? _c("job-card-info-block", {
            attrs: { icon: "map-signs", text: _vm.jobObj.getCrossStreets() }
          })
        : _vm._e(),
      _vm.showTags
        ? _c("div", [
            _c("div", { staticClass: "scheduler-section-header" }, [
              _vm._v("Tags")
            ]),
            _c(
              "div",
              { staticClass: "tags" },
              _vm._l(_vm.jobObj.getTags(), function(tag) {
                return _c(
                  "el-tag",
                  {
                    key: tag.id,
                    style: {
                      color: _vm.tagColor(tag.color),
                      borderColor: tag.color
                    },
                    attrs: { color: tag.color, size: "small" }
                  },
                  [_vm._v(" " + _vm._s(tag.text) + " ")]
                )
              }),
              1
            )
          ])
        : _vm._e(),
      _vm.showVendors
        ? _c(
            "div",
            { staticClass: "vendors" },
            [
              _c("div", { staticClass: "scheduler-section-header" }, [
                _vm._v("Vendors")
              ]),
              _c("scheduler-vendors", { attrs: { vendors: _vm.vendors } })
            ],
            1
          )
        : _vm._e(),
      _vm.noDate
        ? _c(
            "div",
            { staticClass: "inline" },
            [
              _vm.jobObj.getTimeWithMinutes()
                ? _c("job-card-info-block", {
                    attrs: {
                      icon: "clock",
                      text: _vm.jobObj.getTimeWithMinutes(),
                      small: true,
                      solid: _vm.solidCards
                    }
                  })
                : _vm._e(),
              _c("scheduler-times-row", {
                attrs: { item: _vm.routeEntry, solid: _vm.solidCards }
              })
            ],
            1
          )
        : [
            _vm.jobObj.getDate() || _vm.jobObj.getTimeWithMinutes()
              ? _c(
                  "div",
                  { staticClass: "inline" },
                  [
                    _c("job-card-info-block", {
                      attrs: {
                        icon: "calendar",
                        text: _vm.jobObj.getTimeWithMinutes(),
                        small: true,
                        solid: _vm.solidCards
                      }
                    }),
                    _c("job-card-info-block", {
                      attrs: {
                        icon: "clock",
                        text: _vm.jobObj.getTimeWithMinutes(),
                        small: true,
                        solid: _vm.solidCards
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "inline" },
                  [
                    _c("job-card-info-block", {
                      attrs: {
                        icon: "calendar",
                        text: "No date scheduled",
                        small: true,
                        solid: _vm.solidCards
                      }
                    })
                  ],
                  1
                )
          ],
      _vm.jobConfirmationDetailsVisible
        ? _c("job-confirmation-details", {
            attrs: {
              details: _vm.jobObj.getConfirmationDetails(),
              job: Object.assign({}, _vm.jobObj, {
                vehicle: _vm.jobObj.job_vehicle
              })
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }