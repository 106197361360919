<template>
  <div class="quick-buttons">
    <div
      ref="ref"
      v-for="item in options"
      :key="item.label"
      :class="{ 'quick-divider': item.divider, selected: item.value === value }"
    >
      <el-button
        type="text"
        size="mini"
        @click="$emit('click', item.value)"
        :ref="item.label"
      >
        {{ item.label }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { addDays } from 'date-fns'
import {
  formatToDb,
  getClosestDayOfNextWeek
} from '@/scripts/helpers/date.helpers'
export default {
  props: ['value', 'autoFocus'],
  data() {
    return {
      options: [
        { label: 'Today', value: formatToDb(new Date()) },
        { label: 'Tomorrow', value: formatToDb(addDays(new Date(), 1)) },
        {
          label: 'The Day After',
          divider: true,
          value: formatToDb(addDays(new Date(), 2))
        },
        { label: 'Next Monday', value: formatToDb(getClosestDayOfNextWeek(1)) },
        {
          label: 'Next Tuesday',
          value: formatToDb(getClosestDayOfNextWeek(2))
        },
        {
          label: 'Next Wednesday',
          value: formatToDb(getClosestDayOfNextWeek(3))
        },
        {
          label: 'Next Thursday',
          value: formatToDb(getClosestDayOfNextWeek(4))
        },
        { label: 'Next Friday', value: formatToDb(getClosestDayOfNextWeek(5)) },
        {
          label: 'Next Saturday',
          value: formatToDb(getClosestDayOfNextWeek(6))
        }
      ]
    }
  },
  methods: {
    click(v) {
      this.model.date = v
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-buttons {
  .selected {
    .el-button {
      font-weight: 600;
    }
  }
  @media screen and (min-width: 769px) {
    width: 100px;
    .quick-divider {
      padding-bottom: $padding-sm;
      margin-bottom: $padding-sm;
      border-bottom: solid 1px #ebeef5;
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .el-button {
      margin-right: $padding-sm;
    }
  }
}
</style>
