<template>
  <div class="t-space-y-3">
    <div class="t-flex t-px-8 t-space-x-6">
      <el-form-item
        prop="color"
        label="Color"
        :rules="{ required: true, message: 'Catalog color is required' }"
      >
        <el-color-picker
          ref="test"
          class="t-flex"
          :value="color"
          :predefine="predefinedColors"
          @change="$emit('update:color', $event)"
          @active-change="activeColorChanged"
        />
      </el-form-item>
    </div>

    <div class="t-flex t-px-8 t-space-x-6">
      <el-form-item
        prop="number"
        label="Name"
        :rules="{
          required: true,
          message: 'Name is required',
          trigger: 'blur'
        }"
      >
        <el-input :value="number" @input="$emit('update:number', $event)" />
      </el-form-item>
    </div>

    <div class="t-flex t-px-8 t-space-x-6">
      <el-form-item prop="description" label="Description">
        <el-input
          :value="description"
          @input="$emit('update:description', $event)"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    predefinedColors: Array,
    number: String,
    color: String,
    description: String
  },
  methods: {
    activeColorChanged() {
      this.$emit('update:color', this.$refs.test.color.value)
    }
  }
}
</script>
