var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { placement: "top", trigger: "hover" },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "div",
        [
          _vm.location
            ? [
                _vm._v(" Clocked in: "),
                _c("b", [_vm._v(_vm._s(_vm.clockIn))]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" Updated: "),
                _c("b", [_vm._v(_vm._s(_vm.updated))])
              ]
            : _vm.userdata
            ? [
                _vm._v(" Last clock in: "),
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.userdata.last_clock_in
                        ? _vm.humanDateTime(_vm.userdata.last_clock_in)
                        : "-"
                    )
                  )
                ]),
                _c("br"),
                _vm._v(" Last clock out: "),
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.userdata.last_clock_out
                        ? _vm.humanDateTime(_vm.userdata.last_clock_out)
                        : "-"
                    )
                  )
                ]),
                _c("br")
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.location
        ? _c("div", {
            staticClass: "tech-location-indicator",
            class: { clickable: _vm.$listeners.click },
            style: { color: _vm.color },
            attrs: { slot: "reference" },
            on: { click: _vm.onClick },
            slot: "reference"
          })
        : _vm.username
        ? _c("div", {
            staticClass: "tech-location-indicator offline",
            attrs: { slot: "reference" },
            slot: "reference"
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }