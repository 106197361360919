<template>
  <div class="header-v2" :class="{ 'header-v2-mobile': isMobile }">
    <a href="/" class="header-v2-logo">
      <img :src="logo" alt="Glassbiller Logo" />
    </a>
    <header-v-2-menu />
    <div class="t-flex t-items-center">
      <header-v-2-search
        :file-picker-key="filePickerKey"
        :allow-quick-books="allowQuickBooks"
      />
      <header-v-2-user
        :allow-quick-books="allowQuickBooks"
        :file-picker-key="filePickerKey"
      />
    </div>
  </div>
</template>

<script>
import logo from '@/assets/img/gb-logo-new.svg'
import HeaderV2Menu from './HeaderV2Menu'
import HeaderV2User from './HeaderV2User'
import HeaderV2Search from './HeaderV2Search'

export default {
  provide() {
    return {
      isMobile: this.isMobile
    }
  },
  props: ['filePickerKey', 'allowQuickBooks'],
  components: { HeaderV2Menu, HeaderV2User, HeaderV2Search },
  computed: {
    isMobile() {
      return document.querySelector('body').clientWidth <= 1070
    }
  },
  data() {
    return {
      logo
    }
  }
}
</script>
<style lang="scss">
.header-v2 {
  z-index: 999;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%), inset 1px 0px 0px rgb(0 0 0 / 8%);
  height: 56px;
  position: relative;
  width: 100vw;
  background: $grayscale-9;
  display: flex;
  padding: 0 $padding-sm;
  align-items: center;
  justify-content: space-between;
  .header-v2-logo {
    width: 230px;
    img {
      height: 32px;
    }
  }
  &.header-v2-mobile {
    .header-v2-logo {
      width: 146px;
      img {
        height: 26px;
      }
      margin-right: auto;
    }
  }
}
</style>
