import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class GlaxisLossAcknowledgement extends ObjectTracker {
  id = -1
  glaxis_claim_id = -1
  environment_id = ''
  session_id = ''
  origination_pid = ''
  origination_company_id = ''
  destination_pid = ''
  transaction_date = ''
  transaction_time = ''
  shop_id = -1
  created = -1
  modified = -1
  created_by = -1
  created_by_name = ''
  modified_by = -1
  modified_by_name = ''
  reference_number = ''
  accept_work = ''
  acceptance_contact = ''
  requested_appointment_date = ''
  requested_appointment_time = ''
  mobile_indicator = ''
  work_location_postal_code = ''
  vehicle_id_number = ''
  vehicle_id_source = ''
  vehicle_line_number = ''
  error_code = ''
  error_text = ''
  filename = ''
  return_code = ''
  return_text = ''
  xml = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static submitLossAcknowledgement (glaxisClaimId, lossAcknowledgementData, successCallbackFunction, errorCallbackFunction) {
    Api.post(`/api/glaxis-loss-acknowledgement/submit/${glaxisClaimId}`, lossAcknowledgementData).then(res => {
      successCallbackFunction(res.data)
    }, err => {
      errorCallbackFunction(err)
    })
  }
}
