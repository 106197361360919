<template>
  <div class="panel-container" v-loading="loadingJobVehicleData">
    <div v-if="!isSinglePartSearchModal" class="panel-header" data-toggle="collapse" data-target="#panel-part-search" aria-expanded="true">
      <div class="t-flex" v-if="!isMobile">
        <div class="t-flex-grow">
          Catalog <span v-for='(license, index) in user.nags_license' :key='index'> <flag :squared='false' :iso='license.nags_license_type.flag_iso'/></span>
        </div>
        <div
          @click.stop="orderingStatusDialogVisible = true"
          class="t-flex t-normal-case t-font-sans">
          <div class="t-text-purple-700">
            Live Ordering Available
          </div>
          <div class="t-rounded-full t-bg-purple-600 t-ml-2 t-px-2 t-text-gray-200">
            Beta
          </div>
        </div>
        <div id="accordion-total-invoice-toggler" style="display:none;color:darkviolet;font-style:italic;margin-left:10px;">
          Total Invoice: ${{totalToDisplay}}
        </div>
      </div>
      <div v-else>1
        Catalog <span v-for='(license, index) in user.nags_license' :key='index'> <flag :squared='false' :iso='license.nags_license_type.flag_iso'/></span>
        <span id="accordion-total-invoice-toggler" style="display:none;color:darkviolet;font-style:italic;font-size:10px">Total Invoice: ${{totalToDisplay}}</span>
      </div>
    </div>
    <div id="panel-part-search" class="show">
      <div class="panel-body" style='padding-top: 0px !important;'>
        <div class='row'>
          <div  class="col-lg-12 nags-update">
          <span>Last updated: January 11th, 2021</span><span class='float-right'>Next Update: May 10th, 2021</span>
          </div>
        </div>
        <div class="row">
          <div v-if="!isSinglePartSearchModal" class="col-lg-12">
            <div class="form-row mt-0">
              <a v-show="nagsPartsPartition.length > 0" href="javascript:void(0)" :class="{'label':true, 'label-primary':this.selectedSection == 'partition', 'label-gray':this.selectedSection != 'partition', 'label-sm':true}"
                @click="selectedSection = 'partition'; showJustInventory = false"><i class="fa fa-check" v-show="this.selectedSection == 'partition'"></i> Partition</a>
              <a v-show="nagsPartsWindshield.length > 0" href="javascript:void(0)" :class="{'label':true, 'label-primary':this.selectedSection == 'windshield', 'label-gray':this.selectedSection != 'windshield', 'label-sm':true}"
                @click="selectedSection = 'windshield'; showJustInventory = false"><i class="fa fa-check" v-show="this.selectedSection == 'windshield'"></i> Windshield</a>
              <a v-show="nagsPartsSide.length > 0" href="javascript:void(0)" :class="{'label':true, 'label-primary':this.selectedSection == 'side', 'label-gray':this.selectedSection != 'side', 'label-sm':true}"
                @click="selectedSection = 'side'; showJustInventory = false"><i class="fa fa-check" v-show="this.selectedSection == 'side'"></i> Side</a>
              <a v-show="nagsPartsDoor.length > 0" href="javascript:void(0)" :class="{'label':true, 'label-primary':this.selectedSection == 'door', 'label-gray':this.selectedSection != 'door', 'label-sm':true}"
                @click="selectedSection = 'door'; showJustInventory = false"><i class="fa fa-check" v-show="this.selectedSection == 'door'"></i> Door</a>
              <a v-show="nagsPartsRoof.length > 0" href="javascript:void(0)" :class="{'label':true, 'label-primary':this.selectedSection == 'roof', 'label-gray':this.selectedSection != 'roof', 'label-sm':true}"
                @click="selectedSection = 'roof'; showJustInventory = false"><i class="fa fa-check" v-show="this.selectedSection == 'roof'"></i> Roof</a>
              <a v-show="nagsPartsVent.length > 0" href="javascript:void(0)" :class="{'label':true, 'label-primary':this.selectedSection == 'vent', 'label-gray':this.selectedSection != 'vent', 'label-sm':true}"
                @click="selectedSection = 'vent'; showJustInventory = false"><i class="fa fa-check" v-show="this.selectedSection == 'vent'"></i> Vent</a>
              <a v-show="nagsPartsQuarter.length > 0" href="javascript:void(0)" :class="{'label':true, 'label-primary':this.selectedSection == 'quarter', 'label-gray':this.selectedSection != 'quarter', 'label-sm':true}"
                @click="selectedSection = 'quarter'; showJustInventory = false"><i class="fa fa-check" v-show="this.selectedSection == 'quarter'"></i> Quarter</a>
              <a v-show="nagsPartsBack.length > 0" href="javascript:void(0)" :class="{'label':true, 'label-primary':this.selectedSection == 'backWindow', 'label-gray':this.selectedSection != 'backWindow', 'label-sm':true}"
                @click="selectedSection = 'backWindow'; showJustInventory = false"><i class="fa fa-check" v-show="this.selectedSection == 'backWindow'"></i> Back Window</a>
              <a href="javascript:void(0)" :class="{'label':true, 'label-primary':this.selectedSection == 'search', 'label-gray':this.selectedSection != 'search', 'label-sm':true}"
                @click="selectedSection = 'search'; showJustInventory = false"><i  @click="alert('click 1'); this.selectedSection = 'search'" class="fa fa-check" v-show="this.selectedSection == 'search'"></i> Search</a>
              <a  href="javascript:void(0)" :class="{'label':true, 'label-primary':showJustInventory, 'label-gray':!showJustInventory, 'label-sm':true}"
                @click="showJustInventory = !showJustInventory; selectedSection = ''"><i class="fa fa-check" v-show="showJustInventory"></i> Inventory</a>
              <a v-if='showLoadNagsButton' style="font-weight:bold;" href="javascript:void(0)" :class="{'label':true, 'label-primary':true, 'label-sm':true, 'label-active-green':true}"
                @click="triggerNagsSearch()"> Load Nags</a>
            </div>
            <div class="mt-3" v-if="showJustInventory">
              <input type="text" placeholder="Search Inventory" v-model="inventorySearchText">
            </div>
          </div>
          <div class="col-lg-12" v-if="selectedSection == 'search'">
            <div class="form-row">
              <div class="form-group col-md-9">
                <label for="#">Search Parts:</label>
                <Autocomplete ref="partSearchAutocomplete" :smallInput="true" :dataSet="nagsSearchAutoCompleteResults" @selected="selectSearchAutoComplete"
                :filterInternally="false" :inLoading="loadingResults" :searchValue.sync="searchText" placeholder="(3-char. min)" :autoCompleteHeight="500" :showSpinner="true" :closeOnClick="true" inInputRef='inputRef'/>
              </div>
              <div class="form-group col-md-3">
                <label>&nbsp;</label>
                <input type="button" class="form-control form-control-sm btn btn-sm btn-gray" value="Clear" @click="clearButtonPressed()">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if='!showJustInventory'>
        <div ref="partResultsArea" class="scrollable-wrap" v-if="this.selectedSection !== 'search'">
          <PartResult
            v-for="(part, topIndex) in activeParts"
            :key="topIndex"
            :isSinglePartSearchModal="isSinglePartSearchModal"
            :part="part"
            :shopId="shopId"
            :topIndex="topIndex"
            :popupPartDetails.sync="popupPartDetails"
            :edi_locked="edi_locked"
            :insurancefleetAutoAddAdhesive="insurancefleetAutoAddAdhesive"
            :glassGraphics="glassGraphics"
            :inventoryParts='inventoryParts'
            :pricelevels='pricelevels'
            :selectedPriceLevel='selectedPriceLevel'
            :job='job'
            :showJustInventory='showJustInventory'
            :shopParts='shopParts'
            :trends="trends"
            :inventoryItems='inventoryItems'
            :shop='shop'
          />
        </div>
        <div ref="searchResultsArea" id="searchResultsArea" class="scrollable-wrap" v-if="this.selectedSection === 'search'">
          <template v-if="activeParts['Partition'] && activeParts['Partition'].length > 0">
            <div class="cell-sub-header cell-search-header"
              data-toggle="collapse" data-target="#partitionSearchResults" data-parent="#searchResultsArea">
              <h5>Partition</h5>
            </div>
            <div id="partitionSearchResults" class="collapse partResultCollapse show">
              <PartResult
                v-for="(part, topIndex) in activeParts['Partition']"
                :key="topIndex + '-partition'"
                :isSinglePartSearchModal="isSinglePartSearchModal"
                :part="part"
                :shopId="shopId"
                :topIndex="topIndex + '-partition'"
                :popupPartDetails.sync="popupPartDetails"
                :edi_locked="edi_locked"
                :insurancefleetAutoAddAdhesive="insurancefleetAutoAddAdhesive"
                :glassGraphics="glassGraphics"
                :inventoryParts='inventoryParts'
                :pricelevels='pricelevels'
                :selectedPriceLevel='selectedPriceLevel'
                :job='job'
                :showJustInventory='showJustInventory'
                :shopParts='shopParts'
                :trends="trends"
                :inventoryItems='inventoryItems'
                :shop='shop'
              />
            </div>
          </template>
          <template v-if="activeParts['Windshield'] && activeParts['Windshield'].length > 0">
            <div class="cell-sub-header cell-search-header"
              data-toggle="collapse" data-target="#windshieldSearchResults" data-parent="#searchResultsArea">
              <h5>Windshield</h5>
            </div>
            <div id="windshieldSearchResults" class="collapse partResultCollapse show">
              <PartResult
                v-for="(part, topIndex) in activeParts['Windshield']"
                :key="topIndex + '-windshield'"
                :isSinglePartSearchModal="isSinglePartSearchModal"
                :part="part"
                :shopId="shopId"
                :topIndex="topIndex + '-windshield'"
                :popupPartDetails.sync="popupPartDetails"
                :edi_locked="edi_locked"
                :insurancefleetAutoAddAdhesive="insurancefleetAutoAddAdhesive"
                :glassGraphics="glassGraphics"
                :inventoryParts='inventoryParts'
                :pricelevels='pricelevels'
                :selectedPriceLevel='selectedPriceLevel'
                :job='job'
                :showJustInventory='showJustInventory'
                :shopParts='shopParts'
                :trends="trends"
                :inventoryItems='inventoryItems'
                :shop='shop'
              />
            </div>
          </template>
          <template v-if="activeParts['Side'] && activeParts['Side'].length > 0">
            <div class="cell-sub-header cell-search-header"
              data-toggle="collapse" data-target="#sideSearchResults" data-parent="#searchResultsArea">
              <h5>Side</h5>
            </div>
            <div id="sideSearchResults" class="collapse partResultCollapseshow">
              <PartResult
                v-for="(part, topIndex) in activeParts['Side']"
                :key="topIndex + '-side'"
                :isSinglePartSearchModal="isSinglePartSearchModal"
                :part="part"
                :shopId="shopId"
                :topIndex="topIndex + '-side'"
                :popupPartDetails.sync="popupPartDetails"
                :edi_locked="edi_locked"
                :insurancefleetAutoAddAdhesive="insurancefleetAutoAddAdhesive"
                :glassGraphics="glassGraphics"
                :inventoryParts='inventoryParts'
                :pricelevels='pricelevels'
                :selectedPriceLevel='selectedPriceLevel'
                :job='job'
                :showJustInventory='showJustInventory'
                :shopParts='shopParts'
                :trends="trends"
                :inventoryItems='inventoryItems'
                :shop='shop'
              />
            </div>
          </template>
          <template v-if="activeParts['Door'] && activeParts['Door'].length > 0">
            <div class="cell-sub-header cell-search-header"
              data-toggle="collapse" data-target="#doorSearchResults" data-parent="#searchResultsArea">
              <h5>Door</h5>
            </div>
            <div id="doorSearchResults" class="collapse partResultCollapse show">
              <PartResult
                v-for="(part, topIndex) in activeParts['Door']"
                :key="topIndex + '-door'"
                :isSinglePartSearchModal="isSinglePartSearchModal"
                :part="part"
                :shopId="shopId"
                :topIndex="topIndex + '-door'"
                :popupPartDetails.sync="popupPartDetails"
                :edi_locked="edi_locked"
                :insurancefleetAutoAddAdhesive="insurancefleetAutoAddAdhesive"
                :glassGraphics="glassGraphics"
                :inventoryParts='inventoryParts'
                :pricelevels='pricelevels'
                :selectedPriceLevel='selectedPriceLevel'
                :job='job'
                :showJustInventory='showJustInventory'
                :shopParts='shopParts'
                :trends="trends"
                :inventoryItems='inventoryItems'
                :shop='shop'
              />
            </div>
          </template>
          <template v-if="activeParts['Roof'] && activeParts['Roof'].length > 0">
            <div class="cell-sub-header cell-search-header"
              data-toggle="collapse" data-target="#roofSearchResults" data-parent="#searchResultsArea">
              <h5>Roof</h5>
            </div>
            <div id="roofSearchResults" class="collapse partResultCollapse show">
              <PartResult
                v-for="(part, topIndex) in activeParts['Roof']"
                :key="topIndex + '-roof'"
                :isSinglePartSearchModal="isSinglePartSearchModal"
                :part="part"
                :shopId="shopId"
                :topIndex="topIndex + '-roof'"
                :popupPartDetails.sync="popupPartDetails"
                :edi_locked="edi_locked"
                :insurancefleetAutoAddAdhesive="insurancefleetAutoAddAdhesive"
                :glassGraphics="glassGraphics"
                :inventoryParts='inventoryParts'
                :pricelevels='pricelevels'
                :selectedPriceLevel='selectedPriceLevel'
                :job='job'
                :showJustInventory='showJustInventory'
                :shopParts='shopParts'
                :trends="trends"
                :inventoryItems='inventoryItems'
                :shop='shop'
              />
            </div>
          </template>
          <template v-if="activeParts['Vent'] && activeParts['Vent'].length > 0">
            <div class="cell-sub-header cell-search-header"
              data-toggle="collapse" data-target="#ventSearchResults" data-parent="#searchResultsArea">
              <h5>Vent</h5>
            </div>
            <div id="ventSearchResults" class="collapse partResultCollapse show">
              <PartResult
                v-for="(part, topIndex) in activeParts['Vent']"
                :key="topIndex + '-vent'"
                :isSinglePartSearchModal="isSinglePartSearchModal"
                :part="part"
                :shopId="shopId"
                :topIndex="topIndex + '-vent'"
                :popupPartDetails.sync="popupPartDetails"
                :edi_locked="edi_locked"
                :insurancefleetAutoAddAdhesive="insurancefleetAutoAddAdhesive"
                :glassGraphics="glassGraphics"
                :inventoryParts='inventoryParts'
                :pricelevels='pricelevels'
                :selectedPriceLevel='selectedPriceLevel'
                :job='job'
                :showJustInventory='showJustInventory'
                :shopParts='shopParts'
                :trends="trends"
                :inventoryItems='inventoryItems'
                :shop='shop'
              />
            </div>
          </template>
          <template v-if="activeParts['Quarter'] && activeParts['Quarter'].length > 0">
            <div class="cell-sub-header cell-search-header"
              data-toggle="collapse" data-target="#quarterSearchResults" data-parent="#searchResultsArea">
              <h5>Quarter</h5>
            </div>
            <div id="quarterSearchResults" class="collapse partResultCollapse show">
              <PartResult
                v-for="(part, topIndex) in activeParts['Quarter']"
                :key="topIndex + '-quarter'"
                :isSinglePartSearchModal="isSinglePartSearchModal"
                :part="part"
                :shopId="shopId"
                :topIndex="topIndex + '-quarter'"
                :popupPartDetails.sync="popupPartDetails"
                :edi_locked="edi_locked"
                :insurancefleetAutoAddAdhesive="insurancefleetAutoAddAdhesive"
                :glassGraphics="glassGraphics"
                :inventoryParts='inventoryParts'
                :pricelevels='pricelevels'
                :selectedPriceLevel='selectedPriceLevel'
                :job='job'
                :showJustInventory='showJustInventory'
                :shopParts='shopParts'
                :trends="trends"
                :inventoryItems='inventoryItems'
                :shop='shop'
              />
            </div>
          </template>
          <template v-if="activeParts['Back'] && activeParts['Back'].length > 0">
            <div class="cell-sub-header cell-search-header"
              data-toggle="collapse" data-target="#backSearchResults" data-parent="#searchResultsArea">
              <h5>Back</h5>
            </div>
            <div id="backSearchResults" class="collapse partResultCollapse show">
              <PartResult
                v-for="(part, topIndex) in activeParts['Back']"
                :key="topIndex + '-back'"
                :isSinglePartSearchModal="isSinglePartSearchModal"
                :part="part"
                :shopId="shopId"
                :topIndex="topIndex + '-back'"
                :popupPartDetails.sync="popupPartDetails"
                :edi_locked="edi_locked"
                :insurancefleetAutoAddAdhesive="insurancefleetAutoAddAdhesive"
                :glassGraphics="glassGraphics"
                :inventoryParts='inventoryParts'
                :pricelevels='pricelevels'
                :selectedPriceLevel='selectedPriceLevel'
                :job='job'
                :showJustInventory='showJustInventory'
                :shopParts='shopParts'
                :trends="trends"
                :inventoryItems='inventoryItems'
                :shop='shop'
              />
            </div>
          </template>
        </div>
      </div>
      <div ref="inventoryResultsArea" id="searchResultsArea" class="scrollable-wrap" v-if='showJustInventory'>
        <template >
          <div v-for="(part, index) in inventoryInStock" :key="index" class="cell-sub-header cell-search-header"
            data-toggle="collapse" data-parent="#searchResultsArea">
            <div class="row">
              <div class="col-lg-4">
                <h5 style="color:white">{{part.part_number}}</h5>
              </div>
              <div class="col-lg-2">
                Quantity:
              </div>
              <div class="col-lg-2">
                <p>{{part.quantity}}</p>
              </div>
              <div class="col-lg-2">
                Avg Cost:
              </div>
              <div class="col-lg-2">
                <p>${{part.avg_cost}}</p>
              </div>
            </div>
          </div>
          <!-- data-target="#manageInventoryPart" -->
          <!-- <div id="manageInventoryPart" class="collapse partResultCollapse show">
            check this out
          </div> -->
        </template>
      </div>
    </div>
    <NagsDetailsDialog
      v-if="!isSinglePartSearchModal"
      :shopId="shopId"
      :job="job"
      :popupPartDetails="popupPartDetails"
      :glassGraphics="glassGraphics"
      :partInventory.sync="partInventory"
      :inventoryDetails="popupPartInventoryDetails"
    />
    <OrderingStatusDialog
      :visible="orderingStatusDialogVisible"
      :mygrant="shop ? shop.mygrant_account_id : null"
      :pgw="vendorCredentials.filter(i => i.vendor === 'pgw')"
      :igc="shop ? shop.igc_account_id : null"
      :pilkington="shop ? shop.pilkington_username : null"
      :american="vendorCredentials.filter(i => i.vendor === 'american')"
      @close="orderingStatusDialogVisible = false"
    />
  </div>
</template>

<script>
import Autocomplete from '@/components/Helpers/Autocomplete.vue'
import PartResult from '@/components/PartSearch/PartResult.vue'
import Nags from '@/scripts/objects/nags'
import NagsDetailsDialog from '@/components/modals/NagsDetailsDialog.vue'
import OrderingStatusDialog from './OrderingStatusDialog'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import { mapState } from 'vuex'
import { getInventoryFromParts } from '@/scripts/helpers/inventory.helpers'
import $ from 'jquery'
import Api from '@/services/Api'
import * as R from 'ramda'
import { get, call } from 'vuex-pathify'

export default {
  props: ['shopId', 'vehiclePartResults', 'vehicleYear', 'vehicleMake', 'vehicleModel',
    'vehicleSubModel', 'vehicleStyle', 'edi_locked', 'insurancefleetAutoAddAdhesive',
    'inventoryParts', 'pricelevels', 'selectedPriceLevel', 'job', 'shopParts',
    'totalToDisplay', 'isSinglePartSearchModal', 'shop', 'startInputValue'],
  data () {
    return {
      selectedSection: 'search',
      searchText: '',
      selectedSearchResult: {},
      nagsSearchResults: [],
      nagsSearchData: [],
      nagsSearchAutoCompleteResults: [],
      nagsPartsPartition: [],
      nagsPartsWindshield: [],
      nagsPartsSide: [],
      nagsPartsDoor: [],
      nagsPartsRoof: [],
      nagsPartsVent: [],
      nagsPartsQuarter: [],
      nagsPartsBack: [],
      timeout: null,
      popupPartDetails: [],
      partInventory: 10,
      selectedSearchResultOption: '',
      autoSelectSearchResultOption: false,
      shopDefaultPGWWarehouse: '',
      showJustInventory: false,
      inventorySearchText: '',
      loadingResults: false,
      inventoryItems: [],
      trends: {},
      orderingStatusDialogVisible: false
    }
  },
  computed: {
    activeParts () {
      if (this.selectedSection === 'partition') {
        return this.nagsPartsPartition
      } else if (this.selectedSection === 'windshield') {
        return this.nagsPartsWindshield
      } else if (this.selectedSection === 'side') {
        return this.nagsPartsSide
      } else if (this.selectedSection === 'door') {
        return this.nagsPartsDoor
      } else if (this.selectedSection === 'roof') {
        return this.nagsPartsRoof
      } else if (this.selectedSection === 'vent') {
        return this.nagsPartsVent
      } else if (this.selectedSection === 'quarter') {
        return this.nagsPartsQuarter
      } else if (this.selectedSection === 'backWindow') {
        return this.nagsPartsBack
      } else if (this.selectedSection === 'search') {
        return this.nagsSearchResults
      } else {
        return null
      }
    },
    popupPartInventoryDetails () {
      if(this.inventoryParts) {
        for (var i = 0; i < this.inventoryParts.length; i++) {
          if (this.inventoryParts[i].part_number === this.popupPartDetails.partno) {
            return this.inventoryParts[i]
          }
        }
      }
      return null
    },
    inventoryInStock () {
      // filtering inventory parts to display by 1) quantity > 0 and 2) part_number includes search value
      let stockedInventory = []
      if(this.inventoryParts) {
        for (var j = 0; j < this.inventoryParts.length; j++) {
          let loweredPartNumber = this.inventoryParts[j].part_number.toLowerCase()
          let loweredSearchValue = this.inventorySearchText.toLowerCase()
          if (this.inventoryParts[j].quantity > 0 && loweredPartNumber.includes(loweredSearchValue)) {
            stockedInventory.push(this.inventoryParts[j])
          }
        }
      }
      return stockedInventory
    },
    glassGraphics () {
      return (this.vehiclePartResults && this.vehiclePartResults['glass_graphics']) ? this.vehiclePartResults['glass_graphics'] : null
    },
    showLoadNagsButton () {
      if (this.vehiclePartResults && this.vehiclePartResults.Openings) {
        return false
      }
      if (this.vehicleYear &&
        this.vehicleMake &&
        this.vehicleModel &&
        this.vehicleStyle &&
        this.shop.id) {
          return true
        }

      return false
    },
    ...mapState(['loadingJobVehicleData', 'user']),
    vendorCredentials: get('partVendors/credentials')
  },
  watch: {
    'searchText': function (val) {
      clearTimeout(this.timeout)
      if (val.length >= 3) {
        let currentSearchText = val
        let self = this
        this.timeout = setTimeout(function () {
          if (self.searchText === currentSearchText) {
            self.performNagsSearch(self.searchText)
          }
        }, 800)
      }
    },
    'selectedSection': async function (val) {
      if (this.$refs.partResultsArea) {
        this.$refs.partResultsArea.scrollTop = 0
        $('.partResultCollapse').collapse('hide')
      }
    },
    'vehiclePartResults': function (val) {
      // Error in callback for watcher "vehiclePartResults": "TypeError: Cannot read property 'length' of undefined"
      var openings = val ? val['Openings'] : null
      //
      this.nagsPartsPartition = []
      this.nagsPartsWindshield = []
      this.nagsPartsSide = []
      this.nagsPartsDoor = []
      this.nagsPartsRoof = []
      this.nagsPartsVent = []
      this.nagsPartsQuarter = []
      this.nagsPartsBack = []
      if (openings != null) {
        //
        this.selectedSection = 'search'
        if (openings['Partition']) {
          this.nagsPartsPartition = openings['Partition']
          if (this.selectedSection === 'search') {
            this.selectedSection = 'partition'
          }
        }
        if (openings['Windshield']) {
          this.nagsPartsWindshield = openings['Windshield']
          if (this.selectedSection === 'search') {
            this.selectedSection = 'windshield'
          }
        }
        if (openings['Side']) {
          this.nagsPartsSide = openings['Side']
          if (this.selectedSection === 'search') {
            this.selectedSection = 'side'
          }
        }
        if (openings['Door']) {
          this.nagsPartsDoor = openings['Door']
          if (this.selectedSection === 'search') {
            this.selectedSection = 'door'
          }
        }
        if (openings['Roof']) {
          this.nagsPartsRoof = openings['Roof']
          if (this.selectedSection === 'search') {
            this.selectedSection = 'roof'
          }
        }
        if (openings['Vent']) {
          this.nagsPartsVent = openings['Vent']
          if (this.selectedSection === 'search') {
            this.selectedSection = 'vent'
          }
        }
        if (openings['Quarter']) {
          this.nagsPartsQuarter = openings['Quarter']
          if (this.selectedSection === 'search') {
            this.selectedSection = 'quarter'
          }
        }
        if (openings['Back']) {
          this.nagsPartsBack = openings['Back']
          if (this.selectedSection === 'search') {
            this.selectedSection = 'backWindow'
          }
        }

        var temp = ''
        if ((openings['Back'] && openings['Back'].length > 0) || (openings['Quarter'] && openings['Quarter'].length > 0) || (openings['Vent'] && openings['Vent'].length > 0) ||
          (openings['Roof'] && openings['Roof'].length > 0) || (openings['Door'] && openings['Door'].length > 0) || (openings['Side'] && openings['Side'].length > 0) ||
          (openings['Windshield'] && openings['Windshield'].length > 0) || (openings['Partition'] && openings['Partition'].length > 0)) {
          var started = false
          var tempKeys = Object.keys(openings)
          this.getShopInventoryForSearchParts(openings)
          for (var i = 0; i < tempKeys.length; i++) {
            for (var j = 0; j < openings[tempKeys[i]].length; j++) {
              let tempPart = openings[tempKeys[i]][j].detail.partno
              if (!started) {
                temp += tempPart
                started = true
              } else {
                temp += '--' + tempPart
              }
            }
          }
        }
      }
    },
    'nagsSearchData': function (val) {
      let tempOpenings = {}
      if (val.Search && Object.keys(val.Search).length) {
        for (let i = 0; i < Object.keys(val.Search).length; i++) {
          let opening = Object.keys(val.Search)[i]
          if (tempOpenings[opening]) {
            tempOpenings[opening] = [...tempOpenings[opening], ...val.Search[opening]]
          } else {
            tempOpenings[opening] = [...val.Search[opening]]
          }
        }
      }
      if (val.SearchOEM && Object.keys(val.SearchOEM).length) {
        for (let i = 0; i < Object.keys(val.SearchOEM).length; i++) {
          let opening = Object.keys(val.SearchOEM)[i]
          if (tempOpenings[opening]) {
            tempOpenings[opening] = [...tempOpenings[opening], ...val.SearchOEM[opening]]
          } else {
            tempOpenings[opening] = [...val.SearchOEM[opening]]
          }
        }
      }
      if (val.HW_CFGS && Object.keys(val.HW_CFGS).length) {
        for (let i = 0; i < Object.keys(val.HW_CFGS).length; i++) {
          let opening = Object.keys(val.HW_CFGS)[i]
          if (tempOpenings[opening]) {
            tempOpenings[opening] = [...tempOpenings[opening], ...val.HW_CFGS[opening]]
          } else {
            tempOpenings[opening] = [...val.HW_CFGS[opening]]
          }
        }
      }
      // Error in callback for watcher "vehiclePartResults": "TypeError: Cannot read property 'length' of undefined"
      var openings = Object.keys(tempOpenings).length ? tempOpenings : null // val.search ? val['Openings'] : null

      if (openings != null) {
        if ((openings['Back'] && openings['Back'].length > 0) || (openings['Quarter'] && openings['Quarter'].length > 0) || (openings['Vent'] && openings['Vent'].length > 0) ||
          (openings['Roof'] && openings['Roof'].length > 0) || (openings['Door'] && openings['Door'].length > 0) || (openings['Side'] && openings['Side'].length > 0) ||
          (openings['Windshield'] && openings['Windshield'].length > 0) || (openings['Partition'] && openings['Partition'].length > 0)) {
          this.getShopInventoryForSearchParts(openings)
        }
      }
    },
    'shopId': async function (val) {
      this.redoNagsSearchBecauseIdChange(val)
      if (val > 0) {
        this.getGlaxisWarehouses(this.shopId)
        this.getPilkingtonWarehouses()
        this.getCredentials(this.shopId)
      }
    },
    'activeParts': async function (val, old) {
      if (this.selectedSection !== 'search' && val && !R.equals(val, old)) {
        const parts = R.map(R.path(['detail', 'partno']))(val)
        const { data } = await Api.get('/api/nags/trends', { params: { parts } })
        this.trends = data
      }
    },
    'startInputValue': function (val) {
      var self = this
      setTimeout(() => {
        self.$refs.partSearchAutocomplete.$el.children[0].children[0].focus()
      }, 600)
      this.searchText = val
    }
  },
  mounted () {
    // check loading vehicle and set to false if it was left true for some reason
    if (this.loadingJobVehicleData) {
      this.$store.commit('toggleLoadingJobVehicleData')
    }
  },
  created () {
    this.$root.$on('updateAutoAddParts', this.updateAutoAddPartsListener)
    this.$root.$on('removeAutoAddSavedPart', this.removeAutoAddSavedPartListener)
    this.$root.$on('partSearchClearButtonPress', this.clearButtonPressed)
    this.$root.$on('refreshNagsInventory', this.refreshNagsInventory)
  },
  beforeDestroy () {
    this.$root.$off('updateAutoAddParts', this.updateAutoAddPartsListener)
    this.$root.$off('removeAutoAddSavedPart', this.removeAutoAddSavedPartListener)
    this.$root.$off('partSearchClearButtonPress', this.clearButtonPressed)
    this.$root.$off('refreshNagsInventory', this.refreshNagsInventory)
  },
  methods: {
    getGlaxisWarehouses: call('partVendors/getGlaxisWarehouses'),
    getPilkingtonWarehouses: call('partVendors/getPilkingtonWarehouses'),
    getCredentials: call('partVendors/getCredentials'),
    getInventoryFromParts,
    refreshNagsInventory () {
      if (this.vehiclePartResults && this.vehiclePartResults.Openings) {
        this.getShopInventoryForSearchParts(this.vehiclePartResults.Openings)
      }
    },
    async getShopInventoryForSearchParts (openings) {
      var tempForInventory = []
      this.inventoryItems = []
      var tempKeys = Object.keys(openings)
      for (var i = 0; i < tempKeys.length; i++) {
        for (var j = 0; j < openings[tempKeys[i]].length; j++) {
          let tempPart = openings[tempKeys[i]][j].detail.partno
          // adding extra bit to get all the part numbers
          let tempChildKeys = Object.keys(openings[tempKeys[i]][j].children)

          for (var k = 0; k < openings[tempKeys[i]][j].children.length; k++) {
            for (var l = 0; l < openings[tempKeys[i]][j].children[k].children.length; l++) {
              if (!tempForInventory.includes(openings[tempKeys[i]][j].children[k].children[l].detail.partno)) {
                tempForInventory.push(openings[tempKeys[i]][j].children[k].children[l].detail.partno)
              }
            }
          }
          if (!tempForInventory.includes(tempPart)) tempForInventory.push(tempPart)
        }
      }
      var self = this
      if (this.shop.inventory_tracking === 1) {
        this.getInventoryFromParts(this.shop.id, tempForInventory.join('--'), (response) => {
          self.inventoryItems = response
        })
      }
    },
    clearButtonPressed () {
      this.searchText = ''
      this.nagsSearchAutoCompleteResults=[]
      this.nagsSearchResults=[]
    },
    selectSearchAutoComplete (data) {
      this.selectedSearchResultOption = data
      if (data['action'] === 'selectAllNags') {
        this.nagsSearchResults = this.getPartsArraySplitByType(this.getAllNagsParts())
      } else if (data['action'] === 'selectAllOEM') {
        this.nagsSearchResults = this.getPartsArraySplitByType(this.getAllOEMParts())
      } else if (data['action'] === 'hideNags') {
        this.updateNagsSearchAutoCompleteResults(true, false)
        this.$refs.partSearchAutocomplete.revealAutocomplete()
      } else if (data['action'] === 'hideOEM') {
        this.updateNagsSearchAutoCompleteResults(false, true)
        this.$refs.partSearchAutocomplete.revealAutocomplete()
      } else if (data['action'] === 'showNags' || data['action'] === 'showOEM') {
        this.updateNagsSearchAutoCompleteResults(false, false)
        this.$refs.partSearchAutocomplete.revealAutocomplete()
      } else if (data['action'] === 'selectItem') {
        this.nagsSearchResults = this.getPartsArraySplitByType([{'value': data}])
      }
    },
    performNagsSearch (searchVal, phase = 1, hwCfgIds = '', shopId = this.shopId) {
      let self = this
      if (phase === 1) {
        self.loadingResults = true
        self.nagsSearchResults = []
        self.nagsSearchData = []
        self.nagsSearchAutoCompleteResults = []
      }
      Nags.nagsSearch(searchVal, phase, self.vehicleYear, self.vehicleMake, self.vehicleModel, self.vehicleSubModel, self.vehicleStyle, hwCfgIds, shopId, function (results) {
        if (self.searchText === searchVal) {
          self.loadingResults = false
          if (phase === 1) {
            self.nagsSearchResults = []
            self.nagsSearchData = []
            self.nagsSearchAutoCompleteResults = []
          }

          if (!results || (results['empty'] && results['empty'] === 'true')) {

            return
          }

          if (phase === 2) {
            // HW parts have arrived
            if (typeof results['HW_CFGS'] !== 'undefined') {

              // stupid hoisting with linting gives errors so we do this here
              var key = null
              var object = null
              var nagsHwCfgIds = null
              var finalKitCategoriesAndParts = []
              var newKitCategoriesAndParts = []
              var kitCategoryAndParts = null
              var kitCategory = null
              var found = false
              var existingKitCategoryAndParts = null
              var potentialNewChild = null
              var skipIt = null
              var testChild = null
              var theSame = null
              var keys = Object.keys(self.nagsSearchData['Search'])
              if (keys.length > 0) {
                for (var i = 0; i < keys.length; i++) {
                  key = keys[i]
                  if (self.nagsSearchData['Search'][key] && Array.isArray(self.nagsSearchData['Search'][key])) {
                    for (var h = 0; h < self.nagsSearchData['Search'][key].length; h++) {
                      object = self.nagsSearchData['Search'][key][h]

                      nagsHwCfgIds = object.detail.hw_cfg_ids.split(';')
                      if (nagsHwCfgIds && Array.isArray(nagsHwCfgIds)) {

                        finalKitCategoriesAndParts = []
                        newKitCategoriesAndParts = []
                        for (var j = 0; j < nagsHwCfgIds.length; ++j) {

                          if (typeof results['HW_CFGS'][nagsHwCfgIds[j]] !== 'undefined') {
                            newKitCategoriesAndParts = results['HW_CFGS'][nagsHwCfgIds[j]]

                            for (var m = 0; m < newKitCategoriesAndParts.length; ++m) {
                              kitCategoryAndParts = newKitCategoriesAndParts[m]
                              if (j === 0) {
                                // initialize
                                finalKitCategoriesAndParts.push(kitCategoryAndParts)
                              } else {
                                // append
                                kitCategory = kitCategoryAndParts.detail.name
                                found = false
                                for (var v = 0; v < finalKitCategoriesAndParts.length; ++v) {
                                  existingKitCategoryAndParts = finalKitCategoriesAndParts[v]
                                  if (existingKitCategoryAndParts.detail.name === kitCategory) {
                                    found = true
                                    // do append children
                                    if (typeof kitCategoryAndParts.children !== 'undefined' && Array.isArray(kitCategoryAndParts.children)) {
                                      for (var w = 0; w < kitCategoryAndParts.children.length; ++w) {
                                        potentialNewChild = kitCategoryAndParts.children[w]
                                        if (typeof potentialNewChild === 'object' && typeof potentialNewChild.detail === 'object') {
                                          // Do not append duplicates
                                          skipIt = false
                                          if (typeof existingKitCategoryAndParts.children !== 'undefined' && Array.isArray(existingKitCategoryAndParts.children)) {
                                            for (var a = 0; a < existingKitCategoryAndParts.children.length; ++a) {
                                              testChild = existingKitCategoryAndParts.children[a]
                                              theSame = self.compareDetails(testChild.detail, potentialNewChild.detail)
                                              if (theSame) {
                                                skipIt = true
                                              }
                                            }
                                          }
                                          if (!skipIt) {
                                            if (typeof existingKitCategoryAndParts.children === 'undefined') {
                                              existingKitCategoryAndParts.children = []
                                            }
                                            existingKitCategoryAndParts.children.push(potentialNewChild)
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                                if (!found) {
                                  // Create the first entry for the category (Adhesive, Molding, or Miscellaneous)
                                  finalKitCategoriesAndParts.push(kitCategoryAndParts)
                                }
                              }
                            }
                          }
                        }
                        finalKitCategoriesAndParts.sort(function (a, b) {
                          if (a.detail.name === 'Adhesives') {
                            return false // first
                          }
                          if (b.detail.name === 'Adhesives') {
                            return true
                          }
                          if (a.detail.name === 'Moldings') {
                            return false // second
                          }
                          if (b.detail.name === 'Moldings') {
                            return true
                          }
                          // Only Misc or Miscellaneous remains (both must be that)
                          return false
                        })




                        for (var n = finalKitCategoriesAndParts.length - 1; n >= 0; n--) {
                          object['children'].unshift(finalKitCategoriesAndParts[n])
                        }

                      }
                    }
                  }
                }
              } else {
                keys = Object.keys(self.nagsSearchData['SearchOEM'])
                for (var b = 0; b < keys.length; b++) {
                  key = keys[b]
                  if (self.nagsSearchData['SearchOEM'][key] && Array.isArray(self.nagsSearchData['SearchOEM'][key])) {
                    for (var c = 0; c < self.nagsSearchData['SearchOEM'][key].length; c++) {
                      object = self.nagsSearchData['SearchOEM'][key][c]

                      nagsHwCfgIds = object.detail.hw_cfg_ids.split(';')
                      if (nagsHwCfgIds && Array.isArray(nagsHwCfgIds)) {

                        finalKitCategoriesAndParts = []
                        newKitCategoriesAndParts = []
                        for (var d = 0; d < nagsHwCfgIds.length; ++d) {

                          if (typeof results['HW_CFGS'][nagsHwCfgIds[d]] !== 'undefined') {
                            newKitCategoriesAndParts = results['HW_CFGS'][nagsHwCfgIds[d]]

                            for (var e = 0; e < newKitCategoriesAndParts.length; ++e) {
                              kitCategoryAndParts = newKitCategoriesAndParts[e]
                              if (d === 0) {
                                // initialize
                                finalKitCategoriesAndParts.push(kitCategoryAndParts)
                              } else {
                                // append
                                kitCategory = kitCategoryAndParts.detail.name
                                found = false
                                for (var f = 0; f < finalKitCategoriesAndParts.length; ++f) {
                                  existingKitCategoryAndParts = finalKitCategoriesAndParts[f]
                                  if (existingKitCategoryAndParts.detail.name === kitCategory) {
                                    found = true
                                    // do append children
                                    if (typeof kitCategoryAndParts.children !== 'undefined' && Array.isArray(kitCategoryAndParts.children)) {
                                      for (var g = 0; g < kitCategoryAndParts.children.length; ++g) {
                                        potentialNewChild = kitCategoryAndParts.children[g]
                                        if (typeof potentialNewChild === 'object' && typeof potentialNewChild.detail === 'object') {
                                          // Do not append duplicates
                                          skipIt = false
                                          if (typeof existingKitCategoryAndParts.children !== 'undefined' && Array.isArray(existingKitCategoryAndParts.children)) {
                                            for (var x = 0; x < existingKitCategoryAndParts.children.length; ++x) {
                                              testChild = existingKitCategoryAndParts.children[x]
                                              theSame = self.compareDetails(testChild.detail, potentialNewChild.detail)
                                              if (theSame) {
                                                skipIt = true
                                              }
                                            }
                                          }
                                          if (!skipIt) {
                                            if (typeof existingKitCategoryAndParts.children === 'undefined') {
                                              existingKitCategoryAndParts.children = []
                                            }
                                            existingKitCategoryAndParts.children.push(potentialNewChild)
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                                if (!found) {
                                  // Create the first entry for the category (Adhesive, Molding, or Miscellaneous)
                                  finalKitCategoriesAndParts.push(kitCategoryAndParts)
                                }
                              }
                            }
                          }
                        }
                        finalKitCategoriesAndParts.sort(function (a, b) {
                          if (a.detail.name === 'Adhesives') {
                            return false // first
                          }
                          if (b.detail.name === 'Adhesives') {
                            return true
                          }
                          if (a.detail.name === 'Moldings') {
                            return false // second
                          }
                          if (b.detail.name === 'Moldings') {
                            return true
                          }
                          // Only Misc or Miscellaneous remains (both must be that)
                          return false
                        })




                        for (var o = finalKitCategoriesAndParts.length - 1; o >= 0; o--) {
                          object['children'].unshift(finalKitCategoriesAndParts[o])
                        }

                      }
                    }
                  }
                }
              }
            }

            // we have all the part numbers

          } else if (phase === 1) {
            self.nagsSearchData = results


            self.updateNagsSearchAutoCompleteResults(false, false)
            // ****************************************************************************************************
            // Initiate phase 2 - retrieve the HW/kit parts in the background,
            // after populating the autocomplete with the list of high-level NAGS glass parts
            // ****************************************************************************************************
            if (typeof results['hw_cfg_ids'] !== 'undefined' && Array.isArray(results['hw_cfg_ids'])) {
              // Initiate phase 2 - call server for the hw kit parts which will load in the background while the autocomplete dropdown is showing for the user.
              // Note that the data is not needed until the user actually clicks on a NAGS glass part in the NAGS pane to expand it.
              // It is *not* needed when the user clicks to select an entry in the autocomplete dropdown.

              self.performNagsSearch(searchVal, 2, results['hw_cfg_ids'])
            }
          }
        }
        if (self.autoSelectSearchResultOption === true) {
          self.selectSearchAutoComplete(self.selectedSearchResultOption)
          self.autoSelectSearchResultOption = false
        }
      })
    },
    redoNagsSearchBecauseIdChange (newShopId) {
      if (this.searchText !== '' && this.selectedSearchResultOption !== '') {
        this.performNagsSearch(this.searchText, undefined, undefined, newShopId)
        this.autoSelectSearchResultOption = true
      } else {

      }
    },
    updateNagsSearchAutoCompleteResults (hideNags, hideOEM) {
      let self = this
      self.nagsSearchAutoCompleteResults = []
      var autocompleteDataNAGS = []
      var autocompleteDataOEM = []

      if (typeof self.nagsSearchData === 'object') {
        // Obtain the data for the NAGS glass ID matches
        // in the format required by the autocomplete API

        autocompleteDataNAGS = self.getAllNagsParts()
        autocompleteDataOEM = self.getAllOEMParts()
      }

      if (!hideNags && !hideOEM && autocompleteDataNAGS.length > 0 && autocompleteDataOEM.length > 0) {
        self.nagsSearchAutoCompleteResults.push({
          'name': 'Select all NAGS matches',
          'value': {
            'opening': '',
            'name': 'Select all NAGS matches',
            'action': 'selectAllNags'
          }
        })
        self.nagsSearchAutoCompleteResults.push({
          'name': '... hide NAGS matches',
          'value': {
            'opening': '',
            'name': '... hide NAGS matches',
            'action': 'hideNags'
          }
        })
        self.nagsSearchAutoCompleteResults.push({
          'name': 'Select all OEM matches',
          'value': {
            'opening': '',
            'name': 'Select all OEM matches',
            'action': 'selectAllOEM'
          }
        })
        self.nagsSearchAutoCompleteResults.push({
          'name': '... hide OEM matches',
          'value': {
            'opening': '',
            'name': '... hide OEM matches',
            'action': 'hideOEM'
          },
          'breakAfter': true
        })
        self.nagsSearchAutoCompleteResults.push({
          'name': '*** NAGS matches ***',
          'value': {
            'opening': '',
            'name': '*** NAGS matches ***',
            'action': 'selectAllNags'
          }
        })
        self.nagsSearchAutoCompleteResults = self.nagsSearchAutoCompleteResults.concat(autocompleteDataNAGS)
        self.nagsSearchAutoCompleteResults.push({
          'name': '*** OEM matches ***',
          'value': {
            'opening': '',
            'name': '*** OEM matches ***',
            'action': 'selectAllOEM'
          },
          'breakBefore': true
        })
        self.nagsSearchAutoCompleteResults = self.nagsSearchAutoCompleteResults.concat(autocompleteDataOEM)
      } else if (!hideNags && autocompleteDataNAGS.length > 0) {
        self.nagsSearchAutoCompleteResults.push({
          'name': 'Select all NAGS matches',
          'value': {
            'opening': '',
            'name': 'Select all NAGS matches',
            'action': 'selectAllNags'
          },
          'breakAfter': true
        })
        if (autocompleteDataOEM.length > 0) {
          self.nagsSearchAutoCompleteResults.push({
            'name': '... show OEM matches',
            'value': {
              'opening': '',
              'name': '... show OEM matches',
              'action': 'showOEM'
            },
            'breakAfter': true
          })
        }
        self.nagsSearchAutoCompleteResults.push({
          'name': '*** NAGS matches ***',
          'value': {
            'opening': '',
            'name': '*** NAGS matches ***',
            'action': 'selectAllNags'
          }
        })
        self.nagsSearchAutoCompleteResults = self.nagsSearchAutoCompleteResults.concat(autocompleteDataNAGS)
      } else if (!hideOEM && autocompleteDataOEM.length > 0) {
        self.nagsSearchAutoCompleteResults.push({
          'name': 'Select all OEM matches',
          'value': {
            'opening': '',
            'name': 'Select all OEM matches',
            'action': 'selectAllOEM'
          },
          'breakAfter': true
        })
        if (autocompleteDataNAGS.length > 0) {
          self.nagsSearchAutoCompleteResults.push({
            'name': '... show NAGS matches',
            'value': {
              'opening': '',
              'name': '... show NAGS matches',
              'action': 'showNags'
            },
            'breakAfter': true
          })
        }
        self.nagsSearchAutoCompleteResults.push({
          'name': '*** OEM matches ***',
          'value': {
            'opening': '',
            'name': '*** OEM matches ***',
            'action': 'selectAllOEM'
          }
        })
        self.nagsSearchAutoCompleteResults = self.nagsSearchAutoCompleteResults.concat(autocompleteDataOEM)
      }
    },
    getAllNagsParts () {
      var nagsParts = []
      let self = this

      if (typeof self.nagsSearchData['Search'] === 'object') {
        nagsParts = [].concat.apply([], Object.keys(self.nagsSearchData['Search']).map(function (opening) {
          var nagsGlassParts = self.nagsSearchData['Search'][opening]
          // Each key ('opening') is an opening - i.e., Door, Windshield, Side, etc.
          // Each value ('nagsGlassParts') is an array of objects, each of which corresponds
          // to the given NAGS glass high-level part...
          // ...See 'populate_nags_glass' in the NAGS model class in the server code,
          // specifically the section where it populates the "$type" variable with 'Door', 'Windshield', etc.,
          // for details on the data format.
          var nagsGlassPartsForAutocompleteList = []
          if (Array.isArray(nagsGlassParts)) {
            nagsGlassPartsForAutocompleteList = Object.keys(nagsGlassParts).map(function (index) {
              var nagsGlassPart = nagsGlassParts[index]
              if (typeof nagsGlassPart === 'object' && typeof nagsGlassPart['detail'] === 'object') {
                // The autocomplete component can accept an array of objects corresponding to the list entries
                // in the autocomplete list.  However, to make this work, each such object must have a data member
                // called 'value' and optionally 'name', which we do use.
                // So create these data members now.
                if (typeof nagsGlassPart['detail']['level'] !== 'undefined' && typeof nagsGlassPart['detail']['nagsid'] !== 'undefined') {
                  if (parseInt(nagsGlassPart['detail']['level']) === 1 && nagsGlassPart['detail']['nagsid'].length > 0) {
                    nagsGlassPart['opening'] = opening
                    var openingText = opening
                    if (openingText === 'Back') {
                      openingText = 'Back Window'
                    }
                    nagsGlassPart['name'] = '(' + openingText + ') ' + nagsGlassPart['detail']['partno']
                    nagsGlassPart['value'] = nagsGlassPart['detail']['partno']
                    nagsGlassPart['action'] = 'selectItem'

                    var autoCompleteResult = {}
                    autoCompleteResult['name'] = nagsGlassPart['name']
                    autoCompleteResult['value'] = nagsGlassPart
                    return autoCompleteResult
                  }
                }
              }
              return {
                'name': '',
                'value': {
                  'opening': '',
                  'value': '',
                  'name': '',
                  'action': 'none'
                }
              }
            })
          }
          // The resulting array from the following (inner) call to 'map',
          // when returned from the current (outer) call to 'map',
          // will simply be flattened into the resulting outer array.
          return nagsGlassPartsForAutocompleteList
        }))
        if (!nagsParts) {
          nagsParts = []
        }
      }
      return nagsParts
    },
    getAllOEMParts () {
      var oemParts = []
      let self = this
      // Obtain the data for the OEM glass ID matches
      // in the format required by the autocomplete API
      if (typeof self.nagsSearchData['SearchOEM'] === 'object') {
        oemParts = [].concat.apply([], Object.keys(self.nagsSearchData['SearchOEM']).map(function (opening) {
          var nagsGlassParts = self.nagsSearchData['SearchOEM'][opening]
          // Each key ('opening') is an opening - i.e., Door, Windshield, Side, etc.
          // Each value ('nagsGlassParts') is an array of objects, each of which corresponds to the given NAGS glass high-level part...
          // ...See 'populate_nags_glass' in the NAGS model class in the server code,
          // specifically the section where it populates the "$type" variable with 'Door', 'Windshield', etc.,
          // for details on the data format.

          var nagsGlassPartsForAutocompleteList = []
          if (Array.isArray(nagsGlassParts)) {
            nagsGlassPartsForAutocompleteList = Object.keys(nagsGlassParts).map(function (index) {
              var nagsGlassPart = nagsGlassParts[index]
              if (typeof nagsGlassPart === 'object' && typeof nagsGlassPart['detail'] === 'object') {
                // The autocomplete component can accept an array of objects corresponding to the list entries
                // in the autocomplete list.  However, to make this work, each such object must have a data member
                // called 'value' and optionally 'name', which we do use.
                // So create these data members now.
                if (typeof nagsGlassPart['detail']['level'] !== 'undefined' && typeof nagsGlassPart['detail']['nagsid'] !== 'undefined') {
                  if (parseInt(nagsGlassPart['detail']['level']) === 1 && nagsGlassPart['detail']['nagsid'].length > 0) {
                    nagsGlassPart['opening'] = opening
                    var openingText = opening
                    if (openingText === 'Back') {
                      openingText = 'Back Window'
                    }
                    nagsGlassPart['name'] = '(' + openingText + ') ' + nagsGlassPart['detail']['name']
                    nagsGlassPart['action'] = 'selectItem'

                    var autoCompleteResult = {}
                    autoCompleteResult['name'] = nagsGlassPart['name']
                    autoCompleteResult['value'] = nagsGlassPart
                    return autoCompleteResult
                  }
                }
              }
              return {
                'name': '',
                'value': {
                  'opening': '',
                  'value': '',
                  'name': '',
                  'action': 'none'
                }
              }
            })
          }
          // The resulting array from the following (inner) call to 'map',
          // when returned from the current (outer) call to 'map',
          // will simply be flattened into the resulting outer array.
          return nagsGlassPartsForAutocompleteList
        }))

        if (!oemParts) {
          oemParts = []
        }
      }
      return oemParts
    },
    getPartsArraySplitByType (partsArray) {
      var array = {}
      for (var i = 0; i < partsArray.length; i++) {
        var part = partsArray[i]['value']
        if (!(part['opening'] in array)) {
          array[part['opening']] = []
        }
        array[part['opening']].push(part)
      }
      return array
    },
    compareDetails (lhs, rhs) {
      if (lhs.name !== rhs.name) {
        return false
      }
      if (lhs.dsc !== rhs.dsc) {
        return false
      }
      if (lhs.nagsid !== rhs.nagsid) {
        return false
      }
      if (lhs.partno !== rhs.partno) {
        return false
      }
      if (Math.abs(parseFloat(lhs.partprice) - parseFloat(rhs.partprice)) > 0.0001) {
        return false
      }
      if (Math.abs(parseFloat(lhs.labor) - parseFloat(rhs.labor)) > 0.0001) {
        return false
      }
      if (lhs.qty !== rhs.qty) {
        return false
      }
      if (lhs.isAdhesiveKitPart !== rhs.isAdhesiveKitPart) {
        return false
      }
      if (lhs.mouldingDiscount !== rhs.mouldingDiscount) {
        return false
      }
      if (lhs.isOem !== rhs.isOem) {
        return false
      }
      if (lhs.isPremium !== rhs.isPremium) {
        return false
      }
      if (lhs.isGlass !== rhs.isGlass) {
        return false
      }
      if (lhs.isChip !== rhs.isChip) {
        return false
      }
      if (lhs.color !== rhs.color) {
        return false
      }
      if (lhs.mfCode !== rhs.mfCode) {
        return false
      }
      if (lhs.attachmentFlag !== rhs.attachmentFlag) {
        return false
      }
      if (lhs.ATCHMNT_FLAG !== rhs.ATCHMNT_FLAG) {
        return false
      }
      if (lhs.CLIPS_FLAG !== rhs.CLIPS_FLAG) {
        return false
      }
      if (lhs.MLDING_FLAG !== rhs.MLDING_FLAG) {
        return false
      }
      if (lhs.ATCHMNT_IS_CLIP !== rhs.ATCHMNT_IS_CLIP) {
        return false
      }
      if (lhs.ATCHMNT_IS_MLDING !== rhs.ATCHMNT_IS_MLDING) {
        return false
      }
      if (lhs.ATCHMNT_DSC !== rhs.ATCHMNT_DSC) {
        return false
      }
      if (lhs.PREFIX !== rhs.PREFIX) {
        return false
      }
      if (lhs.PART_NUM !== rhs.PART_NUM) {
        return false
      }
      if (lhs.ANT_FLAG !== rhs.ANT_FLAG) {
        return false
      }
      if (Math.abs(parseFloat(lhs.BLK_SIZE1) - parseFloat(rhs.BLK_SIZE1)) > 0.0001) {
        return false
      }
      if (Math.abs(parseFloat(lhs.BLK_SIZE2) - parseFloat(rhs.BLK_SIZE2)) > 0.0001) {
        return false
      }
      if (lhs.ENCAP_FLAG !== rhs.ENCAP_FLAG) {
        return false
      }
      if (lhs.HDS_UP_DISP_FLAG !== rhs.HDS_UP_DISP_FLAG) {
        return false
      }
      if (lhs.HEATED_FLAG !== rhs.HEATED_FLAG) {
        return false
      }
      if (Math.abs(parseFloat(lhs.NUM_HOLES) - parseFloat(rhs.NUM_HOLES)) > 0.0001) {
        return false
      }
      if (lhs.SLIDER_FLAG !== rhs.SLIDER_FLAG) {
        return false
      }
      if (lhs.SOLAR_FLAG !== rhs.SOLAR_FLAG) {
        return false
      }
      if (Math.abs(parseFloat(lhs.THICKNESS) - parseFloat(rhs.THICKNESS)) > 0.0001) {
        return false
      }
      if (Math.abs(parseFloat(lhs.WT) - parseFloat(rhs.WT)) > 0.0001) {
        return false
      }
      if (Math.abs(parseFloat(lhs.TUBE_QTY) - parseFloat(rhs.TUBE_QTY)) > 0.0001) {
        return false
      }
      return true
    },
    updateAutoAddPartsListener (partData) {
      // When new auto add parts are received, check which part type is selected
      // and loop through those to find the parent part to update
      let partNumber = partData.parentPartNo + partData.parentPartSuffix
      if (this.selectedSection === 'partition') {
        for (var i = 0; i < this.nagsPartsPartition.length; i++) {
          if (this.nagsPartsPartition[i].detail.partno === partNumber) {
            this.nagsPartsPartition[i].detail.autoAddParts = partData.childParts
          }
        }
      } else if (this.selectedSection === 'windshield') {
        for (var j = 0; j < this.nagsPartsWindshield.length; j++) {
          if (this.nagsPartsWindshield[j].detail.partno === partNumber) {
            this.nagsPartsWindshield[j].detail.autoAddParts = partData.childParts
          }
        }
      } else if (this.selectedSection === 'side') {
        for (var k = 0; k < this.nagsPartsSide.length; k++) {
          if (this.nagsPartsSide[k].detail.partno === partNumber) {
            this.nagsPartsSide[k].detail.autoAddParts = partData.childParts
          }
        }
      } else if (this.selectedSection === 'door') {
        for (var m = 0; m < this.nagsPartsDoor.length; m++) {
          if (this.nagsPartsDoor[m].detail.partno === partNumber) {
            this.nagsPartsDoor[m].detail.autoAddParts = partData.childParts
          }
        }
      } else if (this.selectedSection === 'roof') {
        for (var n = 0; n < this.nagsPartsRoof.length; n++) {
          if (this.nagsPartsRoof[n].detail.partno === partNumber) {
            this.nagsPartsRoof[n].detail.autoAddParts = partData.childParts
          }
        }
      } else if (this.selectedSection === 'vent') {
        for (var o = 0; o < this.nagsPartsVent.length; o++) {
          if (this.nagsPartsVent[o].detail.partno === partNumber) {
            this.nagsPartsVent[o].detail.autoAddParts = partData.childParts
          }
        }
      } else if (this.selectedSection === 'quarter') {
        for (var p = 0; p < this.nagsPartsQuarter.length; p++) {
          if (this.nagsPartsQuarter[p].detail.partno === partNumber) {
            this.nagsPartsQuarter[p].detail.autoAddParts = partData.childParts
          }
        }
      } else if (this.selectedSection === 'back') {
        for (var q = 0; q < this.nagsPartsBack.length; q++) {
          if (this.nagsPartsBack[q].detail.partno === partNumber) {
            this.nagsPartsBack[q].detail.autoAddParts = partData.childParts
          }
        }
      } else if (this.selectedSection === 'search') {
        for (var category in this.nagsSearchResults) {
          for (var r = 0; r < this.nagsSearchResults[category].length; r++) {
            if (this.nagsSearchResults[category][r].detail.partno === partNumber) {
              this.nagsSearchResults[category][r].detail.autoAddParts = partData.childParts
            }
          }
        }
      }
    },
    removeAutoAddSavedPartListener (partData) {
      if (this.selectedSection === 'search') {
        for (var category in this.nagsSearchResults) {
          for (var r = 0; r < this.nagsSearchResults[category].length; r++) {
            if (this.nagsSearchResults[category][r].detail.autoAddParts.length > 0) {
              for (var z = 0; z < this.nagsSearchResults[category][r].detail.autoAddParts.length; z++) {
                if (this.nagsSearchResults[category][r].detail.autoAddParts[z] === ('SAVEDPART-' + partData)) {
                  this.nagsSearchResults[category][r].detail.autoAddParts.splice(z, 1)
                }
              }
            }
          }
        }
      }
    },
    triggerNagsSearch () {
      this.$root.$emit('triggerNagsSearch')
    },
  },
  components: {
    Autocomplete,
    PartResult,
    NagsDetailsDialog,
    OrderingStatusDialog
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped>

.cell-search-header {
  background-image: linear-gradient(#2CA5DE 0%, #2992C4 100%) !important;
}
.nags-update {
  font-size: x-small !important;
  color: gray !important;
  font-style: italic !important;
}
</style>
