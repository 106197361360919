import Vue from 'vue'
import { chatTypes } from './types'

export const mutations = {
  [chatTypes.mutations.setConnected]: (state, payload) => {
    state.connected = payload
  },

  [chatTypes.mutations.setOpen]: (state, payload) => {
    state.isOpen = payload
  },

  [chatTypes.mutations.setView]: (state, payload) => {
    state.view = payload
  },

  [chatTypes.mutations.setActiveChannel]: (state, payload) => {
    state.activeChannel = payload
  },

  [chatTypes.mutations.setChannelIsOpenning]: (state, payload) => {
    state.channelIsOpenning = payload
  },

  [chatTypes.mutations.setNewMessage]: (state, payload) => {
    state.newMessage = payload
  },

  [chatTypes.mutations.setChannelInfoDrawerIsOpen]: (state, payload) => {
    state.channelInfoDrawerIsOpen = payload
  },

  [chatTypes.mutations.setMembersDialogIsOpen]: (state, payload) => {
    state.membersDialogIsOpen = payload
  },

  [chatTypes.mutations.setUsers]: (state, payload) => {
    state.users = payload
  },

  [chatTypes.mutations.setSubscribedUsers]: (state, payload) => {
    state.subscribedUsers = payload
  },

  [chatTypes.mutations.setUserReachability]: (
    state,
    { identity, reachability }
  ) => {
    state.users.forEach(i => {
      if (i.identity === identity) {
        i.online = reachability
      }
    })
  },

  [chatTypes.mutations.setUserChannels]: (state, payload) => {
    state.userChannels = payload
  },

  [chatTypes.mutations.setUnreadMessagesCountForSingleChannel]: (
    state,
    { uniqueName, count }
  ) => {
    Vue.set(state.unreadMessagesCount, uniqueName, count)
  },

  [chatTypes.mutations.setUnreadMessagesCountForAllChannels]: (
    state,
    payload
  ) => {
    state.unreadMessagesCount = payload
  },

  [chatTypes.mutations.setUserChannelsLoading]: (state, payload) => {
    state.userChannelsLoading = payload
  },

  [chatTypes.mutations.deleteUserChannel]: (state, uniqueName) => {
    for (let i = 0; i < state.userChannels.length; i++) {
      if (state.userChannels[i].uniqueName === uniqueName) {
        state.userChannels.splice(i, 1)
      }
    }
  },

  [chatTypes.mutations.setAddUsersDialogIsOpen]: (state, payload) => {
    state.addUsersDialogIsOpen = payload
  },

  [chatTypes.mutations.setMembers]: (state, payload) => {
    state.members = payload
  },

  [chatTypes.mutations.setTotalMessage]: (state, payload) => {
    state.totalMessages = payload
  },

  [chatTypes.mutations.setMemberTyping]: (
    state,
    { channelUniqueName, memberIdentity }
  ) => {
    if (!state.membersTyping.hasOwnProperty(channelUniqueName)) {
      Vue.set(state.membersTyping, channelUniqueName, [])
    }
    if (!state.membersTyping[channelUniqueName].includes(memberIdentity)) {
      state.membersTyping[channelUniqueName].push(memberIdentity)
    }
  },

  [chatTypes.mutations.unsetMemberTyping]: (
    state,
    { channelUniqueName, memberIdentity }
  ) => {
    if (state.membersTyping.hasOwnProperty(channelUniqueName)) {
      if (state.membersTyping[channelUniqueName].includes(memberIdentity)) {
        const index = state.membersTyping[channelUniqueName].indexOf(memberIdentity)
        if (index !== -1) {
          state.membersTyping[channelUniqueName].splice(index, 1)
        }
      }
    }
  },

  [chatTypes.mutations.setScrollBodyDown]: (state, payload) => {
    state.scrollBodyDown = payload
  },

  [chatTypes.mutations.setImagePreviewUrl]: (state, payload) => {
    state.imagePreviewUrl = payload
  }
}
