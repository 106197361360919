import * as R from 'ramda'
// import { isJobScheduled } from './job.helpers'
import { startOfWeek, addDays, addWeeks } from 'date-fns'
import { mapIndexed } from './ramda.helpers'
import { formatToDb } from './date.helpers'
import { filterJobsByDate } from '@/scripts/helpers/scheduler.helpers'
const fillArrayWithDate = date => {
  const days = new Array(7)
  days.fill(date)
  return days
}

const incrementWeekDatesArray = mapIndexed(addDays)

const startOfWeekMonday = date => startOfWeek(date, { weekStartsOn: 1 })

export const getWeekDates = R.pipe(
  addWeeks,
  startOfWeekMonday,
  fillArrayWithDate,
  incrementWeekDatesArray
)

const getValLength = R.pipe(
  R.nth(1),
  R.length
)

const sortTechnicians = R.pipe(
  R.toPairs,
  R.sortBy(R.cond([[R.allPass([R.propEq(0, '0'), getValLength]), R.always(1000)], [R.T, getValLength]])),
  R.reverse
)

const fixTechnicianNullId = R.when(
  R.pipe(
    R.prop('tech_id'),
    R.not
  ),
  R.assoc('tech_id', 0)
)

export const filterAndSortCalendarJobs = (state, date) =>
  R.pipe(
    R.prop('jobs'),
    filterJobsByDate(date),
    R.map(fixTechnicianNullId),
    R.groupBy(R.prop('tech_id')),
    sortTechnicians
  )(state)

export const updateJobListDate = date => R.map(R.over(R.lensProp('scheduled_date'), R.always(formatToDb(date))))
