var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fetching,
              expression: "fetching"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.inGlassBiller }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "Invoice #" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/jobs/" + scope.row.id,
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(scope.row.id))]
                    ),
                    scope.row.balancesMatch
                      ? _c("i", {
                          staticClass:
                            "material-icons md-check_circle qbo-synced-icon"
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.formatDate,
              prop: "invoice_date",
              label: "Invoice Date"
            }
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.formatBalance,
              prop: "total_balance",
              label: "Balance (GB)"
            }
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.formatBalance,
              prop: "qboBalance",
              label: "Balance (QBO)"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "Actions", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          width: "180",
                          "v-model":
                            _vm.popoverVisible &&
                            _vm.popoverVisible === scope.row.id
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "text-align": "left" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.exportInvoice(
                                      scope.row.shop_id,
                                      scope.row.id,
                                      false
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Sync Invoice ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0" },
                                attrs: { type: "primary", size: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.exportInvoice(
                                      scope.row.shop_id,
                                      scope.row.id,
                                      true
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Sync Invoice & Payments ")]
                            )
                          ],
                          1
                        ),
                        _c("i", {
                          staticClass: "el-icon-more",
                          staticStyle: { cursor: "pointer" },
                          attrs: { slot: "reference" },
                          on: {
                            click: function($event) {
                              _vm.popoverVisible === scope.row.id
                            }
                          },
                          slot: "reference"
                        })
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }