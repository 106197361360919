<template>
  <div class="t-bg-blue-400 t-mt-2 t-pl-10 t-pr-6 t-py-6 t-flex t-justify-between">
    <el-select
      v-model="shopId"
      filterable
      placeholder="Select shop"
      class="sm:t-w-2/3 md:t-w-1/2 lg:t-w-1/3">
      <el-option
        v-for="s in shops"
        :key="s.id"
        :label="s.name"
        :value="s.id">
        <div class="t-flex">
          <span class="t-flex-grow">{{ s.name }}</span>
          <span
            v-if="s.itemsCount > 0"
            class="t-text-xs t-text-gray-700 t-w-6 t-h-6 t-self-center
                   t-rounded-full t-bg-blue-300 t-flex t-justify-center t-items-center">
            <span>{{ s.itemsCount > 99 ? '99+' : s.itemsCount }}</span>
          </span>
        </div>
      </el-option>
    </el-select>
    <div>
      <!-- <el-input placeholder="Search parts..." v-model="search"></el-input> -->
    </div>
  </div>
</template>

<script>
import {
  shoppingCartActions,
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'

export default {
  data: () => ({
    search: ''
  }),
  mounted () {
    this.shopId = this.defaultShopId
  },
  computed: {
    visible () {
      return this.$store.state.shoppingCart.isOpen
    },
    shopId: {
      get () {
        return this.$store.state.shoppingCart.shopId
      },
      set (value) {
        return this.$store.commit(shoppingCartMutations.setShopId, value)
      }
    },
    shops () {
      return this.$store.state.shoppingCart.shops
    },
    defaultShopId () {
      return this.$store.state.user.shop_id
    },
    selectedTab () {
      return this.$store.state.shoppingCart.selectedTab
    }
  },
  watch: {
    shopId: function (value) {
      if (value) {
        this.clearSelectedItemIds()
        if (this.selectedTab === 'items') {
          this.getItems()
          this.getItemsCount()
        }
        if (this.selectedTab === 'orders') {
          this.getOrders()
        }
      }
    }
  },
  methods: {
    getItems () {
      return this.$store.dispatch(shoppingCartActions.getItems)
    },
    getItemsCount () {
      return this.$store.dispatch(shoppingCartActions.getVendorsItemsCount)
    },
    getOrders () {
      return this.$store.dispatch(shoppingCartActions.getOrders)
    },
    clearSelectedItemIds () {
      return this.$store.dispatch(shoppingCartActions.clearSelectedItemIds)
    }
  }
}
</script>
