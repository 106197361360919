const state = {
  consumer: null,
  loading: {
    customer: false,
    billTo: false,
    job: false,
    catalog: null,
    draft: false,
    vehicle: 0
  },
  saving: {
    customer: false,
    billTo: false,
    job: false
  },
  modal: {
    consumer: null,
    title: null,
    nags: null
  },
  shops: [],
  job: {},
  newCustomer: null,
  newShipTo: null,
  newBillTo: null,
  forms: {
    customer: {
      valid: false
    }
  },
  drawer: false,
  nags: {
    catalog: null,
    prefix: null,
    part: null,
    variation: null,
    trends: {},
    dialog: false,
    shopHasVendors: true,
    partList: {},
    hardware: [],
    drawer: false,
    autoAdd: {}
  },
  vehicle: {
    new: {},
    vin: '',
    plate: '',
    state: ''
  },
  watchFlags: {
    customer: false,
    bill_to: false
  },
  parts: {
    selected: [],
    shopPartsOpen: false
  },
  mapDialogAddress: null,
  displayLeftBar: false,
  actionDialogs: {
    esign: false,
    terms: false,
    pdf: false,
    email: false,
    audit: false,
    fax: false,
    clone: false,
    quickbooksHistory: false,
    edi_test: false,
    status: false,
    payment: false,
    gbFormPreview: false,
    gbFormDialog: false
  },
  gbFormData: {},
  actionDialogsDefaults: {
    email: null
  },
  recentSearches: [],
  searchConfig: { optimized: true },
  chipVendors: []
}

export default state
