import gql from 'graphql-tag'

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFields on GAddress {
    id
    full_address
    unit
    po_box
    google_place_id
    extra
    address
    zip
    city
    state
    lat
    lng
  }
`
