var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "document-tag" },
    [
      _c(
        "md-label-tag",
        {
          attrs: {
            icon: _vm.doc.icon || "insert_drive_file",
            color: _vm.doc.color
          }
        },
        [_vm._v(" " + _vm._s(_vm.doc.tag) + " " + _vm._s(_vm.number) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }