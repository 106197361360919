var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.type == "none" || _vm.$job.quickbooksDetails.display === "yes"
      ? _c(
          "span",
          {
            class: _vm.type != "none" ? "qbo-button" : "",
            on: {
              click: function($event) {
                return _vm.showQuickBooksHistoryDialog()
              }
            }
          },
          [
            _c("span", { staticClass: "qbo-icon" }, [_vm._v("QBO")]),
            _vm.type != "none" && _vm.$job.quickbooksDetails.loading != "yes"
              ? _c("span", { class: _vm.quickbooksTotalClasses }, [
                  _vm._v(_vm._s(_vm.quickBooksTotal))
                ])
              : _vm._e(),
            _vm.type != "none" && _vm.$job.quickbooksDetails.loading == "yes"
              ? _c("img", {
                  staticClass: "qbo-loader",
                  attrs: { src: require("../../../assets/img/spinner.gif") }
                })
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }