<template>
  <div>
    <div :class='{"ribbon": true, [position]: true, [color]: true}' :style="hex?`background-color: ${hex}`:''">
      {{label}}
    </div>
  </div>
</template>

<script>
export default {
  props: ['color', 'label', 'position', 'hex']
}
</script>

<style lang="scss" scoped>
  .ribbon {
    top: 20px;
    box-shadow: 0 3px 12px 0 rgb(0 0 0 / 50%);
    font: 13px var(--zd_semibold);
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    width: 166px;
    display: block;
    position: absolute;
    padding: 5px 0;
    overflow: hidden;
    &.right {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      right: -40px;
    }
    &.left {
      transform: rotate(320deg);
      -webkit-transform: rotate(320deg);
      left: -40px;
    }
    &.green {
      background-color: $jade-green;
    }
    &.blue {
      background-color: $air-force-blue;
    }
    &.orange {
      background-color: $gold-orange;
    }
    &.dark {
      background-color: $grayscale-8;
    }
    &.dark {
      background-color: $grayscale-8;
    }
    &.sky-blue {
      background-color: $sky-blue;
    }
    &.red {
      background-color: $crimson-red;
    }
    &.gold-orange {
      background-color: $gold-orange;
    }
    &.purple {
      background-color: $royal-purple;
    }
    &.teal {
      background-color: $forest-teal;
    }
    &.burnt-orange {
      background-color: $burnt-orange;
    }
    &.silver {
      background-color: $grayscale-6;
    }
    &.light-orange {
      background-color: $light-orange;
    }
    &.brown {
      background-color: $brown;
    }
    &.call-back-blue {
      background-color: $call-back-blue;
    }
    &.r-and-i-teal {
      background-color: $r-and-i-teal;
    }
    &.recal-purple {
      background-color: $recal-purple;
    }
    &.tint-brown {
      background-color: $tint-brown;
    }
    &.pick-up-blue {
      background-color: $pick-up-blue;
    }
    &.drop-off-purple {
      background-color: $drop-off-purple;
    }
    &.call-back {
      background-color: $call-back-blue;
    }
    &.r-and-i {
      background-color: $r-and-i-teal;
    }
    &.recal {
      background-color: $recal-purple;
    }
    &.tint {
      background-color: $tint-brown;
    }
    &.pick-up {
      background-color: $pick-up-blue;
    }
    &.drop-off {
      background-color: $drop-off-purple;
    }
  }
</style>