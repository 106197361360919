import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import $ from 'jquery'
import LegacyApi from '@/services/LegacyApi'

export default class Ediqueue extends ObjectTracker {
  id = -1
  submit_date_time = ''
  response_date_time = ''
  status = 'Unsubmitted'
  job_edi_message = ''
  job_id = -1
  edi_creation_date_time = ''
  service = ''
  edi_id = -1
  remit_received = -1
  glaxis_id = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getJobEdiqueue (shopId, jobId, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/shop/${shopId}/job/${jobId}/ediqueue`}).then(res => {
      
      callbackFunction(new Ediqueue(res.data))
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getNextEdiSubmissionTime (callbackFunction) {
    Api({url: `/api/nextEdiSubmissionTime`}).then(res => {
      
      callbackFunction(res.data.secondsToSubmission)
    })
  }

  static async updateJobEdiqueue (job_id, status, message, successCallbackFunction, errorCallbackFunction) {
    var newStatus = new FormData()
    newStatus.set('status', status)
    newStatus.set('msg', message)
    try {
      const { data } = await LegacyApi.post('/edi/setstatus/' + job_id, JSON.stringify({status: status, msg: message}))
      if (typeof data.error !== 'undefined') {
        alert(data.error)
        errorCallbackFunction()
      } else {
        if (typeof data.invoiceStatus === 'undefined' || typeof data.invoiceStatusDetails === 'undefined') {
          alert('Updated status not received from server.')
        } else {
          successCallbackFunction(data.invoiceStatus, data.invoiceStatusDetails)
        }
      }
    } catch (error) {
      
    }
  }

  static getRejectedEdisCountsForAllConnectedShops (callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/rejectedEdisCount`}).then(res => {
      
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getRejectedEdisAndJobsDetails (shopId, page, limit, sortBy, sortDesc, invoiceFilter, referralNumberFilter, customerFilter,
    amountFilter, tradingPartnerFilter, successCallbackFunction, errorCallbackFunction) {
    Api({url: `/api/shop/${shopId}/rejectedEdis?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&invoiceFilter=' + encodeURIComponent(invoiceFilter) + '&referralNumberFilter=' + encodeURIComponent(referralNumberFilter) +
      '&customerFilter=' + encodeURIComponent(customerFilter) + '&amountFilter=' + encodeURIComponent(amountFilter) +
      '&tradingPartnerFilter=' + encodeURIComponent(tradingPartnerFilter)
    }).then(res => {
      
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
  static rejectedEdis_v2 ({shop, page, limit, sortBy, sortDesc, filters}) {
    return Api({url: `/api/shop/${shop.id}/rejectedEdis?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&invoiceFilter=' + encodeURIComponent(filters.id) + '&referralNumberFilter=' + encodeURIComponent(filters.referral) +
      '&customerFilter=' + encodeURIComponent(filters.customerName) + '&amountFilter=' + encodeURIComponent(filters.amount) +
      '&tradingPartnerFilter=' + encodeURIComponent(filters.tradingPartner)
    })
  }

  static getQueuedEdisCountsForAllConnectedShops (callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/queuedEdisCount`}).then(res => {
      
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getQueuedEdisAndJobsDetails (shopId, page, limit, sortBy, sortDesc, invoiceFilter, referralNumberFilter, customerFilter,
    amountFilter, tradingPartnerFilter, successCallbackFunction, errorCallbackFunction) {
    Api({url: `/api/shop/${shopId}/queuedEdis?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&invoiceFilter=' + encodeURIComponent(invoiceFilter) + '&referralNumberFilter=' + encodeURIComponent(referralNumberFilter) +
      '&customerFilter=' + encodeURIComponent(customerFilter) + '&amountFilter=' + encodeURIComponent(amountFilter) +
      '&tradingPartnerFilter=' + encodeURIComponent(tradingPartnerFilter)
    }).then(res => {
      
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
  static queuedEdis_v2 ({shop, page, limit, sortBy, sortDesc, filters}) {
    return Api({url: `/api/shop/${shop.id}/queuedEdis?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&invoiceFilter=' + encodeURIComponent(filters.id) + '&referralNumberFilter=' + encodeURIComponent(filters.referral) +
      '&customerFilter=' + encodeURIComponent(filters.customerName) + '&amountFilter=' + encodeURIComponent(filters.amount) +
      '&tradingPartnerFilter=' + encodeURIComponent(filters.tradingPartner)
    })
  }

  static getEdiQueueStatusCounts (callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/ediQueueStatusCounts`}).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getEdisAndJobsDetailsByStatus (page, limit, sortBy, sortDesc, statusFilter, invoiceFilter, referralNumberFilter, customerFilter,
    amountFilter, tradingPartnerFilter, successCallbackFunction, errorCallbackFunction) {
      Api({url: `/api/status/queuedEdis?statusFilter=` + encodeURIComponent(statusFilter) + `&page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&invoiceFilter=' + encodeURIComponent(invoiceFilter) + '&referralNumberFilter=' + encodeURIComponent(referralNumberFilter) +
      '&customerFilter=' + encodeURIComponent(customerFilter) + '&amountFilter=' + encodeURIComponent(amountFilter) +
      '&tradingPartnerFilter=' + encodeURIComponent(tradingPartnerFilter)
    }).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        
        errorCallbackFunction(error)
      }
    })
    }
  static getEdisAndJobsDetailsByStatus_v2 ({page, limit, sortBy, sortDesc, filters, status}) {
      return Api({url: `/api/status/queuedEdis?statusFilter=` + encodeURIComponent(status) + `&page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&invoiceFilter=' + encodeURIComponent(filters.id) + '&referralNumberFilter=' + encodeURIComponent(filters.referral) +
      '&customerFilter=' + encodeURIComponent(filters.customerName) + '&amountFilter=' + encodeURIComponent(filters.amount) +
      '&tradingPartnerFilter=' + encodeURIComponent(filters.tradingPartner)
    })
  }
}
