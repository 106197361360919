var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", { attrs: { data: _vm.data } }, [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("Form Signed")
    ]),
    _c("div", [
      _vm.data.details.signerName
        ? _c("b", [_vm._v(_vm._s(_vm.data.details.signerName))])
        : _c("b", [_vm._v("Your customer")]),
      _vm._v(" has signed form "),
      _c("b", [_vm._v(_vm._s(_vm.data.details.formName))]),
      _vm._v(" for job "),
      _c("a", { attrs: { target: "_blank", href: _vm.jobLink } }, [
        _vm._v(_vm._s(this.data.details.jobId))
      ]),
      _vm._v(". ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }