import Api from '@/services/Api'
import { actions, mutations } from './types'

export default {
  [actions.open] ({ commit }) {
    commit(mutations.setIsOpen, true)
  },

  [actions.close] ({ commit }) {
    commit(mutations.setIsOpen, false)
    commit(mutations.setSelectedTab, 'items')
  },

  async [actions.getShops] ({ commit }) {
    const { data } = await Api.get('/api/shoppingCart/shops')
    commit(mutations.setShopsData, data)
  },

  async [actions.addItem] ({ dispatch }, payload) {
    const { data } = await Api.post('/api/shoppingCart/addItem', payload)
    dispatch(actions.getItemsCount)
    return data
  },

  async [actions.getItems] ({ commit, state }) {
    commit(mutations.items.setItemsFetching, true)
    commit(mutations.items.setItemsData, [])
    const { data } = await Api.get('/api/shoppingCart/items', {
      params: {
        shopId: state.shopId,
        vendor: state.items.selectedVendor
      }
    })
    commit(mutations.items.setItemsData, data)
    commit(mutations.items.setItemsFetching, false)
  },

  async [actions.getVendorsItemsCount] ({ commit, state }) {
    const { data } = await Api.get('/api/shoppingCart/vendorsItemsCount', {
      params: {
        shopId: state.shopId
      }
    })
    commit(mutations.items.setVendorsItemsCount, data)
  },

  async [actions.changeQuantity] ({ commit }, payload) {
    await Api.post('/api/shoppingCart/changeItemQuantity', payload)
    commit(mutations.items.updateItemQuantity, payload)
  },

  async [actions.deleteItem] ({ dispatch }, { vendor, itemId }) {
    await Api.delete('/api/shoppingCart/items', {
      params: {
        vendor,
        itemId
      }
    })
    dispatch(actions.getItemsCount)
    dispatch(actions.getVendorsItemsCount)
  },

  async [actions.getItemsCount] ({ commit }) {
    const { data } = await Api.get('/api/shoppingCart/totalCartItems')
    commit(mutations.items.setTotalItemsCount, data.count)
  },

  async [actions.openAmericanCheckout] ({ commit }) {
    commit(mutations.items.setAmericanCheckoutIsOpen, true)
  },

  async [actions.closeAmericanCheckout] ({ commit }) {
    commit(mutations.items.setAmericanCheckoutIsOpen, false)
  },

  async [actions.openPilkingtonCheckout] ({ commit }) {
    commit(mutations.items.setPilkingtonCheckoutIsOpen, true)
  },

  async [actions.closePilkingtonCheckout] ({ commit }) {
    commit(mutations.items.setPilkingtonCheckoutIsOpen, false)
  },

  async [actions.openNielsenMollerCheckout] ({ commit }) {
    commit(mutations.items.setNielsenMollerCheckoutIsOpen, true)
  },

  async [actions.closeNielsenMollerCheckout] ({ commit }) {
    commit(mutations.items.setNielsenMollerCheckoutIsOpen, false)
  },

  async [actions.getNielsenMollerShippingAddresses] (_, payload) {
    const { data } = await Api.post('/api/nm/getShippingAddresses', payload)
    return data
  },

  async [actions.getPilkingtonShipToAddresses] (_, payload) {
    const { data } = await Api.post('/api/pilkington/getShipToAddresses', payload)
    return data
  },

  async [actions.getPilkingtonDeliveryMethods] (_, payload) {
    const { data } = await Api.post('/api/pilkington/getDeliveryMethods', payload)
    return data
  },

  async [actions.getAmericanShipTerms] (_, payload) {
    const { data } = await Api.post('/api/americanGlass/getShipTerms', payload)
    return data
  },

  async [actions.addToAmericanCart] (_, payload) {
    const { data } = await Api.post('/api/americanGlass/cart', payload)
    return data
  },

  async [actions.placeOrder] (_, payload) {
    const { data } = await Api.post('/api/shoppingCart/placeOrder', payload)
    return data
  },

  async [actions.getOrders] ({ commit, state }) {
    commit(mutations.orders.setOrdersFetching, true)
    commit(mutations.orders.setOrdersData, [])
    const { data } = await Api.get('/api/shoppingCart/orders', {
      params: {
        shopId: state.shopId,
        vendor: state.orders.selectedVendor
      }
    })
    commit(mutations.orders.setOrdersData, data)
    commit(mutations.orders.setOrdersFetching, false)
  },

  async [actions.getOrderItems] ({ commit }, { orderId, vendor }) {
    commit(mutations.orders.setOrderItemsFetching, true)
    commit(mutations.orders.setOrdersItems, [])
    const { data } = await Api.get('/api/shoppingCart/orderItems', {
      params: {
        orderId,
        vendor
      }
    })
    commit(mutations.orders.setOrdersItems, data)
    commit(mutations.orders.setOrderItemsFetching, false)
  },

  [actions.selectItem] ({ commit, state }, { itemId }) {
    const ids = [...state.items.selectedItemIds]
    if (ids.indexOf(itemId) === -1) {
      ids.push(itemId)
    }
    commit(mutations.items.setSelectedItemIds, ids)
  },

  [actions.unselectItem] ({ commit, state }, { itemId }) {
    const ids = [...state.items.selectedItemIds]
    const index = ids.indexOf(itemId)
    if (index > -1) {
      ids.splice(index, 1)
    }
    commit(mutations.items.setSelectedItemIds, ids)
  },

  [actions.clearSelectedItemIds] ({ commit }) {
    commit(mutations.items.setSelectedItemIds, [])
  },

  async [actions.addItemToOrder] ({ dispatch, state }, itemId) {
    await Api.post('/api/shoppingCart/addItemToOrder', {
      itemId: itemId
    })
    await dispatch(actions.getOrderItems, state.orders.orderDetails.id)
  },

  async [actions.deleteItemFromOrder] ({ dispatch, state }, itemId) {
    await Api.delete('/api/shoppingCart/deleteItemFromOrder', {
      params: {
        itemId: itemId
      }
    })
    await dispatch(actions.getOrderItems, state.orders.orderDetails.id)
  },

  async [actions.resubmitOrder] (_, payload) {
    await Api.post('/api/shoppingCart/resubmitOrder', payload)
  }
}
