<template>
  <transition name="slide-down">
    <div
      class="scheduler-dialog"
      v-if="isOpen"
    >
      <div
        @click="closeModal"
        class="close-scheduler-dialog"
      >
        <v-icon
          name="times"
          scale="1.1"
        />
      </div>
      <sheduler-component />
    </div>
  </transition>
</template>

<script>
import ShedulerComponent from '@/components/SchedulerV3/Index.vue'
import { mapState } from 'vuex'
import { schedulerNamespace, schedulerNamespacedMutations } from '@/store/modules/scheduler/scheduler.types'

export default {
  components: {
    ShedulerComponent
  },
  methods: {
    closeModal () {
      this.$store.commit(schedulerNamespacedMutations.closeSchedulerDialog)
    }
  },
  computed: {
    ...mapState(schedulerNamespace, ['isOpen'])
  }
}
</script>

<style lang="scss">
.scheduler-dialog {
  position: absolute;
  z-index: 1040;
  top: 0;
  height: calc(100vh - #{$scheduler-dialog-margin * 2});
  width: calc(100vw - #{$scheduler-dialog-margin * 2});
  margin: $scheduler-dialog-margin;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: $scheduler-dialog-box-shadow;
  .close-scheduler-dialog {
    cursor: pointer;
    position: absolute;
    right: 9px;
    top: 9px;
  }
}
@include media-breakpoint-down(sm) {
  .scheduler-dialog {
    height: calc(100vh - #{$scheduler-dialog-margin-mobile * 2});
    width: calc(100vw - #{$scheduler-dialog-margin-mobile * 2});
    margin: $scheduler-dialog-margin-mobile;
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.4s ease-in;
}
.slide-down-enter,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
