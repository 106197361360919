var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.order.id === _vm.resubmittingOrderId,
          expression: "order.id === resubmittingOrderId"
        }
      ],
      staticClass:
        "t-mb-3 t-border t-rounded-md t-shadow-sm t-flex t-border-solid\n         t-border-gray-300"
    },
    [
      _c("div", { staticClass: "t-flex-grow t-px-4 t-py-2" }, [
        _vm.order.vendor === "pgw" && _vm.order.accountNumber
          ? _c("div", [
              _vm._v(" Account " + _vm._s(_vm.order.accountNumber) + " "),
              _vm.order.accountName
                ? _c("span", [_vm._v(" - " + _vm._s(_vm.order.accountName))])
                : _vm._e()
            ])
          : _vm._e(),
        _vm.order.po
          ? _c("div", [
              _vm._v(" Purchase order: "),
              _c("span", { staticClass: "t-text-base t-text-gray-700" }, [
                _vm._v(" " + _vm._s(_vm.order.po) + " ")
              ])
            ])
          : _vm._e(),
        _vm.order.purchaseOrderId
          ? _c(
              "div",
              {
                staticClass: "t-flex t-mt-1",
                on: {
                  click: function($event) {
                    return _vm.openPurchaseOrder(_vm.order.purchaseOrderId)
                  }
                }
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "t-text-white t-px-2 t-rounded-md t-cursor-pointer",
                    style: _vm.supplierColor
                      ? "background-color: " + _vm.supplierColor
                      : "background-color: #000000"
                  },
                  [
                    _vm._v(
                      " PO " + _vm._s(_vm.order.purchaseOrderInternalId) + " "
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "t-my-2" }, [
          _c(
            "a",
            {
              staticClass:
                "t-bg-white t-border t-border-solid t-border-blue-400\n                t-rounded-md t-px-2 t-py-1 t-cursor-pointer t-text-blue-500\n                hover:t-bg-blue-500 hover:t-border-blue-500\n                hover:t-text-white t-text-xs",
              on: {
                click: function($event) {
                  return _vm.openOrderDetails(_vm.order)
                }
              }
            },
            [_vm._v(" Show details ")]
          )
        ])
      ]),
      _c("div", { staticClass: "t-flex t-flex-col t-justify-center t-w-48" }, [
        _c("div", [
          _c("i", { staticClass: "el-icon-user t-mr-1 t-text-blue-600" }),
          _c("span", { staticClass: "t-text-base" }, [
            _vm._v(" " + _vm._s(_vm.order.username) + " ")
          ])
        ]),
        _c("div", [
          _c("i", { staticClass: "el-icon-time t-mr-1 t-text-blue-600" }),
          _c("span", [_vm._v(_vm._s(_vm.formatTimestamp(_vm.order.createdAt)))])
        ])
      ]),
      _c("div", { staticClass: "t-self-center t-w-32" }, [
        _vm.order.returnCode !== "0"
          ? _c(
              "div",
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: { content: _vm.order.returnText, placement: "top" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "t-cursor-pointer t-flex t-items-center" },
                      [
                        _c("i", {
                          staticClass:
                            "el-icon-error t-mr-2 t-text-red-500 t-text-lg"
                        }),
                        _c(
                          "span",
                          { staticClass: "t-text-base t-text-red-900" },
                          [_vm._v("Error")]
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          : _c("div", [
              _vm.order.fulfilled === null
                ? _c(
                    "div",
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "Waiting for the vendor's response",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "t-cursor-pointer t-flex t-items-center"
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-info t-mr-2 t-text-orange-500 t-text-lg"
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "t-text-base t-text-orange-900"
                                },
                                [_vm._v("Pending...")]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.order.fulfilled === 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "Order was successfully placed",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "t-cursor-pointer t-flex t-items-center"
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-success t-mr-2 t-text-green-500 t-text-lg"
                              }),
                              _c(
                                "span",
                                { staticClass: "t-text-base t-text-green-900" },
                                [_vm._v("Placed")]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.order.fulfilled === 2
                ? _c(
                    "div",
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content:
                              "Order was rejected, please check details for further\n                  information",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "t-cursor-pointer t-flex t-items-center"
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-warning t-mr-2 t-text-red-500 t-text-lg"
                              }),
                              _c(
                                "span",
                                { staticClass: "t-text-base t-text-red-900" },
                                [_vm._v("Rejected")]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
      ]),
      _c(
        "div",
        {
          staticClass:
            "t-font-bold t-px-3 t-bg-gray-100 t-border-solid t-border-0\n          t-border-l-8 t-border-gray-300 t-rounded-r-md t-self-stretch\n          t-flex t-items-center"
        },
        [
          _c(
            "div",
            { staticClass: "t-w-24 t-flex-1 t-text-lg t-text-center" },
            [_vm._v(" $" + _vm._s(_vm.roundPrice(_vm.order.totalAmount)) + " ")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }