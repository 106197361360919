var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.method === "email"
    ? _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isOpen,
            title: "Email",
            "append-to-body": "",
            "destory-on-close": "",
            width: "1100px",
            okText: "Send"
          },
          on: {
            "update:visible": function($event) {
              _vm.isOpen = $event
            },
            input: function($event) {
              return _vm.$emit("input")
            },
            open: _vm.onOpen,
            closed: _vm.onClose,
            "on-save": _vm.submit
          }
        },
        [
          _c(
            "div",
            {
              class: {
                "dragging-overlay": _vm.dragging,
                "uploading-file": _vm.uploadingDropFile
              },
              on: {
                drop: [
                  function($event) {
                    $event.preventDefault()
                    return _vm.documentDrop($event)
                  },
                  function($event) {
                    _vm.dragging = false
                  }
                ],
                dragleave: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragLeave($event)
                },
                dragenter: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragEnter($event)
                },
                dragover: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _vm.uploadingDropFile
                ? _c("div", [
                    _c("div", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: true,
                          expression: "true"
                        }
                      ],
                      staticClass: "uploading-spinner"
                    })
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "t-flex t-align-center",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [
                  _c("div", { staticClass: "el-col el-col-12" }, [
                    _c("div", [_vm._v("Quick Addresses")]),
                    _c(
                      "div",
                      _vm._l(_vm.emailShortcuts, function(shortcut) {
                        return _c(
                          "el-button",
                          {
                            key: shortcut.label,
                            attrs: { size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.setEmail(shortcut.address)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(shortcut.label) + " ")]
                        )
                      }),
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "el-col el-col-12 t-pl-6 t-pr-8 t-pb-4" },
                    [
                      _c("span", { staticClass: "undo-label-styles" }, [
                        _vm._v("Template")
                      ]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            slot: "append",
                            clearable: "",
                            placeholder: "Select email template (optional)",
                            "value-key": "id"
                          },
                          slot: "append",
                          model: {
                            value: _vm.selectedTemplate,
                            callback: function($$v) {
                              _vm.selectedTemplate = $$v
                            },
                            expression: "selectedTemplate"
                          }
                        },
                        [
                          _vm._l(_vm.templates, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item }
                            })
                          }),
                          _c(
                            "div",
                            { staticClass: "t-pl-3" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", icon: "el-icon-plus" },
                                  on: { click: _vm.createTemplate }
                                },
                                [_vm._v(" Add Template ")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _vm._v(" or "),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: { click: _vm.manageTemplates }
                        },
                        [_vm._v("Manage email message templates")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "email-dialog" },
                [
                  _c("gb-form", {
                    ref: "formRef",
                    attrs: { schema: _vm.schema },
                    model: {
                      value: _vm.data,
                      callback: function($$v) {
                        _vm.data = $$v
                      },
                      expression: "data"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "t-flex t-justify-between t-mb-2" },
                    [
                      _c("div", { staticClass: "email-dialog-attachments" }, [
                        _c("div", { staticClass: "attachments-label" }, [
                          _vm._v("Attachments")
                        ]),
                        _c("div", { staticClass: "t-flex t-mt-1 t-mb-1" }, [
                          _c(
                            "div",
                            [
                              _c("el-switch", {
                                staticClass: "t-mt-1",
                                attrs: { "active-text": _vm.file.name },
                                model: {
                                  value: _vm.shareFile,
                                  callback: function($$v) {
                                    _vm.shareFile = $$v
                                  },
                                  expression: "shareFile"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "additional-attachments t-pl-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "additional-attachments__label t-mb-1"
                            },
                            [_vm._v("Additional Attachments")]
                          ),
                          _c(
                            "div",
                            { staticClass: "additional-attachments__files" },
                            [
                              _vm._l(_vm.attachments, function(
                                attachment,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: attachment.url,
                                    staticClass: "t-mb-1"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: attachment.url,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v(_vm._s(attachment.name))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteAttachment(index)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-close remove-icon t-ml-2"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "t-mt-2",
                                  on: {
                                    dragover: function($event) {
                                      $event.preventDefault()
                                    }
                                  }
                                },
                                [
                                  _c("FilePicker", {
                                    attrs: {
                                      buttonLabel: "Choose File",
                                      filePickerKey: _vm.filePickerKey,
                                      handleFilePickerResponse:
                                        _vm.handleFilePickerResponse,
                                      dropListenerName:
                                        "genericEmailAttachment",
                                      uploadingDropFile: _vm.uploadingDropFile
                                    },
                                    on: {
                                      "update:uploadingDropFile": function(
                                        $event
                                      ) {
                                        _vm.uploadingDropFile = $event
                                      },
                                      "update:uploading-drop-file": function(
                                        $event
                                      ) {
                                        _vm.uploadingDropFile = $event
                                      }
                                    }
                                  }),
                                  _c("div", { staticClass: "t-mt-1" }, [
                                    !_vm.uploadingDropFile
                                      ? _c("div", [
                                          _vm._v("Or Drag and Drop...")
                                        ])
                                      : _vm._e(),
                                    _vm.uploadingDropFile
                                      ? _c("div", [_vm._v("Uploading...")])
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "el-collapse",
                    {
                      model: {
                        value: _vm.showLog,
                        callback: function($$v) {
                          _vm.showLog = $$v
                        },
                        expression: "showLog"
                      }
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          attrs: {
                            title: _vm.showLog.length
                              ? "Hide History"
                              : "Show History",
                            name: "1"
                          }
                        },
                        [
                          _c("email-history", {
                            attrs: { history: _vm.history, isGeneric: true }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "normal" },
                  on: {
                    click: function($event) {
                      _vm.isOpen = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "normal",
                    type: "primary",
                    loading: _vm.loading
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v("Send")]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }