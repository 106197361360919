var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex" },
    [
      _c(
        "el-scrollbar",
        {
          staticClass: "t-flex-grow t-h-[700px] t-my-4 t-mr-2",
          attrs: {
            "wrap-class": "t-overflow-x-hidden t-flex-shrink-0 t-py-2 t-px-8"
          }
        },
        [
          _c(
            "div",
            { staticClass: "qbo-mapping-dialog-body" },
            [
              _c("quick-books-account-select", {
                attrs: { accountType: "Receivable Account", disabled: "" }
              }),
              _c("el-divider", { staticClass: "t-my-6" }, [_vm._v("Income")]),
              _c("quick-books-item-select", {
                staticClass: "qbo-account-select",
                attrs: { itemType: "Materials Item" }
              }),
              _c("quick-books-item-select", {
                staticClass: "qbo-account-select",
                attrs: { itemType: "Labor Item" }
              }),
              _c("el-divider", { staticClass: "t-my-6" }, [_vm._v("Payments")]),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: { accountType: "Cash Payment" }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: { accountType: "Check Payment" }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: { accountType: "Bank Transfer Payment" }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: { accountType: "Manual Card Payment" }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: { accountType: "Other Payment" }
              }),
              _vm.shopHasCardProcessor
                ? _c("quick-books-account-select", {
                    staticClass: "qbo-account-select",
                    attrs: { accountType: "Card Payment" }
                  })
                : _vm._e(),
              _c("el-divider", { staticClass: "t-my-6" }, [
                _vm._v("Adjustments")
              ]),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: { accountType: "Clearing Account" }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: {
                  accountType: "Promo Adjustment",
                  disabled: !_vm.defaultClearingAccount
                }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: {
                  accountType: "Coupon Adjustment",
                  disabled: !_vm.defaultClearingAccount
                }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: {
                  accountType: "Credit Adjustment",
                  disabled: !_vm.defaultClearingAccount
                }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: {
                  accountType: "Short Pay Adjustment",
                  disabled: !_vm.defaultClearingAccount
                }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: {
                  accountType: "Write Off Adjustment",
                  disabled: !_vm.defaultClearingAccount
                }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: {
                  accountType: "Deductible Discount Adjustment",
                  disabled: !_vm.defaultClearingAccount
                }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: {
                  accountType: "Fee Adjustment",
                  disabled: !_vm.defaultClearingAccount
                }
              }),
              _c("quick-books-account-select", {
                staticClass: "qbo-account-select",
                attrs: {
                  accountType: "Doubtful Account Adjustment",
                  disabled: !_vm.defaultClearingAccount
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }