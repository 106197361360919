var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-p-3 t-w-90 t-h-36" }, [
    _c(
      "div",
      {
        staticClass:
          "t-flex t-flex-col t-items-center t-justify-center t-border t-border-solid t-border-gray-200\n      t-rounded-md t-shadow-sm t-bg-white t-h-full t-cursor-pointer t-px-3 t-relative"
      },
      [
        _c("div", [
          _c(
            "div",
            {
              staticClass:
                "t-flex t-flex-col t-items-center t-justify-center t-space-x-2\n        t-mb-2 t-text-blue-600"
            },
            [
              _vm.label
                ? _c(
                    "div",
                    { staticClass: "t-text-sm t-mb-4 terminal-content" },
                    [_vm._v(_vm._s(_vm.label))]
                  )
                : _vm._e(),
              _c("img", {
                staticClass: "t-text-blue-600 t-h-10 t-w-10",
                attrs: {
                  src: require("./../DeviceCard/images/desktop-computer.svg")
                }
              })
            ]
          )
        ]),
        _c("div", { staticClass: "t-flex t-flex-col" }, [
          _c("div", { staticClass: "ml-4" }, [
            _vm._v(" " + _vm._s(_vm.status) + " ")
          ]),
          _c("span", { staticClass: "t-flex t-w-3 t-h-3 t-mt-1 t-absolute" }, [
            _c("span", {
              staticClass:
                "t-absolute t-inline-flex t-rounded-full t-opacity-50 t-h-full t-w-full",
              class: {
                "t-animate-ping": !_vm.connected,
                "t-bg-yellow-300": _vm.status === "Cancelling transaction",
                "t-bg-green-300": _vm.connected,
                "t-bg-red-300": !_vm.connected
              }
            }),
            _c("span", {
              staticClass:
                "t-relative t-inline-flex t-rounded-full t-h-3 t-w-3",
              class: {
                "t-bg-yellow-300": _vm.status === "Cancelling transaction",
                "t-bg-green-500":
                  _vm.connected && _vm.status != "Cancelling transaction",
                "t-bg-red-500":
                  !_vm.connected && _vm.status != "Cancelling transaction"
              }
            })
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }