import { make } from 'vuex-pathify'

const state = {
  visible: false
}

const mutations = make.mutations(state)

const getters = {}

const actions = {
  open({ commit }) {
    commit('SET_VISIBLE', true)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}