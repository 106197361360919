var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "consumer-search-bar-mobile",
      class: { focused: _vm.focused },
      on: {
        click: function($event) {
          return _vm.$emit("close-blur")
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "consumer-search-bar-mobile-container",
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "consumer-search-bar-mobile-input" },
            [_vm._t("reference")],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "consumer-search-bar-mobile-results consumer-search-dropdown",
              on: { click: _vm.close }
            },
            [_vm._t("default")],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }