<template>
  <div>
      <button type='button' class='btn btn-primary filestack-button' @click="openFilePicker" variant="primary">{{buttonLabel}}</button>
  </div>
</template>
<script>
import * as filepicker from 'filepicker-js'
import * as filestack from 'filestack-js'
import $ from 'jquery'
const client = filestack.init('AcGSAppE1TjCSLtiIUYNpz')

export default {
  props: ['buttonLabel', 'filePickerKey', 'dialogToReturnTo', 'handleFilePickerResponse', 'dropListenerName', 'uploadingDropFile', 'filepickerOpen'],
  beforeDestroy: function () {
    if (this.dropListenerName && this.dropListenerName.length > 0) {
      this.$root.$off(this.dropListenerName, this.fileDrop)
    }
  },
  created () {
    if (this.dropListenerName && this.dropListenerName.length > 0) {
      
      this.$root.$on(this.dropListenerName, this.fileDrop)
    }
  },
  computed: {
    uploading_drop_file: {
      get () {
        return this.uploadingDropFile
      },
      set (val) {
        this.$emit('update:uploadingDropFile', val)
      }
    }
  },
  methods: {
    fileDrop: function (event) {
      
      let self = this
      if (this.dropListenerName === 'dynamicDialogListener') {
        self.uploading_drop_file = true
        // For the current version, just use the first file dropped - no support for multiple files yet
        filepicker.setKey(this.filePickerKey)
        filepicker.store(event,
          function (Blob) {
            self.handleFilePickerResponse(Blob.filename, Blob.url, Blob)
            self.uploading_drop_file = false
          },
          function (FPError) {
            
          }
        )
      } else if (event.dataTransfer.files.length > 0) {
        
        self.uploading_drop_file = true
        // For the current version, just use the first file dropped - no support for multiple files yet
        filepicker.setKey(this.filePickerKey)
        filepicker.store(event.dataTransfer.files[0],
          function (Blob) {
            self.handleFilePickerResponse(Blob.filename, Blob.url, Blob)
            self.uploading_drop_file = false
          },
          function (FPError) {
            
          }
        )
      }
    },
    openFilePicker: function () {
      this.$emit('update:filepickerOpen', true)
      let self = this
      this.openFilestackPicker()

      if (this.dialogToReturnTo && this.dialogToReturnTo.length > 0) {
        this.$root.$emit('bv::hide::modal', this.dialogToReturnTo)
      }
    },
    openFilestackPicker () {
      const client = filestack.init(this.filePickerKey)
      const options = {
        maxFiles: 1,
        fromSources: ['local_file_system','url','facebook','instagram', 'googledrive','dropbox', 'gmail', 'onedrive'],
        onUploadDone: this.filePickerDone
      }
      client.picker(options).open()
    },
    filePickerDone: function (data) {
      if (data.filesUploaded && data.filesUploaded.length) {
        this.handleFilePickerResponse(data.filesUploaded[0].filename, data.filesUploaded[0].url, data.filesUploaded[0])
        this.$root.$emit('bv::show::modal', this.dialogToReturnTo)
        this.uploading_drop_file = false
      } else {
        this.$root.$emit('bv::show::modal', this.dialogToReturnTo)
      }
    },
    filePickerError: function (data) {
      console.log('Error:', data)
    }
  }
}
</script>
<style>
</style>
