<template>
  <div>
    <div class="header-v2-search">
      <div v-if="isMobile" @click="open = true" class="header-v2-search-mobile">
        <i class="material-icons md-search" />
      </div>
      <el-input
        @focus="open = true"
        v-else
        placeholder="Search"
        prefix-icon="el-icon-search"
      />
      <global-search-dialog
        v-if="open"
        @close="open = false"
        @select="select"
      />
    </div>
    <consumer-dialog
      v-model="consumerOpen"
      :consumer="consumer"
      append-to-body
    />
    <draft-warning-dialog 
      :visible.sync='draftWarningVisible'
      @discardDraft="discardDraft"
      @leaveDraft="leaveDraft"
      @publishDraft="publishDraft"
    />
  </div>
</template>

<script>
import GlobalSearchDialog from '@/components/modals/GlobalSearchDialog/Index'
import ConsumerDialog from '@/components/modals/ConsumerDialog/Index'
import DraftWarningDialog from '../../JobPageV2/helpers/dialogs/DraftWarningDialog.vue'
import { sync } from 'vuex-pathify'
import {
  GET_CONSUMER_QUERY
} from '@/apollo/queries/consumer.query'

export default {
  inject: ['isMobile'],
  data() {
    return {
      open: false,
      consumer: null,
      consumerOpen: false,
      draftWarningVisible: false,
      draftWarningNavKey: null,
      salesideJobNumber: null
    }
  },
  computed: {
    statusDialog: sync('jobDetails/actionDialogs@status'),
    globalLoading: sync('globalLoading')
  },
  components: { GlobalSearchDialog, ConsumerDialog, DraftWarningDialog },
  methods: {
    async discardDraft() {
      try {
        await this.$messageBox.confirm(
          'This will permanently delete the draft. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
        this.draftWarningVisible = false
        this.$unum.job().delete(`/draft/${this.$route.params.draft_id}`)
        window.location.href = `/jobs/${this.draftWarningNavKey}`
      } catch {}
    },
    leaveDraft() {
      this.draftWarningVisible = false
      window.location.href = `/jobs/${this.draftWarningNavKey}`
    },
    publishDraft() {
      this.draftWarningVisible = false
      this.statusDialog = true
    },
    async select(v) {
      this.open = false
      if (v.type === 'job') {
        if (this.$job?.isDraft && this.$job?.shop?.id) {
          this.draftWarningNavKey = v.id
          this.draftWarningVisible = true
        } else {
          window.location.href = `/jobs/${v.id}`
        }
      } else if (v.type === 'consumer') {
        try {
          this.open = false
          this.globalLoading = true
          const apolloData = await this.$apollo.query({
            query: GET_CONSUMER_QUERY,
            variables: {
              id: v.id,
              organization_id: v.organization_id
            }
          })
          this.consumer = apolloData.data.consumer
          this.consumerOpen = true
        } finally {
          this.globalLoading = false
        }
      } else if (v.type === 'salesidejob') {
        this.open = false
        this.salesideJobNumber = v.id
        this.$root.$emit('showMobileJob', v.id, false)
      }
    }
  }
}
</script>

<style lang="scss">
.header-v2-search {
  display: flex;
  .el-input {
    width: 150px;
    > input {
      transition: border-color 0.2s ease-in-out;
      background: $grayscale-8;
      border-color: $grayscale-8;
      border-radius: 4px;
      height: 32px;
      border-radius: 16px;
      &::placeholder {
        transition: color 0.2s ease-in-out;
        color: $grayscale-6 !important;
      }
    }
    &:hover {
      > input {
        border-color: #fff;
        &::placeholder {
          color: #fff !important;
        }
      }
      i {
        color: #fff;
      }
    }
    margin-right: $padding-sm;
    i {
      transform: translateY(-4px);
      transition: color 0.2s ease-in-out;
      color: $grayscale-6;
    }
  }
  .header-v2-search-mobile {
    height: 24px;
    margin-right: $padding-sm;
    i {
      color: #fff;
    }
  }
}
</style>
