var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { placement: "right", trigger: "click" },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c("div", { staticClass: "t-mb-2" }, [
            _vm._v(" Invite sent " + _vm._s(_vm.createdDate) + " ")
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "reference", size: "mini", plain: "" },
                  on: { click: _vm.cancelTechsideInvitation },
                  slot: "reference"
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-button",
        {
          attrs: {
            slot: "reference",
            size: "mini",
            type: "warning",
            plain: ""
          },
          slot: "reference"
        },
        [_vm._v("Pending")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }