<template>
  <el-popover
    placement="right"
    trigger="click"
    v-model="visible"
  >
    <div v-loading="loading" >
      <div class="t-mb-2">
        Invite sent {{ createdDate }}
      </div>
      <div>
        <el-button slot="reference" size="mini" @click="cancelTechsideInvitation" plain>Cancel</el-button>
      </div>
    </div>
    <el-button slot="reference" size="mini" type="warning" plain>Pending</el-button>
  </el-popover>
</template>

<script>
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import { mapActions, mapState } from 'pinia'
import { humanDateTime } from '@/scripts/helpers/date.helpers'

export default {
  data() {
    return {
      loading: false,
      visible: false
    }
  },

  computed: {
    ...mapState( useVendorStore, ['data'] ),
    createdDate () {
      return humanDateTime(this.data.vendorTechsideConnection.invite.createdAt)
    }
  },
  methods: {
    ...mapActions(useVendorStore, ['cancelInvitation']),
    async cancelTechsideInvitation() {
      this.loading = true

      try {
          await this.cancelInvitation();
          this.$message.success("TechSIDE invitation has been successfully cancelled")
        } catch (error) {
          this.$message.error(error.message)
        }
        this.visible = false
        this.loading = false
    }
  }
}
</script>
