var render = function() {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "t-flex t-mt-2",
      class: _vm.loading ? "t-cursor-not-allowed" : "t-cursor-pointer",
      on: { click: _vm.onClick }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "t-rounded-l-md t-w-10 t-text-sm t-flex t-items-center\n      t-justify-center t-h-8 t-text-gray-600 t-border t-border-solid",
          class:
            ((_obj = {}),
            (_obj[_vm.vars.bg] = true),
            (_obj[_vm.vars.border] = true),
            (_obj["t-rounded-r-md"] = _vm.loading),
            _obj)
        },
        [
          _vm.loading
            ? _c("i", { staticClass: "el-icon-loading" })
            : _c("div", [
                _vm.price
                  ? _c("div", [_vm._v(" " + _vm._s(_vm.total) + " ")])
                  : _c("i", { staticClass: "el-icon-circle-close t-text-base" })
              ])
        ]
      ),
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "t-flex t-items-center t-rounded-r-md t-border t-border-l-0\n      t-border-solid t-relative t-px-2 t-space-x-2",
              class: ((_obj$1 = {}), (_obj$1[_vm.vars.border] = true), _obj$1)
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "t-absolute t-text-2xs t--top-2 t-left-2 t-bg-white t-px-1\n        t-tracking-wider",
                  class:
                    ((_obj$2 = {}),
                    (_obj$2[_vm.vars.labelColor] = true),
                    _obj$2)
                },
                [_vm._v(" " + _vm._s(_vm.vars.name) + " ")]
              ),
              !_vm.loading && !_vm.price
                ? _c("div", { staticClass: "t-text-xs t-text-gray-600" }, [
                    _vm._v(" No price data ")
                  ])
                : _vm.price === "new"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "t-text-xs t-text-white t-px-1 t-bg-blue-500 t-rounded-md"
                    },
                    [_vm._v(" New! ")]
                  )
                : _c("div", { staticClass: "t-text-sm t-text-gray-600" }, [
                    _vm._v(" $" + _vm._s(_vm.price) + " ")
                  ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }