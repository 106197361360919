var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.routeEntryDetails,
            expression: "routeEntryDetails"
          }
        ],
        staticClass: "scheduler-route-entry-details"
      },
      [
        _c("div", { staticClass: "header" }, [
          _c("h4", [
            _c("div", [_vm._v(_vm._s(_vm.title))]),
            _c("span", [_vm._v(" " + _vm._s(_vm.data.name) + " ")])
          ]),
          _c(
            "div",
            { on: { click: _vm.closeWindow } },
            [_c("v-icon", { attrs: { name: "times", scale: "0.8" } })],
            1
          )
        ]),
        _c("div", { staticClass: "content" }, [
          _c("table", [
            _c("tbody", [
              _vm.data.g_address
                ? _c("tr", [
                    _c("td", [_c("span", [_vm._v("Address:")])]),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.data.g_address.address) + " "),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(_vm.data.g_address.city) +
                          ", " +
                          _vm._s(_vm.data.g_address.state) +
                          " " +
                          _vm._s(_vm.data.g_address.zip) +
                          " "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.details.arrival
                ? _c("tr", [
                    _c("td", [_c("span", [_vm._v("Arrival:")])]),
                    _c("td", [_vm._v(" " + _vm._s(_vm.details.arrival) + " ")])
                  ])
                : _vm._e(),
              _vm.details.departure
                ? _c("tr", [
                    _c("td", [_c("span", [_vm._v("Departure:")])]),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.details.departure) + " ")
                    ])
                  ])
                : _vm._e(),
              _vm.details.parts && _vm.details.parts.length
                ? _c("tr", [
                    _c("td", [_c("span", [_vm._v("Parts:")])]),
                    _c(
                      "td",
                      [
                        _c("route-entry-part-pickup-list", {
                          attrs: { item: _vm.details }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.details.notes
                ? _c("tr", [
                    _c("td", [_c("span", [_vm._v("Notes:")])]),
                    _c("td", [_vm._v(" " + _vm._s(_vm.details.notes) + " ")])
                  ])
                : _vm._e()
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }