<template>
  <el-input
    @focus="onFocus"
    @blur="onBlur"
    ref="ref"
    v-model="number"
    :size="size"
    :disabled="disabled"
    :placeholder="placeholder"
    v-currency="{ precision, allowNegative }"
    @keypress.native="setNegative"
    @keydown.native="handleTab"
  >
    <slot name="append" slot="append" />
    <slot name="prepend" slot="prepend" />
    <slot name="prefix" slot="prefix" />
    <slot name="suffix" slot="suffix" />
  </el-input>
</template>

<script>
import * as R from 'ramda'

export default {
  props: {
    value: {},
    disabled: Boolean,
    size: {
      type: String,
      default: () => 'small'
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    placeholder: [String, Boolean],
    precision: {
      type: Number,
      default: () => 2
    }
  },
  data() {
    return {
      showNegative: false,
      localNumber: this.value
    }
  },
  computed: {
    number: {
      get() {
        return this.localNumber
      },
      set(v) {
        this.localNumber = v
        this.$emit('input', +v.replace(/,/g, ''))
      }
    }
  },
  methods: {
    focus() {
      this.$refs.ref.focus()
    },
    onFocus(e) {
      this.$emit('focus', e)
    },
    onBlur(e) {
      this.$emit('blur', e)
    },
    setNegative(e) {
      if (e?.key === '-') {
        e.preventDefault()
        this.number = R.ifElse(
          R.startsWith('-'),
          R.tail,
          R.concat('-')
        )(`${this.number}`)
      }
    },
    handleTab(e) {
      if (e?.key === 'Tab') {
        this.$emit('tab')
      }
    }
  },
  watch: {
    value(v) {
      this.localNumber = v
    }
  }
}
</script>

<style>
</style>
