var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gb-tag",
      class:
        ((_obj = {}),
        (_obj[_vm.color] = _vm.color),
        (_obj.outline = _vm.outline),
        (_obj["tag-hidden"] = _vm.hidden),
        (_obj.disabled = _vm.disabled),
        (_obj["no-margin"] = _vm.noMargin),
        (_obj.small = _vm.small),
        _obj),
      style: { color: _vm.hex }
    },
    [_c("span", [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }