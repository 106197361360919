var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      on: {
        "before-leave": _vm.beforeLeave,
        "after-leave": _vm.afterLeave,
        leave: _vm.leave,
        "before-enter": _vm.beforeEnter,
        "after-enter": _vm.afterEnter,
        enter: _vm.enter
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }