<template>
  <base-layout :data="data">
    <div slot="title">SMS Signature Received</div>

    <div>
      {{shopName}}: the customer for job <a target="_blank" :href="jobLink">{{data.details.jobId}}</a>
      has signed their invoice.
    </div>
  </base-layout>

</template>

<script>
import BaseLayout from './_BaseLayout'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    jobLink() {
      return `/jobs/${this.data.details.jobId}`
    },

    shopName() {
      const shop = this.$store.state.shops.find(
        obj => obj.id.toString() === this.data.details.shopId)

      return shop?.name
    }
  },

  components: { BaseLayout }
}
</script>
