var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consumer-tags" },
    [
      _vm.status === "disabled"
        ? _c(
            "gb-tag",
            { attrs: { small: _vm.small, disabled: "", color: "red" } },
            [_vm._v("Disabled")]
          )
        : _vm._e(),
      _vm.is_insurance
        ? _c(
            "gb-tag",
            {
              staticStyle: { color: "#6366f1" },
              attrs: { small: _vm.small, disabled: "" }
            },
            [_vm._v(" Ins ")]
          )
        : _vm.is_commercial
        ? _c(
            "gb-tag",
            {
              staticStyle: { color: "#eab308" },
              attrs: { small: _vm.small, disabled: "" }
            },
            [_vm._v(" Com ")]
          )
        : _vm.guest
        ? _c(
            "gb-tag",
            { attrs: { small: _vm.small, disabled: "", color: "purple" } },
            [_vm._v(" Guest ")]
          )
        : _c(
            "gb-tag",
            {
              staticStyle: { color: "#06b6d4" },
              attrs: { small: _vm.small, disabled: "" }
            },
            [_vm._v(" Retail ")]
          ),
      _vm.tax_exempt
        ? _c("gb-tag", { attrs: { small: _vm.small, disabled: "" } }, [
            _vm._v(" Exempt ")
          ])
        : _vm._e(),
      _vm.po_required
        ? _c(
            "gb-tag",
            { attrs: { small: _vm.small, disabled: "", color: "sky-blue" } },
            [_vm._v(" PO Required ")]
          )
        : _vm._e(),
      _vm.flagged
        ? _c(
            "gb-tag",
            { attrs: { small: _vm.small, disabled: "", color: "dark" } },
            [_vm._v(" Flagged ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }