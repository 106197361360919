<template>
  <VehicleCard :lossNotice.sync="lossNotice" :schema="schema"/>
</template>

<script>
import VehicleCard from '@/components/modals/GlaxisDialogs/claim/components/VehicleCard'
import { applyMediaRules } from '@/scripts/helpers/media.helpers'

export default {
  components: { VehicleCard },
  props: ['lossNotice', 'updateSchemaLabelsWithMatchingAndSubrogation', 'vehicle', 'fieldsDisabled'],
  computed: {
    rowSpanOne() {
      return applyMediaRules({ lg: 24, md: 24, sm: 24, xs: 24 })
    },
    rowSpanTwo() {
      return applyMediaRules({ lg: 12, md: 12, sm: 12, xs: 12 })
    },
    rowSpanThree() {
      return applyMediaRules({ lg: 8, md: 8, sm: 12, xs: 12 })
    },
    rowSpanFour() {
      return applyMediaRules({ lg: 6, md: 6, sm: 12, xs: 12 })
    },
    schema() {
      var schema = {
        schemaType: 'vehicle',
        title: 'Vehicle',
        icon: 'directions_car',
        fields: [[
          {
            lossNoticeDefault: this.vehicle?.nags_id,
            label: 'NAGS ID',
            id: 'vehicle_id_number',
            editable: false,
            span: this.rowSpanTwo,
            tooltip: 'Insurance company or claims manager will use to determine the Vehicle on the policy. If NO NAGS ID exists (i.e. new/obsolete cars) the retailer will leave blank and may have to select the relevant vehicle from the policy during the coverage confirmation process. If NO NAGS ID exists the retailer should continue to send the vehicle YEAR, MAKE, MODEL, and STYLE. If necessary the user will need to manually key in the vehicle information.'
          },
          {
            label: 'Type of Vehicle',
            id: 'type_of_vehicle',
            componentType: 'inputWithLabel',
            labelKey: 'label',
            disabled: this.fieldsDisabled,
            select: {
              valueKey: 'value',
              options: [{
                label: 'Standard',
                value: 'STANDARD'
              }, {
                label: 'RV',
                value: 'RV'
              }],
              clearable: false,
              fixedOptions: true,
              showLabelValue: true,
              select: ({ value }) => {
                this.$set(this.lossNotice, 'type_of_vehicle', value)
              },
              clear: () => {
                this.$set(this.lossNotice, 'type_of_vehicle', '')
              }
            },
            span: this.rowSpanTwo,
            overrideClearable: true
          }
        ],
        [
          {
            label: 'Vehicle Ownership',
            id: 'vehicle_ownership',
            tooltip: 'Relationship of the vehicle to the Insured or Claimant',
            componentType: 'inputWithLabel',
            labelKey: 'label',
            disabled: this.fieldsDisabled,
            select: {
              valueKey: 'value',
              options: this.vehicleOwnership,
              showLabelValue: true,
              clearable: true,
              fixedOptions: true,
              select: ({ value }) => {
                this.$set(this.lossNotice, 'vehicle_ownership', value)
              },
              clear: () => {
                this.$set(this.lossNotice, 'vehicle_ownership', '')
              }
            },
            span: this.rowSpanTwo,
            overrideClearable: true
          },
          {
            label: 'Vehicle Number',
            id: 'vehicle_number',
            componentType: 'inputWithLabel',
            labelKey: 'label',
            disabled: this.fieldsDisabled,
            tooltip: 'Some insurance clients require the vehicle number to verify coverage. This is used for multiple cars on a policy.',
            select: {
              valueKey: 'value',
              options: this.vehicleNumbers,
              clearable: true,
              fixedOptions: true,
              showLabelValue: true,
              select: ({ value }) => {
                this.$set(this.lossNotice, 'vehicle_number', value)
              },
              clear: () => {
                this.$set(this.lossNotice, 'vehicle_number', '')
              }
            },
            span: this.rowSpanTwo,
            overrideClearable: true
          }
        ]
        ]
      }
      return this.updateSchemaLabelsWithMatchingAndSubrogation(schema)
    },
    vehicleNumbers() {
      var results = []
      for (var i = 1; i < 10; i++) {
        results.push({
          label: `${i}`,
          value: `${i}`
        })
      }
      return results
    },
    vehicleOwnership() {
      var options = [
        'Owner', 'Commercial', 'Rental', 'Borrowed', '3rd Party'
      ]
      var results = []
      for (var i = 0; i < options.length; i++) {
        results.push({
          label: options[i],
          value: options[i]
        })
      }
      return results
    }
  }
}
</script>

<style>

</style>
