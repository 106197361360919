var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-group" },
    [
      _c("input-group-row", {
        attrs: { fields: _vm.fields, main: "", label: _vm.label },
        model: {
          value: _vm.primaryValue,
          callback: function($$v) {
            _vm.primaryValue = $$v
          },
          expression: "primaryValue"
        }
      }),
      _vm.value
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "right",
                trigger: "click",
                title: _vm.groupLabel
              }
            },
            [
              _vm._l(_vm.value, function(row, idx) {
                return _c(
                  "div",
                  { key: row.id, staticClass: "group-rows" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Primary",
                          "open-delay": 500,
                          placement: "left"
                        }
                      },
                      [
                        _c("el-radio", {
                          attrs: { label: idx },
                          model: {
                            value: _vm.radio,
                            callback: function($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio"
                          }
                        })
                      ],
                      1
                    ),
                    _c("input-group-row", {
                      attrs: {
                        value: row,
                        fields: _vm.fields,
                        index: idx,
                        length: _vm.value.length
                      },
                      on: {
                        input: function($event) {
                          return _vm.updateRow($event, idx)
                        },
                        delete: function($event) {
                          return _vm.deleteRow(idx)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", size: "mini", type: "text" },
                  on: { click: _vm.addRow }
                },
                [_vm._v(" ADD ")]
              ),
              _c("el-button", {
                attrs: {
                  slot: "reference",
                  icon: "el-icon-caret-right",
                  size: "mini"
                },
                slot: "reference"
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }