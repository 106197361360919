<template>
  <div class="t-flex">
    <!-- Tab list -->
    <div
      class="t-w-44 t-border-0 t-border-r t-border-solid t-border-r-gray-200 t-pt-10 t-space-y-2 t-flex-shrink-0"
    >
      <template v-if="data.id">
        <TabListItem
          v-if="isCSR || isSaleSource || isSalesRep || isTech || isLocation"
          :active="activeTab === tabs.stats"
          @click.native="activeTab = tabs.stats"
        >
          {{ tabs.stats }}
        </TabListItem>

        <TabListItem
          :active="activeTab === tabs.contactInfo"
          @click.native="activeTab = tabs.contactInfo"
        >
          {{ tabs.contactInfo }}
        </TabListItem>

        <TabListItem
          :active="activeTab === tabs.settings"
          @click.native="activeTab = tabs.settings"
        >
          {{ tabs.settings }}
        </TabListItem>

        <TabListItem
          :active="activeTab === tabs.notes"
          @click.native="activeTab = tabs.notes"
        >
          {{ tabs.notes }}
        </TabListItem>

        <TabListItem
          v-if="isCSR || isSaleSource || isSalesRep || isTech || isLocation"
          :active="activeTab === tabs.commissions"
          @click.native="activeTab = tabs.commissions"
        >
          {{ tabs.commissions }}
        </TabListItem>

        <TabListItem
          v-if="isSalesRep"
          :active="activeTab === tabs.techsideAccount"
          @click.native="activeTab = tabs.techsideAccount"
        >
          Saleside Account
        </TabListItem>

        <TabListItem
          :active="activeTab === tabs.documents"
          @click.native="activeTab = tabs.documents"
        >
          <div>Documents</div>
          <div
            v-if="data.documents && data.documents.length"
            class="t-text-blue-700 t-w-5 t-h-5 t-bg-blue-100 t-rounded-full t-flex t-items-center t-justify-center t-text-xs"
          >
            <div>{{ data.documents.length }}</div>
          </div>
        </TabListItem>
      </template>

      <TabListItem v-else :active="true">
        Create
      </TabListItem>
    </div>

    <!-- Tab Body -->
    <el-scrollbar
      class="t-flex-grow t-h-[700px] t-my-4 t-mr-2"
      wrap-class="t-overflow-x-hidden t-flex-shrink-0 t-py-2 t-px-8"
    >
      <template v-if="data.id">
        <Stats v-show="activeTab === tabs.stats" />

        <ContactInfo v-show="activeTab === tabs.contactInfo" />

        <Settings v-show="activeTab === tabs.settings" />

        <Notes v-show="activeTab === tabs.notes" />

        <Commissions v-show="activeTab === tabs.commissions" />

        <TechsideAccount v-show="activeTab === tabs.techsideAccount" />

        <Documents v-show="activeTab === tabs.documents" />
      </template>

      <CreateFrom v-else />
    </el-scrollbar>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import TabListItem from '@/components/Vendors/Profile/TabListItem.vue'
import Stats from '@/components/Vendors/Profile/Tabs/Stats.vue'
import ContactInfo from '@/components/Vendors/Profile/Tabs/ContactInfo/Index.vue'
import Settings from '@/components/Vendors/Profile/Tabs/Settings/Index.vue'
import Notes from '@/components/Vendors/Profile/Tabs/Notes.vue'
import Commissions from '@/components/Vendors/Profile/Tabs/Commissions.vue'
import TechsideAccount from '@/components/Vendors/Profile/Tabs/TechsideAccount.vue'
import Documents from '@/components/Vendors/Profile/Tabs/Documents/Index.vue'
import CreateFrom from './CreateForm.vue'

const tabs = {
  stats: 'Stats',
  contactInfo: 'Contact Info',
  settings: 'Settings',
  notes: 'Notes',
  commissions: 'Commissions',
  techsideAccount: 'Techside Account',
  documents: 'Documents'
}

export default {
  components: {
    TabListItem,
    Stats,
    ContactInfo,
    Settings,
    Notes,
    Commissions,
    TechsideAccount,
    Documents,
    CreateFrom
  },

  data() {
    return {
      tabs,
      activeTab: ''
    }
  },

  computed: {
    ...mapState(
      useVendorStore,
      [
        'data',
        'isCSR',
        'isSaleSource',
        'isSalesRep',
        'isTech',
        'isLocation',
        'isSupplier'
      ]
    )
  },

  watch: {
    'data.id': function (val) {
      if (val) {
        this.activeTab = this.isSupplier ? tabs.contactInfo : tabs.stats
      } else {
        this.activeTab = ''
      }
    }
  }
}
</script>
