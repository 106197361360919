<template>
  <el-form
    ref="form"
    :model="data"
    :rules="rules"
    label-width="140px"
    size="small"
  >
    <consumer-select :consumerToCharge.sync="data.consumerToCharge" />
    <amount :requestedAmount.sync="data.amount" />
    <el-form-item style="word-break: break-word">
      <span slot="label" class="undo-label-styles" style="color: #f56c6c"
        >NOTE:</span
      >
      <div>
        When a link is generated, all previous payment links for this job will
        no longer be valid.
      </div>
    </el-form-item>
    <el-form-item>
      <div v-if="link" class="t-flex">
        <el-input readonly v-model="link"></el-input>
        <el-tooltip content="Copy link" placement="top">
          <i
            class="
              el-icon-copy-document
              t-ml-3 t-mr-1 t-mt-2 t-cursor-pointer
              hover:t-text-blue-500
            "
            @click="copy(link)"
          />
        </el-tooltip>
      </div>
    </el-form-item>
    <el-form-item>
      <!-- <el-input readonly v-model="link"> -->
      <el-button
        class="mb-2 mr-2"
        type="primary"
        @click="handleSubmit"
        :loading="loading"
      >
        Generate Link
      </el-button>
      <!-- </el-input> -->
    </el-form-item>
  </el-form>
</template>

<script>
import ConsumerSelect from "./FormItem/ConsumerSelect.vue";
import Amount from "./FormItem/Amount.vue";
import TextMessageTemplate from "@/scripts/objects/text_message_template";
import { call, get } from "vuex-pathify";

export default {
  components: { ConsumerSelect, Amount },
  data() {
    return {
      data: {
        consumerToCharge: "",
        amount: 0.0,
        typeId: "",
      },
      rules: {
        amount: { required: true, validator: (rule, value, callback) => {
          if (value <= 0) {
            callback(new Error('Please enter a positive amount'))
          } else if (!value) {
            callback(new Error('Please enter a valid amount'))
          } else {
            callback()
          }
        }, trigger: 'change'}
      },
      link: null,
      loading: false,
    };
  },
  computed: {
    paymentWasSentBefore: get(
      "twilio/textMessages/jobPayment/paymentWasSentBefore"
    ),
    balanceDetails: get("twilio/textMessages/jobPayment/balanceDetails"),
    jobId: get("twilio/textMessages/jobPayment/jobId"),
    shopId: get("twilio/textMessages/jobPayment/shopId"),
  },
  mounted() {
    this.setDefaultPaymentTypeAndAmount();
  },
  methods: {
    getHistory: call('twilio/textMessages/jobPayment/getHistory'),
    copy: async function (s) {
      await navigator.clipboard.writeText(s);
      this.$message.success("Copied!");
    },
    async handleSubmit() {
      this.loading = true;
      try {
        if (await this.$refs.form.validate()) {
          if (this.paymentWasSentBefore) {
            await this.$messageBox.confirm(
              "You previously have sent/generated a payment request for this job. Generating a new link with invalidate any previous links. Are you sure you want to send another one ?",
              "Warning",
              {
                type: "warning",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                beforeClose: async (action, instance, done) => {
                  if (action === "confirm") {
                    this.send();
                  }
                  done();
                },
              }
            );
          } else {
            this.send();
          }
        }
      } catch (err) {
        if (
          err.error === "The message From/To pair violates a blacklist rule."
        ) {
          this.$message.warning(
            "Customer previously replied STOP to this number, so this message can't be delivered."
          );
        }
        this.loading = false;
      }
    },
    async send() {
      const link = await TextMessageTemplate.sendPayment({
        jobId: this.jobId,
        typeId: this.data.typeId,
        amount: this.data.amount,
        consumer_id: this.data.consumerToCharge.id,
        consumer_name: this.data.consumerToCharge.name,
        type: "generate",
      });

      this.link = link;

      this.getHistory(this.jobId);

      this.$message.success(`Link successfully generated.`);
      this.loading = false;
    },
    setDefaultPaymentTypeAndAmount() {
      if (
        this.balanceDetails &&
        parseFloat(this.balanceDetails.total_deductible_after_payments)
      ) {
        this.data.typeId = 2;
        this.data.amount = this.balanceDetails.total_deductible_after_payments;
      } else {
        this.data.typeId = 1;
        this.data.amount = this.balanceDetails.total_balance_after_payments;
      }
    },
  },
  watch: {
    "data.consumerToCharge"(v) {
      this.data.amount = v.amount;
    },
  },
};
</script>

<style>
</style>