var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: { width: "25", height: "27", xmlns: "http://www.w3.org/2000/svg" }
    },
    [
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              x: "-39.5%",
              y: "-32.6%",
              width: "178.9%",
              height: "160.9%",
              filterUnits: "objectBoundingBox",
              id: "a"
            }
          },
          [
            _c("feOffset", {
              attrs: {
                dx: "1",
                dy: "1",
                in: "SourceAlpha",
                result: "shadowOffsetOuter1"
              }
            }),
            _c("feGaussianBlur", {
              attrs: {
                stdDeviation: "1",
                in: "shadowOffsetOuter1",
                result: "shadowBlurOuter1"
              }
            }),
            _c("feColorMatrix", {
              attrs: {
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.562581949 0",
                in: "shadowBlurOuter1",
                result: "shadowMatrixOuter1"
              }
            }),
            _c(
              "feMerge",
              [
                _c("feMergeNode", { attrs: { in: "shadowMatrixOuter1" } }),
                _c("feMergeNode", { attrs: { in: "SourceGraphic" } })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "g",
        {
          attrs: {
            filter: "url(#a)",
            transform: "translate(2 2)",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M.509 7.875H18.49c.28 0 .509.221.509.492v10.664C19 20.118 18.088 21 16.964 21H2.036C.912 21 0 20.118 0 19.031V8.367c0-.27.229-.492.509-.492zM19 6.07V4.594c0-1.087-.912-1.969-2.036-1.969H14.93V.492a.502.502 0 00-.51-.492h-1.696a.502.502 0 00-.509.492v2.133H6.786V.492A.502.502 0 006.276 0H4.58a.502.502 0 00-.509.492v2.133H2.036C.912 2.625 0 3.507 0 4.594V6.07c0 .271.229.492.509.492H18.49c.28 0 .509-.221.509-.492z",
              fill: _vm.fillColor || "#FF9393",
              "fill-rule": "nonzero",
              stroke: "#3B240F",
              "stroke-linecap": "round"
            }
          }),
          _c(
            "text",
            {
              attrs: {
                fill: "#FFF",
                "font-family": "Poppins-Regular, Poppins",
                "font-size": "12",
                "text-anchor": "middle",
                "alignment-baseline": "middle"
              }
            },
            [
              _c("tspan", { attrs: { x: "10", y: "18" } }, [
                _vm._v(_vm._s(_vm.text))
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }