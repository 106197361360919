<template>
  <div class="t-px-12">
    <table class="t-w-full">
      <thead>
        <tr
          class="t-border-0 t-border-b t-border-solid t-h-8 t-text-xs
            t-text-gray-500"
        >
          <td>Invoice date</td>
          <td>Invoice #</td>
          <td>Referral #</td>
          <td>Customer</td>
          <td>Third party</td>
          <td>Days</td>
          <td :class="{'pdf-no-display': !anyPaymentsOpen}">Payments</td>
          <td>Total invoice</td>
          <td>Balance due</td>
        </tr>
      </thead>
      <tbody>
        <template v-for="(i, index) in invoices">
          <tr
            class="t-h-8 t-text-sm t-text-gray-600"
            :class="{
              't-border-0 t-border-b t-border-solid': !paymentDetailsOpen[index]
            }"
            :key="index + 'row'"
          >
            <td>{{ formatDate(i.invoice_date) }}</td>
            <td>
              <a :href="`/jobs/${i.job_id}`" target="_blank">
                {{ shopId }}-{{ i.job_id }}
              </a>
            </td>
            <td>{{ i.referral_number }}</td>
            <td>{{ i.consumer_company_name || getConsumerName(i) }}</td>
            <td>{{ i.trading_partner }}</td>
            <td>{{ i.days_old }}</td>
            <td :class="{'pdf-no-display': !paymentDetailsOpen[index]}">
              <div
                v-if="i.payments.length"
                class="t-flex t-items-center t-cursor-pointer"
                
                @click="openPayments(index)"
              >
                <i
                  :class="
                    paymentDetailsOpen[index]
                      ? 'el-icon-arrow-down'
                      : 'el-icon-arrow-right'
                  "
                  class="hover:t-font-bold"
                />
                <div
                  class="t-flex t-items-center t-rounded-full t-bg-gray-50
                    t-border t-shadow-sm t-border-solid t-border-gray-300 t-px-2
                    t-py-[2px] t-ml-2"
                >
                  <i class="el-icon-document-copy" />
                  <span class="t-pl-2 t-text-gray-600">
                    {{ i.payments.length }}
                  </span>
                </div>
              </div>
            </td>
            <td>${{ i.total_balance }}</td>
            <td>${{ i.remaining_balance }}</td>
          </tr>
          <tr
            v-show="paymentDetailsOpen[index]"
            :key="index + 'payments'"
            class="t-border-0 t-border-b t-border-solid"
          >
            <td colspan="9">
              <div class="t-py-1 t-pl-12 t-bg-gray-50">
                <table class="t-w-[500px]">
                  <thead>
                    <tr class="t-h-8 t-text-xs t-text-gray-500">
                      <td>Date</td>
                      <td>Type</td>
                      <td>X-ActionType</td>
                      <td>Amount</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="p in i.payments"
                      :key="p.id"
                      class="t-h-8 t-text-sm t-text-gray-600 t-border-0
                        t-border-t t-border-solid"
                    >
                      <td>{{ formatDate(p.date) }}</td>
                      <td>{{ p.type }}</td>
                      <td>{{ p.x_action_type }}</td>
                      <td>${{ p.amount }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    invoices: Array,
    shopId: Number,
    expandPayments: Boolean
  },
  data: () => ({
    paymentDetailsOpen: {}
  }),
  mounted: function () {
    for (const index in this.invoices) {
      if (this.invoices[index].payments.length) {
        this.paymentDetailsOpen[index] = false
      }
    }
  },
  watch: {
    expandPayments: function(val) {
      for (const i in this.paymentDetailsOpen) {
        this.paymentDetailsOpen[i] = val
      }
      this.$forceUpdate()
    }
  },
  methods: {
    formatDate: date => moment(date).format('ll'),
    getConsumerName: c => {
      if (c.consumer_company_name) {
        return c.consumer_company_name
      }
      let name = c.consumer_first_name
      if (c.consumer_last_name) {
        name += ' ' + c.consumer_last_name
      }
      return name
    },
    openPayments: function(index) {
      this.paymentDetailsOpen[index] = !this.paymentDetailsOpen[index]
      this.$forceUpdate()
    }
  },
  computed: {
    anyPaymentsOpen () {
      const keys = Object.keys(this.paymentDetailsOpen)
      for (var i = 0; i < keys.length; i++) {
        if (this.paymentDetailsOpen[keys[i]]) {
          return true
        }
      }
      return false
    }
  }
}
</script>
