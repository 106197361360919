var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "1000px",
        "append-to-body": "",
        visible: _vm.visible,
        "custom-class": "shopping-cart-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-xl t-text-gray-800",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("Order details")]
      ),
      _c(
        "div",
        {
          staticClass: "t-p-4",
          staticStyle: { "max-height": "600px", "overflow-y": "auto" }
        },
        [
          _vm.loading
            ? _c(
                "div",
                {
                  staticClass: "t-p-12 t-text-center t-text-3xl t-text-blue-600"
                },
                [_c("i", { staticClass: "el-icon-loading" })]
              )
            : _c(
                "table",
                {
                  staticClass:
                    "t-w-full t-border t-border-solid t-border-collapse\n              t-border-gray-500"
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass:
                            "t-w-1/2 t-border t-border-solid t-border-gray-500 t-p-3\n                    t-text-base t-text-center"
                        },
                        [_vm._v(" Requested items ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass:
                            "t-w-1/2 t-border t-border-solid t-border-gray-500 t-p-3\n                    t-text-base t-text-center"
                        },
                        [_vm._v(" Response ")]
                      )
                    ])
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.items, function(i, index) {
                      return _c("tr", { key: index }, [
                        _c(
                          "td",
                          {
                            staticClass:
                              "t-border t-border-solid t-border-gray-500 t-p-3",
                            attrs: { valign: "top" }
                          },
                          _vm._l(i.requested, function(item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass:
                                  "t-px-3 t-py-2 t-rounded t-border t-border-solid\n                      t-border-gray-400 t-bg-gray-100 t-mb-2"
                              },
                              [
                                _c("div", { staticClass: "t-flex" }, [
                                  _c("div", { staticClass: "t-flex-grow" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "t-flex t-text-base t-mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "t-mr-1 t-font-bold" },
                                          [_vm._v(_vm._s(item.partType))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "t-mr-1 t-font-bold" },
                                          [
                                            item.partType === "GL"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.fullPartNumber
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.part) +
                                                      " "
                                                  )
                                                ])
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "t-mr-1 t-text-gray-600"
                                          },
                                          [_vm._v("by")]
                                        ),
                                        _c("div", [
                                          _vm._v(_vm._s(item.trademark))
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "t-flex t-text-sm t-items-center t-text-gray-600"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-location-outline t-text-base t-mr-1 t-text-blue-500"
                                        }),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(item.locationDescription)
                                          )
                                        ])
                                      ]
                                    )
                                  ]),
                                  _c("div", [
                                    _c("div", { staticClass: "t-flex" }, [
                                      _c("div", [
                                        _vm._v(_vm._s(item.quantity))
                                      ]),
                                      _c("div", { staticClass: "t-mx-2" }, [
                                        _vm._v("x")
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "t-font-bold" },
                                        [_vm._v("$" + _vm._s(item.priceBuyer))]
                                      )
                                    ]),
                                    _vm.details.fulfilled === 2 &&
                                    i.requested.length > 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "t-text-center t-mt-2 t-bg-white t-border t-border-solid\n                              t-border-red-400 t-rounded-md t-px-2 t-py-1\n                              t-cursor-pointer t-text-red-500\n                              hover:t-bg-red-500 hover:t-border-red-500\n                              hover:t-text-white t-text-xs",
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteFromOrder(
                                                  item.id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" Delete ")]
                                        )
                                      : _vm._e()
                                  ])
                                ])
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "td",
                          {
                            staticClass:
                              "t-border t-border-solid t-border-gray-600 t-p-3",
                            attrs: { valign: "top" }
                          },
                          [
                            i.received.length === 0 &&
                            _vm.details.fulfilled !== 1
                              ? _c("div", [_vm._v(" No data received yet. ")])
                              : _c(
                                  "div",
                                  _vm._l(i.received, function(item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass:
                                          "t-px-3 t-py-2 t-rounded t-border t-border-solid\n                        t-border-gray-400 t-bg-gray-100",
                                        class:
                                          index + 1 !== i.received.length
                                            ? "t-mb-2"
                                            : ""
                                      },
                                      [
                                        _c("div", { staticClass: "t-flex" }, [
                                          _c(
                                            "div",
                                            { staticClass: "t-flex-grow" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "t-flex t-text-base t-mb-1"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "t-mr-1 t-font-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.partType)
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "t-mr-1 t-font-bold"
                                                    },
                                                    [
                                                      item.partType === "GL"
                                                        ? _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.fullPartNumber
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        : _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.part
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "t-mr-1 t-text-gray-600"
                                                    },
                                                    [_vm._v("by")]
                                                  ),
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(item.trademark)
                                                    )
                                                  ])
                                                ]
                                              ),
                                              item.confirmationCode
                                                ? _c(
                                                    "div",
                                                    { staticClass: "t-mb-2" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "t-text-gray-600"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Confirmation code:"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "t-ml-2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.confirmationCode
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "t-flex t-text-sm t-items-center t-text-gray-600"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-location-outline t-text-base t-mr-1 t-text-blue-500"
                                                  }),
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.locationDescription
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "t-flex t-text-sm t-items-center t-text-gray-600"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-truck t-text-base t-mr-1 t-text-blue-500"
                                                  }),
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatDate(
                                                          item.deliveryDate
                                                        )
                                                      ) + ","
                                                    )
                                                  ]),
                                                  _c(
                                                    "div",
                                                    { staticClass: "t-mx-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatTime(
                                                            item.deliveryTime
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("div", [
                                                    _vm._v(
                                                      "- " +
                                                        _vm._s(
                                                          item.deliveryMethod
                                                        )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "t-flex" },
                                              [
                                                _c("div", [
                                                  _vm._v(_vm._s(item.quantity))
                                                ]),
                                                _c(
                                                  "div",
                                                  { staticClass: "t-mx-2" },
                                                  [_vm._v("x")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "t-font-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "$" +
                                                        _vm._s(item.priceBuyer)
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ]),
                                        index === 0
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-divider", {
                                                  staticClass: "t-mt-4 t-mb-2"
                                                }),
                                                _c(
                                                  "div",
                                                  { staticClass: "t-flex" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "t-mr-1 t-break-normal"
                                                      },
                                                      [_vm._v("Message:")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "t-break-normal",
                                                        class:
                                                          item.errorCode === "0"
                                                            ? "t-text-green-500"
                                                            : "t-text-red-500"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.errorDescription
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  0
                                )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }