import {
  parse,
  format,
  addDays,
  differenceInDays,
  differenceInSeconds,
  addSeconds,
  startOfDay,
  distanceInWordsToNow,
  addMinutes,
  startOfWeek,
  isValid
} from 'date-fns'
import * as R from 'ramda'
import moment from 'moment-timezone'

export const displayDateFormat = 'MMM D, YYYY'
export const dbDateFormat = 'YYYY-MM-DD'
export const reportDateFormat = 'MM/DD/YYYY'
export const humanDateFormat = 'MMM Do, YYYY'
export const humanDateFormatMini = 'MMM D, YYYY'
export const appointmentDateFormat = 'MM/dd/yyyy'
export const clockFormat = 'HH:mm:ss'
export const titleFormat = 'dddd, MMMM D'

export const displayTimeFormat = 'ha'
export const displayTimeMinutesFormat = 'HH:mm'
export const dbTimeFormat = 'HH:mm:ss'
export const displayTimeWithMinutes = 'h:mm a'
export const displayTimeWithMinutesCompact = 'h:mma'

const formatShort = date => format(date, displayDateFormat)
export const formatToDb = date => format(date, dbDateFormat)
export const formatTimeToDb = time => format(time, dbTimeFormat)
export const formatTimeToDbShort = time =>
  format(time, displayTimeMinutesFormat)
export const formatToReportDate = date => format(date, reportDateFormat)

export const getDateFromUTC = date => format(parse(date), dbDateFormat)

export const parseDateToString = R.pipe(parse, formatShort)

/**
 * @argument time '11:00:00'
 * @returns formatted time "11am"
 */
export const getTodaysDate = () => formatToDb(new Date())
export const getTomorrowDate = () => formatToDb(addDays(new Date(), 1))

export const parseTimeDate = (time, date = getTodaysDate()) =>
  parse(`${date}T${time}`)

export const formatToHour = time => +format(time, 'H')

export const parseDate = date => parse(date, dbDateFormat)
export const parseTime = (time, date) =>
  format(parse(`${date || getTodaysDate()}T${time}`), displayTimeFormat)
export const parseTimeWithMinutes = (time, date) => 
  format(parse(`${date || getTodaysDate()}T${time}`), displayTimeWithMinutesCompact)
export const parseTimeMinutes = (time, date) =>
  format(parse(`${date || getTodaysDate()}T${time}`), displayTimeMinutesFormat)

export const humanTime = (time, date) =>
  format(parse(`${date || getTodaysDate()}T${time}`), 'hh:mm a')

export const humanDate = date => format(date, humanDateFormat)
export const humanDateMini = date => format(date, humanDateFormatMini)

export const formatAppointmentDate = date => format(date, reportDateFormat)

export const formatCustomDateFormat = (date, dateFormat) => format(date, dateFormat)

export const daysDifference = date => differenceInDays(new Date(), parse(date))

export const timestampToMilliseconds = R.multiply(1000)

export const noteDateFormat = 'MM/DD/YYYY hh:mmA'
export const humanDateTime = date => format(date, noteDateFormat)

export const auditDateFormat = 'MM/DD/YYYY hh:mm:ss A'
export const humanDateTimeSeconds = date => format(date, auditDateFormat)

export const isNotOlderThan = days => R.pipe(daysDifference, R.gt(days))

export const getCurrentClock = date => {
  const seconds = differenceInSeconds(new Date(), date)
  const helperDate = addSeconds(startOfDay(new Date()), seconds)
  return format(helperDate, clockFormat)
}

export const getHumanDate = date => format(date, humanDateFormat)

export const dateToHumanTime = date => format(date, displayTimeFormat)
export const getHumanTime = date => {
  if (date.includes(' - ')) {
    const twoDates = date.split(' - ')
    const tempStart = format(twoDates[0], 'hh:mmA')
    const tempEnd = format(twoDates[1], 'hh:mmA')
    return tempStart + ' - ' + tempEnd
  }
  return format(date, 'hh:mmA')
}

export const humanTimeDistance = date =>
  distanceInWordsToNow(date, { addSuffix: true })

export const getMinutes = R.pipe(
  R.split(':'),
  R.apply(R.useWith(R.add, [R.multiply(60), R.multiply(1)]))
)

export const ignoreTimezone = v =>
  new Date(new Date(v).valueOf() + new Date(v).getTimezoneOffset() * 60 * 1000)
export const formatToDbWithTimezone = v =>
  formatToDb(new Date(v.valueOf() + v.getTimezoneOffset() * 60 * 1000))

const timeString = s => parse(`${getTodaysDate()}T${s}`)
export const getTimeOptions = R.pipe(
  R.useWith(
    (start, end) =>
      R.unfold(
        v =>
          v > end
            ? false
            : [
              {
                label: format(v, 'hh:mm a'),
                value: format(v, 'HH:mm:ss'),
                shortLabel: format(v, 'h')
              },
              addMinutes(v, 15)
            ],
        start
      ),
    [timeString, timeString]
  ),
  R.map(R.evolve({ label: R.when(R.equals('12:00 am'), () => 'Midnight') })),
  R.map(R.evolve({ label: R.when(R.equals('12:00 pm'), () => 'Noon') }))
)

export const getTimeButtons = R.pipe(
  R.useWith(
    (start, end) =>
      R.unfold(
        v =>
          v > end
            ? false
            : [
              { label: format(v, 'h'), value: format(v, 'HH:mm:ss') },
              addMinutes(v, 60)
            ],
        start
      ),
    [timeString, timeString]
  )
)

export const getClosestDayOfNextWeek = dayOfWeek =>
  addDays(startOfWeek(new Date()), dayOfWeek + 7)

export const fromTimestamp = timestamp =>
  format(new Date(timestamp * 1000), `MM/DD/YY ${displayTimeWithMinutes}`)

export const fromDate = date =>
  format(date, `MM/DD/YY ${displayTimeWithMinutes}`)

export const pickerShortcuts = () => ({
  shortcuts: [
    {
      text: 'Today',
      onClick(picker) {
        picker.$emit('pick', new Date())
      }
    },
    {
      text: 'Tomorrow',
      onClick(picker) {
        var d = new Date()
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000)
        picker.$emit('pick', d)
      }
    },
    {
      text: 'The Day After',
      onClick(picker) {
        var d = new Date()
        d.setTime(d.getTime() + 2 * 24 * 60 * 60 * 1000)
        picker.$emit('pick', d)
      }
    },
    {
      text: '-----------------'
    },
    {
      text: 'Next Monday',
      onClick(picker) {
        var d = new Date()
        d.setDate(d.getDate() + (((7 - d.getDay()) % 7) + 1))
        picker.$emit('pick', d)
      }
    },
    {
      text: 'Next Tuesday',
      onClick(picker) {
        var d = new Date()
        d.setDate(d.getDate() + (((7 - d.getDay()) % 7) + 2))
        picker.$emit('pick', d)
      }
    },
    {
      text: 'Next Wed',
      onClick(picker) {
        var d = new Date()
        d.setDate(d.getDate() + (((7 - d.getDay()) % 7) + 3))
        picker.$emit('pick', d)
      }
    },
    {
      text: 'Next Thursday',
      onClick(picker) {
        var d = new Date()
        d.setDate(d.getDate() + (((7 - d.getDay()) % 7) + 4))
        picker.$emit('pick', d)
      }
    },
    {
      text: 'Next Friday',
      onClick(picker) {
        var d = new Date()
        d.setDate(d.getDate() + (((7 - d.getDay()) % 7) + 5))
        picker.$emit('pick', d)
      }
    },
    {
      text: 'Next Saturday',
      onClick(picker) {
        var d = new Date()
        d.setDate(d.getDate() + (((7 - d.getDay()) % 7) + 6))
        picker.$emit('pick', d)
      }
    }
  ]
})

export const getTitleDate = date => format(date, titleFormat)

export const getFormattedDateTimeWithUserTimezone = (store, dateTime, format) => {
  if (store.state?.user?.timezone?.length > 0) {
    return moment(dateTime).tz(store.state.user.timezone).format(format)
  } else {
    return moment(dateTime).format(format)
  }
}

export const getDateFromIso = date => moment(date).utc().format('MM/DD/YY h:mm A')
