var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "focusout",
          rawName: "v-focusout",
          value: _vm.focusout,
          expression: "focusout"
        }
      ],
      ref: "ref",
      staticClass: "gb-form"
    },
    _vm._l(_vm.schema, function(row, idx) {
      return _c(
        "el-row",
        { key: idx, staticClass: "gb-form-row", attrs: { gutter: 16 } },
        [
          row.divider
            ? _c("el-divider", { attrs: { "content-position": "left" } }, [
                row.divider !== true
                  ? _c("span", [_vm._v(_vm._s(row.divider))])
                  : _vm._e()
              ])
            : _vm._l(row, function(col) {
                return _c(
                  "el-col",
                  { key: col.id, attrs: { span: 24 / row.length } },
                  [
                    col.type === "group"
                      ? _c(
                          "input-group",
                          _vm._b(
                            {
                              model: {
                                value: _vm.model,
                                callback: function($$v) {
                                  _vm.model = $$v
                                },
                                expression: "model"
                              }
                            },
                            "input-group",
                            col,
                            false
                          )
                        )
                      : col.type === "switch"
                      ? _c(
                          "el-switch",
                          _vm._b(
                            {
                              attrs: { value: _vm.getValue(col) },
                              on: {
                                input: function($event) {
                                  return _vm.setValue($event, col)
                                }
                              }
                            },
                            "el-switch",
                            col.props,
                            false
                          )
                        )
                      : col.type === "checkbox"
                      ? _c(
                          "el-checkbox",
                          _vm._b(
                            {
                              class: { full: col.full },
                              attrs: {
                                border: "",
                                size: "mini",
                                value: _vm.getValue(col),
                                disabled: col.disabled
                              },
                              on: {
                                input: function($event) {
                                  return _vm.setValue($event, col)
                                }
                              }
                            },
                            "el-checkbox",
                            _vm.checkboxAttributes(col),
                            false
                          ),
                          [_vm._v(" " + _vm._s(col.label) + " ")]
                        )
                      : col.type === "table"
                      ? _c("form-table", {
                          attrs: { cols: col.cols, data: col.data }
                        })
                      : col.type === "wysiwyg"
                      ? _c("vue-editor", {
                          attrs: {
                            value: _vm.getValue(col),
                            editorOptions: col.editorOptions
                          },
                          on: {
                            input: function($event) {
                              return _vm.setValue($event, col)
                            },
                            "selection-change": col.onSelectionChange,
                            focus: col.onFocus
                          }
                        })
                      : _c(
                          "input-with-label",
                          _vm._b(
                            {
                              ref: "inputs",
                              refInFor: true,
                              attrs: {
                                value: _vm.getValue(col),
                                disabled:
                                  col.disabled && col.disabled(_vm.model),
                                size: _vm.size,
                                error: _vm.getError(col),
                                valid: _vm.getValid(col),
                                classes: col.classes
                                  ? col.classes(_vm.model)
                                  : "",
                                readOnly:
                                  col.readOnly && col.readOnly(_vm.model),
                                lazy: ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.setValue($event, col)
                                },
                                change: function($event) {
                                  return _vm.setValue($event, col)
                                },
                                focus: _vm.onFocus,
                                blur: function($event) {
                                  return _vm.onBlur($event, col)
                                }
                              }
                            },
                            "input-with-label",
                            col,
                            false
                          ),
                          [
                            col.icon
                              ? _c("v-icon", {
                                  staticClass: "icon",
                                  attrs: {
                                    slot: "prefix",
                                    name: col.icon,
                                    size: "md"
                                  },
                                  slot: "prefix"
                                })
                              : _vm._e()
                          ],
                          1
                        )
                  ],
                  1
                )
              })
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }