<template>
  <el-dialog
    append-to-body
    top="120px"
    width="900px"
    custom-class="edit-warehouse-entity-dialog"
    :visible="visible"
    :before-close="handleClose"
  >
    <span slot="title" class="t-text-gray-700 t-text-lg">
      <i class="el-icon-document" />
      <span class="t-ml-3">
        {{ id ? 'Edit' : 'Create' }} Purchase Order
        <span v-if="po">#{{ po.internalId }}</span>
      </span>
    </span>

    <LoadingSpinner v-if="poFetching" />
    <div v-else class="t-pt-6">
      <el-form
        :model="form"
        ref="form"
        size="small"
        label-width="200px"
        hide-required-asterisk
      >
        <el-form-item
          prop="supplierId"
          :show-message="false"
          :rules="[{ required: true }]"
        >
          <span slot="label" class="undo-label-styles">Supplier</span>
          <el-select
            v-model="form.supplierId"
            filterable
            placeholder="Select Supplier"
            popper-class="select-dropdown-with-add"
            class="t-w-72 select-wrapper"
          >
            <el-option
              v-for="i in suppliers"
              :key="i.id"
              :label="i.name"
              :value="i.id"
              :style="i.color ? 'color: ' + i.color : ''"
            />
            <div class="add-new-item">
              <el-button type="text" icon="el-icon-plus" @click="onCreateVendor">
                Add New Supplier
              </el-button>
            </div>
          </el-select>
          <el-button
            class="t-ml-4 min-content"
            effect="plain"
            @click="supplierSearchDialogOpen = true"
          >
            <i class="el-icon-search" /> National Supplier List
          </el-button>
        </el-form-item>

        <el-form-item prop="deliveryAddressId">
          <span slot="label" class="undo-label-styles">Delivery address</span>
          <el-select
            v-model="form.deliveryAddressId"
            filterable
            placeholder="Select Delivery Address"
            class="t-w-72 select-wrapper"
            popper-class="select-dropdown-with-add"
          >
            <el-option label="Will Call" :value="null" />
            <el-option
              v-for="i in addresses"
              :key="i.id"
              :label="i.name"
              :value="i.id"
            />
            <div class="add-new-item">
              <el-button
                type="text"
                icon="el-icon-plus"
                @click="deliveryAddressesOpen = true"
              >
                Add New Address
              </el-button>
            </div>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="date"
          :show-message="false"
          :rules="[{ required: true }]"
        >
          <span slot="label" class="undo-label-styles">Date</span>
          <el-date-picker
            v-model="form.date"
            type="date"
            format="MM/dd/yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Pick a date"
            class="t-w-72"
          />
        </el-form-item>

        <el-form-item prop="deliveryDate">
          <span slot="label" class="undo-label-styles">Est. delivery date</span>
          <el-date-picker
            v-model="form.deliveryDate"
            type="date"
            format="MM/dd/yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Pick a date"
            class="t-w-72"
          />
        </el-form-item>

        <el-form-item prop="ref">
          <span slot="label" class="undo-label-styles">Ref</span>
          <el-input
            v-model="form.ref"
            placeholder="Enter a ref"
            class="t-w-72"
          />
        </el-form-item>

        <el-form-item prop="isConfirmed">
          <span slot="label" class="undo-label-styles">Confirmed ?</span>
          <el-select
            v-model="form.isConfirmed"
            filterable
            class="t-w-72"
          >
            <el-option label="Open" :value="false" />
            <el-option label="Confirmed" :value="true" />
          </el-select>
        </el-form-item>

        <div
          v-for="(line, index) of form.lines"
          :key="index"
          class="t-mx-6"
        >
          <div
            class="t-flex t-border t-border-solid t-border-gray-100 t-rounded-md t-px-4
              t-py-2 t-shadow t-bg-blue-50 t-space-x-3 t-mb-3"
          >
            <div class="t-flex t-flex-col t-w-6">
              <div class="t-h-5" />
              <div class="t-flex t-flex-grow t-items-center">
                <el-tooltip
                  v-if="line.reservedCount > 0"
                  effect="dark"
                  content="Some of the parts are used by jobs"
                  placement="top"
                >
                  <i class="el-icon-delete t-text-gray-400 t-cursor-not-allowed" />
                </el-tooltip>
                <i
                  v-else
                  class="el-icon-delete t-text-red-400 t-cursor-pointer t-transform
                    t-duration-200 hover:t-scale-110"
                  @click="form.lines.splice(index, 1)"
                />
              </div>
            </div>

            <div class="t-flex t-flex-grow">
              <div class="t-w-3/12 t-pr-4">
                <div class="t-text-gray-500 t-text-xs t-h-5">Item</div>
                <el-form-item
                  label-width="0"
                  class="t-mb-0"
                  :prop="'lines.' + index + '.itemId'"
                  :show-message="false"
                >
                  <div
                    v-if="form.lines[index].itemId && form.lines[index].itemName"
                  >
                    {{ form.lines[index].itemName }}
                  </div>
                </el-form-item>
              </div>

              <div class="t-w-5/12 t-pr-4">
                <div class="t-text-gray-500 t-text-xs t-h-5">Memo</div>
                <el-form-item
                  label-width="0"
                  class="t-mb-0"
                  :prop="'lines.' + index + '.notes'"
                >
                  <el-input
                    v-model="form.lines[index].notes"
                    class="t-w-full"
                    placeholder="Notes"
                  />
                </el-form-item>
              </div>

              <div class="t-w-1/6 t-pr-4">
                <div class="t-text-gray-500 t-text-xs t-h-5">Quantity</div>
                <el-form-item
                  label-width="0"
                  class="t-mb-0"
                  :prop="'lines.' + index + '.quantity'"
                  :show-message="false"
                  :rules="[{ required: true }]"
                >
                  <div v-if="form.lines[index].readonlyQuantity">
                    {{ form.lines[index].quantity }}
                  </div>
                  <el-input
                    v-else
                    v-model.number="form.lines[index].quantity"
                    type="number"
                    size="small"
                    class="t-w-full"
                    :min="form.lines[index].reservedCount || 1"
                  />
                </el-form-item>
              </div>

              <div class="t-w-1/6 t-pr-4">
                <div class="t-text-gray-500 t-text-xs t-h-5">Cost</div>
                <el-form-item
                  label-width="0"
                  class="t-mb-0"
                  :prop="'lines.' + index + '.cost'"
                  :show-message="false"
                  :rules="[{ required: true }]"
                >
                  <el-input-number
                    v-model="form.lines[index].cost"
                    size="small"
                    class="t-w-full"
                    :controls="false"
                  />
                </el-form-item>
              </div>

              <div class="t-w-20">
                <div class="t-text-gray-500 t-text-xs t-h-5">
                  Total
                </div>
                <el-form-item label-width="0" class="t-mb-0">
                  {{ formatPrice(form.lines[index].quantity * form.lines[index].cost) || 0 }}
                </el-form-item>
              </div>
            </div>
          </div>
        </div>

        <div class="t-flex">
          <el-form-item>
            <el-button
              plain
              size="mini"
              icon="el-icon-plus"
              @click="itemSearchDialogOpen = true"
            >
              Add item
            </el-button>
          </el-form-item>

          <div class="t-flex t-flex-grow t-items-baseline t-pr-6">
            <div class="t-text-gray-500 t-mr-2 t-text-sm t-flex-grow t-text-right">
              Grand total
            </div>
            <div class="t-text-xl t-font-medium">
              {{ total }}
            </div>
          </div>
        </div>
      </el-form>

      <div class="t-py-3 t-px-6 t-flex t-justify-between t-border-0 t-border-t t-border-solid">
        <el-button
          plain
          size="small"
          @click="handleClose"
        >
          Close
        </el-button>
        <el-button
          size="small"
          type="primary"
          :loading="submitting"
          :icon="id ? 'el-icon-edit' : 'el-icon-plus'"
          @click="handleSubmit"
        >
          {{ id ? 'Update' : 'Create' }}
        </el-button>
      </div>

      <national-supplier-lookup-dialog
        :suppliers="suppliers"
        :visible.sync="supplierSearchDialogOpen"
        :shop="shop"
        @created="onSave"
      />

      <ItemSearchDialog
        :visible.sync="itemSearchDialogOpen"
        :shop-id="shopId"
        @selected="form.lines.push($event)"
      />

      <DeliveryAddresses
        :visible.sync="deliveryAddressesOpen"
        :shop-id="shopId"
        @created="onDeliveryAddressCreated"
        @removed="onDeliveryAddressRemoved"
      />
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'pinia'
import LoadingSpinner from '@/components/Helpers/LoadingSpinner.vue'
import ItemSearchDialog from '@/components/WarehousePage/Items/ItemSearchDialog.vue'
import NationalSupplierLookupDialog from '@/components/WarehousePage/Suppliers/NationalLookup/Index.vue'
import DeliveryAddresses from '@/components/WarehousePage/DeliveryAddresses/Index.vue'
import { useVendorStore, VendorType } from '@/components/Vendors/Profile/vendor.store'

export default {
  components: {
    LoadingSpinner,
    ItemSearchDialog,
    NationalSupplierLookupDialog,
    DeliveryAddresses
  },

  props: {
    visible: Boolean,
    id: Number,
    shopId: Number,
    predefinedLines: Array
  },

  data: () => ({
    suppliers: [],
    addresses: [],
    form: {
      supplierId: null,
      deliveryAddressId: null,
      date: '',
      deliveryDate: '',
      ref: '',
      isConfirmed: false,
      lines: []
    },
    submitting: false,
    poFetching: false,
    po: null,
    supplierSearchDialogOpen: false,
    itemSearchDialogOpen: false,
    deliveryAddressesOpen: false
  }),

  computed: {
    shop() {
      return this.$store.state.shops.find(i => i.id === this.shopId)
    },
    total() {
      let total = 0
      this.form.lines.forEach(i => {
        total += i.quantity * i.cost || 0
      })
      return this.formatPrice(total)
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.form.date = moment().format('YYYY-MM-DD')
        if (this.predefinedLines?.length) {
          this.form.lines = this.predefinedLines.map(i => ({
            ...i,
            notes: '',
            readonlyQuantity: true
          }))
        }
        if (this.id) {
          this.getPurchaseOrder()
        }
        this.getSuppliers()
        this.getAddresses()
      }
    }
  },

  created() {
    this.$root.$on('vendor-created', this.onVendorCreated)
  },

  beforeDestroy() {
    this.$root.$off('vendor-created', this.onVendorCreated)
  },

  methods: {
    ...mapActions(useVendorStore, { createVendor: 'openCreate' }),

    handleClose() {
      this.form = {
        supplierId: null,
        deliveryAddressId: null,
        date: '',
        deliveryDate: '',
        ref: '',
        isConfirmed: false,
        lines: []
      }
      this.suppliers = []
      this.addresses = []
      this.items = []
      this.po = null
      this.$refs.form.clearValidate()
      this.$emit('update:visible', false)
    },

    async getSuppliers() {
      try {
        const { data } = await this.$unum.vendor()
          .get(`organization/gb_supplier/${this.shop.organization_id}/details`)
        this.suppliers = data
      } catch {
        this.$message.error('Can\'t get a list of suppliers')
      }
    },

    async getAddresses() {
      try {
        const { data } = await this.$unum.warehouse()
          .get('delivery-addresses', { params: { shopId: this.shopId } })
        this.addresses = data.data
      } catch {
        this.$message.error('Can\'t get a list of addresses')
      }
    },

    async getPurchaseOrder() {
      try {
        this.poFetching = true
        const { data } = await this.$unum.warehouse().get(`purchase-orders/${this.id}`)
        const po = data.data
        this.po = po
        this.form.supplierId = po.supplierId
        this.form.deliveryAddressId = po.deliveryAddressId
        this.form.date = po.date && moment.utc(po.date).format('YYYY-MM-DD')
        this.form.deliveryDate = po.deliveryDate && moment.utc(po.deliveryDate).format('YYYY-MM-DD')
        this.form.ref = po.ref
        this.form.isConfirmed = po.isConfirmed
        this.form.lines = po.lines.map(i => ({
          purchaseOrderLineNumber: i.purchaseOrderLineNumber,
          itemId: i.itemId,
          itemName: i.itemName,
          quantity: i.quantity,
          cost: i.cost,
          notes: i.notes,
          reservedCount: i.reservedCount
        }))
        this.poFetching = false
      } catch {
        this.$message.error('Can\'t get the purchase order')
        this.poFetching = false
      }
    },

    formatPrice(i) {
      return '$' + Math.round(i * 100) / 100
    },

    async handleSubmit() {
      if (this.form.lines.length === 0) {
        this.$message.warning('At least one item line is required')
        return
      }
      try {
        this.submitting = true
        await this.$refs.form.validate()
        if (this.id) {
          await this.$unum.warehouse().patch(`purchase-orders/${this.id}`, this.form)
          this.$message.success('Purchase order updated')
          await this.updateCost(true)
          this.$emit('updated')
        } else {
          const { data } = await this.$unum.warehouse().post('purchase-orders', {
            shopId: this.shopId,
            ...this.form
          })
          this.$message.success('Purchase order was created')
          await this.updateCost(false)
          this.$emit('created', data.data.id)
        }
        this.handleClose()
      } catch (e) {
        if (e.response?.data) {
          this.$message.error(e.response.data)
        }
      } finally {
        this.submitting = false
      }
    },

    async updateCost(isUpdate = false) {
      // create job part array
      try {
        const jobIds = []
        if (isUpdate) {
          this.po.lines.forEach(line => {
            if (line.jobs?.length) {
              line.jobs.forEach(job => {
                if (!jobIds.includes(job.id)) {
                  jobIds.push(job.id)
                }
              })
            }
          })
        } else {
          this.form.lines.forEach(line => {
            if (line.jobId && !jobIds.includes(line.jobId)) {
              jobIds.push(line.jobId)
            }
          })
        }
        if (jobIds.length) {
          await this.$unum.job().post('data/cost/recalculate', {
            jobIDs: jobIds
          })
        }
      } catch {
        this.$message.error('There was an issue updating the cost on the job/s, please navigate to them to ensure job cost is updated correctly')
      }
    },

    onSave(val) {
      this.supplierSearchDialogOpen = false
      this.form.supplierId = val.id
      this.suppliers.push(val)
      this.suppliers.sort((a, b) => a.name.localeCompare(b.name))
    },

    onCreateVendor() {
      this.createVendor(this.shop.organization_id, VendorType.SUPPLIER)
    },

    onVendorCreated(id) {
      this.form.supplierId = id
      this.getSuppliers()
    },

    selectItem(lineIndex, val) {
      if (val === 'nags') {
        this.nagsSearch = {
          visible: true,
          search: this.search,
          lineIndex
        }
      } else {
        this.form.lines[lineIndex].itemId = val
      }
    },

    onDeliveryAddressCreated(id) {
      this.form.deliveryAddressId = id
      this.getAddresses()
    },

    onDeliveryAddressRemoved(id) {
      if (this.form.deliveryAddressId === id) {
        this.form.deliveryAddressId = null
      }
      this.getAddresses()
    }
  }
}
</script>

<style lang="scss">
.consumer-add-button {
  padding: $padding-xxs $padding-md 0;
  border-top: 1px solid #dcdfe6;
}

.select-dropdown-with-add {
  ul {
    padding-bottom: 42px;
  }
}

.add-new-item {
  display: flex;
  border-top: 1px solid #eee;
  right: 0;
  font-size: $font-sm;
  padding: 2px 20px;
  background: #fff;

  line-height: 18px;
  color: $primary;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;

  &.no-data {
    margin-top: 6px;
  }

  &:hover {
    color: darken($primary, 20%);
  }

  i {
    margin-right: $padding-xxs;
  }

  &.second {
    bottom: 37px;
  }
}
</style>
