// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".consumer-action-buttons {\n  top: 0px;\n}\n.consumer-action-buttons .primary {\n  line-height: 0px;\n  font-size: smaller;\n  color: #409EFF;\n}\n.consumer-action-buttons .secondary {\n  line-height: 0px;\n  font-size: smaller;\n  color: purple;\n}", "",{"version":3,"sources":["/app/src/components/Helpers/PrimaryFieldActionButtons.vue"],"names":[],"mappings":"AAuDA;EACE,QAAA;AA3CF;AA4CE;EACE,gBAAA;EACA,kBAAA;EACA,cAAA;AA1CJ;AA4CE;EACE,gBAAA;EACA,kBAAA;EACA,aAAA;AA1CJ","file":"PrimaryFieldActionButtons.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.consumer-action-buttons {\n  top: 0px;\n}\n.consumer-action-buttons .primary {\n  line-height: 0px;\n  font-size: smaller;\n  color: #409EFF;\n}\n.consumer-action-buttons .secondary {\n  line-height: 0px;\n  font-size: smaller;\n  color: purple;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
