var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-font-sans" },
    [
      _c("Filters", { staticClass: "t-mt-4" }),
      _c("Table", { staticClass: "t-mt-6" }),
      _c("Pagination", { staticClass: "t-mt-6 t-mb-2" }),
      _vm.$store.state.leads.filters.shopId === 4
        ? _c("LeadsGlassbiller", { staticClass: "t-mt-6 t-mb-2" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }