<template>
  <div class="job-details-commission">
    <el-input v-model="number" :disabled='disabled' class="job-details-commission-input">
      <div v-if="showDollarValue" slot="prefix" class="comm-suffix">
        ${{ dollarValue }}
      </div>

      <template slot="append">
        <el-select v-model="type">
          <el-option label="$" value="flat" />
          <el-option label="% Total" value="percent" />
          <el-option label="% Subtotal" value="percent_subtotal" />
          <el-option label="% Margin" value="percent_margin" />
        </el-select>
      </template>
    </el-input>
    <div class="comm-save" v-if="unsaved" @click="saveValues">
      <v-icon name="save" />
    </div>
  </div>
</template>

<script>
import actions from '@/store/modules/scheduler_v3/types'
import { call } from 'vuex-pathify'
import { round } from '@/scripts/helpers/totals.helpers'

export default {
  data() {
    return {
      number: null,
      type: null
    }
  },
  props: ['input', 'disabled'],
  computed: {
    showDollarValue() {
      return ['percent', 'percent_subtotal', 'percent_margin'].includes(this.type)
    },
    isPercent() {
      return this.type === 'percent'
    },
    dollarValue() {
      if (this.type === 'percent_subtotal') {
        return Math.ceil(this.input.subtotal * this.number || 0) / 100
      }
      if (this.type === 'percent_margin') {
        const { totalPartsCost, otherCosts, rebate, subtotal } = this.input
        const costsForMargin = round(totalPartsCost + +(otherCosts || 0)) + +(rebate || 0)
        const margin = subtotal - costsForMargin
        return Math.ceil(margin * this.number || 0) / 100
      }
      // default percent
      return Math.ceil(this.input.total * this.number || 0) / 100
    },
    unsaved() {
      return this.input.type !== this.type || this.input.value !== this.number
    }
  },
  methods: {
    updateJobDetailsValues: call(actions.updateJobDetailsValues),
    setValues() {
      this.number = this.input.value
      this.type = this.input.type
    },
    saveValues() {
      this.$emit('update-tech-commission', {
        commission: this.number,
        commission_flat_or_percent: this.type
      })
    }
  },
  watch: {
    input(input, old) {
      if (input.value !== old.value) this.setValues()
    }
  },
  mounted() {
    this.setValues()
  }
}
</script>

<style lang="scss" scoped>
.job-details-commission {
  display: flex;
  align-items: center;
  .job-details-commission-input {
    width: 100px;
  }
  .comm-suffix {
    line-height: 30px;
  }
  .comm-save {
    color: $scheduler-icon-color;
    cursor: pointer;
    margin-left: $padding-md;
  }
}
</style>

<style lang="scss">
.job-details-commission {
  .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 8px;
  }
  .el-input__inner {
    min-width: 100px;
    padding: 0 $padding-xs;
    text-align: right;
  }
}
</style>
