import Vue from 'vue'
import Api from '@/services/Api'

const state = {
  vendors: [
    { name: 'mygrant', description: 'Mygrant' },
    { name: 'pgw', description: 'PGW' },
    { name: 'igc', description: 'IGC' },
    { name: 'pilkington', description: 'Pilkington' },
    { name: 'american', description: 'American' }
  ],
  searchResults: {
    pilkington: {},
    mygrant: {},
    pgw: {},
    igc: {},
    american: {}
  },
  warehouses: {
    mygrant: [],
    pilkington: []
  },
  shopAccountWarehouses: {
    mygrant: [],
    pilkington: []
  },
  glaxisWarehouses: [],
  pilkingtonWarehouses: [],
  credentials: []
}

const mutations = {
  addSearchResultsPart (state, { vendor, partNumber }) {
    Vue.set(state.searchResults[vendor], partNumber, {})
  },

  setSearchResultsData (state, { vendor, partNumber, data }) {
    Vue.set(state.searchResults[vendor][partNumber], 'data', data)
  },

  setSearchResultsLoading (state, { vendor, partNumber, loading }) {
    Vue.set(state.searchResults[vendor][partNumber], 'loading', loading)
  },

  setSearchResultsVisible (state, { vendor, partNumber, visible }) {
    Vue.set(state.searchResults[vendor][partNumber], 'visible', visible)
  },

  setGlaxisWarehouses (state, payload) {
    state.glaxisWarehouses = payload
  },

  setVendorWarehouses (state, { data, vendor }) {
    state.warehouses[vendor] = data
  },

  setShopAccountWarehouses (state, { data, vendor }) {
    state.shopAccountWarehouses[vendor] = data
  },

  setPilkingtonWarehouses (state, payload) {
    state.pilkingtonWarehouses = payload
  },

  setCredentials (state, payload) {
    state.credentials = payload
  }
}

const actions = {
  presetData ({ commit }, { vendor, partNumber }) {
    commit('addSearchResultsPart', { vendor, partNumber })
    commit('setSearchResultsData', { vendor, partNumber, data: [] })
    commit('setSearchResultsLoading', { vendor, partNumber, loading: false })
    commit('setSearchResultsVisible', { vendor, partNumber, visible: false })
  },

  async checkPilkington ({ commit }, { shopId, partNumber, warehouseId }) {
    commit('setSearchResultsLoading', {
      vendor: 'pilkington',
      partNumber,
      loading: true
    })
    commit('setSearchResultsData', {
      vendor: 'pilkington',
      partNumber: partNumber,
      data: []
    })
    const { data } = await Api.post('/api/pilkington/partSearch', {
      shopId,
      partNumber,
      warehouseId
    })
    commit('setSearchResultsData', {
      vendor: 'pilkington',
      partNumber: partNumber,
      data
    })
    commit('setSearchResultsLoading', {
      vendor: 'pilkington',
      partNumber,
      loading: false
    })
  },

  async checkGlaxis ({ commit }, payload) {
    commit('setSearchResultsLoading', {
      vendor: payload.vendor,
      partNumber: payload.part.full,
      loading: true
    })
    commit('setSearchResultsData', {
      vendor: payload.vendor,
      partNumber: payload.part.full,
      data: []
    })
    const { data } = await Api.post('/api/glaxis/order/partInquiry', payload)
    commit('setSearchResultsData', {
      vendor: payload.vendor,
      partNumber: payload.part.full,
      data
    })
    commit('setSearchResultsLoading', {
      vendor: payload.vendor,
      partNumber: payload.part.full,
      loading: false
    })
  },

  async checkAmerican ({ commit }, payload) {
    commit('setSearchResultsLoading', {
      vendor: 'american',
      partNumber: payload.fullPartNumber,
      loading: true
    })
    commit('setSearchResultsData', {
      vendor: 'american',
      partNumber: payload.fullPartNumber,
      data: []
    })
    try {
      const { data } = await Api.post('/api/americanGlass/productsSearch', payload)
      commit('setSearchResultsData', {
        vendor: 'american',
        partNumber: payload.fullPartNumber,
        data
      })
      commit('setSearchResultsLoading', {
        vendor: 'american',
        partNumber: payload.fullPartNumber,
        loading: false
      })
    } catch (err) {
      commit('setSearchResultsData', {
        vendor: 'american',
        partNumber: payload.fullPartNumber,
        data: []
      })
      commit('setSearchResultsLoading', {
        vendor: 'american',
        partNumber: payload.fullPartNumber,
        loading: false
      })
    }
  },

  async getGlaxisWarehouses ({ commit }, shopId) {
    const { data } = await Api.post('/api/glaxis/getWarehouses', { shopId })
    commit('setGlaxisWarehouses', data)
  },

  async getVendorWarehouses ({ commit }, vendor) {
    const { data } = await Api.post('/api/allVendorWarehouses', { type: vendor })
    commit('setVendorWarehouses', { data, vendor })
  },

  async getShopAccountWarehouses ({ commit }, { vendor, vendorCredentialId }) {
    const { data } = await Api.post('/api/shopAccountWarehouses', { type: vendor, vendorCredentialId })
    commit('setShopAccountWarehouses', { data, vendor })
  },

  async getPilkingtonWarehouses ({ commit }) {
    const { data } = await Api.get('/api/vendorWarehouses', {
      params: {
        vendor: 'pilkington'
      }
    })
    commit('setPilkingtonWarehouses', data)
  },

  async getCredentials ({ commit }, shopId) {
    const { data } = await Api.get(`/api/shop/${shopId}/getVendorCredentials`, {
      shopId
    })
    commit('setCredentials', data)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
