<template>
  <el-dropdown-item :command='{id:jobId, jobtags: [...jobtags, newTag]}'>
    <span class="tag-sm" v-bind:style="{ 'color': blackOrWhite(tag.color), 'background': tag.color }">
      {{tag.text}}
    </span>
  </el-dropdown-item>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import JobTag from '@/scripts/objects/jobtag'

export default {
  props: ['tag', 'jobId', 'jobtags'],
  computed: {
    newTag () {
      return {
        created: -1,
        id: -1,
        job_id: this.jobId,
        modified: -1,
        shop_tag_id: this.tag.id
      }
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style lang='scss' scoped>
@import './RejectedInvoices.scss';
</style>