<template>
<div>
  <h5>Your Square devices <span class="badge badge-primary">Beta!</span></h5>
  <el-table
    border
    size="mini"
    empty-text="No devices found"
    :data="devices"
    v-loading="loading"
  >
    <el-table-column prop="name" label="Name" />
    <el-table-column prop="paired" label="Connected">
      <template slot-scope="scope">
        <el-tag
          v-if="scope.row.paired"
          type="success"
          size="mini"
          effect="dark"
        >
          Yes
        </el-tag>
        <el-tag
          v-else
          type="warning"
          size="mini"
          effect="dark"
        >
          Pending
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="paired_at" label="Paired At" />
    <el-table-column label="Actions" width="175">
      <template slot-scope="scope">
        <div class="t-flex">
          <el-tooltip
            content="Remove this device"
            placement="top"
            :open-delay="300"
          >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              plain
              @click="handleDelete(scope.row)"
            />
          </el-tooltip>
        </div>
      </template>
    </el-table-column>
  </el-table>
</div>
</template>

<script>
import Shop from '@/scripts/objects/shop'

export default {
  props: [
    'status',
    'shopId'
  ],
  data() {
    return {
      devices: [],
      loading: false
    }
  },
  methods: {
    async fetchDevices () {
      this.loading = true
      const { data } = await Shop.getConnectedSquareDevices(this.shopId)
      this.devices = data.devices
      this.loading = false
    },
    async handleDelete(device) {
      try {
        await this.$messageBox.confirm(
          'Remove this device from GlassBiller? The device will not be removed from your Square account.',
          {
            type: 'warning',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            beforeClose: async (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonText = 'Loading...'
                instance.confirmButtonLoading = true
                try {
                  const { data } = await Shop.forgetSquareDevice(this.shopId, device.id)
                  instance.confirmButtonLoading = false
                  if (data.status === 'success') {
                    this.$message.success('Device removed')
                    this.fetchDevices()
                    this.$root.$emit('refetchSquareDeviceOptions')
                  } else {
                    this.$message.error(data.error)
                  }
                  done()
                } catch {
                  instance.confirmButtonText = 'Yes'
                  instance.confirmButtonLoading = false
                }
              } else {
                done()
              }
            }
          }
        )
      } catch {}
    }
  },
  mounted () {
    this.fetchDevices()
    this.$root.$on('refetchSquareDevices', this.fetchDevices)
  },
  beforeDestroy () {
    this.$root.$off('refetchSquareDevices', this.fetchDevices)
  }
}
</script>
