var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "23px",
        height: "23px",
        viewBox: "0 0 23 23",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              x: "-44.1%",
              y: "-44.1%",
              width: "188.2%",
              height: "188.2%",
              filterUnits: "objectBoundingBox",
              id: "filter-1"
            }
          },
          [
            _c("feOffset", {
              attrs: {
                dx: "1",
                dy: "1",
                in: "SourceAlpha",
                result: "shadowOffsetOuter1"
              }
            }),
            _c("feGaussianBlur", {
              attrs: {
                stdDeviation: "1",
                in: "shadowOffsetOuter1",
                result: "shadowBlurOuter1"
              }
            }),
            _c("feColorMatrix", {
              attrs: {
                values:
                  "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.562581949 0",
                type: "matrix",
                in: "shadowBlurOuter1",
                result: "shadowMatrixOuter1"
              }
            }),
            _c(
              "feMerge",
              [
                _c("feMergeNode", { attrs: { in: "shadowMatrixOuter1" } }),
                _c("feMergeNode", { attrs: { in: "SourceGraphic" } })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Group-2",
                transform: "translate(-63.000000, 0.000000)"
              }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "stop-solid",
                    filter: "url(#filter-1)",
                    transform: "translate(65.000000, 2.000000)"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M15.1785714,0 L1.82142857,0 C0.815848214,0 0,0.815848214 0,1.82142857 L0,15.1785714 C0,16.1841518 0.815848214,17 1.82142857,17 L15.1785714,17 C16.1841518,17 17,16.1841518 17,15.1785714 L17,1.82142857 C17,0.815848214 16.1841518,0 15.1785714,0 Z",
                      id: "Path",
                      stroke: "#FFF",
                      fill: _vm.fillColor,
                      "fill-rule": "nonzero",
                      "stroke-linecap": "round"
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }