var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        "append-to-body": "",
        title: "Select a template",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "140px", size: "medium" } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "span",
                {
                  staticClass: "undo-label-styles",
                  attrs: { slot: "label" },
                  slot: "label"
                },
                [_vm._v("Type")]
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "Select a template" },
                  model: {
                    value: _vm.template_id,
                    callback: function($$v) {
                      _vm.template_id = $$v
                    },
                    expression: "template_id"
                  }
                },
                _vm._l(_vm.templates, function(template) {
                  return _c("el-option", {
                    key: template.id,
                    attrs: {
                      label:
                        "" +
                        (template.shop_name
                          ? "(" + template.shop_name + ") "
                          : "") +
                        template.name,
                      value: template.id
                    }
                  })
                }),
                1
              ),
              _vm._v(" or "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.manageTemplates }
                },
                [_vm._v("Manage templates")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "span",
                {
                  staticClass: "undo-label-styles",
                  attrs: { slot: "label" },
                  slot: "label"
                },
                [_vm._v("Message preview")]
              ),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 3, maxRows: 10 },
                  placeholder: "Message preview"
                },
                model: {
                  value: _vm.template_body,
                  callback: function($$v) {
                    _vm.template_body = $$v
                  },
                  expression: "template_body"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    plain: "",
                    disabled: this.template_body === "",
                    type: "primary"
                  },
                  on: { click: _vm.handleUse }
                },
                [_vm._v(" Use ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "pull-right",
                  attrs: { plain: "" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }