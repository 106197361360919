// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".consumer-info .title-box .editable-data-row .data-row {\n  margin-bottom: 0;\n}\n.consumer-info .title-box .editable-data-row.editing {\n  margin-bottom: 0;\n}\n.consumer-info .title-box .editable-data-row .data-row-value {\n  color: #000;\n  font-size: 20px;\n  line-height: 24px;\n  letter-spacing: 0.15px;\n  font-weight: bold;\n  margin: 7px 0;\n}", "",{"version":3,"sources":["/app/src/components/modals/ConsumerDialog/ConsumerInfo.vue"],"names":[],"mappings":"AA4IM;EACE,gBAAA;AAhIR;AAkIM;EACE,gBAAA;AAhIR;AAkIM;EACE,WAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,iBAAA;EACA,aAAA;AAhIR","file":"ConsumerInfo.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.consumer-info .title-box .editable-data-row .data-row {\n  margin-bottom: 0;\n}\n.consumer-info .title-box .editable-data-row.editing {\n  margin-bottom: 0;\n}\n.consumer-info .title-box .editable-data-row .data-row-value {\n  color: #000;\n  font-size: 20px;\n  line-height: 24px;\n  letter-spacing: 0.15px;\n  font-weight: bold;\n  margin: 7px 0;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
