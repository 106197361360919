<template>
  <div
    class="consumer-search-bar"
    @keydown.down.prevent="nextOption"
    @keydown.up.prevent="prevOption"
    @keydown.enter.prevent="selectOption(highlight)"
    v-click-outside="onBlur"
    @keyup="newKeypress"
    ref="consumerSearchBar"
  >
    <component
      :is="wrapper"
      placement="bottom-start"
      trigger="manual"
      v-model="searching"
      popper-class="consumer-search-dropdown"
      :focused="focused"
      @close-blur="closeBlur"
    >
      <input-with-label
        slot="reference"
        v-model="text"
        :label="commercial ? 'Search Commercial Accounts' : label"
        size="normal"
        @focus="onFocus"
        :disabled="!organization_id && !organization_ids"
        ref="inputRef"
      >
        <div slot="suffix" class="search-loading" v-if="loading">
          <el-spinner :stroke-width="2" :radius="50" />
        </div>
        <div
          slot="suffix"
          class="search-clear el-input__icon"
          v-else-if="selectedId && $listeners.clear"
        >
          <el-button
            type="text"
            size="small"
            class="gb-action-button"
            @click="$emit('clear')"
          >
            <i class="el-icon-circle-close" />
          </el-button>
        </div>
        <el-button
          slot="append"
          class="commercial-button"
          @click="setCommercial"
          v-if="!searching && withCommercial"
        >
          Commercial <i class="material-icons-outline md-arrow_drop_down" />
        </el-button>
      </input-with-label>
      <div class="consumer-search-container" ref="scroll" @scroll="onScroll">
        <el-scrollbar
          wrap-class="customer-search-list-container"
          view-class="customer-search-list"
          tag="ul"
          ref="scrollbar"
        >
          <div @click.self.prevent='toggleDisabled' id='show-disabled-toggle' ref='showDisabledToggle'>{{showDisabled ? 'Hide' : 'Show'}} Disabled <i v-if='showDisabled' style='color: red;' class='el-icon-close'/> </div>
          <consumer-search-result
            v-for="(item, idx) in options"
            :key="item.id"
            v-bind="item"
            :active="idx === highlight"
            @mouseenter.native="highlight = idx"
            @mouseleave.native="highlight = -1"
            @click.native="selectOption(idx)"
            :loading="loading"
            :selected="selectedId === item.id"
            :label="
              item.label ||
              (!term &&
                extraOptions.length &&
                idx === extraOptions.length &&
                'Search')
            "
          />
          <div
            class="loading-more-consumers"
            v-observe-visibility="{ callback: onScroll }"
            v-if="remoteOptions.length < count"
          >
            Loading more...
          </div>
        </el-scrollbar>
      </div>
      <div v-if="!preventNew" class="consumer-add-new" @click="newClick">
        <i class="material-icons-outline md-add md-18" />
        {{ commercial ? "New Commercial Account" : newLabel }}
        <div class="consumer-add-new-key-legend">
          <span>ALT</span> + <span>N</span>
        </div>
        <div class="consumer-count" v-if="count && remoteOptions">
          {{ options.length }}/{{ count }}
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import ConsumerSearchResult from './ConsumerSearchResult'
import ConsumerSearchBarMobile from './ConsumerSearchBarMobile'
import ClickOutside from 'vue-click-outside'
import scrollIntoView from 'element-ui/src/utils/scroll-into-view'
import * as R from 'ramda'
import { jobPageMedia } from '@/scripts/helpers/media.helpers'
import { Popover } from 'element-ui'

export default {
  directives: { ClickOutside },
  components: {
    ConsumerSearchResult
  },
  props: {
    organization_id: {
      type: Number
    },
    shop_id: {
      type: Number
    },
    organization_ids: {
      type: Array
    },
    shop_ids: {
      type: Array
    },
    label: {
      type: String,
      default: () => 'Search Customers'
    },
    newLabel: {
      type: String,
      default: () => 'New Customer'
    },
    type: {
      type: String,
      default: () => 'no_insurance'
    },
    withCommercial: {
      type: Boolean
    },
    selectedValue: {
      type: String
    },
    autoFocus: {
      type: Boolean
    },
    extraOptions: {
      type: Array,
      default: () => []
    },
    selectedId: {
      type: Number
    },
    preventNew: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    disableMobilePopup: {
      type: Boolean
    }
  },
  data() {
    return {
      term: '',
      searching: false,
      focused: false,
      loading: false,
      remoteOptions: [],
      count: 0,
      highlight: -1,
      commercial: false,
      showDisabled: false,
      skipClose: false
    }
  },
  computed: {
    wrapper() {
      return (jobPageMedia.sm || this.disableMobilePopup) ? Popover : ConsumerSearchBarMobile
    },
    options() {
      return this.term
        ? R.filter((val) => this.showDisabled || val.status !== 'disabled' || this.selectedId === val.id)(this.remoteOptions)
        : R.pipe(
          R.concat(this.extraOptions),
          R.uniqBy(R.prop('id')),
          R.filter((val) => {
            return this.showDisabled || val.status !== 'disabled' || this.selectedId === val.id
          })
        )(this.remoteOptions)
    },
    text: {
      get() {
        return this.searching ? this.term : this.placeholder
      },
      set(v) {
        this.term = v
      }
    }
  },
  methods: {
    onFocus() {
      this.searching = true
      this.focused = true
      this.search(this.term)
    },
    async onBlur() {
      if (this.skipClose) return
      this.searching = false
      this.noMore = false
      await this.$nextTick()
      this.commercial = false
      this.$emit('on-close')
    },
    async getResults(term, offset = 0) {
      const { data } = await this.$unum.consumer().get('/search/q/', {
        params: {
          term,
          type: this.commercial ? 'commercial' : this.type,
          ...(this.organization_id ? { organization_id: this.organization_id } : {}),
          ...(this.shop_id ? { shop_id: this.shop_id } : {}),
          ...(this.organization_ids ? { organization_ids: this.organization_ids } : {}),
          ...(this.shop_ids ? { shop_ids: this.shop_ids } : {}),
          offset
        }
      })
      return data
    },
    search: debounce(async function (term) {
      this.loading = true
      const { rows, count } = await this.getResults(term)
      this.remoteOptions = rows
      this.count = count
      if (this.count) {
        this.highlight = 0
      }
      this.loading = false
    }, 300),
    nextOption() {
      if (this.highlight < this.options.length) {
        this.highlight = this.highlight + 1
        this.scrollToOption()
      }
    },
    prevOption() {
      if (this.highlight >= 0) {
        this.highlight = this.highlight - 1
        this.scrollToOption()
      }
    },
    scrollToOption(option) {
      const { scroll, scrollbar } = this.$refs
      const target = scroll.querySelectorAll('li')[this.highlight]
      const menu = scroll.querySelector('.customer-search-list-container')
      menu && target && scrollIntoView(menu, target)
      scrollbar && scrollbar.handleScroll()
    },
    selectOption(idx) {
      const consumer = this.options[idx]
      this.showPopupMessage(consumer)
      this.$emit('select', consumer)
      this.onBlur()
    },
    setFocus() {
      this.$refs.inputRef.$refs.inputRef.focus()
    },
    setCommercial() {
      this.commercial = true
      var self = this
      setTimeout(()=> {
        self.setFocus()
      }, 200)
    },
    showPopupMessage({ auto_popup, auto_popup_message, full_name }) {
      if (auto_popup) {
        this.$notify({
          title: full_name,
          message: auto_popup_message,
          duration: 0,
          type: 'info',
          iconClass: 'el-icon-user'
        })
      }
    },
    onScroll: debounce(async function (v) {
      if (v && this.searching && this.count > this.remoteOptions.length) {
        const { rows, count } = await this.getResults(
          this.term,
          this.remoteOptions.length
        )
        this.count = count
        this.remoteOptions = R.uniq([...this.remoteOptions, ...rows])
      }
    }, 300),
    newClick() {
      this.$emit('new-click', { is_commercial: +this.commercial })
    },
    newKeypress(e) {
      if (e.altKey && e.keyCode === 78) {
        e.preventDefault()
        this.newClick()
      }
    },
    closeBlur(e) {
      console.log('closeblur', e)
      this.focused = false
    },
    async toggleDisabled(e) {
      this.showDisabled = !this.showDisabled
    }
  },
  mounted() {
    if (this.autoFocus) this.setFocus()
    this.skipClose = true
    var self = this
    setTimeout(()=> {
      self.skipClose = false
    }, 200)
  },
  watch: {
    term(v) {
      this.search(v)
    }
  }
}
</script>

<style lang="scss" scoped>
.gb-action-button {
  color: lightgray;
}
</style>

<style lang="scss">
.consumer-search-bar {
  .search-loading {
    .el-spinner-inner .path {
      stroke: $primary;
    }
    height: 100%;
    padding: 6px 1px;
  }
  .search-clear {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 $padding-xxs;
  }
  .commercial-button {
    padding: 0 0 0 $padding-xs;
    span {
      display: flex;
      align-items: center;
    }
  }
  .el-input-group__append,
  .el-input-group__prepend {
    background: #fff;
  }
  .search-dropdown-icon {
    height: 100%;
    display: flex;
    align-items: center;
    color: rgb(144, 147, 153);
  }
}
.consumer-search-dropdown {
  padding: 0;
  overflow: hidden;
  .customer-search-list-container {
    position: relative;
    .customer-search-list {
      padding: 0;
      margin: 0;
    }
    .loading-more-consumers {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $padding-xxs;
      margin-bottom: $padding-xxs;
      @include gb-label;
    }
    max-height: 438px;
    min-width: 400px;
  }
  .consumer-add-new {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background: #f5f7fa;
    }
    padding: 13px;
    border-top: 1px solid #dcdfe6;
    color: $primary;
    i {
      margin-right: $padding-xs;
    }
    position: relative;
    .consumer-count {
      position: absolute;
      right: 0;
      top: 0;
      @include gb-label;
    }
    .consumer-add-new-key-legend {
      display: flex;
      align-items: center;
      margin-left: auto;
      span {
        font-size: $font-xs;
        line-height: $font-xs;
        padding: 2px 4px;
        margin: 0 4px;
        letter-spacing: 0.5px;
        border: 1px solid currentColor;
        border-radius: 2px;
      }
    }
  }
  #show-disabled-toggle {
    cursor: pointer;
    text-align: right;
    line-height: 8px;
    font-size: x-small;
    font-style: italic;
    padding: 5px 15px 5px 0;
  }
}
</style>
