import { schedulerMutations as mutations } from './scheduler.types'
import * as R from 'ramda'
import { updateOrAppendValueInList, removeValueFromListById } from '@/scripts/helpers/ramda.helpers'

const schedulerMutations = {
  [mutations.openSchedulerDialog] (state) {
    state.isOpen = true
    document.querySelector('body').classList.add('open-modal')
  },
  [mutations.closeSchedulerDialog] (state) {
    state.isOpen = false
    document.querySelector('body').classList.remove('open-modal')
  },
  [mutations.setStateValue] (state, { key, value }) {
    const [topKey, ...path] = key.split('.')
    if (path.length) {
      // deep path update
      const lens = R.lensPath(path)
      state[topKey] = R.set(lens, value, state[topKey])
    } else {
      state[topKey] = value
    }
  },
  [mutations.updateStateListValueById] (state, { key, id, value }) {
    const index = state[key].findIndex(item => +item.id === +(id || value.id))
    if (index >= 0) {
      state[key] = R.update(index, value, state[key])
    }
  },
  [mutations.updateJob] (state, { job, index }) {
    state.jobs = R.update(index, job)(state.jobs)
  },
  [mutations.updateWaypoint] (state, { waypoint, index }) {
    state.waypoints = R.update(index, waypoint)(state.waypoints)
  },
  [mutations.updateJobValue] (state, { id, key, value }) {
    const index = state.jobs.findIndex(R.propEq('id', `${id}`))
    const lens = R.lensPath([index, key])
    state.jobs = R.set(lens, value, state.jobs)
  },
  [mutations.updateWaypointValue] (state, { id, key, value }) {
    const index = state.waypoints.findIndex(R.propEq('id', id))
    const lens = R.lensPath([index, key])
    state.waypoints = R.set(lens, value, state.waypoints)
  },
  [mutations.setJobsToUpdate] (state, value) {
    if (value) {
      state.jobsToUpdate = R.assoc(value.id, value.updates, state.jobsToUpdate)
    } else {
      state.jobsToUpdate = {}
    }
  },
  [mutations.setTechModal] (state, value) {
    state.techModal = value
  },
  [mutations.updateGeometryValue] (state, { id, key, value }) {
    const index = state.geometry.findIndex(R.propEq('id', id))
    const lens = R.lensPath([index, key])
    state.geometry = R.set(lens, value, state.geometry)
  },
  [mutations.appendRouteWaypoint] (state, waypoint) {
    state.waypoints = R.append(waypoint)(state.waypoints)
  },
  [mutations.deleteWaypoint] (state, id) {
    state.waypoints = R.reject(R.propEq('id', id))(state.waypoints)
  },
  [mutations.setShopTechs] (state, techs) {
    state.shopTechs = techs
  },
  [mutations.updateOrAppendValue] (state, { listKey, value }) {
    state[listKey] = updateOrAppendValueInList(state[listKey], value)
  },
  [mutations.deleteValueFromList] (state, { listKey, id }) {
    state[listKey] = removeValueFromListById(state[listKey], id)
  },
  [mutations.setTechLocation] (state, { key, value }) {
    state.techLocations = R.assoc(key, value)(state.techLocations)
  },
  [mutations.resetTechLocations] (state) {
    state.techLocations = {}
  }
}

export default schedulerMutations
