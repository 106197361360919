var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: _vm.salesrep.id > 0 ? "Edit Sales Rep" : "New Sales Rep",
            id: "salesrepModal",
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg"
          },
          on: {
            show: _vm.dialogAboutToAppear,
            shown: function($event) {
              return _vm.$refs.nameComponent.focus()
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-6" }, [
              _c("label", [_vm._v("Status")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.salesrep.status,
                      expression: "salesrep.status"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.salesrep,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "active" } }, [
                    _vm._v("Active")
                  ]),
                  _c("option", { attrs: { value: "disabled" } }, [
                    _vm._v("Disabled")
                  ])
                ]
              )
            ]),
            _vm.salesrep.id > 0
              ? _c("div", { staticClass: "form-group col-6" }, [
                  _c("label"),
                  _c("p", [
                    _vm._v(" ID: "),
                    _c("span", { staticClass: "idBox" }, [
                      _vm._v(_vm._s(_vm.salesrep.id))
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-6" },
              [
                _c("RequiredInput", {
                  ref: "nameComponent",
                  attrs: {
                    title: "Name",
                    value: _vm.salesrep.name,
                    placeholder: "",
                    capitalizeAll: true
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.salesrep, "name", $event)
                    }
                  }
                })
              ],
              1
            ),
            _vm.checkCommissionPermission
              ? _c(
                  "div",
                  { staticClass: "form-group col-3" },
                  [
                    _c("label", [_vm._v("Commission")]),
                    _c("FloatDropdown", {
                      ref: "commissionComponent",
                      attrs: {
                        dropdownOptions: [
                          { value: "flat", label: "$", dropdownLabel: "Flat" },
                          { value: "percent", label: "%", dropdownLabel: "%" }
                        ],
                        inDisabled: false,
                        dropdownValue: _vm.salesrep.commission_flat_or_percent,
                        value: _vm.salesrep.commission
                      },
                      on: {
                        "update:dropdownValue": function($event) {
                          return _vm.$set(
                            _vm.salesrep,
                            "commission_flat_or_percent",
                            $event
                          )
                        },
                        "update:dropdown-value": function($event) {
                          return _vm.$set(
                            _vm.salesrep,
                            "commission_flat_or_percent",
                            $event
                          )
                        },
                        "update:value": function($event) {
                          return _vm.$set(_vm.salesrep, "commission", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.checkCommissionPermission
              ? _c(
                  "div",
                  { staticClass: "form-group col-3" },
                  [
                    _c("label", [_vm._v("Chip Commission")]),
                    _c("FloatDropdown", {
                      ref: "chipCommissionComponent",
                      attrs: {
                        dropdownOptions: [
                          { value: "flat", label: "$", dropdownLabel: "Flat" },
                          { value: "percent", label: "%", dropdownLabel: "%" }
                        ],
                        inDisabled: false,
                        dropdownValue:
                          _vm.salesrep.chip_commission_flat_or_percent,
                        value: _vm.salesrep.chip_commission
                      },
                      on: {
                        "update:dropdownValue": function($event) {
                          return _vm.$set(
                            _vm.salesrep,
                            "chip_commission_flat_or_percent",
                            $event
                          )
                        },
                        "update:dropdown-value": function($event) {
                          return _vm.$set(
                            _vm.salesrep,
                            "chip_commission_flat_or_percent",
                            $event
                          )
                        },
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.salesrep,
                            "chip_commission",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-3" },
              [
                _c("Phone", {
                  ref: "phoneComponent",
                  attrs: {
                    title: "Phone",
                    value: _vm.salesrep.phone,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.salesrep, "phone", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-md-3" },
              [
                _c("Phone", {
                  ref: "faxComponent",
                  attrs: {
                    title: "Fax",
                    value: _vm.salesrep.fax,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.salesrep, "fax", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-md-6" },
              [
                _c("Email", {
                  ref: "emailComponent",
                  attrs: {
                    title: "Email",
                    value: _vm.salesrep.email,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.salesrep, "email", $event)
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-12" }, [
              _c("label", [_vm._v("Address")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salesrep.address,
                    expression: "salesrep.address"
                  }
                ],
                staticClass: "form-control form-control-sm",
                attrs: { type: "text" },
                domProps: { value: _vm.salesrep.address },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.salesrep, "address", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-md-3" }, [
              _c("label", [_vm._v("ZIP")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salesrep.zip,
                    expression: "salesrep.zip"
                  }
                ],
                staticClass: "form-control form-control-sm",
                attrs: { type: "text" },
                domProps: { value: _vm.salesrep.zip },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.salesrep, "zip", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_vm._v("City")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salesrep.city,
                    expression: "salesrep.city"
                  }
                ],
                staticClass: "form-control form-control-sm",
                attrs: { type: "text" },
                domProps: { value: _vm.salesrep.city },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.salesrep, "city", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group col-md-3" }, [
              _c("label", [_vm._v("State")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.salesrep.state,
                      expression: "salesrep.state"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { id: "#" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.salesrep,
                        "state",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("Choose...")]),
                  _vm._l(_vm.states, function(state) {
                    return _c(
                      "option",
                      { key: state.value, domProps: { value: state.value } },
                      [_vm._v(" " + _vm._s(state.text) + " ")]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_vm._v("Saleside Id (Case Sensitive)")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salesrep.saleside_username,
                    expression: "salesrep.saleside_username"
                  }
                ],
                staticClass: "form-control form-control-sm",
                attrs: { type: "text" },
                domProps: { value: _vm.salesrep.saleside_username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.salesrep,
                      "saleside_username",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm.salesrep.saleside_invitation_status
              ? _c("div", { staticClass: "form-group col-md-3" }, [
                  _c("label", [_vm._v("Invitation Status")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.salesrep.saleside_invitation_status,
                        expression: "salesrep.saleside_invitation_status"
                      }
                    ],
                    staticClass: "form-control form-control-sm",
                    class: {
                      pendingInvitationStatus:
                        _vm.salesrep.saleside_invitation_status === "pending",
                      acceptedInvitionStatus:
                        _vm.salesrep.saleside_invitation_status === "accepted"
                    },
                    attrs: { type: "text", readonly: "" },
                    domProps: {
                      value: _vm.salesrep.saleside_invitation_status
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.salesrep,
                          "saleside_invitation_status",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              : _vm._e(),
            _c("div", { staticClass: "form-group col-md-3" }, [
              _c("label", [_vm._v("Default Location")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.salesrep.location_id,
                      expression: "salesrep.location_id"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { id: "#" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.salesrep,
                        "location_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "-1" } }, [
                    _vm._v("Choose...")
                  ]),
                  _vm._l(_vm.locations, function(location) {
                    return _c(
                      "option",
                      { key: location.id, domProps: { value: location.id } },
                      [_vm._v(" " + _vm._s(location.name) + " ")]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("br"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salesrep.hide_sent_jobs,
                    expression: "salesrep.hide_sent_jobs"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.salesrep.hide_sent_jobs)
                    ? _vm._i(_vm.salesrep.hide_sent_jobs, null) > -1
                    : _vm.salesrep.hide_sent_jobs
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.salesrep.hide_sent_jobs,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.salesrep,
                            "hide_sent_jobs",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.salesrep,
                            "hide_sent_jobs",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.salesrep, "hide_sent_jobs", $$c)
                    }
                  }
                }
              }),
              _vm._v(" Hide sent SSM Jobs ")
            ]),
            _c(
              "div",
              { staticClass: "form-group col-md-8" },
              [
                _c("MultiSelectDropdown", {
                  attrs: {
                    objects: _vm.commercialaccounts,
                    selectedValues: _vm.selectedSalesrepCommercialAccounts,
                    title: "Commercial Accounts"
                  },
                  on: {
                    "update:selectedValues": function($event) {
                      _vm.selectedSalesrepCommercialAccounts = $event
                    },
                    "update:selected-values": function($event) {
                      _vm.selectedSalesrepCommercialAccounts = $event
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-8" },
              [
                _c("MultiSelectDropdown", {
                  attrs: {
                    objects: _vm.locations,
                    selectedValues: _vm.selectedSalesrepLocations,
                    title: "Locations"
                  },
                  on: {
                    "update:selectedValues": function($event) {
                      _vm.selectedSalesrepLocations = $event
                    },
                    "update:selected-values": function($event) {
                      _vm.selectedSalesrepLocations = $event
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-8" },
              [
                _c("MultiSelectDropdown", {
                  attrs: {
                    objects: _vm.salesources,
                    selectedValues: _vm.selectedSalesrepSalesources,
                    title: "Sale Sources"
                  },
                  on: {
                    "update:selectedValues": function($event) {
                      _vm.selectedSalesrepSalesources = $event
                    },
                    "update:selected-values": function($event) {
                      _vm.selectedSalesrepSalesources = $event
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-12" }, [
              _c("label", [_vm._v("Notes")]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salesrep.notes,
                    expression: "salesrep.notes"
                  }
                ],
                staticClass: "form-control form-control-sm",
                domProps: { value: _vm.salesrep.notes },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.salesrep, "notes", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c("div", { staticClass: "float-left" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "col-4": _vm.mostRecentInviteHistory != null,
                        "col-12": _vm.mostRecentInviteHistory == null
                      }
                    },
                    [
                      !_vm.salesrep.saleside_invitation_status ||
                      _vm.salesrep.saleside_invitation_status === "pending" ||
                      _vm.mostRecentInviteHistory != null
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "button",
                                size: "lg",
                                variant: "primary"
                              },
                              on: { click: _vm.inviteSalesrep }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  !_vm.salesrep.saleside_invitation_status ||
                                    _vm.salesrep.saleside_invitation_status ===
                                      "pending"
                                    ? "Invite Sales Rep"
                                    : "Invite History"
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm.mostRecentInviteHistory != null
                    ? _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c(
                            "el-timeline",
                            [
                              _c(
                                "el-timeline-item",
                                {
                                  attrs: {
                                    icon: _vm.mostRecentInviteHistory.icon,
                                    type: _vm.mostRecentInviteHistory.type,
                                    size: _vm.mostRecentInviteHistory.size,
                                    timestamp:
                                      _vm.mostRecentInviteHistory.timestamp
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.mostRecentInviteHistory.name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    staticStyle: { "margin-right": "5px" },
                    attrs: { type: "button", size: "lg", variant: "primary" },
                    on: { click: _vm.saveSalesrep }
                  },
                  [_vm._v("Save")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", variant: "primary" },
                    on: { click: _vm.cancelEdits }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.confirmSaveChanges },
          on: {
            "update:visible": function($event) {
              _vm.confirmSaveChanges = $event
            }
          }
        },
        [
          _c("el-row", { staticClass: "dialog-header-text" }, [
            _vm._v(" Save Changes? ")
          ]),
          _c("br"),
          _c("el-row", { staticClass: "dialog-body-text" }, [
            _vm._v(
              "In order to invite this sales rep, any unsaved changes must be saved. Would you like to continue?"
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.approveSaveChangesAndInvite()
                    }
                  }
                },
                [_vm._v("Yes")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.confirmSaveChanges = false
                    }
                  }
                },
                [_vm._v("No")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }