<template>
  <div class="places-autocomplete-block">
    <div class="main">{{this.result.main_text}}</div>
    <div class="secondary">{{this.result.secondary_text}}</div>
  </div>
</template>

<script>

export default {
  props: ['result']
}
</script>

<style lang="scss" scoped>
.places-autocomplete-block {
  line-height: 14px;
  padding: 5px 0;
  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .main {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .secondary {
    font-size: 12px;
  }
}
</style>
