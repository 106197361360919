var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "data-row",
      class: {
        link: _vm.link,
        overflow: _vm.overflow,
        "no-margin": _vm.noMargin,
        bool: _vm.bool,
        locked: _vm.locked,
        condensed: _vm.condensed
      },
      on: { click: _vm.checkBool }
    },
    [
      _c("div", { staticClass: "data-row-container" }, [
        _c(
          "div",
          { style: { flexGrow: +(!_vm.label || _vm.type === "textarea") } },
          [
            _vm.label
              ? _c(
                  "div",
                  {
                    staticClass: "data-row-label",
                    class: {
                      locked: _vm.locked,
                      valid: _vm.valid,
                      invalid: _vm.invalid
                    }
                  },
                  [
                    _vm.locked
                      ? _c("i", {
                          staticClass: "material-icons md-18 md-lock lock-icon"
                        })
                      : _vm._e(),
                    _c("span", { class: { emptyRed: _vm.emptyRed } }, [
                      _vm._v(_vm._s(_vm.label) + " ")
                    ]),
                    _vm.tooltip && _vm.tooltip.length > 0
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              "popper-class": "dataRowToolTip"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "content",
                                  fn: function() {
                                    return [
                                      _vm._v(" " + _vm._s(_vm.tooltip) + " ")
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              291424268
                            )
                          },
                          [_c("i", { staticClass: "el-icon-info t-text-xs" })]
                        )
                      : _vm._e(),
                    _vm.$attrs.warning && _vm.$attrs.id === "referral_number"
                      ? _c(
                          "el-popover",
                          {
                            staticStyle: { "pointer-events": "auto" },
                            attrs: {
                              placement: "top-start",
                              title: _vm.label,
                              width: "400",
                              trigger: "hover"
                            }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("p", [
                                  _vm._v(
                                    "This referral number appears on an existing job or draft."
                                  )
                                ]),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: { click: _vm.dismissReferralMatch }
                                  },
                                  [_vm._v("Dismiss")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: { click: _vm.viewReferralMatches }
                                  },
                                  [_vm._v("View")]
                                )
                              ],
                              1
                            ),
                            _c("v-icon", {
                              staticStyle: { margin: "0px 2px" },
                              attrs: {
                                slot: "reference",
                                name: "exclamation-circle",
                                scale: "0.6",
                                color: "orange"
                              },
                              slot: "reference"
                            })
                          ],
                          1
                        )
                      : _vm.$attrs.warning
                      ? _c("v-icon", {
                          staticStyle: { margin: "0px 2px" },
                          attrs: {
                            slot: "reference",
                            name: "exclamation-circle",
                            scale: "0.6",
                            color: "orange"
                          },
                          slot: "reference"
                        })
                      : _vm._e(),
                    _vm.invalid
                      ? _c("i", {
                          staticClass:
                            "material-icons md-18 md-new_releases label-icon"
                        })
                      : _vm._e(),
                    _vm.valid
                      ? _c("i", {
                          staticClass:
                            "material-icons md-18 md-check_circle label-icon"
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "data-row-value",
                attrs: { tabindex: _vm.editable ? 0 : -1 },
                on: {
                  focus: function($event) {
                    return _vm.$emit("focus")
                  }
                }
              },
              [
                _vm.loading || _vm.creating
                  ? _c("skeleton")
                  : _vm.isArray && _vm.value.length === 0
                  ? _c("span", [_vm._v(" - ")])
                  : _vm.isArray
                  ? [
                      _c(
                        "div",
                        [
                          _vm._l(_vm.value, function(item, idx) {
                            return _c(
                              "data-row-formatted-value",
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.expanded || idx < 2,
                                      expression: "expanded || idx < 2"
                                    }
                                  ],
                                  key: item.id,
                                  attrs: {
                                    value: _vm.arrayKey
                                      ? item[_vm.arrayKey]
                                      : item
                                  }
                                },
                                "data-row-formatted-value",
                                _vm.$props,
                                false
                              )
                            )
                          }),
                          _vm.value.length > 2
                            ? _c(
                                "span",
                                {
                                  staticClass: "data-row-more-button",
                                  on: {
                                    click: function($event) {
                                      _vm.expanded = !_vm.expanded
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.expanded ? "Less" : "More") +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : _vm.type === "bool"
                  ? _c("div", { staticClass: "data-row-bool-value" }, [
                      _c("i", {
                        staticClass: "material-icons-outline md-18",
                        class: {
                          "md-check_box_outline_blank": !_vm.value,
                          "md-check_box": _vm.value
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.value ? "Yes" : "No") + " ")
                    ])
                  : _c(
                      "el-tooltip",
                      {
                        attrs: {
                          placement: "top",
                          tabindex: _vm.overflow ? 0 : -1,
                          disabled: !_vm.overflow,
                          "open-delay": 750
                        }
                      },
                      [
                        _c(
                          "data-row-formatted-value",
                          _vm._b(
                            { attrs: { slot: "content" }, slot: "content" },
                            "data-row-formatted-value",
                            _vm.$props,
                            false
                          )
                        ),
                        _c(
                          "data-row-formatted-value",
                          _vm._b(
                            { ref: "actual" },
                            "data-row-formatted-value",
                            _vm.$props,
                            false
                          )
                        )
                      ],
                      1
                    ),
                _vm._t("extra")
              ],
              2
            )
          ]
        )
      ]),
      _c("match-dialog", {
        attrs: {
          visible: _vm.matchDialogVisible,
          match: { key: "referral_number", value: _vm.value }
        },
        on: {
          "update:visible": function($event) {
            _vm.matchDialogVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }