import * as R from 'ramda'

export const availabilityNamespace = 'availability'

export const types = {
  getShopData: 'GET_SHOP_DATA',
  getOrgData: 'GET_ORG_DATA',
  createSlot: 'CREATE_SLOT',
  updateSlot: 'UPDATE_SLOT',
  deleteSlot: 'DELETE_SLOT'
}

export default R.map(R.concat(availabilityNamespace + '/'))(types)
