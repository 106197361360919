var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex" },
    [
      _c("Button", {
        attrs: {
          type: "nm",
          loading: false,
          total: "*",
          price: "new",
          adjustedPrice: null
        },
        on: {
          clicked: function($event) {
            _vm.open = true
          }
        }
      }),
      _c("nielsen-moller-alert-dialog", {
        attrs: { visible: _vm.open, shopId: _vm.shopId, syncShopId: false },
        on: {
          "update:visible": function($event) {
            _vm.open = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }