<template>
  <div>
    <b-modal
      @show="dialogAboutToAppear"
      no-close-on-backdrop no-close-on-esc
      v-bind:title="insurancefleet.id > 0 ? 'Edit Insurance Fleet' : 'New Insurance Fleet'"
      id="insurancefleetModal"
      header-bg-variant='primary'
      header-text-variant='light'
      size="lg">
      <div class="row">
        <div class="form-group col-sm-12 col-md">
          <h4>ID: <span class='idBox'>&nbsp;&nbsp;&nbsp;&nbsp;{{insurancefleet.id <= 0 ? 'New' : insurancefleet.id}}&nbsp;&nbsp;&nbsp;&nbsp;</span></h4>
        </div>
        <div class="form-group col-sm-12 col-md">
          <button type='button' style="padding: 0px 1px 0px 1px;" size='sm' class='btn btn-primary mx-3' variant="primary" @click="quickApplyDetails('sgc')">SGC</button>
          <button type='button' style="padding: 0px 1px 0px 1px;" size='sm' class='btn btn-primary mx-3' variant="primary" @click="quickApplyDetails('lynx')">Lynx</button>
        </div>
        <div class="form-group col-sm-12 col-md">
          Status:<select class="form-control form-control-sm" v-model='insurancefleet.status'>
            <option value='active'>Active</option>
            <option value='disabled'>Disabled</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md'>
          <label for='insurancefleetName'>Name</label>
          <input type='text' class="form-control form-control-sm autocomplete-off" v-model='insurancefleet.name'>
        </div>
        <div class='form-group col-sm-12 col-md'>
          <label for='insurancefleetWeb'>Website</label>
          <input id='insurancefleetWeb' v-model='insurancefleet.web' type='text' class="form-control form-control-sm autocomplete-off">
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md-3'>
          <Phone ref="phoneComponet" :title="'Phone'" :value.sync='insurancefleet.phone' :placeholder="''" />
        </div>
        <div class='form-group col-sm-12 col-md-3'>
          <Phone ref="phoneComponet" :title="'Fax'" :value.sync='insurancefleet.fax' :placeholder="''" />
        </div>
        <div class='form-group col-sm-12 col-md-6'>
          <label for='insurancefleetEmail'>Email</label>
          <input id='insurancefleetEmail' v-model='insurancefleet.email' class="form-control form-control-sm autocomplete-off">
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md-8'>
          <label for='insurancefleetAddress'>Address</label>
          <input id='insurancefleetAddress' v-model='insurancefleet.address' class="form-control form-control-sm autocomplete-off">
        </div>
        <div class='form-group col-sm-12 col-md-4'>
          <label for='insurancefleetZip'>Zip</label>
          <input id='insurancefleetZip' v-model='insurancefleet.address_zip' class="form-control form-control-sm autocomplete-off">
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md-8'>
          <label for='insurancefleetCity'>City</label>
          <input id='insurancefleetCity' v-model='insurancefleet.address_city' class="form-control form-control-sm autocomplete-off">
        </div>
        <div class='form-group col-sm-12 col-md-4'>
          <label for='insurancefleetState'>State</label>
          <select id='insurancefleetState' class="form-control form-control-sm" v-model='insurancefleet.address_state'>
            <option value=''>Choose...</option>
            <option v-for='state in states' :value='state.value' :key='state.value'>{{state.text}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class='form-group col insurance-section-header'>
          <b>CASH CUSTOMER:</b>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md-7 col-lg-4'>
          <CheckBoxLabel label="IS THIS A CASH CUSTOMER ACCOUNT?" :value.sync="cashCustomer" />
        </div>
        <div v-if="cashCustomer" class='form-group col-sm-12 col-md-5 col-lg-4'>
          <CheckBoxLabel label="Export As Customer" :value.sync="exportAsCustomer" />
        </div>
      </div>
      <div class="row">
        <div class='form-group col insurance-section-header'>
          <b>RATE INFO:</b>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Glass Discount'"
          :value.sync="insurancefleet.discount"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'% '"/>
        </div>
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Moulding Discount'"
          :value.sync="insurancefleet.moulding_discount"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'% '"/>
        </div>
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Hourly Labor'"
          :value.sync="insurancefleet.labor_hourly"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Flat Labor'"
          :value.sync="insurancefleet.labor_flat"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-md-6 col-lg-3">
          <FloatInput
          :title="'Oem Glass Discount'"
          :value.sync="insurancefleet.oem_discount"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'% '"/>
        </div>
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <label for='insurancefleetDefaultInvoiceTier'>Default Invoice Tier</label>
          <select id='insurancefleetDefaultInvoiceTier' class="form-control form-control-sm" v-model='insurancefleet.default_invoice_tier'>
            <option :value="null">Choose...</option>
            <option v-for='option in invoiceTierOptions' :value='option.value' :key='option.value'>{{option.text}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md-12 col-lg-9'>
          <div class="row">
            <div class='form-group col-sm-12 col-md-12 col-lg-4'>
              <label for='kitStandard'>Kit: Standard</label>
              <FloatDropdown
              :dropdownOptions="[{'value':'each', 'label':'Each', 'dropdownLabel':'Each'}, {'value':'flat', 'label':'Flat', 'dropdownLabel':'Flat'}]"
              :value.sync="insurancefleet.kit_standard"
              :dropdownValue.sync="insurancefleet.kit_charge_type_standard"
              :formattedPrefix="'$ '" />
            </div>
            <div class='form-group col-sm-12 col-md-12 col-lg-4'>
              <label for='kitStandard'>Kit: High Modulous</label>
              <FloatDropdown
              :dropdownOptions="[{'value':'each', 'label':'Each', 'dropdownLabel':'Each'}, {'value':'flat', 'label':'Flat', 'dropdownLabel':'Flat'}]"
              :value.sync="insurancefleet.kit_high"
              :dropdownValue.sync="insurancefleet.kit_charge_type_high"
              :formattedPrefix="'$ '" />
            </div>
            <div class='form-group col-sm-12 col-md-12 col-lg-4'>
              <label for='kitStandard'>Kit: Fast Cure</label>
              <FloatDropdown
              :dropdownOptions="[{'value':'each', 'label':'Each', 'dropdownLabel':'Each'}, {'value':'flat', 'label':'Flat', 'dropdownLabel':'Flat'}]"
              :value.sync="insurancefleet.kit_fast"
              :dropdownValue.sync="insurancefleet.kit_charge_type_fast"
              :formattedPrefix="'$ '" />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6 col-md-6">
              <FloatInput
              :title="'First Chip'"
              :value.sync="insurancefleet.chip_first"
              :minimumDecimalPlaces="2"
              :maximumDecimalPlaces="2"
              :formattedPrefix="'$ '"/>
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <FloatInput
              :title="'Additional Chips'"
              :value.sync="insurancefleet.chip_additional"
              :minimumDecimalPlaces="2"
              :maximumDecimalPlaces="2"
              :formattedPrefix="'$ '"/>
            </div>
          </div>
        </div>
        <div class='form-group col-sm-12 col-md-12 col-lg-3'>
          <div class="form-row">
            &nbsp;
          </div>
          <div class="form-row">
            <CheckBoxLabel label="Tax Exempt" :value.sync="taxExempt"/>
          </div>
          <div class="form-row">
            <CheckBoxLabel label="Auto-add Adhesive" :value.sync="autoAddAdhesive"/>
          </div>
          <div class="form-row">
            <CheckBoxLabel label="PO Required" :value.sync="poRequired"/>
          </div>
          <div class="form-row">
            <CheckBoxLabel label="Display Phone #" :value.sync="displayPhone"/>
          </div>
        </div>
      </div>
      <h5 class="mt-1" style="text-transform: uppercase; font-weight: bold; font-size: 0.9em;">Recal:</h5>
      <div class="row">
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Safelite Dynamic'"
          :value.sync="insurancefleet.recal_safelite_dynamic"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Safelite Static'"
          :value.sync="insurancefleet.recal_safelite_static"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Safelite Dual'"
          :value.sync="insurancefleet.recal_safelite_dual"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Lynx RTL Dynamic'"
          :value.sync="insurancefleet.recal_lynx_rtl_dynamic"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Lynx RTL Static'"
          :value.sync="insurancefleet.recal_lynx_rtl_static"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Lynx RTL Both'"
          :value.sync="insurancefleet.recal_lynx_rtl_both"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Lynx DLR Dynamic'"
          :value.sync="insurancefleet.recal_lynx_dlr_dynamic"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Lynx DLR Static'"
          :value.sync="insurancefleet.recal_lynx_dlr_static"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md-6 col-lg-3'>
          <FloatInput
          :title="'Lynx DLR Both'"
          :value.sync="insurancefleet.recal_lynx_dlr_both"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"/>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md-12 insurance-section-header'>
          <b>EDI INFO:</b>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-12 col-md-4'>
          <div class="row">
            &nbsp;
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-md-12">
                <CheckBoxLabel label="EDI Capable" :value.sync="ediCapable"/>
            </div>
          </div>
        </div>
        <div class='form-group col-sm-12 col-md-8' v-if='ediCapable'>
          <label for='trading-partner-input'>Trading Partner</label>
          <select id='trading-partner-input' class="form-control form-control-sm" v-model='insurancefleet.edi_trading_partner'>
            <option value=''>Choose...</option>
            <option v-for="(partner, index) in tradingPartners" :key='index' :value='partner.value' >{{partner.name}}</option>
          </select>
        </div>
      </div>
      <div class='row'>
        <div class='form-group col-sm-12' v-if='insurancefleet.edi_trading_partner == "safelite"'>
          <label for='trading-program-id'>Safelite Program ID</label>
          <select id='trading-program-id' class="form-control form-control-sm" v-model='insurancefleet.edi_safelite_program_id'>
            <option value=''>Choose...</option>
            <option v-for="partner in safelitePartners" :key='partner.id' :value='partner.program_id' >{{partner.name | capitalize-all}} ({{partner.program_id}})</option>
          </select>
        </div>
      </div>
      <div class='row'>
        <div class='form-group col-sm-12' v-if='insurancefleet.edi_trading_partner == "quest"'>
          <label for='trading-program-id'>Quest Program ID</label>
          <select id='trading-program-id' class="form-control form-control-sm" v-model='insurancefleet.edi_quest_program_id'>
            <option value=''>Choose...</option>
            <option v-for="partner in questPartners" :key='partner.id' :value='partner.program_id' >{{ partner.name | capitalize-all }} ({{partner.program_id}})</option>
          </select>
        </div>
      </div>
      <div class='row'>
        <div class='form-group col'>
          <label for="input-notes">Notes</label>
          <b-form-textarea  id='input-notes'
                            v-model='insurancefleet.notes'
                            placeholder='Enter insurance fleet notes...'
                            :rows="3"
                            :max-rows="5">
          </b-form-textarea>
        </div>
      </div>
      <div class="row">
        <div class="form-group col">
          <CheckBoxLabel label="Auto Popup Message" :value.sync="autoPopupCheck"/>
        </div>
      </div>
      <div v-if='autoPopupCheck' class='row'>
        <div class='form-group col'>
          <label for="input-popup-message">Auto Popup Message</label>
          <b-form-textarea  id='input-popup-message'
                            v-model='insurancefleet.auto_popup_message'
                            placeholder='Enter Message...'
                            :rows="3"
                            :max-rows="5">
          </b-form-textarea>
        </div>
      </div>
      <div slot="modal-footer" class='w-100'>
        <div class='float-right'>
          <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="saveInsurancefleet">Save</button>
          &nbsp;&nbsp;
          <button type='button' class='btn btn-secondary' variant="primary" @click="cancelEdits">Cancel</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Insurancefleet from '@/scripts/objects/insurancefleet'
import Phone from '@/components/Helpers/Phone.vue'
import FloatInput from '@/components/Helpers/FloatInput.vue'
import { ValidateComponents, ResetValidateComponents, StateObjects } from '@/scripts/helpers/helpers.js'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import FloatDropdown from '@/components/Helpers/FloatDropdown'
import Api from '@/services/Api'
import CheckBoxLabel from '@/components/Helpers/CheckBoxLabel'

export default {
  props: ['parentInsurancefleet', 'shopId'],
  data () {
    return {
      states: StateObjects,
      insurancefleet: {},
      validateInputs: [],
      ediPartners: [],
      tradingPartners: [{name: 'Safelite', value: 'safelite'}, {name: 'Lynx ($1.25)', value: 'lynx'}, {name: 'Harmon', value: 'harmon'}, {name: 'Quest (Under Maintenance)', value: 'quest'}, {name: 'Gerber ($1)', value: 'gerber'}],
      invoiceTierOptions: [{ value: 'dor', text: 'Due on receipt' }, { value: 'net15', text: 'Net 15' }, { value: 'net20', text: 'Net 20' }, { value: 'net20_500', text: 'Net 20 $500' }, { value: 'net30', text: 'Net 30' }, { value: 'net45', text: 'Net 45' }, { value: 'net60', text: 'Net 60' }, { value: 'net90', text: 'Net 90' }]
    }
  },
  mounted: function () {
    this.validateInputs = []
  },
  methods: {
    dialogAboutToAppear: function () {
      this.insurancefleet = new Insurancefleet(this.parentInsurancefleet)
      ResetValidateComponents(this.validateInputs)
      Api({url: `/api/shop/${this.shopId}/edipartners`}).then(res => {
        this.ediPartners = res.data
      })
    },
    saveInsurancefleet: function (event) {
      if (ValidateComponents(this.validateInputs)) {
        let self = this
        this.insurancefleet.createUpdateInsuranceFleet(this.shopId, function (object) {
          self.insurancefleet = new Insurancefleet(object.insurancefleet)
          // EMITS
          self.$root.$emit('updateInsurancefleetInformation', self.insurancefleet)
          self.$emit('updateSelectedInsurancefleet', self.insurancefleet)
          self.$root.$emit('bv::hide::modal', 'insurancefleetModal')
        })
      }
    },
    cancelEdits: function (event) {
      this.insurancefleet = new Insurancefleet({})
      this.$root.$emit('bv::hide::modal', 'insurancefleetModal')
    },
    quickApplyDetails: function (company) {
      if (company === 'sgc') {
        this.insurancefleet.address = 'PO Box 182277'
        this.insurancefleet.address_city = 'COLUMBUS'
        this.insurancefleet.address_state = 'OH'
        this.insurancefleet.address_zip = '43218'
        this.insurancefleet.phone = '(614) 602-2120'
        this.insurancefleet.fax = '(614) 210-9502'
        this.insurancefleet.email = 'invoicingfax@safelite.com'
        this.insurancefleet.web = 'sgc.safelite.com'
      }
      if (company === 'lynx') {
        this.insurancefleet.address = '6351 Bayshore Road, Suite 18'
        this.insurancefleet.address_city = 'NORTH FORT MYERS'
        this.insurancefleet.address_state = 'Florida'
        this.insurancefleet.address_zip = '33917'
        this.insurancefleet.phone = '(239) 479-6000'
        this.insurancefleet.fax = ''
        this.insurancefleet.email = ''
        this.insurancefleet.web = 'www.lynxservices.com'
      }
    }
  },
  computed: {
    cashCustomer: {
      get () {
        return this.insurancefleet.cash_customer === 1
      },
      set (val) {
        if (val) {
          this.insurancefleet.cash_customer = 1
        } else {
          this.insurancefleet.cash_customer = 0
        }
      }
    },
    exportAsCustomer: {
      get () {
        return this.insurancefleet.cash_customer === 1 && this.insurancefleet.export_as_customer === 1
      },
      set (val) {
        if (val) {
          this.insurancefleet.export_as_customer = this.insurancefleet.cash_customer
        } else {
          this.insurancefleet.export_as_customer = 0
        }
      }
    },
    taxExempt: {
      get () {
        return this.insurancefleet.tax_exempt === 1
      },
      set (val) {
        if (val) {
          this.insurancefleet.tax_exempt = 1
        } else {
          this.insurancefleet.tax_exempt = 0
        }
      }
    },
    autoAddAdhesive: {
      get () {
        return this.insurancefleet.auto_add_adhesive === 1
      },
      set (val) {
        if (val) {
          this.insurancefleet.auto_add_adhesive = 1
        } else {
          this.insurancefleet.auto_add_adhesive = 0
        }
      }
    },
    poRequired: {
      get () {
        return this.insurancefleet.po_required === 1
      },
      set (val) {
        if (val) {
          this.insurancefleet.po_required = 1
        } else {
          this.insurancefleet.po_required = 0
        }
      }
    },
    displayPhone: {
      get () {
        return this.insurancefleet.display_phone === 1
      },
      set (val) {
        if (val) {
          this.insurancefleet.display_phone = 1
        } else {
          this.insurancefleet.display_phone = 0
        }
      }
    },
    ediCapable: {
      get () {
        return this.insurancefleet.edi_capable === 1
      },
      set (val) {
        if (val) {
          this.insurancefleet.edi_capable = 1
        } else {
          this.insurancefleet.edi_capable = 0
          this.insurancefleet.edi_trading_partner = ''
          this.insruancefleet.edi_safelit_program_id = ''
        }
      }
    },
    autoPopupCheck: {
      get () {
        return this.insurancefleet.auto_popup === 1
      },
      set (val) {
        if (val) {
          this.insurancefleet.auto_popup = 1
        } else {
          this.insurancefleet.auto_popup = 0
        }
      }
    },
    safelitePartners: function () {
      return this.ediPartners.filter(val => val.trading_partner === 'safelite')
    },
    questPartners: function () {
      return this.ediPartners.filter(val => val.trading_partner === 'quest')
    }
  },
  watch: {
    'parentInsurancefleet': function (val) {
      this.insurancefleet = new Insurancefleet(this.parentInsurancefleet)
    },
    'insurancefleet.address_zip': function (val) {
      let self = this
      this.zipLookup(val, function (data) {
        self.insurancefleet.address_city = data.city
        self.insurancefleet.address_state = data.state_short
      })
    }
  },
  components: {
    Phone,
    FloatInput,
    FloatDropdown,
    CheckBoxLabel
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped>
 .insurance-section-header {
    margin-top: 5px;
 }

 .idBox {
    background-color: #EDC9AF;
    padding: 5px;
    border-radius: 3px;
 }
</style>
