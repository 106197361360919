import * as R from 'ramda'
import { dateFilterMap } from '@/constants/reports.configs'
import { reportColumns } from '@/constants/reports.columns'

const reportsGetters = {
  hasNoData: R.pipe(
    R.prop('data'),
    R.isNil
  ),
  emptyData: R.pipe(
    R.prop('data'),
    R.isEmpty
  ),
  currentColumns: ({ type }) =>
    R.pathOr([], [type.group, type.id])(reportColumns),
  dateColumnLabel: state => dateFilterMap[state.filters.date_type],
  isDateMode: R.pathEq(['filters', 'mode'], 'By date'),
  isShopMode: R.pathEq(['filters', 'mode'], 'By shop'),
  noStoresSelected: R.pipe(
    R.path(['filters', 'shops']),
    R.isEmpty
  ),
  shopTaxColumns: state => shop =>
    R.pipe(
      R.propOr([], 'shopData'),
      R.find(R.propEq('name', shop)),
      R.prop('taxes'),
      R.filter(({ id }) => R.contains(id)(state.filters.taxes)),
      R.map(R.assoc('dollar', true))
    )(state),
  isConsumer: (state, getters, rootState) => {
    if (!state?.filters?.shops || (!state?.filters?.shops.length && isNaN(state?.filters?.shops))) return false
    if (Array.isArray(state?.filters?.shops)) {
      for (var i = 0; i < state?.filters?.shops.length; i++) {
        for (var j = 0; j < rootState.shops.length; j++) {
          if (rootState.shops[j].id === state?.filters?.shops[i].id || rootState.shops[j].id === state?.filters?.shops[i]) {
            if (rootState.shops[j].shop_services_config?.consumer) return true
          }
        }
        if (state?.filters?.shops[i].consumer) return true
      }
    } else {
      for (var i = 0; i < rootState.shops.length; i++) {
        if (rootState.shops[i].id === state?.filters?.shops) {
          if (rootState.shops[i].shop_services_config?.consumer) return true
        }
      }
    }
    return false
  }
}

export default reportsGetters
