<template>
  <b-modal @show="dialogAboutToAppear" @shown="$refs.nameComponent.focus()" no-close-on-backdrop no-close-on-esc
  v-bind:title="location.id > 0 ? 'Edit Location' : 'New Location'" id="locationModal"
    header-bg-variant='primary'
    header-text-variant='light'
    size="lg">
    <div class="row">
      <div class="form-group col-6">
        <label>Status</label>
        <select v-model='location.status' class="form-control form-control-sm">
          <option value="active">Active</option>
          <option value="disabled">Disabled</option>
        </select>
      </div>
      <div v-if="location.id > 0" class="form-group col-6">
        <label></label>
        <p>ID: <span class="idBox">{{location.id}}</span></p>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-8">
        <RequiredInput ref="nameComponent" :title="'Name'" :value.sync='location.name' :placeholder="''" :capitalizeAll='true' />
      </div>
      <div v-if='checkCommissionPermission' class="form-group col-4">
        <label>Commission</label>
        <FloatDropdown ref="commissionComponent" :dropdownOptions="[{'value':'flat', 'label':'$', 'dropdownLabel':'Flat'}, {'value':'percent', 'label':'%', 'dropdownLabel':'%'}]" :inDisabled="false" :dropdownValue.sync="location.commission_flat_or_percent" :value.sync="location.commission"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label>Details</label>
        <textarea class="form-control form-control-sm" v-model='location.details'></textarea>
      </div>
    </div>
    <div slot="modal-footer" class='w-100'>
      <div class='float-right'>
        <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="saveLocation">Save</button>
        <button type='button' class='btn btn-secondary' variant="primary" @click="cancelEdits">Cancel</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Location from '@/scripts/objects/location'
import RequiredInput from '@/components/Helpers/RequiredInput.vue'
import { ValidateComponents, ResetValidateComponents } from '@/scripts/helpers/helpers.js'
import FloatDropdown from '@/components/Helpers/FloatDropdown.vue'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default {
  props: ['parentLocation', 'shopId'],
  data () {
    return {
      location: {},
      validateInputs: []
    }
  },
  mounted: function () {
    this.validateInputs = [this.$refs.nameComponent, this.$refs.commissionComponent]
  },
  methods: {
    dialogAboutToAppear: function () {
      this.location = new Location(this.parentLocation)
      ResetValidateComponents(this.validateInputs)
    },
    saveLocation: function (event) {
      if (ValidateComponents(this.validateInputs)) {
        let self = this
        this.location.createUpdateLocation(this.shopId, function (object) {
          var skipSet = false
          if (self.location.objectTrackerInitial.status === 'active' && object.status === 'disabled') {
            skipSet = true
          }
          self.location = new Location(object)
          self.$root.$emit('updateSelectLocation', self.location, skipSet)
          self.$root.$emit('bv::hide::modal', 'locationModal')
        }, function (error) {
          
        })
      }
    },
    cancelEdits: function (event) {
      event.preventDefault()
      this.$root.$emit('bv::hide::modal', 'locationModal')
    }
  },
  watch: {
    'parentLocation': function (val) {
      this.location = new Location(this.parentLocation)
    }
  },
  components: {
    RequiredInput,
    FloatDropdown
  },
  mixins: [UtilityMixin]
}
</script>

<style>
.idBox {
  background-color: #EDC9AF;
  padding: 5px;
  border-radius: 3px;
}
</style>
