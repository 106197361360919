import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import * as R from 'ramda'

export default class Commercialaccount extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  name = ''
  phone1 = ''
  phone2 = ''
  email = ''
  contact_last_name = ''
  contact_first_name = ''
  contact_middle_name = ''
  address = ''
  zip = ''
  city = ''
  state = ''
  commercial_account_tax_exempt = 0
  notes = ''
  insurancefleet = 0
  modified = -1
  shop_id = -1
  insurancefleet_id = null
  default_email = ''
  fax = ''
  default_pricelevel_id = null
  default_salesrep_id = null
  default_tax = []
  flagged_customer = 0
  g_address = null
  install_location = null
  install_location_id = null
  po_required = 0
  discount = 0
  oem_discount = 0
  labor_flat = 0
  labor_hourly = 0
  kit_standard = 0
  kit_high = 0
  kit_fast = 0
  kit_charge_type_standard = 'each'
  kit_charge_type_high = 'each'
  kit_charge_type_fast = 'each'
  chip_first = 0
  chip_additional = 0
  moulding_discount = 0
  recal_safelite_dynamic = 0
  recal_safelite_static = 0
  recal_safelite_dual = 0
  recal_lynx_rtl_dynamic = 0
  recal_lynx_rtl_static = 0
  recal_lynx_rtl_both = 0
  recal_lynx_dlr_dynamic = 0
  recal_lynx_dlr_static = 0
  recal_lynx_dlr_both = 0
  default_invoice_tier = null

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, callbackFunction) {
    Api({ url: `/api/shop/${shopId}/commercialaccount` }).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new Commercialaccount(val))
      })
      callbackFunction(objects)
    })
  }

  static update (commercialaccount, callbackFunction, errorCallbackFunction = null) {
    return Api.post(`/api/shop/${commercialaccount.shop_id}/commercialaccount/${commercialaccount.id}`, JSON.parse(UtilityMixin.methods.StringifyValidProperties(commercialaccount))).then(res => {
      callbackFunction()
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  // GET COMMERCIAL ACCOUNTS BY USER + FILTERS
  static getCommercialAccountsByUserShops (page, limit, sortBy, sortDesc, commercialaccountIdFilter, commercialaccountNameFilter,
    commercialaccountShopNameFilter, commercialaccountEmailFilter, commercialaccountPhoneFilter, successCallback, errorCallback) {
    Api({url: `/api/usercommercialaccounts?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&commercialaccountIdFilter=' + encodeURIComponent(commercialaccountIdFilter) + '&commercialaccountNameFilter=' + encodeURIComponent(commercialaccountNameFilter) +
      '&commercialaccountShopNameFilter=' + encodeURIComponent(commercialaccountShopNameFilter) + '&commercialaccountEmailFilter=' + encodeURIComponent(commercialaccountEmailFilter) +
      '&commercialaccountPhoneFilter=' + encodeURIComponent(commercialaccountPhoneFilter)
    }).then(res => {
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  async createUpdateCommercialAccount (shopId, callbackFunction, errorCallbackFunction = null) {
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    if (this.install_location && this.install_location.g_address && this.install_location.g_address.google_place_id) {
      if (this.install_location_id) {
        await Api.put(
          `/api/install_location/${this.install_location_id}`,
          this.install_location
        )
      } else {
        const { data } = await Api.post(
          '/api/install_location',
          this.install_location
        )
        this.install_location_id = data.id
      }
    } else {
      this.install_location_id = null
    }
    return Api.post(`/api/shop/${this.shop_id}/commercialaccount/${this.id}`, this).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  async checkGetAddress() {
    try {
      const hasAddressFields = R.pipe(R.pick(['address', 'city', 'state', 'zip']), R.isEmpty, R.not)(this)
      if (hasAddressFields && !this.g_address && this.id >= 0) {
        const { data = {} } = await Api.get(`/api/shop/${this.shop_id}/commercialaccount/${this.id}`, this)
        this.g_address = data.g_address
        return data.g_address
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
}
