<template>
  <div>
    <el-divider class="t-my-6" />

    <div class="t-mb-4">
      <el-checkbox
        v-model="hide_completed_jobs"
        @change="updateHideCompletedJobsHandler"
      >
        Hide completed jobs
      </el-checkbox>
    </div>

    <div class="t-mb-4">
      <el-checkbox
        v-model="require_clock_in"
        @change="updateRequireClockInHandler"
      >
        Require clock in
      </el-checkbox>
    </div>

    <div class="t-mb-4">
      <el-checkbox
        v-model="require_tech_pickup"
        @change="updateRequireTechPickupHandler"
      >
        Require tech pickup
      </el-checkbox>
    </div>

    <div class="t-mb-4">
      <el-checkbox
        v-model="require_pre_inspection"
        @change="updateRequirePreInspectionHandler"
      >
        Require inspection
      </el-checkbox>
    </div>

    <div class="t-mb-4">
      <el-checkbox
        v-model="techside_hide_prices"
        @change="updateTechsideHidePricesHandler"
      >
        Hide prices
      </el-checkbox>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  data() {
    return {
      hide_completed_jobs: false,
      require_clock_in: false,
      require_tech_pickup: false,
      require_pre_inspection: false,
      techside_hide_prices: false
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data'])
  },

  mounted() {
    this.hide_completed_jobs = !!this.data.hide_completed_jobs
    this.require_clock_in = !!this.data.require_clock_in
    this.require_tech_pickup = !!this.data.require_tech_pickup
    this.require_pre_inspection = !!this.data.require_pre_inspection
    this.techside_hide_prices = !!this.data.techside_hide_prices
  },

  methods: {
    ...mapActions(useVendorStore, [
      'updateHideCompletedJobs',
      'updateRequireClockIn',
      'updateRequireTechPickup',
      'updateRequirePreInspection',
      'updateTechsideHidePrices'
    ]),

    async updateHideCompletedJobsHandler(value) {
      try {
        await this.updateHideCompletedJobs(value)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateRequireClockInHandler(value) {
      try {
        await this.updateRequireClockIn(value)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateRequireTechPickupHandler(value) {
      try {
        await this.updateRequireTechPickup(value)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateRequirePreInspectionHandler(value) {
      try {
        await this.updateRequirePreInspection(value)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateTechsideHidePricesHandler(value) {
      try {
        await this.updateTechsideHidePrices(value)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>
