<template>
  <div class="row text-center mb-1">
    <div class="col-4">
      <p class="mb-0">Qty.</p>
      <i class="fa fa-plus" style="cursor:pointer;" aria-hidden="true" @click="partQuantity++"></i>
      <input type="text" class="text-center" maxlength="4" size="4" v-model="partQuantity">
      <i class="fa fa-minus" style="cursor:pointer;" aria-hidden="true" @click="partQuantity--"></i>
    </div>
    <div class="col-4">
      <p class="mb-0">Zone</p>
      <input type="text" class="text-center" maxlength="10" size="10" v-model="partZone">
    </div>
    <div class="col-4">
      <p class="mb-0">Avg. Cost</p>
      <input type="text" class="text-center" maxlength="7" size="7" v-model="partAvgCost">
    </div>
  </div>
</template>

<script>
export default {
  props: ['shopId', 'partno', 'avg_cost', 'quantity', 'zone'],
  data () {
    return {
    }
  },
  computed: {
    partAvgCost: {
      set (val) {
        this.$emit('update:avg_cost', val)
      },
      get () {
        return this.avg_cost
      }
    },
    partQuantity: {
      set (val) {
        this.$emit('update:quantity', val)
      },
      get () {
        return this.quantity
      }
    },
    partZone: {
      set (val) {
        this.$emit('update:zone', val)
      },
      get () {
        return this.zone
      }
    }
  }
}
</script>

<style scoped>

</style>
