<template>
  <div class="scheduler-job-counters">
    <div class="wrapper">
      <el-badge
        :value="`${jobTypes.length} of 8`"
        :hidden="jobTypes.length === 8"
        class="t-mr-10">
        <div class="t-mr-3">Job Types:</div>
        <el-select
          v-model="jobTypes"
          multiple
          collapse-tags
          size="mini"
          placeholder="Job Types">
          <el-option
            v-for="item in jobTypeFilterOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-badge>
    </div>
    <div class="wrapper">
      <el-badge
        v-for="(val, key) in contextCounters"
        :key="key"
        :value="val"
        type="primary"
      >
        <el-tag
          size="small"
          @click="setContext(key)"
          :type="context === key ? 'primary' : 'info'"
        >
          {{ keys[key] }}
        </el-tag>
      </el-badge>
    </div>
    <el-divider direction="vertical" class="v" />
    <el-divider class="h" />
    <div class="wrapper">
      <div
        class="counter"
        v-for="(value, label) in midTimeCounters"
        :key="label"
      >
        <el-badge :value="value" :type="timeCounterType(value)">
          <el-tag
            :type="timeCounterEffect(label)"
            size="small"
            @click="toggleCounter(label)"
          >
            {{ label }}
          </el-tag>
        </el-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify'
import * as R from 'ramda'

export default {
  data() {
    return {
      keys: {
        all: 'All',
        inShop: 'In Shop',
        mobile: 'Mobile',
        willCall: 'Will Call'
      },
      jobTypeFilterOptions: [
        { label: 'Call Back', value: 'callBack' },
        { label: 'Drop Off', value: 'dropOff' },
        { label: 'Pick Up', value: 'pickUp' },
        { label: 'R & I', value: 'rAndI' },
        { label: 'Recal', value: 'recal' },
        { label: 'Tint', value: 'tint' },
        { label: 'Warranty', value: 'warranty' },
        { label: 'Work Order', value: 'workOrder' }
      ]
    }
  },
  computed: {
    midTimeCounters: get('sch_v3/midTimeCounters'),
    contextCounters: get('sch_v3/contextCounters'),
    jobCount: get('sch_v3/jobCount'),
    dayCounters: sync('sch_v3/filters@dayCounters'),
    isBayView: get('sch_v3/isBayView'),
    context: sync('sch_v3/filters@context'),
    jobTypes: sync('sch_v3/filters@jobTypes')
  },
  methods: {
    timeCounterType(value) {
      const amt = this.jobCount / 3
      return value < amt ? 'success' : value === amt ? 'warning' : 'danger'
    },
    timeCounterEffect(value) {
      return this.dayCounters[value] ? 'primary' : 'info'
    },
    toggleCounter(key) {
      this.dayCounters = R.over(R.lensProp(key), R.not, this.dayCounters)
    },
    toggleContext(key) {
      this.context = key
    },
    contextType(key) {
      return this.context === key ? 'primary' : 'info'
    },
    setContext(key) {
      if (!this.isBayView) {
        this.context = key
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./styles/SchedulerJobCounters.scss";
</style>
