var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global-search-results-group" },
    [
      _c("div", { staticClass: "global-search-results-group-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _vm.action
          ? _c(
              "div",
              {
                staticClass: "global-search-results-group-action",
                on: {
                  click: function($event) {
                    return _vm.$emit("action")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.action) + " ")]
            )
          : _vm._e()
      ]),
      _vm._l(_vm.rows, function(row, idx) {
        return _c("search-result-row", {
          key: _vm.type + ":" + idx,
          attrs: {
            row: Object.assign({}, { type: _vm.type }, row),
            type: _vm.type,
            highlighted: _vm.highlight === idx + _vm.offset
          },
          on: {
            select: function($event) {
              return _vm.$emit("select", $event)
            }
          },
          nativeOn: {
            mouseenter: function($event) {
              return _vm.$emit("highlight", idx + _vm.offset)
            },
            mouseleave: function($event) {
              return _vm.$emit("highlight", -1)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }