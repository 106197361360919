var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "t-flex t-rounded-md t-shadow-sm t-mb-2",
      class: {
        "hover:t-bg-gray-50 t-cursor-pointer": _vm.type === "search"
      },
      on: { click: _vm.select }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "t-rounded-l-md t-w-12 t-h-12 t-text-base t-text-gray-600 t-flex\n      t-items-center t-justify-center t-flex-shrink-0 t-border t-border-solid\n      t-border-gray-200",
          class: {
            "t-bg-gray-200": !_vm.item.color && !_vm.item.isCatalog,
            "t-bg-orange-200": !_vm.item.color && _vm.item.isCatalog
          },
          style: { backgroundColor: _vm.item.color }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "t-flex t-items-center t-justify-center t-rounded-full\n        t-bg-white t-w-8 t-h-8"
            },
            [
              _c("i", {
                class: _vm.item.isCatalog
                  ? "el-icon-folder"
                  : "el-icon-document"
              })
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "t-flex t-flex-grow t-justify-between t-border t-border-solid\n      t-border-gray-200 t-border-l-0 t-rounded-r-md t-min-w-0"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "t-px-4 t-py-1 t-flex t-flex-col t-justify-center t-flex-grow\n        t-min-w-0"
            },
            [
              _c(
                "div",
                { staticClass: "t-text-gray-700 t-w-full t-truncate" },
                [_vm._v(" " + _vm._s(_vm.item.number) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "t-text-xs t-text-gray-500 t-mt-[1px] t-w-full t-truncate"
                },
                [_vm._v(" " + _vm._s(_vm.item.description) + " ")]
              )
            ]
          ),
          _vm.type === "parent"
            ? _c(
                "div",
                { staticClass: "t-flex t-items-center t-px-4" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "Unlink",
                        placement: "top"
                      }
                    },
                    [
                      _c("i", {
                        staticClass:
                          "el-icon-circle-close t-text-xl t-cursor-pointer\n            t-text-orange-500",
                        on: { click: _vm.unassign }
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.type === "assign"
            ? _c(
                "div",
                { staticClass: "t-flex t-items-center t-px-4" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "Remove",
                        placement: "top"
                      }
                    },
                    [
                      _c("i", {
                        staticClass:
                          "el-icon-delete t-text-base t-cursor-pointer t-text-red-500",
                        on: { click: _vm.removeSelected }
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.item.isCatalog
            ? _c(
                "div",
                {
                  staticClass:
                    "t-min-w-[6rem] t-pr-4 t-flex t-items-center t-justify-end\n        t-border-0 t-border-l t-border-solid t-bg-blue-50 t-text-base"
                },
                [_vm._v(" $" + _vm._s(_vm.item.listPrice) + " ")]
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }