var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-v2", class: { "header-v2-mobile": _vm.isMobile } },
    [
      _c("a", { staticClass: "header-v2-logo", attrs: { href: "/" } }, [
        _c("img", { attrs: { src: _vm.logo, alt: "Glassbiller Logo" } })
      ]),
      _c("header-v-2-menu"),
      _c(
        "div",
        { staticClass: "t-flex t-items-center" },
        [
          _c("header-v-2-search", {
            attrs: {
              "file-picker-key": _vm.filePickerKey,
              "allow-quick-books": _vm.allowQuickBooks
            }
          }),
          _c("header-v-2-user", {
            attrs: {
              "allow-quick-books": _vm.allowQuickBooks,
              "file-picker-key": _vm.filePickerKey
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }