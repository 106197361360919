<template>
  <el-dialog
    append-to-body
    width="900px"
    :visible="visible"
    :before-close="handleClose"
    custom-class="add-payment-dialog t-font-sans"
  >
    <span slot="title" class="t-text-gray-700 t-text-lg">
      <i class="el-icon-bank-card"></i>
      <span class="t-ml-3">
        Edit payment
      </span>
    </span>
    <div class="t-pt-6">
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="200px"
        hide-required-asterisk
        size="medium"
        style="max-height: 700px"
        class="t-overflow-y-auto"
      >
        <el-form-item prop="type">
          <span slot="label" class="undo-label-styles">Type</span>
          <el-select
            v-model="form.type"
            class="t-w-72"
          >
            <el-option label="Payment" value="Payment"/>
            <el-option label="Adjustment" value="Adjustment"/>
          </el-select>
        </el-form-item>
        <el-form-item prop="date">
          <span slot="label" class="undo-label-styles">Date</span>
          <el-date-picker
            v-model="form.date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="Pick a date"
            class="t-w-72"
          />
        </el-form-item>
        <el-form-item prop="x_action_type">
          <span slot="label" class="undo-label-styles">X-action Type</span>
          <el-select
            v-model="form.x_action_type"
            placeholder="Select X-action Type"
            class="t-w-72"
          >
            <el-option
              v-for="item in xActionTypes[form.type]"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="x_action_number">
          <span slot="label" class="undo-label-styles">X-action Number</span>
          <el-input
            placeholder="Enter X-action Number"
            v-model="form.x_action_number"
            class="t-w-72"
          />
        </el-form-item>
        <el-form-item prop="payment_account_id">
          <span slot="label" class="undo-label-styles">Payment account</span>
          <el-select
            v-model="form.payment_account_id"
            placeholder="Select Payment Account"
            class="t-w-72"
          >
            <el-option
              v-for="i in accounts"
              :key="i.id"
              :value="i.id"
              :label="i.name"
            />
          </el-select>
        </el-form-item>
        <div
          v-for="(payment, index) of form.payments"
          :key="index"
          class="t-mx-6"
        >
          <div class="t-flex t-border t-border-solid t-border-gray-300
                      t-rounded-md t-my-2 t-px-4 t-py-2"
          >
            <div class="t-flex-grow t-flex t-justify-between t-pr-8">
              <div>
                <div class="t-text-gray-600">Job id</div>
                <div class="t-text-base">
                  <a :href="`/jobs/${payment.job_id}`" target="_blank">
                    {{ payment.job_id }}
                  </a>
                </div>
              </div>
              <div>
                <div class="t-text-gray-600">Total balance</div>
                <div class="t-text-base">${{ payment.total_balance }}</div>
              </div>
              <div>
                <div class="t-text-gray-600">Remaining balance</div>
                <div class="t-text-base">${{ payment.remaining_balance }}</div>
              </div>
            </div>
            <div class="t-w-72">
              <el-form-item
                label-width="0"
                class="t-mb-1"
                :prop="'payments.' + index + '.amount'"
                :show-message="false"
                :rules="[
                  {
                    required: true
                  },
                ]"
              >
                <el-input
                  v-model="form.payments[index].amount"
                  size="small"
                  type="number"
                  placeholder="Amount"
                  class="t-w-full"
                />
              </el-form-item>
              <el-input
                size="small"
                placeholder="Memo"
                v-model="form.payments[index].memo"
              />
            </div>
          </div>
        </div>
      </el-form>
      <div class="t-flex t-items-baseline t-my-6 t-mx-6">
        <div
          class="t-text-gray-600 t-mr-2 t-text-base t-flex-grow t-text-right"
        >
          Total
        </div>
        <div class="t-text-xl t-font-medium">${{ total }}</div>
      </div>
      <div class="t-bg-gray-200 t-py-3 t-px-6 t-flex t-justify-between">
        <el-button
          plain
          @click="handleClose"
          size="small"
        >
          Close
        </el-button>
        <el-button
          type="primary"
          @click="handleSubmit"
          :loading="loading"
          icon="el-icon-plus"
          size="small"
        >
          Update
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import { paymentGroupRepository } from '@/scripts/repositories/paymentGroup'
import COAccount from '@/scripts/objects/co_account'

export default {
  props: {
    visible: Boolean,
    paymentGroup: Object
  },
  data: () => ({
    form: {
      type: '',
      date: '',
      x_action_type: '',
      x_action_number: '',
      payment_account_id: null,
      payments: []
    },
    rules: {
      date: { required: true, message: 'Date is required', trigger: 'blur' },
      x_action_type: {
        required: true,
        message: 'X-action type is required',
        trigger: 'blur'
      }
    },
    loading: false,
    xActionTypes: {
      Payment: [
        'Cash',
        'Manual Card',
        'Check',
        'Bank Transfer',
        'Other'
      ],
      Adjustment: [
        'Promo',
        'Coupon',
        'Credit',
        'Short Pay',
        'Write Off',
        'Deductible Discount',
        'Fee',
        'Doubtful Account',
        'Other'
      ]
    },
    accounts: []
  }),
  computed: {
    total: function() {
      let total = 0
      for (const i of this.form.payments) {
        total += i.amount
      }
      return this.formatAmount(total)
    },
    shop: function() {
      return this.$store.state.shops.find(i => i.id === this.paymentGroup.shop_id)
    }
  },
  watch: {
    visible: function(value) {
      if (value) {
        this.prefillForm()
        this.getCoAccounts()
      } else {
        this.clearForm()
      }
    }
  },
  methods: {
    handleClose: function() {
      this.$emit('close')
    },
    formatAmount: amount => Math.round(amount * 100) / 100,
    handleSubmit: async function() {
      try {
        this.loading = true
        await this.$refs.form.validate()
        await paymentGroupRepository.update({
          id: this.paymentGroup.id,
          data: this.form
        })
        this.$emit('updated')
        this.handleClose()
        this.$message.success('Payment was successfully updated')
        this.loading = false
      } catch {
        this.loading = false
      }
    },
    prefillForm: function() {
      this.form.type = this.paymentGroup.type
      this.form.date = this.paymentGroup.date ? moment.utc(this.paymentGroup.date).format('YYYY-MM-DD') : ''
      this.form.x_action_type = this.paymentGroup.x_action_type
      this.form.x_action_number = this.paymentGroup.x_action_number
      this.form.payment_account_id = this.paymentGroup.payment_account_id
      this.form.payments = this.paymentGroup.payments
    },
    clearForm: function() {
      this.form = {
        type: '',
        date: '',
        x_action_type: '',
        x_action_number: '',
        payment_account_id: null,
        payments: []
      }
    },
    getCoAccounts: async function() {
      if (this.shop?.accounting_package_id > 0) {
        let accounts = []
        await COAccount.getChartOfAccountsObjects(
          this.shop.accounting_package_id,
          null,
          null,
          function (results) {
            accounts = results
          }
        )
        this.accounts = accounts.filter(
          i => i.active === 1 && i.account_type === 'Liabilities'
        )
      }
    }
  }
}
</script>

<style lang="scss">
.add-payment-dialog {
  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>
