<template>
  <b-modal
    @show="dialogAboutToAppear"
    no-close-on-backdrop
    no-close-on-esc
    v-bind:title="
      location.id > 0 ? 'Edit install location' : 'New install location'
    "
    id="installLocationModal"
    @hidden="cancelEdits"
    header-bg-variant="primary"
    header-text-variant="light"
    size="lg"
  >
    <div v-loading="loading">
      <div class="row">
        <div class="form-group col-6">
          <label>Status</label>
          <select
            v-model="location.status"
            class="form-control form-control-sm"
          >
            <option value="active">Active</option>
            <option value="disabled">Disabled</option>
          </select>
        </div>
        <div v-if="location.id > 0" class="form-group col-6">
          <label></label>
          <p>
            ID: <span class="idBox">{{ location.id }}</span>
          </p>
        </div>
        <div class="form-group col-6">
          <label>Name</label>
          <input
            type="text"
            class="form-control form-control-sm capitalize text-uppercase autocomplete-off"
            v-model="location.name"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label>Address</label>
          <address-input v-model="location.g_address" popperFix />
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="w-100">
      <div class="float-right">
        <button
          type="button"
          size="lg"
          class="btn btn-primary"
          variant="primary"
          @click="saveInstallLocation"
          :disabled="disabled"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          variant="primary"
          @click="cancelEdits"
        >
          Cancel
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import AddressInput from '@/components/Helpers/AddressInput.vue'
import { UtilityMixin } from '@/scripts/helpers/utilities'
// import { schedulerNamespacedActions } from '@/store/modules/scheduler/scheduler.types'
import Api from '@/services/Api'
import * as R from 'ramda'

export default {
  props: ['shopId', 'editLocation'],
  data() {
    return {
      location: {},
      loading: false
    }
  },
  computed: {
    disabled() {
      return (
        !this.location.name ||
        !R.path(['location', 'g_address', 'google_place_id'])(this)
      )
    }
  },
  methods: {
    dialogAboutToAppear: function() {
      if (this.editLocation) {
        this.location = R.clone(this.editLocation)
      } else {
        this.location = {
          status: 'active',
          shop_id: this.shopId
        }
      }
    },
    saveInstallLocation: async function(event) {
      this.loading = true
      try {
        if (this.location.id) {
          await Api.put(
            `/api/install_location/${this.location.id}`,
            this.location
          )
          this.$emit('new-location', this.location)
        } else {
          const { data } = await Api.post(
            '/api/install_location',
            this.location
          )
          this.$emit('new-location', data)
        }
      } catch (error) {}
      this.loading = false
      this.$root.$emit('bv::hide::modal', 'installLocationModal')
      this.bay = {}
    },
    cancelEdits: function(event) {
      event.preventDefault()
      this.location = {}
      this.$root.$emit('bv::hide::modal', 'installLocationModal')
    }
  },
  components: {
    AddressInput
  },
  mixins: [UtilityMixin]
}
</script>

<style>
.idBox {
  background-color: #edc9af;
  padding: 5px;
  border-radius: 3px;
}
</style>
