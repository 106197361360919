<template>
  <base-layout :data="data">
    <div slot="title">SMS Received</div>

    <div>
      {{shopName}} has received an sms from {{phoneNumber}}.
      <br><br>
      <p><b>Message:</b><br>{{data.details.smsBody}}</p>
    </div>
  </base-layout>

</template>

<script>
import BaseLayout from './_BaseLayout'
import { formatPhonePartial } from '@/store/modules/notifications-settings/helpers'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    shopName() {
      const shop = this.$store.state.shops.find(
        obj => obj.id.toString() === this.data.details.shopId)

      return shop?.name
    },

    phoneNumber() {
      return formatPhonePartial(this.data.details.smsFrom)
    }
  },

  components: { BaseLayout }
}
</script>
