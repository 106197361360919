import * as R from 'ramda'
import {
  getTechList,
  filterJobsByDate,
  filterItemsByDate,
  getTechWaypointList,
  getBayList
} from '@/scripts/helpers/scheduler_v2.helpers'
import { formatToDb } from '@/scripts/helpers/date.helpers'
import {
  sortRoutes,
  extractTechsFromRoutes,
  getWeekRange,
  getWeekRoutes,
  getRouteMidTimeCounters,
  getTotalJobCount,
  getRouteContextCounters,
  getTechsideColors,
  getOrgTechs
} from '@/scripts/helpers/scheduler_v3.helpers'
import {
  getPolylines,
  getRouteStates,
  getUnmappedJobs
} from '@/scripts/helpers/geometry_v3.helpers'
import {
  getCombinedMapStops,
  getTechsideUsernames
} from '@/scripts/helpers/routing.helpers.js'

import { propNot } from '@/scripts/helpers/ramda.helpers'
import { addJobMidTimes } from '@/scripts/helpers/job.helpers'

const dataListByKey = key =>
  R.pipe(
    R.path(['data', key]),
    R.values
  )

const getters = {
  waypointList: dataListByKey('waypoints'),
  appointmentList: dataListByKey('appointments'),
  newRequestFilters: R.pipe(
    R.prop('filters'),
    R.pick(['shops', 'date', 'combine_techs', 'week'])
  ),
  newMapRequestFilters: R.pipe(
    R.prop('filters'),
    R.pick(['shops', 'date'])
  ),
  leftListFilterTitle: R.path(['activeJobListFilter', 'value']),
  isListView: R.pathEq(['filters', 'view'], 'list'),
  defaultUserStore: (state, getters, rootState) =>
    R.path(['user', 'shop_id'])(rootState),
  cardView: R.path(['filters', 'cardView']),
  isDayView: R.pathEq(['filters', 'cardView'], 3),
  isBayView: R.pathEq(['filters', 'cardView'], 4),
  isRowView: R.path(['filters', 'rowView']),
  isSolidCardView: R.path(['filters', 'solidCards']),
  dateFilter: state => formatToDb(state.filters.date),
  dateFilteredJobs: (state, getters) => date =>
    filterJobsByDate(date || state.filters.date)(getters.jobList),
  filteredJobs: (state, getters) => date =>
    R.pipe(
      R.filter(
        R.when(
          R.always(state.filters.context !== 'all'),
          R.propEq('install_context', state.filters.context)
        )
      ),
      filterJobsByDate(date || state.filters.date),
      addJobMidTimes
    )(getters.jobList),
  dateFilteredWaypoints: (state, getters) => date =>
    filterItemsByDate(date || state.filters.date)(getters.waypointList),
  dateFilteredAppointments: (state, getters) => date =>
    filterItemsByDate(date || state.filters.date)(getters.appointmentList),
  techList: (state, getters) => date => getTechList(date)(state, getters),
  bayList: (state, getters) => date => getBayList(date)(state, getters),
  techWaypointList: id => state => getTechWaypointList(id)(state),
  combineTechs: (state, getters, rootState, rootGetters) =>
    rootGetters.getUserCombineTechsValue,
  weekDates: (state, getters) =>
    R.pipe(
      R.path(['filters', 'week']),
      getWeekRange
    )(state),
  weekOffset: R.path(['filters', 'weekOffset']),
  routeStates: state => getRouteStates(state.data),
  visibleGeometry: state =>
    state.geometry.filter(R.allPass([propNot('hidden'), propNot('error')])),
  filteredWarehouses: state =>
    R.pipe(
      R.prop('warehouses'),
      R.filter(({ type }) => R.path(['filters', 'warehouses', type], state))
    )(state),
  warehouseFilter: R.path(['filters', 'warehouses']),
  unmappedJobs: state => getUnmappedJobs(state),
  routeList: state => R.propOr({}, 'data')(state),
  routeOrder: state => sortRoutes(state.data),
  techs: state => extractTechsFromRoutes(state),
  getWeekRoutes: state => date => getWeekRoutes(date)(state),
  midTimeCounters: state => getRouteMidTimeCounters(state),
  contextCounters: state => getRouteContextCounters(state),
  jobCount: state => getTotalJobCount(state),
  polylines: state => getPolylines(state),
  combinedStops: state => getCombinedMapStops(state),
  techsideUsernames: state => getTechsideUsernames(state),
  techsideColors: state => getTechsideColors(state),
  orgTechs: state => orgId => getOrgTechs(orgId)(state)
}

export default getters
