<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    custom-class="address-map-dialog"
    append-to-body
    @closed="clearExtra"
  >
    <div class="address-delivery-instructions" v-if="shop_id">
      <div v-if="value && value.extra">
        <div>
          {{ value.extra }}
        </div>
        <a href="#" @click.prevent="saveInstructions(null)" :tabindex="-1">
          <span>Remove instructions</span>
        </a>
      </div>
      <template v-else-if="addExtra">
        <delivery-instructions-input
          v-model="extra"
          :address="value"
          auto-focus
        />
        <div class="t-flex t-justify-end t-mt-2">
          <el-button size="mini" @click="clearExtra"> Cancel </el-button>
          <el-button
            size="mini"
            type="primary"
            :loading="saving"
            @click="saveInstructions(extra)"
          >
            Save
          </el-button>
        </div>
      </template>
      <a href="#" @click.prevent="addExtra = true" :tabindex="-1" v-else>
        <span>Add delivery instructions</span>
      </a>
    </div>

    <div class="address-map-dialog-container">
      <div class="address-map-wrapper">
        <gmap-map v-bind="defaultValues" ref="mapRef">
          <gmap-marker v-if="latLng" :position="latLng" :icon="icon" />
        </gmap-map>
      </div>
    </div>
    <!-- <gb-action-button
      class="address-map-dialog-button"
      @click="map.maxPlaceCount = 24"
    >
      View Nearby Places
    </gb-action-button> -->
    <!-- <div class="address-map-dialog-container">
      <div class="address-map-wrapper" ref="mapWrapper">
        <gmap-map v-bind="defaultValues" ref="mapRef">
          <gmap-marker v-if="latLng" :position="latLng" :icon="icon" />
        </gmap-map>
      </div>
    </div> -->
  </el-dialog>
</template>

<script>
import googleMapsConfig from '@/constants/google-maps.config'
import * as R from 'ramda'
import markerIcon from '@/assets/img/map-icons/map-position-marker.svg'
import DeliveryInstructionsInput from '../Helpers/DeliveryInstructionsInput'
// /* global google */
export default {
  props: ['value', 'shop_id'],
  components: { DeliveryInstructionsInput },
  data() {
    return {
      details: null,
      localContext: 0,
      map: null,
      addExtra: false,
      extra: null,
      saving: false
    }
  },
  computed: {
    defaultValues() {
      return {
        center: this.latLng || googleMapsConfig.defaultValues.center,
        zoom: this.latLng ? 13 : 5
      }
    },
    visible: {
      get() {
        return !!this.value
      },
      set(visible) {
        this.$emit('input', visible)
      }
    },
    title() {
      return this.value?.full_address || 'Map View'
    },
    latLng() {
      return this.value && R.pick(['lat', 'lng'])(this.value)
    },
    icon() {
      return {
        url: markerIcon,
        anchor: {
          x: 31,
          y: 55
        }
      }
    }
  },
  methods: {
    toggleExtra() {
      if (this.addExtra) {
        this.extra = ''
        this.addExtra = false
      } else {
        this.addExtra = true
      }
    },
    clearExtra() {
      this.addExtra = false
      this.extra = null
    },
    async saveInstructions(extra) {
      this.saving = true
      this.$emit('update-extra', extra)
      await this.$unum
        .job()
        .post(`shop/${this.shop_id}/address/${this.value.id}`, {
          extra
        })
      this.clearExtra()
      this.saving = false
    }
  }
}
</script>
<style lang="scss">
.address-map-dialog {
  .el-dialog__body {
    padding-top: 0;
  }
  width: 900px;
  max-width: 100vw;
  .address-delivery-instructions {
    max-width: 400px;
  }
  .address-map-dialog-container {
    margin-top: $padding-md;
    height: 500px;
    max-height: 90vh;
    width: 100%;

    .address-map-wrapper {
      height: 100%;
      width: 100%;
      .vue-map-container {
        flex-grow: 1;
        height: 100%;
        width: 100%;
      }
    }
  }
  .address-map-dialog-button {
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: $padding-sm;
    text-align: right;
    display: inline-block;
  }
}
</style>
