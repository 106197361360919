<template>
  <el-dialog
    :visible="visible"
    :before-close="close"
    width="900px"
    custom-class="vendor-results-dialog"
    append-to-body
  >
    <span slot="title" class="t-text-xl t-text-gray-800">
      American Glass results
    </span>
    <div class="t-mt-2 t-p-5 t-shadow t-bg-orange-200">
      <div class="t-flex">
        <div class="t-flex-1 t-text-gray-800">
          <div class="t-text-base">
            Part
            <span class="t-text-indigo-800 t-font-medium">
              {{ nagsPart.detail.partno }}
            </span>
          </div>
          <div class="t-text-085">{{ nagsPart.detail.dsc }}</div>
        </div>
        <el-tooltip
          v-if="!warehouse"
          class="item"
          effect="dark"
          content="Add to parts list"
          placement="top"
        >
          <button
            v-if="
              returnedPart &&
              returnedPart.item_price > 0 &&
              priceFromLevels &&
              priceLevelSelected
            "
            class="t-border-none t-rounded-full hover:t-bg-purple-600 hover:t-text-white t-bg-white t-cursor-pointer t-text-purple-700 t-text-base t-font-medium t-self-center t-px-3 t-py-1"
            @click="addPart"
          >
            ${{ priceFromLevels }}
          </button>
        </el-tooltip>
      </div>
    </div>
    <div class="t-p-5">
      <div class="t-flex t-items-center">
        <div>Total {{ results.length }} results</div>
        <div v-if="credentials.length > 1" class="t-flex-1 t-text-right">
          <el-select
            v-model="accountNumber"
            class="t-mb-1 t-ml-4"
            placeholder="Select account"
            size="mini"
          >
            <el-option
              v-for="item in credentials"
              :key="item.id"
              :label="
                item.account_number + (item.name ? ' - ' + item.name : '')
              "
              :value="item.account_number"
            />
          </el-select>
        </div>
        <div>
          <el-select
            v-model="accountDefault"
            class="t-ml-4"
            placeholder="Select warehouse"
            size="mini"
          >
            <el-option
              key="1"
              label="Account Default"
              value="Account Default"
            />
          </el-select>
        </div>
      </div>
      <div v-if="loading" v-loading="true" class="t-h-32"></div>
      <div v-else-if="results.length > 0">
        <results-table
          class="t-mt-3"
          :parts="results"
          :shopId="shopId"
          :jobId="jobId"
          :status="status"
          :accountNumber="accountNumber"
          :warehouse="warehouse"
          @refresh-data="$emit('refresh-data')"
          @addAndLinkPart="addAndLinkPart($event)"
        />
      </div>
      <p v-else class="t-text-center t-mt-3">No parts found</p>
    </div>
  </el-dialog>
</template>

<script>
import Api from '@/services/Api'
import ResultsTable from './Table'

export default {
  props: [
    'results',
    'visible',
    'loading',
    'nagsPart',
    'priceFromLevels',
    'shopId',
    'jobId',
    'defaultCredentials',
    'standalone',
    'warehouse',
    'status'
  ],
  data: () => ({
    accountNumber: null,
    allCredentials: [],
    accountDefault: 'Account Default'
  }),
  computed: {
    returnedPart() {
      return this.results[0]
    },
    credentials() {
      if (this.standalone) {
        if (this.allCredentials.length) {
          return this.allCredentials.filter(i => i.vendor === 'american')
        }
      } else {
        if (this.$store.state.partVendors.credentials.length) {
          return this.$store.state.partVendors.credentials.filter(
            i => i.vendor === 'american'
          )
        }
      }
      return []
    },
    priceLevelSelected() {
      const pl = this.$job.job.selected_price_level
      return pl && pl > 0
    }
  },
  watch: {
    accountNumber: function(newVal, oldVal) {
      if (oldVal !== null) {
        this.recheckVendor()
      }
    }
  },
  created() {
    this.accountNumber = this.defaultCredentials
    if (this.standalone) {
      this.getCredentials()
    }
  },
  methods: {
    close() {
      if (this.standalone) {
        this.$emit('close')
      } else {
        this.$store.commit('partVendors/setSearchResultsVisible', {
          vendor: 'american',
          partNumber: this.nagsPart.detail.partno,
          visible: false
        })
      }
    },
    addPart() {
      if (this.standalone) {
        this.$emit('add')
      } else {
        const tempPart = Object.assign({}, this.nagsPart)
        this.$emit(
          'addPart',
          tempPart,
          tempPart.children,
          'american',
          undefined,
          undefined,
          true
        )
      }
    },
    addAndLinkPart(cartItemId) {
      if (this.standalone) {
        this.$emit('addPart', {
          vendor: 'american',
          cartItemId
        })
      } else {
        const tempPart = Object.assign({}, this.nagsPart)
        tempPart.detail.american_order_item_id = cartItemId
        this.$emit('addPart', tempPart, tempPart.children)
      }
    },
    getAccountNameByNumber(number) {
      let name = null
      this.credentials.forEach((i) => {
        if (i.account_number === number) {
          name = i.name
        }
      })
      return name
    },
    recheckVendor() {
      this.$emit('recheckVendor', {
        vendor: 'american',
        accountNumber: this.accountNumber,
        accountName: this.getAccountNameByNumber(this.accountNumber)
      })
    },
    async getCredentials() {
      const { data } = await Api.get(`/api/shop/${this.shopId}/getVendorCredentials`)
      this.allCredentials = data
    }
  },
  components: {
    ResultsTable
  }
}
</script>

<style lang="scss">
.vendor-results-dialog {
  @apply t-font-sans;
  .el-dialog__body {
    @apply t-p-0;
  }
}
</style>
