<template>
  <el-tabs v-model="selectedVendor" tab-position="left" class="t-pl-6">
    <el-tab-pane
      v-for="v in vendors"
      :key="v"
      :name="v.toLowerCase()"
      stretch>
      <span slot="label">{{ v }}
        <span
          v-if="vendorsItemsCount[v.toLowerCase()] > 0"
          class="t-text-xs t-text-gray-700 t-px-2 t-py-1 t-rounded-full t-ml-2"
          :class="badgeClass(v.toLowerCase())">
          {{ vendorsItemsCount[v.toLowerCase()] > 99 ? '99+' : vendorsItemsCount[v.toLowerCase()] }}
        </span>
      </span>
      <div
        v-loading="itemsFetching"
        v-if="v.toLowerCase() === selectedVendor"
        class="t-pl-2 t-pr-6"
        style="min-height: 150px; max-height: 500px; overflow-y: auto;">
        <div v-if="!itemsFetching">
          <div v-if="items.length > 0">
            <div v-if="['mygrant', 'pgw', 'american', 'igc'].includes(selectedVendor)">
              <div v-for="(its, index) in itemsByAccountNumber" :key="index">
                <el-divider content-position="left">
                  <span class="t-text-gray-700 t-font-normal">
                    Account {{ its[0].accountNumber }}
                    <span v-if="its[0].accountName">
                       - {{ its[0].accountName }}
                    </span>
                  </span>
                </el-divider>
                <cart-item
                  v-for="i in its"
                  :key="i.id"
                  :item="i"
                />
              </div>
            </div>
            <div v-else>
              <div v-for="(its, index) in itemsByAccountUsername" :key="index">
                <el-divider content-position="left">
                  <span class="t-text-gray-700 t-font-normal">
                    Account {{ its[0].accountUsername }}
                  </span>
                </el-divider>
                <cart-item
                  v-for="i in its"
                  :key="i.id"
                  :item="i"
                />
              </div>
            </div>
          </div>
          <div v-else class="t-text-center t-pt-4">Cart is empty</div>
        </div>
      </div>
      <div
        v-if="items.length > 0"
        class="t-flex t-items-end t-justify-end t-mt-4 t-mb-5 t-pr-6">
        <span class="t-pr-4 t-text-lg">Total:</span>
        <span class="t-text-xl t-font-bold">${{ orderTotal }}</span>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import * as R from 'ramda'
import {
  shoppingCartActions,
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'
import CartItem from './CartItem'

export default {
  data: () => ({
    vendors: [
      'Mygrant',
      'IGC',
      'PGW',
      'Pilkington',
      // 'American',
      'NM',
      'AGD'
    ]
  }),
  computed: {
    items () {
      return this.$store.state.shoppingCart.items.data
    },
    itemsByAccountNumber () {
      return R.groupBy(i => i.accountNumber, this.items)
    },
    itemsByAccountUsername () {
      return R.groupBy(i => i.accountUsername, this.items)
    },
    selectedVendor: {
      get () {
        return this.$store.state.shoppingCart.items.selectedVendor
      },
      set (value) {
        return this.$store.commit(
          shoppingCartMutations.items.setSelectedVendor,
          value
        )
      }
    },
    vendorsItemsCount () {
      return this.$store.state.shoppingCart.items.vendorsItemsCount
    },
    itemsFetching () {
      return this.$store.state.shoppingCart.items.itemsFetching
    },
    selectedItemIds () {
      return this.$store.state.shoppingCart.items.selectedItemIds
    },
    orderTotal () {
      let total = 0

      let priceProp = 'priceBuyer'
      if (['agd', 'nm', 'pilkington'].includes(this.selectedVendor)) {
        priceProp = 'unitPrice'
      }

      if (this.selectedVendor === 'american') {
        priceProp = 'itemPrice'
      }

      this.items.forEach(i => {
        this.selectedItemIds.forEach(id => {
          if (i.id === id) {
            total += parseFloat(i[priceProp]) * i.quantity
          }
        })
      })

      return Math.round(total * 100) / 100
    }
  },
  watch: {
    selectedVendor: function (value) {
      this.clearSelectedItemIds()
      this.getItems()
      this.getItemsCount()
    }
  },
  methods: {
    getItems () {
      return this.$store.dispatch(shoppingCartActions.getItems)
    },
    getItemsCount () {
      return this.$store.dispatch(shoppingCartActions.getVendorsItemsCount)
    },
    clearSelectedItemIds () {
      return this.$store.dispatch(shoppingCartActions.clearSelectedItemIds)
    },
    badgeClass (vendor) {
      const badgeClassObj = {
        agd: 't-bg-orange-300',
        igc: 't-bg-yellow-300',
        mygrant: 't-bg-blue-300',
        nm: 't-bg-green-400',
        pgw: 't-bg-red-300',
        pilkington: 't-bg-green-300'
        // american: 't-bg-orange-300',
      }

      return badgeClassObj[vendor] || ''
    }
  },
  components: {
    CartItem
  }
}
</script>
