import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class EmailHistory extends ObjectTracker {
  id = -1
  sent = ''
  destination = ''
  email_type = ''
  object_id = -1
  shop_id = -1
  user_id = -1
  email_subject = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, email_type, object_id, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/shop/${shopId}/email_history/${email_type}/${object_id}`}).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new EmailHistory(val))
      })
      callbackFunction(objects)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
