var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-bg-white t-rounded t-w-full t-text-gray-700 t-overflow-y-auto"
    },
    [
      _c("Header", { attrs: { shopId: _vm.shopId } }),
      _c("OutstandingBalances", {
        attrs: {
          groupBy: _vm.groupBy,
          totalBalance: _vm.totalBalance,
          ninetyPlusBalance: _vm.ninetyPlusBalance,
          sixtyNinetyBalance: _vm.sixtyNinetyBalance,
          thirtySixtyBalance: _vm.thirtySixtyBalance,
          zeroThirtyBalance: _vm.zeroThirtyBalance,
          grandTotals: ""
        }
      }),
      _vm.groupBy === "age"
        ? [
            _vm.ninetyPlus && _vm.ninetyPlus.invoices.length
              ? _c("InvoicesSection", {
                  attrs: {
                    data: _vm.ninetyPlus,
                    name: "91+ days",
                    shopId: _vm.shopId,
                    groupBy: _vm.groupBy,
                    expandPayments: _vm.expandPayments
                  }
                })
              : _vm._e(),
            _vm.sixtyNinety && _vm.sixtyNinety.invoices.length
              ? _c("InvoicesSection", {
                  attrs: {
                    data: _vm.sixtyNinety,
                    name: "61 - 90 days",
                    shopId: _vm.shopId,
                    groupBy: _vm.groupBy,
                    expandPayments: _vm.expandPayments
                  }
                })
              : _vm._e(),
            _vm.thirtySixty && _vm.thirtySixty.invoices.length
              ? _c("InvoicesSection", {
                  attrs: {
                    data: _vm.thirtySixty,
                    name: "31 - 60 days",
                    shopId: _vm.shopId,
                    groupBy: _vm.groupBy,
                    expandPayments: _vm.expandPayments
                  }
                })
              : _vm._e(),
            _vm.zeroThirty && _vm.zeroThirty.invoices.length
              ? _c("InvoicesSection", {
                  attrs: {
                    data: _vm.zeroThirty,
                    name: "Under 31 days",
                    shopId: _vm.shopId,
                    groupBy: _vm.groupBy,
                    expandPayments: _vm.expandPayments
                  }
                })
              : _vm._e()
          ]
        : _vm._e(),
      _vm.groupBy === "consumer"
        ? _vm._l(_vm.data.consumers, function(item, key) {
            return _c("InvoicesSection", {
              key: key,
              attrs: {
                data: item,
                name: item.name,
                shopId: _vm.shopId,
                groupBy: _vm.groupBy,
                expandPayments: _vm.expandPayments
              }
            })
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }