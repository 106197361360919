var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("span", { staticStyle: { float: "left", "font-weight": "bold" } }, [
        _vm._v(_vm._s(_vm.truncateString(_vm.Name)))
      ]),
      _c(
        "span",
        {
          staticStyle: { float: "right", color: "#8492a6", "font-size": "13px" }
        },
        [
          _c(
            "gb-tag",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { color: "green" }
            },
            [_vm._v(" $" + _vm._s(_vm.CurrentBalance) + " ")]
          )
        ],
        1
      ),
      _c("br")
    ]),
    _c("div", [
      _c("span", { staticStyle: { float: "left", color: "#8492a6" } }, [
        _vm._v(_vm._s(_vm.Classification))
      ]),
      _c(
        "span",
        {
          staticStyle: { float: "right", color: "#8492a6", "font-size": "13px" }
        },
        [_vm._v(_vm._s(_vm.AccountType))]
      ),
      _c("br")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }