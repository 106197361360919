<template>
  <el-dialog
    append-to-body
    top="50px"
    width="800px"
    :visible="visible"
    :before-close="handleClose"
    custom-class="create-update-catalog-item-dialog"
  >
    <span
      slot="title"
      class="t-flex t-items-center t-mt-[-4px] t-text-gray-700"
    >
      <i
        class="t-text-lg"
        :class="isCatalog ? 'el-icon-folder' : 'el-icon-document'"
      />
      <div class="t-ml-3">
        {{ id > 0 ? 'Edit' : 'Create' }}
        {{ isCatalog ? 'Catalog' : 'Item' }}
        Profile
      </div>
    </span>
    <LoadingSpinner v-if="fetching" />
    <el-form
      v-else
      ref="form"
      :model="form"
      inline
      label-position="top"
      hide-required-asterisk
      size="small"
      id="item-profile-form"
      class="t-py-6"
    >
      <CatalogForm
        v-if="isCatalog"
        :predefinedColors="predefinedCatalogColors"
        :number.sync="form.number"
        :color.sync="form.color"
        :description.sync="form.description"
      />
      <ItemForm
        v-else
        :predefinedColors="predefinedItemColors"
        :id="id"
        :internalId="form.internalId"
        :color.sync="form.color"
        :number.sync="form.number"
        :nagsId.sync="form.nagsId"
        :brand.sync="form.brand"
        :shortCode.sync="form.shortCode"
        :description.sync="form.description"
        :zone.sync="form.zone"
        :listPrice.sync="form.listPrice"
        :retailListPrice.sync="form.retailListPrice"
        :retailMaterial.sync="form.retailMaterial"
        :retailLabor.sync="form.retailLabor"
        :upc.sync="form.upc"
        :sku.sync="form.sku"
        :picture.sync="form.picture"
        :qrCode.sync="form.qrCode"
        :isActive.sync="form.isActive"
        :isInventoryTracked.sync="form.isInventoryTracked"
        :notes.sync="form.notes"
        :autoSourceNoBuy.sync="form.autoSourceNoBuy"
      />

      <div
        class="t-border-0 t-border-b t-border-solid t-border-gray-200 t-mt-4
          t-mb-6"
      />

      <div class="t-flex t-px-8 t-justify-between">
        <el-button
          icon="el-icon-arrow-left"
          type="info"
          size="small"
          @click="handleClose"
        >
          Close
        </el-button>
        <el-button
          type="primary"
          size="small"
          :loading="submitting"
          @click="handleSubmit"
        >
          {{ id > 0 ? 'Update' : 'Create' }}
        </el-button>
      </div>

    </el-form>
  </el-dialog>
</template>

<script>
import ItemForm from './ItemForm.vue'
import CatalogForm from './CatalogForm.vue'
import LoadingSpinner from '../../Helpers/LoadingSpinner.vue'

const form = {
  internalId: null,
  color: '',
  number: '',
  nagsId: '',
  brand: '',
  shortCode: '',
  description: '',
  zone: '',
  listPrice: null,
  retailListPrice: 0,
  retailMaterial: 0,
  retailLabor: 0,
  upc: '',
  sku: '',
  picture: '',
  qrCode: '',
  isActive: true,
  isInventoryTracked: false,
  notes: '',
  autoSourceNoBuy: false
}

export default {
  components: {
    ItemForm,
    CatalogForm,
    LoadingSpinner
  },
  props: {
    visible: Boolean,
    id: Number,
    isCatalog: Boolean,
    parentId: Number,
    organizationId: Number
  },
  data: function() {
    return {
      form: { ...form },
      predefinedCatalogColors: [
        '#FFFFFF', '#E5E7EB', '#D1D5DB', '#FECACA', '#FCA5A5', '#FED7AA', '#FDBA74', '#FDE68A',
        '#FCD34D', '#A7F3D0', '#6EE7B7', '#BFDBFE', '#93C5FD', '#C7D2FE', '#A5B4FC', '#DDD6FE',
        '#C4B5FD', '#FBCFE8', '#F9A8D4'
      ],
      predefinedItemColors: [
        '#9CA3AF', '#6B7280', '#F87171', '#EF4444', '#FB923C', '#F97316', '#FBBF24', '#F59E0B',
        '#34D399', '#10B981', '#60A5FA', '#3B82F6', '#818CF8', '#6366F1', '#A78BFA', '#8B5CF6',
        '#F472B6', '#EC4899'
      ],
      submitting: false,
      fetching: false
    }
  },
  watch: {
    visible: function(val) {
      if (val) {
        if (this.id) {
          this.getItem()
        }
      } else {
        this.form = { ...form }
      }
    }
  },
  methods: {
    getItem: async function() {
      try {
        this.fetching = true
        const { data } = await this.$unum.warehouse().get(`/items/${this.id}`)
        this.form = {
          ...this.form,
          ...data.data
        }
        this.fetching = false
      } catch {
        this.fetching = false
      }
    },
    handleClose: async function() {
      try {
        await this.$messageBox.confirm(
          'All the changes will be lost. Do you want to continue ?',
          {
            type: 'warning',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.$emit('closed')
                this.$emit('update:visible', false)
                this.$refs.form.clearValidate()
                done()
              } else {
                done()
              }
            }
          }
        )
      } catch {}
    },
    handleSubmit: async function() {
      try {
        this.submitting = true
        await this.$refs.form.validate()
        if (this.isCatalog) {
          if (this.id) {
            await this.$unum.warehouse().patch(`/catalogs/${this.id}`, {
              number: this.form.number,
              description: this.form.description,
              color: this.form.color
            })
            this.$emit('updated')
            this.$message.success('Catalog successfully updated')
          } else {
            const { data } = await this.$unum.warehouse().post('/catalogs', {
              organizationId: this.organizationId,
              parentId: this.parentId,
              number: this.form.number,
              description: this.form.description,
              color: this.form.color,
              autoSourceNoBuy: this.form.autoSourceNoBuy
            })
            this.$emit('created', {
              id: data.data.id,
              isCatalog: this.isCatalog
            })
            this.$message.success('Catalog successfully created')
          }
        } else {
          if (this.id) {
            await this.$unum.warehouse().patch(`/items/${this.id}`, this.form)
            this.$emit('updated')
            this.$message.success('Item successfully updated')
          } else {
            const { data } = await this.$unum.warehouse().post('/items', {
              organizationId: this.organizationId,
              parentId: this.parentId,
              ...this.form
            })
            this.$emit('created', {
              id: data.data.id,
              number: this.form.number,
              isCatalog: this.isCatalog
            })
            this.$message.success('Item successfully created')
          }
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          this.$message.error(err.response.data.message)
        }
      } finally {
        this.submitting = false
        this.$emit('update:visible', false)
      }
    }
  }
}
</script>

<style lang="scss">
.create-update-catalog-item-dialog {
  @apply t-font-sans;
  .el-dialog__header {
    @apply t-pl-6 t-pb-3 t-border-0 t-border-b t-border-solid;
  }
  .el-dialog__body {
    @apply t-p-0;
  }
  #item-profile-form {
    .el-form-item__label {
      @apply t-pb-1;
    }
    label {
      @apply t-font-medium t-text-gray-500 t-text-xs t-normal-case t-mb-0;
    }
    .el-form-item {
      @apply t-flex t-flex-col t-mb-3 t-mr-0 t-w-1/2;
    }
  }
}
</style>
