<template>
  <b-modal  ref='unsavedDialog' no-close-on-backdrop no-close-on-esc hide-header-close id="unsavedDialog" size="lg" title="Unsaved Changes Detected" header-bg-variant='primary' header-text-variant='light'>
    <div class="form-row">
      There are unsaved changes detected on this page. If you leave you may lose them.
    </div>
    <div slot="modal-footer" class="w-100">
      <a  href='javascript:void(0)' style="text-decoration: underline;" @click="proceed">Leave Without Saving</a>
      <button type='button' size='lg' class='btn btn-primary float-right' variant='primary' @click="stay">Stay</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['url'],
  methods: {
    proceed () {
      if (this.url.startsWith('/')) {
        var frontendUrl = this.url.replace('/frontend', '')
        var pageChanged = false
        try {
          if (frontendUrl === '/jobs/new' && this.$router.currentRoute.path === '/jobs/new') {
            this.$router.push('/jobs/-1')
            pageChanged = true
          }
        } catch (err) {}
        if (!pageChanged) {
          this.$router.push(frontendUrl)
        }
      } else if (this.url === 'refresh') {
        this.$router.go()
      } else {
        window.location.href = this.url
      }

      this.$store.dispatch('triggerGoToPage', '')
      this.$root.$emit('bv::hide::modal', 'unsavedDialog')
    },
    stay () {
      this.$store.dispatch('triggerGoToPage', '')
      this.$root.$emit('bv::hide::modal', 'unsavedDialog')
    }
  }
}
</script>

<style>

</style>
