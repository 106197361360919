var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "textarea"
    ? _c(
        "div",
        { staticClass: "data-row-formatted-value textarea" },
        [
          _c("el-scrollbar", { attrs: { "wrap-class": "scroll-data-row" } }, [
            _vm._v(" " + _vm._s(_vm.formatted) + " ")
          ])
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "data-row-formatted-value",
          class: {
            pre: _vm.pre,
            clickable: _vm.clickable,
            address: _vm.type === "address"
          },
          style: _vm.hiddenWidth && { width: "auto", height: 0, opacity: 0 },
          on: { click: _vm.click }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.fullValue) +
              " " +
              _vm._s(
                _vm.type === "kit-select" && _vm.value
                  ? "(" + _vm.value.type + ")"
                  : ""
              ) +
              " "
          ),
          _vm.extraValue ? _c("br") : _vm._e(),
          _vm._v(_vm._s(_vm.extraValue) + " ")
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }