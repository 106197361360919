var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "100px",
        width: "1200px",
        "custom-class": "view-payment-dialog",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      },
      on: { open: _vm.onOpen }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-blue-100 t-text-lg",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-bank-card" }),
          _c("span", { staticClass: "t-ml-3" }, [_vm._v("Payment details")])
        ]
      ),
      !_vm.shop || !_vm.paymentGroup
        ? _c("div", [
            _c(
              "div",
              { staticClass: "t-w-full t-h-32 t-flex t-justify-center" },
              [
                _c("div", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: true,
                      expression: "true"
                    }
                  ],
                  staticClass: "t-w-12"
                })
              ]
            )
          ])
        : _c("div", { staticClass: "t-pt-6 t-pb-10 t-px-10 t-bg-gray-200" }, [
            _c(
              "div",
              { staticClass: "t-mb-3 t-text-right" },
              [
                _vm.paymentGroup.x_action_type !== "Card"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-edit",
                          type: "warning",
                          size: "small"
                        },
                        on: {
                          click: function($event) {
                            _vm.editPaymentVisible = true
                          }
                        }
                      },
                      [_vm._v(" Edit ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-download",
                      type: "warning",
                      size: "small",
                      loading: !!_vm.loading
                    },
                    on: {
                      click: function($event) {
                        return _vm.getPdf("download")
                      }
                    }
                  },
                  [_vm._v(" Download ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-printer",
                      type: "warning",
                      size: "small",
                      loading: !!_vm.loading
                    },
                    on: {
                      click: function($event) {
                        return _vm.getPdf("print")
                      }
                    }
                  },
                  [_vm._v(" Print ")]
                ),
                _c(
                  "el-dropdown",
                  {
                    staticStyle: { margin: "0 10px" },
                    attrs: { "hide-on-click": "" },
                    on: { command: _vm.getPdf }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-share",
                          type: "warning",
                          size: "small",
                          loading: !!_vm.loading
                        }
                      },
                      [_vm._v(" Share ")]
                    ),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "job-page-actions-dropdown",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown"
                      },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { icon: "email", command: "email" } },
                          [_vm._v(" Email ")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { icon: "sms", command: "sms" } },
                          [_vm._v(" SMS ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-edit",
                      type: "danger",
                      size: "small"
                    },
                    on: { click: _vm.deletePaymentGroup }
                  },
                  [_vm._v(" Delete ")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                ref: "body",
                staticClass: "t-bg-white t-rounded t-shadow",
                staticStyle: { padding: "30px 60px" }
              },
              [
                _c("div", { staticClass: "t-flex t-items-center t-py-4" }, [
                  _vm.shop.logo.length > 0
                    ? _c("div", { staticClass: "t-w-56 t-mr-8" }, [
                        _c("img", {
                          staticClass: "t-w-full",
                          attrs: { src: _vm.shop.logo, alt: "shop logo" }
                        })
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "t-flex-grow t-text-right" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "t-text-xl t-font-medium t-text-gray-800 t-mb-2"
                      },
                      [_vm._v(" " + _vm._s(_vm.shop.name) + " ")]
                    ),
                    _c("div", { staticClass: "t-text-gray-600" }, [
                      _vm._v(_vm._s(_vm.shop.address))
                    ]),
                    _c("div", { staticClass: "t-text-gray-600" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.shop.address_city) +
                          ", " +
                          _vm._s(_vm.shop.address_state) +
                          " " +
                          _vm._s(_vm.shop.address_zip) +
                          " "
                      )
                    ])
                  ])
                ]),
                _c("el-divider"),
                _c(
                  "div",
                  {
                    staticClass:
                      "t-text-center t-text-xl t-text-gray-700 t-pb-4"
                  },
                  [
                    _vm._v(
                      " Payment Receipt #" + _vm._s(_vm.paymentGroup.id) + " "
                    )
                  ]
                ),
                _c("div", { staticClass: "t-flex t-mt-10 t-pb-8" }, [
                  _c(
                    "div",
                    { staticClass: "t-flex-grow" },
                    [
                      _c("info-row", {
                        attrs: { name: "Type", value: _vm.paymentGroup.type }
                      }),
                      _c("info-row", {
                        attrs: { name: "Date", value: _vm.date }
                      }),
                      _c("info-row", {
                        attrs: {
                          name: "X-action Type",
                          value: _vm.paymentGroup.x_action_type
                        }
                      }),
                      _c("info-row", {
                        attrs: {
                          name: "X-action Number",
                          value: _vm.paymentGroup.x_action_number
                        }
                      }),
                      _vm.paymentGroup.payment_account_name
                        ? _c("info-row", {
                            attrs: {
                              name: "Payment account",
                              value:
                                "Liabilities: " +
                                _vm.paymentGroup.payment_account_name
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "t-bg-green-500 t-w-56 t-flex t-flex-col t-items-center\n                 t-justify-center t-text-white h-36"
                    },
                    [
                      _c("div", { staticClass: "t-text-lg" }, [
                        _vm._v("Amount received")
                      ]),
                      _c("div", { staticClass: "t-text-3xl" }, [
                        _vm._v("$" + _vm._s(_vm.totalAmount))
                      ])
                    ]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "t-mt-10" },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.paymentGroup.payments,
                          "header-cell-class-name":
                            "t-bg-gray-200 t-text-gray-700"
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "Invoice Number", width: "150" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "/jobs/" + props.row.job_id,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(props.row.job_id) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "Customer" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.row.company_name
                                          ? props.row.company_name
                                          : (props.row.first_name || "") +
                                              " " +
                                              (props.row.last_name || "")
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "Vehicle" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _c("div", { staticClass: "t-break-normal" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          [
                                            props.row.year,
                                            props.row.make,
                                            props.row.model,
                                            props.row.style
                                          ].join(" ")
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "Memo" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _vm._v(" " + _vm._s(props.row.memo) + " ")
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "Invoice Amount", width: "150" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _vm._v(" $" + _vm._s(props.row.amount) + " ")
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
      _c("edit-payment", {
        attrs: {
          visible: _vm.editPaymentVisible,
          paymentGroup: _vm.paymentGroup
        },
        on: {
          close: function($event) {
            _vm.editPaymentVisible = false
          },
          updated: _vm.handlePaymentUpdated
        }
      }),
      _vm.paymentGroup
        ? _c("a", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            ref: "dl",
            attrs: { href: this.data, download: _vm.filename }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }