<template>
  <transition name="slide">
    <div class="scheduler-route-entry-details" v-show="routeEntryDetails">
      <div class="header">
        <h4>
          <div>{{ title }}</div>
          <span> {{ data.name }} </span>
        </h4>
        <div @click="closeWindow">
          <v-icon name="times" scale="0.8" />
        </div>
      </div>
      <div class="content">
        <table>
          <tbody>
            <tr v-if="data.g_address">
              <td><span>Address:</span></td>
              <td>
                {{ data.g_address.address }} <br />
                {{ data.g_address.city }}, {{ data.g_address.state }}
                {{ data.g_address.zip }}
              </td>
            </tr>
            <tr v-if="details.arrival">
              <td>
                <span>Arrival:</span>
              </td>
              <td>
                {{ details.arrival }}
              </td>
            </tr>
            <tr v-if="details.departure">
              <td>
                <span>Departure:</span>
              </td>
              <td>
                {{ details.departure }}
              </td>
            </tr>
            <tr v-if="details.parts && details.parts.length">
              <td>
                <span>Parts:</span>
              </td>
              <td>
                <route-entry-part-pickup-list :item="details" />
              </td>
            </tr>
            <tr v-if="details.notes">
              <td>
                <span>Notes:</span>
              </td>
              <td>
                {{ details.notes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </transition>
</template>

<script>
import { sync } from 'vuex-pathify'
import { getRouteEntryTitle } from '@/scripts/helpers/scheduler_v3.helpers'
import RouteEntryPartPickupList from './helpers/RouteEntryPartPickupList'
import * as R from 'ramda'

export default {
  components: {
    RouteEntryPartPickupList
  },
  computed: {
    routeEntryDetails: sync('sch_v3/routeEntryDetails'),
    title() {
      return getRouteEntryTitle(this.details)
    },
    details() {
      return this.routeEntryDetails || {}
    },
    data() {
      return R.pipe(
        R.reduceWhile(R.isNil, (_, v) => R.prop(v, this.details), null),
        R.defaultTo({})
      )(['tech', 'shop', 'vendor', 'route_tech_stop'])
    }
  },
  methods: {
    closeWindow() {
      this.routeEntryDetails = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerRouteEntryDetails.scss';
</style>
