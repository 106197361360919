<template>
  <el-table 
    :data='data' 
    size='mini'
    :loading='loading'
    >
    <el-table-column prop="zoho_ticket_number" label="ID" min-width='10'>
    </el-table-column>
    <el-table-column label="Created" min-width='20'>
      <template slot-scope="props">
        <div>
          {{getFormattedDate(props.row.created)}}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop='subject'
      label='Title'
      min-width='20'>
    </el-table-column>
    <el-table-column
      prop='description'
      label='Status'
      :show-overflow-tooltip='true'
      min-width='25'
    >
    </el-table-column>
    <el-table-column
      label='Status' min-width='15'>
      <template slot-scope="props">
        <gb-tag :hex="statusColor(props.row.status)">
          {{props.row.status}}
        </gb-tag>
      </template>
    </el-table-column>
    <el-table-column min-width='10'>
      <template slot-scope="props">
        <div>
          <el-button @click='openTicket(props.row.id)'>
            view
          </el-button>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import moment from 'moment'
import { call } from 'vuex-pathify'
import { statusColor } from '@/constants/support-ticket.config.js'
import { getDateFromIso } from '@/scripts/helpers/date.helpers.js'

export default {
  props: ['data', 'loading'],
  methods: {
    getFormattedDate(iso) {
      return getDateFromIso(iso)
    },
    openTicket: call('support/openTicket'),
    statusColor
  }
}
</script>

<style lang="scss" scoped>
.gb-tag {
  max-width: fit-content;
}
</style>