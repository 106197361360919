import Api from '@/services/Api'

export default class TextMessageToken {
  static async checkTokenForPayment (jobId, token) {
    return await Api.get(`/api/mobile/textMessageToken/${jobId}/${token}/payment`)
  }
  static async checkTokenForSignature (jobId, token, source) {
    return await Api.get(`/api/mobile/textMessageToken/${jobId}/${token}/signature/${source}`)
  }

  static async consumeTokenAndNotify (jobId, token, source, shopId) {
    return await Api.post(`/api/mobile/textMessageToken/consume/${shopId}/${jobId}/${token}/${source}`)
  }
}