/* eslint-disable prefer-const */
import Vue from 'vue'

Vue.directive('role', {
  inserted(el, { value }, vnode) {
    console.log(vnode.context.$permissions)
    if (value) {
      let hasPermission = vnode.context.$store.getters.hasApiPermission(value)
      if (!hasPermission) {
        el.parentNode.removeChild(el)
      }
    } else {
      console.error('You must specify a permission ID')
    }
  }
})
