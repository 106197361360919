var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "consumer-info" }, [
    _c(
      "div",
      { staticClass: "consumer-data" },
      [
        _c(
          "div",
          { staticClass: "title-box" },
          [
            _vm.value.is_commercial || _vm.value.is_insurance
              ? _c("gb-editable-data-row", {
                  attrs: {
                    value: _vm.value.company_name,
                    "hide-label": true,
                    editable: "",
                    label: "Company Name",
                    size: "normal",
                    options: { capitalize: true }
                  },
                  on: { edit: _vm.edit }
                })
              : _c("h6", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(_vm.name) + " ")
                ]),
            _vm.value.id
              ? _c("div", { staticStyle: { margin: "0px 10px" } }, [
                  _vm._v("Customer Id: " + _vm._s(_vm.value.id))
                ])
              : _vm._e()
          ],
          1
        ),
        _c("gb-data-rows", {
          attrs: { schema: _vm.schema, data: _vm.value },
          on: { edit: _vm.editField }
        })
      ],
      1
    ),
    _c("div", { staticClass: "consumer-meta" }, [
      _c("div", { staticClass: "avatar" }, [
        _c("span", [_vm._v(_vm._s(_vm.initials))])
      ]),
      _vm.value.is_insurance
        ? _c(
            "div",
            { staticClass: "consumer-insurance-buttons" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", tabindex: "-1" },
                  on: { click: _vm.setSgc }
                },
                [_vm._v(" SGC ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", tabindex: "-1" },
                  on: { click: _vm.setLynx }
                },
                [_vm._v(" LYNX ")]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }