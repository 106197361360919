<template>
  <div class="t-bg-white t-rounded-md">
    <div
      v-if="fetching"
      class="t-h-12 t-rounded t-flex t-justify-center"
    >
      <div v-loading="true"></div>
    </div>
    <el-table
      :data="invoices"
      class="t-rounded"
      size="small"
      :row-class-name="tableRowClassName"
      ref="table"
    >
      <el-table-column width="55">
        <template slot-scope="props">
          <el-checkbox
            :key="props.row.job_id"
            :checked="!!selectedInvoices.find(i => i.job_id === props.row.job_id)"
            @change="checkboxChanged($event, props.row.job_id)"
          />
        </template>
      </el-table-column>
      <el-table-column>
        <template
          slot="header"
          slot-scope="props"
        >
          <el-input
            placeholder="Job id"
            :value="jobIdFilter"
            @input="$emit('update:jobIdFilter', $event)"
            clearable
            size="mini"
          />
        </template>
        <template slot-scope="props">
          <a
            :href="`/jobs/${props.row.job_id}`"
            target="_blank"
          >
            {{ props.row.job_id }}
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="referral_number">
        <template
          slot="header"
          slot-scope="props"
        >
          <el-input
            placeholder="Referral #"
            :value="referralFilter"
            @input="$emit('update:referralFilter', $event)"
            clearable
            size="mini"
          />
        </template>
      </el-table-column>
      <el-table-column prop="purchase_order_number">
        <template
          slot="header"
          slot-scope="props"
        >
          <el-input
            placeholder="PO/RO #"
            :value="poFilter"
            @input="$emit('update:poFilter', $event)"
            clearable
            size="mini"
          />
        </template>
        <template slot-scope="props">
          <span v-if="props.row.purchase_order_number && props.row.requisition_order_number">
            {{props.row.purchase_order_number}} / {{props.row.requisition_order_number}}
          </span>
          <span v-else-if="props.row.requisition_order_number">
            / {{props.row.requisition_order_number}}
          </span>
          <span v-else>
            {{props.row.purchase_order_number}}
          </span>
        </template>
      </el-table-column>
      <template v-if="consumer && consumer.is_insurance">
        <el-table-column label="Company name" prop="main_company_name" />
        <el-table-column width="160">
          <template
            slot="header"
            slot-scope="props"
          >
            <el-input
              placeholder="Customer name"
              :value="customerNameFilter"
              @input="$emit('update:customerNameFilter', $event)"
              clearable
              size="mini"
            />
          </template>
          <template slot-scope="props">
            <div class="t-break-normal">
              {{ props.row.secondary_customer_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Trading partner" prop="trading_partner" />
      </template>
      <el-table-column label="Invoice date">
        <template slot-scope="props">
          {{ formatDate(props.row.invoice_date) }}
        </template>
      </el-table-column>
      <el-table-column
        label="Days old"
        width="80px"
      >
        <template slot-scope="props">
          <span
            v-if="props.row.invoice_date !== '0000-00-00'"
            v-html="daysPassed(props.row.invoice_date)"
          />
        </template>
      </el-table-column>
      <el-table-column label="Total balance">
        <template slot-scope="props">
          $ {{ formatAmount(props.row.total_balance) }}
        </template>
      </el-table-column>
      <el-table-column label="Balance due">
        <template slot-scope="props">
          $ {{ formatAmount(props.row.remaining_balance) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: [
    'fetching',
    'invoices',
    'consumer',
    'jobIdFilter',
    'referralFilter',
    'poFilter',
    'customerNameFilter',
    'selectedInvoices'
  ],
  data: () => ({
    temp: []
  }),
  methods: {
    formatDate: function(date) {
      return moment(date).format('ll')
    },
    daysPassed: function(date) {
      const days = moment().diff(moment(date), 'days')
      let color = 't-text-green-600'
      if (days > 30 && days <= 60) {
        color = 't-text-yellow-600'
      } else if (days > 60 && days <= 90) {
        color = 't-text-orange-600'
      } else if (days > 90) {
        color = 't-text-red-600'
      }
      return `<span class="${color}">${days}</span>`
    },
    formatAmount: function(amount) {
      return Math.round(amount * 100) / 100
    },
    tableRowClassName({ row }) {
      if (this.consumer && row.consumer_id !== this.consumer.consumer_id) {
        return 't-bg-orange-100'
      }
      return ''
    },
    checkboxChanged: function(value, jobId) {
      this.$emit('checkboxChanged', { jobId, value })
    }
  }
}
</script>
