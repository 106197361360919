<template>
  <div class="input-group">
    <input-group-row
      v-model="primaryValue"
      :fields="fields"
      main
      :label="label"
    />
    <el-popover
      placement="right"
      trigger="click"
      v-if="value"
      :title="groupLabel"
    >
      <div class="group-rows" v-for="(row, idx) in value" :key="row.id">
        <el-tooltip content="Primary" :open-delay="500" placement="left">
          <el-radio :label="idx" v-model="radio" />
        </el-tooltip>
        <input-group-row
          :value="row"
          :fields="fields"
          :index="idx"
          @input="updateRow($event, idx)"
          @delete="deleteRow(idx)"
          :length="value.length"
        />
      </div>
      <el-button icon="el-icon-plus" size="mini" type="text" @click="addRow">
        ADD
      </el-button>
      <el-button icon="el-icon-caret-right" size="mini" slot="reference" />
    </el-popover>
  </div>
</template>

<script>
import * as R from 'ramda'
import InputGroupRow from './InputGroupRow/Index.vue'

export default {
  components: { InputGroupRow },
  data() {
    return {
      expand: false
    }
  },
  props: ['value', 'label', 'fields', 'groupLabel', 'id', 'groupId'],
  computed: {
    primaryValue: {
      get() {
        return R.propOr({}, this.id)(this.value)
      },
      set(v) {
        // this.$emit('input', R.set(R.lensProp(this.id), v, this.value))
        this.$emit(
          'input',
          R.set(
            R.compose(R.lensIndex(this.radio), R.lensProp(this.groupId)),
            v,
            this.value
          )
        )
      }
    },
    isValid() {
      return typeof this.value === 'object'
    },
    radio: {
      get() {
        return R.pipe(
          R.propOr([], this.groupId),
          R.findIndex(R.equals(this.primaryValue)),
          R.unless(R.lte(0), R.always(0))
        )(this.value)
      },
      set(value) {
        this.$emit(
          'input',
          this.value.map((v, idx) => ({ ...v, primary: idx === value }))
        )
      }
    }
  },
  mounted() {
    // if (!this.value.length) {
    //   this.$emit('input', R.append({ primary: true })(this.value))
    // }
  },
  methods: {
    updateRow(val, idx) {
      this.$emit('input', R.set(R.lensIndex(idx), val, this.value))
    },
    addRow() {
      this.$emit('input', R.append({ primary: true })(this.value))
    },
    deleteRow(idx) {
      const values = R.pipe(
        R.remove(idx, 1),
        R.unless(
          R.find(R.prop('primary')),
          R.over(R.lensIndex(0), R.set(R.lensProp('primary'), true))
        )
      )(this.value)
      this.$emit('input', values)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./InputGroup.scss";
</style>

<style lang="scss">
.input-group {
  .el-radio {
    margin: 0 $padding-xs 0 0;
    height: 14px;
    span:last-child {
      display: none;
    }
  }
}
</style>
