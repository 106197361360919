var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-mt-4" }, [
    _c("div", { staticClass: "t-mb-2 t-flex t-items-center t-pl-6 t-pr-4" }, [
      _c("i", { staticClass: "el-icon-document-copy t-text-base" }),
      _c("div", { staticClass: "t-flex-grow t-ml-3 t-cursor-default" }, [
        _vm._v("Channels")
      ]),
      _c("i", {
        staticClass:
          "el-icon-plus t-cursor-pointer t-p-1 hover:t-bg-gray-300\n             t-rounded t-text-base",
        on: { click: _vm.createChannel }
      })
    ]),
    _vm.loading
      ? _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "t-h-12 t-mt-8",
          attrs: { "element-loading-background": "#F7FAFC" }
        })
      : _c(
          "div",
          _vm._l(_vm.channels, function(i) {
            return _c(
              "div",
              {
                key: i.sid,
                staticClass: "t-pl-12 t-py-1 t-pr-3 t-cursor-pointer t-flex",
                class: [
                  { "t-bg-blue-500 t-text-blue-100": _vm.isSelected(i.sid) },
                  { "hover:t-bg-gray-300": !_vm.isSelected(i.sid) }
                ],
                on: {
                  click: function($event) {
                    return _vm.open(i.sid)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "t-w-6 t-text-center t-flex-shrink-0" },
                  [
                    i.isPrivate
                      ? _c("i", { staticClass: "el-icon-lock" })
                      : _c("span", [_vm._v("#")])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "t-flex-grow t-ml-1",
                    class: {
                      "t-mr-8": _vm.unreadMessagesCount[i.uniqueName] < 1
                    }
                  },
                  [_vm._v(" " + _vm._s(i.uniqueName) + " ")]
                ),
                _vm.unreadMessagesCount[i.uniqueName] > 0
                  ? _c("div", { staticClass: "t-flex-shrink-0 t-ml-3" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "t-flex t-items-center t-justify-center t-h-5 t-w-5 t-text-xs\n                 t-rounded-full t-bg-indigo-600 t-text-indigo-100"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.unreadMessagesCount[i.uniqueName]) +
                              " "
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            )
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }