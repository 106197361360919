<template>
  <div
    class="gb-card collapse-parent"
    :class="{ 'gb-card-collapsed': collapsed }"
  >
    <div class="card-content-wrapper">
      <div class="card-content">
        <div class="header" v-if="!hideHeader">
          <div class="card-name" @click="collapsed = !collapsed">
            <i :class="`material-icons-outline md-${mdi}`" v-if="mdi" />
            <v-icon :name="icon" v-if="icon" />
            <div>{{ title }}</div>
          </div>
          <slot name="collapsed-info" v-if="collapsed" />
          <slot name="title" v-else />
          <div class="actions">
            <md-circle-button
              v-if="collapsed"
              icon="expand_more"
              size="normal"
              @click="collapsed = !collapsed"
            />
            <slot name="actions" v-else />
          </div>
        </div>
        <collapse-top-transition>
          <div v-show="!collapsed">
            <slot />
          </div>
        </collapse-top-transition>
        <collapse-top-transition>
          <div class="footer" v-if="$slots.footer && !collapsed">
            <slot name="footer" />
          </div>
        </collapse-top-transition>
      </div>
      <div v-if="!collapsed">
        <slot name="side-area" />
      </div>
    </div>
    <collapse-top-transition>
      <div v-if="!collapsed">
        <slot name="bottom-area" />
      </div>
    </collapse-top-transition>

    <div class="gb-card-confirm" v-if="confirmation">
      <div class="gb-card-confirm-content">
        <div class="gb-card-confirm-title">{{ confirmation.message }}</div>
        <div class="gb-card-confirm-actions">
          <el-button size="mini" @click="resolve(false)">No</el-button>
          <el-button type="primary" size="mini" @click="resolve(true)">
            Yes
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollapseTopTransition from '@/components/Helpers/transitions/CollapseTopTrans.vue'

export default {
  props: {
    icon: String,
    title: String,
    mdi: String,
    startCollapsed: Boolean,
    hideHeader: Boolean
  },
  data() {
    return {
      confirmation: null,
      collapsed: false
    }
  },
  components: { CollapseTopTransition },
  methods: {
    confirm(message) {
      const self = this

      return new Promise((resolve, reject) => {
        self.confirmation = {
          message,
          resolve,
          reject
        }
      })
    },
    resolve(v) {
      if (v) {
        this.confirmation.resolve()
      } else {
        this.confirmation.reject()
      }
      this.confirmation = null
    }
  },
  created() {
    if (this.startCollapsed) {
      this.collapsed = true
    }
  }
}
</script>

<style lang="scss" scoped>
.gb-card {
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-1;
  border-radius: 6px;
  background: #fafafa;
  width: 100%;
  position: relative;
  overflow: hidden;
  .card-content-wrapper {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }
  .card-content {
    flex-grow: 1;
    padding: $padding-md $padding-md 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    .gb-card-content {
      overflow: hidden;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $padding-sm;
    height: 24px;
    width: 100%;
    .card-name {
      display: flex;
      align-items: center;
      font-weight: 600;
      letter-spacing: 0.5px;
      font-size: $font-xl;
      > div {
        margin-left: $padding-xs;
      }
      flex-shrink: 0;
      cursor: pointer;
    }
    .title {
      overflow: hidden;
      width: 100%;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-right: $padding-md;
    }
  }
  .actions {
    color: #888;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    > * {
      margin-left: $padding-sm;
    }
  }
  .footer {
    margin-top: $padding-sm;
    display: flex;
    justify-content: flex-end;
  }
  .gb-card-confirm {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #36363649;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    .gb-card-confirm-content {
      box-shadow: $box-shadow-1;
      padding: $padding-md;
      background: #fff;
      border-radius: 6px;
      width: 50%;
      max-width: 300px;
      text-align: center;
      .gb-card-confirm-title {
        font-size: 14px;
        margin-bottom: $padding-md;
      }
      .gb-card-confirm-actions {
        display: flex;
        .el-button {
          width: 100%;
        }
      }
    }
  }
  &.gb-card-collapsed {
    height: auto;
    .card-content-wrapper {
      padding-bottom: 4px;
    }
  }
}
</style>

<style lang="scss">
.gb-card {
  .el-button.el-button--text.gb-action-button {
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .gb-card-divider {
    width: calc(100% + 32px);
    height: 1px;
    background: $grayscale-5;
    margin: $padding-md (-$padding-md);
  }
}
</style>
