<template>
  <div
    class="t-flex t-px-4"
    :class="{ 't-justify-end': message.author === username }"
  >
    <div
      class="t-px-2 t-py-1 t-rounded-md t-text-gray-700 t-shadow-sm
             t-border-solid t-border t-break-normal"
      :class="[
        { 't-bg-blue-100 t-border-blue-200': message.author === username },
        { 't-bg-gray-100 t-border-gray-300': message.author !== username },
        { 't-max-w-xl': !infoDrawerIsOpen },
        { 't-max-w-xs': infoDrawerIsOpen }
      ]"
    >
      <div v-if="message.author !== username" class="t-font-semibold t-mb-1">
        <i class="el-icon-user"></i>
        {{ message.author }}
      </div>
      <div v-if="message.type === 'text'">
        {{ message.body }}
      </div>
      <div v-else>
        <div v-if="filetype === 'image'" class="t-mb-2 t-text-center">
          <div v-if="url">
            <a :href="url" target="_blank">
              <img
                v-images-loaded.on.done="imageLoaded"
                ref="image"
                :src="url"
                :alt="message.media.filename"
                class="chat-message-image"
              >
            </a>
          </div>
        </div>
        <div
          class="t-flex t-items-stretch t-bg-white t-mt-1 t-rounded-md
                 t-shadow-sm">
          <div
            class="t-flex t-items-center t-px-3 t-text-xl t-text-blue-100
                   t-bg-blue-500 t-rounded-l-md">
            <i v-if="filetype === 'image'" class="el-icon-picture-outline-round"/>
            <i v-else-if="filetype === 'video'" class="el-icon-video-camera"/>
            <i v-else-if="filetype === 'audio'" class="el-icon-video-play"/>
            <i
              v-else-if="filetype === 'text' || filetype === 'application'"
              class="el-icon-document"
            />
          </div>
          <div
            class="t-px-3 t-py-2 t-border-0 t-border-l t-border-r t-border-solid
                   t-border-gray-300 t-flex-grow t-truncate">
            {{ message.media.filename }}
          </div>
          <div
            class="t-flex t-items-center t-px-3 t-text-lg t-rounded-r-md
                   t-cursor-pointer hover:t-bg-green-500 hover:t-text-white"
            @click="download">
            <i class="el-icon-download"></i>
          </div>
        </div>
      </div>
      <div class="chat-message-time t-text-gray-600 t-text-right t-mt-1">
        {{ sendTime }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import imagesLoaded from 'vue-images-loaded'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  directives: {
    imagesLoaded
  },
  props: ['message'],
  data: () => ({
    url: null
  }),
  computed: {
    username: function() {
      return this.$store.state.user.username
    },
    sendTime: function() {
      return moment(this.message.dateUpdated).format('h:mm A')
    },
    infoDrawerIsOpen: function() {
      return this.$store.state.chat.channelInfoDrawerIsOpen
    },
    filetype: function() {
      return (this.message.media && this.message.media.contentType.split('/')[0]) || null
    }
  },
  created: async function() {
    if (this.message.media) {
      this.url = await this.getUrl()
    }
  },
  methods: {
    getUrl: function() {
      return this.message.media.getContentTemporaryUrl()
    },
    download: async function() {
      const url = await this.getUrl()
      const res = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob'
      })
      var fileURL = window.URL.createObjectURL(new Blob([res.data]))
      var fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', this.message.media.filename)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    imageLoaded: function() {
      if (this.$refs.image) {
        const height = this.$refs.image.clientHeight
        this.$emit('scrollDown', height)
      }
    },
    openPreview: async function() {
      const url = await this.getUrl()
      this.$store.commit(
        chatTypes.namespace + chatTypes.mutations.setImagePreviewUrl,
        url
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-message-time {
  font-size: 10px;
}
.chat-message-image {
  max-width: 300px;
  max-height: 300px;
}
</style>
