<template>
  <el-dialog
    :visible="visible"
    :before-close="close"
    width="900px"
    custom-class="vendor-results-dialog"
    append-to-body
  >
    <span slot="title" class="t-text-xl t-text-gray-800">
      {{ vendorName }} results
    </span>
    <div class="t-mt-2 t-p-5 t-shadow" :class="headerBackgroundColor">
      <div class="t-flex">
        <div class="t-flex-1 t-text-gray-800">
          <div class="t-text-base">
            Part
            <span class="t-text-indigo-800 t-font-medium">
              {{ nagsPart.detail.partno }}
            </span>
          </div>
          <div class="t-text-085">{{ nagsPart.detail.dsc }}</div>
        </div>
        <el-tooltip
          v-if="!warehouse"
          class="item"
          effect="dark"
          content="Add to parts list"
          placement="top"
        >
          <button
            v-if="
              returnedPart &&
              !returnedPart.invalidMaterial &&
              returnedPart.UnitPrice > 0 &&
              priceFromLevels &&
              priceLevelSelected
            "
            class="t-border-none t-rounded-full hover:t-bg-purple-600
              hover:t-text-white t-bg-white t-cursor-pointer
              t-text-purple-700 t-text-base t-font-medium t-self-center
              t-px-3 t-py-1"
            @click="addPart"
          >
            ${{ priceFromLevels }}
          </button>
        </el-tooltip>
      </div>
    </div>
    <div class="t-p-5">
      <div class="t-flex t-items-center">
        <div class="t-flex-grow">Total {{ results.length }} results</div>
        <div v-if="credentials.length > 1">
          <el-select
            v-model="accountNumber"
            class="t-ml-4"
            placeholder="Select account"
            size="mini"
          >
            <el-option
              v-for="item in credentials"
              :key="item.id"
              :label="
                item.username + (item.name ? ' - ' + item.name : '')
              "
              :value="item.username"
            />
          </el-select>
        </div>
      </div>
      <div v-if="loading" v-loading="true" class="t-h-32"></div>
      <div v-else-if="Object.keys(parts).length > 0">
        <div v-for="(pts, locationName) in parts" :key="locationName">
          <el-divider>
            <span class="t-text-purple-700">{{ locationName }}</span>
          </el-divider>
          <results-table
            :vendor="vendor"
            :parts="pts"
            :shopId="shopId"
            :jobId="jobId"
            :status="status"
            :warehouse="warehouse"
            :username="accountNumber"
            @refresh-data="$emit('refresh-data')"
            @addAndLinkPart="addAndLinkPart($event)"
          />
        </div>
      </div>
      <p v-else class="t-text-center t-mt-3">No parts found</p>
    </div>
  </el-dialog>
</template>

<script>
import * as R from 'ramda'
import ResultsTable from './Table'
import Api from '@/services/Api'

export default {
  props: [
    'defaultCredentials',
    'jobId',
    'loading',
    'nagsPart',
    'priceFromLevels',
    'results',
    'shopId',
    'standalone',
    'status',
    'vendor',
    'visible',
    'warehouse'
  ],
  data: () => ({
    accountNumber: null,
    allCredentials: [],
    vendorCredential: {}
  }),
  watch: {
    accountNumber: async function(newVal, oldVal) {
      if (oldVal !== null) {
        this.recheckVendor()
      }
    }
  },
  computed: {
    returnedPart() {
      return this.results[0]
    },
    parts() {
      return R.groupBy(part => part.locationName, this.results)
    },
    priceLevelSelected() {
      const pl = this.$job.job.selected_price_level
      return pl && pl > 0
    },
    credentials() {
      const vendorFilter = i => i.vendor === this.vendor

      if (this.standalone) {
        return this.allCredentials.filter(vendorFilter)
      }

      return this.$store.state.partVendors.credentials.filter(vendorFilter) || []
    },
    vendorName() {
      const names = {
        agd: 'Auto Glass Direct',
        nm: 'Nielsen & Moller'
      }

      return names[this.vendor]
    },
    headerBackgroundColor() {
      const colors = {
        agd: 't-bg-orange-200',
        nm: 't-bg-green-400'
      }

      return colors[this.vendor]
    }
  },
  created() {
    if (this.standalone) {
      this.accountNumber = this.defaultCredentials
      this.standaloneCreated(true)
    }
  },
  methods: {
    async standaloneCreated() {
      await this.getCredentials()
    },
    close() {
      if (this.standalone) {
        this.$emit('close')
        return
      }

      this.$store.commit('partVendors/setSearchResultsVisible', {
        vendor: this.vendor,
        partNumber: this.nagsPart.detail.partno,
        visible: false
      })
    },
    addPart() {
      if (this.standalone) {
        this.$emit('add')
        return
      }

      const tempPart = Object.assign({}, this.nagsPart)

      this.$emit(
        'addPart',
        tempPart,
        tempPart.children,
        this.vendor,
        undefined,
        undefined,
        true
      )
    },
    addAndLinkPart(cartItemId) {
      this.$emit('addPart', {
        vendor: this.vendor,
        cartItemId
      })
    },
    recheckVendor() {
      this.$emit('recheckVendor', {
        vendor: this.vendor,
        username: this.accountNumber
      })
    },
    async getCredentials() {
      const { data } = await Api.get(`/api/shop/${this.shopId}/getVendorCredentials`)
      this.allCredentials = data
    }
  },
  components: {
    ResultsTable
  }
}
</script>

<style lang="scss" scoped>
.vendor-results-dialog {
  @apply t-font-sans;
  .el-dialog__body {
    @apply t-p-0;
  }
}
</style>
