export default class ObjectTracker {
  objectTrackerInitial = {} // append object tracker in case an object ever used initial property

  setInitial (initialObject) {
    for (let key of Object.keys(this)) {
      if (key !== 'objectTrackerInitial' && typeof initialObject[key] !== 'undefined' && initialObject[key] !== null) {
        this[key] = initialObject[key]
      }
    }
    Object.assign(this.objectTrackerInitial, this)

    // delete keys from objectTrackerInitial object, preventing circular references
    delete this.objectTrackerInitial.objectTrackerInitial
  }

  get cleanCheck () {
    // debugger
    let initialKeys = Object.keys(this.objectTrackerInitial)
    let currentKeys = Object.keys(this)

    for (var i = 0; i < initialKeys.length; ++i) {
      for (var j = 0; j < currentKeys.length; ++j) {
        if (initialKeys[i] === currentKeys[j] && initialKeys[i] !== 'id' && initialKeys[i] !== 'objectTrackerInitial' && !initialKeys[i].startsWith('_') && !changesToIgnore.includes(initialKeys[i])) {
          if (this.objectTrackerInitial[initialKeys[i]] !== this[currentKeys[j]]) {
            var secondaryCheck = false
            try {
              // 
              // 
              // 
              try {
                if (currentKeys[j] === 'vehicle_sub_model') {
                  // special condition due to unique vehicle sub model scenarios
                  if (this.objectTrackerInitial[initialKeys[i]] + '' === '0' && this[currentKeys[j]] + '' === '-2') {
                    continue
                  }
                }
              } catch (err) {}

              if (((typeof this.objectTrackerInitial[initialKeys[i]] === 'string' && typeof this[currentKeys[j]] === 'number') ||
              (typeof this.objectTrackerInitial[initialKeys[i]] === 'number' && typeof this[currentKeys[j]] === 'string')) &&
                parseFloat(this.objectTrackerInitial[initialKeys[i]]) === parseFloat(this[currentKeys[j]])) {
                secondaryCheck = true
              }

              if (((typeof this.objectTrackerInitial[initialKeys[i]] === 'number' && typeof this[currentKeys[j]] === 'boolean') ||
              (typeof this.objectTrackerInitial[initialKeys[i]] === 'boolean' && typeof this[currentKeys[j]] === 'number'))) {
                if ((this.objectTrackerInitial[initialKeys[i]] && this[currentKeys[j]]) ||
                (!this.objectTrackerInitial[initialKeys[i]] && !this[currentKeys[j]])) {
                  secondaryCheck = true
                }
              }
            } catch (err) {}
            if (!secondaryCheck) {
              try {
                if (this.objectTrackerInitial[initialKeys[i]] === '0000-00-00' && this[currentKeys[j]] === 'Invalid date') {
                  secondaryCheck = true
                }
              } catch (err) {}
            }

            if (!secondaryCheck) {
              
              
              
              return false // meaning this has changed somewhere along the line
            }
          }
        }
      }
    }

    return true
  }

  /**
   * @returns {Array} Array of changes that have been made from intial state
   */
  getChangesArray () {
    let initialKeys = Object.keys(this.objectTrackerInitial)
    let currentKeys = Object.keys(this)
    let changesArray = []
    for (var i = 0; i < initialKeys.length; ++i) {
      for (var j = 0; j < currentKeys.length; ++j) {
        if (initialKeys[i] === currentKeys[j]) {
          if (this.objectTrackerInitial[initialKeys[i]] !== this[currentKeys[j]]) {
            var tempObject = {}
            tempObject[initialKeys[i]] = this[currentKeys[j]]
            changesArray.push(tempObject)
          }
        }
      }
    }
    return changesArray
  }

  get getStrippedObject () {
    let tempObject = {}
    let curKeys = Object.keys(this)
    for (var i = 0; i < curKeys.length; i++) {
      if (!curKeys[i].toString().startsWith('_') && curKeys[i] != 'objectTrackerInitial') {
        tempObject[curKeys[i]] = this[curKeys[i]]
      }
    }
    return tempObject
  }
}

const changesToIgnore = ['customer_cancel', 'customer_reschedule']
