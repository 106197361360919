import gql from 'graphql-tag'
import { DOCUMENT_FRAGMENT } from '../fragments/docs.fragment'
import { JOBTAG_FRAGMENT } from '../fragments/tag.fragment'
import { JOBTAX_FRAGMENT } from '../fragments/tax.fragment'
import { NOTE_FRAGMENT } from '../fragments/note.fragment'
import { JOB_GB_VENDORS_FRAGMENT } from '../fragments/vendors.fragment'

export const CREATE_JOB_MUTATION = gql`
  mutation createJob($job: JobInput!) {
    createJob(job: $job) {
      id
    }
  }
`

export const SAVE_JOB_MUTATION = gql`
  mutation saveJob($job: JobInput!, $audit: AuditRowInput) {
    saveJob(job: $job, audit: $audit) {
      id
    }
  }
`

export const CREATE_DRAFT_MUTATION = gql`
  mutation createDraft($job: JobInput!) {
    createDraft(job: $job) {
      id
      consumer_id
      bill_to_id
    }
  }
`

export const SAVE_DRAFT_MUTATION = gql`
  mutation saveDraft($job: JobInput!, $audit: AuditRowInput) {
    saveDraft(job: $job, audit: $audit) {
      id
    }
  }
`

export const PUBLISH_DRAFT_MUTATION = gql`
  mutation publishDraft($id: ID!, $status: String!) {
    publishDraft(id: $id, status: $status) {
      id
    }
  }
`

export const SET_JOBTAXES_MUTATION = gql`
  mutation setJobtaxes($id: ID, $draft_id: ID, $taxes: [JobtaxInput]!) {
    setJobtaxes(id: $id, draft_id: $draft_id, taxes: $taxes) {
      ...JobtaxFields
    }
  }
  ${JOBTAX_FRAGMENT}
`

export const ADD_JOB_DOCUMENTS_MUTATION = gql`
  mutation addJobDocs($id: ID, $draft_id: ID, $documents: [DocumentInput]!) {
    addJobDocs(id: $id, draft_id: $draft_id, documents: $documents) {
      ...DocumentFields
    }
  }

  ${DOCUMENT_FRAGMENT}
`

export const DELETE_JOB_PIECE_MUTATION = gql`
  mutation deleteJobPiece(
    $id: ID
    $draft_id: ID
    $type: String!
    $model_id: ID!
  ) {
    deleteJobPiece(
      id: $id
      draft_id: $draft_id
      type: $type
      model_id: $model_id
    )
  }
`

export const ADD_JOB_GB_VENDORS_MUTATION = gql`
  mutation addJobGbVendor($id: ID, $draft_id: ID, $jobGbVendors: [JobGbVendorInput]!) {
    addJobGbVendor(id: $id, draft_id: $draft_id, jobGbVendors: $jobGbVendors) {
      ...JobGBVendorsFields
    }
  }

  ${JOB_GB_VENDORS_FRAGMENT}
`

export const DELETE_JOB_GB_VENDORS_MUTATION = gql`
  mutation deleteJobGbVendor(
    $id: ID
    $draft_id: ID
    $job_gb_vendor_type_id: ID!
    $job_gb_vendor_id: ID!
  ) {
    deleteJobGbVendor(
      id: $id
      draft_id: $draft_id
      job_gb_vendor_type_id: $job_gb_vendor_type_id
      job_gb_vendor_id: $job_gb_vendor_id
    )
  }
`
export const UPDATE_JOB_GB_VENDOR_COMMISSION = gql`
  mutation updateJobGbVendorCommission(
    $id: ID
    $draft_id: ID
    $jobGbVendors: [JobGbVendorInput]!
  ) {
    updateJobGbVendorCommission(
      id: $id
      draft_id: $draft_id
      jobGbVendors: $jobGbVendors
    ) {
      ...JobGBVendorsFields
    }
  }
  ${JOB_GB_VENDORS_FRAGMENT}
`

export const SET_JOBTAGS_MUTATION = gql`
  mutation setJobtags($id: ID, $draft_id: ID, $tags: [JobtagInput]!) {
    setJobtags(id: $id, draft_id: $draft_id, tags: $tags) {
      ...JobtagFields
    }
  }
  ${JOBTAG_FRAGMENT}
`

export const CREATE_NOTE_MUTATION = gql`
  mutation createNote($id: ID, $draft_id: ID, $note: NoteInput!) {
    createNote(id: $id, draft_id: $draft_id, note: $note) {
      ...NoteFields
    }
  }
  ${NOTE_FRAGMENT}
`
