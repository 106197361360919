var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.text && _vm.text !== "0"
      ? _c(
          "div",
          {
            class: {
              "job-card-info-block": true,
              strong: _vm.strong,
              small: _vm.small,
              solid: _vm.solid,
              clickable: _vm.clickable
            }
          },
          [
            _c("v-icon", { attrs: { name: _vm.icon, scale: "0.7" } }),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.noLower
                    ? _vm.text
                    : _vm.text.toLowerCase
                    ? _vm.text.toLowerCase()
                    : _vm.text
                )
              )
            ])
          ],
          1
        )
      : _vm._e(),
    _vm.secondary
      ? _c("div", { staticClass: "secondary" }, [_vm._v(_vm._s(_vm.secondary))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }