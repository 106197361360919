var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        "append-to-body": "",
        title: "Right Part search",
        "custom-class": "right-part-dialog",
        top: "120px",
        width: "1000px",
        visible: _vm.visible
      },
      on: { close: _vm.handleClose }
    },
    [
      _vm.detailsLoading
        ? _c(
            "div",
            {
              staticClass:
                "t-w-full t-h-32 t-flex t-items-center t-justify-center"
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: true,
                    expression: "true"
                  }
                ]
              })
            ]
          )
        : _c(
            "div",
            [
              _vm.details !== null ? _c("Details") : _vm._e(),
              _vm.hasAccess ? _c("Form") : _vm._e()
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }