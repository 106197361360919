export const routingConfigTable = [
  {
    label: 'Part pickups',
    type: 'checkbox',
    key: 'pickups',
    tooltip:
      "Use actual part pickup locations from job <i>purchase orders</i> vendor locations. Pickups and dropoffs will be optimized for a better routing balance. Please note, that if a job doesn't have a purchase order associated with it, the pickup location will default to the <i>shop location</i>."
  },
  {
    label: 'Reassign jobs',
    type: 'checkbox',
    key: 'reassign',
    tooltip:
      'By default, all the jobs that are currently assigned to a technician might be reassigned to a different technician for optimization. Unselect this option if you want to keep currently assigned job. Please note that the job order might still change'
  }
]

export const routingOptionsTable = [
  {
    label: 'Speed',
    type: 'radio',
    options: [
      {
        value: 'faster',
        label: 'Faster'
      },
      {
        value: 'fast',
        label: 'Fast'
      },
      {
        value: 'normal',
        label: 'Normal'
      },
      {
        value: 'slow',
        label: 'Slow'
      },
      {
        value: 'very slow',
        label: 'Very slow'
      }
    ],
    key: 'speed',
    tooltip:
      "It's usually a good idea to simulate slower traffic to ensure you will never be late for a delivery. Estimated driving times tend to be more optimistic than in reality, and using this parameter will slow down driving speed. The default value is <strong>faster</strong>. Each step slows down the speed by 25%, so <strong>fast</strong> would be 25% slower from default, while <strong>very slow</strong> would be twice as slow."
  },
  {
    label: 'Minimum visits per vehicle',
    type: 'number',
    key: 'min_visits_per_vehicle',
    disable: ['visit_balance_coefficient', 'min_vehicles'],
    tooltip:
      'We will optimize your routes by finding the minimum total travel time. This may lead to some vehicles being assigned only a few visits or not being assigned at all. This may not always be desired because some businesses prefer to spread out visits/orders across all drivers. This parameter allows you to specify a minimum number of visits for each vehicle. '
  },
  {
    label: 'Balance (beta)',
    type: 'checkbox',
    disable: ['visit_balance_coefficient', 'min_vehicles'],
    key: 'balance',
    tooltip:
      'If assigning equal number of orders across your vehicles is not enough and you want them to also have balanced driving shifts across your fleet, you can use this parameter. It <em>should</em> only be used in conjunction with <code>min_visits_per_vehicle</code>. This parameter will instruct the algorithm to keep the variance across driver shift times as small as possible.'
  },
  {
    key: 'visit_balance_coefficient',
    label: 'Visit balance coefficient',
    disable: ['min_visits_per_vehicle', 'balance'],
    type: 'slider',
    config: { min: 0, max: 1, step: 0.05 },
    tooltip:
      'Use this parameter (a number between 0.0 and 1.0) to adjust relative balance levels so that you have control over how balance is traded off with routing efficiency. 0.0 gives you a route solution that minimizes overall driving time while 1.0 gives you a completely balanced route at the expense of routing efficiency. This parameter cannot be used together with the <code>balance</code> and <code>min_visits_per_vehicle</code> parameters.'
  },
  {
    label: 'Minimize technicians',
    type: 'checkbox',
    key: 'min_vehicles',
    tooltip:
      "Use this parameter if you are interested to minimize the number of vehicles you'd like to use to get all the jobs done. This allows you to figure out how many drivers you should call in. When using this parameter, make sure you provide ample vehicles in the input, so all the orders can be served. The idle vehicles will be empty in the solution."
  },
  {
    label: 'Shortest distance',
    type: 'checkbox',
    key: 'shortest_distance',
    tooltip:
      "By default we optimize by minimizing the total driving time and working hours. In some cases – due to time-windows – your drivers might have idle-time as it waits for the next time-window. By default we take this into account, which potentially could result in more miles driven, as we'd send the drive to another location first and coming back later. If you'd rather optimize purely on shortest distance, set this parameter to true."
  }
]
