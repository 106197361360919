<template>
  <div class="scheduler-generic-card" :style="cardStyle" @click="openDetails">
    <job-card-info-block
      v-if="icons[item.type]"
      :text="title"
      :icon="icons[item.type]"
      :solid="solidCards"
      strong
    />

    <scheduler-times-row :item="item" :solid="!solidCards" />
  </div>
</template>

<script>
import JobCardInfoBlock from './helpers/JobCardInfoBlock'
import SchedulerTimesRow from './helpers/SchedulerTimesRow'
import { get, sync } from 'vuex-pathify'
import * as R from 'ramda'

export default {
  components: { JobCardInfoBlock, SchedulerTimesRow },
  data() {
    return {
      openParts: false,

      icons: {
        start: 'play',
        pickup: 'cog',
        end: 'stop',
        appointment: 'calendar-check'
      }
    }
  },
  props: ['color', 'item', 'cardStyle'],
  computed: {
    routeEntryDetails: sync('sch_v3/routeEntryDetails'),
    solidCards: get('sch_v3/filters@solidCards'),
    title() {
      return R.cond([
        [R.path(['shop']), R.path(['shop', 'name'])],
        [R.path(['vendor']), R.path(['vendor', 'name'])],
        [R.path(['route_tech_stop']), R.path(['route_tech_stop', 'name'])],
        [R.T, R.always('Tech Home')]
      ])(this.item)
    }
  },
  methods: {
    openDetails() {
      this.routeEntryDetails = this.item
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerGenericCard.scss';
</style>

<style lang="scss">
.scheduler-generic-card {
  .job-card-info-block {
    font-size: $font-sm;
  }
}
</style>
