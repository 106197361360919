<template>
  <el-dialog
    :visible.sync="open"
    append-to-body
    @open="getFile"
    @closed="
      type = null;
      size = 0;
    "
    custom-class="gb-el-dialog file-preview-modal"
    :title="`Preview - ${label}`"
    top="75px"
  >
    <div class="container" v-if="doc" v-loading="loading">
      <div class="image" v-if="isImage">
        <el-image :src="doc.url" />
      </div>

      <div class="content" v-else-if="doc.url">
        <object :data="doc.url" v-if="isAttachment" />
        <empty-placeholder
          text="File is missing"
          icon="file"
          v-else-if="error"
        />
        <span v-else> Preview not available </span>
      </div>
      <div class="details">
        <file-details-table v-bind="doc" :type="type" :size="size" :dateFunc='dateFunc'/>
        <file-preview-actions :doc="doc" @close="$emit('input', false)" :noDelete='noDelete' :gcp='gcp' :deleteFunc="deleteFunc" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import pretty from 'prettysize'
import * as R from 'ramda'
import FileDetailsTable from './FileDetailsTable/Index'
import FilePreviewActions from './FilePreviewActions/Index'
import { documentsConfig } from '@/constants/documents_v2.config'

export default {
  props: ['value', 'doc', 'noDelete', 'gcp', 'dateFunc', 'deleteFunc'],
  components: {
    FileDetailsTable,
    FilePreviewActions
  },
  data() {
    return {
      loading: false,
      type: '',
      size: 0,
      error: null
    }
  },
  computed: {
    open: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    isImage() {
      return R.test(/image/)(this.type)
    },
    isAttachment() {
      return R.test(/(pdf|video)/)(this.type)
    },
    label() {
      return documentsConfig[this.doc?.document_type]?.label || this.doc?.document_type_name || ""
    }
  },
  methods: {
    async getFile() {
      try {
        this.loading = true
        const data = await axios.get(this.doc.url)
        this.type = R.path(['headers', 'content-type'])(data)
        this.size = pretty(R.path(['headers', 'content-length'])(data))
      } catch (error) {
        this.error = true
      }
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./FilePreviewModal.scss";
</style>
