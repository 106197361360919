import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default class Tech extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  name = ''
  commission = ''
  address = ''
  city = ''
  state = ''
  zip = ''
  phone = ''
  fax = ''
  email = ''
  notes = ''
  modified = -1
  shop_id = -1
  techside_username = ''
  techside_invitation_status = ''
  commission_flat_or_percent = 'flat'
  hide_completed_jobs = -1
  color = '#000'
  chip_commission = ''
  chip_commission_flat_or_percent = 'flat'
  require_tech_pickup = false
  g_address = null
  require_clock_in = false
  require_pre_inspection = false
  techside_hide_prices = false

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, callbackFunction) {
    Api({ url: `/api/shop/${shopId}/tech` }).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new Tech(val))
      })

      callbackFunction(objects)
    })
  }

  static async getOne (techId) {
    const { data } = await Api(`/api/tech/${techId}`)
    if (!data) {
      return null
    }
    return new Tech(data)
  }

  static getAllTechsideUsers (callbackFunction) {
    let foundTechsideUsers
    Api({ url: `/api/techsideUsers` }).then(res => {
      foundTechsideUsers = res.data
      callbackFunction(foundTechsideUsers)
    })
  }

  static updateTechsideUser (techsideUser, callbackFunction) {
    return Api.post(`/api/update/techsideUser/user`, techsideUser).then(res => {
      callbackFunction(res.status)
    })
  }

  static resetTSSSPassword (userObj, callbackFunction) {
    return Api.post(`/api/update/techsideUser/tspr`, {username: userObj.username}).then(res => {
      callbackFunction(res)
    })
  }

  static async getTextMessageInviteHistory (techId, limit = 0) {
    const { data } = await Api.get(`/api/tech/${techId}/textMessageInviteHistory?limit=${limit}`)
    return data
  }

  // GET ALL TECHS BY USER + FILTERS
  static getTechsByUserShops (page, limit, sortBy, sortDesc, techIdFilter, techNameFilter,
    techShopNameFilter, techEmailFilter, techPhoneFilter, successCallback, errorCallback) {
    Api({url: `/api/usertechs?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&techIdFilter=' + encodeURIComponent(techIdFilter) + '&techNameFilter=' + encodeURIComponent(techNameFilter) +
      '&techShopNameFilter=' + encodeURIComponent(techShopNameFilter) + '&techEmailFilter=' + encodeURIComponent(techEmailFilter) +
      '&techPhoneFilter=' + encodeURIComponent(techPhoneFilter)
    }).then(res => {
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  createUpdateTech (shopId, callbackFunction, errorCallbackFunction = null) {
    this.commission = UtilityMixin.methods.formatFloatDecimals(this.commission, 2, 2)
    this.chip_commission = UtilityMixin.methods.formatFloatDecimals(this.chip_commission, 2, 2)
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    return Api.post(`/api/shop/${this.shop_id}/tech/${this.id}`, this).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
