<template>
  <div>
    <div class="t-pl-6 t-h-14 t-border-solid t-border-0 t-border-b
              t-border-gray-200 t-flex t-items-center">
      <div class="t-text-base t-text-gray-700">Create a channel</div>
    </div>
    <div class="t-w-1/2 t-pl-6">
      <div class="t-pt-5">
        <div class="t-text-gray-700 t-font-semibold t-pb-1">Name</div>
        <el-input
          :prefix-icon="form.isPrivate ? 'fa fa-lock' : 'fa fa-hashtag'"
          v-model="form.name"
        />
      </div>
      <div class="t-pt-5 t-flex t-items-center">
        <div class="t-flex-grow t-pr-4">
          <div class="t-text-gray-700 t-font-semibold t-pb-1">
            Make private
          </div>
          <div v-if="!form.isPrivate">
            When a channel is set to private, it can only be viewed or joined
            by invitation.
          </div>
          <div v-else>
            <span class="t-font-semibold t-mr-1">This can't be undone.</span>
            <span>A private channel cannot be made public later on.</span>
          </div>
        </div>
        <el-switch
          v-model="form.isPrivate"
          active-color="#276749"
          inactive-color="#CBD5E0">
        </el-switch>
      </div>
      <div class="t-text-right t-pt-5">
        <button
          class="t-border-solid t-border t-border-gray-300 t-px-3 t-py-2
                 t-bg-white t-text-gray-700 t-rounded t-cursor-pointer
                 hover:t-border-blue-500 hover:t-text-blue-700"
          :class="{ 't-cursor-not-allowed': submitting }"
          :disabled="submitting"
          @click="create"
        >
          <i v-if="!submitting" class="el-icon-plus"></i>
          <i v-else class="el-icon-loading"></i>
          <span class="t-ml-2">Create</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { chatViews } from '@/constants/chat.config'
import { ChatService } from '@/services/ChatService'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  data: () => ({
    form: {
      name: '',
      isPrivate: false
    },
    submitting: false
  }),
  computed: {
    view: function() {
      return this.$store.state.chat.view
    }
  },
  watch: {
    view: function(value) {
      if (value === chatViews.create) {
        this.clear()
      }
    }
  },
  methods: {
    openChannel: function(channel) {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.openChannel,
        channel
      )
    },
    getUserChannels: function() {
      this.$store.dispatch(chatTypes.namespace + chatTypes.actions.getUserChannels)
    },
    create: async function() {
      if (!this.form.name || /\s/g.test(this.form.name)) {
        this.$message.warning('Channel name is required and no spaces allowed')
      } else {
        try {
          this.submitting = true
          const channel = await ChatService.createChannel({
            isPrivate: this.form.isPrivate,
            uniqueName: this.form.name
          })
          await ChatService.joinChannel(channel)
          this.openChannel(channel)
          this.getUserChannels()
          this.submitting = false
        } catch (err) {
          this.$message.error(err.message)
          this.submitting = false
        }
      }
    },
    clear: function() {
      this.form.name = ''
      this.form.isPrivate = false
    }
  }
}
</script>
