var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "t-font-sans",
      attrs: {
        "append-to-body": "",
        top: "100px",
        width: "1000px",
        "custom-class": "unit-adjustment-dialog",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "t-text-base t-text-gray-700",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("Adjust inventory")]
      ),
      _vm.loading
        ? _c("LoadingSpinner")
        : _c("div", { staticClass: "t-py-4" }, [
            _c("div", { staticClass: "t-flex t-space-x-12 t-px-6" }, [
              _c("div", { staticClass: "t-w-1/3" }, [
                _c(
                  "div",
                  { staticClass: "t-mb-1 t-text-xs t-italic t-text-gray-400" },
                  [_vm._v(" Number: ")]
                ),
                _c("div", { staticClass: "t-mt-2" }, [
                  _c("span", { staticClass: "t-font-medium" }, [
                    _vm._v(_vm._s(_vm.item.number))
                  ]),
                  _c("span", { staticClass: "t-text-xs t-text-gray-500" }, [
                    _vm._v(
                      " ( Starting: " +
                        _vm._s(_vm.availableCount) +
                        ", Adjusted: " +
                        _vm._s(_vm.form.quantity) +
                        ", Ending: " +
                        _vm._s(+_vm.form.quantity + _vm.availableCount) +
                        " ) "
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "t-flex" },
                [
                  _c(
                    "div",
                    { staticClass: "t-flex t-flex-col" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "t-mb-1 t-text-xs t-italic t-text-gray-400"
                        },
                        [_vm._v(" Quantity: ")]
                      ),
                      _c("el-input-number", {
                        attrs: { size: "small", min: -_vm.availableCount },
                        model: {
                          value: _vm.form.quantity,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "quantity", $$v)
                          },
                          expression: "form.quantity"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.form.quantity > 0
                    ? [
                        _c(
                          "div",
                          { staticClass: "t-flex t-flex-col" },
                          [
                            _c("div", { staticClass: "t-h-4 t-mb-1" }),
                            _c("v-icon", {
                              staticClass: "t-mt-2 t-mx-4",
                              attrs: { name: "times" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "t-flex t-flex-col t-w-36" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "t-mb-1 t-text-xs t-italic t-text-gray-400"
                              },
                              [_vm._v("Unit cost:")]
                            ),
                            _c("el-input-number", {
                              attrs: { controls: false, size: "small" },
                              model: {
                                value: _vm.form.cost,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "cost", $$v)
                                },
                                expression: "form.cost"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "t-flex t-flex-col" },
                          [
                            _c("div", { staticClass: "t-h-4 t-mb-1" }),
                            _c("v-icon", {
                              staticClass: "t-mt-2 t-mx-4",
                              attrs: { name: "equals" }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "t-flex t-flex-col" }, [
                          _c("div", { staticClass: "t-h-4 t-mb-1" }),
                          _c("div", { staticClass: "t-mt-1 t-text-base" }, [
                            _vm._v("$" + _vm._s(_vm.total))
                          ])
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ]),
            _c(
              "div",
              { staticClass: "t-mt-4 t-px-6" },
              [
                _c(
                  "div",
                  { staticClass: "t-mb-1 t-text-xs t-italic t-text-gray-400" },
                  [_vm._v(" Note: ")]
                ),
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "Enter adjustment note"
                  },
                  model: {
                    value: _vm.form.notes,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "notes", $$v)
                    },
                    expression: "form.notes"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "t-bg-blue-50 t-px-6 t-py-4 t-my-6 t-shadow t-text-gray-500"
              },
              [
                _c("div", { staticClass: "t-flex t-mb-2" }, [
                  _c("div", { staticClass: "t-w-1/3 t-text-right" }, [
                    _c(
                      "div",
                      {
                        staticClass: "t-mb-1 t-text-xs t-italic t-text-blue-400"
                      },
                      [_vm._v(" Changes Summary ")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "t-flex t-mb-2" }, [
                  _vm.form.quantity >= 0
                    ? _c("div", { staticClass: "t-w-1/3 t-text-right" }, [
                        _vm._v(" Units to be "),
                        _c("span", { staticClass: "t-font-bold" }, [
                          _vm._v("added")
                        ]),
                        _vm._v(" to inventory ")
                      ])
                    : _c("div", { staticClass: "t-w-1/3 t-text-right" }, [
                        _vm._v(" Units to be "),
                        _c("span", { staticClass: "t-font-bold" }, [
                          _vm._v("removed")
                        ]),
                        _vm._v(" from inventory ")
                      ]),
                  _c("div", { staticClass: "t-pl-4 t-text-gray-600" }, [
                    _vm._v(_vm._s(Math.abs(_vm.form.quantity)))
                  ])
                ]),
                _vm.form.quantity >= 0
                  ? _c("div", [
                      _c("div", { staticClass: "t-flex t-mb-2" }, [
                        _c("div", { staticClass: "t-w-1/3 t-text-right" }, [
                          _vm._v(" Units will be "),
                          _c("span", { staticClass: "t-font-bold" }, [
                            _vm._v("added")
                          ]),
                          _vm._v(" at ")
                        ]),
                        _c("div", { staticClass: "t-pl-4 t-text-gray-600" }, [
                          _vm._v(
                            " $" +
                              _vm._s(parseFloat(this.form.cost).toFixed(2)) +
                              " / per unit "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "t-flex t-mb-2" }, [
                        _c("div", { staticClass: "t-w-1/3 t-text-right" }, [
                          _vm._v("Total adjustment")
                        ]),
                        _c("div", { staticClass: "t-pl-4 t-text-gray-600" }, [
                          _vm._v("$" + _vm._s(_vm.total))
                        ])
                      ])
                    ])
                  : _vm.shop.costingMethod
                  ? _c("div", [
                      _c("div", { staticClass: "t-flex t-mb-2" }, [
                        _c("div", { staticClass: "t-w-1/3 t-text-right" }, [
                          _vm._v(" The units will be "),
                          _c("span", { staticClass: "t-font-bold" }, [
                            _vm._v("removed")
                          ]),
                          _vm._v(
                            " based on your shop's default costing method "
                          )
                        ]),
                        _c("div", { staticClass: "t-pl-4 t-text-gray-600" }, [
                          _vm._v(_vm._s(_vm.shop.costingMethod.toUpperCase()))
                        ])
                      ])
                    ])
                  : _vm._e()
              ]
            ),
            _c("div", { staticClass: "t-px-6 t-italic t-text-gray-500" }, [
              _vm._v(
                " * Remember take care when using this feature. This action will affect inventory counts as well as total valuation and cannot be undone "
              )
            ])
          ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                plain: "",
                type: "info",
                size: "small",
                disabled: _vm.submitting
              },
              on: { click: _vm.handleClose }
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                loading: _vm.submitting,
                disabled: _vm.form.quantity === 0
              },
              on: { click: _vm.submit }
            },
            [_vm._v(" Apply Adjusment ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }