<template>
  <span>
    <span class="col-11" style="font-size: 14px; text-overflow: ellipsis;overflow: hidden;padding:0px;">
      {{shop.name}}
    </span>
    <span v-if="shopRejectedCount($store.state, shop.id)" class="float-right shop-option-tag" :style="`background:#dc3545;`">
      {{shopRejectedCount($store.state, shop.id)}}
    </span>
    <span v-if="shopQueuedCount($store.state, shop.id)" class="float-right shop-option-tag" :style="`background:#26af5d;`">
      {{shopQueuedCount($store.state, shop.id)}}
    </span>
  </span>
</template>

<script>
import { shopRejectedCount, shopQueuedCount } from '@/scripts/helpers/rejected.helpers'
import { get } from 'vuex-pathify'

export default {
  props: {
    shop: Object,
  },
  methods: {
    shopRejectedCount,
    shopQueuedCount
  }
}
</script>

<style lang='scss' scoped>
@import './RejectedInvoices.scss';
</style>