<template>
<div>
  <div :id="'white-cell-wrap-' + topIndex">
    <div class="cell-container cell-light-blue d-flex justify-content-center align-content-center" >
      <div class="ml-2" style="width:100px;">
        <el-col>
          <el-row>
            <el-col><a v-if="!isSinglePartSearchModal" href="javascript:void(0)" v-b-modal="'nagsDetailsModal'" class="more-btn align-self-center" @click="$emit('update:popupPartDetails', popupPartDetailWithChildren)"><i class="fa fa-car"></i></a>&nbsp;&nbsp;
            <a href="javascript:void(0)" :class="{'btn':true, 'btn-gray':edi_locked !== 1, 'btn-sm':true, 'align-self-center':true, 'ediLocked':edi_locked === 1}" :disabled="edi_locked === 1" @click="addPartButtonPressed (part, part.children)">{{isSinglePartSearchModal ? 'Select' : 'Add'}}</a></el-col>
          </el-row>
          <el-row>
            <InventoryPartAdd :part='part' :vendorObject='inventoryData(part.detail.partno)' @addPart='addPartInventoryButtonPressed' :isButton='true' :isDisabled='edi_locked === 1'/>
          </el-row>
        </el-col>
      </div>
      <div class="cell-middle-col" style="display: block; cursor:pointer; width:67%;"
      v-b-toggle="'sub-categories-' + topIndex"
      :data-parent="'#white-cell-wrap-' + topIndex">
        <p><b><span style="cursor:pointer" @mouseover="getVehiclesByNagsGlassId(partNameHeader(part.detail))" :id="partNameHeader(part.detail)" v-if="partNameHeader(part.detail).length > 0" class="text-primary text-bold mr-1">{{ partNameHeader(part.detail) }}</span>
        <span style='color: red;' :id='part.detail.partno + "-note"' v-if='part.detail.note && part.detail.note !== ""'>
            &nbsp;<v-icon name='exclamation-circle' v-b-popover.hover='part.detail.note' :title='"Note for " + part.detail.partno' scale='1'/>&nbsp;
        </span>
        <span v-html="partNameDetails(part.detail)"></span></b></p>
        <el-tooltip v-if="trend" content="Percentage of times part used for this vehicle" placement="top">
          <span class="trend" :class="{ high: trend >= 50, med: trend < 50 && trend >= 20, low: trend < 20 }">Trend: <span>{{trend}}%</span></span>
        </el-tooltip>
        <el-tooltip v-if='itemForPart && (itemForPart.retail_list_price || itemForPart.retail_material || itemForPart.retail_labor)' content="A Retail price has been set for this item" placement="top">
          <span class='retail-part mr-2' @click.stop='addRetailPart'>Retail Price: ${{itemForPart.retail_list_price? itemForPart.retail_list_price.toFixed(2) : "0.00"}}</span>
        </el-tooltip>
        <el-tooltip v-if='itemForPart && itemForPart.zone' content="A Zone has been set for this item" placement="top">
          <span class='zone-part'>Zone: {{itemForPart.zone}}</span>
        </el-tooltip>
        <b-popover triggers='hover' :delay='{show: 750}' :placement="'right'" :target="partNameHeader(part.detail)">
          <!-- <v-icon v-if='showLoading' name="spinner" style='color: black;' spin scale="1.5"/> -->
          <ul id="matchingVehiclesDisplay" class="m-0 pl-3">
            <li v-for="(vehicle, vehicleIndex) in matchingVehicles" :key="vehicleIndex">{{vehicleIndex}}
              <ul class="m-0 pl-2">
                <li v-for="(model, modelIndex) in vehicle" :key="modelIndex">
                  {{modelIndex}}
                  <ul class="m-0 pl-2">
                    <li v-for="(year, yearIndex) in model.years" :key="yearIndex">
                      {{year.year}}: {{year.style}}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </b-popover>
      </div>
      <div class="t-whitespace-no-wrap t-w-1/3 t-text-left">
        <p v-if="part.detail.showSecondaryFields" class="t-mb-0 t-text-xs">
          List Price: <span class="text-error" v-html="estimatedCostString(part.detail)"/>
        </p>

        <a
          v-if="inventoryIndex !== null && inventoryParts && inventoryParts[inventoryIndex].quantity > 0"
          href="javascript:void(0)"
          class="tag cursor-pointer"
          style="background-color: white; color: blue; display:inline;"
          @click.stop="addPartButtonPressed (part, part.children, undefined, undefined, true)"
        >
          Inv {{inventoryParts[inventoryIndex].quantity > 5 ? '5+' : inventoryParts[inventoryIndex].quantity}}
          <!-- INV {{inventoryData.qty}} - ${{inventoryData.cost}} : {{getAmountFromPriceLevel(selectedPriceLevel, pricelevels, 'inventory', inventoryData)}} -->
        </a>

        <el-button
          v-if="!checkVendorsClicked[part.detail.partno]"
          @click="handleCheckVendors"
          class="t-text-white t-bg-gray-600 hover:t-opacity-75 t-rounded-full
                 t-px-3 t-py-1 t-mt-1"
        >
          Check Vendors
        </el-button>

        <div v-else>
          <div v-for="vendor in vendors" :key="vendor.name">
            <div v-if="hasVendorCredentials(vendor.name)">
              <span
                v-if="vendorSearchResults[vendor.name][part.detail.partno].loading"
                :class="vendorLoaderBg(vendor.name)"
                class="t-border-none t-rounded-full t-mt-1 t-text-085
                       t-font-medium t-px-1"
              >
                <i class="el-icon-loading"></i>
              </span>
              <div v-else>
                <PilkingtonButton
                  v-if="vendor.name === 'pilkington'"
                  :vendor="vendor.name"
                  :priceFromLevels="getPriceFromLevels(vendor.name)"
                  :partNumber="part.detail.partno"
                  :part="vendorSearchResults[vendor.name][part.detail.partno].data[0]"
                />
                <AmericanButton
                  v-else-if="vendor.name === 'american'"
                  :vendor="vendor.name"
                  :priceFromLevels="getPriceFromLevels(vendor.name)"
                  :partNumber="part.detail.partno"
                  :part="vendorSearchResults[vendor.name][part.detail.partno].data[0]"
                />
                <GlaxisButton
                  v-else
                  :vendor="vendor.name"
                  :priceFromLevels="getPriceFromLevels(vendor.name)"
                  :partNumber="part.detail.partno"
                  :part="vendorSearchResults[vendor.name][part.detail.partno].data[0]"
                />
              </div>
              <PilkingtonDialog
                v-if="vendor.name === 'pilkington'"
                :nagsPart="part"
                :priceFromLevels="getPriceFromLevels(vendor.name)"
                :results="vendorSearchResults[vendor.name][part.detail.partno].data"
                :visible="vendorSearchResults[vendor.name][part.detail.partno].visible"
                :loading="vendorSearchResults[vendor.name][part.detail.partno].loading"
                :shopId="shopId"
                :jobId="job.id"
                @addPart="addPartButtonPressed"
                @recheckVendor="checkVendors($event)"
              />
              <AmericanDialog
                v-else-if="vendor.name === 'american'"
                :nagsPart="part"
                :priceFromLevels="getPriceFromLevels(vendor.name)"
                :results="vendorSearchResults[vendor.name][part.detail.partno].data"
                :visible="vendorSearchResults[vendor.name][part.detail.partno].visible"
                :loading="vendorSearchResults[vendor.name][part.detail.partno].loading"
                :shopId="shopId"
                :jobId="job.id"
                :defaultCredentials="getDefaultVendorCredentials('american').accountNumber"
                @addPart="addPartButtonPressed"
                @recheckVendor="checkVendors($event)"
              />
              <GlaxisDialog
                v-else
                :vendor="vendor.name"
                :nagsPart="part"
                :priceFromLevels="getPriceFromLevels(vendor.name)"
                :results="vendorSearchResults[vendor.name][part.detail.partno].data"
                :visible="vendorSearchResults[vendor.name][part.detail.partno].visible"
                :loading="vendorSearchResults[vendor.name][part.detail.partno].loading"
                :shopId="shopId"
                :jobId="job.id"
                :defaultWarehouseId="vendor.name === 'mygrant' ? shop.mygrant_default_warehouse_id : null"
                :defaultCredentials="vendor.name === 'pgw' ? getDefaultVendorCredentials('pgw').accountNumber : null"
                @addPart="addPartButtonPressed"
                @recheckVendor="checkVendors($event)"
              />
            </div>
          </div>
        </div>

        <WarehouseWrapper
          :vendor='"inventory"'
          :part='part'
          :checking='false'
          :check='false'
          :vendorObject='inventoryData(part.detail.partno)'
          :pricelevels='pricelevels'
          :selectedPriceLevel='selectedPriceLevel'
          :shopId='shopId'
          @addPart='addPartButtonPressed'
        />
      </div>
    </div>
  </div>

  <b-collapse :id="'sub-categories-' + topIndex" class="partResultCollapse">
    <div v-for="(partCategory, categoryIndex) in part.children" :key="topIndex + '-' + categoryIndex">
      <div :style="{'background-image': partCategoryColor(partCategory.detail.name)}" class="cell-sub-header"
      v-b-toggle="'cell-inner-' + topIndex + '-' + categoryIndex"
      :data-parent="'#sub-categories-' + topIndex">
        <h5>{{ partCategory.detail.name }}</h5>
      </div>

      <b-collapse :id="'cell-inner-' + topIndex + '-' + categoryIndex" class="partResultCollapse">
        <div class="cell-container p-0" v-for="(subPart, subPartIndex) in partCategory.children" :key="topIndex + '-' + categoryIndex + '-' + subPartIndex">
          <div class="border-bottom d-flex justify-content-around align-content-center p-1 text-smaller">

            <div v-if="!isSinglePartSearchModal" class="p-1" style="width:30px;"><a href="javascript:void(0)" @click="$emit('update:popupPartDetails', { ...subPart.detail, glassGraphicId: part.detail.glassGraphicId, callouts: part.details.callouts })" v-b-modal="'nagsDetailsModal'" class="more-btn align-self-center"><i class="fa fa-car"></i></a></div>
            <div class="p-1" style="width:20%">
              <a href="javascript:void(0)" :disabled="edi_locked === 1" :style="{'background-color': partCategoryListColor(partCategory.detail.name), 'cursor':'pointer'}" class="badge badge-success" @click="addPartButtonPressed (subPart, subPart.children)">
                {{ partNameHeader(subPart.detail).trim().length > 0 ? partNameHeader(subPart.detail) : 'Add' }}
                </a>
              <InventoryPartAdd :part='subPart' :vendorObject='inventoryData(subPart.detail.partno)' @addPart='addPartButtonPressed' :isDisabled='edi_locked === 1'/>
            </div>
            <div class="p-1" style="width:55%" >
              <div v-html="partNameDetails(subPart.detail)"></div>
            </div>
            <div class="p-1" style="width:25%" v-if="subPart.detail.showSecondaryFields"><b>List Price: </b> <span class="text-error" v-html="estimatedCostString(subPart.detail)"></span></div>
            <div v-if="!isSinglePartSearchModal" class="p-1" style="width:100px"><label style="color:black; font-weight:bold">
              <input @change="autoAddChanged(part.detail.partno, part.detail.autoAddParts)" type="checkbox" v-model="part.detail.autoAddParts" :value="subPart.detail.partno"/> Auto Add</label>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </b-collapse>
  <InventoryConfirmationDialog v-bind='inventoryDialogObject' :visible.sync='confirmInventoryIsVisible' @takePartFromInventory='takePartFromInventory' :inventoryDialogType='null' :isInventoryDialogFromNagsSearch='true' />
</div>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
import $ from 'jquery'
import Nags from '@/scripts/objects/nags'
import AutoAddPart from '@/scripts/objects/auto_add_part'
import WarehouseWrapper from '@/components/PartSearch/WarehouseWrapper.vue'
import InventoryPartAdd from '@/components/PartSearch/InventoryPartAdd.vue'
import InventoryConfirmationDialog from '@/components/modals/InventoryConfirmationDialog.vue'
import { getAvailableUnits, inventoryFromPart } from '@/scripts/helpers/inventory.helpers'
import { getAmountFromPriceLevel } from '@/scripts/helpers/vendor.helpers'
import GlaxisButton from './Glaxis/Button'
import GlaxisDialog from './Glaxis/Dialog'
import PilkingtonButton from './Pilkington/Button'
import PilkingtonDialog from './Pilkington/Dialog'
import AmericanButton from './American/Button'
import AmericanDialog from './American/Dialog'
import { get, call } from 'vuex-pathify'

export default {
  props: ['edi_locked', 'part', 'shopId', 'topIndex', 'popupPartDetails', 'job',
    'insurancefleetAutoAddAdhesive', 'glassGraphics', 'inventoryParts',
    'pricelevels', 'selectedPriceLevel', 'shopParts', 'isSinglePartSearchModal',
    'trends', 'inventoryItems', 'shop'],
  data: function () {
    return {
      shopInventoryValue: null,
      matchingVehicles: {},
      autoPartsChangedIndex: 0,
      checkVendorsClicked: {},
      inventoryDialogObject: null,
      confirmInventoryIsVisible: false
    }
  },
  methods: {
    inventoryFromPart,
    getAvailableUnits,
    getAmountFromPriceLevel,
    takePartFromInventory (data) {
      data.partData.detail.take_from_inventory = 1
      data.partData.detail.inventory_type_to_take = data.typeToUse
      this.addPartButtonPressed({detail: {...data.partData.detail, inventory_cost: data.selectedCost}}, data.partData.children, 'inventory_sub')
      this.confirmInventoryIsVisible = false
    },
    addPartInventoryButtonPressed (partObject, children, type) {
      if (partObject) {
        // need to get the item
        // partObject._item
        this.inventoryDialogObject = partObject
        this.confirmInventoryIsVisible = true
      }
    },
    getItem (partno) {
      for (var i = 0; i < this.inventoryItems.length; i++) {
        if (partno === this.inventoryItems[i].number) {
          return this.inventoryItems[i]
        }
      }
      return []
    },
    async autoAddChanged(partNo, autoAddParts) {
      this.autoPartsChangedIndex++
      var partSuffix = partNo.substring(7, 10)
      var nagsId = partNo.substring(0, 7)
      var autoAddPartResponse = await AutoAddPart.createUpdateAutoAddParts(this.shopId, nagsId, partSuffix, autoAddParts, function (res) {
      })
      this.autoPartsChangedIndex--
      if (this.autoPartsChangedIndex === 0) {
        this.$root.$emit('updateAutoAddParts', autoAddPartResponse.data)
      }
    },
    addPartButtonPressed (nagsPart, children, vendor = '', adhesiveAt = undefined, fromInventory = false, fromVendor = false, adhesiveVendor = null) {
      if (this.isSinglePartSearchModal) {
        this.$root.$emit('singlePartSearchModalSelection', nagsPart.detail)
        this.$root.$emit('partSearchClearButtonPress')
        this.$root.$emit('bv::hide::modal', 'singlePartSearchModal')
        return
      }

      if (this.edi_locked === 1) {
        return
      }

      if (typeof vendor === 'undefined') {
        vendor = ''
      }

      if (typeof adhesiveAt === 'undefined') {
        adhesiveAt = null
      }
      if (nagsPart.detail && nagsPart.detail.isOem && nagsPart.detail.isGlass) {
        nagsPart.detail.labor = this.part.detail.labor
      }

      var priceLevelData = null
      // TODO Need to do these
      if (vendor === 'glassbiller') {
        priceLevelData = nagsPart.warehouseGlassbiller().priceLevelTierDetails
      } else if (vendor === 'mygrant' || vendor === 'pgw' || vendor === 'igc') {
        if (this.vendorSearchResults[vendor][this.part.detail.partno].data.length > 0) {
          priceLevelData = this.getPriceTier(vendor)
          priceLevelData._vendor_price = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].priceBuyer
        }
      } else if (vendor === 'pilkington') {
        if (this.vendorSearchResults[vendor][this.part.detail.partno].data.length > 0) {
          priceLevelData = this.getPriceTier(vendor) // nagsPart.warehousePilkington().priceLevelTierDetails;
          // add vendor price
          priceLevelData._vendor_price = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].unitPrice
        }
      } else if (vendor === 'american') {
        if (this.vendorSearchResults[vendor][this.part.detail.partno].data.length > 0) {
          priceLevelData = this.getPriceTier(vendor) // nagsPart.warehousePilkington().priceLevelTierDetails;
          // add vendor price
          priceLevelData._vendor_price = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].item_price
        }
      } else if (vendor === 'inventory') {
        priceLevelData = this.getPriceTier(vendor)
        priceLevelData._vendor_price = this.inventoryData(nagsPart.detail.partno).cost
      }

      if (priceLevelData) {
        if (typeof nagsPart.popoverTriggerElement !== 'undefined') {
          nagsPart.popoverTriggerElement.popover('hide')
          nagsPart.popoverTriggerElement.data('bs.popover').inState.click = false
        }
      }

      /* TODO Do this with pricelevel data */
      if (vendor.length > 0 && vendor !== 'inventory_sub') {
        nagsPart.detail = Nags.generateNagPart(this.job, nagsPart.detail, 'nags-part-added', priceLevelData)
      }
      let tempInventoryData = this.inventoryData(nagsPart.detail.part_number)
      if (vendor === 'inventory' && tempInventoryData) {
        nagsPart.detail.cost = tempInventoryData.cost
      }

      if (adhesiveAt) {
        nagsPart.detail.price_level_tier_this_instance_is_auto_added_adhesive = 1 // set special flag to prevent using insurance fleet to set pricing
        if (adhesiveVendor) {
          var tempPriceLevel = this.getPriceTier(adhesiveVendor)
          nagsPart.detail.price_level_tier_auto_add_adhesive_flat_or_each = tempPriceLevel.auto_add_adhesive_flat_or_each
          nagsPart.detail.price_level_tier_auto_add_adhesive_at = tempPriceLevel.auto_add_adhesive_amount
          nagsPart.detail.price_level_tier_tax_included = tempPriceLevel.tax_included
        }
        nagsPart.detail.list_price = parseFloat(adhesiveAt)
      }

      var glassGraphicsData = ''
      if (this.glassGraphics && nagsPart.detail.glassGraphic in this.glassGraphics) {
        glassGraphicsData = this.glassGraphics[nagsPart.detail.glassGraphic]
      }

      var doAutoAddAdhesiveFromPriceTier = false
      var addAdhesiveAt = 0.0
      var skipAutoAddAdhesive = false
      if (priceLevelData) {
        if (priceLevelData.auto_add_adhesive === 1) {
          doAutoAddAdhesiveFromPriceTier = true
          addAdhesiveAt = parseFloat(priceLevelData.auto_add_adhesive_amount)
        } else {
          skipAutoAddAdhesive = true
        }
      }

      // if (vendor === 'inventory') {
      //   // nagsPart.detail.take_from_inventory = 1
      // } else {
      //   nagsPart.detail.take_from_inventory = 0
      // }

      if (fromVendor) {
        this.$root.$emit('addJobPart', nagsPart.detail)
      } else if (adhesiveAt) {
        this.$root.$emit('addJobNagsPart', nagsPart, glassGraphicsData, false, adhesiveAt)
      } else if (fromInventory === false) {
        this.$root.$emit('addJobNagsPart', nagsPart, glassGraphicsData, false, undefined, vendor === 'inventory_sub')
      } else if (fromInventory === true) {
        this.$root.$emit('addJobNagsPart', nagsPart, glassGraphicsData, true)
        this.$root.$emit('removePartInventory', this.part['detail'].partno, 1)
      }

      if (nagsPart.detail.autoAddParts) {
        if (nagsPart.detail.autoAddParts.length > 0) {
          skipAutoAddAdhesive = true
        }
      }

      // Looping through children.children and comparing part numbers against auto add parts (stored on parent object) to find parts to add automatically
      // #Auto Add Saved Parts
      let savedAutoAddPartIds = []
      if (nagsPart.children) {
        for (var i = 0; i < nagsPart.children.length; i++) {
          let currentCategory = nagsPart.children[i]
          for (var j = 0; j < currentCategory.children.length; j++) {
            let potentialAutoAddPart = currentCategory.children[j]
            if (nagsPart.detail.autoAddParts) {
              for (var k = 0; k < nagsPart.detail.autoAddParts.length; k++) {
                // checking for saved parts and saving ids for later
                if ((nagsPart.detail.autoAddParts[k].includes('SAVEDPART')) && (!savedAutoAddPartIds.includes(nagsPart.detail.autoAddParts[k].split('-')[1]))) {
                  savedAutoAddPartIds.push(nagsPart.detail.autoAddParts[k].split('-')[1])
                }
                if (potentialAutoAddPart.detail.partno === nagsPart.detail.autoAddParts[k]) {
                  this.$root.$emit('addJobNagsPart', potentialAutoAddPart, glassGraphicsData, false)
                }
              }
            }
          }
        }
      }

      // looping through saved part ids to get and add saved parts
      for (var v = 0; v < savedAutoAddPartIds.length; v++) {
        for (var w = 0; w < this.shopParts.length; w++) {
          if (savedAutoAddPartIds[v] === (this.shopParts[w].id).toString()) {
            this.$root.$emit('addJobPart', Object.assign({}, this.shopParts[w]))
          }
        }
      }

      if (!skipAutoAddAdhesive) {
        // Special-case logic.  If the insurance is set to auto-add the first associated adhesive, do so now
        if (doAutoAddAdhesiveFromPriceTier || this.insurancefleetAutoAddAdhesive === 1 || this.insurancefleetAutoAddAdhesive === '1') {
          let shop = this.getShopFromId(this, this.shopId)
          let adhesive_default = shop.adhesive_default
          // TODO Ensure part children are loaded first
          // if (self.jobData.masterView && typeof self.jobData.masterView == "object" && self.jobData.masterView.nags && typeof self.jobData.masterView.nags == "object" && typeof self.jobData.masterView.nags.ensureChildrenLoaded == "function") {
          if (typeof children !== 'undefined' && children && $.isArray(children)) {
            if (children.length === 0) {

              // TODO Ensure children loaded
              // children = self.jobData.masterView.nags.ensureChildrenLoaded(event, nagsPart)
            }
            if (typeof children !== 'undefined' && children && $.isArray(children) && children.length) {
              for (var ch = 0; ch < children.length; ++ch) {
                var child = children[ch]
                if (typeof child.detail === 'object' && child.detail && typeof child.detail.name !== 'undefined' && child.detail.name === 'Adhesives') {
                  // Matched high-level 'Adhesives' category beneath the glass part
                  // ... now go one level deeper still
                  if (typeof child.children !== 'undefined' && child.children && $.isArray(child.children) && child.children.length) {
                    var grandchildren = child.children
                    // if we are here, we know that insurancefleetAutoAddAdhesive === true
                    // check shop.adhesive_default and build an array of acceptable ids based on that
                    let standard = false
                    let found = false
                    let relevantIds = []
                    let nonStandardIds = ['HAH016000', 'HAH024358', 'HAH000456', 'HAH000448']
                    if (adhesive_default === 'high_modulus') {
                      relevantIds.push('HAH016000', 'HAH024358', 'HAH000456')
                    } else if (adhesive_default === 'fast_cure') {
                      relevantIds.push('HAH000448')
                    } else {
                      standard = true
                    }
                    // if shop.adhesive_default is not 'standard' use the array built above to match
                    // a qualified part id
                    for (var ia = 0; ia < grandchildren.length; ia++) {
                      var potentialPart = grandchildren[ia]
                      if (typeof potentialPart.detail === 'object' && potentialPart.detail) {
                        if (!standard) {
                          if (relevantIds.includes(potentialPart.detail.nagsid)) {
                            if (vendor === '') {
                              this.addPartButtonPressed(potentialPart, null, '', addAdhesiveAt)
                              found = true
                              break
                            } else {
                              this.addPartButtonPressed(potentialPart, null, '', addAdhesiveAt, false, false, vendor)
                              found = true
                              break
                            }
                          }
                        } else {
                          if (!nonStandardIds.includes(potentialPart.detail.nagsid)) {
                            if (vendor === '') {
                              this.addPartButtonPressed(potentialPart, null, '', addAdhesiveAt)
                              found = true
                              break
                            } else {
                              this.addPartButtonPressed(potentialPart, null, '', addAdhesiveAt, false, false, vendor)
                              found = true
                              break
                            }
                          }
                        }
                      }
                    }
                    // if adhesive_default === 'standard' or we couldn't find the desired part type,
                    // loop through and grab the first part as usual
                    if (!found) {
                      for (var gch = 0; gch < grandchildren.length; ++gch) {
                        var grandchild = grandchildren[gch]
                        // This is the first one!
                        if (typeof grandchild.detail === 'object' && grandchild.detail) {
                          if (vendor === '') {
                            this.addPartButtonPressed(grandchild, null, '', addAdhesiveAt)
                            break
                          } else {
                            this.addPartButtonPressed(grandchild, null, '', addAdhesiveAt, false, false, vendor)
                            break
                          }
                        }
                      }
                    }
                  }
                  break
                } else {
                  continue
                }
              }
            }
          }
          // }
        }
      }
      $('html,body').animate({scrollTop: document.body.scrollHeight}, 'fast')
    },
    partNameHeader (detail) {
      if (detail && detail.name && detail.partno && detail.name.includes(detail.partno)) {
        return detail.partno
      }
      return ''
    },
    partCategoryColor (name) {
      if (name === 'Adhesives') {
        return 'linear-gradient(#DB0000 0%, #B50000 100%)'
      } else if (name === 'Moldings') {
        return 'linear-gradient(#17C200 0%, #118701 100%)'
      } else if (name === 'Miscellaneous') {
        return 'linear-gradient(#B39D9D 0%, #807070 100%)'
      } else if (name === 'OEM Glass') {
        return 'linear-gradient(#83BAD6 0%, #60899E 100%)'
      } else if (name === 'Interchanges') {
        return 'linear-gradient(#76D3D6 0%, #5CA9AB 100%)'
      } else {
        return 'linear-gradient(rgb(81, 81, 81) 0%, rgb(54, 54, 54) 100%)'
      }
    },
    partCategoryListColor (name) {
      if (name === 'Adhesives') {
        return '#DB0000'
      } else if (name === 'Moldings') {
        return '#15ad00'
      } else if (name === 'Miscellaneous') {
        return '#a69292'
      } else if (name === 'OEM Glass') {
        return '#6fa3bd'
      } else if (name === 'Interchanges') {
        return '#65b5b8'
      } else {
        return 'rgb(81, 81, 81)'
      }
    },
    partNameDetails (detail) {
      if (detail && detail.name) {
        var name = detail.name
        if (detail.partno && detail.name.startsWith(detail.partno)) {
          name = name.replace(detail.partno, '')
        }
        if (detail.isGlass) {
          if (detail.SLIDER_FLAG && detail.SLIDER_FLAG === 'Y') {
            name += ', Back Slider'
          }
          if (detail.SOLAR_FLAG && detail.SOLAR_FLAG === 'Y') {
            name += ', Solar Coated'
          }
          if (detail.HDS_UP_DISP_FLAG && detail.HDS_UP_DISP_FLAG === 'Y') {
            name += ' <b style=\'color:#569BB3\'>HDS UP DISP</b>'
          }
          if (detail.HDS_UP_DISP_FLAG && detail.HEATED_FLAG === 'Y') {
            name += ' <b style=\'color:#6DA65A\'>Heated</b>'
          }
          if (detail.HDS_UP_DISP_FLAG && detail.ANT_FLAG === 'Y') {
            name += ' <b style=\'color:#935AA6\'>Anterior</b>'
          }
          if (detail.CLIPS_FLAG && detail.CLIPS_FLAG === 'Y' && detail.ATCHMNT_IS_CLIP && detail.ATCHMNT_IS_CLIP === 'N') {
            name += ' <b style=\'color:#E69C27\'>Clips Req\'d</b>'
          }
          if (detail.MLDING_FLAG && detail.MLDING_FLAG === 'Y' && detail.ATCHMNT_IS_MLDING && detail.ATCHMNT_IS_MLDING === 'N') {
            name += ' <b style=\'color:#A66D5A\'>Moulding Req\'d</b>'
          }
        }
        return name
      }
      return ''
    },
    estimatedCostString (detail) {
      if (detail.showSecondaryFields) {
        var results = '<span style="color: red;">' + this.formatCurrency(detail.partprice) + '</span>'
        if (detail.level === 1) {
          results += '<span style="color: mediumvioletred;"> - ' + this.formatFloatDecimals(detail.labor, 2) + '</span> <span style="color:#888888">hrs</span>'
        }
        return results
      }
      return ''
    },
    getVehiclesByNagsGlassId (nagsGlassId) {
      var self = this
      Nags.getVehiclesByNagsGlassId(nagsGlassId, function (res) {
        self.matchingVehicles = res
      })
    },
    getPriceLevel () {
      for (var i = 0; i < this.pricelevels.length; i++) {
        if (this.selectedPriceLevel === parseInt(this.pricelevels[i].id)) {
          return this.pricelevels[i]
        }
      }

      return null
    },
    getPriceTier (vendor) {
      var temp = this.getPriceLevel()
      if (temp === null) {
        return null
      }
      let amount = ''
      if (vendor === 'pilkington') {
        if (this.vendorSearchResults[vendor][this.part.detail.partno].data.length > 0) {
          amount = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].unitPrice
        }
      } else if (vendor === 'american') {
        if (this.vendorSearchResults[vendor][this.part.detail.partno].data.length > 0) {
          amount = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].item_price
        }
      } else if (vendor === 'inventory') {
        amount = this.inventoryData(this.part.detail.partno).cost
      } else {
        if (this.vendorSearchResults[vendor][this.part.detail.partno].data.length > 0) {
          amount = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].priceBuyer
        }
      }
      return temp.getPriceLevelTier(amount)
    },
    getAmountFromTier (vendor) {
      var tier = this.getPriceTier(vendor)
      if (tier === null) {
        return null
      }
      let amount = ''
      if (this.vendorSearchResults[vendor][this.part.detail.partno].data.length > 0) {
        if (vendor === 'pilkington') {
          amount = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].unitPrice
        } else if (vendor === 'american') {
          amount = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].item_price
        } else {
          amount = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].priceBuyer
        }
      }
      return tier.getAmountFromTier(amount)
    },
    inventoryData (partno) {
      if (this.shop) {
        var temp = this.inventoryFromPart(partno, this.inventoryItems, this.shop.inventory_costing_method)
        return temp
      }
      return {}
    },
    checkPilkington: call('partVendors/checkPilkington'),
    checkGlaxis: call('partVendors/checkGlaxis'),
    checkAmerican: call('partVendors/checkAmerican'),
    handleCheckVendors () {
      this.vendors.forEach(vendor => {
        if (this.hasVendorCredentials(vendor.name)) {
          this.$store.dispatch('partVendors/presetData', {
            vendor: vendor.name,
            partNumber: this.part.detail.partno
          })
        }
      })
      this.$set(this.checkVendorsClicked, this.part.detail.partno, true)
      this.checkVendors()
    },
    checkVendors (data = null) {
      const part = {
        full: this.part.detail.partno,
        prefix: this.part.detail.nagsPrefix.prefixCd,
        number: (this.part.detail.PART_NUM.length === 4 ? '0' : '') + this.part.detail.PART_NUM,
        color: this.part.detail.partno.substr(7, 2),
        hardware: this.part.detail.ATCHMNT_FLAG === 'Y',
        premium: this.part.detail.isPremium
      }
      this.vendors.forEach(vendor => {
        if (this.hasVendorCredentials(vendor.name)) {
          if (!data || (data && data.vendor && data.vendor === vendor.name)) {
            let warehouseId = null
            let accountNumber = null
            let accountName = null
            if (vendor.name === 'mygrant') {
              if (data && data.warehouseId) {
                warehouseId = data.warehouseId
              } else {
                warehouseId = this.shop.mygrant_default_warehouse_id
              }
              accountNumber = this.shop.mygrant_account_id
            } else if (vendor.name === 'pgw') {
              if (data && data.accountNumber) {
                accountNumber = data.accountNumber
                accountName = data.accountName
              } else {
                const credentials = this.getDefaultVendorCredentials('pgw')
                if (credentials) {
                  accountNumber = credentials.accountNumber
                  accountName = credentials.accountName
                }
              }
            } else if (vendor.name === 'igc') {
              accountNumber = this.shop.igc_account_id
            } else if (vendor.name === 'pilkington') {
              if (data && data.warehouseId) {
                warehouseId = data.warehouseId
              }
            } else if (vendor.name === 'american') {
              if (data && data.accountNumber) {
                accountNumber = data.accountNumber
                accountName = data.accountName
              } else {
                const credentials = this.getDefaultVendorCredentials('american')
                if (credentials) {
                  accountNumber = credentials.accountNumber
                  accountName = credentials.accountName
                }
              }
            }
            if (vendor.name === 'pilkington') {
              this.checkPilkington({
                shopId: this.shopId,
                partNumber: part.full,
                warehouseId
              })
            } else if (vendor.name === 'american') {
              this.checkAmerican({
                shopId: this.shopId,
                fullPartNumber: part.full,
                partNumber: part.number,
                accountNumber,
                accountName
              })
            } else {
              this.checkGlaxis({
                vendor: vendor.name,
                shopId: this.shopId,
                jobId: this.job.id,
                warehouseId,
                accountNumber,
                accountName,
                part
              })
            }
          }
        }
      })
    },
    getPriceFromLevels (vendor) {
      if (this.vendorSearchResults[vendor].hasOwnProperty(this.part.detail.partno)) {
        let price
        if (this.vendorSearchResults[vendor][this.part.detail.partno].data.length > 0) {
          if (vendor === 'pilkington') {
            price = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].unitPrice
          } else if (vendor === 'american') {
            price = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].item_price
          } else {
            price = this.vendorSearchResults[vendor][this.part.detail.partno].data[0].priceBuyer
          }
        }
        return getAmountFromPriceLevel(
          this.selectedPriceLevel,
          this.pricelevels,
          vendor,
          {
            cost: price
          }
        )
      }
      return null
    },
    hasVendorCredentials (vendor) {
      if (vendor === 'pilkington') {
        return this.shop.pilkington_username && this.shop.pilkington_password
      }
      if (vendor === 'mygrant') {
        return this.shop.mygrant_account_id
      }
      if (vendor === 'pgw' || vendor === 'american') {
        if (!this.vendorCredentials.length) {
          return false
        }
        return this.vendorCredentials.filter(i => i.vendor === vendor).length > 0
      }
      if (vendor === 'igc') {
        return this.shop.igc_account_id
      }
      return false
    },
    vendorLoaderBg (vendor) {
      if (vendor === 'pilkington') {
        return 't-bg-green-300'
      }
      if (vendor === 'mygrant') {
        return 't-bg-blue-300'
      }
      if (vendor === 'pgw') {
        return 't-bg-red-300'
      }
      if (vendor === 'igc') {
        return 't-bg-yellow-300'
      }
      if (vendor === 'american') {
        return 't-bg-orange-300'
      }
      return ''
    },
    addRetailPart () {
      var newPart =
        {
          description: this.part.detail.dsc,
          nagsid: '',
          part_number: this.part.detail.partno,
          list_price: this.itemForPart.retail_list_price || 0,
          is_adhesive_kit_part: '0',
          is_oem: '0',
          is_premium: '0',
          is_glass: '1',
          is_chip: '0',
          is_recal: '1',
          color: '',
          mf_code: '',
          attachment_flag: '',
          moulding_discount: '0',
          is_custom_part: '0',
          hours: 0,
          quantity: 1,
          discount: 0,
          labor_price: this.itemForPart.retail_labor || 0,
          materials_price: this.itemForPart.retail_material || 0,
          cost: 0,
          glass_graphic_data: '',
          glass_graphic_id: 0,
          glass_callout: 0,
          atchmnt_flag: '',
          clips_flag: '',
          mlding_flag: '',
          atchmnt_is_clip: '',
          atchmnt_is_mlding: '',
          atchmnt_dsc: '',
          prefix: '',
          part_num: 0,
          ant_flag: '',
          blk_size1: 0.0,
          blk_size2: 0.0,
          encap_flag: '',
          hds_up_disp_flag: '',
          heated_flag: '',
          num_holes: 0.0,
          slider_flag: '',
          solar_flag: '',
          thickness: 0.0,
          wt: 0.0,
          tube_qty: 0.0,
          price_level_tier_vendor_price: 0.0,
          price_level_tier_add_material: 0.0,
          price_level_tier_add_labor: 0.0,
          price_level_tier_auto_add_adhesive: '0',
          price_level_tier_auto_add_adhesive_at: 0.0,
          price_level_tier_tax_included: '0',
          price_level_tier_round_to_nearest9: '0',
          price_level_tier_this_instance_is_auto_added_adhesive: '0',
          price_level_tier_additional_add_labor_round_to9: 0.0,
          price_level_tier_list_price_tax_backed_out: 0.0,
          price_level_tier_add_material_tax_backed_out: 0.0,
          price_level_tier_total_material_tax_backed_out: 0.0,
          price_level_tier_add_labor_tax_backed_out: 0.0,
          order: -1
        }
      this.$root.$emit('addJobPart', newPart, -1, true)
    },
    getDefaultVendorCredentials: function (vendor) {
      if (this.vendorCredentials.length > 0) {
        const allCredentials = this.vendorCredentials.filter(i => i.vendor === vendor)
        if (allCredentials.length > 0) {
          let credentials = {
            accountNumber: allCredentials[0].account_number,
            accountName: allCredentials[0].name
          }
          allCredentials.forEach(i => {
            if (i.default === 1) {
              credentials = {
                accountNumber: i.account_number,
                accountName: i.name
              }
            }
          })
          return credentials
        }
      }
      return null
    }
  },
  computed: {
    inventoryIndex () {
      if (this.inventoryParts) {
        for (var i = 0; i < this.inventoryParts.length; ++i) {
          if (this.part.detail.partno === this.inventoryParts[i].part_number) {
            return i
          }
        }
      }
      return null
    },
    popupPartDetailWithChildren () {
      return {...this.part.detail, children: this.part.children}
    },
    trend() {
      return this.trends[this.part.detail.partno]
    },
    itemForPart () {
      if (this.inventoryItems.length) {
        for (var i = 0; i < this.inventoryItems.length; i++) {
          if (this.inventoryItems[i].number === this.part.detail.partno) {
            return this.inventoryItems[i]
          }
        }
      }
      return null
    },
    vendors: get('partVendors/vendors'),
    vendorSearchResults: get('partVendors/searchResults'),
    vendorCredentials: get('partVendors/credentials')
  },
  components: {
    WarehouseWrapper,
    InventoryPartAdd,
    GlaxisButton,
    GlaxisDialog,
    PilkingtonButton,
    PilkingtonDialog,
    AmericanButton,
    AmericanDialog,
    InventoryConfirmationDialog
  },
  mixins: [UtilityMixin]
}
</script>

<style>
.cell-sub-header {
  border-bottom: 1px solid rgb(11, 11, 11) !important;
}
.trend {
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  padding: 0 2px;
}

.trend.high {
  background: #2E7D32;
}

.trend.med {
  background: #FF8F00;
}

.trend.low {
  background: #D84315;
}

.retail-part {
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  padding: 0 2px;
  background: blue;
}
.zone-part {
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  padding: 0 2px;
  background: purple;
}

.cell-right-col p, .cell-right-col .pilkingtonWrapper {
  white-space: nowrap
}

</style>
