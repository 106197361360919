var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "150px",
        width: "600px",
        "custom-class": "edit-warehouse-entity-dialog",
        title: "Delivery addresses",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "div",
        { staticClass: "t-py-6" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "t-px-6",
              attrs: { model: _vm.form, "hide-required-asterisk": "" }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "name",
                    "show-message": false,
                    rules: [{ required: true }]
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "t-w-full",
                    attrs: { placeholder: "Enter name" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c("AddressInput", {
                attrs: { popperFix: "" },
                model: {
                  value: _vm.form.address,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address"
                }
              }),
              _c(
                "div",
                { staticClass: "t-text-right" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "t-mt-6",
                      attrs: { type: "primary" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" Add and Select ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.addresses.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "t-px-6 t-border-0 t-border-t t-border-solid t-border-gray-200\n        t-pt-6 t-mt-6"
                },
                _vm._l(_vm.addresses, function(i, index) {
                  return _c(
                    "div",
                    {
                      key: i.id,
                      staticClass: "t-flex t-items-center",
                      class: {
                        "t-border-0 t-border-b t-border-solid t-border-gray-200 t-pb-2 t-mb-2":
                          index + 1 < _vm.addresses.length
                      }
                    },
                    [
                      _c("div", { staticClass: "t-flex-grow" }, [
                        _c("div", [_vm._v(_vm._s(i.name))]),
                        _c("div", { staticClass: "t-text-sm" }, [
                          _vm._v(
                            " " +
                              _vm._s(i.address) +
                              ", " +
                              _vm._s(i.city) +
                              ", " +
                              _vm._s(i.state) +
                              " " +
                              _vm._s(i.zip) +
                              ", " +
                              _vm._s(i.unit) +
                              " "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "t-text-red-400" }, [
                        _c("i", {
                          staticClass: "el-icon-delete t-cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.remove(i.id)
                            }
                          }
                        })
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }