import gql from 'graphql-tag'

export const NOTE_FRAGMENT = gql`
  fragment NoteFields on Note {
    id
    note_text
    note_sequence
    note_date
    username
    color
  }
`
