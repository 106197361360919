<template>
  <div v-if="!shopPhoneNumbers.length">
    <p>Ask us about setting up a texting package to use automated confirmations.</p>
  </div>
  <div
    v-else
    class="t-font-sans t-px-6 t-py-5 t-mt-4 t-border t-border-solid t-border-gray-400 t-rounded-md
      t-break-normal"
  >
    <el-form>
      <el-form-item
        label="Automatically send confirmation texts"
        prop="send"
      >
        <el-switch v-model="send" />
      </el-form-item>
      <el-form-item prop="shopPhoneNumber">
        <span slot="label" class="undo-label-styles">Send from</span>
        <el-select
          placeholder="Select shop phone number"
          v-model="shopPhoneId"
          ref="shopPhoneInput"
          style="width: 100%">
          <el-option
            v-for="item in shopPhoneNumbers"
            :label="`${item.phone_number} - ${item.name}`"
            :key="item.id"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <p>Note: Adjusting the below settings will NOT affect jobs that have already been scheduled.</p>
    </el-form>
    <el-divider/>
    <el-form :model="settings" :disabled="!send" ref="settings">
      <el-form-item
        label="time"
        prop="time"
      >
        <el-time-picker
          v-model="time"
          :picker-options="{
            selectableRange: '06:00:00 - 20:00:00'
          }"
          format="h:mm A"
          ref="timeInput"
          placeholder="Time to send">
        </el-time-picker>
      </el-form-item>
      <el-form-item
        label="day"
        prop="sameDay"
      >
        <el-radio-group v-model="sameDay">
          <el-radio :label="false">Day Before</el-radio>
          <el-radio :label="true">Same Day</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="!sameDay"
        label="send monday's on saturday"
        prop="mondaysOnSaturday"
      >
        <el-switch v-model="mondaysOnSaturday" />
      </el-form-item>
      <el-form-item
        label="include commercial customers"
        prop="commercial"
      >
        <el-switch v-model="includeCommercial" />
      </el-form-item>

      <el-form-item prop="selectedTemplate">
        <span slot="label" class="undo-label-styles">Template</span>
        <el-select
          clearable
          placeholder="Select text message template (optional)"
          v-model="templateId"
          ref="templateInput"
          style="width: 100%">
          <el-option
            v-for="item in templates"
            :label="item.name"
            :key="item.id"
            :value="item.id">
          </el-option>
        </el-select>
        or <a href="javascript:void(0)" @click="manageTemplates">Manage text message templates</a>
      </el-form-item>
      <el-form-item prop="body">
        <span slot="label" class="undo-label-styles">Template body</span>
        <el-input
          type="textarea"
          disabled
          :autosize="{ minRows: 3, maxRows: 10}"
          placeholder="Template body"
          v-model="selectedTemplateBody"
        />
      </el-form-item>
      <el-form-item style="word-break: break-word;">
        <p class="mt-3"><span style="color: #F56C6C;">NOTE:</span> text "Please reply YES to confirm or reply NO to
          change" will be automatically added in the end of the message.</p>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { call } from 'vuex-pathify'
import TwilioPhoneNumber from '@/scripts/objects/twilio_phone_number'

export default {
  props: {
    settings: Object,
    shopId: Number
  },
  data: () => ({
    templates: [],
    shopPhoneNumbers: [],
    selectedTemplateBody: ''
  }),
  watch: {
    'shopId'(val) {
      if (val) {
        this.getShopPhones()
        this.getTemplates()
      }
    },
    templateId: async function () {
      this.setTemplateBody()
    }
  },
  mounted() {
    if (this.shopId) {
      this.getShopPhones()
      this.getTemplates()
    }
  },
  methods: {
    async getTemplates () {
      try {
        const url = `textMessageTemplate/allByType/sms?shopId=${this.shopId}&type=1`
        const {data} = await this.$unum.job().get(url)
        this.templates = data || []
        this.setTemplateBody()
      } catch (error) {
        this.templates = []
      }
    },
    setTemplateBody () {
      if (!this.templateId) {
        this.selectedTemplateBody = ''
      } else {
        this.templates.forEach((template) => {
          if (template.id === this.templateId) {
            this.selectedTemplateBody = template.body
          }
        })
      }
    },
    async getShopPhones () {
      const shopPhoneNumbers = await TwilioPhoneNumber.getForJobConfirmation(this.shopId)
      this.shopPhoneNumbers = shopPhoneNumbers
    },
    manageTemplates () {
      this.openTextMessageTemplatesDialog()
      this.setTextMessageTemplatesReferral('job-confirmation')
    },
    manualValidate () {
      if (this.settings.send) {
        if (!this.settings.shop_phone_id) {
          this.$emit('confirmationSettingsInvalid', 'Please select a shop phone number to send confirmations from.')

          setTimeout(() => {
            this.$refs.shopPhoneInput.focus()
          }, 200)

          return 'invalid'
        } else if (!this.settings.time) {
          this.$emit('confirmationSettingsInvalid', 'Please select a time to send automated confirmations.')

          setTimeout(() => {
            this.$refs.timeInput.focus()
          }, 200)

          return 'invalid'
        } else if (!this.settings.template_id) {
          this.$emit('confirmationSettingsInvalid', 'Please select a template to use for automated confirmations.')

          setTimeout(() => {
            this.$refs.templateInput.focus()
          }, 200)

          return 'invalid'
        } else {
          return 'valid'
        }
      } else {
        return 'valid'
      }
    }
  },
  computed: {
    openTextMessageTemplatesDialog: call('twilio/textMessages/templates/open'),
    setTextMessageTemplatesReferral: call('twilio/textMessages/templates/setReferral'),
    includeCommercial: {
      get() {
        return !!this.settings.commercial
      },
      set(value) {
        this.$emit('modifySettings', {...this.settings, commercial: +value})
      }
    },
    mondaysOnSaturday: {
      get() {
        return !this.settings.send_sunday
      },
      set(value) {
        this.$emit('modifySettings', {...this.settings, send_sunday: +!value})
      }
    },
    sameDay: {
      get() {
        return !!this.settings.same_day
      },
      set(value) {
        this.$emit('modifySettings', {...this.settings, same_day: +value})
      }
    },
    send: {
      get() {
        return !!this.settings.send
      },
      set(value) {
        this.$emit('modifySettings', {...this.settings, send: +value})
      }
    },
    time: {
      get() {
        return this.settings.time
      },
      set(value) {
        this.$emit('modifySettings', {...this.settings, time: value})
      }
    },
    templateId: {
      get() {
        return this.settings.template_id
      },
      set(value) {
        this.$emit('modifySettings', {...this.settings, template_id: value})
      }
    },
    shopPhoneId: {
      get() {
        return this.settings.shop_phone_id
      },
      set(value) {
        this.$emit('modifySettings', {...this.settings, shop_phone_id: value})
      }
    }
  }
}
</script>
