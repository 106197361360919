var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            lazy: "",
            title: "Shop Message",
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg",
            id: "shopMessageModal"
          },
          on: {
            show: _vm.dialogAboutToAppear,
            shown: _vm.dialogAppeared,
            hidden: _vm.dialogDisappeared
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.selectedMessage.created))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "mb-0" }, [
                      _c("b", [_vm._v("From:")]),
                      _vm._v(" " + _vm._s(_vm.selectedMessage.salesRep))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "mb-0" }, [
                      _c("b", [_vm._v("Mobile Job:")]),
                      _vm._v(" " + _vm._s(_vm.selectedMessage.mobileJobNumber))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "mb-0" }, [
                      _c("b", [_vm._v("Job:")]),
                      _vm._v(" "),
                      _vm.selectedMessage.jobNumber > 0
                        ? _c(
                            "a",
                            {
                              staticClass: "tag",
                              staticStyle: {
                                "background-color": "gray",
                                display: "inline"
                              },
                              attrs: {
                                href: "/jobs/" + _vm.selectedMessage.jobNumber,
                                target: "_blank"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.selectedMessage.jobNumber))]
                          )
                        : _vm._e()
                    ])
                  ])
                ],
                1
              ),
              _c("br"),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.selectedMessage.message))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _vm.selectedMessage.message_status === "unread"
                ? _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "primary", size: "sm" },
                      on: {
                        click: function($event) {
                          return _vm.toggleMessageStatus(_vm.selectedMessage)
                        }
                      }
                    },
                    [_vm._v(" Mark as read ")]
                  )
                : _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "primary", size: "sm" },
                      on: {
                        click: function($event) {
                          return _vm.toggleMessageStatus(_vm.selectedMessage)
                        }
                      }
                    },
                    [_vm._v(" Mark as unread ")]
                  )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }