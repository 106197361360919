<template>
  <div id="start-chat-wrapper">
    <div class="pr-2">
      <el-select
        clearable
        size="small"
        placeholder="Shop number"
        v-model="shop_phone_number"
        style="width: 100%">
        <el-option
          v-for="item in phoneNumbers"
          :label="item.name"
          :key="item.id"
          :value="item.phone_number">
          <span class="select-shop-option-block">
            <i class="el-icon-mobile-phone"></i>
            <span>{{ formatPhoneToReadable(item.phone_number) }}</span>
            <i class="el-icon-office-building ml-3"></i>
            <span>{{ item.name }}</span>
          </span>
        </el-option>
      </el-select>
    </div>
    <div class="pr-2">
      <el-autocomplete
        clearable
        size="small"
        v-model="recipient_phone_faker"
        popper-class="recipient-search-input"
        :fetch-suggestions="recipientSearch"
        :debounce="1000"
        :trigger-on-focus="false"
        placeholder="Name or number"
        @select="handleSelect"
        style="width: 100%">
        <template slot-scope="{ item }">
          <div class="recipient-block">
            <div class="recipient-block-left">
              <div class="recipient-name"><i class="el-icon-user"></i><span>{{ item.name }}</span></div>
              <div class="recipient-phone"><i class="el-icon-mobile-phone"></i><span>{{ item.phone }}</span></div>
            </div>
            <div class="recipient-block-right">
              <span v-if="item.type === 'customer'" class="recipient-type-tag recipient-customer">Customer</span>
              <span v-if="item.type === 'tech'" class="recipient-type-tag recipient-tech">Tech</span>
              <span v-if="item.type === 'salesrep'" class="recipient-type-tag recipient-salesrep">Sales rep</span>
            </div>
          </div>
        </template>
      </el-autocomplete>
    </div>
    <div>
      <el-button
        plain
        circle
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="handleSubmit"
        :loading="submitting">
      </el-button>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import User from '@/scripts/objects/user'
import TwilioTextMessageThread from '@/scripts/objects/twilio_text_message_thread'

export default {
  props: [
    'shopPhoneNumber',
    'recipientPhoneNumber'
  ],
  data: () => ({
    form: {
      recipient_model: null,
      recipient_id: null,
      recipient_name: null
    },
    recipient_phone_faker: '',
    submitting: false
  }),
  computed: {
    visible: get('twilio/textMessages/visible'),
    phoneNumbers: get('twilio/phoneNumbers/forUser'),
    shop_phone_number: {
      get () {
        return this.shopPhoneNumber
      },
      set (value) {
        this.$emit('setShopPhoneNumber', value)
        let defaultPhoneNumberId = null
        if (value !== '') {
          const selectedPhoneNumber = this.phoneNumbers.filter(phone => {
            if (phone.phone_number === value) {
              return phone
            }
          })
          if (selectedPhoneNumber.length !== 0) {
            defaultPhoneNumberId = selectedPhoneNumber[0].id
          }
        }
        User.setDefaultMessagingPhoneNumber(defaultPhoneNumberId)
      }
    },
    recipient_phone_number: {
      get () {
        return this.recipientPhoneNumber
      },
      set (value) {
        this.$emit('setRecipientPhoneNumber', value)
      }
    }
  },
  watch: {
    visible: function (value) {
      if (!value) {
        this.recipient_phone_faker = ''
      }
    },
    recipient_phone_faker: function (value) {
      const formatedPhone = this.formatPhone(value)
      if (this.recipient_phone_number !== formatedPhone) {
        this.form.recipient_model = null
        this.form.recipient_id = null
        this.form.recipient_name = null
      }
      this.recipient_phone_number = formatedPhone
    }
  },
  methods: {
    setSelectedThread: call('twilio/textMessages/setSelectedThread'),
    async handleSubmit () {
      try {
        if (this.shop_phone_number !== '' && this.recipient_phone_number.length === 10) {
          this.submitting = true
          const { data } = await TwilioTextMessageThread.store({
            recipient_model: this.form.recipient_model,
            recipient_id: this.form.recipient_id,
            recipient_name: this.form.recipient_name,
            shop_phone_number: this.shop_phone_number,
            recipient_phone_number: this.recipient_phone_number
          })
          this.setSelectedThread(data)
          this.submitting = false
        } else {
          this.$message.warning('Please select a shop number and a correct recipient phone number to start a chat.')
        }
      } catch {}
    },
    async recipientSearch (query, callback) {
      if (query.length > 0) {
        const recipients = await TwilioTextMessageThread.recipientSearch(query)
        callback(recipients)
      }
    },
    handleSelect (item) {
      this.recipient_phone_number = this.formatPhone(item.phone)
      this.form.recipient_model = item.type
      this.form.recipient_id = item.id
      this.form.recipient_name = item.name
      this.recipient_phone_faker = item.phone
    },
    formatPhone (phone) {
      return phone.replace(/\D+/g, '')
    },
    formatPhoneToReadable (phone) {
      return `${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6)}`
    },
    clear () {
      this.form = {
        recipient_model: null,
        recipient_id: null,
        recipient_name: null
      }
      this.recipient_phone_faker = ''
      this.recipient_phone_number = ''
      this.shop_phone_number = ''
    },
    async startTextMessagingChat (recipient) {
      await (this.recipient_phone_number = this.formatPhone(recipient.phoneNumber))
      this.form.recipient_model = recipient.model
      this.form.recipient_id = recipient.id
      this.form.recipient_name = recipient.name
      this.recipient_phone_faker = recipient.phoneNumber
      await this.handleSubmit()
      this.$emit('reloadThreads')
    }
  }
}
</script>

<style lang="scss">
  #start-chat-wrapper {
    display: flex;
    min-height: 70px;
    padding: 0 15px;
    align-items: center;
    border-bottom: 1px solid #DCDFE6;
    box-shadow: 1px 5px 10px 0 rgb(0, 0, 0, 0.08);
  }

  .select-shop-option-block {
    i {
        color: #409EFF;
        font-size: 18px;
        margin-right: 5px;
      }
  }

  .recipient-search-input {
    width: 375px !important;
    .recipient-block {
      display: flex;
      padding: 10px 0;
      border-bottom: 1px solid #EBEEF5;
      .recipient-block-left {
        flex-grow: 1;
      }
      .recipient-block-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .recipient-type-tag {
          padding: 5px 8px;
          border-radius: 15px;
          color: white;
        }
        .recipient-customer {
          background-color: #E6A23C;
        }
        .recipient-tech {
          background-color: #67C23A;
        }
        .recipient-salesrep {
          background-color: #409EFF;
        }
      }
      i {
        color: #409EFF;
        font-size: 18px;
        margin-right: 10px;
      }
      div {
        line-height: normal;
      }
      .recipient-name {
        color: #303133;
        padding-bottom: 5px;
      }
      .recipient-phone {
        color: #909399;
      }
    }
  }
</style>
