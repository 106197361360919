var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._b(
      {
        ref: "dialog",
        attrs: {
          visible: _vm.value,
          width: "600px",
          "before-close": _vm.handleClose,
          "destroy-on-close": "",
          "custom-class": "gb-el-dialog consumer-dialog",
          top: "24px",
          "append-to-body": ""
        },
        on: { close: _vm.close, open: _vm.onOpen, opened: _vm.opened }
      },
      "el-dialog",
      _vm.$attrs,
      false
    ),
    [
      _vm.consumer
        ? _c("consumer-info", {
            model: {
              value: _vm.data,
              callback: function($$v) {
                _vm.data = $$v
              },
              expression: "data"
            }
          })
        : _c("consumer-new", {
            model: {
              value: _vm.data,
              callback: function($$v) {
                _vm.data = $$v
              },
              expression: "data"
            }
          }),
      _c("consumer-tabs", {
        ref: "tabs",
        attrs: { isMerge: _vm.isMerge },
        model: {
          value: _vm.data,
          callback: function($$v) {
            _vm.data = $$v
          },
          expression: "data"
        }
      }),
      _c(
        "div",
        {
          staticClass: "consumer",
          style: { display: "flex", alignItems: "center" },
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _vm._v(" " + _vm._s(_vm.consumer ? "Edit" : "New Customer") + " "),
          _c("consumer-type-select", {
            model: {
              value: _vm.data,
              callback: function($$v) {
                _vm.data = $$v
              },
              expression: "data"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          !_vm.valid
            ? _c("el-alert", {
                attrs: {
                  type: "error",
                  title: "Consumer form contains errors"
                },
                on: {
                  close: function($event) {
                    _vm.valid = true
                  }
                }
              })
            : _vm._e(),
          _c("el-button", { on: { click: _vm.onClose } }, [_vm._v("Cancel")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.saveConsumer }
            },
            [_vm._v(" Save ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }