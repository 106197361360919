<template>
  <div>
    <h6>Import customers</h6>
    <el-row :gutter="20">
      <el-col :span="12">
        <Multiselect
          v-model="organization"
          :custom-label="({ id, name }) => `(${id}) ${name}`"
          placeholder="Organization"
          open-direction="bottom"
          track-by="id"
          :options="organizations"
          :multiple="false"
          :searchable="true"
          :loading="loading"
          :internal-search="true"
          :clear-on-select="false"
          :close-on-select="true"
          :options-limit="300"
          :limit="3"
          :max-height="600"
          :show-no-results="false"
          :hide-selected="true"
          :block-keys="['Delete']"
          @search-change="asyncFindOrganizations"
          @select="organizationId=arguments[0].id"
          @remove="organizationId=null"
          @open="searchOrganizations()">
        </Multiselect>
      </el-col>
      <el-col :span="6">
        <el-select v-model="type" placeholder="Type">
          <el-option
            v-for="(type, index) in types"
            :key="index"
            :label="type"
            :value="type">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" style="text-align:center">
        <el-button
          @click="downloadTemplate"
          :disabled="!organization || !type">
          <i class="el-icon-download"></i> Template
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col style="text-align:center">
        <div style="padding: 35px 0px;" v-if="file">
          <a :href="file.url" target="_blank" style="margin: 0px 30px;">{{file.filename}}</a>&nbsp;
          <el-button
            icon="el-icon-delete"
            @click="file = null"
            type="danger"
            size="mini"
            circle>
          </el-button>
        </div>
        <div
          v-else
          @drop.prevent="documentDrop"
          @dragleave.stop.prevent="dragLeave"
          @dragenter.stop.prevent="dragEnter"
          @dragover.prevent
          @drop="dragging = false"
          style="padding: 30px 0px;"
          :class="{
            'dragging-overlay': dragging,
            'uploading-file': uploadingDropFile
          }">
          <div v-if="uploadingDropFile">
            <div v-loading="true" class="uploading-spinner"></div>
          </div>
          <filestack-picker
            v-model="filepicker"
            :accept="['text/csv']"
            @closed="closed"
            @uploaded="onUpload"
          >
            <el-button @click="filepicker = true">Upload File</el-button><br>
            <span>or drag & drop</span>
          </filestack-picker>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col style="text-align:center">
        <el-button :disabled="!file" type="primary" @click="start" :loading="importing">Import</el-button>
      </el-col>
    </el-row>
    <!-- <loader v-if="loading" /> -->
  </div>
</template>

<script>
import { organizationRepository } from '@/scripts/repositories/organization'
import Multiselect from 'vue-multiselect'
import exportFromJSON from 'export-from-json'
import * as filestack from 'filestack-js'
export default {
  props: ['filePickerKey'],
  components: {
    Multiselect
    // FilePicker
    // Loader
  },
  data() {
    return {
      filepicker: false,
      adding: null,
      loading: false,
      organization: null,
      searchTerm: null,
      organizations: [],
      type: null,
      types: [
        'Cash',
        'Commercial',
        'Insurance'
      ],
      dragging: 0,
      uploadingDropFile: false,
      file: null,
      importing: false
    }
  },
  mounted() {
    this.client = filestack.init(this.filePickerKey)
  },
  methods: {
    asyncFindOrganizations: function (query) {
      const self = this
      self.loading = true
      self.searchTerm = query
      clearTimeout(self.organizationSearchTimer)
      self.organizationSearchTimer = setTimeout(function () {
        self.searchOrganizations()
      }, 300)
    },
    async searchOrganizations() {
      this.loading = true
      this.organizations = await organizationRepository.searchOrganizations({
        searchTerm: this.searchTerm,
        adminShowAll: true // only because this is in admin utils
      })
      this.loading = false
    },
    async start() {
      this.importing = true
      const { name, url } = this.file
      const { data } = await this.$unum.consumer().post('list/import', { name, url })
      console.log('data:', data)
      if (data.result === 'error') {
        this.$message.warning(`Unable to import all customers. Successfully imported ${data.successfulCount} customers.`)
        const link = document.createElement('a')
        link.href = data.url[0]
        const filename = 'CustomerFailedImports.csv'

        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      } else {
        this.$message.success('All customers imported.')
      }
      this.importing = false
    },
    downloadTemplate() {
      exportFromJSON({
        data: [
          {
            organization_id: this.organization.id,
            is_commercial: this.type === 'Commercial' ? 1 : 0,
            is_insurance: this.type === 'Insurance' ? 1 : 0,
            company_name: this.type === 'Commercial' || this.type === 'Insurance' ? '' : null,
            first_name_1: '',
            first_name_2: null,
            first_name_3: null,
            last_name_1: '',
            last_name_2: null,
            last_name_3: null,
            email_1: '',
            email_2: null,
            email_3: null,
            phone_1: '',
            phone_2: null,
            phone_3: null,
            fax: '',
            address_1: '',
            city_1: '',
            state_1: '',
            zip_1: '',
            address_2: null,
            city_2: null,
            state_2: null,
            zip_2: null,
            address_3: null,
            city_3: null,
            state_3: null,
            zip_3: null
          }
        ],
        fileName: `CustomerImportTemplate - ${this.organization.name} - ${this.type}`,
        exportType: 'csv'
      })
    },
    dragEnter() {
      this.dragging -= 1
    },
    dragLeave() {
      this.dragging += 1
    },
    closed() {
      console.log('closed')
    },
    async onUpload(file) {
      this.file = file
    },
    async documentDrop(e) {
      const fileData = Array.from(e.dataTransfer.files)[0]
      if (fileData.type !== 'text/csv') {
        return this.$message.error(`Incorrect file type ${fileData.type}. Please provide a csv.`)
      }
      this.uploadingDropFile = true
      this.file = await this.client.upload(fileData)
      this.uploadingDropFile = false
    }
  }
}
</script>
