<template>
  <el-dialog
    append-to-body
    top="80px"
    width="800px"
    title="Supplier Details"
    :custom-class="type"
    :visible="visible"
    :before-close="handleClose"
  >
    <div>
      <info-row name="name" :value="searchLabel" />
      <info-row name="address" :value="address" />
      <info-row name="manager" :value="manager" />
      <info-row name="phone" :value="phone" />
      <info-row name="fax" :value="fax" />
      <info-row name="email" :value="email" />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="creating" type="primary" @click="$emit('create')">Use Supplier</el-button>
      <el-button :loading="creating" style="background-color:black; color:white;" @click="handleClose">Cancel</el-button>
    </span>
  </el-dialog>
</template>

<script>
import InfoRow from './InfoRow.vue'

export default {
  components: {
    InfoRow
  },

  props: {
    type: String,
    visible: Boolean,
    searchLabel: String,
    manager: String,
    address_city: String,
    address_street: String,
    address_state: String,
    address_zip: String,
    email: String,
    fax: String,
    phone: String,
    creating: Boolean
  },

  computed: {
    address() {
      return `${this.address_street}, ${this.address_city}, ${this.address_state} ${this.address_zip}`
    }
  },

  methods:  {
    handleClose() {
      this.$emit("update:visible", null)
    }
  }
}
</script>

<style lang="scss">
.pilk {
  .el-dialog__header {
    background-color: #A1D490;
  }
}
.mygrant {
  .el-dialog__header {
    background-color: #568EFC;
    .el-dialog__title {
      color: white !important;
    }
  }
}
.pgw {
  .el-dialog__header {
    background-color: #FFAA33;
  }
}
.igc {
  .el-dialog__header {
    background-color: #103f78;
    .el-dialog__title {
      color: white !important;
    }
  }
}
.service {
  .el-dialog__header {
    background-color: #FCBA03;
  }
}
</style>