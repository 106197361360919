<template>
  <div class="time-slot-picker">
    <el-cascader
      :placeholder="focused ? '' : label"
      :options="options"
      v-model="model"
      @focus="
        $emit('focus', $event);
        focused = true;
      "
      @blur="
        $emit('blur', $event);
        focused = false;
      "
      :size="size"
      ref="ref"
    />

    <i class="material-icons-outline md-access_time md-18" />
  </div>
</template>

<script>
import { getTimeOptions } from '@/scripts/helpers/date.helpers'

export default {
  props: {
    value: String,
    size: String,
    label: String,
    start: { type: String, default: () => '00:00:00' },
    end: { type: String, default: () => '23:15:00' }
  },
  data() {
    return {
      focused: false,
      expand: { up: false, down: false }
    }
  },
  computed: {
    model: {
      get() {
        return [this.value]
      },
      set([v]) {
        this.$emit('input', v)
      }
    },
    timeSlots() {
      return []
    },
    options() {
      return [
        {
          label: 'Custom Time Frame',
          children: [...this.timeSlots]
        },
        ...getTimeOptions(this.start, this.end)
      ]
    }
  },
  methods: {
    focus() {
      console.log('focus')
      this.$refs.ref.$el.click()
      // this.$refs.ref.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.time-slot-picker {
  position: relative;
  .md-access_time {
    position: absolute;
    left: 10px;
    top: 10px;
    color: rgb(192, 196, 204);
  }
}
</style>

<style lang="scss">
.time-slot-picker {
  .el-input__prefix {
    width: 25px;
    @include flexAllCenter;
  }
  .expand-button {
    width: 100%;
    padding: 0;
  }
  .el-input__inner {
    padding-left: 32px;
  }
}
</style>
