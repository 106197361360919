var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-mb-3 t-border t-border-solid t-border-gray-300 t-rounded-md\n    t-shadow-sm t-flex"
    },
    [
      _c("div", { staticClass: "t-flex-grow t-px-4 t-py-2" }, [
        _c("div", { staticClass: "t-text-base t-font-medium" }, [
          _vm._v(" Order # " + _vm._s(_vm.order.orderNumber) + " ")
        ]),
        _vm.order.purchaseOrderId
          ? _c(
              "div",
              {
                staticClass: "t-flex t-mt-1",
                on: {
                  click: function($event) {
                    return _vm.openPurchaseOrder(_vm.order.purchaseOrderId)
                  }
                }
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "t-text-white t-px-2 t-rounded-md t-cursor-pointer",
                    style: _vm.supplierColor
                      ? "background-color: " + _vm.supplierColor
                      : "background-color: #000000"
                  },
                  [
                    _vm._v(
                      " PO " + _vm._s(_vm.order.purchaseOrderInternalId) + " "
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "t-my-2" }, [
          _c(
            "a",
            {
              staticClass:
                "t-bg-white t-border t-border-solid t-border-blue-400\n          t-rounded-md t-px-2 t-py-1 t-cursor-pointer t-text-blue-500\n          hover:t-bg-blue-500 hover:t-border-blue-500\n          hover:t-text-white t-text-xs",
              on: {
                click: function($event) {
                  return _vm.openOrderDetails(_vm.order)
                }
              }
            },
            [_vm._v(" Show details ")]
          )
        ])
      ]),
      _c("div", { staticClass: "t-flex t-flex-col t-py-2 t-w-40" }, [
        _c("div", [
          _c("i", { staticClass: "el-icon-user t-mr-1 t-text-blue-600" }),
          _c("span", { staticClass: "t-text-base" }, [
            _vm._v(" " + _vm._s(_vm.order.username) + " ")
          ])
        ]),
        _c("div", [
          _c("i", { staticClass: "el-icon-time t-mr-1 t-text-blue-600" }),
          _c("span", [_vm._v(_vm._s(_vm.formatTimestamp(_vm.order.createdAt)))])
        ])
      ]),
      _c("div", { staticClass: "t-p-2 t-w-56 t-break-normal t-leading-6" }, [
        _c("div", { staticClass: "t-flex t-items-baseline" }, [
          _c("div", { staticClass: "t-w-16 t-text-xs t-text-gray-500" }, [
            _vm._v("Method")
          ]),
          _c("div", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.order.shipViaCode === "DEL" ? "Delivery" : "Will Call"
                ) +
                " "
            )
          ])
        ]),
        _vm.order.shipViaCode === "DEL"
          ? _c("div", [
              _c("div", { staticClass: "t-flex t-items-baseline" }, [
                _c("div", { staticClass: "t-w-16 t-text-xs t-text-gray-500" }, [
                  _vm._v("Ship date")
                ]),
                _c("div", { staticClass: "t-truncate" }, [
                  _vm._v(_vm._s(_vm.formatShipDate(_vm.order.shipDate)))
                ])
              ]),
              _c("div", { staticClass: "t-flex t-items-baseline" }, [
                _c("div", { staticClass: "t-w-16 t-text-xs t-text-gray-500" }, [
                  _vm._v("Ship adr")
                ]),
                _c("div", { staticClass: "t-truncate" }, [
                  _vm._v(_vm._s(_vm.order.shipToAddress))
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        {
          staticClass:
            "t-font-bold t-px-3 t-bg-gray-100 t-border-solid t-border-0\n      t-border-l-8 t-border-gray-300 t-rounded-r-md t-self-stretch t-flex\n      t-items-center"
        },
        [
          _c(
            "div",
            { staticClass: "t-w-24 t-flex-1 t-text-lg t-text-center" },
            [_vm._v(" $" + _vm._s(_vm.roundPrice(_vm.order.orderTotal)) + " ")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }