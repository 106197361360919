<template>
  <div class="t-flex">
    <el-table
      v-loading="fetching"
      :data="inGlassBiller"
      style="width: 100%">
      <el-table-column
        prop="id"
        label="Invoice #">
        <template slot-scope="scope">
          <a :href="`/jobs/${scope.row.id}`" target="_blank">{{scope.row.id}}</a>
          <i
            class="material-icons md-check_circle qbo-synced-icon"
            v-if="scope.row.balancesMatch"
          />
        </template>
      </el-table-column>
      <el-table-column
        :formatter="formatDate"
        prop="invoice_date"
        label="Invoice Date">
      </el-table-column>
      <el-table-column
        :formatter="formatBalance"
        prop="total_balance"
        label="Balance (GB)">
      </el-table-column>
      <el-table-column
        :formatter="formatBalance"
        prop="qboBalance"
        label="Balance (QBO)">
      </el-table-column>
      <el-table-column
        label="Actions"
        align="center">
        <template slot-scope="scope">
          <el-popover
            placement="right"
            width="180"
            :v-model="popoverVisible && popoverVisible === scope.row.id">
            <div style="text-align: left">
              <el-button
                type="primary"
                size="text"
                @click="exportInvoice(scope.row.shop_id, scope.row.id, false)">
                Sync Invoice
              </el-button>
              <el-button
                type="primary"
                size="text"
                style="margin: 0;"
                @click="exportInvoice(scope.row.shop_id, scope.row.id, true)">
                Sync Invoice & Payments
              </el-button>
            </div>
            <i
              class="el-icon-more"
              slot="reference"
              style="cursor:pointer;"
              @click="popoverVisible === scope.row.id"></i>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'pinia'
import { useQboSyncStore } from '@/components/modals/QuickBooksSyncDialog/qboSync.store.js'

export default {
  data() {
    return {
      popoverVisible: null
    }
  },
  computed: {
    ...mapState(useQboSyncStore, ['inGlassBiller', 'fetching', 'startingExport'])
  },
  methods: {
    ...mapActions(useQboSyncStore, ['exportInvoiceToQbo']),
    formatDate (row, column, cellValue, index) {
      return moment.utc(cellValue).startOf('day').format('YYYY-MM-DD')
    },
    formatBalance (row, column, cellValue, index) {
      return cellValue === '-' ? cellValue : `$${cellValue}`
    },
    exportInvoice: async function (shopId, id, includePayments) {
      const status = await this.exportInvoiceToQbo(shopId, id, includePayments)
      if (status === 'success') {
        this.$message.success('Success! Your QuickBooks Online export has started.')
      } else {
        this.$message.error('Something went wrong')
      }
    }
  }
}
</script>

<style lang="scss">
</style>
