var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { attrs: { for: "title" } }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "form-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue"
          }
        ],
        ref: "input",
        class: _vm.classes,
        style: _vm.inStyle,
        attrs: {
          type: "text",
          placeholder: _vm.placeholder,
          title: _vm.title,
          disabled: _vm.inDisabled
        },
        domProps: { value: _vm.inputValue },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.inputValue = $event.target.value
          }
        }
      }),
      _c("div", { staticClass: "invalid-feedback" }, [
        _vm._v(" " + _vm._s(_vm.internalFailMessage) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }