<template>
  <el-dialog
    append-to-body
    :visible="visible"
    :before-close="handleClose"
    width="800px"
    top="100px"
    class="t-font-sans"
  >
    <div slot="title" class="t-text-base t-text-gray-700">{{ title }}</div>

    <el-table :data="units" size="mini">
      <el-table-column prop="id" label="Inv Unit #" />
      <el-table-column label="Job #">
        <template slot-scope="scope">
          <a
            v-if="scope.row.jobId"
            target='_blank'
            class="t-font-normal t-text-xs t-text-blue-500"
            :href="'/jobs/' + scope.row.jobId"
          >
            {{ scope.row.jobId }}
          </a>
        </template>
      </el-table-column>
      <el-table-column label="PO #">
        <template slot-scope="scope">
          <span
            v-if="scope.row.purchaseOrderId"
            class="t-font-normal t-text-xs t-text-blue-500 hover:t-underline t-cursor-pointer"
            @click="openPO(scope.row.purchaseOrderId)"
          >
            {{ scope.row.purchaseOrderInternalId }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Receive #">
        <template slot-scope="scope">
          <span
            v-if="scope.row.receiveId"
            class="t-font-normal t-text-xs t-text-blue-500 hover:t-underline t-cursor-pointer"
            @click="openReceive(scope.row.receiveId)"
          >
            {{ scope.row.receiveInternalId }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="TechPU #">
      <template slot-scope="scope">
          <span
            v-if="scope.row.techPickUpId"
            class="t-font-normal t-text-xs t-text-blue-500 hover:t-underline t-cursor-pointer"
            @click="openTechPickup(scope.row.techPickUpId)"
          >
            {{ scope.row.techPickUpInternalId }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="t-text-right t-mt-6">
      Total: <span class="t-text-lg t-mx-2">{{ units.length }}</span>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    title: String,
    units: Array,
    shop: Object
  },

  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
    openPO(id) {
      this.$store.dispatch('warehouse/openPurchaseOrderDialog', id)
    },
    openReceive(id) {
      this.$store.dispatch('warehouse/openReceiveDialog', id)
    },
    openTechPickup(id) {
      this.$store.dispatch('warehouse/openTechPickUpDialog', id)
    }
  }
}
</script>
