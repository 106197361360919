var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-flex t-flex-col t-h-full" }, [
    _vm._m(0),
    _vm.loading
      ? _c("div", { staticClass: "t-flex-grow t-flex t-justify-center" }, [
          _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ]
          })
        ])
      : _c(
          "div",
          { staticClass: "t-px-6 t-overflow-y-auto t-pb-12" },
          _vm._l(_vm.otherUsers, function(identity) {
            return _c(
              "div",
              {
                key: identity,
                staticClass:
                  "direct-channel-row t-flex t-items-center t-border-solid\n             t-border-0 t-border-b t-border-gray-200 t-p-3"
              },
              [
                _c("div", { staticClass: "t-flex-grow" }, [
                  _c("div", { staticClass: "t-text-gray-700 t-text-base" }, [
                    _c("i", { staticClass: "el-icon-user" }),
                    _c("span", { staticClass: "t-ml-1" }, [
                      _vm._v(_vm._s(identity))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "t-flex t-text-gray-600 t-text-xs t-mt-1" },
                    [
                      _vm.directChannels.find(function(ch) {
                        return ch.name === identity && ch.lastMessageDate
                      })
                        ? _c("div", [
                            _c("span", [_vm._v("Last message")]),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDate(
                                      _vm.directChannels.find(function(ch) {
                                        return ch.name === identity
                                      }).lastMessageDate
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _c("div", { staticClass: "t-italic" }, [
                            _vm._v(" No messages yet ")
                          ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "button-section" }, [
                  _vm.directChannels.find(function(ch) {
                    return ch.name === identity
                  })
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "t-border-solid t-border t-border-blue-500 t-bg-blue-500\n                t-text-blue-100 t-px-3 t-py-1 t-rounded t-cursor-pointer\n                hover:t-border-blue-400 hover:t-bg-blue-400",
                          on: {
                            click: function($event) {
                              return _vm.open(identity)
                            }
                          }
                        },
                        [_vm._v(" Open ")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "t-border-solid t-border t-border-green-500 t-bg-green-500\n                t-text-green-100 t-px-3 t-py-1 t-rounded t-cursor-pointer\n                hover:t-border-green-400 hover:t-bg-green-400",
                          on: {
                            click: function($event) {
                              return _vm.start(identity)
                            }
                          }
                        },
                        [_vm._v(" Start ")]
                      )
                ])
              ]
            )
          }),
          0
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "t-pl-6 t-h-14 t-border-solid t-border-0 t-border-b\n            t-border-gray-200 t-flex t-items-center"
      },
      [
        _c("div", { staticClass: "t-text-base t-text-gray-700" }, [
          _vm._v("Start direct messaging")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }