<template>
  <el-dialog
    @open='dialogAboutToAppear'
    :visible.sync="visible"
    title='Shop Details'
    id='shopDialog'
    destroy-on-close
    custom-class="gb-el-dialog"
    :modal-append-to-body="false"
    append-to-body
    width="800px">
    <div class='form-row'>
      <div v-if='adminCheck()' class='form-group col-md-12'>
        <label for='shopName'>Name</label>
        <input id='shopName' type='text' v-model="shop.name" class='form-control form-control-sm autocomplete-off'/>
      </div>
      <div v-if='!adminCheck()' class='form-group col-md-12'>
        <label for='shopName'>Name</label>
        <p id='shopName'>{{shop.name}}</p>
      </div>
    </div>
    <div class='form-row'>
      <div class='form-group col-md-6' v-if='adminCheck()'>
        <label for='shopStatusSelect'>STATUS</label>
        <select id='shopStatusSelect' class='form-control form-control-sm autocomplete-off' v-model="shop.status">
          <option value='active'>Active</option>
          <option value='disabled'>Disabled</option>
        </select>
      </div>
      <div class='form-group col-md-6' v-if='!adminCheck()'>
        <label for='shopStatusDisplay'>STATUS</label>
        <p v-if="shop.status === 'active'" id='shopStatusDisplay' class='form-group col-md-12'>Active</p>
        <p v-else-if="shop.status === 'disabled'" id='shopStatusDisplay' class='form-group col-md-12'>Disabled</p>
      </div>
      <div class='form-group col-md-2'>
        <label for='shopId'>Id</label>
        <span id='shopId' class='form-control form-control-sm' style='background-color: #EDC9AF;'><strong>{{shop.id > 0 ? shop.id : 'NEW'}}</strong></span>
      </div>
    </div>

    <div class='form-row'>
      <div class='form-group col-md-12' v-if='adminCheck()'>
        <label>Organization</label>
          <Multiselect
            v-model="selectedOrganization"
            :custom-label="({ id, name }) => `(${id}) ${name}`"
            placeholder="Type to search"
            open-direction="bottom"
            track-by="id"
            :options="organizations"
            :multiple="false"
            :searchable="true"
            :loading="organizationsLoading"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :options-limit="300"
            :limit="3"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            :block-keys="['Delete']"
            @search-change="asyncFindOrganizations"
            @select="shop.organization_id=arguments[0].id"
            @remove="shop.organization_id=null"
            @open="searchOrganizations()">
          </Multiselect>
          <span style="white-space:pre-wrap;word-break:break-word;" v-if="shop && shop.id">*NOTICE: Be aware that changing an organization here is only recommended for brand new shops. Changing an organization here will not change any ids on any data. If there are any jobs on this shop, they may no longer work as they may contain links to consumers/vendors/etc in the original organization. If you want to edit existing shops, use the merge feature to merge organizations.</span>
        </div>
        <div class='form-group col-md-6' v-if='!adminCheck()'>
          <label>Organization</label>
          <span v-if="selectedOrganization && selectedOrganization.name">{{ selectedOrganization.name }}</span>
        </div>
      </div>
    <div role='tablist'>
      <div role='tab'>
        <a href='javascript:void(0)' v-b-toggle.shopInfoGroup>Shop Information <v-icon v-if='!isShopInfoOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopInfoOpen' class='caret' scale='1.5' name='caret-down'/></a>
      </div>
      <div class='form-row'>
        <div class='form-group col-md-12'>
          <b-collapse class='px-1 pb-1' id='shopInfoGroup' v-model='isShopInfoOpen' accordion='shopMainAccordion' role='tabpanel'>
            <div class='form-row'>
              <div class='form-group col-md-4'>
                <Phone :title='"Phone"' :value.sync='shop.phone' :placeholder='""' :required='false'/>
              </div>
              <div class='form-group col-md-4'>
                <Phone :title='"Fax"' :value.sync='shop.fax' :placeholder='""' :required='false'/>
              </div>
              <div class='form-group col-md-4'>
                <Email :title='"Email"' :value.sync='shop.email' :placeholder='""' :required='false'/>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <label for='shopWebsite'>Website</label>
                <input type="text" class='form-control form-control-sm autocomplete-off' v-model="shop.web"/>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-9'>
                <label for='shopAddressLine1'>Address</label>
                <address-input v-model="shop.g_address"/>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck()'>
              <div class='form-group col-md-12'>
                <label for='shopNotes'>Notes</label>
                <textarea id='shopNotes' class='form-control form-control-sm' rows='3' v-model='shop.notes'/>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopCustomPdfField><label>Custom PDF field for shop <v-icon v-if='!isShopCustomPdfOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopCustomPdfOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopCustomPdfField' v-model='isShopCustomPdfOpen' accordion='shopInfoNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col-md-12'>
                      <div class='form-row'>
                        <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                          <label for='shopCustomPdf'>Text to display on every PDF for this shop</label>
                          <input type='text' class='form-control form-control-sm autocomplete-off' v-model='shop.shop_special_pdf_field'/>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopCustomerQuoteMessageField><label>Customer Quote Message <v-icon v-if='!isShopCustomerQuoteMessageOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopCustomerQuoteMessageOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopCustomerQuoteMessageField' v-model='isShopCustomerQuoteMessageOpen' accordion='shopInfoNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col-md-12'>
                      <div class='form-row'>
                        <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                          <label for='customerQuoteMessage'>Text to display on every quote PDF for this shop (Max 400 characters)</label>
                          <textarea id='customerQuoteMessage' class='form-control form-control-sm' rows='2' maxlength="400" v-model='shop.customer_quote_message'/>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopCustomerWorkOrderMessageField><label>Customer Work Order Message <v-icon v-if='!isShopCustomerWorkOrderMessageOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopCustomerWorkOrderMessageOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopCustomerWorkOrderMessageField' v-model='isShopCustomerWorkOrderMessageOpen' accordion='shopInfoNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col-md-12'>
                      <div class='form-row'>
                        <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                          <label for='customerWorkOrderMessage'>Text to display on every work order PDF for this shop (Max 400 characters)</label>
                          <textarea id='customerWorkOrderMessage' class='form-control form-control-sm' rows='2' maxlength="400" v-model='shop.customer_workorder_message'/>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopCustomerInvoiceMessageField><label>Customer Invoice Message <v-icon v-if='!isShopCustomerInvoiceMessageOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopCustomerInvoiceMessageOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopCustomerInvoiceMessageField' v-model='isShopCustomerInvoiceMessageOpen' accordion='shopInfoNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col-md-12'>
                      <div class='form-row'>
                        <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                          <label for='customerInvoiceMessage'>Text to display on every invoice PDF for this shop (Max 400 characters)</label>
                          <textarea id='customerInvoiceMessage' class='form-control form-control-sm' rows='2' maxlength="400" v-model='shop.customer_invoice_message'/>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopLegalDisclaimerField><label>Legal Disclaimer <v-icon v-if='!isShopDisclaimerOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopDisclaimerOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopLegalDisclaimerField' v-model='isShopDisclaimerOpen' accordion='shopInfoNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col' >
                      <a href='javascript:void(0)' v-if='adminCheck() || accountManagerCheck()' @click="addAOBClicked" class="label label-gray label-small">Add AOB</a>
                      <CheckBoxLabel v-if='adminCheck() || accountManagerCheck()' label='Auto Update AOB' :value.sync='shop.auto_update_aob'/>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                      <label for='shopLegalDisclaimer'>Legal Disclaimer</label>
                      <textarea id='shopLegalDisclaimer' class='form-control form-control-sm autocomplete-off' rows='7' v-model='shop.disclaimer'/>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div role='tab'>
        <a href='javascript:void(0)' v-b-toggle.shopDetailsGroup>Shop Details <v-icon v-if='!isShopDetailsOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopDetailsOpen' class='caret' scale='1.5' name='caret-down'/></a>
      </div>
      <div class='form-row'>
        <div class='form-group col-md-12'>
          <b-collapse class='px-1 pb-1' id='shopDetailsGroup' v-model='isShopDetailsOpen' accordion='shopMainAccordion' role='tabpanel'>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-4'>
                <label for='shopSafeliteEdiNumberField'>Safelite EDI Number</label>
                <input v-if="adminCheck()" type='text' id='shopSafeliteEdiNumberField' class='form-control form-control-sm autocomplete-off' v-model='shop.safelite_edi_number'/>
                <p v-else id='shopSafeliteEdiNumberField'>{{shop.safelite_edi_number}}</p>
              </div>
              <div class='form-group col-md-4'>
                <label for='shopQuestEdiNumberField'>Quest EDI Number</label>
                <input v-if="adminCheck()" type='text' id='shopQuestEdiNumberField' class='form-control form-control-sm autocomplete-off' v-model='shop.quest_edi_number'/>
                <p v-else id='shopQuestEdiNumberField'>{{shop.quest_edi_number}}</p>
              </div>
              <div class='form-group col-md-4'>
                <label for='shopLynxEdiNumberField'>Lynx/Gerber/Harmon EDI Number</label>
                <input v-if="adminCheck()" type='text' id='shopLynxEdiNumberField' class='form-control form-control-sm autocomplete-off' v-model='shop.lynx_edi_number'/>
                <p v-else id='shopLynxEdiNumberField'>{{shop.lynx_edi_number}}</p>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck()'>
              <div class='form-group col-md-4'>
                <label for='shopTaxOrSsn'>Choose tax ID or SSN</label>
                <select v-model='shop.ssn_vs_tax_id' id='shopTaxOrSsn' class='form-control form-control-sm autocomplete-off'>
                  <option value='taxid'>Tax ID</option>
                  <option value='ssn'>SSN</option>
                </select>
              </div>
              <div class='form-group col-md-8'>
                <div class='form-row'>
                  <div class='form-group col-md-12'>
                    <label for='shopTaxIdSsnField'>Tax ID / SSN</label>
                    <input id='shopTaxIdSsnField' type='text' v-model='shop.ssn_or_tax_id' class='form-control form-control-sm autocomplete-off'/>
                  </div>
                </div>
                <div class='form-row'>
                  <div class='form-group col-md-12'>
                    <CheckBoxLabel label="Include on PDF" :value.sync="shop.include_tax_id_on_pdf"/>
                  </div>
                </div>
              </div>
            </div>
            <div class='form-row' v-else-if='accountManagerCheck()'>
              <div class='form-group col-md-8'>
                <div class='form-row'>
                  <div class='form-group col-md-12'>
                    <label for='shopTaxIdSsnField'>{{shop.ssn_vs_tax_id === 'taxid' ? 'Tax Id' : 'SSN'}}</label>
                    <p id='shopTaxIdSsnField'>{{shop.ssn_or_tax_id}}</p>
                  </div>
                </div>
                <div class='form-row'>
                  <div class='form-group col-md-12'>
                    <CheckBoxLabel label="Include on PDF" :value.sync="shop.include_tax_id_on_pdf"/>
                  </div>
                </div>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-5'>
                <br>
                <CheckBoxLabel label='Auto-Increment Internal Number' :value.sync='shop.is_internal_number_auto_increment'/>
              </div>
              <div class='form-group col-md-7' v-if='shop.is_internal_number_auto_increment'>
                <label for='shopInternalStartNumber'>Internal number start</label>
                <input id='shopInternalStartNumber' type='text' class='form-control form-control-sm autocomplete-off' v-model='shop.internal_number_start'/>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-3'>
                <label for='tsignVersionSelect'>Tsign/Smssign Version</label>
                <b-form-select id="tsignVerisonSelect" v-model="shop.tsign_version" :options="tsignVersionOptions" size="sm"></b-form-select>
              </div>
              <div class='form-group col-md-3'>
                <label for='tsignVersionSelect'>PDF Print/Share Version</label>
                <b-form-select id="printShareVerisonSelect" v-model="shop.pdf_default" :options="pdfVersionOptions" size="sm"></b-form-select>
              </div>
            </div>
            <div class="form-row">
              <div class='form-group col-md-4'>
                <label for='adhesiveDefaultSelect'>Adhesive Default</label>
                <b-form-select id="adhesiveDefaultSelect" size="sm" v-model="shop.adhesive_default" :options="adhesiveDefaultOptions"></b-form-select>
              </div>
              <div class='form-group col-md-4'>
                <label for='defaultInvoiceTier'>Default Invoice Terms</label>
                <b-form-select id="defaultInvoiceTier" size="sm" v-model="shop.default_invoice_tier" :options="invoiceTierOptions"></b-form-select>
              </div>
              <div class='form-group col-md-4'>
                <label for='defaultInvoiceTier'>Default Install Context</label>
                <b-form-select id="defaultInstallContext" size="sm" v-model="shop.default_install_context" :options="installContextOptions"></b-form-select>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck()'>
              <div class='form-group col-md-12'>
                <CheckBoxLabel label='Show Glaxis Inbox' id="showGlaxisInbox" :value.sync='shop.show_glaxis_inbox'/>
              </div>
            </div>
            <div class='form-row' v-if="adminCheck() && shop.show_glaxis_inbox">
              <div class="form-group col-md-3">
                <label for='glaxisCompanyId'>Glaxis Company ID</label>
                <input id='glaxisCompanyId' class='form-control form-control-sm autocomplete-off' v-model='shop.glaxis_company_id'/>
              </div>
              <div class="form-group col-md-6">
                <label for="glaxisShopName">Glaxis Shop Name</label>
                <input id='glaxisShopName' class='form-control form-control-sm autocomplete-off' v-model='shop.glaxis_shop_name'/>
              </div>
            </div>
            <div class='form-row' v-if='!adminCheck() && accountManagerCheck()'>
              <div class='form-group col-md-4'>
                <label for='showGlaxisInbox'>Show Glaxis Inbox</label>
                <p id='showGlaxisInbox'>{{shop.show_glaxis_inbox ? 'Yes' : 'No'}}</p>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <CheckBoxLabel label='Use Find Tax Rates Option' :value.sync='shop.colorado_tax_option'/>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-3'>
                <CheckBoxLabel label='Inventory Tracking' :value.sync='shop.inventory_tracking'/>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-3'>
                <CheckBoxLabel label='Auto Sms Payment Receipt' :value.sync='shop.auto_sms_payment_receipt'/>
              </div>
            </div>
            <div class='form-row'>
              <div v-if='shop.inventory_tracking' class='form-group col-md-3'>
                <label>Costing Method</label>
                <select v-model='shop.inventory_costing_method' class='form-control form-control-sm autocomplete-off'>
                  <option value='fifo'>FIFO</option>
                  <option value='lifo'>LIFO</option>
                  <!-- HIDDING FOR NOW -- WILL ADD BACK IF AGGRESSIVELY REQUESTED <option vlaue='avg'>Average Cost</option> -->
                </select>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck()'>
              <div class='form-group col-md-12'>
                <CheckBoxLabel label='Right Part Access' :value.sync='shop.right_part_access'/>
              </div>
            </div>
            <div class='form-row' v-else-if='accountManagerCheck()'>
              <div class='form-group col-md-12'>
                <label for='rightPartAccess'>Right Part Access</label>
                <p id='rightPartAccess'>{{shop.right_part_access ? 'Yes' : 'No'}}</p>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-12'>
                <CheckBoxLabel label='Created Consumers Auto Set Install Address' :value.sync='shop.created_consumers_auto_set_install_address'/>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-4'>
                <label for='royaltyPercentage'>Royalty %</label>
                <input type='number' id='royaltyPercentage' class='form-control form-control-sm autocomplete-off' v-model='shop.royalty_percentage'/>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopEmailsField><label>Emails <v-icon v-if='!isShopEmailsOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopEmailsOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopEmailsField' v-model='isShopEmailsOpen' accordion='shopDetailsNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Edi Notification Emails&nbsp;&nbsp;<a href='javascript:void(0)' @click='addEmailClicked' class='label label-gray label-sm'><i class="fa fa-plus"></i> New Email</a></label>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-4 mt-2' v-for='(email, index) in shop.emails' :key='index + "-email"'>
                          <InputWithDelete type='email' :parentValue.sync='email.email' @remove='removeEmail(email, index)'/>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopLogoField><label>Logo <v-icon v-if='!isShopLogoOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopLogoOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopLogoField' v-model='isShopLogoOpen' accordion='shopDetailsNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                      <div class='form-row'>
                        <div class='form-group col'>
                          <a href='javascript:void(0)' @click='gettingFilepicker = true'><FilePicker :buttonLabel="shop.logo && shop.logo.length > 0 ? 'Change Logo':'Add Logo'" :filePickerKey="filepickerKey" :dialogToReturnTo="'shopDialog'" :handleFilePickerResponse="handleFilePickerResponse"/></a>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col wrapper' v-if='shop.logo && shop.logo.length > 0'>
                          <img :src='shop.logo' fluid alt='Current Shop Logo'/>
                          <span @click='shop.logo = ""'><v-icon class='close-button' name="minus-circle" scale="1.5"/></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopQuickBooksOnlineField><label>Quickbooks Online Credentials <v-icon v-if='!isShopQuickbooksOnlineCredentialsOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopQuickbooksOnlineCredentialsOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopQuickBooksOnlineField' v-model='isShopQuickbooksOnlineCredentialsOpen' accordion='shopDetailsNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                      <div v-if="(!shop.qbo_refresh_token || shop.qbo_refresh_token.length == 0)" class='form-row'>
                        <div class='form-group col-md-12'>
                          <p>Click on the button below in order to connect your QuickBooks Online file to this shop:</p>
                          <img @click="connectToQBOClicked()" ref="qboButton" src="../../assets/img/intuit/C2QB_white_btn_lg_default.png" style="width:100%; max-width:220px;cursor: pointer;" />
                          <p>(NOTE: If you have already connected to QuickBooks through the old version of GlassBiller, you will have to connect again on this new version.</p>
                        </div>
                      </div>
                      <div v-if="(shop.qbo_refresh_token && shop.qbo_refresh_token.length > 0)" class='form-row'>
                        <div class='form-group col-md-12'>
                          <label for='qboCredentials'>Disconnect From QuickBooks Online?</label><br>
                            <button type='button' class='btn btn-secondary' variant="primary" @click="disconnectFromQBOClicked()">Disconnect</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class='form-row' v-if='hasOtherPaymentProcessingCredentials("authorize.net") && (adminCheck() || accountManagerCheck())'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)'><label>Authorize.net Credentials cannot be added because Square Credentials have been added</label></a>
              </div>
            </div>
            <div class='form-row' v-else-if='!hasOtherPaymentProcessingCredentials("authorize.net") && (adminCheck() || accountManagerCheck())'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopAuthorizeNetField><label>Authorize.net Credentials <v-icon v-if='!isShopAuthorizeCredentialsOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopAuthorizeCredentialsOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row' v-if='adminCheck() || accountManagerCheck()'>
              <div class='form-group col-md-12'>
                <b-collapse v-if='!hasOtherPaymentProcessingCredentials("authorize.net")' id='shopAuthorizeNetField' v-model='isShopAuthorizeCredentialsOpen' accordion='shopDetailsNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <p>Follow These Steps to Connect an Authorize.Net Account to GlassBiller:</p>
                          <ol>
                            <li>Login to your account on Authorize.Net and visit the "Account" section.</li>
                            <li>Click on the "API Credentials & Keys" link under the "Security Settings" area.</li>
                            <li>Copy and paste your "API Login ID" on that page into the box below.</li>
                            <li>In the "Create New Key(s)" section, fill in the answer to your secret question and select the "New Transaction Key" option. Upon submitting, you will be directed to a page with your transaction key, that you can copy and paste into the box below.</li>
                            <li>Return to the "Account" section and click on the "Manage Public Client Key" link under the "Security Settings" area.</li>
                            <li>If you have previously created a client key, you can copy and paste that key into the box below. Otherwise in the "Create New Public Client Key" section, fill in the answer to your secret question and submit. Upon submitting, you will be directed to a page with your public client key, that you can copy and paste into the box below.</li>
                          </ol>
                          <p>(NOTE: If you have ever previously created and used a "Transaction Key", by creating a new key, that old key will expire. Either use that old key in GlassBiller without creating and using a new one, or update that old key with the new one in any other integrations.)</p>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label for='authorizeApiLoginId'>Authorize.net API Login ID</label>
                          <input type='text' id='authorizeApiLoginId' class='form-control form-control-sm autocomplete-off' v-model='shop.authorizenet_api_login_id' @blur='updateAuthorizeNetCredentials'/>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label for='authorizeTransactionKey'>Authorize.net Transaction Key</label>
                          <input type='text' id='authorizeTransactionKey' class='form-control form-control-sm autocomplete-off' v-model='shop.authorizenet_transaction_key' @blur='updateAuthorizeNetCredentials'/>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label for='authorizePublicClientKey'>Authorize.net Public Client Key</label>
                          <input type='text' id='authorizePublicClientKey' class='form-control form-control-sm autocomplete-off' v-model='shop.authorizenet_public_client_key' @blur='updateAuthorizeNetCredentials'/>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class='form-row' v-if='hasOtherPaymentProcessingCredentials("square") && (adminCheck() || accountManagerCheck())'>
              <div class='form-group col-md-12'>
                <label>Square Credentials Cannot be added because Authorize.Net Credentials have been added</label>
              </div>
            </div>
            <div class='form-row' v-if='!hasOtherPaymentProcessingCredentials("square")'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopSquareField><label>Square Credentials <v-icon v-if='!isShopSquareCredentialsOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopSquareCredentialsOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse
                  id='shopSquareField'
                  v-model='isShopSquareCredentialsOpen'
                  accordion='shopDetailsNested'
                  role='tabpanel'
                >
                  <div class='form-row'>
                    <div class='m-3 t-font-sans t-p-4 t-rounded t-w-full' id='shopNestedGroup'>
                      <div v-if="!hasSquareCredentials && (adminCheck() || accountManagerCheck())" class='form-group col-md-12'>
                        <p>Click the following link to connect your Square Account to GlassBiller:</p>
                        <a href="javascript:void(0)" @click="connectToSquareClicked()">Connect To Square</a>
                      </div>
                      <div v-if="hasSquareCredentials">
                        <div
                          v-if="hasSquareCredentials && (adminCheck() || accountManagerCheck())"
                          class='t-mb-4'
                        >
                          <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="mini"
                            plain
                            @click="disconnectFromSquareClicked()"
                          >
                            Disconnect From Square
                          </el-button>
                        </div>
                        <div class='t-mb-4'>
                          <div class="t-text-xs">Square Location (needed for techSIDE)</div>
                          <el-select size="mini" :loading="squareLocationsLoading" @visible-change="getSquareLocations" v-model="shop.square_location_id">
                            <el-option
                              v-for="location in squareLocations"
                              :key="location.id"
                              :label="location.label"
                              :value="location.id"
                              >
                              <span style="float: left">{{ location.label }}</span>
                              <span style="float: right; color: #8492a6; font-size: 10px">{{ location.id }}</span>
                            </el-option>
                          </el-select>
                        </div>
                        <div class='t-mb-4'>
                          <div class="t-text-xs">Processing Currency</div>
                          <el-select size="mini" v-model="shop.square_currency">
                            <el-option value='USD'>USD</el-option>
                            <el-option value='CAD'>CAD</el-option>
                          </el-select>
                        </div>
                        <SquareDevicesTable
                          class="t-mb-4"
                          :shopId="shop.id"
                        />
                        <el-button
                          v-if="squareDevicesFormIsOpen === false"
                          type="primary"
                          icon="el-icon-plus"
                          size="mini"
                          plain
                          @click="squareDevicesFormIsOpen = true"
                        >
                          Add Devices
                        </el-button>
                        <SquareDevicesForm
                          v-if="squareDevicesFormIsOpen === true"
                          :shopId="shop.id"
                          :locationId="shop.square_location_id"
                          @close="closeSquareDevicesForm"
                        />
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <!-- <div class='form-row' v-if='hasOtherPaymentProcessingCredentials("clover") && (adminCheck() || accountManagerCheck())'>
              <div class='form-group col-md-12'>
                <label>Clover Credentials Cannot be added because either Authorize.Net or Square Credentials have been added</label>
              </div>
            </div>
            <div class='form-row' v-if='!hasOtherPaymentProcessingCredentials("clover")'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopCloverField><label>Clover Credentials <v-icon v-if='!isShopCloverCredentialsOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopCloverCredentialsOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse
                  id='shopCloverField'
                  v-model='isShopCloverCredentialsOpen'
                  accordion='shopDetailsNested'
                  role='tabpanel'
                >
                  <div class='form-row'>
                    <div class='m-3 t-font-sans t-p-4 t-rounded t-w-full' id='shopNestedGroup'>
                      <div v-if="!hasCloverCredentials && (adminCheck() || accountManagerCheck())" class='form-group col-md-12'>
                        <p>Click the following link to connect your Clover Account to GlassBiller:</p>
                        <a href="javascript:void(0)" @click="connectToCloverClicked()">Connect To Clover</a>
                      </div>
                      <div v-if="hasCloverCredentials">
                        <div
                          v-if="hasCloverCredentials && (adminCheck() || accountManagerCheck())"
                          class='t-mb-4'
                        >
                          <el-button
                            @click="disconnectFromCloverClicked"
                            type="danger"
                            plain
                            icon="el-icon-delete"
                            size="mini"
                          >
                            Disconnect shop from clover
                          </el-button>
                        </div>
                        <div class='t-mb-4'>
                          <div class="t-text-xs">Processing Currency</div>
                          <el-select size="mini" v-model="shop.clover_currency">
                            <el-option value='USD'>USD</el-option>
                            <el-option value='CAD'>CAD</el-option>
                          </el-select>
                        </div>
                        <clover-device-table class="t-mb-4" :devices="shop.shop_device_clovers" />
                        <el-button
                          v-if="addNewCloverDevice === false"
                          type="primary"
                          plain
                          icon="el-icon-plus"
                          size="mini"
                          @click="addNewCloverDevice = true"
                        >
                          Add new device
                        </el-button>
                        <clover-device-form
                          v-if="addNewCloverDevice === true"
                          :options="cloverMerchantDevices"
                          @add="addCloverDevice"
                          @cancel="addNewCloverDevice = false"
                        />
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div> -->

            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <a v-b-toggle.shopMygrantField>
                  <label>Mygrant Credentials
                    <v-icon
                      class="caret"
                      scale="1.5"
                      :name="
                        isShopMygrantCredentialsOpen
                          ? 'caret-down'
                          : 'caret-left'
                      "
                    />
                  </label>
                </a>
              </div>
            </div>
            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <b-collapse
                  id="shopMygrantField"
                  v-model="isShopMygrantCredentialsOpen"
                  accordion="shopDetailsNested"
                  role="tabpanel"
                >
                  <div class="form-row">
                    <div
                      id="shopNestedGroup"
                      class="m-3 t-font-sans t-p-4 t-rounded"
                    >
                      <p class="t-mb-4 t-text-gray-700">
                        Enter in the account id of the shop in Mygrant below.
                        This can be found by logging into the Mygrant admin
                        panel and clicking the Order Parts tab. In the right of
                        the screen will be a yellow "Ship-To:" box with the id.
                        The id is in the form of C000000-000. This id will also
                        need to be entered into the GlassBiller Glaxis admin
                        panel with Mygrant.
                      </p>
                      <glaxis-credentials
                        v-if="isShopMygrantCredentialsOpen"
                        :shopId="parentShop && parentShop.id"
                        vendor="mygrant"
                        :credentials="shop.vendor_credentials.filter(i => i.vendor === 'mygrant')"
                        @updateCredentials="shop.vendor_credentials = $event"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>

            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <a v-b-toggle.shopPgwCredentials>
                  <label>PGW Credentials
                    <v-icon
                      class="caret"
                      scale="1.5"
                      :name="
                        isShopPgwCredentialsOpen
                        ? 'caret-down'
                        : 'caret-left'
                      "
                    />
                  </label>
                </a>
              </div>
            </div>
            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <b-collapse
                  id="shopPgwCredentials"
                  v-model="isShopPgwCredentialsOpen"
                  accordion="shopDetailsNested"
                  role="tabpanel"
                >
                  <div class="form-row">
                    <div
                      id="shopNestedGroup"
                      class="m-3 t-font-sans t-p-4 t-rounded"
                    >
                      <p class="t-mb-4 t-text-gray-700">
                        Enter in the Customer Number of the shop in PGW below.
                        This can be found by logging into the PGW admin panel
                        and clicking the Returns tab. On that page you will see
                        a Customer Number. This will be entirely numeric. This
                        number will also need to be entered into the GlassBiller
                        Glaxis admin panel with PPGAG.
                      </p>
                      <glaxis-credentials
                        v-if="isShopPgwCredentialsOpen"
                        :shopId="parentShop && parentShop.id"
                        vendor="pgw"
                        :credentials="shop.vendor_credentials.filter(i => i.vendor === 'pgw')"
                        @updateCredentials="shop.vendor_credentials = $event"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>

            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <a v-b-toggle.igcCredentials>
                  <label>Import Glass Corp Credentials
                    <v-icon
                      class="caret"
                      scale="1.5"
                      :name="isIgcCredentialsOpen
                        ? 'caret-down'
                        : 'caret-left'
                      "
                    />
                  </label>
                </a>
              </div>
            </div>
            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <b-collapse
                  id="igcCredentials"
                  v-model="isIgcCredentialsOpen"
                  accordion="shopDetailsNested"
                  role="tabpanel"
                >
                  <div class="form-row">
                    <div
                      class="m-3 t-font-sans t-p-4 t-rounded t-w-full"
                      id="shopNestedGroup"
                    >
                      <p>Enter in your Import Glass Corp account number below.</p>
                      <glaxis-credentials
                        v-if="isIgcCredentialsOpen"
                        :shopId="parentShop && parentShop.id"
                        vendor="igc"
                        :credentials="shop.vendor_credentials.filter(i => i.vendor === 'igc')"
                        @updateCredentials="shop.vendor_credentials = $event"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>

            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <a v-b-toggle.shopPilkingtonCredentials>
                  <label>Pilkington Credentials
                    <v-icon
                      class="caret"
                      scale="1.5"
                      :name="
                        isShopPilkingtonCredentialsOpen
                          ? 'caret-down'
                          : 'caret-left'
                        "
                    />
                  </label>
                </a>
              </div>
            </div>
            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <b-collapse
                  id="shopPilkingtonCredentials"
                  v-model="isShopPilkingtonCredentialsOpen"
                  accordion="shopDetailsNested"
                  role="tabpanel"
                >
                  <div class="form-row">
                    <div
                      class="m-3 t-font-sans t-p-4 t-rounded t-w-full"
                      id="shopNestedGroup"
                    >
                      <p class="t-break-normal">
                        Enter in your Pilkington username, password, and account
                        number below. Your account number can be found by
                        logging into Pilkington, going to "Add/Edit Login(s)",
                        and clicking the "Edit" option next to your login.
                        The Account Number will be on the bottom of the page.
                      </p>
                      <pilkington-credentials
                        v-if="isShopPilkingtonCredentialsOpen"
                        :shopId="parentShop && parentShop.id"
                        :credentials="shop.vendor_credentials.filter(i => i.vendor === 'pilkington')"
                        @updateCredentials="shop.vendor_credentials = $event"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>

            <!-- <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <a v-b-toggle.shopAmericanCredentials>
                  <label>
                    American Credentials
                    <v-icon
                      class="caret"
                      scale="1.5"
                      :name="
                        isShopAmericanCredentialsOpen
                          ? 'caret-down'
                          : 'caret-left'
                      "
                    />
                  </label>
                </a>
              </div>
            </div>
            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <b-collapse
                  id="shopAmericanCredentials"
                  v-model="isShopAmericanCredentialsOpen"
                  accordion="shopDetailsNested"
                  role="tabpanel"
                >
                  <div class="form-row">
                    <div
                      id="shopNestedGroup"
                      class="m-3 t-font-sans t-p-4 t-rounded t-w-full"
                    >
                      <american-credentials
                        v-if="isShopAmericanCredentialsOpen"
                        :shopId="parentShop && parentShop.id"
                        :credentials="shop.vendor_credentials.filter(i => i.vendor === 'american')"
                        @updateCredentials="shop.vendor_credentials = $event"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div> -->

            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <a v-b-toggle.shopNmCredentials>
                  <label>
                    Nielsen & Moller Credentials
                    <v-icon
                      class="caret"
                      scale="1.5"
                      :name="
                        isShopNmCredentialsOpen
                          ? 'caret-down'
                          : 'caret-left'
                      "
                    />
                  </label>
                </a>
              </div>
            </div>
            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <b-collapse
                  id="shopNmCredentials"
                  v-model="isShopNmCredentialsOpen"
                  accordion="shopDetailsNested"
                  role="tabpanel"
                >
                  <div class="form-row">
                    <div
                      id="shopNestedGroup"
                      class="m-3 t-font-sans t-p-4 t-rounded t-w-full"
                    >
                      <nielsen-moller-credentials
                        v-if="isShopNmCredentialsOpen"
                        :shopId="parentShop && parentShop.id"
                        :credentials="shop.vendor_credentials.filter(i => i.vendor === 'nm')"
                        @updateCredentials="shop.vendor_credentials = $event"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>

            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <a v-b-toggle.shopAgdCredentials>
                  <label>
                    Auto Glass Direct Credentials
                    <v-icon
                      class="caret"
                      scale="1.5"
                      :name="
                        isShopAgdCredentialsOpen
                          ? 'caret-down'
                          : 'caret-left'
                      "
                    />
                  </label>
                </a>
              </div>
            </div>
            <div class="form-row" v-if="adminCheck() || accountManagerCheck()">
              <div class="form-group col-md-12">
                <b-collapse
                  id="shopAgdCredentials"
                  v-model="isShopAgdCredentialsOpen"
                  accordion="shopDetailsNested"
                  role="tabpanel"
                >
                  <div class="form-row">
                    <div
                      id="shopNestedGroup"
                      class="m-3 t-font-sans t-p-4 t-rounded t-w-full"
                    >
                      <auto-glass-direct-credentials
                        v-if="isShopAgdCredentialsOpen"
                        :shopId="parentShop && parentShop.id"
                        :credentials="shop.vendor_credentials.filter(i => i.vendor === 'agd')"
                        @updateCredentials="shop.vendor_credentials = $event"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>

            <div class='form-row'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopReviewCredentials><label>Review Credentials <v-icon v-if='!isShopReviewCredentialsOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopReviewCredentialsOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopReviewCredentials' v-model='isShopReviewCredentialsOpen' accordion='shopDetailsNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                      <div>
                        <div class='form-row'>
                          <div class='form-group col-md-12'>
                            <p>Enter your Podium location id and api key. These can be found in your Podium account.</p>
                          </div>
                        </div>
                        <div class='form-row'>
                          <div class='form-group col-md-12'>
                            <label for='shopPodiumLocationId'>Podium Location ID</label>
                            <input id='shopPodiumLocationId' type='text' class='form-control form-control-sm autocomplete-off' v-model='shop.podium_location_id'/>
                          </div>
                        </div>
                        <div class='form-row'>
                          <div class='form-group col-md-12'>
                            <label for='shopPodiumApiKey'>Podium API Key</label>
                            <input id='shopPodiumApiKey' type='text' class='form-control form-control-sm autocomplete-off' v-model='shop.podium_api_key'/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopSalesideWarrantyField><label>Saleside - Warranty & Terms/Conditions <v-icon v-if='!isShopSalesideWarrantyTermsOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopSalesideWarrantyTermsOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopSalesideWarrantyField' v-model='isShopSalesideWarrantyTermsOpen' accordion='shopDetailsNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label for='shopSalesideWarranty'>Warranty</label>
                          <textarea id='shopSalesideWarranty' class='form-control form-control-sm autocomplete-off' rows='7' v-model='shop.saleside_warranty_text'/>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label for='shopSalesideTermsConditions'>Terms & Conditions</label>
                          <textarea id='shopSalesideTermsConditions' class='form-control form-control-sm autocomplete-off' rows='7' v-model='shop.saleside_terms_and_conditions'/>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.shopCustomerCorrespondence><label>Customer Correspondence <v-icon v-if='!isShopCustomerCorrespondenceOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isShopCustomerCorrespondenceOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse id='shopCustomerCorrespondence' v-model='isShopCustomerCorrespondenceOpen' accordion='shopDetailsNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                      <div class='form-row'>
                        <div class='form-group col-md-6'>
                          <span href='javascript:void(0)' @click='gettingFilepicker = true'><FilePicker :buttonLabel="shop.customer_logo && shop.customer_logo.length > 0 ? 'Change Customer Logo':'Add Customer Logo'" :filePickerKey="filepickerKey" :dialogToReturnTo="'shopDialog'" :handleFilePickerResponse="handleFilePickerResponseCustomer"/></span>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col wrapper' v-if='shop.customer_logo && shop.customer_logo.length > 0'>
                          <img :src='shop.customer_logo' fluid alt='Current Customer Logo'>
                          <span @click='shop.customer_logo = ""'><v-icon class='close-button' name="minus-circle" scale="1.5"/></span>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-8'>
                          <label for='shopCustomerAddress'>Address</label>
                          <input id='shopCustomerAddress' type='text' class='form-control form-control-sm autocomplete-off' v-model="shop.customer_address"/>
                        </div>
                        <div class='form-group col-md-4'>
                          <label for='shopCustomerZip'>Zip</label>
                          <input id='shopCustomerZip' type='text' class='form-control form-control-sm autocomplete-off' v-model='shop.customer_zip'/>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-8'>
                          <label for='shopCustomerCity'>City</label>
                          <input id='shopCustomerCity' type='text' class='form-control form-control-sm autocomplete-off' v-model='shop.customer_city'/>
                        </div>
                        <div class='form-group col-md-4'>
                          <label for='shopCustomerState'>State</label>
                          <select id='shopCustomerState' v-model='shop.customer_state' class='form-control form-control-sm'>
                            <option value=''>Choose...</option>
                            <option v-for='state in states' :key='state.value' v-bind:value='state.value'>{{ state.text }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div v-if="coAccounts && coAccounts.length > 0" class='form-row'>
              <div class='form-group col-md-12'>
                <a href='javascript:void(0)' v-b-toggle.chartOfAccountSettings><label><span class="badge badge-primary">Beta!</span> Chart of Accounts Settings <v-icon v-if='!isChartOfAccountSettingsOpen' class='caret' scale='1.5' name='caret-left'/><v-icon v-if='isChartOfAccountSettingsOpen' class='caret' scale='1.5' name='caret-down'/></label></a>
              </div>
            </div>
            <div v-if="coAccounts && coAccounts.length > 0" class='form-row'>
              <div class='form-group col-md-12'>
                <b-collapse id='chartOfAccountSettings' v-model='isChartOfAccountSettingsOpen' accordion='shopDetailsNested' role='tabpanel'>
                  <div class='form-row'>
                    <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <p></p>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default A/R Account</label>
                          <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_accounts_receivable_co_account_id">
                            <option v-for='(account) in accountReceivables' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Material Sales Account</label>
                          <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_materials_sales_co_account_id">
                            <option v-for='(account) in laborMaterialsAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Labor Sales Account</label>
                          <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_labor_sales_other_co_account_id">
                            <option v-for='(account) in laborMaterialsAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Balance Due Cash Account</label>
                          <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_balancedue_cash_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Balance Due Card Account</label>
                          <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_balancedue_card_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Balance Due Check Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_balancedue_check_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Balance Due Bank Transfer Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_balancedue_banktransfer_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Balance Due Other Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_balancedue_other_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Deductible Disc. Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_deductible_deductibledisc_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Deductible Cash Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_deductible_cash_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Deductible Card Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_deductible_card_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Deductible Check Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_deductible_check_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Deductible Bank Transfer Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_deductible_banktransfer_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Deductible Other Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_deductible_other_co_account_id">
                            <option v-for='(account) in paymentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Adjustment Promo Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_adjustment_promo_co_account_id">
                            <option v-for='(account) in adjustmentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Adjustment Coupon Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_adjustment_coupon_co_account_id">
                            <option v-for='(account) in adjustmentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Adjustment Credit Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_adjustment_credit_co_account_id">
                            <option v-for='(account) in adjustmentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Adjustment Short Pay Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_adjustment_shortpay_co_account_id">
                            <option v-for='(account) in adjustmentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Adjustment Write Off Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_adjustment_writeoff_co_account_id">
                            <option v-for='(account) in adjustmentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-12'>
                          <label>Default Adjustment Other Account</label>
                           <select class="form-control form-control-sm" v-model="shopAccountingPackageSettings.default_adjustment_other_co_account_id">
                            <option v-for='(account) in adjustmentAccounts' :key='account.id' :value='account.id'>{{account.name}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <!-- Twilio settings -->
      <div role='tab' v-if='adminCheck() || accountManagerCheck()'>
        <a href='javascript:void(0)' v-b-toggle.twilioSettings>
          Twilio Setting
          <v-icon class='caret' scale='1.5' :name="isTwilioOpen ? 'caret-down' : 'caret-left'"/>
        </a>
      </div>
      <b-collapse
        class='px-1 pb-1'
        id='twilioSettings'
        v-model='isTwilioOpen'
        accordion='shopMainAccordion'
        role='tabpanel'
      >
        <!-- Twilio account -->
        <el-row>
          <el-col>
            <a v-b-toggle.twilioAccount>
              <label>
                Twilio Account
                <v-icon
                  class='caret'
                  scale='1.5'
                  :name="isTwilioAccountOpen ? 'caret-down' : 'caret-left'"
                />
              </label>
            </a>
          </el-col>
        </el-row>
        <b-collapse
          id='twilioAccount'
          v-model='isTwilioAccountOpen'
          accordion='shopDetailsNested'
          role='tabpanel'
        >
          <div class='form-row'>
            <div class='form-group col pb-1 m-3' id='shopNestedGroup'>
              <twilio-account-create
                v-if="!twilioAccountExist"
                :shopId="shop.id"
                @created="shop.twilio_account = $event"
                :disabled="!adminCheck()"
              />
              <twilio-account-update
                v-else
                :twilioAccount="shop.twilio_account"
                @updated="shop.twilio_account = $event"
                :disabled="!adminCheck()"
              />
            </div>
          </div>
        </b-collapse>
        <!-- Twilio account end -->
        <!-- Twilio phone numbers -->
        <el-row>
          <el-col>
            <a v-b-toggle.twilioPhoneNumbers v-if='twilioAccountExist'>
              <label>
                Twilio Phone Numbers
                <v-icon
                  class='caret'
                  scale='1.5'
                  :name="isTwilioPhoneNumbersOpen ? 'caret-down' : 'caret-left'"
                />
              </label>
            </a>
          </el-col>
        </el-row>
        <b-collapse
          id='twilioPhoneNumbers'
          v-model='isTwilioPhoneNumbersOpen'
          accordion='shopDetailsNested'
          role='tabpanel'
        >
          <div class="row m-3" id='shopNestedGroup'>
            <div class="col-md-12">
              <twilio-phone-number-form
                :shopId="shop.id"
                :visible="isTwilioPhoneNumberFormOpen"
                :phoneNumber="twilioPhoneNumber"
                @closed="isTwilioPhoneNumberFormOpen = false"
                @createdUpdated="shop.twilio_phone_numbers = $event"
                :disabled="!adminCheck()"
              />
              <div class='row mb-3'>
                <div class='col-6'>
                  <span style="color: #606266">Phone numbers</span>
                </div>
                <div class='col-6'>
                  <el-button
                    type="primary"
                    size="mini"
                    class="pull-right"
                    icon="el-icon-plus"
                    :disabled="!adminCheck()"
                    @click="openTwilioPhoneNumberForm(null)">Create</el-button>
                </div>
              </div>
              <twilio-phone-number-table
                :shopId="shop.id"
                :defaultPhoneNumberId.sync="shop.default_job_confirmation_phone_number_id"
                :phoneNumbers="shop.twilio_phone_numbers"
                @updatePhoneNumber="openTwilioPhoneNumberForm($event)"
                @deletedPhoneNumber="shop.twilio_phone_numbers = $event"
                :disabled="!adminCheck()"
              />
            </div>
          </div>
        </b-collapse>
        <!-- Twilio phone numbers end -->
      </b-collapse>
      <!-- End of Twilio section -->
      <!-- Inspection settings -->
      <div role='tab' v-if='adminCheck() || accountManagerCheck()'>
        <a href='javascript:void(0)' v-b-toggle.preInspection>
          Pre-inspection settings
          <v-icon class='caret' scale='1.5' :name="isPreInspectionOpen ? 'caret-down' : 'caret-left'"/>
        </a>
      </div>
      <b-collapse
        class='px-1 pb-1'
        id='preInspection'
        accordion='shopMainAccordion'
        role='tabpanel'
        v-model="isPreInspectionOpen"
      >
        <div class="row">
          <div class="col-12 px-2">
            TechSIDE vehicle inspection customer initials checks
          </div>
        </div>
        <div class='form-row py-2' v-for="item in shop.inspection_initials" :key="item.id">
          <div class='form-group col-md-12'>
            <textarea class='form-control form-control-sm' rows='3' v-model='item.description'/>
          </div>
        </div>
      </b-collapse>
      <div role='tab' v-if='adminCheck()'>
        <a href='javascript:void(0)' v-b-toggle.fundingSettings>
          Funding Settings
          <v-icon class='caret' scale='1.5' :name="isShopFundingSettingaOpen ? 'caret-down' : 'caret-left'"/>
        </a>
      </div>
      <b-collapse
        class='px-1 pb-1'
        id='fundingSettings'
        accordion='shopMainAccordion'
        v-model="isShopFundingSettingaOpen"
      >
        <div class="form-row">
          <div class="form-group col-12">
            <CheckBoxLabel label='Shop is Funded' :value.sync='isFunded'/>
            <!-- <el-checkbox v-model="shop.is_funded"></el-checkbox> -->
          </div>
        </div>
        <div class='form-row' v-if='shop.is_funded'>
          <div class='form-group col-12'>
            <label for='funding-start-date'>Funding Started
            <el-date-picker
              v-model="shop.funding_start_date"
              id='funding-start-date'
              type="date"
              format='MM/dd/yyyy'
              placeholder="Pick a day"
              :clearable="false"
            /></label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <CheckBoxLabel label='Pending?' :value.sync='shop.is_funding_pending'/>
            <!-- <el-checkbox v-model="shop.is_funded"></el-checkbox> -->
          </div>
        </div>
        <!-- <div class='form-row py-2' v-for="item in shop.inspection_initials" :key="item.id">
          <div class='form-group col-md-12'>
            <textarea class='form-control form-control-sm' rows='3' v-model='item.description'/>
          </div>
        </div> -->
      </b-collapse>
      <div role='tab' v-if='adminCheck()'>
        <a href='javascript:void(0)' v-b-toggle.adminFunctions>
          Shop Admin Functions
          <v-icon class='caret' scale='1.5' :name="isShopadminFunctionsOpen ? 'caret-down' : 'caret-left'"/>
        </a>
      </div>
      <b-collapse
        class='px-1 pb-1'
        id='adminFunctions'
        accordion='shopMainAccordion'
        v-model="isShopadminFunctionsOpen"
      >
        <div class="form-row">
          <div class="form-group col-12 mt-2">
            <el-button type='primary' @click='adminCreateShopItems' :loading="creatingShopItems">Create 2k Most Popular Nags Items From Shop</el-button>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 mt-2">
            <el-button type='primary' @click='adminUpdateNagsItems' :loading="updatingNagsItems">Track Inventory All Nags Items</el-button>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 mt-2">
            <el-button type='primary' @click='adminUpdateAllItems' :loading="updatingAllItems">Track Inventory All Items</el-button>
          </div>
        </div>
      </b-collapse>
      <div role='tab' >
        <a href='javascript:void(0)' v-b-toggle.pdfOptoions>
          Pdf Options
          <v-icon class='caret' scale='1.5' :name="isShopPdfOptionsOpen ? 'caret-down' : 'caret-left'"/>
        </a>
      </div>
      <b-collapse
        class='px-1 pb-1'
        id='pdfOptoions'
        accordion='shopMainAccordion'
        v-model="isShopPdfOptionsOpen"
      >
        <div class="form-row" v-for='option in additionalPdfOptions' :key='option.name'>
          <div class='form-group col-md-12'>
            <CheckBoxLabel :label='"Show " + snakeCaseToTitle(option.name)' :value.sync='option.visible'/>
          </div>
        </div>
        <div class='form-row'>
          <div class='form-group col-md-5 mt-2'>
            <CheckBoxLabel label='Include full NAGS description on job PDF' :value.sync='shop.pdf_nags_description'/>
          </div>
        </div>
        <div class='form-row'>
          <div class='form-group col-md-5 mt-2'>
            <CheckBoxLabel label='Show customer as bill to' :value.sync='shop.pdf_bill_to_customer'/>
          </div>
        </div>
        <!-- <Draggable v-model='shop.pdfOptions'> -->
          <!-- <div v-for='option in shop.pdfOptions' :key='option.id'>{{option.name}}</div> -->
          <!-- <PdfOptionCard v-for='option in shop.pdfOptions' :key='option.id' :option='option'/> -->
        <!-- </Draggable> -->
        <div class='form-row'>
          <PdfOptionsContainer :options='shop.pdfOptions'/>
        </div>
      </b-collapse>
      <div>
        <a href="javascript:void(0)" v-b-toggle.webQuote>
          Web quote
          <v-icon v-if="isWebQuoteOpen" class="caret" scale="1.5" name="caret-down"/>
          <v-icon v-else class="caret" scale="1.5" name="caret-left"/>
        </a>
      </div>
      <b-collapse
        id="webQuote"
        v-model="isWebQuoteOpen"
        accordion="shopMainAccordion"
        role="tabpanel">
        <WebQuote :shopId="shop.id"/>
      </b-collapse>
      <div>
        <a href="javascript:void(0)" v-b-toggle.confirmationSettings>
          Confirmation Settings
          <v-icon v-if="isConfirmationSettingsOpen" class="caret" scale="1.5" name="caret-down"/>
          <v-icon v-else class="caret" scale="1.5" name="caret-left"/>
        </a>
      </div>
      <b-collapse
        id="confirmationSettings"
        v-model="isConfirmationSettingsOpen"
        accordion="shopMainAccordion"
        role="tabpanel">
        <confirmation-settings
          :settings="shop.shop_confirmation_settings"
          :shopId="shop.id"
          @modifySettings="modifyConfirmationSettings"
          @confirmationSettingsInvalid="alertSettingsInvalid"
          ref="confirmationSettingsForm"
        />
      </b-collapse>
    </div>
    <div slot='footer' class='w-100'>
        <el-button @click="visible = false">Cancel</el-button>
        <el-button type="primary" :loading="loading || saving" @click="saveClicked">
          Save
        </el-button>
        <!-- <button v-if='changesFound' type='button' class='btn btn-primary' variant="primary" style="margin-right:5px;" @click='saveClicked'>Save</button>
        <b-button v-if='!changesFound' type='button' class='' variant="outline-primary" style="margin-right:5px;" @click='saveClicked'>Save</b-button>
        <button type='button' class='btn btn-secondary' variant="primary" @click="cancelClicked">Cancel</button> -->
    </div>
  </el-dialog>
</template>

<script>
import Shop from '@/scripts/objects/shop'
import TwilioAccountCreate from '@/components/Twilio/Account/Create'
import TwilioAccountUpdate from '@/components/Twilio/Account/Update'
import TwilioPhoneNumberForm from '@/components/Twilio/PhoneNumber/Form'
import TwilioPhoneNumberTable from '@/components/Twilio/PhoneNumber/Table'
import VendorCredentialsForm from '@/components/Vendors/CredentialsForm'
import VendorCredentialsTable from '@/components/Vendors/CredentialsTable'
import SquareDevicesTable from '@/components/Square/DevicesTable'
import SquareDevicesForm from '@/components/Square/DevicesForm'
import PilkingtonCredentials from '@/components/Vendors/Pilkington/CredentialsForm'
import GlaxisCredentials from '@/components/Vendors/Glaxis/CredentialsForm'
// import AmericanCredentials from '@/components/Vendors/American/CredentialsForm'
import NielsenMollerCredentials from '@/components/Vendors/NielsenMoller/CredentialsForm'
import AutoGlassDirectCredentials from '@/components/Vendors/AutoGlassDirect/CredentialsForm'
import Phone from '@/components/Helpers/Phone.vue'
import Email from '@/components/Helpers/Email.vue'
import CheckBoxLabel from '@/components/Helpers/CheckBoxLabel'
import InputWithDelete from '@/components/Helpers/InputWithDelete.vue'
import FilePicker from '@/components/Helpers/FilePicker.vue'
// import Draggable from 'vuedraggable'
import PdfOptionsContainer from '@/components/AdminPage/PdfOptions/Index.vue'
import { UtilityMixin, snakeCaseToTitle } from '@/scripts/helpers/utilities'
import { isDev } from '@/config'
import { StateObjects } from '@/scripts/helpers/helpers'
import _Email from '@/scripts/objects/email'
import * as R from 'ramda'
import WebQuote from '../WebQuote/Index'
import Api from '@/services/Api'
import { sync } from 'vuex-pathify'
import { organizationRepository } from '@/scripts/repositories/organization'
import Multiselect from 'vue-multiselect'
import CloverDeviceTable from '@/components/Clover/DeviceTable'
import CloverDeviceForm from '@/components/Clover/AddDeviceForm'
import ConfirmationSettings from '@/components/ConfirmationSettings/Index.vue'

export default {
  props: ['parentShop', 'filepickerKey', 'sectionToOpen'],
  data () {
    return {
      states: StateObjects,
      shop: new Shop(),
      loading: false,
      saving: false,
      isShopInfoOpen: false,
      isShopDetailsOpen: false,
      isShopCustomPdfOpen: false,
      isShopDisclaimerOpen: false,
      isShopEmailsOpen: false,
      isShopLogoOpen: false,
      isShopQuickbooksOnlineCredentialsOpen: false,
      isShopAuthorizeCredentialsOpen: false,
      isShopSquareCredentialsOpen: false,
      isShopCloverCredentialsOpen: false,
      isShopMygrantCredentialsOpen: false,
      isShopPgwCredentialsOpen: false,
      isShopPilkingtonCredentialsOpen: false,
      // isShopAmericanCredentialsOpen: false,
      isShopNmCredentialsOpen: false,
      isShopAgdCredentialsOpen: false,
      isIgcCredentialsOpen: false,
      isChartOfAccountSettingsOpen: false,
      isShopReviewCredentialsOpen: false,
      isShopSalesideWarrantyTermsOpen: false,
      isShopCustomerCorrespondenceOpen: false,
      isShopZohoSetupOpen: false,
      isShopCustomerQuoteMessageOpen: false,
      isShopCustomerWorkOrderMessageOpen: false,
      isShopCustomerInvoiceMessageOpen: false,
      isShopFundingSettingaOpen: false,
      isShopadminFunctionsOpen: false,
      isShopPdfOptionsOpen: false,
      isWebQuoteOpen: false,
      isConfirmationSettingsOpen: false,
      gettingFilepicker: false,
      gotFilepicker: false,
      isChanged: false,
      // eventually need to sort this out, but to get this done going to keep track of these locally
      shopInitialEmails: [],
      readOnly: true,
      pdfVersionOptions: [
        { value: 'customerCopy', text: 'Customer Copy' },
        { value: 'customerWithPrices', text: 'Customer with prices' },
        { value: 'insuranceCopy', text: 'Insurance Copy' },
        { value: 'withPrices', text: 'With Prices' },
        { value: 'noPrices', text: 'Without Prices' }
      ],
      adhesiveDefaultOptions: [
        { value: 'standard', text: 'Standard' },
        { value: 'high_modulus', text: 'High Modulus' },
        { value: 'fast_cure', text: 'Fast Cure' }
      ],
      tsignVersionOptions: [
        { value: 'customerCopy', text: 'Customer Copy' },
        { value: 'insuranceCopy', text: 'Insurance Copy' },
        { value: 'withPrices', text: 'With Prices' },
        { value: 'noPrices', text: 'Without Prices' }
      ],
      invoiceTierOptions: [
        { value: null, text: 'Choose...' },
        { value: 'dor', text: 'Due on receipt' },
        { value: 'net10', text: 'Net 10' },
        { value: 'net15', text: 'Net 15' },
        { value: 'net20', text: 'Net 20' },
        { value: 'net20_500', text: 'Net 20 $500' },
        { value: 'net30', text: 'Net 30' },
        { value: 'net45', text: 'Net 45' },
        { value: 'net60', text: 'Net 60' },
        { value: 'net90', text: 'Net 90' }
      ],
      installContextOptions: [
        { value: null, text: 'Choose...' },
        { value: 'mobile', text: 'Mobile' },
        { value: 'inShop', text: 'In Shop' },
        { value: 'delivery', text: 'Delivery' },
        { value: 'willCall', text: 'Will Call' }
      ],
      isTwilioOpen: false,
      isPreInspectionOpen: false,
      isTwilioAccountOpen: false,
      isTwilioPhoneNumbersOpen: false,
      twilioPhoneNumber: null,
      isTwilioPhoneNumberFormOpen: false,
      shopAccountingPackageSettings: {},
      coAccounts: [],
      creatingShopItems: false,
      updatingNagsItems: false,
      updatingAllItems: false,
      vendorCredentialsFormIsOpen: false,
      vendorCredentialsToEdit: null,
      pdfOptionsChange: false, // this is realllllly dumb, but the way the object tracker works with arrays and not effect other areas this is what I am doing
      selectedOrganization: null,
      organizations: [],
      organizationsLoading: false,
      currentOrganizationSearchTerm: '',
      organizationSearchTimer: null,
      squareDevicesFormIsOpen: false,
      addNewCloverDevice: false,
      cloverMerchantDevices: [],
      addingCloverDevice: false,
      squareLocationsLoading: false,
      squareLocations: []
    }
  },
  mounted () {
    this.readOnly = false
    this.autocompleteOffInit()
    this.$root.$on('updateChildren', this.updatePdfOptions)
    this.$root.$on('visible-toggle', this.updateVisiblePdfOptions)
    this.$root.$on('reset-pdf-to-default', this.resetPdfToDefault)
  },
  beforeDestroy: function () {
    this.$root.$off('updateChildren', this.updatePdfOptions)
    this.$root.$off('visible-toggle', this.updateVisiblePdfOptions)
    this.$root.$off('reset-pdf-to-default', this.resetPdfToDefault)
  },
  methods: {
    asyncFindOrganizations: function (query) {
      const self = this
      self.organizationsLoading = true
      self.currentOrganizationSearchTerm = query
      clearTimeout(self.organizationSearchTimer)
      self.organizationSearchTimer = setTimeout(function () {
        self.searchOrganizations()
      }, 300)
    },
    async searchOrganizations() {
      this.organizationsLoading = true
      this.organizations = await organizationRepository.searchOrganizations({
        searchTerm: this.currentOrganizationSearchTerm,
        adminShowAll: true
      })
      this.organizationsLoading = false
    },
    connectToSquareClicked: function () {
      this.$store.dispatch('setSquareShopId', this.shop.id)
      window.location = this.squareConnectUrl
    },
    disconnectFromSquareClicked: function () {
      if (confirm('Are you sure you want to disconnect this shop from Square?')) {
        let self = this
        Api.post(`/api/shop/` + self.shop.id + `/squareConnector/disconnect`).then(res => {
          if (res.data.result && res.data.result === 'success') {
            self.shop.square_merchant_id = ''
            self.$root.$emit('runAppSetupCommand')
            alert('Square has been disconnected.')
          } else {
            alert('Something went wrong disconnecting.')
          }
        }, function (error) {
          console.log('error: ', error)
          alert('Something went wrong disconnecting.')
        })
      }
    },
    connectToCloverClicked: function () {
      this.$store.dispatch('setCloverShopId', this.shop.id)
      window.location = this.cloverConnectUrl
    },
    disconnectFromCloverClicked: function () {
      if (confirm(`Are you sure you want to disconnect this shop from Clover? 
        After disconnecting from GlassBiller you will need to disconnect the app in Clover as well.`)) {
        let self = this
        Api.post(`/api/shop/` + self.shop.id + `/cloverConnector/disconnect`).then(res => {
          if (res.data.result && res.data.result === 'success') {
            self.shop.clover_merchant_id = ''
            self.$root.$emit('runAppSetupCommand')
            alert('Clover has been disconnected.')
          } else {
            alert('Something went wrong disconnecting.')
          }
        }, function (error) {
          console.log('error: ', error)
          alert('Something went wrong disconnecting.')
        })
      }
    },
    connectToQBOClicked: function () {
      this.$store.dispatch('setQBOShopId', this.shop.id)
      this.changeRouteWithCheck('/quickBooksOnlineConnect?request=startConnection')
    },
    disconnectFromQBOClicked: function () {
      if (confirm('Are you sure you want to disconnect this shop from QuickBooks Online?')) {
        let self = this
        this.shop.disconnectFromQBO(function (response) {
          if (response['response'] === 'success') {
            self.$root.$emit('runAppSetupCommand')
            self.shop.qbo_refresh_token = ''
            self.shop.qbo_refresh_token_expires = ''
            self.shop.qbo_realm_id = ''
            alert('This shop is now disconnected from QuickBooks Online.')
          } else {
            alert('Something went wrong disconnecting this shop from QuickBooks Online.')
          }
        }, function (error) {
          console.log('QOB ERROR OCCURRED', error)
          alert('Something went wrong disconnecting this shop from QuickBooks Online.')
        })
      }
    },
    dialogAboutToAppear () {
      this.initialize()
    },
    async initialize () {
      // this.shop = new Shop(this.parentShop)
      if (!this.gettingFilepicker) {
        this.loading = false
        this.saving = false
        // reset all the collapse sections
        this.isShopInfoOpen = false
        this.isShopDetailsOpen = this.sectionToOpen === 'square' || this.sectionToOpen === 'clover'
        this.isShopCustomPdfOpen = false
        this.isShopDisclaimerOpen = false
        this.isShopEmailsOpen = false
        this.isShopLogoOpen = false
        this.isShopQuickbooksOnlineCredentialsOpen = false
        this.isShopAuthorizeCredentialsOpen = false
        this.isShopSquareCredentialsOpen = this.sectionToOpen === 'square'
        this.isShopCloverCredentialsOpen = this.sectionToOpen === 'clover'
        this.isShopMygrantCredentialsOpen = false
        this.isShopPgwCredentialsOpen = false
        this.isShopPilkingtonCredentialsOpen = false
        // this.isShopAmericanCredentialsOpen = false
        this.isShopNmCredentialsOpen = false
        this.isShopAgdCredentialsOpen = false
        this.isIgcCredentialsOpen = false
        this.isChartOfAccountSettingsOpen = false
        this.isShopReviewCredentialsOpen = false
        this.isShopSalesideWarrantyTermsOpen = false
        this.isShopCustomerCorrespondenceOpen = false
        this.isShopZohoSetupOpen = false
        this.isTwilioOpen = false
        this.isPreInspectionOpen = false
        this.isTwilioAccountOpen = false
        this.isTwilioPhoneNumbersOpen = false
        this.isChanged = false
        this.shopInitialEmails = []
        this.shop = new Shop({})
        this.creatingShopItems = false
        this.updatingNagsItems = false
        this.updatingAllItems = false
        this.isShopPdfOptionsOpen = false
        this.isShopadminFunctionsOpen = false
        this.isShopFundingSettingaOpen = false
        this.pdfOptionsChange = false
        this.addNewCloverDevice = false
        this.cloverMerchantDevices = []
        this.addingCloverDevice = false
        this.isConfirmationSettingsOpen = false
        var self = this
        if (this.parentShop && this.parentShop.id > 0) {
          self.loading = true
          self.shopAccountingPackageSettings = {}
          self.coAccounts = []

          this.selectedOrganization = null
          this.organizations = []
          this.organizationsLoading = false
          this.currentOrganizationSearchTerm = ''
          this.squareLocations = []

          Shop.getShopFull(this.parentShop.id, res => {
            self.shop = new Shop({
              ...res.data.shop,
              emails: res.data.emails,
              pdfOptions: res.data.pdfOptions,
              shop_confirmation_settings: res.data.shopConfirmationSettings
            })

            this.shopInitialEmails = [...res.data['emails']]

            if (res.data['shopAccountingPackageSettings']) {
              self.shopAccountingPackageSettings = res.data['shopAccountingPackageSettings']
            }

            if (res.data['coAccounts']) {
              self.coAccounts = res.data['coAccounts']
            }

            if (res.data.organization) {
              self.selectedOrganization = res.data.organization
            }

            if (res.data['cloverDevices']) {
              self.cloverMerchantDevices = res.data.cloverDevices
            }

            self.loading = false
          }, error => {
            self.loading = false
          })
        } else if (this.parentShop && this.parentShop.id === -1) {
          this.shop = this.parentShop
        }
      } else if (this.gettingFilepicker && this.gotFilepicker) {
        this.gettingFilepicker = false
        this.gotFilepicker = false
      } else {
        this.gettingFilepicker = false
      }
    },
    adminCheck () {
      return this.$store.state.user.admin
    },
    accountManagerCheck () {
      return this.$store.state.user.account_manager
    },
    saveClicked () {
      const confirmationSettingsStatus = !this.shop.twilio_phone_numbers.length ? 'valid' : this.$refs.confirmationSettingsForm.manualValidate()

      if (confirmationSettingsStatus === 'valid') {
        var self = this
        this.saving = true
        var temp = this.shop.getChangesArray()
        var canUpdateAOB = this.shop.auto_update_aob === 1 || this.shop.auto_update_aob === '1'
        var shouldAddNewAOB = false
        for (var i = 0; i < temp.length; i++) {
          if (Object.keys(temp[i]).includes('name')) shouldAddNewAOB = true
        }
        if (shouldAddNewAOB && canUpdateAOB && (this.adminCheck() || this.accountManagerCheck())) this.addAOBClicked()

        if (this.shopAccountingPackageSettings && this.shopAccountingPackageSettings.id) {
          Shop.updateShopAccountingPackageSettings(this.shop.id, this.shopAccountingPackageSettings, function() {})
        }

        this.shop.createUpdate(res => {
          self.shop = new Shop({...res.data['shop'], emails: res.data['emails']})
          self.shopInitialEmails = [...res.data['emails']]
          self.saving = false
          self.$store.dispatch('updateShopData', self.shop)
          self.$root.$emit('updateShop', self.shop)
          self.$root.$emit('showGlobalNotification', 'Success', `Shop '${self.shop.name}' Updated`)
          self.visible = false
        }, error => {
          self.saving = false
          self.$root.$emit('showGlobalNotification', 'Unsuccessful', `Shop failed to update`, 'danger')
        })
      }
    },
    cancelClicked () {
      if (this.changesFound) {
        if (confirm('There have been changes made to this shop. Are you sure you want to close without saving?')) {
        self.visible = false
        }
      } else {
        self.visible = false
      }
    },
    addEmailClicked () {
      this.isChanged = true
      this.shop.emails.push(new _Email({}))
    },
    removeEmail (email, index) {
      this.isChanged = true

      this.shop.emails.splice(index, 1)
    },
    handleFilePickerResponse (fileName, fileUrl) {
      this.isChanged = true
      this.shop.logo = fileUrl
      this.gotFilepicker = true
    },
    handleFilePickerResponseCustomer (fileName, fileUrl) {
      this.isChanged = true
      this.shop.customer_logo = fileUrl
      this.gotFilepicker = true
    },
    addAOBClicked () {
      if (this.shop.auto_update_aob !== 1 && this.shop.auto_update_aob !== '1') this.shop.auto_update_aob = 1
      this.shop.disclaimer = `<strong>Assignment of benefits:</strong> I hereby assign any and all insurance rights, benefits and proceeds under any applicable insurance policies to ${this.shop.name}.  I make this assignment in consideration of ${this.shop.name} agreement to perform services and supply materials and otherwise perform its obligations under this contract, including not requiring full payment at the time of service.  If payment is made directly to owner/agent by an insurer, it shall be endorsed over to ${this.shop.name} within three days.  I agree that any portion of work not covered by insurance must be paid by the undersigned on or before its completion.  I hereby direct my insurance carrier to release any and all information requested by ${this.shop.name}, and to accept notice of claim from ${this.shop.name}.<br/><br/>* I acknowledge the above assignment, and that the goods and/or services have been provided to my complete satisfaction.`
    },
    openTwilioPhoneNumberForm (phoneNumber) {
      this.twilioPhoneNumber = phoneNumber
      this.isTwilioPhoneNumberFormOpen = true
    },
    formatPhoneNumberName (phone) {
      return `${phone.phone_number.substr(0, 3)}-${phone.phone_number.substr(3, 3)}-${phone.phone_number.substr(6)} - ${phone.name}`
    },
    async adminCreateShopItems () {
      this.creatingShopItems = true
      const {data} = await this.shop.adminCreateShopItems()
      if (data) {
        if (data.status === 'success') {
          alert(`Success!\nNewly Added Item Count: ${data.addedItemCount}\nItems Already In Shop List: ${data.notAddedItemCount}`)
        } else {
          alert('Failed to ceate: ' + data)
        }
      }
      this.creatingShopItems = false
    },
    async adminUpdateNagsItems () {
      this.updatingNagsItems = true
      try {
        const {data} = await this.shop.adminUpdateNagsItems()
        if (data) {
          if (data.status === 'success') {
            alert(`Success!\nNags Items Set To Track Inventory: ${data.updatedItemCount}`)
          } else {
            alert('Failed to Update: ' + data)
          }
        }
      } catch (err) {
        alert('Failed ' + err.message)
      }
      this.updatingNagsItems = false
    },
    async adminUpdateAllItems () {
      this.updatingAllItems = true
      try {
        const {data} = await this.shop.adminUpdateAllItems()
        if (data) {
          if (data.status === 'success') {
            alert(`Success!\nItems Set To Track Inventory: ${data.updatedItemCount}`)
          } else {
            alert('Failed to Update: ' + data)
          }
        }
      } catch (err) {
        alert('Failed ' + err.message)
      }
      this.updatingAllItems = false
    },
    closeVendorCredentialsForm () {
      this.vendorCredentialsFormIsOpen = false
      this.vendorCredentialsToEdit = null
    },
    closeSquareDevicesForm () {
      this.squareDevicesFormIsOpen = false
    },
    async editVendorCredentials (item) {
      await this.closeVendorCredentialsForm()
      this.vendorCredentialsToEdit = item
      this.vendorCredentialsFormIsOpen = true
    },
    updatePdfOptions (vals, col) {
      for (var i = 0; i < vals.length; i++) {
        for (var j = 0; j < this.shop.pdfOptions.length; j++) {
          if (this.shop.pdfOptions[j].id === vals[i].id) {
            let tempObj = {
              ...this.shop.pdfOptions[j],
              col: col,
              row: i+1
            }
            this.shop.pdfOptions.splice(j, 1, tempObj)
          }
        }
      }
      this.pdfOptionsChange = true
    },
    updateVisiblePdfOptions (option) {
      // find and make visible false... move to top of the non visible options
      // find colomn that
      let columnVisibleindex = 0
      let columnSpliceIndex = 0
      let tempColArray = []
      for (var i = 0; i < this.shop.pdfOptions.length; i++) {
        if (this.shop.pdfOptions[i].col === option.col) {
          tempColArray.push(this.shop.pdfOptions[i])
        }
      }
      tempColArray.sort((a, b) => a.row - b.row)
      for (var j = 0; j < tempColArray.length; j++) {
        if (tempColArray[j].id === option.id) {
          columnSpliceIndex = j
        }
      }
      let removed = tempColArray.splice(columnSpliceIndex, 1)
      removed[0].visible = option.visible ? 0 : 1
      for (var h = 0; h < tempColArray.length; h++) {
        if (tempColArray[h].visible) {
          columnVisibleindex = h+1
        }
      }

      tempColArray.splice(columnVisibleindex, 0, removed[0])

      // now go through and reorder rows for column
      for (var k = 0; k < tempColArray.length; k++) {
        tempColArray[k].row = k+1
      }

      // now set in master array
      let currentRow = 1
      for (var m = 0; m < tempColArray.length; m++) {
        for (var l = 0; l < this.shop.pdfOptions.length; l++) {
          if (this.shop.pdfOptions[l].id === tempColArray[m].id) {
            this.shop.pdfOptions.splice(l, 1, tempColArray[m])
            break;
          }
        }
      }
      this.pdfOptionsChange = true
    },
    resetPdfToDefault (defaultOptions) {
      if (defaultOptions) {
        // this.shop.pdfOptions = []
        for (var i = 0; i < defaultOptions.length; i++) {
          if (defaultOptions[i].col) {
            let found = false
            for (var j = 0; j < this.shop.pdfOptions.length; j++) {
              // this.shop.pdfOptions.push({...defaultOptions, shop_id: this.shop.id})
              if (this.shop.pdfOptions[j].name === defaultOptions[i].name) {
                this.shop.pdfOptions[j].col = defaultOptions[i].col
                this.shop.pdfOptions[j].row = defaultOptions[i].row
                this.shop.pdfOptions[j].visible = defaultOptions[i].visible
                found = true
              }
            }
            if (!found) {
              this.shop.pdfOptions.push(defaultOptions[i])
            }
          }
        }
      }
    },
    async updateAuthorizeNetCredentials () {
      var temp = this.shop.getChangesArray()
      var objectToChange = {}
      for (var i = 0; i < temp.length; i++) {
        if (temp[i]['authorizenet_api_login_id'] !== undefined || temp[i]['authorizenet_transaction_key'] !== undefined || temp[i]['authorizenet_public_client_key'] !== undefined) {
          objectToChange = {...objectToChange, ...temp[i]}
        }
      }
      if (Object.keys(objectToChange).length) {
        const {data} = await this.shop.updateAuthorizeNet(objectToChange)
        this.shop.objectTrackerInitial = {...this.shop.objectTrackerInitial, ...objectToChange}
      }
    },
    hasOtherPaymentProcessingCredentials (type) {
      if (type === 'authorize.net') {
        return this.hasSquareCredentials || this.hasCloverCredentials
      } else if (type === 'square') {
        return this.hasAuthorizeNetCredentials || this.hasCloverCredentials
      } else if (type === 'clover') {
        return this.hasAuthorizeNetCredentials || this.hasSquareCredentials
      }

      return false
    },
    async addCloverDevice (device) {
      this.addingCloverDevice = true
      try {
        const {data} = this.shop.addShopCloverDevice(this.shop.id, device)

        this.cloverMerchantDevices = this.cloverMerchantDevices.filter(device => {
          return device.serial != device.serial
        })

        this.shop.shop_device_clovers.push(device)
      } catch (error) {
        console.log(error)
      }
      this.addingCloverDevice = false
    },
    snakeCaseToTitle,
    async getSquareLocations (opened) {
      if (opened) {
        this.squareLocationsLoading = true
        this.squareLocations = []
          const {data} = await Shop.getSquareLocationsForShop(this.shop.id)
          if (data.status === 'error') {
            // console.log(e.response)
              this.$alert(data.message, 'Square Permission Error', {
                confirmButtonText: 'Ok'
              })
          } else {
            data.forEach(loc => {
              this.squareLocations.push({
                label: loc.name,
                id: loc.id
              })
            });
          }

        this.squareLocationsLoading = false
      }
    },
    modifyConfirmationSettings(settings) {
      this.shop.shop_confirmation_settings = settings
    },
    alertSettingsInvalid(string) {
      if (!this.isConfirmationSettingsOpen) this.isConfirmationSettingsOpen = true
      this.$message.warning(`Invalid confirmation settings. ${string}`)
    }
  },
  watch: {
    // 'shop.address_zip': function (val) {
    //   if (val.length === 5) {
    //     var self = this
    //     this.zipLookup(val, function (data) {
    //       self.shop.address_city = data.city
    //       self.shop.address_state = data.state_short
    //     })
    //   }
    // },
    // 'shop.customer_zip': function (val) {
    //   if (val.length === 5) {
    //     var self = this
    //     this.zipLookup(val, function (data) {
    //       self.shop.customer_city = data.city
    //       self.shop.customer_state = data.state_short
    //     })
    //   }
    // }
  },
  computed: {
    squareConnectUrl () {
      var url = 'https://connect.squareupsandbox.com'
      var clientId = 'sandbox-sq0idb-rb4fRIvm1xiyU1hqdjsGmA'
      if (
        window.location.href.indexOf('https://staging-pro.glassbiller.com/') === -1 &&
        !isDev &&
        window.location.href.indexOf('clustervue.glassbiller.com') === -1) {
        url = 'https://connect.squareup.com'
        clientId = 'sq0idp-P-5K6TPobCUQdpJzcBsfBw'
      }
      url += '/oauth2/authorize?client_id=' + clientId +
        '&scope=MERCHANT_PROFILE_READ+PAYMENTS_WRITE+PAYMENTS_READ+PAYMENTS_WRITE_IN_PERSON+DEVICE_CREDENTIAL_MANAGEMENT+ORDERS_READ+ORDERS_WRITE'
      return url
    },
    cloverConnectUrl () {
      var url = 'https://sandbox.dev.clover.com'
      var clientId = 'Y086PMWQ863MJ'
      if (
        window.location.href.indexOf('https://staging-pro.glassbiller.com/') === -1 &&
        !isDev &&
        window.location.href.indexOf('clustervue.glassbiller.com') === -1) {
        url = 'https://www.clover.com/'
        clientId = 'sq0idp-P-5K6TPobCUQdpJzcBsfBw'
      }
      url += '/oauth/authorize?client_id=' + clientId + '&redirect_uri=http://localhost:8080/cloverConnect' 
      return url
    },
    hasAuthorizeNetCredentials () {
      return (this.shop.authorizenet_api_login_id && this.shop.authorizenet_api_login_id.length > 0) ||
      (this.shop.authorizenet_transaction_key && this.shop.authorizenet_transaction_key.length > 0) ||
      (this.shop.authorizenet_public_client_key && this.shop.authorizenet_public_client_key.length > 0)
    },
    hasSquareCredentials () {
      return (this.shop.square_merchant_id && this.shop.square_merchant_id.length > 0)
    },
    hasCloverCredentials() {
      return this.shop.clover_merchant_id && this.shop.clover_merchant_id.length > 0
    },
    laborMaterialsAccounts () {
      var accounts = []
      for (var i = 0; i < this.coAccounts.length; i++) {
        if (this.coAccounts[i].active === 1 && (this.coAccounts[i].account_type === 'Income' || this.coAccounts[i].account_type === 'Expense')) {
          accounts.push(this.coAccounts[i])
        }
      }
      return accounts
    },
    accountReceivables () {
      var accountsReceivables = []
      for (var i = 0; i < this.coAccounts.length; i++) {
        if (this.coAccounts[i].active === 1 && this.coAccounts[i].account_category === 'Accounts Receivable') {
          accountsReceivables.push(this.coAccounts[i])
        }
      }
      return accountsReceivables
    },
    paymentAccounts () {
      var paymentAccounts = []
      for (var i = 0; i < this.coAccounts.length; i++) {
        if (this.coAccounts[i].active === 1 && (this.coAccounts[i].account_type === 'Assets' || this.coAccounts[i].account_type === 'Liabilities')) {
          paymentAccounts.push(this.coAccounts[i])
        }
      }
      return paymentAccounts
    },
    adjustmentAccounts () {
      var paymentAccounts = []
      for (var i = 0; i < this.coAccounts.length; i++) {
        if (this.coAccounts[i].active === 1 && (this.coAccounts[i].account_type === 'Income' || this.coAccounts[i].account_type === 'Expense')) {
          paymentAccounts.push(this.coAccounts[i])
        }
      }
      return paymentAccounts
    },
    spinnerTitle () {
      if (this.loading) {
        return 'Loading Shop Data...'
      } else if (this.saving) {
        return 'Saving Shop Data...'
      } else {
        return ''
      }
    },
    changesFound () {
      if (!R.equals(this.shopInitialEmails, this.shop.emails)) {
        return true
      } else if (!this.shop.cleanCheck) {
        return true
      } else if (this.pdfOptionsChange) {
        return true
      }

      return false
    },
    twilioAccountExist () {
      return Object.entries(this.shop.twilio_account).length !== 0
    },
    isFunded: {
      get() {return this.shop.is_funded},
      set (val) {
        if (val && this.shop.funding_start_date) {
          if (confirm('Do you want to update the funding start date to today?')) {
            this.shop.funding_start_date = new Date()
          }
        } else if (val && !this.shop.funding_start_date) {
          this.shop.funding_start_date = new Date()
        }
        this.shop.is_funded = val
      }
    },
    additionalPdfOptions () {
      const arr = []
      for (var i = 0; i < this.shop.pdfOptions.length; i++) {
        if (!this.shop.pdfOptions[i].col) {
          arr.push(this.shop.pdfOptions[i])
        }
      }
      return arr
    },
    visible: sync('shopDialog/visible')
  },
  components: {
    Phone,
    Email,
    CheckBoxLabel,
    InputWithDelete,
    FilePicker,
    TwilioAccountCreate,
    TwilioAccountUpdate,
    TwilioPhoneNumberForm,
    TwilioPhoneNumberTable,
    VendorCredentialsForm,
    VendorCredentialsTable,
    PilkingtonCredentials,
    GlaxisCredentials,
    // AmericanCredentials,
    NielsenMollerCredentials,
    AutoGlassDirectCredentials,
    PdfOptionsContainer,
    WebQuote,
    ConfirmationSettings,
    Multiselect,
    SquareDevicesTable,
    SquareDevicesForm,
    CloverDeviceTable,
    CloverDeviceForm
  },
  mixins: [UtilityMixin]
}
</script>

<style>
#shopInfoGroup, #shopDetailsGroup, #twilioSettings, #fundingSettings, #adminFunctions, #pdfOptoions {
  background-color: #EBE5E0;
}
#shopNestedGroup {
  background-color: white;
  padding: 15px;
}
.wrapper {
  position: relative;
  display: inline-block;
}
.close-button {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 75px;
  color: rgba(255,0,0,0.7);
}
.close-button:hover {
  color: rgba(255,0,0,1.0);
}
.caret {
  color: darkred;
  vertical-align: middle;
}
</style>
