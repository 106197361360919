var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title: _vm.tag.id > 0 ? "Edit Shop Tag" : "New Shop Tag",
        id: _vm.modalID,
        "header-bg-variant": "primary",
        "header-text-variant": "light"
      },
      on: {
        show: _vm.dialogAboutToAppear,
        hidden: _vm.dialogDisappeared,
        shown: function($event) {
          return _vm.$refs.nameComponent.focus()
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-6" }, [
          _c("label", [_vm._v("Status")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.tag.status,
                  expression: "tag.status"
                }
              ],
              staticClass: "form-control form-control-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.tag,
                    "status",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "active" } }, [_vm._v("Active")]),
              _c("option", { attrs: { value: "disabled" } }, [
                _vm._v("Disabled")
              ])
            ]
          )
        ]),
        _vm.tag.id > 0
          ? _c("div", { staticClass: "form-group col-6" }, [
              _c("label"),
              _c("p", [
                _vm._v("ID: "),
                _c("span", { staticClass: "idBox" }, [
                  _vm._v(_vm._s(_vm.tag.id))
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "form-group col-6" },
          [
            _c("RequiredInput", {
              ref: "nameComponent",
              attrs: {
                title: "Tag Name",
                value: _vm.tag.text,
                placeholder: "",
                capitalizeAll: true
              },
              on: {
                "update:value": function($event) {
                  return _vm.$set(_vm.tag, "text", $event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-group col-6" },
          [
            _c("label", [_vm._v("Tag Color")]),
            _c("br"),
            _c("Sketch", {
              model: {
                value: _vm.tagColor,
                callback: function($$v) {
                  _vm.tagColor = $$v
                },
                expression: "tagColor"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c("label", [_vm._v("Details")]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.tag.details,
                expression: "tag.details"
              }
            ],
            staticClass: "form-control form-control-sm",
            domProps: { value: _vm.tag.details },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.tag, "details", $event.target.value)
              }
            }
          }),
          _c("br")
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.tag.default_tag,
                expression: "tag.default_tag"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.tag.default_tag)
                ? _vm._i(_vm.tag.default_tag, null) > -1
                : _vm.tag.default_tag
            },
            on: {
              change: function($event) {
                var $$a = _vm.tag.default_tag,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.tag, "default_tag", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.tag,
                        "default_tag",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.tag, "default_tag", $$c)
                }
              }
            }
          }),
          _vm._v(" Auto add on new job ")
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          staticStyle: { "margin-top": "0px" },
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _vm.tag.id <= 0
            ? _c("div", { staticClass: "float-left" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.replicateAcrossAllShops,
                      expression: "replicateAcrossAllShops"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.replicateAcrossAllShops)
                      ? _vm._i(_vm.replicateAcrossAllShops, null) > -1
                      : _vm.replicateAcrossAllShops
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.replicateAcrossAllShops,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.replicateAcrossAllShops = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.replicateAcrossAllShops = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.replicateAcrossAllShops = $$c
                      }
                    }
                  }
                }),
                _vm._v(" Replicate across all shops ")
              ])
            : _vm._e(),
          _c("div", { staticClass: "float-right" }, [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.saving,
                  expression: "saving"
                }
              ],
              staticStyle: {
                width: "18px",
                height: "18px",
                "margin-right": "3px"
              },
              attrs: { src: require("./../../assets/img/spinner.gif") }
            }),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.saving,
                    expression: "!saving"
                  }
                ],
                staticClass: "btn btn-primary",
                attrs: { type: "button", size: "lg", variant: "primary" },
                on: { click: _vm.saveTag }
              },
              [_vm._v("Save")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", variant: "primary" },
                on: { click: _vm.cancelEdits }
              },
              [_vm._v("Cancel")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }