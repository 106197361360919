<template>
  <div
    class="t-mb-3 t-border t-border-solid t-border-gray-300 t-rounded-md
           t-shadow-sm t-flex">
    <div class="t-flex-grow t-px-4 t-py-2">
      <div
        v-if="order.purchaseOrderId"
        @click="openPurchaseOrder(order.purchaseOrderId)"
        class="t-flex t-mt-1"
      >
        <span
          class="t-text-white t-px-2 t-rounded-md t-cursor-pointer"
          :style="supplierColor ? `background-color: ${supplierColor}` : 'background-color: #000000'">
          PO {{ order.purchaseOrderInternalId }}
        </span>
      </div>
      <div class="t-my-2">
        <a
          @click="openOrderDetails(order)"
          class="t-bg-white t-border t-border-solid t-border-blue-400
                  t-rounded-md t-px-2 t-py-1 t-cursor-pointer t-text-blue-500
                  hover:t-bg-blue-500 hover:t-border-blue-500
                  hover:t-text-white t-text-xs">
          Show details
        </a>
        <!-- <a
          v-if="order.fulfilled === 2 || order.return_code !== '0'"
          @click="resubmit(order.id)"
          class="t-bg-white t-border t-border-solid t-border-orange-500
                  t-rounded-md t-px-2 t-py-1 t-cursor-pointer t-text-orange-600
                  hover:t-bg-orange-600 hover:t-border-orange-600
                  hover:t-text-white t-text-xs t-ml-2">
          Resubmit
        </a> -->
      </div>
    </div>
    <div class="t-flex t-flex-col t-justify-center t-w-48">
      <div>
        <i class="el-icon-user t-mr-1 t-text-blue-600"></i>
        <span class="t-text-base">
          {{ order.username }}
        </span>
      </div>
      <div>
        <i class="el-icon-time t-mr-1 t-text-blue-600"></i>
        <span>{{ formatTimestamp(order.createdAt) }}</span>
      </div>
    </div>
    <div class="t-self-center t-w-32">
      <div v-if="!order.partialFailure">
        <div v-if="order.status === 'created'">
          <el-tooltip
            content="Order was created but wasn't submitted"
            placement="top">
            <div class="t-cursor-pointer t-flex t-items-center">
              <i class="el-icon-info t-mr-2 t-text-orange-500 t-text-lg"></i>
              <span class="t-text-base t-text-orange-900">Created</span>
            </div>
          </el-tooltip>
        </div>
        <div v-if="order.status === 'success'">
          <el-tooltip content="Order was successfully placed" placement="top">
            <div class="t-cursor-pointer t-flex t-items-center">
              <i class="el-icon-success t-mr-2 t-text-green-500 t-text-lg"></i>
              <span class="t-text-base t-text-green-900">Placed</span>
            </div>
          </el-tooltip>
        </div>
        <div v-if="order.status === 'rejected'">
          <el-tooltip
            content="Order was rejected, please check details for further
                    information"
            placement="top">
            <div class="t-cursor-pointer t-flex t-items-center">
              <i class="el-icon-warning t-mr-2 t-text-red-500 t-text-lg"></i>
              <span class="t-text-base t-text-red-900">Rejected</span>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div v-else>
        <el-tooltip
          content="Order partually failed, please check details for further
                  information"
          placement="top">
          <div class="t-cursor-pointer t-flex t-items-center">
            <i class="el-icon-warning t-mr-2 t-text-red-500 t-text-lg"></i>
            <span class="t-text-base t-text-red-900">Partial failure</span>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div
      class="t-font-bold t-px-3 t-bg-gray-100 t-border-solid t-border-0
            t-border-l-8 t-border-gray-300 t-rounded-r-md t-self-stretch
            t-flex t-items-center">
      <div class="t-w-24 t-flex-1 t-text-lg t-text-center">
        ${{ roundPrice(order.totalAmount) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  shoppingCartActions,
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'

export default {
  props: ['order', 'supplierColor'],

  computed: {
    orderDetailsIsOpen: {
      get() {
        return this.$store.state.shoppingCart.orders.orderDetailsIsOpen
      },
      set(value) {
        return this.$store.commit(
          shoppingCartMutations.orders.setOrderDetailsIsOpen,
          value
        )
      }
    },
    orderDetails: {
      get() {
        return this.$store.state.shoppingCart.orders.orderDetails
      },
      set(value) {
        return this.$store.commit(
          shoppingCartMutations.orders.setOrderDetails,
          value
        )
      }
    }
  },

  methods: {
    getOrderItems(payload) {
      return this.$store.dispatch(shoppingCartActions.getOrderItems, payload)
    },
    formatTimestamp(dt) {
      return moment(dt).calendar()
    },
    roundPrice(price) {
      return Math.round(price * 100) / 100
    },
    openOrderDetails(order) {
      this.getOrderItems({
        orderId: order.id,
        vendor: 'pilkington'
      })
      this.orderDetails = order
      this.orderDetailsIsOpen = true
    },
    async openPurchaseOrder(poId) {
      this.$store.dispatch('warehouse/openPurchaseOrderDialog', poId)
    }
  }
}
</script>
