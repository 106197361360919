var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { placement: "right", trigger: "click", width: "400" },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c("el-button", { on: { click: _vm.disconnectTech } }, [
            _vm._v("Disconnect Technician")
          ])
        ],
        1
      ),
      _c(
        "el-button",
        {
          attrs: {
            slot: "reference",
            size: "mini",
            type: "success",
            plain: ""
          },
          slot: "reference"
        },
        [_vm._v("Connected")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }