<template>
  <div class="t-flex t-justify-center t-items-center t-mb-2">
    <div
      class="t-border-0 t-border-t t-border-solid t-border-gray-300
             t-flex-grow"
    />
    <div
      class="t-border t-border-solid t-border-gray-300 t-px-3 t-py-1
             t-rounded-full t-shadow-sm t-text-gray-700">
      {{ date }}
    </div>
    <div
      class="t-border-0 t-border-t t-border-solid t-border-gray-300
             t-flex-grow"
    />
  </div>
</template>

<script>
export default {
  props: ['date']
}
</script>
