<template>
  <el-row>
    <el-col :lg="12" :sm="24">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :hide-required-asterisk="true"
        label-width="60px"
        size="mini"
        :disabled="disabled"
      >
        <el-form-item>
          <span slot="label" class="undo-label-styles">SID</span>
          <el-input
            :value="twilioAccount.sid"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item prop="name">
          <span slot="label" class="undo-label-styles">Name</span>
          <el-input
            placeholder="Enter a name"
            v-model="form.name"
          />
        </el-form-item>
        <el-form-item prop="status">
          <span slot="label" class="undo-label-styles">Status</span>
          <el-select
            placeholder="Select a status"
            v-model="form.status"
            style="width: 100%"
          >
            <el-option label="Active" value="active"></el-option>
            <el-option label="Suspended" value="suspended"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            @click="submit"
            :loading="loading"
          >
            Update
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import TwilioAccount from '@/scripts/objects/twilio_account'

export default {
  props: ['twilioAccount', 'disabled'],
  data: () => ({
    loading: false,
    form: {
      id: 0,
      name: '',
      status: ''
    },
    rules: {
      name: { required: true, message: 'Please enter a name', trigger: 'blur' },
      status: { required: true, message: 'Please select a status', trigger: 'blur' }
    }
  }),
  mounted () {
    this.form.id = this.twilioAccount.id
    this.form.name = this.twilioAccount.name
    this.form.status = this.twilioAccount.status
  },
  methods: {
    async submit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          let res = await TwilioAccount.update(this.form)
          this.$emit('updated', res.data)
          this.loading = false
          this.$message.success('Account updated.')
        }
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>
