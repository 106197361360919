<template>
  <div class="vendor-notes-tab">
    <div class="t-text-gray-400 t-mb-4">Notes</div>
    <el-input
      v-model="notes"
      type="textarea"
      :rows="6"
      placeholder="Click to add notes"
      @change="updateNotesHandler"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  data() {
    return {
      notes: ''
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data'])
  },

  created() {
    this.notes = this.data.notes
  },

  methods: {
    ...mapActions(useVendorStore, ['updateNotes']),

    async updateNotesHandler(notes) {
      try {
        await this.updateNotes(notes)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
.vendor-notes-tab {
  .el-textarea__inner {
    @apply t-rounded-lg t-resize-none;
  }
}
</style>
