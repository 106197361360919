<template>
  <el-form
    label-width="180px"
    size="mini"
  >
    <div class="t-flex">
      <div class="t-flex-grow">
        <el-form-item>
          <div class="h6 mb-0">
            Last search request:
          </div>
        </el-form-item>
        <details-item name="User" :value="details.username" />
        <details-item name="Request time" :value="formattedTimestamp" />
        <details-item name="VIN" :value="details.vin" />
        <div v-if="details.status != 'success'">
          <details-item name="Message" value="VIN number is invalid" />
        </div>
        <div style="word-break: break-word;">
          <div v-if="details.help_request_status === 'sent'">
            <details-item
              name="Message"
              value="Help request was sent. Please give us some time to process
                it and we'll get back to you right away."
            />
          </div>
          <div v-if="details.result">
            <details-item
              name="Year"
              :value="details.result.year"
            />
            <details-item
              name="Make"
              :value="details.result.make"
            />
            <details-item
              name="Model"
              :value="details.result.model"
            />
            <details-item
              name="Style"
              :value="details.result.body"
            />
            <details-item
              name="Glass type"
              value="Windshields"
            />
            <template v-for="g in glass">
              <el-divider class="t-flex-grow"/>
              <details-item-tag
                name="After Market Part number"
                :values="[g.partNumber.slice(0, -1)]"
                color="#fff"
                background="#337ab7"

              />
              <!-- <details-item
                name="Features"
                :value="g.features.join(', ')" /> -->
              <el-form-item v-if="g.calibrations.length" class="mb-0">
                <span slot="label" class="undo-label-styles right-part-for-label">Calibrations:</span>
                <div v-for="cal in g.calibrations"> 
                  {{ cal.sensor }}<span class="details-recal-tag t-mx-2" :style="{
                    background: '#805ad5',
                    color: '#fff'
                    }">{{ cal.calibrationType }}
                  </span>
                </div>
              </el-form-item>
              <details-item-tag
                name="Dealer part numbers"
                :values="g.oemPartNumbers"
                color="#fff"
                background="#299fd7"
              />
            </template>
            <template v-if="glass.length == 0">
              <el-divider class="t-flex-grow"/>
              <div class="t-flex-grow t-text-center t-italic t-font-bold">No glass results found...</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <el-divider/>
  </el-form>
</template>

<script>
import { get } from 'vuex-pathify'
import DetailsItem from './DetailsItem'
import DetailsItemTag from './DetailsItemTag.vue'
import moment from 'moment'

export default {
  components: { DetailsItem, DetailsItemTag },
  computed: {
    details: get('rightPart/details'),
    formattedTimestamp () {
      return moment(this.details.created_at).format('lll')
    },
    glass () {
      return this.details?.result?.windshields || []
    },
  },
}
</script>
<style lang="scss" scoped>
.details-recal-tag {
  font-size: $font-md;
  line-height: 17px;
  color: #fff;
  background: $air-force-blue;
  border-radius: 20px;
  padding: 0 $padding-xs;
  letter-spacing: 0.1em;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  height: 23px;
  position: relative;
  white-space: nowrap;
  & ~ .details-recal-tag {
    margin-left: $padding-xs;
  }
  .el-icon-loading {
    color: #FFF;
  }
  span {
    color: #fff;
  }
  &.with-click {
    cursor: pointer;
    /* transition: box-shadow 0.2s ease-in-out; */
    /* &:hover {
      box-shadow: 0 0 3px 0 currentColor;
    } */
  }
  .trigger {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    overflow: hidden;
  }

  &:active {
    filter: brightness(95%);
  }
  i.md-playlist_add {
    margin-left: -24px;
    opacity: 0.4;
  }
  &:hover {
    i.md-playlist_add {
      margin-left: 0;
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
    filter: brightness(95%);
  }
}
</style>