<template>
  <div
    class="t-font-sans t-px-6 t-py-5 t-border t-border-solid t-border-gray-400 t-rounded-md t-mt-4
      t-break-normal"
  >
    <div>
      <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
        Form type
      </div>
      <div class="t-text-gray-700 t-text-sm">
        Select a form type.
      </div>
      <div class="t-flex t-mt-5 t-text-xs t-text-blue-800">
        <div class="t-w-1/2 t-pr-6">
          <el-radio
            v-model="formType"
            label="overlay"
            class="t-normal-case t-font-normal t-bg-white"
            border>
            Overlay
          </el-radio>
          <div class="t-mt-2 t-italic">
            Form will be placed in a side or a pop-up modal by your choice.
          </div>
        </div>
        <div class="t-w-1/2 t-pr-6">
          <el-radio
            v-model="formType"
            label="embedded"
            class="t-normal-case t-font-normal t-bg-white"
            border>
            Embedded
          </el-radio>
          <div class="t-mt-2 t-italic">
            Form will be embedded in your page.
          </div>
        </div>
      </div>
    </div>

    <el-divider/>
    <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
      Form fields
    </div>
    <div class="t-text-gray-700 t-text-sm">
      <el-table
        :data="fields"
        style="width: 100%">
        <el-table-column
          width="55">
          <template slot="header" slot-scope="scope">
            <el-checkbox
              v-model="allFieldsSelected"
              :indeterminate="someFieldsSelected"
            >
            </el-checkbox>
          </template>
          <template slot-scope="scope">
            <el-checkbox
              :disabled="['gb_first_name', 'gb_phone'].includes(scope.row.id)"
              v-model="scope.row.show"
              @change="handleShowFieldChange($event, scope.row)"
            >
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          property="name"
          label="Field"
          width="120">
        </el-table-column>
        <el-table-column
          prop="required"
          label="Required"
          align="right">
          <template slot-scope="scope">
            <el-switch
              :disabled="['gb_first_name', 'gb_phone'].includes(scope.row.id)"
              v-model="scope.row.required"
              @change="handleRequiredFieldChange($event, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="formType === 'embedded'">
      <el-divider/>
      <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
        Submit button text
      </div>
      <div class="t-text-gray-700 t-text-sm">
        Enter text you want on the submit button (defaults to "Get a quote")
      </div>
      <div class="t-flex t-mt-5 t-text-xs t-text-blue-800">
        <div class="t-w-1/2 t-pr-6">
          <el-input
            v-model="submitText"
          />
        </div>
      </div>
      <el-divider/>
      <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
        Redirect Url
      </div>
      <div class="t-text-gray-700 t-text-sm">
        Website to direct customers to after they complete the form (Optional)
      </div>
      <div class="t-flex t-mt-5 t-text-xs t-text-blue-800">
        <div class="t-w-1/2 t-pr-6">
          <el-input v-model="redirectUrl">
            <template slot="prepend">https://</template>
          </el-input>
        </div>
      </div>
      <el-divider/>
      <div>
        <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
          Scripts for your website
        </div>
        <div class="t-text-gray-700 t-text-sm">
          Click on the text below to copy the script to clipboard. Paste it into your website in any place you want. The
          width of the form is dependant on the parent element or the wrapper. The script can be places into a &lt;div&gt;
          element which would dictate the position of it.
        </div>
        <div
          @click="copy($event, 'script')"
          ref="script"
          class="t-mt-10 t-cursor-pointer">
            <pre class="t-text-blue-700 t-mb-0">
              &lt;iframe
                id="gb-iframe"
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"&gt;
              &lt;/iframe&gt;
            </pre>
        </div>
      </div>
      <div class="t-text-gray-700 t-text-sm">
        Click on the text below to copy the script to clipboard. Add it to
        your HTML app by pasting it before &lt;/body&gt; tag.
      </div>
      <div
        @click="copy($event, 'script')"
        ref="script"
        class="t-mt-10 t-cursor-pointer">
        <pre class="t-text-blue-700 t-mb-0">
          &lt;script&gt;
            document.addEventListener("DOMContentLoaded", () => {
              const src = 'https://web-form.glassbiller.com/iframe.html' +
                `?shopId={{ shopId }}` +
                `&submitText={{ encodeURIComponent(submitText) }}` +
                `&fields={{ encodeURIComponent(JSON.stringify(fields)) }}` +
                `&url=${window.location.href}` +
                `&redirectUrl={{ `https://${redirectUrl}` }}`
              const iframe = document.getElementById('gb-iframe');
              iframe.src = src
            })
          &lt;/script&gt;
        </pre>
      </div>
    </div>

    <div v-if="formType === 'overlay'">
      <el-divider/>
      <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
        Button type
      </div>
      <div class="t-text-gray-700 t-text-sm">
        You can select a button type for opening the form.
      </div>
      <div class="t-flex t-mt-5 t-text-xs t-text-blue-800">
        <div class="t-w-1/2 t-pr-6">
          <el-radio
            v-model="buttonType"
            label="fixed"
            class="t-normal-case t-font-normal t-bg-white"
            border>
            Floating button
          </el-radio>
          <div class="t-mt-2 t-italic">
            With this option the button will be sticky in one of the bottom corners
            of the screen (you can choose which one).
          </div>
        </div>
        <div class="t-w-1/2 t-pr-6">
          <el-radio
            v-model="buttonType"
            label="regular"
            class="t-normal-case t-font-normal t-bg-white"
            border>
            Regular button
          </el-radio>
          <div class="t-mt-2 t-italic">
            With this option you can add a button to any place of your HTML code, use
            already existing button or even multiple buttons.
          </div>
        </div>
      </div>

      <div v-if="buttonType !== ''">
        <el-divider/>
        <div v-if="buttonType === 'fixed'">
          <div>
            <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
              Button position
            </div>
            <div class="t-text-gray-700 t-text-sm">
              You can select a button position, where you want it to appear on your
              website.
            </div>
            <div class="t-flex t-mt-5 t-text-xs t-text-blue-800">
              <div class="t-w-1/2 t-pr-6">
                <el-radio
                  v-model="buttonPosition"
                  label="left"
                  class="t-normal-case t-font-normal t-bg-white"
                  border>
                  Left side
                </el-radio>
                <div class="t-mt-2 t-italic">
                  With this option the button will be sticky in the lower left corner
                  of the screen.
                </div>
              </div>
              <div class="t-w-1/2 t-pr-6">
                <el-radio
                  v-model="buttonPosition"
                  label="right"
                  class="t-normal-case t-font-normal t-bg-white"
                  border>
                  Right side
                </el-radio>
                <div class="t-mt-2 t-italic">
                  With this option the button will be sticky in the lower right corner
                  of the screen.
                </div>
              </div>
            </div>
          </div>
          <el-divider/>
          <div>
            <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
              Button colors
            </div>
            <div class="t-text-gray-700 t-text-sm">
              You can choose your own color for the button background, border and
              text. Enter hex for desired colors.
            </div>
            <div class="t-flex t-mt-5">
              <div class="t-w-1/2">
                <div class="t-flex t-items-center t-mb-1">
                  <el-color-picker v-model="buttonBgColor"/>
                  <div class="t-text-gray-700 t-text-sm t-ml-2">Background color</div>
                </div>
                <div class="t-flex t-items-center t-mb-1">
                  <el-color-picker v-model="buttonBorderColor"/>
                  <div class="t-text-gray-700 t-text-sm t-ml-2">Border color</div>
                </div>
                <div class="t-flex t-items-center t-mb-1">
                  <el-color-picker v-model="buttonTextColor"/>
                  <div class="t-text-gray-700 t-text-sm t-ml-2">Text color</div>
                </div>
              </div>
              <div class="t-w-1/2 t-flex t-items-center">
                <button
                  class="t-rounded-full t-px-6 t-py-3 t-text-sm t-outline-none
                   t-uppercase t-cursor-default"
                  :style="[
                  { 'background-color': buttonBgColor },
                  { 'border': `1px solid ${buttonBorderColor}` },
                  { 'color': buttonTextColor }
                ]"
                >
                  Get a quote
                </button>
              </div>
            </div>
          </div>
          <el-divider/>
        </div>
        <div>
          <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
            Form style
          </div>
          <div class="t-text-gray-700 t-text-sm">
            Choose between side modal opening or a pop-up modal for your
            quick quote form. For both styles the form will remain the same on
            mobile devices.
          </div>
          <div class="t-flex t-mt-5 t-text-xs t-text-blue-800">
            <div class="t-w-1/2 t-pr-6">
              <el-radio
                v-model="modalPosition"
                label="dialog"
                class="t-normal-case t-font-normal t-bg-white"
                border>
                Pop-up
              </el-radio>
              <div class="t-mt-2 t-italic">
                With this option the modal will open as a pop-up in the middle of
                the screen.
              </div>
            </div>
            <div class="t-w-1/2 t-pr-6">
              <el-radio
                v-model="modalPosition"
                label="drawer"
                class="t-normal-case t-font-normal t-bg-white"
                border>
                Side modal
              </el-radio>
              <div class="t-mt-2 t-italic">
                With this option the modal will open from the right side of the
                screen.
              </div>
            </div>
          </div>
        </div>
        <el-divider/>
        <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
          Submit button text
        </div>
        <div class="t-text-gray-700 t-text-sm">
          Enter a text you want on the submit button (defaults to "Get a quote")
        </div>
        <div class="t-flex t-mt-5 t-text-xs t-text-blue-800">
          <div class="t-w-1/2 t-pr-6">
            <el-input
              v-model="submitText"
            />
          </div>
        </div>
        <el-divider/>
        <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
          Redirect Url
        </div>
        <div class="t-text-gray-700 t-text-sm">
          Website to direct customers to after they complete the form (Optional)
        </div>
        <div class="t-flex t-mt-5 t-text-xs t-text-blue-800">
          <div class="t-w-1/2 t-pr-6">
            <el-input v-model="redirectUrl">
              <template slot="prepend">https://</template>
            </el-input>
          </div>
        </div>
        <el-divider/>
        <div>
          <div class="t-text-gray-800 t-text-lg t-mb-1 t-font-medium">
            Script for your website
          </div>
          <div v-if="buttonType === 'regular'" class="t-mb-8">
            <div class="t-text-gray-700 t-text-sm">
              Click on the text below to copy the script to clipboard. Add it to any desired place
              in your HTML code and style it any way you wish (or just copy a class name and add it to one or many
              existing buttons on your website).
            </div>
            <div
              @click="copy($event, 'button')"
              ref="button"
              class="t-mt-3 t-text-blue-700 t-cursor-pointer">
              &lt;button
              class="gb-get-quote-button"
              &gt;Get a quote&lt;/button&gt;
            </div>
          </div>
          <div class="t-text-gray-700 t-text-sm">
            Click on the text below to copy the script to clipboard. Add it to
            your HTML app by pasting it before &lt;/body&gt; tag.
          </div>
          <div
            @click="copy($event, 'script')"
            ref="script"
            class="t-mt-10 t-cursor-pointer">
            <pre class="t-text-blue-700 t-mb-0">
              &lt;script&gt;
                document.addEventListener("DOMContentLoaded", () => {
                  const src = "https://web-form.glassbiller.com/index.js";
                  let script = document.createElement("script");
                  script.setAttribute("src", `${src}?${Date.now()}`);
                  script.setAttribute("shop-id", "{{ shopId }}");
                  script.setAttribute("button-type", "{{ buttonType }}");
                  script.setAttribute("button-position", "{{ buttonPosition }}");
                  script.setAttribute("button-bg-color", "{{ buttonBgColor }}");
                  script.setAttribute("button-border-color", "{{ buttonBorderColor }}");
                  script.setAttribute("button-text-color", "{{ buttonTextColor }}");
                  script.setAttribute("modal-position", "{{ modalPosition }}");
                  script.setAttribute("submit-text", "{{ submitText }}");
                  script.setAttribute("redirect-url", "{{ `https://${redirectUrl}` }}");
                  script.setAttribute("fields", "{{ encodeURIComponent(JSON.stringify(fields)) }}");
                  document.body.appendChild(script);
                })
              &lt;/script&gt;
            </pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['shopId'],
  data: () => ({
    formType: '',
    buttonType: '',
    buttonPosition: 'right',
    modalPosition: 'dialog',
    buttonBgColor: '#90cdf4',
    buttonBorderColor: '#90cdf4',
    buttonTextColor: '#2c5282',
    submitText: 'Get a quote',
    redirectUrl: '',
    fields: [
      { id: 'gb_first_name', name: 'First Name', show: true, required: true },
      { id: 'gb_last_name', name: 'Last Name', show: true, required: false },
      { id: 'gb_phone', name: 'Phone', show: true, required: true },
      { id: 'gb_email', name: 'Email Address', show: true, required: true },
      { id: 'gb_address', name: 'Address', show: true, required: false },
      { id: 'gb_vin', name: 'Vin', show: true, required: false },
      { id: 'gb_vehicle', name: 'Vehicle', show: true, required: true },
      { id: 'gb_referral', name: 'Referral', show: true, required: false },
      { id: 'gb_description', name: 'Description', show: true, required: false }
    ]
  }),
  computed: {
    allFieldsSelected: {
      // first name and phone cannot be hidden or marked as optional
      get () {
        let allSelected = true

        for (const field of this.fields) {
          if (!['gb_first_name', 'gb_phone'].includes(field.id) && !field.show) {
            allSelected = false
            break
          }
        }

        return allSelected
      },
      set (v) {
        for (const field of this.fields) {
          if (!['gb_first_name', 'gb_phone'].includes(field.id)) {
            field.show = v
            if (!v) field.required = false
          }
        }
      }
    },
    someFieldsSelected() {
      // first name and phone cannot be hidden or marked as optional
      const selected = this.fields.filter((field) => {
        return !['gb_first_name', 'gb_phone'].includes(field.id) && field.show
      })
      const unselected = this.fields.filter((field) => !field.show)

      if (selected.length && unselected.length) {
        return true
      }

      return false
    }
  },
  methods: {
    copy: async function (event, ref) {
      await this.$copyText(event.target.innerText, this.$refs[ref])
      this.$message.success('Copied to clipboard')
    },
    handleShowFieldChange: function (value, row) {
      if (!value) row.required = false
    },
    handleRequiredFieldChange: function (value, row) {
      if (value) row.show = true
    }
  }
}
</script>
