<template>
  <div class="calendar-job-card-list">
    <div class="title">{{ title }}</div>
    <div class="job-card-container" @dragover="setDragoverDate">
      <calendar-tech-jobs-card-list
        class="job-card-group"
        v-for="route in routes"
        :key="route.id"
        :route="route"
        :date="date"
        :isDragover="isDragover"
      />
    </div>
  </div>
</template>

<script>
import { humanDate } from '@/scripts/helpers/date.helpers'
import CalendarTechJobsCardList from './CalendarTechJobsCardList'
import { get } from 'vuex-pathify'

export default {
  components: { CalendarTechJobsCardList },
  props: ['date', 'dragoverDate'],
  computed: {
    title() {
      return humanDate(this.date)
    },
    getWeekRoutes: get('sch_v3/getWeekRoutes'),
    routes() {
      return this.getWeekRoutes(this.date)
    },
    isDragover() {
      return this.dragoverDate === this.date
    }
  },
  methods: {
    setDragoverDate() {
      if (!this.isDragover) {
        this.$root.$emit('calendar-drag-over', this.date)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/CalendarJobCardList.scss';
</style>
