import gql from 'graphql-tag'
import { RATES_FRAGMENT } from '../fragments/rates.fragment'

export const CREATE_UPDATE_SHOP_RATES_MUTATION = gql`
  mutation createUpdateShopRates($shop_id: ID!, $rates: RatesInput!) {
    createUpdateShopRates(shop_id: $shop_id, rates: $rates) {
      ...RatesFields
    }
  }
  ${RATES_FRAGMENT}
`

export const DELETE_SHOP_RATES_MUTATION = gql`
  mutation deleteShopRates($id: ID!, $shop_id: ID!) {
    deleteShopRates(id: $id, shop_id: $shop_id)
  }
`

export const CREATE_UPDATE_JOB_RATES_MUTATION = gql`
  mutation createUpdateJobRates($id: ID!, $rates: RatesInput!) {
    createUpdateJobRates(id: $id, rates: $rates) {
      ...RatesFields
    }
  }
  ${RATES_FRAGMENT}
`
