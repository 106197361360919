var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-flex t-justify-between t-space-x-6 t-items-center t-px-6 t-py-4 t-border-b t-border-b-gray-200 t-shadow"
    },
    [
      _c(
        "div",
        { staticClass: "t-flex t-flex-grow t-justify-between t-items-center" },
        [
          _c("div", { staticClass: "t-flex t-items-center" }, [
            _c("div", { staticClass: "t-text-gray-400 t-text-base t-mr-6" }, [
              _vm._v("Vendor Profile")
            ]),
            _c("div", { staticClass: "t-flex t-items-center" }, [
              _vm.isCSR
                ? _c(
                    "div",
                    {
                      staticClass: "vendor-badge",
                      style: {
                        backgroundColor:
                          _vm.vendorTypes[_vm.vendorType.CSR].color
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.vendorTypes[_vm.vendorType.CSR].name) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.isSaleSource
                ? _c(
                    "div",
                    {
                      staticClass: "vendor-badge",
                      style: {
                        backgroundColor:
                          _vm.vendorTypes[_vm.vendorType.SALESOURCE].color
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.vendorTypes[_vm.vendorType.SALESOURCE].name
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.isSalesRep
                ? _c(
                    "div",
                    {
                      staticClass: "vendor-badge",
                      style: {
                        backgroundColor:
                          _vm.vendorTypes[_vm.vendorType.SALESREP].color
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.vendorTypes[_vm.vendorType.SALESREP].name
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.isTech
                ? _c(
                    "div",
                    {
                      staticClass: "vendor-badge",
                      style: {
                        backgroundColor:
                          _vm.vendorTypes[_vm.vendorType.TECH].color
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.vendorTypes[_vm.vendorType.TECH].name) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.isLocation
                ? _c(
                    "div",
                    {
                      staticClass: "vendor-badge",
                      style: {
                        backgroundColor:
                          _vm.vendorTypes[_vm.vendorType.LOCATION].color
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.vendorTypes[_vm.vendorType.LOCATION].name
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.isSupplier
                ? _c(
                    "div",
                    {
                      staticClass: "vendor-badge",
                      style: {
                        backgroundColor:
                          _vm.vendorTypes[_vm.vendorType.SUPPLIER].color
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.vendorTypes[_vm.vendorType.SUPPLIER].name
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _vm.saving
            ? _c(
                "div",
                {
                  staticClass:
                    "t-flex t-text-yellow-700 t-items-center t-space-x-2"
                },
                [
                  _c("i", { staticClass: "el-icon-loading t-text-xl" }),
                  _c("div", [_vm._v("Saving...")])
                ]
              )
            : _vm._e(),
          !_vm.saving && _vm.saved
            ? _c(
                "div",
                {
                  staticClass:
                    "t-flex t-text-green-600 t-items-center t-space-x-2"
                },
                [
                  _c("i", { staticClass: "material-icons-outline md-check" }),
                  _c("div", [_vm._v("Updated!")])
                ]
              )
            : _vm._e()
        ]
      ),
      _c("i", {
        staticClass:
          "material-icons-outline md-clear t-text-gray-600 t-text-lg t-cursor-pointer",
        on: { click: _vm.close }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }