import Api from '@/services/Api'

const getPaymentGroups = async ({
  shopId,
  dateFrom,
  dateTo,
  page,
  id,
  customerName,
  amount,
  type,
  xActionType,
  xActionNumber
}) => {
  const { data } = await Api.get('/api/arap/paymentGroups', {
    params: {
      shopId,
      dateFrom,
      dateTo,
      page,
      id,
      customerName,
      amount,
      type,
      xActionType,
      xActionNumber
    }
  })
  return data
}

const getPaymentGroupDetails = async paymentGroupId => {
  const { data } = await Api.get(`/api/arap/paymentGroups/${paymentGroupId}`)
  return data
}

const add = async ({
  shopId,
  type,
  date,
  xActionType,
  xActionNumber,
  paymentAccountId,
  payments,
  processor,
  authorizeDataDescriptor,
  authorizeDataValue,
  squareToken,
  cloverToken,
  paymentGroupId
}) => {
  await Api.post('/api/arap/paymentGroups', {
    shopId,
    type,
    date,
    xActionType,
    xActionNumber,
    paymentAccountId,
    payments,
    processor,
    authorizeDataDescriptor,
    authorizeDataValue,
    squareToken,
    cloverToken,
    paymentGroupId
  })
}

const addEmpty = async ({
  shopId,
  type,
  date,
  xActionType,
  xActionNumber,
  paymentAccountId
}) => {
  return await Api.post('/api/arap/paymentGroups/empty', {
    shopId,
    type,
    date,
    xActionType,
    xActionNumber,
    paymentAccountId
  }) 
}

const update = ({ id, data }) => Api.put(`/api/arap/paymentGroups/${id}`, data)

const remove = id => Api.delete(`/api/arap/paymentGroups/${id}`)

export const paymentGroupRepository = Object.freeze({
  getPaymentGroups,
  getPaymentGroupDetails,
  add,
  addEmpty,
  update,
  remove
})
