var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gb-form", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        key: _vm.forceUpdate,
        ref: "formRef",
        attrs: { schema: _vm.schema },
        model: {
          value: _vm.data,
          callback: function($$v) {
            _vm.data = $$v
          },
          expression: "data"
        }
      }),
      _c("el-button", { on: { click: _vm.submit } }, [
        _vm._v(" Submit Comment ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }