import Api from '@/services/Api'

const updateVendorWarehouses = async ({
  shopId,
  account,
  warehouses,
  defaultWarehouse
}) => Api.post(`/api/shop/${shopId}/updateVendorWarehouses`, {
  account,
  warehouses,
  defaultWarehouse
})

export const glaxisRepository = Object.freeze({
  updateVendorWarehouses
})
