var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title: "Job Notes",
        id: "notesModal",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg"
      },
      on: {
        show: _vm.dialogAboutToAppear,
        shown: function($event) {
          return _vm.$refs.notesTextArea.focus()
        },
        hide: _vm.dialogAboutToDisappear
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "form-group col-12" },
          [
            _vm._l(_vm.notes, function(note, index) {
              return _c("div", { key: index }, [
                _c("p", { style: note.getUserStyleForNote(_vm.users) }, [
                  note.user_id === _vm.$store.state.user.id
                    ? _c(
                        "span",
                        [
                          _c("font-awesome-icon", {
                            staticStyle: { color: "red", cursor: "pointer" },
                            attrs: { icon: _vm.faMinusCircle },
                            on: {
                              click: function($event) {
                                return _vm.deleteNote(index, note)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("b", [_vm._v(_vm._s(note.getUserNameForNote(_vm.users)))]),
                  _vm._v(": " + _vm._s(note.note_text) + " "),
                  _c("span", { staticClass: "noteDate" }, [
                    _vm._v(_vm._s(note.note_date))
                  ])
                ])
              ])
            }),
            _c("hr")
          ],
          2
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.noteText,
                expression: "noteText"
              }
            ],
            ref: "notesTextArea",
            staticClass: "form-control form-control-sm",
            domProps: { value: _vm.noteText },
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                ) {
                  return null
                }
                return _vm.textareaEnterKeyPressed($event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.noteText = $event.target.value
              }
            }
          })
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", size: "lg", variant: "primary" },
                on: {
                  click: function($event) {
                    return _vm.closeModal()
                  }
                }
              },
              [_vm._v("Close")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }