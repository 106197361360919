var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-date-filter" },
    [
      _c("div", { staticClass: "ui-input-label" }, [_vm._v("Date:")]),
      _c("el-date-picker", {
        attrs: {
          type: "week",
          placeholder: "Pick a week",
          format: "week of MMM d",
          clearable: false
        },
        model: {
          value: _vm.week,
          callback: function($$v) {
            _vm.week = $$v
          },
          expression: "week"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }