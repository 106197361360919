var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "120px",
        width: "900px",
        "custom-class": "edit-warehouse-entity-dialog",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-gray-700 t-text-lg",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-document" }),
          _c("span", { staticClass: "t-ml-3" }, [
            _vm._v(
              " " + _vm._s(_vm.id ? "Edit" : "Create") + " Purchase Order "
            ),
            _vm.po
              ? _c("span", [_vm._v("#" + _vm._s(_vm.po.internalId))])
              : _vm._e()
          ])
        ]
      ),
      _vm.poFetching
        ? _c("LoadingSpinner")
        : _c(
            "div",
            { staticClass: "t-pt-6" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    size: "small",
                    "label-width": "200px",
                    "hide-required-asterisk": ""
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "supplierId",
                        "show-message": false,
                        rules: [{ required: true }]
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Supplier")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticClass: "t-w-72 select-wrapper",
                          attrs: {
                            filterable: "",
                            placeholder: "Select Supplier",
                            "popper-class": "select-dropdown-with-add"
                          },
                          model: {
                            value: _vm.form.supplierId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "supplierId", $$v)
                            },
                            expression: "form.supplierId"
                          }
                        },
                        [
                          _vm._l(_vm.suppliers, function(i) {
                            return _c("el-option", {
                              key: i.id,
                              style: i.color ? "color: " + i.color : "",
                              attrs: { label: i.name, value: i.id }
                            })
                          }),
                          _c(
                            "div",
                            { staticClass: "add-new-item" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", icon: "el-icon-plus" },
                                  on: { click: _vm.onCreateVendor }
                                },
                                [_vm._v(" Add New Supplier ")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "t-ml-4 min-content",
                          attrs: { effect: "plain" },
                          on: {
                            click: function($event) {
                              _vm.supplierSearchDialogOpen = true
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-search" }),
                          _vm._v(" National Supplier List ")
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "deliveryAddressId" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Delivery address")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticClass: "t-w-72 select-wrapper",
                          attrs: {
                            filterable: "",
                            placeholder: "Select Delivery Address",
                            "popper-class": "select-dropdown-with-add"
                          },
                          model: {
                            value: _vm.form.deliveryAddressId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "deliveryAddressId", $$v)
                            },
                            expression: "form.deliveryAddressId"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Will Call", value: null }
                          }),
                          _vm._l(_vm.addresses, function(i) {
                            return _c("el-option", {
                              key: i.id,
                              attrs: { label: i.name, value: i.id }
                            })
                          }),
                          _c(
                            "div",
                            { staticClass: "add-new-item" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", icon: "el-icon-plus" },
                                  on: {
                                    click: function($event) {
                                      _vm.deliveryAddressesOpen = true
                                    }
                                  }
                                },
                                [_vm._v(" Add New Address ")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "date",
                        "show-message": false,
                        rules: [{ required: true }]
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Date")]
                      ),
                      _c("el-date-picker", {
                        staticClass: "t-w-72",
                        attrs: {
                          type: "date",
                          format: "MM/dd/yyyy",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "Pick a date"
                        },
                        model: {
                          value: _vm.form.date,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "date", $$v)
                          },
                          expression: "form.date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "deliveryDate" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Est. delivery date")]
                      ),
                      _c("el-date-picker", {
                        staticClass: "t-w-72",
                        attrs: {
                          type: "date",
                          format: "MM/dd/yyyy",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "Pick a date"
                        },
                        model: {
                          value: _vm.form.deliveryDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deliveryDate", $$v)
                          },
                          expression: "form.deliveryDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "ref" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Ref")]
                      ),
                      _c("el-input", {
                        staticClass: "t-w-72",
                        attrs: { placeholder: "Enter a ref" },
                        model: {
                          value: _vm.form.ref,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "ref", $$v)
                          },
                          expression: "form.ref"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "isConfirmed" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Confirmed ?")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticClass: "t-w-72",
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.isConfirmed,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "isConfirmed", $$v)
                            },
                            expression: "form.isConfirmed"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Open", value: false }
                          }),
                          _c("el-option", {
                            attrs: { label: "Confirmed", value: true }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.form.lines, function(line, index) {
                    return _c("div", { key: index, staticClass: "t-mx-6" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "t-flex t-border t-border-solid t-border-gray-100 t-rounded-md t-px-4\n            t-py-2 t-shadow t-bg-blue-50 t-space-x-3 t-mb-3"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "t-flex t-flex-col t-w-6" },
                            [
                              _c("div", { staticClass: "t-h-5" }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "t-flex t-flex-grow t-items-center"
                                },
                                [
                                  line.reservedCount > 0
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "Some of the parts are used by jobs",
                                            placement: "top"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-delete t-text-gray-400 t-cursor-not-allowed"
                                          })
                                        ]
                                      )
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-delete t-text-red-400 t-cursor-pointer t-transform\n                  t-duration-200 hover:t-scale-110",
                                        on: {
                                          click: function($event) {
                                            return _vm.form.lines.splice(
                                              index,
                                              1
                                            )
                                          }
                                        }
                                      })
                                ],
                                1
                              )
                            ]
                          ),
                          _c("div", { staticClass: "t-flex t-flex-grow" }, [
                            _c(
                              "div",
                              { staticClass: "t-w-3/12 t-pr-4" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-text-gray-500 t-text-xs t-h-5"
                                  },
                                  [_vm._v("Item")]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "t-mb-0",
                                    attrs: {
                                      "label-width": "0",
                                      prop: "lines." + index + ".itemId",
                                      "show-message": false
                                    }
                                  },
                                  [
                                    _vm.form.lines[index].itemId &&
                                    _vm.form.lines[index].itemName
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.lines[index].itemName
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "t-w-5/12 t-pr-4" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-text-gray-500 t-text-xs t-h-5"
                                  },
                                  [_vm._v("Memo")]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "t-mb-0",
                                    attrs: {
                                      "label-width": "0",
                                      prop: "lines." + index + ".notes"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "t-w-full",
                                      attrs: { placeholder: "Notes" },
                                      model: {
                                        value: _vm.form.lines[index].notes,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.lines[index],
                                            "notes",
                                            $$v
                                          )
                                        },
                                        expression: "form.lines[index].notes"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "t-w-1/6 t-pr-4" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-text-gray-500 t-text-xs t-h-5"
                                  },
                                  [_vm._v("Quantity")]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "t-mb-0",
                                    attrs: {
                                      "label-width": "0",
                                      prop: "lines." + index + ".quantity",
                                      "show-message": false,
                                      rules: [{ required: true }]
                                    }
                                  },
                                  [
                                    _vm.form.lines[index].readonlyQuantity
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.lines[index].quantity
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _c("el-input", {
                                          staticClass: "t-w-full",
                                          attrs: {
                                            type: "number",
                                            size: "small",
                                            min:
                                              _vm.form.lines[index]
                                                .reservedCount || 1
                                          },
                                          model: {
                                            value:
                                              _vm.form.lines[index].quantity,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form.lines[index],
                                                "quantity",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "form.lines[index].quantity"
                                          }
                                        })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "t-w-1/6 t-pr-4" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-text-gray-500 t-text-xs t-h-5"
                                  },
                                  [_vm._v("Cost")]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "t-mb-0",
                                    attrs: {
                                      "label-width": "0",
                                      prop: "lines." + index + ".cost",
                                      "show-message": false,
                                      rules: [{ required: true }]
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticClass: "t-w-full",
                                      attrs: { size: "small", controls: false },
                                      model: {
                                        value: _vm.form.lines[index].cost,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.lines[index],
                                            "cost",
                                            $$v
                                          )
                                        },
                                        expression: "form.lines[index].cost"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "t-w-20" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-text-gray-500 t-text-xs t-h-5"
                                  },
                                  [_vm._v(" Total ")]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "t-mb-0",
                                    attrs: { "label-width": "0" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatPrice(
                                            _vm.form.lines[index].quantity *
                                              _vm.form.lines[index].cost
                                          ) || 0
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ])
                  }),
                  _c(
                    "div",
                    { staticClass: "t-flex" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                plain: "",
                                size: "mini",
                                icon: "el-icon-plus"
                              },
                              on: {
                                click: function($event) {
                                  _vm.itemSearchDialogOpen = true
                                }
                              }
                            },
                            [_vm._v(" Add item ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "t-flex t-flex-grow t-items-baseline t-pr-6"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "t-text-gray-500 t-mr-2 t-text-sm t-flex-grow t-text-right"
                            },
                            [_vm._v(" Grand total ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "t-text-xl t-font-medium" },
                            [_vm._v(" " + _vm._s(_vm.total) + " ")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass:
                    "t-py-3 t-px-6 t-flex t-justify-between t-border-0 t-border-t t-border-solid"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "small" },
                      on: { click: _vm.handleClose }
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        loading: _vm.submitting,
                        icon: _vm.id ? "el-icon-edit" : "el-icon-plus"
                      },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.id ? "Update" : "Create") + " ")]
                  )
                ],
                1
              ),
              _c("national-supplier-lookup-dialog", {
                attrs: {
                  suppliers: _vm.suppliers,
                  visible: _vm.supplierSearchDialogOpen,
                  shop: _vm.shop
                },
                on: {
                  "update:visible": function($event) {
                    _vm.supplierSearchDialogOpen = $event
                  },
                  created: _vm.onSave
                }
              }),
              _c("ItemSearchDialog", {
                attrs: {
                  visible: _vm.itemSearchDialogOpen,
                  "shop-id": _vm.shopId
                },
                on: {
                  "update:visible": function($event) {
                    _vm.itemSearchDialogOpen = $event
                  },
                  selected: function($event) {
                    return _vm.form.lines.push($event)
                  }
                }
              }),
              _c("DeliveryAddresses", {
                attrs: {
                  visible: _vm.deliveryAddressesOpen,
                  "shop-id": _vm.shopId
                },
                on: {
                  "update:visible": function($event) {
                    _vm.deliveryAddressesOpen = $event
                  },
                  created: _vm.onDeliveryAddressCreated,
                  removed: _vm.onDeliveryAddressRemoved
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }