<template>
  <div>
    <el-dialog
      append-to-body
      top="80px"
      width="600px"
      :visible="visible"
      :before-close="handleClose"
      @open="onOpen"
    >
      <div>
        <span slot="label" class="undo-label-styles">Search National List</span>
        <el-input
          type="text"
          v-model="searchVal"
        />
      </div>
      <div>
        <Table :data="prunedList" @selected="selected"/>
      </div>
    </el-dialog>
    <profile :visible.sync="showSupplierProfile" v-bind="selectedSupplier" @create="create" :creating="creating"/>
  </div>
</template>

<script>
import Vendor from '@/scripts/objects/vendor'
import Table from './Table.vue'
import Profile from './Profile.vue'
import { getVendorColor, vendorTitleMapping } from '@/scripts/helpers/gb_vendor.helpers.js'

export default {
  components: {
    Table,
    Profile
  },
  props: {
    visible: Boolean,
    suppliers: Array,
    shop: Object
  },

  data: () => ({
    fetching: true,
    warehouses: [],
    searchVal: "",
    supplierMappingObject: {},
    selectedSupplier: null,
    showSupplierProfile: false,
    creating: false
  }),

  computed: {
    prunedList () {
      return this.warehouses.filter(warehouse => {
        return !this.supplierMappingObject[warehouse.searchLabel.toUpperCase().replaceAll(' ', '')] && warehouse.searchLabel.toUpperCase().includes(this.searchVal.toUpperCase())
      })
    },
  },

  methods: {
    onOpen() {
      var self = this
      Vendor.getNationalWarehouseList(response => {
        let tempArr = []
        for (var i = 0; i < response.length; i++) {
          var tempItem = response[i]
          tempItem.searchLabel = (vendorTitleMapping[tempItem.type] || 'Other') + ': ' + tempItem.name
          tempArr.push(tempItem)
        }
        self.warehouses = tempArr
      })
      this.supplierMappingObject = {}
      if (this.suppliers?.length) {
        this.suppliers.map(v => {
          this.supplierMappingObject[v.name.toUpperCase().replaceAll(" ", "")] = true
        })
      }
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    selected(sSupplier) {
      this.selectedSupplier = sSupplier
      this.showSupplierProfile = true
    },
    async create() {
      this.creating = true
      const s = this.selectedSupplier
      var tempVendor = {
        ...(s.g_address 
        ? {
          address: s.g_address.address,
          city: s.g_address.city,
          state: s.g_address.state,
          zip: s.g_address.zip,
          g_address: s.g_address
        }
        : {
          address: s.address_street,
          city: s.address_city,
          state: s.address_state,
          zip: s.address_zip
        }),
        email: s.email,
        status: 'active',
        name: s.searchLabel,
        phone: s.phone,
        fax: s.fax,
        color: getVendorColor(s.type),
        national_vendor_id: s.id,
        short_code: s.short_code
      }
      const tempGbVendor = new Vendor(tempVendor)
      const newSavedVendor = await tempGbVendor.createUpdateVendor(this.shop)
      this.$emit('created', newSavedVendor)
      this.showSupplierProfile = false
    }
  }
}
</script>

<style>
</style>