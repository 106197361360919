import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class GlaxisLossNotice extends ObjectTracker {
  id = -1
  glaxis_claim_id = -1
  environment_id = ''
  session_id = ''
  origination_pid = ''
  origination_company_id = ''
  destination_pid = ''
  transaction_date = ''
  transaction_time = ''
  shop_id = -1
  created = -1
  modified = -1
  created_by = -1
  created_by_name = ''
  modified_by = -1
  modified_by_name = ''
  insurance_company_id_code = ''
  insured_first_name = ''
  insured_last_name = ''
  insured_phone_number = ''
  insured_alternate_phone_number = ''
  insured_street_address = ''
  insured_city = ''
  insured_state_province = ''
  insured_postal_code = ''
  insured_policy_number = ''
  state_policy_written = ''
  agency_name = ''
  agent_first_name = ''
  agent_last_name = ''
  agent_phone_number = ''
  insurer_claim_number = ''
  date_of_loss = ''
  vehicle_id_number = ''
  vehicle_id_source = ''
  type_of_vehicle = 'STANDARD'
  vin_number = ''
  vehicle_number = ''
  manual_vehicle_entry = ''
  vehicle_year_text = ''
  vehicle_make_text = ''
  vehicle_model_text = ''
  vehicle_sub_model_text = ''
  vehicle_style_text = ''
  vehicle_year = ''
  vehicle_make = ''
  vehicle_model = ''
  vehicle_sub_model = ''
  vehicle_style = ''
  vehicle_thumb_url = ''
  vehicle_image_url = ''
  vehicle_plate_number = ''
  vehicle_state = ''
  vehicle_ownership = ''
  glass_only_loss = ''
  cause_of_loss_code = ''
  subrogation_data = ''
  subrogation_contact_name = ''
  bodily_injury = ''
  origination_contact_person = ''
  reference_number = ''
  contact_number = ''
  error_code = ''
  error_text = ''
  filename = ''
  return_code = ''
  return_text = ''
  xml = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static submitLossNotice (glaxisClaimId, lossNotice, damageInformationArray, jobId, successCallbackFunction, errorCallbackFunction) {
    var data = {
      lossNotice: lossNotice,
      damageInformationArray: damageInformationArray,
      jobId: jobId
    }
    Api.post(`/api/glaxis-loss-notice/submit/${glaxisClaimId}`, data).then(res => {
      successCallbackFunction(res.data)
    }, err => {
      errorCallbackFunction(err)
    })
  }
}
