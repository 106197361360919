var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-divider", { staticClass: "t-my-6" }),
      _c(
        "div",
        { staticClass: "t-mb-4" },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.updateHideCompletedJobsHandler },
              model: {
                value: _vm.hide_completed_jobs,
                callback: function($$v) {
                  _vm.hide_completed_jobs = $$v
                },
                expression: "hide_completed_jobs"
              }
            },
            [_vm._v(" Hide completed jobs ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t-mb-4" },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.updateRequireClockInHandler },
              model: {
                value: _vm.require_clock_in,
                callback: function($$v) {
                  _vm.require_clock_in = $$v
                },
                expression: "require_clock_in"
              }
            },
            [_vm._v(" Require clock in ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t-mb-4" },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.updateRequireTechPickupHandler },
              model: {
                value: _vm.require_tech_pickup,
                callback: function($$v) {
                  _vm.require_tech_pickup = $$v
                },
                expression: "require_tech_pickup"
              }
            },
            [_vm._v(" Require tech pickup ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t-mb-4" },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.updateRequirePreInspectionHandler },
              model: {
                value: _vm.require_pre_inspection,
                callback: function($$v) {
                  _vm.require_pre_inspection = $$v
                },
                expression: "require_pre_inspection"
              }
            },
            [_vm._v(" Require inspection ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t-mb-4" },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.updateTechsideHidePricesHandler },
              model: {
                value: _vm.techside_hide_prices,
                callback: function($$v) {
                  _vm.techside_hide_prices = $$v
                },
                expression: "techside_hide_prices"
              }
            },
            [_vm._v(" Hide prices ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }