var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            lazy: "",
            title: "Job Not Found",
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg",
            id: "failedJobNavModal"
          }
        },
        [
          _c("div", { attrs: { fluid: "", id: "failedJobNavBody" } }, [
            _c("p", [_vm._v(_vm._s(_vm.message))])
          ]),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  attrs: { variant: "primary", size: "sm" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [_vm._v(" Ok ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }