<template>
  <el-popover
    placement="right"
    trigger="click"
    width="400"
    @show="init"
    v-model="visible"
  >
    <div v-loading="loading">
      <div class="t-my-3 t-text-xl">
        Invite Techside Technician
      </div>
      <el-form
        :model="inviteForm"
        ref="inviteForm"
        @submit.prevent.native
        :rules="{
          phoneId: {
            // required: true,
            validator: phoneSelectValidator,
            trigger: 'change'
          }
        }"
      >
        <div class="t-flex justify-content-center">
          <el-form-item
            prop="phoneId"
          >
            <el-select
              v-model="inviteForm.phoneId"
              filterable
              v-mask="'(###) ###-####'"
              placeholder="(999) 999-9999"
              allow-create
              
            >
              <el-option v-for="phone in data.phones" :value="phone.id" :label="phone.number">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          Include shops in technician connection
        </div>
        <el-checkbox-group
          v-model="inviteForm.shops"
        >
          <div v-for="shop in shops">
            <el-checkbox :label="shop.id">
              {{ shop.name }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
        <div class="t-mt-4">
          <el-button
            @click="sendTechsideInvite"
            class="t-float-right t-ml-2"
            :disabled="loading"
          >
            Send
          </el-button>
          <el-button
            @click="visible = false"
            class="t-float-right"
            :disabled="loading"
          >
            cancel
          </el-button>
        </div>
      </el-form>
    </div>
    <el-button slot="reference" size="mini" type="danger" plain>Terminated -</el-button>
  </el-popover>
</template>
  
<script>
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import { mapActions, mapState } from 'pinia'
import parsePhoneNumber from 'libphonenumber-js'

export default {
  data() {
    return {
      loading: false,
      inviteForm: {
        shops: [],
        phoneId: null,
        newPhone: ''
      },
      shops: [],
      visible: false,
      finalCheck: false,
    }
  },

  computed: {
    ...mapState(
      useVendorStore,
      [
        'data',
        'primaryPhone',
      ]
    ),
  },

  methods: {
    ...mapActions(useVendorStore, [
      'sendVendorTechsideConnectionInvitation',
      'createPhone',
      'setPrimaryPhone',
      'updateOverrideAllowedShops',
      'updateShops'
    ]),
    async sendTechsideInvite() {
      try {
        this.finalCheck = true
        this.$refs.inviteForm.validate(async valid => {
          if (valid) {
            this.loading = true
            await this.updateOverrideAllowedShops(this.inviteForm.shops?.length !== this.shops?.length)
            await this.updateShops(!!this.data.override_allowed_shops === false ? [] : this.inviteForm.shops)

            if (isNaN(this.inviteForm.phoneId) && isNaN(parseInt(this.inviteForm.phoneId))) {
              const phoneId = await this.createNewPhone(this.inviteForm.phoneId)
              await this.setPrimaryPhone(phoneId)
              this.inviteForm.phoneId = phoneId
            }

            try {
              await this.sendVendorTechsideConnectionInvitation(this.inviteForm.phoneId);
              this.$message.success("TechSIDE invitation has been sent successfully")
            } catch (error) {
              this.$message.error(error.message)
            }
            this.visible = false
            this.loading = false
          }
        })
        this.finalCheck = false
      } catch (e) {
        this.$message.error(e.message)
        this.loading = false
      }
    },

    async init() {
      if (this.data?.phone_id) {
        this.inviteForm.phoneId = this.data.phone_id
      }
      this.newPhone = ''
      const { data } = await this.$unum
        .job()
        .get(`shop/organization/${this.data.organization_id}/list`)
      this.shops = data
      this.inviteForm.shops = !!this.data.override_allowed_shops ?
        this.data.shop_ids :
        data.map(shop => shop.id)
    },

    phoneValidator(value) {
      const phone = parsePhoneNumber(value, 'US')
      if (!phone?.isValid()) {
        return false
      }
      return true
    },

    phoneSelectValidator(rule, value, callback) {
      if (!this.finalCheck) callback()

      if (!value) {
        callback(new Error('Invalid phone number'))
        return
      }
      if (!isNaN(parseInt(value)) &&
        !(typeof value === 'string' || value instanceof String)
      ) {
        callback()
        return
      }

      if (value.length === 0) {
        callback(new Error('Invalid phone number'))
        return
      }

      const phone = parsePhoneNumber(value, 'US')
      if (!phone.isValid()) {
        callback(new Error('Invalid phone number'))
      } else {
        callback()
      }
    },

    async createNewPhone(number) {
      try {
        const phoneId = await this.createPhone(number)
        await this.setPrimaryPhoneHandler(phoneId)
        return phoneId
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async setPrimaryPhoneHandler(id) {
      try {
        await this.setPrimaryPhone(id)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>
