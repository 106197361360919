<template>
  <div class="vendor-commissions-tab">
    <div>
      <div class="t-text-gray-400 t-mb-4">Replacement Commission</div>
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="commission.amount"
            type="number"
            @change="updateCommissionHandler"
          >
            <template #prepend>
              <div class="t-w-2">
                {{ commission.is_percent ? '%' : '$' }}
              </div>
            </template>

            <template #append>
              <el-select
                v-model="commission.is_percent"
                @change="updateCommissionHandler"
              >
                <el-option label="$" :value="0" />
                <el-option label="% Total" :value="1" />
                <el-option label="% Subtotal" :value="2" />
                <el-option label="% Margin" :value="3" />
              </el-select>
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" v-if="commission.is_percent === 3">
          <el-tooltip class="item" effect="dark" content="Margin calculated before all commissions" placement="right">
            <i class="material-icons-outline md-error_outline t-text-orange-500 t-mt-1" style="font-size: 32px"/>
          </el-tooltip>
        </el-col>
        <el-col :span="12" v-else>
        </el-col>
      </el-row>
    </div>

    <div v-if="isCSR || isSalesRep || isTech">
      <div class="t-text-gray-400 t-mt-6 t-mb-4">Repair Commission</div>
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="chip_commission.amount"
            type="number"
            @change="updateChipCommissionHandler"
          >
            <template #prepend>
              <div class="t-w-2">
                {{ chip_commission.is_percent ? '%' : '$' }}
              </div>
            </template>

            <template #append>
              <el-select
                v-model="chip_commission.is_percent"
                @change="updateChipCommissionHandler"
              >
                <el-option label="$" :value="0" />
                <el-option label="% Total" :value="1" />
                <el-option label="% Subtotal" :value="2" />
                <el-option label="% Margin" :value="3" />
              </el-select>
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" v-if="chip_commission.is_percent === 3">
          <el-tooltip class="item" effect="dark" content="Margin calculated before all commissions" placement="right">
            <i class="material-icons-outline md-error_outline t-text-orange-500 t-mt-1" style="font-size: 32px"/>
          </el-tooltip>
        </el-col>
        <el-col :span="12" v-else>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

// is_percent: 0 = false, 1 = total, 2 = subtotal, 3 = margin
const commission = {
  amount: 0,
  is_percent: 0
}

export default {
  data() {
    return {
      commission: { ...commission },
      chip_commission: { ...commission }
    }
  },

  computed: {
    ...mapState(useVendorStore, [
      'data',
      'isCSR',
      'isSaleSource',
      'isSalesRep',
      'isTech',
      'isLocation',
      'isSupplier'
    ])
  },

  created() {
    if (this.data.commission_id) {
      this.commission = this.data.commission
    }

    if (this.data.chip_commission_id) {
      this.chip_commission = this.data.chip_commission
    }
  },

  watch: {
    'commission.amount'(newVal) {
      if (newVal % 1 !== 0) {
        this.commission.amount = Math.round(newVal * 100) / 100
      }
    },

    'chip_commission.amount'(newVal) {
      if (newVal % 1 !== 0) {
        this.chip_commission.amount = Math.round(newVal * 100) / 100
      }
    }
  },

  methods: {
    ...mapActions(useVendorStore, ['updateCommission', 'updateChipCommission']),

    async updateCommissionHandler() {
      if (this.commission.amount === '') {
        this.commission.amount = 0
      }

      try {
        await this.updateCommission(this.commission)
        this.$root.$emit('vendor-commission-updated', this.data.id)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateChipCommissionHandler() {
      if (this.chip_commission.amount === '') {
        this.chip_commission.amount = 0
      }

      try {
        await this.updateChipCommission(this.chip_commission)
        this.$root.$emit('vendor-chip-commission-updated', this.data.id)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
.vendor-commissions-tab {
  .el-input-group {
    @apply t-w-80;

    .el-input-group__prepend {
      @apply t-bg-white t-rounded-l-lg t-w-12;
    }

    .el-input-group__append {
      @apply t-bg-white t-rounded-r-lg;

      .el-input {
        @apply t-w-20;
      }
    }
  }
}
</style>
