import Vue from 'vue'
import Unum from '@glassbiller/gb-unum'
import cfg from '@/config'

let instance

export const useUnum = () => {
  if (instance) return instance

  instance = new Vue({
    computed: {
      authenticated() {
        return this.$auth.isAuthenticated
      }
    },
    methods: {
      init() {}
    },
    watch: {
      authenticated: {
        immediate: true,
        async handler(authenticated) {
          if (authenticated && !this.unum) {
            this.unum = new Unum(false, this.$auth.getTokenSilently, `${cfg.uri}/unum/`)
            this.unum.init('consumer')
            this.consumer = this.unum.consumer
            this.unum.init('job-details')
            this.job = this.unum['job-details']
            this.unum.init('vendor')
            this.vendor = this.unum.vendor
            this.unum.init('charts')
            this.charts = this.unum.charts
            this.unum.init('catalogs')
            this.catalogs = this.unum.catalogs
            this.unum.init('scheduler')
            this.scheduler = this.unum.scheduler
            this.sch = this.unum.scheduler
            this.unum.init('support')
            this.support = this.unum.support
            this.unum.init('documents')
            this.documents = this.unum.documents
            this.unum.init('inventory')
            this.inventory = this.unum.inventory
            this.unum.init('warehouse')
            this.warehouse = this.unum.warehouse
            this.unum.init('consumer-confirmation')
            this.confirmation = this.unum['consumer-confirmation']
            this.unum.init('insurance')
            this.insurance = this.unum.insurance
          }
        }
      }
    }
  })

  return instance
}

export default {
  install(Vue, options) {
    Vue.prototype.$unum = useUnum()
  }
}
