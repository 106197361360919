<template>
  <gb-card
    mdi="insert_chart"
    title="Tickets"
    v-loading="loading"
    class="performance-chart"
  >
    <div class="performance-chart-title" v-if="dataSet.user.length">My User
      <chart :chart-data="dataSet.user" ref="chart" />
    </div>
    <div v-if="$role.can('account_manager') && dataSet.shops.length" class="performance-chart-title">All Shops
      <chart :chart-data="dataSet.shops" ref="chart" />
    </div>
  </gb-card>
</template>

<script>
import Chart from './Chart'

export default {
  props: ['data', 'loading'],
  components: { Chart },
  data() {
    return {
    }
  },
  computed: {
    dataSet() {
      return this.data
    }
  }
}
</script>

<style lang="scss" scoped>
.performance-chart {
  .performance-chart-title {
    font-size: $font-md;
    color: $grayscale-7;
    letter-spacing: 0.02em;
  }
}
</style>
