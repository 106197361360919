<template>
  <el-dialog
    center
    append-to-body
    :close-on-click-modal="false"
    title="Send Customer Payment Link"
    :visible.sync="visible"
    custom-class="text-message-payment-dialog"
    :width="activeTab === 'Email' ? '1200px' : '900px'"
  >
    <el-tabs tab-position="left" style="min-height: 500px;" v-model="activeTab">
      <el-tab-pane v-for="tab in options" :label="tab.label" :name="tab.label" :key="tab.label">
        <component 
          v-if="activeTab === tab.label"
          :is="tab.component"
          :is-active="activeTab === tab.label"
          :filePickerKey='filePickerKey'
          @sent="sentLink"
          @close="visible = false"
          />
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import History from './History.vue'
import Email from './Email.vue'
import Sms from './Sms.vue'
import Generate from './Generate.vue'
import { call, get, sync } from 'vuex-pathify'

export default {
  props: ['filePickerKey'],
  data: () => ({
    activeTab: null,
    options: [
      { label: "SMS", component: Sms },
      { label: "Email", component: Email },
      { label: "Generate", component: Generate },
      { label: "History", component: History }
    ],
  }),
  computed: {
    visible: sync('twilio/textMessages/jobPayment/visible'),
    jobId: get('twilio/textMessages/jobPayment/jobId'),
  },
  methods: {
    getJobPaymentHistory: call('twilio/textMessages/jobPayment/getHistory'),
    sentLink() {
      this.activeTab = 'History'
      this.getJobPaymentHistory(this.jobId)
    }
  },
  watch: {
    visible (v) {
      if (v) {
        this.getJobPaymentHistory(this.jobId)
        if (!this.activeTab) {
          this.activeTab = 'SMS'
        }
      }
    }
  }
}
</script>

<style>

</style>