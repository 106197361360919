var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-action-buttons" },
    [
      _c(
        "el-button",
        {
          attrs: {
            type: "text",
            icon: "el-icon-plus",
            disabled: _vm.newDisabled
          },
          on: {
            click: function($event) {
              return _vm.$emit("add")
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.newLabel) + " ")]
      ),
      _c("el-divider", { attrs: { direction: "vertical" } }),
      _c(
        "el-button",
        {
          staticClass: "select-delete-button",
          attrs: {
            type: "text",
            icon: "el-icon-delete",
            disabled: _vm.clearDisabled
          },
          on: {
            click: function($event) {
              return _vm.$emit("delete")
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.clearLabel) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }