<template>
  <div>
    <el-select
      filterable
      v-model="selected"
      :class="classes"
      :size="size"
      :multiple="multiple"
      :collapse-tags="collapseTags"
      class="select-wrapper"
      :value-key="byId ? null : valueKey || 'id'"
      :placeholder="label"
      @focus="fetchOptions"
      :loading="loading || isLoading"
      :clearable="clearable"
      @change="onChange"
      @clear="$emit('clear')"
      @blur="$emit('blur', $event)"
      ref="ref"
      :popper-class="withAdd ? 'select-dropdown-with-new' : ''"
      :disabled="disabled"
      :filter-method="filterMethod"
      :allow-create="allowCreate"
      :default-first-option="defaultFirstOption"
      :popper-append-to-body="!popperDontAppendToBody"
    >
      <div
        v-if="multiple && withSelectAll && items.length > 1"
        :class="{'select-all-options': true, 'all-selected': allSelected  }"
        @click="selectDeselectAllClicked"
      >
        <i class="material-icons md-check md-18" /> {{ allSelected ? 'Deselect All' : 'Select All' }}
      </div>
      <div
        class="show-disabled"
        v-if="withDisabled"
        @click="onDisabledClick"
        >
        {{showDisabled ? 'hide disabled' : 'show disabled'}} <i v-if='showDisabled' style='color: red;' class='el-icon-close'/>
      </div>
      <div
        class="add-new-item"
        :class="{ 'no-data': !items.length }"
        v-if="withAdd"
        @click="onAddClick"
        :slot="items.length ? null : 'empty'"
      >
        <i class="material-icons md-add md-18" /> Add new
      </div>
      <el-option
        v-for="item in items"
        :key="item.id"
        :label="item[labelKey || 'name']"
        :value="byId ? item[valueKey || 'id'] : item"
        :class="{ 'disabled-option': withDisabled && item.is_disabled }"
      >
        <slot name="option" :item="{...item, ...(withDisabled )}" v-if="$scopedSlots.option" />
      </el-option>
      <slot slot="prefix" name="prefix" />
    </el-select>
  </div>
</template>

<script>
import * as R from 'ramda'

export default {
  data() {
    return {
      loading: false,
      remoteOptions: null
    }
  },
  props: {
    value: [Object, String, Number, Array],
    options: { type: Array, default: () => [] },
    getOptions: { type: Function, default: () => () => [] },
    size: String,
    prop: String,
    query: Object,
    remote: Boolean,
    variables: Function,
    label: String,
    onChangeAction: String,
    byId: Boolean,
    emptyLabelKey: String,
    isLoading: Boolean,
    clearable: Boolean,
    labelKey: String,
    valueKey: String,
    withAdd: Boolean,
    disabled: Boolean,
    filterMethod: Function,
    multiple: Boolean,
    collapseTags: Boolean,
    withSelectAll: Boolean,
    allowCreate: Boolean,
    defaultFirstOption: Boolean,
    popperDontAppendToBody: Boolean,
    withDisabled: Boolean,
    showDisabled: Boolean,
    showLabelValue: Boolean
  },
  computed: {
    selectedRemote: {
      get() {
        if (this.value) {
          return (
            this.items.find(R.propEq('id', this.value.id)) || this.value.name
          )
        }
        return null
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    selected: {
      get() {
        try {
          if (this.multiple && this.value.length > 0) {
            this.fetchOptions({}) // prefetch options so multiple selected values populate their names properly
          }
        } catch (err) {}

        if (this.showLabelValue) {
          for (var i = 0; i < this.items.length; i++) {
            if (this.items[i][this.valueKey || 'id'] + '' === this.value + '') {
              return this.items[i][this.labelKey || 'name']
            }
          }
        }
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    allSelected() {
      return (this.value && this.value.length === this.items.length)
    },
    items() {
      return this.remote ? this.remoteOptions || this.options : this.options
    },
    classes() {
      return {
        ...(this.size ? { [this.size]: true } : {})
      }
    },
    hasOptions() {
      return this.options && this.options.length
    }
  },
  methods: {
    async fetchOptions(e) {
      this.$emit('focus', e)
      if (!this.remoteOptions) {
        this.loading = true
        this.remoteOptions = await this.getOptions()
        this.loading = false
      }
    },
    onChange(event) {
      this.$emit('change', event)
      if (this.byId) {
        const value = this.items.find(R.propEq('id', event))
        this.$emit('option-change', value)
      }
    },
    focus() {
      this.$refs.ref.focus()
    },
    onAddClick() {
      this.$refs.ref.blur()
      this.$emit('add')
    },
    onDisabledClick() {
      this.$emit('disabled')
    },
    selectDeselectAllClicked() {
      if (this.allSelected) {
        this.$emit('input', [])
      } else {
        var updatedValue = []
        for (var i = 0; i < this.items.length; i++) {
          updatedValue.push(this.items[i])
        }
        this.$emit('input', updatedValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./SelectWrapper.scss";
</style>

<style lang="scss">
.select-dropdown-with-new {
  min-height: 40px;
  overflow: hidden;
  ul {
    margin-bottom: 39px;
    padding-bottom: 0;
  }
  /* padding-bottom: 39px; */
  .show-disabled {
    position: relative;
    text-align: right;
    font-size: x-small;
    font-style: italic;
    padding: 0 10px 5px 0;
    cursor: pointer;
  }
  .disabled-option {
    ::after {
      color: red;
      content: ' (disabled)'
    }
  }
}
</style>
