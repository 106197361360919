var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-down" } }, [
    _vm.isOpen
      ? _c(
          "div",
          { staticClass: "scheduler-dialog" },
          [
            _c(
              "div",
              {
                staticClass: "close-scheduler-dialog",
                on: { click: _vm.closeModal }
              },
              [_c("v-icon", { attrs: { name: "times", scale: "1.1" } })],
              1
            ),
            _c("sheduler-component")
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }