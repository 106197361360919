<template>
  <InfoCard :schema="schema" :dataObject.sync="lossNotice" />
</template>

<script>
import InfoCard from '@/components/modals/GlaxisDialogs/claim/components/InfoCard'
import { applyMediaRules } from '@/scripts/helpers/media.helpers'
import { getConsumerName } from '@/scripts/helpers/consumer.helpers'

export default {
  components: { InfoCard },
  props: ['lossNotice', 'updateSchemaLabelsWithMatchingAndSubrogation', 'billTo', 'supportedInsurers', 'job', 'fieldsDisabled'],
  computed: {
    rowSpanOne() {
      return applyMediaRules({ lg: 24, md: 24, sm: 24, xs: 24 })
    },
    rowSpanTwo() {
      return applyMediaRules({ lg: 12, md: 12, sm: 12, xs: 12 })
    },
    rowSpanThree() {
      return applyMediaRules({ lg: 8, md: 8, sm: 12, xs: 12 })
    },
    rowSpanFour() {
      return applyMediaRules({ lg: 6, md: 6, sm: 12, xs: 12 })
    },
    schema() {
      var schema = {
        title: 'Insurance',
        icon: 'receipt',
        subTitleLabel: '*Insurance Company',
        subTitleType: 'select',
        subTitleId: 'insurance_company_id_code',
        subTitleTooltip: 'Used to Identify the Insurance Company and relevant claim processing business rules',
        subTitleOptions: this.insuranceSubTitleOptions,
        subTitleDisabled: this.fieldsDisabled,
        subTitleMatchText: getConsumerName(this.billTo),
        subTitleRequired: true,
        fields: [[
          {
            lossNoticeDefault: this.job?.policy_number,
            lossNoticeLimit: 20,
            label: '*Policy Number',
            redIfEmpty: true,
            tooltip: 'Send all characters, do not include spaces',
            id: 'insured_policy_number',
            editable: false,
            span: this.rowSpanTwo
          },
          {
            lossNoticeDefault: this.job?.loss_date,
            label: '*Date of Loss',
            redIfEmpty: true,
            id: 'date_of_loss',
            editable: false,
            span: this.rowSpanTwo
          }
        ]]
      }
      return this.updateSchemaLabelsWithMatchingAndSubrogation(schema)
    },
    insuranceSubTitleOptions() {
      var options = []
      for (var i = 0; i < this.supportedInsurers.length; i++) {
        options.push(
          { name: this.supportedInsurers[i].name, value: this.supportedInsurers[i].insurer_id + '' }
        )
      }
      return options
    }
  }
}
</script>

<style>

</style>
