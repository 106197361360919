<template>
  <div class="scheduler-map-tech-filters" :class="{ expanded: expanded }">
    <div
      class="mobile-expand"
      @click="
        () => {
          expanded = !expanded
        }
      "
    >
      <v-icon name="angle-up" :class="{ 'fa-rotate-180': expanded }" />
    </div>
    <div v-if="routeStates.length > 1">
      <h4>States:</h4>
      <div class="tech-state-filters">
        <el-button
          v-for="state in routeStates"
          :key="state"
          round
          :type="state === bounds ? 'primary' : ''"
          @click="setBounds(state)"
          :size="isMobile ? 'small' : 'default'"
          >{{ state }}</el-button
        >
      </div>
    </div>
    <h4>Technician routes:</h4>
    <el-checkbox
      class="tech-checkbox"
      v-for="route in routes"
      :key="route.id"
      @change="handleSelectionChange($event, route)"
      :value="!route.hidden && !route.error"
      :disabled="!!route.error"
      :style="{ color: route.color }"
    >
      <div class="tech-checkbox-label">
        {{ getName(route) }}
        <tech-location-indicator
          @click="onIndicatorClick"
          :username="route.techside_username"
          :color="route.color"
        />
      </div>
    </el-checkbox>
    <h4>Warehouses:</h4>
    <el-checkbox
      class="tech-checkbox"
      v-for="wh in warehouseList"
      :key="wh"
      :checked="warehouseFilter[wh]"
      :style="{ color: warehouseColors[wh] }"
      @change="handleWarehouseChange($event, wh)"
    >
      {{ wh.toUpperCase() }}
    </el-checkbox>
  </div>
</template>

<script>
import { shedulerColors, warehouseColors } from '@/constants/sheduler.configs'
import { isMobile } from '@/scripts/helpers/helpers'
import { get, sync } from 'vuex-pathify'
import * as R from 'ramda'
import { getRouteTechColor } from '@/scripts/helpers/scheduler_v3.helpers'
import TechLocationIndicator from '../helpers/TechLocationIndicator'

export default {
  components: { TechLocationIndicator },
  computed: {
    ...get('sch_v3', ['routeStates']),
    bounds: sync('sch_v3/bounds'),
    warehouseFilter: sync('sch_v3/filters@warehouses'),
    data: sync('sch_v3/data'),
    warehouseList() {
      return R.keys(this.warehouseFilter)
    },
    warehouseColors() {
      return warehouseColors
    },
    routes() {
      return R.pipe(
        R.values,
        R.filter(R.pipe(R.prop('route_entries'), R.length))
      )(this.data)
    }
  },
  data() {
    return {
      defaultColor: shedulerColors.default,
      isMobile: isMobile(),
      expanded: false
    }
  },
  methods: {
    handleSelectionChange(value, { id }) {
      this.data = R.over(R.lensProp(id), R.assoc('hidden', !value))(this.data)
    },
    getRouteTechColor(route) {
      return getRouteTechColor(route)
    },
    handleWarehouseChange(value, wh) {
      this.warehouseFilter = R.set(R.lensProp(wh), value)(this.warehouseFilter)
    },
    setBounds(state) {
      this.bounds = state
    },
    getName(route) {
      return R.cond([
        [R.path(['tech', 'name']), R.path(['tech', 'name'])],
        [R.prop('techs'), R.prop('techside_username')],
        [R.complement(R.prop('tech_id')), () => 'UNASSIGNED']
      ])(route)
    },
    onIndicatorClick({ latitude, longitude }) {
      this.$emit('center-map', { lat: latitude, lng: longitude })
    }
  }
}
</script>

<style lang="scss">
@import '../styles/SchedulerMapTechFilters';
</style>
