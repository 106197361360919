import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class AccountingPackage extends ObjectTracker {
  id = -1
  created = -1
  modified = -1
  account_id = -1
  name = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAccountingPackages (callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/accounting_packages`}).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getUserConnectedAccountingPackages (callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/accounting_packages/user`}).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  createUpdateAccountingPackage (selectedShops, selectedUsers, callbackFunction, errorCallbackFunction = null) {
    var data = {}
    data['accountingPackage'] = this
    data['selectedShops'] = []
    data['selectedUsers'] = []

    for (var i = 0; i < selectedShops.length; i++) {
      data['selectedShops'].push(selectedShops[i].id)
    }

    for (i = 0; i < selectedUsers.length; i++) {
      data['selectedUsers'].push(selectedUsers[i].id)
    }

    Api.post(`/api/accounting_package/${this.id}`, data).then(res => {
      callbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
