var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fetching,
          expression: "fetching"
        }
      ],
      ref: "table",
      attrs: {
        stripe: "",
        data: _vm.leads,
        "row-class-name": "t-cursor-pointer"
      },
      on: { "row-click": _vm.rowClicked }
    },
    [
      _c("el-table-column", {
        attrs: { type: "expand" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("div", { staticClass: "t-flex" }, [
                  _c(
                    "div",
                    { staticClass: "t-pr-3 t-font-bold t-w-32 t-text-right" },
                    [_vm._v(" Email: ")]
                  ),
                  _c("div", [_vm._v(_vm._s(scope.row.email))])
                ]),
                _c("div", { staticClass: "t-flex" }, [
                  _c(
                    "div",
                    { staticClass: "t-pr-3 t-font-bold t-w-32 t-text-right" },
                    [_vm._v(" Vin: ")]
                  ),
                  _c("div", [_vm._v(_vm._s(scope.row.vin))])
                ]),
                _c("div", { staticClass: "t-flex" }, [
                  _c(
                    "div",
                    { staticClass: "t-pr-3 t-font-bold t-w-32 t-text-right" },
                    [_vm._v(" Vehicle: ")]
                  ),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.year) +
                        " " +
                        _vm._s(scope.row.make) +
                        " " +
                        _vm._s(scope.row.model) +
                        " " +
                        _vm._s(scope.row.style) +
                        " "
                    )
                  ])
                ]),
                _c("div", { staticClass: "t-flex" }, [
                  _c(
                    "div",
                    { staticClass: "t-pr-3 t-font-bold t-w-32 t-text-right" },
                    [_vm._v(" Opening: ")]
                  ),
                  _c("div", [_vm._v(_vm._s(scope.row.opening))])
                ]),
                _c("div", { staticClass: "t-flex" }, [
                  _c(
                    "div",
                    { staticClass: "t-pr-3 t-font-bold t-w-32 t-text-right" },
                    [_vm._v(" Referral: ")]
                  ),
                  _c("div", [_vm._v(_vm._s(scope.row.referral))])
                ]),
                _c("div", { staticClass: "t-flex" }, [
                  _c(
                    "div",
                    { staticClass: "t-pr-3 t-font-bold t-w-32 t-text-right" },
                    [_vm._v(" Address: ")]
                  ),
                  _c("div", [_vm._v(_vm._s(scope.row.address))])
                ]),
                _c("div", { staticClass: "t-flex" }, [
                  _c(
                    "div",
                    { staticClass: "t-pr-3 t-font-bold t-w-32 t-text-right" },
                    [_vm._v(" Description: ")]
                  ),
                  _c("div", [_vm._v(_vm._s(scope.row.description))])
                ]),
                _c("div", { staticClass: "t-flex t-mt-2" }, [
                  _c("div", { staticClass: "t-w-32" }),
                  _c(
                    "div",
                    _vm._l(scope.row.attachments, function(i) {
                      return _c(
                        "el-button",
                        {
                          key: i.id,
                          attrs: { type: "primary", size: "mini", plain: "" },
                          on: {
                            click: function($event) {
                              return _vm.download(i.url)
                            }
                          }
                        },
                        [
                          _vm._v(" " + _vm._s(i.name)),
                          _c("i", {
                            staticClass: "el-icon-download el-icon-right t-ml-2"
                          })
                        ]
                      )
                    }),
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "", width: "40px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                scope.row.attachmentsCount
                  ? _c("i", { staticClass: "el-icon-paperclip" })
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Name" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(scope.row.firstName) +
                    " " +
                    _vm._s(scope.row.lastName) +
                    " "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Phone number", width: "150px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(" " + _vm._s(_vm.formatPhone(scope.row.phone)) + " ")
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Vehicle" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                scope.row.year > 0
                  ? _c("div", { staticClass: "t-break-normal" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.year) +
                          " " +
                          _vm._s(scope.row.make) +
                          " " +
                          _vm._s(scope.row.model) +
                          " " +
                          _vm._s(scope.row.style) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Created", width: "140px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  " " + _vm._s(_vm.formatCreated(scope.row.createdAt)) + " "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Job id", width: "100px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/jobs/" + scope.row.jobId,
                      target: "_blank"
                    }
                  },
                  [_vm._v(" " + _vm._s(scope.row.jobId) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { fixed: "right", label: "Actions", width: "200px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                !scope.row.jobId
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Start job",
                          placement: "top",
                          "open-delay": 300
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            loading: _vm.startJobSubmitting,
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-video-play"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleStartJob(scope.row.id)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                !scope.row.archived
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Archive",
                          placement: "top",
                          "open-delay": 300
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            loading: _vm.archiveSubmitting,
                            size: "mini",
                            type: "warning",
                            icon: "el-icon-takeaway-box"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleArchive(scope.row.id)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Unarchive",
                          placement: "top",
                          "open-delay": 300
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            loading: _vm.unarchiveSubmitting,
                            plain: "",
                            size: "mini",
                            type: "warning",
                            icon: "el-icon-takeaway-box"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleUnarchive(scope.row.id)
                            }
                          }
                        })
                      ],
                      1
                    ),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "Delete",
                      placement: "top",
                      "open-delay": 300
                    }
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        loading: _vm.deleteSubmitting,
                        size: "mini",
                        type: "danger",
                        icon: "el-icon-delete"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleDelete(scope.row.id)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }