var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vendor-notes-tab" },
    [
      _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [_vm._v("Notes")]),
      _c("el-input", {
        attrs: { type: "textarea", rows: 6, placeholder: "Click to add notes" },
        on: { change: _vm.updateNotesHandler },
        model: {
          value: _vm.notes,
          callback: function($$v) {
            _vm.notes = $$v
          },
          expression: "notes"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }