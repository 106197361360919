var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "consumer-action-buttons t-absolute t-space-x-5 lg:t-space-x-3 t-py-1\n    t-right-4 t-flex t-items-center t-bg-white t-rounded-full t-border\n    t-border-solid t-border-gray-300 t-px-3"
    },
    [
      _vm.isPrimary
        ? _c("span", { staticClass: "primary" }, [_vm._v("primary")])
        : _vm._e(),
      _vm.isSecondary
        ? _c("span", { staticClass: "secondary" }, [_vm._v("secondary")])
        : _vm._e(),
      _c(
        "el-popover",
        {
          staticClass: "primary-secondary-popover",
          attrs: { placement: "top", trigger: "hover", disabled: _vm.isPrimary }
        },
        [
          _c("div", [
            !_vm.isPrimary
              ? _c(
                  "div",
                  {
                    staticClass: "t-cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.$emit("setPrimary")
                      }
                    }
                  },
                  [_vm._v(" Set to primary ")]
                )
              : _vm._e(),
            !_vm.isPrimary && _vm.type === "phone"
              ? _c("div", [_c("br")])
              : _vm._e(),
            !_vm.isSecondary && !_vm.isPrimary && _vm.type === "phone"
              ? _c(
                  "div",
                  {
                    staticClass: "t-cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.$emit("setSecondary")
                      }
                    }
                  },
                  [_vm._v(" Set to secondary ")]
                )
              : _vm._e(),
            _vm.isSecondary
              ? _c(
                  "div",
                  {
                    staticClass: "t-cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.$emit("unsetSecondary")
                      }
                    }
                  },
                  [_vm._v(" Unset secondary ")]
                )
              : _vm._e()
          ]),
          _c("i", {
            staticClass:
              "el-icon-edit t-text-blue-500 t-cursor-pointer\n        t-transform t-duration-300 hover:t-scale-115",
            attrs: { slot: "reference" },
            slot: "reference"
          })
        ]
      ),
      _c("el-popover", { attrs: { placement: "top", trigger: "hover" } }, [
        _c("div", [_vm._v("Delete")]),
        _c("i", {
          staticClass:
            "el-icon-circle-close t-text-red-500 t-cursor-pointer\n        t-transform t-duration-300 hover:t-scale-115",
          attrs: { slot: "reference" },
          on: {
            click: function($event) {
              return _vm.$emit("clear")
            }
          },
          slot: "reference"
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }