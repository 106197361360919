<template>
  <el-table
    border
    size="mini"
    empty-text="No accounts are available"
    :data="credentials"
  >
    <el-table-column prop="name" label="Name" />
    <el-table-column prop="account_number" label="Account number" />
    <el-table-column prop="default" label="Is default">
      <template slot-scope="scope">
        <el-tag
          v-if="scope.row.default"
          type="success"
          size="mini"
          effect="dark"
        >
          Yes
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column label="Actions" width="175">
      <template slot-scope="scope">
        <div class="t-flex">
          <el-tooltip
            v-if="vendor !== 'american'"
            content="Edit"
            placement="top"
            :open-delay="300"
          >
            <el-button
              size="mini"
              icon="el-icon-edit"
              :disabled="disabled"
              @click="handleEdit(scope.row)"
            />
          </el-tooltip>
          <el-tooltip
            v-if="vendor !== 'american'"
            content="Delete"
            placement="top"
            :open-delay="300"
          >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              :disabled="disabled"
              @click="handleDelete(scope.row)"
            />
          </el-tooltip>
          <el-tooltip
            v-if="!scope.row.default"
            content="Set default"
            placement="top"
            :open-delay="300"
          >
            <el-button
              size="mini"
              type="success"
              plain
              icon="el-icon-check"
              :disabled="disabled"
              @click="setDefault(scope.row)"
            />
          </el-tooltip>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import Shop from '@/scripts/objects/shop'

export default {
  props: [
    'vendor',
    'credentials',
    'disabled'
  ],
  methods: {
    async refetchCredentials (shopId) {
      const credentials = await Shop.getVendorCredentials({ shopId })
      this.$emit('updateCredentials', credentials)
    },
    async handleDelete(item) {
      const self = this
      try {
        await self.$messageBox.confirm(
          `This will permanently delete account number ${item.account_number}. Do you wish to continue?`,
          'Warning',
          {
            beforeClose: async (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                await Shop.deleteVendorCredentials({ id: item.id })
                await self.refetchCredentials(item.shop_id)
                self.$emit('close')
                self.$message.success('Account number was removed')
                instance.confirmButtonLoading = false
              }
              done()
            }
          }
        )
      } catch {}
    },
    handleEdit (item) {
      this.$emit('edit', item)
    },
    async setDefault (item) {
      await Shop.setDefaultVendorCredentials({ id: item.id })
      await this.refetchCredentials(item.shop_id)
    }
  }
}
</script>
