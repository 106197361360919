var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.title !== "" ? _c("label", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      _c(
        "Multiselect",
        {
          attrs: {
            options: _vm.computedObjects,
            multiple: _vm.singleSelection ? false : true,
            "close-on-select": _vm.closeOnSelect ? _vm.closeOnSelect : false,
            "clear-on-select": false,
            "hide-selected": false,
            "preserve-search": true,
            placeholder: "",
            label: "name",
            limit: _vm.showAllSelected
              ? 99999
              : _vm.displayLimit && _vm.displayLimit > 0
              ? _vm.displayLimit
              : 4,
            "limit-text": _vm.maxString,
            "track-by": "id",
            "preselect-first": false,
            selectLabel: _vm.hideSelectDeselectLabels
              ? ""
              : _vm.selectLabel && _vm.selectLabel.length > 0
              ? _vm.selectLabel
              : "Press enter/click to select",
            deselectLabel: _vm.hideSelectDeselectLabels
              ? ""
              : _vm.deselectLabel && _vm.deselectLabel.length > 0
              ? _vm.deselectLabel
              : "Press enter/click to deselect",
            selectedLabel: _vm.hideSelectDeselectLabels
              ? ""
              : _vm.selectedLabel && _vm.selectedLabel.length > 0
              ? _vm.selectedLabel
              : "Selected",
            showLabels: !_vm.hideSelectDeselectLabels,
            clearOnSelect: _vm.clearOnSelect,
            openDirection:
              _vm.openDirection != null && _vm.openDirection.length > 0
                ? _vm.openDirection
                : ""
          },
          on: {
            select: _vm.optionSelected,
            close: _vm.closeHandler,
            open: _vm.openHandler
          },
          scopedSlots: _vm._u(
            [
              {
                key: "option",
                fn: function(props) {
                  return _vm.$scopedSlots["optionSlot"] != null
                    ? [_vm._t("optionSlot", null, { props: props })]
                    : undefined
                }
              },
              {
                key: "tag",
                fn: function(props) {
                  return _vm.$scopedSlots["tagSlot"] != null
                    ? [_vm._t("tagSlot", null, { props: props })]
                    : undefined
                }
              }
            ],
            null,
            true
          ),
          model: {
            value: _vm.values,
            callback: function($$v) {
              _vm.values = $$v
            },
            expression: "values"
          }
        },
        [
          _vm.clickToShowMore
            ? _c("template", { slot: "limit" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.showAllSelected = !_vm.showAllSelected
                      }
                    }
                  },
                  [
                    _c("strong", { staticClass: "multiselect__strong" }, [
                      _vm._v(
                        _vm._s(_vm.values.length) +
                          " of " +
                          _vm._s(_vm.objects.length) +
                          " selected"
                      )
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm.noResultText && _vm.noResultText.length > 0
            ? _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v(_vm._s(_vm.noResultText))
              ])
            : _vm._e()
        ],
        2
      ),
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.clickToShowMore &&
                _vm.showAllSelected &&
                _vm.values.length >
                  (_vm.displayLimit && _vm.displayLimit > 0
                    ? _vm.displayLimit
                    : 4),
              expression:
                "clickToShowMore && showAllSelected && values.length > (displayLimit && displayLimit > 0 ? displayLimit : 4)"
            }
          ],
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function($event) {
              _vm.showAllSelected = !_vm.showAllSelected
            }
          }
        },
        [
          _c("strong", { staticClass: "multiselect__strong" }, [
            _vm._v("Show Less")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }