import { render, staticRenderFns } from "./SchedulerShopIcon.vue?vue&type=template&id=18981b23&"
import script from "./SchedulerShopIcon.vue?vue&type=script&lang=js&"
export * from "./SchedulerShopIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18981b23')) {
      api.createRecord('18981b23', component.options)
    } else {
      api.reload('18981b23', component.options)
    }
    module.hot.accept("./SchedulerShopIcon.vue?vue&type=template&id=18981b23&", function () {
      api.rerender('18981b23', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SchedulerV3/helpers/map-icons/SchedulerShopIcon.vue"
export default component.exports