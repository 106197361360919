<template>
  <el-dialog
    width="500px"
    :visible.sync="localVisible"
    title="New Device"
    append-to-body
    @closed="$emit('closed')"
    @close="reset"
  >
    <div v-if="code">
      <div class="t-break-normal">
        Please sign out of the device, then select "Sign in" on the device, then select "Use a device code." Enter the code below to sign in.
      </div>
      <div class="t-text-3xl t-text-center t-pt-3">{{code}}</div>
      <div class="t-break-normal" style="margin-top:15px;">
        Once you've signed in with this code, your device has been created in your Square account. Next, we need to connect it to Glassbiller. To do that, close this dialog, select your newly created device from the dropdown on the left, click "Add", and follow the instructions provided.
      </div>
    </div>
    <el-form
      v-else
      v-loading="loading"
      :model="newDeviceCodeForm"
      :rules="rules" size="small"
      ref="newDeviceCodeForm"
    >
      <el-row>
        <div class="t-break-normal">
          First, we need to create this device in your Square account. To do that, please enter a name for your new device and click "Create."
        </div>
      </el-row>
      <el-row>
        <el-form-item prop="name" style="margin-top:15px;">
          <el-input v-model="newDeviceCodeForm.name" placeholder="Enter a name for your new device"></el-input>
        </el-form-item>
      </el-row>
      <el-row style="text-align:center;">
        <el-form-item>
          <el-button type="primary" @click="createNewDeviceCode">Create</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import Shop from '@/scripts/objects/shop'

export default {
  props: ['locationId', 'shopId', 'visible'],
  data: () => ({
    code: null,
    loading: false,
    newDeviceCodeForm: {
      name: ''
    },
    rules: {
      name: [
        { required: true, message: 'Please provide a device name', trigger: 'blur' }
      ]
    }
  }),
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    async createNewDeviceCode () {
      this.loading = true
      const { data } = await Shop.createDeviceCode(
        this.shopId,
        {
          name: this.newDeviceCodeForm.name,
          locationId: this.locationId
        }
      )

      if (data.status === 'success') {
        this.code = data.code
      } else {
        this.$message.error(data.error)
      }

      this.loading = false
    },
    reset () {
      this.code = null
      this.newDeviceCodeForm.name = ''
    }
  }
}
</script>
