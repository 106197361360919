<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    @open="dialogAboutToAppear"
    @close="dialogDisappeared"
    class="jobDetailsClaimDialog"
    append-to-body
    id="glaxisClaim"
    width="1400px"
    destroy-on-close>
    <div style="font-size: 18px; font-weight: bold; margin-bottom:10px;">
      <el-row class="claim-page-row top-cards-row" :gutter="16" type="flex">
        <el-col style="white-space:pre-wrap;word-break:break-word;">
          {{`Review Coverage Response`}}
        </el-col>
      </el-row>
    </div>
    <div v-if="loading" class="row">
      <div style="font-size: 16px; text-align: center;" class="col-md-12">
        <p><img style="width:18px;height:18px;margin-right:6px;" src='../../../../assets/img/spinner.gif'> Loading please wait...</p>
      </div>
    </div>
    <div v-if="foundClaimData === null" class="col-md-12 font-size-14 claimDialogArea">
      No Data Found
    </div>
    <div v-else class="col-md-12 font-size-14 claimDialogArea">
      <div >
        <div class='form-row'>
          <div class='form-group col-md-4'>
            <label>Reference Number</label><br>
            {{ foundClaimData.coverageResponseData.coverageResponse.reference_number }}
          </div>
          <div class='form-group col-md-8'>
            <label>Details</label><br>
            {{ foundClaimData.coverageResponseData.coverageResponse.error_text }}
          </div>
        </div>
        <br>
        <p>Vehicle Information</p>
        <div v-for='(vehicleInformation, index) in foundClaimData.coverageResponseData.coverageResponseVehicleInformation' :key='index'>
          <div class="vehicleInformationBorder">
            <div class='form-row'>
              <div class='form-group col-md-4'>
                <label>Vehicle Selection</label><br>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" :value="index" v-model="selectedVehicle"> Use This Vehicle With Loss Acknowledgement
                </div>
              </div>
              <div class='form-group col-md-4'>
                <label>Description</label><br>
                {{vehicleInformation.vehicle_description}}
              </div>
              <div class='form-group col-md-4'>
                <label>VIN (Last 4)</label><br>
                ****{{vehicleInformation.vin_number}}
              </div>
            </div>
            <br>
            <div class='form-row'>
              <div class='form-group col-md-4'>
                <label>Id Number</label><br>
                {{vehicleInformation.vehicle_id_number}}
              </div>
              <div class='form-group col-md-4'>
                <label>Id Source</label><br>
                {{vehicleInformation.vehicle_id_source}}
              </div>
              <div class='form-group col-md-4'>
                <label>Vehicle Line Number</label><br>
                {{vehicleInformation.vehicle_line_number}}
              </div>
            </div>
            <br>
            <div class='form-row'>
              <div class='form-group col-md-4'>
                <label>Deductible</label><br>
                {{vehicleInformation.deductible}}
              </div>
              <div class='form-group col-md-8'>
                <label>Coverage Details</label><br>
                {{vehicleInformation.vehicle_error_text}}
              </div>
            </div>
          </div>
          <br>
        </div>
        <p><strong>Loss Notice Acknowledgement</strong></p>
        <div class='form-row'>
          <div class='form-group col-md-4'>
            <label><strong>Accept Work</strong> <el-tooltip popper-class="reviewCoverageToolTip" :content="'Review the coverage and deductible information with the policyholder. If they agrees to the terms of the work, confirm acceptance of the work with this option. An electronic work assignment will follow from the insurance company or claims manager.'"><i class="el-icon-info t-text-xs"/></el-tooltip></label><br>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" value="yes" v-model="acceptWork"/> Yes
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" value="no" v-model="acceptWork"/> No
              </label>
            </div>
          </div>
          <div v-if="acceptWork === 'yes'" class='form-group col-md-4'>
            <label>Acceptance Contact <el-tooltip popper-class="reviewCoverageToolTip" :content="'Used by the insurance company or claims manager to track whom at the retail location accepted the work.'"><i class="el-icon-info t-text-xs"/></el-tooltip></label><br>
            <input class="form-control form-control-sm" type="text" v-model="acceptanceContact"/>
          </div>
          <div v-if="acceptWork === 'yes'" class='form-group col-md-4'>
            <label><strong>Mobile Indicator</strong></label><br>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" value="yes" v-model="mobileIndicator"/> Yes
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" value="no" v-model="mobileIndicator"/> No
              </label>
            </div>
          </div>
        </div>
        <br>
        <div v-if="acceptWork === 'yes'" class='form-row'>
          <div class='form-group col-md-4'>
            <label><strong>Work Location Postal Code</strong> <el-tooltip popper-class="reviewCoverageToolTip" :content="'This data must be manually entered by the user for both in-shop and mobile jobs. By requiring the user to manually enter the zip code where the work is going to be performed, they are physically certifying the zip code that they intend to do the work.'"><i class="el-icon-info t-text-xs"/></el-tooltip></label><br>
            <input class="form-control form-control-sm" type="text" v-model="workLocationPostalCode"/>
          </div>
          <div class="form-group col-md-4">
            <el-row>
              <el-col :span="24">
                <label for="installTimeStart">Appointment Start Time</label>
                <select class='form-control form-control-sm timeSelect' v-model='requestedAppointmentTime'>
                  <option v-for='(time, index) in startTimeOptions' :key='index' :value="time.value" :class='time.style'>{{time.name}}</option>
                </select>
              </el-col>
            </el-row>
          </div>
          <div class='form-group col-md-4'>
            <label for="installDate">Appointment Date</label>
            <DatePickerShortcut :value.sync='requestedAppointmentDate' :displayFormat='"MM/dd/yyyy"' :saveFormat='"yyyy-MM-dd"' autoSetToday:true/>
          </div>
        </div>
      </div>
        <div v-if="!loading" class="t-py-3 t-flex">
          <div v-if="submittingLossAcknowledgement"><img style="width: 20px;" src='../../../../assets/img/spinner.gif' /> Submitting Please Wait...</div>
          <el-button v-if="foundClaimData !== null && !submittingLossAcknowledgement" type="primary" @click="submitLossAcknowledgement" class="submitLossAcknowledgementButton" >
            Submit Loss Acknowledgement
          </el-button>
        </div>
    </div>
      <el-dialog
        :visible.sync='confirmPopup'
        :appendToBody="true"
        >
        <el-row class='dialog-header-text'>
            Submit Loss Notice Acknowledgement?
        </el-row>
        <br/>
        <el-row class='dialog-body-text'>Are you sure you would like to submit this Loss Notice Acknowledgement?</el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="finishSubmitLossNoticeAcknowledgement()">Yes</el-button>
          <el-button @click="confirmPopup = false">No</el-button>
        </span>
      </el-dialog>
      <el-dialog
        @close="closeAlertPopupEvent"
        :visible.sync='alertPopup'
       :appendToBody="true"
        >
        <el-row class='dialog-body-text'>{{ alertPopupText }}</el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="alertPopup = false">Ok</el-button>
        </span>
      </el-dialog>
    </el-dialog>
</template>

<script>
import GlaxisClaim from '@/scripts/objects/glaxis_claim'
import { TimePickerObjects } from '@/scripts/helpers/helpers.js'
import DatePickerShortcut from '@/components/Helpers/DatePickerShortcut.vue'
import GlaxisLossAcknowledgement from '@/scripts/objects/glaxis_loss_acknowledgement'
import { sync } from 'vuex-pathify'

export default {
  data () {
    return {
      loading: false,
      submittingLossAcknowledgement: false,
      foundClaimData: null,
      startTimeOptions: JSON.parse(JSON.stringify(TimePickerObjects)),
      acceptWork: '',
      acceptanceContact: '',
      mobileIndicator: '',
      workLocationPostalCode: '',
      requestedAppointmentDate: '',
      requestedAppointmentTime: '',
      selectedVehicle: -1,
      confirmPopup: false,
      alertPopup: false,
      alertPopupText: '',
      closePopupOnAlertClose: false
    }
  },
  methods: {
    closeAlertPopupEvent() {
      if (this.closePopupOnAlertClose) {
        this.visible = false
      }
    },
    submitLossAcknowledgement() {
      if (this.submittingLossAcknowledgement) {
        return
      }
      if (this.selectedVehicle < 0) {
        this.alertPopupText = 'Please select a vehicle.'
        this.alertPopup = true
        return
      }
      if (this.acceptWork !== 'yes' && this.acceptWork !== 'no') {
        this.alertPopupText = 'Please mark whether or not the work was accepted.'
        this.alertPopup = true
        return
      }
      if (this.acceptWork === 'yes') {
        if (this.acceptanceContact.length === 0) {
          this.alertPopupText = 'Please enter in an Acceptance Contact.'
          this.alertPopup = true
          return
        }
        if (this.mobileIndicator.length === 0) {
          this.alertPopupText = 'Please choose an option for Mobile Indicator.'
          this.alertPopup = true
          return
        }
        if (this.workLocationPostalCode.length === 0) {
          this.alertPopupText = 'Please enter in a Work Location Postal Code.'
          this.alertPopup = true
          return
        }
        if (this.requestedAppointmentDate.length === 0) {
          this.alertPopupText = 'Please enter in a Requested Appointment Date.'
          this.alertPopup = true
          return
        }
        if (this.requestedAppointmentTime.length === 0) {
          this.alertPopupText = 'Please enter in a Requested Appointment Time.'
          this.alertPopup = true
          return
        }
      }
      this.confirmPopup = true
    },
    finishSubmitLossNoticeAcknowledgement() {
      if (this.submittingLossAcknowledgement) {
        return
      }
      this.confirmPopup = false
      var lossAcknowledgementData = {
        acceptWork: this.acceptWork,
        acceptanceContact: this.acceptanceContact,
        mobileIndicator: this.mobileIndicator,
        workLocationPostalCode: this.workLocationPostalCode,
        requestedAppointmentDate: this.requestedAppointmentDate,
        requestedAppointmentTime: this.requestedAppointmentTime,
        vehicleIdNumber: this.foundClaimData.coverageResponseData.coverageResponseVehicleInformation[this.selectedVehicle].vehicle_id_number,
        vehicleIdSource: this.foundClaimData.coverageResponseData.coverageResponseVehicleInformation[this.selectedVehicle].vehicle_id_source,
        vehicleLineNumber: this.foundClaimData.coverageResponseData.coverageResponseVehicleInformation[this.selectedVehicle].vehicle_line_number
      }
      const self = this
      self.submittingLossAcknowledgement = true
      GlaxisLossAcknowledgement.submitLossAcknowledgement(this.claimId, lossAcknowledgementData, (response) => {
        self.submittingLossAcknowledgement = false
        if (response.result === 'success') {
          self.alertPopupText = 'The loss acknowledgement has been submitted. Please check back later for a response.'
          self.alertPopup = true
          self.closePopupOnAlertClose = true
          self.$root.$emit('refreshGlaxisClaim')
          self.$root.$emit('refreshGlaxisDialogTable')
        } else {
          alert(response.message)
        }
      }, (error) => {
        console.log('error: ', error)
        self.alertPopupText = 'Something went wrong. Please verify your data and try again.'
        self.alertPopup = true
        self.submittingLossAcknowledgement = false
      })
    },
    dialogDisappeared () {
      this.closePopupOnAlertClose = false
      this.$claimJobDetails.jobId = null
    },
    dialogAboutToAppear () {
      this.acceptWork = ''
      this.acceptanceContact = ''
      this.mobileIndicator = ''
      this.workLocationPostalCode = ''
      this.requestedAppointmentDate = this.job.install_date ? this.job.install_date : ''
      this.requestedAppointmentTime = this.job.scheduled_time_start ? this.job.scheduled_time_start : ''

      this.selectedVehicle = -1
      if (this.claimId > 0) {
        this.loading = true
        const self = this
        GlaxisClaim.getClaim(self.claimId,
          function (results) {
            self.loading = false
            if (results.coverageResponseData && results.coverageResponseData.coverageResponse) {
              self.foundClaimData = results
              if (self.foundClaimData.coverageResponseData.coverageResponseVehicleInformation &&
              self.foundClaimData.coverageResponseData.coverageResponseVehicleInformation.length === 1) {
                self.selectedVehicle = 0
              }
            } else {
              self.alertPopupText = 'Something went wrong loading this claim.'
              self.alertPopup = true
              self.closePopupOnAlertClose = true
            }
          }, function (error) {
            console.log('got error: ', error)
            self.alertPopupText = 'Something went wrong loading this claim.'
            self.alertPopup = true
            self.loading = false
            self.closePopupOnAlertClose = true
          }
        )
      } else {
        self.alertPopupText = 'Something went wrong loading this claim.'
        self.alertPopup = true
        this.loading = false
        self.closePopupOnAlertClose = true
      }
    }
  },
  computed: {
    visible: sync('glaxis/dialogs@reviewCoverage'),
    claimId: sync('glaxis/reviewCoverage@claimId'),
    syncJobId: sync('glaxis/claim@jobId'),
    hasJobMixin() {
      return this.$job && this.$job.job && this.$job.job.id === this.syncJobId
    },
    job() {
      if (this.hasJobMixin) {
        return this.$job.job
      } else {
        return this?.$claimJobDetails?.job
      }
    }
  },
  components: {
    DatePickerShortcut
  }
}
</script>

<style>
.claimDialogArea > .form-row {
  margin-bottom: 5px;
}

.vehicleInformationBorder {
  border-radius: 25px;
  border: 2px solid #299fd7;
  padding: 20px;
}

.reviewCoverageToolTip {
  width: 200px;
}
</style>
