import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default class Salesrep extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  name = ''
  commission = 0
  address = ''
  city = ''
  state = ''
  zip = ''
  phone = ''
  fax = ''
  email = ''
  notes = ''
  modified = -1
  shop_id = -1
  saleside_username = ''
  saleside_invitation_status = ''
  location_id = -1
  commission_flat_or_percent = 'flat'
  location = -1
  hide_sent_jobs = -1
  chip_commission = 0
  chip_commission_flat_or_percent = 'flat'

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, callbackFunction) {

    Api({ url: `/api/shop/${shopId}/salesrep` }).then(res => {

      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new Salesrep(val))
      })

      callbackFunction(objects)
    })
  }

  static async getTextMessageInviteHistory (salesrepId, limit = 0) {
    const { data } = await Api.get(`/api/salesrep/${salesrepId}/textMessageInviteHistory?limit=${limit}`)
    return data
  }

  static async getOne (salesrepId) {
    const { data } = await Api(`/api/salesrep/${salesrepId}`)
    if (!data) {
      return null
    }
    return new Salesrep(data)
  }

  // GET ALL SALES REPS BY USER + FILTERS
  static getSalesRepsByUserShops (page, limit, sortBy, sortDesc, salesrepIdFilter, salesrepNameFilter,
    salesrepShopNameFilter, salesrepEmailFilter, salesrepPhoneFilter, successCallback, errorCallback) {
    Api({url: `/api/usersalesreps?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&salesrepIdFilter=' + encodeURIComponent(salesrepIdFilter) + '&salesrepNameFilter=' + encodeURIComponent(salesrepNameFilter) +
      '&salesrepShopNameFilter=' + encodeURIComponent(salesrepShopNameFilter) + '&salesrepEmailFilter=' + encodeURIComponent(salesrepEmailFilter) +
      '&salesrepPhoneFilter=' + encodeURIComponent(salesrepPhoneFilter)
    }).then(res => {

      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  createUpdateSalesrepAndItemsToSkip (shopId, commercialAccountsToSkip, locationsToSkip, salesourcesToSkip, callbackFunction, errorCallbackFunction = null) {
    this.commission = UtilityMixin.methods.formatFloatDecimals(this.commission, 2, 2)
    this.chip_commission = UtilityMixin.methods.formatFloatDecimals(this.chip_commission, 2, 2)

    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }

    var salesrepDetails = {}
    salesrepDetails['salesrep'] = this
    salesrepDetails['salesrepCommercialaccountsToSkip'] = commercialAccountsToSkip
    salesrepDetails['salesrepLocationsToSkip'] = locationsToSkip
    salesrepDetails['salesrepSalesourcesToSkip'] = salesourcesToSkip

    return Api.post(`/api/shop/${this.shop_id}/salesrep/${this.id}`, salesrepDetails).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
