<template>
  <div>
    <div class="t-mb-4 t-flex t-px-12">
      <div class="t-text-base t-flex-grow">
        <span class="t-mr-2" :class="{ 't-italic': !name }">
          {{ name || 'No name' }} 
          <span class='consumer-tags t-mr-2'>
            <gb-tag :small="true" disable v-if="data.consumer_type === 'insurance'" color='blue'> Ins </gb-tag>
            <gb-tag :small="true" disable v-else-if="data.consumer_type === 'commercial'" color='orange'> Com </gb-tag>
            <gb-tag :small="true" disable v-else-if="data.consumer_type === 'cash'" color="green"> Cash </gb-tag>
          </span>
          <span class="t-text-xs t-text-gray-600">
            {{ data.invoices.length }} invoice{{ data.invoices.length > 1 ? 's' : '' }}
          </span>
        </span>
      </div>
      <div v-if="groupBy === 'age'">
        <span class="t-text-sm t-mr-2">Total:</span>
        ${{ data.balance }}
      </div>
    </div>
    <Table
      :invoices="data.invoices"
      :shopId="shopId"
      :expandPayments="expandPayments"
    />
    <div v-if="groupBy === 'age'" class="t-text-right t-mt-4 t-px-12">
      <span class="t-text-sm t-mr-2">Total:</span>
      ${{ data.balance }}
    </div>
    <div class="t-px-12 t-mt-6">
      <OutstandingBalances
        v-if="groupBy === 'consumer'"
        :groupBy="groupBy"
        :totalBalance="data.balance"
        :ninetyPlusBalance="data.ninety_plus_balance"
        :sixtyNinetyBalance="data.sixty_ninety_balance"
        :thirtySixtyBalance="data.thirty_sixty_balance"
        :zeroThirtyBalance="data.zero_thirty_balance"
      />
    </div>
    <el-divider/>
  </div>
</template>

<script>
import Table from './Table.vue'
import OutstandingBalances from './OutstandingBalances.vue'

export default {
  components: {
    Table,
    OutstandingBalances
  },
  props: {
    data: Object,
    name: String,
    shopId: Number,
    groupBy: String,
    expandPayments: Boolean
  }
}
</script>

<style lang="scss" scoped>
.consumer-tags {
  overflow: hidden;
  > .gb-tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline
  }
}

</style>
