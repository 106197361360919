<template>
  <div>
    <el-dialog
      :visible="visible"
      @open="dialogAppeared"
      :before-close="dialogDisappeared"
      append-to-body
      custom-class="t-font-sans chat-dialog saleside-job-dialog md:t-w-full"
      >
      <loader v-if="loading" />
      <span slot="title" class="t-text-blue-100 t-text-lg">
        Mobile Job Data
        <span
          v-if="salesidejob"
          :style="{'background-color': salesidejob.job_id ? '#337ab7' : salesidejob.sent ? '#33b747' : '#b73333'}"
          class="saleside-job-status-tag"
        >
          <a
            v-if="salesidejob.job_id"
            :href="'/jobs/' + salesidejob.job_id"
            target='_blank'
            class="saleside-job-status-tag-link"
          >Job #{{salesidejob.job_id}}</a>
          <span v-else-if="salesidejob.sent">Sent</span>
          <span v-else>Unsent</span>
        </span>
      </span>
      <div class="t-pt-6 t-pl-4">
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Mobile #:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.id}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Sales Rep (username):</div>
          <div class="t-text-base t-pl-2">{{salesidejob.salesrep_name}} <span v-if='salesidejob.saleside_username'>({{salesidejob.saleside_username}})</span></div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Customer:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.customer_first_name}} {{salesidejob.customer_last_name}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Customer Phone:</div> 
          <div class="t-text-base t-pl-2">{{salesidejob.customer_phone1}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Customer Email:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.customer_email}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Customer Address:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.customer_address}}, {{salesidejob.customer_address_city}}, {{salesidejob.customer_address_state}} {{salesidejob.customer_address_zip}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Contact:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.contact_name}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Contact Phone:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.contact_phone}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Contact Address:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.contact_address}}, {{salesidejob.contact_address_city}}, {{salesidejob.contact_address_state}} {{salesidejob.contact_address_zip}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Vehicle:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.vehicle_year}} {{salesidejob.vehicle_make_readable}} {{salesidejob.vehicle_model_readable}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Vin:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.vehicle_vin}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Repair:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.repair_type}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Insurance:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.insurance}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Apt Date:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.scheduled_date}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Apt Time:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.scheduled_time_start}} - {{salesidejob.scheduled_time_end}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Loss Date:</div>
          <div class="t-text-base t-pl-2">{{formatToReportDate(salesidejob.loss_date)}}</div>
        </div>
        <div class="t-flex t-py-2">
          <div class="t-text-base t-font-bold t-text-gray-500">Notes:</div>
          <div class="t-text-base t-pl-2">{{salesidejob.install_notes}}</div>
        </div>
      </div>
      <div slot="footer" class="w-100">
        <div class='float-left t-flex t-space-x-2'>
          <div v-for='(document, index) in salesidejob.documents' class="document-tag" :key="document.id">
            <md-label-tag icon="insert_photo" color="#005AA3" class="t-cursor-pointer" @click.native="openPreview(document)">
              SSMPic {{ index + 1 }}
            </md-label-tag>
          </div>
        </div>
        <div>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="dialogDisappeared()"
          >
            Close
          </b-button>
          <img v-show="busy" style="width:20px; height:20px" src='./../../assets/img/spinner.gif'>
          <a v-show="!busy && salesidejob.saleside_job_status !== 'archived' && !salesidejob.job_id && sent" @click="startButtonPressed(salesidejob)" href="javascript:void(0)" class="label label-gray label-sm t-mr-1">Start</a>
          <a v-show="!busy && salesidejob.saleside_job_status !== 'archived' && !salesidejob.job_id && sent" @click="kickbackJobPressed(salesidejob)" href="javascript:void(0)" class="label label-gray label-sm t-mr-1">Kick Back</a>
          <a v-show="!busy && salesidejob.saleside_job_status !== 'archived' && sent" @click="archiveButtonPressed(salesidejob)" href="javascript:void(0)" class="label label-gray label-sm t-mr-2">Archive</a>
          <a v-show="!busy && salesidejob.saleside_job_status === 'archived' && sent" @click="unarchiveButtonPressed(salesidejob)" href="javascript:void(0)" class="label label-gray label-sm t-mr-2">Unarchive</a>
          <span v-if="!busy && !loading && !sent" class="t-pr-2 t-italic">*Viewing unsent saleside job</span>
        </div>
      </div>
    </el-dialog>
    <file-preview-dialog v-model="previewDialog" :doc="preview" :no-delete="true"/>
  </div>
</template>

<script>
import Salesidejob from "@/scripts/objects/salesidejob";
import { UtilityMixin } from "@/scripts/helpers/utilities";
import { formatToReportDate } from "@/scripts/helpers/date.helpers";
import FilePreviewDialog from '@/components/modals/FilePreviewDialog/Index'
import LegacyApi from "@/services/LegacyApi";
import Loader from "../Helpers/Loader";
import AuditLog from "@/scripts/objects/audit_log.js";

export default {
  data() {
    return {
      salesidejob: {},
      loading: false,
      busy: false,
      previewDialog: false,
      preview: null
    };
  },
  props: {
    mobileJobId: null,
    title: "",
    shouldOpenMobileInbox: false,
    dialogId: "",
    visible: false,
  },
  mounted() {},
  computed: {
    currentDialogId() {
      return this.dialogId && this.dialogId.length > 0
        ? this.dialogId
        : "mobileJobDialog";
    },
    sent() {
      return this.salesidejob?.sent === 1
    }
  },
  methods: {
    dialogAboutToAppear: function () {
      // cannot access props with this hook
    },
    dialogAppeared: function () {
      var self = this;
      // can access props with this hook
      // this.loading = true
      if (self.mobileJobId) {
        Salesidejob.getSalesidejobById(self.mobileJobId, function (res) {
          // trimming seconds of schedule times
          let timeArrStart = res.scheduled_time_start
            ? res.scheduled_time_start.split(":")
            : [];
          let timeArrEnd = res.scheduled_time_end
            ? res.scheduled_time_end.split(":")
            : [];
          let startTime =
            timeArrStart.length > 0
              ? timeArrStart[0] + ":" + timeArrStart[1]
              : "";
          let endTime =
            timeArrEnd.length > 0 ? timeArrEnd[0] + ":" + timeArrEnd[1] : "";
          self.salesidejob = res;
          self.salesidejob.scheduled_time_start = startTime;
          self.salesidejob.scheduled_time_end = endTime;
        });
      } else {
        console.log("Error: did not receive a valid mobile job id");
      }
    },
    dialogDisappeared: function () {
      this.$emit("close");
      if (this.shouldOpenMobileInbox) {
        this.$root.$emit("bv::show::modal", "mobileInboxModal");
      }
    },
    closeDialog: function () {
      this.localVisible = false;
      // this.$root.$emit('bv::hide::modal', this.currentDialogId)
    },
    startButtonPressed: async function (salesideJob) {
      let self = this;
      if (!confirm("Are you sure you want to start this SaleSIDE job?")) {
        return;
      }
      self.busy = true;
      try {
        const formdata = new FormData();
        formdata.set("start", true);
        // const { data } = await LegacyApi.post('/mobile/saleSIDE/' + salesideJob.id, formdata)
        var { data } = await this.$unum
          .job()
          .post(`saleside/startjob/${salesideJob.id}`, {
            ...salesideJob,
            mobileJobNumber: salesideJob.id,
            selectedStartStatus: "workOrder",
          });
        if (data.result === "success") {
          var swoResponse = await this.$unum
            .job()
            .post(`/docs/${data.jobId}/ssign`);
        }
        // self.$store.dispatch('updateUserSalesideCount')
        // self.$refs.mobileInboxTable.refresh()
        if (
          typeof data === "object" &&
          data !== null &&
          typeof data.error === "string" &&
          data.error.length > 0
        ) {
          alert("Failed to start saleside job: " + data.error);
          // self.startingJobs.splice(self.startingJobs.indexOf(salesideJob.id), 1)
          self.busy = false;
          return;
        }
        self.salesidejob.saleside_job_status = "archived";
        AuditLog.createAuditLog(data.jobId, "SSJOBSTARTED");
        self.changeRouteWithCheck("/jobs/" + data.jobId);
        this.localVisible = false;
        // this.$root.$emit('bv::hide::modal', this.currentDialogId)
        // self.$root.$emit('bv::hide::modal', 'mobileInboxModal')
        self.busy = false;
      } catch (error) {
        alert("Failed to start saleside job." + error);
        // self.startingJobs.splice(self.startingJobs.indexOf(item.mobileJobNumber), 1)
        // self.$refs.mobileInboxTable.refresh()
        self.busy = false;
        self.$store.dispatch("updateUserSalesideCount");
      }
    },
    kickbackJobPressed: function (salesideJob) {
      let self = this;
      self.busy = true;
      if (
        confirm(
          "Are you sure you want to kick this back to the Sales Rep? They will need to send the job from Salesidemobile to get this job in the mobile inbox again."
        )
      ) {
        Salesidejob.kickbackSalesideJob(
          salesideJob.shop_id,
          salesideJob.id,
          function (results) {
            if (results === true) {
              // self.startingJobs.splice(self.startingJobs.indexOf(item.mobileJobNumber), 1)
              // self.$refs.mobileInboxTable.refresh()
              self.salesidejob.sent = null;
            }
            self.busy = false;
            self.$root.$emit(
              "showGlobalNotification",
              "Success",
              "The job has been kicked back to the Sales Rep"
            );
            self.$store.dispatch("updateUserSalesideCount");
          }
        );
      }
    },
    archiveButtonPressed: function (salesideJob) {
      let self = this;
      self.busy = true;
      Salesidejob.archiveSalesidejob(
        salesideJob.shop_id,
        salesideJob.id,
        function (results) {
          if (results === true) {
            self.salesidejob.saleside_job_status = "archived";
            // if (!self.showArchive) {
            //   self.$refs.mobileInboxTable.localItems.splice(index, 1)
            // }
            self.$root.$emit(
              "showGlobalNotification",
              "Success",
              "The job has been archived"
            );
          }
          self.busy = false;
          self.$store.dispatch("updateUserSalesideCount");
        }
      );
    },
    unarchiveButtonPressed: function (salesideJob) {
      let self = this;
      self.busy = true;
      Salesidejob.unarchiveSalesidejob(
        salesideJob.shop_id,
        salesideJob.id,
        function (results) {
          if (results === true) {
            self.salesidejob.saleside_job_status = null;
            self.$root.$emit(
              "showGlobalNotification",
              "Success",
              "The job has been unarchived"
            );
          }
          self.busy = false;
          self.$store.dispatch("updateUserSalesideCount");
        }
      );
    },
    formatToReportDate(date) {
      if (date === "0000-00-00") return "";
      else return formatToReportDate(date);
    },
    openPreview(doc) {
      console.log(doc)
      this.preview = { ...doc, document_type: 'ssmpic' }
      this.previewDialog = true
    }
  },
  components: {
    Loader,
    FilePreviewDialog
  },
  mixins: [UtilityMixin],
};
</script>

<style lang="scss">
  .saleside-job-dialog.el-dialog {
    width: 100% !important;
    @media only screen and (min-width: 600px) {
      width: 50% !important;
    }
  }
  .saleside-job-dialog .saleside-job-status-tag {
    border-radius: 10px;
    padding: 0px 10px;
    margin-left: 10px;

    .saleside-job-status-tag-link {
      color: white;
      text-decoration: none;
    }
  }
</style>
