<template>
  <div class="t-flex">
    <div class="t-flex-grow">
      <div class="t-text-gray-400 t-mt-2">Phone Number</div>

      <el-form
        :model="form"
        ref="form"
        class="t-space-y-6"
        @submit.prevent.native
      >
        <div
          v-for="(i, index) in form.phones"
          :key="index"
          class="t-flex t-items-center t-space-x-3 first:t-mt-6"
        >
          <el-form-item
            :prop="'phones.' + index + '.number'"
            :rules="{
              validator: phoneValidator,
              trigger: 'blur'
            }"
          >
            <el-input
              v-model="i.number"
              v-mask="'(###) ###-####'"
              placeholder="(999) 999-9999"
              @change="setPhone(index, $event)"
            />
          </el-form-item>

          <el-tooltip content="Remove Phone Number" placement="top">
            <i
              class="material-icons-outline md-cancel t-text-base t-text-red-400 t-cursor-pointer"
              @click="deletePhoneHandler(index)"
            />
          </el-tooltip>

          <el-tooltip
            v-if="i.id"
            :content="
              i.id === data.phone_id
                ? 'Primary Phone'
                : 'Set as Primary Phone'
            "
            placement="top"
          >
            <i
              :class="
                i.id === data.phone_id
                  ? 'md-radio_button_checked t-text-blue-500'
                  : 'md-radio_button_unchecked t-text-gray-400'
              "
              class="material-icons-outline t-text-base t-cursor-pointer"
              @click="setPrimaryPhoneHandler(i.id)"
            />

          </el-tooltip>
        </div>
      </el-form>
    </div>

    <div class="t-flex t-flex-col t-justify-end">
      <el-button
        plain
        icon="el-icon-circle-plus-outline"
        @click="addPhone"
      >
        Add
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'pinia'
import parsePhoneNumber from 'libphonenumber-js'
import cloneDeep from 'lodash.clonedeep'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  data() {
    return {
      form: {
        phones: []
      }
    }
  },

  computed: {
    ...mapState(useVendorStore, [
      'data',
    ])
  },

  mounted() {
    this.form.phones = cloneDeep(this.data.phones)
    this.$nextTick(async () => {
      try {
        await this.$refs.form.validate()
      } catch {
      }
    })
  },

  methods: {
    ...mapActions(
      useVendorStore,
      ['createPhone', 'updatePhone', 'deletePhone', 'setPrimaryPhone']
    ),

    phoneValidator(rule, value, callback) {
      const phone = parsePhoneNumber(value, 'US')
      if (!phone.isValid()) {
        callback(new Error('Invalid phone number'))
      } else {
        callback()
      }
    },

    addPhone() {
      this.form.phones.push({
        id: null,
        number: ''
      })
    },

    setPhone(phoneIndex, number) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const phone = this.form.phones[phoneIndex]
          try {
            if (phone.id) {
              await this.updatePhone(phone.id, number)
            } else {
              const phoneId = await this.createPhone(number)

              this.form.phones[phoneIndex].id = phoneId

              if (this.form.phones.length === 1) {
                await this.setPrimaryPhoneHandler(phoneId)
              }
            }
          } catch (e) {
            this.$message.error(e.message)
          }
        }
      })
    },

    async deletePhoneHandler(phoneIndex) {
      const phone = this.form.phones[phoneIndex]
      if (phone.id) {
        try {
          await this.deletePhone(phone.id)
          Vue.delete(this.form.phones, phoneIndex)
        } catch (e) {
          this.$message.error(e.message)
        }
      }

    },

    async setPrimaryPhoneHandler(id) {
      try {
        await this.setPrimaryPhone(id)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

  },
  
  watch: {
    "data.phones"() {
      this.form.phones = cloneDeep(this.data.phones)
    }
  }
}
</script>
