import * as R from 'ramda'

const PREFIX = 'confirmationsSettings/'

const actions = {
  // get settings for all shops currently selected in scheduler
  setShopsSettings: 'setShopsSettings',
  syncShopsTimers: 'syncShopsTimers',
  syncJobPageTimer: 'syncJobPageTimer',
  syncSchedulerJobsTimers: 'syncSchedulerJobsTimers',
  setJobsConfirmationDateTime: 'setJobsConfirmationDateTime'
}

const mutations = {
  setShopsSettings: 'setShopsSettings',
  setShopsTimers: 'setShopsTimers',
  setJobPageTimer: 'setJobPageTimer',
  setSchedulerJobsTimers: 'setSchedulerJobsTimers'
}

const getters = {
  shopsSettings: 'shopsSettings',
  shopsTimers: 'shopsTimers',
  jobPageTimer: 'jobPageTimer',
  schedulerJobsTimers: 'schedulerJobsTimers'
}

const globalActions = R.map(R.concat(PREFIX))(actions)
const globalMutations = R.map(R.concat(PREFIX))(mutations)

export {
  actions,
  getters,
  mutations,
  globalActions,
  globalMutations
}
