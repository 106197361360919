import { types } from './activity.types'
import store from '@/store/store'
import { techActivityEndpoint } from '@/constants/endpoints'
import Api from '@/services/Api'

const actions = {
  async [types.getShopTechs]({ state }) {
    const { shops } = state.filters
    if (shops.length) {
      const { data } = await Api.get('/api/reporting/shop_data/techs', {
        params: { shops }
      })
      store.set('activity/shopTechs', data)
    }
  },
  async [types.getTechActivity]({ state }) {
    const { date, tech } = state.filters
    store.set('activity/data', [])
    store.set('activity/user', null)
    if (tech) {
      const { data } = await Api.get(techActivityEndpoint, {
        params: { date, tech }
      })
      const { userActivities, path, ...user } = data
      store.set('activity/data', userActivities)
      store.set('activity/user', user)
      store.set('activity/path', path)
    }
  }
}

export default actions
