<template>
  <el-dialog
    :visible.sync='localVisible'
    width='1200px'
    :modalAppendToBody="false"
    append-to-body
    custom-class="consumer-jobs-dialog"
    @open="open"
    @close="close">
    <span slot="title" class="t-text-xl t-text-gray-800">Drafts</span>
    <el-table
      v-if="showTable"
      v-infinite-scroll="handleScroll"
      :infinite-scroll-disabled="done"
      :infinite-scroll-distance="0"
      :data="drafts"
      class="drafts-table"
      v-loading="loading"
      @selection-change="handleSelectionChange"
      >
      <el-table-column
        v-if="canDiscard"
        type="selection">
      </el-table-column>
      <el-table-column
        prop="id"
        label="Id"
        width="100">
        <template slot-scope="scope">
          <a href="javascript:void(0)" style="text-decoration: none;" @click="openDraft(scope.row.id)">{{ scope.row.id }}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="Status"
        width="100">
        <template slot-scope="scope">
          <span>{{ getReadableStatus(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="consumer"
        label="Customer"
        width="200">
        <template slot-scope="scope">
          <span>{{ getConsumerName(scope.row.consumer) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="vehicle"
        label="Vehicle">
        <template slot-scope="scope">
          <span>{{ getVehicleString(scope.row.vehicle) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="referral_number"
        label="Referral #"
        width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.referral_number }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="purchase_order_number"
        label="PO #"
        width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.purchase_order_number }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button
        v-if="canDiscard && multipleSelection.length"
        type="danger"
        style="float:left"
        @click="discardSelectedDrafts">
          Discard
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
import * as R from 'ramda'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    drafts: [],
    multipleSelection: [],
    done: false,
    showTable: false
  }),
  methods: {
    async handleScroll () {
      const { data } = await this.$unum.job().get(`draft/unpublished`, {
        params: { offset: this.drafts.length }
      })
      this.drafts = [...this.drafts, ...data]
      if (data.length < 25) {
        this.done = true
      }
    },
    getConsumerName (consumer) {
      if (consumer === null) return ''
      if (consumer?.is_commercial === 1) {
        return consumer.company_name ? consumer.company_name : consumer.full_name
      } else if (consumer.full_name) {
        return consumer.full_name
      } else if (consumer.name) {
        return consumer.name.last ? consumer.name.first + ' ' + consumer.name.last : consumer.name.first
      } else {
        return 'Guest'
      }
    },
    getVehicleString (vehicle) {
      return !vehicle ? '' : vehicle.generated_name
    },
    openDraft (id) {
      this.changeRouteWithCheck('/jobs/new/' + id)
      this.localVisible = false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async discardSelectedDrafts () {
      try {
        await this.$messageBox.confirm(
          `This will permanently delete ${this.multipleSelection.length} draft(s). Continue?`,
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
        // delete selected drafts
        const deleteRes = await this.$unum.job().delete('/draft/batch', {
          params: { drafts: this.multipleSelection.map(R.prop('id')) }
        })
        // refresh table
        this.loading = true
        const { data } = await this.$unum.job().get(`draft/unpublished`, {
          params: { offset: 0 }
        })
        this.multipleSelection = []
        this.drafts = data
        this.loading = false
      } catch {}
    },
    open () {
      this.showTable = true
    },
    close () {
      this.loading = false,
      this.drafts = [],
      this.multipleSelection = []
      this.done = false
      this.showTable = false
    },
    getReadableStatus (status) {
      const statusConfig = {
        draft: 'Draft',
        quote: 'Quote',
        workOrder: 'Work Order',
        invoice: 'Invoice'
      }
      return statusConfig[status]
    }
  },
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    canDiscard () {
      return this.$role.can('discarddraft')
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped lang="scss">
  .center {
    text-align: center;
  }
  .commercial-customer {
    color: white;
    background-color: #F0AD4E;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .individual-customer {
    color: white;
    background-color: #54A955;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .consumer-jobs-dialog {
    max-width: 100vw;
  }
  .dialog-footer {
    height: 50px !important;
  }
  .drafts-table {
    overflow:auto;
    height: 500px;
    width: 100%;
    &::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(117, 117, 117);
    }
  }
</style>