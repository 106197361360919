var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "doc-drop-zone",
          rawName: "v-doc-drop-zone:vendor.popover",
          value: {
            id: _vm.localVendor.id,
            shopId: _vm.shop ? _vm.shop.id : null
          },
          expression: "{id: localVendor.id, shopId: shop ? shop.id : null}",
          arg: "vendor",
          modifiers: { popover: true }
        }
      ],
      class: { vendorEditWrapper: true, documentDragDrop: _vm.amDragging },
      on: { filedrop: _vm.documentDrop }
    },
    [
      !_vm.isInModal
        ? _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-12 text-right" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-sm btn-primary",
                  staticStyle: {
                    "margin-left": "5px",
                    "margin-bottom": "5px",
                    float: "right"
                  },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.cancelVendorEditPressed()
                    }
                  }
                },
                [_vm._v("CLOSE")]
              ),
              !_vm.localVendor.cleanCheck ||
              (_vm.localVendor && _vm.localVendor.id === -1)
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      staticStyle: {
                        "margin-left": "5px",
                        "margin-bottom": "5px",
                        float: "right"
                      },
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.saveVendorEditPressed()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.localVendor.id > 0 ? "UPDATE" : "SAVE"))]
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _c("br"),
      _vm.localVendor.id < 0
        ? _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "small", icon: "el-icon-search" },
                  on: { click: _vm.showNationalVendorSearch }
                },
                [_vm._v(" Search National Database ")]
              ),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("span", { staticClass: "t-text-gray-600" }, [
                  _vm._v("or enter information manually")
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "form-row" }, [
        _vm.localVendor.national_vendor_id
          ? _c("div", { staticClass: "form-group col-md-1" }, [
              _c(
                "label",
                { attrs: { for: "vendorNVID" } },
                [
                  _vm._v("NVID "),
                  _c("v-icon", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover",
                        value:
                          "ID of National Warehouse this Supplier was Created From",
                        expression:
                          '"ID of National Warehouse this Supplier was Created From"',
                        modifiers: { hover: true }
                      }
                    ],
                    attrs: { name: "info-circle", scale: ".8" }
                  })
                ],
                1
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localVendor.national_vendor_id,
                    expression: "localVendor.national_vendor_id"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "vendorNVID", disabled: "" },
                domProps: { value: _vm.localVendor.national_vendor_id },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.localVendor,
                      "national_vendor_id",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group col-md-5" }, [
          _c("label", { attrs: { for: "vendorName" } }, [_vm._v("Name")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.localVendor.name,
                expression: "localVendor.name"
              }
            ],
            staticClass: "form-control form-control-sm autocomplete-off",
            attrs: { id: "vendorName" },
            domProps: { value: _vm.localVendor.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.localVendor, "name", $event.target.value)
              }
            }
          })
        ]),
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c("label", { attrs: { for: "vendorShortCode" } }, [
            _vm._v("Short Code")
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.localVendor.short_code,
                expression: "localVendor.short_code"
              }
            ],
            staticClass:
              "form-control capitalize form-control-sm autocomplete-off",
            attrs: { id: "vendorShortCode" },
            domProps: { value: _vm.localVendor.short_code },
            on: {
              keydown: _vm.shortCodeKeyDown,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.localVendor, "short_code", $event.target.value)
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-group col-md-4" }, [
          _c("label", [_vm._v("Status")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.localVendor.status,
                  expression: "localVendor.status"
                }
              ],
              staticClass: "form-control form-control-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.localVendor,
                    "status",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "active" } }, [_vm._v("Active")]),
              _c("option", { attrs: { value: "disabled" } }, [
                _vm._v("Disabled")
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "form-group col-md-8" }, [
          _c("label", { attrs: { for: "vendorContact" } }, [_vm._v("Contact")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.localVendor.contact,
                expression: "localVendor.contact"
              }
            ],
            staticClass: "form-control form-control-sm autocomplete-off",
            attrs: { id: "vendorContact" },
            domProps: { value: _vm.localVendor.contact },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.localVendor, "contact", $event.target.value)
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "form-group col-md-4" },
          [
            _c("Phone", {
              ref: "phoneComponent",
              attrs: {
                title: "Phone",
                value: _vm.localVendor.phone,
                placeholder: "",
                required: false
              },
              on: {
                "update:value": function($event) {
                  return _vm.$set(_vm.localVendor, "phone", $event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-group col-md-4" },
          [
            _c("Phone", {
              ref: "faxComponent",
              attrs: {
                title: "Fax",
                value: _vm.localVendor.fax,
                placeholder: "",
                required: false
              },
              on: {
                "update:value": function($event) {
                  return _vm.$set(_vm.localVendor, "fax", $event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-group col-md-4" },
          [
            _c("Email", {
              ref: "emailComponent",
              attrs: {
                title: "Email",
                value: _vm.localVendor.email,
                placeholder: "",
                required: false
              },
              on: {
                "update:value": function($event) {
                  return _vm.$set(_vm.localVendor, "email", $event)
                }
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "form-group col-md-12" },
          [
            _c("label", { attrs: { for: "vendorAddress" } }, [
              _vm._v("Address")
            ]),
            _c("address-input", {
              attrs: {
                id: "vendorAddress",
                "with-po-box": "",
                "popper-fix": ""
              },
              model: {
                value: _vm.localVendor.g_address,
                callback: function($$v) {
                  _vm.$set(_vm.localVendor, "g_address", $$v)
                },
                expression: "localVendor.g_address"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "form-group col-md-12" },
          [
            _c("label", [_vm._v("Notes")]),
            _c("b-form-textarea", {
              attrs: { placeholder: "Enter Supplier Notes...", rows: "5" },
              model: {
                value: _vm.localVendor.notes,
                callback: function($$v) {
                  _vm.$set(_vm.localVendor, "notes", $$v)
                },
                expression: "localVendor.notes"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "form-group col-md-6" },
          [
            _c("label", [_vm._v("Supplier Color")]),
            _c("br"),
            _c("Sketch", {
              model: {
                value: _vm.localVendor.color,
                callback: function($$v) {
                  _vm.$set(_vm.localVendor, "color", $$v)
                },
                expression: "localVendor.color"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "form-row" }, [
        _vm.localVendor.id > 0
          ? _c(
              "div",
              { staticClass: "form-group col-md-12" },
              [
                _c("label", [
                  _vm._v("Documents "),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "doc-button",
                          rawName: "v-doc-button",
                          value: {
                            type: _vm.vendorType,
                            id: _vm.localVendor.id,
                            shopId: _vm.shop ? _vm.shop.id : null
                          },
                          expression:
                            "{type: vendorType, id: localVendor.id, shopId: shop ? shop.id : null}"
                        }
                      ],
                      attrs: { href: "javascript:void(0)" }
                    },
                    [_vm._v("Add New +")]
                  )
                ]),
                _c("DocumentWellContent", {
                  attrs: { type: _vm.vendorType, documents: _vm.documents }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      !_vm.isInModal
        ? _c("VendorSearchDialog", {
            on: { selectClicked: _vm.selectedNationalVendor }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }