<template>
  <div v-bind:style='groupWrapperStyleObject' v-bind:class='groupWrapperClassObject'>
    <label v-if='title' for='title'>{{title}}</label>
    <input
      ref='input'
      maxlength='14'
      :readonly='readonly'
      type='text'
      v-bind:class='classes'
      :placeholder='placeholder'
      :title='title'
      @blur='exitingInput'
      @focus='inputActive = true'
      v-model='formatted'
      :disabled='inDisabled'
      v-bind:style='groupInputStyleObject' />
      <div v-if="successMessage && successMessage.length > 0" class="valid-feedback">
        {{ successMessage }}
      </div>
      <div v-if="internalFailMessage && internalFailMessage.length > 0" class="invalid-feedback">
        {{ internalFailMessage }}
      </div>
  </div>
</template>

<script>
export default {
  props: ['placeholder', 'title', 'value', 'failMessage', 'successMessage', 'required', 'inDisabled', 'inClass', 'isGroupInput', 'isSaveNotFormatted'],
  data: () => {
    return {
      showFailureMessage: false,
      inputActive: false,
      readonly: true
    }
  },
  mounted () {
    var self = this
    this.$nextTick(() => { self.readonly = false })
  },
  methods: {
    exitingInput: function () {
      this.inputActive = false
      this.$emit('inputBlur')
    },
    updateShowFailureMessage: function (activateShowFailureMessage) {
      this.showFailureMessage = activateShowFailureMessage
    },
    isValid: function () {
      
      if (this.value) {
        let checkValue = parseInt(('' + this.value).replace(/[^\d.]/g, ''))
        if (isNaN(checkValue)) {
          checkValue = 0
        }
        return (checkValue.toString().length === 10 || (!this.required && ('' + this.value).length === 0))
      } else {
        
        return !this.required
      }
    },
    focus: function () {
      this.$refs.input.focus()
    }
  },
  computed: {
    internalFailMessage: function () {
      if (this.isValid() || !this.showFailureMessage) {
        return ''
      } else if (this.failMessage) {
        return this.failMessage
      } else {
        if (this.required && (!this.value || this.value.length === 0)) {
          return '\'' + this.title + '\' is required.'
        } else {
          return 'This field can only contain digits and must contain an area code'
        }
      }
    },
    classes: function () {
      var prop = ['form-control', 'autocomplete-off']
      if (!this.isGroupInput) {
        prop.push('form-control-sm')
      }
      if (this.inClass && this.inClass.length > 0) {
        var classes = this.inClass.split(',')
        for (var i = 0; i < classes.length; i++) {
          if (classes[i] && classes[i].trim().length > 0) {
            prop.push(classes[i].trim())
          }
        }
      }
      if (this.required || (this.value && this.value.length > 0 && (!this.isValid() || this.inputActive || this.showFailureMessage))) {
        prop.push(this.isValid() ? 'is-valid' : 'is-invalid')
      }
      return prop
    },
    formatted: {
      get: function () {
        
        if (this.value) {
          // this is if the user is in the input field... just return the non formatted
          if (this.inputActive) {
            let temp = ('' + this.value).replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})$/)
            
            temp = (!temp[2]) ? temp[1] : '(' + temp[1] + ') ' + temp[2] + (temp[3] ? '-' + temp[3] : '')
            if (temp.length === 0 && this.value.length > 0) {
              this.$emit('update:value', '')
            }
            return temp
          } else { // this is if the user is not in the input field and it should be formatted
            if (this.value.toString().length > 0) {
              let temp = ('' + this.value).replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})$/)
              
              return (!temp[2]) ? temp[1] : '(' + temp[1] + ') ' + temp[2] + (temp[3] ? '-' + temp[3] : '')
            }
          }
        } else {
          return ''
        }
      },
      set: function (changedVal) {
        var tempPhone = changedVal
        if (this.isSaveNotFormatted) {
          tempPhone = tempPhone.replace(/\D+/g, '')
        }
        this.$emit('update:value', tempPhone)
      }
    },
    groupWrapperStyleObject: function () {
      var styles = {}
      if (this.isGroupInput) {
        styles = {
          'padding': '2px',
          'margin': '0px'
        }
        if (this.inDisabled) {
          styles['background-color'] = '#e9ecef'
        }
      }
      return styles
    },
    groupWrapperClassObject: function () {
      var classes = []
      if (this.isGroupInput) {
        classes = ['form-control', 'form-control-sm']
        if (!this.isValid() && !this.inDisabled) {
          classes.push('is-invalid')
        } else if (this.inputActive) {
          classes.push('is-valid')
        }
      }
      return classes
    },
    groupInputStyleObject: function () {
      var styles = {}
      if (this.isGroupInput) {
        styles = {
          'padding-left': '4px',
          'padding-right': '4px',
          'padding-top': '0px',
          'padding-bottom': '0px',
          'margin': '0px',
          'border': 'none',
          'font-family': 'Lato, sans-serif',
          'font-size': '14px',
          'line-height': '21px'
        }
      }
      if (this.textAlign && this.textAlign.length > 0) {
        styles['text-align'] = this.textAlign
      }
      return styles
    }
  }
}
</script>

<style>

</style>
