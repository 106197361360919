<template>
  <el-dialog
    append-to-body
    title="Warning"
    :visible.sync="showDialog"
    width="30%"
    :before-close="handleClose">
      <div class='mb-3'>
        <el-col :span='2'>
          <i class="el-icon-warning warning-icon" />
        </el-col>
        <el-col :span='22'>
          <span>You are navigating away from an unpublished draft. Do you want to continue?</span>
        </el-col>
      </div>
      <span slot="footer" class="dialog-footer">
        <a v-if="canDiscard" href="javascript:void(0)" class="discard-option mt-2" @click="$emit('discardDraft')">Discard</a>
        <el-button @click="$emit('leaveDraft')">Leave as Draft</el-button>
        <el-button type="primary" @click="$emit('publishDraft')">Publish now</el-button>
      </span>
  </el-dialog>
</template>

<script>
  export default {
    props: ['visible'],
    data() {
      return {
        dialogVisible: false
      };
    },
    computed: {
      showDialog: {
        get() {
          return this.visible
        },
        set(v) {
          this.$emit('update:visible', v)
        }
      },
      canDiscard () {
        return this.$role.can('discarddraft')
      }
    },
    methods: {
      handleClose(done) {
        console.log('handleClose')
        done();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .warning-icon {
    color: #E6A23C;
    font-size: 24px !important;
    margin-right: 10px;
  }

  .discard-option {
    float: left;
  }
</style>
