var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-w-1/4 t-px-3 t-pb-6" }, [
    _c(
      "div",
      {
        staticClass:
          "t-flex t-flex-col t-items-stretch t-border t-border-solid t-border-gray-200 t-p-4\n      t-rounded-md t-shadow t-bg-white t-h-full"
      },
      [
        _c("div", { staticClass: "t-flex t-space-x-2 t-mb-2" }, [
          _c("div", { staticClass: "t-w-1/3 t-text-gray-400 t-text-right" }, [
            _vm._v("Part")
          ]),
          _c("div", [_vm._v(_vm._s(_vm.number))])
        ]),
        _c(
          "div",
          { staticClass: "t-flex t-space-x-2 t-mb-2" },
          [
            _c("div", { staticClass: "t-w-1/3 t-text-gray-400 t-text-right" }, [
              _vm._v("Status")
            ]),
            _c(
              "el-tag",
              {
                staticClass: "t-capitalize",
                attrs: { type: _vm.tagType, size: "mini" }
              },
              [_vm._v(_vm._s(_vm.type))]
            )
          ],
          1
        ),
        _c("div", { staticClass: "t-flex t-space-x-2 t-mb-2" }, [
          _c("div", { staticClass: "t-w-1/3 t-text-gray-400 t-text-right" }, [
            _vm._v("Quantity")
          ]),
          _c("div", [_vm._v(_vm._s(_vm.total) + " unit(s)")])
        ]),
        _c("div", { staticClass: "t-flex t-space-x-2 t-mb-2" }, [
          _c("div", { staticClass: "t-w-1/3 t-text-gray-400 t-text-right" }, [
            _vm._v("Price")
          ]),
          _c("div", [_vm._v("$" + _vm._s(_vm.price))])
        ]),
        _vm.poId
          ? _c("div", { staticClass: "t-flex t-space-x-2 t-mb-2" }, [
              _c(
                "div",
                { staticClass: "t-w-1/3 t-text-gray-400 t-text-right" },
                [_vm._v("PO #")]
              ),
              _c(
                "div",
                {
                  staticClass: "t-text-blue-500 t-cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.$emit("viewPO", _vm.poId)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.poInternalId) + " ")]
              )
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "t-flex-grow t-mt-4 t-flex t-items-end t-justify-center"
          },
          [
            _c(
              "el-button",
              {
                attrs: {
                  plain: "",
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-plus"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("selected")
                  }
                }
              },
              [_vm._v(" Add ")]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }