<template>
  <div>
    <el-table
      :data='pagedTableData'
      size='mini'
      empty-text='No emails sent for this job'
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <p>Subject: {{ props.row.subject }}</p>
          <p>Message: {{ props.row.body }}</p>
        </template>
      </el-table-column>
      <el-table-column label='Sent'>
        <template slot-scope="scope">{{humanDateTime(scope.row.sent)}}</template>
      </el-table-column>
      <el-table-column prop='destination' label='To'></el-table-column>
      <!-- <el-table-column prop='subject' label='Subject'></el-table-column>
      <el-table-column prop='body' label='Message'></el-table-column> -->
      <!-- <el-table-column width="75" label='Job PDF'><template slot-scope="props" style='text-align: center'><i v-if='props.row.pdf_included' class='el-icon-check' style='color: green; font-size: 18px'></i></template></el-table-column> -->
      <el-table-column v-if="!isGeneric" label='Job Status'>
        <template slot-scope="props">{{capitalCase(props.row.job_type)}}</template>
      </el-table-column>
      <el-table-column label="Add'l Docs">
        <template slot-scope="props">
          <div class='job-documents'>
          <div class='document-list-item' v-for="(doc, index) in getDocConfig(props.row.sent_documents)" :key='"emailsent_document-" + index'>
            <div class="document-tag" >
              <md-label-tag
              v-if="doc"
              :icon="doc.icon || 'insert_drive_file'"
              :color="doc.color"
              @click.native="openPreview(doc.docObj)">
                {{ doc.tag }}
              </md-label-tag>
              <md-label-tag v-else
              :icon="'error'"
              @click.native="$message({ type: 'error', message: 'This document has been deleted from the job and you can no longer view it.'})"
              >
                Doc Deleted
              </md-label-tag>
            </div>
          </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Status" width="115">
        <template slot-scope="props" style='text-align: center' class='email-status'>
          <el-popover
            trigger="click">
            <el-timeline>
              <el-timeline-item
                v-for="(status, index) in props.row.email_statuses"
                :key="index"
                :timestamp="formatTimestamp(status.timestamp)"
                :type='getStatusType(status.event)'
              >
                {{status.event}}
              </el-timeline-item>
            </el-timeline>
            <gb-tag v-if="props.row.email_statuses.length" :color="'green'" slot="reference">
              {{props.row.email_statuses[0].event}}
            </gb-tag>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :page-size="5" :total="history.length" :current-page.sync="page"></el-pagination>
    <file-preview-dialog v-model="previewDialog" :doc="preview" :no-delete='true' />
  </div>
</template>

<script>
import FilePreviewDialog from '@/components/modals/FilePreviewDialog/Index'
import { humanDateTime } from '@/scripts/helpers/date.helpers'
import { documentsConfig } from '@/constants/documents_v2.config'
import { capitalCase } from 'change-case'
import moment from 'moment'

export default {
  props: ['history', 'isGeneric'],
  components: {
    FilePreviewDialog
  },
  data() {
    return {
      preview: null,
      previewDialog: false,
      page: 1,
      pageSize: 5
    }
  },
  computed: {
    pagedTableData() {
      return this.history.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    }
  },
  methods: {
    getDocConfig (docs) {
      return docs.map(doc => {
        return doc?.document?.document_type ? {docObj: doc, ...documentsConfig[doc?.document?.document_type]} : null
      })
    },
    openPreview(doc) {
      this.previewDialog = true
      this.preview = doc?.document || doc
    },
    getStatusType(status) {
      if (status === 'delivered') return 'success'
      if (status === 'open') return 'info'
      if (status === 'click') return 'info'
      if (status === 'bounce') return 'danger'
      if (status === 'spamreport') return 'danger'
      if (status === 'dropped') return 'danger'
    },
    getStatusName(status) {
      if (status === 'spamreport') return 'spam report'
      if (status === 'group_unsubscribe') return 'group unsubscribe'
      if (status === 'group_resubscribe') return 'group resubscribe'
      return status
    },
    humanDateTime,
    capitalCase,
    formatTimestamp (dt) {
      const date = moment.unix(dt).format('MMM Do')
      const time = moment.unix(dt).format('h:mm a')
      return date + ', ' + time
    }
  }
}
</script>

<style lang='scss' scoped>
@import "./EmailDialog.scss";
</style>
