<template>
  <div class="consumer-taxes" v-loading="loading">
    <div class="taxes-label">Default Taxes</div>
    <el-table
      :data="shopTaxes"
      size="mini"
      @row-click="rowClick"
      :row-class-name="rowClass"
    >
      <el-table-column width="38" class-name="table-checkbox">
        <slot slot-scope="{ row }">
          <i
            class="material-icons md-18"
            :class="{
              'md-check_box_outline_blank': !isChecked(row),
              'md-check_box': isChecked(row),
            }"
          ></i>
        </slot>
      </el-table-column>
      <el-table-column prop="name" label="Name" />
      <el-table-column prop="percentage" label="Percentage" />
      <el-table-column label="Materials" width="90">
        <slot slot-scope="{ row }">
          <i
            class="material-icons-outline md-18 md-done"
            v-if="row.for_materials"
          />
        </slot>
      </el-table-column>
      <el-table-column label="Labor" width="60">
        <slot slot-scope="{ row }">
          <i
            class="material-icons-outline md-18 md-done"
            v-if="row.for_labor"
          />
        </slot>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions */
import { SHOP_PIECES_QUERIES } from '@/apollo/queries/shop-pieces.query'
import * as R from 'ramda'

export default {
  props: ['value', 'shopId'],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    taxes: {
      get() {
        return this.value?.taxes || []
      },
      set(taxes) {
        this.$emit('input', { ...this.value, taxes })
      }
    }
  },
  apollo: {
    shopTaxes: {
      query: SHOP_PIECES_QUERIES.tax,
      variables() {
        return {
          shop_id: this.shopId
        }
      },
      skip() {
        return !this.shopId
      },
      watchLoading(v) {
        this.loading = v
      }
    }
  },
  methods: {
    isChecked(row) {
      return R.filter(R.propEq('id', row.id))(this.taxes).length > 0
    },
    rowClick(row) {
      if (this.isChecked(row)) {
        this.taxes = R.reject(R.propEq('id', row.id))(this.taxes)
      } else {
        this.taxes = R.append(row)(this.taxes)
      }
    },
    rowClass({ row }) {
      return this.isChecked(row) ? 'is-checked' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.consumer-taxes {
  min-height: 300px;
}
</style>

<style lang="scss">
.consumer-taxes {
  .el-table__row {
    cursor: pointer;
    .table-checkbox {
      > * {
        display: flex;
        align-items: center;
      }
    }
    &.is-checked {
      background: transparentize($primary, 0.9);
    }
  }
  .taxes-label {
    @include gb-label;
    margin-left: $padding-xs;
    margin-bottom: 2px;
  }
}
</style>
