var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("Upload"),
          _vm._l(_vm.data.documents, function(i) {
            return _c("DocumentLine", {
              key: i.id,
              attrs: { document: i },
              on: {
                preview: function($event) {
                  return _vm.preview(i)
                },
                createAvatar: function($event) {
                  _vm.imageCropDialog = i.url
                }
              }
            })
          })
        ],
        2
      ),
      _c("ImageCropDialog", {
        on: { cropped: _vm.setAvatar },
        model: {
          value: _vm.imageCropDialog,
          callback: function($$v) {
            _vm.imageCropDialog = $$v
          },
          expression: "imageCropDialog"
        }
      }),
      _c("FilePreviewDialog", {
        attrs: {
          doc: _vm.previewDialog.file,
          dateFunc: _vm.formatPreviewDate,
          deleteFunc: _vm.deleteDocumentHandler
        },
        model: {
          value: _vm.previewDialog.visible,
          callback: function($$v) {
            _vm.$set(_vm.previewDialog, "visible", $$v)
          },
          expression: "previewDialog.visible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }