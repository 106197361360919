<template>
  <div class="file-preview-actions">
    <el-button size="mini" icon="las la-download" @click="dl">
      Download
    </el-button>
    <el-button size="mini" icon="las la-external-link-alt" @click="open">
      Open in new tab
    </el-button>
    <el-button
      v-if="!noDelete"
      size="mini"
      icon="las la-download"
      type="danger"
      plain
      :loading="deleting"
      @click="deleteItem"
    >
      Delete
    </el-button>
    <a :href="doc.url" target="_blank" ref="open" />
    <a :href="`${doc.url}${dlParam}`" download ref="dl" />
  </div>
</template>

<script>
import JobPiecesMixin from '@/components/JobPageV2/mixins/job-pieces.mixin'
export default {
  mixins: [JobPiecesMixin],
  props: ['doc', 'noDelete', 'gcp', 'deleteFunc'],
  data() {
    return { deleting: false }
  },
  computed: {
    dlParam() {
      return this.gcp ? '&response-content-disposition=attachment;' : '?dl=true'
    }
  },
  methods: {
    open() {
      this.$refs.open.click()
    },
    dl() {
      this.$refs.dl.click()
    },
    async deleteItem() {
      if (this.deleteFunc) {
        this.deleteFunc(this.doc)
        this.$emit('close')
        return
      }
      this.deleting = true
      await this.deleteJobPiece(this.doc, 'docs', 'jobDocs')
      this.$job.updateJob(
        {},
        {
          label: 'Deleted document',
          value: this.doc.document_name,
          refetch: 'jobDocs'
        }
      )
      this.deleting = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./FilePreviewActions.scss";
</style>
