var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InfoCard", {
    attrs: { schema: _vm.schema, dataObject: _vm.lossNotice },
    on: {
      "update:dataObject": function($event) {
        _vm.lossNotice = $event
      },
      "update:data-object": function($event) {
        _vm.lossNotice = $event
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }