<template>
  <div class="t-flex t-justify-between t-items-end t-my-3 t-flex-wrap">
    <div class="t-text-xs t-text-gray-600">
      Showing {{ pagination.total ? pagination.from : 0 }} to {{ to }} of {{ pagination.total }}
    </div>
    <el-pagination
      v-show="!hideLinks"
      background
      class="t-text-gray-700"
      layout="prev, pager, next"
      :page-size="pagination.perPage"
      :pager-count="7"
      :current-page="value"
      :total="pagination.total"
      @current-change="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    pagination: Object,
    hideLinks: Boolean
  },
  computed: {
    to() {
      return this.pagination.to < this.pagination.total ? this.pagination.to : this.pagination.total
    }
  }
}
</script>
