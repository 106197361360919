<template>
  <div class="t-px-4 t-h-14 t-flex-shrink-0 t-border-solid t-border-0 t-border-b
              t-border-gray-300 t-flex t-items-center">
    <div
      class="t-text-base t-text-gray-700 t-flex t-flex-grow t-items-center
             t-pr-2">
      <i v-if="activeChannel.friendlyName === 'direct'" class="el-icon-user"></i>
      <i v-else-if="activeChannel.isPrivate" class="el-icon-lock"></i>
      <span v-else>#</span>
      <div
        class="t-ml-1 t-truncate"
        :class="infoDraweIsOpen ? 't-w-64' : 't-w-120'"
      >
        {{ channelName }}
      </div>
    </div>
    <div v-if="activeChannel.friendlyName !== 'direct'" class="t-flex">
      <div
        @click="openMembersDialog"
        class="t-w-14 t-h-8 t-flex t-items-center t-justify-center t-space-x-2
              t-cursor-pointer t-text-base t-rounded t-text-gray-700
              hover:t-bg-gray-200 t-border t-border-solid t-border-gray-300"
      >
        <span class="t-text-sm">{{ members.length }}</span>
        <i class="fa fa-users"></i>
      </div>
      <div
        v-if="activeChannel.createdBy === username"
        @click="addUser"
        class="t-w-8 t-h-8 t-flex t-items-center t-justify-center t-cursor-pointer
              t-text-base t-ml-3 t-rounded t-text-gray-700
              hover:t-bg-gray-200 t-border t-border-solid t-border-gray-300"
        :class="{ 't-bg-gray-200': addUsersDialogisOpen }"
      >
        <i class="fa fa-user-plus"></i>
      </div>
      <div
        @click="toggleInfoDrawer"
        class="t-w-8 t-h-8 t-flex t-items-center t-justify-center t-cursor-pointer
              t-text-xl t-ml-3 t-rounded t-text-gray-700 hover:t-bg-gray-200
              t-border t-border-solid t-border-gray-300"
        :class="{ 't-bg-gray-200': infoDrawerIsOpen }"
      >
        <i class="el-icon-more-outline"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { chatTypes } from '@/store/modules/chat/types'

export default {
  computed: {
    activeChannel: function() {
      return this.$store.state.chat.activeChannel
    },
    infoDrawerIsOpen: function() {
      return this.$store.state.chat.channelInfoDrawerIsOpen
    },
    membersDialogIsOpen: function() {
      return this.$store.state.chat.membersDialogIsOpen
    },
    infoDraweIsOpen: function() {
      return this.$store.state.chat.channelInfoDrawerIsOpen
    },
    addUsersDialogisOpen: function() {
      return this.$store.state.chat.addUsersDialogIsOpen
    },
    members: function() {
      return this.$store.state.chat.members
    },
    username: function() {
      return this.$store.state.user.username
    },
    channelName: function() {
      if (this.activeChannel.friendlyName !== 'direct') {
        return this.activeChannel.uniqueName
      }
      if (this.activeChannel.uniqueName.split('-')[0] === this.username) {
        return this.activeChannel.uniqueName.split('-')[1]
      }
      return this.activeChannel.uniqueName.split('-')[0]
    }
  },
  methods: {
    toggleInfoDrawer: function() {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.setChannelInfoDrawerIsOpen,
        !this.infoDrawerIsOpen
      )
    },
    openMembersDialog: function() {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.setMembersDialogIsOpen,
        true
      )
    },
    addUser: function() {
      this.$store.commit(
        chatTypes.namespace + chatTypes.mutations.setAddUsersDialogIsOpen,
        true
      )
    }
  }
}
</script>
