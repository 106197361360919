const colors = {
  azureBlue: '#2898ff',
  skyBlue: '#299fd7',
  airForceBlue: '#337ab7',
  royalPurple: '#805ad5',
  crimsonRed: '#dc3545',
  goldOrange: '#f0ad4e',
  jadeGreen: '#54a955',
  teaGreen: '#cadfc9',
  supplementalInvoiceGreen: '#387039'
}

export default colors
