var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-vendors" },
    _vm._l(_vm.vendors, function(vendor) {
      return _c(
        "el-tag",
        {
          key: vendor.id,
          style: {
            color: "#FFFF",
            borderColor: vendor.color
          },
          attrs: { color: vendor.color, size: "small" }
        },
        [_vm._v(" " + _vm._s(vendor.name) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }