import { leadRepository } from '@/scripts/repositories/lead'

const state = {
  filters: {
    shopId: null,
    archived: false
  },
  pagination: {
    page: 1,
    limit: 10,
    from: null,
    to: null,
    total: null
  },
  fetching: false,
  data: []
}

const mutations = {
  setFilterShopId: (state, payload) => {
    state.filters.shopId = payload
  },
  setFilterArchived: (state, payload) => {
    state.filters.archived = payload
  },
  setPaginationPage: (state, payload) => {
    state.pagination.page = payload
  },
  setPaginationFrom: (state, payload) => {
    state.pagination.from = payload
  },
  setPaginationTo: (state, payload) => {
    state.pagination.to = payload
  },
  setPaginationTotal: (state, payload) => {
    state.pagination.total = payload
  },
  setData: (state, payload) => {
    state.data = payload
  },
  setFetching: (state, payload) => {
    state.fetching = payload
  }
}

const actions = {
  get: async ({ state, commit }) => {
    commit('setFetching', true)
    commit('setData', [])
    const { data, meta } = await leadRepository.get({
      page: state.pagination.page,
      limit: state.pagination.limit,
      shopId: state.filters.shopId,
      archived: state.filters.archived ? 1 : 0
    })
    commit('setData', data)
    commit('setPaginationFrom', meta.from)
    commit('setPaginationTo', meta.to)
    commit('setPaginationTotal', meta.total)
    commit('setFetching', false)
  },

  startJob: (_, { id }) => leadRepository.startJob(id),

  setArchived: async ({ dispatch }, { id, value }) => {
    await leadRepository.setArchived({ id, value })
    await dispatch('get')
  },

  remove: async ({ dispatch }, { id }) => {
    await leadRepository.remove(id)
    await dispatch('get')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
