var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.schema, function(item) {
      return _c(
        "primary-fields-selector",
        _vm._b(
          {
            key: item.title,
            ref: "fields",
            refInFor: true,
            attrs: {
              value: _vm.getValue(
                item.listKey,
                item.primaryKey,
                item.secondaryKey
              )
            },
            on: {
              input: function($event) {
                return _vm.setValue(
                  $event,
                  item.listKey,
                  item.primaryKey,
                  item.secondaryKey
                )
              }
            }
          },
          "primary-fields-selector",
          item,
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }