var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "900px",
        "append-to-body": "",
        visible: _vm.visible,
        "before-close": _vm.close,
        "custom-class": "shopping-cart-dialog"
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-xl t-text-gray-800",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("Shopping cart")]
      ),
      _c("cart-header"),
      _c("tabs", { attrs: { suppliers: _vm.suppliers } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }