// number of active processes
var processCnt = 0;
var timerId = null;

export class MultiLoader {

    constructor(start, stop, close) {
        this._start = start;
        this._stop = stop;
        this._close = close;
    }

    /**
     * execute loading function
     */
    start() {
        this._start();
        // increase number of active processes
        processCnt += 1;

        // if we have no closing timeout just return
        if (timerId === null) {
            return;
        }
        clearTimeout(timerId);
    }

    stop() {
        processCnt -= 1
        if (processCnt > 0) {
            return;
        }

        this._stop()

        timerId = setTimeout(this._close, 1500);
    }
}
