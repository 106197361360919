<template>
  <el-dialog
    width="1000px"
    append-to-body
    :visible.sync="visible"
    custom-class="shopping-cart-dialog">
    <span slot="title" class="t-text-xl t-text-gray-800">Order details</span>
    <div
      style="max-height: 600px; overflow-y: auto;"
      class="t-p-4">
      <div v-if="loading" class="t-p-12 t-text-center t-text-3xl t-text-blue-600">
        <i class="el-icon-loading" />
      </div>
      <table
        v-else
        class="t-w-full t-border t-border-solid t-border-collapse
              t-border-gray-500">
        <thead>
        <tr>
          <td
            class="t-w-1/2 t-border t-border-solid t-border-gray-500 t-p-3
                    t-text-base t-text-center">
            Requested items
          </td>
          <td
            class="t-w-1/2 t-border t-border-solid t-border-gray-500 t-p-3
                    t-text-base t-text-center">
            Response
          </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(i, index) in items" :key="index">
          <td
            class="t-border t-border-solid t-border-gray-500 t-p-3"
            valign="top">
            <div
              v-for="item in i.requested"
              :key="item.id"
              class="t-px-3 t-py-2 t-rounded t-border t-border-solid
                      t-border-gray-400 t-bg-gray-100 t-mb-2">
              <div class="t-flex">
                <div class="t-flex-grow">
                  <div class="t-flex t-text-base t-mb-1">
                    <div class="t-mr-1 t-font-bold">{{ item.partType }}</div>
                    <div class="t-mr-1 t-font-bold">
                      <div v-if="item.partType === 'GL'">
                        {{ item.fullPartNumber }}
                      </div>
                      <div v-else>
                        {{ item.part }}
                      </div>
                    </div>
                    <div class="t-mr-1 t-text-gray-600">by</div>
                    <div>{{ item.trademark }}</div>
                  </div>
                  <div class="t-flex t-text-sm t-items-center t-text-gray-600">
                    <i class="el-icon-location-outline t-text-base t-mr-1 t-text-blue-500"></i>
                    <div>{{ item.locationDescription }}</div>
                  </div>
                </div>
                <div>
                  <div class="t-flex">
                    <div>{{ item.quantity }}</div>
                    <div class="t-mx-2">x</div>
                    <div class="t-font-bold">${{ item.priceBuyer }}</div>
                  </div>
                  <div
                    v-if="details.fulfilled === 2 && i.requested.length > 1"
                    @click="deleteFromOrder(item.id)"
                    class="t-text-center t-mt-2 t-bg-white t-border t-border-solid
                              t-border-red-400 t-rounded-md t-px-2 t-py-1
                              t-cursor-pointer t-text-red-500
                              hover:t-bg-red-500 hover:t-border-red-500
                              hover:t-text-white t-text-xs">
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td
            class="t-border t-border-solid t-border-gray-600 t-p-3"
            valign="top">
            <div v-if="i.received.length === 0 && details.fulfilled !== 1">
              No data received yet.
            </div>
            <div v-else>
              <div
                v-for="(item, index) in i.received"
                :key="item.id"
                :class="index + 1 !== i.received.length ? 't-mb-2' : ''"
                class="t-px-3 t-py-2 t-rounded t-border t-border-solid
                        t-border-gray-400 t-bg-gray-100">
                <div class="t-flex">
                  <div class="t-flex-grow">
                    <div class="t-flex t-text-base t-mb-1">
                      <div class="t-mr-1 t-font-bold">{{ item.partType }}</div>
                      <div class="t-mr-1 t-font-bold">
                        <div v-if="item.partType === 'GL'">
                          {{ item.fullPartNumber }}
                        </div>
                        <div v-else>
                          {{ item.part }}
                        </div>
                      </div>
                      <div class="t-mr-1 t-text-gray-600">by</div>
                      <div>{{ item.trademark }}</div>
                    </div>
                    <div v-if="item.confirmationCode" class="t-mb-2">
                      <span class="t-text-gray-600">Confirmation code:</span>
                      <span class="t-ml-2">{{ item.confirmationCode }}</span>
                    </div>
                    <div class="t-flex t-text-sm t-items-center t-text-gray-600">
                      <i class="el-icon-location-outline t-text-base t-mr-1 t-text-blue-500"></i>
                      <div>{{ item.locationDescription }}</div>
                    </div>
                    <div class="t-flex t-text-sm t-items-center t-text-gray-600">
                      <i class="el-icon-truck t-text-base t-mr-1 t-text-blue-500"></i>
                      <div>{{ formatDate(item.deliveryDate) }},</div>
                      <div class="t-mx-1">{{ formatTime(item.deliveryTime) }}</div>
                      <div>- {{ item.deliveryMethod }}</div>
                    </div>
                  </div>
                  <div>
                    <div class="t-flex">
                      <div>{{ item.quantity }}</div>
                      <div class="t-mx-2">x</div>
                      <div class="t-font-bold">${{ item.priceBuyer }}</div>
                    </div>
<!--                    <div-->
<!--                      v-if="details.fulfilled === 2"-->
<!--                      @click="addToOrder(item.id)"-->
<!--                      class="t-mt-2 t-bg-white t-border t-border-solid-->
<!--                               t-border-blue-400 t-rounded-md t-px-2 t-py-1-->
<!--                               t-cursor-pointer t-text-blue-500-->
<!--                               hover:t-bg-blue-500 hover:t-border-blue-500-->
<!--                               hover:t-text-white t-text-xs">-->
<!--                      Add to order-->
<!--                    </div>-->
                  </div>
                </div>
                <div v-if="index === 0">
                  <el-divider class="t-mt-4 t-mb-2" />
                  <div class="t-flex">
                    <div class="t-mr-1 t-break-normal">Message:</div>
                    <div
                      :class="item.errorCode === '0' ? 't-text-green-500' : 't-text-red-500'"
                      class="t-break-normal">
                      {{ item.errorDescription }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </el-dialog>
</template>

<script>
import {
  shoppingCartActions,
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'
import moment from 'moment'

export default {
  computed: {
    items() {
      return this.$store.state.shoppingCart.orders.orderItems
    },
    details() {
      return this.$store.state.shoppingCart.orders.orderDetails
    },
    loading() {
      return this.$store.state.shoppingCart.orders.orderItemsFetching
    },
    visible: {
      get() {
        return this.$store.state.shoppingCart.orders.orderDetailsIsOpen
      },
      set(value) {
        return this.$store.commit(
          shoppingCartMutations.orders.setOrderDetailsIsOpen,
          value
        )
      }
    }
  },
  methods: {
    addItemToOrder(payload) {
      return this.$store.dispatch(shoppingCartActions.addItemToOrder, payload)
    },
    deleteItemFromOrder(payload) {
      return this.$store.dispatch(
        shoppingCartActions.deleteItemFromOrder,
        payload
      )
    },
    formatDate(date) {
      return moment(date).format('MMM Do')
    },
    formatTime(time) {
      return moment(time, 'HH:mm:ss').format('h:mm a')
    },
    async addToOrder(id) {
      await this.addItemToOrder(id)
      this.$message.success('Item was added successfully')
    },
    async deleteFromOrder(id) {
      try {
        await this.$messageBox.confirm(
          'Are you sure you want to delete this item ?',
          'warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning'
          }
        )
        await this.deleteItemFromOrder(id)
        this.$message.success('Item was removed successfully')
      } catch (e) {
      }
    }
  }
}
</script>
