import { gql } from 'apollo-boost'

export const AUDIT_LOG_ENTRY_FRAGMENT = gql`
  fragment AuditLogEntryFields on AuditLogEntry {
    timestamp
    user_id
    username
    label
    job
    color
    value
    type
    parts
    refetch
    part
    docs
    tags
    taxes
  }
`
