import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class DynamicDocument extends ObjectTracker {
  id = -1
  created = -1
  document_type = ''
  document_name = ''
  url = ''
  table_ref = ''
  ref_id = ''
  modified = -1

  constructor (initialObject = {}) {
    super ()
    super.setInitial(initialObject)
  }

  static getDynamicDocuments (shopId, type, id, successCallbackFunction, errorCallbackFunction = null) {
    Api(`/api/shop/${shopId}/document/type/${type}/id/${id}`).then(res => {
      var tempArray = []
      if (res.data && res.data.data) {
        for (var i = 0; i < res.data.data.length; i++) {
          tempArray.push(new DynamicDocument(res.data.data[i]))
        }
      }
      successCallbackFunction(tempArray)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  createUpdateDynamicDocument (shopId, successCallback, errorCallback = null) {
    Api.post(`/api/shop/${shopId}/document/${this.id}`, this).then(res => {
      if (res.data.status === 'success') {
        successCallback(new DynamicDocument(res.data.data))
      } else {
        errorCallback(res.data.message)
      }
    })
  }

  deleteDynamicDocument (shopId, callback, errorCallback = null) {
    Api.delete(`/api/shop/${shopId}/document/type/${this.table_ref}/refId/${this.ref_id}/id/${this.id}`, this).then(res => {
      callback(res)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  translateDynamicObjectForBackend () {
    var objectToSend = null
    objectToSend['id'] = this.id
    objectToSend['created'] = this.created
    objectToSend['document_type'] = this.document_type
    objectToSend['document_name'] = this.document_name
    objectToSend['url'] = this.url
    objectToSend[this.type+'_id'] = this.ref_id
    objectToSend['modified'] = this.modified
  }

  static saveSignaturesForTechside (jobId, signatureData, source) {
    const reqObj = {'signatureData': signatureData, 'source': source}
    Api.post(`/api/mobile/job/${jobId}`, reqObj).then(res => {
      console.log('response from document controller:', res)
    })
  }

}