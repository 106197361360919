var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        "append-to-body": "",
        "close-on-click-modal": false,
        title: "Send Customer Payment Link",
        visible: _vm.visible,
        "custom-class": "text-message-payment-dialog",
        width: _vm.activeTab === "Email" ? "1200px" : "900px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { "min-height": "500px" },
          attrs: { "tab-position": "left" },
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        _vm._l(_vm.options, function(tab) {
          return _c(
            "el-tab-pane",
            { key: tab.label, attrs: { label: tab.label, name: tab.label } },
            [
              _vm.activeTab === tab.label
                ? _c(tab.component, {
                    tag: "component",
                    attrs: {
                      "is-active": _vm.activeTab === tab.label,
                      filePickerKey: _vm.filePickerKey
                    },
                    on: {
                      sent: _vm.sentLink,
                      close: function($event) {
                        _vm.visible = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }