import phoneNumbers from './phone-numbers'
import textMessages from './text-messages/index'

export default {
  namespaced: true,
  modules: {
    phoneNumbers,
    textMessages
  }
}
