<template>
  <div class="calendar-view">
    <div>
      <el-button
        icon="el-icon-arrow-left"
        circle
        @click="incrementWeekDate(-1)"
      />
    </div>
    <calendar-job-card-list
      v-for="date in weekDates"
      :date="date"
      :key="date.toString()"
      :dragoverDate="dragoverDate"
    />
    <div>
      <el-button
        icon="el-icon-arrow-right"
        circle
        @click="incrementWeekDate(1)"
      />
    </div>
  </div>
</template>

<script>
import CalendarJobCardList from './CalendarJobCardList'
import { get, sync } from 'vuex-pathify'
import { addWeeks } from 'date-fns'

export default {
  components: { CalendarJobCardList },
  data() {
    return {
      dragoverDate: null
    }
  },
  computed: {
    weekDates: get('sch_v3/weekDates'),
    week: sync('sch_v3/filters@week'),
    dates() {
      return this.weekDates(this.offset)
    },
    context: sync('sch_v3/filters@context')
  },
  methods: {
    incrementWeekDate(increment) {
      this.week = addWeeks(this.week, increment)
    }
  },
  watch: {
    dateFilter(date, old) {
      if (date !== old) {
        this.offset = 0
      }
    }
  },
  mounted() {
    this.$root.$on('calendar-drag-over', id => {
      this.dragoverDate = id
    })
    this.context = 'all'
  }
}
</script>

<style lang="scss">
@import 'CalendarView.scss';
</style>
