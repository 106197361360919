import * as R from 'ramda'

const prefix = 'shoppingCart/'

const actions = {
  open: 'open',
  close: 'close',
  getShops: 'getShops',
  addItem: 'addItem',
  getItems: 'getItems',
  getVendorsItemsCount: 'getVendorsItemsCount',
  changeQuantity: 'changeQuantity',
  deleteItem: 'deleteItem',
  getItemsCount: 'getItemsCount',
  openAmericanCheckout: 'openAmericanCheckout',
  closeAmericanCheckout: 'closeAmericanCheckout',
  openPilkingtonCheckout: 'openPilkingtonCheckout',
  closePilkingtonCheckout: 'closePilkingtonCheckout',
  openNielsenMollerCheckout: 'openNielsenMollerCheckout',
  closeNielsenMollerCheckout: 'closeNielsenMollerCheckout',
  getNielsenMollerShippingAddresses: 'getNielsenMollerShippingAddresses',
  getPilkingtonShipToAddresses: 'getPilkingtonShipToAddresses',
  getPilkingtonDeliveryMethods: 'getPilkingtonDeliveryMethods',
  getAmericanShipTerms: 'getAmericanShipTerms',
  addToAmericanCart: 'addToAmericanCart',
  placeOrder: 'placeOrder',
  getOrders: 'getOrders',
  getOrderItems: 'getOrderItems',
  selectItem: 'selectItem',
  unselectItem: 'unselectItem',
  clearSelectedItemIds: 'clearSelectedItemIds',
  addItemToOrder: 'addItemToOrder',
  deleteItemFromOrder: 'deleteItemFromOrder',
  resubmitOrder: 'resubmitOrder'
}

const shoppingCartActions = R.map(R.concat(prefix))(actions)

const mutations = {
  setIsOpen: 'setIsOpen',
  setSelectedTab: 'setSelectedTab',
  setShopsData: 'setShopsData',
  setShopId: 'setShopId',
  items: {
    setItemsData: 'items/setData',
    setItemsFetching: 'items/setFetching',
    setSelectedVendor: 'items/setSelectedVendor',
    setSelectedItemIds: 'items/setSelectedIds',
    setTotalItemsCount: 'items/setTotalCount',
    setVendorsItemsCount: 'items/setVendorCount',
    updateItemQuantity: 'items/setQuantity',
    setAmericanCheckoutIsOpen: 'items/setAmericanCheckoutIsOpen',
    setPilkingtonCheckoutIsOpen: 'items/setPilkingtonCheckoutIsOpen',
    setNielsenMollerCheckoutIsOpen: 'items/setNielsenMollerCheckoutIsOpen',
    setSelectedAccountNumber: 'items/setSelectedAccountNumber'
  },
  orders: {
    setOrdersData: 'orders/setData',
    setOrdersFetching: 'orders/setFetching',
    setSelectedVendor: 'orders/setSelectedVendor',
    setOrderDetailsIsOpen: 'orders/setDetailsIsOpen',
    setOrderDetails: 'orders/setDetails',
    setOrdersItems: 'orders/setItems',
    setOrderItemsFetching: 'orders/setItemsFetching',
    setAmericanOrderDetailsIsOpen: 'orders/setAmericanOrderDetailsIsOpen'
  }
}

const shoppingCartMutations = {
  setShopId: prefix + mutations.setShopId,
  setSelectedTab: prefix + mutations.setSelectedTab,
  items: {
    setSelectedVendor: prefix + mutations.items.setSelectedVendor,
    setSelectedAccountNumber: prefix + mutations.items.setSelectedAccountNumber
  },
  orders: {
    setSelectedVendor: prefix + mutations.orders.setSelectedVendor,
    setOrderDetailsIsOpen: prefix + mutations.orders.setOrderDetailsIsOpen,
    setOrderDetails: prefix + mutations.orders.setOrderDetails,
    setOrdersItems: prefix + mutations.orders.setOrdersItems,
    setAmericanOrderDetailsIsOpen: prefix + mutations.orders.setAmericanOrderDetailsIsOpen
  }
}

export {
  actions,
  mutations,
  shoppingCartActions,
  shoppingCartMutations
}
