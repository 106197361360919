var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vendorObject &&
    _vm.vendorObject.totalAvailableIncludingSubs &&
    _vm.vendorObject.totalAvailableIncludingSubs > 0
    ? _c("span", [
        _c(
          "span",
          {
            class: _vm.classes,
            staticStyle: { cursor: "pointer" },
            attrs: { tabindex: "0", disabled: _vm.isDisabled },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.addPart($event)
              }
            }
          },
          [
            _c("span", { staticStyle: { color: "darkblue" } }, [
              _vm._v(
                " " +
                  _vm._s(_vm.vendorObject.totalAvailableIncludingSubs) +
                  " INV "
              ),
              !_vm.isButton
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        "font-style": "italic",
                        "font-size": "small"
                      }
                    },
                    [_vm._v("add")]
                  )
                : _c("span", [_vm._v("+")])
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }