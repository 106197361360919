<template>
  <el-form
    :model="form"
    ref="form"
    class="vendor-create-form"
    @submit.prevent.native
  >
    <div class="t-text-gray-400 t-mb-6">Vendor Type</div>
    <el-form-item
      prop="type"
      :rules="{
        required: true,
        message: 'Please select vendor type'
      }"
    >
      <el-select
        v-model="form.type"
        placeholder="Select vendor type"
        @change="onCreate"
      >
        <el-option
          v-for="(item, key) in vendorTypes"
          :key="key"
          :value="key"
          :label="item.name"
        >
          <div class="t-flex t-items-center">
            <div
              class="t-h-3 t-w-3 t-rounded-full"
              :style="{ background: item.color }"
            />
            <div class="t-ml-3">{{ item.name }}</div>
          </div>
        </el-option>
      </el-select>
    </el-form-item>

    <el-divider class="t-my-6" />

    <div class="t-text-gray-400 t-mb-6">Name</div>
    <el-form-item
      prop="name"
      :rules="{
        required: true,
        message: 'Please enter name',
        trigger: 'blur'
      }"
    >
      <el-input v-model="form.name" @change="onCreate" />
    </el-form-item>
  </el-form>
</template>

<script>
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import { mapActions, mapState } from 'pinia'

export default {
  data() {
    return {
      form: {
        name: '',
        type: '',
        organizationId: null
      }
    }
  },

  computed: {
    ...mapState(useVendorStore, [
      'id',
      'visible',
      'data',
      'newVendor',
      'vendorTypes'
    ])
  },

  watch: {
    visible: function (val) {
      if (val) {
        this.prefillForm()
      } else {
        this.form = {
          name: '',
          type: '',
          organizationId: null
        }
        this.$refs.form.resetFields()
      }
    }
  },

  mounted() {
    this.prefillForm()
  },

  methods: {
    ...mapActions(useVendorStore, ['create']),

    prefillForm() {
      this.form.organizationId = this.newVendor.organizationId
      this.form.type = this.newVendor.type
      this.$refs.form.validate(() => {})
    },

    async onCreate() {
      if (this.form.name && this.form.type) {
        try {
          await this.create(this.form)
          this.$root.$emit('vendor-created', this.id)
        } catch (error) {
          this.$message.error(error.message)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.vendor-create-form {
  .el-input {
    @apply t-w-[312px];
  }

  .el-input__inner {
    @apply t-rounded-lg;
  }
}
</style>
