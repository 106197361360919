<template>
  <div></div>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  data() {
    return {
      service: null
    }
  },
  computed: {
    techs: get('sch_v3/techsideUsernames')
  }
}
</script>
