<template>
  <div>
    <el-dialog
    :visible.sync="visible"
    title='Mobile'
    :width="isMobile ? '100%' : '1400px'"
    @open='dialogAboutToAppear'
    @opened='dialogAppeared'
    custom-class="gb-el-dialog quotes-dialog mobileInboxModal"
    top="24px"
    ref="quotes-dialog"
    append-to-body>
      <el-tabs type="card" @tab-click="tabClicked">
        <el-tab-pane label="Mobile">
          <div slot="label">
            <div v-if="$store.state.userSalesideJobCount > 0" class="notification right blue">
              <div style='margin-top: -10px;'>
                {{ $store.state.userSalesideJobCount }}
              </div>
            </div>
            Mobile
          </div>
          <div class="row mb-2">
            <div class="col-sm-12 col-md-8 col-xl-5 col-lg-5">
              <label for="#">Shop</label>
              <Multiselect
                placeholder="Select Shop..."
                :options="$store.state.shops"
                label="name"
                :show-labels="false"
                v-model="selectedShop">
                <template slot="singleLabel" slot-scope="props">
                  <div class="row" style="margin:0px;">
                    <div class="col-11" style="font-size: 14px; text-overflow: ellipsis;overflow: hidden;padding:0px;">
                      {{ props.option.name }}
                    </div>
                    <div class="float-right col-1" style="padding:0px;">
                      <span v-show="getShopCountForDropdown (props.option.id) != '0'" style="text-align:center;color:white;font-size:14px;padding-right:6px;padding-left:5px;padding-top:5px;padding-bottom:5px;border-radius: 5px;background:#007bff;">
                        {{ getShopCountForDropdown(props.option.id) }}
                      </span>
                    </div>
                  </div>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="row" style="margin:0px;">
                    <div class="col-11" style="font-size: 14px; text-overflow: ellipsis;overflow: hidden;padding:0px;">
                      {{ props.option.name }}
                    </div>
                    <div class="float-right col-1" style="padding:0px;">
                      <span v-show="getShopCountForDropdown (props.option.id) != '0'" style="text-align:center;color:white;font-size:14px;padding-right:6px;padding-left:5px;padding-top:5px;padding-bottom:5px;border-radius: 5px;background:#007bff;">
                        {{ getShopCountForDropdown(props.option.id) }}
                      </span>
                    </div>
                  </div>
                </template>
              </Multiselect>
            </div>
            <b-row v-if="!isMobile">
              <b-col align-self="center">
                <a class="tag ttFilter" style="background-color: gray; color: white; display:inline;" @click="changeInboxView('jobs')">new jobs: {{inboxCountForDispaly}}</a>
                <a class="tag ttFilter" style="background-color: #21b7ff; color: white; display:inline;" @click="changeInboxView('messages')">messages: {{unreadCounter()}}</a>
                <a class="tag ttFilter" style="background-color: #e66926; color: white; display:inline;" @click="toggleUnsentFilter()">Unsent: {{inboxUnsentCountForDisplay}}</a>
                <a v-if="upcomingJobs.today > 0" class="tag ttFilter" style="background-color: red; color: white; display:inline;" @click="filterByTodayTomorrow('today')">Today: {{upcomingJobs.today}}</a>
                <a v-if="upcomingJobs.tomorrow > 0" class="tag ttFilter" style="background-color: orange; color: white; display:inline;" @click="filterByTodayTomorrow('tomorrow')">Tomorrow: {{upcomingJobs.tomorrow}}</a>
              </b-col>
            </b-row>
            <b-row v-else style="padding: 0px 15px;">
              <b-col>
                <a class="tag ttFilter" style="background-color: gray; color: white; display:block; text-align:center;" @click="changeInboxView('jobs')">new jobs: {{inboxCountForDispaly}}</a>
                <a class="tag ttFilter" style="background-color: #21b7ff; color: white; display:block; text-align:center;" @click="changeInboxView('messages')">messages: {{unreadCounter()}}</a>
                <a class="tag ttFilter" style="background-color: #e66926; color: white; display:inline;" @click="toggleUnsentFilter()">Unsent: {{inboxUnsentCountForDisplay}}</a>
                <a v-if="upcomingJobs.today > 0" class="tag ttFilter" style="background-color: red; color: white; display:block; text-align:center;" @click="filterByTodayTomorrow('today')">Today: {{upcomingJobs.today}}</a>
                <a v-if="upcomingJobs.tomorrow > 0" class="tag ttFilter" style="background-color: orange; color: white; display:block; text-align:center;" @click="filterByTodayTomorrow('tomorrow')">Tomorrow: {{upcomingJobs.tomorrow}}</a>
              </b-col>
            </b-row>
          </div>

          <div class="row mb-2" v-if="currentInboxView === 'jobs'">
            <div class="col-6 col-xl-2 col-lg-3">
              <input  v-model="showArchive" type="checkbox" /> Show Archive?
            </div>
          </div>

          <!-- Jobs Table -->
          <div class="tableHolder row" v-if="currentInboxView==='jobs'">
            <div class="form-group col-12">
              <b-table ref="mobileInboxTable"
                stacked="md"
                :items="myProvider"
                :fields="fields"
                :current-page="inboxCurrentPage"
                :per-page="inboxPerPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy.sync="isBusy"
                :show-empty="true"
                :empty-text="isBusy ? 'Loading, please wait...' : 'There are no records to show'"
                :empty-filtered-text="isBusy ? 'Loading, please wait...' : 'There are no records matching your request'"
                class="text-left p-0"
                >
                <template slot="HEAD_mobileJobNumber" slot-scope="row">
                  <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="mobileJobNumberFilter" placeholder="Mobile Job #" class="form-control form-control-sm"/>
                </template>
                <template slot="HEAD_referralNumber" slot-scope="row">
                  <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="referralNumberFilter" placeholder="Referral #" class="form-control form-control-sm"/>
                </template>
                <template slot="HEAD_lastName" slot-scope="row">
                  <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="lastNameFilter" placeholder="Last Name" class="form-control form-control-sm"/>
                </template>
                <template slot="HEAD_firstName" slot-scope="row">
                  <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="firstNameFilter" placeholder="First Name" class="form-control form-control-sm"/>
                </template>
                <template slot="HEAD_jobNumber" slot-scope="row">
                  <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="jobNumberFilter" placeholder="Job #" class="form-control form-control-sm"/>
                </template>
                <template slot="HEAD_salesRep" slot-scope="row">
                  <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="salesRepFilter" placeholder="Sales Rep" class="form-control form-control-sm"/>
                </template>
                <template slot="HEAD_yearMakeModel" slot-scope="row">
                  <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="yearMakeModelFilter" placeholder="Year Make Model" class="form-control form-control-sm"/>
                </template>
                <template slot="HEAD_scheduledDate" slot-scope="row">
                  <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="scheduledDateFilter" placeholder="Scheduled Date" class="form-control form-control-sm"/>
                </template>
                <template slot="HEAD_sentOn">
                  Sent
                </template>
                <template slot="sentOn" slot-scope="row">
                  <span v-if="row.item.sentOn">{{getTimeZoneFormattedDateTime(row.item.sentOn)}}</span>
                </template>
                <template slot="referralNumber" slot-scope="row">
                  {{row.item.referralNumber}}
                  <a v-if="row.item.pending_offline === 1" class="tag" style="background-color: purple; color: white; display:inline;">Offline</a>
                </template>
                <template slot="scheduledDate" slot-scope="row">
                  <a v-if="isJobSoon(row.item.scheduledDate) === 'today'" class="tag" style="background-color: red; color: white; display:inline;">Today</a>
                  <a v-else-if="isJobSoon(row.item.scheduledDate) === 'tomorrow'" class="tag" style="background-color: orange; color: white; display:inline;">Tomorrow</a>
                  <span v-else>{{row.item.scheduledDate}}</span>
                </template>

                <template slot="jobNumber" slot-scope="row">
                  <a v-show="row.item.jobNumber > 0" href="javascript:void(0)" @click="closeModal(); changeRouteWithCheck('/jobs/' + row.item.jobNumber)">{{ row.item.jobNumber }}</a>
                  <a v-show="row.item.sent === null || row.item.sent === 0" class="tag" style="background-color: #e66926; color: white; display:inline;">Unsent</a>
                </template>

                <template slot="actions" slot-scope="row">
                  <img v-show="startingJobs.indexOf(row.item.mobileJobNumber) >= 0 " style="width:20px; height:20px" src='./../../assets/img/spinner.gif'>
                  <!-- <a v-show="startingJobs.indexOf(row.item.mobileJobNumber) < 0 && row.item.status !== 'archived' && row.item.jobNumber <= 0 && row.item.sent === 1" @click="startButtonPressed(row.item)" href="javascript:void(0)" class="label label-gray label-sm">Start</a> -->
                  <el-dropdown class='mobile-inbox-actions' round split-button type="primary" @command="startButtonPressed" @click="startButtonPressed({ ...row.item, selectedStartStatus: 'workOrder' })">
                    Start
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="{ ...row.item, selectedStartStatus: 'quote' }">Start Quote</el-dropdown-item>
                      <el-dropdown-item :command="{ ...row.item, selectedStartStatus: 'invoice' }">Start Invoice</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <a v-show="startingJobs.indexOf(row.item.mobileJobNumber) < 0 && row.item.status !== 'archived' && row.item.jobNumber <= 0 && row.item.sent === 1" @click="kickbackJobPressed(row.item)" href="javascript:void(0)" class="label label-gray label-sm">Kick Back</a>
                  <a v-show="startingJobs.indexOf(row.item.mobileJobNumber) < 0 && row.item.status !== 'archived' && row.item.sent === 1" @click="archiveButtonPressed(row.index, row.item)" href="javascript:void(0)" class="label label-gray label-sm">Archive</a>
                  <a v-show="startingJobs.indexOf(row.item.mobileJobNumber) < 0 && row.item.status === 'archived' && row.item.sent === 1" @click="unarchiveButtonPressed(row.item)" href="javascript:void(0)" class="label label-gray label-sm">Unarchive</a>
                  <a href="javascript:void(0)" @click="viewUnsentJob(row.item)" class="label label-gray label-sm">View</a>
                </template>

                <template slot="row-details" slot-scope="row">
                  <b-row v-if="!isMobile" @click="messageClicked(row.item)" class="shopMessageRow">
                    <b-col cols="1" style="text-align:center;">
                      <b-badge variant="success">New</b-badge>
                    </b-col>
                    <b-col cols="5">
                      <p><b>Message:</b> {{truncateMessage(row.item.message)}}</p>
                    </b-col>
                    <b-col cols="1">
                      <a v-if="row.item.jobNumber > 0" :href="'/jobs/' + row.item.jobNumber" target='_blank' class="tag" style="background-color: gray; display:inline;" v-on:click.stop>{{row.item.jobNumber}}</a>
                      <span v-else><b>Mob#:</b> {{row.item.mobileJobNumber}}</span>
                    </b-col>
                    <b-col cols="1">
                      <p><b>From:</b> {{row.item.salesRep}}</p>
                    </b-col>
                    <b-col cols="2">
                      <p><b>Sent:</b> {{row.item.created}}</p>
                    </b-col>
                    <b-col cols="2" style="text-align:center;">
                      <a v-if="row.item.message_status==='unread'" href="javascript:void(0)" v-on:click.stop="toggleMessageStatus(row.item)" class="label label-gray label-sm">Mark as read</a>
                      <a v-else-if="row.item.message_status==='read'" href="javascript:void(0)" v-on:click.stop="toggleMessageStatus(row.item)" class="label label-gray label-sm">Mark as unread</a>
                    </b-col>
                  </b-row>
                  <b-row v-else @click="messageClicked(row.item)" class="shopMessageRow">
                    <b-col cols="2">
                      <b-badge variant="success">New</b-badge>
                    </b-col>
                    <b-col cols="5">
                      <p><b>Message</b></p>
                    </b-col>
                    <b-col cols="5">
                      <p><b>From:</b> {{row.item.salesRep}}</p>
                    </b-col>
                  </b-row>
                </template>
              </b-table>
            </div>
          </div>
          <b-row v-if="currentInboxView === 'jobs'">
            <b-col md="6">
              <span>{{ inboxResultsText }}</span>
            </b-col>
            <b-col md="6">
              <b-pagination :total-rows="inboxTotalRows" :per-page="inboxPerPage" v-model="inboxCurrentPage" align="right"/>
            </b-col>
          </b-row>
          <!-- Messages Table -->
          <div class="row" v-bind:class="{ tableHolder2 : allShopMessages.length, emptyTable: !allShopMessages.length }" v-if="currentInboxView==='messages'">
            <div class="form-group col-12">
              <b-table
                ref="shopMessagesTable"
                stacked="md"
                :items="allShopMessages"
                @row-clicked="messageClicked"
                :fields="messageFields"
                :current-page="messagesCurrentPage"
                :per-page="messagesPerPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy.sync="isBusy"
                :show-empty="true"
                :empty-text="isBusy ? 'Loading, please wait...' : 'There are no records to show'"
                :empty-filtered-text="isBusy ? 'Loading, please wait...' : 'There are no records matching your request'"
                class="text-left p-0"
                >

                <template slot="message" slot-scope="row">
                  <b-row v-if="row.item.message_status === 'unread' && !isMobile">
                    <b-col cols="2" style="text-align:center;">
                      <b-badge variant="success">New</b-badge>
                    </b-col>
                    <b-col cols="10">
                      <p>{{truncateMessage(row.item.message)}}</p>
                    </b-col>
                  </b-row>
                  <b-row v-else-if="row.item.message_status === 'unread' && isMobile">
                    <b-col>
                      <b-badge variant="success">New</b-badge>
                      <p>{{truncateMessage(row.item.message)}}</p>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col>
                      <p>{{truncateMessage(row.item.message)}}</p>
                    </b-col>
                  </b-row>
                </template>
                <template slot="jobNumber" slot-scope="row">
                  <a v-if="row.item.jobNumber > 0" :href="'/jobs/' + row.item.jobNumber" target='_blank' class="tag" style="background-color: gray; display:inline;" v-on:click.stop>{{row.item.jobNumber}}</a>
                  <span v-else></span>
                </template>
                <template slot="actions" slot-scope="row">
                  <a v-if="row.item.message_status==='unread'" href="javascript:void(0)" v-on:click.stop="toggleMessageStatus(row.item)" class="label label-gray label-sm">Mark as read</a>
                  <a v-else-if="row.item.message_status==='read'" href="javascript:void(0)" v-on:click.stop="toggleMessageStatus(row.item)" class="label label-gray label-sm">Mark as unread</a>
                </template>
              </b-table>
            </div>
          </div>
          <b-row v-if="currentInboxView === 'messages'">
            <b-col md="6">
              <span>{{ messagesResultsText }}</span>
            </b-col>
            <b-col md="6">
              <b-pagination :total-rows="messagesTotalRows" :per-page="messagesPerPage" v-model="messagesCurrentPage" align="right"/>
            </b-col>
          </b-row>
        </el-tab-pane>
        <el-tab-pane label="Leads">
          <div slot="label">
            <div v-if="$store.state.leads.pagination.total > 0" class="notification right blue">
              <div style='margin-top: -10px;'>
                {{ $store.state.leads.pagination.total }}
              </div>
            </div>
            Leads
          </div>
          <Leads/>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <ShopMessageDialog :selectedMessage="selectedMessage"/>
    <!-- <SalesideJobDialog :mobileJobId="selectedUnsentJob.mobileJobNumber" title="Unsent Job"/> -->
  </div>
</template>

<script>
import Salesidejob from '@/scripts/objects/salesidejob'
import ShopMessage from '@/scripts/objects/shop_message'
import ShopMessageDialog from './ShopMessageDialog'
// import SalesideJobDialog from './SalesideJobDialog'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import Multiselect from 'vue-multiselect'
import Moment from 'moment'
import $ from 'jquery'
import LegacyApi from '@/services/LegacyApi'
import AuditLog from '@/scripts/objects/audit_log.js'
import Leads from '../Leads/Index'
import { getFormattedDateTimeWithUserTimezone } from '@/scripts/helpers/date.helpers'

export default {
  props: ['parentVisible'],
  data () {
    return {
      selectedShop: null,
      fields: [
        { key: 'mobileJobNumber', label: 'Mobile Job #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'referralNumber', label: 'Referral #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'lastName', label: 'Last Name', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'firstName', label: 'First Name', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'jobNumber', label: 'Job #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'salesRep', label: 'Sales Rep', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'yearMakeModel', label: 'Year Make Model', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'scheduledDate', label: 'Schedule Date', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'sentOn', label: 'Sent', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'actions', label: 'Actions', sortable: false, thClass: 'leftTh', tdClass: 'leftTd' }
      ],
      messageFields: [
        { key: 'message', label: 'Message', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'jobNumber', label: 'Job #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'mobileJobNumber', label: 'Mobile Job #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'salesRep', label: 'From', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'created', label: 'Sent', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'actions', label: 'Actions', sortable: false, thClass: 'leftTh', tdClass: 'leftTd' }
      ],
      inboxCurrentPage: 1,
      inboxPerPage: 10,
      inboxTotalRows: 0,
      inboxCountForDispaly: 0,
      inboxUnsentCountForDisplay: 0,
      messagesCurrentPage: 1,
      messagesPerPage: 10,
      messagesTotalRows: 0,
      pageOptions: [ 10 ],
      sortBy: (this.currentInboxView === 'jobs') ? 'mobileJobNumber' : '',
      sortDesc: false,
      isBusy: false,
      showArchive: false,
      mobileJobNumberFilter: '',
      referralNumberFilter: '',
      lastNameFilter: '',
      firstNameFilter: '',
      jobNumberFilter: '',
      salesRepFilter: '',
      yearMakeModelFilter: '',
      scheduledDateFilter: '',
      unsentFilter: false,
      startingJobs: [],
      delayTimer: null,
      skipTableReset: false,
      firstLoad: true,
      loadCount: 0,
      allShopMessages: [],
      unreadShopMessages: [],
      unreadMessagesCount: 0,
      pageVisible: false,
      upcomingJobs: {today: 0, tomorrow: 0},
      selectedMessage: {},
      currentInboxView: 'jobs',
      selectedUnsentJob: {},
      unsentJobCount: 0
    }
  },
  mounted () {
    // This causes modal to open on initial landing. Hanging on to it until we can be sure that its not critical to some other piece. //
    // var self = this
    // self.$root.$emit('bv::show::modal', 'mobileInboxModal')
  },
  watch: {
    selectedShop () {
      this.resetTableVariables()
      if (this.$refs.mobileInboxTable) {
        this.$refs.mobileInboxTable.refresh()
      } else {
        if (this.currentInboxView === 'messages') {
          this.myProvider('ctx', function () {
          })
        }
      }
    },
    showArchive () {
      this.refreshTable()
    },
    mobileJobNumberFilter () {
      this.refreshTable()
    },
    referralNumberFilter () {
      this.refreshTable()
    },
    lastNameFilter () {
      this.refreshTable()
    },
    firstNameFilter () {
      this.refreshTable()
    },
    jobNumberFilter () {
      this.refreshTable()
    },
    salesRepFilter () {
      this.refreshTable()
    },
    yearMakeModelFilter () {
      this.refreshTable()
    },
    scheduledDateFilter () {
      this.refreshTable()
    },
    unsentFilter () {
      this.refreshTable()
    },
    visible: function(value) {
      if (value) {
        this.getLeads()
      }
    }
  },
  computed: {
    visible: {
      get () {
        return this.parentVisible
      },
      set (val) {
        this.$emit('update:parentVisible', val)
      }
    },
    inboxResultsText: function () {
      var inboxTableResults = 'Showing'
      if (this.inboxTotalRows > 0) {
        var currentFirstResult = ((this.inboxCurrentPage * this.inboxPerPage) - this.inboxPerPage) + 1
        var currentLastResult = (this.inboxCurrentPage * this.inboxPerPage)
        if (currentLastResult > this.inboxTotalRows) {
          currentLastResult = this.inboxTotalRows
        }
        inboxTableResults += ' ' + currentFirstResult + ' to ' + currentLastResult + ' of'
      }
      inboxTableResults += ' ' + this.inboxTotalRows + ' Saleside Job' + (this.inboxTotalRows === 1 ? '' : 's')
      return inboxTableResults
    },
    messagesResultsText: function () {
      var messagesTableResults = 'Showing'
      if (this.messagesTotalRows > 0) {
        var currentFirstResult = ((this.messagesCurrentPage * this.messagesPerPage) - this.messagesPerPage) + 1
        var currentLastResult = (this.messagesCurrentPage * this.messagesPerPage)
        if (currentLastResult > this.messagesTotalRows) {
          currentLastResult = this.messagesTotalRows
        }
        messagesTableResults += ' ' + currentFirstResult + ' to ' + currentLastResult + ' of'
      }
      messagesTableResults += ' ' + this.messagesTotalRows + ' Shop Message' + (this.messagesTotalRows === 1 ? '' : 's')
      return messagesTableResults
    },
    totalLeads () {
      return this.$store.state.leads.pagination.total || 0
    },
    salesideJobsCount () {
      return this.$store.state.userSalesideJobCount
    }
  },
  methods: {
    getTimeZoneFormattedDateTime (date) {
      return getFormattedDateTimeWithUserTimezone(this.$store, date, 'lll')
    },
    isJobSoon (date) {
      if (date) {
        let today = Moment().endOf('day').format('MM/DD/YYYY')
        let tomorrow = Moment().add(1, 'day').endOf('day').format('MM/DD/YYYY')
        if (Moment(date).diff(today, 'days') === 0) {
          return 'today'
        } else if (Moment(date).diff(tomorrow, 'days') === 0) {
          return 'tomorrow'
        } else {
        }
      }
    },
    filterByTodayTomorrow (selection) {
      this.currentInboxView = 'jobs'
      if (selection === 'today') {
        let todayFilter = Moment().format('M/D/YYYY')
        this.scheduledDateFilter = todayFilter
      } else if (selection === 'tomorrow') {
        let tomorrowFilter = Moment().add(1, 'days').format('M/D/YYYY')
        this.scheduledDateFilter = tomorrowFilter
      }
    },
    getShopCountForDropdown (shop_id) {
      if (this.$store.state.userSalesideShopJobCountArray[shop_id]) {
        return this.$store.state.userSalesideShopJobCountArray[shop_id]['countForDisplay']
      } else {
        return '0'
      }
    },
    refreshTable: function () {
      this.inboxCurrentPage = 1
      this.messagesCurrentPage = 1
      this.filterResults()
    },
    dialogAboutToAppear: function () {
      this.pageVisible = true
      this.currentInboxView = 'jobs'
      let self = this
      Salesidejob.getSalesidejobsDatesForAllConnectedShops(function (res) {
        self.upcomingJobs.today = res.today
        self.upcomingJobs.tomorrow = res.tomorrow
      })
      if (!this.skipTableReset) {
        this.resetTableVariables()
        if (this.firstLoad) {
          if (this.$store.state.user.shop_id) {
            for (var i = 0; i < this.$store.state.shops.length; i++) {
              if (this.$store.state.shops[i].id === this.$store.state.user.shop_id) {
                this.selectedShop = this.$store.state.shops[i]
                break
              }
            }
          } else {
            this.selectedShop = null
          }
          this.firstLoad = false
        }
      }
    },
    dialogAppeared: function () {
      if (this.skipTableReset) {
        this.$refs.mobileInboxTable.refresh()
        this.skipTableReset = false
      }
      this.filterResults()
    },
    resetTableVariables: function () {
      this.mobileJobNumberFilter = ''
      this.referralNumberFilter = ''
      this.lastNameFilter = ''
      this.firstNameFilter = ''
      this.jobNumberFilter = ''
      this.salesRepFilter = ''
      this.yearMakeModelFilter = ''
      this.scheduledDateFilter = ''
      this.inboxCurrentPage = 1
      this.messagesCurrentPage = 1
      this.unsentFilter = false
    },
    filterResults: function () {
      let self = this
      clearTimeout(self.delayTimer)
      self.delayTimer = setTimeout(function () {
        self.isBusy = false
        self.$refs.mobileInboxTable.refresh()
        if (!self.isBusy) {
          self.filterResults()
        }
      }, 300)
    },
    startButtonPressed: async function (item) {
      if (!confirm('Are you sure you want to start this SaleSIDE job?')) {
        return
      }
      if (this.startingJobs.indexOf(item.mobileJobNumber) < 0) {
        this.startingJobs.push(item.mobileJobNumber)
        let self = this
        try {
          const formdata = new FormData()
          formdata.set('start', true)
          formdata.set('selectedStartStatus', item.selectedStartStatus)
          if (true) {// this.selectedShop?.shop_services_config?.consumer) {
            try {
              var { data } = await this.$unum.job().post(`saleside/startjob/${item.mobileJobNumber}`, item)
              if (data.result === 'success') {
                var swoResponse = await this.$unum.job().post(`/docs/${data.jobId}/ssign`)
              }
              self.$store.dispatch('updateUserSalesideCount')
              self.$refs.mobileInboxTable.refresh()
              if (typeof data === 'object' && data !== null && typeof data.error === 'string' && data.error.length > 0) {
                alert('Failed to start saleside job: ' + data.error)
                self.startingJobs.splice(self.startingJobs.indexOf(item.mobileJobNumber), 1)
                return
              }
              item.status = 'archived'
              AuditLog.createAuditLog(data.jobId, 'SSJOBSTARTED')
              self.$unum.job().post('/audit/log', { label: 'Started Saleside job as ', value: item.selectedStartStatus, job_id: data.jobId})
              self.changeRouteWithCheck('/jobs/' + data.jobId)
            } catch (error) {
              console.log(error)
            }
          } else {
            var { data } = await LegacyApi.post('/mobile/saleSIDE/' + item.mobileJobNumber, formdata)
            self.$store.dispatch('updateUserSalesideCount')
            self.$refs.mobileInboxTable.refresh()
            if (typeof data === 'object' && data !== null && typeof data.error === 'string' && data.error.length > 0) {
              alert('Failed to start saleside job: ' + data.error)
              self.startingJobs.splice(self.startingJobs.indexOf(item.mobileJobNumber), 1)
              return
            }
            item.status = 'archived'
            AuditLog.createAuditLog(data.jobId, 'SSJOBSTARTED')
            self.changeRouteWithCheck('/jobs/' + data.jobId)
          }
          // self.$root.$emit('bv::hide::modal', 'mobileInboxModal')
          self.closeModal()
        } catch (error) {
          alert('Failed to start saleside job.')
          self.startingJobs.splice(self.startingJobs.indexOf(item.mobileJobNumber), 1)
          self.$refs.mobileInboxTable.refresh()
          self.$store.dispatch('updateUserSalesideCount')
        }
      }
    },
    kickbackJobPressed: function (item) {
      let self = this
      if (confirm('Are you sure you want to kick this back to the Sales Rep? They will need to send the job from Salesidemobile to get this job in the mobile inbox again.')) {
        Salesidejob.kickbackSalesideJob(item.shopId, item.mobileJobNumber, function (results) {
          if (results === true) {
            self.startingJobs.splice(self.startingJobs.indexOf(item.mobileJobNumber), 1)
            self.$refs.mobileInboxTable.refresh()
          }
          self.$root.$emit('showGlobalNotification', 'Success', 'The job has been kicked back to the Sales Rep')
          self.$store.dispatch('updateUserSalesideCount')
        })
      }
    },
    archiveButtonPressed: function (index, item) {
      let self = this
      Salesidejob.archiveSalesidejob(item.shopId, item.mobileJobNumber, function (results) {
        if (results === true) {
          item.status = 'archived'
          if (!self.showArchive) {
            self.$refs.mobileInboxTable.localItems.splice(index, 1)
          }
          self.$root.$emit('showGlobalNotification', 'Success', 'The job has been archived')
        }
        self.$store.dispatch('updateUserSalesideCount')
      })
    },
    unarchiveButtonPressed: function (item) {
      let self = this
      Salesidejob.unarchiveSalesidejob(item.shopId, item.mobileJobNumber, function (results) {
        if (results === true) {
          item.status = null
          self.$root.$emit('showGlobalNotification', 'Success', 'The job has been unarchived')
        }
        self.$store.dispatch('updateUserSalesideCount')
      })
    },
    myProvider (ctx, callback) {
      if (!this.pageVisible) {
        return []
      }
      let self = this
      self.isBusy = true
      setTimeout(function () {
        if (self.sortBy === null) {
          self.sortBy = ''
        }
        self.loadCount++
        var currentLoadCount = self.loadCount
        var items = []
        Salesidejob.getSalesidemobileTable(self.selectedShop != null ? self.selectedShop.id : -1, self.inboxCurrentPage, self.inboxPerPage, self.sortBy, self.sortDesc,
          self.showArchive, self.mobileJobNumberFilter, self.referralNumberFilter, self.lastNameFilter,
          self.firstNameFilter, self.jobNumberFilter, self.salesRepFilter, self.yearMakeModelFilter,
          self.scheduledDateFilter, self.unsentFilter, function (results) {
            if (currentLoadCount === self.loadCount) {
              var rows = results.rows
              if (rows && rows.length) {
                for (var i = 0; i < rows.length; i++) {
                  var row = rows[i]
                  items.push({
                    'mobileJobNumber': row['mobileJobNumber'],
                    'referralNumber': row['referralNumber'],
                    'lastName': row['lastName'],
                    'firstName': row['firstName'],
                    'jobNumber': row['jobNumber'],
                    'salesRep': row['salesRep'],
                    'yearMakeModel': row['yearMakeModel'],
                    'scheduledDate': row['scheduledDate'],
                    'status': row['status'],
                    'shopId': row['shopId'],
                    'pending_offline': row['pending_offline'],
                    'created': row['created'],
                    'sent': row['sent'],
                    'sentOn': row['sent_on']
                  })
                  if (row.sent === 0 || row.sent === null) {
                    self.unsentJobCount++
                  }
                }
              }
              self.inboxTotalRows = results.numberOfRowsFound
              if (self.mobileJobNumberFilter === '' && self.referralNumberFilter === '' && self.lastNameFilter === '' &&
                  self.firstNameFilter === '' && self.jobNumberFilter === '' && self.salesRepFilter === '' &&
                  self.yearMakeModelFilter === '' && self.scheduledDateFilter === '' && self.unsentFilter === false) {
                // setting this here to make sure that inbox total remains separate from filtered total
                self.inboxCountForDispaly = results.numberOfRowsFound
                self.inboxUnsentCountForDisplay = results.numberOfUnsentRowsFound
              }
              $('#mobileInboxModal').scrollTop(0)
              self.unreadShopMessages = []
              self.allShopMessages = []
              self.unreadMessagesCount = 0
              //
              ShopMessage.getShopMessages(self.selectedShop.id, function (messages) {
                for (var j = 0; j < messages.length; j++) {
                  if (messages[j].message_status === 'unread') {
                    self.allShopMessages.unshift({
                      'id': messages[j].id,
                      'mobileJobNumber': messages[j].salesidejob_id,
                      'jobNumber': (messages[j].job_id > -1) ? messages[j].job_id : '',
                      'salesRep': messages[j].sender,
                      'message': messages[j].message,
                      'message_status': messages[j].message_status,
                      'shopId': messages[j].shop_id,
                      'created': Moment((messages[j].created * 1000)).format('llll')
                    })
                    if (self.mobileJobNumberFilter === '' && self.referralNumberFilter === '' && self.lastNameFilter === '' &&
                        self.firstNameFilter === '' && self.jobNumberFilter === '' && self.salesRepFilter === '' &&
                        self.yearMakeModelFilter === '' && self.scheduledDateFilter === '' && self.unsentFilter === false) {
                      self.unreadShopMessages.unshift({
                        'id': messages[j].id,
                        'mobileJobNumber': messages[j].salesidejob_id,
                        'jobNumber': (messages[j].job_id > -1) ? messages[j].job_id : '',
                        'salesRep': messages[j].sender,
                        'message': messages[j].message,
                        'message_status': messages[j].message_status,
                        'shopId': messages[j].shop_id,
                        'created': Moment((messages[j].created * 1000)).format('llll'),
                        '_showDetails': true
                      })
                      items.unshift({
                        'id': messages[j].id,
                        'mobileJobNumber': messages[j].salesidejob_id,
                        'jobNumber': (messages[j].job_id > -1) ? messages[j].job_id : '',
                        'salesRep': messages[j].sender,
                        'message': messages[j].message,
                        'message_status': messages[j].message_status,
                        'shopId': messages[j].shop_id,
                        'created': Moment((messages[j].created * 1000)).format('llll'),
                        '_showDetails': true
                      })
                    } else {
                      self.unreadMessagesCount += 1
                    }
                  } else if (messages[j].message_status === 'read') {
                    self.allShopMessages.push({
                      'id': messages[j].id,
                      'mobileJobNumber': messages[j].salesidejob_id,
                      'jobNumber': (messages[j].job_id > -1) ? messages[j].job_id : '',
                      'salesRep': messages[j].sender,
                      'message': messages[j].message,
                      'message_status': messages[j].message_status,
                      'shopId': messages[j].shop_id,
                      'created': Moment((messages[j].created * 1000)).format('llll'),
                      '_rowVariant': 'light'
                    })
                  }
                }
                // sorting returned jobs and messages by date
                // items.sort(function (a, b) {
                //   return (Moment(a.created).format('X')) - (Moment(b.created).format('X'))
                // })
                // items.reverse()
                self.allShopMessages.sort(function (c, d) {
                  return (Moment(c.created).format('X')) - (Moment(d.created).format('X'))
                })
                self.allShopMessages.reverse()
                self.messagesTotalRows = self.allShopMessages.length
                self.isBusy = false
                callback(items)
              })
            } else {

            }
          }, function () {
            self.isBusy = false
            /* eslint-disable */
            callback([])
            /* eslint-enable */
          })
      }, 300)
      return null
    },
    truncateMessage (message) {
      if (message.length > 80) {
        let trunkMessage = message.substring(0, 80)
        trunkMessage += '...'
        return trunkMessage
      } else {
        return message
      }
    },
    messageClicked (message) {
      var self = this
      self.selectedMessage = message
      self.$root.$emit('bv::show::modal', 'shopMessageModal')
    },
    toggleMessageStatus (message) {
      // Update message status in db
      var self = this
      let newStatus = ''
      if (message.message_status === 'read') {
        newStatus = 'unread'
      } else if (message.message_status === 'unread') {
        newStatus = 'read'
      }
      ShopMessage.updateShopMessageStatus(message, newStatus, function (res) {
        if (self.currentInboxView === 'jobs') {
          // update jobs table
          self.$refs.mobileInboxTable.refresh()
        } else if (self.currentInboxView === 'messages') {
          // update messages table
          for (var m = 0; m < self.allShopMessages.length; m++) {
            if (self.allShopMessages[m].id === message.id) {
              // not sure what ctx is, just passing to fill the first parameter for myProvider
              self.myProvider('ctx', function () {

              })
            }
          }
        }
      })
      // this.isBusy = false
    },
    changeInboxView (newView) {
      this.mobileJobNumberFilter = ''
      this.referralNumberFilter = ''
      this.lastNameFilter = ''
      this.firstNameFilter = ''
      this.jobNumberFilter = ''
      this.salesRepFilter = ''
      this.yearMakeModelFilter = ''
      this.scheduledDateFilter = ''
      this.unsentFilter = false
      this.currentInboxView = newView
    },
    unreadCounter () {
      if (this.unreadShopMessages.length > 0) {
        return this.unreadShopMessages.length
      } else {
        return this.unreadMessagesCount
      }
    },
    viewUnsentJob (job) {
      this.$root.$emit('showMobileJob', job.mobileJobNumber, true)
      this.selectedUnsentJob = job
      this.visible = false
      // this.$root.$emit('bv::show::modal', 'Unsent Job')
    },
    toggleUnsentFilter () {
      if (this.currentInboxView !== 'jobs') {
        this.currentInboxView = 'jobs'
      }
      this.unsentFilter = !this.unsentFilter
    },
    closeModal () {
      this.visible = false
    },
    tabClicked: function(tab) {
      if (tab.label === 'Leads') {
        this.getLeads()
      }
    },
    getLeads: function() {
      if (this.$store.state.leads.filters.shopId) {
        this.$store.dispatch('leads/get')
      }
    }
  },
  components: {
    Multiselect,
    ShopMessageDialog,
    // SalesideJobDialog,
    Leads
  },
  mixins: [UtilityMixin]
}
</script>

<style lang='scss'>
#mobileInboxModal .modal-lg {
  min-width: 90%;
}
#mobileInboxModal .tableHolder {
  overflow:auto;
}
#mobileInboxModal .leftTd {
  text-align: left;
}
#mobileInboxModal .leftTh {
  text-align: left;
}

#mobileInboxModal .b-table-has-details {
  display: none !important;
}
.b-table-details td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.tableHolder2 table tbody tr {
  color: black;
  background-color:#21b7ff !important;
}
.emptyTable table tbody tr {
  background-color: white !important;
  color: black;
}
.tableHolder2 table tbody tr:hover {
  cursor: pointer;
}
.mobile-inbox-actions {
  .el-button-group .el-button--primary:first-child {
    padding: 1px 6px !important;
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
  }
  .el-button-group .el-dropdown__caret-button {
    padding: 1px 6px !important;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
  }
}
</style>

<style lang='scss' scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
.multiselect--active {
  z-index: 10;
}
.multiselect__option {
  padding: 0px;
}
.ttFilter:hover {
  cursor: pointer;
}
.shopMessageRow {
  background-color: #21b7ff;
  border-top: 1px solid #dee2e6;
  color: black;
  padding: 12px;
}
.shopMessageRow:hover {
  cursor: pointer;
}
.notification {
  position: absolute !important;
  // top: -4px;
  font-weight: bold !important;
  color: white !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important;
  border-radius: 25px !important;
  height: 20px !important;
  min-width: 20px !important;
  padding-top: 1px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  font-size: 0.9em !important;
  z-index: 1000 !important;
  &.blue {
    background: #007bff !important;
  }
  &.right {
    right: -4px !important;
  }
}
</style>
