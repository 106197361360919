<template>
  <div
    v-if="isOpen"
    class="t-flex-shrink-0 t-w-72 t-h-full t-border-solid t-border-0
           t-border-l t-border-gray-300">
    <div class="t-px-5 t-h-14 t-flex-shrink-0 t-border-solid t-border-0 t-border-b
              t-border-gray-300 t-flex t-items-center">
      <div class="t-flex-grow">Details</div>
      <i @click="closeInfoDrawer" class="el-icon-close t-cursor-pointer"/>
    </div>
    <div class="t-p-5">
      <div class="t-mb-3">
        <div class="t-text-gray-500 t-text-xs">Channel name</div>
        <div class="t-text-gray-700 t-text-base">
          {{ activeChannel.uniqueName }}
        </div>
      </div>
      <div class="t-mb-3">
        <div class="t-text-gray-500 t-text-xs">Channel type</div>
        <div class="t-text-gray-700 t-text-base">
          {{ activeChannel.isPrivate ? 'Private' : 'Public' }}
        </div>
      </div>
      <div class="t-mb-3">
        <div class="t-text-gray-500 t-text-xs">Created by</div>
        <div class="t-text-gray-700 t-text-base">
          {{ activeChannel.createdBy }}
        </div>
      </div>
      <div class="t-mb-3">
        <div class="t-text-gray-500 t-text-xs">Created on</div>
        <div class="t-text-gray-700 t-text-base">{{ creationDate }}</div>
      </div>
      <div class="t-mb-3">
        <div class="t-text-gray-500 t-text-xs">Total members</div>
        <div class="t-text-gray-700 t-text-base">
          {{ members.length }}
          member<span v-if="members.length > 1">s</span>
        </div>
      </div>
      <div>
        <div class="t-text-gray-500 t-text-xs">Total messages</div>
        <div class="t-text-gray-700 t-text-base">
          {{ totalMessagesCount }}
          message<span v-if="totalMessagesCount == 0 || totalMessagesCount > 1">s</span>
        </div>
      </div>
      <div v-if="activeChannel.createdBy !== 'system'" class="t-mt-6">
        <el-button
          v-if="activeChannel.createdBy === username"
          @click="deleteChannel"
          size="small"
          type="danger"
          :loading="deleting"
          icon="el-icon-delete"
        >
          Delete channel
        </el-button>
        <el-button
          v-else
          @click="leaveChannel"
          size="small"
          type="danger"
          :loading="leaving"
          icon="el-icon-document-delete"
        >
          Leave channel
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { chatViews } from '@/constants/chat.config'
import { ChatService } from '@/services/ChatService'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  data: () => ({
    deleting: false,
    leaving: false
  }),
  computed: {
    username: function() {
      return this.$store.state.user.username
    },
    isOpen: function() {
      return this.$store.state.chat.channelInfoDrawerIsOpen
    },
    activeChannel: function() {
      return this.$store.state.chat.activeChannel
    },
    creationDate: function() {
      return moment(this.activeChannel.dateCreated).format('LL')
    },
    members: function() {
      return this.$store.state.chat.members
    },
    totalMessagesCount: function() {
      return this.$store.state.chat.totalMessages
    }
  },
  watch: {
    activeChannel: function(value) {
      if (value && value.friendlyName === 'direct') {
        this.closeInfoDrawer()
      }
    }
  },
  methods: {
    closeInfoDrawer: function() {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.setChannelInfoDrawerIsOpen,
        false
      )
    },
    deleteChannel: async function() {
      try {
        await this.$messageBox.confirm(
          `Do you wish to delete the channel ${this.activeChannel.uniqueName} ?
          Deleting a channel means it cannot be retrieved at a later date for any reason.`,
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'error'
          }
        )
        this.loading = true
        const channelSid = this.activeChannel.sid
        const channel = await ChatService.getChannelBySid(channelSid)
        await channel.delete()
        this.$store.commit(
          chatTypes.namespace + chatTypes.mutations.deleteUserChannel,
          channel.uniqueName
        )
        this.$store.commit(
          chatTypes.namespace + chatTypes.mutations.setActiveChannel,
          null
        )
        this.$store.dispatch(
          chatTypes.namespace + chatTypes.actions.changeView,
          chatViews.welcome
        )
        ChatService.setActiveChannel(null)
        this.closeInfoDrawer()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    leaveChannel: async function() {
      try {
        await this.$messageBox.confirm(
          `Do you wish to leave the channel ${this.activeChannel.uniqueName} ?`,
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'error'
          }
        )
        this.leaving = true
        const channelSid = this.activeChannel.sid
        const channel = await ChatService.getChannelBySid(channelSid)
        await channel.leave()
        this.$store.commit(
          chatTypes.namespace + chatTypes.mutations.deleteUserChannel,
          channel.uniqueName
        )
        this.$store.commit(
          chatTypes.namespace + chatTypes.mutations.setActiveChannel,
          null
        )
        this.$store.dispatch(
          chatTypes.namespace + chatTypes.actions.changeView,
          chatViews.welcome
        )
        ChatService.setActiveChannel(null)
        this.closeInfoDrawer()
        this.leaving = false
      } catch (e) {
        this.leaving = false
      }
    }
  }
}
</script>
