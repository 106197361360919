import * as R from 'ramda'
import {
  partSearchHardwareConfig,
  partTypeConfig
} from '@/constants/nags-catalog.config.js'
import { toNum } from './totals.helpers'
import { v4 } from 'uuid'

export const sortHardware = group => R.filter(R.propEq('typeId', group))
const shouldDefaultMfCode = p => {
  if (p?.name === 'Recalibration Service') return false
  if (p?.name === 'labor') return false
  if (p?.partno === 'TINTING') return false
  if (p?.partno === 'WSREP') return false
  if (p?.partno === 'CUST') return false
  return true
}
export const getHardwareTypes = R.pipe(
  R.map(R.prop('typeId')),
  R.uniq,
  R.sortBy(v => R.indexOf(v)(R.keys(partSearchHardwareConfig))),
  R.pick(R.__, partSearchHardwareConfig)
)

export const getPartType = part =>
  R.reduceWhile(
    R.isNil,
    (curr, type) => (!type.key || part[type.key] === '1' ? type : null),
    null
  )(partTypeConfig)

export const getPartTypeKey = type =>
  R.pipe(R.find(R.propEq('type', type)), R.prop('key'))(partTypeConfig)

export const nagsToPart = (key, extra = {}) =>
  R.pipe(
    R.applySpec({
      description: R.prop('name'),
      part_number: R.prop('partno'),
      list_price: R.pipe(R.propOr(0, 'price'), toNum),
      hours: R.pipe(R.propOr(0, 'labor'), toNum),
      quantity: R.pipe(R.propOr(1, 'qty'), toNum),
      nagsid: R.prop('partno'),
      mf_code: R.ifElse(
        shouldDefaultMfCode,
        R.ifElse(
          R.pipe(R.prop('mf_code'), R.isNil),
          R.pipe(R.path(['options', 'mf_code']), R.defaultTo('NAG')),
          R.pipe(R.prop('mf_code'), R.defaultTo('NAG'))
        ),
        () => ''
      )
    }),
    R.merge({
      cost: 0,
      discount: 0,
      __typename: 'Part',
      is_adhesive_kit_part: '0',
      is_chip: '0',
      is_custom_part: '0',
      is_glass: '0',
      is_inventory_part: 0,
      is_oem: '0',
      is_recal: '0',
      is_premium: '0',
      labor_price: 0,
      materials_price: 0,
      moulding_discount: '0',
      po_quantity: 1,
      total_price: 0,
      order: 0,
      order_status: null,
      shop_id: null,
      flat: 0,
      ...(key ? getFlags(key) : {}),
      id: v4()
    }),
    R.mergeDeepLeft(extra)
  )

export const getVehicleName = R.pipe(
  R.pick(['year', 'make', 'model']),
  R.values,
  R.join(' ')
)

const getFlags = flag => {
  if (!Array.isArray(flag)) {
    return { [flag]: '1' }
  } else if (Array.isArray(flag)) {
    const flags = {}
    flag.forEach(v => {
      flags[v] = '1'
    })
    return flags
  }
  return {}
}
