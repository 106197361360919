<template>
  <div class="document-tag">
    <md-label-tag :icon="doc.icon || 'insert_drive_file'" :color="doc.color">
      {{ tag }} {{ number }}
    </md-label-tag>
  </div>
</template>

<script>
export default {
  props: {
    doc: {
      default: () => ({})
    },
    number: {}
  },
  computed: {
    tag () {
      return this.doc?.document_type?.toUpperCase() || 'OTHER'
    }
  }
}
</script>