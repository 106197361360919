<template>
  <ul class="" style="width:100%" v-loading="loading">
    <template v-for="(suggestion, index) in value">
      <li :key='index'
        :ref="'autoComplete:' + index"
        class="autocomplete-result"
        v-on:mouseover="mouseOver(index)"
        v-bind:class="{'is-active': isActive(index)}"
        @mousedown="suggestionClick(index)">
        <a href="javascript:void(0)" style="text-decoration: none;" v-bind:class="{'white-text': isActive(index)}">
          <div class="label-ssjob">
            <div :class="{ 'suggestion-info-block': true, strong: true }">
              <span>{{suggestion.shop_id}}-{{suggestion.id}}</span>
            </div>
            <div class="secondary">
              {{getJobCustomerName(suggestion)}} 
              {{getJobCustomerPhone(suggestion)}} 
            </div>
          </div>
        </a>
      </li>
    </template>
  </ul>
</template>

<script>

export default {
  props: ['value', 'loading'],
  data () {
    return {
      current: 0
    }
  },
  methods: {
    mouseOver: function (index) {
      this.current = index
    },
    isActive (index) {
      return index === this.current
    },
    suggestionClick (index) {
      this.$emit('selected', this.value[index].id, 'salesidejob')
    },
    getJobStatusClass (status) {
      return {
        'label-job-invoice': status === 'invoice',
        'label-job-workOrder': status === 'workOrder',
        'label-job-quote': status === 'quote',
        'label-job-warranty': (status === 'warranty' || status === 'warrantyComplete'),
        'label-job-void': status === 'void'
      }
    },
    getJobCustomerName (suggestion) {
      if (suggestion.consumer_id) {
        if (suggestion.consumer.is_commercial) {
          let companyName = suggestion.consumer.company_name
          if (suggestion.consumer?.full_name?.first) {
            companyName += ` (contact: ${suggestion.consumer.full_name.first}`
            if (suggestion.consumer?.full_name?.last) {
              companyName += ` ${suggestion.consumer.full_name.last})`
            } else {
              companyName += `)`
            }
          }
          return companyName
        }
        return suggestion.consumer.full_name.first + ' ' + suggestion.consumer.full_name.last
      }
      return suggestion.customer_first_name + ' ' + suggestion.customer_last_name
    },
    getJobCustomerPhone (suggestion) {
      if (suggestion.consumer?.phone?.number) {
        return suggestion.consumer.phone.number
      }
      return suggestion.customer_phone1
    },
    getJobDetails ({internal_number, policy_number, purchase_order_number, referral_number, unit_number}) {
      let detailsString = ''
      if (internal_number != '' && internal_number != null) {
        detailsString += `Internal: ${internal_number} `
      }
      if (policy_number != '' && policy_number != null) [
        detailsString += `Policy: ${policy_number} `
      ]
      if (purchase_order_number != '' && purchase_order_number != null) {
        detailsString += `PO: ${purchase_order_number} `
      }
      if (referral_number != '' && referral_number != null) {
        detailsString += `Referral: ${referral_number} `
      }
      if (unit_number != '' && unit_number != null) {
        detailsString += `Unit: ${unit_number}`
      }
      return detailsString
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "GlobalSearch.scss";
</style>
