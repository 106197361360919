var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "consumer-type-select",
      on: {
        mouseenter: function($event) {
          _vm.hover = true
        },
        mouseleave: function($event) {
          _vm.hover = false
          _vm.firstType = _vm.selectedType
        }
      }
    },
    [
      _vm._l(_vm.types, function(type) {
        return _c(
          "gb-tag",
          {
            key: type.key,
            attrs: {
              color: type.color,
              hidden: !_vm.hover && _vm.selectedType !== type.key,
              outline: _vm.selectedType !== type.key
            },
            nativeOn: {
              click: function($event) {
                _vm.selectedType = type.key
              }
            }
          },
          [_vm._v(" " + _vm._s(type.key) + " ")]
        )
      }),
      _vm._l(_vm.toggles, function(toggle) {
        return _c(
          "gb-tag",
          {
            key: toggle.key,
            attrs: {
              hidden: !_vm.hover && _vm.hiddenCheck(toggle.key),
              outline: _vm.hiddenCheck(toggle.key),
              color: toggle.color
            },
            nativeOn: {
              click: function($event) {
                return _vm.toggleValue(toggle.key)
              }
            }
          },
          [_vm._v(" " + _vm._s(toggle.label) + " ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }