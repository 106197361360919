<template>
  <div
    class="editable-data-row"
    @keydown="keydown"
    :class="{ editing, 'edi-action-locked': $attrs.locked, condensed }"
  >
    <input-with-label
      v-if="editing"
      :label="$attrs.label"
      :autoFocus="true"
      v-model="value"
      @blur="blur"
      v-bind="{ ...$attrs.input, ...$attrs, size }"
      :lazy="$attrs.type === 'active'"
      @lazyValue="lazyValue"
    >
      <span v-if="$attrs.input && $attrs.input.prefix" slot="prefix">
        {{ $attrs.input.prefix }}
      </span>
      <span v-if="$attrs.input && $attrs.input.suffix" slot="suffix">
        {{ $attrs.input.suffix }}
      </span>
    </input-with-label>
    <div class="data-row" v-else @click="setEdit" :link="$attrs.link">
      <gb-data-row
        v-bind="{ ...$attrs, ...$attrs.display }"
        :label="hideLabel ? null : $attrs.label"
        :editable="$attrs.type !== 'date' || $attrs.select"
        @focus="setEdit"
      />
    </div>
    <el-date-picker
      v-if="$attrs.type === 'date'"
      v-model="date"
      @focus="dateFocus"
    />
    <div
      class="clear-data-row"
      @click.stop="$emit('edit', { key: $attrs.id, value: null })"
      v-if="($attrs.clearable || $attrs.type === 'date') && $attrs.value"
    >
      <i class="material-icons md-18 md-clear label-icon row-clear-icon" />
    </div>
    <span v-if="$attrs.id === 'referral_number' && $attrs.value && $attrs.value.length > 0 && !editing"
      @click="copy" class="referral-copy"
    >
      <i
        class="el-icon-copy-document t-mr-1
          t-cursor-pointer hover:t-text-blue-500"
      />
    </span>
    <el-select v-if="$attrs.select" v-model="select" @focus="dateFocus">
      <el-option
        v-for="item in $attrs.select.options"
        :key="item.value"
        :value="item.value"
        :label="item.label"
      />
    </el-select>
  </div>
</template>

<script>
import { formatToDb, parseDate } from '@/scripts/helpers/date.helpers'
import * as R from 'ramda'

export default {
  data() {
    return {
      editing: false,
      value: null
    }
  },
  props: {
    onEdit: {},
    hideLabel: {},
    size: { type: String, default: () => 'normal' },
    disabled: Boolean,
    condensed: Boolean
  },
  computed: {
    date: {
      get() {
        return this.value && parseDate(this.value)
      },
      set(v) {
        this.$emit('edit', { key: this.$attrs.id, value: formatToDb(v) })
      }
    },
    select: {
      get() {
        return R.pipe(
          R.propOr([], 'options'),
          R.find(R.propEq('value', this.value)),
          R.prop('label')
        )(this.select)
      },
      set(v) {
        this.$emit('edit', v)
      }
    }
  },
  methods: {
    blur(v) {
      this.$emit('edit', { key: this.$attrs.id, value: v })
      this.editing = false
    },
    keydown({ keyCode }) {
      if (keyCode === 13 && this.$attrs.type !== 'textarea') {
        this.$emit('edit', { key: this.$attrs.id, value: this.value })
        this.editing = false
      }
    },
    setEdit() {
      if (!this.disabled) {
        this.$emit('start-edit')
        this.editing = true
      }
    },
    dateFocus(v) {
      if (!this.$attrs.value) {
        this.date = new Date()
      }
    },
    lazyValue(v) {
      if (this.$attrs.type === 'active') {
        this.$emit('lazyValue', v)
      }
    },
    async copy() {
      await navigator.clipboard.writeText(this.$attrs.value)
      this.$message.success('Copied!')
    }
  },
  watch: {
    editing(editing) {
      if (editing) {
        this.value = this.$attrs.value
      }
    },
    '$attrs.value'(v) {
      if (this.$attrs.type === 'date' && v === '0000-00-00') {
        this.$emit('edit', { key: this.$attrs.id, value: null })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.editable-data-row {
  position: relative;
  &.editing {
    margin-bottom: $padding-md;
  }
  .data-row {
    cursor: pointer;
    margin-bottom: 1px;
  }
  .el-date-editor {
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }
}
</style>

<style lang="scss">
.editable-data-row {
  .el-date-editor {
    input {
      height: 100%;
      cursor: pointer;
    }
  }
  &.condensed {
    .data-row-value {
      margin-bottom: 0;
      font-size: $font-sm;
    }
    .data-row-label {
      margin-bottom: 0;
    }
  }
  .data-row-label {
    .row-clear-icon {
      display: none;
    }
  }
  .clear-data-row {
    display: none;
    position: absolute;
    top: 0;
    right: 2px;
    color: #999;
    z-index: 10;
    i {
      font-size: 12px;
      line-height: 12px;
      display: block;
      cursor: pointer;
    }
    &:hover {
      color: $crimson-red;
    }
  }
  &:hover {
    .clear-data-row {
      display: block;
    }
  }
  .referral-copy {
    position: absolute;
    top: 19px;
    right: 2px;
    color: #999;
    z-index: 10;
    pointer-events: auto;
    i {
      font-size: 15px;
      line-height: 12px;
      display: block;
      cursor: pointer;
    }
    &:hover {
      color: $crimson-red;
    }
  }
}
</style>
