<template>
  <div
    class="input-with-label v-input"
    :class="{
      focused: focused || forceFocus,
      'has-value': value,
      error,
      emptyRed,
      valid,
      right: align === 'right',
      'with-address-input': type === 'address',
      'with-time-slot': type === 'time',
      ...(size ? { [size]: true } : {}),
      locked,
    }"
  >
    <div class="sticky-label" v-if="displayLabel">
      <div :style="{'background-color': 'white', 'margin-top': (tooltip && tooltip.length > 0) ? '2px' : '0px'}">
      {{ label }}
      </div>
      <el-tooltip v-if="tooltip && tooltip.length > 0" :content="tooltip" raw-content placement="top" popper-class="inputLabelToolTip">
        <i class="el-icon-info t-text-xs"></i>
      </el-tooltip>
      <span class="asterisk">*</span>
      <div
        class="input-edit"
        v-if="value && $listeners.edit"
        @click="$emit('edit')"
      >
        <i class="material-icons md-edit edit-icon" /> <span class='edit-text'>Edit</span>
      </div>
    </div>

    <address-input
      v-model="param"
      v-if="type === 'address'"
      v-bind="options"
      @focus="onFocus"
      @blur="onBlur"
      @change="onChange"
      @clear="$emit('input', null)"
      :disabled="disabled"
      loose
    >
      <slot name="prefix" slot="prefix" />
    </address-input>

    <el-date-picker
      v-else-if="type === 'date'"
      ref="inputRef"
      v-model="param"
      :size="size || 'small'"
      :placeholder="placeholder"
      @focus="onFocus"
      @blur="onBlur"
      @clear="this.$emit('input', null)"
    />
    <select-wrapper
      ref="inputRef"
      v-else-if="select"
      v-bind="select"
      :label="label"
      :multiple="multiple"
      :collapseTags="collapseTags"
      :withSelectAll="withSelectAll"
      :size="size || 'small'"
      :labelKey="labelKey"
      v-model="param"
      @change="$emit('change', $event)"
      @clear="$emit('clear')"
      @option-change="$emit('option-change', $event)"
      @focus="onFocus"
      @blur="onBlur"
      :with-add="!!$listeners.add"
      :with-disabled="!!$listeners.disabled"
      :show-disabled="showDisabled"
      :is-vendor-disabled="isVendorDisabled"
      @add="$emit('add')"
      @disabled="$emit('disabled')"
      :disabled="disabled"
    >
      <i
        class="material-icons md-18 md-lock lock-icon"
        v-if="locked"
        slot="prefix"
      />
      <slot name="prefix" slot="prefix" v-else />
      <slot
        name="option"
        slot="option"
        slot-scope="item"
        v-bind="item"
        v-if="$scopedSlots.option"
      />
    </select-wrapper>
    <kit-select
      v-else-if="type === 'kit-select'"
      v-model="param"
      @focus="onFocus"
      :allowNegative="allowNegative"
      @blur="onBlur"
      @change="$emit('change', $event)"
      ref="inputRef"
      :size="size || 'small'"
    >
      <slot name="prefix" slot="prefix" />
    </kit-select>
    <number-input
      v-else-if="type === 'money'"
      @focus="onFocus"
      @blur="onBlur"
      @tab="onTab"
      ref="inputRef"
      v-model="param"
      :size="size || 'small'"
      :disabled="disabled"
      :allowNegative="allowNegative"
      :placeholder="placeholder"
      :precision="precision"
    >
      <slot name="append" slot="append" />
      <i
        class="material-icons md-18 md-lock lock-icon"
        v-if="locked"
        slot="prefix"
      />
      <slot name="prefix" slot="prefix" v-else />
      <slot name="suffix" slot="suffix" />
      <slot name="prepend" slot="prepend" />
      <i
        class="material-icons md-18 md-attach_money"
        slot="prefix"
        v-if="dollar"
      />
    </number-input>
    <el-input
      v-else-if="type === 'phone'"
      @focus="onFocus"
      @blur="onBlur"
      :size="size || 'small'"
      :placeholder="placeholder"
      v-model="phone"
      ref="inputRef"
      :disabled="disabled"
    >
      <slot name="prefix" slot="prefix" />
    </el-input>
    <el-autocomplete
      v-else-if="type === 'autocomplete'"
      @focus="onFocus"
      @blur="onBlur"
      :size="size || 'small'"
      :placeholder="placeholder"
      v-model="param"
      :disabled="disabled"
      v-bind="options"
      @select="$emit('select', $event)"
    >
      <slot name="append" slot="append" />

      <template slot-scope="{ item }">
        <slot name="result" v-bind="item" />
      </template>
    </el-autocomplete>
    <time-slot-picker
      @focus="onFocus"
      @blur="onBlur"
      v-else-if="type === 'time'"
      :size="size || 'small'"
      :label="label"
      v-model="param"
      :disabled="disabled"
      ref="inputRef"
    >
      <slot name="append" slot="append" />
    </time-slot-picker>
    <el-input
      v-else-if="type === 'textarea'"
      @focus="onFocus"
      @blur="onBlur"
      type="textarea"
      :size="size || 'small'"
      :placeholder="placeholder"
      v-model="param"
      :disabled="disabled"
      @clear="$emit('clear')"
      v-bind="options"
      :maxlength="maxlength"
      ref="inputRef"
      @change="onChange"
      :tabindex="noTab ? '-1' : '0'"
    >
    </el-input>
    <gb-vin-input
      v-model="param"
      v-else-if="type === 'vin'"
      ref="inputRef"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
    />
    <email-input
      v-model="param"
      v-else-if="type === 'email'"
      :placeholder="placeholder"
      ref="inputRef"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
    />
    <el-input
      v-else
      @focus="onFocus"
      @blur="onBlur"
      :size="size || 'small'"
      :placeholder="placeholder"
      :maxlength="maxlength"
      v-model="param"
      :disabled="disabled"
      v-mask="mask"
      @clear="$emit('clear')"
      v-bind="options"
      ref="inputRef"
      :class="classes"
      :readOnly="readOnly"
      @change="onChange"
    >
      <slot name="prefix" slot="prefix" />
      <slot name="append" slot="append" />
      <slot name="prepend" slot="prepend" />
      <slot name="suffix" slot="suffix" />
    </el-input>
    <div class="error" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import AddressInput from '../AddressInput'
import EmailInput from './EmailInput'
import SelectWrapper from './SelectWrapper'
import NumberInput from './NumberInput'
import TimeSlotPicker from './TimeSlotPicker'
import { formatToDb } from '@/scripts/helpers/date.helpers'
import KitSelect from './KitSelect'
import { maskJs } from 'mask-js'
import * as R from 'ramda'

export default {
  components: {
    AddressInput,
    SelectWrapper,
    KitSelect,
    TimeSlotPicker,
    NumberInput,
    EmailInput
  },
  data() {
    return {
      focused: false,
      lazyValue: null
    }
  },
  props: {
    label: {},
    value: {},
    mask: {
      default: () => ({
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ }
        }
      })
    },
    type: {},
    error: String,
    valid: [Boolean, Number],
    size: {},
    select: {},
    time: {},
    disabled: Boolean,
    align: {},
    group: {},
    options: {},
    required: Boolean,
    forceFocus: Boolean,
    some: {},
    autoFocus: Boolean,
    hideLabel: Boolean,
    dollar: Boolean,
    lazy: Boolean,
    noTab: Boolean,
    locked: Boolean,
    multiple: Boolean,
    collapseTags: Boolean,
    labelKey: String,
    withSelectAll: Boolean,
    classes: String,
    readOnly: Boolean,
    allowNegative: Boolean,
    precision: Number,
    showDisabled: Boolean,
    isVendorDisabled: Boolean,
    tooltip: String,
    maxlength: Number,
    redIfEmpty: Boolean
  },
  computed: {
    param: {
      get() {
        return this.lazy ? this.lazyValue : this.value
      },
      set(value) {
        let newValue = value
        if (this.type === 'date' && value) {
          newValue = formatToDb(value)
        }
        if (this.options && this.options.capitalize) {
          newValue = newValue.toUpperCase()
        }
        if (this.lazy) {
          this.lazyValue = newValue
          this.$emit('lazyValue', newValue)
        } else {
          this.$emit('input', newValue)
        }
      }
    },
    number() {
      return (+this.value || 0).toFixed(2)
    },
    displayLabel() {
      return (
        (this.value ||
          this.focused ||
          this.forceFocus ||
          this.type === 'kit-select' ||
          this.type === 'address' ||
          this.type === 'vin') &&
        !this.hideLabel
      )
    },
    phone: {
      get() {
        return maskJs('(999) 999-9999', this.value)
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    placeholder() {
      return !(this.focused || this.forceFocus) ? this.label : null
    },
    emptyRed () {
      return this.redIfEmpty && (!this.param || (this.param + '').length === 0)
    }
  },
  methods: {
    onBlur({ target, value }) {
      this.focused = false
      this.$emit('blur', value || target.value)
      if (this.value !== this.lazyValue) {
        // Safari browser does not automatically trigger onChange event, this handles that
        this.onChange()
      }
      // setTimeout()
    },
    onFocus({ target, value }) {
      this.focused = true
      this.$emit('focus', value || target?.value)
    },
    onChange() {
      if (this.lazy) {
        this.$emit('input', this.lazyValue)
      }
    },
    onTab() {
      this.$emit('tab')
    }
  },
  watch: {
    value(v) {
      if (this.lazy) {
        this.lazyValue = R.clone(v)
      }
    }
  },
  mounted() {
    if (this.lazy) {
      this.lazyValue = this.value
    }
    if (this.autoFocus) {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.inputRef?.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./InputWithLabel.scss";
</style>
<style lang="scss">
.input-with-label.v-input {
  &.focused {
    & {
      .el-input__inner,
      .el-textarea__inner {
        border-color: $primary;
      }
      .icon,
      .material-icons {
        color: $primary;
      }
    }
  }
  .el-input--normal .el-input__inner {
    height: 38px;
  }
  &.error {
    & {
      .el-input__inner,
      .el-textarea__inner {
        border-color: $danger;
      }
      .icon,
      .material-icons {
        color: $danger;
      }
    }
    .sticky-label {
      color: $danger;
    }
  }
  &.emptyRed {
    & {
      .el-input__inner,
      .el-textarea__inner {
        border-color: $danger;
      }
      .icon,
      .material-icons {
        color: $danger;
      }
    }
    .sticky-label {
      color: $danger;
    }
  }
  &.valid {
    & {
      .el-input__inner,
      .el-textarea__inner {
        border-color: $success;
      }
      .icon,
      .material-icons {
        color: $success;
      }
    }
    .sticky-label {
      color: $success;
    }
  }
  .el-date-editor {
    width: 132px;
  }
  .el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  .el-textarea.is-disabled .el-textarea__inner {
    color: #707277;
    -webkit-text-fill-color: rgba(0, 0, 0, 1);
    -webkit-opacity: 1;
    opacity: 1;
  }

  &.right {
    .el-input__inner {
      text-align: right;
    }
  }
  &:not(.with-address-input, .with-time-slot) {
    .el-date-editor {
      .el-input__inner {
        padding-left: 30px;
      }
    }
  }
  .address-input {
    .address-unit {
      .el-input__inner {
        padding-left: 22px;
      }
      .el-input__prefix {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  .icon,
  .material-icons {
    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .el-date-editor {
    width: 100%;
  }
  .address-input {
    .address-autocomplete {
      .el-input--prefix {
        input {
          padding-left: 30px;
        }
      }
      .el-input__prefix {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }
  .el-input__prefix {
    display: flex;
    align-items: center;
  }
  .el-input--prefix:not(.el-date-editor) {
    .el-input__inner {
      padding-left: 22px;
    }
  }
  .el-textarea__inner {
    padding-top: $padding-sm;
    padding-bottom: $padding-sm;
  }
}
.inputLabelToolTip {
  width: 200px;
}
</style>
