var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-footer": "",
        title: "Part Search",
        id:
          _vm.modalId && _vm.modalId.length > 0
            ? _vm.modalId
            : "singlePartSearchModal",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg"
      },
      on: { hide: _vm.dialogAboutToDisappear }
    },
    [
      _c("PartSearch", {
        attrs: {
          isSinglePartSearchModal: true,
          shop: _vm.shop,
          startInputValue: _vm.startInputValue
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }