<template>
  <el-form-item v-if="values" class="mb-0">
    <span slot="label" class="undo-label-styles right-part-for-label">{{ name }}:</span>
    <span>
      <span v-for="value in values" class="detail-tag" :style="{
        background: background,
        color: color
        }">{{ value }}
      </span>
    </span>
  </el-form-item>
</template>

<script>
export default {
  props: ['name', 'values', 'color', 'background']
}
</script>
<style lang="scss" scoped>
.detail-tag {
  font-size: $font-md;
  line-height: 17px;
  color: #fff;
  background: $air-force-blue;
  border-radius: 20px;
  padding: 0 $padding-xs;
  letter-spacing: 0.1em;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  height: 23px;
  position: relative;
  white-space: nowrap;
  & ~ .detail-tag {
    margin-left: $padding-xs;
  }
  .el-icon-loading {
    color: #FFF;
  }
  span {
    color: #fff;
  }
  &.with-click {
    cursor: pointer;
    /* transition: box-shadow 0.2s ease-in-out; */
    /* &:hover {
      box-shadow: 0 0 3px 0 currentColor;
    } */
  }
  .trigger {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    overflow: hidden;
  }

  &:active {
    filter: brightness(95%);
  }
  i.md-playlist_add {
    margin-left: -24px;
    opacity: 0.4;
  }
  &:hover {
    i.md-playlist_add {
      margin-left: 0;
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
    filter: brightness(95%);
  }
}
</style>
