import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class CSRDefaults extends ObjectTracker {
  id = -1
  user_id = -1
  csr_id = -1
  gb_csr_id = -1
  shop_id = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getCSRDefaults (userId, callbackFunction) {
    if (userId > 0) {
      Api({ url: `/api/user/${userId}/csrdefaults` }).then(res => {
        var defaults = []
        if (res.data) {
          for (var i = 0; i < res.data.length; i++) {
            defaults.push(new CSRDefaults(res.data[i]))
          }
        }
        callbackFunction(defaults)
      })
    } else {
      callbackFunction([])
    }
  }
}
