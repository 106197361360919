<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    hide-required-asterisk
    label-width="150px"
    size="mini"
    class="t-mt-4 xl:t-w-1/2"
  >
    <el-form-item class="t-mb-3 t-mt-6">
      <div class="t-text-gray-700 t-text-base">
        {{ !credentials ? 'Add' : 'Update' }} account number
      </div>
    </el-form-item>
    <el-form-item prop="name">
      <span slot="label" class="undo-label-styles">Name</span>
        <el-input
        placeholder="Enter name"
        v-model="form.name"
      />
    </el-form-item>
    <el-form-item prop="number">
      <span slot="label" class="undo-label-styles">Account number</span>
      <el-input
        placeholder="Enter account number"
        v-model="form.number"
      />
    </el-form-item>
    <el-form-item class="t-mb-0">
      <el-button
        class="t-mb-2 t-mr-2"
        type="primary"
        @click="submit"
        :loading="loading"
      >
        {{ !credentials ? 'Add' : 'Update' }}
      </el-button>
      <el-button
        class="t-ml-0"
        plain
        @click="close"
      >
        Close
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Shop from '@/scripts/objects/shop'

export default {
  props: [
    'vendor',
    'shopId',
    'credentials'
  ],
  data: () => ({
    form: {
      name: '',
      number: ''
    },
    rules: {
      number: { required: true, message: 'Enter an account number', trigger: 'change' }
    },
    loading: false
  }),
  created: function () {
    if (this.credentials) {
      this.form.name = this.credentials.name
      this.form.number = this.credentials.account_number
    }
  },
  methods: {
    close: function () {
      this.form.name = ''
      this.form.number = ''
      this.$emit('close')
    },
    submit: async function () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          if (!this.credentials) {
            await Shop.createVendorCredentials({
              shopId: this.shopId,
              vendor: this.vendor,
              name: this.form.name,
              accountNumber: this.form.number
            })
          } else {
            await Shop.updateVendorCredentials({
              id: this.credentials.id,
              name: this.form.name,
              accountNumber: this.form.number,
              shopId: this.shopId,
              vendor: this.vendor
            })
          }
          const credentials = await Shop.getVendorCredentials({
            shopId: this.shopId
          })
          this.$emit('updateCredentials', credentials)
          this.close()
          this.loading = false
          this.$message.success('Account was ' + (!this.credentials ? 'added.' : 'updated.'))
        }
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>
