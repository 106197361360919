var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "claim-status-tag",
      class: { small: _vm.small, "pointer-tag": _vm.openDialog },
      style: { background: _vm.statusColor },
      on: { click: _vm.onClick }
    },
    [
      _vm._v(
        " " +
          _vm._s(_vm.includeClaimStatusText ? "Claim: " : "") +
          " " +
          _vm._s(_vm.status) +
          " "
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }