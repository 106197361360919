var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex t-flex-wrap" },
    [
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: {
          label: "ID",
          value: _vm.item.internalId ? _vm.item.internalId.toString() : ""
        }
      }),
      _c("div", { staticClass: "t-w-1/2 t-mb-3 t-pr-4" }, [
        _c("div", { staticClass: "t-font-medium t-text-gray-400 t-text-xs" }, [
          _vm._v("Color")
        ]),
        _c("div", {
          staticClass:
            "t-w-6 t-h-6 t-rounded-md t-shadow-sm t-border t-border-solid",
          style: "background-color: " + _vm.item.color
        })
      ]),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: { label: "Item number", value: _vm.item.number }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: { label: "Nags id", value: _vm.item.nagsId }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: { label: "Description", value: _vm.item.description }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: { label: "Zone", value: _vm.item.zone }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: {
          label: "List price",
          value: _vm.formatPrice(_vm.item.listPrice)
        }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: {
          label: "Retail List price",
          value: _vm.formatPrice(_vm.item.retailListPrice)
        }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: {
          label: "Retail material",
          value: _vm.formatPrice(_vm.item.retailMaterial)
        }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: {
          label: "Retail labor",
          value: _vm.formatPrice(_vm.item.retailLabor)
        }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: {
          label: "Retail Total",
          value: _vm.formatPrice(
            (parseFloat(_vm.item.retailLabor) || 0) +
              (parseFloat(_vm.item.retailMaterial) || 0)
          )
        }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: { label: "UPC", value: _vm.item.upc }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: { label: "SKU", value: _vm.item.sku }
      }),
      _c("InfoImagePiece", {
        staticClass: "t-w-1/2",
        attrs: { label: "Image", value: _vm.item.picture },
        on: {
          view: function($event) {
            return _vm.viewImage(_vm.item.picture)
          }
        }
      }),
      _c("InfoImagePiece", {
        staticClass: "t-w-1/2",
        attrs: { label: "QR code", value: _vm.item.qrCode },
        on: {
          view: function($event) {
            return _vm.viewImage(_vm.item.qrCode)
          }
        }
      }),
      _c("div", { staticClass: "t-w-1/2 t-mb-3 t-pr-4" }, [
        _c("div", { staticClass: "t-font-medium t-text-gray-400 t-text-xs" }, [
          _vm._v("Status")
        ]),
        _c("div", { staticClass: "t-mt-1" }, [
          _c(
            "span",
            {
              staticClass:
                "t-capitalize t-px-2 t-py-1 t-rounded t-text-xs t-text-white",
              class: _vm.item.isActive ? "t-bg-green-400" : "t-bg-red-400"
            },
            [
              _vm._v(
                " " + _vm._s(_vm.item.isActive ? "Active" : "Disabled") + " "
              )
            ]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "t-w-1/2 t-mb-3 t-pr-4" },
        [
          _c(
            "div",
            { staticClass: "t-font-medium t-text-gray-400 t-text-xs" },
            [_vm._v("Inv tracking")]
          ),
          _c("el-switch", {
            staticClass: "t-mt-1 item-catalog-dialog__info-switch",
            attrs: {
              value: _vm.item.isInventoryTracked,
              "active-color": "#34D399",
              "inactive-color": "#F87171"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t-w-1/2 t-mb-3 t-pr-4" },
        [
          _c(
            "div",
            { staticClass: "t-font-medium t-text-gray-400 t-text-xs" },
            [_vm._v("Auto Source No Buy")]
          ),
          _c("el-checkbox", {
            attrs: { disabled: "" },
            model: {
              value: _vm.item.autoSourceNoBuy,
              callback: function($$v) {
                _vm.$set(_vm.item, "autoSourceNoBuy", $$v)
              },
              expression: "item.autoSourceNoBuy"
            }
          })
        ],
        1
      ),
      _c("InfoPiece", {
        staticClass: "t-w-full",
        attrs: { label: "Notes", value: _vm.item.notes }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: {
          label: "Created by",
          value:
            _vm.item.createdByName + " on " + _vm.formatDate(_vm.item.createdAt)
        }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: {
          label: "Updated by",
          value:
            _vm.item.updatedByName + " on " + _vm.formatDate(_vm.item.updatedAt)
        }
      }),
      _c("ImagePreviewDialog", {
        attrs: { url: _vm.imagePreviewUrl },
        on: {
          close: function($event) {
            _vm.imagePreviewUrl = null
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }