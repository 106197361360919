<template>
  <div>
    <!-- Where this gets attached to the body it does not have this 
      before so we need it here to prevent google autofill -->
    <div style="height: 0px; width: 0px; overflow:hidden;">
      <input name="autofillEmailBlocker"/>
      <input name="autofillPasswordBlocker" type="password"/>
    </div>
    <el-dialog
      center
      append-to-body
      @closed="onClose"
      :close-on-click-modal="false"
      title="Text Signature Request"
      :visible.sync="visible"
      width="900px"
      custom-class="text-message-confirmation-dialog">
      <el-timeline v-if="(!gbForm || !gbForm.id) && signatureHistory.length > 0" style="margin: 10px 0 0 100px;">
        <el-timeline-item
          v-for="(activity, index) in signatureHistory"
          :key="index"
          :icon="activity.icon"
          :type="activity.type"
          :size="activity.size"
          :timestamp="activity.timestamp">
          {{ activity.name }}
        </el-timeline-item>
      </el-timeline>
      <el-timeline v-else-if="gbForm && gbForm.id && gbFormSmsSignatureHistory.length > 0" style="margin: 10px 0 0 100px;">
        <el-timeline-item
          v-for="(activity, index) in gbFormSmsSignatureHistory"
          :key="index"
          :type="gbFormLogType(activity.event_type.name)"
          :timestamp="gbFormLogDate(activity.created_at)">
          {{gbFormLogDescription(activity)}}
        </el-timeline-item>
      </el-timeline>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :hide-required-asterisk="true"
        label-width="140px"
        size="small">
        <el-form-item prop="shopPhoneNumber" v-if='shopPhoneNumbers.length'>
          <span slot="label" class="undo-label-styles">Shop phone</span>
          <el-select
            placeholder="Select shop phone number"
            v-model="form.shopPhoneNumber"
            style="width: 100%"
            @focus='focusedShopPhoneSelect'
            >
            <el-option
              v-for="item in shopPhoneNumbers"
              :label="generateLabel(item)"
              :key="item.id"
              :value="item.phone_number">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="customerPhoneNumber">
          <span slot="label" class="undo-label-styles">Customer phone</span>
          <el-select
            placeholder="Enter Custom number or Choose"
            v-model="formattedPhoneNumber"
            style="width: 100%"
            allow-create
            clearable
            default-first-option
            filterable>
            <el-option
              v-for="(item, key) in customerPhoneNumbers"
              :label="formatPhone(item.phone_number)"
              :key="key"
              :value="item.phone_number">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="selectedTemplate">
          <span slot="label" class="undo-label-styles">Template</span>
          <el-select
            clearable
            placeholder="Select text message template (optional)"
            v-model="selectedTemplateId"
            style="width: 100%">
            <el-option
              v-for="item in templates"
              :label="item.name"
              :key="item.id"
              :value="item.id">
            </el-option>
          </el-select>
          or <a href="javascript:void(0)" @click="manageTemplates">Manage text message templates</a>
        </el-form-item>
        <el-form-item prop="body">
          <span slot="label" class="undo-label-styles">Template body</span>
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 10}"
            placeholder="Text Body"
            v-model="form.body"
          />
        </el-form-item>
        <el-form-item style="word-break: break-word;">
          <p class="mt-3"><span style="color: #F56C6C;">NOTE:</span> A link will be generated and automatically added to the end of this message.</p>
        </el-form-item>
        <el-form-item>
          <el-button
            class="mb-2 mr-2"
            type="primary"
            @click="handleSubmit"
            :loading="loading">
            Send
          </el-button>
          <el-button
            class="ml-0"
            plain
            @click="visible = false">
            Close
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import Job from '@/scripts/objects/job'
import TwilioPhoneNumber from '@/scripts/objects/twilio_phone_number'
import TextMessageTemplate from '@/scripts/objects/text_message_template'
import actions from '@/store/modules/scheduler_v2/types'
import { camelCaseToTitle } from '@/scripts/helpers/utilities'
import { formatPhone } from '@/scripts/helpers/twilio.helpers'
import moment from 'moment'

export default {
  data: () => ({
    form: {
      shopPhoneNumber: '',
      customerPhoneNumber: '',
      body: ''
    },
    rules: {
      shopPhoneNumber: { required: true, message: 'Please select shop phone number', trigger: 'change' },
      customerPhoneNumber: { required: true, message: 'Please select customer phone number', trigger: 'change' },
      body: { required: true, message: 'Please enter a template body', trigger: 'change' }
    },
    selectedTemplateId: '',
    templates: [],
    shopPhoneNumbers: [],
    customerPhoneNumbers: [],
    loading: false,
    showMarketingMessage: true
  }),
  computed: {
    jobId: get('twilio/textMessages/jobSignature/jobId'),
    signatureLink: get('twilio/textMessages/jobSignature/signatureLink'),
    salesideJobId: get('twilio/textMessages/jobSignature/salesideJobId'),
    shopId: get('twilio/textMessages/jobSignature/shopId'),
    visible: sync('twilio/textMessages/jobSignature/visible'),
    signatureHistory: get('twilio/textMessages/jobSignature/formattedHistory'),
    signatureWasSentBefore: get('twilio/textMessages/jobSignature/signatureWasSentBefore'),
    gbForm: sync('jobDetails/gbFormData'),
    gbFormPreview: sync('jobDetails/actionDialogs@gbFormPreview'),
    localJobStatus () {
      if (this.jobStatus) {
        return this.camelCaseToTitle(this.jobStatus)
      }
      return ''
    },
    formattedPhoneNumber: {
      get () {
        if (this.form.customerPhoneNumber && !this.isCurrentCustomerPhone(this.form.customerPhoneNumber)) {
            this.form.customerPhoneNumber = this.form.customerPhoneNumber.length > 10 ? this.form.customerPhoneNumber.substr(0,10) : this.form.customerPhoneNumber
            let temp = ('' + this.form.customerPhoneNumber).replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})$/)
            
            temp = (!temp[2]) ? temp[1] : temp[1] + '-' + temp[2] + (temp[3] ? '-' + temp[3] : '')
            if (temp.length === 0 && this.form.customerPhoneNumber.length > 0) {
              return ''
            }
            return temp
        } else {
          return this.form.customerPhoneNumber
        }
      },
      set: function (changedVal) {
        var tempPhone = changedVal
        if (!this.isCurrentCustomerPhone(changedVal)) {
          tempPhone = tempPhone.replace(/\D+/g, '')
        }
        this.form.customerPhoneNumber = tempPhone
      }
    },
    gbFormSmsSignatureHistory () {
      // only return gbForm logs that relate to sms signature
      let smsSignatureHistory = []
      if (this.gbForm && this.gbForm.logs && this.gbForm.logs.length > 0) {
        for (var i = 0; i < this.gbForm.logs.length; i++) {
          if ([5, 10].includes(this.gbForm.logs[i].type_id)) {
            smsSignatureHistory.push(this.gbForm.logs[i])
          }
        }
      }
      return smsSignatureHistory
    },
    whatToSign () {
      if (this.gbForm && this.signatureLink && this.signatureLink.includes(`gb_form/${this.gbForm.id}`)) return 'form'
      if (this.$job.job.status === 'quote') return 'quote'
      if (this.$job.job.status === 'invoice') return 'invoice'
      return 'work order'
    }
  },
  watch: {
    visible: async function (value) {
      if (value) {
        this.showMarketingMessage = true
        this.clearJobSignatureHistory()
        this.getTemplates()
        this.form = Object.assign({}, {...this.form, body:`Thanks for your continued business! Please sign this digital copy of your ${this.whatToSign}.`})
        this.getShopPhoneNumbers()
        this.getCustomerPhoneNumbers()
        this.getJobSignatureHistory({ jobId: this.jobId, salesideJobId: this.salesideJobId })
      } else {
        await this.clearForm()
        this.$refs.form.clearValidate()
      }
    },
    selectedTemplateId: async function (value) {
      if (value !== '') {
        this.form.body = (await TextMessageTemplate.generateMessageBody(value, this.jobId)).body
      } else {
        this.form.body = ''
      }
    }
  },
  methods: {
    setTextMessageSignatureJobId: call('twilio/textMessages/jobSignature/setJobId'),
    getJobSignatureHistory: call('twilio/textMessages/jobSignature/getHistory'),
    clearJobSignatureHistory: call('twilio/textMessages/jobSignature/clearHistory'),
    openTextMessageTemplatesDialog: call('twilio/textMessages/templates/open'),
    setTextMessageTemplatesReferral: call('twilio/textMessages/templates/setReferral'),
    openTextMessageAdDialog: call('twilio/textMessages/ad/open'),
    setTextMessageAdReferral: call('twilio/textMessages/ad/setReferral'),
    formatPhone,
    isCurrentCustomerPhone (val) {
      for (var i = 0; i < this.customerPhoneNumbers.length; i++) {
        if (this.customerPhoneNumbers[i].phone_number.replace(/\D+/g, '') === val.replace(/\D+/g, '')) {
          return true
        }
      }
      return false
    },
    manageTemplates () {
      this.visible = false
      this.openTextMessageTemplatesDialog()
      this.setTextMessageTemplatesReferral('job-signature')
    },
    showAd () {
      this.visible = false
      this.openTextMessageAdDialog()
      this.setTextMessageAdReferral('job-signature')
    },
    clearForm () {
      this.selectedTemplateId = ''
      this.form.shopPhoneNumber = ''
      this.form.customerPhoneNumber = ''
      this.form.body = ''
    },
    async handleSubmit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          if (this.signatureWasSentBefore && Object.keys(this.gbForm).length === 0) {
            await this.$messageBox.confirm(
              'You already sent a signature request text message before for this job. Are you sure you want to send another one ?',
              'Warning',
              {
                type: 'warning',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                beforeClose: async (action, instance, done) => {
                  if (action === 'confirm') {
                    this.send()
                  }
                  done()
                }
              }
            )
          } else {
            this.send()
          }
        }
      } catch (err) {
        if (err.error === 'The message From/To pair violates a blacklist rule.') {
          this.$message.warning('Customer previously replied STOP to this number, so this message can\'t be delivered.')
        }
        this.loading = false
      }
    },
    async send () {
      const status = await TextMessageTemplate.sendSignature({
        jobId: this.jobId,
        gbForm: this.gbForm,
        body: this.form.body,
        signatureLink: this.signatureLink,
        shopPhoneNumber: this.form.shopPhoneNumber,
        customerPhoneNumber: this.form.customerPhoneNumber
      })
      this.visible = false
      this.loading = false
      this.getJobSignatureHistory({ jobId: this.jobId, salesideJobId: this.salesideJobId }) // need to get appropriate history
      this.$message.success(`Job ${this.localJobStatus} signature request link successfully sent.`)
      if (this.gbForm && Object.keys(this.gbForm).length > 0) {
        // Log gbForm event
        this.$unum.job().post('gbForm/log', {form: this.gbForm, action: 'sms_sign', to_phone: this.form.customerPhoneNumber})
      }
    },
    async getTemplates () {
      try {
        const {data} = await this.$unum.job().get(`textMessageTemplate/allByType/sms?shopId=${this.shopId}&type=5`)
        this.templates = data || []

        if (this.templates.length === 1) {
          this.selectedTemplateId = this.templates[0].id
          return
        }

        this.templates.forEach(template => {
          if (template.default === 1) {
            this.selectedTemplateId = template.id
          }
        })
      } catch {
        this.templates = []
      }
    },
    async getShopPhoneNumbers () {
      let shopPhoneNumbers = await TwilioPhoneNumber.getForJobMessaging(this.shopId)
      let defaultNumber = ''
      shopPhoneNumbers.forEach(phoneNumber => {
        if (phoneNumber.default) {
          defaultNumber = phoneNumber.phone_number
        }
      })
      if (defaultNumber === '' && shopPhoneNumbers.length > 0) {
        defaultNumber = shopPhoneNumbers[0].phone_number
      }

      if (shopPhoneNumbers.length) {
        this.shopPhoneNumbers = shopPhoneNumbers
        this.form.shopPhoneNumber = defaultNumber
      } else {
        this.shopPhoneNumbers = [{phone_number: '4803516401', name: 'Glassbiller Default'}]
        this.form.shopPhoneNumber = '4803516401'
      }
    },
    async getCustomerPhoneNumbers () {
      this.customerPhoneNumbers = await Job.getCustomerPhoneNumbers(this.jobId)
      this.customerPhoneNumbers.forEach(phone => {
        if (phone.default === 1) {
          this.form.customerPhoneNumber = phone.phone_number
        }
      })
    },
    generateLabel (i) {
      const number = `${i.phone_number.substr(0, 3)}-${i.phone_number.substr(3, 3)}-${i.phone_number.substr(6)}`
      let label = `${number} - ${i.name}`
      if (i.hasOwnProperty('type')) {
        label += ` ( ${i.type} )`
      }
      return label
    },
    focusedShopPhoneSelect () {
      if (this.shopPhoneNumbers.length === 1 && this.shopPhoneNumbers[0].phone_number === '4803516401' && this.showMarketingMessage) {
        this.showMarketingMessage = false
        this.showAd()
      }
    },
    gbFormLogDate(date) {
      return moment(date).format('LLLL')
    },
    gbFormLogDescription(log) {
      let description = `${log.user ? log.user.username : 'The Customer'} ${log.event_type.name}`

      if (log.to_phone) {
        description += ` to ${log.to_phone}`
      } else if (log.to_email) {
        description += ` to ${log.to_email}`
      }

      return description
    },
    gbFormLogType(eventName) {
      if (eventName === 'signed') return 'success'
      
      if (eventName.includes('signature')) return 'warning'
      
      return 'primary'
    },
    camelCaseToTitle,
    onClose() {
      if (!this.gbFormPreview) this.gbForm = {}
    }
  }
}
</script>

<style>
  .text-message-confirmation-dialog {
    @apply t-font-sans;
    max-width: 100vw;
  }
</style>
