var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vc-sketch-container",
      class: _vm.vc_sketch_open,
      style: {
        width: _vm.widthHeight > 0 ? _vm.widthHeight + "px" : "30px",
        height: _vm.widthHeight > 0 ? _vm.widthHeight + "px" : "30px"
      },
      attrs: { tabindex: "0" },
      on: {
        blur: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return (function(e) {
            return _vm.onBlur(e.relatedTarget)
          })($event)
        }
      }
    },
    [
      _c("div", {
        staticClass: "vc-sketch-box",
        style: { background: _vm.activeColor },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.togglePopover($event)
          }
        }
      }),
      _c("transition", { attrs: { name: "vc-sketch-show-hide" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen,
                expression: "isOpen"
              }
            ],
            class: [
              "vc-sketch",
              _vm.disableAlpha ? "vc-sketch__disable-alpha" : ""
            ],
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "vc-sketch-saturation-wrap" },
              [
                _c("saturation", {
                  on: { change: _vm.childChange },
                  model: {
                    value: _vm.colors,
                    callback: function($$v) {
                      _vm.colors = $$v
                    },
                    expression: "colors"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vc-sketch-controls" }, [
              _c("div", { staticClass: "vc-sketch-sliders" }, [
                _c(
                  "div",
                  { staticClass: "vc-sketch-hue-wrap" },
                  [
                    _c("hue", {
                      on: { change: _vm.childChange },
                      model: {
                        value: _vm.colors,
                        callback: function($$v) {
                          _vm.colors = $$v
                        },
                        expression: "colors"
                      }
                    })
                  ],
                  1
                ),
                !_vm.disableAlpha
                  ? _c(
                      "div",
                      { staticClass: "vc-sketch-alpha-wrap" },
                      [
                        _c("alpha", {
                          on: { change: _vm.childChange },
                          model: {
                            value: _vm.colors,
                            callback: function($$v) {
                              _vm.colors = $$v
                            },
                            expression: "colors"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "vc-sketch-color-wrap" },
                [
                  _c("div", {
                    staticClass: "vc-sketch-active-color",
                    style: { background: _vm.activeColor }
                  }),
                  _c("checkboard")
                ],
                1
              )
            ]),
            !_vm.disableFields
              ? _c("div", { staticClass: "vc-sketch-field" }, [
                  _c(
                    "div",
                    { staticClass: "vc-sketch-field--double" },
                    [
                      _c("ed-in", {
                        attrs: { label: "hex", value: _vm.hex },
                        on: { change: _vm.inputChange }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vc-sketch-field--single" },
                    [
                      _c("ed-in", {
                        attrs: { label: "r", value: _vm.colors.rgba.r },
                        on: { change: _vm.inputChange }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vc-sketch-field--single" },
                    [
                      _c("ed-in", {
                        attrs: { label: "g", value: _vm.colors.rgba.g },
                        on: { change: _vm.inputChange }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vc-sketch-field--single" },
                    [
                      _c("ed-in", {
                        attrs: { label: "b", value: _vm.colors.rgba.b },
                        on: { change: _vm.inputChange }
                      })
                    ],
                    1
                  ),
                  !_vm.disableAlpha
                    ? _c(
                        "div",
                        { staticClass: "vc-sketch-field--single" },
                        [
                          _c("ed-in", {
                            attrs: {
                              label: "a",
                              value: _vm.colors.a,
                              "arrow-offset": 0.01,
                              max: 1
                            },
                            on: { change: _vm.inputChange }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "vc-sketch-presets" },
              _vm._l(_vm.presetColors, function(c) {
                return _c("div", {
                  key: c,
                  staticClass: "vc-sketch-presets-color",
                  style: { background: c },
                  attrs: { "aria-label": "color:" + c },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handlePreset(c)
                    }
                  }
                })
              }),
              0
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }