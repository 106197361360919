<template>
  <el-select
    :value="value"
    multiple
    placeholder="Select technicians"
    class="tech-select"
    filterable
    value-key="id"
    @change="$emit('input', $event)"
  >
    <el-option
      v-for="item in techsWithDefaultParams"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </el-select>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  props: ['value'],
  computed: {
    techs: get('sch_v3/techs'),
    techsWithDefaultParams() {
      return this.techs.map(tech => ({
        ...tech,
        start_location: tech.g_address ? 'tech' : 'shop',
        end_location: tech.g_address ? 'tech' : null,
        shift_start: '08:00',
        shift_end: '18:00',
        min_visits: 0
      }))
    }
  }
}
</script>
