import Api from '@/services/Api'

export default class TwilioTextMessageThread {
  static async store (data) {
    const res = await Api.post('/api/twilioTextMessageThreads', data)
    return res
  }

  static async view (threadId) {
    const res = await Api.get('/api/twilioTextMessageThreads/' + threadId)
    return res
  }

  static async markUnread (threadId) {
    const res = await Api.put(`/api/twilioTextMessageThreads/${threadId}/markUnread`)
    return res
  }

  static async sendMessage (threadId, data) {
    const res = await Api.post(`/api/twilioTextMessageThreads/${threadId}/sendMessage`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return res
  }

  static async getForUser (shopPhoneNumber, recipientPhoneNumber, offset, limit) {
    const res = await Api.get('/api/twilioTextMessageThreads/getForUser', {
      params: {
        shopPhoneNumber: shopPhoneNumber,
        recipientPhoneNumber: recipientPhoneNumber,
        offset: offset,
        limit: limit
      }
    })
    return res
  }

  static async getUnreadThreadsCount () {
    const res = await Api.get('/api/twilioTextMessageThreads/getUnreadThreadsCount')
    return res.data
  }

  static async recipientSearch (query) {
    const { data } = await Api.get('/api/twilioTextMessageThreads/recipientSearch', {
      params: { q: query }
    })
    return data
  }
}
