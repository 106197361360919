var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          ref: "ref",
          staticClass: "select-wrapper",
          class: _vm.classes,
          attrs: {
            filterable: "",
            size: _vm.size,
            multiple: _vm.multiple,
            "collapse-tags": _vm.collapseTags,
            "value-key": _vm.byId ? null : _vm.valueKey || "id",
            placeholder: _vm.label,
            loading: _vm.loading || _vm.isLoading,
            clearable: _vm.clearable,
            "popper-class": _vm.withAdd ? "select-dropdown-with-new" : "",
            disabled: _vm.disabled,
            "filter-method": _vm.filterMethod,
            "allow-create": _vm.allowCreate,
            "default-first-option": _vm.defaultFirstOption,
            "popper-append-to-body": !_vm.popperDontAppendToBody
          },
          on: {
            focus: _vm.fetchOptions,
            change: _vm.onChange,
            clear: function($event) {
              return _vm.$emit("clear")
            },
            blur: function($event) {
              return _vm.$emit("blur", $event)
            }
          },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _vm.multiple && _vm.withSelectAll && _vm.items.length > 1
            ? _c(
                "div",
                {
                  class: {
                    "select-all-options": true,
                    "all-selected": _vm.allSelected
                  },
                  on: { click: _vm.selectDeselectAllClicked }
                },
                [
                  _c("i", { staticClass: "material-icons md-check md-18" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.allSelected ? "Deselect All" : "Select All") +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.withDisabled
            ? _c(
                "div",
                {
                  staticClass: "show-disabled",
                  on: { click: _vm.onDisabledClick }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.showDisabled ? "hide disabled" : "show disabled"
                      ) +
                      " "
                  ),
                  _vm.showDisabled
                    ? _c("i", {
                        staticClass: "el-icon-close",
                        staticStyle: { color: "red" }
                      })
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm.withAdd
            ? _c(
                "div",
                {
                  staticClass: "add-new-item",
                  class: { "no-data": !_vm.items.length },
                  attrs: { slot: _vm.items.length ? null : "empty" },
                  on: { click: _vm.onAddClick },
                  slot: _vm.items.length ? null : "empty"
                },
                [
                  _c("i", { staticClass: "material-icons md-add md-18" }),
                  _vm._v(" Add new ")
                ]
              )
            : _vm._e(),
          _vm._l(_vm.items, function(item) {
            return _c(
              "el-option",
              {
                key: item.id,
                class: {
                  "disabled-option": _vm.withDisabled && item.is_disabled
                },
                attrs: {
                  label: item[_vm.labelKey || "name"],
                  value: _vm.byId ? item[_vm.valueKey || "id"] : item
                }
              },
              [
                _vm.$scopedSlots.option
                  ? _vm._t("option", null, {
                      item: Object.assign({}, item, _vm.withDisabled)
                    })
                  : _vm._e()
              ],
              2
            )
          }),
          _vm._t("prefix", null, { slot: "prefix" })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }