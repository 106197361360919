import { make } from 'vuex-pathify'
import Vue from 'vue'

const state = {
  dialogs: {
    inbox: false,
    assignmentDetail: false,
    assignmentStart: false,
    claim: false,
    reviewCoverage: false
  },
  jobClaimStatuses: {},
  claim: {
    jobId: null
  },
  reviewCoverage: {
    claimId: null
  },
  loading: {
    job: false,
    shop: false,
    customer: false,
    billTo: false,
    vehicle: false
  }
}

const mutations = {
  ...make.mutations(state),
  setDialogsInbox(state, payload) {
    state.dialogs.inbox = payload
  },
  setDialogsAssignmentDetail(state, payload) {
    state.dialogs.assignmentDetail = payload
  },
  openClaimDialog(state, jobId) {
    state.dialogs.claim = true
    state.claim.jobId = jobId
  },
  openReviewCoverageDialog(state, claimId) {
    state.dialogs.reviewCoverage = true
    state.reviewCoverage.claimId = claimId
  },
  addUpdateJobClaimStatuses(state, jobStatusData) {
    Vue.set(state.jobClaimStatuses, jobStatusData.jobId, jobStatusData.status)
  }
}
const getters = {}

const actions = {
  openGlaxisDialog({commit}) {
    commit('setDialogsInbox', true)
  },
  closeGlaxisDialog({commit}) {
    commit('setDialogsInbox', false)
  },
  openAssignmentDetailDialog({commit}) {
    commit('setDialogsAssignmentDetail', true)
  },
  openClaimDialog({commit}, jobId) {
    commit('openClaimDialog', jobId)
  },
  openReviewCoverageDialog({commit}, claimId) {
    commit('openReviewCoverageDialog', claimId)
  },
  addUpdateJobClaimStatuses({commit}, jobStatusData) {
    commit('addUpdateJobClaimStatuses', jobStatusData)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
