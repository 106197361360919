<template>
  <b-container fluid class="scheduler-container">
    <scheduler-header />
    <scheduler-body v-if="isListView" v-loading="loading" />
    <scheduler-map v-else v-loading="loading" />
    <scheduler-job-details />
    <scheduler-route-entry-details />
    <tech-modal />
    <scheduler-pdf-dialog :tech="pdfTech" />
    <scheduler-waypoints-modal />
    <scheduler-notifications />
  </b-container>
</template>

<script>
import SchedulerHeader from './SchedulerHeader'
import SchedulerBody from './SchedulerBody'
import TechModal from '@/components/modals/TechDialog'
import SchedulerJobDetails from './SchedulerJobDetails'
import SchedulerNotifications from './helpers/SchedulerNotifications'
import SchedulerRouteEntryDetails from './SchedulerRouteEntryDetails'
import SchedulerMap from './SchedulerMap'
import SchedulerPdfDialog from '@/components/modals/SchedulerPdfDialog.vue'
import SchedulerWaypointsModal from './SchedulerWaypointsModal.vue'
import actions from '@/store/modules/scheduler_v3/types'
import { call, get, sync } from 'vuex-pathify'
import * as R from 'ramda'

export default {
  data() {
    return {
      pdfTech: {}
    }
  },
  computed: {
    filters: get('sch_v3/newRequestFilters'),
    newMapRequestFilters: get('sch_v3/newMapRequestFilters'),
    isListView: get('sch_v3/isListView'),
    loading: get('sch_v3/loading'),
    dataToUpdate: get('sch_v3/dataToUpdate'),
    editTech: get('sch_v3/editTech'),
    showTotals: get('sch_v3/filters@showTotals'),
    date: get('sch_v3/filters@date'),
    combineTechs: get('sch_v3/combineTechs'),
    routeStates: get('sch_v3/routeStates'),
    bounds: sync('sch_v3/bounds'),
    shops: sync('sch_v3/filters@shops')
  },
  components: {
    SchedulerHeader,
    SchedulerBody,
    SchedulerJobDetails,
    TechModal,
    SchedulerMap,
    SchedulerPdfDialog,
    SchedulerRouteEntryDetails,
    SchedulerNotifications,
    SchedulerWaypointsModal
  },
  watch: {
    filters(filters, old) {
      if (!R.equals(filters, old)) this.getSchedulerData()
    },
    combineTechs(combineTechs, old) {
      if (!R.equals(combineTechs, old)) this.getSchedulerData()
    },
    dataToUpdate(data, old) {
      if (!R.equals(data, old) && !R.isEmpty(data)) this.postSchedulerUpdates()
    },
    routeStates(data, old) {
      if (!R.equals(data, old)) {
        this.bounds = data[0]
      }
    }
  },
  methods: {
    getSchedulerData: call(actions.getSchedulerData),
    postSchedulerUpdates: call(actions.postSchedulerUpdates),
    updateTechInList: call(actions.updateTechInList),
    getSchedulerTotals: call(actions.getSchedulerTotals),

    openSchedulerPdfDialogListener(tech) {
      this.pdfTech = tech
      this.$nextTick(() => {
        this.$root.$emit('bv::show::modal', 'schedulerPdfDialog')
      })
    }
  },
  mounted() {
    this.$root.$on('updateSelectTech', this.getSchedulerData)
    this.$root.$on(
      'openSchedulerPdfDialog',
      this.openSchedulerPdfDialogListener
    )
  },
  beforeDestroy() {
    this.$root.$off('updateSelectTech', this.getSchedulerData)
    this.$root.$off(
      'openSchedulerPdfDialog',
      this.openSchedulerPdfDialogListener
    )
  }
}
</script>

<style lang="scss">
@import "Scheduler.scss";
</style>
