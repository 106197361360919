<template>
  <div class="t-w-1/4 t-px-3 t-pb-6">
    <div
      class="t-flex t-flex-col t-items-stretch t-border t-border-solid t-border-gray-200 t-p-4
        t-rounded-md t-shadow t-bg-white t-h-full"
    >
      <div class="t-flex t-space-x-2 t-mb-2">
        <div class="t-w-1/3 t-text-gray-400 t-text-right">Part</div>
        <div>{{ number }}</div>
      </div>
      <div class="t-flex t-space-x-2 t-mb-2">
        <div class="t-w-1/3 t-text-gray-400 t-text-right">Status</div>
        <el-tag :type="tagType" size="mini" class="t-capitalize">{{ type }}</el-tag>
      </div>
      <div class="t-flex t-space-x-2 t-mb-2">
        <div class="t-w-1/3 t-text-gray-400 t-text-right">Quantity</div>
        <div>{{ total }} unit(s)</div>
      </div>
      <div class="t-flex t-space-x-2 t-mb-2">
        <div class="t-w-1/3 t-text-gray-400 t-text-right">Price</div>
        <div>${{ price }}</div>
      </div>
      <div v-if="poId" class="t-flex t-space-x-2 t-mb-2">
        <div class="t-w-1/3 t-text-gray-400 t-text-right">PO #</div>
        <div class="t-text-blue-500 t-cursor-pointer" @click="$emit('viewPO', poId)">
          {{ poInternalId }}
        </div>
      </div>
      <div class="t-flex-grow t-mt-4 t-flex t-items-end t-justify-center">
        <el-button
          plain
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="$emit('selected')"
        >
          Add
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    number: String,
    total: Number,
    price: Number,
    poId: Number,
    poInternalId: Number
  },

  computed: {
    tagType() {
      if (this.type === 'available') {
        return 'success'
      } else if (this.type === 'unreceived') {
        return 'warning'
      } else {
        return 'info'
      }
    }
  }
}
</script>
