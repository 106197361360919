import { schedulerActions as actions, schedulerMutations as mutations } from './scheduler.types'
import axios from 'axios'
import { formatToDb } from '@/scripts/helpers/date.helpers'
import {
  schedulerJobsUrl,
  schedulerUserSettingsUrl,
  schedulerMapUrl,
  schedulerWarehousesUrl,
  techUrl,
  schedulerWaypointUrl,
  schedulerUrl,
  schedulerJobDetailsUrl,
  techsideCompleteLegacyUrl,
  shopTechsUrl,
  schedulerAppointmentUrl
} from '@/constants/endpoints'
import objectDiff from 'object-diff'
import * as R from 'ramda'
import { getFormData } from '@/scripts/helpers/scheduler.helpers'
import Api from '@/services/Api'
import { fixAppointment } from '@/scripts/helpers/appointment.helpers'

const schedulerActions = {
  [actions.setJobFilter] ({ commit }, value) {
    commit(mutations.setStateValue, { key: 'activeJobListFilter', value })
  },
  [actions.setStateValue] ({ commit }, { key, value }) {
    commit(mutations.setStateValue, { key, value })
  },
  async [actions.getSchedulerJobs] ({ commit, getters, dispatch }) {
    commit(mutations.setStateValue, { key: 'loading', value: true })
    try {
      const selectedShops = getters.getShopFiltersString
      if (selectedShops.length) {
        const params = { selectedShops }
        const { data } = await axios.get(schedulerJobsUrl, { params })
        commit(mutations.setStateValue, { key: 'jobs', value: data.jobs })
        commit(mutations.setStateValue, { key: 'techs', value: data.techs })
        await dispatch(actions.getRouteWaypoints)
        await dispatch(actions.getAppointments)
      } else {
        commit(mutations.setStateValue, { key: 'jobs', value: [] })
        commit(mutations.setStateValue, { key: 'techs', value: [] })
      }
    } catch (error) {

    }
    commit(mutations.setStateValue, { key: 'loading', value: false })
  },
  [actions.updateSchedulerItems] ({ dispatch }, { updatedItems, tech, date }) {
    if (tech) {
      // const waypoints = []
      // const appts = []
      updatedItems.forEach((item, index) => {
        // if (item.shop_name) {
        dispatch(actions.updateJob, { job: item, tech, index, date })
        // } else if (item.google_place_id) {
        //   waypoints.push({ item, index })
        // } else {
        //   appts.push({ item, index, tech })
        // }
      })
      // dispatch(actions.updateWaypoints, waypoints)
      // dispatch(actions.updateAppointments, appts)
    }
  },
  async [actions.updateJob] ({ commit, state }, { job, tech, index, date }) {
    const shopTech = tech.tech_ids
      ? state.techs.find(({ id, shop_id }) => R.contains(id, tech.tech_ids) && shop_id === job.shop_id)
      : tech
    console.log(tech)
    // const jobIndex = state.jobs.findIndex(R.propEq('id', job.id))
    // const updatedJob = {
    //   ...job,
    //   tech_id: shopTech.id,
    //   tech_color: shopTech.color,
    //   scheduled_date: formatToDb(date),
    //   scheduler_order: `${index}`
    // }
    // if (!R.eqBy(R.pick(['tech_id', 'scheduler_order', 'scheduled_date']), state.jobs[jobIndex], updatedJob)) {
    //   commit(mutations.updateJob, {
    //     index: jobIndex,
    //     job: updatedJob
    //   })
    //   commit(mutations.setJobsToUpdate, {
    //     id: job.id,
    //     updates: objectDiff(job, updatedJob)
    //   })
    // }
  },
  async [actions.postJobUpdates] ({ commit, state }, jobUpdates) {
    try {
      await axios.post(schedulerJobsUrl, jobUpdates)
    } catch (error) {

    }
    commit(mutations.setJobsToUpdate)
  },
  [actions.updateWaypoints] ({ commit, state, dispatch }, waypoints) {
    if (waypoints.length) {
      const updatedWaypoints = []
      waypoints.forEach(({ item, index }) => {
        const waypointIndex = state.waypoints.findIndex(R.propEq('id', item.id))
        const updatedWaypoint = {
          ...item,
          scheduler_order: index
        }
        if (!R.eqBy(R.prop('scheduler_order'), state.waypoints[waypointIndex], updatedWaypoint)) {
          commit(mutations.updateWaypoint, {
            index: waypointIndex,
            waypoint: updatedWaypoint
          })
          updatedWaypoints.push(updatedWaypoint)
        }
      })
      dispatch(actions.postWaypointUpdates, updatedWaypoints)
    }
  },
  async [actions.postWaypointUpdates] ({ state }, waypointUpdates) {
    try {
      await Api.put(schedulerWaypointUrl, waypointUpdates)
    } catch (error) {

    }
  },
  [actions.updateAppointments] ({ state, commit, dispatch }, appts) {
    if (appts.length) {
      const updatedAppts = []
      appts.forEach(({ item, index, tech }) => {
        const appt = state.appointments.find(R.propEq('id', item.id))
        const updatedAppt = {
          ...item,
          scheduler_order: index,
          tech_id: +tech.id
        }
        if (!R.eqBy(R.pick(['tech_id', 'scheduler_order']), appt, updatedAppt)) {
          commit(mutations.updateOrAppendValue, {
            listKey: 'appointments',
            value: updatedAppt
          })
          updatedAppts.push(updatedAppt)
        }
      })
      dispatch(actions.postAppointmentUpdates, updatedAppts)
    }
  },
  async [actions.postAppointmentUpdates] ({ state }, apptUpdates) {
    try {
      await Api.put(schedulerAppointmentUrl, apptUpdates)
    } catch (error) {

    }
  },
  async [actions.getJobDetails] ({ commit }, jobId) {
    commit(mutations.setStateValue, { key: 'loading', value: true })
    try {
      const { data } = await Api(schedulerJobDetailsUrl(jobId))
      commit(mutations.setStateValue, {
        key: 'jobDetails',
        value: data
      })
      commit(mutations.setStateValue, { key: 'loading', value: false })
      return
    } catch (error) {

    }
  },
  [actions.updateJobValueById] ({ commit }, { id, key, value }) {
    commit(mutations.updateJobValue, { id, key, value })
    commit(mutations.setJobsToUpdate, {
      id,
      updates: { [key]: value }
    })
  },
  async [actions.postUserSettingsUpdates] (store, userSettings) {
    const data = getFormData(userSettings)
    try {
      await axios.post(schedulerUserSettingsUrl, data)
    } catch (error) {

    }
  },
  async [actions.getSchedulerMapData] ({ state, commit, getters, rootGetters }) {
    commit(mutations.setStateValue, { key: 'loading', value: true })
    commit(mutations.setStateValue, {
      key: 'bounds'
    })
    try {
      const params = {
        scheduled_date: formatToDb(state.filters.date),
        shops: state.filters.shops,
        combined: rootGetters.getUserCombineTechsValue
      }
      const { data } = await Api({ url: schedulerMapUrl, params })
      commit(mutations.setStateValue, { key: 'geometry', value: data })
      commit(mutations.setStateValue, {
        key: 'bounds',
        value: getters.routeStates[0]
      })
    } catch (error) {

    }
    commit(mutations.setStateValue, { key: 'loading', value: false })
  },
  [actions.updateGeometryValueById] ({ commit }, { id, key, value }) {
    commit(mutations.updateGeometryValue, { id, key, value })
  },
  [actions.setMapBounds] ({ commit }, value) {
    commit(mutations.setStateValue, { key: 'bounds', value })
  },
  async [actions.getSchedulerWarehouses] ({ state, commit }) {
    const { data } = await Api({ url: schedulerWarehousesUrl })
    commit(mutations.setStateValue, { key: 'warehouses', value: data })
  },
  async [actions.getTechInfo] ({ state, commit }, { id, shop_id }) {
    try {
      const { data } = await Api({ url: techUrl(shop_id, id) })
      commit(mutations.setStateValue, { key: 'editTech', value: data })
    } catch (error) {

    }
  },
  [actions.updateTechListValue] ({ state, commit }, tech) {
    commit(mutations.updateStateListValueById, { key: 'techs', value: tech })
  },
  async [actions.getRouteWaypoints] ({ state, commit }) {
    try {
      const params = { shops: R.pathOr([], ['filters', 'shops'])(state) }
      if (params.shops.length) {
        const { data } = await Api({ url: schedulerWaypointUrl, params })
        commit(mutations.setStateValue, { key: 'waypoints', value: data })
        return
      }
    } catch (error) {

    }
  },
  async [actions.createRouteWaypoint] ({ state, commit }, { waypoint, event }) {
    commit(mutations.setStateValue, { key: 'waypointLoading', value: true })
    try {
      const { data } = await Api.post(schedulerWaypointUrl, waypoint)
      commit(mutations.appendRouteWaypoint, data)
      if (event) {
        event.to.removeChild(event.item)
      }
    } catch (error) {

    }
    commit(mutations.setStateValue, { key: 'waypointLoading', value: false })
  },
  async [actions.deleteWaypoint] ({ commit }, id) {
    try {
      await Api.delete(`${schedulerWaypointUrl}/${id}`)
    } catch (error) {

    }
    commit(mutations.deleteWaypoint, id)
  },
  async [actions.postSchedulerUpdates] ({ dispatch, commit }, values) {
    commit(mutations.setStateValue, { key: 'loading', value: true })
    try {
      await Api.put(`${schedulerUrl}`, values)
    } catch (error) {

    }
    dispatch(actions.getSchedulerMapData)
  },
  async [actions.completeTechsideJob] ({ commit }, id) {
    try {
      await axios.post(techsideCompleteLegacyUrl(id), getFormData({ isTechsign: true }))
      commit(mutations.setStateValue, {
        key: 'jobDetails.techside_job_completed',
        value: true
      })
      return
    } catch (error) {

    }
  },
  async [actions.getShopTechs] ({ commit }, shopId) {
    try {
      const { data } = await Api.get(shopTechsUrl(shopId))
      commit(mutations.setShopTechs, data)
      return
    } catch (error) {

    }
  },
  async [actions.saveAppointment] ({ commit }, appointment) {
    try {
      const { data } = await Api.post(schedulerAppointmentUrl, fixAppointment(appointment))
      commit(mutations.updateOrAppendValue, {
        listKey: 'appointments',
        value: data
      })
    } catch (error) {

    }
  },
  async [actions.getAppointments] ({ state, commit }) {
    try {
      const params = { shops: R.pathOr([], ['filters', 'shops'])(state) }
      if (params.shops.length) {
        const { data } = await Api({ url: schedulerAppointmentUrl, params })
        commit(mutations.setStateValue, { key: 'appointments', value: data })
        return
      }
    } catch (error) {

    }
  },
  async [actions.deleteAppointment] ({ commit }, id) {
    try {

      await Api.delete(`${schedulerAppointmentUrl}/${id}`)
    } catch (error) {

    }
    commit(mutations.deleteValueFromList, { listKey: 'appointments', id })
  },
  async [actions.saveJob] (ctx, { id, values }) {
    try {
      await Api.post(schedulerJobDetailsUrl(id), values)
    } catch (error) {

    }
  }
}

export default schedulerActions
