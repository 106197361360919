var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "commercialAccountInfoModal",
            size: "lg",
            title: "Commercial Account Information",
            "header-bg-variant": "primary",
            "header-text-variant": "light"
          },
          on: { show: _vm.dialogAboutToAppear }
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", { attrs: { for: "accountName" } }, [
                _vm._v("Company Name")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customer.name,
                    expression: "customer.name"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "accountName", type: "text" },
                domProps: { value: _vm.customer.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.customer, "name", $event.target.value)
                  }
                }
              })
            ]),
            _c(
              "div",
              { staticClass: "col-md-6 mt-4" },
              [
                _c("CheckBoxLabel", {
                  attrs: {
                    label: "FLAG CUSTOMER",
                    value: _vm.customer.flagged_customer
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.customer, "flagged_customer", $event)
                    }
                  }
                }),
                _c("CheckBoxLabel", {
                  attrs: {
                    label: "TAX EXEMPT",
                    value: _vm.customer.commercial_account_tax_exempt
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.customer,
                        "commercial_account_tax_exempt",
                        $event
                      )
                    }
                  }
                }),
                _c("CheckBoxLabel", {
                  attrs: {
                    label: "PO REQUIRED",
                    value: _vm.customer.po_required
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.customer, "po_required", $event)
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-4" },
              [
                _c("Phone", {
                  ref: "phone1Component",
                  attrs: {
                    title: "Phone 1",
                    value: _vm.customer.phone1,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.customer, "phone1", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-md-4" },
              [
                _c("Phone", {
                  ref: "phone2Component",
                  attrs: {
                    title: "Phone 2",
                    value: _vm.customer.phone2,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.customer, "phone2", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-md-4" },
              [
                _c("Phone", {
                  ref: "faxComponent",
                  attrs: {
                    title: "Fax",
                    value: _vm.customer.fax,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.customer, "fax", $event)
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-row" }, [
            !_vm.showInput
              ? _c("div", { staticClass: "form-group col-md-8" }, [
                  _c("label", { attrs: { for: "customerEmail" } }, [
                    _vm._v(
                      "Email -- Default Set To: " + _vm._s(_vm.selectedEmail)
                    )
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedEmail,
                          expression: "selectedEmail"
                        }
                      ],
                      staticClass: "form-control form-control-sm",
                      attrs: { id: "customerEmail" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedEmail = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.customerEmails, function(email, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: email.value } },
                        [_vm._v(_vm._s(email.value))]
                      )
                    }),
                    0
                  )
                ])
              : _c(
                  "div",
                  { staticClass: "form-group col-md-8" },
                  [
                    _c("Email", {
                      attrs: {
                        title: "Email",
                        id: "customerEmail",
                        type: "text",
                        value: _vm.emailToBeAdded,
                        inIsValid: _vm.isEmailValid,
                        required: false
                      },
                      on: {
                        "update:value": function($event) {
                          _vm.emailToBeAdded = $event
                        },
                        "update:inIsValid": function($event) {
                          _vm.isEmailValid = $event
                        },
                        "update:in-is-valid": function($event) {
                          _vm.isEmailValid = $event
                        }
                      }
                    })
                  ],
                  1
                ),
            _c("div", { staticClass: "form-group col-md-1" }, [
              _c(
                "label",
                {
                  staticClass: "d-sm-block d-md-none",
                  attrs: { for: "addCustomerEmail" }
                },
                [_vm._v("Add Email")]
              ),
              _c("label", {
                staticClass: "d-none d-md-block",
                attrs: { for: "addCustomerEmail" }
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.top.hover",
                      value: _vm.inputShowing
                        ? "Add Entered Email"
                        : "Add New Email",
                      expression:
                        "inputShowing ? 'Add Entered Email':'Add New Email'",
                      modifiers: { top: true, hover: true }
                    }
                  ]
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "form-control form-control-sm",
                      attrs: {
                        variant: "outline-success",
                        id: "addCustomerEmail"
                      },
                      on: { click: _vm.addEmail }
                    },
                    [_c("font-awesome-icon", { attrs: { icon: _vm.faPlus } })],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "form-group col-md-1" }, [
              _c(
                "label",
                {
                  staticClass: "d-sm-block d-md-none",
                  attrs: { for: "deleteCustomerEmail" }
                },
                [_vm._v("Delete Email")]
              ),
              _c("label", {
                staticClass: "d-none d-md-block",
                attrs: { for: "deleteCustomerEmail" }
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.top.hover",
                      value: _vm.inputShowing
                        ? "Clear Input/Go Back To List"
                        : "Delete This Commercial Account Email",
                      expression:
                        "inputShowing ? 'Clear Input/Go Back To List':'Delete This Commercial Account Email'",
                      modifiers: { top: true, hover: true }
                    }
                  ]
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "form-control form-control-sm",
                      attrs: {
                        variant: "outline-danger",
                        id: "deleteCustomerEmail"
                      },
                      on: { click: _vm.deleteEmail }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: _vm.faTrashAlt }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "commercialCustomerLastName" } }, [
                _vm._v("Contact Last Name")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customer.contact_last_name,
                    expression: "customer.contact_last_name"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "commercialCustomerLastName", type: "text" },
                domProps: { value: _vm.customer.contact_last_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.customer,
                      "contact_last_name",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "commercialCustomerFirstName" } }, [
                _vm._v("Contact First Name")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customer.contact_first_name,
                    expression: "customer.contact_first_name"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "commercialCustomerFirstName", type: "text" },
                domProps: { value: _vm.customer.contact_first_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.customer,
                      "contact_first_name",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "commercialCustomerMiddleName" } }, [
                _vm._v("Contact Middle Name")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customer.contact_middle_name,
                    expression: "customer.contact_middle_name"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "commercialCustomerMiddleName", type: "text" },
                domProps: { value: _vm.customer.contact_middle_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.customer,
                      "contact_middle_name",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-6" },
              [
                _c("label", { attrs: { for: "commercialCustomerAddress" } }, [
                  _vm._v("Address")
                ]),
                _c("address-input", {
                  attrs: {
                    full: "",
                    "popper-fix": "",
                    loading: _vm.addressLoading
                  },
                  model: {
                    value: _vm.customer.g_address,
                    callback: function($$v) {
                      _vm.$set(_vm.customer, "g_address", $$v)
                    },
                    expression: "customer.g_address"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-md-6" },
              [
                _c("label", { attrs: { for: "commercialDefaultLocation" } }, [
                  _vm._v("Default install location")
                ]),
                _c("address-input", {
                  attrs: { full: "", "popper-fix": "" },
                  model: {
                    value: _vm.installLocationAddress,
                    callback: function($$v) {
                      _vm.installLocationAddress = $$v
                    },
                    expression: "installLocationAddress"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "defaultPriceLevel" } }, [
                _vm._v("Default Price Level")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customer.default_pricelevel_id,
                      expression: "customer.default_pricelevel_id"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { id: "defaultPriceLevel" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.customer,
                        "default_pricelevel_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("Choose...")
                  ]),
                  _vm._l(_vm.priceLevels, function(priceLevel) {
                    return _c(
                      "option",
                      {
                        key: priceLevel.id,
                        domProps: { value: priceLevel.id }
                      },
                      [_vm._v(_vm._s(priceLevel.price_level_name))]
                    )
                  })
                ],
                2
              )
            ]),
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "defaultPriceLevel" } }, [
                _vm._v("Insurance/Fleet")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customer.insurancefleet_id,
                      expression: "customer.insurancefleet_id"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { id: "defaultPriceLevel" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.customer,
                        "insurancefleet_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("Choose...")
                  ]),
                  _vm._l(_vm.insuranceFleets, function(insuranceFleet) {
                    return _c(
                      "option",
                      {
                        key: insuranceFleet.id,
                        domProps: { value: insuranceFleet.id }
                      },
                      [_vm._v(_vm._s(insuranceFleet.name))]
                    )
                  })
                ],
                2
              )
            ]),
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "defaultPriceLevel" } }, [
                _vm._v("Default Sales Rep")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customer.default_salesrep_id,
                      expression: "customer.default_salesrep_id"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { id: "defaultPriceLevel" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.customer,
                        "default_salesrep_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("Choose...")
                  ]),
                  _vm._l(_vm.salesreps, function(salesrep) {
                    return _c(
                      "option",
                      { key: salesrep.id, domProps: { value: salesrep.id } },
                      [_vm._v(_vm._s(salesrep.name))]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "defaultInvoiceTier" } }, [
                _vm._v("Default Invoice Tier")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customer.default_invoice_tier,
                      expression: "customer.default_invoice_tier"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { id: "defaultInvoiceTier" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.customer,
                        "default_invoice_tier",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("Choose...")
                  ]),
                  _vm._l(_vm.invoiceTierOptions, function(option) {
                    return _c(
                      "option",
                      { key: option.value, domProps: { value: option.value } },
                      [_vm._v(_vm._s(option.text))]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-12" },
              [
                _c("label", { attrs: { for: "Notes" } }, [_vm._v("Notes")]),
                _c("b-form-textarea", {
                  attrs: {
                    id: "notes",
                    placeholder: "Enter customer notes...",
                    rows: 5,
                    "max-rows": 5
                  },
                  model: {
                    value: _vm.customer.notes,
                    callback: function($$v) {
                      _vm.$set(_vm.customer, "notes", $$v)
                    },
                    expression: "customer.notes"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col mt-2" },
              [
                _c(
                  "b",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.commercialaccountRatesCollapse",
                        modifiers: { commercialaccountRatesCollapse: true }
                      }
                    ],
                    staticStyle: {
                      "vertical-align": "middle",
                      cursor: "pointer"
                    }
                  },
                  [_vm._v("RATE INFO:")]
                ),
                _c("span"),
                !_vm.isRateInfoOpen
                  ? _c("v-icon", {
                      staticClass: "caret",
                      staticStyle: { "vertical-align": "middle" },
                      attrs: { scale: "1.5", name: "caret-left" }
                    })
                  : _vm._e(),
                _vm.isRateInfoOpen
                  ? _c("v-icon", {
                      staticClass: "caret",
                      staticStyle: { "vertical-align": "middle" },
                      attrs: { scale: "1.5", name: "caret-down" }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "b-collapse",
            {
              attrs: { id: "commercialaccountRatesCollapse" },
              model: {
                value: _vm.isRateInfoOpen,
                callback: function($$v) {
                  _vm.isRateInfoOpen = $$v
                },
                expression: "isRateInfoOpen"
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Glass Discount",
                        value: _vm.customer.discount,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "% "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(_vm.customer, "discount", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Moulding Discount",
                        value: _vm.customer.moulding_discount,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "% "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "moulding_discount",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Hourly Labor",
                        value: _vm.customer.labor_hourly,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(_vm.customer, "labor_hourly", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Flat Labor",
                        value: _vm.customer.labor_flat,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(_vm.customer, "labor_flat", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Oem Glass Discount",
                        value: _vm.customer.oem_discount,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "% "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(_vm.customer, "oem_discount", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-12 col-lg-3" },
                  [
                    _c("label", { attrs: { for: "kitStandard" } }, [
                      _vm._v("Kit: Standard")
                    ]),
                    _c("FloatDropdown", {
                      attrs: {
                        dropdownOptions: [
                          {
                            value: "each",
                            label: "Each",
                            dropdownLabel: "Each"
                          },
                          {
                            value: "flat",
                            label: "Flat",
                            dropdownLabel: "Flat"
                          }
                        ],
                        value: _vm.customer.kit_standard,
                        dropdownValue: _vm.customer.kit_charge_type_standard,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(_vm.customer, "kit_standard", $event)
                        },
                        "update:dropdownValue": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "kit_charge_type_standard",
                            $event
                          )
                        },
                        "update:dropdown-value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "kit_charge_type_standard",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-12 col-lg-3" },
                  [
                    _c("label", { attrs: { for: "kitStandard" } }, [
                      _vm._v("Kit: High Modulous")
                    ]),
                    _c("FloatDropdown", {
                      attrs: {
                        dropdownOptions: [
                          {
                            value: "each",
                            label: "Each",
                            dropdownLabel: "Each"
                          },
                          {
                            value: "flat",
                            label: "Flat",
                            dropdownLabel: "Flat"
                          }
                        ],
                        value: _vm.customer.kit_high,
                        dropdownValue: _vm.customer.kit_charge_type_high,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(_vm.customer, "kit_high", $event)
                        },
                        "update:dropdownValue": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "kit_charge_type_high",
                            $event
                          )
                        },
                        "update:dropdown-value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "kit_charge_type_high",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-12 col-lg-3" },
                  [
                    _c("label", { attrs: { for: "kitStandard" } }, [
                      _vm._v("Kit: Fast Cure")
                    ]),
                    _c("FloatDropdown", {
                      attrs: {
                        dropdownOptions: [
                          {
                            value: "each",
                            label: "Each",
                            dropdownLabel: "Each"
                          },
                          {
                            value: "flat",
                            label: "Flat",
                            dropdownLabel: "Flat"
                          }
                        ],
                        value: _vm.customer.kit_fast,
                        dropdownValue: _vm.customer.kit_charge_type_fast,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(_vm.customer, "kit_fast", $event)
                        },
                        "update:dropdownValue": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "kit_charge_type_fast",
                            $event
                          )
                        },
                        "update:dropdown-value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "kit_charge_type_fast",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-sm-6 col-md-6" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "First Chip",
                        value: _vm.customer.chip_first,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(_vm.customer, "chip_first", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Additional Chips",
                        value: _vm.customer.chip_additional,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "chip_additional",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "form-group col mt-2" }, [
                  _c("b", [_vm._v("RECAL:")])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Safelite Dynamic",
                        value: _vm.customer.recal_safelite_dynamic,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "recal_safelite_dynamic",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Safelite Static",
                        value: _vm.customer.recal_safelite_static,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "recal_safelite_static",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Safelite Dual",
                        value: _vm.customer.recal_safelite_dual,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "recal_safelite_dual",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Lynx RTL Dynamic",
                        value: _vm.customer.recal_lynx_rtl_dynamic,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "recal_lynx_rtl_dynamic",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Lynx RTL Static",
                        value: _vm.customer.recal_lynx_rtl_static,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "recal_lynx_rtl_static",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Lynx RTL Both",
                        value: _vm.customer.recal_lynx_rtl_both,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "recal_lynx_rtl_both",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Lynx DLR Dynamic",
                        value: _vm.customer.recal_lynx_dlr_dynamic,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "recal_lynx_dlr_dynamic",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Lynx DLR Static",
                        value: _vm.customer.recal_lynx_dlr_static,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "recal_lynx_dlr_static",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
                  [
                    _c("FloatInput", {
                      attrs: {
                        title: "Lynx DLR Both",
                        value: _vm.customer.recal_lynx_dlr_both,
                        minimumDecimalPlaces: 2,
                        maximumDecimalPlaces: 2,
                        formattedPrefix: "$ "
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.customer,
                            "recal_lynx_dlr_both",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm.customer.commercial_account_tax_exempt != "1"
            ? _c("div", [
                _c(
                  "label",
                  { attrs: { for: "DefaultCommercialAccountTaxes" } },
                  [_vm._v("Default Taxes")]
                ),
                _c(
                  "table",
                  { staticClass: "table table-sm table-less-padding" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticStyle: { "text-align": "center col-md-1" },
                            attrs: { scope: "col" }
                          },
                          [_vm._v("Default")]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { "text-align": "center col-md-2" },
                            attrs: { scope: "col" }
                          },
                          [_vm._v("Name")]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { "text-align": "center col-md-2" },
                            attrs: { scope: "col" }
                          },
                          [_vm._v("Percentage")]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { "text-align": "center col-md-1" },
                            attrs: { scope: "col" }
                          },
                          [_vm._v("Materials")]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { "text-align": "center col-md-1" },
                            attrs: { scope: "col" }
                          },
                          [_vm._v("Labor")]
                        )
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.shopTaxes, function(tax, index) {
                        return _c("tr", { key: index }, [
                          _c(
                            "td",
                            { attrs: { scope: "row" } },
                            [
                              _c("b-form-checkbox", {
                                attrs: {
                                  id: "taxDefault-" + index,
                                  value: tax.id
                                },
                                model: {
                                  value: _vm.defaultTaxes,
                                  callback: function($$v) {
                                    _vm.defaultTaxes = $$v
                                  },
                                  expression: "defaultTaxes"
                                }
                              })
                            ],
                            1
                          ),
                          _c("td", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(tax.name))
                          ]),
                          _c("td", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(tax.percentage))
                          ]),
                          _c("td", { attrs: { scope: "row" } }, [
                            tax.for_materials == "1"
                              ? _c("i", { staticClass: "fa fa-check isYes" })
                              : _vm._e()
                          ]),
                          _c("td", { attrs: { scope: "row" } }, [
                            tax.for_labor == "1"
                              ? _c("i", { staticClass: "fa fa-check isYes" })
                              : _vm._e()
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                )
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", size: "lg", variant: "primary" },
                    on: { click: _vm.saveCustomer }
                  },
                  [_vm._v("Save")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", variant: "primary" },
                    on: { click: _vm.cancelEdits }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }