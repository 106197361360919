import { mutations } from './types'

export default {
  [mutations.setIsOpen] (state, payload) {
    state.isOpen = payload
  },

  [mutations.setSelectedTab] (state, payload) {
    state.selectedTab = payload
  },

  [mutations.setShopsData] (state, payload) {
    state.shops = payload
  },

  [mutations.setShopId] (state, payload) {
    state.shopId = payload
  },

  [mutations.items.setItemsData] (state, payload) {
    state.items.data = payload
  },

  [mutations.items.setItemsFetching] (state, payload) {
    state.items.itemsFetching = payload
  },

  [mutations.items.setSelectedVendor] (state, payload) {
    state.items.selectedVendor = payload
  },

  [mutations.items.setSelectedItemIds] (state, payload) {
    state.items.selectedItemIds = payload
  },

  [mutations.items.setTotalItemsCount] (state, payload) {
    state.items.totalItemsCount = payload
  },

  [mutations.items.setVendorsItemsCount] (state, payload) {
    state.items.vendorsItemsCount = payload
  },

  [mutations.items.updateItemQuantity] (state, payload) {
    state.items.data.forEach(i => {
      if (i.id === payload.itemId) {
        i.quantity = payload.quantity
      }
    })
  },

  [mutations.items.setAmericanCheckoutIsOpen] (state, payload) {
    state.items.americanCheckoutIsOpen = payload
  },

  [mutations.items.setPilkingtonCheckoutIsOpen] (state, payload) {
    state.items.pilkingtonCheckoutIsOpen = payload
  },

  [mutations.items.setNielsenMollerCheckoutIsOpen] (state, payload) {
    state.items.nielsenMollerCheckoutIsOpen = payload
  },

  [mutations.orders.setOrdersData] (state, payload) {
    state.orders.data = payload
  },

  [mutations.orders.setOrdersFetching] (state, payload) {
    state.orders.ordersFetching = payload
  },

  [mutations.orders.setSelectedVendor] (state, payload) {
    state.orders.selectedVendor = payload
  },

  [mutations.orders.setOrderDetailsIsOpen] (state, payload) {
    state.orders.orderDetailsIsOpen = payload
  },

  [mutations.orders.setOrderDetails] (state, payload) {
    state.orders.orderDetails = payload
  },

  [mutations.orders.setOrdersItems] (state, payload) {
    state.orders.orderItems = payload
  },

  [mutations.orders.setOrderItemsFetching] (state, payload) {
    state.orders.orderItemsFetching = payload
  },

  [mutations.orders.setAmericanOrderDetailsIsOpen] (state, payload) {
    state.orders.americanOrderDetailsIsOpen = payload
  }
}
