var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title: "EDI Status:",
        id: _vm.modalId,
        "header-bg-variant": "primary",
        "header-text-variant": "light"
      },
      on: { shown: _vm.dialogAppeared, hidden: _vm.dialogDisappeared }
    },
    [
      _vm.loading
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "form-group col-6",
                staticStyle: { "font-size": "22px", "font-weight": "bold" },
                style: {
                  color:
                    _vm.internalEdiqueue.status.toLowerCase() == "paid" ||
                    _vm.internalEdiqueue.status.toLowerCase() == "accepted"
                      ? "green"
                      : _vm.internalEdiqueue.status.toLowerCase() == "rejected"
                      ? "red"
                      : _vm.internalEdiqueue.status.toLowerCase() == "unknown"
                      ? "blue"
                      : "black"
                }
              },
              [_vm._v(" Loading Please Wait... ")]
            )
          ])
        : _vm._e(),
      !_vm.loading
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "form-group col-6",
                  staticStyle: { "font-size": "22px", "font-weight": "bold" },
                  style: {
                    color:
                      _vm.internalEdiqueue.status.toLowerCase() == "paid" ||
                      _vm.internalEdiqueue.status.toLowerCase() == "accepted"
                        ? "green"
                        : _vm.internalEdiqueue.status.toLowerCase() ==
                          "rejected"
                        ? "red"
                        : _vm.internalEdiqueue.status.toLowerCase() == "unknown"
                        ? "blue"
                        : "black"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.internalEdiqueue.status) + " ")]
              ),
              _c("div", { staticClass: "form-group col-6" }, [
                _c(
                  "a",
                  {
                    staticClass: "label label-gray label-sm float-right",
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.manuallyChangeStatusButtonPressed }
                  },
                  [_vm._v("Manually Change Status")]
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "row", staticStyle: { "margin-top": "10px" } },
              [
                _c("div", {
                  staticClass: "form-group col-12",
                  domProps: {
                    innerHTML: _vm._s(_vm.internalEdiqueue.job_edi_message)
                  }
                })
              ]
            )
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "w-100",
          staticStyle: { "margin-top": "0px" },
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [_c("div", { staticClass: "float-right" })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }