import { defineStore } from 'pinia'
import Api from '@/services/Api'

export const gbExportItems = {
  'Receivable Account': 1,
  'Cash Payment': 2,
  'Check Payment': 3,
  'Bank Transfer Payment': 4,
  'Manual Card Payment': 5,
  'Other Payment': 6,
  'Card Payment': 7,
  'Promo Adjustment': 8,
  'Coupon Adjustment': 9,
  'Credit Adjustment': 10,
  'Short Pay Adjustment': 11,
  'Write Off Adjustment': 12,
  'Deductible Discount Adjustment': 13,
  'Fee Adjustment': 14,
  'Clearing Account': 15,
  'Materials Item': 16,
  'Labor Item': 17,
  'Doubtful Account Adjustment': 18
}

export const useQboStore = defineStore('qbo', {
  state: () => {
    return {
      shopId: null,
      visible: false,
      fetching: false,
      saving: false,
      saved: false,
      defaults: []
    }
  },

  getters: {
    defaultClearingAccount () {
      return this.defaults.filter((account) => account.gb_export_item === gbExportItems['Clearing Account']).length > 0
    }
  },

  actions: {
    openView(val) {
      this.shopId = val
      this.visible = true
    },

    async updateShopQboMapping(shopId, update) {
      try {
        this.saving = true
        await Api.post(
          `api/shop/${shopId}/qboMapping/updateShopQboMapping`,
          update
        ).then(res => {
          this.saved = true
          this.getAllQboDefaultsForShop()
        })
      } catch (e) {
        throw new Error('Failed to update default QuickBooks Online account')
      } finally {
        this.saving = false
      }
    },

    async deleteShopQboMapping(shopId, gbExportItem) {
      try {
        this.saving = true
        await Api.post(
          `api/shop/${shopId}/qboMapping/deleteShopQboMapping`,
          { gbExportItem }
        ).then(res => {
          this.saved = true
          this.getAllQboDefaultsForShop()
        })
      } catch (e) {
        throw new Error('Failed to set back to System Default')
      } finally {
        this.saving = false
      }
    },

    async getShopAccountsFromQbo(shopId, accountType) {
      try {
        this.fetching = true

        let type = 'Receivable'

        if (accountType.includes('Payment')) {
          type = 'Payment'
        } else if (accountType === 'Clearing Account') {
          type = 'Clearing'
        } else if (accountType.includes('Adjustment')) {
          type = 'Adjustment'
        }

        const { data } = await Api.get(`api/shop/${shopId}/qboMapping/getQbo${type}Accounts`)

        return data
      } catch (e) {
        throw new Error('Failed to retrieve accounts from QuickBooks Online')
      } finally {
        this.fetching = false
      }
    },

    async getShopItemsFromQbo(shopId, accountType) {
      try {
        this.fetching = true

        const { data } = await Api.get(`api/shop/${shopId}/qboMapping/getQboItems`)

        return data
      } catch (e) {
        throw new Error('Failed to retrieve items from QuickBooks Online')
      } finally {
        this.fetching = false
      }
    },

    async getShopDefaultQboAccount(shopId, accountType) {
      try {
        const { data } = await Api.get(
          `api/shop/${shopId}/qboMapping/getShopDefault/item/${gbExportItems[accountType]}`
        )
        return data
      } catch (e) {
        throw new Error(`Failed to retrieve default QuickBooks Online account for type: ${accountType}`)
      }
    },

    async getAllQboDefaultsForShop() {
      try {
        const { data } = await Api.get(
          `/api/shop/${this.shopId}/qboMapping/getShopDefault/all`
        )

        this.defaults = data
      } catch (e) {
        throw new Error('Failed to retrieve shop default QuickBooks accounts')
      }
    },

    close() {
      this.$reset()
    }
  }
})
