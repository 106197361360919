var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title: "Job Tags",
        id: _vm.modalID,
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg"
      },
      on: { show: _vm.dialogAboutToAppear, hide: _vm.dialogAboutToDisappear }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2 text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              staticStyle: { width: "100%" },
              attrs: { type: "button", variant: "primary" },
              on: {
                click: function($event) {
                  return _vm.openTagDialog(null)
                }
              }
            },
            [_vm._v("New Tag")]
          )
        ])
      ]),
      _c("hr", {
        staticStyle: { "margin-top": "20px", "margin-bottom": "20px" }
      }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "form-group col-md-12" },
          _vm._l(_vm.tags, function(tag, index) {
            return tag.status === "active" || _vm.showInactive
              ? _c(
                  "span",
                  {
                    key: index,
                    staticStyle: {
                      display: "inline-block",
                      "margin-top": "10px"
                    }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkedTags,
                          expression: "checkedTags"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: tag.id,
                        checked: Array.isArray(_vm.checkedTags)
                          ? _vm._i(_vm.checkedTags, tag.id) > -1
                          : _vm.checkedTags
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.checkedTags,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = tag.id,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.checkedTags = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checkedTags = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checkedTags = $$c
                          }
                        }
                      }
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "tag",
                        style: {
                          color: _vm.blackOrWhite(tag.color),
                          background: tag.color
                        },
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.openTagDialog(tag)
                          }
                        }
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: _vm.faTag } }),
                        _vm._v(" " + _vm._s(tag.text))
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c("div", { staticClass: "float-left" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.showInactive,
                  expression: "showInactive"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.showInactive)
                  ? _vm._i(_vm.showInactive, null) > -1
                  : _vm.showInactive
              },
              on: {
                change: function($event) {
                  var $$a = _vm.showInactive,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.showInactive = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.showInactive = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.showInactive = $$c
                  }
                }
              }
            }),
            _vm._v(" Show Inactive ")
          ]),
          _c("div", { staticClass: "float-right" }, [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.saving,
                  expression: "saving"
                }
              ],
              staticStyle: {
                width: "18px",
                height: "18px",
                "margin-right": "3px"
              },
              attrs: { src: require("./../../assets/img/spinner.gif") }
            }),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.saving,
                    expression: "!saving"
                  }
                ],
                staticClass: "btn btn-secondary",
                attrs: { type: "button", variant: "primary" },
                on: { click: _vm.applyTags }
              },
              [_vm._v("Apply Tags")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }