var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [
        _vm._v(
          "There is no Twilio account connected to this shop. Would you like to create one ?"
        )
      ]),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { lg: 12, sm: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "hide-required-asterisk": true,
                    "label-width": "60px",
                    size: "mini",
                    disabled: _vm.disabled
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Name")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "Enter a name" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            loading: _vm.loading
                          },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(" Create an account ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }