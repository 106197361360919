var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-px-4 t-h-14 t-flex-shrink-0 t-border-solid t-border-0 t-border-b\n            t-border-gray-300 t-flex t-items-center"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "t-text-base t-text-gray-700 t-flex t-flex-grow t-items-center\n           t-pr-2"
        },
        [
          _vm.activeChannel.friendlyName === "direct"
            ? _c("i", { staticClass: "el-icon-user" })
            : _vm.activeChannel.isPrivate
            ? _c("i", { staticClass: "el-icon-lock" })
            : _c("span", [_vm._v("#")]),
          _c(
            "div",
            {
              staticClass: "t-ml-1 t-truncate",
              class: _vm.infoDraweIsOpen ? "t-w-64" : "t-w-120"
            },
            [_vm._v(" " + _vm._s(_vm.channelName) + " ")]
          )
        ]
      ),
      _vm.activeChannel.friendlyName !== "direct"
        ? _c("div", { staticClass: "t-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "t-w-14 t-h-8 t-flex t-items-center t-justify-center t-space-x-2\n            t-cursor-pointer t-text-base t-rounded t-text-gray-700\n            hover:t-bg-gray-200 t-border t-border-solid t-border-gray-300",
                on: { click: _vm.openMembersDialog }
              },
              [
                _c("span", { staticClass: "t-text-sm" }, [
                  _vm._v(_vm._s(_vm.members.length))
                ]),
                _c("i", { staticClass: "fa fa-users" })
              ]
            ),
            _vm.activeChannel.createdBy === _vm.username
              ? _c(
                  "div",
                  {
                    staticClass:
                      "t-w-8 t-h-8 t-flex t-items-center t-justify-center t-cursor-pointer\n            t-text-base t-ml-3 t-rounded t-text-gray-700\n            hover:t-bg-gray-200 t-border t-border-solid t-border-gray-300",
                    class: { "t-bg-gray-200": _vm.addUsersDialogisOpen },
                    on: { click: _vm.addUser }
                  },
                  [_c("i", { staticClass: "fa fa-user-plus" })]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "t-w-8 t-h-8 t-flex t-items-center t-justify-center t-cursor-pointer\n            t-text-xl t-ml-3 t-rounded t-text-gray-700 hover:t-bg-gray-200\n            t-border t-border-solid t-border-gray-300",
                class: { "t-bg-gray-200": _vm.infoDrawerIsOpen },
                on: { click: _vm.toggleInfoDrawer }
              },
              [_c("i", { staticClass: "el-icon-more-outline" })]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }