var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    _vm._b(
      {
        staticClass: "md-icon-button",
        class: { reverse: _vm.reverse },
        attrs: { icon: "material-icons-outline md-18 md-" + _vm.icon },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      "el-button",
      Object.assign({}, _vm.$props, _vm.$attrs),
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }