import * as R from 'ramda'

export const getNameFirstTwo = R.pipe(R.prop('name'), R.defaultTo(''), R.take(2), R.toUpper())

export const getVendorColor = (vendor) => {
  if (vendor === 'pilk') return '#A1D490'
  else if (vendor === 'mygrant') return '#568EFC'
  else if (vendor === 'pgw') return '#FFAA33'
  else if (vendor === 'igc') return '#103f78'
  else if (vendor === 'service') return '#FCBA03'
  else return '#B103FC'
}

export const vendorColorMapping = {
  pilk: '#A1D490',
  mygrant: '#568EFC',
  pgw: '#FFAA33',
  igc: '#103f78',
  service: '#FCBA03'
}

export const vendorTitleMapping = {
  'pilk': 'Pilkington',
  'mygrant': 'Mygrant',
  'pgw': 'PGW',
  'igc': 'IGC',
  'service': 'Service',
}

export const VendorTypeIds = {
  CSR: 1,
  SALESOURCE: 2,
  SALESREP: 3,
  TECH: 4,
  LOCATION: 5,
  SUPPLIER: 6,
}