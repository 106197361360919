<template>
  <el-table
    stripe
    v-loading="fetching"
    :data='leads'
    :row-class-name='hasNotes'
    @expand-change='expandChange'>
    <el-table-column type='expand'>
      <template slot-scope="scope">
        <el-row>
          <el-col :lg='3' :sm='24'>
            <el-checkbox v-model='scope.row.call_back' border label='Call Back?' @change='updateCallBack(scope.row)'>
            </el-checkbox>
          </el-col>
          <el-col :lg='21' :sm='24'>
            <span class='t-mr-3 t-font-bold'>Notes:</span>
            <el-input @blur='updateLeadNote(scope.row)' type='textarea' v-model='scope.row.notes'>
            </el-input>
          </el-col>
        </el-row>
      </template>
    </el-table-column>
    <el-table-column label='Name'>
      <template slot-scope="scope">
        {{scope.row.first_name}} {{scope.row.last_name}}
      </template>
    </el-table-column>
    <el-table-column label='Phone number'>
      <template slot-scope="scope">
        {{ formatPhone(scope.row.phone) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="email"
      label="Email"
    >  
    </el-table-column>
    <el-table-column
      prop="company"
      label="Company"
    >  
    </el-table-column>
    <el-table-column
      prop="existing_account"
      label="Existing Account"
    >  
    </el-table-column>
    <el-table-column
      label="Demo Date"
    >
      <template slot-scope='scope'>
        {{ scope.row.demo_date }}
      </template>  
    </el-table-column>
    <el-table-column
      label="Demo Time"
    >
      <template slot-scope='scope'>
        {{ scope.row.demo_time }}
      </template>
    </el-table-column>
    <el-table-column
      prop="referral"
      label="Referral"
    >  
    </el-table-column>
    <el-table-column
      prop="add_to_networks"
      label="Add to networks"
    >  
    </el-table-column>
    <el-table-column
      fixed="right"
      label="Actions"
      width="200px">
      <template slot-scope="scope">
        <!-- <el-tooltip
          content="Start job"
          placement="top"
          :open-delay="300"
        >
          <el-button
            :loading="startJobSubmitting"
            size="mini"
            type="primary"
            icon="el-icon-video-play"
            @click="handleStartJob(scope.row.id)"
          />
        </el-tooltip> -->
        <el-tooltip
          v-if="!scope.row.archived"
          content="Archive"
          placement="top"
          :open-delay="300"
        >
          <el-button
            :loading="archiveSubmitting"
            size="mini"
            type="warning"
            icon="el-icon-takeaway-box"
            @click="handleArchive(scope.row.id)"
          />
        </el-tooltip>
        <el-tooltip
          v-else
          content="Unarchive"
          placement="top"
          :open-delay="300"
        >
          <el-button
            :loading="unarchiveSubmitting"
            plain
            size="mini"
            type="warning"
            icon="el-icon-takeaway-box"
            @click="handleUnarchive(scope.row.id)"
          />
        </el-tooltip>
        <!-- <el-tooltip
          content="Delete"
          placement="top"
          :open-delay="300"
        >
          <el-button
            :loading="deleteSubmitting"
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row.id)"
          />
        </el-tooltip> -->
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import moment from 'moment'

export default {
  data: () => ({
    archiveSubmitting: false,
    unarchiveSubmitting: false,
    deleteSubmitting: false,
    // startJobSubmitting: false
  }),
  computed: {
    leads: function () {
      return this.$store.state.glassbillerLeads.data
    },
    fetching: function () {
      return this.$store.state.glassbillerLeads.fetching
    }
  },
  methods: {
    hasNotes ({row, rowIndex}) {
      if (row.notes && row.notes !== '' && !row.call_back) {
        return 'has-notes'
      } else if (row.call_back) {
        return 'call-back'
      }
      return ''
    },
    formatPhone: i => {
      // console.log(i)
      if (!i) return ''
      return `(${i.substring(0, 3)}) ${i.substring(3, 6)}-${i.substring(6)}`},
    setArchived: function (payload) {
      this.$store.dispatch('glassbillerLeads/setArchived', payload)
    },
    setNote: function (payload) {
      this.$store.dispatch('glassbillerLeads/setNote', payload)
    },
    setCallBack: function (payload) {
      this.$store.dispatch('glassbillerLeads/setCallBack', payload)
    },
    remove: function (payload) {
      this.$store.dispatch('glassbillerLeads/remove', payload)
    },
    handleArchive: async function (id) {
      this.archiveSubmitting = true
      await this.setArchived({ id, value: 1 })
      this.archiveSubmitting = false
      this.$message.info('Lead was archived')
    },
    handleUnarchive: async function (id) {
      this.unarchiveSubmitting = true
      await this.setArchived({ id, value: 0 })
      this.unarchiveSubmitting = false
      this.$message.info('Lead was unarchived')
    },
    updateLeadNote: async function (lead) {
      // console.log(lead)
      await this.setNote({ id: lead.id, value: lead.notes})
    },
    updateCallBack: async function (lead) {
      await this.setCallBack({ id: lead.id, value: lead.call_back })
    },
    handleDelete: async function (id) {
      try {
        await this.$messageBox.confirm(
          'This will permanently remove this lead. Continue ?',
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'error'
          }
        )
        this.deleteSubmitting = true
        await this.remove({ id })
        this.deleteSubmitting = false
        this.$message.info('Lead was removed')
      } catch (e) {}
    },
    expandChange: async function (val1, val2) {
      // console.log(val1, val2)
    }
  }
}
</script>

<style lang='scss'>
.has-notes {
  .el-table__expand-icon {
    background-color: lightgreen !important;
    border-radius: 15px !important;
  }
}
.call-back {
  .el-table__expand-icon {
    background-color: red !important;
    border-radius: 15px !important;
    color: white;
  }
}
</style>