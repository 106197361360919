<template>
  <div>
    <el-tabs v-model='tab' class='tabs-container'>
      <el-tab-pane v-for="item in tabs" :key="item.key" :name="item.key" class='tabs-pane-container'>
        <span slot="label">
          {{item.label}}&nbsp;&nbsp;
          <span v-if="getTabCount(item.key)" class="rejected-tab-count" :style="`background:${getColor(item.key)};`">{{getTabCount(item.key)}}</span>
          <!-- <i
            class='el-icon-warning-outline error-tab-icon'
            v-if="errors[item.key]"
          /> -->
        </span>
        <div class="tab-table-container">
          <rejected-table />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import RejectedTable from './RejectedTable'
import Ediqueue from '@/scripts/objects/ediqueue'
import {shopRejectedCount, shopQueuedCount} from '@/scripts/helpers/rejected.helpers'
import { get, sync, call } from 'vuex-pathify'

export default {
  components: { RejectedTable },
  data () {
    return {
      preparingCount: 0,
      pendingCount: 0,
      failedCount: 0
    }
  },
  methods: {
    changeTab: call('rejectedInvoices/changeTab'),
    // shopRejectedCount,
    // shopQueuedCount,
    getTabCount (key) {
      if (this.getShop?.id) {
        if (key === 'rejected') {
          return shopRejectedCount(this.$store.state, this.getShop.id)
        } else if (key === 'queued') {
          return shopQueuedCount(this.$store.state, this.getShop.id)
        } else if (key === 'preparing') {
          return this.preparingCount || 0
        } else if (key === 'pending') {
          return this.pendingCount || 0
        } else if (key === 'failed') {
          return this.failedCount || 0
        }
      }
      return 0
    },
    getColor (key) {
      if (key === 'rejected') {
        return '#dc3545'
      } else if (key === 'queued') {
        return '#26af5d'
      } else if (key === 'preparing' || key === 'pending' || key === 'failed') {
        return '#e66926'
      }
    }
  },
  mounted () {
    if (this.$store.state.user.admin === 1) {
      var self = this
      Ediqueue.getEdiQueueStatusCounts(function (res) {
        self.preparingCount = parseInt(res.PreparingCount)
        self.pendingCount = parseInt(res.PendingCount)
        self.failedCount = parseInt(res.FailedCount)
      })
    }
  },
  computed: {
    getTab: get('rejectedInvoices/tab'),
    getShop: get('rejectedInvoices/selectedShop'),
    tabs () {
      const tabs = [
        { label: 'Rejected', key: 'rejected' },
        { label: 'Queued', key: 'queued' },
      ]
      if (this.$store.state.user.admin === 1) {
        tabs.push({ label: 'Preparing', key: 'preparing' })
        tabs.push({ label: 'Pending', key: 'pending' })
        tabs.push({ label: 'Failed', key: 'failed' })
      }
      return tabs
    },
    tab: {
      get () {
        return this.getTab
      },
      set (val) {
        this.changeTab(val)
      }
    }
  }
}
</script>

<style lang='scss'>
@import './RejectedInvoices.scss';
</style>