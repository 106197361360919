import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Chart extends ObjectTracker {
  id = -1
  chartvisiblesettings = ''
  user_id = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getUserCharts (callbackFunction = null, errorCallbackFunction = null) {
    return Api.get(`/api/charts/user`).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getChartData (callbackFunction = null, errorCallbackFunction = null) {
    return Api.get(`/api/charts/data`).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static createUpdateUserCharts (userChartObj, callbackFunction = null, errorCallbackFunction = null) {
    return Api.post(`/api/charts`, userChartObj).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
