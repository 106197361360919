import { make } from 'vuex-pathify'

const state = {
  visible: false,
  method: 'email',
  fileType: null,
  file: {},
  shops: [],
  options: []
}

// generate mutations based on fields in state
// e.g. visibile -> SET_VISIBLE
const mutations = make.mutations(state)

const getters = {}

const actions = {
  openEmail({ commit }, { fileObj, fileType, shops, options = [] }) {
    commit('SET_METHOD', 'email')
    commit('SET_FILE_TYPE', fileType)
    commit('SET_FILE', fileObj)
    commit('SET_SHOPS', shops)
    commit('SET_VISIBLE', true)
    commit('SET_OPTIONS', options)
  },
  openSms({ commit }, { fileObj, fileType, options = [] }) {
    commit('SET_METHOD', 'sms')
    commit('SET_FILE_TYPE', fileType)
    commit('SET_FILE', fileObj)
    commit('SET_SHOPS', shops)
    commit('SET_VISIBLE', true)
    commit('SET_OPTIONS', options)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
