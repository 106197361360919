// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".filestack-picker .picker-container {\n  width: 100%;\n  height: 560px;\n}\n.filestack-picker .fsp-picker--inline .fsp-picker,\n.filestack-picker .fsp-picker--inline .fsp-modal,\n.filestack-picker .fsp-picker--inline .fsp-modal__sidebar,\n.filestack-picker .fsp-picker--inline .fsp-header {\n  background-color: #fff;\n}\n.filestack-picker .fsp-modal__sidebar:hover,\n.filestack-picker .fsp-source-list__item:focus {\n  -webkit-box-shadow: none;\n          box-shadow: none;\n}", "",{"version":3,"sources":["/app/src/components/Helpers/FilestackPicker.vue"],"names":[],"mappings":"AA4FE;EACE,WAAA;EACA,aAAA;AAhFJ;AAmFI;;;;EAIE,sBAAA;AAjFN;AAoFE;;EAEE,wBAAA;UAAA,gBAAA;AAlFJ","file":"FilestackPicker.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.filestack-picker .picker-container {\n  width: 100%;\n  height: 560px;\n}\n.filestack-picker .fsp-picker--inline .fsp-picker,\n.filestack-picker .fsp-picker--inline .fsp-modal,\n.filestack-picker .fsp-picker--inline .fsp-modal__sidebar,\n.filestack-picker .fsp-picker--inline .fsp-header {\n  background-color: #fff;\n}\n.filestack-picker .fsp-modal__sidebar:hover,\n.filestack-picker .fsp-source-list__item:focus {\n  box-shadow: none;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
