var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { border: "", data: _vm.phoneNumbers } },
    [
      _c("el-table-column", { attrs: { prop: "name", label: "Name" } }),
      _c("el-table-column", {
        attrs: { prop: "sid", label: "SID", align: "center", width: "45" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("el-tooltip", { attrs: { effect: "light" } }, [
                  _c("i", { staticClass: "material-icons md-info md-18" }),
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c("span", [_vm._v(_vm._s(scope.row.sid))])
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "id", label: "Default", align: "center", width: "75" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                scope.row.id === _vm.defaultPhoneNumberId
                  ? _c(
                      "el-tag",
                      {
                        attrs: { type: "success", size: "mini", effect: "dark" }
                      },
                      [_vm._v(" Yes ")]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "phone_number", label: "Phone number", width: "115" }
      }),
      _c("el-table-column", {
        attrs: { fixed: "right", label: "Actions", width: "175" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("el-button", {
                  staticClass: "mb-2 mr-2",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-edit",
                    disabled: _vm.disabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleEdit(scope.row)
                    }
                  }
                }),
                _c("el-button", {
                  staticClass: "ml-0",
                  attrs: {
                    size: "mini",
                    type: "danger",
                    icon: "el-icon-delete",
                    disabled: _vm.disabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleDelete(scope.row)
                    }
                  }
                }),
                scope.row.id !== _vm.defaultPhoneNumberId
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Set default",
                          placement: "top",
                          "open-delay": 300
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            size: "mini",
                            type: "success",
                            plain: "",
                            icon: "el-icon-check",
                            disabled: _vm.disabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "update:defaultPhoneNumberId",
                                scope.row.id
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }