<template>
  <div class="t-flex-grow">
    <div class="t-flex t-flex-col">
      <form 
        id="payment-form" 
        :class="{
          hide: !loaded,
        }"
      >
        <div id="amount" style="display: none;">
          <input name="amount" :value="amount" />
        </div>
        <div class="form-row clover-form-row t-flex t-justify-center">
          <div
            id="card-number"
            class=""
          ></div>
          <div class="input-errors" id="card-number-errors" role="alert"></div>
        </div>
        <div class="form-row clover-form-row t-flex t-justify-center">
          <div id="card-date" class=""></div>
          <div class="input-errors" id="card-date-errors" role="alert"></div>
        </div>
        <div class="form-row clover-form-row t-flex t-justify-center">
          <div id="card-cvv" class=""></div>
          <div class="input-errors" id="card-cvv-errors" role="alert"></div>
        </div>
        <div class="form-row clover-form-row t-flex t-justify-center">
          <div id="card-postal-code" class=""></div>
          <div class="input-errors" id="card-postal-code-errors" role="alert"></div>
        </div>
        <div id="card-response" role="alert"></div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['amount', 'loaded']
};
</script>

<style>
.hide {
  display: none;
}
.input-errors {
  font-size: 12px;
  position: absolute;
  margin-left: 5px;
  margin-top: 35px;
  color: red;
}
.clover-form-row {
  height: 55px;
}
</style>