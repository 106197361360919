var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-px-5 t-py-1.5 t-cursor-pointer t-text-gray-400 hover:t-bg-[#F6F9FC] t-flex t-items-center t-justify-between",
      class: {
        "t-bg-[#F6F9FC] t-text-blue-800 t-border-0 t-border-solid t-border-l-4 t-border-blue-600":
          _vm.active
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }