import { mutations } from './types';

export default {
  [mutations.setIsOpened](state, payload) {
    state.isOpened = payload;
  },

  [mutations.setStatus](state, status) {
    state.status = status;
  },

  [mutations.setSettings](state, data) {
    data.phone = data?.phone || '';
    data.email = data?.email || '';
    state.settings = data;
    state.settings_loaded = true;
  },

  [mutations.changeSettings](state, settings) {
    state.settings = settings;
  },

  [mutations.setEventsSettings](state, rows) {

    // add data to state
    for (const row of rows) {
      // set default values for nullable keys
      row.web_enabled = row.web_enabled || false;
      row.shop_ids = row.shop_ids || [];
      row.sms_phones = row.sms_phones || [];
      row.email_addresses = row.email_addresses || [];

      state.settings_events[row.event_type] = row;
    }
    state.settings_events_loaded = true;
  },

  [mutations.changeEventsSettings](state, settingsItem) {
    state.settings[settingsItem.event_type] = settingsItem;
  },

};
