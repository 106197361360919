<template>
  <li class="consumer-search-result">
    <el-divider content-position="left" v-if="label">{{ label }}</el-divider>
    <div class="consumer-search-result-content" :class="{ active, selected }">
      <div>
        <div class="name">
          <span class="phone-number" v-if="phone_number" v-html="phoneString" />
          <span v-html="nameString" />
        </div>
        <div class="result-meta">
          <div class="shop-name" v-if="shop_name">{{ shop_name }}</div>
          <div class="trading-partner" v-if="trading_partner">
            {{ trading_partner }}
          </div>
        </div>
      </div>
      <consumer-tags v-bind="$props" />
    </div>
  </li>
</template>

<script>
import ConsumerTags from '../../helpers/ConsumerTags'
import * as R from 'ramda'
import { formatPhone } from '@/scripts/helpers/consumer.helpers'

export default {
  components: {
    ConsumerTags
  },
  props: [
    'full_name',
    'shop_name',
    'phone_number',
    'match_start',
    'match_end',
    'is_commercial',
    'is_insurance',
    'company_name',
    'active',
    'loading',
    'label',
    'selected',
    'phone_match_end',
    'trading_partner',
    'status'
  ],
  computed: {
    nameString() {
      const { match_start = 1, match_end = 1, full_name } = this
      return (
        full_name &&
        R.pipe(
          R.insert(
            match_start - 1,
            '<span class="consumer-search-result-match">'
          ),
          R.insert(match_end, '</span>'),
          R.join('')
        )(full_name)
      )
    },
    phone() {
      return formatPhone(this.phone_number)
    },
    phoneString() {
      const { phone_match_end, phone_number } = this
      let counter = 0
      return phone_number && phone_match_end
        ? R.pipe(
          R.split(''),
          R.reduce((acc, v) => {
            if (R.test(/[0-9]/, v)) {
              counter++
            }
            if (counter === 0) {
              return `${acc}<span class="consumer-search-result-match">${v}`
            }
            if (counter === phone_match_end - 1) {
              return `${acc}${v}</span>`
            }
            return `${acc}${v}`
          }, '')
        )(phone_number)
        : phone_number
    }
  }
}
</script>

<style lang="scss" scoped>
.consumer-search-result {
  .consumer-search-result-content {
    padding: $padding-xs $padding-md;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &.active, &:focus {
      background: #6db5ff6e;
    }
    &.selected {
      color: $primary;
      .shop_name {
        color: $primary;
      }
    }
    .name {
      font-size: $font-md;
      .phone-number {
        &:after {
          content: "◦";
          margin: 0 $padding-xxs;
        }
      }
    }
    .result-meta {
      display: flex;
      color: $sub-text-color;
      font-size: $font-sm;
      .shop-name + .trading-partner {
        &:before {
          margin: 0 $padding-xxs;
          content: "∙";
        }
      }
      .trading-partner {
        text-transform: uppercase;
      }
    }
  }
  .el-divider {
    margin: $padding-sm 0 $padding-xs;
    .el-divider__text {
      text-transform: uppercase;
      color: #999;
      letter-spacing: 0.1em;
      line-height: 12px;
      font-size: $font-xs;
      padding: 0 $padding-xs;
    }
  }
}
</style>
<style lang="scss">
.consumer-search-result-match {
  font-weight: bolder;
  text-decoration: underline;
}
</style>
