import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class GlaxisClaim extends ObjectTracker {
  id = -1
  shop_id = -1
  environment_id = ''
  origination_pid = ''
  origination_company_id = ''
  destination_pid = ''
  transaction_date = ''
  transaction_time = ''
  created = -1
  modified = -1
  created_by = -1
  created_by_name = ''
  modified_by = -1
  modified_by_name = ''
  trading_partner = ''
  first_name = ''
  last_name = ''
  reference_number = ''
  contact_number = ''
  status = ''
  response_code = ''
  response_details = ''
  filename = ''
  archived = 0
  job_id = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getForShop (
    shopId,
    showArchive,
    { page, limit },
    { field, order },
    { internalId, jobId, dateSubmitted, firstName, lastName, referenceNumber, contactNumber, status, response },
    successCallbackFunction,
    errorCallbackFunction
  ) {
    Api.get('/api/shops/' + encodeURIComponent(shopId) + '/glaxis-claims', {
      params: {
        page,
        limit,
        sort: field,
        order,
        internalId,
        jobId,
        dateSubmitted,
        firstName,
        lastName,
        referenceNumber,
        contactNumber,
        status,
        response,
        archived: showArchive
      }
    }).then(res => {
      successCallbackFunction(res)
    }, err => {
      errorCallbackFunction(err)
    })
  }

  static handleClaimArchive (
    glaxisClaimId, archive,
    successCallbackFunction,
    errorCallbackFunction
  ) {
    Api.post('/api/glaxis-claims/' + encodeURIComponent(glaxisClaimId) + '/' + (archive ? 'archive' : 'unarchive')).then(res => {
      successCallbackFunction(res)
    }, err => {
      errorCallbackFunction(err)
    })
  }

  static getClaim (
    glaxisClaimId,
    successCallbackFunction,
    errorCallbackFunction
  ) {
    Api.get('/api/glaxis-claims/' + encodeURIComponent(glaxisClaimId)).then(res => {
      successCallbackFunction(res.data)
    }, err => {
      errorCallbackFunction(err)
    })
  }

  static getForJob (jobId, successCallbackFunction, errorCallbackFunction) {
    Api.get(`/api/glaxis-loss-notice/job/${encodeURIComponent(jobId)}`).then(res => {
      successCallbackFunction(res.data)
    }, err => {
      errorCallbackFunction(err)
    })
  }

  static getDetailsForJob (jobId, successCallbackFunction, errorCallbackFunction) {
    Api.get(`/api/glaxis-loss-notice/job-details/${encodeURIComponent(jobId)}`).then(res => {
      successCallbackFunction(res.data)
    }, err => {
      errorCallbackFunction(err)
    })
  }
}
