var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length
    ? _c("div", { staticClass: "scheduler-unmapped-jobs" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "item-container" },
          _vm._l(_vm.items, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "item",
                style: _vm.getStyle(item),
                on: {
                  click: function($event) {
                    return _vm.$emit("item-clicked", item)
                  }
                }
              },
              [
                _c("scheduler-map-infowindow", { attrs: { marker: item } }),
                _vm._v(" " + _vm._s(item.order) + " ")
              ],
              1
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", [_vm._v("Unmapped locations:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }