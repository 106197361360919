<template>
  <el-dialog
    append-to-body
    top="120px"
    width="1000px"
    :visible="visible"
    :before-close="handleClose"
    custom-class="add-payment-dialog t-font-sans"
  >
    <span slot="title" class="t-text-gray-700 t-text-lg">
      <i class="el-icon-bank-card"></i>
      <span class="t-ml-3">
        Add payment
        <span v-if="consumer">
          from
          {{
            consumer.is_insurance || consumer.is_commercial
              ? consumer.company_name
              : consumer.first_name + ' ' + consumer.last_name
          }}
        </span>
      </span>
    </span>
    <div class="t-pt-6">
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="165px"
        hide-required-asterisk
        size="medium"
        style="max-height: 700px"
        class="t-overflow-y-auto"
      >
        <div class="t-flex">
          <div class="t-w-108">
            <el-form-item prop="type">
              <span slot="label" class="undo-label-styles">Type</span>
              <el-select v-model="form.type" class="t-w-full">
                <el-option label="Payment" value="Payment"/>
                <el-option label="Adjustment" value="Adjustment"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="date">
              <span slot="label" class="undo-label-styles">Date</span>
              <el-date-picker
                v-model="form.date"
                type="date"
                placeholder="Pick a date"
                class="t-w-full"
              />
            </el-form-item>
            <el-form-item prop="xActionType">
              <span slot="label" class="undo-label-styles">
                X-action Type
              </span>
              <el-select
                v-model="form.xActionType"
                placeholder="Select X-action Type"
                class="t-w-full"
              >
                <el-option
                  v-for="item in xActionTypes[form.type]"
                  :key="item"
                  :label="item"
                  :value="item"
                  :disabled="item === 'Card' && !authorizeEnabled && !squareEnabled && !cloverEnabled"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="form.xActionType === 'Card' && paymentProcessor"
            >
              <span slot="label" class="undo-label-styles">
                Payment Processor
              </span>
              <el-input
                v-if="paymentProcessor === 'authorize'"
                value="Authorize.Net"
                readonly
              />
              <el-input
                v-if="paymentProcessor === 'square'"
                value="Square"
                readonly
              />
              <el-input
                v-if="paymentProcessor === 'clover'"
                value="Clover"
                readonly
              />
            </el-form-item>
            <el-form-item
              v-if="form.xActionType !== 'Card'"
              prop="xActionNumber"
            >
              <span slot="label" class="undo-label-styles">
                X-action Number
              </span>
              <el-input
                placeholder="Enter X-action Number"
                v-model="form.xActionNumber"
                class="t-w-full"
              />
            </el-form-item>
            <el-form-item v-if="shopHasQbo && form.type === 'Payment'">
              <span slot="label" class="undo-label-styles">
                Deposit to:
              </span>
              {{truncateString(defaultQboAccount)}}
              <span style="margin-left: 15px" v-if="adminCheck() || accountManagerCheck()">
                <a href="javascript:void(0)" @click="onOpenQboMapping(shopId)">QBO Mapping</a>
              </span>
            </el-form-item>
          </div>
          <div class="t-flex-grow">
            <div
              v-if="
                form.xActionType !== 'Card' &&
                !squareTerminalConnecting &&
                !cloverTerminalConnecting
              "
            >
              <div
                class="t-flex t-justify-center"
              >
                <div
                  v-for="(card, index) in processorCards"
                  :key="card.label"
                  :class="{
                    'device-card-border': index > 0,
                    't-h-36': index === 1
                  }"
                >
                  <device-card
                    :label="card.label"
                    :beta="card.betaFlag"
                    :device="card.device"
                    @selected="card.action"
                    >
                  </device-card>
                </div>
              </div>
              <div
                class="t-text-right"
                :class="{
                  't-pr-[120px]': processorCards.length > 1,
                  't-pr-[208px]': processorCards.length <= 1
                }"
                v-if="
                  canChooseDevice &&
                  !squareTerminalConnecting &&
                  !cloverTerminalConnecting &&
                  form.xActionType !== 'Card'
                "
              >
                <el-dropdown
                  @command="choseDevice" trigger="click">
                  <span class="dropdown-link">
                    Choose device<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="device in availableProcessorDevices"
                      :key="device.id"
                      :command="device">
                      {{ device.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div
              class="t-flex t-flex-col"
              v-else
            >
              <div class="t-mb-2 t-px-6">
                <el-button
                  type="primary"
                  icon="el-icon-back"
                  size="mini"
                  plain
                  round
                  v-if="form.xActionType === 'Card'"
                  @click="backClicked"
                >
                  Back
                </el-button>
              </div>
              <CardForm
                class="t-px-6"
                v-if="
                  form.xActionType === 'Card' &&
                  paymentProcessor === 'authorize' &&
                  authorizeScriptLoaded
                "
                :name.sync="card.name"
                :number.sync="card.number"
                :month.sync="card.month"
                :year.sync="card.year"
                :cvv.sync="card.cvv"
                :zip.sync="card.zip"
              />
              <div
                v-if="
                  form.xActionType === 'Card' &&
                  paymentProcessor === 'square' &&
                  !squareTerminalConnecting &&
                  squareScriptLoaded
                "
                class="t-ml-12 t-px-6 t-flex t-justify-center"
              >
                <div
                  id="card-container"
                  class="t-px-6 t-pt-6 t-pb-2 t-flex t-flex-col t-items-center
                        t-border t-border-solid t-border-blue-400 t-rounded-md"
                >
                </div>
              </div>
              <div>
                <CloverCardForm
                  v-if="
                    form.xActionType === 'Card' &&
                    paymentProcessor === 'clover' &&
                    cloverScriptLoaded &&
                    !cloverTerminalConnecting
                  "
                  :amount="total"
                  :loaded="cloverFormLoaded"
                  class="t-px-6"
                />
              </div>
              <div
                class="t-flex-grow"
                v-if="cloverTerminalConnecting || squareTerminalConnecting"
              >
                <div class="t-flex t-justify-center">
                  <DeviceForm
                    class="t-px-6"
                    :status="terminalStatus"
                    :connected="terminalConnected"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(invoice, index) of invoices"
          :key="index"
          class="t-mx-6 t-my-3 t-bg-gray-100"
        >
          <div
            class="t-flex t-border t-border-solid t-border-gray-300 t-rounded-md
                   t-my-2 t-px-4 t-py-2"
          >
            <div class="t-flex-grow t-flex t-justify-between t-pr-8">
              <div>
                <div class="t-text-gray-500 t-text-xs">Job id</div>
                <div class="t-text-base">
                  <a :href="`/jobs/${invoice.job_id}`" target="_blank">
                    {{ invoice.job_id }}
                  </a>
                </div>
              </div>
              <div>
                <div class="t-text-gray-500 t-text-xs">Total balance</div>
                <div class="t-text-base">${{ invoice.total_balance }}</div>
              </div>
              <div>
                <div class="t-text-gray-500 t-text-xs">Remaining balance</div>
                <div class="t-text-base">${{ invoice.remaining_balance }}</div>
              </div>
            </div>
            <div class="t-w-72">
              <el-form-item
                label-width="0"
                class="t-mb-1"
                :prop="'payments.' + index + '.amount'"
                :show-message="false"
                :rules="[
                  {
                    required: true,
                  },
                ]"
              >
                <el-input
                  v-model="form.payments[index].amount"
                  size="small"
                  type="number"
                  placeholder="Amount"
                  class="t-w-full"
                />
              </el-form-item>
              <el-input
                size="small"
                placeholder="Memo"
                v-model="form.payments[index].memo"
              />
            </div>
          </div>
        </div>
      </el-form>
      <div
        class="t-flex t-items-baseline t-py-6 t-px-6 t-border-0 t-border-t t-border-solid t-border-gray-300"
      >
        <div
          class="t-text-gray-600 t-mr-2 t-text-base t-flex-grow t-text-right"
        >
          Total
        </div>
        <div class="t-text-xl t-font-medium">${{ total }}</div>
      </div>
      <div class="t-bg-gray-200 t-py-3 t-px-6 t-flex t-justify-between">
        <el-button plain @click="handleClose" size="small"> Close</el-button>
        <el-button
          type="primary"
          @click="handleSubmit"
          :loading="loading"
          icon="el-icon-plus"
          size="small"
        >
          Add
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import CardForm from './CardForm/Index'
import COAccount from '@/scripts/objects/co_account'
import { paymentGroupRepository } from '@/scripts/repositories/paymentGroup'
import { getFormattedDateTimeWithUserTimezone } from '@/scripts/helpers/date.helpers'
import CloverCardForm from './CloverCardForm/Index'
import DeviceCard from './DeviceCard/DeviceCard.vue'
import Shop from '@/scripts/objects/shop'
import { CloverDeviceService } from '@/services/CloverDeviceService'
import { sync, call } from 'vuex-pathify'
import DeviceForm from './DeviceForm/Index'
import { mapState, mapActions } from 'pinia'
import { useQboStore } from '@/components/modals/QuickBooksAccountMappingDialog/qbo.store.js'

export default {
  props: {
    visible: Boolean,
    shopId: Number,
    invoices: Array,
    consumer: Object
  },

  data: () => ({
    form: {
      type: 'Payment',
      date: moment(),
      xActionType: '',
      xActionNumber: '',
      paymentAccountId: null,
      payments: [],
      processor: ''
    },
    card: {
      name: '',
      number: '',
      month: '',
      year: '',
      cvv: '',
      zip: ''
    },
    squareForm: null,
    rules: {
      date: { required: true, message: 'Date is required', trigger: 'blur' },
      xActionType: {
        required: true,
        message: 'X-action type is required',
        trigger: 'blur'
      }
    },
    xActionTypes: {
      Payment: [
        // 'Card',
        'Manual Card',
        'Cash',
        'Check',
        'Bank Transfer',
        'Other'
      ],
      Adjustment: [
        'Promo',
        'Coupon',
        'Credit',
        'Short Pay',
        'Write Off',
        'Deductible Discount',
        'Fee',
        'Doubtful Account',
        'Other'
      ]
    },
    loading: false,
    accounts: [],
    authorizeScriptLoaded: false,
    squareScriptLoaded: false,
    cloverScriptLoaded: false,
    squareCard: null,
    cloverInstance: null,
    cloverFormLoaded: false,
    processorCards: [],
    waitingForCloverSwipe: false,
    processorDeviceToUse: null,
    availableProcessorDevices: [],
    cloverTerminalConnecting: false,
    devicePaymentGroupId: null,
    squareTerminalConnecting: false,
    terminalStatus: null,
    terminalConnected: false,
    terminalTimeout: null,
    canChooseDevice: false,
    squareCancelMessage: {
      SELLER_CANCELED: 'Canceled by shop',
      BUYER_CANCELED: 'Canceled by customer'
    },
    defaultQboAccount: 'System Default'
  }),

  mounted() {
    this.form.date = moment(getFormattedDateTimeWithUserTimezone(this.$store, new Date(), 'YYYY-MM-DD'), 'YYYY-MM-DD')
  },

  computed: {
    ...mapState(useQboStore, { qboMappingDialogIsVisible: 'visible' }),
    total: function() {
      let total = 0
      for (const i of this.form.payments) {
        total += parseFloat(i.amount)
      }
      return this.formatAmount(total)
    },
    shop: function() {
      return this.$store.state.shops.find(i => i.id === this.shopId)
    },
    shopHasQbo: function() {
      return this.shop?.qbo_key?.length > 0
    },
    isProd: function() {
      return process.env.NODE_ENV === 'production'
    },
    paymentProcessor: function() {
      if (this.authorizeEnabled) {
        return 'authorize'
      } else if (this.squareEnabled) {
        return 'square'
      } else if (this.cloverEnabled) {
        return 'clover'
      } else {
        return null
      }
    },
    authorizeEnabled: function() {
      return (
        !!this.shop?.authorizenet_public_client_key &&
        !!this.shop?.authorizenet_api_login_id &&
        !!this.shop?.authorizenet_transaction_key
      )
    },
    squareEnabled: function() {
      return !!this.shop?.square_merchant_id
    },
    cloverEnabled: function() {
      return !!this.shop?.clover_merchant_id
    },
    deviceType: function() {
      if (this.squareEnabled) return 'square'
      if (this.cloverEnabled) return 'clover'
      return null
    },
    squareTerminalCheckout: sync('square/checkout/paymentResponse'),
    squareTerminalDeviceId: sync('square/checkout/deviceId')
  },

  watch: {
    visible: function(value) {
      if (value) {
        this.setProcessorCards()
        this.getDevices()
        this.getCoAccounts()
        this.loadScripts()
      } else {
        this.clear()
      }
    },
    invoices: {
      handler: function() {
        this.form.payments = this.invoices.map(i => {
          return {
            jobId: i.job_id,
            consumerId: i.consumer_id,
            amount: i.amount,
            type: i.type,
            memo: ''
          }
        })
      },
      deep: true
    },
    'form.xActionType': async function(value) {
      if (
        this.paymentProcessor === 'square' &&
        this.squareScriptLoaded &&
        value === 'Card' &&
        !this.squareTerminalConnecting
      ) {
        this.loadSquareForm()
      }
      if (
        this.paymentProcessor === 'clover' &&
        this.cloverScriptLoaded &&
        value === 'Card' &&
        !this.cloverTerminalConnecting
      ) {
        this.loadCloverForm()
      }
      // set default account for selected payment type
      await this.setShopDefaultQboAccount()
    },
    'form.type': function(val, oldVal) {
      if (val !== oldVal) {
        this.form.xActionType = ''
      }
    },
    squareTerminalCheckout: async function (val) {
      if (val?.status) {
        const { status } = val
        if (status === 'COMPLETED') {
          await this.finishSquareTerminalPayment()
        } else if (status === 'CANCELED') {
          // to handle: can receive cancelled and then completed
          await this.handleSquareTerminalPaymentCancelled(val.cancel_reason)
        } else if (status === 'CREATED') {
          if (!this.terminalConnected) {
            this.terminalStatus = 'Connecting to terminal'
          }
        } else if (status === 'IN_PROGRESS') {
          this.terminalConnected = true
          this.terminalStatus = 'Sent payment to terminal, waiting for customer'
          if (this.terminalTimeout) {
            clearTimeout(this.terminalTimeout)
          }
        }
      }
    },
    qboMappingDialogIsVisible: async function (val, oldVal) {
      if (this.visible && val === false && oldVal === true) {
        await this.setShopDefaultQboAccount()
      }
    }
  },

  methods: {
    ...mapActions(useQboStore, {
      viewQboMapping: 'openView',
      getShopDefaultQboAccount: 'getShopDefaultQboAccount'
    }),
    onOpenQboMapping(shopId) {
      this.viewQboMapping(shopId)
    },
    setProcessorCards: function() {
      this.processorCards = []
      if (this.authorizeEnabled || this.squareEnabled || this.cloverEnabled) {
        this.processorCards.push({
          label: 'Virtual Terminal',
          device: null,
          action: () => {
            this.form.xActionType = 'Card'
          }
        })
      }
    },
    handleClose: function() {
      this.$emit('close')
    },
    formatAmount: amount => Math.round(amount * 100) / 100,
    handleSubmit: async function() {
      try {
        this.loading = true
        await this.$refs.form.validate()
        if (
          this.paymentProcessor === 'authorize' &&
          this.form.xActionType === 'Card'
        ) {
          this.form.processor = this.paymentProcessor
          if (
            !this.card.name ||
            !this.card.number ||
            !this.card.month ||
            !this.card.year ||
            !this.card.cvv ||
            !this.card.zip
          ) {
            this.$message.warning('All card fields are required')
            this.loading = false
          } else {
            const authData = {
              clientKey: this.shop.authorizenet_public_client_key,
              apiLoginID: this.shop.authorizenet_api_login_id
            }
            const cardData = {
              cardNumber: this.card.number.replace(/\s/g, ''),
              month: this.card.month,
              year: this.card.year.substr(2),
              cardCode: this.card.cvv,
              zip: this.card.zip,
              fullName: this.card.name
            }
            const vm = this
            // eslint-disable-next-line no-undef
            Accept.dispatchData({ authData, cardData }, async function(res) {
              if (res.messages.resultCode === 'Error') {
                vm.$message.error(res.messages.message[0].text)
                vm.loading = false
              } else {
                await paymentGroupRepository.add({
                  shopId: vm.shopId,
                  ...vm.form,
                  authorizeDataDescriptor: res.opaqueData.dataDescriptor,
                  authorizeDataValue: res.opaqueData.dataValue
                })
                vm.$emit('added')
                vm.$message.success('Payment was successfully added')
                vm.loading = false
              }
            })
          }
        } else if (
          this.paymentProcessor === 'square' &&
          this.form.xActionType === 'Card'
        ) {
          this.form.processor = this.paymentProcessor
          this.processSquarePayment()
        } else if (
          this.paymentProcessor === 'clover' &&
          this.form.xActionType === 'Card'
        ) {
          this.form.processor = this.paymentProcessor
          this.processCloverPayment()
        } else {
          // internal payment create
          await paymentGroupRepository.add({
            shopId: this.shopId,
            ...this.form
          })
          this.$emit('added')
          this.$message.success('Payment was successfully added')
          this.loading = false
        }
      } catch {
        this.loading = false
      }
    },
    async processSquarePayment() {
      try {
        const token = await this.tokenize(this.squareCard);
        await paymentGroupRepository.add({
          shopId: this.shopId,
          ...this.form,
          squareToken: token
        })
        this.$emit('added')
        this.$message.success('Payment was successfully added')
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async processCloverPayment() {
      try {
        const tokenData = await this.cloverInstance.createToken()
        if (tokenData && tokenData.errors) {
          let errorMessage = ''
          Object.values(tokenData.errors).forEach(v => {
            if (errorMessage.length) errorMessage += ', '
            errorMessage += v
          })
          if (errorMessage.length === 0) {
            errorMessage = 'there was an error trying to authorize this card'
          }

          this.$message.error(errorMessage)
          this.loading = false
          return
        }
        await paymentGroupRepository.add({
          shopId: this.shopId,
          ...this.form,
          cloverToken: tokenData
        })
        this.$emit('added')
        this.$message.success('Payment was successfully added')
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }
        throw new Error(errorMessage);
      }
    },
    getCoAccounts: async function() {
      if (this.shop?.accounting_package_id > 0) {
        let accounts = []
        await COAccount.getChartOfAccountsObjects(
          this.shop.accounting_package_id,
          null,
          null,
          function(results) {
            accounts = results
          }
        )
        this.accounts = accounts.filter(
          i => i.active === 1 && i.account_type === 'Liabilities'
        )
      }
    },
    getDevices: async function() {
      try {
        const cookieDevice = this.$cookies.get(this.deviceType)
        const {data} = await Shop.getAvailableDevices(this.shopId)
        if (data) {
          let cookieDeviceFound = false
          if (cookieDevice) {
            for (var i = 0; i < data.length; i++) {
              if (data[i].id === cookieDevice.id) {
                cookieDeviceFound = true
                break
              }
            }
            if (cookieDeviceFound) {
              this.processorCards.push({
                label: cookieDevice.name,
                betaFlag: true,
                device: cookieDevice,
                action: () => {
                  this.sendToDevice(cookieDevice)
                }
              })
            }
          }
          this.availableProcessorDevices = data
          if (data.length > 1) {
            this.canChooseDevice = true
          } else if (data.length === 1 && !cookieDeviceFound) {
            this.$cookies.set(this.deviceType, data[0], '30d')
            this.processorCards.push({
            label: data[0].name,
            betaFlag: true,
            device: data[0],
            action: () => {
              this.sendToDevice(data[0])
            }
          })
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    choseDevice: function (device) {
      if (this.processorCards.length === 2) {
        this.processorCards[1] = {
          label: device.name,
          betaFlag: true,
          device: device,
          action: () => {
            this.sendToDevice(device)
          }
        }
      } else if (this.processorCards.length === 1) {
        this.processorCards.push({
          label: device.name,
          betaFlag: true,
          device: device,
          action: () => {
            this.sendToDevice(device)
          }
        })
      }
      this.sendToDevice(device)
    },
    sendToDevice: function(device) {
      console.log(device)
      this.$cookies.set(this.deviceType, device, '30d')
      this.form.xActionType = 'Card'
      this.terminalStatus = 'Preparing to connect'

      if (this.deviceType === 'clover') {
        this.cloverTerminalConnecting = true
        this.sendPaymentToCloverDevice(device)
      } else if (this.deviceType === 'square') {
        this.sendPaymentToSquareDevice(device)
      }
    },
    async sendPaymentToCloverDevice(device) {
      try {
        const {data} = await paymentGroupRepository.addEmpty({
          shopId: this.shopId,
          ...this.form,
          xActionType: 'Card',
          processor: 'clover',
        })
        this.devicePaymentGroupId = data.paymentGroupId
        this.cloverTerminalConnecting = true
        this.loading = true
        this.terminalStatus = 'Connecting to terminal'
        this.terminalTimeout = setTimeout(() => {
          this.$messageBox.alert(`It is taking a while to connect, please ensure a payment request has
          not already been sent, the terminal is connected to the internet and "Cloud Pay Display" app
          is open on the terminal.`, 'Attempt')
        }, 7500)
        this.initializeService(device)
      } catch (err) {
        console.log(err)
      }
    },
    async initializeService (device) {
      // go get access token
      const {data} = await Shop.getDeviceCredentials(this.shop.id)
      await CloverDeviceService.init({
        clover_merchant_id: this.shop.clover_merchant_id,
        clover_access_token: data
      }, device, this.sendCloverPayment)
    },
    async sendCloverPayment () {
      this.loading = true
      try {
        this.$messageBox.close()
      } catch {}
      this.terminalConnected = true
      this.terminalStatus = 'Sent payment to terminal, waiting for customer'
      if (this.terminalTimeout) {
        clearTimeout(this.terminalTimeout)
      }
      CloverDeviceService.createSale(
        (parseFloat(this.total) * 100),
        this.devicePaymentGroupId,
        this.afterSaleCallback,
        this.errorCallback
      )
    },
    async afterSaleCallback (paymentData) {
      try {
        await paymentGroupRepository.add({
          shopId: this.shopId,
          ...this.form,
          xActionType: 'Card',
          processor: 'clover',
          cloverToken: {
            ...paymentData,
            terminalCharge: true
          },
          paymentGroupId: this.devicePaymentGroupId
        })
        this.devicePaymentGroupId = null

        this.$emit('added')
        this.$message.success('Payment was successfully added')
        this.loading = false
      } catch (err) {

      }

      this.loading = false
      this.cloverTerminalConnecting = false
      CloverDeviceService.connectDispose()
    },
    async errorCallback (paymentData) {
      this.$message.error(paymentData.message)
      this.resetCloverDevice()
      this.backClicked()
    },
    async resetCloverDevice () {
      if (this.devicePaymentGroupId) {
        await paymentGroupRepository.remove(this.devicePaymentGroupId)
        this.devicePaymentGroupId = null
      }
      this.cloverTerminalConnecting = false
      this.terminalStatus = null
      this.terminalConnected = false
      this.loading = false
      CloverDeviceService.cancelSale()
    },
    clear: async function() {
      this.form.type = 'Payment'
      this.form.date = moment(getFormattedDateTimeWithUserTimezone(this.$store, new Date(), 'YYYY-MM-DD'), 'YYYY-MM-DD')
      this.form.xActionType = ''
      this.form.xActionNumber = ''
      this.form.paymentAccountId = null
      this.card = {
        name: '',
        number: '',
        month: '',
        year: '',
        cvv: '',
        zip: ''
      }
      if (CloverDeviceService) {
        CloverDeviceService.connectDispose()
      }
      if (this.squareTerminalConnecting) {
        await this.cancelSquareTerminalPayment()
      }
      this.resetSquareDevice()
      this.processorCards = []
      this.waitingForCloverSwipe = false
      this.processorDeviceToUse = null
      this.availableProcessorDevices = []
      if (this.cloverTerminalConnecting) {
        this.resetCloverDevice()
      }
      this.cloverTerminalConnecting = false
    },
    async backClicked() {
      if (this.squareTerminalConnecting) {
        await this.cancelSquareTerminalPayment()
      } else {
        this.form.xActionType = ''
        if (this.terminalTimeout) {
          clearTimeout(this.terminalTimeout)
        }
        if (this.cloverTerminalConnecting) {
          this.resetCloverDevice()
        }
      }
    },
    loadScripts: async function() {
      if (this.paymentProcessor === 'authorize' && !this.authorizeScriptLoaded) {
        await this.$loadScript(
          this.isProd
            ? 'https://js.authorize.net/v1/Accept.js'
            : 'https://jstest.authorize.net/v1/Accept.js'
        )
        this.authorizeScriptLoaded = true
      }
      if (this.paymentProcessor === 'square' && !this.squareScriptLoaded) {
        await this.$loadScript(
          this.isProd
            ? 'https://web.squarecdn.com/v1/square.js'
            : 'https://sandbox.web.squarecdn.com/v1/square.js'
        )
        this.squareScriptLoaded = true
      }
      if (this.paymentProcessor === 'clover' && !this.cloverScriptLoaded && !this.cloverTerminalConnecting) {
        await this.$loadScript(
          this.isProd
          ? 'https://checkout.clover.com/sdk.js'
          : 'https://checkout.sandbox.dev.clover.com/sdk.js'
        )
        this.cloverScriptLoaded = true
      }
    },
    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach('#card-container');
      return card;
    },
    loadSquareForm: async function() {
      if (this.squareScriptLoaded) {
        const payments = window.Square.payments(this.isProd ? 'sq0idp-P-5K6TPobCUQdpJzcBsfBw' : 'sandbox-sq0idb-rb4fRIvm1xiyU1hqdjsGmA')
        try {
          this.squareCard = await this.initializeCard(payments)
        } catch (e) {
          console.error('Initializing card failed', e)
        }
      }
    },
    loadCloverForm: async function() {
      if (this.cloverScriptLoaded) {
        this.$nextTick(() => {
          this.cloverInstance = new Clover(this.shop.clover_api_key)

          const styles = {
            body: {
              fontFamily: 'Roboto, Open Sans, sans-serif',
              fontSize: '12px'
            },
            input: {
              'padding-top': '0.1rem !important',
              'padding-left': '0.5rem !important',
              'border-style': 'solid',
              'border-radius': '0.5rem',
              'border-width': '4px',
              'border-color': '#dddddd',
              height: '2rem',
            }
          }
          const elements = this.cloverInstance.elements()
          const cardNumber = elements.create('CARD_NUMBER', styles);
          const cardDate = elements.create('CARD_DATE', styles);
          const cardCvv = elements.create('CARD_CVV', styles);
          const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);
            
          cardNumber.mount('#card-number');
          cardDate.mount('#card-date');
          cardCvv.mount('#card-cvv');
          cardPostalCode.mount('#card-postal-code');
          const displayCardNumberError = document.getElementById('card-number-errors');
          const displayCardDateError = document.getElementById('card-date-errors');
          const displayCardCvvError = document.getElementById('card-cvv-errors');
          const displayCardPostalCodeError = document.getElementById('card-postal-code-errors');

          cardNumber.addEventListener('change', function(event) {
            displayCardNumberError.innerHTML = event.CARD_NUMBER.error || '';
          });

          cardNumber.addEventListener('blur', function(event) {
            displayCardNumberError.innerHTML = event.CARD_NUMBER.error || '';
          });

          cardDate.addEventListener('change', function(event) {
            displayCardDateError.innerHTML = event.CARD_DATE.error || '';
          });

          cardDate.addEventListener('blur', function(event) {
            displayCardDateError.innerHTML = event.CARD_DATE.error || '';
          });

          cardCvv.addEventListener('change', function(event) {
            displayCardCvvError.innerHTML = event.CARD_CVV.error || '';
          });

          cardCvv.addEventListener('blur', function(event) {
            displayCardCvvError.innerHTML = event.CARD_CVV.error || '';
          });

          cardPostalCode.addEventListener('change', function(event) {
            displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || '';
          });

          cardPostalCode.addEventListener('blur', function(event) {
            displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || '';
          });
          this.cloverFormLoaded = true
          this.pollCloverFooter()
        })
      }
    },
    pollCloverFooter() {
      var interval = setInterval(() => {
        var footer = document.getElementsByClassName('clover-footer')
        if (footer && footer.length) {
          document.getElementById("payment-form").appendChild(footer[0])
          clearInterval(interval)
        }
      }, 1000)
    },
    async sendPaymentToSquareDevice(device) {
      // need to handle error (server unavailable)
      this.squareTerminalConnecting = true

      // Square Sandbox Device Ids:
      // https://developer.squareup.com/docs/devtools/sandbox/testing#terminal-checkout-test-device-ids

      const { device_id } = device
      this.squareTerminalDeviceId = device_id

      await this.$root.$emit('createSquareDeviceSubscription', this.shopId, device_id)

      const { data: { paymentGroupId } } = await paymentGroupRepository.addEmpty({
        shopId: this.shopId,
        ...this.form,
        xActionType: 'Card',
        processor: 'square'
      })

      this.devicePaymentGroupId = paymentGroupId

      const { data } = await Shop.startSquareCheckout(this.shopId, {
        deviceId: device_id,
        payments: this.form.payments,
        paymentGroupId: this.devicePaymentGroupId
      })

      this.terminalTimeout = setTimeout(() => {
        this.$messageBox.alert(`It is taking a while to connect, please ensure a payment request has
        not already been sent and the terminal is connected to the internet.`, 'Attempt')
      }, 7500)

      this.squareTerminalCheckout = data
    },
    async finishSquareTerminalPayment() {
      await paymentGroupRepository.add({
        shopId: this.shopId,
        ...this.form,
        xActionType: 'Card',
        processor: 'square',
        squareToken: {
          ...this.squareTerminalCheckout,
          terminalCharge: true
        },
        paymentGroupId: this.devicePaymentGroupId
      })

      await this.$root.$emit('removeSquareDeviceSubscription', this.shopId, this.squareTerminalDeviceId)

      this.$message.success('Payment was successfully added')
      this.squareTerminalConnecting = false
      this.$emit('added')
    },
    async cancelSquareTerminalPayment() {
      await Shop.cancelSquareCheckout(this.shopId, {
        checkoutId: this.squareTerminalCheckout.id
      })

      this.terminalStatus = 'Cancelling transaction'
    },
    async handleSquareTerminalPaymentCancelled(reason) {
      await this.$root.$emit('removeSquareDeviceSubscription', this.shopId, this.squareTerminalDeviceId)

      if (this.terminalTimeout) {
        clearTimeout(this.terminalTimeout)
      }

      const cancelMessage = this.squareCancelMessage[reason] || reason

      this.$message.warning(cancelMessage)
      this.resetSquareDevice()
      this.form.xActionType = ''
    },
    resetSquareDevice() {
      this.devicePaymentGroupId = null
      this.squareTerminalConnecting = false
      this.terminalStatus = null
      this.terminalConnected = false
      this.clearSquareState()
    },
    clearSquareState: call('square/checkout/clear'),
    setShopDefaultQboAccount: async function () {
      if (this.shopHasQbo) {
        if (this.form.type === 'Payment' && this.form.xActionType.length > 0) {
          try {
            const defaultQboAccount = await this.getShopDefaultQboAccount(
              this.shopId,
              `${this.form.xActionType} Payment`
            )

            this.defaultQboAccount = defaultQboAccount?.name || 'System Default'
          } catch (error) {
            this.$message.error(error.message)
          }
        } else {
          this.defaultQboAccount = 'System Default'
        }
      }
    },
    truncateString (name) {
      let formattedName
      if (name.length > 20) {
        formattedName = name.substring(0, 20) + '...'
      } else {
        formattedName = name
      }
      return formattedName
    },
    adminCheck () {
      return this.$store.state.user.admin
    },
    accountManagerCheck () {
      return this.$store.state.user.account_manager
    }
  },

  components: {
    CardForm,
    CloverCardForm,
    DeviceCard,
    DeviceForm
  }
}
</script>

<style lang="scss">
.add-payment-dialog {
  .el-dialog__body {
    padding: 0 !important;

    .square-input {
      height: 36px;
      line-height: 36px !important;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      padding: 7px 15px 0;
      transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
      width: 100%;
      margin-bottom: 1rem;
      -webkit-writing-mode: horizontal-tb !important;
    }

    .dropdown-link {
      font-size: 12px;
      font-style: italic;
      cursor: pointer;
      color: #409EFF;
    }

    .el-icon-arrow-down {
      font-size: 12px;
    }

    .device-card-border {
      border-left:#D1D5DB solid 2px;
    }
  }
}
</style>
