const state = {
  isOpen: false,
  shops: [],
  shopId: null,
  selectedTab: 'items',
  items: {
    data: [],
    itemsFetching: false,
    selectedVendor: 'mygrant',
    selectedItemIds: [],
    selectedAccountNumber: null,
    totalItemsCount: 0,
    vendorsItemsCount: {
      agd: 0,
      american: 0,
      igc: 0,
      mygrant: 0,
      nm: 0,
      pgw: 0,
      pilkington: 0
    },
    americanCheckoutIsOpen: false,
    pilkingtonCheckoutIsOpen: false,
    nielsenMollerCheckoutIsOpen: false
  },
  orders: {
    data: [],
    ordersFetching: false,
    selectedVendor: 'mygrant',
    orderDetailsIsOpen: false,
    americanOrderDetailsIsOpen: false,
    orderDetails: null,
    orderItems: null,
    orderItemsFetching: false
  }
}

export default state
