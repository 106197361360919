<template>
  <div class="global-search-loading">
    <div class="global-search-loading-row" v-for="n in 4" :key="n">
      <skeleton />
      <skeleton size="small" :height="21" />
      <div :style="{ width: '60%' }">
        <skeleton size="medium" :height="10" />
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '@/components/Helpers/DataRows/Skeleton.vue'
export default {
  components: { Skeleton }
}
</script>

<style lang="scss" scoped>
.global-search-loading {
  border-top: 1px solid #dcdfe6;
  .global-search-loading-row {
    padding: 14px $padding-xl;
  }
}
</style>
