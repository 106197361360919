import Document from '@/scripts/objects/document'
import * as R from 'ramda'
import { mergeSpec } from './ramda.helpers'

// #DYNAMIC DOCUMENT

export const documentConfig = {
  job: {
    swo: {
      color: '#cd0a0a',
      label: 'SWO',
      value: 'swo'
    },
    dis: {
      color: '#1c94c4',
      label: 'DIS',
      value: 'dis'
    },
    pop: {
      color: '#00008b',
      label: 'PoP',
      value: 'pop'
    },
    fiv: {
      color: '#a577a6',
      label: 'FIV',
      value: 'fiv'
    },
    quo: {
      color: '#b38a7f',
      label: 'QUO',
      value: 'quo'
    },
    dl: {
      color: '#a10000',
      label: 'DL',
      value: 'dl'
    },
    // ssmpic: {
    //   color: '#005AA3',
    //   label: 'SSMPic',
    //   value: 'ssmpic'
    // },
    // tsmpic: {
    //   color: '#08A300',
    //   label: 'TSMPic',
    //   value: 'tsmpic'
    // },
    // ssign: {
    //   color: '#6db3f2',
    //   label: 'SSign',
    //   value: 'ssign'
    // },
    // tsign: {
    //   color: 'rgb(132, 201, 127)',
    //   label: 'TSign',
    //   value: 'tsign'
    // },
    oth: {
      color: '#cbbce1',
      label: 'OTH',
      value: 'oth'
    },
    pic: {
      color: '#6f3d8b',
      label: 'PIC',
      value: 'pic'
    },
    app: {
      color: '#0086ad',
      label: 'APP',
      value: 'app'
    },
    auth: {
      color: '#99d8d8',
      label: 'AUTH',
      value: 'auth'
    },
    recal: {
      color: '#ffb62b',
      label: 'RECAL',
      value: 'recal'
    },
    // bill: {
    //   color: '#cbbce1',
    //   label: 'BILL',
    //   value: 'bill'
    // },
    payment: {
      color: '#33c90e',
      label: 'PAYMENT',
      value: 'payment'
    }
  },
  vendor: {
    taxform: {
      color: '#3d8f65',
      label: 'TAX',
      value: 'taxform'
    },
    w9: {
      color: '#9c0364',
      label: 'W9',
      value: 'w9'
    },
    oth: {
      color: '#99d8d8',
      label: 'OTH',
      value: 'oth'
    }
  },
  gb_vendor: {
    taxform: {
      color: '#3d8f65',
      label: 'TAX',
      value: 'taxform'
    },
    w9: {
      color: '#9c0364',
      label: 'W9',
      value: 'w9'
    },
    oth: {
      color: '#99d8d8',
      label: 'OTH',
      value: 'oth'
    }
  },
  item: {
    pic: {
      color: '#ffb62b',
      label: 'PIC',
      value: 'pic'
    },
    oth: {
      color: '#99d8d8',
      label: 'OTH',
      value: 'oth'
    }
  },
  purchase_order: {
    receipt: {
      color: '#cbbce1',
      label: 'RECPT',
      value: 'receipt'
    },
    spo: {
      color: '#049410',
      label: 'SIGNPO',
      value: 'spo'
    },
    other: {
      color: '#99d8d8',
      label: 'OTHER',
      value: 'other'
    },
    auth: {
      color: 'rgb(132, 201, 127)',
      label: 'AUTH',
      value: 'auth'
    },
    pop: {
      color: '#00008b',
      label: 'POP',
      value: 'pop'
    }
  },
  bill: {
    receipt: {
      color: '#cbbce1',
      label: 'RECPT',
      value: 'receipt'
    },
    spo: {
      color: '#049410',
      label: 'SIGNPO',
      value: 'spo'
    },
    other: {
      color: '#99d8d8',
      label: 'OTHER',
      value: 'other'
    },
    auth: {
      color: 'rgb(132, 201, 127)',
      label: 'AUTH',
      value: 'auth'
    },
    pop: {
      color: '#00008b',
      label: 'POP',
      value: 'pop'
    }
  },
  bill_payment: {
    receipt: {
      color: '#cbbce1',
      label: 'RECPT',
      value: 'receipt'
    },
    spo: {
      color: '#049410',
      label: 'SIGNPO',
      value: 'spo'
    },
    other: {
      color: '#99d8d8',
      label: 'OTHER',
      value: 'other'
    },
    auth: {
      color: 'rgb(132, 201, 127)',
      label: 'AUTH',
      value: 'auth'
    },
    pop: {
      color: '#00008b',
      label: 'POP',
      value: 'pop'
    }
  },
  expense: {
    receipt: {
      color: '#cbbce1',
      label: 'RECPT',
      value: 'receipt'
    },
    spo: {
      color: '#049410',
      label: 'SIGNPO',
      value: 'spo'
    },
    other: {
      color: '#99d8d8',
      label: 'OTHER',
      value: 'other'
    },
    auth: {
      color: 'rgb(132, 201, 127)',
      label: 'AUTH',
      value: 'auth'
    },
    pop: {
      color: '#00008b',
      label: 'POP',
      value: 'pop'
    }
  },
  journal_entry: {
    receipt: {
      color: '#cbbce1',
      label: 'RECPT',
      value: 'receipt'
    },
    spo: {
      color: '#049410',
      label: 'SIGNPO',
      value: 'spo'
    },
    other: {
      color: '#99d8d8',
      label: 'OTHER',
      value: 'other'
    },
    auth: {
      color: 'rgb(132, 201, 127)',
      label: 'AUTH',
      value: 'auth'
    },
    pop: {
      color: '#00008b',
      label: 'POP',
      value: 'pop'
    }
  },
  receive: {
    other: {
      color: '#99d8d8',
      label: 'OTHER',
      value: 'other'
    },
    receipt: {
      color: '#cbbce1',
      label: 'RECPT',
      value: 'receipt'
    },
    packingSlip: {
      color: 'red',
      label: 'PACKSLIP',
      value: 'packingSlip'
    }
  },
  tech_pickup: {
    other: {
      color: '#99d8d8',
      label: 'OTHER',
      value: 'other'
    },
    receipt: {
      color: '#cbbce1',
      label: 'RECPT',
      value: 'receipt'
    },
    packingSlip: {
      color: 'red',
      label: 'PACKSLIP',
      value: 'packingSlip'
    },
    tpuPic: {
      color: 'green',
      label: 'TSPIC',
      value: 'tpuPic'
    }
  },
  vendor_return: {
    other: {
      color: '#99d8d8',
      label: 'OTHER',
      value: 'other'
    },
    receipt: {
      color: '#cbbce1',
      label: 'RECPT',
      value: 'receipt'
    },
    returnSlip: {
      color: '#ebb434',
      label: 'RETURNSLIP',
      value: 'returnSlip'
    }
  },
  unit_adjustments: {
    other: {
      color: '#99d8d8',
      label: 'OTHER',
      value: 'other'
    }
  }
}

export const getDocumentTagColorWithType = (ref_table, document_type) =>
  R.path([ref_table, document_type, 'color'])(documentConfig)
export const getDocumentTagNameWithType = (ref_table, document_type) =>
  R.path([ref_table, document_type, 'label'])(documentConfig)
export const getDocumentOptions = ref_table =>
  R.pipe(R.propOr({}, ref_table), R.values)(documentConfig)
const objectToArray = R.pipe(R.toPairs, R.map(R.apply(R.objOf)))
// export const getDocumentTagColorWithType = document_type => {
//   if (document_type === 'swo') {
//     return getSWOColor()
//   } else if (document_type === 'dis') {
//     return getDISColor()
//   } else if (document_type === 'pop') {
//     return getPoPColor()
//   } else if (document_type === 'fiv') {
//     return getFIVColor()
//   } else if (document_type === 'quo') {
//     return getQUOColor()
//   } else if (document_type === 'dl') {
//     return getDLColor()
//   } else if (document_type === 'ssmpic') {
//     return getSSMPicColor()
//   } else if (document_type === 'tsmpic') {
//     return getTSMPicColor()
//   } else if (document_type === 'ssign') {
//     return getSSignColor()
//   } else if (document_type === 'tsign') {
//     return getTSignColor()
//   } else if (document_type === 'oth') {
//     return getOTHColor()
//   } else if (document_type === 'pic') {
//     return getPicColor()
//   } else if (document_type === 'app') {
//     return getApprovalColor()
//   } else if (document_type === 'auth') {
//     return getAuthColor()
//   } else if (document_type === 'recal') {
//     return getRecalColor()
//   } else if (document_type === 'bill') {
//     return getBillColor()
//   }
//   return ''
// }

// export const getDocumentTagNameWithType = document_type => {
//   if (document_type === 'swo') {
//     return 'SWO'
//   } else if (document_type === 'dis') {
//     return 'DIS'
//   } else if (document_type === 'pop') {
//     return 'PoP'
//   } else if (document_type === 'fiv') {
//     return 'FIV'
//   } else if (document_type === 'quo') {
//     return 'QUO'
//   } else if (document_type === 'dl') {
//     return 'DL'
//   } else if (document_type === 'ssmpic') {
//     return 'SSMPic'
//   } else if (document_type === 'tsmpic') {
//     return 'TSMPic'
//   } else if (document_type === 'ssign') {
//     return 'SSign'
//   } else if (document_type === 'tsign') {
//     return 'TSign'
//   } else if (document_type === 'oth') {
//     return 'OTH'
//   } else if (document_type === 'pic') {
//     return 'PIC'
//   } else if (document_type === 'app') {
//     return 'APP'
//   } else if (document_type === 'auth') {
//     return 'AUTH'
//   } else if (document_type === 'recal') {
//     return 'RECAL'
//   } else if (document_type === 'bill') {
//     return 'BILL'
//   }
//   return ''
// }

export const getDocumentTags = documents => {
  var documentTags = []
  var swoTags = []
  var disTags = []
  var popTags = []
  var fivTags = []
  var quoTags = []
  var dlTags = []
  var ssmPicTags = []
  var tsmPicTags = []
  var ssignTags = []
  var tsignTags = []
  var othTags = []
  var picTags = []
  var appTags = []
  var authTags = []
  var recalTags = []

  for (var i = 0; i < documents.length; i++) {
    var document = new Document(documents[i])
    if (document.document_type) {
      document.tagColor = this.getDocumentTagColorWithType(
        document.document_type
      )
      document.tagName = this.getDocumentTagNameWithType(
        document.document_type
      )
      if (document.document_type === 'swo') {
        swoTags.push(document)
      } else if (document.document_type === 'dis') {
        disTags.push(document)
      } else if (document.document_type === 'pop') {
        popTags.push(document)
      } else if (document.document_type === 'fiv') {
        fivTags.push(document)
      } else if (document.document_type === 'quo') {
        quoTags.push(document)
      } else if (document.document_type === 'dl') {
        dlTags.push(document)
      } else if (document.document_type === 'ssmpic') {
        ssmPicTags.push(document)
      } else if (document.document_type === 'tsmpic') {
        tsmPicTags.push(document)
      } else if (document.document_type === 'ssign') {
        ssignTags.push(document)
      } else if (document.document_type === 'tsign') {
        tsignTags.push(document)
      } else if (document.document_type === 'oth') {
        othTags.push(document)
      } else if (document.document_type === 'pic') {
        picTags.push(document)
      } else if (document.document_type === 'app') {
        appTags.push(document)
      } else if (document.document_type === 'auth') {
        authTags.push(document)
      } else if (document.document_type === 'recal') {
        recalTags.push(document)
      }
    }
  }

  var allArrays = [
    swoTags,
    disTags,
    popTags,
    fivTags,
    quoTags,
    dlTags,
    ssmPicTags,
    tsmPicTags,
    ssignTags,
    tsignTags,
    othTags,
    picTags,
    appTags,
    authTags,
    recalTags
  ]
  for (var j = 0; j < allArrays.length; j++) {
    var array = allArrays[j]
    if (array.length > 1) {
      for (var k = 0; k < array.length; k++) {
        var object = array[k]
        object.tagName = object.tagName + '-' + (k + 1)
      }
    }
  }

  documentTags = documentTags
    .concat(swoTags)
    .concat(disTags)
    .concat(popTags)
    .concat(fivTags)
    .concat(quoTags)
    .concat(dlTags)
    .concat(ssmPicTags)
    .concat(tsmPicTags)
    .concat(ssignTags)
    .concat(tsignTags)
    .concat(othTags)
    .concat(picTags)
    .concat(appTags)
    .concat(authTags)
    .concat(recalTags)

  return documentTags
}

const getSWOColor = () => '#cd0a0a'
const getDISColor = () => '#1c94c4'
const getPoPColor = () => '#00008b'
const getFIVColor = () => '#a577a6'
const getQUOColor = () => '#b38a7f'
const getDLColor = () => '#a10000'
const getSSMPicColor = () => '#005AA3'
const getTSMPicColor = () => '#08A300'
const getPicColor = () => '#6f3d8b'
const getApprovalColor = () => '#0086ad'
const getAuthColor = () => '#99d8d8'
const getRecalColor = () => '#ffb62b'
const getSSignColor = () => '#6db3f2'
const getTSignColor = () => 'rgb(132, 201, 127)'
const getOTHColor = () => '#cbbce1'
const getBillColor = () => '#cbbce1'
const getBillPaymentColor = () => '#cbbce1'

export const getNumberedDocList = docs =>
  R.pipe(
    R.filter(R.complement(R.propEq('document_type', 'presignature'))),
    R.map(
      mergeSpec({
        number: doc =>
          R.pipe(
            R.filter(R.eqProps('document_type')(doc)),
            R.ifElse(
              R.pipe(R.length, R.equals(1)),
              R.always(null),
              R.pipe(R.findIndex(R.eqProps('id', doc)), R.add(1))
            )
          )(docs)
      })
    )
  )(docs)
