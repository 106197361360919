var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-tech-header" },
    [
      _c(
        "div",
        { staticClass: "top-row" },
        [
          _vm.route && _vm.route.techside_username
            ? _c(
                "div",
                { staticClass: "indicator" },
                [
                  _c("tech-location-indicator", {
                    attrs: {
                      username: _vm.route.techside_username,
                      userdata: _vm.route.ts_user,
                      color: _vm.route.color
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.route.techs
            ? [
                _c("div", { staticClass: "header-text" }, [
                  _vm._v(" " + _vm._s(_vm.getTitle) + " ")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "expand-icon",
                    on: {
                      click: function($event) {
                        _vm.expanded = !_vm.expanded
                      }
                    }
                  },
                  [
                    _c("v-icon", {
                      attrs: { name: _vm.expanded ? "caret-up" : "caret-down" }
                    })
                  ],
                  1
                )
              ]
            : [
                _c("div", { staticClass: "header-text" }, [
                  _vm._v(" " + _vm._s(_vm.getTitle) + " ")
                ]),
                _vm.route.tech && _vm.route.tech.id
                  ? _c("scheduler-tech-header-menu", {
                      attrs: { tech: _vm.route.tech, route: _vm.route },
                      on: {
                        "open-tech-dialog": _vm.onViewVendor,
                        "open-pdf": function($event) {
                          _vm.pdfDialog = true
                        },
                        "open-bulk-reschedule-dialog": function($event) {
                          _vm.bulkRescheduleDialog = true
                        },
                        "open-bulk-reassign-dialog": function($event) {
                          _vm.bulkReassignDialog = true
                        }
                      }
                    })
                  : _vm._e()
              ]
        ],
        2
      ),
      _vm.expanded && _vm.route.techs
        ? [
            _c("div", { staticClass: "divider" }),
            _vm._l(_vm.route.techs, function(tech) {
              return _c(
                "div",
                { key: tech.id, staticClass: "top-row" },
                [
                  _c("v-icon", {
                    staticClass: "single-tech-icon",
                    attrs: { name: "circle", color: tech.color, scale: "0.5" }
                  }),
                  _c("div", { staticClass: "header-text" }, [
                    _vm._v(" " + _vm._s(_vm.namecase(tech.name)) + " ")
                  ]),
                  tech && tech.id
                    ? _c("scheduler-tech-header-menu", {
                        attrs: { tech: tech, route: _vm.route },
                        on: {
                          "open-pdf": function($event) {
                            _vm.pdfDialog = true
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            })
          ]
        : _vm._e(),
      _vm.showTotals
        ? _c("scheduler-tech-totals", { attrs: { route: _vm.route } })
        : _vm._e(),
      _vm.showAvailability
        ? _c("scheduler-tech-time-slots", { attrs: { route: _vm.route } })
        : _vm._e(),
      _vm.route.tech
        ? _c("scheduler-pdf-dialog", {
            attrs: {
              job_ids: _vm.jobIds,
              date: _vm.date,
              tech: _vm.route.tech.name,
              shop: _vm.route.shop || null
            },
            model: {
              value: _vm.pdfDialog,
              callback: function($$v) {
                _vm.pdfDialog = $$v
              },
              expression: "pdfDialog"
            }
          })
        : _vm._e(),
      _c("scheduler-bulk-reschedule-dialog", {
        attrs: { date: _vm.date, job_ids: _vm.jobIds, tech: _vm.route.tech },
        model: {
          value: _vm.bulkRescheduleDialog,
          callback: function($$v) {
            _vm.bulkRescheduleDialog = $$v
          },
          expression: "bulkRescheduleDialog"
        }
      }),
      _c("scheduler-bulk-reassign-dialog", {
        attrs: {
          tech: _vm.route.tech,
          visible: _vm.bulkReassignDialog,
          jobIds: _vm.jobIds
        },
        on: {
          close: function($event) {
            _vm.bulkReassignDialog = false
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }