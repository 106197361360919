<template>
  <base-layout :data="data">
    <div slot="title">Job Rescheduled</div>

    <div>
      Job <a target="_blank" :href="jobLink">{{this.data.details.jobId}}</a> for {{data.details.shopName}} has been marked "Customer Reschedule."
    </div>
  </base-layout>

</template>

<script>
import BaseLayout from './_BaseLayout'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    jobLink() {
      return `/jobs/${this.data.details.jobId}`
    }
  },

  components: { BaseLayout }
}
</script>
