import { types } from './availability.types'
// import store from '@/store/store'
import { getAvailabilityShopUrl } from '@/constants/endpoints'
import Api from '@/services/Api'
import Vue from 'vue'

const actions = {
  async [types.getShopData]({ state, commit }) {
    commit('SET_LOADING', true)
    const { shopId } = state
    if (shopId) {
      const { data } = await Api.get(getAvailabilityShopUrl(shopId))
      commit('SET_SHOP_DATA', data)
    } else {
      commit('SET_SHOP_DATA', [])
    }
    commit('SET_LOADING', false)
  },
  async [types.getOrgData]({ state, commit }) {
    commit('SET_LOADING', true)
    const { orgId } = state
    if (orgId) {
      const unum = Vue.prototype.$unum
      const { data } = await unum.sch().get(`/availability/org/${orgId}`)
      commit('SET_ORG_DATA', data)
    } else {
      commit('SET_ORG_DATA', [])
    }
    commit('SET_LOADING', false)
  },
  async [types.createSlot]({ commit }, slot) {
    commit('SET_LOADING', true)
    const unum = Vue.prototype.$unum
    const { data } = await unum.sch().post('/availability/slot', slot)
    commit('SET_LOADING', false)
    return data
  },
  async [types.updateSlot]({ commit }, slot) {
    commit('SET_LOADING', true)
    const unum = Vue.prototype.$unum
    await unum.sch().patch(`/availability/slot/${slot.id}`, slot)
    commit('SET_LOADING', false)
  },
  async [types.deleteSlot]({ commit }, slot) {
    commit('SET_LOADING', true)
    const unum = Vue.prototype.$unum
    await unum.sch().delete(`/availability/slot/${slot.id}`)
    commit('SET_LOADING', false)
  }
}

export default actions
