<template>
  <div
    class="calendar-tech-job-card-list"
    v-show="isDragover || items.length"
    :class="{ 'no-drop': !enabled }"
  >
    <div class="job-group-title">{{ title }}</div>
    <draggable :options="draggableOptions" @change="log" v-model="itemsList" @end="onEnd">
      <calendar-job-card
        v-for="item in itemsList"
        :key="item.id"
        :item="item"
        :color="color(item, tech)"
      />
    </draggable>
  </div>
</template>

<script>
import CalendarJobCard from './CalendarJobCard'
import Draggable from 'vuedraggable'
import { isMobile } from '@/scripts/helpers/helpers'
import { call, get } from 'vuex-pathify'
import actions from '@/store/modules/scheduler_v3/types'
import { checkDraggingTech } from '@/scripts/helpers/scheduler_v2.helpers'
import * as R from 'ramda'
import { mapActions } from 'vuex'

export default {
  props: ['route', 'date', 'isDragover'],
  components: {
    CalendarJobCard,
    Draggable
  },
  data() {
    return { enabled: true }
  },
  computed: {
    jobTypes: get('sch_v3/filters@jobTypes'),
    title() {
      return R.cond([
        [R.prop('tech'), R.path(['tech', 'name'])],
        [R.prop('techs'), R.prop('techside_username')],
        [R.T, () => 'Unassigned']
      ])(this.route)
    },
    itemsList: {
      get() {
        return this.items
      },
      set(items) {
        this.updateSchedulerItems({
          items,
          route: this.route,
          date: this.date
        })
      }
    },
    draggableOptions() {
      return {
        group: {
          name: this.enabled ? 'default' : 'disabled'
        },
        chosenClass: 'dragging',
        ...(isMobile() ? { delay: 300 } : {})
      }
    },
    color() {
      return function(item, tech) {
        return tech?.color || '#607D8B'
      }
    },
    tech() {
      return R.propOr({}, 'tech')(this.route)
    },
    items() {
      const list = R.propOr([], 'route_entries')(this.route)
      const filteredByType = list.filter((item) => this.jobTypes.includes(item.job.status))
      return filteredByType
    }
  },
  methods: {
    updateSchedulerItems: call(actions.updateSchedulerItems),
    ...mapActions('confirmationsSettings', [
      'setJobsConfirmationDateTime'
    ]),
    onEnd() {
      this.$root.$emit('calendar-drag-over', null)
    },
    log: async function (logObj) {
      if (logObj.added) {
        await this.setJobsConfirmationDateTime({
          jobIds: [logObj.added.element.job_id],
          scheduledDate: this.date
        })
      }
    }
  },
  mounted() {
    this.$root.$on('dragging-item', item => {
      this.enabled = checkDraggingTech(item, this.tech)
    })
  },
  beforeDestroy() {
    this.$root.$off('dragging-item')
  }
}
</script>

<style lang="scss" scoped>
@import './styles/CalendarTechJobsCardList.scss';
</style>
