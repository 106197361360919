import { render, staticRenderFns } from "./AddEditDialog.vue?vue&type=template&id=d554c884&scoped=true&"
import script from "./AddEditDialog.vue?vue&type=script&lang=js&"
export * from "./AddEditDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d554c884",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d554c884')) {
      api.createRecord('d554c884', component.options)
    } else {
      api.reload('d554c884', component.options)
    }
    module.hot.accept("./AddEditDialog.vue?vue&type=template&id=d554c884&scoped=true&", function () {
      api.rerender('d554c884', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Vendors/Glaxis/AddEditDialog.vue"
export default component.exports