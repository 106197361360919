<template>
  <b-modal
      :id="id + '-spinner'"
      hide-footer
      hide-header
      size="sm"
      header-bg-variant='primary'
      >
      <div style='display: block; margin-left: auto; margin-right: auto; width: 20%;'>
        <img src='./../../assets/img/spinner.gif' />
      </div>
      <div class="mx-auto" style="width: 200px">
        {{message}}
      </div>
    </b-modal>
</template>

<script>
export default {
  props: ['id', 'isOpen', 'message'],
  watch: {
    'isOpen': function (val) {
      if (val) {
        this.$root.$emit('bv::show::modal', this.id + '-spinner')
      } else {
        this.$root.$emit('bv::hide::modal', this.id + '-spinner')
      }
    }
  }
}
</script>

<style>

</style>
