<template>
  <div v-if="slots.length" class="scheduler-tech-time-slots">
    <scheduler-tech-time-slot
      v-for="slot in slots"
      :key="slot.id"
      :slot="slot"
      :entries="route.route_entries"
    />
  </div>
</template>

<script>
import * as R from 'ramda'
import SchedulerTechTimeSlot from './helpers/SchedulerTechTimeSlot'

export default {
  components: {
    SchedulerTechTimeSlot
  },
  props: {
    route: Object
  },

  computed: {
    slots() {
      return R.pathOr([], ['tech', 'availability_slots'])(this.route)
    }
  }
}
</script>

<style lang="scss" scoped>
.scheduler-tech-time-slots {
  border-top: 1px solid $scheduler-border-color;
  padding: $padding-sm $padding-sm $padding-xs;
}
</style>
