<template>
  <div class="vendor-contact-info-tab">
    <Name />

    <el-divider class="t-my-6" />

    <div v-if="isCSR || isSalesRep || isTech || isSupplier">
      <PhoneNumbers />

      <el-divider class="t-my-6" />

      <FaxNumbers />

      <el-divider class="t-my-6" />

      <Emails />

      <el-divider class="t-my-6" />

      <Addresses />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import Name from './Name.vue'
import PhoneNumbers from './PhoneNumbers.vue'
import FaxNumbers from './FaxNumbers.vue'
import Emails from './Emails.vue'
import Addresses from './Addresses.vue'

export default {
  components: {
    Name,
    PhoneNumbers,
    FaxNumbers,
    Emails,
    Addresses
  },

  computed: {
    ...mapState(
      useVendorStore,
      [
        'isCSR',
        'isSaleSource',
        'isSalesRep',
        'isTech',
        'isLocation',
        'isSupplier'
      ]
    )
  }
}
</script>

<style lang="scss">
.vendor-contact-info-tab {
  .el-input {
    @apply t-w-[312px];
  }

  .el-input__inner {
    @apply t-rounded-lg;
  }

  .el-radio__label {
    @apply t-hidden;
  }

  .el-button {
    @apply t-text-blue-500 t-rounded-md;
  }
}
</style>
