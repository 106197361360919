var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { trigger: "manual", placement: "left" },
      model: {
        value: _vm.editing,
        callback: function($$v) {
          _vm.editing = $$v
        },
        expression: "editing"
      }
    },
    [
      _c("div", { style: { width: "500px" } }, [
        _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-group col-md-12" },
            [
              _c("label", { attrs: { for: "installAddressLine1" } }, [
                _vm._v(" Address "),
                !_vm.onInstallLocation || !_vm.install_location_id
                  ? _c("span", [
                      _vm._v(" | "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.onInstallLocation = true
                            }
                          }
                        },
                        [_vm._v(" Use saved install location ")]
                      )
                    ])
                  : _vm._e()
              ]),
              _c("address-input", {
                attrs: {
                  full: "",
                  disabled: _vm.onInstallLocation || !!_vm.install_location_id
                },
                model: {
                  value: _vm.address,
                  callback: function($$v) {
                    _vm.address = $$v
                  },
                  expression: "address"
                }
              })
            ],
            1
          )
        ]),
        _vm.onInstallLocation || _vm.install_location_id
          ? _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "form-group col-md-12" },
                [
                  _c("label", { attrs: { for: "installLocation" } }, [
                    _vm._v(" Install location "),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.$root.$emit(
                              "bv::show::modal",
                              "installLocationModal"
                            )
                          }
                        }
                      },
                      [
                        _vm.install_location_id
                          ? _c("span", [_vm._v("(EDIT)")])
                          : _c("span", [_vm._v("(ADD +)")])
                      ]
                    ),
                    _c(
                      "a",
                      {
                        style: { marginLeft: "15px" },
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.onInstallLocation = false
                            _vm.install_location = null
                            _vm.address = null
                          }
                        }
                      },
                      [_vm._v(" Remove ")]
                    )
                  ]),
                  _c("install-location", {
                    attrs: { shopId: _vm.shopId },
                    on: {
                      "update-address": function($event) {
                        _vm.address = $event
                      }
                    },
                    model: {
                      value: _vm.install_location_id,
                      callback: function($$v) {
                        _vm.install_location_id = $$v
                      },
                      expression: "install_location_id"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _c(
          "div",
          { style: { textAlign: "right", marginTop: "8px" } },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    _vm.editing = false
                  }
                }
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function($event) {
                    _vm.$emit("on-save")
                    _vm.editing = false
                  }
                }
              },
              [_vm._v(" Save ")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          style: { marginLeft: "16px", cursor: "pointer" },
          attrs: { slot: "reference" },
          on: {
            click: function($event) {
              _vm.editing = true
            }
          },
          slot: "reference"
        },
        [_c("i", { staticClass: "fa fa-edit" })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }