var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "600px",
        "append-to-body": "",
        visible: _vm.visible,
        "custom-class": "shopping-cart-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-xl t-text-gray-800",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("Order details")]
      ),
      _c(
        "div",
        {
          staticClass: "t-p-4",
          staticStyle: { "max-height": "600px", "overflow-y": "auto" }
        },
        [
          _vm.loading
            ? _c(
                "div",
                {
                  staticClass: "t-p-12 t-text-center t-text-3xl t-text-blue-600"
                },
                [_c("i", { staticClass: "el-icon-loading" })]
              )
            : _c(
                "div",
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass:
                        "t-px-3 t-py-2 t-rounded t-border t-border-solid\n              t-border-gray-400 t-bg-gray-100 t-mb-2"
                    },
                    [
                      _c("div", { staticClass: "t-flex" }, [
                        _c("div", { staticClass: "t-flex-grow" }, [
                          _c(
                            "div",
                            { staticClass: "t-flex t-text-base t-mb-2" },
                            [
                              _c("div", { staticClass: "t-mr-1 t-font-bold" }, [
                                _vm._v(_vm._s(item.industryCode))
                              ])
                            ]
                          ),
                          item.orderId
                            ? _c(
                                "div",
                                { staticClass: "t-flex t-text-base t-mb-2" },
                                [
                                  _c("div", { staticClass: "t-mr-1" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "t-text-gray-600 t-text-sm"
                                      },
                                      [_vm._v("Order number:")]
                                    ),
                                    _vm._v(" " + _vm._s(item.orderId) + " ")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "t-flex t-text-sm t-items-center t-text-gray-600"
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-location-outline t-text-base t-mr-1 t-text-blue-500"
                              }),
                              _c("div", [_vm._v(_vm._s(item.locationName))])
                            ]
                          )
                        ]),
                        _c("div", [
                          _c("div", { staticClass: "t-flex" }, [
                            _c("div", [_vm._v(_vm._s(item.quantity))]),
                            _c("div", { staticClass: "t-mx-2" }, [_vm._v("x")]),
                            _c("div", { staticClass: "t-font-bold" }, [
                              _vm._v("$" + _vm._s(item.unitPrice))
                            ])
                          ])
                        ])
                      ]),
                      item.sku
                        ? _c(
                            "div",
                            [
                              _c("el-divider", { staticClass: "t-my-2" }),
                              _c("div", { staticClass: "t-flex" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "t-text-gray-600 t-text-sm t-mr-3 t-break-normal"
                                  },
                                  [_vm._v(" Description: ")]
                                ),
                                _c("span", { staticClass: "t-break-normal" }, [
                                  _vm._v(_vm._s(item.sku))
                                ])
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "t-flex t-text-sm t-items-center t-text-gray-600"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "el-icon-truck t-text-base t-mr-1 t-text-blue-500"
                          }),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDeliveryDate(
                                  _vm.details.requestedShipDate
                                )
                              ) + ","
                            )
                          ]),
                          _c("div", [
                            _vm._v("- " + _vm._s(_vm.details.deliveryOption))
                          ])
                        ]
                      )
                    ]
                  )
                }),
                0
              )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }