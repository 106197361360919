<template>
  <div>
    <el-dialog
      title="Auto Glass Direct Credentials"
      :visible.sync="localVisible"
      @open="open"
      append-to-body
      width="500px"
    >
      <el-form :model="form" v-loading="submitting">
        <el-form-item label="Name" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Username" :label-width="formLabelWidth">
          <el-input v-model="form.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Password" :label-width="formLabelWidth">
          <el-input v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Default" :label-width="formLabelWidth">
          <el-checkbox v-model="form.default"></el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="localVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submit">{{form.id > 0 ? 'Update' : 'Add'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Shop from '@/scripts/objects/shop'

export default {
  props: ['shopId', 'vendor', 'visible', 'edit'],
  data: () => ({
    form: {},
    formLabelWidth: '150px',
    submitting: false
  }),
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    open: async function() {
      if (!this.edit.id) {
        this.resetForm()
        return
      }

      this.form = {
        ...this.edit,
        accountNumber: this.edit.account_number,
        default: this.edit.default === 1,
        shopId: this.edit.shop_id
      }
    },

    resetForm () {
      this.form = {
        accountNumber: '',
        default: 0,
        id: -1,
        name: '',
        password: null,
        shop_id: this.shopId,
        username: null,
        vendor: this.vendor
      }
    },

    submit: async function() {
      try {
        this.submitting = true
        const vendorCredentials = {
          ...this.form,
          isDefault: this.form.default,
          shopId: this.shopId,
          vendor: this.vendor
        }

        if (this.form.id > 0) {
          await Shop.updateVendorCredentials({
            ...vendorCredentials,
            id: this.form.id
          })
        } else {
          const { data } = await Shop.createVendorCredentials(vendorCredentials)

          this.form.id = data.id
        }

        const credentials = await Shop.getVendorCredentials({
          shopId: this.shopId
        })

        this.$emit('updateCredentials', {credentials, createdUpdatedId: this.form.id})

        this.submitting = false
        this.$message.success('Credentials updated')

        this.localVisible = false
      } catch (error) {
        if (error?.response?.data) {
          this.$message.error(error.response.data)
        } else if (error?.message && error.message !== '') {
          this.$message.error('Error:', error.message)
        } else {
          this.$message.error('Something went wrong. Please contact GlassBiller support.')
        }

        this.submitting = false
      }
    }
  }
}
</script>
