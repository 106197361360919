<template>
  <b-modal
    title='Terms and Conditions'
    header-bg-variant='primary'
    header-text-variant='light'
    size='lg'
    id='termsAndConditionsModal'
    ok-only
    no-close-on-backdrop
    no-close-on-esc
    @ok='ok'>
    <div v-if='termsAndConditions && termsAndConditions != ""' v-html="termsAndConditions">
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['termsAndConditions'],
  data () {
    return {

    }
  },
  methods: {
    ok: function () {
      this.$root.$emit('bv::hide::modal', 'termsAndConditionsModa')
      this.$root.$emit('bv::show::modal', 'esignModal')
    }
  }
}
</script>

<style>

</style>
