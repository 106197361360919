var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group col mt-2" },
    [
      _c("PdfOptionHeader"),
      _c("div", { staticClass: "pdf-options-body" }, [
        _c(
          "div",
          { staticClass: "options-table" },
          _vm._l(_vm.columns, function(column) {
            return _c("PdfOptionCardList", {
              key: column.id,
              attrs: {
                dragoverColumn: _vm.dragoverColumn,
                optionItems: _vm.optionsList[column.col],
                column: column.col
              }
            })
          }),
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }