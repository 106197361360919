const state = {
  filters: {
    shops: [],
    tech: null,
    date: new Date()
  },
  shopTechs: [],
  data: [],
  user: null,
  path: []
}

export default state
