var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "80px",
        width: "685px",
        "custom-class": "qbo-sync-dialog",
        visible: _vm.visible,
        "before-close": _vm.close,
        "close-on-click-modal": false
      },
      on: { open: _vm.initialize }
    },
    [
      _c(
        "div",
        { staticClass: "t-flex-grow t-min-w-0" },
        [
          _c("Header"),
          _c("Body", {
            attrs: {
              month: _vm.month,
              shopOptions: _vm.shopsWithSameQbo,
              shops: _vm.shops
            },
            on: {
              "update:month": function($event) {
                _vm.month = $event
              },
              "update:shops": function($event) {
                _vm.shops = $event
              },
              refresh: _vm.compareInvoices
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }