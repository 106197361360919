var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        visible: _vm.isOpen,
        "before-close": _vm.handleClose,
        top: "50px",
        "custom-class": "chat-image-view-dialog"
      }
    },
    [_c("img", { staticClass: "t-object-contain", attrs: { src: _vm.url } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }