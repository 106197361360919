var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "Shop Details",
        id: "shopDialog",
        "destroy-on-close": "",
        "custom-class": "gb-el-dialog",
        "modal-append-to-body": false,
        "append-to-body": "",
        width: "800px"
      },
      on: {
        open: _vm.dialogAboutToAppear,
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "form-row" }, [
        _vm.adminCheck()
          ? _c("div", { staticClass: "form-group col-md-12" }, [
              _c("label", { attrs: { for: "shopName" } }, [_vm._v("Name")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shop.name,
                    expression: "shop.name"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "shopName", type: "text" },
                domProps: { value: _vm.shop.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.shop, "name", $event.target.value)
                  }
                }
              })
            ])
          : _vm._e(),
        !_vm.adminCheck()
          ? _c("div", { staticClass: "form-group col-md-12" }, [
              _c("label", { attrs: { for: "shopName" } }, [_vm._v("Name")]),
              _c("p", { attrs: { id: "shopName" } }, [
                _vm._v(_vm._s(_vm.shop.name))
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "form-row" }, [
        _vm.adminCheck()
          ? _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", { attrs: { for: "shopStatusSelect" } }, [
                _vm._v("STATUS")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shop.status,
                      expression: "shop.status"
                    }
                  ],
                  staticClass: "form-control form-control-sm autocomplete-off",
                  attrs: { id: "shopStatusSelect" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.shop,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "active" } }, [
                    _vm._v("Active")
                  ]),
                  _c("option", { attrs: { value: "disabled" } }, [
                    _vm._v("Disabled")
                  ])
                ]
              )
            ])
          : _vm._e(),
        !_vm.adminCheck()
          ? _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", { attrs: { for: "shopStatusDisplay" } }, [
                _vm._v("STATUS")
              ]),
              _vm.shop.status === "active"
                ? _c(
                    "p",
                    {
                      staticClass: "form-group col-md-12",
                      attrs: { id: "shopStatusDisplay" }
                    },
                    [_vm._v("Active")]
                  )
                : _vm.shop.status === "disabled"
                ? _c(
                    "p",
                    {
                      staticClass: "form-group col-md-12",
                      attrs: { id: "shopStatusDisplay" }
                    },
                    [_vm._v("Disabled")]
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c("label", { attrs: { for: "shopId" } }, [_vm._v("Id")]),
          _c(
            "span",
            {
              staticClass: "form-control form-control-sm",
              staticStyle: { "background-color": "#EDC9AF" },
              attrs: { id: "shopId" }
            },
            [
              _c("strong", [
                _vm._v(_vm._s(_vm.shop.id > 0 ? _vm.shop.id : "NEW"))
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "form-row" }, [
        _vm.adminCheck()
          ? _c(
              "div",
              { staticClass: "form-group col-md-12" },
              [
                _c("label", [_vm._v("Organization")]),
                _c("Multiselect", {
                  attrs: {
                    "custom-label": function(ref) {
                      var id = ref.id
                      var name = ref.name

                      return "(" + id + ") " + name
                    },
                    placeholder: "Type to search",
                    "open-direction": "bottom",
                    "track-by": "id",
                    options: _vm.organizations,
                    multiple: false,
                    searchable: true,
                    loading: _vm.organizationsLoading,
                    "internal-search": true,
                    "clear-on-select": false,
                    "close-on-select": true,
                    "options-limit": 300,
                    limit: 3,
                    "max-height": 600,
                    "show-no-results": false,
                    "hide-selected": true,
                    "block-keys": ["Delete"]
                  },
                  on: {
                    "search-change": _vm.asyncFindOrganizations,
                    select: function($event) {
                      _vm.shop.organization_id = arguments[0].id
                    },
                    remove: function($event) {
                      _vm.shop.organization_id = null
                    },
                    open: function($event) {
                      return _vm.searchOrganizations()
                    }
                  },
                  model: {
                    value: _vm.selectedOrganization,
                    callback: function($$v) {
                      _vm.selectedOrganization = $$v
                    },
                    expression: "selectedOrganization"
                  }
                }),
                _vm.shop && _vm.shop.id
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          "white-space": "pre-wrap",
                          "word-break": "break-word"
                        }
                      },
                      [
                        _vm._v(
                          "*NOTICE: Be aware that changing an organization here is only recommended for brand new shops. Changing an organization here will not change any ids on any data. If there are any jobs on this shop, they may no longer work as they may contain links to consumers/vendors/etc in the original organization. If you want to edit existing shops, use the merge feature to merge organizations."
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        !_vm.adminCheck()
          ? _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_vm._v("Organization")]),
              _vm.selectedOrganization && _vm.selectedOrganization.name
                ? _c("span", [_vm._v(_vm._s(_vm.selectedOrganization.name))])
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { attrs: { role: "tablist" } },
        [
          _c("div", { attrs: { role: "tab" } }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.shopInfoGroup",
                    modifiers: { shopInfoGroup: true }
                  }
                ],
                attrs: { href: "javascript:void(0)" }
              },
              [
                _vm._v("Shop Information "),
                !_vm.isShopInfoOpen
                  ? _c("v-icon", {
                      staticClass: "caret",
                      attrs: { scale: "1.5", name: "caret-left" }
                    })
                  : _vm._e(),
                _vm.isShopInfoOpen
                  ? _c("v-icon", {
                      staticClass: "caret",
                      attrs: { scale: "1.5", name: "caret-down" }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-12" },
              [
                _c(
                  "b-collapse",
                  {
                    staticClass: "px-1 pb-1",
                    attrs: {
                      id: "shopInfoGroup",
                      accordion: "shopMainAccordion",
                      role: "tabpanel"
                    },
                    model: {
                      value: _vm.isShopInfoOpen,
                      callback: function($$v) {
                        _vm.isShopInfoOpen = $$v
                      },
                      expression: "isShopInfoOpen"
                    }
                  },
                  [
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4" },
                        [
                          _c("Phone", {
                            attrs: {
                              title: "Phone",
                              value: _vm.shop.phone,
                              placeholder: "",
                              required: false
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(_vm.shop, "phone", $event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4" },
                        [
                          _c("Phone", {
                            attrs: {
                              title: "Fax",
                              value: _vm.shop.fax,
                              placeholder: "",
                              required: false
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(_vm.shop, "fax", $event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4" },
                        [
                          _c("Email", {
                            attrs: {
                              title: "Email",
                              value: _vm.shop.email,
                              placeholder: "",
                              required: false
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(_vm.shop, "email", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("label", { attrs: { for: "shopWebsite" } }, [
                          _vm._v("Website")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shop.web,
                              expression: "shop.web"
                            }
                          ],
                          staticClass:
                            "form-control form-control-sm autocomplete-off",
                          attrs: { type: "text" },
                          domProps: { value: _vm.shop.web },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.shop, "web", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-9" },
                        [
                          _c("label", { attrs: { for: "shopAddressLine1" } }, [
                            _vm._v("Address")
                          ]),
                          _c("address-input", {
                            model: {
                              value: _vm.shop.g_address,
                              callback: function($$v) {
                                _vm.$set(_vm.shop, "g_address", $$v)
                              },
                              expression: "shop.g_address"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm.adminCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c("label", { attrs: { for: "shopNotes" } }, [
                              _vm._v("Notes")
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.shop.notes,
                                  expression: "shop.notes"
                                }
                              ],
                              staticClass: "form-control form-control-sm",
                              attrs: { id: "shopNotes", rows: "3" },
                              domProps: { value: _vm.shop.notes },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.shop,
                                    "notes",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.shopCustomPdfField",
                                modifiers: { shopCustomPdfField: true }
                              }
                            ],
                            attrs: { href: "javascript:void(0)" }
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v("Custom PDF field for shop "),
                                !_vm.isShopCustomPdfOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-left"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.isShopCustomPdfOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-down"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "shopCustomPdfField",
                                accordion: "shopInfoNested",
                                role: "tabpanel"
                              },
                              model: {
                                value: _vm.isShopCustomPdfOpen,
                                callback: function($$v) {
                                  _vm.isShopCustomPdfOpen = $$v
                                },
                                expression: "isShopCustomPdfOpen"
                              }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group col pb-1 m-3",
                                          attrs: { id: "shopNestedGroup" }
                                        },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { for: "shopCustomPdf" } },
                                            [
                                              _vm._v(
                                                "Text to display on every PDF for this shop"
                                              )
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.shop
                                                    .shop_special_pdf_field,
                                                expression:
                                                  "shop.shop_special_pdf_field"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm autocomplete-off",
                                            attrs: { type: "text" },
                                            domProps: {
                                              value:
                                                _vm.shop.shop_special_pdf_field
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.shop,
                                                  "shop_special_pdf_field",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName:
                                  "v-b-toggle.shopCustomerQuoteMessageField",
                                modifiers: {
                                  shopCustomerQuoteMessageField: true
                                }
                              }
                            ],
                            attrs: { href: "javascript:void(0)" }
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v("Customer Quote Message "),
                                !_vm.isShopCustomerQuoteMessageOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-left"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.isShopCustomerQuoteMessageOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-down"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "shopCustomerQuoteMessageField",
                                accordion: "shopInfoNested",
                                role: "tabpanel"
                              },
                              model: {
                                value: _vm.isShopCustomerQuoteMessageOpen,
                                callback: function($$v) {
                                  _vm.isShopCustomerQuoteMessageOpen = $$v
                                },
                                expression: "isShopCustomerQuoteMessageOpen"
                              }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group col pb-1 m-3",
                                          attrs: { id: "shopNestedGroup" }
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "customerQuoteMessage"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Text to display on every quote PDF for this shop (Max 400 characters)"
                                              )
                                            ]
                                          ),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.shop
                                                    .customer_quote_message,
                                                expression:
                                                  "shop.customer_quote_message"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              id: "customerQuoteMessage",
                                              rows: "2",
                                              maxlength: "400"
                                            },
                                            domProps: {
                                              value:
                                                _vm.shop.customer_quote_message
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.shop,
                                                  "customer_quote_message",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName:
                                  "v-b-toggle.shopCustomerWorkOrderMessageField",
                                modifiers: {
                                  shopCustomerWorkOrderMessageField: true
                                }
                              }
                            ],
                            attrs: { href: "javascript:void(0)" }
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v("Customer Work Order Message "),
                                !_vm.isShopCustomerWorkOrderMessageOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-left"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.isShopCustomerWorkOrderMessageOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-down"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "shopCustomerWorkOrderMessageField",
                                accordion: "shopInfoNested",
                                role: "tabpanel"
                              },
                              model: {
                                value: _vm.isShopCustomerWorkOrderMessageOpen,
                                callback: function($$v) {
                                  _vm.isShopCustomerWorkOrderMessageOpen = $$v
                                },
                                expression: "isShopCustomerWorkOrderMessageOpen"
                              }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group col pb-1 m-3",
                                          attrs: { id: "shopNestedGroup" }
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "customerWorkOrderMessage"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Text to display on every work order PDF for this shop (Max 400 characters)"
                                              )
                                            ]
                                          ),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.shop
                                                    .customer_workorder_message,
                                                expression:
                                                  "shop.customer_workorder_message"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              id: "customerWorkOrderMessage",
                                              rows: "2",
                                              maxlength: "400"
                                            },
                                            domProps: {
                                              value:
                                                _vm.shop
                                                  .customer_workorder_message
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.shop,
                                                  "customer_workorder_message",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName:
                                  "v-b-toggle.shopCustomerInvoiceMessageField",
                                modifiers: {
                                  shopCustomerInvoiceMessageField: true
                                }
                              }
                            ],
                            attrs: { href: "javascript:void(0)" }
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v("Customer Invoice Message "),
                                !_vm.isShopCustomerInvoiceMessageOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-left"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.isShopCustomerInvoiceMessageOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-down"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "shopCustomerInvoiceMessageField",
                                accordion: "shopInfoNested",
                                role: "tabpanel"
                              },
                              model: {
                                value: _vm.isShopCustomerInvoiceMessageOpen,
                                callback: function($$v) {
                                  _vm.isShopCustomerInvoiceMessageOpen = $$v
                                },
                                expression: "isShopCustomerInvoiceMessageOpen"
                              }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group col pb-1 m-3",
                                          attrs: { id: "shopNestedGroup" }
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "customerInvoiceMessage"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Text to display on every invoice PDF for this shop (Max 400 characters)"
                                              )
                                            ]
                                          ),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.shop
                                                    .customer_invoice_message,
                                                expression:
                                                  "shop.customer_invoice_message"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              id: "customerInvoiceMessage",
                                              rows: "2",
                                              maxlength: "400"
                                            },
                                            domProps: {
                                              value:
                                                _vm.shop
                                                  .customer_invoice_message
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.shop,
                                                  "customer_invoice_message",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.shopLegalDisclaimerField",
                                modifiers: { shopLegalDisclaimerField: true }
                              }
                            ],
                            attrs: { href: "javascript:void(0)" }
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v("Legal Disclaimer "),
                                !_vm.isShopDisclaimerOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-left"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.isShopDisclaimerOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-down"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "shopLegalDisclaimerField",
                                accordion: "shopInfoNested",
                                role: "tabpanel"
                              },
                              model: {
                                value: _vm.isShopDisclaimerOpen,
                                callback: function($$v) {
                                  _vm.isShopDisclaimerOpen = $$v
                                },
                                expression: "isShopDisclaimerOpen"
                              }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col" },
                                  [
                                    _vm.adminCheck() ||
                                    _vm.accountManagerCheck()
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "label label-gray label-small",
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: { click: _vm.addAOBClicked }
                                          },
                                          [_vm._v("Add AOB")]
                                        )
                                      : _vm._e(),
                                    _vm.adminCheck() ||
                                    _vm.accountManagerCheck()
                                      ? _c("CheckBoxLabel", {
                                          attrs: {
                                            label: "Auto Update AOB",
                                            value: _vm.shop.auto_update_aob
                                          },
                                          on: {
                                            "update:value": function($event) {
                                              return _vm.$set(
                                                _vm.shop,
                                                "auto_update_aob",
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-group col pb-1 m-3",
                                    attrs: { id: "shopNestedGroup" }
                                  },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "shopLegalDisclaimer" } },
                                      [_vm._v("Legal Disclaimer")]
                                    ),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.shop.disclaimer,
                                          expression: "shop.disclaimer"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-sm autocomplete-off",
                                      attrs: {
                                        id: "shopLegalDisclaimer",
                                        rows: "7"
                                      },
                                      domProps: { value: _vm.shop.disclaimer },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.shop,
                                            "disclaimer",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", { attrs: { role: "tab" } }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.shopDetailsGroup",
                    modifiers: { shopDetailsGroup: true }
                  }
                ],
                attrs: { href: "javascript:void(0)" }
              },
              [
                _vm._v("Shop Details "),
                !_vm.isShopDetailsOpen
                  ? _c("v-icon", {
                      staticClass: "caret",
                      attrs: { scale: "1.5", name: "caret-left" }
                    })
                  : _vm._e(),
                _vm.isShopDetailsOpen
                  ? _c("v-icon", {
                      staticClass: "caret",
                      attrs: { scale: "1.5", name: "caret-down" }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-12" },
              [
                _c(
                  "b-collapse",
                  {
                    staticClass: "px-1 pb-1",
                    attrs: {
                      id: "shopDetailsGroup",
                      accordion: "shopMainAccordion",
                      role: "tabpanel"
                    },
                    model: {
                      value: _vm.isShopDetailsOpen,
                      callback: function($$v) {
                        _vm.isShopDetailsOpen = $$v
                      },
                      expression: "isShopDetailsOpen"
                    }
                  },
                  [
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c(
                              "label",
                              { attrs: { for: "shopSafeliteEdiNumberField" } },
                              [_vm._v("Safelite EDI Number")]
                            ),
                            _vm.adminCheck()
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shop.safelite_edi_number,
                                      expression: "shop.safelite_edi_number"
                                    }
                                  ],
                                  staticClass:
                                    "form-control form-control-sm autocomplete-off",
                                  attrs: {
                                    type: "text",
                                    id: "shopSafeliteEdiNumberField"
                                  },
                                  domProps: {
                                    value: _vm.shop.safelite_edi_number
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shop,
                                        "safelite_edi_number",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _c(
                                  "p",
                                  {
                                    attrs: { id: "shopSafeliteEdiNumberField" }
                                  },
                                  [_vm._v(_vm._s(_vm.shop.safelite_edi_number))]
                                )
                          ]),
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c(
                              "label",
                              { attrs: { for: "shopQuestEdiNumberField" } },
                              [_vm._v("Quest EDI Number")]
                            ),
                            _vm.adminCheck()
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shop.quest_edi_number,
                                      expression: "shop.quest_edi_number"
                                    }
                                  ],
                                  staticClass:
                                    "form-control form-control-sm autocomplete-off",
                                  attrs: {
                                    type: "text",
                                    id: "shopQuestEdiNumberField"
                                  },
                                  domProps: {
                                    value: _vm.shop.quest_edi_number
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shop,
                                        "quest_edi_number",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _c(
                                  "p",
                                  { attrs: { id: "shopQuestEdiNumberField" } },
                                  [_vm._v(_vm._s(_vm.shop.quest_edi_number))]
                                )
                          ]),
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c(
                              "label",
                              { attrs: { for: "shopLynxEdiNumberField" } },
                              [_vm._v("Lynx/Gerber/Harmon EDI Number")]
                            ),
                            _vm.adminCheck()
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shop.lynx_edi_number,
                                      expression: "shop.lynx_edi_number"
                                    }
                                  ],
                                  staticClass:
                                    "form-control form-control-sm autocomplete-off",
                                  attrs: {
                                    type: "text",
                                    id: "shopLynxEdiNumberField"
                                  },
                                  domProps: { value: _vm.shop.lynx_edi_number },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shop,
                                        "lynx_edi_number",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _c(
                                  "p",
                                  { attrs: { id: "shopLynxEdiNumberField" } },
                                  [_vm._v(_vm._s(_vm.shop.lynx_edi_number))]
                                )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c("label", { attrs: { for: "shopTaxOrSsn" } }, [
                              _vm._v("Choose tax ID or SSN")
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.shop.ssn_vs_tax_id,
                                    expression: "shop.ssn_vs_tax_id"
                                  }
                                ],
                                staticClass:
                                  "form-control form-control-sm autocomplete-off",
                                attrs: { id: "shopTaxOrSsn" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.shop,
                                      "ssn_vs_tax_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "taxid" } }, [
                                  _vm._v("Tax ID")
                                ]),
                                _c("option", { attrs: { value: "ssn" } }, [
                                  _vm._v("SSN")
                                ])
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "form-group col-md-8" }, [
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-12" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "shopTaxIdSsnField" } },
                                    [_vm._v("Tax ID / SSN")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.shop.ssn_or_tax_id,
                                        expression: "shop.ssn_or_tax_id"
                                      }
                                    ],
                                    staticClass:
                                      "form-control form-control-sm autocomplete-off",
                                    attrs: {
                                      id: "shopTaxIdSsnField",
                                      type: "text"
                                    },
                                    domProps: { value: _vm.shop.ssn_or_tax_id },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.shop,
                                          "ssn_or_tax_id",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-12" },
                                [
                                  _c("CheckBoxLabel", {
                                    attrs: {
                                      label: "Include on PDF",
                                      value: _vm.shop.include_tax_id_on_pdf
                                    },
                                    on: {
                                      "update:value": function($event) {
                                        return _vm.$set(
                                          _vm.shop,
                                          "include_tax_id_on_pdf",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      : _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-8" }, [
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-12" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "shopTaxIdSsnField" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.shop.ssn_vs_tax_id === "taxid"
                                            ? "Tax Id"
                                            : "SSN"
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    { attrs: { id: "shopTaxIdSsnField" } },
                                    [_vm._v(_vm._s(_vm.shop.ssn_or_tax_id))]
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-12" },
                                [
                                  _c("CheckBoxLabel", {
                                    attrs: {
                                      label: "Include on PDF",
                                      value: _vm.shop.include_tax_id_on_pdf
                                    },
                                    on: {
                                      "update:value": function($event) {
                                        return _vm.$set(
                                          _vm.shop,
                                          "include_tax_id_on_pdf",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-5" },
                            [
                              _c("br"),
                              _c("CheckBoxLabel", {
                                attrs: {
                                  label: "Auto-Increment Internal Number",
                                  value:
                                    _vm.shop.is_internal_number_auto_increment
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.shop,
                                      "is_internal_number_auto_increment",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm.shop.is_internal_number_auto_increment
                            ? _c(
                                "div",
                                { staticClass: "form-group col-md-7" },
                                [
                                  _c(
                                    "label",
                                    {
                                      attrs: { for: "shopInternalStartNumber" }
                                    },
                                    [_vm._v("Internal number start")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.shop.internal_number_start,
                                        expression: "shop.internal_number_start"
                                      }
                                    ],
                                    staticClass:
                                      "form-control form-control-sm autocomplete-off",
                                    attrs: {
                                      id: "shopInternalStartNumber",
                                      type: "text"
                                    },
                                    domProps: {
                                      value: _vm.shop.internal_number_start
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.shop,
                                          "internal_number_start",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-3" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "tsignVersionSelect" } },
                            [_vm._v("Tsign/Smssign Version")]
                          ),
                          _c("b-form-select", {
                            attrs: {
                              id: "tsignVerisonSelect",
                              options: _vm.tsignVersionOptions,
                              size: "sm"
                            },
                            model: {
                              value: _vm.shop.tsign_version,
                              callback: function($$v) {
                                _vm.$set(_vm.shop, "tsign_version", $$v)
                              },
                              expression: "shop.tsign_version"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-3" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "tsignVersionSelect" } },
                            [_vm._v("PDF Print/Share Version")]
                          ),
                          _c("b-form-select", {
                            attrs: {
                              id: "printShareVerisonSelect",
                              options: _vm.pdfVersionOptions,
                              size: "sm"
                            },
                            model: {
                              value: _vm.shop.pdf_default,
                              callback: function($$v) {
                                _vm.$set(_vm.shop, "pdf_default", $$v)
                              },
                              expression: "shop.pdf_default"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "adhesiveDefaultSelect" } },
                            [_vm._v("Adhesive Default")]
                          ),
                          _c("b-form-select", {
                            attrs: {
                              id: "adhesiveDefaultSelect",
                              size: "sm",
                              options: _vm.adhesiveDefaultOptions
                            },
                            model: {
                              value: _vm.shop.adhesive_default,
                              callback: function($$v) {
                                _vm.$set(_vm.shop, "adhesive_default", $$v)
                              },
                              expression: "shop.adhesive_default"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "defaultInvoiceTier" } },
                            [_vm._v("Default Invoice Terms")]
                          ),
                          _c("b-form-select", {
                            attrs: {
                              id: "defaultInvoiceTier",
                              size: "sm",
                              options: _vm.invoiceTierOptions
                            },
                            model: {
                              value: _vm.shop.default_invoice_tier,
                              callback: function($$v) {
                                _vm.$set(_vm.shop, "default_invoice_tier", $$v)
                              },
                              expression: "shop.default_invoice_tier"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "defaultInvoiceTier" } },
                            [_vm._v("Default Install Context")]
                          ),
                          _c("b-form-select", {
                            attrs: {
                              id: "defaultInstallContext",
                              size: "sm",
                              options: _vm.installContextOptions
                            },
                            model: {
                              value: _vm.shop.default_install_context,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.shop,
                                  "default_install_context",
                                  $$v
                                )
                              },
                              expression: "shop.default_install_context"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm.adminCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c("CheckBoxLabel", {
                                attrs: {
                                  label: "Show Glaxis Inbox",
                                  id: "showGlaxisInbox",
                                  value: _vm.shop.show_glaxis_inbox
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.shop,
                                      "show_glaxis_inbox",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.adminCheck() && _vm.shop.show_glaxis_inbox
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _c("label", { attrs: { for: "glaxisCompanyId" } }, [
                              _vm._v("Glaxis Company ID")
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.shop.glaxis_company_id,
                                  expression: "shop.glaxis_company_id"
                                }
                              ],
                              staticClass:
                                "form-control form-control-sm autocomplete-off",
                              attrs: { id: "glaxisCompanyId" },
                              domProps: { value: _vm.shop.glaxis_company_id },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.shop,
                                    "glaxis_company_id",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "form-group col-md-6" }, [
                            _c("label", { attrs: { for: "glaxisShopName" } }, [
                              _vm._v("Glaxis Shop Name")
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.shop.glaxis_shop_name,
                                  expression: "shop.glaxis_shop_name"
                                }
                              ],
                              staticClass:
                                "form-control form-control-sm autocomplete-off",
                              attrs: { id: "glaxisShopName" },
                              domProps: { value: _vm.shop.glaxis_shop_name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.shop,
                                    "glaxis_shop_name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    !_vm.adminCheck() && _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c("label", { attrs: { for: "showGlaxisInbox" } }, [
                              _vm._v("Show Glaxis Inbox")
                            ]),
                            _c("p", { attrs: { id: "showGlaxisInbox" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.shop.show_glaxis_inbox ? "Yes" : "No"
                                )
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _c("CheckBoxLabel", {
                            attrs: {
                              label: "Use Find Tax Rates Option",
                              value: _vm.shop.colorado_tax_option
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.shop,
                                  "colorado_tax_option",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-3" },
                            [
                              _c("CheckBoxLabel", {
                                attrs: {
                                  label: "Inventory Tracking",
                                  value: _vm.shop.inventory_tracking
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.shop,
                                      "inventory_tracking",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-3" },
                            [
                              _c("CheckBoxLabel", {
                                attrs: {
                                  label: "Auto Sms Payment Receipt",
                                  value: _vm.shop.auto_sms_payment_receipt
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.shop,
                                      "auto_sms_payment_receipt",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-row" }, [
                      _vm.shop.inventory_tracking
                        ? _c("div", { staticClass: "form-group col-md-3" }, [
                            _c("label", [_vm._v("Costing Method")]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.shop.inventory_costing_method,
                                    expression: "shop.inventory_costing_method"
                                  }
                                ],
                                staticClass:
                                  "form-control form-control-sm autocomplete-off",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.shop,
                                      "inventory_costing_method",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "fifo" } }, [
                                  _vm._v("FIFO")
                                ]),
                                _c("option", { attrs: { value: "lifo" } }, [
                                  _vm._v("LIFO")
                                ])
                              ]
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm.adminCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c("CheckBoxLabel", {
                                attrs: {
                                  label: "Right Part Access",
                                  value: _vm.shop.right_part_access
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.shop,
                                      "right_part_access",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c("label", { attrs: { for: "rightPartAccess" } }, [
                              _vm._v("Right Part Access")
                            ]),
                            _c("p", { attrs: { id: "rightPartAccess" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.shop.right_part_access ? "Yes" : "No"
                                )
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c("CheckBoxLabel", {
                                attrs: {
                                  label:
                                    "Created Consumers Auto Set Install Address",
                                  value:
                                    _vm.shop
                                      .created_consumers_auto_set_install_address
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.shop,
                                      "created_consumers_auto_set_install_address",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c(
                              "label",
                              { attrs: { for: "royaltyPercentage" } },
                              [_vm._v("Royalty %")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.shop.royalty_percentage,
                                  expression: "shop.royalty_percentage"
                                }
                              ],
                              staticClass:
                                "form-control form-control-sm autocomplete-off",
                              attrs: {
                                type: "number",
                                id: "royaltyPercentage"
                              },
                              domProps: { value: _vm.shop.royalty_percentage },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.shop,
                                    "royalty_percentage",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.shopEmailsField",
                                    modifiers: { shopEmailsField: true }
                                  }
                                ],
                                attrs: { href: "javascript:void(0)" }
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v("Emails "),
                                    !_vm.isShopEmailsOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-left"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.isShopEmailsOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-down"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "shopEmailsField",
                                    accordion: "shopDetailsNested",
                                    role: "tabpanel"
                                  },
                                  model: {
                                    value: _vm.isShopEmailsOpen,
                                    callback: function($$v) {
                                      _vm.isShopEmailsOpen = $$v
                                    },
                                    expression: "isShopEmailsOpen"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-group col pb-1 m-3",
                                        attrs: { id: "shopNestedGroup" }
                                      },
                                      [
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Edi Notification Emails "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "label label-gray label-sm",
                                                    attrs: {
                                                      href: "javascript:void(0)"
                                                    },
                                                    on: {
                                                      click: _vm.addEmailClicked
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-plus"
                                                    }),
                                                    _vm._v(" New Email")
                                                  ]
                                                )
                                              ])
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "form-row" },
                                          _vm._l(_vm.shop.emails, function(
                                            email,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: index + "-email",
                                                staticClass:
                                                  "form-group col-md-4 mt-2"
                                              },
                                              [
                                                _c("InputWithDelete", {
                                                  attrs: {
                                                    type: "email",
                                                    parentValue: email.email
                                                  },
                                                  on: {
                                                    "update:parentValue": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        email,
                                                        "email",
                                                        $event
                                                      )
                                                    },
                                                    "update:parent-value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        email,
                                                        "email",
                                                        $event
                                                      )
                                                    },
                                                    remove: function($event) {
                                                      return _vm.removeEmail(
                                                        email,
                                                        index
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.shopLogoField",
                                    modifiers: { shopLogoField: true }
                                  }
                                ],
                                attrs: { href: "javascript:void(0)" }
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v("Logo "),
                                    !_vm.isShopLogoOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-left"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.isShopLogoOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-down"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "shopLogoField",
                                    accordion: "shopDetailsNested",
                                    role: "tabpanel"
                                  },
                                  model: {
                                    value: _vm.isShopLogoOpen,
                                    callback: function($$v) {
                                      _vm.isShopLogoOpen = $$v
                                    },
                                    expression: "isShopLogoOpen"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-group col pb-1 m-3",
                                        attrs: { id: "shopNestedGroup" }
                                      },
                                      [
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-group col" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0)"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.gettingFilepicker = true
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("FilePicker", {
                                                    attrs: {
                                                      buttonLabel:
                                                        _vm.shop.logo &&
                                                        _vm.shop.logo.length > 0
                                                          ? "Change Logo"
                                                          : "Add Logo",
                                                      filePickerKey:
                                                        _vm.filepickerKey,
                                                      dialogToReturnTo:
                                                        "shopDialog",
                                                      handleFilePickerResponse:
                                                        _vm.handleFilePickerResponse
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _vm.shop.logo &&
                                          _vm.shop.logo.length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col wrapper"
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: _vm.shop.logo,
                                                      fluid: "",
                                                      alt: "Current Shop Logo"
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.shop.logo = ""
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", {
                                                        staticClass:
                                                          "close-button",
                                                        attrs: {
                                                          name: "minus-circle",
                                                          scale: "1.5"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName:
                                      "v-b-toggle.shopQuickBooksOnlineField",
                                    modifiers: {
                                      shopQuickBooksOnlineField: true
                                    }
                                  }
                                ],
                                attrs: { href: "javascript:void(0)" }
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v("Quickbooks Online Credentials "),
                                    !_vm.isShopQuickbooksOnlineCredentialsOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-left"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.isShopQuickbooksOnlineCredentialsOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-down"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "shopQuickBooksOnlineField",
                                    accordion: "shopDetailsNested",
                                    role: "tabpanel"
                                  },
                                  model: {
                                    value:
                                      _vm.isShopQuickbooksOnlineCredentialsOpen,
                                    callback: function($$v) {
                                      _vm.isShopQuickbooksOnlineCredentialsOpen = $$v
                                    },
                                    expression:
                                      "isShopQuickbooksOnlineCredentialsOpen"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-group col pb-1 m-3",
                                        attrs: { id: "shopNestedGroup" }
                                      },
                                      [
                                        !_vm.shop.qbo_refresh_token ||
                                        _vm.shop.qbo_refresh_token.length == 0
                                          ? _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-12"
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "Click on the button below in order to connect your QuickBooks Online file to this shop:"
                                                      )
                                                    ]),
                                                    _c("img", {
                                                      ref: "qboButton",
                                                      staticStyle: {
                                                        width: "100%",
                                                        "max-width": "220px",
                                                        cursor: "pointer"
                                                      },
                                                      attrs: {
                                                        src: require("../../assets/img/intuit/C2QB_white_btn_lg_default.png")
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.connectToQBOClicked()
                                                        }
                                                      }
                                                    }),
                                                    _c("p", [
                                                      _vm._v(
                                                        "(NOTE: If you have already connected to QuickBooks through the old version of GlassBiller, you will have to connect again on this new version."
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.shop.qbo_refresh_token &&
                                        _vm.shop.qbo_refresh_token.length > 0
                                          ? _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-12"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "qboCredentials"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Disconnect From QuickBooks Online?"
                                                        )
                                                      ]
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-secondary",
                                                        attrs: {
                                                          type: "button",
                                                          variant: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.disconnectFromQBOClicked()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Disconnect")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.hasOtherPaymentProcessingCredentials("authorize.net") &&
                    (_vm.adminCheck() || _vm.accountManagerCheck())
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c("a", { attrs: { href: "javascript:void(0)" } }, [
                              _c("label", [
                                _vm._v(
                                  "Authorize.net Credentials cannot be added because Square Credentials have been added"
                                )
                              ])
                            ])
                          ])
                        ])
                      : !_vm.hasOtherPaymentProcessingCredentials(
                          "authorize.net"
                        ) &&
                        (_vm.adminCheck() || _vm.accountManagerCheck())
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.shopAuthorizeNetField",
                                    modifiers: { shopAuthorizeNetField: true }
                                  }
                                ],
                                attrs: { href: "javascript:void(0)" }
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v("Authorize.net Credentials "),
                                    !_vm.isShopAuthorizeCredentialsOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-left"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.isShopAuthorizeCredentialsOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-down"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              !_vm.hasOtherPaymentProcessingCredentials(
                                "authorize.net"
                              )
                                ? _c(
                                    "b-collapse",
                                    {
                                      attrs: {
                                        id: "shopAuthorizeNetField",
                                        accordion: "shopDetailsNested",
                                        role: "tabpanel"
                                      },
                                      model: {
                                        value:
                                          _vm.isShopAuthorizeCredentialsOpen,
                                        callback: function($$v) {
                                          _vm.isShopAuthorizeCredentialsOpen = $$v
                                        },
                                        expression:
                                          "isShopAuthorizeCredentialsOpen"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-group col pb-1 m-3",
                                            attrs: { id: "shopNestedGroup" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-12"
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "Follow These Steps to Connect an Authorize.Net Account to GlassBiller:"
                                                      )
                                                    ]),
                                                    _c("ol", [
                                                      _c("li", [
                                                        _vm._v(
                                                          'Login to your account on Authorize.Net and visit the "Account" section.'
                                                        )
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          'Click on the "API Credentials & Keys" link under the "Security Settings" area.'
                                                        )
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          'Copy and paste your "API Login ID" on that page into the box below.'
                                                        )
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          'In the "Create New Key(s)" section, fill in the answer to your secret question and select the "New Transaction Key" option. Upon submitting, you will be directed to a page with your transaction key, that you can copy and paste into the box below.'
                                                        )
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          'Return to the "Account" section and click on the "Manage Public Client Key" link under the "Security Settings" area.'
                                                        )
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          'If you have previously created a client key, you can copy and paste that key into the box below. Otherwise in the "Create New Public Client Key" section, fill in the answer to your secret question and submit. Upon submitting, you will be directed to a page with your public client key, that you can copy and paste into the box below.'
                                                        )
                                                      ])
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        '(NOTE: If you have ever previously created and used a "Transaction Key", by creating a new key, that old key will expire. Either use that old key in GlassBiller without creating and using a new one, or update that old key with the new one in any other integrations.)'
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-12"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for:
                                                            "authorizeApiLoginId"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Authorize.net API Login ID"
                                                        )
                                                      ]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.shop
                                                              .authorizenet_api_login_id,
                                                          expression:
                                                            "shop.authorizenet_api_login_id"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm autocomplete-off",
                                                      attrs: {
                                                        type: "text",
                                                        id:
                                                          "authorizeApiLoginId"
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.shop
                                                            .authorizenet_api_login_id
                                                      },
                                                      on: {
                                                        blur:
                                                          _vm.updateAuthorizeNetCredentials,
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.shop,
                                                            "authorizenet_api_login_id",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-12"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for:
                                                            "authorizeTransactionKey"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Authorize.net Transaction Key"
                                                        )
                                                      ]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.shop
                                                              .authorizenet_transaction_key,
                                                          expression:
                                                            "shop.authorizenet_transaction_key"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm autocomplete-off",
                                                      attrs: {
                                                        type: "text",
                                                        id:
                                                          "authorizeTransactionKey"
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.shop
                                                            .authorizenet_transaction_key
                                                      },
                                                      on: {
                                                        blur:
                                                          _vm.updateAuthorizeNetCredentials,
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.shop,
                                                            "authorizenet_transaction_key",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-12"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for:
                                                            "authorizePublicClientKey"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Authorize.net Public Client Key"
                                                        )
                                                      ]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.shop
                                                              .authorizenet_public_client_key,
                                                          expression:
                                                            "shop.authorizenet_public_client_key"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm autocomplete-off",
                                                      attrs: {
                                                        type: "text",
                                                        id:
                                                          "authorizePublicClientKey"
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.shop
                                                            .authorizenet_public_client_key
                                                      },
                                                      on: {
                                                        blur:
                                                          _vm.updateAuthorizeNetCredentials,
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.shop,
                                                            "authorizenet_public_client_key",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.hasOtherPaymentProcessingCredentials("square") &&
                    (_vm.adminCheck() || _vm.accountManagerCheck())
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c("label", [
                              _vm._v(
                                "Square Credentials Cannot be added because Authorize.Net Credentials have been added"
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    !_vm.hasOtherPaymentProcessingCredentials("square")
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.shopSquareField",
                                    modifiers: { shopSquareField: true }
                                  }
                                ],
                                attrs: { href: "javascript:void(0)" }
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v("Square Credentials "),
                                    !_vm.isShopSquareCredentialsOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-left"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.isShopSquareCredentialsOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-down"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "shopSquareField",
                                accordion: "shopDetailsNested",
                                role: "tabpanel"
                              },
                              model: {
                                value: _vm.isShopSquareCredentialsOpen,
                                callback: function($$v) {
                                  _vm.isShopSquareCredentialsOpen = $$v
                                },
                                expression: "isShopSquareCredentialsOpen"
                              }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "m-3 t-font-sans t-p-4 t-rounded t-w-full",
                                    attrs: { id: "shopNestedGroup" }
                                  },
                                  [
                                    !_vm.hasSquareCredentials &&
                                    (_vm.adminCheck() ||
                                      _vm.accountManagerCheck())
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "form-group col-md-12"
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                "Click the following link to connect your Square Account to GlassBiller:"
                                              )
                                            ]),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void(0)"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.connectToSquareClicked()
                                                  }
                                                }
                                              },
                                              [_vm._v("Connect To Square")]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.hasSquareCredentials
                                      ? _c(
                                          "div",
                                          [
                                            _vm.hasSquareCredentials &&
                                            (_vm.adminCheck() ||
                                              _vm.accountManagerCheck())
                                              ? _c(
                                                  "div",
                                                  { staticClass: "t-mb-4" },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "danger",
                                                          icon:
                                                            "el-icon-delete",
                                                          size: "mini",
                                                          plain: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.disconnectFromSquareClicked()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Disconnect From Square "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "t-mb-4" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "t-text-xs" },
                                                  [
                                                    _vm._v(
                                                      "Square Location (needed for techSIDE)"
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      loading:
                                                        _vm.squareLocationsLoading
                                                    },
                                                    on: {
                                                      "visible-change":
                                                        _vm.getSquareLocations
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.shop
                                                          .square_location_id,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.shop,
                                                          "square_location_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "shop.square_location_id"
                                                    }
                                                  },
                                                  _vm._l(
                                                    _vm.squareLocations,
                                                    function(location) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: location.id,
                                                          attrs: {
                                                            label:
                                                              location.label,
                                                            value: location.id
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                float: "left"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  location.label
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                float: "right",
                                                                color:
                                                                  "#8492a6",
                                                                "font-size":
                                                                  "10px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  location.id
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "t-mb-4" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "t-text-xs" },
                                                  [
                                                    _vm._v(
                                                      "Processing Currency"
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: { size: "mini" },
                                                    model: {
                                                      value:
                                                        _vm.shop
                                                          .square_currency,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.shop,
                                                          "square_currency",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "shop.square_currency"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-option",
                                                      {
                                                        attrs: { value: "USD" }
                                                      },
                                                      [_vm._v("USD")]
                                                    ),
                                                    _c(
                                                      "el-option",
                                                      {
                                                        attrs: { value: "CAD" }
                                                      },
                                                      [_vm._v("CAD")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c("SquareDevicesTable", {
                                              staticClass: "t-mb-4",
                                              attrs: { shopId: _vm.shop.id }
                                            }),
                                            _vm.squareDevicesFormIsOpen ===
                                            false
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      icon: "el-icon-plus",
                                                      size: "mini",
                                                      plain: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.squareDevicesFormIsOpen = true
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" Add Devices ")]
                                                )
                                              : _vm._e(),
                                            _vm.squareDevicesFormIsOpen === true
                                              ? _c("SquareDevicesForm", {
                                                  attrs: {
                                                    shopId: _vm.shop.id,
                                                    locationId:
                                                      _vm.shop
                                                        .square_location_id
                                                  },
                                                  on: {
                                                    close:
                                                      _vm.closeSquareDevicesForm
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.shopMygrantField",
                                    modifiers: { shopMygrantField: true }
                                  }
                                ]
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v("Mygrant Credentials "),
                                    _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: _vm.isShopMygrantCredentialsOpen
                                          ? "caret-down"
                                          : "caret-left"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "shopMygrantField",
                                    accordion: "shopDetailsNested",
                                    role: "tabpanel"
                                  },
                                  model: {
                                    value: _vm.isShopMygrantCredentialsOpen,
                                    callback: function($$v) {
                                      _vm.isShopMygrantCredentialsOpen = $$v
                                    },
                                    expression: "isShopMygrantCredentialsOpen"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-3 t-font-sans t-p-4 t-rounded",
                                        attrs: { id: "shopNestedGroup" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "t-mb-4 t-text-gray-700"
                                          },
                                          [
                                            _vm._v(
                                              ' Enter in the account id of the shop in Mygrant below. This can be found by logging into the Mygrant admin panel and clicking the Order Parts tab. In the right of the screen will be a yellow "Ship-To:" box with the id. The id is in the form of C000000-000. This id will also need to be entered into the GlassBiller Glaxis admin panel with Mygrant. '
                                            )
                                          ]
                                        ),
                                        _vm.isShopMygrantCredentialsOpen
                                          ? _c("glaxis-credentials", {
                                              attrs: {
                                                shopId:
                                                  _vm.parentShop &&
                                                  _vm.parentShop.id,
                                                vendor: "mygrant",
                                                credentials: _vm.shop.vendor_credentials.filter(
                                                  function(i) {
                                                    return (
                                                      i.vendor === "mygrant"
                                                    )
                                                  }
                                                )
                                              },
                                              on: {
                                                updateCredentials: function(
                                                  $event
                                                ) {
                                                  _vm.shop.vendor_credentials = $event
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.shopPgwCredentials",
                                    modifiers: { shopPgwCredentials: true }
                                  }
                                ]
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v("PGW Credentials "),
                                    _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: _vm.isShopPgwCredentialsOpen
                                          ? "caret-down"
                                          : "caret-left"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "shopPgwCredentials",
                                    accordion: "shopDetailsNested",
                                    role: "tabpanel"
                                  },
                                  model: {
                                    value: _vm.isShopPgwCredentialsOpen,
                                    callback: function($$v) {
                                      _vm.isShopPgwCredentialsOpen = $$v
                                    },
                                    expression: "isShopPgwCredentialsOpen"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-3 t-font-sans t-p-4 t-rounded",
                                        attrs: { id: "shopNestedGroup" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "t-mb-4 t-text-gray-700"
                                          },
                                          [
                                            _vm._v(
                                              " Enter in the Customer Number of the shop in PGW below. This can be found by logging into the PGW admin panel and clicking the Returns tab. On that page you will see a Customer Number. This will be entirely numeric. This number will also need to be entered into the GlassBiller Glaxis admin panel with PPGAG. "
                                            )
                                          ]
                                        ),
                                        _vm.isShopPgwCredentialsOpen
                                          ? _c("glaxis-credentials", {
                                              attrs: {
                                                shopId:
                                                  _vm.parentShop &&
                                                  _vm.parentShop.id,
                                                vendor: "pgw",
                                                credentials: _vm.shop.vendor_credentials.filter(
                                                  function(i) {
                                                    return i.vendor === "pgw"
                                                  }
                                                )
                                              },
                                              on: {
                                                updateCredentials: function(
                                                  $event
                                                ) {
                                                  _vm.shop.vendor_credentials = $event
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.igcCredentials",
                                    modifiers: { igcCredentials: true }
                                  }
                                ]
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v("Import Glass Corp Credentials "),
                                    _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: _vm.isIgcCredentialsOpen
                                          ? "caret-down"
                                          : "caret-left"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "igcCredentials",
                                    accordion: "shopDetailsNested",
                                    role: "tabpanel"
                                  },
                                  model: {
                                    value: _vm.isIgcCredentialsOpen,
                                    callback: function($$v) {
                                      _vm.isIgcCredentialsOpen = $$v
                                    },
                                    expression: "isIgcCredentialsOpen"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-3 t-font-sans t-p-4 t-rounded t-w-full",
                                        attrs: { id: "shopNestedGroup" }
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "Enter in your Import Glass Corp account number below."
                                          )
                                        ]),
                                        _vm.isIgcCredentialsOpen
                                          ? _c("glaxis-credentials", {
                                              attrs: {
                                                shopId:
                                                  _vm.parentShop &&
                                                  _vm.parentShop.id,
                                                vendor: "igc",
                                                credentials: _vm.shop.vendor_credentials.filter(
                                                  function(i) {
                                                    return i.vendor === "igc"
                                                  }
                                                )
                                              },
                                              on: {
                                                updateCredentials: function(
                                                  $event
                                                ) {
                                                  _vm.shop.vendor_credentials = $event
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName:
                                      "v-b-toggle.shopPilkingtonCredentials",
                                    modifiers: {
                                      shopPilkingtonCredentials: true
                                    }
                                  }
                                ]
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v("Pilkington Credentials "),
                                    _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: _vm.isShopPilkingtonCredentialsOpen
                                          ? "caret-down"
                                          : "caret-left"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "shopPilkingtonCredentials",
                                    accordion: "shopDetailsNested",
                                    role: "tabpanel"
                                  },
                                  model: {
                                    value: _vm.isShopPilkingtonCredentialsOpen,
                                    callback: function($$v) {
                                      _vm.isShopPilkingtonCredentialsOpen = $$v
                                    },
                                    expression:
                                      "isShopPilkingtonCredentialsOpen"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-3 t-font-sans t-p-4 t-rounded t-w-full",
                                        attrs: { id: "shopNestedGroup" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "t-break-normal" },
                                          [
                                            _vm._v(
                                              ' Enter in your Pilkington username, password, and account number below. Your account number can be found by logging into Pilkington, going to "Add/Edit Login(s)", and clicking the "Edit" option next to your login. The Account Number will be on the bottom of the page. '
                                            )
                                          ]
                                        ),
                                        _vm.isShopPilkingtonCredentialsOpen
                                          ? _c("pilkington-credentials", {
                                              attrs: {
                                                shopId:
                                                  _vm.parentShop &&
                                                  _vm.parentShop.id,
                                                credentials: _vm.shop.vendor_credentials.filter(
                                                  function(i) {
                                                    return (
                                                      i.vendor === "pilkington"
                                                    )
                                                  }
                                                )
                                              },
                                              on: {
                                                updateCredentials: function(
                                                  $event
                                                ) {
                                                  _vm.shop.vendor_credentials = $event
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.shopNmCredentials",
                                    modifiers: { shopNmCredentials: true }
                                  }
                                ]
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v(" Nielsen & Moller Credentials "),
                                    _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: _vm.isShopNmCredentialsOpen
                                          ? "caret-down"
                                          : "caret-left"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "shopNmCredentials",
                                    accordion: "shopDetailsNested",
                                    role: "tabpanel"
                                  },
                                  model: {
                                    value: _vm.isShopNmCredentialsOpen,
                                    callback: function($$v) {
                                      _vm.isShopNmCredentialsOpen = $$v
                                    },
                                    expression: "isShopNmCredentialsOpen"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-3 t-font-sans t-p-4 t-rounded t-w-full",
                                        attrs: { id: "shopNestedGroup" }
                                      },
                                      [
                                        _vm.isShopNmCredentialsOpen
                                          ? _c("nielsen-moller-credentials", {
                                              attrs: {
                                                shopId:
                                                  _vm.parentShop &&
                                                  _vm.parentShop.id,
                                                credentials: _vm.shop.vendor_credentials.filter(
                                                  function(i) {
                                                    return i.vendor === "nm"
                                                  }
                                                )
                                              },
                                              on: {
                                                updateCredentials: function(
                                                  $event
                                                ) {
                                                  _vm.shop.vendor_credentials = $event
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.shopAgdCredentials",
                                    modifiers: { shopAgdCredentials: true }
                                  }
                                ]
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v(" Auto Glass Direct Credentials "),
                                    _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: _vm.isShopAgdCredentialsOpen
                                          ? "caret-down"
                                          : "caret-left"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.adminCheck() || _vm.accountManagerCheck()
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "shopAgdCredentials",
                                    accordion: "shopDetailsNested",
                                    role: "tabpanel"
                                  },
                                  model: {
                                    value: _vm.isShopAgdCredentialsOpen,
                                    callback: function($$v) {
                                      _vm.isShopAgdCredentialsOpen = $$v
                                    },
                                    expression: "isShopAgdCredentialsOpen"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-3 t-font-sans t-p-4 t-rounded t-w-full",
                                        attrs: { id: "shopNestedGroup" }
                                      },
                                      [
                                        _vm.isShopAgdCredentialsOpen
                                          ? _c(
                                              "auto-glass-direct-credentials",
                                              {
                                                attrs: {
                                                  shopId:
                                                    _vm.parentShop &&
                                                    _vm.parentShop.id,
                                                  credentials: _vm.shop.vendor_credentials.filter(
                                                    function(i) {
                                                      return i.vendor === "agd"
                                                    }
                                                  )
                                                },
                                                on: {
                                                  updateCredentials: function(
                                                    $event
                                                  ) {
                                                    _vm.shop.vendor_credentials = $event
                                                  }
                                                }
                                              }
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.shopReviewCredentials",
                                modifiers: { shopReviewCredentials: true }
                              }
                            ],
                            attrs: { href: "javascript:void(0)" }
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v("Review Credentials "),
                                !_vm.isShopReviewCredentialsOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-left"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.isShopReviewCredentialsOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-down"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "shopReviewCredentials",
                                accordion: "shopDetailsNested",
                                role: "tabpanel"
                              },
                              model: {
                                value: _vm.isShopReviewCredentialsOpen,
                                callback: function($$v) {
                                  _vm.isShopReviewCredentialsOpen = $$v
                                },
                                expression: "isShopReviewCredentialsOpen"
                              }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-group col pb-1 m-3",
                                    attrs: { id: "shopNestedGroup" }
                                  },
                                  [
                                    _c("div", [
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group col-md-12"
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                "Enter your Podium location id and api key. These can be found in your Podium account."
                                              )
                                            ])
                                          ]
                                        )
                                      ]),
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group col-md-12"
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "shopPodiumLocationId"
                                                }
                                              },
                                              [_vm._v("Podium Location ID")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.shop.podium_location_id,
                                                  expression:
                                                    "shop.podium_location_id"
                                                }
                                              ],
                                              staticClass:
                                                "form-control form-control-sm autocomplete-off",
                                              attrs: {
                                                id: "shopPodiumLocationId",
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.shop.podium_location_id
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.shop,
                                                    "podium_location_id",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ]),
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group col-md-12"
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "shopPodiumApiKey"
                                                }
                                              },
                                              [_vm._v("Podium API Key")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.shop.podium_api_key,
                                                  expression:
                                                    "shop.podium_api_key"
                                                }
                                              ],
                                              staticClass:
                                                "form-control form-control-sm autocomplete-off",
                                              attrs: {
                                                id: "shopPodiumApiKey",
                                                type: "text"
                                              },
                                              domProps: {
                                                value: _vm.shop.podium_api_key
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.shop,
                                                    "podium_api_key",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.shopSalesideWarrantyField",
                                modifiers: { shopSalesideWarrantyField: true }
                              }
                            ],
                            attrs: { href: "javascript:void(0)" }
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v(
                                  "Saleside - Warranty & Terms/Conditions "
                                ),
                                !_vm.isShopSalesideWarrantyTermsOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-left"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.isShopSalesideWarrantyTermsOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-down"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "shopSalesideWarrantyField",
                                accordion: "shopDetailsNested",
                                role: "tabpanel"
                              },
                              model: {
                                value: _vm.isShopSalesideWarrantyTermsOpen,
                                callback: function($$v) {
                                  _vm.isShopSalesideWarrantyTermsOpen = $$v
                                },
                                expression: "isShopSalesideWarrantyTermsOpen"
                              }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-group col pb-1 m-3",
                                    attrs: { id: "shopNestedGroup" }
                                  },
                                  [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group col-md-12" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "shopSalesideWarranty"
                                              }
                                            },
                                            [_vm._v("Warranty")]
                                          ),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.shop
                                                    .saleside_warranty_text,
                                                expression:
                                                  "shop.saleside_warranty_text"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm autocomplete-off",
                                            attrs: {
                                              id: "shopSalesideWarranty",
                                              rows: "7"
                                            },
                                            domProps: {
                                              value:
                                                _vm.shop.saleside_warranty_text
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.shop,
                                                  "saleside_warranty_text",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group col-md-12" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for:
                                                  "shopSalesideTermsConditions"
                                              }
                                            },
                                            [_vm._v("Terms & Conditions")]
                                          ),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.shop
                                                    .saleside_terms_and_conditions,
                                                expression:
                                                  "shop.saleside_terms_and_conditions"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm autocomplete-off",
                                            attrs: {
                                              id: "shopSalesideTermsConditions",
                                              rows: "7"
                                            },
                                            domProps: {
                                              value:
                                                _vm.shop
                                                  .saleside_terms_and_conditions
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.shop,
                                                  "saleside_terms_and_conditions",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName:
                                  "v-b-toggle.shopCustomerCorrespondence",
                                modifiers: { shopCustomerCorrespondence: true }
                              }
                            ],
                            attrs: { href: "javascript:void(0)" }
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v("Customer Correspondence "),
                                !_vm.isShopCustomerCorrespondenceOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-left"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.isShopCustomerCorrespondenceOpen
                                  ? _c("v-icon", {
                                      staticClass: "caret",
                                      attrs: {
                                        scale: "1.5",
                                        name: "caret-down"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "shopCustomerCorrespondence",
                                accordion: "shopDetailsNested",
                                role: "tabpanel"
                              },
                              model: {
                                value: _vm.isShopCustomerCorrespondenceOpen,
                                callback: function($$v) {
                                  _vm.isShopCustomerCorrespondenceOpen = $$v
                                },
                                expression: "isShopCustomerCorrespondenceOpen"
                              }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-group col pb-1 m-3",
                                    attrs: { id: "shopNestedGroup" }
                                  },
                                  [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group col-md-6" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.gettingFilepicker = true
                                                }
                                              }
                                            },
                                            [
                                              _c("FilePicker", {
                                                attrs: {
                                                  buttonLabel:
                                                    _vm.shop.customer_logo &&
                                                    _vm.shop.customer_logo
                                                      .length > 0
                                                      ? "Change Customer Logo"
                                                      : "Add Customer Logo",
                                                  filePickerKey:
                                                    _vm.filepickerKey,
                                                  dialogToReturnTo:
                                                    "shopDialog",
                                                  handleFilePickerResponse:
                                                    _vm.handleFilePickerResponseCustomer
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "form-row" }, [
                                      _vm.shop.customer_logo &&
                                      _vm.shop.customer_logo.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col wrapper"
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.shop.customer_logo,
                                                  fluid: "",
                                                  alt: "Current Customer Logo"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      _vm.shop.customer_logo =
                                                        ""
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", {
                                                    staticClass: "close-button",
                                                    attrs: {
                                                      name: "minus-circle",
                                                      scale: "1.5"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group col-md-8" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "shopCustomerAddress"
                                              }
                                            },
                                            [_vm._v("Address")]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.shop.customer_address,
                                                expression:
                                                  "shop.customer_address"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm autocomplete-off",
                                            attrs: {
                                              id: "shopCustomerAddress",
                                              type: "text"
                                            },
                                            domProps: {
                                              value: _vm.shop.customer_address
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.shop,
                                                  "customer_address",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-group col-md-4" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: { for: "shopCustomerZip" }
                                            },
                                            [_vm._v("Zip")]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.shop.customer_zip,
                                                expression: "shop.customer_zip"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm autocomplete-off",
                                            attrs: {
                                              id: "shopCustomerZip",
                                              type: "text"
                                            },
                                            domProps: {
                                              value: _vm.shop.customer_zip
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.shop,
                                                  "customer_zip",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group col-md-8" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: { for: "shopCustomerCity" }
                                            },
                                            [_vm._v("City")]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.shop.customer_city,
                                                expression: "shop.customer_city"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm autocomplete-off",
                                            attrs: {
                                              id: "shopCustomerCity",
                                              type: "text"
                                            },
                                            domProps: {
                                              value: _vm.shop.customer_city
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.shop,
                                                  "customer_city",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-group col-md-4" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "shopCustomerState"
                                              }
                                            },
                                            [_vm._v("State")]
                                          ),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.shop.customer_state,
                                                  expression:
                                                    "shop.customer_state"
                                                }
                                              ],
                                              staticClass:
                                                "form-control form-control-sm",
                                              attrs: {
                                                id: "shopCustomerState"
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.shop,
                                                    "customer_state",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "" } },
                                                [_vm._v("Choose...")]
                                              ),
                                              _vm._l(_vm.states, function(
                                                state
                                              ) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: state.value,
                                                    domProps: {
                                                      value: state.value
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(state.text))]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm.coAccounts && _vm.coAccounts.length > 0
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName:
                                      "v-b-toggle.chartOfAccountSettings",
                                    modifiers: { chartOfAccountSettings: true }
                                  }
                                ],
                                attrs: { href: "javascript:void(0)" }
                              },
                              [
                                _c(
                                  "label",
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "badge badge-primary" },
                                      [_vm._v("Beta!")]
                                    ),
                                    _vm._v(" Chart of Accounts Settings "),
                                    !_vm.isChartOfAccountSettingsOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-left"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.isChartOfAccountSettingsOpen
                                      ? _c("v-icon", {
                                          staticClass: "caret",
                                          attrs: {
                                            scale: "1.5",
                                            name: "caret-down"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.coAccounts && _vm.coAccounts.length > 0
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "chartOfAccountSettings",
                                    accordion: "shopDetailsNested",
                                    role: "tabpanel"
                                  },
                                  model: {
                                    value: _vm.isChartOfAccountSettingsOpen,
                                    callback: function($$v) {
                                      _vm.isChartOfAccountSettingsOpen = $$v
                                    },
                                    expression: "isChartOfAccountSettingsOpen"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-group col pb-1 m-3",
                                        attrs: { id: "shopNestedGroup" }
                                      },
                                      [
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [_c("p")]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v("Default A/R Account")
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_accounts_receivable_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_accounts_receivable_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_accounts_receivable_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.accountReceivables,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Material Sales Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_materials_sales_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_materials_sales_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_materials_sales_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.laborMaterialsAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Labor Sales Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_labor_sales_other_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_labor_sales_other_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_labor_sales_other_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.laborMaterialsAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Balance Due Cash Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_balancedue_cash_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_balancedue_cash_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_balancedue_cash_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Balance Due Card Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_balancedue_card_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_balancedue_card_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_balancedue_card_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Balance Due Check Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_balancedue_check_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_balancedue_check_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_balancedue_check_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Balance Due Bank Transfer Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_balancedue_banktransfer_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_balancedue_banktransfer_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_balancedue_banktransfer_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Balance Due Other Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_balancedue_other_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_balancedue_other_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_balancedue_other_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Deductible Disc. Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_deductible_deductibledisc_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_deductible_deductibledisc_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_deductible_deductibledisc_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Deductible Cash Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_deductible_cash_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_deductible_cash_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_deductible_cash_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Deductible Card Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_deductible_card_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_deductible_card_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_deductible_card_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Deductible Check Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_deductible_check_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_deductible_check_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_deductible_check_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Deductible Bank Transfer Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_deductible_banktransfer_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_deductible_banktransfer_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_deductible_banktransfer_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Deductible Other Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_deductible_other_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_deductible_other_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_deductible_other_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.paymentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Adjustment Promo Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_adjustment_promo_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_adjustment_promo_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_adjustment_promo_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.adjustmentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Adjustment Coupon Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_adjustment_coupon_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_adjustment_coupon_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_adjustment_coupon_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.adjustmentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Adjustment Credit Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_adjustment_credit_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_adjustment_credit_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_adjustment_credit_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.adjustmentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Adjustment Short Pay Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_adjustment_shortpay_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_adjustment_shortpay_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_adjustment_shortpay_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.adjustmentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Adjustment Write Off Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_adjustment_writeoff_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_adjustment_writeoff_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_adjustment_writeoff_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.adjustmentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-12"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Default Adjustment Other Account"
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .shopAccountingPackageSettings
                                                          .default_adjustment_other_co_account_id,
                                                      expression:
                                                        "shopAccountingPackageSettings.default_adjustment_other_co_account_id"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.shopAccountingPackageSettings,
                                                        "default_adjustment_other_co_account_id",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.adjustmentAccounts,
                                                  function(account) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: account.id,
                                                        domProps: {
                                                          value: account.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(account.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ]),
          _vm.adminCheck() || _vm.accountManagerCheck()
            ? _c("div", { attrs: { role: "tab" } }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.twilioSettings",
                        modifiers: { twilioSettings: true }
                      }
                    ],
                    attrs: { href: "javascript:void(0)" }
                  },
                  [
                    _vm._v(" Twilio Setting "),
                    _c("v-icon", {
                      staticClass: "caret",
                      attrs: {
                        scale: "1.5",
                        name: _vm.isTwilioOpen ? "caret-down" : "caret-left"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "b-collapse",
            {
              staticClass: "px-1 pb-1",
              attrs: {
                id: "twilioSettings",
                accordion: "shopMainAccordion",
                role: "tabpanel"
              },
              model: {
                value: _vm.isTwilioOpen,
                callback: function($$v) {
                  _vm.isTwilioOpen = $$v
                },
                expression: "isTwilioOpen"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle.twilioAccount",
                            modifiers: { twilioAccount: true }
                          }
                        ]
                      },
                      [
                        _c(
                          "label",
                          [
                            _vm._v(" Twilio Account "),
                            _c("v-icon", {
                              staticClass: "caret",
                              attrs: {
                                scale: "1.5",
                                name: _vm.isTwilioAccountOpen
                                  ? "caret-down"
                                  : "caret-left"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "twilioAccount",
                    accordion: "shopDetailsNested",
                    role: "tabpanel"
                  },
                  model: {
                    value: _vm.isTwilioAccountOpen,
                    callback: function($$v) {
                      _vm.isTwilioAccountOpen = $$v
                    },
                    expression: "isTwilioAccountOpen"
                  }
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group col pb-1 m-3",
                        attrs: { id: "shopNestedGroup" }
                      },
                      [
                        !_vm.twilioAccountExist
                          ? _c("twilio-account-create", {
                              attrs: {
                                shopId: _vm.shop.id,
                                disabled: !_vm.adminCheck()
                              },
                              on: {
                                created: function($event) {
                                  _vm.shop.twilio_account = $event
                                }
                              }
                            })
                          : _c("twilio-account-update", {
                              attrs: {
                                twilioAccount: _vm.shop.twilio_account,
                                disabled: !_vm.adminCheck()
                              },
                              on: {
                                updated: function($event) {
                                  _vm.shop.twilio_account = $event
                                }
                              }
                            })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-row",
                [
                  _c("el-col", [
                    _vm.twilioAccountExist
                      ? _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.twilioPhoneNumbers",
                                modifiers: { twilioPhoneNumbers: true }
                              }
                            ]
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v(" Twilio Phone Numbers "),
                                _c("v-icon", {
                                  staticClass: "caret",
                                  attrs: {
                                    scale: "1.5",
                                    name: _vm.isTwilioPhoneNumbersOpen
                                      ? "caret-down"
                                      : "caret-left"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "twilioPhoneNumbers",
                    accordion: "shopDetailsNested",
                    role: "tabpanel"
                  },
                  model: {
                    value: _vm.isTwilioPhoneNumbersOpen,
                    callback: function($$v) {
                      _vm.isTwilioPhoneNumbersOpen = $$v
                    },
                    expression: "isTwilioPhoneNumbersOpen"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row m-3",
                      attrs: { id: "shopNestedGroup" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("twilio-phone-number-form", {
                            attrs: {
                              shopId: _vm.shop.id,
                              visible: _vm.isTwilioPhoneNumberFormOpen,
                              phoneNumber: _vm.twilioPhoneNumber,
                              disabled: !_vm.adminCheck()
                            },
                            on: {
                              closed: function($event) {
                                _vm.isTwilioPhoneNumberFormOpen = false
                              },
                              createdUpdated: function($event) {
                                _vm.shop.twilio_phone_numbers = $event
                              }
                            }
                          }),
                          _c("div", { staticClass: "row mb-3" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "span",
                                { staticStyle: { color: "#606266" } },
                                [_vm._v("Phone numbers")]
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "pull-right",
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      icon: "el-icon-plus",
                                      disabled: !_vm.adminCheck()
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openTwilioPhoneNumberForm(
                                          null
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Create")]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("twilio-phone-number-table", {
                            attrs: {
                              shopId: _vm.shop.id,
                              defaultPhoneNumberId:
                                _vm.shop
                                  .default_job_confirmation_phone_number_id,
                              phoneNumbers: _vm.shop.twilio_phone_numbers,
                              disabled: !_vm.adminCheck()
                            },
                            on: {
                              "update:defaultPhoneNumberId": function($event) {
                                return _vm.$set(
                                  _vm.shop,
                                  "default_job_confirmation_phone_number_id",
                                  $event
                                )
                              },
                              "update:default-phone-number-id": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.shop,
                                  "default_job_confirmation_phone_number_id",
                                  $event
                                )
                              },
                              updatePhoneNumber: function($event) {
                                return _vm.openTwilioPhoneNumberForm($event)
                              },
                              deletedPhoneNumber: function($event) {
                                _vm.shop.twilio_phone_numbers = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _vm.adminCheck() || _vm.accountManagerCheck()
            ? _c("div", { attrs: { role: "tab" } }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.preInspection",
                        modifiers: { preInspection: true }
                      }
                    ],
                    attrs: { href: "javascript:void(0)" }
                  },
                  [
                    _vm._v(" Pre-inspection settings "),
                    _c("v-icon", {
                      staticClass: "caret",
                      attrs: {
                        scale: "1.5",
                        name: _vm.isPreInspectionOpen
                          ? "caret-down"
                          : "caret-left"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "b-collapse",
            {
              staticClass: "px-1 pb-1",
              attrs: {
                id: "preInspection",
                accordion: "shopMainAccordion",
                role: "tabpanel"
              },
              model: {
                value: _vm.isPreInspectionOpen,
                callback: function($$v) {
                  _vm.isPreInspectionOpen = $$v
                },
                expression: "isPreInspectionOpen"
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 px-2" }, [
                  _vm._v(
                    " TechSIDE vehicle inspection customer initials checks "
                  )
                ])
              ]),
              _vm._l(_vm.shop.inspection_initials, function(item) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "form-row py-2" },
                  [
                    _c("div", { staticClass: "form-group col-md-12" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.description,
                            expression: "item.description"
                          }
                        ],
                        staticClass: "form-control form-control-sm",
                        attrs: { rows: "3" },
                        domProps: { value: item.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "description", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]
                )
              })
            ],
            2
          ),
          _vm.adminCheck()
            ? _c("div", { attrs: { role: "tab" } }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.fundingSettings",
                        modifiers: { fundingSettings: true }
                      }
                    ],
                    attrs: { href: "javascript:void(0)" }
                  },
                  [
                    _vm._v(" Funding Settings "),
                    _c("v-icon", {
                      staticClass: "caret",
                      attrs: {
                        scale: "1.5",
                        name: _vm.isShopFundingSettingaOpen
                          ? "caret-down"
                          : "caret-left"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "b-collapse",
            {
              staticClass: "px-1 pb-1",
              attrs: { id: "fundingSettings", accordion: "shopMainAccordion" },
              model: {
                value: _vm.isShopFundingSettingaOpen,
                callback: function($$v) {
                  _vm.isShopFundingSettingaOpen = $$v
                },
                expression: "isShopFundingSettingaOpen"
              }
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("CheckBoxLabel", {
                      attrs: { label: "Shop is Funded", value: _vm.isFunded },
                      on: {
                        "update:value": function($event) {
                          _vm.isFunded = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm.shop.is_funded
                ? _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-12" }, [
                      _c(
                        "label",
                        { attrs: { for: "funding-start-date" } },
                        [
                          _vm._v("Funding Started "),
                          _c("el-date-picker", {
                            attrs: {
                              id: "funding-start-date",
                              type: "date",
                              format: "MM/dd/yyyy",
                              placeholder: "Pick a day",
                              clearable: false
                            },
                            model: {
                              value: _vm.shop.funding_start_date,
                              callback: function($$v) {
                                _vm.$set(_vm.shop, "funding_start_date", $$v)
                              },
                              expression: "shop.funding_start_date"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("CheckBoxLabel", {
                      attrs: {
                        label: "Pending?",
                        value: _vm.shop.is_funding_pending
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.shop,
                            "is_funding_pending",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm.adminCheck()
            ? _c("div", { attrs: { role: "tab" } }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.adminFunctions",
                        modifiers: { adminFunctions: true }
                      }
                    ],
                    attrs: { href: "javascript:void(0)" }
                  },
                  [
                    _vm._v(" Shop Admin Functions "),
                    _c("v-icon", {
                      staticClass: "caret",
                      attrs: {
                        scale: "1.5",
                        name: _vm.isShopadminFunctionsOpen
                          ? "caret-down"
                          : "caret-left"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "b-collapse",
            {
              staticClass: "px-1 pb-1",
              attrs: { id: "adminFunctions", accordion: "shopMainAccordion" },
              model: {
                value: _vm.isShopadminFunctionsOpen,
                callback: function($$v) {
                  _vm.isShopadminFunctionsOpen = $$v
                },
                expression: "isShopadminFunctionsOpen"
              }
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12 mt-2" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          loading: _vm.creatingShopItems
                        },
                        on: { click: _vm.adminCreateShopItems }
                      },
                      [_vm._v("Create 2k Most Popular Nags Items From Shop")]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12 mt-2" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          loading: _vm.updatingNagsItems
                        },
                        on: { click: _vm.adminUpdateNagsItems }
                      },
                      [_vm._v("Track Inventory All Nags Items")]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12 mt-2" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          loading: _vm.updatingAllItems
                        },
                        on: { click: _vm.adminUpdateAllItems }
                      },
                      [_vm._v("Track Inventory All Items")]
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _c("div", { attrs: { role: "tab" } }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.pdfOptoions",
                    modifiers: { pdfOptoions: true }
                  }
                ],
                attrs: { href: "javascript:void(0)" }
              },
              [
                _vm._v(" Pdf Options "),
                _c("v-icon", {
                  staticClass: "caret",
                  attrs: {
                    scale: "1.5",
                    name: _vm.isShopPdfOptionsOpen ? "caret-down" : "caret-left"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "b-collapse",
            {
              staticClass: "px-1 pb-1",
              attrs: { id: "pdfOptoions", accordion: "shopMainAccordion" },
              model: {
                value: _vm.isShopPdfOptionsOpen,
                callback: function($$v) {
                  _vm.isShopPdfOptionsOpen = $$v
                },
                expression: "isShopPdfOptionsOpen"
              }
            },
            [
              _vm._l(_vm.additionalPdfOptions, function(option) {
                return _c(
                  "div",
                  { key: option.name, staticClass: "form-row" },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12" },
                      [
                        _c("CheckBoxLabel", {
                          attrs: {
                            label: "Show " + _vm.snakeCaseToTitle(option.name),
                            value: option.visible
                          },
                          on: {
                            "update:value": function($event) {
                              return _vm.$set(option, "visible", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              }),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-md-5 mt-2" },
                  [
                    _c("CheckBoxLabel", {
                      attrs: {
                        label: "Include full NAGS description on job PDF",
                        value: _vm.shop.pdf_nags_description
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.shop,
                            "pdf_nags_description",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-md-5 mt-2" },
                  [
                    _c("CheckBoxLabel", {
                      attrs: {
                        label: "Show customer as bill to",
                        value: _vm.shop.pdf_bill_to_customer
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(
                            _vm.shop,
                            "pdf_bill_to_customer",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("PdfOptionsContainer", {
                    attrs: { options: _vm.shop.pdfOptions }
                  })
                ],
                1
              )
            ],
            2
          ),
          _c("div", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.webQuote",
                    modifiers: { webQuote: true }
                  }
                ],
                attrs: { href: "javascript:void(0)" }
              },
              [
                _vm._v(" Web quote "),
                _vm.isWebQuoteOpen
                  ? _c("v-icon", {
                      staticClass: "caret",
                      attrs: { scale: "1.5", name: "caret-down" }
                    })
                  : _c("v-icon", {
                      staticClass: "caret",
                      attrs: { scale: "1.5", name: "caret-left" }
                    })
              ],
              1
            )
          ]),
          _c(
            "b-collapse",
            {
              attrs: {
                id: "webQuote",
                accordion: "shopMainAccordion",
                role: "tabpanel"
              },
              model: {
                value: _vm.isWebQuoteOpen,
                callback: function($$v) {
                  _vm.isWebQuoteOpen = $$v
                },
                expression: "isWebQuoteOpen"
              }
            },
            [_c("WebQuote", { attrs: { shopId: _vm.shop.id } })],
            1
          ),
          _c("div", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.confirmationSettings",
                    modifiers: { confirmationSettings: true }
                  }
                ],
                attrs: { href: "javascript:void(0)" }
              },
              [
                _vm._v(" Confirmation Settings "),
                _vm.isConfirmationSettingsOpen
                  ? _c("v-icon", {
                      staticClass: "caret",
                      attrs: { scale: "1.5", name: "caret-down" }
                    })
                  : _c("v-icon", {
                      staticClass: "caret",
                      attrs: { scale: "1.5", name: "caret-left" }
                    })
              ],
              1
            )
          ]),
          _c(
            "b-collapse",
            {
              attrs: {
                id: "confirmationSettings",
                accordion: "shopMainAccordion",
                role: "tabpanel"
              },
              model: {
                value: _vm.isConfirmationSettingsOpen,
                callback: function($$v) {
                  _vm.isConfirmationSettingsOpen = $$v
                },
                expression: "isConfirmationSettingsOpen"
              }
            },
            [
              _c("confirmation-settings", {
                ref: "confirmationSettingsForm",
                attrs: {
                  settings: _vm.shop.shop_confirmation_settings,
                  shopId: _vm.shop.id
                },
                on: {
                  modifySettings: _vm.modifyConfirmationSettings,
                  confirmationSettingsInvalid: _vm.alertSettingsInvalid
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-100", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading || _vm.saving },
              on: { click: _vm.saveClicked }
            },
            [_vm._v(" Save ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }