var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pdf-options-header" }, [
    _c("span", { staticClass: "title" }, [
      _vm._v("Pdf Column Sort/Visibilty Options")
    ]),
    _c(
      "span",
      { staticClass: "reset-button" },
      [
        _c(
          "el-tooltip",
          { attrs: { content: "Reset Pdf columns to GlassBiller defaults" } },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: _vm.loading ? "" : "el-icon-refresh-left",
                  round: "",
                  loading: _vm.loading,
                  size: "mini"
                },
                on: { click: _vm.resetOptions }
              },
              [_vm._v(" Reset To Default ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }