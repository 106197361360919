import Vue from 'vue'
import * as R from 'ramda'

function setCursor(el, offset) {
  var setSelectionRange = function() {
    el.setSelectionRange(offset, offset)
  }
  if (el === document.activeElement) {
    setSelectionRange()
    setTimeout(setSelectionRange, 1) // Android Fix
  }
}

export default Vue.directive('number-input', {
  twoWay: false,
  inserted: function(el) {
    // let offset = true
    const input = el.querySelector('input')
    input.addEventListener('focus', () => {
      setCursor(input, input.value.length - 3)
    })
    input.addEventListener('mouseup', () => {
      setCursor(input, input.value.length - 3)
    })
  }
})
