<template>
  <svg
    width="29"
    height="37"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        x="-13%"
        y="-9.7%"
        width="134.8%"
        height="127%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feMorphology
          radius=".5"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset
          dx="1"
          dy="1"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M0 31V2.906C0 1.301 1.287 0 2.875 0h17.25C21.713 0 23 1.301 23 2.906V31l-11.5-6.781L0 31z"
        id="b"
      />
    </defs>
    <g transform="translate(2 2)" fill-rule="nonzero" fill="none">
      <use fill="#000" filter="url(#a)" xlink:href="#b" />
      <use stroke="#3B240F" :fill="fillColor || '#FF9393'" xlink:href="#b" />
    </g>
  </svg>
</template>

<script>
export default {
  props: ['fillColor'],
  computed: {}
}
</script>
