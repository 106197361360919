var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title:
          _vm.location.id > 0
            ? "Edit install location"
            : "New install location",
        id: "installLocationModal",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg"
      },
      on: { show: _vm.dialogAboutToAppear, hidden: _vm.cancelEdits }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-6" }, [
              _c("label", [_vm._v("Status")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.location.status,
                      expression: "location.status"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.location,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "active" } }, [
                    _vm._v("Active")
                  ]),
                  _c("option", { attrs: { value: "disabled" } }, [
                    _vm._v("Disabled")
                  ])
                ]
              )
            ]),
            _vm.location.id > 0
              ? _c("div", { staticClass: "form-group col-6" }, [
                  _c("label"),
                  _c("p", [
                    _vm._v(" ID: "),
                    _c("span", { staticClass: "idBox" }, [
                      _vm._v(_vm._s(_vm.location.id))
                    ])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "form-group col-6" }, [
              _c("label", [_vm._v("Name")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.location.name,
                    expression: "location.name"
                  }
                ],
                staticClass:
                  "form-control form-control-sm capitalize text-uppercase autocomplete-off",
                attrs: { type: "text" },
                domProps: { value: _vm.location.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.location, "name", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-12" },
              [
                _c("label", [_vm._v("Address")]),
                _c("address-input", {
                  attrs: { popperFix: "" },
                  model: {
                    value: _vm.location.g_address,
                    callback: function($$v) {
                      _vm.$set(_vm.location, "g_address", $$v)
                    },
                    expression: "location.g_address"
                  }
                })
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  size: "lg",
                  variant: "primary",
                  disabled: _vm.disabled
                },
                on: { click: _vm.saveInstallLocation }
              },
              [_vm._v(" Save ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", variant: "primary" },
                on: { click: _vm.cancelEdits }
              },
              [_vm._v(" Cancel ")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }