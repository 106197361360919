<template>
  <b-modal
    @show='dialogAboutToAppear'
    title='National Vendor Database Search'
    id='vendorSearchDialog'
    no-close-on-backdrop
    no-cose-on-esc
    header-bg-variant='primary'
    header-text-variant='light'
    size='lg'
    hide-header-close
    >
    <div class="form-row" style=''>
      <div class='form-group col-md-6'>
        <label for='searchInput'>Search or choose from list</label>
        <input id='searchInput' v-model='searchValue' class='form-control form-control-sm autocomplete-off' />
      </div>
    </div>
    <div class='form-row'>
      <div class='form-group col-md-12' :id='"vendor-list" + (selectedWarehouse !== null ? "-short" : "")'>
        <b-list-group-item v-for='(warehouse, index) in prunedWarehouseList' :key='index' href='#' @click='selectWarehouseClicked(warehouse)'>
          <i class="fa fa-circle" :style='"color:" + getVendorColor(warehouse.type)'></i> {{getVendorTitle(warehouse.type).toUpperCase()}} : <strong>{{warehouse.name}}</strong>
        </b-list-group-item>
      </div>
    </div>
    <div v-if='selectedWarehouse !== null' id='detail-wrapper'>
      <div class='form-row'>
        <div class='form-group col-md-12'>
          <Strong>Selected Warhouse For New Vendor</Strong>
        </div>
      </div>
      <div class='form-row'>
        <div class='form-group col-md-6'>
          <label>Name</label>
          <p id='name'>{{getVendorTitle(selectedWarehouse.type) + ':' + selectedWarehouse.name}}</p>
        </div>
        <div class='form-group col-md-6'>
          <label>Manager</label>
          <p id='name'>{{selectedWarehouse.manager}}</p>
        </div>
      </div>
      <div class='form-row'>
        <div class='form-group col-md-12'>
          <label>Address</label>
          <p id='address'>{{selectedWarehouse.g_address && selectedWarehouse.g_address.full_address}}</p>
        </div>
      </div>
      <div class='form-row'>
        <div class='form-group col-md-4'>
          <label>Phone</label>
          <p id='phone'>{{selectedWarehouse.phone}}</p>
        </div>
        <div class='form-group col-md-4'>
          <label>Fax</label>
          <p id='fax'>{{selectedWarehouse.fax}}</p>
        </div>
        <div class='form-group col-md-4'>
          <label>Email</label>
          <p id='email'>{{selectedWarehouse.email}}</p>
        </div>
      </div>
    </div>
    <div slot='modal-footer' class='w-100'>
      <div class='float-right'>
        <button type='button' class='btn btn-primary' variant="primary" @click='selectClicked'>Use Selected</button>
        <button type='button' class='btn btn-secondary' variant="primary" @click="cancelClicked">Cancel</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Vendor from '@/scripts/objects/vendor'
import { VendorMixin } from '@/scripts/helpers/vendor.helpers.js'

export default {
  data () {
    return {
      warehouseList: [],
      prunedWarehouseList: [],
      searchValue: '',
      selectedWarehouse: null
    }
  },
  methods: {
    dialogAboutToAppear () {
      this.searchValue = ''
      this.warehouseList = []
      this.prunedWarehouseList = []
      this.selectedWarehouse = null
      var self = this
      Vendor.getNationalWarehouseList(response => {
        // lets build out the label for the multiselect
        let tempArr = []
        for (var i = 0; i < response.length; i++) {
          var tempItem = response[i]
          tempItem.searchLabel = tempItem.type.toUpperCase() + ': ' + tempItem.name
          tempArr.push(tempItem)
        }
        self.warehouseList = tempArr
        self.pruneWarehouseList()
      })
    },
    selectWarehouseClicked (item) {
      this.selectedWarehouse = item
    },
    pruneWarehouseList () {
      this.prunedWarehouseList = []
      if (this.searchValue.length > 0) {
        var tempArr = []
        for (var i = 0; i < this.warehouseList.length; i++) {
          var tempW = this.warehouseList[i]
          var tempKeys = Object.keys(tempW)
          for (var j = 0; j < tempKeys.length; j++) {
            if (tempW[tempKeys[j]] && tempW[tempKeys[j]].toString().toLowerCase().includes(this.searchValue.toLowerCase())) {
              tempArr.push(tempW)
              break
            }
          }
        }
        this.prunedWarehouseList = tempArr
      } else {
        this.prunedWarehouseList = this.warehouseList
      }
    },
    selectClicked () {
      this.$emit('selectClicked', this.selectedWarehouse)
      this.$root.$emit('bv::hide::modal', 'vendorSearchDialog')
    },
    cancelClicked () {
      this.$root.$emit('bv::hide::modal', 'vendorSearchDialog')
    },
    getColor (val) {
      this.getVendorColor(val)
    },
    getTitle (val){
      this.getVendorTitle(val)
    },
    getWarehouseType (val) {
      if (val === 'pilk') return 'PILKINGTON'
      else if (val === 'mygrant') return ''
    }
  },
  computed: {
    vendorId () {
      var temp = 'vendor-list' + (this.selectedWarehouse ? '-short' : '')
      return temp
    }
  },
  watch: {
    searchValue () {
      this.pruneWarehouseList()
    }
  },
  mixins: [VendorMixin]
}
</script>

<style>
#vendor-list {
  height: 65vh !important;
  max-height: 65vh !important;
  overflow: auto;
  text-align: left;
  margin-top: 5px;
}

#vendor-list-short {
  height: 30vh !important;
  max-height: 30vh !important;
  overflow: auto;
  text-align: left;
  margin-top: 5px;
}

#detail-wrapper {
  margin-top: 18px;
  padding: 4px;
  border-radius: 10px;
  border-color: gray;
  border-style: solid;
  box-shadow: 0 1px 7px 0 rgba(117, 117, 117, 0.56);
}
</style>