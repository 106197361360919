<template>
  <el-table
    border
    header-cell-class-name="t-bg-gray-100 t-text-gray-700 t-font-medium t-break-normal"
    class="t-mb-4"
    :data="lines"
  >
    <el-table-column label="#" width="50" prop="purchaseOrderLineNumber" />

    <el-table-column label="Item & Description">
      <template slot-scope="scope">
        <div class="t-break-normal">
          <div class="t-font-medium">{{ scope.row.itemName }}</div>
          <div class="t-text-xs t-italic t-text-gray-400">
            {{ scope.row.itemDescription }}
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="Notes">
      <template slot-scope="scope" class="t-text-xs">
        {{ scope.row.notes }}
      </template>
    </el-table-column>

    <el-table-column label="Job #" width="120">
      <template slot-scope="scope" class="t-text-xs">
        <div v-if="scope.row.jobs !== null">
          <div
            v-for="i in scope.row.jobs"
            :key="i.id"
            class="t-flex t-items-center"
          >
            <el-tooltip effect="dark" content="Release to inventory" placement="top">
              <el-popconfirm
                title="Do you want to release the item back to inventory ?"
                confirm-button-text="Yes"
                cancel-button-text="No"
                @confirm="release({
                  lineNumber: scope.row.purchaseOrderLineNumber,
                  jobId: i.id
                })"
              >
                <i
                  slot="reference"
                  class="el-icon-circle-close t-mr-1 t-text-red-400 t-cursor-pointer"
                />
              </el-popconfirm>
            </el-tooltip>
            <a target="_blank" :href="'/jobs/' + i.id">{{ i.id }}</a>
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="REC #" width="100">
      <template slot-scope="scope">
        <div class="t-flex t-flex-col t-items-start t-space-y-2">
          <div
            v-for="(receive, index) in scope.row.receives"
            :key="index"
            class="t-px-2 t-rounded t-opacity-80 t-text-white t-cursor-pointer t-bg-red-500"
            @click="openReceive(receive.id)"
          >
            REC {{ receive.internalId }}
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="TPU #" width="100">
      <template slot-scope="scope">
        <div class="t-flex t-flex-col t-items-start t-space-y-2">
          <div
            v-for="(tpu, index) in scope.row.techPickUps"
            :key="index"
            class="t-px-2 t-rounded t-opacity-80 t-text-white t-cursor-pointer t-bg-green-400"
            @click="openTechPickUp(tpu.id)"
          >
            TPU {{ tpu.internalId }}
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="RTN #" width="100">
      <template slot-scope="scope">
        <div class="t-flex t-flex-col t-items-start t-space-y-2">
          <div
            v-for="(ret, index) in scope.row.returns"
            :key="index"
            class="t-px-2 t-rounded t-opacity-80 t-text-white t-cursor-pointer t-bg-orange-400"
            @click="openReturn(ret.id)"
          >
            RTN {{ ret.internalId }}
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="Qty" width="80" prop="quantity" />

    <el-table-column label="Cost" width="80">
      <template slot-scope="scope">
        ${{ scope.row.cost }}
      </template>
    </el-table-column>

    <el-table-column label="Total" width="80">
      <template slot-scope="scope">
        ${{ total(scope.row) }}
      </template>
    </el-table-column>

  </el-table>
</template>

<script>
export default {
  props: {
    poId: Number,
    lines: Array
  },

  methods: {
    total({ quantity, cost }) {
      return Math.round(quantity * cost * 100) / 100
    },

    async release({ lineNumber, jobId }) {
      try {
        await this.$unum.warehouse()
          .post(`purchase-orders/${this.poId}/release-units`, {
            lineNumber,
            jobId
          })
        this.$emit('units-released')
      } catch (e) {
        this.$message.error(e.response.data.message)
      }
    },

    openReceive(receiveId) {
      this.$store.dispatch('warehouse/openReceiveDialog', receiveId)
    },

    openTechPickUp(techPickUpId) {
      this.$store.dispatch('warehouse/openTechPickUpDialog', techPickUpId)
    },

    openReturn(returnId) {
      this.$store.dispatch('warehouse/openReturnDialog', returnId)
    }
  }
}
</script>
