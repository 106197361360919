var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "dashboardDialog",
            size: "lg",
            title: "Dashboard Controls"
          },
          on: { show: _vm.dialogAboutToAppear },
          model: {
            value: _vm.showDashboardDialog,
            callback: function($$v) {
              _vm.showDashboardDialog = $$v
            },
            expression: "showDashboardDialog"
          }
        },
        [
          _c("b-container", { attrs: { fluid: "" } }, [
            _c("div", { staticClass: "autoAddCheckList" }, [
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "w-75", attrs: { scope: "col" } }, [
                      _vm._v("Chart")
                    ]),
                    _c(
                      "th",
                      {
                        staticStyle: { "text-align": "right" },
                        attrs: { scope: "col" }
                      },
                      [_vm._v("Display")]
                    )
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.userChartOptions, function(chart, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(chart.name))]),
                      _c(
                        "td",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c("b-form-checkbox", {
                            attrs: { value: true },
                            model: {
                              value: chart.visible,
                              callback: function($$v) {
                                _vm.$set(chart, "visible", $$v)
                              },
                              expression: "chart.visible"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              ])
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c("b-col", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              size: "lg",
                              variant: "primary"
                            },
                            on: { click: _vm.updateUserCharts }
                          },
                          [_vm._v("Save")]
                        )
                      ]),
                      _c("b-col", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button", variant: "primary" },
                            on: { click: _vm.cancelChartsUpdate }
                          },
                          [_vm._v("Cancel")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }