var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "t-mt-4 xl:t-w-1/2",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "hide-required-asterisk": "",
        "label-width": "150px",
        size: "mini"
      }
    },
    [
      _c("el-form-item", { staticClass: "t-mb-3 t-mt-6" }, [
        _c("div", { staticClass: "t-text-gray-700 t-text-base" }, [
          _vm._v(
            " " +
              _vm._s(!_vm.credentials ? "Add" : "Update") +
              " account number "
          )
        ])
      ]),
      _c(
        "el-form-item",
        { attrs: { prop: "name" } },
        [
          _c(
            "span",
            {
              staticClass: "undo-label-styles",
              attrs: { slot: "label" },
              slot: "label"
            },
            [_vm._v("Name")]
          ),
          _c("el-input", {
            attrs: { placeholder: "Enter name" },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "number" } },
        [
          _c(
            "span",
            {
              staticClass: "undo-label-styles",
              attrs: { slot: "label" },
              slot: "label"
            },
            [_vm._v("Account number")]
          ),
          _c("el-input", {
            attrs: { placeholder: "Enter account number" },
            model: {
              value: _vm.form.number,
              callback: function($$v) {
                _vm.$set(_vm.form, "number", $$v)
              },
              expression: "form.number"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "t-mb-0" },
        [
          _c(
            "el-button",
            {
              staticClass: "t-mb-2 t-mr-2",
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(!_vm.credentials ? "Add" : "Update") + " ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "t-ml-0",
              attrs: { plain: "" },
              on: { click: _vm.close }
            },
            [_vm._v(" Close ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }