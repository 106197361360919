import { gql } from 'apollo-boost'
import { VEHICLE_FRAGMENT } from '../fragments/vehicle.fragment'

export const JOB_VEHICLE_QUERY = gql`
  query getVehicle($id: ID) {
    vehicle(id: $id) {
      ...VehicleFields
    }
  }
  ${VEHICLE_FRAGMENT}
`
