<template>
  <el-col>
    <gb-card :title="schema.title" :mdi="schema.icon" class="glaxis">
      <div
        ref="card"
        class="t-border t-border-solid t-border-gray-300 t-rounded-lg t-shadow-md
          t-flex-shrink-0 t-relative t-bg-white t-w-full t-p-4 t-pb-0"
      >
        <div
          class="t-flex t-flex-col t-space-y-4 md:t-flex-row md:t-space-x-6
            md:t-space-y-0 "
        >
          <div class="md:t-w-48 t-flex t-flex-col t-flex-shrink-0 t-items-center">
            <CarImage
              :vehicle="vehicle"
              class="t-w-40 t-h-24 t-self-center"
            />
            <div v-if="vehicle.plate_number && vehicle.plate_number.length > 0"
              class="t-border-2 t-border-solid t-border-gray-300 t-rounded-md
                t-mt-2 t-flex t-flex-col t-items-center t-px-3 t-py-1 t-shadow
                t-cursor-pointer t-bg-gray-50"
              slot="reference"
            >
              <div class="t-flex t-items-center">
                <div class="t-w-1 t-h-1 t-bg-gray-400 t-rounded-full" />
                <div class="t-px-2 t-uppercase t-text-gray-500 t-text-2xs t-flex-grow">
                  {{ selectedState }}
                </div>
                <div class="t-w-1 t-h-1 t-bg-gray-400 t-rounded-full" />
              </div>
              <div class="t-text-gray-700 t-tracking-wider">
                {{ vehicle.plate_number }}
              </div>
            </div>
          </div>
          <div class="t-flex-grow t-flex t-flex-col md:t-flex-row t-items-center">
            <img
              v-if="vehicle && vehicle.make_id && logoUrl"
              class="t-w-32 t-h-24 t-mx-2 t-flex-shrink-0 t-object-contain
                t-inline-block md:t-hidden"
              :src="logoUrl"
            >
            <div class="t-flex t-flex-col t-flex-grow">
              <div class="t-flex t-items-center">
                <div class="t-flex-grow">
                  <div class="t-text-gray-700 t-font-medium">{{ vehicleName }}</div>
                  <div class="t-text-gray-600 t-text-sm">{{ vehicleStyle }}</div>
                </div>
                <img
                  v-if="vehicle && vehicle.make_id && logoUrl"
                  class="t-w-32 t-h-24 t-mx-2 t-flex-shrink-0 t-object-contain
                    t-hidden md:t-inline-block"
                  :src="logoUrl"
                >
              </div>
              <div class="t-flex t-items-center">
                <gb-editable-data-row
                  :value="vin"
                  label="VIN"
                  :tooltip="'A policyholder often has more than one vehicle with the same make/model/yr on a policy, therefore, if available, the VIN may be sent to filter coverage confirmation information.'"
                  :loading="false"
                  type="vin"
                  :pre="true"
                  lazy
                  :disabled="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="vehicle-claim-additional-info">
          <gb-data-rows
            :schema="schema.fields"
            :data="lossNotice"
            @change="onChange"
            @input="onInput"
          />
        </div>
      </div>
    </gb-card>
  </el-col>
</template>

<script>
import CarImage from '@/components/JobPageV2/VehicleCatalog/VehiclesList/Vehicle/Image.vue'
import { StateObjects } from '@/scripts/helpers/helpers.js'

export default {
  components: {
    CarImage
  },
  props: {
    lossNotice: Object,
    schema: Object
  },
  data: () => ({
  }),
  computed: {
    vehicle() {
      var vehicle = {
        vin: this.lossNotice.vin_number,
        make: this.lossNotice.vehicle_make_text,
        make_id: this.lossNotice.vehicle_make,
        model: this.lossNotice.vehicle_model_text,
        model_id: this.lossNotice.vehicle_model,
        style: this.lossNotice.vehicle_style_text,
        style_id: this.lossNotice.vehicle_style,
        sub_model: this.lossNotice.sub_model,
        sub_model_id: this.lossNotice.vehicle_sub_model,
        year: this.lossNotice.vehicle_year_text,
        manual: this.lossNotice.manual_vehicle_entry === 1 ? '1' : null,
        nags_id: this.lossNotice.vehicle_id_source === 'NAGS' ? this.lossNotice.vehicle_id_number : null,
        plate_number: this.lossNotice.vehicle_plate_number,
        state: this.lossNotice.vehicle_state,
        thumb_url: this.lossNotice.vehicle_thumb_url,
        image_url: this.lossNotice.vehicle_image_url
      }
      return vehicle
    },
    vehicleName: function() {
      if (!this.vehicle) return null
      const { year, make, model, sub_model } = this.vehicle
      return `${year} ${make} ${model} ${sub_model || ''}`.trim()
    },
    vehicleStyle: function() {
      if (!this.vehicle) return null
      return this.vehicle.style
    },
    vin() {
      return this.vehicle?.vin || ''
    },
    logoUrl() {
      try {
        return require(`@/assets/img/car-logos/${this.vehicle.make_id}.png`)
      } catch (err) {
        return null
      }
    },
    selectedState() {
      return StateObjects.find(i => i.value === this.vehicle.state)?.text
    },
    states() {
      return StateObjects
    }
  },
  methods: {
    async onChange({ key, value }, col) {
      this.lossNotice[col.id] = value
    },
    async onInput(value, col) {
      this.lossNotice[col.id] = value
    }

  }
}
</script>

<style scoped>
  .vehicle-claim-additional-info {
    margin-top: 15px;
  }
</style>
