<template>
  <div>
    <div class="autocomplete input-group-job-nav" style="position:relative">
      <input class="form-control quick-search autocomplete-off"
        name="autocompleteOffPleaseThankYou"
        :readonly='readonly'
        :placeholder="filter"
        v-model="internalSearchValue"
        autocomplete="false"
        @keydown="onKeyDown"
        @keydown.enter = 'enter'>
    </div>
    <FailedJobNavDialog :message="failMessage" />
    <QuickNavResultsDialog :jobs="jobResults" :results="resultsCount" :filter="filter" :searchVal='jobSearchVal'/>
  </div>
</template>

<script>
import Job from '@/scripts/objects/job'
import FailedJobNavDialog from '../modals/FailedJobNavDialog'
import QuickNavResultsDialog from '../modals/QuickNavResultsDialog'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default {
  data () {
    return {
      value: '',
      jobSearchVal: '',
      readonly: true,
      jobResults: [],
      resultsCount: 0,
      failMessage: ''
    }
  },
  props: ['filter'],
  methods: {
    enter () {
      var self = this
      self.$emit('toggleNavSearch')
      // do our special checks for different types
      if (self.filter === 'VIN #' && self.jobSearchVal.length < 6) {
        alert('Please enter at least 6 characters for vin search')
        self.$emit('toggleNavSearch')
      }

      Job.newSearch(self.jobSearchVal, self.filter, 0, function (response, count) {
        if (response !== 'not found' && response.length > 1) {
          self.resultsCount = count.count
          self.jobResults = response
          self.$emit('toggleNavSearch')
          self.$root.$emit('bv::show::modal', 'quickNavResultsModal')
        } else if (response !== 'not found' && response.length === 1) {
          if (self.filter === 'MOBILE #') {
            if (response[0].salesidejob_id) {
              // if it has a salesidejob_id, then its a glassbiller job
              self.$emit('toggleNavSearch')
              self.changeRouteWithCheck('/jobs/' + response[0].id)
              self.$nextTick(() => {
                self.jobSearchVal = ''
              })
            } else {
              // call salesidejob dialog
              self.$emit('toggleNavSearch')
              self.$root.$emit('showMobileJob', response[0].id, false)
            }
          } else {
            self.$emit('toggleNavSearch')
            self.changeRouteWithCheck('/jobs/' + response[0].id)
            self.$nextTick(() => {
              self.jobSearchVal = ''
            })
          }
        } else {
          self.$emit('toggleNavSearch')
          self.failMessage = `Could not find a ${self.filter.split(' ')[0].toLowerCase()} ` + (self.filter === 'MOBILE #' ? `job ` : ``) + `with that number. Please double check the number and try again.`
          self.$root.$emit('bv::show::modal', 'failedJobNavModal')
          self.$nextTick(() => {
            self.jobSearchVal = ''
          })
        }
      }, function (error) {
      })
      if (this.iMobile) {
        this.$root.$emit('bv::toggle::collapse', 'navbarCollapse')
      }
    },
    onKeyDown (evt) {
      let allowedKeys = [8, 46, 37, 39]
      if (this.filter === 'JOB #') {
        if ((evt.keyCode >= 65 && evt.keyCode <= 90) || (this.internalSearchValue.length >= 7 && !allowedKeys.includes(evt.keyCode))) {
          evt.preventDefault()
        }
      } else if (this.filter === 'VIN #') {
        // vin can have letters
        if (this.internalSearchValue.length >= 17 && !allowedKeys.includes(evt.keyCode)) {
          evt.preventDefault()
        }
      } else if (this.filter === 'REF #') {
        if ((evt.keyCode >= 65 && evt.keyCode <= 90) || (this.internalSearchValue.length >= 12 && !allowedKeys.includes(evt.keyCode))) {
          evt.preventDefault()
        }
      } else if (this.filter === 'RO #') {
        if ((evt.keyCode >= 65 && evt.keyCode <= 90) || (this.internalSearchValue.length >= 12 && !allowedKeys.includes(evt.keyCode))) {
          evt.preventDefault()
        }
      } else if (this.filter === 'PO #') {
        if ((evt.keyCode >= 65 && evt.keyCode <= 90) || (this.internalSearchValue.length >= 12 && !allowedKeys.includes(evt.keyCode))) {
          evt.preventDefault()
        }
      } else if (this.filter === 'ADH #') {
        // adh can have letters
        if (this.internalSearchValue.length >= 17 && !allowedKeys.includes(evt.keyCode)) {
          evt.preventDefault()
        }
      } else if (this.filter === 'PLATE #') {
        // plate can have letters
        if (this.internalSearchValue.length >= 7 && !allowedKeys.includes(evt.keyCode)) {
          evt.preventDefault()
        }
      } else if (this.filter === 'UNIT #') {
        // FOR NOW, unit can have letters
        if (this.internalSearchValue.length >= 12 && !allowedKeys.includes(evt.keyCode)) {
          evt.preventDefault()
        }
      }
    }
  },
  computed: {
    internalSearchValue: {
      get () {
        return this.jobSearchVal
      },
      set (val) {
        this.jobSearchVal = val
      }
    }
  },
  mounted () {
    var self = this
    this.$nextTick(() => { self.readonly = false })
  },
  components: {
    FailedJobNavDialog,
    QuickNavResultsDialog
  },
  watch: {
    filter () {
      this.internalSearchValue = ''
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped>
.autocomplete {
  position: relative;
  width: 100%;
}

.input-group-job-nav input.form-control {
  border: 0px;
  background: transparent;
  width: 100px;
  text-align:center;
}

  /* do not group these rules */
*::-webkit-input-placeholder {
  color: whitesmoke;
}
*:-moz-placeholder {
  /* FF 4-18 */
  color: whitesmoke;
  opacity: 1;
}
*::-moz-placeholder {
  /* FF 19+ */
  color: whitesmoke;
  opacity: 1;
}
*:-ms-input-placeholder {
  /* IE 10+ */
  color: whitesmoke;
}
*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: whitesmoke;
}
*::placeholder {
  /* modern browser */
  color: whitesmoke;
}
/* This is here to hide the arrow buttons in the select arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>
