import axios from 'axios'
import Vue from 'vue'
import { Message } from 'element-ui'
import cfg from '@/config'

export const legacyBaseUrl = `${cfg.uri}/legacy/api/v1`

const request = axios.create({
  baseURL: legacyBaseUrl,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }
})

const isHandlerEnabled = (config = {}) => {
  return !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled)
}

request.interceptors.request.use(async request => {
  const { $auth } = Vue.prototype
  if (isHandlerEnabled(request) && $auth && !$auth.skipAuth) {
    const token = await $auth.getTokenSilently()
    request.headers.authorization = `Bearer ${token}`
    return request
  }
  return request
})

request.interceptors.response.use(
  response => {
    return response
  },
  async function(error) {
    Message.error(error.message)
    return Promise.reject(error)
  }
)

request.defaults = {
  params: { _cacheBust: Math.round(Math.random() * 100000) }
}

export default request
