<template>
  <el-dialog
    append-to-body
    width="400px"
    :visible="isOpen"
    :before-close="handleClose"
    top="18vh"
    custom-class="add-channel-user-dialog">
    <span slot="title" class="t-text-lg">
      <span class="t-mr-8 t-flex">
        <div class="t-truncate">
          <span>Add users to </span>
          <span v-if="!activeChannel.isPrivate">#</span>
          <i v-else class="el-item-lock"></i>
          {{ activeChannel.uniqueName }}
        </div>
      </span>
    </span>
    <div class="t-h-full">
      <div v-if="!users" v-loading="true" class="t-h-20"></div>
      <el-checkbox-group
        v-else
        v-model="selectedIdentities"
        class="t-flex t-flex-col t-mb-2"
      >
        <el-checkbox
          v-for="identity in users"
          :key="identity"
          :label="identity"
          :disabled="!!members.find(i => i.identity === identity)"
          border
          class="t-normal-case t-flex t-items-center t-h-10 t-mx-0 t-mb-2 t-font-normal"
        >
          <i class="el-icon-user"></i>
          <span class="t-text-lg">
            {{ identity }}
          </span>
          <span
            v-if="!!members.find(i => i.identity === identity)"
            class="t-text-xs t-ml-4"
          >
            already joined
          </span>
        </el-checkbox>
      </el-checkbox-group>
      <div class="t-text-right">
        <el-button
          type="primary"
          icon="el-icon-plus"
          :disabled="!selectedIdentities.length"
          :loading="submitting"
          @click="add"
          size="medium"
          class="t-mt-2"
        >
          Add
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ChatService } from '@/services/ChatService'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  data: () => ({
    selectedIdentities: [],
    submitting: false
  }),
  computed: {
    username: function() {
      return this.$store.state.user.username
    },
    isOpen: function() {
      return this.$store.state.chat.addUsersDialogIsOpen
    },
    activeChannel: function() {
      return this.$store.state.chat.activeChannel
    },
    members: function() {
      return this.$store.state.chat.members
    },
    users: function() {
      return this.$store.state.chat.users
    }
  },
  watch: {
    isOpen: async function(value) {
      if (value) {
        this.selectedIdentities = []
        await this.$store.dispatch(
          chatTypes.namespace + chatTypes.actions.getUsers
        )
      }
    }
  },
  methods: {
    handleClose: function() {
      this.$store.commit(
        chatTypes.namespace + chatTypes.mutations.setAddUsersDialogIsOpen,
        false
      )
    },
    add: async function() {
      this.submitting = true
      const channel = await ChatService.getChannelBySid(this.activeChannel.sid)
      for (const identity of this.selectedIdentities) {
        await channel.add(identity)
      }
      await this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.getMembers,
        channel
      )
      this.handleClose()
      this.submitting = false
    }
  }
}
</script>

<style lang="scss">
.add-channel-user-dialog {
  @apply t-font-sans;
  .el-dialog__header {
    @apply t-border-0 t-border-b t-border-solid t-border-gray-300;
  }
  .el-dialog__body {
    @apply t-py-4;
  }
}
</style>
