var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tooltip",
        {
          staticClass: "scheduler-tech-time-slot",
          attrs: {
            content: _vm.types[_vm.slot.type].tooltip,
            placement: "right"
          }
        },
        [
          _c(
            "el-badge",
            {
              style: { zIndex: 40 },
              attrs: { value: _vm.count, type: _vm.badgeType }
            },
            [
              _c(
                "el-tag",
                {
                  staticClass: "mr-1 mb-1",
                  attrs: { size: "small", type: _vm.types[_vm.slot.type].color }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.slot.all_day ? "All Day" : _vm.slotTime) +
                      " "
                  ),
                  _vm.slot.type === "limit"
                    ? _c("span", { staticClass: " t-font-extrabold" }, [
                        _vm._v(" (" + _vm._s(_vm.slot.job_limit) + " max) ")
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }