var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-result-row",
      class: { highlighted: _vm.highlighted },
      on: {
        click: function($event) {
          return _vm.$emit("select", _vm.row)
        }
      }
    },
    [
      _vm.icon
        ? _c("div", { staticClass: "search-result-row-icon" }, [
            _c("i", {
              staticClass: "material-icons",
              class: ((_obj = {}), (_obj[_vm.icon] = true), _obj)
            })
          ])
        : _vm._e(),
      _c("div", { staticClass: "search-result-content" }, [
        _c("div", { staticClass: "t-flex t-justify-between t-items-start" }, [
          _c("div", { staticClass: "search-result-ids" }, [
            _c("div", [
              _c("div", { staticClass: "id-label" }, [_vm._v("ID:")]),
              _c("div", {
                staticClass: "id-value",
                domProps: { innerHTML: _vm._s(_vm.withMatch("id")) }
              })
            ]),
            _vm.row.referral_number
              ? _c("div", [
                  _c("div", { staticClass: "id-label" }, [_vm._v("REF#:")]),
                  _c("div", {
                    staticClass: "id-value",
                    domProps: {
                      innerHTML: _vm._s(_vm.withMatch("referral_number"))
                    }
                  })
                ])
              : _vm._e(),
            _vm.row.stock_number && _vm.row.stock_number === _vm.query.value
              ? _c("div", [
                  _c("div", { staticClass: "id-label" }, [
                    _vm._v("ADH/STOCK#:")
                  ]),
                  _c("div", {
                    staticClass: "id-value",
                    domProps: {
                      innerHTML: _vm._s(_vm.withMatch("stock_number"))
                    }
                  })
                ])
              : _vm.row.purchase_order_number &&
                _vm.row.requisition_order_number === _vm.query.value
              ? _c("div", [
                  _c("div", { staticClass: "id-label" }, [_vm._v("RO#:")]),
                  _c("div", {
                    staticClass: "id-value",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.withMatch("requisition_order_number")
                      )
                    }
                  })
                ])
              : _vm.row.purchase_order_number &&
                _vm.row.purchase_order_number === _vm.query.value
              ? _c("div", [
                  _c("div", { staticClass: "id-label" }, [_vm._v("PO#:")]),
                  _c("div", {
                    staticClass: "id-value",
                    domProps: {
                      innerHTML: _vm._s(_vm.withMatch("purchase_order_number"))
                    }
                  })
                ])
              : _vm.row.internal_number
              ? _c("div", [
                  _c("div", { staticClass: "id-label" }, [_vm._v("INT#:")]),
                  _c("div", {
                    staticClass: "id-value",
                    domProps: {
                      innerHTML: _vm._s(_vm.withMatch("internal_number"))
                    }
                  })
                ])
              : _vm.row.policy_number
              ? _c("div", [
                  _c("div", { staticClass: "id-label" }, [_vm._v("POL#:")]),
                  _c("div", {
                    staticClass: "id-value",
                    domProps: {
                      innerHTML: _vm._s(_vm.withMatch("policy_number"))
                    }
                  })
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "search-result-tags" },
            [
              _c("div", { staticClass: "t-text-xs t-ml-auto result-date" }, [
                _vm._v(" " + _vm._s(_vm.date) + " ")
              ]),
              _vm.type === "job" || _vm.type === "salesidejob"
                ? _c("job-status-tag", {
                    attrs: {
                      "job-status":
                        _vm.type === "salesidejob"
                          ? "salesidejob"
                          : _vm.row.status,
                      small: "",
                      disabled: ""
                    }
                  })
                : _vm._e(),
              _vm.type === "consumer"
                ? _c(
                    "consumer-tags",
                    _vm._b(
                      { attrs: { small: "" } },
                      "consumer-tags",
                      _vm.row,
                      false
                    )
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _c("div", { staticClass: "t-flex t-items-center" }, [
          _c("div", {
            staticClass: "t-text-sm t-font-semibold t-pr-2",
            domProps: { innerHTML: _vm._s(_vm.name) }
          }),
          _c("div", {
            staticClass: "t-text-sm t-pr-2 t-font-light",
            domProps: { innerHTML: _vm._s(_vm.withPhoneMatch()) }
          }),
          _c("div", {
            staticClass: "t-text-xs result-address",
            domProps: { innerHTML: _vm._s(_vm.withMatch("full_address")) }
          })
        ]),
        _vm.showBillTo
          ? _c("div", { staticClass: "t-flex t-items-center t-text-xxs" }, [
              _c("div", [_vm._v("BILL TO:")]),
              _c("div", {
                staticClass: "id-value",
                domProps: {
                  innerHTML: _vm._s(_vm.withMatch("bill_to_company_name"))
                }
              })
            ])
          : _vm._e(),
        _c("div", { staticClass: "t-flex t-items-center" }, [
          _c("div", {
            staticClass: "t-text-sm",
            domProps: { innerHTML: _vm._s(_vm.withMatch("vehicle_name")) }
          }),
          _c("div", { staticClass: "search-result-ids t-ml-4" }, [
            _vm.row.vin
              ? _c("div", [
                  _c("div", { staticClass: "id-label" }, [_vm._v("VIN:")]),
                  _c("div", {
                    staticClass: "id-value",
                    domProps: { innerHTML: _vm._s(_vm.withMatch("vin")) }
                  })
                ])
              : _vm._e(),
            _vm.row.unit
              ? _c("div", [
                  _c("div", { staticClass: "id-label" }, [_vm._v("UNIT#:")]),
                  _c("div", {
                    staticClass: "id-value",
                    domProps: { innerHTML: _vm._s(_vm.withMatch("unit")) }
                  })
                ])
              : _vm._e(),
            _vm.row.plate
              ? _c("div", [
                  _c("div", { staticClass: "id-label" }, [_vm._v("plate#:")]),
                  _c("div", {
                    staticClass: "id-value",
                    domProps: { innerHTML: _vm._s(_vm.withMatch("plate")) }
                  })
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "t-text-xs result-shop",
              staticStyle: { "text-align": "right" }
            },
            [_vm._v(" " + _vm._s(_vm.row.shop_name) + " ")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }