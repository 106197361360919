<template>
  <div class="t-flex t-flex-col t-items-center t-justify-center t-h-full
              t-text-center">
    <img
      src="@/assets/img/LiveChat.png"
      alt="Live Chat"
    >
    <div class="t-mt-8 t-mb-2 t-text-xl t-text-gray-700">
      Welcome to the Chat
    </div>
    <div class="t-text-gray-600">
      Select a joined channel or browse public channels
    </div>
  </div>
</template>

<script>
export default {

}
</script>
