<template>
  <div
    class="search-result-row"
    @click="$emit('select', row)"
    :class="{ highlighted }"
  >
    <div class="search-result-row-icon" v-if="icon">
      <i class="material-icons" :class="{ [icon]: true }" />
    </div>
    <div class="search-result-content">
      <div class="t-flex t-justify-between t-items-start">
        <div class="search-result-ids">
          <div>
            <div class="id-label">ID:</div>
            <div class="id-value" v-html="withMatch('id')" />
          </div>
          <div v-if="row.referral_number">
            <div class="id-label">REF#:</div>
            <div class="id-value" v-html="withMatch('referral_number')" />
          </div>
          <div v-if="row.stock_number && row.stock_number === query.value">
            <div class="id-label">ADH/STOCK#:</div>
            <div class="id-value" v-html="withMatch('stock_number')" />
          </div>
          <div
            v-else-if="
              row.purchase_order_number &&
              row.requisition_order_number === query.value
            "
          >
            <div class="id-label">RO#:</div>
            <div
              class="id-value"
              v-html="withMatch('requisition_order_number')"
            />
          </div>
          <div
            v-else-if="
              row.purchase_order_number &&
              row.purchase_order_number === query.value
            "
          >
            <div class="id-label">PO#:</div>
            <div class="id-value" v-html="withMatch('purchase_order_number')" />
          </div>
          <div v-else-if="row.internal_number">
            <div class="id-label">INT#:</div>
            <div class="id-value" v-html="withMatch('internal_number')" />
          </div>
          <div v-else-if="row.policy_number">
            <div class="id-label">POL#:</div>
            <div class="id-value" v-html="withMatch('policy_number')" />
          </div>
        </div>
        <div class="search-result-tags">
          <div class="t-text-xs t-ml-auto result-date">
            {{ date }}
          </div>
          <job-status-tag
            :job-status="type === 'salesidejob' ? 'salesidejob' : row.status"
            v-if="type === 'job' || type === 'salesidejob'"
            small
            disabled
          />
          <consumer-tags v-bind="row" small v-if="type === 'consumer'" />
        </div>
      </div>
      <div class="t-flex t-items-center">
        <div class="t-text-sm t-font-semibold t-pr-2" v-html="name" />
        <div class="t-text-sm t-pr-2 t-font-light" v-html="withPhoneMatch()" />
        <div
          class="t-text-xs result-address"
          v-html="withMatch('full_address')"
        />
      </div>
      <div class="t-flex t-items-center t-text-xxs" v-if="showBillTo">
        <div>BILL TO:</div>
        <div class="id-value" v-html="withMatch('bill_to_company_name')" />
      </div>
      <div class="t-flex t-items-center">
        <div class="t-text-sm" v-html="withMatch('vehicle_name')" />
        <div class="search-result-ids t-ml-4">
          <div v-if="row.vin">
            <div class="id-label">VIN:</div>
            <div class="id-value" v-html="withMatch('vin')" />
          </div>
          <div v-if="row.unit">
            <div class="id-label">UNIT#:</div>
            <div class="id-value" v-html="withMatch('unit')" />
          </div>
          <div v-if="row.plate">
            <div class="id-label">plate#:</div>
            <div class="id-value" v-html="withMatch('plate')" />
          </div>
        </div>
        <div class="t-text-xs result-shop" style="text-align:right;">
          {{ row.shop_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobStatusTag from '@/components/JobPageV2/helpers/JobStatusTag'
import ConsumerTags from '@/components/JobPageV2/helpers/ConsumerTags'
import {
  formatAppointmentDate,
  parseDate
} from '@/scripts/helpers/date.helpers'
import {
  highlightMatch,
  matchEveryWord,
  highlightPhone
} from '@/scripts/helpers/search.helpers'

const icons = {
  job: 'md-work',
  consumer: 'md-person',
  salesidejob: 'md-work_outline'
}

export default {
  inject: ['query'],
  components: { JobStatusTag, ConsumerTags },
  props: {
    row: {},
    type: String,
    highlighted: Boolean
  },
  computed: {
    icon() {
      return icons[this.row.type || this.type]
    },
    isCompany() {
      return this.row.is_commercial || this.row.is_insurance
    },
    date() {
      return (this.row.date && this.row.date !== '0000-00-00')
        ? formatAppointmentDate(parseDate(this.row.date))
        : (this.row.scheduled_date && this.row.scheduled_date !== '0000-00-00')
          ? formatAppointmentDate(parseDate(this.row.scheduled_date))
          : ''
    },
    name() {
      if (this.isCompany) {
        return highlightMatch(this.query.value)(this.row.company) || ''
      } else if (this.row.first_name || this.row.last_name) {
        return matchEveryWord(this.query.value)(
          `${this.row.first_name || ''} ${this.row.last_name || ''}`
        )
      } else if (this.row.customer_first_name || this.row.customer_last_name) {
        return matchEveryWord(this.query.value)(
          `${this.row.customer_first_name || ''} ${this.row.customer_last_name || ''}`
        )
      } else {
        return ''
      }
    },
    showBillTo() {
      return this.row.consumer_id !== this.row.bill_to_id && this.row.bill_to_company_name && this.row.bill_to_company_name !== ""
    }
  },
  methods: {
    withMatch(key) {
      return highlightMatch(this.query.value)(this.row[key])
    },
    withPhoneMatch() {
      if (this.row.phone_number) {
        return highlightPhone(this.query.value)(this.row.phone_number)
      } else if (this.row.customer_phone1) {
        return highlightPhone(this.query.value)(this.row.customer_phone1)
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.search-result-row {
  border-radius: 6px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  &.highlighted {
    background: $grayscale-3;
  }
  padding: $padding-sm;
  display: flex;
  align-items: flex-start;
  min-height: 60px;
  .search-result-content {
    flex-grow: 1;
  }
  .search-result-row-icon {
    display: flex;
    align-items: center;
    height: 100%;
    align-self: center;
    i {
      line-height: 24px;
    }
    color: $grayscale-6;
    padding: 0 $padding-xs;
    margin-right: $padding-xs;
  }
  .search-result-tags {
    display: flex;
    align-items: center;
    .job-status-tag {
      margin: 0;
      display: inline-block;
    }
  }
  .result-address {
    color: $grayscale-7;
    line-height: 14px;
  }
  .search-result-ids {
    display: flex;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      .id-label {
        @include gb-label;
      }
      .id-value {
        font-size: 12px;
        line-height: 12px;
        margin-left: $padding-xxs;
      }
      & + div {
        margin-left: $padding-md;
      }
    }
  }
  .result-date {
    margin-left: auto;
    margin-right: $padding-sm;
  }
  .result-shop {
    color: $grayscale-7;
    margin-left: auto;
    margin-right: $padding-sm;
  }
}
</style>

<style lang="scss">
.search-result-row {
  u {
    background: $watermelon-pink;
    color: #fff;
    text-decoration: none;
    border-radius: 2px;
    letter-spacing: 1px;
  }
}
</style>
