var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-badge",
    { attrs: { max: 99, value: _vm.unreadCount, hidden: !_vm.unreadCount } },
    [
      _c(
        "div",
        { staticClass: "gb-icon-white-button", on: { click: _vm.open } },
        [_c("i", { staticClass: "material-icons-outline md-notifications" })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }