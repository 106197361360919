import * as R from 'ramda'

export const round = v => Math.round(v * 100) / 100
export const getPercent = R.pipe(R.multiply, R.multiply(0.01), round)

export const calculateDiscount = (discounted, original) =>
  discounted === 0
    ? original
    : R.pipe(
      R.divide(discounted),
      R.multiply(100),
      round,
      R.subtract(100)
    )(original)

export const subtractPercent = R.pipe(
  R.converge(R.subtract, [R.identity, getPercent]),
  round
)

export const calculatePercent = R.pipe(R.divide, R.multiply(100), round)

export const addPercent = R.curry(
  R.pipe(R.converge(R.add, [R.identity, getPercent]), round)
)

export const sumByProp = prop => R.pipe(R.pluck(prop), R.sum, round)

export const toNum = v => +v

export const subtractPercentToMatch = R.useWith(R.flip(R.divide), [
  R.pipe(R.add(100), R.multiply(0.01)),
  R.identity
])

export const getTotals = R.pick([
  'total_materials',
  'total_labor',
  'total_list_price',
  'total_cost',
  'total_materials_taxes',
  'total_labor_taxes',
  'total_taxes',
  'total_hours',
  'total_subtotal',
  'total_after_taxes',
  'total_after_deductible',
  'total_sale_source_commission',
  'total_sales_rep_commission',
  'total_tech_commission',
  'total_location_commission',
  'total_commission',
  'total_csr_commission',
  'total_deductible_after_payments',
  'total_balance_after_payments',
  'total_margin'
])
