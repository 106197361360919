var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        "append-to-body": "",
        "close-on-click-modal": false,
        title: "GB Form templates",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        "custom-class": "gb-form-template-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-button",
              {
                staticClass: "pull-right",
                attrs: { type: "primary", size: "mini", icon: "el-icon-plus" },
                on: {
                  click: function($event) {
                    return _vm.openForm()
                  }
                }
              },
              [_vm._v("Create")]
            )
          ],
          1
        )
      ]),
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.templates } },
        [
          _c("el-table-column", { attrs: { prop: "name", label: "Name" } }),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "Actions", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-button", {
                      staticClass: "mb-2 mr-2",
                      attrs: { size: "mini", icon: "el-icon-edit" },
                      on: {
                        click: function($event) {
                          return _vm.openForm(scope.row)
                        }
                      }
                    }),
                    _c("el-button", {
                      staticClass: "ml-0",
                      attrs: {
                        size: "mini",
                        type: "danger",
                        icon: "el-icon-delete"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleDelete(scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "el-button",
              {
                staticClass: "pull-right mt-3",
                attrs: { plain: "" },
                on: { click: _vm.handleClose }
              },
              [_vm._v(" Close ")]
            )
          ],
          1
        )
      ]),
      _c("Form", {
        attrs: { template: _vm.template, visible: _vm.formVisible },
        on: { close: _vm.closeForm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }