<template>
  <div class="scheduler-times-row">
    <job-card-info-block
      v-if="item.arrival"
      icon="sign-in-alt"
      :text="convert(item.arrival)"
      small
      :solid="solid"
    />
    <job-card-info-block
      v-if="item.departure"
      icon="sign-out-alt"
      :text="convert(item.departure)"
      small
      :solid="solid"
    />
  </div>
</template>

<script>
import JobCardInfoBlock from './JobCardInfoBlock'
import convertTime from 'convert-time'

export default {
  components: { JobCardInfoBlock },
  props: ['item', 'solid'],
  methods: {
    convert(time) {
      return time && convertTime(time, 'hh:mma')
    }
  }
}
</script>

<style lang="scss" scoped>
.scheduler-times-row {
  display: flex;
  justify-content: space-between;
  > div {
    &:first-of-type {
      margin-right: $padding-xs;
    }
  }
}
</style>
