var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: "600px",
        "destroy-on-close": "",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("template", { slot: "title" }, [
        _vm.ediData
          ? _c(
              "div",
              {
                staticClass: "form-group col-6",
                staticStyle: { "font-size": "22px", "font-weight": "bold" },
                style: {
                  color: _vm.ediStatusColor(_vm.ediData.edi_status || "")
                }
              },
              [_vm._v(" " + _vm._s(_vm.ediData.edi_status || "") + " ")]
            )
          : _vm._e()
      ]),
      _vm.ediData
        ? _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "10px" } },
            [
              _c("div", {
                staticClass: "form-group col-12",
                domProps: { innerHTML: _vm._s(_vm.ediData.edi_message || "") }
              })
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }