<template>
  <div class="fake-job-data">
    <div class="fake-job-header">
      <h6>Create jobs with fake data</h6>
      <i class="el-icon-loading" v-if="loading" />
    </div>
    <div class="fake-job-form">
      <el-select
        size="small"
        v-model="shop_id"
        placeholder="Shop ID (default if empty)"
      >
        <el-option
          v-for="shop in shops"
          :key="shop.id"
          :value="shop.id"
          :label="shop.name"
        />
      </el-select>
      <el-input-number size="small" :min="1" v-model="count" />
      <el-button @click="generate" size="small">Create</el-button>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  data() {
    return {
      loading: false,
      shop_id: null,
      shops: [],
      count: 1
    }
  },
  computed: {user: get('user')},
  methods: {
    async generate() {
      try {
        this.loading = true
        const { data } = await this.$unum.scheduler().get('/fake/job', {
          params: {shop_id: this.shop_id, count: this.count}
        })
        window.location.href = `/jobs/${data.job_id}`
        window.reload()
      } finally {
        this.loading = false
      }
    },
    async getShops() {
      const { data } = await this.$unum.sch().get('/data/shops')
      this.shops = data
      this.shop_id = this.user.shop_id
    }
  },
  mounted() {
    this.getShops()
  }
}
</script>

<style lang="scss" scoped>
.fake-job-header {
  display: flex;
  align-items: center;
}
.fake-job-form {
  display: flex;
  .el-select {
    flex-grow: 1;
  }
  .el-input-number,
  .el-button {
    flex-shrink: 0;
    margin-left: $padding-md;
  }
}
</style>
