<template>
  <b-modal
    :title="'Invalid Fields'"
    id="invalidFieldsModal"
    header-bg-variant='primary' header-text-variant='light' size="lg">
    <div class="row">
      <div class="col-md-12">
        <p>Please fix the following errors before preceding:</p>
        <p><b><span v-html="text"/></b></p>
      </div>
    </div>
    <div slot="modal-footer" class='w-100'>
      <div class='float-right'>
        <button type='button' class='btn btn-secondary' variant="primary" @click="okButtonPressed">Ok</button>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: ['text'],
  methods: {
    okButtonPressed: function () {
      this.$root.$emit('bv::hide::modal', 'invalidFieldsModal')
    }
  }
}
</script>

<style>

</style>
