<template>
  <div>
    <label for='title'>{{title}}</label>
    <div class='form-group'>
        <input
        ref='input'
        type='text'
        v-bind:class='classes'
        :placeholder='placeholder'
        :title='title'
        v-model='inputValue'
        :disabled='inDisabled'
        :style='inStyle'
         />
        <div class="invalid-feedback">
          {{ internalFailMessage }}
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['placeholder', 'title', 'value', 'failMessage', 'inDisabled', 'inStyle', 'capitalizeAll'],
  data () {
    return {
      showFailureMessage: false
    }
  },
  methods: {
    updateShowFailureMessage: function (activateShowFailureMessage) {
      this.showFailureMessage = activateShowFailureMessage
    },
    isValid: function () {
      return (this.inputValue && this.inputValue.length) > 0
    },
    focus: function () {
      this.$refs.input.focus()
    }
  },
  computed: {
    internalFailMessage: function () {
      if (this.isValid() || !this.showFailureMessage) {
        return ''
      } else if (this.failMessage) {
        return this.failMessage
      } else {
        return '\'' + this.title + '\' is required.'
      }
    },
    classes: function () {
      var prop = ['form-control', 'form-control-sm']
      prop.push(this.isValid() ? 'is-valid' : 'is-invalid')
      return prop
    },
    inputValue: {
      get: function () {
        var value = this.value ? this.value : ''
        return this.capitalizeAll ? value.toUpperCase() : value
      },
      set: function (changedVal) {
        this.$emit('update:value', this.capitalizeAll ? changedVal.toUpperCase() : changedVal)
      }
    }
  },
  watch: {
    
  }
}
</script>

<style>

</style>
