var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    _vm._b(
      {
        staticClass: "v-icon-button",
        attrs: { circle: "" },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      "el-button",
      Object.assign({}, _vm.$props, _vm.$attrs),
      false
    ),
    [_c("v-icon", { attrs: { name: _vm.name } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }