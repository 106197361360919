var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-pb-4" },
    [
      _vm.parts.length > 0
        ? _c(
            "el-table",
            {
              staticClass: "t-w-full",
              attrs: {
                data: _vm.parts,
                size: "small",
                "show-header": false,
                "empty-text": "No parts found"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "fullPartNumber",
                  label: "Part number",
                  width: "120"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", { staticClass: "t-text-indigo-800" }, [
                            scope.row.fullPartNumber
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.fullPartNumber))
                                ])
                              : _c("span", [_vm._v(_vm._s(scope.row.part))])
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  190065416
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "trademark", label: "Trademark", width: "90" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "locationDescription",
                  label: "Warehouse",
                  width: "150"
                }
              }),
              _vm.user.admin === 1
                ? _c("el-table-column", {
                    attrs: {
                      prop: "locationCode",
                      label: "Warehouse ID",
                      width: "100"
                    }
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "Delivery" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return scope.row.deliveryDate && scope.row.deliveryTime
                          ? [
                              _c("i", { staticClass: "el-icon-truck" }),
                              _c("span", { staticClass: "t-ml-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDeliveryDate(
                                      scope.row.deliveryDate,
                                      scope.row.deliveryTime
                                    )
                                  )
                                )
                              ])
                            ]
                          : undefined
                      }
                    }
                  ],
                  null,
                  true
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "quantity", label: "Quantity", width: "80" }
              }),
              _c("el-table-column", {
                attrs: { label: "Price", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(" $" + _vm._s(scope.row.priceBuyer) + " ")
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2288889037
                )
              }),
              _c("el-table-column", {
                attrs: { align: "center", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass:
                                "t-cursor-pointer t-text-purple-600 t-px-2 t-pt-1 t-pb-0 t-border t-border-solid t-border-purple-400\n                 t-rounded-full t-bg-white hover:t-text-white hover:t-border-purple-600 hover:t-bg-purple-600",
                              on: {
                                click: function($event) {
                                  return _vm.add(scope.row)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-shopping-cart-2 t-text-base"
                              })
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1719935993
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("add-part-to-cart", {
        attrs: {
          visible: _vm.addPartToCartOpen,
          part: _vm.selectedPart,
          vendor: _vm.vendor,
          shopId: _vm.shopId,
          jobId: _vm.jobId,
          status: _vm.status,
          warehouse: _vm.warehouse
        },
        on: {
          "refresh-data": function($event) {
            return _vm.$emit("refresh-data")
          },
          close: function($event) {
            _vm.addPartToCartOpen = false
          },
          addAndLinkPart: function($event) {
            return _vm.addAndLinkPart($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }