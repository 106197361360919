import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import {
  CREATE_GB_VENDOR_MUTATION,
  UPDATE_GB_VENDOR_MUTATION
} from '@/apollo/mutation/gb-vendor.mutation'
import { apolloProvider } from '@/apollo'

export default class Vendor extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  name = ''
  address = ''
  city = ''
  state = ''
  zip = ''
  phone = ''
  fax = ''
  email = ''
  notes = ''
  modified = -1
  shop_id = -1
  color = '#000000'
  contact = ''
  national_vendor_id = null
  short_code = null
  last_deliver_to_id = null
  g_address = null

  phone_id = null // used for gb_vendor
  fax_id = null // used for gb_vendor
  email_id = null // used for gb_vendor

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static async getAllVendors (vendorService, shop,
    successCallbackFunction, errorCallbackFunction = null) {
    if (shop?.shop_services_config.gb_vendor) {
      const data = await vendorService.get(`organization/gb_supplier/${shop.organization_id}/details`)
      var converted = []
      for (var i = 0; i < data.data.length; i++) {
        converted.push(Vendor.getOldSupplierFromGbVendor(data.data[i]))
      }
      data.data = converted
      successCallbackFunction(data.data)
    } else {
      Api({url: `/api/shop/${shop.id}/getAllVendors` }).then(res => {
        successCallbackFunction(res.data)
      }, function (error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      })
    }
  }

  static async getVendorTable (vendorService, shop, page, limit, sortBy, sortDesc, statusFilter, nameFilter, addressFilter,
    cityFilter, stateFilter, zipFilter, phoneFilter, faxFilter, emailFilter, notesFilter,
    successCallbackFunction, errorCallbackFunction = null) {
    if (shop?.shop_services_config.gb_vendor) {
      var params = {
        page,
        limit,
        sortBy,
        sortDesc,
        statusFilter,
        nameFilter,
        addressFilter,
        cityFilter,
        stateFilter,
        zipFilter,
        phoneFilter,
        faxFilter,
        emailFilter,
        notesFilter
      }
      const { data } = await vendorService.get(`gb_supplier/${shop.organization_id}/table`, { params })
      var converted = []
      for (var i = 0; i < data.rows.length; i++) {
        converted.push(Vendor.getOldSupplierFromGbVendor(data.rows[i]))
      }
      data.rows = converted
      successCallbackFunction(data)
    } else {
      Api({
        url: `/api/shop/${shop.id}/vendors?page=` + encodeURIComponent(page) +
          '&limit=' + encodeURIComponent(limit) + '&sortBy=' + encodeURIComponent(sortBy) +
          '&sortDesc=' + encodeURIComponent(sortDesc) + '&statusFilter=' + encodeURIComponent(statusFilter) +
          '&nameFilter=' + encodeURIComponent(nameFilter) + '&addressFilter=' + encodeURIComponent(addressFilter) +
          '&cityFilter=' + encodeURIComponent(cityFilter) + '&stateFilter=' + encodeURIComponent(stateFilter) +
          '&zipFilter=' + encodeURIComponent(zipFilter) + '&phoneFilter=' + encodeURIComponent(phoneFilter) +
          '&faxFilter=' + encodeURIComponent(faxFilter) + '&emailFilter=' + encodeURIComponent(emailFilter) +
          '&notesFilter=' + encodeURIComponent(notesFilter)
      }).then(res => {
        successCallbackFunction(res.data)
      }, function (error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      })
    }
  }

  static getNationalWarehouseList (callbackFunction, errorCallbackFunction) {
    Api({url: '/api/warehouse/map'}).then(res => {
      callbackFunction(res.data)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getGbVendorFromOldSupplier(oldSupplier) {
    var gbVendorData = {
      __typename: 'GbVendor'
    }
    if (oldSupplier?.g_address?.google_place_id.length > 0) {
      gbVendorData.address = {
        __typename: 'GAddress',
        ...oldSupplier.g_address
      }
      gbVendorData.addresses = [{
        __typename: 'GAddress',
        ...oldSupplier.g_address
      }]
    }
    if (oldSupplier?.phone.length > 0) {
      gbVendorData.phone = {
        __typename: 'Phone',
        number: oldSupplier.phone,
        ...((oldSupplier.phone_id && oldSupplier.phone_id > 0) && {id: oldSupplier.phone_id})
      }
      gbVendorData.phones = [{
        __typename: 'Phone',
        number: oldSupplier.phone,
        ...((oldSupplier.phone_id && oldSupplier.phone_id > 0) && {id: oldSupplier.phone_id})
      }]
    }
    if (oldSupplier?.fax.length > 0) {
      gbVendorData.fax = {
        __typename: 'Phone',
        number: oldSupplier.fax,
        ...((oldSupplier.fax_id && oldSupplier.fax_id > 0) && {id: oldSupplier.fax_id})
      }
      gbVendorData.faxes = [{
        __typename: 'Phone',
        number: oldSupplier.fax,
        ...((oldSupplier.fax_id && oldSupplier.fax_id > 0) && {id: oldSupplier.fax_id})
      }]
    }
    if (oldSupplier?.email.length > 0) {
      gbVendorData.email = {
        __typename: 'Email',
        email: oldSupplier.email,
        ...((oldSupplier.email_id && oldSupplier.email_id > 0) && {id: oldSupplier.email_id})
      }
      gbVendorData.emails = [{
        __typename: 'Email',
        email: oldSupplier.email,
        ...((oldSupplier.email_id && oldSupplier.email_id > 0) && {id: oldSupplier.email_id})
      }]
    }
    gbVendorData.notes = oldSupplier.notes

    if (oldSupplier?.color) {
      if (typeof oldSupplier.color === 'string' || oldSupplier.color instanceof String) {
        gbVendorData.color = oldSupplier.color
      } else if (oldSupplier?.color?.hex) {
        gbVendorData.color = oldSupplier.color.hex
      }
    }
    gbVendorData.contact = oldSupplier.contact
    gbVendorData.national_vendor_id = oldSupplier.national_vendor_id
    gbVendorData.short_code = oldSupplier.short_code
    gbVendorData.is_supplier = 1
    gbVendorData.name = oldSupplier.name
    gbVendorData.is_disabled = oldSupplier.status === 'disabled' ? 1 : 0
    if (oldSupplier.id > 0) {
      gbVendorData.id = oldSupplier.id
    }

    return gbVendorData
  }

  static getOldSupplierFromGbVendor(gbVendor) {
    var oldSupplierData = {}
    if (gbVendor?.address?.google_place_id?.length > 0) {
      oldSupplierData.address = gbVendor?.address.address
      oldSupplierData.address_id = gbVendor?.address.id
      oldSupplierData.city = gbVendor?.address.city
      oldSupplierData.state = gbVendor?.address.state
      oldSupplierData.zip = gbVendor?.address.zip
      oldSupplierData.g_address = gbVendor?.address
    } else {
      oldSupplierData.address = ''
      oldSupplierData.address_id = -1
      oldSupplierData.city = ''
      oldSupplierData.state = ''
      oldSupplierData.zip = ''
    }

    oldSupplierData.color = gbVendor.color
    oldSupplierData.contact = gbVendor.contact
    oldSupplierData.created = gbVendor.created
    if (gbVendor?.email?.email.length > 0) {
      oldSupplierData.email = gbVendor.email.email
      oldSupplierData.email_id = gbVendor.email.id
    } else {
      oldSupplierData.email = ''
    }
    if (gbVendor?.fax?.number.length > 0) {
      oldSupplierData.fax = gbVendor.fax.number
      oldSupplierData.fax_id = gbVendor.fax.id
    } else {
      oldSupplierData.fax = ''
    }
    oldSupplierData.id = gbVendor.id
    oldSupplierData.last_deliver_to_id = gbVendor.last_deliver_to_id
    oldSupplierData.modified = gbVendor.modified
    oldSupplierData.name = gbVendor.name

    oldSupplierData.national_vendor_id = gbVendor.national_vendor_id
    oldSupplierData.notes = gbVendor.notes
    if (gbVendor?.phone?.number.length > 0) {
      oldSupplierData.phone = gbVendor.phone.number
      oldSupplierData.phone_id = gbVendor.phone.id
    } else {
      oldSupplierData.phone = ''
    }
    oldSupplierData.organization_id = gbVendor.organization_id
    oldSupplierData.short_code = gbVendor.short_code
    oldSupplierData.status = gbVendor.is_disabled ? 'disabled' : 'active'

    return oldSupplierData
  }

  async createUpdateVendor (shop, callbackFunction, errorCallbackFunction = null) {
    this.organization_id = shop.organization_id
    try {
      var gbVendorData = Vendor.getGbVendorFromOldSupplier(this)
      gbVendorData.organization_id = shop.organization_id
      const { data } = await apolloProvider.defaultClient.mutate({
        mutation: gbVendorData?.id
          ? UPDATE_GB_VENDOR_MUTATION
          : CREATE_GB_VENDOR_MUTATION,
        variables: {
          gb_vendor: gbVendorData,
          ...(gbVendorData?.id ? { gb_vendor_id: gbVendorData.id } : {})
        }
      })
      const { id } = data.createGbVendor || data.updateGbVendor
      gbVendorData.id = id
      var oldSupplierData = Vendor.getOldSupplierFromGbVendor(gbVendorData)

      // callbackFunction(oldSupplierData)
      return gbVendorData
    } catch (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    }
  }
}
