var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("p", { staticStyle: { "word-break": "normal" } }, [
        _vm._v(
          " * Please note, GlassBiller is currently compatible with the Square Terminal ONLY. The Square Reader, Square Register, and Square Stand are not supported yet. "
        )
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "125px", size: "mini" }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Select Device")]
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            "value-key": "square_device_id",
                            placeholder: "Select",
                            "no-data-text": "No unpaired devices"
                          },
                          model: {
                            value: _vm.form.device,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "device", $$v)
                            },
                            expression: "form.device"
                          }
                        },
                        _vm._l(_vm.options, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "t-mb-0" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "t-mb-2 t-mr-2",
                          attrs: {
                            type: "primary",
                            loading: _vm.loading,
                            disabled: !_vm.form.device
                          },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(" Add ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "t-ml-0",
                          attrs: { plain: "" },
                          on: { click: _vm.close }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 12 }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.newCodeDialogVisisble = true
                        }
                      }
                    },
                    [_vm._v("New Device")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("device-code-dialog", {
        attrs: { code: _vm.code, visible: _vm.codeDialogVisible },
        on: {
          closed: _vm.refetchDevices,
          "update:visible": function($event) {
            _vm.codeDialogVisible = $event
          }
        }
      }),
      _c("new-device-code-dialog", {
        attrs: {
          shopId: _vm.shopId,
          locationId: _vm.locationId,
          visible: _vm.newCodeDialogVisisble
        },
        on: {
          closed: _vm.reset,
          "update:visible": function($event) {
            _vm.newCodeDialogVisisble = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }