var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global-search-loading" },
    _vm._l(4, function(n) {
      return _c(
        "div",
        { key: n, staticClass: "global-search-loading-row" },
        [
          _c("skeleton"),
          _c("skeleton", { attrs: { size: "small", height: 21 } }),
          _c(
            "div",
            { style: { width: "60%" } },
            [_c("skeleton", { attrs: { size: "medium", height: 10 } })],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }