var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticClass: "t-w-1/4",
          attrs: { filterable: "", placeholder: "Select shop" },
          model: {
            value: _vm.shopId,
            callback: function($$v) {
              _vm.shopId = $$v
            },
            expression: "shopId"
          }
        },
        _vm._l(_vm.shops, function(i) {
          return _c("el-option", {
            key: i.id,
            attrs: { label: i.name, value: i.id }
          })
        }),
        1
      ),
      _c("el-checkbox", {
        staticClass: "t-ml-4 t-normal-case",
        attrs: { label: "Show archived ?", border: "" },
        model: {
          value: _vm.archived,
          callback: function($$v) {
            _vm.archived = $$v
          },
          expression: "archived"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }