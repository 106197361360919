var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "25px",
        height: "26px",
        viewBox: "0 0 25 26",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              x: "-39.5%",
              y: "-37.5%",
              width: "178.9%",
              height: "175.0%",
              filterUnits: "objectBoundingBox",
              id: "filter-1"
            }
          },
          [
            _c("feOffset", {
              attrs: {
                dx: "1",
                dy: "1",
                in: "SourceAlpha",
                result: "shadowOffsetOuter1"
              }
            }),
            _c("feGaussianBlur", {
              attrs: {
                stdDeviation: "1",
                in: "shadowOffsetOuter1",
                result: "shadowBlurOuter1"
              }
            }),
            _c("feColorMatrix", {
              attrs: {
                values:
                  "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.562581949 0",
                type: "matrix",
                in: "shadowBlurOuter1",
                result: "shadowMatrixOuter1"
              }
            }),
            _c(
              "feMerge",
              [
                _c("feMergeNode", { attrs: { in: "shadowMatrixOuter1" } }),
                _c("feMergeNode", { attrs: { in: "SourceGraphic" } })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Group-2",
                transform: "translate(2.000000, 2.000000)"
              }
            },
            [
              _c(
                "g",
                { attrs: { id: "play-solid", filter: "url(#filter-1)" } },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M17.0522609,7.96586985 L2.90900964,0.243722435 C1.75987047,-0.383400541 0,0.225168501 0,1.7762774 L0,17.2168614 C0,18.6084065 1.63531343,19.4470443 2.90900964,18.7494164 L17.0522609,11.0309798 C18.3139032,10.3444842 18.3179212,8.65236541 17.0522609,7.96586985 Z",
                      id: "Path",
                      stroke: "#FFF",
                      fill: _vm.fillColor,
                      "fill-rule": "nonzero",
                      "stroke-linecap": "round"
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }