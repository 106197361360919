<template>
  <el-dialog
    :visible="visible"
    :before-close="close"
    width="900px"
    custom-class="vendor-results-dialog"
    append-to-body
  >
    <span slot="title" class="t-text-xl t-text-gray-800">
      Pilkington results
    </span>
    <div class="t-mt-2 t-p-5 t-shadow t-bg-green-200">
      <div class="t-flex">
        <div class="t-flex-1 t-text-gray-800">
          <div class="t-text-base">
            Part
            <span class="t-text-indigo-800 t-font-medium">
              {{ nagsPart.detail.partno }}
            </span>
          </div>
          <div class="t-text-085">{{ nagsPart.detail.dsc }}</div>
        </div>
        <el-tooltip
          v-if="!warehouse"
          class="item"
          effect="dark"
          content="Add to parts list"
          placement="top"
        >
          <button
            v-if="
              returnedPart &&
              !returnedPart.invalidMaterial &&
              returnedPart.unitPrice > 0 &&
              priceFromLevels &&
              priceLevelSelected
            "
            class="t-border-none t-rounded-full hover:t-bg-purple-600
                  hover:t-text-white t-bg-white t-cursor-pointer
                  t-text-purple-700 t-text-base t-font-medium t-self-center
                  t-px-3 t-py-1"
            @click="addPart"
          >
            ${{ priceFromLevels }}
          </button>
        </el-tooltip>
      </div>
    </div>
    <div class="t-p-5">
      <div class="t-flex t-items-center">
        <div class="t-flex-grow">Total {{ results.length }} results</div>
        <div v-if="credentials.length > 1">
          <el-select
            v-model="accountNumber"
            class="t-ml-4"
            placeholder="Select account"
            size="mini"
          >
            <el-option
              v-for="item in credentials"
              :key="item.id"
              :label="
                item.username + (item.name ? ' - ' + item.name : '')
              "
              :value="item.username"
            />
          </el-select>
        </div>
        <div v-if="warehouseId">
          <el-select
            v-model="warehouseId"
            class="t-ml-4"
            placeholder="Select warehouse"
            size="mini"
          >
            <el-option
              v-for="item in warehouses"
              :key="item.warehouseId"
              :label="item.warehouse"
              :value="item.warehouseId"
            />
          </el-select>
        </div>
        <div v-else>
          <el-select
            v-model="accountDefault"
            class="t-ml-4"
            placeholder="Select warehouse"
            size="mini"
          >
            <el-option
              key="1"
              label="Account Default"
              value="Account Default"
            />
          </el-select>
        </div>
      </div>
      <div v-if="loading" v-loading="true" class="t-h-32"></div>
      <div v-else-if="Object.keys(parts).length > 0">
        <div v-for="(pts, plantName) in parts" :key="plantName">
          <el-divider>
            <span class="t-text-purple-700">{{ plantName }}</span>
          </el-divider>
          <results-table
            :parts="pts"
            :shopId="shopId"
            :jobId="jobId"
            :status="status"
            :warehouse="warehouse"
            :username="vendorCredential.username"
            @refresh-data="$emit('refresh-data')"
            @addAndLinkPart="addAndLinkPart($event)"
          />
        </div>
      </div>
      <p v-else class="t-text-center t-mt-3">No parts found</p>
    </div>
  </el-dialog>
</template>

<script>
import * as R from 'ramda'
import ResultsTable from './Table'
import Api from '@/services/Api'

export default {
  props: [
    'results',
    'visible',
    'loading',
    'nagsPart',
    'priceFromLevels',
    'shopId',
    'jobId',
    'standalone',
    'defaultWarehouseId',
    'defaultCredentials',
    'warehouse',
    'status'
  ],
  data: () => ({
    warehouseId: null,
    accountNumber: null,
    allWarehouses: [],
    allCredentials: [],
    accountDefault: 'Account Default',
    vendorCredential: {}
  }),
  computed: {
    returnedPart() {
      return this.results[0]
    },
    parts() {
      return R.groupBy(part => part.plantName, this.results)
    },
    warehouses() {
      if (this.standalone) {
        return this.allWarehouses
      } else {
        return this.$store.state.partVendors.pilkingtonWarehouses
      }
    },
    priceLevelSelected() {
      const pl = this.$job.job.selected_price_level
      return pl && pl > 0
    },
    credentials() {
      if (this.standalone) {
        if (this.allCredentials.length) {
          return this.allCredentials.filter(i => i.vendor === 'pilkington')
        }
      } else {
        if (this.$store.state.partVendors.credentials.length) {
          return this.$store.state.partVendors.credentials.filter(
            i => i.vendor === 'pilkington'
          )
        }
      }
      return []
    }
  },
  watch: {
    warehouseId: function(newVal, oldVal) {
      if (oldVal !== null) {
        this.recheckVendor()
      }
    },
    accountNumber: async function(newVal, oldVal) {
      if (oldVal !== null) {
        await this.getShopVendorWarehouses()
      }
    }
  },
  created() {
    if (this.standalone) {
      this.accountNumber = this.defaultCredentials
      this.warehouseId = this.defaultWarehouseId
      this.standaloneCreated(true)
    }
  },
  methods: {
    async standaloneCreated(skipDefault = false) {
      await this.getCredentials()
      await this.getShopVendorWarehouses(skipDefault)
    },
    close() {
      if (this.standalone) {
        this.$emit('close')
      } else {
        this.$store.commit('partVendors/setSearchResultsVisible', {
          vendor: 'pilkington',
          partNumber: this.nagsPart.detail.partno,
          visible: false
        })
      }
    },
    addPart() {
      if (this.standalone) {
        this.$emit('add')
      } else {
        const tempPart = Object.assign({}, this.nagsPart)
        this.$emit(
          'addPart',
          tempPart,
          tempPart.children,
          'pilkington',
          undefined,
          undefined,
          true
        )
      }
    },
    addAndLinkPart(cartItemId) {
      if (this.standalone) {
        this.$emit('addPart', {
          vendor: 'pilkington',
          cartItemId
        })
      } else {
        this.$emit('addPart', {
          vendor: 'pilkington',
          cartItemId
        })
      }
    },
    recheckVendor() {
      this.$emit('recheckVendor', {
        vendor: 'pilkington',
        username: this.vendorCredential.username,
        accountNumber: this.accountNumber,
        warehouseId: this.warehouseId
      })
    },
    async getCredentials() {
      const { data } = await Api.get(`/api/shop/${this.shopId}/getVendorCredentials`)
      this.allCredentials = data
    },
    async getShopVendorWarehouses(skipDefault = false) {
      const selectedCredentials = this.allCredentials.filter(
        c => c.username === this.accountNumber
      )

      this.vendorCredential = selectedCredentials[0]

      const { data } = await Api.post('/api/shopVendorWarehouses', {
        vendorCredentialId: this.vendorCredential.id,
        vendor: 'pilkington'
      })

      this.allWarehouses = data

      if (!skipDefault) {
        this.selectDefaultWarehouse()
      }
    },
    selectDefaultWarehouse() {
      let recheck = false

      if (this.warehouseId === null) {
        recheck = true
      }

      if (this.allWarehouses.length === 0) {
        this.warehouseId = null
      }

      for (const warehouse of this.allWarehouses) {
        if (warehouse.default) {
          if (this.warehouseId === warehouse.warehouseId) {
            recheck = true
          } else {
            this.warehouseId = warehouse.warehouseId
          }

          break
        }
      }

      if (recheck) {
        this.recheckVendor()
      }
    }
  },
  components: {
    ResultsTable
  }
}
</script>

<style lang="scss">
.vendor-results-dialog {
  @apply t-font-sans;
  .el-dialog__body {
    @apply t-p-0;
  }
}
</style>
