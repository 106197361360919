import { render, staticRenderFns } from "./VehicleCard.vue?vue&type=template&id=643d8a89&scoped=true&"
import script from "./VehicleCard.vue?vue&type=script&lang=js&"
export * from "./VehicleCard.vue?vue&type=script&lang=js&"
import style0 from "./VehicleCard.vue?vue&type=style&index=0&id=643d8a89&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "643d8a89",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('643d8a89')) {
      api.createRecord('643d8a89', component.options)
    } else {
      api.reload('643d8a89', component.options)
    }
    module.hot.accept("./VehicleCard.vue?vue&type=template&id=643d8a89&scoped=true&", function () {
      api.rerender('643d8a89', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/modals/GlaxisDialogs/claim/components/VehicleCard.vue"
export default component.exports