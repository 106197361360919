<template>
  <el-table
    ref="table"
    stripe
    v-loading="fetching"
    :data="leads"
    row-class-name="t-cursor-pointer"
    @row-click="rowClicked"
  >
    <el-table-column type="expand">
      <template slot-scope="scope">
        <div class="t-flex">
          <div class="t-pr-3 t-font-bold t-w-32 t-text-right">
            Email:
          </div>
          <div>{{ scope.row.email }}</div>
        </div>
        <div class="t-flex">
          <div class="t-pr-3 t-font-bold t-w-32 t-text-right">
            Vin:
          </div>
          <div>{{ scope.row.vin }}</div>
        </div>
        <div class="t-flex">
          <div class="t-pr-3 t-font-bold t-w-32 t-text-right">
            Vehicle:
          </div>
          <div>
            {{ scope.row.year }}
            {{ scope.row.make }}
            {{ scope.row.model }}
            {{ scope.row.style }}
          </div>
        </div>
        <div class="t-flex">
          <div class="t-pr-3 t-font-bold t-w-32 t-text-right">
            Opening:
          </div>
          <div>{{ scope.row.opening }}</div>
        </div>
        <div class="t-flex">
          <div class="t-pr-3 t-font-bold t-w-32 t-text-right">
            Referral:
          </div>
          <div>{{ scope.row.referral }}</div>
        </div>
        <div class="t-flex">
          <div class="t-pr-3 t-font-bold t-w-32 t-text-right">
            Address:
          </div>
          <div>{{ scope.row.address }}</div>
        </div>
        <div class="t-flex">
          <div class="t-pr-3 t-font-bold t-w-32 t-text-right">
            Description:
          </div>
          <div>{{ scope.row.description }}</div>
        </div>
        <div class="t-flex t-mt-2">
          <div class="t-w-32"></div>
          <div>
            <el-button
              v-for="i in scope.row.attachments"
              @click="download(i.url)"
              :key="i.id"
              type="primary"
              size="mini"
              plain
            >
              {{ i.name }}<i class="el-icon-download el-icon-right t-ml-2"/>
            </el-button>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="" width="40px">
      <template slot-scope="scope">
        <i v-if="scope.row.attachmentsCount" class="el-icon-paperclip"/>
      </template>
    </el-table-column>
    <el-table-column label="Name">
      <template slot-scope="scope">
        {{ scope.row.firstName }} {{ scope.row.lastName }}
      </template>
    </el-table-column>
    <el-table-column label="Phone number" width="150px">
      <template slot-scope="scope">
        {{ formatPhone(scope.row.phone) }}
      </template>
    </el-table-column>
    <el-table-column
      label="Vehicle">
      <template slot-scope="scope">
        <div v-if="scope.row.year > 0" class="t-break-normal">
          {{ scope.row.year }}
          {{ scope.row.make }}
          {{ scope.row.model }}
          {{ scope.row.style }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Created" width="140px">
      <template slot-scope="scope">
        {{ formatCreated(scope.row.createdAt) }}
      </template>
    </el-table-column>
    <el-table-column label="Job id" width="100px">
      <template slot-scope="scope">
        <a
          :href="`/jobs/${scope.row.jobId}`"
          target="_blank">
          {{ scope.row.jobId }}
        </a>
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="Actions" width="200px">
      <template slot-scope="scope">
        <el-tooltip
          v-if="!scope.row.jobId"
          content="Start job"
          placement="top"
          :open-delay="300"
        >
          <el-button
            :loading="startJobSubmitting"
            size="mini"
            type="primary"
            icon="el-icon-video-play"
            @click.stop="handleStartJob(scope.row.id)"
          />
        </el-tooltip>
        <el-tooltip
          v-if="!scope.row.archived"
          content="Archive"
          placement="top"
          :open-delay="300"
        >
          <el-button
            :loading="archiveSubmitting"
            size="mini"
            type="warning"
            icon="el-icon-takeaway-box"
            @click.stop="handleArchive(scope.row.id)"
          />
        </el-tooltip>
        <el-tooltip
          v-else
          content="Unarchive"
          placement="top"
          :open-delay="300"
        >
          <el-button
            :loading="unarchiveSubmitting"
            plain
            size="mini"
            type="warning"
            icon="el-icon-takeaway-box"
            @click.stop="handleUnarchive(scope.row.id)"
          />
        </el-tooltip>
        <el-tooltip
          content="Delete"
          placement="top"
          :open-delay="300"
        >
          <el-button
            :loading="deleteSubmitting"
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click.stop="handleDelete(scope.row.id)"
          />
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import moment from 'moment'
import { leadRepository } from '@/scripts/repositories/lead'

export default {
  data: () => ({
    archiveSubmitting: false,
    unarchiveSubmitting: false,
    deleteSubmitting: false,
    startJobSubmitting: false
  }),
  computed: {
    leads: function () {
      return this.$store.state.leads.data
    },
    fetching: function () {
      return this.$store.state.leads.fetching
    }
  },
  methods: {
    formatPhone: i => `(${i.substring(0, 3)}) ${i.substring(3, 6)}-${i.substring(6)}`,
    formatCreated: dt => moment(dt).format('D MMM h:mm a'),
    setArchived: function (payload) {
      this.$store.dispatch('leads/setArchived', payload)
    },
    remove: function (payload) {
      this.$store.dispatch('leads/remove', payload)
    },
    handleStartJob: async function (id) {
      try {
        await this.$messageBox.confirm(
          'Do you wish to start a job ?',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }
        )
        this.startJobSubmitting = true
        // const { id: jobId } = await this.$store.dispatch('leads/startJob', { id })
        const {data} = await this.$unum.job().post('/lead', {id})
        this.$store.dispatch('triggerGoToPage', `/jobs/${data.id}`)
        this.$root.$emit('bv::hide::modal', 'mobileInboxModal')
        this.startJobSubmitting = false
      } catch (e) {}
    },
    handleArchive: async function (id) {
      this.archiveSubmitting = true
      await this.setArchived({ id, value: 1 })
      this.archiveSubmitting = false
      this.$message.info('Lead was archived')
    },
    handleUnarchive: async function (id) {
      this.unarchiveSubmitting = true
      await this.setArchived({ id, value: 0 })
      this.unarchiveSubmitting = false
      this.$message.info('Lead was unarchived')
    },
    handleDelete: async function (id) {
      try {
        await this.$messageBox.confirm(
          'This will permanently remove this lead. Continue ?',
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'error'
          }
        )
        this.deleteSubmitting = true
        await this.remove({ id })
        this.deleteSubmitting = false
        this.$message.info('Lead was removed')
      } catch (e) {}
    },
    rowClicked: async function(row) {
      row.attachments = await leadRepository.getAttachments(row.id)
      this.$refs.table.toggleRowExpansion(row)
    },
    download: function(url) {
      window.open(url)
    }
  }
}
</script>
