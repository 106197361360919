import Vue from 'vue'
import { GbAuthPlugin } from '@glassbiller/gb-oauth-client'
import { GbAuditPlugin } from '@glassbiller/gb-audit-client'
import cfg from '@/config'

export const skipRoutes = ['signature_capture', 'gb_form', 'paymentPortal', 'newclient']

Vue.use(GbAuthPlugin, {
  tenant: cfg.oauth.tenant,
  skipRoutes,
  jwtExpireBuffer: 10000,
  lockTimeout: 1500
})

Vue.use(GbAuditPlugin, {
  url: cfg.wsUri
})

window.auth = Vue.prototype.$auth
