var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        width: "900px",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        title: _vm.settings.name + " Confirmation Settings"
      }
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "0" } },
        [
          _c("confirmation-settings", {
            ref: "confirmationSettingsForm",
            attrs: { settings: _vm.localSettings, shopId: _vm.shopId },
            on: {
              modifySettings: _vm.modifySettings,
              confirmationSettingsInvalid: _vm.alertSettingsInvalid
            }
          })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("Cancel")
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("Save")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }