<template>
  <div
    class="data-row"
    :class="{
      link,
      overflow,
      'no-margin': noMargin,
      bool,
      locked,
      condensed,
    }"
    @click="checkBool"
  >
    <div class="data-row-container">
      <div :style="{ flexGrow: +(!label || type === 'textarea') }">
        <div
          class="data-row-label"
          v-if="label"
          :class="{ locked, valid, invalid }"
        >
          <i class="material-icons md-18 md-lock lock-icon" v-if="locked" />
          <span :class="{emptyRed}">{{ label }} </span>
          <el-tooltip v-if="tooltip && tooltip.length > 0" placement="top" popper-class="dataRowToolTip">
            <template #content> {{tooltip}} </template>
            <i class="el-icon-info t-text-xs"></i>
          </el-tooltip>
          <el-popover
            v-if="$attrs.warning && $attrs.id === 'referral_number'"
            placement="top-start"
            :title="label"
            width="400"
            style="pointer-events: auto;"
            trigger="hover">
            <div>
              <p>This referral number appears on an existing job or draft.</p>
              <el-button size="mini" type="text" @click="dismissReferralMatch">Dismiss</el-button>
              <el-button type="primary" size="mini" @click="viewReferralMatches">View</el-button>
            </div>
            <v-icon 
              name="exclamation-circle"
              scale="0.6"
              style="margin: 0px 2px"
              color="orange"
              slot="reference"
            />
          </el-popover>
          <v-icon
            v-else-if="$attrs.warning"
            name="exclamation-circle"
            scale="0.6"
            style="margin: 0px 2px"
            color="orange"
            slot="reference"
          />
          <i
            class="material-icons md-18 md-new_releases label-icon"
            v-if="invalid"
          />
          <i
            class="material-icons md-18 md-check_circle label-icon"
            v-if="valid"
          />

        </div>
        <div
          class="data-row-value"
          :tabindex="editable ? 0 : -1"
          @focus="$emit('focus')"
        >
          <skeleton v-if="loading || creating" />
          <span v-else-if="isArray && value.length === 0"> - </span>
          <template v-else-if="isArray">
            <div>
              <data-row-formatted-value
                v-for="(item, idx) in value"
                v-bind="$props"
                :key="item.id"
                :value="arrayKey ? item[arrayKey] : item"
                v-show="expanded || idx < 2"
              />
              <span
                @click="expanded = !expanded"
                class="data-row-more-button"
                v-if="value.length > 2"
              >
                {{ expanded ? "Less" : "More" }}
              </span>
            </div>
          </template>
          <div v-else-if="type === 'bool'" class="data-row-bool-value">
            <i
              class="material-icons-outline md-18"
              :class="{
                'md-check_box_outline_blank': !value,
                'md-check_box': value,
              }"
            />
            {{ value ? "Yes" : "No" }}
          </div>
          <el-tooltip
            placement="top"
            v-else
            :tabindex="overflow ? 0 : -1"
            :disabled="!overflow"
            :open-delay="750"
          >
            <data-row-formatted-value v-bind="$props" slot="content" />
            <data-row-formatted-value v-bind="$props" ref="actual" />
          </el-tooltip>
          <slot name="extra" />
        </div>
      </div>
    </div>
    <match-dialog 
      :visible.sync="matchDialogVisible"
      :match="{ key: 'referral_number', value }"
    />
  </div>
</template>

<script>
import Skeleton from './Skeleton'
import DataRowFormattedValue from './DataRowFormattedValue'
import MatchDialog from '@/components/modals/MatchDialog/Index.vue'

export default {
  components: { Skeleton, DataRowFormattedValue, MatchDialog },
  props: [
    'label',
    'value',
    'type',
    'render',
    'loading',
    'select',
    'edit',
    'link',
    'suffix',
    'prefix',
    'editable',
    'isArray',
    'arrayKey',
    'overflow',
    'noMargin',
    'bool',
    'pre',
    'locked',
    'condensed',
    'valid',
    'invalid',
    'clearable',
    'tooltip',
    'redIfEmpty'
  ],
  data() {
    return {
      adding: false,
      newValue: {},
      creating: false,
      sfocused: false,
      expanded: false,
      matchDialogVisible: false
    }
  },

  methods: {
    async onFocusOut(v) {
      if (this.select.callback) {
        this.creating = true
        this.adding = false
        await this.select.callback(v)
        this.creating = false
        this.newValue = {}
      }
    },
    onClickAway() {
      this.creating = false
      this.adding = false
      this.newValue = {}
    },
    checkBool() {
      if (this.bool?.editable) {
        this.$emit('edit', {
          key: this.$attrs.id,
          value: this.bool?.numeric ? +!this.value : !this.value
        })
      }
    },
    dismissReferralMatch() {
      this.$job.updateJob(
        { referral_match: 0 },
        {label: 'Refferal match set to', value: 'off'}
      )
    },
    viewReferralMatches() {
      this.matchDialogVisible = true
    }
  },
  computed: {
    emptyRed () {
      return this.redIfEmpty && (!this.value || (this.value + '').length === 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.data-row {
  position: relative;

  &.locked {
    pointer-events: none;
  }
  &.link {
    .data-row-value {
      color: $primary;
      font-weight: 700;
      line-height: 17px;
    }
  }
  &.overflow {
    .data-row-container > * {
      width: 100%;
    }
    .data-row-value {
      > * {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .data-row-label {
    /* white-space: nowrap; */
    z-index: 10;
    font-size: $font-xs;
    text-transform: uppercase;
    color: #999;
    letter-spacing: 0.1em;
    line-height: 12px;
    margin-bottom: $padding-xxs;
    display: flex;
    align-items: center;
    &.locked {
      /* color: $watermelon-pink; */
    }
    &.invalid {
      color: $crimson-red;
    }
    &.valid {
      color: $jade-green;
    }
    .lock-icon {
      color: $watermelon-pink;
      font-size: 12px;
      line-height: 12px;
      margin-right: 2px;
      margin-left: -1px;
    }
    .label-icon {
      font-size: 12px;
      line-height: 12px;
      margin-left: 2px;
    }
  }
  .data-row-container {
    display: flex;
    align-items: center;
  }
  .data-row-value {
    font-size: $font-md;
    margin-bottom: $padding-md;
    color: #272727;
    display: flex;
    align-items: center;
    position: relative;
  }
  &.condensed {
    .data-row-label {
      margin-bottom: 0;
    }
    .data-row-value {
      font-size: $font-sm;
      margin-bottom: 0;
    }
  }
  &.no-margin {
    .data-row-value {
      margin-bottom: 0;
    }
  }
  .select-button {
    &.rotated {
      transform: rotate(180deg);
    }
  }
  .select-bg {
    position: absolute;
    height: 0;
    left: 0;
    bottom: 30px;
    overflow: hidden;
    width: 100%;
    .el-input__inner {
      height: 38px;
      background: none;
      border: none;
    }
  }
  .data-row-more-button {
    color: $primary;
    cursor: pointer;
    font-size: 12px;
  }
  &.bool {
    cursor: pointer;
  }
  .data-row-bool-value {
    display: flex;
    align-items: center;
    i {
      margin-right: $padding-xxs;
      color: $grayscale-8;
    }
  }
}
</style>

<style lang="scss">
.data-row {
  .select-bg {
  }
}

.dataRowToolTip {
  width:200px;
}

.emptyRed {
  color:red;
}

</style>
