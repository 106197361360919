var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-pt-1" },
    [
      _c("div", { staticClass: "t-flex t-mb-2" }, [
        _c("div", { staticClass: "t-mr-8 t-flex" }, [
          _c("div", { staticClass: "t-mr-2" }, [_vm._v("Uncleared :")]),
          _c("div", [_vm._v(_vm._s(_vm.total - _vm.cleared))])
        ]),
        _c("div", { staticClass: "t-flex" }, [
          _c("div", { staticClass: "t-mr-2" }, [_vm._v("Total :")]),
          _c("div", [_vm._v(_vm._s(_vm.total))])
        ])
      ]),
      _c(
        "el-button",
        {
          attrs: { loading: _vm.loading, size: "mini" },
          on: { click: _vm.decode }
        },
        [_vm._v(" Retry decoding VINs with multiple results ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }