var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.tags, function(tag) {
        return _c(
          "el-tag",
          {
            key: tag.id,
            staticClass: "job-details-tags",
            style: { color: _vm.getBlackOrWhite(tag.color) },
            attrs: { color: tag.color, size: "small", closable: !_vm.disabled },
            on: {
              close: function($event) {
                return _vm.handleClose(tag)
              }
            }
          },
          [_vm._v(" " + _vm._s(tag.text) + " ")]
        )
      }),
      (_vm.shopTags ||
        (_vm.propJob && _vm.propJob.shop && _vm.propJob.shop.tags)) &&
      !_vm.disabled
        ? _c(
            "el-dropdown",
            { on: { command: _vm.addTag } },
            [
              _c(
                "el-button",
                { staticClass: "tag-button", attrs: { size: "mini" } },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" ADD")]
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "tag-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                _vm._l(_vm.dropdownTags, function(tag) {
                  return _c(
                    "el-dropdown-item",
                    { key: tag.id, attrs: { command: tag } },
                    [
                      _c(
                        "el-tag",
                        {
                          staticClass: "job-details-tags",
                          attrs: { color: tag.color, size: "small" }
                        },
                        [
                          _c(
                            "span",
                            {
                              style: { color: _vm.getBlackOrWhite(tag.color) }
                            },
                            [_vm._v(" " + _vm._s(tag.text) + " ")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }