var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "document-tile" },
    [
      _c("i", { staticClass: "material-icons-outline md-insert_drive_file" }),
      _c("span", [_vm._v("Documents")]),
      _vm.documents
        ? _c("div", {
            staticClass: "job-documents",
            class: { expanded: _vm.expanded }
          })
        : _vm._e(),
      _c(
        "gb-dropdown-menu",
        {
          attrs: {
            multiselect: false,
            options: _vm.options,
            "keep-open": false
          },
          on: { select: _vm.addNew },
          scopedSlots: _vm._u([
            {
              key: "menu-option",
              fn: function(item) {
                return _c("div", { staticClass: "document-menu-item" }, [
                  _c("i", {
                    staticClass: "material-icons md-fiber_manual_record",
                    style: { color: item.color }
                  }),
                  _c("span", [_vm._v(" " + _vm._s(item.name) + " ")])
                ])
              }
            }
          ])
        },
        [
          _c("md-circle-button", {
            ref: "addButton",
            staticClass: "add-icon",
            attrs: { slot: "trigger", icon: "add_circle", primary: "" },
            slot: "trigger"
          }),
          _c("div", { attrs: { slot: "menu-header" }, slot: "menu-header" }, [
            _vm._v("Select document type to upload")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "document-list t-flex" },
        _vm._l(_vm.numberedDocs, function(doc) {
          return _c("list-item", {
            key: doc.id,
            attrs: { doc: doc },
            nativeOn: {
              click: function($event) {
                return _vm.openPreview(doc)
              }
            }
          })
        }),
        1
      ),
      _c("filestack-picker", {
        attrs: {
          "doc-type": _vm.adding && _vm.adding.code,
          "doc-label": _vm.adding && _vm.adding.name
        },
        on: {
          uploaded: _vm.onUpload,
          closed: function($event) {
            return _vm.$emit("clear-files")
          }
        },
        model: {
          value: _vm.filepicker,
          callback: function($$v) {
            _vm.filepicker = $$v
          },
          expression: "filepicker"
        }
      }),
      _c("file-preview-dialog", {
        attrs: {
          doc: _vm.preview,
          dateFunc: _vm.dateFunc,
          deleteFunc: _vm.deleteFunc
        },
        model: {
          value: _vm.previewDialog,
          callback: function($$v) {
            _vm.previewDialog = $$v
          },
          expression: "previewDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }