import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import JobCalculations from '@/scripts/objects/jobCalculations'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import $ from 'jquery'
import moment from 'moment'
import LegacyApi from '@/services/LegacyApi'

export default class Job extends ObjectTracker {
  id = 'new'
  created = -1
  status = ''
  scheduled_date = ''
  scheduled_time_start = ''
  scheduled_time_end = ''
  completed_date = ''
  contact_name = ''
  contact_phone1 = ''
  contact_phone2 = ''
  install_g_address = null
  install_major_street1 = ''
  install_major_street2 = ''
  install_notes = ''
  install_context = 'mobile'
  loss_date = ''
  work_order_date = ''
  dispatch_date = ''
  install_date = ''
  invoice_date = ''
  quote_date = ''
  warranty_date = ''
  credit_memo_date = ''
  warranty_complete_date = ''
  deductible = '0'
  referral_number = ''
  policy_number = ''
  requisition_order_number = ''
  purchase_order_number = ''
  customer_rebate = '0'
  other_costs = '0'
  insurance_fleet_name = ''
  insurance_fleet_address_line1 = null
  insurance_fleet_address_line2 = null
  insurance_fleet_address_city = ''
  insurance_fleet_address_state = ''
  insurance_fleet_address_zip = ''
  insurance_fleet_fax = ''
  insurance_fleet_email = ''
  insurance_fleet_notes = ''
  insurance_fleet_tax_exempt = 0
  insurance_fleet_auto_add_adhesive = 0
  insurance_fleet_po_required = 0
  insurance_fleet_discount = '0'
  insurance_fleet_oem_discount = '0'
  insurance_fleet_labor_flat = '0'
  insurance_fleet_labor_hourly = '0'
  insurance_fleet_kit_charge_type = null
  insurance_fleet_kit_standard = '0'
  insurance_fleet_kit_high = '0'
  insurance_fleet_kit_fast = '0'
  insurance_fleet_molding = null
  insurance_fleet_chip_first = '0'
  insurance_fleet_chip_additional = '0'
  vehicle_vin = ''
  vehicle_vin_is_valid = 0
  vehicle_description = ''
  vehicle_year = '0'
  vehicle_make = '0'
  vehicle_model = '0'
  vehicle_sub_model = '0'
  vehicle_style = '0'
  vehicle_plate_number = ''
  customer_type = 'individual'
  customer_id = -1
  customervehicle_id = -1
  customer_first_name = ''
  customer_middle_name = ''
  customer_last_name = ''
  customer_company_name = ''
  customer_phone1 = ''
  customer_phone2 = ''
  customer_email = ''
  customer_g_address = null
  modified = -1
  shop_id = -1
  csr_id = -1
  salesrep_id = -1
  salesource_id = -1
  tech_id = -1
  insurancefleet_id = ''
  insurance_fleet_address = ''
  insurance_fleet_phone = ''
  insurance_fleet_web = ''
  insurance_fleet_kit_charge_type_standard = 'each'
  insurance_fleet_kit_charge_type_high = 'each'
  insurance_fleet_kit_charge_type_fast = 'each'
  insurance_fleet_moulding_discount = 0
  edi_locked = 0
  salesrep = null
  csr = null
  tech = null
  salesource = null
  insurancefleet = null
  total_list_price = 0
  total_materials = 0
  total_hours = 0
  total_labor = 0
  total_subtotal = 0
  total_cost = 0
  total_materials_taxes = 0
  total_labor_taxes = 0
  total_taxes = 0
  total_after_taxes = 0
  total_after_deductible = 0
  total_margin = 0
  edi_id = null
  manual_vehicle_entry = 0
  vehicle_year_manual = 0
  vehicle_make_manual = ''
  vehicle_model_manual = ''
  vehicle_sub_model_manual = ''
  vehicle_style_manual = ''
  follow_up_date = ''
  vehicle_mileage = ''
  vehicle_plate_number = ''
  commercialaccount_id = -1
  commercialaccount = null
  contact_signature = ''
  techside_job_status = null
  internal_number = ''
  unit_number = ''
  techside_job_completed = null
  technician_notes = ''
  salesrep_notes = ''
  location_id = -1
  location = null
  sales_rep_commission = 0
  sale_source_commission = 0
  tech_commission = 0
  location_commission = 0
  csr_commission = 0
  sales_rep_commission_flat_or_percent = 'flat'
  sale_source_commission_flat_or_percent = 'flat'
  tech_commission_flat_or_percent = 'flat'
  location_commission_flat_or_percent = 'flat'
  csr_commission_flat_or_percent = 'flat'
  total_sale_source_commission = 0
  total_sales_rep_commission = 0
  total_tech_commission = 0
  total_location_commission = 0
  total_csr_commission = 0
  total_commission = 0
  total_balance_after_payments = 0
  total_deductible_after_payments = 0
  total_balance_adjustable_payments = 0
  dot_number = ''
  lot_number = ''
  selected_price_level = 0
  selected_invoice_tier = 'dor'
  invoice_due_date = ''
  pending_inspection = 0
  pending_schedule = 0
  parts_on_backorder = 0
  scheduler_order = null
  techside_sent = ''
  is_duplicate = 0
  is_warranty = 0
  is_supplemental = 0
  is_credit_memo = 0
  base_job = 0
  stock_number = ''
  customer_cancel = 0
  customer_reschedule = 0
  review_sent = 0
  customer_invoice_message = null
  customer_workorder_message = null
  customer_quote_message = null
  salesidejob_id = null
  pending_offline = 0
  install_location_id = null
  _base_job_status = null
  _jobCalculations = null
  _child_jobs = []
  accounts_receivable_id = -1
  bay_id = null
  insurance_fleet_recal_safelite_dynamic = 0
  insurance_fleet_recal_safelite_static = 0
  insurance_fleet_recal_safelite_dual = 0
  insurance_fleet_recal_lynx_rtl_dynamic = 0
  insurance_fleet_recal_lynx_rtl_static = 0
  insurance_fleet_recal_lynx_rtl_both = 0
  insurance_fleet_recal_lynx_dlr_dynamic = 0
  insurance_fleet_recal_lynx_dlr_static = 0
  insurance_fleet_recal_lynx_dlr_both = 0
  cause_of_loss_id = null
  install_time_span_id = null
  consumer_id = null
  confirmation_date_time = null

  constructor (initialObject = {}, parentComponent) {
    super()
    super.setInitial(initialObject)
    this._jobCalculations = new JobCalculations(this, parentComponent)

    try {
      this.vehicle_year = parseInt(this.vehicle_year)
    } catch (err) {}
    try {
      this.vehicle_make = parseInt(this.vehicle_make)
    } catch (err) {}
    try {
      this.vehicle_model = parseInt(this.vehicle_model)
    } catch (err) {}
    try {
      this.vehicle_sub_model = parseInt(this.vehicle_sub_model)
    } catch (err) {}
    try {
      this.vehicle_style = parseInt(this.vehicle_style)
    } catch (err) {}
  }

  static get (shopId, jobId, parentComponent, callbackFunction, errorCallbackFunction = null, justEdiLocked = false) {
    Api({ url: `/api/shop/${shopId}/job/${jobId}` + (justEdiLocked ? '?justEdiLocked=true' : '') }).then(res => {
      if (justEdiLocked) {
        callbackFunction(res.data.edi_locked)
      } else {
        callbackFunction(new Job(res.data, parentComponent))
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getFullJob (shopId, jobId, callbackFunction, errorCallbackFunction = null) {
    Api({ url: `/api/shop/${shopId}/fullJob/${jobId}` }).then(res => {
      res.data.job._child_jobs = res.data.childJobs
      res.data.job._base_job_status = res.data.job.base_status

      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getBaseJob (shopId, baseJobId, newVehicle, copyType, callbackFunction, errorCallbackFunction = null) {
    Api({ url: `/api/shop/${shopId}/fullJob/${baseJobId}` }).then(res => {
      var tempJob = new Job(res.data.job)
      if (newVehicle === 'true') {
        tempJob.vehicle_vin = ''
        tempJob.vehicle_plate_number = ''
        tempJob.unit_number = ''
        tempJob.manual_vehicle_entry = 0
        tempJob.vehicle_description = ''
        tempJob.vehicle_make = '0'
        tempJob.vehicle_make_manual = ''
        tempJob.vehicle_mileage = ''
        tempJob.vehicle_model = '0'
        tempJob.vehicle_model_manual = ''
        tempJob.vehicle_plate_number = ''
        tempJob.vehicle_style = '0'
        tempJob.vehicle_style_manual = ''
        tempJob.vehicle_sub_model = '0'
        tempJob.vehicle_sub_model_manual = ''
        tempJob.vehicle_vin_is_valid = 0
        tempJob.vehicle_year = '0'
        tempJob.vehicle_year_manual = 0
      }

      // clear install/contact data
      tempJob.scheduled_date = ''
      tempJob.scheduled_time_end = ''
      tempJob.scheduled_time_start = ''
      tempJob.contact_name = ''
      tempJob.contact_phone1 = ''
      tempJob.contact_phone2 = ''
      tempJob.contact_signature = ''
      tempJob.install_address_city = ''
      tempJob.install_address_line1 = ''
      tempJob.install_address_line2 = null
      tempJob.install_address_state = ''
      tempJob.install_address_zip = ''
      tempJob.install_date = ''
      tempJob.install_major_street1 = ''
      tempJob.install_major_street2 = ''

      // empty out dates
      tempJob.invoice_date = ''
      tempJob.work_order_date = ''
      tempJob.invoice_due_date = ''
      tempJob.completed_date = ''

      // empty techside
      tempJob.techside_job_status = null
      tempJob.techside_job_completed = null
      tempJob.techside_sent = ''

      // other odds and ends
      tempJob.internal_number = ''
      tempJob.edi_id = null
      tempJob.edi_locked = 0
      tempJob.created = ''

      // remove tags.. think this one we will remove

      // empty notes
      tempJob.install_notes = ''
      tempJob.technician_notes = ''
      tempJob.salerep_notes = ''

      tempJob.is_duplicate = copyType === 'duplicate' ? 1 : 0
      tempJob.is_supplemental = copyType === 'supplemental' ? 1 : 0
      tempJob.is_warranty = copyType === 'warranty' ? 1 : 0

      // extra removals for warranty
      if (copyType === 'warranty') {
        // tempJob.tech_commission = 0
        tempJob.insurance_fleet_name = ''
        tempJob.insurance_fleet_address_line1 = null
        tempJob.insurance_fleet_address_line2 = null
        tempJob.insurance_fleet_address_city = ''
        tempJob.insurance_fleet_address_state = ''
        tempJob.insurance_fleet_address_zip = ''
        tempJob.insurance_fleet_fax = ''
        tempJob.insurance_fleet_email = ''
        tempJob.insurance_fleet_notes = ''
        tempJob.insurance_fleet_tax_exempt = 0
        tempJob.insurance_fleet_auto_add_adhesive = 0
        tempJob.insurance_fleet_po_required = 0
        tempJob.insurance_fleet_discount = '0'
        tempJob.insurance_fleet_oem_discount = '0'
        tempJob.insurance_fleet_labor_flat = '0'
        tempJob.insurance_fleet_labor_hourly = '0'
        tempJob.insurance_fleet_kit_charge_type = null
        tempJob.insurance_fleet_kit_standard = '0'
        tempJob.insurance_fleet_kit_high = '0'
        tempJob.insurance_fleet_kit_fast = '0'
        tempJob.insurance_fleet_molding = null
        tempJob.insurance_fleet_chip_first = '0'
        tempJob.insurance_fleet_chip_additional = '0'
        tempJob.insurancefleet_id = ''
        tempJob.insurance_fleet_address = ''
        tempJob.insurance_fleet_phone = ''
        tempJob.insurance_fleet_web = ''
        tempJob.insurance_fleet_kit_charge_type_standard = 'each'
        tempJob.insurance_fleet_kit_charge_type_high = 'each'
        tempJob.insurance_fleet_kit_charge_type_fast = 'each'
        tempJob.insurance_fleet_moulding_discount = 0
        res.data.jobParts = []
        tempJob.sales_rep_commission = 0
        tempJob.sale_source_commission = 0
        tempJob.tech_commission = 0
        tempJob.location_commission = 0
        tempJob.csr_commission = 0
        tempJob.sales_rep_commission_flat_or_percent = 'flat'
        tempJob.sale_source_commission_flat_or_percent = 'flat'
        tempJob.tech_commission_flat_or_percent = 'flat'
        tempJob.location_commission_flat_or_percent = 'flat'
        tempJob.csr_commission_flat_or_percent = 'flat'
        tempJob.total_sale_source_commission = 0
        tempJob.total_sales_rep_commission = 0
        tempJob.total_tech_commission = 0
        tempJob.total_location_commission = 0
        tempJob.total_csr_commission = 0
        tempJob.total_commission = 0
      }

      // these will need to be cleared out for duplicate/warranty/supplimental
      res.data.documents = []
      res.data.jobEmails = []
      res.data.jobFaxes = []
      res.data.payments = []
      res.data.ediqueue = {}

      tempJob.base_job = tempJob.id
      tempJob.id = 'new'
      tempJob.status = ''
      var job = new Job(tempJob)
      res.data.job = job
      // now let's send back this cleaned up dupe job
      callbackFunction(res.data)
    }, function (error) {
      errorCallbackFunction(error)
    })
  }

  static getJobShopPieces (shopId, callbackFunction, errorCallbackFunction = null) {
    Api({ url: `/api/shop/${shopId}/jobShopPieces` }).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static unvoidOption (invoice_date, work_order_date, quote_date, warranty_date, warranty_complete_date, is_warranty) {
    if (invoice_date && invoice_date !== '0000-00-00' && invoice_date !== '' && is_warranty === 0) {
      return 'invoice'
    }

    if (work_order_date && work_order_date !== '0000-00-00' && work_order_date !== '' && is_warranty === 0) {
      return 'workOrder'
    }

    if (quote_date && quote_date !== '0000-00-00' && quote_date !== '' && is_warranty === 0) {
      return 'quote'
    }

    if (warranty_complete_date && warranty_complete_date !== '0000-00-00' && warranty_complete_date !== '' && is_warranty === 1) {
      return 'warrantyComplete'
    }

    if (warranty_date && warranty_date !== '0000-00-00' && warranty_date !== '' && is_warranty === 1) {
      return 'warranty'
    }

    return 'void'
  }

  static convertJobStatusToInt (status) {
    if (status === 'quote') {
      return 1
    }
    if (status === 'workOrder') {
      return 2
    }
    if (status === 'invoice') {
      return 3
    }
    if (status === 'warranty') {
      return 4
    }
    if (status === 'warrantyComplete') {
      return 5
    }
    if (status === 'creditMemo') {
      return 6
    }
    if (status === 'void') {
      return 10
    }
    return 0
  }

  static convertJobStatusValueToName (status) {
    if (status === 'quote') {
      return 'Quote'
    }
    if (status === 'workOrder') {
      return 'Work Order'
    }
    if (status === 'invoice') {
      return 'Invoice'
    }
    if (status === 'void') {
      return 'Void'
    }
    if (status === 'warranty') {
      return 'Warranty'
    }
    if (status === 'warrantyComplete') {
      return 'Warranty Complete'
    }
    return 'New'
  }

  static quickSearchCount (searchVal, callbackFunction, errorCallbackFunction = null) {
    var encodedSearchVal = encodeURIComponent(searchVal)
    Api({ url: `/api/jobs/count/${encodedSearchVal}` }).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static quickSearch (searchVal, tab, callbackFunction, errorCallbackFunction = null) {
    var encodedSearchVal = encodeURIComponent(searchVal)
    Api({ url: `/api/jobs/search/${encodedSearchVal}/tab/${tab}` }).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static newSearch (searchVal, filter, offset, callbackFunction, errorCallbackFunction = null) {
    const apiFilter = filter.split(' ')[0].toLowerCase() || ''
    var encodedSearchVal = encodeURIComponent(searchVal)
    Api({ url: `/api/jobs/quickNav/${encodedSearchVal}/${apiFilter}/${offset}` }).then(res => {
      if (res.data.status === 'success') {
        const foundJobs = res.data.data.jobs
        const count = res.data.data.count
        callbackFunction(foundJobs, count[0])
      } else if (res.data.status === 'not found') {
        callbackFunction('not found')
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  async ediTestableData (selectedInsuranceFleet, jobParts, callback) {
    var ediFields = {}
    ediFields.invoiceDate = UtilityMixin.methods.dateReverseFromClient(this.invoice_date)

    ediFields.ediLocked = this.edi_locked === 1
    ediFields.status = this.status
    ediFields.lossDate = UtilityMixin.methods.dateReverseFromClient(this.loss_date)
    ediFields.installDate = UtilityMixin.methods.dateReverseFromClient(this.install_date)

    ediFields.insuranceFleetIsSelected = Boolean(selectedInsuranceFleet)

    ediFields.insuranceFleet = {}
    if (ediFields.insuranceFleetIsSelected) {
      ediFields.insuranceFleet.ediCapable = selectedInsuranceFleet.edi_capable
      ediFields.insuranceFleet.insuranceFleetName = selectedInsuranceFleet.name
      ediFields.insuranceFleet.insuranceFleetEdiTradingPartner = selectedInsuranceFleet.edi_trading_partner
      ediFields.insuranceFleet.insuranceFleetEdiSafeliteProgramID = selectedInsuranceFleet.edi_safelite_program_id
      ediFields.insuranceFleet.insuranceFleetEdiQuestProgramID = selectedInsuranceFleet.edi_quest_program_id
    }
    ediFields.referralNumber = this.referral_number
    ediFields.vehicleVin = this.vehicle_vin
    ediFields.installContext = this.install_context
    ediFields.manualVehicleEntry = this.manual_vehicle_entry
    ediFields.partCount = jobParts.length

    // Vehicle info - added Thursday, April 21, 2016
    ediFields.vehicleYear = this.vehicle_year
    ediFields.vehicleMake = this.vehicle_make
    ediFields.vehicleModel = this.vehicle_model
    ediFields.vehicleStyle = this.vehicle_style

    // Tax ID for the shop
    ediFields.taxOrSsnId = 0
    ediFields.safeliteEdiNumber = 0
    ediFields.questEdiNumber = 0
    var selectedShop = this._jobCalculations.selectedShop
    if (selectedShop && selectedShop.id > 0) {
      ediFields.taxOrSsnId = parseInt(selectedShop.ssn_or_tax_id)
      if (isNaN(ediFields.taxOrSsnId)) {
        ediFields.taxOrSsnId = 0
      }
      ediFields.safeliteEdiNumber = selectedShop.safelite_edi_number
      if (ediFields.safeliteEdiNumber == null || typeof ediFields.safeliteEdiNumber === 'undefined' || ediFields.safeliteEdiNumber.trim() === '') {
        ediFields.safeliteEdiNumber = ''
      }
      ediFields.questEdiNumber = selectedShop.quest_edi_number
      if (ediFields.questEdiNumber == null || typeof ediFields.questEdiNumber === 'undefined' || ediFields.questEdiNumber.trim() === '') {
        ediFields.questEdiNumber = ''
      }
      ediFields.lynxEdiNumber = selectedShop.lynx_edi_number
      if (ediFields.lynxEdiNumber == null || typeof ediFields.lynxEdiNumber === 'undefined' || ediFields.questEdiNumber.trim() === '') {
        ediFields.lyncEdiNumber = ''
      }
    }

    if (!this.vehicle_year || this.vehicle_year === 'Year...') {
      ediFields.vehicleYear = 0
    }
    if (!this.vehicle_make || this.vehicle_make === 'Make...') {
      ediFields.vehicleMake = 0
    }
    if (!this.vehicle_model || this.vehicle_model === 'Model...') {
      ediFields.vehicleModel = 0
    }
    if (!this.vehicle_style || this.vehicle_style === 'Style...') {
      ediFields.vehicleStyle = 0
    }

    ediFields.doSetInvoiceDueDate = false
    if (ediFields.invoiceDate == null || ediFields.invoiceDate === '0000-00-00' || ediFields.invoiceDate === '') {
      try {
        const { data } = await LegacyApi.get('/currentserverdate?_cacheBust=' + Math.round(Math.random() * 100000))
        ediFields.invoiceDate = data
        ediFields.doSetInvoiceDueDate = true
        callback(ediFields)
      } catch (error) {
        alert('Failed to retrieve server\'s current date during EDI validity test!')
      }
    } else {
      callback(ediFields)
    }
  }

  customerVehicleChanged (loadedCustomer, loadedVehicle) {
    if (this.customer_id > 0 && loadedCustomer && loadedCustomer.id === this.customer_id) {
      if (this.customer_first_name !== loadedCustomer.first_name) {

      }
      if (this.customer_last_name !== loadedCustomer.last_name) {

      }
      if (this.customer_middle_name !== loadedCustomer.middle_name) {

      }
      if (this.customer_phone1 !== loadedCustomer.phone1) {

      }
      if (this.customer_phone2 !== loadedCustomer.phone2) {

      }

      var newCustomerEmail = true
      var customerEmails = []
      if (loadedCustomer.email) {
        customerEmails = loadedCustomer.email.split('#~#/')
      }
      for (var i = 0; i < customerEmails.length; i++) {
        if (customerEmails[i].toLowerCase().trim() === this.customer_email.toLowerCase().trim()) {
          newCustomerEmail = false
          break
        }
      }

      if (newCustomerEmail) {

      }
      if (this.customer_address !== loadedCustomer.address) {

      }
      if (this.customer_address_city !== loadedCustomer.city) {

      }
      if (this.customer_address_state !== loadedCustomer.state) {

      }
      if (this.customer_address_zip !== loadedCustomer.zip) {

      }

      if (this.customer_first_name !== loadedCustomer.first_name ||
        this.customer_last_name !== loadedCustomer.last_name ||
        this.customer_middle_name !== loadedCustomer.middle_name ||
        this.customer_phone1 !== loadedCustomer.phone1 ||
        this.customer_phone2 !== loadedCustomer.phone2 ||
        newCustomerEmail ||
        this.customer_address !== loadedCustomer.address ||
        this.customer_address_city !== loadedCustomer.city ||
        this.customer_address_state !== loadedCustomer.state ||
        this.customer_address_zip !== loadedCustomer.zip) {
        return true
      }
    }

    if (this.customervehicle_id > 0 && loadedVehicle && loadedVehicle.id === this.customervehicle_id) {
      if (this.vehicle_vin !== loadedVehicle.vehicle_vin) {

      }
      if (this.vehicle_vin_is_valid !== loadedVehicle.vehicle_vin_is_valid) {

      }
      if (this.vehicle_description !== loadedVehicle.vehicle_description) {

      }
      if (this.vehicle_year !== loadedVehicle.vehicle_year) {

      }
      if (this.vehicle_make !== loadedVehicle.vehicle_make) {

      }
      if (this.vehicle_model !== loadedVehicle.vehicle_model) {

      }
      if (this.vehicle_sub_model !== loadedVehicle.vehicle_sub_model) {

      }
      if (this.vehicle_style !== loadedVehicle.vehicle_style) {

      }
      if (this.manual_vehicle_entry !== loadedVehicle.manual_vehicle_entry) {

      }
      if (this.vehicle_year_manual !== loadedVehicle.vehicle_year_manual) {

      }
      if (this.vehicle_make_manual !== loadedVehicle.vehicle_make_manual) {

      }
      if (this.vehicle_model_manual !== loadedVehicle.vehicle_model_manual) {

      }
      if (this.vehicle_sub_model_manual !== loadedVehicle.vehicle_sub_model_manual) {

      }
      if (this.vehicle_style_manual !== loadedVehicle.vehicle_style_manual) {

      }
      if (this.vehicle_mileage !== loadedVehicle.vehicle_mileage) {

      }
      if (this.vehicle_plate_number !== loadedVehicle.vehicle_plate_number) {

      }
      if (this.unit_number !== loadedVehicle.unit_number) {

      }

      if (this.vehicle_vin !== loadedVehicle.vehicle_vin ||
        this.vehicle_vin_is_valid !== loadedVehicle.vehicle_vin_is_valid ||
        this.vehicle_description !== loadedVehicle.vehicle_description ||
        this.vehicle_year + '' !== loadedVehicle.vehicle_year + '' ||
        this.vehicle_make + '' !== loadedVehicle.vehicle_make + '' ||
        this.vehicle_model + '' !== loadedVehicle.vehicle_model + '' ||
        this.vehicle_sub_model + '' !== loadedVehicle.vehicle_sub_model + '' ||
        this.vehicle_style + '' !== loadedVehicle.vehicle_style + '' ||
        this.manual_vehicle_entry !== loadedVehicle.manual_vehicle_entry ||
        this.vehicle_year_manual !== loadedVehicle.vehicle_year_manual ||
        this.vehicle_make_manual !== loadedVehicle.vehicle_make_manual ||
        this.vehicle_model_manual !== loadedVehicle.vehicle_model_manual ||
        this.vehicle_sub_model_manual !== loadedVehicle.vehicle_sub_model_manual ||
        this.vehicle_style_manual !== loadedVehicle.vehicle_style_manual ||
        this.vehicle_mileage !== loadedVehicle.vehicle_mileage ||
        this.vehicle_plate_number !== loadedVehicle.vehicle_plate_number ||
        this.unit_number !== loadedVehicle.unit_number
      ) {
        return true
      }
      return false
    }
  }

  setInvoiceTermDate (value) {
    // first format the date
    if (this.invoice_date && this.invoice_date.length > 0) {
      var tempDate = moment(this.invoice_date, 'YYYY-MM-DD').toDate()
      switch (this.selected_invoice_tier) {
        case 'net15':
          tempDate = moment(this.invoice_date).add(15, 'd')
          break
        case 'net20':
          tempDate = moment(this.invoice_date).add(20, 'd')
          break
        case 'net30':
          tempDate = moment(this.invoice_date).add(30, 'd')
          break
        case 'net45':
          tempDate = moment(this.invoice_date).add(45, 'd')
          break
        case 'net60':
          tempDate = moment(this.invoice_date).add(60, 'd')
          break
        case 'net90':
          tempDate = moment(this.invoice_date).add(90, 'd')
          break
        case 'dor':
          // this will be if invoice and due date are the same so no
          // manipulation needs to happen with the date
          break
        case 'custom':
          // this will require that the user set their own date... will
          // default to the invoice date just in case and then the user
          // can adjust
          if (this.invoice_due_date !== '00-00-0000' || this.invoice_due_date() !== '') {
            tempDate = this.invoice_due_date
          }
          break
      }
      this.invoice_due_date = moment(tempDate).format('YYYY-MM-DD')
    }
  }

  saveJobInternal (shop, sendEDI, updateCustomerVehicle, customerEmails, jobParts, payments, jobTaxes, jobtags, notes, documents, component) {
    // self.didSave = true;

    // ***************************************************************************************** //
    // Properly handle enabled/disabled state of the widgets in the 'Save Job' dialog!
    // ***************************************************************************************** //

    /* eslint-disable */
    // run this so calculations in the job update
    this._jobCalculations.totalBalanceAdjustablePayments
    this._jobCalculations.refreshJobTaxValues()
    /* eslint-enable */

    component.$root.$emit('updateSavingJob', (true))

    var savingTitle = ''
    if (sendEDI) {
      savingTitle = 'Saving job & submitting EDI ...'
    } else {
      if (this.edi_locked === 1) {
        savingTitle = 'Saving locked job ...'
      } else {
        savingTitle = 'Saving job ...'
      }
    }
    component.$root.$emit('updateSavingTitle', (savingTitle))

    var dataToSave = {}
    dataToSave.job = this
    if (this.objectTrackerInitial.install_notes !== this.install_notes) {
      dataToSave.job.install_initial = this.objectTrackerInitial.install_notes
    }
    dataToSave.sendEDI = sendEDI
    dataToSave.updateCustomerVehicle = updateCustomerVehicle
    dataToSave.customerEmails = customerEmails

    for (var i = 0; i < jobParts.length; i++) {
      jobParts[i].order = i
    }
    dataToSave.parts = jobParts
    dataToSave.payments = payments
    dataToSave.jobTaxes = jobTaxes
    dataToSave.jobtags = jobtags
    dataToSave.notes = notes
    dataToSave.documents = documents
    dataToSave.clientDate = moment(new Date()).format('YYYY-MM-DD')

    const self = this
    Api.post(`/api/shop/${this.shop_id}/job/${this.id}`, JSON.parse(UtilityMixin.methods.StringifyValidProperties(dataToSave))).then(res => {
      if (res.data.modified && res.data.modified > 0) {
        this.modified = res.data.modified
      }
      var saveText = ''
      // component.inventoryErrorNotification()
      if (res.data.inventory_error && res.data.inventory_error.status === 'failed') {
        component.inventoryErrorNotification()
      }
      if (typeof res.data.ediSubmittedByClient === 'boolean' && res.data.ediSubmittedByClient) {
        // Test for EDI errors
        if (typeof res.data.ediCheckResult !== 'undefined' && $.isArray(res.data.ediCheckResult) && res.data.ediCheckResult.length > 0) {
          // If data.ediCheckResult is an array, this means that the server is sending us ERRORS
          // ... otherwise, data.ediCheckResult is TRUE (and we don't need to check for that)

          // ************************************************************************************************* //
          // NOTE!  data.ediCheckResult could be FALSE, but not an array.
          // This ONLY happens on the client, indicating EDI was NOT sent, but it is NOT an error.
          // On the server, if the instruction to send EDI was given (represented by 'data.ediSubmittedByClient === TRUE'),
          // data.ediCheckResult is always an error if it is not TRUE
          // ************************************************************************************************* //
          var ediErrors = '' + res.data.ediCheckResult.join('\n')
          saveText = 'Invoice #' + res.data.job_id + ' saved.\nBut the EDI was not submitted!\nThe server reports the following errors:\n\n' + ediErrors
          // alert(saveText)
          component.$root.$emit('showGlobalNotification', 'Success', saveText, 'warning')
        } else {
          if (res.data.ediCheckResult === false) {
            // ************************************************************************************************* //
            // See comments above - this case should NEVER occur...
            // ... but if it does, don't crash - just report the problem and allow the user to try again
            // ************************************************************************************************* //
            // alert('Invoice #' + res.data.job_id + ' saved!\nError submitting EDI.')
            component.$root.$emit('showGlobalNotification', 'Success', 'Invoice #' + res.data.job_id + ' saved!\nError submitting EDI.', 'warning')
          } else {
            // Note: The server sends 'data.ediLocked === "unchanged"' if the EDI was not submitted or validated,
            // but there's no need to check that on the client
            if (typeof res.data.ediLocked === 'boolean' && res.data.ediLocked) {
              // This condition should always be met, but we add this variable and check it as an added measure of safety -
              // and in case the design changes in the future, we'll have this in place with no changes required on the client
              // This will get set on job load self.jobData.ediLocked(true)
            }

            // This will happen on job load self.jobData.masterView.checkInvoiceStatus();

            // alert('Invoice #' + res.data.job_id + ' saved!\nSubmitted EDI!')
            component.$root.$emit('showGlobalNotification', 'Success', 'Invoice #' + res.data.job_id + ' saved!\nSubmitted EDI!', 'success')
          }
        }
      } else {
        if (self.status === 'invoice') {
          // alert('Invoice #' + res.data.job_id + ' saved!  Did not submit EDI.')
          component.$root.$emit('showGlobalNotification', 'Success', 'Invoice #' + res.data.job_id + ' saved!  Did not submit EDI.', 'warning')
        } else {
          saveText = Job.convertJobStatusValueToName(self.status) + ' #' + res.data.job_id + ' saved!'
          // alert(saveText)
          component.$root.$emit('showGlobalNotification', 'Success', saveText, 'success')
        }
      }

      if (shop && (shop.auto_qb_export_invoices || shop.auto_qb_export_payments) && this.status === 'invoice') {
        component.$root.$emit('setQuickBooksDetailsLoading', true)
        Job.exportJobToQBO(shop.id, res.data.job_id, true, true, function (results) {
          component.$root.$emit('loadQuickBooksDetails', true)
        }, function (error) {
          console.log('export to qbo error: ', error)
          component.$root.$emit('setQuickBooksDetailsLoading', false)
        })
      }

      component.$root.$emit('getFullJob', res.data.job_id, true)
      component.$root.$emit('updateSavingJob', (false))
    }, function (error) {
      console.log('got error: ', error)
      alert('Failed to save job!')
      component.$root.$emit('updateSavingJob', (false))
    })
  }

  static getQuoteJobsAndDetails (shopId, page, limit, sortBy, sortDesc, quoteFilter, customerNameFilter,
    customerPhoneFilter, customerVehicleFilter, followUpDateFilter, quoteDateFilter, amountFilter,
    successCallback, errorCallback) {
    Api({
      url: `/api/shop/${shopId}/quotes?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&quoteFilter=' + encodeURIComponent(quoteFilter) + '&customerNameFilter=' + encodeURIComponent(customerNameFilter) +
      '&customerPhoneFilter=' + encodeURIComponent(customerPhoneFilter) + '&customerVehicleFilter=' + encodeURIComponent(customerVehicleFilter) +
      '&followUpDateFilter=' + encodeURIComponent(followUpDateFilter) + '&quoteDateFilter=' + encodeURIComponent(quoteDateFilter) +
      '&amountFilter=' + encodeURIComponent(amountFilter)
    }).then(res => {
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  static getQuoteJobsAndDetails_v2 ({shop, page, limit, sortBy, sortDesc, filters}) {
    return Api({
      url: `/api/shop/${shop.id}/quotes?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&quoteFilter=' + encodeURIComponent(filters.id) + '&customerNameFilter=' + encodeURIComponent(filters.customerName) +
      '&customerPhoneFilter=' + encodeURIComponent(filters.customerPhone) + '&customerVehicleFilter=' + encodeURIComponent(filters.vehicle) +
      '&followUpDateFilter=' + encodeURIComponent(filters.followupDate) + '&quoteDateFilter=' + encodeURIComponent(filters.quoteDate) +
      '&amountFilter=' + encodeURIComponent(filters.amount)
    })
  }

  static getQuotesCountsForAllConnectedShops (callbackFunction, errorCallbackFunction = null) {
    Api({url: '/api/quotescount'}).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getJobsTableFilterData (successCallbackFunction, errorCallbackFunction) {
    var parameters = {}
    parameters.request = 'filterData'

    Api.post('/api/jobs', parameters).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getJobsTableRow (job_id, successCallbackFunction, errorCallbackFunction = null) {
    var parameters = {}
    parameters.request = 'jobRow'
    parameters.job_id = job_id

    Api.post('/api/jobs', parameters).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getJobsTable (getTotalCountOnly, page, limit, sortBy, sortDesc, exportCSV, allFilter, selectedShops, selectedShopIds,
    jobIdFilter, selectedJobTypes, customerLastNameFilter, referralNumberFilter, customerFirstNameFilter,
    partsFilter, customerEmailFilter, ediStatusFilter, customerTypeFilter,
    quotesDateFilterType, quotesDateFilter1, quotesDateFilter2,
    workOrderDateFilterType, workOrderDateFilter1, workOrderDateFilter2,
    dispatchDateFilterType, dispatchDateFilter1, dispatchDateFilter2,
    invoiceDateFilterType, invoiceDateFilter1, invoiceDateFilter2,
    followUpDateFilterType, followUpDateFilter1, followUpDateFilter2,
    tagsFilterType, tagsFilter1, tagsFilter2,
    taxesFilterType, taxesFilter1, taxesFilter2,
    documentsFilter,
    notesFilter,
    totalMaterialsType, totalMaterialsFilter1, totalMaterialsFilter2,
    totalLaborType, totalLaborFilter1, totalLaborFilter2,
    totalSubtotalType, totalSubtotalFilter1, totalSubtotalFilter2,
    totalTaxesType, totalTaxesFilter1, totalTaxesFilter2,
    totalAfterTaxesType, totalAfterTaxesFilter1, totalAfterTaxesFilter2,
    deductibleType, deductibleFilter1, deductibleFilter2,
    totalBalanceAfterPaymentsType, totalBalanceAfterPaymentsFilter1, totalBalanceAfterPaymentsFilter2,
    csrNameFilter, salesourceNameFilter,
    salesourceCommissionType, salesourceCommissionFilter1, salesourceCommissionFilter2,
    salesrepNameFilter,
    salesrepCommissionType, salesrepCommissionFilter1, salesrepCommissionFilter2,
    techNameFilter,
    techCommissionType, techCommissionFilter1, techCommissionFilter2,
    locationNameFilter,
    locationCommissionType, locationCommissionFilter1, locationCommissionFilter2,
    commercialaccountNameFilter,
    internalNumberFilter,
    partsCostType, partsCostFilter1, partsCostFilter2,
    customerRebateType, customerRebateFilter1, customerRebateFilter2,
    otherCostsType, otherCostsFilter1, otherCostsFilter2,
    totalMarginType, totalMarginFilter1, totalMarginFilter2,
    customerPhone1Filter,
    customerPhone2Filter,
    customerAddressFilter,
    customerAddressZipFilter,
    customerAddressCityFilter,
    customerAddressStateFilter,
    vehicleYearFilter,
    vehicleMakeFilter,
    vehicleModelFilter,
    vehicleSubModelFilter,
    vehicleStyleFilter,
    vehicleVinFilter,
    insuranceFleetNameFilter,
    ediTradingPartnerFilter,
    lossDateFilterType, lossDateFilter1, lossDateFilter2,
    scheduleDateFilterType, scheduleDateFilter1, scheduleDateFilter2,
    installDateFilterType, installDateFilter1, installDateFilter2,
    scheduledTimeStartFilter,
    scheduledTimeEndFilter,
    installAddressLine1Filter,
    installAddressCityFilter,
    installAddressStateFilter,
    installAddressZipFilter,
    contactNameFilter,
    contactPhone1Filter,
    contactPhone2Filter,
    purchaseOrderNumberFilter,
    requisitionOrderNumberFilter,
    dotNumberFilter,
    lotNumberFilter,
    installContextFilter,
    policyNumberFilter,
    unitNumberFilter,
    installNotesFilter,
    customerIdFilter,
    pendingScheduleFilter,
    pendingInspectionFilter,
    fullJobNumberFilter,
    technicianNotesFilter,
    vehiclePlateNumberFilter,
    lastUpdatedFilter,
    salesidejobIdFilter,
    csvColumns,
    csvRow_export,
    csvAllRowsSelected,
    successCallbackFunction, errorCallbackFunction
  ) {
    var parameters = {}
    parameters.allFilter = allFilter
    parameters.selectedShopsString = selectedShops
    parameters.selectedShopIdsString = selectedShopIds
    parameters.jobIdFilter = jobIdFilter
    parameters.selectedJobTypes = selectedJobTypes
    parameters.customerLastNameFilter = customerLastNameFilter
    parameters.referralNumberFilter = referralNumberFilter
    parameters.customerFirstNameFilter = customerFirstNameFilter
    parameters.partsFilter = partsFilter
    parameters.customerEmailFilter = customerEmailFilter
    parameters.ediStatusFilter = ediStatusFilter
    parameters.customerTypeFilter = customerTypeFilter

    parameters.quotesDateFilterType = quotesDateFilterType
    parameters.quotesDateFilter1 = quotesDateFilter1
    parameters.quotesDateFilter2 = quotesDateFilter2

    parameters.workOrderDateFilterType = workOrderDateFilterType
    parameters.workOrderDateFilter1 = workOrderDateFilter1
    parameters.workOrderDateFilter2 = workOrderDateFilter2

    parameters.dispatchDateFilterType = dispatchDateFilterType
    parameters.dispatchDateFilter1 = dispatchDateFilter1
    parameters.dispatchDateFilter2 = dispatchDateFilter2

    parameters.invoiceDateFilterType = invoiceDateFilterType
    parameters.invoiceDateFilter1 = invoiceDateFilter1
    parameters.invoiceDateFilter2 = invoiceDateFilter2

    parameters.followUpDateFilterType = followUpDateFilterType
    parameters.followUpDateFilter1 = followUpDateFilter1
    parameters.followUpDateFilter2 = followUpDateFilter2

    parameters.tagsFilterType = tagsFilterType
    parameters.tagsFilter1 = tagsFilter1
    parameters.tagsFilter2 = tagsFilter2

    parameters.taxesFilterType = taxesFilterType
    parameters.taxesFilter1 = taxesFilter1
    parameters.taxesFilter2 = taxesFilter2

    parameters.documentsFilter = documentsFilter
    parameters.notesFilter = notesFilter

    parameters.totalMaterialsType = totalMaterialsType
    parameters.totalMaterialsFilter1 = totalMaterialsFilter1
    parameters.totalMaterialsFilter2 = totalMaterialsFilter2

    parameters.totalLaborType = totalLaborType
    parameters.totalLaborFilter1 = totalLaborFilter1
    parameters.totalLaborFilter2 = totalLaborFilter2

    parameters.totalSubtotalType = totalSubtotalType
    parameters.totalSubtotalFilter1 = totalSubtotalFilter1
    parameters.totalSubtotalFilter2 = totalSubtotalFilter2

    parameters.totalTaxesType = totalTaxesType
    parameters.totalTaxesFilter1 = totalTaxesFilter1
    parameters.totalTaxesFilter2 = totalTaxesFilter2

    parameters.totalAfterTaxesType = totalAfterTaxesType
    parameters.totalAfterTaxesFilter1 = totalAfterTaxesFilter1
    parameters.totalAfterTaxesFilter2 = totalAfterTaxesFilter2

    parameters.deductibleType = deductibleType
    parameters.deductibleFilter1 = deductibleFilter1
    parameters.deductibleFilter2 = deductibleFilter2

    parameters.totalBalanceAfterPaymentsType = totalBalanceAfterPaymentsType
    parameters.totalBalanceAfterPaymentsFilter1 = totalBalanceAfterPaymentsFilter1
    parameters.totalBalanceAfterPaymentsFilter2 = totalBalanceAfterPaymentsFilter2

    parameters.csrNameFilter = csrNameFilter
    parameters.salesourceNameFilter = salesourceNameFilter
    parameters.salesourceCommissionType = salesourceCommissionType
    parameters.salesourceCommissionFilter1 = salesourceCommissionFilter1
    parameters.salesourceCommissionFilter2 = salesourceCommissionFilter2
    parameters.salesrepNameFilter = salesrepNameFilter
    parameters.salesrepCommissionType = salesrepCommissionType
    parameters.salesrepCommissionFilter1 = salesrepCommissionFilter1
    parameters.salesrepCommissionFilter2 = salesrepCommissionFilter2
    parameters.techNameFilter = techNameFilter
    parameters.techCommissionType = techCommissionType
    parameters.techCommissionFilter1 = techCommissionFilter1
    parameters.techCommissionFilter2 = techCommissionFilter2
    parameters.locationNameFilter = locationNameFilter
    parameters.locationCommissionType = locationCommissionType
    parameters.locationCommissionFilter1 = locationCommissionFilter1
    parameters.locationCommissionFilter2 = locationCommissionFilter2
    parameters.commercialaccountNameFilter = commercialaccountNameFilter
    parameters.internalNumberFilter = internalNumberFilter
    parameters.partsCostType = partsCostType
    parameters.partsCostFilter1 = partsCostFilter1
    parameters.partsCostFilter2 = partsCostFilter2
    parameters.customerRebateType = customerRebateType
    parameters.customerRebateFilter1 = customerRebateFilter1
    parameters.customerRebateFilter2 = customerRebateFilter2
    parameters.otherCostsType = otherCostsType
    parameters.otherCostsFilter1 = otherCostsFilter1
    parameters.otherCostsFilter2 = otherCostsFilter2
    parameters.totalMarginType = totalMarginType
    parameters.totalMarginFilter1 = totalMarginFilter1
    parameters.totalMarginFilter2 = totalMarginFilter2
    parameters.customerPhone1Filter = customerPhone1Filter
    parameters.customerPhone2Filter = customerPhone2Filter
    parameters.customerAddressFilter = customerAddressFilter
    parameters.customerAddressZipFilter = customerAddressZipFilter
    parameters.customerAddressCityFilter = customerAddressCityFilter
    parameters.customerAddressStateFilter = customerAddressStateFilter
    parameters.vehicleYearFilter = vehicleYearFilter
    parameters.vehicleMakeFilter = vehicleMakeFilter
    parameters.vehicleModelFilter = vehicleModelFilter
    parameters.vehicleSubModelFilter = vehicleSubModelFilter
    parameters.vehicleStyleFilter = vehicleStyleFilter
    parameters.vehicleVinFilter = vehicleVinFilter
    parameters.insuranceFleetNameFilter = insuranceFleetNameFilter
    parameters.ediTradingPartnerFilter = ediTradingPartnerFilter
    parameters.lossDateFilterType = lossDateFilterType
    parameters.lossDateFilter1 = lossDateFilter1
    parameters.lossDateFilter2 = lossDateFilter2
    parameters.scheduleDateFilterType = scheduleDateFilterType
    parameters.scheduleDateFilter1 = scheduleDateFilter1
    parameters.scheduleDateFilter2 = scheduleDateFilter2
    parameters.installDateFilterType = installDateFilterType
    parameters.installDateFilter1 = installDateFilter1
    parameters.installDateFilter2 = installDateFilter2
    parameters.scheduledTimeStartFilter = scheduledTimeStartFilter
    parameters.scheduledTimeEndFilter = scheduledTimeEndFilter
    parameters.installAddressLine1Filter = installAddressLine1Filter
    parameters.installAddressCityFilter = installAddressCityFilter
    parameters.installAddressStateFilter = installAddressStateFilter
    parameters.installAddressZipFilter = installAddressZipFilter
    parameters.contactNameFilter = contactNameFilter
    parameters.contactPhone1Filter = contactPhone1Filter
    parameters.contactPhone2Filter = contactPhone2Filter
    parameters.purchaseOrderNumberFilter = purchaseOrderNumberFilter
    parameters.requisitionOrderNumberFilter = requisitionOrderNumberFilter
    parameters.dotNumberFilter = dotNumberFilter
    parameters.lotNumberFilter = lotNumberFilter
    parameters.installContextFilter = installContextFilter
    parameters.policyNumberFilter = policyNumberFilter
    parameters.unitNumberFilter = unitNumberFilter
    parameters.installNotesFilter = installNotesFilter
    parameters.customerIdFilter = customerIdFilter
    parameters.pendingScheduleFilter = pendingScheduleFilter
    parameters.pendingInspectionFilter = pendingInspectionFilter
    parameters.fullJobNumberFilter = fullJobNumberFilter
    parameters.lastUpdatedFilter = lastUpdatedFilter
    parameters.salesidejobIdFilter = salesidejobIdFilter
    parameters.technicianNotesFilter = technicianNotesFilter
    parameters.vehiclePlateNumberFilter = vehiclePlateNumberFilter
    parameters.getTotalCountOnly = getTotalCountOnly
    parameters.page = page
    parameters.limit = limit
    parameters.sortBy = sortBy
    parameters.sortDesc = sortDesc
    parameters.exportCSV = exportCSV

    if (exportCSV) {
      parameters.csvColumns = csvColumns
      parameters.csvRow_export = csvRow_export
      parameters.csvAllRowsSelected = csvAllRowsSelected
    }

    var url = '/api/jobs'
    for (var key in parameters) {
      if (Object.prototype.hasOwnProperty.call(parameters, key)) {
        if (parameters[key] === '*|RE|OV|ED|*') {
          delete parameters[key]
        }
      }
    }

    Api.post(url, parameters).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static updateJobVariable (jobId, variable, value, callbackFunction, errorCallbackFunction = null) {
    return Api.post(`/api/job/${jobId}/update/${encodeURIComponent(variable)}/${encodeURIComponent(value)}`).then(res => {
      callbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  // # Send Review (2)
  static sendCustomerReview (jobId, shopId, callbackFunction, errorCallbackFunction = null) {
    const isSaleSide = false
    return Api.post(`/api/web/shop/${shopId}/review/${jobId}/${isSaleSide}`).then(res => {
      callbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static exportJobToQBO (shopId, jobId, autoExport, includePayments, successCallbackFunction = null, errorCallbackFunction = null) {
    Job.getInvoiceJobsQBTable(shopId, '1', '9999', '', '', '', '',
      jobId + '', '', '', '', '',
      '', '', '', '', '', '',
      true, false, [jobId + ''], 'qbOnline', autoExport, includePayments,
      function (results) {
        if (successCallbackFunction != null) {
          successCallbackFunction(results)
        }
      }, function (error) {
        if (errorCallbackFunction != null) {
          errorCallbackFunction(error)
        }
      })
  }

  static async syncInvoiceFromArQboSync (shopId, jobId, includePayments) {
    return await Api(`/api/shop/${shopId}/job/${jobId}/syncInvoiceFromArQboSync?includePayments=` + encodeURIComponent(includePayments ? 'true' : 'false'))
  }

  static getInvoiceJobsQBTable (shopId, page, limit, sortBy, sortDesc, fromDate, toDate, invoiceInvoiceNumberFilter, invoiceCustomerFilter, invoiceMemoFilter,
    invoiceReferralNumberFilter, invoiceInvoiceTotalFilter, invoiceInvoiceDateFilter, invoiceLastExportedFilter, invoiceErrorsFilter, invoiceExportDetailsFilter, showOnlyUnexported, searchAllFilter,
    exportToQB, exportAllInvoiceRows, selectedExportInvoiceRows, qbExportType, autoExport, includePayments,
    successCallbackFunction, errorCallbackFunction) {
    var url = `/api/shop/${shopId}/jobs/invoiceJobsQBTable?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
    '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
    '&fromDate=' + encodeURIComponent(fromDate) + '&toDate=' + encodeURIComponent(toDate) + '&invoiceInvoiceNumberFilter=' + encodeURIComponent(invoiceInvoiceNumberFilter) +
    '&invoiceCustomerFilter=' + encodeURIComponent(invoiceCustomerFilter) + '&invoiceMemoFilter=' + encodeURIComponent(invoiceMemoFilter) +
    '&invoiceReferralNumberFilter=' + encodeURIComponent(invoiceReferralNumberFilter) + '&invoiceInvoiceTotalFilter=' + encodeURIComponent(invoiceInvoiceTotalFilter) +
    '&invoiceInvoiceDateFilter=' + encodeURIComponent(invoiceInvoiceDateFilter) + '&invoiceLastExportedFilter=' + encodeURIComponent(invoiceLastExportedFilter) +
    '&invoiceErrorsFilter=' + encodeURIComponent(invoiceErrorsFilter) + '&invoiceExportDetailsFilter=' + encodeURIComponent(invoiceExportDetailsFilter) +
    '&showOnlyUnexported=' + encodeURIComponent(showOnlyUnexported) + '&searchAllFilter=' + encodeURIComponent(searchAllFilter)
    if (exportToQB) {
      url += '&exportToQB=true&exportAllInvoiceRows=' + (exportAllInvoiceRows ? 'true' : 'false') + '&qbExportType=' + encodeURIComponent(qbExportType) +
      '&autoExport=' + encodeURIComponent(autoExport ? 'true' : 'false') + '&includePayments=' + encodeURIComponent(includePayments ? 'true' : 'false')

      var selectedRows = ''
      for (var i = 0; i < selectedExportInvoiceRows.length; i++) {
        if (selectedRows.length > 0) {
          selectedRows += ','
        }
        selectedRows += selectedExportInvoiceRows[i]
      }
      url += '&selectedExportInvoiceRows=' + encodeURIComponent(selectedRows)
    } else {
      url += '&exportToQB=false'
    }
    Api({url: url}).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getQuickBooksJobInfo (jobId, shopId, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/shop/${shopId}/job/${jobId}/quickBooksDetails`}).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static kickBackJob (jobId, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/job/${jobId}/kickback`}).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getARInvoicesTable (shopId, type, sortBy, sortDesc, loadPosition,
    jobIdFilter, jobReferralNumberFilter, jobCustomerNameFilter,
    jobThirdPartyFilter, daysFilter, jobTotal,
    jobBalanceDue, jobDeductibleDue, partiallyReceivedFilter,
    successCallbackFunction, errorCallbackFunction) {
    var url = `/api/shop/${shopId}/jobs/arInvoicesTable?type=` + encodeURIComponent(type) +
      '&sortBy=' + encodeURIComponent(sortBy) +
      '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&loadPosition=' + encodeURIComponent(loadPosition) +
      '&jobIdFilter=' + encodeURIComponent(jobIdFilter) +
      '&jobReferralNumberFilter=' + encodeURIComponent(jobReferralNumberFilter) +
      '&jobCustomerNameFilter=' + encodeURIComponent(jobCustomerNameFilter) +
      '&jobThirdPartyFilter=' + encodeURIComponent(jobThirdPartyFilter) +
      '&daysFilter=' + encodeURIComponent(daysFilter) +
      '&jobTotal=' + encodeURIComponent(jobTotal) +
      '&jobBalanceDue=' + encodeURIComponent(jobBalanceDue) +
      '&jobDeductibleDue=' + encodeURIComponent(jobDeductibleDue) +
      '&partiallyReceivedFilter=' + encodeURIComponent(partiallyReceivedFilter + '')

    Api({url: url}).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getARInvoicesDetails (shopId, type,
    successCallbackFunction, errorCallbackFunction) {
    var url = `/api/shop/${shopId}/jobs/arInvoicesDetails?type=` + encodeURIComponent(type)
    Api({url: url}).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getGlassOrderDetails (shopId, status, startDateRangeFilter, endDateRangeFilter, hideAdhesiveFilter,
    successCallbackFunction, errorCallbackFunction) {
    var url = `/api/shop/${shopId}/jobs/glassOrderDetails?status=` + encodeURIComponent(status) +
    '&startDate=' + encodeURIComponent(startDateRangeFilter) +
    '&endDate=' + encodeURIComponent(endDateRangeFilter) +
    '&hideAdhesiveFilter=' + encodeURIComponent(hideAdhesiveFilter)

    Api({url: url}).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getGlassOrderTable (shopId, status, sortBy, sortDesc, loadPosition,
    startDateRangeFilter, endDateRangeFilter,
    jobIdFilter,
    customerFilter, vehicleFilter,
    scheduledDateFilter, techFilter,
    partFilter, vendorFilter, poFilter,
    recFilter, tpuFilter, hideAdhesiveFilter, groupByTechFilter, isPartsRequiredFilter,
    successCallbackFunction, errorCallbackFunction) {
    var url = `/api/shop/${shopId}/jobs/glassOrderTable?status=` + encodeURIComponent(status) +
      '&startDate=' + encodeURIComponent(startDateRangeFilter) +
      '&endDate=' + encodeURIComponent(endDateRangeFilter) +
      '&sortBy=' + encodeURIComponent(sortBy) +
      '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&loadPosition=' + encodeURIComponent(loadPosition) +
      '&jobIdFilter=' + encodeURIComponent(jobIdFilter) +
      '&customerFilter=' + encodeURIComponent(customerFilter) +
      '&vehicleFilter=' + encodeURIComponent(vehicleFilter) +
      '&scheduledDateFilter=' + encodeURIComponent(scheduledDateFilter) +
      '&techFilter=' + encodeURIComponent(techFilter) +
      '&partFilter=' + encodeURIComponent(partFilter) +
      '&vendorFilter=' + encodeURIComponent(vendorFilter) +
      '&poFilter=' + encodeURIComponent(poFilter) +
      '&recFilter=' + encodeURIComponent(recFilter) +
      '&tpuFilter=' + encodeURIComponent(tpuFilter) +
      '&hideAdhesiveFilter=' + encodeURIComponent(hideAdhesiveFilter) +
      '&groupByTechFilter=' + encodeURIComponent(groupByTechFilter) +
      '&isPartsRequiredFilter=' + encodeURIComponent(isPartsRequiredFilter)
    Api({url: url}).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getJobAndPayments (shopId, jobId,
    successCallbackFunction, errorCallbackFunction) {
    var url = `/api/shop/${shopId}/job/${jobId}/payment`
    Api({url: url}).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static updateJobPendingOffline (jobId, newValue, successCallbackFunction, errorCallbackFunction) {
    return Api.post(`/api/job/${jobId}/updatePendingOffline`, { pending_offline: newValue }).then(res => {
      successCallbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static async getTextMessageConfirmationHistory (jobId) {
    const { data } = await Api.get(`/api/job/${jobId}/textMessageConfirmationHistory`)
    return data
  }

  static async getTextMessageInvoicePdfHistory (jobId) {
    const { data } = await Api.get(`/api/job/${jobId}/textMessageInvoicePdfHistory`)
    return data
  }

  static async getTextMessagePaymentHistory (jobId) {
    const { data } = await Api.get(`/api/job/${jobId}/textMessagePaymentHistory`)
    return data
  }

  static async getTextMessagePaymentCurrentToken (jobId) {
    const { data } = await Api.get(`/api/job/${jobId}/textMessagePaymentCurrentToken`)
    return data
  }

  static async getTextMessageSignatureHistory (jobId, salesideJobId) {
    const { data } = await Api.get(`/api/job/${jobId}/${salesideJobId}/textMessageSignatureHistory`)
    return data
  }

  static async getTextMessageSignatureCurrentToken (jobId) {
    const { data } = await Api.get(`/api/job/${jobId}/textMessageSignatureCurrentToken`)
    return data
  }

  static async getCustomerPhoneNumbers (jobId) {
    const { data } = await Api.get(`/api/job/${jobId}/customerPhoneNumbers`)
    return data
  }

  static async updateConfirmationStatus (jobId, payload) {
    const { data } = await Api.post(`/api/job/${jobId}/updateConfirmationStatus`, payload)
    return data
  }

  static async getParts (jobId) {
    const { data } = await Api.get(`/api/job/${jobId}/parts`)
    return data
  }
}
