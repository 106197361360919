var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title: _vm.location.id > 0 ? "Edit Location" : "New Location",
        id: "locationModal",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg"
      },
      on: {
        show: _vm.dialogAboutToAppear,
        shown: function($event) {
          return _vm.$refs.nameComponent.focus()
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-6" }, [
          _c("label", [_vm._v("Status")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.location.status,
                  expression: "location.status"
                }
              ],
              staticClass: "form-control form-control-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.location,
                    "status",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "active" } }, [_vm._v("Active")]),
              _c("option", { attrs: { value: "disabled" } }, [
                _vm._v("Disabled")
              ])
            ]
          )
        ]),
        _vm.location.id > 0
          ? _c("div", { staticClass: "form-group col-6" }, [
              _c("label"),
              _c("p", [
                _vm._v("ID: "),
                _c("span", { staticClass: "idBox" }, [
                  _vm._v(_vm._s(_vm.location.id))
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "form-group col-8" },
          [
            _c("RequiredInput", {
              ref: "nameComponent",
              attrs: {
                title: "Name",
                value: _vm.location.name,
                placeholder: "",
                capitalizeAll: true
              },
              on: {
                "update:value": function($event) {
                  return _vm.$set(_vm.location, "name", $event)
                }
              }
            })
          ],
          1
        ),
        _vm.checkCommissionPermission
          ? _c(
              "div",
              { staticClass: "form-group col-4" },
              [
                _c("label", [_vm._v("Commission")]),
                _c("FloatDropdown", {
                  ref: "commissionComponent",
                  attrs: {
                    dropdownOptions: [
                      { value: "flat", label: "$", dropdownLabel: "Flat" },
                      { value: "percent", label: "%", dropdownLabel: "%" }
                    ],
                    inDisabled: false,
                    dropdownValue: _vm.location.commission_flat_or_percent,
                    value: _vm.location.commission
                  },
                  on: {
                    "update:dropdownValue": function($event) {
                      return _vm.$set(
                        _vm.location,
                        "commission_flat_or_percent",
                        $event
                      )
                    },
                    "update:dropdown-value": function($event) {
                      return _vm.$set(
                        _vm.location,
                        "commission_flat_or_percent",
                        $event
                      )
                    },
                    "update:value": function($event) {
                      return _vm.$set(_vm.location, "commission", $event)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c("label", [_vm._v("Details")]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.location.details,
                expression: "location.details"
              }
            ],
            staticClass: "form-control form-control-sm",
            domProps: { value: _vm.location.details },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.location, "details", $event.target.value)
              }
            }
          })
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", size: "lg", variant: "primary" },
                on: { click: _vm.saveLocation }
              },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", variant: "primary" },
                on: { click: _vm.cancelEdits }
              },
              [_vm._v("Cancel")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }