var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "t-flex t-flex-col t-items-center t-justify-center t-h-full\n            t-text-center"
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/img/LiveChat.png"), alt: "Live Chat" }
        }),
        _c("div", { staticClass: "t-mt-8 t-mb-2 t-text-xl t-text-gray-700" }, [
          _vm._v(" Welcome to the Chat ")
        ]),
        _c("div", { staticClass: "t-text-gray-600" }, [
          _vm._v(" Select a joined channel or browse public channels ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }