var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-bg-white t-p-3 t-rounded-full t-shadow-md t-cursor-pointer",
      attrs: { id: "button" },
      on: { click: _vm.open }
    },
    [
      _vm.totalMessagesCount > 0
        ? _c(
            "span",
            {
              staticClass: "t-flex t-items-center t-justify-center",
              attrs: { id: "chat-messages-count" }
            },
            [_vm._v(" " + _vm._s(_vm.totalMessagesCount) + " ")]
          )
        : _vm._e(),
      _c("i", { staticClass: "el-icon-chat-line-round" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }