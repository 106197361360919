
// Essentially allias for eventType enum from gb-api-grpc...
// @TODO: need to check if it's safe to include gb-api-grpc directly.

const EventType = {
  EVENT_TYPE_JOB_RECEIVED: 1,
  EVENT_TYPE_JOB_RESCHEDULED: 2,
  EVENT_TYPE_JOB_CANCELED: 3,
  EVENT_TYPE_JOB_COMPLETED: 4,
  EVENT_TYPE_PAYMENT_CREATED: 5,
  EVENT_TYPE_FAX_RECEIVED: 6,
  EVENT_TYPE_FAX_SEND_ERROR: 7,
  EVENT_TYPE_SMS_RECEIVED: 8,
  EVENT_TYPE_SMS_CONFIRMATION_DECLINED: 9,
  EVENT_TYPE_SMS_SIGNATURE_RECEIVED: 10,
  EVENT_TYPE_RIGHT_PART_RECEIVED: 11,
  EVENT_TYPE_DATA_EXPORTED: 12,
  EVENT_TYPE_QUICKBOOKS_EXPORT_ERROR: 13,
  EVENT_TYPE_EDI_REJECTED: 14,
  EVENT_TYPE_EDI_ACCEPTED: 15,
  EVENT_TYPE_EDI_PAID: 16,
  EVENT_TYPE_WEB_LEAD_RECEIVED: 17,
  EVENT_TYPE_SUPPORT_TICKET_UPDATED: 18,
  EVENT_TYPE_INVENTORY_RECEIVED: 19,
  EVENT_TYPE_FORM_SIGNED: 20,
  EVENT_TYPE_ACCOUNT_CREATED: 21,
  EVENT_TYPE_REPORT_PARTS_UNSOURCED: 22,
  EVENT_TYPE_REPORT_JOBS_UNASSIGNED: 23,
  EVENT_TYPE_REPORT_JOBS_UNCONFIRMED: 24,
  EVENT_TYPE_REPORT_JOBS_UNMAPPED: 25,
  EVENT_TYPE_REPORT_PERFORMANCE: 26,
  EVENT_TYPE_REPORT_ACCOUNTS_OVERDUE: 27,
  EVENT_TYPE_REPORT_RIGHT_PARTS: 28,
  EVENT_TYPE_REPORT_EDI_QUEUED: 29,
  EVENT_TYPE_REPORT_WORK_ORDERS: 30,
  EVENT_TYPE_REPORT_INVOICES_UNEXPORTED: 31,
};

const EventsStyles = {
  // JOB
  [EventType.EVENT_TYPE_JOB_COMPLETED]: {
    color: 'green',
    icon: 'md-verified'
  },
  [EventType.EVENT_TYPE_SMS_SIGNATURE_RECEIVED]: {
    color: 'green',
    icon: 'md-draw'
  },
  [EventType.EVENT_TYPE_PAYMENT_CREATED]: {
    color: 'green',
    icon: 'md-paid',
  },
  [EventType.EVENT_TYPE_RIGHT_PART_RECEIVED]: {
    color: 'green',
    icon: 'md-filter_alt',
  },
  [EventType.EVENT_TYPE_FORM_SIGNED]: {
    color: 'green',
    icon: 'md-assignment',
  },

  // SCHEDULER
  [EventType.EVENT_TYPE_JOB_RESCHEDULED]: {
    color: 'blue',
    icon: 'md-cached',
  },
  [EventType.EVENT_TYPE_JOB_CANCELED]: {
    color: 'blue',
    icon: 'md-block',
  },
  [EventType.EVENT_TYPE_JOB_RECEIVED]: {
    color: 'blue',
    icon: 'md-sim_card_download',
  },
  [EventType.EVENT_TYPE_SMS_CONFIRMATION_DECLINED]: {
    color: 'blue',
    icon: 'md-cancel',
  },
  [EventType.EVENT_TYPE_WEB_LEAD_RECEIVED]: {
    color: 'blue',
    icon: 'md-monitor',
  },

  // EXPORT
  [EventType.EVENT_TYPE_DATA_EXPORTED]: {
    color: 'purple',
    icon: 'md-description',
  },
  [EventType.EVENT_TYPE_QUICKBOOKS_EXPORT_ERROR]: {
    color: 'purple',
    icon: 'md-error',
  },

  // COMMUNICATION
  [EventType.EVENT_TYPE_SMS_RECEIVED]: {
    color: 'yellow',
    icon: 'md-sms',
  },
};

export {
  EventType,
  EventsStyles
};
