var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        width: "1400px",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        "custom-class": "chat-dialog"
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-blue-100 t-text-lg",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-bank-card" }),
          _c("span", { staticClass: "t-ml-3" }, [_vm._v("Add payment")])
        ]
      ),
      _c(
        "div",
        { staticClass: "t-h-full t-flex t-flex-col" },
        [
          _c(
            "div",
            { staticClass: "t-flex-grow t-p-6 t-overflow-y-auto" },
            [
              _c(
                "div",
                { staticClass: "t-flex t-justify-between" },
                [
                  _c(
                    "el-radio-group",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      staticClass: "t-mb-4",
                      model: {
                        value: _vm.consumerId,
                        callback: function($$v) {
                          _vm.consumerId = $$v
                        },
                        expression: "consumerId"
                      }
                    },
                    _vm._l(_vm.consumers, function(c) {
                      return _c(
                        "el-radio",
                        {
                          key: c.consumer_id,
                          staticClass: "t-flex t-ml-0 t-mb-3 t-w-full",
                          attrs: { label: c.consumer_id, border: "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "t-flex t-text-gray-700 t-font-normal t-items-center t-w-120"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "t-flex t-ml-4 t-flex-grow" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "t-mr-1 t-text-base t-text-gray-600",
                                    class: c.is_insurance
                                      ? "el-icon-office-building"
                                      : "el-icon-user"
                                  }),
                                  _c("div", { staticClass: "t-truncate" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          c.is_insurance || c.is_commercial
                                            ? c.company_name
                                            : c.first_name + " " + c.last_name
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "t-flex t-mr-8" }, [
                                _c("i", {
                                  staticClass: "el-icon-document-copy t-mr-1"
                                }),
                                _c("div", [_vm._v(_vm._s(c.invoices_count))])
                              ]),
                              _c("div", { staticClass: "t-flex" }, [
                                _c("div", { staticClass: "t-mr-1" }, [
                                  _vm._v("$")
                                ]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatAmount(c.remaining_balance)
                                    )
                                  )
                                ])
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _vm.selectedConsumer && _vm.selectedConsumer.trading_partner
                    ? _c("el-checkbox", {
                        staticClass: "t-normal-case t-bg-white t-font-normal",
                        attrs: {
                          label:
                            "Show all with " +
                            _vm.selectedConsumer.trading_partner,
                          border: ""
                        },
                        model: {
                          value: _vm.showRelatedTroughTradingPartner,
                          callback: function($$v) {
                            _vm.showRelatedTroughTradingPartner = $$v
                          },
                          expression: "showRelatedTroughTradingPartner"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.selectedInvoices.length
                ? _c(
                    "div",
                    { staticClass: "t-pb-4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "t-mb-2 t-text-base t-flex t-justify-between"
                        },
                        [
                          _c("div", [_vm._v("Selected invoices:")]),
                          _c("div", [
                            _vm._v("Total: "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "t-text-gray-700 t-font-medium t-ml-2"
                              },
                              [_vm._v(" $" + _vm._s(_vm.total) + " ")]
                            )
                          ])
                        ]
                      ),
                      _vm._l(_vm.selectedInvoices, function(invoice, index) {
                        return _c(
                          "div",
                          {
                            key: invoice.job_id,
                            staticClass:
                              "t-flex t-border t-border-solid t-border-gray-300 t-rounded\n                 t-px-6 t-py-1 t-bg-gray-100 t-items-center t-mb-1"
                          },
                          [
                            _c("div", { staticClass: "t-w-48" }, [
                              _c(
                                "div",
                                { staticClass: "t-text-xs t-text-gray-500" },
                                [_vm._v("Job id")]
                              ),
                              _c("div", [_vm._v(_vm._s(invoice.job_id))])
                            ]),
                            invoice.type === "insurance"
                              ? [
                                  _c("div", { staticClass: "t-flex-grow" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "t-text-xs t-text-gray-500"
                                      },
                                      [_vm._v("Company name")]
                                    ),
                                    _c("div", [
                                      _vm._v(_vm._s(invoice.main_company_name))
                                    ])
                                  ]),
                                  _c("div", { staticClass: "t-w-64" }, [
                                    invoice.secondary_customer_name
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "t-text-xs t-text-gray-500"
                                          },
                                          [_vm._v(" Customer name ")]
                                        )
                                      : _vm._e(),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(invoice.secondary_customer_name)
                                      )
                                    ])
                                  ])
                                ]
                              : [
                                  _c("div", { staticClass: "t-w-64" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "t-text-xs t-text-gray-500"
                                      },
                                      [_vm._v("Customer name")]
                                    ),
                                    _c("div", [
                                      _vm._v(_vm._s(invoice.main_customer_name))
                                    ])
                                  ]),
                                  _c("div", { staticClass: "t-flex-grow" }, [
                                    invoice.secondary_company_name
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "t-text-xs t-text-gray-500"
                                          },
                                          [_vm._v(" Company name ")]
                                        )
                                      : _vm._e(),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(invoice.secondary_company_name)
                                      )
                                    ])
                                  ])
                                ],
                            _c("div", { staticClass: "t-w-48" }, [
                              _c(
                                "div",
                                { staticClass: "t-text-xs t-text-gray-500" },
                                [_vm._v("Total")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatAmount(invoice.total_balance)
                                    )
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "t-w-48" }, [
                              _c(
                                "div",
                                { staticClass: "t-text-xs t-text-gray-500" },
                                [_vm._v("Balance")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatAmount(
                                        invoice.remaining_balance
                                      )
                                    )
                                )
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "t-w-48" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "t-text-xs t-text-gray-500" },
                                  [_vm._v("Amount")]
                                ),
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    size: "mini",
                                    placeholder: "Amount"
                                  },
                                  model: {
                                    value: _vm.selectedInvoices[index].amount,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.selectedInvoices[index],
                                        "amount",
                                        $$v
                                      )
                                    },
                                    expression: "selectedInvoices[index].amount"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          2
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _c("InvoicesTable", {
                ref: "table",
                staticClass: "t-mt-4",
                attrs: {
                  fetching: _vm.fetching,
                  invoices: _vm.filteredInvoices,
                  selectedInvoices: _vm.selectedInvoices,
                  consumer: _vm.selectedConsumer,
                  jobIdFilter: _vm.jobIdFilter,
                  referralFilter: _vm.referralFilter,
                  poFilter: _vm.poFilter,
                  customerNameFilter: _vm.customerNameFilter
                },
                on: {
                  "update:jobIdFilter": function($event) {
                    _vm.jobIdFilter = $event
                  },
                  "update:job-id-filter": function($event) {
                    _vm.jobIdFilter = $event
                  },
                  "update:referralFilter": function($event) {
                    _vm.referralFilter = $event
                  },
                  "update:referral-filter": function($event) {
                    _vm.referralFilter = $event
                  },
                  "update:poFilter": function($event) {
                    _vm.poFilter = $event
                  },
                  "update:po-filter": function($event) {
                    _vm.poFilter = $event
                  },
                  "update:customerNameFilter": function($event) {
                    _vm.customerNameFilter = $event
                  },
                  "update:customer-name-filter": function($event) {
                    _vm.customerNameFilter = $event
                  },
                  checkboxChanged: function($event) {
                    return _vm.checkboxChanged($event)
                  }
                }
              })
            ],
            1
          ),
          _vm.selectedInvoices.length
            ? _c(
                "div",
                { staticClass: "t-text-right t-px-6 t-py-3 t-bg-gray-200" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-plus",
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          _vm.addPaymentIsOpen = true
                        }
                      }
                    },
                    [_vm._v(" Add payment ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("AddPayment", {
            attrs: {
              visible: _vm.addPaymentIsOpen,
              invoices: _vm.selectedInvoices,
              shopId: _vm.shopId,
              consumer: _vm.selectedConsumer
            },
            on: {
              close: function($event) {
                _vm.addPaymentIsOpen = false
              },
              added: _vm.paymentAdded
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }