<template>
  <gb-card mdi="timeline" title="Ticket Open Time" v-loading="loading">
    <chart :chart-data="data" ref="chart" class="earnings-chart" />
  </gb-card>
</template>

<script>
import Chart from './Chart'

export default {
  components: { Chart },
  data() {
    return {
      loading: false,
      data: {}
    }
  },
  computed: {
    gradient() {
      const ctx = this.$refs.chart.context
      const gradient = ctx.createLinearGradient(0, 0, 0, 300)
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0')
      gradient.addColorStop(0, 'rgba(0, 122, 255, 0.33')
      return gradient
    },
    revenueStyle() {
      return {
        backgroundColor: this.gradient,
        borderColor: '#007AFF',
        borderWidth: 1,
        pointRadius: 0
      }
    }
  },
  methods: {
    async getData() {
      this.loading = true
      // const { data } = await this.$unum.charts().get('data/revenue')
      const data = [
        {month: "2020-10-01T00:00:00-07:00", AverageTicketOpen: 12},
        {month: "2020-11-01T00:00:00-07:00", AverageTicketOpen: 15},
        {month: "2020-12-01T00:00:00-07:00", AverageTicketOpen: 75},
        {month: "2021-01-01T00:00:00-07:00", AverageTicketOpen: 85},
        {month: "2021-02-01T00:00:00-07:00", AverageTicketOpen: 63},
        {month: "2021-03-01T00:00:00-07:00", AverageTicketOpen: 32},
        {month: "2021-04-01T00:00:00-07:00", AverageTicketOpen: 12},
        {month: "2021-05-01T00:00:00-07:00", AverageTicketOpen: 9},
        {month: "2021-06-01T00:00:00-07:00", AverageTicketOpen: 11},
        {month: "2021-07-01T00:00:00-07:00", AverageTicketOpen: 7},
        {month: "2021-08-01T00:00:00-07:00", AverageTicketOpen: 5},
        {month: "2021-09-01T00:00:00-07:00", AverageTicketOpen: 4},
      ]
      this.data = {
        datasets: [
          {
            label: 'Time open',
            ...this.revenueStyle,
            data: data.map(({ AverageTicketOpen, month }) => ({
              x: month,
              y: AverageTicketOpen
            }))
          }
        ]
      }
      this.loading = false
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.earnings-chart {
  height: 300px;
}
</style>
