<template>
  <el-dialog
    append-to-body
    top="100px"
    width="1000px"
    class="t-font-sans"
    custom-class="unit-adjustment-dialog"
    :visible="visible"
    :before-close="handleClose"
  >
    <div slot="title" class="t-text-base t-text-gray-700">Adjust inventory</div>

    <LoadingSpinner v-if="loading" />

    <div v-else class="t-py-4">
      <div class="t-flex t-space-x-12 t-px-6">
        <div class="t-w-1/3">
          <div class="t-mb-1 t-text-xs t-italic t-text-gray-400">
            Number:
          </div>
          <div class="t-mt-2">
            <span class="t-font-medium">{{ item.number }}</span>
            <span class="t-text-xs t-text-gray-500">
              (
                Starting: {{ availableCount }},
                Adjusted: {{ form.quantity }},
                Ending: {{ +form.quantity + availableCount }}
              )
            </span>
          </div>
        </div>
        <div class="t-flex">
          <div class="t-flex t-flex-col">
            <div class="t-mb-1 t-text-xs t-italic t-text-gray-400">
              Quantity:
            </div>
            <el-input-number
              v-model="form.quantity"
              size="small"
              :min="-availableCount"
            />
          </div>
          <template v-if="form.quantity > 0">
            <div class="t-flex t-flex-col">
              <div class="t-h-4 t-mb-1" />
              <v-icon name="times" class="t-mt-2 t-mx-4" />
            </div>
            <div class="t-flex t-flex-col t-w-36">
              <div class="t-mb-1 t-text-xs t-italic t-text-gray-400">Unit cost:</div>
              <el-input-number v-model="form.cost" :controls="false" size="small" />
            </div>
            <div class="t-flex t-flex-col">
              <div class="t-h-4 t-mb-1" />
              <v-icon name="equals" class="t-mt-2 t-mx-4" />
            </div>
            <div class="t-flex t-flex-col">
              <div class="t-h-4 t-mb-1" />
              <div class="t-mt-1 t-text-base">${{ total }}</div>
            </div>
          </template>
        </div>
      </div>

      <div class="t-mt-4 t-px-6">
        <div class="t-mb-1 t-text-xs t-italic t-text-gray-400">
          Note:
        </div>
        <el-input v-model="form.notes" type="textarea" placeholder="Enter adjustment note" />
      </div>

      <div class="t-bg-blue-50 t-px-6 t-py-4 t-my-6 t-shadow t-text-gray-500">
        <div class="t-flex t-mb-2">
          <div class="t-w-1/3 t-text-right">
            <div class="t-mb-1 t-text-xs t-italic t-text-blue-400">
              Changes Summary
            </div>
          </div>
        </div>
        <div class="t-flex t-mb-2">
          <div v-if="form.quantity >= 0" class="t-w-1/3 t-text-right">
            Units to be <span class="t-font-bold">added</span> to inventory
          </div>
          <div v-else class="t-w-1/3 t-text-right">
            Units to be <span class="t-font-bold">removed</span> from inventory
          </div>
          <div class="t-pl-4 t-text-gray-600">{{ Math.abs(form.quantity) }}</div>
        </div>
        <div v-if="form.quantity >= 0">
          <div class="t-flex t-mb-2">
            <div class="t-w-1/3 t-text-right">
              Units will be <span class="t-font-bold">added</span> at
            </div>
            <div class="t-pl-4 t-text-gray-600">
              ${{ parseFloat(this.form.cost).toFixed(2) }} / per unit
            </div>
          </div>
          <div class="t-flex t-mb-2">
            <div class="t-w-1/3 t-text-right">Total adjustment</div>
            <div class="t-pl-4 t-text-gray-600">${{ total }}</div>
          </div>
        </div>
        <div v-else-if="shop.costingMethod">
          <div class="t-flex t-mb-2">
            <div class="t-w-1/3 t-text-right">
              The units will be
              <span class="t-font-bold">removed</span>
              based on your shop's default costing method
            </div>
            <div class="t-pl-4 t-text-gray-600">{{ shop.costingMethod.toUpperCase() }}</div>
          </div>
        </div>
      </div>

      <div class="t-px-6 t-italic t-text-gray-500">
        * Remember take care when using this feature. This action will affect inventory counts as
        well as total valuation and cannot be undone
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button
        plain
        type="info"
        size="small"
        :disabled="submitting"
        @click="handleClose"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        size="small"
        :loading="submitting"
        :disabled="form.quantity === 0"
        @click="submit"
      >
        Apply Adjusment
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import LoadingSpinner from '@/components/Helpers/LoadingSpinner.vue'

export default {
  components: {
    LoadingSpinner
  },

  props: {
    visible: Boolean,
    itemId: {
      type: Number,
      required: true
    },
    shopId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    loading: true,
    item: null,
    availableCount: 0,
    form: {
      quantity: 0,
      cost: 0,
      notes: ''
    },
    submitting: false
  }),

  computed: {
    shop() {
      return this.$store.state.shops.find(i => i.id === this.shopId)
    },
    total() {
      return (this.form.cost * this.form.quantity).toFixed(2)
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.resetData()
        this.getData()
      }
    }
  },

  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
    async getAvailableCount() {
      const available = await this.$unum.warehouse()
        .get(`/items/${this.itemId}/units/count-available`, {
          params: {
            shopId: this.shopId
          }
        })
      this.availableCount = +available.data.data
    },
    async getItem() {
      const item = await this.$unum.warehouse().get(`/items/${this.itemId}`)
      this.item = item.data.data
    },
    resetData() {
      this.item = null
      this.availableCount = 0
      this.form = {
        quantity: 0,
        cost: 0,
        notes: ''
      }
    },
    async getData() {
      this.loading = true
      await this.getAvailableCount()
      await this.getItem()
      this.loading = false
    },
    async submit() {
      this.submitting = true
      try {
        await this.$unum.warehouse().post('/adjustments', {
          itemId: this.itemId,
          shopId: this.shopId,
          ...this.form
        })
        this.$message.success('Inventory adjusted')
        this.handleClose()
        this.$root.$emit('adjustment::created')
        this.$emit('adjusted')
        this.submitting = false
      } catch (e) {
        this.$message.error(e.response.data.message)
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss">
.unit-adjustment-dialog {
  @apply t-font-sans;
  .el-dialog__header {
    @apply t-pl-6 t-pb-3 t-border-0 t-border-b t-border-solid;
  }
  .el-dialog__body {
    @apply t-p-0
  }
}
</style>
