<template>
  <div>
    <el-tooltip
      :content="types[slot.type].tooltip"
      placement="right"
      class="scheduler-tech-time-slot"
    >
      <el-badge :value="count" :type="badgeType" :style="{ zIndex: 40 }">
        <el-tag size="small" class="mr-1 mb-1" :type="types[slot.type].color">
          {{ slot.all_day ? 'All Day' : slotTime }}
          <span v-if="slot.type === 'limit'" class=" t-font-extrabold">
            ({{ slot.job_limit }} max)
          </span>
        </el-tag>
      </el-badge>
    </el-tooltip>
  </div>
</template>

<script>
import { parseTime } from '@/scripts/helpers/date.helpers'
import { getSlotRouteEntryCount } from '@/scripts/helpers/availability.helpers'

export default {
  props: {
    slot: Object,
    entries: Array
  },
  data() {
    return {
      types: {
        limit: {
          color: 'primary',
          tooltip: 'Time range job limit'
        },
        block: {
          color: 'danger',
          tooltip: 'Tech unavailable'
        }
      }
    }
  },
  computed: {
    slotTime() {
      return `${parseTime(this.slot.start)} to ${parseTime(this.slot.end)}`
    },
    count() {
      return getSlotRouteEntryCount(this.slot)(this.entries)
    },
    badgeType() {
      if (this.slot.type === 'limit') {
        return this.slot.job_limit >= this.count ? 'success' : 'danger'
      } else {
        return this.count ? 'danger' : 'success'
      }
    }
  }
}
</script>

<style lang="scss">
.scheduler-tech-time-slot {
  .el-badge__content {
    z-index: 40;
  }
}
</style>
