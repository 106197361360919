import { defineStore } from 'pinia'
import Api from '@/services/Api'
import Job from '@/scripts/objects/job'

export const useQboSyncStore = defineStore('qboSync', {
  state: () => {
    return {
      shop: {},
      visible: false,
      fetching: false,
      startingExport: false,
      exportStarted: false,
      inGlassBiller: [],
      notInGlassBiller: []
    }
  },

  actions: {
    openView(shop) {
      this.shop = shop
      this.visible = true
    },

    async getShopQboArAccountBalance(shopId) {
      try {
        const { data } = await Api.get(
          `/api/shop/${shopId}/qboSync/getArAccountBalance`
        )

        return data
      } catch (e) {
        throw new Error('Failed to retrieve AR balance from QBO')
      }
    },

    async compareQboInvoices(shops, dates) {
      try {
        // get qbo invoices and gb invoices with balance > 0 and compare
        this.fetching = true
        const { data } = await Api.post('/api/qboSync/compareInvoices', { shops, dates })

        this.inGlassBiller = data.inGlassBiller
        this.notInGlassBiller = data.notInGlassBiller
        this.fetching = false
      } catch (e) {
        this.fetching = false
        throw new Error('Something went wrong while comparing invoices')
      }
    },

    async exportInvoiceToQbo(shopId, id, includePayments) {
      try {
        this.startingExport = true
        const { data } = await Job.syncInvoiceFromArQboSync(shopId, id, includePayments)

        if (data.onlineExportStarted === 'yes') {
          this.exportStarted = true
          return 'success'
        }
      } catch (e) {
        throw new Error('Something went wrong while starting export')
      } finally {
        this.startingExport = false
      }
    },

    close() {
      this.$reset()
    }
  }
})
