import Api from '@/services/Api'
const axios = require('axios')


export default class TextMessageTemplate {
  static async getForUser (type, shopId = null) {
    const { data } = await Api.get(`/api/textMessageTemplates/getTemplatesByType/${type || 'sms'}?shopId=${shopId}`)
    return data
  }

  static async getTypes (payload) {
    const { data } = await Api.get(`/api/textMessageTemplates/getTypes${payload === 'email' ? '/email' : '/sms'}`)
    return data
  }

  static async store (payload) {
    const { data } = await Api.post('/api/textMessageTemplates', payload)
    return data
  }

  static async update (templateId, payload) {
    const { data } = await Api.put(`/api/textMessageTemplates/${templateId}`, payload)
    return data
  }

  static async destroy (templateId) {
    await Api.delete(`/api/textMessageTemplates/${templateId}`)
  }

  static async generateMessageBody (templateId, jobId) {
    const { data } = await Api.post(`/api/textMessageTemplates/${templateId}/generateMessageBody`, {
      jobId: jobId
    })
    return data
  }

  static async sendPdfInvoice (payload) {
    const { data } = await Api.post('/api/textMessageTemplates/sendPdfInvoice', payload)
    return data
  }

  static async sendPayment (payload) {
    const { data } = await Api.post('/api/textMessageTemplates/sendPayment', payload)
    return data
  }

  static async getPaymentLink (payload) {
    const { data } = await Api.post('/api/textMessageTemplates/getPaymentLink', payload)
    return data
  }

  static async sendSignature (payload) {
    const { data } = await Api.post('/api/textMessageTemplates/sendSignature', payload)
    return data
  }

  static async getSignatureLink (payload) {
    const { data } = await Api.post('/api/textMessageTemplates/getSignatureLink', payload)
    return data
  }

  static async sendTssInvite (payload) {
    const { data } = await Api.post('/api/textMessageTemplates/sendTssInvite', payload)
    return data
  }

  static async getTextMessageInviteHistory (gbVendorId, limit = 0) {
    const { data } = await Api.get(`/api/textMessageTemplates/textMessageInviteHistory/${gbVendorId}?limit=${limit}`)
    return data
  }
}
