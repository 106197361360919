// Defining columns
const invoiceNumCol = { label: 'Invoice #', prop: 'invoice_num', link: true }
const laborCol = { label: 'Labor', prop: 'total_labor', dollar: true }
const materialsCol = {
  label: 'Materials',
  prop: 'total_materials',
  dollar: true
}
const subtotalCol = { label: 'Subtotal', prop: 'total_subtotal', dollar: true }
const taxesCol = { label: 'Sales Tax', prop: 'total_taxes', dollar: true }
const grossCol = {
  label: 'Gross Sales',
  prop: 'total_after_taxes',
  class: 'strong',
  dollar: true
}
const commissionsCol = {
  label: 'Commissions',
  prop: 'total_commission',
  dollar: true
}

const rebateCol = {
  label: 'Rebate',
  prop: 'customer_rebate',
  dollar: true
}

const otherCol = {
  label: 'Other',
  prop: 'other_costs',
  dollar: true
}

const marginCol = {
  label: 'Margin',
  prop: 'total_margin',
  dollar: true,
  class: 'strong'
}

const costCol = {
  label: 'Parts Cost',
  prop: 'total_cost',
  dollar: true,
  class: 'strong'
}

const customerNameCol = { label: 'Customer Name', prop: 'customer_name' }

const referralNumCol = { label: 'Referral #', prop: 'referral_number' }

const techComCol = { label: 'Commision', prop: 'total_tech_commission', dollar: true }
const salesrepComCol = {
  label: 'Commision',
  prop: 'total_sales_rep_commission',
  dollar: true
}
const salesourseComCol = {
  label: 'Commision',
  prop: 'total_sale_source_commission',
  dollar: true
}
const locationComCol = {
  label: 'Commision',
  prop: 'total_location_commission',
  dollar: true
}
const csrComCol = {
  label: 'Commission',
  prop: 'total_csr_commission',
  dollar: true
}
const payerCol = {
  label: 'Payer',
  prop: 'payer',
  dollar: false,
  wrap: true
}

const quoteDateColumn = {
  label: 'Quote Date',
  prop: 'quote_date'
}

const workOrderDateColumn = {
  label: 'Work Order Date',
  prop: 'work_order_date'
}

const invoiceDateColumn = {
  label: 'Invoice Date',
  prop: 'invoice_date'
}

const installDateColumn = {
  label: 'Install Date',
  prop: 'install_date'
}

const warrantyDateColumn = {
  label: 'Warranty Date',
  prop: 'warranty_date'
}

const warrantyCompleteDateColumn = {
  label: 'Warranty Complete Date',
  prop: 'warranty_complete_date'
}

const recalDateColumn = {
  label: 'Recal Date',
  prop: 'recal_date'
}

const recalCompleteDateColumn = {
  label: 'Recal Complete Date',
  prop: 'recal_complete_date'
}

const callBackDateColumn = {
  label: 'Call Back Date',
  prop: 'call_back_date'
}

const callBackCompleteDateColumn = {
  label: 'Call Back Complete Date',
  prop: 'call_back_complete_date'
}

const rAndIDateColumn = {
  label: 'R&I Date',
  prop: 'r_and_i_date'
}

const rAndICompleteDateColumn = {
  label: 'R&I Complete Date',
  prop: 'r_and_i_complete_date'
}

const tintDateColumn = {
  label: 'Tint Date',
  prop: 'tint_date'
}

const tintCompleteDateColumn = {
  label: 'Tint Complete Date',
  prop: 'tint_complete_date'
}

const pickUpDateColumn = {
  label: 'Pick Up Date',
  prop: 'pick_up_date'
}

const pickUpCompleteDateColumn = {
  label: 'Pick Up Complete Date',
  prop: 'pick_up_complete_date'
}

const dropOffDateColumn = {
  label: 'Drop Off Date',
  prop: 'drop_off_date'
}

const dropOffCompleteDateColumn = {
  label: 'Drop Off Complete Date',
  prop: 'drop_off_complete_date'
}

const voidDateColumn = {
  label: 'Void Date',
  prop: 'void_date'
}

const variableDateColumns = {
  quote_date: quoteDateColumn,
  work_order_date: workOrderDateColumn,
  invoice_date: invoiceDateColumn,
  install_date: installDateColumn,
  warranty_date: warrantyDateColumn,
  warranty_complete_date: warrantyCompleteDateColumn,
  recal_date: recalDateColumn,
  recal_complete_date: recalCompleteDateColumn,
  call_back_date: callBackDateColumn,
  call_back_complete_date: callBackCompleteDateColumn,
  r_and_i_date: rAndIDateColumn,
  r_and_i_complete_date: rAndICompleteDateColumn,
  tint_date: tintDateColumn,
  tint_complete_date: tintCompleteDateColumn,
  pick_up_date: pickUpDateColumn,
  pick_up_complete_date: pickUpCompleteDateColumn,
  drop_off_date: dropOffDateColumn,
  drop_off_complete_date: dropOffCompleteDateColumn,
  void_date: voidDateColumn
}

// associating columns with tables
export const salesTablesColumns = {
  sales: (date_type) => {
    return [
      invoiceNumCol,
      ...(variableDateColumns[date_type] ? [variableDateColumns[date_type]] : []),
      payerCol,
      referralNumCol,
      materialsCol,
      laborCol,
      subtotalCol,
      taxesCol,
      grossCol
    ]
  },
  margin: [
    invoiceNumCol,
    customerNameCol,
    materialsCol,
    laborCol,
    subtotalCol,
    costCol,
    commissionsCol,
    rebateCol,
    otherCol,
    marginCol
  ]
}

const dateColumn = { label: 'Date', prop: 'date' }

const installDefaultCols = [
  invoiceNumCol,
  customerNameCol,
  dateColumn,
  referralNumCol
]

const paymentsListColumn = {
  label: 'Payments',
  prop: 'payment_list',
  wrap: true
}

const installDateCol = {
  label: 'Ins. Date',
  prop: 'install_date'
}

const invoiceDateCol = {
  label: 'Inv. Date',
  prop: 'install_date'
}

export const installTablesColumns = {
  installers: [...installDefaultCols, techComCol],
  salesreps: [...installDefaultCols, salesrepComCol],
  salesources: [...installDefaultCols, salesourseComCol],
  locations: [...installDefaultCols, locationComCol],
  csrs: [...installDefaultCols, csrComCol],
  'tech-collections': [
    invoiceNumCol,
    customerNameCol,
    installDateCol,
    invoiceDateCol,
    referralNumCol,
    paymentsListColumn,
    techComCol
  ]
}

const timeCol = {
  label: 'Time',
  prop: 'time'
}

const partsColumn = {
  label: 'Parts',
  prop: 'part_list'
}

export const jobNotesCol = {
  label: 'Job Notes',
  prop: 'install_notes',
  wrap: true
}

export const yearMakeModelCol = {
  label: 'Year / Make / Model',
  prop: 'vehicle'
}

export const schedulerTableCols = {
  schedule: [
    invoiceNumCol,
    timeCol,
    {
      ...customerNameCol,
      wrap: true
    },
    {
      ...partsColumn,
      wrap: true 
    },
    yearMakeModelCol,
    jobNotesCol
  ]
}

const invTotalCol = {
  label: 'Invoice total',
  prop: 'total_after_taxes',
  dollar: true
}

const paymentTypeCol = {
  label: 'Payment type',
  prop: 'type'
}

const tpaCol = {
  label: 'TPA',
  prop: 'tpa'
}

const paymentAmountCol = {
  label: 'Payment amount',
  prop: 'amount',
  class: 'strong',
  dollar: true
}

const xActionTypeCol = {
  label: 'X Action Type',
  prop: 'x_action_type'
}

const xActionNumberCol = {
  label: 'X Action Number',
  prop: 'x_action_number'
}

const memoCol = {
  label: 'Memo',
  prop: 'memo',
  class: 'memo-col'
}

export const paymentCols = {
  receipt: [
    dateColumn,
    invoiceNumCol,
    invoiceDateColumn,
    invTotalCol,
    customerNameCol,
    paymentTypeCol,
    xActionTypeCol,
    xActionNumberCol,
    memoCol,
    tpaCol,
    paymentAmountCol
  ]
}
// In/Out Columns
const inWeekToDateCol = {
  label:'In<br/>week to date',
  prop: 'in_week_to_date'
}

const inYesterdayCol = {
  label: 'In Yesterday',
  prop: 'in_yesterday'
}

const outTodayCol = {
  label: 'Out Today',
  prop: 'out_today'
}

const outRestOfWeekCol = {
  label: 'Out<br/>rest of week',
  prop: 'out_rest_of_week'
}

// In/Out Export
// export const inOutCols = [inWeekToDateCol, inYesterdayCol, outTodayCol, outRestOfWeekCol]

const selectedTaxTotalCol = {
  label: 'Selected<br/>taxes total',
  prop: 'total',
  dollar: true
}

const taxTotalCol = {
  label: 'Total job<br/> taxes',
  prop: 'total_taxes',
  dollar: true
}

const grossCostCol = {
  label: 'Gross cost',
  prop: 'total_after_taxes',
  class: 'strong',
  dollar: true
}

export const taxDetailLastCols = [selectedTaxTotalCol, taxTotalCol, grossCol]

const summaryTaxTotalCol = {
  label: 'Total Tax',
  prop: 'total_taxes',
  dollar: true
}

export const taxSummaryLastCols = [summaryTaxTotalCol, grossCol]
export const totalTaxableCol = {
  label: 'Net taxable',
  prop: 'total_taxable',
  dollar: true
}

export const totalMaterialExempt = {
  label: 'Material Exempt',
  prop: 'material_exempt',
  dollar: true
}

export const totalLaborExempt = {
  label: 'Labor Exempt',
  prop: 'labor_exempt',
  dollar: true
}

export const netTaxable = {
  label: 'Net Taxable',
  prop: 'total_taxable',
  dollar: true
}

export const totalExempt = {
  label: 'Exempt Subtotal',
  class: 'strong',
  prop: 'total_exempt',
  dollar: true
}

const summarySubtotalCol = { label: 'Subtotal', prop: 'total_subtotal', dollar: true, class: 'strong' }

export const totalCollectedCol = {
  label: 'Total collected',
  prop: 'amount',
  dollar: true
}

export const totalMaterialTaxesCol = {
  label: 'Total material taxes',
  prop: 'total_materials_taxes',
  dollar: true
}

export const totalLaborTaxesCol = {
  label: 'Total labor taxes',
  prop: 'total_labor_taxes',
  dollar: true
}

export const totalTaxCollectedCol = {
  label: 'Total tax collected',
  prop: 'total_tax_collected',
  dollar: true
}

export const emptyCol = {
  label: '',
  prop: 'empty'
}

const royaltyRepairSalesCol = {
  label: 'Collected Repair Sales Volume',
  prop: 'repairSales',
  dollar: true
}

const royaltyRepairPercentageCol = {
  label: 'Repair Percent',
  id: 'repairRoyaltyPercentage',
  prop: 'royaltyPercentage',
  percent: true
}

const royaltyRepairRoyaltyCol = {
  label: 'Repair Royalty',
  prop: 'repairRoyalty',
  bold: true,
  dollar: true
}

const royaltyRepairSpacerCol = {
  id: 'repairSpacer'
}

const royaltyReplaceSalesCol = {
  label: 'Collected Replacement Sales Volume',
  id: 'replaceSales',
  prop: 'replaceSales',
  dollar: true
}

const royaltyReplacePercentageCol = {
  label: 'Replacement Percent',
  id: 'replaceRoyaltyPercentage',
  prop: 'royaltyPercentage',
  percent: true
}

const royaltyReplaceRoyaltyCol = {
  label: 'Replacement Royalty',
  id: 'replaceRoyalty',
  prop: 'replaceRoyalty',
  bold: true,
  dollar: true
}

const royaltyReplaceSpacerCol = {
  id: 'replaceSpacer'
}

const royaltyAdditionalSalesCol = {
  label: 'Collected Additional Products and Service Sales Volume',
  id: 'additionalSales',
  prop: 'additionalSales',
  dollar: true
}

const royaltyAdditionalPercentageCol = {
  label: 'Additional Products and Service Percent',
  id: 'additionalRoyaltyPercentage',
  prop: 'royaltyPercentage',
  percent: true
}

const royaltyAdditionalRoyaltyCol = {
  label: 'Additional Royalty',
  id: 'additionalRoyalty',
  prop: 'additionalRoyalty',
  bold: true,
  dollar: true
}

const royaltyAdditionalSpacerCol = {
  id: 'additionalSpacer'
}

const royaltyRepairVehiclesCol = {
  label: 'Total Number of Vehicles for Repair Service',
  id: 'repairVehicles',
  prop: 'repairVehicles',
  bold: true
}

const royaltyReplaceVehiclesCol = {
  label: 'Total Number of Vehicles for Replacement Service',
  id: 'replaceVehicles',
  prop: 'replaceVehicles',
  bold: true
}

const royaltyAdditionalInvoicesCol = {
  label: 'Invoices for Additional Products and Services',
  id: 'additionalInvoices',
  prop: 'additionalInvoices',
}

const royaltyVehicleSpacerCol = {
  id: 'vehicleSpacer'
}

const royaltySubtotalCol = {
  label: 'Subtotal of Royalties',
  id: 'royaltySubtotal',
  prop: 'royaltySubtotal',
  bold: true,
  dollar: true
}

export const taxColumns = {
  'tax-detail': [invoiceNumCol, materialsCol, laborCol, subtotalCol],
  'tax-summary': [
    materialsCol,
    laborCol,
    summarySubtotalCol,
    emptyCol,
    totalMaterialExempt,
    totalLaborExempt,
    totalExempt,
    emptyCol,
    // totalMaterialTaxesCol,
    // totalLaborTaxesCol,
    netTaxable
    // totalTaxableCol
  ]
}

export const cashBasisColumns = {
  detail: [
    invoiceNumCol,
    subtotalCol,
    totalTaxableCol,
    taxTotalCol,
    grossCostCol,
    totalCollectedCol,
    totalTaxCollectedCol
  ],
  summary: [totalCollectedCol, totalTaxCollectedCol]
}

const installListColumns = {
  'install-list': [
    invoiceNumCol,
    {
      label: 'Job Type',
      prop: 'status'
    },
    customerNameCol,
    {
      label: 'Sales Rep',
      prop: 'salesrep'
    },
    {
      label: 'Installer',
      prop: 'tech'
    },
    {
      ...payerCol,
      wrap: false
    },
    {
      label: 'Install Date',
      prop: 'date'
    }
  ]
}

export const royaltyColumns = {
  'royalty-summary': [
    royaltyRepairSalesCol,
    royaltyRepairPercentageCol,
    royaltyRepairRoyaltyCol,
    royaltyRepairSpacerCol,
    royaltyReplaceSalesCol,
    royaltyReplacePercentageCol,
    royaltyReplaceRoyaltyCol,
    royaltyReplaceSpacerCol,
    royaltyAdditionalSalesCol,
    royaltyAdditionalPercentageCol,
    royaltyAdditionalRoyaltyCol,
    royaltyAdditionalSpacerCol,
    royaltyRepairVehiclesCol,
    royaltyReplaceVehiclesCol,
    royaltyAdditionalInvoicesCol,
    royaltyVehicleSpacerCol,
    royaltySubtotalCol
  ]
}

export const reportColumns = {
  sales: salesTablesColumns,
  install: installTablesColumns,
  scheduler: schedulerTableCols,
  payment: paymentCols,
  tax: taxColumns,
  'cash-basis': cashBasisColumns,
  'install-list': installListColumns,
  royalty: royaltyColumns
}

export const csvColumns = {
  sales: {
    sales: {
      invoice_num: { label: 'Invoice #', dollar: false },
      quote_date: { label: 'Quote Date', dollar: false },
      work_order_date: { label: 'Work Order Date', dollar: false },
      invoice_date: { label: 'Invoice Date', dollar: false },
      install_date: { label: 'Install Date', dollar: false },
      warranty_date: { label: 'Warranty Date', dollar: false },
      warranty_complete_date: { label: 'Warranty Complete Date', dollar: false },
      recal_date: { label: 'Recal Date', dollar: false },
      recal_complete_date: { label: 'Recal Complete Date', dollar: false },
      call_back_date: { label: 'Call Back Date', dollar: false },
      call_back_complete_date: { label: 'Call Back Complete Date', dollar: false },
      r_and_i_date: { label: 'R&I Date', dollar: false },
      r_and_i_complete_date: { label: 'R&I Complete Date', dollar: false },
      tint_date: { label: 'Tint Date', dollar: false },
      tint_complete_date: { label: 'Tint Complete Date', dollar: false },
      void_date: { label: 'Void Date', dollar: false },
      payer: { label: 'Payer', dollar: false },
      referral_number: { label: 'Referral #', dollar: false },
      total_materials: { label: 'Materials', dollar: true },
      total_labor: { label: 'Labor', dollar: true },
      total_subtotal: { label: 'Subtotal', dollar: true },
      total_taxes: { label: 'Sales Tax', dollar: true },
      total_after_taxes: { label: 'Gross Sales', dollar: true }
    },
    margin: {
      invoice_num: { label: 'Invoice #', dollar: false },
      customer_name: { label: 'Customer Name', dollar: false },
      total_materials: { label: 'Materials', dollar: true },
      total_labor: { label: 'Labor', dollar: true },
      total_subtotal: { label: 'Subtotal', dollar: true },
      total_cost: { label: 'Part Cost', dollar: true },
      total_commission: { label: 'Commissions', dollar: true },
      customer_rebate: { label: 'Rebate', dollar: true },
      other_costs: { label: 'Other', dollar: true },
      total_margin: { label: 'Margin', dollar: true }
    }
  },
  payment: {
    receipt: {
      date: { label: 'Date', dollar: false },
      invoice_num: { label: 'Invoice #', dollar: false },
      invoice_date: { label: 'Invoice Date', dollar: false },
      total_after_taxes: { label: 'Invoice Total', dollar: true },
      customer_name: { label: 'Customer Name', dollar: false },
      type: { label: 'Payment Type', dollar: false },
      x_action_type: { label: 'X Action Type', dollar: false },
      x_action_number: { label: 'X Action Number', dollar: false },
      memo: { label: 'Memo', dollar: false },
      tpa: { label: 'TPA', dollar: false },
      amount: { label: 'Payment Amount', dollar: true }
    }
  },
  'install-list': {
    'install-list': {
      invoice_num: { label: 'Invoice #', dollar: false },
      status: { label: 'Job Type', dollar: false },
      customer_name: { label: 'Customer Name', dollar: false },
      salesrep: { label: 'Sales Rep', dollar: false},
      tech: { label: 'Installer', dollar: false },
      payer: { label: 'Payer', dollar: false },
      date: { label: 'Install Date', dollar: false }
    }
  }
}
