import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default class CSR extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  name = ''
  commission = ''
  address = ''
  city = ''
  state = ''
  zip = ''
  phone = ''
  fax = ''
  email = ''
  notes = ''
  modified = -1
  shop_id = -1
  commission_flat_or_percent = 'flat'
  chip_commission = ''
  chip_commission_flat_or_percent = 'flat'

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, callbackFunction) {
    Api({ url: `/api/shop/${shopId}/csr` }).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new CSR(val))
      })
      callbackFunction(objects)
    })
  }

  // GET ALL CSRS BY USER + FILTERS !
  static getCsrsByUserShops (page, limit, sortBy, sortDesc, csrIdFilter, csrNameFilter,
    csrShopNameFilter, csrEmailFilter, csrPhoneFilter, successCallback, errorCallback) {
    Api({url: `/api/usercsrs?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&csrIdFilter=' + encodeURIComponent(csrIdFilter) + '&csrNameFilter=' + encodeURIComponent(csrNameFilter) +
      '&csrShopNameFilter=' + encodeURIComponent(csrShopNameFilter) + '&csrEmailFilter=' + encodeURIComponent(csrEmailFilter) +
      '&csrPhoneFilter=' + encodeURIComponent(csrPhoneFilter)
    }).then(res => {
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  createUpdateCSR (shopId, callbackFunction, errorCallbackFunction = null) {
    this.commission = UtilityMixin.methods.formatFloatDecimals(this.commission, 2, 2)
    this.chip_commission = UtilityMixin.methods.formatFloatDecimals(this.chip_commission, 2, 2)
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    return Api.post(`/api/shop/${this.shop_id}/csr/${this.id}`, this).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
