<template>
  <div class="delivery-instructions-input" v-loading="extraLoading">
    <input-with-label
      size="normal"
      label="Delivery instructions"
      v-model="extra"
      @blur="$emit('blur')"
      :options="{ autosize: { minRows: 2 } }"
      ref="extra"
      type="textarea"
      :auto-focus="autoFocus"
    />

    <el-tooltip
      content="Look up cross streets"
      :tabindex="-1"
      v-if="address && address.google_place_id"
    >
      <div class="cross-street-lookup" @click="getIntersection">
        <i class="material-icons md-search md-18" />
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import { getNearestIntersection } from '@/scripts/helpers/map.helpers'
export default {
  data() {
    return {
      extraLoading: false
    }
  },
  props: { value: {}, address: {}, autoFocus: Boolean },
  computed: {
    extra: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    focusExtra() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.extra?.$refs?.inputRef?.focus()
    },
    async getIntersection() {
      this.focusExtra()
      this.extraLoading = true
      try {
        this.extra = await getNearestIntersection(this.address)
        this.$emit('change')
      } finally {
        this.extraLoading = false
      }
    }
  }
}
</script>
<style lang="scss">
.delivery-instructions-input {
  position: relative;
  .cross-street-lookup {
    position: absolute;
    right: $padding-xs;
    top: $padding-xs;
    line-height: 18px;
    height: 18px;
    cursor: pointer;
    .material-icons {
      color: #c0c4cc !important;
    }
    position: absolute;
    &:focus,
    &:hover {
      outline: none;
      .material-icons {
        color: $sky-blue !important;
      }
    }
  }
}
</style>
