<template>
  <div>
    <el-tabs v-model="selectedVendor" tab-position="left" class="t-pl-6">
      <el-tab-pane
        v-for="v in vendors"
        :key="v"
        :name="v.toLowerCase()"
        stretch>
        <span slot="label">{{ v }}</span>
        <div
          v-loading="ordersFetching"
          v-if="v.toLowerCase() === selectedVendor"
          class="t-pl-2"
          style="min-height: 150px; max-height: 500px; overflow-y: auto;">
          <div v-if="!ordersFetching">
            <div v-if="orders.length === 0" class="t-text-center t-mt-4">
              You don't have any orders yet
            </div>
            <div v-else class="t-pl-2 t-pr-6">
              <div v-if="v.toLowerCase() === 'pilkington'">
                <pilkington-order
                  v-for="order in orders"
                  :key="order.id"
                  :order="order"
                  :supplierColor="getSupplierColor(order.supplierId)"
                />
                <pilkington-order-details />
              </div>
              <div v-else-if="v.toLowerCase() === 'american'">
                <american-order
                  v-for="order in orders"
                  :key="order.id"
                  :order="order"
                  :supplierColor="getSupplierColor(order.supplierId)"
                />
                <american-order-details />
              </div>
              <div v-else-if="['agd', 'nm'].includes(v.toLowerCase())">
                <nielsen-moller-order
                  v-for="order in orders"
                  :key="order.id"
                  :order="order"
                  :supplierColor="getSupplierColor(order.supplierId)"
                />
                <nielsen-moller-order-details />
              </div>
              <div v-else>
                <glaxis-order
                  v-for="order in orders"
                  :key="order.id"
                  :order="order"
                  :supplierColor="getSupplierColor(order.supplierId)"
                />
                <glaxis-order-details />
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="t-pl-10 t-pr-6 t-py-6 t-bg-gray-100"></div>
  </div>
</template>

<script>
import {
  shoppingCartActions,
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'
import GlaxisOrder from './GlaxisOrder'
import GlaxisOrderDetails from './GlaxisOrderDetails'
import PilkingtonOrder from './PilkingtonOrder'
import PilkingtonOrderDetails from './PilkingtonOrderDetails'
import AmericanOrder from './AmericanOrder'
import AmericanOrderDetails from './AmericanOrderDetails'
import NielsenMollerOrder from './NielsenMollerOrder'
import NielsenMollerOrderDetails from './NielsenMollerOrderDetails'

export default {
  components: {
    GlaxisOrder,
    GlaxisOrderDetails,
    PilkingtonOrder,
    PilkingtonOrderDetails,
    AmericanOrder,
    AmericanOrderDetails,
    NielsenMollerOrder,
    NielsenMollerOrderDetails
  },

  props: {
    suppliers: Array
  },

  data() {
    return {
      vendors: [
        'Mygrant',
        'IGC',
        'PGW',
        'Pilkington',
        // 'American',
        'NM',
        'AGD'
      ],
      selectedPOId: null,
      selectedShop: null
    }
  },

  computed: {
    orders() {
      return this.$store.state.shoppingCart.orders.data
    },
    ordersFetching() {
      return this.$store.state.shoppingCart.orders.ordersFetching
    },
    selectedVendor: {
      get() {
        return this.$store.state.shoppingCart.orders.selectedVendor
      },
      set(value) {
        return this.$store.commit(
          shoppingCartMutations.orders.setSelectedVendor,
          value
        )
      }
    },
    shopId() {
      return this.$store.state.shoppingCart.shopId
    }
  },

  watch: {
    selectedVendor: function (value) {
      this.$store.commit(shoppingCartMutations.orders.setOrdersItems, null)
      this.getOrders()
    }
  },

  methods: {
    getOrders() {
      return this.$store.dispatch(shoppingCartActions.getOrders)
    },

    getSupplierColor(supplierId) {
      const supplier = this.suppliers.find(s => s.id === supplierId)
      return supplier ? supplier.color : null
    }
  }
}
</script>
