<template>
  <div>
    <el-autocomplete
      placeholder="Enter address"
      :fetch-suggestions="fetchSuggestions"
      v-model="value"
      :class="{ full }"
      @select="handleSelect"
      :popper-append-to-body="false"
      :trigger-on-focus="false"
    >
      <template slot-scope="{ item }">
        <places-autocomplete-block :result="item.structured_formatting" />
      </template>
    </el-autocomplete>
    <gmap-autocomplete v-show="false" />
  </div>
</template>

<script>
import {
  getPlacesPrediction,
  getStateBounds
} from '@/scripts/helpers/map.helpers'
import PlacesAutocompleteBlock from './PlacesAutocompleteBlock'

export default {
  data() {
    return {
      suggestions: [],
      value: ''
    }
  },
  components: { PlacesAutocompleteBlock },
  props: ['state', 'full', 'initialValue'],
  methods: {
    async fetchSuggestions(input, cb) {
      if (!this.bounds && this.state) {
        if (this.state) {
          this.bounds = await getStateBounds(this.state)
        }
      }
      if (input) {
        const data = await getPlacesPrediction(input, this.bounds)
        cb(data)
      }
    },
    handleSelect(value, event) {
      this.$emit('place-selected', value)
    }
  },
  mounted() {
    this.value = this.initialValue || ''
  }
}
</script>

<style lang="scss" scoped>
.full {
  width: 100%;
}
</style>
