var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "addEditVendorDialog",
            title: _vm.amEditing ? "Edit Supplier" : "Create Supplier",
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "xl"
          },
          on: { show: _vm.dialogShown },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _vm.saving
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "loader-tech",
                          attrs: { name: "spinner", spin: "" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.amEditing
                                ? "Editing Supplier"
                                : "Saving New Supplier"
                            )
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { disabled: _vm.saving, variant: "dark" },
                      on: { click: _vm.cancelButton }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { disabled: _vm.saving, variant: "dark" },
                      on: { click: _vm.saveButton }
                    },
                    [_vm._v(" Save ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("VendorEdit", {
            attrs: {
              shop: _vm.shop,
              parentVendor: _vm.localVendor,
              inModal: true
            }
          })
        ],
        1
      ),
      _c("VendorSearchDialog", {
        on: {
          selectClicked: _vm.selectedNationalVendor,
          close: _vm.closeNationalVendor
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }