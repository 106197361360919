<template>
  <div>
    <div class='input-group mt-1 mt-lg-0' v-if='type === "phone"'>
      <Phone :isGroupInput='true' :value.sync='inputValue' :isSaveNotFormatted='true'/>
      <div class='input-group-append append-delete'>
        <button class='btn btn-gray btn-sm' type='button' @click='removeItem' aria-haspopup="true" aria-expanded="false"><span>X</span></button>
      </div>
    </div>
    <div class='input-group mt-1 mt-lg-0' v-if='type === "email"'>
      <Email :isGroupInput='true' :value.sync='inputValue' />
      <div class='input-group-append append-delete'>
        <button class='btn btn-gray btn-sm' type='button' @click='removeItem' aria-haspopup="true" aria-expanded="false"><span>X</span></button>
      </div>
    </div>
  </div>
</template>

<script>
import Phone from '@/components/Helpers/Phone.vue'
import Email from '@/components/Helpers/Email.vue'

export default {
  props: ['parentValue', 'type'],
  data () {
    return {
      inputType: this.type ? this.type : 'phone'
    }
  },
  methods: {
    removeItem () {
      this.$emit('remove')
    }
  },
  components: {
    Phone,
    Email
  },
  watch: {
  },
  computed: {
    inputValue: {
      get () {
        return this.parentValue
      },
      set (val) {
        this.$emit('update:parentValue', val)
      }
    }
  }
}
</script>

<style scoped>
.append-delete {
  z-index: 0 !important;
}
</style>
