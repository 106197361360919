var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-divider", { staticClass: "t-my-6" }),
      _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
        _vm._v("Default Sales Rep")
      ]),
      _c(
        "div",
        [
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                placeholder: "Select Default Sales Rep"
              },
              on: { change: _vm.updateDefaultSalesRepHandler },
              model: {
                value: _vm.default_salesrep_id,
                callback: function($$v) {
                  _vm.default_salesrep_id = $$v
                },
                expression: "default_salesrep_id"
              }
            },
            _vm._l(_vm.sales_reps, function(i) {
              return _c("el-option", {
                key: i.id,
                attrs: { label: i.name, value: i.id }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }