var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c("div", { staticClass: "t-flex t-items-baseline t-mb-2" }, [
        _c(
          "div",
          { staticClass: "t-w-48 t-text-right t-text-gray-500 t-text-sm" },
          [_vm._v(" " + _vm._s(_vm.name) + " ")]
        ),
        _c("div", { staticClass: "t-text-gray-700 t-ml-4" }, [
          _vm._v(" " + _vm._s(_vm.value) + " ")
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }