var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return true
    ? _c(
        "div",
        [_c("el-button", { on: { click: _vm.refresh } }, [_vm._v("Refresh")])],
        1
      )
    : _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticClass: "scheduler-route-modal",
              attrs: {
                title: "Calculate optimized route",
                visible: _vm.dialogVisible,
                width: "90%",
                "modal-append-to-body": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ]
                },
                [
                  _c("tech-select", {
                    model: {
                      value: _vm.selectedTechs,
                      callback: function($$v) {
                        _vm.selectedTechs = $$v
                      },
                      expression: "selectedTechs"
                    }
                  }),
                  _c("tech-table", {
                    attrs: { data: _vm.selectedTechs },
                    on: {
                      "row-change": _vm.updateRows,
                      "row-remove": _vm.removeRow
                    }
                  }),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("routing-configuration", {
                            attrs: {
                              title: "Routing options",
                              config: _vm.routingOptionsTable
                            },
                            model: {
                              value: _vm.options,
                              callback: function($$v) {
                                _vm.options = $$v
                              },
                              expression: "options"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("routing-configuration", {
                            attrs: {
                              title: "Configuration",
                              config: _vm.routingConfigTable
                            },
                            model: {
                              value: _vm.config,
                              callback: function($$v) {
                                _vm.config = $$v
                              },
                              expression: "config"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.unmapped.length
                    ? _c("el-alert", {
                        attrs: {
                          title:
                            "You have " +
                            _vm.unmapped.length +
                            " jobs with invalid or missing address",
                          description: _vm.unmapped
                            .map(function(ref) {
                              var job = ref.job

                              return (
                                job &&
                                "#" + job.id + " (" + job.customer_name + ")"
                              )
                            })
                            .join(" • "),
                          type: "warning"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: !_vm.selectedTechs.length
                      },
                      on: { click: _vm.onSubmit }
                    },
                    [_vm._v(" Submit ")]
                  )
                ],
                1
              )
            ]
          ),
          _vm.isDayView || _vm.isBayView || _vm.isInPast
            ? _c(
                "el-button",
                { attrs: { disabled: "", size: "large", type: "primary" } },
                [_vm._v(" Calculate routes ")]
              )
            : _c(
                "el-badge",
                {
                  attrs: {
                    "is-dot": "",
                    type: "warning",
                    hidden: !_vm.modified
                  }
                },
                [
                  _c(
                    "el-dropdown",
                    {
                      attrs: { "split-button": "", type: "primary" },
                      on: { click: _vm.onClick, command: _vm.onCommand }
                    },
                    [
                      _vm._v(" Calculate routes "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                disabled: !_vm.modified,
                                command: "refresh"
                              }
                            },
                            [_vm._v(" Refresh routes ")]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "clear" } },
                            [
                              _c("span", { staticClass: "danger" }, [
                                _vm._v("Clear routes")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }