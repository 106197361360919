var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-h-full" }, [
    _vm.isOpenning
      ? _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isOpenning,
              expression: "isOpenning"
            }
          ],
          staticClass: "t-h-full"
        })
      : _c(
          "div",
          { staticClass: "t-flex t-h-full" },
          [
            _c(
              "div",
              { staticClass: "t-flex t-flex-col t-flex-grow t-h-full" },
              [_c("Header"), _c("Body"), _c("Controls")],
              1
            ),
            _c("InfoDrawer"),
            _c("AddUsersDialog"),
            _c("MembersDialog")
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }