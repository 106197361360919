export const glassbillerSecondaryOptions = [
  'Admin',
  'AR/AP',
  'Dashboard',
  'Fax Inbox',
  'Job Page',
  'Jobs List',
  'Log in issues',
  'Mobile Inbox',
  'Other',
  'Quote Dialog',
  'Rejected Dialog',
  'Reports',
  'Scheduler',
  'Shop Settings',
  'SMS Dialog',
  'User Settings',
  'Warehouse',
]

export const techsideNativeSecondaryOptions = [
  'Connecting with shop/organization',
  'Emailing',
  'Log in issues',
  'Not seeing jobs',
  'Other',
  'Uploading images',
]

export const techsideWebSecondaryOptions = [
  'Connecting with shop/organization',
  'Emailing',
  'Log in issues',
  'Not seeing jobs',
  'Other',
  'Uploading images',
]

const colors = {
  ["Closed"]: '#54a955',
  ["Escalated"]: '#ff7f49',
  ["On Hold"]: '#ff7f49',
  ["Open"]: '#299fd7'
}

export const statusColor = (status) => {
  return colors[status] || '#ff7f49'  
}