<template>
  <div
    class="bay-job-card-list"
    :class="{
      'row-view': isRowView
    }"
  >
    <div class="bay-job-list-card-header">
      <div class="header-text">
        <el-tag
          :style="{
            ...cardStyle(null, color),
            border: 'none',
            margin: 0,
            width: '100%'
          }"
          >{{ name }}</el-tag
        >
      </div>
    </div>
    <draggable
      v-model="jobList"
      class="job-card-list-container"
      :options="draggableOptions"
    >
      <div
        v-for="item in jobList"
        :key="item.id"
        class="card-wrapper"
        @dragstart="itemDrag(item)"
        @dragend="itemDrag()"
      >
        <scheduler-job-card
          v-if="item.shop_name"
          :job="item"
          :color="color"
          :routeEntry="{}"
          :cardStyle="cardStyle(item)"
        />
      </div>
    </draggable>
  </div>
</template>

<script>
import SchedulerJobCard from '../SchedulerJobCard'
import Draggable from 'vuedraggable'
import { checkDraggingTech } from '@/scripts/helpers/scheduler_v2.helpers'
import { isMobile } from '@/scripts/helpers/helpers'
import { get, call } from 'vuex-pathify'
import { getCardTextColor } from '../../../scripts/helpers/scheduler.helpers'
import actions from '@/store/modules/scheduler_v3/types'

export default {
  props: ['id', 'color', 'items', 'name'],
  components: {
    SchedulerJobCard,
    Draggable
  },
  computed: {
    isRowView: get('sch_v3/isRowView'),
    isSolidCardView: get('sch_v3/isSolidCardView'),
    jobList: {
      get() {
        return this.items
      },
      set(items) {
        this.updateBayItems({ items, bay_id: this.id })
      }
    },
    draggableOptions() {
      return {
        group: {
          name: 'default'
        },
        chosenClass: 'dragging',
        ...(isMobile() ? { delay: 300 } : {})
      }
    },
    cardStyle() {
      return function(item, c) {
        const color = c || this.getColor(item)
        return c || this.isSolidCardView
          ? {
              color: getCardTextColor(color),
              background: color
            }
          : {}
      }
    }
  },
  methods: {
    // getTechInfo: call(actions.getTechInfo),
    updateBayItems: call(actions.updateBayItems),
    async setTechModal(tech) {
      this.openingTechDialog = true
      await this.getTechInfo(tech)
      this.openingTechDialog = false
      this.$root.$emit('bv::show::modal', 'techModal')
    },
    itemDrag(item) {
      this.$root.$emit('dragging-item', item)
    },
    getColor(item) {
      return item.tech_data
        ? item.tech_data.color
          ? item.tech_data.color
          : '#607d8b'
        : '#90A4AE'
    }
  },
  mounted() {
    this.$root.$on('dragging-item', item => {
      this.enabled = checkDraggingTech(item, this.tech)
    })
  },
  beforeDestroy() {
    this.$root.$off('dragging-item')
  }
}
</script>

<style lang="scss" scoped>
@import './styles/BayJobCardList.scss';
</style>
