var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.localVisible,
        width: "1200px",
        modalAppendToBody: false,
        "append-to-body": "",
        "custom-class": "consumer-jobs-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.localVisible = $event
        },
        open: _vm.open,
        close: _vm.close
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-xl t-text-gray-800",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("Drafts")]
      ),
      _vm.showTable
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.handleScroll,
                  expression: "handleScroll"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "drafts-table",
              attrs: {
                "infinite-scroll-disabled": _vm.done,
                "infinite-scroll-distance": 0,
                data: _vm.drafts
              },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _vm.canDiscard
                ? _c("el-table-column", { attrs: { type: "selection" } })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { prop: "id", label: "Id", width: "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "a",
                            {
                              staticStyle: { "text-decoration": "none" },
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.openDraft(scope.row.id)
                                }
                              }
                            },
                            [_vm._v(_vm._s(scope.row.id))]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3797415709
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "Status", width: "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getReadableStatus(scope.row.status))
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1090295177
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "consumer", label: "Customer", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getConsumerName(scope.row.consumer))
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  166638230
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "vehicle", label: "Vehicle" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getVehicleString(scope.row.vehicle))
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3593991332
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "referral_number",
                  label: "Referral #",
                  width: "100"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.referral_number))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2229535107
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "purchase_order_number",
                  label: "PO #",
                  width: "100"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.purchase_order_number))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  100111136
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _vm.canDiscard && _vm.multipleSelection.length
            ? _c(
                "el-button",
                {
                  staticStyle: { float: "left" },
                  attrs: { type: "danger" },
                  on: { click: _vm.discardSelectedDrafts }
                },
                [_vm._v(" Discard ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }