import gql from 'graphql-tag'

export const JOB_GB_VENDORS_FRAGMENT = gql`
  fragment JobGBVendorsFields on JobGbVendor {
      id
      job_id
      gb_vendor_id
      name
      gb_vendor_type_id
      commission
      commission_flat_or_percent
      commission_total
    }
    `
