var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "claimDamageInformationArea" },
    [
      _c("p", [_vm._v("Damage Information")]),
      _vm._l(_vm.damageInformationArray, function(info, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("InfoCard", {
              attrs: {
                schema: _vm.schema,
                dataObject: _vm.damageInformationArray[index],
                deleteButtonMethod:
                  !_vm.fieldsDisabled && index != 0
                    ? _vm.removeDamageInformation
                    : null,
                deleteIndex: index,
                hideHeader: true
              },
              on: {
                "update:dataObject": function($event) {
                  return _vm.$set(_vm.damageInformationArray, index, $event)
                },
                "update:data-object": function($event) {
                  return _vm.$set(_vm.damageInformationArray, index, $event)
                }
              }
            })
          ],
          1
        )
      }),
      !_vm.fieldsDisabled
        ? _c(
            "el-button",
            {
              attrs: { size: "mini", icon: "las la-download", plain: "" },
              on: { click: _vm.addMoreDamageInformation }
            },
            [_vm._v(" + Add More Damage Information ")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }