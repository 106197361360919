<template>
  <div class="t-flex">
    <Button
      type="nm"
      :loading="false"
      total="*"
      price="new"
      :adjustedPrice="null"
      @clicked="open = true"
    />
    <nielsen-moller-alert-dialog
      :visible.sync='open'
      :shopId="shopId"
      :syncShopId="false"
    />
  </div>
</template>

<script>
import Button from './Button.vue'
import NielsenMollerAlertDialog from '@/components/modals/NielsenMollerAlertDialog.vue'

export default {
  components: {
    Button,
    NielsenMollerAlertDialog
  },
  props: ['shopId'],
  data: () => ({
    open: false
  })
}
</script>

<style lang="scss" scoped>
.grow-enter-active {
  transition: all 0.2s ease;
}

.grow-enter {
  font-size: 0;
  margin-left: 0;
}
</style>
