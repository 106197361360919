<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <defs>
      <circle id="my-location-a" cx="330" cy="366" r="8" />
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(-316 -352)">
      <use :fill="color" xlink:href="#my-location-a"/>
      <circle cx="330" cy="366" r="11" :stroke="color" stroke-opacity="0.3" stroke-width="6" />
      <circle cx="330" cy="366" r="7" stroke="#FFF" stroke-linejoin="square" stroke-width="2" />
    </g>
  </svg>
</template>

<script>
export default {
  props: ['color']
}
</script>
