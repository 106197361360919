<template>
  <el-table
    border
    size="mini"
    empty-text="No devices are connected"
    :data="devices"
  >
    <el-table-column prop="name" label="Name" />
    <el-table-column prop="product_name" label="Device Type" />
    <el-table-column label="Actions" width="175">
      <template slot-scope="scope">
        <div class="t-flex">
          <el-tooltip
            content="Delete"
            placement="top"
            :open-delay="300"
          >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              :disabled="disabled"
              @click="handleDelete(scope.row)"
            />
          </el-tooltip>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
// import { disconnectDevice } 
export default {
  props: ['devices'],
  methods: {
    async handleDelete (device) {

    }
  }
}
</script>

<style>

</style>