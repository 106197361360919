var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-wrapper d-flex align-items-center" },
    [
      _c(
        "div",
        { staticClass: "notification-wrapper-content d-flex flex-column" },
        [
          _c("notification", { attrs: { data: _vm.item } }),
          !_vm.item.is_read
            ? _c(
                "el-tooltip",
                {
                  staticClass: "box-item",
                  attrs: {
                    effect: "light",
                    content: "Archive notification",
                    placement: "top"
                  }
                },
                [
                  _c("el-button", {
                    staticClass: "button-read",
                    attrs: { icon: "el-icon-check" },
                    on: {
                      click: function($event) {
                        return _vm.readOne(_vm.item)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }