import { make } from 'vuex-pathify'
import Api from '@/services/Api'
import Ediqueue from '@/scripts/objects/ediqueue'
import Vue from 'vue'

const getDefaultState = () => ({
  visible: false,
  tab: 'rejected',
  loading: false,
  selectedShop: null,
  shopTags: [],
  totalRows: 0,
  data: [],
  table: {
    page: 1,
    limit: 20,
    sortBy: 'id',
    sortDesc: true,
    numberOfRows: 0,
    filters: {
      id: '',
      referral: '',
      customerName: '',
      amount: '',
      tradingPartner: '',
      includeVoid: false
    },
  },
  timer: null,
  ediData: null,
  ediDataVisible: false
})

const state = getDefaultState()

const mutations = {
  ...make.mutations(state),
  RESET_STATE (state) {
    Object.assign(state, {...getDefaultState(), visible: state.visible})
  },
  RESET_DATA (state) {
    Object.assign(state, {...state, table: getDefaultState().table})
  },
  SET_PAGE (state, payload) {
    state.table.page = payload
  },
  SET_SORT_BY (state, payload) {
    state.table.sortBy = payload
  },
  SET_SORT_DESC (state, payload) {
    state.table.sortDesc = payload === 'descending'
  },
  RESET_DATA (state) {
    Object.assign(state, {...state, data: [], totalRows: 0})
  },
  SET_FILTERS_id (state, payload) {
    state.filters.id = payload
  },
  SET_FILTERS_referral (state, payload) {
    state.filters.referral = payload
  },
  SET_FILTERS_customerName (state, payload) {
    state.filters.customerName = payload
  },
  SET_FILTERS_amount (state, payload) {
    state.filters.amount = payload
  },
  SET_FILTERS_tradingPartner (state, payload) {
    state.filters.tradingPartner = payload
  },
  DELETE_TAG (state, {job_id, job_tag_id}) {
    for (var i = 0; i < state.data.length; i++) {
      if (state.data[i].id === job_id) {
        const jobData = state.data[i]
        const tags = jobData.tags
        for (var j = 0; j < tags.length; j++) {
          if (tags[j].id === job_tag_id) {
            tags.splice(j,1)
            Vue.set(state.data[i], 'tags', [...tags])
            break
          }
        }
        break
      }
    }
  },
  UPDATE_TAGS (state, {id, tags}) {
    const tagsToAdd = []
    let job = null
    for (var i = 0; i < state.data.length; i++) {
      if (state.data[i].id === id) {
        job = i
        if (state.data[i].tags) {
          for (var k = 0; k < tags.length; k++) {
            let found = false
            for (var l = 0; l < state.data[i].tags.length; l++) {
              if (parseInt(state.data[i].tags[l].id) === tags[k].id) {
                found = true
                break
              }
            }
            if (!found) {
              tagsToAdd.push(tags[k])
            }
          }
        }
      }
    }
    if (tagsToAdd.length) {
      const serializedTags = []
      for (var v = 0; v < tagsToAdd.length; v++) {
        for (var j = 0; j < state.shopTags.length; j++) {
          if (state.shopTags[j].id === tagsToAdd[v].shop_tag_id ) {
            serializedTags.push({
              id: tagsToAdd[v].id,
              color: state.shopTags[j].color,
              shop_tag_id: state.shopTags[j].id,
              text: state.shopTags[j].text
            })
            break;
          }
        }
      }
      if (job !== null && serializedTags.length) {
        if (!state.data[job].tags) state.data[job].tags = []
        Vue.set(state.data[job], 'tags', [...state.data[job].tags, ...serializedTags])
      }
      
    }
  }
}
const getters = {
  color: (state) => {
    if (state.tab === 'rejected') {
      return '#dc3545'
    } else if (state.tab === 'queued') {
      return '#26af5d'
    }
  }
}

const actions = {
  open ({ commit }, shopDefault) {
    commit('SET_TAB', 'rejected')
    commit('SET_VISIBLE', true)
  },
  close ({ commit }) {
    commit('SET_VISIBLE', true)
  },
  openEdiData({ commit }, ediData) {
    commit('SET_EDI_DATA', ediData)
    commit('SET_EDI_DATA_VISIBLE', true)
  },
  async changeTab ({ commit, dispatch }, newTab) {
    commit('SET_TAB', newTab)
    dispatch('getData')
  },
  async changeShop ({ commit, dispatch }, payload) {
    dispatch('resetState')
    commit('SET_SELECTED_SHOP', payload)
    dispatch('getShopTags')
    dispatch('getData')
  },
  async changeSort ({ commit, dispatch }, { prop, order }) {
    console.log('resetting state')
    dispatch('resetData')
    commit('SET_SORT_BY', prop || 'id')
    commit('SET_SORT_DESC', order || '')
    dispatch('getData')
  },
  async updatedFilters ({ commit, dispatch }) {
    if (state.timer) clearTimeout(state.timer)
    commit('SET_TIMER', setTimeout(() => {
      console.log('dispatch get data')
      dispatch('getData')
    }, 700))
  },
  async getShopTags ({ commit }) {
    const {data} = await Api(`/api/shop/${state.selectedShop.id}/tag`)
    commit('SET_SHOP_TAGS', data)
  },
  async deleteJobTag ({ commit }, {job_id, job_tag_id}) {
    const {data} = await Api.delete(`/api/shop/${state.selectedShop.id}/job/${job_id}/jobtag/${job_tag_id}`)
    if (data.success) {
      commit('DELETE_TAG', {job_id, job_tag_id})
    }
  },
  async addJobTag ({ commit }, {id, jobtags}) {
    console.log('adding job tag')
    const {data} = await Api.post(`/api/shop/${state.selectedShop.id}/job/${id}/jobtag`, jobtags)
    if (data && !data.error) {
      commit('UPDATE_TAGS', {id, tags: data})
    }
  },
  async getData ({commit}) {
    if (state.selectedShop) {
      // commit('RESET_DATA')
      commit('RESET_DATA')
      if (state.tab === 'rejected') {
        commit('SET_LOADING', true)
        console.log(state.selectedShop)

        if (state.selectedShop.shop_services_config?.consumer === 1) {
          // console.log('go get the data from the consumer service')
          const {data} = await this._vm.$unum.job().post(`jobslist/rejected-dialog/rejected`, { shop: state.selectedShop, ...state.table})
          commit('SET_LOADING', false)
          commit('SET_DATA', data?.rows || [])
          commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
        } else {
          const {data} = await Ediqueue.rejectedEdis_v2({ shop: state.selectedShop, ...state.table})
          commit('SET_LOADING', false)
          commit('SET_DATA', data?.rows || [])
          commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
        }
      } else if (state.tab === 'queued') {
        commit('SET_LOADING', true)
        if (state.selectedShop.shop_services_config?.consumer === 1) {
          const {data} = await this._vm.$unum.job().post(`jobslist/rejected-dialog/queued`, { shop: state.selectedShop, ...state.table})
          commit('SET_LOADING', false)
          commit('SET_DATA', data?.rows || [])
          commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
        } else {
          const {data} = await Ediqueue.queuedEdis_v2({ shop: state.selectedShop, ...state.table})
          commit('SET_LOADING', false)
          commit('SET_DATA', data?.rows || [])
          commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
        }
      } else if (state.tab === 'preparing') {
        const {data} = await Ediqueue.getEdisAndJobsDetailsByStatus_v2({ ...state.table, status: 'preparing'})
        commit('SET_LOADING', false)
        commit('SET_DATA', data?.rows || [])
        commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
      } else if (state.tab === 'pending') {
        const {data} = await Ediqueue.getEdisAndJobsDetailsByStatus_v2({ ...state.table, status: 'pending'})
        commit('SET_LOADING', false)
        commit('SET_DATA', data?.rows || [])
        commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
      } else if (state.tab === 'failed') {
        const {data} = await Ediqueue.getEdisAndJobsDetailsByStatus_v2({ ...state.table, status: 'failed'})
        commit('SET_LOADING', false)
        commit('SET_DATA', data?.rows || [])
        commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
      }
    }
  },
  async getDataNextSet ({commit}) {
    commit('SET_PAGE', state.table.page + 1)
    if (state.selectedShop) {
      if (state.tab === 'rejected') {
        console.log(state.selectedShop)
        if (state.selectedShop.shop_services_config?.consumer) {
          const {data} = await this._vm.$unum.job().post(`jobslist/rejected-dialog/rejected`, { shop: state.selectedShop, ...state.table})
          commit('SET_LOADING', false)
          commit('SET_DATA', data?.rows || [])
          commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
        } else {
          const {data} = await Ediqueue.rejectedEdis_v2({ shop: state.selectedShop, ...state.table})
          commit('SET_DATA', [...state.data, ...data?.rows || []])
        }
      } else if (state.tab === 'queued') {
        if (state.selectedShop.shop_services_config?.consumer) {
          const {data} = await this._vm.$unum.job().post(`jobslist/rejected-dialog/rejected`, { shop: state.selectedShop, ...state.table})
          commit('SET_LOADING', false)
          commit('SET_DATA', data?.rows || [])
          commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
        } else {
          const {data} = await Ediqueue.queuedEdis_v2({ shop: state.selectedShop, ...state.table})
          commit('SET_DATA', [...state.data,data?.rows || []])
        }
      } else if (state.tab === 'preparing') {
        const {data} = await Ediqueue.getEdisAndJobsDetailsByStatus_v2({ ...state.table, status: 'preparing'})
        commit('SET_DATA', [...state.data,data?.rows || []])
      } else if (state.tab === 'pending') {
        const {data} = await Ediqueue.getEdisAndJobsDetailsByStatus_v2({ ...state.table, status: 'pending'})
        commit('SET_DATA', [...state.data,data?.rows || []])
      } else if (state.tab === 'failed') {
        const {data} = await Ediqueue.getEdisAndJobsDetailsByStatus_v2({ ...state.table, status: 'failed'})
        commit('SET_DATA', [...state.data,data?.rows || []])
      }
    }
  },
  resetState ({commit}) {
    commit('RESET_STATE')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}