<template>
  <el-select
    v-model='lValue'
    value-key="serial"
    placeholder="Choose device"
    size="mini"
    class="t-mr-2"
  >
    <el-option
      v-for="device in options"
      :key="device.serial"
      :value="device"
      :label="device.name"
    >
      {{device.name}}: {{device.product_name}}
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    value: Object,
    options: Array
  },
  computed: {
    lValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('update:value', v)
      }
    }
  }
}
</script>
