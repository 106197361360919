<template>
  <div class='pdf-option-card-list'>
    <div class='pdf-options-list-header'>
      <div class='header-text'>Column {{column}}</div>
    </div>
    <draggable :options="draggableOptions" v-model="optionsList" class="pdf-options-list-container">
      <div
        v-for='option in optionsList'
        :key="option.id"
        class='card-wrapper'
        @dragstart="optionDrag(option)"
        @dragend="optionDrag()"
      >
        <PdfOptionCard
         :option='option'
        />
      </div>
    </draggable>
  </div>
</template>

<script>
import PdfOptionCard from './PdfOptionCard'
import Draggable from 'vuedraggable'
import { isMobile } from '@/scripts/helpers/helpers'

export default {
  props: ['optionItems', 'column'],
  components: {
    Draggable,
    PdfOptionCard
  },
  data () {
    return {}
  },
  computed: {
    optionsList: {
      get() {
        return this.optionItems.sort((a,b) => a.row - b.row)
      },
      set (vals) {
        // this.$emit('input', vals)
        this.$root.$emit('updateChildren', vals, this.column)
      }
    },
    draggableOptions() {
      return {
        group: 'default',
        chosenClass: 'dragging',
        ...(isMobile() ? { delay: 300 } : {})
      }
    }
  },
  methods: {
    optionDrag(item) {
      this.$root.$emit('dragging-option', item)
    },
  },
}
</script>

<style lang='scss'>
@import './styles/PdfOptionCardList.scss';
</style>