<template>
  <div style="width:100%">
    <p style="word-break: normal">
      * Please note, GlassBiller is currently compatible with the Square Terminal ONLY. The Square Reader, Square Register, and Square Stand are not supported yet.
    </p>
    <el-form
      ref="form"
      :model="form"
      label-width="125px"
      size="mini"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="name">
            <span slot="label" class="undo-label-styles">Select Device</span>
            <el-select v-model="form.device" value-key="square_device_id" placeholder="Select" no-data-text="No unpaired devices">
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.name"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="t-mb-0">
            <el-button
              class="t-mb-2 t-mr-2"
              type="primary"
              @click="submit"
              :loading="loading"
              :disabled="!form.device"
            >
              Add
            </el-button>
            <el-button
              class="t-ml-0"
              plain
              @click="close"
            >
              Close
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="text-align:center;">
          <el-button type="primary" @click="newCodeDialogVisisble = true">New Device</el-button>
        </el-col>
      </el-row>
    </el-form>
    <device-code-dialog @closed="refetchDevices" :code="code" :visible.sync="codeDialogVisible" />
    <new-device-code-dialog @closed="reset" :shopId="shopId" :locationId="locationId" :visible.sync="newCodeDialogVisisble"/>
  </div>
</template>

<script>
import Shop from '@/scripts/objects/shop'
import DeviceCodeDialog from '@/components/Square/DeviceCodeDialog.vue'
import NewDeviceCodeDialog from '@/components/Square/NewDeviceCodeDialog.vue'

export default {
  props: [
    'shopId',
    'locationId'
  ],
  data: () => ({
    form: {
      device: null
    },
    options: [],
    loading: false,
    code: null,
    codeDialogVisible: false,
    newCodeDialogVisisble: false
  }),
  async created () {
    this.reset()
  },
  methods: {
    close: function () {
      this.form.device = null
      this.$emit('close')
    },
    async reset () {
      // Devices registered with Square:
      // https://squareup.com/dashboard/business/devices

      this.form.device = null
      this.loading = true
      const { data } = await Shop.getUnconnectedSquareDevices(this.shopId)
      this.options = data.devices
      this.loading = false
    },
    async submit () {
      this.loading = true
      this.codeDialogVisible = true
      try {
        const { data } = await Shop.saveSquareDevices(this.shopId, this.form.device)

        if (data.status === 'success') {
          this.code = data.code
          this.reset()
        } else {
          this.$message.error(data.error)
          this.refetchDevices()
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        this.refetchDevices()
      }
    },
    refetchDevices() {
      this.code = null
      this.$root.$emit('refetchSquareDevices')
    }
  },
  mounted () {
    this.$root.$on('refetchSquareDeviceOptions', this.reset)
  },
  // beforeDestroy () {
  //   this.$root.$off('refetchSquareDeviceOptions', this.reset)
  // },
  components: {
    DeviceCodeDialog,
    NewDeviceCodeDialog
  }
}
</script>
