export default class JobCalculations {
  job = null
  parentComponent = null

  shops = []
  payments = []

  constructor (job, parentComponent) {
    this.job = job
    this.parentComponent = parentComponent
  }

  getSelectedObject (objects, fieldName) {
    if (objects && objects.length) {
      for (var i = 0; i < objects.length; i++) {
        if (objects[i].id === this.job[fieldName]) {
          return objects[i]
        }
      }
    }
    return null
  }

  individualListPrice (part) {
    var val = part.list_price
    if (typeof val === 'string' && isNaN(parseFloat(val))) {
      val = 0.0
    }
    return val
  }

  get totalListPrice () {
    var sum = 0.0
    for (var i = this.parentComponent.jobParts.length - 1; i >= 0; i--) {
      var part = this.parentComponent.jobParts[i]
      var val = this.individualListPrice(part)
      sum = sum + parseFloat(val)
    }
    var totalListPrice = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_list_price = totalListPrice
    return totalListPrice
  }

  individualInternalTotalMaterials (part) {
    var val = part.materials_price
    if (typeof val === 'string' && isNaN(parseFloat(val))) {
      val = 0.0
    }

    var qty = part.quantity
    if (typeof qty === 'string' && isNaN(parseFloat(qty))) {
      qty = 0.0
    }

    var isAdhesiveKitPart = part.is_adhesive_kit_part === '1'

    var multiplyByQuantity = true
    if (isAdhesiveKitPart) {
      // Special case: Adhesive parts that are FLAT do NOT multiply by the quantity when contributing to the bottom line (sum across all parts) for the Materials (total) cost
      if (part.part_number === 'HAH000448') {
        if (this.job.insurance_fleet_kit_charge_type_fast === 'flat' ||
          ((part.price_level_tier_this_instance_is_auto_added_adhesive === '1' || part.price_level_tier_this_instance_is_auto_added_adhesive === 1) &&
          part.price_level_tier_auto_add_adhesive_flat_or_each === 'flat')) {
          multiplyByQuantity = false
        }
      } else if ((part.part_number === 'HAH016000' || part.part_number === 'HAH024358' || part.part_number === 'HAH000456')) {
        if (this.job.insurance_fleet_kit_charge_type_high === 'flat' ||
        ((part.price_level_tier_this_instance_is_auto_added_adhesive === '1' || part.price_level_tier_this_instance_is_auto_added_adhesive === 1) &&
        part.price_level_tier_auto_add_adhesive_flat_or_each === 'flat')) {
          multiplyByQuantity = false
        }
      } else {
        if (this.job.insurance_fleet_kit_charge_type_standard === 'flat' ||
        ((part.price_level_tier_this_instance_is_auto_added_adhesive === '1' || part.price_level_tier_this_instance_is_auto_added_adhesive === 1) &&
        part.price_level_tier_auto_add_adhesive_flat_or_each === 'flat')) {
          multiplyByQuantity = false
        }
      }
    }

    var qtyVal = 0.0
    if (multiplyByQuantity) {
      qtyVal = parseFloat(qty) * parseFloat(val)
    } else {
      qtyVal = parseFloat(val)
    }
    return qtyVal
  }

  get internalTotalMaterials () {
    'use strict'
    var sum = 0.0
    for (var i = this.parentComponent.jobParts.length - 1; i >= 0; i--) {
      var part = this.parentComponent.jobParts[i]
      var qtyVal = this.individualInternalTotalMaterials(part)
      sum = sum + qtyVal
    }
    return sum
  };

  individualTotalMaterials (part) {
    'use strict'
    var internalTotalMaterials = this.individualInternalTotalMaterials(part)
    return parseFloat(internalTotalMaterials)
  }

  get totalMaterials () {
    'use strict'
    var internalTotalMaterials = this.internalTotalMaterials
    var totalMaterials = (Math.round(internalTotalMaterials * 100.0) / 100.0).toFixed(2)
    this.job.total_materials = totalMaterials
    return totalMaterials
  }

  individualTotalHours (part) {
    var val = part.hours
    if (typeof val === 'string' && isNaN(parseFloat(val))) {
      val = 0.0
    }
    return val
  }

  get totalHours () {
    var sum = 0.0
    for (var i = this.parentComponent.jobParts.length - 1; i >= 0; i--) {
      var part = this.parentComponent.jobParts[i]
      var val = this.individualTotalHours(part)
      sum = sum + parseFloat(val)
    }
    var totalHours = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_hours = totalHours
    return totalHours
  }

  individualInternalTotalLabor (part) {
    var val = part.labor_price
    if (typeof val === 'string' && isNaN(parseFloat(val))) {
      val = 0.0
    }

    var qty = part.quantity
    if (typeof qty === 'string' && isNaN(parseFloat(qty))) {
      qty = 0.0
    }

    var isAdhesiveKitPart = part.is_adhesive_kit_part === '1'

    var multiplyByQuantity = true
    if (isAdhesiveKitPart) {
      // Special case: Adhesive parts that are FLAT do NOT multiply by the quantity when contributing to the bottom line (sum across all parts) for the Materials (total) cost
      if (part.part_number === 'HAH000448') {
        if (this.job.insurance_fleet_kit_charge_type_fast === 'flat') {
          multiplyByQuantity = false
        }
      } else if ((part.part_number === 'HAH016000' || part.part_number === 'HAH024358' || part.part_number === 'HAH000456')) {
        if (this.job.insurance_fleet_kit_charge_type_high === 'flat') {
          multiplyByQuantity = false
        }
      } else {
        if (this.job.insurance_fleet_kit_charge_type_standard === 'flat') {
          multiplyByQuantity = false
        }
      }
    }

    var qtyVal = 0.0
    if (multiplyByQuantity) {
      qtyVal = parseFloat(qty) * parseFloat(val)
    } else {
      qtyVal = parseFloat(val)
    }

    return qtyVal
  }

  get internalTotalLabor () {
    'use strict'
    var sum = 0.0
    for (var i = this.parentComponent.jobParts.length - 1; i >= 0; i--) {
      var part = this.parentComponent.jobParts[i]
      var qtyVal = this.individualInternalTotalLabor(part)
      sum = sum + qtyVal
    }
    return sum
  }

  individualTotalLabor (part) {
    'use strict'
    var internalTotalLabor = this.individualInternalTotalLabor(part)
    var totalLabor = parseFloat(internalTotalLabor)
    this.job.total_labor = totalLabor
    return totalLabor
  };

  get totalLabor () {
    'use strict'
    var internalTotalLabor = this.internalTotalLabor
    return (Math.round(internalTotalLabor * 100.0) / 100.0).toFixed(2)
  }

  individualTotalSubtotal (part) {
    var internalTotalMaterials = this.individualInternalTotalMaterials(part)
    var internalTotalLabor = this.individualInternalTotalLabor(part)
    var sum = parseFloat(internalTotalMaterials) + parseFloat(internalTotalLabor)
    return sum
  }

  get totalSubtotal () {
    var internalTotalMaterials = this.internalTotalMaterials
    var internalTotalLabor = this.internalTotalLabor
    var sum = internalTotalMaterials + internalTotalLabor
    var totalSubtotal = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_subtotal = totalSubtotal
    return totalSubtotal
  }

  individualTotalPartTotals (part) {
    var val = part.total_price
    if (typeof val === 'string' && isNaN(parseFloat(val))) {
      val = 0.0
    }
    return val
  }

  get totalPartTotals () {
    'use strict'
    var sum = 0.0
    for (var i = this.parentComponent.jobParts.length - 1; i >= 0; i--) {
      var part = this.parentComponent.jobParts[i]
      var val = this.individualTotalPartTotals(part)
      sum = sum + parseFloat(val)
    }
    return (Math.round(sum * 100.0) / 100.0).toFixed(2)
  }

  individualTotalCost (part) {
    var val = part.cost
    if (typeof val === 'string' && isNaN(parseFloat(val))) {
      val = 0.0
    }
    return val
  }

  get totalCost () {
    var sum = 0.0
    for (var i = this.parentComponent.jobParts.length - 1; i >= 0; i--) {
      var part = this.parentComponent.jobParts[i]
      var val = this.individualTotalCost(part)
      sum = sum + parseFloat(val)
    }
    var totalCost = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_cost = totalCost
    return totalCost
  }

  get taxesForJob () {
    var taxesForJob = []
    for (var i = 0; i < this.parentComponent.jobTaxes.length; ++i) {
      for (var j = 0; j < this.parentComponent.shopTaxes.length; ++j) {
        if (this.parentComponent.jobTaxes[i].shop_tax_id === this.parentComponent.shopTaxes[j].id) {
          taxesForJob.push(this.parentComponent.shopTaxes[j])
          break
        }
      }
    }
    return taxesForJob
  }

  refreshJobTaxValues() {
    var totalTaxes = this.totalTaxes
    var totalAmountCalculated = 0.0
    for (var i = 0; i < this.parentComponent.jobTaxes.length; ++i) {
      for (var j = 0; j < this.parentComponent.shopTaxes.length; ++j) {
        if (this.parentComponent.jobTaxes[i].shop_tax_id === this.parentComponent.shopTaxes[j].id && this.parentComponent.shopTaxes[j].status === 'active') {
          var amount = 0
          if (this.parentComponent.shopTaxes[j].for_materials) {
            var innerAmount = parseFloat(this.totalMaterials) * (this.parentComponent.shopTaxes[j].percentage * 0.01)
            if (isNaN(innerAmount)) {
              innerAmount = 0.0
            }
            amount += (Math.round(innerAmount * 100.0) / 100.0).toFixed(2)
          }
          if (this.parentComponent.shopTaxes[j].for_labor) {
            innerAmount = parseFloat(this.totalLabor) * (this.parentComponent.shopTaxes[j].percentage * 0.01)
            if (isNaN(innerAmount)) {
              innerAmount = 0.0
            }
            amount += (Math.round(innerAmount * 100.0) / 100.0).toFixed(2)
          }
          this.parentComponent.jobTaxes[i]['amount'] = amount
          totalAmountCalculated += parseFloat(amount)
        }
      }
    }

    if (this.parentComponent.jobTaxes.length > 0) {
      var indexToIncrement = 0
      var totalTaxesStartedLarger = ((totalTaxes - totalAmountCalculated) > 0)
      while ((totalTaxesStartedLarger && (totalTaxes - totalAmountCalculated) > 0.0) ||
        (!totalTaxesStartedLarger && (totalTaxes - totalAmountCalculated) < 0.0)
      ) {
        if (totalTaxes > totalAmountCalculated) {
          this.parentComponent.jobTaxes[indexToIncrement]['amount'] =
            (Math.round((parseFloat(this.parentComponent.jobTaxes[indexToIncrement]['amount']) + 0.01) * 100.0) / 100.0).toFixed(2)
          totalTaxes -= 0.01
        } else if (totalAmountCalculated > totalTaxes) {
          this.parentComponent.jobTaxes[indexToIncrement]['amount'] =
          (Math.round((parseFloat(this.parentComponent.jobTaxes[indexToIncrement]['amount']) - 0.01) * 100.0) / 100.0).toFixed(2)
          totalAmountCalculated -= 0.01
        }
        indexToIncrement++
        if (indexToIncrement >= this.parentComponent.jobTaxes.length) {
          indexToIncrement = 0
        }
      }
    }
  }

  get materialsPercentageTax () {
    var totalPercentage = 0.0
    var currentTaxes = this.taxesForJob
    for (var i = 0; i < currentTaxes.length; ++i) {
      if (currentTaxes[i].status === 'active' && currentTaxes[i].for_materials) {
        totalPercentage = totalPercentage + parseFloat(currentTaxes[i].percentage)
      }
    }
    return totalPercentage
  }

  individualTotalMaterialsTaxes (part) {
    var totalPercentage = this.materialsPercentageTax
    var sum = parseFloat(this.individualTotalMaterials(part)) * (totalPercentage * 0.01)
    if (isNaN(sum)) {
      sum = 0.0
    }
    return parseFloat(sum)
  };

  get totalMaterialsTaxes () {
    var totalPercentage = this.materialsPercentageTax
    var sum = parseFloat(this.totalMaterials) * (totalPercentage * 0.01)
    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalMaterialsTaxes = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_materials_taxes = totalMaterialsTaxes
    return totalMaterialsTaxes
  }

  individualReverseCalculateMaterialCostBasedOnTargetMaterialPlusTaxCost (materialPlusTaxCost) {
    var targetMaterialCost = parseFloat(materialPlusTaxCost) / (1.0 + parseFloat(this.materialsPercentageTax) * 0.01)
    return targetMaterialCost
  }

  get laborPercentageTax () {
    var totalPercentage = 0.0
    var currentTaxes = this.taxesForJob
    for (var i = 0; i < currentTaxes.length; ++i) {
      if (currentTaxes[i].status === 'active' && currentTaxes[i].for_labor) {
        totalPercentage = totalPercentage + parseFloat(currentTaxes[i].percentage)
      }
    }
    return totalPercentage
  }

  individualTotalLaborTaxes (part) {
    var totalPercentage = this.laborPercentageTax
    var sum = parseFloat(this.individualTotalLabor(part)) * (totalPercentage * 0.01)
    if (isNaN(sum)) {
      sum = 0.0
    }
    return parseFloat(sum)
  };

  get totalLaborTaxes () {
    var totalPercentage = this.laborPercentageTax
    var sum = parseFloat(this.totalLabor) * (totalPercentage * 0.01)

    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalLaborTaxes = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_labor_taxes = totalLaborTaxes
    return totalLaborTaxes
  }

  individualReverseCalculateLaborCostBasedOnTargetLaborPlusTaxCost (laborPlusTaxCost) {
    var targetLaborCost = parseFloat(laborPlusTaxCost) / (1.0 + parseFloat(this.laborPercentageTax) * 0.01)
    return targetLaborCost
  }

  get isTaxExempt () {
    // Always use the commercial account's 'tax exempt' status as a final override of any other tax exempt option if it is SET
    // if (this.job.customer_type === 'commercial') {
    //   var selectedCommercialAccount = this.getSelectedObject(this.parentComponent.commercialaccounts, 'commercialaccount_id')
    //   if (selectedCommercialAccount !== null) {
    //     var taxExempt = selectedCommercialAccount.commercial_account_tax_exempt
    //     if (taxExempt) {
    //       return true
    //     }
    //   }
    // }
    if (this.job.insurance_fleet_tax_exempt) {
      return true
    }
    return false
  }

  individualTotalTaxes (part) {
    var isTaxExempt = this.isTaxExempt
    if (isTaxExempt) {
      return 0.0
    }

    var sum = parseFloat(this.individualTotalMaterialsTaxes(part)) + parseFloat(this.individualTotalLaborTaxes(part))
    if (isNaN(sum)) {
      sum = 0.0
    }
    return parseFloat(sum)
  }

  get totalTaxes () {
    var isTaxExempt = this.isTaxExempt
    if (isTaxExempt) {
      this.job.total_taxes = 0.0
      return 0.0
    }

    var sum = parseFloat(this.totalMaterialsTaxes) + parseFloat(this.totalLaborTaxes)
    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalTaxes = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_taxes = totalTaxes
    return totalTaxes
  }

  individualTotalAfterTaxes (part) {
    var sum = parseFloat(this.individualTotalSubtotal(part)) + parseFloat(this.individualTotalTaxes(part))
    if (isNaN(sum)) {
      sum = 0.0
    }
    return parseFloat(sum)
  }

  get totalAfterTaxes () {
    var sum = parseFloat(this.totalSubtotal) + parseFloat(this.totalTaxes)
    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalAfterTaxes = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_after_taxes = totalAfterTaxes
    return totalAfterTaxes
  }

  get totalAfterDeductible () {
    var deductible = parseFloat(this.job.deductible)
    if (isNaN(deductible)) {
      deductible = 0.0
    }

    var sum = parseFloat(this.totalAfterTaxes) - deductible
    if (isNaN(sum)) {
      sum = 0.0
    }

    var totalAfterDeductible = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_after_deductible = totalAfterDeductible
    return totalAfterDeductible
  }

  get totalSalesRepCommission () {
    // Sales rep
    var salesRepCommission = this.job.sales_rep_commission
    if (isNaN(parseFloat(salesRepCommission))) {
      salesRepCommission = 0.0
    }
    var sum = parseFloat(salesRepCommission)
    if (this.job.sales_rep_commission_flat_or_percent === 'percent') {
      sum = (0.01 * parseFloat(salesRepCommission) * parseFloat(this.totalAfterTaxes))
    }

    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalSalesRepCommission = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_sales_rep_commission = totalSalesRepCommission
    return totalSalesRepCommission
  }

  get totalSaleSourceCommission () {
    // Sale source
    var saleSourceCommission = this.job.sale_source_commission
    if (isNaN(parseFloat(saleSourceCommission))) {
      saleSourceCommission = 0.0
    }
    var sum = parseFloat(saleSourceCommission)
    if (this.job.sale_source_commission_flat_or_percent === 'percent') {
      sum = (0.01 * parseFloat(saleSourceCommission) * parseFloat(this.totalAfterTaxes))
    }

    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalSaleSourceCommission = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_sale_source_commission = totalSaleSourceCommission
    return totalSaleSourceCommission
  }

  get totalTechCommission () {
    // Tech
    var techCommission = this.job.tech_commission
    if (isNaN(parseFloat(techCommission))) {
      techCommission = 0.0
    }
    var sum = parseFloat(techCommission)
    if (this.job.tech_commission_flat_or_percent === 'percent') {
      sum = (0.01 * parseFloat(techCommission) * parseFloat(this.totalAfterTaxes))
    }

    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalTechCommission = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_tech_commission = totalTechCommission
    return totalTechCommission
  }

  get totalCsrCommission () {
    // Csr
    var csrCommission = this.job.csr_commission
    if (isNaN(parseFloat(csrCommission))) {
      csrCommission = 0.0
    }
    var sum = parseFloat(csrCommission)
    if (this.job.csr_commission_flat_or_percent === 'percent') {
      sum = (0.01 * parseFloat(csrCommission) * parseFloat(this.totalAfterTaxes))
    }

    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalCsrCommission = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_csr_commission = totalCsrCommission
    return totalCsrCommission
  }

  get totalLocationCommission () {
    // Location
    var locationCommission = this.job.location_commission
    if (isNaN(parseFloat(locationCommission))) {
      locationCommission = 0.0
    }
    var sum = parseFloat(locationCommission)
    if (this.job.location_commission_flat_or_percent === 'percent') {
      sum = (0.01 * parseFloat(locationCommission) * parseFloat(this.totalAfterTaxes))
    }

    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalLocationCommission = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_location_commission = totalLocationCommission
    return totalLocationCommission
  }

  get totalCommission () {
    var salesRepCommissionDollarAmount = this.totalSalesRepCommission
    var techCommissionDollarAmount = this.totalTechCommission
    var csrCommissionDollarAmount = this.totalCsrCommission
    var saleSourceCommissionDollarAmount = this.totalSaleSourceCommission
    var locationCommissionDollarAmount = this.totalLocationCommission

    var sum = parseFloat(salesRepCommissionDollarAmount) + parseFloat(techCommissionDollarAmount) + parseFloat(saleSourceCommissionDollarAmount) + parseFloat(locationCommissionDollarAmount) + parseFloat(csrCommissionDollarAmount)
    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalCommission = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_commission = totalCommission
    return totalCommission
  }

  get totalMargin () {
    var customerRebate = this.job.customer_rebate
    if (isNaN(parseFloat(customerRebate))) {
      customerRebate = 0.0
    }
    var otherCosts = this.job.other_costs
    if (isNaN(parseFloat(otherCosts))) {
      otherCosts = 0.0
    }
    var sum = parseFloat(this.totalSubtotal) - (parseFloat(this.totalCommission) + parseFloat(this.totalCost) + parseFloat(customerRebate) + parseFloat(otherCosts))
    if (isNaN(sum)) {
      sum = 0.0
    }
    var totalMargin = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_margin = totalMargin
    return totalMargin
  }

  get selectedShop () {
    return this.getSelectedObject(this.parentComponent.$store.state.shops, 'shop_id')
  }

  get totalBalanceAfterPayments () {
    var sum = this.totalAfterDeductible
    for (var i = this.parentComponent.payments.length - 1; i >= 0; i--) {
      if (this.parentComponent.payments[i].type === 'Balance Due' ||
      this.parentComponent.payments[i].type === 'Adjustment') {
        var val = this.parentComponent.payments[i].amount
        if (val == null || (typeof val === 'string' && isNaN(parseFloat(val)))) {
          val = 0.0
        }
        sum = sum - parseFloat(val)
      }
    }
    var totalBalanceAfterPayments = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_balance_after_payments = totalBalanceAfterPayments
    return totalBalanceAfterPayments
  }

  get totalDeductibleAfterPayments () {
    var sum = parseFloat(this.job.deductible)
    if (isNaN(sum)) {
      sum = 0.0
    }

    for (var i = this.parentComponent.payments.length - 1; i >= 0; i--) {
      if (this.parentComponent.payments[i].type === 'Deductible') {
        var val = this.parentComponent.payments[i].amount
        if (val === null || (typeof val === 'string' && isNaN(parseFloat(val)))) {
          val = 0.0
        }
        sum = sum - parseFloat(val)
      }
    }
    var totalDeductibleAfterPayments = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_deductible_after_payments = totalDeductibleAfterPayments
    return totalDeductibleAfterPayments
  }

  get totalBalanceAdjustablePayments () {
    var sum = 0.0
    for (var i = this.parentComponent.payments.length - 1; i >= 0; i--) {
      if (this.parentComponent.payments[i].type === 'Balance Due' ||
      this.parentComponent.payments[i].type === 'Adjustment') {
        var val = this.parentComponent.payments[i].amount
        if (val == null || (typeof val === 'string' && isNaN(parseFloat(val)))) {
          val = 0.0
        }
        sum = sum + parseFloat(val)
      }
    }
    var totalBalanceAdjustablePayments = (Math.round(sum * 100.0) / 100.0).toFixed(2)
    this.job.total_balance_adjustable_payments = totalBalanceAdjustablePayments
    return totalBalanceAdjustablePayments
  }
}
