<template>
  <b-popover :id='partDetail.detail.partno' triggers='click blur' @show='popoverShown' :target='id' :container='"warhouse" + type + "wrapper"'>
    <template slot='title' :class='type'>
      <div class='form-row'>
        <div class='form-group col-md-9'>
          <div class='form-row'>
            <div class='form-group col-md-12'>
              Order Part <span id='partnumber' class='warehouse-part-number'>{{partDetail.detail.partno}}</span>
              <div id='description' style='font-size: small;'>{{partDetail.detail.dsc}}</div>
            </div>
          </div>
        </div>
        <div class='form-group col-md-3' v-if='price'>
          <a :id='partDetail.detail.partno' :key='partDetail.detail.partno' disabled class='label label-gray label-no-padding' style='color: purple;' @click='addPartClicked'>${{price}}</a>
        </div>
      </div>
      <div class="form-row">
        <div v-if='type !== "Pgw"' class="form-group col-md-12">
          <label for='warhouseDropdown'>Warehouse</label>
          <select v-model="selectedShop" class="form-control form-control-sm">
            <option v-for="shop in $store.state.shops" :key="shop.id" v-bind:value="shop.id" >{{ shop.name }}</option>
          </select>
        </div>
      </div>
    </template>
    <div>
      <div class='form-row'>
        <div class="form-group col-md-12">
          <hr/>
        </div>
      </div>
      <div class="form-row" v-if='localInventoryObject.totalAvailableIncludingSubs && localInventoryObject.totalAvailableIncludingSubs > 0 && !loading'>
        <div class="form-group col-md-4 warehouse-part">
          {{partDetail.detail.partno}}
        </div>
        <div class='form-group col-md-2 warehouse-quantity'>
          {{localInventoryObject.totalAvailableIncludingSubs}}
        </div>
        <div class='form-group col-md-3 warehouse-price'>
          ${{localInventoryObject.cost}}
        </div>
        <div class='form-group col-md-2' v-b-tooltip.hover title="Coming Soon!">
          <a disabled class="label label-gray label-sm"><v-icon name="cart-plus" scale="1.0"/></a>
        </div>
      </div>
      <div class='text-center' v-if='loading'>
        <v-icon name="spinner" style='color: black;' spin scale="1.5"/>
        <div class='text-center'>
          Please wait while we check for Inventory at this warehouse...
        </div>
      </div>
      <div class='text-center' v-if='(!localInventoryObject || !localInventoryObject.qty || localInventoryObject.qty === 0) && !loading'>
        No results were found at this warehouse.
      </div>
      <div class='form-row'>
        <div class="form-group col-md-12">
          <hr/>
        </div>
      </div>
    </div>
    <div >
      <div class='form-row'>
        <div class='form-group col-md-12 text-center'>
          <strong>-Related Parts-</strong>
        </div>
      </div>
      <hr/>
        <div class='form-row'>
          <div class='form-group col-md-12 text-center'>
            Inventory Related Parts Coming Soon!
          </div>
        </div>
      <hr/>
    </div>
  </b-popover>
</template>

<script>
import { getInventoryFromParts, inventoryFromPart } from '@/scripts/helpers/inventory.helpers'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default {
  props: ['id', 'type', 'partDetail', 'shopId', 'price', 'inventoryObject'],
  data: function () {
    return {
      loading: false,
      mainPart: {},
      localInventoryObject: {},
      relatedParts: [],
      localWarehouses: [],
      selectedShop: this.shopId
    }
  },
  methods: {
    getInventoryFromParts,
    inventoryFromPart,
    popoverShown () {
      this.selectedShop = this.shopId
      this.localInventoryObject = this.inventoryObject
      this.loading = false
    },
    addPartClicked () {
      var tempPart = Object.assign({}, this.partDetail)
      this.$emit('addPart', tempPart, tempPart.children, this.type.toLowerCase(), undefined, undefined, true)
    }
  },
  watch: {
    selectedShop (val) {
      // if (this.selectedShop !== this.shopId) {
      this.loading = true
      this.selectedShop = val
      var self = this
      // get costing method
      this.getInventoryFromParts(val, this.partDetail.detail.partno, (response) => {
        const temp = self.inventoryFromPart(self.partDetail.detail.partno, response, self.getShopFromId(self, val).inventory_costing_method)
        self.localInventoryObject = temp
        self.loading = false
      }, _ => {
        self.loading = false
      })
      // }
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style>

</style>
