const state = {
  isOpened: false,

  status: '',

  layout: [],

  defaultTab: '',

  settings: {},
  settings_loaded: false,

  settings_events: {},
  settings_events_loaded: false
};

export default state;
