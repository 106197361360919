<template>
  <div class="gb-tag-select">
    <gb-tag v-bind="selected" v-if="selected">
      {{ selected.label }}
    </gb-tag>
    <el-select v-model="select" :disabled='disabled'>
      <el-option v-for="item in options" :key="item.value" :value="item.value">
        {{ item.label }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
import * as R from 'ramda'

export default {
  props: {
    value: String,
    options: {
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    select: {
      get() {
        return this.value
      },
      set(v) {
        console.log(v)
        this.$emit('input', v)
      }
    },
    selected() {
      return this.options.find(R.propEq('value', this.value))
    }
  }
}
</script>

<style lang="scss" >
.gb-tag-select {
  position: relative;
  .el-select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    .el-input__inner {
      height: 24px;
    }
  }
}
</style>
