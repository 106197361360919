var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "140px",
        size: "small"
      }
    },
    [
      _vm.shopPhoneNumbers.length
        ? _c(
            "el-form-item",
            { attrs: { prop: "shopPhoneNumber" } },
            [
              _c(
                "span",
                {
                  staticClass: "undo-label-styles",
                  attrs: { slot: "label" },
                  slot: "label"
                },
                [_vm._v("Shop phone")]
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "Select shop phone number" },
                  on: { focus: _vm.focusedShopPhoneSelect },
                  model: {
                    value: _vm.form.shopPhoneNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "shopPhoneNumber", $$v)
                    },
                    expression: "form.shopPhoneNumber"
                  }
                },
                _vm._l(_vm.shopPhoneNumbers, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: _vm.generateLabel(item),
                      value: item.phone_number
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { prop: "customerPhoneNumber" } },
        [
          _c(
            "span",
            {
              staticClass: "undo-label-styles",
              attrs: { slot: "label" },
              slot: "label"
            },
            [_vm._v("Customer phone")]
          ),
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: {
                placeholder: "Select customer phone number",
                "allow-create": "",
                clearable: "",
                "default-first-option": "",
                filterable: ""
              },
              model: {
                value: _vm.form.customerPhoneNumber,
                callback: function($$v) {
                  _vm.$set(_vm.form, "customerPhoneNumber", $$v)
                },
                expression: "form.customerPhoneNumber"
              }
            },
            _vm._l(_vm.customerPhoneNumbers, function(item, key) {
              return _c("el-option", {
                key: key,
                attrs: {
                  label: _vm.formatPhone(item.phone_number),
                  value: item.phone_number
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("consumer-select", {
        attrs: { consumerToCharge: _vm.form.consumerToCharge },
        on: {
          "update:consumerToCharge": function($event) {
            return _vm.$set(_vm.form, "consumerToCharge", $event)
          },
          "update:consumer-to-charge": function($event) {
            return _vm.$set(_vm.form, "consumerToCharge", $event)
          }
        }
      }),
      _c("amount", {
        attrs: { requestedAmount: _vm.form.amount },
        on: {
          "update:requestedAmount": function($event) {
            return _vm.$set(_vm.form, "amount", $event)
          },
          "update:requested-amount": function($event) {
            return _vm.$set(_vm.form, "amount", $event)
          }
        }
      }),
      _c(
        "el-form-item",
        { attrs: { prop: "selectedTemplate" } },
        [
          _c(
            "span",
            {
              staticClass: "undo-label-styles",
              attrs: { slot: "label" },
              slot: "label"
            },
            [_vm._v("Template")]
          ),
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: {
                clearable: "",
                placeholder: "Select text message template (optional)"
              },
              model: {
                value: _vm.selectedTemplateId,
                callback: function($$v) {
                  _vm.selectedTemplateId = $$v
                },
                expression: "selectedTemplateId"
              }
            },
            _vm._l(_vm.templates, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _vm._v(" or "),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.manageTemplates }
            },
            [_vm._v("Manage text message templates")]
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "body" } },
        [
          _c(
            "span",
            {
              staticClass: "undo-label-styles",
              attrs: { slot: "label" },
              slot: "label"
            },
            [_vm._v("Message")]
          ),
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 3, maxRows: 10 },
              placeholder: "Text Body"
            },
            model: {
              value: _vm.form.body,
              callback: function($$v) {
                _vm.$set(_vm.form, "body", $$v)
              },
              expression: "form.body"
            }
          })
        ],
        1
      ),
      _c("el-form-item", { staticStyle: { "word-break": "break-word" } }, [
        _c("p", { staticClass: "mt-3" }, [
          _c("span", { staticStyle: { color: "#F56C6C" } }, [_vm._v("NOTE:")]),
          _vm._v(
            " A link to the customer payment portal will be automatically added in the end of the message."
          )
        ])
      ]),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              staticClass: "mb-2 mr-2",
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v(" Send ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "ml-0",
              attrs: { plain: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(" Close ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }