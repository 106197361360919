<template>
  <div class="scheduler-body" :class="{ 'row-view': isRowView }">
    <calendar-view v-if="isDayView" />
    <bay-view v-else-if="isBayView" />
    <template v-else>
      <div class="job-list" v-if="hasData">
        <scheduler-left-card-list />
      </div>
      <div
        :class="{ 'tech-table': true, empty: !hasData }"
        v-custom-infinite-scroll="{
          direction: isRowView ? 'vertical' : 'horizontal',
          loadMore: showMoreTechs
        }"
      >
        <template v-if="hasData">
          <scheduler-job-card-list
            v-for="route_id in data"
            :route="routeList[route_id]"
            :key="route_id"
          />
          <div v-if="data.length < routeOrder.length" class="loading-techs">
            <i class="el-icon-loading" />
          </div>
        </template>
        <div v-else>
          <v-icon name="calendar-alt" scale="2.5" />
          <div>
            Select shops to see
            <br />scheduler data
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SchedulerJobCardList from './SchedulerJobCardList'
import SchedulerLeftCardList from './SchedulerLeftCardList'
import { get } from 'vuex-pathify'
import CalendarView from './CalendarView/Index'
import BayView from './BayView/Index'
import * as R from 'ramda'

export default {
  data() {
    return {
      showTechs: 2
    }
  },
  computed: {
    jobs: get('sch_v3/jobList'),
    hasData() {
      return !R.isEmpty(this.routeList)
    },
    isDayView: get('sch_v3/isDayView'),
    isBayView: get('sch_v3/isBayView'),
    isRowView: get('sch_v3/isRowView'),
    cardView: get('sch_v3/cardView'),
    routeList: get('sch_v3/routeList'),
    routeOrder: get('sch_v3/routeOrder'),
    data() {
      return R.take(this.showTechs * 5)(this.routeOrder)
    }
  },
  components: {
    SchedulerJobCardList,
    CalendarView,
    SchedulerLeftCardList,
    BayView
  },
  methods: {
    showMoreTechs() {
      this.showTechs = this.showTechs + 1
    }
  },
  watch: {
    cardView() {
      this.showTechs = 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerBody';
</style>
