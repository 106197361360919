<template>
  <el-dialog
    :title="phoneNumber === null ? 'Create a phone number' : 'Update a phone number'"
    width="80%"
    :visible="visible"
    :modal-append-to-body="false"
    :before-close="handleClose"
    @open="handleOpen">
    <el-row>
      <el-col :lg ="18" :sm="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :hide-required-asterisk="true"
          label-width="70px"
          size="medium"
          :disabled="disabled"
        >
          <el-form-item prop="name">
            <span slot="label" class="undo-label-styles">Name</span>
            <el-input
              placeholder="Enter a name"
              v-model="form.name"
            />
          </el-form-item>
          <el-form-item prop="sid">
            <span slot="label" class="undo-label-styles">SID</span>
            <el-input
              placeholder="Enter an SID"
              v-model="form.sid"
            />
          </el-form-item>
          <el-form-item prop="phone_number">
            <span slot="label" class="undo-label-styles">Phone</span>
            <el-input
              placeholder="Enter a phone number"
              v-model="form.phone_number"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              class="mb-2 mr-2"
              type="primary"
              @click="handleSubmit"
              :loading="loading"
            >
              {{ phoneNumber === null ? 'Create' : 'Update' }}
            </el-button>
            <el-button
              class="ml-0"
              plain
              @click="handleClose"
            >
              Close
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import TwilioPhoneNumber from '@/scripts/objects/twilio_phone_number'

export default {
  props: ['shopId', 'visible', 'phoneNumber', 'disabled'],
  data: () => ({
    loading: false,
    form: {
      name: '',
      sid: '',
      phone_number: ''
    },
    rules: {
      name: { required: true, message: 'Please enter a name', trigger: 'blur' },
      sid: { required: true, message: 'Please enter an SID', trigger: 'blur' },
      phone_number: { required: true, len: 10, message: 'Please enter a 10 digits phone number', trigger: 'blur' }
    }
  }),
  methods: {
    async handleSubmit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          if (this.phoneNumber === null) {
            await TwilioPhoneNumber.store(this.shopId, this.form)
          } else {
            await TwilioPhoneNumber.update(this.phoneNumber.id, this.form)
          }
          let res = await TwilioPhoneNumber.get(this.shopId)
          this.$emit('createdUpdated', res.data)
          this.handleClose()
          this.loading = false
          this.$message.success(this.phoneNumber === null ? 'Phone number created.' : 'Phone number updated.')
        }
      } catch (error) {
        this.loading = false
      }
    },
    handleOpen () {
      if (this.phoneNumber === null) {
        this.form.name = ''
        this.form.sid = ''
        this.form.phone_number = ''
      } else {
        this.form.name = this.phoneNumber.name
        this.form.sid = this.phoneNumber.sid
        this.form.phone_number = this.phoneNumber.phone_number
      }
    },
    handleClose () {
      this.$refs.form.clearValidate()
      this.$emit('closed')
    }
  }
}
</script>
