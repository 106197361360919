<template>
  <div class="consumer-vehicles">
    <el-table :data="vehicles || []" size="mini" border>
      <el-table-column label="Vehicle">
        <template slot-scope="scope">
          <span>{{ scope.row.year }} </span>
          <span>{{ scope.row.make }} </span>
          <span>{{ scope.row.model }} </span>
          <span>{{ scope.row.sub_model }} </span>
          <br />
          <span class="subtitle">{{ scope.row.style }} </span>
        </template>
      </el-table-column>
      <el-table-column prop="vin" label="VIN" />
      <el-table-column width="95px">
        <template slot-scope="{ row }">
          <md-circle-button
            icon="delete"
            outline
            size="normal"
            danger
            @click="removeVehicle(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getVehicleString } from '@/scripts/helpers/job.helpers'
import { updateFieldMutate } from '@/scripts/helpers/apollo.helpers'
import {
  UPDATE_VEHICLE_MUTATION
} from '@/apollo/mutation/vehicle.mutation'

export default {
  props: ['value'],

  computed: {
    vehicles() {
      return this.value?.vehicles || []
    }
  },
  methods: {
    async removeVehicle(v) {
      const id = v.id
      const vehicle = { consumer_id: null }
      const { cache } = this.$apollo.getClient()
      cache.modify({
        id: `Vehicle:${id}`,
        fields: updateFieldMutate(vehicle)
      })
      await this.$apollo.mutate({
        mutation: UPDATE_VEHICLE_MUTATION,
        variables: {
          vehicle,
          id
        }
      })

      for (var i = 0; i < this.vehicles.length; i++) {
        if (this.vehicles[i].id === v.id) {
          this.vehicles.splice(i, 1)
          break
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.consumer-vehicles {
  .subtitle {
    color: $sub-text-color;
  }
}
</style>
