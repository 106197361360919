var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "modal-xl",
      attrs: {
        id: _vm.modalId,
        title: "Billing Details",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": !_vm.userAccountIsActive,
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "xl"
      },
      on: { show: _vm.dialogAboutToAppear, hidden: _vm.dialogDisappeared }
    },
    [
      this.$store.state.user.account_manager === 1 ||
      this.$store.state.user.admin === 1
        ? _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loadingBillingDetails,
                    expression: "loadingBillingDetails"
                  }
                ],
                staticClass: "form-row"
              },
              [_vm._v("Loading...")]
            ),
            !_vm.loadingBillingDetails && _vm.billingDetailsResult === "error"
              ? _c("div", { staticClass: "form-row" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.errorMessage && _vm.errorMessage.length > 0
                          ? _vm.errorMessage
                          : "Something went wrong loading billing details..."
                      )
                  )
                ])
              : _vm._e(),
            !_vm.loadingBillingDetails && _vm.billingDetailsResult === "success"
              ? _c(
                  "div",
                  { staticClass: "form-row", staticStyle: { width: "100%" } },
                  [
                    _c("div", { staticClass: "form-group col-3" }, [
                      !_vm.userAccountIsActive
                        ? _c("h5", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "Your GlassBiller account is currently inactive. Please enter in updated payment details. If you are unable to update with this form, you may need to reach out to GlassBiller support."
                            )
                          ])
                        : _vm._e(),
                      _c("p", [
                        _c("b", [_vm._v("Account:")]),
                        _vm._v(" " + _vm._s(_vm.accountName))
                      ]),
                      _c("p", [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.userAccountIsActive ? "Price" : "Amount Due"
                            ) + ":"
                          )
                        ]),
                        _vm._v(" " + _vm._s(_vm.billingDisplayAmount))
                      ]),
                      _vm.userAccountIsActive
                        ? _c("p", [
                            _c("b", [_vm._v("Next Billing:")]),
                            _vm._v(" " + _vm._s(_vm.nextBillingAt))
                          ])
                        : _vm._e(),
                      _c("p", [
                        _c("b", [_vm._v("Current Status:")]),
                        _vm._v(" " + _vm._s(_vm.billingStatus.toUpperCase()))
                      ])
                    ]),
                    _c("div", { staticClass: "form-group col-9" }, [
                      _vm.billingDetailsUrl.length > 0
                        ? _c(
                            "iframe",
                            {
                              attrs: {
                                src: _vm.billingDetailsUrl,
                                width: "100%",
                                height: "650px",
                                frameBorder: "0"
                              }
                            },
                            [_vm._v("Browser not compatible.")]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              : _vm._e(),
            !_vm.loadingBillingDetails &&
            _vm.billingDetailsResult === "idNotFound"
              ? _c("div", { staticClass: "form-row" }, [
                  _vm._v(
                    "Please contact GlassBiller in order to update your billing details."
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      this.$store.state.user.account_manager !== 1
        ? _c("div", [
            _c("div", { staticClass: "form-row" }, [
              !_vm.userAccountIsActive
                ? _c("h5", { staticStyle: { color: "red" } }, [
                    _vm._v(
                      "This account is currently inactive. The account manager must update the account's billing information in order to reactivate the account."
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c("div", { staticClass: "float-right" }, [
            _vm.userAccountIsActive
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", variant: "primary" },
                    on: { click: _vm.closeButtonClicked }
                  },
                  [_vm._v("Close")]
                )
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }