<template>
  <div class="data-rows">
    <el-row v-for="(row, idx) in schema" :key="idx" :gutter="nogutter ? 0 : 12">
      <el-col
        v-for="col in row"
        :key="col.id"
        :span="col.span || 24 / row.length"
      >
        <slot :name="col.slot" v-if="col.slot" />
        <component
          v-else
          :is="getComponent(col)"
          v-bind="col"
          :value="getValue(col)"
          :loading="loading"
          :class="{'inputWithLabel': col.componentType === 'inputWithLabel'}"
          v-on="col.componentType === 'inputWithLabel'  ?
          { change: (e) =>$emit('change', e, col), input: (e) => $emit('input', e, col) } :
          { edit: (e) =>$emit('edit', e, col) }"
          :hideLabel="hideLabels"
          :size="size"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as R from 'ramda'
export default {
  props: ['data', 'schema', 'loading', 'hideLabels', 'size', 'nogutter'],
  methods: {
    getValue({ id, render, extraData }) {
      return render
        ? render(extraData || this.data)
        : R.pathOr('', R.split('.')(id))(extraData || this.data)
    },
    getComponent(col) {
      if (col.componentType === 'inputWithLabel') {
        return 'input-with-label'
      } else if (col.editable && col.type !== 'bool') {
        return 'gb-editable-data-row'
      } else if (col.select) {
        return 'gb-select-data-row'
      } else {
        return 'gb-data-row'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./DataRows.scss";
.inputWithLabel {
  margin-bottom: 10px;
}
</style>
