var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticClass: "t-mb-4",
      attrs: {
        border: "",
        "header-cell-class-name":
          "t-bg-gray-100 t-text-gray-700 t-font-medium t-break-normal",
        data: _vm.lines
      }
    },
    [
      _c("el-table-column", {
        attrs: { label: "", width: "50" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  { staticClass: "t-flex t-justify-center" },
                  [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          title: "Do you want to remove the item ?",
                          "icon-color": "red",
                          "confirm-button-text": "Yes",
                          "cancel-button-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.$emit("remove-line", scope.row.jobPartId)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "el-icon-circle-close t-text-red-400 t-text-base t-cursor-pointer",
                          attrs: { slot: "reference" },
                          slot: "reference"
                        })
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Item & Description" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("div", { staticClass: "t-break-normal" }, [
                  _c("div", { staticClass: "t-font-medium" }, [
                    _vm._v(_vm._s(scope.row.itemName))
                  ]),
                  _c(
                    "div",
                    { staticClass: "t-text-xs t-italic t-text-gray-400" },
                    [_vm._v(" " + _vm._s(scope.row.itemDescription) + " ")]
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Job #", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                scope.row.jobId !== null
                  ? _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href: "/jobs/" + scope.row.jobId
                        }
                      },
                      [_vm._v(" " + _vm._s(scope.row.jobId) + " ")]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Qty", width: "120", prop: "quantity" }
      }),
      _c("el-table-column", {
        attrs: { label: "PO #", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("div", { staticClass: "t-flex" }, [
                  scope.row.purchaseOrderId !== null
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "t-px-2 t-rounded t-opacity-80 t-text-white t-cursor-pointer",
                          style:
                            "background-color: " +
                            _vm.getSupplierColor(
                              scope.row.purchaseOrderSupplierId
                            ),
                          on: {
                            click: function($event) {
                              return _vm.openPO(scope.row.purchaseOrderId)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " PO " +
                              _vm._s(scope.row.purchaseOrderInternalId) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }