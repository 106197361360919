<template>
  <el-dialog
    append-to-body
    width="400px"
    :visible="isOpen"
    :before-close="handleClose"
    top="18vh"
    custom-class="channel-members-dialog">
    <span slot="title" class="t-text-lg">
      <span class="t-mr-8 t-flex">
        <span class="t-mr-2 t-flex-shrink-0">
          {{ members.length }}
          member{{ members.length > 1 ? 's' : '' }} in
        </span>
        <div class="t-truncate">
          <span v-if="!activeChannel.isPrivate">#</span>
          <i v-else class="el-item-lock"></i>
          {{ activeChannel.uniqueName }}
        </div>
      </span>
    </span>
    <div class="t-h-full">
      <div
        v-for="i in members"
        :key="i.sid"
        class="t-flex t-items-center t-mb-2 t-text-lg">
        <i class="el-icon-user t-mr-2"/>
        <div class="t-text-gray-700 t-mr-3">
          {{ i.identity }}
        </div>
        <!-- <i
          v-if="findUser(i.identity).online"
          class="fa fa-circle t-text-green-700 t-text-xs"
        />
        <i v-else class="fa fa-circle-o t-text-xs"/> -->
      </div>
      <el-button
        v-if="activeChannel.createdBy === username"
        type="primary"
        icon="el-icon-plus"
        @click="addUser"
        size="medium"
        class="t-mt-4"
        plain
      >
        Add users
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { chatTypes } from '@/store/modules/chat/types'

export default {
  computed: {
    isOpen: function() {
      return this.$store.state.chat.membersDialogIsOpen
    },
    activeChannel: function() {
      return this.$store.state.chat.activeChannel
    },
    members: function() {
      return this.$store.state.chat.members
    },
    username: function() {
      return this.$store.state.user.username
    }
  },
  methods: {
    handleClose: function() {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.setMembersDialogIsOpen,
        false
      )
    },
    addUser: function() {
      this.$store.commit(
        chatTypes.namespace + chatTypes.mutations.setAddUsersDialogIsOpen,
        true
      )
      this.handleClose()
    }
  }
}
</script>

<style lang="scss">
.channel-members-dialog {
  @apply t-font-sans;
  .el-dialog__header {
    @apply t-border-0 t-border-b t-border-solid t-border-gray-300;
  }
  .el-dialog__body {
    @apply t-py-4;
  }
}
</style>
