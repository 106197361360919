var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "span",
      {
        staticClass: "undo-label-styles",
        attrs: { slot: "label" },
        slot: "label"
      },
      [_vm._v("Plants")]
    ),
    _c("table", { staticClass: "t-border-collapse" }, [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.allWarehouses, function(i) {
          return _c("tr", { key: i.id }, [
            _c(
              "td",
              {
                staticClass:
                  "t-border t-border-solid t-border-gray-200 t-text-center"
              },
              [
                _c("el-checkbox", {
                  attrs: { label: i.id },
                  on: {
                    change: function($event) {
                      return _vm.checkboxChanged($event, i.id)
                    }
                  },
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                })
              ],
              1
            ),
            _c(
              "td",
              {
                staticClass:
                  "t-border t-border-solid t-border-gray-200 t-text-center"
              },
              [_vm._v(" " + _vm._s(i.glaxis_warehouse_id) + " ")]
            ),
            _c(
              "td",
              {
                staticClass:
                  "t-border t-border-solid t-border-gray-200 t-text-center"
              },
              [_vm._v(" " + _vm._s(i.name) + " ")]
            ),
            _c(
              "td",
              {
                staticClass:
                  "t-border t-border-solid t-border-gray-200 t-text-center"
              },
              [
                _vm.selected.includes(i.id)
                  ? _c("el-radio", {
                      attrs: { label: i.id },
                      model: {
                        value: _vm.selectedDefault,
                        callback: function($$v) {
                          _vm.selectedDefault = $$v
                        },
                        expression: "selectedDefault"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c(
          "td",
          { staticClass: "t-border t-border-solid t-border-gray-200 t-px-4" },
          [_vm._v(" Selected ")]
        ),
        _c(
          "td",
          { staticClass: "t-border t-border-solid t-border-gray-200 t-px-4" },
          [_vm._v(" Plant Code ")]
        ),
        _c(
          "td",
          { staticClass: "t-border t-border-solid t-border-gray-200 t-px-4" },
          [_vm._v(" Plant Name ")]
        ),
        _c(
          "td",
          { staticClass: "t-border t-border-solid t-border-gray-200 t-px-4" },
          [_vm._v(" Default ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }