import { make } from 'vuex-pathify'

const state = {
  visible: false,
  referral: null,
  selectTemplateDialogVisible: false
}

const mutations = make.mutations(state)

const actions = {
  open ({ commit }) {
    commit('SET_VISIBLE', true)
  },
  setReferral ({ commit }, referral) {
    commit('SET_REFERRAL', referral)
  },
  setSelectTemplateDialogVisible ({ commit }, payload) {
    commit('SET_SELECT_TEMPLATE_DIALOG_VISIBLE', payload)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
