import { reportsMutations as mutations } from './reports.types'
import * as R from 'ramda'
import reportsInitialState from './reports.state'
import Vue from 'vue'

const reportsMutations = {
  [mutations.setStateValue] (state, { key, value }) {
    const [topKey, ...path] = key.split('.')
    if (path.length) {
      // deep path update
      const lens = R.lensPath(path)
      // state[topKey] = R.set(lens, value, state[topKey])
      Vue.set(state, topKey, R.set(lens, value, state[topKey]))
    } else {
      // state[topKey] = value
      Vue.set(state, topKey, value)
    }
  },
  [mutations.resetReportsState] (state) {
    R.keys(reportsInitialState).forEach(key => {
      state[key] = reportsInitialState[key]
    })
  },
  [mutations.resetData] (state) {
    state.data = null
  }
}

export default reportsMutations
