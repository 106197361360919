import moment from 'moment';
import { EventType } from '@/store/modules/notifications-settings/events';

// number of loaded notifications per page
const PAGE_SIZE = 10;

const getComponentName = (value) => {
  let valueKey = Object.keys(EventType).find(key => EventType[key] === value);
  let tmp = valueKey.split('_').slice(2).map(el => {
    return el[0] + el.substring(1).toLowerCase();
  });
  return tmp.join('');
}

// get notification type based on is_read status
const getType = (item) => {
  return item.is_read ? 'read' : 'unread';
}

const parseRows = (rows) => {
  return rows.map(row => parseRow(row));
}

const parseRow = (row) => {
  let row_copy = { ...row };
  row_copy.dt_moment = moment(row.dt);
  row_copy.details = JSON.parse(row.details);

  return row_copy;
}

const findNotificationIndex = (existing_rows, item) => {
  if (!existing_rows.length) {
    return 0;
  }

  for (const [index, row] of existing_rows.entries()) {
    // if current row created later the row we are checking skip
    if (row.dt_moment.isAfter(item.dt_moment)) {
      continue;
    }

    if (row.dt_moment.isSame(item.dt_moment)) {
      // if by any chance we have stumbled upon the same item id it means we got duplicate
      // utilised when we re-load same page in autoloader
      // in this case let's return null and don't insert item lateron
      if (row.id === item.id) {
        return null;
      }
      continue;
    }

    return index;
  }

  return existing_rows.length;
}

export {
  EventType,
  getComponentName,

  getType,

  findNotificationIndex,
  PAGE_SIZE,

  parseRows,
  parseRow,
};
