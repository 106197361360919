<template>
  <div class="confirmation-countdown">
    <div v-if="jobId" class="wrapper">
      <el-tag
        size="small"
        type="primary'"
        @click.stop="openConfirmationDialog"
      >
        <div style="cursor: pointer;">
          {{ display }}
        </div>
      </el-tag>
    </div>
    <div v-else-if="shopsTimers.length > 1" class="wrapper">
      <el-dropdown @command="openSettings">
        <el-tag
          size="small"
          type="primary"
        >
          <div style="cursor: pointer;">
            {{ shopsTimers[0].display }}
          </div>
        </el-tag>
        <el-dropdown-menu slot="dropdown" style="width:450px;">
          <div style="text-align:center;">Confirmations Automatically Send:</div>
          <el-dropdown-item
            v-for="timer in shopsTimers"
            :key="timer.name"
            :command="timer.shop_id"
            >
            <el-row>
              <el-col :span="16">
                {{ timer.name }}
              </el-col>
              <el-col :span="8" style="text-align: right;">
                {{ timer.display }}
              </el-col>
            </el-row>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-else-if="shopsTimers.length === 1">
      <el-tag
        size="small"
        type="primary"
        @click="openSettings(shopsTimers[0].shop_id)"
      >
        <div style="cursor: pointer;">{{ shopsTimers[0].display }}</div>
      </el-tag>
    </div>
    <div v-else>
      <el-tag
        size="small"
        type="primary"
        @click="openTextPackageMessage"
      >
        <div style="cursor: pointer;">Text Confirmations</div>
      </el-tag>
    </div>
    <confirmation-settings-dialog
      :visible="settingsDialogVisible"
      :shopId="selectedShopId"
      :settings="selectedSettings"
      @setUpdatedSettings="setUpdatedSettings"
      @close="closeSettings"
    />
  </div>
</template>

<script>
import ConfirmationSettingsDialog from '@/components/modals/ConfirmationSettingsDialog/Index.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['jobId', 'scheduler'],
  data() {
    return {
      selectedShopId: null,
      settingsDialogVisible: false,
      selectedSettings: {}
    }
  },
  computed: {
    ...mapGetters(
      'confirmationsSettings',
      ['shopsSettings', 'shopsTimers', 'jobPageTimer', 'schedulerJobsTimers']
    ),
    display() {
      if (this.scheduler) {
        return this.schedulerJobsTimers[this.jobId]
      }

      return this.jobPageTimer[this.jobId]
    }
  },
  methods: {
    openTextPackageMessage() {
      this.$messageBox.alert('Ask us about setting up a texting package to use automated confirmations.')
    },
    openSettings(shopId) {
      this.selectedShopId = shopId
      var result = this.shopsSettings.filter(settings => {
        return settings.shop_id === shopId
      })
      this.selectedSettings = result[0]
      this.settingsDialogVisible = true
    },
    openConfirmationDialog() {
      this.$emit('openConfirmationDialog')
    },
    setUpdatedSettings() {
      this.$emit('setUpdatedShopsSettings')
    },
    closeSettings() {
      this.settingsDialogVisible = false
      this.selectedSettings = {}
    }
  },
  components: {
    ConfirmationSettingsDialog
  }
}
</script>
<style lang="scss" scoped>
.confirmation-countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-divider--vertical {
    margin: 0 $padding-xl;
  }
  .el-divider--horizontal {
    margin: $padding-sm 0;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    > * {
      display: flex;
      align-items: center;
      margin: $padding-xs 0;
      .label {
        font-weight: bold;
        margin-right: $padding-xxs;
      }
      & + * {
        margin-left: $padding-md;
      }
    }
    .el-tag {
      cursor: pointer;
    }
  }
}

@include media-breakpoint-down(sm) {
  .confirmation-countdown {
    flex-direction: column;
  }
}

</style>
