<template>
  <div>
    <el-divider class="t-my-6" />

    <div class="t-text-gray-400 t-mb-4">Short Code</div>

    <el-input
      v-model="short_code"
      placeholder="Enter Short Code"
      @change="updateShortCodeHandler"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  data() {
    return {
      short_code: null
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data'])
  },

  async mounted() {
    this.short_code = this.data.short_code
  },

  methods: {
    ...mapActions(useVendorStore, ['updateShortCode']),

    async updateShortCodeHandler(val) {
      try {
        await this.updateShortCode(val)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>
