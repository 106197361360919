<template>
  <div
    class="consumer-search-bar-mobile"
    :class="{ focused }"
    @click="$emit('close-blur')"
  >
    <div class="consumer-search-bar-mobile-container" @click.stop>
      <div class="consumer-search-bar-mobile-input">
        <slot name="reference" />
      </div>
      <div
        class="consumer-search-bar-mobile-results consumer-search-dropdown"
        @click="close"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['focused'],
  methods: {
    close() {
      setTimeout(() => {
        this.$emit('close-blur')
      }, 100)
    }
  }
}
</script>
<style scoped lang="scss">
@keyframes searchKF {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.consumer-search-bar-mobile {
  .consumer-search-bar-mobile-results {
    display: none;
  }
  &.focused {
    animation-name: searchKF;
    animation-duration: 0.1s;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: calc(100vh + 50px);
    z-index: 1000;
    padding: 80px $padding-xxs 30px;
    left: 0;
    top: 0;
    .consumer-search-bar-mobile-results {
      display: block;
    }
    .consumer-search-bar-mobile-container {
      background: #fafafa;
      border-radius: 3px;
      width: 100%;
      box-shadow: 0px 4px 8px rgb(0 0 0 / 30%), 0px 0px 2px rgb(0 0 0 / 40%),
        0px 0px 1px rgb(0 0 0 / 30%);
    }
    .consumer-search-bar-mobile-input {
      padding: $padding-lg $padding-md;
    }
  }
}
</style>
