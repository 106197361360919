import { make } from 'vuex-pathify'
import TwilioPhoneNumber from '@/scripts/objects/twilio_phone_number'

const state = {
  forUser: []
}

const mutations = make.mutations(state)

const actions = {
  async getForUser ({ commit }) {
    const { data } = await TwilioPhoneNumber.getForUser()
    commit('SET_FOR_USER', data)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
