<template>
  <el-dialog
    v-if="tech"
    :title="`Reschedule all of ${tech.name}'s jobs for today`"
    :visible="value"
    width="470px"
    append-to-body
    @opened="onOpen"
    @close="close">
    <div class="install-time-picker" ref="div">
      <div class="quick-options-picker">
        <quick-buttons :value="newDate" @click="newDate = $event" />
      </div>
      <div class="install-time-picker-container">
        <input id="install-time-picker" />
        <AirbnbStyleDatepicker
          ref="asd"
          :dateOne="newDate"
          @date-one-selected="newDate = $event"
          inline
          triggerElementId="install-time-picker"
          mode="single"
          :monthsToShow="1"
          :showShortcutsMenuTrigger="false"
          @focusin.native="focus = 'date'"
        >
          <i class="el-icon-arrow-left" slot="previous-month-icon" />
          <i class="el-icon-arrow-right" slot="next-month-icon" />
        </AirbnbStyleDatepicker>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">Cancel</el-button>
      <el-button type="primary" @click="rescheduleJobs">Update</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Vue from 'vue'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
import QuickButtons from '../../Helpers/InstallTimePicker/QuickButtons'
import actions from '@/store/modules/scheduler_v3/types'
import { call } from 'vuex-pathify'

const datepickerOptions = { sundayFirst: true }

Vue.use(AirbnbStyleDatepicker, datepickerOptions)

export default {
  props: ['value', 'date', 'job_ids', 'tech'],
  components: { QuickButtons },
  data() {
    return {
      newDate: null
    }
  },
  methods: {
    async rescheduleJobs() {
      try {
        const h = this.$createElement;
        await this.$msgbox({
          message: h('p', null, [
            h('span', null, 'Update the scheduled date for '),
            h('b', { style: 'color: #F56C6C' }, `ALL ${this.job_ids.length} JOBS`),
            h('span', null, ` on this route to ${this.newDate}?`),
          ]),
          type: 'warning',
          confirmButtonText: 'Yes',
          showCancelButton: true,
          cancelButtonText: 'No',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              try {
                this.$root.$emit('closeSchedulerJobDetails')

                const req = {
                  job_ids: this.job_ids,
                  update: {
                    install_date: this.newDate,
                    scheduled_date: this.newDate
                  }
                }

                await this.$unum.job().post('data/update/bulk', req)

                await this.$unum.confirmation().post('/job/setConfirmationDateTime', {
                  jobIds: this.job_ids,
                  scheduledDate: this.newDate
                })

                this.$message(
                  {
                    type: 'success',
                    message: `Updated scheduled date for ${this.job_ids.length} jobs`
                  }
                )
                this.getSchedulerData()
              } catch (error) {
                this.$message({ type: 'error', message: 'Failed to update scheduled date' })
              }
            }
            this.$emit('input', false)
            done()
          }
        })
      } catch {}
    },
    close() {
      this.$emit('input', false)
    },
    onOpen() {
      this.newDate = this.date
      /* eslint-disable no-unused-expressions */
      this.$refs.asd?.setFocusedDate(this.newDate)
    },
    getSchedulerData: call(actions.getSchedulerData),
    viewJobDetails: call(actions.getJobDetails)
  }
}
</script>
<style lang="scss">
  @import "../../Helpers/InstallTimePicker/InstallTimePicker.scss";
</style>
