var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "sidebar-button",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("div", { staticClass: "sidebar-button-image" }, [
        _c("img", { attrs: { src: _vm.image, alt: _vm.label } })
      ]),
      _vm.count
        ? _c(
            "div",
            { staticClass: "sidebar-badge", class: { expanded: _vm.expanded } },
            [_vm.expanded ? _c("span", [_vm._v(_vm._s(_vm.count))]) : _vm._e()]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "sidebar-label", class: { expanded: _vm.expanded } },
        [_c("span", [_vm._v(" " + _vm._s(_vm.label) + " ")])]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }