var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-row",
                [
                  _c(
                    "gb-card",
                    { attrs: { title: "My Open Tickets" } },
                    [
                      _c("ticket-table", {
                        attrs: { data: _vm.data, loading: _vm.loadingHistory }
                      }),
                      _vm.hasMore
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "float-right",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("changeTab", "history")
                                    }
                                  }
                                },
                                [_vm._v("See More")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _vm.showCharts
                    ? _c("ticket-charts", {
                        attrs: { data: _vm.stats, loading: _vm.loadingStats }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }