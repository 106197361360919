<template>
  <div
    class="t-flex t-justify-between t-space-x-6 t-items-center t-px-6 t-py-4 t-border-b t-border-b-gray-200 t-shadow"
  >
    <div class="t-flex t-flex-grow t-justify-between t-items-center">
      <div class="t-flex t-items-center">
        <div class="t-text-gray-400 t-text-base t-mr-6">QBO Account Mapping</div>
      </div>

      <div v-if="saving" class="t-flex t-text-yellow-700 t-items-center t-space-x-2">
        <i class="el-icon-loading t-text-xl" />
        <div>Saving...</div>
      </div>

      <div v-if="!saving && saved" class="t-flex t-text-green-600 t-items-center t-space-x-2">
        <i class="material-icons-outline md-check" />
        <div>Updated!</div>
      </div>
    </div>

    <i
      class="material-icons-outline md-clear t-text-gray-600 t-text-lg t-cursor-pointer"
      @click="close"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useQboStore } from '@/components/modals/QuickBooksAccountMappingDialog/qbo.store.js'

export default {
  computed: {
    ...mapState(
      useQboStore,
      [
        'saving'
      ]
    ),
    ...mapWritableState(useQboStore, ['saved'])
  },

  watch: {
    saved: function (val) {
      this.revert = true
      if (val) {
        setTimeout(() => {
          this.saved = false
        }, 3000)
      }
    }
  },

  methods: {
    ...mapActions(useQboStore, ['close'])
  }
}
</script>
