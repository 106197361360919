var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "80px",
        width: "1200px",
        "custom-class": "green-header-dialog",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-base t-text-white",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-document" }),
          _c("span", { staticClass: "t-ml-3" }, [_vm._v("Tech Pickup details")])
        ]
      ),
      _vm.fetching
        ? _c("LoadingSpinner")
        : _c(
            "div",
            {
              staticClass: "t-pt-3 t-pb-8 t-px-8 t-bg-gray-200 drop-wrapper",
              class: {
                "dragging-over": _vm.dragging
              },
              on: {
                drop: _vm.onDrop,
                dragleave: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragEnter($event)
                },
                dragenter: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragLeave($event)
                }
              }
            },
            [
              _c("div", { staticClass: "t-mb-3 t-flex t-justify-between" }, [
                _c(
                  "div",
                  [
                    _c("documents", {
                      attrs: {
                        id: _vm.data.id,
                        files: _vm.dropFiles,
                        type: _vm.documentType
                      },
                      on: {
                        "clear-files": function($event) {
                          _vm.dropFiles = null
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "min-content",
                        attrs: {
                          icon: "el-icon-download",
                          type: "warning",
                          size: "small",
                          loading: !!_vm.loading
                        },
                        on: {
                          click: function($event) {
                            return _vm.getPdf(true)
                          }
                        }
                      },
                      [_vm._v(" Download ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "min-content",
                        attrs: {
                          icon: "el-icon-printer",
                          type: "warning",
                          size: "small",
                          loading: !!_vm.loading
                        },
                        on: {
                          click: function($event) {
                            return _vm.getPdf(false)
                          }
                        }
                      },
                      [_vm._v(" Print ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "min-content",
                        attrs: {
                          icon: "el-icon-delete",
                          type: "danger",
                          size: "small"
                        },
                        on: { click: _vm.remove }
                      },
                      [_vm._v(" Delete ")]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "t-bg-white t-rounded t-shadow t-px-8 t-py-3" },
                [
                  _c(
                    "div",
                    { staticClass: "t-flex t-items-center t-pt-4 t-pb-2" },
                    [
                      _vm.shop.logo.length > 0
                        ? _c("div", { staticClass: "t-w-56 t-mr-8" }, [
                            _c("img", {
                              staticClass: "t-w-full",
                              attrs: { src: _vm.shop.logo, alt: "shop logo" }
                            })
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "t-flex-grow t-text-right" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-text-lg t-font-medium t-text-gray-700 t-mb-2"
                          },
                          [_vm._v(" " + _vm._s(_vm.shop.name) + " ")]
                        ),
                        _c("div", { staticClass: "t-text-gray-600" }, [
                          _vm._v(_vm._s(_vm.shop.address))
                        ]),
                        _c("div", { staticClass: "t-text-gray-600" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.shop.address_city) +
                              ", " +
                              _vm._s(_vm.shop.address_state) +
                              " " +
                              _vm._s(_vm.shop.address_zip) +
                              " "
                          )
                        ])
                      ])
                    ]
                  ),
                  _c("el-divider"),
                  _c(
                    "div",
                    {
                      staticClass:
                        "t-text-center t-text-lg t-text-gray-700 t-pb-10"
                    },
                    [
                      _vm._v(" Tech Pickup "),
                      _c("span", { staticClass: "t-ml-2" }, [
                        _vm._v("#" + _vm._s(_vm.data.internalId))
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "t-flex t-pb-8" }, [
                    _c(
                      "div",
                      { staticClass: "t-flex-grow" },
                      [
                        _c("InfoRow", {
                          attrs: {
                            name: "Date picked up",
                            value: _vm.createdAt
                          }
                        }),
                        _c("InfoRow", {
                          attrs: {
                            name: "Picked up by",
                            value: _vm.data.createdByName
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "t-bg-green-400 t-w-56 t-flex t-flex-col t-items-center\n            t-justify-center t-text-white t-py-3 t-h-[144px]"
                      },
                      [
                        _c("div", { staticClass: "t-text-lg" }, [
                          _vm._v("Quantity picked up")
                        ]),
                        _c("div", { staticClass: "t-text-3xl" }, [
                          _vm._v(_vm._s(_vm.data.unitsCount))
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "t-flex t-pb-8" }, [
                    _c("div", { staticClass: "t-flex-grow" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "t-flex t-border-0 t-border-t t-border-solid t-border-gray-100 t-pt-3 t-mt-4"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "t-w-48 t-text-right t-text-gray-500 t-text-xs t-pt-[3px] t-flex-shrink-0"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.data.notes ? "Notes" : "") +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "t-ml-4 t-flex-grow" },
                            [
                              _vm._l(_vm.data.notes, function(i) {
                                return _c(
                                  "div",
                                  { key: i.id, staticClass: "t-mb-1 t-pb-2" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "t-text-gray-700" },
                                      [_vm._v(_vm._s(i.note))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "t-flex t-text-gray-400 t-text-xs"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(i.createdAt)
                                            ) + ","
                                          )
                                        ]),
                                        _c("div", { staticClass: "t-ml-2" }, [
                                          _vm._v(_vm._s(i.createdByName))
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "t-mb-1 t-pb-2 t-text-blue-500 t-cursor-pointer t-italic",
                                  attrs: { loading: _vm.addingNote },
                                  on: { click: _vm.addNote }
                                },
                                [_vm._v(" + Add Note ")]
                              )
                            ],
                            2
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("Table", {
                    attrs: {
                      lines: _vm.data.lines,
                      organizationId: _vm.shop.organization_id
                    },
                    on: { "remove-line": _vm.removeLine }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "t-flex t-justify-between t-mt-3 t-text-gray-500 t-italic"
                },
                [
                  _c("div", [
                    _vm._v(
                      "Created on " +
                        _vm._s(_vm.createdAt) +
                        " by " +
                        _vm._s(_vm.createdBy)
                    )
                  ]),
                  _c("div", [
                    _vm._v(
                      "Modified on " +
                        _vm._s(_vm.updatedAt) +
                        " by " +
                        _vm._s(_vm.updatedBy)
                    )
                  ])
                ]
              )
            ]
          ),
      _c("a", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "dl",
        attrs: { href: this.pdfdata, download: _vm.filename }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }