var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vc-editable-input" }, [
    _c("input", {
      directives: [
        { name: "model", rawName: "v-model", value: _vm.val, expression: "val" }
      ],
      ref: "input",
      staticClass: "vc-input__input",
      attrs: {
        "aria-label": _vm.desc ? _vm.label + "(" + _vm.desc + ")" : _vm.label
      },
      domProps: { value: _vm.val },
      on: {
        keydown: _vm.handleKeyDown,
        input: [
          function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.val = $event.target.value
          },
          _vm.update
        ]
      }
    }),
    _c("span", { staticClass: "vc-input__label" }, [_vm._v(_vm._s(_vm.label))]),
    _c("span", { staticClass: "vc-input__desc" }, [_vm._v(_vm._s(_vm.desc))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }