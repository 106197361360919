<template>
  <div
    class="t-flex t-justify-between t-space-x-6 t-items-center t-px-6 t-py-4 t-border-b t-border-b-gray-200 t-shadow"
  >
    <div class="t-flex t-flex-grow t-justify-between t-items-center">
      <div class="t-flex t-items-center">
        <div class="t-text-gray-400 t-text-base t-mr-6">Vendor Profile</div>

        <div class="t-flex t-items-center">
          <div
            v-if="isCSR"
            class="vendor-badge"
            :style="{ backgroundColor: vendorTypes[vendorType.CSR].color }"
          >
            {{ vendorTypes[vendorType.CSR].name }}
          </div>
          <div
            v-if="isSaleSource"
            class="vendor-badge"
            :style="{ backgroundColor: vendorTypes[vendorType.SALESOURCE].color }"
          >
            {{ vendorTypes[vendorType.SALESOURCE].name }}
          </div>
          <div
            v-if="isSalesRep"
            class="vendor-badge"
            :style="{ backgroundColor: vendorTypes[vendorType.SALESREP].color }"
          >
            {{ vendorTypes[vendorType.SALESREP].name }}
          </div>
          <div
            v-if="isTech"
            class="vendor-badge"
            :style="{ backgroundColor: vendorTypes[vendorType.TECH].color }"
          >
            {{ vendorTypes[vendorType.TECH].name }}
          </div>
          <div
            v-if="isLocation"
            class="vendor-badge"
            :style="{ backgroundColor: vendorTypes[vendorType.LOCATION].color }"
          >
            {{ vendorTypes[vendorType.LOCATION].name }}
          </div>
          <div
            v-if="isSupplier"
            class="vendor-badge"
            :style="{ backgroundColor: vendorTypes[vendorType.SUPPLIER].color }"
          >
            {{ vendorTypes[vendorType.SUPPLIER].name }}
          </div>
        </div>
      </div>

      <div v-if="saving" class="t-flex t-text-yellow-700 t-items-center t-space-x-2">
        <i class="el-icon-loading t-text-xl" />
        <div>Saving...</div>
      </div>

      <div v-if="!saving && saved" class="t-flex t-text-green-600 t-items-center t-space-x-2">
        <i class="material-icons-outline md-check" />
        <div>Updated!</div>
      </div>
    </div>

    <i
      class="material-icons-outline md-clear t-text-gray-600 t-text-lg t-cursor-pointer"
      @click="close"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useVendorStore, VendorType } from '@/components/Vendors/Profile/vendor.store'

export default {
  computed: {
    ...mapState(
      useVendorStore,
      [
        'data',
        'saving',
        'vendorTypes',
        'isCSR',
        'isSaleSource',
        'isSalesRep',
        'isTech',
        'isLocation',
        'isSupplier'
      ]
    ),
    ...mapWritableState(useVendorStore, ['saved', 'revert']),
    vendorType() {
      return VendorType
    }
  },

  watch: {
    saved: function (val) {
      this.revert = true
      if (val) {
        setTimeout(() => {
          this.saved = false
        }, 3000)
      }
    }
  },

  methods: {
    ...mapActions(useVendorStore, ['close'])
  }
}
</script>

<style scoped>
.vendor-badge {
  @apply t-px-2 t-py-0.5 t-rounded t-text-white t-text-xs t-uppercase t-mr-3;
}
</style>
