<template>
  <transition name="fade">
    <div
      class="checkbox-tag"
      :class="{ [color]: true, checked: !!value, focused }"
      v-if="!!value || visible"
    >
      <label>
        <input
          type="checkbox"
          @focus="focus"
          @blur="blur"
          @change="change"
          :checked="!!value"
        />
        {{ label }}
      </label>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    label: String,
    value: [Boolean, Number],
    color: String,
    visible: [Boolean]
  },
  data() {
    return {
      focused: false
    }
  },
  methods: {
    focus() {
      this.focused = true
    },
    blur() {
      this.focused = false
    },
    change(v) {
      this.$emit('input', v.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-tag {
  color: $primary;
  cursor: pointer;
  &.purple {
    color: #805ad5;
  }
  border: 1px solid currentColor;
  height: 22px;
  padding: $padding-xxs $padding-xs;
  display: inline-flex;
  align-items: center;
  border-radius: 18px;
  &.focused {
    box-shadow: 0px 0px 3px 0px currentColor;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  cursor: pointer;
  label {
    cursor: pointer;

    color: currentColor;
    text-transform: none;
    line-height: 14.4px;
    font-size: 12px;
    font-weight: 400;
  }
  &.checked {
    background: currentColor;
    label {
      color: #fff;
    }
  }
  & + & {
    margin-left: $padding-xs;
  }
}
</style>
