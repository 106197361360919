<template>
  <div :class='{vendorEditWrapper: true, documentDragDrop: amDragging}' v-doc-drop-zone:vendor.popover="{id: localVendor.id, shopId: shop ? shop.id : null}" @filedrop="documentDrop">
    <div class='form-row' v-if='!isInModal'>
      <div class='col-12 text-right'>
        <a href="javascript:void(0)" @click="cancelVendorEditPressed()" style="margin-left:5px;margin-bottom:5px;float:right;" class="btn btn-sm btn-primary">CLOSE</a>
        <a v-if='!localVendor.cleanCheck || (localVendor && localVendor.id === -1)' href="javascript:void(0)" @click="saveVendorEditPressed()" style="margin-left:5px;margin-bottom:5px;float:right;" class="btn btn-sm btn-primary">{{localVendor.id > 0 ? 'UPDATE' : 'SAVE'}}</a>
      </div>
    </div>
    <br>
    <div v-if="localVendor.id < 0">
      <el-button
        plain
        size="small"
        @click="showNationalVendorSearch"
        icon="el-icon-search"
      >
        Search National Database
      </el-button>
      <el-divider content-position="left">
        <span class="t-text-gray-600">or enter information manually</span>
      </el-divider>
    </div>
    <div class='form-row'>
      <div v-if="localVendor.national_vendor_id" class="form-group col-md-1">
        <label for='vendorNVID'>NVID <v-icon v-b-popover.hover='"ID of National Warehouse this Supplier was Created From"' name='info-circle' scale='.8'/></label>
        <input id='vendorNVID' v-model='localVendor.national_vendor_id' class='form-control form-control-sm autocomplete-off' disabled>
      </div>
      <div class='form-group col-md-5'>
        <label for='vendorName'>Name</label>
        <input id='vendorName' v-model='localVendor.name' class='form-control form-control-sm autocomplete-off'>
      </div>
      <div class='form-group col-md-2'>
        <label for='vendorShortCode'>Short Code</label>
        <input id='vendorShortCode' v-model='localVendor.short_code' @keydown="shortCodeKeyDown" class='form-control capitalize form-control-sm autocomplete-off'>
      </div>
    </div>
    <div class='form-row'>
      <div class='form-group col-md-4'>
        <label>Status</label>
        <select v-model='localVendor.status' class="form-control form-control-sm" >
          <option value='active'>Active</option>
          <option value='disabled'>Disabled</option>
        </select>
      </div>
      <div class='form-group col-md-8'>
        <label for='vendorContact'>Contact</label>
        <input id='vendorContact' v-model='localVendor.contact' class='form-control form-control-sm autocomplete-off'>
      </div>
    </div>
    <div class='form-row'>
      <div class='form-group col-md-4'>
        <Phone ref='phoneComponent' :title='"Phone"' :value.sync='localVendor.phone' :placeholder="''" :required='false' />
      </div>
      <div class='form-group col-md-4'>
        <Phone ref='faxComponent' :title="'Fax'" :value.sync='localVendor.fax' :placeholder="''" :required='false'/>
      </div>
      <div class='form-group col-md-4'>
        <Email ref="emailComponent" :title="'Email'" :value.sync='localVendor.email' :placeholder="''" :required='false' />
      </div>
    </div>
    <div class='form-row'>
      <div class='form-group col-md-12'>
        <label for='vendorAddress'>Address</label>
        <address-input id='vendorAddress' v-model='localVendor.g_address' with-po-box popper-fix/>
      </div>
    </div>
    <div class='form-row'>
      <div class='form-group col-md-12'>
        <label>Notes</label>
        <b-form-textarea v-model='localVendor.notes' placeholder='Enter Supplier Notes...' rows='5'></b-form-textarea>
      </div>
    </div>
    <div class='form-row'>
      <div class='form-group col-md-6'>
        <label>Supplier Color</label>
        <br>
        <Sketch v-model='localVendor.color'/>
      </div>
    </div>
    <div class='form-row'>
      <div class='form-group col-md-12' v-if='localVendor.id > 0'>
        <label>Documents <a href="javascript:void(0)" v-doc-button='{type: vendorType, id: localVendor.id, shopId: shop ? shop.id : null}'>Add New +</a></label>
        <DocumentWellContent :type='vendorType' :documents='documents'/>
      </div>
    </div>
    <VendorSearchDialog v-if='!isInModal' @selectClicked='selectedNationalVendor'/>
  </div>
</template>

<script>
import Vendor from '@/scripts/objects/vendor'
import Phone from '@/components/Helpers/Phone.vue'
import Email from '@/components/Helpers/Email.vue'
import Sketch from '@/assets/customComponents/sketchColorPicker/components/Sketch.vue'
import VendorSearchDialog from '@/components/modals/VendorSearchDialog.vue'
import DynamicDocument from '@/scripts/objects/document_.js'
import DocumentWellContent from '@/components/DynamicDocument/DocumentWellContent.vue'
import faFile from '@fortawesome/fontawesome-free-solid/faFile'
import { StateObjects } from '@/scripts/helpers/helpers.js'
import { VendorMixin } from '@/scripts/helpers/vendor.helpers.js'
import { getDocumentTagColorWithType, getDocumentTagNameWithType } from '@/scripts/helpers/document.helpers'

export default {
  props: ['parentVendor', 'shop', 'filePickerKey', 'inModal'],
  data () {
    return {
      states: StateObjects,
      warehouseList: [],
      selectedWarehouse: null,
      localVendor: this.parentVendor || new Vendor({}),
      amDragging: false,
      fileDropped: {},
      docTypes: [{label: 'Contract', value: 'contract'}],
      testId: this.$id,
      documents: [],
      isInModal: this.inModal || false
    }
  },
  beforeDestroy: function () {
    this.$root.$off('updateDocuments', this.updateDocuments)
  },
  created () {
    this.$root.$on('updateDocuments', this.updateDocuments)
  },
  mounted () {
    Vendor.getNationalWarehouseList(response => {
      // lets build out the label for the multiselect
      const tempArr = []
      for (var i = 0; i < response.length; i++) {
        var tempItem = response[i]
        tempItem.searchLabel = tempItem.type.toUpperCase() + ': ' + tempItem.name
        tempArr.push(tempItem)
      }
      this.warehouseList = tempArr
    })
    var self = this
    if (this.localVendor && this.shop && !this.inModal) {
      DynamicDocument.getDynamicDocuments(this.shop?.id, this.vendorType, this.localVendor.id, function (res) {
        self.documents = res
      })
    }
  },
  methods: {
    getDocumentTagNameWithType,
    getDocumentTagColorWithType,
    updateDocuments () {
      var self = this
      if (!this.inModal) {
        DynamicDocument.getDynamicDocuments(this.shop?.id, this.vendorType, this.localVendor.id, function (res) {
          self.documents = res
        })
      }
    },
    cancelVendorEditPressed () {
      this.$emit('cancel')
    },
    saveVendorEditPressed () {
      var self = this
      if (this.localVendor.id === -1 || this.localVendor.id === '-1') {
        this.localVendor.organization_id = this.shop.organization_id
      }
      if (this.localVendor.name === null || this.localVendor.name === '') {
        alert('The supplier must have at minimum a name to be saved. Please enter a name to save.')
      } else {
        this.localVendor.createUpdateVendor(this.shop, function (response) {
          self.$emit('save', response)
        })
      }
    },
    showNationalVendorSearch () {
      this.$root.$emit('bv::show::modal', 'vendorSearchDialog')
    },
    selectedNationalVendor (val) {
      const { g_address: g } = val
      var tempObj = {
        ...(g
          ? {
            address: g.address,
            city: g.city,
            state: g.state,
            zip: g.zip,
            g_address: g
          }
          : {
            address: val.address_street,
            city: val.address_city,
            state: val.address_state,
            zip: val.address_zip
          }),
        email: val.email,
        contact: val.manager,
        status: 'active',
        name: this.getVendorTitle(val.type) + ':' + val.name,
        phone: val.phone,
        fax: val.fax,
        shop_id: 4,
        color: this.getVendorColor(val.type),
        national_vendor_id: val.id,
        short_code: val.short_code
      }
      this.localVendor = new Vendor(tempObj)
    },
    documentDragenter: function () {
      this.amDragging = true
    },
    documentDragleave: function () {
      this.amDragging = false
    },
    documentDragover: function () {
      this.amDragging = true
    },
    documentDrop: function (e) {
      this.amDragging = false
      // open dialog
      this.$root.$emit('bv::show::modal', 'dynamicDocumentModal')
      this.$root.$emit('documentFilePickerDrop', e)
    },
    shortCodeKeyDown (evt) {
      const allowedKeys = [8, 46]
      if (this.localVendor && this.localVendor.short_code && this.localVendor.short_code.length >= 4) {
        if (!allowedKeys.includes(evt.keyCode)) {
          evt.preventDefault()
        }
      }
    }
  },
  watch: {
    vendorSearchValue (val) {
    },
    selectedWarehouse (val) {
      this.localVendor = {
        name: val.name || '',
        address: val.address_street || '',
        city: val.address_city || '',
        state: val.address_state || '',
        zip: val.address_zip || '',
        email: val.email || '',
        phone: val.phone || '',
        fax: val.fax || '',
        color: val.color || '#000000'
      }
    },
    parentVendor (val) {
      this.localVendor = val
      this.documents = []
      this.updateDocuments()
    }
  },
  computed: {
    faFile () {
      return faFile
    },
    vendorType () {
      return (this.shop?.shop_services_config.gb_vendor ? 'gb_vendor' : 'vendor')
    }
  },
  components: {
    Phone,
    Email,
    Sketch,
    VendorSearchDialog,
    DocumentWellContent
  },
  mixins: [VendorMixin]
}
</script>

<style>

</style>
