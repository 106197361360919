var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-col",
        { attrs: { lg: 12, sm: 24 } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "hide-required-asterisk": true,
                "label-width": "60px",
                size: "mini",
                disabled: _vm.disabled
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("SID")]
                  ),
                  _c("el-input", {
                    attrs: { value: _vm.twilioAccount.sid, disabled: true }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "name" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("Name")]
                  ),
                  _c("el-input", {
                    attrs: { placeholder: "Enter a name" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "status" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("Status")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "Select a status" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Active", value: "active" }
                      }),
                      _c("el-option", {
                        attrs: { label: "Suspended", value: "suspended" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        plain: "",
                        loading: _vm.loading
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" Update ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }