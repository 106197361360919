<template>
  <div class="document-tile">
    <i class="material-icons-outline md-insert_drive_file"/>
    <span>Documents</span>
    <div class="job-documents" v-if="documents" :class="{ expanded }">
    </div>
    <gb-dropdown-menu
      :multiselect="false"
      :options="options"
      @select="addNew"
      :keep-open="false"
    >
      <md-circle-button
        class="add-icon"
        slot="trigger"
        icon="add_circle"
        primary
        ref="addButton"
      />
      <div slot="menu-header">Select document type to upload</div>
      
      <div class="document-menu-item" slot="menu-option" slot-scope="item">
        <i
          class="material-icons md-fiber_manual_record"
          :style="{ color: item.color }"
        />
        <span>
          {{ item.name }}
        </span>
      </div>
    </gb-dropdown-menu>
    <div class="document-list t-flex">
      <!-- <div class="no-data" v-if="!documents.length && !fetchingDocuments">No documents added</div> -->
      <list-item
        v-for="doc in numberedDocs"
        :key="doc.id"
        :doc="doc"
        @click.native="openPreview(doc)"
      />
    </div>
    <filestack-picker
      v-model="filepicker"
      :doc-type="adding && adding.code"
      :doc-label="adding && adding.name"
      @uploaded="onUpload"
      @closed="$emit('clear-files')"
    >
      <!-- <div slot="extra" class="t-flex t-justify-end" v-if="$job.vehicle">
        <gb-checkbox v-model="attachToVehicle">
          Attach to vehicle profile
        </gb-checkbox>
      </div> -->
    </filestack-picker>

    <file-preview-dialog v-model="previewDialog" :doc="preview" :dateFunc="dateFunc" :deleteFunc="deleteFunc"/>
  </div>
</template>

<script>
import moment from 'moment'
import ListItem from './ListItem'
import FilePreviewDialog from '@/components/modals/FilePreviewDialog/Index'
import { getNumberedDocList } from '@/scripts/helpers/document.helpers'
import { documentsConfig } from '@/constants/documents.config'
import * as filestack from 'filestack-js'
const FILESTACK_KEY = 'AcGSAppE1TjCSLtiIUYNpz'
const client = filestack.init(FILESTACK_KEY)
export default {
  data() {
    return {
      filepicker: false,
      adding: null,
      options: [], //Object.values(documentsConfig[this.type]?.docOptions || {}),
      expanded: false,
      preview: null,
      previewDialog: false,
      uploading: false,
      attachToVehicle: false,
      documents: [],
      fetchingDocuments: false
    }
  },
  props: ['files', 'type', 'id'],
  components: {
    ListItem,
    FilePreviewDialog
  },
  computed: {
    numberedDocs() {
      return getNumberedDocList(this.documents)
    }
  },
  methods: {
    addNew(doc) {
      this.adding = doc
      if (this.files?.length) {
        this.uploadFile(this.files[0])
      } else {
        this.filepicker = true
      }
    },
    async onUpload(file) {
      // await this.$job.createDoc(file, this.adding.id, this.attachToVehicle)
      try {
        await this.$unum.documents().post("data/", {
          type_id: this.type,
          tag_id: this.adding.id,
          reference_id: this.id,
          file
        })
        this.$message({ type: 'success', message: 'Document added successfully'})
      } catch(err) {
        this.$message({ type: 'error', message: 'Failed to upload file' })
      }
      this.getDocuments()
      this.adding = null
      this.attachToVehicle = false
    },
    openPreview(doc) {
      this.previewDialog = true
      this.preview = doc
    },
    async uploadFile(file) {
      this.uploading = true
      const data = await client.upload(file)
      this.onUpload(data)
      this.uploading = false
      this.$emit('clear-files')
    },
    async getTags() {
      const {data} = await this.$unum.documents().get(`data/tags/${this.type}`)
      this.options = data
    },
    async getDocuments() {
      this.fetchingDocuments = true
      const {data} = await this.$unum.documents().get(`data/${this.type}/${this.id}`)
      this.documents = data
      this.fetchingDocuments = false
    },
    dateFunc(val) {
      return moment.utc(val).format('lll')
    },
    async deleteFunc(doc) {
      try {
        const {data} = await this.$unum.documents().delete(`data/${this.id}/${doc.id}`)
        this.$message({ type: 'success', message: 'Document deleted successfully'})
        this.getDocuments()
      } catch (err) {
        this.$message({ type: 'error', message: 'Failed to delete file' })
      }
    }
  },
  watch: {
    previewDialog(v) {
      if (!v) {
        this.preview = null
      }
    },
    files(v) {
      if (v?.length) {
        // eslint-disable-next-line no-unused-expressions
        this.$refs?.addButton?.$el?.click()
      }
    }
  },
  mounted() {
    // go get documents
    // get options
    this.getTags()
    this.getDocuments()
  }
}
</script>

<style lang="scss" scoped>
.job-documents {
  display: flex;
  flex-wrap: wrap;
}
</style>

<style lang="scss" scoped>
.document-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  i {
    transform: translateX(-$padding-xs);
  }
}
.no-data {
  font-style: italic;
}
.document-tile {
    display: flex;
    align-items: center;
    > i {
      color: rgba(0, 0, 0, 0.54);
      margin-right: $padding-xs;
    }
    span {
      font-size: 16px;
      line-height: 19px;
    }
    /* margin-bottom: $padding-xs; */
    .add-icon,
    .add-menu {
      margin-left: auto;
    }
    .add-icon {
      margin-right: -10px;
    }
  }
.document-list {
  margin-left: 10px;
}
</style>
