import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=162d6a25&"
import script from "./Table.vue?vue&type=script&lang=js&"
export * from "./Table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('162d6a25')) {
      api.createRecord('162d6a25', component.options)
    } else {
      api.reload('162d6a25', component.options)
    }
    module.hot.accept("./Table.vue?vue&type=template&id=162d6a25&", function () {
      api.rerender('162d6a25', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/WarehousePage/Returns/ViewDialog/Table.vue"
export default component.exports