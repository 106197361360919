<template>
  <div
    v-if="isSupplemental"
    class="job-status-tag"
    :style="{ background: status.supplementalColor }"
    :class="{ small, disabled }"
    @click="onClick"
  >
    Sup {{ status.label }}
  </div>
  <div
    v-else
    class="job-status-tag"
    :style="{ background: status.color }"
    :class="{ small, disabled }"
    @click="onClick"
  >
    {{ status.label }}
  </div>
</template>

<script>
import { jobStatusConfig } from '@/constants/job-details.config'
export default {
  props: { disabled: Boolean, small: Boolean, jobStatus: String, isSupplemental: Number },
  computed: {
    status() {
      return jobStatusConfig[this.jobStatus] || {}
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        window.location.reload()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.job-status-tag {
  color: #fff;
  padding: 0 $padding-xs;
  border-radius: 4px;
  font-size: $font-md;
  line-height: 20px;
  margin: 0 $padding-xs;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  &.small {
    font-size: $font-sm;
    font-weight: 400;
  }
  &.disabled {
    pointer-events: none;
  }
}
</style>
