var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "35px",
        height: "46px",
        viewBox: "0 0 35 46",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              x: "-25.9%",
              y: "-18.8%",
              width: "151.7%",
              height: "137.5%",
              filterUnits: "objectBoundingBox",
              id: "filter-1"
            }
          },
          [
            _c("feOffset", {
              attrs: {
                dx: "1",
                dy: "1",
                in: "SourceAlpha",
                result: "shadowOffsetOuter1"
              }
            }),
            _c("feGaussianBlur", {
              attrs: {
                stdDeviation: "1",
                in: "shadowOffsetOuter1",
                result: "shadowBlurOuter1"
              }
            }),
            _c("feColorMatrix", {
              attrs: {
                values:
                  "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.562581949 0",
                type: "matrix",
                in: "shadowBlurOuter1",
                result: "shadowMatrixOuter1"
              }
            }),
            _c(
              "feMerge",
              [
                _c("feMergeNode", { attrs: { in: "shadowMatrixOuter1" } }),
                _c("feMergeNode", { attrs: { in: "SourceGraphic" } })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "map-marker-23x31-active",
                transform: "translate(2.000000, 2.000000)"
              }
            },
            [
              _c("g", { attrs: { id: "Group", filter: "url(#filter-1)" } }, [
                _c("path", {
                  attrs: {
                    d:
                      "M13,5 C4.72727273,5 0,9.66666667 0,17.8333333 C0,26 11.7443182,40 13,40 C14.2556818,40 26,26 26,17.8333333 C26,9.66666667 21.2727273,5 13,5 Z",
                    id: "path3761",
                    stroke: _vm.success ? "#00E676" : "#FFF",
                    "stroke-width": _vm.success ? 2 : 1,
                    fill: _vm.fillColor,
                    "fill-rule": "nonzero",
                    "stroke-linecap": "round"
                  }
                }),
                _vm.count
                  ? _c("circle", {
                      attrs: {
                        id: "Oval",
                        stroke: _vm.success ? "#00E676" : "#FFF",
                        "stroke-width": _vm.success ? 2 : 1,
                        fill: _vm.fillColor,
                        "fill-rule": "nonzero",
                        "stroke-linecap": "round",
                        cx: "23",
                        cy: "6",
                        r: "6"
                      }
                    })
                  : _vm._e(),
                _vm.count
                  ? _c(
                      "text",
                      {
                        attrs: {
                          id: "1",
                          "font-family": "Poppins-Medium, Poppins",
                          "font-size": "9",
                          "font-weight": "400",
                          fill: "#FFF",
                          "text-anchor": "middle"
                        }
                      },
                      [
                        _c("tspan", { attrs: { x: "23", y: "9" } }, [
                          _vm._v(_vm._s(_vm.count))
                        ])
                      ]
                    )
                  : _vm._e()
              ]),
              _c("g", { attrs: { transform: _vm.getPath.transform } }, [
                _c("path", {
                  attrs: {
                    d: _vm.getPath.path,
                    id: "Shape",
                    fill: "#FFF",
                    "fill-rule": "nonzero"
                  }
                })
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }