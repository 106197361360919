var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: "Glaxis Inbox",
            appendToBody: true,
            id: "glaxisInbox",
            width: "1400px",
            "destroy-on-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            open: _vm.dialogAboutToAppear
          }
        },
        [
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-sm-8" }, [
              _c(
                "a",
                {
                  class: {
                    glaxisTabButton: true,
                    selected: _vm.selectedTab === "claims"
                  },
                  on: { click: _vm.claimsTabClicked }
                },
                [_vm._v("Claims")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: {
                    glaxisTabButton: true,
                    selected: _vm.selectedTab === "assignments"
                  },
                  on: { click: _vm.assignmentsTabClicked }
                },
                [_vm._v("Work Assignments")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: {
                    glaxisTabButton: true,
                    selected: _vm.selectedTab === "authorizations"
                  },
                  on: { click: _vm.authorizationsTabClicked }
                },
                [_vm._v("Authorizations")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: {
                    glaxisTabButton: true,
                    selected: _vm.selectedTab === "payments"
                  },
                  on: { click: _vm.paymentsTabClicked }
                },
                [_vm._v("Payments")]
              )
            ]),
            _c("div", { staticClass: "col-sm-4 " }, [
              _c(
                "a",
                {
                  staticClass: "glaxisTabButton pull-right",
                  on: { click: _vm.refreshTableClicked }
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: _vm.faSync } }),
                  _vm._v(" Refresh")
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row mb-2" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-8 col-xl-5 col-lg-5" },
              [
                _c("label", { attrs: { for: "#" } }, [_vm._v("Shops")]),
                _c("Multiselect", {
                  attrs: {
                    placeholder: "Select Shop...",
                    options: _vm.$store.state.shops,
                    label: "name",
                    "show-labels": false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "singleLabel",
                      fn: function(props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { margin: "0px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-11",
                                  staticStyle: {
                                    "font-size": "14px",
                                    "text-overflow": "ellipsis",
                                    overflow: "hidden",
                                    padding: "0px"
                                  }
                                },
                                [_vm._v(" " + _vm._s(props.option.name) + " ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "float-right col-1",
                                  staticStyle: { padding: "0px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.getShopCountForDropdown(
                                              props.option.id
                                            ) != "0",
                                          expression:
                                            "getShopCountForDropdown (props.option.id) != '0'"
                                        }
                                      ],
                                      staticClass: "count-for-shops-dropdown"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getShopCountForDropdown(
                                              props.option.id
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "option",
                      fn: function(props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { margin: "0px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-11",
                                  staticStyle: {
                                    "font-size": "14px",
                                    "text-overflow": "ellipsis",
                                    overflow: "hidden",
                                    padding: "0px"
                                  }
                                },
                                [_vm._v(" " + _vm._s(props.option.name) + " ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "float-right col-1",
                                  staticStyle: { padding: "0px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.getShopCountForDropdown(
                                              props.option.id
                                            ) != "0",
                                          expression:
                                            "getShopCountForDropdown (props.option.id) != '0'"
                                        }
                                      ],
                                      staticClass: "count-for-shops-dropdown"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getShopCountForDropdown(
                                              props.option.id
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.selectedShop,
                    callback: function($$v) {
                      _vm.selectedShop = $$v
                    },
                    expression: "selectedShop"
                  }
                })
              ],
              1
            ),
            _vm.selectedTab === "assignments"
              ? _c(
                  "div",
                  {
                    staticClass: "col-sm-12 col-md-4 col-xl-7 col-lg-7",
                    staticStyle: { "margin-top": "30px" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "tag ttFilter text-white pointer",
                        staticStyle: { "background-color": "gray" },
                        on: {
                          click: function($event) {
                            return _vm.resetTableVariables()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " New assignments: " + _vm._s(_vm.counts.new) + " "
                        )
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "tag ttFilter text-white pointer",
                        staticStyle: { "background-color": "red" },
                        on: {
                          click: function($event) {
                            return _vm.filerByDay("today")
                          }
                        }
                      },
                      [_vm._v(" Today: " + _vm._s(_vm.counts.today) + " ")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "tag ttFilter text-white pointer",
                        staticStyle: { "background-color": "orange" },
                        on: {
                          click: function($event) {
                            return _vm.filerByDay("tomorrow")
                          }
                        }
                      },
                      [
                        _vm._v(
                          " Tomorrow: " + _vm._s(_vm.counts.tomorrow) + " "
                        )
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "glaxisActionButton",
                        on: { click: _vm.checkGlaxis }
                      },
                      [_vm._v("Check Glaxis")]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "row mb-2" }, [
            _c(
              "div",
              { staticClass: "col-6 col-xl-2 col-lg-3" },
              [
                _c("CheckBoxLabel", {
                  attrs: { label: "Show Archive?", value: _vm.showArchive },
                  on: {
                    "update:value": function($event) {
                      _vm.showArchive = $event
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "tableHolder row", staticStyle: { color: "black" } },
            [
              _c(
                "div",
                { staticClass: "form-group col-12" },
                [
                  _c("b-table", {
                    ref: "glaxisDialogTable",
                    staticClass: "text-left p-0",
                    attrs: {
                      stacked: "md",
                      items: _vm.myProvider,
                      fields: _vm.tableFields,
                      "current-page": _vm.links.page,
                      "per-page": _vm.links.limit,
                      "sort-by": _vm.sort.field,
                      "sort-desc": _vm.sort.order,
                      busy: _vm.loading,
                      "show-empty": true,
                      "empty-text": _vm.loading
                        ? "Loading, please wait..."
                        : "There are no records to show",
                      "empty-filtered-text": _vm.loading
                        ? "Loading, please wait..."
                        : "There are no records matching your request"
                    },
                    on: {
                      "update:sortBy": function($event) {
                        return _vm.$set(_vm.sort, "field", $event)
                      },
                      "update:sort-by": function($event) {
                        return _vm.$set(_vm.sort, "field", $event)
                      },
                      "update:sortDesc": function($event) {
                        return _vm.$set(_vm.sort, "order", $event)
                      },
                      "update:sort-desc": function($event) {
                        return _vm.$set(_vm.sort, "order", $event)
                      },
                      "update:busy": function($event) {
                        _vm.loading = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "HEAD_id",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.id,
                                    expression: "filters.id"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "ID #"
                                },
                                domProps: { value: _vm.filters.id },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_internalId",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.internalId,
                                    expression: "filters.internalId"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "ID #"
                                },
                                domProps: { value: _vm.filters.internalId },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "internalId",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_jobId",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.jobId,
                                    expression: "filters.jobId"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Job ID #"
                                },
                                domProps: { value: _vm.filters.jobId },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "jobId",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_dateSubmitted",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.dateSubmitted,
                                    expression: "filters.dateSubmitted"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Date Submitted"
                                },
                                domProps: { value: _vm.filters.dateSubmitted },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "dateSubmitted",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_referenceNumber",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.referenceNumber,
                                    expression: "filters.referenceNumber"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Reference #"
                                },
                                domProps: {
                                  value: _vm.filters.referenceNumber
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "referenceNumber",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_contactNumber",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.contactNumber,
                                    expression: "filters.contactNumber"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Contact #"
                                },
                                domProps: { value: _vm.filters.contactNumber },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "contactNumber",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_response",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.response,
                                    expression: "filters.response"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Response"
                                },
                                domProps: { value: _vm.filters.response },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "response",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_status",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.status,
                                    expression: "filters.status"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Status"
                                },
                                domProps: { value: _vm.filters.status },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "status",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_referralNumber",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.referralNumber,
                                    expression: "filters.referralNumber"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Referral #"
                                },
                                domProps: { value: _vm.filters.referralNumber },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "referralNumber",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_lastName",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.lastName,
                                    expression: "filters.lastName"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Last Name"
                                },
                                domProps: { value: _vm.filters.lastName },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "lastName",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_firstName",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.firstName,
                                    expression: "filters.firstName"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "First Name"
                                },
                                domProps: { value: _vm.filters.firstName },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "firstName",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_phone",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.phone,
                                    expression: "filters.phone"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Phone"
                                },
                                domProps: { value: _vm.filters.phone },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "phone",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_jobNumber",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.jobNumber,
                                    expression: "filters.jobNumber"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Job #"
                                },
                                domProps: { value: _vm.filters.jobNumber },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "jobNumber",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_yearMakeModel",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.yearMakeModel,
                                    expression: "filters.yearMakeModel"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Year Make Model"
                                },
                                domProps: { value: _vm.filters.yearMakeModel },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "yearMakeModel",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_scheduledDate",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.scheduledDate,
                                    expression: "filters.scheduledDate"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Scheduled Date"
                                },
                                domProps: { value: _vm.filters.scheduledDate },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "scheduledDate",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "phone",
                          fn: function(row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " (" +
                                    _vm._s(row.item.phone.substr(0, 3)) +
                                    ") " +
                                    _vm._s(row.item.phone.substr(3, 3)) +
                                    "-" +
                                    _vm._s(row.item.phone.substr(6)) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "jobId",
                          fn: function(row) {
                            return [
                              _c("span", [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.item.jobId !== null,
                                        expression: "row.item.jobId !== null"
                                      }
                                    ],
                                    attrs: {
                                      href: "/jobs/" + row.item.jobId,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(row.item.jobId) + " ")]
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "jobNumber",
                          fn: function(row) {
                            return [
                              _c("span", [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.item.jobNumber !== null,
                                        expression:
                                          "row.item.jobNumber !== null"
                                      }
                                    ],
                                    attrs: {
                                      href: "/jobs/" + row.item.jobNumber,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(row.item.jobNumber) + " "
                                    )
                                  ]
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "yearMakeModel",
                          fn: function(row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.item.yearMakeModel
                                        .toLowerCase()
                                        .split(" ")
                                        .map(function(s) {
                                          return (
                                            s.charAt(0).toUpperCase() +
                                            s.substring(1)
                                          )
                                        })
                                        .join(" ")
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "actions",
                          fn: function(row) {
                            return !_vm.loading
                              ? [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            row.item.archived !== 1 &&
                                            row.item.jobNumber === null,
                                          expression:
                                            "row.item.archived !== 1 && row.item.jobNumber === null"
                                        }
                                      ],
                                      staticClass: "label label-gray label-sm",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleStart(row.item.id)
                                        }
                                      }
                                    },
                                    [_vm._v(" Start ")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "label label-gray label-sm",
                                      on: {
                                        click: function($event) {
                                          return _vm.viewWorkAssignment(
                                            row.item.id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("View")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row.item.archived !== 1,
                                          expression: "row.item.archived !== 1"
                                        }
                                      ],
                                      staticClass: "label label-gray label-sm",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleArchive(row.item)
                                        }
                                      }
                                    },
                                    [_vm._v(" Archive ")]
                                  )
                                ]
                              : undefined
                          }
                        },
                        {
                          key: "status",
                          fn: function(row) {
                            return [
                              _c(
                                "span",
                                {
                                  style: {
                                    color:
                                      row.item.status == "Success"
                                        ? "green"
                                        : row.item.status == "Error"
                                        ? "red"
                                        : "orange"
                                  }
                                },
                                [_vm._v(_vm._s(row.item.status))]
                              )
                            ]
                          }
                        },
                        {
                          key: "response",
                          fn: function(row) {
                            return [
                              _c(
                                "span",
                                {
                                  style: {
                                    color:
                                      row.item.status == "Success"
                                        ? "green"
                                        : row.item.status == "Error"
                                        ? "red"
                                        : "orange"
                                  }
                                },
                                [_vm._v(_vm._s(row.item.response))]
                              )
                            ]
                          }
                        },
                        {
                          key: "claimActions",
                          fn: function(row) {
                            return [
                              row.item.jobId && row.item.jobId > 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "label label-gray label-sm",
                                      on: {
                                        click: function($event) {
                                          return _vm.openLossNotice(
                                            row.item.jobId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Open Claim")]
                                  )
                                : _vm._e(),
                              _c(
                                "a",
                                {
                                  staticClass: "label label-gray label-sm",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleClaimArchive(
                                        row.item.id,
                                        row.item.archived !== 1
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      row.item.archived === 1
                                        ? "Unarchive"
                                        : "Archive"
                                    )
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "6" } }, [
                _c("span", [
                  _vm._v(
                    "Showing " + _vm._s(_vm.numberOfRecords) + " assignments"
                  )
                ])
              ]),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.links.total,
                      "per-page": _vm.links.limit,
                      align: "right"
                    },
                    model: {
                      value: _vm.links.page,
                      callback: function($$v) {
                        _vm.$set(_vm.links, "page", $$v)
                      },
                      expression: "links.page"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("GlaxisWorkAssignmentDialog", {
        attrs: { id: _vm.workAssignmentId, skipTableReset: _vm.skipTableReset },
        on: {
          "update:skipTableReset": function($event) {
            _vm.skipTableReset = $event
          },
          "update:skip-table-reset": function($event) {
            _vm.skipTableReset = $event
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.confirmArchiveChangePopup, appendToBody: true },
          on: {
            "update:visible": function($event) {
              _vm.confirmArchiveChangePopup = $event
            }
          }
        },
        [
          _c("el-row", { staticClass: "dialog-header-text" }, [
            _vm._v(
              " " + _vm._s(_vm.archiveClaim ? "Archive" : "Unarchive") + "? "
            )
          ]),
          _c("br"),
          _c("el-row", { staticClass: "dialog-body-text" }, [
            _vm._v(
              "Are you sure you want to " +
                _vm._s(_vm.archiveClaim ? "archive" : "unarchive") +
                " this Claim?"
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.finishHandleClaimArchive()
                    }
                  }
                },
                [_vm._v("Yes")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.confirmArchiveChangePopup = false
                    }
                  }
                },
                [_vm._v("No")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.alertPopup, appendToBody: true },
          on: {
            "update:visible": function($event) {
              _vm.alertPopup = $event
            }
          }
        },
        [
          _c("el-row", { staticClass: "dialog-body-text" }, [
            _vm._v(_vm._s(_vm.alertPopupText))
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.alertPopup = false
                    }
                  }
                },
                [_vm._v("Ok")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }