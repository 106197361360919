<template>
  <div class="file-details-table">
    <table>
      <tr>
        <td>File Name:</td>
        <td>{{ document_name }}</td>
      </tr>
      <tr>
        <td>File Type:</td>
        <td>{{ type }}</td>
      </tr>
      <tr>
        <td>Doc type:</td>
        <td>{{ attchType }}</td>
      </tr>
      <tr>
        <td>File Size:</td>
        <td>{{ size }}</td>
      </tr>
      <tr>
        <td>Created:</td>
        <td>{{ date }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import * as R from 'ramda'
import { documentsConfig } from '@/constants/documents_v2.config'
import { fromTimestamp } from '@/scripts/helpers/date.helpers'

export default {
  props: ['type', 'size', 'document_type', 'document_name', 'created', 'url', 'dateFunc', 'document_type_name'],
  computed: {
    date() {
      return this.dateFunc ? this.created && this.dateFunc(this.created) : this.created && fromTimestamp(this.created)
    },
    otherDate() {
      return this.dateFunc(this.created)
    },
    attchType() {
      return this.document_type_name || R.pipe(R.path([this.document_type, 'label']))(documentsConfig)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./FileDetailsTable.scss";
</style>
