<template>
  <div>
    <label for='title' v-if="title && title.length > 0">{{title}}</label>
    <div class='form-group'>
      <input
        ref='input'
        type='text'
        :maxlength='maxlength'
        v-bind:class='inClass'
        :placeholder='placeholder'
        :title='title'
        v-model='inputValue'
        :disabled='inDisabled'
        :style='inStyle' />
    </div>
  </div>
</template>

<script>
export default {
  props: ['placeholder', 'title', 'value', 'inDisabled', 'inStyle', 'inClass', 'maxlength'],
  computed: {
    inputValue: {
      get: function () {
        var value = this.value ? this.value : ''
        return value.toUpperCase()
      },
      set: function (changedVal) {
        this.$emit('update:value', changedVal.toUpperCase())
      }
    }
  }
}
</script>

<style>

</style>
