import { ApolloLink } from '@apollo/client/core'
import * as R from 'ramda'

export const getFragmentValues = R.pipe(R.keys, R.join(' '))

export const queryLoading = queries =>
  R.pipe(
    R.propOr({}, 'queries'),
    R.pick(queries),
    R.map(R.prop('loading')),
    R.values,
    R.any(R.identity)
  )

const omitDeepArrayWalk = (arr, key) => {
  return arr.map(val => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
    else if (typeof val === 'object') return omitDeep(val, key)
    return val
  })
}

const omitDeep = (obj, key) => {
  const keys = Object.keys(obj)
  const newObj = {}
  keys.forEach(i => {
    if (i !== key) {
      const val = obj[i]
      if (val instanceof Date) newObj[i] = val
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
      else if (typeof val === 'object' && val !== null) {
        newObj[i] = omitDeep(val, key)
      } else newObj[i] = val
    }
  })
  return newObj
}

export const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.getContext().upload) {
    // eslint-disable-next-line
    operation.variables = omitDeep(operation.variables, "__typename");
  }

  return forward(operation)
})

export const updateFieldMutate = R.map(
  v => (_, { toReference, fieldName, canRead }) => {
    const __typename = v?.__typename
    const ref = toReference({ ...v, __typename })
    if (ref) {
      return ref
    } else {
      return v || null
    }
  }
)
