import gql from 'graphql-tag'
import { VEHICLE_FRAGMENT } from '../fragments/vehicle.fragment'

export const CREATE_VEHICLE_MUTATION = gql`
  mutation createVehicle($vehicle: VehicleInput!) {
    createVehicle(vehicle: $vehicle) {
      ...VehicleFields
    }
  }
  ${VEHICLE_FRAGMENT}
`

export const DELETE_VEHICLE_MUTATION = gql`
  mutation createVehicle($id: ID!) {
    deleteVehicle(id: $id)
  }
`

export const UPDATE_VEHICLE_MUTATION = gql`
  mutation updateVehicle($id: ID!, $vehicle: VehicleInput!) {
    updateVehicle(id: $id, vehicle: $vehicle)
  }
`
