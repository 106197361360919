<template>
  <div
    class="gb-dropdown-menu"
    @keydown.down.prevent="nextOption"
    @keydown.up.prevent="prevOption"
    @keydown.enter.prevent="selectOption(highlight)"
  >
    <el-popover
      placement="bottom-end"
      trigger="manual"
      v-model="open"
      popper-class="gb-dropdown-menu-container"
    >
      <div slot="reference" @click="open = true" ref="trigger">
        <slot name="trigger" />
      </div>
      <div v-element-clickoutside="clickOutside">
        <div class="gb-dropdown-header" v-if="$slots['menu-header']">
          <slot name="menu-header" />
        </div>
        <div class="gb-dropdown-container" ref="scroll">
          <el-scrollbar
            wrap-class="gb-dropdown-list-container"
            view-class="gb-dropdown-list"
            tag="ul"
            ref="scrollbar"
          >
            <li
              @mouseenter="highlight = idx"
              @mouseleave="highlight = -1"
              @click="selectOption(idx)"
              class="gb-dropdown-menu-item"
              v-for="(option, idx) in options"
              :key="option.id"
              :class="{ active: idx === highlight }"
            >
              <slot name="menu-option" v-bind="option" />
            </li>
          </el-scrollbar>
        </div>
        <div
          class="gb-dropdown-add-new"
          @click="$emit('new-click')"
          v-if="$listeners['new-click']"
        >
          <i class="material-icons-outline md-add md-18" />
          {{ newLabel }}
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import scrollIntoView from 'element-ui/src/utils/scroll-into-view'

export default {
  data() {
    return {
      highlight: -1,
      open: false
    }
  },
  props: {
    newLabel: {
      type: String,
      default: () => 'Add new'
    },
    options: {
      type: Array,
      default: () => []
    },
    multiselect: Boolean,
    keepOpen: Boolean
  },
  methods: {
    trigger(i) {
      console.log(i)
    },
    nextOption() {
      if (this.highlight < this.options.length) {
        this.highlight = this.highlight + 1
        this.scrollToOption()
      }
    },
    prevOption() {
      if (this.highlight >= 0) {
        this.highlight = this.highlight - 1
        this.scrollToOption()
      }
    },
    scrollToOption(option) {
      const { scroll, scrollbar } = this.$refs
      const target = scroll.querySelectorAll('li')[this.highlight]
      const menu = scroll.querySelector('.customer-search-list-container')
      menu && target && scrollIntoView(menu, target)
      scrollbar && scrollbar.handleScroll()
    },
    selectOption(idx) {
      const option = this.options[idx]
      if (option) {
        this.$emit('select', option)
      }
      if (!this.multiselect) {
        this.open = false
      }
    },

    clickOutside() {
      if (this.open && !this.keepOpen) {
        this.open = false
      }
    }
  },
  watch: {
    open(v) {
      if (v) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gb-dropdown-menu {
  .menu-trigger {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.gb-dropdown-menu-container {
  padding: 0;
  .gb-dropdown-header {
    padding: 13px;
    border-bottom: 1px solid #dcdfe6;
  }
  .gb-dropdown-list-container {
    position: relative;
    .gb-dropdown-list {
      padding: $padding-xss 0;
      margin: 0;
    }
    max-height: 500px;
    min-width: 281px;
  }
  .gb-dropdown-menu-item {
    padding: $padding-xs $padding-md;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &.active {
      background: #f5f7fa;
    }
    &.selected {
      color: $primary;
    }
  }
  .gb-dropdown-add-new {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background: #f5f7fa;
    }
    padding: 13px;
    border-top: 1px solid #dcdfe6;
    color: $primary;
    i {
      margin-right: $padding-xs;
    }
  }
}
</style>
