import { make } from 'vuex-pathify'
import Job from '@/scripts/objects/job'
import moment from 'moment'

const state = {
  visible: false,
  jobId: null,
  shopId: null,
  history: [],
  job: {}
}

const mutations = make.mutations(state)

const getters = {
  formattedHistory: (state) => {
    let data = []
    for (const i of state.history) {
      let historyItem = {
        size: 'large',
        timestamp: moment(i.created_at).format('LLLL')
      }
      if (i.status_name === 'sent') {
        historyItem.name = `Job confirmation was sent by ${i.username || 'GlassBiller'}`
        historyItem.type = 'warning'
      } else if (i.status_name === 'confirmed') {
        if (i.username === null) {
          historyItem.name = 'Customer confirmed'
        } else {
          historyItem.name = i.username + ' manually updated status to confirmed'
        }
        historyItem.type = 'success'
      } else if (i.status_name === 'declined') {
        if (i.username === null) {
          historyItem.name = 'Customer declined'
        } else {
          historyItem.name = i.username + ' manually updated status to declined'
        }
        historyItem.type = 'danger'
      }
      data.push(historyItem)
    }
    return data
  },

  status: (state) => {
    return state.history[state.history.length - 1]
  },

  confirmationWasSentBefore: (state) => {
    const confirmations = state.history.filter(item => {
      return item.status_name === 'sent'
    })
    return confirmations.length > 0
  }
}

const actions = {
  open ({ commit }) {
    commit('SET_VISIBLE', true)
  },
  setJobId ({ commit }, jobId) {
    commit('SET_JOB_ID', jobId)
  },
  setShopId ({ commit }, shopId) {
    commit('SET_SHOP_ID', shopId)
  },
  async getHistory ({ commit }, jobId) {
    const history = await Job.getTextMessageConfirmationHistory(jobId)
    commit('SET_HISTORY', history)
  },
  clearHistory ({ commit }) {
    commit('SET_HISTORY', [])
  },
  setJob ({ commit }, job) {
    commit('SET_JOB', job)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
