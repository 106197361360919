var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: _vm.tech.id > 0 ? "Edit Tech" : "New Tech",
            id: "techModal",
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg"
          },
          on: {
            show: _vm.dialogAboutToAppear,
            shown: function($event) {
              return _vm.$refs.nameComponent.focus()
            },
            hidden: _vm.resetEditTech
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-6" }, [
              _c("label", [_vm._v("Status")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tech.status,
                      expression: "tech.status"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.tech,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "active" } }, [
                    _vm._v("Active")
                  ]),
                  _c("option", { attrs: { value: "disabled" } }, [
                    _vm._v("Disabled")
                  ])
                ]
              )
            ]),
            _vm.tech.id > 0
              ? _c("div", { staticClass: "form-group col-6" }, [
                  _c("label"),
                  _c("p", [
                    _vm._v("ID: "),
                    _c("span", { staticClass: "idBox" }, [
                      _vm._v(_vm._s(_vm.tech.id))
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-6" },
              [
                _c("RequiredInput", {
                  ref: "nameComponent",
                  attrs: {
                    title: "Name",
                    value: _vm.tech.name,
                    placeholder: "",
                    capitalizeAll: true
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.tech, "name", $event)
                    }
                  }
                })
              ],
              1
            ),
            _vm.checkCommissionPermission
              ? _c(
                  "div",
                  { staticClass: "form-group col-3" },
                  [
                    _c("label", [_vm._v("Commission")]),
                    _c("FloatDropdown", {
                      ref: "commissionComponent",
                      attrs: {
                        dropdownOptions: [
                          { value: "flat", label: "$", dropdownLabel: "Flat" },
                          { value: "percent", label: "%", dropdownLabel: "%" }
                        ],
                        inDisabled: false,
                        dropdownValue: _vm.tech.commission_flat_or_percent,
                        value: _vm.tech.commission
                      },
                      on: {
                        "update:dropdownValue": function($event) {
                          return _vm.$set(
                            _vm.tech,
                            "commission_flat_or_percent",
                            $event
                          )
                        },
                        "update:dropdown-value": function($event) {
                          return _vm.$set(
                            _vm.tech,
                            "commission_flat_or_percent",
                            $event
                          )
                        },
                        "update:value": function($event) {
                          return _vm.$set(_vm.tech, "commission", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.checkCommissionPermission
              ? _c(
                  "div",
                  { staticClass: "form-group col-3" },
                  [
                    _c("label", [_vm._v("Chip Commission")]),
                    _c("FloatDropdown", {
                      ref: "chipCommissionComponent",
                      attrs: {
                        dropdownOptions: [
                          { value: "flat", label: "$", dropdownLabel: "Flat" },
                          { value: "percent", label: "%", dropdownLabel: "%" }
                        ],
                        inDisabled: false,
                        dropdownValue: _vm.tech.chip_commission_flat_or_percent,
                        value: _vm.tech.chip_commission
                      },
                      on: {
                        "update:dropdownValue": function($event) {
                          return _vm.$set(
                            _vm.tech,
                            "chip_commission_flat_or_percent",
                            $event
                          )
                        },
                        "update:dropdown-value": function($event) {
                          return _vm.$set(
                            _vm.tech,
                            "chip_commission_flat_or_percent",
                            $event
                          )
                        },
                        "update:value": function($event) {
                          return _vm.$set(_vm.tech, "chip_commission", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-3" },
              [
                _c("Phone", {
                  ref: "phoneComponent",
                  attrs: {
                    title: "Phone",
                    value: _vm.tech.phone,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.tech, "phone", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-md-3" },
              [
                _c("Phone", {
                  ref: "faxComponent",
                  attrs: {
                    title: "Fax",
                    value: _vm.tech.fax,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.tech, "fax", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-md-3" },
              [
                _c("Email", {
                  ref: "emailComponent",
                  attrs: {
                    title: "Email",
                    value: _vm.tech.email,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.tech, "email", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-md-3" },
              [
                _c("label", [_vm._v("Technician Color")]),
                _c("br"),
                _c("Sketch", {
                  model: {
                    value: _vm.techColor,
                    callback: function($$v) {
                      _vm.techColor = $$v
                    },
                    expression: "techColor"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-12" },
              [
                _c("label", [_vm._v("Address")]),
                _c("address-input", {
                  attrs: { "with-po-box": "", "popper-fix": "" },
                  model: {
                    value: _vm.tech.g_address,
                    callback: function($$v) {
                      _vm.$set(_vm.tech, "g_address", $$v)
                    },
                    expression: "tech.g_address"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-12" }, [
              _c("label", [_vm._v("Notes")]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tech.notes,
                    expression: "tech.notes"
                  }
                ],
                staticClass: "form-control form-control-sm",
                domProps: { value: _vm.tech.notes },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.tech, "notes", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c("div", { staticClass: "float-left" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "col-4": _vm.mostRecentInviteHistory != null,
                        "col-12": _vm.mostRecentInviteHistory == null
                      }
                    },
                    [
                      !_vm.tech.techside_invitation_status ||
                      _vm.tech.techside_invitation_status === "pending" ||
                      _vm.mostRecentInviteHistory != null
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "button",
                                size: "lg",
                                variant: "primary"
                              },
                              on: { click: _vm.inviteTech }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  !_vm.tech.techside_invitation_status ||
                                    _vm.tech.techside_invitation_status ===
                                      "pending"
                                    ? "Invite Tech"
                                    : "Invite History"
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm.mostRecentInviteHistory != null
                    ? _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c(
                            "el-timeline",
                            [
                              _c(
                                "el-timeline-item",
                                {
                                  attrs: {
                                    icon: _vm.mostRecentInviteHistory.icon,
                                    type: _vm.mostRecentInviteHistory.type,
                                    size: _vm.mostRecentInviteHistory.size,
                                    timestamp:
                                      _vm.mostRecentInviteHistory.timestamp
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.mostRecentInviteHistory.name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    staticStyle: { "margin-right": "5px" },
                    attrs: { type: "button", size: "lg", variant: "primary" },
                    on: { click: _vm.saveTech }
                  },
                  [_vm._v("Save")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", variant: "primary" },
                    on: { click: _vm.cancelEdits }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ]
          ),
          _c("el-divider"),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _vm._v(" TechSIDE settings ")
            ])
          ]),
          _c("div", { staticClass: "row techside-settings" }, [
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", [_vm._v("Techside Username (Case Sensitive)")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tech.techside_username,
                    expression: "tech.techside_username"
                  }
                ],
                staticClass: "form-control form-control-sm",
                attrs: { type: "text" },
                domProps: { value: _vm.tech.techside_username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.tech, "techside_username", $event.target.value)
                  }
                }
              })
            ]),
            _vm.tech.techside_invitation_status
              ? _c("div", { staticClass: "form-group col-md-4" }, [
                  _c("label", [_vm._v("Invitation Status")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tech.techside_invitation_status,
                        expression: "tech.techside_invitation_status"
                      }
                    ],
                    staticClass: "form-control form-control-sm",
                    class: {
                      pendingInvitationStatus:
                        _vm.tech.techside_invitation_status === "pending",
                      acceptedInvitionStatus:
                        _vm.tech.techside_invitation_status === "accepted"
                    },
                    attrs: { type: "text", readonly: "" },
                    domProps: { value: _vm.tech.techside_invitation_status },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.tech,
                          "techside_invitation_status",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("el-checkbox", {
                  style: { marginTop: "21px" },
                  attrs: { label: "Hide completed jobs (legacy)", border: "" },
                  model: {
                    value: _vm.hideCompletedJobs,
                    callback: function($$v) {
                      _vm.hideCompletedJobs = $$v
                    },
                    expression: "hideCompletedJobs"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row techside-settings" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("el-checkbox", {
                  attrs: { label: "Require clock-in", border: "" },
                  model: {
                    value: _vm.tech.require_clock_in,
                    callback: function($$v) {
                      _vm.$set(_vm.tech, "require_clock_in", $$v)
                    },
                    expression: "tech.require_clock_in"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("el-checkbox", {
                  attrs: { label: "Require Tech Pickup", border: "" },
                  model: {
                    value: _vm.tech.require_tech_pickup,
                    callback: function($$v) {
                      _vm.$set(_vm.tech, "require_tech_pickup", $$v)
                    },
                    expression: "tech.require_tech_pickup"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("el-checkbox", {
                  attrs: { label: "Require Inspection", border: "" },
                  model: {
                    value: _vm.tech.require_pre_inspection,
                    callback: function($$v) {
                      _vm.$set(_vm.tech, "require_pre_inspection", $$v)
                    },
                    expression: "tech.require_pre_inspection"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("el-checkbox", {
                  attrs: { label: "Hide prices", border: "" },
                  model: {
                    value: _vm.tech.techside_hide_prices,
                    callback: function($$v) {
                      _vm.$set(_vm.tech, "techside_hide_prices", $$v)
                    },
                    expression: "tech.techside_hide_prices"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.confirmSaveChanges },
          on: {
            "update:visible": function($event) {
              _vm.confirmSaveChanges = $event
            }
          }
        },
        [
          _c("el-row", { staticClass: "dialog-header-text" }, [
            _vm._v(" Save Changes? ")
          ]),
          _c("br"),
          _c("el-row", { staticClass: "dialog-body-text" }, [
            _vm._v(
              "In order to invite this tech, any unsaved changes must be saved. Would you like to continue?"
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.approveSaveChangesAndInvite()
                    }
                  }
                },
                [_vm._v("Yes")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.confirmSaveChanges = false
                    }
                  }
                },
                [_vm._v("No")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }