<template>
  <div class="gb-action-button" @click="click" :class="{ disabled, dark }">
    <i
      :class="`material-icons-outline md-${prefix} ${
        space ? 'space-left' : ''
      }`"
      v-if="prefix"
    />
    <slot />
    <i
      :class="`material-icons-outline md-${suffix} ${
        space ? 'space-right' : ''
      }`"
      v-if="suffix"
    />
  </div>
</template>

<script>
export default {
  props: {
    prefix: String,
    suffix: String,
    space: Boolean,
    disabled: Boolean,
    dark: Boolean,
    noStop: Boolean
  },
  methods: {
    click(e) {
      if (!this.disabled) {
        if (!this.noStop) {
          e.stopPropagation()
        }
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gb-action-button {
  color: #2898ff;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.1s ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #1c71c0;
  }
  .space-left {
    margin-right: $padding-xxs;
  }
  .space-right {
    margin-left: $padding-xxs;
  }
  &.disabled {
    color: $grayscale-7;
    cursor: not-allowed;
  }
  &.dark {
    color: $grayscale-8;
    i {
      color: $grayscale-7;
    }
    &:hover {
      color: $grayscale-9;
    }
  }
}
</style>
