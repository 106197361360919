<template>
  <!-- <b-modal @show="dialogAboutToAppear" @hidden="dialogDisappeared"  no-close-on-backdrop no-close-on-esc
  v-bind:title="'Attach Fax'" id="attachFaxModal"
    header-bg-variant='primary'
    header-text-variant='light'
    size="lg"> -->
  <div v-bind:class="{ mobileVersion: isMobile }">
    <div class="row">
      <div class="col">
        <strong>File Name: </strong> {{ fax ? fax.filename : '' }}
      </div>
      <div class="col">
        <a class="float-right" @click="$emit('close-attach')" href="javascript:void(0)"><font-awesome-icon style="color:red" :icon="faTimesCircle"/></a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <strong>Date/Time: </strong> {{ fax ? fax.dateTime : '' }}
      </div>
    </div>
    <div class="row">
      <div class="form-group col-6">
        <label>Job</label>
          <Autocomplete
          :startingPosition="-1"
          :smallInput="true"
          :dataSet="jobArray"
          @selected="selectAutocompletePart"
          :searchValue.sync="jobSearchValue"
          @deleteKeyPressed="handleAutoCompleteDelete"
          placeholder="Search For Job..."
          :inLoading="loadingJobs"
          :showSpinner="true"
          :autoCompleteHeight="180"/>
      </div>
      <div class="form-group col-6">
        <label>Type</label>
        <select v-model="selectedType" class="form-control form-control-sm">
          <option value="">...</option>
          <option value="swo">SWO</option>
          <option value="dis">DIS</option>
          <option value="pop">PoP</option>
          <option value="fiv">FIV</option>
          <option value="quo">QUO</option>
          <option value="oth">OTH</option>
        </select>
      </div>
    </div>
    <!-- <div slot="modal-footer" class='w-100'> -->
    <div class='float-right mt-1'>
      <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="attachButtonPressed">Attach</button>
    </div>
    <!-- </div> -->
  </div>
  <!-- </b-modal> -->
</template>

<script>
import Autocomplete from '@/components/Helpers/Autocomplete.vue'
import Job from '@/scripts/objects/job'
import Document from '@/scripts/objects/document'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faTimesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default {
  props: ['fax'],
  data () {
    return {
      selectedType: '',
      jobId: -1,
      jobShopId: -1,
      jobSearchValue: '',
      jobArray: [],
      timeout: null,
      loadingJobs: false
    }
  },
  watch: {
    jobSearchValue () {
      
      clearTimeout(this.timeout)

      if (this.jobSearchValue && this.jobSearchValue.length >= 3 && this.jobId === -1) {
        var self = this
        let currentVal = self.jobSearchValue
        self.loadingJobs = true
        this.timeout = setTimeout(function () {
          if (self.jobSearchValue === currentVal) {
            Job.quickSearch(self.jobSearchValue, 'faxsearch', function (response) {
              
              if (self.jobSearchValue === currentVal) {
                self.jobArray = response
                self.loadingJobs = false
              }
            }, function (error) {
              
              self.loadingJobs = false
            })
          }
        }, 600)
      } else {
        this.jobArray = []
      }
    }
  },
  computed: {
    faTimesCircle () {
      return faTimesCircle
    }
  },
  methods: {
    handleAutoCompleteDelete: function () {
      if (this.jobId !== -1) {
        let self = this
        
        setTimeout(function () {
          self.jobSearchValue = ''
          self.jobId = -1
          self.jobShopId = -1
          self.loadingJobs = false
          
        }, 100)
      }
    },
    dialogAboutToAppear: function () {
      this.selectedType = ''
      this.jobId = -1
      this.jobSearchValue = ''
      this.jobShopId = -1
    },
    dialogDisappeared: function () {
      
      this.$root.$emit('bv::show::modal', 'faxInboxModal')
    },
    attachButtonPressed: function () {
      
      if (this.jobId <= 0) {
        alert('Please select a job.')
        return
      }
      if (this.selectedType.length === 0) {
        alert('Please select a document type.')
        return
      }

      var document = new Document()
      document.job_id = this.jobId
      document.document_type = this.selectedType
      // handle filename vs fileName
      let fileKey = ''
      if (this.fax.hasOwnProperty('filename')) {
        fileKey = 'filename'
      } else if (this.fax.hasOwnProperty('fileName')) {
        fileKey = 'fileName'
      }
      document.document_name = this.fax[fileKey]
      document.url = this.fax.url

      let self = this
      if (document.document_name && document.document_name.length > 0 &&
      document.url && document.url.length > 0) {
        document.createUpdateDocument(self.jobShopId, self.jobId, function (newDocument) {
          // alert('The document was successfully attached.')
          // self.$root.$emit('bv::hide::modal', 'attachFaxModal')
          // update job docs without reloading
          self.$root.$emit('addNewDocument', newDocument)
          self.$emit('close-attach')
        }, function (error) {
          
        })
      } else {
        alert('This fax could not be attached.')
      }
    },
    selectAutocompletePart (data) {
      
      for (var i = 0; i < this.jobArray.length; i++) {
        if (this.jobArray[i].id === data) {
          
          this.jobSearchValue = this.jobArray[i].name
          this.jobId = this.jobArray[i].id
          this.jobShopId = this.jobArray[i].shop_id
          this.loadingJobs = false
        }
      }
    }
  },
  components: {
    Autocomplete,
    FontAwesomeIcon
  },
  mixins: [UtilityMixin]
}
</script>

<style>
.idBox {
  background-color: #EDC9AF;
  padding: 5px;
  border-radius: 3px;
}

.mobileVersion {
  margin-top: 50px;
}
</style>
