var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "el-dialog",
        {
          attrs: {
            center: "",
            "append-to-body": "",
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.visible,
            "custom-class": "text-message-confirmation-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            !_vm.showAllInviteHistory && _vm.inviteHistory.length > 2
              ? _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.showAllInviteHistory = true
                      }
                    }
                  },
                  [_vm._v("Show All History...")]
                )
              : _vm._e(),
            _vm.showAllInviteHistory && _vm.inviteHistory.length > 2
              ? _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.showAllInviteHistory = false
                      }
                    }
                  },
                  [_vm._v("Show Less History")]
                )
              : _vm._e()
          ]),
          _vm.displayInviteHistory.length > 0
            ? _c(
                "el-timeline",
                { staticStyle: { margin: "10px 0 0 100px" } },
                _vm._l(_vm.displayInviteHistory, function(activity, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: index,
                      attrs: {
                        icon: activity.icon,
                        type: activity.type,
                        size: activity.size,
                        timestamp: activity.timestamp
                      }
                    },
                    [_vm._v(" " + _vm._s(activity.name) + " ")]
                  )
                }),
                1
              )
            : _vm._e(),
          !_vm.historyOnly
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "hide-required-asterisk": true,
                    "label-width": "140px",
                    size: "small"
                  }
                },
                [
                  _vm.shopPhoneNumbers.length
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "shopPhoneNumber" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "undo-label-styles",
                              attrs: { slot: "label" },
                              slot: "label"
                            },
                            [_vm._v("Shop phone")]
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "Select shop phone number"
                              },
                              on: { focus: _vm.focusedShopPhoneSelect },
                              model: {
                                value: _vm.form.shopPhoneNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "shopPhoneNumber", $$v)
                                },
                                expression: "form.shopPhoneNumber"
                              }
                            },
                            _vm._l(_vm.shopPhoneNumbers, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: _vm.generateLabel(item),
                                  value: item.phone_number
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phoneNumber" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Phone Number")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "Enter phone number" },
                        model: {
                          value: _vm.formattedPhoneNumber,
                          callback: function($$v) {
                            _vm.formattedPhoneNumber = $$v
                          },
                          expression: "formattedPhoneNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "body" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Text Message")]
                      ),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 3, maxRows: 10 },
                          placeholder: "Text Body"
                        },
                        model: {
                          value: _vm.form.body,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "body", $$v)
                          },
                          expression: "form.body"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticStyle: { "word-break": "break-word" } },
                    [
                      _c("p", { staticClass: "mt-3" }, [
                        _c("span", { staticStyle: { color: "#F56C6C" } }, [
                          _vm._v("NOTE:")
                        ]),
                        _vm._v(
                          " A link to accept this invitation will be automatically added in the end of the message."
                        )
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mb-2 mr-2",
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.handleSubmit }
                        },
                        [_vm._v(" Send ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml-0",
                          attrs: { plain: "" },
                          on: {
                            click: function($event) {
                              _vm.visible = false
                            }
                          }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { height: "0px", width: "0px", overflow: "hidden" } },
      [
        _c("input", { attrs: { name: "autofillEmailBlocker" } }),
        _c("input", {
          attrs: { name: "autofillPasswordBlocker", type: "password" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }