<template>
  <el-dialog
    width="600px"
    append-to-body
    :visible.sync="visible"
    custom-class="shopping-cart-dialog">
    <span slot="title" class="t-text-xl t-text-gray-800">Order details</span>
    <div
      style="max-height: 600px; overflow-y: auto;"
      class="t-p-4">
      <div v-if="loading" class="t-p-12 t-text-center t-text-3xl t-text-blue-600">
        <i class="el-icon-loading" />
      </div>
      <div v-else>
        <div
          v-for="item in items"
          :key="item.id"
          class="t-px-3 t-py-2 t-rounded t-border t-border-solid
                t-border-gray-400 t-bg-gray-100 t-mb-2">
          <div class="t-flex">
            <div class="t-flex-grow">
              <div class="t-flex t-text-base t-mb-2">
                <div class="t-mr-1 t-font-bold">{{ item.industryCode }}</div>
              </div>
              <div v-if="item.orderId" class="t-flex t-text-base t-mb-2">
                <div class="t-mr-1">
                  <span class="t-text-gray-600 t-text-sm">Order number:</span>
                  {{ item.orderId }}
                </div>
              </div>
              <div class="t-flex t-text-sm t-items-center t-text-gray-600">
                <i class="el-icon-location-outline t-text-base t-mr-1 t-text-blue-500"></i>
                <div>{{ item.locationName }}</div>
              </div>
            </div>
            <div>
              <div class="t-flex">
                <div>{{ item.quantity }}</div>
                <div class="t-mx-2">x</div>
                <div class="t-font-bold">${{ item.unitPrice }}</div>
              </div>
            </div>
          </div>
          <div v-if="item.sku">
            <el-divider class="t-my-2" />
            <div class="t-flex">
              <span class="t-text-gray-600 t-text-sm t-mr-3 t-break-normal">
                Description:
              </span>
              <span class="t-break-normal">{{ item.sku }}</span>
            </div>
          </div>
          <div class="t-flex t-text-sm t-items-center t-text-gray-600">
            <i class="el-icon-truck t-text-base t-mr-1 t-text-blue-500"></i>
            <div>{{ formatDeliveryDate(details.requestedShipDate) }},</div>
            <div>- {{ details.deliveryOption }}</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'
import { getHumanDate } from '@/scripts/helpers/date.helpers'

export default {
  computed: {
    items() {
      return this.$store.state.shoppingCart.orders.orderItems
    },
    details() {
      return this.$store.state.shoppingCart.orders.orderDetails
    },
    loading() {
      return this.$store.state.shoppingCart.orders.orderItemsFetching
    },
    visible: {
      get() {
        return this.$store.state.shoppingCart.orders.orderDetailsIsOpen
      },
      set(value) {
        return this.$store.commit(
          shoppingCartMutations.orders.setOrderDetailsIsOpen,
          value
        )
      }
    },
  },
  methods: {
    formatDeliveryDate (timestamp) {
      return getHumanDate(timestamp)
    },
  }
}
</script>
