var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "address-input",
        class: { pink: _vm.pink, loose: _vm.loose }
      },
      [
        _c("el-autocomplete", {
          staticClass: "address-autocomplete",
          class: {
            full: _vm.full,
            valid:
              _vm.value && _vm.value.full_address && _vm.value.google_place_id,
            invalid:
              _vm.value &&
              !(_vm.value.full_address && _vm.value.google_place_id)
          },
          attrs: {
            "prefix-icon": _vm.loading ? "el-icon-loading" : "fa fa-google",
            placeholder: _vm.poBox ? "Enter zip" : "Enter address",
            "fetch-suggestions": _vm.fetchSuggestions,
            "popper-append-to-body": _vm.popperFix,
            "trigger-on-focus": !!_vm.defaultResults,
            disabled: _vm.disabled,
            autocomplete: "none",
            clearable: _vm.clearable
          },
          on: {
            select: _vm.handleSelect,
            focus: _vm.setToken,
            blur: function($event) {
              return _vm.$emit("blur", $event)
            },
            clear: function($event) {
              return _vm.$emit("clear")
            }
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("address-input-block", {
                    attrs: {
                      result: item.id ? item : item.structured_formatting
                    }
                  })
                ]
              }
            }
          ]),
          model: {
            value: _vm.address,
            callback: function($$v) {
              _vm.address = $$v
            },
            expression: "address"
          }
        }),
        _vm.withPoBox && !_vm.loose
          ? _c("el-checkbox", {
              staticClass: "po-box-check",
              attrs: { label: "PO BOX", border: "" },
              on: {
                focus: function($event) {
                  return _vm.$emit("focus", $event)
                },
                blur: function($event) {
                  return _vm.$emit("blur", $event)
                }
              },
              model: {
                value: _vm.poBox,
                callback: function($$v) {
                  _vm.poBox = $$v
                },
                expression: "poBox"
              }
            })
          : _vm._e(),
        _c(
          "el-input",
          {
            ref: "unitRef",
            staticClass: "address-unit",
            attrs: {
              placeholder: _vm.poBox ? "PO BOX" : "Unit/Apt",
              disabled: _vm.disabled
            },
            on: {
              focus: function($event) {
                return _vm.$emit("focus", $event)
              },
              blur: function($event) {
                return _vm.$emit("blur", $event)
              }
            },
            model: {
              value: _vm.unit,
              callback: function($$v) {
                _vm.unit = $$v
              },
              expression: "unit"
            }
          },
          [
            _c("v-icon", {
              attrs: { slot: "prefix", name: "hashtag" },
              slot: "prefix"
            })
          ],
          1
        ),
        _c("gmap-autocomplete", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ]
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "t-flex" },
      [
        _vm.withPoBox && _vm.loose
          ? _c("el-checkbox", {
              attrs: { label: "P.O. BOX" },
              model: {
                value: _vm.poBox,
                callback: function($$v) {
                  _vm.poBox = $$v
                },
                expression: "poBox"
              }
            })
          : _vm._e(),
        _vm.withDeliveryInstructions
          ? _c(
              "div",
              { staticClass: "delivery-instructions" },
              [
                _vm.showExtra
                  ? _c("delivery-instructions-input", {
                      attrs: { address: _vm.value, "auto-focus": _vm.addExtra },
                      model: {
                        value: _vm.extra,
                        callback: function($$v) {
                          _vm.extra = $$v
                        },
                        expression: "extra"
                      }
                    })
                  : _vm._e(),
                _c(
                  "a",
                  {
                    attrs: { href: "#", tabindex: -1 },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.toggleExtra($event)
                      }
                    }
                  },
                  [
                    _vm.showExtra
                      ? _c("span", [_vm._v("Remove instructions")])
                      : _c("span", [_vm._v("Add delivery instructions")])
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }