var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-job-counters" },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c(
            "el-badge",
            {
              staticClass: "t-mr-10",
              attrs: {
                value: _vm.jobTypes.length + " of 8",
                hidden: _vm.jobTypes.length === 8
              }
            },
            [
              _c("div", { staticClass: "t-mr-3" }, [_vm._v("Job Types:")]),
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    size: "mini",
                    placeholder: "Job Types"
                  },
                  model: {
                    value: _vm.jobTypes,
                    callback: function($$v) {
                      _vm.jobTypes = $$v
                    },
                    expression: "jobTypes"
                  }
                },
                _vm._l(_vm.jobTypeFilterOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrapper" },
        _vm._l(_vm.contextCounters, function(val, key) {
          return _c(
            "el-badge",
            { key: key, attrs: { value: val, type: "primary" } },
            [
              _c(
                "el-tag",
                {
                  attrs: {
                    size: "small",
                    type: _vm.context === key ? "primary" : "info"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setContext(key)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.keys[key]) + " ")]
              )
            ],
            1
          )
        }),
        1
      ),
      _c("el-divider", { staticClass: "v", attrs: { direction: "vertical" } }),
      _c("el-divider", { staticClass: "h" }),
      _c(
        "div",
        { staticClass: "wrapper" },
        _vm._l(_vm.midTimeCounters, function(value, label) {
          return _c(
            "div",
            { key: label, staticClass: "counter" },
            [
              _c(
                "el-badge",
                { attrs: { value: value, type: _vm.timeCounterType(value) } },
                [
                  _c(
                    "el-tag",
                    {
                      attrs: {
                        type: _vm.timeCounterEffect(label),
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleCounter(label)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(label) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }