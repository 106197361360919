<template>
  <div class="t-bg-gray-100 t-py-6 t-px-12 t-mb-8">
    <div v-if="grandTotals" class="t-text-center t-text-lg t-mb-5">
      Aging Summary
    </div>
    <div class="t-flex t-space-x-12">
      <div
        class="t-w-1/5 t-rounded-md t-border t-border-solid t-border-green-200
          t-flex t-flex-col t-items-center t-justify-center t-bg-white
          t-shadow"
        :class="{'t-h-24': groupBy !== 'consumer'}"
      >
        <div class="t-text-xs t-text-green-500">
          Under 31 days
        </div>
        <div class="t-text-xl t-text-green-700">
          ${{ zeroThirtyBalance }}
        </div>
      </div>
      <div
        class="t-w-1/5 t-rounded t-border t-border-solid t-border-yellow-200
          t-flex t-flex-col t-items-center t-justify-center t-bg-white
          t-shadow"
        :class="{'t-h-24': groupBy !== 'consumer'}"
      >
        <div class="t-text-xs t-text-yellow-600">
          31 - 60 days
        </div>
        <div class="t-text-xl t-text-yellow-700">
          ${{ thirtySixtyBalance }}
        </div>
      </div>
      <div
        class="t-w-1/5 t-rounded t-border t-border-solid t-border-orange-200
          t-flex t-flex-col t-items-center t-justify-center t-bg-white
          t-shadow"
        :class="{'t-h-24': groupBy !== 'consumer'}"
      >
        <div class="t-text-xs t-text-orange-500">
          61 - 90 days
        </div>
        <div class="t-text-xl t-text-orange-700">
          ${{ sixtyNinetyBalance }}
        </div>
      </div>
      <div
        class="t-w-1/5 t-rounded t-border t-border-solid t-border-red-200
          t-flex t-flex-col t-items-center t-justify-center t-bg-white
          t-shadow"
        :class="{'t-h-24': groupBy !== 'consumer'}"
      >
        <div class="t-text-xs t-text-red-500">
          91+ days
        </div>
        <div class="t-text-xl t-text-red-700">
          ${{ ninetyPlusBalance }}
        </div>
      </div>
      <div
        class="t-w-1/5 t-rounded t-border t-border-solid t-border-blue-200
          t-flex t-flex-col t-items-center t-justify-center t-bg-white
          t-shadow"
        :class="{'t-h-24': groupBy !== 'consumer'}"
      >
        <div class="t-text-xs t-text-blue-500">
          Total outstanding
        </div>
        <div class="t-text-xl t-text-blue-700">
          ${{ totalBalance }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    groupBy: String,
    totalBalance: Number,
    ninetyPlusBalance: Number,
    sixtyNinetyBalance: Number,
    thirtySixtyBalance: Number,
    zeroThirtyBalance: Number,
    grandTotals: Boolean
  }
}
</script>
