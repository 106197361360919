var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-h-full t-flex t-flex-col" }, [
    _c(
      "div",
      {
        staticClass:
          "t-px-6 t-h-14 t-flex-shrink-0 t-border-solid t-border-0\n              t-border-b t-border-gray-200 t-flex t-items-center"
      },
      [
        _c("div", { staticClass: "t-text-base t-text-gray-700 t-flex-grow" }, [
          _vm._v(" Browse channels ")
        ]),
        _c(
          "span",
          {
            staticClass:
              "t-border-solid t-border t-border-gray-300 t-px-3 t-py-1\n             t-rounded t-cursor-pointer hover:t-border-blue-500\n             hover:t-text-blue-700",
            on: { click: _vm.createChannel }
          },
          [
            _c("i", { staticClass: "el-icon-plus" }),
            _c("span", { staticClass: "t-ml-2" }, [_vm._v("Create Channel")])
          ]
        )
      ]
    ),
    _vm.loading
      ? _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "t-h-full"
        })
      : _c(
          "div",
          { staticClass: "t-px-6 t-overflow-y-auto t-pb-12" },
          _vm._l(_vm.publicChannels, function(i) {
            return _c(
              "div",
              {
                key: i.sid,
                staticClass:
                  "channel-row t-flex t-items-center t-border-solid t-border-0\n             t-border-b t-border-gray-200 t-p-3"
              },
              [
                _c("div", { staticClass: "t-flex-grow" }, [
                  _c("div", { staticClass: "t-text-gray-700 t-text-base" }, [
                    i.isPrivate
                      ? _c("i", { staticClass: "el-icon-lock" })
                      : _c("span", [_vm._v("#")]),
                    _c("span", { staticClass: "t-ml-1" }, [
                      _vm._v(_vm._s(i.uniqueName))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "t-flex t-text-gray-600 t-text-xs t-mt-1" },
                    [
                      _vm.userChannels.find(function(ch) {
                        return ch.sid === i.sid
                      })
                        ? _c("div", { staticClass: "t-flex" }, [
                            _vm._m(0, true),
                            _c("span", { staticClass: "t-mx-2" }, [_vm._v("•")])
                          ])
                        : _vm._e(),
                      _c("div", [
                        _c("span", [_vm._v(_vm._s(i.membersCount))]),
                        _c("span", { staticClass: "t-ml-1" }, [
                          _vm._v("member")
                        ]),
                        i.membersCount > 1
                          ? _c("span", [_vm._v("s")])
                          : _vm._e(),
                        _c("span", { staticClass: "t-mx-2" }, [_vm._v("•")])
                      ]),
                      _c("div", { staticClass: "t-flex" }, [
                        _c("div", { staticClass: "t-mr-1" }, [
                          _vm._v("Created by")
                        ]),
                        i.createdBy === _vm.username
                          ? _c("div", [_vm._v("you")])
                          : _c("div", [_vm._v(_vm._s(i.createdBy))])
                      ])
                    ]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "button-section t-flex-shrink-0 t-pl-8" },
                  [
                    _vm.userChannels.find(function(ch) {
                      return ch.sid === i.sid && ch.status === "joined"
                    })
                      ? _c("div", [
                          i.createdBy !== "system"
                            ? _c("div", [
                                i.createdBy === _vm.username
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "t-border-solid t-border t-border-red-500 t-bg-red-500\n                    t-text-red-100 t-px-3 t-py-1 t-rounded t-cursor-pointer\n                    hover:t-border-red-400 hover:t-bg-red-400",
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteChannel(i)
                                          }
                                        }
                                      },
                                      [_vm._v(" Delete ")]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "t-border-solid t-border t-border-red-500 t-bg-red-500\n                    t-text-red-100 t-px-3 t-py-1 t-rounded t-cursor-pointer\n                    hover:t-border-red-400 hover:t-bg-red-400",
                                        on: {
                                          click: function($event) {
                                            return _vm.leaveChannel(i)
                                          }
                                        }
                                      },
                                      [_vm._v(" Leave ")]
                                    )
                              ])
                            : _vm._e()
                        ])
                      : _c(
                          "div",
                          {
                            staticClass:
                              "t-border-solid t-border t-border-green-500 t-bg-green-500\n                t-text-green-100 t-px-3 t-py-1 t-rounded t-cursor-pointer\n                hover:t-border-green-400 hover:t-bg-green-400",
                            on: {
                              click: function($event) {
                                return _vm.joinChannel(i)
                              }
                            }
                          },
                          [_vm._v(" Join ")]
                        )
                  ]
                )
              ]
            )
          }),
          0
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t-text-green-600" }, [
      _c("i", { staticClass: "el-icon-check" }),
      _c("span", { staticClass: "t-ml-1" }, [_vm._v("Joined")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }