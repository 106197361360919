var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-group-row" },
    [
      _c(
        "el-row",
        { staticClass: "values", attrs: { gutter: 12 } },
        _vm._l(_vm.fields, function(col) {
          return _c(
            "el-col",
            { key: col.id, attrs: { span: 24 / _vm.fields.length } },
            [
              _c(
                "input-with-label",
                _vm._b(
                  {
                    attrs: { value: _vm.value[col.id] },
                    on: {
                      input: function($event) {
                        return _vm.updateCol($event, col.id)
                      }
                    }
                  },
                  "input-with-label",
                  col,
                  false
                )
              )
            ],
            1
          )
        }),
        1
      ),
      !_vm.main
        ? _c("el-button", {
            attrs: {
              icon: "el-icon-delete",
              circle: "",
              size: "mini",
              disabled: _vm.length === 1
            },
            on: { click: _vm.deleteRow }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }