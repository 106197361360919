import gql from 'graphql-tag'

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFields on Document {
    id
    created
    document_type
    document_name
    url
  }
`
