// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".notification-wrapper-content .button-read {\n  position: absolute;\n  right: 8px;\n  top: 24px;\n  width: 26px !important;\n  padding: 6px;\n  background: #DDF2FF;\n  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n  border-radius: 50%;\n  border: none;\n}\n.notification-wrapper-content .button-read .el-icon-check {\n  color: #0075E4;\n  font-weight: 900;\n}\n.notification-wrapper-content .button-read:hover {\n  background: #bae3fd;\n}", "",{"version":3,"sources":["/app/src/components/Notifications/NotificationWrapper.vue"],"names":[],"mappings":"AAiDE;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EAGA,sBAAA;EAEA,YAAA;EACA,mBAAA;EACA,mDAAA;UAAA,2CAAA;EACA,kBAAA;EACA,YAAA;AAxCJ;AA0CI;EACE,cAAA;EACA,gBAAA;AAxCN;AA2CI;EACE,mBAAA;AAzCN","file":"NotificationWrapper.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.notification-wrapper-content .button-read {\n  position: absolute;\n  right: 8px;\n  top: 24px;\n  width: 26px !important;\n  padding: 6px;\n  background: #DDF2FF;\n  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n  border-radius: 50%;\n  border: none;\n}\n.notification-wrapper-content .button-read .el-icon-check {\n  color: #0075E4;\n  font-weight: 900;\n}\n.notification-wrapper-content .button-read:hover {\n  background: #bae3fd;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
