var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "routing-configuration" },
    [
      _c("h5", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-form",
        { attrs: { model: _vm.data } },
        _vm._l(_vm.config, function(item) {
          return _c(
            "el-form-item",
            {
              key: item.key,
              attrs: { label: item.label + ":", prop: item.key }
            },
            [
              item.type === "radio"
                ? _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.data[item.key],
                        callback: function($$v) {
                          _vm.$set(_vm.data, item.key, $$v)
                        },
                        expression: "data[item.key]"
                      }
                    },
                    _vm._l(item.options, function(option) {
                      return _c(
                        "el-radio",
                        { key: option.value, attrs: { label: option.value } },
                        [_vm._v(" " + _vm._s(option.label) + " ")]
                      )
                    }),
                    1
                  )
                : item.type === "number"
                ? _c(
                    "el-input-number",
                    _vm._b(
                      {
                        attrs: { disabled: _vm.isDisabled(item) },
                        model: {
                          value: _vm.data[item.key],
                          callback: function($$v) {
                            _vm.$set(_vm.data, item.key, $$v)
                          },
                          expression: "data[item.key]"
                        }
                      },
                      "el-input-number",
                      item.config,
                      false
                    )
                  )
                : item.type === "checkbox"
                ? _c("el-checkbox", {
                    attrs: { disabled: _vm.isDisabled(item) },
                    model: {
                      value: _vm.data[item.key],
                      callback: function($$v) {
                        _vm.$set(_vm.data, item.key, $$v)
                      },
                      expression: "data[item.key]"
                    }
                  })
                : item.type === "slider"
                ? _c(
                    "div",
                    { staticClass: "slider-container" },
                    [
                      _c("span", [_vm._v("Minimum driving")]),
                      _c(
                        "el-slider",
                        _vm._b(
                          {
                            attrs: {
                              size: "small",
                              disabled: _vm.isDisabled(item)
                            },
                            model: {
                              value: _vm.data[item.key],
                              callback: function($$v) {
                                _vm.$set(_vm.data, item.key, $$v)
                              },
                              expression: "data[item.key]"
                            }
                          },
                          "el-slider",
                          item.config,
                          false
                        )
                      ),
                      _c("span", [_vm._v("Balance jobs per tech")])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-popover",
                {
                  style: { verticalAlign: "top" },
                  attrs: { trigger: "hover", placement: "right", width: "400" }
                },
                [
                  _c("div", {
                    style: { wordBreak: "normal" },
                    domProps: { innerHTML: _vm._s(item.tooltip) }
                  }),
                  _c("v-icon", {
                    staticClass: "config-tooltip",
                    attrs: { slot: "reference", name: "info-circle" },
                    slot: "reference"
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }