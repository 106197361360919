<template>
  <div class="solid-card-filter">
    <el-checkbox
      class="select-checkbox"
      v-model="select"
      border
      :label="label"
    />
  </div>
</template>

<script>
export default {
  props: ['value', 'label'],
  computed: {
    select: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-checkbox {
  background: #fff;
  text-transform: none;
}

// @include media-breakpoint-down(md) {
//   .solid-card-filter {
//     width: 50%;
//     padding-right: $padding-xs;
//     .select-checkbox {
//       width: 100%;
//     }
//   }
// }
</style>
