<template>
  <div class="t-flex t-items-center t-pt-10 t-px-12 t-mb-8">
    <div v-if="shop.logo.length > 0">
      <img
        :src="shop.logo"
        alt="Shop's logo"
        class="t-object-contain"
        style="max-height: 70px; max-width: 250px;"
      />
    </div>
    <div class="t-flex-grow t-text-right">
      <div class="t-text-xl t-text-gray-700 t-mb-1">
        Aging Report
      </div>
      <div class="t-text-gray-600">as of {{ date }}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    shopId: Number
  },
  computed: {
    shop: function() {
      return this.$store.state.shops.find(i => i.id === this.shopId)
    },
    date: function() {
      return moment().format('LL')
    }
  }
}
</script>
