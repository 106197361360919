<template>
<div v-loading='loading || uploadingDropFile'
  @drop.prevent="documentDrop"
  @dragleave.stop.prevent="dragLeave"
  @dragenter.stop.prevent="dragEnter"
  @dragover.prevent
  @drop="dragging = false"
  :class="{
    'dragging-overlay': dragging,
    'ticket-container': true
  }">
  <el-row>
    <el-col>
      <gb-card title="New Ticket">
        <gb-form
          :schema="[...userSchema, ...problemSchema]"
          v-model="ticketData"
          ref="formRef1"      
        >
        </gb-form>
      </gb-card>
    </el-col>
  </el-row>
  <el-row>
    <div class='mt-2 pl-2 pr-2' style='word-break: keep-all;'>
      <i class="material-icons md-warning md-18" style='color: #ffa500; font-size: 18px;' />
      Please include any information that will help us to identify your issue. Include screenshots, job numbers, error codes,
      dates occurred, steps to reproduce error/issue, users affected, device (pc/mac or mobile device?), browser and any other
      helpful information.
      </div>
  </el-row>
  <el-row>
    <div class="additional-attachments t-pl-2">
      <div class="additional-attachments__label t-mt-5 t-mb-1">Attachments</div>
      <div class="additional-attachments__files">
        <div
          v-for="(attachment, index) in attachments"
          :key="attachment.url"
          class="t-mb-1"
        >
          <a :href="attachment.url" target="_blank">{{attachment.name}}</a>&nbsp;
          <a @click="deleteAttachment(index)" href="javascript:void(0)">
            <v-icon name="times" style='color: red' scale="1.1" />
          </a>
        </div>
        <div class="t-mt-2" @dragover.prevent>
          <FilePicker
            :buttonLabel="'Choose File'"
            :filePickerKey="filePickerKey"
            :handleFilePickerResponse="handleFilePickerResponse"
            :dropListenerName="'emailAttachment'"
            :uploadingDropFile.sync="uploadingDropFile"
          />
          <div class="t-mt-1">
            <div v-if="!uploadingDropFile">Or Drag and Drop...</div>
            <div v-if="uploadingDropFile">Uploading...</div>
          </div>
        </div>
      </div>
    </div>
  </el-row>
  <el-row>
    <el-button class='float-right' @click='submitTicket'>
      Submit
    </el-button>
  </el-row>
</div>
</template>

<script>
import {
  phonePattern,
  emailPattern,
  addressValidator
} from '@/constants/form.config'
import {
  glassbillerSecondaryOptions,
  techsideNativeSecondaryOptions,
  techsideWebSecondaryOptions
} from '@/constants/support-ticket.config.js'
import FilePicker from '@/components/Helpers/FilePicker.vue'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import { get } from 'vuex-pathify'
import * as R from 'ramda'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default {
  components: {
    FilePicker,
    FontAwesomeIcon,
  },
  props: ['filePickerKey', 'tab'],
  data() {
    return {
      ticketData: null,
      loading: false,
      attachments: [],
      dragging: false,
      uploadingDropFile: false
    }
  },
  computed: {
    visible: get('support/visible'),
    userSchema() {
      return [
        [
          {
            id: 'contact.firstName',
            label: 'First Name',
            rule: [
              {
                required: true,
                message: 'first name is required'
              }
            ]
          },
          {
            id: 'contact.lastName',
            label: 'Last Name',
          },
        ],
        [
          {
            id: 'contact.email',
            label: 'Email',
            rule: [
              {
                required: true,
                message: 'email required'
              },
              {
                pattern: emailPattern,
                message: 'invalid email'
              }
            ],
          },
          {
            id: 'contact.phone',
            label: 'Phone *',
            mask: '(###) ###-####',
            rule: [
              {
                required: true,
                message: 'phone is required'
              },
              {
                pattern: phonePattern,
                message: 'invalid phone'
              }
            ]
          }
        ],
        [
          {
            label: 'Shop',
            id: 'cf.shop',
            select: {
              options: this.$store.state.shops,
              labelKey: 'name',
              selectedKey: 'id',
            },
            rule: [
              {
                required: true,
                message: 'Shop required' 
              }
            ]
          },
          {
            label: 'Job #',
            id: 'cf.cf_job_id'
          }
        ]
      ]
    },
    problemSchema() {
      return [
        [
          {
            id: 'productId',
            label: 'Platform',
            select: {
              options: [
                {id: 'glassbiller', name: 'Glassbiller', code: '650853000000290892'}, 
                {id: 'techside_native', name: 'TechSIDE Native', code: '650853000000317245'}, 
                {id: 'techside_web', name: 'Techside/Saleside website', code: '650853000000317263'}
              ],
              labelKey: 'name',
              selectedKey: 'id'
            },
            rule: [
              {
                required: true,
                message: 'product required' 
              }
            ]
          },
          ...(this.ticketData?.productId ? [
            {
              id: 'cf.cf_section',
              label: 'Specific Section',
              select: {
                options: this.productSecondaryOptions
              },
              rule: [
              {
                required: true,
                message: 'section required' 
              }
            ]
            }
          ] : [])
        ],
        ...(this.ticketData?.cf?.cf_section === 'Other' ? [
          [
            {
              id: 'cf.custom_product_section',
              label: 'What section?',
              rule: [
              {
                required: true,
                message: 'section required' 
              }
            ]
            }
          ]
        ] : []),
        [
          {
            id: 'subject',
            label: 'Subject',
            rule: [
              {
                required: true,
                message: 'subject required' 
              }
            ]
          }
        ],
        [
          {
            id: 'description',
            label: 'Description',
            type: 'textarea',
            rule: [
              {
                required: true,
                message: 'description required'
              }
            ]
          }
        ],
      ]
    },
    productSecondaryOptions() {
      if (this.ticketData?.productId?.id === 'glassbiller') return glassbillerSecondaryOptions
      if (this.ticketData?.productId?.id === 'techside_native') return techsideNativeSecondaryOptions
      if (this.ticketData?.productId?.id === 'techside_web') return techsideWebSecondaryOptions
      return []
    }
  },
  methods: {
    async submitTicket() {
      if (await this.$refs.formRef1.validateAll() && this.ticketData?.cf?.shop.id) {
        this.loading = true
        const customFields = {
          cf_shop_id: this.ticketData.cf.shop.id,
          cf_shop_name: this.ticketData.cf.shop.name,
          cf_section: this.ticketData.cf.cf_section === 'Other' ? this.ticketData.cf.custom_product_section : this.ticketData.cf.cf_section,
          cf_job_id: this.ticketData.cf.cf_job_id
        }
        // const dataToSend = {
        //   ...this.ticketData,
        //   productId: R.prop('id', this.ticketData.productId)
        // }
        // const dataToSend = {
        //   // email: 'brandon.rabe@glassbiller.com',
        //   // phone: '(480) 235-7114',
        //   subject: 'Testing -- subject',
        //   description: 'Testing -- description asdf adf afd fdsa fdsa fdas afds fad fads',
        //   contact: {
        //     firstName: 'Brandon',
        //     lastName: 'Rabe',
        //     phone: '(480) 235-7114',
        //     email: 'brandon.rabe@glassbiller.com'
        //   },
        //   productId: R.prop('code', this.ticketData.productId)
        // }
        try {
          const { data } = await this.$unum.support().post('/ticket/create', {...this.ticketData, cf: {...customFields}, uploads: [...this.attachments], productId: R.prop('code', this.ticketData.productId) })
          this.$message.success('Ticket created successfully')
          this.$emit('changeTab', 'dashboard')
        } catch (error) {
          this.$message.warning('There was an error creating this ticket, please try again.')
        }
        this.loading = false
      } else {
        await this.$message.error('Please complete the missing fields')
      }
    },
    handleFilePickerResponse: function (fileName, fileUrl, full) {
      this.attachments.push({
        name: full.filename,
        href: full.url,
        size: full.size,
        isPublic: true
      })
    },
    deleteAttachment (index) {
      const attachments = this.attachments
      attachments.splice(index, 1)
      this.attachments = attachments
    },
    documentDrop: function (e) {
      this.$root.$emit('emailAttachment', e)
    },
    dragEnter() {
      this.dragging -= 1
    },
    dragLeave() {
      this.dragging += 1
    }
  },
  watch: {
    tab(v) {
      if (v === 'create') {
        this.loading = false
        this.ticketData = {}
        this.attachments = []
        this.dragging = false
        this.uploadingDropFile = false
        if (this.$job?.hasId && this.$job.job) {
          this.ticketData = {
            cf: {
              shop: this.$job.shop,
              cf_job_id: `${this.$job.isDraft ? 'Draft-' : ''}${this.$job.job.id}`,
              cf_section: 'Job Page'
            },
            productId: {id: 'glassbiller', name: 'Glassbiller', code: '650853000000290892'},
            contact: {
              email: this.$store.state.user.email
            }
          }
        } else {
          this.ticketData = {
            contact: {
              email: this.$store.state.user.email
            },
            ...(this.$store.state.user.shop_id ? {
              cf: {
                shop: this.getShopFromId(this, this.$store.state.user.shop_id)
              }
            } : {})
          }
        }
      }
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style lang="scss">
.ticket-container {
  // width: 50%;

  .additional-attachments {
    width: 40%;

    &__files {
      line-height: 12px;

      .remove-icon {
        color: $crimson-red;
        vertical-align: text-bottom;

        &:hover {
          color: $watermelon-pink;
        }
      }
      button {
        font-size: 12px;
        padding: .275rem .65rem;
      }
    }
  }

  &.dragging-overlay {
    &:after {
      content: "Drop Files to Upload";
      position: absolute;
      top: $padding-xs;
      bottom: $padding-xs;
      left: $padding-xs;
      right: $padding-xs;
      background: transparentize($primary, 0.7);
      border: 3px dashed $primary;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      color: $primary;
    }
  }

  .uploading-file {
    &:after {
      content: "";
      position: absolute;
      top: $padding-xs;
      bottom: $padding-xs;
      left: $padding-xs;
      right: $padding-xs;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      color: $primary;
    }
  }
}
</style>
