<template>
  <el-dialog
    :visible.sync='localVisible'
    title='New Version'
    append-to-body
    :width='isMobile ? "90%" : "900px"'
    >
    <p>Hello All</p>
    
    <p style='word-break: keep-all;'>The time has arrived. The new version is ready! The conversion to our new system will be different from our previous version upgrades. 
      Your account will need to be converted over and that process will be done overnight during weekends.  
      You can request a conversion by sending us an email. We can only accommodate so many accounts each weekend,  
      so if you would like to be scheduled on the next available slot please email <a href='mailto:support@glassbiller.com'>support@glassbiller.com</a>. 
      Please specify your shop name and phone number and we will put you in queue. We will have video tutorials to go along with new aspects of the site.</p>
    
    <p style='word-break: keep-all;'>Even after the conversion we will continue working at breakneck speed to bring you more features and increase the benefits to your business.</p>
    
    <p style='word-break: keep-all;'>As always, we appreciate you as customers and as business leaders!</p>
  </el-dialog>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
export default {
  props: ['visible'],
  computed: {
    localVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style>

</style>