var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vendor-commissions-tab" }, [
    _c(
      "div",
      [
        _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
          _vm._v("Replacement Commission")
        ]),
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c("el-input", {
                  attrs: { type: "number" },
                  on: { change: _vm.updateCommissionHandler },
                  scopedSlots: _vm._u([
                    {
                      key: "prepend",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "t-w-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.commission.is_percent ? "%" : "$") +
                                " "
                            )
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "append",
                      fn: function() {
                        return [
                          _c(
                            "el-select",
                            {
                              on: { change: _vm.updateCommissionHandler },
                              model: {
                                value: _vm.commission.is_percent,
                                callback: function($$v) {
                                  _vm.$set(_vm.commission, "is_percent", $$v)
                                },
                                expression: "commission.is_percent"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "$", value: 0 }
                              }),
                              _c("el-option", {
                                attrs: { label: "% Total", value: 1 }
                              }),
                              _c("el-option", {
                                attrs: { label: "% Subtotal", value: 2 }
                              }),
                              _c("el-option", {
                                attrs: { label: "% Margin", value: 3 }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.commission.amount,
                    callback: function($$v) {
                      _vm.$set(_vm.commission, "amount", $$v)
                    },
                    expression: "commission.amount"
                  }
                })
              ],
              1
            ),
            _vm.commission.is_percent === 3
              ? _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "Margin calculated before all commissions",
                          placement: "right"
                        }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "material-icons-outline md-error_outline t-text-orange-500 t-mt-1",
                          staticStyle: { "font-size": "32px" }
                        })
                      ]
                    )
                  ],
                  1
                )
              : _c("el-col", { attrs: { span: 12 } })
          ],
          1
        )
      ],
      1
    ),
    _vm.isCSR || _vm.isSalesRep || _vm.isTech
      ? _c(
          "div",
          [
            _c("div", { staticClass: "t-text-gray-400 t-mt-6 t-mb-4" }, [
              _vm._v("Repair Commission")
            ]),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-input", {
                      attrs: { type: "number" },
                      on: { change: _vm.updateChipCommissionHandler },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prepend",
                            fn: function() {
                              return [
                                _c("div", { staticClass: "t-w-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.chip_commission.is_percent
                                          ? "%"
                                          : "$"
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    on: {
                                      change: _vm.updateChipCommissionHandler
                                    },
                                    model: {
                                      value: _vm.chip_commission.is_percent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.chip_commission,
                                          "is_percent",
                                          $$v
                                        )
                                      },
                                      expression: "chip_commission.is_percent"
                                    }
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "$", value: 0 }
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "% Total", value: 1 }
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "% Subtotal", value: 2 }
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "% Margin", value: 3 }
                                    })
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        183638161
                      ),
                      model: {
                        value: _vm.chip_commission.amount,
                        callback: function($$v) {
                          _vm.$set(_vm.chip_commission, "amount", $$v)
                        },
                        expression: "chip_commission.amount"
                      }
                    })
                  ],
                  1
                ),
                _vm.chip_commission.is_percent === 3
                  ? _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "Margin calculated before all commissions",
                              placement: "right"
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "material-icons-outline md-error_outline t-text-orange-500 t-mt-1",
                              staticStyle: { "font-size": "32px" }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : _c("el-col", { attrs: { span: 12 } })
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }