<template>
  <div :class="{'popover-open':show}" id='popover-container' v-close-outside-click="closePopover">
    <div id='popoverTarget'>
      <!-- &nbsp; -->
    </div>
    <b-popover ref="popoverDropZone" target="popoverTarget" triggers='' container='popover-container' :show='show' placement='auto' @hide='popoverHide' >
      <DocumentPopoverContent @openDialog='openDialog' @closePopover='closePopover' :type='getType' :docTypes='getDocTypes' :file='getFile' :refId='getRefId' :shopId='getShopId' :filePickerKey='filePickerKey' :show='show'/>
    </b-popover>
  </div>
</template>

<script>
import DocumentPopoverContent from '@/components/DynamicDocument/DocumentPopoverContent.vue'

export default {
  props: ['showPopover', 'filePickerKey'],
  data () {
    return {
      dialogConfig: null,
      show: false,
      offsetString: ''
    }
  },
  methods: {
    openDialog () {
      this.closePopover()
      this.$nextTick(() => this.$root.$emit('bv::show::modal', 'dynamicDocumentModal'))
    },
    closePopover () {
      this.show = false
    },
    popoverHide (e) {
      if (this.show) {
        e.preventDefault()
      }
    }
  },
  watch: {
    showPopover (val) {
      if (val) {
        this.saving = false
        this.dialogConfig = this.$store.getters.documentConfig
        if (this.dialogConfig.config['directiveType'] && this.dialogConfig.config['directiveType'] === 'dropZone') {
          // this.offsetString = this.dialogConfig.x + 'px, ' + this.dialogConfig.y + 'px'
          var self = this
          this.$nextTick(() => {
            // self.$refs.popoverDropZone.$emit('open')
            self.show = true
            self.$emit('popoverShown')
          })
        }
        // this.$store.commit('setDocumentConfig', null)
      }
    }
  },
  computed: {
    getDocTypes () {
      if (this.dialogConfig && this.dialogConfig.config && this.dialogConfig.config.docOptions) return this.dialogConfig.config.docOptions
      return []
    },
    getType () {
      if (this.dialogConfig && this.dialogConfig.config && this.dialogConfig.config.type) return this.dialogConfig.config.type
      return ''
    },
    getFile () {
      if (this.dialogConfig && this.dialogConfig.file) return this.dialogConfig.file
      return null
    },
    getRefId () {
      if (this.dialogConfig && this.dialogConfig.config && this.dialogConfig.config.id) return this.dialogConfig.config.id
      return null
    },
    getShopId () {
      if (this.dialogConfig && this.dialogConfig.config && this.dialogConfig.config.shopId) return this.dialogConfig.config.shopId
      return null
    }
  },
  components: {
    DocumentPopoverContent
  }
}
</script>
