<template>
  <div id="main-sidebar" class="sidebar" :class="{ expanded: sidebarOpen }">
    <side-bar-button
      :image="logoImg"
      @click="
        sidebarOpen = !sidebarOpen;
        sidebarSync();
      "
    />
    <i class="material-icons-outline md-chevron_right md-18 sidebar-arrow" />
    <div class="sidebar-divider" />
    <side-bar-button
      v-for="button in buttons"
      :key="button.label"
      v-bind="button"
      :expanded="sidebarOpen"
      @click="button.click()"
    />
    <mobile-inbox-dialog :parentVisible.sync="mobileVisible" />
    <div
      class="sidebar-mobile-button"
      @click="sidebarOpen = true"
      v-if="!sidebarOpen && !$route.meta.hideSidebarArrow"
    >
      <i class="material-icons md-arrow_right" />
    </div>
  </div>
</template>

<script>
import SideBarButton from './SideBarButton'
import MobileInboxDialog from '@/components/modals/MobileInboxDialog.vue'
import { get, sync, call } from 'vuex-pathify'
import { schedulerNamespacedMutations } from '@/store/modules/scheduler/scheduler.types'
import Api from '@/services/Api'

export default {
  components: { SideBarButton, MobileInboxDialog },
  data() {
    return {
      logoImg: require('./img/glassbiller_logo_small_v2.svg'),
      mobileVisible: false
    }
  },
  computed: {
    sms: get('twilio/textMessages/unreadThreadsCount'),
    rejected: get('userEdiRejectedJobCount'),
    schedule: get('userTechsideCount'),
    quote: get('userQuoteCount'),
    mobile: get('userSalesideJobCount'),
    fax: get('unreadFaxCount'),
    glaxis: get('glaxisWorkAssignmentsCount'),
    openCount: get('support/openTicketCount'),
    sidebarOpen: sync('sidebarOpen'),
    buttons() {
      return [
        {
          label: 'Support',
          key: 'support',
          image: require('./img/contact_support.svg'),
          click: () => {
            this.supportOpen()
          },
          count: this.openCount
        },
        {
          label: 'SMS',
          key: 'sms',
          image: require('./img/sms.svg'),
          click: () => {
            this.smsOpen()
          },
          count: this.sms
        },
        {
          label: 'Rejected',
          key: 'rejected',
          image: require('./img/rejected.svg'),
          click: () => {
            this.rejectedOpen()
          },
          count: this.rejected
        },
        ...(this.$role.can('scheduler')
          ? [
            {
              label: 'Schedule',
              key: 'schedule',
              image: require('./img/schedule.svg'),
              click: () => {
                this.$store.commit(
                  schedulerNamespacedMutations.openSchedulerDialog
                )
              },
              count: this.schedule
            }
          ]
          : []),
        {
          label: 'Quote',
          key: 'quote',
          image: require('./img/quote.svg'),
          click: () => {
            this.openModalV3('openQuotesDialog')
          },
          count: this.quote
        },
        {
          label: 'Mobile',
          key: 'mobile',
          image: require('./img/mobile.svg'),
          click: () => {
            this.mobileVisible = true
          },
          count: this.mobile
        },
        {
          label: 'Fax',
          key: 'fax',
          image: require('./img/fax.svg'),
          click: () => {
            this.faxOpen()
          },
          count: this.fax
        },
        ...(this.showGlaxis
          ? [
            {
              label: 'Glaxis',
              key: 'glaxis',
              image: require('./img/glaxis.svg'),
              click: () => {
                this.openModalV3('openInsuranceDialog')
              },
              count: this.glaxis
            }
          ]
          : [])
      ]
    },
    showGlaxis() {
      var tempShops = this.$store.state.shops
      for (var i = 0; i < tempShops.length; i++) {
        if (
          tempShops[i].show_glaxis_inbox === '1' ||
          tempShops[i].show_glaxis_inbox === 1
        ) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    displayLeftBar(v) {
      if (v) {
        this.sidebarOpen = true
      }
    },
    '$store.state.user'(val) {
      this.sidebarOpen = val.sidebar_open === 1
    }
  },
  methods: {
    rejectedOpen: call('rejectedInvoices/open'),
    quotesOpen: call('quotes/open'),
    smsOpen: call('twilio/textMessages/open'),
    supportOpen: call('support/open'),
    glaxisOpen: call('glaxis/openGlaxisDialog'),
    faxOpen() {
      this.$root.$emit('bv::show::modal', 'faxInboxModal')
    },
    sidebarSync() {
      const syncObj = { newVal: this.sidebarOpen ? 1 : 0 }
      Api.post('/api/user/sidebarSync', syncObj).then(
        (res) => {},
        function (error) {
          console.log('sidebar sync error:', error)
        }
      )
    },
    openMobileInboxDialog() {
      this.mobileVisible = true
    },
    openModalV3(type) {
      const newEvent = new CustomEvent(type, { detail: true })
      window.dispatchEvent(newEvent)
    }
  },
  created () {
    this.$root.$on('openMobileInboxDialog', this.openMobileInboxDialog)
  },
  beforeDestroy () {
    this.$root.$off('openMobileInboxDialog', this.openMobileInboxDialog)
  }
}
</script>

<style lang="scss" scoped>
@import "./SideBarV2.scss";
</style>
