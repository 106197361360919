<template>
  <span>
    <slot />
    <el-dialog
      :visible.sync="open"
      @opened="opened"
      @closed="closed"
      :title="`Upload Document${docLabel ? ` - ${docLabel}` : ''}`"
      class="filestack-picker"
      :append-to-body="true"
    >
      <slot name="extra" />
      <div class="picker-container" ref="picker" />
    </el-dialog>
  </span>
</template>

<script>
import * as filestack from 'filestack-js'
const FILESTACK_KEY = 'AcGSAppE1TjCSLtiIUYNpz'
const client = filestack.init(FILESTACK_KEY)
export default {
  data() {
    return {
      picker: null
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    docType: String,
    docLabel: String
  },
  computed: {
    open: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    async opened() {
      this.picker = client.picker({
        displayMode: 'inline',
        container: this.$refs.picker,
        uploadConfig: {},
        fromSources: [
          'local_file_system',
          'url',
          'imagesearch',
          'facebook',
          'instagram',
          'googledrive',
          'dropbox',
          'webcam',
          'gmail'
        ],
        onUploadDone: (v) => {
          const uploaded = v?.filesUploaded?.[0]
          if (uploaded) {
            this.$emit('uploaded', uploaded)
            this.$emit('input', false)
          } else {
            this.$message({ type: 'error', message: 'Failed to upload file' })
          }
        }
      })

      this.picker.open()
    },
    closed() {
      // eslint-disable-next-line no-unused-expressions
      this.picker?.close()
      this.$emit('closed')
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
.filestack-picker {
  .picker-container {
    width: 100%;
    height: 560px;
  }
  .fsp-picker--inline {
    .fsp-picker,
    .fsp-modal,
    .fsp-modal__sidebar,
    .fsp-header {
      background-color: #fff;
    }
  }
  .fsp-modal__sidebar:hover,
  .fsp-source-list__item:focus {
    box-shadow: none;
  }
}
</style>
