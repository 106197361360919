<template>
  <div
    :class="{
      'scheduler-map-infowindow': true,
      warehouse: !isJob,
      random: marker.random
    }"
  >
    <el-card :body-style="{ padding: '10px' }">
      <div v-if="isJob" class="info-row">
        <div>
          <span>{{ job.getTitle() || marker.label }}</span>
        </div>
        <div v-if="marker.label">
          custom waypoint
        </div>
        <div v-if="marker.distance_to_next">
          <span>{{ marker.distance_to_next }}</span> to next job
        </div>
        <div v-if="marker.distance_to_prev">
          <span>{{ marker.distance_to_prev }}</span> from prev. job
        </div>
        <div>{{ job.getTime() }}</div>
        <scheduler-times-row :item="marker" />
        <div v-if="marker.random">
          <span>No address</span>
        </div>
      </div>
      <div v-else-if="marker.name" class="info-row">
        <div>
          <span>{{ warehouse.getTitle() }}</span>
        </div>
        <div>
          {{ warehouse.getAddressStreet() }}
          <br />
          {{ warehouse.getAddressCityState() }}
        </div>
        <div v-if="marker.phone">
          <span>Phone:</span>
          {{ warehouse.getPhone() }}
        </div>
        <div v-if="marker.fax">
          <span>Fax:</span>
          {{ warehouse.getFax() }}
        </div>
        <div v-if="marker.manager">
          <span>Manager:</span>
          {{ warehouse.getManager() }}
        </div>
      </div>
      <div v-else class="info-row">
        <div>
          <span>{{ marker.type.toUpperCase() }}</span>
        </div>
        <div>
          {{ marker.details.name }}
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getInfowindowClass } from '@/scripts/helpers/map.helpers'
import { JobWrapperV2 } from '@/scripts/helpers/job.helpers'
import { get } from 'vuex-pathify'
import { WarehouseWrapper } from '@/scripts/helpers/warehouse.helpers'
import SchedulerTimesRow from './helpers/SchedulerTimesRow'
import * as R from 'ramda'

export default {
  components: { SchedulerTimesRow },
  data() {
    return {
      overlayView: null
    }
  },
  props: ['map', 'marker'],
  mounted() {
    const InfoWindow = getInfowindowClass()

    const location =
      this.marker.position ||
      R.path(['details', 'g_address'])(this.marker) ||
      R.path(['g_address'])(this.marker)
    this.overlayView = new InfoWindow(this.map, location, this.$el)
  },
  destroyed() {
    this.overlayView.setMap(null)
  },
  computed: {
    getJob: get(`sch_v3/data@jobs`),
    isJob() {
      return this.marker.job
    },
    job() {
      return this.isJob && new JobWrapperV2(this.marker.job)
    },
    warehouse() {
      return new WarehouseWrapper(this.marker)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerMapInfowindow';
</style>
