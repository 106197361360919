<template>
  <el-dialog
    append-to-body
    title="Notifications"
    :visible="isOpened"
    :before-close="close"
    :show-close="true"
    custom-class="notifications-dialog">
    <div class="notifications-container">
      <div class="settings-icon icon-active-wrapper d-flex flex-column justify-content-center" @click="openSettings">
        <div class="gb-icon-gray-button">
          <i class="material-icons-outline md-settings fix-icon-settings" />
        </div>
      </div>

      <el-tabs :value="activeTab" :before-leave="changeTabTrigger">
        <!-- NEW TAB -->
        <el-tab-pane label="New" :name="tabs.unread.type">
          <div class="d-flex align-items-center tab-header-unread">
            <div class="unread-count" ref="counter">
              {{initialCountText}}
            </div>

            <div class="ml-auto">
              <el-button
                type="text"
                icon="md-done_all material-icons-round"
                class="d-flex align-items-center read-all"
                :disabled="readAllDisabled"
                @click="readAll"
                :loading="readAllLoader"
              >Archive All</el-button>
            </div>
          </div>

          <div class="notifications-list-unread">
            <notifications-list :type="tabs.unread.type" :rows="tabs.unread.rows">
              <div slot="no-more">No more notifications!</div>
              <div slot="no-results">No new notifications!</div>
            </notifications-list>
          </div>

          <div class="tab-footer"><div>&nbsp;</div></div>
        </el-tab-pane>

        <!-- ARCHIVE TAB -->
        <el-tab-pane label="Archived" :name="tabs.read.type" :lazy="true">
          <div class="d-flex align-items-center tab-header-read"></div>

          <div class="notifications-list-read">
            <notifications-list :type="tabs.read.type" :rows="tabs.read.rows">
              <div slot="no-more">No more notifications!</div>
              <div slot="no-results">No notifications in archive!</div>
            </notifications-list>
          </div>

          <div class="tab-footer"><div>&nbsp;</div></div>
        </el-tab-pane>

      </el-tabs>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import NotificationsList from './NotificationsList';

export default {
  data () {
    return {
      initialCountText: '0 New Notifications',
    }
  },

  watch: {
    // direct binding causes halt in list animation on
    // list item removal so using this stupid method
    // to avoid animation disturbance...
    // the cause is most likely in the way vue re-renders common parent objects on child change
    unreadCount(newValue) {

      const suffix = newValue === 1 ? '' : 's';
      const text = `${newValue} New Notification${suffix}`;

      if (this.$refs.counter === undefined) {
        this.initialCountText = text;
        return;
      }

      this.$refs.counter.innerHTML = text;
    }
  },

  computed: {
    ...mapState('notifications', [
      'isOpened',
      'tabs',
      'activeTab',
      'readAllLoader',
      'unreadCount'
    ]),

    readAllDisabled () {
      return this.activeTab === 'read' || !this.tabs.unread.rows.length;
    },
  },

  methods: {
    ...mapActions('notifications', ['close', 'readAll', 'changeTab']),
    ...mapActions('notificationsSettings', { openSettings: 'open' }),

    changeTabTrigger(newTab, oldTab) {
      this.changeTab(newTab);
      return true;
    },
  },

  components: { NotificationsList }
}
</script>

<style lang="scss">

.notifications-dialog { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  .notifications-dialog { font-family: 'Inter var', sans-serif; }
}

.notifications-dialog {
  margin: 56px 0 0 calc(100vw - 360px) !important;
  overflow-y: hidden;
  width: 360px;
  height: calc(100vh - 56px);

  padding-bottom: 16px;

  .el-dialog__header {
    text-align: left !important;
    padding: 16px 0px 16px 16px;

    .el-dialog__headerbtn {
      top: 16px;
      right: 21px;

      > i {
        font-weight: 900;
        color: black;
      }
    }
  }

  .el-dialog__body {
    padding: 0px;
  }

  .el-tabs__header {
    padding: 0px 16px 0px 16px;
    margin-bottom: 0px;

    .el-tabs__active-bar {
      background-color: #053FA1;
    }

    .el-tabs__item {
      width: 82px;
      height: 44px;
      padding: 0px;

      text-align: center;
    }

    .el-tabs__item.is-active {
      color: #053FA1;
    }
  }
}

.notifications-container {
   height: calc(100vh - 86px);

  .read-all {
    padding: 7px 11px 7px 9px;
    gap: 4px;

    border-radius: 6px;
    height: 30px;
    width: 100px;

    background: #DDF2FF;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    > i, > span {
      color: #0075E4;
    }
    > i {
      font-size: 16px;
      font-weight: 600;
    }
    > span {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .read-all.is-disabled {
    >i, >span {
      color: gray;
    }
  }
  // fix span for when loader is enabled
  .read-all.is-loading span {
    margin-left: 0px;
  }

  .read-all:hover {
    background: #bae3fd;
  }

  .notifications-list-unread {
    height: calc(100vh - 86px);
  }

  .notifications-list-read {
    height: calc(100vh - 48px);
  }

}
</style>

<style lang="scss" scoped>

.notifications-container {
  .settings-icon {
    position: absolute;
    top: 62px;
    right: 13px;
    z-index: 1000;
    cursor: pointer;

    :hover {
      color:#409EFF;
    }

    .fix-icon-settings {
      margin-top: 1px;
    }
  }

  .tab-header-unread {
    padding: 16px 16px 8px 16px;
  }

  .tab-header-read {
    padding-top: 16px;
  }

  .tab-footer {
    padding-left: 16px;
    padding-right: 16px;

    > div {
      border-bottom: 1px solid #F3F4F6;
    }
  }

  .unread-count {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6B7280;
  }
}
</style>
