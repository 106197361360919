<template>
  <InfoCard :schema="schema" :dataObject.sync="lossNotice" />
</template>

<script>
import InfoCard from '@/components/modals/GlaxisDialogs/claim/components/InfoCard'
import { applyMediaRules } from '@/scripts/helpers/media.helpers'
import { StateObjects, CanadaProvinceObjects } from '@/scripts/helpers/helpers.js'

export default {
  components: { InfoCard },
  props: ['lossNotice', 'updateSchemaLabelsWithMatchingAndSubrogation', 'shop', 'fieldsDisabled'],
  computed: {
    rowSpanOne() {
      return applyMediaRules({ lg: 24, md: 24, sm: 24, xs: 24 })
    },
    rowSpanTwo() {
      return applyMediaRules({ lg: 12, md: 12, sm: 12, xs: 12 })
    },
    rowSpanThree() {
      return applyMediaRules({ lg: 8, md: 8, sm: 12, xs: 12 })
    },
    rowSpanFour() {
      return applyMediaRules({ lg: 6, md: 6, sm: 12, xs: 12 })
    },
    schema() {
      var schema = {
        title: 'Additional Information',
        icon: 'format_list_bulleted',
        fields: [
          [
            {
              label: 'Shop',
              id: 'name',
              extraData: this.shop,
              editable: false,
              span: this.rowSpanTwo
            },
            {
              label: '*Trading Partner',
              id: 'destination_pid',
              componentType: 'inputWithLabel',
              redIfEmpty: true,
              labelKey: 'label',
              disabled: this.fieldsDisabled,
              select: {
                showLabelValue: true,
                valueKey: 'value',
                options: [{
                  label: 'Lynx Services',
                  value: '1000'
                }
                ],
                fixedOptions: true,
                clearable: false,
                select: ({ value }) => {
                  this.$set(this.lossNotice, 'destination_pid', value)
                },
                clear: () => {
                  this.$set(this.lossNotice, 'destination_pid', '')
                }
              },
              span: this.rowSpanTwo,
              overrideClearable: false
            }
          ],
          [
            {
              label: 'State Policy Was Written',
              id: 'state_policy_written',
              componentType: 'inputWithLabel',
              labelKey: 'label',
              disabled: this.fieldsDisabled,
              select: {
                valueKey: 'value',
                options: this.stateProvinceList,
                showLabelValue: true,
                fixedOptions: true,
                clearable: true,
                select: ({ value }) => {
                  this.$set(this.lossNotice, 'state_policy_written', value)
                },
                clear: () => {
                  this.$set(this.lossNotice, 'state_policy_written', '')
                }
              },
              span: this.rowSpanTwo,
              overrideClearable: true
            },
            {
              label: 'Agency Name',
              id: 'agency_name',
              componentType: 'inputWithLabel',
              span: this.rowSpanTwo,
              disabled: this.fieldsDisabled,
              maxlength: 20
            }
          ],
          [
            {
              label: 'Agent First Name',
              id: 'agent_first_name',
              componentType: 'inputWithLabel',
              span: this.rowSpanTwo,
              disabled: this.fieldsDisabled,
              maxlength: 25
            },
            {
              label: 'Agent Last Name',
              id: 'agent_last_name',
              componentType: 'inputWithLabel',
              span: this.rowSpanTwo,
              disabled: this.fieldsDisabled,
              maxlength: 25
            }
          ],
          [
            {
              label: 'Agent Phone Number',
              id: 'agent_phone_number',
              componentType: 'inputWithLabel',
              type: 'phone',
              span: this.rowSpanTwo,
              disabled: this.fieldsDisabled
            },
            {
              label: 'Insurer Claim Number',
              id: 'insurer_claim_number',
              componentType: 'inputWithLabel',
              span: this.rowSpanTwo,
              tooltip: 'May be supplied by Insurance Co. / TPA to correlate with an internal claim number. If a claim number exists, retailer must send. Send all characters, do not include spaces.',
              disabled: this.fieldsDisabled,
              maxlength: 10
            }
          ],
          [
            {
              label: '*Glass Only Loss',
              redIfEmpty: true,
              id: 'glass_only_loss',
              tooltip: 'Determine if this is a Glass Only claim',
              componentType: 'inputWithLabel',
              labelKey: 'label',
              disabled: this.fieldsDisabled,
              select: {
                valueKey: 'value',
                showLabelValue: true,
                options: [{
                  label: 'Yes',
                  value: 'Y'
                }, {
                  label: 'No',
                  value: 'N'
                }],
                clearable: true,
                fixedOptions: true,
                select: ({ value }) => {
                  this.$set(this.lossNotice, 'glass_only_loss', value)
                },
                clear: () => {
                  this.$set(this.lossNotice, 'glass_only_loss', '')
                }
              },
              span: this.rowSpanTwo,
              overrideClearable: true
            },
            {
              label: '*Cause of Loss',
              id: 'cause_of_loss_code',
              redIfEmpty: true,
              tooltip: 'Determine how loss occurred',
              componentType: 'inputWithLabel',
              labelKey: 'label',
              disabled: this.fieldsDisabled,
              select: {
                valueKey: 'value',
                showLabelValue: true,
                options: [
                  {
                    label: '101 - Rock from Road - No One at Fault',
                    value: '101'
                  },
                  {
                    label: '105 - Rock from Road - 3rd Party Fault License #',
                    value: '105'
                  },
                  {
                    label: '111 - Animal',
                    value: '111'
                  },
                  {
                    label: '121 - Tree Branch',
                    value: '121'
                  },
                  {
                    label: '131 - Garage Door',
                    value: '131'
                  },
                  {
                    label: '199 - Other Object',
                    value: '199'
                  },
                  {
                    label: '201 - Collision',
                    value: '201'
                  },
                  {
                    label: '301 - Vandalism – 3rd Party Known',
                    value: '301'
                  },
                  {
                    label: '311 - Vandalism – 3rd Party Unknown',
                    value: '311'
                  },
                  {
                    label: '321 - Terrorism',
                    value: '321'
                  },
                  {
                    label: '331 - Attempted Theft',
                    value: '331'
                  },
                  {
                    label: '341 - Theft',
                    value: '341'
                  },
                  {
                    label: '401 - Extreme Heat or Cold Weather',
                    value: '401'
                  },
                  {
                    label: '411 - Hail Storm',
                    value: '411'
                  },
                  {
                    label: '421 - Hurricane',
                    value: '421'
                  },
                  {
                    label: '431 - Earthquake',
                    value: '431'
                  },
                  {
                    label: '499 - Other Storm',
                    value: '499'
                  },
                  {
                    label: '901 - Unknown',
                    value: '901'
                  }
                ],
                clearable: true,
                fixedOptions: true,
                select: ({ value }) => {
                  this.$set(this.lossNotice, 'cause_of_loss_code', value)
                },
                clear: () => {
                  this.$set(this.lossNotice, 'cause_of_loss_code', '')
                }
              },
              span: this.rowSpanTwo,
              overrideClearable: true
            }
          ],
          [
            {
              label: '*Contact Person at the Origination of the Transaction',
              tooltip: 'Allows Insurance Company or TPA to contact the originator.',
              redIfEmpty: true,
              id: 'origination_contact_person',
              componentType: 'inputWithLabel',
              disabled: this.fieldsDisabled,
              span: this.rowSpanOne,
              maxlength: 40
            }
          ],
          [
            {
              label: '*Bodily Injury',
              id: 'bodily_injury',
              redIfEmpty: true,
              tooltip: 'Was there an injury as it relates to this claim',
              componentType: 'inputWithLabel',
              labelKey: 'label',
              disabled: this.fieldsDisabled,
              select: {
                valueKey: 'value',
                showLabelValue: true,
                options: [{
                  label: 'Yes',
                  value: 'Y'
                }, {
                  label: 'No',
                  value: 'N'
                }],
                clearable: true,
                fixedOptions: true,
                select: ({ value }) => {
                  this.$set(this.lossNotice, 'bodily_injury', value)
                },
                clear: () => {
                  this.$set(this.lossNotice, 'bodily_injury', '')
                }
              },
              span: this.rowSpanTwo,
              overrideClearable: true
            }
          ]
        ]
      }
      return this.updateSchemaLabelsWithMatchingAndSubrogation(schema)
    },
    stateProvinceList () {
      var results = []
      for (var i = 0; i < StateObjects.length; i++) {
        var object = StateObjects[i]
        if (!object.label) {
          object.label = `${object.text} (${object.value})`
          object.id = `${object.value}`
        }
        results.push(object)
      }
      for (i = 0; i < CanadaProvinceObjects.length; i++) {
        object = CanadaProvinceObjects[i]
        if (!object.label) {
          object.label = `${object.text} (${object.value})`
          object.id = `${object.value}`
        }
        results.push(object)
      }
      return results
    }
  }
}
</script>

<style>

</style>
