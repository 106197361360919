var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "autocomplete input-group-job-nav",
          staticStyle: { position: "relative" }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.internalSearchValue,
                expression: "internalSearchValue"
              }
            ],
            staticClass: "form-control quick-search autocomplete-off",
            attrs: {
              name: "autocompleteOffPleaseThankYou",
              readonly: _vm.readonly,
              placeholder: _vm.filter,
              autocomplete: "false"
            },
            domProps: { value: _vm.internalSearchValue },
            on: {
              keydown: [
                _vm.onKeyDown,
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.enter($event)
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.internalSearchValue = $event.target.value
              }
            }
          })
        ]
      ),
      _c("FailedJobNavDialog", { attrs: { message: _vm.failMessage } }),
      _c("QuickNavResultsDialog", {
        attrs: {
          jobs: _vm.jobResults,
          results: _vm.resultsCount,
          filter: _vm.filter,
          searchVal: _vm.jobSearchVal
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }