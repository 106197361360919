<template>
  <div class="vendor-document-upload">
    <el-upload
      drag
      multiple
      action=""
      :auto-upload="false"
      :show-file-list="false"
      :on-change="onUploadChange"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text">
        Drop files here or <em>click to upload</em>
      </div>
    </el-upload>

    <el-form
      v-if="form.files.length"
      :model="form"
      ref="form"
      class="t-mt-6 t-space-y-4"
    >
      <div
        v-for="(file, index) in form.files"
        :key="index"
        class="t-flex t-items-center t-space-x-4"
      >
        <el-form-item>
          <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            :disabled="submitting"
            circle
            plain
            @click="form.files.splice(index, 1)"
          />
        </el-form-item>

        <el-form-item
          :prop="'files.' + index + '.name'"
          :show-message="false"
          :rules="{
            required: true,
            trigger: 'blur'
          }"
          class="t-flex-grow"
        >
          <el-input v-model="file.name" size="small" />
        </el-form-item>

        <el-form-item
          :prop="'files.' + index + '.document_tag_id'"
          :show-message="false"
          :rules="{
            required: true,
            trigger: 'change'
          }"
        >
          <el-select v-model="file.document_tag_id" size="small">
            <el-option
              v-for="tag in documentTags"
              :key="tag.id"
              :value="tag.id"
              :label="tag.name"
            >
              <div class="t-flex t-items-center t-space-x-3">
                <div
                  class="t-w-2 t-h-2 t-rounded-full"
                  :style="`background: ${tag.color}`"
                />
                <div>{{ tag.name }}</div>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <el-button
        class="t-w-full upload-button"
        type="primary"
        size="small"
        icon="el-icon-upload"
        :loading="submitting"
        @click="uploadDocumentsHandler"
      >
        Upload
      </el-button>

      <el-divider />
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  data() {
    return {
      form: {
        files: []
      },
      submitting: false
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data', 'documentTags'])
  },

  created() {
    this.getDocumentTags()
  },

  methods: {
    ...mapActions(
      useVendorStore,
      ['getDocuments', 'uploadDocument', 'getDocumentTags']
    ),

    async onUploadChange(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = () => {
        this.form.files.push({
          name: file.name,
          type: file.raw.type,
          size: file.raw.size,
          base64: reader.result,
          document_tag_id: null
        })
      }
    },

    uploadDocumentsHandler() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const idsToRemove = []
          try {
            this.submitting = true
            for (const file of this.form.files) {
              await this.uploadDocument(file)
              idsToRemove.push(file.id)
            }
          } catch (e) {
            this.$message.error('Some files were not uploaded. Please try again.')
          } finally {
            this.submitting = false
            this.form.files = this.form.files.filter(
              file => !idsToRemove.includes(file.id)
            )
            await this.getDocuments()
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.vendor-document-upload {
  .el-upload {
    @apply t-w-full t-mb-2;

    .el-upload-dragger {
      @apply t-w-full t-flex t-flex-col t-justify-center t-h-28 t-bg-gray-100 t-mt-2;

      .el-icon-upload {
        @apply t-mt-0;
      }
    }
  }

  .el-input__inner {
    @apply t-rounded-lg;
  }

  .el-select {
    @apply t-w-[150px];
  }

  .upload-button {
    @apply t-rounded-lg;
  }
}
</style>
