<template>
  <el-dialog
    :visible.sync='localVisible'
    :width='dialogWidth'
    :show-close='false'
    append-to-body
    @close="resetDialog"
  >
    <template slot='title'>
      <div class="t-text-center">
        <el-badge value="NEW" class="item" type="success">
          <div class="t-text-xl t-font-bold t-text-gray-800">
            New Glass Supplier Available!
          </div>
        </el-badge>
      </div>
    </template>

    <div class="loading" v-if="loadingData">
      <v-icon name="spinner" scale='1.5' spin />
    </div>

    <add-edit-dialog
      :shopId="shopId"
      :edit="{}"
      vendor="nm"
      :visible.sync="linkingAccount"
      @updateCredentials="createdCredentials"
    />

    <div v-if="!interested">
      <img style="margin:auto" src='../../assets/img/nielsen-moller-logo.png'>
      <img style="margin:auto" src='../../assets/img/nielsen-moller-specs.png'><br/>
      <div class="t-text-xl t-font-bold t-text-gray-800 t-text-center t-mt-6">
        Great Prices &#x2022; Will Call & Delivery Options
      </div>
    </div>

    <div v-else>
      <div class="t-text-gray-800 t-ml-4">
        <span class="t-text-xl">Shop Info:</span>
        <ul class="t-mt-4">
          <li><span class="t-font-bold">Name:</span> {{dataToShare.name}}</li>
          <li><span class="t-font-bold">Phone:</span> {{dataToShare.phone}}</li>
          <li><span class="t-font-bold">Email:</span> {{dataToShare.email}}</li>
        </ul>
      </div>
    </div>

    <span v-if="!interested" slot="footer" class="t-flex t-flex-grow t-justify-between">
      <el-button @click="decline">Maybe later</el-button>
      <el-button type="success" @click="isInterested">Get or Link Account</el-button>
    </span>

    <span v-else slot="footer">
      <span class="t-flex t-flex-grow t-justify-between">
        <el-checkbox
          size="mini"
          v-model="shareData"
          text-color="black"
        >
          Send basic shop info to Nielsen & Moller
        </el-checkbox>
      </span>
      <span class="t-flex t-flex-grow t-justify-between t-mt-2">
        <el-button @click="linkingAccount = true">Link Existing Account</el-button>
        <el-button type="success" @click="submit('getAccount')">Create Account</el-button>
      </span>
    </span>
  </el-dialog>
</template>

<script>
import Api from '@/services/Api'
import { call } from 'vuex-pathify'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import AddEditDialog from '@/components/Vendors/NielsenMoller/AddEditDialog'

export default {
  props: ['shopId', 'syncShopId', 'visible'],
  data () {
    return {
      dataToShare: null,
      interested: false,
      linkingAccount: false,
      loadingData: false,
      shareData: true
    }
  },
  components: {
    AddEditDialog
  },
  computed: {
    localVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    dialogWidth: function () {
      return this.isMobile ? '95%' : '30%'
    },
  },
  methods: {
    openShopDialog: call('shopDialog/open'),
    resetDialog() {
      this.dataToShare = null
      this.interested = false
      this.shareData = true

      if (this.syncShopId) {
        this.$emit('update:shopId', null)
      }
    },
    isInterested() {
      const { shops } = this.$store.state
      const filteredShop = shops.filter((s) => s.id === this.shopId)
      const { name, phone, email } = filteredShop[0]

      this.dataToShare = {
        email,
        name,
        phone,
      }

      this.interested = true
    },
    decline() {
      this.shareData = false
      this.submit('declined')
    },
    createdCredentials({ createdUpdatedId }) {
      if (createdUpdatedId) {
        this.submit('linkAccount')
      }
    },
    async submit(option) {
      await Api.post('/api/nm/handleInvitation', {
        option,
        shareData: this.shareData,
        shopId: this.shopId
      })

      if (option === 'getAccount') {
        window.open('https://portal.nmautoglass.com/register')
      }

      this.resetDialog()
      this.localVisible = false
    }
  },
  mixins: [UtilityMixin]
}
</script>
