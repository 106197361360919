<template>
  <ul class="route-entry-part-pickup-list">
    <li v-for="(parts, job_id) in partList" :key="job_id">
      <strong>
        {{ job_id }}
      </strong>
      <ul>
        <li v-for="part in parts" :key="part.id">
          {{ part.part_number }}
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { getRouteItemPartList } from '@/scripts/helpers/scheduler_v3.helpers'

export default {
  props: ['item'],
  computed: {
    partList() {
      return getRouteItemPartList(this.item)
    }
  }
}
</script>

<style lang="scss" scoped>
.route-entry-part-pickup-list {
  margin: 0 0 0 $padding-md;
  font-size: $font-sm;
  padding-bottom: $padding-xs;
  padding-left: 0;
  ul {
    padding-left: $padding-xs;
    padding-bottom: $padding-xxs;
  }
  li:last-child {
    ul {
      padding-bottom: 0;
    }
  }
}
</style>
