import { make } from 'vuex-pathify'

const state = {
  deviceId: null,
  paymentResponse: null
}

const mutations = make.mutations(state)

const actions = {
  async update ({ commit }, payload) {
    if (!state.paymentResponse || !state.paymentResponse.id || state.paymentResponse.id === payload.id) {
      commit('SET_PAYMENT_RESPONSE', payload)
    }
  },
  async clear ({ commit }) {
    commit('SET_PAYMENT_RESPONSE', null)
    commit('SET_DEVICE_ID', null)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
