<template>
  <div class="scheduler-map-route-stop-overlay">
    <div v-for="stop in stops" :key="stop.id" class="stop-container">
      <component
        :is="icons[stop.type]"
        :fillColor="stop.color || '#607D8B'"
        :text="stop.order"
      />
      <el-card class="stop-details" :body-style="{ padding: '10px' }">
        <div class="title">
          {{ title[stop.type] }}
        </div>
        <route-entry-part-pickup-list
          :item="stop"
          v-if="stop.type === 'pickup'"
        />
        <div v-if="stop.notes">{{ stop.notes }}</div>
        <scheduler-times-row :item="stop" />
      </el-card>
    </div>
  </div>
</template>

<script>
import { getInfowindowClass } from '@/scripts/helpers/map.helpers'
import SchedulerStartIcon from './map-icons/SchedulerStartIcon'
import SchedulerPickupIcon from './map-icons/SchedulerPickupIcon'
import SchedulerStopIcon from './map-icons/SchedulerStopIcon'
import RouteEntryPartPickupList from './RouteEntryPartPickupList'
import SchedulerTimesRow from './SchedulerTimesRow'
import SchedulerAppointmentIcon from './map-icons/SchedulerAppointmentIcon.vue'

export default {
  components: {
    SchedulerStartIcon,
    SchedulerPickupIcon,
    SchedulerStopIcon,
    RouteEntryPartPickupList,
    SchedulerTimesRow
  },
  data() {
    return {
      overlayView: null,
      icons: {
        start: SchedulerStartIcon,
        pickup: SchedulerPickupIcon,
        end: SchedulerStopIcon,
        appointment: SchedulerAppointmentIcon
      },
      title: {
        start: 'Route Start',
        pickup: 'Part Pickup',
        end: 'Route End',
        appointment: 'Appointment'
      },
      hoveredItem: null
    }
  },
  props: ['map', 'stops', 'details'],
  methods: {
    render() {
      if (this.overlayView) {
        this.overlayView.setMap(null)
      }
      const InfoWindow = getInfowindowClass()
      const location = this.details.g_address
      this.overlayView = new InfoWindow(this.map, location, this.$el)
    }
  },
  mounted() {
    const InfoWindow = getInfowindowClass()
    const location = this.details.g_address
    this.overlayView = new InfoWindow(this.map, location, this.$el)
  },
  destroyed() {
    this.overlayView.setMap(null)
  },
  watch: {
    stops(s) {
      this.render()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/SchedulerMapRouteStopOverlay.scss';
</style>
