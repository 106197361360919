<template>
  <el-dialog
    :visible.sync='localVisible'
    width='1200px'
    :modalAppendToBody="false"
    append-to-body
    custom-class="consumer-jobs-dialog"
    @close="close"
    @open="open">
    <span v-if="consumer.id > 1" slot="title">
      <span>There are open drafts for </span>
      <span class="customer-name">{{getConsumerName(consumer)}} </span>
      <span v-if="consumer.is_commercial" class="commercial-customer">Comm</span>
      <span v-else class="individual-customer">Cash</span>
      <span>. Would you like to use one of these?</span>
    </span>
    <span v-else slot="title">
      <span>Jobs with a {{readableMatchKey(match.key)}} of {{match.value}}</span>
    </span>
    <el-tabs tab-position="left" v-model="tab" style="height: 500px;">
      <el-tab-pane name="draft" label="Drafts">
        <span slot="label">Drafts
          <span class="drafts-count">
            <span v-if='loadingCounts'>
              <v-icon  name="spinner" style='color: white;' spin scale="0.7"/>
            </span>
            <span v-else>
              {{ counts['draft'] }}
            </span>
          </span>
        </span>
        <Table
          v-if="localVisible && tab==='draft'"
          :match="match"
          :tab="tab"
          :tableData.sync="tableData"
          :multipleSelection.sync="multipleSelection"
        />
      </el-tab-pane>
      <el-tab-pane name="quote" label="Quotes">
        <span slot="label">Quotes
          <span class="quotes-count">
            <span v-if='loadingCounts'>
              <v-icon  name="spinner" style='color: white;' spin scale="0.7"/>
            </span>
            <span v-else>
              {{ counts['quote'] }}
            </span>
          </span>
        </span>
        <Table
          v-if="localVisible && tab==='quote'"
          :match="match"
          :tab="tab"
          :tableData.sync="tableData"
          :multipleSelection.sync="multipleSelection"
        />
      </el-tab-pane>
      <el-tab-pane name="workOrder" label="Work Orders">
        <span slot="label">Work Orders
          <span class="work-orders-count">
            <span v-if='loadingCounts'>
              <v-icon  name="spinner" style='color: white;' spin scale="0.7"/>
            </span>
            <span v-else>
              {{ counts['workOrder'] }}
            </span>
          </span>
        </span>
        <Table
          v-if="localVisible && tab==='workOrder'"
          :match="match"
          :tab="tab"
          :tableData.sync="tableData"
          :multipleSelection.sync="multipleSelection"
        />
      </el-tab-pane>
      <el-tab-pane name="invoice" label="Invoices">
        <span slot="label">Invoices
          <span class="invoices-count">
            <span v-if='loadingCounts'>
              <v-icon  name="spinner" style='color: white;' spin scale="0.7"/>
            </span>
            <span v-else>
              {{ counts['invoice'] }}
            </span>
          </span>
        </span>
        <Table
          v-if="localVisible && tab==='invoice'"
          :match="match"
          :tab="tab"
          :tableData.sync="tableData"
          :multipleSelection.sync="multipleSelection"
        />
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button
        v-if="multipleSelection.length"
        type="danger"
        style="float:left"
        @click="discardSelectedDrafts">
          Discard
      </el-button>
      <el-button v-if="consumer.id > 0" type="warning" @click="createNewDraft">No, create a new draft</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
import Table from './Table.vue'
import * as R from 'ramda'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    consumer: {
      type: Object,
      default: () => ({})
    },
    match: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loadingCounts: false,
    multipleSelection: [],
    tab: 'draft',
    tableData: [],
    counts: {
      draft: '0',
      quote: '0',
      workOrder: '0',
      invoice: '0'
    }
  }),
  components: { Table },
  methods: {
    getConsumerName (consumer) {
      if (!consumer) return ''
      if (consumer?.is_commercial === 1) {
        return consumer.company_name ? consumer.company_name : consumer.full_name
      } else if (consumer.full_name) {
        return consumer.full_name
      } else if (consumer.name) {
        return consumer.name.last ? consumer.name.first + ' ' + consumer.name.last : consumer.name.first
      } else {
        return 'Guest'
      }
    },
    readableMatchKey (key) {
      const keyConfig = {
        referral_number: 'Referral Number',
        consumer_id: 'Customer Id'
      }
      return keyConfig[key]
    },
    createNewDraft () {
      this.$emit('createNewDraft', { ...this.consumer, skip_check: true, full_name: this.getConsumerName(this.consumer) })
    },
    async discardSelectedDrafts () {
      try {
        await this.$messageBox.confirm(
          `This will permanently delete ${this.multipleSelection.length} draft(s). Continue?`,
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
        // delete selected drafts
        await this.$unum.job().delete('/draft/batch', {
          params: { drafts: this.multipleSelection.map(R.prop('id')) }
        })
        // reset table
        this.getCounts()
        this.multipleSelection = []
        this.tableData = []
        this.tab = null
        var self = this
        setTimeout(function(){
          self.tab = 'draft'
        }, 100)
      } catch {}
    },
    async getCounts () {
      this.loadingCounts = true
      const { data } = await this.$unum.job().get(`/data/counts/${this.match.key}`, {
        params: {
          value: this.match.value
        }
      })
      this.counts = data
      this.loadingCounts = false
      for (const count in this.counts) {
        if (this.counts[count] > 0) {
          this.tab = count;
          break;
        }
      }
    },
    async open () {
      this.getCounts()
    },
    close () {
      this.tableData = [],
      this.multipleSelection = [],
      this.counts = {
        draft: '0',
        quote: '0',
        workOrder: '0',
        invoice: '0'
      }
    }
  },
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    tab: function (o, n) {
      this.tableData = []
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped lang="scss">
  .customer-name {
    color:gray;
  }
  .black-font {
    color:black;
  }
  .center {
    text-align: center;
  }
  .commercial-customer {
    color: white;
    background-color: #F0AD4E;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .individual-customer {
    color: white;
    background-color: #54A955;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .consumer-jobs-dialog {
    height: 500px;
    max-width: 100vw;
  }
  .dialog-footer {
    height: 50px !important;
  }
  .drafts-count {
    background-color: black;
    color: white;
    border-radius: 7px;
    padding: 1px 5px;
    margin-left: 5px;
  }
  .quotes-count {
    background-color: rgb(128, 90, 213);
    color: white;
    border-radius: 7px;
    padding: 1px 5px;
    margin-left: 5px;
  }
  .work-orders-count {
    background-color: rgb(51, 122, 183);
    color: white;
    border-radius: 7px;
    padding: 1px 5px;
    margin-left: 5px;
  }
  .invoices-count {
    background-color: rgb(84, 169, 85);
    color: white;
    border-radius: 7px;
    padding: 1px 5px;
    margin-left: 5px;
  }
</style>