<template>
  <div>
    <b-modal @show="dialogAboutToAppear" @shown="$refs.nameComponent.focus()" no-close-on-backdrop no-close-on-esc v-bind:title="tech.id > 0 ? 'Edit Tech' : 'New Tech'" id="techModal"
      @hidden="resetEditTech"
      header-bg-variant='primary'
      header-text-variant='light'
      size="lg">
      <div class="row">
        <div class="form-group col-6">
          <label>Status</label>
          <select v-model='tech.status' class="form-control form-control-sm">
            <option value="active">Active</option>
            <option value="disabled">Disabled</option>
          </select>
        </div>
        <div v-if="tech.id > 0" class="form-group col-6">
          <label></label>
          <p>ID: <span class="idBox">{{tech.id}}</span></p>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <RequiredInput ref="nameComponent" :title="'Name'" :value.sync='tech.name' :placeholder="''" :capitalizeAll='true' />
        </div>
        <div v-if='checkCommissionPermission' class="form-group col-3">
          <label>Commission</label>
          <FloatDropdown ref="commissionComponent" :dropdownOptions="[{'value':'flat', 'label':'$', 'dropdownLabel':'Flat'}, {'value':'percent', 'label':'%', 'dropdownLabel':'%'}]" :inDisabled="false" :dropdownValue.sync="tech.commission_flat_or_percent" :value.sync="tech.commission"/>
        </div>
        <div v-if='checkCommissionPermission' class="form-group col-3">
          <label>Chip Commission</label>
          <FloatDropdown ref="chipCommissionComponent" :dropdownOptions="[{'value':'flat', 'label':'$', 'dropdownLabel':'Flat'}, {'value':'percent', 'label':'%', 'dropdownLabel':'%'}]" :inDisabled="false" :dropdownValue.sync="tech.chip_commission_flat_or_percent" :value.sync="tech.chip_commission"/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-3">
          <Phone ref="phoneComponent" :title="'Phone'" :value.sync='tech.phone' :placeholder="''" :required='false' />
        </div>
        <div class="form-group col-md-3">
          <Phone ref="faxComponent" :title="'Fax'" :value.sync='tech.fax' :placeholder="''" :required='false' />
        </div>
        <div class="form-group col-md-3">
          <Email ref="emailComponent" :title="'Email'" :value.sync='tech.email' :placeholder="''" :required='false' />
        </div>
        <div class="form-group col-md-3">
          <label>Technician Color</label>
          <br>
          <Sketch v-model="techColor" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label>Address</label>
          <address-input v-model='tech.g_address' with-po-box popper-fix />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label>Notes</label>
          <textarea class="form-control form-control-sm" v-model='tech.notes'></textarea>
        </div>
      </div>
      <div slot="modal-footer" class='w-100'>
        <div class='float-left'>
          <div class="row">
            <div :class="{'col-4':(mostRecentInviteHistory != null), 'col-12':(mostRecentInviteHistory == null)}">
              <button v-if="(!tech.techside_invitation_status || tech.techside_invitation_status === 'pending') || mostRecentInviteHistory != null" type='button' size='lg' class='btn btn-primary' variant="primary" @click="inviteTech">{{(!tech.techside_invitation_status || tech.techside_invitation_status === 'pending')  ? 'Invite Tech' : 'Invite History'}}</button>
            </div>
            <div v-if="mostRecentInviteHistory != null" class="col-8">
              <el-timeline>
                <el-timeline-item
                  :icon="mostRecentInviteHistory.icon"
                  :type="mostRecentInviteHistory.type"
                  :size="mostRecentInviteHistory.size"
                  :timestamp="mostRecentInviteHistory.timestamp">
                  {{ mostRecentInviteHistory.name }}
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
        </div>
        <div class='float-right'>
          <button style="margin-right: 5px;" type='button' size='lg' class='btn btn-primary' variant="primary" @click="saveTech">Save</button>
          <button type='button' class='btn btn-secondary' variant="primary" @click="cancelEdits">Cancel</button>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="row">
        <div class="col-md-4">
          TechSIDE settings
        </div>
      </div>
      <div class="row techside-settings">
        <div class="form-group col-md-4">
          <label>Techside Username (Case Sensitive)</label>
          <input type="text" class="form-control form-control-sm" v-model='tech.techside_username'/>
        </div>
        <div class="form-group col-md-4" v-if="tech.techside_invitation_status">
          <label>Invitation Status</label>
          <input type="text" v-bind:class="{'pendingInvitationStatus': tech.techside_invitation_status === 'pending',
            'acceptedInvitionStatus': tech.techside_invitation_status === 'accepted'}" class="form-control form-control-sm" v-model='tech.techside_invitation_status'
            readonly/>
        </div>
        <div class="col-md-4" >
          <el-checkbox label="Hide completed jobs (legacy)" border v-model='hideCompletedJobs' :style="{marginTop: '21px'}" />
        </div>
      </div>
      <div class="row techside-settings">
        <div class="col-md-3">
          <el-checkbox label="Require clock-in" border v-model='tech.require_clock_in'/>
        </div>
        <div class="col-md-3">
          <el-checkbox label="Require Tech Pickup" border v-model='tech.require_tech_pickup'/>
        </div>
        <div class="col-md-3" >
          <el-checkbox label="Require Inspection" border v-model='tech.require_pre_inspection'/>
        </div>
        <div class="col-md-3" >
          <el-checkbox label="Hide prices" border v-model='tech.techside_hide_prices'/>
        </div>
      </div>
    </b-modal>

    <el-dialog :visible.sync='confirmSaveChanges'>
      <el-row class='dialog-header-text'>
          Save Changes?
      </el-row>
      <br/>
      <el-row class='dialog-body-text'>In order to invite this tech, any unsaved changes must be saved. Would you like to continue?</el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="approveSaveChangesAndInvite()">Yes</el-button>
        <el-button @click="confirmSaveChanges = false">No</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Tech from '@/scripts/objects/tech'
import Phone from '@/components/Helpers/Phone.vue'
import RequiredInput from '@/components/Helpers/RequiredInput.vue'
import Email from '@/components/Helpers/Email.vue'
import { ValidateComponents, ResetValidateComponents, StateObjects } from '@/scripts/helpers/helpers.js'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import FloatDropdown from '@/components/Helpers/FloatDropdown.vue'
import Sketch from '@/assets/customComponents/sketchColorPicker/components/Sketch.vue'
import { mapState } from 'vuex'
import { schedulerNamespacedActions } from '@/store/modules/scheduler/scheduler.types'
import { get, call } from 'vuex-pathify'

export default {
  props: ['parentTech', 'shopId'],
  data () {
    return {
      states: StateObjects,
      tech: new Tech(),
      techColor: {},
      validateInputs: [],
      confirmSaveChanges: false,
      saveAndOpenInvite: false
    }
  },
  mounted: function () {
    this.validateInputs = [this.$refs.nameComponent, this.$refs.commissionComponent,
      this.$refs.chipCommissionComponent, this.$refs.phoneComponent, this.$refs.faxComponent,
      this.$refs.emailComponent]
  },
  computed: {
    mostRecentInviteHistory: get('twilio/textMessages/tssInvite/formattedMostRecentHistory'),
    ...mapState({
      editTech: state => state.scheduler.editTech || state.sch_v2.editTech || state.sch_v3.editTech
    }),
    // fix for this field being an integer but el-checkbox requiring true/false
    hideCompletedJobs: {
      get () {
        if (this.tech.hide_completed_jobs) {
          return true
        } else {
          return false
        }
      },
      set (v) {
        if (v) {
          this.tech.hide_completed_jobs = 1
        } else {
          this.tech.hide_completed_jobs = 0
        }
      }
    }
  },
  methods: {
    getTssInviteMostRecentHistory: call('twilio/textMessages/tssInvite/getMostRecentHistory'),
    clearTssInviteMostRecentHistory: call('twilio/textMessages/tssInvite/clearMostRecentHistory'),
    approveSaveChangesAndInvite () {
      this.confirmSaveChanges = false
      this.saveAndOpenInvite = true
      this.saveTech()
    },
    openTssInviteDialog: call('twilio/textMessages/tssInvite/open'),
    setTssInviteShopId: call('twilio/textMessages/tssInvite/setShopId'),
    setTssInviteInviteType: call('twilio/textMessages/tssInvite/setInviteType'),
    setTssInviteInviteId: call('twilio/textMessages/tssInvite/setInviteId'),
    setInviteInvitePhoneNumber: call('twilio/textMessages/tssInvite/setInvitePhoneNumber'),
    setTssInviteHistoryOnly: call('twilio/textMessages/tssInvite/setHistoryOnly'),
    setTssInviteVendor: call('twilio/textMessages/tssInvite/setVendor'),
    inviteTech: function () {
      var historyOnly = !(!this.tech.techside_invitation_status || this.tech.techside_invitation_status === 'pending')
      if (!historyOnly && (!this.tech.id || this.tech.id <= 0 || !this.tech.cleanCheck)) {
        this.confirmSaveChanges = true
      } else {
        this.$root.$emit('bv::hide::modal', 'techModal')
        this.setTssInviteShopId(this.shopId)
        this.setTssInviteInviteType('tech')
        this.setTssInviteVendor(false)
        this.setTssInviteInviteId(this.tech.id)
        this.setInviteInvitePhoneNumber(this.tech.phone)
        this.setTssInviteHistoryOnly(historyOnly)

        let self = this
        setTimeout(() => {
          self.openTssInviteDialog()
        }, 300)
      }
    },
    dialogAboutToAppear: async function () {
      this.tech = new Tech(this.editTech || this.parentTech)
      if (this.tech.hide_completed_jobs === 1) {
        this.tech.hide_completed_jobs = true
      }

      this.clearTssInviteMostRecentHistory()
      if (this.tech && this.tech.id > 0) {
        var data = {
          objectType: 'tech',
          objectId: this.tech.id
        }
        this.getTssInviteMostRecentHistory(data)
      }

      this.techColor = this.tech.color
      ResetValidateComponents(this.validateInputs)
    },
    saveTech: function (event) {
      if (ValidateComponents(this.validateInputs)) {
        let self = this
        this.tech.createUpdateTech(this.shopId, function (object) {
          var skipSet = false
          if (self.tech.objectTrackerInitial.status === 'active' && object.status === 'disabled') {
            skipSet = true
          }
          self.tech = new Tech(object)
          self.$root.$emit('updateSelectTech', self.tech, skipSet)
          self.$emit('updated', self.tech.id)
          if (self.saveAndOpenInvite) {
            self.saveAndOpenInvite = false
            self.inviteTech()
          } else {
            self.$root.$emit('bv::hide::modal', 'techModal')
          }
        })
      }
    },
    cancelEdits: function (event) {
      event.preventDefault()
      this.$root.$emit('bv::hide::modal', 'techModal')
    },
    resetEditTech: function () {
      this.$store.dispatch(schedulerNamespacedActions.setStateValue, { key: 'editTech' })
    }
  },
  watch: {
    'parentTech': function (val) {
      if (!this.editTech) {
        this.tech = new Tech(this.parentTech)
      }
    },
    'techColor': function (val) {
      if (val.hex) {
        this.tech.color = val.hex
      } else {
        this.tech.color = val
      }
    },
    'tech.zip': function (val) {
      let self = this
      this.zipLookup(val, function (data) {
        self.tech.city = data.city
        self.tech.state = data.state_short
      })
    }
  },
  components: {
    RequiredInput,
    FloatDropdown,
    Phone,
    Email,
    Sketch
  },
  mixins: [UtilityMixin]
}
</script>

<style lang="scss">
.idBox {
  background-color: #EDC9AF;
  padding: 5px;
  border-radius: 3px;
}

.pendingInvitationStatus {
  background-color: #FFFF99 !important;
}

.acceptedInvitionStatus {
  background-color: #66FF99 !important;
}
.techside-settings {
  .col-md-4, .col-md-3 {
    margin-top: $padding-sm;
  }
  .el-checkbox.is-bordered {
    width: 100%;
  }
  .el-checkbox__label {
    text-transform: none;
  }
}
</style>
