import { make } from 'vuex-pathify'
import Job from '@/scripts/objects/job'
import moment from 'moment'
import { stat } from 'fs'

const state = {
  visible: false,
  jobId: null,
  shopId: null,
  history: [],
  currentToken: null,
  job: null,
  balanceDetails: null,
  customer: null,
  billTo: null
}

const mutations = make.mutations(state)

const getters = {
  formattedHistory: (state) => {
    const data = []
    for (const i of state.history) {
      const historyItem = {
        size: 'large',
        timestamp: moment(i.created_at).format('LLLL')
      }
      const amount = i.amount ? i.amount : 0
      if (i.status_name === 'sent') {
        historyItem.name = `
          ${i.e_id ? 'An email' : i.ttm_id ? 'An sms' : 'A '} 
          payment request 
          ${i.consumer_id ? !i.e_id && !i.ttm_id ? 'link generated for ' : 'sent to ' : ''}
          ${i.consumer_id ? (i.is_insurance || i.is_commercial) ? i.company_name : (i.first || '') + ' ' + (i.last || '') : ''} 
          in the amount of $${parseFloat(amount).toFixed(2)}`
        historyItem.type = 'warning'
        historyItem.username = i.username
      } else if (i.status_name === 'paid') {
        if (i.username === null) {
          historyItem.name = 'Customer paid'
        } else {
          historyItem.name = i.username + ' manually updated status to confirmed'
        }
        historyItem.type = 'success'
      } else if (i.status_name === 'declined') {
        if (i.username === null) {
          historyItem.name = 'Customer payment declined'
        } else {
          historyItem.name = i.username + ' manually updated status to declined'
        }
        historyItem.type = 'danger'
      } else if (i.status_name === 'failed') {
        if (i.username === null) {
          historyItem.name = 'Customer payment failed'
        } else {
          historyItem.name = i.username + ' manually updated status to failed'
        }
        historyItem.type = 'danger'
      }
      data.push(historyItem)
    }
    return data
  },
  formattedToken: (state) => {
    return state.currentToken
  },
  status: (state) => {
    return state.history[state.history.length - 1]
  },

  paymentWasSentBefore: (state) => {
    const payments = state.history.filter(item => {
      return item.status_name === 'sent'
    })
    return payments.length > 0
  }
}

const actions = {
  open ({ commit }) {
    commit('SET_VISIBLE', true)
  },
  setJobId ({ commit }, jobId) {
    commit('SET_JOB_ID', jobId)
  },
  setShopId ({ commit }, shopId) {
    commit('SET_SHOP_ID', shopId)
  },
  setBalanceDetails ({ commit }, balanceDetails) {
    commit('SET_BALANCE_DETAILS', balanceDetails)
  },
  setConsumersWithJob ({ commit }, jobObj) {
    commit('SET_CUSTOMER', jobObj?.customer || null)
    commit('SET_BILL_TO', jobObj?.billTo || null)
  },
  async getHistory ({ commit }, jobId) {
    const history = await Job.getTextMessagePaymentHistory(jobId)
    commit('SET_HISTORY', history)
  },
  clearHistory ({ commit }) {
    commit('SET_HISTORY', [])
  },
  async getCurrentToken ({ commit }, jobId) {
    const token = await Job.getTextMessagePaymentCurrentToken(jobId)
    commit('SET_CURRENT_TOKEN', token)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
