<template>
  <div
    class="t-flex t-justify-between t-space-x-6 t-items-center t-px-6 t-py-4 t-border-b t-border-b-gray-200 t-shadow"
  >
    <div class="t-flex t-flex-grow t-justify-between t-items-center">
      <div class="t-flex t-items-center">
        <div class="t-text-gray-400 t-text-base t-mr-6">QBO Sync</div>
      </div>

      <div v-if="startingExport" class="t-flex t-text-yellow-700 t-items-center t-space-x-2">
        <i class="el-icon-loading t-text-xl" />
        <div>Starting export...</div>
      </div>

      <div v-if="!startingExport && exportStarted" class="t-flex t-text-green-600 t-items-center t-space-x-2">
        <i class="material-icons-outline md-check" />
        <div>Export started!</div>
      </div>
    </div>

    <i
      class="material-icons-outline md-clear t-text-gray-600 t-text-lg t-cursor-pointer"
      @click="close"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useQboSyncStore } from '@/components/modals/QuickBooksSyncDialog/qboSync.store.js'

export default {
  computed: {
    ...mapState(useQboSyncStore, ['startingExport']
    ),
    ...mapWritableState(useQboSyncStore, ['exportStarted'])
  },

  watch: {
    exportStarted: function (val) {
      if (val) {
        setTimeout(() => {
          this.exportStarted = false
        }, 3000)
      }
    }
  },

  methods: {
    ...mapActions(useQboSyncStore, ['close'])
  }
}
</script>
