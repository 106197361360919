var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "white-cell-wrap-" + _vm.topIndex } }, [
        _c(
          "div",
          {
            staticClass:
              "cell-container cell-light-blue d-flex justify-content-center align-content-center"
          },
          [
            _c(
              "div",
              { staticClass: "ml-2", staticStyle: { width: "100px" } },
              [
                _c(
                  "el-col",
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", [
                          !_vm.isSinglePartSearchModal
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal",
                                      value: "nagsDetailsModal",
                                      expression: "'nagsDetailsModal'"
                                    }
                                  ],
                                  staticClass: "more-btn align-self-center",
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "update:popupPartDetails",
                                        _vm.popupPartDetailWithChildren
                                      )
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-car" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              class: {
                                btn: true,
                                "btn-gray": _vm.edi_locked !== 1,
                                "btn-sm": true,
                                "align-self-center": true,
                                ediLocked: _vm.edi_locked === 1
                              },
                              attrs: {
                                href: "javascript:void(0)",
                                disabled: _vm.edi_locked === 1
                              },
                              on: {
                                click: function($event) {
                                  _vm.addPartButtonPressed(
                                    _vm.part,
                                    _vm.part.children
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isSinglePartSearchModal ? "Select" : "Add"
                                )
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("InventoryPartAdd", {
                          attrs: {
                            part: _vm.part,
                            vendorObject: _vm.inventoryData(
                              _vm.part.detail.partno
                            ),
                            isButton: true,
                            isDisabled: _vm.edi_locked === 1
                          },
                          on: { addPart: _vm.addPartInventoryButtonPressed }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: "sub-categories-" + _vm.topIndex,
                    expression: "'sub-categories-' + topIndex"
                  }
                ],
                staticClass: "cell-middle-col",
                staticStyle: {
                  display: "block",
                  cursor: "pointer",
                  width: "67%"
                },
                attrs: { "data-parent": "#white-cell-wrap-" + _vm.topIndex }
              },
              [
                _c("p", [
                  _c("b", [
                    _vm.partNameHeader(_vm.part.detail).length > 0
                      ? _c(
                          "span",
                          {
                            staticClass: "text-primary text-bold mr-1",
                            staticStyle: { cursor: "pointer" },
                            attrs: { id: _vm.partNameHeader(_vm.part.detail) },
                            on: {
                              mouseover: function($event) {
                                _vm.getVehiclesByNagsGlassId(
                                  _vm.partNameHeader(_vm.part.detail)
                                )
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.partNameHeader(_vm.part.detail)))]
                        )
                      : _vm._e(),
                    _vm.part.detail.note && _vm.part.detail.note !== ""
                      ? _c(
                          "span",
                          {
                            staticStyle: { color: "red" },
                            attrs: { id: _vm.part.detail.partno + "-note" }
                          },
                          [
                            _vm._v(" "),
                            _c("v-icon", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover",
                                  value: _vm.part.detail.note,
                                  expression: "part.detail.note",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                name: "exclamation-circle",
                                title: "Note for " + _vm.part.detail.partno,
                                scale: "1"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.partNameDetails(_vm.part.detail))
                      }
                    })
                  ])
                ]),
                _vm.trend
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            "Percentage of times part used for this vehicle",
                          placement: "top"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "trend",
                            class: {
                              high: _vm.trend >= 50,
                              med: _vm.trend < 50 && _vm.trend >= 20,
                              low: _vm.trend < 20
                            }
                          },
                          [
                            _vm._v("Trend: "),
                            _c("span", [_vm._v(_vm._s(_vm.trend) + "%")])
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.itemForPart &&
                (_vm.itemForPart.retail_list_price ||
                  _vm.itemForPart.retail_material ||
                  _vm.itemForPart.retail_labor)
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "A Retail price has been set for this item",
                          placement: "top"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "retail-part mr-2",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.addRetailPart($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "Retail Price: $" +
                                _vm._s(
                                  _vm.itemForPart.retail_list_price
                                    ? _vm.itemForPart.retail_list_price.toFixed(
                                        2
                                      )
                                    : "0.00"
                                )
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.itemForPart && _vm.itemForPart.zone
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "A Zone has been set for this item",
                          placement: "top"
                        }
                      },
                      [
                        _c("span", { staticClass: "zone-part" }, [
                          _vm._v("Zone: " + _vm._s(_vm.itemForPart.zone))
                        ])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      triggers: "hover",
                      delay: { show: 750 },
                      placement: "right",
                      target: _vm.partNameHeader(_vm.part.detail)
                    }
                  },
                  [
                    _c(
                      "ul",
                      {
                        staticClass: "m-0 pl-3",
                        attrs: { id: "matchingVehiclesDisplay" }
                      },
                      _vm._l(_vm.matchingVehicles, function(
                        vehicle,
                        vehicleIndex
                      ) {
                        return _c("li", { key: vehicleIndex }, [
                          _vm._v(_vm._s(vehicleIndex) + " "),
                          _c(
                            "ul",
                            { staticClass: "m-0 pl-2" },
                            _vm._l(vehicle, function(model, modelIndex) {
                              return _c("li", { key: modelIndex }, [
                                _vm._v(" " + _vm._s(modelIndex) + " "),
                                _c(
                                  "ul",
                                  { staticClass: "m-0 pl-2" },
                                  _vm._l(model.years, function(
                                    year,
                                    yearIndex
                                  ) {
                                    return _c("li", { key: yearIndex }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(year.year) +
                                          ": " +
                                          _vm._s(year.style) +
                                          " "
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      }),
                      0
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "t-whitespace-no-wrap t-w-1/3 t-text-left" },
              [
                _vm.part.detail.showSecondaryFields
                  ? _c("p", { staticClass: "t-mb-0 t-text-xs" }, [
                      _vm._v(" List Price: "),
                      _c("span", {
                        staticClass: "text-error",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.estimatedCostString(_vm.part.detail)
                          )
                        }
                      })
                    ])
                  : _vm._e(),
                _vm.inventoryIndex !== null &&
                _vm.inventoryParts &&
                _vm.inventoryParts[_vm.inventoryIndex].quantity > 0
                  ? _c(
                      "a",
                      {
                        staticClass: "tag cursor-pointer",
                        staticStyle: {
                          "background-color": "white",
                          color: "blue",
                          display: "inline"
                        },
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.addPartButtonPressed(
                              _vm.part,
                              _vm.part.children,
                              undefined,
                              undefined,
                              true
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          " Inv " +
                            _vm._s(
                              _vm.inventoryParts[_vm.inventoryIndex].quantity >
                                5
                                ? "5+"
                                : _vm.inventoryParts[_vm.inventoryIndex]
                                    .quantity
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                !_vm.checkVendorsClicked[_vm.part.detail.partno]
                  ? _c(
                      "el-button",
                      {
                        staticClass:
                          "t-text-white t-bg-gray-600 hover:t-opacity-75 t-rounded-full\n                 t-px-3 t-py-1 t-mt-1",
                        on: { click: _vm.handleCheckVendors }
                      },
                      [_vm._v(" Check Vendors ")]
                    )
                  : _c(
                      "div",
                      _vm._l(_vm.vendors, function(vendor) {
                        return _c("div", { key: vendor.name }, [
                          _vm.hasVendorCredentials(vendor.name)
                            ? _c(
                                "div",
                                [
                                  _vm.vendorSearchResults[vendor.name][
                                    _vm.part.detail.partno
                                  ].loading
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "t-border-none t-rounded-full t-mt-1 t-text-085\n                       t-font-medium t-px-1",
                                          class: _vm.vendorLoaderBg(vendor.name)
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-loading"
                                          })
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        [
                                          vendor.name === "pilkington"
                                            ? _c("PilkingtonButton", {
                                                attrs: {
                                                  vendor: vendor.name,
                                                  priceFromLevels: _vm.getPriceFromLevels(
                                                    vendor.name
                                                  ),
                                                  partNumber:
                                                    _vm.part.detail.partno,
                                                  part:
                                                    _vm.vendorSearchResults[
                                                      vendor.name
                                                    ][_vm.part.detail.partno]
                                                      .data[0]
                                                }
                                              })
                                            : vendor.name === "american"
                                            ? _c("AmericanButton", {
                                                attrs: {
                                                  vendor: vendor.name,
                                                  priceFromLevels: _vm.getPriceFromLevels(
                                                    vendor.name
                                                  ),
                                                  partNumber:
                                                    _vm.part.detail.partno,
                                                  part:
                                                    _vm.vendorSearchResults[
                                                      vendor.name
                                                    ][_vm.part.detail.partno]
                                                      .data[0]
                                                }
                                              })
                                            : _c("GlaxisButton", {
                                                attrs: {
                                                  vendor: vendor.name,
                                                  priceFromLevels: _vm.getPriceFromLevels(
                                                    vendor.name
                                                  ),
                                                  partNumber:
                                                    _vm.part.detail.partno,
                                                  part:
                                                    _vm.vendorSearchResults[
                                                      vendor.name
                                                    ][_vm.part.detail.partno]
                                                      .data[0]
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                  vendor.name === "pilkington"
                                    ? _c("PilkingtonDialog", {
                                        attrs: {
                                          nagsPart: _vm.part,
                                          priceFromLevels: _vm.getPriceFromLevels(
                                            vendor.name
                                          ),
                                          results:
                                            _vm.vendorSearchResults[
                                              vendor.name
                                            ][_vm.part.detail.partno].data,
                                          visible:
                                            _vm.vendorSearchResults[
                                              vendor.name
                                            ][_vm.part.detail.partno].visible,
                                          loading:
                                            _vm.vendorSearchResults[
                                              vendor.name
                                            ][_vm.part.detail.partno].loading,
                                          shopId: _vm.shopId,
                                          jobId: _vm.job.id
                                        },
                                        on: {
                                          addPart: _vm.addPartButtonPressed,
                                          recheckVendor: function($event) {
                                            return _vm.checkVendors($event)
                                          }
                                        }
                                      })
                                    : vendor.name === "american"
                                    ? _c("AmericanDialog", {
                                        attrs: {
                                          nagsPart: _vm.part,
                                          priceFromLevels: _vm.getPriceFromLevels(
                                            vendor.name
                                          ),
                                          results:
                                            _vm.vendorSearchResults[
                                              vendor.name
                                            ][_vm.part.detail.partno].data,
                                          visible:
                                            _vm.vendorSearchResults[
                                              vendor.name
                                            ][_vm.part.detail.partno].visible,
                                          loading:
                                            _vm.vendorSearchResults[
                                              vendor.name
                                            ][_vm.part.detail.partno].loading,
                                          shopId: _vm.shopId,
                                          jobId: _vm.job.id,
                                          defaultCredentials: _vm.getDefaultVendorCredentials(
                                            "american"
                                          ).accountNumber
                                        },
                                        on: {
                                          addPart: _vm.addPartButtonPressed,
                                          recheckVendor: function($event) {
                                            return _vm.checkVendors($event)
                                          }
                                        }
                                      })
                                    : _c("GlaxisDialog", {
                                        attrs: {
                                          vendor: vendor.name,
                                          nagsPart: _vm.part,
                                          priceFromLevels: _vm.getPriceFromLevels(
                                            vendor.name
                                          ),
                                          results:
                                            _vm.vendorSearchResults[
                                              vendor.name
                                            ][_vm.part.detail.partno].data,
                                          visible:
                                            _vm.vendorSearchResults[
                                              vendor.name
                                            ][_vm.part.detail.partno].visible,
                                          loading:
                                            _vm.vendorSearchResults[
                                              vendor.name
                                            ][_vm.part.detail.partno].loading,
                                          shopId: _vm.shopId,
                                          jobId: _vm.job.id,
                                          defaultWarehouseId:
                                            vendor.name === "mygrant"
                                              ? _vm.shop
                                                  .mygrant_default_warehouse_id
                                              : null,
                                          defaultCredentials:
                                            vendor.name === "pgw"
                                              ? _vm.getDefaultVendorCredentials(
                                                  "pgw"
                                                ).accountNumber
                                              : null
                                        },
                                        on: {
                                          addPart: _vm.addPartButtonPressed,
                                          recheckVendor: function($event) {
                                            return _vm.checkVendors($event)
                                          }
                                        }
                                      })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      }),
                      0
                    ),
                _c("WarehouseWrapper", {
                  attrs: {
                    vendor: "inventory",
                    part: _vm.part,
                    checking: false,
                    check: false,
                    vendorObject: _vm.inventoryData(_vm.part.detail.partno),
                    pricelevels: _vm.pricelevels,
                    selectedPriceLevel: _vm.selectedPriceLevel,
                    shopId: _vm.shopId
                  },
                  on: { addPart: _vm.addPartButtonPressed }
                })
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "b-collapse",
        {
          staticClass: "partResultCollapse",
          attrs: { id: "sub-categories-" + _vm.topIndex }
        },
        _vm._l(_vm.part.children, function(partCategory, categoryIndex) {
          return _c(
            "div",
            { key: _vm.topIndex + "-" + categoryIndex },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: "cell-inner-" + _vm.topIndex + "-" + categoryIndex,
                      expression:
                        "'cell-inner-' + topIndex + '-' + categoryIndex"
                    }
                  ],
                  staticClass: "cell-sub-header",
                  style: {
                    "background-image": _vm.partCategoryColor(
                      partCategory.detail.name
                    )
                  },
                  attrs: { "data-parent": "#sub-categories-" + _vm.topIndex }
                },
                [_c("h5", [_vm._v(_vm._s(partCategory.detail.name))])]
              ),
              _c(
                "b-collapse",
                {
                  staticClass: "partResultCollapse",
                  attrs: {
                    id: "cell-inner-" + _vm.topIndex + "-" + categoryIndex
                  }
                },
                _vm._l(partCategory.children, function(subPart, subPartIndex) {
                  return _c(
                    "div",
                    {
                      key:
                        _vm.topIndex + "-" + categoryIndex + "-" + subPartIndex,
                      staticClass: "cell-container p-0"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "border-bottom d-flex justify-content-around align-content-center p-1 text-smaller"
                        },
                        [
                          !_vm.isSinglePartSearchModal
                            ? _c(
                                "div",
                                {
                                  staticClass: "p-1",
                                  staticStyle: { width: "30px" }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal",
                                          value: "nagsDetailsModal",
                                          expression: "'nagsDetailsModal'"
                                        }
                                      ],
                                      staticClass: "more-btn align-self-center",
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "update:popupPartDetails",
                                            Object.assign({}, subPart.detail, {
                                              glassGraphicId:
                                                _vm.part.detail.glassGraphicId,
                                              callouts:
                                                _vm.part.details.callouts
                                            })
                                          )
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fa fa-car" })]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "p-1",
                              staticStyle: { width: "20%" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "badge badge-success",
                                  style: {
                                    "background-color": _vm.partCategoryListColor(
                                      partCategory.detail.name
                                    ),
                                    cursor: "pointer"
                                  },
                                  attrs: {
                                    href: "javascript:void(0)",
                                    disabled: _vm.edi_locked === 1
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.addPartButtonPressed(
                                        subPart,
                                        subPart.children
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .partNameHeader(subPart.detail)
                                          .trim().length > 0
                                          ? _vm.partNameHeader(subPart.detail)
                                          : "Add"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("InventoryPartAdd", {
                                attrs: {
                                  part: subPart,
                                  vendorObject: _vm.inventoryData(
                                    subPart.detail.partno
                                  ),
                                  isDisabled: _vm.edi_locked === 1
                                },
                                on: { addPart: _vm.addPartButtonPressed }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "p-1",
                              staticStyle: { width: "55%" }
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.partNameDetails(subPart.detail)
                                  )
                                }
                              })
                            ]
                          ),
                          subPart.detail.showSecondaryFields
                            ? _c(
                                "div",
                                {
                                  staticClass: "p-1",
                                  staticStyle: { width: "25%" }
                                },
                                [
                                  _c("b", [_vm._v("List Price: ")]),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "text-error",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.estimatedCostString(subPart.detail)
                                      )
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          !_vm.isSinglePartSearchModal
                            ? _c(
                                "div",
                                {
                                  staticClass: "p-1",
                                  staticStyle: { width: "100px" }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        color: "black",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.part.detail.autoAddParts,
                                            expression:
                                              "part.detail.autoAddParts"
                                          }
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          value: subPart.detail.partno,
                                          checked: Array.isArray(
                                            _vm.part.detail.autoAddParts
                                          )
                                            ? _vm._i(
                                                _vm.part.detail.autoAddParts,
                                                subPart.detail.partno
                                              ) > -1
                                            : _vm.part.detail.autoAddParts
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$a =
                                                  _vm.part.detail.autoAddParts,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = subPart.detail.partno,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.part.detail,
                                                      "autoAddParts",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.part.detail,
                                                      "autoAddParts",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.part.detail,
                                                  "autoAddParts",
                                                  $$c
                                                )
                                              }
                                            },
                                            function($event) {
                                              return _vm.autoAddChanged(
                                                _vm.part.detail.partno,
                                                _vm.part.detail.autoAddParts
                                              )
                                            }
                                          ]
                                        }
                                      }),
                                      _vm._v(" Auto Add")
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        }),
        0
      ),
      _c(
        "InventoryConfirmationDialog",
        _vm._b(
          {
            attrs: {
              visible: _vm.confirmInventoryIsVisible,
              inventoryDialogType: null,
              isInventoryDialogFromNagsSearch: true
            },
            on: {
              "update:visible": function($event) {
                _vm.confirmInventoryIsVisible = $event
              },
              takePartFromInventory: _vm.takePartFromInventory
            }
          },
          "InventoryConfirmationDialog",
          _vm.inventoryDialogObject,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }