import * as R from 'ramda'

export const activityNamespace = 'activity'

export const types = {
  getShopTechs: 'GET_SHOP_TECHS',
  getTechActivity: 'GET_TECH_ACTIVITY'
}

export default R.map(R.concat(activityNamespace + '/'))(types)
