// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".timestamp {\n  color: #909399;\n  line-height: 1;\n  font-size: 13px;\n}\n.timestamp .username {\n  font-weight: 700 !important;\n  color: #9ca3af !important;\n}", "",{"version":3,"sources":["/app/src/components/Twilio/TextMessages/JobPayment/History.vue"],"names":[],"mappings":"AA4CA;EACI,cAAA;EACA,cAAA;EACA,eAAA;AAhCJ;AAiCI;EACE,2BAAA;EACA,yBAAA;AA/BN","file":"History.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.timestamp {\n  color: #909399;\n  line-height: 1;\n  font-size: 13px;\n}\n.timestamp .username {\n  font-weight: 700 !important;\n  color: #9ca3af !important;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
