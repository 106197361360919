var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "t-mb-4 t-flex t-px-12" }, [
        _c("div", { staticClass: "t-text-base t-flex-grow" }, [
          _c(
            "span",
            { staticClass: "t-mr-2", class: { "t-italic": !_vm.name } },
            [
              _vm._v(" " + _vm._s(_vm.name || "No name") + " "),
              _c(
                "span",
                { staticClass: "consumer-tags t-mr-2" },
                [
                  _vm.data.consumer_type === "insurance"
                    ? _c(
                        "gb-tag",
                        { attrs: { small: true, disable: "", color: "blue" } },
                        [_vm._v(" Ins ")]
                      )
                    : _vm.data.consumer_type === "commercial"
                    ? _c(
                        "gb-tag",
                        {
                          attrs: { small: true, disable: "", color: "orange" }
                        },
                        [_vm._v(" Com ")]
                      )
                    : _vm.data.consumer_type === "cash"
                    ? _c(
                        "gb-tag",
                        { attrs: { small: true, disable: "", color: "green" } },
                        [_vm._v(" Cash ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("span", { staticClass: "t-text-xs t-text-gray-600" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.data.invoices.length) +
                    " invoice" +
                    _vm._s(_vm.data.invoices.length > 1 ? "s" : "") +
                    " "
                )
              ])
            ]
          )
        ]),
        _vm.groupBy === "age"
          ? _c("div", [
              _c("span", { staticClass: "t-text-sm t-mr-2" }, [
                _vm._v("Total:")
              ]),
              _vm._v(" $" + _vm._s(_vm.data.balance) + " ")
            ])
          : _vm._e()
      ]),
      _c("Table", {
        attrs: {
          invoices: _vm.data.invoices,
          shopId: _vm.shopId,
          expandPayments: _vm.expandPayments
        }
      }),
      _vm.groupBy === "age"
        ? _c("div", { staticClass: "t-text-right t-mt-4 t-px-12" }, [
            _c("span", { staticClass: "t-text-sm t-mr-2" }, [_vm._v("Total:")]),
            _vm._v(" $" + _vm._s(_vm.data.balance) + " ")
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "t-px-12 t-mt-6" },
        [
          _vm.groupBy === "consumer"
            ? _c("OutstandingBalances", {
                attrs: {
                  groupBy: _vm.groupBy,
                  totalBalance: _vm.data.balance,
                  ninetyPlusBalance: _vm.data.ninety_plus_balance,
                  sixtyNinetyBalance: _vm.data.sixty_ninety_balance,
                  thirtySixtyBalance: _vm.data.thirty_sixty_balance,
                  zeroThirtyBalance: _vm.data.zero_thirty_balance
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("el-divider")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }