var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          ref: "rejected-dialog",
          attrs: {
            visible: _vm.visible,
            title: "Rejected Invoices",
            width: "1400px",
            "destroy-on-close": "",
            "custom-class": "gb-el-dialog rejected-invoices-dialog",
            top: "24px",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.close,
            open: _vm.open
          }
        },
        [
          _c("shop-select"),
          _c(
            "el-checkbox",
            {
              staticClass: "show-void-checkbox",
              model: {
                value: _vm.includeVoid,
                callback: function($$v) {
                  _vm.includeVoid = $$v
                },
                expression: "includeVoid"
              }
            },
            [_vm._v(" Include voided jobs ")]
          ),
          _c("rejected-tabs")
        ],
        1
      ),
      _c("ediqueue-message-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }