<template>
  <div>
    <gb-form :schema="schema" v-model="data" ref='formRef' :key="forceUpdate" v-loading="loading" />
    <el-button @click='submit'>
      Submit Comment
    </el-button>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";

Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji,
    "modules/imageDrop": ImageDrop
  },
  true
);

export default {
  props: ['ticketId'],
  data() {
    return {
      data: {},
      templateBodySelection: {
        index: 0,
        length: 0
      },
      quill: null,
      forceUpdate: 0,
      dragging: 0,
      loading: false
    }
  },
  components: {
    VueEditor
  },
  computed: {
    schema() {
      return [
        [
          {
            id: 'body',
            label: 'Body',
            type: 'wysiwyg',
            editorOptions: {
              modules: {
                toolbar: {
                  container: [
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                    ['bold', 'italic', 'underline'], // toggled buttons
                    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    ['clean'], // remove formatting button
                    ['emoji'],
                    ["image"]
                  ],
                  handlers: {
                    emoji: function() {},
                    imageAdded: function() {
                    }
                  }
                },
                short_name_emoji: true,
                toolbar_emoji: true,
                textarea_emoji: false,
                imageDrop: true
              }
            },
            onSelectionChange: (range) => {
              this.templateBodySelection = range
            },
            onFocus: (quill) => {
              this.quill = quill
            }
          }
        ]
      ]
    }
  },
  methods: {
    async submit() {
      if (this.data?.body && this.data?.body.length) {
        this.loading = true
        const {data} = await this.$unum.support().post(`/ticket/${this.ticketId}/comment`, {comment: this.data.body})
        this.$message({ type: 'success', message: 'Comment added'})
        this.$emit('addedComment')
      } else {
        this.$message({ type: 'error', message: 'Please enter a comment to submit'})
      }
    }
  }
}
</script>

<style>

</style>