var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "threads-wrapper" } },
    [
      _c("start-chat", {
        ref: "startChat",
        attrs: {
          shopPhoneNumber: _vm.shopPhoneNumber,
          recipientPhoneNumber: _vm.recipientPhoneNumber
        },
        on: {
          reloadThreads: function($event) {
            return _vm.reloadThreads()
          },
          setShopPhoneNumber: function($event) {
            return _vm.setShopPhoneNumber($event)
          },
          setRecipientPhoneNumber: function($event) {
            return _vm.setRecipientPhoneNumber($event)
          }
        }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.threadsLoading,
              expression: "threadsLoading"
            }
          ],
          staticClass: "infinite-list-wrapper",
          attrs: { id: "text-message-threads" }
        },
        [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.load,
                  expression: "load"
                }
              ],
              staticClass: "threads-list",
              attrs: {
                "infinite-scroll-delay": "300",
                "infinite-scroll-disabled": "disabled",
                "infinite-scroll-immediate": "false"
              }
            },
            _vm._l(_vm.threads, function(i) {
              return _c(
                "li",
                {
                  key: i.id,
                  style:
                    i.new === 1 ? "font-weight: bold; color: #303133;" : "",
                  on: {
                    click: function($event) {
                      return _vm.openThread(i)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "threads-item",
                      style:
                        i.id === _vm.selectedThread.id
                          ? "background-color: #EBEEF5;"
                          : ""
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "thread-icon" },
                        [
                          i.recipient_model === null
                            ? _c("el-avatar", {
                                staticClass: "recipient-icon",
                                attrs: { icon: "el-icon-user-solid" }
                              })
                            : _vm._e(),
                          i.recipient_model === "customer" ||
                          i.recipient_model === "consumer"
                            ? _c(
                                "el-avatar",
                                {
                                  staticClass:
                                    "recipient-icon recipient-customer"
                                },
                                [_vm._v("C")]
                              )
                            : _vm._e(),
                          i.recipient_model === "salesrep"
                            ? _c(
                                "el-avatar",
                                {
                                  staticClass:
                                    "recipient-icon recipient-salesrep"
                                },
                                [_vm._v("S")]
                              )
                            : _vm._e(),
                          i.recipient_model === "tech"
                            ? _c(
                                "el-avatar",
                                {
                                  staticClass: "recipient-icon recipient-tech"
                                },
                                [_vm._v("T")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "thread-info" }, [
                        _c("div", { staticClass: "phone-number-name" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatPhoneNumberName(i.phone_number_name)
                            )
                          )
                        ]),
                        _c("div", [
                          _c("i", { staticClass: "el-icon-phone" }),
                          _vm._v(
                            _vm._s(_vm.formatPhone(i.recipient_phone_number))
                          )
                        ]),
                        i.recipient_name
                          ? _c("div", [
                              i.recipient_model === "customer"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "recipient-customer",
                                      on: {
                                        click: function($event) {
                                          return _vm.openCustomerInfoModal(
                                            i.recipient_id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-user-solid"
                                      }),
                                      _vm._v(_vm._s(i.recipient_name) + " ")
                                    ]
                                  )
                                : _c("span", [
                                    _c("i", {
                                      staticClass: "el-icon-user-solid"
                                    }),
                                    _vm._v(_vm._s(i.recipient_name))
                                  ])
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "message-body" }, [
                          i.last_message_direction === 2
                            ? _c("span", [_vm._v("You: ")])
                            : _vm._e(),
                          i.last_message_body.length === 0
                            ? _c("span", [_c("i", [_vm._v("Attachment")])])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm.formatBody(i.last_message_body))
                                )
                              ])
                        ])
                      ]),
                      _c("div", { staticClass: "thread-right" }, [
                        i.new
                          ? _c("span", { staticClass: "thread-new" }, [
                              _vm._v("new")
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "thread-datetime" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDateTime(i.last_message_created_at)
                              )
                            )
                          ])
                        ]),
                        i.failed
                          ? _c("span", { staticClass: "thread-failed" }, [
                              _vm._v("failed")
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              )
            }),
            0
          ),
          _vm.threadsLoading
            ? _c("p", { staticClass: "threads-message" }, [
                _vm._v(" Loading... ")
              ])
            : _vm._e(),
          _vm.noMore
            ? _c("p", { staticClass: "threads-message" }, [
                _vm.threads.length !== 0
                  ? _c("span", [_vm._v("No more records")])
                  : _c("span", [_vm._v("No records")])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }