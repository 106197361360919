<template>
  <el-form-item prop="amount">
    <span slot="label" class="undo-label-styles">Requested Amount</span>
    <el-input
      v-model="amount" @blur='currencyBlur'
    >
      <span class="t-ml-2" slot="prefix">$</span>
    </el-input>
  </el-form-item>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
export default {
  props: ['requestedAmount'],
  computed: {
    amount: {
      get () {
        return this.requestedAmount
      },
      set (v) {
        this.$emit('update:requestedAmount', v)
      }
    },
  },
  methods: {
    currencyBlur () {
      if (isNaN(this.amount) || !this.amount) {
        this.amount = null
      } else {
        this.amount = parseFloat(this.amount).toFixed(2)
      }
    },
  }
}
</script>

<style>

</style>