var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      attrs: {
        border: "",
        size: "mini",
        "empty-text": "No devices are connected",
        data: _vm.devices
      }
    },
    [
      _c("el-table-column", { attrs: { prop: "name", label: "Name" } }),
      _c("el-table-column", {
        attrs: { prop: "product_name", label: "Device Type" }
      }),
      _c("el-table-column", {
        attrs: { label: "Actions", width: "175" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  { staticClass: "t-flex" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Delete",
                          placement: "top",
                          "open-delay": 300
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            size: "mini",
                            type: "danger",
                            icon: "el-icon-delete",
                            disabled: _vm.disabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(scope.row)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }