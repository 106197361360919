var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    { attrs: { trigger: "click" } },
    [
      _c(
        "div",
        { style: { display: "flex" } },
        [
          _c("el-input", {
            model: {
              value: _vm.note,
              callback: function($$v) {
                _vm.note = $$v
              },
              expression: "note"
            }
          }),
          _c("el-button", {
            style: { marginLeft: "8px" },
            on: { click: _vm.save }
          })
        ],
        1
      ),
      _c(
        "el-link",
        {
          staticClass: "job-details-new-note",
          attrs: { slot: "reference", type: "primary" },
          slot: "reference"
        },
        [_vm._v(" + Add ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }