var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pdf-option-card-list" },
    [
      _c("div", { staticClass: "pdf-options-list-header" }, [
        _c("div", { staticClass: "header-text" }, [
          _vm._v("Column " + _vm._s(_vm.column))
        ])
      ]),
      _c(
        "draggable",
        {
          staticClass: "pdf-options-list-container",
          attrs: { options: _vm.draggableOptions },
          model: {
            value: _vm.optionsList,
            callback: function($$v) {
              _vm.optionsList = $$v
            },
            expression: "optionsList"
          }
        },
        _vm._l(_vm.optionsList, function(option) {
          return _c(
            "div",
            {
              key: option.id,
              staticClass: "card-wrapper",
              on: {
                dragstart: function($event) {
                  return _vm.optionDrag(option)
                },
                dragend: function($event) {
                  return _vm.optionDrag()
                }
              }
            },
            [_c("PdfOptionCard", { attrs: { option: option } })],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }