<template>
  <b-modal @show="dialogAboutToAppear" no-close-on-backdrop no-close-on-esc @shown="$refs.notesTextArea.focus()" @hide="dialogAboutToDisappear" title="Job Notes" id="notesModal"
    header-bg-variant='primary'
    header-text-variant='light'
    size="lg">
    <div class="row">
      <div class="form-group col-12">
        <div v-for="(note, index) in notes" :key="index">
          <p v-bind:style="note.getUserStyleForNote(users)">
            <span v-if="note.user_id === $store.state.user.id"><font-awesome-icon @click="deleteNote(index, note)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;&nbsp;</span><b>{{note.getUserNameForNote(users)}}</b>: {{ note.note_text }}&nbsp;&nbsp;&nbsp;<span class="noteDate">{{ note.note_date }}</span></p>
        </div>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <textarea ref="notesTextArea" @keydown.enter.exact="textareaEnterKeyPressed($event)" class="form-control form-control-sm" v-model="noteText"></textarea>
      </div>
    </div>
    <div slot="modal-footer" class='w-100'>
      <div class='float-right'>
        <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="closeModal()">Close</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faMinusCircle from '@fortawesome/fontawesome-free-solid/faMinusCircle'
import Note from '@/scripts/objects/note'

export default {
  props: ['notes', 'users', 'job_id', 'shop_id'],
  data () {
    return {
      noteText: ''
    }
  },
  methods: {
    dialogAboutToAppear: function () {
      this.noteText = ''
    },
    dialogAboutToDisappear: function () {
      let self = this
      if (this.noteText && this.noteText.trim().length > 0) {
        var note = Note.createNewNote(this.$store.state.user, this.job_id, this.noteText, this.notes)
        if (this.job_id && this.job_id > 0) {
          note.createUpdateNote(this.shop_id, this.job_id, function (newNote) {
            self.$root.$emit('addNewNote', newNote)
          }, function (error) {
            
          })
        } else {
          this.$root.$emit('addNewNote', note)
        }
      }
    },
    textareaEnterKeyPressed: function (event) {
      event.preventDefault()
      this.closeModal()
    },
    closeModal: function (event) {
      this.$root.$emit('bv::hide::modal', 'notesModal')
    },
    deleteNote (noteIndex, note) {
      let self = this
      if (noteIndex >= 0) {
        if (this.job_id && this.job_id > 0) {
          if (confirm('Are you sure you want to PERMANENTLY delete this note?')) {
            note.deleteNote(this.shop_id, function () {
              self.$root.$emit('deleteNote', noteIndex)
            }, function (error) {
              
            })
          }
        } else {
          this.$root.$emit('deleteNote', noteIndex)
        }
      }
    }
  },
  computed: {
    faMinusCircle () {
      return faMinusCircle
    }
  },
  components: {
    FontAwesomeIcon
  }
}
</script>

<style>

</style>
