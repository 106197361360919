var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "User Audit Log",
            visible: _vm.isVisible,
            width: "60%",
            modalAppendToBody: false
          },
          on: {
            "update:visible": function($event) {
              _vm.isVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "row mb-2" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "Select",
                      filterable: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.selectedUser,
                      callback: function($$v) {
                        _vm.selectedUser = $$v
                      },
                      expression: "selectedUser"
                    }
                  },
                  _vm._l(_vm.users, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.username, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-md-4" }),
            _c("div", { staticClass: "col-md-4" })
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.handleScroll,
                  expression: "handleScroll"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass:
                "infinite-list el-table--fluid-height el-table--scrollable-y userAuditTable",
              staticStyle: { overflow: "auto", height: "500px", width: "100%" },
              attrs: {
                "infinite-scroll-disabled": _vm.done,
                "infinite-scroll-distance": 1000,
                data: _vm.userLogs
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "created", label: "Date/Time", "min-width": 20 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.humanDateTimeSeconds(scope.row._date)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "job_id", label: "Job", "min-width": 10 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.job_id
                          ? _c("div", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/jobs/" + scope.row.job_id,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.job_id))]
                              )
                            ])
                          : _c("div")
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "description",
                  label: "Description",
                  "min-width": 60
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.event_id && scope.row.event_id === 55
                          ? _c("div", [
                              _vm._v(" " + _vm._s(scope.row.username) + " "),
                              _c("span", { staticClass: "eventChip login" }, [
                                _vm._v("logged in")
                              ])
                            ])
                          : scope.row.event_id && scope.row.event_id === 57
                          ? _c("div", [
                              _vm._v(" " + _vm._s(scope.row.username) + " "),
                              _c("span", { staticClass: "eventChip logout" }, [
                                _vm._v("logged out")
                              ])
                            ])
                          : scope.row.event_id && scope.row.event_id === 56
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(scope.row.username) + " was "
                              ),
                              _c("span", { staticClass: "eventChip bump" }, [
                                _vm._v("bumped out")
                              ])
                            ])
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "95%",
                                  "word-break": "keep-all"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(scope.row.description) + " "
                                )
                              ]
                            )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "browser",
                  label: "Browser/Device",
                  "min-width": 20
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.browser &&
                        scope.row.browser.includes("Chrome")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Chrome",
                                  expression: "'Chrome'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-chrome fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser &&
                        scope.row.browser.includes("Safari")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Safari",
                                  expression: "'Safari'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-safari fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser &&
                        scope.row.browser.includes("Firefox")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Firefox",
                                  expression: "'Firefox'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-firefox fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser && scope.row.browser.includes("Opera")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Opera",
                                  expression: "'Opera'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-opera fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser && scope.row.browser.includes("Edge")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Edge",
                                  expression: "'Edge'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-edge fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser &&
                        scope.row.browser.includes("Samsung")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Samsung Browser",
                                  expression: "'Samsung Browser'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-globe fa-2x"
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.browser &&
                        scope.row.browser.includes("Windows")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Windows",
                                  expression: "'Windows'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-windows fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser &&
                        scope.row.browser.includes("Android")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Android",
                                  expression: "'Android'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-android fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser &&
                        (scope.row.browser.includes("Apple") ||
                          scope.row.browser.includes("Macintosh"))
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Apple",
                                  expression: "'Apple'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-apple fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser && scope.row.browser.includes("Linux")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Linux",
                                  expression: "'Linux'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-linux fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser && scope.row.browser.includes("cBook")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Chromebook",
                                  expression: "'Chromebook'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-laptop fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser &&
                        scope.row.browser.includes("iPhone")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "iPhone",
                                  expression: "'iPhone'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-mobile fa-2x"
                            })
                          : _vm._e(),
                        scope.row.browser && scope.row.browser.includes("iPad")
                          ? _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "iPad",
                                  expression: "'iPad'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "fa fa-tablet fa-2x"
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "user_ip",
                  label: "IP Address",
                  "min-width": 30
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.user_ip !== null
                                ? scope.row.user_ip
                                : ""
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }