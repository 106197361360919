var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "shop-rates-dialog",
      attrs: {
        visible: _vm.visible,
        title:
          _vm.currentRates && _vm.currentRates.name
            ? _vm.currentRates.name
            : "New Nags Pricing",
        width: "550px",
        "modal-append-to-body": true,
        "append-to-body": ""
      },
      on: {
        close: function($event) {
          _vm.visible = false
        },
        open: function($event) {
          return _vm.setCurrentRate()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "shop-rates" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c("input-with-label", {
                    attrs: {
                      label: "Name",
                      size: "medium",
                      error: _vm.showNameError ? "Name is required" : null
                    },
                    model: {
                      value: _vm.currentRates.name,
                      callback: function($$v) {
                        _vm.$set(_vm.currentRates, "name", $$v)
                      },
                      expression: "currentRates.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "Shop Default",
                      value: _vm.current_rate_is_shop_default
                    },
                    on: {
                      input: function($event) {
                        _vm.current_rate_is_shop_default = +$event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("rates-form", {
            model: {
              value: _vm.currentRates,
              callback: function($$v) {
                _vm.currentRates = $$v
              },
              expression: "currentRates"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticClass: "shop-rates-dialog-footer-button-holder-right",
                  attrs: { id: "right" }
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.visible = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.saving,
                        disabled: !_vm.currentRates.name
                      },
                      on: {
                        click: function($event) {
                          return _vm.save(false)
                        }
                      }
                    },
                    [_vm._v(" Save ")]
                  ),
                  !_vm.autoApply
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.savingAndApplying,
                            disabled: !_vm.currentRates.name
                          },
                          on: {
                            click: function($event) {
                              return _vm.save(true)
                            }
                          }
                        },
                        [_vm._v(" Save and apply ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }