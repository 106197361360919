<template>
  <div class="autocomplete" style="position:relative" v-bind:class="{'open':openSuggestion}">
    <input
      v-if='!showSpinner'
      :class="classes"
      type="text"
      :placeholder="placeholder"
      :disabled="inDisabled"
      :style="inStyle"
      :ref="inInputRef"
      v-model="internalSearchValue"
      @keydown.enter = 'enter'
      @keydown.down = 'down'
      @keydown.up = 'up'
      @keydown.tab= 'tab'
      @keydown.delete = 'deleteKeyPressed'
      @focus = 'focused'
      @blur="blurred"
      :readonly='readonly'
      >
      <InputWithSpinner
        v-if='showSpinner'
        :inClass="inClass"
        type="text"
        :placeholder="placeholder"
        :disabled="inDisabled"
        :style="inStyle"
        :ref="inInputRef"
        :searchValue.sync="internalSearchValue"
        @enter = 'enter'
        @down = 'down'
        @up = 'up'
        @tab = 'tab'
        @delete = 'deleteKeyPressed'
        @focus = 'focused'
        :loading="loading"
      />
      <div :ref="'autocompleteResults'" v-show="open && matches.length > 0" class="autocomplete-results" :style="{'max-height': autoCompleteHeight + 'px', 'margin-bottom': '15px'}">
        <ul class="" style="width:100%">
            <template v-for="(suggestion, index) in matches">
                <hr v-if="suggestion['breakBefore']" :key="index + '-spacerBefore'"/>
                <li :key='index'
                    :ref="'autoComplete:' + index"
                    class="autocomplete-result"
                    tabindex="-1"
                    :style="suggestion['style'] ? suggestion['style'] : ''"
                    v-on:mouseover="mouseOver(index)"
                    v-bind:class="{'is-active': isActive(index)}"
                    @click="suggestionClick(index)">
                    <slot name="autocompleteItem" :suggestion="suggestion" :index="index" :active="isActive(index)">
                      <a href="javascript:void(0)" v-bind:class="{'white-text': isActive(index)}">{{ suggestion['name'] }}</a>
                    </slot>
                </li>
                <hr v-if="suggestion['breakAfter']" :key="index + '-spacerAfter'"/>
            </template>
        </ul>
      </div>
  </div>
</template>

<script>
import InputWithSpinner from '@/components/Helpers/InputWithSpinner.vue'

export default {
  props: {
    selection: {
      type: Object,
      required: false
    },
    dataSet: {
      type: Array,
      required: true
    },
    smallInput: {
      type: Boolean,
      required: false
    },
    inDisabled: {
      type: Boolean,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    searchValue: {
      type: String,
      required: false
    },
    filterInternally: {
      type: Boolean,
      required: false,
      default: true
    },
    autoCompleteHeight: {
      type: Number,
      required: false
    },
    inClass: {
      type: String,
      required: false
    },
    inStyle: {
      type: String,
      required: false
    },
    startingPosition: {
      type: Number,
      required: false
    },
    autoSelectOnTab: {
      type: Boolean,
      required: false,
      default: true
    },
    inId: {
      type: String,
      required: false
    },
    inInputRef: {
      type: String,
      required: false
    },
    inLoading: {
      type: Boolean,
      required: false
    },
    showSpinner: {
      type: Boolean,
      required: false,
      default: false
    },
    isPhone: {
      type: Boolean,
      required: false,
      default: false
    },
    isInModal: {
      type: Boolean,
      required: false,
      default: false
    },
    closeOnClick: {
      type: Boolean,
      required: false
    },
    selectedArrayIndexToReturn: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      value: '',
      open: false,
      current: 0,
      readonly: true
    }
  },
  computed: {
    loading: function () {
      return this.inLoading || false
    },
    internalSearchValue: {
      get () {
        if (this.isPhone) {
          if (this.searchValue) {
            // this is if the user is in the input field... just return the non formatted
            if (this.open) {
              let temp = ('' + this.searchValue).replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})$/)
              temp = (!temp[2]) ? temp[1] : '(' + temp[1] + ') ' + temp[2] + (temp[3] ? '-' + temp[3] : '')
              if (temp.length === 0 && this.searchValue.length > 0) {
                this.$emit('update:value', '')
              }
              return temp
            } else { // this is if the user is not in the input field and it should be formatted
              if (this.searchValue.toString().length > 0) {
                let temp = ('' + this.searchValue).replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})$/)
                return (!temp[2]) ? temp[1] : '(' + temp[1] + ') ' + temp[2] + (temp[3] ? '-' + temp[3] : '')
              }
            }
          } else {
            return ''
          }
        } else {
          return this.searchValue
        }
        return ''
      },
      set (val) {
        if (this.open === false) {
          this.open = true
          this.current = this.startingPosition ? this.startingPosition : 0
        }

        this.$emit('update:searchValue', val)
        if (this.selectedArrayIndexToReturn !== null && this.selectedArrayIndexToReturn >= 0) {
          this.$emit('searchValueChanged', val, this.selectedArrayIndexToReturn) // this one is for autocompletes inside of lists, the index of the particular autocomplete is returned to be able to reference
        }
      }
    },
    matches () {
      if (this.filterInternally) {
        return this.dataSet.filter(obj => {
          return obj['name'].toLowerCase().indexOf(this.internalSearchValue.toLowerCase()) >= 0
        })
      } else {
        return this.dataSet
      }
    },
    classes () {
      var props = ['form-control', 'autocomplete-off']
      if (this.smallInput) {
        props.push('form-control-sm')
      }
      if (this.inClass && this.inClass.length > 0) {
        var classes = this.inClass.split(',')
        for (var i = 0; i < classes.length; i++) {
          if (classes[i] && classes[i].trim().length > 0) {
            props.push(classes[i].trim())
          }
        }
      }
      return props
    },
    openSuggestion () {
      return this.selection !== '' && this.matches.length !== 0 && this.open === true
    }
  },
  methods: {
    revealAutocomplete () {
      let self = this
      setTimeout(function () {
        self.open = true
        self.current = this.startingPosition ? this.startingPosition : 0
      }, 100)
    },
    enter () {
      if (this.current >= 0 && this.matches && this.matches.length >= this.current) {
        if (this.selectedArrayIndexToReturn != null && this.selectedArrayIndexToReturn >= 0) {
          this.$emit('selected', this.matches[this.current]['value'], this.selectedArrayIndexToReturn)
        } else {
          this.$emit('selected', this.matches[this.current]['value'])
        }
      }
      this.open = false
    },
    mouseOver: function (index) {
      this.current = index
    },
    up () {
      if (this.current > -1) {
        this.current--
      }
      try {
        this.$refs['autocompleteResults'].scrollTop = (this.$refs['autoComplete:' + this.current][0].offsetTop - (this.$refs['autocompleteResults'].clientHeight - (this.$refs['autoComplete:' + this.current][0].clientHeight * 2)))
      } catch (err) {}
    },
    deleteKeyPressed () {
      this.$emit('deleteKeyPressed')
    },
    focused () {
      if (this.filterInternally) {
        if (this.open === false && this.internalSearchValue.length > 0) {
          this.open = true
          this.current = this.startingPosition ? this.startingPosition : 0
        }
      } else {
        if (this.open === false) {
          this.open = true
          this.current = this.startingPosition ? this.startingPosition : 0
        }
      }
      this.$emit('autoCompleteFocused')
    },
    blurred () {
      if (this.isInModal) {
        var self = this
        setTimeout(function () {
          self.open = false
        }, 200)
      }
    },
    down () {
      if (this.current < this.matches.length - 1) {
        this.current++
      }
      try {
        this.$refs['autocompleteResults'].scrollTop = (this.$refs['autoComplete:' + this.current][0].offsetTop - this.$refs['autoComplete:' + this.current][0].clientHeight)
      } catch (err) {
      }
    },
    tab () {
      if (this.current >= 0 && this.autoSelectOnTab) {
        if (this.selectedArrayIndexToReturn != null && this.selectedArrayIndexToReturn >= 0) {
          this.$emit('selected', this.matches[this.current]['value'], this.selectedArrayIndexToReturn)
        } else {
          this.$emit('selected', this.matches[this.current]['value'])
        }
      }
      this.open = false
    },
    tabUp () {
      this.$emit('tab')
    },
    isActive (index) {
      return index === this.current
    },
    suggestionClick (index) {
      if (this.selectedArrayIndexToReturn != null && this.selectedArrayIndexToReturn >= 0) {
        this.$emit('selected', this.matches[index]['value'], this.selectedArrayIndexToReturn)
      } else {
        this.$emit('selected', this.matches[index]['value'])
      }
      if (this.closeOnClick) {
        this.open = false
      }
      // setTimeout(function() {
      //   this.open = false
      // }, 6000)
    },
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.open = false
      }
    }
  },
  mounted () {
    var self = this
    this.$nextTick(() => { self.readonly = false })
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  components: {
    InputWithSpinner
  }
}
</script>

<style scoped>
  .autocomplete {
    position: relative;
    width: 100%;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    max-height: 120px;
    overflow: auto;
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .autocomplete-results ul {
    padding: 4px;
    margin-bottom: 0px;

  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding-bottom: 4px;
    cursor: pointer;
  }

  .autocomplete-result.is-active {
    background-color: dodgerblue !important;
    color: white !important;
  }

  .white-text {
    color: white;
  }
</style>
