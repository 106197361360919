<template>
  <b-modal no-close-on-backdrop no-close-on-esc hide-footer title="Part Search"
    :id="modalId && modalId.length > 0 ? modalId : 'singlePartSearchModal'"
    @hide="dialogAboutToDisappear"
    header-bg-variant='primary'
    header-text-variant='light'
    size="lg">
    <PartSearch :isSinglePartSearchModal="true" :shop='shop' :startInputValue='startInputValue'/>
  </b-modal>
</template>

<script>
import PartSearch from '@/components/PartSearch/Index.vue'

export default {
  props: ['reopenDialogId', 'modalId', 'shop', 'startInputValue'],
  methods: {
    dialogAboutToDisappear: function () {
      this.$emit('closingDialog')
      if (this.reopenDialogId && this.reopenDialogId.length > 0) {
        setTimeout(() => {
          this.$root.$emit('bv::show::modal', this.reopenDialogId)
        }, 100)
      }
    }
  },
  components: {
    PartSearch
  }
}
</script>

<style>
</style>
