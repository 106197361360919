<template>
  <b-modal @show="dialogAboutToAppear" no-close-on-backdrop no-close-on-esc
  @shown="$refs.nameComponent.focus()" v-bind:title="csr.id > 0 ? 'Edit CSR' : 'New CSR'" id="csrModal"
    header-bg-variant='primary'
    header-text-variant='light'
    size="lg">
    <div class="row">
      <div class="form-group col-6">
        <label>Status</label>
        <select v-model='csr.status' class="form-control form-control-sm">
          <option value="active">Active</option>
          <option value="disabled">Disabled</option>
        </select>
      </div>
      <div v-if="csr.id > 0" class="form-group col-6">
        <label></label>
        <p>ID: <span class="idBox">{{csr.id}}</span></p>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-6">
        <RequiredInput ref="nameComponent" :title="'Name'" :value.sync='csr.name' :placeholder="''" :capitalizeAll='true' />
      </div>
      <div v-if='checkCommissionPermission' class="form-group col-3">
        <label>Commission</label>
        <FloatDropdown ref="commissionComponent" :dropdownOptions="[{'value':'flat', 'label':'$', 'dropdownLabel':'Flat'}, {'value':'percent', 'label':'%', 'dropdownLabel':'%'}]" :inDisabled="false" :dropdownValue.sync="csr.commission_flat_or_percent" :value.sync="csr.commission"/>
      </div>
      <div v-if='checkCommissionPermission' class="form-group col-3">
        <label>Chip Commission</label>
        <FloatDropdown ref="chipCommissionComponent" :dropdownOptions="[{'value':'flat', 'label':'$', 'dropdownLabel':'Flat'}, {'value':'percent', 'label':'%', 'dropdownLabel':'%'}]" :inDisabled="false" :dropdownValue.sync="csr.chip_commission_flat_or_percent" :value.sync="csr.chip_commission"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-3">
        <Phone ref="phoneComponent" :title="'Phone'" :value.sync='csr.phone' :placeholder="''" :required='false' />
      </div>
      <div class="form-group col-md-3">
        <Phone ref="faxComponent" :title="'Fax'" :value.sync='csr.fax' :placeholder="''" :required='false' />
      </div>
      <div class="form-group col-md-6">
        <Email ref="emailComponent" :title="'Email'" :value.sync='csr.email' :placeholder="''" :required='false' />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label>Address</label>
        <input type="text" class="form-control form-control-sm autocomplete-off" v-model='csr.address'/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-3">
        <label>ZIP</label>
        <input type="text" class="form-control form-control-sm autocomplete-off" v-model='csr.zip'/>
      </div>
      <div class="form-group col-md-6">
        <label>City</label>
        <input type="text" class="form-control form-control-sm autocomplete-off" v-model='csr.city'/>
      </div>
      <div class="form-group col-md-3">
        <label>State</label>
        <select id="#" class="form-control form-control-sm" v-model='csr.state'>
          <option value="">Choose...</option>
          <option v-for="state in states" :key="state.value" v-bind:value="state.value">{{ state.text }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label>Notes</label>
        <textarea class="form-control form-control-sm" v-model='csr.notes'></textarea>
      </div>
    </div>
    <div slot="modal-footer" class='w-100'>
      <div class='float-right'>
        <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="saveCSR">Save</button>
        <button type='button' class='btn btn-secondary' variant="primary" @click="cancelEdits">Cancel</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import CSR from '@/scripts/objects/csr'
import Phone from '@/components/Helpers/Phone.vue'
import RequiredInput from '@/components/Helpers/RequiredInput.vue'
import Email from '@/components/Helpers/Email.vue'
import { ValidateComponents, ResetValidateComponents, StateObjects } from '@/scripts/helpers/helpers.js'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import FloatDropdown from '@/components/Helpers/FloatDropdown.vue'

export default {
  props: ['parentCSR', 'shopId'],
  data () {
    return {
      states: StateObjects,
      csr: {},
      validateInputs: []
    }
  },
  mounted: function () {
    this.validateInputs = [this.$refs.nameComponent, this.$refs.commissionComponent,
    this.$refs.chipCommissionComponent, this.$refs.phoneComponent, this.$refs.faxComponent, 
    this.$refs.emailComponent]
  },
  methods: {
    dialogAboutToAppear: function () {
      this.csr = new CSR(this.parentCSR)
      ResetValidateComponents(this.validateInputs)
    },
    saveCSR: function (event) {
      if (ValidateComponents(this.validateInputs)) {
        let self = this
        this.csr.createUpdateCSR(this.shopId, function (object) {
          var skipSet = false
          if (self.csr.objectTrackerInitial.status === 'active' && object.status === 'disabled') {
            skipSet = true
          }
          self.csr = new CSR(object)
          self.$root.$emit('updateSelectCSR', self.csr, skipSet)
          self.$root.$emit('bv::hide::modal', 'csrModal')
        }, function (error) {
          
        })
      }
    },
    cancelEdits: function (event) {
      event.preventDefault()
      this.$root.$emit('bv::hide::modal', 'csrModal')
    }
  },
  watch: {
    'parentCSR': function (val) {
      this.csr = new CSR(this.parentCSR)
    },
    'csr.zip': function (val) {
      let self = this
      
      this.zipLookup(val, function (data) {
        
        self.csr.city = data.city
        self.csr.state = data.state_short
      })
    }
  },
  components: {
    Phone,
    FloatDropdown,
    RequiredInput,
    Email
  },
  mixins: [UtilityMixin]
}
</script>

<style>
.idBox {
  background-color: #EDC9AF;
  padding: 5px;
  border-radius: 3px;
}
</style>
