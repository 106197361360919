<template>
  <div
    class="t-mb-3 t-border t-border-solid t-border-gray-300 t-rounded-md
      t-shadow-sm t-flex"
  >
    <div class="t-flex-grow t-px-4 t-py-2">
      <div class="t-text-base t-font-medium">
        Order # {{ order.orderNumber }}
      </div>
      <div
        v-if="order.purchaseOrderId"
        @click="openPurchaseOrder(order.purchaseOrderId)"
        class="t-flex t-mt-1"
      >
        <span
          class="t-text-white t-px-2 t-rounded-md t-cursor-pointer"
          :style="
            supplierColor
              ? `background-color: ${supplierColor}`
              : 'background-color: #000000'
          "
        >
          PO {{ order.purchaseOrderInternalId }}
        </span>
      </div>
      <div class="t-my-2">
        <a
          @click="openOrderDetails(order)"
          class="t-bg-white t-border t-border-solid t-border-blue-400
            t-rounded-md t-px-2 t-py-1 t-cursor-pointer t-text-blue-500
            hover:t-bg-blue-500 hover:t-border-blue-500
            hover:t-text-white t-text-xs"
        >
          Show details
        </a>
      </div>
    </div>
    <div class="t-flex t-flex-col t-py-2 t-w-40">
      <div>
        <i class="el-icon-user t-mr-1 t-text-blue-600"></i>
        <span class="t-text-base">
          {{ order.username }}
        </span>
      </div>
      <div>
        <i class="el-icon-time t-mr-1 t-text-blue-600"></i>
        <span>{{ formatTimestamp(order.createdAt) }}</span>
      </div>
    </div>
    <div class="t-p-2 t-w-56 t-break-normal t-leading-6">
      <div class="t-flex t-items-baseline">
        <div class="t-w-16 t-text-xs t-text-gray-500">Method</div>
        <div>
          {{ order.shipViaCode === 'DEL' ? 'Delivery' : 'Will Call' }}
        </div>
      </div>
      <div v-if="order.shipViaCode === 'DEL'">
        <div class="t-flex t-items-baseline">
          <div class="t-w-16 t-text-xs t-text-gray-500">Ship date</div>
          <div class="t-truncate">{{ formatShipDate(order.shipDate) }}</div>
        </div>
        <div class="t-flex t-items-baseline">
          <div class="t-w-16 t-text-xs t-text-gray-500">Ship adr</div>
          <div class="t-truncate">{{ order.shipToAddress }}</div>
        </div>
      </div>
    </div>
    <div
      class="t-font-bold t-px-3 t-bg-gray-100 t-border-solid t-border-0
        t-border-l-8 t-border-gray-300 t-rounded-r-md t-self-stretch t-flex
        t-items-center"
    >
      <div class="t-w-24 t-flex-1 t-text-lg t-text-center">
        ${{ roundPrice(order.orderTotal) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  shoppingCartActions,
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'

export default {
  props: ['order', 'supplierColor'],

  computed: {
    orderDetailsIsOpen: {
      get() {
        return this.$store.state.shoppingCart.orders.americanOrderDetailsIsOpen
      },
      set(value) {
        return this.$store.commit(
          shoppingCartMutations.orders.setAmericanOrderDetailsIsOpen,
          value
        )
      }
    },
    orderDetails: {
      get() {
        return this.$store.state.shoppingCart.orders.orderDetails
      },
      set(value) {
        return this.$store.commit(
          shoppingCartMutations.orders.setOrderDetails,
          value
        )
      }
    }
  },

  methods: {
    getOrderItems(payload) {
      return this.$store.dispatch(shoppingCartActions.getOrderItems, payload)
    },
    formatTimestamp(dt) {
      return moment(dt).calendar()
    },
    formatShipDate(dt) {
      return moment(dt).format('MMM Do')
    },
    roundPrice(price) {
      return Math.round(price * 100) / 100
    },
    openOrderDetails(order) {
      this.getOrderItems({
        orderId: order.id,
        vendor: 'american'
      })
      this.orderDetails = order
      this.orderDetailsIsOpen = true
    },
    async openPurchaseOrder(poId) {
      this.$store.dispatch('warehouse/openPurchaseOrderDialog', poId)
    }
  }
}
</script>
