import { getHumanDate, dateToHumanTime } from './date.helpers'
import * as R from 'ramda'
import TechActivityMapMarker from '@/components/TechsideActivity/helpers/TechActivityMapMarker'
import Vue from 'vue'
import { activityIcons } from '@/constants/activity.config'

const TechActivityMapMarkerCtr = Vue.extend(TechActivityMapMarker)

export const getUserSessionString = ({ last_clock_in, last_clock_out }) =>
  last_clock_in &&
  `${getHumanDate(last_clock_in)} ${dateToHumanTime(
    last_clock_in
  )} - ${dateToHumanTime(last_clock_out)}`

const isLocation = R.propEq('activityType', 'location')

export const getPolylines = R.pipe(
  R.prop('data'),
  R.filter(isLocation)
)

export const getMarkers = R.pipe(
  R.prop('data'),
  R.filter(
    R.pipe(
      isLocation,
      R.not
    )
  )
)

export const getIconUrl = (type, active) => {
  const iconComponent = new TechActivityMapMarkerCtr({
    propsData: {
      fillColor: active ? '#1565C0' : '#00C183',
      path: R.path([type, 'path'])(activityIcons)
    }
  })
  iconComponent.$mount()
  const iconString = new XMLSerializer().serializeToString(iconComponent.$el)
  return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString)
}

export const getMarkerOptions = (marker, active) => {
  const a = {
    position: { lat: marker.lat, lng: marker.lng },
    icon: {
      url: getIconUrl(marker.activityTypeId, active),
      anchor: {
        x: 17,
        y: 40
      }
    },
    zIndex: active ? 12 : 11
  }
  return a
}
