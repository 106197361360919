<template>
  <div>
    <el-form
      ref="formRef"
      :model="form"
      :rules="formRules"
      class="d-flex settings-general-form"
    >
      <div class="d-flex flex-wrap align-items-center settings-general-content">

        <!-- DESCRIPTION -->
        <div class="d-flex align-items-start flex-column section-title">
          <div class="title-container"><span class="title">Main Settings</span></div>
          <div><span class="description text-break">Phone Number and Email to use for all events.</span></div>
        </div>

        <div class="d-flex flex-wrap align-items-center settings-general-form">
          <el-form-item prop="phone">
            <el-input
              v-model="form.phone"
              size="small"
              placeholder="Add Phone"
              @input="formatPhone"
              ref="phone"
              class="phone"
            />
          </el-form-item>

          <el-form-item prop="email">
            <el-input
              v-model="form.email"
              size="small"
              placeholder="Add Email"
            />
          </el-form-item>
        </div>

      </div>

    </el-form>
  </div>
</template>

<script>
import {
  formatPhonePartial,
  validateEmail,
  validatePhone
} from '@/store/modules/notifications-settings/helpers';

import { mapState, mapActions } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: cloneDeep(this.data),
    }
  },

  mounted () {
    // run phone formatting on init
    this.formatPhone(this.form.phone);
  },

  watch: {
    form: {
      handler(settings) {
        const formEl = this.$refs.formRef;

        if (!formEl) {
          return;
        }

        formEl.validate((valid, fields) => {
          if (!valid) {
            return;
          }
          this.changeSettings(cloneDeep(settings));
        });
      },

      deep: true
    }
  },

  computed: {
    ...mapState('notificationsSettings', [ 'settings' ]),

    formRules() {
      return {
        phone: [
          {
            validator: validatePhone,
            trigger: 'blur',
            message: 'Incorrect phone format'
          }
        ],
        email: [
          {
            validator: validateEmail,
            trigger: 'blur',
            message: 'Incorrect e-mail format',
          }
        ]
      }
    }
  },

  methods: {
    ...mapActions('notificationsSettings', ['changeSettings']),

    formatPhone (value) {
      setTimeout(
        () => {
          this.$refs.phone.$el.querySelector('input').value = formatPhonePartial(value);
        }, 0
      );
    },
  }
}
</script>

<style lang="scss" scoped>
.settings-general-content {
  column-gap: 32px;
  row-gap: 16px;
  padding: 16px;

  .section-title {
    width: 250px;
    gap: 16px;
    color: black;

    .title-container {
      height: 34px;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.settings-general-form {
  gap: 16px;

  .el-input {
    width: 240px;
    font-size: 14px;
  }
}

.el-form-item {
  margin-bottom: 0px;

  .el-form-item__label {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #9CA3AF;
    margin-bottom: 6px;
  }
  .el-form-item__content {
    line-height: 32px;

    .el-input__inner {
      border: 1px solid #D1D5DB;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      height: 40px;
    }
  }
}
</style>