import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-ui'
import cfg from '@/config'

export const baseApiUrl = `${cfg.uri}/unum/notifications`

const NotificationsAPI = axios.create({
  baseURL: baseApiUrl
})

NotificationsAPI.interceptors.request.use(async request => {
  const { $auth } = Vue.prototype
  // check if handler is enabled
  if (request.hasOwnProperty('handlerEnabled') && !request.handlerEnabled) {
    return request
  }

  // check auth
  if (!$auth || $auth.skipAuth) {
    return request
  }

  // add auth token
  const token = await $auth.getTokenSilently()
  request.headers.Authorization = `Bearer ${token}`

  return request
})

NotificationsAPI.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (axios.isCancel(error)) {
      return
    }

    Message.error(error.response?.data?.error)
    return Promise.reject(error)
  }
)

export default NotificationsAPI
