<template>
  <div
    class="t-px-5 t-py-1.5 t-cursor-pointer t-text-gray-400 hover:t-bg-[#F6F9FC] t-flex t-items-center t-justify-between"
    :class="{
      't-bg-[#F6F9FC] t-text-blue-800 t-border-0 t-border-solid t-border-l-4 t-border-blue-600': active
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true
    }
  }
}
</script>
