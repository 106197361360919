import * as R from 'ramda'
import marker from 'vue2-google-maps/dist/components/marker'
import { mergeSpec } from './ramda.helpers'
/* global google */

export const getSameRouteMarkers = (marker, markers) => {
  console.log(marker)
  R.pipe(R.values,
    R.pluck('route_entries'),
    R.unnest,
    R.map(
      R.applySpec({
        id: R.prop('id'),
        latLng: R.pipe(R.path(['job', 'install_g_address']), R.pick(['lat', 'lng']))
      })
    )
  )(markers)
}
export const findClosestMarker = (marker, latLng, markers) => {
  const v = R.pipe(

    R.map(mergeSpec({
      distance:
        v => google.maps.geometry.spherical.computeDistanceBetween(v.latLng, latLng)
    })),
    R.sortBy(R.prop('distance')),
    R.head
  )(markers)
  if (v.distance < 4000) {
    return v
  }
}

export const swapMarkersInRoute = (id1, id2, route) => {
  const marker1 = route.route_entries.find(R.propEq('id', id1))
  const marker2 = route.route_entries.find(R.propEq('id', id2))
  const route_entries = route.route_entries.map(
    v => {
      if (v.id === id1) {
        return { ...marker1, order: marker2.order }
      } else if (v.id === id2) {
        return { ...marker2, order: marker1.order }
      }
    }
  )
  return { [route.id]: { route_entries } }
}
