import { render, staticRenderFns } from "./VendorsTabs.vue?vue&type=template&id=03ce9d43&"
import script from "./VendorsTabs.vue?vue&type=script&lang=js&"
export * from "./VendorsTabs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03ce9d43')) {
      api.createRecord('03ce9d43', component.options)
    } else {
      api.reload('03ce9d43', component.options)
    }
    module.hot.accept("./VendorsTabs.vue?vue&type=template&id=03ce9d43&", function () {
      api.rerender('03ce9d43', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ShoppingCart/Items/VendorsTabs.vue"
export default component.exports