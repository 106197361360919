<template>
  <div class="t-flex">
    <el-table
      v-loading="fetching"
      :data="notInGlassBiller"
      style="width: 100%">
      <el-table-column
        prop="DocNumber"
        label="Invoice #">
      </el-table-column>
      <el-table-column
        prop="TxnDate"
        label="Invoice Date">
      </el-table-column>
      <el-table-column
        :formatter="formatBalance"
        prop="TotalAmt"
        label="Total (QBO)">
      </el-table-column>
      <el-table-column
        :formatter="formatBalance"
        prop="Balance"
        label="Balance (QBO)">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useQboSyncStore } from '@/components/modals/QuickBooksSyncDialog/qboSync.store.js'

export default {
  computed: {
    ...mapState(useQboSyncStore, ['notInGlassBiller', 'fetching'])
  },
  methods: {
    formatBalance (row, column, cellValue, index) {
      return cellValue === '-' ? cellValue : `$${cellValue}`
    }
  }
}
</script>

<style lang="scss">
</style>
