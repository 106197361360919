var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c("el-form-item", { staticClass: "mb-0" }, [
        _c(
          "span",
          {
            staticClass: "undo-label-styles right-part-for-label",
            attrs: { slot: "label" },
            slot: "label"
          },
          [_vm._v(_vm._s(_vm.name) + ":")]
        ),
        _c("span", [_vm._v(_vm._s(_vm.value))])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }