import Api from '@/services/Api'

export default class RightPart {
  static async getGlassTypes () {
    const { data } = await Api.get('/api/rightParts/glassTypes')
    return data
  }

  static async getGlassOptions () {
    const { data } = await Api.get('/api/rightParts/glassOptions')
    return data
  }

  static async search (payload) {
    console.log('here as well')
    const { data } = await Api.post('/api/right-parts/decode', payload)
    return data
  }

  static async getDetails (params) {
    const { data } = await Api.get(
      '/api/right-parts/last-result',
      { params: {
          [params['field']]: params['value']
        } 
      }
    )
    return data
  }

  static async getRequestDetails (id) {
    const { data } = await Api.get(
      `/api/right-parts/requests/${id}`
    )
    return data
  }

  static async unlinkDraftJob (jobDraftId) {
    await Api.post(`/api/right-parts/requests/unlink-job-draft`, {
      jobDraftId 
    })
  }

  static async publishDraftJob ({ jobDraftId, jobId }) {
    await Api.post('/api/right-parts/requests/publish-job-draft', { jobDraftId, jobId })
  }
}
