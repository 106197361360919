var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gb-card collapse-parent",
      class: { "gb-card-collapsed": _vm.collapsed }
    },
    [
      _c("div", { staticClass: "card-content-wrapper" }, [
        _c(
          "div",
          { staticClass: "card-content" },
          [
            !_vm.hideHeader
              ? _c(
                  "div",
                  { staticClass: "header" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "card-name",
                        on: {
                          click: function($event) {
                            _vm.collapsed = !_vm.collapsed
                          }
                        }
                      },
                      [
                        _vm.mdi
                          ? _c("i", {
                              class: "material-icons-outline md-" + _vm.mdi
                            })
                          : _vm._e(),
                        _vm.icon
                          ? _c("v-icon", { attrs: { name: _vm.icon } })
                          : _vm._e(),
                        _c("div", [_vm._v(_vm._s(_vm.title))])
                      ],
                      1
                    ),
                    _vm.collapsed ? _vm._t("collapsed-info") : _vm._t("title"),
                    _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _vm.collapsed
                          ? _c("md-circle-button", {
                              attrs: { icon: "expand_more", size: "normal" },
                              on: {
                                click: function($event) {
                                  _vm.collapsed = !_vm.collapsed
                                }
                              }
                            })
                          : _vm._t("actions")
                      ],
                      2
                    )
                  ],
                  2
                )
              : _vm._e(),
            _c("collapse-top-transition", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.collapsed,
                      expression: "!collapsed"
                    }
                  ]
                },
                [_vm._t("default")],
                2
              )
            ]),
            _c("collapse-top-transition", [
              _vm.$slots.footer && !_vm.collapsed
                ? _c("div", { staticClass: "footer" }, [_vm._t("footer")], 2)
                : _vm._e()
            ])
          ],
          1
        ),
        !_vm.collapsed ? _c("div", [_vm._t("side-area")], 2) : _vm._e()
      ]),
      _c("collapse-top-transition", [
        !_vm.collapsed ? _c("div", [_vm._t("bottom-area")], 2) : _vm._e()
      ]),
      _vm.confirmation
        ? _c("div", { staticClass: "gb-card-confirm" }, [
            _c("div", { staticClass: "gb-card-confirm-content" }, [
              _c("div", { staticClass: "gb-card-confirm-title" }, [
                _vm._v(_vm._s(_vm.confirmation.message))
              ]),
              _c(
                "div",
                { staticClass: "gb-card-confirm-actions" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.resolve(false)
                        }
                      }
                    },
                    [_vm._v("No")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.resolve(true)
                        }
                      }
                    },
                    [_vm._v(" Yes ")]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }