var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    { attrs: { prop: "amount" } },
    [
      _c(
        "span",
        {
          staticClass: "undo-label-styles",
          attrs: { slot: "label" },
          slot: "label"
        },
        [_vm._v("Requested Amount")]
      ),
      _c(
        "el-input",
        {
          on: { blur: _vm.currencyBlur },
          model: {
            value: _vm.amount,
            callback: function($$v) {
              _vm.amount = $$v
            },
            expression: "amount"
          }
        },
        [
          _c(
            "span",
            {
              staticClass: "t-ml-2",
              attrs: { slot: "prefix" },
              slot: "prefix"
            },
            [_vm._v("$")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }