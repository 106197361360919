var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        title: "Warning",
        visible: _vm.showDialog,
        width: "30%",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c("el-col", { attrs: { span: 2 } }, [
            _c("i", { staticClass: "el-icon-warning warning-icon" })
          ]),
          _c("el-col", { attrs: { span: 22 } }, [
            _c("span", [
              _vm._v(
                "You are navigating away from an unpublished draft. Do you want to continue?"
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _vm.canDiscard
            ? _c(
                "a",
                {
                  staticClass: "discard-option mt-2",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("discardDraft")
                    }
                  }
                },
                [_vm._v("Discard")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("leaveDraft")
                }
              }
            },
            [_vm._v("Leave as Draft")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.$emit("publishDraft")
                }
              }
            },
            [_vm._v("Publish now")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }