var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "confirmation-countdown" },
    [
      _vm.jobId
        ? _c(
            "div",
            { staticClass: "wrapper" },
            [
              _c(
                "el-tag",
                {
                  attrs: { size: "small", type: "primary'" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.openConfirmationDialog($event)
                    }
                  }
                },
                [
                  _c("div", { staticStyle: { cursor: "pointer" } }, [
                    _vm._v(" " + _vm._s(_vm.display) + " ")
                  ])
                ]
              )
            ],
            1
          )
        : _vm.shopsTimers.length > 1
        ? _c(
            "div",
            { staticClass: "wrapper" },
            [
              _c(
                "el-dropdown",
                { on: { command: _vm.openSettings } },
                [
                  _c("el-tag", { attrs: { size: "small", type: "primary" } }, [
                    _c("div", { staticStyle: { cursor: "pointer" } }, [
                      _vm._v(" " + _vm._s(_vm.shopsTimers[0].display) + " ")
                    ])
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticStyle: { width: "450px" },
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("Confirmations Automatically Send:")
                      ]),
                      _vm._l(_vm.shopsTimers, function(timer) {
                        return _c(
                          "el-dropdown-item",
                          {
                            key: timer.name,
                            attrs: { command: timer.shop_id }
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 16 } }, [
                                  _vm._v(" " + _vm._s(timer.name) + " ")
                                ]),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    attrs: { span: 8 }
                                  },
                                  [_vm._v(" " + _vm._s(timer.display) + " ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.shopsTimers.length === 1
        ? _c(
            "div",
            [
              _c(
                "el-tag",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openSettings(_vm.shopsTimers[0].shop_id)
                    }
                  }
                },
                [
                  _c("div", { staticStyle: { cursor: "pointer" } }, [
                    _vm._v(_vm._s(_vm.shopsTimers[0].display))
                  ])
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "el-tag",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.openTextPackageMessage }
                },
                [
                  _c("div", { staticStyle: { cursor: "pointer" } }, [
                    _vm._v("Text Confirmations")
                  ])
                ]
              )
            ],
            1
          ),
      _c("confirmation-settings-dialog", {
        attrs: {
          visible: _vm.settingsDialogVisible,
          shopId: _vm.selectedShopId,
          settings: _vm.selectedSettings
        },
        on: {
          setUpdatedSettings: _vm.setUpdatedSettings,
          close: _vm.closeSettings
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }