<template>
  <el-popover
    width="300"
    :placement="placement"
    :trigger="trigger"
    @show="value = statusId"
  >
    <i
      slot="reference"
      class="el-icon-edit t-text-sm t-cursor-pointer t-text-orange-500
        hover:t-text-orange-400 ml-2"
      @click.stop
    />
    <div class="t-flex t-h-6 t-flex-grow t-justify-between t-items-center">
      <el-switch
        v-model="value"
        :active-value="2"
        active-text="Confirmed"
        :inactive-value="1"
        inactive-text="Open"
      />
      <el-button
        v-if="value !== statusId"
        size="mini"
        type="primary"
        :loading="submitting"
        @click="submit"
      >
        Update
      </el-button>
    </div>
  </el-popover>
</template>

<script>
export default {
  props: {
    returnId: Number,
    statusId: Number,
    placement: {
      type: String,
      default: 'right'
    },
    trigger: {
      type: String,
      default: 'click'
    }
  },

  data() {
    return {
      value: null,
      submitting: false
    }
  },

  methods: {
    async submit() {
      try {
        this.submitting = true
        await this.$unum
          .warehouse()
          .patch(`returns/${this.returnId}/status`, {
            status: this.value
          })
        this.$emit('updated')
      } catch (err) {
        this.$message.error(err.response.data.message)
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>
