<template>
  <div>
    <LoadingSpinner v-if="inventoryLoading" />
    <div v-else>

      <div class="t-flex t-items-center t-h-8">
        <div class="t-flex-grow t-text-gray-500 t-mr-2 t-w-40">Total Available</div>
        <div
          class="t-w-12 t-text-center"
          :class="{ 't-text-blue-500 hover:t-underline t-cursor-pointer': available.length > 0 }"
          @click="showDetails('Total Available', available)"
        >
          {{ available.length }}
        </div>
      </div>

      <div class="t-flex t-items-center t-h-8">
        <div class="t-flex-grow t-text-gray-500 t-mr-2 t-w-40">Unreceived PO</div>
        <div
          class="t-w-12 t-text-center"
          :class="{
            't-text-blue-500 hover:t-underline t-cursor-pointer': onPurchaseOrder.length > 0
          }"
          @click="showDetails('Total on Purchase', onPurchaseOrder)"
        >
          {{ onPurchaseOrder.length }}
        </div>
      </div>

      <div class="t-flex t-items-center t-h-8">
        <div class="t-flex-grow t-text-gray-500 t-mr-2 t-w-40">Allocated Units</div>
        <div
          class="t-w-12 t-text-center"
          :class="{ 't-text-blue-500 hover:t-underline t-cursor-pointer': openJobInventory.length > 0 }"
          @click="showDetails('Total Allocated Units', openJobInventory)"
        >
          {{ openJobInventory.length }}
        </div>
      </div>

      <el-button
        plain
        type="primary"
        size="mini"
        class="t-mt-2"
        @click="adjustmentDialogIsOpen = true"
      >
        Adjust inventory
      </el-button>
    </div>
    <DetailsDialog
      :visible.sync="detailsDialog.isOpen"
      :title="detailsDialog.title"
      :units="detailsDialog.units"
      :shop="shop"
    />
    <AdjustmentDialog
      :visible.sync="adjustmentDialogIsOpen"
      :itemId="itemId"
      :shopId="shopId"
      @adjusted="getInventory"
    />
  </div>
</template>

<script>
import DetailsDialog from './DetailsDialog.vue'
import AdjustmentDialog from './AdjustmentDialog.vue'
import LoadingSpinner from '@/components/Helpers/LoadingSpinner.vue'

export default {
  components: {
    DetailsDialog,
    AdjustmentDialog,
    LoadingSpinner
  },

  props: {
    itemId: {
      type: Number,
      required: true
    },
    shopId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    inventory: [],
    openJobInventory: [],
    inventoryLoading: true,
    detailsDialog: {
      isOpen: false,
      title: '',
      units: []
    },
    adjustmentDialogIsOpen: false
  }),

  computed: {
    shop() {
      return this.$store.state.shops.find(i => i.id === this.shopId)
    },
    available() {
      return this.inventory.filter(i => !i.jobPartId && (!i.purchaseOrderId || i.receiveId))
    },
    onPurchaseOrder() {
      return this.inventory.filter(i => (
        i.purchaseOrderId && !i.receiveId && !i.techPickUpId
      ))
    },
    onJobs() {
      return this.inventory.filter(i => i.jobPartId)
    },
    onTrucks() {
      return this.inventory.filter(i => i.techPickUpId)
    }
  },

  mounted() {
    this.getInventory()
  },

  watch: {
    shopId(val) {
      if (val) {
        this.getInventory()
      }
    }
  },

  methods: {
    async getInventory() {
      try {
        this.inventoryLoading = true
        const { data } = await this.$unum.warehouse()
          .get(`/items/${this.itemId}/units`, {
            params: {
              shopId: this.shopId
            }
          })
        this.inventory = data?.data?.available || []
        this.openJobInventory = data?.data?.jobPartUnits || []
        this.inventoryLoading = false
      } catch {
        this.inventoryLoading = false
      }
    },
    showDetails(title, units) {
      if (units.length > 0) {
        this.detailsDialog.title = title
        this.detailsDialog.units = units
        this.detailsDialog.isOpen = true
      }
    }
  }
}
</script>
