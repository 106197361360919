<template>
  <div
    class="t-bg-white t-rounded t-w-full t-text-gray-700 t-overflow-y-auto"
  >
    <Header :shopId="shopId" />
    <OutstandingBalances
      :groupBy="groupBy"
      :totalBalance="totalBalance"
      :ninetyPlusBalance="ninetyPlusBalance"
      :sixtyNinetyBalance="sixtyNinetyBalance"
      :thirtySixtyBalance="thirtySixtyBalance"
      :zeroThirtyBalance="zeroThirtyBalance"
      grandTotals
    />
    <template v-if="groupBy === 'age'">
      <InvoicesSection
        v-if="ninetyPlus && ninetyPlus.invoices.length"
        :data="ninetyPlus"
        name="91+ days"
        :shopId="shopId"
        :groupBy="groupBy"
        :expandPayments="expandPayments"
      />
      <InvoicesSection
        v-if="sixtyNinety && sixtyNinety.invoices.length"
        :data="sixtyNinety"
        name="61 - 90 days"
        :shopId="shopId"
        :groupBy="groupBy"
        :expandPayments="expandPayments"
      />
      <InvoicesSection
        v-if="thirtySixty && thirtySixty.invoices.length"
        :data="thirtySixty"
        name="31 - 60 days"
        :shopId="shopId"
        :groupBy="groupBy"
        :expandPayments="expandPayments"
      />
      <InvoicesSection
        v-if="zeroThirty && zeroThirty.invoices.length"
        :data="zeroThirty"
        name="Under 31 days"
        :shopId="shopId"
        :groupBy="groupBy"
        :expandPayments="expandPayments"
      />
    </template>
    <template v-if="groupBy === 'consumer'">
      <InvoicesSection
        v-for="(item, key) in data.consumers"
        :key="key"
        :data="item"
        :name="item.name"
        :shopId="shopId"
        :groupBy="groupBy"
        :expandPayments="expandPayments"
      />
    </template>
  </div>
</template>

<script>
import Header from './Header.vue'
import InvoicesSection from './InvoicesSection.vue'
import OutstandingBalances from './OutstandingBalances.vue'

export default {
  components: {
    Header,
    OutstandingBalances,
    InvoicesSection
  },
  props: {
    data: Object,
    shopId: Number,
    groupBy: String,
    expandPayments: Boolean
  },
  computed: {
    ninetyPlus: function() {
      return this.data?.categories.ninety_plus
    },
    sixtyNinety: function() {
      return this.data?.categories.sixty_ninety
    },
    thirtySixty: function() {
      return this.data?.categories.thirty_sixty
    },
    zeroThirty: function() {
      return this.data?.categories.zero_thirty
    },
    totalBalance: function() {
      return this.data?.balance
    },
    ninetyPlusBalance: function() {
      return this.data?.categories.ninety_plus.balance
    },
    sixtyNinetyBalance: function() {
      return this.data?.categories.sixty_ninety.balance
    },
    thirtySixtyBalance: function() {
      return this.data?.categories.thirty_sixty.balance
    },
    zeroThirtyBalance: function() {
      return this.data?.categories.zero_thirty.balance
    }
  }
}
</script>
