<template>
  <div class="consumer-info">
    <div class="consumer-data">
      <div class="title-box">
        <gb-editable-data-row
          :value="value.company_name"
          :hide-label="true"
          @edit="edit"
          editable
          label="Company Name"
          size="normal"
          v-if="value.is_commercial || value.is_insurance"
          :options="{ capitalize: true }"
        />
        <h6 class="title" v-else>
          {{ name }}
        </h6>
        <div v-if="value.id" style="margin: 0px 10px;">Customer Id: {{value.id}}</div>
      </div>
      <gb-data-rows :schema="schema" :data="value" @edit="editField" />
    </div>
    <div class="consumer-meta">
      <div class="avatar">
        <span>{{ initials }}</span>
      </div>
      <div v-if="value.is_insurance" class="consumer-insurance-buttons">
        <el-button size="mini" tabindex="-1" @click="setSgc"> SGC </el-button>
        <el-button size="mini" tabindex="-1" @click="setLynx"> LYNX </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getConsumerName,
  getInitials,
} from "@/scripts/helpers/consumer.helpers";
import { sgcFields, lynxFields } from "@/constants/insurance.config";

export default {
  props: ["value"],
  computed: {
    name() {
      return getConsumerName(this.value);
    },
    schema() {
      return [
        [
          { label: "Primary Phone", id: "phone.number", type: "phone" },
          { label: "Secondary Phone", id: "secondaryPhone.number", type: "phone" },
          { label: "Fax", id: "fax", editable: true, mask: "(###) ###-####" },
        ],
        [
          { label: "Primary Email", id: "email.email" },
          { label: "Website", id: "website", editable: true },
        ],
        [{ label: "Primary Address", id: "address", type: "address" }],
      ];
    },
    initials() {
      try {
        return getInitials(this.value);
      } catch {
        return "";
      }
    },
  },
  methods: {
    edit({ value }) {
      this.$emit("input", { ...this.value, company_name: value });
    },
    editField({ key, value }) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    setSgc() {
      this.$emit("input", { ...this.value, ...sgcFields });
    },
    setLynx() {
      this.$emit("input", { ...this.value, ...lynxFields });
    },
  },
};
</script>

<style lang="scss" scoped>
.consumer-info {
  display: flex;
  justify-content: space-between;
  .consumer-data {
    min-width: 50%;
  }
  .title-box {
    display: flex;
    align-items: center;
    margin-bottom: $padding-lg;
    i {
      font-size: 12px;
      margin-left: $padding-xxs;
      cursor: pointer;
      display: block;
    }
  }
  .consumer-meta {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    .consumer-insurance-buttons {
      margin-bottom: $padding-md;
    }
  }
  .title {
    color: #000;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: bold;
    margin: 7px 0;
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c4c4c4;
    font-size: 45px;
    color: #000;
  }
}
</style>

<style lang="scss">
.consumer-info {
  .title-box {
    .editable-data-row {
      .data-row {
        margin-bottom: 0;
      }
      &.editing {
        margin-bottom: 0;
      }
      .data-row-value {
        color: #000;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        font-weight: bold;
        margin: 7px 0;
      }
    }
  }
}
</style>
