<template>
  <div
    class="scheduler-tech-totals"
    v-if="route && (route.distance || route.duration || route.total_nags_hours)"
  >
    <div>
      <template v-if="route.distance">
        Total driving distance: <span>{{ distance }}</span> miles<br />
      </template>
      <template v-if="route.duration">
        Total driving time: <span>{{ duration }}</span> hours<br />
      </template>
      <template v-if="route.total_nags_hours">
        Total nags hours: <span>{{ route.total_nags_hours }} hours</span>
      </template>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import * as R from 'ramda'
import {
  metersToMiles,
  secondsToHours
} from '@/scripts/helpers/scheduler_v3.helpers'

export default {
  props: ['route'],
  computed: {
    techTotals: get('sch_v3/techTotals'),
    distance() {
      return R.pipe(R.pathOr(0, ['route', 'distance']), metersToMiles)(this)
    },
    duration() {
      return R.pipe(R.pathOr(0, ['route', 'duration']), secondsToHours)(this)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerTechTotals.scss';
</style>
