import ObjectTracker from '@/scripts/objects/objectTracker'

export default class Priceleveltier extends ObjectTracker {
  id = -1
  created = -1
  tier_min = 0
  tier_max = 0
  add_material = 0
  add_labor = 0
  auto_add_adhesive = 0
  auto_add_adhesive_flat_or_each = 'each'
  auto_add_adhesive_amount = 0
  tax_included = 0
  round_to9 = 0
  pricelevel_id = -1
  is_flat_price = 0
  modified = -1
  is_cost_plus_flat = 1
  is_cost_plus_percent = 0

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  getAmountFromTier (inAmount) {
    var tempAmount = parseFloat(inAmount)
    if (this.is_flat_price === '1' || this.is_flat_price === 1) {
      tempAmount = 0
      if (this.add_material !== null && this.add_material !== 0) {
        tempAmount += this.add_material
      }
    }
    if (this.is_cost_plus_flat === '1' || this.is_cost_plus_flat === 1) {
      tempAmount += this.add_material
    }
    if (this.is_cost_plus_percent === '1' || this.is_cost_plus_percent === 1) {
      tempAmount += (tempAmount * (this.add_material / 100))
    }
    if (this.add_labor !== null && this.add_labor !== 0) {
      tempAmount += this.add_labor
    }
    if (this.round_to9 !== null && this.round_to9 === 1) {
      // var tempInt = parseInt(tempAmount)
      // var tempCeil = Math.ceil((tempInt + 2) / 10)
      // var temphuh = tempCeil * 10
      // tempAmount = temphuh - 1
      tempAmount = (Math.ceil((parseInt(tempAmount) + 2) / 10) * 10) - 1
    }
    if (this.auto_add_adhesive === 1) {
      if (this.auto_add_adhesive_flat_or_each === 'flat') {
        tempAmount += parseInt(this.auto_add_adhesive_amount)
      } else {
        tempAmount += parseInt(this.auto_add_adhesive_amount) * 2
      }
    }
    return tempAmount.toFixed(2).toString()
  }
}
