var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: _vm.title,
            id: _vm.computedModalId,
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg"
          },
          on: {
            show: _vm.dialogAboutToAppear,
            hide: _vm.dialogAboutToDisappear
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6" },
              [
                _c("Email", {
                  ref: "emailToComponent",
                  attrs: {
                    title: "Email Address",
                    id: "email-address",
                    value: _vm.emailTo,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      _vm.emailTo = $event
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6" },
              [
                _c("Email", {
                  ref: "emailFromComponent",
                  attrs: {
                    title: "Reply Email Address",
                    id: "email-reply",
                    value: _vm.emailFrom,
                    placeholder: "",
                    required: false
                  },
                  on: {
                    "update:value": function($event) {
                      _vm.emailFrom = $event
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-md-12" }, [
              _c("label", { attrs: { for: "email-subject" } }, [
                _vm._v("Subject")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.emailSubject,
                    expression: "emailSubject"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { type: "text", id: "email-subject" },
                domProps: { value: _vm.emailSubject },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.emailSubject = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-12" },
              [
                _c("label", { attrs: { for: "email-body" } }, [_vm._v("Body")]),
                _c("b-form-textarea", {
                  staticClass: "form-control form-control-sm",
                  attrs: {
                    type: "textarea",
                    id: "email-body",
                    placeholder: "Enter Message Body...",
                    rows: "3",
                    "max-rows": "5"
                  },
                  model: {
                    value: _vm.emailMessage,
                    callback: function($$v) {
                      _vm.emailMessage = $$v
                    },
                    expression: "emailMessage"
                  }
                })
              ],
              1
            )
          ]),
          _vm.mainPDFAttachmentName && _vm.mainPDFAttachmentName.length > 0
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-12 col-lg-5" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "form-check label label-gray label-sm mt-1"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.includePdf,
                              expression: "includePdf"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox", id: "include-docs" },
                          domProps: {
                            checked: Array.isArray(_vm.includePdf)
                              ? _vm._i(_vm.includePdf, null) > -1
                              : _vm.includePdf
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.includePdf,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.includePdf = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.includePdf = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.includePdf = $$c
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "include-docs" }
                          },
                          [_vm._v(_vm._s(_vm.mainPDFAttachmentName))]
                        )
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _c("br"),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col" },
              [
                _c("b-table", {
                  attrs: {
                    busy: _vm.loadingEmailHistory,
                    "sort-by": "sent",
                    "sort-desc": false,
                    "per-page": 5,
                    "current-page": _vm.currentPage,
                    striped: "",
                    hover: "",
                    items: _vm.emailHistory,
                    fields: _vm.tableFields,
                    responsive: ""
                  }
                }),
                _c("b-pagination", {
                  attrs: {
                    align: "right",
                    size: "md",
                    "total-rows": _vm.emailHistory.length,
                    "per-page": 5
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", size: "lg", variant: "primary" },
                    on: { click: _vm.sendEmail }
                  },
                  [_vm._v("Send Email")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", variant: "primary" },
                    on: { click: _vm.cancelButtonPressed }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ]
          )
        ]
      ),
      _c("LoadingDialog", {
        attrs: {
          id: _vm.computedLoadingModalId,
          isOpen: _vm.emailSending,
          message: _vm.emailSendingMessage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }