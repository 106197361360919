import gql from 'graphql-tag'

export const TIMESPAN_FRAGMENT = gql`
  fragment InstallTimeSpanFields on InstallTimeSpan {
    id
    name
    time_start
    time_end
  }
`
