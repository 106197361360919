import Api from '@/services/Api'

const getWarehouses = async () => {
  const { data } = await Api.get('/api/pilkington/warehouses')
  return data
}

const getShopWarehouses = async shopId => {
  const { data } = await Api.get('/api/pilkington/shopWarehouses', {
    params: { shopId }
  })
  return data
}

const updateVendorWarehouses = async ({
  shopId,
  account,
  warehouses,
  defaultWarehouse
}) => Api.post(`/api/shop/${shopId}/updateVendorWarehouses`, {
  account,
  warehouses,
  defaultWarehouse
})

export const pilkingtonRepository = Object.freeze({
  getWarehouses,
  getShopWarehouses,
  updateVendorWarehouses
})
