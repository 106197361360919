// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".has-notes .el-table__expand-icon {\n  background-color: lightgreen !important;\n  border-radius: 15px !important;\n}\n.call-back .el-table__expand-icon {\n  background-color: red !important;\n  border-radius: 15px !important;\n  color: white;\n}", "",{"version":3,"sources":["/app/src/components/LeadsGlassbiller/Table.vue"],"names":[],"mappings":"AAiOE;EACE,uCAAA;EACA,8BAAA;AArNJ;AAyNE;EACE,gCAAA;EACA,8BAAA;EACA,YAAA;AAtNJ","file":"Table.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.has-notes .el-table__expand-icon {\n  background-color: lightgreen !important;\n  border-radius: 15px !important;\n}\n\n.call-back .el-table__expand-icon {\n  background-color: red !important;\n  border-radius: 15px !important;\n  color: white;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
