var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticClass: "tech-table",
      attrs: { data: _vm.data, "empty-text": "No techs selected" }
    },
    [
      _c("el-table-column", { attrs: { label: "Technician", prop: "name" } }),
      _c("el-table-column", {
        attrs: { label: "Start location", width: "250px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("tech-location-select", {
                  attrs: { tech: scope.row, prop: "start_location" },
                  on: {
                    change: function($event) {
                      return _vm.$emit(
                        "row-change",
                        Object.assign({}, scope.row, { start_location: $event })
                      )
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "End location", width: "250px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("tech-location-select", {
                  attrs: { tech: scope.row, prop: "end_location" },
                  on: {
                    change: function($event) {
                      return _vm.$emit(
                        "row-change",
                        Object.assign({}, scope.row, { end_location: $event })
                      )
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Shift start" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("el-time-select", {
                  attrs: {
                    value: scope.row.shift_start,
                    "picker-options": {
                      start: "05:30",
                      step: "00:30",
                      end: "20:30"
                    }
                  },
                  on: {
                    input: function($event) {
                      return _vm.$emit(
                        "row-change",
                        Object.assign({}, scope.row, { shift_start: $event })
                      )
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Shift end" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("el-time-select", {
                  attrs: {
                    value: scope.row.shift_end,
                    "picker-options": {
                      start: "05:30",
                      step: "00:30",
                      end: "20:30"
                    }
                  },
                  on: {
                    input: function($event) {
                      return _vm.$emit(
                        "row-change",
                        Object.assign({}, scope.row, { shift_end: $event })
                      )
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Min. jobs" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("el-input-number", {
                  attrs: { size: "small", value: scope.row.min_visits },
                  on: {
                    change: function($event) {
                      return _vm.$emit(
                        "row-change",
                        Object.assign({}, scope.row, { min_visits: $event })
                      )
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { width: "100px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("row-remove", scope.row)
                          }
                        }
                      },
                      [_vm._v("Remove")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }