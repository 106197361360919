var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { width: "300", placement: _vm.placement, trigger: _vm.trigger },
      on: {
        show: function($event) {
          _vm.value = _vm.statusId
        }
      }
    },
    [
      _c("i", {
        staticClass:
          "el-icon-edit t-text-sm t-cursor-pointer t-text-orange-500\n      hover:t-text-orange-400 ml-2",
        attrs: { slot: "reference" },
        on: {
          click: function($event) {
            $event.stopPropagation()
          }
        },
        slot: "reference"
      }),
      _c(
        "div",
        {
          staticClass:
            "t-flex t-h-6 t-flex-grow t-justify-between t-items-center"
        },
        [
          _c("el-switch", {
            attrs: {
              "active-value": 2,
              "active-text": "Confirmed",
              "inactive-value": 1,
              "inactive-text": "Open"
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          }),
          _vm.value !== _vm.statusId
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    loading: _vm.submitting
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(" Update ")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }