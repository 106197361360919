var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-flex t-justify-between t-space-x-6 t-items-center t-px-6 t-py-4 t-border-b t-border-b-gray-200 t-shadow"
    },
    [
      _c(
        "div",
        { staticClass: "t-flex t-flex-grow t-justify-between t-items-center" },
        [
          _vm._m(0),
          _vm.startingExport
            ? _c(
                "div",
                {
                  staticClass:
                    "t-flex t-text-yellow-700 t-items-center t-space-x-2"
                },
                [
                  _c("i", { staticClass: "el-icon-loading t-text-xl" }),
                  _c("div", [_vm._v("Starting export...")])
                ]
              )
            : _vm._e(),
          !_vm.startingExport && _vm.exportStarted
            ? _c(
                "div",
                {
                  staticClass:
                    "t-flex t-text-green-600 t-items-center t-space-x-2"
                },
                [
                  _c("i", { staticClass: "material-icons-outline md-check" }),
                  _c("div", [_vm._v("Export started!")])
                ]
              )
            : _vm._e()
        ]
      ),
      _c("i", {
        staticClass:
          "material-icons-outline md-clear t-text-gray-600 t-text-lg t-cursor-pointer",
        on: { click: _vm.close }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t-flex t-items-center" }, [
      _c("div", { staticClass: "t-text-gray-400 t-text-base t-mr-6" }, [
        _vm._v("QBO Sync")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }