<template>
  <button class="sidebar-button" @click="$emit('click')">
    <div class="sidebar-button-image">
      <img :src="image" :alt="label" />
    </div>
    <div class="sidebar-badge" :class="{ expanded }" v-if="count">
      <span v-if="expanded">{{ count }}</span>
    </div>
    <div class="sidebar-label" :class="{ expanded }">
      <span>
        {{ label }}
      </span>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    image: String,
    count: Number,
    label: String,
    expanded: Boolean
  }
}
</script>

<style lang="scss" scoped>
.sidebar-button {
  .sidebar-button-image {
    @include flexAllCenter;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }
  overflow-x: hidden;
  padding: $padding-xs;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.1s ease-in-out;
  position: relative;
  overflow-y: hidden;

  &:hover,
  &:focus {
    background: #eee;
  }
  &:active {
    background: #ddd;
  }
  .sidebar-badge {
    position: absolute;
    top: $padding-xs;
    right: $padding-xs;
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    background: #f56c6c;
    border-radius: 5px;
    pointer-events: none;
    @include flexAllCenter;
    transition: all 0.2s ease-in;
    font-size: 1px;
    &.expanded {
      width: 34px;
      height: 18px;
      font-size: 12px;
      line-height: 18px;
      border-radius: 9px;
      color: #fff;
      top: 0;
    }
  }
  .sidebar-label {
    transition: all 0.2s ease-in;
    color: #414141;
    line-height: 14px;
    font-size: 1px;
    opacity: 0;
    &.expanded {
      font-size: 12px;
      opacity: 1;
    }
  }
}
</style>
