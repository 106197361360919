import * as R from 'ramda'

export const types = {
  updateUserSettings: 'UPDATE_USER_SETTINGS',
  getSchedulerData: 'GET_SCHEDULER_DATA',
  updateSchedulerItems: 'UPDATE_SCHEDULER_ITEMS',
  postSchedulerUpdates: 'POST_SCHEDULER_UPDATES',
  createRouteWaypoint: 'CREATE_ROUTE_WAYPOINT',
  deleteWaypoint: 'DELETE_WAYPOINT',
  getJobDetails: 'GET_JOB_DETAILS',
  updateJobDetailsValue: 'UPDATE_JOB_DETAILS_VALUE',
  updateJobDetailsValues: 'UPDATE_JOB_DETAILS_VALUES',
  completeTechsideJob: 'COMPLETE_TECHSIDE_JOB',
  updateJobAddressByPlaceId: 'UPDATE_JOB_ADDRESS_BY_PLACE_ID',
  getShopTechs: 'GET_SHOP_TECHS',
  saveAppointment: 'SAVE_APPOINTMENT',
  getTechInfo: 'GET_TECH_INFO',
  updateTechInList: 'UPDATE_TECH_IN_LIST',
  getSchedulerMapData: 'GET_SCHEDULER_MAP_DATA',
  getSchedulerWarehouses: 'GET_SCHEDULER_WAREHOUSES',
  getSchedulerTotals: 'GET_SCHEDULER_TOTALS',
  addStatusToConfirmationHistory: 'ADD_STATUS_TO_CONFIRMATION_HISTORY',
  updateBayItems: 'UPDATE_BAY_ITEMS',
  mapUpdate: 'MAP_UPDATE'
}

export const mutationTypes = {
  setJobs: 'SET_JOBS',
  setWaypoints: 'SET_WAYPOINTS',
  setAppointments: 'SET_APPOINTMENTS',
  addStatusToConfirmationHistory: 'ADD_STATUS_TO_CONFIRMATION_HISTORY'
}

export default R.map(R.concat('sch_v2/'))(types)
