<template>
  <div>
    <div class="input-group mt-1 mt-lg-0">
      <FloatInput
      ref="input"
      :inDisabled="inDisabled"
      :value.sync="inputValue"
      :minimumDecimalPlaces="2"
      :maximumDecimalPlaces="2"
      :inClass="inInputClass"
      :required="required"
      :failMessage="''"
      :isGroupInput="true"
      :formattedPrefix="formattedPrefix"/>
      <div class="input-group-append">
        <button @click="showDropdown" :disabled="inDisabled" class="btn btn-gray btn-sm dropdown-toggle" type="button" aria-haspopup="true" aria-expanded="false">
          <span v-for="(dropdownOption, index) in dropdownOptions" v-bind:key="index" v-show="inputDropdownValue === dropdownOption.value || ((!inputDropdownValue || inputDropdownValue.length === 0) && index === 0)">{{dropdownOption.label}}</span>
        </button>
        <div ref="dropdown" class="dropdown-menu dropdown-menu-right dropdown-menu-sm">
          <div class="px-3">
            <div class="form-check" v-for="(dropdownOption, index) in dropdownOptions" v-bind:key="index">
              <input :disabled="inDisabled" v-model="inputDropdownValue" class="form-check-input" type="radio" :value="dropdownOption.value">
              <label class="form-check-label text-dark">{{dropdownOption.dropdownLabel}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="internalBottomText && internalBottomText.length > 0">
      {{ internalBottomText }}
    </div>
  </div>
</template>

<script>
import FloatInput from '@/components/Helpers/FloatInput.vue'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default {
  props: ['inDisabled', 'dropdownValue', 'value', 'totalToDisplay', 'required', 'dropdownOptions', 'inInputClass',
    'formattedPrefix'],
  data: () => {
    return {
      isMounted: false
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    updateShowFailureMessage: function (activateShowFailureMessage) {
      this.$refs.input.updateShowFailureMessage(activateShowFailureMessage)
    },
    showDropdown: function () {
      if (this.$refs.dropdown.classList.contains('show')) {
        this.$refs.dropdown.classList.remove('show')
      } else {
        this.$refs.dropdown.classList.add('show')
      }
    },
    isValid: function () {
      return this.$refs.input.isValid()
    }
  },
  computed: {
    inputValue: {
      get: function () {
        return this.value
      },
      set: function (changedVal) {
        this.$emit('update:value', changedVal)
      }
    },
    inputDropdownValue: {
      get: function () {
        return this.dropdownValue
      },
      set: function (changedVal) {
        this.$refs.dropdown.classList.remove('show')
        this.$emit('update:dropdownValue', changedVal)
      }
    },
    internalBottomText: function () {
      if (this.isMounted && this.$refs && this.$refs.input && this.$refs.input.isValid() &&
        (this.totalToDisplay === 0 || this.totalToDisplay) && !this.inDisabled && this.dropdownValue === 'percent') {
        return UtilityMixin.methods.formatCurrency(this.totalToDisplay)
      }
      return ''
    },
    internalFailMessage: function () {
      return this.$refs.input.internalFailMessage()
    }
  },
  components: {
    FloatInput
  }
}
</script>

<style>

</style>
