import Vue from 'vue'
import Pusher from 'pusher-js'
import store from '@/store/store'
import { baseApiUrl } from './Api'
import { Notification, MessageBox } from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import cfg from '@/config'
import PusherBatchAuthorizer from 'pusher-js-auth'
import User from '@/scripts/objects/user'
import { globalActions } from '@/store/modules/notifications/types'
import { parseRow } from '@/store/modules/notifications/helpers'
import GBNotification from '@/components/Notifications/Notification'

const ChannelType = {
  CHANNEL_TYPE_WEB: 'web',
  CHANNEL_TYPE_POPUPP: 'pop-up'
}

const playNotificationSound = () => {
  const audio = new Audio(require('@/assets/sounds/notification.ogg'))
  audio.play()
}

/**
 * Load vue template for short notificatin and compile it to static HTML
 */
const loadNotificationHTML = (data) => {
  const component = new (Vue.extend(GBNotification))({
    propsData: {
      data: parseRow(data)
    }
  })

  component.$mount()

  // create parent div
  const div = document.createElement('div')
  div.appendChild(component.$el)

  return div.innerHTML
}

class PusherService {
  init = async () => {
    const { $auth } = Vue.prototype

    const token = await $auth.getTokenSilently()
    this.pusher = new Pusher(cfg.pusher.key, {
      cluster: cfg.pusher.cluster,
      disableStats: true,
      authorizer: PusherBatchAuthorizer,
      authEndpoint: baseApiUrl + '/api/pusher/auth'
    })

    this.setAuth(token)

    this.pusher.connection.bind('connected', this.onConnected)
    this.subscribeToNotifications()
  }

  setAuth = (token) => {
    this.pusher.config.auth = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  }

  onConnected = () => {
    store.dispatch(globalActions.getUnreadCount)
  }

  subscribeToSquareDeviceNotifications = async (shopId, deviceId) => {
    const channel = this.pusher.subscribe('square-shop-' + shopId + '-device-' + deviceId)

    channel.bind('checkout-updated', payload => {
      store.dispatch('square/checkout/update', payload)
    })
  };

  unsubscribeToSquareDeviceNotifications = async (shopId, deviceId) => {
    this.pusher.unsubscribe('square-shop-' + shopId + '-device-' + deviceId)
  };

  subscribeToNotifications = async () => {
    const channel = this.pusher.subscribe(`private-notifications-${store.state.user.id}`)
  
    channel.bind(ChannelType.CHANNEL_TYPE_POPUPP, ({ data, sound }) => {
      if (sound) {
        playNotificationSound()
      }

      MessageBox({
        dangerouslyUseHTMLString: true,
        message: loadNotificationHTML(data),
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: 'notification-message-box',
        onClose: () => {
          data.is_read = true
          store.dispatch(globalActions.receive, { data })
        },
        locale
      })
    })
  
    channel.bind(ChannelType.CHANNEL_TYPE_WEB, ({ data, sound }) => {
      if (sound) {
        playNotificationSound()
      }

      const duration = 4500
      const receivedTime = Date.now()

      Notification({
        dangerouslyUseHTMLString: true,
        customClass: 'notification-push',
        message: loadNotificationHTML(data),
        duration: duration,
        showClose: true,
        onClose: () => {
          const elapsedTime = Date.now() - receivedTime
          // buffer time we consider was spent on
          // notification initialisation in ms
          const bufferTime = 5

          // check if we closed notifiation manually
          data.is_read = (duration - elapsedTime - bufferTime) > 0

          store.dispatch(globalActions.receive, { data })
        }
      })
    })
  }

  subscribeToRelatedShops = async () => {
    const shopIds = await User.getShopIdsWithActiveTwilio()
    for (const { id } of shopIds) {
      const channel = this.pusher.subscribe('private-shop-' + id)

      channel.bind('new-incoming-text-message', async ({ threadId, phoneNumberId, recipientType }) => {
        const usersPhoneNumbers = await User.getSelectedTwilioPhoneNumbers(store.state.user.id)
        let hasAccess = false
        for (const phoneNumber of usersPhoneNumbers) {
          if (phoneNumber.id === phoneNumberId) {
            hasAccess = true
          }
        }
        if (hasAccess) {
          if (store.state.twilio.textMessages.visible) {
            store.dispatch('twilio/textMessages/setNewMessageThreadId', threadId)
          } else {
            await User.hasUnreadTextMessage({ hasUnreadTextMessage: 1 })
            store.commit('hasUnreadTextMessage', 1)
          }
          store.dispatch('twilio/textMessages/getUnreadThreadsCount')
        }
      })

      channel.bind('unread-thread-opened', () => {
        store.dispatch('twilio/textMessages/getUnreadThreadsCount')
      })

      channel.bind('text-message-delivery-failed', payload => {
        if (store.state.twilio.textMessages.visible) {
          store.dispatch('twilio/textMessages/setUndeliveredMessage', payload)
        }
      })
    }
  }
}

export default new PusherService()
