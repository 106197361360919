var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "span",
      {
        staticClass: "col-11",
        staticStyle: {
          "font-size": "14px",
          "text-overflow": "ellipsis",
          overflow: "hidden",
          padding: "0px"
        }
      },
      [_vm._v(" " + _vm._s(_vm.shop.name) + " ")]
    ),
    _vm.shopRejectedCount(_vm.$store.state, _vm.shop.id)
      ? _c(
          "span",
          {
            staticClass: "float-right shop-option-tag",
            style: "background:#dc3545;"
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.shopRejectedCount(_vm.$store.state, _vm.shop.id)) +
                " "
            )
          ]
        )
      : _vm._e(),
    _vm.shopQueuedCount(_vm.$store.state, _vm.shop.id)
      ? _c(
          "span",
          {
            staticClass: "float-right shop-option-tag",
            style: "background:#26af5d;"
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.shopQueuedCount(_vm.$store.state, _vm.shop.id)) +
                " "
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }