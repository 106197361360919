<template>
  <el-dialog
    append-to-body
    width="700px"
    :visible.sync="localVisible"
    class="report-permissions-dialog"
    title="Report Permissions"
    @open="open"
    @close="close"
  >
    <span v-if="displayOverlay" class="overlay">
      <div @click="localVisible = false" class="close-dialog-overlay">
        <v-icon name="times" scale="1.1" />
      </div>
      <div class="overlay-text">The general reports permission must be checked for these permissions to take effect</div>
    </span>
    <div class="permissions-container p-2">
      <div v-for="(category, index) in reportsConfig" :key="`test-${index}`">
        <el-checkbox :indeterminate="indeterminates[category.type]" v-model="checkAlls[category.type]" @change="handleAllReports($event, category.options, category.type)">
          <span style="font-weight:bold;">{{category.label}}</span>
        </el-checkbox>
        <div style="margin: 5px 0;"></div>
        <el-checkbox-group v-model="checkedReports" @change="handleCheckedReports(category.options, category.type)">
          <el-checkbox v-for="report in category.options" :label="report.value" :key="report.value">{{report.label}}</el-checkbox>
        </el-checkbox-group>
        <hr/>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :disabled="saving" @click="save">Save</el-button>
      <el-button style="background-color:black; color:white;" :disabled="saving" @click="localVisible = false">Cancel</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as R from 'ramda'
import User from '@/scripts/objects/user'
import {reportPermissionsConfigSchema, reportPermissionIds} from '../../constants/report-permissions.config.js'

export default {
  props: ['visible', 'userId', 'userPermissions'],
  data () {
    return {
      reportsConfig: reportPermissionsConfigSchema,
      reportIdsConfig: reportPermissionIds,
      displayOverlay: false,
      saving: false,
      checkedReports: [],
      indeterminates: {
        sales: false,
        taxes: false,
        schedule: false,
        install: false,
        payment: false,
        statement: false,
        accounting: false,
        miscellaneous: false
      },
      checkAlls: {
        sales: false,
        taxes: false,
        schedule: false,
        install: false,
        payment: false,
        statement: false,
        accounting: false,
        miscellaneous: false
      }
    }
  },
  computed: {
    localVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    open() {
      // display overlay if needed
      if (!this.userPermissions.hasOwnProperty('reports')) {
        this.displayOverlay = true
      }
      var self = this
      // check boxes for individual reports
      this.checkedReports = [...Object.keys(this.userPermissions)]
      // determine check alls and indeterminates
      for (var i = 0; i < self.reportsConfig.length; i++) {
        let category = self.reportsConfig[i]
        let confirmedChecked = []
        for (var j = 0; j < category.options.length; j++) {
          let tempIndex = self.checkedReports.indexOf(category.options[j].value)
          if (tempIndex !== -1) {
            confirmedChecked.push(category.options[j].value)
          }
        }
        if (confirmedChecked.length === category.options.length) {
          self.checkAlls[category.type] = true
        } else if (confirmedChecked.length > 0) {
          self.indeterminates[category.type] = true
        }
      }
    },
    close() {
      this.displayOverlay = false
    },
    async save() {
      this.saving = true
      const newPermissions = []
      const reportKeys = Object.keys(this.reportIdsConfig)
      for (var i = 0; i < reportKeys.length; i++) {
        if (this.checkedReports.includes(reportKeys[i])) {
          newPermissions.push({name: reportKeys[i], role_id: this.reportIdsConfig[reportKeys[i]]})
        }
      }
      const res = await User.updateReportPermissions({userId: this.userId, permissions: newPermissions})
      this.saving = false
      if (res === 'success') {
        this.$message.success('Report permissions updated')
      } else {
        this.$message.error('Something went wrong')
      }
      this.$emit('refreshPermissions')
      this.localVisible = false
    },
    handleAllReports(val, reports, type) {
      // for 'check all' option in each category
      if (val) {
        for (var i = 0; i < reports.length; i++) {
          if (!this.checkedReports.includes(reports[i].value)) {
            this.checkedReports.push(reports[i].value)
          }
        }
      } else {
        this.checkedReports = R.pipe(
          R.reject(R.contains(R.__, R.map(R.prop('value'))(reports)))
        )(this.checkedReports)
      }
      this.indeterminates[type] = false
    },
    handleCheckedReports(options, type) {
      // for checking individual reports
      let checkedCount = 0
      for (var i = 0; i < options.length; i++) {
        if (this.checkedReports.includes(options[i].value)) {
          checkedCount++
        }
      }
      this.checkAlls[type] = checkedCount === options.length;
      this.indeterminates[type] = checkedCount > 0 && checkedCount < options.length;
    }
  }
}
</script>

<style lang='scss'>
.report-permissions-dialog {
  .permissions-container {
    border: 2px solid lightgray;
    border-radius: 5px;
  }
  .overlay {
    position:absolute;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    background-color:rgba(252, 252, 252, 0.8);
    z-index: 100;
  }
  .overlay-text {
    margin:30% 10%;
    text-align:center;
    font-weight:bold;
    font-size:24px;
    white-space:pre-wrap;
  }
  .close-dialog-overlay {
    cursor: pointer;
    position: absolute;
    right: 21px;
    top: 21px;
  }
}
</style>