<template>
  <div class="gb-content-slider">
    <div class="gb-content-slider-body" :style="{ marginLeft: offset }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: { active: { default: () => 0 } },
  computed: {
    offset() {
      return `-${this.active * 100}%`
    }
  }
}
</script>

<style lang="scss" scoped>
.gb-content-slider {
  overflow-x: hidden;
  width: 100%;
  .gb-content-slider-body {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    transition: margin 0.2s ease-in-out;
    > * {
      width: 100%;
      flex-shrink: 0;
    }
  }
}
</style>
