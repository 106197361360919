<template>
  <div
    class="part-tag"
    :style="{
      background: color || type.color,
      color: color || type.color
    }"
    :class="{ 'with-click': withClick || withDropdown }"
    @click.stop="onClick"
  >
    <part-tag-icon :part="part" v-if="withClick" ref="icon" />
    <span>
      <slot /> {{ justType ? type.short : part.part_number || part.partno }}
    </span>
  </div>
</template>

<script>
import { getPartType } from '@/scripts/helpers/nags.helpers'
import PartTagIcon from './PartTagIcon'

export default {
  components: { PartTagIcon },
  props: {
    part: {},
    justType: Boolean,
    color: String,
    animate: String,
    locked: Boolean
  },
  computed: {
    type() {
      return getPartType(this.part)
    },

    withClick() {
      return !!this.$listeners.click && !this.locked
    },
    withDropdown() {
      return !!this.$listeners.input
    }
  },

  methods: {
    onClick() {
      if (!this.locked) {
        this.$emit('click', this.type)
        const { icon } = this.$refs
        if (icon) {
          icon.add()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.part-tag {
  font-size: $font-md;
  line-height: 17px;
  color: #fff;
  background: $air-force-blue;
  border-radius: 20px;
  padding: 0 $padding-xs;
  letter-spacing: 0.1em;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  height: 23px;
  position: relative;
  white-space: nowrap;
  & ~ .part-tag {
    margin-left: $padding-xs;
  }
  .el-icon-loading {
    color: #FFF;
  }
  span {
    color: #fff;
  }
  &.with-click {
    cursor: pointer;
    /* transition: box-shadow 0.2s ease-in-out; */
    /* &:hover {
      box-shadow: 0 0 3px 0 currentColor;
    } */
  }
  .trigger {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    overflow: hidden;
  }

  &:active {
    filter: brightness(95%);
  }
  i.md-playlist_add {
    margin-left: -24px;
    opacity: 0.4;
  }
  &:hover {
    i.md-playlist_add {
      margin-left: 0;
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
    filter: brightness(95%);
  }
}
</style>
