/* global google */
import Api from '@/services/Api'
import { jobLocationUrl } from '@/constants/endpoints'
import axios from 'axios'
import * as R from 'ramda'

export const getInfowindowClass = () =>
  google &&
  class InfoWindow extends google.maps.OverlayView {
    constructor(map, location, element) {
      super()
      this.location = new google.maps.LatLng(location)
      this.setMap(map)
      this.div = element
    }

    onAdd() {
      this.div.style.position = 'absolute'
      var panes = this.getPanes()
      panes.floatPane.appendChild(this.div)
    }

    draw() {
      this.projection = this.getProjection()
      const { x, y } = this.projection.fromLatLngToDivPixel(this.location)
      this.div.style.left = `${x}px`
      this.div.style.top = `${y}px`
    }

    onRemove() {
      this.div = null
    }
  }

let autocomplete

export const getPlacesPrediction = (input, bounds, po_box, sessionToken) =>
  new Promise(async (resolve, reject) => {
    try {
      const service =
        autocomplete || new google.maps.places.AutocompleteService()
      service.getPlacePredictions(
        {
          input,
          // bounds,
          types: po_box ? ['(regions)'] : ['address'],
          componentRestrictions: { country: ['us', 'ca'] },
          sessionToken
        },
        (results, status) => {
          resolve(results)
        }
      )
    } catch (error) {
      resolve([])
    }
  })

let geocoder

export const getStateBounds = state =>
  new Promise(async resolve => {
    try {
      const service = geocoder || new google.maps.Geocoder()
      service.geocode({ address: state }, ([result]) => {
        resolve(result.geometry.bounds)
      })
    } catch (error) {
      resolve()
    }
  })

export const getJobLatLng = async jobId => {
  try {
    const { data } = await Api.get(jobLocationUrl(jobId))
    return data
  } catch (error) {
    return null
  }
}

export const parseAutocompleteResult = result => {
  try {
    const values = R.prop('structured_formatting')(result)
    const install_address_line1 = R.prop('main_text')(values)
    const rest = R.pipe(R.prop('secondary_text'), R.split(', '))(values)

    const install_address_city = R.prop(0)(rest)
    const install_address_state = R.pipe(
      R.prop(1),
      R.split(' '),
      R.prop(0)
    )(rest)
    return {
      install_address_city,
      install_address_state,
      install_address_line1
    }
  } catch (error) {}
}

export const getAddrFromHtmlStr = html => {
  const element = new DOMParser()
    .parseFromString(html, 'text/html')
    .body.querySelector('.locality')
  return element ? element.innerText : null
}

export const getCity = R.pipe(R.propOr('', 'adr_address'), getAddrFromHtmlStr)

export const serializeAddressComponents = R.pipe(
  R.evolve({
    address_components: R.reduce(
      (aggr, { short_name, types }) => ({ ...aggr, [types[0]]: short_name }),
      {}
    )
  }),
  R.applySpec({
    lat: R.pipe(
      R.pathOr(() => {}, ['geometry', 'location', 'lat']),
      R.call
    ),
    lng: R.pipe(
      R.pathOr(() => {}, ['geometry', 'location', 'lng']),
      R.call
    ),
    address: R.pipe(
      R.propOr({}, 'address_components'),
      R.pick(['street_number', 'route']),
      R.values,
      R.join(' ')
    ),
    city: R.ifElse(
      getCity,
      getCity,
      R.path(['address_components', 'locality'])
    ),
    state: R.path(['address_components', 'administrative_area_level_1']),
    zip: R.path(['address_components', 'postal_code']),
    google_place_id: R.prop('place_id'),
    full_address: R.pipe(
      R.propOr('', 'formatted_address'),
      R.split(','),
      R.init,
      R.join(',')
    )
  })
)

export const getPlaceDetails = (placeId, sessionToken) =>
  new Promise(async resolve => {
    try {
      const service = new google.maps.places.PlacesService(
        document.createElement('div')
      )
      service.getDetails(
        {
          placeId,
          sessionToken,
          fields: [
            'address_component',
            'adr_address',
            'formatted_address',
            'geometry,place_id'
          ]
        },
        results => {
          resolve(serializeAddressComponents(results))
        }
      )
    } catch (error) {
      resolve(null)
    }
  })

export const getNearestIntersection = async ({ lat, lng }) => {
  try {
    const { data } = await axios.get(
      `${window.location.protocol}//api.geonames.org/findNearestIntersectionJSON`,
      {
        params: { lat, lng, username: 'glassbiller' }
      }
    )
    return R.pipe(
      R.prop('intersection'),
      R.pick(['street1', 'street2']),
      R.values,
      R.join(' / ')
    )(data)
  } catch (error) {
    return 'Not Available'
  }
}
