<template>
  <div>
    <el-row :gutter="16" class="t-mb-4 t-mt-1">
      <el-col :span="12">
        <input-with-label
          label="Default Invoice Tier"
          size="medium"
          v-model="invoiceTier"
          :select="{
            options: terms,
            clearable: true,
            byId: true,
          }"
        />
      </el-col>
      <el-col :span="12">
        <input-with-label
          :select="{
            options: gb_salesreps,
            byId: true,
          }"
          size="medium"
          label="Default Salesrep"
          v-model="defaultSalesrep"
          @focus="repsFocused = true"
        />
      </el-col>
    </el-row>
    <el-row :gutter="16" class="t-mb-4">
      <el-col :span="12">
        <input-with-label
          :select="{
            options: gb_techs,
            byId: true,
          }"
          size="medium"
          label="Default Tech"
          v-model="defaultTech"
          @focus="techsFocused = true"
        />
      </el-col>
      <el-col :span="12">
        <input-with-label
          size="medium"
          label="Standing PO"
          v-model="standingPo"
          @focus="standingPoFocused = true"
        />
      </el-col>
    </el-row>
    <el-row :gutter="16" class="t-mb-4">
      <el-col :span="12" v-if="!this.value.is_insurance">
        <div class="bill-to-select">
          <consumer-search-bar
            ref="bar"
            :organization_id="value.organization_id"
            :shop_id="$job && $job.shop ? $job.shop.id : null"
            label="Default Bill To"
            :autoFocus="selectingBillTo"
            :selected-id="+defaultBillTo.id"
            :placeholder="defaultBillTo.full_name"
            type="insurance"
            @select="selectBillTo"
            @clear="defaultBillTo = null"
          />
        </div>
      </el-col>
      <el-col :span="12">
        <input-with-label
          size="medium"
          label="Customer Resale ID"
          v-model="resaleId"
        />
      </el-col>
    </el-row>
    <el-row :gutter="16" class="t-mb-6">
      <el-col :span="24">
        <input-with-label
          type="address"
          v-model="installLocationAddress"
          label="Default install location"
          :options="{ clearable: true, defaultResults: extraAddresses }"
        />
      </el-col>
    </el-row>
    <el-row :gutter="16" class="t-mb-4">
      <el-col :span="12">
        <gb-checkbox v-model="autoAddAdh">
          Auto Add Adhesive
        </gb-checkbox>
      </el-col>
      <el-col :span="12">
        <gb-checkbox v-model="autoAddToFleet">
          Auto Add Vehicles To Fleet
        </gb-checkbox>
      </el-col>
    </el-row>
    <el-row :gutter="16" class="t-mb-4">
      <el-col :span="12">
        <gb-checkbox v-model="autoSetInstallAddress">
          Auto Set Install Address
        </gb-checkbox>
      </el-col>
      <el-col :span="12">
        <gb-checkbox v-model="autoSendConfirmationTexts">
          Auto Send Confirmation Texts
        </gb-checkbox>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { invoiceTerms } from '@/constants/invoice.config'
import * as R from 'ramda'
import ConsumerSearchBar from '../../JobPageV2/helpers/ConsumerSearchBar/Index.vue'
import { GB_VENDOR_DROPDOWN_QUERIES } from '@/apollo/queries/gb-vendor.query'

export default {
  props: ['value'],
  data() {
    return {
      selected: false,
      terms: invoiceTerms,
      selectingBillTo: false,
      repsFocused: false,
      techsFocused: false,
      standingPoFocused: false
    }
  },
  apollo: {
    gb_salesreps: {
      query() {
        return GB_VENDOR_DROPDOWN_QUERIES.gb_salesrep
      },
      variables() {
        return {
          organization_id: this.value.organization_id
        }
      },
      skip() {
        return (!this.repsFocused && !this.defaultSalesrep) || !this.value.organization_id
      }
    },
    gb_techs: {
      query() {
        return GB_VENDOR_DROPDOWN_QUERIES.gb_tech
      },
      variables() {
        return {
          organization_id: this.value.organization_id
        }
      },
      skip() {
        return (!this.techsFocused && !this.defaultTech) || !this.value.organization_id
      }
    }
  },
  components: { ConsumerSearchBar },
  computed: {
    autoAddAdh: {
      get() {
        return this.value?.auto_add_adhesive
      },
      set(v) {
        this.$emit('input', {
          ...this.value,
          auto_add_adhesive: +v
        })
      }
    },
    autoAddToFleet: {
      get() {
        return this.value?.auto_add_to_fleet
      },
      set(v) {
        this.$emit('input', {
          ...this.value,
          auto_add_to_fleet: +v
        })
      }
    },
    autoSetInstallAddress: {
      get() {
        return this.value?.auto_set_install_address
      },
      set(v) {
        this.$emit('input', {
          ...this.value,
          auto_set_install_address: +v
        })
      }
    },
    autoSendConfirmationTexts: {
      get() {
        return this.value.consumer_confirmation_settings?.send
      },
      set(v) {
        this.$emit('input', {
          ...this.value,
          consumer_confirmation_settings: {
            send: +v
          }
        })
      }
    },
    invoiceTier: {
      get() {
        return this.value.default_invoice_tier
      },
      set(default_invoice_tier) {
        this.$emit('input', { ...this.value, default_invoice_tier })
      }
    },
    installLocationAddress: {
      get() {
        return this.value.install_location?.address
      },
      set(address) {
        this.$emit('input', {
          ...this.value,
          install_location: {
            ...this.value.install_location,
            address
          }
        })
      }
    },
    defaultBillTo: {
      get() {
        return this.value?.bill_to || {}
      },
      set(bill_to) {
        this.$emit('input', { ...this.value, bill_to })
      }
    },
    defaultSalesrep: {
      get() {
        return this.value?.salesrep?.id
      },
      set(salesrep) {
        this.$emit('input', {
          ...this.value,
          salesrep: { id: salesrep }
        })
      }
    },
    defaultTech: {
      get() {
        return this.value?.tech?.id
      },
      set(tech) {
        this.$emit('input', {
          ...this.value,
          tech: { id: tech }
        })
      }
    },
    extraAddresses() {
      if (this.$job) {
        return [
          ...this.$job.shopInstallLocations.map((v) => ({
            ...v.address,
            extra: `Shop location: ${v.name}`
          })),
          ...this.$job.customer.addresses.map((v) => ({
            ...v,
            extra: 'Customer address'
          }))
        ]
      } else {
        return []
      }
    },
    standingPo: {
      get() {
        return this.value?.standing_po
      },
      set(standing_po) {
        this.$emit('input', {
          ...this.value,
          standing_po
        })
      }
    },
    resaleId: {
      get() {
        return this.value?.resale_id
      },
      set(resale_id) {
        this.$emit('input', {
          ...this.value,
          resale_id: resale_id || null
        })
      }
    }
  },
  methods: {
    selectBillTo(v) {
      this.defaultBillTo = R.pick(['id', 'full_name'], v)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
