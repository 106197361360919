var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-primary filestack-button",
        attrs: { type: "button", variant: "primary" },
        on: { click: _vm.openFilePicker }
      },
      [_vm._v(_vm._s(_vm.buttonLabel))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }