<template>
  <el-scrollbar class="notifications-scrollbar" always >
    <transition-group
      class="list-unstyled list-notifications"
      tag="ul"
      name="list-notifications"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__backOutUp"
    >
      <li v-for="item in rows" :key="item.id" class="notification-item">
        <notification-wrapper :item="item" />
      </li>
    </transition-group>

    <infinite-loading :ref="'loader_' + type" @infinite="loadItems">
      <div slot="no-more"><slot name="no-more"></slot></div>
      <div slot="no-results"><slot name="no-results"></slot></div>
      <div slot="spinner"><div v-loading="true"></div></div>
    </infinite-loading>
  </el-scrollbar>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import NotificationWrapper from './NotificationWrapper'
import { mapActions } from 'vuex'

let cachedLength = 0

export default {
  props: {
    type: {
      type: String,
      required: true
    },

    rows: {
      type: Array,
      required: true
    }
  },

  created() {
    if (this.type === 'read') {
      return
    }

    this.unwatch = this.$store.watch(
      (state, getters) => state.notifications.tabs.unread.rows,
      (newValue, oldValue) => {
        if (newValue.length === cachedLength) {
          return
        }

        // if old list and new list are different then try to reset
        if (!newValue.length !== !cachedLength) {
          this.$refs.loader_unread.stateChanger.reset()
        }

        // cache current length
        cachedLength = newValue.length
      }
    )
  },

  beforeDestroy() {
    this.unwatch()
  },

  methods: {
    ...mapActions('notifications', ['load']),

    loadItems($state) {
      this.load({tab_type: this.type, $state})
    }
  },

  components: {
    InfiniteLoading,
    NotificationWrapper
  }
}
</script>
<style lang="scss">
.notifications-scrollbar .el-scrollbar__bar.is-horizontal {
  display:none;
}

.el-loading-spinner {
  margin-top: 5px;
  width: 100%;
  height: 24px;
  display: inline;

  & svg {
    margin: 0 auto;
  }
}
.el-divider--horizontal {
  margin: 12px 0;
}
</style>

<style lang="scss" scoped>
.list-notifications {
  position: relative;
}

.notification-item {
  z-index: 1000;
}

.notifications-scrollbar {
  padding-left: 8px;
  padding-right: 8px;
  height: calc(100% - 140px);

  background-color: #F3F4F6;
}

.infinite-loading-container {
  margin-bottom: 16px;
}

.list-notifications-move {
  transition: all 400ms ease-in-out;
  transition-delay: 50ms;
}

.animate__animated.animate__backOutUp {
  --animate-duration: 350ms;
  position: absolute;
  z-index: 100;

  // hack to make sure content will be at background :/
  .notification-wrapper-content {
    z-index: 100;
  }
}

</style>
