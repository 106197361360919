import * as R from 'ramda'
import { formatToDb } from './date.helpers'
import { getJobMidTimes, getJobContextCounts } from './job.helpers'
import { shedulerColors } from '@/constants/sheduler.configs'
import { addDays } from 'date-fns'
import { mergeSpec, invoke } from './ramda.helpers'

const mapIndexed = R.addIndex(R.map)

export const generateUpdatedRouteEntries = (rt, date) =>
  mapIndexed((entry, index) => {
    const shopId = R.when(R.prop('job'), R.path(['job', 'shop_id']))(entry)
    const tech = R.ifElse(
      R.prop('techs'),
      R.pipe(R.prop('techs'), R.find(R.propEq('shop_id', shopId))),
      R.pipe(
        R.prop('tech'),
        R.when(R.isNil, () => ({}))
      )
    )(rt)
    return {
      ...entry,
      order: index,
      old_gb_route_gb_vendor_id: entry.gb_route_gb_vendor_id,
      gb_route_gb_vendor_id: tech.id,
      techside_username: tech.techside_username,
      gb_route_date: rt.date,
      color: tech.color || rt.color,
      departure: null,
      arrival: null,
      ...(entry.job
        ? {
          job: {
            ...entry.job,
            scheduler_order: index,
            scheduled_date: rt.date || date,
            tech_id: tech.old_id || null,
            gb_tech_id: tech.id || null,
            tech_commission: R.pathOr(0, ['tech_commission'])(tech),
            tech_commission_flat_or_percent: R.pathOr('flat', [
              'tech_commission_flat_or_percent'
            ])(tech)
          }
        }
        : {})
    }
  })

const ln = R.pipe(R.propOr([], 'route_entries'), R.length)
// i know i know...
export const sortRoutes = R.pipe(
  R.values,
  R.sort((a, b) =>
    !a.gb_vendor_id && !a.techs
      ? ln(a)
        ? -1
        : ln(b)
          ? 1
          : -1
      : !b.gb_vendor_id && !b.techs
        ? ln(b)
          ? 1
          : ln(a)
            ? -1
            : 1
        : ln(a) < ln(b)
          ? 1
          : -1
  ),
  R.map(R.ifElse(R.prop('techs'), R.prop('techside_username'), R.prop('id')))
)

export const extractTechsFromRoutes = R.pipe(
  R.propOr([], 'data'),
  R.values,
  R.map(({ tech, techs }) => tech || techs),
  R.unnest,
  R.filter(R.identity),
  R.sortWith([R.ascend(R.prop('name'))])
)

export const getWeekRange = w =>
  R.pipe(
    R.range,
    R.map(i => formatToDb(addDays(w, i)))
  )(0, 7)

export const getSchedulerRequestParams = (state, rootGetters) =>
  R.pipe(
    R.prop('filters'),
    R.ifElse(
      R.propEq('cardView', 3),
      R.pipe(
        R.pick(['week', 'shops']),
        R.evolve({
          week: getWeekRange
        })
      ),
      R.pipe(
        R.pick(['date', 'shops']),
        R.evolve({
          date: formatToDb
        })
      )
    ),

    R.merge({ combine: rootGetters.getUserCombineTechsValue })
  )(state)

export const getItemColor = (item, route) =>
  R.cond([
    [
      R.prop('techs'),
      R.pipe(
        R.prop('techs'),
        R.find(R.propEq('id', item.route_tech_id)),
        R.prop('color'),
        R.when(R.isEmpty, R.always(shedulerColors.default))
      )
    ],
    [R.path(['tech', 'color']), R.path(['tech', 'color'])],
    [R.T, R.always(shedulerColors.default)]
  ])(route)

export const getTechShopIds = R.cond([
  [R.prop('techs'), R.pipe(R.prop('techs'), R.map(R.prop('shop_id')))],
  [R.prop('shop_id'), R.pipe(R.prop('shop_id'), R.of)],
  [R.T, R.always([])]
])

export const getTechIds = R.cond([
  [R.prop('techs'), R.pipe(R.prop('techs'), R.map(R.prop('id')))],
  [R.prop('id'), R.pipe(R.prop('id'), id => [id])],
  [R.T, R.always([])]
])

export const checkDraggingRoute = (item, route, list) => {
  if (!item) {
    return true
  }
  if (!route) {
    return false
  }
  if (item.job) {
    const shopIds = getTechShopIds(route)
    if (R.isEmpty(shopIds)) {
      return true
    }
    return R.contains(item.job.shop_id, shopIds)
  }
  // else if (isWaypoint(item)) {
  //   const techIds = getTechIds(tech)
  //   if (R.isEmpty(techIds)) {
  //     return false
  //   }
  //   return R.contains(item.tech_id, techIds)
  // }
}

export const getWeekRoutes = date =>
  R.pipe(
    R.prop('data'),
    R.when(
      R.complement(R.isEmpty),
      R.pipe(R.pickBy(R.propEq('date', date)), routes => {
        const order = sortRoutes(routes)
        return order.map(i => routes[i] || routes[date])
      })
    )
  )

export const getJobs = R.pipe(
  R.prop('data'),
  R.values,
  R.map(R.prop('route_entries')),
  R.unnest,
  R.map(R.prop('job')),
  R.filter(R.identity)
)

export const getRouteMidTimeCounters = R.pipe(getJobs, getJobMidTimes)

export const getTotalJobCount = R.pipe(getJobs, R.length)

export const getRouteContextCounters = R.pipe(getJobs, getJobContextCounts)

export const getRouteItemPartList = R.pipe(
  R.propOr([], 'parts'),
  R.groupBy(R.prop('job_id'))
)

export const getRouteEntryTitle = R.cond([
  [R.propEq('type', 'start'), () => 'Route Start'],
  [R.propEq('type', 'pickup'), () => 'Part pickup'],
  [R.propEq('type', 'appointment'), () => 'Appoinment'],
  [R.T, () => 'Route End']
])

export const getRouteTechColor = R.cond([
  [R.path(['tech', 'color']), R.path(['tech', 'color'])],
  [R.path(['techs', 0, 'color']), R.path(['techs', 0, 'color'])],
  [R.T, R.always(shedulerColors.default)]
])

export const metersToMiles = R.pipe(R.multiply(0.000621371), n => n.toFixed(1))

export const secondsToHours = R.pipe(R.multiply(0.000277778), n =>
  n.toFixed(1)
)

export const getTechsideColors = R.pipe(
  R.prop('data'),
  R.values,
  R.filter(R.prop('techside_username')),
  R.indexBy(R.prop('techside_username')),
  R.map(R.prop('color'))
)

const groupById = (techs) => {
  const groupedList = {}
  techs.forEach(tech => {
    if (!groupedList[tech.id]) {
      groupedList[tech.id] = tech
    }
  });
  return groupedList
}

export const getOrgTechs = orgId =>
  R.pipe(
    R.prop('data'),
    R.values,
    R.pluck('tech'),
    R.filter(R.identity),
    R.filter(R.propEq('organization_id', orgId)),
    groupById,
    R.values,
    R.sortBy(R.path(['name'])),
    R.prepend({ name: 'Unassigned', id: null })
  )

export const removeDisabled = techId => 
  R.filter((v) => !v.is_disabled || v.status === 'active' || v.id === techId)

const VENDOR_COLORS = {
  1: '#00897B',
  2: '#F0AD4E',
  3: '#299FD7',
  5: '#C4C4C4'
}

const VENDOR_TYPES = Object.keys(VENDOR_COLORS)

export const getVendorsTags = (vendors = []) => {
  const rVendors = []
 
  vendors.forEach(v => {
    if (!VENDOR_TYPES.includes(v.gb_vendor_type_id?.toString() || '')) return

    rVendors.push({
      ...v,
      color: VENDOR_COLORS[v.gb_vendor_type_id]
    })
  })

  return rVendors
};
