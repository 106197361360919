var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onBlur,
          expression: "onBlur"
        }
      ],
      ref: "consumerSearchBar",
      staticClass: "consumer-search-bar",
      on: {
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.nextOption($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.prevOption($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.selectOption(_vm.highlight)
          }
        ],
        keyup: _vm.newKeypress
      }
    },
    [
      _c(
        _vm.wrapper,
        {
          tag: "component",
          attrs: {
            placement: "bottom-start",
            trigger: "manual",
            "popper-class": "consumer-search-dropdown",
            focused: _vm.focused
          },
          on: { "close-blur": _vm.closeBlur },
          model: {
            value: _vm.searching,
            callback: function($$v) {
              _vm.searching = $$v
            },
            expression: "searching"
          }
        },
        [
          _c(
            "input-with-label",
            {
              ref: "inputRef",
              attrs: {
                slot: "reference",
                label: _vm.commercial
                  ? "Search Commercial Accounts"
                  : _vm.label,
                size: "normal",
                disabled: !_vm.organization_id && !_vm.organization_ids
              },
              on: { focus: _vm.onFocus },
              slot: "reference",
              model: {
                value: _vm.text,
                callback: function($$v) {
                  _vm.text = $$v
                },
                expression: "text"
              }
            },
            [
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticClass: "search-loading",
                      attrs: { slot: "suffix" },
                      slot: "suffix"
                    },
                    [
                      _c("el-spinner", {
                        attrs: { "stroke-width": 2, radius: 50 }
                      })
                    ],
                    1
                  )
                : _vm.selectedId && _vm.$listeners.clear
                ? _c(
                    "div",
                    {
                      staticClass: "search-clear el-input__icon",
                      attrs: { slot: "suffix" },
                      slot: "suffix"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "gb-action-button",
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clear")
                            }
                          }
                        },
                        [_c("i", { staticClass: "el-icon-circle-close" })]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.searching && _vm.withCommercial
                ? _c(
                    "el-button",
                    {
                      staticClass: "commercial-button",
                      attrs: { slot: "append" },
                      on: { click: _vm.setCommercial },
                      slot: "append"
                    },
                    [
                      _vm._v(" Commercial "),
                      _c("i", {
                        staticClass: "material-icons-outline md-arrow_drop_down"
                      })
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "scroll",
              staticClass: "consumer-search-container",
              on: { scroll: _vm.onScroll }
            },
            [
              _c(
                "el-scrollbar",
                {
                  ref: "scrollbar",
                  attrs: {
                    "wrap-class": "customer-search-list-container",
                    "view-class": "customer-search-list",
                    tag: "ul"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      ref: "showDisabledToggle",
                      attrs: { id: "show-disabled-toggle" },
                      on: {
                        click: function($event) {
                          if ($event.target !== $event.currentTarget) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.toggleDisabled($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.showDisabled ? "Hide" : "Show") +
                          " Disabled "
                      ),
                      _vm.showDisabled
                        ? _c("i", {
                            staticClass: "el-icon-close",
                            staticStyle: { color: "red" }
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm._l(_vm.options, function(item, idx) {
                    return _c(
                      "consumer-search-result",
                      _vm._b(
                        {
                          key: item.id,
                          attrs: {
                            active: idx === _vm.highlight,
                            loading: _vm.loading,
                            selected: _vm.selectedId === item.id,
                            label:
                              item.label ||
                              (!_vm.term &&
                                _vm.extraOptions.length &&
                                idx === _vm.extraOptions.length &&
                                "Search")
                          },
                          nativeOn: {
                            mouseenter: function($event) {
                              _vm.highlight = idx
                            },
                            mouseleave: function($event) {
                              _vm.highlight = -1
                            },
                            click: function($event) {
                              return _vm.selectOption(idx)
                            }
                          }
                        },
                        "consumer-search-result",
                        item,
                        false
                      )
                    )
                  }),
                  _vm.remoteOptions.length < _vm.count
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "observe-visibility",
                              rawName: "v-observe-visibility",
                              value: { callback: _vm.onScroll },
                              expression: "{ callback: onScroll }"
                            }
                          ],
                          staticClass: "loading-more-consumers"
                        },
                        [_vm._v(" Loading more... ")]
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          !_vm.preventNew
            ? _c(
                "div",
                {
                  staticClass: "consumer-add-new",
                  on: { click: _vm.newClick }
                },
                [
                  _c("i", {
                    staticClass: "material-icons-outline md-add md-18"
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.commercial ? "New Commercial Account" : _vm.newLabel
                      ) +
                      " "
                  ),
                  _c("div", { staticClass: "consumer-add-new-key-legend" }, [
                    _c("span", [_vm._v("ALT")]),
                    _vm._v(" + "),
                    _c("span", [_vm._v("N")])
                  ]),
                  _vm.count && _vm.remoteOptions
                    ? _c("div", { staticClass: "consumer-count" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.options.length) +
                            "/" +
                            _vm._s(_vm.count) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }