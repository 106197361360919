var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vendor-profile-techside-settings" },
    [
      _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
        _vm._v(
          " " +
            _vm._s(_vm.isSalesRep ? "Saleside" : "Techside") +
            " Username (Case Sensitive) "
        )
      ]),
      _c("el-input", {
        attrs: {
          placeholder:
            "Enter " + (_vm.isSalesRep ? "Saleside" : "Techside") + " Username"
        },
        on: { change: _vm.updateUsernameHandler },
        model: {
          value: _vm.username,
          callback: function($$v) {
            _vm.username = $$v
          },
          expression: "username"
        }
      }),
      _vm.invitation_status
        ? _c(
            "div",
            [
              _c("el-divider", { staticClass: "t-my-6" }),
              _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.isSalesRep ? "Saleside" : "Techside") +
                    " Invitation Status "
                )
              ]),
              _c("el-input", {
                attrs: { readonly: "", disabled: "" },
                model: {
                  value: _vm.invitation_status,
                  callback: function($$v) {
                    _vm.invitation_status = $$v
                  },
                  expression: "invitation_status"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }