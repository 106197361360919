<template>
  <div class="rates-form">
    <el-collapse v-model="activeNames">
      <el-collapse-item
        v-for="group in schema"
        :key="group.key"
        :name="group.key"
        :title="group.label"
      >
        <el-row :gutter="12" class="rates-group">
          <el-col
            :span="24 / group.fields.length"
            v-for="field in group.fields"
            :key="field.key"
          >
            <gb-editable-data-row
              :value="get(field.key)"
              @edit="set($event.value, field.key)"
              :hideLabel="false"
              size="normal"
              v-bind="field"
            >
            </gb-editable-data-row>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import * as R from 'ramda'

export default {
  props: ['value'],
  data() {
    return {
      activeNames: ['discounts', 'labor'],
      schema: [
        {
          key: 'discounts',
          label: 'Discounts',
          fields: [
            { key: 'discounts.glass', label: 'WS Glass', percent: true, lazy: true, input: { prefix: '%' }, type: 'money' },
            { key: 'discounts.oem', label: 'OEM', percent: true, input: { prefix: '%'}, type: 'money' },
            { key: 'discounts.moulding', label: 'Moulding', percent: true, input: { prefix: '%'}, type: 'money' },
            { key: 'discounts.tempered', label: 'Tempered', percent: true, lazy: true, input: { prefix: '%'}, type: 'money' },
          ]
        },
        {
          key: 'labor',
          label: 'Labor',
          fields: [
            { key: 'labor_flat', label: 'Flat', input: { prefix: '$'}, type: 'money' },
            { key: 'labor_hourly', label: 'Hourly', input: { prefix: '$'}, type: 'money' },
            { key: 'labor_flat_tempered', label: 'Flat (Tempered)', input: { prefix: '$'}, type: 'money' },
            { key: 'labor_hourly_tempered', label: 'Hourly (Tempered)', input: { prefix: '$'}, type: 'money' }
          ]
        },
        {
          key: 'chips',
          label: 'Chips',
          fields: [
            { key: 'chip_first', label: 'First', input: { prefix: '$'}, type: 'money' },
            { key: 'chip_additional', label: 'Additional', input: { prefix: '$'}, type: 'money' }
          ]
        },
        {
          key: 'kits',
          label: 'Kits',
          fields: [
            { key: 'standard_kit', label: 'Standard', type: 'kit-select', input: { prefix: '$'} },
            { key: 'high_kit', label: 'High Modulus', type: 'kit-select', input: { prefix: '$'} },
            { key: 'fast_kit', label: 'Fast Cure', type: 'kit-select', input: { prefix: '$'} }
          ]
        },
        {
          key: 'safelite_recals',
          label: 'Recal SAFELITE',
          fields: [
            { key: 'safelite_recals.static', label: 'Static', input: { prefix: '$'}, type: 'money' },
            { key: 'safelite_recals.dynamic', label: 'Dynamic', input: { prefix: '$'}, type: 'money' },
            { key: 'safelite_recals.dual', label: 'Dual', input: { prefix: '$'}, type: 'money' }
          ]
        },
        {
          key: 'lynx_rtl_recals',
          label: 'Recal LYNX RTL',
          fields: [
            { key: 'lynx_rtl_recals.static', label: 'Static', input: { prefix: '$'}, type: 'money' },
            { key: 'lynx_rtl_recals.dynamic', label: 'Dynamic', input: { prefix: '$'}, type: 'money' },
            { key: 'lynx_rtl_recals.dual', label: 'Dual', input: { prefix: '$'}, type: 'money' }
          ]
        },
        {
          key: 'lynx_dlr_recals',
          label: 'Recal LYNX DLR',
          fields: [
            { key: 'lynx_dlr_recals.static', label: 'Static', input: { prefix: '$'}, type: 'money' },
            { key: 'lynx_dlr_recals.dynamic', label: 'Dynamic', input: { prefix: '$'}, type: 'money' },
            { key: 'lynx_dlr_recals.dual', label: 'Dual', input: { prefix: '$'}, type: 'money' }
          ]
        }
      ]
    }
  },
  methods: {
    get(key) {
      return R.pathOr(0, key.split('.'))(this.value)
    },
    async set(value, key) {
      if (typeof value !== 'object') {
        value = +value
      }
      if (((key === 'discounts.glass' && value !== R.path(key.split('.'))(this.value)) || 
        key === 'discounts.tempered' && value !== R.path(key.split('.'))(this.value)) && 
        await this.$messageBox.confirm(
          `Do you want to also update ${key === 'discounts.glass' ? "tempered": "WS glass"} discount?`,
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning'
          }
        ).catch(()=>{})
      ) {
        const path = key === 'discounts.glass' ? ('discounts.tempered'):('discounts.glass')
        this.$emit('input', R.set(R.lensPath(key.split('.')), value, R.set(R.lensPath(path.split('.')), value, this.value)))
      } else if (((key === 'labor_flat' && value !== R.path(key)(this.value)) || 
        (key === 'labor_hourly' && value !== R.path(key)(this.value)) || 
        (key === 'labor_flat_tempered' && value !== R.path(key)(this.value)) || 
        (key === 'labor_hourly_tempered' && value !== R.path(key)(this.value))) &&
        await this.$messageBox.confirm(
          `Do you want to also update ${key.includes('tempered') ? key.includes('flat') ? "standard flat" : "standard hourly" : key.includes('flat') ? 'tempered flat' : 'tempered hourly'} rate?`,
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning'
          }
        ).catch(()=>{})
      ) {
        let additionalField = key.includes('tempered') ? key.includes('flat') ? 'labor_flat' : 'labor_hourly' : key.includes('flat') ? 'labor_flat_tempered' : 'labor_hourly_tempered'
        this.$emit('input', R.set(R.lensPath([key]), value, R.set(R.lensPath([additionalField]), value, this.value)))
      } else {
        this.$emit('input', R.set(R.lensPath(key.split('.')), value, this.value))
      }
    }
  }
}
</script>
<style lang="scss">
.rates-form {
  .el-collapse {
    border-top: none;
    border-bottom: none;
  }
  .el-collapse-item__header {
    background: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .el-collapse-item__arrow {
      margin-left: 0;
    }
  }
  .el-collapse-item__wrap {
    background: none;
  }
  .rates-group {
    padding: $padding-xs 0;
  }
}
</style>
