<template>
  <el-dialog
    append-to-body
    :visible="isOpen"
    :before-close="handleClose"
    top="50px"
    custom-class="chat-image-view-dialog">
    <img :src="url" class="t-object-contain">
  </el-dialog>
</template>

<script>
import { chatTypes } from '@/store/modules/chat/types'

export default {
  computed: {
    url: function() {
      return this.$store.state.chat.imagePreviewUrl
    },
    isOpen: function() {
      return this.url !== null
    }
  },
  methods: {
    handleClose: function() {
      this.$store.commit(
        chatTypes.namespace + chatTypes.mutations.setImagePreviewUrl,
        null
      )
    }
  }
}
</script>

<style lang="scss">
.chat-image-view-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .el-dialog {
      margin: 0 auto !important;
      height: 90%;
      overflow: hidden;
      .el-dialog__body {
          position: absolute;
          left: 0;
          top: 54px;
          bottom: 0;
          right: 0;
          padding: 0;
          z-index: 1;
          overflow: hidden;
          overflow-y: auto;
      }
  }
}
</style>
