import * as R from 'ramda'
import { mergeSpec } from './ramda.helpers'
import {
  formatToDbWithTimezone,
  formatTimeToDb,
  formatToDb
} from './date.helpers'
import { startOfHour, addHours, getDay } from 'date-fns'
import { repeatOptions } from '@/constants/availability.config'

export const getTechCalEvents = viewCells =>
  R.pipe(
    R.values,
    R.map(
      R.pipe(
        R.propOr([], 'availability_slots'),
        R.map(
          R.pipe(
            mergeSpec({
              actualDate: R.ifElse(
                R.pipe(R.prop('day'), R.isNil, R.not),
                ({ day }) => formatToDb(viewCells[day]),
                R.prop('date')
              )
            }),
            mergeSpec({
              start: ({ start, actualDate }) => `${actualDate} ${start}`,
              end: ({ end, actualDate }) => `${actualDate} ${end}`,
              repeat: ({ repeat }) => R.path([repeat, 'config'])(repeatOptions),
              split: R.prop('gb_vendor_id'),
              allDay: R.prop('all_day')
            })
          )
        )
      )
    ),
    R.unnest
  )

export const getNewEvent = ({ date, split }, copying) => ({
  ...(copying
    ? {
      ...R.dissoc('id', copying),
      ...(copying.day
        ? {
          date: null,
          day: getDay(date)
        }
        : {
          date: formatToDbWithTimezone(date),
          day: null
        })
    }
    : {
      start: formatTimeToDb(startOfHour(date)),
      end: formatTimeToDb(addHours(startOfHour(date), 2)),
      type: 'block',
      job_limit: 0,
      all_day: false,
      repeat: 'none',
      date: formatToDbWithTimezone(date),
      day: null
    }),
  gb_vendor_id: +split
})

export const getSlotRouteEntryCount = ({ start, end, all_day }) =>
  R.pipe(
    R.when(
      () => !all_day,
      R.filter(({ departure, arrival }) => {
        return (
          (departure > start.slice(0, 5) && departure < end.slice(0, 5)) ||
          (arrival > start.slice(0, 5) && arrival < end.slice(0, 5))
        )
      })
    ),
    R.length
  )
