var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.phoneNumber === null
            ? "Create a phone number"
            : "Update a phone number",
        width: "80%",
        visible: _vm.visible,
        "modal-append-to-body": false,
        "before-close": _vm.handleClose
      },
      on: { open: _vm.handleOpen }
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { lg: 18, sm: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "hide-required-asterisk": true,
                    "label-width": "70px",
                    size: "medium",
                    disabled: _vm.disabled
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Name")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "Enter a name" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "sid" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("SID")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "Enter an SID" },
                        model: {
                          value: _vm.form.sid,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sid", $$v)
                          },
                          expression: "form.sid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone_number" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Phone")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "Enter a phone number" },
                        model: {
                          value: _vm.form.phone_number,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phone_number", $$v)
                          },
                          expression: "form.phone_number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mb-2 mr-2",
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.handleSubmit }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.phoneNumber === null ? "Create" : "Update"
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml-0",
                          attrs: { plain: "" },
                          on: { click: _vm.handleClose }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }