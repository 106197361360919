var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "vendor-create-form",
      attrs: { model: _vm.form },
      nativeOn: {
        submit: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c("div", { staticClass: "t-text-gray-400 t-mb-6" }, [
        _vm._v("Vendor Type")
      ]),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "type",
            rules: {
              required: true,
              message: "Please select vendor type"
            }
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "Select vendor type" },
              on: { change: _vm.onCreate },
              model: {
                value: _vm.form.type,
                callback: function($$v) {
                  _vm.$set(_vm.form, "type", $$v)
                },
                expression: "form.type"
              }
            },
            _vm._l(_vm.vendorTypes, function(item, key) {
              return _c(
                "el-option",
                { key: key, attrs: { value: key, label: item.name } },
                [
                  _c("div", { staticClass: "t-flex t-items-center" }, [
                    _c("div", {
                      staticClass: "t-h-3 t-w-3 t-rounded-full",
                      style: { background: item.color }
                    }),
                    _c("div", { staticClass: "t-ml-3" }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c("el-divider", { staticClass: "t-my-6" }),
      _c("div", { staticClass: "t-text-gray-400 t-mb-6" }, [_vm._v("Name")]),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "name",
            rules: {
              required: true,
              message: "Please enter name",
              trigger: "blur"
            }
          }
        },
        [
          _c("el-input", {
            on: { change: _vm.onCreate },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }