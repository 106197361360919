// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".el-dialog.qbo-account-mapping-dialog {\n  border-radius: 0.75rem;\n  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n}\n.el-dialog.qbo-account-mapping-dialog .el-dialog__header {\n  display: none;\n}\n.el-dialog.qbo-account-mapping-dialog .el-dialog__body {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  overflow-y: hidden;\n  padding: 0px;\n}\n.el-dialog.qbo-account-mapping-dialog .el-form-item {\n  margin-bottom: 0px;\n}\n.el-dialog.qbo-account-mapping-dialog .el-form-item__content {\n  line-height: 1;\n}", "",{"version":3,"sources":["/app/<no source>","Index.vue"],"names":[],"mappings":"AAAA;EAAA,sBAAA;EAAA,4NAAA;ACcA;ADdA;EAAA,aAAA;ACiBA;ADjBA;EAAA,oBAAA;EAAA,oBAAA;EAAA,aAAA;EAAA,kBAAA;EAAA,YAAA;ACwBA;ADxBA;EAAA,kBAAA;AC2BA;AD3BA;EAAA,cAAA;AC8BA","file":"Index.vue","sourcesContent":[null,":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.el-dialog.qbo-account-mapping-dialog {\n  border-radius: 0.75rem;\n  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n}\n.el-dialog.qbo-account-mapping-dialog .el-dialog__header {\n  display: none;\n}\n.el-dialog.qbo-account-mapping-dialog .el-dialog__body {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  overflow-y: hidden;\n  padding: 0px;\n}\n.el-dialog.qbo-account-mapping-dialog .el-form-item {\n  margin-bottom: 0px;\n}\n.el-dialog.qbo-account-mapping-dialog .el-form-item__content {\n  line-height: 1;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
