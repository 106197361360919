import { make } from 'vuex-pathify'
import Tech from '@/scripts/objects/tech'
import Salesrep from '@/scripts/objects/salesrep'
import moment from 'moment'
import TextMessageTemplate from '@/scripts/objects/text_message_template'

const state = {
  visible: false,
  shopId: -1,
  inviteType: '',
  inviteId: -1,
  invitePhoneNumber: '',
  history: [],
  mostRecentHistory: [],
  historyOnly: false,
  vendor: false
}

const mutations = make.mutations(state)

const getters = {
  formattedHistory: (state) => {
    let data = []
    for (const i of state.history) {
      data.push(getters.formattedHistoryItem(i))
    }
    return data
  },
  formattedMostRecentHistory: (state) => {
    let mostRecentHistory = null
    if (state.mostRecentHistory.length > 0) {
      mostRecentHistory = getters.formattedHistoryItem(state.mostRecentHistory[0])
    }
    return mostRecentHistory
  },
  formattedHistoryItem: (historyData) => {
    let historyItem = {
      size: 'large',
      timestamp: moment(historyData.created_at).format('LLLL')
    }
    if (historyData.status_name === 'sent') {
      if (historyData.object_type_id === 1) {
        historyItem.name = `A Tech invite request was sent by ${historyData.username}`
      } else {
        historyItem.name = `A Sales Rep invite request was sent by ${historyData.username}`
      }
      historyItem.type = 'warning'
    } else if (historyData.status_name === 'rejected') {
      if (historyData.object_type_id === 1) {
        historyItem.name = `Tech invite request was rejected`
      } else {
        historyItem.name = `Sales Rep invite request was rejected`
      }
      historyItem.type = 'danger'
    } else if (historyData.status_name === 'accepted') {
      if (historyData.object_type_id === 1) {
        historyItem.name = `Tech invite request was accepted`
      } else {
        historyItem.name = `Sales Rep invite request was accepted`
      }
      historyItem.type = 'success'
    }
    return historyItem
  }
}

const actions = {
  open ({ commit }) {
    commit('SET_VISIBLE', true)
  },
  setShopId ({ commit }, shopId) {
    commit('SET_SHOP_ID', shopId)
  },
  setInviteType ({ commit }, inviteType) {
    commit('SET_INVITE_TYPE', inviteType)
  },
  setInviteId ({ commit }, inviteId) {
    commit('SET_INVITE_ID', inviteId)
  },
  setInvitePhoneNumber ({ commit }, invitePhoneNumber) {
    commit('SET_INVITE_PHONE_NUMBER', invitePhoneNumber)
  },
  setHistoryOnly ({ commit }, historyOnly) {
    commit('SET_HISTORY_ONLY', historyOnly)
  },
  setVendor ({ commit }, vendor) {
    commit('SET_VENDOR', vendor)
  },
  async getHistory ({ commit }) {
    var history
    if (state.vendor) {
      history = await TextMessageTemplate.getTextMessageInviteHistory(state.inviteId)
    } else if (state.inviteType === 'salesrep') {
      history = await Salesrep.getTextMessageInviteHistory(state.inviteId)
    } else {
      history = await Tech.getTextMessageInviteHistory(state.inviteId)
    }
    commit('SET_HISTORY', history)
  },
  clearHistory ({ commit }) {
    commit('SET_HISTORY', [])
  },
  async getMostRecentHistory ({ commit }, data) {
    var mostRecentHistory
    if (data && data.vendor) {
      mostRecentHistory = await TextMessageTemplate.getTextMessageInviteHistory(data.objectId, 1)
    } else if (data.objectType === 'salesrep') {
      mostRecentHistory = await Salesrep.getTextMessageInviteHistory(data.objectId, 1)
    } else {
      mostRecentHistory = await Tech.getTextMessageInviteHistory(data.objectId, 1)
    }
    commit('SET_MOST_RECENT_HISTORY', mostRecentHistory)
  },
  clearMostRecentHistory ({ commit }) {
    commit('SET_MOST_RECENT_HISTORY', [])
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
