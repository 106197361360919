var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "consumer-jobs"
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.jobs, border: "", size: "mini" } },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/jobs/" + scope.row.id,
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(scope.row.id))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "status", label: "Status" } }),
          _c("el-table-column", {
            attrs: { prop: "install_date", label: "Install Date" }
          }),
          _c("el-table-column", {
            attrs: { prop: "invoice_date", label: "Invoice Date" }
          }),
          _c("el-table-column", {
            attrs: { prop: "total_balance_after_payments", label: "Balance" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }