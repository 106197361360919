<template>
  <div>
    <b-modal
      id="commercialAccountInfoModal"
      size="lg"
      title="Commercial Account Information"
      header-bg-variant='primary'
      header-text-variant='light'
      @show="dialogAboutToAppear"
      >
      <div class="form-row">
        <div class='form-group col-md-6'>
          <label for='accountName'>Company Name</label>
          <input id='accountName' type='text' class='form-control form-control-sm autocomplete-off' v-model="customer.name">
        </div>
        <div class="col-md-6 mt-4">
          <CheckBoxLabel label="FLAG CUSTOMER" :value.sync="customer.flagged_customer" />
          <CheckBoxLabel label="TAX EXEMPT"  :value.sync="customer.commercial_account_tax_exempt"/>
          <CheckBoxLabel label="PO REQUIRED"  :value.sync="customer.po_required"/>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <Phone ref='phone1Component' :title="'Phone 1'" :value.sync='customer.phone1' :placeholder="''" :required='false' />
        </div>
        <div class="form-group col-md-4">
          <Phone ref='phone2Component' :title="'Phone 2'" :value.sync='customer.phone2' :placeholder="''" :required='false' />
        </div>
        <div class='form-group col-md-4'>
          <Phone ref='faxComponent' :title="'Fax'" :value.sync="customer.fax" :placeholder="''" :required='false'/>
        </div>
      </div>
      <div class='form-row'>
        <div v-if='!showInput' class="form-group col-md-8">
          <label for='customerEmail'>Email -- Default Set To: {{selectedEmail}}</label>
          <select id='customerEmail' class="form-control form-control-sm" v-model='selectedEmail'>
              <option v-for='(email, index) in customerEmails' :key='index' :value='email.value'>{{email.value}}</option>
          </select>
        </div>
        <div v-else class="form-group col-md-8">
          <Email :title='"Email"' id='customerEmail' type="text" :value.sync="emailToBeAdded" :inIsValid.sync="isEmailValid" :required='false' />
        </div>
        <div class="form-group col-md-1">
            <label for='addCustomerEmail' class="d-sm-block d-md-none">Add Email</label>
            <label for='addCustomerEmail' class="d-none d-md-block">&nbsp;</label>
          <span v-b-popover.top.hover="inputShowing ? 'Add Entered Email':'Add New Email'" >
            <b-button @click='addEmail' variant="outline-success" class="form-control form-control-sm" id="addCustomerEmail"><font-awesome-icon :icon="faPlus"/></b-button>
          </span>
        </div>
        <div class="form-group col-md-1">
          <label for='deleteCustomerEmail' class="d-sm-block d-md-none">Delete Email</label>
          <label for='deleteCustomerEmail' class="d-none d-md-block">&nbsp;</label>
          <span v-b-popover.top.hover="inputShowing ? 'Clear Input/Go Back To List':'Delete This Commercial Account Email'" >
            <b-button @click='deleteEmail' variant="outline-danger" class="form-control form-control-sm" id="deleteCustomerEmail"><font-awesome-icon :icon="faTrashAlt"/></b-button>
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4"><label for="commercialCustomerLastName">Contact Last Name</label><input id="commercialCustomerLastName" type="text" class="form-control form-control-sm autocomplete-off" v-model="customer.contact_last_name"></div>
        <div class="form-group col-md-4"><label for="commercialCustomerFirstName">Contact First Name</label><input id="commercialCustomerFirstName" type="text" class="form-control form-control-sm autocomplete-off" v-model="customer.contact_first_name"></div>
        <div class="form-group col-md-4"><label for="commercialCustomerMiddleName">Contact Middle Name</label><input id="commercialCustomerMiddleName" type="text" class="form-control form-control-sm autocomplete-off" v-model="customer.contact_middle_name"></div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6"><label for="commercialCustomerAddress">Address</label><address-input v-model="customer.g_address" full popper-fix :loading="addressLoading" /></div>
        <div class="form-group col-md-6">
          <label for="commercialDefaultLocation">Default install location</label>
          <address-input v-model="installLocationAddress" full popper-fix />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="defaultPriceLevel">Default Price Level</label>
          <select id="defaultPriceLevel" v-model='customer.default_pricelevel_id' class="form-control form-control-sm">
            <option :value="null">Choose...</option>
            <option v-for="priceLevel in priceLevels" :key="priceLevel.id" v-bind:value="priceLevel.id">{{priceLevel.price_level_name}}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="defaultPriceLevel">Insurance/Fleet</label>
          <select id="defaultPriceLevel" v-model='customer.insurancefleet_id' class="form-control form-control-sm">
            <option :value="null">Choose...</option>
            <option v-for="insuranceFleet in insuranceFleets" :key="insuranceFleet.id" v-bind:value="insuranceFleet.id">{{insuranceFleet.name}}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="defaultPriceLevel">Default Sales Rep</label>
          <select id="defaultPriceLevel" v-model='customer.default_salesrep_id' class="form-control form-control-sm">
            <option :value="null">Choose...</option>
            <option v-for="salesrep in salesreps" :key="salesrep.id" v-bind:value="salesrep.id">{{salesrep.name}}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="defaultInvoiceTier">Default Invoice Tier</label>
          <select id="defaultInvoiceTier" v-model='customer.default_invoice_tier' class="form-control form-control-sm">
            <option :value="null">Choose...</option>
            <option v-for="option in invoiceTierOptions" :key="option.value" v-bind:value="option.value">{{option.text}}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="Notes">Notes</label>
          <b-form-textarea  id='notes'
                            v-model='customer.notes'
                            placeholder='Enter customer notes...'
                            :rows="5"
                            :max-rows="5">
          </b-form-textarea>
        </div>
      </div>
      <div class="row">
        <div class='form-group col mt-2'>
          <b v-b-toggle.commercialaccountRatesCollapse style="vertical-align:middle; cursor:pointer;">RATE INFO:</b>
          <span>&nbsp;&nbsp;</span>
          <v-icon v-if='!isRateInfoOpen' class='caret' scale='1.5' name='caret-left' style="vertical-align:middle;"/>
          <v-icon v-if='isRateInfoOpen' class='caret' scale='1.5' name='caret-down' style="vertical-align:middle;"/>
        </div>
      </div>
      <b-collapse id="commercialaccountRatesCollapse" v-model="isRateInfoOpen">
        <div class="row">
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Glass Discount'"
            :value.sync="customer.discount"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'% '"/>
          </div>
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Moulding Discount'"
            :value.sync="customer.moulding_discount"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'% '"/>
          </div>
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Hourly Labor'"
            :value.sync="customer.labor_hourly"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Flat Labor'"
            :value.sync="customer.labor_flat"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 col-md-6 col-lg-3">
            <FloatInput
            :title="'Oem Glass Discount'"
            :value.sync="customer.oem_discount"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'% '"/>
          </div>
          <div class='form-group col-sm-12 col-md-12 col-lg-3'>
            <label for='kitStandard'>Kit: Standard</label>
            <FloatDropdown
            :dropdownOptions="[{'value':'each', 'label':'Each', 'dropdownLabel':'Each'}, {'value':'flat', 'label':'Flat', 'dropdownLabel':'Flat'}]"
            :value.sync="customer.kit_standard"
            :dropdownValue.sync="customer.kit_charge_type_standard"
            :formattedPrefix="'$ '" />
          </div>
          <div class='form-group col-sm-12 col-md-12 col-lg-3'>
            <label for='kitStandard'>Kit: High Modulous</label>
            <FloatDropdown
            :dropdownOptions="[{'value':'each', 'label':'Each', 'dropdownLabel':'Each'}, {'value':'flat', 'label':'Flat', 'dropdownLabel':'Flat'}]"
            :value.sync="customer.kit_high"
            :dropdownValue.sync="customer.kit_charge_type_high"
            :formattedPrefix="'$ '" />
          </div>
          <div class='form-group col-sm-12 col-md-12 col-lg-3'>
            <label for='kitStandard'>Kit: Fast Cure</label>
            <FloatDropdown
            :dropdownOptions="[{'value':'each', 'label':'Each', 'dropdownLabel':'Each'}, {'value':'flat', 'label':'Flat', 'dropdownLabel':'Flat'}]"
            :value.sync="customer.kit_fast"
            :dropdownValue.sync="customer.kit_charge_type_fast"
            :formattedPrefix="'$ '" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6 col-md-6">
            <FloatInput
            :title="'First Chip'"
            :value.sync="customer.chip_first"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
          <div class="form-group col-sm-12 col-md-6">
            <FloatInput
            :title="'Additional Chips'"
            :value.sync="customer.chip_additional"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
        </div>
        <div class="row">
          <div class='form-group col mt-2'>
            <b>RECAL:</b>
          </div>
        </div>
        <div class="row">
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Safelite Dynamic'"
            :value.sync="customer.recal_safelite_dynamic"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Safelite Static'"
            :value.sync="customer.recal_safelite_static"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Safelite Dual'"
            :value.sync="customer.recal_safelite_dual"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Lynx RTL Dynamic'"
            :value.sync="customer.recal_lynx_rtl_dynamic"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
        </div>
        <div class="row">
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Lynx RTL Static'"
            :value.sync="customer.recal_lynx_rtl_static"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Lynx RTL Both'"
            :value.sync="customer.recal_lynx_rtl_both"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Lynx DLR Dynamic'"
            :value.sync="customer.recal_lynx_dlr_dynamic"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Lynx DLR Static'"
            :value.sync="customer.recal_lynx_dlr_static"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
        </div>
        <div class="row">
          <div class='form-group col-sm-12 col-md-6 col-lg-3'>
            <FloatInput
            :title="'Lynx DLR Both'"
            :value.sync="customer.recal_lynx_dlr_both"
            :minimumDecimalPlaces="2"
            :maximumDecimalPlaces="2"
            :formattedPrefix="'$ '"/>
          </div>
        </div>
      </b-collapse>
      <div v-if="customer.commercial_account_tax_exempt != '1'">
        <label for="DefaultCommercialAccountTaxes">Default Taxes</label>
        <table class="table table-sm table-less-padding">
          <thead>
            <tr>
              <th style="text-align:center col-md-1" scope="col">Default</th>
              <th style="text-align:center col-md-2" scope="col">Name</th>
              <th style="text-align:center col-md-2" scope="col">Percentage</th>
              <th style="text-align:center col-md-1" scope="col">Materials</th>
              <th style="text-align:center col-md-1" scope="col">Labor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tax, index) in shopTaxes" :key="index">
              <td scope="row">
                <b-form-checkbox :id="'taxDefault-'+index" v-model="defaultTaxes" :value="tax.id"></b-form-checkbox>
              </td>
              <td scope="row">{{tax.name}}</td>
              <td scope="row">{{tax.percentage}}</td>
              <td scope="row">
                <i v-if='tax.for_materials == "1"' class='fa fa-check isYes'/>
              </td>
              <td scope="row">
                <i v-if='tax.for_labor == "1"' class='fa fa-check isYes'/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div slot="modal-footer" class='w-100'>
        <div class='float-right'>
          <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="saveCustomer">Save</button>
          <button type='button' class='btn btn-secondary' variant="primary" @click="cancelEdits">Cancel</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus'
import faTrashAlt from '@fortawesome/fontawesome-free-solid/faTrashAlt'
import Commercialaccount from '@/scripts/objects/commercialaccount'
import CommercialAccountDefaultTax from '@/scripts/objects/commercial_account_default_tax'
import Tax from '@/scripts/objects/tax'
import Email from '@/components/Helpers/Email.vue'
import Phone from '@/components/Helpers/Phone.vue'
import { ValidateComponents, ResetValidateComponents, StateObjects } from '@/scripts/helpers/helpers.js'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import CheckBoxLabel from '@/components/Helpers/CheckBoxLabel'
import * as R from 'ramda'
import FloatInput from '@/components/Helpers/FloatInput.vue'
import FloatDropdown from '@/components/Helpers/FloatDropdown'

export default {
  props: ['show', 'parentCustomer', 'priceLevels', 'shopId', 'insuranceFleets', 'salesreps'],
  data: function () {
    return {
      states: StateObjects,
      inputShowing: true,
      emailToBeAdded: '',
      isEmailValid: false,
      emailsToBeAdded: [],
      emailsToBeDeleted: [],
      selectedEmail: '',
      customer: {},
      validateInputs: [],
      shopTaxes: [],
      defaultTaxes: [],
      taxesChanged: false,
      addressLoading: false,
      isRateInfoOpen: false,
      invoiceTierOptions: [
        { value: 'dor', text: 'Due on receipt' }, 
        { value: 'net15', text: 'Net 15' }, 
        { value: 'net20', text: 'Net 20' }, 
        { value: 'net20_500', text: 'Net 20 $500' }, 
        { value: 'net30', text: 'Net 30' }, 
        { value: 'net45', text: 'Net 45' }, 
        { value: 'net60', text: 'Net 60' }, 
        { value: 'net90', text: 'Net 90' }
      ]
    }
  },
  watch: {
    mounted: function () {
      this.validateInputs = [ this.$refs.phone1Component, this.$refs.phone2Component, this.$refs.faxComponet ]
    },
    // show: function (newVal) {
    //   if (newVal === true) {
    //     this.$refs.commercialAccountInfoModal.show()
    //   } else {
    //     this.$refs.commercialAccountInfoModal.hide()
    //   }

    //   if (this.customerEmails.length > 0) {
    //     if (this.customerEmails[0].value === '') {
    //       this.inputShowing = true
    //     } else if (this.customer.default_email.length > 1) {
    //       this.inputShowing = false
    //       this.selectedEmail = this.customer.default_email
    //     } else {
    //       this.inputShowing = false
    //       this.selectedEmail = this.customerEmails[0].value
    //     }
    //   } else {
    //     this.inputShowing = true
    //   }
    // }
    'parentCustomer': function (val) {
      this.customer = new Commercialaccount(this.parentCustomer)
      this.shopTaxes = []
      this.defaultTaxes = []
    },
    'defaultTaxes': function (val, oldVal) {
      let compIndex = (val.length >= oldVal.length) ? val.length : oldVal.length
      for (var b = 0; b < compIndex; b++) {
        let newTax = val[b]
        let isNewInOld = oldVal.includes(newTax)
        let oldTax = oldVal[b]
        let isOldInNew = val.includes(oldTax)
        if (!isNewInOld && newTax !== undefined) {
          this.taxesChanged = true
        }
        if (!isOldInNew && oldTax !== undefined) {
          this.taxesChanged = true
        }
      }
    }
  },
  methods: {
    dialogAboutToAppear: async function () {
      var self = this
      this.customer = new Commercialaccount(this.parentCustomer)
      this.emailsToBeAdded = []
      this.emailsToBeDeleted = []
      this.selectedEmail = ''
      this.isRateInfoOpen = false
      ResetValidateComponents(this.validateInputs)
      if (this.customerEmails.length > 0) {
        if (this.customerEmails[0].value === '') {
          this.inputShowing = true
        } else if (this.customer.default_email.length > 1) {
          this.inputShowing = false
          this.selectedEmail = this.customer.default_email
        } else {
          this.inputShowing = false
          this.selectedEmail = this.customerEmails[0].value
        }
      } else {
        this.inputShowing = true
      }
      self.shopTaxes = []
      Tax.getAll(this.shopId, function (taxes) {
        for (var i = 0; i < taxes.length; i++) {
          if (taxes[i].status === 'active' && !taxes[i].colorado_tax) {
            self.shopTaxes.push(taxes[i])
          }
        }
      })
      CommercialAccountDefaultTax.getAll(self.customer.id, function (taxes) {
        for (var j = 0; j < taxes.length; j++) {
          self.defaultTaxes.push(taxes[j].tax_id)
        }
      })
      this.addressLoading = true
      await this.customer.checkGetAddress()
      this.addressLoading = false
    },
    saveCustomer: function (event) {
      // commercialaccount.update(this.customer, function () {      // }, function (error) {

      // })

      // event.preventDefault()

      // this.$emit('hide')
      if (ValidateComponents(this.validateInputs)) {
        let self = this
        // we will call this to see if
        if (this.emailToBeAdded.length > 0) {
          if (!this.addEmail()) {
            return
          }
        }
        this.customer.email = this.customerEmails.map(e => e.value).join('#~#/')
        this.customer.default_email = this.selectedEmail
        this.customer.createUpdateCommercialAccount(this.shopId, function (object) {
          self.customer = new Commercialaccount(object)
          if (self.parentCustomer instanceof Commercialaccount) {
            self.$root.$emit('updateCommercialAccount', self.customer)
          } else {
            self.$root.$emit('updateSelectCommercialAccount', self.customer)
          }

          self.$emit('updateSelectedCustomer', 'commercial', self.customer)
          self.$root.$emit('bv::hide::modal', 'commercialAccountInfoModal')

          // we should emit this to the job too to update the information
        }, function () {

        })

        CommercialAccountDefaultTax.updateDefaultTaxes(this.customer.id, this.shopId, this.defaultTaxes, function (res) {
          if (res.message === 'success') {
            console.log('commercial account default taxes updated')
          } else {
            console.log('failed to updated commercial account default taxes')
          }
        })

        if (this.taxesChanged && this.customer.commercial_account_tax_exempt != '1') {
          if (confirm('Would you like to apply these changes to current job?')) {
            this.$root.$emit('clearJobTaxes')
            for (var j = 0; j < this.defaultTaxes.length; j++) {
              this.$root.$emit('updateJobTax', {shopTaxId: this.defaultTaxes[j], isAdd: true})
            }
          } else {
            console.log('Not applying taxes')
          }
        }
      }
    },
    cancelEdits: function (event) {
      this.$root.$emit('bv::hide::modal', 'commercialAccountInfoModal')
    },
    onHidden: function () {
      this.emailToBeAdded = ''
      this.emailsToBeAdded = []
      this.emailsToBeDeleted = []
      this.$root.$emit('bv::hide::modal', 'commercialAccountInfoModal')
    },
    addEmail: function () {
      if (this.showInput) {
        if (this.isEmailValid) {
          this.emailsToBeAdded.push(this.emailToBeAdded)
          setTimeout(this.updateSelectedEmail(this.emailToBeAdded), 500)

          this.emailToBeAdded = ''
          this.inputShowing = false
          return true
        } else {
          alert('Please enter valid Email to add to customer or clear it using the delete/clear button.')
          return false
        }
      } else {
        this.inputShowing = true
        this.emailToBeAdded = ''
        return true
      }
    },
    deleteEmail: function () {
      if (this.inputShowing) {
        // input is showing so let's just clear the input
        this.emailToBeAdded = ''
        if (this.customerEmails.length > 0) {
          if (this.customerEmails[0].value !== '') {
            this.inputShowing = false
          }
        }
      } else {
        // we have a email selected in the dropdown... so let's delete it
        for (var i = 0; i < this.customerEmails.length; ++i) {
          if (this.customerEmails[i].value === this.selectedEmail) {
            this.emailsToBeDeleted.push(this.customerEmails[i].value)
          }
        }
        // if we've deleted all the emails, clear the default as well
        if (!this.customerEmails.length) {
          this.selectedEmail = ''
        }
      }
    },
    updateSelectedEmail: function (newSelection) {
      this.selectedEmail = newSelection
    },
    updateInputShowing: function (newValue) {
      this.inputShowing = newValue
    }
  },
  computed: {
    faPlus () {
      return faPlus
    },
    faTrashAlt () {
      return faTrashAlt
    },
    Email () {
      return Email
    },
    customerEmails: {
      get () {
        if (this.customer.email || this.emailsToBeAdded) {
          if (this.customer.email.split('#~#/').length > 0) {
            var temp = this.customer.email.split('#~#/')

            var returnArray = []
            for (var i = 0; i < temp.length; ++i) {
              returnArray.push({name: temp[i], value: temp[i]})
            }

            // now add any extras to be added on save
            for (var j = 0; j < this.emailsToBeAdded.length; ++j) {
              returnArray.push({name: this.emailsToBeAdded[j], value: this.emailsToBeAdded[j]})
            }

            // let's clear out if we actually have emails and a first entry of ''
            if (returnArray.length > 1 && returnArray[0].value === '') {
              returnArray.shift()
            }

            // now that we have added any new ones and removed the starting '' is there we can remove any.
            // in the future we should probably keep from adding deleted emails to the return array initially here
            // rather than adding and then going through to remove.
            for (var h = 0; h < this.emailsToBeDeleted.length; ++h) {
              for (var f = 0; f < returnArray.length; ++f) {
                if (this.emailsToBeDeleted[h] === returnArray[f].value) {
                  // remove IT!
                  returnArray.splice(f, 1)
                  f = returnArray.length
                }
              }
            }

            // before we get out of here we need to make sure we didn't get rid of all the emails... if we did then we need to
            // show the input... we also need to make sure that we 'select' a new email if there are others left
            if (returnArray.length === 0) {
              this.updateInputShowing(true)
            } else {
              // we need to see if there is the currently selected email
              var found = false
              for (var z = 0; z < returnArray.length; ++z) {
                if (returnArray[z].value === this.selectedEmail) {
                  found = true
                }
              }

              // if not found then set to first
              if (!found) {
                this.updateSelectedEmail(returnArray[0].value)
              }
            }

            return returnArray
          }
        } else {
          return []
        }
      },
      set (val) {
      }
    },
    showInput: {
      get () {
        if (this.inputShowing) {
          return true
        } else if (this.customerEmails.length > 0) {
          if (this.customerEmails[0].value === '') {
            // this.inputShowing = true
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    },
    commercialZip: {
      get () {
        return this.customer.zip
      },
      set (val) {
        let self = this

        this.zipLookup(val, function (data) {
          self.customer.city = data.city
          self.customer.state = data.state_short
        })
        self.customer.zip = val
      }
    },
    installLocationAddress: {
      get() {
        return R.path(['customer', 'install_location', 'g_address'])(this)
      },
      set(address) {
        this.customer.install_location = {
          ...this.customer.install_location,
          status: 'active',
          name: `${this.customer.name} default location`,
          g_address: address
        }
      }
    }
  },
  components: {
    FontAwesomeIcon,
    // faPlus,
    // faTrashAlt,
    Email,
    Phone,
    CheckBoxLabel,
    FloatInput,
    FloatDropdown
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped>

</style>
