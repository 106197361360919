import ObjectTracker from '@/scripts/objects/objectTracker'

export default class Email extends ObjectTracker {
  id = -1
  email = ''
  shop_id = -1
  created = -1
  modified = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }
}
