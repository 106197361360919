<template>
  <el-dialog
    :visible.sync="open"
    width="600px"
    destroy-on-close
    custom-class="gb-el-dialog consumer-dialog"
    top="24px"
    append-to-body
    title="Admin Utils"
    v-if="$role.can('admin')"
  >
    <fix-phone-numbers />
    <div class="t-border-0 t-border-t t-border-solid t-border-gray-200 t-my-2" />
    <fake-job-data />
    <div class="t-border-0 t-border-t t-border-solid t-border-gray-200 t-my-2" />
    <DecodeVins :visible="visible" />
    <div class="t-border-0 t-border-t t-border-solid t-border-gray-200 t-my-2" />
    <find-job-shop />
    <div class="t-border-0 t-border-t t-border-solid t-border-gray-200 t-my-2" />
    <import-customers :filePickerKey="filePickerKey" />
  </el-dialog>
</template>

<script>
import FixPhoneNumbers from './FixPhoneNumbers.vue'
import FakeJobData from './FakeJobData.vue'
import DecodeVins from './DecodeVins.vue'
import FindJobShop from './FindJobShop.vue'
import ImportCustomers from './ImportCustomers.vue'

export default {
  components: {
    FixPhoneNumbers,
    FakeJobData,
    DecodeVins,
    FindJobShop,
    ImportCustomers
  },
  props: {
    visible: Boolean,
    filePickerKey: String
  },
  computed: {
    open: {
      get() {
        return this.visible
      },
      set(v) {
        this.$emit('update:visible', v)
      }
    }
  }
}
</script>
