<template>
  <div class="t-pb-4">
    <el-table
      :data="parts"
      class="t-w-full"
      size="small"
      :show-header="false"
      empty-text="No parts found">
      <el-table-column
        label="Part number"
        width="150">
        <template slot-scope="scope">
          <span class="t-text-indigo-800">
            <span>{{ scope.row.industryCode }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="plantName"
        label="Warehouse"
        width="150">
      </el-table-column>
      <el-table-column
        v-if="user.admin === 1"
        prop="plantCode"
        label="Warehouse ID"
        width="100">
      </el-table-column>
      <el-table-column
        label="Delivery">
        <template slot-scope="scope">
          <i class="el-icon-truck"></i>
          <span class="t-ml-1">{{ formatDeliveryDate(scope.row.leadTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="quantityAvailable"
        label="Quantity"
        width="80">
      </el-table-column>
      <el-table-column
        label="Price"
        width="80">
        <template slot-scope="scope">
          ${{ scope.row.unitPrice }}
        </template>
      </el-table-column>
      <el-table-column align="center" width="80">
        <template slot-scope="scope">
          <button
            v-if="scope.row.quantityAvailable > 0"
            @click="add(scope.row)"
            class="t-cursor-pointer t-text-purple-600 t-px-2 t-pt-1 t-pb-0
                   t-border t-border-solid t-border-purple-400 t-rounded-full
                   t-bg-white hover:t-text-white hover:t-border-purple-600
                   hover:t-bg-purple-600"
          >
            <i class="el-icon-shopping-cart-2 t-text-base"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>
    <add-part-to-cart
      :visible="addPartToCartOpen"
      :part="selectedPart"
      :vendor="'pilkington'"
      :shopId="shopId"
      :jobId="jobId"
      :status="status"
      :warehouse="warehouse"
      :username="username"
      @refresh-data="$emit('refresh-data')"
      @close="addPartToCartOpen = false"
      @addAndLinkPart="addAndLinkPart($event)"
    />
  </div>
</template>

<script>
import AddPartToCart from '../AddPartToCart'

export default {
  props: ['parts', 'shopId', 'jobId', 'status', 'warehouse', 'username'],
  data: () => ({
    addPartToCartOpen: false,
    selectedPart: null
  }),
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    formatDeliveryDate (leadTime) {
      let delivery
      if (leadTime === '0') {
        delivery = 'Next run'
      } else if (leadTime === 'X') {
        delivery = 'No route found'
      } else if (parseInt(leadTime) > 0) {
        delivery = `In ${leadTime} day(s)`
      } else {
        delivery = 'No lead time'
      }
      return delivery
    },
    async add (part) {
      this.selectedPart = part
      this.addPartToCartOpen = true
    },
    addAndLinkPart (cartItemId) {
      this.$emit('addAndLinkPart', cartItemId)
    }
  },
  components: {
    AddPartToCart
  }
}
</script>
