var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex" },
    [
      _c("Button", {
        attrs: {
          type: _vm.type,
          loading: _vm.loading,
          total: _vm.quantity,
          price: _vm.price
        },
        on: { clicked: _vm.openDialog }
      }),
      _vm.data && _vm.dialogData
        ? _c(
            _vm.dialogComponent,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  standalone: true,
                  shopId: _vm.shopId,
                  warehouse: _vm.warehouse
                },
                on: {
                  close: function($event) {
                    _vm.open = false
                  },
                  add: _vm.addPart,
                  addPart: _vm.addPart,
                  recheckVendor: _vm.reFetchData,
                  "refresh-data": function($event) {
                    return _vm.$emit("refresh-data")
                  }
                }
              },
              "component",
              _vm.dialogData,
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }