<template>
  <div class="card-form t-mb-4">
    <div class="card-list">
      <div class="card-item" :class="{ '-active': isCardFlipped }">
        <div class="card-item__side -front">
          <div
            class="card-item__focus"
            :class="{ '-active': focusElementStyle }"
            :style="focusElementStyle"
            ref="focusElement"
          />
          <div class="card-item__cover">
            <img
              :src="require('./images/' + currentCardBackground + '.jpeg')"
              class="card-item__bg"
              alt=""
            />
          </div>

          <div class="card-item__wrapper">
            <div class="card-item__top">
              <img
                :src="require('./images/chip.png')"
                class="card-item__chip"
                alt=""
              />
              <div class="card-item__type">
                <transition name="slide-fade-up">
                  <img
                    :src="require('./images/' + getCardType + '.png')"
                    v-if="getCardType"
                    :key="getCardType"
                    alt=""
                    class="card-item__typeImg"
                  />
                </transition>
              </div>
            </div>
            <label for="cardNumber" class="card-item__number" ref="cardNumber">
              <template v-if="getCardType === 'amex'">
                <span v-for="(n, $index) in amexCardMask" :key="$index">
                  <transition name="slide-fade-up">
                    <div
                      class="card-item__numberItem"
                      v-if="
                        $index > 4 &&
                        $index < 14 &&
                        number.length > $index &&
                        n.trim() !== ''
                      "
                    >
                      *
                    </div>
                    <div
                      class="card-item__numberItem"
                      :class="{ '-active': n.trim() === '' }"
                      :key="$index"
                      v-else-if="number.length > $index"
                    >
                      {{ number[$index] }}
                    </div>
                    <div
                      class="card-item__numberItem"
                      :class="{ '-active': n.trim() === '' }"
                      v-else
                      :key="$index + 1"
                    >
                      {{ n }}
                    </div>
                  </transition>
                </span>
              </template>

              <template v-else>
                <span v-for="(n, $index) in otherCardMask" :key="$index">
                  <transition name="slide-fade-up">
                    <div
                      class="card-item__numberItem"
                      v-if="
                        $index > 4 &&
                        $index < 15 &&
                        number.length > $index &&
                        n.trim() !== ''
                      "
                    >
                      *
                    </div>
                    <div
                      class="card-item__numberItem"
                      :class="{ '-active': n.trim() === '' }"
                      :key="$index"
                      v-else-if="number.length > $index"
                    >
                      {{ number[$index] }}
                    </div>
                    <div
                      class="card-item__numberItem"
                      :class="{ '-active': n.trim() === '' }"
                      v-else
                      :key="$index + 1"
                    >
                      {{ n }}
                    </div>
                  </transition>
                </span>
              </template>
            </label>
            <div class="card-item__content">
              <label for="cardName" class="card-item__info" ref="cardName">
                <div class="card-item__holder">Card Holder</div>
                <transition name="slide-fade-up">
                  <div class="card-item__name" v-if="name.length" key="1">
                    <transition-group name="slide-fade-right">
                      <span
                        class="card-item__nameItem"
                        v-for="(n, $index) in name.replace(/\s\s+/g, ' ')"
                        :key="$index + 1"
                      >
                        {{ n }}
                      </span>
                    </transition-group>
                  </div>
                  <div class="card-item__name" v-else key="2">Full Name</div>
                </transition>
              </label>
              <div class="card-item__date" ref="cardDate">
                <label for="cardMonth" class="card-item__dateTitle">
                  Expires
                </label>
                <label for="cardMonth" class="card-item__dateItem">
                  <transition name="slide-fade-up">
                    <span v-if="month" :key="month">
                      {{ month }}
                    </span>
                    <span v-else key="2">MM</span>
                  </transition>
                </label>
                /
                <label for="cardYear" class="card-item__dateItem">
                  <transition name="slide-fade-up">
                    <span v-if="year" :key="year">
                      {{ String(year).slice(2, 4) }}
                    </span>
                    <span v-else key="2">YY</span>
                  </transition>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-item__side -back">
          <div class="card-item__cover">
            <img
              :src="require('./images/' + currentCardBackground + '.jpeg')"
              class="card-item__bg"
              alt=""
            />
          </div>
          <div class="card-item__band"></div>
          <div class="card-item__cvv">
            <div class="card-item__cvvTitle">CVV</div>
            <div class="card-item__cvvBand">
              <span v-for="(n, $index) in cvv" :key="$index"> * </span>
            </div>
            <div class="card-item__type">
              <img
                :src="require('./images/' + getCardType + '.png')"
                v-if="getCardType"
                class="card-item__typeImg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-form__inner">
      <div class="card-input">
        <label for="cardNumber" class="card-input__label">Card Number</label>
        <input
          type="text"
          id="cardNumber"
          class="card-input__input"
          v-mask="generateCardNumberMask"
          :value="number"
          @input="$emit('update:number', $event.target.value)"
          @focus="focusInput"
          @blur="blurInput"
          data-ref="cardNumber"
          autocomplete="off"
        />
      </div>
      <div class="card-input">
        <label for="cardName" class="card-input__label">Card Holders</label>
        <input
          type="text"
          id="cardName"
          class="card-input__input"
          :value="name"
          @input="$emit('update:name', $event.target.value)"
          @focus="focusInput"
          @blur="blurInput"
          data-ref="cardName"
          autocomplete="off"
        />
      </div>
      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-form__group">
            <label for="cardMonth" class="card-input__label">
              Expiration Date
            </label>
            <select
              class="card-input__input -select"
              id="cardMonth"
              :value="month"
              @input="$emit('update:month', $event.target.value)"
              @focus="focusInput"
              @blur="blurInput"
              data-ref="cardDate"
            >
              <option value="" disabled selected>Month</option>
              <option
                :value="n < 10 ? '0' + n : n"
                v-for="n in 12"
                :disabled="n < minCardMonth"
                :key="n"
              >
                {{ n < 10 ? "0" + n : n }}
              </option>
            </select>
            <select
              class="card-input__input -select"
              id="cardYear"
              :value="year"
              @input="$emit('update:year', $event.target.value)"
              @focus="focusInput"
              @blur="blurInput"
              data-ref="cardDate"
            >
              <option value="" disabled selected>Year</option>
              <option
                :value="$index + minCardYear"
                v-for="(n, $index) in 12"
                :key="n"
              >
                {{ $index + minCardYear }}
              </option>
            </select>
          </div>
        </div>
        <div class="card-form__col -cvv">
          <div class="card-input">
            <label for="cardCvv" class="card-input__label">CVV</label>
            <input
              type="text"
              class="card-input__input"
              id="cardCvv"
              v-mask="'####'"
              maxlength="4"
              :value="cvv"
              @input="$emit('update:cvv', $event.target.value)"
              @focus="flipCard(true)"
              @blur="flipCard(false)"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="card-form__col -zip">
          <div class="card-input">
            <label for="cardZip" class="card-input__label">Zipcode</label>
            <input
              type="text"
              class="card-input__input"
              id="cardZip"
              maxlength="6"
              :value="zip"
              @input="$emit('update:zip', $event.target.value)"
              @focus="focusInput"
              @blur="blurInput"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'number', 'month', 'year', 'cvv', 'zip'],
  data: () => ({
    currentCardBackground: Math.floor(Math.random() * 25 + 1),
    minCardYear: new Date().getFullYear(),
    amexCardMask: '#### ###### #####',
    otherCardMask: '#### #### #### ####',
    cardNumberTemp: '',
    isCardFlipped: false,
    focusElementStyle: null,
    isInputFocused: false
  }),
  mounted() {
    this.cardNumberTemp = this.otherCardMask
    document.getElementById('cardNumber').focus()
  },
  computed: {
    getCardType() {
      const number = this.number
      let re = new RegExp('^4')
      if (number.match(re) != null) return 'visa'

      re = new RegExp('^(34|37)')
      if (number.match(re) != null) return 'amex'

      re = new RegExp('^5[1-5]')
      if (number.match(re) != null) return 'mastercard'

      re = new RegExp('^6011')
      if (number.match(re) != null) return 'discover'

      re = new RegExp('^9792')
      if (number.match(re) != null) return 'troy'

      return 'visa'
    },
    generateCardNumberMask() {
      return this.getCardType === 'amex'
        ? this.amexCardMask
        : this.otherCardMask
    },
    minCardMonth() {
      if (this.year === this.minCardYear) return new Date().getMonth() + 1
      return 1
    }
  },
  watch: {
    cardYear() {
      if (this.month < this.minCardMonth) {
        this.month = ''
      }
    }
  },
  methods: {
    flipCard(status) {
      this.isCardFlipped = status
    },
    focusInput(e) {
      this.isInputFocused = true
      const targetRef = e.target.dataset.ref
      if (targetRef) {
        const target = this.$refs[targetRef]
        this.focusElementStyle = {
          width: `${target.offsetWidth}px`,
          height: `${target.offsetHeight}px`,
          transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
        }
      }
    },
    blurInput() {
      const vm = this
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null
        }
      }, 300)
      vm.isInputFocused = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "style";
</style>
