var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { placement: "right", trigger: "click", width: "400" },
      on: { show: _vm.init },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c("div", { staticClass: "t-my-3 t-text-xl" }, [
            _vm._v(" Invite Techside Technician ")
          ]),
          _c(
            "el-form",
            {
              ref: "inviteForm",
              attrs: {
                model: _vm.inviteForm,
                rules: {
                  phoneId: {
                    // required: true,
                    validator: _vm.phoneSelectValidator,
                    trigger: "change"
                  }
                }
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "t-flex justify-content-center" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phoneId" } },
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "(###) ###-####",
                              expression: "'(###) ###-####'"
                            }
                          ],
                          attrs: {
                            filterable: "",
                            placeholder: "(999) 999-9999",
                            "allow-create": ""
                          },
                          model: {
                            value: _vm.inviteForm.phoneId,
                            callback: function($$v) {
                              _vm.$set(_vm.inviteForm, "phoneId", $$v)
                            },
                            expression: "inviteForm.phoneId"
                          }
                        },
                        _vm._l(_vm.data.phones, function(phone) {
                          return _c("el-option", {
                            attrs: { value: phone.id, label: phone.number }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", [_vm._v(" Include shops in technician connection ")]),
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.inviteForm.shops,
                    callback: function($$v) {
                      _vm.$set(_vm.inviteForm, "shops", $$v)
                    },
                    expression: "inviteForm.shops"
                  }
                },
                _vm._l(_vm.shops, function(shop) {
                  return _c(
                    "div",
                    [
                      _c("el-checkbox", { attrs: { label: shop.id } }, [
                        _vm._v(" " + _vm._s(shop.name) + " ")
                      ])
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "t-mt-4" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "t-float-right t-ml-2",
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.sendTechsideInvite }
                    },
                    [_vm._v(" Send ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "t-float-right",
                      attrs: { disabled: _vm.loading },
                      on: {
                        click: function($event) {
                          _vm.visible = false
                        }
                      }
                    },
                    [_vm._v(" cancel ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          attrs: { slot: "reference", size: "mini", type: "danger", plain: "" },
          slot: "reference"
        },
        [_vm._v("Rejected -")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }