<template>
  <b-modal no-close-on-backdrop no-close-on-esc
  title="Job Documents" id="documentModal"
    header-bg-variant='primary'
    header-text-variant='light'
    @show="dialogAboutToAppear"
    size="lg">

    <div class="row">
      <div class="form-group col-lg-2">
        <FilePicker :buttonLabel="'Choose File'" :filePickerKey="filePickerKey" :dialogToReturnTo="'documentModal'" :handleFilePickerResponse="handleFilePickerResponse"/>
      </div>
      <div class="form-group col-lg-4 mt-2">
        <a v-if="document.document_name != null && document.document_name.length > 0" @click="deleteTempDocument()" href="javascript:void(0)"><font-awesome-icon style="color:red" :icon="faTimesCircle"/></a>&nbsp;<label>{{ document.document_name != null && document.document_name.length > 0 ? document.document_name : 'No file chosen' }}&nbsp;&nbsp;</label>
      </div>
      <div class="form-group col-lg-3 text-right">
        <select v-model='document.document_type' class="form-control form-control-sm">
          <option value="">Category...</option>
          <option value="swo">Signed Work Order</option>
          <option value="dis">Dispatch</option>
          <option value="pop">Proof of purchase</option>
          <option value="fiv">Field invoice</option>
          <option value="quo">Quote</option>
          <option value="dl">Damage Locator</option>
          <!-- <option value="ssmpic">Saleside Mobile Picture</option> -->
          <!-- <option value="tsmpic">Techside Mobile Picture</option> -->
          <option value='pic'>Picture</option>
          <option value='app'>Approval</option>
          <option value='auth'>Authorization</option>
          <option value='recal'>Recalibration</option>
          <option value='payment'>Payment</option>
          <option value="oth">Other</option>
        </select>
        <br>
      </div>
      <div class="form-group col-lg-3 text-right">
        <button type='button' style="width: 100%;" class='btn btn-primary' @click="createNewDocument" variant="primary">Attach</button>
      </div>
    </div>
    <hr style="margin-top:20px; margin-bottom:20px;">
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': SWOColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;SWO
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'swo'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': DISColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;DIS
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'dis'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': PoPColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;PoP
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'pop'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': FIVColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;FIV
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'fiv'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': QUOColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;QUO
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'quo'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': DLColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;DL
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'dl'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': SSMPicColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;SSMPic
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'ssmpic'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': TSMPicColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;TSMPic
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'tsmpic'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': SSignColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;SSign
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'ssign'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': TSignColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;TSign
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'tsign'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': picColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;PIC
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'pic'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': appColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;APP
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'app'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': authColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;AUTH
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'auth'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': recalColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;RECAL
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'recal'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': ESignColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;ESign
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'esign'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': SmsSignColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;SmsSign
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'smssign'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': PaymentColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;Payment
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'payment'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': OTHColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;OTH
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'oth'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-2">
          <a v-bind:style="{ 'background-color': TSMCNPColor, 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;TSMCNP
          </a>
      </div>
      <div class="form-group col-md-10">
        <span v-for="(document, index) in documents" :key="index" v-if="document.document_type == 'tsmcnp'"><font-awesome-icon @click="deleteDocument(index, document)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{document.document_name}}&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
    <div slot="modal-footer" class='w-100'>
      <div class='float-right'>
        <button type='button' class='btn btn-secondary' variant="primary" @click="cancelEdits">Close</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import FilePicker from '@/components/Helpers/FilePicker.vue'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faFile from '@fortawesome/fontawesome-free-solid/faFile'
import faMinusCircle from '@fortawesome/fontawesome-free-solid/faMinusCircle'
import Document from '@/scripts/objects/document'
import faTimesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle'

export default {
  props: ['documents', 'filePickerKey', 'shop_id', 'job_id', 'inFile'],
  data () {
    return {
      document: new Document()
    }
  },
  mounted () {
    this.document = new Document()
  },
  methods: {
    dialogAboutToAppear: function () {
      if (this.inFile) {
        this.document = this.inFile
        this.$emit('update:inFile', null)
      }
    },
    cancelEdits: function (event) {
      event.preventDefault()
      this.$root.$emit('bv::hide::modal', 'documentModal')
    },
    deleteDocument: function (documentIndex, document) {
      let self = this
      if (documentIndex >= 0) {
        if (this.job_id && this.job_id > 0) {
          if (confirm('Are you sure you want to PERMANENTLY delete this document?')) {
            document.deleteDocument(this.shop_id, function () {
              self.$root.$emit('deleteDocument', documentIndex)
            }, function (error) {
              
            })
          }
        } else {
          this.$root.$emit('deleteDocument', documentIndex)
        }
      }
    },
    deleteTempDocument: function () {
      this.document = new Document()
    },
    createNewDocument: function () {
      let self = this
      if (!this.document.document_type || this.document.document_type.length === 0) {
        alert('Please select a category to add the document to.')
      } else {
        if (this.document.document_name && this.document.document_name.length > 0 &&
        this.document.url && this.document.url.length > 0) {
          if (this.job_id && this.job_id > 0) {
            this.document.createUpdateDocument(this.shop_id, this.job_id, function (newDocument) {
              self.$root.$emit('addNewDocument', newDocument)
              self.document = new Document()
            }, function (error) {
              
            })
          } else {
            this.$root.$emit('addNewDocument', this.document)
            this.document = new Document()
          }
        }
      }
    },
    handleFilePickerResponse: function (fileName, fileUrl) {
      this.document.document_name = fileName
      this.document.url = fileUrl
    }
  },
  computed: {
    SWOColor () {
      return Document.getSWOColor()
    },
    DISColor () {
      return Document.getDISColor()
    },
    PoPColor () {
      return Document.getPoPColor()
    },
    FIVColor () {
      return Document.getFIVColor()
    },
    QUOColor () {
      return Document.getQUOColor()
    },
    DLColor () {
      return Document.getDLColor()
    },
    SSMPicColor () {
      return Document.getSSMPicColor()
    },
    TSMPicColor () {
      return Document.getTSMPicColor()
    },
    SSignColor () {
      return Document.getSSignColor()
    },
    TSignColor () {
      return Document.getTSignColor()
    },
    recalColor () {
      return Document.getRecalColor()
    },
    authColor () {
      return Document.getAuthColor()
    },
    appColor () {
      return Document.getApprovalColor()
    },
    picColor () {
      return Document.getPicColor()
    },
    ESignColor () {
      return Document.getEsignColor()
    },
    SmsSignColor () {
      return Document.getSmsSignColor()
    },
    PaymentColor () {
      return Document.getPaymentColor()
    },
    OTHColor () {
      return Document.getOTHColor()
    },
    TSMCNPColor () {
      return Document.getTSMCNPColor()
    },
    faFile () {
      return faFile
    },
    faMinusCircle () {
      return faMinusCircle
    },
    faTimesCircle () {
      return faTimesCircle
    }
  },
  components: {
    FontAwesomeIcon,
    FilePicker
  }
}
</script>

<style>
#documentModal .modal-lg {
  min-width: 70%;
}

</style>
