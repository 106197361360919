var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.location,
              expression: "location"
            }
          ],
          staticClass: "form-control form-control-sm",
          attrs: { id: "install_location" },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.location = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          _c("option", { attrs: { value: "" } }, [_vm._v("Choose...")]),
          _vm._l(_vm.options, function(loc) {
            return _c("option", { key: loc.id, domProps: { value: loc.id } }, [
              _vm._v(" " + _vm._s(loc.name) + " ")
            ])
          })
        ],
        2
      ),
      _c("install-location-dialog", {
        attrs: { shopId: _vm.shopId, editLocation: _vm.currentSelected },
        on: { "new-location": _vm.onNewLocation }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }