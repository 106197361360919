var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading || _vm.uploadingDropFile,
          expression: "loading || uploadingDropFile"
        }
      ],
      class: {
        "dragging-overlay": _vm.dragging,
        "ticket-container": true
      },
      on: {
        drop: [
          function($event) {
            $event.preventDefault()
            return _vm.documentDrop($event)
          },
          function($event) {
            _vm.dragging = false
          }
        ],
        dragleave: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.dragLeave($event)
        },
        dragenter: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.dragEnter($event)
        },
        dragover: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "gb-card",
                { attrs: { title: "New Ticket" } },
                [
                  _c("gb-form", {
                    ref: "formRef1",
                    attrs: { schema: _vm.userSchema.concat(_vm.problemSchema) },
                    model: {
                      value: _vm.ticketData,
                      callback: function($$v) {
                        _vm.ticketData = $$v
                      },
                      expression: "ticketData"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-row", [
        _c(
          "div",
          {
            staticClass: "mt-2 pl-2 pr-2",
            staticStyle: { "word-break": "keep-all" }
          },
          [
            _c("i", {
              staticClass: "material-icons md-warning md-18",
              staticStyle: { color: "#ffa500", "font-size": "18px" }
            }),
            _vm._v(
              " Please include any information that will help us to identify your issue. Include screenshots, job numbers, error codes, dates occurred, steps to reproduce error/issue, users affected, device (pc/mac or mobile device?), browser and any other helpful information. "
            )
          ]
        )
      ]),
      _c("el-row", [
        _c("div", { staticClass: "additional-attachments t-pl-2" }, [
          _c(
            "div",
            { staticClass: "additional-attachments__label t-mt-5 t-mb-1" },
            [_vm._v("Attachments")]
          ),
          _c(
            "div",
            { staticClass: "additional-attachments__files" },
            [
              _vm._l(_vm.attachments, function(attachment, index) {
                return _c(
                  "div",
                  { key: attachment.url, staticClass: "t-mb-1" },
                  [
                    _c(
                      "a",
                      { attrs: { href: attachment.url, target: "_blank" } },
                      [_vm._v(_vm._s(attachment.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.deleteAttachment(index)
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          staticStyle: { color: "red" },
                          attrs: { name: "times", scale: "1.1" }
                        })
                      ],
                      1
                    )
                  ]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "t-mt-2",
                  on: {
                    dragover: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("FilePicker", {
                    attrs: {
                      buttonLabel: "Choose File",
                      filePickerKey: _vm.filePickerKey,
                      handleFilePickerResponse: _vm.handleFilePickerResponse,
                      dropListenerName: "emailAttachment",
                      uploadingDropFile: _vm.uploadingDropFile
                    },
                    on: {
                      "update:uploadingDropFile": function($event) {
                        _vm.uploadingDropFile = $event
                      },
                      "update:uploading-drop-file": function($event) {
                        _vm.uploadingDropFile = $event
                      }
                    }
                  }),
                  _c("div", { staticClass: "t-mt-1" }, [
                    !_vm.uploadingDropFile
                      ? _c("div", [_vm._v("Or Drag and Drop...")])
                      : _vm._e(),
                    _vm.uploadingDropFile
                      ? _c("div", [_vm._v("Uploading...")])
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            2
          )
        ])
      ]),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            { staticClass: "float-right", on: { click: _vm.submitTicket } },
            [_vm._v(" Submit ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }