var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title: _vm.dialogConfig ? _vm.dialogConfig.label : "",
        id: "dynamicDocumentModal",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg"
      },
      on: {
        show: _vm.dialogToShow,
        shown: _vm.dialogShown,
        hidden: _vm.dialogAboutToDisappear
      }
    },
    [
      _vm.dialogConfig
        ? _c(
            "div",
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-lg-2" },
                  [
                    _c("FilePicker", {
                      attrs: {
                        buttonLabel: "Choose File",
                        filePickerKey: _vm.filePickerKey,
                        dialogToReturnTo: "dynamicDocumentModal",
                        handleFilePickerResponse: _vm.handleFilePickerResponse,
                        dropListenerName: "dynamicDialogListener",
                        uploadingDropFile: _vm.uploadingDropFile,
                        filepickerOpen: _vm.filepickerOpen
                      },
                      on: {
                        "update:uploadingDropFile": function($event) {
                          _vm.uploadingDropFile = $event
                        },
                        "update:uploading-drop-file": function($event) {
                          _vm.uploadingDropFile = $event
                        },
                        "update:filepickerOpen": function($event) {
                          _vm.filepickerOpen = $event
                        },
                        "update:filepicker-open": function($event) {
                          _vm.filepickerOpen = $event
                        }
                      }
                    }),
                    _vm._v(" Or Drag and Drop... ")
                  ],
                  1
                ),
                _c("div", { staticClass: "form-group col-lg-4" }, [
                  _c(
                    "a",
                    {
                      class: {
                        "form-control": true,
                        "form-control-sm": true,
                        uploadingDropFile: _vm.uploadingDropFile
                      },
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.deleteTempDocument()
                        }
                      }
                    },
                    [
                      _vm.document.document_name != null &&
                      _vm.document.document_name.length > 0
                        ? _c("font-awesome-icon", {
                            staticStyle: { color: "red" },
                            attrs: { icon: _vm.faTimesCircle }
                          })
                        : _vm._e(),
                      _vm._v(
                        _vm._s(
                          _vm.document.document_name != null &&
                            _vm.document.document_name.length > 0
                            ? _vm.document.document_name
                            : _vm.uploadingDropFile
                            ? "Preparing File..."
                            : "No file chosen"
                        ) + " "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("label")
                ]),
                _c("div", { staticClass: "form-group col-lg-3 text-right" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.document.document_type,
                          expression: "document.document_type"
                        }
                      ],
                      staticClass: "form-control form-control-sm",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.document,
                            "document_type",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Category...")
                      ]),
                      _vm._l(_vm.dialogConfig.docOptions, function(doc) {
                        return _c(
                          "option",
                          { key: doc.label, domProps: { value: doc.value } },
                          [_vm._v(_vm._s(doc.label))]
                        )
                      })
                    ],
                    2
                  ),
                  _c("label")
                ]),
                _c("div", { staticClass: "form-group col-lg-2 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      staticStyle: { width: "100%" },
                      attrs: { type: "button", variant: "primary" },
                      on: { click: _vm.createNewDocument }
                    },
                    [_vm._v("Attach")]
                  )
                ])
              ]),
              _c("br"),
              _vm._l(_vm.dialogConfig.docOptions, function(docType) {
                return _c(
                  "div",
                  {
                    key: "actualDocuments" + docType.value,
                    staticClass: "row"
                  },
                  [
                    _c("div", { staticClass: "col-2" }, [
                      _c(
                        "a",
                        {
                          staticClass: "tag tag-light",
                          style: {
                            "background-color": _vm.getDocumentTagColorWithType(
                              _vm.dialogConfig.type,
                              docType.value
                            ),
                            color: "white"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: _vm.faFile }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getDocumentTagNameWithType(
                                  _vm.dialogConfig.type,
                                  docType.value
                                )
                              ) +
                              " "
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-10" },
                      _vm._l(_vm.documents, function(doc, index) {
                        return _c("span", { key: doc.id + index }, [
                          doc.document_type === docType.value
                            ? _c(
                                "span",
                                [
                                  _c("font-awesome-icon", {
                                    staticStyle: {
                                      color: "red",
                                      cursor: "pointer"
                                    },
                                    attrs: { icon: _vm.faMinusCircle },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteDocument(index, doc)
                                      }
                                    }
                                  }),
                                  _vm._v(" " + _vm._s(doc.document_name) + " ")
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      }),
                      0
                    )
                  ]
                )
              })
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }