import * as R from 'ramda'

import { maskJs } from 'mask-js'

export const getConsumerName = R.cond([
  [R.prop('company_name'), R.pipe(R.prop('company_name'))],
  [
    R.prop('name'),
    R.pipe(
      R.propOr({}, 'name'),
      ({ first, last }) => `${first} ${last || ''}`,
      R.toUpper
    )
  ],
  [R.prop('guest'), () => 'Guest'],
  [R.T, R.always('-')]
])

export const getConsumerStatus = R.prop('status')

export const getInitials = R.pipe(
  R.cond([
    [
      R.anyPass([R.prop('is_commercial'), R.prop('is_insurance')]),
      R.pipe(R.propOr('', 'company_name'), R.split(' '), R.take(2))
    ],
    [R.T, R.pipe(R.propOr({}, 'name'), R.pick(['first', 'last']), R.values)]
  ]),
  R.map(R.head),
  R.join('')
)

export const replaceDoubleSpace = R.pipe(R.replace(/  +/g, ' '), R.trim)

export const getNameString = R.pipe(
  R.juxt([R.prop('first'), R.prop('middle'), R.prop('last')]),
  R.join(' '),
  replaceDoubleSpace
)

export const getName = R.ifElse(
  R.anyPass([R.prop('is_insurance'), R.prop('is_commercial')]),
  R.prop('company_name'),
  R.pipe(R.prop('name'), getNameString)
)

export const getNameList = R.pipe(
  R.propOr([], 'names'),
  R.when(R.isNil, R.always([])),
  R.map(getNameString),
  R.uniq
)
export const formatPhone = v => maskJs('(999) 999-9999', v)

export const getPhoneList = R.pipe(
  R.propOr([], 'phones'),
  R.when(R.isNil, R.always([])),
  R.pluck('number'),
  R.map(formatPhone)
)

export const generateOption = label =>
  R.applySpec({
    phone_number: R.path(['phone', 'number']),
    full_name: R.pipe(R.prop('name'), getNameString),
    shop_name: R.path(['shop', 'name']),
    id: R.prop('id'),
    label: R.always(label),
    is_commercial: R.prop('is_commercial'),
    is_insurance: R.prop('is_insurance'),
    company_name: R.prop('company_name')
  })

export const generateConsumerOptions = reject => (customer, shipTo) => [
  generateOption('Customer')(customer),
  ...(shipTo ? [generateOption()(shipTo)] : []),
  ...R.pipe(
    R.propOr([], 'associated_consumers'),
    R.reject(R.prop(reject)),
    R.addIndex(R.map)((v, idx) =>
      generateOption(idx === 0 && 'Associated Consumers')(v)
    )
  )(customer)
]

export const getJobBooleanFlags = (customer, billTo, job) => {
  const newValues = {
    insurance_fleet_tax_exempt:
      job.insurance_fleet_tax_exempt ||
      billTo?.tax_exempt ||
      customer?.tax_exempt,
    insurance_fleet_auto_add_adhesive:
      job.insurance_fleet_auto_add_adhesive ||
      billTo?.auto_add_adhesive ||
      customer?.auto_add_adhesive,
    insurance_fleet_po_required:
      job.insurance_fleet_po_required ||
      billTo?.po_required ||
      customer?.po_required
  }
  if (
    R.pipe(
      R.pick([
        'insurance_fleet_tax_exempt',
        'insurance_fleet_auto_add_adhesive',
        'insurance_fleet_po_required'
      ]),
      R.equals(newValues),
      R.not
    )(customer)
  ) {
    return newValues
  }
}

export const getJobDefaultValues = R.pipe(
  R.applySpec({
    contact_name: R.pipe(
      R.propOr({}, 'name'),
      R.juxt([R.prop('first'), R.prop('last')]),
      R.join(' ')
    ),
    contact_phone1: R.pipe(R.pathOr('', ['phone', 'number']), formatPhone),
    install_address_id: R.path(['address', 'id']),
    install_g_address: R.path(['address']),
    bill_to_id: R.ifElse(
      R.path(['bill_to', 'id']),
      R.path(['bill_to', 'id']),
      R.prop('id')
    ),
    gb_salesrep_id: R.path(['salesrep', 'id']),
    gb_tech_id: R.path(['tech', 'id']),
    selected_invoice_tier: R.prop('default_invoice_tier'),
    purchase_order_number: R.prop('standing_po')
  })
)

export const getJobBillToDefaultValues = (shopId, consumer) => {
  const update = {}

  if (consumer && consumer.consumer_shop_settings) {
    for (var i = 0; i < consumer.consumer_shop_settings.length; i++) {
      if (consumer.consumer_shop_settings[i].shop_id === shopId) {
        update.rates = consumer.consumer_shop_settings[i].rates;
        break;
      }
    }
  }

  if (consumer.standing_po) {
    update.purchase_order_number = consumer.standing_po;
  }

  return Object.keys(update).length ? update : null
}
