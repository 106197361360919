var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "glaxis-credentials-form",
          attrs: {
            model: _vm.form,
            "label-width": "120px",
            "hide-required-asterisk": "",
            size: "medium"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "account" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Account")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "300px",
                            "margin-right": "10px"
                          },
                          attrs: { placeholder: "Select" },
                          model: {
                            value: _vm.form.account,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "account", $$v)
                            },
                            expression: "form.account"
                          }
                        },
                        _vm._l(_vm.credentials, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label:
                                item.name +
                                " - " +
                                item.account_number +
                                " " +
                                (item.default ? "(default)" : ""),
                              value: item.id
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "t-flex" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Add Credentials",
                          placement: "top",
                          "open-delay": 300
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-plus"
                          },
                          on: { click: _vm.createNew }
                        })
                      ],
                      1
                    ),
                    _vm.form.account
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Edit selected",
                              placement: "top",
                              "open-delay": 300
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: { size: "mini", icon: "el-icon-edit" },
                              on: { click: _vm.editSelected }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.account
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Delete selected",
                              placement: "top",
                              "open-delay": 300
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                type: "danger",
                                icon: "el-icon-delete"
                              },
                              on: { click: _vm.deleteSelected }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedAccountNotDefault
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Set selected as default",
                              placement: "top",
                              "open-delay": 300
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                type: "success",
                                plain: "",
                                icon: "el-icon-check"
                              },
                              on: { click: _vm.saveDefault }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm.vendor === "mygrant"
            ? _c(
                "el-form-item",
                [
                  _c("warehouses-table", {
                    attrs: {
                      allWarehouses: _vm.allWarehouses,
                      selectedWarehouses: _vm.form.warehouses,
                      defaultWarehouse: _vm.form.defaultWarehouse
                    },
                    on: {
                      "update:selectedWarehouses": function($event) {
                        return _vm.$set(_vm.form, "warehouses", $event)
                      },
                      "update:selected-warehouses": function($event) {
                        return _vm.$set(_vm.form, "warehouses", $event)
                      },
                      "update:defaultWarehouse": function($event) {
                        return _vm.$set(_vm.form, "defaultWarehouse", $event)
                      },
                      "update:default-warehouse": function($event) {
                        return _vm.$set(_vm.form, "defaultWarehouse", $event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.vendor === "mygrant"
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.submitting,
                        size: "small"
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.shopId
        ? _c("add-edit-dialog", {
            attrs: {
              shopId: _vm.shopId,
              edit: _vm.credentialsToEdit,
              vendor: _vm.vendor,
              visible: _vm.addEditCredentialsDialogVisible
            },
            on: {
              "update:visible": function($event) {
                _vm.addEditCredentialsDialogVisible = $event
              },
              updateCredentials: _vm.refreshCredentials
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }