var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "event-item",
      class: !_vm.permitted ? "event-item-disabled" : ""
    },
    [
      _c(
        "div",
        {
          staticClass: "overlay d-flex justify-content-end",
          class: _vm.permitted ? "invisible" : "visible"
        },
        [
          _c(
            "div",
            { staticClass: "overlay-lock" },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "light",
                    content:
                      "Permission denied. Request permission from your manager.",
                    placement: "left"
                  }
                },
                [
                  _c("div", { staticClass: "gb-icon-gray-button" }, [
                    _c("i", { staticClass: "md-lock material-icons-outline" })
                  ])
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            disabled: !_vm.permitted
          }
        },
        [
          _c("div", { staticClass: "d-flex flex-wrap event-item-content" }, [
            _c("div", { staticClass: "d-flex flex-column info" }, [
              _c("div", { staticClass: "d-flex header align-items-center" }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "icon-square-round",
                      class: _vm.backgroundClass
                    },
                    [
                      _c("i", {
                        staticClass: "material-icons-round md-24",
                        class: _vm.iconClass
                      })
                    ]
                  )
                ]),
                _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
              ]),
              _c("div", { staticClass: "description text-break" }, [
                _vm._v(_vm._s(_vm.description))
              ])
            ]),
            _c(
              "div",
              { staticClass: "section-shops d-flex align-items-center" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Shops Limit", prop: "shop_ids" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          "value-key": "id",
                          filterable: "",
                          placeholder: "All Shops",
                          multiple: ""
                        },
                        model: {
                          value: _vm.form.shop_ids,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "shop_ids", $$v)
                          },
                          expression: "form.shop_ids"
                        }
                      },
                      _vm._l(_vm.$store.state.shops, function(
                        shop,
                        shop_index
                      ) {
                        return _c("el-option", {
                          key: shop_index,
                          attrs: { label: shop.name, value: shop.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "section-switchers d-flex flex-wrap align-items-center"
              },
              [
                _c(
                  "div",
                  { staticClass: "column-switch" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Web", prop: "web_enabled" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.form.web_enabled,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "web_enabled", $$v)
                            },
                            expression: "form.web_enabled"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "column-switch" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "SMS", prop: "sms_enabled" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.form.sms_enabled,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sms_enabled", $$v)
                            },
                            expression: "form.sms_enabled"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "column-switch" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Email", prop: "email_enabled" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.form.email_enabled,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "email_enabled", $$v)
                            },
                            expression: "form.email_enabled"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "column-more d-flex align-items-end" },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-end",
                          width: "369px",
                          trigger: "click",
                          title: "More Options",
                          "popper-class": "popper-more"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass:
                                  "d-flex align-items-center button-more",
                                attrs: {
                                  icon: "md-more_vert material-icons-outline",
                                  size: "mini"
                                }
                              },
                              [_vm._v("More")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-more d-flex flex-column" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-more-web form-more-row d-flex"
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Web Mode",
                                      prop: "web_mode"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          "default-first-option": true,
                                          disabled: !_vm.form.web_enabled,
                                          size: "small"
                                        },
                                        model: {
                                          value: _vm.form.web_mode,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "web_mode", $$v)
                                          },
                                          expression: "form.web_mode"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-option",
                                          {
                                            key: 1,
                                            attrs: { value: 1, label: "Bell" }
                                          },
                                          [_vm._v("Bell")]
                                        ),
                                        _c(
                                          "el-option",
                                          {
                                            key: 2,
                                            attrs: { value: 2, label: "Pop-Up" }
                                          },
                                          [_vm._v("Pop-Up")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass:
                                      "d-flex align-items-end form-bell-item",
                                    attrs: { prop: "web_sound_enabled" }
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          content: _vm.form.web_sound_enabled
                                            ? "Disable sound"
                                            : "Enable sound",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            staticClass: "form-bell",
                                            attrs: { label: "mock" },
                                            model: {
                                              value: _vm.form.web_sound_enabled,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "web_sound_enabled",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.web_sound_enabled"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "icon-active-wrapper d-flex flex-column justify-content-center"
                                              },
                                              [
                                                _vm.form.web_sound_enabled
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gb-icon-light-blue-button"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "md-notifications material-icons"
                                                        })
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gb-icon-gray-button"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "md-notifications material-icons-outline"
                                                        })
                                                      ]
                                                    )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("el-divider"),
                            _c(
                              "div",
                              { staticClass: "form-more-sms" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Extra Phones",
                                      prop: "sms_phones"
                                    }
                                  },
                                  [
                                    _c("el-select", {
                                      ref: "phones",
                                      attrs: {
                                        placeholder: "Add Phone #",
                                        filterable: "",
                                        multiple: "",
                                        "allow-create": "",
                                        disabled: !_vm.form.sms_enabled,
                                        "filter-method": _vm.renderPhone
                                      },
                                      on: { change: _vm.formatPhones },
                                      model: {
                                        value: _vm.form.sms_phones,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "sms_phones", $$v)
                                        },
                                        expression: "form.sms_phones"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("el-divider"),
                            _c(
                              "div",
                              { staticClass: "form-more-email" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Extra Emails",
                                      prop: "email_addresses"
                                    }
                                  },
                                  [
                                    _c("el-select", {
                                      attrs: {
                                        placeholder: "Add Email",
                                        filterable: "",
                                        multiple: "",
                                        "allow-create": "",
                                        disabled: !_vm.form.email_enabled
                                      },
                                      model: {
                                        value: _vm.form.email_addresses,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "email_addresses",
                                            $$v
                                          )
                                        },
                                        expression: "form.email_addresses"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }