<template>
  <div class="t-p-3 t-w-44 t-h-36">
    <div
      class="t-flex t-flex-col t-items-center t-justify-center t-border t-border-solid t-border-gray-200
        t-rounded-md t-shadow-sm t-bg-white t-h-full t-cursor-pointer t-px-3 t-relative"
      @click="$emit('selected')"
    >
      <span v-if="beta" class="badge badge-primary t-absolute t-top-[-8px] t-left-[10px]">Beta!</span>
      <div class="t-flex t-flex-col t-items-center t-justify-center t-space-x-2
        t-mb-2 t-text-blue-600">
        <div class="t-text-sm t-mb-4 terminal-content" v-if="label">{{ label }}</div>
        <img v-if="device" src="./images/desktop-computer.svg" class="t-text-blue-600 t-h-10 t-w-10" />
        <img v-else src="./images/credit-card.svg" class="t-text-blue-600 t-h-10 t-w-10" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    device: Object,
    beta: Boolean
  }
}
</script>

<style scoped>
.beta-badge {
  position: absolute;
  top: 85px;
}
.terminal-content {
  font-family: 'Inter', sans-serif;
}
</style>
