<template>
  <el-form
    v-if="items.length > 0"
    ref="form"
    :model="form"
    :rules="rules"
    size="medium"
    inline
    hide-required-asterisk
    class="t-flex">
    <el-form-item
      prop="deliveryMethod"
      class="t-m-0 t-w-48">
      <el-select
        v-model="form.deliveryMethod"
        placeholder="Delivery method"
        size="medium"
        class="t-pr-3">
        <el-option
          label="Schedule run"
          value="Schedule Run">
        </el-option>
        <el-option label="Will call" value="Will Call"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      prop="po"
      class="t-m-0 t-w-32">
      <el-input
        placeholder="PO number"
        v-model="form.po"
        size="medium">
      </el-input>
    </el-form-item>
    <el-form-item prop="comment" class="t-m-0 t-flex-1" id="place-order-form-comment">
      <el-input
        placeholder="Order notes"
        v-model="form.comment"
        size="medium"
        class="t-px-3">
      </el-input>
    </el-form-item>
    <el-form-item class="t-m-0">
      <el-button
        @click="submit"
        type="primary"
        size="medium"
        class="t-bg-blue-500 hover:t-bg-blue-400"
        :loading="submitting">
        Place order
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {
  shoppingCartActions,
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'

export default {
  data: () => ({
    form: {
      deliveryMethod: 'Will Call',
      po: '',
      comment: ''
    },
    rules: {
      deliveryMethod: { required: true, message: 'Please select delivery method' }
    },
    submitting: false
  }),
  computed: {
    items () {
      return this.$store.state.shoppingCart.items.data
    },
    visible () {
      return this.$store.state.shoppingCart.isOpen
    },
    vendor () {
      return this.$store.state.shoppingCart.items.selectedVendor
    },
    shopId () {
      return this.$store.state.shoppingCart.shopId
    },
    selectedItemIds () {
      return this.$store.state.shoppingCart.items.selectedItemIds
    },
    selectedTab: {
      get () {
        return this.$store.state.shoppingCart.selectedTab
      },
      set (value) {
        return this.$store.commit(shoppingCartMutations.setSelectedTab, value)
      }
    }
  },
  watch: {
    visible: function (value) {
      if (!value && this.items.length > 0) {
        this.clearForm()
      }
    }
  },
  methods: {
    placeOrder (payload) {
      return this.$store.dispatch(shoppingCartActions.placeOrder, payload)
    },
    getItemsCount () {
      return this.$store.dispatch(shoppingCartActions.getItemsCount)
    },
    async submit () {
      if (this.selectedItemIds.length === 0) {
        this.$message.warning('Please select items you wish to purchase')
      } else {
        this.submitting = true
        try {
          if (await this.$refs.form.validate()) {
            await this.placeOrder({
              shopId: this.shopId,
              vendor: this.vendor,
              cartItemIds: this.selectedItemIds,
              ...this.form
            })
            this.submitting = false
            this.$message.info('Your order is processing...')
            this.selectedTab = 'orders'
            this.$root.$emit('purchase-order::updated')
            this.getItemsCount()
            this.clearForm()
          }
        } catch (error) {
          this.submitting = false
        }
      }
    },
    clearForm () {
      this.form.deliveryMethod = 'Will Call'
      this.form.po = ''
      this.form.comment = ''
      this.$refs.form.clearValidate()
    }
  }
}
</script>
