var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "data-tab-permission-dialog",
      attrs: {
        "append-to-body": "",
        width: "700px",
        visible: _vm.localVisible,
        title: "Data Tab Permissions"
      },
      on: {
        "update:visible": function($event) {
          _vm.localVisible = $event
        },
        open: _vm.open,
        close: _vm.close
      }
    },
    [
      _vm.displayOverlay
        ? _c("span", { staticClass: "overlay" }, [
            _c(
              "div",
              {
                staticClass: "close-dialog-overlay",
                on: {
                  click: function($event) {
                    _vm.localVisible = false
                  }
                }
              },
              [_c("v-icon", { attrs: { name: "times", scale: "1.1" } })],
              1
            ),
            _c("div", { staticClass: "overlay-text" }, [
              _vm._v(
                "The general data tab permission must be checked for these permissions to take effect"
              )
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "permissions-container p-2" },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.checkedDatatabs,
                callback: function($$v) {
                  _vm.checkedDatatabs = $$v
                },
                expression: "checkedDatatabs"
              }
            },
            _vm._l(_vm.options, function(option) {
              return _c(
                "el-checkbox",
                { key: option.value, attrs: { label: option.value } },
                [_vm._v(" " + _vm._s(option.label) + " ")]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.saving },
              on: { click: _vm.save }
            },
            [_vm._v("Save")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "background-color": "black", color: "white" },
              attrs: { disabled: _vm.saving },
              on: {
                click: function($event) {
                  _vm.localVisible = false
                }
              }
            },
            [_vm._v("Cancel")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }