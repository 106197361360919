import Vue from 'vue'

export const jobPageMedia = Vue.observable({
  width: 0,
  xl: false,
  lg: false,
  md: false,
  mds: false,
  sm: false,
  xs: false
})

export const applyMediaRules = (rules, media = jobPageMedia) => {
  if (rules.xl && media.xl) {
    return rules.xl
  } else if (rules.lg && media.lg) {
    return rules.lg
  } else if (rules.md && media.md) {
    return rules.md
  } else if (rules.mds && media.mds) {
    return rules.mds
  } else if (rules.sm && media.sm) {
    return rules.sm
  } else if (rules.xs && media.xs) {
    return rules.xs
  }
  return rules.xs || rules.sm || rules.mds || rules.md || rules.lg || rules.xl
}
