<template>
  <svg
    width="25px"
    height="26px"
    viewBox="0 0 25 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        x="-39.5%"
        y="-37.5%"
        width="178.9%"
        height="175.0%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset
          dx="1"
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.562581949 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Group-2" transform="translate(-30.000000, 2.000000)">
        <g
          id="cog-solid"
          filter="url(#filter-1)"
          transform="translate(32.000000, 0.000000)"
        >
          <path
            d="M17.7700904,11.7830559 L16.1551656,10.8407207 C16.3181745,9.95201442 16.3181745,9.04032432 16.1551656,8.151618 L17.7700904,7.20928285 C17.9558446,7.10202519 18.0392445,6.87984861 17.97859,6.67299455 C17.5577998,5.30929003 16.8413191,4.07582695 15.904966,3.04921793 C15.7609117,2.89216207 15.5258757,2.85385576 15.3439124,2.96111342 L13.7289876,3.90344857 C13.050416,3.31353144 12.2694899,2.85768639 11.4241185,2.5588972 L11.4241185,0.678057532 C11.4241185,0.463542214 11.2762733,0.27584131 11.0677736,0.229873742 C9.6765122,-0.0842379741 8.25113263,-0.0689154514 6.92810744,0.229873742 C6.71960777,0.27584131 6.57176254,0.463542214 6.57176254,0.678057532 L6.57176254,2.56272783 C5.73018205,2.86534765 4.94925601,3.3211927 4.26689345,3.9072792 L2.65575962,2.96494405 C2.47000537,2.85768639 2.23876028,2.89216207 2.09470596,3.05304856 C1.15835289,4.07582695 0.441872196,5.30929003 0.021081949,6.67682519 C-0.0433634041,6.88367924 0.0438273677,7.10585582 0.229581621,7.21311348 L1.84450635,8.15544863 C1.68149752,9.04415495 1.68149752,9.95584505 1.84450635,10.8445514 L0.229581621,11.7868865 C0.0438273677,11.8941442 -0.039572501,12.1163208 0.021081949,12.3231748 C0.441872196,13.6868793 1.15835289,14.9203424 2.09470596,15.9469514 C2.23876028,16.1040073 2.47379627,16.1423136 2.65575962,16.0350559 L4.27068435,15.0927208 C4.94925601,15.6826379 5.73018205,16.138483 6.57555345,16.4372722 L6.57555345,18.3219425 C6.57555345,18.5364578 6.72339867,18.7241587 6.93189834,18.7701263 C8.32315979,19.084238 9.74853936,19.0689155 11.0715645,18.7701263 C11.2800642,18.7241587 11.4279094,18.5364578 11.4279094,18.3219425 L11.4279094,16.4372722 C12.2694899,16.1346523 13.050416,15.6788073 13.7327785,15.0927208 L15.3477033,16.0350559 C15.5334575,16.1423136 15.7647026,16.1078379 15.9087569,15.9469514 C16.84511,14.9241731 17.5615907,13.69071 17.9823809,12.3231748 C18.0392445,12.1124901 17.9558446,11.8903135 17.7700904,11.7830559 Z"
            id="Shape"
            stroke="#FFF"
            :fill="fillColor"
            fill-rule="nonzero"
            stroke-linecap="round"
          ></path>
          <text
            id="12"
            fill="#FFFFFF"
            font-family="Poppins-Regular, Poppins"
            font-size="10"
            font-weight="normal"
            text-anchor="middle"
            alignment-baseline="middle"
          >
            <tspan x="9" y="13">{{ text }}</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['fillColor', 'text'],
  computed: {}
}
</script>
