var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consumer-dialog-edi" },
    [
      _c("el-switch", {
        attrs: {
          "active-value": 1,
          "inactive-value": 0,
          "active-text": "EDI Capable",
          "inactive-text": "Disabled"
        },
        model: {
          value: _vm.capable,
          callback: function($$v) {
            _vm.capable = $$v
          },
          expression: "capable"
        }
      }),
      _c("input-with-label", {
        attrs: {
          select: { options: _vm.partnerOptions, byId: true },
          label: "Trading Partner",
          size: "normal"
        },
        model: {
          value: _vm.partner,
          callback: function($$v) {
            _vm.partner = $$v
          },
          expression: "partner"
        }
      }),
      _c(
        "input-with-label",
        {
          staticClass: "program-select",
          attrs: {
            select: {
              options: _vm.filteredProgramOptions,
              byId: true,
              valueKey: "program_id",
              filterMethod: _vm.searchPrograms
            },
            label: "Trading Partner Program ID",
            size: "normal",
            disabled: _vm.disabled
          },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(ref) {
                var item = ref.item
                return _c(
                  "div",
                  { staticClass: "consumer-dialog-program-select" },
                  [
                    _vm._v(" " + _vm._s(item.name) + " "),
                    _c("span", [_vm._v("ID: " + _vm._s(item.program_id))])
                  ]
                )
              }
            }
          ]),
          model: {
            value: _vm.program,
            callback: function($$v) {
              _vm.program = $$v
            },
            expression: "program"
          }
        },
        [
          _vm.program
            ? _c(
                "div",
                {
                  staticClass: "program-id",
                  attrs: { slot: "prefix" },
                  slot: "prefix"
                },
                [_vm._v(_vm._s(_vm.program))]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }