<template>
  <div>
    <div class="ui-input-label">Display settings:</div>
    <el-select class="scheduler-settings-filter" multiple v-model="settings">
      <el-option
        v-for="option in options"
        :key="option.key"
        :label="option.label"
        :value="option.key"
      />
    </el-select>
  </div>
</template>

<script>
import { sync, dispatch, get } from 'vuex-pathify'
import actions from '@/store/modules/scheduler_v3/types'
import * as R from 'ramda'

export default {
  data() {
    return {
      options: [
        // { key: 'combineTechs', label: 'Combine techs' },
        { key: 'solidCards', label: 'Card background' },
        { key: 'showTotals', label: 'Tech totals' },
        { key: 'jobConfirmationDetailsVisible', label: 'Job confirmation' },
        { key: 'showTags', label: 'Show tags' },
        { key: 'showAvailability', label: 'Tech availability' },
        { key: 'showVendors', label: 'Show vendors'}
      ]
    }
  },
  computed: {
    keys() {
      return R.pluck('key')(this.options)
    },
    value: get('getUserCombineTechsValue'),
    combineTechs: {
      get() {
        return !!this.value
      },
      set(value) {
        dispatch('setUserCombineTechs', +value)
        dispatch(actions.updateUserSettings, {
          scheduler_combine_techs: +value
        })
      }
    },
    solidCards: sync('sch_v3/filters@solidCards'),
    showTotals: sync('sch_v3/filters@showTotals'),
    showAvailability: sync('sch_v3/filters@showAvailability'),
    jobConfirmationDetailsVisible: sync(
      'sch_v3/filters@jobConfirmationDetailsVisible'
    ),
    showVendors: sync('sch_v3/filters@showVendors'),
    showTags: sync('sch_v3/filters@showTags'),
    settings: {
      get() {
        return R.pipe(R.pick(this.keys), R.filter(R.identity), R.keys)(this)
      },
      set(values) {
        R.forEach(key => {
          this[key] = R.contains(key)(values)
        })(this.keys)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/SchedulerSettingsFilter.scss';
</style>
