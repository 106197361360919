var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { trigger: "manual", "append-to-body": false },
      on: { "after-enter": _vm.focusRef },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "div",
        {
          style: { cursor: "pointer" },
          attrs: { slot: "reference" },
          on: {
            click: function($event) {
              _vm.visible = true
            }
          },
          slot: "reference"
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "input-with-label",
        _vm._b(
          {
            ref: "input",
            attrs: {
              size: "mini",
              lazy: "",
              value: _vm.value,
              label: _vm.label
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              },
              blur: function($event) {
                _vm.visible = false
              }
            }
          },
          "input-with-label",
          _vm.inputOptions,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }