import Vue from 'vue'
import JobDetailsClaimDataMixin from '@/components/modals/GlaxisDialogs/claim/mixins/job-details-claim-data.mixin'

import router from '@/router'
import store from '@/store/store'
import { apolloProvider } from '@/apollo'

let instance

export const getJobDetailsClaimData = () => {
  if (instance) return instance

  instance = new Vue({
    mixins: [
      JobDetailsClaimDataMixin
    ],
    apolloProvider,
    router,
    store
  })

  return instance
}

export default {
  install(Vue, options) {
    Vue.prototype.$claimJobDetails = getJobDetailsClaimData()
  }
}
