<template>
  <div>
    <div
      :class="{ 'job-card-info-block': true, strong, small, solid, clickable }"
      v-if="text && text !== '0'"
    >
      <v-icon :name="icon" scale="0.7" />
      <span>{{
        noLower ? text : text.toLowerCase ? text.toLowerCase() : text
      }}</span>
    </div>
    <div class="secondary" v-if="secondary">{{ secondary }}</div>
  </div>
</template>

<script>
const JobCardInfoBlock = {
  props: {
    icon: String,
    text: [String, Number],
    strong: Boolean,
    small: Boolean,
    noLower: Boolean,
    secondary: String,
    solid: Boolean,
    clickable: Boolean
  }
}

export default JobCardInfoBlock
</script>

<style lang="scss" scoped>
@import '../styles/JobCardInfoBlock';
</style>
