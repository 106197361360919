var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "skeleton-text" }, [
    _c("div", {
      class: ((_obj = {}), (_obj[_vm.size] = true), _obj),
      style: { height: _vm.height + "px" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }