import axios from 'axios'
import Vue from 'vue'
import cfg from '@/config'

const { $auth } = Vue.prototype

const baseUrl = `${cfg.uri}/unum/consumer-confirmation`

const ConfirmationsAPI = axios.create({
  baseURL: baseUrl
})

ConfirmationsAPI.interceptors.request.use(async request => {
  if (!$auth || $auth.skipAuth) {
    return request
  }

  // add auth token
  const token = await $auth.getTokenSilently()
  request.headers.Authorization = `Bearer ${token}`

  return request
})

ConfirmationsAPI.interceptors.response.use(response => response.data)

export { ConfirmationsAPI }
