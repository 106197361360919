import axios from 'axios'
import Vue from 'vue'
import cfg from '@/config'

const { $auth } = Vue.prototype

const VendorsAPI = axios.create({
  baseURL: `${cfg.uri}/unum/vendor`
})

VendorsAPI.interceptors.request.use(async request => {
  if (!$auth || $auth.skipAuth) {
    return request
  }

  // add auth token
  const token = await $auth.getTokenSilently()
  request.headers.Authorization = `Bearer ${token}`

  return request
})

VendorsAPI.interceptors.response.use(response => response.data)

export { VendorsAPI }
