var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
        title: "Manually Override EDI Status",
        id: _vm.modalId,
        "header-bg-variant": "primary",
        "header-text-variant": "light"
      },
      on: { hidden: _vm.dialogDisappeared, show: _vm.dialogAboutToAppear }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c("label", { attrs: { for: "ediStatusDropdown" } }, [
            _vm._v("Manually set or adjust the EDI status for this job:")
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-6" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.status,
                  expression: "status"
                }
              ],
              staticClass: "form-control form-control-sm",
              attrs: { disabled: _vm.buttonsDisabled, id: "ediStatusDropdown" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.status = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c("option", { attrs: { value: "Unsubmitted" } }, [
                _vm._v("Unsubmitted")
              ]),
              _c("option", { attrs: { value: "Sent" } }, [_vm._v("Sent")]),
              _c("option", { attrs: { value: "Paid" } }, [_vm._v("PAID")]),
              _c("option", { attrs: { value: "Accepted" } }, [
                _vm._v("ACCEPTED")
              ]),
              _c("option", { attrs: { value: "Rejected" } }, [
                _vm._v("REJECTED")
              ]),
              _c("option", { attrs: { value: "Unknown" } }, [_vm._v("UNKNOWN")])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c("label", { attrs: { for: "ediStatusMessage" } }, [
            _vm._v("Message (optional):")
          ]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.message,
                expression: "message"
              }
            ],
            staticClass: "form-control form-control-sm col-md-12",
            attrs: { disabled: _vm.buttonsDisabled, id: "ediStatusMessage" },
            domProps: { value: _vm.message },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.message = $event.target.value
              }
            }
          })
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          staticStyle: { "margin-top": "0px" },
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  disabled: _vm.buttonsDisabled,
                  type: "button",
                  size: "lg",
                  variant: "primary"
                },
                on: { click: _vm.setButtonPressed }
              },
              [_vm._v("Set")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  disabled: _vm.buttonsDisabled,
                  type: "button",
                  size: "lg",
                  variant: "primary"
                },
                on: { click: _vm.cancelButtonPressed }
              },
              [_vm._v("Cancel")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }