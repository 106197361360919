<template>
  <div class="scheduler-date-filter">
    <div class="ui-input-label">Date:</div>
    <el-date-picker
      v-model="date"
      type="date"
      placeholder="Pick a day"
      :picker-options="pickerOptions"
      :clearable="false"
    />
  </div>
</template>

<script>
import { subDays, addDays } from 'date-fns'
import { isMobile } from '@/scripts/helpers/helpers'

export default {
  props: ['value'],
  data() {
    return {
      pickerOptions: {
        ...(isMobile
          ? {}
          : {
            shortcuts: [
              {
                text: 'Today',
                onClick(picker) {
                  picker.$emit('pick', new Date())
                }
              },
              {
                text: 'Yesterday',
                onClick(picker) {
                  picker.$emit('pick', subDays(new Date(), 1))
                }
              },
              {
                text: 'Tomorrow',
                onClick(picker) {
                  picker.$emit('pick', addDays(new Date(), 1))
                }
              }
            ]
          })
      }
    }
  },
  computed: {
    date: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  mounted() {
    this.$emit('input', new Date())
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/SchedulerDateFilter';
</style>

<style lang="scss">
.date-picker {
  input {
    border: 1px solid #e8e8e8;
    box-shadow: none;
    height: 40px;
  }
}
</style>
