var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        width: "900px",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        "custom-class": "add-payment-dialog t-font-sans"
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-gray-700 t-text-lg",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-bank-card" }),
          _c("span", { staticClass: "t-ml-3" }, [_vm._v(" Edit payment ")])
        ]
      ),
      _c(
        "div",
        { staticClass: "t-pt-6" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "t-overflow-y-auto",
              staticStyle: { "max-height": "700px" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
                "hide-required-asterisk": "",
                size: "medium"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "type" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("Type")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "t-w-72",
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Payment", value: "Payment" }
                      }),
                      _c("el-option", {
                        attrs: { label: "Adjustment", value: "Adjustment" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "date" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("Date")]
                  ),
                  _c("el-date-picker", {
                    staticClass: "t-w-72",
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "Pick a date"
                    },
                    model: {
                      value: _vm.form.date,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "x_action_type" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("X-action Type")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "t-w-72",
                      attrs: { placeholder: "Select X-action Type" },
                      model: {
                        value: _vm.form.x_action_type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "x_action_type", $$v)
                        },
                        expression: "form.x_action_type"
                      }
                    },
                    _vm._l(_vm.xActionTypes[_vm.form.type], function(item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "x_action_number" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("X-action Number")]
                  ),
                  _c("el-input", {
                    staticClass: "t-w-72",
                    attrs: { placeholder: "Enter X-action Number" },
                    model: {
                      value: _vm.form.x_action_number,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "x_action_number", $$v)
                      },
                      expression: "form.x_action_number"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "payment_account_id" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("Payment account")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "t-w-72",
                      attrs: { placeholder: "Select Payment Account" },
                      model: {
                        value: _vm.form.payment_account_id,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "payment_account_id", $$v)
                        },
                        expression: "form.payment_account_id"
                      }
                    },
                    _vm._l(_vm.accounts, function(i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { value: i.id, label: i.name }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.form.payments, function(payment, index) {
                return _c("div", { key: index, staticClass: "t-mx-6" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "t-flex t-border t-border-solid t-border-gray-300\n                    t-rounded-md t-my-2 t-px-4 t-py-2"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "t-flex-grow t-flex t-justify-between t-pr-8"
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "t-text-gray-600" }, [
                              _vm._v("Job id")
                            ]),
                            _c("div", { staticClass: "t-text-base" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/jobs/" + payment.job_id,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(" " + _vm._s(payment.job_id) + " ")]
                              )
                            ])
                          ]),
                          _c("div", [
                            _c("div", { staticClass: "t-text-gray-600" }, [
                              _vm._v("Total balance")
                            ]),
                            _c("div", { staticClass: "t-text-base" }, [
                              _vm._v("$" + _vm._s(payment.total_balance))
                            ])
                          ]),
                          _c("div", [
                            _c("div", { staticClass: "t-text-gray-600" }, [
                              _vm._v("Remaining balance")
                            ]),
                            _c("div", { staticClass: "t-text-base" }, [
                              _vm._v("$" + _vm._s(payment.remaining_balance))
                            ])
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "t-w-72" },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "t-mb-1",
                              attrs: {
                                "label-width": "0",
                                prop: "payments." + index + ".amount",
                                "show-message": false,
                                rules: [
                                  {
                                    required: true
                                  }
                                ]
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "t-w-full",
                                attrs: {
                                  size: "small",
                                  type: "number",
                                  placeholder: "Amount"
                                },
                                model: {
                                  value: _vm.form.payments[index].amount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form.payments[index],
                                      "amount",
                                      $$v
                                    )
                                  },
                                  expression: "form.payments[index].amount"
                                }
                              })
                            ],
                            1
                          ),
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "Memo" },
                            model: {
                              value: _vm.form.payments[index].memo,
                              callback: function($$v) {
                                _vm.$set(_vm.form.payments[index], "memo", $$v)
                              },
                              expression: "form.payments[index].memo"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              })
            ],
            2
          ),
          _c("div", { staticClass: "t-flex t-items-baseline t-my-6 t-mx-6" }, [
            _c(
              "div",
              {
                staticClass:
                  "t-text-gray-600 t-mr-2 t-text-base t-flex-grow t-text-right"
              },
              [_vm._v(" Total ")]
            ),
            _c("div", { staticClass: "t-text-xl t-font-medium" }, [
              _vm._v("$" + _vm._s(_vm.total))
            ])
          ]),
          _c(
            "div",
            {
              staticClass:
                "t-bg-gray-200 t-py-3 t-px-6 t-flex t-justify-between"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "small" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading,
                    icon: "el-icon-plus",
                    size: "small"
                  },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v(" Update ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }