var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gb-action-button",
      class: { disabled: _vm.disabled, dark: _vm.dark },
      on: { click: _vm.click }
    },
    [
      _vm.prefix
        ? _c("i", {
            class:
              "material-icons-outline md-" +
              _vm.prefix +
              " " +
              (_vm.space ? "space-left" : "")
          })
        : _vm._e(),
      _vm._t("default"),
      _vm.suffix
        ? _c("i", {
            class:
              "material-icons-outline md-" +
              _vm.suffix +
              " " +
              (_vm.space ? "space-right" : "")
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }