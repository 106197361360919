<template>
  <el-dialog
    title="Add custom waypoint"
    :visible.sync="visible"
    @closed="onClosed"
    @close="visible = false"
    :modal-append-to-body="false"
  >
    <div v-loading="loading">
      <div class="t-flex">
        <div class="t-py-3">
          <div>Select location</div>
          <el-select v-model="waypoint" value-key="id" :clearable="true">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
              <div class="t-flex t-justify-between">
                <div class="t-text-gray-800 t-mr-3">{{ item.name }}</div>
                <div class="t-text-gray-500">
                  {{ item.g_address.full_address }}
                </div>
              </div>
            </el-option>
            <el-option label="+ Create new" :value="{}" />
          </el-select>
          <div class="t-mt-2">Type</div>
          <el-select v-model="route_entry.type">
            <el-option
              v-for="item in types"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            />
          </el-select>
        </div>
        <div
          v-if="isNew"
          class="t-flex-grow t-ml-3 t-p-3 t-rounded-sm t-bg-gray-200"
        >
          <div>New location name</div>
          <el-input
            v-model="waypoint.name"
            label="Name"
            placeholder="Custom location name"
          />
          <div class="t-mt-2">Address</div>
          <address-input v-model="waypoint.g_address" popperFix />
        </div>
      </div>

      <div class="t-mt-2">Time</div>
      <div class="t-flex">
        <el-time-picker
          arrow-control
          class="t-mr-3"
          v-if="route_entry.type !== 'start'"
          v-model="route_entry.arrival"
          :placeholder="
            route_entry.type === 'appointment' ? 'Start' : 'Arrival'
          "
          :picker-options="{
            format: 'hh:mm a'
          }"
          format="hh:mm a"
        >
        </el-time-picker>
        <el-time-picker
          v-if="route_entry.type !== 'end'"
          v-model="route_entry.departure"
          arrow-control
          :placeholder="
            route_entry.type === 'appointment' ? 'End' : 'Departure'
          "
          :picker-options="{
            format: 'hh:mm a'
          }"
          format="hh:mm a"
        >
        </el-time-picker>
      </div>
      <div :style="{ maxWidth: '452px' }">
        <div class="t-mt-2">Notes</div>
        <el-input type="textarea" v-model="route_entry.notes" />
      </div>
    </div>
    <div slot="footer">
      <el-button @click="visible = false" size="small">
        Cancel
      </el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="disabled"
        @click="onSubmit"
      >
        Submit
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { sync, call } from 'vuex-pathify'
import * as R from 'ramda'
import actions from '@/store/modules/scheduler_v3/types'
import { formatTimeToDbShort, formatToDb } from '@/scripts/helpers/date.helpers'

export default {
  data() {
    return {
      loading: false,
      types: [
        { key: 'start', label: 'Route start' },
        { key: 'end', label: 'Route end' },
        { key: 'appointment', label: 'Appointment' }
      ],
      visible: false,
      waypoint: null,
      route_entry: {
        type: 'appointment',
        arrival: null,
        departure: null
      }
    }
  },
  computed: {
    date: sync('sch_v3/filters@date'),
    tech: sync('sch_v3/waypointModalTech'),
    options() {
      return R.pathOr([], ['tech', 'route_tech_stops'])(this)
    },
    isNew() {
      return this.waypoint && !this.waypoint.id
    },
    disabled() {
      return !R.allPass([
        R.path(['waypoint', 'name']),
        R.path(['waypoint', 'g_address', 'google_place_id'])
      ])(this)
    }
  },
  watch: {
    tech() {
      if (this.tech) {
        this.visible = true
      }
    }
  },
  methods: {
    onClosed() {
      this.waypoint = null
      this.loading = false
      this.tech = null
      this.route_entry = {
        type: 'appointment',
        arrival: null,
        departure: null
      }
    },
    createTechRouteEntryStop: call(actions.createTechRouteEntryStop),
    async onSubmit() {
      const body = {
        tech_stop: { ...this.waypoint, tech_id: this.tech.id },
        route_entry: {
          ...R.evolve({
            arrival: R.unless(R.isNil, formatTimeToDbShort),
            departure: R.unless(R.isNil, formatTimeToDbShort)
          })(this.route_entry),
          route_date: formatToDb(this.date),
          route_tech_id: this.tech.id,
          route_shop_id: this.tech.shop_id,
          order: this.tech.order
        }
      }
      this.loading = true
      await this.createTechRouteEntryStop(body)
      this.loading = false
      this.visible = false
    }
  }
}
</script>
