import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default class Salesource extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  name = ''
  details = ''
  modified = -1
  shop_id = -1
  commission = ''
  commission_flat_or_percent = 'flat'
  default_rep_id = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, callbackFunction) {
    Api({ url: `/api/shop/${shopId}/salesource` }).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new Salesource(val))
      })
      callbackFunction(objects)
    })
  }

  // GET ALL SALE SOURCES BY USER + FILTERS
  static getSaleSourcesByUserShops (page, limit, sortBy, sortDesc, salesourceIdFilter, salesourceNameFilter,
    salesourceShopNameFilter, successCallback, errorCallback) {
    Api({url: `/api/usersalesources?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&salesourceIdFilter=' + encodeURIComponent(salesourceIdFilter) + '&salesourceNameFilter=' + encodeURIComponent(salesourceNameFilter) +
      '&salesourceShopNameFilter=' + encodeURIComponent(salesourceShopNameFilter)
    }).then(res => {
      
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  createUpdateSalesource (shopId, callbackFunction, errorCallbackFunction = null) {
    this.commission = UtilityMixin.methods.formatFloatDecimals(this.commission, 2, 2)
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    
    return Api.post(`/api/shop/${this.shop_id}/salesource/${this.id}`, this).then(res => {
      
      
      callbackFunction(res.data)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
