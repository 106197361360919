<template>
  <div class="md-label-tag" :style="style">
    <i :class="{ [mdi]: true, [mdIcon]: true }" v-if="icon" />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    icon: {},
    outline: {
      type: Boolean,
      default: () => true
    },
    color: {
      default: () => '#888888'
    }
  },
  computed: {
    mdi() {
      return this.outline ? 'material-icons-outline' : 'material-icons'
    },
    mdIcon() {
      return `md-${this.icon}`
    },
    style() {
      return {
        background: this.color,
        color: '#FFF'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.md-label-tag {
  padding: $padding-xxs $padding-xs;
  border-radius: 4px;
  display: flex;
  align-items: center;
  i {
    font-size: 12px;
    margin-right: $padding-xxs;
  }
  font-size: $font-sm;
  line-height: $font-lg;
}
</style>
