var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "t-font-sans",
      attrs: {
        "append-to-body": "",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        width: "800px",
        top: "100px"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "t-text-base t-text-gray-700",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.units, size: "mini" } },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "Inv Unit #" } }),
          _c("el-table-column", {
            attrs: { label: "Job #" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.jobId
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "t-font-normal t-text-xs t-text-blue-500",
                            attrs: {
                              target: "_blank",
                              href: "/jobs/" + scope.row.jobId
                            }
                          },
                          [_vm._v(" " + _vm._s(scope.row.jobId) + " ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "PO #" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.purchaseOrderId
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "t-font-normal t-text-xs t-text-blue-500 hover:t-underline t-cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.openPO(scope.row.purchaseOrderId)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.purchaseOrderInternalId) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Receive #" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.receiveId
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "t-font-normal t-text-xs t-text-blue-500 hover:t-underline t-cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.openReceive(scope.row.receiveId)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(scope.row.receiveInternalId) + " "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "TechPU #" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.techPickUpId
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "t-font-normal t-text-xs t-text-blue-500 hover:t-underline t-cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.openTechPickup(
                                  scope.row.techPickUpId
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(scope.row.techPickUpInternalId) + " "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "t-text-right t-mt-6" }, [
        _vm._v(" Total: "),
        _c("span", { staticClass: "t-text-lg t-mx-2" }, [
          _vm._v(_vm._s(_vm.units.length))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }