import Api from '@/services/Api'
import { chatTypes } from './types'
import { Notification } from 'element-ui'
import { chatViews } from '@/constants/chat.config'
import { ChatService } from '@/services/ChatService'

export const actions = {
  [chatTypes.actions.init]: async ({ commit, dispatch }) => {
    const success = await ChatService.init()
    if (success) {
      await dispatch(chatTypes.actions.getUserChannels)
      commit(chatTypes.mutations.setConnected, true)
    }
  },

  [chatTypes.actions.activateChatService]: async (_, { accountId }) => {
    const { data } = await Api.post('/api/chat/services', { accountId })
    return data
  },

  [chatTypes.actions.generateToken]: async () => {
    const { data } = await Api.get('/api/chat/token')
    return data
  },

  [chatTypes.actions.getUsers]: async ({ commit }) => {
    commit(chatTypes.mutations.setUsers, [])
    const { data } = await Api.get('/api/chat/users')
    commit(chatTypes.mutations.setUsers, data)
  },

  [chatTypes.actions.open]: ({ commit }) => {
    commit(chatTypes.mutations.setOpen, true)
  },

  [chatTypes.actions.close]: ({ commit }) => {
    commit(chatTypes.mutations.setOpen, false)
  },

  [chatTypes.actions.openChannel]: async (
    { state, commit, dispatch },
    channel
  ) => {
    commit(chatTypes.mutations.setChannelIsOpenning, true)
    if (state.view !== chatViews.view) {
      await dispatch(chatTypes.actions.changeView, chatViews.view)
    }
    commit(chatTypes.mutations.setActiveChannel, {
      sid: channel.sid,
      uniqueName: channel.uniqueName,
      friendlyName: channel.friendlyName,
      isPrivate: channel.isPrivate,
      createdBy: channel.createdBy,
      dateCreated: channel.dateCreated
    })
    ChatService.setActiveChannel(channel)
    commit(chatTypes.mutations.setChannelIsOpenning, false)
    commit(chatTypes.mutations.setUnreadMessagesCountForSingleChannel, {
      uniqueName: channel.uniqueName,
      count: 0
    })
    await channel.setAllMessagesConsumed()
  },

  [chatTypes.actions.changeView]: ({ commit }, view) => {
    commit(chatTypes.mutations.setView, view)
    if (view !== chatViews.view) {
      commit(chatTypes.mutations.setActiveChannel, null)
    }
  },

  [chatTypes.actions.setNewMessage]: async (
    { commit, state, rootState, dispatch },
    message
  ) => {
    if (message) {
      commit(chatTypes.mutations.setNewMessage, message)
      const channel = await ChatService.getChannelBySid(message.channel.sid)
      if (
        state.activeChannel &&
        message.channel.uniqueName === state.activeChannel.uniqueName &&
        state.isOpen
      ) {
        await channel.setAllMessagesConsumed()
      } else {
        if (message.author !== rootState.user.id) {
          commit(chatTypes.mutations.setUnreadMessagesCountForSingleChannel, {
            uniqueName: message.channel.uniqueName,
            count: state.unreadMessagesCount[message.channel.uniqueName] + 1
          })
          const notification = Notification({
            duration: 10000,
            title: message.channel.name,
            dangerouslyUseHTMLString: true,
            message: `<span class="t-mr-2 t-font-bold t-text-gray-700">${message.author}:</span>${message.body}`,
            position: 'bottom-right',
            customClass: 't-cursor-pointer t-shadow-xl t-font-sans',
            onClick: () => {
              if (!state.isOpen) {
                dispatch(chatTypes.actions.open)
              }
              dispatch(chatTypes.actions.openChannel, channel)
              notification.close()
            }
          })
        }
      }
    }
  },

  [chatTypes.actions.setChannelInfoDrawerIsOpen]: ({ commit }, value) => {
    commit(chatTypes.mutations.setChannelInfoDrawerIsOpen, value)
  },

  [chatTypes.actions.setMembersDialogIsOpen]: ({ commit }, value) => {
    commit(chatTypes.mutations.setMembersDialogIsOpen, value)
  },

  [chatTypes.actions.getSubscribedUsers]: async ({ commit }) => {
    const users = await ChatService.getSubscribedUsers()
    commit(chatTypes.mutations.setSubscribedUsers, users.map(i => {
      return {
        identity: i.identity,
        online: i.online
      }
    }))
  },

  [chatTypes.actions.getUserChannels]: async ({ commit, dispatch }) => {
    commit(chatTypes.mutations.setUserChannelsLoading, true)
    let paginator = await ChatService.getUserChannels()
    const channels = paginator.items
    while (paginator.hasPrevPage) {
      paginator = await paginator.prevPage()
      channels.unshift(...paginator.items)
    }
    commit(chatTypes.mutations.setUserChannels, channels.map(i => {
      return {
        sid: i.sid,
        uniqueName: i.uniqueName,
        friendlyName: i.friendlyName,
        isPrivate: i.isPrivate,
        status: i.status,
        type: i.type,
        lastConsumedMessageIndex: i.lastConsumedMessageIndex
      }
    }))
    commit(chatTypes.mutations.setUserChannelsLoading, false)
    dispatch(chatTypes.actions.getUnreadMessagesCount, channels)
  },

  [chatTypes.actions.getUnreadMessagesCount]: async (
    { commit },
    channelDescriptors
  ) => {
    const counts = {}
    for (const channelDescriptor of channelDescriptors) {
      const channel = await channelDescriptor.getChannel()
      const count = await channel.getUnconsumedMessagesCount()
      counts[channel.uniqueName] = count || 0
    }
    commit(chatTypes.mutations.setUnreadMessagesCountForAllChannels, counts)
  },

  [chatTypes.actions.getMembers]: async ({ commit }, channel) => {
    const members = await channel.getMembers()
    commit(
      chatTypes.mutations.setMembers,
      members.map(i => {
        return {
          identity: i.identity
        }
      })
    )
  },

  [chatTypes.actions.getTotalMessages]: async ({ commit }, channel) => {
    const count = await channel.getMessagesCount()
    commit(chatTypes.mutations.setTotalMessage, count)
  }
}
