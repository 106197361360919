<template>
  <el-button
    v-bind="{ ...$props, ...$attrs }"
    class="md-icon-button"
    :class="{ reverse }"
    @click="$emit('click')"
    :icon="`material-icons-outline md-18 md-${icon}`"
  >
    <slot />
  </el-button>
</template>

<script>
export default {
  props: {
    icon: String,
    size: {
      type: String,
      default: () => 'mini'
    },
    reverse: Boolean
  }
}
</script>

<style lang="scss">
.md-icon-button {
  &.el-button--mini {
    height: 28px;
  }
  display: flex;
  align-items: center;
  .material-icons-outline {
    margin-right: 8px;
  }
  &.reverse {
    flex-direction: row-reverse;
    justify-content: center;
    .material-icons-outline {
      margin-right: 0;
    }
    span {
      margin-left: 8px;
    }
  }
}
</style>
