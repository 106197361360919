var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-tech-location-infowindow" },
    [
      _c("el-card", { attrs: { "body-style": { padding: "10px" } } }, [
        _c("span", [_vm._v(" " + _vm._s(_vm.username) + " ")])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }