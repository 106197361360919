var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kit-select", class: { focused: _vm.focused } },
    [
      _c(
        "number-input",
        {
          ref: "inputRef",
          staticClass: "input-with-select",
          attrs: {
            placeholder: "0",
            allowNegative: _vm.allowNegative,
            size: _vm.size || "small"
          },
          on: {
            focus: _vm.onFocus,
            blur: _vm.onBlur,
            change: function($event) {
              return _vm.$emit("change", $event)
            }
          },
          model: {
            value: _vm.amount,
            callback: function($$v) {
              _vm.amount = $$v
            },
            expression: "amount"
          }
        },
        [
          _c(
            "el-button",
            {
              attrs: { slot: "append" },
              on: { click: _vm.toggleType },
              nativeOn: {
                focus: function($event) {
                  return _vm.onFocus($event)
                },
                blur: function($event) {
                  return _vm.onBlur($event)
                }
              },
              slot: "append"
            },
            [
              _c("div", { staticClass: "kit-select-type-button" }, [
                _vm._v(" " + _vm._s(_vm.type) + " "),
                _c("i", {
                  staticClass: "material-icons md-arrow_drop_down md-18"
                })
              ])
            ]
          ),
          _vm._t("prefix", null, { slot: "prefix" })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }