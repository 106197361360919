import { render, staticRenderFns } from "./InfoDrawer.vue?vue&type=template&id=781ffa7a&"
import script from "./InfoDrawer.vue?vue&type=script&lang=js&"
export * from "./InfoDrawer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('781ffa7a')) {
      api.createRecord('781ffa7a', component.options)
    } else {
      api.reload('781ffa7a', component.options)
    }
    module.hot.accept("./InfoDrawer.vue?vue&type=template&id=781ffa7a&", function () {
      api.rerender('781ffa7a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Chat/Views/Channel/InfoDrawer.vue"
export default component.exports