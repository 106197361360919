var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Add custom waypoint",
        visible: _vm.visible,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        closed: _vm.onClosed,
        close: function($event) {
          _vm.visible = false
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c("div", { staticClass: "t-flex" }, [
            _c(
              "div",
              { staticClass: "t-py-3" },
              [
                _c("div", [_vm._v("Select location")]),
                _c(
                  "el-select",
                  {
                    attrs: { "value-key": "id", clearable: true },
                    model: {
                      value: _vm.waypoint,
                      callback: function($$v) {
                        _vm.waypoint = $$v
                      },
                      expression: "waypoint"
                    }
                  },
                  [
                    _vm._l(_vm.options, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "t-flex t-justify-between" },
                            [
                              _c(
                                "div",
                                { staticClass: "t-text-gray-800 t-mr-3" },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _c("div", { staticClass: "t-text-gray-500" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.g_address.full_address) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    _c("el-option", {
                      attrs: { label: "+ Create new", value: {} }
                    })
                  ],
                  2
                ),
                _c("div", { staticClass: "t-mt-2" }, [_vm._v("Type")]),
                _c(
                  "el-select",
                  {
                    model: {
                      value: _vm.route_entry.type,
                      callback: function($$v) {
                        _vm.$set(_vm.route_entry, "type", $$v)
                      },
                      expression: "route_entry.type"
                    }
                  },
                  _vm._l(_vm.types, function(item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.label, value: item.key }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.isNew
              ? _c(
                  "div",
                  {
                    staticClass:
                      "t-flex-grow t-ml-3 t-p-3 t-rounded-sm t-bg-gray-200"
                  },
                  [
                    _c("div", [_vm._v("New location name")]),
                    _c("el-input", {
                      attrs: {
                        label: "Name",
                        placeholder: "Custom location name"
                      },
                      model: {
                        value: _vm.waypoint.name,
                        callback: function($$v) {
                          _vm.$set(_vm.waypoint, "name", $$v)
                        },
                        expression: "waypoint.name"
                      }
                    }),
                    _c("div", { staticClass: "t-mt-2" }, [_vm._v("Address")]),
                    _c("address-input", {
                      attrs: { popperFix: "" },
                      model: {
                        value: _vm.waypoint.g_address,
                        callback: function($$v) {
                          _vm.$set(_vm.waypoint, "g_address", $$v)
                        },
                        expression: "waypoint.g_address"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "t-mt-2" }, [_vm._v("Time")]),
          _c(
            "div",
            { staticClass: "t-flex" },
            [
              _vm.route_entry.type !== "start"
                ? _c("el-time-picker", {
                    staticClass: "t-mr-3",
                    attrs: {
                      "arrow-control": "",
                      placeholder:
                        _vm.route_entry.type === "appointment"
                          ? "Start"
                          : "Arrival",
                      "picker-options": {
                        format: "hh:mm a"
                      },
                      format: "hh:mm a"
                    },
                    model: {
                      value: _vm.route_entry.arrival,
                      callback: function($$v) {
                        _vm.$set(_vm.route_entry, "arrival", $$v)
                      },
                      expression: "route_entry.arrival"
                    }
                  })
                : _vm._e(),
              _vm.route_entry.type !== "end"
                ? _c("el-time-picker", {
                    attrs: {
                      "arrow-control": "",
                      placeholder:
                        _vm.route_entry.type === "appointment"
                          ? "End"
                          : "Departure",
                      "picker-options": {
                        format: "hh:mm a"
                      },
                      format: "hh:mm a"
                    },
                    model: {
                      value: _vm.route_entry.departure,
                      callback: function($$v) {
                        _vm.$set(_vm.route_entry, "departure", $$v)
                      },
                      expression: "route_entry.departure"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { style: { maxWidth: "452px" } },
            [
              _c("div", { staticClass: "t-mt-2" }, [_vm._v("Notes")]),
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.route_entry.notes,
                  callback: function($$v) {
                    _vm.$set(_vm.route_entry, "notes", $$v)
                  },
                  expression: "route_entry.notes"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", disabled: _vm.disabled },
              on: { click: _vm.onSubmit }
            },
            [_vm._v(" Submit ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }