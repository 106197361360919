<template>
  <el-collapse class="primary-fields-selector" v-model="active">
    <el-collapse-item name="1">
      <template slot="title">
        <div class="item-title">
          <span>{{ title }}</span>
          <el-button
            type="text"
            icon="el-icon-circle-plus"
            @click.stop="addRow"
            @keydown.stop
          >
            Add {{ fieldLabel }}
          </el-button>
        </div>
      </template>
      <el-row :gutter="16">
        <el-col :span="span" v-for="(col, idx) in cols" :key="col.id">
          <div
            class="field-container"
            :class="{ primary: primaryIndex === idx,
              secondary: secondaryIndex === idx }"
          >
            <gb-form
              :schema="getSchemaWithLabels(idx)"
              :value="col"
              @input="updateField($event, col, idx)"
              ref="forms"
              :auto-focus="idx === lastAdded"
            />
            <primary-field-action-buttons
              :type='type'
              :isPrimary="primaryIndex === idx"
              :isSecondary="secondaryIndex === idx"
              @setPrimary="makePrimary(col)"
              @setSecondary="makeSecondary(col)"
              @unsetSecondary="unsetSecondary(col)"
              @clear="deleteRow(idx)"
            />
          </div>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import * as R from 'ramda'
import PrimaryFieldActionButtons from './PrimaryFieldActionButtons'

export default {
  components: {
    PrimaryFieldActionButtons
  },
  data() {
    return {
      active: ['1'],
      lastAdded: null
    }
  },
  props: {
    span: {
      type: Number,
      default: () => 12
    },
    title: String,
    type: String,
    fieldLabel: String,
    valuePath: String,
    value: Object,
    schema: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    cols() {
      return this.value.list
    },
    primary() {
      return this.value.primary
    },
    primaryIndex() {
      return R.findIndex(R.equals(this.primary))(this.cols) || 0
    },
    secondary() {
      return this.value.secondary
    },
    secondaryIndex() {
      return R.findIndex(R.equals(this.secondary))(this.cols)
    }
  },
  methods: {
    getLabel(col, idx) {},
    updateField(value, col, idx) {
      const data = {
        list: R.set(R.lensIndex(idx), value, this.cols),
        primary: this.primaryIndex === idx ? value : this.primary,
        secondary: this.secondaryIndex === idx ? value : this.secondary
      }
      this.$emit('input', data)
    },
    addRow() {
      const value = this.schema
        .map(R.prop('id'))
        .filter(R.identity)
        .reduce((c, acc) => ({ ...c, [acc]: '' }), {})
      const evolve = {
        list: R.append(value)
      }
      if (this.primaryIndex < 0) {
        evolve.primary = R.always(value)
      }

      this.$emit('input', R.evolve(evolve)(this.value))
      this.lastAdded = this.value?.list?.length
    },
    deleteRow(idx) {
      this.$emit('input', R.evolve({ list: R.remove(idx, 1) })(this.value))
    },
    makePrimary(col) {
      let val = this.value
      if (this.secondaryIndex > -1 && col.id === this.cols[this.secondaryIndex].id) {
        val = R.omit(['secondary'])(val)
      }
      this.$emit('input', R.evolve({ primary: R.always(col) })(val))
    },
    makeSecondary(col) {
      this.$emit('input', R.evolve({ secondary: R.always(col) })(this.value))
    },
    unsetSecondary(col) {
      this.$emit('input', R.omit(['secondary'])(this.value))
    },
    getSchemaWithLabels(idx) {
      return this.schema.map((v) =>
        R.map(
          R.evolve({
            label: (label) =>
              this.primaryIndex === idx
                ? `1st ${label}`
                : this.secondaryIndex === idx 
                  ? `2nd ${label}`
                  : `${label} ${idx + 1}`
          })
        )(v)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.primary-fields-selector {
  .item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }
  .field-container {
    padding: $padding-md $padding-xs $padding-xs;
    border-radius: 6px;
    position: relative;
    line-height: 0px;
    font-size: smaller;
    color: #409EFF;
    &.primary {
      background: #f1f1f1;
    }
    &.secondary {
      background: #e9e9e9
    }
    .field-bottom-row {
      display: flex;
      align-items: center;
      margin-top: $padding-xxs;
    }
  }
}
</style>

<style lang="scss">
.primary-fields-selector {
  border-top: none;
  border-bottom: none;
  .el-collapse-item__header {
    background: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .el-collapse-item__arrow {
      margin-left: 0;
    }
  }
  .el-collapse-item__wrap {
    background: none;
  }
}
</style>
