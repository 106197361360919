<template>
  <i class="material-icons md-playlist_add_check md-18" v-if="added" />
  <i class="material-icons md-playlist_add md-18" v-else ref="check" />
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  props: {
    part: {}
  },
  computed: {
    partno() {
      return (this.part.part_number || this.part.partno).replace(/ /g, '')
    },
    added: sync('jobDetails/nags@:partno')
  },
  methods: {
    add() {
      this.added = true
      this.$el.animate(
        [
          { transform: 'scale(1)' },
          { transform: 'scale(1.3)' },
          { transform: 'scale(1)' }
        ],
        { duration: 300 }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.material-icons {
  color: #fff;
  margin-right: $padding-xss;
  transition: all 0.2s ease-in-out;
}
</style>
