export const reportsMenuConfig = [
  // these get pulled into reports.state when a new report is selected
  {
    label: 'Sales Reports',
    id: 'sales',
    children: [
      {
        label: 'Sales report',
        id: 'sales',
        group: 'sales',
        filter: 'contractors',
        groupBy: 'contractorsGroup',
        total_key: 'total_after_taxes'
      },
      {
        label: 'Margin report',
        id: 'margin',
        group: 'sales',
        total_key: 'total_margin',
        filter: 'contractors',
        groupBy: 'contractorsGroup',
      },
      {
        label: 'Customer Summary',
        id: 'customer-summary',
        group: 'sales'
      },
      {
        label: 'Royalty Summary',
        id: 'royalty-summary',
        group: 'royalty',
        date: 'Payment Date'
      }
    ]
  },
  {
    label: 'Sales tax',
    id: 'tax',
    children: [
      {
        label: 'Accrual Detail',
        id: 'tax-detail',
        group: 'tax',
        filter: 'taxes'
      },
      {
        label: 'Accrual Summary',
        id: 'tax-summary',
        group: 'tax',
        filter: 'taxes'
      },
      {
        label: 'Cash Basis detail',
        id: 'detail',
        group: 'cash-basis',
        filter: 'taxes',
        total_key: 'total_tax_collected',
        date: 'Payment Date'
      },
      {
        label: 'Cash Basis summary',
        id: 'summary',
        group: 'cash-basis',
        filter: 'taxes',
        date: 'Payment Date'
      }
    ]
  },
  {
    label: 'Schedule Reports',
    id: 'scheduler',
    children: [
      {
        label: 'Schedule report',
        id: 'schedule',
        group: 'scheduler',
        filter: 'techs',
        default_date_type: 'scheduled_date'
      },
      // {
      //   label: 'In/Out Report',
      //   id: 'in-out',
      //   group: 'in-out',
      //   groupBy: 'listBy',
      //   filter: 'tags'
      // },
      {
        label: 'Manifest',
        id: 'manifest',
        disabled: true
      }
    ]
  },
  {
    label: 'Install Reports',
    id: 'install',
    children: [
      {
        label: 'Installers',
        id: 'installers',
        group: 'install',
        filter: 'techs',
        total_key: 'total_tech_commission',
        groupBy: 'tech'
      },
      {
        label: 'Sales reps',
        id: 'salesreps',
        group: 'install',
        filter: 'salesreps',
        total_key: 'total_sales_rep_commission',
        groupBy: 'salesrep'
      },
      {
        label: 'Sales sources',
        id: 'salesources',
        group: 'install',
        filter: 'salesources',
        total_key: 'total_sale_source_commission',
        groupBy: 'salesource'
      },
      {
        label: 'Locations',
        id: 'locations',
        group: 'install',
        filter: 'locations',
        total_key: 'total_locations_commission',
        groupBy: 'location'
      },
      {
        label: 'Tech collections',
        id: 'tech-collections',
        group: 'install',
        filter: 'techs',
        total_key: 'total_tech_commission',
        groupBy: 'tech'
      },
      {
        label: 'Customer Service Reps',
        id: 'csrs',
        group: 'install',
        filter: 'csrs',
        total_key: 'total_csr_commission',
        groupBy: 'csr'
      }
    ]
  },
  {
    label: 'Payments',
    id: 'payment',
    children: [
      {
        label: 'Payment receipt',
        id: 'receipt',
        group: 'payment',
        date: 'Payment Date',
        total_key: 'amount'
      }
    ]
  },
  {
    label: 'Statements',
    id: 'statements',
    children: [
      {
        label: 'Account statement',
        id: 'account-statement',
        date: 'Statement Date',
        group: 'statements'
      },
      {
        label: 'Open invoice report',
        id: 'open-invoice',
        date: 'Statement Date',
        group: 'statements'
      },
      {
        label: 'Aging Report',
        id: 'aging'
        // group: 'aging',
      }
    ]
  },
  {
    label: 'Accounting Reports',
    id: 'accountingReports',
    children: [
      {
        label: 'Profit and Loss',
        id: 'profit-and-loss',
        date: 'Date Range',
        group: 'accountingReports',
        hideShopSelect: false
      },
      {
        label: 'Balance Sheet',
        id: 'balance-sheet',
        date: 'Date',
        group: 'accountingReports',
        hideShopSelect: false
      }
    ]
  },
  {
    label: 'Miscellaneous Reports',
    id: 'miscellaneous',
    children: [
      {
        label: 'Sales Rep/Source',
        id: 'sales-rep-source',
        group: 'miscellaneous',
        disabled: true
      },
      {
        label: 'Right Part',
        id: 'requests',
        group: 'right-part',
        date: 'Request date'
      },
      {
        label: 'Nags License',
        id: 'nags-license',
        group: 'nags-license',
        date: 'Renewal Date',
        adminOnly: true,
        hideShopSelect: true
      },
      {
        label: 'EDI Report',
        id: 'edi',
        group: 'edi',
        date: 'Submit Date',
        adminOnly: true,
        hideShopSelect: true
      },
      {
        label: 'Install List',
        id: 'install-list',
        group: 'install-list',
        date: 'Install Date',
        default_date_type: 'install_date'
      }
    ]
  }
]

export const dateFilterMap = {
  call_back_date: 'Call Back Date',
  call_back_complete_date: 'Call Back Complete Date',
  draft_date: 'Draft Date',
  drop_off_date: 'Drop Off Date',
  drop_off_complete_date: 'Drop Off Complete Date',
  install_date: 'Install Date',
  invoice_date: 'Invoice Date',
  pick_up_date: 'Pick Up Date',
  pick_up_complete_date: 'Pick Up Complete Date',
  quote_date: 'Quote Date',
  r_and_i_date: 'R&I Date',
  r_and_i_complete_date: 'R&I Complete Date',
  recal_date: 'Recal Date',
  recal_complete_date: 'Recal Complete Date',
  tint_date: 'Tint Date',
  tint_complete_date: 'Tint Complete Date',
  void_date: 'Void Date',
  work_order_date: 'Work Order Date',
  warranty_date: 'Warranty Date',
  warranty_complete_date: 'Warranty Complete Date'
}

/// GROUP BY:
export const tableModes = ['None', 'By date', 'By shop']
export const paymentTableModes = ['None', 'By date', 'By shop', 'By Customer', 'By x action type', 'By Contractor']
export const agingTableModes = ['None', 'By customer', 'By insurance']
export const salesTableModes = ['None', 'By date', 'By shop', 'By Vendor']

export const filterDisplayConfig = {
  taxes: ['tax-detail', 'tax-summary', 'detail', 'summary'],
  techs: ['installers', 'schedule', 'tech-collections'],
  salesreps: ['salesreps'],
  salesources: ['salesources'],
  locations: ['locations'],
  csrs: ['csrs']
}

export const filterDisplayLabels = {
  techs: 'Installers',
  salesreps: 'Sales Reps',
  salesources: 'Sale Sources',
  locations: 'Locations',
  taxes: 'Sales taxes',
  csrs: 'Csrs'
}

export const shopDataScopes = {
  techs: 'techs',
  installers: 'techs',
  'tech-collections': 'techs',
  'tax-detail': 'taxes',
  'tax-summary': 'taxes',
  detail: 'taxes',
  summary: 'taxes',
  salesreps: 'salesreps',
  salesources: 'salesources',
  locations: 'locations',
  commercialaccounts: 'commercialaccounts',
  insurancefleets: 'insurancefleets',
  schedule: 'techs',
  // 'in-out': 'tags',
  csrs: 'csrs'
}

export const statementAgingColumns = ['Current', '0-30', '31-60', '61-90', '91+']

export const xActionTypeOptions = ['Card', 'Manual Card', 'Cash', 'Check', 'Bank Transfer', 'Other', 'Promo', 'Coupon', 'Credit', 'Short Pay', 'Write Off', 'Fee', 'Deductible Discount', 'Doubtful Account']
export const xActionTypes = ['Card', 'Manual Card', 'Cash', 'Check', 'Bank Transfer', 'Other', 'Promo', 'Coupon', 'Credit', 'Short Pay', 'Write Off', 'Fee', 'Deductible Discount', 'Doubtful Account']
