<template>
  <div class="t-flex">
    <div class="t-text-gray-700 t-text-sm">
      Showing {{ total ? from: '0' }} to {{ to }} of {{ total }}
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="limit"
      :current-page.sync="page"
      class="t-flex-grow t-text-right">
    </el-pagination>
  </div>
</template>

<script>
export default {
  computed: {
    page: {
      get: function () {
        return this.$store.state.leads.pagination.page
      },
      set: function (value) {
        this.$store.commit('leads/setPaginationPage', value)
      }
    },
    limit: function () {
      return this.$store.state.leads.pagination.limit
    },
    from: function () {
      return this.$store.state.leads.pagination.from
    },
    to: function () {
      return this.$store.state.leads.pagination.to
    },
    total: function () {
      return this.$store.state.leads.pagination.total
    }
  },
  watch: {
    page: function () {
      this.$store.dispatch('leads/get')
    }
  }
}
</script>
