import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Fax extends ObjectTracker {
  id = -1
  faxdatetime = ''
  cid = ''
  dnis = ''
  filename = ''
  pagecount = -1
  status = ''
  url = ''
  referral = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getUnreadFaxCount (callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/unreadFaxCount`}).then(res => {
      
      if (res.data && res.data['numberOfUnreadFaxes']) {
        callbackFunction(res.data['numberOfUnreadFaxes'])
      } else {
        callbackFunction(0)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getFaxInboxTable (page, limit, sortBy, sortDesc,
    inboxDateTimeFilter, inboxSentFromFilter, inboxSentToFilter,
    inboxPagesFilter, inboxReferralNotesFilter, inboxStatusFilter,
    successCallbackFunction, errorCallbackFunction) {
    Api({url: `/api/fax/inboxTable?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&inboxDateTimeFilter=' + encodeURIComponent(inboxDateTimeFilter) +
      '&inboxSentFromFilter=' + encodeURIComponent(inboxSentFromFilter) +
      '&inboxSentToFilter=' + encodeURIComponent(inboxSentToFilter) +
      '&inboxPagesFilter=' + encodeURIComponent(inboxPagesFilter) +
      '&inboxReferralNotesFilter=' + encodeURIComponent(inboxReferralNotesFilter) +
      '&inboxStatusFilter=' + encodeURIComponent(inboxStatusFilter)
    }).then(res => {
      
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getFaxOutboxTable (page, limit, sortBy, sortDesc,
    outboxDateTimeFilter, outboxFileFilter, outboxSenderFilter, 
    outboxReceiverFilter, outboxPagesFilter, outboxStatusFilter,
    successCallbackFunction, errorCallbackFunction) {
    Api({url: `/api/fax/outboxTable?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&outboxDateTimeFilter=' + encodeURIComponent(outboxDateTimeFilter) +
      '&outboxFileFilter=' + encodeURIComponent(outboxFileFilter) +
      '&outboxSenderFilter=' + encodeURIComponent(outboxSenderFilter) +
      '&outboxReceiverFilter=' + encodeURIComponent(outboxReceiverFilter) +
      '&outboxPagesFilter=' + encodeURIComponent(outboxPagesFilter) +
      '&outboxStatusFilter=' + encodeURIComponent(outboxStatusFilter)    
    }).then(res => {
      
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static updateFaxReferral (faxId, referral, successCallbackFunction, errorCallbackFunction) {
    
    return Api.post(`/api/fax/${faxId}/referral`, {'referral': referral}).then(res => {
      
      
      if (res.data.response === 'success') {
        successCallbackFunction()
      } else {
        errorCallbackFunction()
      }
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction()
      }
    })
  }

  static updateFaxStatus (faxIdsArray, status, successCallbackFunction, errorCallbackFunction = null) {
    
    return Api.post(`/api/fax/updateStatus?status=${status}&faxIds=${faxIdsArray.join(',')}`).then(res => {
      
      
      successCallbackFunction(res.data)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction()
      }
    })
  }

  static deleteFaxes (faxIdsArray, successCallbackFunction, errorCallbackFunction = null) {
    
    return Api.post(`/api/fax/deleteFaxes?faxIds=${faxIdsArray.join(',')}`).then(res => {
      
      
      successCallbackFunction(res.data)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction()
      }
    })
  }

  static deleteBursts (burstIdsArray, successCallbackFunction, errorCallbackFunction = null) {
    
    return Api.post(`/api/fax/deleteBursts?burstIds=${burstIdsArray.join(',')}`).then(res => {
      
      
      successCallbackFunction(res.data)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction()
      }
    })
  }

  static retrieveOutgoingFaxNumber (successCallbackFunction, errorCallbackFunction) {
    Api({url: `/api/fax/outgoingFaxNumber`}).then(res => {
      
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getBurstCount (successCallbackFunction, errorCallbackFunction) {
    
    Api({url: `/api/fax/burstCount`}).then(res => {
      
      successCallbackFunction(res)
    })
  }

  static burstFaxes (burstFileInfo, successCallbackFunction, errorCallbackFunction) {
    var temp = {
      faxId: burstFileInfo.faxID,
      burstName: null,
      burstUrl: null
    }
    return Api.post(`/api/fax/burstFaxes`, temp).then(res => {
      
      if (res.data.status === 'success') {
        successCallbackFunction(res.data)
      } else {
        // Todo: Handle errors
        
      }
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction()
      }
    })
  }

  static burstLocalFax (burstFileInfo, successCallbackFunction, errorCallbackFunction) {
    var temp = {
      faxId: null,
      burstName: burstFileInfo.filename,
      burstUrl: burstFileInfo.url
    }
    
    return Api.post(`/api/fax/burstFaxes`, temp).then(res => {
      
      if (res.data.status === 'success') {
        successCallbackFunction(res.data)
      } else {
        // todo: handle errors
        
      }
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction()
      }
    })
  }

  static getFaxBurstTable (page, limit, sortBy, sortDesc,
    burstDateTimeFilter, burstReferralNotesFilter, burstStatusFilter,
    successCallbackFunction, errorCallbackFunction) {
    Api({url: `/api/fax/burstTable?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&burstDateTimeFilter=' + encodeURIComponent(burstDateTimeFilter) +
      '&burstReferralNotesFilter=' + encodeURIComponent(burstReferralNotesFilter) +
      '&burstStatusFilter=' + encodeURIComponent(burstStatusFilter)
    }).then(res => {
      
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
