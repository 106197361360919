// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".vendor-contact-info-tab .el-input {\n  width: 312px;\n}\n.vendor-contact-info-tab .el-input__inner {\n  border-radius: 0.5rem;\n}\n.vendor-contact-info-tab .el-radio__label {\n  display: none;\n}\n.vendor-contact-info-tab .el-button {\n  border-radius: 0.375rem;\n  --tw-text-opacity: 1;\n  color: rgba(59, 130, 246, var(--tw-text-opacity));\n}", "",{"version":3,"sources":["/app/<no source>","Index.vue"],"names":[],"mappings":"AAAA;EAAA,YAAA;ACaA;ADbA;EAAA,qBAAA;ACgBA;ADhBA;EAAA,aAAA;ACmBA;ADnBA;EAAA,uBAAA;EAAA,oBAAA;EAAA,iDAAA;ACwBA","file":"Index.vue","sourcesContent":[null,":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.vendor-contact-info-tab .el-input {\n  width: 312px;\n}\n.vendor-contact-info-tab .el-input__inner {\n  border-radius: 0.5rem;\n}\n.vendor-contact-info-tab .el-radio__label {\n  display: none;\n}\n.vendor-contact-info-tab .el-button {\n  border-radius: 0.375rem;\n  --tw-text-opacity: 1;\n  color: rgba(59, 130, 246, var(--tw-text-opacity));\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
