import { mutations } from './types'

export default {
  [mutations.setShopsSettings](state, settings) {
    state.shopsSettings = settings
  },
  [mutations.setShopsTimers](state, timers) {
    state.shopsTimers = timers
  },
  [mutations.setJobPageTimer](state, timersObj) {
    state.jobPageTimer = Object.assign({}, timersObj)
  },
  [mutations.setSchedulerJobsTimers](state, timersObj) {
    state.schedulerJobsTimers = Object.assign({}, timersObj)
  }
}
