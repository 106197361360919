<template>
  <b-modal
    ref="modal"
    size="lg"
    title="Customer Information"
    header-bg-variant="primary"
    header-text-variant="light"
    @hide="close"
    >
    <h3 class='text-uppercase'>{{customer.first_name}} {{customer.last_name}} - {{customer.id}}</h3>
    <div>{{customer.phone1}}</div>
    <br>
    <div class='text-uppercase'>{{customer.address}}</div>
    <div class='text-uppercase'>{{customer.city}}, {{customer.state}} {{customer.zip}}</div>123
    <br>
    <div>Jobs:</div>
    <div v-show="jobsLoading">
      <img style="width:18px;height:18px;margin-right:6px;" src='../../assets/img/spinner.gif'> Jobs Loading
    </div>
    <b-card v-if="!jobsLoading && jobs.length > 0" id="jobsHistoryContainer" style="padding: 0;">
      <ul style="list-style-type: none; padding: 0;">
        <li v-for='(job, index) in jobs' :key='index'>
          <a class="historyJobLink" @click="openJob(jobs[index])">{{job.id}} - {{job.status}}</a>
        </li>
      </ul>
    </b-card>
    <template v-slot:modal-footer>
      <b-button variant="dark" @click="close">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Customer from '@/scripts/objects/customer'

export default {
  props: [
    'visible',
    'customerId'
  ],
  data: () => ({
    customer: {},
    jobs: [],
    jobsLoading: false
  }),
  watch: {
    visible: async function (value) {
      if (value) {
        this.$refs.modal.show()
        const { data } = await Customer.getById(this.customerId)
        this.customer = data
        await this.getJobs()
      } else {
        this.$refs.modal.hide()
        this.customer = {}
        this.jobs = []
        this.jobsLoading = false
      }
    }
  },
  methods: {
    close () {
      this.$emit('closed')
    },
    getJobs () {
      let self = this
      self.jobsLoading = true
      Customer.getCustomerJobs(self.customer.shop_id, self.customer.id, function (jobs) {
        jobs.forEach(function (job, index) {
          let jobToDisplay = {
            id: job.shop_id + '-' + job.id
          }
          switch (job.status) {
            case 'workOrder':
              jobToDisplay.status = 'Work Order'
              break
            case 'invoice':
              jobToDisplay.status = 'Invoice'
              break
            case 'void':
              jobToDisplay.status = 'Void'
              break
            case 'quote':
              jobToDisplay.status = 'Quote'
          }
          self.jobs.push(jobToDisplay)
        })
        self.jobsLoading = false
      })
    },
    openJob (val) {
      let prevJobId = val.id.split('-')[1]
      let route = '/jobs/' + prevJobId
      let win = window.open(route, '_blank')
      win.focus()
    }
  }
}
</script>

<style>
.historyJobLink {
  color:blue !important;
  text-decoration: underline !important;
}

.historyJobLink:hover {
  cursor: pointer;
}
</style>
