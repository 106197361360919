<template>
  <div class="global-search-results-group">
    <div class="global-search-results-group-title">
      <span>{{ title }}</span>
      <div
        class="global-search-results-group-action"
        v-if="action"
        @click="$emit('action')"
      >
        {{ action }}
      </div>
    </div>

    <search-result-row
      v-for="(row, idx) in rows"
      :key="type + ':' + idx"
      :row="{ type, ...row }"
      @select="$emit('select', $event)"
      :type="type"
      @mouseenter.native="$emit('highlight', idx + offset)"
      @mouseleave.native="$emit('highlight', -1)"
      :highlighted="highlight === idx + offset"
    />
  </div>
</template>

<script>
// const searchGroups = {
//   id: 'Job ID'
// }
import SearchResultRow from './SearchResultRow'

export default {
  components: { SearchResultRow },
  props: {
    title: String,
    type: String,
    rows: {},
    action: String,
    highlight: Number,
    offset: { type: Number, default: () => 0 }
  }
}
</script>

<style lang="scss" scoped>
.global-search-results-group {
  padding: 20px;

  .global-search-results-group-title {
    @include gb-label;
    margin-bottom: $padding-sm;
    display: flex;
    padding: 0 $padding-sm;
    justify-content: space-between;
    .global-search-results-group-action {
      color: $primary;
      cursor: pointer;
    }
  }
}
</style>
