// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import singleSpaVue from 'single-spa-vue'
import Vue from 'vue'
import './gb-oauth'
import App from './App'
import router from './router'
import { sync } from 'vuex-router-sync'
import store from '@/store/store'
import BootstrapVue from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import './assets/scss/glassbiller-all-styles.scss'
import './assets/css/tailwind.css'
import VueImg from 'v-img'
import LoadScript from 'vue-plugin-load-script'
import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import VueSignaturePad from 'vue-signature-pad'
import VueAnalytics from 'vue-analytics'
import { documentsConfig } from '@/constants/documents.config'
import AddressInput from '@/components/Helpers/AddressInput'
import InputWithLabel from '@/components/Helpers/InputWithLabel'
import InputGroup from '@/components/Helpers/InputGroup/Index.vue'
import GbForm from '@/components/Helpers/Form/Index.vue'
import GbCard from '@/components/Helpers/Card.vue'
import GbDataRows from '@/components/Helpers/DataRows/Index.vue'
import GbDataRow from '@/components/Helpers/DataRows/DataRow.vue'
import GbSkeleton from '@/components/Helpers/DataRows/Skeleton.vue'
import GbEditableDataRow from '@/components/Helpers/DataRows/EditableDataRow.vue'
import GbSelectDataRow from '@/components/Helpers/DataRows/SelectDataRow.vue'
import GbPopoverDataRow from '@/components/Helpers/DataRows/PopoverDataRow.vue'
import SelectActionButtons from '@/components/Helpers/DataRows/SelectActionButtons.vue'
import FaIconButton from '@/components/Helpers/buttons/FaIconButton.vue'
import MdIconButton from '@/components/Helpers/buttons/MdIconButton.vue'
import MdCircleButton from '@/components/Helpers/buttons/MdCircleButton.vue'
import MdCheckboxButton from '@/components/Helpers/buttons/MdCheckboxButton.vue'
import MdLabelTag from '@/components/Helpers/MdLabelTag.vue'
import ActionButton from '@/components/Helpers/buttons/ActionButton.vue'
import PartTag from '@/components/Helpers/PartTag.vue'
import CheckboxTag from '@/components/Helpers/CheckboxTag.vue'
import GbDropdownMenu from '@/components/Helpers/DropdownMenu.vue'
import GbCheckbox from '@/components/Helpers/GbCheckbox.vue'
import GbContentSlider from '@/components/Helpers/ContentSlider.vue'
import GbTag from '@/components/Helpers/Tag.vue'
import GbTagSelect from '@/components/Helpers/TagSelect.vue'
import GbEdiLock from '@/components/JobPageV2/helpers/EdiLock.vue'
import VinInput from '@/components/Helpers/VinInput/Index.vue'
import './scripts/helpers/directives/infinite-scroll.directive'
import './scripts/helpers/directives/number-input.directive'
import './scripts/helpers/directives/phone-mask.directive'
import './scripts/helpers/directives/focusout.directive'
import './scripts/helpers/directives/role.directive'
import TechLocationsPlugin from './plugins/tech-locations.plugin'
import RolesPlugin from './plugins/roles.plugin'
import UnumPlugin from './plugins/unum.plugin'
import FlagIcon from 'vue-flag-icon'
import VueMask from 'vue-the-mask'
import { apolloProvider } from './apollo'
import FilestackPicker from '@/components/Helpers/FilestackPicker'
import { ObserveVisibility } from 'vue-observe-visibility'
import Clickoutside from 'element-ui/src/utils/clickoutside'
import { ResizeObserver } from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import 'animate.css/animate.min.css'
import VueCurrencyInput from 'vue-currency-input'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'

// icons
import './icons'
import Icon from 'vue-awesome/components/Icon'

import * as VueGoogleMaps from 'vue2-google-maps'
import googleMapsConfig from './constants/google-maps.config'
import {
  permissions,
  permissionCheck
} from '@/scripts/helpers/permissions.helpers'
import VueClipboard from 'vue-clipboard2'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueCompositionAPI from '@vue/composition-api'

import cfg from '@/config'

require('bootstrap')

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(VueCompositionAPI)

Vue.use(ElementUI, { locale })

Vue.use(BootstrapVue)
Vue.use(VueCookies)
Vue.use(VueImg)
Vue.use(LoadScript)
Vue.use(Datetime)
Vue.component('v-icon', Icon)
Vue.use(VueSignaturePad)
Vue.use(ElementUI.InfiniteScroll)
Vue.use(VueClipboard)
Vue.use(FlagIcon)
Vue.directive('element-clickoutside', Clickoutside)
Vue.use(VueCurrencyInput, {
  globalOptions: { currency: null, distractionFree: true }
})

Vue.prototype.$message = ElementUI.Message
Vue.prototype.$messageBox = ElementUI.MessageBox
Vue.component('address-input', AddressInput)
Vue.component('input-with-label', InputWithLabel)
Vue.component('input-group', InputGroup)
Vue.component('gb-form', GbForm)
Vue.component('gb-card', GbCard)
Vue.component('gb-data-rows', GbDataRows)
Vue.component('gb-data-row', GbDataRow)
Vue.component('gb-editable-data-row', GbEditableDataRow)
Vue.component('gb-select-data-row', GbSelectDataRow)
Vue.component('gb-popover-data-row', GbPopoverDataRow)
Vue.component('gb-tag', GbTag)
Vue.component('gb-tag-select', GbTagSelect)
Vue.component('v-icon-button', FaIconButton)
Vue.component('md-icon-button', MdIconButton)
Vue.component('md-circle-button', MdCircleButton)
Vue.component('md-checkbox-button', MdCheckboxButton)
Vue.component('md-label-tag', MdLabelTag)
Vue.component('gb-action-button', ActionButton)
Vue.component('gb-dropdown-menu', GbDropdownMenu)
Vue.component('gb-checkbox-tag', CheckboxTag)
Vue.component('gb-skeleton', GbSkeleton)
Vue.component('select-action-buttons', SelectActionButtons)
Vue.component('filestack-picker', FilestackPicker)
Vue.component('part-tag', PartTag)
Vue.component('gb-checkbox', GbCheckbox)
Vue.component('gb-vin-input', VinInput)
Vue.component('gb-content-slider', GbContentSlider)
Vue.component('gb-edi-lock', GbEdiLock)
Vue.component('resize-observer', ResizeObserver)

Vue.use(VueGoogleMaps, {
  load: googleMapsConfig.baseConfig
})

Vue.use(VueMask)

Vue.use(ElementUI.Loading.directive)
Vue.prototype.$notify = ElementUI.Notification

Vue.directive('capitalize', {
  update(el, binding, vnode) {
    if (typeof el.value === 'string') {
      const sel = el.selectionStart
      el.value = el.value.toUpperCase()
      vnode.context.$nextTick(() => {
        el.setSelectionRange(sel, sel)
        el.setFocus()
      })
    }
  }
})
Vue.directive('doc-drop-zone', {
  update(el, binding, vnode) {
    el.shopId = binding.value.shopId
    el.type = binding.arg
      ? binding.arg
      : binding.value.type
        ? binding.value.type
        : ''
    el.id = binding.value.id
    el.configObject = Object.assign({}, documentsConfig[el.type])
    el.configObject.popover = binding.modifiers.popover === true
    el.configObject.directiveType = 'dropZone'
    el.clientLarge = el.clientHeight > 150 && el.clientWidth > 150
  },
  bind(el, binding, vnode) {
    el.counter = 0
    el.shopId = binding.value.shopId
    // get type from argument if there otherwise value
    el.type = binding.arg
      ? binding.arg
      : binding.value.type
        ? binding.value.type
        : ''
    el.id = binding.value.id
    el.configObject = Object.assign({}, documentsConfig[el.type])
    // see if modifier is present and add to configObject
    el.configObject.popover = binding.modifiers.popover === true
    el.configObject.directiveType = 'dropZone'
    el.clientLarge = el.clientHeight > 150 && el.clientWidth > 150
    // add
    el.timeout = null
    el.addEventListener(
      'dragenter',
      event => {
        el.classList.add(
          el.clientLarge ? 'documentDragging' : 'documentDraggingSmall'
        )
        if (el.counter === 0 && el.clientLarge) {
          var newElement = document.createElement('div')
          // newElement.setAttribute('id', 'dropZoneText')
          // at some point we can do a unique id so that you can have multiple drop zones of the same type on the screen at once'
          newElement.setAttribute('id', 'dropZone' + el.type)
          newElement.classList.add('dropZoneText')
          newElement.innerHTML = 'DROP FILE'
          el.appendChild(newElement)
        }
        event.stopPropagation()
        event.preventDefault()
        el.counter++
        el.timeout = setTimeout(() => {
          el.classList.add('active')
        }, 100)
      },
      false
    )
    el.addEventListener(
      'dragleave',
      event => {
        el.counter--
        if (el.counter === 0) {
          el.classList.remove(
            el.clientLarge ? 'documentDragging' : 'documentDraggingSmall'
          )
          clearTimeout(el.timeout)
          el.classList.remove('active')
          var tempElement = document.getElementById('dropZone' + el.type)
          tempElement.parentNode.removeChild(tempElement)
        }
        event.stopPropagation()
        event.preventDefault()
      },
      false
    )
    el.addEventListener('dragover', event => {
      event.preventDefault()
    })
    el.addEventListener(
      'drop',
      event => {
        event.stopPropagation()
        event.preventDefault()
        el.counter = 0
        clearTimeout(el.timeout)
        el.classList.remove(
          el.clientLarge ? 'documentDragging' : 'documentDraggingSmall'
        )
        el.classList.remove('active')
        if (el.clientLarge) {
          var tempElement = document.getElementById('dropZone' + el.type)
          tempElement.parentNode.removeChild(tempElement)
        }
        var file = null
        file = event.dataTransfer.files[0]
        if (file) {
          if (el.configObject.popover) {
            // var popoverElement = document.getElementById('popoverTarget')
            var popoverContainer = document.getElementById('popover-container')
            // var popoverTarget = document.createElement('div')
            // popoverTarget.setAttribute('id', 'popoverTarget')
            // popoverElement.setAttribute('style', "position: absolute; left: " + event.pageX + "px; top: " + event.pageY + "px;")
            popoverContainer.setAttribute(
              'style',
              'position: absolute; left: ' +
              event.pageX +
              'px; top: ' +
              event.pageY +
              'px;'
            )
            // popoverTarget.setAttribute('style', "position: absolute; left: " + event.pageX + "px; top: " + event.pageY + "px;")
            // el.appendChild(popoverTarget)
            // el.configObject['x'] = event.pageX
            // el.configObject['y'] = event.pageY
          }
          // sconst custEvent = new CustomEvent('fileDrop', {detail: {file}, bubbles: true})
          store.commit('setDocumentConfig', {
            config: {
              ...el.configObject,
              shopId: el.shopId,
              type: el.type,
              id: el.id
            },
            file
          })
          // vnode.context.$emit(custEvent)
        }
      },
      false
    )
  }
})
Vue.directive('doc-button', {
  update(el, binding, vnode) {
    el.shopId = binding.value.shopId
    el.type = binding.value.type
    el.id = binding.value.id
    el.isModal = binding.value.isModal ? binding.value.isModal : false
    el.dialogToReturnTo = binding.value.dialogToReturnTo
      ? binding.value.dialogToReturnTo
      : ''
    el.configObject = Object.assign({}, documentsConfig[el.type])
    el.configObject.popover = binding.modifiers.popover === true
    el.configObject.directiveType = 'docButton'
  },
  bind(el, binding, vnode) {
    el.shopId = binding.value.shopId
    el.type = binding.value.type
    el.id = binding.value.id
    el.isModal = binding.value.isModal ? binding.value.isModal : false
    el.dialogToReturnTo = binding.value.dialogToReturnTo
      ? binding.value.dialogToReturnTo
      : ''
    el.configObject = Object.assign({}, documentsConfig[el.type])
    el.configObject.popover = binding.modifiers.popover === true
    el.configObject.directiveType = 'docButton'
    el.addEventListener(
      'click',
      event => {
        event.stopPropagation()
        event.preventDefault()
        store.commit('setDocumentConfig', {
          config: {
            ...el.configObject,
            shopId: el.shopId,
            type: el.type,
            id: el.id,
            isModal: el.isModal,
            dialogToReturnTo: el.dialogToReturnTo
          },
          file: null
        })
      },
      false
    )
  }
})

Vue.directive('close-outside-click', {
  bind: function (el, binding, vnode) {
    document.addEventListener('click', event => {
      const clickedInside = el.contains(event.target)
      if (!clickedInside && el.classList.contains('popover-open')) {
        binding.value()
      }
    })
  }
})

sync(store, router)

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAccountManager) {
    if (parseInt(to.query.tab) === 17 || parseInt(to.query.tab) === 25) {
      if (parseInt(store.state.user.admin) === 1) {
        next()
      } else {
        next('/')
      }
    } else if (parseInt(to.query.tab) === 26) {
      if (store.state.user &&
        (store.state.user.account_manager === 1 || store.state.user.admin === 1)) {
        next()
      } else {
        next('/')
      }
    } else if (
      store.state.user &&
      (store.state.user.account_manager === 1 || store.state.user.admin === 1)
    ) {
      // next just check the tab to ensure they actually have access to specific tab
      if (permissionCheck(parseInt(to.query.tab), store.state)) {
        next()
      } else {
        next('/')
      }
    } else {
      next('/')
    }
    next()
  } else if (to.meta.requiresReports) {
    if (permissionCheck(permissions.REPORTS, store.state)) {
      next()
    } else {
      next('/')
    }
  } else if (to.meta.requiresManage) {
    if (permissionCheck(parseInt(to.query.tab), store.state)) {
      next()
    } else {
      next('/')
    }
  } else if (to.meta.requiresARAP) {
    if (permissionCheck(permissions.ARAP, store.state)) {
      next()
    } else {
      next('/')
    }
  } else if (to.meta.requiresWarehouse) {
    if (permissionCheck(permissions.WAREHOUSE, store.state)) {
      next()
    } else {
      next('/')
    }
  } else if (to.meta.requiresJob) {
    if (permissionCheck(permissions.JOB, store.state)) {
      if (
        to.params.id.toLowerCase() === 'new' &&
        permissionCheck(permissions.JOBVIEWONLY, store.state)
      ) {
        next('/')
      }
      next()
    } else {
      next('/')
    }
  } else if (to.meta.requiresJobManager) {
    if (permissionCheck(permissions.JOBMANAGER, store.state)) {
      next()
    } else {
      next('/')
    }
  } else if (to.meta.requiresAdmin) {
    if (parseInt(store.state.user.admin) === 1) {
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

Vue.directive('observe-visibility', ObserveVisibility)

Vue.use(VueAnalytics, {
  id: cfg.googleAnalyticsID,
  router,
  disabled: process.env.NODE_ENV !== 'production'
})

Vue.filter('capitalize-all', function (val) {
  if (!val) return ''
  val = val.toString()
  return val.toUpperCase()
})

/* eslint-disable no-new */
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App)
    },
    router,
    apolloProvider,
    store,
    pinia,
    created() {
      window.VUEX = store.state
      Vue.use(TechLocationsPlugin, store)
      Vue.use(UnumPlugin)
      Vue.use(RolesPlugin)
    }
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
