<template>
  <el-dialog
    append-to-body
    width="700px"
    :visible.sync="localVisible"
    class="data-tab-permission-dialog"
    title="Data Tab Permissions"
    @open="open"
    @close="close"
  >
    <span v-if="displayOverlay" class="overlay">
      <div @click="localVisible = false" class="close-dialog-overlay">
        <v-icon name="times" scale="1.1" />
      </div>
      <div class="overlay-text">The general data tab permission must be checked for these permissions to take effect</div>
    </span>
    <div class="permissions-container p-2">
      <el-checkbox-group v-model="checkedDatatabs">
        <el-checkbox v-for="option in options" :label="option.value" :key="option.value">
          {{option.label}}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :disabled="saving" @click="save">Save</el-button>
      <el-button style="background-color:black; color:white;" :disabled="saving" @click="localVisible = false">Cancel</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as R from 'ramda'
import User from '@/scripts/objects/user'
import {datatabPermissionIds} from '../../constants/data-tab.config.js'

export default {
props: ['visible', 'userId', 'userPermissions'],
  data () {
    return {
      datatabIdsConfig: datatabPermissionIds,
      displayOverlay: false,
      saving: false,
      checkedDatatabs: [],
      options: [
        {label:'Job', value:'datatabjob'},
        {label:'Customers', value:'datatabcustomers'},
        {label:'Vendors', value: 'datatabvendors'}
      ]
    }
  },
  computed: {
    localVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    open() {
      console.log(this.userPermissions.hasOwnProperty('datatab'))
      if (!this.userPermissions.hasOwnProperty('datatab')) {
        this.displayOverlay = true
      }
      this.checkedDatatabs = [...Object.keys(this.userPermissions)]
    },
    close() {
      this.displayOverlay = false
    },
    async save() {
      console.log('saving', this.datatabIdsConfig)
      this.saving = true
      let newPermissions = []
      let datatabKeys = Object.keys(this.datatabIdsConfig)
      for(var i = 0; i < datatabKeys.length; i++) {
        if (this.checkedDatatabs.includes(datatabKeys[i])) {
          newPermissions.push({name: datatabKeys[i], role_id: this.datatabIdsConfig[datatabKeys[i]]})
        }
      }
      const res = await User.updateDatatabPermissions({userId: this.userId, permissions: newPermissions})
      this.saving = false
      if(res === 'success') {
        this.$message.success('Data tab permissions updated')
      } else {
        this.$message.error('Something went wrong')
      }
      this.$emit('refreshPermissions')
      this.localVisible = false
    },
  }
}
</script>

<style lang='scss'>
.data-tab-permission-dialog {
  .permissions-container {
    border: 2px solid lightgray;
    border-radius: 5px;
  }
  .overlay {
    position:absolute;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    background-color:rgba(252, 252, 252, 0.8);
    z-index: 100;
  }
  .overlay-text {
    text-align:center;
    font-weight:bold;
    font-size:24px;
    white-space:pre-wrap;
    padding: 40px 20px;
    word-break: keep-all;
  }
  .close-dialog-overlay {
    cursor: pointer;
    position: absolute;
    right: 21px;
    top: 21px;
  }
}
</style>