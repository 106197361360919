import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import schedulerModule from './modules/scheduler'
import schedulerV2Module from './modules/scheduler_v2'
import schedulerV3Module from './modules/scheduler_v3'
import twilioModule from './modules/twilio'
import squareModule from './modules/square'
import rightPartModule from './modules/right-part'
import shoppingCartModule from './modules/shopping-cart'
import notificationsModule from './modules/notifications'
import notificationsSettingsModule from './modules/notifications-settings'
import confirmationsSettingsModule from './modules/confirmations-settings'
import partVendorsModule from './modules/part-vendors'
import analyticsModule from './modules/shop-analytics'
import { schedulerNamespace } from './modules/scheduler/scheduler.types'
import { reportsNamespace } from './modules/reports/reports.types'
import Ediqueue from '@/scripts/objects/ediqueue'
import * as R from 'ramda'
import reportsModule from './modules/reports/index'
import jobDetailsModule from './modules/job-details'
import Job from '@/scripts/objects/job'
import Salesidejob from '@/scripts/objects/salesidejob'
import Fax from '@/scripts/objects/fax'
import GlaxisWorkAssingment from '@/scripts/objects/glaxis_work_assignment'
import { schedulerCompletedCountUrl } from '@/constants/endpoints'
import Api from '@/services/Api'
import activityModule from './modules/activity'
import { activityNamespace } from './modules/activity/activity.types'
import availabilityModule from './modules/availability'
import { availabilityNamespace } from './modules/availability/availability.types'
import pathify, { make } from 'vuex-pathify'
import AccountingPackage from '@/scripts/objects/accounting_package'
import Account from '@/scripts/objects/account'
import { Message } from 'element-ui'
import leadsModule from './modules/leads'
import UserRecentJob from '@/scripts/objects/user_recent_job'
import glassbillerLeadsModule from './modules/leads-glassbiller'
import rejectedInvoicesModule from './modules/rejected-invoices'
import quotesModule from './modules/quotes'
import shopDialogModule from './modules/shop-dialog'
import shareModule from './modules/share'
import chatModule from './modules/chat'
import catalogsModule from './modules/catalogs'
import supportModule from './modules/support'
import glaxisModule from './modules/glaxis'
import warehouseModule from './modules/warehouse'

Vue.use(Vuex)

const state = {
  shops: [],
  organizations: [],
  user: {},
  roles: [],
  customers: [],
  userEdiRejectedJobCount: 0,
  userEdiRejectedJobShopCountArray: [],
  userEdiQueuedJobCount: 0,
  userEdiQueuedJobShopCountArray: [],
  userQuoteCount: 0,
  userQuoteShopCountArray: [],
  userFollowupQuoteCount: 0,
  userTechsideCount: 0,
  userSalesideJobCount: 0,
  userSalesideShopJobCountArray: [],
  userSalesideUnreadMessageCount: 0,
  userSalesideShopMessageCountArray: [],
  unreadFaxCount: 0,
  glaxisWorkAssignmentsCount: 0,
  glaxisWorkAssignmentsCountArray: [],
  goToPage: '',
  dialogData: null,
  ediRejectedCountLoading: false,
  ediQueuedJobCountLoading: false,
  userQuoteCountLoading: false,
  techsideCountLoading: false,
  salesideCountLoading: false,
  unreadFaxCountLoading: false,
  glaxisWorkAssignmentsLoading: false,
  qboShopId: -1,
  squareShopId: -1,
  cloverShopId: -1,
  loadingJobVehicleData: false,
  documentConfig: null,
  accountingPackages: [],
  accountingPackagesLoading: false,
  accountStatus: '',
  accountStatusLoading: false,
  jobV2Drawer: false,
  sidebarOpen: false
}

export default new Vuex.Store({
  strict: true,
  plugins: [
    createPersistedState({
      paths: [
        ...Object.keys(state),
        'scheduler.filters.cardView',
        'scheduler.filters.solidCards',
        'sch_v2.filters.cardView',
        'sch_v2.filters.solidCards',
        'sch_v2.filters.shops',
        'reports.filters.shops',
        'reports.filters.status',
        'sch_v3.filters.cardView',
        'sch_v3.filters.solidCards',
        'sch_v3.filters.showAvailability',
        'sch_v3.filters.showTags',
        'sch_v3.filters.showTotals',
        'sch_v3.filters.jobConfirmationDetailsVisible',
        'sch_v3.filters.showVendors',
        'sch_v3.filters.shops',
        'sch_v3.filters.date',
        'jobDetails.drawer',
        'jobDetails.recentSearches'
      ]
    }),
    pathify.plugin
  ],
  state: { ...state, globalLoading: false },
  getters: {
    getShopsFiltersList(state) {
      return state.shops
        ? state.shops.map(R.pick(['id', 'name', 'accounting_package_id']))
        : []
    },
    getShopOrgFiltersList(state) {
      if (state.shops && state.organizations) {
        const orgsById = R.groupBy(R.prop('id'), state.organizations)
        return state.shops.map(shop => ({
          ...(R.pick(['id', 'name', 'accounting_package_id'])(shop)),
          organization: orgsById[shop.organization_id][0]
          // state.shops.map(R.pick(['id', 'name', 'accounting_package_id', 'organization_id']))
        }))
      }
      return []
    },
    getAccountingPackagesFiltersList(state) {
      return state.accountingPackages
        ? state.accountingPackages.map(R.pick(['id', 'name']))
        : []
    },

    getUserCombineTechsValue: R.pathOr(0, ['user', 'scheduler_combine_techs']),
    getWarehouseShopIdCache: R.pathOr(0, ['user', 'warehouse_shop_id_cache']),
    getNagsPriceIncreaseAcknowledged: R.pathOr(0, ['user', 'nags_price_increase_acknowledged']),
    getWarehouseHideAdhesiveCache: R.pathOr(0, [
      'user',
      'warehouse_hide_adhesive_cache'
    ]),
    getWarehouseGroupByTechCache: R.pathOr(0, [
      'user',
      'warehouse_group_by_cache'
    ]),
    getWarehouseIsPartsRequired: R.pathOr(0, [
      'user',
      'warehouse_is_parts_required_cache'
    ]),
    pageTrigger: state => {
      return state.goToPage
    },
    documentConfig: state => {
      return state.documentConfig
    },
    getUserName: R.path(['user', 'username']),
    getShopName: state => id =>
      R.pipe(
        R.prop('shops'),
        R.find(R.propEq('id', id)),
        R.prop('name')
      )(state),
    isNagsUser: R.path(['user', 'nags_user']),
    userAccountIsActive(state) {
      if (
        state.accountStatus === 'unpaid' ||
        state.accountStatus === 'cancelled' ||
        state.accountStatus === 'expired' ||
        state.accountStatus === 'trial_expired'
      ) {
        return false
      }
      return true
    },
    userConsumer(state) {
      for (var i = 0; i < state.shops.length; i++) {
        if (state.shops[i]?.shop_services_config?.consumer) return true
      }
      return false
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setRoles(state, roles) {
      state.roles = roles
    },
    setUserEmailVerified(state, verification_finished) {
      state.user.verification_finished = verification_finished
    },
    setUserDefaultJobslistViewId(state, default_jobslist_view_id) {
      state.user.default_jobslist_view_id = default_jobslist_view_id
    },
    updateShopData(state, shopData) {
      for (var i = 0; i < state.shops.length; i++) {
        if (state.shops[i].id === shopData.id) {
          state.shops.splice(i, 1, shopData)
        }
      }
    },
    setShops(state, shops) {
      state.shops = shops
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations
    },
    setCustomers(state, customers) {
      state.customers = customers
    },
    setUserEdiRejectedJobCount(state, userEdiRejectedJobCount) {
      state.userEdiRejectedJobCount = userEdiRejectedJobCount
    },
    setAccountingPackages(state, accountingPackages) {
      state.accountingPackages = accountingPackages
    },
    setAccountStatus(state, accountStatus) {
      state.accountStatus = accountStatus
    },
    setUserEdiRejectedJobShopCountArray(
      state,
      userEdiRejectedJobShopCountArray
    ) {
      state.userEdiRejectedJobShopCountArray = userEdiRejectedJobShopCountArray
    },
    setUserEdiQueuedJobCount(state, userEdiQueuedJobCount) {
      state.userEdiQueuedJobCount = userEdiQueuedJobCount
    },
    setUserEdiQueuedJobShopCountArray(state, userEdiQueuedJobShopCountArray) {
      state.userEdiQueuedJobShopCountArray = userEdiQueuedJobShopCountArray
    },
    setUserQuoteCount(state, userQuoteCount) {
      state.userQuoteCount = userQuoteCount
    },
    setUserQuoteShopCountArray(state, userQuoteShopCountArray) {
      state.userQuoteShopCountArray = userQuoteShopCountArray
    },
    setUserFollowUpQuoteCount(state, userFollowUpQuoteCount) {
      state.userFollowupQuoteCount = userFollowUpQuoteCount
    },
    // setUserFollowUpQuoteCountArray(state, userFollowupQuoteCountArray) {
    //   state.userFollowupQuoteCountArray = userFollowupQuoteCountArray
    // },
    setUserTechsideCount(state, userTechsideCount) {
      state.userTechsideCount = userTechsideCount
    },
    setUserSalesideJobCount(state, userSalesideJobCount) {
      state.userSalesideJobCount = userSalesideJobCount
    },
    setUserSalesideShopJobCountArray(state, userSalesideShopJobCountArray) {
      state.userSalesideShopJobCountArray = userSalesideShopJobCountArray
    },
    setUserSalesideUnreadMessageCount(state, userSalesideMessageCount) {
      state.userSalesideUnreadMessageCount = userSalesideMessageCount
    },
    setUserSalesideUnreadMessageCountArray(
      state,
      userSalesideShopMessageCountArray
    ) {
      state.userSalesideUnreadMessageCountArray = userSalesideShopMessageCountArray
    },
    setUnreadFaxCount(state, unreadFaxCount) {
      state.unreadFaxCount = unreadFaxCount
    },
    setGlaxisWorkAssignmentsCount(state, glaxisWorkAssignmentsCount) {
      state.glaxisWorkAssignmentsCount = glaxisWorkAssignmentsCount
    },
    setGlaxisWorkAssignmentsCountArray(state, glaxisWorkAssignmentsCountArray) {
      state.glaxisWorkAssignmentsCountArray = glaxisWorkAssignmentsCountArray
    },
    setUserCombineTechs(state, value) {
      if (state.user) {
        state.user.scheduler_combine_techs = value
      }
    },
    setWarehouseHideAdhesiveCache(state, value) {
      if (state.user) {
        state.user.warehouse_hide_adhesive_cache = value
      }
    },
    setWarehouseGroupByCache(state, value) {
      if (state.user) {
        state.user.warehouse_group_by_cache = value
      }
    },
    setWarehouseIsPartsRequired(state, value) {
      if (state.user) {
        state.user.warehouse_is_parts_required_cache = value
      }
    },
    setWarehouseShopIdCache(state, value) {
      if (state.user) {
        state.user.warehouse_shop_id_cache = value
      }
    },
    setNagsPriceIncreaseAcknowledged(state, value) {
      if (state.user) {
        state.user.nags_price_increase_acknowledged = value
        state.user.terms_and_conditions_acknowledged = value
      }
    },
    setGoToPage(state, goToPage) {
      state.goToPage = goToPage
    },
    setDocumentConfig(state, configObject) {
      state.documentConfig = configObject
    },
    // pass { data, dialog } to override dialog data
    setDialogData(state, dialogData) {
      state.dialogData = dialogData
    },
    setQBOShopId(state, qboShopId) {
      state.qboShopId = qboShopId
    },
    setSquareShopId(state, squareShopId) {
      state.squareShopId = squareShopId
    },
    setCloverShopId(state, cloverShopId) {
      state.cloverShopId = cloverShopId
    },
    updateUserReplyTo(state, email) {
      state.user.replyto_email_cache = email
    },
    updateUserCcReplyTo(state, value) {
      state.user.cc_replyto = value
    },
    toggleLoadingJobVehicleData(state) {
      state.loadingJobVehicleData = !state.loadingJobVehicleData
    },
    hasUnreadTextMessage(state, value) {
      if (state.user) {
        state.user.has_unread_text_message = value
      }
    },
    updateUserRecentJobCount(state, count) {
      state.user.recent_job_count = count
      // update db as well
      UserRecentJob.updateUserRecentJobsCount({
        userId: state.user.id,
        count: count
      })
    },
    setUserLegacyNagsView(state, value) {
      state.user.legacy_nags = value
    },
    setUserShowNagsSubstitutes(state, value) {
      state.user.show_nags_substitutes = value
    },
    ...make.mutations({ ...state, globalLoading: false })
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    setRoles({ commit }, roles) {
      commit('setRoles', roles)
    },
    setUserEmailVerified({ commit }, email) {
      commit('setUserEmailVerified', email)
    },
    setUserDefaultJobslistViewId({ commit }, default_jobslist_view_id) {
      commit('setUserDefaultJobslistViewId', default_jobslist_view_id)
    },
    setShops({ commit }, shops) {
      commit('setShops', shops)
    },
    setOrganizations({ commit }, organizations) {
      commit('setOrganizations', organizations)
    },
    updateShopData({ commit }, shopData) {
      commit('updateShopData', shopData)
    },
    setCustomers({ commit }, customers) {
      commit('setCustomers', customers)
    },
    setQBOShopId({ commit }, qboShopId) {
      commit('setQBOShopId', qboShopId)
    },
    setSquareShopId({ commit }, squareShopId) {
      commit('setSquareShopId', squareShopId)
    },
    setCloverShopId({ commit }, cloverShopId) {
      commit('setCloverShopId', cloverShopId)
    },
    updateAccountingPackages({ commit }) {
      if (!this.accountingPackagesLoading) {
        const self = this
        this.accountingPackagesLoading = true
        AccountingPackage.getUserConnectedAccountingPackages(
          function(results) {
            var accountingPackages = []
            for (var i = 0; i < results.length; i++) {
              accountingPackages.push(new AccountingPackage(results[i]))
            }
            commit('setAccountingPackages', accountingPackages)
            self.accountingPackagesLoading = false
          },
          function(error) {
            console.log('had accounting package error: ', error)
            self.accountingPackagesLoading = false
          }
        )
      }
    },
    updateAccountStatus({ commit }) {
      if (!this.accountStatusLoading) {
        const self = this
        this.accountStatusLoading = true
        Account.getUserAccountStatus(
          function(results) {
            if (results && results.status && results.status.length > 0) {
              commit('setAccountStatus', results.status)
            } else {
              commit('setAccountStatus', '')
            }
            self.accountStatusLoading = false
          },
          function(error) {
            console.log('had account status error: ', error)
            self.accountStatusLoading = false
          }
        )
      }
    },
    updateUserEdiRejectedJobCount({ commit }) {
      if (!this.ediRejectedCountLoading) {
        const self = this
        this.ediRejectedCountLoading = true
        Ediqueue.getRejectedEdisCountsForAllConnectedShops(
          function(results) {
            var userEdiRejectedJobShopCountArray = []
            var totalRejectedJobCount = 0
            for (var i = 0; i < results.length; i++) {
              userEdiRejectedJobShopCountArray[results[i].shop_id] = []
              userEdiRejectedJobShopCountArray[results[i].shop_id].count =
                results[i].count
              userEdiRejectedJobShopCountArray[
                results[i].shop_id
              ].countForDisplay =
                results[i].count > 999 ? '999+' : '' + results[i].count
              totalRejectedJobCount += results[i].count
            }
            commit(
              'setUserEdiRejectedJobShopCountArray',
              userEdiRejectedJobShopCountArray
            )
            commit('setUserEdiRejectedJobCount', totalRejectedJobCount)
            self.ediRejectedCountLoading = false
          },
          function(error) {
            self.ediRejectedCountLoading = false
          }
        )
      }
    },
    updateUserEdiQueuedJobCount({ commit }) {
      if (!this.ediQueuedJobCountLoading) {
        const self = this
        this.ediQueuedJobCountLoading = true
        Ediqueue.getQueuedEdisCountsForAllConnectedShops(
          function(results) {
            var userEdiQueuedJobShopCountArray = []
            var totalQueuedJobCount = 0
            for (var i = 0; i < results.length; i++) {
              userEdiQueuedJobShopCountArray[results[i].shop_id] = []
              userEdiQueuedJobShopCountArray[results[i].shop_id].count =
                results[i].count
              userEdiQueuedJobShopCountArray[
                results[i].shop_id
              ].countForDisplay =
                results[i].count > 999 ? '999+' : '' + results[i].count
              totalQueuedJobCount += results[i].count
              if (i === 174) {
              }
            }
            commit(
              'setUserEdiQueuedJobShopCountArray',
              userEdiQueuedJobShopCountArray
            )
            commit('setUserEdiQueuedJobCount', totalQueuedJobCount)
            self.ediQueuedJobCountLoading = false
          },
          function(error) {
            self.ediQueuedJobCountLoading = false
          }
        )
      }
    },
    updateUserQuoteCount({ commit }) {
      if (!this.userQuoteCountLoading) {
        const self = this
        this.userQuoteCountLoading = true
        Job.getQuotesCountsForAllConnectedShops(
          function(results) {
            var userQuoteShopCountArray = []
            var totalQuotesCount = 0
            var totalFollowupQuoteCount = 0
            if (results && results.count) {
              for (var i = 0; i < results.count.length; i++) {
                userQuoteShopCountArray[results.count[i].shop_id] = []
                userQuoteShopCountArray[results.count[i].shop_id].count =
                  results.count[i].count
                userQuoteShopCountArray[
                  results.count[i].shop_id
                ].countForDisplay =
                  results.count[i].count > 999
                    ? '999+'
                    : '' + results.count[i].count
                totalQuotesCount += results.count[i].count
              }
            }
            commit('setUserQuoteShopCountArray', userQuoteShopCountArray)
            commit('setUserQuoteCount', totalQuotesCount)
            self.userQuoteCountLoading = false
            if (results && results.followupCount) {
              for (var j = 0; j < results.followupCount.length; j++) {
                // userQuoteFollowupArray[results.followupCount[j]['shop_id']] = []
                // userQuoteFollowupArray[results.followupCount[j]['shop_id']]['count'] =
                //   results.followupCount[j]['count']
                //   userQuoteFollowupArray[results.followupCount[j]['shop_id']][
                //   'countForDisplay'
                // ] = results.followupCount[j]['count'] > 999 ? '999+' : '' + results.followupCount[j]['count']
                totalFollowupQuoteCount += results.followupCount[j].count
              }
            }
            commit('setUserFollowUpQuoteCount', totalFollowupQuoteCount)
          },
          function(error) {
            self.userQuoteCountLoading = false
          }
        )
      }
    },
    async updateUserTechsideCount({ commit }) {
      if (!this.techsideCountLoading) {
        const self = this
        this.techsideCountLoading = true
        try {
          const { data } = await Api.get(`${schedulerCompletedCountUrl}`)
          commit('setUserTechsideCount', data.count)
          self.techsideCountLoading = false
        } catch (error) {
          self.techsideCountLoading = false
        }
      }
    },
    updateUserSalesideCounts({ commit }) {
      if (!this.salesideCountLoading) {
        const self = this
        this.salesideCountLoading = true
        Salesidejob.getSalesidejobsCountsForAllConnectedShops(
          function(results) {
            var userSalesideShopJobCountArray = []
            var totalSalesideJobCount = 0
            var userSalesideShopMessageCountArray = []
            var totalSalesideMessageCount = 0
            for (var i = 0; i < results.length; i++) {
              // count/group jobs
              userSalesideShopJobCountArray[results[i].shop_id] = []
              userSalesideShopJobCountArray[results[i].shop_id].jobCount =
                results[i].jobCount
              userSalesideShopJobCountArray[
                results[i].shop_id
              ].countForDisplay =
                results[i].jobCount > 999 ? '999+' : '' + results[i].jobCount
              totalSalesideJobCount += results[i].jobCount
              // count/group messages
              userSalesideShopMessageCountArray[results[i].shop_id] = []
              userSalesideShopMessageCountArray[
                results[i].shop_id
              ].messageCount = results[i].messageCount
              userSalesideShopMessageCountArray[
                results[i].shop_id
              ].countForDisplay =
                results[i].messageCount > 999
                  ? '999+'
                  : '' + results[i].messageCount
              totalSalesideMessageCount += results[i].messageCount
            }
            commit(
              'setUserSalesideShopJobCountArray',
              userSalesideShopJobCountArray
            )
            commit('setUserSalesideJobCount', totalSalesideJobCount)
            commit(
              'setUserSalesideUnreadMessageCountArray',
              userSalesideShopMessageCountArray
            )
            commit(
              'setUserSalesideUnreadMessageCount',
              totalSalesideMessageCount
            )
            self.salesideCountLoading = false
          },
          function(error) {
            self.salesideCountLoading = false
          }
        )
      }
    },
    updateUnreadFaxCount({ commit }) {
      if (!this.unreadFaxCountLoading) {
        const self = this
        this.unreadFaxCountLoading = true
        Fax.getUnreadFaxCount(
          function(results) {
            commit('setUnreadFaxCount', results)
            self.unreadFaxCountLoading = false
          },
          function(error) {
            self.unreadFaxCountLoading = false
          }
        )
      }
    },
    updateGlaxisWorkAssignmentsCount({ commit }) {
      if (!this.glaxisWorkAssignmentsLoading) {
        const self = this
        this.glaxisWorkAssignmentsLoading = true
        GlaxisWorkAssingment.countForAllShops(
          function(results) {
            let totalCount = 0
            const glaxisWorkAssignmentsCountArray = []
            for (var i = 0; i < results.length; i++) {
              glaxisWorkAssignmentsCountArray[results[i].shop_id] = []
              glaxisWorkAssignmentsCountArray[results[i].shop_id].count =
                results[i].count
              glaxisWorkAssignmentsCountArray[results[i].shop_id].shopName =
                results[i].shop_name
              glaxisWorkAssignmentsCountArray[
                results[i].shop_id
              ].countForDisplay =
                results[i].count > 999 ? '999+' : '' + results[i].count
              totalCount += results[i].count
            }
            commit('setGlaxisWorkAssignmentsCount', totalCount)
            commit(
              'setGlaxisWorkAssignmentsCountArray',
              glaxisWorkAssignmentsCountArray
            )
            self.glaxisWorkAssignmentsLoading = false
          },
          function(error) {
            self.glaxisWorkAssignmentsLoading = false
          }
        )
      }
    },
    setUserCombineTechs({ commit }, value) {
      commit('setUserCombineTechs', value)
    },
    setWarehouseHideAdhesiveCache({ commit }, value) {
      commit('setWarehouseHideAdhesiveCache', value)
    },
    setWarehouseShopIdCache({ commit }, value) {
      commit('setWarehouseShopIdCache', value)
    },
    setWarehouseGroupByCache({ commit }, value) {
      commit('setWarehouseGroupByCache', value)
    },
    setWarehouseIsPartsRequired({ commit }, value) {
      commit('setWarehouseIsPartsRequired', value)
    },
    setNagsPriceIncreaseAcknowledged({ commit }, value) {
      commit('setNagsPriceIncreaseAcknowledged', value)
    },
    triggerGoToPage({ commit }, val) {
      commit('setGoToPage', val)
    },
    triggerDocument({ commit }, val) {
      console.log('trigger document with val: ', val)
      commit('setDocumentConfig', val)
    },
    async sendEmailVerification({ commit }) {
      try {
        await Api.get('/api/user/sendEmailVerification')
        Message.success('Email sent')
      } catch (error) {
        console.log(error)
      }
    },
    async confirmEmailVerification({ commit }) {
      try {
        const { data } = await Api.get('/api/user/confirmEmailVerification')
        if (data) {
          Message.success('Email verified')
        } else {
          Message.warning(
            'Please check your email for verification instructions'
          )
        }
        return data
      } catch (error) {
        console.log(error)
      }
    }
  },
  modules: {
    [schedulerNamespace]: schedulerModule,
    [reportsNamespace]: reportsModule,
    [activityNamespace]: activityModule,
    [availabilityNamespace]: availabilityModule,
    sch_v2: schedulerV2Module,
    sch_v3: schedulerV3Module,
    twilio: twilioModule,
    square: squareModule,
    rightPart: rightPartModule,
    shoppingCart: shoppingCartModule,
    notifications: notificationsModule,
    notificationsSettings: notificationsSettingsModule,
    confirmationsSettings: confirmationsSettingsModule,
    partVendors: partVendorsModule,
    leads: leadsModule,
    glassbillerLeads: glassbillerLeadsModule,
    rejectedInvoices: rejectedInvoicesModule,
    quotes: quotesModule,
    analytics: analyticsModule,
    jobDetails: jobDetailsModule,
    shopDialog: shopDialogModule,
    share: shareModule,
    chat: chatModule,
    catalogs: catalogsModule,
    support: supportModule,
    glaxis: glaxisModule,
    warehouse: warehouseModule
  }
})
