<template>
  <el-dialog
    width="600px"
    append-to-body
    :visible="visible"
    :before-close="close"
    custom-class="shopping-cart-dialog">
    <span slot="title" class="t-text-xl t-text-gray-800">Checkout</span>
    <div class="t-px-10 t-pb-8">
      <div>
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-shopping-cart-full t-mr-3"></i>
            <span class="t-font-normal">Items</span>
          </span>
        </el-divider>
        <div
          v-for="item in items"
          :key="item.id"
          class="t-flex t-rounded-md t-shadow t-px-3 t-py-2 t-mb-3 t-bg-gray-100"
        >
          <div class="t-flex-grow">
            <div class="t-font-medium t-text-gray-800 t-text-base">
              {{ item.sku }}
            </div>
            <div>{{ item.locationName }}</div>
          </div>
          <div class="t-flex t-text-base">
            <div>{{ item.quantity }}</div>
            <div class="t-pl-3 t-pr-2">x</div>
            <div class="t-font-medium t-text-gray-700">${{ item.unitPrice }}</div>
          </div>
        </div>
        <div
          class="t-flex t-items-end t-justify-end t-mt-4 t-mb-5 t-mr-3">
          <span class="t-pr-4 t-text-base">Total:</span>
          <span class="t-text-lg t-font-medium t-text-gray-700">
            ${{ orderTotal }}
          </span>
        </div>
      </div>
      <div class="t-pb-2">
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-coordinate t-mr-3"></i>
            <span class="t-font-normal">Shipping address</span>
          </span>
        </el-divider>
        <div v-if="fetchingAddresses" class="t-text-2xl t-text-center">
          <i class="el-icon-loading"/>
        </div>
        <div v-else>
          <div v-for="(address, index) in addresses" :key="address.CustomerAddressId" >
            <el-radio
              v-model="addressIndex"
              :label="index"
              :disabled="false"
              class="t-normal-case t-px-3 t-py-2 t-mb-3 t-font-normal t-flex
                     t-items-center t-rounded-md t-shadow t-bg-gray-100"
              >
              <div class="t-text-gray-600">{{ formatAddress(address.Address) }}</div>
            </el-radio>
          </div>
          <div>
            <el-radio
              v-model="addressIndex"
              :label="addresses.length"
              :disabled="false"
              class="t-normal-case t-px-3 t-py-2 t-mb-3 t-font-normal t-flex
                    t-items-center t-rounded-md t-shadow t-bg-gray-100"
              >
              <div class="t-text-gray-600">Will Call (Pickup)</div>
            </el-radio>
          </div>
        </div>
      </div>
      <div class="t-pb-2">
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-truck t-mr-3"></i>
            <span class="t-font-normal">Delivery methods</span>
          </span>
        </el-divider>
        <div v-if="addressIndex != null">
          <div v-for="(deliveryMethod, index) in deliveryMethods" :key="index">
            <el-radio
              v-model="deliveryMethodIndex"
              :label="index"
              class="t-normal-case t-px-3 t-py-2 t-mb-3 t-font-normal t-flex
                        t-items-center t-rounded-md t-shadow t-bg-gray-100"
            >
              <div class="t-mb-1">
                <span class="t-text-gray-600">Method:</span>
                <span class="t-ml-2 t-text-gray-800">
                  {{ deliveryMethod.label }}
                </span>
              </div>
              <div v-if="deliveryMethod.date" class="t-mb-1">
                <span class="t-text-gray-600">Date:</span>
                <span class="t-ml-2 t-text-gray-800">
                  <span>{{ formatDeliveryDate(deliveryMethod.date) }}</span>
                </span>
              </div>
              <div v-if="deliveryMethod.note" class="t-mb-1">
                <span class="t-text-gray-600">Hours:</span>
                <span class="t-ml-2 t-text-gray-600 t-italic">
                  <span>{{ deliveryMethod.note }}</span>
                </span>
              </div>
            </el-radio>
          </div>
        </div>
        <div v-else class="t-text-center">
          Select Shipping Address first
        </div>
      </div>
      <div>
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-chat-line-square t-mr-3"></i>
            <span class="t-font-normal">Comments</span>
          </span>
        </el-divider>
        <el-input
          type="textarea"
          :rows="3"
          placeholder="Enter your comments"
          v-model="form.comment"
        />
      </div>
    </div>
    <div class="t-px-10 t-py-5 t-text-right t-bg-gray-100 t-shadow">
      <el-button
        @click="submit"
        type="primary"
        size="medium"
        class="t-bg-blue-500 hover:t-bg-blue-400"
        v-loading.fullscreen.lock="submitting"
        :disabled="!submitEnabled">
        Place order
      </el-button>
    </div>
  </el-dialog>
</template>
<script>

import { isSaturday, isSunday } from 'date-fns'
import { getHumanDate, formatToDb } from '@/scripts/helpers/date.helpers'
import { 
  shoppingCartActions,
  shoppingCartMutations
 } from '@/store/modules/shopping-cart/types'

export default {
  data: () => ({
    addresses: [],
    addressIndex: null,
    deliveryMethodIndex: null,
    deliveryMethods: [],
    fetchingAddresses: false,
    submitting: false,
    form: {
      deliveryMethod: null,
      shippingAddress: null,
      requestedShipDate: null,
      deliveryOption: null,
      orderRemarks: null,
      comment: ''
    }
  }),
  computed: {
    visible () {
      return this.$store.state.shoppingCart.items.nielsenMollerCheckoutIsOpen
    },
    shopId () {
      return this.$store.state.shoppingCart.shopId
    },
    allItems () {
      return this.$store.state.shoppingCart.items.data
    },
    selectedItemIds () {
      return this.$store.state.shoppingCart.items.selectedItemIds
    },
    vendor () {
      return this.$store.state.shoppingCart.items.selectedVendor
    },
    items () {
      return this.allItems.filter(i => this.selectedItemIds.includes(i.id))
    },
    locationId () {
      if (this.items.length) {
        return this.items.filter(i => this.items[0].locationId === i.locationId).length === this.items.length ? 
        this.items[0].locationId : 
        null
      }
      return null
    },
    orderTotal () {
      let total = 0

      this.items.forEach(i => {
        total += parseFloat(i.unitPrice) * i.quantity
      })

      return Math.round(total * 100) / 100
    },
    submitEnabled () {
      return this.form.deliveryMethod !== null && this.form.requestedShipDate && this.locationId
    },
    selectedTab: {
      get () {
        return this.$store.state.shoppingCart.selectedTab
      },
      set (value) {
        return this.$store.commit(shoppingCartMutations.setSelectedTab, value)
      }
    }
  },

  watch: {
    visible: function (value) {
      if (value) {
        this.getAddresses()
      } else {
        this.addresses = []
        this.form.shippingAddress = null
        this.form.deliveryOption = null
        this.form.orderRemarks = null
        this.form.comment = ''
        this.addressIndex = null
        this.deliveryMethodIndex = null
      }
    },
    addressIndex: function (value) {
      this.deliveryMethods = []
      this.deliveryMethodIndex = null
      if (value !== null) {
        if (value === this.addresses.length) {
          this.generateWillCallOptions()
          return
        } else {
          this.generateDailyDeliveryOptions(value)
        }
      }
    },
    deliveryMethodIndex: function (value) {
      if (value !== null) {
        this.form.orderRemarks = this.deliveryMethods[value].label
        this.form.requestedShipDate = this.deliveryMethods[value].date || new Date()
      } else {
        this.form.orderRemarks = null
        this.form.requestedShipDate = null
      }
    }
  },
  methods: {
    close () {
      if (!this.submitting) {
        return this.$store.dispatch(shoppingCartActions.closeNielsenMollerCheckout)
      }
    },
    async getAddresses () {
      try {
        this.fetchingAddresses = true

        const parts = this.items.map((p) => {
          return {
            sku: p.sku,
            locationId: p.locationId,
            quantity: p.quantity,
            id: p.id
          }
        })

        const data = await this.$store.dispatch(shoppingCartActions.getNielsenMollerShippingAddresses, {
          parts,
          shopId: this.shopId,
          username: this.items[0].accountUsername,
          vendor: this.vendor
        })

        if (data.unavailableParts.length) {
          this.$emit('partsUnavailable', data.unavailableParts)
        }

        this.addresses = data.addresses
        this.fetchingAddresses = false
      } catch {
        this.$message.error('Can\'t get shipping addresses. Check Nielsen Moller credentials and try again.')
      }
    },
    placeOrder (payload) {
      return this.$store.dispatch(shoppingCartActions.placeOrder, payload)
    },
    getItemsCount () {
      return this.$store.dispatch(shoppingCartActions.getItemsCount)
    },
    formatDeliveryDate (timestamp) {
      return getHumanDate(timestamp)
    },
    async submit () {
      try {
        this.submitting = true

        await this.placeOrder({
          shopId: this.shopId,
          username: this.items[0].accountUsername,
          vendor: this.vendor,
          cartItemIds: this.selectedItemIds,
          locationId: this.locationId,
          ...this.form,
          requestedShipDate: formatToDb(this.form.requestedShipDate)
        })

        this.submitting = false

        this.selectedTab = 'orders'
        this.$store.commit(shoppingCartMutations.orders.setSelectedVendor, this.vendor)
        this.close()
        this.$message.info('Your order is processing...')
        this.$root.$emit('purchase-order::updated')
        this.getItemsCount()
      } catch (err) {
        this.$notify({
          title: 'Oops',
          message: err.response.data.message,
          type: 'error',
          showClose: true,
          duration: 10000,
          customClass: 't-break-all'
        })
        this.submitting = false
      }
    },
    formatAddress (address) {
      let fullAddress = address.Address1
      fullAddress += address.Address2 || ''
      fullAddress += `, ${address.City}, ${address.State} ${address.PostalCode}`
      return fullAddress
    },
    generateWillCallOptions() {
      let currentDayIndex = 0
      for (var i = 0; i < 4; i++) {
        let note = '6am to 5pm'
        const date = new Date();
        date.setDate(date.getDate() + currentDayIndex)
        if (isSaturday(date)) {
          currentDayIndex += 2
          note = '6am to 12pm'
        } else if (isSunday(date)) {
          currentDayIndex ++
          date.setDate(date.getDate() + 1)
        } else {
          currentDayIndex++
        }
        this.deliveryMethods.push(
          {
            date: date,
            label: 'Will Call (pickup)',
            note: note
          }
        )
      }
      this.form.shippingAddress = null
      this.form.deliveryMethod = 'Will Call (pickup)'
      return
    },
    generateDailyDeliveryOptions(value) {
      const singleOptions = []
      const dailyOptions = []
      this.addresses[value]?.DeliveryOptions.map((option) => {
        if (option.includes(":")) {
          dailyOptions.push(option)
        } else {
          singleOptions.push({
            label: option,
            date: null,
          })
        }
      })
  
      const dailyMethods = []
      let currentDayIndex = 0
      for (var i = 0; i < 4; i++) {
        const date = new Date();
        date.setDate(date.getDate() + currentDayIndex)
        if (isSaturday(date)) {
          currentDayIndex += 3
          date.setDate(date.getDate() + 2)
        } else if (isSunday(date)) {
          currentDayIndex++
          date.setDate(date.getDate() + 1)
        } else {
          currentDayIndex++
        }
        dailyOptions.map((option) => {
          dailyMethods.push({
            date,
            label: option
          })
        })
      }
  
      this.deliveryMethods = [...singleOptions, ...dailyMethods]
      this.form.shippingAddress = this.addresses[value].Address
      this.form.deliveryMethod = 'Delivery'
    }
  }
}
</script>
