<template>
  <div class="scheduler-view-filter" @click="toggleView" id="schedulerViewButton">
    <v-icon name="globe-americas" v-if="isList" scale="1.5" />
    <v-icon name="th-list" v-else scale="1.5" />
    <b-tooltip
      ref="tooltip"
      target="schedulerViewButton"
      placement="right"
    >{{isList ? 'Map View' : 'Board view'}}</b-tooltip>
  </div>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    isList() {
      return this.value === 'list'
    }
  },
  methods: {
    toggleView() {
      const value = this.isList ? 'map' : 'list'
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/SchedulerViewFilter';
</style>
