const firebaseConfig = {
  apiKey: 'AIzaSyBYV2dxYY_RkbdAIwits-SzAd3yn-BDGYc',
  authDomain: 'techside-native.firebaseapp.com',
  databaseURL: 'https://techside-native.firebaseio.com',
  projectId: 'techside-native',
  storageBucket: 'techside-native.appspot.com',
  messagingSenderId: '192144396084',
  appId: '1:192144396084:web:1d33052d0c6929ce'
}

export default firebaseConfig
