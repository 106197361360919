var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        title: "National Vendor Database Search",
        id: "vendorSearchDialog",
        "no-close-on-backdrop": "",
        "no-cose-on-esc": "",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg",
        "hide-header-close": ""
      },
      on: { show: _vm.dialogAboutToAppear }
    },
    [
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c("label", { attrs: { for: "searchInput" } }, [
            _vm._v("Search or choose from list")
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchValue,
                expression: "searchValue"
              }
            ],
            staticClass: "form-control form-control-sm autocomplete-off",
            attrs: { id: "searchInput" },
            domProps: { value: _vm.searchValue },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchValue = $event.target.value
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          {
            staticClass: "form-group col-md-12",
            attrs: {
              id:
                "vendor-list" + (_vm.selectedWarehouse !== null ? "-short" : "")
            }
          },
          _vm._l(_vm.prunedWarehouseList, function(warehouse, index) {
            return _c(
              "b-list-group-item",
              {
                key: index,
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.selectWarehouseClicked(warehouse)
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "fa fa-circle",
                  style: "color:" + _vm.getVendorColor(warehouse.type)
                }),
                _vm._v(
                  " " +
                    _vm._s(_vm.getVendorTitle(warehouse.type).toUpperCase()) +
                    " : "
                ),
                _c("strong", [_vm._v(_vm._s(warehouse.name))])
              ]
            )
          }),
          1
        )
      ]),
      _vm.selectedWarehouse !== null
        ? _c("div", { attrs: { id: "detail-wrapper" } }, [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "form-group col-md-12" },
                [_c("Strong", [_vm._v("Selected Warhouse For New Vendor")])],
                1
              )
            ]),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v("Name")]),
                _c("p", { attrs: { id: "name" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.getVendorTitle(_vm.selectedWarehouse.type) +
                        ":" +
                        _vm.selectedWarehouse.name
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v("Manager")]),
                _c("p", { attrs: { id: "name" } }, [
                  _vm._v(_vm._s(_vm.selectedWarehouse.manager))
                ])
              ])
            ]),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-12" }, [
                _c("label", [_vm._v("Address")]),
                _c("p", { attrs: { id: "address" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.selectedWarehouse.g_address &&
                        _vm.selectedWarehouse.g_address.full_address
                    )
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", [_vm._v("Phone")]),
                _c("p", { attrs: { id: "phone" } }, [
                  _vm._v(_vm._s(_vm.selectedWarehouse.phone))
                ])
              ]),
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", [_vm._v("Fax")]),
                _c("p", { attrs: { id: "fax" } }, [
                  _vm._v(_vm._s(_vm.selectedWarehouse.fax))
                ])
              ]),
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", [_vm._v("Email")]),
                _c("p", { attrs: { id: "email" } }, [
                  _vm._v(_vm._s(_vm.selectedWarehouse.email))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", variant: "primary" },
                on: { click: _vm.selectClicked }
              },
              [_vm._v("Use Selected")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", variant: "primary" },
                on: { click: _vm.cancelClicked }
              },
              [_vm._v("Cancel")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }