var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "34px",
        height: "42px",
        viewBox: "0 0 34 42",
        version: "1.1"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                transform: "translate(1.000000, 1.000000)",
                "fill-rule": "nonzero"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M14.3556667,39.1929592 C2.2475,22.7367913 0,21.0478855 0,14.9999963 C0,6.71570149 7.16341667,0 16,0 C24.8365833,0 32,6.71570149 32,14.9999963 C32,21.0478855 29.7525,22.7367913 17.6443333,39.1929592 C16.84975,40.2690527 15.1501667,40.2689745 14.3556667,39.1929592 Z",
                  stroke: "#FFFFFF",
                  "stroke-width": "2",
                  fill: _vm.fillColor
                }
              }),
              _c("path", { attrs: { d: _vm.path, fill: "#FFFFFF" } })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }