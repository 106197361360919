<template>
  <div>
    <el-badge :value="jobFilterCount[selected.value]" type="primary">
      <el-select
        v-model="selected"
        value-key="label"
        class="no-border-select"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item"
        >
          <span class="select-job-filter-option">
            <span>{{ item.label }}</span>
            <el-tag size="mini">{{ jobFilterCount[item.value] }}</el-tag>
          </span>
        </el-option>
      </el-select>
    </el-badge>
    <i class="el-icon-loading" v-if="loading" />
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import actions from '@/store/modules/scheduler_v3/types'
import * as R from 'ramda'

export default {
  computed: {
    selected: sync('sch_v3/activeJobListFilter'),
    jobFilterCount: get('sch_v3/jobFilterCount')
  },
  data() {
    return {
      loading: false,
      options: [
        {
          label: 'Completed',
          value: 'completed'
        },
        {
          label: 'Unscheduled',
          value: 'unscheduled'
        },
        {
          label: 'Past scheduled',
          value: 'past'
        },
        {
          label: 'Unassigned',
          value: 'unassigned'
        },
        {
          label: 'Parts backorder',
          value: 'backorder'
        },
        {
          label: 'Inspection',
          value: 'inspection'
        },
        {
          label: 'Pending schedule',
          value: 'pending'
        },
        {
          label: 'To reschedule',
          value: 'reschedule'
        },
        {
          label: 'Cancelled',
          value: 'cancel'
        }
      ]
    }
  },
  methods: {
    getSchedulerJobList: call(actions.getSchedulerJobList)
  },
  watch: {
    async selected(curr, prev) {
      if (!R.equals(curr, prev)) {
        this.loading = true
        await this.getSchedulerJobList()
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/SchedulerJobListFilter';
</style>
