<template>
  <el-dialog
    append-to-body
    top="120px"
    width="900px"
    custom-class="edit-warehouse-entity-dialog"
    :visible="visible"
    :before-close="handleClose"
  >
    <span slot="title" class="t-text-gray-700 t-text-lg">
      <i class="el-icon-search"/>
      <span class="t-ml-3">
        Item Search
      </span>
    </span>

    <div
      class="t-px-6 t-py-4 t-border-0 t-border-b t-border-solid
        t-border-gray-200 t-flex t-justify-between t-items-center"
    >
      <el-input
        v-model="search"
        clearable
        size="small"
        class="t-w-72"
        placeholder="Enter search string (minimum 4 char)"
      />
      <el-button
        v-if="search.length >= 4"
        size="mini"
        icon="el-icon-refresh"
        @click="refreshResults"
      >
        Refresh results
      </el-button>
    </div>

    <div v-if="search && search.length >= 4" class="t-flex t-max-h-[800px]">
      <div
        class="t-w-1/2 t-pt-4 t-pb-8 t-border-0 t-border-r t-border-solid
          t-border-gray-200"
      >
        <div class="t-text-base t-text-gray-600 t-pb-2 t-text-center">
          Organization Results
        </div>

        <div
          v-if="itemsFetching"
          v-loading="true"
          class="t-h-16"
        />

        <div
          v-else-if="!items.length"
          class="t-text-gray-400 t-italic t-text-center t-py-2"
        >
          <div>No items found</div>
          <el-button
            size="mini"
            icon="el-icon-plus"
            class="t-mt-4"
            @click="createItemDialogVisible = true"
          >
            Create an item
          </el-button>
        </div>

        <el-scrollbar
          v-else
          class="t-h-full"
          wrap-class="t-overflow-x-hidden"
          view-class="t-space-y-2 t-mb-6 t-px-6"
        >
          <div
            v-for="i in items"
            :key="i.id"
            class="t-border t-border-solid t-border-gray-200 t-shadow t-rounded
              t-px-4 t-py-2 t-flex t-items-center t-space-x-4"
          >
            <div class="t-flex-shrink-0 t-text-blue-400">
              <i
                class="el-icon-setting t-cursor-pointer"
                @click="itemProfileId = i.id"
              />
            </div>
            <div class="t-flex-grow">
              <div v-html="highlightSearch(i.number)" />
              <div class="t-text-xs t-text-gray-400">{{ i.description }}</div>
            </div>
            <div class="t-flex-shrink-0">${{ i.listPrice }}</div>
            <div class="t-flex-shrink-0">
              <el-button
                circle
                size="mini"
                icon="el-icon-plus"
                @click="selectItem(i)"
              />
            </div>
          </div>
        </el-scrollbar>
      </div>

      <div class="t-w-1/2 t-pt-4 t-pb-8">
        <div class="t-text-base t-text-gray-600 t-pb-2 t-text-center">
          NAGS Glass Results
        </div>

        <div
          v-if="nagsFetching"
          v-loading="true"
          class="t-h-16"
        />

        <div
          v-else-if="!Object.keys(nagsGlass).length"
          class="t-text-gray-400 t-italic t-text-center t-py-2"
        >
          No glass found
        </div>

        <el-scrollbar
          v-else
          class="t-h-full"
          wrap-class="t-overflow-x-hidden"
          view-class="t-space-y-4 t-mb-6"
        >
          <div
            v-for="(value, key) in nagsGlass"
            :key="key"
            class="t-space-y-2 t-px-6"
          >
            <div class="t-text-gray-500">{{ key }}</div>
            <div
              v-for="i in value"
              :key="i.id"
              class="t-border t-border-solid t-border-gray-200 t-shadow
                t-rounded t-px-4 t-py-2 t-flex t-items-center t-space-x-4"
            >
              <div class="t-flex-grow">
                <div v-html="highlightSearch(i.number)" />
                <div class="t-text-xs t-text-gray-400">
                  {{ i.positionName }} {{ i.sideName }} {{ i.glassColor }}
                </div>
              </div>
              <div class="t-flex-shrink-0">${{ i.price }}</div>
              <div class="t-flex-shrink-0">
                <el-button
                  circle
                  size="mini"
                  icon="el-icon-plus"
                  v-loading.fullscreen.lock="nagsItemSelecting"
                  @click="selectNagsItem(i)"
                />
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <ItemProfileDialog
      :id="itemProfileId"
      :is-catalog="false"
      :shop-id="shopId"
      @closed="itemProfileId = null"
    />

    <CreateItemDialog
      :visible.sync="createItemDialogVisible"
      :is-catalog="false"
      :parent-id="null"
      :organization-id="shop.organization_id"
      @created="onItemCreated"
    />
  </el-dialog>
</template>

<script>
import debounce from 'lodash.debounce'
import ItemProfileDialog from '@/components/Catalogs/Profile/Index.vue'
import CreateItemDialog from '@/components/Catalogs/CreateUpdate/Index.vue'

export default {
  components: {
    ItemProfileDialog,
    CreateItemDialog
  },

  props: {
    visible: Boolean,
    shopId: Number
  },

  data() {
    return {
      search: '',
      itemsFetching: false,
      nagsFetching: false,
      items: [],
      nagsGlass: [],
      nagsItemSelecting: false,
      createItemDialogVisible: false,
      itemProfileId: null
    }
  },

  computed: {
    shop() {
      return this.$store.state.shops.find(i => i.id === this.shopId)
    }
  },

  watch: {
    search: debounce(function(val) {
      if (val && val.length >= 4) {
        this.searchItems()
        this.searchNagsGlass()
      } else {
        this.items = []
        this.nagsGlass = []
      }
    }, 700)
  },

  methods: {
    handleClose() {
      this.search = ''
      this.$emit('update:visible', false)
    },

    async searchItems() {
      try {
        this.itemsFetching = true
        const { data } = await this.$unum.warehouse()
          .post('items/search-by-number', {
            organizationId: this.shop.organization_id,
            search: this.search
          })
        this.items = data.data
      } finally {
        this.itemsFetching = false
      }
    },

    async searchNagsGlass() {
      try {
        this.nagsFetching = true
        const { data } = await this.$unum.catalogs()
          .get('nags/glass-search', {
            params: {
              search: this.search
            }
          })
        const glass = {}
        data.forEach(i => {
          if (!glass[i.openingType]) {
            glass[i.openingType] = []
          }
          glass[i.openingType].push(i)
        })
        this.nagsGlass = glass
      } finally {
        this.nagsFetching = false
      }
    },

    refreshResults() {
      this.searchItems()
      this.searchNagsGlass()
    },

    highlightSearch(number) {
      return number.replace(
        new RegExp(this.search, 'gi'), match => `<mark>${match}</mark>`
      )
    },

    selectItem({ id, number }) {
      this.$emit('selected', {
        itemId: id,
        itemName: number,
        quantity: 1,
        cost: null,
        notes: ''
      })
      this.handleClose()
    },

    async selectNagsItem(i) {
      try {
        this.nagsItemSelecting = true
        const { data } = await this.$unum.warehouse()
          .post('items/create-update-from-nags-part', {
            organizationId: this.shop.organization_id,
            number: i.number,
            description: i.openingType + ' ' + i.glassColor,
            nagsId: i.number,
            listPrice: +i.price
          })
        this.selectItem({
          id: data.data.id,
          number: i.number
        })
      } finally {
        this.nagsItemSelecting = false
      }
    },

    onItemCreated({ id, number }) {
      this.selectItem({ id, number })
    }
  }
}
</script>
