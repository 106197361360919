import { getters } from './types'

export default {
  [getters.shopsSettings]: state => {
    return state.shopsSettings
  },
  [getters.shopsTimers]: state => {
    return state.shopsTimers
  },
  [getters.jobPageTimer]: state => {
    return state.jobPageTimer
  },
  [getters.schedulerJobsTimers]: state => {
    return state.schedulerJobsTimers
  }
}
