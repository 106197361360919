import { getAsYouType, parsePhoneNumber } from 'awesome-phonenumber';
import { isEmail } from 'validator';

const validateExists = (rule, value) => {
  return !!value || (!rule?.required && !value);
};

const validateEmail = (rule, value, callback) => {
  if (!validateExists(rule, value)) {
    return callback(new Error('Field is required'));
  }

  if (isEmail(value)) {
    return callback();
  }

  return callback(new Error(rule.message));
};

const validateEmailArray = (rule, value, callback) => {
  if (!validateExists(rule, value)) {
    return callback(new Error('Field is required'));
  }

  for (let email of value) {
    let res = validateEmail(rule, email, callback);
    if (!res) {
      continue;
    }

    return res;
  }
  return callback();
};

const validatePhone = (rule, value, callback) => {
  if (!validateExists(rule, value)) {
    return callback(new Error('Field is required'));
  }

  const phone = parsePhoneNumber(value, 'US');
  if (phone.isValid()) {
    return callback();
  }

  return callback(new Error(rule.message));
};

const validatePhoneArray = (rule, value, callback) => {
  if (!validateExists(rule, value)) {
    return callback(new Error('Field is required'));
  }

  for (let phone of value) {
    let res = validatePhone(rule, phone, callback);

    if (!res) {
      continue;
    }

    return res;
  }

  return callback();
};

const formatPhonePartial = (value) => {
  const ayt = getAsYouType('US');

  const stripped = value.toString().replace(/[\D\+]/g, '');
  ayt.reset(stripped);

  const phone = ayt.getPhoneNumber();
  if (!phone.isValid()) {
    // if phone is not correct replace it with original contect
    // so we will see what we are typing.
    ayt.reset(value);
    return ayt.number();
  }

  if (phone.getRegionCode() == 'US') {
    return phone.getNumber('national');
  }

  return phone.getNumber('international');
};

export {
  formatPhonePartial,

  validatePhone,
  validatePhoneArray,
  validateEmail,
  validateEmailArray
};