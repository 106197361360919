<template>
  <div class="t-pb-4">
    <el-table
      :data="parts"
      class="t-w-full"
      size="small"
      :show-header="false"
      empty-text="No parts found">
      <el-table-column
        width="150">
        <template slot-scope="scope">
          <span class="t-text-indigo-800">
            <span>{{ scope.row.Sku }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="locationName"
        width="150">
      </el-table-column>
      <el-table-column width="100">
      </el-table-column>
      <!-- TODO: try to return delivery info to use here -->
      <!-- <el-table-column>
        <template>
          <i class="el-icon-truck"></i>
          <span class="t-ml-1">No lead time</span>
        </template>
      </el-table-column> -->
      <el-table-column>
      </el-table-column>
      <el-table-column
        prop="QuantityAvailable"
        width="80">
      </el-table-column>
      <el-table-column
        width="80">
        <template slot-scope="scope">
          ${{ scope.row.UnitPrice }}
        </template>
      </el-table-column>
      <el-table-column align="center" width="80">
        <template slot-scope="scope">
          <button
            v-if="scope.row.QuantityAvailable > 0"
            @click="add(scope.row)"
            class="t-cursor-pointer t-text-purple-600 t-px-2 t-pt-1 t-pb-0
                   t-border t-border-solid t-border-purple-400 t-rounded-full
                   t-bg-white hover:t-text-white hover:t-border-purple-600
                   hover:t-bg-purple-600"
          >
            <i class="el-icon-shopping-cart-2 t-text-base"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>
    <add-part-to-cart
      :visible="addPartToCartOpen"
      :part="selectedPart"
      :vendor="vendor"
      :shopId="shopId"
      :jobId="jobId"
      :status="status"
      :warehouse="warehouse"
      :username="username"
      @refresh-data="$emit('refresh-data')"
      @close="addPartToCartOpen = false"
      @addAndLinkPart="addAndLinkPart($event)"
    />
  </div>
</template>

<script>
import AddPartToCart from '../AddPartToCart'

export default {
  props: ['jobId', 'parts', 'shopId', 'status', 'username', 'vendor', 'warehouse'],
  data: () => ({
    addPartToCartOpen: false,
    selectedPart: null
  }),
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    async add (part) {
      this.selectedPart = part
      this.addPartToCartOpen = true
    },
    addAndLinkPart (cartItemId) {
      this.$emit('addAndLinkPart', cartItemId)
    }
  },
  components: {
    AddPartToCart
  }
}
</script>
