<template>
  <el-dialog
    center
    append-to-body
    title="Select a template"
    :visible="visible"
    :before-close="handleClose">
    <el-form
      label-width="140px"
      size="medium">
      <el-form-item>
        <span slot="label" class="undo-label-styles">Type</span>
        <el-select v-model="template_id" placeholder="Select a template" style="width: 100%;">
          <el-option
            v-for="template in templates"
            :key="template.id"
            :label="`${template.shop_name ? '(' + template.shop_name + ') ': ''}${template.name}`"
            :value="template.id">
          </el-option>
        </el-select>
        or <a href="javascript:void(0)" @click="manageTemplates">Manage templates</a>
      </el-form-item>
      <el-form-item>
        <span slot="label" class="undo-label-styles">Message preview</span>
        <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 10}"
          placeholder="Message preview"
          v-model="template_body"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          plain
          :disabled="this.template_body === ''"
          type="primary"
          @click="handleUse">
          Use
        </el-button>
        <el-button
          class="pull-right"
          plain
          @click="handleClose">
          Close
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { get, call } from 'vuex-pathify'
import TextMessageTemplate from '@/scripts/objects/text_message_template'

export default {
  props: ['name'],
  data: () => ({
    templates: [],
    template_id: null,
    template_body: ''
  }),
  computed: {
    visible: get('twilio/textMessages/templates/selectTemplateDialogVisible')
  },
  watch: {
    visible: async function (value) {
      if (value) {
        try {
          const {data} = await this.$unum.job().get('textMessageTemplate/allByType/sms?type=2')
          this.templates = data || []
        } catch {
          this.templates = []
        }
      } else {
        this.template_id = null
        this.template_body = ''
      }
    },
    template_id: function (value) {
      if (value !== null) {
        this.templates.forEach(tmp => {
          if (tmp.id === value) {
            let body = tmp.body
            if (this.name) {
              body = body.replace('{name}', this.name)
            }
            this.template_body = body
          }
        })
      }
    }
  },
  methods: {
    openTextMessageTemplatesDialog: call('twilio/textMessages/templates/open'),
    setTextMessageTemplatesReferral: call('twilio/textMessages/templates/setReferral'),
    setSelectTemplateDialogVisible: call('twilio/textMessages/templates/setSelectTemplateDialogVisible'),
    handleClose () {
      this.setSelectTemplateDialogVisible(false)
    },
    manageTemplates () {
      this.setSelectTemplateDialogVisible(false)
      this.openTextMessageTemplatesDialog()
      this.setTextMessageTemplatesReferral('select-template')
    },
    handleUse () {
      this.$emit('templateSelected', this.template_body)
      this.handleClose()
    }
  }
}
</script>
