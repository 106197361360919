import * as R from 'ramda';

const PREFIX = 'notifications/';

const actions = {
  open: 'open',
  close: 'close',
  changeTab: 'changeTab',

  // on new data loaded to notifications list
  load: 'load',

  // load specific page for the list
  loadPage: 'loadPage',

  // reload last page. Utilised when we run readOne
  reloadPage: 'reloadPage',

  // on websocket connection established
  getUnreadCount: 'getUnreadCount',

  // on receiving notifications pushed using websocket
  receive: 'receive',

  // execute action of reading notification against server
  read: 'read',

  // mark one notifications as read
  readOne: 'readOne',

  // mark all notifications read
  readAll: 'readAll',

  // add notification to the list
  add: 'add',
};

const mutations = {
  setIsOpened: 'setIsOpened',
  setTab: 'setTab',

  addNotifications: 'addNotifications',
  removeNotifications: 'removeNotifications',
  moveNotifications: 'moveNotifications',

  setCount: 'setCount',
  setPage: 'setPage',
  increaseCount: 'increaseCount',

  toggleReadAllLoader: 'toggleReadAllLoader',

  lock: 'lock',
  unlock: 'unlock',
};

const globalActions = R.map(R.concat(PREFIX))(actions);
const globalMutations = R.map(R.concat(PREFIX))(mutations);

export {
  actions,
  mutations,
  globalActions,
  globalMutations
};
