var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gb-tag-select" },
    [
      _vm.selected
        ? _c("gb-tag", _vm._b({}, "gb-tag", _vm.selected, false), [
            _vm._v(" " + _vm._s(_vm.selected.label) + " ")
          ])
        : _vm._e(),
      _c(
        "el-select",
        {
          attrs: { disabled: _vm.disabled },
          model: {
            value: _vm.select,
            callback: function($$v) {
              _vm.select = $$v
            },
            expression: "select"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c(
            "el-option",
            { key: item.value, attrs: { value: item.value } },
            [_vm._v(" " + _vm._s(item.label) + " ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }