import { JOB_DETAILS_QUERY } from '@/apollo/queries/job-details.query'
import { GET_CONSUMER_QUERY } from '@/apollo/queries/consumer.query'
import { SHOP_QUERY } from '@/apollo/queries/shops.query'
import { JOB_VEHICLE_QUERY } from '@/apollo/queries/job-vehicle.query'

import { sync } from 'vuex-pathify'
import Vue from 'vue'

const JobDetailsClaimDataMixin = {
  data() {
    return {
      jobId: -1,
      job: {}
    }
  },
  apollo: {
    job: {
      query: JOB_DETAILS_QUERY,
      variables() {
        return {
          id: this.jobId
        }
      },
      skip() {
        return !this.jobId || this.jobId <= -1
      },
      watchLoading(v) {
        this.jobLoading = v
      }
    },
    shop: {
      query: SHOP_QUERY,
      variables() {
        return {
          id: this.job?.shop_id
        }
      },
      skip() {
        return !this.job?.shop_id
      },
      watchLoading(v) {
        this.shopLoading = v
      }
    },
    customer: {
      query: GET_CONSUMER_QUERY,
      variables() {
        return {
          id: this.job?.consumer_id,
          organization_id: this.shop?.organization_id
        }
      },
      update: data => data.consumer,
      skip() {
        return !this.job?.consumer_id || !this.shop?.organization_id
      },
      watchLoading(v) {
        this.customerLoading = v
      }
    },
    billTo: {
      query: GET_CONSUMER_QUERY,
      variables() {
        return {
          id: this.job?.bill_to_id,
          organization_id: this.shop?.organization_id
        }
      },
      update: data => data.consumer,
      skip() {
        return !this.job?.bill_to_id || !this.shop?.organization_id
      },
      watchLoading(v) {
        this.billToLoading = v
      }
    },
    vehicle: {
      query: JOB_VEHICLE_QUERY,
      variables() {
        return {
          id: this.job?.vehicle_id
        }
      },
      skip() {
        return !this.job?.vehicle_id
      },
      watchLoading(v) {
        this.vehicleLoading = v
      }
    }
  },
  computed: {
    jobLoading: sync('glaxis/loading@job'),
    shopLoading: sync('glaxis/loading@shop'),
    customerLoading: sync('glaxis/loading@customer'),
    billToLoading: sync('glaxis/loading@billTo'),
    vehicleLoading: sync('glaxis/loading@vehicle')
  }
}

export default JobDetailsClaimDataMixin
