var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex" },
    [
      _c("div", { staticClass: "t-text-gray-700 t-text-sm" }, [
        _vm._v(
          " Showing " +
            _vm._s(_vm.total ? _vm.from : "0") +
            " to " +
            _vm._s(_vm.to) +
            " of " +
            _vm._s(_vm.total) +
            " "
        )
      ]),
      _c("el-pagination", {
        staticClass: "t-flex-grow t-text-right",
        attrs: {
          background: "",
          layout: "prev, pager, next",
          total: _vm.total,
          "page-size": _vm.limit,
          "current-page": _vm.page
        },
        on: {
          "update:currentPage": function($event) {
            _vm.page = $event
          },
          "update:current-page": function($event) {
            _vm.page = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }