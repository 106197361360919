var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { placement: "right", trigger: "manual" },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "scheduler-route-tech-stop"
        },
        [
          _c("div", [_vm._v("Name")]),
          _c("el-input", {
            attrs: { label: "Name", placeholder: "Custom location name" },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          }),
          _c("div", [_vm._v("Address")]),
          _c("address-input", {
            attrs: { popperFix: "" },
            model: {
              value: _vm.address,
              callback: function($$v) {
                _vm.address = $$v
              },
              expression: "address"
            }
          }),
          _c(
            "div",
            { staticClass: "tech-stop-button" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    disabled:
                      !_vm.name || !(_vm.address && _vm.address.google_place_id)
                  },
                  on: { click: _vm.save }
                },
                [_vm._v(" Save ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "reference" }, slot: "reference" },
        [
          _c("v-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.hideIcon,
                expression: "!hideIcon"
              }
            ],
            attrs: { name: "map-marker-alt" }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }