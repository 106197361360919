<template>
  <el-button
    v-bind="{ ...$props, ...$attrs }"
    circle
    class="v-icon-button"
    @click="$emit('click')"
  >
    <v-icon :name="name" />
  </el-button>
</template>

<script>
export default {
  props: {
    name: String,
    size: {
      type: String,
      default: () => 'mini'
    }
  }
}
</script>

<style lang="scss" scoped>
.v-icon-button {
  &.el-button--mini {
    height: 32px;
    width: 32px;
  }
  background: none;
  border: none;
}
</style>
