import { render, staticRenderFns } from "./DraftWarningDialog.vue?vue&type=template&id=3d3b0248&scoped=true&"
import script from "./DraftWarningDialog.vue?vue&type=script&lang=js&"
export * from "./DraftWarningDialog.vue?vue&type=script&lang=js&"
import style0 from "./DraftWarningDialog.vue?vue&type=style&index=0&id=3d3b0248&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d3b0248",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d3b0248')) {
      api.createRecord('3d3b0248', component.options)
    } else {
      api.reload('3d3b0248', component.options)
    }
    module.hot.accept("./DraftWarningDialog.vue?vue&type=template&id=3d3b0248&scoped=true&", function () {
      api.rerender('3d3b0248', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/JobPageV2/helpers/dialogs/DraftWarningDialog.vue"
export default component.exports