import gql from 'graphql-tag'

export const VEHICLE_FRAGMENT = gql`
  fragment VehicleFields on Vehicle {
    id
    vin
    vin_is_valid
    description
    year
    make
    make_id
    model
    model_id
    sub_model
    sub_model_id
    style
    style_id
    manual
    image_url
    thumb_url
    nags_id
    plate_number
    unit_number
    state
  }
`
