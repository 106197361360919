var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-font-sans" },
    [
      _vm.connected ? _c("ChatButton", { staticClass: "t-z-50" }) : _vm._e(),
      _c("Dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }