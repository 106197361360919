var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-bg-white t-rounded-md" },
    [
      _vm.fetching
        ? _c(
            "div",
            { staticClass: "t-h-12 t-rounded t-flex t-justify-center" },
            [
              _c("div", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: true,
                    expression: "true"
                  }
                ]
              })
            ]
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          ref: "table",
          staticClass: "t-rounded",
          attrs: {
            data: _vm.invoices,
            size: "small",
            "row-class-name": _vm.tableRowClassName
          }
        },
        [
          _c("el-table-column", {
            attrs: { width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c("el-checkbox", {
                      key: props.row.job_id,
                      attrs: {
                        checked: !!_vm.selectedInvoices.find(function(i) {
                          return i.job_id === props.row.job_id
                        })
                      },
                      on: {
                        change: function($event) {
                          return _vm.checkboxChanged($event, props.row.job_id)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function(props) {
                  return [
                    _c("el-input", {
                      attrs: {
                        placeholder: "Job id",
                        value: _vm.jobIdFilter,
                        clearable: "",
                        size: "mini"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$emit("update:jobIdFilter", $event)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/jobs/" + props.row.job_id,
                          target: "_blank"
                        }
                      },
                      [_vm._v(" " + _vm._s(props.row.job_id) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "referral_number" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function(props) {
                  return [
                    _c("el-input", {
                      attrs: {
                        placeholder: "Referral #",
                        value: _vm.referralFilter,
                        clearable: "",
                        size: "mini"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$emit("update:referralFilter", $event)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "purchase_order_number" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function(props) {
                  return [
                    _c("el-input", {
                      attrs: {
                        placeholder: "PO/RO #",
                        value: _vm.poFilter,
                        clearable: "",
                        size: "mini"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$emit("update:poFilter", $event)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "default",
                fn: function(props) {
                  return [
                    props.row.purchase_order_number &&
                    props.row.requisition_order_number
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(props.row.purchase_order_number) +
                              " / " +
                              _vm._s(props.row.requisition_order_number) +
                              " "
                          )
                        ])
                      : props.row.requisition_order_number
                      ? _c("span", [
                          _vm._v(
                            " / " +
                              _vm._s(props.row.requisition_order_number) +
                              " "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            " " + _vm._s(props.row.purchase_order_number) + " "
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _vm.consumer && _vm.consumer.is_insurance
            ? [
                _c("el-table-column", {
                  attrs: { label: "Company name", prop: "main_company_name" }
                }),
                _c("el-table-column", {
                  attrs: { width: "160" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function(props) {
                          return [
                            _c("el-input", {
                              attrs: {
                                placeholder: "Customer name",
                                value: _vm.customerNameFilter,
                                clearable: "",
                                size: "mini"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.$emit(
                                    "update:customerNameFilter",
                                    $event
                                  )
                                }
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("div", { staticClass: "t-break-normal" }, [
                              _vm._v(
                                " " +
                                  _vm._s(props.row.secondary_customer_name) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1509824601
                  )
                }),
                _c("el-table-column", {
                  attrs: { label: "Trading partner", prop: "trading_partner" }
                })
              ]
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Invoice date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(props.row.invoice_date)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Days old", width: "80px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    props.row.invoice_date !== "0000-00-00"
                      ? _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.daysPassed(props.row.invoice_date)
                            )
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Total balance" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _vm._v(
                      " $ " +
                        _vm._s(_vm.formatAmount(props.row.total_balance)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Balance due" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _vm._v(
                      " $ " +
                        _vm._s(_vm.formatAmount(props.row.remaining_balance)) +
                        " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }