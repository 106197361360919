var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        {
          class: _vm.variant,
          attrs: { show: _vm.dismissCountDown, dismissible: "" },
          on: {
            dismissed: function($event) {
              _vm.dismissCountDown = 0
            },
            "dismiss-count-down": _vm.countDownChanged
          }
        },
        [
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.title) + ": ")]),
            _vm._v(_vm._s(_vm.message))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }