<template>
  <div>
    <el-row class='dialog-header-text'>
      Orginal Purchase Order:
    </el-row>
      <br/>
    <el-row class='dialog-body-text'>
      <el-col :span='10'>
        Id:
      </el-col>
      <el-col :span='8'>
        {{purchaseOrderInfo.internal_id}}
      </el-col>
    </el-row>
    <el-row class='dialog-body-text'>
      <el-col :span='10'>
        Cost:
      </el-col>
      <el-col :span='8'>
        ${{partData.cost}}
      </el-col>
    </el-row>
    <el-row class='dialog-body-text'>
      <el-col :span='10'>
        Vendor:
      </el-col>
      <el-col :span='8'>
        {{vendorInfo ? vendorInfo.name : null}}
      </el-col>
    </el-row>
    <el-row class='dialog-body-text'>
      <el-col :span='10'>
        Available Inventory:
      </el-col>
      <el-col :span='8'>
        {{inventoryData.qty}}
      </el-col>
    </el-row>
    <el-row class='dialog-body-text'>
      <el-col :span='10'>
        PO Date:
      </el-col>
      <el-col :span='8'>
        {{poDate}}
      </el-col>
    </el-row>
    <br/>
    <div style='text-align: center;'>
    <el-button icon='el-icon-document' type="primary"  @click="poButtonPressed"
      >View Purchase Order</el-button>
    </div>
    <el-divider/>
    <br/>
  </div>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
import moment from 'moment'

export default {
  props: ['vendorInfo', 'purchaseOrderInfo', 'partData', 'inventoryData'],
  methods: {
    poButtonPressed () {
      this.$root.$emit('openOriginalPurchaseOrder', this.purchaseOrderInfo.id)
    }
  },
  computed: {
    poDate () {
      if (this.purchaseOrderInfo && this.purchaseOrderInfo.date && this.purchaseOrderInfo.date.length > 0) {
        return moment(this.purchaseOrderInfo.date).format('MM-DD-YYYY')
      }
      return null
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style>
  
</style>