import { make } from 'vuex-pathify'
import Job from '@/scripts/objects/job'

const getDefaultState = () => ({
  visible: false,
  loading: false,
  selectedShop: null,
  totalRows: 0,
  data: [],
  table: {
    page: 1,
    limit: 20,
    sortBy: 'id',
    sortDesc: true,
    numberOfRows: 0,
    filters: {
      id: '',
      customerName: '',
      customerPhone: '',
      vehicle: '',
      followupDate: '',
      quoteDate: '',
      amount: '',
      gbCsrId: ''
    }
  },
  timer: null
})

const state = getDefaultState()

const mutations = {
  ...make.mutations(state),
  RESET_STATE(state) {
    Object.assign(state, { ...getDefaultState(), visible: state.visible })
  },
  RESET_PAGE_AND_ROWS(state) {
    // Object.assign(state, {...state, table: (getDefaultState()).table})
    // state.table.page = 1
    // state.table.numberOfRows = 0
  },
  SET_PAGE(state, payload) {
    state.table.page = payload
  },
  SET_SORT_BY(state, payload) {
    state.table.sortBy = payload
  },
  SET_SORT_DESC(state, payload) {
    state.table.sortDesc = payload === 'descending'
  },
  RESET_DATA(state) {
    // Object.assign(state, {...state, table: (getDefaultState()).table})
    Object.assign(state, { ...state, data: [], totalRows: 0 })
    state.table.page = 1
    state.table.numberOfRows = 0
  },
  SET_FILTERS_id(state, payload) {
    state.filters.id = payload
  },
  SET_FILTERS_customerName(state, payload) {
    state.filters.customerName = payload
  },
  SET_FILTERS_customerPhone(state, payload) {
    state.filters.customerPhone = payload
  },
  SET_FILTERS_followupDate(state, payload) {
    state.filters.followupDate = payload
  },
  SET_FILTERS_quoteDate(state, payload) {
    state.filters.quoteDate = payload
  },
  SET_FILTERS_amount(state, payload) {
    state.filters.amount = payload
  },
  SET_FILTERS_gbCsrId(state, payload) {
    state.filters.gbCsrId = payload
  }
}
const getters = {}

const actions = {
  open({ commit }, shopDefault) {
    commit('SET_VISIBLE', true)
  },
  close({ commit }) {
    commit('SET_VISIBLE', true)
  },
  async changeShop({ commit, dispatch }, payload) {
    dispatch('resetState')
    commit('SET_SELECTED_SHOP', payload)
    dispatch('getData')
  },
  async changeSort({ commit, dispatch }, { prop, order }) {
    console.log('resetting state')
    // commit('RESET_DATA')
    commit('SET_SORT_BY', prop || 'id')
    commit('SET_SORT_DESC', order || '')
    dispatch('getData')
  },
  async updatedFilters({ commit, dispatch }) {
    if (state.timer) clearTimeout(state.timer)
    commit(
      'SET_TIMER',
      setTimeout(() => {
        console.log('dispatch get data')
        dispatch('getData')
      }, 700)
    )
  },
  async getData({ commit }) {
    if (state.selectedShop) {
      // commit('RESET_DATA')
      commit('RESET_DATA')
      commit('SET_LOADING', true)
      console.log(state.selectedShop)

      if (state.selectedShop.shop_services_config?.consumer) {
        // console.log('go get the data from the consumer service')
        const { data } = await this._vm.$unum
          .job()
          .post('jobslist/quotes-dialog/table', {
            shop: state.selectedShop,
            ...state.table
          })
        console.log(data)
        commit('SET_LOADING', false)
        commit('SET_DATA', data?.rows || [])
        commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
      } else {
        const { data } = await Job.getQuoteJobsAndDetails_v2({
          shop: state.selectedShop,
          ...state.table
        })
        commit('SET_LOADING', false)
        commit('SET_DATA', data?.rows || [])
        commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
      }
    }
  },
  async getDataNextSet({ commit }) {
    commit('SET_PAGE', state.table.page + 1)
    console.log('get more!')
    if (state.selectedShop) {
      if (state.selectedShop.shop_services_config?.consumer) {
        const { data } = await this._vm.$unum
          .job()
          .post('jobslist/quotes-dialog/table', {
            shop: state.selectedShop,
            ...state.table
          })
        console.log(data)
        commit('SET_LOADING', false)
        commit('SET_DATA', [...state.data, ...(data?.rows || [])])
        // commit('SET_TOTAL_ROWS', data?.numberOfRowsFound || 0)
      } else {
        const { data } = await Job.getQuoteJobsAndDetails_v2({
          shop: state.selectedShop,
          ...state.table
        })
        commit('SET_DATA', [...state.data, ...(data?.rows || [])])
      }
    }
  },
  resetState({ commit }) {
    commit('RESET_STATE')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
