import state from './availability.state'
import actions from './availability.actions'
import getters from './availability.getters'
import { make } from 'vuex-pathify'

const availabilityModule = {
  namespaced: true,
  state,
  getters,
  mutations: make.mutations(state),
  actions
}

export default availabilityModule
