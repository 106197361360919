import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class GlaxisInsurer extends ObjectTracker {
  id = -1
  insurer_id = ''
  name = ''
  enol = ''
  active = ''
  last_updated = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static reloadInsurerIds (successCallbackFunction, errorCallbackFunction) {
    Api.post(`/api/glaxis-insurer/reload`).then(res => {
      successCallbackFunction(res.data)
    }, err => {
      errorCallbackFunction(err)
    })
  }

  static getInsurerIds (
    successCallbackFunction,
    errorCallbackFunction
  ) {
    Api.get('/api/glaxis-insurers').then(res => {
      successCallbackFunction(res.data)
    }, err => {
      errorCallbackFunction(err)
    })
  }
}
