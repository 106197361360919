import * as R from 'ramda'

export const highlightMatch = term => value =>
  value
    ? `${value}`.replace(new RegExp(term, 'i'), match => `<u>${match}</u>`)
    : ''

export const matchEveryWord = term => value =>
  R.reduce((acc, v) => highlightMatch(v)(acc), value)(term.split(' '))

const insertWithCheck = (pos, char) =>
  R.when(R.pipe(R.length, R.lte(pos)), R.insert(pos - 1, char))

export const maskPhone = R.pipe(
  R.replace(/\D/g, ''),
  R.split(''),
  insertWithCheck(7, '-'),
  insertWithCheck(4, ') '),
  insertWithCheck(1, '('),
  R.join('')
)

const escapeRegex = v => v.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string

export const highlightPhone = term =>
  highlightMatch(escapeRegex(maskPhone(term)))
