var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "form",
      staticClass:
        "t-flex t-items-start t-flex-wrap t-px-6 t-py-3 t-border-0 t-border-t\n         t-border-solid t-border-gray-300",
      attrs: {
        action: "",
        limit: 10,
        multiple: true,
        "on-exceed": _vm.onExceed,
        "on-remove": _vm.onRemove,
        "on-change": _vm.onChange,
        "auto-upload": false,
        accept: "image/*,audio/*,video/*,text/*,application/pdf"
      }
    },
    [
      _c(
        "el-button",
        {
          staticClass: "t-mr-2",
          attrs: { slot: "trigger", circle: "" },
          slot: "trigger"
        },
        [_c("i", { staticClass: "fa fa-paperclip" })]
      ),
      _c(
        "el-button",
        {
          staticClass: "t-m-0",
          attrs: {
            disabled: _vm.totalAttachmentSize > 0,
            id: "emoji-button",
            circle: ""
          },
          on: {
            click: function($event) {
              _vm.emojiPicker = !_vm.emojiPicker
            }
          }
        },
        [_c("i", { staticClass: "fa fa-smile-o" })]
      ),
      _c(
        "div",
        { staticClass: "t-relative" },
        [
          _c("Picker", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.emojiPicker,
                expression: "emojiPicker"
              },
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.toggleEmojiPicker,
                expression: "toggleEmojiPicker"
              }
            ],
            style: {
              position: "absolute",
              bottom: "10px",
              left: "-60px",
              boxShadow: "5px 5px 10px 0 rgb(0, 0, 0, 0.08)",
              "z-index": "9999"
            },
            attrs: { title: "Pick your emoji…" },
            on: { select: _vm.addEmoji }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t-mx-3 t-flex-grow" },
        [
          _c("el-input", {
            ref: "body",
            attrs: {
              type: "textarea",
              autosize: { minRows: 2, maxRows: 3 },
              placeholder: _vm.bodyPlaceholder,
              disabled: _vm.totalAttachmentSize > 0,
              resize: "none"
            },
            on: {
              focus: function($event) {
                _vm.emojiPicker = false
              }
            },
            nativeOn: {
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  ) {
                    return null
                  }
                  $event.preventDefault()
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  if (!$event.ctrlKey) {
                    return null
                  }
                  if ($event.shiftKey || $event.altKey || $event.metaKey) {
                    return null
                  }
                  return _vm.newline($event)
                }
              ],
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                ) {
                  return null
                }
                return _vm.submit($event)
              }
            },
            model: {
              value: _vm.body,
              callback: function($$v) {
                _vm.body = $$v
              },
              expression: "body"
            }
          })
        ],
        1
      ),
      _c("el-button", {
        staticClass: "t-bg-blue-500 hover:t-bg-blue-400 t-border-0",
        attrs: {
          loading: _vm.submitting,
          type: "primary",
          icon: "fa fa-paper-plane",
          circle: ""
        },
        on: { click: _vm.submit }
      }),
      _c("div", { staticClass: "break-row" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }