import Vue from 'vue'
import * as R from 'ramda'
import { Message } from 'element-ui'

let instance

export const useRole = () => {
  if (instance) return instance

  instance = new Vue({
    computed: {
      roles() {
        return this?.$store?.state?.roles || []
      },
      accountManager() {
        return this?.$store?.state?.user?.account_manager
      },
      admin() {
        return this?.$store?.state?.user?.admin
      }
    },
    methods: {
      can(role, message) {
        if (role === 'account_manager') return !!this.accountManager
        if (role === 'admin') return !!this.admin
        const can = !!this.roles.find(
          R.anyPass([R.propEq('role_id')(role), R.propEq('name')(role)])
        )
        if (!can && message) {
          this.message()
        }
        return can
      },
      cannot(role, message) {
        const cannot = !this.roles.find(
          R.anyPass([R.propEq('role_id')(role), R.propEq('name')(role)])
        )
        if (cannot && message) {
          this.message()
        }
        return cannot
      },
      message(message = 'Permission denied') {
        Message.warning(message)
      }
    }
  })

  return instance
}

export default {
  install(Vue) {
    Vue.prototype.$role = useRole()
  }
}
