var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "50px",
        width: "1300px",
        visible: !!_vm.id,
        "before-close": _vm.handleClose,
        "custom-class": "item-profile-dialog"
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-flex t-items-center t-mt-[-4px] t-text-gray-600",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "t-text-lg el-icon-document" }),
          _c("div", { staticClass: "t-ml-3" }, [
            _vm._v(
              " " + _vm._s(_vm.isCatalog ? "Catalog" : "Item") + " Profile "
            )
          ]),
          _vm.item
            ? _c(
                "el-button",
                {
                  staticClass: "t-ml-4",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-edit",
                    type: "primary",
                    plain: "",
                    round: ""
                  },
                  on: { click: _vm.openUpdate }
                },
                [_vm._v(" Edit ")]
              )
            : _vm._e(),
          _vm.item && !_vm.item.unremovable && _vm.isCatalog
            ? _c(
                "el-button",
                {
                  staticClass: "t-ml-2",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-delete",
                    type: "danger",
                    plain: "",
                    round: ""
                  },
                  on: { click: _vm.deleteCatalog }
                },
                [_vm._v(" Delete ")]
              )
            : _vm._e(),
          _vm.jobId && !_vm.isCatalog
            ? _c(
                "el-button",
                {
                  staticClass: "t-ml-2",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "success",
                    plain: "",
                    round: "",
                    loading: _vm.partAdding
                  },
                  on: { click: _vm.addToPartList }
                },
                [_vm._v(" Add to part list ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.fetching
        ? _c("LoadingSpinner")
        : _c("div", { staticClass: "t-flex t-h-[700px]" }, [
            _c(
              "div",
              {
                staticClass:
                  "t-w-1/2 t-border-0 t-border-r t-border-solid t-border-gray-200 t-px-8 t-py-6"
              },
              [
                !_vm.isCatalog
                  ? _c("ItemInfo", { attrs: { item: _vm.item } })
                  : _c("CatalogInfo", { attrs: { item: _vm.item } })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "t-w-1/2 t-flex t-flex-col" },
              [
                !_vm.isCatalog
                  ? _c("InventorySection", {
                      attrs: { itemId: _vm.id, shopId: _vm.shopId }
                    })
                  : _vm._e(),
                _c("ParentsSection", {
                  attrs: { id: _vm.id, isCatalog: _vm.isCatalog },
                  on: { updated: _vm.refreshParents }
                })
              ],
              1
            )
          ]),
      _c("CreateUpdateDialog", {
        attrs: {
          visible: _vm.update.visible,
          id: _vm.id,
          isCatalog: _vm.isCatalog
        },
        on: { closed: _vm.onUpdateClosed, updated: _vm.onUpdated }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }