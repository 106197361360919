var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        visible: !!_vm.url,
        "before-close": _vm.handleClose,
        top: "50px"
      }
    },
    [
      _c("div", { staticClass: "t-flex t-justify-center" }, [
        _c("img", {
          staticClass: "t-max-h-[700px] t-max-w-full t-object-contain",
          attrs: { src: _vm.url }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }