var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticClass: "t-mb-4",
      attrs: {
        border: "",
        "header-cell-class-name":
          "t-bg-gray-100 t-text-gray-700 t-font-medium t-break-normal",
        data: _vm.lines
      }
    },
    [
      _c("el-table-column", {
        attrs: { label: "#", width: "50", prop: "purchaseOrderLineNumber" }
      }),
      _c("el-table-column", {
        attrs: { label: "Item & Description" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("div", { staticClass: "t-break-normal" }, [
                  _c("div", { staticClass: "t-font-medium" }, [
                    _vm._v(_vm._s(scope.row.itemName))
                  ]),
                  _c(
                    "div",
                    { staticClass: "t-text-xs t-italic t-text-gray-400" },
                    [_vm._v(" " + _vm._s(scope.row.itemDescription) + " ")]
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Notes" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_vm._v(" " + _vm._s(scope.row.notes) + " ")]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Job #", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                scope.row.jobs !== null
                  ? _c(
                      "div",
                      _vm._l(scope.row.jobs, function(i) {
                        return _c(
                          "div",
                          { key: i.id, staticClass: "t-flex t-items-center" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "Release to inventory",
                                  placement: "top"
                                }
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title:
                                        "Do you want to release the item back to inventory ?",
                                      "confirm-button-text": "Yes",
                                      "cancel-button-text": "No"
                                    },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.release({
                                          lineNumber:
                                            scope.row.purchaseOrderLineNumber,
                                          jobId: i.id
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-circle-close t-mr-1 t-text-red-400 t-cursor-pointer",
                                      attrs: { slot: "reference" },
                                      slot: "reference"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: "/jobs/" + i.id
                                }
                              },
                              [_vm._v(_vm._s(i.id))]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "REC #", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "t-flex t-flex-col t-items-start t-space-y-2"
                  },
                  _vm._l(scope.row.receives, function(receive, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "t-px-2 t-rounded t-opacity-80 t-text-white t-cursor-pointer t-bg-red-500",
                        on: {
                          click: function($event) {
                            return _vm.openReceive(receive.id)
                          }
                        }
                      },
                      [_vm._v(" REC " + _vm._s(receive.internalId) + " ")]
                    )
                  }),
                  0
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "TPU #", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "t-flex t-flex-col t-items-start t-space-y-2"
                  },
                  _vm._l(scope.row.techPickUps, function(tpu, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "t-px-2 t-rounded t-opacity-80 t-text-white t-cursor-pointer t-bg-green-400",
                        on: {
                          click: function($event) {
                            return _vm.openTechPickUp(tpu.id)
                          }
                        }
                      },
                      [_vm._v(" TPU " + _vm._s(tpu.internalId) + " ")]
                    )
                  }),
                  0
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "RTN #", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "t-flex t-flex-col t-items-start t-space-y-2"
                  },
                  _vm._l(scope.row.returns, function(ret, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "t-px-2 t-rounded t-opacity-80 t-text-white t-cursor-pointer t-bg-orange-400",
                        on: {
                          click: function($event) {
                            return _vm.openReturn(ret.id)
                          }
                        }
                      },
                      [_vm._v(" RTN " + _vm._s(ret.internalId) + " ")]
                    )
                  }),
                  0
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Qty", width: "80", prop: "quantity" }
      }),
      _c("el-table-column", {
        attrs: { label: "Cost", width: "80" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_vm._v(" $" + _vm._s(scope.row.cost) + " ")]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Total", width: "80" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_vm._v(" $" + _vm._s(_vm.total(scope.row)) + " ")]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }