export const calendarColors = {
  limit: '#2196F3',
  block: '#FF5722'
}

export const repeatOptions = {
  none: {
    value: null,
    label: 'Does not repeat',
    config: null
  },
  weekdays: {
    value: 'weekdays',
    label: 'Monday through Friday',
    config: { weekdays: [1, 2, 3, 4, 5] }
  },
  weekly: {
    value: 'weekly',
    label: 'Every week',
    config: { every: 'week' }
  },
  monthly: {
    value: 'monthly',
    label: 'Every month',
    config: { every: 'month' }
  },
  yearly: {
    value: 'yearly',
    label: 'Every year',
    config: { every: 'year' }
  }
}

export const weekDays = [
  { label: 'Sunday', key: 0 },
  { label: 'Monday', key: 1 },
  { label: 'Tuesday', key: 2 },
  { label: 'Wednesday', key: 3 },
  { label: 'Thursday', key: 4 },
  { label: 'Friday', key: 5 },
  { label: 'Saturday', key: 6 }
]
