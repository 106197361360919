var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vendor-sales-rep-settings" }, [
    _c(
      "div",
      [
        _c("el-divider", { staticClass: "t-my-6" }),
        _c(
          "el-checkbox",
          {
            on: { change: _vm.updateHideSentSSMJobsHandler },
            model: {
              value: _vm.hide_sent_ssm_jobs,
              callback: function($$v) {
                _vm.hide_sent_ssm_jobs = $$v
              },
              expression: "hide_sent_ssm_jobs"
            }
          },
          [_vm._v(" Hide sent ssm jobs ")]
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("el-divider", { staticClass: "t-my-6" }),
        _c(
          "el-checkbox",
          {
            on: { change: _vm.updateSalesidejobStartOnSendHandler },
            model: {
              value: _vm.salesidejob_start_on_send,
              callback: function($$v) {
                _vm.salesidejob_start_on_send = $$v
              },
              expression: "salesidejob_start_on_send"
            }
          },
          [_vm._v(" Automatically start SSM jobs when sent ")]
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("el-divider", { staticClass: "t-my-6" }),
        _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
          _vm._v("SSM Allowed Shops")
        ]),
        _c(
          "el-select",
          {
            attrs: { multiple: "", placeholder: "Select SSM Allowed Shops" },
            on: { change: _vm.updateSSMAllowedShopsHandler },
            model: {
              value: _vm.ssm_allowed_shop_ids,
              callback: function($$v) {
                _vm.ssm_allowed_shop_ids = $$v
              },
              expression: "ssm_allowed_shop_ids"
            }
          },
          _vm._l(_vm.shops, function(i) {
            return _c("el-option", {
              key: i.id,
              attrs: { label: i.name, value: i.id }
            })
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("el-divider", { staticClass: "t-my-6" }),
        _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
          _vm._v("Default Location")
        ]),
        _c(
          "el-select",
          {
            attrs: {
              clearable: "",
              filterable: "",
              placeholder: "Select Default Location"
            },
            on: { change: _vm.updateDefaultLocationHandler },
            model: {
              value: _vm.default_location_id,
              callback: function($$v) {
                _vm.default_location_id = $$v
              },
              expression: "default_location_id"
            }
          },
          _vm._l(_vm.locations, function(i) {
            return _c("el-option", {
              key: i.id,
              attrs: {
                label: i.name,
                value: i.id,
                disabled: i.is_disabled === 1
              }
            })
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("el-divider", { staticClass: "t-my-6" }),
        _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
          _vm._v("Locations")
        ]),
        _c("el-transfer", {
          attrs: {
            filterable: "",
            "filter-placeholder": "Filter by name",
            data: _vm.locationsForMultiselect,
            titles: ["Available", "Connected"]
          },
          on: { change: _vm.updateLocationsHandler },
          model: {
            value: _vm.location_ids,
            callback: function($$v) {
              _vm.location_ids = $$v
            },
            expression: "location_ids"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("el-divider", { staticClass: "t-my-6" }),
        _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
          _vm._v("Commercial Accounts")
        ]),
        _c("el-transfer", {
          attrs: {
            filterable: "",
            "filter-placeholder": "Filter by name",
            data: _vm.commercialAccountsForMultiselect,
            titles: ["Available", "Connected"]
          },
          on: { change: _vm.updateCommercialAccountsHandler },
          model: {
            value: _vm.commercial_account_ids,
            callback: function($$v) {
              _vm.commercial_account_ids = $$v
            },
            expression: "commercial_account_ids"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("el-divider", { staticClass: "t-my-6" }),
        _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
          _vm._v("Salesources")
        ]),
        _c("el-transfer", {
          attrs: {
            filterable: "",
            "filter-placeholder": "Filter by name",
            data: _vm.saleSourcesForMultiselect,
            titles: ["Available", "Connected"]
          },
          on: { change: _vm.updateSaleSourcesHandler },
          model: {
            value: _vm.sale_source_ids,
            callback: function($$v) {
              _vm.sale_source_ids = $$v
            },
            expression: "sale_source_ids"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }