<template>
  <el-dialog
    append-to-body
    width="1400px"
    :visible="visible"
    :before-close="handleClose"
    custom-class="chat-dialog"
  >
    <span slot="title" class="t-text-blue-100 t-text-lg">
      <i class="el-icon-bank-card"></i>
      <span class="t-ml-3">Add payment</span>
    </span>
    <div class="t-h-full t-flex t-flex-col">
      <div class="t-flex-grow t-p-6 t-overflow-y-auto">
        <div class="t-flex t-justify-between">
          <el-radio-group
            v-model="consumerId"
            v-loading="loading"
            class="t-mb-4"
          >
            <el-radio
              v-for="c in consumers"
              :key="c.consumer_id"
              :label="c.consumer_id"
              border
              class="t-flex t-ml-0 t-mb-3 t-w-full"
            >
              <div
                class="t-flex t-text-gray-700 t-font-normal t-items-center t-w-120"
              >
                <div class="t-flex t-ml-4 t-flex-grow">
                  <i
                    class="t-mr-1 t-text-base t-text-gray-600"
                    :class="
                      c.is_insurance
                        ? 'el-icon-office-building'
                        : 'el-icon-user'
                    "
                  />
                  <div class="t-truncate">
                    {{
                      c.is_insurance || c.is_commercial
                        ? c.company_name
                        : c.first_name + " " + c.last_name
                    }}
                  </div>
                </div>
                <div class="t-flex t-mr-8">
                  <i class="el-icon-document-copy t-mr-1" />
                  <div>{{ c.invoices_count }}</div>
                </div>
                <div class="t-flex">
                  <div class="t-mr-1">$</div>
                  <div>{{ formatAmount(c.remaining_balance) }}</div>
                </div>
              </div>
            </el-radio>
          </el-radio-group>
          <el-checkbox
            v-if="selectedConsumer && selectedConsumer.trading_partner"
            v-model="showRelatedTroughTradingPartner"
            :label="'Show all with ' + selectedConsumer.trading_partner"
            border
            class="t-normal-case t-bg-white t-font-normal"
          />
        </div>
        <div v-if="selectedInvoices.length" class="t-pb-4">
          <div class="t-mb-2 t-text-base t-flex t-justify-between">
            <div>Selected invoices:</div>
            <div>Total:
              <span class="t-text-gray-700 t-font-medium t-ml-2">
                ${{ total }}
              </span>
            </div>
          </div>
          <div
            v-for="(invoice, index) in selectedInvoices"
            :key="invoice.job_id"
            class="t-flex t-border t-border-solid t-border-gray-300 t-rounded
                   t-px-6 t-py-1 t-bg-gray-100 t-items-center t-mb-1"
          >
            <div class="t-w-48">
              <div class="t-text-xs t-text-gray-500">Job id</div>
              <div>{{ invoice.job_id }}</div>
            </div>
            <template v-if="invoice.type === 'insurance'">
              <div class="t-flex-grow">
                <div class="t-text-xs t-text-gray-500">Company name</div>
                <div>{{ invoice.main_company_name }}</div>
              </div>
              <div class="t-w-64">
                <div
                  v-if="invoice.secondary_customer_name"
                  class="t-text-xs t-text-gray-500"
                >
                  Customer name
                </div>
                <div>{{ invoice.secondary_customer_name }}</div>
              </div>
            </template>
            <template v-else>
              <div class="t-w-64">
                <div class="t-text-xs t-text-gray-500">Customer name</div>
                <div>{{ invoice.main_customer_name }}</div>
              </div>
              <div class="t-flex-grow">
                <div
                  v-if="invoice.secondary_company_name"
                  class="t-text-xs t-text-gray-500"
                >
                  Company name
                </div>
                <div>{{ invoice.secondary_company_name }}</div>
              </div>
            </template>
            <div class="t-w-48">
              <div class="t-text-xs t-text-gray-500">Total</div>
              <div>${{ formatAmount(invoice.total_balance) }}</div>
            </div>
            <div class="t-w-48">
              <div class="t-text-xs t-text-gray-500">Balance</div>
              <div>${{ formatAmount(invoice.remaining_balance) }}</div>
            </div>
            <div class="t-w-48">
              <div class="t-text-xs t-text-gray-500">Amount</div>
              <el-input
                type="number"
                v-model="selectedInvoices[index].amount"
                size="mini"
                placeholder="Amount"
              />
            </div>
          </div>
        </div>
        <InvoicesTable
          :fetching="fetching"
          :invoices="filteredInvoices"
          :selectedInvoices="selectedInvoices"
          :consumer="selectedConsumer"
          :jobIdFilter.sync="jobIdFilter"
          :referralFilter.sync="referralFilter"
          :poFilter.sync="poFilter"
          :customerNameFilter.sync="customerNameFilter"
          @checkboxChanged="checkboxChanged($event)"
          ref="table"
          class="t-mt-4"
        />
      </div>
      <div
        v-if="selectedInvoices.length"
        class="t-text-right t-px-6 t-py-3 t-bg-gray-200"
      >
        <el-button
          @click="addPaymentIsOpen = true"
          type="primary"
          icon="el-icon-plus"
          size="small"
        >
          Add payment
        </el-button>
      </div>
      <AddPayment
        :visible="addPaymentIsOpen"
        :invoices="selectedInvoices"
        :shopId="shopId"
        :consumer="selectedConsumer"
        @close="addPaymentIsOpen = false"
        @added="paymentAdded"
      />
    </div>
  </el-dialog>
</template>

<script>
import InvoicesTable from './InvoicesTable'
import AddPayment from './AddPayment'
import { consumerRepository } from '@/scripts/repositories/consumer'

export default {
  props: {
    visible: Boolean,
    shopId: Number,
    consumerIds: Array,
    selectedConsumerId: Number,
    jobId: Number,
    fromJob: Boolean
  },
  data: () => ({
    loading: true,
    consumers: [],
    consumerId: null,
    fetching: false,
    invoices: [],
    filteredInvoices: [],
    selectedInvoices: [],
    addPaymentIsOpen: false,
    showRelatedTroughTradingPartner: false,
    jobIdFilter: null,
    referralFilter: null,
    poFilter: null,
    customerNameFilter: null
  }),
  computed: {
    selectedConsumer: function() {
      return this.consumers.find(
        i => i.consumer_id === this.consumerId
      )
    },
    total: function() {
      let total = 0
      for (const i of this.selectedInvoices) {
        total += parseFloat(i.amount)
      }
      return this.formatAmount(total)
    }
  },
  watch: {
    visible: async function(value) {
      if (value) {
        this.consumerId = this.selectedConsumerId
        try {
          this.consumers = await consumerRepository.getByIds({
            shopId: this.shopId,
            consumerIds: this.consumerIds,
            jobId: this.jobId,
            fromJob: this.fromJob
          })
          this.loading = false
        } catch {
          this.loading = false
        }
      } else {
        this.consumers = []
        this.consumerId = null
        this.invoices = []
        this.filteredInvoices = []
        this.selectedInvoices = []
        this.jobIdFilter = ''
        this.showRelatedTroughTradingPartner = false
      }
    },
    selectedConsumer: function(value) {
      if (value) {
        this.getConsumerInvoices()
      }
    },
    showRelatedTroughTradingPartner: function() {
      if (this.selectedConsumer) {
        this.getConsumerInvoices()
      }
    },
    jobIdFilter: function(val) {
      const selectedInvoiceIds = this.selectedInvoices.map(i => i.job_id)
      this.filteredInvoices = this.invoices.filter(i => {
        return selectedInvoiceIds.includes(i.job_id) ||
          i.job_id.toString().includes(val)
      })
    },
    referralFilter: function(val) {
      const selectedInvoiceIds = this.selectedInvoices.map(i => i.job_id)
      this.filteredInvoices = this.invoices.filter(i => {
        return selectedInvoiceIds.includes(i.job_id) ||
          i.referral_number?.toString().includes(val)
      })
    },
    poFilter: function(val) {
      const selectedInvoiceIds = this.selectedInvoices.map(i => i.job_id)
      this.filteredInvoices = this.invoices.filter(i => {
        return selectedInvoiceIds.includes(i.job_id) ||
          i.purchase_order_number?.toString().includes(val) ||
          i.requisition_order_number?.toString().includes(val)
      })
    },
    customerNameFilter: function(val) {
      const selectedInvoiceIds = this.selectedInvoices.map(i => i.job_id)
      this.filteredInvoices = this.invoices.filter(i => {
        return selectedInvoiceIds.includes(i.job_id) ||
          !val ||
          i.secondary_customer_name?.toString().toLowerCase().includes(val.toLowerCase())
      })
    }
  },
  methods: {
    handleClose: function() {
      this.$emit('close')
    },
    formatAmount: amount => Math.round(amount * 100) / 100,
    getConsumerInvoices: async function() {
      try {
        this.fetching = true
        this.jobIdFilter = null
        this.referralFilter = null
        this.poFilter = null
        this.customerNameFilter = null
        this.selectedInvoices = []
        this.invoices = await consumerRepository.getAllInvoices({
          shopId: this.shopId,
          consumerId: this.consumerId,
          tradingPartner: this.selectedConsumer.trading_partner,
          showRelatedTroughTradingPartner: this.showRelatedTroughTradingPartner,
          jobId: this.jobId,
          fromJob: this.fromJob
        })
        this.filteredInvoices = this.invoices
        if (this.jobId) {
          this.checkboxChanged({ jobId: this.jobId, value: true })
        }
        this.fetching = false
      } catch {
        this.fetching = false
      }
    },
    paymentAdded: function() {
      this.addPaymentIsOpen = false
      this.$emit('paymentAdded')
    },
    checkboxChanged: function({ jobId, value }) {
      if (value) {
        const invoice = this.invoices.find(i => i.job_id === jobId)
        this.selectedInvoices.push({
          ...invoice,
          amount: this.formatAmount(invoice.remaining_balance)
        })
      } else {
        this.selectedInvoices = this.selectedInvoices.filter(i => {
          return i.job_id !== jobId
        })
      }
    }
  },
  components: {
    InvoicesTable,
    AddPayment
  }
}
</script>
