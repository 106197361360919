var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.imagesLoaded !== _vm.totalImages,
          expression: "imagesLoaded !== totalImages"
        }
      ],
      staticClass: "t-flex-grow t-flex t-justify-end t-overflow-y-hidden"
    },
    [
      _vm.chatIsOpen
        ? _c(
            "div",
            {
              ref: "messages",
              staticClass:
                "t-flex-grow t-overflow-y-auto t-py-2 t-space-y-2 t-flex\n           t-flex-col",
              on: { scroll: _vm.handleScroll }
            },
            [
              _c(
                "infinite-loading",
                {
                  ref: "infiniteLoader",
                  staticClass: "t-pb-3",
                  attrs: { direction: "top", spinner: "spiral" },
                  on: { infinite: _vm.load }
                },
                [
                  _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }, [
                    _c(
                      "div",
                      { staticClass: "t-text-left t-mb-3 t-mt-8 t-px-4" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-text-xl t-font-medium t-mb-3 t-text-gray-800"
                          },
                          [
                            _vm.activeChannel.friendlyName === "direct"
                              ? _c("i", { staticClass: "el-icon-user" })
                              : _vm.activeChannel.isPrivate
                              ? _c("i", { staticClass: "el-icon-lock" })
                              : _c("span", [_vm._v("#")]),
                            _c("span", { staticClass: "t-ml-1" }, [
                              _vm._v(_vm._s(_vm.channelName))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-text-base t-text-gray-700 t-break-normal"
                          },
                          [
                            _vm.activeChannel.friendlyName === "direct"
                              ? _c("div", [
                                  _vm.channelName === _vm.username
                                    ? _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "t-font-medium" },
                                          [_vm._v("This is your space.")]
                                        ),
                                        _vm._v(
                                          " Draft messages, list your to-dos, or keep links and files handy. "
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          " This is the very beginning of your direct message history with "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "t-font-medium" },
                                          [_vm._v(_vm._s(_vm.channelName))]
                                        ),
                                        _vm._v(
                                          ". Only the two of you are in this conversation, and no one else can join it. "
                                        )
                                      ])
                                ])
                              : _c("div", [
                                  _vm._v(" User "),
                                  _c("span", { staticClass: "t-font-medium" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.activeChannel.createdBy) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " created this " +
                                      _vm._s(
                                        _vm.activeChannel.isPrivate
                                          ? "private"
                                          : "public"
                                      ) +
                                      " channel on " +
                                      _vm._s(_vm.channelCreationDate) +
                                      ". This is the very beginning of the "
                                  ),
                                  _c("span", { staticClass: "t-font-medium" }, [
                                    _vm.activeChannel.isPrivate
                                      ? _c("i", { staticClass: "el-icon-lock" })
                                      : _c("span", [_vm._v("#")]),
                                    _c("span", { staticClass: "t-ml-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.activeChannel.uniqueName)
                                      )
                                    ])
                                  ]),
                                  _vm._v(" channel. ")
                                ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              ),
              _vm._l(_vm.messages, function(item, index) {
                return _c(
                  "div",
                  { key: item.sid },
                  [
                    !index
                      ? _c("MessageDate", {
                          attrs: {
                            date: _vm.formatMessageDate(item.state.timestamp)
                          }
                        })
                      : _vm.getMessageDate(index)
                      ? _c("MessageDate", {
                          attrs: { date: _vm.getMessageDate(index) }
                        })
                      : _vm._e(),
                    _c("Message", {
                      attrs: { message: item },
                      on: {
                        scrollDown: function($event) {
                          return _vm.scrollDownByPixels($event)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "t-italic t-text-xs" }, [
                _c(
                  "div",
                  {
                    staticClass: "t-mt-1 t-pl-4",
                    class: { "t-hidden": _vm.typingMembers.length === 0 }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.typingMembers.join(", ")))]),
                    _c("span", { staticClass: "t-ml-1" }, [
                      _vm._v(
                        _vm._s(_vm.typingMembers.length > 1 ? "are" : "is")
                      )
                    ]),
                    _c("span", { staticClass: "t-ml-1" }, [_vm._v("typing")])
                  ]
                )
              ])
            ],
            2
          )
        : _vm._e(),
      _vm.scrollButtonIsVisible
        ? _c(
            "div",
            {
              staticClass:
                "t-absolute t-flex t-justify-center t-items-center t-text-blue-500\n           t-text-lg t-cursor-pointer t-rounded-full t-border t-border-solid\n           t-border-blue-500 t-w-10 t-h-10 t-bg-white hover:t-bg-blue-200\n           t-shadow",
              attrs: { id: "scroll-to-chat-bottom-button" },
              on: { click: _vm.scrollDown }
            },
            [_c("i", { staticClass: "el-icon-arrow-down" })]
          )
        : _vm._e(),
      _c("ViewImageDialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }