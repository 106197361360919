<template>
  <div>
    <div class="t-flex t-items-center t-my-6">
      <div
        class="t-flex t-flex-grow t-space-x-6 t-items-center t-cursor-pointer"
        @click="$emit('preview')"
      >
        <div
          v-if="isImage"
          class="t-flex t-items-center t-justify-center t-h-10 t-w-10
              t-rounded-md t-bg-orange-100 t-text-orange-600"
        >
          <i class="material-icons-outline md-image t-text-2xl t-font-light" />
        </div>
        <div
          v-else-if="isVideo"
          class="t-flex t-items-center t-justify-center t-h-10 t-w-10
              t-rounded-md t-bg-red-100 t-text-red-600"
        >
          <i class="material-icons-outline md-videocam t-text-2xl t-font-light" />
        </div>
        <div
          v-else
          class="t-flex t-items-center t-justify-center t-h-10 t-w-10
              t-rounded-md t-bg-blue-100 t-text-blue-600"
        >
          <i class="material-icons-outline md-assignment t-text-2xl t-font-light" />
        </div>

        <div class="t-flex t-flex-grow">
          <div class="t-flex t-flex-col t-flex-grow">
            <div class="t-text-gray-700">{{ document.document_name }}</div>
            <div class="t-text-gray-300">
              {{ listDate }}
              <span v-if="document.created_by_name">
                by {{ document.created_by_name }}
              </span>
            </div>
          </div>

          <div class="t-flex t-items-center t-flex-shrink-0 t-w-48">
            <div
              class="t-w-2 t-h-2 t-rounded-full"
              :style="`background: ${document.document_tag_color}`"
            />
            <div class="t-ml-2">{{ document.document_tag_name }},</div>
            <div class="t-pl-2">{{ size }}</div>
          </div>
        </div>
      </div>

      <el-dropdown class="t-shrink-0" trigger="click">
        <i
          class="material-icons-outline md-menu el-dropdown-link t-mx-3
            t-text-base t-text-gray-500 t-cursor-pointer"
        />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div
              @click="openInNewTab"
              class="t-flex t-items-center"
            >
              <i class="material-icons-outline md-dynamic_feed t-text-gray-400 t-text-base" />
              <div class="t-ml-2">Open in new tab</div>
            </div>
          </el-dropdown-item>

          <el-dropdown-item>
            <div
              @click="download"
              class="t-flex t-items-center"
            >
              <i class="material-icons-outline md-cloud_download t-text-blue-400 t-text-base" />
              <div class="t-ml-2">Download</div>
            </div>
          </el-dropdown-item>

          <el-dropdown-item v-if="isImage">
            <div
              @click="$emit('createAvatar')"
              class="t-flex t-items-center"
            >
              <i class="material-icons-outline md-account_circle t-text-orange-400 t-text-base" />
              <div class="t-ml-2">Create avatar</div>
            </div>
          </el-dropdown-item>

          <el-dropdown-item>
            <div
              @click="remove"
              class="t-flex t-items-center"
            >
              <i class="material-icons-outline md-delete t-text-red-400 t-text-base" />
              <div class="t-ml-2">Delete</div>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-divider />
  </div>
</template>

<script>
import moment from 'moment'
import prettyBytes from 'pretty-bytes'
import axios from 'axios'
import { mapActions } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  props: {
    document: {
      type: Object,
      required: true
    }
  },

  computed: {
    isImage() {
      return this.document.type.includes('image')
    },

    isVideo() {
      return this.document.type.includes('video')
    },

    listDate() {
      return moment(this.document.created_at).format('MM-DD-YYYY')
    },

    size() {
      return prettyBytes(this.document.size)
    }
  },

  methods: {
    ...mapActions(useVendorStore, ['deleteDocument']),

    openInNewTab() {
      window.open(this.document.url, '_blank')
    },

    async download() {
      const res = await axios.get(this.document.url, { responseType: 'blob' })
      const blob = new Blob([res.data], { type: this.document.type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = this.document.document_name
      link.click()
      URL.revokeObjectURL(link.href)
    },

    async remove() {
      try {
        await this.deleteDocument(this.document.id)
        this.$message.success('Document deleted successfully')
      } catch (err) {
        this.$message.error(err)
      }
    }
  }
}
</script>
