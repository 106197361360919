<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      @open="dialogAboutToAppear"
      title="Glaxis Inbox"
      :appendToBody="true"
      id="glaxisInbox"
      width="1400px"
      destroy-on-close
    >
      <div class="row mb-2">
        <div class="col-sm-8">
          <a :class="{ 'glaxisTabButton': true, 'selected': selectedTab === 'claims' }" @click="claimsTabClicked">Claims</a>&nbsp;&nbsp;
          <a :class="{ 'glaxisTabButton': true, 'selected': selectedTab === 'assignments' }" @click="assignmentsTabClicked">Work Assignments</a>&nbsp;&nbsp;
          <a :class="{ 'glaxisTabButton': true, 'selected': selectedTab === 'authorizations' }" @click="authorizationsTabClicked">Authorizations</a>&nbsp;&nbsp;
          <a :class="{ 'glaxisTabButton': true, 'selected': selectedTab === 'payments' }" @click="paymentsTabClicked">Payments</a>&nbsp;&nbsp;
        </div>
        <div class="col-sm-4 ">
          <!-- <a class="glaxisTabButton pull-right" @click="reloadInsurerIds">Reload Insurer Ids</a> -->
          <a class="glaxisTabButton pull-right" @click="refreshTableClicked"><font-awesome-icon :icon='faSync'/> Refresh</a>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-12 col-md-8 col-xl-5 col-lg-5">
          <label for="#">Shops</label>
          <Multiselect
            placeholder="Select Shop..."
            :options="$store.state.shops"
            label="name"
            :show-labels="false"
            v-model="selectedShop"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="row" style="margin:0px;">
                <div class="col-11" style="font-size: 14px; text-overflow: ellipsis;overflow: hidden;padding:0px;">
                  {{ props.option.name }}
                </div>
                <div class="float-right col-1" style="padding:0px;">
                  <span v-show="getShopCountForDropdown (props.option.id) != '0'" class="count-for-shops-dropdown">
                    {{ getShopCountForDropdown(props.option.id) }}
                  </span>
                </div>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="row" style="margin:0px;">
                <div class="col-11" style="font-size: 14px; text-overflow: ellipsis;overflow: hidden;padding:0px;">
                  {{ props.option.name }}
                </div>
                <div class="float-right col-1" style="padding:0px;">
                  <span v-show="getShopCountForDropdown (props.option.id) != '0'" class="count-for-shops-dropdown">
                    {{ getShopCountForDropdown(props.option.id) }}
                  </span>
                </div>
              </div>
            </template>
          </Multiselect>
        </div>
        <div v-if="selectedTab === 'assignments'" class="col-sm-12 col-md-4 col-xl-7 col-lg-7" style="margin-top: 30px;">
          <a class="tag ttFilter text-white pointer" style="background-color: gray" @click="resetTableVariables()">
            New assignments: {{ counts.new }}
          </a>
          <a class="tag ttFilter text-white pointer" style="background-color: red" @click="filerByDay('today')">
            Today: {{ counts.today }}
          </a>
          <a class="tag ttFilter text-white pointer" style="background-color: orange" @click="filerByDay('tomorrow')">
            Tomorrow: {{ counts.tomorrow }}
          </a>
          <a class="glaxisActionButton" @click="checkGlaxis">Check Glaxis</a>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6 col-xl-2 col-lg-3">
          <CheckBoxLabel label="Show Archive?" :value.sync="showArchive"/>
        </div>
      </div>

      <div class="tableHolder row" style="color: black">
        <div class="form-group col-12">
          <b-table
            ref="glaxisDialogTable"
            stacked="md"
            :items="myProvider"
            :fields="tableFields"
            :current-page="links.page"
            :per-page="links.limit"
            :sort-by.sync="sort.field"
            :sort-desc.sync="sort.order"
            :busy.sync="loading"
            :show-empty="true"
            :empty-text="loading ? 'Loading, please wait...' : 'There are no records to show'"
            :empty-filtered-text="loading ? 'Loading, please wait...' : 'There are no records matching your request'"
            class="text-left p-0"
          >
            <template slot="HEAD_id" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.id"
                placeholder="ID #"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_internalId" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.internalId"
                placeholder="ID #"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_jobId" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.jobId"
                placeholder="Job ID #"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_dateSubmitted" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.dateSubmitted"
                placeholder="Date Submitted"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_referenceNumber" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.referenceNumber"
                placeholder="Reference #"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_contactNumber" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.contactNumber"
                placeholder="Contact #"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_response" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.response"
                placeholder="Response"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_status" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.status"
                placeholder="Status"
                class="form-control form-control-sm"
              />
            </template>

            <template slot="HEAD_referralNumber" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.referralNumber"
                placeholder="Referral #"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_lastName" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.lastName"
                placeholder="Last Name"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_firstName" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.firstName"
                placeholder="First Name"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_phone" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.phone"
                placeholder="Phone"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_jobNumber" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.jobNumber"
                placeholder="Job #"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_yearMakeModel" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.yearMakeModel"
                placeholder="Year Make Model"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="HEAD_scheduledDate" slot-scope="row">
              <input
                :data-id="row.id"
                v-on:click.stop
                v-on:keydown.space.stop
                type="text"
                v-model="filters.scheduledDate"
                placeholder="Scheduled Date"
                class="form-control form-control-sm"
              />
            </template>
            <template slot="phone" slot-scope="row">
              <span>
                ({{ row.item.phone.substr(0, 3) }}) {{ row.item.phone.substr(3, 3) }}-{{ row.item.phone.substr(6) }}
              </span>
            </template>
            <template slot="jobId" slot-scope="row">
              <span>
                <a
                  v-show="row.item.jobId !== null"
                  :href="'/jobs/' + row.item.jobId"
                  target='_blank'
                >
                  {{ row.item.jobId }}
                </a>
              </span>
            </template>
            <template slot="jobNumber" slot-scope="row">
              <span>
                <a
                  v-show="row.item.jobNumber !== null"
                  :href="'/jobs/' + row.item.jobNumber"
                  target='_blank'
                >
                  {{ row.item.jobNumber }}
                </a>
              </span>
            </template>
            <template slot="yearMakeModel" slot-scope="row">
              <span>
                {{ row.item.yearMakeModel.toLowerCase().split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') }}
              </span>
            </template>
            <template v-if="!loading" slot="actions" slot-scope="row">
              <a
                v-show="row.item.archived !== 1 && row.item.jobNumber === null"
                @click="handleStart(row.item.id)"
                class="label label-gray label-sm"
              >
                Start
              </a>
              <a @click="viewWorkAssignment(row.item.id)" class="label label-gray label-sm">View</a>
              <a
                v-show="row.item.archived !== 1"
                @click="handleArchive(row.item)"
                class="label label-gray label-sm"
              >
                Archive
              </a>
            </template>
            <template slot="status" slot-scope="row">
              <span :style="{'color':row.item.status == 'Success' ? 'green' : row.item.status == 'Error' ? 'red' : 'orange'}">{{row.item.status}}</span>
            </template>
            <template slot="response" slot-scope="row">
              <span :style="{'color':row.item.status == 'Success' ? 'green' : row.item.status == 'Error' ? 'red' : 'orange'}">{{row.item.response}}</span>
            </template>
            <template slot="claimActions" slot-scope="row">
              <a v-if="row.item.jobId && row.item.jobId > 0" class="label label-gray label-sm" @click="openLossNotice(row.item.jobId)">Open Claim</a>
              <a @click="handleClaimArchive(row.item.id, row.item.archived !== 1)" class="label label-gray label-sm" >{{row.item.archived === 1 ? 'Unarchive' : 'Archive'}}</a>
            </template>
          </b-table>
        </div>
      </div>

      <b-row>
        <b-col md="6">
          <span>Showing {{ numberOfRecords }} assignments</span>
        </b-col>
        <b-col md="6">
          <b-pagination
            :total-rows="links.total"
            :per-page="links.limit"
            v-model="links.page"
            align="right"
          />
        </b-col>
      </b-row>
    </el-dialog>
    <GlaxisWorkAssignmentDialog :id="workAssignmentId" :skipTableReset.sync="skipTableReset"/>
    <el-dialog
      :visible.sync='confirmArchiveChangePopup'
      :appendToBody="true"
      >
      <el-row class='dialog-header-text'>
          {{archiveClaim ? 'Archive' : 'Unarchive' }}?
      </el-row>
      <br/>
      <el-row class='dialog-body-text'>Are you sure you want to  {{archiveClaim ? 'archive' : 'unarchive' }} this Claim?</el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="finishHandleClaimArchive()">Yes</el-button>
        <el-button @click="confirmArchiveChangePopup = false">No</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync='alertPopup'
      :appendToBody="true"
      >
      <el-row class='dialog-body-text'>{{ alertPopupText }}</el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="alertPopup = false">Ok</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import GlaxisWorkAssignment from '@/scripts/objects/glaxis_work_assignment'
import GlaxisWorkAssignmentDialog from './workAssignments/Index.vue'
import GlaxisClaim from '@/scripts/objects/glaxis_claim'
import GlaxisInsurer from '@/scripts/objects/glaxis_insurer'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import CheckBoxLabel from '@/components/Helpers/CheckBoxLabel'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faSync from '@fortawesome/fontawesome-free-solid/faSync'
import { sync, call } from 'vuex-pathify'

export default {
  data () {
    return {
      selectedShop: null,
      showArchive: false,
      loading: false,
      workAssignmentId: null,
      skipTableReset: false,
      counts: {
        new: 0,
        today: 0,
        tomorrow: 0
      },
      links: {
        page: 1,
        limit: 10,
        total: 0
      },
      sort: {
        field: 'internalId',
        order: true
      },
      filters: {
        id: '',
        internalId: '',
        jobId: '',
        referralNumber: '',
        lastName: '',
        firstName: '',
        phone: '',
        jobNumber: '',
        yearMakeModel: '',
        scheduledDate: '',
        dateSubmitted: '',
        referenceNumber: '',
        contactNumber: '',
        status: '',
        response: ''
      },
      selectedTab: 'claims',
      delayTimer: null,
      loadTableCount: 0,
      resubmitClaimId: -1,
      viewClaimId: -1,
      coverageClaimId: -1,
      confirmArchiveChangePopup: false,
      archiveClaimId: -1,
      archiveClaim: false,
      archiving: false,

      alertPopupText: '',
      alertPopup: false
    }
  },
  watch: {
    selectedShop () {
      this.resetTableVariables()
      this.getCounts(this.selectedShop.id)
      this.refreshTable()
    },
    showArchive () {
      this.refreshTable()
    },
    filters: {
      handler: function (val, oldVal) {
        this.refreshTable()
      },
      deep: true
    }
  },
  computed: {
    visible: sync('glaxis/dialogs@inbox'),
    faSync () {
      return faSync
    },
    tableFields: function () {
      if (this.selectedTab === 'claims') {
        return [
          { key: 'internalId', label: 'ID #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'jobId', label: 'Job ID #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'dateSubmitted', label: 'Date Submitted', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'firstName', label: 'First Name', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'lastName', label: 'Last Name', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'referenceNumber', label: 'Reference Number', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'contactNumber', label: 'Contact Number', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'status', label: 'Status', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'response', label: 'Response', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'claimActions', label: 'Actions', sortable: false, thClass: 'leftTh claimActionsHeader', tdClass: 'leftTd' }
        ]
      } else if (this.selectedTab === 'assignments') {
        return [
          { key: 'id', label: 'ID #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'referralNumber', label: 'Referral #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'lastName', label: 'Last Name', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'firstName', label: 'First Name', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'phone', label: 'Phone', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'jobNumber', label: 'Job #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'yearMakeModel', label: 'Year Make Model', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'scheduledDate', label: 'Schedule Date', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
          { key: 'actions', label: 'Actions', sortable: false, thClass: 'leftTh', tdClass: 'leftTd' }
        ]
      }
      return []
    },
    numberOfRecords: function () {
      let firstResult = 0
      let lastResult = 0
      const total = this.links.total
      if (total > 0) {
        firstResult = this.links.page * this.links.limit - this.links.limit + 1
        if (this.links.page * this.links.limit > total) {
          lastResult = total
        } else {
          lastResult = this.links.page * this.links.limit
        }
      }
      return firstResult + ' to ' + lastResult + ' of ' + total
    }
  },
  methods: {
    viewOpen: call('glaxis/openAssignmentDetailDialog'),
    refreshTable() {
      const self = this
      clearTimeout(self.delayTimer)
      self.delayTimer = setTimeout(function () {
        if (self.$refs.glaxisDialogTable) {
          self.$refs.glaxisDialogTable.refresh()
        }
      }, 300)
    },
    refreshTableClicked () {
      this.refreshTable()
    },
    reloadInsurerIds () {
      GlaxisInsurer.reloadInsurerIds(
        res => {
        }, err => {
          console.log('insurer error: ', err)
        })
    },
    claimsTabClicked () {
      this.selectedTab = 'claims'
      this.resetTableVariables()
    },
    assignmentsTabClicked () {
      this.selectedTab = 'assignments'
      this.resetTableVariables()
    },
    authorizationsTabClicked () {
      this.selectedTab = 'authorizations'
      this.resetTableVariables()
    },
    paymentsTabClicked () {
      this.selectedTab = 'payments'
      this.resetTableVariables()
    },
    dialogAboutToAppear () {
      if (!this.skipTableReset) {
        if (this.$store.state.user.shop_id) {
          for (var i = 0; i < this.$store.state.shops.length; i++) {
            if (this.$store.state.shops[i].id === this.$store.state.user.shop_id) {
              this.selectedShop = this.$store.state.shops[i]
              this.getCounts(this.selectedShop.id)
              break
            }
          }
        } else {
          this.selectedShop = null
        }
        this.claimsTabClicked()
      }
      this.skipTableReset = false
    },
    getShopCountForDropdown (shop_id) {
      if (this.$store.state.glaxisWorkAssignmentsCountArray[shop_id]) {
        return this.$store.state.glaxisWorkAssignmentsCountArray[shop_id].countForDisplay
      } else {
        return '0'
      }
    },
    myProvider (ctx, callback) {
      const self = this
      if (this.selectedShop === null) {
        var emptyResponse = []
        callback(emptyResponse)
        return
      }

      this.loading = true
      if (this.sortBy === null) {
        this.sortBy = ''
      }

      self.loadTableCount++
      var currentLoadCount = self.loadTableCount
      if (this.selectedTab === 'claims') {
        GlaxisClaim.getForShop(
          this.selectedShop.id,
          this.showArchive,
          this.links,
          this.sort,
          {
            internalId: this.filters.internalId,
            jobId: this.filters.jobId,
            dateSubmitted: this.filters.dateSubmitted,
            firstName: this.filters.firstName,
            lastName: this.filters.lastName,
            referenceNumber: this.filters.referenceNumber,
            contactNumber: this.filters.contactNumber,
            status: this.filters.status,
            response: this.filters.response
          },
          res => {
            if (currentLoadCount === self.loadTableCount) {
              self.links.total = res.data.total
              callback(res.data.items)
              self.loading = false
            }
          }, err => {
            console.log('have error: ', err)
            self.loading = false
          }
        )
      } else if (this.selectedTab === 'assignments') {
        GlaxisWorkAssignment.getForShop(
          this.selectedShop.id,
          this.showArchive,
          this.links,
          this.sort,
          {
            id: this.filters.id,
            referralNumber: this.filters.referralNumber,
            lastName: this.filters.lastName,
            firstName: this.filters.firstName,
            phone: this.filters.phone,
            jobNumber: this.filters.jobNumber,
            yearMakeModel: this.filters.yearMakeModel,
            scheduledDate: this.filters.scheduledDate
          },
          res => {
            if (currentLoadCount === self.loadTableCount) {
              self.links.total = res.data.total
              callback(res.data.items)
              self.loading = false
            }
          }, err => {
            console.log('have error: ', err)
            self.loading = false
          }
        )
      } else {
        self.loading = false
        emptyResponse = []
        callback(emptyResponse)
      }
    },
    resetTableVariables () {
      this.showArchive = false
      this.links.page = 1
      this.links.total = 0

      if (this.selectedTab === 'claims') {
        this.sort = {
          field: 'internalId',
          order: true
        }
      } else if (this.selectedTab === 'assignments') {
        this.sort = {
          field: 'id',
          order: true
        }
      } else {
        this.sort = {
          field: '',
          order: ''
        }
      }

      this.filters = {
        id: '',
        internalId: '',
        jobId: '',
        referralNumber: '',
        lastName: '',
        firstName: '',
        phone: '',
        jobNumber: '',
        yearMakeModel: '',
        scheduledDate: '',
        dateSubmitted: '',
        referenceNumber: '',
        contactNumber: '',
        status: '',
        response: ''
      }
    },
    checkGlaxis () {
      const self = this
      GlaxisWorkAssignment.checkGlaxis(res => {
        if (res === 'No more records') {
          self.alertPopupText = 'No new assignments available.'
          self.alertPopup = true
        } else {
          self.myProvider('ctx', function () {
            self.refreshTable()
          })
        }
      })
    },
    getCounts (shopId) {
      const self = this
      GlaxisWorkAssignment.getCounts(shopId, res => {
        self.counts.new = res.new
        self.counts.today = res.today
        self.counts.tomorrow = res.tomorrow
      })
    },
    filerByDay (day) {
      this.showArchive = false
      if (day === 'today') {
        this.filters.scheduledDate = moment().format('M/D/YYYY')
      } else if (day === 'tomorrow') {
        this.filters.scheduledDate = moment().add(1, 'days').format('M/D/YYYY')
      }
    },
    viewWorkAssignment (id) {
      this.workAssignmentId = id
      this.viewOpen()
      // this.$root.$emit('bv::show::modal', 'glaxisWorkAssignmentModal')
    },
    async handleStart (id) {
      // const self = this
      this.$messageBox.alert('Coming soon!')
      // if (confirm('Are you sure you want to start a job from this assignment ?')) {
      //   self.loading = true
      //   // GlaxisWorkAssignment.startJob(id, res => {
      //   //   if ('error' in res) {
      //   //     self.alertPopupText = res.error
      //   //     self.alertPopup = true
      //   //   } else {
      //   //     self.$store.dispatch('updateGlaxisWorkAssignmentsCount')
      //   //     self.changeRouteWithCheck('/jobs/' + res.jobId)
      //   //     this.$store.dispatch('glaxis/closeGlaxisDialog'))
      //   //     self.loading = false
      //   //   }
      //   // }, err => {
      //   //   console.log('err: ', err)
      //   //   self.alertPopupText = 'Failed to start a job from work assignment.'
      //   //   self.alertPopup = true
      //   //   self.loading = false
      //   // })

      //   await this.$unum.job().post('glaxis/start', {id})
      // }
    },
    openLossNotice(jobId) {
      this.$store.dispatch('glaxis/openClaimDialog', jobId)
    },
    handleClaimArchive(glaxisClaimId, archive) {
      if (glaxisClaimId && glaxisClaimId > 0) {
        this.archiveClaimId = glaxisClaimId
        this.archiveClaim = archive
        this.confirmArchiveChangePopup = true
      }
    },
    finishHandleClaimArchive() {
      if (this.archiving) {
        return
      }

      const self = this
      self.confirmArchiveChangePopup = false
      self.archiving = true
      GlaxisClaim.handleClaimArchive(
        self.archiveClaimId,
        self.archiveClaim,
        res => {
          self.archiving = false
          self.refreshTable()
        }, err => {
          self.archiving = false
          console.log('have error: ', err)
          self.alertPopupText = 'Something went wrong ' + (self.archiveClaim ? 'archiving' : 'unarchiving') + ' this claim.'
          self.alertPopup = true
        }
      )
    },
    handleArchive (item) {
      const self = this
      if (item.jobNumber !== null || (
        item.jobNumber === null &&
        confirm('Are you sure you want to archive this assignment, no job has been created ?')
      )) {
        self.loading = true
        GlaxisWorkAssignment.archive(item.id, () => {
          self.myProvider('ctx', function () {
            self.refreshTable()
          })
          self.getCounts(self.selectedShop.id)
          self.$root.$emit('showGlobalNotification', 'Success', 'The work assignment has been archived')
          self.$store.dispatch('updateGlaxisWorkAssignmentsCount')
          self.getShopCountForDropdown(self.selectedShop.id)
          self.loading = false
        })
      }
    }
  },
  created () {
    this.$root.$on('refreshGlaxisDialogTable', this.refreshTable)
  },
  beforeDestroy: function () {
    this.$root.$off('refreshGlaxisDialogTable', this.refreshTable)
  },
  components: {
    Multiselect,
    GlaxisWorkAssignmentDialog,
    CheckBoxLabel,
    FontAwesomeIcon
  },
  mixins: [UtilityMixin]
}
</script>

<style>
#glaxisModal .modal-lg {
  min-width: 90%;
}

.glaxisActionButton {
  background-color: rgb(210, 210, 210);
  color: black;
  cursor: pointer;
  float: right;
  border-radius: 20px;
  padding: 5px 15px;
}

.glaxisTabButton {
  background-color: rgb(210, 210, 210);
  color: black;
  cursor: pointer;
  border-radius: 20px;
  padding: 5px 15px;
}

.glaxisTabButton.selected {
  background-color: green !important;
  color: white !important;
}

.count-for-shops-dropdown {
  text-align:center;
  color:white;
  font-size:14px;
  padding-right:6px;
  padding-left:5px;
  padding-top:5px;
  padding-bottom:5px;
  border-radius: 5px;
  background:#007bff;
}

.pointer {
  cursor: pointer;
}

.claimActionsHeader {
  min-width: 160px;
}
</style>
