import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: '/jobs_deprecated/:jobId',
      name: 'jobpage_deprecated',
      component: () =>
        import(/* webpackChunkName: "JobPage" */ '@/components/JobPage/Index'),
      meta: {
        requiresAccountManager: false,
        requiresAdmin: false,
        // requiresJob: true
      }
    },
    {
      path: '/jobs/:copyType/:baseJobID/:useVehicle',
      name: 'duplicatejobpage',
      component: () =>
        import(/* webpackChunkName: "JobPage" */ '@/components/JobPage/Index'),
      meta: { requiresAccountManager: false, requiresAdmin: false }
    },
    {
      path: '/',
      name: 'Graph',
      component: () =>
        import(
          /* webpackChunkName: "LandingPage" */ '@/components/Blank/Blank'
        ),
      meta: { requiresAccountManager: false, requiresAdmin: false, sidebar_v2: true }
    },
    {
      path: '/scheduler_v2',
      name: 'SchedulerV2',
      component: () =>
        import(
          /* webpackChunkName: "SchedulerPage" */ '@/components/SchedulerPageV2/Index'
        ),
      meta: { requiresAccountManager: false, requiresAdmin: false }
    },
    {
      path: '/scheduler_v3',
      name: 'SchedulerV3',
      component: () =>
        import(
          /* webpackChunkName: "SchedulerPage" */ '@/components/SchedulerPageV3/Index'
        ),
      meta: { requiresAccountManager: false, requiresAdmin: false }
    },
    {
      path: '/reports',
      name: 'Reports',
      component: () =>
        import(
          /* webpackChunkName: "ReportsPage" */ '@/components/ReportsPage/Index'
        ),
      meta: { requiresReports: true }
    },
    {
      path: '/tutorials',
      name: 'Tutorials',
      component: () =>
        import(
          /* webpackChunkName: "TutorialsPage" */ '@/components/TutorialsPage/Tutorials.vue'
        ),
      meta: { requiresAccountManager: false, requiresAdmin: false }
    },
    {
      path: '/login',
      name: 'Login',
      component: () =>
        import(
          /* webpackChunkName: "LoginPage" */ '@/components/LoginPage/Page.vue'
        )
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: () =>
        import(
          /* webpackChunkName: "SignUpPage" */ '@/components/SignUpPage/Index.vue'
        )
    },
    {
      path: '/forgotPassword',
      name: 'Forgot Password',
      component: () =>
        import(
          /* webpackChunkName: "ForgotPasswordPage" */ '@/components/ForgotPasswordPage'
        )
    },
    {
      path: '/updateEmail',
      name: 'Update Email',
      component: () =>
        import(
          /* webpackChunkName: "UpdateEmailPage" */ '@/components/UpdateEmailPage'
        )
    },
    {
      path: '/manage',
      name: 'Manage',
      component: () =>
        import(
          /* webpackChunkName: "ManagePage" */ '@/components/ManagePage/Index'
        ),
      meta: {
        requiresAccountManager: false,
        requiresAdmin: false,
        requiresManage: true
      }
    },
    {
      path: '/jobs',
      name: 'Jobs',
      component: () =>
        import(
          /* webpackChunkName: "JobsListV2" */ '@/components/DataTab/JobsListV2/Index'
        ),
      meta: { requiresDatatabJob: true }
    },
    {
      path: '/customers',
      name: 'Customers',
      component: () =>
        import(
          /* webpackChunkName: "CustomerList" */ '@/components/Blank/Blank'
        ),
      meta: { requiresDatatabCustomers: true }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () =>
        import(
          /* webpackChunkName: "/dashboardPage" */ '@/components/Blank/Blank'
        )
    },
    {
      path: '/vendors',
      name: 'Vendors',
      component: () =>
        import(
          /* webpackChunkName: "VendorList" */ '@/components/DataTab/Vendor/Index'
        ),
      meta: { requiresDatatabVendors: true }
    },
    {
      path: '/admin',
      name: 'Admin',
      component: () =>
        import(
          /* webpackChunkName: "AdminPage" */ '@/components/AdminPage/Index'
        ),
      meta: { requiresAccountManager: true, requiresAdmin: false }
    },
    {
      path: '/quickBooksOnlineConnect',
      name: 'QuickBooks Online Connector',
      component: () =>
        import(
          /* webpackChunkName: "QuickBooksConnector" */ '@/components/QuickBooksConnector'
        )
    },
    {
      path: '/squareConnect',
      name: 'Square Connector',
      component: () =>
        import(
          /* webpackChunkName: "SquareConnector" */ '@/components/SquareConnector'
        )
    },
    {
      path: '/cloverConnect',
      name: 'Clover Connector',
      component: () =>
        import(
          /* webpackChunkName: "CloverConnector" */ '@/components/CloverConnector'
        )
    },
    {
      path: '/ar',
      name: 'AR/AP',
      component: () =>
        import(
          /* webpackChunkName: "ARAPPage" */ '@/components/ARAPPage/Index'
        ),
      meta: { requiresARAP: true }
    },
    {
      path: "/accounting/*",
      name: "Vue3Route",
      component: () =>
        import(
          /* webpackChunkName: "Vue3Route" */ "@/components/Blank/Blank"
        ),
      meta: { requiresARAP: true }
    },
    {
      path: "/warehouse",
      name: "Warehouse",
      component: () =>
        import(
          /* webpackChunkName: "WarehousePage" */ "@/components/WarehousePage/Index"
        ),
      meta: { requiresWarehouse: true }
    },
    {
      path: '/activity',
      name: 'Techside Activity',
      component: () =>
        import(
          /* webpackChunkName: "WarehousePage" */ '@/components/TechsideActivity/Index'
        )
    },
    {
      path: '/signature_capture/:wo/:token/:source',
      name: 'Signature Capture',
      component: () =>
        import(
          /* webpackChunkName: "SignatureCapture" */ '@/components/SignatureCapture/Index'
        )
    },
    {
      path: '/gb_form/:id/:token/landing',
      name: 'GB Form Signature Capture',
      component: () =>
        import(
          /* webpackChunkName: "GbFormSignatureCapture" */ '@/components/GbFormSignatureCapture/Landing.vue'
        )
    },
    {
      path: '/gb_form/:id/:token/sign',
      name: 'GB Form Signature Capture',
      component: () =>
        import(
          /* webpackChunkName: "GbFormSignatureCapture" */ '@/components/GbFormSignatureCapture/Index'
        )
    },
    {
      path: '/paymentPortal/:jobId/:token',
      name: 'Payment Portal',
      component: () =>
        import(
          /* webpackChunkName: "PaymentPortal" */ '@/components/PaymentPortal/Index'
        )
    },
    {
      path: '/newclient',
      name: 'New Client',
      component: () =>
        import(
          /* webpackChunkName: "NewClient" */ '@/components/NewClient/Index'
        )
    },
    {
      path: '/fundingCenter',
      name: 'Funding Center',
      component: () =>
        import(
          /* webpackChunkName: "WarehousePage" */ '@/components/FundingCenter/Index'
        ),
      meta: { requiresAdmin: true }
    },
    {
      path: '/shopAnalytics',
      name: 'Shop Analytics',
      component: () =>
        import(
          /* webpackChunkName: "WarehousePage" */ '@/components/ShopAnalytics/Index'
        ),
      meta: { requiresAdmin: true }
    },
    {
      path: '/availability',
      name: 'Availability Calendar',
      component: () =>
        import(
          /* webpackChunkName: "WarehousePage" */ '@/components/AvailabiltyCalendar/Index'
        ),
      props: route => ({
        tech_id: route.query.tech_id,
        org_id: route.query.org_id
      })
    },
    {
      path: '/jobs/:id/:draft_id?',
      name: 'jobpage',
      component: () =>
        import(
          /* webpackChunkName: "JobPageV2" */ '@/components/JobPageV2/Index'
        ),
      meta: {
        fixedLayout: true,
        requiresAccountManager: false,
        requiresAdmin: false,
        requiresJob: true,
        hideSidebarArrow: true
      }
    }
  ]
});

// Listen for route changes from vue3 microfrontend
window.addEventListener("changeRoute", event => {
  switch (event.detail.action) {
    case "push":
      router.push(event.detail.path);
      break;
    case "replace":
      router.replace(event.detail.path);
      break;
    case "go":
      router.go(event.detail.delta);
      break;
    case "back":
      router.back();
      break;
    case "forward":
      router.forward();
      break;
    default:
      throw new Error("Invalid action");
  }
});

export default router;
