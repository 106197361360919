<template>
  <div class="scheduler-unmapped-jobs" v-if="items.length">
    <div class="title">
      <div>Unmapped locations:</div>
    </div>
    <div class="item-container">
      <div
        class="item"
        v-for="item in items"
        :key="item.id"
        :style="getStyle(item)"
        @click="$emit('item-clicked', item)"
      >
        <scheduler-map-infowindow :marker="item" />
        {{ item.order }}
      </div>
    </div>
  </div>
</template>

<script>
import { checkColor } from '@/scripts/helpers/geometry.helpers'
import { get } from 'vuex-pathify'
import { getTechSideSent } from '@/scripts/helpers/job.helpers'
import { shedulerColors } from '@/constants/sheduler.configs'
import SchedulerMapInfowindow from './SchedulerMapInfowindow'

export default {
  components: { SchedulerMapInfowindow },
  computed: {
    items: get('sch_v3/unmappedJobs')
  },
  methods: {
    getStyle(item) {
      const sent = getTechSideSent(item)
      return {
        borderColor: sent ? shedulerColors.success : checkColor(item.color, true),
        borderWidth: sent ? '3px' : '1px',
        backgroundColor: checkColor(item.color)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerUnmappedJobs.scss';
</style>
