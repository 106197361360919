<template>
  <el-dialog
    append-to-body
    top="50px"
    width="1300px"
    :visible="!!id"
    :before-close="handleClose"
    custom-class="item-profile-dialog"
  >
    <span
      slot="title"
      class="t-flex t-items-center t-mt-[-4px] t-text-gray-600"
    >
      <i class="t-text-lg el-icon-document" />
      <div class="t-ml-3">
        {{ isCatalog ? 'Catalog' : 'Item' }} Profile
      </div>
      <el-button
        v-if="item"
        class="t-ml-4"
        size="mini"
        icon="el-icon-edit"
        type="primary"
        plain
        round
        @click="openUpdate"
      >
        Edit
      </el-button>
      <el-button
        v-if="item && !item.unremovable && isCatalog"
        class="t-ml-2"
        size="mini"
        icon="el-icon-delete"
        type="danger"
        plain
        round
        @click="deleteCatalog"
      >
        Delete
      </el-button>
      <el-button
        v-if="jobId && !isCatalog"
        class="t-ml-2"
        size="mini"
        icon="el-icon-plus"
        type="success"
        plain
        round
        :loading="partAdding"
        @click="addToPartList"
      >
        Add to part list
      </el-button>
    </span>
    <LoadingSpinner v-if="fetching" />
    <div v-else class="t-flex t-h-[700px]">
      <div class="t-w-1/2 t-border-0 t-border-r t-border-solid t-border-gray-200 t-px-8 t-py-6">
        <ItemInfo v-if="!isCatalog" :item="item" />
        <CatalogInfo v-else :item="item" />
      </div>
      <div class="t-w-1/2 t-flex t-flex-col">
        <InventorySection v-if="!isCatalog" :itemId="id" :shopId="shopId" />
        <ParentsSection
          :id="id"
          :isCatalog="isCatalog"
          @updated="refreshParents"
        />
      </div>
    </div>
    <CreateUpdateDialog
      :visible="update.visible"
      :id="id"
      :isCatalog="isCatalog"
      @closed="onUpdateClosed"
      @updated="onUpdated"
    />
  </el-dialog>
</template>

<script>
import ItemInfo from './ItemInfo.vue'
import CatalogInfo from './CatalogInfo.vue'
import ParentsSection from './ParentsSection.vue'
import InventorySection from './Inventory/Index.vue'
import CreateUpdateDialog from '../CreateUpdate/Index.vue'
import LoadingSpinner from '../../Helpers/LoadingSpinner.vue'

export default {
  components: {
    ItemInfo,
    CatalogInfo,
    CreateUpdateDialog,
    ParentsSection,
    InventorySection,
    LoadingSpinner
  },
  props: {
    id: Number,
    isCatalog: Boolean,
    jobId: Number,
    isDraft: Boolean,
    shopId: Number
  },
  data: () => ({
    item: null,
    fetching: true,
    update: {
      visible: false
    },
    partAdding: false
  }),
  watch: {
    id: function(val) {
      if (val) {
        this.getItem()
      }
    }
  },
  methods: {
    getItem: async function() {
      try {
        this.fetching = true
        const { data } = await this.$unum.warehouse().get(`/items/${this.id}`)
        this.item = data.data
        this.fetching = false
      } catch {
        this.fetching = false
      }
    },
    handleClose: async function() {
      this.$emit('closed')
    },
    openUpdate: function() {
      this.update.visible = true
    },
    onUpdated: function() {
      this.getItem()
      this.update.visible = false
      this.$emit('updated')
    },
    onUpdateClosed: function() {
      this.update.visible = false
    },
    refreshParents: function() {
      this.$emit('updated')
    },
    deleteCatalog: async function() {
      try {
        await this.$messageBox.confirm(
          'This catalog will be deleted, and all the items and catalogs ' +
          'inside of it will be unassigned from it. Do you want to continue ?',
          {
            type: 'error',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            beforeClose: async (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonText = 'Loading...'
                instance.confirmButtonLoading = true
                try {
                  await this.$unum.warehouse().delete(`/catalogs/${this.id}`)
                  instance.confirmButtonLoading = false
                  this.$emit('deleted')
                  done()
                } catch {
                  instance.confirmButtonText = 'Yes'
                  instance.confirmButtonLoading = false
                }
              } else {
                done()
              }
            }
          }
        )
      } catch {}
    },
    addToPartList: async function() {
      this.partAdding = true
      try {
        await this.$unum.job().post('part/add-from-catalog', {
          itemId: this.item.id,
          jobId: this.jobId,
          isDraft: this.isDraft
        })
        this.$emit('refetchParts')
        this.$message.success('Item added')
        this.partAdding = false
      } catch {
        this.partAdding = false
      }
    }
  }
}
</script>

<style lang="scss">
.item-profile-dialog {
  @apply t-font-sans;
  .el-dialog__header {
    @apply t-pl-6 t-pb-3 t-border-0 t-border-b t-border-solid;
  }
  .el-dialog__body {
    @apply t-p-0;
  }
}
</style>
