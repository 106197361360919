var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bay-job-card-list",
      class: {
        "row-view": _vm.isRowView
      }
    },
    [
      _c("div", { staticClass: "bay-job-list-card-header" }, [
        _c(
          "div",
          { staticClass: "header-text" },
          [
            _c(
              "el-tag",
              {
                style: Object.assign({}, _vm.cardStyle(null, _vm.color), {
                  border: "none",
                  margin: 0,
                  width: "100%"
                })
              },
              [_vm._v(_vm._s(_vm.name))]
            )
          ],
          1
        )
      ]),
      _c(
        "draggable",
        {
          staticClass: "job-card-list-container",
          attrs: { options: _vm.draggableOptions },
          model: {
            value: _vm.jobList,
            callback: function($$v) {
              _vm.jobList = $$v
            },
            expression: "jobList"
          }
        },
        _vm._l(_vm.jobList, function(item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "card-wrapper",
              on: {
                dragstart: function($event) {
                  return _vm.itemDrag(item)
                },
                dragend: function($event) {
                  return _vm.itemDrag()
                }
              }
            },
            [
              item.shop_name
                ? _c("scheduler-job-card", {
                    attrs: {
                      job: item,
                      color: _vm.color,
                      routeEntry: {},
                      cardStyle: _vm.cardStyle(item)
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }