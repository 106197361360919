var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "close-outside-click",
          rawName: "v-close-outside-click",
          value: _vm.closePopover,
          expression: "closePopover"
        }
      ],
      class: { "popover-open": _vm.show },
      attrs: { id: "popover-container" }
    },
    [
      _c("div", { attrs: { id: "popoverTarget" } }),
      _c(
        "b-popover",
        {
          ref: "popoverDropZone",
          attrs: {
            target: "popoverTarget",
            triggers: "",
            container: "popover-container",
            show: _vm.show,
            placement: "auto"
          },
          on: { hide: _vm.popoverHide }
        },
        [
          _c("DocumentPopoverContent", {
            attrs: {
              type: _vm.getType,
              docTypes: _vm.getDocTypes,
              file: _vm.getFile,
              refId: _vm.getRefId,
              shopId: _vm.getShopId,
              filePickerKey: _vm.filePickerKey,
              show: _vm.show
            },
            on: { openDialog: _vm.openDialog, closePopover: _vm.closePopover }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }