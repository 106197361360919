var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "document-list-item", class: { expanded: _vm.expanded } },
    [
      _vm.expanded ? _c("span", [_vm._v(_vm._s(_vm.name))]) : _vm._e(),
      _c("documents-tag", { attrs: { doc: _vm.doc, number: _vm.doc.number } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }