<template>
  <div class="select-action-buttons">
    <el-button
      type="text"
      icon="el-icon-plus"
      @click="$emit('add')"
      :disabled="newDisabled"
    >
      {{ newLabel }}
    </el-button>
    <el-divider direction="vertical" />
    <el-button
      type="text"
      icon="el-icon-delete"
      @click="$emit('delete')"
      :disabled="clearDisabled"
      class="select-delete-button"
    >
      {{ clearLabel }}
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    newLabel: {
      type: String,
      default: () => 'Add new'
    },
    clearLabel: {
      type: String,
      default: () => 'Clear'
    },
    newDisabled: {
      type: Boolean
    },
    clearDisabled: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.select-action-buttons {
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  .el-button {
    flex-grow: 1;
  }
  .select-delete-button {
    color: $danger;
  }
}
</style>
