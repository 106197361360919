<template>
  <el-form-item prop="typeId">
    <span slot="label" class="undo-label-styles">Customer</span>
    <el-select
      v-model='selectedConsumer'
      style="width: 100%"
      value-key='id'
      >
      <el-option
        v-for='consumer in consumers'
        :key='consumer.id'
        :value='consumer'
        :label='consumer.name'>
        {{consumer.name}} (${{consumer.amount.toFixed(2)}})
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  props: ['consumerToCharge'],
  data () {
    return {

    }
  },
  methods: {
    consumerName (consumer) {
      if (consumer.is_commercial || consumer.is_insurance) {
        return consumer.company_name
      }

      if (consumer.name.first) {
        if (consumer.name.last) {
          return consumer.name.first + ' ' + consumer.name.last
        }

        return consumer.name.first
      }

      if (consumer.name.last) {
        return consumer.name.last
      }

      return ''
    }
  },
  computed: {
    customer: get('twilio/textMessages/jobPayment/customer'),
    billTo: get('twilio/textMessages/jobPayment/billTo'),
    balanceDetails: get('twilio/textMessages/jobPayment/balanceDetails'),
    consumers () {
      if (this.customer && this.billTo && (this.customer.id === this.billTo.id)) {
        return [
          {
            id: this.customer.id,
            name: this.consumerName(this.customer),
            amount: this.balanceDetails.total_deductible_after_payments
          }
        ]
      } else if (this.customer && this.billTo) {
        return [
          {
            id: this.customer.id,
            name: this.consumerName(this.customer),
            amount: this.balanceDetails.total_deductible_after_payments
          },
          {
            id: this.billTo.id,
            name: this.consumerName(this.billTo),
            amount: this.balanceDetails.total_balance_after_payments
          }
        ]
      } else {
        return []
      }
    },
    selectedConsumer: {
      get () {
        return this.consumerToCharge
      },
      set (val) {
        this.$emit('update:consumerToCharge', val)
      }
    }
  },
  watch: {
    consumers: {
      immediate: true,
      handler (v) {
        if (this.consumers.length) {
          if (this.consumers?.length > 1 && parseFloat(this.consumers[1].amount) > 0 && parseFloat(this.consumers[0].amount) <= 0) {
            this.selectedConsumer = this.consumers[1]
          } else {
            this.selectedConsumer = this.consumers[0]
          }
        }
      }
    }
  }
}
</script>

<style>

</style>
