export const catalogsStore = {
  ns: 'catalogs/',
  mutations: {
    setIsOpen: 'setIsOpen'
  }
}

const state = {
  isOpen: false
}

const mutations = {
  [catalogsStore.mutations.setIsOpen](state, payload) {
    state.isOpen = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
