var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title:
              _vm.insurancefleet.id > 0
                ? "Edit Insurance Fleet"
                : "New Insurance Fleet",
            id: "insurancefleetModal",
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg"
          },
          on: { show: _vm.dialogAboutToAppear }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-sm-12 col-md" }, [
              _c("h4", [
                _vm._v("ID: "),
                _c("span", { staticClass: "idBox" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.insurancefleet.id <= 0
                          ? "New"
                          : _vm.insurancefleet.id
                      ) +
                      " "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "form-group col-sm-12 col-md" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mx-3",
                  staticStyle: { padding: "0px 1px 0px 1px" },
                  attrs: { type: "button", size: "sm", variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.quickApplyDetails("sgc")
                    }
                  }
                },
                [_vm._v("SGC")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mx-3",
                  staticStyle: { padding: "0px 1px 0px 1px" },
                  attrs: { type: "button", size: "sm", variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.quickApplyDetails("lynx")
                    }
                  }
                },
                [_vm._v("Lynx")]
              )
            ]),
            _c("div", { staticClass: "form-group col-sm-12 col-md" }, [
              _vm._v(" Status:"),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.insurancefleet.status,
                      expression: "insurancefleet.status"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.insurancefleet,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "active" } }, [
                    _vm._v("Active")
                  ]),
                  _c("option", { attrs: { value: "disabled" } }, [
                    _vm._v("Disabled")
                  ])
                ]
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-sm-12 col-md" }, [
              _c("label", { attrs: { for: "insurancefleetName" } }, [
                _vm._v("Name")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.insurancefleet.name,
                    expression: "insurancefleet.name"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { type: "text" },
                domProps: { value: _vm.insurancefleet.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.insurancefleet, "name", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group col-sm-12 col-md" }, [
              _c("label", { attrs: { for: "insurancefleetWeb" } }, [
                _vm._v("Website")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.insurancefleet.web,
                    expression: "insurancefleet.web"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "insurancefleetWeb", type: "text" },
                domProps: { value: _vm.insurancefleet.web },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.insurancefleet, "web", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-3" },
              [
                _c("Phone", {
                  ref: "phoneComponet",
                  attrs: {
                    title: "Phone",
                    value: _vm.insurancefleet.phone,
                    placeholder: ""
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.insurancefleet, "phone", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-3" },
              [
                _c("Phone", {
                  ref: "phoneComponet",
                  attrs: {
                    title: "Fax",
                    value: _vm.insurancefleet.fax,
                    placeholder: ""
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.insurancefleet, "fax", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "form-group col-sm-12 col-md-6" }, [
              _c("label", { attrs: { for: "insurancefleetEmail" } }, [
                _vm._v("Email")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.insurancefleet.email,
                    expression: "insurancefleet.email"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "insurancefleetEmail" },
                domProps: { value: _vm.insurancefleet.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.insurancefleet, "email", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-sm-12 col-md-8" }, [
              _c("label", { attrs: { for: "insurancefleetAddress" } }, [
                _vm._v("Address")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.insurancefleet.address,
                    expression: "insurancefleet.address"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "insurancefleetAddress" },
                domProps: { value: _vm.insurancefleet.address },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.insurancefleet, "address", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group col-sm-12 col-md-4" }, [
              _c("label", { attrs: { for: "insurancefleetZip" } }, [
                _vm._v("Zip")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.insurancefleet.address_zip,
                    expression: "insurancefleet.address_zip"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "insurancefleetZip" },
                domProps: { value: _vm.insurancefleet.address_zip },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.insurancefleet,
                      "address_zip",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-sm-12 col-md-8" }, [
              _c("label", { attrs: { for: "insurancefleetCity" } }, [
                _vm._v("City")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.insurancefleet.address_city,
                    expression: "insurancefleet.address_city"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "insurancefleetCity" },
                domProps: { value: _vm.insurancefleet.address_city },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.insurancefleet,
                      "address_city",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group col-sm-12 col-md-4" }, [
              _c("label", { attrs: { for: "insurancefleetState" } }, [
                _vm._v("State")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.insurancefleet.address_state,
                      expression: "insurancefleet.address_state"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { id: "insurancefleetState" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.insurancefleet,
                        "address_state",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("Choose...")]),
                  _vm._l(_vm.states, function(state) {
                    return _c(
                      "option",
                      { key: state.value, domProps: { value: state.value } },
                      [_vm._v(_vm._s(state.text))]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col insurance-section-header" },
              [_c("b", [_vm._v("CASH CUSTOMER:")])]
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-7 col-lg-4" },
              [
                _c("CheckBoxLabel", {
                  attrs: {
                    label: "IS THIS A CASH CUSTOMER ACCOUNT?",
                    value: _vm.cashCustomer
                  },
                  on: {
                    "update:value": function($event) {
                      _vm.cashCustomer = $event
                    }
                  }
                })
              ],
              1
            ),
            _vm.cashCustomer
              ? _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-5 col-lg-4" },
                  [
                    _c("CheckBoxLabel", {
                      attrs: {
                        label: "Export As Customer",
                        value: _vm.exportAsCustomer
                      },
                      on: {
                        "update:value": function($event) {
                          _vm.exportAsCustomer = $event
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col insurance-section-header" },
              [_c("b", [_vm._v("RATE INFO:")])]
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Glass Discount",
                    value: _vm.insurancefleet.discount,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "% "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.insurancefleet, "discount", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Moulding Discount",
                    value: _vm.insurancefleet.moulding_discount,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "% "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "moulding_discount",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Hourly Labor",
                    value: _vm.insurancefleet.labor_hourly,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "labor_hourly",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Flat Labor",
                    value: _vm.insurancefleet.labor_flat,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.insurancefleet, "labor_flat", $event)
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Oem Glass Discount",
                    value: _vm.insurancefleet.oem_discount,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "% "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "oem_discount",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c(
                  "label",
                  { attrs: { for: "insurancefleetDefaultInvoiceTier" } },
                  [_vm._v("Default Invoice Tier")]
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.insurancefleet.default_invoice_tier,
                        expression: "insurancefleet.default_invoice_tier"
                      }
                    ],
                    staticClass: "form-control form-control-sm",
                    attrs: { id: "insurancefleetDefaultInvoiceTier" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.insurancefleet,
                          "default_invoice_tier",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("Choose...")
                    ]),
                    _vm._l(_vm.invoiceTierOptions, function(option) {
                      return _c(
                        "option",
                        {
                          key: option.value,
                          domProps: { value: option.value }
                        },
                        [_vm._v(_vm._s(option.text))]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-12 col-lg-9" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-12 col-md-12 col-lg-4" },
                    [
                      _c("label", { attrs: { for: "kitStandard" } }, [
                        _vm._v("Kit: Standard")
                      ]),
                      _c("FloatDropdown", {
                        attrs: {
                          dropdownOptions: [
                            {
                              value: "each",
                              label: "Each",
                              dropdownLabel: "Each"
                            },
                            {
                              value: "flat",
                              label: "Flat",
                              dropdownLabel: "Flat"
                            }
                          ],
                          value: _vm.insurancefleet.kit_standard,
                          dropdownValue:
                            _vm.insurancefleet.kit_charge_type_standard,
                          formattedPrefix: "$ "
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "kit_standard",
                              $event
                            )
                          },
                          "update:dropdownValue": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "kit_charge_type_standard",
                              $event
                            )
                          },
                          "update:dropdown-value": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "kit_charge_type_standard",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-12 col-md-12 col-lg-4" },
                    [
                      _c("label", { attrs: { for: "kitStandard" } }, [
                        _vm._v("Kit: High Modulous")
                      ]),
                      _c("FloatDropdown", {
                        attrs: {
                          dropdownOptions: [
                            {
                              value: "each",
                              label: "Each",
                              dropdownLabel: "Each"
                            },
                            {
                              value: "flat",
                              label: "Flat",
                              dropdownLabel: "Flat"
                            }
                          ],
                          value: _vm.insurancefleet.kit_high,
                          dropdownValue:
                            _vm.insurancefleet.kit_charge_type_high,
                          formattedPrefix: "$ "
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "kit_high",
                              $event
                            )
                          },
                          "update:dropdownValue": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "kit_charge_type_high",
                              $event
                            )
                          },
                          "update:dropdown-value": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "kit_charge_type_high",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-12 col-md-12 col-lg-4" },
                    [
                      _c("label", { attrs: { for: "kitStandard" } }, [
                        _vm._v("Kit: Fast Cure")
                      ]),
                      _c("FloatDropdown", {
                        attrs: {
                          dropdownOptions: [
                            {
                              value: "each",
                              label: "Each",
                              dropdownLabel: "Each"
                            },
                            {
                              value: "flat",
                              label: "Flat",
                              dropdownLabel: "Flat"
                            }
                          ],
                          value: _vm.insurancefleet.kit_fast,
                          dropdownValue:
                            _vm.insurancefleet.kit_charge_type_fast,
                          formattedPrefix: "$ "
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "kit_fast",
                              $event
                            )
                          },
                          "update:dropdownValue": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "kit_charge_type_fast",
                              $event
                            )
                          },
                          "update:dropdown-value": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "kit_charge_type_fast",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6 col-md-6" },
                    [
                      _c("FloatInput", {
                        attrs: {
                          title: "First Chip",
                          value: _vm.insurancefleet.chip_first,
                          minimumDecimalPlaces: 2,
                          maximumDecimalPlaces: 2,
                          formattedPrefix: "$ "
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "chip_first",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-12 col-md-6" },
                    [
                      _c("FloatInput", {
                        attrs: {
                          title: "Additional Chips",
                          value: _vm.insurancefleet.chip_additional,
                          minimumDecimalPlaces: 2,
                          maximumDecimalPlaces: 2,
                          formattedPrefix: "$ "
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.insurancefleet,
                              "chip_additional",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-12 col-lg-3" },
              [
                _c("div", { staticClass: "form-row" }),
                _c(
                  "div",
                  { staticClass: "form-row" },
                  [
                    _c("CheckBoxLabel", {
                      attrs: { label: "Tax Exempt", value: _vm.taxExempt },
                      on: {
                        "update:value": function($event) {
                          _vm.taxExempt = $event
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-row" },
                  [
                    _c("CheckBoxLabel", {
                      attrs: {
                        label: "Auto-add Adhesive",
                        value: _vm.autoAddAdhesive
                      },
                      on: {
                        "update:value": function($event) {
                          _vm.autoAddAdhesive = $event
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-row" },
                  [
                    _c("CheckBoxLabel", {
                      attrs: { label: "PO Required", value: _vm.poRequired },
                      on: {
                        "update:value": function($event) {
                          _vm.poRequired = $event
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-row" },
                  [
                    _c("CheckBoxLabel", {
                      attrs: {
                        label: "Display Phone #",
                        value: _vm.displayPhone
                      },
                      on: {
                        "update:value": function($event) {
                          _vm.displayPhone = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]),
          _c(
            "h5",
            {
              staticClass: "mt-1",
              staticStyle: {
                "text-transform": "uppercase",
                "font-weight": "bold",
                "font-size": "0.9em"
              }
            },
            [_vm._v("Recal:")]
          ),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Safelite Dynamic",
                    value: _vm.insurancefleet.recal_safelite_dynamic,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "recal_safelite_dynamic",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Safelite Static",
                    value: _vm.insurancefleet.recal_safelite_static,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "recal_safelite_static",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Safelite Dual",
                    value: _vm.insurancefleet.recal_safelite_dual,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "recal_safelite_dual",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Lynx RTL Dynamic",
                    value: _vm.insurancefleet.recal_lynx_rtl_dynamic,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "recal_lynx_rtl_dynamic",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Lynx RTL Static",
                    value: _vm.insurancefleet.recal_lynx_rtl_static,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "recal_lynx_rtl_static",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Lynx RTL Both",
                    value: _vm.insurancefleet.recal_lynx_rtl_both,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "recal_lynx_rtl_both",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Lynx DLR Dynamic",
                    value: _vm.insurancefleet.recal_lynx_dlr_dynamic,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "recal_lynx_dlr_dynamic",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Lynx DLR Static",
                    value: _vm.insurancefleet.recal_lynx_dlr_static,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "recal_lynx_dlr_static",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-sm-12 col-md-6 col-lg-3" },
              [
                _c("FloatInput", {
                  attrs: {
                    title: "Lynx DLR Both",
                    value: _vm.insurancefleet.recal_lynx_dlr_both,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ "
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(
                        _vm.insurancefleet,
                        "recal_lynx_dlr_both",
                        $event
                      )
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "form-group col-sm-12 col-md-12 insurance-section-header"
              },
              [_c("b", [_vm._v("EDI INFO:")])]
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-sm-12 col-md-4" }, [
              _c("div", { staticClass: "row" }),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-12" },
                  [
                    _c("CheckBoxLabel", {
                      attrs: { label: "EDI Capable", value: _vm.ediCapable },
                      on: {
                        "update:value": function($event) {
                          _vm.ediCapable = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm.ediCapable
              ? _c("div", { staticClass: "form-group col-sm-12 col-md-8" }, [
                  _c("label", { attrs: { for: "trading-partner-input" } }, [
                    _vm._v("Trading Partner")
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.insurancefleet.edi_trading_partner,
                          expression: "insurancefleet.edi_trading_partner"
                        }
                      ],
                      staticClass: "form-control form-control-sm",
                      attrs: { id: "trading-partner-input" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.insurancefleet,
                            "edi_trading_partner",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Choose...")
                      ]),
                      _vm._l(_vm.tradingPartners, function(partner, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: partner.value } },
                          [_vm._v(_vm._s(partner.name))]
                        )
                      })
                    ],
                    2
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "row" }, [
            _vm.insurancefleet.edi_trading_partner == "safelite"
              ? _c("div", { staticClass: "form-group col-sm-12" }, [
                  _c("label", { attrs: { for: "trading-program-id" } }, [
                    _vm._v("Safelite Program ID")
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.insurancefleet.edi_safelite_program_id,
                          expression: "insurancefleet.edi_safelite_program_id"
                        }
                      ],
                      staticClass: "form-control form-control-sm",
                      attrs: { id: "trading-program-id" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.insurancefleet,
                            "edi_safelite_program_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Choose...")
                      ]),
                      _vm._l(_vm.safelitePartners, function(partner) {
                        return _c(
                          "option",
                          {
                            key: partner.id,
                            domProps: { value: partner.program_id }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("capitalize-all")(partner.name)) +
                                " (" +
                                _vm._s(partner.program_id) +
                                ")"
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "row" }, [
            _vm.insurancefleet.edi_trading_partner == "quest"
              ? _c("div", { staticClass: "form-group col-sm-12" }, [
                  _c("label", { attrs: { for: "trading-program-id" } }, [
                    _vm._v("Quest Program ID")
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.insurancefleet.edi_quest_program_id,
                          expression: "insurancefleet.edi_quest_program_id"
                        }
                      ],
                      staticClass: "form-control form-control-sm",
                      attrs: { id: "trading-program-id" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.insurancefleet,
                            "edi_quest_program_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Choose...")
                      ]),
                      _vm._l(_vm.questPartners, function(partner) {
                        return _c(
                          "option",
                          {
                            key: partner.id,
                            domProps: { value: partner.program_id }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("capitalize-all")(partner.name)) +
                                " (" +
                                _vm._s(partner.program_id) +
                                ")"
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col" },
              [
                _c("label", { attrs: { for: "input-notes" } }, [
                  _vm._v("Notes")
                ]),
                _c("b-form-textarea", {
                  attrs: {
                    id: "input-notes",
                    placeholder: "Enter insurance fleet notes...",
                    rows: 3,
                    "max-rows": 5
                  },
                  model: {
                    value: _vm.insurancefleet.notes,
                    callback: function($$v) {
                      _vm.$set(_vm.insurancefleet, "notes", $$v)
                    },
                    expression: "insurancefleet.notes"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col" },
              [
                _c("CheckBoxLabel", {
                  attrs: {
                    label: "Auto Popup Message",
                    value: _vm.autoPopupCheck
                  },
                  on: {
                    "update:value": function($event) {
                      _vm.autoPopupCheck = $event
                    }
                  }
                })
              ],
              1
            )
          ]),
          _vm.autoPopupCheck
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col" },
                  [
                    _c("label", { attrs: { for: "input-popup-message" } }, [
                      _vm._v("Auto Popup Message")
                    ]),
                    _c("b-form-textarea", {
                      attrs: {
                        id: "input-popup-message",
                        placeholder: "Enter Message...",
                        rows: 3,
                        "max-rows": 5
                      },
                      model: {
                        value: _vm.insurancefleet.auto_popup_message,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.insurancefleet,
                            "auto_popup_message",
                            $$v
                          )
                        },
                        expression: "insurancefleet.auto_popup_message"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", size: "lg", variant: "primary" },
                    on: { click: _vm.saveInsurancefleet }
                  },
                  [_vm._v("Save")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", variant: "primary" },
                    on: { click: _vm.cancelEdits }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }