var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      Object.keys(_vm.selectedThread).length === 0
        ? _c(
            "div",
            { staticStyle: { "padding-top": "150px", "text-align": "center" } },
            [_c("p", [_vm._v("No conversation selected")])]
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.messagesLoading,
                  expression: "messagesLoading"
                }
              ],
              attrs: { id: "text-message-thread-block" }
            },
            [
              !_vm.messagesLoading
                ? _c("div", { attrs: { id: "thread-header" } }, [
                    _c("div", { attrs: { id: "customer-block" } }, [
                      _vm.selectedThread.recipient_model
                        ? _c(
                            "div",
                            { attrs: { id: "recipient-type-section" } },
                            [
                              _vm._v(
                                _vm._s(_vm.selectedThread.recipient_model) + ":"
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { attrs: { id: "customer-phone-name-section" } },
                        [
                          _c("div", [
                            _c("i", { staticClass: "el-icon-phone" }),
                            _vm._v(
                              _vm._s(
                                _vm.formatPhone(
                                  _vm.selectedThread.recipient_phone_number
                                )
                              ) + " "
                            )
                          ]),
                          _vm.selectedThread.recipient_name
                            ? _c("div", { staticClass: "ml-3" }, [
                                _vm.selectedThread.recipient_model ===
                                "customer"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "recipient-customer",
                                        on: {
                                          click: function($event) {
                                            return _vm.openCustomerInfoModal(
                                              _vm.selectedThread.recipient_id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-user-solid"
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedThread.recipient_name
                                          ) + " "
                                        )
                                      ]
                                    )
                                  : _vm.selectedThread.recipient_model ===
                                      "salesrep" && _vm.salesRep
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "recipient-customer",
                                        on: { click: _vm.openSalesRepModal }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-user-solid"
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedThread.recipient_name
                                          ) + " "
                                        )
                                      ]
                                    )
                                  : _vm.selectedThread.recipient_model ===
                                      "tech" && _vm.tech
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "recipient-customer",
                                        on: { click: _vm.openTechModal }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-user-solid"
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedThread.recipient_name
                                          ) + " "
                                        )
                                      ]
                                    )
                                  : _c("span", [
                                      _c("i", {
                                        staticClass: "el-icon-user-solid"
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedThread.recipient_name
                                        ) + " "
                                      )
                                    ])
                              ])
                            : _vm._e()
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      { attrs: { id: "mark-unread" } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "Mark unread",
                              placement: "top-start"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { circle: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.markUnread($event)
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "material-icons md-18 md-mark_chat_unread"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { ref: "thread", attrs: { id: "messages-block" } },
                [
                  _vm._l(_vm.messages, function(i) {
                    return _c(
                      "el-row",
                      { key: i.id, staticClass: "pt-2" },
                      [
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "images-loaded",
                                rawName: "v-images-loaded.on.done",
                                value: _vm.imagesLoaded,
                                expression: "imagesLoaded",
                                modifiers: { on: true, done: true }
                              }
                            ],
                            style:
                              i.direction === 2 ? "text-align: right;" : "",
                            attrs: {
                              span: 20,
                              offset: i.direction === 1 ? 0 : 4
                            }
                          },
                          [
                            _vm._l(i.media, function(media, index) {
                              return _c("div", { key: index }, [
                                media.type.substr(0, 5) === "image"
                                  ? _c("div", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: media.url,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "message-image",
                                            attrs: { src: media.url }
                                          })
                                        ]
                                      )
                                    ])
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "message-media",
                                        style:
                                          i.media.length - 1 !== index ||
                                          (i.media.length - 1 === index &&
                                            i.body !== "")
                                            ? "margin-bottom: 5px;"
                                            : ""
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: media.url,
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-download"
                                            }),
                                            _vm._v(
                                              " " + _vm._s(media.type) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                              ])
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "message-block",
                                style:
                                  i.direction === 1
                                    ? ""
                                    : "justify-content: flex-end;"
                              },
                              [
                                i.error_message !== null && i.direction === 2
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "message-error-tooltip",
                                        attrs: {
                                          effect: "dark",
                                          content: i.error_message,
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-warning mr-2"
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      disabled: i.user_id === null,
                                      effect: "dark",
                                      content: _vm.generateSentBy(i),
                                      placement: "bottom-end"
                                    }
                                  },
                                  [
                                    i.body !== ""
                                      ? _c("div", {
                                          staticClass: "message-body",
                                          class:
                                            i.direction === 1
                                              ? "incoming"
                                              : "outgoing",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.modifyBody(i.body)
                                            )
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "message-timestamp" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatDateTime(i.created_at)) +
                                  " "
                              )
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    )
                  }),
                  !_vm.messagesLoading && this.messages.length === 0
                    ? _c("div", { attrs: { id: "no-messages-yet" } }, [
                        _c("i", [_vm._v("Send a first message")])
                      ])
                    : _vm._e()
                ],
                2
              ),
              !_vm.messagesLoading
                ? _c(
                    "div",
                    { attrs: { id: "controls-block" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "form",
                          staticClass: "upload-block",
                          attrs: {
                            action: "",
                            limit: 10,
                            multiple: true,
                            "on-exceed": _vm.onExceed,
                            "on-remove": _vm.onRemove,
                            "on-change": _vm.onChange,
                            "auto-upload": false,
                            accept:
                              "image/*,audio/*,video/*,text/*,application/pdf"
                          }
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "trigger",
                              icon: "el-icon-paperclip",
                              circle: ""
                            },
                            on: {
                              click: function($event) {
                                _vm.emojiPicker = false
                              }
                            },
                            slot: "trigger"
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { id: "emoji-button", circle: "" },
                              on: {
                                click: function($event) {
                                  _vm.emojiPicker = !_vm.emojiPicker
                                }
                              }
                            },
                            [_c("v-icon", { attrs: { name: "regular/grin" } })],
                            1
                          ),
                          _c("picker", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.emojiPicker,
                                expression: "emojiPicker"
                              },
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: _vm.toggleEmojiPicker,
                                expression: "toggleEmojiPicker"
                              }
                            ],
                            style: {
                              position: "absolute",
                              bottom: "64px",
                              right: "50px",
                              boxShadow: "5px 5px 10px 0 rgb(0, 0, 0, 0.08)"
                            },
                            attrs: { title: "Pick your emoji…" },
                            on: { select: _vm.addEmoji }
                          }),
                          _c(
                            "div",
                            { attrs: { id: "text-message-input" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 1, maxRows: 3 },
                                  placeholder: "Please enter your message"
                                },
                                on: {
                                  focus: function($event) {
                                    _vm.emojiPicker = false
                                  }
                                },
                                nativeOn: {
                                  keydown: [
                                    function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      if (
                                        $event.ctrlKey ||
                                        $event.shiftKey ||
                                        $event.altKey ||
                                        $event.metaKey
                                      ) {
                                        return null
                                      }
                                      $event.preventDefault()
                                    },
                                    function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      if (!$event.ctrlKey) {
                                        return null
                                      }
                                      if (
                                        $event.shiftKey ||
                                        $event.altKey ||
                                        $event.metaKey
                                      ) {
                                        return null
                                      }
                                      return _vm.newline($event)
                                    }
                                  ],
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    if (
                                      $event.ctrlKey ||
                                      $event.shiftKey ||
                                      $event.altKey ||
                                      $event.metaKey
                                    ) {
                                      return null
                                    }
                                    return _vm.submit($event)
                                  }
                                },
                                model: {
                                  value: _vm.body,
                                  callback: function($$v) {
                                    _vm.body = $$v
                                  },
                                  expression: "body"
                                }
                              }),
                              _c(
                                "a",
                                {
                                  staticClass: "pull-right mt-2",
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.setSelectTemplateDialogVisible(
                                        true
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Select a template ")]
                              )
                            ],
                            1
                          ),
                          _c("el-button", {
                            attrs: {
                              loading: _vm.submitLoading,
                              type: "primary",
                              icon: "el-icon-position",
                              circle: ""
                            },
                            on: { click: _vm.submit }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
      _c("SelectTemplateDialog", {
        attrs: { name: _vm.selectedThread.recipient_name },
        on: {
          templateSelected: function($event) {
            _vm.body = $event
          }
        }
      }),
      _vm.salesRep
        ? _c("SalesrepDialog", {
            attrs: {
              id: "salesrepModal",
              parentSalesrep: _vm.salesRep,
              shopId: _vm.selectedThread.shop_id,
              locations: _vm.locations,
              commercialaccounts: _vm.commercialAccounts,
              salesources: _vm.saleSources
            },
            on: {
              updated: function($event) {
                return _vm.findSalesRep($event)
              }
            }
          })
        : _vm._e(),
      _vm.tech
        ? _c("TechDialog", {
            attrs: { parentTech: _vm.tech, shopId: _vm.selectedThread.shop_id },
            on: {
              updated: function($event) {
                return _vm.findTech($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }