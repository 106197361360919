<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    @open="dialogAboutToAppear"
    @close="dialogDisappeared"
    class="jobDetailsClaimDialog"
    :appendToBody="true"
    id="glaxisClaim"
    width="1400px"
    destroy-on-close
  >
    <div style="font-size: 18px; font-weight: bold; margin-bottom:10px;">
      <el-row class="claim-page-row top-cards-row" :gutter="16" type="flex">
        <el-col style="white-space:pre-wrap;word-break:break-word;">
          Claim for Job # <a :href="`/jobs/${loadedJobId > 0 ? loadedJobId : ''}`" target="_blank">{{loadedJobId > 0 ? loadedJobId : ''}} </a>
           <ClaimStatus v-if="glaxisClaim && glaxisClaim.id > 0" :jobId="loadedJobId" :includeClaimStatusText="false"/>
        </el-col>
      </el-row>
      <el-row class="claim-page-row top-cards-row" :gutter="16" type="flex">
        <el-col style="white-space:pre-wrap;word-break:break-word;">
          <el-button v-if="this.glaxisClaim && this.glaxisClaim.status === 'Coverage Response Received' && !loading"
            type="secondary"
            @click="reviewCoverage"
            class="reviewCoverageButton"
            icon="el-icon-edit">
            Review Coverage
          </el-button>
        </el-col>
        <el-col style="white-space:pre-wrap;word-break:break-word;">
          <div class="actionButtonsDiv">
            <el-button v-if="this.glaxisClaim && this.glaxisClaim.id > 0 && !loading"
              type="primary"
              @click="reloadClaim"
              class="refreshButton"
              icon="el-icon-refresh"
            >
              Refresh
            </el-button>
            <el-button v-if="this.glaxisClaim && this.glaxisClaim.id > 0 && !loading && showSubmitResubmitButton && showUpdateToMatchJobButton"
              type="secondary"
              @click="updateToMatchJob"
              class="updateToMatchJobButton"
              icon="el-icon-upload"
            >
              Update To Match Job
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-if="loading" class="row">
      <div style="font-size: 16px; text-align: center;" class="col-md-12">
        <p><img style="width:18px;height:18px;margin-right:6px;" src='../../../../assets/img/spinner.gif'> Loading please wait...</p>
      </div>
    </div>
    <div v-else>
        <div class="claimInfoArea">
          <el-row class="claim-page-row top-cards-row" :gutter="16" type="flex">
            <el-col style="white-space:pre-wrap;word-break:break-word;">
              Review the details below and fill in any additional fields for the Claim submission. If you need to change job fields, please edit the job.
              <el-button v-if="!isLive && this.lossNotice.id < 0"
                type="primary"
                @click="prefillWithTestData"
                class="prefillTestData"
              >
                Prefill With Test Data
              </el-button>
            </el-col>
            <el-col v-if="this.glaxisClaim && this.glaxisClaim.response_details && this.glaxisClaim.response_details.length > 0" style="white-space:pre-wrap;word-break:break-word;">
              <div :style="{ color: claimStatusColor }">
                Response Details: {{ this.glaxisClaim.response_details }}
              </div>
            </el-col>
          </el-row>
        </div>
        <el-row class="claim-page-row top-cards-row" :gutter="16" type="flex">
          <InsuredCustomer ref="insuredCustomer" :updateSchemaLabelsWithMatchingAndSubrogation="updateSchemaLabelsWithMatchingAndSubrogation" :lossNotice="lossNotice" :customer="customer" :fieldsDisabled="fieldsDisabled"/>
          <Insurance ref="insurance" :updateSchemaLabelsWithMatchingAndSubrogation="updateSchemaLabelsWithMatchingAndSubrogation" :lossNotice="lossNotice" :billTo="billTo" :supportedInsurers="supportedInsurers" :job="job" :fieldsDisabled="fieldsDisabled"/>
        </el-row>
        <el-row class="claim-page-row top-cards-row" :gutter="16" type="flex">
          <Vehicle ref="vehicle" :updateSchemaLabelsWithMatchingAndSubrogation="updateSchemaLabelsWithMatchingAndSubrogation" :lossNotice="lossNotice" :vehicle="vehicle" :fieldsDisabled="fieldsDisabled"/>
          <AdditionalInfo ref="additionalInfo" :updateSchemaLabelsWithMatchingAndSubrogation="updateSchemaLabelsWithMatchingAndSubrogation" :lossNotice="lossNotice" :shop="shop" :fieldsDisabled="fieldsDisabled"/>
        </el-row>
        <DamageInfo :damageInformationArray="damageInformationArray" :fieldsDisabled="fieldsDisabled" :removeDamageInformation="removeDamageInformation"/>
        <div class="t-py-3 t-px-6 t-flex t-border-0 t-border-t t-border-solid">
          <div v-if="submittingClaim" class="claimSubmitButton">Submitting Claim...
            <img style="width:18px;height:18px;margin-right:6px;" src='../../../../assets/img/spinner.gif'>
          </div>
          <el-button v-if="showSubmitResubmitButton" type="primary" @click="submitClaim" class="claimSubmitButton" >
            {{ glaxisClaim && glaxisClaim.id > 0 ? 'Resubmit' : 'Submit' }}
          </el-button>
        </div>
    </div>

    <el-dialog
      :visible.sync='confirmPopup'
      :appendToBody="true"
      >
      <el-row class='dialog-header-text'>
          {{ confirmPopupType === 'submitClaim' ? 'Submit Claim' :
             confirmPopupType === 'removeDamageInformation' ? 'Remove Damage Information' :
             ''
          }}
      </el-row>
      <br/>
      <el-row class='dialog-body-text'>
        {{ confirmPopupType === 'submitClaim' ? 'Are you sure you would like to submit this claim?' :
            confirmPopupType === 'removeDamageInformation' ? 'Are you sure you want to delete this damage information?' :
            ''
        }}
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleConfirmPopup">Yes</el-button>
        <el-button @click="confirmPopup = false">No</el-button>
      </span>
    </el-dialog>
    <el-dialog
      @close="closeAlertPopupEvent"
      :visible.sync='alertPopup'
      :appendToBody="true"
      >
      <el-row class='dialog-body-text'>{{ alertPopupText }}</el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="alertPopup = false">Ok</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import JobDetailsClaimDataPlugin from '@/components/modals/GlaxisDialogs/claim/plugins/job-details-claim-data.plugin'
import AdditionalInfo from '@/components/modals/GlaxisDialogs/claim/AdditionalInfo'
import Insurance from '@/components/modals/GlaxisDialogs/claim/Insurance'
import InsuredCustomer from '@/components/modals/GlaxisDialogs/claim/InsuredCustomer'
import Vehicle from '@/components/modals/GlaxisDialogs/claim/Vehicle'
import DamageInfo from '@/components/modals/GlaxisDialogs/claim/DamageInfo'
import ClaimStatus from '@/components/modals/GlaxisDialogs/claim/components/ClaimStatus'

import GlaxisLossNotice from '@/scripts/objects/glaxis_loss_notice'
import GlaxisInsurer from '@/scripts/objects/glaxis_insurer'
import GlaxisClaim from '@/scripts/objects/glaxis_claim'

import { sync } from 'vuex-pathify'
import Vue from 'vue'
import { getConsumerName, formatPhone } from '@/scripts/helpers/consumer.helpers'
import GlaxisLossDamageInfo from '@/scripts/objects/glaxis_loss_damage_info'

export default {
  components: {
    AdditionalInfo,
    Insurance,
    InsuredCustomer,
    Vehicle,
    DamageInfo,
    ClaimStatus
  },
  props: [],
  data () {
    return {
      loading: false,
      lossNotice: new GlaxisLossNotice(),
      supportedInsurers: [],
      damageInformationArray: [new GlaxisLossDamageInfo()],
      glaxisClaim: null,
      submittingClaim: false,
      confirmPopup: false,
      confirmPopupType: '',
      closePopupOnAlertClose: false,
      alertPopup: false,
      alertPopupText: '',
      removeDamageInformationIndex: -1,
      loadClaimAfterJobLoads: false,
      showUpdateToMatchJobButton: false
    }
  },
  computed: {
    jobLoading: sync('glaxis/loading@job'),
    syncJobId: sync('glaxis/claim@jobId'),
    visible: sync('glaxis/dialogs@claim'),
    isLive () {
      return process.env.NODE_ENV === 'production'
    },
    hasJobMixin() {
      return this.$job && this.$job.job && this.$job.job.id === this.syncJobId
    },
    loadedJobId() {
      return this.job && this.job.id + '' === this.syncJobId + '' ? this.job.id : -1
    },
    job() {
      if (this.hasJobMixin) {
        return this.$job.job
      } else {
        return this?.$claimJobDetails?.job
      }
    },
    customer() {
      if (this.hasJobMixin) {
        return this.$job.customer
      } else {
        return this?.$claimJobDetails?.customer
      }
    },
    shop() {
      if (this.hasJobMixin) {
        return this.$job.shop
      } else {
        return this?.$claimJobDetails?.shop
      }
    },
    vehicle() {
      if (this.hasJobMixin) {
        return this.$job.vehicle
      } else {
        return this?.$claimJobDetails?.vehicle
      }
    },
    billTo() {
      if (this.hasJobMixin) {
        return this.$job.billTo
      } else {
        return this?.$claimJobDetails?.billTo
      }
    },
    fieldsDisabled() {
      return !this.showSubmitResubmitButton
    },
    claimStatusColor() {
      if (this.glaxisClaim && this.glaxisClaim.status === 'Success') {
        return 'green'
      } else if (this.glaxisClaim && this.glaxisClaim.status === 'Error') {
        return 'red'
      } else {
        return 'orange'
      }
    },
    showSubmitResubmitButton() {
      return !this.submittingClaim && (!this.glaxisClaim || (this.glaxisClaim.status !== 'Success' && this.glaxisClaim.status !== 'Coverage Response Received' && this.glaxisClaim.status !== 'Loss Notice Acknowledgement Submitted'))
    },
    subrogationFields () {
      if (this.lossNotice.cause_of_loss_code === '105') {
        return {
          SubrogationData: 'Subrogation Data: Fault License #',
          SubrogationContactName: 'Driver\'s Name (if available)'
        }
      } else if (this.lossNotice.cause_of_loss_code === '111') {
        return {
          SubrogationData: 'Subrogation Data: Type of Animal',
          SubrogationContactName: null
        }
      } else if (this.lossNotice.cause_of_loss_code === '199') {
        return {
          SubrogationData: 'Subrogation Data: Description of Object',
          SubrogationContactName: 'Person\'s Name (if available/applicable)'
        }
      } else if (this.lossNotice.cause_of_loss_code === '201') {
        return {
          SubrogationData: 'Subrogation Data: Claim Number (if available) and Contact Info',
          SubrogationContactName: 'Driver\'s Name (if available)'
        }
      } else if (this.lossNotice.cause_of_loss_code === '301') {
        return {
          SubrogationData: 'Subrogation Data: Contact Info',
          SubrogationContactName: 'Person\'s Name (if available)'
        }
      } else if (this.lossNotice.cause_of_loss_code === '341') {
        return {
          SubrogationData: 'Subrogation Data: Contact Info (e.g. policy report)',
          SubrogationContactName: 'Person\'s Name (if available)'
        }
      } else if (this.lossNotice.cause_of_loss_code === '499') {
        return {
          SubrogationData: 'Subrogation Data: Type of Storm (e.g. lightning, wind, sand, etc)',
          SubrogationContactName: null
        }
      }
      return {
        SubrogationData: null,
        SubrogationContactName: null
      }
    }
  },
  methods: {
    dialogAboutToAppear () {
      var preserveNonJobData = false
      console.log('current glaxis claim: ', (this.glaxisClaim ? this.glaxisClaim.job_id : null))
      console.log('current syncJobId: ', this.syncJobId)

      if (this.glaxisClaim && this.syncJobId + '' !== this.glaxisClaim.job_id + '') {
        console.log('resetting')
        this.lossNotice = new GlaxisLossNotice()
        this.damageInformationArray = [new GlaxisLossDamageInfo()]
        this.glaxisClaim = null
      } else {
        if (this.hasJobMixin) {
          preserveNonJobData = true
        }
      }

      console.log('at a with this.loadClaimAfterJobLoads: ', this.loadClaimAfterJobLoads)
      if (!this.hasJobMixin && this.$claimJobDetails && this.$claimJobDetails.jobId !== this.syncJobId) {
        this.loadClaimAfterJobLoads = true
        console.log('getting loading a')
        console.log('a loss notice id: ', this.lossNotice.id)
        this.loading = true
        this.$claimJobDetails.jobId = this.syncJobId
        const self = this
        this.$nextTick(() => {
          self.loadInsurerAndGlaxisLossNotice(preserveNonJobData)
        })
      } else {
        this.loadInsurerAndGlaxisLossNotice(preserveNonJobData)
      }
    },
    loadInsurerAndGlaxisLossNotice(preserveNonJobData) {
      const self = this
      console.log('check for loaded id')
      console.log('b loss notice id: ', this.lossNotice.id)

      if (self.loadedJobId > 0) {
        console.log('check for loaded id found')
        self.loadClaimAfterJobLoads = false
      } else {
        console.log('check for loaded id NOT found')
      }
      if (this.supportedInsurers.length === 0) {
        self.loading = true
        GlaxisInsurer.getInsurerIds(
          res => {
            self.supportedInsurers = res
            self.loadGlaxisLossNotice()
          }, err => {
            console.log('insurer error: ', err)
          })
      } else {
        self.loadGlaxisLossNotice(preserveNonJobData)
      }
    },
    reloadClaim () {
      this.loadGlaxisLossNotice()
    },
    loadGlaxisLossNotice(preserveNonJobData) {
      const self = this
      console.log('got loaded job id: ', self.loadedJobId)
      if (self.loadedJobId > 0) {
        self.loading = true
        console.log('getting claim for job')
        GlaxisClaim.getForJob(self.loadedJobId, (response) => {
          self.loading = false // must be before the populate method so refs are not null

          if (response != null && response.glaxisClaim) {
            self.glaxisClaim = new GlaxisClaim(response.glaxisClaim)
            this.$store.dispatch('glaxis/addUpdateJobClaimStatuses', {
              jobId: self.loadedJobId,
              status: self.glaxisClaim.status
            })
          } else {
            if (!preserveNonJobData) {
              self.glaxisClaim = new GlaxisClaim()
              self.glaxisClaim.job_id = self.loadedJobId
            }
          }

          if (response != null && response.lossNoticeData && response.lossNoticeData.lossNotice) {
            self.lossNotice = new GlaxisLossNotice(response.lossNoticeData.lossNotice)
            if (self.lossNotice.origination_pid === '1000' || self.lossNotice.origination_pid === '6000') {
              self.lossNotice.destination_pid = self.lossNotice.origination_pid
            }
            if (self.lossNotice.insured_phone_number && self.lossNotice.insured_phone_number.length > 0) {
              self.lossNotice.insured_phone_number = formatPhone(self.lossNotice.insured_phone_number)
            }

            if (self.lossNotice.insured_alternate_phone_number && self.lossNotice.insured_alternate_phone_number.length > 0) {
              self.lossNotice.insured_alternate_phone_number = formatPhone(self.lossNotice.insured_alternate_phone_number)
            }

            self.damageInformationArray = []
            for (var i = 0; i < response.lossNoticeData.lossDamageInfo.length; i++) {
              var glaxisLossDamageInfo = new GlaxisLossDamageInfo(response.lossNoticeData.lossDamageInfo[i])
              self.damageInformationArray = [glaxisLossDamageInfo]
            }
          } else {
            if (!preserveNonJobData) {
              self.lossNotice = new GlaxisLossNotice()
              self.damageInformationArray = [new GlaxisLossDamageInfo()]
            }
            self.$nextTick(() => {
              self.populateGlaxisLossNotice(preserveNonJobData)
            })
          }
        }, (error) => {
          console.log('got error: ', error)
        })
      } else {
        console.log('not getting claim for job')
      }
    },
    dialogDisappeared () {
      this.closePopupOnAlertClose = false
      this.$claimJobDetails.jobId = null
    },
    getAdditionalLabelText(lossNoticeField, expectedValue) {
      if (expectedValue) {
        var firstField = this.lossNotice[lossNoticeField] ? (this.lossNotice[lossNoticeField] + '').toLowerCase() : ''
        var secondField = expectedValue ? (expectedValue + '').toLowerCase() : ''
        if (firstField !== secondField) {
          this.showUpdateToMatchJobButton = true
          return `(Job Set To: ${expectedValue})`
        }
      }
      return ''
    },
    updateSchemaLabelsWithMatchingAndSubrogation(schema) {
      // update labels to show job differences and add subrogation data
      for (var i = 0; i < schema.fields.length; i++) {
        for (var j = 0; j < schema.fields[i].length; j++) {
          if (schema.fields[i][j].id === 'cause_of_loss_code') {
            var subrogationFields = []
            if (this.subrogationFields.SubrogationData != null) {
              var subrogationData = {
                label: this.subrogationFields.SubrogationData,
                id: 'subrogation_data',
                componentType: 'inputWithLabel',
                span: this.rowSpanTwo,
                disabled: this.fieldsDisabled,
                maxlength: 40
              }
              subrogationFields.push(subrogationData)
            }

            if (this.subrogationFields.SubrogationContactName != null) {
              subrogationFields.push({
                label: this.subrogationFields.SubrogationContactName,
                id: 'subrogation_contact_name',
                componentType: 'inputWithLabel',
                span: this.rowSpanTwo,
                disabled: this.fieldsDisabled,
                maxlength: 40
              })
            }

            if (subrogationFields.length > 0) {
              schema.fields.splice(i + 1, 0, subrogationFields)
            }
          }

          var additionalLabelText = this.getAdditionalLabelText(schema.fields[i][j].id, schema.fields[i][j].lossNoticeDefault)
          if (additionalLabelText.length > 0) {
            schema.fields[i][j].label = `${schema.fields[i][j].label} ${additionalLabelText}`
            schema.fields[i][j].invalid = true
          }
        }
      }
      return schema
    },
    populateWithLossNoticeDefaults(schema) {
      for (var i = 0; i < schema.fields.length; i++) {
        for (var j = 0; j < schema.fields[i].length; j++) {
          if (schema.fields[i][j].lossNoticeDefault) {
            this.setLossNoticeValueWithLimit(schema.fields[i][j].id, schema.fields[i][j].lossNoticeDefault, (schema.fields[i][j].lossNoticeLimit && schema.fields[i][j].lossNoticeLimit > 0) ? schema.fields[i][j].lossNoticeLimit : -1)
          }
        }
      }
    },
    populateGlaxisLossNotice(preserveNonJobData) {
      this.showUpdateToMatchJobButton = false
      if (!preserveNonJobData) {
        this.$set(this, 'lossNotice', new GlaxisLossNotice())
      }
      var supportedInsurerId = -1
      for (var i = 0; i < this.supportedInsurers.length; i++) {
        if (getConsumerName(this.billTo).toLowerCase() === this.supportedInsurers[i].name.toLowerCase()) {
          supportedInsurerId = this.supportedInsurers[i].insurer_id
          break
        } else if (getConsumerName(this.billTo).toLowerCase().includes(this.supportedInsurers[i].name.toLowerCase())) {
          supportedInsurerId = this.supportedInsurers[i].insurer_id
        }
      }

      this.setLossNoticeValueWithLimit('shop_id', this?.job?.shop_id, -1) // make string due to RequiredSelect validation
      this.setLossNoticeValueWithLimit('destination_pid', '1000', -1)
      if (!preserveNonJobData) {
        this.setLossNoticeValueWithLimit('insurance_company_id_code', supportedInsurerId + '', -1)
      }
      this.setLossNoticeValueWithLimit('insured_first_name', this.customer?.name?.first, 20)
      this.setLossNoticeValueWithLimit('insured_last_name', this.customer?.name?.last, 20)

      this.populateWithLossNoticeDefaults(this.$refs.insuredCustomer.schema)
      this.populateWithLossNoticeDefaults(this.$refs.insurance.schema)
      this.populateWithLossNoticeDefaults(this.$refs.vehicle.schema)
      this.populateWithLossNoticeDefaults(this.$refs.additionalInfo.schema)

      this.setLossNoticeValueWithLimit('state_policy_written', this.customer?.address?.state, -1)
      this.setLossNoticeValueWithLimit('vin_number', this.vehicle?.vin, -1)
      this.setLossNoticeValueWithLimit('vehicle_make_text', this.vehicle?.make, 20)
      this.setLossNoticeValueWithLimit('vehicle_make', this.vehicle?.make_id, -1)
      this.setLossNoticeValueWithLimit('vehicle_model_text', this.vehicle?.model, 20)
      this.setLossNoticeValueWithLimit('vehicle_model', this.vehicle?.model_id, -1)
      this.setLossNoticeValueWithLimit('vehicle_style_text', this.vehicle?.style, 25)
      this.setLossNoticeValueWithLimit('vehicle_style', this.vehicle?.style_id, -1)
      this.setLossNoticeValueWithLimit('vehicle_sub_model', this.vehicle?.sub_model_id, -1)
      this.setLossNoticeValueWithLimit('vehicle_year_text', this.vehicle?.year, 4)
      this.setLossNoticeValueWithLimit('vehicle_year', this.vehicle?.year, 4)
      this.setLossNoticeValueWithLimit('manual_vehicle_entry', this.vehicle?.manual != null ? this.vehicle?.manual : 0, -1)
      this.setLossNoticeValueWithLimit('vehicle_sub_model_text', this.vehicle?.sub_model, -1)

      this.setLossNoticeValueWithLimit('vehicle_thumb_url', this.vehicle?.thumb_url, -1)
      this.setLossNoticeValueWithLimit('vehicle_image_url', this.vehicle?.image_url, -1)
      this.setLossNoticeValueWithLimit('vehicle_plate_number', this.vehicle?.plate_number, -1)
      this.setLossNoticeValueWithLimit('vehicle_state', this.vehicle?.state, -1)
      this.setLossNoticeValueWithLimit('vehicle_id_source', this.vehicle?.nags_id && this.vehicle?.nags_id > 0 ? 'NAGS' : '', -1)
      this.setLossNoticeValueWithLimit('vehicle_id_number', this.vehicle?.nags_id, 10)
      if (!preserveNonJobData) {
        this.setLossNoticeValueWithLimit('type_of_vehicle', 'STANDARD', -1)
        this.setLossNoticeValueWithLimit('cause_of_loss_code', '', -1)
        this.setLossNoticeValueWithLimit('subrogation_data', '', -1)
        this.setLossNoticeValueWithLimit('bodily_injury', 'N', -1)
      }
    },
    setLossNoticeValueWithLimit(field, value, limit) {
      var finalValue = value
      if (limit > 0 && (finalValue + '') > limit) {
        finalValue = (finalValue + '').substring(0, limit)
      }
      this.$set(this.lossNotice, field, finalValue) // make string due to RequiredSelect validation
    },
    prefillWithTestData() {
      this.lossNotice.insurance_company_id_code = '6813444'

      this.lossNotice.type_of_vehicle = 'STANDARD'
      this.lossNotice.vehicle_ownership = 'Owner'
      this.lossNotice.vehicle_number = '1'

      this.lossNotice.state_policy_written = 'VA'
      this.lossNotice.agency_name = 'John Smith Agency'
      this.lossNotice.agent_first_name = 'John'
      this.lossNotice.agent_last_name = 'Smith'
      this.lossNotice.agent_phone_number = '800-555-1212'
      this.lossNotice.insurer_claim_number = ''
      this.lossNotice.glass_only_loss = 'Y'
      this.lossNotice.cause_of_loss_code = '105'
      this.lossNotice.subrogation_data = 'License: 456-123X'
      this.lossNotice.subrogation_contact_name = 'John Doe'
      this.lossNotice.origination_contact_person = 'Test User'
      this.lossNotice.bodily_injury = 'N'

      this.lossNotice.insured_street_address = '311 Glenwood Ave'
      this.lossNotice.insured_city = 'Raleigh'
      this.lossNotice.insured_state_province = 'VA'
      this.lossNotice.insured_postal_code = '23221'

      this.damageInformationArray = []
      var glaxisLossDamageInfo = new GlaxisLossDamageInfo()
      glaxisLossDamageInfo.location_of_damage = 'WR'
      glaxisLossDamageInfo.position_on_vehicle = 'R'
      glaxisLossDamageInfo.relative_position_on_vehicle = ''
      glaxisLossDamageInfo.side_of_vehicle = 'L'
      glaxisLossDamageInfo.quantity_of_glass_for_loss = '1'
      glaxisLossDamageInfo.glass_only_loss = 'Y'

      this.damageInformationArray = [glaxisLossDamageInfo]

      /*
      this.lossNotice.insured_first_name = 'Full'

      this.lossNotice.insured_last_name = 'cat46'
      this.lossNotice.insured_policy_number = 'CSR2980A4046'

      this.lossNotice.vin_number = '1GKEV18K5LF528402'
      this.lossNotice.vehicle_year_text = '1990'
      this.lossNotice.vehicle_make_text = 'GMC'
      this.lossNotice.vehicle_model_text = 'Jimmy'
      this.lossNotice.vehicle_style_text = '2 Door Utility'

      this.setVehicleSelection(1990, 16, 274, 0, 259)

      this.lossNotice.vehicle_id_number = '2204'
      this.lossNotice.vehicle_id_source = 'NAGS'

      this.lossNotice.insured_phone_number = '800-555-1212'
      this.lossNotice.insured_alternate_phone_number = '800-555-1212'

      this.lossNotice.date_of_loss = '2020-10-06'
      */
    },
    submitClaim() {
      if (this.submittingClaim) {
        return
      }
      if (this.lossNotice.shop_id <= 0) {
        this.showAlertPopup('Please select a shop for the job.', true)
        return
      }

      var fieldsToCheck = [
        [this.lossNotice.destination_pid, 'Please select a Trading Partner.'],
        [this.lossNotice.insurance_company_id_code, 'Please select an Insurance Company under Insurance.'],
        [this.lossNotice.insured_first_name, 'Please go back and edit the job to fill in the insured customer\'s first name under Customer.'],
        [this.lossNotice.insured_last_name, 'Please go back and edit the job to fill in the insured customer\'s last name under Customer.'],
        // [this.lossNotice.vehicle_id_number, 'Please enter in the NAGS or other Vehicle Identification Number (or look up the VIN Number or fill in the Vehicle Quick Entry to have this field auto-fill in).'],
        // [this.lossNotice.vehicle_id_source, 'Please select the Source of the Vehicle ID.'],
        [this.lossNotice.insured_policy_number, 'Please go back and edit the job to fill in the policy number under Bill To.'],
        [this.lossNotice.date_of_loss, 'Please go back and edit the job to fill in the loss date under Bill To.'],
        [this.lossNotice.glass_only_loss, 'Please select if this was a Glass Only Loss under Additional Information.'],
        [this.lossNotice.cause_of_loss_code, 'Please select a Cause of Loss under Additional Information.'],
        [this.lossNotice.origination_contact_person, 'Please fill in the Contact Person At the Origination of the Transaction under Additional Information.'],
        [this.lossNotice.bodily_injury, 'Please select if there was a Bodily Injury under Additional Information.']
      ]
      for (var i = 0; i < fieldsToCheck.length; i++) {
        if (!fieldsToCheck[i][0] || fieldsToCheck[i][0].length === 0) {
          this.showAlertPopup(fieldsToCheck[i][1], false)
          return
        }
      }

      for (i = 0; i < this.damageInformationArray.length; i++) {
        var damageInformation = this.damageInformationArray[i]

        fieldsToCheck = [
          [damageInformation.location_of_damage, 'Please select a Location of Damage, opening on Vehicle in the Damage Information area.'],
          [damageInformation.quantity_of_glass_for_loss, 'Please select a Quantity of Glass For Loss in the Damage Information area.'],
          [damageInformation.glass_only_loss, 'Please select a Retailer Qualificiation of Repair Option in the Damage Information area.']
        ]
        for (var j = 0; j < fieldsToCheck.length; j++) {
          if (!fieldsToCheck[j][0] || fieldsToCheck[j][0].length === 0) {
            this.showAlertPopup(fieldsToCheck[j][1], false)
            return
          }
        }
      }

      this.showConfirmPopup('submitClaim')
    },
    handleConfirmPopup () {
      if (this.confirmPopupType === 'submitClaim') {
        this.finishSubmitClaim()
      } else if (this.confirmPopupType === 'removeDamageInformation') {
        this.finishRemoveDamageInformation()
      }
    },
    showConfirmPopup (type) {
      this.confirmPopup = true
      this.confirmPopupType = type
    },
    showAlertPopup (alertPopupText, closePopupOnAlertClose) {
      this.closePopupOnAlertClose = closePopupOnAlertClose
      try {
        if (this.$job) {
          this.$job.loadClaimDetails(false)
        }
      } catch (err) {
        console.log('err with loading claim popup: ', err)
      }

      this.alertPopupText = alertPopupText
      this.alertPopup = true
    },
    closeAlertPopupEvent() {
      if (this.closePopupOnAlertClose) {
        this.visible = false
        this.$root.$emit('refreshGlaxisDialogTable')
      }
    },
    removeDamageInformation (index) {
      this.removeDamageInformationIndex = index
      this.showConfirmPopup('removeDamageInformation')
    },
    finishRemoveDamageInformation () {
      this.confirmPopup = false
      this.damageInformationArray.splice(this.removeDamageInformationIndex, 1)
      this.removeDamageInformationIndex = -1
    },
    reviewCoverage () {
      this.$store.dispatch('glaxis/openReviewCoverageDialog', this.glaxisClaim.id)
    },
    updateToMatchJob () {
      this.populateGlaxisLossNotice(true)
    },
    finishSubmitClaim () {
      if (this.submittingClaim) {
        return
      }

      this.confirmPopup = false
      const self = this

      if (self.subrogationFields.SubrogationData == null) {
        self.lossNotice.subrogation_data = ''
      }
      if (self.subrogationFields.SubrogationContactName == null) {
        self.lossNotice.subrogation_contact_name = ''
      }

      self.submittingClaim = true
      GlaxisLossNotice.submitLossNotice(self.glaxisClaim && self.glaxisClaim.id > 0 ? self.glaxisClaim.id : -1, self.lossNotice, self.damageInformationArray, this.loadedJobId, (response) => {
        setTimeout(() => {
          self.submittingClaim = false
          if (response.result === 'success') {
            self.showAlertPopup('The claim has been submitted. Please check back later for a response.', true)
          } else {
            self.showAlertPopup(response.message, false)
          }
        }, 3000)
      }, (error) => {
        console.log('got submit error: ', error)
        self.showAlertPopup('Something went wrong. Please verify your data and try again.', false)
        self.submittingClaim = false
      })
    }
  },
  created () {
    Vue.use(JobDetailsClaimDataPlugin)
    this.$root.$on('refreshGlaxisClaim', this.loadGlaxisLossNotice)
  },
  beforeDestroy: function () {
    this.$root.$off('refreshGlaxisClaim', this.loadGlaxisLossNotice)
  },
  watch: {
    'lossNotice.cause_of_loss_code': {
      handler(newVal, oldVal) {
        if (!this.glaxisClaim || this.glaxisClaim.id <= 0) {
          if (this.subrogationFields.SubrogationData == null) {
            this.lossNotice.subrogation_data = ''
          }

          if (this.subrogationFields.SubrogationContactName == null) {
            this.lossNotice.subrogation_contact_name = ''
          }
        }
      }
    },
    jobLoading: {
      handler(newVal, oldVal) {
        console.log('job loading: ', newVal)
        if (!newVal) {
          const self = this
          self.$nextTick(() => {
            if (this.loadClaimAfterJobLoads) {
              self.loadInsurerAndGlaxisLossNotice(false)
            }
          })
        }
      }
    }
  }
}
</script>

<style>
.claim-page-row {
  margin-bottom:15px;
}

.jobDetailsClaimDialog .el-dialog__body {
  padding-top: 0px;
}

.claimInfoArea {
  padding-bottom:10px;
}

.claimSubmitButton {
  margin-right: 0;
  margin-bottom: 5px;
  margin-left:auto;
  display:block;
}

.prefillTestData {
  display:block;
  margin-top:10px;
}

.actionButtonsDiv {
  margin-right: 0;
  margin-bottom: 5px;
  margin-left:auto;
  display:block;
}

.updateToMatchJobButton {
  float: right;
  margin-right:10px;
}

.refreshButton {
  float: right;
}

.reviewCoverageButton, .reviewCoverageButton:visited {
  background-color: orange;
  color:white;
  display:block;
  border-color: #ffffff;
}
.reviewCoverageButton:hover, .reviewCoverageButton:focus {
  background-color: #ffdc5c;
  color:white;
  display:block;
  border-color: #ffffff;
}

</style>
