var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    { attrs: { prop: "typeId" } },
    [
      _c(
        "span",
        {
          staticClass: "undo-label-styles",
          attrs: { slot: "label" },
          slot: "label"
        },
        [_vm._v("Customer")]
      ),
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: { "value-key": "id" },
          model: {
            value: _vm.selectedConsumer,
            callback: function($$v) {
              _vm.selectedConsumer = $$v
            },
            expression: "selectedConsumer"
          }
        },
        _vm._l(_vm.consumers, function(consumer) {
          return _c(
            "el-option",
            {
              key: consumer.id,
              attrs: { value: consumer, label: consumer.name }
            },
            [
              _vm._v(
                " " +
                  _vm._s(consumer.name) +
                  " ($" +
                  _vm._s(consumer.amount.toFixed(2)) +
                  ") "
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }