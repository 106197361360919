var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", { attrs: { data: _vm.data } }, [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("QuickBooks Export Error")
    ]),
    _c("div", [
      _vm._v(
        " An error occured during a QuickBooks export for " +
          _vm._s(_vm.data.details.shopName) +
          ". "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }