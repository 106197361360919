<template>
  <div class="t-mb-3 t-pr-4">
    <div class="t-font-medium t-text-gray-400 t-text-xs">
      {{ label }}
    </div>
    <span
      v-if="value"
      class="t-text-gray-600 t-break-normal"
      :class="{
        't-font-bold': label === 'Item number' || label === 'Name'
      }"
    >
      {{ value }}
    </span>
    <span v-else class="t-font-light t-italic t-text-gray-600">
      -
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String
  }
}
</script>
