var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "80px",
        width: "1200px",
        "custom-class": "blue-header-dialog",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-base t-text-white",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-document" }),
          _c("span", { staticClass: "t-ml-3" }, [
            _vm._v("Purchase Order details")
          ])
        ]
      ),
      _vm.fetching
        ? _c("LoadingSpinner")
        : _c(
            "div",
            {
              staticClass: "t-pt-3 t-pb-8 t-px-8 t-bg-gray-200 drop-wrapper",
              class: {
                "dragging-over": _vm.dragging
              },
              on: {
                drop: _vm.onDrop,
                dragleave: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragEnter($event)
                },
                dragenter: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragLeave($event)
                }
              }
            },
            [
              _c("div", { staticClass: "t-mb-3 t-flex t-justify-between" }, [
                _c(
                  "div",
                  [
                    _c("documents", {
                      attrs: {
                        files: _vm.dropFiles,
                        type: _vm.documentType,
                        id: _vm.data.id
                      },
                      on: {
                        "clear-files": function($event) {
                          _vm.dropFiles = null
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "min-content",
                        attrs: {
                          size: "small",
                          type: "warning",
                          icon: "el-icon-edit"
                        },
                        on: { click: _vm.openUpdateDialog }
                      },
                      [_vm._v(" Edit ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "min-content",
                        attrs: {
                          icon: "el-icon-download",
                          type: "warning",
                          size: "small",
                          loading: !!_vm.loading
                        },
                        on: {
                          click: function($event) {
                            return _vm.getPdf(true)
                          }
                        }
                      },
                      [_vm._v(" Download ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "min-content",
                        attrs: {
                          icon: "el-icon-printer",
                          type: "warning",
                          size: "small",
                          loading: !!_vm.loading
                        },
                        on: {
                          click: function($event) {
                            return _vm.getPdf(false)
                          }
                        }
                      },
                      [_vm._v(" Print ")]
                    ),
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "t-mx-2",
                        on: { command: _vm.handleCommand }
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "small" } },
                          [
                            _vm._v(" Actions"),
                            _c("i", {
                              staticClass:
                                "el-icon-arrow-down el-icon--right t-ml-3"
                            })
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "createReceive" } },
                              [_vm._v(" Create Receive ")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "createReturn" } },
                              [_vm._v(" Create Return ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "min-content",
                        attrs: {
                          icon: "el-icon-delete",
                          type: "danger",
                          size: "small"
                        },
                        on: { click: _vm.remove }
                      },
                      [_vm._v(" Delete ")]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "t-bg-white t-rounded t-shadow t-px-8 t-py-3" },
                [
                  _c(
                    "div",
                    { staticClass: "t-flex t-items-center t-pt-4 t-pb-2" },
                    [
                      _vm.shop.logo.length > 0
                        ? _c("div", { staticClass: "t-w-56 t-mr-8" }, [
                            _c("img", {
                              staticClass: "t-w-full",
                              attrs: { src: _vm.shop.logo, alt: "shop logo" }
                            })
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "t-flex-grow t-text-right" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-text-lg t-font-medium t-text-gray-700 t-mb-2"
                          },
                          [_vm._v(" " + _vm._s(_vm.shop.name) + " ")]
                        ),
                        _c("div", { staticClass: "t-text-gray-600" }, [
                          _vm._v(_vm._s(_vm.shop.address))
                        ]),
                        _c("div", { staticClass: "t-text-gray-600" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.shop.address_city) +
                              ", " +
                              _vm._s(_vm.shop.address_state) +
                              " " +
                              _vm._s(_vm.shop.address_zip) +
                              " "
                          )
                        ])
                      ])
                    ]
                  ),
                  _c("el-divider"),
                  _c(
                    "div",
                    {
                      staticClass:
                        "t-text-center t-text-lg t-text-gray-700 t-pb-10"
                    },
                    [
                      _vm._v(" Purchase Order "),
                      _c("span", { staticClass: "t-ml-2" }, [
                        _vm._v("#" + _vm._s(_vm.data.internalId))
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "t-flex t-pb-8" }, [
                    _c(
                      "div",
                      { staticClass: "t-flex-grow" },
                      [
                        _c("InfoRow", {
                          attrs: { name: "Ref", value: _vm.data.ref }
                        }),
                        _c("InfoRow", {
                          attrs: {
                            name: "Date",
                            value:
                              _vm.data.date && _vm.formatDate(_vm.data.date)
                          }
                        }),
                        _c("InfoRow", {
                          attrs: {
                            name: "Supplier",
                            bold: true,
                            value: _vm.data.supplier.name
                          }
                        }),
                        _c("InfoRow", {
                          attrs: {
                            link: true,
                            name: "",
                            value: _vm.data.supplier.address
                          },
                          on: { click: _vm.openAddress }
                        }),
                        _c("InfoRow", {
                          attrs: {
                            name: "",
                            value:
                              (_vm.data.supplier && _vm.data.supplier.phone
                                ? "p " + _vm.data.supplier.phone + " "
                                : "") +
                              (_vm.data.supplier && _vm.data.supplier.fax
                                ? "f " + _vm.data.supplier.fax
                                : "")
                          }
                        }),
                        _c("InfoRow", {
                          attrs: { name: "", value: _vm.data.supplier.email }
                        }),
                        _c("InfoRow", {
                          attrs: {
                            name: "Delivery date",
                            value:
                              _vm.data.deliveryDate &&
                              _vm.formatDate(_vm.data.deliveryDate)
                          }
                        }),
                        _c("InfoRow", {
                          attrs: {
                            name: "Delivery address",
                            value: _vm.deliveryAddress
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "t-flex t-items-baseline t-mb-2" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "t-w-48 t-text-right t-text-gray-500 t-text-xs"
                              },
                              [_vm._v(" Status ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "t-text-gray-700 t-ml-4" },
                              [
                                _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      type: _vm.data.isConfirmed
                                        ? "success"
                                        : "",
                                      effect: "plain",
                                      size: "mini"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.data.isConfirmed
                                            ? "Confirmed"
                                            : "Open"
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("StatusUpdatePopover", {
                                  attrs: {
                                    poId: _vm.id,
                                    isConfirmed: _vm.data.isConfirmed
                                  },
                                  on: { updated: _vm.onUpdated }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "t-bg-blue-400 t-w-56 t-flex t-flex-col t-items-center t-justify-center\n            t-text-white t-h-[144px]"
                      },
                      [
                        _c("div", { staticClass: "t-text-lg" }, [
                          _vm._v("Total amount")
                        ]),
                        _c("div", { staticClass: "t-text-3xl" }, [
                          _vm._v("$" + _vm._s(_vm.totalAmount))
                        ])
                      ]
                    )
                  ]),
                  _c("Table", {
                    attrs: { lines: _vm.data.lines, poId: _vm.data.id },
                    on: { "units-released": _vm.onUnitsReleased }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "t-flex t-justify-between t-mt-3 t-text-gray-500 t-italic"
                },
                [
                  _c("div", [
                    _vm._v(" Created on " + _vm._s(_vm.createdAt) + " "),
                    _vm.createdBy
                      ? _c("span", [_vm._v("by " + _vm._s(_vm.createdBy))])
                      : _vm._e()
                  ]),
                  _c("div", [
                    _vm._v(" Modified on " + _vm._s(_vm.updatedAt) + " "),
                    _vm.updatedBy
                      ? _c("span", [_vm._v("by " + _vm._s(_vm.updatedBy))])
                      : _vm._e()
                  ])
                ]
              ),
              _c("UpdateDialog", {
                attrs: {
                  visible: _vm.updateDialog.isOpen,
                  id: _vm.updateDialog.id,
                  shopId: _vm.data.shopId
                },
                on: {
                  "update:visible": function($event) {
                    return _vm.$set(_vm.updateDialog, "isOpen", $event)
                  },
                  updated: _vm.onUpdated
                }
              }),
              _c("CreateReceiveDialog", {
                attrs: {
                  visible: _vm.createReceiveDialogIsOpen,
                  shopId: _vm.shop.id,
                  poId: _vm.data.id,
                  poLines: _vm.data.lines
                },
                on: {
                  "update:visible": function($event) {
                    _vm.createReceiveDialogIsOpen = $event
                  },
                  created: _vm.getData
                }
              }),
              _c("CreateReturnDialog", {
                attrs: {
                  visible: _vm.createReturnDialogIsOpen,
                  poId: _vm.data.id,
                  poLines: _vm.data.lines
                },
                on: {
                  "update:visible": function($event) {
                    _vm.createReturnDialogIsOpen = $event
                  },
                  created: _vm.getData
                }
              }),
              _c("address-map-dialog", {
                model: {
                  value: _vm.addressForDialog,
                  callback: function($$v) {
                    _vm.addressForDialog = $$v
                  },
                  expression: "addressForDialog"
                }
              }),
              _c("a", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                ref: "dl",
                attrs: { href: _vm.pdfData, download: _vm.filename }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }