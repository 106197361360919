<template>
  <el-dialog
    width="900px"
    append-to-body
    :visible="visible"
    :before-close="close"
    custom-class="shopping-cart-dialog"
  >
    <span slot="title" class="t-text-xl t-text-gray-800">Shopping cart</span>
    <cart-header />
    <tabs :suppliers="suppliers" />
  </el-dialog>
</template>

<script>
import CartHeader from './Header'
import Tabs from './Tabs'
import { shoppingCartActions } from '@/store/modules/shopping-cart/types'

export default {
  components: {
    CartHeader,
    Tabs
  },

  data() {
    return {
      suppliers: []
    }
  },

  computed: {
    visible() {
      return this.$store.state.shoppingCart.isOpen
    },
    shops() {
      return this.$store.state.shops
    },
    shopId() {
      return this.$store.state.shoppingCart.shopId
    },
    shop() {
      return this.shops.find(i => i.id === this.shopId)
    }
  },

  watch: {
    visible: function (value) {
      if (value) {
        this.getShops()
        if (this.shopId) {
          this.getItems()
          this.getItemsCount()
          this.getSuppliers()
        }
      } else {
        this.clearSelectedItemIds()
      }
    },

    shopId: function (value) {
      if (value) {
        this.getSuppliers()
      }
    }
  },

  methods: {
    getShops() {
      return this.$store.dispatch(shoppingCartActions.getShops)
    },
    getItems() {
      return this.$store.dispatch(shoppingCartActions.getItems)
    },
    getItemsCount() {
      return this.$store.dispatch(shoppingCartActions.getVendorsItemsCount)
    },
    close() {
      return this.$store.dispatch(shoppingCartActions.close)
    },
    clearSelectedItemIds() {
      return this.$store.dispatch(shoppingCartActions.clearSelectedItemIds)
    },
    async getSuppliers() {
      try {
        const { data } = await this.$unum.vendor()
          .get(`organization/gb_supplier/${this.shop.organization_id}/details`)
        this.suppliers = data
      } catch {
        this.$message.error('Can\'t get a list of suppliers')
      }
    }
  }
}
</script>

<style lang="scss">
.shopping-cart-dialog {
  @apply t-font-sans;
  .el-dialog__header {
    @apply t-text-center;
  }

  .el-dialog__body {
    @apply t-p-0;
  }

  #place-order-form-comment {
    .el-form-item__content {
      @apply t-w-full;
    }
  }
}
</style>
