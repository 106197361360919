import schedulerActions from './scheduler.actions'
import schedulerInitialState from './scheduler.state'
import schedulerMutations from './scheduler.mutations'
import schedulerGetters from './scheduler.getters'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import {
  schedulerNamespace,
  schedulerMutations as mutationsTypes,
  schedulerActions as actionTypes
} from './scheduler.types'
import * as R from 'ramda'

const schedulerModule = {
  namespaced: true,
  state: schedulerInitialState,
  getters: schedulerGetters,
  mutations: schedulerMutations,
  actions: schedulerActions
}

export default schedulerModule

export const mapScheduler = {
  getters: getters => mapGetters(schedulerNamespace, getters),
  state: state => mapState(schedulerNamespace, state),
  mutations: mutations =>
    mapMutations(schedulerNamespace, R.pick(mutations)(mutationsTypes)),
  actions: actions => mapActions(schedulerNamespace, R.pick(actions)(actionTypes))
}
