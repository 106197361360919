
import Api from '@/services/Api'

export default class Token {
  static login (type, username, password, bumpUser, successCallback, errorCallback) {
    Api.post(`/api/login?type=` + encodeURIComponent(type) + '&username=' + encodeURIComponent(username) +
      '&password=' + encodeURIComponent(password) + '&bumpUser=' + encodeURIComponent(bumpUser)).then(res => {
      
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  static forgotPassword (email, successCallback, errorCallback) {
    Api.post(`/api/forgotPassword?email=` + encodeURIComponent(email)).then(res => {
      
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  static validateForgotPasswordLink (id, code, successCallback, errorCallback) {
    Api.post(`/api/validateForgotPassword?code=` + encodeURIComponent(code) + `&id=` + encodeURIComponent(id)).then(res => {
      
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  static updateForgotPassword (id, code, password, successCallback, errorCallback) {
    Api.post(`/api/updateForgotPassword?code=` + encodeURIComponent(code) +
      `&id=` + encodeURIComponent(id) +
      `&password=` + encodeURIComponent(password)).then(res => {
      
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }
}
