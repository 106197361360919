import { make } from 'vuex-pathify'
import Job from '@/scripts/objects/job'
import moment from 'moment'

const state = {
  visible: false,
  jobId: null,
  shopId: null,
  jobStatus: null,
  signatureLink: null,
  files: [],
  history: []
}

const mutations = make.mutations(state)

const getters = {
  formattedHistory: (state) => {
    let data = []
    for (const i of state.history) {
      let historyItem = {
        size: 'large',
        timestamp: moment(i.created_at).format('LLLL')
      }
      if (i.type_id && i.type_name) {
        historyItem.name = i.type_name + ' PDF was'
      } else {
        historyItem.name = 'Job PDF was'
      }

      if (i.username) {
        historyItem.name += ' sent by ' + i.username
      } else {
        historyItem.name += ' automatically generated'
      }
      historyItem.type = 'success'
      data.push(historyItem)
    }
    return data
  },

  status: (state) => {
    return state.history[state.history.length - 1]
  },

  invoicePdfWasSentBefore: (state) => {
    return state.history.length > 0
  }
}

const actions = {
  open ({ commit }) {
    commit('SET_VISIBLE', true)
  },
  setJobId ({ commit }, jobId) {
    commit('SET_JOB_ID', jobId)
  },
  setSignatureLink ({ commit }, signatureLink) {
    commit('SET_SIGNATURE_LINK', signatureLink)
  },
  setFiles ({ commit }, files) {
    commit('SET_FILES', files)
  },
  setShopId ({ commit }, shopId) {
    commit('SET_SHOP_ID', shopId)
  },
  setJobStatus ({ commit }, status) {
    commit('SET_JOB_STATUS', status)
  },
  async getHistory ({ commit }, jobId) {
    const history = await Job.getTextMessageInvoicePdfHistory(jobId)
    commit('SET_HISTORY', history)
  },
  clearHistory ({ commit }) {
    commit('SET_HISTORY', [])
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
