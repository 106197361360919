import Api from '@/services/Api'
import Part from '@/scripts/objects/part'
import * as R from 'ramda'

export const getAvailableUnits = (costingMethod, itemObject) => {
  var cost = 0.0
  var qty = 0
  // var subQty = 0
  var unreceivedAvailable = []
  var totalUnreceivedAvailable = 0
  var totalAvailable = 0
  var totalAvailableIncludingSubs = 0
  var totalSubsAvailable = 0
  var subsAvailable = []
  var idForCost = -1
  if (itemObject && itemObject.units) {
    for (var i = 0; i < itemObject.units.length; i++) {
      const unit = itemObject.units[i]
      let tempStatus = ''
      if (/*(unit.job_id && unit.job_id > 0 && unit.job.status !== 'invoice') ||*/ !unit.job_id && ((unit.receive_id && unit.adjustment_type !== 'down') || unit.adjustment_type === 'up')) {
        qty++
        totalAvailable++
        totalAvailableIncludingSubs++
        if ((idForCost < 0 && costingMethod !== 'avg') || ((costingMethod === 'fifo' && idForCost > unit.id) || (costingMethod === 'lifo' && idForCost < unit.id))) {
          idForCost = unit.id
          cost = unit.actual_cost
        }
        if (costingMethod === 'avg' && idForCost < 0) {
          cost = unit.average_cost
          idForCost = unit.id
        }
      } else if (!unit.job_id && !unit.receive_id && !unit.adjustment_type && unit.purchase_order_id && unit.purchase_order_part_id) {
        // let tempUnit = unit.purchase_order_id
        let foundPOUnit = -1
        for (var j = 0; j < unreceivedAvailable.length; j++) {
          if (unreceivedAvailable[j].purchase_order_part_id === unit.purchase_order_part_id) {
            foundPOUnit = j
          }
        }
        totalUnreceivedAvailable++
        totalAvailable++
        totalAvailableIncludingSubs++
        if (foundPOUnit >= 0) {
          unreceivedAvailable[foundPOUnit].qty++
        } else {
          unreceivedAvailable.push({...unit, qty: 1})
        }
      }
    }
  }
  // console.log(itemObject.item_substitutions.item)
  if (itemObject && itemObject.item_substitutions) {
    // console.group()
    idForCost = -1
    for (var l = 0; l < itemObject.item_substitutions.length; l++) {
      if (itemObject.item_substitutions[l].item && itemObject.item_substitutions[l].item.units) {
        let subForArray = {
          ...R.omit(['units'], itemObject.item_substitutions[l].item), 
          cost: 0,
          qty: 0, 
          totalQty: 0, 
          unreceivedQty: 0, 
          unreceivedAvailable: [],
        }
        for (var k = 0; k < itemObject.item_substitutions[l].item.units.length; k++) {
          const subUnit = itemObject.item_substitutions[l].item.units[k]
          if (/*(unit.job_id && unit.job_id > 0 && unit.job.status !== 'invoice') ||*/ !subUnit.job_id && ((subUnit.receive_id && subUnit.adjustment_type !== 'down') || subUnit.adjustment_type === 'up')) {
            // subQty++
            subForArray.qty++
            subForArray.totalQty++
            totalSubsAvailable++
            totalAvailableIncludingSubs++
            if ((idForCost < 0 && costingMethod !== 'avg') || ((costingMethod === 'fifo' && idForCost > subUnit.id) || (costingMethod === 'lifo' && idForCost < subUnit.id))) {
              idForCost = subUnit.id
              subForArray.cost = subUnit.actual_cost
            }
            if (costingMethod === 'avg' && idForCost < 0) {
              subForArray.cost = subUnit.average_cost
              idForCost = subUnit.id
            }
          } else if (!subUnit.job_id && !subUnit.receive_id && !subUnit.adjustment_type && subUnit.purchase_order_id && subUnit.purchase_order_part_id) {
            // let tempUnit = unit.purchase_order_id
            let foundPOUnit = -1
            for (var g = 0; g < subForArray.unreceivedAvailable.length; g++) {
              if (subForArray.unreceivedAvailable[g].purchase_order_part_id === subUnit.purchase_order_part_id) {
                foundPOUnit = g
              }
            }
            subForArray.unreceivedQty++
            subForArray.totalQty++
            totalSubsAvailable++
            totalAvailableIncludingSubs++
            if (foundPOUnit >= 0) {
              subForArray.unreceivedAvailable[foundPOUnit].qty++
            } else {
              subForArray.unreceivedAvailable.push({...subUnit, qty: 1})
            }
          }
        }
        if (subForArray && subForArray.totalQty) {
          subsAvailable.push(subForArray)
        }
      }
    }
    // console.groupEnd()
  }
  return {cost, qty, unreceivedAvailable, totalUnreceivedAvailable, totalAvailable, totalSubsAvailable, subsAvailable, totalAvailableIncludingSubs} 
}

export const isInventoryPart = (jobId, partId, itemObject) => {
  if (itemObject.units) {
    for (var i = 0; i < itemObject.units.length; i++) {
      let tempJob = itemObject.units[i].job
      if (itemObject.units[i].job && itemObject.units[i].job_id === jobId && partId === itemObject.units[i].job_part_id) return true
    }
  }
  return false
}

export const inventoryFromPart = (partnumber, items, costingMethod) => {
  if (items && items.length && partnumber && costingMethod) {
    for (var i = 0; i < items.length; i++) {
      if (items[i].number === partnumber) {
        return getAvailableUnits(costingMethod, items[i])
      }
    }
  }
  return {}
}

export const getInventoryFromParts = (shopId, partnumbers, callbackFunction, errorCallbackFunction = null) => {
  Api.post(`/api/shop/${shopId}/inventory`, {partnumbers: partnumbers}).then(res => {
    callbackFunction(res.data)
  }, error => {
    if (errorCallbackFunction) {
      errorCallbackFunction(error)
    }
  })
}

export const useFromInventory = (shopId, partData, typeToUse, callbackFunction, errorCallbackFunction = null) => {  
  Api.post(`/api/shop/${shopId}/useInventory/${typeToUse}`, {partData: partData instanceof Part ? partData.getStrippedObject : partData}).then(res => {
    callbackFunction(res.data)
  }, error => {
    if (errorCallbackFunction) {
      errorCallbackFunction(error)
    }
  })
}

export const releaseInventory = (shopId, partIds, jobId, callbackFunction, errorCallbackFunction) => {  
  Api.post(`/api/shop/${shopId}/releaseInventory`, {partIds, jobId}).then(res => {
    callbackFunction(res.data)
  }, error => {
    if (errorCallbackFunction) {
      errorCallbackFunction(error)
    }
  })
}

export const getPODataForPart = (shopId, poId, callbackFunction) => {
  Api.get(`/api/shop/${shopId}/purchaseOrder/${poId}`).then(res => {
    if (res.data.vendors && res.data.purchaseOrder) {
      for (var i = 0; i < res.data.vendors.length; i++) {
        if (res.data.vendors[i].id === res.data.purchaseOrder.vendor_id) {
          res.data.vendor = res.data.vendors[i]
        }
      }
    }
    callbackFunction(res.data)
  })
}
