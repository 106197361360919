import { gql } from 'apollo-boost'
import { CONSUMER_FRAGMENT } from '../fragments/consumer.fragment'
import { VEHICLE_FRAGMENT } from '../fragments/vehicle.fragment'

export const GET_CONSUMER_QUERY = gql`
  query getConsumer($id: ID, $organization_id: ID) {
    consumer(id: $id, organization_id: $organization_id) {
      ...ConsumerFields
    }
  }
  ${CONSUMER_FRAGMENT}
`

export const GET_INSURANCES_QUERY = gql`
  query getInsurances($organization_id: ID!) {
    insurances(organization_id: $organization_id) {
      id
      company_name
    }
  }
`

export const GET_CONSUMER_VEHICLES_QUERY = gql`
  query getConsumerVehicles($id: ID, $count: Int, $page: Int, $q: String) {
    consumerVehicles(id: $id, count: $count, page: $page, q: $q) {
      rows {
        ...VehicleFields
      }
      count
    }
  }
  ${VEHICLE_FRAGMENT}
`
