import { map, concat } from 'ramda'

export const reportsNamespace = 'reports'
const reportsActionPrefix = 'REPORTS_ACTION_'
const reportsMutationPrefix = 'REPORTS_MUTATE_'

export const mutations = {
  setStateValue: 'SET_STATE_VALUE',
  resetReportsState: 'RESET_REPORTS_STATE',
  resetData: 'RESET_DATA'
}

export const actions = {
  setStateValue: 'SET_STATE_VALUE',
  getReportData: 'GET_REPORT_DATA',
  getReportDataAdmin: 'GET_REPORT_DATA_ADMIN',
  getReportShopData: 'GET_REPORT_SHOP_DATA',
  fetchReportShopData: 'FETCH_REPORT_SHOP_DATA',
  sendPrintToPdfRequest: 'SEND_PRINT_TO_PDF_REQUEST',
  sendPrintRequest: 'SEND_PRINT_REQUEST'
}

// export mutations in format to be used in a store module
// i.e. { getJobShops: "REPORTS_MUTATE_SET_JOB_SHOP" }
const addMutationPrefix = concat(`${reportsMutationPrefix}`)
export const reportsMutations = map(addMutationPrefix)(mutations)

// export action types in format to be used in a store module
// i.e. { getJobShops: "REPORTS_ACTION_GET_JOB_SHOP" }
const addActionPrefix = concat(`${reportsActionPrefix}`)
export const reportsActions = map(addActionPrefix)(actions)

const addNamespace = concat(`${reportsNamespace}/`)
// export actions types in format to be used in a component
// i.e. { getJobShops: "reports/REPORTS_ACTION_GET_JOB_SHOP" }
export const reportsNamespacedActions = map(addNamespace)(reportsActions)

// export actions types in format to be used in a component
// i.e. { getJobShops: "reports/REPORTS_ACTION_GET_JOB_SHOP" }
export const reportsNamespacedMutations = map(addNamespace)(reportsMutations)
