<template>
    <el-badge :max="99" :value="unreadCount" :hidden="!unreadCount">
      <div class="gb-icon-white-button" @click="open">
        <i class="material-icons-outline md-notifications" />
      </div>
    </el-badge>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  computed: mapState('notifications', ['unreadCount']),

  methods: {
    ...mapActions('notifications', ['open']),

    setCount(count) {
      this.count = count;
    }
  }
}
</script>
