var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "500px",
        visible: _vm.localVisible,
        title: "Device Code",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.localVisible = $event
        },
        closed: function($event) {
          return _vm.$emit("closed")
        }
      }
    },
    [
      !_vm.code
        ? _c("div", [_vm._v("Loading...")])
        : _c("div", [
            _c("div", { staticClass: "t-break-normal" }, [
              _vm._v(
                ' To connect this device to GlassBiller, please sign out of the device. Then select "Sign in" and select "Use a device code." Enter the code below to sign in. '
              )
            ]),
            _c("div", { staticClass: "t-text-3xl t-text-center t-pt-3" }, [
              _vm._v(_vm._s(_vm.code))
            ]),
            _c(
              "div",
              {
                staticClass: "t-break-normal",
                staticStyle: { "margin-top": "15px" }
              },
              [
                _vm._v(
                  " Once you've signed in with this code, your device is connected to GlassBiller and ready to use. "
                )
              ]
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }