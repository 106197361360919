const state = {
  shopId: null,
  suppliers: [],
  purchaseOrderDialog: {
    isOpen: false,
    id: null
  },
  receiveDialog: {
    isOpen: false,
    id: null
  },
  techPickUpDialog: {
    isOpen: false,
    id: null
  },
  returnDialog: {
    isOpen: false,
    id: null
  }
}

const mutations = {
  setShopId(state, id) {
    state.shopId = id
  },
  setSuppliers(state, suppliers) {
    state.suppliers = suppliers
  },
  setPurchaseOrderDialog(state, { isOpen, id }) {
    state.purchaseOrderDialog = { isOpen, id }
  },
  setReceiveDialog(state, { isOpen, id }) {
    state.receiveDialog = { isOpen, id }
  },
  setTechPickUpDialog(state, { isOpen, id }) {
    state.techPickUpDialog = { isOpen, id }
  },
  setReturnDialog(state, { isOpen, id }) {
    state.returnDialog = { isOpen, id }
  }
}

const actions = {
  async openPurchaseOrderDialog({ commit, dispatch }, poId) {
    await dispatch('closePurchaseOrderDialog')
    commit('setPurchaseOrderDialog', { isOpen: true, id: poId })
  },

  closePurchaseOrderDialog({ commit }) {
    commit('setPurchaseOrderDialog', { isOpen: false, id: null })
  },

  async openReceiveDialog({ commit, dispatch }, receiveId) {
    await dispatch('closeReceiveDialog')
    commit('setReceiveDialog', { isOpen: true, id: receiveId })
  },

  closeReceiveDialog({ commit }) {
    commit('setReceiveDialog', { isOpen: false, id: null })
  },

  async openTechPickUpDialog({ commit, dispatch }, techPickUpId) {
    await dispatch('closeTechPickUpDialog')
    commit('setTechPickUpDialog', { isOpen: true, id: techPickUpId })
  },

  closeTechPickUpDialog({ commit }) {
    commit('setTechPickUpDialog', { isOpen: false, id: null })
  },

  async openReturnDialog({ commit, dispatch }, returnId) {
    await dispatch('closeReturnDialog')
    commit('setReturnDialog', { isOpen: true, id: returnId })
  },

  closeReturnDialog({ commit }) {
    commit('setReturnDialog', { isOpen: false, id: null })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
