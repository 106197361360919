var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vendor-document-upload" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            drag: "",
            multiple: "",
            action: "",
            "auto-upload": false,
            "show-file-list": false,
            "on-change": _vm.onUploadChange
          }
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v(" Drop files here or "),
            _c("em", [_vm._v("click to upload")])
          ])
        ]
      ),
      _vm.form.files.length
        ? _c(
            "el-form",
            {
              ref: "form",
              staticClass: "t-mt-6 t-space-y-4",
              attrs: { model: _vm.form }
            },
            [
              _vm._l(_vm.form.files, function(file, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "t-flex t-items-center t-space-x-4"
                  },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c("el-button", {
                          attrs: {
                            type: "danger",
                            size: "small",
                            icon: "el-icon-delete",
                            disabled: _vm.submitting,
                            circle: "",
                            plain: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.form.files.splice(index, 1)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "t-flex-grow",
                        attrs: {
                          prop: "files." + index + ".name",
                          "show-message": false,
                          rules: {
                            required: true,
                            trigger: "blur"
                          }
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { size: "small" },
                          model: {
                            value: file.name,
                            callback: function($$v) {
                              _vm.$set(file, "name", $$v)
                            },
                            expression: "file.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "files." + index + ".document_tag_id",
                          "show-message": false,
                          rules: {
                            required: true,
                            trigger: "change"
                          }
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: file.document_tag_id,
                              callback: function($$v) {
                                _vm.$set(file, "document_tag_id", $$v)
                              },
                              expression: "file.document_tag_id"
                            }
                          },
                          _vm._l(_vm.documentTags, function(tag) {
                            return _c(
                              "el-option",
                              {
                                key: tag.id,
                                attrs: { value: tag.id, label: tag.name }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-flex t-items-center t-space-x-3"
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "t-w-2 t-h-2 t-rounded-full",
                                      style: "background: " + tag.color
                                    }),
                                    _c("div", [_vm._v(_vm._s(tag.name))])
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c(
                "el-button",
                {
                  staticClass: "t-w-full upload-button",
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-upload",
                    loading: _vm.submitting
                  },
                  on: { click: _vm.uploadDocumentsHandler }
                },
                [_vm._v(" Upload ")]
              ),
              _c("el-divider")
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }