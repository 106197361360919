var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._b(
      {
        attrs: {
          visible: _vm.visible,
          width: _vm.width,
          title: _vm.title,
          "append-to-body": _vm.appendToBody,
          "custom-class": "gb-base-dialog " + _vm.extraClass
        },
        on: {
          "update:visible": function($event) {
            _vm.visible = $event
          },
          open: function($event) {
            return _vm.$emit("open")
          },
          close: function($event) {
            return _vm.$emit("close")
          },
          opened: function($event) {
            return _vm.$emit("opened")
          },
          closed: function($event) {
            return _vm.$emit("closed")
          }
        }
      },
      "el-dialog",
      _vm.$attrs,
      false
    ),
    [
      _vm._t("default"),
      _vm.$slots.title ? _vm._t("title", null, { slot: "title" }) : _vm._e(),
      _vm.$slots.footer
        ? _vm._t("footer", null, { slot: "footer" })
        : _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "normal" },
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "normal",
                    disabled: !_vm.valid,
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("on-save")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.okText) + " ")]
              )
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }