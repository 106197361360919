var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "t-flex t-px-4",
      class: { "t-justify-end": _vm.message.author === _vm.username }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "t-px-2 t-py-1 t-rounded-md t-text-gray-700 t-shadow-sm\n           t-border-solid t-border t-break-normal",
          class: [
            {
              "t-bg-blue-100 t-border-blue-200":
                _vm.message.author === _vm.username
            },
            {
              "t-bg-gray-100 t-border-gray-300":
                _vm.message.author !== _vm.username
            },
            { "t-max-w-xl": !_vm.infoDrawerIsOpen },
            { "t-max-w-xs": _vm.infoDrawerIsOpen }
          ]
        },
        [
          _vm.message.author !== _vm.username
            ? _c("div", { staticClass: "t-font-semibold t-mb-1" }, [
                _c("i", { staticClass: "el-icon-user" }),
                _vm._v(" " + _vm._s(_vm.message.author) + " ")
              ])
            : _vm._e(),
          _vm.message.type === "text"
            ? _c("div", [_vm._v(" " + _vm._s(_vm.message.body) + " ")])
            : _c("div", [
                _vm.filetype === "image"
                  ? _c("div", { staticClass: "t-mb-2 t-text-center" }, [
                      _vm.url
                        ? _c("div", [
                            _c(
                              "a",
                              { attrs: { href: _vm.url, target: "_blank" } },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "images-loaded",
                                      rawName: "v-images-loaded.on.done",
                                      value: _vm.imageLoaded,
                                      expression: "imageLoaded",
                                      modifiers: { on: true, done: true }
                                    }
                                  ],
                                  ref: "image",
                                  staticClass: "chat-message-image",
                                  attrs: {
                                    src: _vm.url,
                                    alt: _vm.message.media.filename
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "t-flex t-items-stretch t-bg-white t-mt-1 t-rounded-md\n               t-shadow-sm"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "t-flex t-items-center t-px-3 t-text-xl t-text-blue-100\n                 t-bg-blue-500 t-rounded-l-md"
                      },
                      [
                        _vm.filetype === "image"
                          ? _c("i", {
                              staticClass: "el-icon-picture-outline-round"
                            })
                          : _vm.filetype === "video"
                          ? _c("i", { staticClass: "el-icon-video-camera" })
                          : _vm.filetype === "audio"
                          ? _c("i", { staticClass: "el-icon-video-play" })
                          : _vm.filetype === "text" ||
                            _vm.filetype === "application"
                          ? _c("i", { staticClass: "el-icon-document" })
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "t-px-3 t-py-2 t-border-0 t-border-l t-border-r t-border-solid\n                 t-border-gray-300 t-flex-grow t-truncate"
                      },
                      [_vm._v(" " + _vm._s(_vm.message.media.filename) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "t-flex t-items-center t-px-3 t-text-lg t-rounded-r-md\n                 t-cursor-pointer hover:t-bg-green-500 hover:t-text-white",
                        on: { click: _vm.download }
                      },
                      [_c("i", { staticClass: "el-icon-download" })]
                    )
                  ]
                )
              ]),
          _c(
            "div",
            {
              staticClass:
                "chat-message-time t-text-gray-600 t-text-right t-mt-1"
            },
            [_vm._v(" " + _vm._s(_vm.sendTime) + " ")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }