<template>
  <el-dialog
    width="500px"
    :visible.sync="localVisible"
    title="Device Code"
    append-to-body
    @closed="$emit('closed')"
  >
    <div v-if="!code">Loading...</div>
    <div v-else>
      <div class="t-break-normal">
        To connect this device to GlassBiller, please sign out of the device. Then select "Sign in" and select "Use a device code." Enter the code below to sign in.
      </div>
      <div class="t-text-3xl t-text-center t-pt-3">{{code}}</div>
      <div class="t-break-normal" style="margin-top:15px;">
        Once you've signed in with this code, your device is connected to GlassBiller and ready to use.
      </div>
    </div>

  </el-dialog>
</template>

<script>
export default {
  props: ['code', 'visible'],
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  }
}
</script>
