<template>
  <div class="t-flex t-flex-wrap">
    <InfoPiece class="t-w-1/2" label="Name" :value="item.number" />
    <div class="t-w-1/2 t-mb-3 t-pr-4">
      <div class="t-font-medium t-text-gray-400 t-text-xs">Color</div>
      <div
        class="t-w-6 t-h-6 t-rounded-md t-shadow-sm t-border t-border-solid"
        :style="'background-color: ' + item.color"
      />
    </div>
    <InfoPiece class="t-w-full" label="Description" :value="item.description" />
    <InfoPiece
      class="t-w-1/2"
      label="Created by"
      :value="item.createdByName + ' on ' + formatDate(item.createdAt)"
    />
    <InfoPiece
      class="t-w-1/2"
      label="Updated by"
      :value="item.updatedByName + ' on ' + formatDate(item.updatedAt)"
    />
  </div>
</template>

<script>
import moment from 'moment'
import InfoPiece from './InfoPiece.vue'

export default {
  components: {
    InfoPiece
  },
  props: {
    item: Object
  },
  methods: {
    formatDate: function(date) {
      return moment.utc(date).format('lll')
    }
  }
}
</script>
