<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    title="Image Crop"
    top="75px"
    width="800px"
    custom-class="image-crop-dialog"
  >
    <LoadingSpinner v-if="!cropperReady" />

    <div class="t-mt-2 t-mx-4 t-pb-2">
      <cropper
        :src="value"
        :stencil-props="{
          aspectRatio: 1
        }"
        @change="onChange"
        @ready="cropperReady = true"
        @error="onError"
      />

      <div v-if="cropperReady" class="t-text-center t-mt-2">
        <el-button
          size="small"
          type="primary"
          @click="setAvatar"
        >
          Set Avatar
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import LoadingSpinner from '@/components/Helpers/LoadingSpinner.vue'

export default {
  components: { Cropper, LoadingSpinner },

  props: {
    value: String
  },

  data() {
    return {
      img: null,
      cropperReady: false
    }
  },

  computed: {
    visible: {
      get() {
        return !!this.value
      },
      set() {
        this.$emit('input', null)
        this.img = null
        this.cropperReady = false
      }
    }
  },

  methods: {
    onChange(image) {
      this.img = image.canvas.toDataURL('image/jpeg')
    },

    onError() {
      this.$message.error(
        'Unable to load a file. Make sure it\'s a valid image.',
        5000
      )
      this.visible = false
    },

    setAvatar() {
      this.$emit('cropped', this.img)
      this.visible = false
    }
  }
}
</script>

<style lang="scss">
.image-crop-dialog {
  @apply t-font-sans t-rounded-xl;

  .el-dialog__body {
    @apply t-p-0;
  }
}
</style>
