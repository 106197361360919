<template>
  <div class="t-pb-4">
    <el-table
      v-if="parts.length > 0"
      :data="parts"
      class="t-w-full"
      size="small"
      :show-header="false"
      empty-text="No parts found">
      <el-table-column
        prop="fullPartNumber"
        label="Part number"
        width="120">
        <template slot-scope="scope">
          <span class="t-text-indigo-800">
            <span v-if="scope.row.fullPartNumber">{{ scope.row.fullPartNumber }}</span>
            <span v-else>{{ scope.row.part }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="trademark"
        label="Trademark"
        width="90">
      </el-table-column>
      <el-table-column
        prop="locationDescription"
        label="Warehouse"
        width="150">
      </el-table-column>
      <el-table-column
        v-if="user.admin === 1"
        prop="locationCode"
        label="Warehouse ID"
        width="100">
      </el-table-column>
      <el-table-column
        label="Delivery">
        <template v-if="scope.row.deliveryDate && scope.row.deliveryTime" slot-scope="scope">
          <i class="el-icon-truck"></i>
          <span class="t-ml-1">{{ formatDeliveryDate(scope.row.deliveryDate, scope.row.deliveryTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="quantity"
        label="Quantity"
        width="80">
      </el-table-column>
      <el-table-column
        label="Price"
        width="80">
        <template slot-scope="scope">
          ${{ scope.row.priceBuyer }}
        </template>
      </el-table-column>
      <el-table-column align="center" width="80">
        <template slot-scope="scope">
          <button
            @click="add(scope.row)"
            class="t-cursor-pointer t-text-purple-600 t-px-2 t-pt-1 t-pb-0 t-border t-border-solid t-border-purple-400
                   t-rounded-full t-bg-white hover:t-text-white hover:t-border-purple-600 hover:t-bg-purple-600">
            <i class="el-icon-shopping-cart-2 t-text-base"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>
    <add-part-to-cart
      :visible="addPartToCartOpen"
      :part="selectedPart"
      :vendor="vendor"
      :shopId="shopId"
      :jobId="jobId"
      :status="status"
      :warehouse="warehouse"
      @refresh-data="$emit('refresh-data')"
      @close="addPartToCartOpen = false"
      @addAndLinkPart="addAndLinkPart($event)"
    />
  </div>
</template>

<script>
import moment from 'moment'
import AddPartToCart from '../AddPartToCart'

export default {
  props: ['vendor', 'parts', 'shopId', 'jobId', 'status', 'warehouse'],
  data: () => ({
    addPartToCartOpen: false,
    selectedPart: null
  }),
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    formatDeliveryDate (date, time) {
      return moment(date).format('Do MMM') + ' ' + moment(time, 'HH:mm:ss').format('h:mm a')
    },
    async add (part) {
      this.selectedPart = part
      this.addPartToCartOpen = true
    },
    addAndLinkPart (cartItemId) {
      this.$emit('addAndLinkPart', cartItemId)
    }
  },
  components: {
    AddPartToCart
  }
}
</script>
