var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        visible: _vm.isOpened,
        "before-close": _vm.close,
        "custom-class": "notifications-settings-dialog"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center notifications-settings-title",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("div", [_vm._v("Notifications Settings")]),
          _c("transition", { attrs: { name: "status-fade", duration: 1000 } }, [
            _vm.status == "saving"
              ? _c("div", { staticClass: "status-saving d-flex" }, [
                  _c("div", { staticClass: "icon" }, [
                    _c("i", { staticClass: "el-icon-loading" })
                  ]),
                  _c("div", { staticClass: "text" }, [_vm._v("Saving...")])
                ])
              : _vm._e()
          ]),
          _c("transition", { attrs: { name: "status-fade", duration: 1000 } }, [
            _vm.status == "saved"
              ? _c("div", { staticClass: "status-saved d-flex" }, [
                  _c("div", { staticClass: "icon" }, [
                    _c("i", { staticClass: "el-icon-check" })
                  ]),
                  _c("div", { staticClass: "text" }, [_vm._v("Saved!")])
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _vm.settings_loaded && _vm.settings_events_loaded
        ? _c(
            "div",
            { staticClass: "notifications-settings-body" },
            [
              _c("settings-general", {
                staticClass: "settings-general",
                attrs: { data: _vm.settings }
              }),
              _c(
                "div",
                { staticClass: "settings-events" },
                [
                  _c(
                    "el-tabs",
                    {
                      staticClass: "settings-events-tabs",
                      attrs: { value: _vm.defaultTab }
                    },
                    _vm._l(_vm.layout, function(tab_layout, index) {
                      return _c(
                        "el-tab-pane",
                        {
                          key: index,
                          attrs: {
                            label: tab_layout.title,
                            name: tab_layout.title
                          }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "list-events" },
                            _vm._l(tab_layout.events, function(
                              event_layout,
                              event_index
                            ) {
                              return _c("settings-event", {
                                key: event_index,
                                attrs: {
                                  title: event_layout.title,
                                  description: event_layout.description,
                                  permitted: _vm.$role.can(
                                    event_layout.permission
                                  ),
                                  item: _vm.settings_events[event_layout.key]
                                }
                              })
                            }),
                            1
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: true,
                expression: "true"
              }
            ]
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }