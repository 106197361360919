var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "t-flex t-px-8 t-mb-2 t-space-x-6" },
      [
        _c(
          "el-form-item",
          { attrs: { prop: "internalId", label: "ID" } },
          [
            _c("el-input", {
              staticClass: "item-form__disabled-input",
              attrs: {
                disabled: "",
                placeholder: "ID is generated automaticaly",
                value: _vm.internalId
              }
            })
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            attrs: {
              prop: "color",
              label: "Color",
              rules: { required: true, message: "Item color is required" }
            }
          },
          [
            _c("el-color-picker", {
              ref: "test",
              staticClass: "t-flex",
              attrs: { value: _vm.color, predefine: _vm.predefinedColors },
              on: {
                change: function($event) {
                  return _vm.$emit("update:color", $event)
                },
                "active-change": _vm.activeColorChanged
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "t-flex t-px-8 t-mb-2 t-space-x-6" },
      [
        _c(
          "el-form-item",
          {
            attrs: {
              prop: "number",
              label: "Item number",
              rules: {
                required: true,
                message: "Item number is required",
                trigger: "blur"
              }
            }
          },
          [
            _c("el-input", {
              attrs: { value: _vm.number },
              on: {
                input: function($event) {
                  return _vm.$emit("update:number", $event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { prop: "nagsId", label: "Nags ID" } },
          [
            _c("el-input", {
              staticClass: "item-form__disabled-input",
              attrs: { disabled: "", value: _vm.nagsId },
              on: {
                input: function($event) {
                  return _vm.$emit("update:nagsId", $event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "t-flex t-px-8 t-mb-4 t-space-x-6" },
      [
        _c(
          "el-form-item",
          {
            attrs: {
              prop: "description",
              label: "Description",
              rules: {
                required: true,
                message: "Description is required",
                trigger: "blur"
              }
            }
          },
          [
            _c("el-input", {
              attrs: { value: _vm.description },
              on: {
                input: function($event) {
                  return _vm.$emit("update:description", $event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { prop: "zone", label: "Zone" } },
          [
            _c("el-input", {
              attrs: { value: _vm.zone },
              on: {
                input: function($event) {
                  return _vm.$emit("update:zone", $event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "t-py-4 t-mb-4 t-border-0 t-border-t t-border-b t-border-solid t-border-gray-200"
      },
      [
        _c(
          "div",
          { staticClass: "t-flex t-px-8 t-mb-2 t-space-x-6" },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  prop: "listPrice",
                  label: "List price",
                  rules: {
                    required: true,
                    message: "List price is required",
                    trigger: "blur"
                  }
                }
              },
              [
                _c("el-input-number", {
                  staticClass: "t-w-full item-form__disabled-input",
                  attrs: {
                    controls: false,
                    disabled: _vm.nagsId !== "" && _vm.nagsId !== "CUSTOM",
                    value: _vm.listPrice
                  },
                  on: {
                    input: function($event) {
                      return _vm.$emit("update:listPrice", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  prop: "retailListPrice",
                  label: "Retail List price",
                  rules: {
                    required: true,
                    message: "Retail price is required",
                    trigger: "blur"
                  }
                }
              },
              [
                _c("el-input-number", {
                  staticClass: "t-w-full",
                  attrs: { controls: false, value: _vm.retailListPrice },
                  on: {
                    input: function($event) {
                      return _vm.$emit("update:retailListPrice", $event)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "t-flex t-px-8 t-space-x-6" },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  prop: "retailMaterial",
                  label: "Retail material",
                  rules: {
                    required: true,
                    message: "Retail material is required",
                    trigger: "blur"
                  }
                }
              },
              [
                _c("el-input-number", {
                  staticClass: "t-w-full",
                  attrs: { controls: false, value: _vm.retailMaterial },
                  on: {
                    input: function($event) {
                      return _vm.$emit("update:retailMaterial", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  prop: "retailLabor",
                  label: "Retail labor",
                  rules: {
                    required: true,
                    message: "Retail labor is required",
                    trigger: "blur"
                  }
                }
              },
              [
                _c("el-input-number", {
                  staticClass: "t-w-full",
                  attrs: { controls: false, value: _vm.retailLabor },
                  on: {
                    input: function($event) {
                      return _vm.$emit("update:retailLabor", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "retailTotal", label: "Retail total" } },
              [
                _c("el-input-number", {
                  staticClass: "t-w-full item-form__disabled-input",
                  attrs: {
                    controls: false,
                    disabled: true,
                    value: _vm.retailTotal
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "t-flex t-px-8 t-mb-2 t-space-x-6" },
      [
        _c(
          "el-form-item",
          { attrs: { prop: "upc", label: "UPC" } },
          [
            _c("el-input", {
              attrs: { value: _vm.upc },
              on: {
                input: function($event) {
                  return _vm.$emit("update:upc", $event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { prop: "sku", label: "SKU" } },
          [
            _c("el-input", {
              attrs: { value: _vm.sku },
              on: {
                input: function($event) {
                  return _vm.$emit("update:sku", $event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "t-flex t-px-8 t-mb-2 t-space-x-6" },
      [
        _c(
          "el-form-item",
          { attrs: { prop: "picture", label: "Image" } },
          [
            _vm.picture
              ? _c("div", { staticClass: "t-flex" }, [
                  _c("img", {
                    staticClass: "t-max-w-32 t-max-h-32 t-object-contain",
                    attrs: { src: _vm.picture, alt: "Item image" }
                  }),
                  _c("i", {
                    staticClass:
                      "el-icon-remove t-text-red-500 t-text-xl t-ml-[-13px]\n            t-mt-[-10px] t-cursor-pointer",
                    on: {
                      click: function($event) {
                        _vm.picture = null
                      }
                    }
                  })
                ])
              : _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-upload2",
                      size: "mini",
                      type: "primary",
                      plain: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.addFile("picture")
                      }
                    }
                  },
                  [_vm._v(" Add ")]
                )
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { prop: "qrCode", label: "QR code" } },
          [
            _vm.qrCode
              ? _c("div", { staticClass: "t-flex" }, [
                  _c("img", {
                    staticClass: "t-max-w-32 t-max-h-32 t-object-contain",
                    attrs: { src: _vm.qrCode, alt: "QR Code" }
                  }),
                  _c("i", {
                    staticClass:
                      "el-icon-remove t-text-red-500 t-text-xl t-ml-[-13px]\n            t-mt-[-10px] t-cursor-pointer",
                    on: {
                      click: function($event) {
                        _vm.qrCode = null
                      }
                    }
                  })
                ])
              : _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-upload2",
                      size: "mini",
                      type: "primary",
                      plain: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.addFile("qrCode")
                      }
                    }
                  },
                  [_vm._v(" Add ")]
                )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "t-flex t-px-8 t-mb-2 t-space-x-6" },
      [
        _vm.id
          ? _c(
              "el-form-item",
              { attrs: { prop: "isActive", label: "Status" } },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "t-w-full",
                    attrs: {
                      value: _vm.isActive,
                      placeholder: "Select status"
                    },
                    on: {
                      change: function($event) {
                        return _vm.$emit("update:isActive", $event)
                      }
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Active", value: true }
                    }),
                    _c("el-option", {
                      attrs: { label: "Disabled", value: false }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-form-item",
          { attrs: { prop: "isInventoryTracked", label: "Inv tracking" } },
          [
            _c("el-switch", {
              attrs: {
                value: _vm.isInventoryTracked,
                "active-color": "#34D399",
                "inactive-color": "#F87171"
              },
              on: {
                change: function($event) {
                  return _vm.$emit("update:isInventoryTracked", $event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { prop: "autoSourceNoBuy", label: "Auto Source No Buy" } },
          [
            _c("el-checkbox", {
              attrs: { value: _vm.autoSourceNoBuy },
              on: {
                change: function($event) {
                  return _vm.$emit("update:autoSourceNoBuy", $event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "t-flex t-px-8 t-space-x-6" },
      [
        _c(
          "el-form-item",
          { staticClass: "t-w-full", attrs: { prop: "notes", label: "Notes" } },
          [
            _c("el-input", {
              attrs: { type: "textarea", value: _vm.notes, rows: 2 },
              on: {
                input: function($event) {
                  return _vm.$emit("update:notes", $event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }