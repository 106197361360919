<template>
  <div class="kit-select" :class="{ focused: focused }">
    <number-input
      placeholder="0"
      v-model="amount"
      :allowNegative="allowNegative"
      class="input-with-select"
      @focus="onFocus"
      @blur="onBlur"
      @change="$emit('change', $event)"
      ref="inputRef"
      :size="size || 'small'"
    >
      <el-button
        slot="append"
        @click="toggleType"
        @focus.native="onFocus"
        @blur.native="onBlur"
      >
        <div class="kit-select-type-button">
          {{ type }}
          <i class="material-icons md-arrow_drop_down md-18" />
        </div>
      </el-button>
      <slot name="prefix" slot="prefix" />
    </number-input>
  </div>
</template>

<script>
import NumberInput from '../NumberInput'

export default {
  components: { NumberInput },
  props: ['value', 'size', 'allowNegative'],
  data() {
    return {
      focused: false
    }
  },
  computed: {
    type() {
      return this.value?.type || 'each'
    },
    amount: {
      get() {
        return this.value?.amount || 0
      },

      set(amount) {
        this.$emit('input', {
          __typename: 'Kit',
          type: this.type,
          amount
        })
      }
    }
  },
  methods: {
    toggleType() {
      const type = this.value?.type === 'each' ? 'flat' : 'each'
      this.$emit('input', {
        __typename: 'Kit',
        type,
        amount: +(this.amount || 0)
      })
    },
    onFocus(e) {
      this.focused = true
      this.$emit('focus', {
        value: { __typename: 'Kit', amount: this.amount, type: this.type }
      })
    },
    onBlur(e) {
      this.focused = false
      setTimeout(() => {
        if (!this.focused) {
          this.$emit('blur', {
            value: { __typename: 'Kit', amount: this.amount, type: this.type }
          })
        }
      }, 100)
    },
    focus() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.inputRef?.focus()
    }
  }
}
</script>

<style lang="scss">
.kit-select {
  .el-input-group__append {
    padding: 0 $padding-xxs 0 $padding-xs;
  }
  .el-input-group--append {
    width: 100%;
    /* width: 125px; */
    .el-input__inner {
      text-align: right;
      padding: 0 4px 0 0;
    }
  }
}
.kit-select-type-button {
  display: flex;
  align-items: center;
}
</style>
