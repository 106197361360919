import gql from 'graphql-tag'

export const PAYMENT_FRAGMENT = gql`
  fragment PaymentFields on Payment {
    type
    amount
    date
    x_action_type
    x_action_number
    memo
    job_id
    id
    consumer_name
    payment_account_type
    payment_account_name
    consumer_id
    payment_group_id
  }
`
