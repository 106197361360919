const googleMapsConfig = {
  baseConfig: {
    v: '3.43',
    key: 'AIzaSyA0l0vTHZ7gwKA0F5H74eT9HiOx7JbLfTI',
    libraries: 'places,geometry,localContext'
  },
  defaultValues: {
    center: { lat: 37.09024, lng: -95.712891 },
    zoom: 6
  },
  iconPath:
    'M13.4584375,39.1929592 C2.10703125,22.7367913 0,21.0478855 0,14.9999963 C0,6.71570149 6.71570313,0 15,0 C23.2842969,0 30,6.71570149 30,14.9999963 C30,21.0478855 27.8929687,22.7367913 16.5415625,39.1929592 C15.7966406,40.2690527 14.2032812,40.2689745 13.4584375,39.1929592 Z',
  warehouseIconPath: 'M0 0 H 16 V 16 H 0 L 0 0',
  waypointIconPath: 'M0 0 h 30 L 15 30z',
  smallIconPath:
    'M0,10.5 C1.98951966e-15,16.2989899 4.70101013,21 10.5,21 C16.2989899,21 21,16.2989899 21,10.5 C21,4.70101013 16.2989899,2.89190893e-14 10.5,2.84217094e-14 C4.70101013,2.79243295e-14 1.98951966e-15,4.70101013 1.49213975e-15,10.5'
}

export default googleMapsConfig

export const localContextTypes = [
  'bank',
  'bar',
  'cafe',
  'convenience_store',
  'department_store',
  'electronics_store',
  'restaurant',
  'shopping_mall',
  'supermarket',
  'tourist_attraction'
]
