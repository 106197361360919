<template>
  <div class="t-flex t-flex-wrap">
    <InfoPiece
      class="t-w-1/2"
      label="ID"
      :value="item.internalId ? item.internalId.toString() : ''"
    />
    <div class="t-w-1/2 t-mb-3 t-pr-4">
      <div class="t-font-medium t-text-gray-400 t-text-xs">Color</div>
      <div
        class="t-w-6 t-h-6 t-rounded-md t-shadow-sm t-border t-border-solid"
        :style="'background-color: ' + item.color"
      />
    </div>
    <InfoPiece class="t-w-1/2" label="Item number" :value="item.number" />
    <InfoPiece class="t-w-1/2" label="Nags id" :value="item.nagsId" />
    <InfoPiece class="t-w-1/2" label="Description" :value="item.description" />
    <InfoPiece class="t-w-1/2" label="Zone" :value="item.zone" />
    <InfoPiece
      class="t-w-1/2"
      label="List price"
      :value="formatPrice(item.listPrice)"
    />
    <InfoPiece
      class="t-w-1/2"
      label="Retail List price"
      :value="formatPrice(item.retailListPrice)"
    />
    <InfoPiece
      class="t-w-1/2"
      label="Retail material"
      :value="formatPrice(item.retailMaterial)"
    />
    <InfoPiece
      class="t-w-1/2"
      label="Retail labor"
      :value="formatPrice(item.retailLabor)"
    />
    <InfoPiece
      class="t-w-1/2"
      label="Retail Total"
      :value="formatPrice((parseFloat(item.retailLabor) || 0) + (parseFloat(item.retailMaterial) || 0))"
    />
    <InfoPiece class="t-w-1/2" label="UPC" :value="item.upc" />
    <InfoPiece class="t-w-1/2" label="SKU" :value="item.sku" />
    <InfoImagePiece
      class="t-w-1/2"
      label="Image"
      :value="item.picture"
      @view="viewImage(item.picture)"
    />
    <InfoImagePiece
      class="t-w-1/2"
      label="QR code"
      :value="item.qrCode"
      @view="viewImage(item.qrCode)"
    />
    <div class="t-w-1/2 t-mb-3 t-pr-4">
      <div class="t-font-medium t-text-gray-400 t-text-xs">Status</div>
      <div class="t-mt-1">
        <span
          class="t-capitalize t-px-2 t-py-1 t-rounded t-text-xs t-text-white"
          :class="item.isActive ? 't-bg-green-400' : 't-bg-red-400'"
        >
          {{ item.isActive ? 'Active' : 'Disabled' }}
        </span>
      </div>
    </div>
    <div class="t-w-1/2 t-mb-3 t-pr-4">
      <div class="t-font-medium t-text-gray-400 t-text-xs">Inv tracking</div>
      <el-switch
        :value="item.isInventoryTracked"
        class="t-mt-1 item-catalog-dialog__info-switch"
        active-color="#34D399"
        inactive-color="#F87171"
      />
    </div>
    <div class="t-w-1/2 t-mb-3 t-pr-4">
      <div class="t-font-medium t-text-gray-400 t-text-xs">Auto Source No Buy</div>
      <el-checkbox v-model="item.autoSourceNoBuy" disabled></el-checkbox>
    </div>
    <InfoPiece class="t-w-full" label="Notes" :value="item.notes"/>
    <InfoPiece
      class="t-w-1/2"
      label="Created by"
      :value="item.createdByName + ' on ' + formatDate(item.createdAt)"
    />
    <InfoPiece
      class="t-w-1/2"
      label="Updated by"
      :value="item.updatedByName + ' on ' + formatDate(item.updatedAt)"
    />
    <ImagePreviewDialog
      :url="imagePreviewUrl"
      @close="imagePreviewUrl = null"
    />
  </div>
</template>

<script>
import moment from 'moment'
import InfoPiece from './InfoPiece.vue'
import InfoImagePiece from './InfoImagePiece.vue'
import ImagePreviewDialog from './ImagePreviewDialog.vue'

export default {
  components: {
    InfoPiece,
    InfoImagePiece,
    ImagePreviewDialog
  },

  props: {
    item: Object
  },

  data: () => ({
    imagePreviewUrl: null
  }),

  methods: {
    viewImage(url) {
      this.imagePreviewUrl = url
    },
    formatDate(date) {
      return moment.utc(date).format('lll')
    },
    formatPrice(p) {
      if (!p) return ''

      return (p < 0 ? '- ' : '') + '$' + Math.abs(p)
    }
  }
}
</script>

<style lang="scss">
.item-catalog-dialog__info-switch {
  .el-switch__core {
    cursor: default;
  }
}
</style>
