<template>
  <div class="t-p-3 t-w-90 t-h-36">
    <div class="t-flex t-flex-col t-items-center t-justify-center t-border t-border-solid t-border-gray-200
        t-rounded-md t-shadow-sm t-bg-white t-h-full t-cursor-pointer t-px-3 t-relative">
      <div>
        <div class="t-flex t-flex-col t-items-center t-justify-center t-space-x-2
          t-mb-2 t-text-blue-600">
          <div class="t-text-sm t-mb-4 terminal-content" v-if="label">{{ label }}</div>
          <img src="./../DeviceCard/images/desktop-computer.svg" class="t-text-blue-600 t-h-10 t-w-10" />
        </div>
      </div>
      <div class="t-flex t-flex-col">
        <div class='ml-4'>
          {{status}}
        </div>
        <span class="t-flex t-w-3 t-h-3 t-mt-1 t-absolute">
          <span
            class="t-absolute t-inline-flex t-rounded-full t-opacity-50 t-h-full t-w-full"
            :class="{
              't-animate-ping': !connected,
              't-bg-yellow-300': status === 'Cancelling transaction',
              't-bg-green-300': connected,
              't-bg-red-300': !connected,
            }"
          ></span>
          <span
            class="t-relative t-inline-flex t-rounded-full t-h-3 t-w-3"
            :class="{
              't-bg-yellow-300': status === 'Cancelling transaction',
              't-bg-green-500': connected && status != 'Cancelling transaction',
              't-bg-red-500': !connected && status != 'Cancelling transaction'
            }"
          ></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: String,
    connected: Boolean,
    label: String
  }
}
</script>
