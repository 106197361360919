import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Salesidejob extends ObjectTracker {
  id = -1
  shop_id = -1
  customer_first_name = ''
  customer_last_name = ''
  customer_phone1 = ''
  customer_phone2 = ''
  customer_email = ''
  customer_address = ''
  customer_address_city = ''
  customer_address_state = ''
  customer_address_zip = ''
  scheduled_date = ''
  scheduled_time_start = ''
  scheduled_time_end = ''
  contact_is_customer = 0
  contact_name = ''
  contact_phone = ''
  contact_address = ''
  contact_address_city = ''
  contact_address_state = ''
  contact_address_zip = ''
  insurancefleet_id = -1
  loss_date = ''
  referral_number = ''
  policy_number = ''
  vehicle_vin = ''
  vehicle_vin_is_valid = 0
  vehicle_year = ''
  vehicle_make = ''
  vehicle_model = ''
  vehicle_sub_model = ''
  vehicle_style = ''
  repair_type = ''
  vehicle_description = ''
  install_notes = ''
  contact_signature = ''
  saleside_username = ''
  sent = 0
  saleside_job_status = ''
  deductible = -1
  job_id = -1
  created_on = ''
  requisition_order_number = ''
  purchase_order_number = ''
  customer_type = ''
  commercialaccount_id = -1
  commercialaccount = 0
  damage_locator = ''
  review_sent = null
  pending_offline = 0

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getSalesidejobsCountsForAllConnectedShops (callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/salesidejobsCount`}).then(res => {
      
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getSalesidejobsDatesForAllConnectedShops (callbackFunction, errorCallbackFunction = null) {
    Api({url: '/api/salesidejobsDate'}).then(res => {
      
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getSalesidemobileTable (shopId, page, limit, sortBy, sortDesc, showArchive, mobileJobNumberFilter,
    referralNumberFilter, lastNameFilter, firstNameFilter, jobNumberFilter, salesRepFilter, yearMakeModelFilter,
    scheduledDateFilter, unsentFilter, successCallbackFunction, errorCallbackFunction) {
    Api({url: `/api/shop/${shopId}/salesidejob/inboxTable?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&showArchive=' + encodeURIComponent(showArchive) +
      '&mobileJobNumberFilter=' + encodeURIComponent(mobileJobNumberFilter) +
      '&referralNumberFilter=' + encodeURIComponent(referralNumberFilter) +
      '&lastNameFilter=' + encodeURIComponent(lastNameFilter) +
      '&firstNameFilter=' + encodeURIComponent(firstNameFilter) +
      '&jobNumberFilter=' + encodeURIComponent(jobNumberFilter) +
      '&salesRepFilter=' + encodeURIComponent(salesRepFilter) +
      '&yearMakeModelFilter=' + encodeURIComponent(yearMakeModelFilter) +
      '&scheduledDateFilter=' + encodeURIComponent(scheduledDateFilter) +
      '&unsentFilter=' + encodeURIComponent(unsentFilter)
    }).then(res => {
      
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static archiveSalesidejob (shopId, salesidejobId, callbackFunction, errorCallbackFunction = null) {
    return Api.post(`/api/shop/${shopId}/salesidejob/${salesidejobId}/archive`, this).then(res => {
      
      
      callbackFunction(res.data)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static unarchiveSalesidejob (shopId, salesidejobId, callbackFunction, errorCallbackFunction = null) {
    return Api.post(`/api/shop/${shopId}/salesidejob/${salesidejobId}/unarchive`, this).then(res => {
      
      
      callbackFunction(res.data)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static kickbackSalesideJob (shopId, salesideJobId, callbackFunction, errorCallbackFunction = null) {
    return Api.post(`/api/shop/${shopId}/salesidejob/${salesideJobId}/kickback`, this).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getSalesidejobById (id, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/shop/getSalesidejobById/${id}`}).then(res => {
      
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
