<template>
  <div class="global-search-results" ref="scroll" v-if="allResults.length">
    <el-scrollbar
      wrap-class="global-search-results-container"
      view-class="global-search-results-list"
      ref="scrollbar"
    >
      <search-results-group
        v-if="showRecent && recent.length"
        :rows="recent"
        title="Recent searches"
        action="Clear"
        type="recent"
        @highlight="highlight = $event"
        @select="select($event)"
        :highlight="highlight"
        @action="recent = []"
      />

      <template v-else>
        <el-row>
          <el-col :md="(results.consumers && results.consumers.length > 0) ? 12 : 24" :sm="24" v-if="results.jobs && results.jobs.length > 0">
            <search-results-group
              v-if="results.jobs && results.jobs.length > 0"
              :rows="results.jobs"
              title="Jobs"
              type="job"
              @highlight="highlight = $event"
              :highlight="highlight"
              @select="select($event)"
            />
            <load-more v-if="!noMoreJobResults" @loadMore="$emit('loadMore')" />
          </el-col>
          <el-col :md="(results.jobs && results.jobs.length > 0) ? 12 : 24" :sm="24" v-if="results.consumers && results.consumers.length > 0">
            <search-results-group
              v-if="results.consumers && results.consumers.length > 0"
              :rows="results.consumers"
              title="Consumers"
              type="consumer"
              @highlight="highlight = $event"
              :highlight="highlight"
              :offset="results.jobs.length"
              @select="select($event)"
            />
            <load-more v-if="!noMoreConsumerResults" @loadMore="$emit('loadMore')" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="(results.jobs && results.jobs.length > 0) && (results.consumers && results.consumers.length > 0) ? 12 : 24" :sm="24" v-if="results.salesidejobs && results.salesidejobs.length > 0">
            <search-results-group
              v-if="results.salesidejobs && results.salesidejobs.length > 0"
              :rows="results.salesidejobs"
              title="Mobile Jobs"
              type="salesidejob"
              @highlight="highlight = $event"
              :highlight="highlight"
              :offset="results.jobs.length"
              @select="select($event)"
            />
            <load-more v-if="!noMoreSalesideJobResults" @loadMore="$emit('loadMore')" />
          </el-col>
        </el-row>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import SearchResultsGroup from './SearchResultsGroup'
import { sync } from 'vuex-pathify'
import scrollIntoView from 'element-ui/src/utils/scroll-into-view'
import * as R from 'ramda'
import LoadMore from './LoadMore'

export default {
  props: ['results', 'showRecent', 'noMoreJobResults', 'noMoreConsumerResults', 'noMoreSalesideJobResults'],
  data() {
    return {
      highlight: 0
    }
  },
  components: { SearchResultsGroup, LoadMore },
  computed: {
    recent: sync('jobDetails/recentSearches'),
    allResults() {
      return this.showRecent ? this.recent : R.pipe(R.values, R.unnest)(this.results)
    }
  },
  methods: {
    nextOption() {
      if (this.highlight < this.allResults.length - 1) {
        this.highlight = this.highlight + 1
        this.scrollToOption()
      }
    },
    prevOption() {
      if (this.highlight > 0) {
        this.highlight = this.highlight - 1
        this.scrollToOption()
      }
    },
    scrollToOption() {
      const { scroll, scrollbar } = this.$refs
      const target = scroll.querySelectorAll('.search-result-row')[
        this.highlight
      ]
      const menu = scroll.querySelector('.global-search-results-container')
      menu && target && scrollIntoView(menu, target)
      scrollbar && scrollbar.handleScroll()
    },
    select(value) {
      const res = value || this.allResults[this.highlight]
      if (res) {
        this.recent = R.pipe(R.prepend(res), R.uniq, R.take(6))(this.recent)
        this.$emit('select', res)
      }
    }
  }
}
</script>
<style lang="scss">
.global-search-results {
  border-top: 1px solid #dcdfe6;
  .global-search-results-container {
    max-height: 600px;
  }
}
</style>
