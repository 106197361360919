<template>
  <el-dialog
    append-to-body
    :visible="visible"
    :before-close="handleClose"
    custom-class="no-body-padding"
    title="Select item source"
  >
    <LoadingSpinner v-if="loading" />
    <div v-else-if="item" class="t-flex t-flex-wrap t-px-3 t-pt-6 t-bg-gray-50">
      <Card
        v-if="item.availableUnitsQuantity"
        :type="'available'"
        :number="item.number"
        :total="item.availableUnitsQuantity"
        :price="item.availableUnitPrice"
        @selected="select({
          type: 'available',
          price: item.availableUnitPrice
        })"
      />

      <Card
        v-for="(i, index) in item.unreceivedUnits"
        :key="'unreceived-' + index"
        :id="i.id"
        :type="'unreceived'"
        :number="i.number"
        :total="i.quantity"
        :price="i.price"
        :poId="i.purchaseOrderId"
        :poInternalId="i.purchaseOrderInternalId"
        @viewPO="openPO"
        @selected="select({
          type: 'unreceived',
          price: i.price,
          poId: i.purchaseOrderId
        })"
      />

      <div v-if="unsoucedAllowed" class="t-w-1/4 t-px-3 t-pb-6">
        <div
          class="t-flex t-flex-col t-items-stretch t-border t-border-solid t-border-gray-200
           t-p-4 t-rounded-md t-shadow t-min-h-[200px] t-h-full t-bg-white"
        >
          <div class="t-text-center t-text-lg t-mb-4">Add unsourced</div>
          <div class="t-text-center">
            <el-button
              plain
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="select()"
            >
              Add
            </el-button>
          </div>
        </div>
      </div>

    </div>
  </el-dialog>
</template>

<script>
import Card from './Card.vue'
import LoadingSpinner from '@/components/Helpers/LoadingSpinner.vue'

export default {
  components: {
    Card,
    LoadingSpinner
  },

  props: {
    itemId: Number,
    shopId: Number,
    visible: Boolean,
    unsoucedAllowed: Boolean
  },

  data: () => ({
    item: null,
    loading: false
  }),

  watch: {
    visible(val) {
      if (val) {
        this.getItemDetails()
      } else {
        this.item = null
      }
    }
  },

  methods: {
    async getItemDetails() {
      try {
        this.loading = true
        const { data } = await this.$unum.warehouse()
          .get(`items/${this.itemId}/inventory-details`, {
            params: {
              shopId: this.shopId
            }
          })
        this.item = data.data

        // if unsourced parts are not allowed and only one source is available,
        // then select the unit right away
        if (!this.unsoucedAllowed) {
          if (
            this.item.availableUnitsQuantity > 0 &&
            this.item.unreceivedUnits.length === 0
          ) {
            this.select({ type: 'available' })
          } else if (
            this.item.availableUnitsQuantity === 0 &&
            this.item.unreceivedUnits.length === 1
          ) {
            this.select({
              type: 'unreceived',
              poId: this.item.unreceivedUnits[0].purchaseOrderId
            })
          }
        }

        this.loading = false
      } catch {
        this.loading = false
      }
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    openPO(id) {
      this.$store.dispatch('warehouse/openPurchaseOrderDialog', id)
    },
    select(data = null) {
      this.$emit('selected', data)
      this.handleClose()
    }
  }
}
</script>
