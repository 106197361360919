var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("clover-select", {
        attrs: { value: _vm.deviceToAdd, options: _vm.options },
        on: {
          "update:value": function($event) {
            _vm.deviceToAdd = $event
          }
        }
      }),
      _c(
        "el-button",
        {
          attrs: {
            size: "mini",
            type: "primary",
            plain: "",
            icon: "el-icon-plus",
            disabled: !_vm.deviceToAdd
          },
          on: {
            click: function($event) {
              return _vm.$emit("add", _vm.deviceToAdd)
            }
          }
        },
        [_vm._v(" add ")]
      ),
      _c(
        "el-button",
        {
          attrs: { size: "mini", type: "danger", icon: "el-icon-delete" },
          on: {
            click: function($event) {
              return _vm.$emit("cancel")
            }
          }
        },
        [_vm._v(" cancel ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }