import { actions, mutations } from './types';
import { PAGE_SIZE, parseRows, parseRow } from './helpers';
import NotificationsAPI from '@/services/NotificationsAPI';
import cloneDeep from 'lodash.clonedeep';

export default {
  [actions.open]({ commit }) {
    commit(mutations.setIsOpened, true);
  },

  [actions.close]({ commit }) {
    commit(mutations.setTab, 'unread');
    commit(mutations.setIsOpened, false);
  },

  [actions.changeTab]: async ({ commit }, tab_type) => {
    commit(mutations.setTab, tab_type);
  },

  [actions.loadPage]: async ({ commit }, { tab_type, page }) => {

    const params = {
      pager: { number: page, size: PAGE_SIZE },
      view: tab_type
    };

    const result = await NotificationsAPI.get('/history/list', { params });
    let rows = result?.data?.response?.rows || [];

    if (!rows.length) {
      return 0;
    }

    // add notifications to the tab
    commit(mutations.addNotifications, parseRows(rows));

    return rows.length;
  },

  [actions.load]: async ({ commit, state, dispatch }, { tab_type, $state }) => {

    // calculate page we need to load or reload in case list of notifications
    // is mutated separately by read buttons.
    let page = state.tabs[tab_type].page;
    let count = await dispatch(actions.loadPage, { tab_type, page });

    // if we didn't get anything just return. we have reached the end
    if (!count) {
      $state.complete();
      return;
    }

    // if we requested more then we have in database we can stop
    if (count < PAGE_SIZE) {
      $state.loaded();
      $state.complete();
      return;
    }

    commit(mutations.setPage, { tab_type, page: page + 1 });

    // otherwise we will need to request more later
    $state.loaded();
  },

  [actions.reloadPage]: async ({ dispatch, state }) => {

    let page = Math.floor(state.tabs.unread.rows.length / PAGE_SIZE);
    await dispatch(actions.loadPage, { tab_type: 'unread', page });
  },

  [actions.receive]: async ({ commit, dispatch }, { data }) => {
    const parsed = parseRow(data);

    commit(mutations.addNotifications, [parsed]);

    // if we closed window forcefuly we need to mark it read
    // on the server side
    if (data.is_read) {
      await dispatch(actions.read, { id: data.id });
    }

    // increase counter after read process
    // so delay when counter is wron would be minimised
    commit(mutations.increaseCount, 1);
  },

  [actions.getUnreadCount]: async ({ commit }) => {
    const result = await NotificationsAPI.get('/history/count');
    const count = result?.data?.response?.count || 0;

    commit(mutations.setCount, count);
  },

  [actions.read]: async ({ commit }, payload) => {
    const result = await NotificationsAPI.post('/history/read', payload);
    const affected = result?.data?.response?.affected || 0;

    commit(mutations.increaseCount, -affected);
  },

  [actions.readOne]: async ({ commit, dispatch, state }, data) => {
    commit(mutations.moveNotifications, [data]);

    let params = { tab_type: 'unread', id: data.id };
    commit(mutations.lock, params);

    await dispatch(actions.read, { id: data.id });

    // reload page when we got read of all read locks
    if (state.tabs.unread.locksCounter) {
      return;
    }

    await dispatch(actions.reloadPage);

    commit(mutations.unlock, params);
  },

  [actions.readAll]: async ({ commit, dispatch, state }) => {
    commit(mutations.toggleReadAllLoader);

    // run read first to make sure we will not get any old notifications
    await dispatch(actions.read, {});
    commit(mutations.moveNotifications, cloneDeep(state.tabs.unread.rows));

    commit(mutations.toggleReadAllLoader);
  },

}
