<template>
  <el-dialog
    :visible="visible"
    :before-close="close"
    width="900px"
    custom-class="vendor-results-dialog"
    append-to-body
  >
    <span slot="title" class="t-text-xl t-text-gray-800">
      {{ vendorName }} results
    </span>
    <div class="t-mt-2 t-p-5 t-shadow" :class="headerBackgroundColor">
      <div class="t-flex">
        <div class="t-flex-1 t-text-gray-800">
          <div class="t-text-base">
            Part
            <span class="t-text-indigo-800">
              {{ nagsPart.detail.partno }}
            </span>
          </div>
          <div class="t-text-085">{{ nagsPart.detail.dsc }}</div>
        </div>
        <el-tooltip
          v-if="!warehouse"
          class="item"
          effect="dark"
          content="Add to parts list"
          placement="top"
        >
          <button
            v-if="
              returnedPart &&
              returnedPart.errorCode === '0' &&
              returnedPart.priceBuyer &&
              priceFromLevels &&
              priceLevelSelected
            "
            class="t-border-none t-rounded-full hover:t-bg-purple-600
                   hover:t-text-white t-bg-white t-cursor-pointer
                   t-text-purple-700 t-text-base t-font-medium t-self-center
                   t-px-3 t-py-1"
            @click="addPart"
          >
            ${{ priceFromLevels }}
          </button>
        </el-tooltip>
      </div>
    </div>
    <div class="t-p-5">
      <div class="t-flex t-items-center">
        <div class="t-flex-grow">Total {{ results.length }} results</div>
        <div
          v-if="
            [ 'mygrant', 'pgw', 'igc'].includes(vendor) &&
            credentials.length > 1
          "
        >
          <el-select
            v-model="accountNumber"
            class="t-ml-4"
            placeholder="Select account"
            size="mini"
          >
            <el-option
              v-for="item in credentials"
              :key="item.id"
              :label="
                item.account_number + (item.name ? ' - ' + item.name : '')
              "
              :value="item.account_number"
            />
          </el-select>
        </div>
        <div v-if="warehouseId">
          <el-select
            v-model="warehouseId"
            class="t-ml-4"
            placeholder="Select warehouse"
            size="mini"
          >
            <el-option
              v-for="item in warehouses"
              :key="item.warehouseId"
              :label="item.warehouse"
              :value="item.warehouseId"
            />
          </el-select>
        </div>
        <div v-else>
          <el-select
            v-model="accountDefault"
            class="t-ml-4"
            placeholder="Select warehouse"
            size="mini"
          >
            <el-option
              key="1"
              label="Account Default"
              value="Account Default"
            />
          </el-select>
        </div>
      </div>
      <div v-if="loading" v-loading="true" class="t-h-32"></div>
      <div v-else-if="Object.keys(parts).length > 0">
        <div v-for="(pts, locationName) in parts" :key="locationName">
          <el-divider>
            <span class="t-text-purple-700">{{ locationName }}</span>
          </el-divider>
          <results-table
            :vendor="vendor"
            :parts="pts"
            :shopId="shopId"
            :jobId="jobId"
            :status="status"
            :warehouse="warehouse"
            @refresh-data="$emit('refresh-data')"
            @addAndLinkPart="addAndLinkPart($event)"
          />
        </div>
      </div>
      <p v-else class="t-text-center t-mt-3">No parts found</p>
    </div>
  </el-dialog>
</template>

<script>
import * as R from 'ramda'
import Api from '@/services/Api'
import ResultsTable from './Table'

export default {
  props: [
    'vendor',
    'nagsPart',
    'priceFromLevels',
    'results',
    'visible',
    'loading',
    'defaultWarehouseId',
    'defaultCredentials',
    'shopId',
    'jobId',
    'standalone',
    'warehouse',
    'status'
  ],
  data: () => ({
    warehouseId: null,
    accountNumber: null,
    allCredentials: [],
    mygrantWarehouses: [],
    accountDefault: 'Account Default'
  }),
  computed: {
    returnedPart() {
      return this.results[0]
    },
    parts() {
      return R.groupBy((part) => part.locationDescription, this.results)
    },
    warehouses() {
      if (this.vendor === 'mygrant') {
        if (this.standalone) {
          return this.mygrantWarehouses
        } else {
          return this.$store.state.partVendors.glaxisWarehouses
        }
      }
      return []
    },
    credentials() {
      if (this.standalone) {
        if (this.allCredentials.length) {
          return this.allCredentials.filter(i => i.vendor === this.vendor)
        }
      } else {
        if (this.$store.state.partVendors.credentials.length) {
          return this.$store.state.partVendors.credentials.filter(
            i => i.vendor === this.vendor
          )
        }
      }
      return []
    },
    vendorName() {
      if (this.vendor === 'mygrant') return 'Mygrant'
      if (this.vendor === 'pgw') return 'PGW'
      if (this.vendor === 'igc') return 'Import Glass Corp'
      return ''
    },
    headerBackgroundColor() {
      if (this.vendor === 'mygrant') return 't-bg-blue-200'
      if (this.vendor === 'pgw') return 't-bg-red-200'
      if (this.vendor === 'igc') return 't-bg-yellow-200'
      return null
    },
    priceLevelSelected() {
      const pl = this.$job.job.selected_price_level
      return pl && pl > 0
    }
  },
  watch: {
    warehouseId: function(newVal, oldVal) {
      if (oldVal !== null) {
        this.recheckVendor()
      }
    },
    accountNumber: async function(newVal, oldVal) {
      if (oldVal !== null) {
        await this.getShopVendorWarehouses()
      }
    }
  },
  created() {
    this.warehouseId = this.defaultWarehouseId
    this.accountNumber = this.defaultCredentials
    if (this.standalone) {
      this.standaloneCreated(true)
    }
  },
  methods: {
    async standaloneCreated(skipDefault = false) {
      await this.getCredentials()
      if (this.vendor === 'mygrant') {
        await this.getShopVendorWarehouses(skipDefault)
      }
    },
    close() {
      if (this.standalone) {
        this.$emit('close')
      } else {
        this.$store.commit('partVendors/setSearchResultsVisible', {
          vendor: this.vendor,
          partNumber: this.nagsPart.detail.partno,
          visible: false
        })
      }
    },
    addPart() {
      if (this.standalone) {
        this.$emit('add')
      } else {
        const tempPart = Object.assign({}, this.nagsPart)
        this.$emit(
          'addPart',
          tempPart,
          tempPart.children,
          this.vendor,
          undefined,
          undefined,
          true
        )
      }
    },
    addAndLinkPart(cartItemId) {
      if (this.standalone) {
        this.$emit('addPart', {
          vendor: 'glaxis',
          cartItemId
        })
      } else {
        const tempPart = Object.assign({}, this.nagsPart)
        tempPart.detail.glaxis_order_item_id = cartItemId
        this.$emit('addPart', tempPart, tempPart.children)
      }
    },
    getAccountNameByNumber(number) {
      let name = null
      this.credentials.forEach((i) => {
        if (i.account_number === number) {
          name = i.name
        }
      })
      return name
    },
    recheckVendor() {
      this.$emit('recheckVendor', {
        vendor: this.vendor,
        warehouseId: this.warehouseId,
        accountNumber: this.accountNumber,
        accountName: this.getAccountNameByNumber(this.accountNumber)
      })
    },
    async getCredentials() {
      const { data } = await Api.get(`/api/shop/${this.shopId}/getVendorCredentials`)
      this.allCredentials = data
    },
    async getShopVendorWarehouses(skipDefault = false) {
      const selectedCredentials = this.allCredentials.filter(
        c => c.account_number === this.accountNumber
      )

      const { data } = await Api.post('/api/shopVendorWarehouses', {
        vendorCredentialId: selectedCredentials[0].id,
        vendor: 'mygrant'
      })

      this.mygrantWarehouses = data

      if (!skipDefault) {
        this.selectDefaultWarehouse()
      }
    },
    selectDefaultWarehouse() {
      let recheck = false

      if (this.warehouseId === null) {
        recheck = true
      }

      if (this.mygrantWarehouses.length === 0) {
        this.warehouseId = null
      }

      for (const warehouse of this.mygrantWarehouses) {
        if (warehouse.default) {
          if (this.warehouseId === warehouse.warehouseId) {
            recheck = true
          } else {
            this.warehouseId = warehouse.warehouseId
          }

          break
        }
      }

      if (recheck) {
        this.recheckVendor()
      }
    }
  },
  components: {
    ResultsTable
  }
}
</script>

<style lang="scss">
.vendor-results-dialog {
  @apply t-font-sans;
  .el-dialog__body {
    @apply t-p-0;
  }
}
</style>
