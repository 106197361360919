import 'vue-awesome/icons/spinner'
import 'vue-awesome/icons/circle'
import 'vue-awesome/icons/copy'
import 'vue-awesome/icons/trash'
import 'vue-awesome/icons/edit'
import 'vue-awesome/icons/times'
import 'vue-awesome/icons/hashtag'
import 'vue-awesome/icons/flag'
import 'vue-awesome/icons/comment-dots'
import 'vue-awesome/icons/minus-circle'
import 'vue-awesome/icons/caret-down'
import 'vue-awesome/icons/caret-left'
import 'vue-awesome/icons/cart-plus'
import 'vue-awesome/icons/angle-down'
import 'vue-awesome/icons/calendar-alt'
import 'vue-awesome/icons/cloud-download-alt'
import 'vue-awesome/icons/map-marker-alt'
import 'vue-awesome/icons/angle-up'
import 'vue-awesome/icons/globe-americas'
import 'vue-awesome/icons/th-list'
import 'vue-awesome/icons/save'
import 'vue-awesome/icons/file'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/info-circle'
import 'vue-awesome/icons/regular/grin'
import 'vue-awesome/icons/exchange-alt'
import 'vue-awesome/icons/cog'
import 'vue-awesome/icons/plus-circle'
import 'vue-awesome/icons/user-check'
import 'vue-awesome/icons/calendar'
import 'vue-awesome/icons/check'
import 'vue-awesome/icons/caret-up'
import 'vue-awesome/icons/caret-right'
import 'vue-awesome/icons/ellipsis-h'
import 'vue-awesome/icons/check-circle'
import 'vue-awesome/icons/times-circle'
import 'vue-awesome/icons/clock'
import 'vue-awesome/icons/user-tie'
import 'vue-awesome/icons/user-cog'
import 'vue-awesome/icons/car'
import 'vue-awesome/icons/building'
import 'vue-awesome/icons/map-signs'
import 'vue-awesome/icons/sign-in-alt'
import 'vue-awesome/icons/sign-out-alt'
import 'vue-awesome/icons/eye'
import 'vue-awesome/icons/eye-slash'
import 'vue-awesome/icons/exclamation-circle'
import 'vue-awesome/icons/equals'
import 'vue-awesome/icons/images'
import 'vue-awesome/icons/barcode'
import 'vue-awesome/icons/qrcode'
import 'vue-awesome/icons/undo'
import 'vue-awesome/icons/briefcase'
import 'vue-awesome/icons/sync'
import 'vue-awesome/icons/pen'
import 'vue-awesome/icons/regular/address-book'
import 'vue-awesome/icons/regular/play-circle'
import 'vue-awesome/icons/sun'
import 'vue-awesome/icons/moon'
import 'vue-awesome/icons/cloud-sun'
import 'vue-awesome/icons/cloud-moon'
import 'vue-awesome/icons/cloud'
import 'vue-awesome/icons/cloud-showers-heavy'
import 'vue-awesome/icons/cloud-sun-rain'
import 'vue-awesome/icons/cloud-moon-rain'
import 'vue-awesome/icons/bolt'
import 'vue-awesome/icons/snowflake'
import 'vue-awesome/icons/smog'
import 'vue-awesome/icons/regular/trash-alt'
import 'vue-awesome/icons/receipt'
import 'vue-awesome/icons/phone'
import 'vue-awesome/icons/cogs'
import 'vue-awesome/icons/comment-alt'
import 'vue-awesome/icons/paste'
import 'vue-awesome/icons/cart-arrow-down'
import 'vue-awesome/icons/tags'
import 'vue-awesome/icons/warehouse'
import 'vue-awesome/icons/calendar-minus'
import 'vue-awesome/icons/calendar-times'
import 'vue-awesome/icons/home'
import 'vue-awesome/icons/percentage'
import 'vue-awesome/icons/dollar-sign'
import 'vue-awesome/icons/arrow-up'
