var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", { attrs: { data: _vm.data } }, [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("Data Exported")
    ]),
    _c("div", [
      _vm._v(
        " " +
          _vm._s(_vm.data.details.count) +
          " " +
          _vm._s(_vm.data.details.count > 1 ? "records were" : "record was") +
          " exported from the "
      ),
      _c("b", [_vm._v(_vm._s(_vm.data.details.table))]),
      _vm._v(" table. A copy can be found "),
      _c("a", { attrs: { href: _vm.data.details.url[0] } }, [_vm._v("here")]),
      _vm._v(" (expires in 72 hours). ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }