import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import Priceleveltier from '@/scripts/objects/priceleveltier.js'

export default class Pricelevel extends ObjectTracker {
  id = -1
  created = -1
  price_level_name = ''
  is_default = 0
  modified = -1
  shop_id = -1

  _priceleveltiers = [] // not stored in db

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
    this._priceleveltiers = []
    if (initialObject && initialObject._priceleveltiers) {
      initialObject.priceleveltiers = initialObject._priceleveltiers
    }
    if (initialObject && initialObject.priceleveltiers) {
      for (var i = 0; i < initialObject.priceleveltiers.length; i++) {
        this._priceleveltiers.push(new Priceleveltier(initialObject.priceleveltiers[i]))
      }
    }
  }

  static getPricelevels (shopId, callbackFunction) {
    Api({ url: `/api/shop/${shopId}/pricelevel` }).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new Pricelevel(val))
      })
      callbackFunction(objects)
    })
  }

  createUpdatePricelevel (shopId, callbackFunction, errorCallbackFunction = null) {
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    return Api.post(`/api/shop/${this.shop_id}/pricelevel/${this.id}`, this).then(res => {
      callbackFunction(new Pricelevel(res.data))
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  deletePricelevel (shopId, successCallbackFunction, errorCallbackFunction = null) {
    if (!this.id || this.id <= 0) {
      successCallbackFunction()
    }
    return Api.delete(`/api/shop/${this.shop_id}/pricelevel/${this.id}`, this).then(res => {
      successCallbackFunction()
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  getPriceLevelTier (inAmount) {
    if (this._priceleveltiers.length > 0) {
      var tempAmount = parseFloat(inAmount)
      for (var i = 0; i < this._priceleveltiers.length; i++) {
        var tempTier = this._priceleveltiers[i]
        if (tempAmount > tempTier.tier_min && tempAmount < tempTier.tier_max) {
          return tempTier
        }
      }
    }
    return null
  }
}
