<template>
  <div class="t-font-sans">
    <ChatButton class="t-z-50" v-if="connected"/>
    <Dialog/>
  </div>
</template>

<script>
import Dialog from './Dialog'
import ChatButton from './ChatButton'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  computed: {
    connected: function() {
      return this.$store.state.chat.connected
    }
  },
  created: async function () {
    this.$store.dispatch(chatTypes.namespace + chatTypes.actions.init)
  },
  components: {
    Dialog,
    ChatButton
  }
}
</script>
