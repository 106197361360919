var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { value: _vm.value, title: "Generate PDF", "append-to-body": "" },
      on: {
        input: function($event) {
          return _vm.$emit("input")
        },
        open: _vm.onOpen
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "scheduler-pdf-dialog"
        },
        [
          _c(
            "div",
            { staticClass: "t-flex t-justify-between my-4" },
            [
              _c("pdf-type-select", {
                attrs: {
                  copy: _vm.copy,
                  template: "select",
                  userShopPdfDefault: _vm.userShopPdfDefault
                },
                on: {
                  "update:copy": function($event) {
                    _vm.copy = $event
                  }
                }
              }),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.collated,
                    callback: function($$v) {
                      _vm.collated = $$v
                    },
                    expression: "collated"
                  }
                },
                [_vm._v("Collated")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "t-flex t-justify-center my-4" },
            _vm._l(_vm.actions, function(action) {
              return _c(
                "md-icon-button",
                {
                  key: action.key,
                  attrs: {
                    size: "normal",
                    icon: action.icon,
                    loading: _vm.loading === action.key,
                    disabled: !!_vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.getPdf(action.key)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(action.label) + " ")]
              )
            }),
            1
          ),
          _c("div", [
            _vm.data
              ? _c("object", {
                  staticClass: "pdf-preview-object",
                  attrs: { data: _vm.data }
                })
              : _vm._e()
          ])
        ]
      ),
      _c("div", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        attrs: { slot: "footer" },
        slot: "footer"
      }),
      _c("a", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "dl",
        attrs: { href: this.data, download: _vm.filename }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }