import * as R from 'ramda'
import { mergeSpec, anyProp } from './ramda.helpers'
import {
  subtractPercent,
  addPercent,
  calculateDiscount,
  round,
  subtractPercentToMatch
} from './totals.helpers'

export const updateAutoAdd = (autoAdd, catalog) => {
  const { part_to_add, part_number, part_post, value } = autoAdd
  const addOrRemove = value ? R.append(part_to_add) : R.without([part_to_add])
  const c = R.map(
    R.map(
      R.when(
        R.propEq('nagsid', part_number),
        R.evolve({
          variations: R.map(
            R.when(
              R.propEq('code', part_post),
              R.evolve({
                auto_add: addOrRemove
              })
            )
          )
        })
      )
    )
  )(catalog)
  return c
}

export const getCallouts = R.pipe(
  R.juxt([
    R.applySpec({
      callout: R.path(['parent', 'options', 'callout']),
      label: () => 'Glass'
    }),
    R.pipe(
      R.propOr([], 'hardware'),
      R.uniqBy(R.path(['options', 'callout'])),
      R.map(
        R.applySpec({
          callout: R.path(['options', 'callout']),
          label: R.prop('type')
        })
      )
    )
  ]),
  R.unnest,
  R.filter(R.prop('callout')),
  R.sortBy(R.prop('callout'))
)

const checkStringProp = R.propEq(R.__, '1')
const checkTempered = part => {
  return (
    part.is_glass === '1' &&
    !['CUSTOM', 'CUST'].includes(part.nagsid) &&
    part.part_number?.length > 2 &&
    !['FW', 'DW'].includes(part.part_number.substring(0, 2)) &&
    ['F', 'D'].includes(part.part_number[0])
  )
}

const applyKit = (key, rates) =>
  mergeSpec({
    materials_price: R.always(rates[key]?.amount),
    flat: () => +(rates?.[key]?.type === 'flat')
  })

const applyAdhesiveRates = rates =>
  R.cond([
    [R.propEq('part_number', 'HAH000004'), applyKit('standard_kit', rates)],
    [R.propEq('part_number', 'HAH000448'), applyKit('fast_kit', rates)],
    [R.T, applyKit('high_kit', rates)]
  ])

export const applyRatesToPart = rates =>
  R.pipe(
    mergeSpec({
      discount: R.cond([
        [checkTempered, () => rates?.discounts?.tempered || 0],
        [checkStringProp('is_oem'), () => rates?.discounts?.oem || 0],
        [checkStringProp('is_glass'), () => rates?.discounts?.glass || 0],
        [
          checkStringProp('moulding_discount'),
          () => rates?.discounts?.moulding || 0
        ],
        [R.T, R.prop('discount')]
      ]),
      labor_price: R.cond([
        [
          R.anyPass([
            checkStringProp('is_glass'),
            checkStringProp('is_oem'),
            checkStringProp('is_premium')
          ]),
          R.ifElse(
            checkTempered,
            R.pipe(
              R.prop('hours'),
              R.multiply(rates?.labor_hourly_tempered || 0),
              R.add(rates?.labor_flat_tempered || 0)
            ),
            R.pipe(
              R.prop('hours'),
              R.multiply(rates?.labor_hourly || 0),
              R.add(rates?.labor_flat || 0)
            )
          )
        ],
        [R.propEq('description', 'Chip Repair'), () => rates?.chip_first || 0],
        [
          R.propEq('description', "Add'l Chip Repair"),
          () => rates?.chip_additional || 0
        ],
        // [R.propEq('part_number', 'TINTING'), () => 25],
        // [R.propEq('part_number', 'SUNSCREEN'), () => 25],
        [
          R.propEq('part_number', 'RECAL DYNAMIC'),
          () => rates?.safelite_recals?.dynamic || 0
        ],
        [
          R.propEq('part_number', 'RECAL STATIC'),
          () => rates?.safelite_recals?.static || 0
        ],
        [
          R.propEq('part_number', 'RECAL DUALMETHOD'),
          () => rates?.safelite_recals?.dual || 0
        ],
        [
          R.propEq('part_number', 'RECAL-RTL-DYNAMIC'),
          () => rates?.lynx_rtl_recals?.dynamic || 0
        ],
        [
          R.propEq('part_number', 'RECAL-RTL-STATIC'),
          () => rates?.lynx_rtl_recals?.static || 0
        ],
        [
          R.propEq('part_number', 'RECAL-RTL-BOTH'),
          () => rates?.lynx_rtl_recals?.dual || 0
        ],
        [
          R.propEq('part_number', 'RECAL-DLR-DYNAMIC'),
          () => rates?.lynx_dlr_recals?.dynamic || 0
        ],
        [
          R.propEq('part_number', 'RECAL-DLR-STATIC'),
          () => rates?.lynx_dlr_recals?.static || 0
        ],
        [
          R.propEq('part_number', 'RECAL-DLR-BOTH'),
          () => rates?.lynx_dlr_recals?.dual || 0
        ],
        [R.propEq('description', 'Chip Repair'), () => rates?.chip_first || 0],
        [R.T, () => 0]
      ])
    }),
    R.cond([
      [checkStringProp('is_adhesive_kit_part'), applyAdhesiveRates(rates)],
      [
        R.T,
        mergeSpec({
          materials_price: R.converge(subtractPercent, [
            R.prop('list_price'),
            R.prop('discount')
          ])
        })
      ]
    ]),

    mergeSpec({
      total_price: R.converge(R.add, [
        R.converge(R.multiply, [
          R.ifElse(R.prop('flat'), R.always(1), R.propOr(1, 'quantity')),
          R.prop('materials_price')
        ]),
        R.prop('labor_price')
      ])
    }),
    R.when(
      R.propEq('is_adhesive_kit_part', '1'),
      R.unless(
        R.converge(R.equals, [R.prop('list_price'), R.prop('materials_price')]),
        mergeSpec({
          discount: R.converge(calculateDiscount, [
            R.prop('materials_price'),
            R.prop('list_price')
          ])
        })
      )
    )
  )

export const retailGetDiscountAndTotal = (part) => {
  const temp = {}
  temp.discount = calculateDiscount(part.materials_price, part.list_price)
  temp.total_price = part.materials_price + part.labor_price
  return temp
}

const getSum = R.pipe(R.omit(['flat']), R.values, R.filter(R.lt(0)), R.sum)
const getModulo = R.pipe(getSum, R.modulo(R.__, 10), R.subtract(19))

const roundUpToValue = (v, key, job, tier) => {
  let tempValue = 0
  if (tier.round_to_value < 0) {
    tempValue = (v.total % 1).toFixed(2)
    if (tempValue > 0) tempValue = 1 - tempValue
    else tempValue = 0
  } else {
    tempValue = (((v.total / 10) % 1).toFixed(3) * 10)
    if ((tier.round_to_value / tempValue) < 1) {
      tempValue = (10 + tier.round_to_value) - tempValue
    } else {
      tempValue = (tier.round_to_value) - tempValue
    }
  }

    if (key === 'labor' && job.labor_tax_percent) {
      tempValue = subtractPercentToMatch(job.labor_tax_percent)(tempValue)
    }
  return v[key] + tempValue
}

export const applyPricelevelToPart = ({ $job, price, adhesive_qty }) =>
  R.pipe(
    R.propOr([], 'tiers'),
    R.find(
      R.allPass([
        R.propSatisfies(R.gte(price), 'tier_min'),
        R.propSatisfies(R.lte(price), 'tier_max')
      ])
    ),
    R.when(R.identity, tier =>
      R.pipe(
        R.applySpec({
          materials: R.cond([
            [
              R.prop('is_cost_plus_flat'),
              R.pipe(R.prop('add_material'), R.add(price))
            ],
            [
              R.prop('is_cost_plus_percent'),
              R.pipe(R.prop('add_material'), addPercent(price))
            ],
            [R.T, R.prop('add_material')]
          ]),
          labor: R.prop('add_labor'),
          adhesive: R.ifElse(
            R.prop('auto_add_adhesive'),
            R.pipe(
              R.ifElse(
                R.propEq('auto_add_adhesive_flat_or_each', 'each'),
                R.pipe(
                  R.prop('auto_add_adhesive_amount'),
                  R.multiply(+adhesive_qty)
                ),
                R.prop('auto_add_adhesive_amount')
              )
            ),
            R.always(0)
          ),
          flat: R.propEq('auto_add_adhesive_flat_or_each', 'flat')
        }),
        mergeSpec({
          total: R.pipe(
            R.when(
              () => tier.tax_included && !$job.job.insurance_fleet_tax_exempt,
              R.evolve({
                materials: v => addPercent(v, $job.materials_tax_percent),
                adhesive: v => addPercent(v, $job.materials_tax_percent),
                labor: v => addPercent(v, $job.labor_tax_percent)
              })
            ),
            getSum
          )
        }),
        R.when(() => tier.round_to9, mergeSpec({
          labor: v => roundUpToValue(v, 'labor', $job, tier),
          total: v => roundUpToValue(v, 'total', $job, tier)
        })),
        R.map(round)
      )(tier)
    )
  )($job.pricelevel)

export const isPoPart = R.anyPass([
  R.propEq('order_status', 'No Buy'),
  R.prop('is_inventory_part'),
  R.prop('purchaseOrderId')
])

export const deepRemoveItem = R.pipe(
  R.dissoc('item'),
  R.evolve({
    hardware: R.map(
      R.evolve({
        variations: R.map(R.dissoc('item'))
      })
    ),
    oem: R.map(R.dissoc('item')),
    interchanges: R.map(R.dissoc('item')),
    parent: R.evolve({
      variations: R.map(
        R.pipe(
          R.dissoc('item'),
          R.evolve({
            oem: R.map(R.dissoc('item')),
            interchanges: R.map(R.dissoc('item'))
          })
        )
      ),
      hardware: R.map(
        R.evolve({
          variations: R.map(R.dissoc('item'))
        })
      )
    })
  })
)

export const getAdhesiveType = R.cond([
  [R.propEq('part_number', 'HAH000004'), R.always('standard_kit')],
  [R.propEq('part_number', 'HAH000448'), R.always('fast_kit')],
  [R.T, R.always('high_kit')]
])

export const checkAdhesiveRatesFlat = rates =>
  R.ifElse(
    R.propEq('is_adhesive_kit_part', '1'),
    R.pipe(
      R.propOr({}, R.__, rates),
      R.unless(R.isNil, R.propEq('type', 'flat'))
    ),
    R.F
  )

export const shopAdhesiveDefaultType = ({ adhesive_default }) => {
  if (adhesive_default === 'high_modulus') return 'high-modulus'
  if (adhesive_default === 'fast_cure') return 'fast-cure'
  if (adhesive_default === 'standard') return 'standard'
  return null
}
