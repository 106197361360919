<template>
  <div>
    <b-modal
      id="dashboardDialog"
      size="lg"
      v-model="showDashboardDialog"
      @show="dialogAboutToAppear"
      title="Dashboard Controls">
      <b-container fluid>
        <div class="autoAddCheckList">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="w-75">Chart</th>
                <th style="text-align:right" scope="col">Display</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(chart, index) in userChartOptions" :key="index">
                <td>{{chart.name}}</td>
                <td style="text-align:right">
                  <b-form-checkbox
                    v-model="chart.visible"
                    :value="true">
                  </b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-container>
      <div slot="modal-footer" class='w-100'>
        <div>
          <b-row style="text-align:center">
            <b-col>
              <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="updateUserCharts">Save</button>
            </b-col>
            <b-col>
              <button type='button' class='btn btn-secondary' variant="primary" @click="cancelChartsUpdate">Cancel</button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Chart from '@/scripts/objects/chart'
import { chartMasterList } from '@/scripts/helpers/graph.helpers.js'

export default {
  data () {
    return {
      showDashboardDialog: false,
      userChartOptions: [],
      chartMasterList: chartMasterList
    }
  },
  // props: {
  //   charts: Array
  // },
  methods: {
    dialogAboutToAppear: function () {
      let self = this
      self.userChartOptions = []
      let visibilityArray = []
      Chart.getUserCharts(function (res) {
        let responseKeys = Object.keys(res)
        if (responseKeys.includes('dataValues')) {
          if (res.dataValues.chartvisiblesettings.length > 0) {
            visibilityArray = res.dataValues.chartvisiblesettings.split('|')
          } else if (res.dataValues.chartvisiblesettings.length === 0) {
            visibilityArray.push(res.dataValues.chartvisiblesettings)
          }
        } else {
          if (res.chartvisiblesettings && res.chartvisiblesettings.length > 0) {
            visibilityArray = res.chartvisiblesettings.split('|')
          } else if (res.chartvisiblesettings && res.chartvisiblesettings.length === 0) {
            visibilityArray.push(res.chartvisiblesettings)
          }
        }
        // converting visibility array of strings into array of objects
        for (var j = 0; j < self.chartMasterList.length; j++) {
          let found = false
          for (var i = 0; i < visibilityArray.length; i++) {
            let tempArray = visibilityArray[i].split('-')
            if (self.chartMasterList[j] === tempArray[0]) {
              found = true
            }
          }
          if (!found) {
            self.userChartOptions.push({name: self.chartMasterList[j], visible: true})
          } else {
            self.userChartOptions.push({name: self.chartMasterList[j], visible: false})
          }
        }
      })
    },
    updateUserCharts: function () {
      let self = this
      let visbilityStringToSave = ''
      // converting visibility array of objects back into a single string
      for (var k = 0; k < self.userChartOptions.length; k++) {
        if (self.userChartOptions[k].visible === false) {
          self.userChartOptions[k].visible = 0
          if (visbilityStringToSave.length === 0) {
            visbilityStringToSave = self.userChartOptions[k].name + '-' + self.userChartOptions[k].visible
          } else {
            visbilityStringToSave += '|' + self.userChartOptions[k].name + '-' + self.userChartOptions[k].visible
          }
        }
      }
      // building save object
      let chartSaveObj = {
        user_id: self.$store.state.user.id,
        chartvisiblesettings: visbilityStringToSave
      }
      Chart.createUpdateUserCharts(chartSaveObj, function () {
        // TODO: figure out how to tell the dashboard to refresh without using $root.$emit
        self.$root.$emit('refreshDashboard')
        // TODO: call global alert to confirm that update took effect
      })
      this.showDashboardDialog = false
    },
    cancelChartsUpdate: function () {
      this.showDashboardDialog = false
    }
  }
}
</script>

<style scoped>

</style>
