<template>
  <div class="t-w-full t-flex-shrink-0 t-flex t-items-center t-justify-center">
    <div
      v-if="loading"
      class="t-flex t-items-center t-justify-center"
    >
      <i class="el-icon-loading t-text-lg" />
    </div>
    <img
      v-else-if="thumb_url || image_url"
      :src="thumb_url || image_url"
      alt="Car image"
      class="t-w-full t-object-contain"
      @error="getRemoteImages"
    >
    <div
      v-else
      class="t-w-full t-h-full t-text-gray-400 t-flex t-flex-col
        t-justify-center t-items-center"
    >
      <i class="el-icon-picture-outline t-text-2xl" />
      <div class="t-text-2xs">No image</div>
    </div>
  </div>
</template>

<script>
export default {
  props: { vehicle: Object },
  data: () => ({
    loading: false,
    image_url: null,
    thumb_url: null,
    storageBaseUrl: 'https://storage.googleapis.com/nags-part-images'
  }),
  methods: {
    async getRemoteImages() {
      this.loading = true
      const { data } = await this.$unum
        .job()
        .get(`/nags/graphic/${this.vehicle.nags_id}`)
      this.thumb_url = data.thumb_url
      this.image_url = data.image_url
      this.loading = false
    },
    setImages() {
      this.image_url = this.vehicle.image_url
      if (!this.image_url && this.vehicle.nags_id) {
        this.image_url = `${this.storageBaseUrl}/${this.vehicle.nags_id}.jpg`
      }

      this.thumb_url = this.vehicle.thumb_url
      if (!this.thumb_url && this.vehicle.nags_id) {
        this.thumb_url = `${this.storageBaseUrl}/${this.vehicle.nags_id}.gif`
      }
    }
  },
  watch: {
    vehicle: async function(val) {
      if (val) {
        this.setImages()
      }
    }
  },
  async mounted() {
    if (this.vehicle) {
      this.setImages()
    }
  }
}
</script>
