var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    _vm._b(
      {
        staticClass: "md-circle-button",
        class:
          ((_obj = {
            danger: _vm.danger,
            primary: _vm.primary,
            ghost: _vm.ghost,
            ghostDark: _vm.ghostDark,
            warning: _vm.warning,
            purple: _vm.purple,
            success: _vm.success,
            pink: _vm.pink
          }),
          (_obj[_vm.diameter] = true),
          (_obj["no-hover"] = _vm.noHover),
          (_obj[_vm.size] = true),
          (_obj.filled = _vm.filled),
          _obj),
        attrs: { disabled: _vm.disabled, tabindex: _vm.noTab ? -1 : 1 },
        on: {
          click: function($event) {
            return _vm.$emit("click", $event)
          },
          mouseenter: function($event) {
            _vm.hovered = true
          },
          mouseleave: function($event) {
            _vm.hovered = false
          }
        }
      },
      "button",
      _vm.$attrs,
      false
    ),
    [
      _c("i", {
        class:
          ((_obj$1 = {}),
          (_obj$1[_vm.mdi] = true),
          (_obj$1[_vm.mdIcon] = true),
          (_obj$1[_vm.mdSize] = true),
          _obj$1)
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }