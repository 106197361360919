<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      title='Rejected Invoices'
      @close='close'
      width="1400px"
      @open='open'
      destroy-on-close
      custom-class="gb-el-dialog rejected-invoices-dialog"
      top="24px"
      ref="rejected-dialog"
      append-to-body
    >
      <shop-select />
      <el-checkbox v-model='includeVoid' class="show-void-checkbox">
        Include voided jobs
      </el-checkbox>
      <rejected-tabs />
    </el-dialog>
    <ediqueue-message-dialog />
  </div>
</template>

<script>
import ShopSelect from './ShopSelect'
import RejectedTabs from './RejectedTabs'
import EdiqueueMessageDialog from './EdiqueueMessageDialog'
import { get, call, sync } from 'vuex-pathify'

export default {
  components: { ShopSelect, RejectedTabs, EdiqueueMessageDialog },
  data() {
    return {
      loading: false,
      data: {},
    }
  },
  computed: {
    visible: sync('rejectedInvoices/visible'),
    tab: get('rejectedInvoices/tab'),
    includeVoid: sync('rejectedInvoices/table@filters.includeVoid')
  },
  methods: {
    updatedFilters: call('rejectedInvoices/updatedFilters'),
    changeShop: call('rejectedInvoices/changeShop'),
    onClose() {
      this.handleClose(this.close)
    },
    async close() {
      this.$emit('input', false)
      this.data = {}
      this.valid = true
    },
    async handleClose(done) {
      done()
    },
    open () {
      if (this.$store.state.user.shop_id && this.$store.state.shops) {
        for (var i = 0; i < this.$store.state.shops.length; i++) {
          if (this.$store.state.shops[i].id === this.$store.state.user.shop_id) {
            this.changeShop(this.$store.state.shops[i])
            break
          }
        }
      }
    }
  },
  watch: {
    includeVoid() {
      this.updatedFilters()
    }
  }
}
</script>

<style>
.show-void-checkbox {
  margin-left: 15px;
}
</style>