<template>
  <div>
    <el-tabs v-model="tab">
      <el-tab-pane v-for="item in tabs" :key="item.key" :name="item.key" lazy>
        <span slot="label">
          {{ item.label }}
          <i
            class="el-icon-warning-outline error-tab-icon"
            v-if="errors[item.key]"
          />
        </span>
        <div class="tab-container">
          <component :is="item.component" v-model="data" ref="tabs" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ConsumerContacts from './ConsumerContacts'
import ConsumerNotes from './ConsumerNotes'
import ConsumerVehicles from './ConsumerVehicles'
import ConsumerJobs from './ConsumerJobs'
import ConsumerUploads from './ConsumerUploads'
import ConsumerEdi from './ConsumerEdi'
import * as R from 'ramda'
import ConsumerSettings from './ConsumerSettings'
import ConsumerShopSettings from './ConsumerShopSettings'

export default {
  props: ['value', 'isMerge'],
  data() {
    return {
      tab: 'contacts',
      errors: {}
    }
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    tabs () {
      var tabs = [
        { label: 'Contact Info', key: 'contacts', component: ConsumerContacts },
        { label: 'Settings', key: 'settings', component: ConsumerSettings },
        { label: 'Shop Settings', key: 'shopsettings', component: ConsumerShopSettings },
        { label: 'Notes', key: 'notes', component: ConsumerNotes },
        { label: 'EDI', key: 'edi', component: ConsumerEdi },
        { label: 'Vehicles', key: 'vehicles', component: ConsumerVehicles },
      ]
      if (!this.isMerge) {
        tabs.push({ label: 'Previous Jobs', key: 'jobs', component: ConsumerJobs })
        tabs.push({ label: 'Uploads', key: 'uploads', component: ConsumerUploads })
      }
      return tabs
    }
  },
  methods: {
    async validate() {
      const refs = R.pipe(
        R.pathOr([], ['$refs', 'tabs']),
        R.filter(R.has('validate')),
        R.map(R.invoker(0, 'validate'))
      )(this)
      try {
        return R.all(R.identity)(await Promise.all(refs))
      } catch (error) {
        return false
      }
    }
  },
  watch: {
    async tab(v, old) {
      if (v !== old) {
        const validate = R.pipe(
          R.findIndex(R.propEq('key', old)),
          R.prop(R.__, this.$refs.tabs),
          R.prop('validate')
        )(this.tabs)
        if (validate) {
          this.errors = { ...this.errors, [old]: !(await validate()) }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tab-container {
  min-height: 400px;
}
.error-tab-icon {
  color: $danger;
}
</style>
