var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "600px",
        "append-to-body": "",
        visible: _vm.visible,
        "before-close": _vm.close,
        "custom-class": "shopping-cart-dialog"
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-xl t-text-gray-800",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("Checkout")]
      ),
      _c("div", { staticClass: "t-px-10 t-pb-8" }, [
        _c(
          "div",
          [
            _c(
              "el-divider",
              { staticClass: "t-mb-8", attrs: { "content-position": "left" } },
              [
                _c("span", { staticClass: "t-text-lg t-text-blue-700" }, [
                  _c("i", { staticClass: "el-icon-shopping-cart-full t-mr-3" }),
                  _c("span", { staticClass: "t-font-normal" }, [
                    _vm._v("Items")
                  ])
                ])
              ]
            ),
            _vm._l(_vm.items, function(item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass:
                    "t-flex t-rounded-md t-shadow t-px-3 t-py-2 t-mb-3 t-bg-gray-100"
                },
                [
                  _c("div", { staticClass: "t-flex-grow" }, [
                    _c(
                      "div",
                      {
                        staticClass: "t-font-medium t-text-gray-800 t-text-base"
                      },
                      [_vm._v(" " + _vm._s(item.itemNumber) + " ")]
                    ),
                    _c("div", [_vm._v(_vm._s(item.itemDescription))])
                  ]),
                  _c("div", { staticClass: "t-flex t-text-base" }, [
                    _c("div", [_vm._v(_vm._s(item.quantity))]),
                    _c("div", { staticClass: "t-pl-3 t-pr-2" }, [_vm._v("x")]),
                    _c(
                      "div",
                      { staticClass: "t-font-medium t-text-gray-700" },
                      [
                        _vm._v(
                          " $" +
                            _vm._s(Math.round(item.itemPrice * 100) / 100) +
                            " "
                        )
                      ]
                    )
                  ])
                ]
              )
            }),
            _c(
              "div",
              {
                staticClass:
                  "t-flex t-items-end t-justify-end t-mt-4 t-mb-5 t-mr-3"
              },
              [
                _c("span", { staticClass: "t-pr-4 t-text-base" }, [
                  _vm._v("Total:")
                ]),
                _c(
                  "span",
                  { staticClass: "t-text-lg t-font-medium t-text-gray-700" },
                  [_vm._v(" $" + _vm._s(_vm.orderTotal) + " ")]
                )
              ]
            )
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "t-pb-2" },
          [
            _c(
              "el-divider",
              { staticClass: "t-mb-8", attrs: { "content-position": "left" } },
              [
                _c("span", { staticClass: "t-text-lg t-text-blue-700" }, [
                  _c("i", { staticClass: "el-icon-coordinate t-mr-3" }),
                  _c("span", { staticClass: "t-font-normal" }, [
                    _vm._v("Shipping")
                  ])
                ])
              ]
            ),
            _vm.fetchingTerms
              ? _c("div", { staticClass: "t-text-2xl t-text-center" }, [
                  _c("i", { staticClass: "el-icon-loading" })
                ])
              : _c(
                  "div",
                  [
                    _vm.form.terms_code === "N/A" || _vm.ship_via.length === 0
                      ? _c("el-alert", {
                          attrs: {
                            title: "No shipping options available",
                            type: "warning",
                            closable: false,
                            center: ""
                          }
                        })
                      : _c(
                          "div",
                          _vm._l(_vm.ship_via, function(s, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass:
                                      "t-normal-case t-px-3 t-py-2 t-mb-3 t-font-normal t-flex\n                    t-items-center t-rounded-md t-shadow t-bg-gray-100",
                                    attrs: { label: s.ship_via_code },
                                    model: {
                                      value: _vm.form.ship_via_code,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "ship_via_code", $$v)
                                      },
                                      expression: "form.ship_via_code"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "t-mb-1 t-text-gray-800" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(s.ship_via_descriptions) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                  ],
                  1
                )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "t-pb-2" },
          [
            _c(
              "el-divider",
              { staticClass: "t-mb-8", attrs: { "content-position": "left" } },
              [
                _c("span", { staticClass: "t-text-lg t-text-blue-700" }, [
                  _c("i", { staticClass: "el-icon-coordinate t-mr-3" }),
                  _c("span", { staticClass: "t-font-normal" }, [
                    _vm._v("Order summary")
                  ])
                ])
              ]
            ),
            _vm.addingToCart
              ? _c("div", { staticClass: "t-text-2xl t-text-center" }, [
                  _c("i", { staticClass: "el-icon-loading" })
                ])
              : _c("div", [
                  _vm.orderSummary
                    ? _c(
                        "div",
                        [
                          _vm.orderSummary.response_code === "1"
                            ? _c("el-alert", {
                                attrs: {
                                  title: _vm.orderSummary.response_message,
                                  type: "warning",
                                  closable: false,
                                  center: ""
                                }
                              })
                            : _c("div", { staticClass: "t-flex" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-w-40 t-text-right t-text-gray-600 t-pr-3"
                                  },
                                  [
                                    _c("div", [_vm._v("Ship to name")]),
                                    _c("div", [_vm._v("Ship to address")]),
                                    _c("div", [_vm._v("Bill to name")]),
                                    _c("div", [_vm._v("Bill to address")]),
                                    _c("div", [_vm._v("Shipping cost")]),
                                    _c("div", [_vm._v("Tax")]),
                                    _c("div", [_vm._v("Total")])
                                  ]
                                ),
                                _c("div", { staticClass: "t-text-gray-700" }, [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.orderSummary.ship_to_name)
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.orderSummary.ship_to_address)
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.orderSummary.bill_to_name)
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.orderSummary.bill_to_address)
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.orderSummary.shipping_cost)
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.orderSummary.tax))
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.orderSummary.order_sub_total)
                                    )
                                  ])
                                ])
                              ])
                        ],
                        1
                      )
                    : _c("div", { staticClass: "t-text-center" }, [
                        _vm._v("Select shipping method")
                      ])
                ])
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-divider",
              { staticClass: "t-mb-8", attrs: { "content-position": "left" } },
              [
                _c("span", { staticClass: "t-text-lg t-text-blue-700" }, [
                  _c("i", { staticClass: "el-icon-chat-line-square t-mr-3" }),
                  _c("span", { staticClass: "t-font-normal" }, [
                    _vm._v("Comments")
                  ])
                ])
              ]
            ),
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 3,
                placeholder: "Enter your comments"
              },
              model: {
                value: _vm.form.comment,
                callback: function($$v) {
                  _vm.$set(_vm.form, "comment", $$v)
                },
                expression: "form.comment"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "t-px-10 t-py-5 t-text-right t-bg-gray-100 t-shadow" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.submitting,
                  expression: "submitting",
                  modifiers: { fullscreen: true, lock: true }
                }
              ],
              staticClass: "t-bg-blue-500 hover:t-bg-blue-400",
              class: {
                "t-opacity-50":
                  !_vm.form.terms_code ||
                  !_vm.form.ship_via_code ||
                  !_vm.orderSummary
              },
              attrs: {
                type: "primary",
                size: "medium",
                disabled:
                  !_vm.form.terms_code ||
                  !_vm.form.ship_via_code ||
                  !_vm.orderSummary
              },
              on: { click: _vm.submit }
            },
            [_vm._v(" Place order ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }