import * as filestack from 'filestack-js'

export const convertPdfData = (pdf) => {
  try {
    const dataBlob = new Blob([pdf.data], { type: 'application/pdf'})
    const URL = window.URL || window.webkitURL
    const data = URL.createObjectURL(dataBlob)
    return {data, dataBlob}
  } catch (error) {
    console.log('convertPdfData -> error:', error)
  }
}

export const generateFile = async (filePickerKey, dataBlob, filename) => {
  try {
    const client = filestack.init(filePickerKey)
    const file = await client.upload(dataBlob, {}, { filename: `${filename}.pdf` })
    return file
  } catch (error) {
    console.log('error:', error)
  }
}

export const gbFormTableActions = [
  {command: 'email', label: 'Email form'},
  {command: 'email_sign', label: 'Email to sign'},
  {command: 'sms', label: 'Text form'},
  {command: 'sms_sign', label: 'Text to sign'},
  {command: 'copy_sign_link', label: 'Copy signature link'},
  {command: 'download', label: 'Download'},
  {command: 'print', label: 'Print'}
]

export const gbFormPreviewShareActions = [
  { label: 'Email form', icon: 'email', key: 'email' },
  { label: 'Email to sign', icon: 'email', key: 'email_sign' },
  { label: 'Text form', icon: 'sms', key: 'sms' },
  { label: 'Text to sign', icon: 'sms', key: 'sms_sign' },
  { label: 'Copy signature link', icon: 'content_copy', key: 'copy_sign_link' }
]

export const initialBlock = `<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABECAYAAADaz4jLAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAKYSURBVHhe7`
  +`dzBS5NxHMfx/SEd2pqEh8gg2mmFjBhBbMLGwCBoGBhFl46BhwIDh10Mdok8dAgmwyRvmTE1iszplBUY1KGLMANrgVYgn549z2/zM/cI4irRfb7wBp/58zd/L/d42zwws7m5iXQ6jUQigVAodOiLx+MYGhrCxsaGEQBsjPGJVzgeCOP67RRGns9iM`
  +`v/p0JedeIebfffhPdWJx8+m8f2nhVEsFnHEfwIjkwUsf0XL9XT6Pbynw8i9mYcnFoth+Mmo68JW6eHoFMLRBDw+nw9r1m3jtqiVOhk4C080GhWGVfeVXuc2EQZw+eoNYVQTBiUMShiUMChhUMKghEEJgxIGJQxKGJQwKGFQwqCEQQmDEgYlDEoYl`
  +`DAoYVDCoIRBCYMSBiUMShiUMChhUMKghEEJgxIGJQxKGJQwKGFQwqCEQQmDEgYlDEoYlDAoYVDCoIRBCYMSBlXDqLyn8/PawcrtQM1Uw1j/bb/h98DMj1/uB2omYVDCoIRB2RhdXV2uGKVsL7wDC87FShbJtl5kVpxL+7oni5J9sYD+2vdWkelph`
  +`7eNMnvU7WfGfqy2jzNu67bPv8C4lLwGj9/v3yWGdbDqL74jhhl7fQp5c1mZxkM6P5e08PrnzEPW7BdGR+Cc88p4O79knmZrGjCsw2cGzF/6b2DMpWzc/LbH9wPj0dgMzl+MwVMoFNB35555mq1xwyjZB29HciDVNEbegk1mVxvW/m+M8dfLOHbmA`
  +`l7MzDofJrL44aP9JDzuGOZrvmX2hOGg8v8WG8aa3WCsflvH1NKXphrLLeLW3Qc42tGJ4dGXzoeJmP0bZkeMypiX+F4xKq+KugNX9jPr7XU1pPo9qpPL5RAMBpsqEolgcHAQ5XLZ7Ar8AXAuG5wAECTiAAAAAElFTkSuQmCC">`

export const signatureBlock = `<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATcAAABFCAYAAAAvv6FMAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAPQSURBVHh`
  +`e7drdS1NxGMDx/SteDOk2SUGEdtMY5GUoRQhjpEOqeREViJTCCIWw0VVCkXRlrUhCL3oxo4LyBSVbIYSMqAazFzCsm+ppv3POtvOyXqgb9/h94IM7x+M5B2FffmcaEtdMT09LPB6XlpYWaWpqAoAtr7m5Wbq6umRyctIpmT2VuB04PCDRzqSMXbs`
  +`rj3Lv5MlqEQC2vMcvCnLxxn3Ze/CI7Dt0XNY+2k2z4pZIJOToqYysvhcAqFvHzoxJR/cJO26zs7MSiUTk1YfaBwNAPYnt75WpqSkJpVIpGR8fJ24AVLhw9Y71NBqKxWKyvLxM3ACo8ODZa2lra5NQNBqVlZUV4gZAhYfP30praytxA6ALcQOgEnE`
  +`DoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6A`
  +`ScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoBJxA6AScQOgEnEDoFIgboUNkTfbUP5T7V8QgPoUiNu3H7Ith7gBuhA3Z4gboAtxc4a4AboQN2eIG6BLJW7t7e0yPz9fI27rMpHYIQ1hW3qhus9+bU8x21M5piHcIxM`
  +`F1/5EVor2prUdz647W2bsc1X3ea9nGV6yvmOdy3ntvwfP9X3Xq56rel/+IW6ALvcW1yQSiUiov79fMplMMG4LI66glMcXt8Ax61J0x60UlnK8AnEzP5so7QuPyKKzy5pCNrDvT3Ern3dx2Hu94P0Hh7gBupy9dEP6+voklMvlpLGxMRg3KzL+VY8`
  +`3LCYm7lWce+zoZCVdOoc5xh83O0RL3lia+c+4ufcTN2D72bWnQ+bm5iRk3uBDQ0Oy8XnTerP7xwTDrMDsaLjDEoxM9ThXdMwKrRTI9LA7bkul6NnRDEToH+JWfvx0x9O9/3eRI26ADi+L36Wzd0BSp89Z720rbmY2v3x1XgWnuiLyhsV+tKx+zuV`
  +`fOZVjUw5NJT5W8JzwWFyrwxpx8z7+mnvwfrZXiaj/M7e/WLndmnkqV27OAKhTl6/flpPp8xLeudv6av4530wlbv7xrHwq0fDFrTSe40pqxc2M/Rhqts2qzXsOa3VYDlGtuJWmvII03Od1X8c+xv5Z7339+g8Kg4OD0t3dDaBOJZNJGR0dlXw+77y`
  +`rzYj8BNfasjfQ3I/hAAAAAElFTkSuQmCC">`

const idedInitialBlock = (id) => {
  let splitInitialBlock = initialBlock.split(' ')

  return splitInitialBlock.join(` class="initials-block" id="initials-block-${id}" style="max-width: 67px; max-height: 68px;" `)
}

const idedSignatureBlock = (id) => {
  let splitSignatureBlock = signatureBlock.split(' ')

  return splitSignatureBlock.join(` class="signature-block" id="signature-block-${id}" style="max-width: 311px; max-height: 69px;" `)
}

export const handleBlocks = async (body) => {
  let signableBody = body

  if (signableBody.includes(initialBlock)) {
    let splitByInitialBlock = signableBody.split(initialBlock)

    signableBody = splitByInitialBlock[0]

    for (var i = 1; i < splitByInitialBlock.length; i++) {
      signableBody += idedInitialBlock(i) + splitByInitialBlock[i]
    }
  }

  if (signableBody.includes(signatureBlock)) {
    let splitBySignatureBlock = signableBody.split(signatureBlock)

    signableBody = splitBySignatureBlock[0]

    for (var j = 1; j < splitBySignatureBlock.length; j++) {
      signableBody += idedSignatureBlock(j) + splitBySignatureBlock[j]
    }
  }

  return signableBody
}

export const templateVariables = [
  {
    label: "CUSTOMER",
    options: [
      { label: "Company Name", value: "{customer company name}" },
      { label: "First", value: "{customer first}" },
      { label: "Last", value: "{customer last}" },
      { label: "Phone", value: "{customer phone}" },
      { label: "Email", value: "{customer email}" },
      { label: "Address", value: "{customer address}" }
    ]
  },
  {
    label: "BILL-TO",
    options: [
      { label: "Company Name", value: "{bill-to company name}" },
      { label: "First", value: "{bill-to first}" },
      { label: "Last", value: "{bill-to last}" },
      { label: "Phone", value: "{bill-to phone}" },
      { label: "Email", value: "{bill-to email}" },
      { label: "Address", value: "{bill-to address}" }
    ]
  },
  {
    label: "SCHEDULE",
    options: [
      { label: "Today's Date", value: "{today's date}" },
      { label: "Schedule Date", value: "{schedule date}" },
      { label: "Schedule Day", value: "{schedule day}" },
      { label: "Start time", value: "{schedule start time}" },
      { label: "End time", value: "{schedule end time}" },
      { label: "Phone", value: "{schedule contact phone}" },
      { label: "Contact name", value: "{schedule contact name}" },
      { label: "Address", value: "{schedule customer address}" }
    ]
  },
  {
    label: "BILLING INFO",
    options: [
      { label: "Job number", value: "{job-id #}" },
      { label: "Refferal", value: "{referral #}" },
      { label: "Policy number", value: "{policy #}" },
      { label: "Loss date", value: "{loss date}" },
      { label: "Dispatch date", value: "{dispatch date}" },
      { label: "Install date", value: "{install date}" },
      { label: "Deductible", value: "{deductible $}" },
      { label: "PO number", value: "{PO #}" },
      { label: "RO number", value: "{RO #}" },
      { label: "DOT number", value: "{DOT #}" },
      { label: "Lot number", value: "{lot #}" },
      { label: "Stock number", value: "{stock #}" }
    ]
  },
  {
    label: "VEHICLE",
    options: [
      { label: "Year", value: "{veh year}" },
      { label: "Make", value: "{veh make}" },
      { label: "Model", value: "{veh model}" },
      { label: "Style", value: "{veh style}" },
      { label: "Unit number", value: "{veh unit #}" },
      { label: "Plate number", value: "{veh plate #}" },
      { label: "VIN", value: "{veh vin #}" },
      { label: "License plate", value: "{veh license plate}" },
      { label: "Mileage", value: "{veh mileage}" }
    ]
  },
  {
    label: "TOTALS",
    options: [
      { label: "Invoice total", value: "{job total $}" },
      { label: "Balance due customer", value: "{customer balance $}" },
      { label: "Balance due bill to", value: "{bill to balance $}" }
    ]
  },
  {
    label: "VENDORS",
    options: [
      { label: "Csr name", value: "{csr name}" },
      { label: "Sale source name", value: "{sale source name}" },
      { label: "Sales rep name", value: "{sales rep name}" },
      { label: "Tech name", value: "{tech name}" },
      { label: "Location name", value: "{location name}" }
    ]
  },
  {
    label: "SHOP",
    options: [
      { label: "Name", value: "{shop name}" },
      { label: "Email", value: "{shop email}" },
      { label: "Address", value: "{shop address}" },
      { label: "Address city", value: "{shop address city}" },
      { label: "Address state", value: "{shop address state}" },
      { label: "Address ZIP", value: "{shop address zip}" },
      { label: "Full address", value: "{shop full address}" },
      { label: "Logo", value: "{shop logo}" }
    ]
  }
]
