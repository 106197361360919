var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex" },
    [
      _c(
        "div",
        {
          staticClass:
            "t-w-44 t-border-0 t-border-r t-border-solid t-border-r-gray-200 t-pt-10 t-space-y-2 t-flex-shrink-0"
        },
        [
          _vm.data.id
            ? [
                _vm.isCSR ||
                _vm.isSaleSource ||
                _vm.isSalesRep ||
                _vm.isTech ||
                _vm.isLocation
                  ? _c(
                      "TabListItem",
                      {
                        attrs: { active: _vm.activeTab === _vm.tabs.stats },
                        nativeOn: {
                          click: function($event) {
                            _vm.activeTab = _vm.tabs.stats
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.tabs.stats) + " ")]
                    )
                  : _vm._e(),
                _c(
                  "TabListItem",
                  {
                    attrs: { active: _vm.activeTab === _vm.tabs.contactInfo },
                    nativeOn: {
                      click: function($event) {
                        _vm.activeTab = _vm.tabs.contactInfo
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.tabs.contactInfo) + " ")]
                ),
                _c(
                  "TabListItem",
                  {
                    attrs: { active: _vm.activeTab === _vm.tabs.settings },
                    nativeOn: {
                      click: function($event) {
                        _vm.activeTab = _vm.tabs.settings
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.tabs.settings) + " ")]
                ),
                _c(
                  "TabListItem",
                  {
                    attrs: { active: _vm.activeTab === _vm.tabs.notes },
                    nativeOn: {
                      click: function($event) {
                        _vm.activeTab = _vm.tabs.notes
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.tabs.notes) + " ")]
                ),
                _vm.isCSR ||
                _vm.isSaleSource ||
                _vm.isSalesRep ||
                _vm.isTech ||
                _vm.isLocation
                  ? _c(
                      "TabListItem",
                      {
                        attrs: {
                          active: _vm.activeTab === _vm.tabs.commissions
                        },
                        nativeOn: {
                          click: function($event) {
                            _vm.activeTab = _vm.tabs.commissions
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.tabs.commissions) + " ")]
                    )
                  : _vm._e(),
                _vm.isSalesRep
                  ? _c(
                      "TabListItem",
                      {
                        attrs: {
                          active: _vm.activeTab === _vm.tabs.techsideAccount
                        },
                        nativeOn: {
                          click: function($event) {
                            _vm.activeTab = _vm.tabs.techsideAccount
                          }
                        }
                      },
                      [_vm._v(" Saleside Account ")]
                    )
                  : _vm._e(),
                _c(
                  "TabListItem",
                  {
                    attrs: { active: _vm.activeTab === _vm.tabs.documents },
                    nativeOn: {
                      click: function($event) {
                        _vm.activeTab = _vm.tabs.documents
                      }
                    }
                  },
                  [
                    _c("div", [_vm._v("Documents")]),
                    _vm.data.documents && _vm.data.documents.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "t-text-blue-700 t-w-5 t-h-5 t-bg-blue-100 t-rounded-full t-flex t-items-center t-justify-center t-text-xs"
                          },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.data.documents.length))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            : _c("TabListItem", { attrs: { active: true } }, [
                _vm._v(" Create ")
              ])
        ],
        2
      ),
      _c(
        "el-scrollbar",
        {
          staticClass: "t-flex-grow t-h-[700px] t-my-4 t-mr-2",
          attrs: {
            "wrap-class": "t-overflow-x-hidden t-flex-shrink-0 t-py-2 t-px-8"
          }
        },
        [
          _vm.data.id
            ? [
                _c("Stats", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === _vm.tabs.stats,
                      expression: "activeTab === tabs.stats"
                    }
                  ]
                }),
                _c("ContactInfo", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === _vm.tabs.contactInfo,
                      expression: "activeTab === tabs.contactInfo"
                    }
                  ]
                }),
                _c("Settings", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === _vm.tabs.settings,
                      expression: "activeTab === tabs.settings"
                    }
                  ]
                }),
                _c("Notes", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === _vm.tabs.notes,
                      expression: "activeTab === tabs.notes"
                    }
                  ]
                }),
                _c("Commissions", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === _vm.tabs.commissions,
                      expression: "activeTab === tabs.commissions"
                    }
                  ]
                }),
                _c("TechsideAccount", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === _vm.tabs.techsideAccount,
                      expression: "activeTab === tabs.techsideAccount"
                    }
                  ]
                }),
                _c("Documents", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === _vm.tabs.documents,
                      expression: "activeTab === tabs.documents"
                    }
                  ]
                })
              ]
            : _c("CreateFrom")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }