<template>
  <div>
    <div class="address-input" :class="{ pink, loose }">
      <el-autocomplete
        :prefix-icon="loading ? 'el-icon-loading' : 'fa fa-google'"
        :placeholder="poBox ? 'Enter zip' : 'Enter address'"
        :fetch-suggestions="fetchSuggestions"
        v-model="address"
        class="address-autocomplete"
        :class="{
          full,
          valid: value && value.full_address && value.google_place_id,
          invalid: value && !(value.full_address && value.google_place_id),
        }"
        @select="handleSelect"
        :popper-append-to-body="popperFix"
        :trigger-on-focus="!!defaultResults"
        :disabled="disabled"
        autocomplete="none"
        @focus="setToken"
        @blur="$emit('blur', $event)"
        :clearable="clearable"
        @clear="$emit('clear')"
      >
        <template slot-scope="{ item }">
          <address-input-block
            :result="item.id ? item : item.structured_formatting"
          />
        </template>
      </el-autocomplete>
      <el-checkbox
        v-if="withPoBox && !loose"
        label="PO BOX"
        border
        class="po-box-check"
        v-model="poBox"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
      />
      <el-input
        class="address-unit"
        v-model="unit"
        :placeholder="poBox ? 'PO BOX' : 'Unit/Apt'"
        :disabled="disabled"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        ref="unitRef"
      >
        <v-icon name="hashtag" slot="prefix" />
      </el-input>

      <gmap-autocomplete v-show="false" />
    </div>
    <div class="t-flex">
      <el-checkbox v-if="withPoBox && loose" label="P.O. BOX" v-model="poBox" />
      <div v-if="withDeliveryInstructions" class="delivery-instructions">
        <delivery-instructions-input
          v-model="extra"
          :address="value"
          v-if="showExtra"
          :auto-focus="addExtra"
        />
        <a href="#" @click.prevent="toggleExtra" :tabindex="-1">
          <span v-if="showExtra">Remove instructions</span>
          <span v-else>Add delivery instructions</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPlacesPrediction,
  getStateBounds,
  getPlaceDetails
} from '@/scripts/helpers/map.helpers'
import AddressInputBlock from './AddressInputBlock'
import DeliveryInstructionsInput from './DeliveryInstructionsInput'

/* global google */
export default {
  data() {
    return {
      suggestions: [],
      valid: null,
      token: null,
      extraLoading: false,
      addExtra: false,
      focusExtra: true
    }
  },
  components: { AddressInputBlock, DeliveryInstructionsInput },
  props: {
    value: Object,
    full: Boolean,
    loading: Boolean,
    disabled: Boolean,
    pink: Boolean,
    popperFix: Boolean,
    withPoBox: Boolean,
    loose: Boolean,
    withExtra: Boolean,
    clearable: Boolean,
    withDeliveryInstructions: {
      type: Boolean,
      default: true
    },
    defaultResults: Array
  },
  computed: {
    address: {
      get() {
        return this.value ? this.value.full_address : ''
      },
      set(value) {
        this.$emit('input', {
          full_address: value,
          unit: this.unit,
          po_box: +this.poBox
        })
      }
    },
    unit: {
      get() {
        return this.value ? this.value.unit : ''
      },
      set(unit) {
        this.$emit('input', { ...this.value, unit })
        this.$emit('change', { ...this.value, unit })
      }
    },
    poBox: {
      get() {
        return this.value ? !!this.value.po_box : false
      },
      set(po_box) {
        this.$emit('input', { ...this.value, po_box: +po_box })
        this.$emit('change', { ...this.value, po_box: +po_box })
      }
    },
    extra: {
      get() {
        return this.value?.extra
      },
      set(extra) {
        this.$emit('input', { ...this.value, extra })
        this.$emit('change', { ...this.value, extra })
      }
    },
    showExtra() {
      return this.addExtra || this.value?.extra
    }
  },
  methods: {
    async fetchSuggestions(input, cb) {
      if (input) {
        if (!this.bounds && this.state) {
          if (this.state) {
            this.bounds = await getStateBounds(this.state)
          }
        }
        const data = await getPlacesPrediction(
          input,
          this.bounds,
          this.poBox,
          this.token
        )
        cb(data)
      } else if (this.defaultResults) {
        cb(this.defaultResults)
      }
    },
    async handleSelect(result) {
      const { description, place_id, id } = result
      if (id) {
        this.$emit('input', result)
        this.$emit('change', result)
      } else {
        try {
          this.$refs.unitRef.focus()
        } catch {}
        this.$emit('input', {
          full_address: description,
          po_box: this.poBox,
          unit: this.unit
        })
        const address = await getPlaceDetails(place_id, this.token)
        const value = { ...address, po_box: +this.poBox, unit: this.unit }
        this.$emit('input', value)
        this.$emit('change', value)
      }
    },
    setToken(v) {
      this.$emit('focus', v)
      this.token = new google.maps.places.AutocompleteSessionToken()
    },

    toggleExtra() {
      if (this.showExtra) {
        this.extra = ''
        this.addExtra = false
      } else {
        this.addExtra = true
      }
    }
  }
}
</script>

<style lang="scss">
.address-input {
  display: flex;
  &.pink {
    .el-input.is-disabled .el-input__inner {
      color: #495057;
      background: #e8d9de;
    }
  }
  .form-wrap {
    width: 100%;
  }
  &:not(.loose) {
    .address-autocomplete {
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .address-unit {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-left: 22px;
        padding-right: 4px;
      }
    }
  }
  &.loose {
    .address-unit {
      margin-left: $padding-md;
      .el-input__prefix {
        margin-top: 11px;
        margin-left: 5px;
      }
    }
    .el-input__inner {
      height: 38px;
    }
    .el-input__icon {
      line-height: 38px;
    }
  }
  .address-autocomplete {
    flex-grow: 1;
  }
  .address-unit {
    width: 100px !important;
    flex-shrink: 0;
    .el-input__prefix {
      margin-top: 6px;
      font-size: 10px;
    }
  }
  .full {
    width: 100%;
  }

  .el-checkbox.is-bordered.po-box-check {
    background: #fff;
    border-radius: 0;
    flex-shrink: 0;
    width: 0;
    overflow-x: hidden;
    padding: 4px 0;
    border-left: 0;
    border-right: 0;
    transition: all 0.2s ease-in-out;
    display: block;
    &.is-checked {
      border-color: #dcdfe6;
    }
    span:last-child {
      font-size: $font-sm;
      padding-left: 5px;
    }
  }
  &:hover .el-checkbox.is-bordered.po-box-check,
  .el-checkbox.is-bordered.po-box-check.is-checked {
    width: 84px;
    padding: 4px 10px;
    overflow-x: hidden;
  }
  .valid {
    .el-input__prefix {
      color: #67c23a;
    }
  }
  .invalid {
    .el-input__prefix {
      color: $danger;
    }
  }
}
.delivery-instructions {
  line-height: 12px;
  flex-grow: 1;
  a {
    font-size: $font-xs;
    line-height: 12px;
    position: absolute;
    margin-left: 8px;
    color: $grayscale-7;
    &:hover {
      color: $sky-blue;
    }
  }
  .input-with-label {
    margin-top: $padding-md;
  }
}
</style>
