var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            "before-close": _vm.dialogDisappeared,
            "append-to-body": "",
            "custom-class":
              "t-font-sans chat-dialog saleside-job-dialog md:t-w-full"
          },
          on: { open: _vm.dialogAppeared }
        },
        [
          _vm.loading ? _c("loader") : _vm._e(),
          _c(
            "span",
            {
              staticClass: "t-text-blue-100 t-text-lg",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _vm._v(" Mobile Job Data "),
              _vm.salesidejob
                ? _c(
                    "span",
                    {
                      staticClass: "saleside-job-status-tag",
                      style: {
                        "background-color": _vm.salesidejob.job_id
                          ? "#337ab7"
                          : _vm.salesidejob.sent
                          ? "#33b747"
                          : "#b73333"
                      }
                    },
                    [
                      _vm.salesidejob.job_id
                        ? _c(
                            "a",
                            {
                              staticClass: "saleside-job-status-tag-link",
                              attrs: {
                                href: "/jobs/" + _vm.salesidejob.job_id,
                                target: "_blank"
                              }
                            },
                            [_vm._v("Job #" + _vm._s(_vm.salesidejob.job_id))]
                          )
                        : _vm.salesidejob.sent
                        ? _c("span", [_vm._v("Sent")])
                        : _c("span", [_vm._v("Unsent")])
                    ]
                  )
                : _vm._e()
            ]
          ),
          _c("div", { staticClass: "t-pt-6 t-pl-4" }, [
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Mobile #:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.id))
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Sales Rep (username):")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.salesrep_name) + " "),
                _vm.salesidejob.saleside_username
                  ? _c("span", [
                      _vm._v(
                        "(" + _vm._s(_vm.salesidejob.saleside_username) + ")"
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Customer:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(
                  _vm._s(_vm.salesidejob.customer_first_name) +
                    " " +
                    _vm._s(_vm.salesidejob.customer_last_name)
                )
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Customer Phone:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.customer_phone1))
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Customer Email:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.customer_email))
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Customer Address:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(
                  _vm._s(_vm.salesidejob.customer_address) +
                    ", " +
                    _vm._s(_vm.salesidejob.customer_address_city) +
                    ", " +
                    _vm._s(_vm.salesidejob.customer_address_state) +
                    " " +
                    _vm._s(_vm.salesidejob.customer_address_zip)
                )
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Contact:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.contact_name))
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Contact Phone:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.contact_phone))
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Contact Address:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(
                  _vm._s(_vm.salesidejob.contact_address) +
                    ", " +
                    _vm._s(_vm.salesidejob.contact_address_city) +
                    ", " +
                    _vm._s(_vm.salesidejob.contact_address_state) +
                    " " +
                    _vm._s(_vm.salesidejob.contact_address_zip)
                )
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Vehicle:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(
                  _vm._s(_vm.salesidejob.vehicle_year) +
                    " " +
                    _vm._s(_vm.salesidejob.vehicle_make_readable) +
                    " " +
                    _vm._s(_vm.salesidejob.vehicle_model_readable)
                )
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Vin:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.vehicle_vin))
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Repair:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.repair_type))
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Insurance:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.insurance))
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Apt Date:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.scheduled_date))
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Apt Time:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(
                  _vm._s(_vm.salesidejob.scheduled_time_start) +
                    " - " +
                    _vm._s(_vm.salesidejob.scheduled_time_end)
                )
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Loss Date:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(
                  _vm._s(_vm.formatToReportDate(_vm.salesidejob.loss_date))
                )
              ])
            ]),
            _c("div", { staticClass: "t-flex t-py-2" }, [
              _c(
                "div",
                { staticClass: "t-text-base t-font-bold t-text-gray-500" },
                [_vm._v("Notes:")]
              ),
              _c("div", { staticClass: "t-text-base t-pl-2" }, [
                _vm._v(_vm._s(_vm.salesidejob.install_notes))
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "w-100", attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "div",
                { staticClass: "float-left t-flex t-space-x-2" },
                _vm._l(_vm.salesidejob.documents, function(document, index) {
                  return _c(
                    "div",
                    { key: document.id, staticClass: "document-tag" },
                    [
                      _c(
                        "md-label-tag",
                        {
                          staticClass: "t-cursor-pointer",
                          attrs: { icon: "insert_photo", color: "#005AA3" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.openPreview(document)
                            }
                          }
                        },
                        [_vm._v(" SSMPic " + _vm._s(index + 1) + " ")]
                      )
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "primary", size: "sm" },
                      on: {
                        click: function($event) {
                          return _vm.dialogDisappeared()
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.busy,
                        expression: "busy"
                      }
                    ],
                    staticStyle: { width: "20px", height: "20px" },
                    attrs: { src: require("./../../assets/img/spinner.gif") }
                  }),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.busy &&
                            _vm.salesidejob.saleside_job_status !==
                              "archived" &&
                            !_vm.salesidejob.job_id &&
                            _vm.sent,
                          expression:
                            "!busy && salesidejob.saleside_job_status !== 'archived' && !salesidejob.job_id && sent"
                        }
                      ],
                      staticClass: "label label-gray label-sm t-mr-1",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.startButtonPressed(_vm.salesidejob)
                        }
                      }
                    },
                    [_vm._v("Start")]
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.busy &&
                            _vm.salesidejob.saleside_job_status !==
                              "archived" &&
                            !_vm.salesidejob.job_id &&
                            _vm.sent,
                          expression:
                            "!busy && salesidejob.saleside_job_status !== 'archived' && !salesidejob.job_id && sent"
                        }
                      ],
                      staticClass: "label label-gray label-sm t-mr-1",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.kickbackJobPressed(_vm.salesidejob)
                        }
                      }
                    },
                    [_vm._v("Kick Back")]
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.busy &&
                            _vm.salesidejob.saleside_job_status !==
                              "archived" &&
                            _vm.sent,
                          expression:
                            "!busy && salesidejob.saleside_job_status !== 'archived' && sent"
                        }
                      ],
                      staticClass: "label label-gray label-sm t-mr-2",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.archiveButtonPressed(_vm.salesidejob)
                        }
                      }
                    },
                    [_vm._v("Archive")]
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.busy &&
                            _vm.salesidejob.saleside_job_status ===
                              "archived" &&
                            _vm.sent,
                          expression:
                            "!busy && salesidejob.saleside_job_status === 'archived' && sent"
                        }
                      ],
                      staticClass: "label label-gray label-sm t-mr-2",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.unarchiveButtonPressed(_vm.salesidejob)
                        }
                      }
                    },
                    [_vm._v("Unarchive")]
                  ),
                  !_vm.busy && !_vm.loading && !_vm.sent
                    ? _c("span", { staticClass: "t-pr-2 t-italic" }, [
                        _vm._v("*Viewing unsent saleside job")
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("file-preview-dialog", {
        attrs: { doc: _vm.preview, "no-delete": true },
        model: {
          value: _vm.previewDialog,
          callback: function($$v) {
            _vm.previewDialog = $$v
          },
          expression: "previewDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }