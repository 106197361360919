var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quick-buttons" },
    _vm._l(_vm.options, function(item) {
      return _c(
        "div",
        {
          key: item.label,
          ref: "ref",
          refInFor: true,
          class: {
            "quick-divider": item.divider,
            selected: item.value === _vm.value
          }
        },
        [
          _c(
            "el-button",
            {
              ref: item.label,
              refInFor: true,
              attrs: { type: "text", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.$emit("click", item.value)
                }
              }
            },
            [_vm._v(" " + _vm._s(item.label) + " ")]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }