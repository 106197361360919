var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.data, size: "mini", loading: _vm.loading } },
    [
      _c("el-table-column", {
        attrs: { prop: "zoho_ticket_number", label: "ID", "min-width": "10" }
      }),
      _c("el-table-column", {
        attrs: { label: "Created", "min-width": "20" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c("div", [
                  _vm._v(
                    " " + _vm._s(_vm.getFormattedDate(props.row.created)) + " "
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "subject", label: "Title", "min-width": "20" }
      }),
      _c("el-table-column", {
        attrs: {
          prop: "description",
          label: "Status",
          "show-overflow-tooltip": true,
          "min-width": "25"
        }
      }),
      _c("el-table-column", {
        attrs: { label: "Status", "min-width": "15" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "gb-tag",
                  { attrs: { hex: _vm.statusColor(props.row.status) } },
                  [_vm._v(" " + _vm._s(props.row.status) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { "min-width": "10" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openTicket(props.row.id)
                          }
                        }
                      },
                      [_vm._v(" view ")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }