export const notificationPermissionsConfigSchema = [
  {
    type:'job',
    label:'Job',
    options: [
      {label:'Job Complete', value:'notificationjobcompleted'},
      {label:'Signature Received', value:'notificationsmssignaturereceived'},
      {label:'Payment Created', value: 'notificationpaymentcreated'},
      {label:'Right Part Received', value: 'notificationrightpartreceived'},
      {label:'Form Signed', value: 'notificationformsigned'}
    ]
  },
  {
    type:'scheduling',
    label:'Scheduling',
    options: [
      {label:'Job Received', value:'notificationjobreceived'},
      {label:'Job Rescheduled', value:'notificationjobrescheduled'},
      {label:'Job Canceled', value:'notificationjobcanceled'},
      {label:'Confirmation Declined', value:'notificationsmsconfirmationdeclined'},
      {label:'Web Lead Received', value:'notificationwebleadreceived'}
    ]
  },
  {
    type:'export',
    label:'Export',
    options: [
      {label:'Data Exported', value:'notificationdataexported'},
      {label:'Quickbooks Export Error', value:'notificationquickbooksexporterror'}
    ]
  },
  {
    type:'communications',
    label:'Communications',
    options: [
      {label:'SMS Received', value:'notificationsmsreceived'}
    ]
  }
]
  
export const notificationSettingPermissionIds = {
  notificationjobreceived: 60,
  notificationjobrescheduled: 61,
  notificationjobcanceled: 62,
  notificationjobcompleted: 63,
  notificationpaymentcreated: 64,
  notificationsmsreceived: 65,
  notificationsmsconfirmationdeclined: 66,
  notificationsmssignaturereceived: 67,
  notificationrightpartreceived: 68,
  notificationdataexported: 69,
  notificationquickbooksexporterror: 70,
  notificationwebleadreceived: 71,
  notificationformsigned: 72
}
