var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "scheduler-view-filter",
      attrs: { id: "schedulerViewButton" },
      on: { click: _vm.toggleView }
    },
    [
      _vm.isList
        ? _c("v-icon", { attrs: { name: "globe-americas", scale: "1.5" } })
        : _c("v-icon", { attrs: { name: "th-list", scale: "1.5" } }),
      _c(
        "b-tooltip",
        {
          ref: "tooltip",
          attrs: { target: "schedulerViewButton", placement: "right" }
        },
        [_vm._v(_vm._s(_vm.isList ? "Map View" : "Board view"))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }