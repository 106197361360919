<template>
  <el-dialog
    append-to-body
    :visible="!!url"
    :before-close="handleClose"
    top="50px"
  >
    <div class="t-flex t-justify-center">
      <img :src="url" class="t-max-h-[700px] t-max-w-full t-object-contain">
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    url: String
  },
  methods: {
    handleClose: function() {
      this.$emit('close')
    }
  }
}
</script>
