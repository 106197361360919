import { mutations, globalMutations } from './types';
import { getType, findNotificationIndex } from './helpers';

export default {
  [mutations.setIsOpened](state, payload) {
    state.isOpened = payload;
  },

  [mutations.setTab](state, tab) {
    state.activeTab = tab;
  },

  // add notifiation based on date in items
  [mutations.addNotifications](state, items) {
    // get rows of the tab we are targeting
    let tab = state.tabs[getType(items[0])];

    for (const item of items) {

      // don't add if notification is listed in locks
      if (tab.locks.includes(item.id)) {
        continue;
      }

      // find closest place to insert
      // since new notification wold be at the very top
      // overhead would not be significant
      const index = findNotificationIndex(tab.rows, item);

      // we can get null in case we already have item in the list
      // so we don't need to insert duplicate
      if (index === null) {
        continue;
      }

      tab.rows.splice(index, 0, item);
    }
  },

  [mutations.removeNotifications](state, items) {
    let tab = state.tabs[getType(items[0])];

    for (const item of items) {
      tab.rows = tab.rows.filter(el => el.id !== item.id);
    }
  },

  [mutations.moveNotifications]({ state, commit }, items) {
    if (!items.length) {
      return;
    }

    // remove notifications from original tab
    this.commit(globalMutations.removeNotifications, items);

    items = items.map(item => {
      item.is_read = !this.is_read;
      return item;
    });

    this.commit(globalMutations.addNotifications, items);
  },

  [mutations.setPage](state, { tab_type, page }) {
    state.tabs[tab_type].page = page;
  },

  [mutations.increaseCount](state, count) {
    state.unreadCount += count;
  },

  [mutations.setCount](state, count) {
    state.unreadCount = count;
  },

  [mutations.toggleReadAllLoader](state) {
    state.readAllLoader = !state.readAllLoader;
  },

  [mutations.lock](state, { tab_type, id }) {
    state.tabs[tab_type].locks.push(id);
  },

  [mutations.unlock](state, { tab_type, id }) {
    state.tabs[tab_type].locks
      = state.tabs[tab_type].locks.filter(el => el === id);
  },
};
