var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { staticStyle: { width: "100%" }, attrs: { data: _vm.data, size: "mini" } },
    [
      _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
      _vm._l(_vm.cols, function(col) {
        return _c("el-table-column", {
          key: col.id,
          attrs: { property: col.id, label: col.label },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function(scope) {
                  return [
                    col.type === "checkbox"
                      ? _c("el-checkbox", {
                          attrs: { value: !!scope.row[col.id] }
                        })
                      : _c("span", [
                          _vm._v(" " + _vm._s(scope.row[col.id]) + " ")
                        ])
                  ]
                }
              }
            ],
            null,
            true
          )
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }