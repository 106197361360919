var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fetching,
              expression: "fetching"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.notInGlassBiller }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "DocNumber", label: "Invoice #" }
          }),
          _c("el-table-column", {
            attrs: { prop: "TxnDate", label: "Invoice Date" }
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.formatBalance,
              prop: "TotalAmt",
              label: "Total (QBO)"
            }
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.formatBalance,
              prop: "Balance",
              label: "Balance (QBO)"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }