<template>
  <div v-bind:style='groupWrapperStyleObject' v-bind:class='groupWrapperClassObject'>
    <label v-if='title' for='title'>{{title}}</label>
    <div class='form-group'>
      <input
        ref='input'
        type='text'
        v-bind:class='classes'
        :placeholder='placeholder'
        :title='title'
        @blur='inputActive = false'
        @focus='inputActive = true'
        v-model='inputValue'
        :disabled='inDisabled'
        :style='groupInputStyleObject' />
        <div class="invalid-feedback">
          {{ internalFailMessage }}
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['placeholder', 'title', 'value', 'failMessage', 'successMessage', 'inDisabled', 'inStyle', 'required', 'inIsValid', 'isGroupInput'],
  data () {
    return {
      showFailureMessage: false,
      inputActive: false
    }
  },
  methods: {
    updateShowFailureMessage: function (activateShowFailureMessage) {
      this.showFailureMessage = activateShowFailureMessage
    },
    isValid: function () {
      if (this.value && this.value.length > 0) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var isThisValid = re.test(String(this.value).toLowerCase())
        this.$emit('update:inIsValid', isThisValid)
        return isThisValid
      } else {
        return !this.required
      }
    },
    focus: function () {
      this.$refs.input.focus()
    }
  },
  computed: {
    internalFailMessage: function () {
      if (this.isValid() || !this.showFailureMessage) {
        return ''
      } else if (this.failMessage) {
        return this.failMessage
      } else {
        if (this.required && (!this.value || this.value.length === 0)) {
          return '\'' + this.title + '\' is required.'
        } else {
          return 'Please enter in a valid email address.'
        }
      }
    },
    classes: function () {
      var prop = ['form-control', 'form-control-sm', 'autocomplete-off']
      if (this.required || (this.value && this.value.length > 0)) {
        prop.push(this.isValid() ? 'is-valid' : 'is-invalid')
      }
      return prop
    },
    inputValue: {
      get: function () {
        return this.value
      },
      set: function (changedVal) {
        this.$emit('update:value', changedVal)
      }
    },
    groupWrapperStyleObject: function () {
      var styles = {}
      if (this.isGroupInput) {
        styles = {
          'padding': '2px',
          'margin': '0px'
        }
        if (this.inDisabled) {
          styles['background-color'] = '#e9ecef'
        }
      }
      return styles
    },
    groupWrapperClassObject: function () {
      var classes = []
      if (this.isGroupInput) {
        classes = ['form-control', 'form-control-sm']
        if (!this.isValid() && !this.inDisabled) {
          classes.push('is-invalid')
        } else if (this.inputActive) {
          classes.push('is-valid')
        }
      }
      return classes
    },
    groupInputStyleObject: function () {
      var styles = {}
      if (this.isGroupInput) {
        styles = {
          'padding-left': '4px',
          'padding-right': '4px',
          'padding-top': '0px',
          'padding-bottom': '0px',
          'margin': '0px',
          'border': 'none',
          'font-family': 'Lato, sans-serif',
          'font-size': '14px',
          'line-height': '21px'
        }
      }
      return styles
    }
  }
}
</script>

<style>

</style>
