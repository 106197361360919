var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-px-12" }, [
    _c("table", { staticClass: "t-w-full" }, [
      _c("thead", [
        _c(
          "tr",
          {
            staticClass:
              "t-border-0 t-border-b t-border-solid t-h-8 t-text-xs\n          t-text-gray-500"
          },
          [
            _c("td", [_vm._v("Invoice date")]),
            _c("td", [_vm._v("Invoice #")]),
            _c("td", [_vm._v("Referral #")]),
            _c("td", [_vm._v("Customer")]),
            _c("td", [_vm._v("Third party")]),
            _c("td", [_vm._v("Days")]),
            _c("td", { class: { "pdf-no-display": !_vm.anyPaymentsOpen } }, [
              _vm._v("Payments")
            ]),
            _c("td", [_vm._v("Total invoice")]),
            _c("td", [_vm._v("Balance due")])
          ]
        )
      ]),
      _c(
        "tbody",
        [
          _vm._l(_vm.invoices, function(i, index) {
            return [
              _c(
                "tr",
                {
                  key: index + "row",
                  staticClass: "t-h-8 t-text-sm t-text-gray-600",
                  class: {
                    "t-border-0 t-border-b t-border-solid": !_vm
                      .paymentDetailsOpen[index]
                  }
                },
                [
                  _c("td", [_vm._v(_vm._s(_vm.formatDate(i.invoice_date)))]),
                  _c("td", [
                    _c(
                      "a",
                      {
                        attrs: { href: "/jobs/" + i.job_id, target: "_blank" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.shopId) +
                            "-" +
                            _vm._s(i.job_id) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("td", [_vm._v(_vm._s(i.referral_number))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(i.consumer_company_name || _vm.getConsumerName(i))
                    )
                  ]),
                  _c("td", [_vm._v(_vm._s(i.trading_partner))]),
                  _c("td", [_vm._v(_vm._s(i.days_old))]),
                  _c(
                    "td",
                    {
                      class: {
                        "pdf-no-display": !_vm.paymentDetailsOpen[index]
                      }
                    },
                    [
                      i.payments.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "t-flex t-items-center t-cursor-pointer",
                              on: {
                                click: function($event) {
                                  return _vm.openPayments(index)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "hover:t-font-bold",
                                class: _vm.paymentDetailsOpen[index]
                                  ? "el-icon-arrow-down"
                                  : "el-icon-arrow-right"
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "t-flex t-items-center t-rounded-full t-bg-gray-50\n                  t-border t-shadow-sm t-border-solid t-border-gray-300 t-px-2\n                  t-py-[2px] t-ml-2"
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-document-copy"
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "t-pl-2 t-text-gray-600" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(i.payments.length) + " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _c("td", [_vm._v("$" + _vm._s(i.total_balance))]),
                  _c("td", [_vm._v("$" + _vm._s(i.remaining_balance))])
                ]
              ),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.paymentDetailsOpen[index],
                      expression: "paymentDetailsOpen[index]"
                    }
                  ],
                  key: index + "payments",
                  staticClass: "t-border-0 t-border-b t-border-solid"
                },
                [
                  _c("td", { attrs: { colspan: "9" } }, [
                    _c("div", { staticClass: "t-py-1 t-pl-12 t-bg-gray-50" }, [
                      _c("table", { staticClass: "t-w-[500px]" }, [
                        _vm._m(0, true),
                        _c(
                          "tbody",
                          _vm._l(i.payments, function(p) {
                            return _c(
                              "tr",
                              {
                                key: p.id,
                                staticClass:
                                  "t-h-8 t-text-sm t-text-gray-600 t-border-0\n                      t-border-t t-border-solid"
                              },
                              [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.formatDate(p.date)))
                                ]),
                                _c("td", [_vm._v(_vm._s(p.type))]),
                                _c("td", [_vm._v(_vm._s(p.x_action_type))]),
                                _c("td", [_vm._v("$" + _vm._s(p.amount))])
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "t-h-8 t-text-xs t-text-gray-500" }, [
        _c("td", [_vm._v("Date")]),
        _c("td", [_vm._v("Type")]),
        _c("td", [_vm._v("X-ActionType")]),
        _c("td", [_vm._v("Amount")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }