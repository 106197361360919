import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import { AddPropertyWithWatcher } from '@/scripts/helpers/helpers.js'

export default class Part extends ObjectTracker {
  id = -1
  created = -1
  description = ''
  part_number = ''
  total_price = 0
  cost = 0
  job_id = null
  modified = -1
  apply_discount = false
  molding = '0'
  nags_part = '0'
  is_molding = '0'
  is_oem = '0'
  is_premium = '0'
  is_adhesive_kit_part = '0'
  color = ''
  mf_code = ''
  order = -1
  shop_id = null
  glass_graphic_data = ''
  nagsid = ''
  attachment_flag = 'N'
  glass_graphic_id = -1
  atchmnt_flag = 'N'
  clips_flag = 'N'
  mlding_flag = 'N'
  atchmnt_is_clip = 'N'
  atchmnt_is_mlding = 'N'
  atchmnt_dsc = ''
  prefix = ''
  part_num = -1
  ant_flag = 'N'
  blk_size1 = -1
  blk_size2 = -1
  encap_flag = 'N'
  hds_up_disp_flag = 'N'
  heated_flag = 'N'
  num_holes = 0
  slider_flag = 'N'
  solar_flag = 'N'
  thickness = 0
  wt = 0
  tube_qty = 0
  glass_callout = 0
  price_level_tier_vendor_price = 0
  price_level_tier_add_material = 0
  price_level_tier_add_labor = 0
  price_level_tier_auto_add_adhesive = '0'
  price_level_tier_auto_add_adhesive_at = 0
  price_level_tier_tax_included = '0'
  price_level_tier_round_to_nearest9 = '0'
  price_level_tier_this_instance_is_auto_added_adhesive = '0'
  price_level_tier_additional_add_labor_round_to9 = 0
  price_level_tier_list_price_tax_backed_out = 0
  price_level_tier_add_material_tax_backed_out = 0
  price_level_tier_total_material_tax_backed_out = 0
  price_level_tier_add_labor_tax_backed_out = 0
  price_level_tier_auto_add_adhesive_flat_or_each = 'each'
  inventory_part = 0
  po_id = null
  order_status = ''
  po_quantity = 1
  po_rate = 0
  po_substitution = ''
  po_memo = ''
  _receive_quantity = 1
  _tech_pickup_quantity = 1
  _quantity_received = 1
  receive_id = null
  pilkington_order_item_id = null
  american_order_item_id = null
  glaxis_order_item_id = null
  nielsen_moller_order_item_id = null
  tech_pickup_id = null
  materials_co_account_id = -1
  labor_co_account_id = -1
  item_id = null
  is_inventory_part = 0
  take_from_inventory = 0
  inventory_type_to_take = 'available'
  consumer_auto_add_id = null
  consumer_auto_add_type = ''

  _item = []
  _total_on_purchase_order = null
  _total_count = null
  _total_on_jobs = null
  _total_on_truck = null
  _total_available = null
  tech_pickup_status = null
  vendor_return_id = null
  _inventory_timestamp = null

  _job = []
  _is_saved = false
  _is_loaded_job_part = false
  _autoCompletePart = false
  _price_level_tier_is_flat_price = false
  _price_level_tier_is_cost_plus_flat = false
  _price_level_tier_is_cost_plus_percent = false
  _internal_po_id = null
  _purchase_order_part = null
  _skipMaterialsCalculation = false

  // Getters and Setters here are not included in the object properties/aren't stored in the DB
  get is_addl_chip () {
    return (this.description === 'Add\'l Chip Repair') ? '1' : '0'
  }

  set is_addl_chip (val) {
    if (val === '1') {
      this.description = 'Add\'l Chip Repair'
    } else {
      if (this.description === 'Add\'l Chip Repair') {
        this.description = ''
      }
    }
  }
  // # Created Saved Solution
  get strippedObject () {
    return {
      id: this.id,
      created: this.created,
      description: this.description,
      part_number: this.part_number,
      total_price: this.total_price,
      cost: this.cost,
      job_id: this.job_id,
      modified: this.modified,
      apply_discount: this.apply_discount,
      molding: this.molding,
      nags_part: this.nags_part,
      is_molding: this.is_molding,
      is_oem: this.is_oem,
      is_premium: this.is_premium,
      is_adhesive_kit_part: this.is_adhesive_kit_part,
      color: this.color,
      mf_code: this.mf_code,
      order: this.order,
      shop_id: this.shop_id,
      glass_graphic_data: this.glass_graphic_data,
      nagsid: this.nagsid,
      attachment_flag: this.attachment_flag,
      glass_graphic_id: this.glass_graphic_id,
      atchmnt_flag: this.atchmnt_flag,
      clips_flag: this.clips_flag,
      mlding_flag: this.mlding_flag,
      atchmnt_is_clip: this.atchmnt_is_clip,
      atchmnt_is_mlding: this.atchmnt_is_mlding,
      atchmnt_dsc: this.atchmnt_dsc,
      prefix: this.prefix,
      part_num: this.part_num,
      ant_flag: this.ant_flag,
      blk_size1: this.blk_size1,
      blk_size2: this.blk_size2,
      encap_flag: this.encap_flag,
      hds_up_disp_flag: this.hds_up_disp_flag,
      heated_flag: this.heated_flag,
      num_holes: this.num_holes,
      slider_flag: this.slider_flag,
      solar_flag: this.solar_flag,
      thickness: this.thickness,
      wt: this.wt,
      tube_qty: this.tube_qty,
      glass_callout: this.glass_callout,
      price_level_tier_vendor_price: this.price_level_tier_vendor_price,
      price_level_tier_add_material: this.price_level_tier_add_material,
      price_level_tier_add_labor: this.price_level_tier_add_labor,
      price_level_tier_auto_add_adhesive: this.price_level_tier_auto_add_adhesive,
      price_level_tier_auto_add_adhesive_at: this.price_level_tier_auto_add_adhesive_at,
      price_level_tier_tax_included: this.price_level_tier_tax_included,
      price_level_tier_round_to_nearest9: this.price_level_tier_round_to_nearest9,
      price_level_tier_this_instance_is_auto_added_adhesive: this.price_level_tier_this_instance_is_auto_added_adhesive,
      price_level_tier_additional_add_labor_round_to9: this.price_level_tier_additional_add_labor_round_to9,
      price_level_tier_list_price_tax_backed_out: this.price_level_tier_list_price_tax_backed_out,
      price_level_tier_add_material_tax_backed_out: this.price_level_tier_add_material_tax_backed_out,
      price_level_tier_total_material_tax_backed_out: this.price_level_tier_total_material_tax_backed_out,
      price_level_tier_add_labor_tax_backed_out: this.price_level_tier_add_labor_tax_backed_out,
      price_level_tier_auto_add_adhesive_flat_or_each: this.price_level_tier_auto_add_adhesive_flat_or_each,
      inventory_part: this.inventory_part,
      is_custom_part: this.is_custom_part,
      is_chip: this.is_chip,
      is_recal: this.is_recal,
      is_labor_only: this.is_labor_only,
      moulding_discount: this.moulding_discount,
      is_glass: this.is_glass,
      labor_price: this.labor_price,
      materials_price: this.materials_price,
      list_price: this.list_price,
      discount: this.discount,
      quantity: this.quantity,
      hours: this.hours // ,
      // _job: this._job
    }
  }

  constructor (job, _is_saved, initialObject = {}, _autoCompletePart = false, _is_loaded_job_part = false) {
    super()
    let self = this
    self._job = job
    self._is_saved = _is_saved
    self._is_loaded_job_part = _is_loaded_job_part
    self._autoCompletePart = _autoCompletePart

    // dynamically set some variables if pricelevel is included
    var actualListPrice = 0.0
    if (initialObject) {
      if ((initialObject.price_level_tier_vendor_price && Math.abs(parseFloat(initialObject.price_level_tier_vendor_price)) > 0.001) || initialObject._price_level_tier_is_flat_price || initialObject._price_level_tier_is_cost_plus_flat || initialObject._price_level_tier_is_cost_plus_percent) {
        if (initialObject.price_level_tier_tax_included && initialObject.price_level_tier_tax_included !== '0' && initialObject.price_level_tier_tax_included !== 0 && !job._jobCalculations.isTaxExempt) {
          var listPrice = parseFloat(initialObject.price_level_tier_vendor_price)
          // actualListPrice = job._jobCalculations.individualReverseCalculateMaterialCostBasedOnTargetMaterialPlusTaxCost(listPrice)
          // back tax out of the labor if possible
          // if (initialObject.price_level_tier_add_labor && parseFloat(initialObject.price_level_tier_add_labor) > 0) {
          //  actualListPrice = job._jobCalculations.individualReverseCalculateLaborCostBasedOnTargetLaborPlusTaxCost(initialObject.price_level_tier_add_labor)
          //   initialObject.price_level_tier_list_price_tax_backed_out = listPrice
          //   initialObject.price_level_tier_add_labor_tax_backed_out = actualListPrice
          // } else {
          actualListPrice = job._jobCalculations.individualReverseCalculateMaterialCostBasedOnTargetMaterialPlusTaxCost(listPrice)
          initialObject.price_level_tier_list_price_tax_backed_out = actualListPrice
          // }
          // if (typeof obj.listPrice == 'function') {
          //   // Must be an observable
          //   console.log('Unexpected bound part in part ctor.');
          // }
          // else {
          //   obj.priceLevelTier_listPriceTaxBackedOut = actualListPrice;
          // }
        }
        if (initialObject.price_level_tier_round_to_nearest9 && (initialObject.price_level_tier_round_to_nearest9 === '1' || initialObject.price_level_tier_round_to_nearest9 === 1)) {
          var currentQuoteTotal = parseFloat(initialObject.price_level_tier_vendor_price) + parseFloat(initialObject.price_level_tier_add_material) + parseFloat(initialObject.price_level_tier_add_labor)
          var remainderAbove10 = currentQuoteTotal % 10.0
          var target9 = 0.0
          if (remainderAbove10 > 9.000001) {
            target9 = (currentQuoteTotal - remainderAbove10 + 19.0)
          } else {
            target9 = (currentQuoteTotal - remainderAbove10 + 9.0)
          }
          var differenceToTarget = target9 - currentQuoteTotal

          initialObject.price_level_tier_additional_add_labor_round_to9 = differenceToTarget
          // tempAmount = (Math.ceil((parseInt(tempAmount) + 2) / 10) * 10) - 1
        }
      } else if (initialObject.price_level_tier_this_instance_is_auto_added_adhesive === 1) {
        if ((initialObject.price_level_tier_tax_included === 1 || initialObject.price_level_tier_tax_included === '1') &&
          parseFloat(initialObject.price_level_tier_auto_add_adhesive_at) > 0 && !job._jobCalculations.isTaxExempt) {
          actualListPrice = job._jobCalculations.individualReverseCalculateMaterialCostBasedOnTargetMaterialPlusTaxCost(parseFloat(initialObject.price_level_tier_auto_add_adhesive_at))
          // if (initialObject.price_level_tier_auto_add_adhesive_flat_or_each === 'each') actualListPrice *= initialObject.quantity
          initialObject.price_level_tier_list_price_tax_backed_out = actualListPrice
        }
      }
    }

    AddPropertyWithWatcher(self, 'is_custom_part', '0', function (val) {
      if (val !== '1') {
        self.is_chip = '0'
      }
    })

    AddPropertyWithWatcher(self, 'is_chip', '0', function (val) {
      if (val === '1') {
        self.moulding_discount = '0'
        self.is_adhesive_kit_part = '0'
        self.is_glass = '0'
      } else {
        self.is_addl_chip = '0'
      }
    })

    AddPropertyWithWatcher(self, 'is_recal', '0', function (val) {
      if (val === '1') {
        self.moulding_discount = '0'
        self.is_adhesive_kit_part = '0'
        self.is_glass = '0'
        self.is_addl_chip = '0'
        self.is_chip = '0'
      } else {
        // don't know abou this
        self.is_addl_chip = '0'
        self.is_chip = '0'
      }
    })

    AddPropertyWithWatcher(self, 'is_labor_only', '0', function (val) {
      if (val === '1') {
        self.moulding_discount = '0'
        self.is_adhesive_kit_part = '0'
        self.is_glass = '0'
        self.is_addl_chip = '0'
        self.is_chip = '0'
      } else {
        // don't know abou this
        self.is_addl_chip = '0'
        self.is_chip = '0'
      }
    })

    AddPropertyWithWatcher(self, 'moulding_discount', '0', function (val) {
      if (val === '1') {
        self.is_adhesive_kit_part = '0'
        self.is_chip = '0'
        self.is_glass = '0'
      }
    })

    AddPropertyWithWatcher(self, 'is_glass', '0', function (val) {
      if (val === '1') {
        self.moulding_discount = '0'
        self.is_adhesive_kit_part = '0'
        self.is_chip = '0'
      }
    })

    AddPropertyWithWatcher(self, 'labor_price', 0, function (val) {
      self.calculateTotalPrice()
    })

    AddPropertyWithWatcher(self, 'materials_price', 0, function (val) {
      self.calculateTotalPrice()
    })

    AddPropertyWithWatcher(self, 'list_price', 0, function (val) {
      self.calculateMaterialsPrice()
    })

    AddPropertyWithWatcher(self, 'discount', 0, function (val) {
      if (!self._skipMaterialsCalculation) {
        self.calculateMaterialsPrice()
      } else {
        self._skipMaterialsCalculation = false
      }
    })

    AddPropertyWithWatcher(self, 'quantity', 1, function (val) {
      self.calculateTotalPrice()
    })

    AddPropertyWithWatcher(self, 'hours', 0, function (val) {
      self.calculateLabor()
    })

    if (this._is_saved) {
      let self = this
      setTimeout(function () {
        self.objectTrackerInitial['is_custom_part'] = self.is_custom_part
        self.objectTrackerInitial['is_chip'] = self.is_chip
        self.objectTrackerInitial['is_recal'] = self.is_recal
        self.objectTrackerInitial['is_labor_only'] = self.is_labor_only
        self.objectTrackerInitial['moulding_discount'] = self.moulding_discount
        self.objectTrackerInitial['is_glass'] = self.is_glass
        self.objectTrackerInitial['labor_price'] = self.labor_price
        self.objectTrackerInitial['discount'] = self.discount
        self.objectTrackerInitial['quantity'] = self.quantity
        self.objectTrackerInitial['hours'] = self.hours
        self.objectTrackerInitial['total_price'] = self.total_price
        self.objectTrackerInitial['list_price'] = self.list_price
        self.objectTrackerInitial['materials_price'] = self.materials_price
      }, 100)
    }
    super.setInitial(initialObject)

    // retrigger if loaded in from saved job
    if (this._is_loaded_job_part) {
      let self = this
      setTimeout(function () {
        self.materials_price = initialObject.materials_price
        self.labor_price = initialObject.labor_price
        self.objectTrackerInitial['list_price'] = self.list_price
        self.objectTrackerInitial['materials_price'] = self.materials_price
        self.objectTrackerInitial['total_price'] = self.total_price
      }, 200)
    }
  }

  getPartTypeButtonText () {
    if (this.is_glass === '1') {
      return 'Glass'
    } else if (this.is_adhesive_kit_part === '1') {
      return 'Adh'
    } else if (this.moulding_discount === '1') {
      return 'Mld'
    } else if (this.is_addl_chip === '1') {
      return '+Chp'
    } else if (this.is_chip === '1') {
      return 'Chip'
    } else if (this.is_recal === '1') {
      return 'Recal'
    } else if (this.is_labor_only === '1') {
      return 'Labor Only'
    } else if (this.is_custom_part === '1') {
      return 'Cst'
    } else {
      return 'Misc'
    }
  }

  getPartCSSClass () {
    if (this.is_glass === '1') {
      return 'glassPart'
    } else if (this.is_adhesive_kit_part === '1') {
      return 'adhesivePart'
    } else if (this.moulding_discount === '1') {
      return 'mouldingPart'
    } else if (this.is_chip === '1') {
      return 'chipPart'
    } else if (this.is_addl_chip === '1') {
      return 'addlChipPart'
    } else if (this.is_recal === '1') {
      return 'recalPart'
    } else if (this.is_labor_only === '1') {
      return 'laborOnlyPart'
    } else if (this.is_custom_part === '1') {
      return 'customPart'
    } else {
      return ''
    }
  }

  // Should the "Chip Repair" checkbox be disabled?
  partTypeIsChipDisabled () {
    var disabled = this.is_custom_part === '0'
    if (this._job.edi_locked === 1 || this.is_adhesive_kit_part === '1') {
      // Always disabled in this scenario
      disabled = true
    }
    if (this._is_saved) {
      // 'saved parts' are never disabled
      disabled = false
    }
    return disabled
  }

  // Should the "Add'l Chip Repair" checkbox be disabled?
  partTypeIsAddlChipDisabled () {
    if (this.partTypeIsChipDisabled()) {
      return true
    }

    var disabled = false
    if (this.is_chip === '0') {
      disabled = true
    }
    if (this._job.edi_locked === 1 || this.is_adhesive_kit_part === '1') {
      // Always disabled in this scenario
      disabled = true
    }
    if (this._is_saved) {
      // 'saved parts' are never disabled
      disabled = false
    }
    return disabled
  }

  // Should the "Custom part" checkbox be disabled?
  partTypeIsCustomPartDisabled () {
    var disabled = false
    if (this._job.edi_locked === 1 || this.is_adhesive_kit_part === '1') {
      // Always disabled in this scenario
      disabled = true
    }
    if (this._is_saved) {
      // 'saved parts' are never disabled
      disabled = false
    }
    return disabled
  }

  // Should the "Moulding" checkbox be disabled?
  partTypeIsMouldingDisabled () {
    var disabled = false
    if (this._job.edi_locked === 1 || this.is_adhesive_kit_part === '1') {
      // Always disabled in this scenario
      disabled = true
    }
    if (this._is_saved) {
      // 'saved parts' are never disabled
      disabled = false
    }
    return disabled
  }

  // Should the "Glass" checkbox be disabled?
  partTypeIsGlassDisabled () {
    var disabled = false
    if (this._job.edi_locked === 1 || this.is_adhesive_kit_part === '1') {
      // Always disabled in this scenario
      disabled = true
    }
    if (this._is_saved) {
      // 'saved parts' are never disabled
      disabled = false
    }
    return disabled
  }

  isPriceLevelTierPart () {
    if (Math.abs(this.price_level_tier_vendor_price) < 0.001 && !this._price_level_tier_is_flat_price && !this._price_level_tier_is_cost_plus_percent && !this._price_level_tier_is_cost_plus_flat) {
      return false
    }
    return true
  }

  calculateMaterialsPrice (skipCheck = false) {
    var valueToBeDiscounted = 0.0
    var addedMaterial = 0.0
    var actualAddedMaterial = 0.0
    if (this.price_level_tier_this_instance_is_auto_added_adhesive === '1' || this.price_level_tier_this_instance_is_auto_added_adhesive === 1) {
      if ((this.price_level_tier_tax_included !== '1' && this.price_level_tier_tax_included !== 1) || (this._job._jobCalculations.isTaxExempt)) {
        valueToBeDiscounted = parseFloat(this.list_price)
      } else {
        var priceToUse = this.price_level_tier_auto_add_adhesive_at
        // if (this.price_level_tier_auto_add_adhesive_flat_or_each === 'each') {
        //   priceToUse = priceToUse * this.quantity
        // }
        addedMaterial = parseFloat(priceToUse) // self.priceLevelTier_addMaterial());
        actualAddedMaterial = this._job._jobCalculations.individualReverseCalculateMaterialCostBasedOnTargetMaterialPlusTaxCost(addedMaterial)

        this.price_level_tier_add_material_tax_backed_out = actualAddedMaterial

        // if (self.constructingNags) {
        valueToBeDiscounted = parseFloat(this.price_level_tier_list_price_tax_backed_out)
        // }
        // else {
        // valueToBeDiscounted = parseFloat(self.listPrice());
        // }''

        // valueToBeDiscounted

        // valueToBeDiscounted += actualAddedMaterial

        this.price_level_tier_total_material_tax_backed_out = valueToBeDiscounted
      }
    } else if (!this.isPriceLevelTierPart() || skipCheck) {
      if (this.is_adhesive_kit_part === '1') {
        var fixed_mat = 0
        if (this.part_number === 'HAH000448') {
          fixed_mat = Number(this._job.insurance_fleet_kit_fast)
        } else if ((this.part_number === 'HAH016000' || this.part_number === 'HAH024358' || this.part_number === 'HAH000456')) {
          fixed_mat = Number(this._job.insurance_fleet_kit_high)
        } else {
          fixed_mat = Number(this._job.insurance_fleet_kit_standard)
        }
        valueToBeDiscounted = parseFloat(fixed_mat)
      } else {
        valueToBeDiscounted = parseFloat(this.list_price)
      }
    } else {
      // TODO IMPLEMENT PRICELEVEL HERE
      // Discount is always 0 in this case because the discount for 'price level tier' parts
      // is not the insurance/fleet discount associated with the job, but rather
      // a discount pre-applied, if any, by the vendor.  (I.e., the Zoho discount table, possibly etc.)

      if ((this.price_level_tier_tax_included !== '1' && this.price_level_tier_tax_included !== 1) || this._job._jobCalculations.isTaxExempt) {
        // Simple case - tax is added on afterwards anyways by the 'job data calculated view model'

        // Constructing this part from a NAGS part

        // ****************************************************************************************************
        // CALLED TWICE during construction of NAGS part
        // (1) during 'assign', above, in which 'isPriceLevelTierPart' is not even set yet so this code will not even be reached and incorrect value will be set
        // (... and overwritten with 0 when materials price is loaded from default value set on initialization object which was not loaded from database)
        // (2) later, in the above post-'assign' call to 'initializeNewNagsPart',
        // the part is known as a 'price level tier' part because 'obj.priceLevelTier_vendorPrice' has been assigned
        // and so this code is reached, so the calculation is correct and writes last
        //
        // ... note that this is NOT called this second time when loaded from database because the above 'initializeNewNagsPart'
        // is not called in that case so the just-noted 'overwritten from database' step (see end of comment (1)) is the last step that occurs)
        // ****************************************************************************************************
        // if (self.constructingNags) {
        valueToBeDiscounted = parseFloat(this.price_level_tier_vendor_price) // priceLevelTier_vendorPrice());
        valueToBeDiscounted += parseFloat(this.price_level_tier_add_material) // priceLevelTier_addMaterial());
        // }
        // else {
        //   valueToBeDiscounted = parseFloat(self.listPrice());
        // }
      } else {
        // More challenging case - must 'back in' taxes

        addedMaterial = parseFloat(this.price_level_tier_add_material) // self.priceLevelTier_addMaterial());
        actualAddedMaterial = this._job._jobCalculations.individualReverseCalculateMaterialCostBasedOnTargetMaterialPlusTaxCost(addedMaterial)

        this.price_level_tier_add_material_tax_backed_out = actualAddedMaterial

        // if (self.constructingNags) {
        valueToBeDiscounted = parseFloat(this.price_level_tier_list_price_tax_backed_out)
        // }
        // else {
        // valueToBeDiscounted = parseFloat(self.listPrice());
        // }

        valueToBeDiscounted += actualAddedMaterial

        this.price_level_tier_total_material_tax_backed_out = valueToBeDiscounted
        // self.priceLevelTier_totalMaterialTaxBackedOut(valueToBeDiscounted);
      }
    }
    var val = Math.round(valueToBeDiscounted * (1.0 - 0.01 * parseFloat(this.discount)) * 100.0) / 100.0
    if (typeof val === 'string' && isNaN(parseFloat(val))) {
      val = 0.0
    }
    if (isNaN(val)) {
      val = 0.0
    }
    if (typeof val === 'string') {
      val = parseFloat(val)
    }
    this.materials_price = parseFloat((Math.round(val * 100.0) / 100.0).toFixed(2))
  }

  calculateLabor (skipCheck = false) {
    var val = 0.0
    if (!this.isPriceLevelTierPart() || skipCheck) {
      if (this.is_glass === '1') {
        var flat = 0.0
        var hourly = 0.0
        if (parseFloat(this._job.insurance_fleet_labor_flat) > 0.001) {
          flat = this._job.insurance_fleet_labor_flat
          if (typeof flat === 'string' && isNaN(parseFloat(flat))) {
            flat = 0.0
          }
          if (isNaN(flat)) {
            flat = 0.0
          }
          if (typeof flat === 'string') {
            flat = parseFloat(this._job.insurance_fleet_labor_flat)
          }
        }
        if (parseFloat(this._job.insurance_fleet_labor_hourly) > 0.001) {
          hourly = parseFloat(this.hours) * parseFloat(this._job.insurance_fleet_labor_hourly)
          if (typeof hourly === 'string' && isNaN(parseFloat(hourly))) {
            hourly = 0.0
          }
          if (isNaN(hourly)) {
            hourly = 0.0
          }
          if (typeof hourly === 'string') {
            hourly = parseFloat(hourly)
          }
        }

        val = flat + hourly
        this.labor_price = parseFloat((Math.round(val * 100.0) / 100.0).toFixed(2))
      } else {
        // Trigger calculation up the chain even if the labor itself did not change
        this.calculateTotalPrice()
      }
    } else {
      // TODO IMPLEMENT PRICELEVEL HERE
      // See comments for calculateMaterialsPrice.  Do not use insurance/fleet flat/hourly labor.
      // Just use the user's entry for labor in the price level tier.
      // if (!self.priceLevelTier_taxIncluded()) {
      var addForRoundTo9 = 0.0
      if (this.price_level_tier_tax_included === 0 || this.price_level_tier_tax_included === '0' || this._job._jobCalculations.isTaxExempt) {
        //   // Simple case - tax is added on afterwards anyways by the 'job data calculated view model'
        addForRoundTo9 = 0.0
        //   var addForRoundTo9 = 0.0;
        addForRoundTo9 += parseFloat(this.price_level_tier_additional_add_labor_round_to9)
        //   if (self.constructingNags) {
        //     addForRoundTo9 += parseFloat(self.priceLevelTier_additionalAddLaborRoundTo9());
        //   }
        val = parseFloat(this.price_level_tier_add_labor) + addForRoundTo9
        //   val = parseFloat(self.priceLevelTier_addLabor()) + addForRoundTo9;
        this.labor_price = (Math.round(val * 100.0) / 100.0).toFixed(2)
        //   self.laborPrice((Math.round(val * 100.0) / 100.0).toFixed(2));
        // }
      } else {
        // else {
        //   // More challenging case - must 'back in' taxes
        addForRoundTo9 = 0.0
        //   var addForRoundTo9 = 0.0;
        //   if (self.constructingNags) {
        addForRoundTo9 += parseFloat(this.price_level_tier_additional_add_labor_round_to9)
        //     addForRoundTo9 += parseFloat(self.priceLevelTier_additionalAddLaborRoundTo9());
        //   }
        var addedLabor = parseFloat(this.price_level_tier_add_labor) + addForRoundTo9
        //   var addedLabor = parseFloat(self.priceLevelTier_addLabor()) + addForRoundTo9;
        var actualAddedLabor = this._job._jobCalculations.individualReverseCalculateLaborCostBasedOnTargetLaborPlusTaxCost(addedLabor)
        //   var actualAddedLabor = self.jobData.calculations.individualReverseCalculateLaborCostBasedOnTargetLaborPlusTaxCost(self, addedLabor);
        this.price_level_tier_add_labor_tax_backed_out = actualAddedLabor
        //   self.priceLevelTier_addLaborTaxBackedOut(actualAddedLabor);
        this.labor_price = (Math.round(actualAddedLabor * 100.0) / 100.0).toFixed(2)
        //   self.laborPrice((Math.round(actualAddedLabor * 100.0) / 100.0).toFixed(2));
        // }
      }
    }
    // update recal parts
    if (this.description === 'Recalibration Service') {
      if (this.part_number === 'RECAL DYNAMIC') {
        this.labor_price = this._job.insurance_fleet_recal_safelite_dynamic
      } else if (this.part_number === 'RECAL STATIC') {
        this.labor_price = this._job.insurance_fleet_recal_safelite_static
      } else if (this.part_number === 'RECAL DUALMETHOD') {
        this.labor_price = this._job.insurance_fleet_recal_safelite_dual
      } else if (this.part_number === 'RECAL-RTL-DYNAMIC') {
        this.labor_price = this._job.insurance_fleet_recal_lynx_rtl_dynamic
      } else if (this.part_number === 'RECAL-RTL-STATIC') {
        this.labor_price = this._job.insurance_fleet_recal_lynx_rtl_static
      } else if (this.part_number === 'RECAL-RTL-BOTH') {
        this.labor_price = this._job.insurance_fleet_recal_lynx_rtl_both
      } else if (this.part_number === 'RECAL-DLR-DYNAMIC') {
        this.labor_price = this._job.insurance_fleet_recal_lynx_dlr_dynamic
      } else if (this.part_number === 'RECAL-DLR-STATIC') {
        this.labor_price = this._job.insurance_fleet_recal_lynx_dlr_static
      } else if (this.part_number === 'RECAL-DLR-BOTH') {
        this.labor_price = this._job.insurance_fleet_recal_lynx_dlr_both
      }
    }
  }

  calculateDiscount (skipCheck = false) {
    var val = 0.0
    if (!this.isPriceLevelTierPart() || skipCheck) {
      // Do not add discount for a 'price level tier' part!
      if (this.is_glass === '1' && this.moulding_discount !== '1') {
        if (this.is_oem === '1') {
          val = this._job.insurance_fleet_oem_discount
        } else {
          val = this._job.insurance_fleet_discount
        }
      } else if (this.moulding_discount === '1') {
        val = this._job.insurance_fleet_moulding_discount
      } else {
        val = 0.0
      }
    }
    if (typeof val === 'string' && isNaN(parseFloat(val))) {
      val = 0.0
    }
    if (isNaN(val)) {
      val = 0.0
    }
    if (typeof val === 'string') {
      val = parseFloat(val)
    }
    this.discount = parseFloat((Math.round(val * 100.0) / 100.0).toFixed(2))
  };

  calculateTotalPrice () {
    var val = 0.0

    var actualMaterials = this.materials_price
    var actualLabor = this.labor_price

    if (this.price_level_tier_this_instance_is_auto_added_adhesive === '1' || this.price_level_tier_this_instance_is_auto_added_adhesive === 1) {
      if (this.price_level_tier_auto_add_adhesive_flat_or_each === 'each') {
        // each
        val = (parseFloat(actualMaterials /* this.list_price */)) * parseFloat(this.quantity)
      } else {
        // flat
        val = parseFloat(actualMaterials)
      }
    } else if (this.is_adhesive_kit_part === '1') {
      if (this.part_number === 'HAH000448') {
        if (this._job.insurance_fleet_kit_charge_type_fast === 'each') {
          // each
          val = (parseFloat(actualMaterials) + parseFloat(actualLabor)) * parseFloat(this.quantity)
        } else {
          // flat
          val = parseFloat(actualMaterials)
        }
      } else if ((this.part_number === 'HAH016000' || this.part_number === 'HAH024358' || this.part_number === 'HAH000456')) {
        if (this._job.insurance_fleet_kit_charge_type_high === 'each') {
          // each
          val = (parseFloat(actualMaterials) + parseFloat(actualLabor)) * parseFloat(this.quantity)
        } else {
          // flat
          val = parseFloat(actualMaterials)
        }
      } else {
        if (this._job.insurance_fleet_kit_charge_type_standard === 'each') {
          // each
          val = (parseFloat(actualMaterials) + parseFloat(actualLabor)) * parseFloat(this.quantity)
        } else {
          // flat
          val = parseFloat(actualMaterials)
        }
      }
    } else {
      val = (parseFloat(actualMaterials) + parseFloat(actualLabor)) * parseFloat(this.quantity)
    }
    if (typeof val === 'string' && isNaN(parseFloat(val))) {
      val = 0.0
    }
    if (isNaN(val)) {
      val = 0.0
    }
    if (typeof val === 'string') {
      val = parseFloat(val)
    }

    this.total_price = parseFloat((Math.round(val * 100.0) / 100.0).toFixed(2))
  }

  static getJobParts (job, callbackFunction) {
    Api({ url: `/api/shop/${job.shop_id}/job/${job.id}/part` }).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        var partObject = new Part(job, false, {...val, _item: val.item, _purchase_order_part: val.purchase_order_part}, false, true)
        partObject['vendor_color'] = val['vendor_color']
        objects.push(partObject)
      })
      callbackFunction(objects)
    })
  }

  // #Auto Add Saved Part
  static getShopPart (shopId, partId, callbackFunction) {
    Api({ url: `/api/shop/${shopId}/part/${partId}` }).then(res => {
      callbackFunction(res)
    })
  }

  static getShopParts (shopId, job, callbackFunction) {
    Api({ url: `/api/shop/${shopId}/part` }).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        var shopPart = new Part(job, true, val)
        shopPart.calculateDiscount()
        shopPart.calculateLabor()
        shopPart.calculateMaterialsPrice()
        objects.push(shopPart)
      })
      callbackFunction(objects)
    })
  }

  createUpdateShopPart (shopId, callbackFunction, errorCallbackFunction = null) {
    // This is for shop/saved parts only, job parts do not have a shop_id
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    // # Created Saved Solution
    return Api.post(`/api/shop/${this.shop_id}/part/${this.id}`, this.strippedObject).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static createPartFromNagsPart (job, isSavedPart, nagsPart, glassGraphicsData, is_inventory_part = false) {

    var newPart = new Part(job, isSavedPart, {
      description: nagsPart.dsc,
      nagsid: nagsPart.nagsid,
      nags_part: '1',
      part_number: nagsPart.partno,
      list_price: nagsPart.partprice,
      is_adhesive_kit_part: nagsPart.isAdhesiveKitPart ? '1' : '0',
      is_oem: nagsPart.isOem ? '1' : '0',
      is_premium: nagsPart.isPremium ? '1' : '0',
      is_glass: nagsPart.isGlass ? '1' : '0',
      is_chip: nagsPart.isChip ? '1' : '0',
      color: nagsPart.color,
      mf_code: nagsPart.mfCode,
      attachment_flag: nagsPart.attachmentFlag,
      moulding_discount: nagsPart.mouldingDiscount ? '1' : '0',
      is_custom_part: '0',
      hours: nagsPart.labor,
      quantity: nagsPart.qty,
      cost: nagsPart.inventory_cost ? nagsPart.inventory_cost : 0,
      glass_graphic_data: glassGraphicsData,
      glass_graphic_id: nagsPart.glassGraphicId,
      glass_callout: nagsPart.glassCallout,
      atchmnt_flag: nagsPart.ATCHMNT_FLAG,
      clips_flag: nagsPart.CLIPS_FLAG,
      mlding_flag: nagsPart.MLDING_FLAG,
      atchmnt_is_clip: nagsPart.ATCHMNT_IS_CLIP,
      atchmnt_is_mlding: nagsPart.ATCHMNT_IS_MLDING,
      atchmnt_dsc: nagsPart.ATCHMNT_DSC,
      prefix: nagsPart.PREFIX,
      part_num: nagsPart.PART_NUM,
      ant_flag: nagsPart.ANT_FLAG,
      blk_size1: nagsPart.BLK_SIZE1,
      blk_size2: nagsPart.BLK_SIZE2,
      encap_flag: nagsPart.ENCAP_FLAG,
      hds_up_disp_flag: nagsPart.HDS_UP_DISP_FLAG,
      heated_flag: nagsPart.HEATED_FLAG,
      num_holes: nagsPart.NUM_HOLES,
      slider_flag: nagsPart.SLIDER_FLAG,
      solar_flag: nagsPart.SOLAR_FLAG,
      thickness: nagsPart.THICKNESS,
      wt: nagsPart.WT,
      tube_qty: nagsPart.TUBE_QTY,
      inventory_part: (is_inventory_part ? 1 : 0),
      price_level_tier_vendor_price: nagsPart.price_level_tier_vendor_price,
      price_level_tier_add_material: nagsPart.price_level_tier_add_material,
      price_level_tier_add_labor: nagsPart.price_level_tier_add_labor,
      price_level_tier_auto_add_adhesive: nagsPart.price_level_tier_auto_add_adhesive,
      price_level_tier_auto_add_adhesive_at: nagsPart.price_level_tier_auto_add_adhesive_at,
      price_level_tier_auto_add_adhesive_flat_or_each: nagsPart.price_level_tier_auto_add_adhesive_flat_or_each,
      price_level_tier_tax_included: nagsPart.price_level_tier_tax_included,
      price_level_tier_round_to_nearest9: nagsPart.price_level_tier_round_to_nearest9,
      price_level_tier_this_instance_is_auto_added_adhesive: nagsPart.price_level_tier_this_instance_is_auto_added_adhesive,
      price_level_tier_additional_add_labor_round_to9: nagsPart.price_level_tier_additional_add_labor_round_to9,
      price_level_tier_list_price_tax_backed_out: nagsPart.price_level_tier_list_price_tax_backed_out,
      price_level_tier_add_material_tax_backed_out: nagsPart.price_level_tier_add_material_tax_backed_out,
      price_level_tier_total_material_tax_backed_out: nagsPart.price_level_tier_total_material_tax_backed_out,
      price_level_tier_add_labor_tax_backed_out: nagsPart.price_level_tier_add_labor_tax_backed_out,
      _price_level_tier_is_flat_price: nagsPart._price_level_tier_is_flat_price,
      _inventory_timestamp: nagsPart.inventory_cost ? Math.round(new Date().getTime() / 1000) : 0,
      pilkington_order_item_id: nagsPart.pilkington_order_item_id,
      american_order_item_id: nagsPart.american_order_item_id,
      glaxis_order_item_id: nagsPart.glaxis_order_item_id,
      nielsen_moller_order_item_id: nagsPart.nielsen_moller_order_item_id,
      take_from_inventory: nagsPart.take_from_inventory && nagsPart.take_from_inventory == 1 ? 1 : 0,
      inventory_type_to_take: nagsPart.inventory_type_to_take || 'available'
      /*
      Check if any of the below are '0' and '1' instead of true false
      priceLevelTier_vendorPrice: priceLevelTier_vendorPrice,
      priceLevelTier_addMaterial: priceLevelTier_addMaterial,
      priceLevelTier_addLabor: priceLevelTier_addLabor,
      priceLevelTier_autoAddAdhesive: priceLevelTier_autoAddAdhesive,
      priceLevelTier_autoAddAdhesiveAt: priceLevelTier_autoAddAdhesiveAt,
      priceLevelTier_taxIncluded: priceLevelTier_taxIncluded,
      priceLevelTier_roundToNearest9: priceLevelTier_roundToNearest9,
      priceLevelTier_thisInstanceIsAutoAddedAdhesive: false, // dynamically set later
      priceLevelTier_additionalAddLaborRoundTo9: 0.0, // dynamically set later
      priceLevelTier_listPriceTaxBackedOut: 0.0, // dynamically set later
      priceLevelTier_addMaterialTaxBackedOut: 0.0, // dynamically set later
      priceLevelTier_totalMaterialTaxBackedOut: 0.0, // dynamically set later
      priceLevelTier_addLaborTaxBackedOut: 0.0, // dynamically set later
      order: self.sortedParts().length > 0 ? self.sortedParts()[self.sortedParts().length - 1].order() + 1 : 0 */
      // , discount, laborPrice, materialsPrice - for Nags parts, these are calculated when the part is added (see part.js)
    })
    newPart.calculateDiscount()
    newPart.calculateLabor()
    newPart.calculateMaterialsPrice()
    return newPart
  }

  deleteShopPart (successCallbackFunction, errorCallbackFunction = null) {
    if (this._is_saved) {
      if (!this.id || this.id <= 0) {
        successCallbackFunction()
      }
      return Api.delete(`/api/shop/${this.shop_id}/part/${this.id}`, this).then(res => {
        successCallbackFunction()
      }, function (error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      })
    }
  }

  static switchPartOrders (shopId, partId1, order1, partId2, order2, callbackFunction) {
    var changes = {
      'partId1': partId1,
      'order1': order1,
      'partId2': partId2,
      'order2': order2
    }
    return Api.post(`/api/shop/${shopId}/partReorder`, changes).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      console.log('had error: ', error)
    })
  }

  static markPartNoBuy (shopId, jobId, partId, callbackFunction) {
    return Api.post(`/api/shop/${shopId}/job/${jobId}/part/${partId}/markPartNoBuy`).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      console.log('had error: ', error)
    })
  }

  static markPartNoBuyMultiple (shopId, jobId, partIds, callbackFunction, errorFunction) {
    return Api.post(`/api/shop/${shopId}/job/${jobId}/part/markPartNoBuy`, partIds).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      console.log('had error: ', error)
      errorFunction(error)
    })
  }

  static removePartNoBuy (shopId, jobId, partId, callbackFunction) {
    return Api.post(`/api/shop/${shopId}/job/${jobId}/part/${partId}/removePartNoBuy`).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      console.log('had error: ', error)
    })
  }

  static markPartNoTechPickup (shopId, jobId, partId, callbackFunction) {
    return Api.post(`/api/shop/${shopId}/job/${jobId}/part/${partId}/markPartNoTechPickup`).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      console.log('error marking no tech pickup: ', error)
    })
  }

  static removePartNoTechPickup (shopId, jobId, partId, callbackFunction) {
    return Api.post(`/api/shop/${shopId}/job/${jobId}/part/${partId}/removePartNoTechPickup`).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      console.log('error removing no tech pickup:', error)
    })
  }

  static async attachVendorItem (vendor, partId, vendorItemId) {
    await Api.post(`/api/parts/${partId}/attachVendorItem`, {
      vendor,
      vendorItemId
    })
  }
}
