import Api from '@/services/Api'
import axios from 'axios'
import Part from '@/scripts/objects/part'
import store from '@/store/store'
export default class Nags {
  static getYears(callbackFunction, errorCallbackFunction = null) {
    Api({ url: '/api/nags/years' }).then(
      ({ data }) => {
        const years = data.map(({ year }) => ({ name: year, id: year }))
        callbackFunction(years)
      },
      function(error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      }
    )
  }

  static getMakes(year, callbackFunction, errorCallbackFunction = null) {
    Api({ url: '/api/nags/years/' + year + '/makes' }).then(
      res => {
        callbackFunction(res.data)
      },
      function(error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      }
    )
  }

  static getModels(year, make, callbackFunction, errorCallbackFunction = null) {
    Api({ url: '/api/nags/years/' + year + '/makes/' + make + '/models' }).then(
      res => {
        callbackFunction(res.data)
      },
      function(error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      }
    )
  }

  static getSubModels(
    year,
    make,
    model,
    callbackFunction,
    errorCallbackFunction = null
  ) {
    Api({
      url:
        '/api/nags/years/' +
        year +
        '/makes/' +
        make +
        '/models/' +
        model +
        '/subModels'
    }).then(
      res => {
        callbackFunction(res.data)
      },
      function(error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      }
    )
  }

  static getStyles(
    year,
    make,
    model,
    subModel,
    callbackFunction,
    errorCallbackFunction = null
  ) {
    Api({
      url:
        '/api/nags/years/' +
        year +
        '/makes/' +
        make +
        '/models/' +
        model +
        '/subModels/' +
        subModel +
        '/styles'
    }).then(
      res => {
        callbackFunction(res.data)
      },
      function(error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      }
    )
  }

  static getVehicle(
    year,
    make,
    model,
    subModel,
    style,
    shopId,
    callbackFunction
  ) {
    store.commit('toggleLoadingJobVehicleData')

    Api({
      url:
        '/api/nags/years/' +
        year +
        '/makes/' +
        make +
        '/models/' +
        model +
        '/subModels/' +
        subModel +
        '/styles/' +
        style +
        '/' +
        shopId
    }).then(res => {
      store.commit('toggleLoadingJobVehicleData')
      callbackFunction(res.data)
    })
  }

  static getVehicleFromVin(
    vin,
    callbackFunction,
    errorCallbackFunction = null
  ) {
    Api({ url: '/api/nags/vehicle/vin/' + encodeURIComponent(vin) }).then(
      res => {
        callbackFunction(res.data)
      },
      function(error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      }
    )
  }

  static async getNGTSADataFromVin(vin) {
    const { data } = await axios.get(
      `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinExtended/${vin}?format=json`
    )
    if (data.Results && data.Results.length) {
      const serializedData = []
      const emptyData = []
      const errorObj = {}
      let foundError = false
      for (const obj of data.Results) {
        if (
          ![
            'Suggested VIN',
            'Error Code',
            'Possible Values',
            'Additional Error Text',
            'Error Text'
          ].includes(obj.Variable)
        ) {
          if (obj.Value && obj.Value !== 'Not Applicable') {
            serializedData.push(obj)
          } else {
            emptyData.push(obj)
          }
        } else {
          if (obj.Variable === 'Error Code' && obj.Value !== '0') {
            foundError = true
          }
          errorObj[obj.Variable] = obj
        }
      }
      const sortFunc = (a, b) => {
        const vA = a.Variable.toUpperCase()
        const vB = b.Variable.toUpperCase()
        if (vA < vB) return -1
        else if (vA > vB) return 1
        else return 0
      }

      serializedData.sort(sortFunc)
      emptyData.sort(sortFunc)

      var errorData = null
      if (foundError) {
        errorData = [
          errorObj['Suggested VIN'],
          errorObj['Possible Values'],
          errorObj['Error Code'],
          errorObj['Error Text'],
          errorObj['Additional Error Text']
        ]
      }

      return {
        data: serializedData,
        emptyData,
        errorData: errorData
      }
    }
    return {
      data: [{ Variable: 'Message', Value: data.Message }],
      errorData: null
    }
  }

  static async getVinFromPlate(plate, state) {
    try {
      return await Api.post('/api/nags/getVinFromPlate', { state, plate })
    } catch (error) {
      return {'data': {'message': 'Unable to get Vin from Plate.', 'error': error}}
    }
  }

  static async getPlateFromVin(vin) {
    try {
      return await Api.post('/api/nags/getPlateFromVin', { vin })
    } catch (error) {
      return {'data': {'message': 'Unable to get Plate from Vin.', 'error': error}}
    }
  }

  static nagsSearch(
    searchVal,
    phase,
    year,
    make,
    model,
    submodel,
    style,
    hwCfgIds,
    shopId,
    callbackFunction
  ) {
    Api.post(
      '/api/nags/search/' +
        encodeURIComponent(searchVal) +
        '/phase/' +
        phase +
        '/years/' +
        year +
        '/makes/' +
        make +
        '/models/' +
        model +
        '/subModels/' +
        submodel +
        '/styles/' +
        style,
      { hwCfgIds: hwCfgIds, shopId: shopId }
    ).then(res => {
      callbackFunction(res.data)
    })
  }

  static nagsSearchHumanReadable(
    year,
    make,
    model,
    submodel,
    style,
    callbackFunction
  ) {
    Api.get(
      '/api/nags/hr/year/' +
        year +
        '/make/' +
        make +
        '/model/' +
        model +
        '/submodel/' +
        submodel +
        '/style/' +
        style
    ).then(res => {
      if (Object.keys(res.data).length === 0) {
        // this will an issue getting this vehicle information
        callbackFunction(null)
      } else {
        callbackFunction(res.data)
      }
    })
  }

  static getVehiclesByNagsGlassId(nagsGlassId, callbackFunction) {
    Api.get('/api/nags/vehiclesbynagsglassid/' + nagsGlassId).then(res => {
      callbackFunction(res.data)
    })
  }

  static generateNagPart(job, nagsPart, mode, priceLevelData) {
    if (typeof priceLevelData === 'undefined') {
      priceLevelData = null
    }

    var price_level_tier_vendor_price = 0
    var price_level_tier_add_material = 0
    var price_level_tier_add_labor = 0
    var price_level_tier_auto_add_adhesive = '0'
    var price_level_tier_auto_add_adhesive_at = 0
    var price_level_tier_tax_included = '0'
    var price_level_tier_round_to_nearest9 = '0'
    var price_level_tier_auto_add_adhesive_flat_or_each = 'each'
    var listPrice = nagsPart.partprice
    var price_level_tier_is_flat_price = false
    var price_level_tier_is_cost_plus_flat = false
    var price_level_tier_is_cost_plus_percent = false

    if (priceLevelData) {
      price_level_tier_vendor_price = priceLevelData._vendor_price // 0 // ?????????
      price_level_tier_add_material = priceLevelData.add_material
      price_level_tier_add_labor = priceLevelData.add_labor
      price_level_tier_auto_add_adhesive = priceLevelData.auto_add_adhesive
      price_level_tier_auto_add_adhesive_at =
        priceLevelData.auto_add_adhesive_amount
      price_level_tier_tax_included = priceLevelData.tax_included
      price_level_tier_round_to_nearest9 = priceLevelData.round_to9
      price_level_tier_auto_add_adhesive_flat_or_each =
        priceLevelData.auto_add_adhesive_flat_or_each
      if (
        priceLevelData.is_flat_price === 1 ||
        priceLevelData.is_flat_price === '1'
      ) {
        // listPrice = 0
        price_level_tier_vendor_price = 0
        price_level_tier_is_flat_price = true
      }

      if (
        priceLevelData.is_cost_plus_flat === 1 ||
        priceLevelData.is_cost_plus_flat === '1'
      ) {
        // listPrice = 0
        price_level_tier_is_cost_plus_flat = true
      }

      if (
        priceLevelData.is_cost_plus_percent === 1 ||
        priceLevelData.is_cost_plus_percent === '1'
      ) {
        // listPrice = 0
        price_level_tier_vendor_price =
          price_level_tier_vendor_price *
            (price_level_tier_add_material / 100 + 1) -
          price_level_tier_add_material
        price_level_tier_is_cost_plus_percent = true
      }
    }

    var newPart = new Part(job, false, {
      description: nagsPart.dsc,
      nagsid: nagsPart.nagsid,
      part_number: nagsPart.partno,
      list_price: listPrice,
      is_adhesive_kit_part: nagsPart.isAdhesiveKitPart,
      is_oem: nagsPart.isOem ? '1' : '0',
      is_premium: nagsPart.isPremium ? '1' : '0',
      is_glass: nagsPart.isGlass ? '1' : '0',
      is_chip: nagsPart.isChip ? '1' : '0',
      color: nagsPart.color,
      mf_code: nagsPart.mfCode,
      attachment_flag: nagsPart.attachmentFlag,
      moulding_discount: nagsPart.mouldingDiscount,
      is_custom_part: false,
      hours: nagsPart.labor,
      quantity: nagsPart.qty,
      cost: 0,
      glass_graphic_data: null, // glassGraphicData ** no more!
      glass_graphic_id: -1, // nagsPart.glassGraphicId ** no more!
      glass_callout: nagsPart.glassCallout,
      atchmnt_flag: nagsPart.ATCHMNT_FLAG,
      clips_flag: nagsPart.CLIPS_FLAG,
      mlding_flag: nagsPart.MLDING_FLAG,
      atchmnt_is_clip: nagsPart.ATCHMNT_IS_CLIP,
      atchmnt_is_mlding: nagsPart.ATCHMNT_IS_MLDING,
      atchmnt_dsc: nagsPart.ATCHMNT_DSC,
      prefix: nagsPart.PREFIX,
      part_num: nagsPart.PART_NUM,
      ant_flag: nagsPart.ANT_FLAG,
      blk_size1: nagsPart.BLK_SIZE1,
      blk_size2: nagsPart.BLK_SIZE2,
      encap_flag: nagsPart.ENCAP_FLAG,
      hds_up_disp_flag: nagsPart.HDS_UP_DISP_FLAG,
      heated_flag: nagsPart.HEATED_FLAG,
      num_holes: nagsPart.NUM_HOLES,
      slider_flag: nagsPart.SLIDER_FLAG,
      solar_flag: nagsPart.SOLAR_FLAG,
      thickness: nagsPart.THICKNESS,
      wt: nagsPart.WT,
      tube_qty: nagsPart.TUBE_QTY,
      price_level_tier_vendor_price: price_level_tier_vendor_price,
      price_level_tier_add_material: price_level_tier_add_material,
      price_level_tier_add_labor: price_level_tier_add_labor,
      price_level_tier_auto_add_adhesive: price_level_tier_auto_add_adhesive,
      price_level_tier_auto_add_adhesive_at: price_level_tier_auto_add_adhesive_at,
      price_level_tier_auto_add_adhesive_flat_or_each: price_level_tier_auto_add_adhesive_flat_or_each,
      price_level_tier_tax_included: price_level_tier_tax_included,
      price_level_tier_round_to_nearest9: price_level_tier_round_to_nearest9,
      price_level_tier_this_instance_is_auto_added_adhesive: '0',
      price_level_tier_additional_add_labor_round_to9: 0,
      price_level_tier_list_price_tax_backed_out: 0,
      price_level_tier_add_material_tax_backed_out: 0,
      price_level_tier_total_material_tax_backed_out: 0,
      price_level_tier_add_labor_tax_backed_out: 0,
      _price_level_tier_is_flat_price: price_level_tier_is_flat_price,
      _price_level_tier_is_cost_plus_flat: price_level_tier_is_cost_plus_flat,
      _price_level_tier_is_cost_plus_percent: price_level_tier_is_cost_plus_percent
      // order needs to be sorted
    })
    newPart.calculateDiscount()
    newPart.calculateLabor()
    newPart.calculateMaterialsPrice()
    return newPart
  }

  static async searchVehicles(q) {
    const { data } = await Api.get('/api/nags/autocomplete', { params: { q } })
    return data
  }

  static async searchVehicleVin(q, check = null) {
    const { data } = await Api.get('/api/nags/vin/' + q + (check?'?check=true':''))
    return data
  }

  static async searchVehiclesId(params) {
    const { data } = await Api.get('/api/nags/autocomplete/ids', { params })
    return data
  }

  static async getDropdownData(params) {
    const { data } = await Api.get('/api/nags/dropdown', { params })
    return data
  }

  static async getNagsData({
    vehicle_year,
    vehicle_make,
    vehicle_model,
    vehicle_sub_model,
    vehicle_style,
    shop_id
  }) {
    const { data } = await Api({
      url:
        '/api/nags/years/' +
        vehicle_year +
        '/makes/' +
        vehicle_make +
        '/models/' +
        vehicle_model +
        '/subModels/' +
        vehicle_sub_model +
        '/styles/' +
        vehicle_style +
        '/' +
        shop_id
    })
    return data
  }

  static async searchPart(query, source) {
    try {
      const { data } = await Api.get(
        `/api/nags/search/part/${query}`,
        source ? { cancelToken: source.token } : {}
      )
      return data
    } catch (error) {
      return []
    }
  }

  static async searchPartDetails(nagsIds) {
    const { data } = await Api.get(`/api/nags/search/part/details/${nagsIds}`)
    return data
  }
}
