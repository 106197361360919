import Api from '@/services/Api'

export var NotificationHandler = {
  methods: {
    intializeEventListeners (component) {
      var self = component
      component.$root.$on('alertEvent', (param) => self.getEvent(param, self))
    },
    destroyEventListeners (component) {
      var self = component
      component.$root.$on('alertEvent', (param) => self.getEvent(param, self))
    },
    getEvent (eventName, component) {
      var self = component
      Api({ url: `/api/alertcheck/${eventName}` }).then(res => {
        self.$root.$emit('glassbillerNotification', res.data)
      })
    }
  }
}
