var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "start-chat-wrapper" } }, [
    _c(
      "div",
      { staticClass: "pr-2" },
      [
        _c(
          "el-select",
          {
            staticStyle: { width: "100%" },
            attrs: { clearable: "", size: "small", placeholder: "Shop number" },
            model: {
              value: _vm.shop_phone_number,
              callback: function($$v) {
                _vm.shop_phone_number = $$v
              },
              expression: "shop_phone_number"
            }
          },
          _vm._l(_vm.phoneNumbers, function(item) {
            return _c(
              "el-option",
              {
                key: item.id,
                attrs: { label: item.name, value: item.phone_number }
              },
              [
                _c("span", { staticClass: "select-shop-option-block" }, [
                  _c("i", { staticClass: "el-icon-mobile-phone" }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.formatPhoneToReadable(item.phone_number)))
                  ]),
                  _c("i", { staticClass: "el-icon-office-building ml-3" }),
                  _c("span", [_vm._v(_vm._s(item.name))])
                ])
              ]
            )
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pr-2" },
      [
        _c("el-autocomplete", {
          staticStyle: { width: "100%" },
          attrs: {
            clearable: "",
            size: "small",
            "popper-class": "recipient-search-input",
            "fetch-suggestions": _vm.recipientSearch,
            debounce: 1000,
            "trigger-on-focus": false,
            placeholder: "Name or number"
          },
          on: { select: _vm.handleSelect },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("div", { staticClass: "recipient-block" }, [
                    _c("div", { staticClass: "recipient-block-left" }, [
                      _c("div", { staticClass: "recipient-name" }, [
                        _c("i", { staticClass: "el-icon-user" }),
                        _c("span", [_vm._v(_vm._s(item.name))])
                      ]),
                      _c("div", { staticClass: "recipient-phone" }, [
                        _c("i", { staticClass: "el-icon-mobile-phone" }),
                        _c("span", [_vm._v(_vm._s(item.phone))])
                      ])
                    ]),
                    _c("div", { staticClass: "recipient-block-right" }, [
                      item.type === "customer"
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "recipient-type-tag recipient-customer"
                            },
                            [_vm._v("Customer")]
                          )
                        : _vm._e(),
                      item.type === "tech"
                        ? _c(
                            "span",
                            {
                              staticClass: "recipient-type-tag recipient-tech"
                            },
                            [_vm._v("Tech")]
                          )
                        : _vm._e(),
                      item.type === "salesrep"
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "recipient-type-tag recipient-salesrep"
                            },
                            [_vm._v("Sales rep")]
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              }
            }
          ]),
          model: {
            value: _vm.recipient_phone_faker,
            callback: function($$v) {
              _vm.recipient_phone_faker = $$v
            },
            expression: "recipient_phone_faker"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("el-button", {
          attrs: {
            plain: "",
            circle: "",
            size: "small",
            type: "primary",
            icon: "el-icon-plus",
            loading: _vm.submitting
          },
          on: { click: _vm.handleSubmit }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }