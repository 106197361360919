const state = {
  shopId: null,
  shopData: null,
  techs: {},
  shop: null,
  loading: false,
  orgId: null,
  orgData: null
}

export default state
