import axios from 'axios'
import Api from '@/services/Api'
import $ from 'jquery'
import tinycolor from 'tinycolor2'
import LegacyApi from '@/services/LegacyApi'
import pdf from '@/scripts/objects/pdf'
import { permissions, permissionCheck } from '@/scripts/helpers/permissions.helpers'
import * as R from 'ramda'

export var UtilityMixin = {
  methods: {
    formatCurrency (data, blankUndefined = false, includeCommas = false, includeDollarSymbol = true) {
      var float2Decimals = this.formatFloatDecimals(data, 2, 2, blankUndefined)
      if (blankUndefined && float2Decimals === '') {
        return ''
      } else {
        if (includeCommas) {
          float2Decimals = this.numberWithCommas(float2Decimals)
        }
        return (includeDollarSymbol ? '$' : '') + float2Decimals
      }
    },
    numberWithCommas(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    numberOfDecimalPlaces (value) {
      if (Math.floor(value) === value) {
        return 0
      }
      return value.toString().split('.')[1].length || 0
    },
    formatFloatDecimals (data, minimumDecimalPlaces, maximumDecimalPlaces = -1, blankUndefined = false) {
      var val = parseFloat(data)
      if (isNaN(val)) {
        if (blankUndefined) {
          return ''
        } else {
          val = 0.0
        }
      }
      if (this.numberOfDecimalPlaces(val) < minimumDecimalPlaces) {
        val = (Math.round(val * 100.0) / 100.0).toFixed(minimumDecimalPlaces)
      } else if (maximumDecimalPlaces > -1 && this.numberOfDecimalPlaces(val) > maximumDecimalPlaces) {
        val = (Math.round(val * 100.0) / 100.0).toFixed(maximumDecimalPlaces)
      }
      return '' + val
    },
    zipLookup: function (zip, callback) {
      var zipValid = {
        us: /[0-9]{5}(-[0-9]{4})?/
      }

      if (zipValid.us.test(zip)) {
        var country = 'US'
        axios({
          url: 'https://zip.getziptastic.com/v2/' + country + '/' + zip, 
          responseType: 'json',
          crossdomain: true,
          transformRequest: [ function (data, headers) {
            delete headers.common['Auth']; return data;}]
        }).then(function (response) {
          
          response.data.city = response.data.city.toUpperCase()
          callback(response.data)
        })
      }
    },
    blackOrWhiteRGBA: function (rgba) {
      var rValue = (1 - rgba.a) * 255 + rgba.a * rgba.r
      var gValue = (1 - rgba.a) * 255 + rgba.a * rgba.g
      var bValue = (1 - rgba.a) * 255 + rgba.a * rgba.b

      var contrast = (Math.round(rValue * 299) + Math.round(gValue * 587) + Math.round(bValue * 114)) / 1000
      return (contrast >= 128) ? 'black' : 'white'
    },
    blackOrWhite: function (hex) {
      try {
        // ********************************************************************************* //
        // from http://codepen.io/znak/pen/aOvMOd
        // and http://stackoverflow.com/a/3943023/368896.
        // And http://stackoverflow.com/a/5624139/368896 for the conversion from hex to RGB
        // ********************************************************************************* //
        // 'hex' must be in form "#rrggbb"
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b
        })

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

        if (!result) {
          return '#000000'
        }

        var R = parseInt(result[1], 16)
        var G = parseInt(result[2], 16)
        var B = parseInt(result[3], 16)

        var C = [R / 255, G / 255, B / 255]

        for (var i = 0; i < C.length; ++i) {
          if (C[i] <= 0.03928) {
            C[i] = C[i] / 12.92
          } else {
            C[i] = Math.pow((C[i] + 0.055) / 1.055, 2.4)
          }
        }

        var L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2]
        if (L > 0.179) {
          return '#000000'
        }
        return '#FFFFFF'
      } catch (err) {
        return '#000000'
      }
    },
    getObjectFromArrayWithId: function (array, id) {
      if (id) {
        for (var i = 0; i < array.length; i++) {
          var object = array[i]
          if (object.id === id) {
            return object
          }
        }
      }
      return {}
    },
    stripPhoneCharacters: function (inString) {
      // just in case we will convert to string
      return inString.toString().replace(/\D/g, '')
    },
    formatAsPhoneNumber: function (value) {
      if (typeof value === 'string') {
        var valueArray = value.split('')
        var formattedValueArray = []
        var formattedValue = ''
        // Remove anything that isn't a number.
        for (var i = 0; i < valueArray.length; i++) {
          if (!isNaN(parseFloat(valueArray[i])) && isFinite(valueArray[i])) {
            formattedValueArray.push(valueArray[i])
          }
        }
        for (i = 0; i < formattedValueArray.length; i++) {
          if (i === 0 && formattedValueArray.length > 0) {
            formattedValue = formattedValue + '(' + formattedValueArray[i]
          } else if (i === 2 && formattedValueArray.length > 3) {
            formattedValue = formattedValue + formattedValueArray[i] + ') '
          } else if (i === 5 && formattedValueArray.length > 6) {
            formattedValue = formattedValue + formattedValueArray[i] + '-'
          } else if (i <= 9) {
            formattedValue = formattedValue + formattedValueArray[i]
          }
        }
        return formattedValue
      }
      return ''
    },
    sendGenericEmail: function (emailObject, shopId, emailType, objectId, callbackFunction) {
      Api.post(`/api/shop/${shopId}/${emailType}/${objectId}/sendEmail`, emailObject).then(res => {
        if (callbackFunction) {
          callbackFunction(res.data)
        }
      })
    },
    retreivePdf: function (url, callback, autoPrint) {
      this.openContent(url, autoPrint, callback, true)
    },
    openContent: async function (location, explicitNewTab, callback, withToken) {
      let url = location
      if (withToken) {
        const token = await this.$auth.getTokenSilently()
        const symbol = url.indexOf('?') >= 0 ? '&' : '?'
        url += `${symbol}token=Bearer ${token}`
      }
      var res = await LegacyApi.get(url, {responseType: 'arraybuffer'})
      callback(res)
    },
    retrievePdfContentForAttachment: async function (url, jobId, jobStatus, callback) {
      // var newWindow = window.open(url, '')
      // newWindow.addEventListener('load', function (res) {
      //   
      // }, false)
      // Axios({
      //   method: 'GET',
      //   responseType: 'arraybuffer',
      //   url: '/legacy' + url
      // }).then(res => {
      var res = await LegacyApi.get(url, {responseType: 'arraybuffer'})
      let temp = window.btoa(
        new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
      )
      let result = {
        'content-type': res.headers['content-type'],
        'file-name': `${jobStatus}-${jobId}.pdf`,
        'content': temp
      }
      callback(result)
      // })
    },
    dateReverseFromClient: function (val) {
      if (typeof val === 'string') {
        if (val.length === 10) {
          var regexClientFormat = /(\d{2})\/(\d{2})\/(\d{4})/
          var results = val.match(regexClientFormat)
          if (results != null) {
            var m_ = results[1]
            var d_ = results[2]
            var y_ = results[3]
            val = y_ + '-' + m_ + '-' + d_
          }
        }
      }
      return val
    },
    sortArrayOfObjects: function (objects, field) {
      function compare (a, b) {
        if (a[field] < b[field]) {
          return -1
        }
        if (a[field] > b[field]) {
          return 1
        }
        return 0
      }
      return objects.sort(compare)
    },
    // Thanks to https://stackoverflow.com/a/2893259/368896
    formatXml: function (xml) { // sep = optional indent value, default is tab (\t)
      var reg = /(>)\s*(<)(\/*)/g // updated Mar 30, 2015
      var wsexp = / *(.*) +\n/g
      var contexp = /(<.+>)(.+\n)/g
      xml = xml.replace(reg, '$1\n$2$3').replace(wsexp, '$1\n').replace(contexp, '$1\n$2')
      var formatted = ''
      var lines = xml.split('\n')
      var indent = 0
      var lastType = 'other'
      // 4 types of tags - single, closing, opening, other (text, doctype, comment) - 4*4 = 16 transitions
      var transitions = {
        'single->single': 0,
        'single->closing': -1,
        'single->opening': 0,
        'single->other': 0,
        'closing->single': 0,
        'closing->closing': -1,
        'closing->opening': 0,
        'closing->other': 0,
        'opening->single': 1,
        'opening->closing': 0,
        'opening->opening': 1,
        'opening->other': 1,
        'other->single': 0,
        'other->closing': -1,
        'other->opening': 0,
        'other->other': 0
      }
      var lineSep = '<br />'
      for (var i = 0; i < lines.length; i++) {
        var ln = lines[i]
        // Luca Viggiani 2017-07-03: handle optional <?xml ... ?> declaration
        if (ln.match(/\s*<\?xml/)) {
          formatted += ln + lineSep
          continue
        }
        // ---
        var single = Boolean(ln.match(/<.+\/>/)) // is this line a single tag? ex. <br />
        var closing = Boolean(ln.match(/<\/.+>/)) // is this a closing tag? ex. </a>
        var opening = Boolean(ln.match(/<[^!].*>/)) // is this even a tag (that's not <!something>)
        var type = single ? 'single' : closing ? 'closing' : opening ? 'opening' : 'other'
        var fromTo = lastType + '->' + type
        lastType = type
        var padding = ''
        var desiredPadding = '&nbsp;&nbsp;&nbsp;&nbsp;'
        var openTag = '&lt;'
        var closeTag = '&gt;'

        indent += transitions[fromTo]
        for (var j = 0; j < indent; j++) {
          padding += desiredPadding
        }
        var appendNode = ln.length > 1 && ln[0] === '<' && ln[ln.length - 1] === '>' ? openTag + ln.substring(1, ln.length - 1) + closeTag : ln // Replace < and > with &lt; and &gt; if desired
        if (fromTo === 'opening->closing') {
          formatted = formatted.substr(0, formatted.length - lineSep.length) + appendNode + lineSep // substr removes line break (\n) from prev loop
        } else {
          formatted += padding + appendNode + lineSep
        }
      }
      return formatted
    },
    padValue: function (n, width, z) {
      z = z || '0'
      n = n + ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    },
    sendJobFax: async function (faxObject, shopId, CallbackFunction) {
      pdf.getShopDefault(shopId, async function (res) {
        let pdfDefault = res.data.version
        let pdfCondensed = res.data.condensed
        // if (faxObject.jobpdfsent === 1) {
        if (pdfDefault === 'legacy') {
          if (faxObject.jobpdfsent === 1) {
            var url = '/pdf/job/invoice/' + faxObject.jobId
            if (faxObject.noPrices === true || faxObject.customercopy === true || faxObject.insuranceCopy === true) {
              if (faxObject.noPrices === true) {
                url += '?noprices=1'
              } else if (faxObject.customercopy === true) {
                url += '?customercopy=1&noprices=1'
              } else if (faxObject.insuranceCopy === true) {
                url += '?insurancecopy=1'
              }
              if (pdfCondensed == 1) {
                url += '&condensed=1'
              }
            } else {
              if (pdfCondensed === 1) {
                url += '?condensed=1'
              }
            }
            var pdfData = await LegacyApi.get(url, {responseType: 'arraybuffer'})
            let temp = new Uint8Array(pdfData.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            faxObject.jobPdfData = {
              'content-type': res.headers['content-type'],
              'file-name': `${faxObject.jobtype}-${faxObject.jobId}.pdf`,
              'content': temp
            }
            const {data} = await Api.post(`/api/shop/${shopId}/job/${faxObject.jobId}/fax/legacy`, faxObject)
            CallbackFunction(data)
          } else {
            const {data} = await Api.post(`/api/shop/${shopId}/job/${faxObject.jobId}/fax/legacy`, faxObject)
            CallbackFunction(data)
          }
        } else if (pdfDefault === 'redesign') {
          let variant = ''
          if (faxObject.customercopy === true) {
            variant = 'customerCopy'
          } else if (faxObject.noPrices === true) {
            variant = 'noPrices'
          } else if (faxObject.insuranceCopy === true) {
            variant = 'insuranceCopy'
          } else {
            variant = 'withPrices'
          }
          const { data } = await Api.post(`/api/shop/${shopId}/job/${faxObject.jobId}/fax/variant/${variant}`, faxObject)
          CallbackFunction(data)
        }
        // }
      })
    },
    sendPurchaseOrderFax: async function (faxObject, shopId, CallbackFunction) {
      const { data } = await Api.post(`/api/shop/${shopId}/purchase_order/${faxObject.object_id}/fax`, faxObject)
      CallbackFunction(data)
    },
    sendBillFax: async function (faxObject, shopId, CallbackFunction) {
      const { data } = await Api.post(`/api/shop/${shopId}/bill/${faxObject.object_id}/fax`, faxObject)
      CallbackFunction(data)
    },
    sendBillPaymentFax: async function (faxObject, shopId, CallbackFunction) {
      const { data } = await Api.post(`/api/shop/${shopId}/bill_payment/${faxObject.object_id}/fax`, faxObject)
      CallbackFunction(data)
    },
    sendJournalEntryFax: async function (faxObject, shopId, CallbackFunction) {
      const { data } = await Api.post(`/api/shop/${shopId}/journal_entry/${faxObject.object_id}/fax`, faxObject)
      CallbackFunction(data)
    },
    sendExpenseFax: async function (faxObject, shopId, CallbackFunction) {
      const { data } = await Api.post(`/api/shop/${shopId}/expense/${faxObject.object_id}/fax`, faxObject)
      CallbackFunction(data)
    },
    sendReceiveFax: async function (faxObject, shopId, CallbackFunction) {
      const { data } = await Api.post(`/api/shop/${shopId}/receive/${faxObject.object_id}/fax`, faxObject)
      CallbackFunction(data)
    },
    sendTechPickupFax: async function (faxObject, shopId, CallbackFunction) {
      const { data } = await Api.post(`/api/shop/${shopId}/tech_pickup/${faxObject.object_id}/fax`, faxObject)
      CallbackFunction(data)
    },
    sendVendorReturnFax: async function (faxObject, shopId, CallbackFunction) {
      const { data } = await Api.post(`/api/shop/${shopId}/vendor_return/${faxObject.object_id}/fax`, faxObject)
      CallbackFunction(data)
    },
    sendAdjustmentFax: async function (faxObject, shopId, CallbackFunction) {
      const { data } = await Api.post(`/api/shop/${shopId}/unit_adjustments/${faxObject.object_id}/fax`, faxObject)
      CallbackFunction(data)
    },
    getFaxConfirmationPage: async function (faxId, CallbackFunction) {
      if (!isNaN(faxId)) {
        try {
          const { data } = await LegacyApi.get('/faxtransmittal', { params: { id: faxId }, headers: {'Content-Type': 'multipart/form-data'} })
          CallbackFunction(data)
        } catch (error) {
          console.log('confirmation error: ', error)
        }
      }
    },
    getFaxSentPdf: async function (faxId) {
      return await Api.get(`/api/fax/pdf/${faxId}`, {headers: {'Content-Type': 'multipart/form-data'}})
    },
    getFaxTransmittalPdf: async function (faxId) {
      return await Api.get(`/api/fax/transmittal/${faxId}`, {headers: {'Content-Type': 'multipart/form-data'}})
    },
    base64ToArrayBuffer: function (data) {
      var binaryString = window.atob(data)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    capitalizeClassWatcherInit: function () {
      const makeUppercase = function (value) {
        if (typeof value === 'string') {
          return value.toUpperCase()
        }
        return ''
      }

      $('.capitalize').each(function (index) {
        $(this).val(makeUppercase($(this).val()))
      })

      $('body').on('keyup', '.capitalize', function (e) {
        // 
        // var inputPosition = this.selectionStart
        $(this).val(makeUppercase($(this).val()))
        // this.selectionStart = this.selectionEnd = inputPosition
        // $(this).trigger('change')
        $(this)[0].dispatchEvent(new CustomEvent('input'))
      })
    },
    capitalizeClassWatcherDestroy: function () {
      $('body').off('keyup', '.capitalize')
    },
    autocompleteOffInit: function () {
      $('.autocomplete-off').each(function (index) {
        
        $(this).prop('autocomplete', 'disabled')
        // $(this).attr('readonly', false)
      })
    },
    StringifyValidProperties (object) {
      return JSON.stringify(object, this.replacer)
    },
    replacer (key, value) {
      if (key === 'objectTrackerInitial' || key.indexOf('_') === 0) {
        return undefined
      } else {
        return value
      }
    },
    changeRouteWithCheck: function (url) {
      this.$store.dispatch('triggerGoToPage', url)
    },
    setDefaultFrontend: function (isRedesign, CallbackFunction = null) {
      Api.post(`/api/default/frontend`, {isRedesign: isRedesign}).then(res => {
        if (CallbackFunction) {
          CallbackFunction(res.data)
        }
      })
    },
    getScrollBarWidth: function () {
      var inner = document.createElement('p')
      inner.style.width = '100%'
      inner.style.height = '200px'

      var outer = document.createElement('div')
      outer.style.position = 'absolute'
      outer.style.top = '0px'
      outer.style.left = '0px'
      outer.style.visibility = 'hidden'
      outer.style.width = '200px'
      outer.style.height = '150px'
      outer.style.overflow = 'hidden'
      outer.appendChild(inner)

      document.body.appendChild(outer)
      var w1 = inner.offsetWidth
      outer.style.overflow = 'scroll'
      var w2 = inner.offsetWidth
      if (w1 === w2) w2 = outer.clientWidth

      document.body.removeChild(outer)
      return (w1 - w2)
    },
    objectsToCommaSeparatedString (objects, identifier) {
      if (objects && identifier) {
        var responseString = ''
        for (var i = 0; i < objects.length; i++) {
          responseString += objects[i][identifier]
          if (i < objects.length - 1) {
            responseString += ','
          }
        }
        return responseString
      }
      return null
    },
    getShopFromId (component, shopId) {
      for (var i = 0; i < component.$store.state.shops.length; i++) {
        if (component.$store.state.shops[i].id === shopId) {
          return component.$store.state.shops[i]
        }
      }
      return null
    }
  },
  computed: {
    isMobile: function () {
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    checkCommissionPermission: function() {
      var temp = permissionCheck(permissions.COMMISSION, this.$store.state)
      return temp
    }
  }
}

export const camelCaseToTitle = (camelCase) => camelCase.replace(/([A-Z])/g, function(match) {return " " + match;}).replace(/^./, function(match) {return match.toUpperCase();})
export const snakeCaseToTitle = (snakeCase) => {
  let wordArr = snakeCase.split('_')
  for (var i = 0; i < wordArr.length; i++) {
    wordArr[i] = wordArr[i][0].toUpperCase() + wordArr[i].slice(1)
  }
  return wordArr.join(' ')
}
export const getBlackOrWhite = color => tinycolor.mostReadable(color, [], {includeFallbackColors: true})

export const testEmail = R.test(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
