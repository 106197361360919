<template>
  <div class="claimDamageInformationArea">
    <p>Damage Information</p>
    <div v-for='(info, index) in damageInformationArray' :key='index'>
      <InfoCard :schema="schema" :dataObject.sync="damageInformationArray[index]" :deleteButtonMethod="!fieldsDisabled && index != 0 ? removeDamageInformation : null" :deleteIndex="index" :hideHeader="true" />
    </div>
    <el-button v-if="!fieldsDisabled"
      size="mini"
      icon="las la-download"
      plain
      @click="addMoreDamageInformation"
    >
      + Add More Damage Information
    </el-button>
  </div>
</template>

<script>
import InfoCard from '@/components/modals/GlaxisDialogs/claim/components/InfoCard'
import { applyMediaRules } from '@/scripts/helpers/media.helpers'
import GlaxisLossDamageInfo from '@/scripts/objects/glaxis_loss_damage_info'

export default {
  components: { InfoCard },
  props: ['damageInformationArray', 'fieldsDisabled', 'removeDamageInformation'],
  computed: {
    rowSpanOne() {
      return applyMediaRules({ lg: 24, md: 24, sm: 24, xs: 24 })
    },
    rowSpanTwo() {
      return applyMediaRules({ lg: 12, md: 12, sm: 12, xs: 12 })
    },
    rowSpanThree() {
      return applyMediaRules({ lg: 8, md: 8, sm: 12, xs: 12 })
    },
    rowSpanFour() {
      return applyMediaRules({ lg: 6, md: 6, sm: 12, xs: 12 })
    },
    schema() {
      return {
        fields: [[
          {
            label: '*Location of Damage, Opening On Vehicle',
            id: 'location_of_damage',
            tooltip: 'Capture what glass will be repaired or replaced',
            componentType: 'inputWithLabel',
            redIfEmpty: true,
            labelKey: 'label',
            disabled: this.fieldsDisabled,
            select: {
              showLabelValue: true,
              valueKey: 'value',
              clearable: true,
              options: [{
                label: 'WR - Windshield Repair',
                value: 'WR'
              }, {
                label: 'WS - Windshield',
                value: 'WS'
              }, {
                label: 'DR - Door',
                value: 'DR'
              }, {
                label: 'SI - Side',
                value: 'SI'
              }, {
                label: 'VN - Vent',
                value: 'VN'
              }, {
                label: 'QT - Quarter',
                value: 'QT'
              }, {
                label: 'PT - Partition',
                value: 'PT'
              }, {
                label: 'BK - Back Window',
                value: 'BK'
              }, {
                label: 'RF - Roof',
                value: 'RF'
              }
              ],
              fixedOptions: true
            },
            span: this.rowSpanThree,
            overrideClearable: false
          },
          {
            label: 'NAGS Position Code',
            id: 'position_on_vehicle',
            tooltip: 'Position on vehicle',
            componentType: 'inputWithLabel',
            labelKey: 'label',
            disabled: this.fieldsDisabled,
            select: {
              showLabelValue: true,
              valueKey: 'value',
              clearable: true,
              options: [{
                label: 'B - Back',
                value: 'B'
              }, {
                label: 'F - Front',
                value: 'F'
              }, {
                label: 'M - Middle',
                value: 'M'
              }, {
                label: 'R - Rear',
                value: 'R'
              }
              ],
              fixedOptions: true
            },
            span: this.rowSpanThree,
            overrideClearable: false
          },
          {
            label: 'NAGS Relative Location Code',
            tooltip: 'Relative position on vehicle',
            id: 'relative_position_on_vehicle',
            componentType: 'inputWithLabel',
            labelKey: 'label',
            disabled: this.fieldsDisabled,
            select: {
              showLabelValue: true,
              valueKey: 'value',
              clearable: true,
              options: [{
                label: 'IN - Inner',
                value: 'IN'
              }, {
                label: 'LO - Lower',
                value: 'LO'
              }, {
                label: 'OU - Outer',
                value: 'OU'
              }, {
                label: 'UP - Upper',
                value: 'UP'
              }
              ],
              fixedOptions: true
            },
            span: this.rowSpanThree,
            overrideClearable: false
          }
        ], [
          {
            label: 'NAGS Side Code',
            tooltip: 'Side of vehicle',
            id: 'side_of_vehicle',
            componentType: 'inputWithLabel',
            labelKey: 'label',
            disabled: this.fieldsDisabled,
            select: {
              showLabelValue: true,
              valueKey: 'value',
              clearable: true,
              options: [{
                label: 'L - Left',
                value: 'L'
              }, {
                label: 'R - Right',
                value: 'R'
              }, {
                label: 'C - Center',
                value: 'C'
              }
              ],
              fixedOptions: true
            },
            span: this.rowSpanThree,
            overrideClearable: false
          },
          {
            label: '*Quantity of Glass For Loss',
            id: 'quantity_of_glass_for_loss',
            tooltip: 'Capture the pieces of glass that will be used to service the vehicle',
            componentType: 'inputWithLabel',
            redIfEmpty: true,
            disabled: this.fieldsDisabled,
            labelKey: 'label',
            select: {
              showLabelValue: true,
              valueKey: 'value',
              clearable: true,
              options: [{
                label: '1',
                value: '1'
              }, {
                label: '2',
                value: '2'
              }, {
                label: '3',
                value: '3'
              }, {
                label: '4',
                value: '4'
              }, {
                label: '5',
                value: '5'
              }, {
                label: '6',
                value: '6'
              }, {
                label: '7',
                value: '7'
              }, {
                label: '8',
                value: '8'
              }, {
                label: '9',
                value: '9'
              }
              ],
              fixedOptions: true
            },
            span: this.rowSpanThree,
            overrideClearable: false
          },
          {
            label: '*Retailer Qualification of Repair Option',
            id: 'glass_only_loss',
            tooltip: 'If Glass Damage Opening is Windshield, the Retailer must certify that the windshield was not repairable',
            componentType: 'inputWithLabel',
            labelKey: 'label',
            redIfEmpty: true,
            disabled: this.fieldsDisabled,
            select: {
              showLabelValue: true,
              valueKey: 'value',
              clearable: true,
              options: [{
                label: 'Repairable',
                value: 'N'
              }, {
                label: 'Not Repairable',
                value: 'Y'
              }
              ],
              fixedOptions: true
            },
            span: this.rowSpanThree,
            overrideClearable: false
          }
        ]
        ]
      }
    }
  },
  methods: {
    addMoreDamageInformation() {
      this.damageInformationArray.push(new GlaxisLossDamageInfo())
    }
  }
}
</script>

<style>
.claimDamageInformationArea {
  margin-bottom: 15px;
}
</style>
