var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "places-autocomplete-block" }, [
    _c("div", [
      _c("div", { staticClass: "main" }, [_vm._v(_vm._s(_vm.main_text))]),
      _c("div", { staticClass: "secondary" }, [
        _vm._v(_vm._s(_vm.secondary_text))
      ])
    ]),
    _vm.result.extra
      ? _c("div", { staticClass: "extra" }, [
          _vm._v(" " + _vm._s(_vm.result.extra) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }