<template>
  <el-dialog
    :visible="value"
    @close="close"
    width="600px"
    :before-close="handleClose"
    @open="onOpen"
    @opened="opened"
    destroy-on-close
    custom-class="gb-el-dialog consumer-dialog"
    top="24px"
    ref="dialog"
    v-bind="$attrs"
    append-to-body
  >
    <consumer-info v-model="data" v-if="consumer" />
    <consumer-new v-model="data" v-else />
    <consumer-tabs v-model="data" ref="tabs" :isMerge="isMerge" />
    <div
      slot="title"
      class="consumer"
      :style="{ display: 'flex', alignItems: 'center' }"
    >
      {{ consumer ? "Edit" : "New Customer" }}
      <consumer-type-select v-model="data" />
    </div>
    <div slot="footer">
      <el-alert
        type="error"
        title="Consumer form contains errors"
        v-if="!valid"
        @close="valid = true"
      />
      <el-button @click="onClose">Cancel</el-button>
      <el-button type="primary" :loading="loading" @click="saveConsumer">
        Save
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as R from 'ramda'
import Pricelevel from '@/scripts/objects/pricelevel'
import ConsumerInfo from './ConsumerInfo'
import ConsumerNew from './ConsumerNew'
import ConsumerTabs from './ConsumerTabs'
import ConsumerTypeSelect from '../../JobPageV2/helpers/ConsumerTypeSelect'
import {
  CREATE_CONSUMER_MUTATION,
  UPDATE_CONSUMER_MUTATION
} from '@/apollo/mutation/consumer.mutation'

export default {
  components: { ConsumerInfo, ConsumerTabs, ConsumerNew, ConsumerTypeSelect },
  props: {
    value: Boolean,
    consumer: Object,
    shop_id: {
      type: Number
    },
    organization_id: {
      type: Number
    },
    focus: Boolean,
    isMerge: Boolean
  },
  data() {
    return {
      data: {},
      taxes: [],
      loading: false,
      valid: true
    }
  },
  methods: {
    onClose() {
      this.handleClose(this.close)
    },
    async close() {
      this.$emit('input', false)
      this.data = {}
      this.valid = true
    },
    async handleClose(done) {
      if (!R.equals(this.consumer, this.data)) {
        if (
          await this.$messageBox.confirm('Close modal with unsaved changes?')
        ) {
          done()
        }
      } else {
        done()
      }
    },
    getPriceLevels() {
      return new Promise((resolve) => {
        Pricelevel.getPricelevels(this.shop_id, resolve)
      })
    },
    async validateDialog() {
      let valid = false
      valid = await this.$refs.tabs.validate()
      const noname =
        !(this.data.is_commercial || this.data.is_insurance) ||
        this.data.company_name
      if (!noname) {
        this.focusCompanyName()
      }
      valid = noname
      return valid
    },
    async trimNames(consumer) {
      const { company_name, name, names } = consumer

      consumer.company_name = company_name.trim()

      if (name) {
        consumer.name.first = name.first ? name.first.trim() : null
        consumer.name.last = name.last ? name.last.trim() : null
      }

      if (names) {
        const trimmedNames = []

        for (const n of names) {
          trimmedNames.push({
            first: n.first ? n.first.trim() : null,
            middle: null,
            id: n.id,
            last: n.last ? n.last.trim() : null
          })
        }

        consumer.names = trimmedNames
      }

      return consumer
    },
    async saveConsumer() {
      try {
        if (await this.validateDialog()) {
          if (this.isMerge) {
            if (!(await this.$messageBox.confirm('Are you sure you want to perform this merge? MERGING CANNOT BE UNDONE!!', 'NOTICE', { type: 'warning' }).catch(() => {}))) {
              return
            }
          }

          if (!this.data.reserve_balance) {
            this.data.reserve_balance = 0
          }

          this.data = await this.trimNames(this.data)

          this.$emit('before-save', this.data)
          this.valid = true
          this.loading = true
          this.data = R.omit(['associated_consumers', 'shop', 'organization'])(this.data)

          const { data } = await this.$apollo.mutate({
            mutation: this.consumer?.id
              ? UPDATE_CONSUMER_MUTATION
              : CREATE_CONSUMER_MUTATION,
            variables: {
              consumer: this.data,
              ...(this.consumer?.id ? { consumer_id: this.consumer.id } : {})
            }
          })
          const { id } = data.createConsumer || data.updateConsumer
          this.$emit('on-save', this.data, id)
          this.$emit('input', false)
          this.$root.$emit('consumerSuccessfullySaved', id)
        } else {
          this.valid = false
        }
      } finally {
        this.loading = false
      }
    },
    onOpen() {
      this.changed = false
      if (this.consumer) {
        this.data = R.clone(this.consumer)
        if (!this.consumer.id) {
          this.data.auto_add_to_fleet = +(!this.consumer.is_commercial)
        }
      } else {
        this.data = {
          name: {},
          names: [{}],
          phone: {},
          phones: [{}],
          email: {},
          emails: [{}],
          address: {},
          addresses: [{}],
          is_commercial: 0,
          is_insurance: 0,
          organization_id: this.organization_id,
          reserve_balance: 0,
          auto_add_to_fleet: 1,
          consumer_shop_settings: []
        }
      }
    },
    focusCompanyName() {
      const element = this.$refs.dialog.$el.querySelector('[tabindex="0"]')
      if (element) {
        element.focus()
      }
    },
    opened() {
      if (this.focus) {
        this.focusCompanyName()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-alert {
  margin-bottom: $padding-md;
}
</style>

<style lang="scss">
.el-dialog.gb-el-dialog.consumer-dialog {
  margin-top: $padding-lg;
  .el-dialog__body {
    padding-top: 17px;
  }
}
</style>
