var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        "custom-class": "no-body-padding",
        title: "Select item source"
      }
    },
    [
      _vm.loading
        ? _c("LoadingSpinner")
        : _vm.item
        ? _c(
            "div",
            { staticClass: "t-flex t-flex-wrap t-px-3 t-pt-6 t-bg-gray-50" },
            [
              _vm.item.availableUnitsQuantity
                ? _c("Card", {
                    attrs: {
                      type: "available",
                      number: _vm.item.number,
                      total: _vm.item.availableUnitsQuantity,
                      price: _vm.item.availableUnitPrice
                    },
                    on: {
                      selected: function($event) {
                        return _vm.select({
                          type: "available",
                          price: _vm.item.availableUnitPrice
                        })
                      }
                    }
                  })
                : _vm._e(),
              _vm._l(_vm.item.unreceivedUnits, function(i, index) {
                return _c("Card", {
                  key: "unreceived-" + index,
                  attrs: {
                    id: i.id,
                    type: "unreceived",
                    number: i.number,
                    total: i.quantity,
                    price: i.price,
                    poId: i.purchaseOrderId,
                    poInternalId: i.purchaseOrderInternalId
                  },
                  on: {
                    viewPO: _vm.openPO,
                    selected: function($event) {
                      return _vm.select({
                        type: "unreceived",
                        price: i.price,
                        poId: i.purchaseOrderId
                      })
                    }
                  }
                })
              }),
              _vm.unsoucedAllowed
                ? _c("div", { staticClass: "t-w-1/4 t-px-3 t-pb-6" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "t-flex t-flex-col t-items-stretch t-border t-border-solid t-border-gray-200\n         t-p-4 t-rounded-md t-shadow t-min-h-[200px] t-h-full t-bg-white"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "t-text-center t-text-lg t-mb-4" },
                          [_vm._v("Add unsourced")]
                        ),
                        _c(
                          "div",
                          { staticClass: "t-text-center" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  plain: "",
                                  size: "mini",
                                  type: "primary",
                                  icon: "el-icon-plus"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.select()
                                  }
                                }
                              },
                              [_vm._v(" Add ")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }