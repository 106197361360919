var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-parent" },
    [
      _vm.accountType === "Clearing Account"
        ? _c(
            "el-popover",
            {
              attrs: { placement: "top-start", width: "275", trigger: "hover" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "text-align": "start"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      "A bank account must be chosen as\na clearing account for adjusting entries."
                    )
                  )
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "select-label special-content-label",
                  attrs: { slot: "reference" },
                  slot: "reference"
                },
                [_vm._v("*" + _vm._s(_vm.label))]
              )
            ]
          )
        : _c("span", { staticClass: "select-label" }, [
            _vm._v(_vm._s(_vm.label))
          ]),
      _c(
        "el-select",
        {
          attrs: {
            filterable: true,
            placeholder: "Select",
            disabled: _vm.disabled,
            loading: _vm.loading,
            "popper-class": "qbo-mapping-popper"
          },
          on: { change: _vm.updateShopDefaultQboAccountHandler },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _vm.accountType.includes("Adjustment") ||
          _vm.accountType === "Clearing Account"
            ? _c("el-option", {
                key: "System Default",
                attrs: { label: "No Adjusting Entry", value: "System Default" }
              })
            : _c("el-option", {
                key: "System Default",
                attrs: { label: "System Default", value: "System Default" }
              }),
          _vm._l(_vm.options, function(item) {
            return _c(
              "el-option",
              {
                key: item.Id,
                staticClass: "qbo-mapping-option",
                attrs: { label: item.Name, value: item.Id }
              },
              [
                _c(
                  "quick-books-account-option",
                  _vm._b({}, "quick-books-account-option", item, false)
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }