var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    !!_vm.value || _vm.visible
      ? _c(
          "div",
          {
            staticClass: "checkbox-tag",
            class:
              ((_obj = {}),
              (_obj[_vm.color] = true),
              (_obj.checked = !!_vm.value),
              (_obj.focused = _vm.focused),
              _obj)
          },
          [
            _c("label", [
              _c("input", {
                attrs: { type: "checkbox" },
                domProps: { checked: !!_vm.value },
                on: { focus: _vm.focus, blur: _vm.blur, change: _vm.change }
              }),
              _vm._v(" " + _vm._s(_vm.label) + " ")
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }