
import { EventType } from './events';

const layout = [
  {
    title: 'Job',
    events: [
      {
        title: 'Complete',
        description: 'Triggers when a job is marked complete on TechSIDE Mobile.',
        key: EventType.EVENT_TYPE_JOB_COMPLETED,
        permission: 'notificationjobcompleted'
      },
      {
        title: 'Signature Received',
        description: 'Triggers when a customer electronically signs a quote, work order or invoice via SMS signature request.',
        key: EventType.EVENT_TYPE_SMS_SIGNATURE_RECEIVED,
        permission: 'notificationsmssignaturereceived'
      },
      {
        title: 'Payment Created',
        description: 'Triggers when a payment is applied to job in GlassBiller.',
        key: EventType.EVENT_TYPE_PAYMENT_CREATED,
        permission: 'notificationpaymentcreated'
      },
      {
        title: 'Right Part Received',
        description: 'Triggers when a response to a right part request is received.',
        key: EventType.EVENT_TYPE_RIGHT_PART_RECEIVED,
        permission: 'notificationrightpartreceived'
      },
      {
        title: 'Form Signed',
        description: 'Triggers when a customer electronically signs a GB Form.',
        key: EventType.EVENT_TYPE_FORM_SIGNED,
        permission: 'notificationformsigned'
      },
    ]
  },
  {
    title: 'Scheduling',
    events: [
      {
        title: 'Job Rescheduled',
        description: 'Triggers when a job is marked for rescheduling.',
        key: EventType.EVENT_TYPE_JOB_RESCHEDULED,
        permission: 'notificationjobrescheduled'
      },
      {
        title: 'Job Canceled',
        description: 'Triggers when a job is marked for cancellation.',
        key: EventType.EVENT_TYPE_JOB_CANCELED,
        permission: 'notificationjobcanceled'
      },
      {
        title: 'Job Received',
        description: 'Triggers when a job is received from SaleSIDE',
        key: EventType.EVENT_TYPE_JOB_RECEIVED,
        permission: 'notificationjobreceived'
      },
      {
        title: 'Confirmation Declined',
        description: 'Triggers when a customer replies "No" to a schedule confirmation SMS',
        key: EventType.EVENT_TYPE_SMS_CONFIRMATION_DECLINED,
        permission: 'notificationsmsconfirmationdeclined'
      },
      {
        title: 'Web Lead',
        description: 'Triggers when a web lead is received',
        key: EventType.EVENT_TYPE_WEB_LEAD_RECEIVED,
        permission: 'notificationwebleadreceived'
      },
    ]
  },
  {
    title: 'Export',
    events: [
      {
        title: 'Data Exported',
        description: 'Triggers when data is exported from the Jobs, Customers or Vendors table.',
        key: EventType.EVENT_TYPE_DATA_EXPORTED,
        permission: 'notificationdataexported'
      },
      {
        title: 'Quickbooks Export Error',
        description: 'Triggers when an error occurs while exporting data to Quickbooks Online.',
        key: EventType.EVENT_TYPE_QUICKBOOKS_EXPORT_ERROR,
        permission: 'notificationquickbooksexporterror'
      },
    ]
  },

  {
    title: 'Communications',
    events: [
      {
        title: 'SMS Received',
        description: 'Triggers when we got new sms',
        key: EventType.EVENT_TYPE_SMS_RECEIVED,
        permission: 'notificationsmsreceived'
      }
    ]
  }
];

export { layout };