<template>
  <el-dropdown class="tech-menu" trigger="click" @command="command">
    <div class="tech-menu-wrapper">
      <v-icon :name="loading ? 'spinner' : 'ellipsis-h'" :spin="loading" />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item icon="el-icon-edit-outline" command="setTechModal">
        Details
      </el-dropdown-item>
      <el-dropdown-item icon="el-icon-document-copy" command="createJobPdfs">
        Download PDF
      </el-dropdown-item>
      <el-dropdown-item
        icon="el-icon-chat-line-round"
        :disabled="!textsEnabled"
        command="startTextMessagingChat"
      >
        Messaging
      </el-dropdown-item>
      <el-dropdown-item icon="el-icon-date" command="openCalendar">
        Availability
      </el-dropdown-item>
      <el-dropdown-item
        disabled
        icon="el-icon-add-location"
        command="openWaypointsModal"
      >
        Waypoints
      </el-dropdown-item>
      <el-dropdown-item
        :disabled="!techHasJobsToday"
        icon="el-icon-magic-stick"
        command="openBulkRescheduleModal"
      >
        Reschedule Route
      </el-dropdown-item>
      <el-dropdown-item
        :disabled="!techHasJobsToday"
        icon="material-icons-outline md-swap_horiz t-text-xs"
        command="openBulkReassignModal"
      >
        Reassign Route
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { sync } from 'vuex-pathify'
import * as R from 'ramda'

export default {
  data() {
    return {
      loading: false
    }
  },
  props: ['tech', 'route'],
  computed: {
    textsEnabled() {
      return (
        this.$store.state.user.has_access_to_twilio_phone_numbers &&
        this.tech.phone
      )
    },
    techHasJobsToday() {
      if (this.route?.route_entries?.length > 0) {
        return true
      }
      return false
    },
    waypointModalTech: sync('sch_v3/waypointModalTech')
  },
  methods: {
    command(command) {
      if (this[command]) {
        this[command]()
      }
    },
    async getTechInfo() {
      const { data } = await this.$unum
        .vendor()
        .get(
          `/data/${this.tech.id}`
        )
      return data
    },
    async setTechModal() {
      this.loading = true
      const data = await this.getTechInfo()
      this.loading = false
      this.$emit('open-tech-dialog', data)
    },
    startTextMessagingChat() {
      this.$root.$emit('startTextMessagingChat', {
        model: 'tech',
        id: this.tech.id,
        name: this.tech.name,
        phoneNumber: this.tech.phone
      })
    },
    openCalendar() {
      const routeData = this.$router.resolve({
        path: '/availability',
        query: {
          tech_id: R.path(['tech', 'id'])(this),
          org_id: R.path(['tech', 'organization_id'])(this)
        }
      })
      window.open(routeData.href, '_blank')
    },
    createJobPdfs() {
      this.$emit('open-pdf')
    },
    openWaypointsModal() {
      this.waypointModalTech = {
        ...this.tech,
        order: R.pipe(R.path(['route', 'route_entries']), R.length)(this)
      }
    },
    openBulkRescheduleModal() {
      this.$emit('open-bulk-reschedule-dialog')
    },
    openBulkReassignModal() {
      this.$emit('open-bulk-reassign-dialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.tech-menu {
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 16px;
  cursor: pointer;
  .tech-menu-wrapper {
    height: 16px;
  }
}
</style>
