var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _c(
        "gb-card",
        {
          staticClass: "glaxis",
          attrs: {
            title: _vm.schema.title,
            mdi: _vm.schema.icon,
            hideHeader: _vm.hideHeader
          }
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _vm.schema.notes
                ? _c("el-tooltip", { attrs: { content: _vm.schema.notes } }, [
                    _c("i", {
                      staticClass:
                        "material-icons-outline md-18 md-article info-notes-icon"
                    })
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.schema.subTitleLabel
                ? _c("div", { staticClass: "subtitle" }, [
                    _c(
                      "div",
                      [
                        _vm.subTitleMatchingText &&
                        _vm.subTitleMatchingText.length > 0
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "red",
                                  "font-size": "12px",
                                  "margin-bottom": "5px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.subTitleMatchingText))]
                            )
                          : _vm._e(),
                        _vm.schema.subTitleType == "select"
                          ? _c("gb-data-rows", {
                              attrs: {
                                schema: _vm.subTitleSchemaFields,
                                data: _vm.dataObject
                              },
                              on: { change: _vm.onChange, input: _vm.onInput }
                            })
                          : _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.schema.subTitleLabel) + " "
                              ),
                              _vm.schema.subTitleTooltip &&
                              _vm.schema.subTitleTooltip.length > 0
                                ? _c("i", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true }
                                      }
                                    ],
                                    staticClass: "el-icon-info t-text-xs",
                                    attrs: { title: _vm.schema.subTitleTooltip }
                                  })
                                : _vm._e()
                            ])
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.schema.subTitleLabel ? _c("el-divider") : _vm._e(),
              _c(
                "div",
                { staticClass: "consumer-data" },
                [
                  _vm.deleteButtonMethod != null
                    ? _c(
                        "el-button",
                        {
                          staticClass: "deleteButton",
                          attrs: {
                            size: "mini",
                            icon: "las la-download",
                            type: "danger",
                            plain: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteButtonMethod(_vm.deleteIndex)
                            }
                          }
                        },
                        [_vm._v(" x ")]
                      )
                    : _vm._e(),
                  _c("gb-data-rows", {
                    attrs: { schema: _vm.schema.fields, data: _vm.dataObject },
                    on: { change: _vm.onChange, input: _vm.onInput }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }