<script>
import { Line, mixins } from 'vue-chartjs'
// import millify from 'millify'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MMM'
              }
            },
            ticks: {
              fontColor: '#999999',
              fontFamily: 'Lato',
              fontSize: 12
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              // callback: (v) => millify(v),
              fontColor: '#999999',
              fontFamily: 'Lato',
              fontSize: 12,
              min: 0,
              suggestedMin: 0
            },
            gridLines: {
              display: false
            }
          }
        ]
      },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      hover: {
        mode: 'nearest',
        intersect: true
      }
    }
  }),
  computed: {
    context() {
      return this.$refs.canvas.getContext('2d')
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
