import gql from 'graphql-tag'
export const KIT_FRAGMENT = gql`
  fragment KitFields on Kit {
    type
    amount
  }
`

export const RECALS_FRAGMENT = gql`
  fragment RecalsFields on Recals {
    dual
    dynamic
    static
  }
`

export const DISCOUNTS_FRAGMENT = gql`
  fragment DiscountsFields on Discounts {
    glass
    moulding
    oem
    tempered
  }
`

export const RATES_FRAGMENT = gql`
  fragment RatesFields on Rates {
    id
    name
    chip_first
    chip_additional
    labor_flat
    labor_hourly
    labor_flat_tempered
    labor_hourly_tempered
    discounts {
      ...DiscountsFields
    }
    standard_kit {
      ...KitFields
    }
    high_kit {
      ...KitFields
    }
    fast_kit {
      ...KitFields
    }
    safelite_recals {
      ...RecalsFields
    }
    lynx_rtl_recals {
      ...RecalsFields
    }
    lynx_dlr_recals {
      ...RecalsFields
    }
  }
  ${DISCOUNTS_FRAGMENT}
  ${RECALS_FRAGMENT}
  ${KIT_FRAGMENT}
`
