var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar",
      class: { expanded: _vm.sidebarOpen },
      attrs: { id: "main-sidebar" }
    },
    [
      _c("side-bar-button", {
        attrs: { image: _vm.logoImg },
        on: {
          click: function($event) {
            _vm.sidebarOpen = !_vm.sidebarOpen
            _vm.sidebarSync()
          }
        }
      }),
      _c("i", {
        staticClass:
          "material-icons-outline md-chevron_right md-18 sidebar-arrow"
      }),
      _c("div", { staticClass: "sidebar-divider" }),
      _vm._l(_vm.buttons, function(button) {
        return _c(
          "side-bar-button",
          _vm._b(
            {
              key: button.label,
              attrs: { expanded: _vm.sidebarOpen },
              on: {
                click: function($event) {
                  return button.click()
                }
              }
            },
            "side-bar-button",
            button,
            false
          )
        )
      }),
      _c("mobile-inbox-dialog", {
        attrs: { parentVisible: _vm.mobileVisible },
        on: {
          "update:parentVisible": function($event) {
            _vm.mobileVisible = $event
          },
          "update:parent-visible": function($event) {
            _vm.mobileVisible = $event
          }
        }
      }),
      !_vm.sidebarOpen && !_vm.$route.meta.hideSidebarArrow
        ? _c(
            "div",
            {
              staticClass: "sidebar-mobile-button",
              on: {
                click: function($event) {
                  _vm.sidebarOpen = true
                }
              }
            },
            [_c("i", { staticClass: "material-icons md-arrow_right" })]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }