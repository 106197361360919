<template>
  <svg
    width="45px"
    height="37px"
    viewBox="0 0 45 37"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
    <title>warehouse-solid</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M30.7125,21.3120282 L8.311875,21.3120282 C8.04375,21.3120282 7.824375,21.5300075 7.824375,21.7964267 L7.81828125,24.7028183 C7.81828125,24.9692375 8.03765625,25.1872169 8.30578125,25.1872169 L30.7125,25.1872169 C30.980625,25.1872169 31.2,24.9692375 31.2,24.7028183 L31.2,21.7964267 C31.2,21.5300075 30.980625,21.3120282 30.7125,21.3120282 Z M30.7125,27.1248113 L8.29359375,27.1248113 C8.02546875,27.1248113 7.80609375,27.3427906 7.80609375,27.6092099 L7.8,30.5156014 C7.8,30.7820206 8.019375,31 8.2875,31 L30.7125,31 C30.980625,31 31.2,30.7820206 31.2,30.5156014 L31.2,27.6092099 C31.2,27.3427906 30.980625,27.1248113 30.7125,27.1248113 Z M30.7125,15.499245 L8.3240625,15.499245 C8.0559375,15.499245 7.8365625,15.7172244 7.8365625,15.9836436 L7.83046875,18.8900352 C7.83046875,19.1564544 8.04984375,19.3744338 8.31796875,19.3744338 L30.7125,19.3744338 C30.980625,19.3744338 31.2,19.1564544 31.2,18.8900352 L31.2,15.9836436 C31.2,15.7172244 30.980625,15.499245 30.7125,15.499245 Z M37.2023437,7.0828195 L20.62125,0.222524432 C19.9014578,-0.0741748108 19.0924485,-0.0741748108 18.3726562,0.222524432 L1.79765625,7.0828195 C0.71296875,7.53694318 0,8.59051012 0,9.7651767 L0,30.5156014 C0,30.7820206 0.219375,31 0.4875,31 L5.3625,31 C5.630625,31 5.85,30.7820206 5.85,30.5156014 L5.85,15.499245 C5.85,14.4335681 6.7396875,13.5616507 7.8365625,13.5616507 L31.1634375,13.5616507 C32.2603125,13.5616507 33.15,14.4335681 33.15,15.499245 L33.15,30.5156014 C33.15,30.7820206 33.369375,31 33.6375,31 L38.5125,31 C38.780625,31 39,30.7820206 39,30.5156014 L39,9.7651767 C39,8.59051012 38.2870312,7.53694318 37.2023437,7.0828195 Z"
        id="path-1"
      ></path>
      <filter
        x="-7.7%"
        y="-9.7%"
        width="120.5%"
        height="126.0%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feMorphology
          radius="0.5"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="1"
          dy="1"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="warehouse-solid"
        transform="translate(2.000000, 2.000000)"
        fill-rule="nonzero"
      >
        <g id="Shape">
          <use
            fill="black"
            fill-opacity="1"
            filter="url(#filter-2)"
            xlink:href="#path-1"
          ></use>
          <use
            stroke="#FFF"
            stroke-width="1"
            :fill="fillColor || '#FF9393'"
            xlink:href="#path-1"
          ></use>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['fillColor'],
  computed: {}
}
</script>
