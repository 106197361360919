var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gb-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "performance-chart",
      attrs: { mdi: "insert_chart", title: "Tickets" }
    },
    [
      _vm.dataSet.user.length
        ? _c(
            "div",
            { staticClass: "performance-chart-title" },
            [
              _vm._v("My User "),
              _c("chart", {
                ref: "chart",
                attrs: { "chart-data": _vm.dataSet.user }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.$role.can("account_manager") && _vm.dataSet.shops.length
        ? _c(
            "div",
            { staticClass: "performance-chart-title" },
            [
              _vm._v("All Shops "),
              _c("chart", {
                ref: "chart",
                attrs: { "chart-data": _vm.dataSet.shops }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }