var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-alert",
    {
      attrs: { variant: _vm.variant, dismissible: "", show: _vm.showAlert },
      on: {
        dismissed: function($event) {
          _vm.showAlert = false
        }
      }
    },
    [
      _c("strong", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" " + _vm._s(_vm.message) + " ")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }