<template>
  <div class="t-h-full t-flex t-flex-col">
    <div class="t-px-6 t-h-14 t-flex-shrink-0 t-border-solid t-border-0
                t-border-b t-border-gray-200 t-flex t-items-center">
      <div class="t-text-base t-text-gray-700 t-flex-grow">
        Browse channels
      </div>
      <span
        class="t-border-solid t-border t-border-gray-300 t-px-3 t-py-1
               t-rounded t-cursor-pointer hover:t-border-blue-500
               hover:t-text-blue-700"
        @click="createChannel"
      >
        <i class="el-icon-plus"></i>
        <span class="t-ml-2">Create Channel</span>
      </span>
    </div>
    <div v-if="loading" v-loading="loading" class="t-h-full"></div>
    <div v-else class="t-px-6 t-overflow-y-auto t-pb-12">
      <div
        v-for="i in publicChannels"
        :key="i.sid"
        class="channel-row t-flex t-items-center t-border-solid t-border-0
               t-border-b t-border-gray-200 t-p-3"
      >
        <div class="t-flex-grow">
          <div class="t-text-gray-700 t-text-base">
            <i v-if="i.isPrivate" class="el-icon-lock"></i>
            <span v-else>#</span>
            <span class="t-ml-1">{{ i.uniqueName }}</span>
          </div>
          <div class="t-flex t-text-gray-600 t-text-xs t-mt-1">
            <div
              v-if="userChannels.find(ch => ch.sid === i.sid)"
              class="t-flex"
            >
              <div class="t-text-green-600">
                <i class="el-icon-check"></i>
                <span class="t-ml-1">Joined</span>
              </div>
              <span class="t-mx-2">•</span>
            </div>
            <div>
              <span>{{ i.membersCount }}</span>
              <span class="t-ml-1">member</span>
              <span v-if="i.membersCount > 1">s</span>
              <span class="t-mx-2">•</span>
            </div>
            <div class="t-flex">
              <div class="t-mr-1">Created by</div>
              <div v-if="i.createdBy === username">you</div>
              <div v-else>{{ i.createdBy }}</div>
            </div>
          </div>
        </div>
        <div class="button-section t-flex-shrink-0 t-pl-8">
          <div v-if="userChannels.find(ch => ch.sid === i.sid && ch.status === 'joined')">
            <div v-if="i.createdBy !== 'system'">
              <div
                v-if="i.createdBy === username"
                class="t-border-solid t-border t-border-red-500 t-bg-red-500
                      t-text-red-100 t-px-3 t-py-1 t-rounded t-cursor-pointer
                      hover:t-border-red-400 hover:t-bg-red-400"
                @click="deleteChannel(i)"
              >
                Delete
              </div>
              <div
                v-else
                class="t-border-solid t-border t-border-red-500 t-bg-red-500
                      t-text-red-100 t-px-3 t-py-1 t-rounded t-cursor-pointer
                      hover:t-border-red-400 hover:t-bg-red-400"
                @click="leaveChannel(i)"
              >
                Leave
              </div>
            </div>
          </div>
          <div
            v-else
            class="t-border-solid t-border t-border-green-500 t-bg-green-500
                  t-text-green-100 t-px-3 t-py-1 t-rounded t-cursor-pointer
                  hover:t-border-green-400 hover:t-bg-green-400"
            @click="joinChannel(i)"
          >
            Join
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChatService } from '@/services/ChatService'
import { chatViews } from '@/constants/chat.config'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  data: () => ({
    publicChannels: null,
    loading: true
  }),
  computed: {
    username: function() {
      return this.$store.state.user.username
    },
    userChannels: function() {
      return this.$store.state.chat.userChannels
    }
  },
  mounted: function() {
    this.getPublicChannels()
  },
  methods: {
    getPublicChannels: async function() {
      this.loading = true
      let paginator = await ChatService.getPublicChannels()
      this.publicChannels = paginator.items
      while (paginator.hasPrevPage) {
        paginator = await paginator.prevPage()
        this.publicChannels.unshift(...paginator.items)
      }
      this.loading = false
    },
    changeView: function(view) {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.changeView,
        view
      )
    },
    openChannel: function(channel) {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.openChannel,
        channel
      )
    },
    createChannel: function() {
      this.changeView(chatViews.create)
    },
    getUserChannels: function() {
      this.$store.dispatch(chatTypes.namespace + chatTypes.actions.getUserChannels)
    },
    joinChannel: async function(channelDescriptor) {
      this.loading = true
      let channel = await channelDescriptor.getChannel()
      channel = await ChatService.joinChannel(channel)
      await this.openChannel(channel)
      this.getUserChannels()
      this.loading = false
    },
    leaveChannel: async function(channelDescriptor) {
      try {
        await this.$messageBox.confirm(
          `Do you wish to leave the channel ${channelDescriptor.uniqueName} ?`,
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'error'
          }
        )
        this.loading = true
        const channel = await channelDescriptor.getChannel()
        await channel.leave()
        this.getUserChannels()
        await this.getPublicChannels()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    deleteChannel: async function(channelDescriptor) {
      try {
        await this.$messageBox.confirm(
          `Do you wish to delete the channel ${channelDescriptor.uniqueName} ?
          Deleting a channel means it cannot be retrieved at a later date for any reason.`,
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'error'
          }
        )
        this.loading = true
        const channel = await channelDescriptor.getChannel()
        await channel.delete()
        this.getUserChannels()
        await this.getPublicChannels()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-section {
    @apply t-hidden;
  }

  .channel-row:hover > .button-section {
    @apply t-block;
  }
</style>
