var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "600px",
        "append-to-body": "",
        visible: _vm.visible,
        "before-close": _vm.close,
        "custom-class": "shopping-cart-dialog"
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-xl t-text-gray-800",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("Checkout")]
      ),
      _c("div", { staticClass: "t-px-10 t-pb-8" }, [
        _c(
          "div",
          [
            _c(
              "el-divider",
              { staticClass: "t-mb-8", attrs: { "content-position": "left" } },
              [
                _c("span", { staticClass: "t-text-lg t-text-blue-700" }, [
                  _c("i", { staticClass: "el-icon-shopping-cart-full t-mr-3" }),
                  _c("span", { staticClass: "t-font-normal" }, [
                    _vm._v("Items")
                  ])
                ])
              ]
            ),
            _vm._l(_vm.items, function(item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass:
                    "t-flex t-rounded-md t-shadow t-px-3 t-py-2 t-mb-3 t-bg-gray-100"
                },
                [
                  _c("div", { staticClass: "t-flex-grow" }, [
                    _c(
                      "div",
                      {
                        staticClass: "t-font-medium t-text-gray-800 t-text-base"
                      },
                      [_vm._v(" " + _vm._s(item.industryCode) + " ")]
                    ),
                    _c("div", [_vm._v(_vm._s(item.plantName))])
                  ]),
                  _c("div", { staticClass: "t-flex t-text-base" }, [
                    _c("div", [_vm._v(_vm._s(item.quantity))]),
                    _c("div", { staticClass: "t-pl-3 t-pr-2" }, [_vm._v("x")]),
                    _c(
                      "div",
                      { staticClass: "t-font-medium t-text-gray-700" },
                      [_vm._v("$" + _vm._s(item.unitPrice))]
                    )
                  ])
                ]
              )
            }),
            _c(
              "div",
              {
                staticClass:
                  "t-flex t-items-end t-justify-end t-mt-4 t-mb-5 t-mr-3"
              },
              [
                _c("span", { staticClass: "t-pr-4 t-text-base" }, [
                  _vm._v("Total:")
                ]),
                _c(
                  "span",
                  { staticClass: "t-text-lg t-font-medium t-text-gray-700" },
                  [_vm._v(" $" + _vm._s(_vm.orderTotal) + " ")]
                )
              ]
            )
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "t-pb-2" },
          [
            _c(
              "el-divider",
              { staticClass: "t-mb-8", attrs: { "content-position": "left" } },
              [
                _c("span", { staticClass: "t-text-lg t-text-blue-700" }, [
                  _c("i", { staticClass: "el-icon-coordinate t-mr-3" }),
                  _c("span", { staticClass: "t-font-normal" }, [
                    _vm._v("Shipping address")
                  ])
                ])
              ]
            ),
            _vm.fetchingAddresses
              ? _c("div", { staticClass: "t-text-2xl t-text-center" }, [
                  _c("i", { staticClass: "el-icon-loading" })
                ])
              : _c(
                  "div",
                  _vm._l(_vm.addresses, function(address, index) {
                    return _c(
                      "div",
                      { key: address.accountNumber },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass:
                              "t-normal-case t-px-3 t-py-2 t-mb-3 t-font-normal t-flex\n                   t-items-center t-rounded-md t-shadow t-bg-gray-100",
                            attrs: {
                              label: index,
                              disabled: _vm.fetchingDeliveryMethods
                            },
                            model: {
                              value: _vm.addressIndex,
                              callback: function($$v) {
                                _vm.addressIndex = $$v
                              },
                              expression: "addressIndex"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "t-mb-1 t-text-gray-800" },
                              [_vm._v(_vm._s(address.name))]
                            ),
                            _c("div", { staticClass: "t-text-gray-600" }, [
                              _vm._v(_vm._s(_vm.formatAddress(address)))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "t-pb-2" },
          [
            _c(
              "el-divider",
              { staticClass: "t-mb-8", attrs: { "content-position": "left" } },
              [
                _c("span", { staticClass: "t-text-lg t-text-blue-700" }, [
                  _c("i", { staticClass: "el-icon-truck t-mr-3" }),
                  _c("span", { staticClass: "t-font-normal" }, [
                    _vm._v("Delivery method")
                  ])
                ])
              ]
            ),
            !_vm.form.addressCode
              ? _c("div", { staticClass: "t-text-center" }, [
                  _vm._v(" Select shipping address first ")
                ])
              : _c("div", [
                  _vm.fetchingDeliveryMethods
                    ? _c("div", { staticClass: "t-text-2xl t-text-center" }, [
                        _c("i", { staticClass: "el-icon-loading" })
                      ])
                    : _c("div", [
                        !_vm.deliveryMethods.length
                          ? _c("div", { staticClass: "t-text-center" }, [
                              _vm._v(" No delivery methods available ")
                            ])
                          : _c(
                              "div",
                              _vm._l(_vm.deliveryMethods, function(
                                deliveryMethod,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        staticClass:
                                          "t-normal-case t-px-3 t-py-2 t-mb-3 t-font-normal t-flex\n                      t-items-center t-rounded-md t-shadow t-bg-gray-100",
                                        attrs: { label: index },
                                        model: {
                                          value: _vm.deliveryMethodIndex,
                                          callback: function($$v) {
                                            _vm.deliveryMethodIndex = $$v
                                          },
                                          expression: "deliveryMethodIndex"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "t-mb-1" }, [
                                          _c(
                                            "span",
                                            { staticClass: "t-text-gray-600" },
                                            [_vm._v("Method:")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "t-ml-2 t-text-gray-800"
                                            },
                                            [
                                              deliveryMethod.selfCollection
                                                ? _c("span", [
                                                    _vm._v("Will Call")
                                                  ])
                                                : _c("span", [
                                                    _vm._v("Delivery")
                                                  ])
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "t-mb-1" }, [
                                          _c(
                                            "span",
                                            { staticClass: "t-text-gray-600" },
                                            [_vm._v("Description:")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "t-ml-2 t-text-gray-800"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    deliveryMethod.shippingConditionDescription
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "t-mb-1" }, [
                                          _c(
                                            "span",
                                            { staticClass: "t-text-gray-600" },
                                            [_vm._v("Date:")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "t-ml-2 t-text-gray-800"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDeliveryDate(
                                                      deliveryMethod.deliveryDttm
                                                    )
                                                  )
                                                )
                                              ]),
                                              deliveryMethod.showTimeOnDelivery
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatDeliveryTime(
                                                            deliveryMethod.deliveryDttm
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ]),
                                        parseFloat(
                                          deliveryMethod.deliveryCharge
                                        ) > 0
                                          ? _c(
                                              "div",
                                              { staticClass: "t-mb-1" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "t-text-gray-600"
                                                  },
                                                  [_vm._v("Delivery charge:")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "t-ml-2 t-text-gray-800"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " $" +
                                                        _vm._s(
                                                          deliveryMethod.deliveryCharge
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        parseFloat(deliveryMethod.vanCharge) > 0
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "t-text-gray-600"
                                                },
                                                [_vm._v("Van charge:")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "t-ml-2 t-text-gray-800"
                                                },
                                                [
                                                  _vm._v(
                                                    " $" +
                                                      _vm._s(
                                                        deliveryMethod.vanCharge
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                      ])
                ])
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-divider",
              { staticClass: "t-mb-8", attrs: { "content-position": "left" } },
              [
                _c("span", { staticClass: "t-text-lg t-text-blue-700" }, [
                  _c("i", { staticClass: "el-icon-chat-line-square t-mr-3" }),
                  _c("span", { staticClass: "t-font-normal" }, [
                    _vm._v("Comments")
                  ])
                ])
              ]
            ),
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 3,
                placeholder: "Enter your comments"
              },
              model: {
                value: _vm.form.comment,
                callback: function($$v) {
                  _vm.$set(_vm.form, "comment", $$v)
                },
                expression: "form.comment"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "t-px-10 t-py-5 t-text-right t-bg-gray-100 t-shadow" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.submitting,
                  expression: "submitting",
                  modifiers: { fullscreen: true, lock: true }
                }
              ],
              staticClass: "t-bg-blue-500 hover:t-bg-blue-400",
              attrs: {
                type: "primary",
                size: "medium",
                disabled: !_vm.form.addressCode || !_vm.form.deliveryCode
              },
              on: { click: _vm.submit }
            },
            [_vm._v(" Place order ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }