var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "input-group mt-1 mt-lg-0" },
      [
        _c("FloatInput", {
          ref: "input",
          attrs: {
            inDisabled: _vm.inDisabled,
            value: _vm.inputValue,
            minimumDecimalPlaces: 2,
            maximumDecimalPlaces: 2,
            inClass: _vm.inInputClass,
            required: _vm.required,
            failMessage: "",
            isGroupInput: true,
            formattedPrefix: _vm.formattedPrefix
          },
          on: {
            "update:value": function($event) {
              _vm.inputValue = $event
            }
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-gray btn-sm dropdown-toggle",
              attrs: {
                disabled: _vm.inDisabled,
                type: "button",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              },
              on: { click: _vm.showDropdown }
            },
            _vm._l(_vm.dropdownOptions, function(dropdownOption, index) {
              return _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.inputDropdownValue === dropdownOption.value ||
                        ((!_vm.inputDropdownValue ||
                          _vm.inputDropdownValue.length === 0) &&
                          index === 0),
                      expression:
                        "inputDropdownValue === dropdownOption.value || ((!inputDropdownValue || inputDropdownValue.length === 0) && index === 0)"
                    }
                  ],
                  key: index
                },
                [_vm._v(_vm._s(dropdownOption.label))]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              ref: "dropdown",
              staticClass: "dropdown-menu dropdown-menu-right dropdown-menu-sm"
            },
            [
              _c(
                "div",
                { staticClass: "px-3" },
                _vm._l(_vm.dropdownOptions, function(dropdownOption, index) {
                  return _c("div", { key: index, staticClass: "form-check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputDropdownValue,
                          expression: "inputDropdownValue"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: { disabled: _vm.inDisabled, type: "radio" },
                      domProps: {
                        value: dropdownOption.value,
                        checked: _vm._q(
                          _vm.inputDropdownValue,
                          dropdownOption.value
                        )
                      },
                      on: {
                        change: function($event) {
                          _vm.inputDropdownValue = dropdownOption.value
                        }
                      }
                    }),
                    _c("label", { staticClass: "form-check-label text-dark" }, [
                      _vm._v(_vm._s(dropdownOption.dropdownLabel))
                    ])
                  ])
                }),
                0
              )
            ]
          )
        ])
      ],
      1
    ),
    _vm.internalBottomText && _vm.internalBottomText.length > 0
      ? _c("div", [_vm._v(" " + _vm._s(_vm.internalBottomText) + " ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }