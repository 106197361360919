<template>
  <el-popover
    trigger="manual"
    v-model="visible"
    @after-enter="focusRef"
    :append-to-body="false"
  >
    <div
      slot="reference"
      @click="visible = true"
      :style="{ cursor: 'pointer' }"
    >
      <slot />
    </div>
    <input-with-label
      ref="input"
      size="mini"
      lazy
      :value="value"
      @input="$emit('input', $event)"
      :label="label"
      v-bind="inputOptions"
      @blur="visible = false"
    />
  </el-popover>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  props: ['value', 'label', 'inputOptions'],
  methods: {
    focusRef() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs?.input?.$refs?.inputRef?.focus()
    }
  }
}
</script>
