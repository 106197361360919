<template>
  <div
    v-loading="itemDeleting"
    class="t-mb-3 t-border t-rounded-md t-shadow-sm t-flex t-border-solid
           t-border-gray-300">
    <div class="t-pl-3 t-pt-1">
      <el-checkbox :value="selected" @change="toggleSelection"/>
    </div>
    <div class="t-flex t-flex-1">
      <div class="t-w-1/2 t-pl-2 t-py-2">
        <div>
          <span class="t-font-medium t-text-gray-700">
            <span v-if="vendor === 'pilkington'">{{ item.industryCode }}</span>
            <span v-else-if="vendor === 'american'">{{ item.itemNumber }}</span>
            <span v-else-if="['agd', 'nm'].includes(vendor)">{{ item.sku }}</span>
            <span v-else>{{ item.fullPartNumber || item.part }}</span>
          </span>
          <span v-if="item.trademark">
            <span class="t-ml-1 t-text-xs">by</span>
            <span class="t-ml-1">{{ item.trademark }}</span>
          </span>
        </div>
        <div v-if="item.jobId" class="t-text-sm">
          Job #
          <a
            :href="'/jobs/' + item.jobId"
            target="_blank"
            class="t-font-medium"
          >
            {{ item.jobId }}
          </a>
        </div>
        <div class="t-text-gray-500 t-text-xs">added by {{ item.username }}</div>
        <div>
          <span
            @click="deleteItem"
            class="t-text-blue-600 t-cursor-pointer hover:t-underline">
            delete
          </span>
        </div>
      </div>
      <div class="t-w-1/2 t-py-2 t-flex t-flex-col">
        <div v-if="vendor !== 'american'" class="t-mb-1">
          <i class="el-icon-location-information t-mr-2 t-text-blue-400"></i>
          <span class="t-text-gray-700">
            <span v-if="vendor === 'pilkington'">{{ item.plantName }}</span>
            <span v-else-if="['agd', 'nm'].includes(vendor)">{{ item.locationName }}</span>
            <span v-else>{{ item.locationDescription }}</span>
          </span>
        </div>
        <div
          v-if="item.deliveryDate && item.deliveryTime">
          <i class="el-icon-truck t-mr-2 t-text-blue-400"></i>
          <span class="t-text-gray-600">{{ delivery }}</span>
        </div>
        <div v-if="item.leadTimeFormatted">
          <i class="el-icon-truck t-mr-2 t-text-blue-400"></i>
          <span class="t-text-gray-600">{{ item.leadTimeFormatted }}</span>
        </div>
      </div>
    </div>
    <div
      class="t-font-bold t-px-3 t-bg-gray-100 t-border-solid t-border-0
             t-border-l-8 t-border-gray-300 t-rounded-r-md t-self-stretch t-flex
             t-items-center">
      <div
        v-loading="quantityUpdating"
        class="t-w-24 t-flex t-justify-end t-items-center">
        <el-select v-model="quantity" @change="updateQuantity">
          <el-option v-for="i in 10" :label="i" :key="i" :value="i"></el-option>
        </el-select>
        <span class="t-pl-3 t-pr-2">x</span>
      </div>
      <div class="t-w-16 t-flex-1 t-text-right">
        <span v-if="['agd', 'nm', 'pilkington'].includes(vendor)">{{ item.unitPrice }}</span>
        <span v-else-if="vendor === 'american'">
          {{ Math.round(item.itemPrice * 100) / 100 }}
        </span>
        <span v-else>{{ item.priceBuyer }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { shoppingCartActions } from '@/store/modules/shopping-cart/types'

export default {
  props: ['item'],
  data: () => ({
    quantity: '',
    quantityUpdating: false,
    itemDeleting: false,
    selected: false
  }),
  watch: {
    selectedItemIds (val) {
      if (!val.includes(this.item.id) && this.selected) {
        this.selected = false
      }
    }
  },
  computed: {
    vendor () {
      return this.$store.state.shoppingCart.items.selectedVendor
    },
    delivery () {
      return moment(this.item.deliveryDate).format('Do MMM') +
        ' ' +
        moment(this.item.deliveryTime, 'HH:mm:ss').format('h:mm a')
    },
    items () {
      return this.$store.state.shoppingCart.items.data
    },
    selectedItemIds () {
      return this.$store.state.shoppingCart.items.selectedItemIds
    }
  },
  created () {
    this.quantity = this.item.quantity
  },
  methods: {
    getItems () {
      return this.$store.dispatch(shoppingCartActions.getItems)
    },
    changeQuantity (payload) {
      return this.$store.dispatch(shoppingCartActions.changeQuantity, payload)
    },
    removeItem (payload) {
      return this.$store.dispatch(shoppingCartActions.deleteItem, payload)
    },
    selectItem () {
      return this.$store.dispatch(shoppingCartActions.selectItem, {
        itemId: this.item.id
      })
    },
    unselectItem () {
      return this.$store.dispatch(shoppingCartActions.unselectItem, {
        itemId: this.item.id
      })
    },
    // selectedAccountNumber: {
    //   get () {
    //     return this.$store.state.shoppingCart.items.selectedAccountNumber
    //   },
    //   set (value) {
    //     return this.$store.commit(
    //       shoppingCartMutations.items.setSelectedAccountNumber,
    //       value
    //     )
    //   }
    // },
    toggleSelection () {
      if (this.selected) {
        this.selected = false
        this.unselectItem()
      } else {
        if (['pilkington', 'mygrant'].includes(this.vendor) && this.selectedItemIds.length) {
          const selectedItems = this.items.filter(({ id }) => this.selectedItemIds.includes(id))
          let sameWarehouse = true

          if (this.vendor === 'pilkington') {
            selectedItems.forEach(({ plantCode }) => {
              if (plantCode !== this.item.plantCode) {
                sameWarehouse = false
              }
            })
          }

          if (this.vendor === 'mygrant') {
            selectedItems.forEach(({ locationCode }) => {
              if (locationCode !== this.item.locationCode) {
                sameWarehouse = false
              }
            })
          }

          if (!sameWarehouse) {
            this.$message.warning('All selected items have to be from the same warehouse.')
            return
          }
        }

        if (this.selectedItemIds.length) {
          const selectedItems = this.items.filter(({ id }) => this.selectedItemIds.includes(id))
          let sameAccount = true

          if (this.vendor === 'pilkington') {
            selectedItems.forEach(({ username }) => {
              if (username !== this.item.username) {
                sameAccount = false
              }
            })
          } else {
            selectedItems.forEach(({ accountNumber }) => {
              if (accountNumber !== this.item.accountNumber) {
                sameAccount = false
              }
            })
          }

          if (!sameAccount) {
            this.$message.warning('You can only select items from the same account.')
            return
          }
        }

        this.selected = true
        this.selectItem()
      }
    },
    async updateQuantity () {
      try {
        this.quantityUpdating = true
        await this.changeQuantity({
          vendor: this.vendor,
          itemId: this.item.itemId,
          quantity: this.quantity
        })
        this.$message.success('Quantity was updated.')
        this.quantityUpdating = false
      } catch (e) {
        this.$message.error('Quantity wasn\'t updated. Please try again.')
        this.getItems()
        this.quantityUpdating = false
      }
    },
    async deleteItem () {
      try {
        this.itemDeleting = true
        await this.removeItem({
          vendor: this.vendor,
          itemId: this.item.itemId
        })
        this.getItems()
        this.itemDeleting = false
        this.$message.success('Item removed.')
      } catch (err) {
        this.$message.error('Item wasn\'t removed. Please try again.')
        this.itemDeleting = false
      }
    }
  }
}
</script>
