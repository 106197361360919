<template>
  <el-dialog
    append-to-body
    top="80px"
    width="1200px"
    custom-class="blue-header-dialog"
    :visible="visible"
    :before-close="handleClose"
  >
    <span slot="title" class="t-text-base t-text-white">
      <i class="el-icon-document" />
      <span class="t-ml-3">Purchase Order details</span>
    </span>

    <LoadingSpinner v-if="fetching" />
    <div
      v-else
      class="t-pt-3 t-pb-8 t-px-8 t-bg-gray-200 drop-wrapper"
      @drop="onDrop"
      @dragleave.stop.prevent="dragEnter"
      @dragenter.stop.prevent="dragLeave"
      :class="{
        'dragging-over': dragging,
      }"
    >
      <div class="t-mb-3 t-flex t-justify-between">
        <div>
          <documents
            :files="dropFiles"
            :type="documentType"
            @clear-files="dropFiles = null"
            :id="data.id"
          />
        </div>
        <div>
          <el-button
            class="min-content"
            size="small"
            type="warning"
            icon="el-icon-edit"
            @click="openUpdateDialog"
          >
            Edit
          </el-button>
          <el-button
            class="min-content"
            icon="el-icon-download"
            type="warning"
            size="small"
            :loading="!!loading"
            @click="getPdf(true)"
          >
            Download
          </el-button>
          <el-button
            class="min-content"
            icon="el-icon-printer"
            type="warning"
            size="small"
            :loading="!!loading"
            @click="getPdf(false)"
          >
            Print
          </el-button>
          <el-dropdown @command="handleCommand" class="t-mx-2">
            <el-button type="primary" size="small">
              Actions<i class="el-icon-arrow-down el-icon--right t-ml-3" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="createReceive">
                Create Receive
              </el-dropdown-item>
              <el-dropdown-item command="createReturn">
                Create Return
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            class="min-content"
            icon="el-icon-delete"
            type="danger"
            size="small"
            @click="remove"
          >
            Delete
          </el-button>
        </div>
      </div>

      <div class="t-bg-white t-rounded t-shadow t-px-8 t-py-3">
        <div class="t-flex t-items-center t-pt-4 t-pb-2">
          <div v-if="shop.logo.length > 0" class="t-w-56 t-mr-8">
            <img
              :src="shop.logo"
              alt="shop logo"
              class="t-w-full"
            />
          </div>
          <div class="t-flex-grow t-text-right">
            <div class="t-text-lg t-font-medium t-text-gray-700 t-mb-2">
              {{ shop.name }}
            </div>
            <div class="t-text-gray-600">{{ shop.address }}</div>
            <div class="t-text-gray-600">
              {{ shop.address_city }},
              {{ shop.address_state }}
              {{ shop.address_zip }}
            </div>
          </div>
        </div>

        <el-divider/>

        <div class="t-text-center t-text-lg t-text-gray-700 t-pb-10">
          Purchase Order <span class="t-ml-2">#{{ data.internalId }}</span>
        </div>
        <div class="t-flex t-pb-8">
          <div class="t-flex-grow">
            <InfoRow :name="'Ref'" :value="data.ref" />
            <InfoRow :name="'Date'" :value="data.date && formatDate(data.date)" />
            <InfoRow :name="'Supplier'" :bold="true" :value="data.supplier.name" />
            <InfoRow
              @click="openAddress"
              :link="true"
              :name="''"
              :value="data.supplier.address"
            />
            <InfoRow :name="''" :value="(data.supplier && data.supplier.phone ? 'p ' + data.supplier.phone + ' ': '') + (data.supplier && data.supplier.fax ? 'f ' + data.supplier.fax : '')"/>
            <InfoRow :name="''" :value="data.supplier.email"/>
            <InfoRow
              :name="'Delivery date'"
              :value="data.deliveryDate && formatDate(data.deliveryDate)"
            />
            <InfoRow :name="'Delivery address'" :value="deliveryAddress" />
            <div class="t-flex t-items-baseline t-mb-2">
              <div class="t-w-48 t-text-right t-text-gray-500 t-text-xs">
                Status
              </div>
              <div class="t-text-gray-700 t-ml-4">
                <el-tag
                  :type="data.isConfirmed ? 'success' : ''"
                  effect="plain"
                  size="mini"
                >
                  {{ data.isConfirmed ? 'Confirmed' : 'Open' }}
                </el-tag>
                <StatusUpdatePopover
                  :poId="id"
                  :isConfirmed="data.isConfirmed"
                  @updated="onUpdated"
                />
              </div>
            </div>
          </div>
          <div
            class="t-bg-blue-400 t-w-56 t-flex t-flex-col t-items-center t-justify-center
              t-text-white t-h-[144px]"
          >
            <div class="t-text-lg">Total amount</div>
            <div class="t-text-3xl">${{ totalAmount }}</div>
          </div>
        </div>
        <Table
          :lines="data.lines"
          :poId="data.id"
          @units-released="onUnitsReleased"
        />
      </div>

      <div class="t-flex t-justify-between t-mt-3 t-text-gray-500 t-italic">
        <div>
          Created on {{ createdAt }} <span v-if="createdBy">by {{ createdBy }}</span>
        </div>
        <div>
          Modified on {{ updatedAt }} <span v-if="updatedBy">by {{ updatedBy }}</span>
        </div>
      </div>

      <UpdateDialog
        :visible.sync="updateDialog.isOpen"
        :id="updateDialog.id"
        :shopId="data.shopId"
        @updated="onUpdated"
      />

      <CreateReceiveDialog
        :visible.sync="createReceiveDialogIsOpen"
        :shopId="shop.id"
        :poId="data.id"
        :poLines="data.lines"
        @created="getData"
      />

      <CreateReturnDialog
        :visible.sync="createReturnDialogIsOpen"
        :poId="data.id"
        :poLines="data.lines"
        @created="getData"
      />

      <address-map-dialog v-model="addressForDialog" />
      <a :href="pdfData" :download="filename" ref="dl" v-show="false" />
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import print from 'print-js'
import Table from './Table.vue'
import InfoRow from './InfoRow.vue'
import UpdateDialog from '../CreateUpdateDialog.vue'
import LoadingSpinner from '@/components/Helpers/LoadingSpinner.vue'
import { getTodaysDate } from '@/scripts/helpers/date.helpers'
import AddressMapDialog from '@/components/modals/AddressMapDialog'
import Documents from '@/components/Documents/Index.vue'
import { documentTypes } from '@/constants/documents.config'
import CreateReceiveDialog from '@/components/WarehousePage/Receives/CreateDialog/Index.vue'
import CreateReturnDialog from '@/components/WarehousePage/Returns/CreateDialog/Index.vue'
import StatusUpdatePopover from '../StatusUpdatePopover.vue'

export default {
  components: {
    Table,
    InfoRow,
    UpdateDialog,
    LoadingSpinner,
    AddressMapDialog,
    Documents,
    CreateReceiveDialog,
    CreateReturnDialog,
    StatusUpdatePopover
  },

  data: () => ({
    fetching: true,
    data: null,
    updateDialog: {
      isOpen: false,
      id: null
    },
    createReceiveDialogIsOpen: false,
    createReturnDialogIsOpen: false,
    addressForDialog: null,
    dataBlob: null,
    pdfData: null,
    dropFiles: null,
    dragging: 0,
    loading: false
  }),

  computed: {
    id() {
      return this.$store.state.warehouse.purchaseOrderDialog.id
    },
    visible() {
      return this.$store.state.warehouse.purchaseOrderDialog.isOpen
    },
    shop() {
      return this.data && this.$store.state.shops.find(i => i.id === this.data.shopId)
    },
    totalAmount() {
      let total = 0
      if (this.data) {
        this.data.lines.forEach(i => {
          total += i.totalCost
        })
      }
      return Math.round(total * 100) / 100
    },
    createdAt() {
      return this.data && moment.utc(this.data.createdAt).local().format('MMM D, YYYY h:mm a')
    },
    createdBy() {
      if (!this.data.createdById || !this.data.createdByName) {
        return null
      }
      return `${this.data.createdByName} (${this.data.createdById})`
    },
    updatedAt() {
      return this.data && moment.utc(this.data.updatedAt).local().format('MMM D, YYYY h:mm a')
    },
    updatedBy() {
      if (!this.data.updatedById || !this.data.updatedByName) {
        return null
      }
      return `${this.data.updatedByName} (${this.data.updatedById})`
    },
    filename() {
      return `Purchase-Order-${this.data?.internalId || ''}-${getTodaysDate()}`
    },
    documentType () {
      return documentTypes.PURCHASE_ORDER
    },
    deliveryAddress() {
      if (this.data.deliveryAddressId === null) {
        return 'Will Call'
      } else {
        let addr = this.data.deliveryAddress
        if (this.data.deliveryUnit !== '') {
          addr += ', ' + this.data.deliveryUnit
        }
        return addr
      }
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.getData()
      }
    }
  },

  mounted() {
    this.$root.$on('receive::updated', this.getData)
    this.$root.$on('receive::removed', this.getData)
    this.$root.$on('return::updated', this.getData)
    this.$root.$on('return::removed', this.getData)
    this.$root.$on('tech-pick-up::updated', this.getData)
    this.$root.$on('tech-pick-up::removed', this.getData)
  },

  beforeDestroy() {
    this.$root.$off('receive::updated', this.getData)
    this.$root.$off('receive::removed', this.getData)
    this.$root.$off('return::updated', this.getData)
    this.$root.$off('return::removed', this.getData)
    this.$root.$off('tech-pick-up::updated', this.getData)
    this.$root.$off('tech-pick-up::removed', this.getData)
  },

  methods: {
    handleClose() {
      this.$store.dispatch('warehouse/closePurchaseOrderDialog')
    },

    formatDate(d) {
      return moment.utc(d).format('ll')
    },

    openUpdateDialog() {
      this.updateDialog.id = this.id
      this.updateDialog.isOpen = true
    },

    onUpdated() {
      this.getData()
      this.$root.$emit('purchase-order::updated')
    },

    async getData() {
      if (this.id) {
        try {
          this.fetching = true
          const { data } = await this.$unum.warehouse().get(`purchase-orders/${this.id}`)
          this.data = data.data
          this.fetching = false
        } catch {
          this.$message.error('Can\'t fetch details')
          this.handleClose()
        }
      }
    },

    onUnitsReleased() {
      this.getData()
      this.$root.$emit('purchase-order::units-released')
    },

    remove() {
      let message = ''
      this.data.lines.forEach(line => {
        if (line.jobs) {
          message = 'Purchase order units that have Jobs attached can\'t be removed. Release those units first.'
        }
        if (line.receives) {
          message = 'Purchase order units that have Receives attached can\'t be removed. Delete those Receives first.'
        }
        if (line.techPickUps) {
          message = 'Purchase order units that have Tech Pickups attached can\'t be removed. Delete those Tech Pickups first.'
        }
      })
      if (message) {
        this.$message({
          type: 'warning',
          duration: 5000,
          message: message
        })
        return
      }

      this.$messageBox.confirm(
        'Are you sure you want to delete this purchase order ?',
        {
          type: 'error',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          callback: () => {},
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              try {
                instance.confirmButtonLoading = true
                await this.$unum.warehouse().delete(`purchase-orders/${this.id}`)
                this.$root.$emit('purchase-order::removed')
                this.handleClose()
                done()
              } catch (err) {
                this.$message.error(err.response?.data.message)
              } finally {
                instance.confirmButtonLoading = false
              }
            } else {
              done()
            }
          }
        }
      )
    },

    async getPdf(download = false) {
      this.loading = true
      const data = await this.$unum.documents().post(
        `warehouse/purchaseorders/${this.id}`,
        {},
        {responseType: 'arraybuffer'}
      )
      this.convertPdfData(data.data)
      if (!download) {
        print({ printable: this.pdfData })
        this.loading = false
      }
      this.$nextTick(() => {
        if (download) {
          this.$refs.dl.click()
          this.loading = false
        }
      })
    },

    convertPdfData(data) {
      this.dataBlob = new Blob([data], { type: 'application/pdf'})
      const URL = window.URL || window.webkitURL
      this.pdfData = URL.createObjectURL(this.dataBlob)
    },

    async openAddress() {
      try {
        const { data } = await this.$unum.vendor().get(`/gb_supplier/g_address/${this.data.supplier.id}`)
        if (data?.address) {
          this.addressForDialog = data.address // this.data.data.supplier.address
        }
      } catch (error) {
        this.$message({ type: 'error', message: 'Error getting customer address data' })
      }
    },

    handleCommand(cmd) {
      if (cmd === 'createReceive') {
        this.createReceiveDialogIsOpen = true
      }
      if (cmd === 'createReturn') {
        this.createReturnDialogIsOpen = true
      }
    },

    dragEnter() {
      this.dragging += 1
    },

    dragLeave() {
      this.dragging -= 1
    },

    onDrop(e) {
      this.dragging = 0
      this.dropFiles = [Array.from(e.dataTransfer.files)[0]]
    }
  }
}
</script>

<style lang="scss">
.drop-wrapper {
  &:after {
    content: "Drop Files to Upload";
    position: absolute;
    top: $padding-xs;
    bottom: $padding-xs;
    left: $padding-xs;
    right: $padding-xs;
    background: transparentize($primary, 0.7);
    border: 3px dashed $primary;
    border-radius: 4px;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 100;
    color: $primary;
  }
  &.dragging-over {
    &:after {
      display: flex;
    }
  }
}
</style>
