const schedulerInitialState = {
  isOpen: false,
  filters: {
    cardView: 0,
    date: new Date(),
    shops: [],
    view: 'list',
    rowView: false,
    warehouses: {
      mygrant: true,
      pilk: true,
      pgw: true,
      igc: true,
      service: true,
      other: true
    },
    solidCards: false,
    weekOffset: 0,
    calendarSort: 'techs'
  },
  activeJobListFilter: {
    label: 'Unscheduled',
    value: 'unscheduled'
  },
  jobs: [],
  techs: [],
  jobDetails: null,
  jobsToUpdate: {},
  geometry: [],
  showUnmapped: false,
  loading: false,
  bounds: null,
  warehouses: [],
  draggingShopId: null,
  editTech: null,
  waypointLoading: false,
  waypoints: [],
  jobDetailsLoading: false,
  count: null,
  appointments: [],
  techLocations: {}
}

export default schedulerInitialState
