<template>
  <div>
    <el-button type="text" @click="fixNumber">Fix bad phone numbers</el-button>
    <i class="el-icon-loading" v-if="loading" />
    <el-alert v-if="result">Fixed {{result}} phone numbers</el-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      result: ''
    }
  },
  methods: {
    async fixNumber() {
      try {
        this.loading = true
        const { data } = await this.$unum.consumer().get('pieces/fix-phone-numbers')
        this.result = data.length
      } catch {}
      this.loading = false
    }
  }
}
</script>
