<template>
  <el-dialog
    append-to-body
    top="80px"
    width="1200px"
    custom-class="green-header-dialog"
    :visible="visible"
    :before-close="handleClose"
  >
    <span slot="title" class="t-text-base t-text-white">
      <i class="el-icon-document" />
      <span class="t-ml-3">Tech Pickup details</span>
    </span>

    <LoadingSpinner v-if="fetching" />
    <div
      v-else
      class="t-pt-3 t-pb-8 t-px-8 t-bg-gray-200 drop-wrapper"
      @drop="onDrop"
      @dragleave.stop.prevent="dragEnter"
      @dragenter.stop.prevent="dragLeave"
      :class="{
        'dragging-over': dragging,
      }"
    >
      <div class="t-mb-3 t-flex t-justify-between">
        <div>
          <documents
            :id="data.id"
            :files="dropFiles"
            :type="documentType"
            @clear-files="dropFiles = null"
          />
        </div>
        <div>
          <el-button
            class="min-content"
            icon="el-icon-download"
            type="warning"
            size="small"
            :loading="!!loading"
            @click="getPdf(true)"
          >
            Download
          </el-button>
          <el-button
            class="min-content"
            icon="el-icon-printer"
            type="warning"
            size="small"
            :loading="!!loading"
            @click="getPdf(false)"
          >
            Print
          </el-button>
          <el-button
            class="min-content"
            icon="el-icon-delete"
            type="danger"
            size="small"
            @click="remove"
          >
            Delete
          </el-button>
        </div>
      </div>

      <div class="t-bg-white t-rounded t-shadow t-px-8 t-py-3">
        <div class="t-flex t-items-center t-pt-4 t-pb-2">
          <div v-if="shop.logo.length > 0" class="t-w-56 t-mr-8">
            <img
              :src="shop.logo"
              alt="shop logo"
              class="t-w-full"
            />
          </div>
          <div class="t-flex-grow t-text-right">
            <div class="t-text-lg t-font-medium t-text-gray-700 t-mb-2">
              {{ shop.name }}
            </div>
            <div class="t-text-gray-600">{{ shop.address }}</div>
            <div class="t-text-gray-600">
              {{ shop.address_city }},
              {{ shop.address_state }}
              {{ shop.address_zip }}
            </div>
          </div>
        </div>

        <el-divider/>

        <div class="t-text-center t-text-lg t-text-gray-700 t-pb-10">
          Tech Pickup <span class="t-ml-2">#{{ data.internalId }}</span>
        </div>

        <div class="t-flex t-pb-8">
          <div class="t-flex-grow">
            <InfoRow :name="'Date picked up'" :value="createdAt" />
            <InfoRow :name="'Picked up by'" :value="data.createdByName" />
          </div>
          <div
            class="t-bg-green-400 t-w-56 t-flex t-flex-col t-items-center
              t-justify-center t-text-white t-py-3 t-h-[144px]"
          >
            <div class="t-text-lg">Quantity picked up</div>
            <div class="t-text-3xl">{{ data.unitsCount }}</div>
          </div>
        </div>
        <div class="t-flex t-pb-8">
          <div class="t-flex-grow">
            <div
              class="t-flex t-border-0 t-border-t t-border-solid t-border-gray-100 t-pt-3 t-mt-4"
            >
              <div class="t-w-48 t-text-right t-text-gray-500 t-text-xs t-pt-[3px] t-flex-shrink-0">
                {{data.notes ? "Notes" : ""}}
              </div>
              <div class="t-ml-4 t-flex-grow">
                <div
                  v-for="i in data.notes"
                  :key="i.id"
                  class="t-mb-1 t-pb-2"
                >
                  <div class="t-text-gray-700">{{ i.note }}</div>
                  <div class="t-flex t-text-gray-400 t-text-xs">
                    <div>{{ formatDate(i.createdAt) }},</div>
                    <div class="t-ml-2">{{ i.createdByName }}</div>
                  </div>
                </div>
                <div class="t-mb-1 t-pb-2 t-text-blue-500 t-cursor-pointer t-italic"
                  :loading="addingNote"
                  @click="addNote"
                >
                  + Add Note
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          :lines="data.lines"
          :organizationId="shop.organization_id"
          @remove-line="removeLine"
        />
      </div>

      <div class="t-flex t-justify-between t-mt-3 t-text-gray-500 t-italic">
        <div>Created on {{ createdAt }} by {{ createdBy }}</div>
        <div>Modified on {{ updatedAt }} by {{ updatedBy }}</div>
      </div>

    </div>

    <a :href="this.pdfdata" :download="filename" ref="dl" v-show="false" />
  </el-dialog>
</template>

<script>
import moment from 'moment'
import Table from './Table.vue'
import InfoRow from './InfoRow.vue'
import LoadingSpinner from '@/components/Helpers/LoadingSpinner.vue'
import { getTodaysDate } from '@/scripts/helpers/date.helpers'
import Documents from '@/components/Documents/Index.vue'
import { documentTypes } from '@/constants/documents.config'
import print from 'print-js'

export default {
  components: {
    Table,
    InfoRow,
    LoadingSpinner,
    Documents
  },

  data: () => ({
    fetching: true,
    data: null,
    addingNote: false,
    dataBlob: null,
    pdfdata: null,
    dropFiles: null,
    dragging: 0,
    loading: false
  }),

  computed: {
    id() {
      return this.$store.state.warehouse.techPickUpDialog.id
    },
    visible() {
      return this.$store.state.warehouse.techPickUpDialog.isOpen
    },
    shop() {
      return this.data && this.$store.state.shops.find(i => i.id === this.data.shopId)
    },
    createdAt() {
      return this.data && moment.utc(this.data.createdAt).local().format('MMM D, YYYY h:mm a')
    },
    createdBy() {
      return this.data && `${this.data.createdByName} (${this.data.createdById})`
    },
    updatedAt() {
      return this.data && moment.utc(this.data.updatedAt).local().format('MMM D, YYYY h:mm a')
    },
    updatedBy() {
      return this.data && `${this.data.updatedByName} (${this.data.updatedById})`
    },
    filename() {
      return this.data && `Tech-pickup-${this.data.internalId}-${getTodaysDate()}`
    },
    documentType () {
      return documentTypes.TECH_PICK_UP
    }
  },

  mounted() {
    this.$root.$on('purchase-order::units-released', this.onUpdated)
  },

  beforeDestroy() {
    this.$root.$off('purchase-order::units-released', this.onUpdated)
  },

  watch: {
    visible(val) {
      if (val) {
        this.getData()
      }
    }
  },

  methods: {
    handleClose() {
      this.$store.dispatch('warehouse/closeTechPickUpDialog')
    },

    formatDate(date) {
      return moment.utc(date).format('lll')
    },

    async getData() {
      if (this.id) {
        try {
          this.fetching = true
          const { data } = await this.$unum.warehouse().get(`tech-pick-ups/${this.id}`)
          this.data = data.data
          this.fetching = false
        } catch {
          this.$message.error('Can\'t fetch details')
          this.handleClose()
        }
      }
    },

    onUpdated() {
      this.getData()
      this.$root.$emit('tech-pick-up::updated')
    },

    remove() {
      this.$messageBox.confirm(
        'Are you sure you want to delete this tech pick up ?',
        {
          type: 'error',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          callback: () => {},
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              try {
                instance.confirmButtonLoading = true
                await this.$unum.warehouse().delete(`tech-pick-ups/${this.id}`)
                this.$root.$emit('tech-pick-up::removed')
                this.handleClose()
                done()
              } catch (err) {
                this.$message.error(err.response?.data.message)
              } finally {
                instance.confirmButtonLoading = false
              }
            } else {
              done()
            }
          }
        }
      )
    },

    async removeLine(partId) {
      try {
        if (this.data.lines.length > 1) {
          await this.$unum.warehouse().delete(`tech-pick-ups/${this.id}/parts/${partId}`)
          this.onUpdated()
        } else {
          await this.$messageBox.confirm(
            `There is only one part left. If you remove it the whole Teck Pickup will be removed.
              Do you wish to continue?`,
            'Last part',
            {
              confirmButtonText: 'Yes',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }
          )
          this.remove()
        }
      } catch (err) {
        if (err !== 'cancel') {
          this.$message.error(err.response.data.message)
        }
      }
    },

    async addNote() {
      try {
        const { value } = await this.$messageBox.prompt('', 'Add note', {
          confirmButtonText: 'Add',
          cancelButtonText: 'Cancel',
          inputPlaceholder: 'Please enter a note',
          inputType: 'textarea',
          inputValidator(val) {
            if (!val) {
              return 'Note is required'
            }
            return true
          }
        })
        this.addingNote = true
        await this.$unum.warehouse().post(`tech-pick-ups/${this.id}/notes`, { note: value })
        this.getData()
      } catch (err) {
        if (err !== 'cancel') {
          this.$message.error(err.response.data.message)
        }
      } finally {
        this.addingNote = false
      }
    },

    async getPdf(download = false) {
      this.loading = true
      const data = await this.$unum.documents().post(`warehouse/tech-pickups/${this.id}`,
        {},
        {responseType: 'arraybuffer'})
      this.convertPdfData(data.data)
      if (!download) {
        print({ printable: this.pdfdata })
        this.loading = false
      }
      this.$nextTick(() => {
        if (download) {
          this.$refs.dl.click()
          this.loading = false
        }
      })
    },

    convertPdfData(data) {
      this.dataBlob = new Blob([data], { type: 'application/pdf'})
      const URL = window.URL || window.webkitURL
      this.pdfdata = URL.createObjectURL(this.dataBlob)
    },

    dragEnter() {
      this.dragging += 1
    },

    dragLeave() {
      this.dragging -= 1
    },

    onDrop(e) {
      this.dragging = 0
      this.dropFiles = [Array.from(e.dataTransfer.files)[0]]
    }
  }
}
</script>

<style lang="scss" scoped>
  .drop-wrapper {
    &:after {
      content: "Drop Files to Upload";
      position: absolute;
      top: $padding-xs;
      bottom: $padding-xs;
      left: $padding-xs;
      right: $padding-xs;
      background: transparentize($primary, 0.7);
      border: 3px dashed $primary;
      border-radius: 4px;
      display: none;
      justify-content: center;
      align-items: center;
      z-index: 100;
      color: $primary;
    }
    &.dragging-over {
      &:after {
        display: flex;
      }
    }
  }
</style>
