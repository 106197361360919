var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      attrs: {
        stripe: "",
        size: "small",
        "row-class-name": "t-cursor-pointer",
        "highlight-current-row": "",
        data: _vm.data
      },
      on: { "row-click": _vm.selected }
    },
    [
      _c("el-table-column", {
        attrs: { prop: "searchLabel" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c("i", {
                  staticClass: "fa fa-circle",
                  style: {
                    color: _vm.vendorColorMapping[props.row.type] || "#B103FC"
                  }
                }),
                _vm._v(" " + _vm._s(props.row.searchLabel) + " ")
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }