<template>
  <div style="margin-left: 140px">
    <el-button
      v-if="!formVisible"
      @click="toggleForm"
      type="primary"
      size="mini"
      icon="el-icon-plus"
      plain
      round>
      Manually update the status
    </el-button>
    <el-form
      v-if="formVisible"
      inline
      ref="form"
      :model="form"
      :rules="rules"
      :hide-required-asterisk="true"
      size="small">
      <el-form-item prop="statusId" class="mb-0">
        <el-select
          placeholder="Select status"
          v-model="form.statusId">
          <el-option
            v-for="item in statuses"
            :label="item.name"
            :key="item.id"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="mb-0">
        <el-button
          @click="handleSubmit"
          icon="el-icon-plus"
          :type="form.statusId !== '' ? 'primary' : 'default'"
          :disabled="form.statusId === ''"
          :loading="submitting">
          Update
        </el-button>
        <el-button
          @click="toggleForm"
          icon="el-icon-close">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { call, get } from 'vuex-pathify'
import Job from '@/scripts/objects/job'
import actions from '@/store/modules/scheduler_v2/types'
import actionsV3 from '@/store/modules/scheduler_v3/types'

export default {
  props: ['formVisible'],
  data: () => ({
    form: {
      statusId: ''
    },
    rules: {
      statusId: { required: true, message: 'Please select a status', trigger: 'change' }
    },
    statuses: [
      { id: 2, name: 'Confirmed' },
      { id: 3, name: 'Declined' }
    ],
    submitting: false
  }),
  watch: {
    formVisible: function (value) {
      if (!value) {
        this.form.statusId = ''
      }
    }
  },
  computed: {
    jobId: get('twilio/textMessages/jobConfirmation/jobId')
  },
  methods: {
    getJobConfirmationHistory: call('twilio/textMessages/jobConfirmation/getHistory'),
    addStatusToConfirmationHistory: call(actions.addStatusToConfirmationHistory),
    updateScheduler: call(actionsV3.getSchedulerData),
    updateSchedulerJobDetails: call(actionsV3.getJobDetails),
    toggleForm () {
      this.$emit('toggleForm')
    },
    async handleSubmit () {
      this.submitting = true
      try {
        if (await this.$refs.form.validate()) {
          const status = await Job.updateConfirmationStatus(this.jobId, this.form)
          await this.getJobConfirmationHistory(this.jobId)
          this.addStatusToConfirmationHistory(status)
          this.toggleForm()

          if (this.$store.state.scheduler?.isOpen) {
            this.updateScheduler()
          }

          if (this.jobId && this.$store.state.sch_v3?.jobDetails?.id === this.jobId) {
            this.updateSchedulerJobDetails(this.jobId)
          }

          this.submitting = false
        }
      } catch {
        this.submitting = false
      }
    }
  }
}
</script>
