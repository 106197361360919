<template>
  <div class="scheduler-tech-location-infowindow">
    <el-card :body-style="{ padding: '10px' }">
      <span>
        {{ username }}
      </span>
    </el-card>
  </div>
</template>

<script>
import { getInfowindowClass } from '@/scripts/helpers/map.helpers'
export default {
  data() {
    return {
      overlayView: null
    }
  },
  props: ['map', 'location', 'username'],
  mounted() {
    const InfoWindow = getInfowindowClass()
    const location = {
      lat: +this.location.latitude,
      lng: +this.location.longitude
    }
    this.overlayView = new InfoWindow(this.map, location, this.$el)
  },
  destroyed() {
    this.overlayView.setMap(null)
  }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerTechLocationInfowindow.scss';
</style>
