<template>
  <div class="consumer-tags">
    <gb-tag :small="small" disabled v-if='status === "disabled"' color='red'>Disabled</gb-tag>
    <gb-tag :small="small" disabled v-if="is_insurance" style="color: #6366f1"> Ins </gb-tag>
    <gb-tag :small="small" disabled v-else-if="is_commercial" style="color: #eab308"> Com </gb-tag>
    <gb-tag :small="small" disabled v-else-if="guest" color="purple"> Guest </gb-tag>
    <gb-tag :small="small" disabled v-else style="color: #06b6d4"> Retail </gb-tag>
    <gb-tag :small="small" disabled v-if="tax_exempt"> Exempt </gb-tag>
    <gb-tag :small="small" disabled v-if="po_required" color="sky-blue"> PO Required </gb-tag>
    <gb-tag :small="small" disabled v-if="flagged" color="dark"> Flagged </gb-tag>
  </div>
</template>

<script>
export default {
  props: {
    is_commercial: [Boolean, Number],
    is_insurance: [Boolean, Number],
    tax_exempt: [Boolean, Number],
    po_required: [Boolean, Number],
    flagged: [Boolean, Number],
    small: [Boolean, Number],
    guest: [Boolean, Number],
    status: [String]
  }
}
</script>

<style lang="scss" scoped>
.consumer-tags {
  display: flex;
  flex-shrink: 1;
  overflow: hidden;
  > .gb-tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .disabled-consumer-tag {
    font-size: large;
  }
}
</style>
