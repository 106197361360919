<template>
  <div class='form-group col mt-2'>
    <PdfOptionHeader />
    <div class='pdf-options-body'>
      <div class='options-table'>
      <PdfOptionCardList
        v-for='column in columns'
        :key='column.id'
        :dragoverColumn="dragoverColumn"
        :optionItems='optionsList[column.col]'
        :column='column.col'
        />
      </div>
    </div>
  </div>
</template>

<script>
import PdfOptionCardList from './PdfOptionCardList'
import PdfOptionHeader from './PdfOptionHeader'

export default {
  props: ['options'],
  data () {
    return {
      dragoverColumn: null,
      columns: [
        {id: 0, col: 1},
        {id: 1, col: 2},
        {id: 2, col: 3}
      ]
    }
  },
  components: {
    PdfOptionCardList,
    PdfOptionHeader
  },
  mounted() {
    this.$root.$on('column-drag-over', id => {
      this.dragoverColumn = id
    })
  },
  computed: {
    optionsList () {
      const arr = {}
      for (var j = 0; j < this.columns.length; j++) {
        arr[this.columns[j].col] = []
      }
      if (this.options) {
        for (var i = 0; i < this.options.length; i++) {
          if (this.options[i].col) {
            arr[this.options[i].col].push(this.options[i])
          }
        }
      }
      // let's sort these
      // const keys = Object.keys(arr)
      // console.log(keys)
      // for (var j = 0; j < keys.length; j++) {
      //   console.log(arr[keys[j]])
      //   arr[keys[j]].sort((a,b) => a.row - b.row)
      //   console.log(arr[keys[j]])
      // }
      return arr
    }
  },
  methods: {
    children (column) {
      const arr = []
      for (var i = 0; i < this.options.length; i++) {
        if (this.options[i].col == column) arr.push(this.options[i])
      }
      return arr.sort((a,b)  => a.row > b.row)
    }
  }
}
</script>

<style lang='scss'>
@import './styles/PdfOption.scss'
</style>