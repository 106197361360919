<template>
  <transition
    v-on:before-leave="beforeLeave"
    v-on:after-leave="afterLeave"
    v-on:leave="leave"
    v-on:before-enter="beforeEnter"
    v-on:after-enter="afterEnter"
    v-on:enter="enter"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  methods: {
    beforeLeave(el) {
      if (!el.dataset) el.dataset = {}
      el.dataset.oldPaddingTop = el.style.paddingTop
      el.dataset.oldPaddingBottom = el.style.paddingBottom
      el.dataset.oldOverflow = el.style.overflow

      el.style.height = el.scrollHeight + 'px'
      el.style.overflow = 'hidden'
    },
    leave(el) {
      if (el.scrollHeight !== 0) {
        el.classList.add('collapse-top-transition')
        el.style.height = 0
        el.style.paddingTop = 0
        el.style.paddingBottom = 0
      }
    },
    afterLeave(el) {
      el.classList.remove('collapse-top-transition')
      el.style.height = ''
      el.style.overflow = el.dataset.oldOverflow
      el.style.paddingTop = el.dataset.oldPaddingTop
      el.style.paddingBottom = el.dataset.oldPaddingBottom
    },
    beforeEnter(el) {
      el.classList.add('collapse-top-transition')
      if (!el.dataset) el.dataset = {}

      el.dataset.oldPaddingTop = el.style.paddingTop
      el.dataset.oldPaddingBottom = el.style.paddingBottom

      el.style.height = '0'
      el.style.paddingTop = 0
      el.style.paddingBottom = 0
    },
    enter(el) {
      if (el.closest('.collapse-parent')) {
        el.dataset.oldParentHeight = el.closest(
          '.collapse-parent'
        ).style.height
        el.closest('.collapse-parent').style.height = 'auto'
      }
      el.dataset.oldOverflow = el.style.overflow
      if (el.scrollHeight !== 0) {
        el.style.height = el.scrollHeight + 'px'
        el.style.paddingTop = el.dataset.oldPaddingTop
        el.style.paddingBottom = el.dataset.oldPaddingBottom
      } else {
        el.style.height = ''
        el.style.paddingTop = el.dataset.oldPaddingTop
        el.style.paddingBottom = el.dataset.oldPaddingBottom
      }

      el.style.overflow = 'hidden'
    },
    afterEnter(el) {
      if (el.closest('.collapse-parent')) {
        el.closest('.collapse-parent').style.height = ''
      }
      el.classList.remove('collapse-top-transition')
      el.style.height = ''
      el.style.overflow = el.dataset.oldOverflow
    }
  }
}
</script>
<style lang="scss">
.collapse-top-transition {
  transition: height 0.2s ease-in-out, padding 0.2s ease-in-out;
}
</style>
