var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "500px",
        visible: _vm.localVisible,
        title: "New Device",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.localVisible = $event
        },
        closed: function($event) {
          return _vm.$emit("closed")
        },
        close: _vm.reset
      }
    },
    [
      _vm.code
        ? _c("div", [
            _c("div", { staticClass: "t-break-normal" }, [
              _vm._v(
                ' Please sign out of the device, then select "Sign in" on the device, then select "Use a device code." Enter the code below to sign in. '
              )
            ]),
            _c("div", { staticClass: "t-text-3xl t-text-center t-pt-3" }, [
              _vm._v(_vm._s(_vm.code))
            ]),
            _c(
              "div",
              {
                staticClass: "t-break-normal",
                staticStyle: { "margin-top": "15px" }
              },
              [
                _vm._v(
                  ' Once you\'ve signed in with this code, your device has been created in your Square account. Next, we need to connect it to Glassbiller. To do that, close this dialog, select your newly created device from the dropdown on the left, click "Add", and follow the instructions provided. '
                )
              ]
            )
          ])
        : _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "newDeviceCodeForm",
              attrs: {
                model: _vm.newDeviceCodeForm,
                rules: _vm.rules,
                size: "small"
              }
            },
            [
              _c("el-row", [
                _c("div", { staticClass: "t-break-normal" }, [
                  _vm._v(
                    ' First, we need to create this device in your Square account. To do that, please enter a name for your new device and click "Create." '
                  )
                ])
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "15px" },
                      attrs: { prop: "name" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "Enter a name for your new device"
                        },
                        model: {
                          value: _vm.newDeviceCodeForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.newDeviceCodeForm, "name", $$v)
                          },
                          expression: "newDeviceCodeForm.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.createNewDeviceCode }
                        },
                        [_vm._v("Create")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }