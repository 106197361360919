<template>
  <ul class="" style="width:100%" v-loading="loading">
    <template v-for="(suggestion, index) in value">
      <li :key='index'
        :ref="'autoComplete:' + index"
        class="autocomplete-result"
        v-on:mouseover="mouseOver(index)"
        v-bind:class="{'is-active': isActive(index)}"
        @mousedown="suggestionClick(index)">
        <a href="javascript:void(0)" style="text-decoration: none;" v-bind:class="{'white-text': isActive(index)}">
          <div :class="{ 'label-commercial-customer': suggestion.is_commercial, 'label-individual-customer': !suggestion.is_commercial}">
            <div :class="{ 'suggestion-info-block': true, strong: true }">
              <span v-if="suggestion.is_commercial">{{suggestion.company_name}} Contact ({{suggestion.full_name}})</span>
              <span v-else>{{suggestion.full_name}}</span>
            </div>
            <div class="secondary">
              Small stuff
            </div>
          </div>
        </a>
      </li>
    </template>
  </ul>
</template>

<script>

export default {
  props: ['value', 'loading'],
  data () {
    return {
      current: 0
    }
  },
  methods: {
    mouseOver: function (index) {
      this.current = index
    },
    isActive (index) {
      return index === this.current
    },
    suggestionClick (index) {
      this.$emit('selected', this.value[index].id, 'consumer')
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "GlobalSearch.scss";
</style>
