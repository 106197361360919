import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import Shop from '@/scripts/objects/shop'
import Roleuser from '@/scripts/objects/roleuser'
import moment from 'moment-timezone'

export default class User extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  admin = 0
  username = ''
  password = ''
  modified = -1
  setup_token = null
  password_reset_token = null
  reset_password_token = null
  account_id = -1
  charge = '0'
  notes = ''
  nags_user = 0
  anniversary_date = null
  reported_date = null
  shop_id = -1
  faxnumber_id = null
  color = ''
  jobmanagerstate_id = null
  account_manager = 0
  most_recent_warehouse_shop_id = -1
  scheduler_combine_techs = null
  replyto_email_cache = ''
  scheduler_job_list_view = null
  default_redesign = 0
  allow_concurrent_logins = 0
  email = ''
  email_verification_code = ''
  scheduler_selected_shops = []
  default_jobslist_view_id = -1
  has_unread_text_message = 0
  warehouse_group_by_cache = 1
  warehouse_is_parts_required_cache = 0
  warehouse_shop_id_cache = 0
  warehouse_hide_adhesive_cache = 0
  has_access_to_twilio_phone_numbers = false
  verification_finished = false
  verification_sent = false
  nags_license = []
  auto_redo_nags_search = 0
  recent_job_count = 10
  sidebar_open = 1
  timezone
  nags_price_increase_acknowledged = null
  terms_and_conditions_acknowledged = null
  cc_replyto = 0
  // additional needed for user dialog
  shops = []
  shopUser = []
  csrdefaults = []
  _accountName = ''
  legacy_nags = false
  show_nags_substitutes = false

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getUserAndShopsWithCookieToken (callbackFunction, errorCallbackFunction = null) {
    Api({ url: '/api/user' }).then(res => {
      if (res.data && res.data.user && res.data.user.username && res.data.user.username.length > 0) {
        if (!res.data.user.timezone || res.data.user.timezone.length === 0) {
          var timeZone = moment.tz.guess()
          if (timeZone && timeZone.length > 0) {
            User.updateUserTimezone(timeZone)
            res.data.user.timezone = timeZone
          }
        }

        var shops = []
        if (res.data.shops) {
          for (var i = 0; i < res.data.shops.length; i++) {
            shops.push(new Shop(res.data.shops[i]))
          }
        }
        var organizations = res.data.organizations
        var roles = []
        if (res.data.roles) {
          for (var j = 0; j < res.data.roles.length; j++) {
            roles.push(new Roleuser(res.data.roles[j]))
          }
        }
        if (res.data.csrdefaults) {
          res.data.user.csrdefaults = res.data.csrdefaults
        }

        const nmShopToInvite = res?.data?.nmShopToInvite || null

        callbackFunction(new User(res.data.user), shops, organizations, roles, nmShopToInvite)
      } else {
        if (errorCallbackFunction) {
          errorCallbackFunction('invalid user')
        }
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static async getUserData (id) {
    const res = await Api({ url: `/api/user/${id}` })
    // console.log(res)
    if (res.data && res.data.user && res.data.user.username && res.data.user.username.length > 0) {
      var shops = []
      if (res.data.shops) {
        for (var i = 0; i < res.data.shops.length; i++) {
          var shopToAdd = new Shop(res.data.shops[i])
          shops.push(shopToAdd)
        }
      }

      if (res.data.csrdefaults) {
        res.data.user.csrdefaults = res.data.csrdefaults
      }
      return {user: new User(res.data.user), shops}
    } else {
      return 'invalid user'
    }
  }

  static saveUserInternal (dataToSave, component, callbackFunction, errorCallbackFunction = null) {
    component.$root.$emit('userUpdateSaving', (true))
    component.$root.$emit('userUpdateSavingTitle', ('Saving User ...'))
    // var dataToSave = {}
    // dataToSave['user'] = this
    // dataToSave['csrDefaults'] = csrDefaults
    // dataToSave['permissions'] = userPermissions
    // dataToSave['overwrite'] = overwrite
    // dataToSave['faxnumbers'] = faxnumbers
    // dataToSave['attachedShops'] = attachedShops
    Api.post('/api/user', dataToSave).then(res => {
      callbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static updateUserEmailAddress (email, successCallback, errorCallback = null) {
    Api.post('/api/user/updateEmailAddress?email=' + encodeURIComponent(email)).then(res => {
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  static checkUsername (userId, usernameToCheck, callbackFunction, errorCallbackFunction = null) {
    Api({ url: `/api/usernamecheck/${usernameToCheck}/${userId}` }).then(res => {
      callbackFunction(res.data)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static checkEmail (userId, emailToCheck, callbackFunction, errorCallbackFunction = null) {
    Api({ url: `/api/emailcheck/${encodeURIComponent(emailToCheck)}/${encodeURIComponent(userId)}` }).then(res => {
      callbackFunction(res.data)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static resendEmailVerification (successCallback, errorCallback = null) {
    Api.post('/api/user/resendEmailVerification').then(res => {
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  static submitEmailVerification (verificationCode, successCallback, errorCallback = null) {
    Api.post('/api/user/submitEmailVerification?verificationCode=' + encodeURIComponent(verificationCode)).then(res => {
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  static resetVerifiedEmail (userId, callbackFunction, errorCallbackFunction = null) {
    Api.post(`/api/user/${userId}/emailreset`).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static updateDefaultJobslistView (default_jobslist_view_id) {
    Api.post('/api/user/updateDefaultJobslistView?jobslistViewId=' + encodeURIComponent(default_jobslist_view_id)).then(res => {
    }, function (error) {
      console.log('error: ', error)
    })
  }

  static updateUserTimezone (timezone) {
    Api.post('/api/user/updateUserTimezone?timezone=' + encodeURIComponent(timezone)).then(res => {
    }, function (error) {
      console.log('error: ', error)
    })
  }

  static updateUserTermsAndConditions (acknowledged) {
    Api.post('/api/user/updateUserTermsAndConditions?acknowledged='+encodeURIComponent(acknowledged)).then(res => {
      }, function (error) {
        console.log('error: ', error)
      })
  }

  static getUserOptions (userSearchValue, filter, callbackFunction, errorCallbackFunction = null) {
    Api({url: '/api/user_options?userSearchValue=' + encodeURIComponent(userSearchValue) + '&filter=' + encodeURIComponent(filter)}).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static async getShopIdsWithActiveSquare () {
    const { data } = await Api.get('/api/user/getShopIdsWithActiveSquare')
    return data
  }

  static async getShopIdsWithActiveTwilio () {
    const { data } = await Api.get('/api/user/getShopIdsWithActiveTwilio')
    return data
  }

  static async hasUnreadTextMessage (data) {
    await Api.post('/api/user/has-unread-text-message', data)
  }

  static async setDefaultMessagingPhoneNumber (id) {
    await Api.post('/api/user/setDefaultMessagingPhoneNumber', { id: id })
  }

  static async getRelatedTwilioPhoneNumbers (userId) {
    const { data } = await Api.get(`/api/user/${userId}/getRelatedTwilioPhoneNumbers`)
    return data
  }

  static async getSelectedTwilioPhoneNumbers (userId) {
    const { data } = await Api.get(`/api/user/${userId}/getSelectedTwilioPhoneNumbers`)
    return data
  }

  static updateAuth0Scripts (callbackFunction, errorCallbackFunction = null) {
    Api.post('/api/update_auth0_scripts').then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static async getAuditLogsByUser (userId, loadPosition) {
    const { data } = await Api.get(`api/user/${userId}/logs`, {params: {loadPosition}})
    return data
  }

  static async updateReportPermissions (dataToSave) {
    const { data } = await Api.post('/api/user/updateReportPermissions', dataToSave)
    return data
  }

  static async updateDatatabPermissions (dataToSave) {
    const { data } = await Api.post('/api/user/updateDatatabPermissions', dataToSave)
    return data
  }

  static async updateNotificationSettingPermissions (dataToSave) {
    const { data } = await Api.post('/api/user/updateNotificationSettingPermissions', dataToSave)
    return data
  }
}
