// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".select-dropdown-with-new {\n  min-height: 40px;\n  overflow: hidden;\n  /* padding-bottom: 39px; */\n}\n.select-dropdown-with-new ul {\n  margin-bottom: 39px;\n  padding-bottom: 0;\n}\n.select-dropdown-with-new .show-disabled {\n  position: relative;\n  text-align: right;\n  font-size: x-small;\n  font-style: italic;\n  padding: 0 10px 5px 0;\n  cursor: pointer;\n}\n.select-dropdown-with-new .disabled-option ::after {\n  color: red;\n  content: \" (disabled)\";\n}", "",{"version":3,"sources":["/app/src/components/Helpers/InputWithLabel/SelectWrapper/index.vue"],"names":[],"mappings":"AAwMA;EACE,gBAAA;EACA,gBAAA;EAKA,0BAAA;AAhMF;AA4LE;EACE,mBAAA;EACA,iBAAA;AA1LJ;AA6LE;EACE,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;AA3LJ;AA8LI;EACE,UAAA;EACA,sBAAA;AA5LN","file":"index.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.select-dropdown-with-new {\n  min-height: 40px;\n  overflow: hidden;\n  /* padding-bottom: 39px; */\n}\n.select-dropdown-with-new ul {\n  margin-bottom: 39px;\n  padding-bottom: 0;\n}\n.select-dropdown-with-new .show-disabled {\n  position: relative;\n  text-align: right;\n  font-size: x-small;\n  font-style: italic;\n  padding: 0 10px 5px 0;\n  cursor: pointer;\n}\n.select-dropdown-with-new .disabled-option ::after {\n  color: red;\n  content: \" (disabled)\";\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
