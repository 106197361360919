var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.title && _vm.title.length > 0
      ? _c("label", { attrs: { for: "title" } }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _c("div", { staticClass: "form-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue"
          }
        ],
        ref: "input",
        class: _vm.inClass,
        style: _vm.inStyle,
        attrs: {
          type: "text",
          maxlength: _vm.maxlength,
          placeholder: _vm.placeholder,
          title: _vm.title,
          disabled: _vm.inDisabled
        },
        domProps: { value: _vm.inputValue },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.inputValue = $event.target.value
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }