const isDev = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging'

let secure;
if (process.env.VUE_APP_SECURE !== undefined) {
    secure = process.env.VUE_APP_SECURE !== 'false';
} else {
    secure = window.location.protocol == 'https:'
}

let host = process.env.VUE_APP_API_HOST || window.location.host;

const protocol = secure ? 'https' : 'http';
const uri = `${protocol}://${host}`;

const wsProtocol = secure ? 'wss' : 'ws';
const wsUri = `${wsProtocol}://${host}`;

export default {
    isDev,

    host,
    uri,
    wsUri,

    googleAnalyticsID: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    
    pusher: {
        key: process.env.VUE_APP_PUSHER_KEY,
        cluster: process.env.VUE_APP_PUSHER_CLUSTER
    },

    oauth: {
        tenant: process.env.VUE_APP_FB_TENANT_ID
    },

    clover: {
        clientId: process.env.VUE_APP_CLOVER_CLIENT_ID,
        serverUrl: process.env.VUE_APP_CLOVER_SERVER_URL,
    }
}
