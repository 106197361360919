<template>
  <div
    class="consumer-type-select"
    @mouseenter="hover = true"
    @mouseleave="
      hover = false;
      firstType = selectedType;
    "
  >
    <gb-tag
      v-for="type in types"
      :key="type.key"
      :color="type.color"
      :hidden="!hover && selectedType !== type.key"
      :outline="selectedType !== type.key"
      @click.native="selectedType = type.key"
    >
      {{ type.key }}
    </gb-tag>
    <gb-tag
      v-for="toggle in toggles"
      :key="toggle.key"
      :hidden="!hover && hiddenCheck(toggle.key)"
      :outline="hiddenCheck(toggle.key)"
      :color="toggle.color"
      @click.native="toggleValue(toggle.key)"
    >
      {{ toggle.label }}
    </gb-tag>
  </div>
</template>

<script>
import * as R from 'ramda'

export default {
  data() {
    return {
      hover: false,
      firstType: null,
      toggles: [
        {
          color: 'red',
          label: 'Disabled',
          key: 'status'
        },
        {
          label: 'Exempt',
          key: 'tax_exempt'
        },
        {
          label: 'PO Required',
          key: 'po_required',
          color: 'sky-blue'
        },
        {
          label: 'Flagged',
          key: 'flagged',
          color: 'dark'
        }
      ]
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    selectedType: {
      get() {
        return R.cond([
          [R.prop('is_commercial'), () => 'Comm'],
          [R.prop('is_insurance'), () => 'Ins'],
          [R.T, () => 'Cash']
        ])(this.value)
      },
      set(key) {
        this.$emit('input', {
          ...this.value,
          is_commercial: +(key === 'Comm'),
          is_insurance: +(key === 'Ins'),
          auto_add_to_fleet: +(key !== 'Comm')
        })
      }
    },
    types () {
      // const returnVal = []
      if (this.value?.is_insurance) {
        return [{ color: 'blue', key: 'Ins' }]
      } else if (this.value?.is_commercial) {
        return [
          {
            color: 'green',
            key: 'Cash'
          },
          {
            color: 'orange',
            key: 'Comm'
          }
        ]
      } else {
        return [
          {
            color: 'green',
            key: 'Cash'
          },
          {
            color: 'orange',
            key: 'Comm'
          }
        ]
      }
    }
  },
  mounted() {
    this.firstType = this.selectedType
  },
  methods: {
    toggleValue(key) {
      let valueToSet = +!this.value[key]
      if (key === 'status') {
        valueToSet = this.value[key] === 'disabled' ? 'active' : 'disabled' 
      }
      this.$emit('input', { ...this.value, [key]: valueToSet })
    },
    hiddenCheck(key) {
      if (key === 'status') {
        return this.value[key] !== 'disabled'
      }
      return !this.value[key]
    }
  }
}
</script>

<style lang="scss" scoped>
.consumer-type-select {
  display: flex;
  flex-grow: 1;
}
</style>
