import { gql } from 'apollo-boost'
import { ADDRESS_FRAGMENT } from './address.fragment'
import { RATES_FRAGMENT } from './rates.fragment'

const SHIP_TO_FRAGMENT = gql`
  fragment ShipToFields on Job {
    scheduled_date
    scheduled_time_start
    scheduled_time_end
    install_major_street1
    install_major_street2
    install_address_id
    contact_phone1
    contact_name
    customer_cancel
    customer_reschedule
    pending_schedule
    technician_notes
    salesrep_notes
    install_notes
    install_context
    customer_quote_message
    customer_workorder_message
    customer_invoice_message
    install_location_id
    bay_id
    install_time_span_id
  }
`

const BILL_TO_FRAGMENT = gql`
  fragment BillToFields on Job {
    loss_date
    dispatch_date
    scheduled_date
    referral_number
    policy_number
    install_date
    pending_inspection
    pending_offline
    rates_id
    display_rates_id
    deductible
    requisition_order_number
    purchase_order_number
    cause_of_loss_id
    dot_number
    lot_number
    stock_number
    insurance_fleet_tax_exempt
    insurance_fleet_auto_add_adhesive
    insurance_fleet_po_required
    invoice_due_date
    selected_invoice_tier
    referral_match
  }
`

const JOB_TOTALS_FRAGMENT = gql`
  fragment TotalsFields on Job {
    total_list_price
    total_materials
    total_hours
    total_labor
    total_subtotal
    total_cost
    total_materials_taxes
    total_labor_taxes
    total_taxes
    total_after_taxes
    total_after_deductible
    total_margin
    total_sale_source_commission
    total_sales_rep_commission
    total_tech_commission
    total_location_commission
    total_csr_commission
    total_commission
    total_balance_after_payments
    total_deductible_after_payments
    total_balance_adjustable_payments
    customer_rebate
    other_costs
  }
`
export const EDIQUEUE_FRAGMENT = gql`
  fragment EdiqueueFields on Ediqueue {
    edi_creation_date_time
    edi_id
    glaxis_id
    job_edi_message
    job_id
    remit_received
    response_date_time
    service
    status
    submit_date_time
  }
`

export const WARRANTIES_FRAGMENT = gql`
  fragment WarrantiesFields on Warranties {
    id
    status
  }
`

export const CALL_BACKS_FRAGMENT = gql`
  fragment CallBacksFields on CallBacks {
    id
    status
  }
`

export const R_AND_IS_FRAGMENT = gql`
  fragment RAndIsFields on RAndIs {
    id
    status
  }
`

export const RECAL_JOBS_FRAGMENT = gql`
  fragment RecalJobsFields on RecalJobs {
    id
    status
  }
`

export const TINTS_FRAGMENT = gql`
  fragment TintsFields on Tints {
    id
    status
  }
`

export const SUPPLEMENTS_FRAGMENT = gql`
  fragment SupplementsFields on Supplements {
    id
    status
  }
`

export const BASE_JOB_FRAGMENT = gql`
  fragment BaseJobFields on BaseJob {
    id
    status
  }
`

export const PICK_UPS_FRAGMENT = gql`
  fragment PickUpsFields on PickUps {
    id
    status
  }
`

export const DROP_OFFS_FRAGMENT = gql`
  fragment DropOffsFields on DropOffs {
    id
    status
  }
`

export const GB_ROUTE_ENTRY_FRAGMENT = gql`
  fragment GbRouteEntryFields on GbRouteEntry {
    arrival
    duration
  }
`

export const JOB_DETAILS_FRAGMENT = gql`
  fragment JobDetailsFragment on Job {
    id
    created
    modified
    consumer_id
    bill_to_id
    work_order_date
    quote_date
    invoice_date
    warranty_date
    warranty_complete_date
    recal_date
    recal_complete_date
    call_back_date
    call_back_complete_date
    r_and_i_date
    r_and_i_complete_date
    tint_date
    tint_complete_date
    pick_up_date
    pick_up_complete_date
    drop_off_date
    drop_off_complete_date
    void_date
    credit_memo_date
    follow_up_date
    vehicle_id
    base_job
    internal_number
    ...ShipToFields
    ...BillToFields
    shop_id
    vehicle_mileage
    vehicle_plate_number
    unit_number
    parts_on_backorder
    status
    salesidejob_id
    edi_locked
    techside_sent
    is_supplemental
    install_g_address {
      ...AddressFields
    }
    rates {
      ...RatesFields
    }
    selected_price_level
    ...TotalsFields
    ediqueue {
      ...EdiqueueFields
    }
    from_draft_id
    right_part_requests {
      help_request_status
      invalid_vin
      right_part {
        part_number
        dealer_part_nums
      }
    }
    warranties {
      ...WarrantiesFields
    }
    call_backs {
      ...CallBacksFields
    }
    r_and_is {
      ...RAndIsFields
    }
    recal_jobs {
      ...RecalJobsFields
    }
    tints {
      ...TintsFields
    }
    supplements {
      ...SupplementsFields
    }
    pick_ups {
      ...PickUpsFields
    }
    drop_offs {
      ...DropOffsFields
    }
    base_job_obj {
      ...BaseJobFields
    }
    text_message_confirmation_history {
      id
      user_id
      status_id
      created_at
      status_name
      username
    }
    confirmation_date_time
    gb_route_entry {
      ...GbRouteEntryFields
    }
  }
  ${ADDRESS_FRAGMENT}
  ${BILL_TO_FRAGMENT}
  ${SHIP_TO_FRAGMENT}
  ${RATES_FRAGMENT}
  ${JOB_TOTALS_FRAGMENT}
  ${EDIQUEUE_FRAGMENT}
  ${WARRANTIES_FRAGMENT}
  ${CALL_BACKS_FRAGMENT}
  ${R_AND_IS_FRAGMENT}
  ${RECAL_JOBS_FRAGMENT}
  ${TINTS_FRAGMENT}
  ${SUPPLEMENTS_FRAGMENT}
  ${PICK_UPS_FRAGMENT}
  ${DROP_OFFS_FRAGMENT}
  ${BASE_JOB_FRAGMENT}
  ${GB_ROUTE_ENTRY_FRAGMENT}
`
