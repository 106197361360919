var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gb-card",
    { attrs: { title: "My Tickets" } },
    [
      _vm.$role.can("account_manager")
        ? _c(
            "template",
            { staticStyle: { "margin-left": "10px" }, slot: "title" },
            [
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "20px" },
                  model: {
                    value: _vm.showShopHistory,
                    callback: function($$v) {
                      _vm.showShopHistory = $$v
                    },
                    expression: "showShopHistory"
                  }
                },
                [_vm._v("All for Shop")]
              )
            ],
            1
          )
        : _vm._e(),
      _c("ticket-table", { attrs: { data: _vm.data, loading: _vm.loading } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }