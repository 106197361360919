<template>
  <div
    class="pdf-type-select t-flex t-justify-between t-flex-wrap"
    :class="{ 't-flex-col': wrap, 't-items-end': end, 't-items-center': !end }"
  >
    <el-radio-group
      v-if="template == null"
      :value="copy"
      @input="$emit('update:copy', $event)"
      class="t-mr-2"
    >
      <el-radio label="withPrices" :style="style">With Prices</el-radio>
      <el-radio label="noPrices" :style="style">No Prices</el-radio>
      <el-radio label="customerCopy" :style="style">Customer Copy</el-radio>
      <el-radio label="customerWithPrices" :style="style">Customer with prices</el-radio>
      <el-radio label="insuranceCopy" :style="style">Insurance Copy</el-radio>
    </el-radio-group>
    <el-row type="flex" :gutter="5">
      <el-col :span="pdfDefault !== copy ? 18 : 24">
        <el-select
          v-if="template == 'select'"
          placeholder="PDF type"
          v-model="selectedCopy"
          @input="$emit('update:copy', $event)"
          size="mini"
        >
          <el-option
            v-for="item in options"
            :label="pdfDefault === item.id ? `${item.name} (Default)` : item.name"
            :key="item.id"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" v-if="template === 'select' && pdfDefault !== copy && userHasPermission && allowSetDefault">
        <el-button
          type="text"
          size="mini"
          @click="makeDefault(selectedCopy)"
        >
          Make default
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    copy: String,
    wrap: Boolean,
    end: Boolean,
    template: String,
    allowSetDefault: Boolean,
    userShopPdfDefault: String
  },
  data() {
    return {
      options: [
        { id: 'withPrices', name: 'With Prices'},
        { id: 'noPrices', name: 'No Prices'},
        { id: 'customerCopy', name: 'Customer Copy'},
        { id: 'customerWithPrices', name: 'Customer with prices'},
        { id: 'insuranceCopy', name: 'Insurance Copy'}
      ],
      pdfDefault: this.userShopPdfDefault ? this.userShopPdfDefault : this.$job.shop.pdf_default
    }
  },
  computed: {
    style() {
      return this.wrap
        ? { display: 'block', marginRight: 0, marginBottom: '6px' }
        : { display: 'inline-block' }
    },
    userHasPermission () {
      return this.$role.can('admin') || this.$role.can('account_manager')
    },
    selectedCopy: {
      get () {
        return this.copy
      },
      set (val) {
        this.$emit('update:copy', val)
      }
    }
  },
  methods: {
    async makeDefault(version) {
      const obj = { pdf_default: version }
      const { data } = await this.$unum.job().post(`shop/${this.$job.job.shop_id}/pdf_default`, obj)
      if (data.response === 'success') {
        this.$message({ type: 'success', message: 'Shop default updated' })
        this.pdfDefault = version
      } else {
        this.$message({ type: 'error', message: 'Could not update shop default' })
      }
    }
  }
}
</script>
