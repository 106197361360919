<template>
  <div class="t-flex">
    <div class="t-flex-grow">
      <div class="t-text-gray-400 t-mt-2">Fax</div>

      <el-form
        :model="form"
        ref="form"
        class="t-space-y-6"
        @submit.prevent.native
      >
        <div
          v-for="(i, index) in form.faxes"
          :key="index"
          class="t-flex t-items-center t-space-x-3 first:t-mt-6"
        >
          <el-form-item
            :prop="'faxes.' + index + '.number'"
            :rules="{
              validator: faxValidator,
              trigger: 'blur'
            }"
          >
            <el-input
              v-model="i.number"
              v-mask="'(###) ###-####'"
              placeholder="(999) 999-9999"
              @change="setFax(index, $event)"
            />
          </el-form-item>

          <el-tooltip content="Remove Fax Number" placement="top">
            <i
              class="material-icons-outline md-cancel t-text-base t-text-red-400 t-cursor-pointer"
              @click="deleteFaxHandler(index)"
            />
          </el-tooltip>

          <el-tooltip
            v-if="i.id"
            :content="
              i.id === data.fax_id
                ? 'Primary Fax'
                : 'Set as Primary Fax'
            "
            placement="top"
          >
            <i
              :class="
                i.id === data.fax_id
                  ? 'md-radio_button_checked t-text-blue-500'
                  : 'md-radio_button_unchecked t-text-gray-400'
              "
              class="material-icons-outline t-text-base t-cursor-pointer"
              @click="setPrimaryFaxHandler(i.id)"
            />
          </el-tooltip>
        </div>
      </el-form>
    </div>

    <div class="t-flex t-flex-col t-justify-end">
      <el-button
        plain
        icon="el-icon-circle-plus-outline"
        @click="addFax"
      >
        Add
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { mapActions, mapState } from 'pinia'
import parsePhoneNumber from 'libphonenumber-js'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  data() {
    return {
      form: {
        faxes: []
      }
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data'])
  },

  mounted() {
    this.form.faxes = cloneDeep(this.data.faxes)
    this.$nextTick(async () => {
      try {
        await this.$refs.form.validate()
      } catch {
      }
    })
  },

  methods: {
    ...mapActions(
      useVendorStore,
      ['createFax', 'updateFax', 'deleteFax', 'setPrimaryFax']
    ),

    faxValidator(rule, value, callback) {
      const phone = parsePhoneNumber(value, 'US')
      if (!phone.isValid()) {
        callback(new Error('Invalid fax number'))
      } else {
        callback()
      }
    },

    addFax() {
      this.form.faxes.push({
        id: null,
        number: ''
      })
    },

    setFax(faxIndex, number) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const fax = this.form.faxes[faxIndex]
          try {
            if (fax.id) {
              await this.updateFax(fax.id, number)
            } else {
              const faxId = await this.createFax(number)

              this.form.faxes[faxIndex].id = faxId

              if (this.form.faxes.length === 1) {
                await this.setPrimaryFaxHandler(faxId)
              }
            }
          } catch (e) {
            this.$message.error(e.message)
          }
        }
      })
    },

    async deleteFaxHandler(faxIndex) {
      const fax = this.form.faxes[faxIndex]
      if (fax.id) {
        try {
          await this.deleteFax(fax.id)
        } catch (e) {
          this.$message.error(e.message)
        }
      }

      Vue.delete(this.form.faxes, faxIndex)
    },

    async setPrimaryFaxHandler(id) {
      try {
        await this.setPrimaryFax(id)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>
