var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "29",
        height: "37",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              x: "-13%",
              y: "-9.7%",
              width: "134.8%",
              height: "127%",
              filterUnits: "objectBoundingBox",
              id: "a"
            }
          },
          [
            _c("feMorphology", {
              attrs: {
                radius: ".5",
                operator: "dilate",
                in: "SourceAlpha",
                result: "shadowSpreadOuter1"
              }
            }),
            _c("feOffset", {
              attrs: {
                dx: "1",
                dy: "1",
                in: "shadowSpreadOuter1",
                result: "shadowOffsetOuter1"
              }
            }),
            _c("feGaussianBlur", {
              attrs: {
                stdDeviation: "1",
                in: "shadowOffsetOuter1",
                result: "shadowBlurOuter1"
              }
            }),
            _c("feComposite", {
              attrs: {
                in: "shadowBlurOuter1",
                in2: "SourceAlpha",
                operator: "out",
                result: "shadowBlurOuter1"
              }
            }),
            _c("feColorMatrix", {
              attrs: {
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0",
                in: "shadowBlurOuter1"
              }
            })
          ],
          1
        ),
        _c("path", {
          attrs: {
            d:
              "M0 31V2.906C0 1.301 1.287 0 2.875 0h17.25C21.713 0 23 1.301 23 2.906V31l-11.5-6.781L0 31z",
            id: "b"
          }
        })
      ]),
      _c(
        "g",
        {
          attrs: {
            transform: "translate(2 2)",
            "fill-rule": "nonzero",
            fill: "none"
          }
        },
        [
          _c("use", {
            attrs: { fill: "#000", filter: "url(#a)", "xlink:href": "#b" }
          }),
          _c("use", {
            attrs: {
              stroke: "#3B240F",
              fill: _vm.fillColor || "#FF9393",
              "xlink:href": "#b"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }