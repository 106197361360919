export const reportPermissionsConfigSchema = [
  {
    type: 'sales',
    label: 'Sales Reports',
    options: [
      {label: 'Sales', value: 'reportsales'},
      {label: 'Margin', value: 'reportmargin'},
      {label: 'Customer Summary', value: 'reportcustomersummary'},
      {label: 'Royalty Summary', value: 'reportroyaltysummary'},
    ]
  },
  {
    type: 'taxes',
    label: 'Sales Tax Reports',
    options: [
      {label: 'Tax Detail', value: 'reporttaxdetail'},
      {label: 'Tax Summary', value: 'reporttaxsummary'},
      {label: 'Cash Basis Detail', value: 'reportdetail'},
      {label: 'Cash Basis Summary', value: 'reportsummary'}
    ]
  },
  {
    type: 'schedule',
    label: 'Schedule Reports',
    options: [
      {label: 'Schedule', value: 'reportschedule'},
      {label: 'Manifest', value: 'reportmanifest'}
    ]
  },
  {
    type: 'install',
    label: 'Install Reports',
    options: [
      {label: 'Installers', value: 'reportinstallers'},
      {label: 'Sales Reps', value: 'reportsalesreps'},
      {label: 'Sales Sources', value: 'reportsalesources'},
      {label: 'Locations', value: 'reportlocations'},
      {label: 'Tech Collections', value: 'reporttechcollections'},
      {label: 'Customer Service Reps', value: 'reportcsrs'}
    ]
  },
  {
    type: 'payment',
    label: 'Payment Reports',
    options: [
      {label: 'Payment Receipt', value: 'reportreceipt'}
    ]
  },
  {
    type: 'statement',
    label: 'Statement Reports',
    options: [
      {label: 'Account Statement', value: 'reportaccountstatement'},
      {label: 'Open Invoice', value: 'reportopeninvoice'},
      {label: 'Aging', value: 'reportaging'}
    ]
  },
  {
    type: 'accounting',
    label: 'Accounting Reports',
    options: [
      {label: 'Profit and Loss', value: 'reportprofitandloss'},
      {label: 'Balance Sheet', value: 'reportbalancesheet'}
    ]
  },
  {
    type: 'miscellaneous',
    label: 'Miscellaneous Reports',
    options: [
      {label: 'Sales Rep/Sales Source', value: 'reportsalesrepsource'},
      {label: 'Right Part', value: 'reportrequests'},
      {label: 'Install List', value: 'reportinstalllist'}
    ]
  }
]

export const reportPermissionIds = {
  reportsales: 30,
  reportmargin: 31,
  reporttaxdetail: 32,
  reporttaxsummary: 33,
  reportdetail: 34,
  reportsummary: 35,
  reportschedule: 36,
  reportmanifest: 37,
  reportinstallers: 38,
  reportsalesreps: 39,
  reportsalesources: 40,
  reportlocations: 41,
  reporttechcollections: 42,
  reportcsrs: 43,
  reportreceipt: 44,
  reportaccountstatement: 45,
  reportopeninvoice: 46,
  reportaging: 47,
  reportprofitandloss: 48,
  reportbalancesheet: 49,
  reportsalesrepsource: 50,
  reportrequests: 51,
  reportcustomersummary: 52,
  reportinstalllist: 73,
  reportroyaltysummary: 74
}
