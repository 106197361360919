<template>
  <b-modal no-close-on-backdrop no-close-on-esc hide-header-close
    @hidden="dialogDisappeared"
    @show="dialogAboutToAppear"
    v-bind:title="'Manually Override EDI Status'"
    :id="modalId"
    header-bg-variant='primary'
    header-text-variant='light'>
    <div class="row">
      <div class="form-group col-12">
          <label for="ediStatusDropdown">Manually set or adjust the EDI status for this job:</label>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-6">
          <select :disabled="buttonsDisabled" v-model="status" id="ediStatusDropdown" class="form-control form-control-sm">
            <option value='Unsubmitted'>Unsubmitted</option>
            <option value='Sent'>Sent</option>
            <option value='Paid'>PAID</option>
            <option value='Accepted'>ACCEPTED</option>
            <option value='Rejected'>REJECTED</option>
            <option value='Unknown'>UNKNOWN</option>
          </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label for="ediStatusMessage">Message (optional):</label>
        <textarea :disabled="buttonsDisabled" v-model="message" class="form-control form-control-sm col-md-12" id="ediStatusMessage"></textarea>
      </div>
    </div>

    <div slot="modal-footer" style="margin-top:0px;" class='w-100'>
      <div class='float-right'>
        <button :disabled="buttonsDisabled" type='button' size='lg' class='btn btn-primary' @click="setButtonPressed" variant="primary">Set</button>
        &nbsp;&nbsp;
        <button :disabled="buttonsDisabled" type='button' size='lg' class='btn btn-primary' @click="cancelButtonPressed" variant="primary">Cancel</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Ediqueue from '@/scripts/objects/ediqueue'

export default {
  props: ['modalId', 'job_id', 'model_to_return_to'],
  data () {
    return {
      status: 'Unsubmitted',
      message: '',
      buttonsDisabled: false
    }
  },
  methods: {
    dialogAboutToAppear: function () {
      this.status = 'Unsubmitted'
      this.message = ''
      this.buttonsDisabled = false
    },
    dialogDisappeared: function () {
      this.$store.dispatch('updateUserEdiRejectedJobCount')
      this.$root.$emit('bv::show::modal', this.model_to_return_to)
    },
    setButtonPressed: function (event) {
      if (!this.buttonsDisabled) {
        let self = this
        this.buttonsDisabled = true
        Ediqueue.updateJobEdiqueue(this.job_id, this.status, this.message, function (status, message) {
          self.$root.$emit('updateEdiqueue', self.job_id, status, message)
          self.$root.$emit('bv::hide::modal', self.modalId)
          self.buttonsDisabled = false
        }, function () {
          self.buttonsDisabled = false
        })
      }
    },
    cancelButtonPressed: function (event) {
      if (!this.buttonsDisabled) {
        event.preventDefault()
        this.$root.$emit('bv::hide::modal', this.modalId)
      }
    }
  }
}
</script>

<style>

</style>
