<template>
  <el-dialog
    append-to-body
    top="80px"
    width="1200px"
    custom-class="base-profile-dialog"
    :visible="visible"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <Sidebar />

    <div v-loading="fetching" class="t-flex-grow t-min-w-0">
      <Header />

      <Body />
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import Header from '@/components/Vendors/Profile/Header.vue'
import Sidebar from '@/components/Vendors/Profile/Sidebar.vue'
import Body from '@/components/Vendors/Profile/Body.vue'

export default {
  components: {
    Body,
    Header,
    Sidebar
  },

  computed: {
    ...mapState(useVendorStore, ['id', 'fetching', 'visible', 'saving'])
  },

  watch: {
    id: async function (val) {
      if (val) {
        try {
          await this.fetch()
          await this.getVendorTechsideConnection()
        } catch (err) {
          this.$message.error(err.message)
        }
      }
    }
  },

  methods: {
    ...mapActions(useVendorStore, ['fetch', 'close', 'getVendorTechsideConnection'])
  }
}
</script>

<style lang="scss">
.el-dialog.base-profile-dialog {
  @apply t-font-sans t-rounded-xl;
  .el-dialog__header {
    @apply t-hidden;
  }

  .el-dialog__body {
    @apply t-flex t-p-0 t-overflow-y-hidden
  }

  .el-form-item {
    @apply t-mb-0;
  }

  .el-form-item__content {
    @apply t-leading-none;
  }
}
</style>
