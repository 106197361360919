var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    { staticClass: "notifications-scrollbar", attrs: { always: "" } },
    [
      _c(
        "transition-group",
        {
          staticClass: "list-unstyled list-notifications",
          attrs: {
            tag: "ul",
            name: "list-notifications",
            "enter-active-class": "animate__animated animate__fadeIn",
            "leave-active-class": "animate__animated animate__backOutUp"
          }
        },
        _vm._l(_vm.rows, function(item) {
          return _c(
            "li",
            { key: item.id, staticClass: "notification-item" },
            [_c("notification-wrapper", { attrs: { item: item } })],
            1
          )
        }),
        0
      ),
      _c(
        "infinite-loading",
        { ref: "loader_" + _vm.type, on: { infinite: _vm.loadItems } },
        [
          _c(
            "div",
            { attrs: { slot: "no-more" }, slot: "no-more" },
            [_vm._t("no-more")],
            2
          ),
          _c(
            "div",
            { attrs: { slot: "no-results" }, slot: "no-results" },
            [_vm._t("no-results")],
            2
          ),
          _c("div", { attrs: { slot: "spinner" }, slot: "spinner" }, [
            _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: true,
                  expression: "true"
                }
              ]
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }