export const phonePattern = /\(\d{3}\) \d{3}-\d{4}/
export const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const multiEmailValidator = (rule, value, callback) => {
  if (value) {
    for (var i = 0; i < value.length; i++) {
      if (!value[i].match(emailPattern)) {
        callback(new Error(rule.message))
        return
      }
    }
    callback()
    return
  } else {
    callback()
    return
  }
}
export const addressValidator = (rule, value) =>
  !value || !!value.google_place_id
