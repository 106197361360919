<template>
  <base-layout :data="data">
    <div slot="title">Job Received</div>

    <div>
      SaleSIDE job <a href="javascript:void(0)" @click="openMobileInbox">{{data.details.salesidejobId}}</a> has been added to your mobile inbox.
    </div>
  </base-layout>

</template>

<script>
import BaseLayout from './_BaseLayout'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  methods: {
    openMobileInbox () {
      if (this.data?.details?.salesidejobId) {
        this.$root.$emit('showMobileJob', this.data.details.salesidejobId, true)
      } else {
        this.$root.$emit('openMobileInboxDialog')
      }
    }
  },

  components: { BaseLayout }
}
</script>
