var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-button-list accordion accordianSidebar" },
    _vm._l(_vm.options, function(category, index) {
      return _c("div", { key: index, staticClass: "panel list-group" }, [
        _c(
          "a",
          {
            staticClass: "list-group-item primary",
            attrs: { href: "#", "data-toggle": "collapse" },
            on: {
              click: function($event) {
                return _vm.collapseMenu(index)
              }
            }
          },
          [
            _c("span", [_vm._v(_vm._s(index))]),
            _vm.selectedTitle &&
            _vm.selectedTitle.length > 0 &&
            _vm.isChildSelected(index)
              ? _c("span", { staticClass: "selectedIndicator" }, [
                  _vm._v(_vm._s(_vm.selectedTitle))
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass: "panel list-group collapse",
            attrs: { id: "categoryContainer-" + index.replace(/\s+/g, "") }
          },
          _vm._l(category.subs, function(option) {
            return _c(
              "a",
              {
                key: option.value,
                staticClass: "list-group-item secondary",
                class: { active: option.value === _vm.selected },
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.selectItem(option)
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(option.text) + " "),
                option.text === "Accounting Packages"
                  ? _c(
                      "span",
                      {
                        class: {
                          badge: true,
                          "badge-primary": option.value !== _vm.selected,
                          "badge-secondary": option.value === _vm.selected
                        }
                      },
                      [_vm._v("Beta!")]
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }