var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.open,
        "append-to-body": "",
        "custom-class": "gb-el-dialog file-preview-modal",
        title: "Preview - " + _vm.label,
        top: "75px"
      },
      on: {
        "update:visible": function($event) {
          _vm.open = $event
        },
        open: _vm.getFile,
        closed: function($event) {
          _vm.type = null
          _vm.size = 0
        }
      }
    },
    [
      _vm.doc
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "container"
            },
            [
              _vm.isImage
                ? _c(
                    "div",
                    { staticClass: "image" },
                    [_c("el-image", { attrs: { src: _vm.doc.url } })],
                    1
                  )
                : _vm.doc.url
                ? _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _vm.isAttachment
                        ? _c("object", { attrs: { data: _vm.doc.url } })
                        : _vm.error
                        ? _c("empty-placeholder", {
                            attrs: { text: "File is missing", icon: "file" }
                          })
                        : _c("span", [_vm._v(" Preview not available ")])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "details" },
                [
                  _c(
                    "file-details-table",
                    _vm._b(
                      {
                        attrs: {
                          type: _vm.type,
                          size: _vm.size,
                          dateFunc: _vm.dateFunc
                        }
                      },
                      "file-details-table",
                      _vm.doc,
                      false
                    )
                  ),
                  _c("file-preview-actions", {
                    attrs: {
                      doc: _vm.doc,
                      noDelete: _vm.noDelete,
                      gcp: _vm.gcp,
                      deleteFunc: _vm.deleteFunc
                    },
                    on: {
                      close: function($event) {
                        return _vm.$emit("input", false)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }