import Api from '@/services/Api'

const getWithBalances = async ({
  shopId,
  dateFrom,
  dateTo,
  type,
  name,
  page,
  sortBy,
  sortOrder
}) => {
  const { data } = await Api.get('/api/arap/consumersBalances', {
    params: { shopId, dateFrom, dateTo, type, name, page, sortBy, sortOrder }
  })
  return data
}

const getInvoices = async ({
  consumerId,
  tradingPartner,
  showRelatedTroughTradingPartner,
  shopId,
  dateFrom,
  dateTo,
  type,
  page
}) => {
  const { data } = await Api.get('/api/arap/consumerInvoices', {
    params: {
      consumerId,
      tradingPartner,
      showRelatedTroughTradingPartner,
      shopId,
      dateFrom,
      dateTo,
      type,
      page
    }
  })
  return data
}

const getByIds = async ({ shopId, consumerIds, jobId, fromJob }) => {
  const { data } = await Api.get('/api/arap/consumers', {
    params: { shopId, consumerIds, jobId, fromJob }
  })
  return data
}

const getAllInvoices = async ({
  shopId,
  consumerId,
  tradingPartner,
  showRelatedTroughTradingPartner,
  jobId,
  fromJob
}) => {
  const { data } = await Api.get('/api/arap/allConsumerInvoices', {
    params: { consumerId, tradingPartner, shopId, showRelatedTroughTradingPartner, jobId, fromJob }
  })
  return data
}

export const consumerRepository = Object.freeze({
  getWithBalances,
  getInvoices,
  getByIds,
  getAllInvoices
})
