var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.route &&
    (_vm.route.distance || _vm.route.duration || _vm.route.total_nags_hours)
    ? _c("div", { staticClass: "scheduler-tech-totals" }, [
        _c(
          "div",
          [
            _vm.route.distance
              ? [
                  _vm._v(" Total driving distance: "),
                  _c("span", [_vm._v(_vm._s(_vm.distance))]),
                  _vm._v(" miles"),
                  _c("br")
                ]
              : _vm._e(),
            _vm.route.duration
              ? [
                  _vm._v(" Total driving time: "),
                  _c("span", [_vm._v(_vm._s(_vm.duration))]),
                  _vm._v(" hours"),
                  _c("br")
                ]
              : _vm._e(),
            _vm.route.total_nags_hours
              ? [
                  _vm._v(" Total nags hours: "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.route.total_nags_hours) + " hours")
                  ])
                ]
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }