import gql from 'graphql-tag'
import { ADDRESS_FRAGMENT } from './address.fragment'
import { SHOP_CONFIRMATION_SETTINGS_FRAGMENT } from './shop-confirmation-settings.fragment'

export const SHOP_DATA_FRAGMENT = gql`
  fragment ShopDataFields on Shop {
    id
    is_internal_number_auto_increment
    default_pricelevel_id
    right_part_access
    inventory_tracking
    logo
    address
    address_city
    address_id
    address_state
    address_zip
    name
    inventory_costing_method
    disclaimer
    qbo_refresh_token_expires
    email
    tsign_version
    default_invoice_tier
    customer_workorder_message
    customer_invoice_message
    customer_quote_message
    adhesive_default
    guest_id
    organization_id
    g_address {
      ...AddressFields
    }
    default_install_context
    organization_id
    pdf_default
    auto_sms_payment_receipt
    glaxis_shop_name
    glaxis_company_id
    default_job_confirmation_phone_number_id
    podium_location_id
    saleside_terms_and_conditions
    saleside_warranty_text
    created_consumers_auto_set_install_address
    shop_confirmation_settings {
      ...ShopConfirmationSettingsFields
    }
    royalty_percentage
  }
  ${ADDRESS_FRAGMENT}
  ${SHOP_CONFIRMATION_SETTINGS_FRAGMENT}
`
