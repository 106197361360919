var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rates-form" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames"
          }
        },
        _vm._l(_vm.schema, function(group) {
          return _c(
            "el-collapse-item",
            { key: group.key, attrs: { name: group.key, title: group.label } },
            [
              _c(
                "el-row",
                { staticClass: "rates-group", attrs: { gutter: 12 } },
                _vm._l(group.fields, function(field) {
                  return _c(
                    "el-col",
                    {
                      key: field.key,
                      attrs: { span: 24 / group.fields.length }
                    },
                    [
                      _c(
                        "gb-editable-data-row",
                        _vm._b(
                          {
                            attrs: {
                              value: _vm.get(field.key),
                              hideLabel: false,
                              size: "normal"
                            },
                            on: {
                              edit: function($event) {
                                return _vm.set($event.value, field.key)
                              }
                            }
                          },
                          "gb-editable-data-row",
                          field,
                          false
                        )
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }