<template>
  <div class="vendor-setting-tab">
    <div class="t-text-gray-400 t-mb-4">Shop Override</div>

    <el-checkbox
      v-model="override_allowed_shops"
      @change="updateOverrideAllowedShopsHandler"
    >
      Override which shops included on
    </el-checkbox>

    <div>
      <el-select
        v-if="override_allowed_shops"
        v-model="shop_ids"
        multiple
        class="t-mt-4"
        placeholder="Select included shops"
        @change="updateAllowedShops"
      >
        <el-option
          v-for="i in shops"
          :key="i.id"
          :label="i.name"
          :value="i.id"
        />
      </el-select>
    </div>

    <Tech v-if="isTech" />

    <SaleSource v-if="isSaleSource" />

    <Supplier v-if="isSupplier" />

    <SalesRep v-if="isSalesRep" :shops="shops" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import Tech from './Tech.vue'
import SaleSource from './SaleSource.vue'
import Supplier from './Supplier.vue'
import SalesRep from './SalesRep.vue'

export default {
  components: {
    Tech,
    SaleSource,
    Supplier,
    SalesRep
  },

  data() {
    return {
      override_allowed_shops: false,
      shop_ids: [],
      shops: []
    }
  },

  computed: {
    ...mapState(
      useVendorStore,
      [
        'data',
        'isCSR',
        'isSaleSource',
        'isSalesRep',
        'isTech',
        'isLocation',
        'isSupplier'
      ]
    )
  },

  mounted() {
    this.override_allowed_shops = !!this.data.override_allowed_shops
    this.shop_ids = this.data.shop_ids
    this.getShops()
  },

  watch: {
    "data.shop_ids" (v) {
      this.shop_ids = v
      this.override_allowed_shops = !!this.data.override_allowed_shops
    }
  },

  methods: {
    ...mapActions(useVendorStore, [
      'updateOverrideAllowedShops',
      'updateShops'
    ]),

    async getShops() {
      const { data } = await this.$unum
        .job()
        .get(`shop/organization/${this.data.organization_id}/list`)
      this.shops = data
    },

    async updateOverrideAllowedShopsHandler(value) {
      try {
        await this.updateOverrideAllowedShops(value)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async updateAllowedShops() {
      try {
        await this.updateShops(this.shop_ids)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
.vendor-setting-tab {
  .el-checkbox__label {
    @apply t-uppercase t-text-sm t-font-normal t-text-gray-500;
  }

  .el-input__inner {
    @apply t-rounded-lg;
  }
}
</style>
