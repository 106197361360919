<template>
  <el-table :data="data" class="tech-table" empty-text="No techs selected">
    <el-table-column label="Technician" prop="name" />
    <el-table-column label="Start location" width="250px">
      <template slot-scope="scope">
        <tech-location-select
          :tech="scope.row"
          prop="start_location"
          @change="
            $emit('row-change', { ...scope.row, start_location: $event })
          "
        />
      </template>
    </el-table-column>
    <el-table-column label="End location" width="250px">
      <template slot-scope="scope">
        <tech-location-select
          :tech="scope.row"
          prop="end_location"
          @change="$emit('row-change', { ...scope.row, end_location: $event })"
        />
      </template>
    </el-table-column>
    <el-table-column label="Shift start">
      <template slot-scope="scope">
        <el-time-select
          :value="scope.row.shift_start"
          @input="$emit('row-change', { ...scope.row, shift_start: $event })"
          :picker-options="{
            start: '05:30',
            step: '00:30',
            end: '20:30'
          }"
        />
      </template>
    </el-table-column>
    <el-table-column label="Shift end">
      <template slot-scope="scope">
        <el-time-select
          :value="scope.row.shift_end"
          @input="$emit('row-change', { ...scope.row, shift_end: $event })"
          :picker-options="{
            start: '05:30',
            step: '00:30',
            end: '20:30'
          }"
        />
      </template>
    </el-table-column>
    <el-table-column label="Min. jobs">
      <template slot-scope="scope">
        <el-input-number
          size="small"
          :value="scope.row.min_visits"
          @change="$emit('row-change', { ...scope.row, min_visits: $event })"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column width="100px">
      <template slot-scope="scope">
        <div>
          <el-button
            type="danger"
            @click="$emit('row-remove', scope.row)"
            size="mini"
            >Remove</el-button
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import TechLocationSelect from './TechLocationSelect'

export default {
  components: { TechLocationSelect },
  props: ['data']
}
</script>

<style lang="scss">
.tech-table {
  .el-date-editor.el-input {
    width: 100px;
  }
}
</style>
