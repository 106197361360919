var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-bg-blue-400 t-mt-2 t-pl-10 t-pr-6 t-py-6 t-flex t-justify-between"
    },
    [
      _c(
        "el-select",
        {
          staticClass: "sm:t-w-2/3 md:t-w-1/2 lg:t-w-1/3",
          attrs: { filterable: "", placeholder: "Select shop" },
          model: {
            value: _vm.shopId,
            callback: function($$v) {
              _vm.shopId = $$v
            },
            expression: "shopId"
          }
        },
        _vm._l(_vm.shops, function(s) {
          return _c(
            "el-option",
            { key: s.id, attrs: { label: s.name, value: s.id } },
            [
              _c("div", { staticClass: "t-flex" }, [
                _c("span", { staticClass: "t-flex-grow" }, [
                  _vm._v(_vm._s(s.name))
                ]),
                s.itemsCount > 0
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "t-text-xs t-text-gray-700 t-w-6 t-h-6 t-self-center\n                 t-rounded-full t-bg-blue-300 t-flex t-justify-center t-items-center"
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(s.itemsCount > 99 ? "99+" : s.itemsCount)
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        }),
        1
      ),
      _c("div")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }