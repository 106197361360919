var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "notification-setting-permissions-dialog",
      attrs: {
        "append-to-body": "",
        width: "700px",
        visible: _vm.localVisible,
        title: "Notification Setting Permissions"
      },
      on: {
        "update:visible": function($event) {
          _vm.localVisible = $event
        },
        open: _vm.open,
        close: _vm.close
      }
    },
    [
      _vm.displayOverlay
        ? _c("span", { staticClass: "overlay" }, [
            _c(
              "div",
              {
                staticClass: "close-dialog-overlay",
                on: {
                  click: function($event) {
                    _vm.localVisible = false
                  }
                }
              },
              [_c("v-icon", { attrs: { name: "times", scale: "1.1" } })],
              1
            ),
            _c("div", { staticClass: "overlay-text" }, [
              _vm._v(
                "The general notifications permission must be checked for these permissions to take effect"
              )
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "permissions-container p-2" },
        _vm._l(_vm.config, function(category, index) {
          return _c(
            "div",
            { key: "test-" + index },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.indeterminates[category.type] },
                  on: {
                    change: function($event) {
                      return _vm.handleAllSettings(
                        $event,
                        category.options,
                        category.type
                      )
                    }
                  },
                  model: {
                    value: _vm.checkAlls[category.type],
                    callback: function($$v) {
                      _vm.$set(_vm.checkAlls, category.type, $$v)
                    },
                    expression: "checkAlls[category.type]"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(_vm._s(category.label))
                  ])
                ]
              ),
              _c("div", { staticStyle: { margin: "5px 0" } }),
              _c(
                "el-checkbox-group",
                {
                  on: {
                    change: function($event) {
                      return _vm.handleCheckedNotificationSettings(
                        category.options,
                        category.type
                      )
                    }
                  },
                  model: {
                    value: _vm.checkedNotificationSettings,
                    callback: function($$v) {
                      _vm.checkedNotificationSettings = $$v
                    },
                    expression: "checkedNotificationSettings"
                  }
                },
                _vm._l(category.options, function(setting) {
                  return _c(
                    "el-checkbox",
                    { key: setting.value, attrs: { label: setting.value } },
                    [_vm._v(_vm._s(setting.label))]
                  )
                }),
                1
              ),
              _c("hr")
            ],
            1
          )
        }),
        0
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.saving },
              on: { click: _vm.save }
            },
            [_vm._v("Save")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "background-color": "black", color: "white" },
              attrs: { disabled: _vm.saving },
              on: {
                click: function($event) {
                  _vm.localVisible = false
                }
              }
            },
            [_vm._v("Cancel")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }