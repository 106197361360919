<template>
  <el-dialog
    append-to-body
    top="120px"
    width="900px"
    custom-class="edit-warehouse-entity-dialog"
    :visible="visible"
    :before-close="handleClose"
  >
    <span slot="title" class="t-text-gray-700 t-text-lg">
      <i class="el-icon-document" />
      <span class="t-ml-3">Create Receive</span>
    </span>

    <div class="t-pt-6">
      <el-form
        :model="form"
        ref="form"
        size="small"
        class="t-mx-6"
        hide-required-asterisk
      >
        <div
          v-for="(part, index) of form.parts"
          :key="index"
        >
          <div
            class="t-flex t-border t-border-solid t-border-gray-200 t-rounded-md t-px-4
              t-py-2 t-shadow t-bg-gray-50 t-mb-3"
          >
            <div class="t-w-1/4">
              <div class="t-text-gray-500 t-text-xs t-h-5">Part #</div>
              <div class="t-mt-1">{{ part.itemName }}</div>
            </div>

            <div class="t-w-1/4">
              <div class="t-text-gray-500 t-text-xs t-h-5">Total Qty</div>
              <div class="t-mt-1">{{ part.totalQuantity }}</div>
            </div>

            <div class="t-w-1/4">
              <div class="t-text-gray-500 t-text-xs t-h-5">Unreceived Qty</div>
              <div class="t-mt-1">{{ countUnreceived(form.parts[index]) }}</div>
            </div>

            <div class="t-w-1/4">
              <div class="t-text-gray-500 t-text-xs t-h-5">Receive Qty</div>
              <div v-if="countUnreceived(form.parts[index]) <= 0" class="t-italic">
                No units to receive
              </div>
              <el-form-item
                v-else
                label-width="0"
                class="t-mb-0"
                :prop="'parts.' + index + '.quantity'"
                :show-message="false"
                :rules="[{ required: true }]"
              >
                <el-input-number
                  v-model.number="form.parts[index].quantity"
                  type="number"
                  size="small"
                  :min="0"
                  :max="countUnreceived(form.parts[index])"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <el-form-item prop="note">
          <span slot="label" class="undo-label-styles">Notes</span>
          <el-input
            v-model="form.note"
            type="textarea"
            placeholder="Enter notes"
            :rows="3"
          />
        </el-form-item>
      </el-form>

      <div class="t-py-3 t-px-6 t-flex t-justify-between t-border-0 t-border-t t-border-solid">
        <el-button
          plain
          size="small"
          @click="handleClose"
        >
          Close
        </el-button>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          :loading="submitting"
          :disabled="!hasUnitsToReceive"
          @click="handleSubmit"
        >
          Create
        </el-button>
      </div>

    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    shopId: Number,
    poId: Number,
    poLines: Array
  },

  data: () => ({
    form: {
      parts: [],
      note: ''
    },
    submitting: false
  }),

  computed: {
    hasUnitsToReceive() {
      return !!this.form.parts.find(i => i.quantity > 0)
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.prepareForm()
      }
    }
  },

  methods: {
    prepareForm() {
      this.poLines.forEach(line => {
        this.form.parts.push({
          itemName: line.itemName,
          purchaseOrderId: this.poId,
          purchaseOrderLineNumber: line.purchaseOrderLineNumber,
          totalQuantity: line.quantity,
          quantity: this.countUnreceived({
            totalQuantity: line.quantity,
            receivedCount: line.receivedCount,
            techPickedUpCount: line.techPickedUpCount,
            returnedCount: line.returnedCount
          }),
          techPickUps: line.techPickUps,
          receivedCount: line.receivedCount,
          techPickedUpCount: line.techPickedUpCount,
          returnedCount: line.returnedCount
        })
      })
    },

    handleClose() {
      this.form = {
        parts: [],
        note: ''
      }
      this.$emit('update:visible', false)
    },

    countUnreceived({ totalQuantity, receivedCount, techPickedUpCount, returnedCount }) {
      return totalQuantity - receivedCount - techPickedUpCount - returnedCount
    },

    async handleSubmit() {
      if (!this.form.parts.find(i => i.quantity > 0)) {
        this.$message.warning('No units are selected to receive')
        return
      }
      try {
        this.submitting = true
        await this.$refs.form.validate()
        await this.$unum.warehouse().post('receives', {
          shopId: this.shopId,
          reference: this.form.reference,
          note: this.form.note,
          parts: this.form.parts.filter(i => i.quantity > 0)
        })
        this.$message.success('Receive was created')
        this.$emit('created')
        this.handleClose()
      } catch (err) {
        this.$message.error(err.response.data.message)
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>
