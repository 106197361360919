// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".job-details-commission .el-input-group__append,\n.job-details-commission .el-input-group__prepend {\n  padding: 0 8px;\n}\n.job-details-commission .el-input__inner {\n  min-width: 100px;\n  padding: 0 8px;\n  text-align: right;\n}", "",{"version":3,"sources":["/app/src/components/SchedulerV3/helpers/JobDetailsCommission.vue"],"names":[],"mappings":"AAwGE;;EAEE,cAAA;AA5FJ;AA8FE;EACE,gBAAA;EACA,cAAA;EACA,iBAAA;AA5FJ","file":"JobDetailsCommission.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.job-details-commission .el-input-group__append,\n.job-details-commission .el-input-group__prepend {\n  padding: 0 8px;\n}\n.job-details-commission .el-input__inner {\n  min-width: 100px;\n  padding: 0 8px;\n  text-align: right;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
