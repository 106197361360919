import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class ShopPartNote extends ObjectTracker {
  id = -1
  shop_id = -1
  partno = ''
  note = ''
  popup = false

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getShopPartNote (shopId, partno, callbackFunction) {
    
    if (partno && partno.length > 0) {
      Api({ url: `/api/shop/${shopId}/shopPartNote/${partno}` }).then(res => {
        
        callbackFunction(new ShopPartNote(res.data))
      })
    } else {
      callbackFunction(new ShopPartNote())
    }
  }
  async createUpdateShopPartNote (shopId, partno, callbackFunction = null, errorCallbackFunction = null) {
    if (!partno || (this.id === -1 && this.popup === false && this.note === '')) {
      
      return
    } else if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
      this.partno = partno
    }
    console.log('create update note')

    return Api.post(`/api/shop/${shopId}/shopPartNote/${this.partno}`, this)
  }
}
