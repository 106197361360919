<template>
  <el-dialog
    append-to-body
    top="150px"
    width="600px"
    custom-class="edit-warehouse-entity-dialog"
    title="Delivery addresses"
    :visible="visible"
    :before-close="handleClose"
  >
    <div class="t-py-6">
      <el-form
        :model="form"
        ref="form"
        class="t-px-6"
        hide-required-asterisk
      >
        <el-form-item
          prop="name"
          :show-message="false"
          :rules="[{ required: true }]"
        >
          <el-input
            v-model="form.name"
            placeholder="Enter name"
            class="t-w-full"
          />
        </el-form-item>

        <AddressInput
          v-model="form.address"
          popperFix
        />

        <div class="t-text-right">
          <el-button
            type="primary"
            class="t-mt-6"
            @click="submit"
          >
            Add and Select
          </el-button>
        </div>
      </el-form>

      <div
        v-if="addresses.length > 0"
        class="t-px-6 t-border-0 t-border-t t-border-solid t-border-gray-200
          t-pt-6 t-mt-6"
      >
        <div
          v-for="(i, index) in addresses"
          :key="i.id"
          class="t-flex t-items-center"
          :class="{
            't-border-0 t-border-b t-border-solid t-border-gray-200 t-pb-2 t-mb-2': index + 1 < addresses.length
          }"
        >
          <div class="t-flex-grow">
            <div>{{ i.name }}</div>
            <div class="t-text-sm">
              {{ i.address }}, {{ i.city }}, {{ i.state }} {{ i.zip }}, {{ i.unit }}
            </div>
          </div>
          <div class="t-text-red-400">
            <i
              class="el-icon-delete t-cursor-pointer"
              @click="remove(i.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import AddressInput from '@/components/Helpers/AddressInput.vue'

export default {
  components: { AddressInput },

  props: {
    visible: Boolean,
    shopId: Number
  },

  data() {
    return {
      form: {
        name: '',
        address: {}
      },
      addresses: []
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.getAddresses()
      }
    }
  },

  methods: {
    async getAddresses() {
      const { data } = await this.$unum.warehouse().get('delivery-addresses', {
        params: {
          shopId: this.shopId
        }
      })
      this.addresses = data.data
    },

    handleClose() {
      this.form = {
        name: '',
        address: {}
      }
      this.addresses = []
      this.$refs.form.clearValidate()
      this.$emit('update:visible', false)
    },

    async submit() {
      if (!this.form.address.google_place_id) {
        this.$message.warning('Incorrect address')
        return
      }

      try {
        await this.$refs.form.validate()

        const { data } = await this.$unum.warehouse().post(
          'delivery-addresses',
          {
            shopId: this.shopId,
            name: this.form.name,
            address: this.form.address.address,
            city: this.form.address.city,
            state: this.form.address.state,
            zip: this.form.address.zip,
            unit: this.form.address.unit
          }
        )
        console.log(data)
        this.$emit('created', data.data)
        this.handleClose()
      } catch (e) {
        if (e.response?.data) {
          this.$message.error(e.response.data)
        }
      }
    },

    async remove(id) {
      try {
        await this.$messageBox.confirm(
          'Do you want to delete address',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
        await this.$unum.warehouse().delete(`delivery-addresses/${id}`)
        this.addresses = this.addresses.filter(i => i.id !== id)
        this.$emit('removed', id)
      } catch {}
    }
  }
}
</script>
