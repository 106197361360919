import ObjectTacker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Alert extends ObjectTacker {
  id = -1
  created = -1
  title = ''
  message = ''
  event_trigger = ''
  type = ''
  user_id = -1
  start_date = ''
  end_date = ''
  always_show = ''
  timer = ''
  updated = ''
  shop_id = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  addDoNotShow (callback, errorCallback) {
    Api.post(`/api/alertcheck/${this.id}/noshow`, this).then(res => {
      callback(res)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }
}
