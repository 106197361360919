<template>
  <el-dialog
    :visible="visible"
    append-to-body
    :before-close="close"
    width="500px"
    class="t-font-sans">
    <span v-if="part" slot="title" class="t-text-lg t-text-gray-800">
      Add part
      <span class="t-font-medium t-text-purple-800">
        {{ partName }}
      </span>
    </span>
    <el-alert
      v-if="optionMessage"
      :title="optionMessage"
      type="error"
      :closable="false"
      class="t-mb-5"
    />
    <div class="t-flex t-flex-col">
      <el-radio
        v-model="option"
        label="toCart"
        class="t-normal-case t-mb-4 t-font-normal"
      >
        Just add to cart
      </el-radio>
      <el-tooltip
        v-if="addToCartAndPartsList"
        :disabled="status !== 'draft'"
        content="publish draft before sourcing parts"
        placement="left"
        effect="light">
        <el-radio
          v-model="option"
          :disabled="status === 'draft'"
          label="toCartAndToParts"
          class="t-normal-case t-font-normal"
        >
          Add to cart and to parts list
        </el-radio>
      </el-tooltip>
      <div v-if="partsLoading" class="t-p-12 t-text-center t-text-3xl t-text-blue-600">
        <i class="el-icon-loading"/>
      </div>
      <div v-else>
        <div v-if="jobParts.length">
          <el-divider/>
          <p class="t-text-gray-600 t-italic">
            Or add to cart and connect to an item from parts list
          </p>
          <el-radio
            v-model="option"
            :label="p.id"
            v-for="p in jobParts"
            :key="p.id"
            :disabled="
              p.american_order_item_id  !== null ||
              p.glaxis_order_item_id  !== null ||
              p.pilkington_order_item_id  !== null ||
              p.nielsen_moller_order_item_id !== null
            "
            :class="{
                't-bg-gray-100':
                  p.american_order_item_id  !== null ||
                  p.glaxis_order_item_id  !== null ||
                  p.pilkington_order_item_id  !== null ||
                  p.nielsen_moller_order_item_id !== null,
                'hover:t-bg-gray-200':
                  p.american_order_item_id  !== null ||
                  p.glaxis_order_item_id  !== null ||
                  p.pilkington_order_item_id  !== null ||
                  p.nielsen_moller_order_item_id !== null,
                't-cursor-not-allowed':
                  p.american_order_item_id  !== null ||
                  p.glaxis_order_item_id  !== null ||
                  p.pilkington_order_item_id  !== null ||
                  p.nielsen_moller_order_item_id !== null
              }"
            class="t-border t-border-solid t-border-blue-300 t-rounded-md t-px-4
                    t-py-3 t-flex t-items-center t-cursor-pointer t-normal-case
                    t-font-normal hover:t-bg-blue-100 t-mb-2 t-w-full"
          >
            <div class="t-ml-1">
              <div class="t-text-gray-700 t-mb-1">{{ p.part_number }}</div>
              <div class="t-text-gray-500">{{ p.description }}</div>
            </div>
          </el-radio>
        </div>
      </div>
    </div>
    <div class="t-text-right t-mt-3">
      <el-button
        :loading="submitting"
        type="primary"
        plain
        @click="add">
        Add
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { call } from 'vuex-pathify'
import job from '@/scripts/objects/job'
import part from '@/scripts/objects/part'

export default {
  props: ['visible', 'part', 'vendor', 'shopId', 'jobId', 'status', 'warehouse', 'username'],
  data: () => ({
    option: null,
    optionMessage: null,
    submitting: false,
    jobParts: [],
    partsLoading: false
  }),
  computed: {
    partName () {
      if (this.vendor === 'pilkington') {
        return this.part.industryCode
      }

      if (['agd', 'nm'].includes(this.vendor)) {
        return this.part.Sku
      }

      return this.part.fullPartNumber || this.part.part
    },
    addToCartAndPartsList () {
      return this.part &&
        !this.warehouse &&
        (
          (this.vendor !== 'pilkington' && this.part.partType === 'GL') ||
          ['agd', 'american', 'nm', 'pilkington'].includes(this.vendor)
        )
    }
  },
  watch: {
    async visible(value) {
      if (!value) {
        this.option = null
        this.optionMessage = null
        this.jobParts = []
      } else {
        this.partsLoading = true
        this.jobParts = await job.getParts(this.jobId)
        if (this.warehouse) {
          const defaultOption = this.jobParts.filter(part =>
            ((part.part_number === this.part.item_number) ||
            (part.part_number === this.part.fullPartNumber)) &&
            part.american_order_item_id === null &&
            part.glaxis_order_item_id === null &&
            part.pilkington_order_item_id === null &&
            part.nielsen_moller_order_item_id === null
          )
          this.option = defaultOption[0]?.id || null
        }
        this.partsLoading = false
      }
    },
    option(value) {
      if (value) {
        this.optionMessage = null
      }
    }
  },
  methods: {
    addToCart: call('shoppingCart/addItem'),
    async add () {
      if (this.option === null) {
        this.optionMessage = 'Please select one of the options below'
      } else {
        this.submitting = true
        try {
          const cartItem = await this.addToCart({
            shopId: this.shopId,
            vendor: this.vendor,
            vendorItem: {
              ...this.part,
              username: this.username,
              vendor: this.vendor
            }
          })
          if (this.option === 'toCartAndToParts') {
            await this.$emit('addAndLinkPart', cartItem.itemId)
          }
          if (this.option !== 'toCart' && this.option !== 'toCartAndToParts') {
            await part.attachVendorItem(this.vendor, this.option, cartItem.itemId)
          }
          if (this.warehouse) {
            this.$emit('refresh-data')
          } else {
            await this.$job.refetchParts()
          }
          this.$message.success('Item was added successfully')
          this.close()
          this.submitting = false
        } catch (err) {
          if (err) {
            this.$message.error('Item wasn\'t added. Please try again.')
          }
          this.submitting = false
        }
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
