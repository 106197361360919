var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.itemDeleting,
          expression: "itemDeleting"
        }
      ],
      staticClass:
        "t-mb-3 t-border t-rounded-md t-shadow-sm t-flex t-border-solid\n         t-border-gray-300"
    },
    [
      _c(
        "div",
        { staticClass: "t-pl-3 t-pt-1" },
        [
          _c("el-checkbox", {
            attrs: { value: _vm.selected },
            on: { change: _vm.toggleSelection }
          })
        ],
        1
      ),
      _c("div", { staticClass: "t-flex t-flex-1" }, [
        _c("div", { staticClass: "t-w-1/2 t-pl-2 t-py-2" }, [
          _c("div", [
            _c("span", { staticClass: "t-font-medium t-text-gray-700" }, [
              _vm.vendor === "pilkington"
                ? _c("span", [_vm._v(_vm._s(_vm.item.industryCode))])
                : _vm.vendor === "american"
                ? _c("span", [_vm._v(_vm._s(_vm.item.itemNumber))])
                : ["agd", "nm"].includes(_vm.vendor)
                ? _c("span", [_vm._v(_vm._s(_vm.item.sku))])
                : _c("span", [
                    _vm._v(_vm._s(_vm.item.fullPartNumber || _vm.item.part))
                  ])
            ]),
            _vm.item.trademark
              ? _c("span", [
                  _c("span", { staticClass: "t-ml-1 t-text-xs" }, [
                    _vm._v("by")
                  ]),
                  _c("span", { staticClass: "t-ml-1" }, [
                    _vm._v(_vm._s(_vm.item.trademark))
                  ])
                ])
              : _vm._e()
          ]),
          _vm.item.jobId
            ? _c("div", { staticClass: "t-text-sm" }, [
                _vm._v(" Job # "),
                _c(
                  "a",
                  {
                    staticClass: "t-font-medium",
                    attrs: { href: "/jobs/" + _vm.item.jobId, target: "_blank" }
                  },
                  [_vm._v(" " + _vm._s(_vm.item.jobId) + " ")]
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "t-text-gray-500 t-text-xs" }, [
            _vm._v("added by " + _vm._s(_vm.item.username))
          ]),
          _c("div", [
            _c(
              "span",
              {
                staticClass:
                  "t-text-blue-600 t-cursor-pointer hover:t-underline",
                on: { click: _vm.deleteItem }
              },
              [_vm._v(" delete ")]
            )
          ])
        ]),
        _c("div", { staticClass: "t-w-1/2 t-py-2 t-flex t-flex-col" }, [
          _vm.vendor !== "american"
            ? _c("div", { staticClass: "t-mb-1" }, [
                _c("i", {
                  staticClass:
                    "el-icon-location-information t-mr-2 t-text-blue-400"
                }),
                _c("span", { staticClass: "t-text-gray-700" }, [
                  _vm.vendor === "pilkington"
                    ? _c("span", [_vm._v(_vm._s(_vm.item.plantName))])
                    : ["agd", "nm"].includes(_vm.vendor)
                    ? _c("span", [_vm._v(_vm._s(_vm.item.locationName))])
                    : _c("span", [_vm._v(_vm._s(_vm.item.locationDescription))])
                ])
              ])
            : _vm._e(),
          _vm.item.deliveryDate && _vm.item.deliveryTime
            ? _c("div", [
                _c("i", {
                  staticClass: "el-icon-truck t-mr-2 t-text-blue-400"
                }),
                _c("span", { staticClass: "t-text-gray-600" }, [
                  _vm._v(_vm._s(_vm.delivery))
                ])
              ])
            : _vm._e(),
          _vm.item.leadTimeFormatted
            ? _c("div", [
                _c("i", {
                  staticClass: "el-icon-truck t-mr-2 t-text-blue-400"
                }),
                _c("span", { staticClass: "t-text-gray-600" }, [
                  _vm._v(_vm._s(_vm.item.leadTimeFormatted))
                ])
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        {
          staticClass:
            "t-font-bold t-px-3 t-bg-gray-100 t-border-solid t-border-0\n           t-border-l-8 t-border-gray-300 t-rounded-r-md t-self-stretch t-flex\n           t-items-center"
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.quantityUpdating,
                  expression: "quantityUpdating"
                }
              ],
              staticClass: "t-w-24 t-flex t-justify-end t-items-center"
            },
            [
              _c(
                "el-select",
                {
                  on: { change: _vm.updateQuantity },
                  model: {
                    value: _vm.quantity,
                    callback: function($$v) {
                      _vm.quantity = $$v
                    },
                    expression: "quantity"
                  }
                },
                _vm._l(10, function(i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { label: i, value: i }
                  })
                }),
                1
              ),
              _c("span", { staticClass: "t-pl-3 t-pr-2" }, [_vm._v("x")])
            ],
            1
          ),
          _c("div", { staticClass: "t-w-16 t-flex-1 t-text-right" }, [
            ["agd", "nm", "pilkington"].includes(_vm.vendor)
              ? _c("span", [_vm._v(_vm._s(_vm.item.unitPrice))])
              : _vm.vendor === "american"
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(Math.round(_vm.item.itemPrice * 100) / 100) +
                      " "
                  )
                ])
              : _c("span", [_vm._v(_vm._s(_vm.item.priceBuyer))])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }