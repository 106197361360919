export const permissions = {
  JOB: 2,
  JOBMANAGER: 3,
  MANAGECSRS: 4,
  MANAGESALESREPS: 5,
  MANAGETECHS: 6,
  MANAGESALESSOURCES: 7,
  MANAGELOCATIONS: 8,
  MANAGEINSURANCE: 9,
  MANAGECOMMERCIALACCOUNTS: 10,
  MANAGETAXES: 11,
  ARAP: 12,
  REPORTS: 13,
  ACCOUNTS: 14,
  SHOPS: 15,
  USERS: 16,
  WAREHOUSE: 17,
  NAGS: 18,
  GRAPH: 19,
  PRICING: 20,
  COMMISSION: 21,
  JOBVIEWONLY: 22,
  VIEWPARTS: 23,
  ACCOUNTINGPACKAGES: 24,
  TECHCOMMISSION: 26,
  JOBVOID: 27,
  SCHEDULER: 28,
  DATATAB: 55,
  DATATABJOB: 56,
  DATATABCUSTOMERS: 57,
  DATATABVENDORS: 58
}

export const permissionCheck = function (permission, state) {
  // have user permissions
  let tempRoles = state.roles
  var found = false
  try {
    for (var i = 0; i < tempRoles.length; i++) {
      if (tempRoles[i].role_id === permission) {
        found = true
      }
    }
  } catch (err) {}
  return found
}

export const permissionHumanReadable = function (permission) {
  switch (permission) {
    case permissions.JOB:
      return 'View/Modify Jobs'
    case permissions.JOBMANAGER:
      return 'Job Manager'
    case permissions.MANAGECSRS:
      return 'Manage CSRS'
    case permissions.MANAGESALESREPS:
      return 'Manage Sales Reps'
    case permissions.MANAGETECHS:
      return 'Manage Techs'
    case permissions.MANAGESALESSOURCES:
      return 'Manage Sale Sources'
    case permissions.MANAGELOCATIONS:
      return 'Manage Locations'
    case permissions.MANAGEINSURANCE:
      return 'Manage Insurance/Fleet'
    case permissions.MANAGECOMMERCIALACCOUNTS:
      return 'Manage Commercial Accounts'
    case permissions.MANAGETAXES:
      return 'Manage Taxes'
    case permissions.ARAP:
      return 'AR/AP'
    case permissions.REPORTS:
      return 'View Reports'
    case permissions.ACCOUNTS:
      return 'View/Modify Accounts'
    case permissions.SHOPS:
      return 'View/Modify Shops'
    case permissions.USERS:
      return 'View/Modify Users'
    case permissions.WAREHOUSE:
      return 'View Warehouse'
    case permissions.NAGS:
      return 'View Nags'
    case permissions.GRAPH:
      return 'View Dashboard Graphs'
    case permissions.PRICING:
      return 'View Pricing'
    case permissions.COMMISSION:
      return 'View Commissions'
    case permissions.JOBVIEWONLY:
      return 'Modify Jobs'
    case permissions.VIEWPARTS:
      return 'View Parts'
    case permissions.ACCOUNTINGPACKAGES:
      return 'Accounting Packages'
    case permissions.TECHCOMMISSION:
      return 'View Tech Commissions'
    case permissions.JOBVOID:
      return 'Change Job Status To Void'
    case permissions.SCHEDULER:
      return 'View Scheduler'
    case permissions.DATATAB:
      return 'View Data Tab'
    case permissions.DATATABJOB:
      return 'View Data Tab Job'
    case permissions.DATATABCUSTOMERS:
      return 'View Data Tab Customers'
    case permissions.DATATABVENDORS:
      return 'View Data Tab Vendors'
    default:
      return ''
  }
}
