<template>
  <div class="autocomplete" style="position:relative" v-bind:class="{'open':openSuggestion, 'input-group':true}">
    <input class="form-control quick-search autocomplete-off" type="text"
      :readonly='readonly'
      placeholder="Global Search..."
      v-model="internalSearchValue"
      autocomplete="false"
      @keydown.enter = 'enter'
      @keydown.down = 'down'
      @keydown.up = 'up'
      @focus = 'focused'
      @blur = 'reset'
      v-loading="true"
      >
    <span class="input-group-btn">
      <button class="btn btn-gray" type="button"><i class="fa fa-search"></i></button>
    </span>
    <!-- Search Results -->
    <div :ref="'autocompleteResults'" v-show="tabs.length || loadingQuickSearch" class="autocomplete-results" :class="{ autocompleteResultsStyled: !isMobile }" :style="{'max-height': '400px', 'min-height': '400px', 'margin-top':'35px'}" v-loading="loadingQuickSearch">
      <el-tabs v-model="currentTab" style="color:black">
        <el-tab-pane v-for="item in tabs" :key="item.key" :name="item.key" lazy>
          <span slot="label">
            {{ item.label }}&nbsp;&nbsp;
            <span class="tab-count" :style="`background:${getTabColor(item.key)};`">{{item.count}}</span>
          </span>
          <div class="tab-container">
            <component :is="item.component" :loading="false" v-model="item.data" ref="tabs" @selected="selected"/>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- Recent Jobs (Non-consumer) -->
    <div v-if="!userConsumer" :ref="'userRecentJobsResults'" v-show="recentJobsToDisplay && recentJobsToDisplay.length > 0 && showUserRecentJobs" class="autocomplete-results" :class="{ autocompleteResultsStyled: !isMobile }" :style="{'max-height': '400px', 'margin-top':'35px', 'color':'black'}">
      <div class="row" style="width:100%; cursor:pointer;" @click.prevent="activateRow">
        <div class="col-8">
          <span style="text-decoration:underline;">Recent Jobs</span>
        </div>
        <div class="col-3">
          <span>Jobs:</span>&nbsp;<input type="number" min="0" max="30" v-model="recentJobCount" style="width:30%;" />
        </div>
        <div class="col-1">
          <a href="javascript:void(0)" style="text-decoration: none;" @click="disableUserRecentJobs">Disable</a>
        </div>
      </div>
      <ul class="" style="width:100%;">
        <template v-for="(recentJob, index) in recentJobsToDisplay">
          <li :key='index'
              :ref="'recentJob:' + index"
              class="autocomplete-result"
              v-on:mouseover="mouseOver(index)"
              v-bind:class="{'is-active-recent': isActive(index)}"
              @mousedown="suggestionClick(index)">
            <a href="javascript:void(0)" style="text-decoration: none;" v-bind:class="{'white-text': isActive(index)}">
              <span v-if="recentJob.job.customer_type === 'individual' || recentJob.job.customer_type === null">
                <strong :class="{
                  'qs-job-color': true,
                  'label-job-invoice': recentJob.job.status === 'invoice',
                  'label-job-workOrder': recentJob.job.status === 'workOrder',
                  'label-job-quote': recentJob.job.status === 'quote',
                  'label-job-warranty': (recentJob.job.status === 'warranty' || recentJob.job.status === 'warrantyComplete'),
                  'label-job-void': recentJob.job.status === 'void'
                }">
                  {{recentJob.job.id}}
                </strong>
                <span class="qs-separator mx-1">|</span>
                <span v-if='recentJob.job.customer_first_name && recentJob.job.customer_first_name.length > 0' class="customerName">{{recentJob.job.customer_first_name}}</span>
                <span v-if='recentJob.job.customer_middle_name && recentJob.job.customer_middle_name.length > 0' class="customerName">&nbsp;{{recentJob.job.customer_middle_name}}</span>
                <span v-if='recentJob.job.customer_last_name && recentJob.job.customer_last_name.length > 0' class="customerName">&nbsp;{{recentJob.job.customer_last_name}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.referral_number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.internal_number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span class="customerNumber">{{recentJob.job.customer_phone1}}</span>
                <span class="qs-separator mx-1">|</span>
                <span class="customerNumber">{{recentJob.job.customer_phone2}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.unit_number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.policy_number}}</span>
              </span>
              <span v-else>
                <strong :class="{
                  'qs-job-color': true,
                  'label-job-invoice': recentJob.job.status === 'invoice',
                  'label-job-workOrder': recentJob.job.status === 'workOrder',
                  'label-job-quote': recentJob.job.status === 'quote',
                  'label-job-warranty': (recentJob.job.status === 'warranty' || recentJob.job.status === 'warrantyComplete'),
                  'label-job-void': recentJob.job.status === 'void'
                }">{{recentJob.job.id}}</strong>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.ca.name}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.referral_number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.internal_number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.ca.phone1}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.ca.phone2}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.unit_number}}</span>
              </span>
            </a>
          </li>
        </template>
      </ul>
    </div>
    <!-- Recent Jobs (Consumer) -->
    <div v-if="userConsumer" :ref="'userRecentJobsResultsConsumer'" v-show="userConsumer === true && recentJobsToDisplay && recentJobsToDisplay.length > 0 && showUserRecentJobs" class="autocomplete-results" :class="{ autocompleteResultsStyled: !isMobile }" :style="{'max-height': '400px', 'margin-top':'35px', 'color':'black'}">
      <div class="row" style="width:100%; cursor:pointer;" @click.prevent="activateRow">
        <div class="col-8">
          <span style="text-decoration:underline;">Recent Jobs</span>
        </div>
        <div class="col-3">
          <span>Jobs:</span>&nbsp;<input type="number" min="0" max="30" v-model="recentJobCount" style="width:30%;" />
        </div>
        <div class="col-1">
          <a href="javascript:void(0)" style="text-decoration: none;" @click="disableUserRecentJobs">Disable</a>
        </div>
      </div>
      <ul class="" style="width:100%;">
        <template v-for="(recentJob, index) in recentJobsToDisplay">
          <li :key='index'
              :ref="'recentJob:' + index"
              class="autocomplete-result"
              v-on:mouseover="mouseOver(index)"
              v-bind:class="{'is-active-recent': isActive(index)}"
              @mousedown="suggestionClick(index)">
            <a href="javascript:void(0)" style="text-decoration: none;" v-bind:class="{'white-text': isActive(index)}">
              <span v-if="recentJob.job.consumer && !recentJob.job.consumer.is_commercial">
                <strong :class="{
                  'qs-job-color': true,
                  'label-job-invoice': recentJob.job.status === 'invoice',
                  'label-job-workOrder': recentJob.job.status === 'workOrder',
                  'label-job-quote': recentJob.job.status === 'quote',
                  'label-job-warranty': (recentJob.job.status === 'warranty' || recentJob.job.status === 'warrantyComplete'),
                  'label-job-void': recentJob.job.status === 'void'
                }">
                  {{recentJob.job_id}}
                </strong>
                <span class="qs-separator mx-1">|</span>
                <span v-if='recentJob.job.consumer.full_name.first && recentJob.job.consumer.full_name.first.length > 0' class="customerName">{{recentJob.job.consumer.full_name.first}}</span>
                <span v-if='recentJob.job.consumer.full_name.middle && recentJob.job.consumer.full_name.middle.length > 0' class="customerName">&nbsp;{{recentJob.job.consumer.full_name.middle}}</span>
                <span v-if='recentJob.job.consumer.full_name.last && recentJob.job.consumer.full_name.last.length > 0' class="customerName">&nbsp;{{recentJob.job.consumer.full_name.last}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.referral_number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.internal_number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span class="customerNumber">{{recentJob.job.consumer.phone.number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.unit_number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.policy_number}}</span>
              </span>
              <span v-else>
                <strong :class="{
                  'qs-job-color': true,
                  'label-job-invoice': recentJob.job.status === 'invoice',
                  'label-job-workOrder': recentJob.job.status === 'workOrder',
                  'label-job-quote': recentJob.job.status === 'quote',
                  'label-job-warranty': (recentJob.job.status === 'warranty' || recentJob.job.status === 'warrantyComplete'),
                  'label-job-void': recentJob.job.status === 'void'
                }">{{recentJob.job.id}}</strong>
                <span v-if="recentJob.job.consumer" class="qs-separator mx-1">|</span>
                <span v-if="recentJob.job.consumer" class="customerName">{{recentJob.job.consumer.company_name}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.referral_number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.internal_number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span v-if="recentJob.job.consumer" class="customerNumber">{{recentJob.job.consumer.phone.number}}</span>
                <span class="qs-separator mx-1">|</span>
                <span>{{recentJob.job.unit_number}}</span>
              </span>
            </a>
          </li>
        </template>
      </ul>
    </div>
    <consumer-dialog
      v-model="showConsumerDialog"
      :consumer="{}"
      :shop_id="4"
      focus
      @on-save="onCreateNew"
    />
  </div>
</template>

<script>
import Job from '@/scripts/objects/job'
import UserRecentJob from '@/scripts/objects/user_recent_job'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import Jobs from './Jobs'
import Salesidejobs from './Salesidejobs'
import Consumers from './Consumers'
import * as R from 'ramda'
import ConsumerDialog from '../../modals/ConsumerDialog/Index.vue'
import { get } from 'vuex-pathify'

export default {
  props: {
    searchValue: {
      type: String,
      required: false
    },
    selection: {
      type: Object,
      required: false
    },
    smallInput: {
      type: Boolean,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    numCharBeforeSearch: {
      type: Number,
      required: false,
      default: 3
    }
  },
  data () {
    return {
      value: '',
      open: false,
      current: 0,
      currentTab: 'jobs',
      quickSearchVal: '',
      userRecentJobs: [],
      loadingUserRecentJobs: false,
      showUserRecentJobs: false,
      filterInternally: false,
      loadingQuickSearch: false,
      timeout: null,
      recentJobsTimeout: null,
      readonly: true,
      userUpdateTimeout: null,
      tabs: [],
      tabsConfig: {
        jobs: {label: 'Jobs', key: 'jobs', count: 0, component: Jobs, data: [] },
        salesidejobs: {label: 'Salesidejobs', key: 'salesidejobs', count: 0, component: Salesidejobs, data: [] }//,
        // consumers: {label: 'Customers', key: 'consumers', count: 0, component: Consumers, data: [] }
      },
      showConsumerDialog: false,
      selectedConsumer: {}
    }
  },
  components: {
    ConsumerDialog
  },
  watch: {
    internalSearchValue (val) {
      this.showUserRecentJobs = false
      if (val && val.length < 3) {
        this.open = false
      }
    },
    currentTab (val) {
      var self = this
      let temp = R.find(R.propEq('key', val), self.tabs)
      if (temp && temp.count > temp.data.length) {
        self.loadingQuickSearch = true
        Job.quickSearch(self.quickSearchVal, val, function (response) {
          temp.data = response
          self.loadingQuickSearch = false
        }, function (error) {
          console.log('quickSearch error:', error)
          self.loadingQuickSearch = false
        })
      }
    }
  },
  methods: {
    // matching (inString) {
    //   if (inString !== null) {
    //     if (inString && inString.length !== 0) {
    //       var temp = inString.toString().toUpperCase().split(this.quickSearchVal.toString().toUpperCase())
    //       if (typeof temp === 'undefined') {
    //       }
    //       var returnArray = []
    //       // now find out where the match is if there is one
    //       if (temp && temp.length === 1) {
    //         return [inString, '', '']
    //       }
    //       if (typeof temp !== 'undefined') {
    //         if (temp && temp.length === 2) {
    //           if (temp[0].length === 0) {
    //             returnArray = ['', this.quickSearchVal.toUpperCase(), temp[1]]
    //           } else if (temp[1].length === 0) {
    //             returnArray = [temp[0], this.quickSearchVal.toUpperCase(), '']
    //           } else {
    //             returnArray = [temp[0], this.quickSearchVal.toUpperCase(), temp[1]]
    //           }
    //           return returnArray
    //         }

    //         // If we hit this we have found multiple matches... we only need to highlight the first occurance
    //         let tempString = inString.toString().toUpperCase()
    //         let indexOfMatch = tempString.indexOf(this.quickSearchVal.toUpperCase())

    //         // have index of first match
    //         let preMatch = tempString.slice(0, indexOfMatch)
    //         let match = this.quickSearchVal.toUpperCase()// tempString.slice(indexOfMatch, this.quickSearchVal.length)
    //         var quickSearchValLength = this.quickSearchVal ? this.quickSearchVal.length : 0
    //         let postMatch = tempString.slice(indexOfMatch + quickSearchValLength)
    //         returnArray = [preMatch, match, postMatch]
    //         return returnArray
    //       } else {
    //         return ['', '', '']
    //       }
    //     } else {
    //       return ['', '', '']
    //     }
    //   } else {
    //     return ['', '', '']
    //   }
    // },
    revealAutocomplete () {
      let self = this
      setTimeout(function () {
        self.open = true
        self.current = 0
      }, 100)
    },
    enter () {
      this.$emit('selected', this.matches[this.current]['value'], this.matches[this.current].jobType)
      this.open = false
      this.internalSearchValue = ''
      // this.suggestionClick(this.current)
    },
    mouseOver: function (index) {
      this.current = index
    },
    up () {
      if (this.current > 0) {
        this.current--
      }
      try {
        this.$refs['autocompleteResults'].scrollTop = (this.$refs['autoComplete:' + this.current][0].offsetTop - (this.$refs['autocompleteResults'].clientHeight - (this.$refs['autoComplete:' + this.current][0].clientHeight * 2)))
      } catch (err) {}
    },
    focused () {
      var self = this
      if (!this.open) {
        this.open = true
        this.current = 0
      }

      // go get user recent jobs
      if (this.$store.state.user.recent_job_count > 0) {
        this.loadingUserRecentJobs = true
        if (!this.userConsumer) {
          UserRecentJob.getUserRecentJobs(this.$store.state.user.id, function (res) {
            if (res.data.error) {
              self.loadingUserRecentJobs = false
            } else {
              self.userRecentJobs = res.data
              self.loadingUserRecentJobs = false
              self.showUserRecentJobs = true
            }
          })
        } else {
          UserRecentJob.getUserRecentJobsConsumer(this.$store.state.user.id, function (res) {
            if (res.data.error) {
              self.loadingUserRecentJobs = false
            } else {
              self.userRecentJobs = res.data
              self.loadingUserRecentJobs = false
              self.showUserRecentJobs = true
            }
          })
        }
      }
    },
    down () {
      if (this.matches && this.current < this.matches.length - 1) {
        this.current++
      }
      try {
        this.$refs['autocompleteResults'].scrollTop = (this.$refs['autoComplete:' + this.current][0].offsetTop - this.$refs['autoComplete:' + this.current][0].clientHeight)
      } catch (err) {
      }
    },
    reset (evt) {
      var self = this
      if (!evt?.relatedTarget?.className.includes('el-tabs')) {
        if (this.recentJobsTimeout) {
          clearTimeout(this.recentJobsTimeout)
        }
        this.recentJobsTimeout = setTimeout(() => {
          self.open = false
          self.internalSearchValue = ''
          self.showUserRecentJobs = false
        }, 200)
      }
    },
    isActive (index) {
      return index === this.current
    },
    suggestionClick (index) {
      if (!this.showUserRecentJobs) {
        this.$emit('selected', this.matches[index]['value'], this.matches[index].jobType)
      } else {
        let selectedJobId = this.userRecentJobs[index].job_id
        this.$emit('selected', selectedJobId, 'job')
      }
      if (!this.showUserRecentJobs) {
        this.reset()
      }
    },
    async selected (id, type) {
      // if (type !== 'consumer') {
        this.$emit('selected', id, type) // handle this here instead of emitting
      // } else {
      //   const {data} = await this.$unum.consumer().get(`/data/${id}`)
      //   console.log('consumer data:', data)
      // }
      this.tabs = []
    },
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.reset()
      }
    },
    activateRow () {
      if (this.recentJobsTimeout && this.showUserRecentJobs) {
        clearTimeout(this.recentJobsTimeout)
      }
    },
    disableUserRecentJobs () {
      this.recentJobCount = 0
    },
    getTabColor (key) {
      if (key === 'jobs') return '#2c85b8'
      if (key === 'salesidejobs') return '#c9a3c9'
      if (key === 'consumers') return '#5ead9d'
    },
    onCreateNew () {
      console.log('onCreateNew')
    }
  },
  computed: {
    internalSearchValue: {
      get () {
        return this.quickSearchVal
      },
      set (val) {
        // resetting tabs
        this.tabs = []
        this.currentTab = 'jobs'
        clearTimeout(this.timeout)
        
        this.quickSearchVal = val

        if (val && val.length >= this.numCharBeforeSearch) {
          if (this.open === false) {
            this.open = true
            this.current = 0
          }
          
          // let's make a call to the backend
          var self = this
          let currentVal = val
          this.timeout = setTimeout(function () {
            if (val === currentVal) {
              self.loadingQuickSearch = true
              // get counts
              Job.quickSearchCount(val, function (response) {
                for (var i = 0; i < response.length; i++) {
                  if (response[i].count > 0) {
                    self.tabs.push({ ...self.tabsConfig[response[i].key], count: response[i].count })
                  }
                }
              }, function (error) {
                console.log('quickSearchCount error:', error)
                self.loadingQuickSearch = false
              })
              // get results
              Job.quickSearch(val, self.currentTab, function (response) {
                self.tabs.unshift({ ...self.tabsConfig['jobs'], count: response.length, data: response })
                self.loadingQuickSearch = false
              }, function (error) {
                console.log('quickSearch error:', error)
                self.loadingQuickSearch = false
              })
            }
          }, 600)
        } else {
          this.open = false
          this.loadingQuickSearch = false
        }
      }
    },
    openSuggestion () {
      return this.selection !== '' && this.matches && this.matches.length !== 0 && this.open === true
    },
    recentJobsToDisplay: {
      get () {
        let tempArray = []
        for (var i = 0; i < parseInt(this.recentJobCount); i++) {
          if (this.userRecentJobs[i]) {
            tempArray.push(this.userRecentJobs[i])
          }
        }
        return tempArray
      }
    },
    recentJobCount: {
      get () {
        return this.$store.state.user.recent_job_count
      },
      set (val) {
        var self = this
        if (val > 30) {
          val = 30
        }
        if (val < 0) {
          val = 0
        }
        if (self.userUpdateTimeout) {
          clearTimeout(self.userUpdateTimeout)
        }
        self.userUpdateTimeout = setTimeout(() => {
          self.$store.commit('updateUserRecentJobCount', val)
        }, 500)
      }
    },
    userConsumer: get('userConsumer'),
  },
  mounted () {
    var self = this
    this.$nextTick(() => { self.readonly = false })
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped lang="scss">
  @import "GlobalSearch.scss";
</style>
