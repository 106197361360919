<template>
  <div>
    <b-modal
      @show="dialogAboutToAppear"
      lazy
      title="Results"
      header-bg-variant='primary'
      header-text-variant='light'
      size="lg"
      id="quickNavResultsModal">
      <div fluid id="quickNavResultsBody">
        <b-card ref="quickNavWrapper" v-on:scroll.passive="onTableScroll" @scroll="onScroll" id="navResultsCard">
          <b-table striped hover :items="localResults" :fields="jobsByVinFields" stacked="md">
            <template slot="id" slot-scope="row">
              <a v-if="row.item.jobType === 'saleside'" href="javascript:void(0)" @click="openSalesidejobDialog(row.item.id)">{{row.item.id}}</a>
              <a v-else href="javascript:void(0)"  @click="navigateToJob(row.item.id)">{{row.item.id}}</a>
            </template>
            <template slot="contact_name" slot-scope="row">
              <p>{{row.item.contact_name}}</p>
            </template>
            <template v-if='filter === "VEHICLE"' slot="vehicle" slot-scope="row">
              {{vehicleFromFields(row.item)}}
            </template>
          </b-table>
        </b-card>

      </div>
      <div slot="modal-footer" class="w-100">
        <b-row>
          <b-col>
            <p style="color:black;">
              Results: {{results}}
              <span v-if='isFetching'>&nbsp;&nbsp;<v-icon spin name='spinner'/> loading more</span>
              <span v-if='noMore'>&nbsp;&nbsp;All Results Loaded</span>
            </p>
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="closeModal()"
            >
              Ok
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
import Job from '@/scripts/objects/job'

export default {
  data () {
    return {
      jobsByVinFields: [
        {
          key: 'id'
        },
        {
          key: 'status',
          label: 'Job Type'
        },
        {
          key: 'contact_name',
          label: 'Customer'
        }
      ],
      isFetching: false,
      noMore: false,
      localResults: [],
      localSearchVal: ''
    }
  },
  props: ['jobs', 'results', 'filter', 'searchVal'],
  methods: {
    dialogAboutToAppear: function () {
      if (this.filter === 'VIN #') {
        this.jobsByVinFields[3] = { key: 'vehicle_vin' }
      } else if (this.filter === 'REF #') {
        this.jobsByVinFields[3] = { key: 'referral_number' }
      } else if (this.filter === 'RO #') {
        this.jobsByVinFields[3] = {
          key: 'requisition_order_number',
          label: 'RO #'
        }
      } else if (this.filter === 'PO #') {
        this.jobsByVinFields[3] = {
          key: 'purchase_order_number',
          label: 'PO #'
        }
      } else if (this.filter === 'ADH #') {
        this.jobsByVinFields[3] = { key: 'stock_number' }
      } else if (this.filter === 'PLATE #') {
        this.jobsByVinFields[3] = {
          key: 'vehicle_plate_number',
          label: 'Plate #'
        }
      } else if (this.filter === 'UNIT #') {
        this.jobsByVinFields[3] = {
          key: 'unit_number',
          label: 'Unit #'
        }
      } else if (this.filter === 'VEHICLE') {
        this.jobsByVinFields[3] = {
          key: 'vehicle',
          label: 'Vehicle'
        }
        this.jobsByVinFields[4] = {
          key: 'customer_phone1',
          label: 'Phone'
        }
      }
      this.localResults = this.jobs
      this.isFetching = false
      this.noMore = false
      this.localSearchVal = this.searchVal
    },
    closeModal: function () {
      this.$root.$emit('bv::hide::modal', 'quickNavResultsModal')
    },
    vehicleFromFields: function (jobObj) {
      let tempString = ''
      if (jobObj.vehicle_year) {
        tempString += jobObj.vehicle_year + ' '
      }
      if (jobObj.vehicle_make_text) {
        tempString += jobObj.vehicle_make_text + ' '
      }
      if (jobObj.vehicle_model_text) {
        tempString += jobObj.vehicle_model_text + ' '
      }
      if (jobObj. vehicle_sub_model_text) {
        tempString += jobObj.vehicle_sub_model_text + ' '
      }
      if (jobObj.vehicle_style_text) {
        tempString += jobObj.vehicle_style_text + ' '
      }
      return tempString
    },
    onTableScroll () {
      // var scrollDifference = ((this.$refs.quickNavWrapper.scrollHeight - this.$refs.quickNavWrapper.clientHeight) - this.$refs.quickNavWrapper.scrollTop)
      // if (scrollDifference <= 2000 && !this.isFetching && !this.noMore) {
      //   console.log('fetching more')
      //   this.isFetching = true
      //   this.loadMoreItems()
      // }
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight && !this.isFetching && !this.noMore) {
        this.isFetching = true
        this.loadMoreItems()
      }
    },
    loadMoreItems: function () {
      var self = this
      // get offset
      Job.newSearch(self.searchVal, self.filter, self.localResults.length + 1, function (response) {
        if (response !== 'not found') {
          self.localResults = [...self.localResults, ...response]
        } else {
          self.noMore = true
        }
        self.isFetching = false
      })
    },
    openSalesidejobDialog: function (salesidejobId) {
      this.$root.$emit('showMobileJob', salesidejobId, false)
    },
    navigateToJob (jobId) {
      this.changeRouteWithCheck('/jobs/' + jobId)
      this.$root.$emit('bv::hide::modal', 'quickNavResultsModal')
    }
  },
  watch: {
    jobs: function (newVal) {
      this.localResults = newVal
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped>
#quickNavResultsBody {
  color: black
}

#navResultsCard {
  overflow-y: scroll;
  height: 70vh;
  max-height: 70vh;
}

div.card-body {
  padding: 0;
}
</style>
