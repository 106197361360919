var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "45px",
        height: "34px",
        viewBox: "0 0 45 34",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("title", [_vm._v("store-24px (1)")]),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _c("defs", [
        _c("path", {
          attrs: {
            d:
              "M36.8356287,1.72307692 C36.6444269,0.732307692 35.7733965,0 34.7536537,0 L4.24634632,0 C3.22660346,0 2.35557309,0.732307692 2.1643713,1.72307692 L0.0399070038,12.4923077 C-0.215028712,13.8276923 0.783469509,15.0769231 2.12188202,15.0769231 L2.50428559,15.0769231 L2.50428559,25.8461538 C2.50428559,27.0307692 3.46029453,28 4.62874989,28 L21.6244643,28 C22.7929197,28 23.7489286,27.0307692 23.7489286,25.8461538 L23.7489286,15.0769231 L32.2467858,15.0769231 L32.2467858,25.8461538 C32.2467858,27.0307692 33.2027947,28 34.3712501,28 C35.5397055,28 36.4957144,27.0307692 36.4957144,25.8461538 L36.4957144,15.0769231 L36.878118,15.0769231 C38.2165305,15.0769231 39.2150287,13.8276923 38.960093,12.4923077 L36.8356287,1.72307692 Z",
            id: "path-1"
          }
        }),
        _c(
          "filter",
          {
            attrs: {
              x: "-7.7%",
              y: "-10.7%",
              width: "120.5%",
              height: "128.6%",
              filterUnits: "objectBoundingBox",
              id: "filter-2"
            }
          },
          [
            _c("feMorphology", {
              attrs: {
                radius: "0.5",
                operator: "dilate",
                in: "SourceAlpha",
                result: "shadowSpreadOuter1"
              }
            }),
            _c("feOffset", {
              attrs: {
                dx: "1",
                dy: "1",
                in: "shadowSpreadOuter1",
                result: "shadowOffsetOuter1"
              }
            }),
            _c("feGaussianBlur", {
              attrs: {
                stdDeviation: "1",
                in: "shadowOffsetOuter1",
                result: "shadowBlurOuter1"
              }
            }),
            _c("feComposite", {
              attrs: {
                in: "shadowBlurOuter1",
                in2: "SourceAlpha",
                operator: "out",
                result: "shadowBlurOuter1"
              }
            }),
            _c("feColorMatrix", {
              attrs: {
                values: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0",
                type: "matrix",
                in: "shadowBlurOuter1"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "store-24px-(1)",
                transform: "translate(2.000000, 2.000000)",
                "fill-rule": "nonzero"
              }
            },
            [
              _c("g", { attrs: { id: "Shape" } }, [
                _c("use", {
                  attrs: {
                    fill: "black",
                    "fill-opacity": "1",
                    filter: "url(#filter-2)",
                    "xlink:href": "#path-1"
                  }
                }),
                _c("use", {
                  attrs: {
                    stroke: "#FFF",
                    "stroke-width": "1",
                    fill: "#0D47A1",
                    "xlink:href": "#path-1"
                  }
                })
              ]),
              _c("polygon", {
                attrs: {
                  id: "Path",
                  fill: "#FFFFFF",
                  "stroke-width": "0",
                  points: "20 23 7 23 7 15 20 15"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }