var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "jobDetailsClaimDialog",
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.visible,
        "append-to-body": "",
        id: "glaxisClaim",
        width: "1400px",
        "destroy-on-close": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.dialogAboutToAppear,
        close: _vm.dialogDisappeared
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "18px",
            "font-weight": "bold",
            "margin-bottom": "10px"
          }
        },
        [
          _c(
            "el-row",
            {
              staticClass: "claim-page-row top-cards-row",
              attrs: { gutter: 16, type: "flex" }
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "word-break": "break-word"
                  }
                },
                [_vm._v(" " + _vm._s("Review Coverage Response") + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.loading
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-md-12",
                staticStyle: { "font-size": "16px", "text-align": "center" }
              },
              [
                _c("p", [
                  _c("img", {
                    staticStyle: {
                      width: "18px",
                      height: "18px",
                      "margin-right": "6px"
                    },
                    attrs: {
                      src: require("../../../../assets/img/spinner.gif")
                    }
                  }),
                  _vm._v(" Loading please wait...")
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm.foundClaimData === null
        ? _c("div", { staticClass: "col-md-12 font-size-14 claimDialogArea" }, [
            _vm._v(" No Data Found ")
          ])
        : _c("div", { staticClass: "col-md-12 font-size-14 claimDialogArea" }, [
            _c(
              "div",
              [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-4" }, [
                    _c("label", [_vm._v("Reference Number")]),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.foundClaimData.coverageResponseData
                            .coverageResponse.reference_number
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "form-group col-md-8" }, [
                    _c("label", [_vm._v("Details")]),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.foundClaimData.coverageResponseData
                            .coverageResponse.error_text
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("br"),
                _c("p", [_vm._v("Vehicle Information")]),
                _vm._l(
                  _vm.foundClaimData.coverageResponseData
                    .coverageResponseVehicleInformation,
                  function(vehicleInformation, index) {
                    return _c("div", { key: index }, [
                      _c("div", { staticClass: "vehicleInformationBorder" }, [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c("label", [_vm._v("Vehicle Selection")]),
                            _c("br"),
                            _c(
                              "div",
                              { staticClass: "form-check form-check-inline" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedVehicle,
                                      expression: "selectedVehicle"
                                    }
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: index,
                                    checked: _vm._q(_vm.selectedVehicle, index)
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.selectedVehicle = index
                                    }
                                  }
                                }),
                                _vm._v(
                                  " Use This Vehicle With Loss Acknowledgement "
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c("label", [_vm._v("Description")]),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(vehicleInformation.vehicle_description) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c("label", [_vm._v("VIN (Last 4)")]),
                            _c("br"),
                            _vm._v(
                              " ****" +
                                _vm._s(vehicleInformation.vin_number) +
                                " "
                            )
                          ])
                        ]),
                        _c("br"),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c("label", [_vm._v("Id Number")]),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(vehicleInformation.vehicle_id_number) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c("label", [_vm._v("Id Source")]),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(vehicleInformation.vehicle_id_source) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c("label", [_vm._v("Vehicle Line Number")]),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(vehicleInformation.vehicle_line_number) +
                                " "
                            )
                          ])
                        ]),
                        _c("br"),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c("label", [_vm._v("Deductible")]),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(vehicleInformation.deductible) + " "
                            )
                          ]),
                          _c("div", { staticClass: "form-group col-md-8" }, [
                            _c("label", [_vm._v("Coverage Details")]),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(vehicleInformation.vehicle_error_text) +
                                " "
                            )
                          ])
                        ])
                      ]),
                      _c("br")
                    ])
                  }
                ),
                _c("p", [
                  _c("strong", [_vm._v("Loss Notice Acknowledgement")])
                ]),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-4" }, [
                    _c(
                      "label",
                      [
                        _c("strong", [_vm._v("Accept Work")]),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              "popper-class": "reviewCoverageToolTip",
                              content:
                                "Review the coverage and deductible information with the policyholder. If they agrees to the terms of the work, confirm acceptance of the work with this option. An electronic work assignment will follow from the insurance company or claims manager."
                            }
                          },
                          [_c("i", { staticClass: "el-icon-info t-text-xs" })]
                        )
                      ],
                      1
                    ),
                    _c("br"),
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("label", { staticClass: "form-check-label" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.acceptWork,
                              expression: "acceptWork"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "radio", value: "yes" },
                          domProps: { checked: _vm._q(_vm.acceptWork, "yes") },
                          on: {
                            change: function($event) {
                              _vm.acceptWork = "yes"
                            }
                          }
                        }),
                        _vm._v(" Yes ")
                      ])
                    ]),
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("label", { staticClass: "form-check-label" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.acceptWork,
                              expression: "acceptWork"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "radio", value: "no" },
                          domProps: { checked: _vm._q(_vm.acceptWork, "no") },
                          on: {
                            change: function($event) {
                              _vm.acceptWork = "no"
                            }
                          }
                        }),
                        _vm._v(" No ")
                      ])
                    ])
                  ]),
                  _vm.acceptWork === "yes"
                    ? _c("div", { staticClass: "form-group col-md-4" }, [
                        _c(
                          "label",
                          [
                            _vm._v("Acceptance Contact "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  "popper-class": "reviewCoverageToolTip",
                                  content:
                                    "Used by the insurance company or claims manager to track whom at the retail location accepted the work."
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-info t-text-xs"
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _c("br"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.acceptanceContact,
                              expression: "acceptanceContact"
                            }
                          ],
                          staticClass: "form-control form-control-sm",
                          attrs: { type: "text" },
                          domProps: { value: _vm.acceptanceContact },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.acceptanceContact = $event.target.value
                            }
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm.acceptWork === "yes"
                    ? _c("div", { staticClass: "form-group col-md-4" }, [
                        _c("label", [
                          _c("strong", [_vm._v("Mobile Indicator")])
                        ]),
                        _c("br"),
                        _c(
                          "div",
                          { staticClass: "form-check form-check-inline" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.mobileIndicator,
                                    expression: "mobileIndicator"
                                  }
                                ],
                                staticClass: "form-check-input",
                                attrs: { type: "radio", value: "yes" },
                                domProps: {
                                  checked: _vm._q(_vm.mobileIndicator, "yes")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.mobileIndicator = "yes"
                                  }
                                }
                              }),
                              _vm._v(" Yes ")
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "form-check form-check-inline" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.mobileIndicator,
                                    expression: "mobileIndicator"
                                  }
                                ],
                                staticClass: "form-check-input",
                                attrs: { type: "radio", value: "no" },
                                domProps: {
                                  checked: _vm._q(_vm.mobileIndicator, "no")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.mobileIndicator = "no"
                                  }
                                }
                              }),
                              _vm._v(" No ")
                            ])
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _c("br"),
                _vm.acceptWork === "yes"
                  ? _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-4" }, [
                        _c(
                          "label",
                          [
                            _c("strong", [_vm._v("Work Location Postal Code")]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  "popper-class": "reviewCoverageToolTip",
                                  content:
                                    "This data must be manually entered by the user for both in-shop and mobile jobs. By requiring the user to manually enter the zip code where the work is going to be performed, they are physically certifying the zip code that they intend to do the work."
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-info t-text-xs"
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _c("br"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.workLocationPostalCode,
                              expression: "workLocationPostalCode"
                            }
                          ],
                          staticClass: "form-control form-control-sm",
                          attrs: { type: "text" },
                          domProps: { value: _vm.workLocationPostalCode },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.workLocationPostalCode = $event.target.value
                            }
                          }
                        })
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4" },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c(
                                  "label",
                                  { attrs: { for: "installTimeStart" } },
                                  [_vm._v("Appointment Start Time")]
                                ),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.requestedAppointmentTime,
                                        expression: "requestedAppointmentTime"
                                      }
                                    ],
                                    staticClass:
                                      "form-control form-control-sm timeSelect",
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.requestedAppointmentTime = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      }
                                    }
                                  },
                                  _vm._l(_vm.startTimeOptions, function(
                                    time,
                                    index
                                  ) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        class: time.style,
                                        domProps: { value: time.value }
                                      },
                                      [_vm._v(_vm._s(time.name))]
                                    )
                                  }),
                                  0
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4" },
                        [
                          _c("label", { attrs: { for: "installDate" } }, [
                            _vm._v("Appointment Date")
                          ]),
                          _c("DatePickerShortcut", {
                            attrs: {
                              value: _vm.requestedAppointmentDate,
                              displayFormat: "MM/dd/yyyy",
                              saveFormat: "yyyy-MM-dd",
                              "autoSetToday:true": ""
                            },
                            on: {
                              "update:value": function($event) {
                                _vm.requestedAppointmentDate = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ],
              2
            ),
            !_vm.loading
              ? _c(
                  "div",
                  { staticClass: "t-py-3 t-flex" },
                  [
                    _vm.submittingLossAcknowledgement
                      ? _c("div", [
                          _c("img", {
                            staticStyle: { width: "20px" },
                            attrs: {
                              src: require("../../../../assets/img/spinner.gif")
                            }
                          }),
                          _vm._v(" Submitting Please Wait...")
                        ])
                      : _vm._e(),
                    _vm.foundClaimData !== null &&
                    !_vm.submittingLossAcknowledgement
                      ? _c(
                          "el-button",
                          {
                            staticClass: "submitLossAcknowledgementButton",
                            attrs: { type: "primary" },
                            on: { click: _vm.submitLossAcknowledgement }
                          },
                          [_vm._v(" Submit Loss Acknowledgement ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.confirmPopup, appendToBody: true },
          on: {
            "update:visible": function($event) {
              _vm.confirmPopup = $event
            }
          }
        },
        [
          _c("el-row", { staticClass: "dialog-header-text" }, [
            _vm._v(" Submit Loss Notice Acknowledgement? ")
          ]),
          _c("br"),
          _c("el-row", { staticClass: "dialog-body-text" }, [
            _vm._v(
              "Are you sure you would like to submit this Loss Notice Acknowledgement?"
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.finishSubmitLossNoticeAcknowledgement()
                    }
                  }
                },
                [_vm._v("Yes")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.confirmPopup = false
                    }
                  }
                },
                [_vm._v("No")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.alertPopup, appendToBody: true },
          on: {
            close: _vm.closeAlertPopupEvent,
            "update:visible": function($event) {
              _vm.alertPopup = $event
            }
          }
        },
        [
          _c("el-row", { staticClass: "dialog-body-text" }, [
            _vm._v(_vm._s(_vm.alertPopupText))
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.alertPopup = false
                    }
                  }
                },
                [_vm._v("Ok")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }