import { make } from 'vuex-pathify'
import Shop from '@/scripts/objects/shop'

const state = {
  showHidden: false,
  sortBy: 'id',
  order: 'DESC'
}

const mutations = make.mutations(state)

const getters = {
}

const actions = {
  setShowHidden ({ commit }, showHidden) {
    commit('SET_SHOW_HIDDEN', showHidden)
  },
  setSortBy ({commit}, val) {
    commit('SET_SORT_BY', val)
  },
  setOrder ({commit}, val) {
    commit('SET_ORDER', val)
  },
  updateShopHidden ({ commit }, {hidden, shopId}) {

  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}