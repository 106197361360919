<template>
  <div>
    <span slot="label" class="undo-label-styles">Warehouses</span>
    <table class="t-border-collapse">
      <thead>
      <tr>
        <td class="t-border t-border-solid t-border-gray-200 t-px-4">
          Selected
        </td>
        <td class="t-border t-border-solid t-border-gray-200 t-px-4">
          Warehouse Id
        </td>
        <td class="t-border t-border-solid t-border-gray-200 t-px-4">
          Warehouse Name
        </td>
        <td class="t-border t-border-solid t-border-gray-200 t-px-4">
          Default
        </td>
      </tr>
      </thead>
      <tbody>
        <tr
          v-for="i in allWarehouses"
          :key="i.id"
          class=""
        >
          <td class="t-border t-border-solid t-border-gray-200 t-text-center">
            <el-checkbox
              v-model="selected"
              :label="i.id"
              @change="checkboxChanged($event, i.id)"
            />
          </td>
          <td class="t-border t-border-solid t-border-gray-200 t-text-center">
            {{ i.glaxis_warehouse_id }}
          </td>
          <td class="t-border t-border-solid t-border-gray-200 t-text-center">
            {{ i.name }}
          </td>
          <td class="t-border t-border-solid t-border-gray-200 t-text-center">
            <el-radio
              v-if="selected.includes(i.id)"
              v-model="selectedDefault"
              :label="i.id"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['allWarehouses', 'selectedWarehouses', 'defaultWarehouse'],
  methods: {
    checkboxChanged: function(value, warehouseId) {
      if (!value && this.selectedDefault === warehouseId) {
        this.$nextTick(() => {
          this.selectedDefault = this.selected?.[0] || ''
        })
      }
    }
  },
  computed: {
    selected: {
      get() {
        return this.selectedWarehouses
      },
      set(v) {
        this.$emit('update:selectedWarehouses', v)
      }
    },
    selectedDefault: {
      get() {
        return this.defaultWarehouse
      },
      set(v) {
        this.$emit('update:defaultWarehouse', v)
      }
    }
  }
}
</script>

<style>

</style>
