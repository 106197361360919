var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header-v2-search" },
        [
          _vm.isMobile
            ? _c(
                "div",
                {
                  staticClass: "header-v2-search-mobile",
                  on: {
                    click: function($event) {
                      _vm.open = true
                    }
                  }
                },
                [_c("i", { staticClass: "material-icons md-search" })]
              )
            : _c("el-input", {
                attrs: {
                  placeholder: "Search",
                  "prefix-icon": "el-icon-search"
                },
                on: {
                  focus: function($event) {
                    _vm.open = true
                  }
                }
              }),
          _vm.open
            ? _c("global-search-dialog", {
                on: {
                  close: function($event) {
                    _vm.open = false
                  },
                  select: _vm.select
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("consumer-dialog", {
        attrs: { consumer: _vm.consumer, "append-to-body": "" },
        model: {
          value: _vm.consumerOpen,
          callback: function($$v) {
            _vm.consumerOpen = $$v
          },
          expression: "consumerOpen"
        }
      }),
      _c("draft-warning-dialog", {
        attrs: { visible: _vm.draftWarningVisible },
        on: {
          "update:visible": function($event) {
            _vm.draftWarningVisible = $event
          },
          discardDraft: _vm.discardDraft,
          leaveDraft: _vm.leaveDraft,
          publishDraft: _vm.publishDraft
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }