var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-autocomplete", {
        class: { full: _vm.full },
        attrs: {
          placeholder: "Enter address",
          "fetch-suggestions": _vm.fetchSuggestions,
          "popper-append-to-body": false,
          "trigger-on-focus": false
        },
        on: { select: _vm.handleSelect },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("places-autocomplete-block", {
                  attrs: { result: item.structured_formatting }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _c("gmap-autocomplete", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }