<template>
  <button
      @click="openDialog"
      :disabled="!part"
      :class="!part ? 't-cursor-not-allowed' : ''"
      class="t-font-sans t-border-none t-rounded-full t-mt-1 t-text-085
             t-font-medium t-whitespace-no-wrap t-cursor-pointer t-bg-orange-300"
    >
      <span v-if="part">
        <span
          class="t-bg-white t-text-red-600 t-rounded-full t-px-1">
          QTY:{{ part.can_order === 'IN STOCK' ? '1' : '0' }}
        </span>
        <span
          v-if="part.item_price"
          class="t-ml-1">
          ${{ Math.round(part.item_price * 100) / 100 }}
        </span>
        <span
          v-if="part.item_price > 0 && priceFromLevels"
          class="t-text-purple-800 t-ml-1">
          ${{ priceFromLevels }}
        </span>
      </span>
      <span v-else class="t-mx-2 t-text-gray-800 t-font-normal">
        No results
      </span>
    </button>
</template>

<script>
export default {
  props: [
    'part',
    'vendor',
    'partNumber',
    'priceFromLevels'
  ],
  methods: {
    openDialog () {
      this.$store.commit('partVendors/setSearchResultsVisible', {
        vendor: this.vendor,
        partNumber: this.partNumber,
        visible: true
      })
    }
  }
}
</script>
