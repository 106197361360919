<template>
  <div>
    <primary-fields-selector
      v-for="item in schema"
      :key="item.title"
      v-bind="item"
      :value="getValue(item.listKey, item.primaryKey, item.secondaryKey)"
      @input="setValue($event, item.listKey, item.primaryKey, item.secondaryKey)"
      ref="fields"
    />
  </div>
</template>

<script>
import PrimaryFieldsSelector from '@/components/Helpers/PrimaryFieldsSelector'
import * as R from 'ramda'
import {
  addressValidator,
  emailPattern,
  phonePattern
} from '@/constants/form.config'

export default {
  components: {
    PrimaryFieldsSelector
  },
  props: ['value'],
  computed: {
    schema() {
      return [
        {
          title: 'Contact names',
          listKey: 'names',
          primaryKey: 'name',
          span: 24,
          schema: [
            [
              {
                id: 'first',
                label: 'First name',
                rule: {
                  required: true,
                  message: 'First name is required'
                },
                options: {
                  capitalize: true
                }
              },
              {
                id: 'last',
                label: 'Last Name',
                options: {
                  capitalize: true
                }
              }
            ]
          ]
        },
        {
          title: 'Phone number',
          listKey: 'phones',
          type: 'phone',
          primaryKey: 'phone',
          secondaryKey: 'secondary_phone',
          schema: [
            [
              {
                id: 'number',
                type: 'phone',
                label: 'Phone',
                rule: [
                  {
                    required: true,
                    message: 'Phone is required'
                  },
                  {
                    pattern: phonePattern,
                    message: 'Invalid phone'
                  }
                ]
              }
            ]
          ]
        },
        {
          title: 'Email',
          listKey: 'emails',
          primaryKey: 'email',
          schema: [
            [
              {
                id: 'email',
                label: 'Email',
                rule: [
                  {
                    required: true,
                    message: 'Email is required'
                  },
                  {
                    pattern: emailPattern,
                    message: 'Invalid email'
                  }
                ]
              }
            ]
          ]
        },
        {
          title: 'Address',
          listKey: 'addresses',
          primaryKey: 'address',
          span: 24,
          schema: [
            [
              {
                type: 'address',
                id: 'address',
                noKey: true,
                label: 'Address',
                options: {
                  withPoBox: true,
                  withExtra: true
                },
                rule: {
                  validator: addressValidator,
                  message: 'Select address from a dropdown'
                }
              }
            ]
          ]
        }
      ]
    }
  },
  methods: {
    getValue(listKey, primaryKey, secondaryKey) {
      return {
        list: R.propOr([], listKey)(this.value),
        primary: R.propOr({}, primaryKey)(this.value),
        secondary: R.propOr({}, secondaryKey)(this.value)
      }
    },
    setValue({ list, primary, secondary }, listKey, primaryKey, secondaryKey) {
      this.$emit('input', {
        ...this.value,
        [primaryKey]: primary,
        [listKey]: list,
        ...(secondaryKey?{[secondaryKey]: secondary}: {})
      })
    },
    async validate() {
      const refs = R.pipe(
        R.pathOr([], ['$refs', 'fields']),
        R.map(R.pathOr([], ['$refs', 'forms'])),
        R.flatten,
        R.map(R.invoker(0, 'validateAll'))
      )(this)
      try {
        return R.all(R.identity)(await Promise.all(refs))
      } catch (e) {
        return false
      }
    }
  }
}
</script>

<style>
</style>
