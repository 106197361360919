<template>
  <div>
    <el-divider class="t-my-6" />

    <div class="t-text-gray-400 t-mb-4">Default Sales Rep</div>

    <div>
      <el-select
        v-model="default_salesrep_id"
        clearable
        filterable
        placeholder="Select Default Sales Rep"
        @change="updateDefaultSalesRepHandler"
      >
        <el-option
          v-for="i in sales_reps"
          :key="i.id"
          :label="i.name"
          :value="i.id"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import { VendorsAPI } from '@/services/VendorsAPI'

export default {
  data() {
    return {
      default_salesrep_id: null,
      sales_reps: []
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data'])
  },

  async mounted() {
    this.default_salesrep_id = this.data.default_salesrep_id
    this.sales_reps = await VendorsAPI.get(
      `organization/${this.data.organization_id}/salesreps/dictionary`
    )
  },

  methods: {
    ...mapActions(useVendorStore, ['updateDefaultSalesRep']),

    async updateDefaultSalesRepHandler(val) {
      try {
        await this.updateDefaultSalesRep(val)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>
