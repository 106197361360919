<template>
  <svg
    width="23px"
    height="23px"
    viewBox="0 0 23 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        x="-44.1%"
        y="-44.1%"
        width="188.2%"
        height="188.2%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset
          dx="1"
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.562581949 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Group-2" transform="translate(-63.000000, 0.000000)">
        <g
          id="stop-solid"
          filter="url(#filter-1)"
          transform="translate(65.000000, 2.000000)"
        >
          <path
            d="M15.1785714,0 L1.82142857,0 C0.815848214,0 0,0.815848214 0,1.82142857 L0,15.1785714 C0,16.1841518 0.815848214,17 1.82142857,17 L15.1785714,17 C16.1841518,17 17,16.1841518 17,15.1785714 L17,1.82142857 C17,0.815848214 16.1841518,0 15.1785714,0 Z"
            id="Path"
            stroke="#FFF"
            :fill="fillColor"
            fill-rule="nonzero"
            stroke-linecap="round"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['fillColor', 'text'],
  computed: {}
}
</script>
