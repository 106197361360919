var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        title: "Notifications",
        visible: _vm.isOpened,
        "before-close": _vm.close,
        "show-close": true,
        "custom-class": "notifications-dialog"
      }
    },
    [
      _c(
        "div",
        { staticClass: "notifications-container" },
        [
          _c(
            "div",
            {
              staticClass:
                "settings-icon icon-active-wrapper d-flex flex-column justify-content-center",
              on: { click: _vm.openSettings }
            },
            [
              _c("div", { staticClass: "gb-icon-gray-button" }, [
                _c("i", {
                  staticClass:
                    "material-icons-outline md-settings fix-icon-settings"
                })
              ])
            ]
          ),
          _c(
            "el-tabs",
            {
              attrs: {
                value: _vm.activeTab,
                "before-leave": _vm.changeTabTrigger
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "New", name: _vm.tabs.unread.type } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-items-center tab-header-unread"
                    },
                    [
                      _c(
                        "div",
                        { ref: "counter", staticClass: "unread-count" },
                        [_vm._v(" " + _vm._s(_vm.initialCountText) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "d-flex align-items-center read-all",
                              attrs: {
                                type: "text",
                                icon: "md-done_all material-icons-round",
                                disabled: _vm.readAllDisabled,
                                loading: _vm.readAllLoader
                              },
                              on: { click: _vm.readAll }
                            },
                            [_vm._v("Archive All")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "notifications-list-unread" },
                    [
                      _c(
                        "notifications-list",
                        {
                          attrs: {
                            type: _vm.tabs.unread.type,
                            rows: _vm.tabs.unread.rows
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "no-more" }, slot: "no-more" },
                            [_vm._v("No more notifications!")]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "no-results" },
                              slot: "no-results"
                            },
                            [_vm._v("No new notifications!")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tab-footer" }, [_c("div")])
                ]
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Archived",
                    name: _vm.tabs.read.type,
                    lazy: true
                  }
                },
                [
                  _c("div", {
                    staticClass: "d-flex align-items-center tab-header-read"
                  }),
                  _c(
                    "div",
                    { staticClass: "notifications-list-read" },
                    [
                      _c(
                        "notifications-list",
                        {
                          attrs: {
                            type: _vm.tabs.read.type,
                            rows: _vm.tabs.read.rows
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "no-more" }, slot: "no-more" },
                            [_vm._v("No more notifications!")]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "no-results" },
                              slot: "no-results"
                            },
                            [_vm._v("No notifications in archive!")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tab-footer" }, [_c("div")])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }