var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "80px",
        width: "685px",
        "custom-class": "qbo-account-mapping-dialog",
        visible: _vm.visible,
        "before-close": _vm.close,
        "close-on-click-modal": false
      },
      on: { open: _vm.getAllQboDefaultsForShop }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fetching,
              expression: "fetching"
            }
          ],
          staticClass: "t-flex-grow t-min-w-0"
        },
        [
          _c("Header"),
          _c("Body", {
            attrs: { shopHasCardProcessor: _vm.shopHasCardProcessor }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }