<template>
  <svg
    width="40px"
    height="37px"
    viewBox="0 0 40 37"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
    <title>house-user-solid</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M33.6865625,14.3054102 L30.2222222,11.1672656 L30.2222222,2.90625 C30.2222222,2.37122415 29.79938,1.9375 29.2777778,1.9375 L25.5,1.9375 C24.9783977,1.9375 24.5555556,2.37122415 24.5555556,2.90625 L24.5555556,6.03470703 L18.5807639,0.623632813 C18.2100694,0.279121094 17.5035069,0 17,0 C16.4964931,0 15.7922917,0.279121094 15.4215972,0.623632813 L0.310486111,14.3072266 C0.124301884,14.5001786 0.0137936798,14.7566218 0,15.0277344 C0.0120634564,15.2645343 0.0965345226,15.4914212 0.241423611,15.6761914 L1.50520833,17.1166016 C1.70217118,17.2979066 1.95022972,17.4102854 2.21354167,17.4375 C2.4418278,17.4140964 2.65977566,17.3281452 2.84454861,17.1886523 L3.78309028,16.3409961 L3.78309028,29.0625 C3.78309028,30.1325517 4.62877464,31 5.67197917,31 L28.3333333,31 C29.3765379,31 30.2222222,30.1325517 30.2222222,29.0625 L30.2222222,16.3403906 L31.1613542,17.1880469 C31.3469458,17.3273525 31.5653544,17.4134534 31.7941319,17.4375 C32.0550835,17.4095217 32.3005324,17.2968954 32.4947917,17.1159961 L33.7585764,15.674375 C33.8942253,15.4847619 33.9776169,15.2611928 34,15.0271289 C33.9728905,14.7585186 33.8631094,14.5057375 33.6865625,14.3054102 L33.6865625,14.3054102 Z"
        id="path-1"
      ></path>
      <filter
        x="-8.8%"
        y="-9.7%"
        width="123.5%"
        height="125.8%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feMorphology
          radius="0.5"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="1"
          dy="1"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="house-user-solid"
        transform="translate(2.000000, 2.000000)"
        fill-rule="nonzero"
      >
        <g id="Shape">
          <use
            fill="black"
            fill-opacity="1"
            filter="url(#filter-2)"
            xlink:href="#path-1"
          ></use>
          <use
            stroke="#FFF"
            stroke-width="1"
            :fill="fillColor || '#FF9393'"
            xlink:href="#path-1"
          ></use>
        </g>
        <path
          d="M17,10.65625 C19.0864091,10.65625 20.7777778,12.3911466 20.7777778,14.53125 C20.7777778,16.6713534 19.0864091,18.40625 17,18.40625 C14.9135909,18.40625 13.2222222,16.6713534 13.2222222,14.53125 C13.2222222,12.3911466 14.9135909,10.65625 17,10.65625 Z"
          id="Path"
          strokeWidth="0"
          fill="#FFF"
        ></path>
        <path
          d="M23.6111111,27.125 L10.3888889,27.125 C9.86728663,27.125 9.44444444,26.6912759 9.44444444,26.15625 C9.44444444,22.9460949 11.9814975,20.34375 15.1111111,20.34375 L18.8888889,20.34375 C22.0185025,20.34375 24.5555556,22.9460949 24.5555556,26.15625 C24.5555556,26.6912759 24.1327134,27.125 23.6111111,27.125 Z"
          id="Path"
          strokeWidth="0"
          fill="#FFF"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['fillColor'],
  computed: {}
}
</script>
