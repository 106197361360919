var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.paymentHistory.length > 0
    ? _c(
        "el-timeline",
        { staticStyle: { margin: "10px 0 0 100px" } },
        _vm._l(_vm.paymentHistory, function(activity, index) {
          return _c(
            "el-timeline-item",
            {
              key: index,
              attrs: {
                icon: activity.icon,
                type: activity.type,
                size: activity.size
              }
            },
            [
              _vm._v(" " + _vm._s(activity.name) + " "),
              _c("div", { staticClass: "timestamp" }, [
                _c("span", { staticClass: "username" }, [
                  _vm._v(
                    _vm._s(
                      activity.username ? "@" + activity.username + " " : ""
                    )
                  )
                ]),
                _vm._v(_vm._s(activity.timestamp) + " ")
              ])
            ]
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }