var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown-item",
    {
      attrs: {
        command: { id: _vm.jobId, jobtags: _vm.jobtags.concat([_vm.newTag]) }
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "tag-sm",
          style: {
            color: _vm.blackOrWhite(_vm.tag.color),
            background: _vm.tag.color
          }
        },
        [_vm._v(" " + _vm._s(_vm.tag.text) + " ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }