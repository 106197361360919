var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm._t("default"),
      _c(
        "el-dialog",
        {
          staticClass: "filestack-picker",
          attrs: {
            visible: _vm.open,
            title:
              "Upload Document" + (_vm.docLabel ? " - " + _vm.docLabel : ""),
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.open = $event
            },
            opened: _vm.opened,
            closed: _vm.closed
          }
        },
        [
          _vm._t("extra"),
          _c("div", { ref: "picker", staticClass: "picker-container" })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }