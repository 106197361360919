<template>
  <div class="consumer-new"><gb-form :schema="schema" v-model="data" /></div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    schema() {
      return [
        ...(this.value.is_commercial || this.value.is_insurance
          ? [
            [
              {
                label: 'Company Name *',
                id: 'company_name',
                rule: {
                  required: true,
                  message: 'Company name is required'
                }
              }
            ]
          ]
          : [])
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.consumer-new {
}
</style>
