<template>
  <div>
    <b-modal
      lazy
      title="Fax"
      header-bg-variant='primary'
      header-text-variant='light'
      size="lg"
      @show="dialogAboutToAppear"
      @shown="dialogAppeared"
      id="faxInboxModal"
      hide-footer>
      <div @dragenter.stop.prevent="faxDragenter" @dragleave.stop.prevent="faxDragleave"
        @dragover.stop.prevent="faxDragover" @drop.stop.prevent="faxDrop" :class="{row: true, 'pb-2': true, 'pt-2':true, faxDragDrop:amDragging}">
        <div class="col-lg-2">
          <FilePicker :buttonLabel="'Choose File'" :uploadingDropFile.sync="uploadingDropFile" :filePickerKey="filePickerKey" :dialogToReturnTo="'faxInboxModal'" :handleFilePickerResponse="handleFilePickerResponse" :dropListenerName="'faxInboxFilePickerDrop'"/>
          ... or drag & drop
        </div>
        <div class="col-lg-3">
          <input :value="uploadingDropFile ? 'Preparing File...' : uploadFaxName != null && uploadFaxName.length > 0 ? uploadFaxName : 'File Name...'" type="text"
            placeholder="File Name..." :class="{'form-control': true, 'form-control-sm': true, 'uploadingDropFile': uploadingDropFile}" disabled/>
        </div>
        <div class="col-lg-1">
        </div>
        <div class="col-lg-3">
          <input v-if="selectedInbox !== 'burst'" v-model="faxNumberToSendTo" type="text" placeholder="Fax Number..." class="form-control form-control-sm"/>
        </div>
        <div class="col-lg-2">
          <button v-if="selectedInbox === 'fax'" type="button" variant="primary" class="float-right btn btn-primary" @click="sendFax()">Send Fax</button>
          <button v-if="selectedInbox === 'burst' && !burstIsBusy" type="button" variant="primary" class="float-right btn btn-primary" @click="localBurst()">Burst</button>
          <span v-else-if="selectedInbox === 'burst' && burstIsBusy" style="padding:10px; float: right;" class="faxInboxActionButton"><v-icon name="spinner" style='color: black' spin scale="2"/></span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <span class="tag" :class="{ activeInbox: selectedInbox === 'fax' }" style="text-align:center">
            Fax Inbox: {{inboxTotalRows}}
          </span>
          <toggle-button color="#bfcbd9" @change="toggleSelectedInbox" :value="isBurstInboxSelected" :sync="true"/>
          <div class="tag" :class="{ activeInbox: selectedInbox === 'burst' }" style="text-align:center">
            Burst Inbox: {{burstTotalRows}}
          </div>
        </div>
      </div>
      <div v-if="selectedInbox === 'fax'" class="row mb-2">
        <div class="col-6">
          Inbox <input v-model="currentTable" value="inbox" type="radio"/>&nbsp;&nbsp;&nbsp;&nbsp;
          Outbox <input v-model="currentTable" value="outbox" type="radio"/>
        </div>
      </div>

      <div v-show="currentTable === 'inbox'">
        <div class="tableHolder row">
          <div v-if="pdfPreviewItem !== null" class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <strong>{{pdfPreviewItem.dateTime}}</strong>
              </div>
              <div class="col-md-6">
                <a class="float-right" @click="closePdfPreview()" href="javascript:void(0)"><font-awesome-icon style="color:red" :icon="faTimesCircle"/></a>
              </div>
            </div>
            <iframe :src="pdfPreviewItem !== null ? pdfPreviewItem.url : ''" type="application/pdf" style="width: 100%; height: 100%; min-height:600px;"></iframe>
          </div>
          <div :class="{'form-group': true, 'col-md-6': pdfPreviewItem !== null, 'col-md-12': pdfPreviewItem === null}">
            <div v-if="selectedFax !== null" style="margin-bottom: 50px;">
              <AttachFaxDialog :fax="selectedFax" v-on:close-attach="closeAttachPanel"/>
            </div>
            <b-table v-if="selectedInbox === 'fax'" style="margin-bottom:0px;" ref="faxInboxTable"
                stacked="md"
                :items="inboxProvider"
                :fields="inboxFields"
                :current-page="inboxCurrentPage"
                :per-page="inboxPerPage"
                :sort-by.sync="inboxSortBy"
                :sort-desc.sync="inboxSortDesc"
                :busy.sync="inboxIsBusy"
                :show-empty="true"
                :empty-text="inboxIsBusy ? 'Loading, please wait...' : 'There are no records to show'"
                :empty-filtered-text="inboxIsBusy ? 'Loading, please wait...' : 'There are no records matching your request'"
                class="text-left"
                >
              <template slot="HEAD_dateTime" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="inboxDateTimeFilter" placeholder="Date/Time" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_sentFrom" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="inboxSentFromFilter" placeholder="Sent From" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_sentTo" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="inboxSentToFilter" placeholder="Sent To" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_pages" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="inboxPagesFilter" placeholder="Pages" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_referralNotes" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="inboxReferralNotesFilter" placeholder="Referral/Notes" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_status" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="inboxStatusFilter" placeholder="Status" class="form-control form-control-sm"/>
              </template>

              <template slot="referralNotes" slot-scope="row">
                <input :ref="'referralNotes' + row.index" type="text" class="form-control form-control-sm" placeholder="Enter Referral/Notes"
                @blur="updateReferralNotes('referralNotes' + row.index, row.item.faxId)" :value="row.item.referralNotes"
                @focus="focusReferralNotes(row.item)"/>
              </template>

              <template slot="select" slot-scope="row">
                <input v-if="selectedInbox === 'fax'" v-model="selectedFaxes" :value="row.item" type="checkbox" />
                <input v-if="selectedInbox === 'burst'" v-model="selectedBursts" :value="row.item" type="checkbox" />
              </template>

              <template slot="actions" slot-scope="row">
                <a @click="inboxDownloadButtonPressed(row.item.faxId)" :href="row.item.url" target="_blank" class="faxInboxActionButton" style="padding:10px;" title="Download"><i style="color:lightgreen" class="fa fa-cloud-download"></i></a>
                <a v-if="selectedInbox === 'fax' && !burstArray.includes(row.item.faxId)" @click="inboxBurstButtonPressed(row.item.faxId)" class="faxInboxActionButton" style="padding:10px;" title="Burst" href="javascript:void(0)"><i style="color:dodgerblue" class="fa fa-scissors"></i></a>
                <span v-else-if="selectedInbox === 'fax' && burstArray.includes(row.item.faxId)" class="faxInboxActionButton" style="padding:10px;"><v-icon name="spinner" style='color: black' spin scale="1"/></span>
                <a @click="inboxAttachButtonPressed(row.item)" class="faxInboxActionButton" style="padding:10px;" title="Attach To Job" href="javascript:void(0)"><i style="color:darkgray" class="fa fa-paperclip"></i></a>
                <a v-if="selectedInbox === 'fax'" @click="inboxForwardButtonPressed()" class="faxInboxActionButton" style="padding:10px;cursor:default;" title="Forward" href="javascript:void(0)"><i style="color:darkgreen" class="fa fa-arrow-right"></i></a>
                <a v-if="selectedInbox === 'fax'" @click="inboxDeleteButtonPressed(row.item.faxId, row.item.dateTime)" class="faxInboxActionButton" style="padding:10px;" title="Delete" href="javascript:void(0)"><i style="color:red" class="fa fa-times-circle"></i></a>
                <a v-if="selectedInbox === 'burst'" @click="burstDeleteButtonPressed(row.item.burstId)" class="faxInboxActionButton" style="padding:10px;" title="Delete" href="javascript:void(0)"><i style="color:red" class="fa fa-times-circle"></i></a>
              </template>
            </b-table>
            <b-table v-if="selectedInbox === 'burst'" style="margin-bottom:0px;" ref="faxBurstTable"
                stacked="md"
                :items="burstProvider"
                :fields="burstFields"
                :current-page="burstCurrentPage"
                :per-page="burstPerPage"
                :sort-by.sync="burstSortBy"
                :sort-desc.sync="burstSortDesc"
                :busy.sync="burstIsBusy"
                :show-empty="true"
                :empty-text="burstIsBusy ? 'Loading, please wait...' : 'There are no records to show'"
                :empty-filtered-text="burstIsBusy ? 'Loading, please wait...' : 'There are no records matching your request'"
                class="text-left"
                >
              <template slot="HEAD_dateTime" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="inboxDateTimeFilter" placeholder="Date/Time" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_referralNotes" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="inboxReferralNotesFilter" placeholder="Referral/Notes" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_status" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="inboxStatusFilter" placeholder="Status" class="form-control form-control-sm"/>
              </template>

              <template slot="referralNotes" slot-scope="row">
                <input :ref="'referralNotes' + row.index" type="text" class="form-control form-control-sm" placeholder="Enter Referral/Notes"
                @blur="updateReferralNotes('referralNotes' + row.index, row.item.faxId)" :value="row.item.referralNotes"
                @focus="focusReferralNotes(row.item)"/>
              </template>

              <template slot="select" slot-scope="row">
                <input v-if="selectedInbox === 'fax'" v-model="selectedFaxes" :value="row.item" type="checkbox" />
                <input v-if="selectedInbox === 'burst'" v-model="selectedBursts" :value="row.item" type="checkbox" />
              </template>

              <template slot="actions" slot-scope="row">
                <a @click="inboxDownloadButtonPressed(row.item.faxId)" :href="row.item.url" target="_blank" class="faxInboxActionButton" style="padding:10px;" title="Download"><i style="color:lightgreen" class="fa fa-cloud-download"></i></a>
                <a @click="inboxAttachButtonPressed(row.item)" class="faxInboxActionButton" style="padding:10px;" title="Attach To Job" href="javascript:void(0)"><i style="color:darkgray" class="fa fa-paperclip"></i></a>
                <a v-if="selectedInbox === 'fax'" @click="inboxDeleteButtonPressed(row.item.faxId, row.item.dateTime)" class="faxInboxActionButton" style="padding:10px;" title="Delete" href="javascript:void(0)"><i style="color:red" class="fa fa-times-circle"></i></a>
                <a v-if="selectedInbox === 'burst'" @click="burstDeleteButtonPressed(row.item.burstId)" class="faxInboxActionButton" style="padding:10px;" title="Delete" href="javascript:void(0)"><i style="color:red" class="fa fa-times-circle"></i></a>
              </template>
            </b-table>
          </div>
        </div>
        <b-row style="margin-bottom:10px;" v-show="currentTable === 'inbox'">
          <b-col>
            <div v-if="selectedInbox !== 'burst'" class="input-group mt-1 mt-lg-0">
              <div class="input-group-append" style="margin-right:10px;margin-top:12px">
                <strong>With Selected: </strong>
              </div>
              <div class="input-group-append" style="margin-right:10px;margin-top:9px">
                <select style="width:250px;" v-model="selectedStatusToChangeTo" @change="selectedStatusChanged()" class="form-control form-control-sm">
                  <option value="">Choose Status...</option>
                  <option value="unread">Unread</option>
                  <option value="read">Read</option>
                  <option value="burst">Burst</option>
                </select>
              </div>
              <div class="input-group-append">
                <a v-if="selectedInbox !== 'burst'" @click="selectedBurstFaxes()" class="faxInboxActionButton" style="padding:10px;" title="Burst all selected faxes" href="javascript:void(0)"><i style="color:dodgerblue" class="fa fa-scissors"></i></a>
                <a v-if="selectedInbox === 'fax'" @click="selectedDeleteFaxes()" class="faxInboxActionButton" style="padding:10px;" title="Delete all selected faxes" href="javascript:void(0)"><i style="color:red" class="fa fa-times-circle"></i></a>
              </div>
            </div>
            <div v-else>
              <b-button :disabled="selectedBursts.length === 0" variant="danger" @click="selectedDeleteBursts()" class="mt-1">Delete Selected</b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <span>{{ inboxTableResultsText }}</span>
          </b-col>
          <b-col md="6">
            <b-pagination v-if="selectedInbox === 'fax' && currentTable === 'inbox'" :total-rows="inboxTotalRows" :per-page="inboxPerPage" v-model="inboxCurrentPage" align="right"/>
            <b-pagination v-if="selectedInbox === 'burst'" :total-rows="burstTotalRows" :per-page="burstPerPage" v-model="burstCurrentPage" align="right"/>
            <b-pagination v-if="selectedInbox === 'fax' && currentTable === 'outbox'" :total-rows="outboxTotalRows" :per-page="outboxPerPage" v-model="outboxCurrentPage" align="right"/>
          </b-col>
        </b-row>
      </div>

      <div v-show="currentTable === 'outbox'">
        <div class="tableHolder row">
          <div class="form-group col-12">
            <b-table  ref="faxOutboxTable"
                stacked="md"
                :items="outboxProvider"
                :fields="outboxFields"
                :current-page="outboxCurrentPage"
                :per-page="outboxPerPage"
                :sort-by.sync="outboxSortBy"
                :sort-desc.sync="outboxSortDesc"
                :busy.sync="outboxIsBusy"
                :show-empty="true"
                :empty-text="outboxIsBusy ? 'Loading, please wait...' : 'There are no records to show'"
                :empty-filtered-text="outboxIsBusy ? 'Loading, please wait...' : 'There are no records matching your request'"
                class="text-left"
                >
              <template slot="file" slot-scope="row">
                <a target='_blank'
                  :href="row.item.fileurl">
                  {{ row.item.file }}</a>
              </template>
              <template slot="HEAD_date" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="outboxDateTimeFilter" placeholder="Date/Time" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_file" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="outboxFileFilter" placeholder="File Name" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_sender" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="outboxSenderFilter" placeholder="Sender" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_receiver" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="outboxReceiverFilter" placeholder="Receiver" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_pages" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="outboxPagesFilter" placeholder="Pages" class="form-control form-control-sm"/>
              </template>
              <template slot="HEAD_status" slot-scope="row">
                <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="outboxStatusFilter" placeholder="Status" class="form-control form-control-sm"/>
              </template>
              <template slot="confirmation" slot-scope="row">
                <a v-if="row.item.status === 'SENT'" @click="outboxDownloadTransmittalButtonPressed (row.item.jobid)" class="faxInboxActionButton" style="padding:10px;" title="Download Transmittal Page"
                href="javascript:void(0)"><i style="color:green" class="fa fa-cloud-download"></i></a>
                <a v-if="row.item.status !== 'SENT'" style="padding:10px;" title="No Transmittal Page Available"
                href="javascript:void(0)"><i style="color:white;" class="fa fa-cloud-download"></i></a>
              </template>
            </b-table>
          </div>
        </div>
        <b-row>
          <b-col md="6">
            <span>{{ inboxTableResultsText }}</span>
          </b-col>
          <b-col md="6">
            <b-pagination v-if="selectedInbox === 'fax' && currentTable === 'inbox'" :total-rows="inboxTotalRows" :per-page="inboxPerPage" v-model="inboxCurrentPage" align="right"/>
            <b-pagination v-if="selectedInbox === 'burst'" :total-rows="burstTotalRows" :per-page="burstPerPage" v-model="burstCurrentPage" align="right"/>
            <b-pagination v-if="selectedInbox === 'fax' && currentTable === 'outbox'" :total-rows="outboxTotalRows" :per-page="outboxPerPage" v-model="outboxCurrentPage" align="right"/>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <!-- <AttachFaxDialog :fax="selectedFax"/> -->
  </div>
</template>

<script>
import FilePicker from '@/components/Helpers/FilePicker.vue'
import Fax from '@/scripts/objects/fax'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faTimesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle'
// AttachFaxDialog is no longer a dialog
import AttachFaxDialog from '@/components/modals/AttachFaxDialog.vue'
import ToggleButton from 'vue-js-toggle-button/src/Button'
import LegacyApi from '@/services/LegacyApi'

import $ from 'jquery'

export default {
  props: ['filePickerKey'],
  data () {
    return {
      shop_id: -1,
      inboxFields: [
        { key: 'select', label: '', sortable: false, thClass: ['leftTh', 'faxInboxSelectHeader'], tdClass: 'leftTd' },
        { key: 'dateTime', label: 'Date/Time', sortable: true, thClass: ['leftTh', 'faxInboxDateTimeHeader'], tdClass: 'leftTd' },
        { key: 'sentFrom', label: 'Sent From', sortable: true, thClass: ['leftTh', 'faxInboxSentFromHeader'], tdClass: 'leftTd' },
        { key: 'sentTo', label: 'Sent To', sortable: true, thClass: ['leftTh', 'faxInboxSentToHeader'], tdClass: 'leftTd' },
        { key: 'pages', label: 'Pages', sortable: true, thClass: ['leftTh', 'faxInboxPagesHeader'], tdClass: 'leftTd' },
        { key: 'referralNotes', label: 'Referral/Notes', sortable: true, thClass: ['leftTh', 'faxInboxReferralNotesHeader'], tdClass: 'leftTd' },
        { key: 'status', label: 'Status', sortable: true, thClass: ['leftTh', 'faxInboxStatusHeader'], tdClass: 'leftTd' },
        { key: 'actions', label: 'Actions', sortable: false, thClass: ['leftTh', 'faxInboxActionsHeader'], tdClass: 'leftTd' }
      ],
      inboxCurrentPage: 1,
      inboxPerPage: 10,
      inboxTotalRows: 0,
      inboxPageOptions: [ 10 ],
      inboxSortBy: 'dateTime',
      inboxSortDesc: true,
      inboxIsBusy: false,

      inboxDateTimeFilter: '',
      inboxSentFromFilter: '',
      inboxSentToFilter: '',
      inboxPagesFilter: '',
      inboxReferralNotesFilter: '',
      inboxStatusFilter: '',

      currentTable: 'inbox',

      outboxFields: [
        { key: 'date', label: 'Date', sortable: false, thClass: ['leftTh', 'faxOutboxDateHeader'], tdClass: 'leftTd' },
        { key: 'file', label: 'File', sortable: false, thClass: ['leftTh', 'faxOutboxFileHeader'], tdClass: 'leftTd' },
        { key: 'sender', label: 'Sender', sortable: false, thClass: ['leftTh', 'faxOutboxSenderHeader'], tdClass: 'leftTd' },
        { key: 'receiver', label: 'Receiver', sortable: false, thClass: ['leftTh', 'faxOutboxReceiverHeader'], tdClass: 'leftTd' },
        { key: 'pages', label: 'Pages', sortable: false, thClass: ['leftTh', 'faxOutboxPagesHeader'], tdClass: 'leftTd' },
        { key: 'status', label: 'Status', sortable: false, thClass: ['leftTh', 'faxOutboxStatusHeader'], tdClass: 'leftTd' },
        { key: 'confirmation', label: 'Confirmation', sortable: false, thClass: ['leftTh', 'faxOutboxConfirmationHeader'], tdClass: 'leftTd' }
      ],
      outboxCurrentPage: 1,
      outboxPerPage: 10,
      outboxTotalRows: 0,
      outboxPageOptions: [ 10 ],
      outboxSortBy: '',
      outboxSortDesc: false,
      outboxIsBusy: false,

      outboxDateTimeFilter: '',
      outboxFileFilter: '',
      outboxSenderFilter: '',
      outboxReceiverFilter: '',
      outboxPagesFilter: '',
      outboxStatusFilter: '',

      delayTimer: null,
      skipTableReset: false,

      isBurstCheckRunning: false,

      selectedFaxes: [],
      selectedStatusToChangeTo: '',

      uploadFaxName: '',
      uploadFaxUrl: '',
      faxNumberToSendTo: '',
      amDragging: false,
      uploadingDropFile: false,
      pdfPreviewItem: null,
      selectedFax: null,

      loadInboxCount: 0,
      loadOutboxCount: 0,

      pageVisible: false,

      burstFields: [
        { key: 'select', label: '', sortable: false, thClass: ['leftTh', 'burstSelectHeader'], tdClass: 'leftTd' },
        { key: 'fileName', label: 'File Name', sortable: true, thClass: ['leftTh', 'burstFileNameHeader'], tdClass: 'leftTd' },
        // { key: 'page', label: 'Page', sortable: false, thClass: ['leftTh', 'burstPageHeader'], tdClass: 'leftTd' },
        { key: 'dateTime', label: 'Date/Time', sortable: true, thClass: ['leftTh', 'burstDateTimeHeader'], tdClass: 'leftTd' },
        // { key: 'url', label: 'Url', sortable: false, thClass: ['leftTh', 'burstUrlHeader'], tdClass: 'leftTd' },
        { key: 'actions', label: 'Actions', sortable: false, thClass: ['leftTh', 'burstActionsHeader'], tdClass: 'leftTd' }
      ],

      selectedInbox: 'fax',
      isBurstInboxSelected: false,

      burstCurrentPage: 1,
      burstPerPage: 10,
      burstTotalRows: 0,
      burstPageOptions: [ 10 ],
      burstSortBy: '',
      burstSortDesc: true,
      burstIsBusy: false,

      loadBurstCount: 0,

      burstDateTimeFilter: '',
      burstReferralNotesFilter: '',
      burstStatusFilter: '',
      burstArray: [],
      uploadBurstName: '',
      uploadBurstUrl: '',

      selectedBursts: []
    }
  },
  watch: {
    selectedFaxes () {
      var statusToSetTo = null
      for (var i = 0; i < this.selectedFaxes.length; i++) {
        if (statusToSetTo === null || this.selectedFaxes[i]['status'] === statusToSetTo) {
          statusToSetTo = this.selectedFaxes[i]['status']
        } else {
          statusToSetTo = ''
          break
        }
      }
      if (statusToSetTo === null) {
        statusToSetTo = ''
      }
      this.selectedStatusToChangeTo = statusToSetTo.toLowerCase()
    },
    // selectedBursts () {
    //   
    // },
    inboxDateTimeFilter () {
      this.refreshInboxTable()
    },
    inboxSentFromFilter () {
      this.refreshInboxTable()
    },
    inboxSentToFilter () {
      this.refreshInboxTable()
    },
    inboxPagesFilter () {
      this.refreshInboxTable()
    },
    inboxReferralNotesFilter () {
      this.refreshInboxTable()
    },
    inboxStatusFilter () {
      this.refreshInboxTable()
    },
    outboxDateTimeFilter () {
      this.refreshOutboxTable()
    },
    outboxFileFilter () {
      this.refreshOutboxTable()
    },
    outboxSenderFilter () {
      this.refreshOutboxTable()
    },
    outboxReceiverFilter () {
      this.refreshOutboxTable()
    },
    outboxPagesFilter () {
      this.refreshOutboxTable()
    },
    outboxStatusFilter () {
      this.refreshOutboxTable()
    },
    currentTable () {
      let self = this
      setTimeout(function () {
        if (self.currentTable === 'outbox') {
          self.refreshOutboxTable()
        } else {
          self.$refs.faxInboxTable.refresh()
        }
      }, 100)
    }
  },
  computed: {
    inboxTableResultsText: function () {
      var tableResults = 'Showing'
      if (this.selectedInbox === 'fax' && this.currentTable === 'inbox') {
        if (this.inboxTotalRows > 0) {
          var currentFirstResult = ((this.inboxCurrentPage * this.inboxPerPage) - this.inboxPerPage) + 1
          var currentLastResult = (this.inboxCurrentPage * this.inboxPerPage)
          if (currentLastResult > this.inboxTotalRows) {
            currentLastResult = this.inboxTotalRows
          }
          tableResults += ' ' + currentFirstResult + ' to ' + currentLastResult + ' of'
        }
        tableResults += ' ' + this.inboxTotalRows + ' Fax' + (this.inboxTotalRows === 1 ? '' : 'es')
      } else if (this.selectedInbox === 'burst') {
        if (this.burstTotalRows > 0) {
          var currentFirstBurstResult = ((this.burstCurrentPage * this.burstPerPage) - this.burstPerPage) + 1
          var currentLastBurstResult = (this.burstCurrentPage * this.burstPerPage)
          if (currentLastBurstResult > this.burstTotalRows) {
            currentLastBurstResult = this.burstTotalRows
          }
          tableResults += ' ' + currentFirstBurstResult + ' to ' + currentLastBurstResult + ' of'
        }
        tableResults += ' ' + this.burstTotalRows + ' Document' + (this.burstTotalRows === 1 ? '' : 's')
      } else if (this.selectedInbox === 'fax' && this.currentTable === 'outbox') {
        if (this.outboxTotalRows > 0) {
          var currentFirstOutboxResult = ((this.outboxCurrentPage * this.outboxPerPage) - this.outboxPerPage) + 1
          var currentLastOutboxResult = (this.outboxCurrentPage * this.outboxPerPage)
          if (currentLastOutboxResult > this.outboxTotalRows) {
            currentLastOutboxResult = this.outboxTotalRows
          }
          tableResults += ' ' + currentFirstOutboxResult + ' to ' + currentLastOutboxResult + ' of'
        }
        tableResults += ' ' + this.outboxTotalRows + ' Document' + (this.outboxTotalRows === 1 ? '' : 's')
      }
      return tableResults
    },
    faTimesCircle () {
      return faTimesCircle
    }
  },
  methods: {
    closePdfPreview: function () {
      this.pdfPreviewItem = null
      this.selectedFax['_rowVariant'] = null
      this.selectedFax = null
    },
    faxDragenter: function () {
      this.amDragging = true
    },
    faxDragleave: function () {
      this.amDragging = false
    },
    faxDragover: function () {
      this.amDragging = true
    },
    faxDrop: function (e) {
      this.amDragging = false
      this.$root.$emit('faxInboxFilePickerDrop', e)
    },
    sendFax: function () {
      let self = this
      Fax.retrieveOutgoingFaxNumber(async function (response) {
        var outgoingFaxNumber = response
        var sendFaxInfo = new FormData()
        sendFaxInfo.set('filename', window.btoa(self.uploadFaxName))
        sendFaxInfo.set('url', window.btoa(self.uploadFaxUrl))

        sendFaxInfo.set('sender', outgoingFaxNumber)
        sendFaxInfo.set('receiver', self.faxNumberToSendTo.replace(/\D/g, ''))

        if (('' + sendFaxInfo.get('receiver')).length !== 10) {
          alert('Please type a fax number to send this fax to.')
          return
        }
        if (sendFaxInfo.get('filename').length === 0 || sendFaxInfo.get('url').length === 0) {
          alert('Please select a file to fax.')
          return
        }

        // Clear the file selection box and all data
        self.uploadFaxName = ''
        self.uploadFaxUrl = ''
        self.faxNumberToSendTo = ''
        try {
          const { data } = await LegacyApi.post('/fax/send', sendFaxInfo)
          if (typeof data === 'object' && data !== null && typeof data.error === 'string' && data.error.length > 0) {
            // Data format in case of error is {error:'string'}
            alert('Error sending fax: ' + data.error)
          } else {
            if (self.currentTable !== 'outbox') {
              self.currentTable = 'outbox'
            } else {
              self.$refs.faxOutboxTable.refresh()
            }
          }
        } catch (error) {
          
        }
      })
    },
    handleFilePickerResponse: function (fileName, fileUrl) {
      
      
      this.uploadFaxName = fileName
      this.uploadFaxUrl = fileUrl
      this.uploadBurstName = fileName
      this.uploadBurstUrl = fileUrl
    },
    selectedBurstFaxes () {
      
      

      if (this.selectedFaxes.length > 0 && confirm('Are you sure you want to burst the selected faxes?')) {
        var faxIds = []
        for (var i = 0; i < this.selectedFaxes.length; i++) {
          faxIds.push(this.selectedFaxes[i].faxId)
        }
        this.burstFaxes(faxIds, true)
      }
    },
    selectedDeleteFaxes () {
      if (this.selectedFaxes.length > 0 && confirm('Are you sure you want to delete the selected faxes?')) {
        var faxIds = []
        for (var i = 0; i < this.selectedFaxes.length; i++) {
          faxIds.push(this.selectedFaxes[i].faxId)
        }
        this.deleteFaxes(faxIds)
      }
    },
    selectedDeleteBursts () {
      if (this.selectedBursts.length > 0 && confirm('Are you sure you want to delete the selected documents?')) {
        var burstIds = []
        for (var i = 0; i < this.selectedBursts.length; i++) {
          burstIds.push(this.selectedBursts[i].burstId)
        }
        this.deleteBursts(burstIds)
      }
    },
    selectedStatusChanged () {
      
      if (this.selectedFaxes.length > 0 &&
        (this.selectedStatusToChangeTo === 'unread' || this.selectedStatusToChangeTo === 'read' || this.selectedStatusToChangeTo === 'burst')) {
        var faxIds = []
        for (var i = 0; i < this.selectedFaxes.length; i++) {
          faxIds.push(this.selectedFaxes[i].faxId)
        }
        this.changeFaxesStatus(faxIds, this.selectedStatusToChangeTo, true)
      }
      this.selectedStatusToChangeTo = ''
    },
    inboxDownloadButtonPressed (faxId) {
      
      this.changeFaxesStatus([faxId], 'read', false)
    },
    changeFaxesStatus (faxIds, status, removeCheckbox) {
      let self = this
      Fax.updateFaxStatus(faxIds, status, function (response) {
        var errorCount = 0
        for (var key in response) {
          if (response[key] === 'success') {
            for (var i = 0; i < self.$refs.faxInboxTable.localItems.length; i++) {
              var item = self.$refs.faxInboxTable.localItems[i]
              if (item.faxId + '' === key + '') {
                item.status = status.toUpperCase()
              }
            }

            if (removeCheckbox) {
              for (i = 0; i < self.selectedFaxes.length; i++) {
                item = self.selectedFaxes[i]
                if (item.faxId + '' === key + '') {
                  var index = self.selectedFaxes.indexOf(item)
                  self.selectedFaxes.splice(index, 1)
                  i--
                }
              }
            }
          } else {
            errorCount++
          }
        }
        if (errorCount === 1) {
          alert('There was an error marking a fax status as ' + status + '.')
        } else if (errorCount > 1) {
          alert('There was an error marking fax statuses as ' + status + '.')
        }
      })
    },
    inboxBurstButtonPressed (faxId) {
      this.burstArray.push(faxId)
      this.burstFaxes([faxId], false)
      
    },
    burstFaxes (faxIds, removeCheckbox) {
      var self = this
      self.burstIsBusy = true
      
      

      var faxIDsString = faxIds.join()
      var burstFileInfo = {}
      burstFileInfo.faxID = faxIDsString
      // let self = this

      Fax.burstFaxes(burstFileInfo, function (results) {
        
        
        if (results.status === 'success') {
          self.burstArray.shift()
          self.burstIsBusy = false
          self.$root.$emit('showGlobalNotification', 'Success', `Fax burst complete`)
          self.toggleSelectedInbox()
        } else {
          self.$root.$emit('showGlobalNotification', 'Danger', 'Failed to burst fax. Please try again.')
        }
      })
    },
    localBurst: function () {
      let self = this
      self.burstIsBusy = true
      // let localBurstDoc = {
      //   filename: window.btoa(self.uploadBurstName),
      //   url: window.btoa(self.uploadBurstUrl)
      // }
      let localBurstDoc = {
        filename: self.uploadBurstName,
        url: self.uploadBurstUrl
        // async burstFaxes (faxIds, removeCheckbox) {
        //   var faxIDsString = faxIds.join()
        //   var burstFileInfo = new FormData();
        //   burstFileInfo.set('faxID',  faxIDsString)
        //   let self = this
        //   try {
        //     const { data } = await LegacyApi.post('/burst/do', burstFileInfo)
        //     if (typeof data === 'object' && data !== null && typeof data.error === 'string' && data.error.length > 0) {
        //       alert('Burst failed: ' + data.error)
        //     } else if (typeof data === 'object' && data !== null && typeof data.error !== 'undefined' && $.isArray(data.error) && data.error.length > 0) {
        //       var report = data.error.join('\n')
        //       alert(report)
        //     }

        //     self.$refs.faxInboxTable.refresh()
        //   } catch (error) {
        //     
        //   }

        //   for (var i = 0; i < faxIds.length; i++) {
        //     var faxId = faxIds[i]
        //     for (var j = 0; j < self.$refs.faxInboxTable.localItems.length; j++) {
        //       var item = self.$refs.faxInboxTable.localItems[j]
        //       if (item.faxId + '' === faxId + '') {
        //         item['_rowVariant'] = 'success'
        //         item['status'] = 'BURSTING'
        //       }
        //     }

        //     if (removeCheckbox) {
        //       for (var k = 0; k < self.selectedFaxes.length; k++) {
        //         item = self.selectedFaxes[k]
        //         if (item.faxId + '' === faxId + '') {
        //           var index = self.selectedFaxes.indexOf(item)
        //           self.selectedFaxes.splice(index, 1)
        //           k--
        //         }
        //       }
        //     }
      }
      Fax.burstLocalFax(localBurstDoc, function (res) {
        
        if (res.status === 'success') {
          self.burstIsBusy = false
          self.$root.$emit('showGlobalNotification', 'Success', `Fax burst complete`)
          self.getBurstCount()
          self.uploadFaxName = ''
          self.uploadFaxUrl = ''
          self.uploadBurstName = ''
          self.uploadBurstUrl = ''
          self.refreshBurstTable()
        }
      })
    },
    inboxAttachButtonPressed (item) {
      if (this.selectedFax !== null) {
        if (this.selectedFax.hasOwnProperty('_rowVariant')) {
          this.selectedFax['_rowVariant'] = null
        }
      }
      this.selectedFax = item
      this.selectedFax['_rowVariant'] = 'primary'
      this.skipTableReset = true
      this.pdfPreviewItem = item
      // this.$root.$emit('bv::show::modal', 'attachFaxModal')
    },
    closeAttachPanel () {
      this.selectedFax['_rowVariant'] = null
      this.selectedFax = null
      this.pdfPreviewItem = null
    },
    inboxForwardButtonPressed () {
      
    },
    inboxDeleteButtonPressed (faxId, faxdatetime) {
      if (confirm('Are you sure you wish to delete fax dated ' + faxdatetime + '?')) {
        this.deleteFaxes([faxId])
      }
    },
    burstDeleteButtonPressed (burstId) {
      if (confirm('Are you sure you wish to delete this document?')) {
        this.deleteBursts([burstId])
      }
    },
    deleteFaxes (faxIds) {
      let self = this
      Fax.deleteFaxes(faxIds, function (response) {
        var errorCount = 0
        for (var key in response) {
          if (response[key] === 'success') {
            for (var i = 0; i < self.selectedFaxes.length; i++) {
              var item = self.selectedFaxes[i]
              if (item.faxId + '' === key + '') {
                var index = self.selectedFaxes.indexOf(item)
                self.selectedFaxes.splice(index, 1)
                i--
              }
            }
          } else {
            errorCount++
          }
        }
        if (errorCount === 1) {
          alert('There was an error deleting a fax.')
        } else if (errorCount > 1) {
          alert('There was an error deleting faxes.')
        }
        self.$refs.faxInboxTable.refresh()
      })
    },
    deleteBursts (burstIds) {
      
      let self = this
      Fax.deleteBursts(burstIds, function (response) {
        
        var errorCount = 0
        for (var key in response) {
          if (response[key] === 'success') {
            for (var i = 0; i < self.selectedBursts.length; i++) {
              var item = self.selectedBursts[i]
              if (item.burstId + '' === key + '') {
                var index = self.selectedBursts.indexOf(item)
                self.selectedBursts.splice(index, 1)
                i--
              }
            }
          } else {
            errorCount++
          }
        }
        if (errorCount === 1) {
          self.$root.$emit('showGlobalNotification', 'Danger', `Failed to delete one of the selected documents`)
        } else if (errorCount > 1) {
          self.$root.$emit('showGlobalNotification', 'Danger', `Failed to deleted documents.`)
        } else {
          self.$root.$emit('showGlobalNotification', 'Success', `Successfully deleted documents`)
          self.refreshBurstTable()
        }
      }, (err) => {
        
      })
    },
    async outboxDownloadTransmittalButtonPressed (faxId) {
      this.retreivingConfirmation = true
      const {data} = await this.getFaxSentPdf(faxId)
      this.retreivingConfirmation = false
      if (!data || data.status) {
        alert('Something went wrong retreiving the copy of your previous fax. Please try again.')
        return
      }
      var pdfData = this.base64ToArrayBuffer(data)
      var link = document.createElement('a')

      var blob = new Blob([pdfData], {type: 'application/pdf;'})
      var URL = window.URL || window.webkitURL
      var downloadUrl = URL.createObjectURL(blob)

      link.href = downloadUrl
      link.download = 'fax-confirmation-' + faxId + '.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    focusReferralNotes (item) {
      
      if (this.selectedFax !== null) {
        if (this.selectedFax.hasOwnProperty('_rowVariant')) {
          this.selectedFax['_rowVariant'] = null
        }
      }
      this.selectedFax = item
      this.selectedFax['_rowVariant'] = 'primary'
      this.skipTableReset = true
      this.pdfPreviewItem = item
    },
    updateReferralNotes (refName, faxId) {
      let self = this
      Fax.updateFaxReferral(faxId, this.$refs[refName].value,
        function () {
          
        },
        function () {
          alert('There was a problem storing the updated notes.')
          self.$refs.faxInboxTable.refresh()
        })
    },
    refreshOutboxTable: function () {
      this.outboxCurrentPage = 1
      this.filterOutboxResults()
    },
    refreshInboxTable: function () {
      this.inboxCurrentPage = 1
      this.filterInboxResults()
    },
    refreshBurstTable: function () {
      this.burstCurrentPage = 1
      this.filterBurstResults()
    },
    dialogAboutToAppear: function () {
      this.getBurstCount()
      this.pageVisible = true
      if (!this.skipTableReset) {
        this.resetTableVariables()
      }
    },
    dialogAppeared: function () {
      if (this.skipTableReset) {
        this.$refs.faxInboxTable.refresh()
        this.skipTableReset = false
      }
    },
    resetTableVariables: function () {
      this.inboxDateTimeFilter = ''
      this.inboxSentFromFilter = ''
      this.inboxSentToFilter = ''
      this.inboxPagesFilter = ''
      this.inboxReferralNotesFilter = ''
      this.inboxStatusFilter = ''
      this.inboxCurrentPage = 1
    },
    filterInboxResults: function () {
      let self = this
      clearTimeout(self.delayTimer)
      self.delayTimer = setTimeout(function () {
        self.inboxIsBusy = false
        self.$refs.faxInboxTable.refresh()
        if (!self.inboxIsBusy) {
          self.filterInboxResults()
        }
      }, 300)
    },
    filterOutboxResults: function () {
      let self = this
      clearTimeout(self.delayTimer)
      self.delayTimer = setTimeout(function () {
        self.outboxIsBusy = false
        self.$refs.faxOutboxTable.refresh()
        if (!self.outboxIsBusy) {
          self.filterOutboxResults()
        }
      }, 300)
    },
    filterBurstResults: function () {
      let self = this
      clearTimeout(self.delayTimer)
      self.delayTimer = setTimeout(function () {
        self.burstIsBusy = false
        self.$refs.faxBurstTable.refresh()
      }, 300)
    },
    outboxProvider (ctx, callback) {
      
      if (this.currentTable === 'inbox' || !this.pageVisible) {
        return []
      }

      let self = this
      self.outboxIsBusy = true
      

      setTimeout(function () {
        if (self.outboxSortBy === null) {
          self.outboxSortBy = ''
        }

        self.loadOutboxCount++
        var currentLoadCount = self.loadOutboxCount

        Fax.getFaxOutboxTable(self.outboxCurrentPage, self.outboxPerPage, self.outboxSortBy, self.outboxSortDesc,
          self.outboxDateTimeFilter, self.outboxFileFilter, self.outboxSenderFilter, self.outboxReceiverFilter,
          self.outboxPagesFilter, self.outboxStatusFilter,
          function (results) {
            
            if (currentLoadCount === self.loadOutboxCount) {
              
              var rows = results.rows
              var items = []
              if (rows && rows.length) {
                for (var i = 0; i < rows.length; i++) {
                  var row = rows[i]
                  var item = {
                    'faxsentId': row['faxsentId'],
                    'date': row['date'],
                    'file': row['file'],
                    'sender': self.formatAsPhoneNumber(row['sender'] + ''),
                    'receiver': self.formatAsPhoneNumber(row['receiver'] + ''),
                    'pages': row['pages'],
                    'status': row['status'],
                    'fileurl': row['fileurl'],
                    'jobid': row['jobid']
                  }
                  if (row['status'] === 'ERROR') {
                    item['_rowVariant'] = 'danger'
                  } else if (row['status'] !== 'SENT' && row['status'] !== 'ERROR') {
                    item['_rowVariant'] = 'success'
                  }
                  items.push(item)
                }
              }

              self.outboxTotalRows = results.numberOfRowsFound
              $('#faxOutboxModal').scrollTop(0)
              self.outboxIsBusy = false
              
              callback(items)
            } else {
              
            }
          }, function (error) {
            
            self.outboxIsBusy = false
            /* eslint-disable */
            callback([])
            /* eslint-enable */
          })
      }, 300)
      return null
    },
    inboxProvider (ctx, callback) {
      
      if (!this.pageVisible) {
        return []
      }

      let self = this
      self.inboxIsBusy = true
      

      setTimeout(function () {
        if (self.inboxSortBy === null) {
          self.inboxSortBy = ''
        }

        self.loadInboxCount++
        var currentLoadCount = self.loadInboxCount

        Fax.getFaxInboxTable(self.inboxCurrentPage, self.inboxPerPage, self.inboxSortBy, self.inboxSortDesc,
          self.inboxDateTimeFilter, self.inboxSentFromFilter, self.inboxSentToFilter,
          self.inboxPagesFilter, self.inboxReferralNotesFilter, self.inboxStatusFilter,
          function (results) {
            
            if (currentLoadCount === self.loadInboxCount) {
              
              var rows = results.rows
              var items = []
              if (rows && rows.length) {
                for (var i = 0; i < rows.length; i++) {
                  var row = rows[i]
                  var item = {
                    'faxId': row['faxId'],
                    'dateTime': row['dateTime'],
                    'sentFrom': self.formatAsPhoneNumber(row['sentFrom']),
                    'sentTo': self.formatAsPhoneNumber(row['sentTo']),
                    'pages': row['pages'],
                    'referralNotes': row['referralNotes'],
                    'status': row['status'],
                    'url': row['url'],
                    'filename': row['filename']
                  }
                  if (row['status'] === 'BURSTING') {
                    item['_rowVariant'] = 'success'
                  }

                  for (var j = 0; j < self.selectedFaxes.length; j++) {
                    var selectedItem = self.selectedFaxes[j]
                    if (selectedItem.faxId + '' === item.faxId + '') {
                      var index = self.selectedFaxes.indexOf(selectedItem)
                      self.selectedFaxes[index] = item
                    }
                  }

                  items.push(item)
                }
              }

              self.inboxTotalRows = results.numberOfRowsFound
              $('#faxInboxModal').scrollTop(0)
              self.inboxIsBusy = false
              
              callback(items)
            } else {
              
            }
          }, function (error) {
            
            self.inboxIsBusy = false
            /* eslint-disable */
            callback([])
            /* eslint-enable */
          })
      }, 300)
      return null
    },
    burstProvider (ctx, callback) {
      
      if (!this.pageVisible) {
        return []
      }

      let self = this
      self.inboxIsBusy = true
      

      setTimeout(function () {
        if (self.burstSortBy === null) {
          self.burstSortBy = ''
        }

        self.loadBurstCount++
        var currentLoadCount = self.loadBurstCount

        Fax.getFaxBurstTable(self.burstCurrentPage, self.burstPerPage, self.burstSortBy, self.burstSortDesc,
          self.burstDateTimeFilter, self.burstReferralNotesFilter, self.burstStatusFilter,
          function (results) {
            
            if (currentLoadCount === self.loadBurstCount) {
              
              var rows = results.rows
              var items = []
              if (rows && rows.length) {
                for (var i = 0; i < rows.length; i++) {
                  var row = rows[i]
                  var item = {
                    'burstId': row['burstId'],
                    'dateTime': row['dateTime'],
                    'fileName': row['fileName'],
                    'displayName': row['displayName'],
                    'url': row['url'],
                    'page': row['page']
                  }

                  items.push(item)
                }
              }

              self.burstTotalRows = results.numberOfRowsFound
              $('#faxInboxModal').scrollTop(0)
              self.inboxIsBusy = false
              
              callback(items)
            } else {
              // hitting this for some reason - happens when bursting pdf from db
              
            }
          }, function (error) {
            
            self.inboxIsBusy = false
            /* eslint-disable */
            callback([])
            /* eslint-enable */
          })
      }, 300)
      return null
    },
    getBurstCount () {
      var self = this
      Fax.getBurstCount(function (response) {
        
        self.burstTotalRows = response.data.burst_count
      },
      function () {
        
      })
    },
    toggleSelectedInbox () {
      if (this.selectedInbox === 'fax') {
        this.selectedInbox = 'burst'
        this.isBurstInboxSelected = true
      } else if (this.selectedInbox === 'burst') {
        this.selectedInbox = 'fax'
        this.isBurstInboxSelected = false
      }
    }
  },
  components: {
    FontAwesomeIcon,
    FilePicker,
    ToggleButton,
    AttachFaxDialog
  },
  mixins: [UtilityMixin]
}
</script>

<style>
#faxInboxModal .modal-lg {
  min-width: 90%;
}

#faxInboxModal .tableHolder {
  overflow:auto;
}

#faxInboxModal .leftTd {
  text-align: left;
}

#faxInboxModal .leftTh {
  text-align: left;
}

.faxInboxActionButton:hover {
  background-color: #90c0ff;
}

.faxInboxActionsHeader {
  min-width:210px;
}

.faxInboxPagesHeader {
  min-width:100px;
}

.faxInboxReferralNotesHeader {
  min-width:100px;
}

.faxDragDrop {
  background-color: #cbcbcb;
  border: 2px solid #0B85A1;
}

.uploadingDropFile {
  background-color: #00beee !important;
  color: white;
}

.activeInbox {
  background-color: #299fd7 !important;
  color: white;
}

</style>
