<template>
  <el-table :data="data" style="width: 100%;" size="mini">
    <el-table-column type="selection" width="55" />
    <el-table-column
      v-for="col in cols"
      :key="col.id"
      :property="col.id"
      :label="col.label"
    >
      <template slot-scope="scope">
        <el-checkbox
          :value="!!scope.row[col.id]"
          v-if="col.type === 'checkbox'"
        />
        <span v-else>
          {{ scope.row[col.id] }}
        </span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    cols: { type: Array, default: () => {} },
    data: { type: Array, default: () => {} },
    value: { type: Array, default: () => {} }
  },
  computed: {
    model: {
      get() {
        return this.data
      },
      set() {
        this.$emit('input', 'asdf')
      }
    }
  }
}
</script>

<style>
</style>
