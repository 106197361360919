import Api from '@/services/Api'
import Vue from 'vue'
import { stat } from 'fs'

const state = {
  filters: {
    // shopId: null,
    archived: false
  },
  pagination: {
    page: 1,
    limit: 10,
    from: null,
    to: null,
    total: null
  },
  fetching: false,
  data: []
}

const mutations = {
  // setFilterShopId: (state, payload) => {
  //   state.filters.shopId = payload
  // },
  setFilterArchived: (state, payload) => {
    state.filters.archived = payload
  },
  setPaginationPage: (state, payload) => {
    state.pagination.page = payload
  },
  setPaginationFrom: (state, payload) => {
    state.pagination.from = payload
  },
  setPaginationTo: (state, payload) => {
    state.pagination.to = payload
  },
  setPaginationTotal: (state, payload) => {
    state.pagination.total = payload
  },
  setData: (state, payload) => {
    state.data = payload
  },
  setFetching: (state, payload) => {
    state.fetching = payload
  },
  setNote: (state, {id, payload}) => {
    for (var i = 0; i < state.data.length; i++) {
      if (state.data[i].id === id) {
        Vue.set(state.data[i], 'note', payload)
      }
    }
    // state.data = [
    //   ...state
    // ]
  }
}

const actions = {
  get: async ({ state, commit }) => {
    commit('setFetching', true)
    commit('setData', [])
    const { data } = await Api.get('/api/glassbillerleads', {
      params: {
        page: state.pagination.page,
        limit: state.pagination.limit,
        shopId: state.filters.shopId,
        archived: state.filters.archived ? 1 : 0
      }
    })
    commit('setData', data.data)
    commit('setPaginationFrom', data.meta.from)
    commit('setPaginationTo', data.meta.to)
    commit('setPaginationTotal', data.meta.total)
    commit('setFetching', false)
  },

  // startJob: async (_, { id }) => {
  //   const { data } = await Api.post(`/api/leads/${id}/startJob`)
  //   return data
  // },

  setArchived: async ({ dispatch }, { id, value }) => {
    await Api.post(`/api/glassbillerleads/${id}/setArchived`, { value })
    await dispatch('get')
  },
  setNote: async ({ dispatch }, { id, value }) => {
    await Api.post(`/api/glassbillerleads/${id}/setNote`, { value })
    await dispatch('get')
  },
  setCallBack: async ({ dispatch }, { id, value }) => {
    await Api.post(`/api/glassbillerleads/${id}/setCallBack`, { value })
    await dispatch('get')
  },
  remove: async ({ dispatch }, { id }) => {
    await Api.delete(`/api/glassbillerleads/${id}`)
    await dispatch('get')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
