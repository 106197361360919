import Vue from 'vue'

/*
  This file will have helpers that we can use throughout the application...
  It may be better to have multiple files one for each type
*/

export const StateAbbreviations = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY']
export const StateFull = ['Alaska', 'Alabama', 'Arkansas', 'American Samoa', 'Arizona', 'California', 'Colorado', 'Connecticut', 'District of Columbia', 'Delaware', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Iowa', 'Idaho', 'Illinois', 'Indiana', 'Kansas', 'Kentucky', 'Louisiana', 'Massachusetts', 'Maryland', 'Maine', 'Michigan', 'Minnesota', 'Missouri', 'Mississippi', 'Montana', 'North Carolina', 'North Dakota', 'Nebraska', 'New Hampshire', 'New Jersey', 'New Mexico', 'Nevada', 'New York', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Virginia', 'Virgin Islands', 'Vermont', 'Washington', 'Wisconsin', 'West Virginia', 'Wyoming']
// {'AK': 'Alaska', 'AL': 'Alabama', 'AR': 'Arkansas', 'AS': 'American Samoa', 'AZ': 'Arizona', 'CA': 'California', 'CO': 'Colorado', 'CT': 'Connecticut', 'DC': 'District of Columbia', 'DE': 'Delaware', 'FL': 'Florida', 'GA': 'Georgia', 'GU': 'Guam', 'HI': 'Hawaii', 'IA': 'Iowa', 'ID': 'Idaho', 'IL': 'Illinois', 'IN': 'Indiana', 'KS': 'Kansas', 'KY': 'Kentucky', 'LA': 'Louisiana', 'MA': 'Massachusetts', 'MD': 'Maryland', 'ME': 'Maine', 'MI': 'Michigan', 'MN': 'Minnesota', 'MO': 'Missouri', 'MS': 'Mississippi', 'MT': 'Montana', 'NC': 'North Carolina', 'ND': ' North Dakota', 'NE': 'Nebraska', 'NH': 'New Hampshire', 'NJ': 'New Jersey', 'NM': 'New Mexico', 'NV': 'Nevada', 'NY': 'New York', 'OH': 'Ohio', 'OK': 'Oklahoma', 'OR': 'Oregon', 'PA': 'Pennsylvania', 'PR': 'Puerto Rico', 'RI': 'Rhode Island', 'SC': 'South Carolina', 'SD': 'South Dakota', 'TN': 'Tennessee', 'TX': 'Texas', 'UT': 'Utah', 'VA': 'Virginia', 'VI': 'Virgin Islands', 'VT': 'Vermont', 'WA': 'Washington', 'WI': 'Wisconsin', 'WV': 'West Virginia', 'WY': 'Wyoming'}
export const StateObjects = [{ value: 'AK', text: 'Alaska' },
  { value: 'AL', text: 'Alabama' },
  { value: 'AR', text: 'Arkansas' },
  { value: 'AS', text: 'American Samoa' },
  { value: 'AZ', text: 'Arizona' },
  { value: 'CA', text: 'California' },
  { value: 'CO', text: 'Colorado' },
  { value: 'CT', text: 'Connecticut' },
  { value: 'DC', text: 'District of Columbia' },
  { value: 'DE', text: 'Delaware' },
  { value: 'FL', text: 'Florida' },
  { value: 'GA', text: 'Georgia' },
  { value: 'GU', text: 'Guam' },
  { value: 'HI', text: 'Hawaii' },
  { value: 'IA', text: 'Iowa' },
  { value: 'ID', text: 'Idaho' },
  { value: 'IL', text: 'Illinois' },
  { value: 'IN', text: 'Indiana' },
  { value: 'KS', text: 'Kansas' },
  { value: 'KY', text: 'Kentucky' },
  { value: 'LA', text: 'Louisiana' },
  { value: 'MA', text: 'Massachusetts' },
  { value: 'MD', text: 'Maryland' },
  { value: 'ME', text: 'Maine' },
  { value: 'MI', text: 'Michigan' },
  { value: 'MN', text: 'Minnesota' },
  { value: 'MO', text: 'Missouri' },
  { value: 'MS', text: 'Mississippi' },
  { value: 'MT', text: 'Montana' },
  { value: 'NC', text: 'North Carolina' },
  { value: 'ND', text: 'North Dakota' },
  { value: 'NE', text: 'Nebraska' },
  { value: 'NH', text: 'New Hampshire' },
  { value: 'NJ', text: 'New Jersey' },
  { value: 'NM', text: 'New Mexico' },
  { value: 'NV', text: 'Nevada' },
  { value: 'NY', text: 'New York' },
  { value: 'OH', text: 'Ohio' },
  { value: 'OK', text: 'Oklahoma' },
  { value: 'OR', text: 'Oregon' },
  { value: 'PA', text: 'Pennsylvania' },
  { value: 'PR', text: 'Puerto Rico' },
  { value: 'RI', text: 'Rhode Island' },
  { value: 'SC', text: 'South Carolina' },
  { value: 'SD', text: 'South Dakota' },
  { value: 'TN', text: 'Tennessee' },
  { value: 'TX', text: 'Texas' },
  { value: 'UT', text: 'Utah' },
  { value: 'VA', text: 'Virginia' },
  { value: 'VI', text: 'Virgin Islands' },
  { value: 'VT', text: 'Vermont' },
  { value: 'WA', text: 'Washington' },
  { value: 'WI', text: 'Wisconsin' },
  { value: 'WV', text: 'West Virginia' },
  { value: 'WY', text: 'Wyoming' }]

export const CanadaProvinceObjects = [
  { value: 'AB', text: 'Alberta' },
  { value: 'BC', text: 'British Columbia' },
  { value: 'MB', text: 'Manitoba' },
  { value: 'NB', text: 'New Brunswick' },
  { value: 'NF', text: 'Newfoundland and Labrador' },
  { value: 'NL', text: 'Newfoundland and Labrador' },
  { value: 'NT', text: 'Northwest Territories' },
  { value: 'NS', text: 'Nova Scotia' },
  { value: 'NU', text: 'Nunavut' },
  { value: 'ON', text: 'Ontario' },
  { value: 'PE', text: 'Prince Edward Island' },
  { value: 'QC', text: 'Quebec' },
  { value: 'SK', text: 'Saskatchewan' },
  { value: 'YT', text: 'Yukon Territory' }
]

export const ValidateComponents = (inputs) => {
  var isValid = true
  for (var i = 0; i < inputs.length; i++) {
    if (inputs[i]) {
      var innerArray = []
      if (Array.isArray(inputs[i])) { // the ref is an array if it is in a v-for loop
        innerArray = inputs[i]
      } else {
        innerArray.push(inputs[i])
      }
      for (var j = 0; j < innerArray.length; j++) {
        innerArray[j].updateShowFailureMessage(true)
        if (!innerArray[j].isValid()) {
          if (isValid) {
            innerArray[j].focus()
          }
          isValid = false
        }
      }
    }
  }
  return isValid
}

export const SaveValidationCheck = (response, inputGroup) => {
  for (var i = 0; i < inputGroup.length; i++) {
    if (inputGroup[i]) {
      var innerArray = []
      if (Array.isArray()) { // the ref is an array if it is in a v-for loop
        innerArray = inputGroup[i]
      } else {
        innerArray.push(inputGroup[i])
      }
      for (var j = 0; j < innerArray.length; j++) {
        try {
          if (innerArray[j][1] === 'textInput') {
            var fieldToCheckValue = inputGroup[i][0]
            var fieldToCheckName = inputGroup[i][2]

            if (fieldToCheckValue == null || fieldToCheckValue.length === 0) {
              if (response.length > 0) {
                response += '<br>'
              }
              response += 'A value must be entered for ' + fieldToCheckName + '.'
            }
          } else if (innerArray[j][1] === 'emailInput') {
            fieldToCheckValue = inputGroup[i][0]
            fieldToCheckName = inputGroup[i][2]

            if (fieldToCheckValue == null ||
              fieldToCheckValue.length === 0 ||
              fieldToCheckValue.indexOf('@') === -1 ||
              fieldToCheckValue.indexOf('.') === -1) {
              if (response.length > 0) {
                response += '<br>'
              }
              response += 'Please enter a valid email address for ' + fieldToCheckName + '.'
            }
          } else if (!innerArray[j][0].isValid()) {
            if (response.length > 0) {
              response += '<br>'
            }

            if (innerArray[j][1] === 'dollar') {
              response += 'Please enter a valid dollar amount (do not include a \'$\')'
            } else if (innerArray[j][1] === 'phone') {
              response += 'Please enter a valid phone number'
            } else if (innerArray[j][1] === 'float') {
              response += 'Please enter a valid amount '
            } else if (innerArray[j][1] === 'requiredSelect') {
              response += 'A value must be selected '
            } else {
              response += 'Please enter a valid value'
            }
            response += ' for ' + innerArray[j][2] + '.'
          }
        } catch (err) {
          console.log('check error: ', err)
        }
      }
    }
  }
  return response
}

export const ResetValidateComponents = (inputs) => {
  for (var i = 0; i < inputs.length; i++) {
    if (inputs[i]) {
      if (Array.isArray(inputs[i])) { // the ref is an array if it is in a v-for loop
        for (var j = 0; j < inputs[i].length; j++) {
          inputs[i][j].updateShowFailureMessage(false)
        }
      } else {
        inputs[i].updateShowFailureMessage(false)
      }
    }
  }
}

export const GetCurrentDateTimeString = () => {
  var now = new Date()
  var day = ('0' + now.getDate()).slice(-2)
  var month = ('0' + (now.getMonth() + 1)).slice(-2)
  var hours = '' + now.getHours()
  var minutes = ('0' + now.getMinutes()).slice(-2)
  var ampm = 'AM'
  if (now.getHours() > 11) {
    ampm = 'PM'
    hours = '' + (now.getHours() - 12)
    if (now.getHours() === 12) {
      hours = '' + 12
    }
  }
  if (now.getHours() === 0) {
    hours = '12'
  }
  return now.getFullYear() + '-' + (month) + '-' + (day) + ' ' + (hours) + ':' + (minutes) + ' ' + ampm
}

// Getters and Setters defined here are included in the object properties and are stored in the DB
export const AddPropertyWithWatcher = (object, propertyName, defaultValue, setterMethod) => {
  Vue.set(object, '_' + propertyName, defaultValue) // This is required so property is reactive, it does include the internal _ properties when enumerated such as with Object.keys
  Object.defineProperty(object, propertyName, {
    enumerable: true,
    get: function () {
      return object['_' + propertyName]
    },
    set: function (val) {
      if (val !== object['_' + propertyName]) {
        object['_' + propertyName] = val
        setterMethod(val)
      }
    }
  })
  object[propertyName] = defaultValue
}


export const isMobile = () => typeof window.orientation !== 'undefined'

export const supportsScrollSnap = () => CSS.supports('scroll-snap-align: start')

export const eqIds = id => otherId => +id === +otherId

export const TimePickerObjects = [
  { value: '00:00:00', name: 'Midnight', style: 'lightBlue', disabled: false },
  { value: '00:15:00', name: '12:15', style: 'lightBlue', disabled: false },
  { value: '00:30:00', name: '12:30', style: 'lightBlue', disabled: false },
  { value: '00:45:00', name: '12:45', style: 'lightBlue', disabled: false },
  { value: '01:00:00', name: '01:00', style: 'lightBlue', disabled: false },
  { value: '01:15:00', name: '01:15', style: 'lightBlue', disabled: false },
  { value: '01:30:00', name: '01:30', style: 'lightBlue', disabled: false },
  { value: '01:45:00', name: '01:45', style: 'lightBlue', disabled: false },
  { value: '02:00:00', name: '02:00', style: 'lightBlue', disabled: false },
  { value: '02:15:00', name: '02:15', style: 'lightBlue', disabled: false },
  { value: '02:30:00', name: '02:30', style: 'lightBlue', disabled: false },
  { value: '02:45:00', name: '02:45', style: 'lightBlue', disabled: false },
  { value: '03:00:00', name: '03:00', style: 'lightBlue', disabled: false },
  { value: '03:15:00', name: '03:15', style: 'lightBlue', disabled: false },
  { value: '03:30:00', name: '03:30', style: 'lightBlue', disabled: false },
  { value: '03:45:00', name: '03:45', style: 'lightBlue', disabled: false },
  { value: '04:00:00', name: '04:00', style: 'lightBlue', disabled: false },
  { value: '04:15:00', name: '04:15', style: 'lightBlue', disabled: false },
  { value: '04:30:00', name: '04:30', style: 'lightBlue', disabled: false },
  { value: '04:45:00', name: '04:45', style: 'lightBlue', disabled: false },
  { value: '05:00:00', name: '05:00', style: 'lightBlue', disabled: false },
  { value: '05:15:00', name: '05:15', style: 'lightBlue', disabled: false },
  { value: '05:30:00', name: '05:30', style: 'lightBlue', disabled: false },
  { value: '05:45:00', name: '05:45', style: 'lightBlue', disabled: false },
  { value: '06:00:00', name: '06:00', style: 'lightBlue', disabled: false },
  { value: '06:15:00', name: '06:15', style: 'lightBlue', disabled: false },
  { value: '06:30:00', name: '06:30', style: 'lightBlue', disabled: false },
  { value: '06:45:00', name: '06:45', style: 'lightBlue', disabled: false },
  { value: '07:00:00', name: '07:00', style: 'darkBlue', disabled: false },
  { value: '07:15:00', name: '07:15', style: 'darkBlue', disabled: false },
  { value: '07:30:00', name: '07:30', style: 'darkBlue', disabled: false },
  { value: '07:45:00', name: '07:45', style: 'darkBlue', disabled: false },
  { value: '08:00:00', name: '08:00', style: 'darkBlue', disabled: false },
  { value: '08:15:00', name: '08:15', style: 'darkBlue', disabled: false },
  { value: '08:30:00', name: '08:30', style: 'darkBlue', disabled: false },
  { value: '08:45:00', name: '08:45', style: 'darkBlue', disabled: false },
  { value: '09:00:00', name: '09:00', style: 'darkBlue', disabled: false },
  { value: '09:15:00', name: '09:15', style: 'darkBlue', disabled: false },
  { value: '09:30:00', name: '09:30', style: 'darkBlue', disabled: false },
  { value: '09:45:00', name: '09:45', style: 'darkBlue', disabled: false },
  { value: '10:00:00', name: '10:00', style: 'darkBlue', disabled: false },
  { value: '10:15:00', name: '10:15', style: 'darkBlue', disabled: false },
  { value: '10:30:00', name: '10:30', style: 'darkBlue', disabled: false },
  { value: '10:45:00', name: '10:45', style: 'darkBlue', disabled: false },
  { value: '11:00:00', name: '11:00', style: 'darkBlue', disabled: false },
  { value: '11:15:00', name: '11:15', style: 'darkBlue', disabled: false },
  { value: '11:30:00', name: '11:30', style: 'darkBlue', disabled: false },
  { value: '11:45:00', name: '11:45', style: 'darkBlue', disabled: false },
  { value: '12:00:00', name: 'Noon', style: 'darkGreen', disabled: false },
  { value: '12:15:00', name: '12:15 PM', style: 'darkGreen', disabled: false },
  { value: '12:30:00', name: '12:30 PM', style: 'darkGreen', disabled: false },
  { value: '12:45:00', name: '12:45 PM', style: 'darkGreen', disabled: false },
  { value: '13:00:00', name: '01:00 PM', style: 'darkGreen', disabled: false },
  { value: '13:15:00', name: '01:15 PM', style: 'darkGreen', disabled: false },
  { value: '13:30:00', name: '01:30 PM', style: 'darkGreen', disabled: false },
  { value: '13:45:00', name: '01:45 PM', style: 'darkGreen', disabled: false },
  { value: '14:00:00', name: '02:00 PM', style: 'darkGreen', disabled: false },
  { value: '14:15:00', name: '02:15 PM', style: 'darkGreen', disabled: false },
  { value: '14:30:00', name: '02:30 PM', style: 'darkGreen', disabled: false },
  { value: '14:45:00', name: '02:45 PM', style: 'darkGreen', disabled: false },
  { value: '15:00:00', name: '03:00 PM', style: 'darkGreen', disabled: false },
  { value: '15:15:00', name: '03:15 PM', style: 'darkGreen', disabled: false },
  { value: '15:30:00', name: '03:30 PM', style: 'darkGreen', disabled: false },
  { value: '15:45:00', name: '03:45 PM', style: 'darkGreen', disabled: false },
  { value: '16:00:00', name: '04:00 PM', style: 'darkGreen', disabled: false },
  { value: '16:15:00', name: '04:15 PM', style: 'darkGreen', disabled: false },
  { value: '16:30:00', name: '04:30 PM', style: 'darkGreen', disabled: false },
  { value: '16:45:00', name: '04:45 PM', style: 'darkGreen', disabled: false },
  { value: '17:00:00', name: '05:00 PM', style: 'darkGreen', disabled: false },
  { value: '17:15:00', name: '05:15 PM', style: 'darkGreen', disabled: false },
  { value: '17:30:00', name: '05:30 PM', style: 'darkGreen', disabled: false },
  { value: '17:45:00', name: '05:45 PM', style: 'darkGreen', disabled: false },
  { value: '18:00:00', name: '06:00 PM', style: 'lightGreen', disabled: false },
  { value: '18:15:00', name: '06:15 PM', style: 'lightGreen', disabled: false },
  { value: '18:30:00', name: '06:30 PM', style: 'lightGreen', disabled: false },
  { value: '18:45:00', name: '06:45 PM', style: 'lightGreen', disabled: false },
  { value: '19:00:00', name: '07:00 PM', style: 'lightGreen', disabled: false },
  { value: '19:15:00', name: '07:15 PM', style: 'lightGreen', disabled: false },
  { value: '19:30:00', name: '07:30 PM', style: 'lightGreen', disabled: false },
  { value: '19:45:00', name: '07:45 PM', style: 'lightGreen', disabled: false },
  { value: '20:00:00', name: '08:00 PM', style: 'lightGreen', disabled: false },
  { value: '20:15:00', name: '08:15 PM', style: 'lightGreen', disabled: false },
  { value: '20:30:00', name: '08:30 PM', style: 'lightGreen', disabled: false },
  { value: '20:45:00', name: '08:45 PM', style: 'lightGreen', disabled: false },
  { value: '21:00:00', name: '09:00 PM', style: 'lightGreen', disabled: false },
  { value: '21:15:00', name: '09:15 PM', style: 'lightGreen', disabled: false },
  { value: '21:30:00', name: '09:30 PM', style: 'lightGreen', disabled: false },
  { value: '21:45:00', name: '09:45 PM', style: 'lightGreen', disabled: false },
  { value: '22:00:00', name: '10:00 PM', style: 'lightGreen', disabled: false },
  { value: '22:15:00', name: '10:15 PM', style: 'lightGreen', disabled: false },
  { value: '22:30:00', name: '10:30 PM', style: 'lightGreen', disabled: false },
  { value: '22:45:00', name: '10:45 PM', style: 'lightGreen', disabled: false },
  { value: '23:00:00', name: '11:00 PM', style: 'lightGreen', disabled: false },
  { value: '23:15:00', name: '11:15 PM', style: 'lightGreen', disabled: false },
  { value: '23:30:00', name: '11:30 PM', style: 'lightGreen', disabled: false },
  { value: '23:45:00', name: '11:45 PM', style: 'lightGreen', disabled: false }
]

export const easyStartTimeButtonsStatic = [
  { value: '07:00:00', name: '7', disabled: false },
  { value: '08:00:00', name: '8', disabled: false },
  { value: '09:00:00', name: '9', disabled: false },
  { value: '10:00:00', name: '10', disabled: false },
  { value: '11:00:00', name: '11', disabled: false },
  { value: '12:00:00', name: '12', disabled: false },
  { value: '13:00:00', name: '1', disabled: false },
  { value: '14:00:00', name: '2', disabled: false }
]
export const easyEndTimeButtonsStatic = [
  { value: '11:00:00', name: '11', disabled: false },
  { value: '12:00:00', name: '12', disabled: false },
  { value: '13:00:00', name: '1', disabled: false },
  { value: '14:00:00', name: '2', disabled: false },
  { value: '15:00:00', name: '3', disabled: false },
  { value: '16:00:00', name: '4', disabled: false },
  { value: '17:00:00', name: '5', disabled: false },
  { value: '18:00:00', name: '6', disabled: false }
]

export const monthsByValue = [
  {value: 1, label: 'January'},
  {value: 2, label: 'February'},
  {value: 3, label: 'March'},
  {value: 4, label: 'April'},
  {value: 5, label: 'May'},
  {value: 6, label: 'June'},
  {value: 7, label: 'July'},
  {value: 8, label: 'August'},
  {value: 9, label: 'September'},
  {value: 10, label: 'October'},
  {value: 11, label: 'November'},
  {value: 12, label: 'December'}
]
