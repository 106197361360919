var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      model: {
        value: _vm.localTab,
        callback: function($$v) {
          _vm.localTab = $$v
        },
        expression: "localTab"
      }
    },
    _vm._l(_vm.tabs, function(item) {
      var _obj
      return _c("el-tab-pane", { key: item.key, attrs: { name: item.key } }, [
        _c("span", { attrs: { slot: "label" }, slot: "label" }, [
          _c("i", {
            class:
              ((_obj = {}),
              (_obj["material-icons"] = true),
              (_obj[item.icon] = true),
              (_obj["md-18"] = true),
              _obj)
          }),
          _vm._v(" " + _vm._s(item.label) + " ")
        ]),
        _c(
          "div",
          { staticClass: "tab-container" },
          [
            _c(item.component, {
              ref: "tabs",
              refInFor: true,
              tag: "component",
              attrs: { filePickerKey: _vm.filePickerKey, tab: _vm.tab },
              on: { changeTab: _vm.changeTab }
            })
          ],
          1
        )
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }