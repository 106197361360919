<template>
  <el-dialog
    :visible.sync="visible"
    @open="dialogAboutToAppear"
    :title="`Assignment Detail (${workAssignment.dispatch_number})`"
    append-to-body
    id="glaxisAssignmentDetail"
    width="600px"
    destroy-on-close
  >
    <div v-if="loading" class="row">
      <div style="font-size: 16px; text-align: center;" class="col-md-12">
        <p>Loading please wait...</p>
      </div>
    </div>
    <div v-else>
      <div class="col-md-12 font-size-14">
        <div><b>Referral number:</b> {{ workAssignment.dispatch_number }}</div>
        <div><b>Date of loss:</b> {{ formatDate(workAssignment.date_of_loss) }}</div>
        <div><b>Dispatch create date:</b> {{ formatDate(workAssignment.dispatch_create_date) }}</div>
        <div><b>Dispatch create time:</b> {{ workAssignment.dispatch_create_time }}</div>
        <div><b>Vehicle:</b> {{ formatVehicle }}</div>
        <div><b>Vehicle ID:</b> {{ workAssignment.vehicle_id }}</div>
        <div><b>Vehicle ID source:</b> {{ workAssignment.vehicle_id_source }}</div>
        <div><b>Vehicle ID number:</b> {{ workAssignment.vehicle_id_number }}</div>
        <div><b>Recreational vehicle:</b> {{ workAssignment.recreational_vehicle }}</div>
        <div>
          <b>Customer name:</b> {{ capitalize(workAssignment.insured_or_claimant_first_name) }} 
          {{ capitalize(workAssignment.insured_or_claimant_last_name) }}
        </div>
        <div>
          <b>Customer address:</b> {{ capitalize(workAssignment.insured_or_claimant_address) }}, 
          {{ capitalize(workAssignment.insured_or_claimant_city) }}, 
          {{ workAssignment.insured_or_claimant_state_province }} {{ workAssignment.insured_or_claimant_postal_code }}
        </div>
        <div><b>Phone:</b> {{ workAssignment.insured_or_claimant_telephone }}</div>
        <div><b>Alternate phone:</b> {{ workAssignment.insured_or_claimant_alternate_telephone }}</div>
        <div><b>Deductible:</b> {{ workAssignment.deductible }}</div>
        <div><b>Claim type:</b> {{ workAssignment.claim_type_description }}</div>
        <div><b>Requested appointment date:</b> {{ formatDate(workAssignment.requested_appointment_date) }}</div>
        <div>
          <b>Requested appointment time:</b> 
          {{ workAssignment.begin_requested_time }} - {{ workAssignment.end_requested_time }}
        </div>
        <div><b>Loss reference number:</b> {{ workAssignment.loss_reference_number }}</div>
        <div><b>Glass vendor name:</b> {{ workAssignment.glass_vendor_name }}</div>
        <div>
          <b>Glass vendor address:</b> {{ capitalize(workAssignment.glass_vendor_address) }}, 
          {{ capitalize(workAssignment.glass_vendor_city) }}, {{ workAssignment.glass_vendor_state_province }} 
          {{ workAssignment.glass_vendor_postal_code }}
        </div>
        <div><b>Glass vendor phone:</b> {{ workAssignment.glass_vendor_telephone }}</div>
        <div><b>Glass vendor fax:</b> {{ workAssignment.glass_vendor_fax }}</div>
        <div><b>Max repairs:</b> {{ workAssignment.max_repairs }}</div>
        <div><b>ESchedule indicator:</b> {{ workAssignment.e_schedule_indicator }}</div>
        <div><b>Work location postal code:</b> {{ workAssignment.work_location_postal_code }}</div>
        <div><b>Mobile location description:</b> {{ workAssignment.mobile_location_description }}</div>
        <div><b>Mobile indicator:</b> {{ workAssignment.mobile_indicator }}</div>
        <div><b>Insurer ID:</b> {{ workAssignment.insurer_id }}</div>
        <div><b>Insurance claim indicator:</b> {{ workAssignment.insurance_claim_indicator }}</div>
        <div><b>Insurer name:</b> {{ workAssignment.insurer_name }}</div>
        <div><b>Origination company name:</b> {{ workAssignment.origination_company_name }}</div>
        <div>
          <b>Origination address:</b> {{ capitalize(workAssignment.origination_address) }}, 
          {{ capitalize(workAssignment.origination_city) }}, {{ workAssignment.origination_state_province }} 
          {{ workAssignment.origination_postal_code }}
        </div>
        <div><b>Origination phone:</b> {{ workAssignment.origination_telephone }}</div>
        <div><b>Origination fax:</b> {{ workAssignment.origination_fax }}</div>
        <div><b>Origination email:</b> {{ workAssignment.origination_email }}</div>
        <div><b>Origination URL:</b> {{ workAssignment.origination_url }}</div>
        <div><b>Agent first name:</b> {{ capitalize(workAssignment.agent_first_name) }}</div>
        <div><b>Agent last name:</b> {{ capitalize(workAssignment.agent_last_name) }}</div>
        <div><b>Agent phone:</b> {{ workAssignment.agent_telephone }}</div>
        <div><b>Fixed bid amount:</b> {{ workAssignment.fixed_bid_amount }}</div>
        <div><b>Cause of loss description:</b> {{ workAssignment.cause_of_loss_description }}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import GlaxisWorkAssignment from '@/scripts/objects/glaxis_work_assignment'
import moment from 'moment'
import { sync } from 'vuex-pathify'

export default {
props: ['id', 'skipTableReset'],
  data () {
    return {
      loading: false,
      workAssignment: new GlaxisWorkAssignment(),
      authorizationDetails: [],
      damageInformation: [],
      discountDetails: [],
      insurerComment: [],
      partDetails: []
    }
  },
  computed: {
    visible: sync('glaxis/dialogs@assignmentDetail'),
    formatVehicle () {
      const year = this.workAssignment.vehicle_year
      const make = this.capitalize(this.workAssignment.vehicle_make)
      const model = this.capitalize(this.workAssignment.vehicle_model)
      const style = this.capitalize(this.workAssignment.vehicle_style)

      return year + ' ' + make + ' ' + model + ' ' + style
    }
  },
  methods: {
    dialogAboutToAppear () {
      let self = this
      self.loading = true
      GlaxisWorkAssignment.view(self.id, res => {
        self.workAssignment = res
        self.loading = false
      })
    },
    dialogDisappeared () {
      this.$emit('update:skipTableReset', true)
    },
    capitalize (s) {
      if (typeof s !== 'string') return ''
      return s.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
    },
    formatDate (date) {
      return moment(date).format('M/D/YYYY')
    }
  }
}
</script>

<style>

</style>