var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "performance-chart-bars" },
      _vm._l(_vm.bars, function(bar) {
        return _c(
          "div",
          {
            key: bar.status,
            staticClass: "performance-chart-bar",
            style: { width: _vm.getWidth(bar.count) }
          },
          [
            _c("div", {
              staticClass: "performance-chart-bar-value",
              style: { background: bar.color }
            })
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "performance-chart-labels" },
      _vm._l(_vm.bars, function(bar) {
        return _c(
          "div",
          { key: bar.label, staticClass: "performance-chart-label" },
          [
            _c("i", {
              staticClass: "material-icons md-lens md-18",
              style: { color: bar.color }
            }),
            _c("div", { staticClass: "performance-chart-label-text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.millify(bar.count, { precision: 0, lowercase: true })
                  ) +
                  " "
              )
            ]),
            _c("div", [_vm._v(" " + _vm._s(bar.status) + " ")]),
            _c("div", { staticClass: "performance-chart-label-percent" }, [
              _vm._v(" " + _vm._s(_vm.getWidth(bar.count)) + " ")
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }