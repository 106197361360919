var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "part-tag",
      class: { "with-click": _vm.withClick || _vm.withDropdown },
      style: {
        background: _vm.color || _vm.type.color,
        color: _vm.color || _vm.type.color
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.onClick($event)
        }
      }
    },
    [
      _vm.withClick
        ? _c("part-tag-icon", { ref: "icon", attrs: { part: _vm.part } })
        : _vm._e(),
      _c(
        "span",
        [
          _vm._t("default"),
          _vm._v(
            " " +
              _vm._s(
                _vm.justType
                  ? _vm.type.short
                  : _vm.part.part_number || _vm.part.partno
              ) +
              " "
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }