<template>
  <div>
    <!-- Where this gets attached to the body it does not have this 
      before so we need it here to prevent google autofill -->
    <div style="height: 0px; width: 0px; overflow:hidden;">
      <input name="autofillEmailBlocker"/>
      <input name="autofillPasswordBlocker" type="password"/>
    </div>
    <el-dialog
      center
      append-to-body
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      custom-class="text-message-confirmation-dialog">
      <div style="text-align:center;">
        <a v-if="!showAllInviteHistory && inviteHistory.length > 2" href="javascript:void(0)" @click="showAllInviteHistory = true">Show All History...</a>
        <a v-if="showAllInviteHistory && inviteHistory.length > 2" href="javascript:void(0)" @click="showAllInviteHistory = false">Show Less History</a>
      </div>
      <el-timeline v-if="displayInviteHistory.length > 0" style="margin: 10px 0 0 100px;">
        <el-timeline-item
          v-for="(activity, index) in displayInviteHistory"
          :key="index"
          :icon="activity.icon"
          :type="activity.type"
          :size="activity.size"
          :timestamp="activity.timestamp">
          {{ activity.name }}
        </el-timeline-item>
      </el-timeline>

      <el-form v-if="!historyOnly"
        ref="form"
        :model="form"
        :rules="rules"
        :hide-required-asterisk="true"
        label-width="140px"
        size="small">
        <el-form-item prop="shopPhoneNumber" v-if='shopPhoneNumbers.length'>
          <span slot="label" class="undo-label-styles">Shop phone</span>
          <el-select
            placeholder="Select shop phone number"
            v-model="form.shopPhoneNumber"
            style="width: 100%"
            @focus='focusedShopPhoneSelect'
            >
            <el-option
              v-for="item in shopPhoneNumbers"
              :label="generateLabel(item)"
              :key="item.id"
              :value="item.phone_number">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="phoneNumber">
          <span slot="label" class="undo-label-styles">Phone Number</span>
          <el-input
            placeholder="Enter phone number"
            v-model="formattedPhoneNumber"
            style="width: 100%">
          </el-input>
        </el-form-item>
        <el-form-item prop="body">
          <span slot="label" class="undo-label-styles">Text Message</span>
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 10}"
            placeholder="Text Body"
            v-model="form.body"
          />
        </el-form-item>
        <el-form-item style="word-break: break-word;">
          <p class="mt-3"><span style="color: #F56C6C;">NOTE:</span> A link to accept this invitation will be automatically added in the end of the message.</p>
        </el-form-item>
        <el-form-item>
          <el-button
            class="mb-2 mr-2"
            type="primary"
            @click="handleSubmit"
            :loading="loading">
            Send
          </el-button>
          <el-button
            class="ml-0"
            plain
            @click="visible = false">
            Close
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import TwilioPhoneNumber from '@/scripts/objects/twilio_phone_number'
import { camelCaseToTitle } from '@/scripts/helpers/utilities'
import TextMessageTemplate from '@/scripts/objects/text_message_template'

export default {
  data: () => ({
    showAllInviteHistory: false,
    form: {
      shopPhoneNumber: '',
      phoneNumber: '',
      body: ''
    },
    rules: {
      shopPhoneNumber: { required: true, message: 'Please select shop phone number', trigger: 'change' },
      phoneNumber: { required: true, message: 'Please enter a phone number', trigger: 'change' },
      body: { required: true, message: 'Please enter a text message', trigger: 'change' }
    },
    shopPhoneNumbers: [],
    loading: false,
    showMarketingMessage: true,
    skipModalShow: false
  }),
  computed: {
    shopId: sync('twilio/textMessages/tssInvite/shopId'),
    inviteType: sync('twilio/textMessages/tssInvite/inviteType'),
    inviteId: sync('twilio/textMessages/tssInvite/inviteId'),
    invitePhoneNumber: sync('twilio/textMessages/tssInvite/invitePhoneNumber'),
    inviteHistory: get('twilio/textMessages/tssInvite/formattedHistory'),
    visible: sync('twilio/textMessages/tssInvite/visible'),
    historyOnly: sync('twilio/textMessages/tssInvite/historyOnly'),
    title () {
      if (this.historyOnly) {
        return 'Invite ' + this.techRep + ' Text Message History'
      } else {
        return 'Invite ' + this.techRep + ' Text Message'
      }
    },
    techRep () {
      if (this.inviteType === 'tech') {
        return 'Tech'
      } else if (this.inviteType === 'salesrep') {
        return 'Sales Rep'
      }
      return ''
    },
    displayInviteHistory () {
      var data = []
      var starting = 0
      if (!this.showAllInviteHistory && this.inviteHistory.length > 2) {
        starting = this.inviteHistory.length - 2
      }
      for (var i = starting; i < this.inviteHistory.length; i++) {
        data.push(this.inviteHistory[i])
      }
      return data
    },
    shopName () {
      const tempShops = this.$store.state.shops
      for (var i = 0; i < tempShops.length; i++) {
        if (tempShops[i].id === this.shopId) {
          return tempShops[i].name
        }
      }
      return ''
    },
    organization () {
      const tempShops = this.$store.state.shops
      for (var i = 0; i < tempShops.length; i++) {
        if (tempShops[i].id === this.shopId) {
          const tempOrganizations = this.$store.state.organizations
          for (var j = 0; j < tempOrganizations.length; j++) {
            if (tempOrganizations[j].id === tempShops[i].organization_id) {
              return tempOrganizations[j]
            }
          }
        }
      }
      return null
    },
    organizationName () {
      if (this.organization) {
        return this.organization.name
      }
      return ''
    },
    organizationId () {
      if (this.organization) {
        return this.organization.id
      }
      return null
    },
    formattedPhoneNumber: {
      get () {
        if (this.form.phoneNumber) {
          this.form.phoneNumber = this.form.phoneNumber.length > 10 ? this.form.phoneNumber.substr(0,10) : this.form.phoneNumber
          let temp = ('' + this.form.phoneNumber).replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})$/)

          temp = (!temp[2]) ? temp[1] : temp[1] + '-' + temp[2] + (temp[3] ? '-' + temp[3] : '')
          if (temp.length === 0 && this.form.phoneNumber.length > 0) {
            return ''
          }
          return temp
        } else {
          return this.form.phoneNumber
        }
      },
      set: function (changedVal) {
        var tempPhone = changedVal.replace(/\D+/g, '')
        this.form.phoneNumber = tempPhone
      }
    }
  },
  watch: {
    visible: async function (value) {
      if (value) {
        this.showMarketingMessage = true
        this.clearTssInviteHistory()
        this.form.phoneNumber = ('' + this.invitePhoneNumber).replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})$/)
        this.form = Object.assign({}, {...this.form, body: 'You have been invited to be a ' + this.techRep + ' for ' + this.organizationName + '. Please click the link below to review and accept this invitation. (Link will expire in 48 hours)'})
        this.getShopPhoneNumbers()
        this.getTssInviteHistory()
        if (this.historyOnly) {
          this.showAllInviteHistory = true
        } else {
          this.showAllInviteHistory = false
        }
      } else {
        await this.clearForm()
        if (!this.historyOnly) {
          this.$refs.form.clearValidate()
        }
        if (!this.skipModalShow) {
          if (this.inviteType === 'tech') {
            this.$root.$emit('bv::show::modal', 'techModal')
          } else if (this.inviteType === 'salesrep') {
            this.$root.$emit('bv::show::modal', 'salesrepModal')
          }
        } else {
          this.skipModalShow = false
        }
      }
    }
  },
  methods: {
    openTextMessageAdDialog: call('twilio/textMessages/ad/open'),
    setTextMessageAdReferral: call('twilio/textMessages/ad/setReferral'),
    getTssInviteHistory: call('twilio/textMessages/tssInvite/getHistory'),
    clearTssInviteHistory: call('twilio/textMessages/tssInvite/clearHistory'),
    showAd () {
      this.skipModalShow = true
      this.visible = false
      this.openTextMessageAdDialog()
      this.setTextMessageAdReferral('tss-invite')
    },
    clearForm () {
      this.form.shopPhoneNumber = ''
      this.form.phoneNumber = ''
      this.form.body = ''
    },
    async handleSubmit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          this.loading = false
          const status = await TextMessageTemplate.sendTssInvite({
            shopId: this.shopId,
            inviteType: this.inviteType,
            inviteId: this.inviteId,
            body: this.form.body,
            shopPhoneNumber: this.form.shopPhoneNumber,
            phoneNumber: this.form.phoneNumber
          })
          this.visible = false
          this.loading = false

          if (status !== 'success') {
            if (status === 'The message From/To pair violates a blacklist rule.') {
              this.$message.warning('Customer previously replied STOP to this number, so this message can\'t be delivered.')
            } else {
              this.$message.warning('Something went wrong sending this message. Please double check your data, refresh, and try again.')
            }
            return
          }

          this.$root.$emit('refreshTssInviteHistory')
          this.$message.success('This invitation was successfully sent.')
        } else {
          this.loading = false
        }
      } catch (err) {
        if (err.error === 'The message From/To pair violates a blacklist rule.') {
          this.$message.warning('Customer previously replied STOP to this number, so this message can\'t be delivered.')
        }
        this.loading = false
      }
    },
    async getShopPhoneNumbers () {
      let shopPhoneNumbers = await TwilioPhoneNumber.getForJobMessaging(this.shopId)
      let defaultNumber = ''
      shopPhoneNumbers.forEach(phoneNumber => {
        if (phoneNumber.default) {
          defaultNumber = phoneNumber.phone_number
        }
      })
      if (defaultNumber === '' && shopPhoneNumbers.length > 0) {
        defaultNumber = shopPhoneNumbers[0].phone_number
      }

      if (shopPhoneNumbers.length) {
        this.shopPhoneNumbers = shopPhoneNumbers
        this.form.shopPhoneNumber = defaultNumber
      } else {
        this.shopPhoneNumbers = [{phone_number: '4803516401', name: 'Glassbiller Default'}]
        this.form.shopPhoneNumber = '4803516401'
      }
    },
    generateLabel (i) {
      const number = `${i.phone_number.substr(0, 3)}-${i.phone_number.substr(3, 3)}-${i.phone_number.substr(6)}`
      let label = `${number} - ${i.name}`
      if (i.hasOwnProperty('type')) {
        label += ` ( ${i.type} )`
      }
      return label
    },
    focusedShopPhoneSelect () {
      if (this.shopPhoneNumbers.length === 1 && this.shopPhoneNumbers[0].phone_number === '4803516401' && this.showMarketingMessage) {
        this.showMarketingMessage = false
        this.showAd()
      }
    },
    camelCaseToTitle
  }
}
</script>

<style>
  .text-message-confirmation-dialog {
    @apply t-font-sans;
  }
</style>
