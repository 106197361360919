var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        width: "1000px",
        visible: _vm.visible,
        "custom-class": "acknowledge-alert",
        "show-close": false,
        top: "20px"
      }
    },
    [
      _vm.title
        ? _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("h3", [_vm._v(_vm._s(_vm.title))])
          ])
        : _vm._e(),
      _c(
        "div",
        {
          ref: "outter",
          staticClass: "message-container",
          on: { scroll: _vm.handleScroll }
        },
        _vm._l(_vm.messages, function(message, index) {
          return _c(
            "span",
            { key: "alert-messages-" + index },
            [_c(message, { tag: "component" })],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          !_vm.scrolledToBottom
            ? _c("span", { staticClass: "please-scroll" }, [
                _vm._v("Please scroll to the bottom of Terms to Accept")
              ])
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { disabled: !_vm.scrolledToBottom },
              on: {
                click: function($event) {
                  return _vm.$emit("acknowledged", 1)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.button_text) + " ")]
          ),
          !_vm.required
            ? _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }