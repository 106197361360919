<template>
  <div class="scheduler-header">
    <b-collapse v-model="showFilters" id="header-filters">
      <div class="header-content">
        <div class="header-content-row">
          <scheduler-view-filter v-model="view" />
          <scheduler-route-modal />
        </div>
        <div class="header-content-row">
          <scheduler-team-filter v-model="shops" />
        </div>
        <div class="header-content-row">
          <scheduler-week-filter v-model="week" v-if="isDayView" />
          <scheduler-date-filter v-model="date" v-else />
          <scheduler-card-view-filter v-model="cardView" />
        </div>
        <div class="header-content-row">
          <scheduler-settings-filter />
          <!-- <scheduler-combine-techs-filter /> -->
          <!-- <scheduler-checkbox-filter
            v-model="solidCards"
            label="Card background"
          /> -->
        </div>
        <div class="header-content-row">
          <!-- <scheduler-checkbox-filter v-model="showTotals" label="Tech totals" /> -->
          <div class="mobile-view-button">
            <el-button @click="toggleView">{{
              rowView ? 'Row view' : 'Column view'
            }}</el-button>
          </div>
        </div>
        <div class="header-content-row">
          <!-- <scheduler-checkbox-filter
            v-model="jobConfirmationDetailsVisible"
            label="Job confirmation"
          /> -->
          <!-- <scheduler-checkbox-filter v-model="showTags" label="Show Tags" /> -->
        </div>
      </div>
      <el-row>
        <el-col :span="24">
          <div style="text-align:center;">
            <div style="display: inline-block; margin-right: 10px;">
              <scheduler-job-counters />
            </div>
            <el-divider direction="vertical" class="v" v-if="!isDayView && shops.length > 0" />
            <el-divider class="h" v-if="!isDayView && shops.length > 0" />
            <div v-if="!isDayView && shops.length > 0" style="display: inline-block; margin-left: 10px;" >
              <confirmation-countdown @setUpdatedShopsSettings="setUpdatedShopsSettings" />
            </div>
          </div>
        </el-col>
      </el-row>

    </b-collapse>
    <div class="toggle-filters" @click="toggleFilters" v-if="isMobile">
      Filters
      <v-icon name="angle-down" :class="{ 'fa-rotate-180': showFilters }" />
    </div>
    <div class="separator"></div>
  </div>
</template>

<script>
import SchedulerTeamFilter from './filters/SchedulerTeamFilter'
import SchedulerCardViewFilter from './filters/SchedulerCardViewFilter'
import SchedulerDateFilter from './filters/SchedulerDateFilter'
import SchedulerSettingsFilter from './filters/SchedulerSettingsFilter'
import SchedulerViewFilter from './filters/SchedulerViewFilter'
import SchedulerRouteModal from './SchedulerRouteModal/Index'
import SchedulerCheckboxFilter from './filters/SchedulerCheckboxFilter'
import SchedulerJobCounters from './SchedulerJobCounters'
import SchedulerWeekFilter from './filters/SchedulerWeekFilter'
import ConfirmationCountdown from '@/components/ConfirmationCountdown/Index.vue'
import { isMobile } from '@/scripts/helpers/helpers'
import { sync, get, call } from 'vuex-pathify'
import { mapActions, mapGetters } from 'vuex'
import actions from '@/store/modules/scheduler_v3/types'

export default {
  data() {
    return {
      layoutCol: {
        cols: 12,
        sm: 6,
        md: 3,
        class: 'filter-block'
      },
      showFilters: !isMobile(),
      isMobile: isMobile(),
      confirmationTimerInterval: null
    }
  },
  components: {
    SchedulerTeamFilter,
    SchedulerJobCounters,
    SchedulerCardViewFilter,
    SchedulerDateFilter,
    SchedulerSettingsFilter,
    SchedulerViewFilter,
    SchedulerRouteModal,
    SchedulerWeekFilter,
    ConfirmationCountdown
  },
  computed: {
    ...mapGetters('confirmationsSettings', ['shopsSettings']),
    view: sync('sch_v3/filters@view'),
    shops: sync('sch_v3/filters@shops'),
    date: sync('sch_v3/filters@date'),
    week: sync('sch_v3/filters@week'),
    cardView: sync('sch_v3/filters@cardView'),
    isDayView: get('sch_v3/isDayView'),
    solidCards: sync('sch_v3/filters@solidCards'),
    rowView: sync('sch_v3/filters@rowView'),
    showTotals: sync('sch_v3/filters@showTotals'),
    showTags: sync('sch_v3/filters@showTags'),
    jobConfirmationDetailsVisible: sync(
      'sch_v3/filters@jobConfirmationDetailsVisible'
    ),
    defaultUserStore: get('sch_v3/defaultUserStore'),
    routeList: get('sch_v3/routeList'),
    jobDetails: sync('sch_v3/jobDetails'),
    scheduledJobs() {
      const scheduledJobs = []
      for (const route in this.routeList) {
        if (this.routeList[route].route_entries.length > 0) {
          this.routeList[route].route_entries.forEach((entry) => {
            scheduledJobs.push({
              jobId: entry.job.id,
              confirmationDateTime: entry.job.confirmation_date_time
            })
          })
        }
      }
      if (this.jobDetails?.id) {
        scheduledJobs.push({
          jobId: this.jobDetails.id,
          confirmationDateTime: this.jobDetails.confirmation_date_time
        })
      }
      return scheduledJobs
    }
  },
  methods: {
    ...mapActions('confirmationsSettings', [
      'setShopsSettings',
      'syncShopsTimers',
      'syncSchedulerJobsTimers',
      'setJobsConfirmationDateTime'
    ]),
    updateJobDetailsValue: call(actions.updateJobDetailsValue),
    toggleFilters() {
      this.showFilters = !this.showFilters
    },
    toggleView() {
      this.rowView = !this.rowView
    },
    setDefaultStore() {
      if (!this.shops.length) {
        this.shops = [this.defaultUserStore]
      }
    },
    setUpdatedShopsSettings() {
      this.setShopsSettings(this.shops)
    }
  },
  watch: {
    defaultUserStore(store) {
      this.setDefaultStore()
    },
    shopsSettings() {
      this.syncShopsTimers(this.date)
    },
    date() {
      this.syncShopsTimers(this.date)
    },
    shops() {
      this.setShopsSettings(this.shops)
    },
    routeList() {
      this.syncSchedulerJobsTimers(this.scheduledJobs)
    },
    'jobDetails.scheduled_date': async function (val, old) {
      if (val && old && (val !== old)) {
        const response = await this.setJobsConfirmationDateTime({
          jobIds: [this.jobDetails.id],
          scheduledDate: this.jobDetails.scheduled_date
        })

        const confirmationDateTime = response[this.jobDetails.id]

        this.updateJobDetailsValue({
          key: 'confirmation_date_time',
          value: confirmationDateTime,
          id: this.jobDetails.id
        })

        this.syncSchedulerJobsTimers(this.scheduledJobs)
      }
    }
  },
  mounted() {
    this.setDefaultStore()

    this.confirmationTimerInterval = setInterval(() => {
      this.syncShopsTimers(this.date)
      this.syncSchedulerJobsTimers(this.scheduledJobs)
    }, 60000)
  },
  beforeDestroy() {
    if (this.confirmationTimerInterval !== null) {
      clearInterval(this.confirmationTimerInterval)
      this.confirmationTimerInterval = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerHeader';
</style>

<style lang="scss">
.multiselect__option--highlight {
  background: #f3f3f3 !important;
  color: #35495e !important;
}
.multiselect__placeholder {
  padding-top: 0;
  margin-bottom: 0;
}
.v {
  display: inline-block;
}
.h {
  display: none;
}

@include media-breakpoint-down(sm) {
  .el-select,
  .el-date-editor,
  .el-checkbox,
  .el-button {
    width: 100% !important;
  }
  .h {
    display: block;
  }
  .v {
    display: none;
  }
}
</style>
