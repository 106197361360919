var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12", staticStyle: { "text-align": "center" } },
        [
          _c("div", { staticClass: "doc-popover-title" }, [
            _c(
              "a",
              {
                staticClass: "close-button",
                attrs: { "aria-label": "Close" },
                on: { click: _vm.closePopover }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "d-inline-block",
                    attrs: { "aria-hidden": "true" }
                  },
                  [_vm._v("×")]
                )
              ]
            )
          ])
        ]
      )
    ]),
    _c("div", [
      _c(
        "div",
        { staticClass: "col-12", staticStyle: { "text-align": "center" } },
        [_vm._v(" Choose Type... ")]
      ),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.docTypes, function(docType, index) {
          return _c(
            "span",
            {
              key: _vm.type + index,
              staticClass: "col",
              staticStyle: { "text-align": "center" }
            },
            [
              _c(
                "a",
                {
                  staticClass: "tag tag-light",
                  style: {
                    cursor: "pointer",
                    "white-space": "nowrap",
                    "background-color": _vm.getDocumentTagColorWithType(
                      _vm.type,
                      docType.value
                    ),
                    color: "white"
                  },
                  on: {
                    click: function($event) {
                      return _vm.optionSelected(docType)
                    }
                  }
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.getDocumentTagNameWithType(_vm.type, docType.value)
                      ) +
                      " "
                  )
                ],
                1
              )
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "col-12", staticStyle: { "text-align": "center" } },
        [_vm._v(" Or... ")]
      ),
      _c(
        "div",
        { staticClass: "col-12", staticStyle: { "text-align": "center" } },
        [
          _c(
            "b-button",
            {
              style: { "background-color": "#2287b7", color: "white" },
              on: { click: _vm.openDialog }
            },
            [_vm._v(" Open Dialog ")]
          )
        ],
        1
      )
    ]),
    _vm.saving
      ? _c(
          "div",
          { attrs: { id: "documentSavingSpinner" } },
          [
            _c("v-icon", {
              staticStyle: { color: "black" },
              attrs: {
                id: "spinner-itself",
                name: "spinner",
                spin: "",
                scale: "1.5"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }