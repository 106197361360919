<template>
  <div class="consumer-jobs" v-loading="loading">
    <el-table :data="jobs" border size="mini">
      <el-table-column prop="id" label="ID">
        <template slot-scope="scope">
          <a :href="'/jobs/' + scope.row.id" target="_blank">{{scope.row.id}}</a>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status" />
      <el-table-column prop="install_date" label="Install Date" />
      <el-table-column prop="invoice_date" label="Invoice Date" />
      <el-table-column prop="total_balance_after_payments" label="Balance" />
    </el-table>
  </div>
</template>

<script>
import { sentenceCase } from 'change-case'
import * as R from 'ramda'

export default {
  props: {
    value: Object
  },
  data() {
    return {
      loading: false,
      jobs: []
    }
  },
  methods: {
    async getJobs() {
      if (!this.jobs.length) {
        try {
          this.loading = true
          const { data } = await this.$unum
            .consumer()
            .get(`job/${this.value.id}`)
          this.jobs = data.map((v) => ({
            ...v,
            status: sentenceCase(v.status)
          }))
        } finally {
          this.loading = false
        }
      }
    }
  },
  mounted() {
    this.getJobs()
  }
}
</script>
<style lang="scss" scoped>
.consumer-jobs {
}
</style>
