<template>
  <el-dialog
    append-to-body
    top="120px"
    width="900px"
    custom-class="edit-warehouse-entity-dialog"
    :visible="visible"
    :before-close="handleClose"
  >
    <span slot="title" class="t-text-gray-700 t-text-lg">
      <i class="el-icon-document"/>
      <span class="t-ml-3">Create Return</span>
    </span>

    <div class="t-pt-6">
      <el-form
        :model="form"
        label-position="top"
        ref="form"
        size="small"
        class="t-mx-6"
        hide-required-asterisk
      >
        <el-form-item prop="reference">
          <span slot="label" class="undo-label-styles">Reference</span>
          <el-input
            v-model="form.reference"
            placeholder="Enter Reference"
          />
        </el-form-item>
        <div
          v-for="(line, index) of poLines"
          :key="index"
        >
          <div
            class="t-flex t-border t-border-solid t-border-gray-200 t-rounded-md t-px-4
              t-py-2 t-shadow t-bg-gray-50 t-mb-4"
          >
            <div class="t-w-1/3">
              <div class="t-text-gray-500 t-text-xs t-h-5">Part #</div>
              <div class="t-mt-1">{{ line.itemName }}</div>
            </div>

            <div class="t-w-1/3">
              <div class="t-text-gray-500 t-text-xs t-h-5">Total Qty</div>
              <div class="t-mt-1">{{ line.quantity }}</div>
            </div>

            <div class="t-w-1/3">
              <div class="t-text-gray-500 t-text-xs t-h-5">Return</div>
              <div
                v-if="line.returnedCount > 0"
                class="t-flex t-flex-col t-items-start t-space-y-2"
              >
                <div
                  v-for="(ret, index) in line.returns"
                  :key="index"
                  class="t-px-2 t-rounded t-opacity-80 t-text-white
                    t-cursor-pointer t-bg-orange-400"
                  @click="openReturn(ret.id)"
                >
                  RTN {{ ret.internalId }}
                </div>
              </div>
              <el-form-item
                v-else
                label-width="0"
                class="t-mb-0"
              >
                <el-checkbox
                  size="large"
                  :checked="form.purchaseOrderLineNumbers.includes(line.purchaseOrderLineNumber)"
                  @change="changed({
                    poLineNumber: line.purchaseOrderLineNumber,
                    value: $event
                  })"
                />
              </el-form-item>
            </div>
          </div>
        </div>

        <div v-if="form.purchaseOrderLineNumbers.length > 0">
          <el-form-item prop="dropOffDate">
            <span slot="label" class="undo-label-styles">Drop off date</span>
            <el-date-picker
              v-model="form.dropOffDate"
              type="date"
              format="MM/dd/yyyy"
              value-format="yyyy-MM-dd"
              placeholder="Pick a date"
              class="t-w-72"
            />
          </el-form-item>

          <el-form-item prop="returnStatusId">
            <span slot="label" class="undo-label-styles">Status</span>
            <el-select
              v-model="form.returnStatusId"
              filterable
              class="t-w-72"
            >
              <el-option label="Opened" :value="1" />
              <el-option label="Confirmed" :value="2" />
            </el-select>
          </el-form-item>

          <el-form-item prop="note">
            <span slot="label" class="undo-label-styles">Notes</span>
            <el-input
              v-model="form.note"
              type="textarea"
              placeholder="Enter notes"
              :rows="3"
            />
          </el-form-item>
        </div>
      </el-form>

      <div
        class="t-py-3 t-px-6 t-flex t-justify-between t-border-0 t-border-t t-border-solid">
        <el-button
          plain
          size="small"
          @click="handleClose"
        >
          Close
        </el-button>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          :loading="submitting"
          :disabled="form.purchaseOrderLineNumbers.length === 0"
          @click="handleSubmit"
        >
          Create
        </el-button>
      </div>

    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    poId: Number,
    poLines: Array
  },

  data: () => ({
    form: {
      purchaseOrderLineNumbers: [],
      returnStatusId: 1,
      dropOffDate: '',
      reference: '',
      note: ''
    },
    submitting: false
  }),

  methods: {
    handleClose() {
      this.form = {
        purchaseOrderLineNumbers: [],
        returnStatusId: 1,
        dropOffDate: '',
        reference: '',
        note: ''
      }
      this.$emit('update:visible', false)
    },

    openReturn(id) {
      this.$store.dispatch('warehouse/openReturnDialog', id)
    },

    changed({ poLineNumber, value }) {
      if (value) {
        this.form.purchaseOrderLineNumbers.push(poLineNumber)
      } else {
        this.form.purchaseOrderLineNumbers = this.form.purchaseOrderLineNumbers.filter(i => i !== poLineNumber)
      }
    },

    async handleSubmit() {
      if (this.form.purchaseOrderLineNumbers.length === 0) {
        this.$message.warning('No units are selected to return')
        return
      }
      try {
        this.submitting = true
        await this.$refs.form.validate()
        await this.$unum.warehouse().post('returns/create-from-po', {
          purchaseOrderId: this.poId,
          ...this.form
        })
        this.$message.success('Return was created')
        this.$emit('created')
        this.handleClose()
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>
