import { chatTypes } from './types'

export const getters = {
  [chatTypes.getters.getTotalUnreadMessages]: state => {
    let total = 0
    for (const i in state.unreadMessagesCount) {
      total += state.unreadMessagesCount[i]
    }
    return total
  },

  [chatTypes.getters.getTypingMembers]: state => {
    if (
      !state.activeChannel ||
      !state.membersTyping.hasOwnProperty(state.activeChannel.uniqueName)
    ) {
      return []
    }
    return state.membersTyping[state.activeChannel.uniqueName]
  }
}
