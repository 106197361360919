import * as R from 'ramda'
import { mergeSpec } from './ramda.helpers'
import {
  formatAppointmentDate,
  parseTime,
  parseDateToString
} from './date.helpers'
import { belongsToTech, isInvalidTime } from './job.helpers'
import { isSameDay, isValid, parse } from 'date-fns'

export const fixAppointment = mergeSpec({
  date: R.pipe(
    R.prop('date'),
    formatAppointmentDate
  ),
  tech_id: R.cond([[R.prop('tech_id'), R.prop('tech_id')], [R.T, R.always(0)]])
})

export const convertTechIdToString = mergeSpec({
  tech_id: R.pipe(
    R.prop('tech_id'),
    R.toString
  )
})

export const filterTechAppts = techId =>
  R.pipe(
    R.map(convertTechIdToString),
    R.filter(belongsToTech(techId))
  )

export const filterApptsByDate = date =>
  R.filter(
    R.pipe(
      R.prop('date'),
      val => isSameDay(date, val)
    )
  )

export const getStartTime = R.prop('time_start')
export const getEndTime = R.prop('time_end')

export const isApptTimeUnscheduled = R.anyPass([
  R.pipe(
    getStartTime,
    isInvalidTime
  ),
  R.pipe(
    getEndTime,
    isInvalidTime
  )
])

export const isApptTimeScheduled = R.pipe(
  isApptTimeUnscheduled,
  R.not
)

export const getApptTimeString = R.cond([
  [
    isApptTimeScheduled,
    R.pipe(
      R.pick(['time_start', 'time_end']),
      R.values,
      R.map(parseTime),
      R.join(' to ')
    )
  ]
])

export const getApptDateString = R.pipe(
  R.prop('date'),
  R.cond([
    [
      R.pipe(
        parse,
        isValid
      ),
      parseDateToString
    ]
  ])
)
