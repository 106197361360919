import { make } from 'vuex-pathify'
import Job from '@/scripts/objects/job'
import moment from 'moment'
import { stat } from 'fs'

const state = {
  visible: false,
  jobId: null,
  signatureLink: null,
  salesideJobId: null,
  shopId: null,
  history: [],
  currentToken: null,
}

const mutations = make.mutations(state)

const getters = {
  formattedHistory: (state) => {
    let data = []
    
    for (const i of state.history) {
      let historyItem = {
        size: 'large',
        timestamp: moment(i.created_at).format('LLLL')
      }

      if (i.status_name === 'sent') {
        if (i.type_name === 'techside' || i.type_name === 'saleside') {
          historyItem.name = `A ${i.type_name} job signature request was sent`
        } else {
          historyItem.name = `A ${i.type_name} job signature request was sent by ${i.username}`
        }

        historyItem.type = 'warning'
      } else if (i.status_name === 'signed') {
        if (i.username === null) {
          if (i.type_name === 'techside' || i.type_name === 'saleside') {
            historyItem.name = `Customer signed (${i.type_name} request)`
          } else {
            historyItem.name = 'Customer signed'
          }
        } else {
          historyItem.name = i.username + ' manually updated status to confirmed'
        }

        historyItem.type = 'success'
      } else if (i.status_name === 'failed') {
        if (i.username === null) {
          historyItem.name = 'Customer signature failed'
        } else {
          historyItem.name = i.username + ' manually updated status to failed'
        }

        historyItem.type = 'danger'
      }

      data.push(historyItem)
    }

    return data
  },
  formattedToken: (state) => {
    return state.currentToken
  },
  status: (state) => {
    return state.history[state.history.length - 1]
  },

  signatureWasSentBefore: (state) => {
    const signatures = state.history.filter(item => {
      return item.status_name === 'sent'
    })
    return signatures.length > 0
  }
}

const actions = {
  open ({ commit }) {
    commit('SET_VISIBLE', true)
  },
  setJobId ({ commit }, jobId) {
    commit('SET_JOB_ID', jobId)
  },
  setSignatureLink ({ commit }, signatureLink) {
    commit('SET_SIGNATURE_LINK', signatureLink)
  },
  setSalesideJobId ({ commit }, salesideJobId) {
    commit('SET_SALESIDE_JOB_ID', salesideJobId)
  },
  setShopId ({ commit }, shopId) {
    commit('SET_SHOP_ID', shopId)
  },
  async getHistory ({ commit }, { jobId, salesideJobId }) {
    const history = await Job.getTextMessageSignatureHistory(jobId, salesideJobId)
    commit('SET_HISTORY', history)
  },
  clearHistory ({ commit }) {
    commit('SET_HISTORY', [])
  },
  async getCurrentToken ({ commit }, jobId) {
    const token = await Job.getTextMessageSignatureCurrentToken(jobId)
    commit('SET_CURRENT_TOKEN', token)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
