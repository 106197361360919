<template>
  <div>
    <b-modal
      @show="dialogAboutToAppear"
      @hide="dialogAboutToDisappear"
      no-close-on-backdrop no-close-on-esc
      :title="title"
      :id="computedModalId"
      header-bg-variant='primary'
      header-text-variant='light'
      size="lg">
      <div class="row">
        <div class="form-group col-sm-12 col-md-6">
          <Email ref='emailToComponent' :title='"Email Address"' id='email-address' :value.sync="emailTo" :placeholder="''" :required='false'/>
        </div>
        <div class="form-group col-sm-12 col-md-6">
          <Email ref='emailFromComponent' :title='"Reply Email Address"' id='email-reply' :value.sync="emailFrom" :placeholder="''" :required='false'/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label for='email-subject'>Subject</label>
          <input type='text' id='email-subject' class="form-control form-control-sm autocomplete-off" v-model="emailSubject"/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label for='email-body'>Body</label>
          <b-form-textarea type='textarea' id='email-body' class="form-control form-control-sm" placeholder='Enter Message Body...' rows='3' max-rows='5' v-model="emailMessage"></b-form-textarea>
        </div>
      </div>
      <div v-if="mainPDFAttachmentName && mainPDFAttachmentName.length > 0" class="row">
        <div class="form-group col-sm-12 col-md-12 col-lg-5">
          <div class="form-check label label-gray label-sm mt-1">
            <input class="form-check-input" type="checkbox" id="include-docs" v-model='includePdf' />
            <label class="form-check-label" for="include-docs">{{mainPDFAttachmentName}}</label>
          </div>
        </div>
      </div>
      <br/>
      <!--
        <div class="row">
        <div class="form-group col">
          Include Attachments:&nbsp;&nbsp;<button @click='selectAllClicked'>{{selectedDocuments.length === documentTags.length ? 'Remove' : 'Select'}} All</button>&nbsp;&nbsp;&nbsp;&nbsp;<span v-if='hasWarranty'><input type='checkbox' v-model='includeWarranty'>&nbsp;Include Warranty</span>
        </div>
      </div>
      <br/>
      <div class="row" v-if='documentTags && documentTags.length > 0'>
        <div class="form-group col">
          <b-form-checkbox-group v-model='selectedDocuments'>
            <b-form-checkbox v-for='(documentTag, index) in documentTags' :key="index" :value='documentTag.id'><span v-bind:style="{ 'background-color': documentTag.tagColor }" class="tag tag-light">{{documentTag.tagName}}</span></b-form-checkbox>
          </b-form-checkbox-group>
        </div>
      </div>
      <br/>
      -->
      <div class="row">
        <div class="form-group col">
          <b-table :busy="loadingEmailHistory" sort-by='sent' :sort-desc='false' :per-page='5' :current-page='currentPage' striped hover :items="emailHistory" :fields="tableFields" responsive>
          </b-table>
          <b-pagination align='right' size='md' :total-rows="emailHistory.length" v-model="currentPage" :per-page="5"></b-pagination>
        </div>
      </div>
      <div slot="modal-footer" class='w-100'>
        <div class='float-right'>
          <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="sendEmail">Send Email</button>
          &nbsp;&nbsp;&nbsp;
          <button type='button' class='btn btn-secondary' variant="primary" @click="cancelButtonPressed">Cancel</button>
        </div>
      </div>
    </b-modal>
    <LoadingDialog :id="computedLoadingModalId" :isOpen='emailSending' :message="emailSendingMessage" />
  </div>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
import Email from '@/components/Helpers/Email.vue'
import LoadingDialog from '@/components/modals/LoadingDialog.vue'
import Pdf from '@/scripts/objects/pdf'
import EmailHistory from '@/scripts/objects/email_history'
import {
  reportPrintToPdfUrl
} from '@/constants/endpoints'
import Api from '@/services/Api'

export default {
  props: ['shopId', 'title', 'toEmailAddress', 'email_subject', 'mainPDFAttachmentName',
    'email_type', 'object_id', 'modalToReturnTo', 'modalId'
  ],
  data () {
    return {
      currentPage: 1,
      emailToSend: {
        to: '',
        subject: '',
        message: '',
        from: '',
        status: ''
      },
      selectedDocuments: [],
      includePdf: true,
      emailSending: false,
      emailSendingMessage: '',
      loadingEmailHistory: false,
      emailHistory: []
    }
  },
  methods: {
    cancelButtonPressed: function () {
      this.$root.$emit('bv::hide::modal', this.computedModalId)
    },
    sendEmail: function () {
      if (!this.emailTo || this.emailTo.length <= 0) {
        alert('Email address is required to send email')
        return
      }

      this.emailToSend.attachments = this.selectedDocuments
      var self = this
      this.emailToSend.includePdf = this.includePdf
      this.emailToSend.shopId = this.shopId

      if (this.includePdf) {
        this.emailSendingMessage = 'Generating pdf for email...'
        this.emailSending = true

        var currentUser = self.$store.state.user

        if (this.email_type === 'purchaseOrder') {
          Pdf.renderPurchaseOrderPdfs(self.shopId, self.object_id, currentUser.id, currentUser.username, function (res) {
            let temp = window.btoa(
              new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            )
            let result = {
              'content-type': res.headers['content-type'],
              'file-name': `${self.mainPDFAttachmentName}.pdf`,
              'content': temp
            }
            self.emailToSend.mainPdf = result
            self.emailSendingMessage = 'Pdf generated, sending email...'

            self.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
              self.$store.commit('updateUserReplyTo', self.emailToSend.from)
              self.emailSending = false
              self.$root.$emit('bv::show::modal', self.computedModalId)
            })
          })
        } else if (this.email_type === 'bill') {
          Pdf.renderBillPdfs(self.shopId, self.object_id, currentUser.id, currentUser.username, function (res) {
            let temp = window.btoa(
              new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            )
            let result = {
              'content-type': res.headers['content-type'],
              'file-name': `${self.mainPDFAttachmentName}.pdf`,
              'content': temp
            }
            self.emailToSend.mainPdf = result
            self.emailSendingMessage = 'Pdf generated, sending email...'

            self.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
              self.$store.commit('updateUserReplyTo', self.emailToSend.from)
              self.emailSending = false
              self.$root.$emit('bv::show::modal', self.computedModalId)
            })
          })
        } else if (this.email_type === 'bill_payment') {
          Pdf.renderBillPaymentPdfs(self.shopId, self.object_id, currentUser.id, currentUser.username, function (res) {
            let temp = window.btoa(
              new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            )
            let result = {
              'content-type': res.headers['content-type'],
              'file-name': `${self.mainPDFAttachmentName}.pdf`,
              'content': temp
            }
            self.emailToSend.mainPdf = result
            self.emailSendingMessage = 'Pdf generated, sending email...'

            self.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
              self.$store.commit('updateUserReplyTo', self.emailToSend.from)
              self.emailSending = false
              self.$root.$emit('bv::show::modal', self.computedModalId)
            })
          })
        } else if (this.email_type === 'journal_entry') {
          Pdf.renderJournalEntryPdfs(self.shopId, self.object_id, currentUser.id, currentUser.username, function (res) {
            let temp = window.btoa(
              new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            )
            let result = {
              'content-type': res.headers['content-type'],
              'file-name': `${self.mainPDFAttachmentName}.pdf`,
              'content': temp
            }
            self.emailToSend.mainPdf = result
            self.emailSendingMessage = 'Pdf generated, sending email...'

            self.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
              self.$store.commit('updateUserReplyTo', self.emailToSend.from)
              self.emailSending = false
              self.$root.$emit('bv::show::modal', self.computedModalId)
            })
          })
        } else if (this.email_type === 'expense') {
          Pdf.renderExpensePdfs(self.shopId, self.object_id, currentUser.id, currentUser.username, function (res) {
            let temp = window.btoa(
              new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            )
            let result = {
              'content-type': res.headers['content-type'],
              'file-name': `${self.mainPDFAttachmentName}.pdf`,
              'content': temp
            }
            self.emailToSend.mainPdf = result
            self.emailSendingMessage = 'Pdf generated, sending email...'

            self.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
              self.$store.commit('updateUserReplyTo', self.emailToSend.from)
              self.emailSending = false
              self.$root.$emit('bv::show::modal', self.computedModalId)
            })
          })
        } else if (this.email_type === 'receive') {
          Pdf.renderReceivePdfs(self.shopId, self.object_id, currentUser.id, currentUser.username, function (res) {
            let temp = window.btoa(
              new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            )
            let result = {
              'content-type': res.headers['content-type'],
              'file-name': `${self.mainPDFAttachmentName}.pdf`,
              'content': temp
            }
            self.emailToSend.mainPdf = result
            self.emailSendingMessage = 'Pdf generated, sending email...'

            self.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
              self.$store.commit('updateUserReplyTo', self.emailToSend.from)
              self.emailSending = false
              self.$root.$emit('bv::show::modal', self.computedModalId)
            })
          })
        } else if (this.email_type === 'techPickup') {
          Pdf.renderTechPickupPdfs(self.shopId, self.object_id, currentUser.id, currentUser.username, function (res) {
            let temp = window.btoa(
              new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            )
            let result = {
              'content-type': res.headers['content-type'],
              'file-name': `${self.mainPDFAttachmentName}.pdf`,
              'content': temp
            }
            self.emailToSend.mainPdf = result
            self.emailSendingMessage = 'Pdf generated, sending email...'

            self.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
              self.$store.commit('updateUserReplyTo', self.emailToSend.from)
              self.emailSending = false
              self.$root.$emit('bv::show::modal', self.computedModalId)
            })
          })
        } else if (this.email_type === 'vendorReturn') {
          Pdf.renderVendorReturnPdfs(self.shopId, self.object_id, currentUser.id, currentUser.username, function (res) {
            let temp = window.btoa(
              new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            )
            let result = {
              'content-type': res.headers['content-type'],
              'file-name': `${self.mainPDFAttachmentName}.pdf`,
              'content': temp
            }
            self.emailToSend.mainPdf = result
            self.emailSendingMessage = 'Pdf generated, sending email...'

            self.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
              self.$store.commit('updateUserReplyTo', self.emailToSend.from)
              self.emailSending = false
              self.$root.$emit('bv::show::modal', self.computedModalId)
            })
          })
        } else if (this.email_type === 'profit_and_loss_report' || this.email_type === 'balance_sheet_report') {
          const html = document.querySelector('.table-container').outerHTML
          Api.post(reportPrintToPdfUrl, { html }, {responseType: 'arraybuffer'}).then(res => {
            let temp = window.btoa(
              new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            )
            let result = {
              'content-type': res.headers['content-type'],
              'file-name': `${self.mainPDFAttachmentName}.pdf`,
              'content': temp
            }
            self.emailToSend.mainPdf = result
            self.emailSendingMessage = 'Pdf generated, sending email...'
            self.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
              self.$store.commit('updateUserReplyTo', self.emailToSend.from)
              self.emailSending = false
              self.$root.$emit('bv::show::modal', self.computedModalId)
            })
          }, error => {
            console.log('error pdf orccurred: ', error)
            self.emailSending = false
            self.$root.$emit('bv::show::modal', self.computedModalId)
          })
        } else if (this.email_type === 'unit_adjustments') {
          Pdf.renderAdjustmentPdfs(self.shopId, self.object_id, currentUser.id, currentUser.username, function (res) {
            let temp = window.btoa(
              new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            )
            let result = {
              'content-type': res.headers['content-type'],
              'file-name': `${self.mainPDFAttachmentName}.pdf`,
              'content': temp
            }
            self.emailToSend.mainPdf = result
            self.emailSendingMessage = 'Pdf generated, sending email...'
            self.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
              self.$store.commit('updateUserReplyTo', self.emailToSend.from)
              self.emailSending = false
              self.$root.$emit('bv::show::modal', self.computedModalId)
            })
          })
        }
      } else {
        this.emailSendingMessage = 'Generating and sending email...'
        this.emailSending = true

        this.sendGenericEmail(self.emailToSend, self.shopId, self.email_type, self.object_id, (response) => {
          self.$store.commit('updateUserReplyTo', self.emailToSend.from)
          self.emailSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        })
      }
    },
    dialogAboutToDisappear: function () {
      if (!this.emailSending) {
        if (this.modalToReturnTo && this.modalToReturnTo.length > 0) {
          setTimeout(() => {
            this.$root.$emit('bv::show::modal', this.modalToReturnTo)
          }, 100)
        }
      }
    },
    dialogAboutToAppear: function () {
      this.emailToSend = {
        to: this.toEmailAddress,
        subject: this.email_subject,
        message: '',
        from: (this.$store.state.user.replyto_email_cache !== '') ? this.$store.state.user.replyto_email_cache : '',
        status: ''
      }

      let self = this
      self.loadingEmailHistory = true
      EmailHistory.getAll(self.shopId, self.email_type, self.object_id, function(emailHistory) {
        self.emailHistory = emailHistory
        self.loadingEmailHistory = false
      }, function (error) {
        console.log('error: ', error)
        self.loadingEmailHistory = false
      })
    },
    shortcutClicked: function (email) {
      this.emailToSend.to = email
    },
    selectAllClicked: function () {
      if (this.selectedDocuments.length === this.documentTags.length) {
        this.selectedDocuments = []
      } else {
        this.selectedDocuments = []
        for (var i = 0; i < this.documentTags.length; ++i) {
          this.selectedDocuments.push(this.documentTags[i].id)
        }
      }
    }
  },
  computed: {

    tableFields () {
      if (this.email_type === 'profit_and_loss_report' ||
        this.email_type === 'balance_sheet_report') {
        return [
          {key: 'destination', label: 'Sent To', sortable: true},
          {key: 'email_subject', label: 'Subject', sortable: true},
          {key: 'sent', label: 'Date/Time', sortable: true}
        ]
      } else {
        return [
          {key: 'destination', label: 'Sent To', sortable: true},
          {key: 'sent', label: 'Date/Time', sortable: true}
        ]
      }
    },
    computedLoadingModalId () {
      return this.computedModalId + '-loading-modal'
    },
    computedModalId() {
      if (this.modalId && this.modalId.length > 0) {
        return this.modalId
      } else {
        return 'genericEmailModal'
      }
    },
    emailTo: {
      get () {
        return this.emailToSend.to
      },
      set (val) {
        this.emailToSend.to = val
      }
    },
    emailFrom: {
      get () {
        return this.emailToSend.from
      },
      set (val) {
        this.emailToSend.from = val
      }
    },
    emailSubject: {
      get () {
        return this.emailToSend.subject
      },
      set (val) {
        this.emailToSend.subject = val
      }
    },
    emailMessage: {
      get () {
        return this.emailToSend.message
      },
      set (val) {
        this.emailToSend.message = val
      }
    }
  },
  mixins: [UtilityMixin],
  components: {
    Email,
    LoadingDialog
  }
}
</script>

<style>

</style>
