var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.allResults.length
    ? _c(
        "div",
        { ref: "scroll", staticClass: "global-search-results" },
        [
          _c(
            "el-scrollbar",
            {
              ref: "scrollbar",
              attrs: {
                "wrap-class": "global-search-results-container",
                "view-class": "global-search-results-list"
              }
            },
            [
              _vm.showRecent && _vm.recent.length
                ? _c("search-results-group", {
                    attrs: {
                      rows: _vm.recent,
                      title: "Recent searches",
                      action: "Clear",
                      type: "recent",
                      highlight: _vm.highlight
                    },
                    on: {
                      highlight: function($event) {
                        _vm.highlight = $event
                      },
                      select: function($event) {
                        return _vm.select($event)
                      },
                      action: function($event) {
                        _vm.recent = []
                      }
                    }
                  })
                : [
                    _c(
                      "el-row",
                      [
                        _vm.results.jobs && _vm.results.jobs.length > 0
                          ? _c(
                              "el-col",
                              {
                                attrs: {
                                  md:
                                    _vm.results.consumers &&
                                    _vm.results.consumers.length > 0
                                      ? 12
                                      : 24,
                                  sm: 24
                                }
                              },
                              [
                                _vm.results.jobs && _vm.results.jobs.length > 0
                                  ? _c("search-results-group", {
                                      attrs: {
                                        rows: _vm.results.jobs,
                                        title: "Jobs",
                                        type: "job",
                                        highlight: _vm.highlight
                                      },
                                      on: {
                                        highlight: function($event) {
                                          _vm.highlight = $event
                                        },
                                        select: function($event) {
                                          return _vm.select($event)
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.noMoreJobResults
                                  ? _c("load-more", {
                                      on: {
                                        loadMore: function($event) {
                                          return _vm.$emit("loadMore")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.results.consumers &&
                        _vm.results.consumers.length > 0
                          ? _c(
                              "el-col",
                              {
                                attrs: {
                                  md:
                                    _vm.results.jobs &&
                                    _vm.results.jobs.length > 0
                                      ? 12
                                      : 24,
                                  sm: 24
                                }
                              },
                              [
                                _vm.results.consumers &&
                                _vm.results.consumers.length > 0
                                  ? _c("search-results-group", {
                                      attrs: {
                                        rows: _vm.results.consumers,
                                        title: "Consumers",
                                        type: "consumer",
                                        highlight: _vm.highlight,
                                        offset: _vm.results.jobs.length
                                      },
                                      on: {
                                        highlight: function($event) {
                                          _vm.highlight = $event
                                        },
                                        select: function($event) {
                                          return _vm.select($event)
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.noMoreConsumerResults
                                  ? _c("load-more", {
                                      on: {
                                        loadMore: function($event) {
                                          return _vm.$emit("loadMore")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _vm.results.salesidejobs &&
                        _vm.results.salesidejobs.length > 0
                          ? _c(
                              "el-col",
                              {
                                attrs: {
                                  md:
                                    _vm.results.jobs &&
                                    _vm.results.jobs.length > 0 &&
                                    _vm.results.consumers &&
                                      _vm.results.consumers.length > 0
                                      ? 12
                                      : 24,
                                  sm: 24
                                }
                              },
                              [
                                _vm.results.salesidejobs &&
                                _vm.results.salesidejobs.length > 0
                                  ? _c("search-results-group", {
                                      attrs: {
                                        rows: _vm.results.salesidejobs,
                                        title: "Mobile Jobs",
                                        type: "salesidejob",
                                        highlight: _vm.highlight,
                                        offset: _vm.results.jobs.length
                                      },
                                      on: {
                                        highlight: function($event) {
                                          _vm.highlight = $event
                                        },
                                        select: function($event) {
                                          return _vm.select($event)
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.noMoreSalesideJobResults
                                  ? _c("load-more", {
                                      on: {
                                        loadMore: function($event) {
                                          return _vm.$emit("loadMore")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }