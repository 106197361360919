<template>
  <base-layout :data="data">
    <div slot="title">QuickBooks Export Error</div>

    <div>
       An error occured during a QuickBooks export for {{data.details.shopName}}.
    </div>
  </base-layout>

</template>

<script>
import BaseLayout from './_BaseLayout';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  components: { BaseLayout }
}
</script>
