var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", { attrs: { data: _vm.data } }, [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("Job Received")
    ]),
    _c("div", [
      _vm._v(" SaleSIDE job "),
      _c(
        "a",
        {
          attrs: { href: "javascript:void(0)" },
          on: { click: _vm.openMobileInbox }
        },
        [_vm._v(_vm._s(_vm.data.details.salesidejobId))]
      ),
      _vm._v(" has been added to your mobile inbox. ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }