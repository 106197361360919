<template>
  <div v-on:click.stop v-on:keydown.space.stop :class="classes">
    <input v-on:click.stop v-on:keydown.space.stop :disabled="inDisabled" style="margin-top:2px" :class="{'form-check-input':true}" type="checkbox" :id="_uid" v-model='internalValue'>
    <label :class="{'form-check-label':true}" :for="_uid">{{label}}</label>
  </div>
</template>

<script>
export default {
  props: ['label', 'value', 'inDisabled', 'inClass', 'activeColorClass'],
  computed: {
    classes () {
      var classes = ['form-check label', 'label-gray', 'label-sm']
      if (this.internalValue) {
        var colorClass = 'label-active-light-purple'
        if (this.activeColorClass && this.activeColorClass.length > 0) {
          colorClass = this.activeColorClass
        }
        classes.push(colorClass)
      }
      if (this.inClass && this.inClass.length > 0) {
        var inClasses = this.inClass.split(',')
        for (var i = 0; i < inClasses.length; i++) {
          if (inClasses[i] && inClasses[i].trim().length > 0) {
            classes.push(inClasses[i].trim())
          }
        }
      }
      return classes
    },
    internalValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    }
  }
}
</script>

<style>

</style>
