var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-with-label v-input",
      class: Object.assign(
        {},
        {
          focused: _vm.focused || _vm.forceFocus,
          "has-value": _vm.value,
          error: _vm.error,
          emptyRed: _vm.emptyRed,
          valid: _vm.valid,
          right: _vm.align === "right",
          "with-address-input": _vm.type === "address",
          "with-time-slot": _vm.type === "time"
        },
        _vm.size ? ((_obj = {}), (_obj[_vm.size] = true), _obj) : {},
        { locked: _vm.locked }
      )
    },
    [
      _vm.displayLabel
        ? _c(
            "div",
            { staticClass: "sticky-label" },
            [
              _c(
                "div",
                {
                  style: {
                    "background-color": "white",
                    "margin-top":
                      _vm.tooltip && _vm.tooltip.length > 0 ? "2px" : "0px"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.label) + " ")]
              ),
              _vm.tooltip && _vm.tooltip.length > 0
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: _vm.tooltip,
                        "raw-content": "",
                        placement: "top",
                        "popper-class": "inputLabelToolTip"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-info t-text-xs" })]
                  )
                : _vm._e(),
              _c("span", { staticClass: "asterisk" }, [_vm._v("*")]),
              _vm.value && _vm.$listeners.edit
                ? _c(
                    "div",
                    {
                      staticClass: "input-edit",
                      on: {
                        click: function($event) {
                          return _vm.$emit("edit")
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "material-icons md-edit edit-icon"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "edit-text" }, [_vm._v("Edit")])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.type === "address"
        ? _c(
            "address-input",
            _vm._b(
              {
                attrs: { disabled: _vm.disabled, loose: "" },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  change: _vm.onChange,
                  clear: function($event) {
                    return _vm.$emit("input", null)
                  }
                },
                model: {
                  value: _vm.param,
                  callback: function($$v) {
                    _vm.param = $$v
                  },
                  expression: "param"
                }
              },
              "address-input",
              _vm.options,
              false
            ),
            [_vm._t("prefix", null, { slot: "prefix" })],
            2
          )
        : _vm.type === "date"
        ? _c("el-date-picker", {
            ref: "inputRef",
            attrs: { size: _vm.size || "small", placeholder: _vm.placeholder },
            on: {
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              clear: function($event) {
                return this.$emit("input", null)
              }
            },
            model: {
              value: _vm.param,
              callback: function($$v) {
                _vm.param = $$v
              },
              expression: "param"
            }
          })
        : _vm.select
        ? _c(
            "select-wrapper",
            _vm._b(
              {
                ref: "inputRef",
                attrs: {
                  label: _vm.label,
                  multiple: _vm.multiple,
                  collapseTags: _vm.collapseTags,
                  withSelectAll: _vm.withSelectAll,
                  size: _vm.size || "small",
                  labelKey: _vm.labelKey,
                  "with-add": !!_vm.$listeners.add,
                  "with-disabled": !!_vm.$listeners.disabled,
                  "show-disabled": _vm.showDisabled,
                  "is-vendor-disabled": _vm.isVendorDisabled,
                  disabled: _vm.disabled
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("change", $event)
                  },
                  clear: function($event) {
                    return _vm.$emit("clear")
                  },
                  "option-change": function($event) {
                    return _vm.$emit("option-change", $event)
                  },
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  add: function($event) {
                    return _vm.$emit("add")
                  },
                  disabled: function($event) {
                    return _vm.$emit("disabled")
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function(item) {
                        return _vm.$scopedSlots.option
                          ? _vm._t("option", null, null, item)
                          : _vm._e()
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.param,
                  callback: function($$v) {
                    _vm.param = $$v
                  },
                  expression: "param"
                }
              },
              "select-wrapper",
              _vm.select,
              false
            ),
            [
              _vm.locked
                ? _c("i", {
                    staticClass: "material-icons md-18 md-lock lock-icon",
                    attrs: { slot: "prefix" },
                    slot: "prefix"
                  })
                : _vm._t("prefix", null, { slot: "prefix" })
            ],
            2
          )
        : _vm.type === "kit-select"
        ? _c(
            "kit-select",
            {
              ref: "inputRef",
              attrs: {
                allowNegative: _vm.allowNegative,
                size: _vm.size || "small"
              },
              on: {
                focus: _vm.onFocus,
                blur: _vm.onBlur,
                change: function($event) {
                  return _vm.$emit("change", $event)
                }
              },
              model: {
                value: _vm.param,
                callback: function($$v) {
                  _vm.param = $$v
                },
                expression: "param"
              }
            },
            [_vm._t("prefix", null, { slot: "prefix" })],
            2
          )
        : _vm.type === "money"
        ? _c(
            "number-input",
            {
              ref: "inputRef",
              attrs: {
                size: _vm.size || "small",
                disabled: _vm.disabled,
                allowNegative: _vm.allowNegative,
                placeholder: _vm.placeholder,
                precision: _vm.precision
              },
              on: { focus: _vm.onFocus, blur: _vm.onBlur, tab: _vm.onTab },
              model: {
                value: _vm.param,
                callback: function($$v) {
                  _vm.param = $$v
                },
                expression: "param"
              }
            },
            [
              _vm._t("append", null, { slot: "append" }),
              _vm.locked
                ? _c("i", {
                    staticClass: "material-icons md-18 md-lock lock-icon",
                    attrs: { slot: "prefix" },
                    slot: "prefix"
                  })
                : _vm._t("prefix", null, { slot: "prefix" }),
              _vm._t("suffix", null, { slot: "suffix" }),
              _vm._t("prepend", null, { slot: "prepend" }),
              _vm.dollar
                ? _c("i", {
                    staticClass: "material-icons md-18 md-attach_money",
                    attrs: { slot: "prefix" },
                    slot: "prefix"
                  })
                : _vm._e()
            ],
            2
          )
        : _vm.type === "phone"
        ? _c(
            "el-input",
            {
              ref: "inputRef",
              attrs: {
                size: _vm.size || "small",
                placeholder: _vm.placeholder,
                disabled: _vm.disabled
              },
              on: { focus: _vm.onFocus, blur: _vm.onBlur },
              model: {
                value: _vm.phone,
                callback: function($$v) {
                  _vm.phone = $$v
                },
                expression: "phone"
              }
            },
            [_vm._t("prefix", null, { slot: "prefix" })],
            2
          )
        : _vm.type === "autocomplete"
        ? _c(
            "el-autocomplete",
            _vm._b(
              {
                attrs: {
                  size: _vm.size || "small",
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled
                },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  select: function($event) {
                    return _vm.$emit("select", $event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [_vm._t("result", null, null, item)]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.param,
                  callback: function($$v) {
                    _vm.param = $$v
                  },
                  expression: "param"
                }
              },
              "el-autocomplete",
              _vm.options,
              false
            ),
            [_vm._t("append", null, { slot: "append" })],
            2
          )
        : _vm.type === "time"
        ? _c(
            "time-slot-picker",
            {
              ref: "inputRef",
              attrs: {
                size: _vm.size || "small",
                label: _vm.label,
                disabled: _vm.disabled
              },
              on: { focus: _vm.onFocus, blur: _vm.onBlur },
              model: {
                value: _vm.param,
                callback: function($$v) {
                  _vm.param = $$v
                },
                expression: "param"
              }
            },
            [_vm._t("append", null, { slot: "append" })],
            2
          )
        : _vm.type === "textarea"
        ? _c(
            "el-input",
            _vm._b(
              {
                ref: "inputRef",
                attrs: {
                  type: "textarea",
                  size: _vm.size || "small",
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                  maxlength: _vm.maxlength,
                  tabindex: _vm.noTab ? "-1" : "0"
                },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  clear: function($event) {
                    return _vm.$emit("clear")
                  },
                  change: _vm.onChange
                },
                model: {
                  value: _vm.param,
                  callback: function($$v) {
                    _vm.param = $$v
                  },
                  expression: "param"
                }
              },
              "el-input",
              _vm.options,
              false
            )
          )
        : _vm.type === "vin"
        ? _c("gb-vin-input", {
            ref: "inputRef",
            on: { change: _vm.onChange, focus: _vm.onFocus, blur: _vm.onBlur },
            model: {
              value: _vm.param,
              callback: function($$v) {
                _vm.param = $$v
              },
              expression: "param"
            }
          })
        : _vm.type === "email"
        ? _c("email-input", {
            ref: "inputRef",
            attrs: { placeholder: _vm.placeholder },
            on: { change: _vm.onChange, focus: _vm.onFocus, blur: _vm.onBlur },
            model: {
              value: _vm.param,
              callback: function($$v) {
                _vm.param = $$v
              },
              expression: "param"
            }
          })
        : _c(
            "el-input",
            _vm._b(
              {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.mask,
                    expression: "mask"
                  }
                ],
                ref: "inputRef",
                class: _vm.classes,
                attrs: {
                  size: _vm.size || "small",
                  placeholder: _vm.placeholder,
                  maxlength: _vm.maxlength,
                  disabled: _vm.disabled,
                  readOnly: _vm.readOnly
                },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  clear: function($event) {
                    return _vm.$emit("clear")
                  },
                  change: _vm.onChange
                },
                model: {
                  value: _vm.param,
                  callback: function($$v) {
                    _vm.param = $$v
                  },
                  expression: "param"
                }
              },
              "el-input",
              _vm.options,
              false
            ),
            [
              _vm._t("prefix", null, { slot: "prefix" }),
              _vm._t("append", null, { slot: "append" }),
              _vm._t("prepend", null, { slot: "prepend" }),
              _vm._t("suffix", null, { slot: "suffix" })
            ],
            2
          ),
      _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v(" " + _vm._s(_vm.error) + " ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }