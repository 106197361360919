var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-data-row" },
    [
      _vm.adding
        ? _c(
            "div",
            { staticClass: "select-data-row-form" },
            [
              _c("gb-form", {
                ref: "form",
                attrs: { schema: _vm.schema, autoFocus: "" },
                on: { focusout: _vm.onFocusout },
                model: {
                  value: _vm.newValue,
                  callback: function($$v) {
                    _vm.newValue = $$v
                  },
                  expression: "newValue"
                }
              }),
              _c("md-circle-button", {
                staticClass: "select-data-row-button-delete",
                attrs: {
                  icon: "clear md-18",
                  size: "small",
                  filled: "",
                  outline: ""
                },
                on: {
                  click: function($event) {
                    _vm.adding = false
                  }
                }
              })
            ],
            1
          )
        : _c(
            "gb-data-row",
            _vm._b(
              {
                attrs: {
                  loading: _vm.loading || _vm.creating,
                  value: _vm.value
                }
              },
              "gb-data-row",
              _vm.$attrs,
              false
            ),
            [
              !_vm.disabled
                ? _c(
                    "template",
                    { slot: "extra" },
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click" },
                          on: { command: _vm.onCommand }
                        },
                        [
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-scrollbar",
                                {
                                  staticClass: "dropdown-scroll",
                                  attrs: { "wrap-class": "dropdown-wrap" }
                                },
                                [
                                  _vm._l(_vm.select.options, function(item) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: _vm.labelKey
                                          ? item[_vm.labelKey]
                                          : item,
                                        staticClass: "select-data-row-option",
                                        class: {
                                          "option-selected": _vm.labelKey
                                            ? item[_vm.labelKey] ===
                                              _vm.$attrs.value[_vm.labelKey]
                                            : item === _vm.$attrs.value
                                        },
                                        attrs: { command: item }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.labelKey
                                                ? item[_vm.labelKey]
                                                : item
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  }),
                                  _vm.select.extra
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          staticClass:
                                            "select-data-row-action select-data-row-extra-item",
                                          attrs: { command: "extra" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.select.extra.label) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.select.fixedOptions
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          staticClass:
                                            "select-data-row-action select-data-row-add-new-item",
                                          attrs: { command: "new" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "material-icons md-add md-18"
                                          }),
                                          _vm._v(" Add New ")
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c("md-circle-button", {
                            staticClass: "select-data-row-button",
                            attrs: {
                              icon: "arrow_drop_down md-24",
                              size: "small"
                            }
                          })
                        ],
                        1
                      ),
                      (_vm.$attrs.value && _vm.$attrs.value.id) ||
                      (_vm.$attrs.value && _vm.$attrs.overrideClearable)
                        ? [
                            _c("md-circle-button", {
                              staticClass: "select-data-row-button-delete",
                              attrs: {
                                icon: "clear md-18",
                                size: "small",
                                outline: "",
                                danger: ""
                              },
                              on: { click: _vm.select.clear }
                            }),
                            _vm.select.edit
                              ? _c("md-circle-button", {
                                  staticClass: "select-data-row-button-delete",
                                  attrs: {
                                    icon: "edit md-18",
                                    size: "small",
                                    primary: ""
                                  },
                                  on: { click: _vm.select.edit }
                                })
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }