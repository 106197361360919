var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c(
        "div",
        {
          staticClass:
            "t-flex-shrink-0 t-w-72 t-h-full t-border-solid t-border-0\n         t-border-l t-border-gray-300"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "t-px-5 t-h-14 t-flex-shrink-0 t-border-solid t-border-0 t-border-b\n            t-border-gray-300 t-flex t-items-center"
            },
            [
              _c("div", { staticClass: "t-flex-grow" }, [_vm._v("Details")]),
              _c("i", {
                staticClass: "el-icon-close t-cursor-pointer",
                on: { click: _vm.closeInfoDrawer }
              })
            ]
          ),
          _c("div", { staticClass: "t-p-5" }, [
            _c("div", { staticClass: "t-mb-3" }, [
              _c("div", { staticClass: "t-text-gray-500 t-text-xs" }, [
                _vm._v("Channel name")
              ]),
              _c("div", { staticClass: "t-text-gray-700 t-text-base" }, [
                _vm._v(" " + _vm._s(_vm.activeChannel.uniqueName) + " ")
              ])
            ]),
            _c("div", { staticClass: "t-mb-3" }, [
              _c("div", { staticClass: "t-text-gray-500 t-text-xs" }, [
                _vm._v("Channel type")
              ]),
              _c("div", { staticClass: "t-text-gray-700 t-text-base" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.activeChannel.isPrivate ? "Private" : "Public") +
                    " "
                )
              ])
            ]),
            _c("div", { staticClass: "t-mb-3" }, [
              _c("div", { staticClass: "t-text-gray-500 t-text-xs" }, [
                _vm._v("Created by")
              ]),
              _c("div", { staticClass: "t-text-gray-700 t-text-base" }, [
                _vm._v(" " + _vm._s(_vm.activeChannel.createdBy) + " ")
              ])
            ]),
            _c("div", { staticClass: "t-mb-3" }, [
              _c("div", { staticClass: "t-text-gray-500 t-text-xs" }, [
                _vm._v("Created on")
              ]),
              _c("div", { staticClass: "t-text-gray-700 t-text-base" }, [
                _vm._v(_vm._s(_vm.creationDate))
              ])
            ]),
            _c("div", { staticClass: "t-mb-3" }, [
              _c("div", { staticClass: "t-text-gray-500 t-text-xs" }, [
                _vm._v("Total members")
              ]),
              _c("div", { staticClass: "t-text-gray-700 t-text-base" }, [
                _vm._v(" " + _vm._s(_vm.members.length) + " member"),
                _vm.members.length > 1 ? _c("span", [_vm._v("s")]) : _vm._e()
              ])
            ]),
            _c("div", [
              _c("div", { staticClass: "t-text-gray-500 t-text-xs" }, [
                _vm._v("Total messages")
              ]),
              _c("div", { staticClass: "t-text-gray-700 t-text-base" }, [
                _vm._v(" " + _vm._s(_vm.totalMessagesCount) + " message"),
                _vm.totalMessagesCount == 0 || _vm.totalMessagesCount > 1
                  ? _c("span", [_vm._v("s")])
                  : _vm._e()
              ])
            ]),
            _vm.activeChannel.createdBy !== "system"
              ? _c(
                  "div",
                  { staticClass: "t-mt-6" },
                  [
                    _vm.activeChannel.createdBy === _vm.username
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              type: "danger",
                              loading: _vm.deleting,
                              icon: "el-icon-delete"
                            },
                            on: { click: _vm.deleteChannel }
                          },
                          [_vm._v(" Delete channel ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              type: "danger",
                              loading: _vm.leaving,
                              icon: "el-icon-document-delete"
                            },
                            on: { click: _vm.leaveChannel }
                          },
                          [_vm._v(" Leave channel ")]
                        )
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }