<template>
  <div>
    <div>
      <span style="float: left; font-weight: bold;">{{ truncateString(Name) }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">
        <gb-tag color="green" style="margin-top: 10px;">
          ${{ CurrentBalance }}
        </gb-tag>
      </span><br/>
    </div>
    <div>
      <span style="float: left; color: #8492a6;">{{ Classification }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ AccountType }}</span><br/>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'Name',
    'CurrentBalance',
    'Classification',
    'AccountType'
  ],
  methods: {
    truncateString (name) {
      let formattedName
      if (name.length > 20) {
        formattedName = name.substring(0, 20) + '...'
      } else {
        formattedName = name
      }
      return formattedName
    }
  }
}
</script>
