var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSupplemental
    ? _c(
        "div",
        {
          staticClass: "job-status-tag",
          class: { small: _vm.small, disabled: _vm.disabled },
          style: { background: _vm.status.supplementalColor },
          on: { click: _vm.onClick }
        },
        [_vm._v(" Sup " + _vm._s(_vm.status.label) + " ")]
      )
    : _c(
        "div",
        {
          staticClass: "job-status-tag",
          class: { small: _vm.small, disabled: _vm.disabled },
          style: { background: _vm.status.color },
          on: { click: _vm.onClick }
        },
        [_vm._v(" " + _vm._s(_vm.status.label) + " ")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }