<template>
  <el-table
    border
    :data="phoneNumbers">
    <el-table-column
      prop="name"
      label="Name">
    </el-table-column>
    <el-table-column
      prop="sid"
      label="SID"
      align="center"
      width="45">
      <template slot-scope="scope">
        <el-tooltip effect="light">
          <i class="material-icons md-info md-18" />
          <div slot="content">
            <span>{{scope.row.sid}}</span>
          </div>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column
      prop="id"
      label="Default"
      align="center"
      width="75">
      <template slot-scope="scope">
        <el-tag
          v-if="scope.row.id === defaultPhoneNumberId"
          type="success"
          size="mini"
          effect="dark"
        >
          Yes
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      prop="phone_number"
      label="Phone number"
      width="115">
    </el-table-column>
    <el-table-column
      fixed="right"
      label="Actions"
      width="175">
      <template slot-scope="scope">
        <el-button
          class="mb-2 mr-2"
          size="mini"
          icon="el-icon-edit"
          :disabled="disabled"
          @click="handleEdit(scope.row)"/>
        <el-button
          class="ml-0"
          size="mini"
          type="danger"
          icon="el-icon-delete"
          :disabled="disabled"
          @click="handleDelete(scope.row)"/>
          <el-tooltip
            v-if="scope.row.id !== defaultPhoneNumberId"
            content="Set default"
            placement="top"
            :open-delay="300"
          >
            <el-button
              size="mini"
              type="success"
              plain
              icon="el-icon-check"
              :disabled="disabled"
              @click="$emit('update:defaultPhoneNumberId', scope.row.id)"
            />
          </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import TwilioPhoneNumber from '@/scripts/objects/twilio_phone_number'

export default {
  props: ['shopId', 'phoneNumbers', 'defaultPhoneNumberId', 'disabled'],
  methods: {
    handleEdit(phoneNumber) {
      this.$emit('updatePhoneNumber', phoneNumber)
    },
    async handleDelete(phoneNumber) {
      let self = this
      try {
        await self.$messageBox.confirm(
          `This will permanently delete the phone number ${phoneNumber.phone_number}. Do you wish to continue?`,
          'Warning',
          {
            beforeClose: async (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                await TwilioPhoneNumber.destroy(phoneNumber.id)
                let res = await TwilioPhoneNumber.get(this.shopId)
                self.$emit('deletedPhoneNumber', res.data)
                await self.$message.success({
                  message: 'Phone number was successfully removed.',
                  type: 'success'
                })
                instance.confirmButtonLoading = false
              }
              done()
            }
          }
        )
      } catch {}
    }
  }
}
</script>
