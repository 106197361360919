<template>
  <collapse-top-transition>
    <div class="global-search-settings-wrapper" v-show="display">
      <div class="global-search-settings">
        <div class="t-flex t-items-center">
          <el-switch
            active-text="Optimized Search"
            inactive-text="Exact Match"
            :value="settings.optimized"
            @input="setMode"
          />
          <el-tooltip>
            <div class="global-search-settings-info" slot="content">
              {{ info }}
            </div>
            <div><i class="material-icons md-info md-18" /></div>
          </el-tooltip>
        </div>
        <collapse-top-transition>
          <div
            class="global-search-settings-items"
            v-show="!settings.optimized"
          >
            <div v-for="item in items" :key="item.key">
              <el-checkbox
                :value="settings[item.key]"
                @input="setValue(item.key, $event)"
              >
                {{ item.label }}
              </el-checkbox>
            </div>
          </div>
        </collapse-top-transition>
      </div>
    </div>
  </collapse-top-transition>
</template>

<script>
import { sync } from 'vuex-pathify'
import CollapseTopTransition from '@/components/Helpers/transitions/CollapseTopTrans.vue'
import { searchSettings } from '@/constants/search.config'
import * as R from 'ramda'

export default {
  components: {
    CollapseTopTransition
  },
  data() {
    return {
      info:
        "Optimized search is performing full-text search on various data fields at the same time, providing high performance and speed. \n\nHowever, it's accuracy is limited when using words shorter than 3 letters, as well as special characters like commas, parentesis etc.\n \nDisabling it will decrease the efficiency, but might provide better results in some specific usecases and gives you an option to search through specific fields.",
      items: searchSettings
    }
  },
  props: ['display'],
  computed: {
    settings: sync('jobDetails/searchConfig')
  },
  methods: {
    setMode(v) {
      this.settings = {
        optimized: v,
        ...R.reduce((c, { key }) => ({ ...c, [key]: !v }), {})(searchSettings)
      }
    },
    setValue(key, value) {
      this.settings = { ...this.settings, [key]: value }
    }
  }
}
</script>
<style lang="scss" scoped>
.global-search-settings-wrapper {
  .global-search-settings {
    border-top: 1px solid #dcdfe6;
    padding: $padding-md $padding-lg;
    .el-tooltip {
      height: 18px;
      margin-left: $padding-xs;
      i {
        color: $grayscale-6;
      }
    }
    .global-search-settings-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: $padding-sm;
      > div {
        margin-right: $padding-md;
      }
      label {
        text-transform: none;
      }
    }
  }
}
.global-search-settings-info {
  width: 300px;
  white-space: pre-wrap;
}
</style>
