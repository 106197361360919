<template>
  <base-dialog
    :value="value"
    @input="$emit('input')"
    title="Generate PDF"
    append-to-body
    @open="onOpen"
  >
    <div class="scheduler-pdf-dialog" v-loading="loading">
      <div class="t-flex t-justify-between my-4">
        <pdf-type-select :copy.sync="copy" template="select" :userShopPdfDefault="userShopPdfDefault"/>
        <el-checkbox v-model="collated">Collated</el-checkbox>
      </div>
      <div class="t-flex t-justify-center my-4">
        <md-icon-button
          v-for="action in actions"
          :key="action.key"
          size="normal"
          :icon="action.icon"
          @click="getPdf(action.key)"
          :loading="loading === action.key"
          :disabled="!!loading"
        >
          {{ action.label }}
        </md-icon-button>
      </div>
      <div>
        <object v-if="data" :data="data" class="pdf-preview-object" />
      </div>
    </div>
    <div slot="footer" v-show="false"></div>
    <a :href="this.data" :download="filename" ref="dl" v-show="false" />
  </base-dialog>
</template>

<script>
import BaseDialog from '../../JobPageV2/helpers/dialogs/BaseDialog'
import PdfTypeSelect from '../../JobPageV2/helpers/PDFTypeSelect'
import print from 'print-js'

export default {
  props: ['value', 'job_ids', 'tech', 'date', 'shop'],
  components: { BaseDialog, PdfTypeSelect },
  data() {
    return {
      copy: 'withPrices',
      loading: null,
      data: null,
      collated: false,
      userShopPdfDefault: null
    }
  },
  computed: {
    actions() {
      return [
        { label: 'Download', icon: 'save_alt', key: 'download' },
        { label: 'Print', icon: 'print', key: 'print' }
      ]
    },
    filename() {
      return `${this.tech}-${this.date.toDateString()}`
    }
  },
  methods: {
    async getPdf(action) {
      if (!this.data) {
        try {
          this.loading = action
          const { data } = await this.$unum.job().post(
            '/docs/batch',
            {
              prices: this.copy === 'withPrices',
              insurance: this.copy === 'insuranceCopy',
              customer: this.copy === 'customerCopy',
              ...(this.collated ? { collated: true } : {}),
              ids: this.job_ids || []
            },
            { responseType: 'blob' }
          )
          this.data = window.URL.createObjectURL(data)
        } catch (error) {
          this.$message({ type: 'error', message: 'Something went wrong' })
        } finally {
          this.loading = null
          this.signature = false
        }
      }

      if (action === 'print') {
        print({ printable: this.data })
      }
      this.$nextTick(() => {
        if (action === 'download') {
          this.$refs.dl.click()
        }
      })
    },
    onOpen() {
      for (var i = 0; i < this.$store.state.shops.length; i++) {
        if (this.$store.state.shops[i].id === this.$store.state.user.shop_id) {
          this.userShopPdfDefault = this.$store.state.shops[i].pdf_default
        }
      }
      this.data = null
      this.copy = this.userShopPdfDefault || 'withPrices'
      this.getPdf('view')
    }
  },
  watch: {
    copy(v) {
      this.data = null
      this.getPdf('view')
    },
    collated(v) {
      this.data = null
      this.getPdf('view')
    }
  }
}
</script>
<style lang="scss" scoped>
.scheduler-pdf-dialog {
  label {
    text-transform: none;
  }
  .pdf-preview-object {
    width: 100%;
    height: 800px;
  }
}
</style>
