<template>
    <el-row>
      <el-col>
        <el-date-picker
          :disabled='inDisabled'
          v-model='formattedDate'
          type='date'
          :placeholder='placeholder'
          :format='displayFormat'
          :value-format='saveFormat'
          :picker-options='!isMobile ? pickerOptions : null'
          @focus='onFocus'
          @blur='onBlur'
          @change="changeEvent"
          :class="classes"
          size='mini'>
        </el-date-picker>
      </el-col>
    </el-row>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
import {
  formatCustomDateFormat,
} from '@/scripts/helpers/date.helpers'

export default {
  props: ['value', 'placeholder', 'hint', 'required', 'inDisabled',
    'title', 'displayFormat', 'saveFormat', 'successMessage',
    'failMessage', 'inClass', 'autoSetToday', 'onChange'],
  data () {
    return {
      showFailureMessage: false,
      inputActive: false,
      setToToday: false,
      validityClassToAdd: '',
      setTodayOnFocus: typeof this.autoSetToday !== 'undefined' ? this.autoSetToday : true,
      pickerOptions: {
        shortcuts: [{
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: 'Tomorrow',
          onClick (picker) {
            var d = new Date()
            d.setTime(d.getTime() + (24 * 60 * 60 * 1000))
            picker.$emit('pick', d)
          }
        }, {
          text: 'The Day After',
          onClick (picker) {
            var d = new Date()
            d.setTime(d.getTime() + (2 * 24 * 60 * 60 * 1000))
            picker.$emit('pick', d)
          }
        }, {
          text: '-----------------'
        }, {
          text: 'Next Monday',
          onClick (picker) {
            var d = new Date()
            d.setDate(d.getDate() + ((7 - d.getDay()) % 7 + 1))
            picker.$emit('pick', d)
          }
        }, {
          text: 'Next Tuesday',
          onClick (picker) {
            var d = new Date()
            d.setDate(d.getDate() + ((7 - d.getDay()) % 7 + 2))
            picker.$emit('pick', d)
          }
        }, {
          text: 'Next Wed',
          onClick (picker) {
            var d = new Date()
            d.setDate(d.getDate() + ((7 - d.getDay()) % 7 + 3))
            picker.$emit('pick', d)
          }
        }, {
          text: 'Next Thursday',
          onClick (picker) {
            var d = new Date()
            d.setDate(d.getDate() + ((7 - d.getDay()) % 7 + 4))
            picker.$emit('pick', d)
          }
        }, {
          text: 'Next Friday',
          onClick (picker) {
            var d = new Date()
            d.setDate(d.getDate() + ((7 - d.getDay()) % 7 + 5))
            picker.$emit('pick', d)
          }
        }, {
          text: 'Next Saturday',
          onClick (picker) {
            var d = new Date()
            d.setDate(d.getDate() + ((7 - d.getDay()) % 7 + 6))
            picker.$emit('pick', d)
          }
        }]
      }
    }
  },
  methods: {
    onFocus: function () {
      if ((this.value === '' || this.value === '0000-00-00') && (this.setTodayOnFocus)) {
        this.formattedDate = formatCustomDateFormat(new Date(), this.saveFormat.toUpperCase())
        this.setToToday = true
      }
    },
    onBlur: function () {
      if (this.setToToday) {
        this.setToToday = false
        if (this.onChange) {
          this.onChange()
        }
      }
    },
    changeEvent: function () {
      this.setToToday = false
      if (this.onChange) {
        this.onChange()
      }
    }
  },
  computed: {
    classes () {
      var classes = []
      if (this.validityClassToAdd.length > 0) {
        classes.push(this.validityClassToAdd)
      }
      if (this.inClass && this.inClass.length > 0) {
        var inClasses = this.inClass.split(',')
        for (var i = 0; i < inClasses.length; i++) {
          if (inClasses[i] && inClasses[i].trim().length > 0) {
            classes.push(inClasses[i].trim())
          }
        }
      }
      return classes.join(' ')
    },
    formattedDate: {
      // get () {
      //   if (this.value === '0000-00-00') {
      //     return ''
      //   }
      //   return this.value
      // },
      // set (val) {
      //   if (val === 'Invalid date') {
      //     this.$emit('update:value', val)
      //   } else {
      //     var tempDate = moment(val).format(this.saveFormat)
      //     
      //     this.$emit('update:value', tempDate)
      //   }
      // }
      get () {
        if (this.value === '0000-00-00') {
          return ''
        }
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    }
  },
  watch: {
    // value: function (val) {
    //   if (val === 'Invalid date') {
    //     this.$emit('update:value', val)
    //   } else {
    //     
    //     var tempDate = moment(val).format(this.saveFormat)
    //     
    //     this.$emit('update:value', tempDate)
    //   }
    // }
  },
  mixins: [UtilityMixin]
}
</script>

<style lang='scss'>
.userTableDatePicker {
  width: 80% !important;
  // input.el-input__inner {
  //   width: 100% !important;
  // }
}
@include media-breakpoint-down(xl) {
  .userTableDatePicker {
    width: 100% !important;
    // input.el-input__inner {
    //   width: 100% !important;
    // }
  }
}
</style>
