var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "120px",
        width: "900px",
        "custom-class": "edit-warehouse-entity-dialog",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-gray-700 t-text-lg",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-document" }),
          _c("span", { staticClass: "t-ml-3" }, [_vm._v("Create Receive")])
        ]
      ),
      _c(
        "div",
        { staticClass: "t-pt-6" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "t-mx-6",
              attrs: {
                model: _vm.form,
                size: "small",
                "hide-required-asterisk": ""
              }
            },
            [
              _vm._l(_vm.form.parts, function(part, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "t-flex t-border t-border-solid t-border-gray-200 t-rounded-md t-px-4\n            t-py-2 t-shadow t-bg-gray-50 t-mb-3"
                    },
                    [
                      _c("div", { staticClass: "t-w-1/4" }, [
                        _c(
                          "div",
                          { staticClass: "t-text-gray-500 t-text-xs t-h-5" },
                          [_vm._v("Part #")]
                        ),
                        _c("div", { staticClass: "t-mt-1" }, [
                          _vm._v(_vm._s(part.itemName))
                        ])
                      ]),
                      _c("div", { staticClass: "t-w-1/4" }, [
                        _c(
                          "div",
                          { staticClass: "t-text-gray-500 t-text-xs t-h-5" },
                          [_vm._v("Total Qty")]
                        ),
                        _c("div", { staticClass: "t-mt-1" }, [
                          _vm._v(_vm._s(part.totalQuantity))
                        ])
                      ]),
                      _c("div", { staticClass: "t-w-1/4" }, [
                        _c(
                          "div",
                          { staticClass: "t-text-gray-500 t-text-xs t-h-5" },
                          [_vm._v("Unreceived Qty")]
                        ),
                        _c("div", { staticClass: "t-mt-1" }, [
                          _vm._v(
                            _vm._s(_vm.countUnreceived(_vm.form.parts[index]))
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "t-w-1/4" },
                        [
                          _c(
                            "div",
                            { staticClass: "t-text-gray-500 t-text-xs t-h-5" },
                            [_vm._v("Receive Qty")]
                          ),
                          _vm.countUnreceived(_vm.form.parts[index]) <= 0
                            ? _c("div", { staticClass: "t-italic" }, [
                                _vm._v(" No units to receive ")
                              ])
                            : _c(
                                "el-form-item",
                                {
                                  staticClass: "t-mb-0",
                                  attrs: {
                                    "label-width": "0",
                                    prop: "parts." + index + ".quantity",
                                    "show-message": false,
                                    rules: [{ required: true }]
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      type: "number",
                                      size: "small",
                                      min: 0,
                                      max: _vm.countUnreceived(
                                        _vm.form.parts[index]
                                      )
                                    },
                                    model: {
                                      value: _vm.form.parts[index].quantity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form.parts[index],
                                          "quantity",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "form.parts[index].quantity"
                                    }
                                  })
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ]
                  )
                ])
              }),
              _c(
                "el-form-item",
                { attrs: { prop: "note" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("Notes")]
                  ),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "Enter notes",
                      rows: 3
                    },
                    model: {
                      value: _vm.form.note,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "note", $$v)
                      },
                      expression: "form.note"
                    }
                  })
                ],
                1
              )
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "t-py-3 t-px-6 t-flex t-justify-between t-border-0 t-border-t t-border-solid"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "small" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus",
                    loading: _vm.submitting,
                    disabled: !_vm.hasUnitsToReceive
                  },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v(" Create ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }