import {
  reportsActions as actions,
  reportsMutations as mutations
} from './reports.types'
import Api from '@/services/Api'
import {
  reportsSalesDataUrl,
  reportsRightPartDataUrl,
  reportsShopDataUrl,
  reportPrintToPdfUrl,
  // reportsInOutUrl,
  reportsAgingUrl,
  reportsProfitAndLossUrl,
  reportsBalanceSheetUrl,
  reportsNagsLicenseUrl,
  reportsCustomerSummaryUrl,
  reportsEdiUrl,
  reportsInstallListUrl
} from '@/constants/endpoints'
import * as R from 'ramda'
import { shopDataScopes } from '@/constants/reports.configs'
import { getTodaysDate } from '@/scripts/helpers/date.helpers'
import print from 'print-js'

const reportsActions = {
  async [actions.getReportData] ({ commit, state, getters }) {
    commit(mutations.setStateValue, { key: 'loading', value: true })
    try {
      let consumerEndpoint = false
      for (var i = 0; i < state.shopData?.length; i++) {
        if (state.shopData[i].consumer) {
          consumerEndpoint = true
          break
        }
      }
      const { group, id } = state.type
      // group: sales, id: sales
      // if (id === 'in-out') {
      //   const { data } = await Api({
      //     url: reportsInOutUrl,
      //     params: state.filters
      //   })
      //   commit(mutations.setStateValue, { key: 'data', value: data })
      // } else
      if (id === 'aging') {
        const {data} = await Api({
          url: reportsAgingUrl,
          params: { ...state.filters, isConsumer: getters.isConsumer }
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (group === 'right-part') {
        let params = {}
        if (state.filters?.shops?.length) {
          params['shopIds'] = state.filters.shops
        }
        if (state.filters?.date_start) {
          params['dateFrom'] = state.filters.date_start
        }
        if (state.filters?.date_end) {
          params['dateTo'] = state.filters.date_end
        }
        const { data } = await Api({
          method: 'get',
          url: reportsRightPartDataUrl(),
          params
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (id === 'profit-and-loss') {
        const {data} = await Api({
          url: reportsProfitAndLossUrl,
          params: state.filters
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (id === 'balance-sheet') {
        const {data} = await Api({
          url: reportsBalanceSheetUrl,
          params: state.filters
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (id === 'nags-license') {
        const {data} = await Api({
          url: reportsNagsLicenseUrl,
          params: state.filters
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (id === 'customer-summary') {
        const {data} = await Api({
          url: reportsCustomerSummaryUrl,
          params: state.filters
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (id === 'edi') {
        const {data} = await Api({
          url: reportsEdiUrl,
          params: state.filters
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (group === 'install') {
        const { data } = await Api.post(reportsSalesDataUrl(group, id, consumerEndpoint),
          { ...state.filters, isConsumer: getters.isConsumer })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (id === 'install-list') {
        const {data} = await Api({
          url: reportsInstallListUrl,
          params: state.filters
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else {
        const { data } = await Api({
          url: reportsSalesDataUrl(group, id, consumerEndpoint),
          params: { ...state.filters, isConsumer: getters.isConsumer }
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      }
    } catch (error) {
    }
    commit(mutations.setStateValue, { key: 'loading', value: false })
  },
  async [actions.getReportDataAdmin] ({ commit, state }) {
    commit(mutations.setStateValue, { key: 'loading', value: true })
    try {
      const { group, id } = state.type
      console.log(group, id)
      // group: sales, id: sales
      // if (id === 'in-out') {
      //   const { data } = await Api({
      //     url: reportsInOutUrl,
      //     params: state.filters
      //   })
      //   commit(mutations.setStateValue, { key: 'data', value: data })
      // } else
      if (id === 'aging') {
        const {data} = await Api({
          url: reportsAgingUrl,
          params: state.filters
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (group === 'right-part') {
        let params = {}
        // if (state.filters?.shops?.length) {
        //   params['shopIds'] = state.filters.shops
        // }
        if (state.filters?.date_start) {
          params['dateFrom'] = state.filters.date_start
        }
        if (state.filters?.date_end) {
          params['dateTo'] = state.filters.date_end
        }
        const { data } = await Api({
          method: 'get',
          url: reportsRightPartDataUrl(),
          params,
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (id === 'profit-and-loss') {
        const {data} = await Api({
          url: reportsProfitAndLossUrl,
          params: state.filters
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else if (id === 'balance-sheet') {
        const {data} = await Api({
          url: reportsBalanceSheetUrl,
          params: state.filters
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      } else {
        const { data } = await Api({
          url: reportsSalesDataUrl(group, id, true),
          params: state.filters
        })
        commit(mutations.setStateValue, { key: 'data', value: data })
      }
    } catch (error) {

    }
    commit(mutations.setStateValue, { key: 'loading', value: false })
  },
  async [actions.getReportShopData] ({ state, dispatch }, type) {
    const typeId = type || R.path(['type', 'id'])(state)
    if (shopDataScopes[typeId]) {
      dispatch(actions.fetchReportShopData, shopDataScopes[typeId])
    }
  },
  async [actions.fetchReportShopData] ({ commit, state, getters }, type) {
    commit(mutations.setStateValue, { key: 'loading', value: true })
    try {
      const { shops } = state.filters
      if (shops.length || !isNaN(shops)) {
        const { data } = await Api({
          url: reportsShopDataUrl(type),
          params: { shops, isConsumer: getters.isConsumer }
        })
        commit(mutations.setStateValue, { key: 'shopData', value: data })
      }
    } catch (error) {
      console.log('fetch error:', error)
    }
    commit(mutations.setStateValue, { key: 'loading', value: false })
  },
  async [actions.sendPrintToPdfRequest] ({ state, commit }, orientation) {
    if (document.querySelector('.table-container') == null) {
      alert('Please generate a report first.')
      return
    }
    try {
      commit(mutations.setStateValue, { key: 'loading', value: true })
      const html = document.querySelector('.table-container').outerHTML
      const { data } = await Api({
        method: 'post',
        url: reportPrintToPdfUrl,
        responseType: 'arraybuffer',
        data: { html, orientation }
      })

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      const filename = `${getTodaysDate()} - ${state.type.label} (${
        state.filters.shops
      }).pdf`

      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      commit(mutations.setStateValue, { key: 'loading', value: false })
    } catch (error) {
    }
  },
  async [actions.sendPrintRequest] ({ state, commit }, orientation) {
    if (document.querySelector('.table-container') == null) {
      alert('Please generate a report first.')
      return
    }
    try {
      commit(mutations.setStateValue, { key: 'loading', value: true })
      const html = document.querySelector('.table-container').outerHTML
      const { data } = await Api({
        method: 'post',
        url: reportPrintToPdfUrl,
        responseType: 'arraybuffer',
        data: { html, orientation }
      })

      const url = window.URL.createObjectURL(new Blob([data]))

      print({ printable: url })
      commit(mutations.setStateValue, { key: 'loading', value: false })
    } catch (error) {
    }
  }
}

export default reportsActions
