<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      title='Support Center'
      width="900px"
      destroy-on-close
      custom-class="gb-el-dialog support-dialog"
      top="24px"
      ref="support-dialog"
      append-to-body
      :close-on-click-modal="false"
    >
      <tabs :filePickerKey='filePickerKey' :tab.sync='tab' /> 
    </el-dialog>
    <ticket-detail/>
  </div>
</template>

<script>
import { call, sync } from 'vuex-pathify'
import Tabs from './Tabs.vue'
import TicketDetail from './TicketDetail/Index.vue'

export default {
  props: ['filePickerKey'],
  data() {
    return {
      tab: null
    }
  },
  components: {
    Tabs,
    TicketDetail
  },
  computed: {
    visible: sync('support/visible')
  },
  watch: {
    visible(v) {
      if (v) {
        setTimeout(()=>{
          this.tab = 'dashboard'
        }, 100)
      } else {
        this.tab = null
      }
    }
  }
}
</script>

<style lang='scss' scoped>
  button {
  &.create-ticket-button {
    margin-right: 60px;
    font-size: 10px;
  }
}
.support-dialog {
  max-width: 100vw;
  min-height: 80vh;
}
</style>