<template>
  <div class="places-autocomplete-block">
    <div>
      <div class="main">{{ main_text }}</div>
      <div class="secondary">{{ secondary_text }}</div>
    </div>
    <div class="extra" v-if="result.extra">
      {{ result.extra }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['result'],
  computed: {
    main_text() {
      return this.result.main_text || this.result.address
    },
    secondary_text() {
      return (
        this.result.secondary_text ||
        `${this.result.city}, ${this.result.state} ${this.result.zip}`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.places-autocomplete-block {
  line-height: 14px;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .main {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .secondary {
    font-size: 12px;
  }
  .extra {
    font-size: 12px;
    color: $grayscale-7;
  }
}
</style>
