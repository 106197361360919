import gql from 'graphql-tag'

export const SHOP_CONFIRMATION_SETTINGS_FRAGMENT = gql`
  fragment ShopConfirmationSettingsFields on ShopConfirmationSettings {
    id
    time
    same_day
    send_sunday
    commercial
  }
`
