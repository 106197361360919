import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import Shop from '@/scripts/objects/shop'
import User from './user'

export default class Account extends ObjectTracker {
  id = -1
  created = -1
  status = ''
  name = ''
  modified = ''
  contact = ''
  phone = ''
  fax = ''
  email = ''
  address = ''
  address_city = ''
  address_state = ''
  address_zip = ''
  notes = ''
  zoho_customer_id = ''
  zoho_card_id = ''
  zoho_subscription_id = ''
  zoho_bank_account_id = ''
  zoho_status_override = 0
  chat_service_sid = null

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAllForUser (isNotAccount, callbackFunction) {
    if (isNotAccount) {
      Api({url: `/api/useraccounts`}).then(res => {
        var objects = []
        res.data.forEach(function (val) {
          objects.push(new Account(val))
        })

        callbackFunction(objects)
      })
    } else {
      Api({url: `/api/accounts`}).then(res => {
        var objects = []
        res.data.forEach(function (val) {
          objects.push(new Account(val))
        })

        callbackFunction(objects)
      })
    }
  }

  static getAllUsersForAccount (accountId, callbackFunction, errorCallbackFunction = null) {
    const url = accountId ? `/api/account/${accountId}/users` : `/api/account/allUsers`
    Api({url}).then(res => {
      // let's get this information a bit better formatted
      var tempData = []
      var masterList = []
      for (var i = 0; i < res.data.length; i++) {
        if (Array.isArray(res.data[i])) {
          // this will be the master list
          masterList = res.data[i]
        } else {
          let tempUserObj = new User(res.data[i])
          // tempUserObj.shops = res.data[i].shops
          // tempUserObj.shopUser = res.data[i].shopUser
          tempData.push(tempUserObj)
        }
      }

      var response = []
      for (var j = 0; j < masterList.length; j++) {
        response.push(new Shop(masterList[j]))
      }
      callbackFunction(tempData, response)
    })
  }

  static async getAllUsersForAccountWithoutShops (accountId) {
    try {
      return await Api.get(`/api/account/${accountId}/users/noShops`)
    } catch (error) {
      return {data: []}
    }
  }

  createUpdateAccount (callbackFunction, errorCallbackFunction = null) {
    Api.post(`/api/account/${this.id}`, this).then(res => {
      callbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getBillingDetails (accountId, getHostedPage, getAddOnPlans, callbackFunction) {
    Api({url: `/api/account/${accountId}/billing_details?getHostedPage=${getHostedPage}&getAddOnPlans=${getAddOnPlans}`}).then(res => {
      callbackFunction(res.data)
    })
  }

  static checkBillingDetailsUpdateResult (accountId, hostedPageId, previousStatus, callbackFunction, errorCallbackFunction = null) {
    var data = {}
    data['hostedPageId'] = hostedPageId
    data['previousStatus'] = previousStatus

    Api.post(`/api/account/${accountId}/checkBillingDetailsUpdateResult`, data).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getUserAccountStatus (callbackFunction, errorCallbackFunction) {
    Api({url: `/api/account/account_status`}).then(res => {
      callbackFunction(res.data)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
