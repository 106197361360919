var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex t-justify-between t-items-end t-my-3 t-flex-wrap" },
    [
      _c("div", { staticClass: "t-text-xs t-text-gray-600" }, [
        _vm._v(
          " Showing " +
            _vm._s(_vm.pagination.total ? _vm.pagination.from : 0) +
            " to " +
            _vm._s(_vm.to) +
            " of " +
            _vm._s(_vm.pagination.total) +
            " "
        )
      ]),
      _c("el-pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hideLinks,
            expression: "!hideLinks"
          }
        ],
        staticClass: "t-text-gray-700",
        attrs: {
          background: "",
          layout: "prev, pager, next",
          "page-size": _vm.pagination.perPage,
          "pager-count": 7,
          "current-page": _vm.value,
          total: _vm.pagination.total
        },
        on: {
          "current-change": function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }