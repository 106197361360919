<template>
  <div>
    <b-modal
      @show="dialogAboutToAppear"
      @shown="$refs.nameComponent.focus()"
      no-close-on-backdrop
      no-close-on-esc
      v-bind:title="salesrep.id > 0 ? 'Edit Sales Rep' : 'New Sales Rep'"
      id="salesrepModal"
      header-bg-variant="primary"
      header-text-variant="light"
      size="lg"
    >
      <div class="row">
        <div class="form-group col-6">
          <label>Status</label>
          <select
            v-model="salesrep.status"
            class="form-control form-control-sm"
          >
            <option value="active">Active</option>
            <option value="disabled">Disabled</option>
          </select>
        </div>
        <div v-if="salesrep.id > 0" class="form-group col-6">
          <label></label>
          <p>
            ID: <span class="idBox">{{ salesrep.id }}</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <RequiredInput
            ref="nameComponent"
            :title="'Name'"
            :value.sync="salesrep.name"
            :placeholder="''"
            :capitalizeAll="true"
          />
        </div>
        <div v-if="checkCommissionPermission" class="form-group col-3">
          <label>Commission</label>
          <FloatDropdown
            ref="commissionComponent"
            :dropdownOptions="[
              { value: 'flat', label: '$', dropdownLabel: 'Flat' },
              { value: 'percent', label: '%', dropdownLabel: '%' },
            ]"
            :inDisabled="false"
            :dropdownValue.sync="salesrep.commission_flat_or_percent"
            :value.sync="salesrep.commission"
          />
        </div>
        <div v-if="checkCommissionPermission" class="form-group col-3">
          <label>Chip Commission</label>
          <FloatDropdown
            ref="chipCommissionComponent"
            :dropdownOptions="[
              { value: 'flat', label: '$', dropdownLabel: 'Flat' },
              { value: 'percent', label: '%', dropdownLabel: '%' },
            ]"
            :inDisabled="false"
            :dropdownValue.sync="salesrep.chip_commission_flat_or_percent"
            :value.sync="salesrep.chip_commission"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-3">
          <Phone
            ref="phoneComponent"
            :title="'Phone'"
            :value.sync="salesrep.phone"
            :placeholder="''"
            :required="false"
          />
        </div>
        <div class="form-group col-md-3">
          <Phone
            ref="faxComponent"
            :title="'Fax'"
            :value.sync="salesrep.fax"
            :placeholder="''"
            :required="false"
          />
        </div>
        <div class="form-group col-md-6">
          <Email
            ref="emailComponent"
            :title="'Email'"
            :value.sync="salesrep.email"
            :placeholder="''"
            :required="false"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label>Address</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="salesrep.address"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-3">
          <label>ZIP</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="salesrep.zip"
          />
        </div>
        <div class="form-group col-md-6">
          <label>City</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="salesrep.city"
          />
        </div>
        <div class="form-group col-md-3">
          <label>State</label>
          <select
            id="#"
            class="form-control form-control-sm"
            v-model="salesrep.state"
          >
            <option value="">Choose...</option>
            <option
              v-for="state in states"
              :key="state.value"
              v-bind:value="state.value"
            >
              {{ state.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label>Saleside Id (Case Sensitive)</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="salesrep.saleside_username"
          />
        </div>
        <div
          class="form-group col-md-3"
          v-if="salesrep.saleside_invitation_status"
        >
          <label>Invitation Status</label>
          <input
            type="text"
            v-bind:class="{
              pendingInvitationStatus:
                salesrep.saleside_invitation_status === 'pending',
              acceptedInvitionStatus:
                salesrep.saleside_invitation_status === 'accepted',
            }"
            class="form-control form-control-sm"
            v-model="salesrep.saleside_invitation_status"
            readonly
          />
        </div>
        <div class="form-group col-md-3">
          <label>Default Location</label>
          <select
            id="#"
            class="form-control form-control-sm"
            v-model="salesrep.location_id"
          >
            <option value="-1">Choose...</option>
            <option
              v-for="location in locations"
              :key="location.id"
              v-bind:value="location.id"
            >
              {{ location.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <br />
          <input type="checkbox" v-model="salesrep.hide_sent_jobs" /> Hide sent
          SSM Jobs
        </div>
        <div class="form-group col-md-8">
          <MultiSelectDropdown
            :objects="commercialaccounts"
            :selectedValues.sync="selectedSalesrepCommercialAccounts"
            :title="'Commercial Accounts'"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-8">
          <MultiSelectDropdown
            :objects="locations"
            :selectedValues.sync="selectedSalesrepLocations"
            :title="'Locations'"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-8">
          <MultiSelectDropdown
            :objects="salesources"
            :selectedValues.sync="selectedSalesrepSalesources"
            :title="'Sale Sources'"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label>Notes</label>
          <textarea
            class="form-control form-control-sm"
            v-model="salesrep.notes"
          ></textarea>
        </div>
      </div>
      <div slot="modal-footer" class='w-100'>
        <div class='float-left'>
          <div class="row">
            <div :class="{'col-4':(mostRecentInviteHistory != null), 'col-12':(mostRecentInviteHistory == null)}">
              <button v-if="(!salesrep.saleside_invitation_status || salesrep.saleside_invitation_status === 'pending') || mostRecentInviteHistory != null" type='button' size='lg' class='btn btn-primary' variant="primary" @click="inviteSalesrep">{{(!salesrep.saleside_invitation_status || salesrep.saleside_invitation_status === 'pending')  ? 'Invite Sales Rep' : 'Invite History'}}</button>
            </div>
            <div v-if="mostRecentInviteHistory != null"  class="col-8">
              <el-timeline>
                <el-timeline-item
                  :icon="mostRecentInviteHistory.icon"
                  :type="mostRecentInviteHistory.type"
                  :size="mostRecentInviteHistory.size"
                  :timestamp="mostRecentInviteHistory.timestamp">
                  {{ mostRecentInviteHistory.name }}
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
        </div>
        <div class='float-right'>
          <button style="margin-right:5px;" type='button' size='lg' class='btn btn-primary' variant="primary" @click="saveSalesrep">Save</button>
          <button type='button' class='btn btn-secondary' variant="primary" @click="cancelEdits">Cancel</button>
        </div>
      </div>
    </b-modal>

    <el-dialog :visible.sync="confirmSaveChanges">
      <el-row class="dialog-header-text"> Save Changes? </el-row>
      <br />
      <el-row class="dialog-body-text"
        >In order to invite this sales rep, any unsaved changes must be saved.
        Would you like to continue?</el-row
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="approveSaveChangesAndInvite()">Yes</el-button>
        <el-button @click="confirmSaveChanges = false">No</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Salesrep from '@/scripts/objects/salesrep'
import Phone from '@/components/Helpers/Phone.vue'
import RequiredInput from '@/components/Helpers/RequiredInput.vue'
import Email from '@/components/Helpers/Email.vue'
import { ValidateComponents, ResetValidateComponents, StateObjects } from '@/scripts/helpers/helpers.js'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import FloatDropdown from '@/components/Helpers/FloatDropdown.vue'
import MultiSelectDropdown from '@/components/Helpers/MultiSelectDropdown.vue'
import SalesrepCommercialaccountToSkip from '@/scripts/objects/salesrepCommercialaccountToSkip'
import SalesrepLocationsToSkip from '@/scripts/objects/salesrepLocationsToSkip'
import SalesrepSalesourcesToSkip from '@/scripts/objects/salesrepSalesourcesToSkip'
import { get, call } from 'vuex-pathify'

export default {
  props: {'parentSalesrep': {}, 'shopId': {}, 'locations': { default: () => [] }, 'commercialaccounts': { default: () => [] }, 'salesources': { default: () => [] }},
  data () {
    return {
      states: StateObjects,
      salesrep: new Salesrep(),
      validateInputs: [],
      selectedSalesrepCommercialAccounts: [],
      salesrepCommercialAccountsToSkip: [],
      selectedSalesrepLocations: [],
      salesrepLocationsToSkip: [],
      selectedSalesrepSalesources: [],
      salesrepSalesourcesToSkip: [],
      confirmSaveChanges: false,
      saveAndOpenInvite: false
    }
  },
  mounted: function () {
    this.validateInputs = [this.$refs.nameComponent, this.$refs.commissionComponent,
      this.$refs.chipCommissionComponent, this.$refs.phoneComponent, this.$refs.faxComponent,
      this.$refs.emailComponent]
  },
  computed: {
    mostRecentInviteHistory: get('twilio/textMessages/tssInvite/formattedMostRecentHistory')
  },
  methods: {
    getTssInviteMostRecentHistory: call('twilio/textMessages/tssInvite/getMostRecentHistory'),
    clearTssInviteMostRecentHistory: call('twilio/textMessages/tssInvite/clearMostRecentHistory'),
    approveSaveChangesAndInvite () {
      this.confirmSaveChanges = false
      this.saveAndOpenInvite = true
      this.saveSalesrep()
    },
    openTssInviteDialog: call('twilio/textMessages/tssInvite/open'),
    setTssInviteShopId: call('twilio/textMessages/tssInvite/setShopId'),
    setTssInviteInviteType: call('twilio/textMessages/tssInvite/setInviteType'),
    setTssInviteInviteId: call('twilio/textMessages/tssInvite/setInviteId'),
    setInvitePhoneNumber: call('twilio/textMessages/tssInvite/setInvitePhoneNumber'),
    setTssInviteHistoryOnly: call('twilio/textMessages/tssInvite/setHistoryOnly'),
    setTssInviteVendor: call('twilio/textMessages/tssInvite/setVendor'),
    inviteSalesrep: function () {
      var historyOnly = !(!this.salesrep.saleside_invitation_status || this.salesrep.saleside_invitation_status === 'pending')
      if (!historyOnly && (!this.salesrep.id || this.salesrep.id <= 0 || !this.salesrep.cleanCheck)) {
        this.confirmSaveChanges = true
      } else {
        this.$root.$emit('bv::hide::modal', 'salesrepModal')
        this.setTssInviteShopId(this.shopId)
        this.setTssInviteInviteType('salesrep')
        this.setTssInviteVendor(false)
        this.setTssInviteInviteId(this.salesrep.id)
        this.setInvitePhoneNumber(this.salesrep.phone)
        this.setTssInviteHistoryOnly(historyOnly)

        let self = this
        setTimeout(() => {
          self.openTssInviteDialog()
        }, 300)
      }
    },
    dialogAboutToAppear () {
      console.log(this.parentSalesrep)
      this.salesrep = new Salesrep(this.parentSalesrep)

      this.clearTssInviteMostRecentHistory()

      if (this.salesrep.id > 0) {
        let self = this
        this.salesrepCommercialAccountsToSkip = []
        this.selectedSalesrepCommercialAccounts = []

        SalesrepCommercialaccountToSkip.getAllForSalesrep(this.salesrep.shop_id, this.salesrep.id, function (objects) {
          self.salesrepCommercialAccountsToSkip = objects

          // get selected commercial accounts
          for (var i = 0; i < self.commercialaccounts.length; i++) {
            var skipCommercialAccount = false
            for (var j = 0; j < self.salesrepCommercialAccountsToSkip.length; j++) {
              if (self.commercialaccounts[i].id === self.salesrepCommercialAccountsToSkip[j].commercialaccount_id) {
                skipCommercialAccount = true
                break
              }
            }
            if (!skipCommercialAccount) {
              self.selectedSalesrepCommercialAccounts.push(self.commercialaccounts[i])
            }
          }
        })

        self.selectedSalesrepLocations = []
        self.salesrepLocationsToSkip = []

        SalesrepLocationsToSkip.getAllForSalesrep(self.salesrep.shop_id, self.salesrep.id, function (objects) {
          self.salesrepLocationsToSkip = objects
          var tempLocationsArray = []
          // get selected locations
          for (var i = 0; i < self.locations.length; i++) {
            var skipLocation = false
            for (var j = 0; j < self.salesrepLocationsToSkip.length; j++) {
              if (self.locations[i].id === self.salesrepLocationsToSkip[j].location_id) {
                skipLocation = true
                break
              }
            }
            if (!skipLocation) {
              tempLocationsArray.push(self.locations[i])
            }
          }
          self.selectedSalesrepLocations = tempLocationsArray
        })

        self.selectedSalesrepSalesources = []
        self.salesrepSalesourcesToSkip = []
        SalesrepSalesourcesToSkip.getAllForSalesrep(self.salesrep.shop_id, self.salesrep.id, function (objects) {
          self.salesrepSalesourcesToSkip = objects
          var tempSalesourcesArray = []
          // get selected salesources
          for (var i = 0; i < self.salesources.length; i++) {
            var skipSalesource = false
            for (var j = 0; j < self.salesrepSalesourcesToSkip.length; j++) {
              if (self.salesources[i].id === self.salesrepSalesourcesToSkip[j].salesource_id) {
                skipSalesource = true
                break
              }
            }
            if (!skipSalesource) {
              tempSalesourcesArray.push(self.salesources[i])
            }
          }
          self.selectedSalesrepSalesources = tempSalesourcesArray
        })

        var data = {
          objectType: 'salesrep',
          objectId: this.salesrep.id
        }
        this.getTssInviteMostRecentHistory(data)
      } else {
        this.salesrepCommercialAccountsToSkip = []
        this.selectedSalesrepCommercialAccounts = []
        this.selectedSalesrepLocations = []
        this.salesrepLocationsToSkip = []
        this.selectedSalesrepSalesources = []
        this.salesrepSalesourcesToSkip = []
      }
      ResetValidateComponents(this.validateInputs)
    },
    saveSalesrep: function (event) {
      if (ValidateComponents(this.validateInputs)) {
        // loop through commercial accounts/selection

        this.salesrepCommercialAccountsToSkip = []
        // get commercial accounts to skip
        for (var i = 0; i < this.commercialaccounts.length; i++) {
          var skipCommercialAccount = true
          for (var j = 0; j < this.selectedSalesrepCommercialAccounts.length; j++) {
            if (this.commercialaccounts[i].id === this.selectedSalesrepCommercialAccounts[j].id) {
              skipCommercialAccount = false
              break
            }
          }
          if (skipCommercialAccount) {
            this.salesrepCommercialAccountsToSkip.push(this.commercialaccounts[i])
          }
        }

        this.salesrepLocationsToSkip = []
        for (var k = 0; k < this.locations.length; k++) {
          var skipLocation = true
          for (var l = 0; l < this.selectedSalesrepLocations.length; l++) {
            if (this.locations[k].id === this.selectedSalesrepLocations[l].id) {
              skipLocation = false
              break
            }
          }
          if (skipLocation) {
            this.salesrepLocationsToSkip.push(this.locations[k])
          }
        }

        this.salesrepSalesourcesToSkip = []
        for (var b = 0; b < this.salesources.length; b++) {
          var skipSalesource = true
          for (var r = 0; r < this.selectedSalesrepSalesources.length; r++) {
            if (this.salesources[b].id === this.selectedSalesrepSalesources[r].id) {
              skipSalesource = false
              break
            }
          }
          if (skipSalesource) {
            this.salesrepSalesourcesToSkip.push(this.salesources[b])
          }
        }

        let self = this
        this.salesrep.createUpdateSalesrepAndItemsToSkip(this.shopId, this.salesrepCommercialAccountsToSkip, this.salesrepLocationsToSkip, this.salesrepSalesourcesToSkip, function (object) {
          var skipSet = false
          if (self.salesrep.objectTrackerInitial.status === 'active' && object.status === 'disabled') {
            skipSet = true
          }
          self.salesrep = new Salesrep(object)
          self.$root.$emit('updateSelectSalesrep', self.salesrep, skipSet)
          self.$emit('updated', self.salesrep.id)

          if (self.saveAndOpenInvite) {
            self.saveAndOpenInvite = false
            self.inviteSalesrep()
          } else {
            self.$root.$emit('bv::hide::modal', 'salesrepModal')
          }
        })
      }
    },
    cancelEdits: function (event) {
      event.preventDefault()
      this.$root.$emit('bv::hide::modal', 'salesrepModal')
    },
    getSelectedCommercialAccounts: function () {}
  },
  watch: {
    'parentSalesrep': function (val) {
      this.salesrep = new Salesrep(this.parentSalesrep)
    },
    'salesrep.zip': function (val) {
      let self = this
      this.zipLookup(val, function (data) {
        self.salesrep.city = data.city
        self.salesrep.state = data.state_short
      })
    }
  },
  components: {
    RequiredInput,
    FloatDropdown,
    Phone,
    Email,
    MultiSelectDropdown
  },
  mixins: [UtilityMixin]
}
</script>

<style>
.idBox {
  background-color: #edc9af;
  padding: 5px;
  border-radius: 3px;
}

.pendingInvitationStatus {
  background-color: #ffff99 !important;
}

.acceptedInvitionStatus {
  background-color: #66ff99 !important;
}
</style>
