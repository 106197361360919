<template>
  <b-modal no-close-on-backdrop no-close-on-esc
  :title='dialogConfig ? dialogConfig.label : ""' id='dynamicDocumentModal'
  header-bg-variant='primary'
  header-text-variant='light'
  @show="dialogToShow"
  @shown="dialogShown"
  @hidden="dialogAboutToDisappear"
  size="lg">
    <div v-if='dialogConfig'>
      <div class='row'>
        <div class='form-group col-lg-2'>
          <FilePicker :buttonLabel="'Choose File'" :filePickerKey="filePickerKey" :dialogToReturnTo="'dynamicDocumentModal'" :handleFilePickerResponse="handleFilePickerResponse" :dropListenerName="'dynamicDialogListener'" :uploadingDropFile.sync="uploadingDropFile" :filepickerOpen.sync='filepickerOpen'/>
          Or Drag and Drop...
        </div>
        <div class="form-group col-lg-4">
          <a @click="deleteTempDocument()" href="javascript:void(0)" :class="{'form-control': true, 'form-control-sm': true, 'uploadingDropFile': uploadingDropFile}">
            <font-awesome-icon v-if="document.document_name != null && document.document_name.length > 0" style="color:red" :icon="faTimesCircle"/>{{ document.document_name != null && document.document_name.length > 0 ? document.document_name : uploadingDropFile ? 'Preparing File...' : 'No file chosen' }}
          </a>&nbsp;
          <label>&nbsp;&nbsp;</label>
        </div>
        <div class="form-group col-lg-3 text-right">
          <select v-model='document.document_type' class="form-control form-control-sm">
            <option value="">Category...</option>
            <option v-for='doc in dialogConfig.docOptions' :key='doc.label' :value="doc.value">{{doc.label}}</option>
          </select>
          <label>&nbsp;&nbsp;</label>
        </div>
        <div class="form-group col-lg-2 text-right">
          <button type='button' style="width: 100%;" class='btn btn-primary' @click="createNewDocument" variant="primary">Attach</button>
        </div>
      </div>
      <br/>
      <div class='row' v-for="docType in dialogConfig.docOptions" :key='"actualDocuments"+docType.value'>
        <div class='col-2'>
          <a v-bind:style="{ 'background-color': getDocumentTagColorWithType(dialogConfig.type, docType.value), 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;{{getDocumentTagNameWithType(dialogConfig.type, docType.value)}}
          </a>
        </div>
        <div class='col-10'>
          <span v-for="(doc, index) in documents" :key='doc.id+index'>
            <span v-if='doc.document_type === docType.value'>
              <font-awesome-icon @click="deleteDocument(index, doc)" style="color:red;cursor:pointer" :icon="faMinusCircle"/>&nbsp;{{doc.document_name}}&nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import FilePicker from '@/components/Helpers/FilePicker.vue'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faFile from '@fortawesome/fontawesome-free-solid/faFile'
import faMinusCircle from '@fortawesome/fontawesome-free-solid/faMinusCircle'
import DynamicDocument from '@/scripts/objects/document_'
import faTimesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle'
import {getDocumentTagColorWithType, getDocumentTagNameWithType} from '@/scripts/helpers/document.helpers'
import Api from '@/services/Api'

export default {
  props: ['filePickerKey'],
  data () {
    return {
      document: new DynamicDocument(),
      uploadingDropFile: false,
      uploadDocumentName: '',
      uploadDocumentUrl: '',
      dialogConfig: null,
      documents: [],
      filepickerOpen: false
    }
  },
  methods: {
    getDocumentTagColorWithType,
    getDocumentTagNameWithType,
    dialogToShow: function () {
      if (!this.filepickerOpen) {
        this.dialogConfig = this.$store.getters.documentConfig && this.$store.getters.documentConfig.config || null
        this.resetDialog()
        this.getDocuments()
      }
    },
    resetDialog: function () {
      this.uploadingDropFile = false
      this.uploadDocumentName = ''
      this.uploadDocumentUrl = ''
      this.document = new DynamicDocument({table_ref: this.dialogConfig.type, ref_id: this.dialogConfig.id})
    },
    getDocuments: function () {
      // go get documents for dialog only if there is a legit id
      if (this.dialogConfig && this.dialogConfig.id > 0) {
        var self = this
        DynamicDocument.getDynamicDocuments(this.dialogConfig.shopId, this.dialogConfig.type, this.dialogConfig.id, function (res) {
          self.documents = res
        })
      }
    },
    dialogShown: function () {
      if (!this.filepickerOpen) {
        if (this.$store.getters.documentConfig && this.$store.getters.documentConfig.file) {
          this.$root.$emit('dynamicDialogListener', this.$store.getters.documentConfig.file)
        }
        this.$store.commit('setDocumentConfig', null)
      } else {
        this.filepickerOpen = false
      }
    },
    deleteTempDocument: function () {
      this.document = new DynamicDocument({table_ref: this.dialogConfig.type, ref_id: this.dialogConfig.id})
    },
    handleFilePickerResponse: function (fileName, fileUrl) {
      this.document.document_name = fileName
      this.document.url = fileUrl
    },
    createNewDocument: function () {
      var self = this
      var isNew = false
      if (this.document && this.document.id < 0) {
        isNew = true
      }
      this.document.createUpdateDynamicDocument(this.dialogConfig.shopId, function (res) {
        self.document = new DynamicDocument({table_ref: self.dialogConfig.type, ref_id: self.dialogConfig.id})
        // self.documents.push(new DynamicDocument(res.data))
        self.resetDialog()
        self.getDocuments()
        self.$root.$emit('showGlobalNotification', 'Success', isNew ? 'Document Added' : 'Document Updated')
        self.$root.$emit('updateDocuments')
      })
    },
    deleteDocument: function (index, document) {
      var self = this
      document.deleteDynamicDocument(this.dialogConfig.shopId, function (res) {
        self.documents.splice(index, 1)
        // self.getDocuments()
        self.$root.$emit('updateDocuments')
        self.$root.$emit('showGlobalNotification', 'Success', 'Document Deleted')
      })
    },
    dialogAboutToDisappear: function () {
      if (!this.filepickerOpen && this.dialogConfig && this.dialogConfig.isModal && (this.dialogConfig.isModal === true || this.dialogConfig.isModal === 'true')) {
        if (this.dialogConfig && this.dialogConfig.dialogToReturnTo && this.dialogConfig.dialogToReturnTo.length > 0) {
          this.$root.$emit('bv::show::modal', this.dialogConfig.dialogToReturnTo)
        }
      }
    },
    filepickerButtonClicked: function () {
      this.filepickerOpen = true
    }
  },
  computed: {
    faFile () {
      return faFile
    },
    faMinusCircle () {
      return faMinusCircle
    },
    faTimesCircle () {
      return faTimesCircle
    },
    dropListenerCompName () {
      return this.dropListenerName || 'documentModalListener'
    },
    documentHelper () {
      return Document
    }
  },
  components: {
    FontAwesomeIcon,
    FilePicker
  }
}
</script>

<style>
.uploadingDropFile {
  background-color: #00beee !important;
  color: white;
}
#dynamicDocumentModal .modal-lg {
  min-width: 90%;
}
</style>