<template>
  <el-dialog
    center
    append-to-body
    title="Right Part search"
    custom-class="right-part-dialog"
    top="120px"
    width="1000px"
    :visible="visible"
    @close="handleClose"
  >
    <div
      v-if="detailsLoading"
      class="t-w-full t-h-32 t-flex t-items-center t-justify-center"
    >
      <div v-loading="true"></div>
    </div>
    <div v-else>
      <Details v-if="details !== null" />
      <Form v-if="hasAccess" />
    </div>
  </el-dialog>
</template>

<script>
import { get, call } from 'vuex-pathify'
import Form from './Form'
import Details from './Details'

export default {
  props: ['hasAccess'],
  computed: {
    visible: get('rightPart/isOpen'),
    loading: get('rightPart/loading'),
    details: get('rightPart/details'),
    detailsLoading: get('rightPart/detailsLoading')
  },
  methods: {
    close: call('rightPart/close'),
    handleClose () {
      if (!this.loading) {
        this.close()
      }
    }
  },
  components: {
    Form,
    Details
  }
}
</script>

<style lang="scss">
  .right-part-dialog {
    @apply t-font-sans;
  }

  .right-part-for-label {
    color: #909399;
  }
</style>
