import * as R from 'ramda'

export const getTotalTaxPercent = (type, zipcode) =>
  R.pipe(
    R.pluck('tax'),
    R.filter(R.prop(type)),
    R.filter(
      R.pipe(
        R.prop('tax_zip_codes'),
        R.when(R.length, R.find(R.propEq('zip_code', zipcode)))
      )
    ),
    R.pluck('percentage'),
    R.sum
  )
