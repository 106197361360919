<template>
  <div class="well-light">
    <span v-if="documents && documents.length > 0">
      <span v-for='(types, index) in documentOptions' :key='index+"types"'>
        <span v-for="(document, index2) in localDocuments" :key="index2+'docs'">
        <a v-if='document.document_type === types.value'
          v-bind:style="{ 'background-color': getDocumentTagColorWithType(document.table_ref, document.document_type) }"
          :href="document.url" target="_blank" class="tag tag-light">
          <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;{{prefix?prefix+'-':''}}{{document.typeName}}
        </a>
        </span>
      </span>
    </span>
    <span v-else>
      Or drag and drop...
    </span>
  </div>
</template>

<script>
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faFile from '@fortawesome/fontawesome-free-solid/faFile'
import {getDocumentOptions, getDocumentTagColorWithType, getDocumentTagNameWithType} from '@/scripts/helpers/document.helpers'
export default {
  props: ['type', 'documents', 'prefix'],
  data () {
    return {
      localDocuments: []
    }
  },
  methods: {
    getDocumentTagColorWithType,
    createLocalDocumentsObject () {
      this.localDocuments = []
      for (var i = 0; i < this.documentOptions.length; i++) {
        let counter = 0
        let tempArr = []
        for (var j = 0; j < this.documents.length; j++) {
          if (this.documents[j].document_type === this.documentOptions[i].value) {
            counter++
            this.documents[j].typeName = getDocumentTagNameWithType(this.documents[j].table_ref, this.documents[j].document_type)
            tempArr.push(this.documents[j])
          }
        }
        if (counter > 1) {
          for (var k = 0; k < tempArr.length; k++) {
            tempArr[k].typeName += '-' + (k+1)
          }
        }
        this.localDocuments = this.localDocuments.concat(tempArr)
      }
    }
  },
  mounted () {
    this.createLocalDocumentsObject()
  },
  watch: {
    documents () {
      this.createLocalDocumentsObject()
    }
  },
  computed: {
    documentOptions () {
      return getDocumentOptions(this.type)
    },
    faFile () {
      return faFile
    }
  },
  components: {
    FontAwesomeIcon
  }
}
</script>

<style>

</style>