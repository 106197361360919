<template>
  <div
    class="t-flex t-mt-2"
    :class="
      loading
        ? 't-cursor-not-allowed'
        : 't-cursor-pointer'
    "
    @click="onClick"
  >
    <div
      class="t-rounded-l-md t-w-10 t-text-sm t-flex t-items-center
        t-justify-center t-h-8 t-text-gray-600 t-border t-border-solid"
      :class="{
        [vars.bg]: true,
        [vars.border]: true,
        't-rounded-r-md': loading
      }"
    >
      <i v-if="loading" class="el-icon-loading" />
      <div v-else>
        <div v-if="price">
          {{ total }}
        </div>
        <i v-else class="el-icon-circle-close t-text-base" />
      </div>
    </div>
    <div
      v-if="!loading"
      class="t-flex t-items-center t-rounded-r-md t-border t-border-l-0
        t-border-solid t-relative t-px-2 t-space-x-2"
      :class="{
        [vars.border]: true
      }"
    >
      <div
        class="t-absolute t-text-2xs t--top-2 t-left-2 t-bg-white t-px-1
          t-tracking-wider"
        :class="{
          [vars.labelColor]: true
        }"
      >
        {{ vars.name }}
      </div>
      <div v-if="!loading && !price" class="t-text-xs t-text-gray-600">
        No price data
      </div>
      <div v-else-if="price === 'new'" class="t-text-xs t-text-white t-px-1 t-bg-blue-500 t-rounded-md">
        New!
      </div>
      <div v-else class="t-text-sm t-text-gray-600">
        ${{ price }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      validator: function (value) {
        return [
          'agd',
          'american',
          'igc',
          'inventory',
          'mygrant',
          'nm',
          'pgw',
          'pilkington'
        ].includes(value)
      }
    },
    loading: Boolean,
    total: [Number, String],
    price: [Number, String]
  },
  data: () => ({
    vendorButtons: {
      agd: {
        name: 'AGD',
        bg: 't-bg-orange-200',
        border: 't-border-orange-300',
        labelColor: 't-text-orange-500'
      },
      american: {
        name: 'American',
        bg: 't-bg-orange-200',
        border: 't-border-orange-300',
        labelColor: 't-text-orange-500'
      },
      igc: {
        name: 'IGC',
        bg: 't-bg-yellow-200',
        border: 't-border-yellow-300',
        labelColor: 't-text-yellow-500'
      },
      inventory: {
        name: 'Inventory',
        bg: 't-bg-gray-200',
        border: 't-border-gray-300',
        labelColor: 't-text-gray-500'
      },
      mygrant: {
        name: 'Mygrant',
        bg: 't-bg-blue-200',
        border: 't-border-blue-300',
        labelColor: 't-text-blue-500'
      },
      nm: {
        name: 'NM',
        bg: 't-bg-green-400',
        border: 't-border-green-500',
        labelColor: 't-text-green-700'
      },
      pgw: {
        name: 'PGW',
        bg: 't-bg-red-200',
        border: 't-border-red-300',
        labelColor: 't-text-red-500'
      },
      pilkington: {
        name: 'Pilkington',
        bg: 't-bg-green-200',
        border: 't-border-green-300',
        labelColor: 't-text-green-500'
      }
    }
  }),
  computed: {
    vars: function() {
      if (!['agd', 'american', 'igc', 'inventory', 'mygrant', 'nm', 'pgw',  'pilkington'].includes(this.type)) {
        return {}
      }

      return this.vendorButtons[this.type]
    }
  },
  methods: {
    onClick: function() {
      this.$emit('clicked')
    }
  }
}
</script>
