var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id + "-spinner",
        "hide-footer": "",
        "hide-header": "",
        size: "sm",
        "header-bg-variant": "primary"
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "block",
            "margin-left": "auto",
            "margin-right": "auto",
            width: "20%"
          }
        },
        [
          _c("img", {
            attrs: { src: require("./../../assets/img/spinner.gif") }
          })
        ]
      ),
      _c("div", { staticClass: "mx-auto", staticStyle: { width: "200px" } }, [
        _vm._v(" " + _vm._s(_vm.message) + " ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }