var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title: _vm.title,
        id: "globalNotificationModal",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg"
      },
      on: { show: _vm.dialogAboutToAppear }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col" }, [
          _vm._v(" " + _vm._s(_vm.message) + " ")
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _vm.showDoNotShowAgainCheckbox
            ? _c(
                "div",
                { staticClass: "float-left" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "doNotShowAgainCheckbox",
                        value: true,
                        "unchecked-value": false
                      },
                      model: {
                        value: _vm.doNotShowAgain,
                        callback: function($$v) {
                          _vm.doNotShowAgain = $$v
                        },
                        expression: "doNotShowAgain"
                      }
                    },
                    [_vm._v("Do not show this message again")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", size: "lg", variant: "primary" },
                on: { click: _vm.okayClicked }
              },
              [_vm._v("Okay")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }