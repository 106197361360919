// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".add-payment-dialog .el-dialog__body {\n  padding: 0 !important;\n}", "",{"version":3,"sources":["/app/src/components/ARAPPage/Payment/EditPayment.vue"],"names":[],"mappings":"AA8SE;EACE,qBAAA;AAlSJ","file":"EditPayment.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.add-payment-dialog .el-dialog__body {\n  padding: 0 !important;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
