<template>
  <div
    class="consumer-action-buttons t-absolute t-space-x-5 lg:t-space-x-3 t-py-1
      t-right-4 t-flex t-items-center t-bg-white t-rounded-full t-border
      t-border-solid t-border-gray-300 t-px-3"
    >
    <span class='primary' v-if="isPrimary">primary</span>
    <span class='secondary' v-if="isSecondary">secondary</span>
    <el-popover 
      placement="top"
      trigger="hover" 
      class="primary-secondary-popover" 
      :disabled="isPrimary"
    >
      <div>
        <div class="t-cursor-pointer" v-if="!isPrimary" @click="$emit('setPrimary')">
          Set to primary
        </div>
        <div v-if="!isPrimary && type === 'phone'">
          <br/>
        </div>
        <div class="t-cursor-pointer" v-if="!isSecondary && !isPrimary && type === 'phone'" @click="$emit('setSecondary')">
          Set to secondary
        </div>
        <div class="t-cursor-pointer" v-if='isSecondary' @click="$emit('unsetSecondary')">
          Unset secondary
        </div>
      </div>
      <i slot="reference"
        class="el-icon-edit t-text-blue-500 t-cursor-pointer
          t-transform t-duration-300 hover:t-scale-115"
      />
    </el-popover>
    <el-popover 
      placement="top"
      trigger="hover"  
    >   
      <div>Delete</div>
      <i slot="reference"
        class="el-icon-circle-close t-text-red-500 t-cursor-pointer
          t-transform t-duration-300 hover:t-scale-115"
        @click="$emit('clear')"
      />
    </el-popover>
  </div>
</template>

<script>
export default {
  props: ['isPrimary', 'isSecondary', 'type']
}
</script>

<style lang="scss">
.consumer-action-buttons {
  top: 0px;
  .primary {
    line-height: 0px;
    font-size: smaller;
    color: #409EFF;
  }
  .secondary {
    line-height: 0px;
    font-size: smaller;
    color: purple;
  }
}
</style>