var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "append-to-body": "",
        title: "Image Crop",
        top: "75px",
        width: "800px",
        "custom-class": "image-crop-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      !_vm.cropperReady ? _c("LoadingSpinner") : _vm._e(),
      _c(
        "div",
        { staticClass: "t-mt-2 t-mx-4 t-pb-2" },
        [
          _c("cropper", {
            attrs: {
              src: _vm.value,
              "stencil-props": {
                aspectRatio: 1
              }
            },
            on: {
              change: _vm.onChange,
              ready: function($event) {
                _vm.cropperReady = true
              },
              error: _vm.onError
            }
          }),
          _vm.cropperReady
            ? _c(
                "div",
                { staticClass: "t-text-center t-mt-2" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.setAvatar }
                    },
                    [_vm._v(" Set Avatar ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }