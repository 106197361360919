<template>
  <div>
    <div class='row'>
      <div class='col-12' style='text-align: center;'>
        <div class='doc-popover-title'>
          <a @click="closePopover" class="close-button" aria-label="Close">
            <span class="d-inline-block" aria-hidden="true">&times;</span>
          </a>
        </div>
      </div>
    </div>
    <div>
      <div class='col-12' style='text-align: center;'>
        Choose Type...
      </div>
      <div class='row'>
        <span class='col' v-for='(docType, index) in docTypes' :key='type+index' style='text-align: center;'>
          <a @click='optionSelected(docType)' v-bind:style="{ 'cursor': 'pointer', 'white-space': 'nowrap', 'background-color': getDocumentTagColorWithType(type, docType.value), 'color':'white' }" class="tag tag-light">
            <font-awesome-icon :icon="faFile"/>&nbsp;&nbsp;{{getDocumentTagNameWithType(type, docType.value)}}
          </a>
        </span>
      </div>
      <div class='col-12' style='text-align: center;'>
        Or...
      </div>
      <div class='col-12' style='text-align: center;'>
        <b-button @click='openDialog' v-bind:style="{ 'background-color': '#2287b7', 'color':'white' }">
          Open Dialog
        </b-button>
      </div>
    </div>
    <div v-if='saving' id='documentSavingSpinner'>
      <v-icon id='spinner-itself' name="spinner" style='color: black;' spin scale="1.5"/>
    </div>
  </div>
</template>

<script>

import {getDocumentTagColorWithType, getDocumentTagNameWithType} from '@/scripts/helpers/document.helpers'
import faFile from '@fortawesome/fontawesome-free-solid/faFile'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import DynamicDocument from '@/scripts/objects/document_'
import Document from '@/scripts/objects/document'
import * as filepicker from 'filepicker-js'

export default {
  props: ['type', 'docTypes', 'file', 'filePickerKey', 'refId', 'shopId', 'show'],
  data () {
    return {
      saving: false
    }
  },
  methods: {
    getDocumentTagColorWithType,
    getDocumentTagNameWithType,
    openDialog () {
      if (this.type === 'job') {
        var self = this
        self.saving = true
        filepicker.setKey(self.filePickerKey)
        filepicker.store(self.file,
          function (Blob) {
            let temp = new Document({
              document_type: self.type,
              document_name: Blob.filename,
              url: Blob.url
            })
            self.saving = false
            self.$root.$emit('openDocumentDialog', temp)
            self.$emit('closePopover')
          }
        )
        
      } else {
        this.$emit('openDialog')
      }
    },
    closePopover () {
      this.$emit('closePopover')
    },
    optionSelected (type) {
      var self = this
      self.saving = true
      filepicker.setKey(self.filePickerKey)
      filepicker.store(self.file,
        function (Blob) {
          let temp = new DynamicDocument({
            document_type: type.value,
            document_name: Blob.filename,
            url: Blob.url,
            table_ref: self.type,
            ref_id: self.refId
          })
          var isNew = false
          if (temp && temp.id < 0) isNew = true // this is kind of stupid as we don't update docs
          if (self.type === 'job' && self.refId === 'new') {
            self.saving = false
            self.$emit('closePopover')
            self.$root.$emit('updateDocuments', temp)
          } else {
            temp.createUpdateDynamicDocument(self.shopId, function (res) {
              self.saving = false
              self.$emit('closePopover')
              self.$root.$emit('showGlobalNotification', 'Success', isNew ? 'Document Added.' : 'Document Updated.')
              self.$root.$emit('updateDocuments', res)
            })
          }
        },
        function (FPError) {
          self.saving = false
          self.$root.$emit('showGlobalNotification', 'Danger', 'Document failed to save.')
          self.$root.$emit('updateDocuments')
        }
      )
      // save file
    }
  },
  computed: {
    faFile () {
      return faFile
    }
  },
  components: {
    FontAwesomeIcon
  }
}
</script>

<style>
#documentSavingSpinner {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, .1);
}
#spinner-itself {
  margin-top: 35%
}
.doc-popover-title {
  background-color: lightgray;
}
.close-button {
  position: absolute;
  top: -12px;
  right: 0px;
  width: 32px;
  height: 32px;
  font-size: 150%;
  opacity: 0.4;
}
.close-button:hover {
  opacity: 1;
  cursor: pointer;
}
</style>