import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class FaxHistory extends ObjectTracker {
  id = -1
  filename = ''
  name = ''
  url = ''
  sender = -1
  receiver = -1
  user_id = -1
  faxdatetime = ''
  shop_id = -1
  pagecount = -1
  status = ''
  fax_type = ''
  object_id = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, fax_type, object_id, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/shop/${shopId}/fax_history/${fax_type}/${object_id}`}).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new FaxHistory(val))
      })
      callbackFunction(objects)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
