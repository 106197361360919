var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "t-mb-4 t-mt-1", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("input-with-label", {
                attrs: {
                  label: "Default Invoice Tier",
                  size: "medium",
                  select: {
                    options: _vm.terms,
                    clearable: true,
                    byId: true
                  }
                },
                model: {
                  value: _vm.invoiceTier,
                  callback: function($$v) {
                    _vm.invoiceTier = $$v
                  },
                  expression: "invoiceTier"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("input-with-label", {
                attrs: {
                  select: {
                    options: _vm.gb_salesreps,
                    byId: true
                  },
                  size: "medium",
                  label: "Default Salesrep"
                },
                on: {
                  focus: function($event) {
                    _vm.repsFocused = true
                  }
                },
                model: {
                  value: _vm.defaultSalesrep,
                  callback: function($$v) {
                    _vm.defaultSalesrep = $$v
                  },
                  expression: "defaultSalesrep"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "t-mb-4", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("input-with-label", {
                attrs: {
                  select: {
                    options: _vm.gb_techs,
                    byId: true
                  },
                  size: "medium",
                  label: "Default Tech"
                },
                on: {
                  focus: function($event) {
                    _vm.techsFocused = true
                  }
                },
                model: {
                  value: _vm.defaultTech,
                  callback: function($$v) {
                    _vm.defaultTech = $$v
                  },
                  expression: "defaultTech"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("input-with-label", {
                attrs: { size: "medium", label: "Standing PO" },
                on: {
                  focus: function($event) {
                    _vm.standingPoFocused = true
                  }
                },
                model: {
                  value: _vm.standingPo,
                  callback: function($$v) {
                    _vm.standingPo = $$v
                  },
                  expression: "standingPo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "t-mb-4", attrs: { gutter: 16 } },
        [
          !this.value.is_insurance
            ? _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "bill-to-select" },
                  [
                    _c("consumer-search-bar", {
                      ref: "bar",
                      attrs: {
                        organization_id: _vm.value.organization_id,
                        shop_id:
                          _vm.$job && _vm.$job.shop ? _vm.$job.shop.id : null,
                        label: "Default Bill To",
                        autoFocus: _vm.selectingBillTo,
                        "selected-id": +_vm.defaultBillTo.id,
                        placeholder: _vm.defaultBillTo.full_name,
                        type: "insurance"
                      },
                      on: {
                        select: _vm.selectBillTo,
                        clear: function($event) {
                          _vm.defaultBillTo = null
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("input-with-label", {
                attrs: { size: "medium", label: "Customer Resale ID" },
                model: {
                  value: _vm.resaleId,
                  callback: function($$v) {
                    _vm.resaleId = $$v
                  },
                  expression: "resaleId"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "t-mb-6", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("input-with-label", {
                attrs: {
                  type: "address",
                  label: "Default install location",
                  options: {
                    clearable: true,
                    defaultResults: _vm.extraAddresses
                  }
                },
                model: {
                  value: _vm.installLocationAddress,
                  callback: function($$v) {
                    _vm.installLocationAddress = $$v
                  },
                  expression: "installLocationAddress"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "t-mb-4", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "gb-checkbox",
                {
                  model: {
                    value: _vm.autoAddAdh,
                    callback: function($$v) {
                      _vm.autoAddAdh = $$v
                    },
                    expression: "autoAddAdh"
                  }
                },
                [_vm._v(" Auto Add Adhesive ")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "gb-checkbox",
                {
                  model: {
                    value: _vm.autoAddToFleet,
                    callback: function($$v) {
                      _vm.autoAddToFleet = $$v
                    },
                    expression: "autoAddToFleet"
                  }
                },
                [_vm._v(" Auto Add Vehicles To Fleet ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "t-mb-4", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "gb-checkbox",
                {
                  model: {
                    value: _vm.autoSetInstallAddress,
                    callback: function($$v) {
                      _vm.autoSetInstallAddress = $$v
                    },
                    expression: "autoSetInstallAddress"
                  }
                },
                [_vm._v(" Auto Set Install Address ")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "gb-checkbox",
                {
                  model: {
                    value: _vm.autoSendConfirmationTexts,
                    callback: function($$v) {
                      _vm.autoSendConfirmationTexts = $$v
                    },
                    expression: "autoSendConfirmationTexts"
                  }
                },
                [_vm._v(" Auto Send Confirmation Texts ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }