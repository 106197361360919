<template>
  <div class="document-list-item" :class="{ expanded }">
    <span v-if="expanded">{{ name }}</span>
    <documents-tag :doc="doc" :number="doc.number" />
  </div>
</template>

<script>
import DocumentsTag from './Tag'

export default {
  components: { DocumentsTag },
  props: {
    doc: Object,
    expanded: Boolean,
    number: Number
  },
}
</script>

<style lang="scss" scoped>
.document-list-item {
  margin-bottom: $padding-xss;
  margin-right: $padding-xss;
  cursor: pointer;
  &.expanded {
    padding: $padding-sm $padding-md;
    border: 1px solid $grayscale-3;
    border-radius: 3px;
    font-size: 14px;
    color: $grayscale-8;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 0;
  }
}
</style>
