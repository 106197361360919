import * as R from 'ramda'

const PREFIX = 'notificationsSettings/';

const actions = {
  open: 'open',
  close: 'close',

  setStatus: 'setStatus',

  load: 'load',
  loadSettings: 'loadSettings',
  loadEventsSettings: 'loadEventsSettings',

  save: 'save',
  changeSettings: 'changeSettings',
  changeEventsSettings: 'changeEventsSettings',
};


const mutations = {
  setIsOpened: 'setIsOpened',
  setSettings: 'setSettings',
  setEventsSettings: 'setEventsSettings',
  setSettingsItem: 'setSettingsItem',

  changeSettings: 'changeSettings',
  changeEventsSettings: 'changeEventsSettings',
  setStatus: 'setStatus'
};

const globalActions = R.map(R.concat(PREFIX))(actions);
const globalMutations = R.map(R.concat(PREFIX))(mutations);

export {
  actions,
  mutations,
  globalActions,
  globalMutations
}
