<template>
  <div class="notification d-flex flex-column">

    <!-- HEADER -->
    <div class="d-flex align-items-center header">
      <div class="icon-square-round" :class="backgroundClass">
        <i class="material-icons-round md-24" :class="iconClass" />
      </div>

      <div class="d-flex flex-column">
        <div class="title font-weight-bold" :class="titleClass">
          <slot name="title"></slot>
        </div>

        <div class="subtitle d-flex">
          <div
            class="font-weight-bold"
            v-if="!isUnknown && isEmail(data.details.user.name)"
          >
            <el-tooltip class="box-item" effect="light" placement="top"
              :content="data.details.user.name"
            >
              <span>@{{parsedUsername}}</span>
            </el-tooltip>
          </div>

          <div class="font-weight-bold" v-else-if="!isUnknown">
            @{{data.details.user.name}}
          </div>
          <div class="font-weight-bold t-text-gray-400" v-else>
            @GlassBiller
          </div>

          <div class="t-text-gray-400 date">
            <span v-if="isToday">{{messageDate.time}}</span>

            <el-tooltip
              class="box-item"
              effect="light"
              :content="messageDate.time"
              placement="top"
              v-else
            >
              <span>{{messageDate.date}}</span>
            </el-tooltip>
          </div>
        </div>
      </div>

    </div>

    <!-- BODY -->
    <div class="text-break">
      <slot></slot>
    </div>

    <!-- FOOTER -->
    <div class="d-flex footer" v-if="!!data.phone_numbers || !!data.email_addresses">
      <div class="d-flex align-items-center ml-auto channels">

        <!-- SMS FLAG -->
        <el-popover
          placement="top-start"
          title="Sent via SMS"
          width="200"
          trigger="hover"
          :content="smsTooltip"
          v-if="!!data.phone_numbers"
        >
          <i class="material-icons-round md-24 md-sms" slot="reference" />
        </el-popover>

        <!-- EMAIL FLAG -->
        <el-popover
          placement="top-start"
          title="Sent via email"
          width="200"
          trigger="hover"
          :content="emailTooltip"
          v-if="!!data.email_addresses"
        >
          <i class="material-icons-round md-24 md-mail" slot="reference" />
        </el-popover>

      </div>
    </div>

  </div>
</template>

<script>
import { isEmail } from 'validator';
import { DateTime } from 'luxon';
import { EventsStyles } from '@/store/modules/notifications-settings/events';
import {
  formatPhonePartial
} from '@/store/modules/notifications-settings/helpers';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    isUnknown() {
      return this.data.details?.user === undefined;
    },

    isToday() {
      const dt = DateTime.fromSeconds(this.data.dt);
      return DateTime.local().toISODate() === dt.toISODate();
    },

    messageDate() {
      const dt = DateTime.fromSeconds(this.data.dt);
      return {
        date: dt.toLocaleString(DateTime.DATE_SHORT),
        time: dt.toLocaleString(DateTime.TIME_SIMPLE)
      }
    },

    parsedUsername() {
      return this.data.details.user.name.split('@')[0]
    },

    titleClass() {
      let color = EventsStyles[this.data.event_type]['color'];
      return `t-text-${color}-700`;
    },

    backgroundClass() {
      let color = EventsStyles[this.data.event_type]['color'];
      return `t-bg-${color}-100`;
    },

    iconClass() {
      let {color, icon} = EventsStyles[this.data.event_type];
      return `t-text-${color}-600 ${icon}`;
    },

    smsTooltip() {
      return this.data.phone_numbers.map(
        el => formatPhonePartial(el)).join(', ');
    },

    emailTooltip() {
      return this.data.email_addresses.join(', ');
    },
  },

  methods: {
    isEmail
  },
}
</script>


<style lang="scss" scoped>
.glassbiller-icon {
  width: 37px;
  height: 16px;
  margin-left: -9px;
  margin-top: -3px;
}

.notification {
  width: 296px;
  gap: 16px;
}

.header {
  gap: 16px;
  height: 42px;
  padding-right: 24px;

  .icon {
    height: 40px;
  }

  .title {
    height: 24px;
    font-size: 16px;
  }

  .subtitle {
    height: 16px;
    font-size: 12px;

    .date {
      margin-left: 8px;
    }
  }
}

.footer {
  .channels {
    height: 18px;
    gap: 8px;

    // fix sms icon
    .md-sms {
      margin-top: 3px;
    }
  }
}

// TEMPORARY STYLE FIX
.t-bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.t-bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.t-bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
</style>