const state = {
  isOpened: false,
  activeTab: 'unread',

  unreadCount: 0,

  readAllLoader: false,
  resetInfiniteLoader: false,

  tabs: {
    read: {
      type: 'read',
      rows: [],
      page: 0,

      // added in order to basically have same interface for read tab.
      locks: [],
    },
    unread: {
      type: 'unread',
      rows: [],
      page: 0,

      // notifications we are removing from unread list right now.
      // All notifications id added to this list will not be added in addNotification mutation
      // This is required to avoid the case when we are pressing read too fast so this happens:
      //     1) read notification.
      //         * read animation played
      //         * request to get next notification to refill list sent to backend
      //     2) read another notification
      //         * animation played
      //     3) response to refill request for 1 action received and notification that we removed on 2 step is added again.
      locks: [],
    }
  },
};

export default state;
