import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class SalesrepCommercialaccountToSkip extends ObjectTracker {
  id = -1
  salesrep_id = -1
  commercialaccount_id = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAllForSalesrep (shopId, salesrepId, callbackFunction) {
    
    Api({ url: `/api/shop/${shopId}/salesrep/${salesrepId}/salesrepCommercialaccountToSkip` }).then(res => {
      
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new SalesrepCommercialaccountToSkip(val))
      })
      
      callbackFunction(objects)
    })
  }
}
