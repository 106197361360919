var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-text-blue-500 t-text-sm t-text-center t-mb-4" },
    [
      _c(
        "span",
        {
          staticClass: "t-cursor-pointer",
          on: {
            click: function($event) {
              return _vm.$emit("loadMore")
            }
          }
        },
        [_vm._v(" Load more ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }