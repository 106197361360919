<template>
  <div>
    <el-row>
      <el-col>
        <el-row>
          <gb-card title="My Open Tickets">
            <ticket-table :data="data" :loading="loadingHistory" />
            <el-row v-if='hasMore'>
              <el-button class='float-right' type='text' @click="$emit('changeTab', 'history')">See More</el-button>
            </el-row>
          </gb-card>
        </el-row>
        <el-row>
        <ticket-charts v-if='showCharts' :data='stats' :loading='loadingStats'/>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TicketCharts from './Charts/Tickets/Index.vue'
import TimeChart from './Charts/Time/Index.vue'
import TicketTable from './TicketTable.vue'
import moment from 'moment'
import { call } from 'vuex-pathify'

export default {
  props: ['tab'],
  components: {
    TicketCharts,
    TimeChart,
    TicketTable
  },
  data() {
    return {
      data: [],
      loadingHistory: false,
      stats: {},
      loadingStats: false,
      hasMore: false
    }
  },
  computed: {
    showCharts() {
      return this.stats?.shops?.length || this.stats?.user?.length
    }
  },
  methods: {
    async getHistory(onlyOpen, onlyUser = true) {
      this.loadingHistory = true
      const {data} = await this.$unum.support().get(`/ticket/history?limit=6&onlyOpen=${onlyOpen}&onlyUser=${onlyUser}`)
      this.data = data.rows
      this.hasMore = data.rows.length < data.count
      this.loadingHistory = false
    },
    async getStats() {
      this.loadingStats = true
      const {data} = await this.$unum.support().get(`/ticket/stats${this.$role.can('account_manager') ? '?includeAll=true' : ''}`)
      this.stats = data
      this.loadingStats = false
    },
    getFormattedDate(iso) {
      return moment(iso).format('MM/DD/YY h:mm A')
    },
    openTicket: call('support/openTicket')
  },
  watch: {
    tab(v) {
      if(v==='dashboard') {
        this.getHistory(true)
        this.getStats()
      }
    }
  }
}
</script>

<style>

</style>