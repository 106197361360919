var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length > 0
    ? _c(
        "el-form",
        {
          ref: "form",
          staticClass: "t-flex",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "medium",
            inline: "",
            "hide-required-asterisk": ""
          }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "t-m-0 t-w-48", attrs: { prop: "deliveryMethod" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "t-pr-3",
                  attrs: { placeholder: "Delivery method", size: "medium" },
                  model: {
                    value: _vm.form.deliveryMethod,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "deliveryMethod", $$v)
                    },
                    expression: "form.deliveryMethod"
                  }
                },
                [
                  _c("el-option", {
                    attrs: { label: "Schedule run", value: "Schedule Run" }
                  }),
                  _c("el-option", {
                    attrs: { label: "Will call", value: "Will Call" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "t-m-0 t-w-32", attrs: { prop: "po" } },
            [
              _c("el-input", {
                attrs: { placeholder: "PO number", size: "medium" },
                model: {
                  value: _vm.form.po,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "po", $$v)
                  },
                  expression: "form.po"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "t-m-0 t-flex-1",
              attrs: { prop: "comment", id: "place-order-form-comment" }
            },
            [
              _c("el-input", {
                staticClass: "t-px-3",
                attrs: { placeholder: "Order notes", size: "medium" },
                model: {
                  value: _vm.form.comment,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "comment", $$v)
                  },
                  expression: "form.comment"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "t-m-0" },
            [
              _c(
                "el-button",
                {
                  staticClass: "t-bg-blue-500 hover:t-bg-blue-400",
                  attrs: {
                    type: "primary",
                    size: "medium",
                    loading: _vm.submitting
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(" Place order ")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }