<template>
  <div class='pdf-options-header'>
    <span class='title'>Pdf Column Sort/Visibilty Options</span>
    <span class='reset-button'>
      <el-tooltip content='Reset Pdf columns to GlassBiller defaults'>
        <el-button type='primary' :icon='loading ? "" : "el-icon-refresh-left"' round @click='resetOptions' :loading='loading' size='mini'>
          Reset To Default
        </el-button>
      </el-tooltip>
    </span>
  </div>
</template>

<script>
import Shop from '@/scripts/objects/shop'

export default {
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async resetOptions () {
      this.loading = true
      try {
      const {data} = await Shop.getGlassBillerShopDefaultPdfOptions()
      this.loading = false
      this.$root.$emit('reset-pdf-to-default', data)
      } catch (err) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang='scss'>
@import './styles/PdfOptionHeader.scss';
</style>