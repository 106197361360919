<template>
  <el-table
    border
    header-cell-class-name="t-bg-gray-100 t-text-gray-700 t-font-medium t-break-normal"
    class="t-mb-4"
    :data="lines"
  >

    <el-table-column label="" width="50">
      <template slot-scope="scope">
        <div class="t-flex t-justify-center">
          <el-popconfirm
            title="Do you want to remove the item ?"
            icon-color="red"
            confirm-button-text="Yes"
            cancel-button-text="No"
            @confirm="$emit('remove-line', scope.row.jobPartId)"
          >
            <i
              slot="reference"
              class="el-icon-circle-close t-text-red-400 t-text-base t-cursor-pointer"
            />
          </el-popconfirm>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="Item & Description">
      <template slot-scope="scope">
        <div class="t-break-normal">
          <div class="t-font-medium">{{ scope.row.itemName }}</div>
          <div class="t-text-xs t-italic t-text-gray-400">
            {{ scope.row.itemDescription }}
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="Job #" width="120">
      <template slot-scope="scope" class="t-text-xs">
        <a
          v-if="scope.row.jobId !== null"
          target="_blank"
          :href="'/jobs/' + scope.row.jobId"
        >
          {{ scope.row.jobId }}
        </a>
      </template>
    </el-table-column>

    <el-table-column label="Qty" width="120" prop="quantity" />

    <el-table-column label="PO #" width="120">
      <template slot-scope="scope">
        <div class="t-flex">
          <div
            v-if="scope.row.purchaseOrderId !== null"
            class="t-px-2 t-rounded t-opacity-80 t-text-white t-cursor-pointer"
            :style="'background-color: ' + getSupplierColor(scope.row.purchaseOrderSupplierId)"
            @click="openPO(scope.row.purchaseOrderId)"
          >
            PO {{ scope.row.purchaseOrderInternalId }}
          </div>
        </div>
      </template>
    </el-table-column>

  </el-table>
</template>

<script>
export default {
  props: {
    lines: Array,
    organizationId: Number
  },

  data() {
    return {
      suppliers: []
    }
  },

  mounted() {
    this.getSuppliers()
  },

  methods: {
    async getSuppliers() {
      try {
        const { data } = await this.$unum.vendor()
          .get(`organization/gb_supplier/${this.organizationId}/details`)
        this.suppliers = data
      } catch {
        this.$message.error('Can\'t get a list of suppliers')
      }
    },

    getSupplierColor(supplierId) {
      const supplier = this.suppliers.find(i => i.id === supplierId)
      return supplier?.color || '#52525B'
    },

    openPO(poId) {
      this.$store.dispatch('warehouse/openPurchaseOrderDialog', poId)
    }
  }
}
</script>
