<template>
  <div class="vin-input">
    <el-input
      v-model="string"
      @focus="focused = true"
      @blur="
        focused = false;
        $emit('input', local);
        $emit('blur', $event);
      "
      ref="input"
    />

    <div class="vin-placeholder">
      <table>
        <tr class="top-row">
          <td v-for="i in 17" :key="i" class="vin-symbols">
            <!-- current: i === local.length + 1 && focused, -->
            <span
              class="caret"
              :class="{
                filled: !!local[i - 1],
              }"
            >
              {{ local[i - 1] || "_" }}
            </span>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              class="vin-part-indicator"
              v-if="make !== null"
              :class="{ invalid: !make }"
            />
          </td>
          <td colspan="5">
            <div
              class="vin-part-indicator"
              v-if="model !== null"
              :class="{ invalid: !model }"
            />
          </td>
          <td>
            <div
              class="vin-part-indicator"
              v-if="check !== null"
              :class="{ invalid: !check }"
            />
          </td>
          <td>
            <div
              class="vin-part-indicator"
              v-if="year !== null"
              :class="{ invalid: !year }"
            />
          </td>
        </tr>
      </table>
    </div>
    <el-popover
      :value="focused && !!make"
      trigger="manual"
      placement="bottom-start"
    >
      <div slot="reference" />
      <div class="vin-results">
        <div class="vin-results-col">
          <div v-if="year"><span>Year:</span> {{ year }}</div>
          <div v-if="make"><span>Make:</span> {{ make }}</div>
          <div v-if="model"><span>Model:</span> {{ model }}</div>
        </div>
        <div class="vin-results-col">
          <div v-if="style"><span>Style:</span> {{ style }}</div>
          <div v-if="doors"><span>Doors:</span> {{ doors }}</div>
          <div v-if="trim"><span>Trim:</span> {{ trim }}</div>
        </div>
        <div class="close-icon" @click="focused = false">
          <i class="material-icons md-close md-18" />
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import * as R from 'ramda'
import Axios from 'axios'
import debounce from 'lodash.debounce'

const NHTS = Axios.create({
  baseURL: 'https://vpic.nhtsa.dot.gov/api/vehicles/',
  params: { format: 'json' }
})

const checkErrorCode = (code) =>
  R.pipe(R.prop('ErrorCode'), R.contains(code), R.not)

export default {
  props: {
    value: String
  },
  data() {
    return {
      local: '',
      focused: true,
      make: null,
      model: null,
      year: null,
      check: null,
      style: null,
      listener: null,
      trim: null,
      doors: null
    }
  },
  computed: {
    placeholder() {
      return ' '.repeat(this.local.length) + '_'.repeat(17 - this.local.length)
    },
    string: {
      get() {
        return this.local
      },
      set(v) {
        this.local = R.pipe(
          R.take(17),
          R.replace(/[^0-9A-Za-z]+/g, ''),
          R.toUpper
        )(v)
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    getWMI: debounce(async function () {
      try {
        const { data } = await NHTS.get(`decodewmi/${R.take(3)(this.local)}`)
        this.make = R.pathOr(false, ['Results', 0, 'CommonName'])(data)
      } catch {}
    }, 400),
    getVinValues: debounce(async function (l) {
      try {
        const { data } = await NHTS.get(`decodevinvalues/${this.local}`)
        const results = data?.Results?.[0] || {}
        this.make = results.Make
        if (l >= 8) {
          this.model = checkErrorCode(14)(results) ? results.Model : ''
          this.style = checkErrorCode(14)(results) ? results.BodyClass : ''
          this.trim = checkErrorCode(14)(results) ? results.Trim : ''
          this.doors = checkErrorCode(14)(results) ? results.Doors : ''
        }
        if (l >= 11) {
          this.year = checkErrorCode(14)(results) ? results.ModelYear : ''
        }
        if (l === 17) {
          this.check = checkErrorCode(1)(results)
        }
      } catch {}
    }, 400)
  },
  watch: {
    async local(v, old) {
      if (old || this.focused) {
        if (
          v.length === 3 ||
          v.length === 8 ||
          v.length === 11 ||
          v.length === 17
        ) {
          this.getVinValues(v.length)
        }
        if (v.length < 3) this.make = null
        if (v.length < 8) this.model = null
        if (v.length < 11) this.year = null
        if (v.length < 17) this.check = null
        if (v.length === 17) {
          this.$emit('input', v)
        }
      }
    },
    value(v) {
      this.local = v
    }
  },
  mounted() {
    this.local = this.value || ''
  }
}
</script>
<style lang="scss" scoped>
@import "./VinInput.scss";
</style>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap");
.vin-input {
  min-width: 270px;
  .el-input {
    z-index: 10;
    input {
      font-size: 16px;
      font-family: "Roboto Mono", monospace;
      letter-spacing: 4.4px;
      text-transform: uppercase;
      background: transparent;
      caret-color: #000;
      color: transparent;
      height: 38px;
    }
  }
}
.vin-results {
  font-size: $font-md;
  display: flex;
  position: relative;
  .vin-results-col {
    margin-right: $padding-sm;
    div + div {
      margin-top: $padding-xxs;
    }
  }
  span {
    color: $grayscale-7;
  }
  .close-icon {
    position: absolute;
    top: -6px;
    right: -6px;
    cursor: pointer;
  }
}
</style>
