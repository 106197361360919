var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.internalSearchValue,
          expression: "internalSearchValue"
        }
      ],
      ref: _vm.inRef || "",
      staticStyle: { width: "85%" },
      attrs: { type: "text", placeholder: _vm.placeholder || "" },
      domProps: { value: _vm.internalSearchValue },
      on: {
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.localEnter($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            return _vm.localDown($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            return _vm.localUp($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
            ) {
              return null
            }
            return _vm.localTab($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                "Backspace",
                "Delete",
                "Del"
              ])
            ) {
              return null
            }
            return _vm.localDelete($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            $event.stopPropagation()
          }
        ],
        focus: _vm.localFocus,
        blur: _vm.localBlur,
        click: function($event) {
          $event.stopPropagation()
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.internalSearchValue = $event.target.value
        }
      }
    }),
    _c(
      "span",
      { staticStyle: { float: "right" } },
      [
        _vm.loading
          ? _c("v-icon", { attrs: { name: "spinner", spin: "", scale: "1.2" } })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }