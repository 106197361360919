var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-w-full t-flex-shrink-0 t-flex t-items-center t-justify-center"
    },
    [
      _vm.loading
        ? _c("div", { staticClass: "t-flex t-items-center t-justify-center" }, [
            _c("i", { staticClass: "el-icon-loading t-text-lg" })
          ])
        : _vm.thumb_url || _vm.image_url
        ? _c("img", {
            staticClass: "t-w-full t-object-contain",
            attrs: { src: _vm.thumb_url || _vm.image_url, alt: "Car image" },
            on: { error: _vm.getRemoteImages }
          })
        : _c(
            "div",
            {
              staticClass:
                "t-w-full t-h-full t-text-gray-400 t-flex t-flex-col\n      t-justify-center t-items-center"
            },
            [
              _c("i", { staticClass: "el-icon-picture-outline t-text-2xl" }),
              _c("div", { staticClass: "t-text-2xs" }, [_vm._v("No image")])
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }