<template>
  <div class="scheduler-card-view-filter">
    <div class="ui-input-label">Board view:</div>
    <el-select v-model="select" placeholder="Select board view">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="item.disabled"
      />
    </el-select>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  props: ['value'],
  computed: {
    options() {
      return [
        {
          label: 'Regular',
          value: 0
        },
        {
          label: 'Expanded',
          value: 1
        },
        {
          label: 'Full',
          value: 2
        },
        {
          label: 'Week view',
          value: 3
        },
        {
          label: 'In-shop view',
          value: 4,
          disabled: this.shops.length > 1
        }
      ]
    },
    shops: get('sch_v3/filters@shops'),
    select: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/SchedulerCardViewFilter';
</style>
