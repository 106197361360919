<template>
  <el-dialog
    center
    append-to-body
    :visible.sync="isVisible"
    width="550px"
  >
    <div v-if="inventoryData" v-loading="isSaving">
      <el-row v-if="isRelease && !loading && purchaseOrderInfo">
        <OriginalPurchaseOrderDetails
          :vendorInfo="vendorInfo"
          :purchaseOrderInfo="purchaseOrderInfo"
          :partData="partData"
          :inventoryData="inventoryData"
        />
      </el-row>
      <el-row>
        <div class="form-group col-md-12 dialog-header-text">
          {{ isRelease ? 'Release back to inventory?' : 'Grab from inventory?' }}
        </div>
      </el-row>
      <span v-if="!isRelease && !loading">
        <el-divider/>
        <el-row class="mb-2 dialog-body-text-bold">Exact Parts Available</el-row>
        <el-radio-group v-model="inventoryTypeToUse" :max="1" style="width: 100%;">
          <el-row class="mb-2">
            <el-radio
              type="checkbox"
              label="available"
              @change="selected(inventoryData.cost, 'available')"
              :disabled="inventoryData && inventoryData.qty === 0 || saving"
            >
              <span :style="inventoryData && inventoryData.qty === 0 ? 'color: #ff9999;' : ''">
                <span v-if="inventoryData && inventoryData.qty === 0">No</span>
                Available Inventory ({{ inventoryData ? inventoryData.qty : 0 }}/${{ inventoryData.cost }})
              </span>
            </el-radio>
          </el-row>
          <span v-for="(unit, index) in inventoryData.unreceivedAvailable" :key="index">
            <el-row>
              <el-radio
                type="checkbox"
                :label="'pop-' + unit.purchase_order_part_id"
                :disabled="saving"
                @change="selected(unit.actual_cost, 'pop-' + unit.purchase_order_part_id)"
              >
                Open PO: {{ unit.purchase_order.internal_id }} ({{ unit.qty }}
                <span style="color: rgb(224, 114, 212);">Unreceived</span>
                Parts/${{ unit.actual_cost }})
              </el-radio>
            </el-row>
          </span>
        </el-radio-group>
        <!-- <span v-if="inventoryData.subsAvailable && inventoryData.subsAvailable.length > 0"> -->
        <el-divider />
        <el-row class="mb-2 dialog-body-text-bold">Substitutions Available</el-row>
        <!-- </span> -->
        <span v-if="inventoryData.subsAvailable && inventoryData.subsAvailable.length > 0">
          <span v-for="(sub, index) in inventoryData.subsAvailable" :key="'sub-' + sub.id + index">
            <el-row :class="index > 0 ? 'mt-2' : ''">
              <span style="font-style: italic;">
                {{ sub.number }} : {{ sub.description }} ({{ sub.totalQty }})
              </span>
            </el-row>
            <el-radio-group v-model="inventoryTypeToUse" :max="1" style="width: 100%;">
              <el-row v-if="sub.qty && sub.qty > 0">
                <el-radio
                  type="checkbox"
                  :label="'sub-' + sub.number + '-available'"
                  :disabled="saving"
                  @change="selected(sub.cost, 'sub-' + sub.number + '-available')"
                >
                  Available Inventory ({{ sub ? sub.qty : 0 }}/${{ sub.cost }})
                </el-radio>
              </el-row>
              <span v-for="(unSub, index2) in sub.unreceivedAvailable" :key="'unsub-' + unSub.id + index2">
                <el-row>
                  <el-radio
                    type="checkbox"
                    :disabled="saving"
                    :label="'subpop-' + unSub.purchase_order_part_id + '-' + sub.number"
                    @change="selected(unSub.actual_cost, 'subpop-' + unSub.purchase_order_part_id + '-' + sub.number)"
                  >
                    Open PO: {{ unSub.purchase_order.internal_id }} ({{ unSub.qty }}
                    <span style="color: rgb(224, 114, 212);">Unreceived</span>
                    Parts/${{ unSub.actual_cost }})
                  </el-radio>
                </el-row>
              </span>
            </el-radio-group>
          </span>
        </span>
        <span v-else>
          <el-row class="mb-2">
            <el-radio
              type="checkbox"
              label="available"
              disabled
            >
              <span style="color: #ff9999;">No Available Inventory (0/$0.00)</span>
            </el-radio>
          </el-row>
        </span>
      </span>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="info" plain @click="addPart" v-if="processedPart" :disabled="isSaving">
        Add part unsourced
      </el-button>
      <el-button type="info" plain @click="cancel">
        Cancel
      </el-button>
      <el-button type="primary" @click="okay" :disabled="isSaving">
        {{ isRelease ? 'Release' : 'Grab' }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getPODataForPart } from '@/scripts/helpers/inventory.helpers'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import OriginalPurchaseOrderDetails from '@/components/WarehousePage/OriginalPurchaseOrderDetails.vue'
export default {
  props: ['inventoryData', 'alreadySaved', 'partData', 'visible', 'inventoryDialogType', 'saving', 'isInventoryDialogFromNagsSearch', 'processedPart'],
  data: function () {
    return {
      loading: false,
      purchaseOrderInfo: null,
      vendorInfo: null,
      purchaseOrders: [],
      inventoryTypeToUse: 'available',
      selectedCost: 0
    }
  },
  methods: {
    okay () {
      this.$emit(
        this.isRelease ? 'releasePartInventory' : 'takePartFromInventory',
        {
          inventoryData: this.inventoryData,
          partData: (this.inventoryData.retailAdd ? this.processedPart[0] : this.partData),
          typeToUse: this.inventoryTypeToUse,
          selectedCost: this.selectedCost
        }
      )
    },
    cancel () {
      this.isVisible = false
    },
    selected (val, typeToUse) {
      var self = this
      this.$nextTick(() => {
        if (typeToUse === self.inventoryTypeToUse) {
          self.selectedCost = val
        }
      })
    },
    async addPart () {
      this.isSaving = true
      await this.$job.addParts(this.processedPart)
      this.isSaving = false
      this.isVisible = false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    isRelease () {
      return this.inventoryDialogType && this.inventoryDialogType === 'release'
    },
    vendorColor () {
      if (this.vendorInfo && this.vendorInfo.color) {
        return this.vendorInfo.color
      }
      return null
    },
    isSaving: {
      get () {
        return this.saving
      },
      set (val) {
        this.$emit('update:saving', val)
      }
    }
  },
  watch: {
    isVisible: function (val) {
      if (val) {
        if (this.inventoryData) {
          this.selectedCost = this.inventoryData.cost
        }
        this.loading = false
        this.purchaseOrders = []
        this.inventoryTypeToUse = 'available'
        this.isSaving = false
        if (!this.isRelease && this.inventoryData && this.inventoryData.qty === 0 && this.inventoryData.unreceivedAvailable.length > 0) {
          this.inventoryTypeToUse = 'pop-' + this.inventoryData.unreceivedAvailable[0].purchase_order_part_id
        }
        if (this.isRelease && this.partData && this.partData._item && this.partData._item.units) {
          // get po
          this.loading = true
          let foundPoId = null
          for (var i = 0; i < this.partData._item.units.length; i++) {
            const tempUnit = this.partData._item.units[i]
            if (tempUnit.job_part_id === this.partData.id) {
              foundPoId = tempUnit.purchase_order_id
            }
          }
          if (!foundPoId) {
            this.purchaseOrderInfo = null
            return
          }
          var self = this
          getPODataForPart(this.partData._job.shop_id, foundPoId, res => {
            self.loading = false
            self.purchaseOrderInfo = res.purchaseOrder
            self.vendorInfo = Object.assign({}, res.vendor)
          })
        } else if (this.isRelease && this.partData && this.partData.item && this.partData.item.units) {
          this.loading = true
          let foundPoId = null
          for (var i = 0; i < this.partData.item.units.length; i++) {
            const tempUnit = this.partData.item.units[i]
            if (tempUnit.job_part_id === parseInt(this.partData.id)) {
              foundPoId = tempUnit.purchase_order_id
            }
          }
          if (!foundPoId) {
            this.purchaseOrderInfo = null
            return
          }
          var self = this
          getPODataForPart(this.partData.shopId, foundPoId, res => {
            self.loading = false
            self.purchaseOrderInfo = res.purchaseOrder
            self.vendorInfo = Object.assign({}, res.vendor)
          })
        }
      }
    }
  },
  components: {
    OriginalPurchaseOrderDetails
  },
  mixins: [UtilityMixin]
}
</script>
