var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { mobileVersion: _vm.isMobile } }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("strong", [_vm._v("File Name: ")]),
        _vm._v(" " + _vm._s(_vm.fax ? _vm.fax.filename : "") + " ")
      ]),
      _c("div", { staticClass: "col" }, [
        _c(
          "a",
          {
            staticClass: "float-right",
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.$emit("close-attach")
              }
            }
          },
          [
            _c("font-awesome-icon", {
              staticStyle: { color: "red" },
              attrs: { icon: _vm.faTimesCircle }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("strong", [_vm._v("Date/Time: ")]),
        _vm._v(" " + _vm._s(_vm.fax ? _vm.fax.dateTime : "") + " ")
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "form-group col-6" },
        [
          _c("label", [_vm._v("Job")]),
          _c("Autocomplete", {
            attrs: {
              startingPosition: -1,
              smallInput: true,
              dataSet: _vm.jobArray,
              searchValue: _vm.jobSearchValue,
              placeholder: "Search For Job...",
              inLoading: _vm.loadingJobs,
              showSpinner: true,
              autoCompleteHeight: 180
            },
            on: {
              selected: _vm.selectAutocompletePart,
              "update:searchValue": function($event) {
                _vm.jobSearchValue = $event
              },
              "update:search-value": function($event) {
                _vm.jobSearchValue = $event
              },
              deleteKeyPressed: _vm.handleAutoCompleteDelete
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "form-group col-6" }, [
        _c("label", [_vm._v("Type")]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedType,
                expression: "selectedType"
              }
            ],
            staticClass: "form-control form-control-sm",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedType = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("...")]),
            _c("option", { attrs: { value: "swo" } }, [_vm._v("SWO")]),
            _c("option", { attrs: { value: "dis" } }, [_vm._v("DIS")]),
            _c("option", { attrs: { value: "pop" } }, [_vm._v("PoP")]),
            _c("option", { attrs: { value: "fiv" } }, [_vm._v("FIV")]),
            _c("option", { attrs: { value: "quo" } }, [_vm._v("QUO")]),
            _c("option", { attrs: { value: "oth" } }, [_vm._v("OTH")])
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "float-right mt-1" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button", size: "lg", variant: "primary" },
          on: { click: _vm.attachButtonPressed }
        },
        [_vm._v("Attach")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }