// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".primary-fields-selector {\n  border-top: none;\n  border-bottom: none;\n}\n.primary-fields-selector .el-collapse-item__header {\n  background: none;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: reverse;\n      -ms-flex-direction: row-reverse;\n          flex-direction: row-reverse;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n}\n.primary-fields-selector .el-collapse-item__header .el-collapse-item__arrow {\n  margin-left: 0;\n}\n.primary-fields-selector .el-collapse-item__wrap {\n  background: none;\n}", "",{"version":3,"sources":["/app/src/components/Helpers/PrimaryFieldsSelector.vue"],"names":[],"mappings":"AAuLA;EACE,gBAAA;EACA,mBAAA;AA3KF;AA4KE;EACE,gBAAA;EACA,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,8BAAA;EAAA,8BAAA;MAAA,+BAAA;UAAA,2BAAA;EACA,qBAAA;MAAA,kBAAA;UAAA,yBAAA;AA1KJ;AA2KI;EACE,cAAA;AAzKN;AA4KE;EACE,gBAAA;AA1KJ","file":"PrimaryFieldsSelector.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.primary-fields-selector {\n  border-top: none;\n  border-bottom: none;\n}\n.primary-fields-selector .el-collapse-item__header {\n  background: none;\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: flex-end;\n}\n.primary-fields-selector .el-collapse-item__header .el-collapse-item__arrow {\n  margin-left: 0;\n}\n.primary-fields-selector .el-collapse-item__wrap {\n  background: none;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
