<template>
  <div>
    <span>{{ truncateString(Name) }}</span>
  </div>
</template>

<script>
export default {
  props: [
    'Name'
  ],
  methods: {
    truncateString (name) {
      let formattedName
      if (name.length > 20) {
        formattedName = name.substring(0, 20) + '...'
      } else {
        formattedName = name
      }
      return formattedName
    }
  }
}
</script>
