var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "scheduler-generic-card",
      style: _vm.cardStyle,
      on: { click: _vm.openDetails }
    },
    [
      _vm.icons[_vm.item.type]
        ? _c("job-card-info-block", {
            attrs: {
              text: _vm.title,
              icon: _vm.icons[_vm.item.type],
              solid: _vm.solidCards,
              strong: ""
            }
          })
        : _vm._e(),
      _c("scheduler-times-row", {
        attrs: { item: _vm.item, solid: !_vm.solidCards }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }