var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("scheduler-job-card-list", {
    directives: [
      {
        name: "custom-infinite-scroll",
        rawName: "v-custom-infinite-scroll",
        value: {
          direction: _vm.isRowView ? "horizontal" : "vertical",
          loadMore: _vm.showMoreCards,
          target: ".job-card-list-container"
        },
        expression:
          "{\n    direction: isRowView ? 'horizontal' : 'vertical',\n    loadMore: showMoreCards,\n    target: '.job-card-list-container'\n  }"
      }
    ],
    attrs: { title: _vm.leftListFilterTitle, list: _vm.list, minHeight: true },
    on: { input: _vm.onDataChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }