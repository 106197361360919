<template>
  <el-dialog
    center
    append-to-body
    :close-on-click-modal="false"
    title="GB Form templates"
    :visible.sync="visible"
    :before-close="handleClose"
    custom-class="gb-form-template-dialog">
    <div class="row mb-3">
      <div class="col-12">
        <el-button
          type="primary"
          size="mini"
          class="pull-right"
          icon="el-icon-plus"
          @click="openForm()">Create</el-button>
      </div>
    </div>
    <el-table
      border
      :data="templates">
      <el-table-column
        prop="name"
        label="Name">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="Actions"
        width="120">
        <template slot-scope="scope">
          <el-button
            class="mb-2 mr-2"
            size="mini"
            icon="el-icon-edit"
            @click="openForm(scope.row)"/>
          <el-button
            class="ml-0"
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"/>
        </template>
      </el-table-column>
    </el-table>
    <div class="row">
      <div class="col-md-12">
        <el-button
          class="pull-right mt-3"
          plain
          @click="handleClose">
          Close
        </el-button>
      </div>
    </div>
    <Form
      :template="template"
      :visible="formVisible"
      @close="closeForm"
    />
  </el-dialog>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import Form from './Form'

export default {
  data: () => ({
    template: null,
    templates: [],
    formVisible: false
  }),
  computed: {
    visible: sync('twilio/textMessages/GbFormTemplates/visible'),
    referral: get('twilio/textMessages/GbFormTemplates/referral'),
    gbFormDialog: sync('jobDetails/actionDialogs@gbFormDialog')
  },
  watch: {
    visible: async function (value) {
      if (value) {
        this.getTemplates()
      }
    }
  },
  methods: {
    async getTemplates () {
      let { data } = await this.$unum.job().get('gbForm/templates', { params: {
        shopId: this.$job?.shop?.id
      }})
      this.templates = data
      if (this.referral === 'add-new-template') {
        this.openForm()
        this.setTextMessageTemplatesReferral('job-email')
      }
    },
    openForm (template = null) {
      this.template = template
      this.formVisible = true
      this.visible = false
    },
    closeForm () {
      this.formVisible = false
      this.visible = true
    },
    async handleDelete (template) {
      let self = this
      try {
        await self.$messageBox.confirm(
          `This will permanently delete the template "${template.name}". Do you wish to continue?`,
          'Warning',
          {
            beforeClose: async (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                await this.$unum.job().delete(`gbForm/template/${template.id}`)
                await self.getTemplates()
                self.$message.success({
                  message: 'Template successfully deleted.',
                  type: 'success'
                })
                instance.confirmButtonLoading = false
              }
              done()
            }
          }
        )
      } catch {}
    },
    openTextMessageConfirmationDialog: call('twilio/textMessages/jobConfirmation/open'),
    openTextMessageInvoiceDialog: call('twilio/textMessages/jobInvoice/open'),
    openTextMessagePaymentDialog: call('twilio/textMessages/jobPayment/open'),
    openTextMessageSignatureDialog: call('twilio/textMessages/jobSignature/open'),
    setTextMessageTemplatesReferral: call('twilio/textMessages/GbFormTemplates/setReferral'),
    setSelectTemplateDialogVisible: call('twilio/textMessages/GbFormTemplates/setSelectTemplateDialogVisible'),
    handleClose () {
      this.visible = false
      if (this.referral === 'gb-forms') {
        this.gbFormDialog = true
      }
      this.setTextMessageTemplatesReferral(null)
    }
  },
  components: {
    Form
  }
}
</script>

<style>
  .gb-form-template-dialog {
    @apply t-font-sans;
    min-width: 500px;
  }
</style>
