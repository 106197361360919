var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-w-64 t-bg-gray-100 t-shadow t-flex-shrink-0 t-overflow-y-auto t-pb-10"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "t-flex t-h-8 t-mt-6 t-pl-6 t-items-center t-cursor-pointer",
          class: [
            { "t-bg-blue-500 t-text-blue-100": _vm.isListView },
            { "hover:t-bg-gray-300": !_vm.isListView }
          ],
          on: { click: _vm.browseChannels }
        },
        [
          _c("i", { staticClass: "el-icon-thumb t-text-base" }),
          _c("div", { staticClass: "t-ml-3" }, [_vm._v("Browse channels")])
        ]
      ),
      _c("UserChannelsList"),
      _c("DirectChannelsList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }