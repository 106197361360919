<template>
  <gb-card title="My Tickets">
    <template style='margin-left: 10px' slot='title' v-if='$role.can("account_manager")'>
      <el-checkbox style='margin-left: 20px;' v-model="showShopHistory">All for Shop</el-checkbox>
    </template>
    <ticket-table :data="data" :loading="loading"/>
  </gb-card>
</template>

<script>
import moment from 'moment'
import { call } from 'vuex-pathify'
import TicketTable from './TicketTable.vue'

export default {
  props: ['tab'],
  components: {
    TicketTable
  },
  data() {
    return {
      showShopHistory: false,
      data: [],
      loading: false
    }
  },
  methods: {
    async getHistory() {
      this.loading = true
      const {data} = await this.$unum.support().get(`/ticket/history?onlyOpen=false&onlyUser=${!this.showShopHistory}`)
      this.data = data.rows
      this.loading = false
    },
  },
  watch: {
    tab(v) {
      if (v === 'history') {
        this.getHistory()
      }
    },
    showShopHistory() {
      this.getHistory()
    }
  }
}
</script>

<style>

</style>