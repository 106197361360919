import gql from 'graphql-tag'

export const TAG_FRAGMENT = gql`
  fragment TagFields on Tag {
    id
    text
    color
    details
    status
    default_tag
  }
`

export const JOBTAG_FRAGMENT = gql`
  fragment JobtagFields on Jobtag {
    id
    shop_tag_id
    tag {
      ...TagFields
    }
  }
  ${TAG_FRAGMENT}
`
