import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class RoleUser extends ObjectTracker {
  id = -1
  role_id = -1
  user_id = -1

  name = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getUserRoles (userId, callbackFunction) {
    Api({ url: `/api/user/${userId}/userroles` }).then(res => {
      var roles = []
      if (res.data) {
        for (var i = 0; i < res.data.length; i++) {
          roles.push(new RoleUser(res.data[i]))
        }
      }
      callbackFunction(roles)
    })
  }

  static getUserRolesDefault (userId, roles, callbackFunction) {
    var response = {}
    let tempKeys = Object.keys(roles)
    for (var i = 0; i < tempKeys.length; i++) {
      response[tempKeys[i]] = new RoleUser({role_id: roles[tempKeys[i]].id})
    }

    callbackFunction(response)
  }
}
