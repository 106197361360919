<template>
  <el-dialog
    center
    append-to-body
    :close-on-click-modal="false"
    :title="template === null ? 'Create a template' : 'Update a template'"
    :visible="visible"
    :before-close="handleClose"
    :width="form.type_id === 8 ? '45%' : '70%'"
    class="edit-text-confirmation-dialog">
    <el-row>
      <el-col v-if="form.type_id !== 8" :span="8">
         <el-tabs tab-position="left">
          <el-tab-pane label="CUSTOMER">
            <p>Company name: <span class="text-message-template-variable" @click="copyVariable">{customer company name}</span></p>
            <p>First: <span class="text-message-template-variable" @click="copyVariable">{customer first}</span></p>
            <p>Last: <span class="text-message-template-variable" @click="copyVariable">{customer last}</span></p>
            <p>Phone: <span class="text-message-template-variable" @click="copyVariable">{customer phone}</span></p>
            <p>Email: <span class="text-message-template-variable" @click="copyVariable">{customer email}</span></p>
            <p>Address: <span class="text-message-template-variable" @click="copyVariable">{customer address}</span></p>
          </el-tab-pane>
          <el-tab-pane label="BILL-TO">
            <p>Company name: <span class="text-message-template-variable" @click="copyVariable">{bill-to company name}</span></p>
            <p>First: <span class="text-message-template-variable" @click="copyVariable">{bill-to first}</span></p>
            <p>Last: <span class="text-message-template-variable" @click="copyVariable">{bill-to last}</span></p>
            <p>Phone: <span class="text-message-template-variable" @click="copyVariable">{bill-to phone}</span></p>
            <p>Email: <span class="text-message-template-variable" @click="copyVariable">{bill-to email}</span></p>
            <p>Address: <span class="text-message-template-variable" @click="copyVariable">{bill-to address}</span></p>
          </el-tab-pane>
          <el-tab-pane label="SCHEDULE">
            <p>Date: <span class="text-message-template-variable" @click="copyVariable">{schedule date}</span></p>
            <p>Day: <span class="text-message-template-variable" @click="copyVariable">{schedule day}</span></p>
            <p>Start time: <span class="text-message-template-variable" @click="copyVariable">{schedule start time}</span></p>
            <p>End time: <span class="text-message-template-variable" @click="copyVariable">{schedule end time}</span></p>
            <p>Phone: <span class="text-message-template-variable" @click="copyVariable">{schedule contact phone}</span></p>
            <p>Contact name: <span class="text-message-template-variable" @click="copyVariable">{schedule contact name}</span></p>
            <p>Address: <span class="text-message-template-variable" @click="copyVariable">{schedule customer address}</span></p>
          </el-tab-pane>
          <el-tab-pane label="BILLING INFO">
            <p>Job number: <span class="text-message-template-variable" @click="copyVariable">{job-id #}</span></p>
            <p>Referral: <span class="text-message-template-variable" @click="copyVariable">{referral #}</span></p>
            <p>Policy number: <span class="text-message-template-variable" @click="copyVariable">{policy #}</span></p>
            <p>Loss date: <span class="text-message-template-variable" @click="copyVariable">{loss date}</span></p>
            <p>Dispatch date: <span class="text-message-template-variable" @click="copyVariable">{dispatch date}</span></p>
            <p>Install date: <span class="text-message-template-variable" @click="copyVariable">{install date}</span></p>
            <p>Deductible: <span class="text-message-template-variable" @click="copyVariable">{deductible $}</span></p>
            <p>PO number: <span class="text-message-template-variable" @click="copyVariable">{PO #}</span></p>
            <p>RO number: <span class="text-message-template-variable" @click="copyVariable">{RO #}</span></p>
            <p>DOT number: <span class="text-message-template-variable" @click="copyVariable">{DOT #}</span></p>
            <p>Lot number: <span class="text-message-template-variable" @click="copyVariable">{lot #}</span></p>
            <p>Stock number: <span class="text-message-template-variable" @click="copyVariable">{stock #}</span></p>
          </el-tab-pane>
          <el-tab-pane label="VEHICLE">
            <p>Year: <span class="text-message-template-variable" @click="copyVariable">{veh year}</span></p>
            <p>Make: <span class="text-message-template-variable" @click="copyVariable">{veh make}</span></p>
            <p>Model: <span class="text-message-template-variable" @click="copyVariable">{veh model}</span></p>
            <p>Style: <span class="text-message-template-variable" @click="copyVariable">{veh style}</span></p>
            <p>Unit number: <span class="text-message-template-variable" @click="copyVariable">{veh unit #}</span></p>
            <p>Plate number: <span class="text-message-template-variable" @click="copyVariable">{veh plate #}</span></p>
            <p>VIN number: <span class="text-message-template-variable" @click="copyVariable">{veh vin #}</span></p>
            <p>License plate: <span class="text-message-template-variable" @click="copyVariable">{veh license plate}</span></p>
            <p>Mileage: <span class="text-message-template-variable" @click="copyVariable">{veh mileage}</span></p>
          </el-tab-pane>
          <el-tab-pane label="TOTALS">
            <p>Invoice total: <span class="text-message-template-variable" @click="copyVariable">{job total $}</span></p>
            <p>Balance due customer: <span class="text-message-template-variable" @click="copyVariable">{customer balance $}</span></p>
            <p>Balance due bill to: <span class="text-message-template-variable" @click="copyVariable">{bill to balance $}</span></p>
          </el-tab-pane>
          <el-tab-pane label="VENDORS">
            <p>Csr name: <span class="text-message-template-variable" @click="copyVariable">{csr name}</span></p>
            <p>Sale source name: <span class="text-message-template-variable" @click="copyVariable">{sale source name}</span></p>
            <p>Sales rep name: <span class="text-message-template-variable" @click="copyVariable">{sales rep name}</span></p>
            <p>Tech name: <span class="text-message-template-variable" @click="copyVariable">{tech name}</span></p>
            <p>Location name: <span class="text-message-template-variable" @click="copyVariable">{location name}</span></p>
          </el-tab-pane>
          <el-tab-pane label="SHOP">
            <p>Name: <span class="text-message-template-variable" @click="copyVariable">{shop name}</span></p>
            <p>Email: <span class="text-message-template-variable" @click="copyVariable">{shop email}</span></p>
            <p>Address: <span class="text-message-template-variable" @click="copyVariable">{shop address}</span></p>
            <p>Address city: <span class="text-message-template-variable" @click="copyVariable">{shop address city}</span></p>
            <p>Address state: <span class="text-message-template-variable" @click="copyVariable">{shop address state}</span></p>
            <p>Address ZIP: <span class="text-message-template-variable" @click="copyVariable">{shop address zip}</span></p>
            <p>Full address: <span class="text-message-template-variable" @click="copyVariable">{shop full address}</span></p>
            <p>Logo: <span class="text-message-template-variable" @click="copyVariable">{shop logo}</span></p>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="form.type_id === 8 ? 24 : 16">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :hide-required-asterisk="true"
          label-width="140px"
          size="medium">
          <el-form-item prop="name">
            <span slot="label" class="undo-label-styles">Name</span>
            <el-input
              placeholder="Enter a name"
              v-model="form.name"
              @focus="focusTextarea(false)"
            />
          </el-form-item>
          <el-form-item prop="type_id">
            <span slot="label" class="undo-label-styles">Type</span>
            <el-select v-model="form.type_id" placeholder="Select a type" style="width: 100%;" @focus="focusTextarea(false)">
              <el-option
                v-for="type in types"
                :key="type.id"
                :label="type.name"
                :value="type.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="shop_id">
            <span slot="label" class="undo-label-styles">Shop</span>
            <el-select v-model="form.shop_id" filterable placeholder="Select a shop" style="width: 100%;" @focus="focusTextarea(false)">
              <el-option
                v-for="shop in shops"
                :key="shop.id"
                :label="shop.name"
                :value="shop.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="subject">
            <span slot="label" class="undo-label-styles">Subject</span>
            <el-input
              :key="forceUpdate"
              ref='templateSubject'
              placeholder="Enter a subject"
              v-model="form.subject"
              @focus="focusSubject(true)"
            />
          </el-form-item>
          <!-- <el-form-item v-if="form.type_id === 1 || form.type_id === 2" style="word-break: break-word;">
            <p>Use the following variables in the template body and they will automatically
              be replaced with the proper job information at the sending time<br>( click to copy to clipboard ):</p>
            <div v-if="form.type_id === 1">
              Customer's name: <span class="text-message-template-variable" @click="copyVariable">{name}</span><br/>
              Customer's address: <span class="text-message-template-variable" @click="copyVariable">{address}</span><br/>
              Appointment date: <span class="text-message-template-variable" @click="copyVariable">{date}</span><br/>
              Appointment window start time: <span class="text-message-template-variable" @click="copyVariable">{from}</span><br/>
              Appointment window end time: <span class="text-message-template-variable" @click="copyVariable">{to}</span><br/>
              Approximate arrival time (based on auto routing): <span class="text-message-template-variable" @click="copyVariable">{aat}</span><br/>
              Approximate job duration (based on auto routing): <span class="text-message-template-variable" @click="copyVariable">{ajd}</span>
            </div>
            <div v-if="form.type_id === 2">
              Name: <span class="text-message-template-variable" @click="copyVariable">{name}</span><br/>
            </div>
          </el-form-item> -->
          <el-form-item prop="body">
            <span slot="label" class="undo-label-styles">Template body</span>
            <vue-editor
              ref='templateTextarea'
              placeholder="Enter a template body"
              v-model="form.body"
              :editorToolbar="editorToolbar"
              @selection-change="onBodySelectionChange"
              @focus="onBodyFocus"
            />
          </el-form-item>
          <el-form-item prop="default">
            <el-checkbox
              border
              v-model="form.default"
              label="Make default for the shop"
              style="text-transform: none"
              @focus="focusTextarea(false)"/>
          </el-form-item>
          <el-form-item v-if="form.type_id === 6" style="word-break: break-word;">
            <p class="mt-3"><span style="color: #F56C6C;">NOTE:</span> that text "Please reply YES to confirm or reply NO to
              change" will be automatically added in the end of the message.</p>
          </el-form-item>
          <el-form-item>
            <el-button
              class="mb-2 mr-2"
              type="primary"
              @click="handleSubmit"
              :loading="loading">
              {{ template === null ? 'Create' : 'Update' }}
            </el-button>
            <el-button
              class="ml-0"
              plain
              @click="handleClose">
              Close
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import TextMessageTemplate from '@/scripts/objects/text_message_template'
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props: ['visible', 'template'],
  data: () => ({
    form: {
      name: '',
      type_id: null,
      shop_id: null,
      body: '',
      subject: '',
      default: false,
      textAreaFocus: false,
      subjectFocus: false
    },
    rules: {
      name: { required: true, message: 'Please enter a name' },
      type_id: { required: true, message: 'Please select a type' },
      shop_id: { required: true, message: 'Please select a shop' },
      body: { required: true, message: 'Please enter a template body' }
    },
    editorToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ['link'],
      ['clean'] // remove formatting button
    ],
    templateBodySelection: {
      index: 0,
      length: 0
    },
    quill: null,
    types: [],
    loading: false,
    forceUpdate: 0
  }),
  computed: {
    shops () {
      return this.$store.state.shops
    }
  },
  watch: {
    visible: async function (value) {
      if (value) {
        this.types = await TextMessageTemplate.getTypes('email')
        if (this.template !== null) {
          this.form.name = this.template.name
          this.form.type_id = this.template.type_id
          this.form.shop_id = this.template.shop_id
          this.form.body = this.template.body
          this.form.subject = this.template.subject
          this.form.default = this.template.default === 1
        }
      }
    }
  },
  methods: {
    clearForm () {
      this.form = {
        name: '',
        type_id: null,
        shop_id: null,
        body: '',
        subject: '',
        default: false,
        textAreaFocus: false,
        subjectFocus: false
      }
    },
    async handleClose () {
      this.$emit('close')
      await this.clearForm()
      this.$refs.form.clearValidate()
    },
    async handleSubmit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          if (this.template === null) {
            await TextMessageTemplate.store(this.form)
          } else {
            await TextMessageTemplate.update(this.template.id, this.form)
          }
          this.handleClose()
          this.loading = false
          this.$message.success(this.template === null ? 'Template successfully created.' : 'Template successfully updated.')
          this.$root.$emit('email-dialog-refresh-templates')
        }
      } catch (error) {
        this.loading = false
      }
    },
    copyVariable (event) {
      this.$copyText(event.target.innerText)
      if (this.textAreaFocus) {
        if (this.quill) {
          this.quill.insertText(this.templateBodySelection.index, `${event.target.innerText} `, 'color', '#409eff')
          this.quill.setSelection({...this.templateBodySelection, index: this.templateBodySelection.index + event.target.innerText.length})
        } else {
          this.form.body += event.target.innerText
        }
      } else if (this.subjectFocus) {
        const subjectArea = this.$refs.templateSubject.$refs.input
        const tempText = subjectArea.value
        const cursorPos = subjectArea.selectionStart
        this.form.subject = tempText.substring(0, subjectArea.selectionStart) + event.target.innerText + tempText.substring(subjectArea.selectionEnd, tempText.length);
        this.forceUpdate++
        setTimeout(() => {
          this.$refs.templateSubject.focus()
          // cursorPos += insert.length;
          subjectArea.selectionStart = subjectArea.selectionEnd = (cursorPos + event.target.innerText.length)
        }, 10)
      } else {
        this.$message.info('Copied to clipboard')
      }
    },
    focusTextarea () {
      this.textAreaFocus = true
      this.subjectFocus = false
    },
    focusSubject(val) {
      this.textAreaFocus = false
      this.subjectFocus = val
    },
    onBodySelectionChange(range) {
      if (range) {
        this.templateBodySelection = range
      }
    },
    onBodyFocus(quill) {
      this.quill = quill
      this.focusTextarea(true)
    }
  }
}
</script>

<style lang="scss">
  .edit-text-confirmation-dialog {
    @apply t-font-sans;
  }

  .text-message-template-variable {
    color: #409EFF;
    cursor: pointer;
    margin-left: 10px;
  }
</style>
