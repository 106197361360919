var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-width": "180px", size: "mini" } },
    [
      _c("div", { staticClass: "t-flex" }, [
        _c(
          "div",
          { staticClass: "t-flex-grow" },
          [
            _c("el-form-item", [
              _c("div", { staticClass: "h6 mb-0" }, [
                _vm._v(" Last search request: ")
              ])
            ]),
            _c("details-item", {
              attrs: { name: "User", value: _vm.details.username }
            }),
            _c("details-item", {
              attrs: { name: "Request time", value: _vm.formattedTimestamp }
            }),
            _c("details-item", {
              attrs: { name: "VIN", value: _vm.details.vin }
            }),
            _vm.details.status != "success"
              ? _c(
                  "div",
                  [
                    _c("details-item", {
                      attrs: { name: "Message", value: "VIN number is invalid" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticStyle: { "word-break": "break-word" } }, [
              _vm.details.help_request_status === "sent"
                ? _c(
                    "div",
                    [
                      _c("details-item", {
                        attrs: {
                          name: "Message",
                          value:
                            "Help request was sent. Please give us some time to process\n              it and we'll get back to you right away."
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.details.result
                ? _c(
                    "div",
                    [
                      _c("details-item", {
                        attrs: { name: "Year", value: _vm.details.result.year }
                      }),
                      _c("details-item", {
                        attrs: { name: "Make", value: _vm.details.result.make }
                      }),
                      _c("details-item", {
                        attrs: {
                          name: "Model",
                          value: _vm.details.result.model
                        }
                      }),
                      _c("details-item", {
                        attrs: { name: "Style", value: _vm.details.result.body }
                      }),
                      _c("details-item", {
                        attrs: { name: "Glass type", value: "Windshields" }
                      }),
                      _vm._l(_vm.glass, function(g) {
                        return [
                          _c("el-divider", { staticClass: "t-flex-grow" }),
                          _c("details-item-tag", {
                            attrs: {
                              name: "After Market Part number",
                              values: [g.partNumber.slice(0, -1)],
                              color: "#fff",
                              background: "#337ab7"
                            }
                          }),
                          g.calibrations.length
                            ? _c(
                                "el-form-item",
                                { staticClass: "mb-0" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "undo-label-styles right-part-for-label",
                                      attrs: { slot: "label" },
                                      slot: "label"
                                    },
                                    [_vm._v("Calibrations:")]
                                  ),
                                  _vm._l(g.calibrations, function(cal) {
                                    return _c("div", [
                                      _vm._v(" " + _vm._s(cal.sensor)),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "details-recal-tag t-mx-2",
                                          style: {
                                            background: "#805ad5",
                                            color: "#fff"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(cal.calibrationType) + " "
                                          )
                                        ]
                                      )
                                    ])
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _c("details-item-tag", {
                            attrs: {
                              name: "Dealer part numbers",
                              values: g.oemPartNumbers,
                              color: "#fff",
                              background: "#299fd7"
                            }
                          })
                        ]
                      }),
                      _vm.glass.length == 0
                        ? [
                            _c("el-divider", { staticClass: "t-flex-grow" }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "t-flex-grow t-text-center t-italic t-font-bold"
                              },
                              [_vm._v("No glass results found...")]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ])
          ],
          1
        )
      ]),
      _c("el-divider")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }