var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "scheduler-map-infowindow": true,
        warehouse: !_vm.isJob,
        random: _vm.marker.random
      }
    },
    [
      _c("el-card", { attrs: { "body-style": { padding: "10px" } } }, [
        _vm.isJob
          ? _c(
              "div",
              { staticClass: "info-row" },
              [
                _c("div", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.job.getTitle() || _vm.marker.label))
                  ])
                ]),
                _vm.marker.label
                  ? _c("div", [_vm._v(" custom waypoint ")])
                  : _vm._e(),
                _vm.marker.distance_to_next
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.marker.distance_to_next))]),
                      _vm._v(" to next job ")
                    ])
                  : _vm._e(),
                _vm.marker.distance_to_prev
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.marker.distance_to_prev))]),
                      _vm._v(" from prev. job ")
                    ])
                  : _vm._e(),
                _c("div", [_vm._v(_vm._s(_vm.job.getTime()))]),
                _c("scheduler-times-row", { attrs: { item: _vm.marker } }),
                _vm.marker.random
                  ? _c("div", [_c("span", [_vm._v("No address")])])
                  : _vm._e()
              ],
              1
            )
          : _vm.marker.name
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.warehouse.getTitle()))])
              ]),
              _c("div", [
                _vm._v(" " + _vm._s(_vm.warehouse.getAddressStreet()) + " "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.warehouse.getAddressCityState()) + " ")
              ]),
              _vm.marker.phone
                ? _c("div", [
                    _c("span", [_vm._v("Phone:")]),
                    _vm._v(" " + _vm._s(_vm.warehouse.getPhone()) + " ")
                  ])
                : _vm._e(),
              _vm.marker.fax
                ? _c("div", [
                    _c("span", [_vm._v("Fax:")]),
                    _vm._v(" " + _vm._s(_vm.warehouse.getFax()) + " ")
                  ])
                : _vm._e(),
              _vm.marker.manager
                ? _c("div", [
                    _c("span", [_vm._v("Manager:")]),
                    _vm._v(" " + _vm._s(_vm.warehouse.getManager()) + " ")
                  ])
                : _vm._e()
            ])
          : _c("div", { staticClass: "info-row" }, [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.marker.type.toUpperCase()))])
              ]),
              _c("div", [_vm._v(" " + _vm._s(_vm.marker.details.name) + " ")])
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }