const namespace = 'chat/'

const actions = {
  init: 'init',
  generateToken: 'generateToken',
  open: 'open',
  close: 'close',
  changeView: 'changeView',
  openChannel: 'openChannel',
  setNewMessage: 'setNewMessage',
  setChannelInfoDrawerIsOpen: 'setChannelInfoDrawerIsOpen',
  setMembersDialogIsOpen: 'setMembersDialogIsOpen',
  getUsers: 'getUsers',
  getSubscribedUsers: 'getSubscribedUsers',
  getUserChannels: 'getUserChannels',
  getUnreadMessagesCount: 'getUnreadMessagesCount',
  activateChatService: 'activateChatService',
  getMembers: 'getMembers',
  getTotalMessages: 'getTotalMessages'
}

const mutations = {
  setConnected: 'setConnected',
  setOpen: 'setOpen',
  setView: 'setView',
  setActiveChannel: 'setActiveChannel',
  setChannelIsOpenning: 'setChannelIsOpenning',
  setNewMessage: 'setNewMessage',
  setChannelInfoDrawerIsOpen: 'setChannelInfoDrawerIsOpen',
  setMembersDialogIsOpen: 'setMembersDialogIsOpen',
  setUsers: 'setUsers',
  setSubscribedUsers: 'setSubscribedUsers',
  setUserReachability: 'setUserReachability',
  setUserChannels: 'setUserChannels',
  setUnreadMessagesCountForSingleChannel: 'setUnreadMessagesCountForSingleChannel',
  setUnreadMessagesCountForAllChannels: 'setUnreadMessagesCountForAllChannels',
  setUserChannelsLoading: 'setUserChannelsLoading',
  deleteUserChannel: 'deleteUserChannel',
  setAddUsersDialogIsOpen: 'setAddUsersDialogIsOpen',
  setMembers: 'setMembers',
  setTotalMessage: 'setTotalMessage',
  setMemberTyping: 'setMemberTyping',
  unsetMemberTyping: 'unsetMemberTyping',
  setScrollBodyDown: 'setScrollBodyDown',
  setImagePreviewUrl: 'setImagePreviewUrl'
}

const getters = {
  getTotalUnreadMessages: 'getTotalUnreadMessages',
  getTypingMembers: 'getTypingMembers'
}

export const chatTypes = {
  namespace,
  actions,
  getters,
  mutations
}
