<template>
  <div class="t-flex">
    <div class="t-flex-grow">
      <div class="t-text-gray-400 t-mt-2">Email Address</div>

      <el-form
        :model="form"
        ref="form"
        class="t-space-y-6"
        @submit.prevent.native
      >
        <div
          v-for="(i, index) in form.emails"
          :key="index"
          class="t-flex t-items-center t-space-x-3 first:t-mt-6"
        >
          <el-form-item
            :prop="'emails.' + index + '.email'"
            :rules="{
              type: 'email',
              required: true,
              message: 'Please enter correct email',
              trigger: 'blur'
            }"
          >
            <el-input
              v-model="i.email"
              placeholder="Enter email address"
              @change="setEmail(index, $event)"
            />
          </el-form-item>

          <el-tooltip content="Remove Email" placement="top">
            <i
              class="material-icons-outline md-cancel t-text-base t-text-red-400 t-cursor-pointer"
              @click="deleteEmailHandler(index)"
            />
          </el-tooltip>

          <el-tooltip
            v-if="i.id"
            :content="
              i.id === data.email_id
                ? 'Primary Email'
                : 'Set as Primary Email'
            "
            placement="top"
          >
            <i
              :class="
                i.id === data.email_id
                  ? 'md-radio_button_checked t-text-blue-500'
                  : 'md-radio_button_unchecked t-text-gray-400'
              "
              class="material-icons-outline t-text-base t-cursor-pointer"
              @click="setPrimaryEmailHandler(i.id)"
            />
          </el-tooltip>
        </div>
      </el-form>
    </div>

    <div class="t-flex t-flex-col t-justify-end">
      <el-button
        plain
        icon="el-icon-circle-plus-outline"
        @click="addEmail"
      >
        Add
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  data() {
    return {
      form: {
        emails: []
      }
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data'])
  },

  mounted() {
    this.form.emails = cloneDeep(this.data.emails)
    this.$nextTick(async () => {
      try {
        await this.$refs.form.validate()
      } catch {
      }
    })
  },

  methods: {
    ...mapActions(
      useVendorStore,
      ['createEmail', 'updateEmail', 'deleteEmail', 'setPrimaryEmail']
    ),

    addEmail() {
      this.form.emails.push({
        id: null,
        email: ''
      })
    },

    setEmail(index, email) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const emailId = this.form.emails[index].id
          try {
            if (emailId) {
              await this.updateEmail(emailId, email)
            } else {
              const emailId = await this.createEmail(email)

              this.form.emails[index].id = emailId

              if (this.form.emails.length === 1) {
                await this.setPrimaryEmailHandler(emailId)
              }
            }
          } catch (e) {
            this.$message.error(e.message)
          }
        }
      })
    },

    async deleteEmailHandler(index) {
      const email = this.form.emails[index]
      if (email.id) {
        try {
          await this.deleteEmail(email.id)
        } catch (e) {
          this.$message.error(e.message)
        }
      }

      Vue.delete(this.form.emails, index)
    },

    async setPrimaryEmailHandler(id) {
      try {
        await this.setPrimaryEmail(id)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>
