var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.values
    ? _c("el-form-item", { staticClass: "mb-0" }, [
        _c(
          "span",
          {
            staticClass: "undo-label-styles right-part-for-label",
            attrs: { slot: "label" },
            slot: "label"
          },
          [_vm._v(_vm._s(_vm.name) + ":")]
        ),
        _c(
          "span",
          _vm._l(_vm.values, function(value) {
            return _c(
              "span",
              {
                staticClass: "detail-tag",
                style: {
                  background: _vm.background,
                  color: _vm.color
                }
              },
              [_vm._v(_vm._s(value) + " ")]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }