import cfg from '@/config'

const path = `${cfg.uri}/legacy`
export const legacyBaseUrl = path + '/api/v1'
export const legacyWebBaseUrl = path
export const legacySchedulerBaseUrl = `${legacyBaseUrl}/scheduler`

export const jobShopsUrl = jobId => `${legacyBaseUrl}/jobs/${jobId}/shop`
export const schedulerJobsUrl = `${legacySchedulerBaseUrl}/jobs`
export const schedulerJobUrl = id => `${legacySchedulerBaseUrl}/job/${id}`
export const schedulerUserSettingsUrl = `${legacySchedulerBaseUrl}/userSetting`
export const techsideCompleteLegacyUrl = id => `/pdf/job/pdf/swo/${id}`

export const baseUrl = `${cfg.uri}/api`
export const schedulerUrl = `${baseUrl}/scheduler`
export const schedulerMapUrl = `${baseUrl}/scheduler/map`
export const schedulerWarehousesUrl = `${baseUrl}/warehouse/map`
export const shopTechsUrl = shopId => `${baseUrl}/shop/${shopId}/tech/`
export const techUrl = (shopId, id) => `${baseUrl}/shop/${shopId}/tech/${id}`
export const schedulerWaypointUrl = `${baseUrl}/scheduler/waypoints`
export const schedulerJobDetailsUrl = id => `${baseUrl}/scheduler/job/${id}`
export const schedulerCompletedCountUrl = `${baseUrl}/scheduler/count`
export const schedulerAppointmentUrl = `${schedulerUrl}/appointment`

export const reportsSalesDataUrl = (group, type) =>
  `${baseUrl}/reporting/${group}/${type}`
export const reportsRightPartDataUrl = () =>
  `api/right-parts/report`
export const reportsShopDataUrl = (type) =>
  `${baseUrl}/reporting/shop_data/${type}`
export const reportPrintToPdfUrl = `${baseUrl}/reporting/print`

export const jobUrl = `${baseUrl}/job`
export const jobLocationUrl = id => `${jobUrl}/location/${id}`

export const baseTechsideActivityEndpoint = `/api/ta`
export const techActivityEndpoint = `${baseTechsideActivityEndpoint}`
export const userSettingsEndpoint = `${baseUrl}/user/settings`
export const schedulerDataEndpoint = `${schedulerUrl}/data`
export const schedulerUnscheduledJobsEndpoint = `${schedulerDataEndpoint}/unscheduled`
export const schedulerJobDetailsV2Endpoint = id =>
  `${schedulerUrl}/job/v2/${id}`
export const schedulerJobUpdateAddressEndpoint = id =>
  `${schedulerUrl}/job/v2/address/${id}`
export const schedulerTechTotalsUrl = `${schedulerUrl}/totals`
// export const reportsInOutUrl = `${baseUrl}/reporting/in-out`
export const reportsAgingUrl = `${baseUrl}/reporting/aging`
// export const reportsAgingUrl = (consumer = false) =>
//   `${baseUrl}/reporting/aging/${consumer}`

export const reportsProfitAndLossUrl = `${baseUrl}/reporting/profit-and-loss`
export const reportsBalanceSheetUrl = `${baseUrl}/reporting/balance-sheet`
export const reportsNagsLicenseUrl = `${baseUrl}/reporting/nags-license`
export const reportsCustomerSummaryUrl = `${baseUrl}/reporting/customer-summary`
export const reportsEdiUrl = `${baseUrl}/reporting/edi`
export const reportsInstallListUrl = `${baseUrl}/reporting/install-list`

export const schedulerDataV3Endpoint = `${schedulerUrl}/data/v3`
export const schedulerJobListEndpoint = `${schedulerUrl}/joblist`
export const schedulerBaysEndpoint = `${schedulerUrl}/bays`
export const schedulerStopEndpoint = `${schedulerUrl}/stop`
export const schedulerRouteEntryStopEndpoint = `${schedulerUrl}/stop/entry`
export const schedulerRoutesEndpoint = `${schedulerUrl}/routes`
export const schedulerClearRoutesEndpoint = `${schedulerUrl}/clear-routes`

export const availabilityBaseUrl = `${baseUrl}/availability`
export const getAvailabilityShopUrl = id => `${availabilityBaseUrl}/shop/${id}`
export const availabilitySlotUrl = `${availabilityBaseUrl}/slot`
export const getAvailabilitySlotUrl = id => `${availabilityBaseUrl}/slot/${id}`
