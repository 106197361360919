<template>
  <base-layout :data="data">
    <div slot="title">Data Exported</div>

    <div>
      {{data.details.count}} {{data.details.count > 1 ? 'records were' : 'record was'}} exported from the <b>{{data.details.table}}</b> table.
      A copy can be found <a :href="data.details.url[0]">here</a> (expires in 72 hours).
    </div>
  </base-layout>

</template>

<script>
import BaseLayout from './_BaseLayout'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  components: { BaseLayout }
}
</script>
