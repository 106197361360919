var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tech-location-select",
      style: { display: "flex", alignItems: "center" }
    },
    [
      _c(
        "el-select",
        {
          style: { width: "100%" },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        [
          _vm.tech.g_address
            ? _c("el-option", {
                key: "tech",
                attrs: { label: "Tech home", value: "tech" }
              })
            : _vm._e(),
          _c("el-option", {
            key: "shop",
            attrs: { label: "Shop", value: "shop" }
          }),
          _c(
            "el-option-group",
            { attrs: { label: "Custom" } },
            [
              _vm._l(_vm.tech.route_tech_stops, function(loc) {
                return _c("el-option", {
                  key: "custom|" + loc.id,
                  attrs: { value: "custom|" + loc.id, label: loc.name }
                })
              }),
              _vm.newTechStop
                ? _c("el-option", {
                    key: "custom|" + _vm.newTechStop.id,
                    attrs: {
                      value: "custom|" + _vm.newTechStop.id,
                      label: _vm.newTechStop.name
                    }
                  })
                : _vm._e()
            ],
            2
          ),
          _c("el-option", {
            key: "create",
            attrs: { value: "create", label: " + Create new" }
          })
        ],
        1
      ),
      _c("scheduler-route-tech-stop", {
        attrs: {
          open: _vm.value === "create",
          hideIcon: true,
          tech_id: _vm.tech.id
        },
        on: { create: _vm.onCreate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }