import Api from '@/services/Api'

const setShowNagsSubstitutes = async value => {
  await Api.post('/api/users/show-nags-substitutes', { value })
}

const getOrganizations = async () => {
  const { data } = await Api.get('/api/users/organizations')
  return data
}

const getDefaultOrganization = async() => {
  const { data } = await Api.get('/api/users/default-organization')
  return data
}

export const userRepository = Object.freeze({
  setShowNagsSubstitutes,
  getOrganizations,
  getDefaultOrganization
})
