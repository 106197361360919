var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "d-flex settings-general-form",
          attrs: { model: _vm.form, rules: _vm.formRules }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-wrap align-items-center settings-general-content"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-start flex-column section-title"
                },
                [
                  _c("div", { staticClass: "title-container" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Main Settings")
                    ])
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "description text-break" }, [
                      _vm._v("Phone Number and Email to use for all events.")
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap align-items-center settings-general-form"
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone" } },
                    [
                      _c("el-input", {
                        ref: "phone",
                        staticClass: "phone",
                        attrs: { size: "small", placeholder: "Add Phone" },
                        on: { input: _vm.formatPhone },
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "Add Email" },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }