<template>
  <el-tabs v-model='localTab' >
      <el-tab-pane v-for="item in tabs" :key="item.key" :name="item.key">
        <span slot="label">
          <i :class="{['material-icons']: true, [item.icon]: true, 'md-18': true}" />
          {{item.label}}
        </span>
        <div class="tab-container">
          <component :is="item.component" ref="tabs" @changeTab="changeTab" :filePickerKey='filePickerKey' :tab='tab'/>
        </div>
      </el-tab-pane>
    </el-tabs>
</template>

<script>
import TicketForm from './TicketForm.vue'
import Dashboard from './Dashboard.vue'
import Chat from './Chat.vue'
import History from './History.vue'

export default {
  props: ['filePickerKey', 'tab'],
  computed: {
    tabs() {
      const tabs = [
        { label: 'Dashboard', key: 'dashboard', component: Dashboard, icon: 'md-analytics' },
        { label: 'Create Ticket', key: 'create', component: TicketForm, icon: 'md-create' },
        { label: 'Ticket History', key: 'history', component: History, icon: 'md-history' },
        { label: 'Chat', key: 'chat', component: Chat, icon: 'md-question_answer' }
      ]
      return tabs
    },
    localTab: {
      get() {
        return this.tab
      },
      set(v) {
        this.$emit('update:tab', v)
      }
    }
  },
  methods: {
    changeTab(v) {
      this.$emit('update:tab', v)
    }
  }
}
</script>

<style>

</style>