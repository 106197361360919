import Api from '@/services/Api'

const getForAdmin = async ({
  page,
  id,
  name,
  sortBy,
  sortOrder,
  duplicateNamesOnly
}) => {
  const { data } = await Api.get('/api/organizations', {
    params: {
      page,
      id,
      name,
      sortBy,
      sortOrder,
      duplicateNamesOnly
    }
  })
  return data
}

const getDetails = async ({
  organizationId
}) => {
  const { data } = await Api.get(`/api/organization/${organizationId}`)
  return data
}

const create = async ({
  name,
  shopIds
}) => {
  await Api.post('/api/organization', {
    name,
    shopIds
  })
}

const update = async ({
  id,
  name,
  shopIds
}) => {
  await Api.post(`/api/organization/${id}`, {
    name,
    shopIds
  })
}

const searchShops = async ({
  searchTerm,
  organizationId,
  includeAllShops
}) => {
  const { data } = await Api.get('/api/organization/shops/search', {
    params: {
      searchTerm,
      organizationId,
      includeAllShops
    }
  })
  return data
}

const searchOrganizations = async ({
  searchTerm,
  adminShowAll // if the user is an admin, all organizations will return if true, otherwise only organizations the user is in a shop of will return
}) => {
  const { data } = await Api.get('/api/searchOrganizations', {
    params: {
      searchTerm,
      adminShowAll
    }
  })
  return data
}

export const organizationRepository = Object.freeze({
  getForAdmin,
  create,
  update,
  getDetails,
  searchShops,
  searchOrganizations
})
