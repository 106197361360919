var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.isGlass === "1" && _vm.shopHasVendors
    ? _c(
        "el-popover",
        { attrs: { placement: "right", trigger: "click" } },
        [
          _vm.data.jobId
            ? _c("p", [
                _c("strong", [_vm._v(_vm._s(_vm.data.jobId))]),
                _vm._v(": "),
                _c("span", { staticStyle: { color: "#999" } }, [
                  _vm._v(_vm._s(_vm.data.partNumber))
                ])
              ])
            : _c("p", [
                _c("span", { staticStyle: { color: "#999" } }, [
                  _vm._v(_vm._s(_vm.data.partNumber))
                ])
              ]),
          _c("CollapseLeftTrans", [
            _c(
              "div",
              [
                _vm._l(_vm.vendorData, function(vendor, vendorName) {
                  return _c(
                    "Vendor",
                    _vm._b(
                      {
                        key: vendorName,
                        attrs: {
                          type: vendorName,
                          part: _vm.part,
                          shopId: _vm.shopId,
                          warehouse: _vm.warehouse
                        },
                        on: {
                          "refresh-data": function($event) {
                            return _vm.$emit("refresh-data")
                          }
                        }
                      },
                      "Vendor",
                      vendor,
                      false
                    )
                  )
                }),
                _vm.showNewVendorAvailable
                  ? _c("new-vendor-available", {
                      attrs: { shopId: _vm.shopId }
                    })
                  : _vm._e()
              ],
              2
            )
          ]),
          _c(
            "template",
            { slot: "reference" },
            [
              _vm.data.isGlass === "1"
                ? _c(
                    "el-tooltip",
                    {
                      staticClass: "t-ml-1 t-mr-5",
                      attrs: { placement: "top" }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("Check Suppliers")]
                      ),
                      _vm.data.isGlass
                        ? _c("i", {
                            staticClass:
                              "el-icon-shopping-cart-1 t-text-green-500 t-cursor-pointer\n          t-transform t-duration-300 hover:t-scale-125",
                            on: {
                              click: function($event) {
                                return _vm.checkVendors(_vm.data)
                              }
                            }
                          })
                        : _c("i", {
                            staticClass: "el-icon-loading t-text-green-500"
                          })
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }