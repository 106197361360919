var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title: "NAGS details",
        id: "nagsDetailsModal",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg"
      },
      on: { show: _vm.dialogAboutToAppear, hide: _vm.dialogAboutToDisappear }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.popupPartDetails.dsc && _vm.popupPartDetails.dsc !== "",
                  expression:
                    "popupPartDetails.dsc && popupPartDetails.dsc !== ''"
                }
              ],
              staticClass: "row"
            },
            [
              _c("div", { staticClass: "form-group col-6 text-right" }, [
                _c("span", [_c("b", [_vm._v("Description:")])]),
                _vm.saving
                  ? _c("span", { staticStyle: { float: "left" } }, [
                      _vm._v("Saving ")
                    ])
                  : _vm._e(),
                _vm.saving
                  ? _c(
                      "span",
                      { staticStyle: { float: "left" } },
                      [_c("loader")],
                      1
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "form-group col-6" }, [
                _vm._v(" " + _vm._s(_vm.popupPartDetails.dsc) + " ")
              ])
            ]
          ),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-6 text-right" }, [
              _c("span", [_c("b", [_vm._v("Graphic identifier:")])])
            ]),
            _c("div", { staticClass: "form-group col-6" }, [
              _vm._v(
                " " +
                  _vm._s(
                    !isNaN(parseInt(_vm.popupPartDetails.glassCallout)) &&
                      parseInt(_vm.popupPartDetails.glassCallout) !== 0
                      ? _vm.popupPartDetails.glassCallout
                      : "N/A"
                  ) +
                  " "
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-12 text-center" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-gray mt-2 mb-2",
                  staticStyle: { width: "200px" },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      _vm.showMoreDetails = !_vm.showMoreDetails
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.showMoreDetails ? "Less" : "More") + " Info"
                  )
                ]
              )
            ])
          ])
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMoreDetails,
              expression: "showMoreDetails"
            }
          ],
          staticClass: "row"
        },
        [
          _c("div", { staticClass: "form-group col-12" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.popupPartDetails.name !== "",
                    expression: "popupPartDetails.name !== ''"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Name:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" " + _vm._s(_vm.popupPartDetails.name) + " ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.popupPartDetails.nagsid !== "",
                    expression: "popupPartDetails.nagsid !== ''"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("NAGS ID:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" " + _vm._s(_vm.popupPartDetails.nagsid) + " ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.popupPartDetails.partno !== "",
                    expression: "popupPartDetails.partno !== ''"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Part number:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" " + _vm._s(_vm.popupPartDetails.partno) + " ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.popupPartDetails.mfCode !== "",
                    expression: "popupPartDetails.mfCode !== ''"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("MF part number:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" " + _vm._s(_vm.popupPartDetails.mfCode) + " ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !isNaN(parseFloat(_vm.popupPartDetails.partprice)) &&
                      parseFloat(_vm.popupPartDetails.partprice) > 0.0001,
                    expression:
                      "\n          !isNaN(parseFloat(popupPartDetails.partprice)) &&\n            parseFloat(popupPartDetails.partprice) > 0.0001\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Price:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatCurrency(_vm.popupPartDetails.partprice, true)
                      ) +
                      " "
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !isNaN(parseFloat(_vm.popupPartDetails.labor)) &&
                      parseFloat(_vm.popupPartDetails.labor) > 0.0001,
                    expression:
                      "\n          !isNaN(parseFloat(popupPartDetails.labor)) &&\n            parseFloat(popupPartDetails.labor) > 0.0001\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Labor Hours:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatFloatDecimals(
                          _vm.popupPartDetails.labor,
                          2,
                          2,
                          true
                        )
                      ) +
                      " "
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !isNaN(parseFloat(_vm.popupPartDetails.qty)) &&
                      parseFloat(_vm.popupPartDetails.qty) > 0.0001,
                    expression:
                      "\n          !isNaN(parseFloat(popupPartDetails.qty)) &&\n            parseFloat(popupPartDetails.qty) > 0.0001\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Quantity:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatFloatDecimals(
                          _vm.popupPartDetails.qty,
                          2,
                          2,
                          true
                        )
                      ) +
                      " "
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.popupPartDetails.isAdhesiveKitPart === true,
                    expression: "popupPartDetails.isAdhesiveKitPart === true"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Adhesive:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" True ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.popupPartDetails.mouldingDiscount === true,
                    expression: "popupPartDetails.mouldingDiscount === true"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Molding:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" True ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.popupPartDetails.isOem === true,
                    expression: "popupPartDetails.isOem === true"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("OEM:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" True ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.popupPartDetails.isPremium === true,
                    expression: "popupPartDetails.isPremium === true"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Premium:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" True ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.popupPartDetails.isGlass === true,
                    expression: "popupPartDetails.isGlass === true"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Glass:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" True ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.popupPartDetails.isChip === true,
                    expression: "popupPartDetails.isChip === true"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Chip repair:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" True ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.color !== "" &&
                      _vm.popupPartDetails.color !== "Not Available",
                    expression:
                      "\n          popupPartDetails.color !== '' &&\n            popupPartDetails.color !== 'Not Available'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Color:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" " + _vm._s(_vm.popupPartDetails.color) + " ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.PREFIX !== "",
                    expression:
                      "\n          popupPartDetails.isGlass === true && popupPartDetails.PREFIX !== ''\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("NAGS glass category:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" " + _vm._s(_vm.popupPartDetails.PREFIX) + " ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ATCHMNT_FLAG === "Y",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ATCHMNT_FLAG === 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Is attachment included:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ATCHMNT_FLAG === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ATCHMNT_FLAG === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Is attachment included:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.CLIPS_FLAG === "Y",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.CLIPS_FLAG === 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Are clips required:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.CLIPS_FLAG === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.CLIPS_FLAG === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Are clips required:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.MLDING_FLAG === "Y",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.MLDING_FLAG === 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Is molding required:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.MLDING_FLAG === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.MLDING_FLAG === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Is molding required:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ATCHMNT_FLAG === "Y" &&
                      _vm.popupPartDetails.ATCHMNT_IS_CLIP === "Y",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ATCHMNT_FLAG === 'Y' &&\n            popupPartDetails.ATCHMNT_IS_CLIP === 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [
                    _c("b", [_vm._v("Is attachment clip included:")])
                  ])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ATCHMNT_FLAG === "Y" &&
                      _vm.popupPartDetails.ATCHMNT_IS_CLIP === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ATCHMNT_FLAG === 'Y' &&\n            popupPartDetails.ATCHMNT_IS_CLIP === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [
                    _c("b", [_vm._v("Is attachment clip included:")])
                  ])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ATCHMNT_FLAG === "Y" &&
                      _vm.popupPartDetails.ATCHMNT_IS_MLDING === "Y",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ATCHMNT_FLAG === 'Y' &&\n            popupPartDetails.ATCHMNT_IS_MLDING === 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [
                    _c("b", [_vm._v("Is attachment molding included:")])
                  ])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ATCHMNT_FLAG === "Y" &&
                      _vm.popupPartDetails.ATCHMNT_IS_MLDING === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ATCHMNT_FLAG === 'Y' &&\n            popupPartDetails.ATCHMNT_IS_MLDING === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [
                    _c("b", [_vm._v("Is attachment molding included:")])
                  ])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ATCHMNT_FLAG === "Y" &&
                      _vm.popupPartDetails.ATCHMNT_DSC !== "",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ATCHMNT_FLAG === 'Y' &&\n            popupPartDetails.ATCHMNT_DSC !== ''\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Attachment description:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" " + _vm._s(_vm.popupPartDetails.ATCHMNT_DSC) + " ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.PART_NUM !== "",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.PART_NUM !== ''\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Internal part number:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" " + _vm._s(_vm.popupPartDetails.PART_NUM) + " ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ANT_FLAG === "Y",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ANT_FLAG === 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Anterior:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ANT_FLAG === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ANT_FLAG === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Anterior:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      !isNaN(parseFloat(_vm.popupPartDetails.BLK_SIZE1)) &&
                      parseFloat(_vm.popupPartDetails.BLK_SIZE1) > 0.0001,
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            !isNaN(parseFloat(popupPartDetails.BLK_SIZE1)) &&\n            parseFloat(popupPartDetails.BLK_SIZE1) > 0.0001\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Block size 1:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatFloatDecimals(
                          _vm.popupPartDetails.BLK_SIZE1,
                          2,
                          2,
                          true
                        )
                      ) +
                      " "
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      !isNaN(parseFloat(_vm.popupPartDetails.BLK_SIZE2)) &&
                      parseFloat(_vm.popupPartDetails.BLK_SIZE2) > 0.0001,
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            !isNaN(parseFloat(popupPartDetails.BLK_SIZE2)) &&\n            parseFloat(popupPartDetails.BLK_SIZE2) > 0.0001\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Block size 2:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatFloatDecimals(
                          _vm.popupPartDetails.BLK_SIZE2,
                          2,
                          2,
                          true
                        )
                      ) +
                      " "
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ENCAP_FLAG === "Y",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ENCAP_FLAG === 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Encap:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.ENCAP_FLAG === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.ENCAP_FLAG === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Encap:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass == true &&
                      _vm.popupPartDetails.HDS_UP_DISP_FLAG == "Y",
                    expression:
                      "\n          popupPartDetails.isGlass == true &&\n            popupPartDetails.HDS_UP_DISP_FLAG == 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("HDS UP DISP:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.HDS_UP_DISP_FLAG === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.HDS_UP_DISP_FLAG === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("HDS UP DISP:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.HEATED_FLAG === "Y",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.HEATED_FLAG === 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Heated:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.HEATED_FLAG === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.HEATED_FLAG === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Heated:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      !isNaN(parseFloat(_vm.popupPartDetails.NUM_HOLES)) &&
                      parseFloat(_vm.popupPartDetails.NUM_HOLES) > 0.0001,
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            !isNaN(parseFloat(popupPartDetails.NUM_HOLES)) &&\n            parseFloat(popupPartDetails.NUM_HOLES) > 0.0001\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Number of holes:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatFloatDecimals(
                          _vm.popupPartDetails.NUM_HOLES,
                          2,
                          2,
                          true
                        )
                      ) +
                      " "
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.SLIDER_FLAG === "Y",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.SLIDER_FLAG === 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Slider window:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.SLIDER_FLAG === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.SLIDER_FLAG === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Slider window:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.SOLAR_FLAG === "Y",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.SOLAR_FLAG === 'Y'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Solar window:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(" Yes ")
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass === true &&
                      _vm.popupPartDetails.SOLAR_FLAG === "N",
                    expression:
                      "\n          popupPartDetails.isGlass === true &&\n            popupPartDetails.SOLAR_FLAG === 'N'\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Solar window:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [_vm._v(" No ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass == true &&
                      !isNaN(parseFloat(_vm.popupPartDetails.THICKNESS)) &&
                      parseFloat(_vm.popupPartDetails.THICKNESS) > 0.0001,
                    expression:
                      "\n          popupPartDetails.isGlass == true &&\n            !isNaN(parseFloat(popupPartDetails.THICKNESS)) &&\n            parseFloat(popupPartDetails.THICKNESS) > 0.0001\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Thickness of glass:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatFloatDecimals(
                          _vm.popupPartDetails.THICKNESS,
                          2,
                          2,
                          true
                        )
                      ) +
                      " "
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass == true &&
                      !isNaN(parseFloat(_vm.popupPartDetails.WT)) &&
                      parseFloat(_vm.popupPartDetails.WT) > 0.0001,
                    expression:
                      "\n          popupPartDetails.isGlass == true &&\n            !isNaN(parseFloat(popupPartDetails.WT)) &&\n            parseFloat(popupPartDetails.WT) > 0.0001\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Weight of glass:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatFloatDecimals(
                          _vm.popupPartDetails.WT,
                          2,
                          2,
                          true
                        )
                      ) +
                      " "
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.popupPartDetails.isGlass == true &&
                      !isNaN(parseFloat(_vm.popupPartDetails.TUBE_QTY)) &&
                      parseFloat(_vm.popupPartDetails.TUBE_QTY) > 0.0001,
                    expression:
                      "\n          popupPartDetails.isGlass == true &&\n            !isNaN(parseFloat(popupPartDetails.TUBE_QTY)) &&\n            parseFloat(popupPartDetails.TUBE_QTY) > 0.0001\n        "
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", { staticClass: "form-group col-6 text-right" }, [
                  _c("span", [_c("b", [_vm._v("Number of tubes:")])])
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatFloatDecimals(
                          _vm.popupPartDetails.TUBE_QTY,
                          2,
                          2,
                          true
                        )
                      ) +
                      " "
                  )
                ])
              ]
            ),
            !_vm.localPermissionCheck(_vm.localPermissions.TECHCOMMISSION)
              ? _c(
                  "div",
                  {
                    staticClass: "row mb-2 mt-2 mb-2 ml-3 mr-3 flex-row-reverse"
                  },
                  [
                    _c("div", { staticClass: "form-group col-4 col-md-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn-sm btn-gray text-right",
                          on: {
                            click: function($event) {
                              return _vm.revertButtonPressed()
                            }
                          }
                        },
                        [_vm._v(" Revert ")]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-group col-8 col-md-4 text-right" },
                      [
                        _c("span", [
                          _c("span", { staticStyle: { "font-size": "14px" } }, [
                            _vm._v("Auto popup")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.shopPartNote.popup,
                                expression: "shopPartNote.popup"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.shopPartNote.popup)
                                ? _vm._i(_vm.shopPartNote.popup, null) > -1
                                : _vm.shopPartNote.popup
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.shopPartNote.popup,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.shopPartNote,
                                        "popup",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.shopPartNote,
                                        "popup",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.shopPartNote, "popup", $$c)
                                }
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-12 col-md-6 align-bottom"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "align-bottom",
                            staticStyle: { "font-size": "12px" }
                          },
                          [_vm._v("Customer message for this part number")]
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "row mb-3 ml-3 mr-3" }, [
              _c("div", { staticClass: "form-group col-12" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shopPartNote.note,
                      expression: "shopPartNote.note"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  staticStyle: { height: "100px" },
                  domProps: { value: _vm.shopPartNote.note },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.shopPartNote, "note", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ])
        ]
      ),
      !_vm.localPermissionCheck(_vm.localPermissions.TECHCOMMISSION)
        ? _c("InventoryInputs", {
            attrs: {
              shopId: _vm.shopId,
              partno: _vm.popupPartDetails.partno,
              avg_cost: _vm.partInventory.avg_cost,
              quantity: _vm.partInventory.quantity,
              zone: _vm.partInventory.zone
            },
            on: {
              "update:avg_cost": function($event) {
                return _vm.$set(_vm.partInventory, "avg_cost", $event)
              },
              "update:quantity": function($event) {
                return _vm.$set(_vm.partInventory, "quantity", $event)
              },
              "update:zone": function($event) {
                return _vm.$set(_vm.partInventory, "zone", $event)
              }
            }
          })
        : _vm._e(),
      _vm.popupPartDetails.children
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6 offset-md-3" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    "text-decoration": "underline",
                    "font-weight": "bold",
                    width: "100%",
                    "text-align": "center"
                  }
                },
                [_c("span", [_vm._v("Compatible Vehicles:")])]
              ),
              _c(
                "ul",
                {
                  staticStyle: { "list-style": "circle inside" },
                  attrs: { id: "matchingVehiclesDisplay" }
                },
                _vm._l(_vm.matchingVehicles, function(vehicle, vehicleIndex) {
                  return _c("li", { key: vehicleIndex }, [
                    _c("u", [_vm._v(_vm._s(vehicleIndex))]),
                    _c(
                      "ul",
                      { staticStyle: { "list-style": "circle inside" } },
                      _vm._l(vehicle, function(model, modelIndex) {
                        return _c("li", { key: modelIndex }, [
                          _vm._v(" " + _vm._s(modelIndex) + ": "),
                          _c(
                            "ul",
                            { staticStyle: { "list-style": "circle inside" } },
                            _vm._l(model, function(year, yearIndex) {
                              return _c("li", { key: yearIndex }, [
                                _vm._v(" " + _vm._s(yearIndex) + ": "),
                                _c(
                                  "ul",
                                  {
                                    staticStyle: { "list-style": "none inside" }
                                  },
                                  _vm._l(year, function(style, styleIndex) {
                                    return _c("li", { key: styleIndex }, [
                                      _vm._v(" " + _vm._s(style) + " ")
                                    ])
                                  }),
                                  0
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      }),
                      0
                    )
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      !_vm.localPermissionCheck(_vm.localPermissions.TECHCOMMISSION)
        ? _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm.dialogOpen
              ? _c(
                  "div",
                  [
                    _c(
                      "b-btn",
                      {
                        staticClass: "w-100 mb-2",
                        on: { click: _vm.autoAddPartsClicked }
                      },
                      [_vm._v("Auto Add Parts")]
                    ),
                    _vm._l(_vm.popupPartDetails.children, function(
                      child,
                      index
                    ) {
                      return _c(
                        "b-collapse",
                        {
                          key: index,
                          staticClass: "h-25 mh-25",
                          attrs: { id: "autoAddAdhesives" },
                          model: {
                            value: _vm.showAutoAdd,
                            callback: function($$v) {
                              _vm.showAutoAdd = $$v
                            },
                            expression: "showAutoAdd"
                          }
                        },
                        [
                          _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: child.detail.name,
                                  expression: "child.detail.name"
                                }
                              ],
                              staticClass: "w-100",
                              style: {
                                "background-image": _vm.partCategoryColor(
                                  child.detail.name
                                )
                              },
                              attrs: {
                                "aria-controls": child.detail.name,
                                "aria-expanded": false
                              }
                            },
                            [_vm._v(_vm._s(child.detail.name))]
                          ),
                          _c(
                            "b-collapse",
                            { attrs: { id: child.detail.name } },
                            [
                              _c("div", { staticClass: "autoAddCheckList" }, [
                                _c("table", { staticClass: "table" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "w-75",
                                          attrs: { scope: "col" }
                                        },
                                        [_vm._v("Part")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "text-align": "right"
                                          },
                                          attrs: { scope: "col" }
                                        },
                                        [_vm._v("Auto-Add")]
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(child.children, function(
                                      childPart,
                                      index
                                    ) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [
                                          _vm._v(_vm._s(childPart.detail.name))
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right"
                                            }
                                          },
                                          [
                                            _c("b-form-checkbox", {
                                              attrs: {
                                                value: childPart.detail.partno
                                              },
                                              model: {
                                                value: _vm.autoAddParts,
                                                callback: function($$v) {
                                                  _vm.autoAddParts = $$v
                                                },
                                                expression: "autoAddParts"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    _vm.showAutoAdd
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "w-100",
                            style: { "background-color": "brown" },
                            attrs: { "aria-expanded": false },
                            on: { click: _vm.autoAddSavedClicked }
                          },
                          [_vm._v("Saved Parts")]
                        )
                      : _vm._e(),
                    _c(
                      "b-collapse",
                      {
                        attrs: { id: "autoAddSavedCollapse" },
                        model: {
                          value: _vm.showAutoAddSaved,
                          callback: function($$v) {
                            _vm.showAutoAddSaved = $$v
                          },
                          expression: "showAutoAddSaved"
                        }
                      },
                      [
                        _c("div", { staticClass: "autoAddCheckList" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass: "w-25",
                                    attrs: { scope: "col" }
                                  },
                                  [_vm._v("Part Id")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "w-50",
                                    attrs: { scope: "col" }
                                  },
                                  [_vm._v("Part Description")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "w-25",
                                    staticStyle: { "text-align": "right" },
                                    attrs: { scope: "col" }
                                  },
                                  [_vm._v(" Auto-Add ")]
                                )
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.savedParts, function(
                                savedPart,
                                index
                              ) {
                                return _c("tr", { key: index }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "w-25",
                                      attrs: { scope: "col" }
                                    },
                                    [_vm._v(_vm._s(savedPart.id))]
                                  ),
                                  savedPart.description != ""
                                    ? _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(savedPart.description) +
                                            " "
                                        )
                                      ])
                                    : _c("td", [_vm._v("N/A")]),
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          value: "SAVEDPART-" + savedPart.id
                                        },
                                        model: {
                                          value: _vm.autoAddParts,
                                          callback: function($$v) {
                                            _vm.autoAddParts = $$v
                                          },
                                          expression: "autoAddParts"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      ]
                    )
                  ],
                  2
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "form-group col-12 text-center" },
          [
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(" " + _vm._s(_vm.glassGraphicLabel))
            ]),
            _vm.popupPartDetails.callouts
              ? _c(
                  "el-alert",
                  {
                    attrs: {
                      center: "",
                      closable: false,
                      title: "Known callouts",
                      type: "info"
                    }
                  },
                  [
                    _vm._l(_vm.popupPartDetails.callouts, function(val, key) {
                      return [
                        _c(
                          "span",
                          {
                            key: key * 10,
                            style: {
                              fontWeight:
                                _vm.popupPartDetails.callout === key
                                  ? "600"
                                  : "400"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(key) + " - " + _vm._s(val) + " "
                            )
                          ]
                        ),
                        _c("el-divider", {
                          key: key,
                          attrs: { direction: "vertical" }
                        })
                      ]
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.image
              ? _c("img", {
                  directives: [
                    {
                      name: "img",
                      rawName: "v-img",
                      value: { src: _vm.image },
                      expression: "{ src: image }"
                    }
                  ],
                  staticClass: "img-fluid single",
                  attrs: { src: _vm.image }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", {
        staticClass: "w-100",
        attrs: { slot: "modal-footer" },
        slot: "modal-footer"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }