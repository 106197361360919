var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "80px",
        width: "800px",
        title: "Supplier Details",
        "custom-class": _vm.type,
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "div",
        [
          _c("info-row", { attrs: { name: "name", value: _vm.searchLabel } }),
          _c("info-row", { attrs: { name: "address", value: _vm.address } }),
          _c("info-row", { attrs: { name: "manager", value: _vm.manager } }),
          _c("info-row", { attrs: { name: "phone", value: _vm.phone } }),
          _c("info-row", { attrs: { name: "fax", value: _vm.fax } }),
          _c("info-row", { attrs: { name: "email", value: _vm.email } })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.creating, type: "primary" },
              on: {
                click: function($event) {
                  return _vm.$emit("create")
                }
              }
            },
            [_vm._v("Use Supplier")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "background-color": "black", color: "white" },
              attrs: { loading: _vm.creating },
              on: { click: _vm.handleClose }
            },
            [_vm._v("Cancel")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }