<template>
  <el-dialog
    :visible.sync="visible"
    :width="width"
    @open="$emit('open')"
    @close="$emit('close')"
    @opened="$emit('opened')"
    @closed="$emit('closed')"
    v-bind="$attrs"
    :title="title"
    :append-to-body="appendToBody"
    :custom-class="`gb-base-dialog ${extraClass}`"
  >
    <slot />
    <slot name="title" slot="title" v-if="$slots.title" />
    <slot name="footer" slot="footer" v-if="$slots.footer" />
    <div class="footer" slot="footer" v-else>
      <el-button @click="visible = false" size="normal">Cancel</el-button>
      <el-button
        @click="$emit('on-save')"
        type="primary"
        size="normal"
        :disabled="!valid"
        :loading="loading"
      >
        {{ okText }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    loading: Boolean,
    appendToBody: {
      default: () => true,
      type: Boolean
    },
    valid: {
      type: Boolean,
      default: () => true
    },
    width: String,
    title: String,
    extraClass: String,
    okText: {
      default: () => 'Save'
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>

<style lang="scss">
.gb-base-dialog:not(.gb-form-dialog) {
  max-width: 100vw;
}
</style>
