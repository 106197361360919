var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-flex-grow" }, [
    _c("div", { staticClass: "t-flex t-flex-col" }, [
      _c(
        "form",
        {
          class: {
            hide: !_vm.loaded
          },
          attrs: { id: "payment-form" }
        },
        [
          _c(
            "div",
            { staticStyle: { display: "none" }, attrs: { id: "amount" } },
            [
              _c("input", {
                attrs: { name: "amount" },
                domProps: { value: _vm.amount }
              })
            ]
          ),
          _vm._m(0),
          _vm._m(1),
          _vm._m(2),
          _vm._m(3),
          _c("div", { attrs: { id: "card-response", role: "alert" } })
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form-row clover-form-row t-flex t-justify-center" },
      [
        _c("div", { attrs: { id: "card-number" } }),
        _c("div", {
          staticClass: "input-errors",
          attrs: { id: "card-number-errors", role: "alert" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form-row clover-form-row t-flex t-justify-center" },
      [
        _c("div", { attrs: { id: "card-date" } }),
        _c("div", {
          staticClass: "input-errors",
          attrs: { id: "card-date-errors", role: "alert" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form-row clover-form-row t-flex t-justify-center" },
      [
        _c("div", { attrs: { id: "card-cvv" } }),
        _c("div", {
          staticClass: "input-errors",
          attrs: { id: "card-cvv-errors", role: "alert" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form-row clover-form-row t-flex t-justify-center" },
      [
        _c("div", { attrs: { id: "card-postal-code" } }),
        _c("div", {
          staticClass: "input-errors",
          attrs: { id: "card-postal-code-errors", role: "alert" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }