var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        width: "1000px",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        "custom-class": "chat-dialog"
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-blue-100 t-text-lg",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-chat-line-round" }),
          _c("span", { staticClass: "t-ml-3" }, [_vm._v("Chat")])
        ]
      ),
      _c(
        "div",
        { staticClass: "t-flex t-h-full" },
        [_c("Sidebar"), _c("MainView")],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }