<template>
  <component :is="component" :data="data" />
</template>

<script>
import DataExported  from './components/DataExported';
import FormSigned from './components/FormSigned';
import JobCanceled from './components/JobCanceled';
import JobCompleted from './components/JobCompleted';
import JobReceived from './components/JobReceived';
import JobRescheduled from './components/JobRescheduled';
import PaymentCreated from './components/PaymentCreated';
import QuickbooksExportError from './components/QuickbooksExportError';
import RightPartReceived from './components/RightPartReceived';
import SmsConfirmationDeclined from './components/SmsConfirmationDeclined';
import WebLeadReceived from './components/WebLeadReceived';
import SmsReceived from './components/SmsReceived';
import SmsSignatureReceived from './components/SmsSignatureReceived';
import { getComponentName } from '@/store/modules/notifications/helpers';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    component () {
      return getComponentName(this.data.event_type);
    },
  },

  components: {
    DataExported,
    FormSigned,
    JobCanceled,
    JobCompleted,
    JobReceived,
    JobRescheduled,
    PaymentCreated,
    QuickbooksExportError,
    RightPartReceived,
    SmsConfirmationDeclined,
    WebLeadReceived,
    SmsSignatureReceived,
    SmsReceived
  },
}
</script>
