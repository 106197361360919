var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-badge",
        {
          attrs: {
            value: _vm.jobFilterCount[_vm.selected.value],
            type: "primary"
          }
        },
        [
          _c(
            "el-select",
            {
              staticClass: "no-border-select",
              attrs: { "value-key": "label" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            _vm._l(_vm.options, function(item) {
              return _c(
                "el-option",
                { key: item.value, attrs: { label: item.label, value: item } },
                [
                  _c(
                    "span",
                    { staticClass: "select-job-filter-option" },
                    [
                      _c("span", [_vm._v(_vm._s(item.label))]),
                      _c("el-tag", { attrs: { size: "mini" } }, [
                        _vm._v(_vm._s(_vm.jobFilterCount[item.value]))
                      ])
                    ],
                    1
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm.loading ? _c("i", { staticClass: "el-icon-loading" }) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }