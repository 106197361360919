<template>
  <div class="t-mb-3 t-pr-4">
    <div class="t-font-medium t-text-gray-400 t-text-xs">
      {{ label }}
    </div>
    <el-tooltip
      v-if="value"
      effect="dark"
      content="Click to preview"
      placement="right"
    >
      <img
        :src="value"
        class="t-max-w-32 t-max-h-32 t-object-contain t-cursor-pointer"
        @click="$emit('view')"
      >
    </el-tooltip>
    <span v-else class="t-font-light t-italic t-text-gray-500">
      -
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String
  }
}
</script>
