var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-map-route-stop-overlay" },
    _vm._l(_vm.stops, function(stop) {
      return _c(
        "div",
        { key: stop.id, staticClass: "stop-container" },
        [
          _c(_vm.icons[stop.type], {
            tag: "component",
            attrs: { fillColor: stop.color || "#607D8B", text: stop.order }
          }),
          _c(
            "el-card",
            {
              staticClass: "stop-details",
              attrs: { "body-style": { padding: "10px" } }
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.title[stop.type]) + " ")
              ]),
              stop.type === "pickup"
                ? _c("route-entry-part-pickup-list", { attrs: { item: stop } })
                : _vm._e(),
              stop.notes ? _c("div", [_vm._v(_vm._s(stop.notes))]) : _vm._e(),
              _c("scheduler-times-row", { attrs: { item: stop } })
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }