var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: _vm.title,
            id: _vm.computedModalId,
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg"
          },
          on: {
            show: _vm.dialogAboutToAppear,
            hide: _vm.dialogAboutToDisappear
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-12" },
              [
                _c("Phone", {
                  ref: "phone1",
                  attrs: { title: "Fax Number", value: _vm.faxNumber },
                  on: {
                    "update:value": function($event) {
                      _vm.faxNumber = $event
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-md-12" }, [
              _c("label", { attrs: { for: "faxSendName" } }, [_vm._v("Name")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name"
                  }
                ],
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "faxSendName", type: "text", maxlength: "32" },
                domProps: { value: _vm.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm.mainPDFAttachmentName && _vm.mainPDFAttachmentName.length > 0
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-sm-12 col-md-12 col-lg-5" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "form-check label label-gray label-sm mt-1"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.includePdf,
                              expression: "includePdf"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox", id: "include-docs" },
                          domProps: {
                            checked: Array.isArray(_vm.includePdf)
                              ? _vm._i(_vm.includePdf, null) > -1
                              : _vm.includePdf
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.includePdf,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.includePdf = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.includePdf = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.includePdf = $$c
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "include-docs" }
                          },
                          [_vm._v(_vm._s(_vm.mainPDFAttachmentName))]
                        )
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _c("br"),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-12" },
              [
                _c("b-table", {
                  attrs: {
                    busy: _vm.loadingFaxHistory,
                    responsive: "",
                    "sort-by": "sent",
                    "sort-desc": false,
                    "per-page": 5,
                    "current-page": _vm.currentPage,
                    striped: "",
                    hover: "",
                    items: _vm.tableValues,
                    fields: _vm.tableFields
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "confirmation",
                        fn: function(row) {
                          return row.item.status === "sent"
                            ? [
                                _c(
                                  "el-tooltip",
                                  { attrs: { content: "Download sent pdf" } },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            return _vm.faxPdfClicked(
                                              row.item.jobid
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass: "fa fa-download"
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.retreivingConfirmation
                                  ? _c("i", { staticClass: "el-icon-loading" })
                                  : _vm._e()
                              ]
                            : undefined
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("b-pagination", {
                  attrs: {
                    align: "right",
                    size: "md",
                    "total-rows": _vm.faxHistory.length,
                    "per-page": 5
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", size: "lg", variant: "primary" },
                    on: { click: _vm.sendFax }
                  },
                  [_vm._v("Send Fax")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", variant: "primary" },
                    on: { click: _vm.cancelButtonPressed }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ]
          )
        ]
      ),
      _c("LoadingDialog", {
        attrs: {
          id: _vm.computedLoadingModalId,
          isOpen: _vm.loadingDialogIsOpen,
          message: "Generating and sending fax..."
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }