var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-team-list-filter" },
    [
      _c("div", { staticClass: "ui-input-label" }, [_vm._v("Selected shops:")]),
      _c(
        "el-select",
        _vm._b(
          {
            attrs: {
              multiple: "",
              "multiple-limit": _vm.cardView === 4 ? 1 : 0
            },
            model: {
              value: _vm.select,
              callback: function($$v) {
                _vm.select = $$v
              },
              expression: "select"
            }
          },
          "el-select",
          _vm.pickerOptions,
          false
        ),
        _vm._l(_vm.orgShops, function(shops, org) {
          return _c(
            "el-option-group",
            { key: org, attrs: { label: org } },
            _vm._l(shops, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }