var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "well-light" }, [
    _vm.documents && _vm.documents.length > 0
      ? _c(
          "span",
          _vm._l(_vm.documentOptions, function(types, index) {
            return _c(
              "span",
              { key: index + "types" },
              _vm._l(_vm.localDocuments, function(document, index2) {
                return _c("span", { key: index2 + "docs" }, [
                  document.document_type === types.value
                    ? _c(
                        "a",
                        {
                          staticClass: "tag tag-light",
                          style: {
                            "background-color": _vm.getDocumentTagColorWithType(
                              document.table_ref,
                              document.document_type
                            )
                          },
                          attrs: { href: document.url, target: "_blank" }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: _vm.faFile }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.prefix ? _vm.prefix + "-" : "") +
                              _vm._s(document.typeName) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              }),
              0
            )
          }),
          0
        )
      : _c("span", [_vm._v(" Or drag and drop... ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }