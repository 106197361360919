<template>
  <b-modal
    class='modal-xl'
    :id='modalId'
    @show="dialogAboutToAppear"
    @hidden="dialogDisappeared"
    title='Billing Details'
    no-close-on-backdrop
    no-close-on-esc
    :hide-header-close="!userAccountIsActive"
    header-bg-variant='primary'
    header-text-variant='light'
    size='xl'>

    <div v-if="this.$store.state.user.account_manager === 1 || this.$store.state.user.admin === 1">
      <div class='form-row' v-show="loadingBillingDetails">Loading...</div>
      <div class='form-row' v-if="!loadingBillingDetails && billingDetailsResult === 'error'"> {{errorMessage && errorMessage.length > 0 ? errorMessage : 'Something went wrong loading billing details...'}}</div>
      <div class='form-row' style="width:100%;" v-if="!loadingBillingDetails && billingDetailsResult === 'success'">
        <div class='form-group col-3'>
          <h5 v-if="!userAccountIsActive" style="color:red">Your GlassBiller account is currently inactive. Please enter in updated payment details. If you are unable to update with this form, you may need to reach out to GlassBiller support.</h5>
          <p><b>Account:</b> {{ accountName }}</p>
          <p ><b>{{ userAccountIsActive ? 'Price' : 'Amount Due' }}:</b> {{ billingDisplayAmount }}</p>
          <p v-if="userAccountIsActive"><b>Next Billing:</b> {{ nextBillingAt }}</p>
          <p><b>Current Status:</b> {{ billingStatus.toUpperCase() }}</p>
        </div>
        <div class='form-group col-9'>
          <iframe v-if="billingDetailsUrl.length > 0" :src="billingDetailsUrl" width="100%" height="650px" frameBorder="0">Browser not compatible.</iframe>
        </div>
      </div>
      <div class='form-row' v-if="!loadingBillingDetails && billingDetailsResult === 'idNotFound'">Please contact GlassBiller in order to update your billing details.</div>
    </div>
    <div v-if="this.$store.state.user.account_manager !== 1">
      <div class='form-row'>
          <h5 v-if="!userAccountIsActive" style="color:red">This account is currently inactive. The account manager must update the account's billing information in order to reactivate the account.</h5>
      </div>
    </div>
    <div slot='modal-footer' class='w-100'>
      <div class='float-right'>
        <button v-if="userAccountIsActive" type='button' class='btn btn-secondary' variant="primary" @click="closeButtonClicked">Close</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Account from '@/scripts/objects/account'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import { get } from 'vuex-pathify'

export default {
  props: ['accountId', 'dialogToReturnTo', 'modalId'],
  data () {
    return {
      loadingBillingDetails: false,
      billingDetailsResult: '',
      billingDetailsUrl: '',

      accountName: '',
      billingAmount: 0,
      billingInterval: 1,
      billingUnit: 'months',
      nextBillingAt: '',
      billingStatus: '',
      errorMessage: ''
    }
  },
  computed: {
    userAccountIsActive: get('userAccountIsActive'),
    billingDisplayAmount: function () {
      var billingDisplayAmount = this.formatCurrency(this.billingAmount, false, true)
      if (this.billingInterval === 1 && this.billingUnit === 'months') {
        billingDisplayAmount += '/Month'
      } else {
        billingDisplayAmount += ' every ' + this.billingInterval + ' ' + this.billingUnit
      }

      return billingDisplayAmount
    }
  },
  methods: {
    dialogAboutToAppear () {
      let self = this
      self.loadingBillingDetails = true
      Account.getBillingDetails(self.accountId, true, false, function (data) {
        self.loadingBillingDetails = false
        self.billingDetailsResult = data.response

        if (data.response === 'success') {
          self.errorMessage = ''

          self.accountName = data.accountName

          self.billingDetailsUrl = data.url

          self.billingAmount = data.amount
          self.billingInterval = data.interval
          self.billingUnit = data.interval_unit
          self.nextBillingAt = data.next_billing_at

          self.billingStatus = data.subscription_status
          self.$store.dispatch('updateAccountStatus')
        } else {
          self.errorMessage = data.errorMessage
        }
      }, function (error) {
        self.loadingBillingDetails = false
        self.billingDetailsResult = 'error'
        self.billingDetailsUrl = ''
        console.log('zoho hosted page error: ', error)
      })
    },
    dialogDisappeared () {
      if (this.dialogToReturnTo && this.dialogToReturnTo.length > 0) {
        this.$root.$emit('bv::show::modal', this.dialogToReturnTo)
      }
    },
    closeButtonClicked () {
      this.$root.$emit('bv::hide::modal', this.modalId)
    }
  },
  mixins: [UtilityMixin]
}
</script>
