<template>
  <div>
    <el-dialog
      title="User Audit Log"
      :visible.sync='isVisible'
      width='60%'
      :modalAppendToBody="false">
      <div class="row mb-2">
        <div class="col-md-4">
          <el-select v-model="selectedUser" placeholder="Select" filterable clearable>
            <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.username"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
      </div>
      <el-table
        v-infinite-scroll="handleScroll"
        :infinite-scroll-disabled="done"
        :infinite-scroll-distance="1000"
        style="overflow: auto; height: 500px; width: 100%;"
        class="infinite-list el-table--fluid-height el-table--scrollable-y userAuditTable"
        v-loading="loading"
        :data="userLogs">
        <el-table-column
          prop="created"
          label="Date/Time"
          :min-width="20">
          <template slot-scope="scope">
            {{ humanDateTimeSeconds(scope.row._date) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="job_id"
          label="Job"
          :min-width="10">
          <template slot-scope="scope">
            <div v-if="scope.row.job_id">
              <a :href="'/jobs/' + scope.row.job_id" target="_blank">{{scope.row.job_id}}</a>
            </div>
            <div v-else>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="Description"
          :min-width="60">
          <template slot-scope="scope">
            <div v-if="scope.row.event_id && scope.row.event_id === 55">
              {{scope.row.username}} <span class="eventChip login">logged in</span>
            </div>
            <div v-else-if="scope.row.event_id && scope.row.event_id === 57">
              {{scope.row.username}} <span class="eventChip logout">logged out</span>
            </div>
            <div v-else-if="scope.row.event_id && scope.row.event_id === 56">
              {{scope.row.username}} was <span class="eventChip bump">bumped out</span>
            </div>
            <div v-else style="width: 95%; word-break: keep-all;">
              {{scope.row.description}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="browser"
          label="Browser/Device"
          :min-width="20">
          <template slot-scope="scope">
            <i class="fa fa-chrome fa-2x" v-if="scope.row.browser && scope.row.browser.includes('Chrome')" v-b-popover.hover.top="'Chrome'"></i>
            <i class="fa fa-safari fa-2x" v-if="scope.row.browser && scope.row.browser.includes('Safari')" v-b-popover.hover.top="'Safari'"></i>
            <i class="fa fa-firefox fa-2x" v-if="scope.row.browser && scope.row.browser.includes('Firefox')" v-b-popover.hover.top="'Firefox'"></i>
            <i class="fa fa-opera fa-2x" v-if="scope.row.browser && scope.row.browser.includes('Opera')" v-b-popover.hover.top="'Opera'"></i>
            <i class="fa fa-edge fa-2x" v-if="scope.row.browser && scope.row.browser.includes('Edge')" v-b-popover.hover.top="'Edge'"></i>
            <i class="fa fa-globe fa-2x" v-if="scope.row.browser && scope.row.browser.includes('Samsung')" v-b-popover.hover.top="'Samsung Browser'"></i>
            &nbsp;
            <i class="fa fa-windows fa-2x" v-if="scope.row.browser && scope.row.browser.includes('Windows')" v-b-popover.hover.top="'Windows'"></i>
            <i class="fa fa-android fa-2x" v-if="scope.row.browser && scope.row.browser.includes('Android')" v-b-popover.hover.top="'Android'"></i>
            <i class="fa fa-apple fa-2x" v-if="scope.row.browser && (scope.row.browser.includes('Apple') || scope.row.browser.includes('Macintosh'))" v-b-popover.hover.top="'Apple'"></i>
            <i class="fa fa-linux fa-2x" v-if="scope.row.browser && scope.row.browser.includes('Linux')" v-b-popover.hover.top="'Linux'"></i>
            <i class="fa fa-laptop fa-2x" v-if="scope.row.browser && scope.row.browser.includes('cBook')" v-b-popover.hover.top="'Chromebook'"></i>
            <i class="fa fa-mobile fa-2x" v-if="scope.row.browser && scope.row.browser.includes('iPhone')" v-b-popover.hover.top="'iPhone'"></i>
            <i class="fa fa-tablet fa-2x" v-if="scope.row.browser && scope.row.browser.includes('iPad')" v-b-popover.hover.top="'iPad'"></i>
          </template>
        </el-table-column>
        <el-table-column
          prop="user_ip"
          label="IP Address"
          :min-width="30">
          <template slot-scope="scope">
            {{scope.row.user_ip !== null ? scope.row.user_ip : '' }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import User from '@/scripts/objects/user'
import Account from '@/scripts/objects/account'
import { humanDateTimeSeconds } from '@/scripts/helpers/date.helpers'

export default {
  props: ['visible'],
  data () {
    return {
      users: [],
      selectedUser: null,
      userLogs: [],
      loading: false,
      loadMoreRows: true,
      loadPosition: 0,
      done: false
    }
  },
  methods: {
    humanDateTimeSeconds,
    async handleScroll () {
      if (this.selectedUser && this.selectedUser !== '' && !this.loading && !this.done) {
        this.loading = true
        const {foundLogs, getMore, loadPosition} = await User.getAuditLogsByUser(this.selectedUser, this.loadPosition)
        if (foundLogs) {
          this.userLogs.push(...foundLogs)
          this.loadPosition = loadPosition || 0
          this.done = !getMore || false
        }
        this.loading = false
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    async visible(val) {
      if (val) {
        const currentUser = this.$store.state.user
        // go get auditable users
        if (!this.users.length) {
          const { data } = await Account.getAllUsersForAccountWithoutShops(currentUser.account_id)
          this.users = data
          this.selectedUser = parseInt(currentUser.id)
        }
      }
    },
    async selectedUser (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.done = false
        this.userLogs = []
        this.loadPosition = 0
        this.handleScroll()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .eventChip {
    border-radius: 5px;
    color: white;
    display: inline-block;
    padding: 0px 1px 0px 1px;
  }
  .login {
    background-color: #41b855;
  }
  .logout {
    background-color: #4153b8;
  }
  .bump {
    background-color: #b84141;
  }
  .userAuditTable {
    &::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
      // margin-left: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(117, 117, 117);
    }
  }
</style>