<template>
  <div class="select-data-row">
    <div class="select-data-row-form" v-if="adding">
      <gb-form
        :schema="schema"
        v-model="newValue"
        autoFocus
        ref="form"
        @focusout="onFocusout"
      />
      <md-circle-button
        icon="clear md-18"
        size="small"
        filled
        @click="adding = false"
        outline
        class="select-data-row-button-delete"
      />
    </div>
    <gb-data-row
      v-bind="$attrs"
      :loading="loading || creating"
      :value="value"
      v-else
    >
      <template slot="extra" v-if="!disabled">
        <el-dropdown trigger="click" @command="onCommand">
          <el-dropdown-menu slot="dropdown">
            <el-scrollbar class="dropdown-scroll" wrap-class="dropdown-wrap">
              <el-dropdown-item
                v-for="item in select.options"
                :key="labelKey ? item[labelKey] : item"
                :command="item"
                class="select-data-row-option"
                :class="{
                  'option-selected': labelKey
                    ? item[labelKey] === $attrs.value[labelKey]
                    : item === $attrs.value,
                }"
              >
                {{ labelKey ? item[labelKey] : item }}
              </el-dropdown-item>
              <el-dropdown-item
                class="select-data-row-action select-data-row-extra-item"
                command="extra"
                v-if="select.extra"
              >
                {{ select.extra.label }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="!select.fixedOptions"
                class="select-data-row-action select-data-row-add-new-item"
                command="new"
              >
                <i class="material-icons md-add md-18" />
                Add New
              </el-dropdown-item>
            </el-scrollbar>
          </el-dropdown-menu>
          <md-circle-button
            icon="arrow_drop_down md-24"
            size="small"
            class="select-data-row-button"
          />
        </el-dropdown>
        <template v-if="($attrs.value && $attrs.value.id) || ($attrs.value && $attrs.overrideClearable)">
          <md-circle-button
            icon="clear md-18"
            size="small"
            outline
            danger
            class="select-data-row-button-delete"
            @click="select.clear"
          />
          <md-circle-button
            v-if="select.edit"
            icon="edit md-18"
            size="small"
            primary
            class="select-data-row-button-delete"
            @click="select.edit"
          />
        </template>
      </template>
    </gb-data-row>
  </div>
</template>

<script>
export default {
  props: ['select', 'loading', 'disabled', 'propValue'],
  data() {
    return {
      adding: false,
      newValue: {},
      creating: false
    }
  },
  computed: {
    options() {
      return this.select?.options || []
    },
    labelKey() {
      return this.select?.labelKey
    },
    schema() {
      return this.select?.form || []
    },
    value() {
      return this.propValue || this.$attrs.value
    }
  },
  methods: {
    onCommand(command) {
      if (command === 'new') {
        if (this.$listeners.add) {
          this.$listeners.add()
        } else {
          this.adding = true
        }
      } else if (command === 'extra') {
        this.select.extra.command()
      } else {
        this.select.select(command)
      }
    },
    async onFocusout(valid) {
      if (valid) {
        this.creating = true
        this.adding = false
        await this.select.callback(this.newValue)
        this.creating = false
        this.newValue = {}
      }
    }
  }
}
</script>

<style lang="scss">
.select-data-row {
  .md-circle-button {
    margin-top: -3px;
  }
  .select-data-row-button-delete {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
  }
  .data-row-value:not(:hover) {
    .select-data-row-button-delete {
      opacity: 0;
    }
  }
  .select-data-row-form {
    position: relative;
    .select-data-row-button-delete {
      position: absolute;
      top: -10px;
      right: -10px;
    }
  }
}
</style>

<style scoped>
.select-data-row >>> .data-row-formatted-value {
  min-width: 75px;
}
.dropdown-scroll {
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 15px;
}
.dropdown-scroll >>> .dropdown-wrap {
  overflow-x: hidden;
}
</style>

<style lang="scss">
.select-data-row-action {
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  border-top: 1px solid #ebeef5;
  align-items: center;
  font-size: 12px;
  padding: 10px 20px;
  background: #fff;
  line-height: 18px;
}
.select-data-row-add-new-item {
  color: #409eff;
}
.select-data-row-add-extra-item {
  color: $grayscale-7;
}
.select-data-row-option {
  display: flex;
  align-items: center;
  &.option-selected {
    color: #409eff;
    font-weight: 600;
  }
}
</style>
