<template>
  <div class="skeleton-text">
    <div :class="{ [size]: true }" :style="{ height: `${height}px` }"></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: () => 'medium'
    },
    height: {
      type: Number,
      default: () => 11
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes skeletonAnimation {
  0% {
    background: #eee;
  }

  40%,
  60% {
    background: #ccc;
  }

  100% {
    background: #eee;
  }
}
.skeleton-text {
  padding: 5px 0;
  width: 100%;
  > * {
    height: 11px;
    animation: skeletonAnimation 1s infinite;
    border-radius: 3px;
  }
  .medium {
    width: 50%;
  }
  .small {
    width: 25%;
  }
  .large {
    width: 75%;
  }
  .full {
    width: 100%;
  }
}
</style>
