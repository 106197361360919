<template>
  <el-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @select="$emit('change', $event)"
    :fetch-suggestions="filter"
    select-when-unmatched
    highlight-first-item
    ref="ref"
    class="gb-email-input"
    :placeholder="placeholder ? placeholder : ''"
  />
</template>

<script>
import * as R from 'ramda'

const domains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'cox.net',
  'msn.com',
  'icloud.com',
  'live.com',
  'outlook.com',
  'safelite.com',
  'comcast.net',
  'me.com',
  'ymail.com',
  'sbcglobal.net',
  'att.net',
  'q.com'
]

export default {
  props: {
    value: String,
    placeholder: {}
  },
  computed: {},
  methods: {
    filter(v, cb) {
      const options =
        v?.split && v.includes('@')
          ? R.pipe(
            R.map(R.concat(`${v.split('@')[0]}@`)),
            R.filter(R.allPass([R.startsWith(v), R.complement(R.equals(v))])),
            R.map(R.objOf('value'))
          )(domains)
          : []
      cb(options)
    }
  }
}
</script>
<style lang="scss" scoped>
.gb-email-input {
  width: 100%;
}
</style>
