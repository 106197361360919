<template>
  <el-timeline v-if="paymentHistory.length > 0" style="margin: 10px 0 0 100px;">
      <el-timeline-item
        v-for="(activity, index) in paymentHistory"
        :key="index"
        :icon="activity.icon"
        :type="activity.type"
        :size="activity.size"
      >
        {{ activity.name }}
        <div class="timestamp">
          <span class="username">{{activity.username ? '@' + activity.username + ' ' : ''}}</span>{{activity.timestamp}}
        </div>
      </el-timeline-item>
    </el-timeline>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'

export default {
  methods: {
    getHistory: call('twilio/textMessages/jobPayment/getHistory')
  },
  computed: {
    jobId: get('twilio/textMessages/jobPayment/jobId'),
    paymentHistory: get('twilio/textMessages/jobPayment/formattedHistory')
  },
  mounted() {
    this.getHistory(this.jobId)
  },
  watch: {
    isActive (v) {
      console.log('initialize', v)
      if (v) {
        this.initialize()
      }
    }
  }
}
</script>

<style lang="scss">
.timestamp {
    color: #909399;
    line-height: 1;
    font-size: 13px;
    .username {
      font-weight: 700!important;
      color: rgba(156,163,175,1)!important;
    }
}
</style>