<template>
  <div>
    <el-checkbox
      v-model="archived"
      label="Show archived ?"
      class="t-ml-4 t-normal-case"
      border
    ></el-checkbox>
  </div>
</template>

<script>
export default {
  computed: {
    archived: {
      get: function () {
        return this.$store.state.glassbillerLeads.filters.archived
      },
      set: function (value) {
        this.$store.commit('glassbillerLeads/setFilterArchived', value)
      }
    }
  },
  created () {
    this.getLeads()
  },
  watch: {
    archived: function () {
      this.getLeads()
    }
  },
  methods: {
    getLeads: function () {
      this.$store.dispatch('glassbillerLeads/get')
    }
  }
}
</script>
