var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        },
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.handleScroll,
          expression: "handleScroll"
        }
      ],
      staticClass: "rejected-table",
      attrs: {
        stripe: "",
        data: _vm.data,
        "infinite-scroll-disabled": !_vm.getMoreData,
        "infinite-scroll-distance": 0
      },
      on: { "sort-change": _vm.sortChange }
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "id",
          label: "Job Id",
          sortable: "custom",
          "class-name": "job-id-column"
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Invoice #" },
                      model: {
                        value: _vm.idFilter,
                        callback: function($$v) {
                          _vm.idFilter = $$v
                        },
                        expression: "idFilter"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "/jobs/" + scope.row.id, target: "_blank" }
                    },
                    [_vm._v(_vm._s(scope.row.id))]
                  )
                ]),
                _c(
                  "gb-tag",
                  {
                    staticStyle: { display: "inline-block" },
                    attrs: { hex: _vm.statusObject(scope.row.job_status).color }
                  },
                  [_vm._v(_vm._s(_vm.statusObject(scope.row.job_status).label))]
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "referral", label: "Referral", sortable: "custom" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Referral #" },
                      model: {
                        value: _vm.referralFilter,
                        callback: function($$v) {
                          _vm.referralFilter = $$v
                        },
                        expression: "referralFilter"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "customer_name", label: "Customer", sortable: "custom" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Customer" },
                      model: {
                        value: _vm.customerNameFilter,
                        callback: function($$v) {
                          _vm.customerNameFilter = $$v
                        },
                        expression: "customerNameFilter"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "amount", label: "Amount", sortable: "custom" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Amount" },
                      model: {
                        value: _vm.amountFilter,
                        callback: function($$v) {
                          _vm.amountFilter = $$v
                        },
                        expression: "amountFilter"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: {
          prop: "trading_partner",
          label: "Trading Partner",
          sortable: "custom"
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Trading Partner" },
                      model: {
                        value: _vm.tradingPartnerFilter,
                        callback: function($$v) {
                          _vm.tradingPartnerFilter = $$v
                        },
                        expression: "tradingPartnerFilter"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "tags", label: "Tags" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "el-dropdown",
                  { staticClass: "testing", on: { command: _vm.addTag } },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "rejectedDialogActionButton-sm label label-gray label-sm",
                        staticStyle: { "margin-left": "5px" },
                        attrs: { href: "javascript:void(0)" }
                      },
                      [_vm._v(" Add + ")]
                    ),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "rejected-dropdown-menu",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown"
                      },
                      _vm._l(_vm.shopTags, function(tag) {
                        return _c("tag-dropdown-item", {
                          key: tag.id,
                          attrs: {
                            tag: tag,
                            jobId: scope.row.id,
                            jobtags: scope.row.tags
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                scope.row.tags
                  ? _c(
                      "div",
                      _vm._l(scope.row.tags, function(tag, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "tag-sm",
                            style: {
                              color: _vm.blackOrWhite(tag.color),
                              background: tag.color
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(tag.text) + " "),
                            _c(
                              "span",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteTag({
                                      job_id: scope.row.id,
                                      job_tag_id: tag.id
                                    })
                                  }
                                }
                              },
                              [_vm._v("X")]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Status Details" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "a",
                  {
                    staticClass:
                      "rejectedDialogActionButton-sm label label-gray label-sm",
                    staticStyle: { "margin-left": "5px" },
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        return _vm.openEdiData(scope.row)
                      }
                    }
                  },
                  [_vm._v(" Show Details ")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }