<template>
  <el-dialog
    center
    append-to-body
    :close-on-click-modal="false"
    title="Text message templates"
    :visible.sync="visible"
    :before-close="handleClose"
    custom-class="text-confirmation-tamplate-dialog">
    <div class="row mb-3">
      <div class="col-12">
        <el-button
          type="primary"
          size="mini"
          class="pull-right"
          icon="el-icon-plus"
          @click="openForm()">Create</el-button>
      </div>
    </div>
    <el-table
      border
      style="width: 100%;"
      :data="templates"
      :loading="loading"
      >
      <el-table-column
        prop="name"
        label="Name">
      </el-table-column>
      <el-table-column
        prop="shop_name"
        label="Shop name">
      </el-table-column>
      <el-table-column
        prop="type_name"
        label="Type name">
      </el-table-column>
      <el-table-column
        label="Default">
        <template slot-scope="scope">
          {{ scope.row.default === 1 ? 'Yes' : 'No' }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="Actions"
        width="120">
        <template slot-scope="scope">
          <el-button
            class="mb-2 mr-2"
            size="mini"
            icon="el-icon-edit"
            @click="openForm(scope.row)"/>
          <el-button
            class="ml-0"
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"/>
        </template>
      </el-table-column>
    </el-table>
    <div class="row">
      <div class="col-md-12">
        <el-button
          class="pull-right mt-3"
          plain
          @click="handleClose">
          Close
        </el-button>
      </div>
    </div>
    <Form
      :template="template"
      :visible="formVisible"
      @close="closeForm"
      @refetchTemplates="refetchTemplates"
    />
  </el-dialog>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import Form from './Form'
import TextMessageTemplate from '@/scripts/objects/text_message_template'

export default {
  data: () => ({
    template: null,
    templates: [],
    formVisible: false,
    loading: false
  }),
  computed: {
    visible: sync('twilio/textMessages/templates/visible'),
    referral: get('twilio/textMessages/templates/referral')
  },
  watch: {
    visible: async function (value) {
      if (value) {
        this.getTemplates()
      }
    }
  },
  methods: {
    async getTemplates () {
      this.loading = true
      try {
        const {data} = await this.$unum.job().get('textMessageTemplate/allByType/sms')
        this.templates = data || []
      } catch {
        this.templates = []
      }
      this.loading = false
    },
    refetchTemplates () {
      this.$root.$emit('refetchTemplates')
      this.getTemplates()
    },
    openForm (template = null) {
      this.template = template
      this.visible = false
      this.formVisible = true
    },
    closeForm () {
      this.formVisible = false
      this.visible = true
    },
    async handleDelete (template) {
      let self = this
      try {
        await self.$messageBox.confirm(
          `This will permanently delete the template "${template.name}". Do you wish to continue?`,
          'Warning',
          {
            beforeClose: async (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                await TextMessageTemplate.destroy(template.id)
                await self.getTemplates()
                self.$message.success({
                  message: 'Template successfully deleted.',
                  type: 'success'
                })
                instance.confirmButtonLoading = false
              }
              done()
            }
          }
        )
      } catch {}
    },
    openTextMessageConfirmationDialog: call('twilio/textMessages/jobConfirmation/open'),
    openTextMessageInvoiceDialog: call('twilio/textMessages/jobInvoice/open'),
    openTextMessagePaymentDialog: call('twilio/textMessages/jobPayment/open'),
    openTextMessageSignatureDialog: call('twilio/textMessages/jobSignature/open'),
    setTextMessageTemplatesReferral: call('twilio/textMessages/templates/setReferral'),
    setSelectTemplateDialogVisible: call('twilio/textMessages/templates/setSelectTemplateDialogVisible'),
    handleClose () {
      this.visible = false
      if (this.referral === 'job-confirmation') {
        this.openTextMessageConfirmationDialog()
      }
      if (this.referral === 'job-invoice') {
        this.openTextMessageInvoiceDialog()
      }
      if (this.referral === 'job-payment') {
        this.openTextMessagePaymentDialog()
      }
      if (this.referral === 'job-signature') {
        this.openTextMessageSignatureDialog()
      }
      if (this.referral === 'select-template') {
        this.setSelectTemplateDialogVisible(true)
      }
      this.setTextMessageTemplatesReferral(null)
    }
  },
  components: {
    Form
  }
}
</script>

<style>
  .text-confirmation-tamplate-dialog {
    @apply t-font-sans;
  }
</style>
