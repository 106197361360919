var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consumer-vehicles" },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.vehicles || [], size: "mini", border: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Vehicle" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.year) + " ")]),
                    _c("span", [_vm._v(_vm._s(scope.row.make) + " ")]),
                    _c("span", [_vm._v(_vm._s(scope.row.model) + " ")]),
                    _c("span", [_vm._v(_vm._s(scope.row.sub_model) + " ")]),
                    _c("br"),
                    _c("span", { staticClass: "subtitle" }, [
                      _vm._v(_vm._s(scope.row.style) + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "vin", label: "VIN" } }),
          _c("el-table-column", {
            attrs: { width: "95px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("md-circle-button", {
                      attrs: {
                        icon: "delete",
                        outline: "",
                        size: "normal",
                        danger: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.removeVehicle(row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }