<template>
  <el-dialog
    width="600px"
    append-to-body
    :visible="visible"
    :before-close="close"
    custom-class="shopping-cart-dialog">
    <span slot="title" class="t-text-xl t-text-gray-800">Checkout</span>
    <div class="t-px-10 t-pb-8">
      <div>
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-shopping-cart-full t-mr-3"></i>
            <span class="t-font-normal">Items</span>
          </span>
        </el-divider>
        <div
          v-for="item in items"
          :key="item.id"
          class="t-flex t-rounded-md t-shadow t-px-3 t-py-2 t-mb-3 t-bg-gray-100"
        >
          <div class="t-flex-grow">
            <div class="t-font-medium t-text-gray-800 t-text-base">
              {{ item.itemNumber }}
            </div>
            <div>{{ item.itemDescription }}</div>
          </div>
          <div class="t-flex t-text-base">
            <div>{{ item.quantity }}</div>
            <div class="t-pl-3 t-pr-2">x</div>
            <div class="t-font-medium t-text-gray-700">
              ${{ Math.round(item.itemPrice * 100) / 100 }}
            </div>
          </div>
        </div>
        <div
          class="t-flex t-items-end t-justify-end t-mt-4 t-mb-5 t-mr-3">
          <span class="t-pr-4 t-text-base">Total:</span>
          <span class="t-text-lg t-font-medium t-text-gray-700">
            ${{ orderTotal }}
          </span>
        </div>
      </div>

      <div class="t-pb-2">
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-coordinate t-mr-3"></i>
            <span class="t-font-normal">Shipping</span>
          </span>
        </el-divider>
        <div v-if="fetchingTerms" class="t-text-2xl t-text-center">
          <i class="el-icon-loading"/>
        </div>
        <div v-else>
          <el-alert
            v-if="form.terms_code === 'N/A' || ship_via.length === 0"
            title="No shipping options available"
            type="warning"
            :closable="false"
            center>
          </el-alert>
          <div v-else>
            <div v-for="(s, index) in ship_via" :key="index">
              <el-radio
                v-model="form.ship_via_code"
                :label="s.ship_via_code"
                class="t-normal-case t-px-3 t-py-2 t-mb-3 t-font-normal t-flex
                      t-items-center t-rounded-md t-shadow t-bg-gray-100"
              >
                <div class="t-mb-1 t-text-gray-800">
                  {{ s.ship_via_descriptions }}
                </div>
              </el-radio>
            </div>
          </div>
        </div>
      </div>

      <div class="t-pb-2">
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-coordinate t-mr-3"></i>
            <span class="t-font-normal">Order summary</span>
          </span>
        </el-divider>
        <div v-if="addingToCart" class="t-text-2xl t-text-center">
          <i class="el-icon-loading"/>
        </div>
        <div v-else>
          <div v-if="orderSummary">
            <el-alert
              v-if="orderSummary.response_code === '1'"
              :title="orderSummary.response_message"
              type="warning"
              :closable="false"
              center>
            </el-alert>
            <div
              v-else
              class="t-flex">
              <div class="t-w-40 t-text-right t-text-gray-600 t-pr-3">
                <div>Ship to name</div>
                <div>Ship to address</div>
                <div>Bill to name</div>
                <div>Bill to address</div>
                <div>Shipping cost</div>
                <div>Tax</div>
                <div>Total</div>
              </div>
              <div class="t-text-gray-700">
                <div>{{ orderSummary.ship_to_name }}</div>
                <div>{{ orderSummary.ship_to_address }}</div>
                <div>{{ orderSummary.bill_to_name }}</div>
                <div>{{ orderSummary.bill_to_address }}</div>
                <div>{{ orderSummary.shipping_cost }}</div>
                <div>{{ orderSummary.tax }}</div>
                <div>{{ orderSummary.order_sub_total }}</div>
              </div>
            </div>
          </div>
          <div v-else class="t-text-center">Select shipping method</div>
        </div>
      </div>

      <div>
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-chat-line-square t-mr-3"></i>
            <span class="t-font-normal">Comments</span>
          </span>
        </el-divider>
        <el-input
          type="textarea"
          :rows="3"
          placeholder="Enter your comments"
          v-model="form.comment"
        />
      </div>
    </div>
    <div class="t-px-10 t-py-5 t-text-right t-bg-gray-100 t-shadow">
      <el-button
        @click="submit"
        type="primary"
        size="medium"
        class="t-bg-blue-500 hover:t-bg-blue-400"
        v-loading.fullscreen.lock="submitting"
        :class="{ 't-opacity-50': !form.terms_code || !form.ship_via_code || !orderSummary }"
        :disabled="!form.terms_code || !form.ship_via_code || !orderSummary">
        Place order
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import moment from 'moment'
import {
  shoppingCartActions,
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'

export default {
  data: () => ({
    submitting: false,
    fetchingTerms: false,
    addingToCart: false,
    ship_via: [],
    accountNumber: null,
    form: {
      requested_ship_date: null,
      terms_code: null,
      terms_description: null,
      ship_via_code: null,
      comment: ''
    },
    orderSummary: null
  }),
  computed: {
    visible () {
      return this.$store.state.shoppingCart.items.americanCheckoutIsOpen
    },
    shopId () {
      return this.$store.state.shoppingCart.shopId
    },
    allItems () {
      return this.$store.state.shoppingCart.items.data
    },
    selectedItemIds () {
      return this.$store.state.shoppingCart.items.selectedItemIds
    },
    items () {
      return this.allItems.filter(i => this.selectedItemIds.includes(i.id))
    },
    orderTotal () {
      let total = 0
      this.items.forEach(i => {
        total += parseFloat(i.itemPrice) * i.quantity
      })
      return Math.round(total * 100) / 100
    },
    selectedTab: {
      get () {
        return this.$store.state.shoppingCart.selectedTab
      },
      set (value) {
        return this.$store.commit(shoppingCartMutations.setSelectedTab, value)
      }
    }
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.$store.state.shoppingCart.items.data.some(i => {
          if (i.id === this.$store.state.shoppingCart.items.selectedItemIds[0]) {
            this.accountNumber = i.accountNumber
          }
        })
        this.getShipTerms()
        this.form.requested_ship_date = moment().format('YYYYMMDD')
      } else {
        this.ship_via = []
        this.form.requested_ship_date = null
        this.form.terms_code = null
        this.form.terms_description = null
        this.form.ship_via_code = null
        this.form.comment = ''
        this.orderSummary = null
      }
    },
    'form.ship_via_code': function (value) {
      if (value) {
        this.addToAmericanCart()
      }
    }
  },
  methods: {
    close () {
      if (!this.submitting) {
        return this.$store.dispatch(shoppingCartActions.closeAmericanCheckout)
      }
    },
    async getShipTerms () {
      try {
        this.fetchingTerms = true
        const res = await this.$store.dispatch(
          shoppingCartActions.getAmericanShipTerms,
          {
            shopId: this.shopId,
            accountNumber: this.accountNumber
          }
        )
        this.form.terms_code = res.terms_code
        this.form.terms_description = res.terms_description
        this.ship_via = res.ship_via
        this.fetchingTerms = false
      } catch (error) {
        if (error?.response?.data?.error) {
          this.$message.error(error.response?.data?.error)
        } else {
          this.$message.error('Can\'t get ship to addresses. Check American credentials and try again.')
        }
      }
    },
    async addToAmericanCart () {
      this.addingToCart = true
      this.orderSummary = await this.$store.dispatch(
        shoppingCartActions.addToAmericanCart,
        {
          account_number: this.accountNumber,
          requested_ship_date: this.form.requested_ship_date,
          ship_via_code: this.form.ship_via_code,
          terms_code: this.form.terms_code,
          orders: this.items.map(i => {
            return {
              order_quantity: i.quantity,
              item_number: i.itemNumber,
              item_prefix: i.itemPrefix
            }
          })
        }
      )
      this.addingToCart = false
    },
    async submit () {
      const americanOrder = {
        accountNumber: this.accountNumber,
        token: this.orderSummary.token,
        shopId: this.shopId,
        vendor: 'american',
        cartItemIds: this.selectedItemIds,
        ...this.form,
        ship_to_name: this.orderSummary.ship_to_name,
        ship_to_address: this.orderSummary.ship_to_address
      }
      console.log('American Order:', americanOrder)
      // if (!this.form.terms_code || !this.form.ship_via_code || !this.orderSummary) {
      //   this.$message.warning('Please select a shipping method')
      // } else {
      //   try {
      //     this.submitting = true
      //     const res = await this.$store.dispatch(shoppingCartActions.placeOrder, {
      //       accountNumber: this.accountNumber,
      //       token: this.orderSummary.token,
      //       shopId: this.shopId,
      //       vendor: 'american',
      //       cartItemIds: this.selectedItemIds,
      //       ...this.form,
      //       ship_to_name: this.orderSummary.ship_to_name,
      //       ship_to_address: this.orderSummary.ship_to_address
      //     })
      //     this.submitting = false
      //     if (res.response_Code === '1') {
      //       this.$message.warning(res.response_Message)
      //     } else {
      //       this.selectedTab = 'orders'
      //       this.$store.commit(shoppingCartMutations.orders.setSelectedVendor, 'american')
      //       this.close()
      //       this.$message.success('Your order was placed')
      //       this.$root.$emit('purchase-order::updated')
      //       this.getItemsCount()
      //     }
      //   } catch (err) {
      //     this.submitting = false
      //   }
      // }
    }
  }
}
</script>
