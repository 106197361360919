<template>
  <div class="t-flex">
    <Button
      :type="type"
      :loading="loading"
      :total="quantity"
      :price="price"
      @clicked="openDialog"
    />
    <component
      v-if="data && dialogData"
      v-bind="dialogData"
      :is="dialogComponent"
      :standalone="true"
      :shopId="shopId"
      :warehouse="warehouse"
      @close="open = false"
      @add="addPart"
      @addPart="addPart"
      @recheckVendor="reFetchData"
      @refresh-data="$emit('refresh-data')"
    />
  </div>
</template>

<script>
import * as R from 'ramda'
import API from '@/services/Api'
import GlaxisDialog from '@/components/PartSearch/Glaxis/Dialog.vue'
import PilkingtonDialog from '@/components/PartSearch/Pilkington/Dialog.vue'
import AmericanDialog from '@/components/PartSearch/American/Dialog.vue'
import NielsenMollerDialog from '@/components/PartSearch/NielsenMoller/Dialog.vue'
import Button from './Button.vue'
import { applyPricelevelToPart } from '@/scripts/helpers/part.helpers'
import { nagsToPart } from '@/scripts/helpers/nags.helpers'
import { calculateDiscount, round } from '@/scripts/helpers/totals.helpers'

export default {
  components: {
    GlaxisDialog,
    PilkingtonDialog,
    AmericanDialog,
    NielsenMollerDialog,
    Button
  },
  props: ['url', 'color', 'request', 'keys', 'type', 'part', 'shopId', 'warehouse'],
  data: () => ({
    loading: false,
    data: null,
    error: false,
    open: false,
    vendorDialogs: {
      agd: NielsenMollerDialog,
      american: AmericanDialog,
      igc: GlaxisDialog,
      mygrant: GlaxisDialog,
      nm: NielsenMollerDialog,
      pgw: GlaxisDialog,
      pilkington: PilkingtonDialog
    },
    vendorQuantityKeys: {
      agd: 'QuantityAvailable',
      igc: 'quantity',
      mygrant: 'quantity',
      nm: 'QuantityAvailable',
      pgw: 'quantity',
      pilkington: 'quantityAvailable'
    }
  }),
  computed: {
    item: function() {
      return this.data?.[0]
    },
    nagsPart: function() {
      return { detail: { dsc: this.part.description, partno: this.part.part_number } }
    },
    price: function() {
      const price = this.item?.[this.keys?.price]
      return price ? (+price).toFixed(2) : null
    },
    adjustedPrice: function() {
      return applyPricelevelToPart(this)
    },
    dialogComponent: function() {
      return this.vendorDialogs[this.type]
    },
    quantity: function() {
      const key = this.vendorQuantityKeys[this.type]

      return this.item?.[key]
    },
    dialogData: function() {
      return {
        vendor: this.type,
        results: this.data,
        priceFromLevels: this.price,
        nagsPart: this.nagsPart,
        visible: this.open,
        defaultCredentials: ['agd', 'nm', 'pilkington'].includes(this.type) ? this.request.username : this.request.accountNumber,
        jobId: this.warehouse ? this.part.job_id : this.$job.job.id,
        loading: this.loading
      }
    }
  },
  mounted: function() {
    this.getData(this.request)
  },
  methods: {
    getData: async function(request) {
      try {
        this.loading = true
        const { data } = await API.post(this.url, request)
        if (!data) {
          this.data = []
          this.$message.warning(`${request.vendor} did not respond.`)
        } else {
          this.data = data
        }
        this.data = data
      } catch (error) {
        this.loading = false
        this.error = true
      } finally {
        this.loading = false
      }
    },
    reFetchData: function(data) {
      this.getData({
        ...this.request,
        ...data
      })
    },
    openDialog: function() {
      this.open = true
    },
    addPart: function(relatedItem) {
      const { materials, labor, adhesive, flat } = this.adjustedPrice
      const parts = [
        nagsToPart('is_glass', {
          materials_price: materials,
          labor_price: labor,
          total_price: round(materials + labor),
          discount: calculateDiscount(materials, +this.part.price),
          cost: +this.price,
          glaxis_order_item_id: relatedItem?.vendor === 'glaxis'
            ? relatedItem.cartItemId
            : null,
          pilkington_order_item_id: relatedItem?.vendor === 'pilkington'
            ? relatedItem.cartItemId
            : null,
          american_order_item_id: relatedItem?.vendor === 'american'
            ? relatedItem.cartItemId
            : null,
          nielsen_moller_order_item_id: relatedItem?.vendor === 'nm'
            ? relatedItem.cartItemId
            : null
        })(this.part)
      ]

      const adhPart = this.$nags?.hardware?.find(
        R.propEq('typeId', 'adhesive')
      )?.variations?.[0]

      if (adhesive && adhPart) {
        parts.push(
          nagsToPart('is_adhesive_kit_part', {
            materials_price: flat ? adhesive : adhesive / +this.adhesive_qty,
            total_price: adhesive,
            discount: calculateDiscount(
              adhesive / +this.adhesive_qty,
              +adhPart.price
            ),
            flat: +flat
          })(adhPart)
        )
      }

      this.$job.addParts(parts)
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
.grow-enter-active {
  transition: all 0.2s ease;
}

.grow-enter {
  font-size: 0;
  margin-left: 0;
}
</style>
