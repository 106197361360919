var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "autocomplete",
      class: { open: _vm.openSuggestion },
      staticStyle: { position: "relative" }
    },
    [
      !_vm.showSpinner
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.internalSearchValue,
                expression: "internalSearchValue"
              }
            ],
            ref: _vm.inInputRef,
            class: _vm.classes,
            style: _vm.inStyle,
            attrs: {
              type: "text",
              placeholder: _vm.placeholder,
              disabled: _vm.inDisabled,
              readonly: _vm.readonly
            },
            domProps: { value: _vm.internalSearchValue },
            on: {
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.enter($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown"
                    ])
                  ) {
                    return null
                  }
                  return _vm.down($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp"
                    ])
                  ) {
                    return null
                  }
                  return _vm.up($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                  ) {
                    return null
                  }
                  return _vm.tab($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                      "Backspace",
                      "Delete",
                      "Del"
                    ])
                  ) {
                    return null
                  }
                  return _vm.deleteKeyPressed($event)
                }
              ],
              focus: _vm.focused,
              blur: _vm.blurred,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.internalSearchValue = $event.target.value
              }
            }
          })
        : _vm._e(),
      _vm.showSpinner
        ? _c("InputWithSpinner", {
            ref: _vm.inInputRef,
            style: _vm.inStyle,
            attrs: {
              inClass: _vm.inClass,
              type: "text",
              placeholder: _vm.placeholder,
              disabled: _vm.inDisabled,
              searchValue: _vm.internalSearchValue,
              loading: _vm.loading
            },
            on: {
              "update:searchValue": function($event) {
                _vm.internalSearchValue = $event
              },
              "update:search-value": function($event) {
                _vm.internalSearchValue = $event
              },
              enter: _vm.enter,
              down: _vm.down,
              up: _vm.up,
              tab: _vm.tab,
              delete: _vm.deleteKeyPressed,
              focus: _vm.focused
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open && _vm.matches.length > 0,
              expression: "open && matches.length > 0"
            }
          ],
          ref: "autocompleteResults",
          staticClass: "autocomplete-results",
          style: {
            "max-height": _vm.autoCompleteHeight + "px",
            "margin-bottom": "15px"
          }
        },
        [
          _c(
            "ul",
            { staticStyle: { width: "100%" } },
            [
              _vm._l(_vm.matches, function(suggestion, index) {
                return [
                  suggestion["breakBefore"]
                    ? _c("hr", { key: index + "-spacerBefore" })
                    : _vm._e(),
                  _c(
                    "li",
                    {
                      key: index,
                      ref: "autoComplete:" + index,
                      refInFor: true,
                      staticClass: "autocomplete-result",
                      class: { "is-active": _vm.isActive(index) },
                      style: suggestion["style"] ? suggestion["style"] : "",
                      attrs: { tabindex: "-1" },
                      on: {
                        mouseover: function($event) {
                          return _vm.mouseOver(index)
                        },
                        click: function($event) {
                          return _vm.suggestionClick(index)
                        }
                      }
                    },
                    [
                      _vm._t(
                        "autocompleteItem",
                        [
                          _c(
                            "a",
                            {
                              class: { "white-text": _vm.isActive(index) },
                              attrs: { href: "javascript:void(0)" }
                            },
                            [_vm._v(_vm._s(suggestion["name"]))]
                          )
                        ],
                        {
                          suggestion: suggestion,
                          index: index,
                          active: _vm.isActive(index)
                        }
                      )
                    ],
                    2
                  ),
                  suggestion["breakAfter"]
                    ? _c("hr", { key: index + "-spacerAfter" })
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }