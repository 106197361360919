import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class AuditLog extends ObjectTracker {
  id = -1
  event = ''
  description = ''
  event_id = null
  job_id = null
  salesidejob_id = null
  status = null
  username = ''
  user_id = null
  user_ip = null
  admin = null
  created = null
  ref_table = ''
  ref_id = null
  broswer = null
  shop_id = null
  internal_note = null
  user_agent = null

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAuditLogs (jobId, salesideJobId, filter = '', callback) {
    var logs = []
    var queryString = ''
    if (salesideJobId) {
      queryString += `?salesideJobId=${salesideJobId}`
      if (filter) {
        queryString += `&filter=${filter}`
      }
    }
    if (filter) {
      queryString += `?filter=${filter}`
    }
    Api({ url: `/api/auditlog/${jobId}${queryString.length > 0 ? queryString : ''}` }).then(res => {
      res.data.forEach(function (val, index) {
        logs.push(new AuditLog(val))
      })
      callback(logs)
    })
  }

  static async getAuditLogsInfinite (jobId, salesideJobId, loadPosition) {
    const { data } = await Api.get(`api/auditlogInfinite/${jobId}`, {params: {salesideJobId, loadPosition}})
    return data
  }

  static createAuditLog (jobId, event, object = {}) {
    // Handling so far: EDIUNLOCKED, GBSIGNCOLLECTED, GBSIGNSENT, TSCOMPLETED, TSSENT, TSMPICADDED, SSMPICADDED
    return Api.post(`/api/auditlog/${jobId}/${event}`, object)
  }

  static createSmsSignAuditLog (jobId, event, object = {}) {
    // Handling so far: EDIUNLOCKED, GBSIGNCOLLECTED, GBSIGNSENT, TSCOMPLETED, TSSENT, TSMPICADDED, SSMPICADDED
    return Api.post(`/api/web/auditlog/${jobId}/${event}`, object)
  }

  static createV2AuditLog (logObj) {
    return Api.post(`/api/auditlogv2/logout`, logObj)
  }
}
