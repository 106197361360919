<template>
<li class="event-item" :class="!permitted ? 'event-item-disabled' : ''">

  <div class="overlay d-flex justify-content-end" :class="permitted ? 'invisible' : 'visible'">
    <div class="overlay-lock">
      <el-tooltip
        effect="light"
        :content="'Permission denied. Request permission from your manager.'"
        placement="left"
      >
        <div class="gb-icon-gray-button">
          <i class="md-lock material-icons-outline"/>
        </div>
      </el-tooltip>
    </div>
  </div>

  <el-form
    ref="formRef"
    :model="form"
    :rules="formRules"
    :disabled="!permitted"
  >
    <div class="d-flex flex-wrap event-item-content">

      <!-- INFO BLOCK -->
      <div class="d-flex flex-column info">
        <div class="d-flex header align-items-center">
          <div>
            <div class="icon-square-round" :class="backgroundClass">
              <i class="material-icons-round md-24" :class="iconClass" />
            </div>
          </div>
          <div class="title">{{title}}</div>
        </div>

        <div class="description text-break">{{description}}</div>
      </div>

      <!-- SHOPS LIMIT -->
      <div class="section-shops d-flex align-items-center">
        <el-form-item label="Shops Limit" prop="shop_ids">

          <el-select
            v-model="form.shop_ids"
            :value-key="'id'"
            filterable
            placeholder="All Shops"
            multiple
          >
            <el-option
              v-for="(shop, shop_index) in $store.state.shops"
              :key="shop_index"
              :label="shop.name"
              :value="shop.id"
            />
          </el-select>
        </el-form-item>
      </div>

      <!-- SWITCHERS -->
      <div class="section-switchers d-flex flex-wrap align-items-center">
        <div class="column-switch">
          <el-form-item label="Web" prop="web_enabled">
            <el-switch v-model="form.web_enabled" />
          </el-form-item>
        </div>

        <div class="column-switch">
          <el-form-item label="SMS" prop="sms_enabled">
            <el-switch v-model="form.sms_enabled" />
          </el-form-item>
        </div>

        <div class="column-switch">
          <el-form-item label="Email" prop="email_enabled">
            <el-switch v-model="form.email_enabled" />
          </el-form-item>
        </div>

        <div class="column-more d-flex align-items-end">
          <el-popover
            placement="top-end"
            width="369px"
            trigger="click"
            title="More Options"
            popper-class="popper-more"
          >

            <div slot="reference">
              <el-button
                class="d-flex align-items-center button-more"
                icon="md-more_vert material-icons-outline"
                size="mini"
              >More</el-button>
            </div>

            <div class="form-more d-flex flex-column">

              <!-- EVENT EXTRA OPTIONS -->
              <div class="form-more-web form-more-row d-flex">

                <!-- WEB OPTIONS -->
                <el-form-item label="Web Mode" prop="web_mode">
                  <el-select
                    v-model="form.web_mode"
                    :default-first-option="true"
                    :disabled="!form.web_enabled"
                    size="small"
                  >
                    <el-option :key='1' :value='1' label='Bell'>Bell</el-option>
                    <el-option :key='2' :value='2' label='Pop-Up'>Pop-Up</el-option>
                  </el-select>
                </el-form-item>

                <el-form-item prop="web_sound_enabled" class="d-flex align-items-end form-bell-item">
                  <el-tooltip
                    effect="light"
                    :content="form.web_sound_enabled ? 'Disable sound' : 'Enable sound'"
                    placement="top"
                  >
                    <el-checkbox v-model="form.web_sound_enabled" class="form-bell" label="mock">
                      <div
                        class="icon-active-wrapper d-flex flex-column justify-content-center"
                      >
                        <div class="gb-icon-light-blue-button" v-if="form.web_sound_enabled">
                          <i class="md-notifications material-icons"/>
                        </div>
                        <div class="gb-icon-gray-button" v-else>
                          <i class="md-notifications material-icons-outline"/>
                        </div>
                      </div>
                    </el-checkbox>
                  </el-tooltip>
                </el-form-item>
              </div>

              <el-divider />

              <!-- EXTRA PHONES -->
              <div class="form-more-sms">
                <el-form-item label="Extra Phones" prop="sms_phones">
                  <el-select
                    v-model="form.sms_phones"
                    placeholder="Add Phone #"
                    filterable
                    multiple
                    allow-create
                    :disabled="!form.sms_enabled"
                    :filter-method="renderPhone"
                    ref="phones"
                    @change="formatPhones"
                  />
                </el-form-item>

              </div>

              <el-divider />

              <!-- EXTRA EMAILS -->
              <div class="form-more-email">
                <el-form-item label="Extra Emails" prop="email_addresses">
                  <el-select
                    v-model="form.email_addresses"
                    placeholder="Add Email"
                    filterable
                    multiple
                    allow-create
                    :disabled="!form.email_enabled"
                  />
                </el-form-item>
              </div>

            </div>

          </el-popover>
        </div>
      </div>

    </div>
  </el-form>
</li>
</template>

<script>
import { mapActions } from 'vuex';
import {
  formatPhonePartial,
  validateEmailArray,
  validatePhoneArray
} from '@/store/modules/notifications-settings/helpers';
import { EventsStyles } from '@/store/modules/notifications-settings/events';

import cloneDeep from 'lodash.clonedeep';

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    permitted: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      // clone data so we will assign default values
      // at the same time we will omit modifying state from form directly
      form: cloneDeep(this.item),

      formRules: {
        sms_phones: [
          {
            type: 'array',
            validator: validatePhoneArray,
            trigger: 'change',
            message: 'Incorrect phone format',
            required: false,
          }
        ],
        email_addresses: [
          {
            type: 'array',
            validator: validateEmailArray,
            trigger: 'change',
            message: 'Incorrect e-mail format',
            required: false,
          }
        ]
      }
    }
  },

  computed: {
    backgroundClass() {
      return `t-bg-${EventsStyles[this.item.event_type]['color']}-100`;
    },

    iconClass() {
      const styles = EventsStyles[this.item.event_type];
      return `t-text-${styles['color']}-600 ${styles['icon']}`;
    }
  },

  created () {
    this.formatPhones();
  },

  watch: {
    form: {
      handler(event_settings) {

        let formEl = this.$refs.formRef;

        if (!formEl) {
          return;
        }

        formEl.validate((valid, fields) => {
          if (!valid) {
            return;
          }

          this.changeEventsSettings(cloneDeep(event_settings));
        });
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('notificationsSettings', ['changeEventsSettings']),

    checkAllowed(permission) {
      return this.$role.can(permission);
    },

    /**
     * Method to format phone in the popover block
     */
    renderPhone (query) {
      let phone = formatPhonePartial(query);
      this.$refs.phones.$refs.popper.$el.querySelector('.el-select-dropdown__item > span').innerText = phone;
    },

    /**
     * Method to format phones inside multiselect
     */
    formatPhones() {
      // run it on next tick so current phone would be added to the list
      setTimeout(() => {
        let phones = this.$refs.phones.$refs.tags.querySelectorAll('.el-select__tags-text');
        for (let phone of phones) {
          phone.innerHTML = formatPhonePartial(phone.innerHTML);
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.event-item.event-item-disabled {
  opacity: 0.4;
}
.event-item {
  min-height: 140px;
  border-bottom: 1px #D1D5DB solid;
  padding: 16px;
  position: relative;

  .overlay {
    padding: 16px;
    position: absolute; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1); /* Black background with opacity */
    z-index: 1000; /* Specify a stack order in case you're using a different order for other elements */
  }

  .event-item-content {
    column-gap: 32px;
    row-gap: 16px;

    .info {
      width: 250px;
      gap: 16px;
      color: black;

      .header {
        gap: 16px;

        .title {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .section-shops {
      width: 190px;
    }

    .section-switchers {
      column-gap: 40px;
      row-gap: 16px;

      .column-switch, .column-more {
        width: 70px;
        height: 64px;
      }

      .button-more {
        gap: 8px;
        border-radius: 6px;
      }
    }
  }
}

.event-icon-wrapper {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 6px;
}

// TEMPORARY STYLE FIX
.t-bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.t-bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.t-bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
</style>

<style lang="scss">
.form-more .el-divider {
  background-color: #F3F4F6;
  margin: 16px 0;
}

.section-shops, .section-switchers, .form-more {

  .el-form-item {
    margin-bottom: 0px;

    .el-form-item__label {
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      color: #9CA3AF;
      margin-bottom: 6px;
    }
    .el-form-item__content {
      line-height: 32px;

      .el-input__inner {
        border: 1px solid #D1D5DB;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        height: 40px;
      }

      .el-switch  {
        height: 42px;

        .el-switch__core {
          height: 24px;
          width: 44px !important;
          border-radius: 12px;
        }

        .el-switch__core:after {
          width: 20px;
          height: 20px;
        }
      }
      .el-switch.is-checked {
        .el-switch__core {
          border-color: #0075E4;
          background-color: #0075E4;
        }

        .el-switch__core::after {
          margin-left: -21px !important;
        }
      }
    }
  }
}

.popper-more {
  padding-bottom: 24px;

  .el-popover__title {
    margin-bottom: 16px;
  }

  .form-bell-item .el-form-item__content {
    line-height: 1;
    margin-left: 5px;
  }

  .form-more-web {
    line-height: 1;

    .el-select {
      width: 154px;
    }

    .el-checkbox__input {
      display: none;
    }
    .el-checkbox__label {
      padding: 0px;
    }
  }

  .form-more-email .el-select, .form-more-sms .el-select {
    width: 190px;
  }

  .el-input__inner {
    color: #666;
  }
}
</style>