var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        width: "400px",
        visible: _vm.isOpen,
        "before-close": _vm.handleClose,
        top: "18vh",
        "custom-class": "channel-members-dialog"
      }
    },
    [
      _c(
        "span",
        { staticClass: "t-text-lg", attrs: { slot: "title" }, slot: "title" },
        [
          _c("span", { staticClass: "t-mr-8 t-flex" }, [
            _c("span", { staticClass: "t-mr-2 t-flex-shrink-0" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.members.length) +
                  " member" +
                  _vm._s(_vm.members.length > 1 ? "s" : "") +
                  " in "
              )
            ]),
            _c("div", { staticClass: "t-truncate" }, [
              !_vm.activeChannel.isPrivate
                ? _c("span", [_vm._v("#")])
                : _c("i", { staticClass: "el-item-lock" }),
              _vm._v(" " + _vm._s(_vm.activeChannel.uniqueName) + " ")
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "t-h-full" },
        [
          _vm._l(_vm.members, function(i) {
            return _c(
              "div",
              {
                key: i.sid,
                staticClass: "t-flex t-items-center t-mb-2 t-text-lg"
              },
              [
                _c("i", { staticClass: "el-icon-user t-mr-2" }),
                _c("div", { staticClass: "t-text-gray-700 t-mr-3" }, [
                  _vm._v(" " + _vm._s(i.identity) + " ")
                ])
              ]
            )
          }),
          _vm.activeChannel.createdBy === _vm.username
            ? _c(
                "el-button",
                {
                  staticClass: "t-mt-4",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "medium",
                    plain: ""
                  },
                  on: { click: _vm.addUser }
                },
                [_vm._v(" Add users ")]
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }