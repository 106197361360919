import { gql } from 'apollo-boost'
import { SHOP_DATA_FRAGMENT } from '../fragments/shop.fragment'

export const SHOPS_QUERY = gql`
  query getShops {
    shops {
      id
      name
    }
  }
`

export const SHOPS_ORGANIZATION_QUERY = gql`
  query getShopsByOrganization($organization_id: ID!) {
    shopsByOrganization(organization_id: $organization_id) {
      id
      name
    }
  }
`

export const SHOP_QUERY = gql`
  query getShop($id: ID!) {
    shop(id: $id) {
      ...ShopDataFields
    }
  }
  ${SHOP_DATA_FRAGMENT}
`
