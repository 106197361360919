<template>
  <div
    class="tech-location-select"
    :style="{ display: 'flex', alignItems: 'center' }"
  >
    <el-select v-model="value" :style="{ width: '100%' }">
      <el-option
        v-if="tech.g_address"
        key="tech"
        label="Tech home"
        value="tech"
      />
      <el-option key="shop" label="Shop" value="shop" />
      <el-option-group label="Custom">
        <el-option
          v-for="loc in tech.route_tech_stops"
          :key="`custom|${loc.id}`"
          :value="`custom|${loc.id}`"
          :label="loc.name"
        />

        <el-option
          v-if="newTechStop"
          :key="`custom|${newTechStop.id}`"
          :value="`custom|${newTechStop.id}`"
          :label="newTechStop.name"
        />
      </el-option-group>
      <el-option key="create" value="create" label=" + Create new" />
    </el-select>
    <scheduler-route-tech-stop
      :open="value === 'create'"
      :hideIcon="true"
      @create="onCreate"
      :tech_id="tech.id"
    />
  </div>
</template>

<script>
import SchedulerRouteTechStop from '../SchedulerRouteTechStop'
export default {
  components: { SchedulerRouteTechStop },
  props: ['tech', 'prop', 'location'],
  data() {
    return {
      modal: false,
      newTechStop: null
    }
  },
  computed: {
    value: {
      get() {
        return this.location || this.tech[this.prop]
      },
      set(v) {
        this.$emit('change', v)
      }
    }
  },
  methods: {
    onCreate(techStop) {
      this.newTechStop = techStop
      this.value = `custom|${techStop.id}`
    }
  }
}
</script>
