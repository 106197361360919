import { sync } from 'vuex-pathify'
import { TextActionsMixin } from './text-actions.mixin'
import Job from '@/scripts/objects/job'
import { call } from 'vuex-pathify'

const MenuActionsMixin = {
  mixins: [TextActionsMixin],
  computed: {
    esign: sync('jobDetails/actionDialogs@esign'),
    pdf: sync('jobDetails/actionDialogs@pdf'),
    audit: sync('jobDetails/actionDialogs@audit'),
    email: sync('jobDetails/actionDialogs@email'),
    gbFormDialog: sync('jobDetails/actionDialogs@gbFormDialog'),
    fax: sync('jobDetails/actionDialogs@fax'),
    clone: sync('jobDetails/actionDialogs@clone'),
    // supplement: sync('jobDetails/actionDialogs@supplement'),
    edi_test: sync('jobDetails/actionDialogs@edi_test'),
    payment: sync('jobDetails/actionDialogs@payment')
  },
  methods: {
    openSave() {
      if (
        this.$job.job.insurance_fleet_po_required &&
        !this.$job.job.purchase_order_number
      ) {
        this.$message({
          showClose: true,
          message:
            'This Insurance/Fleet requires a PO Number be entered for the job. Please enter a PO Number to continue.',
          type: 'warning',
          duration: 6000
        })
      } else if (!this.$job.job.consumer_id) {
        this.$message({
          showClose: true,
          message: 'Please select or create a customer before saving the job.',
          type: 'warning',
          duration: 6000
        })
      } else {
        this.$emit('open-save')
      }
    },
    openEmail() {
      this.email = true
    },
    openFax() {
      this.fax = true
    },
    openTextInvoice() {
      this.setTextMessageInvoiceDocumentLink(null)
      this.setTextMessageInvoiceJobId(this.$job.job.id)
      this.setTextMessageInvoiceShopId(this.$job.job.shop_id)
      this.setTextMessageInvoiceJobStatus(this.$job.job.status)
      this.openTextMessageInvoice()
    },
    openTextSignature() {
      this.setTextMessageSignatureSignatureLink(null)
      this.setTextMessageSignatureJobId(this.$job.job.id)
      this.setTextMessageSignatureShopId(this.$job.job.shop_id)
      this.setTextMessageSignatureSalesideJobId(this.$job.job.salesidejob_id)
      this.openTextMessageSignature()
    },
    openSharePaymentDialog () {
      this.setTextMessagePaymentJobId(this.$job.job.id)
      this.setTextMessagePaymentShopId(this.$job.job.shop_id)
      this.setTextMessagePaymentBalanceDetails({
        total_deductible_after_payments: this.$job.job.total_deductible_after_payments,
        total_balance_after_payments: this.$job.job.total_balance_after_payments
      })
      this.setTextMessagePaymentConsumersWithJob(this.$job)
      // this.setTextMessageInvoiceJobStatus(this.job.status)
      this.openTextMessagePayment()
    },
    openPrint() {
      this.pdf = true
    },
    openESign() {
      this.esign = true
    },
    openClone() {
      this.clone = true
    },
    async createSupplement() {
      try {
        const { csrdefaults } = this.$store.state.user
        let defaultCsr = {}
        for (const csr of csrdefaults) {
          if (csr.shop_id === this.$job.job.shop_id) {
            defaultCsr = csr
            break
          }
        }
        const {data} = await this.$unum.job().post(`/data/supplement/${this.$job.job.id}`, { csr: defaultCsr })
        window.open('/jobs/' + data.id, '_blank')
      } catch (error) {
        this.$message({type: 'error', message: error.message})
      }
    },
    openGbForm() {
      this.gbFormDialog = true
    },
    async openWarranty() {
      try {
        const { data } = await this.$unum
          .job()
          .post(`/data/warranty/${this.$job.job.id}`)
        window.open('/jobs/' + data.id, '_blank')
      } catch (error) {
        this.$message({ type: 'error', message: error.message })
      }
    },
    async openRecal() {
      try {
        const { data } = await this.$unum
          .job()
          .post(`/data/recal/${this.$job.job.id}`)
        window.open('/jobs/' + data.id, '_blank')
      } catch (error) {
        this.$message({ type: 'error', message: error.message })
      }
    },
    async openCallBack() {
      try {
        const { data } = await this.$unum
          .job()
          .post(`/data/callBack/${this.$job.job.id}`)
        window.open('/jobs/' + data.id, '_blank')
      } catch (error) {
        this.$message({ type: 'error', message: error.message })
      }
    },
    async openRAndI() {
      try {
        const { data } = await this.$unum
          .job()
          .post(`/data/rAndI/${this.$job.job.id}`)
        window.open('/jobs/' + data.id, '_blank')
      } catch (error) {
        this.$message({ type: 'error', message: error.message })
      }
    },
    async openTint() {
      try {
        const { data } = await this.$unum
          .job()
          .post(`/data/tint/${this.$job.job.id}`)
        window.open('/jobs/' + data.id, '_blank')
      } catch (error) {
        this.$message({ type: 'error', message: error.message })
      }
    },
    async openPickUp() {
      try {
        const { data } = await this.$unum
          .job()
          .post(`/data/pickUp/${this.$job.job.id}`)
        window.open('/jobs/' + data.id, '_blank')
      } catch (error) {
        this.$message({ type: 'error', message: error.message })
      }
    },
    async openDropOff() {
      try {
        const { data } = await this.$unum
          .job()
          .post(`/data/dropOff/${this.$job.job.id}`)
        window.open('/jobs/' + data.id, '_blank')
      } catch (error) {
        this.$message({ type: 'error', message: error.message })
      }
    },
    openLynxPop() {
      window.open(
        'https://www.lynxservices.com/ProofOfPurchase/Search.aspx',
        '_blank'
      )
    },
    openLynxUpload() {
      window.open(
        'https://www.lynxservices.com/GlassInvoices/RetailerInvoiceLogin.aspx',
        '_blank'
      )
    },
    openSGCFastTrack() {
      window.open(
        'https://www.safelitesolutionsnetwork.com/FastTrack',
        '_blank'
      )
    },
    reviewClaim() {
      window.dispatchEvent(new CustomEvent('showReviewClaimDialog', { detail: this.$job.glaxisClaim }));
    },
    startClaim() {
      window.dispatchEvent(new CustomEvent('showNewClaimDialog', { detail: this.$job }));
    },
    sendReview() {
      let { job, shop, customer } = this.$job;

      let customerName = customer.is_commercial ? customer.company_name : 
        `${customer.name.first} ${customer.name.last}`

      if (!shop.podium_location_id) {
        this.$message({
          type: 'warning',
          message: 'Please add Podium credentials to your GlassBiller account to send review invitations.'
        });
      } else if (!customer.phone?.number) {
        this.$message({
          type: 'warning',
          message: `Please attach a primary phone number to ${customerName} to send a review invitation.`
        });
      } else {
        this.$messageBox.confirm(
          `Send Podium review invitation to ${customerName}?`,
          'Customer Review', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning'
          }).then(() => {
            Job.sendCustomerReview(job.id, shop.id, ({ data }) => {
              this.$message({ type: data.result, message: data.message });
            }
          )
        });
      }
    },
    async produceTSign() {
      try {
        await this.$unum.job().post(`/docs/${this.$job.job.id}/tsign`)
        this.$message({ type: 'success', message: 'tsign created' })
      } catch (error) {
        this.$message({ type: 'error', message: error.message })
      }
    },
    testEdi() {
      this.edi_test = true
    },
    openAudit() {
      this.audit = true
    }
  }
}

export default MenuActionsMixin
