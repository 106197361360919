var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-left": "140px" } },
    [
      !_vm.formVisible
        ? _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-plus",
                plain: "",
                round: ""
              },
              on: { click: _vm.toggleForm }
            },
            [_vm._v(" Manually update the status ")]
          )
        : _vm._e(),
      _vm.formVisible
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: "",
                model: _vm.form,
                rules: _vm.rules,
                "hide-required-asterisk": true,
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mb-0", attrs: { prop: "statusId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "Select status" },
                      model: {
                        value: _vm.form.statusId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "statusId", $$v)
                        },
                        expression: "form.statusId"
                      }
                    },
                    _vm._l(_vm.statuses, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "mb-0" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-plus",
                        type: _vm.form.statusId !== "" ? "primary" : "default",
                        disabled: _vm.form.statusId === "",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(" Update ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-close" },
                      on: { click: _vm.toggleForm }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }