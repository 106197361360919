<template>
  <div>
    <label v-if='title !== ""'>{{title}}</label>
    <Multiselect
      @select="optionSelected"
      @input="inputEvent"
      v-model="values"
      :options="objects"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :hide-selected="false"
      :preserve-search="true"
      placeholder=""
      label="name"
      :limit='4'
      :limit-text='maxString'
      :track-by="trackBy && trackBy.length > 0 ? trackBy : 'id'"
      group-values='csrs'
      group-label='name'
      :preselect-first="false"
      selectLabel="Press enter/click to select"
      deselectLabel="Press enter/click to deselect"
    ></Multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
  props: ['objects', 'selectedValues', 'title', 'trackBy'],
  data () {
    return {
      selectAll: false
    }
  },
  methods: {
    optionSelected (selectedOption, id) {
      let self = this
      self.values = []
      var tempList = []
      var addToList = true
      for (var i = 0; i < self.selectedValues.length; i++) {
        if (self.trackBy === 'shopIdCombo') {
          if (self.selectedValues[i].shopIdCombo.split('-')[0] === selectedOption.shopIdCombo.split('-')[0]) {
            addToList = false
          } else {
            tempList.push(self.selectedValues[i])
          }
        } else {
          if (self.selectedValues[i].shop_id === selectedOption.shop_id) {
            addToList = false
          } else {
            tempList.push(self.selectedValues[i])
          }
        }
      }

      tempList.push(selectedOption)
      self.values = tempList
      // setTimeout(function () {
      //   if (selectedOption) {
      //     if (selectedOption.id === 'SELECTALL') {
      //       self.values = self.objects.slice(0)
      //     } else if (selectedOption.id === 'DESELECTALL') {
      //       self.values = []
      //     }
      //   }
      // }, 100)
      return addToList
    },
    inputEvent (value, id) {
      // last one will be new option
      let temp = value[value.length - 1]
      let tempArray = []
      for (var i = 0; i < value.length; i++) {
        if (this.trackBy === 'shopIdCombo') {
          if (value[i].shopIdCombo.split('-')[0] === temp.shopIdCombo.split('-')[0] && value[i].id !== temp.id) {
            value.slice(i, 1)
          } else {
            tempArray.push(value[i])
          }
        } else {
          if (value[i].shop_id === temp.shop_id && value[i].id !== temp.id) {
            value.slice(i, 1)
          } else {
            tempArray.push(value[i])
          }
        }
      }

      this.values = tempArray
    },
    maxString (val) {
      return `${this.selectedValues.length} of ${this.objects.length} selected`
    }
  },
  computed: {
    shouldHideSelected () {
      if (this.selectedValues.length > 4) {
        return false
      }

      return true
    },
    areAllSelected () {
      // for (var i = 0; i < this.objects.length; i++) {
      //   var foundSelected = false
      //   for (var j = 0; j < this.values.length; j++) {
      //     try {
      //       if (this.objects[i].id === this.values[j].id) {
      //         foundSelected = true
      //         break
      //       }
      //     } catch (err) {}
      //   }
      //   if (!foundSelected) {
      //     return false
      //   }
      // }
      // return true
      // this is not as clean and precise but it should handle the situation
      return this.objects.length === this.values.length
    },
    computedObjects () {
      var computedObjects = []
      var selectAllOption = {}
      // if (this.areAllSelected) {
      //   selectAllOption['name'] = 'Deselect All'
      //   selectAllOption['id'] = 'DESELECTALL'
      // } else {
      //   selectAllOption['name'] = 'Select All'
      //   selectAllOption['id'] = 'SELECTALL'
      // }
      computedObjects = [selectAllOption, ...this.objects]
      return computedObjects
    },
    values: {
      get: function () {
        return this.selectedValues
      },
      set: function (changedVal) {
        for (var i = 0; i < changedVal.length; i++) {
          if (changedVal[i] && (changedVal[i].id === 'SELECTALL' || changedVal[i].id === 'DESELECTALL')) {
            changedVal.splice(i, 1)
            i--
          }
        }
        this.$emit('update:selectedValues', changedVal)
      }
    }
  },
  components: { Multiselect }
}
</script>
<style>
</style>
