var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        "append-to-body": "",
        width: "900px",
        title: "Messages",
        "custom-class": "text-messages-dialog",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "span",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("threads", {
                    ref: "threads",
                    staticClass: "text-messages-block",
                    attrs: {
                      threadsLoading: _vm.threadsLoading,
                      shouldReloadThreads: _vm.shouldReloadThreads,
                      shopPhoneNumber: _vm.shopPhoneNumber,
                      recipientPhoneNumber: _vm.recipientPhoneNumber,
                      threadToMarkUnread: _vm.threadToMarkUnread
                    },
                    on: {
                      threadsLoading: function($event) {
                        _vm.threadsLoading = $event
                      },
                      threadsReloaded: function($event) {
                        _vm.shouldReloadThreads = false
                      },
                      openCustomerInfoModal: function($event) {
                        return _vm.openCustomerInfoModal($event)
                      },
                      setShopPhoneNumber: function($event) {
                        _vm.shopPhoneNumber = $event
                      },
                      setRecipientPhoneNumber: function($event) {
                        _vm.recipientPhoneNumber = $event
                      },
                      threadMarkedUnread: function($event) {
                        _vm.threadToMarkUnread = null
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("thread", {
                    staticClass: "text-messages-block",
                    on: {
                      reloadThreads: function($event) {
                        _vm.shouldReloadThreads = true
                      },
                      markThreadUnread: function($event) {
                        _vm.threadToMarkUnread = $event
                      },
                      openCustomerInfoModal: function($event) {
                        return _vm.openCustomerInfoModal($event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CustomerInfoDialog", {
        attrs: {
          visible: _vm.customerInfoVisible,
          customerId: _vm.selectedCustomerId
        },
        on: { closed: _vm.customerInfoClosed }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }