<template>
  <base-layout :data="data">
    <div slot="title">SMS Confirmation Declined</div>

    <div>
      {{data.details.shopName}}: confirmation for job <a target="_blank" :href="jobLink">{{data.details.jobId}}</a>
      has been declined by the customer.
    </div>
  </base-layout>

</template>

<script>
import BaseLayout from './_BaseLayout'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    jobLink() {
      return `/jobs/${this.data.details.jobId}`
    }
  },

  components: { BaseLayout }
}


</script>
