import * as R from 'ramda'
import { mergeSpec } from './ramda.helpers'
import { formatToDb, parseDateToString } from './date.helpers'
import { sortJobs, sortJobsBay } from './scheduler.helpers'

import { shedulerColors } from '@/constants/sheduler.configs'

export const getSchedulerRequestParams = R.pipe(
  R.prop('filters'),
  R.pick(['date', 'shops', 'combine_techs']),
  mergeSpec({
    date: R.pipe(R.prop('date'), formatToDb)
  })
)

export class SchedulerJob {
  constructor(job) {
    Object.assign(this, job)
  }

  humanDate = () => this.date && parseDateToString(this.date);
}

const sortTechs = R.pipe(
  R.sortBy(R.pipe(R.prop('items'), R.length)),
  R.reverse
)

const addUnassigned = (techs, unassigned) =>
  R.insert(
    unassigned.items.length
      ? 0
      : techs.findIndex(({ items }) => items.length === 0),
    unassigned
  )(techs)

const groupByTech = combine =>
  R.groupBy(R.prop(combine ? 'techside_username' : 'tech_id'))

const groupByBay = R.groupBy(R.prop('bay_id'))

const groupCombineTechs = R.pipe(
  R.groupBy(
    R.cond([
      [R.prop('techside_username'), R.prop('techside_username')],
      [R.T, R.prop('id')]
    ])
  ),
  R.values,
  R.map(
    R.cond([
      [
        R.pipe(R.length, R.lt(1)),
        R.applySpec({
          name: R.path([0, 'techside_username']),
          techside_username: R.path([0, 'techside_username']),
          techs: R.identity
        })
      ],
      [R.T, R.head]
    ])
  )
)

export const addJobsToTechs = date => (state, getters) => {
  const { techs } = state
  const { combineTechs } = getters
  const group = groupByTech(combineTechs)

  const indexedJobs = group(getters.filteredJobs(date))
  const indexedWaypoints = group(getters.dateFilteredWaypoints(date))
  const indexedAppointments = group(getters.dateFilteredAppointments(date))

  const sortedTechs = R.pipe(
    R.when(R.always(combineTechs), groupCombineTechs),
    R.map(tech => {
      const getTechItems = R.propOr(
        [],
        combineTechs && tech.techside_username
          ? tech.techside_username
          : tech.id
      )
      return {
        ...tech,
        items: [
          ...getTechItems(indexedJobs),
          ...getTechItems(indexedWaypoints),
          ...getTechItems(indexedAppointments)
        ]
      }
    }),
    sortTechs
  )(techs)
  const unassigned = {
    id: 0,
    name: 'Unassigned',
    color: '#90A4AE',
    techside_username: '0',
    items: R.propOr([], '0', indexedJobs)
  }
  return addUnassigned(sortedTechs, unassigned)
}

export const getTechList = date =>
  R.pipe(addJobsToTechs(date), R.map(R.over(R.lensProp('items'), sortJobs)))

export const addJobsToBays = date => (state, getters) => {
  const { bays } = state
  const jobs = groupByBay(getters.filteredJobs(date))

  const sortedBays = R.pipe(
    R.map(
      mergeSpec({
        items: ({ id }) => R.propOr([], id)(jobs),
        name: R.pipe(R.prop('name'), R.toLower)
      })
    ),
    sortTechs
  )(bays)

  const unassigned = {
    id: 0,
    name: 'No bay',
    color: '#90A4AE',
    items: R.propOr([], 'null', jobs)
  }
  return addUnassigned(sortedBays, unassigned)
}

export const getBayList = date =>
  R.pipe(addJobsToBays(date), R.map(R.over(R.lensProp('items'), sortJobsBay)))

export const filterJobsByDate = date =>
  R.filter(R.propEq('scheduled_date', formatToDb(date)))

export const filterItemsByDate = date =>
  R.filter(R.pipe(R.prop('date'), formatToDb, R.equals(formatToDb(date))))

const reduceIndexed = R.addIndex(R.reduce)

const getItemType = R.cond([
  [
    R.anyPass([R.prop('shop_name'), R.path(['shop', 'name'])]),
    R.always('jobs')
  ],
  [R.prop('google_place_id'), R.always('waypoints')],
  [R.T, R.always('appointments')]
])

const getTechId = (item, tech) =>
  R.cond([
    [
      R.prop('techs'),
      R.pipe(
        R.prop('techs'),
        R.find(R.propEq('shop_id', item.shop_id)),
        R.prop('id')
      )
    ],
    [R.T, R.prop('id')]
  ])(tech)

export const generateUpdateObject = (date, tech) =>
  reduceIndexed(
    (curr, item, index) => {
      if (!item) return curr
      const itemDate = item.scheduled_date || item.date
      const techId = getTechId(item, tech)
      if (
        itemDate !== date ||
        item.tech_id !== techId ||
        item.scheduler_order !== index
      ) {
        return R.assocPath([getItemType(item), item.id], {
          id: item.id,
          tech_id: techId,
          [item.shop_name ? 'scheduled_date' : 'date']: date,
          scheduler_order: index,
          techside_username: tech.techside_username || `${techId}`,
          tech_commission: tech.commission,
          tech_commission_flat_or_percent: tech.commission_flat_or_percent
        })(curr)
      } else return curr
    },
    { jobs: {}, appointments: {}, waypoints: {} }
  )

export const generateBayUpdateObject = (date, bay_id) =>
  R.pipe(
    reduceIndexed(
      (agg, job, idx) => ({
        ...agg,
        ...(job.bay_id !== bay_id ||
        job.scheduled_date !== date ||
        job.bay_order !== idx
          ? {
            [job.id]: {
              scheduled_date: date,
              bay_id,
              bay_order: idx,
              id: job.id
            }
          }
          : {})
      }),
      {}
    )
  )

export const getTechWaypoints = id =>
  R.pipe(
    R.path(['data', 'waypoints']),
    R.values,
    R.filter(R.propEq('tech_id', id))
  )

export const hasNoDate = R.pipe(R.prop('date'), R.isNil)

export const existsOnSelectedDate = state => ({ google_place_id }) =>
  !R.values(state.waypoints).find(
    R.allPass([
      R.propEq('google_place_id', google_place_id),
      R.propEq('date', formatToDb(state.filters.date))
    ])
  )

export const getTechWaypointList = state => id => {
  return R.pipe(
    getTechWaypoints(id),
    R.filter(R.allPass([hasNoDate, existsOnSelectedDate(state)]))
  )(state)
}

export const getTechShopIds = R.cond([
  [R.prop('techs'), R.pipe(R.prop('techs'), R.map(R.prop('shop_id')))],
  [R.prop('shop_id'), R.pipe(R.prop('shop_id'), R.of)],
  [R.T, R.always([])]
])

export const getTechIds = R.cond([
  [R.prop('techs'), R.pipe(R.prop('techs'), R.map(R.prop('id')))],
  [R.prop('id'), R.pipe(R.prop('id'), id => [id])],
  [R.T, R.always([])]
])

export const getItemColor = (item, tech) =>
  R.cond([
    [
      R.prop('techs'),
      R.pipe(
        R.prop('techs'),
        R.find(R.propEq('id', item.tech_id)),
        R.prop('color'),
        R.when(R.isEmpty, R.always('#607d8b'))
      )
    ],
    [R.prop('color'), R.prop('color')],
    [R.T, R.always(shedulerColors.default)]
  ])(tech)

export const isJob = R.prop('shop_name')
export const isWaypoint = R.prop('google_place_id')
export const isAppt = R.has('description')

export const checkDraggingTech = (item, tech) => {
  if (!item) {
    return true
  }
  if (item.job) {
    const shopIds = getTechShopIds(tech)
    if (R.isEmpty(shopIds)) {
      return true
    }
    return R.contains(item.job.shop_id, shopIds)
  } else if (isWaypoint(item)) {
    const techIds = getTechIds(tech)
    if (R.isEmpty(techIds)) {
      return false
    }
    return R.contains(item.tech_id, techIds)
  }
}
