var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "400px" },
      attrs: { "value-key": "name", filterable: "" },
      model: {
        value: _vm.localSelectedShop,
        callback: function($$v) {
          _vm.localSelectedShop = $$v
        },
        expression: "localSelectedShop"
      }
    },
    _vm._l(_vm.data, function(shop) {
      return _c(
        "el-option",
        { key: shop.id, attrs: { label: shop.name, value: shop } },
        [_c("shop-option", { attrs: { shop: shop } })],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }