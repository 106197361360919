<template>
  <div>
    <el-select
      v-model="shopId"
      filterable
      placeholder="Select shop"
      class="t-w-1/4">
      <el-option
        v-for="i in shops"
        :key="i.id"
        :label="i.name"
        :value="i.id">
      </el-option>
    </el-select>
    <el-checkbox
      v-model="archived"
      label="Show archived ?"
      class="t-ml-4 t-normal-case"
      border
    ></el-checkbox>
  </div>
</template>

<script>
export default {
  computed: {
    shops: function () {
      return this.$store.state.shops
    },
    shopId: {
      get: function () {
        return this.$store.state.leads.filters.shopId
      },
      set: function (value) {
        this.$store.commit('leads/setFilterShopId', value)
      }
    },
    archived: {
      get: function () {
        return this.$store.state.leads.filters.archived
      },
      set: function (value) {
        this.$store.commit('leads/setFilterArchived', value)
      }
    }
  },
  created () {
    if (!this.shopId) {
      this.shops.forEach(i => {
        if (i.id === this.$store.state.user.shop_id) {
          this.shopId = i.id
        }
      })
    } else {
      this.getLeads()
    }
  },
  watch: {
    shopId: function (value) {
      if (value) {
        this.getLeads()
      }
    },
    archived: function () {
      this.getLeads()
    }
  },
  methods: {
    getLeads: function () {
      this.$store.dispatch('leads/get')
    }
  }
}
</script>
