// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".notifications-scrollbar .el-scrollbar__bar.is-horizontal {\n  display: none;\n}\n.el-loading-spinner {\n  margin-top: 5px;\n  width: 100%;\n  height: 24px;\n  display: inline;\n}\n.el-loading-spinner svg {\n  margin: 0 auto;\n}\n.el-divider--horizontal {\n  margin: 12px 0;\n}", "",{"version":3,"sources":["/app/src/components/Notifications/NotificationsList.vue"],"names":[],"mappings":"AAqFA;EACE,aAAA;AAzEF;AA4EA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAzEF;AA2EE;EACE,cAAA;AAzEJ;AA4EA;EACE,cAAA;AAzEF","file":"NotificationsList.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.notifications-scrollbar .el-scrollbar__bar.is-horizontal {\n  display: none;\n}\n\n.el-loading-spinner {\n  margin-top: 5px;\n  width: 100%;\n  height: 24px;\n  display: inline;\n}\n.el-loading-spinner svg {\n  margin: 0 auto;\n}\n\n.el-divider--horizontal {\n  margin: 12px 0;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
