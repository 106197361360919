import { format } from 'date-fns'

export const buildTextMessageBody = async (job, template) => {
  const dictionary = {}

  let approximateArrivalTime = ''
  let approximateJobDuration = ''
  if (job.gb_route_entry) {
    if (job.gb_route_entry.arrival) {
      const routeArrivalDateObj = new Date(`January 1, 1999 ${job.gb_route_entry.arrival}`)
      approximateArrivalTime = format(routeArrivalDateObj, 'h:mma')
    }

    const totalSeconds = job.gb_route_entry.duration
    if (totalSeconds && totalSeconds > 0) {
      const totalMinutes = (totalSeconds - totalSeconds % 60) / 60
      if (totalMinutes === 0) {
        approximateJobDuration = '0m'
      } else {
        const minutes = totalMinutes % 60
        const hours = (totalMinutes - minutes) / 60
        if (hours > 0) {
          approximateJobDuration = hours + 'h '
        }
        approximateJobDuration += minutes + 'm'
      }
    }
  }

  let date = ''
  let day = ''
  if (job.scheduled_date) {
    date = format(job.scheduled_date, 'M/D/YYYY')
    day = format(job.scheduled_date, 'dddd')
  }

  let startTime = ''
  if (job.scheduled_time_start) {
    const startDateTime = new Date(`January 1, 1999 ${job.scheduled_time_start}`)
    startTime = format(startDateTime, 'h:mma')
  }

  let endTime = ''
  if (job.scheduled_time_end) {
    const endDateTime = new Date(`January 1, 1999 ${job.scheduled_time_end}`)
    endTime = format(endDateTime, 'h:mma')
  }

  dictionary['{aat}'] = approximateArrivalTime
  dictionary['{ajd}'] = approximateJobDuration
  dictionary['{job-id #}'] = job.id
  dictionary['{name}'] = job.contact_name || ''
  dictionary['{address}'] = job.install_g_address?.full_address || ''
  dictionary['{date}'] = date
  dictionary['{day}'] = day
  dictionary['{from}'] = startTime
  dictionary['{to}'] = endTime
  dictionary['{veh year}'] = job.vehicle?.year || ''
  dictionary['{veh make}'] = job.vehicle?.make || ''
  dictionary['{veh model}'] = job.vehicle?.model || ''
  dictionary['{veh style}'] = job.vehicle?.style || ''
  dictionary['{veh unit #}'] = job.vehicle?.unit_number || ''
  dictionary['{veh plate #}'] = job.vehicle?.plate_number || ''
  dictionary['{veh vin #}'] = job.vehicle?.vin || ''
  dictionary['{veh mileage}'] = job.vehicle_mileage || ''

  let { body } = template
  for (const tag in dictionary) {
    if (body) {
      body = body.split(tag).join(dictionary[tag])
    }
  }

  return body
}
