import { actions, mutations } from './types';
import NotificationsAPI from '@/services/NotificationsAPI';
import { MultiLoader } from './MultiLoader';

export default {
  [actions.open]: async ({ commit, dispatch }) => {
    commit(mutations.setIsOpened, true);
    await dispatch(actions.load);
  },

  [actions.close]({ commit }) {
    commit(mutations.setIsOpened, false);
  },

  [actions.load]: async ({ dispatch }) => {
    await dispatch(actions.loadSettings);
    await dispatch(actions.loadEventsSettings);
  },

  [actions.setStatus]: async ({ commit }, status) => {
    commit(mutations.setStatus, status);
  },

  [actions.loadSettings]: async ({ commit, state }) => {

    // load in case nothing was loaded previously
    if (state.settings_loaded) {
      return;
    }

    const { data } = await NotificationsAPI.get('/settings/get');
    const info = data?.response?.info || [];
    commit(mutations.setSettings, info);
  },

  [actions.changeSettings]: async ({ commit }, data) => {
    let loader = new MultiLoader(
      () => commit(mutations.setStatus, 'saving'),
      () => commit(mutations.setStatus, 'saved'),
      () => commit(mutations.setStatus, '')
    );

    loader.start();

    await NotificationsAPI.post('/settings/update', data);
    commit(mutations.changeSettings, data);

    loader.stop();
  },

  [actions.loadEventsSettings]: async ({ commit, state }) => {

    // load in case nothing was loaded previously
    if (state.settings_events_loaded) {
      return;
    }

    const { data } = await NotificationsAPI.get('/events_settings/list');
    const rows = data?.response?.rows || [];
    commit(mutations.setEventsSettings, rows);
  },

  [actions.changeEventsSettings]: async ({ commit }, data) => {

    let loader = new MultiLoader(
      () => commit(mutations.setStatus, 'saving'),
      () => commit(mutations.setStatus, 'saved'),
      () => commit(mutations.setStatus, '')
    );

    loader.start();

    await NotificationsAPI.post('/events_settings/update', data);
    commit(mutations.changeEventsSettings, data);

    loader.stop();
  },
};
