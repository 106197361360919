<template>
  <div class="t-h-full">
    <div v-if="isOpenning" v-loading="isOpenning" class="t-h-full"></div>
    <div v-else class="t-flex t-h-full">
      <div class="t-flex t-flex-col t-flex-grow t-h-full">
        <Header/>
        <Body/>
        <Controls/>
      </div>
      <InfoDrawer/>
      <AddUsersDialog/>
      <MembersDialog/>
    </div>
  </div>
</template>

<script>
import Body from './Body'
import Header from './Header'
import Controls from './Controls'
import InfoDrawer from './InfoDrawer'
import MembersDialog from './MembersDialog'
import AddUsersDialog from './AddUsersDialog'
import { ChatService } from '@/services/ChatService'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  computed: {
    isOpenning: function() {
      return this.$store.state.chat.channelIsOpenning
    },
    activeChannel: function() {
      return this.$store.state.chat.activeChannel
    },
    members: function() {
      return this.$store.state.chat.members
    }
  },
  watch: {
    activeChannel: async function(value) {
      if (value) {
        const channel = await ChatService.getChannelBySid(value.sid)
        this.$store.dispatch(
          chatTypes.namespace + chatTypes.actions.getMembers,
          channel
        )
        this.$store.dispatch(
          chatTypes.namespace + chatTypes.actions.getTotalMessages,
          channel
        )
      }
    }
  },
  components: {
    Body,
    Header,
    Controls,
    InfoDrawer,
    MembersDialog,
    AddUsersDialog
  }
}
</script>
