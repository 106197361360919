var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-details-table" }, [
    _c("table", [
      _c("tr", [
        _c("td", [_vm._v("File Name:")]),
        _c("td", [_vm._v(_vm._s(_vm.document_name))])
      ]),
      _c("tr", [
        _c("td", [_vm._v("File Type:")]),
        _c("td", [_vm._v(_vm._s(_vm.type))])
      ]),
      _c("tr", [
        _c("td", [_vm._v("Doc type:")]),
        _c("td", [_vm._v(_vm._s(_vm.attchType))])
      ]),
      _c("tr", [
        _c("td", [_vm._v("File Size:")]),
        _c("td", [_vm._v(_vm._s(_vm.size))])
      ]),
      _c("tr", [
        _c("td", [_vm._v("Created:")]),
        _c("td", [_vm._v(_vm._s(_vm.date))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }