var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          ref: "quotes-dialog",
          attrs: {
            visible: _vm.visible,
            title: "Mobile",
            width: _vm.isMobile ? "100%" : "1400px",
            "custom-class": "gb-el-dialog quotes-dialog mobileInboxModal",
            top: "24px",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            open: _vm.dialogAboutToAppear,
            opened: _vm.dialogAppeared
          }
        },
        [
          _c(
            "el-tabs",
            { attrs: { type: "card" }, on: { "tab-click": _vm.tabClicked } },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Mobile" } },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm.$store.state.userSalesideJobCount > 0
                      ? _c("div", { staticClass: "notification right blue" }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "-10px" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$store.state.userSalesideJobCount
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" Mobile ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "row mb-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-sm-12 col-md-8 col-xl-5 col-lg-5" },
                        [
                          _c("label", { attrs: { for: "#" } }, [
                            _vm._v("Shop")
                          ]),
                          _c("Multiselect", {
                            attrs: {
                              placeholder: "Select Shop...",
                              options: _vm.$store.state.shops,
                              label: "name",
                              "show-labels": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleLabel",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: { margin: "0px" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-11",
                                            staticStyle: {
                                              "font-size": "14px",
                                              "text-overflow": "ellipsis",
                                              overflow: "hidden",
                                              padding: "0px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.option.name) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "float-right col-1",
                                            staticStyle: { padding: "0px" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.getShopCountForDropdown(
                                                        props.option.id
                                                      ) != "0",
                                                    expression:
                                                      "getShopCountForDropdown (props.option.id) != '0'"
                                                  }
                                                ],
                                                staticStyle: {
                                                  "text-align": "center",
                                                  color: "white",
                                                  "font-size": "14px",
                                                  "padding-right": "6px",
                                                  "padding-left": "5px",
                                                  "padding-top": "5px",
                                                  "padding-bottom": "5px",
                                                  "border-radius": "5px",
                                                  background: "#007bff"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getShopCountForDropdown(
                                                        props.option.id
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "option",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: { margin: "0px" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-11",
                                            staticStyle: {
                                              "font-size": "14px",
                                              "text-overflow": "ellipsis",
                                              overflow: "hidden",
                                              padding: "0px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.option.name) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "float-right col-1",
                                            staticStyle: { padding: "0px" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.getShopCountForDropdown(
                                                        props.option.id
                                                      ) != "0",
                                                    expression:
                                                      "getShopCountForDropdown (props.option.id) != '0'"
                                                  }
                                                ],
                                                staticStyle: {
                                                  "text-align": "center",
                                                  color: "white",
                                                  "font-size": "14px",
                                                  "padding-right": "6px",
                                                  "padding-left": "5px",
                                                  "padding-top": "5px",
                                                  "padding-bottom": "5px",
                                                  "border-radius": "5px",
                                                  background: "#007bff"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getShopCountForDropdown(
                                                        props.option.id
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selectedShop,
                              callback: function($$v) {
                                _vm.selectedShop = $$v
                              },
                              expression: "selectedShop"
                            }
                          })
                        ],
                        1
                      ),
                      !_vm.isMobile
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { "align-self": "center" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "tag ttFilter",
                                      staticStyle: {
                                        "background-color": "gray",
                                        color: "white",
                                        display: "inline"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeInboxView("jobs")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "new jobs: " +
                                          _vm._s(_vm.inboxCountForDispaly)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "tag ttFilter",
                                      staticStyle: {
                                        "background-color": "#21b7ff",
                                        color: "white",
                                        display: "inline"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeInboxView("messages")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "messages: " +
                                          _vm._s(_vm.unreadCounter())
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "tag ttFilter",
                                      staticStyle: {
                                        "background-color": "#e66926",
                                        color: "white",
                                        display: "inline"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleUnsentFilter()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "Unsent: " +
                                          _vm._s(_vm.inboxUnsentCountForDisplay)
                                      )
                                    ]
                                  ),
                                  _vm.upcomingJobs.today > 0
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "tag ttFilter",
                                          staticStyle: {
                                            "background-color": "red",
                                            color: "white",
                                            display: "inline"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.filterByTodayTomorrow(
                                                "today"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "Today: " +
                                              _vm._s(_vm.upcomingJobs.today)
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.upcomingJobs.tomorrow > 0
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "tag ttFilter",
                                          staticStyle: {
                                            "background-color": "orange",
                                            color: "white",
                                            display: "inline"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.filterByTodayTomorrow(
                                                "tomorrow"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "Tomorrow: " +
                                              _vm._s(_vm.upcomingJobs.tomorrow)
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "b-row",
                            { staticStyle: { padding: "0px 15px" } },
                            [
                              _c("b-col", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tag ttFilter",
                                    staticStyle: {
                                      "background-color": "gray",
                                      color: "white",
                                      display: "block",
                                      "text-align": "center"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeInboxView("jobs")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "new jobs: " +
                                        _vm._s(_vm.inboxCountForDispaly)
                                    )
                                  ]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "tag ttFilter",
                                    staticStyle: {
                                      "background-color": "#21b7ff",
                                      color: "white",
                                      display: "block",
                                      "text-align": "center"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeInboxView("messages")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "messages: " + _vm._s(_vm.unreadCounter())
                                    )
                                  ]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "tag ttFilter",
                                    staticStyle: {
                                      "background-color": "#e66926",
                                      color: "white",
                                      display: "inline"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleUnsentFilter()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "Unsent: " +
                                        _vm._s(_vm.inboxUnsentCountForDisplay)
                                    )
                                  ]
                                ),
                                _vm.upcomingJobs.today > 0
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "tag ttFilter",
                                        staticStyle: {
                                          "background-color": "red",
                                          color: "white",
                                          display: "block",
                                          "text-align": "center"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.filterByTodayTomorrow(
                                              "today"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Today: " +
                                            _vm._s(_vm.upcomingJobs.today)
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.upcomingJobs.tomorrow > 0
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "tag ttFilter",
                                        staticStyle: {
                                          "background-color": "orange",
                                          color: "white",
                                          display: "block",
                                          "text-align": "center"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.filterByTodayTomorrow(
                                              "tomorrow"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Tomorrow: " +
                                            _vm._s(_vm.upcomingJobs.tomorrow)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _vm.currentInboxView === "jobs"
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-6 col-xl-2 col-lg-3" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.showArchive,
                                expression: "showArchive"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.showArchive)
                                ? _vm._i(_vm.showArchive, null) > -1
                                : _vm.showArchive
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.showArchive,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.showArchive = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.showArchive = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.showArchive = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" Show Archive? ")
                        ])
                      ])
                    : _vm._e(),
                  _vm.currentInboxView === "jobs"
                    ? _c("div", { staticClass: "tableHolder row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group col-12" },
                          [
                            _c(
                              "b-table",
                              {
                                ref: "mobileInboxTable",
                                staticClass: "text-left p-0",
                                attrs: {
                                  stacked: "md",
                                  items: _vm.myProvider,
                                  fields: _vm.fields,
                                  "current-page": _vm.inboxCurrentPage,
                                  "per-page": _vm.inboxPerPage,
                                  "sort-by": _vm.sortBy,
                                  "sort-desc": _vm.sortDesc,
                                  busy: _vm.isBusy,
                                  "show-empty": true,
                                  "empty-text": _vm.isBusy
                                    ? "Loading, please wait..."
                                    : "There are no records to show",
                                  "empty-filtered-text": _vm.isBusy
                                    ? "Loading, please wait..."
                                    : "There are no records matching your request"
                                },
                                on: {
                                  "update:sortBy": function($event) {
                                    _vm.sortBy = $event
                                  },
                                  "update:sort-by": function($event) {
                                    _vm.sortBy = $event
                                  },
                                  "update:sortDesc": function($event) {
                                    _vm.sortDesc = $event
                                  },
                                  "update:sort-desc": function($event) {
                                    _vm.sortDesc = $event
                                  },
                                  "update:busy": function($event) {
                                    _vm.isBusy = $event
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "HEAD_mobileJobNumber",
                                      fn: function(row) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.mobileJobNumberFilter,
                                                expression:
                                                  "mobileJobNumberFilter"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              "data-id": row.id,
                                              type: "text",
                                              placeholder: "Mobile Job #"
                                            },
                                            domProps: {
                                              value: _vm.mobileJobNumberFilter
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              },
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.mobileJobNumberFilter =
                                                  $event.target.value
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "HEAD_referralNumber",
                                      fn: function(row) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.referralNumberFilter,
                                                expression:
                                                  "referralNumberFilter"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              "data-id": row.id,
                                              type: "text",
                                              placeholder: "Referral #"
                                            },
                                            domProps: {
                                              value: _vm.referralNumberFilter
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              },
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.referralNumberFilter =
                                                  $event.target.value
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "HEAD_lastName",
                                      fn: function(row) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.lastNameFilter,
                                                expression: "lastNameFilter"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              "data-id": row.id,
                                              type: "text",
                                              placeholder: "Last Name"
                                            },
                                            domProps: {
                                              value: _vm.lastNameFilter
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              },
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.lastNameFilter =
                                                  $event.target.value
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "HEAD_firstName",
                                      fn: function(row) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.firstNameFilter,
                                                expression: "firstNameFilter"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              "data-id": row.id,
                                              type: "text",
                                              placeholder: "First Name"
                                            },
                                            domProps: {
                                              value: _vm.firstNameFilter
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              },
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.firstNameFilter =
                                                  $event.target.value
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "HEAD_jobNumber",
                                      fn: function(row) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.jobNumberFilter,
                                                expression: "jobNumberFilter"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              "data-id": row.id,
                                              type: "text",
                                              placeholder: "Job #"
                                            },
                                            domProps: {
                                              value: _vm.jobNumberFilter
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              },
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.jobNumberFilter =
                                                  $event.target.value
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "HEAD_salesRep",
                                      fn: function(row) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.salesRepFilter,
                                                expression: "salesRepFilter"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              "data-id": row.id,
                                              type: "text",
                                              placeholder: "Sales Rep"
                                            },
                                            domProps: {
                                              value: _vm.salesRepFilter
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              },
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.salesRepFilter =
                                                  $event.target.value
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "HEAD_yearMakeModel",
                                      fn: function(row) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.yearMakeModelFilter,
                                                expression:
                                                  "yearMakeModelFilter"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              "data-id": row.id,
                                              type: "text",
                                              placeholder: "Year Make Model"
                                            },
                                            domProps: {
                                              value: _vm.yearMakeModelFilter
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              },
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.yearMakeModelFilter =
                                                  $event.target.value
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "HEAD_scheduledDate",
                                      fn: function(row) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.scheduledDateFilter,
                                                expression:
                                                  "scheduledDateFilter"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              "data-id": row.id,
                                              type: "text",
                                              placeholder: "Scheduled Date"
                                            },
                                            domProps: {
                                              value: _vm.scheduledDateFilter
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              },
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.scheduledDateFilter =
                                                  $event.target.value
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "sentOn",
                                      fn: function(row) {
                                        return [
                                          row.item.sentOn
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getTimeZoneFormattedDateTime(
                                                      row.item.sentOn
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "referralNumber",
                                      fn: function(row) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(row.item.referralNumber) +
                                              " "
                                          ),
                                          row.item.pending_offline === 1
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "tag",
                                                  staticStyle: {
                                                    "background-color":
                                                      "purple",
                                                    color: "white",
                                                    display: "inline"
                                                  }
                                                },
                                                [_vm._v("Offline")]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "scheduledDate",
                                      fn: function(row) {
                                        return [
                                          _vm.isJobSoon(
                                            row.item.scheduledDate
                                          ) === "today"
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "tag",
                                                  staticStyle: {
                                                    "background-color": "red",
                                                    color: "white",
                                                    display: "inline"
                                                  }
                                                },
                                                [_vm._v("Today")]
                                              )
                                            : _vm.isJobSoon(
                                                row.item.scheduledDate
                                              ) === "tomorrow"
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "tag",
                                                  staticStyle: {
                                                    "background-color":
                                                      "orange",
                                                    color: "white",
                                                    display: "inline"
                                                  }
                                                },
                                                [_vm._v("Tomorrow")]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(row.item.scheduledDate)
                                                )
                                              ])
                                        ]
                                      }
                                    },
                                    {
                                      key: "jobNumber",
                                      fn: function(row) {
                                        return [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: row.item.jobNumber > 0,
                                                  expression:
                                                    "row.item.jobNumber > 0"
                                                }
                                              ],
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.closeModal()
                                                  _vm.changeRouteWithCheck(
                                                    "/jobs/" +
                                                      row.item.jobNumber
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(row.item.jobNumber))]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    row.item.sent === null ||
                                                    row.item.sent === 0,
                                                  expression:
                                                    "row.item.sent === null || row.item.sent === 0"
                                                }
                                              ],
                                              staticClass: "tag",
                                              staticStyle: {
                                                "background-color": "#e66926",
                                                color: "white",
                                                display: "inline"
                                              }
                                            },
                                            [_vm._v("Unsent")]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "actions",
                                      fn: function(row) {
                                        return [
                                          _c("img", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.startingJobs.indexOf(
                                                    row.item.mobileJobNumber
                                                  ) >= 0,
                                                expression:
                                                  "startingJobs.indexOf(row.item.mobileJobNumber) >= 0 "
                                              }
                                            ],
                                            staticStyle: {
                                              width: "20px",
                                              height: "20px"
                                            },
                                            attrs: {
                                              src: require("./../../assets/img/spinner.gif")
                                            }
                                          }),
                                          _c(
                                            "el-dropdown",
                                            {
                                              staticClass:
                                                "mobile-inbox-actions",
                                              attrs: {
                                                round: "",
                                                "split-button": "",
                                                type: "primary"
                                              },
                                              on: {
                                                command: _vm.startButtonPressed,
                                                click: function($event) {
                                                  return _vm.startButtonPressed(
                                                    Object.assign(
                                                      {},
                                                      row.item,
                                                      {
                                                        selectedStartStatus:
                                                          "workOrder"
                                                      }
                                                    )
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(" Start "),
                                              _c(
                                                "el-dropdown-menu",
                                                {
                                                  attrs: { slot: "dropdown" },
                                                  slot: "dropdown"
                                                },
                                                [
                                                  _c(
                                                    "el-dropdown-item",
                                                    {
                                                      attrs: {
                                                        command: Object.assign(
                                                          {},
                                                          row.item,
                                                          {
                                                            selectedStartStatus:
                                                              "quote"
                                                          }
                                                        )
                                                      }
                                                    },
                                                    [_vm._v("Start Quote")]
                                                  ),
                                                  _c(
                                                    "el-dropdown-item",
                                                    {
                                                      attrs: {
                                                        command: Object.assign(
                                                          {},
                                                          row.item,
                                                          {
                                                            selectedStartStatus:
                                                              "invoice"
                                                          }
                                                        )
                                                      }
                                                    },
                                                    [_vm._v("Start Invoice")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.startingJobs.indexOf(
                                                      row.item.mobileJobNumber
                                                    ) < 0 &&
                                                    row.item.status !==
                                                      "archived" &&
                                                    row.item.jobNumber <= 0 &&
                                                    row.item.sent === 1,
                                                  expression:
                                                    "startingJobs.indexOf(row.item.mobileJobNumber) < 0 && row.item.status !== 'archived' && row.item.jobNumber <= 0 && row.item.sent === 1"
                                                }
                                              ],
                                              staticClass:
                                                "label label-gray label-sm",
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.kickbackJobPressed(
                                                    row.item
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Kick Back")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.startingJobs.indexOf(
                                                      row.item.mobileJobNumber
                                                    ) < 0 &&
                                                    row.item.status !==
                                                      "archived" &&
                                                    row.item.sent === 1,
                                                  expression:
                                                    "startingJobs.indexOf(row.item.mobileJobNumber) < 0 && row.item.status !== 'archived' && row.item.sent === 1"
                                                }
                                              ],
                                              staticClass:
                                                "label label-gray label-sm",
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.archiveButtonPressed(
                                                    row.index,
                                                    row.item
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Archive")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.startingJobs.indexOf(
                                                      row.item.mobileJobNumber
                                                    ) < 0 &&
                                                    row.item.status ===
                                                      "archived" &&
                                                    row.item.sent === 1,
                                                  expression:
                                                    "startingJobs.indexOf(row.item.mobileJobNumber) < 0 && row.item.status === 'archived' && row.item.sent === 1"
                                                }
                                              ],
                                              staticClass:
                                                "label label-gray label-sm",
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.unarchiveButtonPressed(
                                                    row.item
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Unarchive")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "label label-gray label-sm",
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.viewUnsentJob(
                                                    row.item
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("View")]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "row-details",
                                      fn: function(row) {
                                        return [
                                          !_vm.isMobile
                                            ? _c(
                                                "b-row",
                                                {
                                                  staticClass: "shopMessageRow",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.messageClicked(
                                                        row.item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center"
                                                      },
                                                      attrs: { cols: "1" }
                                                    },
                                                    [
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          attrs: {
                                                            variant: "success"
                                                          }
                                                        },
                                                        [_vm._v("New")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "5" } },
                                                    [
                                                      _c("p", [
                                                        _c("b", [
                                                          _vm._v("Message:")
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.truncateMessage(
                                                                row.item.message
                                                              )
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "1" } },
                                                    [
                                                      row.item.jobNumber > 0
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "tag",
                                                              staticStyle: {
                                                                "background-color":
                                                                  "gray",
                                                                display:
                                                                  "inline"
                                                              },
                                                              attrs: {
                                                                href:
                                                                  "/jobs/" +
                                                                  row.item
                                                                    .jobNumber,
                                                                target: "_blank"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  row.item
                                                                    .jobNumber
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _c("span", [
                                                            _c("b", [
                                                              _vm._v("Mob#:")
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  row.item
                                                                    .mobileJobNumber
                                                                )
                                                            )
                                                          ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "1" } },
                                                    [
                                                      _c("p", [
                                                        _c("b", [
                                                          _vm._v("From:")
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              row.item.salesRep
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "2" } },
                                                    [
                                                      _c("p", [
                                                        _c("b", [
                                                          _vm._v("Sent:")
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              row.item.created
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center"
                                                      },
                                                      attrs: { cols: "2" }
                                                    },
                                                    [
                                                      row.item
                                                        .message_status ===
                                                      "unread"
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "label label-gray label-sm",
                                                              attrs: {
                                                                href:
                                                                  "javascript:void(0)"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.toggleMessageStatus(
                                                                    row.item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Mark as read"
                                                              )
                                                            ]
                                                          )
                                                        : row.item
                                                            .message_status ===
                                                          "read"
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "label label-gray label-sm",
                                                              attrs: {
                                                                href:
                                                                  "javascript:void(0)"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.toggleMessageStatus(
                                                                    row.item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Mark as unread"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "b-row",
                                                {
                                                  staticClass: "shopMessageRow",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.messageClicked(
                                                        row.item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "2" } },
                                                    [
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          attrs: {
                                                            variant: "success"
                                                          }
                                                        },
                                                        [_vm._v("New")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "5" } },
                                                    [
                                                      _c("p", [
                                                        _c("b", [
                                                          _vm._v("Message")
                                                        ])
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "5" } },
                                                    [
                                                      _c("p", [
                                                        _c("b", [
                                                          _vm._v("From:")
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              row.item.salesRep
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2634759627
                                )
                              },
                              [
                                _c("template", { slot: "HEAD_sentOn" }, [
                                  _vm._v(" Sent ")
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.currentInboxView === "jobs"
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "6" } }, [
                            _c("span", [_vm._v(_vm._s(_vm.inboxResultsText))])
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c("b-pagination", {
                                attrs: {
                                  "total-rows": _vm.inboxTotalRows,
                                  "per-page": _vm.inboxPerPage,
                                  align: "right"
                                },
                                model: {
                                  value: _vm.inboxCurrentPage,
                                  callback: function($$v) {
                                    _vm.inboxCurrentPage = $$v
                                  },
                                  expression: "inboxCurrentPage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentInboxView === "messages"
                    ? _c(
                        "div",
                        {
                          staticClass: "row",
                          class: {
                            tableHolder2: _vm.allShopMessages.length,
                            emptyTable: !_vm.allShopMessages.length
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group col-12" },
                            [
                              _c("b-table", {
                                ref: "shopMessagesTable",
                                staticClass: "text-left p-0",
                                attrs: {
                                  stacked: "md",
                                  items: _vm.allShopMessages,
                                  fields: _vm.messageFields,
                                  "current-page": _vm.messagesCurrentPage,
                                  "per-page": _vm.messagesPerPage,
                                  "sort-by": _vm.sortBy,
                                  "sort-desc": _vm.sortDesc,
                                  busy: _vm.isBusy,
                                  "show-empty": true,
                                  "empty-text": _vm.isBusy
                                    ? "Loading, please wait..."
                                    : "There are no records to show",
                                  "empty-filtered-text": _vm.isBusy
                                    ? "Loading, please wait..."
                                    : "There are no records matching your request"
                                },
                                on: {
                                  "row-clicked": _vm.messageClicked,
                                  "update:sortBy": function($event) {
                                    _vm.sortBy = $event
                                  },
                                  "update:sort-by": function($event) {
                                    _vm.sortBy = $event
                                  },
                                  "update:sortDesc": function($event) {
                                    _vm.sortDesc = $event
                                  },
                                  "update:sort-desc": function($event) {
                                    _vm.sortDesc = $event
                                  },
                                  "update:busy": function($event) {
                                    _vm.isBusy = $event
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "message",
                                      fn: function(row) {
                                        return [
                                          row.item.message_status ===
                                            "unread" && !_vm.isMobile
                                            ? _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center"
                                                      },
                                                      attrs: { cols: "2" }
                                                    },
                                                    [
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          attrs: {
                                                            variant: "success"
                                                          }
                                                        },
                                                        [_vm._v("New")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "10" } },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.truncateMessage(
                                                              row.item.message
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : row.item.message_status ===
                                                "unread" && _vm.isMobile
                                            ? _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    [
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          attrs: {
                                                            variant: "success"
                                                          }
                                                        },
                                                        [_vm._v("New")]
                                                      ),
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.truncateMessage(
                                                              row.item.message
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "b-row",
                                                [
                                                  _c("b-col", [
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.truncateMessage(
                                                            row.item.message
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ])
                                                ],
                                                1
                                              )
                                        ]
                                      }
                                    },
                                    {
                                      key: "jobNumber",
                                      fn: function(row) {
                                        return [
                                          row.item.jobNumber > 0
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "tag",
                                                  staticStyle: {
                                                    "background-color": "gray",
                                                    display: "inline"
                                                  },
                                                  attrs: {
                                                    href:
                                                      "/jobs/" +
                                                      row.item.jobNumber,
                                                    target: "_blank"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(row.item.jobNumber)
                                                  )
                                                ]
                                              )
                                            : _c("span")
                                        ]
                                      }
                                    },
                                    {
                                      key: "actions",
                                      fn: function(row) {
                                        return [
                                          row.item.message_status === "unread"
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "label label-gray label-sm",
                                                  attrs: {
                                                    href: "javascript:void(0)"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.toggleMessageStatus(
                                                        row.item
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Mark as read")]
                                              )
                                            : row.item.message_status === "read"
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "label label-gray label-sm",
                                                  attrs: {
                                                    href: "javascript:void(0)"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.toggleMessageStatus(
                                                        row.item
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Mark as unread")]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1260591062
                                )
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.currentInboxView === "messages"
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "6" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.messagesResultsText))
                            ])
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c("b-pagination", {
                                attrs: {
                                  "total-rows": _vm.messagesTotalRows,
                                  "per-page": _vm.messagesPerPage,
                                  align: "right"
                                },
                                model: {
                                  value: _vm.messagesCurrentPage,
                                  callback: function($$v) {
                                    _vm.messagesCurrentPage = $$v
                                  },
                                  expression: "messagesCurrentPage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Leads" } },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm.$store.state.leads.pagination.total > 0
                      ? _c("div", { staticClass: "notification right blue" }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "-10px" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$store.state.leads.pagination.total
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" Leads ")
                  ]),
                  _c("Leads")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ShopMessageDialog", {
        attrs: { selectedMessage: _vm.selectedMessage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }