var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "gb-checkbox",
      class: {
        "no-slot": !_vm.$slots.default,
        locked: _vm.locked,
        disabled: _vm.disabled
      },
      on: {
        click: function($event) {
          $event.preventDefault()
          _vm.checked = !_vm.checked
        }
      }
    },
    [
      _c("transition", { attrs: { name: "fade-color" } }, [
        _vm.value
          ? _c("i", {
              staticClass: "material-icons-outline",
              class: {
                "md-done": true,
                "md-18": true,
                focused: _vm.focused,
                checked: _vm.checked
              }
            })
          : _vm._e()
      ]),
      _vm.indeterminate
        ? _c("i", {
            staticClass: "material-icons-outline",
            class: {
              "md-indeterminate_check_box": true,
              focused: true
            }
          })
        : _c("i", {
            staticClass: "material-icons-outline",
            class: {
              "md-check_box_outline_blank": true,
              focused: _vm.focused,
              checked: _vm.checked
            }
          }),
      _c("input", {
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.checked },
        on: {
          focus: function($event) {
            _vm.focused = true
          },
          blur: function($event) {
            _vm.focused = false
          }
        }
      }),
      _c("span", { class: { checked: _vm.checked } }, [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }