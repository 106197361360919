import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Document extends ObjectTracker {
  id = -1
  created = -1
  document_type = ''
  document_name = ''
  url = ''
  job_id = -1
  modified = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getSWOColor () {
    return '#cd0a0a'
  }

  static getDISColor () {
    return '#1c94c4'
  }

  static getPoPColor () {
    return '#00008b'
  }

  static getFIVColor () {
    return '#a577a6'
  }

  static getQUOColor () {
    return '#b38a7f'
  }

  static getDLColor () {
    return '#a10000'
  }

  static getSSMPicColor () {
    return '#005AA3'
  }

  static getTSMPicColor () {
    return '#08A300'
  }

  static getPicColor () {
    return '#6f3d8b'
  }

  static getApprovalColor () {
    return '#0086ad'
  }

  static getAuthColor () {
    return '#99d8d8'
  }

  static getRecalColor () {
    return '#ffb62b'
  }

  static getSSignColor () {
    return '#6db3f2'
  }

  static getTSignColor () {
    return 'rgb(132, 201, 127)'
  }

  static getOTHColor () {
    return '#cbbce1'
  }

  static getBillColor () {
    return '#8CA568'
  }

  static getBillPaymentColor () {
    return '#407E88'
  }

  static getExpenseColor () {
    return '#884A40'
  }

  static getJournalEntryColor () {
    return '#488840'
  }

  static getEsignColor () {
    return '#ff8800'
  }

  static getSmsSignColor () {
    return '#0ec9b7'
  }

  static getPaymentColor () {
    return '#33c90e'
  }

  static getPresignColor() {
    return '#3D5AFE'
  }

  static getTSMCNPColor() {
    return '#547E51'
  }

  static getDocumentTagColorWithType (document_type) {
    if (document_type === 'swo') {
      return this.getSWOColor()
    } else if (document_type === 'dis') {
      return this.getDISColor()
    } else if (document_type === 'pop') {
      return this.getPoPColor()
    } else if (document_type === 'fiv') {
      return this.getFIVColor()
    } else if (document_type === 'quo') {
      return this.getQUOColor()
    } else if (document_type === 'dl') {
      return this.getDLColor()
    } else if (document_type === 'ssmpic') {
      return this.getSSMPicColor()
    } else if (document_type === 'tsmpic') {
      return this.getTSMPicColor()
    } else if (document_type === 'ssign') {
      return this.getSSignColor()
    } else if (document_type === 'tsign') {
      return this.getTSignColor()
    } else if (document_type === 'oth') {
      return this.getOTHColor()
    } else if (document_type === 'pic') {
      return this.getPicColor()
    } else if (document_type === 'app') {
      return this.getApprovalColor()
    } else if (document_type === 'auth') {
      return this.getAuthColor()
    } else if (document_type === 'recal') {
      return this.getRecalColor()
    } else if (document_type === 'bill') {
      return this.getBillColor()
    } else if (document_type === 'bill_payment') {
      return this.getBillPaymentColor()
    } else if (document_type === 'expense') {
      return this.getExpenseColor()
    } else if (document_type === 'journal_entry') {
      return this.getJournalEntryColor()
    } else if (document_type === 'esign') {
      return this.getEsignColor()
    } else if (document_type === 'smssign') {
      return this.getSmsSignColor()
    } else if (document_type === 'payment') {
      return this.getPaymentColor()
    } else if (document_type === 'presign') {
      return this.getPresignColor()
    } else if (document_type === 'tsmcnp') {
      return this.getTSMCNPColor()
    }
    return ''
  }

  static getDocumentTagNameWithType (document_type) {
    if (document_type === 'swo') {
      return 'SWO'
    } else if (document_type === 'dis') {
      return 'DIS'
    } else if (document_type === 'pop') {
      return 'PoP'
    } else if (document_type === 'fiv') {
      return 'FIV'
    } else if (document_type === 'quo') {
      return 'QUO'
    } else if (document_type === 'dl') {
      return 'DL'
    } else if (document_type === 'ssmpic') {
      return 'SSMPic'
    } else if (document_type === 'tsmpic') {
      return 'TSMPic'
    } else if (document_type === 'ssign') {
      return 'SSIGN'
    } else if (document_type === 'tsign') {
      return 'TSIGN'
    } else if (document_type === 'oth') {
      return 'OTH'
    } else if (document_type === 'pic') {
      return 'PIC'
    } else if (document_type === 'app') {
      return 'APP'
    } else if (document_type === 'auth') {
      return 'AUTH'
    } else if (document_type === 'recal') {
      return 'RECAL'
    } else if (document_type === 'bill') {
      return 'BILL'
    } else if (document_type === 'bill_payment') {
      return 'BILLPAYMENT'
    } else if (document_type === 'expense') {
      return 'EXPENSE'
    } else if (document_type === 'journal_entry') {
      return 'JOURNALENTRY'
    } else if (document_type === 'esign') {
      return 'ESIGN'
    } else if (document_type === 'smssign') {
      return 'SMSSIGN'
    } else if (document_type === 'payment') {
      return 'PAYMENT'
    } else if (document_type === 'presign') {
      return 'INSPECTION'
    } else if (document_type === 'tsmcnp') {
      return 'TSMCNP'
    }
    return ''
  }

  static getDocumentTags (documents) {
    var documentTags = []
    var swoTags = []
    var disTags = []
    var popTags = []
    var fivTags = []
    var quoTags = []
    var dlTags = []
    var ssmPicTags = []
    var tsmPicTags = []
    var ssignTags = []
    var tsignTags = []
    var othTags = []
    var picTags = []
    var appTags = []
    var authTags = []
    var recalTags = []
    var esignTags = []
    var smssignTags = []
    var paymentTags = []
    var presignTags = []
    var tsmcnpTags = []

    for (var i = 0; i < documents.length; i++) {
      var document = new Document(documents[i])
      if (document.document_type) {
        document.tagColor = this.getDocumentTagColorWithType(document.document_type)
        document.tagName = this.getDocumentTagNameWithType(document.document_type)
        if (document.document_type === 'swo') {
          swoTags.push(document)
        } else if (document.document_type === 'dis') {
          disTags.push(document)
        } else if (document.document_type === 'pop') {
          popTags.push(document)
        } else if (document.document_type === 'fiv') {
          fivTags.push(document)
        } else if (document.document_type === 'quo') {
          quoTags.push(document)
        } else if (document.document_type === 'dl') {
          dlTags.push(document)
        } else if (document.document_type === 'ssmpic') {
          ssmPicTags.push(document)
        } else if (document.document_type === 'tsmpic') {
          tsmPicTags.push(document)
        } else if (document.document_type === 'ssign') {
          ssignTags.push(document)
        } else if (document.document_type === 'tsign') {
          tsignTags.push(document)
        } else if (document.document_type === 'oth') {
          othTags.push(document)
        } else if (document.document_type === 'pic') {
          picTags.push(document)
        } else if (document.document_type === 'app') {
          appTags.push(document)
        } else if (document.document_type === 'auth') {
          authTags.push(document)
        } else if (document.document_type === 'recal') {
          recalTags.push(document)
        } else if (document.document_type === 'esign') {
          esignTags.push(document)
        } else if (document.document_type === 'smssign') {
          smssignTags.push(document)
        } else if (document.document_type === 'payment') {
          paymentTags.push(document)
        } else if (document.document_type === 'presign') {
          presignTags.push(document)
        } else if (document.document_type === 'tsmcnp') {
          tsmcnpTags.push(document)
        }
      }
    }

    var allArrays = [swoTags, disTags, popTags, fivTags, quoTags, dlTags, ssmPicTags, tsmPicTags, ssignTags, tsignTags, othTags, picTags, appTags, authTags, recalTags, esignTags, smssignTags, paymentTags, presignTags, tsmcnpTags]
    for (var j = 0; j < allArrays.length; j++) {
      var array = allArrays[j]
      if (array.length > 1) {
        for (var k = 0; k < array.length; k++) {
          var object = array[k]
          object.tagName = object.tagName + '-' + (k + 1)
        }
      }
    }

    documentTags = documentTags
      .concat(swoTags)
      .concat(disTags)
      .concat(popTags)
      .concat(fivTags)
      .concat(quoTags)
      .concat(dlTags)
      .concat(ssmPicTags)
      .concat(tsmPicTags)
      .concat(ssignTags)
      .concat(tsignTags)
      .concat(othTags)
      .concat(picTags)
      .concat(appTags)
      .concat(authTags)
      .concat(recalTags)
      .concat(esignTags)
      .concat(smssignTags)
      .concat(paymentTags)
      .concat(presignTags)
      .concat(tsmcnpTags)

    return documentTags
  }

  static getAll (shopId, jobId, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/shop/${shopId}/job/${jobId}/document`}).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new Document(val))
      })
      callbackFunction(objects)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  createUpdateDocument (shopId, job_id, callbackFunction, errorCallbackFunction = null) {
    
    if (!this.id || this.id <= 0) {
      this.job_id = job_id
      
      this.id = -1
    }
    
    return Api.post(`/api/shop/${shopId}/job/${this.job_id}/document/${this.id}`, this).then(res => {
      
      
      callbackFunction(new Document(res.data))
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  deleteDocument (shopId, successCallbackFunction, errorCallbackFunction = null) {
    
    
    if (!this.id || this.id <= 0) {
      successCallbackFunction()
    }
    return Api.delete(`/api/shop/${shopId}/job/${this.job_id}/document/${this.id}`, this).then(res => {
      
      
      successCallbackFunction()
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
