var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-bg-gray-100 t-py-6 t-px-12 t-mb-8" }, [
    _vm.grandTotals
      ? _c("div", { staticClass: "t-text-center t-text-lg t-mb-5" }, [
          _vm._v(" Aging Summary ")
        ])
      : _vm._e(),
    _c("div", { staticClass: "t-flex t-space-x-12" }, [
      _c(
        "div",
        {
          staticClass:
            "t-w-1/5 t-rounded-md t-border t-border-solid t-border-green-200\n        t-flex t-flex-col t-items-center t-justify-center t-bg-white\n        t-shadow",
          class: { "t-h-24": _vm.groupBy !== "consumer" }
        },
        [
          _c("div", { staticClass: "t-text-xs t-text-green-500" }, [
            _vm._v(" Under 31 days ")
          ]),
          _c("div", { staticClass: "t-text-xl t-text-green-700" }, [
            _vm._v(" $" + _vm._s(_vm.zeroThirtyBalance) + " ")
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "t-w-1/5 t-rounded t-border t-border-solid t-border-yellow-200\n        t-flex t-flex-col t-items-center t-justify-center t-bg-white\n        t-shadow",
          class: { "t-h-24": _vm.groupBy !== "consumer" }
        },
        [
          _c("div", { staticClass: "t-text-xs t-text-yellow-600" }, [
            _vm._v(" 31 - 60 days ")
          ]),
          _c("div", { staticClass: "t-text-xl t-text-yellow-700" }, [
            _vm._v(" $" + _vm._s(_vm.thirtySixtyBalance) + " ")
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "t-w-1/5 t-rounded t-border t-border-solid t-border-orange-200\n        t-flex t-flex-col t-items-center t-justify-center t-bg-white\n        t-shadow",
          class: { "t-h-24": _vm.groupBy !== "consumer" }
        },
        [
          _c("div", { staticClass: "t-text-xs t-text-orange-500" }, [
            _vm._v(" 61 - 90 days ")
          ]),
          _c("div", { staticClass: "t-text-xl t-text-orange-700" }, [
            _vm._v(" $" + _vm._s(_vm.sixtyNinetyBalance) + " ")
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "t-w-1/5 t-rounded t-border t-border-solid t-border-red-200\n        t-flex t-flex-col t-items-center t-justify-center t-bg-white\n        t-shadow",
          class: { "t-h-24": _vm.groupBy !== "consumer" }
        },
        [
          _c("div", { staticClass: "t-text-xs t-text-red-500" }, [
            _vm._v(" 91+ days ")
          ]),
          _c("div", { staticClass: "t-text-xl t-text-red-700" }, [
            _vm._v(" $" + _vm._s(_vm.ninetyPlusBalance) + " ")
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "t-w-1/5 t-rounded t-border t-border-solid t-border-blue-200\n        t-flex t-flex-col t-items-center t-justify-center t-bg-white\n        t-shadow",
          class: { "t-h-24": _vm.groupBy !== "consumer" }
        },
        [
          _c("div", { staticClass: "t-text-xs t-text-blue-500" }, [
            _vm._v(" Total outstanding ")
          ]),
          _c("div", { staticClass: "t-text-xl t-text-blue-700" }, [
            _vm._v(" $" + _vm._s(_vm.totalBalance) + " ")
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }