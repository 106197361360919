<template>
  <transition
    v-on:before-leave="beforeLeave"
    v-on:after-leave="afterLeave"
    v-on:leave="leave"
    v-on:before-enter="beforeEnter"
    v-on:after-enter="afterEnter"
    v-on:enter="enter"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  methods: {
    beforeLeave(el) {
      if (!el.dataset) el.dataset = {}
      el.dataset.oldPaddingLeft = el.style.paddingLeft
      el.dataset.oldPaddingRight = el.style.paddingRight
      el.dataset.oldOverflow = el.style.overflow

      el.style.width = el.scrollWidth + 'px'
      el.style.overflow = 'hidden'
    },
    leave(el) {
      if (el.scrollWidth !== 0) {
        el.classList.add('collapse-left-transition')
        el.style.width = 0
        el.style.paddingLeft = 0
        el.style.paddingRight = 0
      }
    },
    afterLeave(el) {
      el.classList.remove('collapse-left-transition')
      el.style.width = ''
      el.style.overflow = el.dataset.oldOverflow
      el.style.paddingLeft = el.dataset.oldPaddingLeft
      el.style.paddingRight = el.dataset.oldPaddingRight
    },
    beforeEnter(el) {
      el.classList.add('collapse-left-transition')
      if (!el.dataset) el.dataset = {}

      el.dataset.oldPaddingLeft = el.style.paddingLeft
      el.dataset.oldPaddingRight = el.style.paddingRight

      el.style.width = '0'
      el.style.paddingLeft = 0
      el.style.paddingRight = 0
    },
    enter(el) {
      el.dataset.oldOverflow = el.style.overflow
      if (el.scrollWidth !== 0) {
        el.style.width = el.scrollWidth + 'px'
        el.style.paddingLeft = el.dataset.oldPaddingLeft
        el.style.paddingRight = el.dataset.oldPaddingRight
      } else {
        el.style.width = ''
        el.style.paddingLeft = el.dataset.oldPaddingLeft
        el.style.paddingRight = el.dataset.oldPaddingRight
      }

      el.style.overflow = 'hidden'
    },
    afterEnter(el) {
      el.classList.remove('collapse-left-transition')
      el.style.width = ''
      el.style.overflow = el.dataset.oldOverflow
    }
  }
}
</script>
<style lang="scss">
.collapse-left-transition {
  transition: width .2s ease-in-out;
}
</style>
