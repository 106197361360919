var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-header" },
    [
      _c(
        "b-collapse",
        {
          attrs: { id: "header-filters" },
          model: {
            value: _vm.showFilters,
            callback: function($$v) {
              _vm.showFilters = $$v
            },
            expression: "showFilters"
          }
        },
        [
          _c("div", { staticClass: "header-content" }, [
            _c(
              "div",
              { staticClass: "header-content-row" },
              [
                _c("scheduler-view-filter", {
                  model: {
                    value: _vm.view,
                    callback: function($$v) {
                      _vm.view = $$v
                    },
                    expression: "view"
                  }
                }),
                _c("scheduler-route-modal")
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header-content-row" },
              [
                _c("scheduler-team-filter", {
                  model: {
                    value: _vm.shops,
                    callback: function($$v) {
                      _vm.shops = $$v
                    },
                    expression: "shops"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header-content-row" },
              [
                _vm.isDayView
                  ? _c("scheduler-week-filter", {
                      model: {
                        value: _vm.week,
                        callback: function($$v) {
                          _vm.week = $$v
                        },
                        expression: "week"
                      }
                    })
                  : _c("scheduler-date-filter", {
                      model: {
                        value: _vm.date,
                        callback: function($$v) {
                          _vm.date = $$v
                        },
                        expression: "date"
                      }
                    }),
                _c("scheduler-card-view-filter", {
                  model: {
                    value: _vm.cardView,
                    callback: function($$v) {
                      _vm.cardView = $$v
                    },
                    expression: "cardView"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header-content-row" },
              [_c("scheduler-settings-filter")],
              1
            ),
            _c("div", { staticClass: "header-content-row" }, [
              _c(
                "div",
                { staticClass: "mobile-view-button" },
                [
                  _c("el-button", { on: { click: _vm.toggleView } }, [
                    _vm._v(_vm._s(_vm.rowView ? "Row view" : "Column view"))
                  ])
                ],
                1
              )
            ]),
            _c("div", { staticClass: "header-content-row" })
          ]),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-right": "10px"
                        }
                      },
                      [_c("scheduler-job-counters")],
                      1
                    ),
                    !_vm.isDayView && _vm.shops.length > 0
                      ? _c("el-divider", {
                          staticClass: "v",
                          attrs: { direction: "vertical" }
                        })
                      : _vm._e(),
                    !_vm.isDayView && _vm.shops.length > 0
                      ? _c("el-divider", { staticClass: "h" })
                      : _vm._e(),
                    !_vm.isDayView && _vm.shops.length > 0
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-block",
                              "margin-left": "10px"
                            }
                          },
                          [
                            _c("confirmation-countdown", {
                              on: {
                                setUpdatedShopsSettings:
                                  _vm.setUpdatedShopsSettings
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.isMobile
        ? _c(
            "div",
            { staticClass: "toggle-filters", on: { click: _vm.toggleFilters } },
            [
              _vm._v(" Filters "),
              _c("v-icon", {
                class: { "fa-rotate-180": _vm.showFilters },
                attrs: { name: "angle-down" }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "separator" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }