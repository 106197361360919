<template>
  <span>
    <span :class="type != 'none' ? 'qbo-button' : ''" v-if="type == 'none' || $job.quickbooksDetails.display === 'yes'"  @click="showQuickBooksHistoryDialog()">
      <span class="qbo-icon">QBO</span>
      <span v-if="type != 'none' && $job.quickbooksDetails.loading != 'yes'" :class="quickbooksTotalClasses">{{ quickBooksTotal }}</span>
      <img v-if="type != 'none' && $job.quickbooksDetails.loading == 'yes'" class="qbo-loader" src='../../../assets/img/spinner.gif'>
    </span>
  </span>
</template>
<script>
import { sync } from 'vuex-pathify'

export default {
  props: ['type'],
  methods: {
    showQuickBooksHistoryDialog() {
      this.quickBooksHistory = true
    },
    formatCurrency: function (val) {
      if (!isNaN(val)) {
        if (parseFloat(val) === 0) {
          return '$0.00'
        } else {
          return '$' + parseFloat(val).toFixed(2)
        }
      }
      return '$'
    }
  },
  computed: {
    quickBooksHistory: sync('jobDetails/actionDialogs@quickbooksHistory'),
    quickbooksTotalClasses () {
      var classes = []
      classes.push('qbo-total')
      var colorClass = 'qbo-red-color'
      try {
        if (this.type === 'balance') {
          var balanceAndDeductible = (this.$job.job.total_balance_after_payments + this.$job.job.total_deductible_after_payments)
          colorClass = this.formatCurrency(balanceAndDeductible) === this.formatCurrency(this.$job.quickbooksDetails.balance) ? 'qbo-black-color' : 'qbo-red-color'
        } else {
          colorClass = this.formatCurrency(this.$job.job.total_after_taxes) === this.formatCurrency(this.$job.quickbooksDetails.total) ? 'qbo-black-color' : 'qbo-red-color'
        }
      } catch (err) {}
      classes.push(colorClass)
      return classes
    },
    quickBooksTotal () {
      if (this.type === 'balance') {
        return this.formatCurrency(this.$job.quickbooksDetails.balance)
      } else {
        return this.formatCurrency(this.$job.quickbooksDetails.total)
      }
    }
  }
}
</script>

<style scoped>
.qbo-button {
  cursor: pointer;
}

.qbo-total {
  font-size:13px;
}

.qbo-black-color {
  color: black;
}

.qbo-red-color {
  color: red;
}

.qbo-loader {
  width:18px;
  height:18px;
  margin-right:6px;
}

.qbo-icon {
  background-color:green;
  color:white;
  margin-left:2px;
  margin-right:1px;
  padding:5px;
  border-radius:5px;
  font-size:13px;
}

</style>
