import { render, staticRenderFns } from "./ConsumerSettings.vue?vue&type=template&id=48db7631&scoped=true&"
import script from "./ConsumerSettings.vue?vue&type=script&lang=js&"
export * from "./ConsumerSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48db7631",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48db7631')) {
      api.createRecord('48db7631', component.options)
    } else {
      api.reload('48db7631', component.options)
    }
    module.hot.accept("./ConsumerSettings.vue?vue&type=template&id=48db7631&scoped=true&", function () {
      api.rerender('48db7631', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/modals/ConsumerDialog/ConsumerSettings.vue"
export default component.exports