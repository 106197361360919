<template>
  <label
    class="gb-checkbox"
    @click.prevent="checked = !checked"
    :class="{ 'no-slot': !$slots.default, locked, disabled }"
  >
    <transition name="fade-color">
      <i
        class="material-icons-outline"
        v-if="value"
        :class="{
          'md-done': true,
          'md-18': true,
          focused,
          checked,
        }"
      />
    </transition>
    <i
      class="material-icons-outline"
      v-if="indeterminate"
      :class="{
        'md-indeterminate_check_box': true,
        focused: true,
      }"
    />
    <i
      v-else
      class="material-icons-outline"
      :class="{
        'md-check_box_outline_blank': true,
        focused,
        checked,
      }"
    />
    <input
      type="checkbox"
      @focus="focused = true"
      @blur="focused = false"
      :checked="checked"
    />
    <span :class="{ checked }">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  data() {
    return {
      focused: false
    }
  },
  computed: {
    checked: {
      get() {
        return !!this.value
      },
      set() {
        this.$emit('input', !this.value)
      }
    }
  },
  props: { value: {}, indeterminate: Boolean, locked: Boolean, disabled: {} }
}
</script>
<style lang="scss" scoped>
.gb-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  .md-done {
    position: absolute;
    left: 3px;
    top: 3px;
    z-index: 100;
    transform: scale(0.8);
  }

  &.no-slot {
    width: 24px;
  }
  i {
    color: rgba(0, 0, 0, 0.54);
    transition: color 0.1s ease-in-out;
    &.focused {
      color: $primary;
    }
  }
  input {
    width: 0px;
    height: 0px;
    display: none;
  }
  span {
    font-weight: 400;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    font-size: $font-sm;
    line-height: 12px;
    text-transform: none;
    margin-left: $padding-xxs;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }

  i,
  span {
    transition: color 0.15s ease-in-out;
  }

  i.checked,
  span.checked {
    color: $primary;
  }

  .fade-color-enter-active,
  .fade-color-leave-active {
    transition: opacity 0.15s ease-in-out;
  }
  .fade-color-enter-to, .fade-color-leave /* .fade-leave-active below version 2.1.8 */ {
    opacity: 1;
    transform: scale(0.8);
  }
  .fade-color-enter, .fade-color-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: scale(0.6);
  }
  &.locked {
    pointer-events: none;

    i.checked,
    i,
    span.checked,
    span {
      color: $watermelon-pink;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  & + .gb-checkbox {
    margin-left: $padding-md;
  }
}
</style>
