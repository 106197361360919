var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bay-view" }, [
    _c("div", { staticClass: "job-list" }, [_c("scheduler-left-card-list")], 1),
    _c(
      "div",
      { staticClass: "bay-view-list-container" },
      _vm._l(_vm.bays, function(bay) {
        return _c(
          "bay-job-card-list",
          _vm._b(
            { key: bay.id, attrs: { items: _vm.getBayJobs(bay.id) } },
            "bay-job-card-list",
            bay,
            false
          )
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }