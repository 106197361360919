<template>
  <el-dialog
    append-to-body
    top="100px"
    width="1200px"
    custom-class="view-payment-dialog"
    :visible="visible"
    :before-close="handleClose"
    @open="onOpen"
  >
    <span slot="title" class="t-text-blue-100 t-text-lg">
      <i class="el-icon-bank-card"></i>
      <span class="t-ml-3">Payment details</span>
    </span>
    <div v-if="!shop || !paymentGroup">
      <div class="t-w-full t-h-32 t-flex t-justify-center">
        <div v-loading="true" class="t-w-12"></div>
      </div>
    </div>
    <div v-else class="t-pt-6 t-pb-10 t-px-10 t-bg-gray-200">
      <div class="t-mb-3 t-text-right">
        <el-button
          v-if="paymentGroup.x_action_type !== 'Card'"
          @click="editPaymentVisible = true"
          icon="el-icon-edit"
          type="warning"
          size="small"
        >
          Edit
        </el-button>
        <el-button
          @click="getPdf('download')"
          icon="el-icon-download"
          type="warning"
          size="small"
          :loading="!!loading"
        >
          Download
        </el-button>
        <el-button
          @click="getPdf('print')"
          icon="el-icon-printer"
          type="warning"
          size="small"
          :loading="!!loading"
        >
          Print
        </el-button>
        <el-dropdown @command="getPdf" hide-on-click style="margin:0 10px">
          <el-button
            icon="el-icon-share"
            type="warning"
            size="small"
            :loading="!!loading"
          >
            Share
          </el-button>
          <el-dropdown-menu slot="dropdown" class="job-page-actions-dropdown">
            <el-dropdown-item
              :icon="'email'"
              :command="'email'"
            >
              Email
            </el-dropdown-item>
            <el-dropdown-item
              :icon="'sms'"
              :command="'sms'"
            >
              SMS
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          @click="deletePaymentGroup"
          icon="el-icon-edit"
          type="danger"
          size="small"
        >
          Delete
        </el-button>
      </div>
      <div
        class="t-bg-white t-rounded t-shadow"
        style="padding: 30px 60px"
        ref="body"
      >
        <div class="t-flex t-items-center t-py-4">
          <div v-if="shop.logo.length > 0" class="t-w-56 t-mr-8">
            <img
              :src="shop.logo"
              alt="shop logo"
              class="t-w-full"
            />
          </div>
          <div class="t-flex-grow t-text-right">
            <div class="t-text-xl t-font-medium t-text-gray-800 t-mb-2">
              {{ shop.name }}
            </div>
            <div class="t-text-gray-600">{{ shop.address }}</div>
            <div class="t-text-gray-600">
              {{ shop.address_city }},
              {{ shop.address_state }}
              {{ shop.address_zip }}
            </div>
          </div>
        </div>
        <el-divider/>
        <div class="t-text-center t-text-xl t-text-gray-700 t-pb-4">
          Payment Receipt #{{ paymentGroup.id }}
        </div>
        <div class="t-flex t-mt-10 t-pb-8">
          <div class="t-flex-grow">
            <info-row :name="'Type'" :value="paymentGroup.type" />
            <info-row :name="'Date'" :value="date" />
            <info-row
              :name="'X-action Type'"
              :value="paymentGroup.x_action_type"
            />
            <info-row
              :name="'X-action Number'"
              :value="paymentGroup.x_action_number"
            />
            <info-row
              v-if="paymentGroup.payment_account_name"
              :name="'Payment account'"
              :value="'Liabilities: ' + paymentGroup.payment_account_name"
            />
          </div>
          <div
            class="t-bg-green-500 t-w-56 t-flex t-flex-col t-items-center
                   t-justify-center t-text-white h-36"
          >
            <div class="t-text-lg">Amount received</div>
            <div class="t-text-3xl">${{ totalAmount }}</div>
          </div>
        </div>
        <div class="t-mt-10">
          <el-table
            :data="paymentGroup.payments"
            header-cell-class-name="t-bg-gray-200 t-text-gray-700"
          >
            <el-table-column label="Invoice Number" width="150">
              <template slot-scope="props">
                <a :href="'/jobs/' + props.row.job_id" target="_blank">
                  {{ props.row.job_id }}
                </a>
              </template>
            </el-table-column>
            <el-table-column label="Customer">
              <template slot-scope="props">
                {{
                  props.row.company_name
                    ? props.row.company_name
                    : `${props.row.first_name || ''} ${props.row.last_name || ''}`
                }}
              </template>
            </el-table-column>
            <el-table-column label="Vehicle">
              <template slot-scope="props">
                <div class="t-break-normal">
                  {{
                    [
                      props.row.year,
                      props.row.make,
                      props.row.model,
                      props.row.style
                    ].join(' ')
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Memo">
              <template slot-scope="props">
                {{ props.row.memo }}
              </template>
            </el-table-column>
            <el-table-column label="Invoice Amount" width="150">
              <template slot-scope="props">
                ${{ props.row.amount }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <edit-payment
      :visible="editPaymentVisible"
      :paymentGroup="paymentGroup"
      @close="editPaymentVisible = false"
      @updated="handlePaymentUpdated"
    />
    <a v-if="paymentGroup" :href="this.data" :download="filename" ref="dl" v-show="false" />
  </el-dialog>
</template>

<script>
import moment from 'moment'
import EditPayment from './EditPayment.vue'
import InfoRow from './ViewPaymentInfoRow.vue'
import { paymentGroupRepository } from '@/scripts/repositories/paymentGroup'
import { getTodaysDate } from '@/scripts/helpers/date.helpers'
import { sync, call } from 'vuex-pathify'
import print from 'print-js'
import Pdf from '@/scripts/objects/pdf'
import * as filestack from 'filestack-js'
import { TextActionsMixin } from '../../../components/JobPageV2/mixins/text-actions.mixin'
import MenuActionsMixin from '../../../components/JobPageV2/mixins/menu-actions.mixin.js'

export default {
  props: {
    visible: Boolean,
    paymentGroupId: null,
    filePickerKey: String
  },
  data: () => ({
    shop: null,
    paymentGroup: null,
    editPaymentVisible: false,
    loading: null,
    data: null,
    dataBlob: null,
    file: null
  }),
  mixins: [TextActionsMixin, MenuActionsMixin],
  computed: {
    date: function() {
      return moment.utc(this.paymentGroup.date).format('LL')
    },
    totalAmount: function() {
      let amount = 0
      for (const i of this.paymentGroup.payments) {
        amount += i.amount
      }
      return Math.round(amount * 100) / 100
    },
    filename() {
      return `PaymentReceipt-${this.paymentGroup.id}-${getTodaysDate()}`
    },
    emailDialog: sync('jobDetails/actionDialogs@email')
  },
  watch: {
    paymentGroupId: function(value) {
      if (value) {
        this.getData()
      }
    }
  },
  methods: {
    handleClose: function() {
      this.shop = null
      this.paymentGroup = null
      this.$emit('closed')
    },
    getData: async function() {
      this.paymentGroup =
        await paymentGroupRepository.getPaymentGroupDetails(this.paymentGroupId)
      this.shop =
        this.$store.state.shops.find(i => i.id === this.paymentGroup.shop_id)
    },
    tableHeaderClassName: function() {
      return 't-bg-gray-200 t-text-gray-700'
    },
    deletePaymentGroup: async function() {
      try {
        await this.$messageBox.confirm(
          'Are you sure you want to delete this payment ?',
          {
            type: 'error',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            beforeClose: async (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonText = 'Loading...'
                instance.confirmButtonLoading = true
                try {
                  await paymentGroupRepository.remove(this.paymentGroupId)
                  instance.confirmButtonLoading = false
                  this.$emit('paymentRemoved')
                  this.handleClose()
                  done()
                } catch {
                  instance.confirmButtonText = 'Yes'
                  instance.confirmButtonLoading = false
                }
              } else {
                done()
              }
            }
          }
        )
      } catch {}
    },
    handlePaymentUpdated: function() {
      this.$emit('paymentUpdated')
      this.getData()
    },
    onOpen() {
      this.file = null
      this.data = null
    },
    async getPdf(action) {
      if (!this.data) {
        try {
          this.loading = action
          const data = await Pdf.renderPaymentReceiptPdf({
            shop: this.shop,
            paymentGroup: this.paymentGroup,
            date: this.date,
            totalAmount: this.totalAmount
          })
          this.convertPdfData(data)
        } catch (error) {
          this.$message({ type: 'error', message: 'Something went wrong' })
          console.log(error)
        } finally {
          this.loading = null
        }
      }

      if (['email', 'sms'].includes(action) && !this.file) {
        try {
          this.loading = action
          const client = filestack.init(this.filePickerKey)
          this.file = await client.upload(this.dataBlob, {}, { filename: this.filename })
        } catch (error) {
          this.$message({ type: 'error', message: 'Error while saving document' })
        } finally {
          this.loading = null
        }
      }

      if (action === 'email') {
        if (this.file) {
          this.file.paymentGroupId = this.paymentGroupId
          this.setEmailFiles([this.file])
          this.emailDialog = true
        }
      }
      if (action === 'sms') {
        this.setTextMessageInvoiceDocumentLink(this.file.url)
        this.setTextMessageInvoiceJobId(this.$job.job.id)
        this.setTextMessageInvoiceShopId(this.$job.job.shop_id)
        this.setTextMessageInvoiceJobStatus('Payment Receipt')
        this.openTextMessageInvoice()
      }
      if (action === 'print') {
        print({ printable: this.data })
      }

      this.$nextTick(() => {
        if (action === 'download') {
          this.$refs.dl.click()
        }
      })
    },
    convertPdfData(data) {
      this.dataBlob = new Blob([data.data], { type: 'application/pdf'})
      const URL = window.URL || window.webkitURL
      this.data = URL.createObjectURL(this.dataBlob)
    },
    setEmailFiles: call(
      'twilio/textMessages/jobEmail/setFiles'
    )
  },
  components: {
    InfoRow,
    EditPayment
  }
}
</script>

<style lang="scss">
.view-payment-dialog {
  @apply t-font-sans;
  .el-dialog__header {
    @apply t-bg-green-600 t-pl-6;
    button {
      i {
        @apply t-text-green-100;
      }
      i:hover {
        @apply t-text-green-200;
      }
    }
  }
  .el-dialog__body {
    padding: 0 !important;
  }
}
.h-36 {
   height: 144px;
 }
</style>
