var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "ui-input-label" }, [
        _vm._v("Display settings:")
      ]),
      _c(
        "el-select",
        {
          staticClass: "scheduler-settings-filter",
          attrs: { multiple: "" },
          model: {
            value: _vm.settings,
            callback: function($$v) {
              _vm.settings = $$v
            },
            expression: "settings"
          }
        },
        _vm._l(_vm.options, function(option) {
          return _c("el-option", {
            key: option.key,
            attrs: { label: option.label, value: option.key }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }