import ObjectTracker from '@/scripts/objects/objectTracker'

export default class GlaxisLossDamageInfo extends ObjectTracker {
  id = -1
  glaxis_loss_notice_id = -1
  location_of_damage = ''
  position_on_vehicle = ''
  relative_position_on_vehicle = ''
  side_of_vehicle = ''
  quantity_of_glass_for_loss = ''
  glass_only_loss = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }
}
