var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticClass: "t-mr-2",
      attrs: {
        "value-key": "serial",
        placeholder: "Choose device",
        size: "mini"
      },
      model: {
        value: _vm.lValue,
        callback: function($$v) {
          _vm.lValue = $$v
        },
        expression: "lValue"
      }
    },
    _vm._l(_vm.options, function(device) {
      return _c(
        "el-option",
        { key: device.serial, attrs: { value: device, label: device.name } },
        [
          _vm._v(
            " " + _vm._s(device.name) + ": " + _vm._s(device.product_name) + " "
          )
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }