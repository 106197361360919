var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "jobDetailsClaimDialog",
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.visible,
        appendToBody: true,
        id: "glaxisClaim",
        width: "1400px",
        "destroy-on-close": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.dialogAboutToAppear,
        close: _vm.dialogDisappeared
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "18px",
            "font-weight": "bold",
            "margin-bottom": "10px"
          }
        },
        [
          _c(
            "el-row",
            {
              staticClass: "claim-page-row top-cards-row",
              attrs: { gutter: 16, type: "flex" }
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "word-break": "break-word"
                  }
                },
                [
                  _vm._v(" Claim for Job # "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/jobs/" +
                          (_vm.loadedJobId > 0 ? _vm.loadedJobId : ""),
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.loadedJobId > 0 ? _vm.loadedJobId : "") + " "
                      )
                    ]
                  ),
                  _vm.glaxisClaim && _vm.glaxisClaim.id > 0
                    ? _c("ClaimStatus", {
                        attrs: {
                          jobId: _vm.loadedJobId,
                          includeClaimStatusText: false
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "claim-page-row top-cards-row",
              attrs: { gutter: 16, type: "flex" }
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "word-break": "break-word"
                  }
                },
                [
                  this.glaxisClaim &&
                  this.glaxisClaim.status === "Coverage Response Received" &&
                  !_vm.loading
                    ? _c(
                        "el-button",
                        {
                          staticClass: "reviewCoverageButton",
                          attrs: { type: "secondary", icon: "el-icon-edit" },
                          on: { click: _vm.reviewCoverage }
                        },
                        [_vm._v(" Review Coverage ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "word-break": "break-word"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "actionButtonsDiv" },
                    [
                      this.glaxisClaim &&
                      this.glaxisClaim.id > 0 &&
                      !_vm.loading
                        ? _c(
                            "el-button",
                            {
                              staticClass: "refreshButton",
                              attrs: {
                                type: "primary",
                                icon: "el-icon-refresh"
                              },
                              on: { click: _vm.reloadClaim }
                            },
                            [_vm._v(" Refresh ")]
                          )
                        : _vm._e(),
                      this.glaxisClaim &&
                      this.glaxisClaim.id > 0 &&
                      !_vm.loading &&
                      _vm.showSubmitResubmitButton &&
                      _vm.showUpdateToMatchJobButton
                        ? _c(
                            "el-button",
                            {
                              staticClass: "updateToMatchJobButton",
                              attrs: {
                                type: "secondary",
                                icon: "el-icon-upload"
                              },
                              on: { click: _vm.updateToMatchJob }
                            },
                            [_vm._v(" Update To Match Job ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.loading
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-md-12",
                staticStyle: { "font-size": "16px", "text-align": "center" }
              },
              [
                _c("p", [
                  _c("img", {
                    staticStyle: {
                      width: "18px",
                      height: "18px",
                      "margin-right": "6px"
                    },
                    attrs: {
                      src: require("../../../../assets/img/spinner.gif")
                    }
                  }),
                  _vm._v(" Loading please wait...")
                ])
              ]
            )
          ])
        : _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "claimInfoArea" },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "claim-page-row top-cards-row",
                      attrs: { gutter: 16, type: "flex" }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "white-space": "pre-wrap",
                            "word-break": "break-word"
                          }
                        },
                        [
                          _vm._v(
                            " Review the details below and fill in any additional fields for the Claim submission. If you need to change job fields, please edit the job. "
                          ),
                          !_vm.isLive && this.lossNotice.id < 0
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "prefillTestData",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.prefillWithTestData }
                                },
                                [_vm._v(" Prefill With Test Data ")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      this.glaxisClaim &&
                      this.glaxisClaim.response_details &&
                      this.glaxisClaim.response_details.length > 0
                        ? _c(
                            "el-col",
                            {
                              staticStyle: {
                                "white-space": "pre-wrap",
                                "word-break": "break-word"
                              }
                            },
                            [
                              _c(
                                "div",
                                { style: { color: _vm.claimStatusColor } },
                                [
                                  _vm._v(
                                    " Response Details: " +
                                      _vm._s(
                                        this.glaxisClaim.response_details
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticClass: "claim-page-row top-cards-row",
                  attrs: { gutter: 16, type: "flex" }
                },
                [
                  _c("InsuredCustomer", {
                    ref: "insuredCustomer",
                    attrs: {
                      updateSchemaLabelsWithMatchingAndSubrogation:
                        _vm.updateSchemaLabelsWithMatchingAndSubrogation,
                      lossNotice: _vm.lossNotice,
                      customer: _vm.customer,
                      fieldsDisabled: _vm.fieldsDisabled
                    }
                  }),
                  _c("Insurance", {
                    ref: "insurance",
                    attrs: {
                      updateSchemaLabelsWithMatchingAndSubrogation:
                        _vm.updateSchemaLabelsWithMatchingAndSubrogation,
                      lossNotice: _vm.lossNotice,
                      billTo: _vm.billTo,
                      supportedInsurers: _vm.supportedInsurers,
                      job: _vm.job,
                      fieldsDisabled: _vm.fieldsDisabled
                    }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticClass: "claim-page-row top-cards-row",
                  attrs: { gutter: 16, type: "flex" }
                },
                [
                  _c("Vehicle", {
                    ref: "vehicle",
                    attrs: {
                      updateSchemaLabelsWithMatchingAndSubrogation:
                        _vm.updateSchemaLabelsWithMatchingAndSubrogation,
                      lossNotice: _vm.lossNotice,
                      vehicle: _vm.vehicle,
                      fieldsDisabled: _vm.fieldsDisabled
                    }
                  }),
                  _c("AdditionalInfo", {
                    ref: "additionalInfo",
                    attrs: {
                      updateSchemaLabelsWithMatchingAndSubrogation:
                        _vm.updateSchemaLabelsWithMatchingAndSubrogation,
                      lossNotice: _vm.lossNotice,
                      shop: _vm.shop,
                      fieldsDisabled: _vm.fieldsDisabled
                    }
                  })
                ],
                1
              ),
              _c("DamageInfo", {
                attrs: {
                  damageInformationArray: _vm.damageInformationArray,
                  fieldsDisabled: _vm.fieldsDisabled,
                  removeDamageInformation: _vm.removeDamageInformation
                }
              }),
              _c(
                "div",
                {
                  staticClass:
                    "t-py-3 t-px-6 t-flex t-border-0 t-border-t t-border-solid"
                },
                [
                  _vm.submittingClaim
                    ? _c("div", { staticClass: "claimSubmitButton" }, [
                        _vm._v("Submitting Claim... "),
                        _c("img", {
                          staticStyle: {
                            width: "18px",
                            height: "18px",
                            "margin-right": "6px"
                          },
                          attrs: {
                            src: require("../../../../assets/img/spinner.gif")
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm.showSubmitResubmitButton
                    ? _c(
                        "el-button",
                        {
                          staticClass: "claimSubmitButton",
                          attrs: { type: "primary" },
                          on: { click: _vm.submitClaim }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.glaxisClaim && _vm.glaxisClaim.id > 0
                                  ? "Resubmit"
                                  : "Submit"
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.confirmPopup, appendToBody: true },
          on: {
            "update:visible": function($event) {
              _vm.confirmPopup = $event
            }
          }
        },
        [
          _c("el-row", { staticClass: "dialog-header-text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.confirmPopupType === "submitClaim"
                    ? "Submit Claim"
                    : _vm.confirmPopupType === "removeDamageInformation"
                    ? "Remove Damage Information"
                    : ""
                ) +
                " "
            )
          ]),
          _c("br"),
          _c("el-row", { staticClass: "dialog-body-text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.confirmPopupType === "submitClaim"
                    ? "Are you sure you would like to submit this claim?"
                    : _vm.confirmPopupType === "removeDamageInformation"
                    ? "Are you sure you want to delete this damage information?"
                    : ""
                ) +
                " "
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleConfirmPopup } }, [
                _vm._v("Yes")
              ]),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.confirmPopup = false
                    }
                  }
                },
                [_vm._v("No")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.alertPopup, appendToBody: true },
          on: {
            close: _vm.closeAlertPopupEvent,
            "update:visible": function($event) {
              _vm.alertPopup = $event
            }
          }
        },
        [
          _c("el-row", { staticClass: "dialog-body-text" }, [
            _vm._v(_vm._s(_vm.alertPopupText))
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.alertPopup = false
                    }
                  }
                },
                [_vm._v("Ok")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }