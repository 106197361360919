<template>
  <el-dialog
    v-if="method === 'sms'"
    :visible.sync="isOpen"
    @input="$emit('input')"
    title="SMS"
    append-to-body
    @closed="onClose"
    @open="onOpen"
    width="1100px"
    okText="Send"
    @on-save="submit"
    :loading="loading"
  >
    <div>SMS</div>
  </el-dialog>
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  components: {},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    onClose() {
      console.log('close')
    },
    async onOpen() {
      console.log('open')
    },
    async submit() {
      console.log('submit')
    }
  },
  computed: {
    method: sync('share/method'),
    isOpen: sync('share/visible'),
    file: sync('share/file')
  }
}
</script>

<style lang="scss">
  .el-dialog__header {
    font-size: 14px;
  }

  .additional-attachments {
    width: 40%;

    &__files {
      line-height: 12px;

      .remove-icon {
        color: $crimson-red;
        vertical-align: text-bottom;

        &:hover {
          color: $watermelon-pink;
        }
      }
      button {
        font-size: 12px;
        padding: .275rem .65rem;
      }
    }
  }

  .receipt-label .el-loading-spinner {
    top: 0;
    margin-top: 0;
  }

  .dragging-overlay {
    &:after {
      content: "Drop Files to Upload";
      position: absolute;
      top: $padding-xs;
      bottom: $padding-xs;
      left: $padding-xs;
      right: $padding-xs;
      background: transparentize($primary, 0.7);
      border: 3px dashed $primary;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      color: $primary;
    }
  }

  .uploading-file {
    &:after {
      content: "";
      position: absolute;
      top: $padding-xs;
      bottom: $padding-xs;
      left: $padding-xs;
      right: $padding-xs;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      color: $primary;
    }
  }

  .new-item-2 {
    background-color: #409EFF;
    color: white;
    border-radius: 5px;
    padding: 0px 5px;
    margin-left: 5px;
    font-size: x-small;
    line-height: 1.5;
  }

  .ql-emoji > svg {
    border-radius: 10px;
    background-color: rgba(238, 255, 0, 0.6);
  }

</style>
