<template>
  <el-form-item v-if="value" class="mb-0">
    <span slot="label" class="undo-label-styles right-part-for-label">{{ name }}:</span>
    <span>{{ value }}</span>
  </el-form-item>
</template>

<script>
export default {
  props: ['name', 'value']
}
</script>
