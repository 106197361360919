import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class COAccount extends ObjectTracker {
  id = -1
  created = -1
  modified = -1
  accounting_package_id = -1
  number = ''
  created_by_user_id = -1
  modified_by_user_id = -1
  name = ''
  balance = 0
  parent_co_account_id = -1
  account_type = ''
  account_category = ''
  active = 1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getCOAccountWithDetails (accounting_package_id, co_account_id, callbackFunction = null, errorCallbackFunction = null) {
    return Api.get(`/api/accounting_package/${accounting_package_id}/co_account/${co_account_id}`
    ).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getCOAccountLedger (coAccount, sortBy, sortDesc, loadPosition, callbackFunction = null, errorCallbackFunction = null) {
    return Api.get(`/api/accounting_package/${coAccount.accounting_package_id}/co_account/${coAccount.id}/ledger` +
      '?sortBy=' + encodeURIComponent(sortBy) +
      '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&loadPosition=' + encodeURIComponent(loadPosition)
    ).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getLedgerDialog (accountingPackageId, displayTable, displayId, directTables, callbackFunction = null, errorCallbackFunction = null) {
    return Api.get(`/api/accounting_package/${accountingPackageId}/ledgerDialog` +
      '?displayTable=' + encodeURIComponent(displayTable) +
      '&displayId=' + encodeURIComponent(displayId) +
      '&directTables=' + encodeURIComponent(directTables.join())
    ).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getAccountTypeAndCategoryOptions (callbackFunction = null, errorCallbackFunction = null) {
    return Api.get(`/api/co_account_type_category_options`).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getStartingChartOfAccountsObjects (accountTypeFilter = null, accountCategoryFilter = null,
    callbackFunction = null, errorCallbackFunction = null) {
    return Api.get(`/api/co_accounts_starting?` +
      'request=objects' +
      (accountTypeFilter != null ? ('&accountTypeFilter=' + encodeURIComponent(accountTypeFilter)) : '') +
      (accountCategoryFilter != null ? ('&accountCategoryFilter=' + encodeURIComponent(accountCategoryFilter)) : '')
    ).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getChartOfAccountsObjects (accounting_package_id, accountTypeFilter, accountCategoryFilter,
    callbackFunction, errorCallbackFunction = null) {
    return Api.get(`/api/accounting_package/${accounting_package_id}/co_accounts?` +
      'request=objects' +
      (accountTypeFilter != null ? ('&accountTypeFilter=' + encodeURIComponent(accountTypeFilter)) : '') +
      (accountCategoryFilter != null ? ('&accountCategoryFilter=' + encodeURIComponent(accountCategoryFilter)) : '')
    ).then(res => {
      var coAccounts = []
      for (var i = 0; i < res.data.length; i++) {
        coAccounts.push(new COAccount(res.data[i]))
      }
      callbackFunction(coAccounts)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getChartOfAccountsTable (accounting_package_id, sortBy, sortDesc, loadPosition, showInactive,
    filter, callbackFunction = null, errorCallbackFunction = null) {
    return Api.get(`/api/accounting_package/${accounting_package_id}/co_accounts?` +
      'request=table' +
      'sortBy=' + encodeURIComponent(sortBy) +
      '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&loadPosition=' + encodeURIComponent(loadPosition) +
      '&showInactive=' + encodeURIComponent(showInactive) +
      '&filter=' + encodeURIComponent(filter)
    ).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getLedgerOpenLinkType (ledgerDisplayTable) {
    if (ledgerDisplayTable === 'bill' || ledgerDisplayTable === 'bill_payment' || ledgerDisplayTable === 'expense' || ledgerDisplayTable === 'journal_entry') {
      return 'popup'
    } else if (ledgerDisplayTable === 'job') {
      return 'link'
    } else {
      return ''
    }
  }

  static getLedgerURL (ledgerDisplayTable, ledgerDisplayId) {
    if (ledgerDisplayTable === 'job') {
      return '/jobs/' + ledgerDisplayId
    } else {
      return ''
    }
  }

  static getAllParentChartOfAccounts (accounting_package_id, callbackFunction = null, errorCallbackFunction = null) {
    return Api.get(`/api/accounting_package/${accounting_package_id}/parent_co_accounts`
    ).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  createUpdateCOAccount (accounting_package_id, callbackFunction, errorCallbackFunction = null) {
    if (!this.id || this.id <= 0) {
      this.accounting_package_id = accounting_package_id
      this.id = -1
    }

    return Api.post(`/api/accounting_package/${accounting_package_id}/co_account/${this.id}`, this).then(res => {
      var coAccount = new COAccount(res.data)
      callbackFunction(coAccount)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
