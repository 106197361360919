<template>
  <el-dialog
    center
    append-to-body
    :close-on-click-modal="false"
    :title="template === null ? 'Create a template' : 'Update a template'"
    :visible="visible"
    :before-close="handleClose"
    class="edit-form-template-dialog">
    <el-row>
      <el-col :span="7">
        <el-tabs tab-position="left">
          <el-tab-pane v-for="(pane, index) in templateVariables" :key="index" :label="pane.label">
            <p v-for="(option, index) in pane.options" :key="index">
              {{option.label}}: <span class="text-message-template-variable" @click="copyVariable">{{option.value}}</span>
            </p>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="17">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :hide-required-asterisk="true"
          label-width="140px"
          size="medium">
          <el-form-item prop="name">
            <span slot="label" class="undo-label-styles">Name</span>
            <el-input
              class="template-name-input"
              placeholder="Enter a name"
              v-model="form.name"
              @focus="focusTextarea(false)"
            />
          </el-form-item>
          <el-form-item prop="body" class="template-body-container">
            <span slot="label" class="undo-label-styles template-body-label">Template body</span>
            <gb-form
              class="template-body-input"
              :schema="schema"
              v-model="form"
              ref="templateTextarea"
              :key="forceUpdate"
            />
          </el-form-item>
          <el-form-item>
            <div>
              <el-button @click="addSignatureBlock" type="primary" plain size="mini">
                Add Signature Block
              </el-button>
              <el-button @click="addInitialBlock" type="primary" plain size="mini">
                Add Initial Block
              </el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              class="mb-2 mr-2"
              type="primary"
              @click="handleSubmit"
              :loading="loading">
              {{ template === null ? 'Create' : 'Update' }}
            </el-button>
            <el-button
              class="ml-0"
              plain
              @click="handleClose">
              Close
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor'
import { ImageDrop } from "quill-image-drop-module"
import signatureBlockImg from '@/assets/img/signature-block-img.png'
import initialBlockImg from '@/assets/img/initials-block-img.png'
import { templateVariables } from "@/scripts/helpers/gb_form.helpers";

Quill.register(
  {
    "modules/imageDrop": ImageDrop
  },
  true
);

export default {
  components: {
    VueEditor
  },
  props: ['visible', 'template'],
  data: () => ({
    form: {
      name: '',
      shop_id: null,
      body: '',
      textAreaFocus: false
    },
    rules: {
      name: { required: true, message: 'Please enter a name' },
      type_id: { required: true, message: 'Please select a type' },
      shop_id: { required: true, message: 'Please select a shop' },
      body: { required: true, message: 'Please enter a template body' }
    },
    templateBodySelection: {
      index: 0,
      length: 0
    },
    quill: null,
    types: [],
    loading: false,
    forceUpdate: 0,
    templateVariables
  }),
  computed: {
    shops () {
      return this.$store.state.shops
    },
    schema() {
      return [
        [
          {
            id: 'body',
            label: 'Body',
            type: 'wysiwyg',
            editorOptions: {
              modules: {
                toolbar: {
                  container: [
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    [
                      { align: '' },
                      { align: 'center' },
                      { align: 'right' },
                      { align: 'justify' }
                    ],
                    ['blockquote', 'code-block'],
                    [
                      { list: 'ordered' },
                      { list: 'bullet' },
                      { list: 'check' }
                    ],
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    ['link'],
                    ["image"],
                    ['clean'], // remove formatting button
                    [{'font': ['', 'arial', 'courier', 'dancing', 'garamond', 'times', 'trebuchet', 'verdana']}]
                  ],
                  handlers: {
                    imageAdded: function() {}
                  }
                },
                imageDrop: true
              }
            },
            onSelectionChange: (range, oldRange) => {
              if (range) {
                this.templateBodySelection = range
              } else {
                this.templateBodySelection = oldRange
              }
            },
            onFocus: (quill) => {
              this.quill = quill
              this.focusTextarea(true)
            }
          }
        ]
      ]
    }
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.form.shop_id = this.$job?.shop?.id || 1
        if (this.template !== null) {
          this.form.name = this.template.name
          this.form.body = this.template.body
        }
      }
    }
  },
  methods: {
    clearForm () {
      this.form = {
        name: '',
        shop_id: null,
        body: '',
      }
    },
    async handleClose () {
      this.$emit('close')
      await this.clearForm()
      this.$refs.form.clearValidate()
    },
    async handleSubmit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          if (this.template === null) {
            await this.$unum.job().post('gbForm/template', this.form)
          } else {
            await this.$unum.job().post(`gbForm/template/${this.template.id}`, this.form)
          }
          this.handleClose()
          this.loading = false
          this.$message.success(this.template === null ? 'Template successfully created.' : 'Template successfully updated.')
        }
      } catch (error) {
        this.loading = false
      }
    },
    copyVariable (event) {
      this.$copyText(event.target.innerText)
      if (this.textAreaFocus) {
        if (this.quill) {
          // insert text with styling
          this.quill.insertText(this.templateBodySelection.index, `${event.target.innerText} `, 'color', '#409eff');
          // remove styling from space following text
          const tempIndex = this.templateBodySelection.index + event.target.innerText.length
          this.quill.setSelection({...this.templateBodySelection, index: this.templateBodySelection.index + event.target.innerText.length})
          this.quill.removeFormat(tempIndex, 1)
        } else {
          this.form.body += event.target.innerText
        }
      } else {
        this.$message.info('Copied to clipboard')
      }
    },
    focusTextarea (v) {
      this.textAreaFocus = v
    },
    addSignatureBlock() {
      if (this.quill) {
        this.quill.insertEmbed(this.quill.getSelection().index, 'image', signatureBlockImg)
        this.quill.setSelection({...this.quill.getSelection(), index: this.quill.getSelection().index + 1})
      }
    },
    addInitialBlock() {
      if (this.quill) {
        this.quill.insertEmbed(this.quill.getSelection().index, 'image', initialBlockImg)
        this.quill.setSelection({...this.quill.getSelection(), index: this.quill.getSelection().index + 1})
      }
    }
  }
}
</script>

<style lang="scss">
  .edit-form-template-dialog {
    @apply t-font-sans;
    .el-dialog {
      min-width: 1500px;
    }
    .quillWrapper {
      min-width: 875px;
      max-width: 875px;
      img {
        vertical-align: baseline;
      }
      img {
        vertical-align: baseline;
      }
    }
    .text-message-template-variable {
      color: #409EFF;
      cursor: pointer;
      margin-left: 10px;
    }
    .template-name-input {
      max-width: 875px;
    }
    .template-body-label, .template-body-input {
      display:inline-block;
    }
    .template-body-container {
      margin-bottom: 0px;
    }
  }
</style>
