export const partTopListItemConfig = [
  {
    key: 'solar',
    label: 'Solar Coated',
    type: 'info'
  },
  {
    key: 'slider',
    label: 'Back Slider',
    type: 'info'
  },
  {
    key: 'heated',
    label: 'Heated',
    type: 'warning'
  },
  {
    key: 'anterior',
    label: 'Anterior',
    type: 'info'
  },
  {
    key: 'hds',
    label: 'HDS UP DISP',
    type: 'success'
  }
]

export const partTypeConfig = [
  {
    type: 'add_chip',
    key: 'is_addl_chip',
    label: 'Additional Chip',
    short: '+chp',
    color: '#f0ad4e',
    isOption: true
  },
  {
    type: 'moulding',
    key: 'moulding_discount',
    label: 'Moulding',
    short: 'mld',
    color: '#54a955',
    isOption: true
  },
  {
    type: 'chip',
    key: 'is_chip',
    label: 'Chip',
    short: 'chip',
    color: '#fdd49b',
    isOption: true
  },
  {
    type: 'custom',
    key: 'is_custom_part',
    label: 'Custom',
    short: 'cst',
    color: '#999999',
    isOption: true
  },
  {
    type: 'glass',
    key: 'is_oem',
    label: 'Glass',
    short: 'glass',
    color: '#299fd7',
    isOption: true
  },
  {
    type: 'glass',
    key: 'is_glass',
    label: 'Glass',
    short: 'glass',
    color: '#337ab7',
    isOption: true
  },
  {
    type: 'adhesive',
    key: 'is_adhesive_kit_part',
    label: 'Adhesive',
    short: 'adh',
    color: '#DC3545',
    isOption: true
  },
  {
    type: 'moulding',
    key: 'moulding_discount',
    label: 'Moulding',
    short: 'mld',
    color: '#54a955',
    isOption: true
  },

  {
    type: 'recal',
    key: 'is_recal',
    label: 'Recal',
    short: 'recal',
    color: '#805ad5'
  },

  {
    type: 'misc',
    label: 'Misc',
    short: 'misc',
    color: '#bcbcbc'
  }
]

export const partSearchHardwareConfig = {
  adhesive: {
    label: 'Adhesives',
    key: 'adhesive',
    color: '#dc3545'
  },
  moulding: {
    label: 'Mouldings',
    key: 'moulding',
    color: '#54a955'
  },
  misc: {
    label: 'Miscellaneous',
    color: '#978484',
    key: 'misc'
  }
}

export const shortNoteToDisplay = {
  21: 'Non-Reusable Part',
  34: 'Paint to Match',
  42: 'Includes Outer Weatherstrip',
  43: 'Includes Retainers',
  50: 'Includes Bracket',
  57: 'Cut to Fit',
  75: 'Includes Clips',
  184: 'Replace Clips...',
  2156: '5th Digit "E" for 2.0 Eng',
  2157: '5th Digit "B" for 1.8 Eng',
  2173: '2 Man Install',
  2177: '5th Digit "F, G, J, or T"',
  2178: '5th Digit "A, E, L, K, or R"',
  2311: 'Cell Antenna',
  2317: '5th Digit "E"',
  2318: '5th Digit "D"',
  2579: '2-Pin Connector',
  2580: '4-Pin Connector',
  2598: 'R Sensor is Non-Reusable',
  2627: '5th Digit "L"',
  2628: '5th Digit "M"',
  2633: '12th Digit "1"',
  2641: 'May Reqr Dlr Calibr',
  2657: 'w/Homelink',
  2671: 'LDWS',
  2673: 'LDWS Cam Assy',
  2701: '4th Digit "W"',
  2707: 'W/Auto Dimmer',
  2711: 'Paint to Match',
  2747: '360 Cam w/ Lane Dep',
  2769: 'ADAS',
  2815: '4th Digit "1"',
  2816: '4th Digit "Z"',
  2817: '4th Digit "P"',
  2818: '4th Digit "B"',
  2821: 'w/ Cam Assy',
  2823: '5th Digit "R" or "V"'
}

export const mfCodeIcon = {
  '001': 'gm', // 'General Motors'
  '002': 'ford', // 'Ford'
  '004': '', // 'Chrysler-Jeep-Eagle'
  '014': '', // 'FCA'
  '018': '', // 'Alfa Romeo-Chrysler'
  '020': '', // 'Genesis'
  '021': 'alfa', // 'Alfa Romeo'
  '023': '', // 'Jag-MG-Tr-Rov'
  '024': 'bmw', // 'BMW'
  '030': 'nissan', // 'Nissan'
  '031': '', // 'Fiat-Lancia'
  '032': 'honda', // 'Honda'
  '035': 'mazda', // 'Mazda'
  '036': 'mercedes', // 'Mercedes Benz'
  '039': 'peugeot', // 'Peugeot'
  '040': 'porsche', // 'Porsche'
  '042': 'saab', // 'Saab'
  '043': 'subaru', // 'Subaru'
  '044': 'toyota', // 'Toyota'
  '046': '', // 'Audi-Volkswagen'
  '047': 'volvo', // 'Volvo'
  '051': 'maserati', // 'Maserati'
  '056': 'isuzu', // 'Isuzu'
  '057': 'mitsubishi', // 'Mitsubishi'
  '058': 'acura', // 'Acura'
  '059': 'suzuki', // 'Suzuki'
  '060': 'hyundai', // 'Hyundai'
  '061': '', // 'Yugo'
  '062': '', // 'Sterling'
  '063': 'daihatsu', // 'Daihatsu'
  '064': 'land-rover', // 'Land Rover'
  '065': 'lexus', // 'Lexus'
  '066': 'infiniti', // 'Infiniti'
  '069': '', // 'BAIC'
  '070': '', // 'Saturn'
  '071': '', // 'Iveco'
  '072': '', // 'Avanti'
  '073': '', // 'Bricklin'
  '074': '', // 'Hino'
  '075': '', // 'Checker'
  '076': '', // 'Scania'
  '078': '', // 'Magirus'
  '079': 'kia', // 'Kia'
  '080': '', // 'Foton'
  '081': '', // 'MAN'
  '083': '', // 'Daewoo'
  '090': 'tesla', // 'Tesla'
  '097': '', // 'JAC'
  300: '', // 'Studebaker'
  301: '', // 'Simca'
  302: '', // 'Sunbeam'
  303: '', // 'Brockway'
  304: 'rolls-royce', // 'Rolls Royce'
  305: '', // 'Delorean'
  350: '', // 'Bluebird'
  351: '', // 'Fleetwood'
  352: '', // 'Holiday Rambler'
  353: '', // 'MCI'
  354: '', // 'National Coach'
  355: '', // 'Silver Eagle'
  356: '', // 'Thor'
  357: '', // 'Rexhall'
  505: '', // 'Autocar'
  510: '', // 'Boyertown'
  515: '', // 'Diamond Reo'
  520: '', // 'Freightliner'
  523: '', // 'FWD'
  525: '', // 'Grumman Olson'
  530: '', // 'Kenworth'
  535: '', // 'Mack'
  540: '', // 'Navistar-Internatal'
  545: '', // 'Peterbilt'
  550: '', // 'Western Star'
  555: '', // 'White-GMC-Volvo'
  560: '', // 'Sterling Trucks'
  561: '', // 'Utilimaster'
  563: '', // 'New Flyer'
  564: '', // 'Morgan Olson'
  566: '', // 'Workhorse'
  570: '', // 'NABI'
  AIP: '', // 'Allimex Industrial'
  CXP: '', // 'Creative Extruded'
  FLM: '', // 'FlexLine Moldings'
  GGG: '', // 'Gold Glass Group'
  MIT: '', // 'Mitchell'
  MRY: '', // 'Marcy'
  OET: '', // 'OETech'
  PRP: '', // 'Precision'
  THO: '' // 'Thomas'
}
