import gql from 'graphql-tag'

export const PART_FRAGMENT = gql`
  fragment PartFields on Part {
    cost
    description
    discount
    hours
    id
    is_addl_chip @client
    is_adhesive_kit_part
    is_chip
    is_custom_part
    is_glass
    is_inventory_part
    is_oem
    is_premium
    is_recal
    item_id
    labor_price
    labor_tax @client
    list_price
    materials_price
    materials_tax @client
    moulding_discount
    nagsid
    order
    order_status
    part_number
    po_quantity
    quantity
    shop_id
    total_price
    total_tax @client
    flat
    glaxis_order_item_id
    pilkington_order_item_id
    american_order_item_id
    nielsen_moller_order_item_id
  }
`
