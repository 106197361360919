var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.shopPhoneNumbers.length
    ? _c("div", [
        _c("p", [
          _vm._v(
            "Ask us about setting up a texting package to use automated confirmations."
          )
        ])
      ])
    : _c(
        "div",
        {
          staticClass:
            "t-font-sans t-px-6 t-py-5 t-mt-4 t-border t-border-solid t-border-gray-400 t-rounded-md\n    t-break-normal"
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Automatically send confirmation texts",
                    prop: "send"
                  }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.send,
                      callback: function($$v) {
                        _vm.send = $$v
                      },
                      expression: "send"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "shopPhoneNumber" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("Send from")]
                  ),
                  _c(
                    "el-select",
                    {
                      ref: "shopPhoneInput",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "Select shop phone number" },
                      model: {
                        value: _vm.shopPhoneId,
                        callback: function($$v) {
                          _vm.shopPhoneId = $$v
                        },
                        expression: "shopPhoneId"
                      }
                    },
                    _vm._l(_vm.shopPhoneNumbers, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.phone_number + " - " + item.name,
                          value: item.id
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("p", [
                _vm._v(
                  "Note: Adjusting the below settings will NOT affect jobs that have already been scheduled."
                )
              ])
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-form",
            {
              ref: "settings",
              attrs: { model: _vm.settings, disabled: !_vm.send }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "time", prop: "time" } },
                [
                  _c("el-time-picker", {
                    ref: "timeInput",
                    attrs: {
                      "picker-options": {
                        selectableRange: "06:00:00 - 20:00:00"
                      },
                      format: "h:mm A",
                      placeholder: "Time to send"
                    },
                    model: {
                      value: _vm.time,
                      callback: function($$v) {
                        _vm.time = $$v
                      },
                      expression: "time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "day", prop: "sameDay" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.sameDay,
                        callback: function($$v) {
                          _vm.sameDay = $$v
                        },
                        expression: "sameDay"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Day Before")
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Same Day")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.sameDay
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "send monday's on saturday",
                        prop: "mondaysOnSaturday"
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.mondaysOnSaturday,
                          callback: function($$v) {
                            _vm.mondaysOnSaturday = $$v
                          },
                          expression: "mondaysOnSaturday"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "include commercial customers",
                    prop: "commercial"
                  }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.includeCommercial,
                      callback: function($$v) {
                        _vm.includeCommercial = $$v
                      },
                      expression: "includeCommercial"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "selectedTemplate" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("Template")]
                  ),
                  _c(
                    "el-select",
                    {
                      ref: "templateInput",
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        placeholder: "Select text message template (optional)"
                      },
                      model: {
                        value: _vm.templateId,
                        callback: function($$v) {
                          _vm.templateId = $$v
                        },
                        expression: "templateId"
                      }
                    },
                    _vm._l(_vm.templates, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _vm._v(" or "),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.manageTemplates }
                    },
                    [_vm._v("Manage text message templates")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "body" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "undo-label-styles",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [_vm._v("Template body")]
                  ),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      disabled: "",
                      autosize: { minRows: 3, maxRows: 10 },
                      placeholder: "Template body"
                    },
                    model: {
                      value: _vm.selectedTemplateBody,
                      callback: function($$v) {
                        _vm.selectedTemplateBody = $$v
                      },
                      expression: "selectedTemplateBody"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { "word-break": "break-word" } },
                [
                  _c("p", { staticClass: "mt-3" }, [
                    _c("span", { staticStyle: { color: "#F56C6C" } }, [
                      _vm._v("NOTE:")
                    ]),
                    _vm._v(
                      ' text "Please reply YES to confirm or reply NO to change" will be automatically added in the end of the message.'
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }