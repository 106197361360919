<template>
  <div></div>
</template>

<script>
import { sync, call } from 'vuex-pathify'
import actions from '@/store/modules/scheduler_v3/types'

export default {
  data() {
    return {
      modifyNotification: null
    }
  },
  methods: {
    getSchedulerData: call(actions.getSchedulerData)
  },
  computed: {
    modified: sync('sch_v3/routesModified')
  },
  mounted() {
    // this.$notify({
    //   type: 'warning',
    //   title: 'Beta feature',
    //   message:
    //     'Please note Scheduler V3 is still in beta. Play around with the automated routing and share some feedback!'
    // })
  }
}
</script>
