<template>
  <el-dialog
    width="900px"
    append-to-body
    :visible="visible"
    :before-close="close"
    custom-class="shopping-cart-dialog">
    <span slot="title" class="t-text-xl t-font-sans t-text-gray-800">
      Live ordering
    </span>
    <div class="t-p-8 t-pt-4">
      <el-table
        :data="tableData"
        stripe
        class="t-w-full t-font-sans">
        <el-table-column
          width="150">
          <template slot-scope="scope">
            <span class="t-font-bold t-base-lg t-text-gray-700">
              {{ scope.row.vendor }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="Pricing">
          <template slot-scope="scope">
            <div v-if="scope.row.pricing">
              <div class="t-flex t-rounded-full t-bg-green-200 t-p-1 t-items-start t-w-18">
                <i class="el-icon-check t-bg-green-400 t-text-green-800 t-rounded-full t-p-1"/>
                <span class="t-ml-2 t-text-green-800">
                  Yes
                </span>
              </div>
            </div>
            <div v-else>
              <div class="t-flex t-rounded-full t-bg-red-200 t-p-1 t-items-start t-w-18">
                <i class="el-icon-close t-bg-red-400 t-text-red-800 t-rounded-full t-p-1"/>
                <span class="t-ml-2 t-text-red-800">
                  No
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Ordering">
          <template slot-scope="scope">
            <div v-if="scope.row.ordering">
              <div class="t-flex t-rounded-full t-bg-green-200 t-p-1 t-items-start t-w-18">
                <i class="el-icon-check t-bg-green-400 t-text-green-800 t-rounded-full t-p-1"/>
                <span class="t-ml-2 t-text-green-800">
                  Yes
                </span>
              </div>
            </div>
            <div v-else>
              <div class="t-flex t-rounded-full t-bg-red-200 t-p-1 t-items-start t-w-18">
                <i class="el-icon-close t-bg-red-400 t-text-red-800 t-rounded-full t-p-1"/>
                <span class="t-ml-2 t-text-red-800">
                  No
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          label="Status">
          <template slot-scope="scope">
            <div v-if="scope.row.accountId && scope.row.accountId.length">
              <div class="t-flex t-rounded-full t-bg-blue-200 t-p-1 t-items-start t-w-26">
                <i class="el-icon-check t-bg-blue-400 t-text-blue-800 t-rounded-full t-p-1"/>
                <span class="t-ml-2 t-text-blue-800">
                  Enabled
                </span>
              </div>
            </div>
            <div v-else>
              <div class="t-flex t-rounded-full t-bg-pink-200 t-p-1 t-items-start t-w-32">
                <i class="el-icon-close t-bg-pink-400 t-text-pink-800 t-rounded-full t-p-1"/>
                <span class="t-ml-2 t-text-pink-800">
                  Not enabled
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="250"
          label="Account ID">
          <template slot-scope="scope">
            <span class="t-break-words">
              <span v-if="['PGW', 'American'].includes(scope.row.vendor)">
                <div v-for="i in scope.row.accountId" :key="i.id">
                  {{ i.name }} - {{ i.account_number }}
                </div>
              </span>
              <span v-else>
                {{ scope.row.accountId || '-' }}
              </span>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="t-mt-8 t-text-base t-text-center">
        Contact GlassBiller to enable vendor live ordering!<br>
        <span class="t-font-bold">(480) 907-5532</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ['visible', 'mygrant', 'pgw', 'igc', 'pilkington', 'american'],
  computed: {
    tableData: function () {
      return [
        {
          vendor: 'Mygrant',
          pricing: true,
          ordering: true,
          accountId: this.mygrant
        },
        {
          vendor: 'PGW',
          pricing: true,
          ordering: true,
          accountId: this.pgw
        },
        {
          vendor: 'IGC',
          pricing: true,
          ordering: true,
          accountId: this.igc
        },
        {
          vendor: 'Pilkington',
          pricing: true,
          ordering: true,
          accountId: this.pilkington
        },
        {
          vendor: 'American',
          pricing: true,
          ordering: false,
          accountId: this.american
        }
      ]
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
