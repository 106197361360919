<template>
  <button
    @click="openDialog"
    :disabled="!part"
    :class="{
      't-bg-blue-300': vendor === 'mygrant',
      't-bg-red-300': vendor === 'pgw',
      't-bg-yellow-300': vendor === 'igc',
      't-cursor-not-allowed': !part
    }"
    class="t-font-sans t-border-none t-rounded-full t-mt-1 t-text-085
            t-font-medium t-whitespace-no-wrap t-cursor-pointer"
  >
    <span v-if="part">
      <span
        class="t-bg-white t-text-red-600 t-rounded-full t-px-1">
        QTY:{{ part.quantity }}
      </span>
      <span
        v-if="part.priceBuyer"
        class="t-ml-1">
        ${{ part.priceBuyer }}
      </span>
      <span
        v-if="part.priceBuyer && priceFromLevels"
        class="t-text-purple-800 t-ml-1">
        ${{ priceFromLevels }}
      </span>
    </span>
    <span v-else class="t-mx-2 t-text-gray-800 t-font-normal">
      No results
    </span>
  </button>
</template>

<script>
export default {
  props: [
    'part',
    'vendor',
    'partNumber',
    'priceFromLevels'
  ],
  methods: {
    openDialog () {
      this.$store.commit('partVendors/setSearchResultsVisible', {
        vendor: this.vendor,
        partNumber: this.partNumber,
        visible: true
      })
    }
  }
}
</script>
