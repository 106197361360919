<template>
  <el-dialog
    append-to-body
    top="80px"
    width="685px"
    custom-class="qbo-account-mapping-dialog"
    :visible="visible"
    :before-close="close"
    :close-on-click-modal="false"
    @open="getAllQboDefaultsForShop"
  >
    <div v-loading="fetching" class="t-flex-grow t-min-w-0">
      <Header />

      <Body :shopHasCardProcessor="shopHasCardProcessor"/>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useQboStore } from '@/components/modals/QuickBooksAccountMappingDialog/qbo.store.js'
import Header from './Header.vue'
import Body from './Body.vue'

export default {
  components: {
    Header,
    Body
  },

  data() {
    return {
      receivableAccount: 'System Default'
    }
  },

  computed: {
    ...mapState(useQboStore, ['shopId', 'fetching', 'visible', 'saving']),
    shopHasCardProcessor: function () {
      const shop = this.$store.state.shops.filter(s => s.id === this.shopId)[0]

      const hasAuthorizeNet = shop?.authorizenet_api_login_id?.length > 0 &&
        shop?.authorizenet_public_client_key?.length > 0 &&
        shop?.authorizenet_transaction_key?.length > 0

      // get square_access_token && square_refresh_token as well?
      const hasSquare = shop?.square_merchant_id?.length > 0

      return hasAuthorizeNet || hasSquare
    }
  },

  methods: {
    ...mapActions(useQboStore, ['getAllQboDefaultsForShop', 'close'])
  }
}
</script>

<style lang="scss">
.el-dialog.qbo-account-mapping-dialog {
  @apply t-font-sans t-rounded-xl;
  .el-dialog__header {
    @apply t-hidden;
  }

  .el-dialog__body {
    @apply t-flex t-p-0 t-overflow-y-hidden
  }

  .el-form-item {
    @apply t-mb-0;
  }

  .el-form-item__content {
    @apply t-leading-none;
  }
}
</style>
