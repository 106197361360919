import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Faxnumber extends ObjectTracker {
  id = -1
  created = -1
  fax = ''
  user_id = -1
  modified = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getUserFaxes (userId, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/user/${userId}/faxes`}).then(res => {
      var response = []
      if (res.data.length > 0) {
        res.data.forEach(element => {
          response.push(new Faxnumber(element))
        })
      }
      callbackFunction(response)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getDefaultFax (callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/user/defaultfax`}).then(res => {
      var response = {}
      if (res.data.fax) {
        response = (new Faxnumber(res.data))
      }
      callbackFunction(response)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
