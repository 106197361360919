import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Payment extends ObjectTracker {
  id = -1
  created = -1
  type = ''
  amount = 0
  date = ''
  x_action_type = ''
  x_action_number = ''
  memo = ''
  job_id = -1
  modified = -1
  qb_last_exported = -1
  payment_group_id = -1
  _authNetDataDescriptor = ''
  _authNetDataValue = ''
  payment_account_id = -1
  _confirmationPdfVerion = ''
  _smsToken = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, jobId, callbackFunction) {
    Api({url: `/api/shop/${shopId}/job/${jobId}/payment`}).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        var payment = new Payment(val)
        objects.push(payment)
      })
      callbackFunction(objects)
    })
  }

  static deletePayment (shopId, jobId, paymentId, callbackFunction, errorCallbackFunction = null) {
    Api.delete(`/api/shop/${shopId}/job/${jobId}/payment/${paymentId}`).then(res => {
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  createUpdatePayment (shopId, jobId, processingDetails, callbackFunction, errorCallbackFunction = null) {
    if (jobId > 0 && jobId !== 'new') {
      if (!this.id || this.id <= 0) {
        this.job_id = jobId
        this.id = -1
      }

      this._processingDetails = processingDetails

      return Api.post(`/api/shop/${shopId}/job/${jobId}/payment/${this.id}`, this).then(res => {
        callbackFunction(res.data)
      }, function (error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      })
    }
  }

  createUpdateSmsPayment (shopId, jobId, processingDetails, smsToken, confirmationPdfVersion, consumer_id, callbackFunction, errorCallbackFunction = null) {
    if (jobId > 0 && jobId !== 'new') {
      if (!this.id || this.id <= 0) {
        this.job_id = jobId
        this.id = -1
      }

      this._processingDetails = processingDetails

      this._confirmationPdfVersion = confirmationPdfVersion ? confirmationPdfVersion : ''
      this._smsToken = smsToken ? smsToken : ''

      return Api.post(`/api/mobile/shop/${shopId}/job/${jobId}/payment/${this.id}`, {...this, consumer_id}).then(res => {
        callbackFunction(res.data)
      }, function (error) {
        if (errorCallbackFunction) {
          errorCallbackFunction(error)
        }
      })
    }
  }

  static getPaymentQBTable (shopId, page, limit, sortBy, sortDesc, fromDate, toDate, paymentPaymentIdFilter, paymentInvoiceNumberFilter,
    paymentCustomerFilter, paymentTypeFilter, paymentXActionTypeFilter, paymentXActionNumberFilter, paymentAmountFilter, paymentMemoFilter,
    paymentPaymentDateFilter, paymentInvoiceExportedFilter, paymentLastExportedFilter, paymentErrorsFilter, paymentExportDetailsFilter, showOnlyUnexported, searchAllFilter,
    exportToQB, exportAllPaymentRows, selectedExportPaymentRows, qbExportType,
    successCallbackFunction, errorCallbackFunction) {
    var url = `/api/shop/${shopId}/payments/paymentQBTable?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
    '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
    '&fromDate=' + encodeURIComponent(fromDate) + '&toDate=' + encodeURIComponent(toDate) + '&paymentPaymentIdFilter=' + encodeURIComponent(paymentPaymentIdFilter) +
    '&paymentInvoiceNumberFilter=' + encodeURIComponent(paymentInvoiceNumberFilter) + '&paymentCustomerFilter=' + encodeURIComponent(paymentCustomerFilter) +
    '&paymentTypeFilter=' + encodeURIComponent(paymentTypeFilter) + '&paymentXActionTypeFilter=' + encodeURIComponent(paymentXActionTypeFilter) +
    '&paymentXActionNumberFilter=' + encodeURIComponent(paymentXActionNumberFilter) + '&paymentAmountFilter=' + encodeURIComponent(paymentAmountFilter) +
    '&paymentMemoFilter=' + encodeURIComponent(paymentMemoFilter) + '&paymentPaymentDateFilter=' + encodeURIComponent(paymentPaymentDateFilter) +
    '&paymentInvoiceExportedFilter=' + encodeURIComponent(paymentInvoiceExportedFilter) + '&paymentLastExportedFilter=' + encodeURIComponent(paymentLastExportedFilter) +
    '&paymentErrorsFilter=' + encodeURIComponent(paymentErrorsFilter) + '&paymentExportDetailsFilter=' + encodeURIComponent(paymentExportDetailsFilter) +
    '&showOnlyUnexported=' + encodeURIComponent(showOnlyUnexported) + '&searchAllFilter=' + encodeURIComponent(searchAllFilter)

    if (exportToQB) {
      url += '&exportToQB=true&exportAllPaymentRows=' + (exportAllPaymentRows ? 'true' : 'false') + '&qbExportType=' + encodeURIComponent(qbExportType)
      var selectedRows = ''
      for (var i = 0; i < selectedExportPaymentRows.length; i++) {
        if (selectedRows.length > 0) {
          selectedRows += ','
        }
        selectedRows += selectedExportPaymentRows[i]
      }
      url += '&selectedExportPaymentRows=' + encodeURIComponent(selectedRows)
    } else {
      url += '&exportToQB=false'
    }

    Api({url: url}).then(res => {
      successCallbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
