import { ediStatuses } from '@/constants/edi.config'
import * as R from 'ramda'

const validateRule = data =>
  R.pipe(
    R.ifElse(
      R.prop('customRule'),
      R.pipe(R.prop('customRule'), R.applyTo(data)),
      R.pipe(R.prop('key'), R.split('.'), R.path(R.__, data))
    ),
    v => !!v
  )

const validateChildRules = data =>
  R.pipe(
    R.propOr([], 'rules'),
    R.map(R.when(R.pipe(R.type, R.equals('Function')), rule => rule(data))),
    R.map(validateRule(data)),
    R.all(R.identity)
  )

const validateRules = data =>
  R.pipe(
    R.map(R.allPass([validateRule(data), validateChildRules(data)])),
    R.all(R.identity)
  )

export const validateEdi = validateRules

export const filterEdiProgramOptions = filter =>
  R.pipe(
    R.filter(
      R.anyPass([
        R.pipe(
          R.prop('name'),
          R.toUpper,
          R.contains(filter.toUpperCase())
        ),  
        R.pipe(
          R.prop('program_id'),
          R.toUpper,
          R.contains(filter)
        )
      ])
    ),
    R.sortBy(
      R.converge(R.min, [
        R.pipe(
          R.prop('name'),
          R.indexOf(filter),
          R.when(R.gt(0), R.always(Infinity))
        ),
        R.pipe(
          R.prop('program_id'),
          R.indexOf(filter),
          R.when(R.gt(0), R.always(Infinity))
        )
      ])
    )
  )

const getTimestamp = R.pipe(
  R.match(/\d\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d/),
  R.unless(R.isEmpty, R.nth(0))
)

export const formatEdiHistory = R.pipe(
  R.propOr('', 'job_edi_message'),
  R.split('<br><br>'),
  R.map(
    R.applySpec({
      message: R.pipe(
        R.converge(R.replace, [getTimestamp, R.always(''), R.identity]),
        R.replace(/<br\/>/g, '')
      ),
      status: R.unless(R.isEmpty, message =>
        R.propOr(
          {},
          R.find(R.contains(R.__, R.toLower(message)))(Object.keys(ediStatuses))
        )(ediStatuses)
      ),
      timestamp: getTimestamp
    })
  ),
  R.filter(R.prop('message'))
)
