export const shopRejectedCount = (state, id) => {
  if (state.userEdiRejectedJobShopCountArray[id]) {
    return state.userEdiRejectedJobShopCountArray[id]['countForDisplay']
  } else {
    return 0
  }
}

export const shopQueuedCount = (state, id) => {
  if (state.userEdiQueuedJobShopCountArray[id]) {
    if (state.userEdiQueuedJobShopCountArray[id]['countForDisplay'] !== undefined && state.userEdiQueuedJobShopCountArray !== null) {
      return state.userEdiQueuedJobShopCountArray[id]['countForDisplay']
    }
  } else {
    return 0
  }
}

export const ediStatusColor = (status) => {
  if (['paid', 'accepted'].includes(status.toLowerCase())) return 'green'
  if (status.toLowerCase() === 'rejected') return 'red'
  if (status.toLowerCase() === 'unknown') return 'blue'
  return 'black'
}