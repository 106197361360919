// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".md-icon-button {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.md-icon-button.el-button--mini {\n  height: 28px;\n}\n.md-icon-button .material-icons-outline {\n  margin-right: 8px;\n}\n.md-icon-button.reverse {\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: reverse;\n      -ms-flex-direction: row-reverse;\n          flex-direction: row-reverse;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.md-icon-button.reverse .material-icons-outline {\n  margin-right: 0;\n}\n.md-icon-button.reverse span {\n  margin-left: 8px;\n}", "",{"version":3,"sources":["/app/src/components/Helpers/buttons/MdIconButton.vue"],"names":[],"mappings":"AA2BA;EAIE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,mBAAA;AAlBF;AAcE;EACE,YAAA;AAZJ;AAgBE;EACE,iBAAA;AAdJ;AAgBE;EACE,8BAAA;EAAA,8BAAA;MAAA,+BAAA;UAAA,2BAAA;EACA,wBAAA;MAAA,qBAAA;UAAA,uBAAA;AAdJ;AAeI;EACE,eAAA;AAbN;AAeI;EACE,gBAAA;AAbN","file":"MdIconButton.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.md-icon-button {\n  display: flex;\n  align-items: center;\n}\n.md-icon-button.el-button--mini {\n  height: 28px;\n}\n.md-icon-button .material-icons-outline {\n  margin-right: 8px;\n}\n.md-icon-button.reverse {\n  flex-direction: row-reverse;\n  justify-content: center;\n}\n.md-icon-button.reverse .material-icons-outline {\n  margin-right: 0;\n}\n.md-icon-button.reverse span {\n  margin-left: 8px;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
