var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gb-dropdown-menu",
      on: {
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.nextOption($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.prevOption($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.selectOption(_vm.highlight)
          }
        ]
      }
    },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-end",
            trigger: "manual",
            "popper-class": "gb-dropdown-menu-container"
          },
          model: {
            value: _vm.open,
            callback: function($$v) {
              _vm.open = $$v
            },
            expression: "open"
          }
        },
        [
          _c(
            "div",
            {
              ref: "trigger",
              attrs: { slot: "reference" },
              on: {
                click: function($event) {
                  _vm.open = true
                }
              },
              slot: "reference"
            },
            [_vm._t("trigger")],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "element-clickoutside",
                  rawName: "v-element-clickoutside",
                  value: _vm.clickOutside,
                  expression: "clickOutside"
                }
              ]
            },
            [
              _vm.$slots["menu-header"]
                ? _c(
                    "div",
                    { staticClass: "gb-dropdown-header" },
                    [_vm._t("menu-header")],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                { ref: "scroll", staticClass: "gb-dropdown-container" },
                [
                  _c(
                    "el-scrollbar",
                    {
                      ref: "scrollbar",
                      attrs: {
                        "wrap-class": "gb-dropdown-list-container",
                        "view-class": "gb-dropdown-list",
                        tag: "ul"
                      }
                    },
                    _vm._l(_vm.options, function(option, idx) {
                      return _c(
                        "li",
                        {
                          key: option.id,
                          staticClass: "gb-dropdown-menu-item",
                          class: { active: idx === _vm.highlight },
                          on: {
                            mouseenter: function($event) {
                              _vm.highlight = idx
                            },
                            mouseleave: function($event) {
                              _vm.highlight = -1
                            },
                            click: function($event) {
                              return _vm.selectOption(idx)
                            }
                          }
                        },
                        [_vm._t("menu-option", null, null, option)],
                        2
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _vm.$listeners["new-click"]
                ? _c(
                    "div",
                    {
                      staticClass: "gb-dropdown-add-new",
                      on: {
                        click: function($event) {
                          return _vm.$emit("new-click")
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "material-icons-outline md-add md-18"
                      }),
                      _vm._v(" " + _vm._s(_vm.newLabel) + " ")
                    ]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }