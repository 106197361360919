<template>
  <base-layout :data="data">
    <div slot="title">Form Signed</div>

    <div>
      <b v-if="data.details.signerName">{{data.details.signerName}}</b>
      <b v-else>Your customer</b>
      has signed form <b>{{data.details.formName}}</b> for job <a target="_blank" :href="jobLink">{{this.data.details.jobId}}</a>.
    </div>
  </base-layout>

</template>

<script>
import BaseLayout from './_BaseLayout'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    jobLink() {
      return `/jobs/${this.data.details.jobId}`
    }
  },

  components: { BaseLayout }
}
</script>
