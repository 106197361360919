export const documentsConfig = {
  swo: {
    color: '#cd0a0a',
    tag: 'SWO',
    id: 'swo',
    label: 'Signed work order'
  },
  dis: {
    color: '#1c94c4',
    tag: 'DIS',
    id: 'dis',
    label: 'Dispatch'
  },
  pop: {
    color: '#00008b',
    tag: 'PoP',
    id: 'pop',
    label: 'Proof of purchase'
  },
  fiv: {
    color: '#a577a6',
    tag: 'FIV',
    id: 'fiv',
    label: 'Field Invoice'
  },
  quo: {
    color: '#b38a7f',
    tag: 'QUO',
    id: 'quo',
    label: 'Quote',
    icon: 'request_quote'
  },
  dl: {
    color: '#a10000',
    tag: 'DL',
    id: 'dl',
    label: 'Damage Locator'
  },
  pic: {
    color: '#6f3d8b',
    tag: 'PIC',
    id: 'pic',
    label: 'Picture',
    icon: 'insert_photo'
  },
  app: {
    color: '#0086ad',
    tag: 'APP',
    id: 'app',
    label: 'Approval'
  },
  auth: {
    color: '#99d8d8',
    tag: 'AUTH',
    id: 'auth',
    label: 'Authorization'
  },
  recal: {
    color: '#ffb62b',
    tag: 'RECAL',
    id: 'recal',
    label: 'Recalibration'
  },
  oth: {
    color: '#cbbce1',
    tag: 'OTH',
    id: 'oth',
    label: 'Other'
  },
  payment: {
    color: '#33c90e',
    tag: 'PAYMENT',
    id: 'payment',
    label: 'Payment',
    icon: 'receipt'
  },
  ssmpic: {
    color: '#005AA3',
    tag: 'SSMPic',
    id: 'ssmpic',
    label: 'SaleSIDE Photo',

    icon: 'insert_photo',
    noAdd: true
  },
  tsmpic: {
    color: '#08A300',
    tag: 'TSMPic',
    id: 'tsmpic',
    label: 'TechSIDE Photo',
    icon: 'insert_photo',
    noAdd: true
  },
  esign: {
    color: '#ff8800',
    tag: 'ESign',
    id: 'esign',
    label: 'E-Signed',
    noAdd: true
  },
  ssign: {
    color: '#6db3f2',
    tag: 'SSign',
    id: 'ssign',
    label: 'SaleSIDE Signed',
    noAdd: true
  },
  tsign: {
    color: 'rgb(132, 201, 127)',
    tag: 'TSign',
    id: 'tsign',
    label: 'TechSIDE Signed',
    noAdd: true
  },
  smssign: {
    color: '#0ec9b7',
    tag: 'SMSSign',
    id: 'smssign',
    label: 'Sms Signed',
    noAdd: true
  },
  gbform: {
    color: '#409EFF',
    tag: 'GBForm',
    id: 'gbform',
    label: 'GB Form'
  },
  job: {
    color: '#525252',
    tag: 'Job',
    id: 'job',
    label: 'Job Pdf',
    noAdd: true
  },
  attch: {
    color: '#80542e',
    tag: 'Attch',
    id: 'attch',
    label: 'Email Attachment',
    noAdd: true
  },
  presign: {
    color: '#3d5afe',
    tag: 'PreSign',
    id: 'presign',
    label: 'Presign',
  },
  support: {
    color: '#32a832', 
    tag: 'TCKT ATTCH', 
    label: 'Support Ticket Attachment', 
    id: 'support',
    noAdd: true
  },
  support_event: {
    color: '#3d5afe', 
    tag: 'ATTCH', 
    label: 'Support Ticket Comment Attachment', 
    id: 'support_event',
    noAdd: true
  },
  warcon: {
    color: '#f0ac4e',
    tag: 'W&C',
    id: 'warcon',
    label: 'Warranty & Conditions',
    noAdd: true
  },
  report: {
    color: '#265c5a',
    tag: 'Report',
    id: 'report',
    label: 'Report',
    noAdd: true
  },
  tsmcnp: {
    color: '#547E51',
    tag: 'TSMCNP',
    id: 'tsmcnp',
    label: 'TechSIDE Customer Not Present',
    noAdd: true
  }
}

export const documentsAddConfig = {
  swo: {
    color: '#cd0a0a',
    tag: 'SWO',
    id: 'swo',
    label: 'Signed work order'
  },
  dis: {
    color: '#1c94c4',
    tag: 'DIS',
    id: 'dis',
    label: 'Dispatch'
  },
  pop: {
    color: '#00008b',
    tag: 'PoP',
    id: 'pop',
    label: 'Proof of purchase'
  },
  fiv: {
    color: '#a577a6',
    tag: 'FIV',
    id: 'fiv',
    label: 'Field Invoice'
  },
  quo: {
    color: '#b38a7f',
    tag: 'QUO',
    id: 'quo',
    label: 'Quote',
    icon: 'request_quote'
  },
  dl: {
    color: '#a10000',
    tag: 'DL',
    id: 'dl',
    label: 'Damage Locator'
  },
  pic: {
    color: '#6f3d8b',
    tag: 'PIC',
    id: 'pic',
    label: 'Picture',
    icon: 'insert_photo'
  },
  app: {
    color: '#0086ad',
    tag: 'APP',
    id: 'app',
    label: 'Approval'
  },
  auth: {
    color: '#99d8d8',
    tag: 'AUTH',
    id: 'auth',
    label: 'Authorization'
  },
  recal: {
    color: '#ffb62b',
    tag: 'RECAL',
    id: 'recal',
    label: 'Recalibration'
  },
  oth: {
    color: '#cbbce1',
    tag: 'OTH',
    id: 'oth',
    label: 'Other'
  },
  payment: {
    color: '#33c90e',
    tag: 'PAYMENT',
    id: 'payment',
    label: 'Payment',
    icon: 'receipt'
  }
}
