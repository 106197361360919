var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "email-dialog",
        class: {
          "dragging-overlay": _vm.dragging,
          "uploading-file": _vm.uploadingDropFile
        },
        on: {
          drop: [
            function($event) {
              $event.preventDefault()
              return _vm.documentDrop($event)
            },
            function($event) {
              _vm.dragging = false
            }
          ],
          dragleave: function($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.dragLeave($event)
          },
          dragenter: function($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.dragEnter($event)
          },
          dragover: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.data,
              rules: _vm.rules,
              "label-width": "140px",
              size: "small"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "selectedTemplate" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "undo-label-styles",
                    attrs: { slot: "label" },
                    slot: "label"
                  },
                  [_vm._v("Template")]
                ),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      clearable: "",
                      placeholder: "Select text message template (optional)"
                    },
                    model: {
                      value: _vm.selectedTemplateId,
                      callback: function($$v) {
                        _vm.selectedTemplateId = $$v
                      },
                      expression: "selectedTemplateId"
                    }
                  },
                  _vm._l(_vm.templates, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                ),
                _vm._v(" or "),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.manageTemplates }
                  },
                  [_vm._v("Manage text message templates")]
                )
              ],
              1
            ),
            _c("consumer-select", {
              attrs: { consumerToCharge: _vm.data.consumerToCharge },
              on: {
                "update:consumerToCharge": function($event) {
                  return _vm.$set(_vm.data, "consumerToCharge", $event)
                },
                "update:consumer-to-charge": function($event) {
                  return _vm.$set(_vm.data, "consumerToCharge", $event)
                }
              }
            }),
            _c("amount", {
              attrs: { requestedAmount: _vm.data.amount },
              on: {
                "update:requestedAmount": function($event) {
                  return _vm.$set(_vm.data, "amount", $event)
                },
                "update:requested-amount": function($event) {
                  return _vm.$set(_vm.data, "amount", $event)
                }
              }
            }),
            _c(
              "el-form-item",
              { attrs: { prop: "to" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "undo-label-styles",
                    attrs: { slot: "label" },
                    slot: "label"
                  },
                  [_vm._v("To email")]
                ),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "Select or enter email",
                      "allow-create": "",
                      clearable: "",
                      "default-first-option": "",
                      filterable: ""
                    },
                    model: {
                      value: _vm.data.to,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "to", $$v)
                      },
                      expression: "data.to"
                    }
                  },
                  _vm._l(_vm.toOptions, function(email, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: email, value: email }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "replyTo" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "undo-label-styles",
                    attrs: { slot: "label" },
                    slot: "label"
                  },
                  [_vm._v("Reply To")]
                ),
                _c("el-input", {
                  model: {
                    value: _vm.data.replyTo,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "replyTo", $$v)
                    },
                    expression: "data.replyTo"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "subject" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "undo-label-styles",
                    attrs: { slot: "label" },
                    slot: "label"
                  },
                  [_vm._v("Subject")]
                ),
                _c("el-input", {
                  model: {
                    value: _vm.data.subject,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "subject", $$v)
                    },
                    expression: "data.subject"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "body" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "undo-label-styles",
                    attrs: { slot: "label" },
                    slot: "label"
                  },
                  [_vm._v("Body")]
                ),
                _c("vue-editor", {
                  attrs: { editorOptions: _vm.quill.editorOptions },
                  on: {
                    "selection-change": _vm.quill.onSelectionChange,
                    focus: _vm.quill.onFocus
                  },
                  model: {
                    value: _vm.data.body,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "body", $$v)
                    },
                    expression: "data.body"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticStyle: { "word-break": "break-word" } },
              [
                _c("p", { staticClass: "mt-3" }, [
                  _c("span", { staticStyle: { color: "#F56C6C" } }, [
                    _vm._v("NOTE:")
                  ]),
                  _vm._v(
                    " A link to the customer payment portal will be automatically added in the end of the message."
                  )
                ])
              ]
            ),
            _c(
              "el-form-item",
              [
                _c("div", { staticClass: "t-flex t-justify-between t-mb-2" }, [
                  _c("div", { staticClass: "email-dialog-attachments" }, [
                    _c("div", { staticClass: "attachments-label" }, [
                      _vm._v("Attachments")
                    ]),
                    _c("div", { staticClass: "t-flex t-mt-1 t-mb-1" }, [
                      _c(
                        "div",
                        [
                          _c("el-switch", {
                            staticClass: "t-mt-1",
                            attrs: {
                              "active-text": _vm.status + " #" + _vm.$job.job.id
                            },
                            model: {
                              value: _vm.sendPdf,
                              callback: function($$v) {
                                _vm.sendPdf = $$v
                              },
                              expression: "sendPdf"
                            }
                          }),
                          _vm._l(_vm.numberedDocs, function(doc) {
                            return _c(
                              "div",
                              { key: doc.id, staticClass: "t-mb-1 t-mt-1" },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-text":
                                      _vm.getDocConfig(doc.document_type)
                                        .label +
                                      " " +
                                      (doc.number ? doc.number : ""),
                                    "active-color": _vm.getDocConfig(
                                      doc.document_type
                                    ).color,
                                    value: !!_vm.docs.find(function(id) {
                                      return id === doc.id
                                    })
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.toggleDoc(doc.id)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "t-ml-3" },
                        [
                          _vm.pdf
                            ? _c("pdf-type-select", {
                                attrs: {
                                  copy: _vm.pdf.copy,
                                  template: "select",
                                  wrap: "",
                                  end: "",
                                  allowSetDefault: ""
                                },
                                on: {
                                  "update:copy": function($event) {
                                    return _vm.$set(_vm.pdf, "copy", $event)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { key: _vm.receiptsUpdate, staticClass: "t-flex t-mb-2" },
                      [
                        _c(
                          "div",
                          _vm._l(_vm.paymentReceipts, function(receipt) {
                            return _c(
                              "div",
                              { key: receipt.id, staticClass: "t-mb-1" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "inline-flex" } },
                                  [
                                    _c("el-switch", {
                                      key: "pmt-switch-" + receipt.id,
                                      on: {
                                        change: function($event) {
                                          return _vm.toggleReceiptSwitch(
                                            receipt.id
                                          )
                                        }
                                      },
                                      model: {
                                        value: receipt.sendPdf,
                                        callback: function($$v) {
                                          _vm.$set(receipt, "sendPdf", $$v)
                                        },
                                        expression: "receipt.sendPdf"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        key: "pmt-label-" + receipt.id,
                                        staticClass: "t-ml-2"
                                      },
                                      [
                                        receipt.link && receipt.sendPdf
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: receipt.link,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      "Pmt. " +
                                                        (receipt.type || "") +
                                                        " - $" +
                                                        receipt.amount +
                                                        " (" +
                                                        receipt.consumer_name +
                                                        ")"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "loading",
                                                    rawName: "v-loading",
                                                    value: receipt.loading,
                                                    expression:
                                                      "receipt.loading"
                                                  }
                                                ],
                                                staticClass: "receipt-label",
                                                attrs: {
                                                  "element-loading-spinner":
                                                    "el-icon-loading"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      "Pmt. " +
                                                        (receipt.type || "") +
                                                        " - $" +
                                                        receipt.amount +
                                                        " (" +
                                                        receipt.consumer_name +
                                                        ")"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "additional-attachments t-pl-2" }, [
                    _c(
                      "div",
                      { staticClass: "additional-attachments__label t-mb-1" },
                      [_vm._v(" Additional Attachments ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "additional-attachments__files" },
                      [
                        _vm._l(_vm.attachments, function(attachment, index) {
                          return _c(
                            "div",
                            { key: attachment.url, staticClass: "t-mb-1" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: attachment.url,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(attachment.name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteAttachment(index)
                                    }
                                  }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "remove-icon t-ml-2",
                                    attrs: { icon: _vm.faTimes }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "t-mt-2",
                            on: {
                              dragover: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c("FilePicker", {
                              attrs: {
                                buttonLabel: "Choose File",
                                filePickerKey: _vm.filePickerKey,
                                handleFilePickerResponse:
                                  _vm.handleFilePickerResponse,
                                dropListenerName: "emailAttachment",
                                uploadingDropFile: _vm.uploadingDropFile
                              },
                              on: {
                                "update:uploadingDropFile": function($event) {
                                  _vm.uploadingDropFile = $event
                                },
                                "update:uploading-drop-file": function($event) {
                                  _vm.uploadingDropFile = $event
                                }
                              }
                            }),
                            _c("div", { staticClass: "t-mt-1" }, [
                              !_vm.uploadingDropFile
                                ? _c("div", [_vm._v("Or Drag and Drop...")])
                                : _vm._e(),
                              _vm.uploadingDropFile
                                ? _c("div", [_vm._v("Uploading...")])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ])
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "mb-2 mr-2",
                    attrs: {
                      type: "primary",
                      loading: _vm.loading || _vm.receiptLoading
                    },
                    on: { click: _vm.handleSubmit }
                  },
                  [_vm._v(" Send ")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "ml-0",
                    attrs: { plain: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [_vm._v(" Close ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }