import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class ShopMessage extends ObjectTracker {
  id = -1
  shop_id = -1
  sender = ''
  message = ''
  message_status = ''
  salesidejob_id = -1
  created = null
  job_id = -1
  // customer_first_name = ''
  // customer_last_name = ''
  // pending_offline = 0
  // referral_number = ''
  // scheduled_date = null
  // vehicle_make = ''
  // vehicle_model = ''
  // vehicle_year = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getShopMessages (shopId, callbackFunction) {
    Api({ url: `/api/shop/getShopMessages/${shopId}` }).then(res => {
      let handledMessages = []
      for (var i = 0; i < res.data.length; i++) {
        let handledMessage = new ShopMessage(res.data[i])
        handledMessages.push(handledMessage)
      }
      // formatting found messages and passing back to front end component
      callbackFunction(handledMessages)
    })
  }

  static updateShopMessageStatus (message, newStatus, callbackFunction) {
    Api({ url: `/api/shop/updateShopMessageStatus/${message.id}/${newStatus}` }).then(res => {
      callbackFunction(res)
    })
  }
}
