import Vue from 'vue'
import axios from 'axios'
import cfg from '@/config'
import { Message } from 'element-ui'

export const baseApiUrl = cfg.uri

const API = axios.create({
  baseURL: baseApiUrl
})

const isHandlerEnabled = (config = {}) => {
  return !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled)
}

API.interceptors.request.use(async request => {
  const { $auth } = Vue.prototype
  if (isHandlerEnabled(request) && $auth && !$auth.skipAuth) {
    const token = await $auth.getTokenSilently()
    request.headers.authorization = `Bearer ${token}`
    return request
  }
  return request
})

API.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (!axios.isCancel(error)) {
      Message.error(error.response?.data?.error)
      return Promise.reject(error)
    }
  }
)

export default API
