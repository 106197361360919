import { map, concat } from 'ramda'

export const schedulerNamespace = 'scheduler'
const schedulerActionPrefix = 'SCHEDULER_ACTION_'
const schedulerMutationPrefix = 'SCHEDULER_MUTATE_'

export const mutations = {
  openSchedulerDialog: 'OPEN_SCHEDULER_DIALOG',
  closeSchedulerDialog: 'CLOSE_SCHEDULER_DIALOG',
  setStateValue: 'SET_STATE_VALUE',
  updateStateListValueById: 'UPDATE_STATE_LIST_VALUE_BY_ID',
  updateJob: 'UPDATE_JOB',
  updateJobValue: 'UPDATE_JOB_VALUE',
  setJobsToUpdate: 'SET_JOBS_TO_UPDATE',
  setTechModal: 'SET_TECH_MODAL',
  updateGeometryValue: 'UPDATE_GEOMETRY_VALUE',
  appendRouteWaypoint: 'APPEND_ROUTE_WAYPOINT',
  updateWaypoint: 'UPDATE_WAYPOINT',
  deleteWaypoint: 'DELETE_WAYPOINT',
  updateWaypointValue: 'UPDATE_WAYPOINT_VALUE',
  setShopTechs: 'SET_SHOP_TECHS',
  deleteAppointment: 'DELETE_APPOINTMENT',
  updateOrAppendValue: 'UPDATE_OR_APPEND_VALUE',
  deleteValueFromList: 'DELETE_VALUE_FROM_LIST',
  setTechLocation: 'SET_TECH_LOCATION',
  resetTechLocations: 'RESET_TECH_LOCATIONS'
}

export const actions = {
  getSchedulerJobs: 'GET_SCHEDULER_JOBS',
  setJobFilter: 'SET_JOB_FILTER',
  setStateValue: 'SET_VALUE',
  updateJob: 'UPDATE_JOB',
  postJobUpdates: 'POST_JOBS_UPDATES',
  getJobDetails: 'GET_JOB_DETAILS',
  updateJobValueById: 'UPDATE_JOB_VALUE_BY_ID',
  postUserSettingsUpdates: 'POST_USER_SETTINGS_UPDATES',
  getSchedulerMapData: 'GET_SCHEDULER_MAP_DATA',
  updateGeometryValueById: 'UPDATE_GEOMETRY_VALUE_BY_ID',
  setMapBounds: 'SET_MAP_BOUNDS',
  getSchedulerWarehouses: 'GET_SCHEDULER_WAREHOUSES',
  getTechInfo: 'GET_TECH_INFO',
  updateTechListValue: 'UPDATE_TECH_LIST_VALUE',
  getRouteWaypoints: 'GET_ROUTE_WAYPOINTS',
  createRouteWaypoint: 'CREATE_ROUTE_WAYPOINT',
  updateSchedulerItems: 'UPDATE_SCHEDULER_ITEMS',
  updateWaypoints: 'UPDATE_WAYPOINTS',
  postWaypointUpdates: 'POST_WAYPOINT_UPDATES',
  deleteWaypoint: 'DELETE_WAYPOINT',
  postSchedulerUpdates: 'POST_SCHEDULER_UPDATES',
  completeTechsideJob: 'COMPLETE_TECHSIDE_JOB',
  getShopTechs: 'GET_SHOP_TECHS',
  saveAppointment: 'SAVE_APPOINTMENT',
  getAppointments: 'GET_APPOINTMENTS',
  deleteAppointment: 'DELETE_APPOINTMENT',
  updateAppointments: 'UPDATE_APPOINTMENTS',
  postAppointmentUpdates: 'POST_APPOINTMENT_UPDATES',
  saveJob: 'SAVE_JOB'
}

// export mutations in format to be used in a store module
// i.e. { getJobShops: "SCHEDULER_MUTATE_SET_JOB_SHOP" }
const addMutationPrefix = concat(`${schedulerMutationPrefix}`)
export const schedulerMutations = map(addMutationPrefix)(mutations)

// export action types in format to be used in a store module
// i.e. { getJobShops: "SCHEDULER_ACTION_GET_JOB_SHOP" }
const addActionPrefix = concat(`${schedulerActionPrefix}`)
export const schedulerActions = map(addActionPrefix)(actions)

const addNamespace = concat(`${schedulerNamespace}/`)
// export actions types in format to be used in a component
// i.e. { getJobShops: "sheduler/SCHEDULER_ACTION_GET_JOB_SHOP" }
export const schedulerNamespacedActions = map(addNamespace)(schedulerActions)

// export actions types in format to be used in a component
// i.e. { getJobShops: "sheduler/SCHEDULER_ACTION_GET_JOB_SHOP" }
export const schedulerNamespacedMutations = map(addNamespace)(schedulerMutations)
