<template>
  <div class="t-w-64 t-p-6 t-bg-gray-100 t-pt-12 t-flex-shrink-0 t-rounded-l-xl">
    <!-- Avatar -->
    <div class="t-flex t-items-center t-justify-center">
      <div
        class="t-w-36 t-h-36 t-rounded-full t-flex t-items-center t-justify-center"
        :style="{ 'background': data.color }"
        :class="{ 't-bg-white': !data.color }"
      >
        <div
          class="t-w-32 t-h-32 t-rounded-full t-flex t-items-center
            t-justify-center t-bg-gray-200"
        >
          <el-avatar
            v-if="data.avatar"
            shape="circle"
            :size="128"
            :src="data.avatar"
          />
          <span v-else class="t-text-6xl t-text-gray-600">{{ initials }}</span>
        </div>
      </div>
    </div>

    <!-- Name -->
    <div class="t-text-base t-font-medium t-mt-5 t-text-gray-700 t-text-center t-break-normal">
      {{ data.name }}
    </div>

    <!-- Rating -->
    <div v-if="isTech && hasRatings" class="t-mt-6 t-text-center">
      <el-tooltip :content="rating" placement="bottom">
        <el-rate
          :value="rating"
          disabled
          :colors="['#F59E0B', '#F59E0B', '#F59E0B']"
          disabled-void-color="#D1D5DB"
        />
      </el-tooltip>
    </div>

    <div
      v-if="isTech && isTechsideConnected && phoneString"
      class="t-mt-6 t-text-center t-cursor-pointer hover:t-underline"
      @click="openTextMessagingDialog(primaryPhone)"
    >
      {{ phoneString }}
    </div>

    <el-divider class="t-my-6" />

    <!-- Info -->
    <div v-if="!fetching" class="t-space-y-6 t-text-sm t-text-gray-700">
      <!-- Vendor ID -->
      <div class="t-flex t-space-x-6 t-items-center">
        <el-tooltip content="Vendor ID" placement="top">
          <i class="material-icons-outline md-badge" />
        </el-tooltip>

        <div>{{ data.id || 'n/a' }}</div>
      </div>

      <!-- Primary Phone -->
      <div
        v-if="isCSR || isSupplier || isSalesRep || isTech"
        class="t-flex t-space-x-6 t-items-center"
      >
        <el-tooltip content="Primary Phone Number" placement="top">
          <i class="material-icons-outline md-phone" />
        </el-tooltip>
        <div
          v-if="primaryPhone"
          class="t-cursor-pointer hover:t-underline"
          @click="openTextMessagingDialog(primaryPhone)"
        >
          {{ primaryPhone }}
        </div>
        <div v-else>n/a</div>
      </div>

      <!-- Primary Email -->
      <div
        v-if="isCSR || isSupplier || isSalesRep || isTech"
        class="t-flex t-space-x-6 t-items-center"
      >
        <el-tooltip content="Email Address" placement="top">
          <i class="material-icons-outline md-email" />
        </el-tooltip>

        <div v-if="primaryEmail">
          {{ primaryEmail }}
        </div>
        <div v-else>n/a</div>
      </div>

      <!-- Primary Address -->
      <div
        v-if="isCSR || isSupplier || isSalesRep || isTech"
        class="t-flex t-space-x-6 t-items-center"
      >
        <el-tooltip content="Home Address" placement="top">
          <i class="material-icons-outline md-home" />
        </el-tooltip>

        <div v-if="primaryAddress" class="t-break-normal">
          {{ primaryAddress }}
        </div>
        <div v-else>n/a</div>
      </div>

      <!-- Personal Color -->
      <div v-if="data.id" class="t-flex t-space-x-6 t-items-center">
        <el-tooltip content="Personal Color" placement="top">
          <i class="material-icons-outline md-palette" />
        </el-tooltip>

        <el-form
          :model="colorForm"
          ref="colorForm"
          class="t-w-full"
          @submit.prevent.native
        >
          <el-form-item
            prop="color"
            :rules="{
                required: true,
                message: 'Please select a color',
                trigger: 'change'
              }"
          >
            <el-color-picker
              v-model="colorForm.color"
              size="small"
              class="t-shadow-md t-rounded"
              @change="colorChanged"
            />
          </el-form-item>
        </el-form>
      </div>

      <!-- Status -->
      <div v-if="data.id" class="t-flex t-space-x-6 t-items-center">
        <el-tooltip content="Status" placement="top">
          <i
            class="material-icons-outline"
            :class="data.is_disabled ? 'md-cancel' : 'md-check_circle'"
          />
        </el-tooltip>

        <div
          class="t-px-2 t-py-0.5 t-rounded t-text-white t-text-xs t-uppercase t-mr-3 t-cursor-pointer t-shadow-md"
          :class="data.is_disabled ? 't-bg-red-500' : 't-bg-green-500'"
          @click="toggleStatusHandler"
        >
          {{ data.is_disabled ? 'Inactive' : 'Active' }}
        </div>
      </div>

      <!-- Techside Account -->
      <div
        v-if="isTech"
        v-loading="isLoading"
        element-loading-background="rgba(0, 0, 0, 0.0)"
        class="t-flex t-space-x-6 t-items-center no-padding-spinner"
      >
        <el-tooltip content="Techside Account" placement="top">
          <el-badge value="new" class="item" type="primary">
            <i class="material-icons-outline md-link" />
          </el-badge>
        </el-tooltip>
        
        <div v-if="isTechsidePending && !isLoading">
          <techside-pending />
        </div>
        <div v-else-if="isTechsideConnected && !isLoading">
          <techside-connected />
        </div>
        <div v-else-if="isTechsideRejected && !isLoading">
          <techside-rejected />
        </div>
        <div v-else-if="isTechsideTerminated && !isLoading">
          <techside-terminated />
        </div>
        <div v-else-if="!isLoading">
          <techside-invitation />
        </div>
      </div>
      <div
        v-if="isSalesRep"
        class="t-flex t-space-x-6 t-items-center"
      >
        <el-tooltip content="Techside Account" placement="top">
          <i class="material-icons-outline md-link" />
        </el-tooltip>

        <div v-if="data.invitation_status === 'pending'">
          Invite Pending...
        </div>
        <div v-else-if="data.invitation_status === 'accepted'">
          Invite Accepted
        </div>
        <div v-else-if="data.invitation_status === 'rejected'">
          Invite Rejected
        </div>
        <div v-else>n/a</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import TechsideInvitation from './TechsideInvite/Invite.vue'
import TechsidePending from './TechsideInvite/Pending.vue'
import TechsideRejected from './TechsideInvite/Rejected.vue'
import TechsideTerminated from './TechsideInvite/Terminated.vue'
import TechsideConnected from './TechsideInvite/Connected.vue'
import parsePhoneNumber from 'libphonenumber-js'

export default {
  data() {
    return {
      colorForm: {
        color: ''
      }
    }
  },

  computed: {
    ...mapState(
      useVendorStore,
      [
        'data',
        'fetching',
        'initials',
        'primaryPhone',
        'primaryEmail',
        'primaryAddress',
        'isCSR',
        'isSaleSource',
        'isSalesRep',
        'isTech',
        'isLocation',
        'isSupplier',
        'fetchingVendorTechsideConnection',
      ]
    ),

    hasAccessToTextMessaging() {
      return this.$store.state.user.has_access_to_twilio_phone_numbers
    },

    isTechsidePending() {
      return this.data.vendorTechsideConnection?.status == 'pending'
    },

    isTechsideConnected() {
      return this.data.vendorTechsideConnection?.status == 'accepted'
    },

    isTechsideRejected() {
      return this.data.vendorTechsideConnection?.status == 'rejected'
    },

    isTechsideTerminated() {
      return this.data.vendorTechsideConnection?.status == 'terminated'
    },

    isLoading() {
      return this.fetchingVendorTechsideConnection || this.fetching
    },

    userIsAdmin () {
      return this.$store.state.user.admin !== 0
    },

    phoneString () {
      if (this.data.vendorTechsideConnection?.techsideUser?.phoneNumber?.length > 0) {
        const phone = parsePhoneNumber(this.data.vendorTechsideConnection.techsideUser.phoneNumber, 'US')
        if (!phone?.isValid()) {
          return null
        }
        return phone.formatNational()
      }

      return null
    },

    rating () {
      return this.data.vendorTechsideConnection?.techsideUser?.rating?.average || null
    },

    hasRatings () {
      return this.data.vendorTechsideConnection?.techsideUser?.rating?.total > 0 || false
    }
  },

  watch: {
    'data.id': function (val) {
      if (val) {
        this.colorForm.color = this.data.color
      } else {
        this.colorForm.color = ''
        this.$refs.colorForm.resetFields()
      }
    },
  },

  methods: {
    ...mapActions(useVendorStore, ['updateColor', 'toggleStatus', 'sendTechsideInvitation']),

    async colorChanged(color) {
      try {
        await this.$refs.colorForm.validate()
      } catch {
        return
      }

      try {
        await this.updateColor(color)
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async toggleStatusHandler() {
      try {
        await this.toggleStatus()
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    openTextMessagingDialog(phone) {
      if (this.hasAccessToTextMessaging) {
        this.$root.$emit('startTextMessagingChat', {
          model: 'vendor',
          id: this.data.id,
          name: `${this.data.name}`,
          phoneNumber: phone
        })
        this.$root.$emit('start-text-with-consumer', phone)
      } else {
        window.open(`tel:${phone}`, '_self')
      }
    }
  },

  components: {
    TechsideInvitation,
    TechsideConnected,
    TechsidePending,
    TechsideRejected,
    TechsideTerminated,
  }
}
</script>

<style lang="scss">
.no-padding-spinner{
  .el-loading-spinner {
    margin-top: 0px;
    top: 0;
  }
  .item {
    .el-badge__content {
      height: 22px;
    }
  }
}
</style>
