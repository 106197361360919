<template>
  <div>
    <b-modal
      @show="dialogAboutToAppear"
      @hide="dialogAboutToDisappear"
      no-close-on-backdrop no-close-on-esc
      :title="title"
      :id="computedModalId"
      header-bg-variant='primary'
      header-text-variant='light'
      size='lg'
      >
      <div class="row">
        <div class="form-group col-md-12">
          <Phone ref="phone1" :title="'Fax Number'" :value.sync="faxNumber"/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label for='faxSendName'>Name</label>
          <input id='faxSendName' type='text' class="form-control form-control-sm autocomplete-off" v-model="name" maxlength="32"/>
        </div>
      </div>
      <div v-if="mainPDFAttachmentName && mainPDFAttachmentName.length > 0" class="row">
        <div class="form-group col-sm-12 col-md-12 col-lg-5">
          <div class="form-check label label-gray label-sm mt-1">
            <input class="form-check-input" type="checkbox" id="include-docs" v-model='includePdf' />
            <label class="form-check-label" for="include-docs">{{mainPDFAttachmentName}}</label>
          </div>
        </div>
      </div>
      <br/>
      <!--
      <div class="row">
        <div class="form-group col">
          Include Attachments:&nbsp;<button @click='selectAllClicked'>Select All</button>&nbsp;&nbsp;&nbsp;&nbsp;<span v-if='hasWarranty'><input type='checkbox' v-model='includeWarranty'>&nbsp;Include Warranty</span>
        </div>
      </div>
      <br/>
      <div class="row" v-if='documentTags && documentTags.length > 0'>
        <div class="form-group col">
          <b-form-checkbox-group v-model='selectedDocuments'>
            <b-form-checkbox v-for='(documentTag, index) in documentTags' :key="index" :value='documentTag.id'><span v-bind:style="{ 'background-color': documentTag.tagColor }" class="tag tag-light">{{documentTag.tagName}}</span></b-form-checkbox>
          </b-form-checkbox-group>
          </div>
      </div>
      <br/>
      -->
      <div class="row">
        <div class="form-group col-md-12">
          <b-table :busy="loadingFaxHistory" responsive sort-by='sent' :sort-desc='false' :per-page='5' :current-page='currentPage' striped hover :items="tableValues" :fields="tableFields" >
            <template slot='confirmation' slot-scope="row" v-if='row.item.status === "sent"'>
              <!-- <el-tooltip content='Download transmittal page'>
                <a href="javascript:void(0)" @click='confirmationClicked(row.item.jobid)'><font-awesome-icon :icon='faCloudDownloadAlt'/></a>
              </el-tooltip>
              &nbsp;&nbsp; -->
              <el-tooltip content='Download sent pdf'>
                <a href="javascript:void(0)" @click='faxPdfClicked(row.item.jobid)'>&nbsp;<i class='fa fa-download'></i></a>
              </el-tooltip>
              &nbsp;
              <i v-if='retreivingConfirmation' class="el-icon-loading"></i>
            </template>
          </b-table>
          <b-pagination align='right' size='md' :total-rows="faxHistory.length" v-model="currentPage" :per-page="5"></b-pagination>
        </div>
      </div>
      <div slot="modal-footer" class='w-100'>
        <div class='float-right'>
          <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="sendFax">Send Fax</button>
          &nbsp;&nbsp;&nbsp;
          <button type='button' class='btn btn-secondary' variant="primary" @click="cancelButtonPressed">Cancel</button>
        </div>
      </div>
    </b-modal>
    <LoadingDialog :id='computedLoadingModalId' :isOpen='loadingDialogIsOpen' :message='"Generating and sending fax..."' />
  </div>
</template>

<script>
import Phone from '@/components/Helpers/Phone.vue'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faCloudDownloadAlt from '@fortawesome/fontawesome-free-solid/faCloudDownloadAlt'
import FaxHistory from '@/scripts/objects/fax_history'
import Faxnumber from '@/scripts/objects/faxnumber'
import LoadingDialog from '@/components/modals/LoadingDialog.vue'
import Api from '@/services/Api'

const tableFields = [
  {key: 'receiver', label: 'Sent To', sortable: true},
  {key: 'faxdatetime', label: 'Date/Time', sortable: true},
  {key: 'status', label: 'Fax Status', sortable: true},
  {key: 'confirmation', label: 'Confirmation', sortable: false},
]

export default {
  props: ['shopId', 'title', 'mainPDFAttachmentName', 'defaultFaxNumber', 'defaultName', 'fax_type', 'object_id', 'modalToReturnTo', 'modalId'],
  data () {
    return {
      faxNumber: '',
      name: '',
      tableFields: tableFields,
      currentPage: 1,
      selectedDocuments: [],
      includePdf: true,
      loadingDialogIsOpen: false,
      faxSendNumber: '',
      loadingFaxHistory: false,
      faxHistory: [],
      faxSending: false,
      retreivingConfirmation: false
    }
  },
  methods: {
    dialogAboutToDisappear: function () {
      if (!this.faxSending) {
        if (this.modalToReturnTo && this.modalToReturnTo.length > 0) {
          setTimeout(() => {
            this.$root.$emit('bv::show::modal', this.modalToReturnTo)
          }, 100)
        }
      }
    },
    dialogAboutToAppear: function () {
      if (this.defaultFaxNumber && this.defaultFaxNumber.length > 0) {
        this.faxNumber = this.defaultFaxNumber
      } else {
        this.faxNumber = ''
      }

      if (this.defaultName && this.defaultName.length > 0) {
        this.name = this.defaultName
      } else {
        this.name = ''
      }

      this.includePdf = true

      var self = this
      Faxnumber.getDefaultFax(res => {
        if (res.fax) {
          if (res.fax.length > 0) {
            self.faxSendNumber = res.fax
          } else {
            self.faxSendNumber = '4803043407'
          }
        }
      }, error => {
        console.log('error', error)
        self.faxSendNumber = '4803043407'
      })
      self.loadingFaxHistory = true
      FaxHistory.getAll(self.shopId, self.fax_type, self.object_id, function(faxHistory) {
        self.faxHistory = faxHistory
        self.loadingFaxHistory = false
      }, function (error) {
        console.log('error: ', error)
        self.loadingFaxHistory = false
      })
    },
    cancelButtonPressed: function () {
      this.$root.$emit('bv::hide::modal', this.computedModalId)
    },
    sendFax: function () {
      if (!this.includePdf && this.selectedDocuments.length === 0) {
        alert('You must include a PDF')
        return
      }
      var faxToSend = {}
      faxToSend.faxnumber = this.stripPhoneCharacters(this.faxNumber)
      faxToSend.name = this.name
      faxToSend.sender = this.stripPhoneCharacters(this.faxSendNumber)
      if (!faxToSend.sender || faxToSend.sender.length === 0) {
        faxToSend.sender = '4803043407'
      }
      faxToSend.documentids = this.selectedDocuments.join(',')
      faxToSend.status = 'preparing'
      faxToSend.object_id = this.object_id
      if (faxToSend.faxnumber.length === 0) {
        alert('Please enter in a Fax Number.')
        return
      }
      this.faxSending = true
      this.loadingDialogIsOpen = true
      var self = this

      if (this.fax_type === 'purchaseOrder') {
        this.sendPurchaseOrderFax(faxToSend, this.shopId, function (response) {
          self.loadingDialogIsOpen = false
          self.faxSending = false

          self.$root.$emit('bv::show::modal', self.computedModalId)

          /*
          if (typeof response === 'object' && response !== null && typeof response.error === 'string' && response.error.length > 0) {
            self.loadingDialogIsOpen = false
            alert('Unable to send fax: ' + response.error)
          } else {
            Jobfaxsent.getOne(self.shopId, response.jobFaxID, function (faxObject) {
              self.$root.$emit('addJobFax', faxObject)
              self.loadingDialogIsOpen = false
              self.faxSending = false
              self.$root.$emit('bv::show::modal', 'faxModal')
            })
          }
          */
        })
      } else if (this.fax_type === 'bill') {
        this.sendBillFax(faxToSend, this.shopId, function (response) {
          self.loadingDialogIsOpen = false
          self.faxSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        })
      } else if (this.fax_type === 'bill_payment') {
        this.sendBillPaymentFax(faxToSend, this.shopId, function (response) {
          self.loadingDialogIsOpen = false
          self.faxSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        })
      } else if (this.fax_type === 'journal_entry') {
        this.sendJournalEntryFax(faxToSend, this.shopId, function (response) {
          self.loadingDialogIsOpen = false
          self.faxSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        })
      } else if (this.fax_type === 'expense') {
        this.sendExpenseFax(faxToSend, this.shopId, function (response) {
          self.loadingDialogIsOpen = false
          self.faxSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        })
      } else if (this.fax_type === 'receive') {
        this.sendReceiveFax(faxToSend, this.shopId, function (response) {
          self.loadingDialogIsOpen = false
          self.faxSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        })
      } else if (this.fax_type === 'techPickup') {
        this.sendTechPickupFax(faxToSend, this.shopId, function (response) {
          self.loadingDialogIsOpen = false
          self.faxSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        })
      } else if (this.fax_type === 'vendorReturn') {
        this.sendVendorReturnFax(faxToSend, this.shopId, function (response) {
          self.loadingDialogIsOpen = false
          self.faxSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        })
      } else if (this.fax_type === 'unit_adjustments') {
        this.sendAdjustmentFax(faxToSend, this.shopId, function (response) {
          self.loadingDialogIsOpen = false
          self.faxSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        })
      } else if (this.fax_type === 'profit_and_loss_report' || this.fax_type === 'balance_sheet_report') {
        const html = document.querySelector('.table-container').outerHTML
        console.log('have fax html: ', html)
        faxToSend.mainPdfHtml = html
        faxToSend.mainPDFAttachmentName = self.mainPDFAttachmentName
        faxToSend.faxType = this.fax_type
        console.log('sneding main pdf: ', faxToSend.mainPdf)
        Api.post(`/api/shop/-1/report/${faxToSend.object_id}/fax`, faxToSend).then(res => {
          self.loadingDialogIsOpen = false
          self.faxSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        }, error => {
          console.log('error gsc orccurred: ', error)
          self.loadingDialogIsOpen = false
          self.faxSending = false
          self.$root.$emit('bv::show::modal', self.computedModalId)
        })
      } else {
        this.loadingDialogIsOpen = false
        this.faxSending = false
        alert('This fax type is unsupported')
      }
    },
    selectAllClicked: function () {
      this.selectedDocuments = []
      for (var i = 0; i < this.documentTags.length; ++i) {
        this.selectedDocuments.push(this.documentTags[i].id)
      }
    },
    confirmationClicked: async function (faxId) {
      this.retreivingConfirmation = true
      const {data} = await this.getFaxTransmittalPdf(faxId)
      this.retreivingConfirmation = false
      if (data.status) {
        alert('Something went wrong retreiving your fax transmittal page. Please try again.\n' + response.error)
        return
      }
      var pdfData = this.base64ToArrayBuffer(data)
      var link = document.createElement('a')

      var blob = new Blob([pdfData], {type: 'application/pdf;'})
      var URL = window.URL || window.webkitURL
      var downloadUrl = URL.createObjectURL(blob)

      link.href = downloadUrl
      link.download = 'transmittal-page-' + faxId + '.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    faxPdfClicked: async function (faxId) {
      this.retreivingConfirmation = true
      const {data} = await this.getFaxSentPdf(faxId)
      this.retreivingConfirmation = false
      if (!data || data.status) {
        alert('Something went wrong retreiving the copy of your previous fax. Please try again.')
        return
      }
      var pdfData = this.base64ToArrayBuffer(data)
      var link = document.createElement('a')

      var blob = new Blob([pdfData], {type: 'application/pdf;'})
      var URL = window.URL || window.webkitURL
      var downloadUrl = URL.createObjectURL(blob)

      link.href = downloadUrl
      link.download = 'fax-confirmation-' + faxId + '.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    empty: function () {
      this.$root.$emit('bv::hide::modal', 'faxModal')
    }
  },
  computed: {
    computedLoadingModalId () {
      return this.computedModalId + '-loading-modal'
    },
    computedModalId() {
      if (this.modalId && this.modalId.length > 0) {
        return this.modalId
      } else {
        return 'genericFaxModal'
      }
    },
    tableValues: {
      get () {
        var temp = []
        for (var i = 0; i < this.faxHistory.length; i++) {
          var shortTemp = this.faxHistory[i]
          shortTemp._rowVariant = shortTemp.status === 'sent' ? 'success' : shortTemp.status === 'error' ? 'danger' : ''
          temp.push(shortTemp)
        }
        return temp
      },
      set (val) {
        return []
      }
    },
    faCloudDownloadAlt () {
      return faCloudDownloadAlt
    }
  },
  mixins: [UtilityMixin],
  components: {
    Phone,
    FontAwesomeIcon,
    LoadingDialog
  }
}
</script>

<style>

</style>
