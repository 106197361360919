import reportsInitialState from './reports.state'
import reportsGetters from './reports.getters'
import reportsMutations from './reports.mutations'
import reportsActions from './reports.actions'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

import {
  reportsNamespace,
  reportsMutations as mutationsTypes,
  reportsActions as actionTypes
} from './reports.types'
import * as R from 'ramda'

const reportsModule = {
  namespaced: true,
  state: reportsInitialState,
  getters: reportsGetters,
  mutations: reportsMutations,
  actions: reportsActions
}

export default reportsModule

export const mapReports = {
  getters: getters => mapGetters(reportsNamespace, getters),
  state: state => mapState(reportsNamespace, state),
  mutations: mutations => mapMutations(reportsNamespace, R.pick(mutations)(mutationsTypes)),
  actions: actions => mapActions(reportsNamespace, R.pick(actions)(actionTypes))
}
