var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notification d-flex flex-column" }, [
    _c("div", { staticClass: "d-flex align-items-center header" }, [
      _c(
        "div",
        { staticClass: "icon-square-round", class: _vm.backgroundClass },
        [
          _c("i", {
            staticClass: "material-icons-round md-24",
            class: _vm.iconClass
          })
        ]
      ),
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c(
          "div",
          { staticClass: "title font-weight-bold", class: _vm.titleClass },
          [_vm._t("title")],
          2
        ),
        _c("div", { staticClass: "subtitle d-flex" }, [
          !_vm.isUnknown && _vm.isEmail(_vm.data.details.user.name)
            ? _c(
                "div",
                { staticClass: "font-weight-bold" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "box-item",
                      attrs: {
                        effect: "light",
                        placement: "top",
                        content: _vm.data.details.user.name
                      }
                    },
                    [_c("span", [_vm._v("@" + _vm._s(_vm.parsedUsername))])]
                  )
                ],
                1
              )
            : !_vm.isUnknown
            ? _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(" @" + _vm._s(_vm.data.details.user.name) + " ")
              ])
            : _c("div", { staticClass: "font-weight-bold t-text-gray-400" }, [
                _vm._v(" @GlassBiller ")
              ]),
          _c(
            "div",
            { staticClass: "t-text-gray-400 date" },
            [
              _vm.isToday
                ? _c("span", [_vm._v(_vm._s(_vm.messageDate.time))])
                : _c(
                    "el-tooltip",
                    {
                      staticClass: "box-item",
                      attrs: {
                        effect: "light",
                        content: _vm.messageDate.time,
                        placement: "top"
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.messageDate.date))])]
                  )
            ],
            1
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "text-break" }, [_vm._t("default")], 2),
    !!_vm.data.phone_numbers || !!_vm.data.email_addresses
      ? _c("div", { staticClass: "d-flex footer" }, [
          _c(
            "div",
            { staticClass: "d-flex align-items-center ml-auto channels" },
            [
              !!_vm.data.phone_numbers
                ? _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top-start",
                        title: "Sent via SMS",
                        width: "200",
                        trigger: "hover",
                        content: _vm.smsTooltip
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "material-icons-round md-24 md-sms",
                        attrs: { slot: "reference" },
                        slot: "reference"
                      })
                    ]
                  )
                : _vm._e(),
              !!_vm.data.email_addresses
                ? _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top-start",
                        title: "Sent via email",
                        width: "200",
                        trigger: "hover",
                        content: _vm.emailTooltip
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "material-icons-round md-24 md-mail",
                        attrs: { slot: "reference" },
                        slot: "reference"
                      })
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }