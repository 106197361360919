import Chat from 'twilio-chat'
import store from '@/store/store'
import { Notification } from 'element-ui'
import { chatViews } from '@/constants/chat.config'
import { chatTypes } from '@/store/modules/chat/types'

let client
let activeChannel
let existingChannels

const init = async () => {
  const { token, channels } = await generateToken()
  if (!token) {
    return false
  }
  existingChannels = channels
  client = await Chat.create(token)
  initClientEvents()
  return true
}

const generateToken = () => store.dispatch(chatTypes.namespace + chatTypes.actions.generateToken)

const updateToken = async () => {
  const token = await generateToken()
  client.updateToken(token)
}

const initClientEvents = () => {
  client.on('tokenAboutToExpire', () => updateToken())

  client.on('channelAdded', channel => {
    // console.log('channelAdded: ' + channel.uniqueName)
  })

  client.on('channelJoined', async channel => {
    if (store.state.chat.connected) {
      await store.dispatch(
        chatTypes.namespace + chatTypes.actions.getUserChannels
      )
      await store.dispatch(
        chatTypes.namespace + chatTypes.actions.openChannel,
        channel
      )
      await store.dispatch(
        chatTypes.namespace + chatTypes.actions.getMembers,
        channel
      )
      await store.dispatch(
        chatTypes.namespace + chatTypes.actions.getTotalMessages,
        channel
      )
      if (!existingChannels.find(i => i.sid === channel.sid)) {
        Notification({
          duration: 10000,
          title: 'Channel added',
          type: 'success',
          dangerouslyUseHTMLString: true,
          message: `You were added to the <span class="t-font-bold">${getChannelName(channel)}</span> channel`,
          position: 'bottom-right',
          customClass: 't-shadow-xl t-font-sans'
        })
      }
    }
  })

  client.on('channelInvited', channel => {
    // console.log('channelInvited: ' + channel.uniqueName)
  })

  client.on('channelUpdated', ({ channel, updateReasons }) => {
    // console.log('channelUpdated: ' + channel.uniqueName + '. Reasons: ' + updateReasons.join(', '))
  })

  client.on('channelLeft', channel => {
    // console.log('channelLeft: ' + channel.uniqueName)
  })

  client.on('channelRemoved', channel => {
    Notification({
      duration: 10000,
      title: 'Channel removed',
      type: 'warning',
      dangerouslyUseHTMLString: true,
      message: `Channel <span class="t-font-bold">${getChannelName(channel)}</span> was removed`,
      position: 'bottom-right',
      customClass: 't-shadow-xl t-font-sans'
    })
    if (
      store.state.chat.activeChannel &&
      store.state.chat.activeChannel.uniqueName === channel.uniqueName
    ) {
      store.dispatch(
        chatTypes.namespace + chatTypes.actions.changeView,
        chatViews.welcome
      )
    }
    store.dispatch(chatTypes.namespace + chatTypes.actions.getUserChannels)
  })

  client.on('memberJoined', member => {
    if (member.identity !== store.state.user.username) {
      Notification({
        duration: 10000,
        title: 'User joined',
        type: 'success',
        dangerouslyUseHTMLString: true,
        message: `<span class="t-font-bold">${member.identity}</span> joined <span class="t-font-bold">${getChannelName(member.channel)}</span> channel`,
        position: 'bottom-right',
        customClass: 't-shadow-xl t-font-sans'
      })
    }
    // console.log('memberJoined: ' + member.identity + '. Channel: ' + member.channel.uniqueName)
  })

  client.on('memberLeft', member => {
    if (member.identity !== store.state.user.username) {
      Notification({
        duration: 10000,
        title: 'User left',
        type: 'warning',
        dangerouslyUseHTMLString: true,
        message: `<span class="t-font-bold">${member.identity}</span> left <span class="t-font-bold">${getChannelName(member.channel)}</span> channel`,
        position: 'bottom-right',
        customClass: 't-shadow-xl t-font-sans'
      })
    }
    if (
      store.state.chat.activeChannel &&
      store.state.chat.activeChannel.uniqueName === member.channel.uniqueName
    ) {
      store.dispatch(
        chatTypes.namespace + chatTypes.actions.getMembers,
        member.channel
      )
    }
  })

  client.on('memberUpdated', ({ member, updateReasons }) => {
    // console.log('memberUpdated: ' + member.identity + '. Reasons: ' + updateReasons.join(', '))
  })

  client.on('messageAdded', message => {
    store.dispatch(chatTypes.namespace + chatTypes.actions.setNewMessage, {
      author: message.author,
      body: message.body,
      attributes: message.attributes,
      dateCreated: message.dateCreated,
      dateUpdated: message.dateUpdated,
      index: message.index,
      lastUpdatedBy: message.lastUpdatedBy,
      memberSid: message.memberSid,
      sid: message.sid,
      type: message.type,
      state: {
        timestamp: message.dateCreated
      },
      channel: {
        name: getChannelName(message.channel),
        sid: message.channel.sid,
        uniqueName: message.channel.uniqueName,
        isPrivate: message.channel.isPrivate
      },
      media: message.media
        ? {
          contentType: message.media.contentType,
          sid: message.media.sid,
          size: message.media.size,
          filename: message.media.filename,
          getContentTemporaryUrl: () => message.media.getContentTemporaryUrl()
        }
        : null
    })
  })

  client.on('messageRemoved', () => {
    // console.log('messageRemoved')
  })

  client.on('messageUpdated', () => {
    // console.log('messageUpdated')
  })

  client.on('typingStarted', member => {
    store.commit(
      chatTypes.namespace + chatTypes.mutations.setMemberTyping,
      {
        channelUniqueName: member.channel.uniqueName,
        memberIdentity: member.identity
      }
    )
  })

  client.on('typingEnded', member => {
    store.commit(
      chatTypes.namespace + chatTypes.mutations.unsetMemberTyping,
      {
        channelUniqueName: member.channel.uniqueName,
        memberIdentity: member.identity
      }
    )
  })

  client.on('userSubscribed', user => {
    // console.log('userSubscribed: ' + user.identity)
  })

  client.on('userUnsubscribed', user => {
    // console.log('userUnsubscribed')
  })

  client.on('userUpdated', event => handleUserUpdated(event))
}

const handleUserUpdated = ({ user, updateReasons }) => {
  if (updateReasons.includes('online')) {
    store.commit(
      chatTypes.namespace + chatTypes.mutations.setUserReachability,
      {
        identity: user.identity,
        reachability: user.online
      }
    )
  }
}

const getChannelName = ({ uniqueName, friendlyName }) => {
  if (friendlyName !== 'direct') {
    return uniqueName
  }
  if (uniqueName.split('-')[0] === store.state.user.username) {
    return uniqueName.split('-')[1]
  }
  return uniqueName.split('-')[0]
}

const subscribeToUsers = async userIdentities => {
  for (const identity of userIdentities) {
    await getUser(identity)
  }
}

const getUser = identity => client.getUser(identity)

const getSubscribedUsers = () => client.getSubscribedUsers()

const getChannelBySid = sid => client.getChannelBySid(sid)

const getPublicChannels = () => client.getPublicChannelDescriptors()

const getUserChannels = () => client.getUserChannelDescriptors()

const getSubscribedChannels = () => client.getSubscribedChannels()

const getChannelByUniqueName = name => client.getChannelByUniqueName(name)

const createChannel = ({
  isPrivate,
  uniqueName,
  friendlyName = ''
}) => client.createChannel({
  isPrivate,
  uniqueName,
  friendlyName
})

const joinChannel = async channel => {
  const ch = await channel.join()
  setActiveChannel(ch)
  return ch
}

const leaveChannel = channel => channel.leave()

const setActiveChannel = channel => {
  activeChannel = channel
}

const sendMessage = async (message, attributes) => {
  const messageIndex = await activeChannel.sendMessage(message, attributes)
  await activeChannel.updateLastConsumedMessageIndex(messageIndex)
}

const getMessages = () => activeChannel.getMessages()

const typingStarted = () => activeChannel.typing()

const getActiveChannelMembers = () => activeChannel.getMembers()

export const ChatService = Object.freeze({
  init,
  initClientEvents,
  subscribeToUsers,
  getUser,
  getSubscribedUsers,
  getChannelBySid,
  getPublicChannels,
  getUserChannels,
  getSubscribedChannels,
  getChannelByUniqueName,
  createChannel,
  joinChannel,
  leaveChannel,
  setActiveChannel,
  sendMessage,
  getMessages,
  typingStarted,
  getActiveChannelMembers
})
