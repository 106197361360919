var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-card-view-filter" },
    [
      _c("div", { staticClass: "ui-input-label" }, [_vm._v("Board view:")]),
      _c(
        "el-select",
        {
          attrs: { placeholder: "Select board view" },
          model: {
            value: _vm.select,
            callback: function($$v) {
              _vm.select = $$v
            },
            expression: "select"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: {
              label: item.label,
              value: item.value,
              disabled: item.disabled
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }