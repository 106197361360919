import * as R from 'ramda'
import { flags } from './../../constants/warehouse.config'
import Api from '@/services/Api'

export const getTitle = ({ type, name }) => `${type.toUpperCase()} - ${name}`

export const getAddressCityState = ({
  address_city,
  address_state,
  address_zip
}) => `${address_city}, ${address_state} ${address_zip}`

export class WarehouseWrapper {
  constructor (warehouse) {
    this.warehouse = warehouse
  }

  getTitle = () => getTitle(this.warehouse)

  getAddressStreet = () => R.prop('address_street')(this.warehouse)

  getAddressCityState = () => getAddressCityState(this.warehouse)

  getPhone = () => R.prop('phone')(this.warehouse)

  getFax = () => R.prop('fax')(this.warehouse)

  getManager = () => R.prop('manager')(this.warehouse)
}

export const getHumanReadableDate = stamp => {
  let tempStamp = (stamp * 1000)
  let dateObj = new Date(tempStamp)
  let month = (dateObj.getMonth() + 1)
  let day = dateObj.getDate()
  let year = dateObj.getFullYear()
  let readableDate = month + '/' + day + '/' + year
  return readableDate
}

export const getHumanReadableTime = stamp => {
  let tempStamp = (stamp * 1000)
  let dateObj = new Date(tempStamp)
  let tempHours = dateObj.getHours()
  let tempMinutes = dateObj.getMinutes()
  let tempSeconds = dateObj.getSeconds()
  let suffix = 'AM'
  if (tempHours === 12) {
    suffix = 'PM'
  }
  if (tempHours > 12) {
    suffix = 'PM'
    tempHours = (tempHours - 12)
  }
  if (tempHours < 10) { tempHours = "0" + tempHours }
  if (tempMinutes < 10) { tempMinutes = "0" + tempMinutes }
  if (tempSeconds < 10) { tempSeconds = "0" + tempSeconds }
  let readableTime = tempHours + ':' + tempMinutes + ':' + tempSeconds + ' ' + suffix
  return readableTime
}

export const getFlagTitle = type => R.pipe(R.propOr('', type), R.propOr('', 'title'))(flags)

export const getFlagColor = type => R.pipe(R.propOr('', type), R.propOr('', 'color'))(flags)

const getTableFromType = type => R.pipe(R.propOr('', type), R.propOr('', 'ref_table'))(flags)

const isNotNull = val => !R.isNil(val)

export const getReceivesCount = (partArray) => {
  let recArray = []
  for (var i = 0; i < partArray.length; i++) {
    if (partArray[i].part_receives) {
      for (var j = 0; j < partArray[i].part_receives.length; j++) {
        if (!recArray.includes(partArray[i].part_receives[j].receive_id)) {
          recArray.push(partArray[i].part_receives[j].receive_id)
        }
      }
    }
  }

  return recArray.length
}

export const checkForAllReceived = (partArray) => {
  for (var i = 0; i < partArray.length; i++) {
    if (parseInt(partArray[i].quantity) > parseInt(partArray[i].quantity_received)) {
      return false
    }
  }
  return true
}

// export const getFlagCount = (type, partArray) => R.pipe(
//   R.filter(
//     R.compose(
//       isNotNull, 
//       R.path([getTableFromType(type)+'_id'])
//       )
//     ),
//     R.groupBy(R.prop([getTableFromType(type)+'_id'])),
//     R.map(R.pipe(R.head, R.pick([getTableFromType(type)+'_id']))),
//     R.values,
//   R.length)(partArray)

export const getTechPickupCount = (partArray) => {
  let foundIds = []
  let foundTPUs = 0
  for (var i = 0; i < partArray.length; i++) {
    if (partArray[i].tech_pickup_id !== null && !foundIds.includes(partArray[i].tech_pickup_id)) {
      foundIds.push(partArray[i].tech_pickup_id)
      foundTPUs++
    }
  }
  return foundTPUs
}

export const checkForAllComplete = (partArray) => R.pipe(
  R.filter(
    R.either(
      R.compose(
        isNotNull, 
        R.path(['tech_pickup_id'])
        ),
      R.compose(
        isNotNull,
        R.path(['tech_pickup_status'])
      )
    )
  ), R.values, R.length, R.equals(R.length(partArray))
)(partArray)

export const checkForAllReturned = (partArray) => R.pipe(
  R.filter(
    R.compose(
      isNotNull,
      R.path(['vendor_return_id'])
    )
  ), R.values, R.length, R.equals(R.length(partArray))
)(partArray)

export const getReturnsCount = (partArray) => {
  let foundIds = []
  let foundVRs = 0
  for (var i = 0; i < partArray.length; i++) {
    if (partArray[i].vendor_return_id !== null && !foundIds.includes(partArray[i].vendor_return_id)) {
      foundIds.push(partArray[i].vendor_return_id)
      foundVRs++
    }
  }
  return foundVRs
}

export const getWarehouseCounts = async (shopId, callbackFunction) => {
  Api.get(`/api/shop/${shopId}/warehouseCounts`).then(res => {
    callbackFunction(res.data)
  })
}

export const checkForInvoice = job => R.pathEq(['status'], 'invoice')(job)

export const getInventoryDetails = async (shopId, itemId, type, callbackFunction) => {
  console.log(shopId, itemId, type)
  Api.get(`/api/shop/${shopId}/items/${itemId}/type/${type}`).then(res => {
    callbackFunction(res.data)
  })
}

export const releasePurchaseOrderInventoryPart = async (purchaseOrderPartIds, shopId, callbackFunction, errorCallbackFunction = null) => {
  Api.post(`/api/shop/${shopId}/releaseInventoryPO`, {purchaseOrderPartIds}).then(res => {
    callbackFunction(res)
  }, (error) => {
    if(errorCallbackFunction) {
      errorCallbackFunction(error)
    }
  })
}