<template>
  <b-modal @show="dialogAboutToAppear" @hidden="dialogDisappeared" @shown="$refs.nameComponent.focus()" no-close-on-backdrop no-close-on-esc
    v-bind:title="tag.id > 0 ? 'Edit Shop Tag' : 'New Shop Tag'"
    :id="modalID"
    header-bg-variant='primary'
    header-text-variant='light'>
    <div class="row">
      <div class="form-group col-6">
        <label>Status</label>
        <select v-model='tag.status' class="form-control form-control-sm">
          <option value="active">Active</option>
          <option value="disabled">Disabled</option>
        </select>
      </div>
      <div v-if="tag.id > 0" class="form-group col-6">
        <label></label>
        <p>ID: <span class="idBox">{{tag.id}}</span></p>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-6">
        <RequiredInput ref="nameComponent" :title="'Tag Name'" :value.sync='tag.text' :placeholder="''" :capitalizeAll='true' />
      </div>
      <div class="form-group col-6">
        <label>Tag Color</label>
        <br>
        <Sketch v-model="tagColor" />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label>Details</label>
        <textarea class="form-control form-control-sm" v-model='tag.details'></textarea>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <input type="checkbox" v-model='tag.default_tag'/> Auto add on new job
      </div>
    </div>
    <div slot="modal-footer" style="margin-top:0px;" class='w-100'>
      <div class='float-left' v-if="tag.id <= 0">
        <input type="checkbox" v-model="replicateAcrossAllShops"/> Replicate across all shops
      </div>
      <div class='float-right'>
        <img v-show="saving" style="width:18px;height:18px;margin-right:3px;" src='./../../assets/img/spinner.gif'>
        <button v-show="!saving" type='button' size='lg' class='btn btn-primary' variant="primary" @click="saveTag">Save</button>
        &nbsp;&nbsp;
        <button type='button' class='btn btn-secondary' variant="primary" @click="cancelEdits">Cancel</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Tag from '@/scripts/objects/tag'
import RequiredInput from '@/components/Helpers/RequiredInput.vue'
import { ValidateComponents, ResetValidateComponents } from '@/scripts/helpers/helpers.js'
import Sketch from '@/assets/customComponents/sketchColorPicker/components/Sketch.vue'

export default {
  props: ['shopId', 'editTag', 'modalId'],
  data () {
    return {
      tag: new Tag(),
      tagColor: {},
      validateInputs: [],
      replicateAcrossAllShops: false,
      saving: false,
      modalID: this.modalId || 'tagModal'
    }
  },
  mounted: function () {
    this.validateInputs = [this.$refs.nameComponent]
  },
  methods: {
    dialogAboutToAppear: function () {
      
      this.tag = new Tag(this.editTag)
      this.tagColor = this.tag.color
      this.saving = false
      this.replicateAcrossAllShops = false
      
      ResetValidateComponents(this.validateInputs)
    },
    dialogDisappeared: function () {
      if (this.modalID === 'rejectedNewTagModal') {
        this.$root.$emit('bv::show::modal', 'rejectedJobTagModal')
      } else if (this.modalID === 'queuedNewTagModal') {
        this.$root.$emit('bv::show::modal', 'queuedJobTagModal')
      } else {
        this.$root.$emit('bv::show::modal', 'jobtagModal')
      }
    },
    saveTag: function (event) {
      if (ValidateComponents(this.validateInputs)) {
        let self = this
        self.saving = true
        this.tag.createUpdateTag(this.shopId, (this.tag.id <= 0 && this.replicateAcrossAllShops), function (object) {
          self.tag = new Tag(object)
          
          if (self.modalID === 'rejectedNewTagModal') {
            self.$root.$emit('addTemporaryTagFromRejectedDialog', self.tag)
          } else if (self.modalID === 'queuedNewTagModal') {
            self.$root.$emit('addTemporaryTagFromQueuedDialog', self.tag)
          } else {
            self.$root.$emit('addUpdateTag', self.tag)
          }
          self.$root.$emit('bv::hide::modal', self.modalID)
          self.saving = false
        }, function (error) {
          
          self.saving = false
        })
      }
    },
    cancelEdits: function (event) {
      event.preventDefault()
      this.$root.$emit('bv::hide::modal', this.modalID)
    }
  },
  watch: {
    'editTag': function (val) {
      if(val) {
        this.tag = new Tag(val)
        this.tagColor = this.tag.color
      } else {
        this.tag = new Tag()
        this.tagColor = this.tag.color
      }
    },
    'tagColor': function (val) {
      if (val.hex) {
        this.tag.color = val.hex
      } else {
        this.tag.color = val
      }
    }
  },
  components: {
    RequiredInput,
    Sketch
  }
}
</script>

<style>
.idBox {
  background-color: #EDC9AF;
  padding: 5px;
  border-radius: 3px;
}
</style>
