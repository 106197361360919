<template>
  <div
    class="global-search-dialog-container"
    @click="onClose"
    @keydown.down.prevent="next"
    @keydown.up.prevent="prev"
    @keydown.enter.prevent="enter"
  >
    <transition
      appear
      enter-active-class="animate__animated animate__fadeInDown"
      leave-active-class="animate__animated animate__fadeOutUp"
      @after-leave="close"
      mode="out-in"
    >
      <!-- @keydown.enter.prevent="select(highlight)" -->
      <div
        class="global-search-dialog"
        :style="{'max-width':
        (results.jobs && results.jobs.length > 0 &&
        results.consumers && results.consumers.length > 0) ? '1200px' : '700px'}"
        @click.stop
        @keydown.esc.prevent="onClose"
        v-if="show"
      >
        <div class="global-search-top">
          <i class="el-icon-search"></i>
          <input
            class="global-search-dialog-input"
            placeholder="Search anything"
            v-model="query"
            @input="initialSearch"
            ref="searchbar"
          />
          <md-circle-button
            icon="tune"
            class="search-settings-button"
            @click="showSettings = !showSettings"
            :primary="showSettings"
          />
          <div class="close-search" @click="onClose">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <global-search-settings :display="showSettings" />
        <global-search-loading v-if="loading" />
        <global-search-results
          v-else
          :results="results"
          @select="onSelect"
          :show-recent="showRecent"
          :noMoreJobResults="noMoreJobResults"
          :noMoreConsumerResults="noMoreConsumerResults"
          :noMoreSalesideJobResults="noMoreSalesideJobResults"
          @loadMore="loadMore"
          ref="results"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import GlobalSearchResults from './GlobalSearchResults'
import GlobalSearchLoading from './GlobalSearchLoading'
import GlobalSearchSettings from './GlobalSearchSettings'
import axios from 'axios'
import { sync } from 'vuex-pathify'
import * as R from 'ramda'

export default {
  provide() {
    return {
      query: this.q
    }
  },
  components: {
    GlobalSearchResults,
    GlobalSearchLoading,
    GlobalSearchSettings
  },
  data() {
    return {
      query: '',
      q: {
        value: ''
      },
      results: {
        consumers: [],
        jobs: [],
        salesidejobs: []
      },
      page: 1,
      noMoreJobResults: false,
      noMoreConsumerResults: false,
      noMoreSalesideJobResults: false,
      loading: false,
      showRecent: true,
      highlight: 0,
      source: null,
      show: true,
      showSettings: false
    }
  },
  computed: {
    settings: sync('jobDetails/searchConfig')
  },
  methods: {
    onClose() {
      this.show = false
    },
    close() {
      this.results = {
        consumers: [],
        jobs: [],
        salesidejobs: []
      }
      this.page = 1
      this.noMoreJobResults = false
      this.noMoreConsumerResults = false
      this.noMoreSalesideJobResults = false
      this.loading = false
      this.showRecent = true
      this.$emit('close')
      this.show = true
    },
    initialSearch: debounce(
      function() {
        this.results = {
          consumers: [],
          jobs: [],
          salesidejobs: []
        }
        this.page = 1
        this.noMoreJobResults = false
        this.noMoreConsumerResults = false
        this.noMoreSalesideJobResults = false
        this.runSearch()
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),
    loadMore() {
      this.page++
      this.runSearch()
    },
    async runSearch() {
      try {
        if (this.source) {
          this.source.cancel()
        }
        this.source = axios.CancelToken.source()
        this.loading = true
        const { data } = await this.$unum.job().get('/search/job', {
          params: {
            q: this.query,
            page: this.page,
            ...R.filter(R.identity)(this.settings)
          },
          ...(this.source ? { cancelToken: this.source?.token } : {})
        })
        this.showRecent = false
        if (data.jobs.length < 4) {
          this.noMoreJobResults = true
        }
        if (data.consumers.length < 4) {
          this.noMoreConsumerResults = true
        }
        if (data.salesidejobs.length < 4) {
          this.noMoreSalesideJobResults = true
        }

        this.results.consumers.push(...data.consumers)
        this.results.jobs.push(...data.jobs)
        this.results.salesidejobs.push(...data.salesidejobs)
        this.loading = false
      } catch (error) {
        if (!axios.isCancel(error)) {
          this.loading = false
          this.results = {}
        }
      } finally {
        this.q.value = this.query
      }
    },
    onSelect(v) {
      this.$emit('select', v)
    },
    next() {
      if (this.$refs.results) {
        this.$refs.results.nextOption()
      }
    },
    prev() {
      if (this.$refs.results) {
        this.$refs.results.prevOption()
      }
    },
    enter() {
      if (this.$refs.results) {
        this.$refs.results.select()
      }
    }
  },
  mounted() {
    this.$refs.searchbar.focus()
  },
  watch: {
    settings() {
      this.runSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./GlobalSearchDialog.scss";
.animate__animated {
  --animate-duration: 0.2s;
}
</style>
