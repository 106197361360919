export const invoiceTerms = [
  { value: 'dor', label: 'Due on receipt', id: 'dor', name: 'Due on receipt' },
  { value: 'net10', label: 'Net 10', id: 'net10', name: 'Net 10' },
  { value: 'net15', label: 'Net 15', id: 'net15', name: 'Net 15' },
  { value: 'net20', label: 'Net 20', id: 'net20', name: 'Net 20' },
  { value: 'net20_500', label: 'Net 20 $500', id: 'net20_500', name: 'Net 20 $500' },
  { value: 'net30', label: 'Net 30', id: 'net30', name: 'Net 30' },
  { value: 'net45', label: 'Net 45', id: 'net45', name: 'Net 45' },
  { value: 'net60', label: 'Net 60', id: 'net60', name: 'Net 60' },
  { value: 'net90', label: 'Net 90', id: 'net90', name: 'Net 90' },
  { value: 'custom', label: 'Custom', id: 'custom', name: 'Custom'}
]
