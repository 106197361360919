<template>
  <div class="t-h-1/2 t-py-6 t-px-8 t-border-0 t-border-b t-border-solid t-border-gray-200">
    <div class="t-w-72 t-flex t-flex-col t-items-stretch ">
      <div class="t-font-medium t-text-gray-400 t-text-xs t-mb-2">
        Inventory
      </div>
      <el-select
        v-model="selectedShop"
        filterable
        placeholder="Select shop"
        size="mini"
        class="t-mt-1 t-mb-2"
      >
        <el-option
          v-for="i in shops"
          :key="i.id"
          :label="i.name"
          :value="i.id"
        />
      </el-select>
      <UnitCounts
        v-if="selectedShop && itemId"
        :itemId="itemId"
        :shopId="selectedShop"
      />
    </div>
  </div>
</template>

<script>
import UnitCounts from './UnitCounts.vue'

export default {
  components: {
    UnitCounts
  },

  props: {
    itemId: Number,
    shopId: Number
  },

  data: () => ({
    selectedShop: null
  }),

  computed: {
    shops() {
      return this.$store.state.shops
    }
  },

  mounted: async function() {
    this.selectedShop = this.shopId || this.$store.state.user.shop_id
  }
}
</script>
