var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-flex" }, [
    _c(
      "div",
      { staticClass: "t-flex-grow" },
      [
        _c("div", { staticClass: "t-text-gray-400 t-mt-2" }, [
          _vm._v("Phone Number")
        ]),
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "t-space-y-6",
            attrs: { model: _vm.form },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          _vm._l(_vm.form.phones, function(i, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "t-flex t-items-center t-space-x-3 first:t-mt-6"
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "phones." + index + ".number",
                      rules: {
                        validator: _vm.phoneValidator,
                        trigger: "blur"
                      }
                    }
                  },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "(###) ###-####",
                          expression: "'(###) ###-####'"
                        }
                      ],
                      attrs: { placeholder: "(999) 999-9999" },
                      on: {
                        change: function($event) {
                          return _vm.setPhone(index, $event)
                        }
                      },
                      model: {
                        value: i.number,
                        callback: function($$v) {
                          _vm.$set(i, "number", $$v)
                        },
                        expression: "i.number"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    attrs: { content: "Remove Phone Number", placement: "top" }
                  },
                  [
                    _c("i", {
                      staticClass:
                        "material-icons-outline md-cancel t-text-base t-text-red-400 t-cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.deletePhoneHandler(index)
                        }
                      }
                    })
                  ]
                ),
                i.id
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            i.id === _vm.data.phone_id
                              ? "Primary Phone"
                              : "Set as Primary Phone",
                          placement: "top"
                        }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "material-icons-outline t-text-base t-cursor-pointer",
                          class:
                            i.id === _vm.data.phone_id
                              ? "md-radio_button_checked t-text-blue-500"
                              : "md-radio_button_unchecked t-text-gray-400",
                          on: {
                            click: function($event) {
                              return _vm.setPrimaryPhoneHandler(i.id)
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "t-flex t-flex-col t-justify-end" },
      [
        _c(
          "el-button",
          {
            attrs: { plain: "", icon: "el-icon-circle-plus-outline" },
            on: { click: _vm.addPhone }
          },
          [_vm._v(" Add ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }