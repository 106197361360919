import Api from '@/services/Api'

const decodeVinNumbersWithMultipleResults = async() => {
  const { data } = await Api.get('/api/nags/decode-vins-with-multiple-results')
  return data
}

const getVinsWithMultipleResultsStats = async() => {
  const { data } = await Api.get('/api/nags/vins-with-multiple-results-stats')
  return data
}

export const nagsRepository = Object.freeze({
  decodeVinNumbersWithMultipleResults,
  getVinsWithMultipleResultsStats
})
