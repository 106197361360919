import state from './job-details.state'
import actions from './job-details.actions'
import getters from './job-details.getters'
import { make } from 'vuex-pathify'

const jobDetailsModule = {
  namespaced: true,
  state,
  getters,
  mutations: make.mutations(state),
  actions
}

export default jobDetailsModule
