<template>
  <div
    @click="open"
    id="button"
    class="t-bg-white t-p-3 t-rounded-full t-shadow-md t-cursor-pointer"
  >
    <span
      v-if="totalMessagesCount > 0"
      id="chat-messages-count"
      class="t-flex t-items-center t-justify-center">
      {{ totalMessagesCount }}
    </span>
    <i class="el-icon-chat-line-round"></i>
  </div>
</template>

<script>
import { chatTypes } from '@/store/modules/chat/types'

export default {
  computed: {
    totalMessagesCount: function() {
      return this.$store.getters[chatTypes.namespace + chatTypes.getters.getTotalUnreadMessages]
    }
  },
  methods: {
    open: function() {
      this.$store.dispatch(chatTypes.namespace + chatTypes.actions.open)
    }
  }
}
</script>

<style lang="scss" scoped>
#button {
  position: fixed;
  bottom: 40px;
  left: 40px;
  i {
    font-size: 40px;
  }

  #chat-messages-count {
    position: absolute;
    color: #fff;
    background-color: #28a745;
    border-radius: 999px;
    font-size: 12px;
    width: 22px;
    height: 22px;
    top: -1px;
    right: -3px;
  }
}
</style>
