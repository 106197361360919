import { JOBTAX_FRAGMENT } from '@/apollo/fragments/tax.fragment'
import {
  JOB_DOCS_QUERY,
  JOB_NOTES_QUERY,
  JOB_PAYMENTS_QUERY,
  JOB_TAGS_QUERY,
  JOB_TAXES_QUERY
} from '@/apollo/queries/job-pieces.query'
import { getTotalTaxPercent } from '@/scripts/helpers/taxes.helpers'
import JobDetailsMixin from './job-details.mixin'
import {
  ADD_JOB_DOCUMENTS_MUTATION,
  DELETE_JOB_PIECE_MUTATION,
  SET_JOBTAGS_MUTATION
} from '@/apollo/mutation/job.mutation'
import * as R from 'ramda'
import { SHOP_PIECES_QUERIES } from '@/apollo/queries/shop-pieces.query'

const JobPiecesMixin = {
  mixins: [JobDetailsMixin],
  data() {
    return {
      jobDocs: [],
      jobTaxes: [],
      jobTaxesLoading: false,
      jobTags: [],
      jobNotes: [],
      jobPayments: []
    }
  },
  apollo: {
    jobDocs: {
      query: JOB_DOCS_QUERY,
      variables() {
        return this.$route.params
      },
      skip() {
        return !this.hasId
      }
    },
    jobTaxes: {
      query: JOB_TAXES_QUERY,
      variables() {
        return this.$route.params
      },
      skip() {
        return !this.hasId
      },
      watchLoading(v) {
        this.jobTaxesLoading = v
      }
    },
    jobTags: {
      query: JOB_TAGS_QUERY,
      variables() {
        return this.$route.params
      },
      skip() {
        return !this.hasId
      }
    },
    jobNotes: {
      query: JOB_NOTES_QUERY,
      variables() {
        return this.$route.params
      },
      skip() {
        return !this.hasId
      }
    },
    jobPayments: {
      query: JOB_PAYMENTS_QUERY,
      variables() {
        return this.$route.params
      },
      skip() {
        return !this.hasId || this.$route.params.id === 'new'
      }
    }
  },
  computed: {
    materials_tax_percent() {
      return getTotalTaxPercent(
        'for_materials',
        this.job?.install_g_address?.zip
      )(this.jobTaxes)
    },
    labor_tax_percent() {
      return getTotalTaxPercent(
        'for_labor',
        this.job?.install_g_address?.zip
      )(this.jobTaxes)
    },
    jobTagsQuery() {
      return {
        query: JOB_TAGS_QUERY,
        variables: this.$route.params
      }
    }
  },
  methods: {
    toJobTax(row) {
      return {
        id: null,
        __typename: 'Jobtax',
        shop_tax_id: row.id,
        amount: 0,
        tax_co_account_id: null,
        coa_update_trigger: null,
        tax: row
      }
    },
    createJobTax(data) {
      const { cache } = this.$apollo.getClient()
      cache.writeFragment({
        fragment: JOBTAX_FRAGMENT,
        fragmentName: 'JobtaxFields',
        data
      })
    },
    async createDoc(file, id, attachToVehicle) {
      const doc = {
        document_name: file.filename,
        url: file.url,
        document_type: id
      }
      if (attachToVehicle) {
        doc.vehicle_document = {
          vehicle_id: this.job.vehicle_id
        }
      }
      await this.$apollo.mutate({
        mutation: ADD_JOB_DOCUMENTS_MUTATION,
        variables: {
          ...this.$route.params,
          documents: [doc]
        },
        update: (_, { data }) => {
          this.$apollo.getClient().cache.modify({
            fields: {
              jobDocs: (docs, { toReference }) => {
                return [...docs, toReference(data?.addJobDocs?.[0])]
              }
            }
          })
        }
      })
      this.$message({ type: 'success', message: 'Document added' })
    },
    async deleteJobPiece(piece, model, field) {
      try {
        await this.$messageBox.confirm(
          'This will permanently delete the item?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
        await this.$apollo.mutate({
          mutation: DELETE_JOB_PIECE_MUTATION,
          variables: {
            ...this.$route.params,
            type: model,
            model_id: piece.id
          }
        })
        const { cache } = this.$apollo.getClient()
        cache.evict(cache.identify(piece))
        cache.modify({
          fields: {
            [field]: (_, { canRead }) => _.filter(canRead)
          }
        })
      } catch {}
    },
    toggleJobTag(tag) {
      const { cache } = this.$apollo.getClient()
      cache.writeQuery({
        ...this.jobTagsQuery,
        data: {
          jobTags: R.ifElse(
            R.find(R.propEq('shop_tag_id', tag.id)),
            R.reject(R.propEq('shop_tag_id', tag.id)),
            R.append({
              __typename: 'Jobtag',
              shop_tag_id: tag.id,
              tag,
              id: null
            })
          )(this.jobTags)
        }
      })
    },
    async setJobTags() {
      await this.$apollo.mutate({
        mutation: SET_JOBTAGS_MUTATION,
        variables: {
          ...this.$route.params,
          tags: this.jobTags.map(
            R.evolve({ tag: R.assoc('shop_id', this.$job.job.shop_id || this.$job.shop.id) })
          )
        }
      })
      this.$message({ type: 'success', message: 'Tags updated' })
    },
    addJobDoc(doc) {
      const { cache } = this.$apollo.getClient()
      console.log(doc)
      console.log(
        R.pick(['id', 'created', 'document_type', 'document_name', 'url'])(doc)
      )
      cache.writeQuery({
        query: JOB_DOCS_QUERY,
        variables: this.$route.params,
        data: {
          jobDocs: [
            ...this.jobDocs,
            {
              __typename: 'Document',
              ...R.pick([
                'id',
                'created',
                'document_type',
                'document_name',
                'url'
              ])(doc)
            }
          ]
        }
      })
    },
    async refetchPayments() {
      await this.$apollo.queries.jobPayments.refetch()
    },
    createSetJobTax(data) {
      const { cache } = this.$apollo.getClient()
      cache.writeQuery({
        query: SHOP_PIECES_QUERIES.tax,
        variables: {
          shop_id: this.job.shop_id,
          disabled: true
        },
        data: {
          shopTaxes: R.sortBy(R.prop('name'))([...this.shopTaxes, data])
        }
      })
      cache.writeQuery({
        query: JOB_TAXES_QUERY,
        variables: this.$route.params,
        data: {
          jobTaxes: [...this.jobTaxes, this.toJobTax(data)]
        }
      })
    }
  },
  watch: {
    'job.shop_id'(value) {
      if (!this.job.id) {
        const shopData = this.$store.state.shops.find(
          i => i.id === value
        )
        if (shopData.default_install_context) {
          this.updateJob({ install_context: shopData.default_install_context })
        }
        if (shopData.default_invoice_tier) {
          this.updateJob({ selected_invoice_tier: shopData.default_invoice_tier })
        }
      }
    }
  }
}

export default JobPiecesMixin
