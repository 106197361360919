// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".saleside-job-dialog.el-dialog {\n  width: 100% !important;\n}\n@media only screen and (min-width: 600px) {\n.saleside-job-dialog.el-dialog {\n    width: 50% !important;\n}\n}\n.saleside-job-dialog .saleside-job-status-tag {\n  border-radius: 10px;\n  padding: 0px 10px;\n  margin-left: 10px;\n}\n.saleside-job-dialog .saleside-job-status-tag .saleside-job-status-tag-link {\n  color: white;\n  text-decoration: none;\n}", "",{"version":3,"sources":["/app/src/components/modals/SalesideJobDialog.vue"],"names":[],"mappings":"AA0VA;EACE,sBAAA;AA9UF;AA+UE;AAFF;IAGI,qBAAA;AA5UF;AACF;AA8UA;EACE,mBAAA;EACA,iBAAA;EACA,iBAAA;AA3UF;AA6UE;EACE,YAAA;EACA,qBAAA;AA3UJ","file":"SalesideJobDialog.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.saleside-job-dialog.el-dialog {\n  width: 100% !important;\n}\n@media only screen and (min-width: 600px) {\n  .saleside-job-dialog.el-dialog {\n    width: 50% !important;\n  }\n}\n\n.saleside-job-dialog .saleside-job-status-tag {\n  border-radius: 10px;\n  padding: 0px 10px;\n  margin-left: 10px;\n}\n.saleside-job-dialog .saleside-job-status-tag .saleside-job-status-tag-link {\n  color: white;\n  text-decoration: none;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
