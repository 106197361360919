var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "edit-form-template-dialog",
      attrs: {
        center: "",
        "append-to-body": "",
        "close-on-click-modal": false,
        title:
          _vm.template === null ? "Create a template" : "Update a template",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c(
                "el-tabs",
                { attrs: { "tab-position": "left" } },
                _vm._l(_vm.templateVariables, function(pane, index) {
                  return _c(
                    "el-tab-pane",
                    { key: index, attrs: { label: pane.label } },
                    _vm._l(pane.options, function(option, index) {
                      return _c("p", { key: index }, [
                        _vm._v(" " + _vm._s(option.label) + ": "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v(_vm._s(option.value))]
                        )
                      ])
                    }),
                    0
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 17 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "hide-required-asterisk": true,
                    "label-width": "140px",
                    size: "medium"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Name")]
                      ),
                      _c("el-input", {
                        staticClass: "template-name-input",
                        attrs: { placeholder: "Enter a name" },
                        on: {
                          focus: function($event) {
                            return _vm.focusTextarea(false)
                          }
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "template-body-container",
                      attrs: { prop: "body" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "undo-label-styles template-body-label",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [_vm._v("Template body")]
                      ),
                      _c("gb-form", {
                        key: _vm.forceUpdate,
                        ref: "templateTextarea",
                        staticClass: "template-body-input",
                        attrs: { schema: _vm.schema },
                        model: {
                          value: _vm.form,
                          callback: function($$v) {
                            _vm.form = $$v
                          },
                          expression: "form"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", plain: "", size: "mini" },
                            on: { click: _vm.addSignatureBlock }
                          },
                          [_vm._v(" Add Signature Block ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", plain: "", size: "mini" },
                            on: { click: _vm.addInitialBlock }
                          },
                          [_vm._v(" Add Initial Block ")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mb-2 mr-2",
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.handleSubmit }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.template === null ? "Create" : "Update"
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml-0",
                          attrs: { plain: "" },
                          on: { click: _vm.handleClose }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }