var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex t-flex-wrap" },
    [
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: { label: "Name", value: _vm.item.number }
      }),
      _c("div", { staticClass: "t-w-1/2 t-mb-3 t-pr-4" }, [
        _c("div", { staticClass: "t-font-medium t-text-gray-400 t-text-xs" }, [
          _vm._v("Color")
        ]),
        _c("div", {
          staticClass:
            "t-w-6 t-h-6 t-rounded-md t-shadow-sm t-border t-border-solid",
          style: "background-color: " + _vm.item.color
        })
      ]),
      _c("InfoPiece", {
        staticClass: "t-w-full",
        attrs: { label: "Description", value: _vm.item.description }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: {
          label: "Created by",
          value:
            _vm.item.createdByName + " on " + _vm.formatDate(_vm.item.createdAt)
        }
      }),
      _c("InfoPiece", {
        staticClass: "t-w-1/2",
        attrs: {
          label: "Updated by",
          value:
            _vm.item.updatedByName + " on " + _vm.formatDate(_vm.item.updatedAt)
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }