export const technicianColors = [
  '#7B1FA2',
  '#512DA8',
  '#303F9F',
  '#1976D2',
  '#0288D1',
  '#0097A7',
  '#00796B',
  '#388E3C',
  '#689F38',
  '#AFB42B',
  '#FBC02D',
  '#FFA000',
  '#F57C00',
  '#E64A19'
]

export const shedulerColors = {
  default: '#607D8B',
  unassigned: '#90A4AE',
  success: '#81C784',
  warning: '#FFD54F',
  danger: '#DC3545',
  secondaryText: '#838383'
}

export const warehouseColors = {
  mygrant: '#1E88E5',
  pilk: '#43A047',
  pgw: '#FB8C00',
  igc: '#103f78',
  service: '#fcba03',
  other: '#b103fc'
}
