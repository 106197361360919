import Vue from 'vue'
import { maskJs } from 'mask-js'

Vue.directive('phone-mask', {
  bind(element, { value }, vnode) {
    if (value) {
      console.log(element)
      element.addEventListener('input', v => {
        v.target.value = maskJs('(999) 999-9999', v.target.value)
      })
    }
    // const { direction, loadMore, target } = value
    // const el = target ? element.querySelector(target) : element
    // el.addEventListener('scroll', function() {
    //   const scrollEnd =
    //     direction === 'horizontal'
    //       ? el.scrollLeft + el.clientWidth >= el.scrollWidth
    //       : el.scrollTop + el.clientHeight >= el.scrollHeight
    //   if (scrollEnd) {
    //     loadMore()
    //   }
    // })
  }
})
