import { chatViews } from '@/constants/chat.config'

const state = {
  connected: false,
  isOpen: false,
  activeChannel: null,
  view: chatViews.welcome,
  channelIsOpenning: false,
  newMessage: null,
  refreshUserChannelsList: false,
  channelInfoDrawerIsOpen: false,
  membersDialogIsOpen: false,
  users: [],
  subscribedUsers: [],
  userChannels: [],
  unreadMessagesCount: {},
  userChannelsLoading: false,
  addUsersDialogIsOpen: false,
  members: [],
  totalMessages: 0,
  membersTyping: {},
  scrollBodyDown: false,
  imagePreviewUrl: null
}

export { state }
