var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            title: "Generate Pdf",
            id: "schedulerPdfDialog",
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg"
          },
          on: {
            show: _vm.dialogAboutToAppear,
            shown: _vm.dialogAppeared,
            hide: _vm.dialogAboutToDisappear
          }
        },
        [
          _c(
            "div",
            [
              _c("p", [_vm._v("Choose a PDF to generate:")]),
              _c("p", [
                _vm._v(
                  "* Generating Pdfs will take longer if you are generating for multiple jobs or if you are including a shop logo."
                )
              ]),
              _c("label", { attrs: { for: "pdfVersionSelect" } }, [
                _vm._v("Version ")
              ]),
              _vm.generating
                ? _c("div", { staticClass: "float-right" }, [
                    _c("img", {
                      attrs: { src: require("./../../assets/img/spinner.gif") }
                    })
                  ])
                : _vm._e(),
              _c(
                "span",
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "pdfVerisonSelect",
                      options: _vm.pdfVersionOptions,
                      size: "sm"
                    },
                    model: {
                      value: _vm.pdfVersion,
                      callback: function($$v) {
                        _vm.pdfVersion = $$v
                      },
                      expression: "pdfVersion"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "collatedCB",
                    value: "1",
                    "unchecked-value": "0"
                  },
                  model: {
                    value: _vm.collated,
                    callback: function($$v) {
                      _vm.collated = $$v
                    },
                    expression: "collated"
                  }
                },
                [_vm._v("Collated (1 1, 2 2, 3 3...)")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-12 mt-2" },
              [
                _c(
                  "b-form-group",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "b-form-radio-group",
                      {
                        attrs: { id: "" },
                        model: {
                          value: _vm.pdfVariant,
                          callback: function($$v) {
                            _vm.pdfVariant = $$v
                          },
                          expression: "pdfVariant"
                        }
                      },
                      [
                        _c("b-form-radio", { attrs: { value: "withPrices" } }, [
                          _vm._v("With Prices")
                        ]),
                        _c("b-form-radio", { attrs: { value: "noPrices" } }, [
                          _vm._v("Without Prices")
                        ]),
                        _c(
                          "b-form-radio",
                          { attrs: { value: "customerCopy" } },
                          [_vm._v("Copy For Customer")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", variant: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.generateClicked()
                      }
                    }
                  },
                  [_vm._v("Generate")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", variant: "primary" },
                    on: { click: _vm.cancelClicked }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "b-modal",
        {
          ref: _vm.id + "-spinner",
          attrs: {
            "hide-footer": "",
            "hide-header": "",
            size: "sm",
            "header-bg-variant": "primary"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "block",
                "margin-left": "auto",
                "margin-right": "auto",
                width: "20%"
              }
            },
            [
              _c("img", {
                attrs: { src: require("./../../assets/img/spinner.gif") }
              })
            ]
          ),
          _c(
            "div",
            { staticClass: "mx-auto", staticStyle: { width: "150px" } },
            [_vm._v(" Generating PDF... ")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }