<template>
  <div v-bind:style="groupWrapperStyleObject" v-bind:class="groupWrapperClassObject">
    <label v-show="title && title.length > 0" :for="_uid">{{title}}</label>
    <input
      ref='input'
      autocomplete='off'
      v-bind:style="groupInputStyleObject"
      :id="_uid"
      type='text'
      :disabled='inDisabled'
      v-bind:class='classes'
      :placeholder='placeholder'
      :title='title'
      @blur='inputActive = false'
      @focus='inputActive = true'
      v-model='formatted' />
      <div v-if="successMessage && successMessage.length > 0" class="valid-feedback">
        {{successMessage}}
      </div>
      <div v-if="internalFailMessage && internalFailMessage.length > 0" class="invalid-feedback">
        {{internalFailMessage}}
      </div>
  </div>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default {
  props: ['placeholder', 'title', 'value', 'minimumDecimalPlaces', 'failMessage', 'successMessage',
    'required', 'maximumDecimalPlaces', 'textAlign', 'inDisabled', 'inClass', 'formattedPrefix',
    'dataType', 'isGroupInput'],
  data: () => {
    return {
      showFailureMessage: false,
      inputActive: false
    }
  },
  methods: {
    updateShowFailureMessage: function (activateShowFailureMessage) {
      this.showFailureMessage = activateShowFailureMessage
    },
    isValid: function () {
      if (!this.value && this.value !== 0) {
        return (!this.required)
      } else {
        return this.value.toString().match(/^-?\d*\.?\d*$/) && (!this.required || this.value.toString().length > 0)
      }
    },
    focus: function () {
      this.$refs.input.focus()
    }
  },
  computed: {
    groupWrapperStyleObject: function () {
      var styles = {}
      if (this.isGroupInput) {
        styles = {
          'padding': '2px',
          'margin': '0px'
        }
        if (this.inDisabled) {
          styles['background-color'] = '#e9ecef'
        }
      }
      return styles
    },
    groupWrapperClassObject: function () {
      var classes = []
      if (this.isGroupInput) {
        classes = ['form-control', 'form-control-sm']
        if (!this.isValid() && !this.inDisabled) {
          classes.push('is-invalid')
        } else if (this.inputActive) {
          classes.push('is-valid')
        }
      }
      return classes
    },
    groupInputStyleObject: function () {
      var styles = {}
      if (this.isGroupInput) {
        styles = {
          'padding-left': '4px',
          'padding-right': '4px',
          'padding-top': '0px',
          'padding-bottom': '0px',
          'margin': '0px',
          'border': 'none',
          'font-family': 'Lato, sans-serif',
          'font-size': '14px',
          'line-height': '21px'
        }
      }
      if (this.textAlign && this.textAlign.length > 0) {
        styles['text-align'] = this.textAlign
      }
      return styles
    },
    internalFailMessage: function () {
      if (this.isValid() || !this.showFailureMessage) {
        return ''
      } else if (this.failMessage) {
        return this.failMessage
      } else {
        if (this.required && (!this.value || this.value.length === 0)) {
          return 'This field is required.'
        } else {
          return 'Please enter in a valid amount.'
        }
      }
    },
    classes: function () {
      var prop = ['form-control']
      if (!this.isGroupInput) {
        prop.push('form-control-sm')
      }

      if (!this.isValid()) {
        prop.push('is-invalid')
      } else if (this.inputActive) {
        prop.push('is-valid')
      }

      if (this.inClass && this.inClass.length > 0) {
        var classes = this.inClass.split(',')
        for (var i = 0; i < classes.length; i++) {
          if (classes[i] && classes[i].trim().length > 0) {
            prop.push(classes[i].trim())
          }
        }
      }
      return prop
    },
    formatted: {
      get: function () {
        // this is if the user is in the input field... just return the non formatted
        if (this.inputActive || !this.isValid()) {
          if (!this.value || parseFloat(this.value) === 0) {
            return ''
          } else {
            return this.value.toString()
          }
        } else { // this is if the user is not in the input field and it should be formatted
          var formattedValue = this.formatFloatDecimals(this.value, this.minimumDecimalPlaces, this.maximumDecimalPlaces)
          if (this.formattedPrefix && this.formattedPrefix.length > 0) {
            formattedValue = this.formattedPrefix + formattedValue
          }
          return formattedValue
        }
      },
      set: function (changedVal) {
        if (this.dataType === 'int' && !isNaN(Math.round(changedVal))) {
          this.$emit('update:value', Math.round(changedVal))
        } else {
          this.$emit('update:value', changedVal)
        }
      }
    }
  },
  watch: {
    'inputActive': function (val) {
      if (!val) {
        if (this.isValid()) {
          var formattedValue = this.formatFloatDecimals(this.value, this.minimumDecimalPlaces, this.maximumDecimalPlaces)
          if (isNaN(parseFloat(formattedValue)) || parseFloat(formattedValue) === 0) {
            this.$emit('update:value', 0.00)
          } else {
            this.$emit('update:value', parseFloat(formattedValue))
          }
        }
      }
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style>

</style>
