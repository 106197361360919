<template>
  <svg width="25" height="27" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter
        x="-39.5%"
        y="-32.6%"
        width="178.9%"
        height="160.9%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.562581949 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      filter="url(#a)"
      transform="translate(2 2)"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M.509 7.875H18.49c.28 0 .509.221.509.492v10.664C19 20.118 18.088 21 16.964 21H2.036C.912 21 0 20.118 0 19.031V8.367c0-.27.229-.492.509-.492zM19 6.07V4.594c0-1.087-.912-1.969-2.036-1.969H14.93V.492a.502.502 0 00-.51-.492h-1.696a.502.502 0 00-.509.492v2.133H6.786V.492A.502.502 0 006.276 0H4.58a.502.502 0 00-.509.492v2.133H2.036C.912 2.625 0 3.507 0 4.594V6.07c0 .271.229.492.509.492H18.49c.28 0 .509-.221.509-.492z"
        :fill="fillColor || '#FF9393'"
        fill-rule="nonzero"
        stroke="#3B240F"
        stroke-linecap="round"
      />
      <text
        fill="#FFF"
        font-family="Poppins-Regular, Poppins"
        font-size="12"
        text-anchor="middle"
        alignment-baseline="middle"
      >
        <tspan x="10" y="18">{{ text }}</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['fillColor', 'text'],
  computed: {}
}
</script>
