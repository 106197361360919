var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-rows" },
    _vm._l(_vm.schema, function(row, idx) {
      return _c(
        "el-row",
        { key: idx, attrs: { gutter: _vm.nogutter ? 0 : 12 } },
        _vm._l(row, function(col) {
          return _c(
            "el-col",
            { key: col.id, attrs: { span: col.span || 24 / row.length } },
            [
              col.slot
                ? _vm._t(col.slot)
                : _c(
                    _vm.getComponent(col),
                    _vm._g(
                      _vm._b(
                        {
                          tag: "component",
                          class: {
                            inputWithLabel:
                              col.componentType === "inputWithLabel"
                          },
                          attrs: {
                            value: _vm.getValue(col),
                            loading: _vm.loading,
                            hideLabel: _vm.hideLabels,
                            size: _vm.size
                          }
                        },
                        "component",
                        col,
                        false
                      ),
                      col.componentType === "inputWithLabel"
                        ? {
                            change: function(e) {
                              return _vm.$emit("change", e, col)
                            },
                            input: function(e) {
                              return _vm.$emit("input", e, col)
                            }
                          }
                        : {
                            edit: function(e) {
                              return _vm.$emit("edit", e, col)
                            }
                          }
                    )
                  )
            ],
            2
          )
        }),
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }