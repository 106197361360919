import Vue from 'vue'

Vue.directive('custom-infinite-scroll', {
  bind(element, { value }, vnode) {
    const { direction, loadMore, target } = value
    const el = target ? element.querySelector(target) : element
    el.addEventListener('scroll', function() {
      const scrollEnd =
        direction === 'horizontal'
          ? el.scrollLeft + el.clientWidth >= el.scrollWidth
          : el.scrollTop + el.clientHeight >= el.scrollHeight
      if (scrollEnd) {
        loadMore()
      }
    })
  }
})
