var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", { attrs: { data: _vm.data } }, [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("Payment Created")
    ]),
    _c("div", [
      _vm._v(
        " A " +
          _vm._s(_vm.data.details.transactionType) +
          " payment of $" +
          _vm._s(_vm.data.details.paymentAmount) +
          " was applied to Job "
      ),
      _c("a", { attrs: { target: "_blank", href: _vm.jobLink } }, [
        _vm._v(_vm._s(this.data.details.jobId))
      ]),
      _vm._v(" for " + _vm._s(_vm.data.details.shopName) + ". ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }