var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "28",
        height: "28",
        viewBox: "0 0 28 28"
      }
    },
    [
      _c("defs", [
        _c("circle", {
          attrs: { id: "my-location-a", cx: "330", cy: "366", r: "8" }
        })
      ]),
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            "fill-rule": "evenodd",
            transform: "translate(-316 -352)"
          }
        },
        [
          _c("use", {
            attrs: { fill: _vm.color, "xlink:href": "#my-location-a" }
          }),
          _c("circle", {
            attrs: {
              cx: "330",
              cy: "366",
              r: "11",
              stroke: _vm.color,
              "stroke-opacity": "0.3",
              "stroke-width": "6"
            }
          }),
          _c("circle", {
            attrs: {
              cx: "330",
              cy: "366",
              r: "7",
              stroke: "#FFF",
              "stroke-linejoin": "square",
              "stroke-width": "2"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }