var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vc-alpha" }, [
    _c(
      "div",
      { staticClass: "vc-alpha-checkboard-wrap" },
      [_c("checkboard")],
      1
    ),
    _c("div", {
      staticClass: "vc-alpha-gradient",
      style: { background: _vm.gradientColor }
    }),
    _c(
      "div",
      {
        ref: "container",
        staticClass: "vc-alpha-container",
        on: {
          mousedown: _vm.handleMouseDown,
          touchmove: _vm.handleChange,
          touchstart: _vm.handleChange
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "vc-alpha-pointer",
            style: { left: _vm.colors.a * 100 + "%" }
          },
          [_c("div", { staticClass: "vc-alpha-picker" })]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }