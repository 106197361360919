<template>
  <div class="loading-screen">
    <img :src="logo" />
  </div>
</template>

<script>
import logo from '@/assets/img/gb-logo-new-short.svg'

export default {
  data() {
    return {
      logo
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes gb-loading-logo {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.loading-screen {
  img {
    width: 200px;
    animation: gb-loading-logo 1.5s infinite ease-in-out;
    transform-origin: 70% 50%;
    margin-left: -140px;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.73);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="css" scoped>
</style>
