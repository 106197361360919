import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=356ff330&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=356ff330&lang=scss&scoped=true&"
import style1 from "./Index.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356ff330",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('356ff330')) {
      api.createRecord('356ff330', component.options)
    } else {
      api.reload('356ff330', component.options)
    }
    module.hot.accept("./Index.vue?vue&type=template&id=356ff330&scoped=true&", function () {
      api.rerender('356ff330', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Support/TicketDetail/Index.vue"
export default component.exports