<template>
  <div>
    <b-alert
      :class="variant"
      :show="dismissCountDown"
      dismissible
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged">
      <p><strong>{{title}}: </strong>{{message}}</p>
    </b-alert>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: '',
      message: '',
      variant: 'success',
      dismissCountDown: 0
    }
  },
  beforeDestroy: function () {
    this.$root.$off('showGlobalNotification', this.showGlobalNotificationListener)
  },
  created () {
    this.$root.$on('showGlobalNotification', this.showGlobalNotificationListener)
  },
  methods: {
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showGlobalNotificationListener (title, message, variant = 'success', time = 4) {
      this.title = title
      this.message = message
      this.dismissCountDown = time
      this.variant = variant.toLowerCase()
    }
  }
}
</script>

<style scoped>
  .success {
    position: fixed !important;
    top: 1vh !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: auto;
    color: white !important;
    background-color: rgba(50, 205, 50, 0.75) !important;
    text-align: center;
    z-index: 10000 !important;
  }
  .warning {
    position: fixed !important;
    top: 1vh !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: auto;
    color: black !important;
    background-color: rgba(251, 255, 0, 0.75) !important;
    text-align: center;
    z-index: 10000 !important;
  }
  .danger {
    position: fixed !important;
    top: 1vh !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: auto;
    color: white !important;
    background-color: rgba(224, 24, 24, 0.75) !important;
    text-align: center;
    z-index: 10000 !important;
  }
  .info {
    position: fixed !important;
    top: 1vh !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: auto;
    color: white !important;
    background-color: rgba(0, 13, 255, 0.75) !important;
    text-align: center;
    z-index: 10000 !important;
  }
</style>
