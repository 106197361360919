var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex t-justify-center t-items-center t-mb-2" },
    [
      _c("div", {
        staticClass:
          "t-border-0 t-border-t t-border-solid t-border-gray-300\n           t-flex-grow"
      }),
      _c(
        "div",
        {
          staticClass:
            "t-border t-border-solid t-border-gray-300 t-px-3 t-py-1\n           t-rounded-full t-shadow-sm t-text-gray-700"
        },
        [_vm._v(" " + _vm._s(_vm.date) + " ")]
      ),
      _c("div", {
        staticClass:
          "t-border-0 t-border-t t-border-solid t-border-gray-300\n           t-flex-grow"
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }