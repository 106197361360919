import { actions, mutations } from './types'
import { ConfirmationsAPI } from '@/services/ConfirmationsAPI'
import { isMonday, format } from 'date-fns'

const getDisplayString = date => {
  let delta = parseInt((date.getTime() - new Date().getTime()) / 1000)

  if (delta <= 0) {
    return 0
  }

  let display

  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  if (days > 0) {
    display = format(date, 'M/D/YYYY h:mma')
  } else {
    const hours = Math.floor(delta / 3600) % 24
    delta -= hours * 3600

    const minutes = Math.floor(delta / 60) % 60
    delta -= minutes * 60

    display = `${hours ? hours + 'h' : ''} ${minutes ? minutes + 'm' : ''}`.trim()
  }

  return display
}

export default {
  [actions.setShopsSettings]: async ({ commit }, shops) => {
    const settings = await ConfirmationsAPI.post('/shop/get', { shops })
    commit(mutations.setShopsSettings, settings)
  },

  [actions.setJobsConfirmationDateTime]: async (_, update) => {
    const { response } = await ConfirmationsAPI.post('/job/setConfirmationDateTime', update)
    return response
  },

  [actions.syncShopsTimers]: async ({ commit, state }, date) => {
    let shopsTimers = []
    const expiredTimers = []

    state.shopsSettings.forEach((settings) => {
      const { name, same_day, send_sunday, shop_id, time } = settings

      const sendTime = new Date(time)

      const nextSend = new Date(date)
      nextSend.setHours(sendTime.getHours())
      nextSend.setMinutes(sendTime.getMinutes())
      nextSend.setSeconds(0)

      if (!same_day) {
        // send the day before install (subtract one day)
        let daysToSubtract = 1

        if (!send_sunday && isMonday(date)) {
          // send for Monday on Saturday (subtract one more day)
          daysToSubtract++
        }

        nextSend.setDate(nextSend.getDate() - daysToSubtract)
      }

      const seconds = parseInt((nextSend.getTime() - new Date().getTime()) / 1000)

      if (seconds > 0) {
        const display = getDisplayString(nextSend)

        shopsTimers.push({
          display,
          name,
          nextSend,
          seconds,
          shop_id
        })
      } else {
        expiredTimers.push({
          display: 'Sent',
          name,
          nextSend,
          seconds,
          shop_id
        })
      }
    })

    shopsTimers = shopsTimers.toSorted((a, b) => a.seconds - b.seconds)
    shopsTimers.push(...expiredTimers)

    commit(mutations.setShopsTimers, shopsTimers)
  },

  [actions.syncJobPageTimer]: async ({ commit, state }, job) => {
    const jobPageTimer = { ...state.jobPageTimer }
    const { jobId, confirmationDateTime } = job

    jobPageTimer[jobId] = getDisplayString(new Date(confirmationDateTime))

    commit(mutations.setJobPageTimer, jobPageTimer)
  },
  [actions.syncSchedulerJobsTimers]: async ({ commit, state }, jobs) => {
    const jobsTimers = { ...state.jobsTimers }
    jobs.forEach((job) => {
      const { jobId, confirmationDateTime } = job

      jobsTimers[jobId] = getDisplayString(new Date(confirmationDateTime))
    })

    commit(mutations.setSchedulerJobsTimers, jobsTimers)
  }
}
