// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".text-messages-dialog {\n  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n}\n.text-messages-dialog .el-dialog__body {\n  padding: 0 !important;\n  border-top: 1px solid #ebebeb;\n}\n.text-messages-block {\n  height: 700px;\n  overflow: auto;\n}", "",{"version":3,"sources":["/app/<no source>","Index.vue","/app/src/components/Twilio/TextMessages/Index.vue"],"names":[],"mappings":"AAAA;EAAA,4NAAA;ACaA;ACqIE;EACE,qBAAA;EACA,6BAAA;AAnIJ;AAuIA;EACE,aAAA;EACA,cAAA;AApIF","file":"Index.vue","sourcesContent":[null,":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.text-messages-dialog {\n  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n}\n.text-messages-dialog .el-dialog__body {\n  padding: 0 !important;\n  border-top: 1px solid #ebebeb;\n}\n\n.text-messages-block {\n  height: 700px;\n  overflow: auto;\n}",":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.text-messages-dialog {\n  @apply t-font-sans;\n}\n.text-messages-dialog .el-dialog__body {\n  padding: 0 !important;\n  border-top: 1px solid #ebebeb;\n}\n\n.text-messages-block {\n  height: 700px;\n  overflow: auto;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
