import * as R from 'ramda'
import {
  getTechList,
  filterJobsByDate,
  filterItemsByDate,
  getTechWaypointList,
  getBayList
} from '@/scripts/helpers/scheduler_v2.helpers'
import { scheduleJobFilterMap } from '@/scripts/helpers/scheduler.helpers'
import { formatToDb } from '@/scripts/helpers/date.helpers'
import { getWeekDates } from '@/scripts/helpers/calendar.helpers'
import {
  getRouteStates,
  getUnmappedLocations
} from '@/scripts/helpers/geometry.helpers'
import { propNot } from '@/scripts/helpers/ramda.helpers'
import { addJobMidTimes } from '@/scripts/helpers/job.helpers'

const dataListByKey = key => R.pipe(R.path(['data', key]), R.values)

const getters = {
  jobList: dataListByKey('jobs'),
  waypointList: dataListByKey('waypoints'),
  appointmentList: dataListByKey('appointments'),
  newRequestFilters: R.pipe(R.prop('filters'), R.pick(['shops'])),
  newMapRequestFilters: R.pipe(R.prop('filters'), R.pick(['shops', 'date'])),
  leftListFilterTitle: R.path(['activeJobListFilter', 'value']),
  filteredLeftJobList: (state, getters) =>
    scheduleJobFilterMap[state.activeJobListFilter.value](getters.jobList),
  jobFilterCount: (state, getters) => filter =>
    scheduleJobFilterMap[filter](getters.jobList).length,
  isListView: R.pathEq(['filters', 'view'], 'list'),
  defaultUserStore: (state, getters, rootState) =>
    R.path(['user', 'shop_id'])(rootState),
  cardView: R.path(['filters', 'cardView']),
  isDayView: R.pathEq(['filters', 'cardView'], 3),
  isBayView: R.pathEq(['filters', 'cardView'], 4),
  isRowView: R.path(['filters', 'rowView']),
  isSolidCardView: R.path(['filters', 'solidCards']),
  dateFilter: state => formatToDb(state.filters.date),
  dateFilteredJobs: (state, getters) => date =>
    filterJobsByDate(date || state.filters.date)(getters.jobList),
  filteredJobs: (state, getters) => date =>
    R.pipe(
      R.filter(
        R.when(
          R.always(state.filters.context !== 'all'),
          R.propEq('install_context', state.filters.context)
        )
      ),
      filterJobsByDate(date || state.filters.date),
      addJobMidTimes
    )(getters.jobList),
  dateFilteredWaypoints: (state, getters) => date =>
    filterItemsByDate(date || state.filters.date)(getters.waypointList),
  dateFilteredAppointments: (state, getters) => date =>
    filterItemsByDate(date || state.filters.date)(getters.appointmentList),
  techList: (state, getters) => date => getTechList(date)(state, getters),
  bayList: (state, getters) => date => getBayList(date)(state, getters),
  techWaypointList: id => state => getTechWaypointList(id)(state),
  combineTechs: (state, getters, rootState, rootGetters) =>
    rootGetters.getUserCombineTechsValue,
  weekDates: (state, getters) => offset =>
    getWeekDates(getters.dateFilter, offset),
  weekOffset: R.path(['filters', 'weekOffset']),
  routeStates: state => getRouteStates(state.geometry),
  visibleGeometry: state =>
    state.geometry.filter(R.allPass([propNot('hidden'), propNot('error')])),
  filteredWarehouses: state =>
    R.pipe(
      R.prop('warehouses'),
      R.filter(({ type }) => R.path(['filters', 'warehouses', type], state))
    )(state),
  warehouseFilter: R.path(['filters', 'warehouses']),
  unmappedLocations: state => getUnmappedLocations(state)
}

export default getters
