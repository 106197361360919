var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            lazy: "",
            title: "Results",
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg",
            id: "quickNavResultsModal"
          },
          on: { show: _vm.dialogAboutToAppear }
        },
        [
          _c(
            "div",
            { attrs: { fluid: "", id: "quickNavResultsBody" } },
            [
              _c(
                "b-card",
                {
                  ref: "quickNavWrapper",
                  attrs: { id: "navResultsCard" },
                  on: {
                    "&scroll": function($event) {
                      return _vm.onTableScroll($event)
                    },
                    scroll: _vm.onScroll
                  }
                },
                [
                  _c("b-table", {
                    attrs: {
                      striped: "",
                      hover: "",
                      items: _vm.localResults,
                      fields: _vm.jobsByVinFields,
                      stacked: "md"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "id",
                          fn: function(row) {
                            return [
                              row.item.jobType === "saleside"
                                ? _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openSalesidejobDialog(
                                            row.item.id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(row.item.id))]
                                  )
                                : _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.navigateToJob(row.item.id)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(row.item.id))]
                                  )
                            ]
                          }
                        },
                        {
                          key: "contact_name",
                          fn: function(row) {
                            return [
                              _c("p", [_vm._v(_vm._s(row.item.contact_name))])
                            ]
                          }
                        },
                        {
                          key: "vehicle",
                          fn: function(row) {
                            return _vm.filter === "VEHICLE"
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.vehicleFromFields(row.item)) +
                                      " "
                                  )
                                ]
                              : undefined
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("p", { staticStyle: { color: "black" } }, [
                        _vm._v(" Results: " + _vm._s(_vm.results) + " "),
                        _vm.isFetching
                          ? _c(
                              "span",
                              [
                                _vm._v(" "),
                                _c("v-icon", {
                                  attrs: { spin: "", name: "spinner" }
                                }),
                                _vm._v(" loading more")
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.noMore
                          ? _c("span", [_vm._v(" All Results Loaded")])
                          : _vm._e()
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "primary", size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.closeModal()
                            }
                          }
                        },
                        [_vm._v(" Ok ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }