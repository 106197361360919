var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          ref: "support-dialog",
          attrs: {
            visible: _vm.visible,
            title: "Support Center",
            width: "900px",
            "destroy-on-close": "",
            "custom-class": "gb-el-dialog support-dialog",
            top: "24px",
            "append-to-body": "",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("tabs", {
            attrs: { filePickerKey: _vm.filePickerKey, tab: _vm.tab },
            on: {
              "update:tab": function($event) {
                _vm.tab = $event
              }
            }
          })
        ],
        1
      ),
      _c("ticket-detail")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }