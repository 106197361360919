<template>
  <el-table
    :data="rows"
    class="drafts-table"
    v-loading="loading"
    @selection-change="handleSelectionChange"
    >
    <el-table-column
      v-if="tab === 'draft'"
      type="selection">
    </el-table-column>
    <el-table-column
      prop="id"
      label="Id"
      width="100">
      <template slot-scope="scope">
        <a :href="'/jobs/' + scope.row.id" target="_blank" style="text-decoration: none;">{{ scope.row.id }}</a>
      </template>
    </el-table-column>
    <el-table-column
      prop="status"
      label="Status"
      width="100">
      <template slot-scope="scope">
        <span>{{ getReadableStatus(scope.row.status) }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="consumer"
      label="Customer"
      width="200">
      <template slot-scope="scope">
        <span>{{ getConsumerName(scope.row.consumer) }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="vehicle"
      label="Vehicle">
      <template slot-scope="scope">
        <span>{{ getVehicleString(scope.row.vehicle) }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="referral_number"
      label="Referral #"
      width="100">
      <template slot-scope="scope">
        <span>{{ scope.row.referral_number }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="purchase_order_number"
      label="PO #"
      width="100">
      <template slot-scope="scope">
        <span>{{ scope.row.purchase_order_number }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="Use"
      width="100"
      v-if="match.key === 'consumer_id' && tab === 'draft'">
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="openJobDraft(scope.row.status, scope.row.id)"
          type="primary">Use</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
export default {
  props: {
    tableData: {
      type: Array,
      default: () => ([])
    },
    multipleSelection: {
      type: Array,
      default: () => ([])
    },
    match: {
      type: Object,
      default: () => ({})
    },
    tab: {
      type: String
    }
  },
  data: () => ({
    loading: false,
    done: false
  }),
  mounted () {
    this.handleScroll()
    document.getElementsByClassName('drafts-table')[0].addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy: function () {
    try {
      document.getElementsByClassName('drafts-table')[0].removeEventListener('scroll', this.handleScroll)
    } catch (error) {}
  },
  methods: {
    async handleScroll () {
      if (!this.loading && !this.done) {
        var distanceToBottom = (document.getElementsByClassName('drafts-table')[0].scrollHeight -
          (document.getElementsByClassName('drafts-table')[0].scrollTop + document.getElementsByClassName('drafts-table')[0].offsetHeight))
        if (distanceToBottom < 150) {
          this.loading = true
          const { data } = await this.$unum.job().get(`/data/match/${this.match.key}`, {
            params: {
              value: this.match.value,
              offset: this.rows.length,
              tab: this.tab
            }
          })
          this.rows = [...this.rows, ...data.rows]
          if (data.rows.length < 25) {
            this.done = true
          }
          this.loading = false
        }
      }
    },
    getConsumerName (consumer) {
      if (!consumer) return ''
      if (consumer?.is_commercial === 1) {
        return consumer.company_name ? consumer.company_name : consumer.full_name
      } else if (consumer.full_name) {
        return consumer.full_name
      } else if (consumer.name) {
        return consumer.name.last ? consumer.name.first + ' ' + consumer.name.last : consumer.name.first
      } else {
        return 'Guest'
      }
    },
    getVehicleString (vehicle) {
      return !vehicle ? '' : vehicle.generated_name
    },
    getReadableStatus (status) {
      const statusConfig = {
        draft: 'Draft',
        quote: 'Quote',
        workOrder: 'Work Order',
        invoice: 'Invoice'
      }
      return statusConfig[status]
    },
    openJobDraft (status, id) {
      let toRoute = status === 'draft' ? `/jobs/new/${id}` : `/jobs/${id}`
      this.changeRouteWithCheck(toRoute)
      this.localVisible = false
    },
    handleSelectionChange(val) {
      this.selectedRows = val;
    }
  },
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    selectedRows: {
      get () {
        return this.multipleSelection
      },
      set (val) {
        this.$emit('update:multipleSelection', val)
      }
    },
    rows: {
      get () {
        return this.tableData
      },
      set (val) {
        this.$emit('update:tableData', val)
      }
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped lang="scss">
  .drafts-table {
    overflow:auto;
    height: 500px;
    width: 100%;
    &::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(117, 117, 117);
    }
  }
</style>