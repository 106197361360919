var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fake-job-data" }, [
    _c("div", { staticClass: "fake-job-header" }, [
      _c("h6", [_vm._v("Create jobs with fake data")]),
      _vm.loading ? _c("i", { staticClass: "el-icon-loading" }) : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "fake-job-form" },
      [
        _c(
          "el-select",
          {
            attrs: { size: "small", placeholder: "Shop ID (default if empty)" },
            model: {
              value: _vm.shop_id,
              callback: function($$v) {
                _vm.shop_id = $$v
              },
              expression: "shop_id"
            }
          },
          _vm._l(_vm.shops, function(shop) {
            return _c("el-option", {
              key: shop.id,
              attrs: { value: shop.id, label: shop.name }
            })
          }),
          1
        ),
        _c("el-input-number", {
          attrs: { size: "small", min: 1 },
          model: {
            value: _vm.count,
            callback: function($$v) {
              _vm.count = $$v
            },
            expression: "count"
          }
        }),
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.generate } },
          [_vm._v("Create")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }