var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", { attrs: { data: _vm.data } }, [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("SMS Signature Received")
    ]),
    _c("div", [
      _vm._v(" " + _vm._s(_vm.shopName) + ": the customer for job "),
      _c("a", { attrs: { target: "_blank", href: _vm.jobLink } }, [
        _vm._v(_vm._s(_vm.data.details.jobId))
      ]),
      _vm._v(" has signed their invoice. ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }