import {getDocumentOptions} from '@/scripts/helpers/document.helpers'

// only thing to be adjusted when adding new types
export const documentTypes = {
  PURCHASE_ORDER: 1,
  RECEIVE: 2,
  TECH_PICK_UP: 3,
  RETURN: 4,
  SUPPLIER: 5,
  ITEM: 6,
  ADJUSTMENT: 7,
  TRANSFERS: 8
}

export const documentsConfig =
  {
    job: {
      label: 'Job Documents',
      type: 'job',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('job')
    },
    vendor: {
      label: 'Vendor Documents',
      type: 'vendor',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('vendor')
    },
    gb_vendor: {
      label: 'Vendor Documents',
      type: 'gb_vendor',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('gb_vendor')
    },
    item: {
      label: 'Item Documents',
      type: 'item',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('item')
    },
    [documentTypes.PURCHASE_ORDER]: {
      label: 'Purchase Order Documents',
      type: 'purchase_orders',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('purchase_orders')
    },
    bill: {
      label: 'Bill Documents',
      type: 'bill',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('bill')
    },
    bill_payment: {
      label: 'Bill Payment Documents',
      type: 'bill_payment',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('bill_payment')
    },
    expense: {
      label: 'Expense Documents',
      type: 'expense',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('expense')
    },
    journal_entry: {
      label: 'Journal Entry Documents',
      type: 'journal_entry',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('journal_entry')
    },
    [documentTypes.RECEIVE]: {
      label: 'Receive Documents',
      type: 'receives',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('receive')
    },
    [documentTypes.TECH_PICK_UP]: {
      label: 'Tech Pickup Documents',
      type: 'tech_pick_ups',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('tech_pickup')
    },
    [documentTypes.RETURN]: {
      label: 'Return Documents',
      type: 'returns',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('vendor_return')
    },
    [documentTypes.ADJUSTMENT]: {
      label: 'Inventory Adjustment Documents',
      type: 'adjustments',
      shopId: null,
      id: null,
      docOptions: getDocumentOptions('unit_adjustments')
    }
    // [documentTypes.TRANSFERS]: {
    //   label: 'Inventory Transfer Documents',
    //   type: 'transfers',
    //   shopId: null,
    //   id: null,
    //   docOptions: getDocumentOptions('unit_adjustments')
    // }
  }
