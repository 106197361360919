import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Pdf extends ObjectTracker {

  static unixDateConverter(timestamp) {
    let start = (timestamp !== 'now') ? new Date(timestamp * 1000) : new Date()
    let year = start.getFullYear()
    let month = (start.getMonth() + 1)
    let date = start.getDate()
    if (month.toString().length < 2) {
      month = '0' + month.toString()
    }
    if (date.toString().length < 2) {
      date = '0' + date.toString()
    }
    let finalDate = month + '/' + date + '/' + year
    return finalDate
  }

  static handleTechsideEvent(event, jobId, tsign, callbackFunction, signatureData = null) {
    let reqObj = {'event': event, 'tsign': tsign, 'signature': signatureData}
    let url = `/api/pdf/tsign/job/${jobId}`
    Api.post(url, reqObj).then(result => {
      callbackFunction(result)
    }, function (error) {
      console.log('TechSIDE event error: ', error)
      callbackFunction({'data': {'error': 'There was an error completing this job'}})
    })
  }

  static redesignSmsSignatureCapture(jobId, tsign, token, source, callbackFunction, signatureData = null) {
    let reqObj = {'tsign': tsign, 'signature': signatureData, 'token': token}
    let url = `/api/mobile/pdf/tsign/job/${jobId}/${source}`
    Api.post(url, reqObj).then(result => {
      callbackFunction(result)
    }, function (error) {
      console.log('TechSIDE event error: ', error)
      callbackFunction({'data': {'status': 'error', 'error': 'There was an error completing this job'}})
    })
  }
  static legacySmsSignatureCapture(jobId, token, callbackFunction, signatureData = null) {
    let reqObj = {'tsign': tsign, 'signature': signatureData, 'token': token}
    let url = `/api/mobile/pdf/tsign/job/${jobId}`
    Api.post(url, reqObj).then(result => {
      callbackFunction(result)
    }, function (error) {
      console.log('TechSIDE event error: ', error)
      callbackFunction({'data': {'status': 'error', 'error': 'There was an error completing this job'}})
    })
  }

  static textSignatureRequest(jobId, toNum, text) {
    let reqObj = {'toNumber':toNum, 'text':text}
    Api.post(`/api/pdf/sms/job/${jobId}`, reqObj)
  }

  static sendSmsPdfLink(smsObj, callbackFunction, errorCallBackFunction) {
    Api.post(`/api/pdf/sendSmsPdfLink`, smsObj).then(result => {
      callbackFunction(result)
    }, function (error) {
      console.log('PDF Link Sms Error: ', error)
      errorCallBackFunction(error)
    })
  }

  static getShopDefault(shopId, callbackFunction) {
    Api({url: `/api/pdf/shop/${shopId}/default`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Default Check Error:', error)
    })
  }

  static renderPdf (shopId, jobId, userId, username, variant, callbackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/shop/${shopId}/job/${jobId}/user/${userId}/name/${username}/variant/${variant}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Render Error:', error)
    })
  }

  static renderPdfFromGbForm (body, signatureLine) {
    return new Promise ((resolve, reject) => {
      Api.post('/api/pdf/gbform/generate', { body, signatureLine }, {responseType: 'arraybuffer'}).then(res => {
        resolve(res)
      }, function (error) {
        console.log('PDF Render Error:', error)
        reject('PDF Render Error:', error)
      })
    })
  }

  static renderPdfDoc (body) {
    return new Promise ((resolve, reject) => {
      Api.post('/api/pdf/pdfdoc/generate', { body }, {responseType: 'arraybuffer'}).then(res => {
        resolve(res)
      }, function (error) {
        console.log('PDF Render Error:', error)
        reject('PDF Render Error:' + error)
      })
    })
  }

  static renderPaymentReceiptPdf (body) {
    return new Promise ((resolve, reject) => {
      Api.post('/api/pdf/pdfpmt/generate', { body }, {responseType: 'arraybuffer'}).then(res => {
        resolve(res)
      }, function (error) {
        console.log('PDF Render Error:', error)
        reject('PDF Render Error:' + error)
      })
    })
  }

  static downloadExternalPdf (jobId, callbackFunction) {
    Api.post(`/api/mobile/pdf/job/${jobId}/user/-1/name/customer/variant/customerCopy`).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('Mobile PDF Render Error:', error)
    })
  }

  // shopId, userId, username, variant, jobs_string, callback
  static renderSchedulerPdfs (shopId, userId, username, variant, jobsString, collated, callbackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/scheduler/shop/${shopId}/user/${userId}/name/${username}/variant/${variant}/collated/${collated}/jobs/${jobsString}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Error:', error)
    })
  }

  // shopId, userId, username, variant, jobs_string, callback
  static renderPurchaseOrderPdfs (shopId, poId, userId, username, callbackFunction, errorCallBackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/shop/${shopId}/po/${poId}/user/${userId}/name/${username}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Error:', error)
      if (errorCallBackFunction) {
        errorCallBackFunction(error)
      }
    })
  }

  // shopId, userId, username, variant, jobs_string, callback
  static renderBillPdfs (shopId, billId, userId, username, callbackFunction, errorCallBackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/shop/${shopId}/bill/${billId}/user/${userId}/name/${username}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Error:', error)
      if (errorCallBackFunction) {
        errorCallBackFunction(error)
      }
    })
  }

  static renderBillPaymentPdfs (shopId, billPaymentId, userId, username, callbackFunction, errorCallBackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/shop/${shopId}/bill_payment/${billPaymentId}/user/${userId}/name/${username}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Error:', error)
      if (errorCallBackFunction) {
        errorCallBackFunction(error)
      }
    })
  }

  static renderJournalEntryPdfs (shopId, journalEntryId, userId, username, callbackFunction, errorCallBackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/shop/${shopId}/journal_entry/${journalEntryId}/user/${userId}/name/${username}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Error:', error)
      if (errorCallBackFunction) {
        errorCallBackFunction(error)
      }
    })
  }

  // shopId, userId, username, variant, jobs_string, callback
  static renderExpensePdfs (shopId, expenseId, userId, username, callbackFunction, errorCallBackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/shop/${shopId}/expense/${expenseId}/user/${userId}/name/${username}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Error:', error)
      if (errorCallBackFunction) {
        errorCallBackFunction(error)
      }
    })
  }  

  // shopId, userId, username, variant, jobs_string, callback
  static renderReceivePdfs (shopId, receiveId, userId, username, callbackFunction, errorCallBackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/shop/${shopId}/receive/${receiveId}/user/${userId}/name/${username}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Error:', error)
      if (errorCallBackFunction) {
        errorCallBackFunction(error)
      }
    })
  }

  // shopId, userId, username, variant, jobs_string, callback
  static renderTechPickupPdfs (shopId, techPickupId, userId, username, callbackFunction, errorCallBackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/shop/${shopId}/tpu/${techPickupId}/user/${userId}/name/${username}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Error:', error)
      if (errorCallBackFunction) {
        errorCallBackFunction(error)
      }
    })
  }

  // shopId, userId, username, variant, jobs_string, callback
  static renderVendorReturnPdfs (shopId, vendorReturnId, userId, username, callbackFunction, errorCallBackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/shop/${shopId}/vr/${vendorReturnId}/user/${userId}/name/${username}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Error:', error)
      if (errorCallBackFunction) {
        errorCallBackFunction(error)
      }
    })
  }

  // shopId, userId, username, variant, jobs_string, callback
  static renderAdjustmentPdfs (shopId, adjustmentId, userId, username, callbackFunction, errorCallBackFunction) {
    Api({responseType: 'arraybuffer', url: `/api/pdf/shop/${shopId}/adjustment/${adjustmentId}/user/${userId}/name/${username}`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      console.log('PDF Error:', error)
      if (errorCallBackFunction) {
        errorCallBackFunction(error)
      }
    })
  }
}
