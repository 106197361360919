var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticClass: "tech-select",
      attrs: {
        value: _vm.value,
        multiple: "",
        placeholder: "Select technicians",
        filterable: "",
        "value-key": "id"
      },
      on: {
        change: function($event) {
          return _vm.$emit("input", $event)
        }
      }
    },
    _vm._l(_vm.techsWithDefaultParams, function(item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.name, value: item }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }