<template>
  <el-dialog
    width="600px"
    append-to-body
    :visible="visible"
    :before-close="close"
    custom-class="shopping-cart-dialog">
    <span slot="title" class="t-text-xl t-text-gray-800">Checkout</span>
    <div class="t-px-10 t-pb-8">
      <div>
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-shopping-cart-full t-mr-3"></i>
            <span class="t-font-normal">Items</span>
          </span>
        </el-divider>
        <div
          v-for="item in items"
          :key="item.id"
          class="t-flex t-rounded-md t-shadow t-px-3 t-py-2 t-mb-3 t-bg-gray-100"
        >
          <div class="t-flex-grow">
            <div class="t-font-medium t-text-gray-800 t-text-base">
              {{ item.industryCode }}
            </div>
            <div>{{ item.plantName }}</div>
          </div>
          <div class="t-flex t-text-base">
            <div>{{ item.quantity }}</div>
            <div class="t-pl-3 t-pr-2">x</div>
            <div class="t-font-medium t-text-gray-700">${{ item.unitPrice }}</div>
          </div>
        </div>
        <div
          class="t-flex t-items-end t-justify-end t-mt-4 t-mb-5 t-mr-3">
          <span class="t-pr-4 t-text-base">Total:</span>
          <span class="t-text-lg t-font-medium t-text-gray-700">
            ${{ orderTotal }}
          </span>
        </div>
      </div>
      <div class="t-pb-2">
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-coordinate t-mr-3"></i>
            <span class="t-font-normal">Shipping address</span>
          </span>
        </el-divider>
        <div v-if="fetchingAddresses" class="t-text-2xl t-text-center">
          <i class="el-icon-loading"/>
        </div>
        <div v-else>
          <div v-for="(address, index) in addresses" :key="address.accountNumber">
            <el-radio
              v-model="addressIndex"
              :label="index"
              :disabled="fetchingDeliveryMethods"
              class="t-normal-case t-px-3 t-py-2 t-mb-3 t-font-normal t-flex
                     t-items-center t-rounded-md t-shadow t-bg-gray-100"
            >
              <div class="t-mb-1 t-text-gray-800">{{ address.name }}</div>
              <div class="t-text-gray-600">{{ formatAddress(address) }}</div>
            </el-radio>
          </div>
        </div>
      </div>
      <div class="t-pb-2">
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-truck t-mr-3"></i>
            <span class="t-font-normal">Delivery method</span>
          </span>
        </el-divider>
        <div v-if="!form.addressCode" class="t-text-center">
          Select shipping address first
        </div>
        <div v-else>
          <div v-if="fetchingDeliveryMethods" class="t-text-2xl t-text-center">
            <i class="el-icon-loading"/>
          </div>
          <div v-else>
            <div v-if="!deliveryMethods.length" class="t-text-center">
              No delivery methods available
            </div>
            <div v-else>
              <div v-for="(deliveryMethod, index) in deliveryMethods" :key="index">
                <el-radio
                  v-model="deliveryMethodIndex"
                  :label="index"
                  class="t-normal-case t-px-3 t-py-2 t-mb-3 t-font-normal t-flex
                        t-items-center t-rounded-md t-shadow t-bg-gray-100"
                >
                  <div class="t-mb-1">
                    <span class="t-text-gray-600">Method:</span>
                    <span class="t-ml-2 t-text-gray-800">
                      <span v-if="deliveryMethod.selfCollection">Will Call</span>
                      <span v-else>Delivery</span>
                    </span>
                  </div>
                  <div class="t-mb-1">
                    <span class="t-text-gray-600">Description:</span>
                    <span class="t-ml-2 t-text-gray-800">
                      {{ deliveryMethod.shippingConditionDescription }}
                    </span>
                  </div>
                  <div class="t-mb-1">
                    <span class="t-text-gray-600">Date:</span>
                    <span class="t-ml-2 t-text-gray-800">
                      <span>{{ formatDeliveryDate(deliveryMethod.deliveryDttm) }}</span>
                      <span v-if="deliveryMethod.showTimeOnDelivery">
                        {{ formatDeliveryTime(deliveryMethod.deliveryDttm) }}
                      </span>
                    </span>
                  </div>
                  <div v-if="parseFloat(deliveryMethod.deliveryCharge) > 0" class="t-mb-1">
                    <span class="t-text-gray-600">Delivery charge:</span>
                    <span class="t-ml-2 t-text-gray-800">
                      ${{ deliveryMethod.deliveryCharge }}
                    </span>
                  </div>
                  <div v-if="parseFloat(deliveryMethod.vanCharge) > 0">
                    <span class="t-text-gray-600">Van charge:</span>
                    <span class="t-ml-2 t-text-gray-800">
                      ${{ deliveryMethod.vanCharge }}
                    </span>
                  </div>
                </el-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <el-divider content-position="left" class="t-mb-8">
          <span class="t-text-lg t-text-blue-700">
            <i class="el-icon-chat-line-square t-mr-3"></i>
            <span class="t-font-normal">Comments</span>
          </span>
        </el-divider>
        <el-input
          type="textarea"
          :rows="3"
          placeholder="Enter your comments"
          v-model="form.comment"
        />
      </div>
    </div>
    <div class="t-px-10 t-py-5 t-text-right t-bg-gray-100 t-shadow">
      <el-button
        @click="submit"
        type="primary"
        size="medium"
        class="t-bg-blue-500 hover:t-bg-blue-400"
        v-loading.fullscreen.lock="submitting"
        :disabled="!form.addressCode || !form.deliveryCode">
        Place order
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import moment from 'moment'
import {
  shoppingCartActions,
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'

export default {
  data: () => ({
    addresses: [],
    addressIndex: null,
    fetchingAddresses: false,
    deliveryMethods: [],
    fetchingDeliveryMethods: false,
    deliveryMethodIndex: null,
    submitting: false,
    form: {
      addressCode: null,
      address: null,
      deliveryCode: null,
      deliveryDescription: null,
      selfCollection: null,
      deliveryDateTime: null,
      deliveryCharge: null,
      vanCharge: null,
      comment: ''
    }
  }),
  computed: {
    visible () {
      return this.$store.state.shoppingCart.items.pilkingtonCheckoutIsOpen
    },
    shopId () {
      return this.$store.state.shoppingCart.shopId
    },
    allItems () {
      return this.$store.state.shoppingCart.items.data
    },
    selectedItemIds () {
      return this.$store.state.shoppingCart.items.selectedItemIds
    },
    items () {
      return this.allItems.filter(i => this.selectedItemIds.includes(i.id))
    },
    orderTotal () {
      let total = 0
      this.items.forEach(i => {
        total += parseFloat(i.unitPrice) * i.quantity
      })
      return Math.round(total * 100) / 100
    },
    selectedTab: {
      get () {
        return this.$store.state.shoppingCart.selectedTab
      },
      set (value) {
        return this.$store.commit(shoppingCartMutations.setSelectedTab, value)
      }
    }
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.getShipToAddresses()
      } else {
        this.addressIndex = null
        this.deliveryMethodIndex = null
        this.form.addressCode = null
        this.form.address = null
        this.form.deliveryCode = null
        this.form.deliveryDescription = null
        this.form.selfCollection = null
        this.form.deliveryDateTime = null
        this.form.deliveryCharge = null
        this.form.vanCharge = null
        this.form.comment = ''
      }
    },
    'form.addressCode': function (value) {
      if (value) {
        this.getDeliveryMethods()
      }
    },
    addressIndex: function (value) {
      if (value !== null) {
        this.form.addressCode = this.addresses[value].accountNumber
        this.form.address = this.formatAddress(this.addresses[value])
      }
    },
    deliveryMethodIndex: function (value) {
      if (value !== null) {
        this.form.deliveryCode = this.deliveryMethods[value].shippingConditionCode
        this.form.deliveryDescription = this.deliveryMethods[value].shippingConditionDescription
        this.form.selfCollection = this.deliveryMethods[value].selfCollection
        this.form.deliveryDateTime = this.deliveryMethods[value].deliveryDttm
        this.form.deliveryCharge = this.deliveryMethods[value].deliveryCharge
        this.form.vanCharge = this.deliveryMethods[value].vanCharge
      }
    }
  },
  methods: {
    close () {
      if (!this.submitting) {
        return this.$store.dispatch(shoppingCartActions.closePilkingtonCheckout)
      }
    },
    placeOrder (payload) {
      return this.$store.dispatch(shoppingCartActions.placeOrder, payload)
    },
    getItemsCount () {
      return this.$store.dispatch(shoppingCartActions.getItemsCount)
    },
    async getShipToAddresses () {
      try {
        this.fetchingAddresses = true
        this.addresses = await this.$store.dispatch(shoppingCartActions.getPilkingtonShipToAddresses, {
          shopId: this.shopId,
          username: this.items[0].accountUsername
        })
        this.fetchingAddresses = false
      } catch (error) {
        if (error?.response?.data?.error) {
          this.$message.error(error.response?.data?.error)
        } else {
          this.$message.error('Can\'t get ship to addresses. Check Pilkington credentials and try again.')
        }
      }
    },
    async getDeliveryMethods () {
      try {
        this.fetchingDeliveryMethods = true
        this.deliveryMethodIndex = null
        this.form.deliveryCode = null
        this.form.deliveryDateTime = null
        this.deliveryMethods = await this.$store.dispatch(shoppingCartActions.getPilkingtonDeliveryMethods, {
          shopId: this.shopId,
          username: this.items[0].accountUsername,
          plants: [{
            plantCode: this.items[0].plantCode,
            materialNumber: this.items[0].materialNumber,
            industryCode: this.items[0].industryCode,
            addressCode: this.form.addressCode
          }]
        })
        this.fetchingDeliveryMethods = false
      } catch {
        this.$message.error('Can\'t get delivery methods. Check Pilkington credentials and try again.')
      }
    },
    formatAddress (address) {
      let fullAddress = address.street1
      fullAddress += address.street2 || ''
      fullAddress += `, ${address.city}, ${address.state} ${address.zip}`
      return fullAddress
    },
    formatDeliveryDate (timestamp) {
      return moment(timestamp).format('LL')
    },
    formatDeliveryTime (timestamp) {
      return moment(timestamp).format('LT')
    },
    async submit () {
      if (!this.form.addressCode) {
        this.$message.warning('Please select a shipping address')
      } else if (!this.form.deliveryCode || !this.form.deliveryDateTime) {
        this.$message.warning('Please select a delivery method')
      } else {
        try {
          this.submitting = true
          await this.placeOrder({
            shopId: this.shopId,
            username: this.items[0].accountUsername,
            vendor: 'pilkington',
            warehouseCode: this.items[0].plantCode,
            cartItemIds: this.selectedItemIds,
            ...this.form
          })
          this.submitting = false
          this.selectedTab = 'orders'
          this.$store.commit(shoppingCartMutations.orders.setSelectedVendor, 'pilkington')
          this.close()
          this.$message.info('Your order is processing...')
          this.$root.$emit('purchase-order::updated')
          this.getItemsCount()
        } catch (err) {
          this.$notify({
            title: 'Oops',
            message: err.response.data.message,
            type: 'error',
            showClose: true,
            duration: 10000,
            customClass: 't-break-all'
          })
          this.submitting = false
        }
      }
    }
  }
}
</script>
