var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "Assignment Detail (" + _vm.workAssignment.dispatch_number + ")",
        "append-to-body": "",
        id: "glaxisAssignmentDetail",
        width: "600px",
        "destroy-on-close": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.dialogAboutToAppear
      }
    },
    [
      _vm.loading
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-md-12",
                staticStyle: { "font-size": "16px", "text-align": "center" }
              },
              [_c("p", [_vm._v("Loading please wait...")])]
            )
          ])
        : _c("div", [
            _c("div", { staticClass: "col-md-12 font-size-14" }, [
              _c("div", [
                _c("b", [_vm._v("Referral number:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.dispatch_number))
              ]),
              _c("div", [
                _c("b", [_vm._v("Date of loss:")]),
                _vm._v(
                  " " + _vm._s(_vm.formatDate(_vm.workAssignment.date_of_loss))
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Dispatch create date:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.formatDate(_vm.workAssignment.dispatch_create_date)
                    )
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Dispatch create time:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.dispatch_create_time))
              ]),
              _c("div", [
                _c("b", [_vm._v("Vehicle:")]),
                _vm._v(" " + _vm._s(_vm.formatVehicle))
              ]),
              _c("div", [
                _c("b", [_vm._v("Vehicle ID:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.vehicle_id))
              ]),
              _c("div", [
                _c("b", [_vm._v("Vehicle ID source:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.vehicle_id_source))
              ]),
              _c("div", [
                _c("b", [_vm._v("Vehicle ID number:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.vehicle_id_number))
              ]),
              _c("div", [
                _c("b", [_vm._v("Recreational vehicle:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.recreational_vehicle))
              ]),
              _c("div", [
                _c("b", [_vm._v("Customer name:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.capitalize(
                        _vm.workAssignment.insured_or_claimant_first_name
                      )
                    ) +
                    " " +
                    _vm._s(
                      _vm.capitalize(
                        _vm.workAssignment.insured_or_claimant_last_name
                      )
                    ) +
                    " "
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Customer address:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.capitalize(
                        _vm.workAssignment.insured_or_claimant_address
                      )
                    ) +
                    ", " +
                    _vm._s(
                      _vm.capitalize(
                        _vm.workAssignment.insured_or_claimant_city
                      )
                    ) +
                    ", " +
                    _vm._s(
                      _vm.workAssignment.insured_or_claimant_state_province
                    ) +
                    " " +
                    _vm._s(_vm.workAssignment.insured_or_claimant_postal_code) +
                    " "
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Phone:")]),
                _vm._v(
                  " " + _vm._s(_vm.workAssignment.insured_or_claimant_telephone)
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Alternate phone:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.workAssignment.insured_or_claimant_alternate_telephone
                    )
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Deductible:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.deductible))
              ]),
              _c("div", [
                _c("b", [_vm._v("Claim type:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.claim_type_description))
              ]),
              _c("div", [
                _c("b", [_vm._v("Requested appointment date:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.formatDate(
                        _vm.workAssignment.requested_appointment_date
                      )
                    )
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Requested appointment time:")]),
                _vm._v(
                  " " +
                    _vm._s(_vm.workAssignment.begin_requested_time) +
                    " - " +
                    _vm._s(_vm.workAssignment.end_requested_time) +
                    " "
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Loss reference number:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.loss_reference_number))
              ]),
              _c("div", [
                _c("b", [_vm._v("Glass vendor name:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.glass_vendor_name))
              ]),
              _c("div", [
                _c("b", [_vm._v("Glass vendor address:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.capitalize(_vm.workAssignment.glass_vendor_address)
                    ) +
                    ", " +
                    _vm._s(
                      _vm.capitalize(_vm.workAssignment.glass_vendor_city)
                    ) +
                    ", " +
                    _vm._s(_vm.workAssignment.glass_vendor_state_province) +
                    " " +
                    _vm._s(_vm.workAssignment.glass_vendor_postal_code) +
                    " "
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Glass vendor phone:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.glass_vendor_telephone))
              ]),
              _c("div", [
                _c("b", [_vm._v("Glass vendor fax:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.glass_vendor_fax))
              ]),
              _c("div", [
                _c("b", [_vm._v("Max repairs:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.max_repairs))
              ]),
              _c("div", [
                _c("b", [_vm._v("ESchedule indicator:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.e_schedule_indicator))
              ]),
              _c("div", [
                _c("b", [_vm._v("Work location postal code:")]),
                _vm._v(
                  " " + _vm._s(_vm.workAssignment.work_location_postal_code)
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Mobile location description:")]),
                _vm._v(
                  " " + _vm._s(_vm.workAssignment.mobile_location_description)
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Mobile indicator:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.mobile_indicator))
              ]),
              _c("div", [
                _c("b", [_vm._v("Insurer ID:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.insurer_id))
              ]),
              _c("div", [
                _c("b", [_vm._v("Insurance claim indicator:")]),
                _vm._v(
                  " " + _vm._s(_vm.workAssignment.insurance_claim_indicator)
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Insurer name:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.insurer_name))
              ]),
              _c("div", [
                _c("b", [_vm._v("Origination company name:")]),
                _vm._v(
                  " " + _vm._s(_vm.workAssignment.origination_company_name)
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Origination address:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.capitalize(_vm.workAssignment.origination_address)
                    ) +
                    ", " +
                    _vm._s(
                      _vm.capitalize(_vm.workAssignment.origination_city)
                    ) +
                    ", " +
                    _vm._s(_vm.workAssignment.origination_state_province) +
                    " " +
                    _vm._s(_vm.workAssignment.origination_postal_code) +
                    " "
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Origination phone:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.origination_telephone))
              ]),
              _c("div", [
                _c("b", [_vm._v("Origination fax:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.origination_fax))
              ]),
              _c("div", [
                _c("b", [_vm._v("Origination email:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.origination_email))
              ]),
              _c("div", [
                _c("b", [_vm._v("Origination URL:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.origination_url))
              ]),
              _c("div", [
                _c("b", [_vm._v("Agent first name:")]),
                _vm._v(
                  " " +
                    _vm._s(_vm.capitalize(_vm.workAssignment.agent_first_name))
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Agent last name:")]),
                _vm._v(
                  " " +
                    _vm._s(_vm.capitalize(_vm.workAssignment.agent_last_name))
                )
              ]),
              _c("div", [
                _c("b", [_vm._v("Agent phone:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.agent_telephone))
              ]),
              _c("div", [
                _c("b", [_vm._v("Fixed bid amount:")]),
                _vm._v(" " + _vm._s(_vm.workAssignment.fixed_bid_amount))
              ]),
              _c("div", [
                _c("b", [_vm._v("Cause of loss description:")]),
                _vm._v(
                  " " + _vm._s(_vm.workAssignment.cause_of_loss_description)
                )
              ])
            ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }