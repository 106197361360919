import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class ShopInventory extends ObjectTracker {
  id = -1
  shop_id = -1
  part_number = ''
  avg_cost = 0
  quantity = 0
  zone = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getShopPartInventory (shopId, partno, callbackFunction) {
    if (partno && partno.length > 0) {
      Api({ url: `/api/shop/${shopId}/shopInventory/${partno}` }).then(res => {
        callbackFunction(new ShopInventory(res.data))
      })
    } else {
      callbackFunction(new ShopInventory())
    }
  }

  static getShopInventory (shopId, callbackFunction, errorCallback) {
    Api({url: `/api/shop/${shopId}/shopInventory`}).then(res => {
      callbackFunction(res)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }
  async createUpdateShopInventory (shopId, callbackFunction = null, errorCallbackFunction = null) {
    return Api.post(`/api/shop/${shopId}/shopInventory/${this.part_number}`, this)
  }
}
