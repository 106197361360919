import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class Jobtag extends ObjectTracker {
  id = -1
  created = -1
  shop_tag_id = -1
  job_id = -1
  modified = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static createTemporaryJobTag (id, shopTagId, jobId) {
    var jobtag = new Jobtag()

    jobtag.id = id
    jobtag.shop_tag_id = shopTagId
    jobtag.job_id = jobId

    return jobtag
  }

  static getAll (shopId, jobId, callbackFunction, errorCallbackFunction = null) {
    
    Api({ url: `/api/shop/${shopId}/job/${jobId}/jobtag` }).then(res => {
      
      var objects = []
      res.data.forEach(function (val) {
        objects.push(new Jobtag(val))
      })
      
      callbackFunction(objects)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getJobtagsWithTags (tags, jobtags) {
    var jobtagsWithTags = []

    for (var i = 0; i < jobtags.length; i++) {
      var jobtag = new Jobtag(jobtags[i])
      for (var j = 0; j < tags.length; j++) {
        var tag = tags[j]
        if (tag.id === jobtag.shop_tag_id) {
          jobtag.backgroundColor = tag.color
          jobtag.text = tag.text
          jobtagsWithTags.push(jobtag)
          break
        }
      }
    }
    return jobtagsWithTags
  }

  static updateJobtags (shopId, jobId, jobtags, callbackFunction, errorCallbackFunction = null) {
    
    
    return Api.post(`/api/shop/${shopId}/job/${jobId}/jobtag`, jobtags).then(res => {
      
      
      var returnedTags = []
      for (var i = 0; i < res.data.length; i++) {
        returnedTags.push(new Jobtag(res.data[i]))
      }
      callbackFunction(returnedTags)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  deleteJobtag (shopId, successCallbackFunction, errorCallbackFunction = null) {
    
    
    if (!this.id || this.id <= 0) {
      successCallbackFunction()
    }
    return Api.delete(`/api/shop/${shopId}/job/${this.job_id}/jobtag/${this.id}`, this).then(res => {
      
      
      successCallbackFunction()
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
