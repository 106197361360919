<template>
  <el-dialog
    append-to-body
    width="1000px"
    :visible="visible"
    :before-close="handleClose"
    custom-class="chat-dialog">
    <span slot="title" class="t-text-blue-100 t-text-lg">
      <i class="el-icon-chat-line-round"></i>
      <span class="t-ml-3">Chat</span>
    </span>
    <div class="t-flex t-h-full">
      <Sidebar/>
      <MainView/>
    </div>
  </el-dialog>
</template>

<script>
import MainView from './Views/Index'
import Sidebar from './Sidebar/Sidebar'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  computed: {
    visible: function() {
      return this.$store.state.chat.isOpen
    },
    view: function() {
      return this.$store.state.chat.view
    }
  },
  methods: {
    handleClose: function() {
      this.$store.dispatch(chatTypes.namespace + chatTypes.actions.close)
    }
  },
  components: {
    MainView,
    Sidebar
  }
}
</script>

<style lang="scss">
.chat-dialog {
  @apply t-font-sans;
  .el-dialog__header {
    @apply t-bg-blue-500 t-pl-6;
    button {
      i {
        @apply t-text-blue-100;
      }
      i:hover {
        @apply t-text-blue-200;
      }
    }
  }
  .el-dialog__body {
    padding: 0 !important;
    height: 800px;
  }
}
</style>
