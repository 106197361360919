var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fetching,
          expression: "fetching"
        }
      ],
      attrs: { stripe: "", data: _vm.leads, "row-class-name": _vm.hasNotes },
      on: { "expand-change": _vm.expandChange }
    },
    [
      _c("el-table-column", {
        attrs: { type: "expand" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { lg: 3, sm: 24 } },
                      [
                        _c("el-checkbox", {
                          attrs: { border: "", label: "Call Back?" },
                          on: {
                            change: function($event) {
                              return _vm.updateCallBack(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.call_back,
                            callback: function($$v) {
                              _vm.$set(scope.row, "call_back", $$v)
                            },
                            expression: "scope.row.call_back"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 21, sm: 24 } },
                      [
                        _c("span", { staticClass: "t-mr-3 t-font-bold" }, [
                          _vm._v("Notes:")
                        ]),
                        _c("el-input", {
                          attrs: { type: "textarea" },
                          on: {
                            blur: function($event) {
                              return _vm.updateLeadNote(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.notes,
                            callback: function($$v) {
                              _vm.$set(scope.row, "notes", $$v)
                            },
                            expression: "scope.row.notes"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Name" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(scope.row.first_name) +
                    " " +
                    _vm._s(scope.row.last_name) +
                    " "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Phone number" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(" " + _vm._s(_vm.formatPhone(scope.row.phone)) + " ")
              ]
            }
          }
        ])
      }),
      _c("el-table-column", { attrs: { prop: "email", label: "Email" } }),
      _c("el-table-column", { attrs: { prop: "company", label: "Company" } }),
      _c("el-table-column", {
        attrs: { prop: "existing_account", label: "Existing Account" }
      }),
      _c("el-table-column", {
        attrs: { label: "Demo Date" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_vm._v(" " + _vm._s(scope.row.demo_date) + " ")]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Demo Time" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_vm._v(" " + _vm._s(scope.row.demo_time) + " ")]
            }
          }
        ])
      }),
      _c("el-table-column", { attrs: { prop: "referral", label: "Referral" } }),
      _c("el-table-column", {
        attrs: { prop: "add_to_networks", label: "Add to networks" }
      }),
      _c("el-table-column", {
        attrs: { fixed: "right", label: "Actions", width: "200px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                !scope.row.archived
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Archive",
                          placement: "top",
                          "open-delay": 300
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            loading: _vm.archiveSubmitting,
                            size: "mini",
                            type: "warning",
                            icon: "el-icon-takeaway-box"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleArchive(scope.row.id)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Unarchive",
                          placement: "top",
                          "open-delay": 300
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            loading: _vm.unarchiveSubmitting,
                            plain: "",
                            size: "mini",
                            type: "warning",
                            icon: "el-icon-takeaway-box"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleUnarchive(scope.row.id)
                            }
                          }
                        })
                      ],
                      1
                    )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }