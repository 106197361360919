var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title: "Job Documents",
        id: "documentModal",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg"
      },
      on: { show: _vm.dialogAboutToAppear }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "form-group col-lg-2" },
          [
            _c("FilePicker", {
              attrs: {
                buttonLabel: "Choose File",
                filePickerKey: _vm.filePickerKey,
                dialogToReturnTo: "documentModal",
                handleFilePickerResponse: _vm.handleFilePickerResponse
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "form-group col-lg-4 mt-2" }, [
          _vm.document.document_name != null &&
          _vm.document.document_name.length > 0
            ? _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.deleteTempDocument()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { color: "red" },
                    attrs: { icon: _vm.faTimesCircle }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("label", [
            _vm._v(
              _vm._s(
                _vm.document.document_name != null &&
                  _vm.document.document_name.length > 0
                  ? _vm.document.document_name
                  : "No file chosen"
              ) + " "
            )
          ])
        ]),
        _c("div", { staticClass: "form-group col-lg-3 text-right" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.document.document_type,
                  expression: "document.document_type"
                }
              ],
              staticClass: "form-control form-control-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.document,
                    "document_type",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "" } }, [_vm._v("Category...")]),
              _c("option", { attrs: { value: "swo" } }, [
                _vm._v("Signed Work Order")
              ]),
              _c("option", { attrs: { value: "dis" } }, [_vm._v("Dispatch")]),
              _c("option", { attrs: { value: "pop" } }, [
                _vm._v("Proof of purchase")
              ]),
              _c("option", { attrs: { value: "fiv" } }, [
                _vm._v("Field invoice")
              ]),
              _c("option", { attrs: { value: "quo" } }, [_vm._v("Quote")]),
              _c("option", { attrs: { value: "dl" } }, [
                _vm._v("Damage Locator")
              ]),
              _c("option", { attrs: { value: "pic" } }, [_vm._v("Picture")]),
              _c("option", { attrs: { value: "app" } }, [_vm._v("Approval")]),
              _c("option", { attrs: { value: "auth" } }, [
                _vm._v("Authorization")
              ]),
              _c("option", { attrs: { value: "recal" } }, [
                _vm._v("Recalibration")
              ]),
              _c("option", { attrs: { value: "payment" } }, [
                _vm._v("Payment")
              ]),
              _c("option", { attrs: { value: "oth" } }, [_vm._v("Other")])
            ]
          ),
          _c("br")
        ]),
        _c("div", { staticClass: "form-group col-lg-3 text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              staticStyle: { width: "100%" },
              attrs: { type: "button", variant: "primary" },
              on: { click: _vm.createNewDocument }
            },
            [_vm._v("Attach")]
          )
        ])
      ]),
      _c("hr", {
        staticStyle: { "margin-top": "20px", "margin-bottom": "20px" }
      }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.SWOColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" SWO ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "swo"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.DISColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" DIS ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "dis"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.PoPColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" PoP ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "pop"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.FIVColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" FIV ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "fiv"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.QUOColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" QUO ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "quo"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.DLColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" DL ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "dl"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.SSMPicColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" SSMPic ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "ssmpic"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.TSMPicColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" TSMPic ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "tsmpic"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.SSignColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" SSign ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "ssign"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.TSignColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" TSign ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "tsign"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.picColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" PIC ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "pic"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.appColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" APP ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "app"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.authColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" AUTH ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "auth"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.recalColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" RECAL ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "recal"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.ESignColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" ESign ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "esign"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.SmsSignColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" SmsSign ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "smssign"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.PaymentColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" Payment ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "payment"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.OTHColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" OTH ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "oth"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-2" }, [
          _c(
            "a",
            {
              staticClass: "tag tag-light",
              style: { "background-color": _vm.TSMCNPColor, color: "white" }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.faFile } }),
              _vm._v(" TSMCNP ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-10" },
          _vm._l(_vm.documents, function(document, index) {
            return document.document_type == "tsmcnp"
              ? _c(
                  "span",
                  { key: index },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { color: "red", cursor: "pointer" },
                      attrs: { icon: _vm.faMinusCircle },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument(index, document)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(document.document_name) + " ")
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        )
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", variant: "primary" },
                on: { click: _vm.cancelEdits }
              },
              [_vm._v("Close")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }