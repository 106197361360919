var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        title: "Terms and Conditions",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg",
        id: "termsAndConditionsModal",
        "ok-only": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": ""
      },
      on: { ok: _vm.ok }
    },
    [
      _vm.termsAndConditions && _vm.termsAndConditions != ""
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.termsAndConditions) } })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }