var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-autocomplete", {
    ref: "ref",
    staticClass: "gb-email-input",
    attrs: {
      value: _vm.value,
      "fetch-suggestions": _vm.filter,
      "select-when-unmatched": "",
      "highlight-first-item": "",
      placeholder: _vm.placeholder ? _vm.placeholder : ""
    },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event)
      },
      change: function($event) {
        return _vm.$emit("change", $event)
      },
      blur: function($event) {
        return _vm.$emit("blur", $event)
      },
      focus: function($event) {
        return _vm.$emit("focus", $event)
      },
      select: function($event) {
        return _vm.$emit("change", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }