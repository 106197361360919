import * as R from 'ramda'
import {
  checkJobBoolByProp,
  isJobUnscheduled,
  isJobInPast,
  isJobUnassigned,
  belongsToTech,
  scheduledOnDate
} from './job.helpers'
import { technicianColors, shedulerColors } from '@/constants/sheduler.configs'
import objectDiff from 'object-diff'
import tinycolor from 'tinycolor2'
import { formatToDb, isNotOlderThan, timestampToMilliseconds } from './date.helpers'

export const isJobPartsOnBackorder = checkJobBoolByProp('parts_on_backorder')
export const isJobPendingInspection = checkJobBoolByProp('pending_inspection')
export const isJobPendingSchedule = checkJobBoolByProp('pending_schedule')
export const isJobRescheduled = checkJobBoolByProp('customer_reschedule')
export const isJobCancelled = checkJobBoolByProp('customer_cancel')

export const isTechsideSent = R.propEq('techside_sent', 'sent')
export const isWorkOrder = R.propEq('status', 'workOrder')

export const isTechsideJobStatusCompleted = R.pipe(
  R.prop('techside_job_status'),
  R.or(
    R.pipe(
      R.equals('archived'),
      R.not
    ),
    R.isNil
  )
)

export const isJobNotOlderThan90Days = R.pipe(
  R.prop('created'),
  timestampToMilliseconds,
  isNotOlderThan(90)
)

export const isJobCompleted = R.allPass([
  isTechsideSent,
  isJobNotOlderThan90Days,
  isWorkOrder
])

export const filterCompleted = R.filter(isJobCompleted)
export const filterUnscheduled = R.filter(isJobUnscheduled)
export const filterPastSheduled = R.filter(isJobInPast)
export const filterUnassigned = R.filter(isJobUnassigned)
export const filterPartsOnBackorder = R.filter(isJobPartsOnBackorder)
export const filterPendingInspection = R.filter(isJobPendingInspection)
export const filterPendingSchedule = R.filter(isJobPendingSchedule)
export const filterRescheduled = R.filter(isJobRescheduled)
export const filterCancelled = R.filter(isJobCancelled)

export const scheduleJobFilterMap = {
  completed: filterCompleted,
  unscheduled: filterUnscheduled,
  past: filterPastSheduled,
  unassigned: filterUnassigned,
  backorder: filterPartsOnBackorder,
  inspection: filterPendingInspection,
  pending: filterPendingSchedule,
  reschedule: filterRescheduled,
  cancel: filterCancelled
}

export const sortJobs = R.pipe(
  R.sortBy(R.prop('id')),
  R.sortBy(R.prop('scheduled_time_end')),
  R.sortBy(R.prop('scheduled_time_start')),
  R.sortBy(R.prop('scheduler_order')),
  arr => {
    const a = []
    const b = []
    arr.forEach(item => {
      if (!R.isNil(item.scheduler_order)) {
        a.push(item)
      } else {
        b.push(item)
      }
    })
    return R.concat(a, b)
  }
)

export const sortJobsBay = R.pipe(
  R.sortBy(R.prop('id')),
  R.sortBy(R.prop('scheduled_time_end')),
  R.sortBy(R.prop('scheduled_time_start')),
  R.sortBy(R.prop('bay_order')),
  arr => {
    const a = []
    const b = []
    arr.forEach(item => {
      if (!R.isNil(item.bay_order)) {
        a.push(item)
      } else {
        b.push(item)
      }
    })
    return R.concat(a, b)
  }
)

export const filterTechJobs = techId =>
  R.pipe(
    R.filter(belongsToTech(techId)),
    sortJobs
  )
export const filterJobsByDate = date => R.filter(scheduledOnDate(date))

export const setTechnicianColors = techs =>
  techs.map((tech, index) => ({
    ...tech,
    color: technicianColors[index]
  }))

export const getUpdatedJobsRequest = (updated, old) => {
  const data = new FormData()
  const updatedJobs = R.difference(updated, old)
  if (updatedJobs.length) {
    const changes = updatedJobs.reduce((lambdas, job) => {
      const { id } = job
      const newJob = {
        ...job,
        tech_id: job.tech_id === '0' ? '-1' : job.tech_id
      }
      const oldJob = old.find(R.propEq('id', id))
      return oldJob ? R.assoc(id, objectDiff(oldJob, newJob))(lambdas) : lambdas
    }, {})
    data.set('changes', JSON.stringify(changes))
    return data
  }
}

export const getReadableColor = color => {
  return tinycolor.isReadable('#FFF', color) ? color : tinycolor(color).darken(30)
}

export const getCardTextColor = color => (tinycolor.readability('#FFF', color) > 2 ? '#FFF' : '#000')

export const getBackgroundColor = color => tinycolor(color).setAlpha(0.2)

export const getFormData = object => {
  const data = new FormData()
  R.forEachObjIndexed((value, key) => {
    data.set(key, value)
  })(object)
  return data
}

export const getCombinedTechList = R.reduce((list, tech) => {
  const { techside_username, id } = tech
  const updatedTech = { ...tech, tech_ids: [id] }
  if (!techside_username) {
    return [...list, updatedTech]
  }
  const index = R.findIndex(R.propEq('techside_username', techside_username))(list)
  if (index > -1) {
    return R.over(R.lensPath([index, 'tech_ids']), R.append(id), list)
  }
  return [...list, updatedTech]
}, [])

export const findTechWithState = id =>
  R.allPass([
    R.propEq('tech_id', id),
    R.pipe(
      R.prop('install_address_state'),
      R.isEmpty,
      R.not
    )
  ])

export const getTechState = id =>
  R.pipe(
    R.prop('jobs'),
    R.find(findTechWithState(id)),
    R.prop('install_address_state')
  )

export const getTechWaypoints = id =>
  R.pipe(
    R.prop('waypoints'),
    R.filter(R.propEq('tech_id', id))
  )

export const hasNoDate = R.pipe(
  R.prop('date'),
  R.isNil
)

export const existsOnSelectedDate = state => ({ google_place_id }) =>
  !state.waypoints.find(
    R.allPass([R.propEq('google_place_id', google_place_id), R.propEq('date', formatToDb(state.filters.date))])
  )

export const getTechWaypointList = id => state => {
  return R.pipe(
    getTechWaypoints(id),
    R.filter(R.allPass([hasNoDate, existsOnSelectedDate(state)]))
  )(state)
}

export const filterWaypointsByDate = date => R.filter(R.propEq('date', date))

export const getDateTechWaypoints = (id, date) =>
  R.pipe(
    getTechWaypoints(id),
    filterWaypointsByDate(formatToDb(date))
  )

export const getTechNameMap = R.pipe(
  R.prop('techs'),
  R.map(
    R.pipe(
      R.when(
        R.pipe(
          R.prop('color'),
          R.not
        ),
        R.assoc('color', shedulerColors.default)
      ),
      R.applySpec({ id: R.prop('id'), val: R.identity }),
      R.values
    )
  ),
  R.fromPairs,
  R.assoc('0', { color: shedulerColors.unassigned, name: 'Unassigned' })
)
