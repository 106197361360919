var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      staticClass: "tech-menu",
      attrs: { trigger: "click" },
      on: { command: _vm.command }
    },
    [
      _c(
        "div",
        { staticClass: "tech-menu-wrapper" },
        [
          _c("v-icon", {
            attrs: {
              name: _vm.loading ? "spinner" : "ellipsis-h",
              spin: _vm.loading
            }
          })
        ],
        1
      ),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            {
              attrs: { icon: "el-icon-edit-outline", command: "setTechModal" }
            },
            [_vm._v(" Details ")]
          ),
          _c(
            "el-dropdown-item",
            {
              attrs: { icon: "el-icon-document-copy", command: "createJobPdfs" }
            },
            [_vm._v(" Download PDF ")]
          ),
          _c(
            "el-dropdown-item",
            {
              attrs: {
                icon: "el-icon-chat-line-round",
                disabled: !_vm.textsEnabled,
                command: "startTextMessagingChat"
              }
            },
            [_vm._v(" Messaging ")]
          ),
          _c(
            "el-dropdown-item",
            { attrs: { icon: "el-icon-date", command: "openCalendar" } },
            [_vm._v(" Availability ")]
          ),
          _c(
            "el-dropdown-item",
            {
              attrs: {
                disabled: "",
                icon: "el-icon-add-location",
                command: "openWaypointsModal"
              }
            },
            [_vm._v(" Waypoints ")]
          ),
          _c(
            "el-dropdown-item",
            {
              attrs: {
                disabled: !_vm.techHasJobsToday,
                icon: "el-icon-magic-stick",
                command: "openBulkRescheduleModal"
              }
            },
            [_vm._v(" Reschedule Route ")]
          ),
          _c(
            "el-dropdown-item",
            {
              attrs: {
                disabled: !_vm.techHasJobsToday,
                icon: "material-icons-outline md-swap_horiz t-text-xs",
                command: "openBulkReassignModal"
              }
            },
            [_vm._v(" Reassign Route ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }