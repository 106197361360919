<template>
  <div class="t-h-1/2 t-py-6">
    <div class="t-font-medium t-text-gray-400 t-text-xs t-mb-2 t-px-8">
      Parent catalogs
    </div>
    <div class="t-h-full t-overflow-y-auto t-px-8">
      <ItemResultCard
        v-for="i in data"
        :key="i.id"
        :item="i"
        type="parent"
        @unassigned="unassignParent(i.id)"
      />
      <div>
        <infinite-loading
          @infinite="loadParents"
          spinner="spiral"
          :identifier="infiniteId"
          style="flex: 1 0 100%;"
        >
          <div
            slot="no-more"
            class="t-pt-2 t-text-xs t-text-gray-500 t-italic t-pb-4"
          >
            No more parents
          </div>
          <div
            slot="no-results"
            class="t-pt-2 t-text-xs t-text-gray-500 t-italic t-pb-4"
          >
            The {{ isCatalog ? 'catalog' : 'item' }} doesn't have any parent catalogs
          </div>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import ItemResultCard from '../ItemResultCard.vue'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    InfiniteLoading,
    ItemResultCard
  },
  props: {
    id: Number,
    isCatalog: Boolean
  },
  data: () => ({
    page: 1,
    data: [],
    pagination: {},
    infiniteId: +new Date()
  }),
  watch: {
    id: function(val) {
      if (val) {
        this.infiniteId++
      } else {
        this.resetParents()
      }
    }
  },
  methods: {
    resetParents: function() {
      this.page = 1
      this.data = []
      this.pagination = {}
    },
    loadParents: async function($state) {
      if (this.id) {
        const { data } = await this.$unum.warehouse().get(`/items/${this.id}/parents`, {
          params: {
            page: this.page
          }
        })
        this.pagination = data.pagination
        if (data.data.length) {
          this.page++
          this.data.push(...data.data)
          $state.loaded()
        } else {
          $state.complete()
        }
      }
    },
    refreshParents: function() {
      this.resetParents()
      this.infiniteId++
      this.$emit('updated')
    },
    unassignParent: async function(parentId) {
      try {
        await this.$messageBox.confirm(
          'The item will be unassigned from the parent catalog. Do you want ' +
          'to continue ?',
          {
            type: 'warning',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            beforeClose: async (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonText = 'Loading...'
                instance.confirmButtonLoading = true
                try {
                  await this.$unum
                    .warehouse()
                    .delete(`/catalogs/${parentId}/children/${this.id}`)
                  this.refreshParents()
                  instance.confirmButtonLoading = false
                  done()
                } catch {
                  instance.confirmButtonText = 'Yes'
                  instance.confirmButtonLoading = false
                }
              } else {
                done()
              }
            }
          }
        )
      } catch {}
    }
  }
}
</script>
