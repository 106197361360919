var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        width: "400px",
        visible: _vm.isOpen,
        "before-close": _vm.handleClose,
        top: "18vh",
        "custom-class": "add-channel-user-dialog"
      }
    },
    [
      _c(
        "span",
        { staticClass: "t-text-lg", attrs: { slot: "title" }, slot: "title" },
        [
          _c("span", { staticClass: "t-mr-8 t-flex" }, [
            _c("div", { staticClass: "t-truncate" }, [
              _c("span", [_vm._v("Add users to ")]),
              !_vm.activeChannel.isPrivate
                ? _c("span", [_vm._v("#")])
                : _c("i", { staticClass: "el-item-lock" }),
              _vm._v(" " + _vm._s(_vm.activeChannel.uniqueName) + " ")
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "t-h-full" },
        [
          !_vm.users
            ? _c("div", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: true,
                    expression: "true"
                  }
                ],
                staticClass: "t-h-20"
              })
            : _c(
                "el-checkbox-group",
                {
                  staticClass: "t-flex t-flex-col t-mb-2",
                  model: {
                    value: _vm.selectedIdentities,
                    callback: function($$v) {
                      _vm.selectedIdentities = $$v
                    },
                    expression: "selectedIdentities"
                  }
                },
                _vm._l(_vm.users, function(identity) {
                  return _c(
                    "el-checkbox",
                    {
                      key: identity,
                      staticClass:
                        "t-normal-case t-flex t-items-center t-h-10 t-mx-0 t-mb-2 t-font-normal",
                      attrs: {
                        label: identity,
                        disabled: !!_vm.members.find(function(i) {
                          return i.identity === identity
                        }),
                        border: ""
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-user" }),
                      _c("span", { staticClass: "t-text-lg" }, [
                        _vm._v(" " + _vm._s(identity) + " ")
                      ]),
                      !!_vm.members.find(function(i) {
                        return i.identity === identity
                      })
                        ? _c("span", { staticClass: "t-text-xs t-ml-4" }, [
                            _vm._v(" already joined ")
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                1
              ),
          _c(
            "div",
            { staticClass: "t-text-right" },
            [
              _c(
                "el-button",
                {
                  staticClass: "t-mt-2",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    disabled: !_vm.selectedIdentities.length,
                    loading: _vm.submitting,
                    size: "medium"
                  },
                  on: { click: _vm.add }
                },
                [_vm._v(" Add ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }