var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-pb-4" },
    [
      _vm.parts.length > 0
        ? _c(
            "el-table",
            {
              staticClass: "t-w-full",
              attrs: {
                data: _vm.parts,
                size: "small",
                "show-header": false,
                "empty-text": "No parts found"
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "150" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", { staticClass: "t-text-indigo-800" }, [
                            _vm._v(" " + _vm._s(scope.row.item_number) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2752299447
                )
              }),
              _c("el-table-column", {
                attrs: { width: "50" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(" " + _vm._s(scope.row.item_prefix) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2942693852
                )
              }),
              _c("el-table-column", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.item_description))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1203582314
                )
              }),
              _c("el-table-column", {
                attrs: { width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.can_order === "IN STOCK"
                            ? _c("span", { staticClass: "t-text-green-600" }, [
                                _vm._v(" In stock ")
                              ])
                            : _c("span", { staticClass: "t-text-gray-600" }, [
                                _vm._v(" Out of stock ")
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  546580845
                )
              }),
              _c("el-table-column", {
                attrs: { width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " $" +
                              _vm._s(
                                Math.round(scope.row.item_price * 100) / 100
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  48260902
                )
              }),
              _c("el-table-column", {
                attrs: { align: "center", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.can_order === "IN STOCK"
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "t-cursor-pointer t-text-purple-600 t-px-2 t-pt-1 t-pb-0\n                 t-border t-border-solid t-border-purple-400 t-rounded-full\n                 t-bg-white hover:t-text-white hover:t-border-purple-600\n                 hover:t-bg-purple-600",
                                  on: {
                                    click: function($event) {
                                      return _vm.add(scope.row)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-shopping-cart-2 t-text-base"
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1737480897
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("add-part-to-cart", {
        attrs: {
          visible: _vm.addPartToCartOpen,
          part: _vm.selectedPart,
          vendor: "american",
          shopId: _vm.shopId,
          jobId: _vm.jobId,
          status: _vm.status,
          warehouse: _vm.warehouse
        },
        on: {
          "refresh-data": function($event) {
            return _vm.$emit("refresh-data")
          },
          close: function($event) {
            _vm.addPartToCartOpen = false
          },
          addAndLinkPart: function($event) {
            return _vm.addAndLinkPart($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }