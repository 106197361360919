<template>
  <div class="consumer-uploads"></div>
</template>

<script>
export default {}
</script>
<style lang="scss" scoped>
.consumer-uploads {
}
</style>
