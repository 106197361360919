var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.classes,
      on: {
        click: function($event) {
          $event.stopPropagation()
        },
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          ) {
            return null
          }
          $event.stopPropagation()
        }
      }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.internalValue,
            expression: "internalValue"
          }
        ],
        class: { "form-check-input": true },
        staticStyle: { "margin-top": "2px" },
        attrs: { disabled: _vm.inDisabled, type: "checkbox", id: _vm._uid },
        domProps: {
          checked: Array.isArray(_vm.internalValue)
            ? _vm._i(_vm.internalValue, null) > -1
            : _vm.internalValue
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
          },
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            $event.stopPropagation()
          },
          change: function($event) {
            var $$a = _vm.internalValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.internalValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.internalValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.internalValue = $$c
            }
          }
        }
      }),
      _c(
        "label",
        { class: { "form-check-label": true }, attrs: { for: _vm._uid } },
        [_vm._v(_vm._s(_vm.label))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }