<template>
  <div class="t-font-sans">
    <Filters class="t-mt-4"/>
    <Table class="t-mt-6"/>
    <Pagination class="t-mt-6 t-mb-2"/>
    <LeadsGlassbiller v-if='$store.state.leads.filters.shopId === 4' class="t-mt-6 t-mb-2"/>
  </div>
</template>

<script>
import Filters from './Filters'
import Table from './Table'
import Pagination from './Pagination'
import LeadsGlassbiller from '../LeadsGlassbiller/Index'

export default {
  components: {
    Filters,
    Table,
    Pagination,
    LeadsGlassbiller
  }
}
</script>
