<template>
  <div id="threads-wrapper">
    <start-chat
      ref="startChat"
      :shopPhoneNumber="shopPhoneNumber"
      :recipientPhoneNumber="recipientPhoneNumber"
      @reloadThreads="reloadThreads()"
      @setShopPhoneNumber="setShopPhoneNumber($event)"
      @setRecipientPhoneNumber="setRecipientPhoneNumber($event)"/>
    <div v-loading="threadsLoading" class="infinite-list-wrapper" id="text-message-threads">
      <ul
        class="threads-list"
        v-infinite-scroll="load"
        infinite-scroll-delay="300"
        infinite-scroll-disabled="disabled"
        infinite-scroll-immediate="false">
        <li
          v-for="i in threads"
          :key="i.id"
          @click="openThread(i)"
          :style="i.new === 1 ? 'font-weight: bold; color: #303133;' : ''">
          <div class="threads-item" :style="i.id === selectedThread.id ? 'background-color: #EBEEF5;' : ''">
            <div class="thread-icon">
              <el-avatar
                v-if="i.recipient_model === null"
                icon="el-icon-user-solid"
                class="recipient-icon">
              </el-avatar>
              <el-avatar
                v-if="i.recipient_model === 'customer' || i.recipient_model === 'consumer'"
                class="recipient-icon recipient-customer">C</el-avatar>
              <el-avatar
                v-if="i.recipient_model === 'salesrep'"
                class="recipient-icon recipient-salesrep">S</el-avatar>
              <el-avatar
                v-if="i.recipient_model === 'tech'"
                class="recipient-icon recipient-tech">T</el-avatar>
            </div>
            <div class="thread-info">
              <div class="phone-number-name">{{ formatPhoneNumberName(i.phone_number_name) }}</div>
              <div><i class="el-icon-phone"/>{{ formatPhone(i.recipient_phone_number) }}</div>
              <div v-if="i.recipient_name">
                <span
                  v-if="i.recipient_model === 'customer'"
                  @click="openCustomerInfoModal(i.recipient_id)"
                  class="recipient-customer">
                  <i class="el-icon-user-solid"/>{{ i.recipient_name }}
                </span>
                <span v-else><i class="el-icon-user-solid"/>{{ i.recipient_name }}</span>
              </div>
              <div class="message-body">
                <span v-if="i.last_message_direction === 2">You: </span>
                <span v-if="i.last_message_body.length === 0"><i>Attachment</i></span>
                <span v-else>{{ formatBody(i.last_message_body) }}</span>
              </div>
            </div>
            <div class="thread-right">
              <span v-if="i.new" class="thread-new">new</span>
              <div class="thread-datetime">
                <span>{{ formatDateTime(i.last_message_created_at) }}</span>
              </div>
              <span v-if="i.failed" class="thread-failed">failed</span>
            </div>
          </div>
        </li>
      </ul>
      <p v-if="threadsLoading" class="threads-message">
        Loading...
      </p>
      <p v-if="noMore" class="threads-message">
        <span v-if="threads.length !== 0">No more records</span>
        <span v-else>No records</span>
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import StartChat from './StartChat'
import debounce from 'lodash.debounce'
import { get, call } from 'vuex-pathify'
import TwilioTextMessageThread from '@/scripts/objects/twilio_text_message_thread'

export default {
  props: [
    'threadsLoading',
    'shouldReloadThreads',
    'threadToMarkUnread',
    'shopPhoneNumber',
    'recipientPhoneNumber'
  ],
  data: () => ({
    threads: [],
    limit: 10,
    offset: 0,
    total: 0
  }),
  computed: {
    disabled () {
      return this.threadsLoading || this.noMore
    },
    noMore () {
      return this.offset > this.total
    },
    visible: get('twilio/textMessages/visible'),
    selectedThread: get('twilio/textMessages/selectedThread'),
    newMessageThreadId: get('twilio/textMessages/newMessageThreadId'),
    undeliveredMessage: get('twilio/textMessages/undeliveredMessage')
  },
  async created () {
    await this.reloadThreads()
  },
  watch: {
    visible: async function (value) {
      if (value) {
        await this.reloadThreads()
      } else {
        this.clearData()
      }
    },
    newMessageThreadId: async function (value) {
      if (this.visible && value !== null) {
        await this.reloadThreads()
        if (value === this.selectedThread.id) {
          this.threads.forEach(thread => {
            if (thread.id === value) {
              thread.new = 0
            }
          })
        }
        this.setNewMessageThreadId(null)
      }
    },
    shouldReloadThreads: async function (value) {
      if (value) {
        await this.reloadThreads()
        this.$emit('threadsReloaded')
      }
    },
    threadToMarkUnread: async function (value) {
      if (value) {
        this.threads.forEach(thread => {
          if (thread.id === value) {
            thread.new = 1
          }
        })
        this.$emit('threadMarkedUnread')
      }
    },
    shopPhoneNumber: async function (value) {
      if (this.visible && (value === '' || (value.length === 10 && /^\d+$/.test(value)))) {
        await this.reloadThreads()
      }
    },
    recipientPhoneNumber: async function (value) {
      if (value === '' || (/^\d+$/.test(value))) {
        await this.reloadThreads()
      }
    },
    undeliveredMessage: function (value) {
      this.threads.forEach(thread => {
        if (thread.id === value.threadId) {
          thread.failed = 1
        }
      })
    }
  },
  methods: {
    clearData () {
      this.threads = []
      this.offset = 0
      this.total = 0
    },
    async reloadThreads () {
      this.clearData()
      await this.load()
    },
    load: debounce(async function () {
      this.$emit('threadsLoading', true)
      const { data } = await TwilioTextMessageThread.getForUser(
        this.shopPhoneNumber,
        this.recipientPhoneNumber,
        this.offset,
        this.limit
      )
      this.total = data.meta.total
      this.threads = this.threads.concat(data.data)
      this.offset = this.offset + this.limit
      this.$emit('threadsLoading', false)
    }, 500),
    formatDateTime (timestamp) {
      let date
      const duration = moment.duration(moment().diff(moment(timestamp)))
      if (duration.asMinutes() < 1) {
        date = 'Just now'
      } else if (duration.asMinutes() < 59) {
        date = Math.round(duration.asMinutes()) + ' min ago'
      } else {
        if (moment(timestamp).isSame(moment(), 'day')) {
          date = moment(timestamp).format('LT')
        } else if (moment(timestamp).isSame(moment(), 'week')) {
          date = moment(timestamp).format('ddd')
        } else if (moment(timestamp).isSame(moment(), 'year')) {
          date = moment(timestamp).format('MMM D')
        } else {
          date = moment(timestamp).format('M/D/YY')
        }
      }
      return date
    },
    formatPhoneNumberName (name) {
      let formattedName
      if (name.length > 35) {
        formattedName = name.substring(0, 35) + '...'
      } else {
        formattedName = name
      }
      return formattedName
    },
    formatBody (body) {
      let formattedBody
      if (body.length > 75) {
        formattedBody = body.substring(0, 75) + '...'
      } else {
        formattedBody = body
      }
      return formattedBody
    },
    formatPhone (phone) {
      return `${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6)}`
    },
    setSelectedThread: call('twilio/textMessages/setSelectedThread'),
    openThread (selectedThread) {
      this.threads.forEach(thread => {
        if (thread.id === selectedThread.id) {
          thread.new = 0
          thread.failed = 0
        }
      })
      this.setSelectedThread({ ...selectedThread })
    },
    setNewMessageThreadId: call('twilio/textMessages/setNewMessageThreadId'),
    openCustomerInfoModal (customerId) {
      this.$emit('openCustomerInfoModal', customerId)
    },
    setShopPhoneNumber (value) {
      this.$emit('setShopPhoneNumber', value)
    },
    setRecipientPhoneNumber (value) {
      this.$emit('setRecipientPhoneNumber', value)
    },
    startTextMessagingChat (recipient) {
      this.$refs.startChat.startTextMessagingChat(recipient)
    }
  },
  components: {
    StartChat
  }
}
</script>

<style lang="scss">
  #threads-wrapper {
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    border-right: 1px solid #DCDFE6;
    #text-message-threads {
      flex-grow: 1;
      overflow-y: auto;
      padding-bottom: 60px;
      .threads-list {
        list-style-type: none;
        margin-bottom: 1px;
        padding: 0;
        .threads-item {
          border-bottom: 1px solid #ebebeb;
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: #F2F6FC;
          }
          .thread-icon {
            display: flex;
            padding: 15px;
            .recipient-icon {
              font-size: 22px;
            }
            .recipient-customer {
              background-color: #E6A23C;
            }
            .recipient-salesrep {
              background-color: #409EFF;
            }
            .recipient-tech {
              background-color: #67C23A;
            }
          }
          .thread-info {
            flex: 1;
            flex-direction: column;
            padding: 7px 0;
            i {
              margin-right: 5px;
            }
            .phone-number-name {
              font-size: 15px;
            }
            .recipient-customer {
              text-decoration: underline;
              color: rgb(38, 95, 151);
            }
            .message-body {
              font-size: 13px;
              color: #909399;
              white-space: pre-line;
              word-break: break-word;
            }
          }
          .thread-right {
            position: relative;
            .thread-datetime {
              width: 80px;
              text-align: right;
              padding-right: 8px;
            }
            .thread-new {
              position: absolute;
              top: -20px;
              right: 8px;
              color: white;
              background-color: rgb(83, 168, 255);
              border-radius: 15px;
              padding: 0 5px;
              font-size: 12px;
            }
            .thread-failed {
              position: absolute;
              top: 23px;
              right: 8px;
              color: white;
              background-color: #F56C6C;
              border-radius: 15px;
              padding: 0 5px;
              font-size: 12px;
            }
          }
        }
      }
      .threads-message {
        text-align: center;
        padding: 20px 0 0;
      }
    }
  }
</style>
