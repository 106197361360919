<template>
  <el-row :gutter="16" class="consumer-settings">
    <div style='margin-left:8px;margin-right:8px;'>
      <gb-checkbox v-model="override_allowed_shops">Override Which Shops Included On</gb-checkbox>
    </div>
    <div v-show="override_allowed_shops" style='margin-left:8px;margin-right:8px;'>
      <input-with-label
        :select="{ options: shopsByOrganization, byId: true }"
        :options="shopsByOrganization"
        v-model="shopsToAppearIn"
        :multiple="true"
        label="Shops To Appear In"
      />
    </div>

    <hr>
    <el-col :span="12" >
      <input-with-label
        :select="{ options: shopsByOrganization, byId: true }"
        :options="shopsByOrganization"
        v-model="shopId"
        label="Shop"
        :forceFocus="!!shopId"
      />
    </el-col>
    <br><br><br>
    <el-col v-show="shopId" :span="12" >
      <input-with-label
        label="Default Nags Pricing"
        size="medium"
        v-model="rates"
        :select="{
          options: shopRatesToSelect,
          isLoading: false,
          clearable: true,
          byId: false,
        }"
        @add="
          showShopRateDialog = true
          shopRateModalData = {};
        "
        @edit="
          shopRateModalData = rates;
          showShopRateDialog = true;
        "
      />
    </el-col>
    <el-col v-show="shopId" :span="12">
      <input-with-label
        label="Default Price Level"
        size="medium"
        v-model="selectedPL"
        :select="{
          options: shopPricelevels,
          isLoading: !!pricelevelsLoading,
          clearable: true,
          byId: true,
        }"
      />
    </el-col>
    <el-col v-show="shopId" :span="24">
      <consumer-taxes :value="value" @input="$emit('input', $event)" :shopId="shopId" />
    </el-col>

    <shop-rates-dialog
      v-model="showShopRateDialog"
      :rate="shopRateModalData"
      :shopRates="shopRates"
      :shop_id="shopId"
      :defaultName="defaultRateNameToSet"
      :applyRateMethod="applyRateMethod"
      :autoApply="true"/>
  </el-row>
</template>

<script>
import ConsumerTaxes from './ConsumerTaxes.vue'
import ShopRatesDialog from '@/components/JobPageV2/helpers/dialogs/ShopRatesDialog'
import * as R from 'ramda'
import { SHOPS_ORGANIZATION_QUERY } from '@/apollo/queries/shops.query'
import Vue from 'vue'

const { SHOP_PIECES_QUERIES } = require('@/apollo/queries/shop-pieces.query')

export default {
  props: ['value'],
  data() {
    return {
      selected: false,
      pricelevelsLoading: 0,
      showShopRateDialog: false,
      shopRateModalData: {},
      shopId: null
    }
  },
  mounted () {
    if (this?.$job?.shop?.id) {
      this.shopId = this.$job.shop.id
    } else if (this.shopsByOrganization && this.shopsByOrganization.length === 1) {
      this.shopId = this.shopsByOrganization[0].id
    }
  },
  apollo: {
    shopsByOrganization: {
      query() {
        return SHOPS_ORGANIZATION_QUERY
      },
      variables() {
        return {
          organization_id: this.value.organization_id
        }
      },
      skip() {
        return !this.value.organization_id
      },
      result() {
        if (!this.shopId && this.shopsByOrganization && this.shopsByOrganization.length === 1) {
          this.shopId = this.shopsByOrganization[0].id
        }
      }
    },
    shopPricelevels: {
      query() {
        return SHOP_PIECES_QUERIES.pricelevels
      },
      variables() {
        return {
          shop_id: this.shopId
        }
      },
      skip() {
        return !this.shopId
      },
      loadingKey: 'pricelevelsLoading'
    },
    shopRates: {
      query: SHOP_PIECES_QUERIES.rates,
      variables() {
        return {
          shop_id: this.shopId
        }
      },
      skip() {
        return !this.shopId
      }
    }
  },
  components: { ConsumerTaxes, ShopRatesDialog },
  computed: {
    shopsToAppearIn: {
      get() {
        var shopsToAppearIn = []
        for (var i = 0; i < this.consumer_shop_settings.length; i++) {
          if (this.consumer_shop_settings[i].override_allowed_shop) {
            shopsToAppearIn.push(this.consumer_shop_settings[i].shop_id)
          }
        }
        return shopsToAppearIn
      },
      set(shopsToAppearIn) {
        for (var i = 0; i < this.consumer_shop_settings.length; i++) {
          Vue.set(this.consumer_shop_settings[i], 'override_allowed_shop', 0)
        }

        for (var j = 0; j < shopsToAppearIn.length; j++) {
          var foundShopSetting = false
          for (i = 0; i < this.consumer_shop_settings.length; i++) {
            if (this.consumer_shop_settings[i].shop_id === shopsToAppearIn[j]) {
              Vue.set(this.consumer_shop_settings[i], 'override_allowed_shop', 1)
              foundShopSetting = true
            }
          }
          if (!foundShopSetting) {
            var newConsumerShopSetting = this.addShopToConsumerSettings(shopsToAppearIn[j])
            Vue.set(newConsumerShopSetting, 'override_allowed_shop', 1)
          }
        }
      }
    },
    override_allowed_shops: {
      get() {
        return this.value?.override_allowed_shops
      },
      set(v) {
        this.$emit('input', {
          ...this.value,
          override_allowed_shops: +v
        })
      }
    },
    consumer_shop_settings: {
      get() {
        return this.value?.consumer_shop_settings || []
      },
      set(consumer_shop_settings) {
        this.$emit('input', { ...this.value, consumer_shop_settings })
      }
    },
    consumer_shop_setting() {
      if (!this.shopId) {
        return {}
      }
      for (var i = 0; i < this.consumer_shop_settings.length; i++) {
        if (this.consumer_shop_settings[i]?.shop_id === this.shopId) {
          return this.consumer_shop_settings[i]
        }
      }
      return this.addShopToConsumerSettings(this.shopId)
    },
    selectedPL: {
      get() {
        return this.consumer_shop_setting.pricelevel_id
      },
      set(pricelevel_id) {
        this.consumer_shop_setting.pricelevel_id = pricelevel_id || null
        this.$emit('input', { ...this.value, consumer_shop_settings: this.consumer_shop_settings })
      }
    },
    defaultRateNameToSet() {
      return this.value?.company_name?.length > 0 ? this.value?.company_name : this.consumerFullName
    },
    consumerFullName() {
      return [this.value?.name?.first, this.value?.name?.middle, this.value?.name?.last].filter(Boolean).join(' ')
    },
    shopRatesToSelect() {
      var shopRatesToCompare = this.shopRates ? this.shopRates : []

      // consumers may have custom individual rates already, this maintains those
      if (!this.consumer_shop_setting.rates || !this.consumer_shop_setting.rates.id || this.consumer_shop_setting.rates.id <= 0) {
        return shopRatesToCompare
      }
      var foundMatch = false
      for (var i = 0; i < shopRatesToCompare.length; i++) {
        if (shopRatesToCompare[i].id === this.consumer_shop_setting.rates.id) {
          foundMatch = true
        }
      }
      if (foundMatch) {
        return shopRatesToCompare
      } else {
        var ratesToReturn = R.clone(shopRatesToCompare)
        var rateToAdd = R.clone(this.consumer_shop_setting.rates)
        rateToAdd.name = 'Custom Consumer Rate'
        rateToAdd.customConsumerRate = true
        rateToAdd.nameToSetTo = this.defaultRateNameToSet
        ratesToReturn.push(rateToAdd)
        return ratesToReturn
      }
    },
    rates: {
      get() {
        if (this.consumer_shop_setting?.rates) {
          for (var i = 0; i < this.shopRatesToSelect.length; i++) {
            if (this.consumer_shop_setting.rates.id === this.shopRatesToSelect[i].id) {
              return this.shopRatesToSelect[i]
            }
          }
        }
        return this.consumer_shop_setting?.rates
      },
      set(rates) {
        if (!rates || rates.length === 0) {
          rates = {}
        }
        this.consumer_shop_setting.rates = rates
        this.$emit('input', { ...this.value, consumer_shop_settings: this.consumer_shop_settings })
      }
    }
  },
  methods: {
    addShopToConsumerSettings(shopId) {
      var consumerShopSettingToAdd = {
        shop_id: shopId,
        pricelevel_id: null,
        rates: null,
        override_allowed_shop: 0
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.consumer_shop_settings.push(consumerShopSettingToAdd)
      return consumerShopSettingToAdd
    },
    applyRateMethod(newRate) {
      this.rates = newRate
    }
  }
}
</script>
<style lang="scss" scoped>
.consumer-settings {
  padding: $padding-sm 0;
  > * {
    margin-bottom: $padding-md;
  }
}
</style>

<style lang="scss">
.consumer-rates .el-dialog__header {
  padding: 20px 20px 10px 20px !important;
}

.consumer-rates .el-dialog__body {
  padding: 0px 20px 30px 20px !important;
}

.consumer-rates .el-dialog__footer {
  padding: 10px 20px 20px 20px !important;
}
</style>
