// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".add-channel-user-dialog {\n  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n}\n.add-channel-user-dialog .el-dialog__header {\n  border-width: 0px;\n  border-bottom-width: 1px;\n  border-style: solid;\n  --tw-border-opacity: 1;\n  border-color: rgba(209, 213, 219, var(--tw-border-opacity));\n}\n.add-channel-user-dialog .el-dialog__body {\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n}", "",{"version":3,"sources":["/app/<no source>","AddUsersDialog.vue"],"names":[],"mappings":"AAAA;EAAA,4NAAA;ACaA;ADbA;EAAA,iBAAA;EAAA,wBAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,2DAAA;ACoBA;ADpBA;EAAA,iBAAA;EAAA,oBAAA;ACwBA","file":"AddUsersDialog.vue","sourcesContent":[null,":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.add-channel-user-dialog {\n  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n}\n.add-channel-user-dialog .el-dialog__header {\n  border-width: 0px;\n  border-bottom-width: 1px;\n  border-style: solid;\n  --tw-border-opacity: 1;\n  border-color: rgba(209, 213, 219, var(--tw-border-opacity));\n}\n.add-channel-user-dialog .el-dialog__body {\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
