export const activityIcons = {
  arrived: '',
  call: {
    path:
      'M23.175,18.3166667 C22.15,18.3166667 21.1583333,18.15 20.2333333,17.85 C19.9416667,17.75 19.6166667,17.825 19.3916667,18.05 L18.0833333,19.6916667 C15.725,18.5666667 13.5166667,16.4416667 12.3416667,14 L13.9666667,12.6166667 C14.1916667,12.3833333 14.2583333,12.0583333 14.1666667,11.7666667 C13.8583333,10.8416667 13.7,9.85 13.7,8.825 C13.7,8.375 13.325,8 12.875,8 L9.99166667,8 C9.54166667,8 9,8.2 9,8.825 C9,16.5666667 15.4416667,23 23.175,23 C23.7666667,23 24,22.475 24,22.0166667 L24,19.1416667 C24,18.6916667 23.625,18.3166667 23.175,18.3166667 Z',
    icon: 'call'
  },
  cc: {
    path:
      'M22.4,9 L9.6,9 C8.712,9 8.008,9.712 8.008,10.6 L8,20.2 C8,21.088 8.712,21.8 9.6,21.8 L22.4,21.8 C23.288,21.8 24,21.088 24,20.2 L24,10.6 C24,9.712 23.288,9 22.4,9 Z M22.4,20.2 L9.6,20.2 L9.6,15.4 L22.4,15.4 L22.4,20.2 Z M22.4,12.2 L9.6,12.2 L9.6,10.6 L22.4,10.6 L22.4,12.2 Z',
    icon: 'credit_cards'
  },
  clockin: {
    path:
      'M18.6666667,6 L13.3333333,6 L13.3333333,7.77777778 L18.6666667,7.77777778 L18.6666667,6 Z M15.1111111,17.5555556 L16.8888889,17.5555556 L16.8888889,12.2222222 L15.1111111,12.2222222 L15.1111111,17.5555556 Z M22.2488889,11.68 L23.5111111,10.4177778 C23.1288889,9.96444444 22.7111111,9.53777778 22.2577778,9.16444444 L20.9955556,10.4266667 C19.6177778,9.32444444 17.8844444,8.66666667 16,8.66666667 C11.5822222,8.66666667 8,12.2488889 8,16.6666667 C8,21.0844444 11.5733333,24.6666667 16,24.6666667 C20.4266667,24.6666667 24,21.0844444 24,16.6666667 C24,14.7822222 23.3422222,13.0488889 22.2488889,11.68 Z M16,22.8888889 C12.56,22.8888889 9.77777778,20.1066667 9.77777778,16.6666667 C9.77777778,13.2266667 12.56,10.4444444 16,10.4444444 C19.44,10.4444444 22.2222222,13.2266667 22.2222222,16.6666667 C22.2222222,20.1066667 19.44,22.8888889 16,22.8888889 Z',
    icon: 'timer'
  },
  clockout: {
    path:
      'M22.2690909,9.0479798 L21.0150649,10.2671717 C19.6462338,9.21111111 17.9241558,8.57575758 16.0519481,8.57575758 C14.4358442,8.57575758 12.9345455,9.0479798 11.6805195,9.84646465 L12.9698701,11.1 C13.8706494,10.5934343 14.9303896,10.2929293 16.0519481,10.2929293 C19.4696104,10.2929293 22.2337662,12.980303 22.2337662,16.3030303 C22.2337662,17.3934343 21.9246753,18.4237374 21.4036364,19.2994949 L22.6841558,20.5444444 C23.5142857,19.3338384 24,17.8742424 24,16.3030303 C24,14.4828283 23.3464935,12.8085859 22.2602597,11.4863636 L23.5142857,10.2671717 L22.2690909,9.0479798 L22.2690909,9.0479798 Z M18.7012987,6 L13.4025974,6 L13.4025974,7.71717172 L18.7012987,7.71717172 L18.7012987,6 Z M15.1688312,13.2464646 L16.9350649,14.9636364 L16.9350649,12.010101 L15.1688312,12.010101 L15.1688312,13.2464646 Z M8.12155844,8.57575758 L7,9.66616162 L9.42857143,12.0358586 C8.58961039,13.2550505 8.1038961,14.7232323 8.1038961,16.3030303 C8.1038961,20.570202 11.654026,24.030303 16.0519481,24.030303 C17.6768831,24.030303 19.187013,23.5580808 20.4498701,22.7424242 L22.6576623,24.8888889 L23.7792208,23.7984848 L16.9703896,17.1787879 L8.12155844,8.57575758 Z M16.0519481,22.3131313 C12.6342857,22.3131313 9.87012987,19.6257576 9.87012987,16.3030303 C9.87012987,15.2040404 10.1792208,14.1737374 10.7090909,13.2808081 L19.1516883,21.4888889 C18.2420779,22.0126263 17.1823377,22.3131313 16.0519481,22.3131313 L16.0519481,22.3131313 Z',
    icon: 'timer_off'
  },
  email: {
    path:
      'M16,7 C11.032,7 7,11.032 7,16 C7,20.968 11.032,25 16,25 L20.5,25 L20.5,23.2 L16,23.2 C12.094,23.2 8.8,19.906 8.8,16 C8.8,12.094 12.094,8.8 16,8.8 C19.906,8.8 23.2,12.094 23.2,16 L23.2,17.287 C23.2,17.998 22.561,18.7 21.85,18.7 C21.139,18.7 20.5,17.998 20.5,17.287 L20.5,16 C20.5,13.516 18.484,11.5 16,11.5 C13.516,11.5 11.5,13.516 11.5,16 C11.5,18.484 13.516,20.5 16,20.5 C17.242,20.5 18.376,19.996 19.186,19.177 C19.771,19.978 20.779,20.5 21.85,20.5 C23.623,20.5 25,19.06 25,17.287 L25,16 C25,11.032 20.968,7 16,7 Z M16,18.7 C14.506,18.7 13.3,17.494 13.3,16 C13.3,14.506 14.506,13.3 16,13.3 C17.494,13.3 18.7,14.506 18.7,16 C18.7,17.494 17.494,18.7 16,18.7 Z',
    icon: 'alternate_email'
  },
  gallery: {
    path:
      'M24,19.2 L24,9.6 C24,8.72 23.28,8 22.4,8 L12.8,8 C11.92,8 11.2,8.72 11.2,9.6 L11.2,19.2 C11.2,20.08 11.92,20.8 12.8,20.8 L22.4,20.8 C23.28,20.8 24,20.08 24,19.2 Z M15.2,16 L16.824,18.168 L19.2,15.2 L22.4,19.2 L12.8,19.2 L15.2,16 Z M8,11.2 L8,22.4 C8,23.28 8.72,24 9.6,24 L20.8,24 L20.8,22.4 L9.6,22.4 L9.6,11.2 L8,11.2 Z',
    icon: 'photo_library'
  },
  left: { path: '', icon: '' },
  location: { path: '', icon: 'navigation' },
  login: {
    path:
      'M20.0266667,14.9422222 L19.0844444,14 L14.7466667,18.3377778 L12.8622222,16.4533333 L11.92,17.3955556 L14.7466667,20.2222222 L20.0266667,14.9422222 L20.0266667,14.9422222 Z M22.2222222,7.77777778 L21.3333333,7.77777778 L21.3333333,6 L19.5555556,6 L19.5555556,7.77777778 L12.4444444,7.77777778 L12.4444444,6 L10.6666667,6 L10.6666667,7.77777778 L9.77777778,7.77777778 C8.79111111,7.77777778 8.00888889,8.57777778 8.00888889,9.55555556 L8,22 C8,22.9777778 8.79111111,23.7777778 9.77777778,23.7777778 L22.2222222,23.7777778 C23.2,23.7777778 24,22.9777778 24,22 L24,9.55555556 C24,8.57777778 23.2,7.77777778 22.2222222,7.77777778 Z M22.2222222,22 L9.77777778,22 L9.77777778,12.2222222 L22.2222222,12.2222222 L22.2222222,22 Z',
    icon: 'event_available'
  },
  logout: {
    path:
      'M13.6088889,20.2222222 L15.7777778,18.0533333 L17.9466667,20.2222222 L18.8888889,19.28 L16.72,17.1111111 L18.8888889,14.9422222 L17.9466667,14 L15.7777778,16.1688889 L13.6088889,14 L12.6666667,14.9422222 L14.8355556,17.1111111 L12.6666667,19.28 L13.6088889,20.2222222 Z M22.2222222,7.77777778 L21.3333333,7.77777778 L21.3333333,6 L19.5555556,6 L19.5555556,7.77777778 L12.4444444,7.77777778 L12.4444444,6 L10.6666667,6 L10.6666667,7.77777778 L9.77777778,7.77777778 C8.79111111,7.77777778 8.00888889,8.57777778 8.00888889,9.55555556 L8,22 C8,22.9777778 8.79111111,23.7777778 9.77777778,23.7777778 L22.2222222,23.7777778 C23.2,23.7777778 24,22.9777778 24,22 L24,9.55555556 C24,8.57777778 23.2,7.77777778 22.2222222,7.77777778 Z M22.2222222,22 L9.77777778,22 L9.77777778,12.2222222 L22.2222222,12.2222222 L22.2222222,22 Z',
    icon: 'event_busy'
  },
  payment: {
    path:
      'M16.459725,15.3123772 C13.78389,14.6168959 12.9233792,13.8978389 12.9233792,12.7779961 C12.9233792,11.4931238 14.1139489,10.5972495 16.1060904,10.5972495 C18.2043222,10.5972495 18.9823183,11.5992141 19.0530452,13.0726916 L21.6581532,13.0726916 C21.5756385,11.0451866 20.3379175,9.1827112 17.8742633,8.58153242 L17.8742633,6 L14.3379175,6 L14.3379175,8.54616896 C12.0510806,9.04125737 10.2121807,10.5265226 10.2121807,12.8015717 C10.2121807,15.524558 12.4636542,16.8801572 15.7524558,17.6699411 C18.6994106,18.3772102 19.2888016,19.4145383 19.2888016,20.5108055 C19.2888016,21.324165 18.7111984,22.6208251 16.1060904,22.6208251 C13.6777996,22.6208251 12.7229862,21.5363458 12.5933202,20.1453831 L10,20.1453831 C10.1414538,22.7269155 12.0746562,24.1768173 14.3379175,24.6601179 L14.3379175,27.2180747 L17.8742633,27.2180747 L17.8742633,24.6836935 C20.172888,24.2475442 22,22.9155206 22,20.4990177 C22,17.151277 19.1355599,16.0078585 16.459725,15.3123772 Z',
    icon: 'attach_money'
  },
  photo: {
    path:
      'M13.6,8 L12.136,9.6 L9.6,9.6 C8.72,9.6 8,10.32 8,11.2 L8,20.8 C8,21.68 8.72,22.4 9.6,22.4 L22.4,22.4 C23.28,22.4 24,21.68 24,20.8 L24,11.2 C24,10.32 23.28,9.6 22.4,9.6 L19.864,9.6 L18.4,8 L13.6,8 Z M16,20 C13.792,20 12,18.208 12,16 C12,13.792 13.792,12 16,12 C18.208,12 20,13.792 20,16 C20,18.208 18.208,20 16,20 Z',
    icon: 'photo_camera'
  },
  sent: {
    path:
      'M22.125,13.0333333 C21.5583333,10.1583333 19.0333333,8 16,8 C13.5916667,8 11.5,9.36666667 10.4583333,11.3666667 C7.95,11.6333333 6,13.7583333 6,16.3333333 C6,19.0916667 8.24166667,21.3333333 11,21.3333333 L21.8333333,21.3333333 C24.1333333,21.3333333 26,19.4666667 26,17.1666667 C26,14.9666667 24.2916667,13.1833333 22.125,13.0333333 Z M14.3333333,18.8333333 L11.4166667,15.9166667 L12.5916667,14.7416667 L14.3333333,16.475 L18.65,12.1666667 L19.825,13.3416667 L14.3333333,18.8333333 Z',
    icon: 'cloud_done'
  },
  sign: {
    path:
      'M9,19.0837384 L9,22 L11.9162616,22 L20.5172893,13.3989724 L17.6010276,10.4827107 L9,19.0837384 Z M22.7725316,11.14373 C23.0758228,10.8404388 23.0758228,10.3505069 22.7725316,10.0472157 L20.9527843,8.22746841 C20.6494931,7.9241772 20.1595612,7.9241772 19.85627,8.22746841 L18.4331343,9.65060408 L21.3493959,12.5668657 L22.7725316,11.14373 Z',
    icon: 'create'
  },
  text: {
    path:
      'M21.6,8 L10.4,8 C9.63,8 9.007,8.63 9.007,9.4 L9,22 L11.8,19.2 L21.6,19.2 C22.37,19.2 23,18.57 23,17.8 L23,9.4 C23,8.63 22.37,8 21.6,8 Z M20.2,16.4 L11.8,16.4 L11.8,15 L20.2,15 L20.2,16.4 Z M20.2,14.3 L11.8,14.3 L11.8,12.9 L20.2,12.9 L20.2,14.3 Z M20.2,12.2 L11.8,12.2 L11.8,10.8 L20.2,10.8 L20.2,12.2 Z',
    icon: 'message'
  },
  vin: {
    path:
      'M11.8333333,27 L13.5,27 L13.5,25.3333333 L11.8333333,25.3333333 L11.8333333,27 Z M15.1666667,27 L16.8333333,27 L16.8333333,25.3333333 L15.1666667,25.3333333 L15.1666667,27 Z M18.5,27 L20.1666667,27 L20.1666667,25.3333333 L18.5,25.3333333 L18.5,27 Z M19.3333333,7.00833333 L12.6666667,7 C11.75,7 11,7.75 11,8.66666667 L11,22 C11,22.9166667 11.75,23.6666667 12.6666667,23.6666667 L19.3333333,23.6666667 C20.25,23.6666667 21,22.9166667 21,22 L21,8.66666667 C21,7.75 20.25,7.00833333 19.3333333,7.00833333 Z M19.3333333,20.3333333 L12.6666667,20.3333333 L12.6666667,10.3333333 L19.3333333,10.3333333 L19.3333333,20.3333333 Z',
    icon: 'settings_cell'
  }
}
