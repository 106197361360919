// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".no-padding-spinner .el-loading-spinner {\n  margin-top: 0px;\n  top: 0;\n}\n.no-padding-spinner .item .el-badge__content {\n  height: 22px;\n}", "",{"version":3,"sources":["/app/src/components/Vendors/Profile/Sidebar.vue"],"names":[],"mappings":"AA0WE;EACE,eAAA;EACA,MAAA;AA9VJ;AAiWI;EACE,YAAA;AA/VN","file":"Sidebar.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.no-padding-spinner .el-loading-spinner {\n  margin-top: 0px;\n  top: 0;\n}\n.no-padding-spinner .item .el-badge__content {\n  height: 22px;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
