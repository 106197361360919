<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input @keyup.enter.native="find" placeholder="Job Id" v-model="jobId"></el-input>
      </el-col>
      <el-col :span="4">
        <el-button plain @click="find">Find</el-button>
      </el-col>
      <el-col :span="14">
        <loader v-if="loading" />
        <span v-else-if="message">{{message}}</span>
        <div v-else-if="shopData.name && shopData.id" style="color:blue">
          {{shopData.name}} ({{shopData.id}})
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Loader from '../../Helpers/Loader'
export default {
  components: {
    Loader
  },
  data() {
    return {
      jobId: null,
      loading: false,
      shopData: {},
      message: null
    }
  },
  methods: {
    find() {
      this.message = null
      this.loading = true
      this.$unum.job().get(`/data/shop/${this.jobId}`).then(({ data }) => {
        if (data.name && data.id) {
          this.shopData = data
        } else {
          this.message = data
        }
        this.loading = false
      }).catch(() => {
        alert('Error. See network reponse.')
        this.loading = false
      })
    }
  }
}
</script>
