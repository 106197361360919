import Api from '@/services/Api'

const get = async ({ page, limit, shopId, archived }) => {
  const { data } = await Api.get('/api/leads', {
    params: { page, limit, shopId, archived }
  })
  return data
}

const startJob = async id => {
  const { data } = await Api.post(`/api/leads/${id}/startJob`)
  return data
}

const setArchived = ({ id, value }) => Api.post(`/api/leads/${id}/setArchived`, { value })

const remove = id => Api.delete(`/api/leads/${id}`)

const getAttachments = async id => {
  const { data } = await Api.get(`/api/leads/${id}/attachments`)
  return data
}

export const leadRepository = Object.freeze({
  get,
  startJob,
  setArchived,
  remove,
  getAttachments
})
