// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".kit-select .el-input-group__append {\n  padding: 0 4px 0 8px;\n}\n.kit-select .el-input-group--append {\n  width: 100%;\n  /* width: 125px; */\n}\n.kit-select .el-input-group--append .el-input__inner {\n  text-align: right;\n  padding: 0 4px 0 0;\n}\n.kit-select-type-button {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}", "",{"version":3,"sources":["/app/src/components/Helpers/InputWithLabel/KitSelect/index.vue"],"names":[],"mappings":"AA8FE;EACE,oBAAA;AAlFJ;AAoFE;EACE,WAAA;EACA,kBAAA;AAlFJ;AAmFI;EACE,iBAAA;EACA,kBAAA;AAjFN;AAqFA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,mBAAA;AAlFF","file":"index.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.kit-select .el-input-group__append {\n  padding: 0 4px 0 8px;\n}\n.kit-select .el-input-group--append {\n  width: 100%;\n  /* width: 125px; */\n}\n.kit-select .el-input-group--append .el-input__inner {\n  text-align: right;\n  padding: 0 4px 0 0;\n}\n\n.kit-select-type-button {\n  display: flex;\n  align-items: center;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
