var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "scheduler-job-card-list": true,
        "row-view": _vm.isRowView,
        "no-drop": !_vm.enabled
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "job-list-card-header",
          class: {
            "min-height": _vm.minHeight
          }
        },
        [
          _vm.route
            ? _c("scheduler-tech-header", { attrs: { route: _vm.route } })
            : _c("scheduler-job-list-filter")
        ],
        1
      ),
      _c(
        "draggable",
        {
          staticClass: "job-card-list-container",
          attrs: { options: _vm.draggableOptions },
          model: {
            value: _vm.entriesList,
            callback: function($$v) {
              _vm.entriesList = $$v
            },
            expression: "entriesList"
          }
        },
        _vm._l(_vm.entriesList, function(item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "card-wrapper",
              on: {
                dragstart: function($event) {
                  return _vm.itemDrag(item)
                },
                dragend: function($event) {
                  return _vm.itemDrag()
                }
              }
            },
            [
              item.job
                ? _c("scheduler-job-card", {
                    attrs: {
                      job: item.job,
                      routeEntry: item,
                      color: _vm.color(item),
                      cardStyle: _vm.cardStyle(item),
                      noDate: !!_vm.route
                    }
                  })
                : _c("scheduler-generic-card", {
                    attrs: {
                      color: _vm.color(item),
                      cardStyle: _vm.cardStyle(item, true),
                      item: item
                    }
                  })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }