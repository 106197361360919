var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "Ticket Details",
        width: "750px",
        "destroy-on-close": "",
        top: "24px",
        "custom-class": "support-detail-dialog",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      !_vm.loading
        ? _c("corner-ribbon", {
            attrs: {
              hex: _vm.statusColor(_vm.data.status),
              label: _vm.data.status,
              position: "left"
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _vm.data.support_ticket_attachments &&
          _vm.data.support_ticket_attachments.length
            ? _c("div", { staticStyle: { right: "10px" } })
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("gb-data-row", {
                    attrs: { label: "Id", value: _vm.data.zoho_ticket_number }
                  })
                ],
                1
              ),
              _vm.data.support_ticket_attachments &&
              _vm.data.support_ticket_attachments.length
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    _vm._l(_vm.data.support_ticket_attachments, function(
                      attachment,
                      index
                    ) {
                      return _c(
                        "div",
                        { key: index, staticClass: "job-documents" },
                        [
                          _c(
                            "div",
                            { staticClass: "document-list-item" },
                            [
                              _c(
                                "document-tag",
                                {
                                  attrs: {
                                    doc: Object.assign({}, _vm.doc, {
                                      tag: attachment.name
                                    })
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.openDocPreview(
                                        Object.assign({}, attachment, {
                                          document_name: attachment.name,
                                          document_type: "support"
                                        })
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(attachment.name))]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ],
            1
          ),
          _c("gb-data-row", {
            attrs: { label: "Current Status", value: _vm.data.status }
          }),
          _c("gb-data-row", {
            attrs: {
              label: "Date",
              type: "date",
              value: _vm.date(_vm.data.created)
            }
          }),
          _c("gb-data-row", {
            attrs: { label: "Subject", value: _vm.data.subject }
          }),
          _c("gb-data-row", {
            attrs: { label: "Description", value: _vm.data.description }
          }),
          _vm.data.resolution_date
            ? _c("gb-data-row", {
                attrs: {
                  label: "Resolved on",
                  value: _vm.date(_vm.data.resolution_date)
                }
              })
            : _vm._e(),
          _vm.data.resolution
            ? _c("gb-data-row", {
                attrs: { label: "Resolution", value: _vm.data.resolution }
              })
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
              on: {
                click: function($event) {
                  _vm.showComment = !_vm.showComment
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.showComment ? "Close" : "Add") +
                  " new comment "
              )
            ]
          ),
          _vm.showComment
            ? _c("comment-new", {
                attrs: { ticketId: _vm.data.zoho_ticket_id },
                on: { addedComment: _vm.addedComment }
              })
            : _vm._e(),
          _vm.data.support_ticket_events &&
          _vm.data.support_ticket_events.length
            ? _c(
                "span",
                [
                  _c("el-divider"),
                  _c("comment-card", {
                    attrs: { data: _vm.data.support_ticket_events }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("el-row", [
            _c(
              "div",
              { staticClass: "float-right" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.visible = false
                      }
                    }
                  },
                  [_vm._v("Close")]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("file-preview-dialog", {
        attrs: { doc: _vm.preview, noDelete: true },
        model: {
          value: _vm.previewDialog,
          callback: function($$v) {
            _vm.previewDialog = $$v
          },
          expression: "previewDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }