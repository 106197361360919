<template>
  <el-col>
    <gb-card :title="schema.title" :mdi="schema.icon" class="glaxis" :hideHeader="hideHeader">
      <template slot="title">
        <el-tooltip v-if="schema.notes"
          :content="schema.notes">
          <i
            class="material-icons-outline md-18 md-article info-notes-icon"
          />
        </el-tooltip>
      </template>
      <div>
        <div class="subtitle" v-if="schema.subTitleLabel">
          <div>
            <div v-if="subTitleMatchingText && subTitleMatchingText.length > 0" style="color:red; font-size:12px; margin-bottom:5px;font-size:12px;">{{ subTitleMatchingText }}</div>
            <gb-data-rows
              v-if="schema.subTitleType == 'select'"
              :schema="subTitleSchemaFields"
              :data="dataObject"
              @change="onChange"
              @input="onInput"
            />
            <span v-else>
               {{schema.subTitleLabel}} <i v-if="schema.subTitleTooltip && schema.subTitleTooltip.length > 0" v-b-tooltip.hover :title="schema.subTitleTooltip" class="el-icon-info t-text-xs"></i>
            </span>
          </div>
        </div>
        <el-divider v-if="schema.subTitleLabel"/>
        <div class="consumer-data">
          <el-button v-if="deleteButtonMethod != null"
            class="deleteButton"
            size="mini"
            icon="las la-download"
            type="danger"
            plain
            @click="deleteButtonMethod(deleteIndex)"
          >
          x
          </el-button>

          <gb-data-rows
            :schema="schema.fields"
            :data="dataObject"
            @change="onChange"
            @input="onInput"
          />
        </div>
      </div>
    </gb-card>
  </el-col>
</template>

<script>
export default {
  props: ['schema', 'dataObject', 'deleteButtonMethod', 'deleteIndex', 'hideHeader'],
  data() {
    return {
    }
  },
  computed: {
    subTitleSchemaFields() {
      return [[
        {
          label: this.schema.subTitleLabel,
          tooltip: this.schema.subTitleTooltip,
          id: this.schema.subTitleId,
          componentType: 'inputWithLabel',
          labelKey: 'name',
          disabled: this.schema.subTitleDisabled,
          redIfEmpty: this.schema.subTitleRequired,
          select: {
            showLabelValue: true,
            valueKey: 'value',
            clearable: true,
            options: this.schema.subTitleOptions,
            fixedOptions: true
          },
          span: this.rowSpanThree,
          overrideClearable: false
        }
      ]]
    },
    subTitleMatchingText() {
      if (this.schema.subTitleType === 'select' && this.schema.subTitleMatchText && this.schema.subTitleMatchText.length > 0) {
        for (var i = 0; i < this.schema.subTitleOptions.length; i++) {
          if (this.schema.subTitleOptions[i].value + '' === this.dataObject[this.schema.subTitleId] + '') {
            if (this.schema.subTitleOptions[i].name.toLowerCase() !== this.schema.subTitleMatchText.toLowerCase()) {
              return `* Job Set to: ${this.schema.subTitleMatchText}`
            } else {
              return ''
            }
          }
        }
      } else if (this.schema.subTitleMatchText.length > 0) {
        if (this.schema.subTitleLabel.toLowerCase() !== this.schema.subTitleMatchText.toLowerCase()) {
          return `* Job Set to: ${this.schema.subTitleMatchText}`
        }
      }
      return ''
    }
  },
  beforeDestroy: function () {
  },
  methods: {
    async onChange({ key, value }, col) {
      this.$set(this.dataObject, col.id, value)
    },
    async onInput(value, col) {
      this.$set(this.dataObject, col.id, value)
    }
  }
}
</script>

<style lang="scss" scoped>
.glaxis {
  height: 100%;
  margin-bottom:15px;
  .info-notes {
    color: #333;
  }
  .info-notes-icon {
    color: $royal-purple;
    margin-left: $padding-xs;
  }
  .subtitle {
    font-size: 16px;
    text-transform: uppercase;
  }
  .deleteButton {
    margin-right: 0;
    margin-bottom: 5px;
    margin-left:auto;
    display:block;
  }
}

</style>
