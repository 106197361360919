export const emailShortcuts = [
  {
    label: 'SGC',
    address: 'invoicingfax@safelite.com'
  },
  {
    label: 'SGC-POP',
    address: 'mailbox.sgcpop@safelite.com'
  },
  {
    label: 'SGC-Shop Care',
    address: 'shopcare@sgcnetwork.com'
  },
  {
    label: 'SGC Research',
    address: 'researchfax@safelite.com'
  },
  {
    label: 'Lynx',
    address: 'fieldsupport@lynxservices.com'
  }
]
