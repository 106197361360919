<template>
  <div>
    <el-tag
      class="job-details-tags"
      v-for="tag in tags"
      :key="tag.id"
      :color="tag.color"
      size="small"
      :style="{ color: getBlackOrWhite(tag.color) }"
      :closable='!disabled'
      @close="handleClose(tag)"
    >
      {{ tag.text }}
    </el-tag>
    <el-dropdown v-if="(shopTags || (propJob && propJob.shop && propJob.shop.tags)) && !disabled" @command="addTag">
      <el-button class="tag-button" size="mini"
        ><i class="el-icon-plus" /> ADD</el-button
      >
      <el-dropdown-menu slot="dropdown" class="tag-dropdown">
        <el-dropdown-item
          v-for="tag in dropdownTags"
          :key="tag.id"
          :command="tag"
        >
          <el-tag class="job-details-tags" :color="tag.color" size="small">
            <span :style="{ color: getBlackOrWhite(tag.color) }">
              {{ tag.text }}
            </span>
          </el-tag>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { sync, call, get } from 'vuex-pathify'
import * as R from 'ramda'
import { getBlackOrWhite } from '@/scripts/helpers/utilities'
import actions from '@/store/modules/scheduler_v3/types'

export default {
  props: ['propJob', 'disabled'],
  computed: {
    job: sync('sch_v3/jobDetails'),
    tags() {
      if (this.propJob) {
        return R.propOr([], 'jobtags')(this.propJob)
      }
      return R.propOr([], 'jobtags')(this.job)
    },
    shopTags:get('sch_v3/jobDetails@shop.tags'),
    dropdownTags() {
      if (this.propJob) return R.without(this.tags)(this.propJob.shop.tags || [])
      return R.without(this.tags)(this.shopTags || [])
    }
  },
  methods: {
    update: call(actions.updateJobDetailsValue),
    getBlackOrWhite(color) {
      return getBlackOrWhite(color) || '#000'
    },
    handleClose(tag) {
      this.update({
        key: 'jobtags',
        value: R.without([tag])(this.tags),
        id: this.job.id
      })
    },
    addTag(tag) {
      this.update({
        key: 'jobtags',
        value: [...this.tags, tag],
        id: this.job.id
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/JobDetailsTags.scss';
</style>

<style lang="scss">
.job-details-tags {
  &.el-tag {
    .el-tag__close {
      color: currentColor;
      font-size: $font-xl;
      &:hover {
        background: none;
      }
    }
  }
}
.tag-dropdown {
  max-height: 400px;
  overflow-y: auto;
}
</style>
