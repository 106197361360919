<template>
  <div class="notification-wrapper d-flex align-items-center">
    <div class="notification-wrapper-content d-flex flex-column">
      <notification :data="item" />

      <el-tooltip
        class="box-item"
        effect="light"
        :content="'Archive notification'"
        placement="top"
        v-if="!item.is_read"
      >
        <el-button
          icon="el-icon-check"
          class="button-read"
          @click="readOne(item)"
        />
      </el-tooltip>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Notification from './Notification';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions('notifications', ['readOne']),
  },

  components: {
    Notification
  }

}
</script>

<style lang="scss">
.notification-wrapper-content {
  .button-read {
    position: absolute;
    right: 8px;
    top: 24px;

    // fix for cases when browser dev tools are opened to the sides
    width: 26px !important;

    padding: 6px;
    background: #DDF2FF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    border: none;

    .el-icon-check {
      color: #0075E4;
      font-weight: 900;
    }

    &:hover {
      background: #bae3fd;
    }
  }
}
</style>

<style lang="scss" scoped>
.notification-wrapper {
  padding: 8px;

  width: 100%;
}

.notification-wrapper-content {
  width: 100%;
  position: relative;
  z-index: 1000;

  padding: 16px;

  box-shadow: 0px 1px 3px rgb(0 0 0 / 10%);
  border-radius: 8px;
  background: white;
}

</style>>