import { make } from 'vuex-pathify'
import User from '@/scripts/objects/user'
import templates from './templates'
import emailTemplates from './emailTemplates'
import GbFormTemplates from './GbFormTemplates'
import jobConfirmation from './job-confirmation'
import jobInvoice from './job-invoice'
import jobEmail from './job-email'
import jobPayment from './job-payment'
import jobSignature from './job-signature'
import ad from './ad'
import TwilioTextMessageThread from '@/scripts/objects/twilio_text_message_thread'
import tssInvite from './tss-invite'

const state = {
  visible: false,
  selectedThread: {},
  newMessageThreadId: null,
  unreadThreadsCount: 0,
  undeliveredMessage: null
}

const mutations = make.mutations(state)

const actions = {
  async open ({ commit, rootState }) {
    commit('SET_VISIBLE', true)
    if (rootState.user.has_unread_text_message !== 0) {
      await User.hasUnreadTextMessage({ hasUnreadTextMessage: 0 })
    }
    commit('hasUnreadTextMessage', 0, { root: true })
  },

  close ({ commit }) {
    commit('SET_VISIBLE', false)
  },

  setSelectedThread ({ commit }, payload) {
    commit('SET_SELECTED_THREAD', payload)
  },

  setNewMessageThreadId ({ commit }, threadId) {
    commit('SET_NEW_MESSAGE_THREAD_ID', threadId)
  },

  async getUnreadThreadsCount ({ commit }) {
    const { total } = await TwilioTextMessageThread.getUnreadThreadsCount()
    commit('SET_UNREAD_THREADS_COUNT', total)
  },

  setUndeliveredMessage ({ commit }, payload) {
    commit('SET_UNDELIVERED_MESSAGE', payload)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    templates,
    emailTemplates,
    GbFormTemplates,
    jobConfirmation,
    jobInvoice,
    jobEmail,
    jobPayment,
    jobSignature,
    ad,
    tssInvite
  }
}
