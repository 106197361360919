var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "job-details-commission" },
    [
      _c(
        "el-input",
        {
          staticClass: "job-details-commission-input",
          attrs: { disabled: _vm.disabled },
          model: {
            value: _vm.number,
            callback: function($$v) {
              _vm.number = $$v
            },
            expression: "number"
          }
        },
        [
          _vm.showDollarValue
            ? _c(
                "div",
                {
                  staticClass: "comm-suffix",
                  attrs: { slot: "prefix" },
                  slot: "prefix"
                },
                [_vm._v(" $" + _vm._s(_vm.dollarValue) + " ")]
              )
            : _vm._e(),
          _c(
            "template",
            { slot: "append" },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.type,
                    callback: function($$v) {
                      _vm.type = $$v
                    },
                    expression: "type"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "$", value: "flat" } }),
                  _c("el-option", {
                    attrs: { label: "% Total", value: "percent" }
                  }),
                  _c("el-option", {
                    attrs: { label: "% Subtotal", value: "percent_subtotal" }
                  }),
                  _c("el-option", {
                    attrs: { label: "% Margin", value: "percent_margin" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm.unsaved
        ? _c(
            "div",
            { staticClass: "comm-save", on: { click: _vm.saveValues } },
            [_c("v-icon", { attrs: { name: "save" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }