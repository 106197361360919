import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class UserRecentJob extends ObjectTracker {
  id = -1
  created = null
  job_id = null
  user_id = null

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static createUserRecentJob (recentJobObj) {
    return Api.post(`/api/userRecentJob/create`, recentJobObj)
  }

  static updateUserRecentJobsCount (updateObj) {
    return Api.post(`api/userRecentJob/update/userJobCount`, updateObj)
  }

  static getUserRecentJobs (userId, callback) {
    Api({ url: `/api/userRecentJobs/${userId}` }).then(res => {
      callback(res)
    })
  }

  static getUserRecentJobsConsumer (userId, callback) {
    Api({ url: `/api/userRecentJobsConsumer/${userId}` }).then(res => {
      callback(res)
    })
  }
}
