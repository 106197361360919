<template>
  <el-popover trigger="manual" placement="left" v-model="editing">
    <div :style="{ width: '500px' }">
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="installAddressLine1">
            Address
            <span v-if="!onInstallLocation || !install_location_id">
              |
              <a href="#" @click.prevent="onInstallLocation = true">
                Use saved install location
              </a>
            </span>
          </label>
          <address-input
            full
            v-model="address"
            :disabled="onInstallLocation || !!install_location_id"
          />
        </div>
      </div>
      <div class="form-row" v-if="onInstallLocation || install_location_id">
        <div class="form-group col-md-12">
          <label for="installLocation">
            Install location
            <a
              href="#"
              @click.prevent="
                $root.$emit('bv::show::modal', 'installLocationModal')
              "
            >
              <span v-if="install_location_id">(EDIT)</span>
              <span v-else>(ADD +)</span>
            </a>
            <a
              href="#"
              @click.prevent="
                onInstallLocation = false
                install_location = null
                address = null
              "
              :style="{ marginLeft: '15px' }"
            >
              Remove
            </a>
          </label>
          <install-location
            v-model="install_location_id"
            :shopId="shopId"
            @update-address="address = $event"
          />
        </div>
      </div>
      <div :style="{ textAlign: 'right', marginTop: '8px' }">
        <el-button size="small" @click="editing = false">
          Cancel
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="
            $emit('on-save')
            editing = false
          "
        >
          Save
        </el-button>
      </div>
    </div>
    <div
      slot="reference"
      @click="editing = true"
      :style="{ marginLeft: '16px', cursor: 'pointer' }"
    >
      <i class="fa fa-edit" />
    </div>
  </el-popover>
</template>

<script>
import InstallLocation from '../../JobPage/InstallLocation/Index'

export default {
  props: ['value', 'shopId'],
  data() {
    return {
      onInstallLocation: false,
      editing: false
    }
  },
  computed: {
    address: {
      get() {
        return this.value.address
      },
      set(value) {
        this.$emit('input', { ...this.value, address: value })
      }
    },
    install_location_id: {
      get() {
        return this.value.install_location_id
      },
      set(install_location_id) {
        setTimeout(() => {
          this.$emit('input', { ...this.value, install_location_id })
        }, 100)
      }
    },
    install_location: {
      get() {
        return this.value.install_location
      },
      set(install_location) {
        setTimeout(() => {
          this.$emit('input', { ...this.value, install_location })
        }, 100)
      }
    }
  },
  components: { InstallLocation }
}
</script>
