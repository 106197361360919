<template>
  <b-modal
    @show="dialogAboutToAppear"
    no-close-on-backdrop no-close-on-esc
    v-bind:title='title'
    id='globalNotificationModal'
    header-bg-variant='primary'
    header-text-variant='light'
    size="lg">
    <div class='row'>
      <div class='form-group col'>
        {{message}}
      </div>
    </div>
    <div slot='modal-footer' class='w-100'>
      <div v-if='showDoNotShowAgainCheckbox' class='float-left'>
        <b-form-checkbox
          id='doNotShowAgainCheckbox'
          v-model='doNotShowAgain'
          :value='true'
          :unchecked-value='false'
          >Do not show this message again</b-form-checkbox>
      </div>
      <div class='float-right'>
        <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="okayClicked">Okay</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { NotificationHandler } from './notification_handler'
import Alert from '@/scripts/objects/alert'

export default {
  props: [],
  data: function () {
    return {
      title: '',
      message: '',
      type: '',
      timer: '',
      id: '',
      show_always: '',
      doNotShowAgain: false,
      notificationArray: []
    }
  },
  beforeDestroy: function () {
    this.$root.$off('glassbillerNotification', this.glassbillerNotificationListener)
    this.destroyEventListeners(this)
  },
  created () {
    this.$root.$on('glassbillerNotification', this.glassbillerNotificationListener)
    this.intializeEventListeners(this)
  },
  computed: {
    showDoNotShowAgainCheckbox: function () {
      return this.show_always === 0
    }
  },
  methods: {
    dialogAboutToAppear: function () {
      this.doNotShowAgain = false
    },
    okayClicked: function () {
      if (this.showDoNotShowAgainCheckbox) {
        if (this.doNotShowAgain) {
          // call to the db to add user to the
          this.notificationArray[0].addDoNotShow(res => {
            
          })
        }
      }
      this.$root.$emit('bv::hide::modal', 'globalNotificationModal')
      this.notificationArray.shift()
      if (this.notificationArray.length > 0) {
        this.triggerNotification(this.notificationArray[0])
      }
    },
    glassbillerNotificationListener (notificationObject) {
      for (var i = 0; i < notificationObject.length; ++i) {
        var tempObj = new Alert(notificationObject[i])
        this.notificationArray.push(tempObj)
      }

      if (this.notificationArray.length > 0) {
        this.triggerNotification(this.notificationArray[0])
      }
    },
    triggerNotification (notificationObj) {
      this.title = notificationObj.title
      this.message = notificationObj.message
      this.id = notificationObj.id
      this.show_always = notificationObj.always_show
      this.timer = notificationObj.timer
      this.$root.$emit('bv::show::modal', 'globalNotificationModal')
    }
  },
  mixins: [NotificationHandler]
}
</script>

<style>

</style>
