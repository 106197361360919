<template>
  <div>
    <b-modal
      title="QuickBooks Export"
      header-bg-variant='primary'
      header-text-variant='light'
      size="lg"
      @show="dialogAboutToAppear"
      @shown="dialogAppeared"
      id="quickBooksExportModal"
      hide-footer>
      <div class="row mb-2">
        <div class="col-sm-12 col-md-6 col-xl-4 col-lg-4">
          <label for="#">Shop</label>
          <Multiselect
            placeholder="Select Shop..."
            :options="shopsForQuickBooks"
            label="name"
            :show-labels="false"
            v-model="selectedShop">
          </Multiselect>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-4 col-lg-4">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <label for="#">From Date</label>
              <DatePickerShortcut :value.sync='fromDate' :displayFormat='"yyyy-MM-dd"' :saveFormat='"yyyy-MM-dd"'/>
            </div>
            <div class="col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <label for="#">To Date</label>
              <DatePickerShortcut :value.sync='toDate' :displayFormat='"yyyy-MM-dd"' :saveFormat='"yyyy-MM-dd"'/>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-2 col-lg-2">
          <label for="#">QuickBooks Type</label>
          <select v-model="qbExportType" style="height:40px;" :class="{'form-control':true, 'form-control-sm':true}">
            <option v-show="selectedShop && selectedShop.qbo_refresh_token && selectedShop.qbo_refresh_token.length > 0" value="qbOnline">QB Online</option>
            <option value="qbDesktop18">QB Desktop 18 and Less</option>
            <option value="qbDesktop19">QB Desktop 19 and Up</option>
          </select>
        </div>
        <div v-show="qbExportType == 'qbOnline'" class="col-sm-12 col-md-6 col-xl-2 col-lg-2">
          <label for="#">Export Type</label>
          <select v-model="tableType" style="height:40px;" :class="{'form-control':true, 'form-control-sm':true}">
            <option value="invoices">Invoices</option>
            <option value="payments">Payments</option>
          </select>
        </div>

        <div v-show="selectedShop && (!selectedShop.qbo_refresh_token || selectedShop.qbo_refresh_token.length == 0)" class="col-sm-12 col-md-6 col-xl-2 col-lg-2">
          <label for="#">Connect To QBO?</label><br>
          <img @click="connectToQBOClicked()" ref="qboButton" src="../../assets/img/intuit/C2QB_white_btn_lg_default.png" style="width:100%; max-width:220px;cursor: pointer;" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-12 col-md-6 col-xl-7 col-lg-7">
          <CheckBoxLabel label="Show Only Unexported" :value.sync="showOnlyUnexported"/>
          <CheckBoxLabel v-show="selectedShop && selectedShop.qbo_refresh_token && selectedShop.qbo_refresh_token.length > 0" label="Auto Export Invoices To QBO?" :value.sync="autoExportInvoicesToQBO"/>
          <CheckBoxLabel v-show="selectedShop && selectedShop.qbo_refresh_token && selectedShop.qbo_refresh_token.length > 0" label="Auto Export Payments To QBO?" :value.sync="autoExportPaymentsToQBO"/>
          <el-button
            v-show="selectedShop &&
              selectedShop.qbo_refresh_token &&
              selectedShop.qbo_refresh_token.length > 0 &&
              (adminCheck() || accountManagerCheck())"
            type="primary"
            size="mini"
            round
            @click="onOpenQboMapping(selectedShop.id)"
          >
            QBO Mapping
          </el-button>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-3 col-lg-3">
          <span v-show="numberSelectedObjects > 0"><span style="font-weight: bold"># Selected:</span> {{numberSelectedObjects}} <span style="font-weight: bold">Selected Total:</span> {{selectedObjectsTotal}}</span>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-2 col-lg-2 text-right">
          <input type="text" class="mt-2 form-control form-control-sm text-right" v-model="searchAllFilter" placeholder="Search All..."/>
        </div>
      </div>
      <div class="tableHolder row">
        <div class="form-group col-12 table-responsive">
          <b-table v-if="tableType === 'invoices'"
              ref="qbInvoicesTable"
              stacked="md"
              :items="invoiceTableProvider"
              :fields="invoiceFields"
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by.sync="invoiceSortBy"
              :sort-desc.sync="invoiceSortDesc"
              :busy.sync="isBusy"
              :show-empty="true"
              :empty-text="isBusy ? 'Loading, please wait...' : 'There are no records to show'"
              :empty-filtered-text="isBusy ? 'Loading, please wait...' : 'There are no records matching your request'"
              class="text-left"
              >
            <template slot="HEAD_select" slot-scope="row">
              <input v-on:click.stop v-on:keydown.space.stop type="checkbox" :data-key="row.id"
              :checked="exportAllInvoiceRows && Object.keys(selectedExportInvoiceRows).length === 0" @change="exportAllInvoiceRows = $event.target.checked;exportAllInvoiceRowsChanged()"
              />
            </template>
            <template slot="HEAD_invoiceNumber" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="invoiceInvoiceNumberFilter" placeholder="Invoice #" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_customer" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="invoiceCustomerFilter" placeholder="Customer" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_memo" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="invoiceMemoFilter" placeholder="Memo" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_referralNumber" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="invoiceReferralNumberFilter" placeholder="Referral Number" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_invoiceTotal" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="invoiceInvoiceTotalFilter" placeholder="Invoice Total" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_invoiceDate" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="invoiceInvoiceDateFilter" placeholder="Invoice Date" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_lastExported" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="invoiceLastExportedFilter" placeholder="Last Exported" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_errors" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="invoiceErrorsFilter" placeholder="Errors" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_exportDetails" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="invoiceExportDetailsFilter" placeholder="Export Details" class="form-control form-control-sm"/>
            </template>

            <template slot="select" slot-scope="row">
              <input @click.stop type="checkbox"
              :checked="(exportAllInvoiceRows && !(row.item.invoiceNumber in selectedExportInvoiceRows)) || (!exportAllInvoiceRows && (row.item.invoiceNumber in selectedExportInvoiceRows))"
              @change="exportInvoiceRowCheckboxChecked($event.target.checked, row.item.invoiceNumber, row.item.invoiceTotal)"/>
            </template>
            <template slot="invoiceNumber" slot-scope="row">
              <a :href="'/jobs/' + row.value" target='_blank'>{{ row.value }}</a>
            </template>

            <template slot="errors" slot-scope="row">
              <span :style="{'color': row.value === 'Yes' ? 'red' : 'green'}">{{ row.value }}</span>
            </template>
            <template slot="exportDetails" slot-scope="row">
              <span :style="{'color': row.item.errors === 'Yes' ? 'red' : 'green'}">{{ row.value }}</span>
            </template>
          </b-table>
          <b-table v-if="tableType === 'payments'"
              ref="qbPaymentsTable"
              stacked="md"
              :items="paymentTableProvider"
              :fields="paymentFields"
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by.sync="paymentsSortBy"
              :sort-desc.sync="paymentsSortDesc"
              :busy.sync="isBusy"
              :show-empty="true"
              :empty-text="isBusy ? 'Loading, please wait...' : 'There are no records to show'"
              :empty-filtered-text="isBusy ? 'Loading, please wait...' : 'There are no records matching your request'"
              class="text-left"
              >
            <template slot="HEAD_select" slot-scope="row">
              <input v-on:click.stop v-on:keydown.space.stop type="checkbox" :data-key="row.id"
              :checked="exportAllPaymentRows && Object.keys(selectedExportPaymentRows).length === 0" @change="exportAllPaymentRows = $event.target.checked;exportAllPaymentRowsChanged()"
              />
            </template>
            <template slot="HEAD_paymentId" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentPaymentIdFilter" placeholder="Payment Id" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_invoiceNumber" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentInvoiceNumberFilter" placeholder="Invoice #" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_customer" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentCustomerFilter" placeholder="Customer" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_type" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentTypeFilter" placeholder="Type" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_xActionType" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentXActionTypeFilter" placeholder="X-Action Type" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_xActionNumber" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentXActionNumberFilter" placeholder="X-Action Number" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_amount" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentAmountFilter" placeholder="Amount" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_memo" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentMemoFilter" placeholder="Memo" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_paymentDate" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentPaymentDateFilter" placeholder="Payment Date" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_invoiceExported" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentInvoiceExportedFilter" placeholder="Invoice Exported" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_lastExported" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentLastExportedFilter" placeholder="Last Exported" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_errors" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentErrorsFilter" placeholder="Errors" class="form-control form-control-sm"/>
            </template>
            <template slot="HEAD_exportDetails" slot-scope="row">
              <input :data-id="row.id" v-on:click.stop v-on:keydown.space.stop type="text" v-model="paymentExportDetailsFilter" placeholder="Export Details" class="form-control form-control-sm"/>
            </template>
            <template slot="select" slot-scope="row">
              <input @click.stop type="checkbox"
              :checked="(exportAllPaymentRows && !(row.item.paymentId in selectedExportPaymentRows)) ||
                (!exportAllPaymentRows && (row.item.paymentId in selectedExportPaymentRows))"
              @change="exportPaymentRowCheckboxChecked($event.target.checked, row.item.paymentId, row.item.amount)"/>
            </template>
            <template slot="invoiceNumber" slot-scope="row">
              <a :href="'/jobs/' + row.value" target='_blank'>{{ row.value }}</a>
            </template>

            <template slot="errors" slot-scope="row">
              <span :style="{'color': row.value === 'Yes' ? 'red' : 'green'}">{{ row.value }}</span>
            </template>
            <template slot="exportDetails" slot-scope="row">
              <span :style="{'color': row.item.errors === 'Yes' ? 'red' : 'green'}">{{ row.value }}</span>
            </template>
          </b-table>
        </div>
      </div>
      <b-row>
        <b-col md="6">
          <span>{{ tableResultsText }}</span>
        </b-col>
        <b-col md="6">
          <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" align="right"/>
        </b-col>
      </b-row>
      <b-row class='modal-footer'>
        <div class='float-right'>
          <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="exportButtonPressed">  <img v-show="exporting" style="width:18px;height:18px;margin-right:6px;" src='./../../assets/img/spinner.gif'>  {{ exporting ? 'Starting Export...' : 'Export' }}</button>&nbsp;&nbsp;
          <button type='button' class='btn btn-secondary' variant="primary" @click="cancelButtonPressed">Cancel</button>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import faTag from '@fortawesome/fontawesome-free-solid/faTag'
import Job from '@/scripts/objects/job'
import Payment from '@/scripts/objects/payment'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import Multiselect from 'vue-multiselect'
import DatePickerShortcut from '@/components/Helpers/DatePickerShortcut.vue'
import CheckBoxLabel from '@/components/Helpers/CheckBoxLabel'
import Shop from '@/scripts/objects/shop'
import { mapActions } from 'pinia'
import { useQboStore } from '@/components/modals/QuickBooksAccountMappingDialog/qbo.store.js'

import $ from 'jquery'

export default {
  data () {
    return {
      selectedShop: null,
      invoiceFields: [
        { key: 'select', label: '', sortable: false, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'invoiceNumber', label: 'Invoice #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'customer', label: 'Customer', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'memo', label: 'Memo', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'referralNumber', label: 'Referral Number', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'invoiceTotal', label: 'Invoice Total', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'invoiceDate', label: 'Invoice Date', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'lastExported', label: 'Last Exported', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'errors', label: 'Errors', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'exportDetails', label: 'Export Details', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' }
      ],
      paymentFields: [
        { key: 'select', label: '', sortable: false, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'paymentId', label: 'Payment Id', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'invoiceNumber', label: 'Invoice #', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'customer', label: 'Customer', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'type', label: 'Type', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'xActionType', label: 'X-Action Type', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'xActionNumber', label: 'X-Action Number', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'amount', label: 'Amount', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'memo', label: 'Memo', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'paymentDate', label: 'Payment Date', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'invoiceExported', label: 'Invoice Exported', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'lastExported', label: 'Last Exported', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'errors', label: 'Errors', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' },
        { key: 'exportDetails', label: 'Export Details', sortable: true, thClass: 'leftTh', tdClass: 'leftTd' }
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [10],

      tableType: 'invoices',
      isBusy: false,
      delayTimer: null,

      invoiceSortBy: 'invoiceNumber',
      invoiceSortDesc: false,

      paymentsSortBy: 'invoiceNumber',
      paymentsSortDesc: false,

      invoiceInvoiceNumberFilter: '',
      invoiceCustomerFilter: '',
      invoiceMemoFilter: '',
      invoiceReferralNumberFilter: '',
      invoiceInvoiceTotalFilter: '',
      invoiceInvoiceDateFilter: '',
      invoiceLastExportedFilter: '',
      invoiceErrorsFilter: '',
      invoiceExportDetailsFilter: '',

      paymentPaymentIdFilter: '',
      paymentInvoiceNumberFilter: '',
      paymentCustomerFilter: '',
      paymentTypeFilter: '',
      paymentXActionTypeFilter: '',
      paymentXActionNumberFilter: '',
      paymentAmountFilter: '',
      paymentMemoFilter: '',
      paymentPaymentDateFilter: '',
      paymentInvoiceExportedFilter: '',
      paymentLastExportedFilter: '',
      paymentErrorsFilter: '',
      paymentExportDetailsFilter: '',

      invoiceTableLoadCount: 0,
      paymentTableLoadCount: 0,

      fromDate: '',
      toDate: '',
      qbExportType: 'qbOnline',
      showOnlyUnexported: false,

      searchAllFilter: '',

      exportAllInvoiceRows: false,
      selectedExportInvoiceRows: {},
      invoicesTotalAmount: 0,

      exportAllPaymentRows: false,
      selectedExportPaymentRows: {},
      paymentsTotalAmount: 0,

      exporting: false
    }
  },
  mounted () {
    // this.$root.$emit('bv::show::modal', 'quickBooksExportModal')
  },
  watch: {
    selectedShop () {
      this.resetTableVariables()

      if (this.selectedShop && this.selectedShop.last_qb_export_type && this.selectedShop.last_qb_export_type.length > 0) {
        if (this.selectedShop.last_qb_export_type === 'qbd19') {
          this.qbExportType = 'qbDesktop19'
        } else if (this.selectedShop.last_qb_export_type === 'qbo' && this.selectedShop.qbo_refresh_token && this.selectedShop.qbo_refresh_token.length > 0) {
          this.qbExportType = 'qbOnline'
        } else {
          this.qbExportType = 'qbDesktop18'
        }
      } else if (this.selectedShop && this.selectedShop.qbo_refresh_token && this.selectedShop.qbo_refresh_token.length > 0) {
        this.qbExportType = 'qbOnline'
      } else {
        this.qbExportType = 'qbDesktop18'
      }
      this.currentPage = 1
      this.filterResults(true)
    },
    qbExportType () {
      if (this.qbExportType !== 'qbOnline') {
        this.tableType = 'invoices'
      }
    },
    invoiceInvoiceNumberFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    invoiceCustomerFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    invoiceMemoFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    invoiceReferralNumberFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    invoiceInvoiceTotalFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    invoiceInvoiceDateFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    invoiceLastExportedFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    invoiceErrorsFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    invoiceExportDetailsFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentPaymentIdFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentInvoiceNumberFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentCustomerFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentTypeFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentXActionTypeFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentXActionNumberFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentAmountFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentMemoFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentPaymentDateFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentInvoiceExportedFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentLastExportedFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentErrorsFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    paymentExportDetailsFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    fromDate () {
      this.currentPage = 1
      this.filterResults(true)
    },
    toDate () {
      this.currentPage = 1
      this.filterResults(true)
    },
    showOnlyUnexported () {
      this.currentPage = 1
      this.filterResults(true)
    },
    searchAllFilter () {
      this.currentPage = 1
      this.filterResults(true)
    },
    tableType () {
      this.currentPage = 1
      this.filterResults(true)
    }
  },
  computed: {
    shopsForQuickBooks: function () {
      var shops = []
      try {
        // var allowedShopsTemp = [597, 4, 849, 852, 853, 861, 764, 837, 267]
        const tempShops = this.$store.state.shops
        for (var i = 0; i < tempShops.length; i++) {
          // if (allowedShopsTemp.includes(tempShops[i].id)) {
          shops.push(tempShops[i])
          // }
        }
      } catch (err) {}
      return shops
    },
    numberSelectedObjects: function () {
      var selectedObjects = 0
      if (this.tableType === 'invoices') {
        if (this.exportAllInvoiceRows) {
          selectedObjects = this.totalRows
          selectedObjects = selectedObjects - Object.keys(this.selectedExportInvoiceRows).length
        } else {
          selectedObjects = Object.keys(this.selectedExportInvoiceRows).length
        }
      } else if (this.tableType === 'payments') {
        if (this.exportAllPaymentRows) {
          selectedObjects = this.totalRows
          selectedObjects = selectedObjects - Object.keys(this.selectedExportPaymentRows).length
        } else {
          selectedObjects = Object.keys(this.selectedExportPaymentRows).length
        }
      }
      return selectedObjects
    },
    selectedObjectsTotal: function () {
      var selectedTotal = 0
      if (this.tableType === 'invoices') {
        var selectedAmount = 0
        var selectedKeys = Object.keys(this.selectedExportInvoiceRows)
        for (var i = 0; i < selectedKeys.length; i++) {
          selectedAmount += this.selectedExportInvoiceRows[selectedKeys[i]].amount
        }
        if (this.exportAllInvoiceRows) {
          selectedTotal = this.invoicesTotalAmount - selectedAmount
        } else {
          selectedTotal = selectedAmount
        }
      } else if (this.tableType === 'payments') {
        selectedAmount = 0
        selectedKeys = Object.keys(this.selectedExportPaymentRows)
        for (i = 0; i < selectedKeys.length; i++) {
          selectedAmount += this.selectedExportPaymentRows[selectedKeys[i]].amount
        }
        if (this.exportAllPaymentRows) {
          selectedTotal = this.paymentsTotalAmount - selectedAmount
        } else {
          selectedTotal = selectedAmount
        }
      }
      return this.formatCurrency(selectedTotal)
    },
    autoExportInvoicesToQBO: {
      get () {
        return this.selectedShop && this.selectedShop.auto_qb_export_invoices === 1
      },
      set (val) {
        const self = this
        var auto_qb_export_invoices = val ? 1 : 0
        Shop.updateShopAutoQBOExportInvoices(this.selectedShop.id, auto_qb_export_invoices, function (results) {
          self.$root.$emit('runAppSetupCommand')
        }, function (error) {
          console.log('got error', error)
          alert('Something went wrong updating the shop\'s auto export to qbo capability.')
        })
      }
    },
    autoExportPaymentsToQBO: {
      get () {
        return this.selectedShop && this.selectedShop.auto_qb_export_payments === 1
      },
      set (val) {
        const self = this
        var auto_qb_export_payments = val ? 1 : 0
        Shop.updateShopAutoQBOExportPayments(this.selectedShop.id, auto_qb_export_payments, function (results) {
          self.$root.$emit('runAppSetupCommand')
        }, function (error) {
          console.log('got error', error)
          alert('Something went wrong updating the shop\'s auto export to qbo capability.')
        })
      }
    },
    tableResultsText: function () {
      var tableResults = 'Showing'
      if (this.totalRows > 0) {
        var currentFirstResult = ((this.currentPage * this.perPage) - this.perPage) + 1
        var currentLastResult = (this.currentPage * this.perPage)
        if (currentLastResult > this.totalRows) {
          currentLastResult = this.totalRows
        }
        tableResults += ' ' + currentFirstResult + ' to ' + currentLastResult + ' of'
      }
      if (this.tableType === 'invoices') {
        tableResults += ' ' + this.totalRows + ' Invoice' + (this.totalRows === 1 ? '' : 's')
      } else if (this.tableType === 'payments') {
        tableResults += ' ' + this.totalRows + ' Payment' + (this.totalRows === 1 ? '' : 's')
      }
      return tableResults
    },
    faTag () {
      return faTag
    }
  },
  methods: {
    ...mapActions(useQboStore, {
      viewQboMapping: 'openView'
    }),
    onOpenQboMapping(shopId) {
      this.viewQboMapping(shopId)
    },
    connectToQBOClicked: function () {
      this.$store.dispatch('setQBOShopId', this.selectedShop.id)
      this.changeRouteWithCheck('/quickBooksOnlineConnect?request=startConnection')
      this.$root.$emit('bv::hide::modal', 'quickBooksExportModal')
    },
    exportButtonPressed: function () {
      const self = this
      if (self.exporting) {
        return
      }
      if (this.tableType === 'invoices') {
        if (!this.exportAllInvoiceRows && Object.keys(this.selectedExportInvoiceRows).length === 0) {
          alert('Please select an invoice to export.')
          return
        }
        self.exporting = true
        this.performJobInvoicesCall(true, function (results) {
          self.exporting = false
          if (results.exportType === 'qbOnline') {
            if (results.onlineExportStarted === 'yes') {
              alert('Success! Your QuickBooks Online export has started. Depending on how many invoices this is writing, this may take a little while. The "Last Exported" date will change after invoices are written over to QuickBooks and after you refresh the table.')
              self.$root.$emit('runAppSetupCommand')
              self.exportAllInvoiceRows = false
              self.selectedExportInvoiceRows = {}
            } else {
              if (results.errorMessage && results.errorMessage.length > 0) {
                alert('There was an error exporting to QuickBooks Online: ' + results.errorMessage)
              } else {
                alert('Something went wrong exporting to QuickBooks Online.')
              }
            }
          } else {
            if (results.iifUrl && results.iifUrl.length > 0) {
              self.$root.$emit('runAppSetupCommand')

              window.open(results.iifUrl, '_blank')

              self.exportAllInvoiceRows = false
              self.selectedExportInvoiceRows = {}
            } else {
              alert('Something went wrong exporting an iif file for these invoices.')
            }
          }
        }, function (error) {
          self.exporting = false
          alert('Something went wrong exporting these invoices.')
          console.log('got error', error)
          self.isBusy = false
        })
      } else if (this.tableType === 'payments') {
        if (!this.exportAllPaymentRows && Object.keys(this.selectedExportPaymentRows).length === 0) {
          alert('Please select a payment to export.')
          return
        }
        self.exporting = true
        this.performJobPaymentsCall(true, function (results) {
          self.exporting = false
          if (results.onlineExportStarted === 'yes') {
            alert('Success! Your QuickBooks Online export has started. Depending on how many payments this is writing, this may take a little while. The "Last Exported" date will change after payments are written over to QuickBooks and after you refresh the table.')
            self.$root.$emit('runAppSetupCommand')
            self.exportAllPaymentRows = false
            self.selectedExportPaymentRows = {}
          } else {
            if (results.errorMessage && results.errorMessage.length > 0) {
              alert('There was an error exporting to QuickBooks Online: ' + results.errorMessage)
            } else {
              alert('Something went wrong exporting to QuickBooks Online.')
            }
          }
        }, function (error) {
          self.exporting = false
          alert('Something went wrong exporting these payments.')
          console.log('got error', error)
          self.isBusy = false
        })
      }
    },
    cancelButtonPressed: function () {
      this.$root.$emit('bv::hide::modal', 'quickBooksExportModal')
    },
    exportAllInvoiceRowsChanged () {
      this.selectedExportInvoiceRows = {}
    },
    exportAllPaymentRowsChanged () {
      this.selectedExportPaymentRows = {}
    },
    exportInvoiceRowCheckboxChecked (checked, jobId, amount) {
      if ((checked && this.exportAllInvoiceRows) || (!checked && !this.exportAllInvoiceRows)) {
        this.$delete(this.selectedExportInvoiceRows, jobId)
      } else {
        try {
          amount = parseFloat(amount.replace(/[^0-9.]/g, ''))
        } catch (err) {
          amount = 0
        }

        this.$set(this.selectedExportInvoiceRows, jobId, {
          amount: amount
        })
      }
    },
    exportPaymentRowCheckboxChecked (checked, paymentId, amount) {
      if ((checked && this.exportAllPaymentRows) || (!checked && !this.exportAllPaymentRows)) {
        this.$delete(this.selectedExportPaymentRows, paymentId)
      } else {
        try {
          amount = parseFloat(amount.replace(/[^0-9.]/g, ''))
        } catch (err) {
          amount = 0
        }
        this.$set(this.selectedExportPaymentRows, paymentId, {
          amount: amount
        })
      }
    },
    dialogAboutToAppear: function () {
      this.tableType = 'invoices'

      this.exportAllInvoiceRows = false
      this.selectedExportInvoiceRows = {}

      this.exportAllPaymentRows = false
      this.selectedExportPaymentRows = {}
      // var allowedShopsTemp = [597, 4, 849, 852, 853, 861, 764, 837, 267]
      this.resetTableVariables()
      if (this.$store.state.user.shop_id) {
        for (var i = 0; i < this.$store.state.shops.length; i++) {
          // This is what it should be without the test filter: if((this.$store.state.shops[i].id === this.$store.state.user.shop_id )) {
          // if (allowedShopsTemp.includes(this.$store.state.shops[i].id)) {
          this.selectedShop = this.$store.state.shops[i]

          if (this.$store.state.shops[i].id === this.$store.state.user.shop_id) {
            break
          }
          // }
        }
      } else {
        this.selectedShop = null
      }
      var currentDate = new Date()
      var mm = currentDate.getMonth() + 1
      if (mm < 10) { mm = '0' + mm }
      var dd = currentDate.getDate()
      if (dd < 10) { dd = '0' + dd }
      currentDate = currentDate.getFullYear() + '-' + mm + '-' + dd

      this.fromDate = currentDate
      this.toDate = currentDate
    },
    dialogAppeared: function () {
      this.exportAllInvoiceRows = false
      this.selectedExportInvoiceRows = {}
      this.invoicesTotalAmount = 0
      this.exportAllPaymentRows = false
      this.selectedExportPaymentRows = {}
      this.paymentsTotalAmount = 0

      if (this.tableType === 'invoices') {
        this.$refs.qbInvoicesTable.refresh()
      } else if (this.tableType === 'payments') {
        this.$refs.qbPaymentsTable.refresh()
      }
    },
    resetTableVariables: function () {
      this.invoiceInvoiceNumberFilter = ''
      this.invoiceCustomerFilter = ''
      this.invoiceMemoFilter = ''
      this.invoiceReferralNumberFilter = ''
      this.invoiceInvoiceTotalFilter = ''
      this.invoiceInvoiceDateFilter = ''
      this.invoiceLastExportedFilter = ''
      this.invoiceErrorsFilter = ''
      this.invoiceExportDetailsFilter = ''

      this.paymentPaymentIdFilter = ''
      this.paymentInvoiceNumberFilter = ''
      this.paymentCustomerFilter = ''
      this.paymentTypeFilter = ''
      this.paymentXActionTypeFilter = ''
      this.paymentXActionNumberFilter = ''
      this.paymentAmountFilter = ''
      this.paymentMemoFilter = ''
      this.paymentPaymentDateFilter = ''
      this.paymentInvoiceExportedFilter = ''
      this.paymentLastExportedFilter = ''
      this.paymentErrorsFilter = ''
      this.paymentExportDetailsFilter = ''

      this.currentPage = 1
    },
    filterResults: function (resetSelection) {
      const self = this
      clearTimeout(self.delayTimer)
      self.delayTimer = setTimeout(function () {
        self.isBusy = false
        if (self.tableType === 'invoices') {
          if (resetSelection) {
            self.exportAllInvoiceRows = false
            self.selectedExportInvoiceRows = {}
            self.invoicesTotalAmount = 0
          }
          self.$refs.qbInvoicesTable.refresh()
        } else if (self.tableType === 'payments') {
          if (resetSelection) {
            self.exportAllPaymentRows = false
            self.selectedExportPaymentRows = {}
            self.paymentsTotalAmount = 0
          }
          self.$refs.qbPaymentsTable.refresh()
        }
      }, 300)
    },
    performJobInvoicesCall(exportToQB, successFunction, errorFunction) {
      const self = this
      Job.getInvoiceJobsQBTable(self.selectedShop.id, self.currentPage, self.perPage, self.invoiceSortBy, self.invoiceSortDesc, self.fromDate, self.toDate,
        self.invoiceInvoiceNumberFilter, self.invoiceCustomerFilter, self.invoiceMemoFilter, self.invoiceReferralNumberFilter, self.invoiceInvoiceTotalFilter,
        self.invoiceInvoiceDateFilter, self.invoiceLastExportedFilter, self.invoiceErrorsFilter, self.invoiceExportDetailsFilter, self.showOnlyUnexported, self.searchAllFilter,
        exportToQB, self.exportAllInvoiceRows, Object.keys(self.selectedExportInvoiceRows), self.qbExportType, false, false,
        function (results) {
          successFunction(results)
        }, function (error) {
          errorFunction(error)
        })
    },
    performJobPaymentsCall(exportToQB, successFunction, errorFunction) {
      const self = this
      Payment.getPaymentQBTable(self.selectedShop.id, self.currentPage, self.perPage, self.paymentsSortBy, self.paymentsSortDesc,
        self.fromDate, self.toDate, self.paymentPaymentIdFilter, self.paymentInvoiceNumberFilter, self.paymentCustomerFilter, self.paymentTypeFilter,
        self.paymentXActionTypeFilter, self.paymentXActionNumberFilter, self.paymentAmountFilter, self.paymentMemoFilter, self.paymentPaymentDateFilter,
        self.paymentInvoiceExportedFilter, self.paymentLastExportedFilter, self.paymentErrorsFilter, self.paymentExportDetailsFilter, self.showOnlyUnexported, self.searchAllFilter,
        exportToQB, self.exportAllPaymentRows, Object.keys(self.selectedExportPaymentRows), self.qbExportType,
        function (results) {
          successFunction(results)
        }, function (error) {
          errorFunction(error)
        })
    },
    invoiceTableProvider (ctx, callback) {
      if (this.selectedShop === null) {
        this.totalRows = 0
        return
      }
      const self = this
      self.isBusy = true

      setTimeout(function () {
        if (self.selectedShop === null) {
          self.totalRows = 0
          self.isBusy = false
          return
        }

        if (self.invoiceSortBy === null) {
          self.invoiceSortBy = ''
        }
        self.invoiceTableLoadCount++
        var currentLoadCount = self.invoiceTableLoadCount

        self.performJobInvoicesCall(false, function (results) {
          if (currentLoadCount === self.invoiceTableLoadCount) {
            var rows = results.rows
            var items = []
            for (var i = 0; i < rows.length; i++) {
              var row = rows[i]
              items.push({
                invoiceNumber: row.invoiceNumber,
                customer: row.customer,
                memo: row.memo,
                referralNumber: row.referralNumber,
                invoiceTotal: row.invoiceTotal,
                invoiceDate: row.invoiceDate,
                lastExported: row.lastExported,
                errors: row.errors,
                exportDetails: row.exportDetails
              })
            }
            self.totalRows = results.numberOfRowsFound
            self.invoicesTotalAmount = results.totalOfAll
            $('#quickBooksExportModal').scrollTop(0)
            self.isBusy = false
            callback(items)
          } else {
          }
        }, function (error) {
          console.log('got error', error)
          self.isBusy = false
          /* eslint-disable */
          callback([])
          /* eslint-enable */
        })
      }, 300)
      return null
    },
    paymentTableProvider (ctx, callback) {
      if (this.selectedShop === null) {
        this.totalRows = 0
        return
      }
      const self = this
      self.isBusy = true
      setTimeout(function () {
        if (self.selectedShop === null) {
          self.totalRows = 0
          self.isBusy = false
          return
        }

        if (self.paymentsSortBy === null) {
          self.paymentsSortBy = ''
        }

        self.paymentTableLoadCount++
        var currentLoadCount = self.paymentTableLoadCount

        self.performJobPaymentsCall(false, function (results) {
          if (currentLoadCount === self.paymentTableLoadCount) {
            var rows = results.rows
            var items = []
            for (var i = 0; i < rows.length; i++) {
              var row = rows[i]
              items.push({
                paymentId: row.paymentId,
                invoiceNumber: row.invoiceNumber,
                customer: row.customer,
                type: row.type,
                xActionType: row.xActionType,
                xActionNumber: row.xActionNumber,
                amount: row.amount,
                memo: row.memo,
                paymentDate: row.paymentDate,
                invoiceExported: row.invoiceExported,
                lastExported: row.lastExported,
                errors: row.errors,
                exportDetails: row.exportDetails
              })
            }
            self.totalRows = results.numberOfRowsFound
            self.paymentsTotalAmount = results.totalOfAll

            $('#quickBooksExportModal').scrollTop(0)
            self.isBusy = false
            callback(items)
          } else {
          }
        }, function (error) {
          console.log('got error', error)
          self.isBusy = false
          /* eslint-disable */
          callback([])
          /* eslint-enable */
        })
      }, 300)
      return null
    },
    adminCheck () {
      return this.$store.state.user.admin
    },
    accountManagerCheck () {
      return this.$store.state.user.account_manager
    }
  },
  components: {
    Multiselect,
    DatePickerShortcut,
    CheckBoxLabel
  },
  mixins: [UtilityMixin]
}
</script>

<style>
#quickBooksExportModal .modal-lg {
  min-width: 95%;
}

#quickBooksExportModal .el-input__inner {
  height: 40px !important;
  padding-right: 15px;
}

#quickBooksExportModal .el-date-editor {
  width: 100%;
}
</style>

<style scoped>

.multiselect--active {
  z-index: 10;
}

.multiselect__option {
  padding: 0px;
}

.activeTableType {
  background-color: #299fd7;
  color: white;
}

.rejectedDialogActionButton-sm {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 11px;
}
</style>
