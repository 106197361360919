var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.pagedTableData,
            size: "mini",
            "empty-text": "No emails sent for this job"
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c("p", [_vm._v("Subject: " + _vm._s(props.row.subject))]),
                    _c("p", [_vm._v("Message: " + _vm._s(props.row.body))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Sent" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(_vm.humanDateTime(scope.row.sent)))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "destination", label: "To" }
          }),
          !_vm.isGeneric
            ? _c("el-table-column", {
                attrs: { label: "Job Status" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _vm._v(_vm._s(_vm.capitalCase(props.row.job_type)))
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1535469354
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Add'l Docs" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "div",
                      { staticClass: "job-documents" },
                      _vm._l(
                        _vm.getDocConfig(props.row.sent_documents),
                        function(doc, index) {
                          return _c(
                            "div",
                            {
                              key: "emailsent_document-" + index,
                              staticClass: "document-list-item"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "document-tag" },
                                [
                                  doc
                                    ? _c(
                                        "md-label-tag",
                                        {
                                          attrs: {
                                            icon:
                                              doc.icon || "insert_drive_file",
                                            color: doc.color
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.openPreview(doc.docObj)
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(doc.tag) + " ")]
                                      )
                                    : _c(
                                        "md-label-tag",
                                        {
                                          attrs: { icon: "error" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.$message({
                                                type: "error",
                                                message:
                                                  "This document has been deleted from the job and you can no longer view it."
                                              })
                                            }
                                          }
                                        },
                                        [_vm._v(" Doc Deleted ")]
                                      )
                                ],
                                1
                              )
                            ]
                          )
                        }
                      ),
                      0
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Status", width: "115" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "el-popover",
                      { attrs: { trigger: "click" } },
                      [
                        _c(
                          "el-timeline",
                          _vm._l(props.row.email_statuses, function(
                            status,
                            index
                          ) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  timestamp: _vm.formatTimestamp(
                                    status.timestamp
                                  ),
                                  type: _vm.getStatusType(status.event)
                                }
                              },
                              [_vm._v(" " + _vm._s(status.event) + " ")]
                            )
                          }),
                          1
                        ),
                        props.row.email_statuses.length
                          ? _c(
                              "gb-tag",
                              {
                                attrs: { slot: "reference", color: "green" },
                                slot: "reference"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(props.row.email_statuses[0].event) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          layout: "prev, pager, next",
          "page-size": 5,
          total: _vm.history.length,
          "current-page": _vm.page
        },
        on: {
          "update:currentPage": function($event) {
            _vm.page = $event
          },
          "update:current-page": function($event) {
            _vm.page = $event
          }
        }
      }),
      _c("file-preview-dialog", {
        attrs: { doc: _vm.preview, "no-delete": true },
        model: {
          value: _vm.previewDialog,
          callback: function($$v) {
            _vm.previewDialog = $$v
          },
          expression: "previewDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }