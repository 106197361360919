<template>
  <div class="header-v2-user-menu">
    <div class="header-v2-user-title">
      {{ $auth.user && $auth.user.nickname }}
      <gb-tag v-if="isNagsUser" color="blue">NAGS</gb-tag>
    </div>
    <el-menu
      default-active="2"
      background-color="#272727"
      text-color="#fff"
      active-text-color="#ffd04b"
      @
    >
      <el-menu-item
        v-for="item in menuConfig"
        :key="item.key"
        :index="item.key"
        @click="menuSelect(item)"
      >
        <i
          class="material-icons"
          :class="{ [`md-${item.icon}`]: true }"
          v-if="item.icon"
        />
        <img v-if="item.image" :src="item.image" />
        {{ item.label }}
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { userMenuConfig } from '@/constants/header.config'
import { get } from 'vuex-pathify'

export default {
  props: ['allowQuickBooks'],
  computed: {
    isNagsUser: get('isNagsUser'),
    menuConfig() {
      return userMenuConfig.filter((item) => {
        let can = true
        if (item.can) {
          item.can.forEach((v) => {
            can = this.$role.can(v)
          })
        }
        if (item.cant) {
          item.cant.forEach((v) => {
            can = this.$role.cannot(v)
          })
        }
        if (item.display) {
          can = this[item.display]
        }
        return can
      })
    }
  },
  methods: {
    menuSelect(item) {
      this.$emit('menu-select', item)
    }
  }
}
</script>
<style lang="scss">
.header-v2-user-menu {
  width: 100vh;
  max-width: 200px;
  padding: 0;
  .header-v2-user-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #fff;
    font-size: $font-lg;
    font-weight: 300;
    padding: $padding-md;
  }
  .el-menu {
    border-right: 0;
    .el-menu-item {
      height: 36px;
      line-height: 36px;
      padding: 0 10px;
      color: rgb(255, 255, 255);
      background-color: rgb(39, 39, 39);
      text-align: left;
      i,
      img {
        margin-right: $padding-xs;
        display: inline;
      }
    }
  }
}
</style>
