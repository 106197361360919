export const fillCanvasWithText = (text, font, canvas, hidden = false) => {
  const padding = 20
  const cheight = hidden ? canvas.height : canvas.offsetHeight
  const cwidth = hidden ? canvas.width - padding * 2 : canvas.offsetWidth - padding * 2
  let size = 144
  const ctx = canvas.getContext('2d')
  let fits = false
  let offset = 0
  ctx.textBaseline = 'alphabetic'
  while (!fits) {
    ctx.font = `${size}px ${font}`
    const { width } = ctx.measureText(text)
    fits = width <= cwidth
    offset = hidden ? cheight * 0.65 : cheight * 0.75
    size -= 12
  }
  ctx.fillText(text, 20, offset)
  return canvas.toDataURL()
}
