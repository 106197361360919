<template>
  <el-dialog
    center
    append-to-body
    :close-on-click-modal="false"
    :title="template === null ? 'Create a template' : 'Update a template'"
    :visible="visible"
    :before-close="handleClose"
    class="edit-text-confirmation-dialog">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :hide-required-asterisk="true"
      label-width="140px"
      size="medium">
      <el-form-item prop="name">
        <span slot="label" class="undo-label-styles">Name</span>
        <el-input
          placeholder="Enter a name"
          v-model="form.name"
          @focus="focusTextarea(false)"
        />
      </el-form-item>
      <el-form-item prop="type_id">
        <span slot="label" class="undo-label-styles">Type</span>
        <el-select v-model="form.type_id" placeholder="Select a type" style="width: 100%;" @focus="focusTextarea(false)">
          <el-option
            v-for="type in types"
            :key="type.id"
            :label="type.name"
            :value="type.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="shop_id">
        <span slot="label" class="undo-label-styles">Shop</span>
        <el-select v-model="form.shop_id" filterable placeholder="Select a shop" style="width: 100%;" @focus="focusTextarea(false)">
          <el-option
            v-for="shop in shops"
            :key="shop.id"
            :label="shop.name"
            :value="shop.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.type_id === 1 || form.type_id === 2" style="word-break: break-word;">
        <p>Use the following variables in the template body and they will automatically
          be replaced with the proper job information at the sending time<br>( click to copy to clipboard ):</p>
        <div v-if="form.type_id === 1">
          Job number: <span class="text-message-template-variable" @click="copyVariable">{job-id #}</span><br/>
          Customer's name: <span class="text-message-template-variable" @click="copyVariable">{name}</span><br/>
          Customer's address: <span class="text-message-template-variable" @click="copyVariable">{address}</span><br/>
          Appointment date: <span class="text-message-template-variable" @click="copyVariable">{date}</span><br/>
          Appointment day: <span class="text-message-template-variable" @click="copyVariable">{day}</span><br/>
          Appointment window start time: <span class="text-message-template-variable" @click="copyVariable">{from}</span><br/>
          Appointment window end time: <span class="text-message-template-variable" @click="copyVariable">{to}</span><br/>
          Approximate arrival time (based on auto routing): <span class="text-message-template-variable" @click="copyVariable">{aat}</span><br/>
          Approximate job duration (based on auto routing): <span class="text-message-template-variable" @click="copyVariable">{ajd}</span><br/>
          Year: <span class="text-message-template-variable" @click="copyVariable">{veh year}</span><br/>
          Make: <span class="text-message-template-variable" @click="copyVariable">{veh make}</span><br/>
          Model: <span class="text-message-template-variable" @click="copyVariable">{veh model}</span><br/>
          Style: <span class="text-message-template-variable" @click="copyVariable">{veh style}</span><br/>
          Unit number: <span class="text-message-template-variable" @click="copyVariable">{veh unit #}</span><br/>
          Plate number: <span class="text-message-template-variable" @click="copyVariable">{veh plate #}</span><br/>
          VIN number: <span class="text-message-template-variable" @click="copyVariable">{veh vin #}</span><br/>
          Mileage: <span class="text-message-template-variable" @click="copyVariable">{veh mileage}</span>
        </div>
        <div v-if="form.type_id === 2">
          Name: <span class="text-message-template-variable" @click="copyVariable">{name}</span><br/>
        </div>
      </el-form-item>
      <el-form-item prop="body">
        <span slot="label" class="undo-label-styles">Template body</span>
        <el-input
          ref='templateTextarea'
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 10}"
          placeholder="Enter a template body"
          v-model="form.body"
          @focus="focusTextarea(true)"
        />
      </el-form-item>
      <el-form-item prop="default" v-if="form.type_id === 1">
        <el-checkbox
          border
          v-model="form.default"
          label="Make default for the shop"
          style="text-transform: none"
          @focus="focusTextarea(false)"/>
      </el-form-item>
      <el-form-item v-if="form.type_id === 1" style="word-break: break-word;">
        <p class="mt-3"><span style="color: #F56C6C;">NOTE:</span> that text "Please reply YES to confirm or reply NO to
          change" will be automatically added in the end of the message.</p>
      </el-form-item>
      <el-form-item>
        <el-button
          class="mb-2 mr-2"
          type="primary"
          @click="handleSubmit"
          :loading="loading">
          {{ template === null ? 'Create' : 'Update' }}
        </el-button>
        <el-button
          class="ml-0"
          plain
          @click="handleClose">
          Close
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import TextMessageTemplate from '@/scripts/objects/text_message_template'

export default {
  props: ['visible', 'template'],
  data: () => ({
    form: {
      name: '',
      type_id: null,
      shop_id: null,
      body: '',
      default: false,
      textAreaFocus: false
    },
    rules: {
      name: { required: true, message: 'Please enter a name' },
      type_id: { required: true, message: 'Please select a type' },
      shop_id: { required: true, message: 'Please select a shop' },
      body: { required: true, message: 'Please enter a template body' }
    },
    types: [],
    loading: false
  }),
  computed: {
    shops () {
      return this.$store.state.shops
    }
  },
  watch: {
    visible: async function (value) {
      if (value) {
        this.types = await TextMessageTemplate.getTypes()
        if (this.template !== null) {
          this.form.name = this.template.name
          this.form.type_id = this.template.type_id
          this.form.shop_id = this.template.shop_id
          this.form.body = this.template.body
          this.form.default = this.template.default === 1
        }
      }
    }
  },
  methods: {
    clearForm () {
      this.form = {
        name: '',
        type_id: null,
        shop_id: null,
        body: '',
        default: false
      }
    },
    async handleClose () {
      this.$emit('close')
      await this.clearForm()
      this.$refs.form.clearValidate()
    },
    async handleSubmit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          if (this.template === null) {
            await TextMessageTemplate.store(this.form)
          } else {
            await TextMessageTemplate.update(this.template.id, this.form)
          }
          this.handleClose()
          this.loading = false
          this.$message.success(this.template === null ? 'Template successfully created.' : 'Template successfully updated.')
          this.$root.$emit('refetchTemplates')
        }
      } catch (error) {
        this.loading = false
      }
    },
    copyVariable (event) {
      this.$copyText(event.target.innerText)
      if (this.textAreaFocus) {
        var textArea = this.$refs.templateTextarea.$refs.textarea
        var tempText = textArea.value
        var cursorPos = textArea.selectionStart
        this.form.body = tempText.substring(0, textArea.selectionStart) + event.target.innerText + tempText.substring(textArea.selectionEnd, tempText.length);
        setTimeout(() => {
          textArea.focus()
          // cursorPos += insert.length;
          textArea.selectionStart = textArea.selectionEnd = (cursorPos + event.target.innerText.length)
        }, 10);
      } else {
        this.$message.info('Copied to clipboard')
      }
    },
    focusTextarea (val) {
      this.textAreaFocus = val
    }
  }
}
</script>

<style lang="scss">
  .edit-text-confirmation-dialog {
    @apply t-font-sans;
  }

  .text-message-template-variable {
    color: #409EFF;
    cursor: pointer;
    margin-left: 10px;
  }
</style>
