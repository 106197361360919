var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        size: "lg",
        title: "Customer Information",
        "header-bg-variant": "primary",
        "header-text-variant": "light"
      },
      on: { hide: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "b-button",
                { attrs: { variant: "dark" }, on: { click: _vm.close } },
                [_vm._v(" Close ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("h3", { staticClass: "text-uppercase" }, [
        _vm._v(
          _vm._s(_vm.customer.first_name) +
            " " +
            _vm._s(_vm.customer.last_name) +
            " - " +
            _vm._s(_vm.customer.id)
        )
      ]),
      _c("div", [_vm._v(_vm._s(_vm.customer.phone1))]),
      _c("br"),
      _c("div", { staticClass: "text-uppercase" }, [
        _vm._v(_vm._s(_vm.customer.address))
      ]),
      _c("div", { staticClass: "text-uppercase" }, [
        _vm._v(
          _vm._s(_vm.customer.city) +
            ", " +
            _vm._s(_vm.customer.state) +
            " " +
            _vm._s(_vm.customer.zip)
        )
      ]),
      _vm._v("123 "),
      _c("br"),
      _c("div", [_vm._v("Jobs:")]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.jobsLoading,
              expression: "jobsLoading"
            }
          ]
        },
        [
          _c("img", {
            staticStyle: {
              width: "18px",
              height: "18px",
              "margin-right": "6px"
            },
            attrs: { src: require("../../assets/img/spinner.gif") }
          }),
          _vm._v(" Jobs Loading ")
        ]
      ),
      !_vm.jobsLoading && _vm.jobs.length > 0
        ? _c(
            "b-card",
            {
              staticStyle: { padding: "0" },
              attrs: { id: "jobsHistoryContainer" }
            },
            [
              _c(
                "ul",
                { staticStyle: { "list-style-type": "none", padding: "0" } },
                _vm._l(_vm.jobs, function(job, index) {
                  return _c("li", { key: index }, [
                    _c(
                      "a",
                      {
                        staticClass: "historyJobLink",
                        on: {
                          click: function($event) {
                            return _vm.openJob(_vm.jobs[index])
                          }
                        }
                      },
                      [_vm._v(_vm._s(job.id) + " - " + _vm._s(job.status))]
                    )
                  ])
                }),
                0
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }