<template>
  <div v-if="value" class="t-flex t-items-baseline t-mb-2">
    <div class="t-w-48 t-text-right t-text-gray-500 t-text-sm">
      {{ name }}
    </div>
    <div class="t-text-gray-700 t-ml-4">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: String
  }
}
</script>
