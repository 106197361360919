var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-h-1/2 t-py-6" }, [
    _c(
      "div",
      { staticClass: "t-font-medium t-text-gray-400 t-text-xs t-mb-2 t-px-8" },
      [_vm._v(" Parent catalogs ")]
    ),
    _c(
      "div",
      { staticClass: "t-h-full t-overflow-y-auto t-px-8" },
      [
        _vm._l(_vm.data, function(i) {
          return _c("ItemResultCard", {
            key: i.id,
            attrs: { item: i, type: "parent" },
            on: {
              unassigned: function($event) {
                return _vm.unassignParent(i.id)
              }
            }
          })
        }),
        _c(
          "div",
          [
            _c(
              "infinite-loading",
              {
                staticStyle: { flex: "1 0 100%" },
                attrs: { spinner: "spiral", identifier: _vm.infiniteId },
                on: { infinite: _vm.loadParents }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "t-pt-2 t-text-xs t-text-gray-500 t-italic t-pb-4",
                    attrs: { slot: "no-more" },
                    slot: "no-more"
                  },
                  [_vm._v(" No more parents ")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "t-pt-2 t-text-xs t-text-gray-500 t-italic t-pb-4",
                    attrs: { slot: "no-results" },
                    slot: "no-results"
                  },
                  [
                    _vm._v(
                      " The " +
                        _vm._s(_vm.isCatalog ? "catalog" : "item") +
                        " doesn't have any parent catalogs "
                    )
                  ]
                )
              ]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }