<template>
  <div class="t-flex t-flex-col t-h-full">
    <div class="t-pl-6 t-h-14 t-border-solid t-border-0 t-border-b
              t-border-gray-200 t-flex t-items-center">
      <div class="t-text-base t-text-gray-700">Start direct messaging</div>
    </div>
    <div v-if="loading" class="t-flex-grow t-flex t-justify-center">
      <div v-loading="loading"></div>
    </div>
    <div v-else class="t-px-6 t-overflow-y-auto t-pb-12">
      <div
        v-for="identity in otherUsers"
        :key="identity"
        class="direct-channel-row t-flex t-items-center t-border-solid
               t-border-0 t-border-b t-border-gray-200 t-p-3"
      >
        <div class="t-flex-grow">
          <div class="t-text-gray-700 t-text-base">
            <i class="el-icon-user"></i>
            <span class="t-ml-1">{{ identity }}</span>
          </div>
          <div class="t-flex t-text-gray-600 t-text-xs t-mt-1">
            <div v-if="directChannels.find(ch => ch.name === identity && ch.lastMessageDate)">
              <span>Last message</span>
              <span>
                {{ formatDate(directChannels.find(ch => ch.name === identity).lastMessageDate) }}
              </span>
            </div>
            <div v-else class="t-italic">
              No messages yet
            </div>
          </div>
        </div>
        <div class="button-section">
          <div
            v-if="directChannels.find(ch => ch.name === identity)"
            class="t-border-solid t-border t-border-blue-500 t-bg-blue-500
                  t-text-blue-100 t-px-3 t-py-1 t-rounded t-cursor-pointer
                  hover:t-border-blue-400 hover:t-bg-blue-400"
            @click="open(identity)"
          >
            Open
          </div>
          <div
            v-else
            class="t-border-solid t-border t-border-green-500 t-bg-green-500
                  t-text-green-100 t-px-3 t-py-1 t-rounded t-cursor-pointer
                  hover:t-border-green-400 hover:t-bg-green-400"
            @click="start(identity)"
          >
            Start
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ChatService } from '@/services/ChatService'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  data: () => ({
    loading: true,
    channels: []
  }),
  computed: {
    users: function() {
      return this.$store.state.chat.users
    },
    otherUsers: function() {
      return this.users.filter(identity => identity !== this.username)
    },
    username: function() {
      return this.$store.state.user.username
    },
    directChannels: function() {
      const directChannels = this.channels.filter(i => i.friendlyName === 'direct')
      return directChannels.map(i => {
        const firstMember = i.uniqueName.split('-')[0]
        const secondMember = i.uniqueName.split('-')[1]
        const name = firstMember === this.username ? secondMember : firstMember
        return {
          uniqueName: i.uniqueName,
          name,
          lastMessageDate: i.lastMessage ? i.lastMessage.dateCreated : null
        }
      })
    }
  },
  mounted: async function() {
    await this.getUsers()
    await this.getChannels()
  },
  methods: {
    getUsers: async function() {
      await this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.getUsers
      )
    },
    getChannels: async function() {
      this.loading = true
      let paginator = await ChatService.getSubscribedChannels()
      this.channels = paginator.items
      while (paginator.hasPrevPage) {
        paginator = await paginator.prevPage()
        this.channels.unshift(...paginator.items)
      }
      this.loading = false
    },
    formatDate: function(timestamp) {
      return moment(timestamp).calendar()
    },
    start: async function(identity) {
      this.loading = true
      const channel = await ChatService.createChannel({
        isPrivate: true,
        uniqueName: `${this.username}-${identity}-${Math.random().toString(36).substr(2, 5)}`,
        friendlyName: 'direct'
      })
      await ChatService.joinChannel(channel)
      await channel.add(identity)
      this.openChannel(channel)
      this.loading = false
    },
    open: function(identity) {
      const uniqueName = this.directChannels.find(i => i.name === identity).uniqueName
      const channel = this.channels.find(i => i.uniqueName === uniqueName)
      this.openChannel(channel)
    },
    openChannel: function(channel) {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.openChannel,
        channel
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-section {
    @apply t-hidden;
  }

  .direct-channel-row:hover > .button-section {
    @apply t-block;
  }
</style>
