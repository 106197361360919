var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vendor-contact-info-tab" },
    [
      _c("Name"),
      _c("el-divider", { staticClass: "t-my-6" }),
      _vm.isCSR || _vm.isSalesRep || _vm.isTech || _vm.isSupplier
        ? _c(
            "div",
            [
              _c("PhoneNumbers"),
              _c("el-divider", { staticClass: "t-my-6" }),
              _c("FaxNumbers"),
              _c("el-divider", { staticClass: "t-my-6" }),
              _c("Emails"),
              _c("el-divider", { staticClass: "t-my-6" }),
              _c("Addresses")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }