var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "drafts-table",
      attrs: { data: _vm.rows },
      on: { "selection-change": _vm.handleSelectionChange }
    },
    [
      _vm.tab === "draft"
        ? _c("el-table-column", { attrs: { type: "selection" } })
        : _vm._e(),
      _c("el-table-column", {
        attrs: { prop: "id", label: "Id", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "a",
                  {
                    staticStyle: { "text-decoration": "none" },
                    attrs: { href: "/jobs/" + scope.row.id, target: "_blank" }
                  },
                  [_vm._v(_vm._s(scope.row.id))]
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "status", label: "Status", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.getReadableStatus(scope.row.status)))
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "consumer", label: "Customer", width: "200" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.getConsumerName(scope.row.consumer)))
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "vehicle", label: "Vehicle" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.getVehicleString(scope.row.vehicle)))
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "referral_number", label: "Referral #", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.referral_number))])]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { prop: "purchase_order_number", label: "PO #", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.purchase_order_number))])
              ]
            }
          }
        ])
      }),
      _vm.match.key === "consumer_id" && _vm.tab === "draft"
        ? _c("el-table-column", {
            attrs: { label: "Use", width: "100" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.openJobDraft(
                                scope.row.status,
                                scope.row.id
                              )
                            }
                          }
                        },
                        [_vm._v("Use")]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3780164414
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }