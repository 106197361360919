var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-mb-3 t-pr-4" },
    [
      _c("div", { staticClass: "t-font-medium t-text-gray-400 t-text-xs" }, [
        _vm._v(" " + _vm._s(_vm.label) + " ")
      ]),
      _vm.value
        ? _c(
            "el-tooltip",
            {
              attrs: {
                effect: "dark",
                content: "Click to preview",
                placement: "right"
              }
            },
            [
              _c("img", {
                staticClass:
                  "t-max-w-32 t-max-h-32 t-object-contain t-cursor-pointer",
                attrs: { src: _vm.value },
                on: {
                  click: function($event) {
                    return _vm.$emit("view")
                  }
                }
              })
            ]
          )
        : _c("span", { staticClass: "t-font-light t-italic t-text-gray-500" }, [
            _vm._v(" - ")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }