var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex t-items-center t-pt-10 t-px-12 t-mb-8" },
    [
      _vm.shop.logo.length > 0
        ? _c("div", [
            _c("img", {
              staticClass: "t-object-contain",
              staticStyle: { "max-height": "70px", "max-width": "250px" },
              attrs: { src: _vm.shop.logo, alt: "Shop's logo" }
            })
          ])
        : _vm._e(),
      _c("div", { staticClass: "t-flex-grow t-text-right" }, [
        _c("div", { staticClass: "t-text-xl t-text-gray-700 t-mb-1" }, [
          _vm._v(" Aging Report ")
        ]),
        _c("div", { staticClass: "t-text-gray-600" }, [
          _vm._v("as of " + _vm._s(_vm.date))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }