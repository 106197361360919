<template>
  <div class="t-pt-1">
    <div class="t-flex t-mb-2">
      <div class="t-mr-8 t-flex">
        <div class="t-mr-2">Uncleared :</div>
        <div>{{ total - cleared }}</div>
      </div>
      <div class="t-flex">
        <div class="t-mr-2">Total :</div>
        <div>{{ total }}</div>
      </div>
    </div>
    <el-button
      @click="decode"
      :loading="loading"
      size="mini"
    >
      Retry decoding VINs with multiple results
    </el-button>
  </div>
</template>

<script>
import { nagsRepository } from '@/scripts/repositories'

export default {
  props: {
    visible: Boolean
  },
  data: () => ({
    total: 0,
    cleared: 0,
    loading: false
  }),
  watch: {
    visible: function(val) {
      if (val) {
        this.getStats()
      }
    }
  },
  created: function() {
    this.getStats()
  },
  methods: {
    decode: async function() {
      try {
        this.loading = true
        const res = await nagsRepository.decodeVinNumbersWithMultipleResults()
        this.$message({
          type: 'info',
          message: res,
          duration: 0,
          showClose: true,
          offset: 40
        })
        await this.getStats()
        this.loading = false
      } catch {
        await this.getStats()
        this.loading = false
      }
    },
    getStats: async function() {
      const res = await nagsRepository.getVinsWithMultipleResultsStats()
      this.total = res.total
      this.cleared = res.cleared
    }
  }
}
</script>
