var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "t-w-1/2 t-pl-6" }, [
      _c(
        "div",
        { staticClass: "t-pt-5" },
        [
          _c("div", { staticClass: "t-text-gray-700 t-font-semibold t-pb-1" }, [
            _vm._v("Name")
          ]),
          _c("el-input", {
            attrs: {
              "prefix-icon": _vm.form.isPrivate ? "fa fa-lock" : "fa fa-hashtag"
            },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t-pt-5 t-flex t-items-center" },
        [
          _c("div", { staticClass: "t-flex-grow t-pr-4" }, [
            _c(
              "div",
              { staticClass: "t-text-gray-700 t-font-semibold t-pb-1" },
              [_vm._v(" Make private ")]
            ),
            !_vm.form.isPrivate
              ? _c("div", [
                  _vm._v(
                    " When a channel is set to private, it can only be viewed or joined by invitation. "
                  )
                ])
              : _c("div", [
                  _c("span", { staticClass: "t-font-semibold t-mr-1" }, [
                    _vm._v("This can't be undone.")
                  ]),
                  _c("span", [
                    _vm._v("A private channel cannot be made public later on.")
                  ])
                ])
          ]),
          _c("el-switch", {
            attrs: { "active-color": "#276749", "inactive-color": "#CBD5E0" },
            model: {
              value: _vm.form.isPrivate,
              callback: function($$v) {
                _vm.$set(_vm.form, "isPrivate", $$v)
              },
              expression: "form.isPrivate"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "t-text-right t-pt-5" }, [
        _c(
          "button",
          {
            staticClass:
              "t-border-solid t-border t-border-gray-300 t-px-3 t-py-2\n               t-bg-white t-text-gray-700 t-rounded t-cursor-pointer\n               hover:t-border-blue-500 hover:t-text-blue-700",
            class: { "t-cursor-not-allowed": _vm.submitting },
            attrs: { disabled: _vm.submitting },
            on: { click: _vm.create }
          },
          [
            !_vm.submitting
              ? _c("i", { staticClass: "el-icon-plus" })
              : _c("i", { staticClass: "el-icon-loading" }),
            _c("span", { staticClass: "t-ml-2" }, [_vm._v("Create")])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "t-pl-6 t-h-14 t-border-solid t-border-0 t-border-b\n            t-border-gray-200 t-flex t-items-center"
      },
      [
        _c("div", { staticClass: "t-text-base t-text-gray-700" }, [
          _vm._v("Create a channel")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }