<template>
  <button
    class="md-circle-button"
    v-bind="$attrs"
    @click="$emit('click', $event)"
    :disabled="disabled"
    :tabindex="noTab ? -1 : 1"
    :class="{
      danger,
      primary,
      ghost,
      ghostDark,
      warning,
      purple,
      success,
      pink,
      [diameter]: true,
      'no-hover': noHover,
      [size]: true,
      filled,
    }"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <i :class="{ [mdi]: true, [mdIcon]: true, [mdSize]: true }" />
  </button>
</template>

<script>
export default {
  data() {
    return {
      hovered: false
    }
  },
  props: {
    icon: {
      type: String
    },
    size: {
      type: String,
      default: () => 'mini'
    },
    disabled: Boolean,
    outline: Boolean,
    medium: Boolean,
    danger: Boolean,
    purple: Boolean,
    pink: Boolean,
    primary: Boolean,
    ghost: Boolean,
    ghostDark: Boolean,
    warning: Boolean,
    noHover: Boolean,
    success: Boolean,
    diameter: { type: String, default: () => 'regular' },
    noTab: Boolean,
    filled: Boolean,
    hoverIcon: String
  },
  computed: {
    mdi() {
      return this.outline ? 'material-icons-outline' : 'material-icons'
    },
    mdSize() {
      return this.size === 'mini' ? 'md-18' : ''
    },
    mdIcon() {
      return `md-${(this.hovered && this.hoverIcon) || this.icon}`
    }
  }
}
</script>

<style lang="scss" scoped>
.md-circle-button {
  &.regular {
    height: 36px;
    width: 36px;
  }
  &.medium {
    height: 30px;
    width: 30px;
  }
  &.small {
    height: 24px;
    width: 24px;
  }
  &.micro {
    height: 18px;
    width: 18px;
    i {
      font-size: 14px;
    }
  }
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  color: rgba(0, 0, 0, 0.54);
  &.filled {
    background: #fff;
    border: 1px solid $grayscale-3;
  }
  &:hover {
    background: $grayscale-3;
  }
  &:focus {
    background: $grayscale-3;
  }
  &:active {
    background: $grayscale-4;
  }
  &.ghostDark {
    color: $grayscale-6;
    &:hover {
      background: transparentize($grayscale-3, 0.9);
    }
    &:focus {
      background: transparentize($grayscale-3, 0.8);
    }
    &:active {
      background: transparentize($grayscale-3, 0.7);
    }
  }
  &.ghost {
    color: $grayscale-3;
    &:hover {
      background: transparentize($grayscale-3, 0.9);
    }
    &:focus {
      background: transparentize($grayscale-3, 0.8);
    }
    &:active {
      background: transparentize($grayscale-3, 0.7);
    }
  }
  &.danger {
    color: $crimson-red;
    &:hover {
      background: transparentize($crimson-red, 0.9);
    }
    &:focus {
      background: transparentize($crimson-red, 0.8);
    }
    &:active {
      background: transparentize($crimson-red, 0.7);
    }
  }
  &.primary {
    color: $primary;
    &:hover {
      background: transparentize($primary, 0.9);
    }
    &:focus {
      background: transparentize($primary, 0.8);
    }
    &:active {
      background: transparentize($primary, 0.7);
    }
  }
  &.success {
    color: $jade-green;
    &:hover {
      background: transparentize($jade-green, 0.9);
    }
    &:focus {
      background: transparentize($jade-green, 0.8);
    }
    &:active {
      background: transparentize($jade-green, 0.7);
    }
  }
  &.pink {
    color: $watermelon-pink;
    &:hover {
      background: transparentize($watermelon-pink, 0.9);
    }
    &:focus {
      background: transparentize($watermelon-pink, 0.8);
    }
    &:active {
      background: transparentize($watermelon-pink, 0.7);
    }
  }

  &.warning {
    color: $gold-orange;
    &:hover {
      background: transparentize($gold-orange, 0.9);
    }
    &:focus {
      background: transparentize($gold-orange, 0.8);
    }
    &:active {
      background: transparentize($gold-orange, 0.7);
    }
  }
  &.purple {
    color: $royal-purple;
    &:hover {
      background: transparentize($royal-purple, 0.9);
    }
    &:focus {
      background: transparentize($royal-purple, 0.8);
    }
    &:active {
      background: transparentize($royal-purple, 0.7);
    }
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.no-hover {
    &:hover {
      background: none;
    }
    &:focus {
      background: none;
    }
    &:active {
      background: none;
    }
  }
}
</style>
