<template>
  <div v-if="true">
    <el-button @click="refresh">Refresh</el-button>
  </div>
  <div v-else>
    <el-dialog
      title="Calculate optimized route"
      :visible.sync="dialogVisible"
      class="scheduler-route-modal"
      width="90%"
      :modal-append-to-body="false"
    >
      <div v-loading="loading">
        <tech-select v-model="selectedTechs" />
        <tech-table
          :data="selectedTechs"
          @row-change="updateRows"
          @row-remove="removeRow"
        />
        <el-row>
          <el-col :span="16">
            <routing-configuration
              v-model="options"
              title="Routing options"
              :config="routingOptionsTable"
            />
          </el-col>
          <el-col :span="8">
            <routing-configuration
              v-model="config"
              title="Configuration"
              :config="routingConfigTable"
            />
          </el-col>
        </el-row>
        <el-alert
          v-if="unmapped.length"
          :title="`You have ${unmapped.length} jobs with invalid or missing address`"
          :description="
            unmapped
              .map(({ job }) => job && `#${job.id} (${job.customer_name})`)
              .join(' • ')
          "
          type="warning"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">
          Cancel
        </el-button>
        <el-button
          type="primary"
          size="small"
          :disabled="!selectedTechs.length"
          @click="onSubmit"
        >
          Submit
        </el-button>
      </span>
    </el-dialog>
    <el-button
      v-if="isDayView || isBayView || isInPast"
      disabled
      size="large"
      type="primary"
    >
      Calculate routes
    </el-button>
    <el-badge is-dot type="warning" :hidden="!modified" v-else>
      <el-dropdown
        split-button
        type="primary"
        @click="onClick"
        @command="onCommand"
      >
        Calculate routes
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :disabled="!modified" command="refresh">
            Refresh routes
          </el-dropdown-item>
          <el-dropdown-item command="clear">
            <span class="danger">Clear routes</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-badge>
  </div>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import TechSelect from './TechSelect'
import TechTable from './TechTable'
import actions from '@/store/modules/scheduler_v3/types'
import RoutingConfiguration from './RoutingConfiguration'
import {
  routingConfigTable,
  routingOptionsTable
} from '@/constants/routing.config.js'
import { isToday, isPast, endOfDay } from 'date-fns'

export default {
  components: { TechSelect, TechTable, RoutingConfiguration },
  data() {
    return {
      dialogVisible: false,
      selectedTechs: [],
      loading: false,
      config: {
        pickups: false,
        reassign: true
      },
      options: {
        speed: 'faster',
        min_visits_per_vehicle: 0,
        balance: false,
        visit_balance_coefficient: 0,
        min_vehicles: false,
        shortest_distance: false
      },
      routingConfigTable,
      routingOptionsTable
    }
  },
  computed: {
    isBayView: get('sch_v3/isBayView'),
    isDayView: get('sch_v3/isDayView'),
    date: get('sch_v3/filters@date'),
    modified: sync('sch_v3/routesModified'),
    unmapped: get('sch_v3/unmappedJobs'),
    isToday() {
      return isToday(this.date)
    },
    isInPast() {
      return isPast(endOfDay(this.date))
    }
  },
  methods: {
    updateRows(row) {
      this.selectedTechs = this.selectedTechs.map(tech =>
        tech.id === row.id ? row : tech
      )
    },
    removeRow(row) {
      this.selectedTechs = this.selectedTechs.filter(tech => tech.id !== row.id)
    },
    calculateRoutes: call(actions.calculateRoutes),
    getSchedulerData: call(actions.getSchedulerData),
    clearRoutes: call(actions.clearRoutes),
    async onSubmit() {
      this.loading = true
      await this.calculateRoutes({
        techs: this.selectedTechs,
        config: this.config,
        options: this.options
      })
      this.dialogVisible = false
      this.loading = false
    },
    async onClick() {
      try {
        if (this.isToday) {
          await this.$messageBox.confirm(
            "You are trying to re-calculate today's routes",
            'Warning',
            {
              type: 'warning',
              confirmButtonText: 'I understand'
            }
          )
        }
        this.dialogVisible = true
      } catch {}
    },
    onCommand(command) {
      this[command]()
    },
    async refresh() {
      await this.getSchedulerData()
      this.getSchedulerData()
      this.modified = false
    },
    async clear() {
      try {
        if (this.isToday) {
          await this.$messageBox.confirm(
            'This will unassign all the jobs for selected date',
            'Warning',
            {
              type: 'warning',
              confirmButtonText: 'I understand'
            }
          )
        }
        await this.clearRoutes()
        this.modified = false
      } catch {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./styles/SchedulerRouteModal.scss";
</style>
