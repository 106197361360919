var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "edit-text-confirmation-dialog",
      attrs: {
        center: "",
        "append-to-body": "",
        "close-on-click-modal": false,
        title:
          _vm.template === null ? "Create a template" : "Update a template",
        visible: _vm.visible,
        "before-close": _vm.handleClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "hide-required-asterisk": true,
            "label-width": "140px",
            size: "medium"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c(
                "span",
                {
                  staticClass: "undo-label-styles",
                  attrs: { slot: "label" },
                  slot: "label"
                },
                [_vm._v("Name")]
              ),
              _c("el-input", {
                attrs: { placeholder: "Enter a name" },
                on: {
                  focus: function($event) {
                    return _vm.focusTextarea(false)
                  }
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "type_id" } },
            [
              _c(
                "span",
                {
                  staticClass: "undo-label-styles",
                  attrs: { slot: "label" },
                  slot: "label"
                },
                [_vm._v("Type")]
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "Select a type" },
                  on: {
                    focus: function($event) {
                      return _vm.focusTextarea(false)
                    }
                  },
                  model: {
                    value: _vm.form.type_id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "type_id", $$v)
                    },
                    expression: "form.type_id"
                  }
                },
                _vm._l(_vm.types, function(type) {
                  return _c("el-option", {
                    key: type.id,
                    attrs: { label: type.name, value: type.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "shop_id" } },
            [
              _c(
                "span",
                {
                  staticClass: "undo-label-styles",
                  attrs: { slot: "label" },
                  slot: "label"
                },
                [_vm._v("Shop")]
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "", placeholder: "Select a shop" },
                  on: {
                    focus: function($event) {
                      return _vm.focusTextarea(false)
                    }
                  },
                  model: {
                    value: _vm.form.shop_id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "shop_id", $$v)
                    },
                    expression: "form.shop_id"
                  }
                },
                _vm._l(_vm.shops, function(shop) {
                  return _c("el-option", {
                    key: shop.id,
                    attrs: { label: shop.name, value: shop.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.form.type_id === 1 || _vm.form.type_id === 2
            ? _c(
                "el-form-item",
                { staticStyle: { "word-break": "break-word" } },
                [
                  _c("p", [
                    _vm._v(
                      "Use the following variables in the template body and they will automatically be replaced with the proper job information at the sending time"
                    ),
                    _c("br"),
                    _vm._v("( click to copy to clipboard ):")
                  ]),
                  _vm.form.type_id === 1
                    ? _c("div", [
                        _vm._v(" Job number: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{job-id #}")]
                        ),
                        _c("br"),
                        _vm._v(" Customer's name: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{name}")]
                        ),
                        _c("br"),
                        _vm._v(" Customer's address: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{address}")]
                        ),
                        _c("br"),
                        _vm._v(" Appointment date: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{date}")]
                        ),
                        _c("br"),
                        _vm._v(" Appointment day: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{day}")]
                        ),
                        _c("br"),
                        _vm._v(" Appointment window start time: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{from}")]
                        ),
                        _c("br"),
                        _vm._v(" Appointment window end time: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{to}")]
                        ),
                        _c("br"),
                        _vm._v(
                          " Approximate arrival time (based on auto routing): "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{aat}")]
                        ),
                        _c("br"),
                        _vm._v(
                          " Approximate job duration (based on auto routing): "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{ajd}")]
                        ),
                        _c("br"),
                        _vm._v(" Year: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{veh year}")]
                        ),
                        _c("br"),
                        _vm._v(" Make: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{veh make}")]
                        ),
                        _c("br"),
                        _vm._v(" Model: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{veh model}")]
                        ),
                        _c("br"),
                        _vm._v(" Style: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{veh style}")]
                        ),
                        _c("br"),
                        _vm._v(" Unit number: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{veh unit #}")]
                        ),
                        _c("br"),
                        _vm._v(" Plate number: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{veh plate #}")]
                        ),
                        _c("br"),
                        _vm._v(" VIN number: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{veh vin #}")]
                        ),
                        _c("br"),
                        _vm._v(" Mileage: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{veh mileage}")]
                        )
                      ])
                    : _vm._e(),
                  _vm.form.type_id === 2
                    ? _c("div", [
                        _vm._v(" Name: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-message-template-variable",
                            on: { click: _vm.copyVariable }
                          },
                          [_vm._v("{name}")]
                        ),
                        _c("br")
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "body" } },
            [
              _c(
                "span",
                {
                  staticClass: "undo-label-styles",
                  attrs: { slot: "label" },
                  slot: "label"
                },
                [_vm._v("Template body")]
              ),
              _c("el-input", {
                ref: "templateTextarea",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 3, maxRows: 10 },
                  placeholder: "Enter a template body"
                },
                on: {
                  focus: function($event) {
                    return _vm.focusTextarea(true)
                  }
                },
                model: {
                  value: _vm.form.body,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "body", $$v)
                  },
                  expression: "form.body"
                }
              })
            ],
            1
          ),
          _vm.form.type_id === 1
            ? _c(
                "el-form-item",
                { attrs: { prop: "default" } },
                [
                  _c("el-checkbox", {
                    staticStyle: { "text-transform": "none" },
                    attrs: { border: "", label: "Make default for the shop" },
                    on: {
                      focus: function($event) {
                        return _vm.focusTextarea(false)
                      }
                    },
                    model: {
                      value: _vm.form.default,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "default", $$v)
                      },
                      expression: "form.default"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.form.type_id === 1
            ? _c(
                "el-form-item",
                { staticStyle: { "word-break": "break-word" } },
                [
                  _c("p", { staticClass: "mt-3" }, [
                    _c("span", { staticStyle: { color: "#F56C6C" } }, [
                      _vm._v("NOTE:")
                    ]),
                    _vm._v(
                      ' that text "Please reply YES to confirm or reply NO to change" will be automatically added in the end of the message.'
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "mb-2 mr-2",
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleSubmit }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.template === null ? "Create" : "Update") +
                      " "
                  )
                ]
              ),
              _c(
                "el-button",
                {
                  staticClass: "ml-0",
                  attrs: { plain: "" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }