import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class CommercialAccountDefaultTax extends ObjectTracker {
  id = -1
  commercialaccount_id = -1
  shop_id = -1
  tax_id = -1

//   constructor (initialObject = {}) {
//     super()
//     super.setInitial(initialObject)
//   }

  static getAll (commercialaccount_id, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/commercialaccount/${commercialaccount_id}/defaultTaxes`}).then(res => {
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(val)
      })
      callbackFunction(objects)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static updateDefaultTaxes (commercialaccount_id, shopId, taxIds, callbackFunction, errorCallbackFunction = null) {
    let temp = {shopId: shopId, taxIds: taxIds}
    Api.post(`/api/commercialaccount/update/${commercialaccount_id}/defaultTaxes`, temp).then(res => {
      callbackFunction(res.data)
    })
  }
}
