var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.localVisible,
        width: _vm.dialogWidth,
        "show-close": false,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.localVisible = $event
        },
        close: _vm.resetDialog
      }
    },
    [
      _c("template", { slot: "title" }, [
        _c(
          "div",
          { staticClass: "t-text-center" },
          [
            _c(
              "el-badge",
              { staticClass: "item", attrs: { value: "NEW", type: "success" } },
              [
                _c(
                  "div",
                  { staticClass: "t-text-xl t-font-bold t-text-gray-800" },
                  [_vm._v(" New Glass Supplier Available! ")]
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm.loadingData
        ? _c(
            "div",
            { staticClass: "loading" },
            [
              _c("v-icon", {
                attrs: { name: "spinner", scale: "1.5", spin: "" }
              })
            ],
            1
          )
        : _vm._e(),
      _c("add-edit-dialog", {
        attrs: {
          shopId: _vm.shopId,
          edit: {},
          vendor: "nm",
          visible: _vm.linkingAccount
        },
        on: {
          "update:visible": function($event) {
            _vm.linkingAccount = $event
          },
          updateCredentials: _vm.createdCredentials
        }
      }),
      !_vm.interested
        ? _c("div", [
            _c("img", {
              staticStyle: { margin: "auto" },
              attrs: {
                src: require("../../assets/img/nielsen-moller-logo.png")
              }
            }),
            _c("img", {
              staticStyle: { margin: "auto" },
              attrs: {
                src: require("../../assets/img/nielsen-moller-specs.png")
              }
            }),
            _c("br"),
            _c(
              "div",
              {
                staticClass:
                  "t-text-xl t-font-bold t-text-gray-800 t-text-center t-mt-6"
              },
              [_vm._v(" Great Prices • Will Call & Delivery Options ")]
            )
          ])
        : _c("div", [
            _c("div", { staticClass: "t-text-gray-800 t-ml-4" }, [
              _c("span", { staticClass: "t-text-xl" }, [_vm._v("Shop Info:")]),
              _c("ul", { staticClass: "t-mt-4" }, [
                _c("li", [
                  _c("span", { staticClass: "t-font-bold" }, [_vm._v("Name:")]),
                  _vm._v(" " + _vm._s(_vm.dataToShare.name))
                ]),
                _c("li", [
                  _c("span", { staticClass: "t-font-bold" }, [
                    _vm._v("Phone:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.dataToShare.phone))
                ]),
                _c("li", [
                  _c("span", { staticClass: "t-font-bold" }, [
                    _vm._v("Email:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.dataToShare.email))
                ])
              ])
            ])
          ]),
      !_vm.interested
        ? _c(
            "span",
            {
              staticClass: "t-flex t-flex-grow t-justify-between",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.decline } }, [
                _vm._v("Maybe later")
              ]),
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.isInterested } },
                [_vm._v("Get or Link Account")]
              )
            ],
            1
          )
        : _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c(
              "span",
              { staticClass: "t-flex t-flex-grow t-justify-between" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { size: "mini", "text-color": "black" },
                    model: {
                      value: _vm.shareData,
                      callback: function($$v) {
                        _vm.shareData = $$v
                      },
                      expression: "shareData"
                    }
                  },
                  [_vm._v(" Send basic shop info to Nielsen & Moller ")]
                )
              ],
              1
            ),
            _c(
              "span",
              { staticClass: "t-flex t-flex-grow t-justify-between t-mt-2" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.linkingAccount = true
                      }
                    }
                  },
                  [_vm._v("Link Existing Account")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "success" },
                    on: {
                      click: function($event) {
                        return _vm.submit("getAccount")
                      }
                    }
                  },
                  [_vm._v("Create Account")]
                )
              ],
              1
            )
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }