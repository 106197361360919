var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nags-price-container" }, [
      _c("p", [
        _vm._v(
          "This notification is to inform you that because of price increases for services used in building and providing GlassBiller as a service, GlassBiller is updating its pricing policy to reflect this reality. Specifically, notice from NAGS that license fees have nearly "
        ),
        _c("b", [_vm._v("TRIPLED")]),
        _vm._v(". This is unfortunate but an unavoidable increase. ")
      ]),
      _c("p", [_vm._v(" New standardized pricing: ")]),
      _c("b", [
        _c("p", [
          _vm._v(
            "GlassBiller NAGS US enabled user: $199/mo. No contract term commitment "
          )
        ]),
        _c("p", [
          _vm._v(
            "GlassBiller NAGS Canada enabled user: $199/mo. No contract term commitment "
          )
        ]),
        _c("p", [
          _vm._v(
            "GlassBiller Non NAGS user: $19/mo. No contract term commitment"
          )
        ])
      ]),
      _c("p", [_vm._v(" All other pricing remains the same. ")]),
      _c("p", [_vm._v(" New Standard pricing effective September 2021. ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }