<template>
  <div
    class="pdf-option-card"
    :class='{
      "not-visible": !!!option.visible
    }'
    :style="cardStyle"
    @click="card.action"
  >
    <span>{{snakeCaseToTitle(card.title)}}</span>
    <span class='float-right'><el-checkbox v-model="isChecked"></el-checkbox></span>
  </div>
</template>

<script>
import { snakeCaseToTitle } from '@/scripts/helpers/utilities'

export default {
  props: ['option'],
  computed: {
    card () {
      return {
        title: this.option.name,
        action: () => {
          this.$root.$emit('visible-toggle', this.option)
        }
      }
    },
    cardStyle () {
      return {} // this.option.visible ? {} : { opacity: .5 }
    },
    isChecked: {
      get () {
        return !!this.option.visible
      },
      set (val) {
        this.card.action()
      }
    }
  },
  methods: {
    optionDrag(option) {
      this.$root.$emit('dragging-option', option)
    },
    snakeCaseToTitle
  }
}
</script>

<style lang='scss'>
@import './styles/PdfOptionCard.scss';
</style>