var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            lazy: "",
            title: "Fax",
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg",
            id: "faxInboxModal",
            "hide-footer": ""
          },
          on: { show: _vm.dialogAboutToAppear, shown: _vm.dialogAppeared }
        },
        [
          _c(
            "div",
            {
              class: {
                row: true,
                "pb-2": true,
                "pt-2": true,
                faxDragDrop: _vm.amDragging
              },
              on: {
                dragenter: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.faxDragenter($event)
                },
                dragleave: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.faxDragleave($event)
                },
                dragover: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.faxDragover($event)
                },
                drop: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.faxDrop($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "col-lg-2" },
                [
                  _c("FilePicker", {
                    attrs: {
                      buttonLabel: "Choose File",
                      uploadingDropFile: _vm.uploadingDropFile,
                      filePickerKey: _vm.filePickerKey,
                      dialogToReturnTo: "faxInboxModal",
                      handleFilePickerResponse: _vm.handleFilePickerResponse,
                      dropListenerName: "faxInboxFilePickerDrop"
                    },
                    on: {
                      "update:uploadingDropFile": function($event) {
                        _vm.uploadingDropFile = $event
                      },
                      "update:uploading-drop-file": function($event) {
                        _vm.uploadingDropFile = $event
                      }
                    }
                  }),
                  _vm._v(" ... or drag & drop ")
                ],
                1
              ),
              _c("div", { staticClass: "col-lg-3" }, [
                _c("input", {
                  class: {
                    "form-control": true,
                    "form-control-sm": true,
                    uploadingDropFile: _vm.uploadingDropFile
                  },
                  attrs: {
                    type: "text",
                    placeholder: "File Name...",
                    disabled: ""
                  },
                  domProps: {
                    value: _vm.uploadingDropFile
                      ? "Preparing File..."
                      : _vm.uploadFaxName != null &&
                        _vm.uploadFaxName.length > 0
                      ? _vm.uploadFaxName
                      : "File Name..."
                  }
                })
              ]),
              _c("div", { staticClass: "col-lg-1" }),
              _c("div", { staticClass: "col-lg-3" }, [
                _vm.selectedInbox !== "burst"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.faxNumberToSendTo,
                          expression: "faxNumberToSendTo"
                        }
                      ],
                      staticClass: "form-control form-control-sm",
                      attrs: { type: "text", placeholder: "Fax Number..." },
                      domProps: { value: _vm.faxNumberToSendTo },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.faxNumberToSendTo = $event.target.value
                        }
                      }
                    })
                  : _vm._e()
              ]),
              _c("div", { staticClass: "col-lg-2" }, [
                _vm.selectedInbox === "fax"
                  ? _c(
                      "button",
                      {
                        staticClass: "float-right btn btn-primary",
                        attrs: { type: "button", variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.sendFax()
                          }
                        }
                      },
                      [_vm._v("Send Fax")]
                    )
                  : _vm._e(),
                _vm.selectedInbox === "burst" && !_vm.burstIsBusy
                  ? _c(
                      "button",
                      {
                        staticClass: "float-right btn btn-primary",
                        attrs: { type: "button", variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.localBurst()
                          }
                        }
                      },
                      [_vm._v("Burst")]
                    )
                  : _vm.selectedInbox === "burst" && _vm.burstIsBusy
                  ? _c(
                      "span",
                      {
                        staticClass: "faxInboxActionButton",
                        staticStyle: { padding: "10px", float: "right" }
                      },
                      [
                        _c("v-icon", {
                          staticStyle: { color: "black" },
                          attrs: { name: "spinner", spin: "", scale: "2" }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          ),
          _c("div", { staticClass: "row mb-2" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "span",
                  {
                    staticClass: "tag",
                    class: { activeInbox: _vm.selectedInbox === "fax" },
                    staticStyle: { "text-align": "center" }
                  },
                  [_vm._v(" Fax Inbox: " + _vm._s(_vm.inboxTotalRows) + " ")]
                ),
                _c("toggle-button", {
                  attrs: {
                    color: "#bfcbd9",
                    value: _vm.isBurstInboxSelected,
                    sync: true
                  },
                  on: { change: _vm.toggleSelectedInbox }
                }),
                _c(
                  "div",
                  {
                    staticClass: "tag",
                    class: { activeInbox: _vm.selectedInbox === "burst" },
                    staticStyle: { "text-align": "center" }
                  },
                  [_vm._v(" Burst Inbox: " + _vm._s(_vm.burstTotalRows) + " ")]
                )
              ],
              1
            )
          ]),
          _vm.selectedInbox === "fax"
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(" Inbox "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentTable,
                        expression: "currentTable"
                      }
                    ],
                    attrs: { value: "inbox", type: "radio" },
                    domProps: { checked: _vm._q(_vm.currentTable, "inbox") },
                    on: {
                      change: function($event) {
                        _vm.currentTable = "inbox"
                      }
                    }
                  }),
                  _vm._v(" Outbox "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentTable,
                        expression: "currentTable"
                      }
                    ],
                    attrs: { value: "outbox", type: "radio" },
                    domProps: { checked: _vm._q(_vm.currentTable, "outbox") },
                    on: {
                      change: function($event) {
                        _vm.currentTable = "outbox"
                      }
                    }
                  })
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTable === "inbox",
                  expression: "currentTable === 'inbox'"
                }
              ]
            },
            [
              _c("div", { staticClass: "tableHolder row" }, [
                _vm.pdfPreviewItem !== null
                  ? _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.pdfPreviewItem.dateTime))
                          ])
                        ]),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "a",
                            {
                              staticClass: "float-right",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.closePdfPreview()
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                staticStyle: { color: "red" },
                                attrs: { icon: _vm.faTimesCircle }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("iframe", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "min-height": "600px"
                        },
                        attrs: {
                          src:
                            _vm.pdfPreviewItem !== null
                              ? _vm.pdfPreviewItem.url
                              : "",
                          type: "application/pdf"
                        }
                      })
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    class: {
                      "form-group": true,
                      "col-md-6": _vm.pdfPreviewItem !== null,
                      "col-md-12": _vm.pdfPreviewItem === null
                    }
                  },
                  [
                    _vm.selectedFax !== null
                      ? _c(
                          "div",
                          { staticStyle: { "margin-bottom": "50px" } },
                          [
                            _c("AttachFaxDialog", {
                              attrs: { fax: _vm.selectedFax },
                              on: { "close-attach": _vm.closeAttachPanel }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedInbox === "fax"
                      ? _c("b-table", {
                          ref: "faxInboxTable",
                          staticClass: "text-left",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: {
                            stacked: "md",
                            items: _vm.inboxProvider,
                            fields: _vm.inboxFields,
                            "current-page": _vm.inboxCurrentPage,
                            "per-page": _vm.inboxPerPage,
                            "sort-by": _vm.inboxSortBy,
                            "sort-desc": _vm.inboxSortDesc,
                            busy: _vm.inboxIsBusy,
                            "show-empty": true,
                            "empty-text": _vm.inboxIsBusy
                              ? "Loading, please wait..."
                              : "There are no records to show",
                            "empty-filtered-text": _vm.inboxIsBusy
                              ? "Loading, please wait..."
                              : "There are no records matching your request"
                          },
                          on: {
                            "update:sortBy": function($event) {
                              _vm.inboxSortBy = $event
                            },
                            "update:sort-by": function($event) {
                              _vm.inboxSortBy = $event
                            },
                            "update:sortDesc": function($event) {
                              _vm.inboxSortDesc = $event
                            },
                            "update:sort-desc": function($event) {
                              _vm.inboxSortDesc = $event
                            },
                            "update:busy": function($event) {
                              _vm.inboxIsBusy = $event
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "HEAD_dateTime",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.inboxDateTimeFilter,
                                          expression: "inboxDateTimeFilter"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        "data-id": row.id,
                                        type: "text",
                                        placeholder: "Date/Time"
                                      },
                                      domProps: {
                                        value: _vm.inboxDateTimeFilter
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.inboxDateTimeFilter =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "HEAD_sentFrom",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.inboxSentFromFilter,
                                          expression: "inboxSentFromFilter"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        "data-id": row.id,
                                        type: "text",
                                        placeholder: "Sent From"
                                      },
                                      domProps: {
                                        value: _vm.inboxSentFromFilter
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.inboxSentFromFilter =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "HEAD_sentTo",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.inboxSentToFilter,
                                          expression: "inboxSentToFilter"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        "data-id": row.id,
                                        type: "text",
                                        placeholder: "Sent To"
                                      },
                                      domProps: {
                                        value: _vm.inboxSentToFilter
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.inboxSentToFilter =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "HEAD_pages",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.inboxPagesFilter,
                                          expression: "inboxPagesFilter"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        "data-id": row.id,
                                        type: "text",
                                        placeholder: "Pages"
                                      },
                                      domProps: { value: _vm.inboxPagesFilter },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.inboxPagesFilter =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "HEAD_referralNotes",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.inboxReferralNotesFilter,
                                          expression: "inboxReferralNotesFilter"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        "data-id": row.id,
                                        type: "text",
                                        placeholder: "Referral/Notes"
                                      },
                                      domProps: {
                                        value: _vm.inboxReferralNotesFilter
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.inboxReferralNotesFilter =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "HEAD_status",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.inboxStatusFilter,
                                          expression: "inboxStatusFilter"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        "data-id": row.id,
                                        type: "text",
                                        placeholder: "Status"
                                      },
                                      domProps: {
                                        value: _vm.inboxStatusFilter
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.inboxStatusFilter =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "referralNotes",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      ref: "referralNotes" + row.index,
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Enter Referral/Notes"
                                      },
                                      domProps: {
                                        value: row.item.referralNotes
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.updateReferralNotes(
                                            "referralNotes" + row.index,
                                            row.item.faxId
                                          )
                                        },
                                        focus: function($event) {
                                          return _vm.focusReferralNotes(
                                            row.item
                                          )
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "select",
                                fn: function(row) {
                                  return [
                                    _vm.selectedInbox === "fax"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.selectedFaxes,
                                              expression: "selectedFaxes"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            value: row.item,
                                            checked: Array.isArray(
                                              _vm.selectedFaxes
                                            )
                                              ? _vm._i(
                                                  _vm.selectedFaxes,
                                                  row.item
                                                ) > -1
                                              : _vm.selectedFaxes
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.selectedFaxes,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = row.item,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.selectedFaxes = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.selectedFaxes = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.selectedFaxes = $$c
                                              }
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.selectedInbox === "burst"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.selectedBursts,
                                              expression: "selectedBursts"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            value: row.item,
                                            checked: Array.isArray(
                                              _vm.selectedBursts
                                            )
                                              ? _vm._i(
                                                  _vm.selectedBursts,
                                                  row.item
                                                ) > -1
                                              : _vm.selectedBursts
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.selectedBursts,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = row.item,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.selectedBursts = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.selectedBursts = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.selectedBursts = $$c
                                              }
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                }
                              },
                              {
                                key: "actions",
                                fn: function(row) {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "faxInboxActionButton",
                                        staticStyle: { padding: "10px" },
                                        attrs: {
                                          href: row.item.url,
                                          target: "_blank",
                                          title: "Download"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.inboxDownloadButtonPressed(
                                              row.item.faxId
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-cloud-download",
                                          staticStyle: { color: "lightgreen" }
                                        })
                                      ]
                                    ),
                                    _vm.selectedInbox === "fax" &&
                                    !_vm.burstArray.includes(row.item.faxId)
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "faxInboxActionButton",
                                            staticStyle: { padding: "10px" },
                                            attrs: {
                                              title: "Burst",
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.inboxBurstButtonPressed(
                                                  row.item.faxId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-scissors",
                                              staticStyle: {
                                                color: "dodgerblue"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm.selectedInbox === "fax" &&
                                        _vm.burstArray.includes(row.item.faxId)
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "faxInboxActionButton",
                                            staticStyle: { padding: "10px" }
                                          },
                                          [
                                            _c("v-icon", {
                                              staticStyle: { color: "black" },
                                              attrs: {
                                                name: "spinner",
                                                spin: "",
                                                scale: "1"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "faxInboxActionButton",
                                        staticStyle: { padding: "10px" },
                                        attrs: {
                                          title: "Attach To Job",
                                          href: "javascript:void(0)"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.inboxAttachButtonPressed(
                                              row.item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-paperclip",
                                          staticStyle: { color: "darkgray" }
                                        })
                                      ]
                                    ),
                                    _vm.selectedInbox === "fax"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "faxInboxActionButton",
                                            staticStyle: {
                                              padding: "10px",
                                              cursor: "default"
                                            },
                                            attrs: {
                                              title: "Forward",
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.inboxForwardButtonPressed()
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-arrow-right",
                                              staticStyle: {
                                                color: "darkgreen"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.selectedInbox === "fax"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "faxInboxActionButton",
                                            staticStyle: { padding: "10px" },
                                            attrs: {
                                              title: "Delete",
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.inboxDeleteButtonPressed(
                                                  row.item.faxId,
                                                  row.item.dateTime
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times-circle",
                                              staticStyle: { color: "red" }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.selectedInbox === "burst"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "faxInboxActionButton",
                                            staticStyle: { padding: "10px" },
                                            attrs: {
                                              title: "Delete",
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.burstDeleteButtonPressed(
                                                  row.item.burstId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times-circle",
                                              staticStyle: { color: "red" }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            303706248
                          )
                        })
                      : _vm._e(),
                    _vm.selectedInbox === "burst"
                      ? _c("b-table", {
                          ref: "faxBurstTable",
                          staticClass: "text-left",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: {
                            stacked: "md",
                            items: _vm.burstProvider,
                            fields: _vm.burstFields,
                            "current-page": _vm.burstCurrentPage,
                            "per-page": _vm.burstPerPage,
                            "sort-by": _vm.burstSortBy,
                            "sort-desc": _vm.burstSortDesc,
                            busy: _vm.burstIsBusy,
                            "show-empty": true,
                            "empty-text": _vm.burstIsBusy
                              ? "Loading, please wait..."
                              : "There are no records to show",
                            "empty-filtered-text": _vm.burstIsBusy
                              ? "Loading, please wait..."
                              : "There are no records matching your request"
                          },
                          on: {
                            "update:sortBy": function($event) {
                              _vm.burstSortBy = $event
                            },
                            "update:sort-by": function($event) {
                              _vm.burstSortBy = $event
                            },
                            "update:sortDesc": function($event) {
                              _vm.burstSortDesc = $event
                            },
                            "update:sort-desc": function($event) {
                              _vm.burstSortDesc = $event
                            },
                            "update:busy": function($event) {
                              _vm.burstIsBusy = $event
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "HEAD_dateTime",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.inboxDateTimeFilter,
                                          expression: "inboxDateTimeFilter"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        "data-id": row.id,
                                        type: "text",
                                        placeholder: "Date/Time"
                                      },
                                      domProps: {
                                        value: _vm.inboxDateTimeFilter
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.inboxDateTimeFilter =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "HEAD_referralNotes",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.inboxReferralNotesFilter,
                                          expression: "inboxReferralNotesFilter"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        "data-id": row.id,
                                        type: "text",
                                        placeholder: "Referral/Notes"
                                      },
                                      domProps: {
                                        value: _vm.inboxReferralNotesFilter
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.inboxReferralNotesFilter =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "HEAD_status",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.inboxStatusFilter,
                                          expression: "inboxStatusFilter"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        "data-id": row.id,
                                        type: "text",
                                        placeholder: "Status"
                                      },
                                      domProps: {
                                        value: _vm.inboxStatusFilter
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.inboxStatusFilter =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "referralNotes",
                                fn: function(row) {
                                  return [
                                    _c("input", {
                                      ref: "referralNotes" + row.index,
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Enter Referral/Notes"
                                      },
                                      domProps: {
                                        value: row.item.referralNotes
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.updateReferralNotes(
                                            "referralNotes" + row.index,
                                            row.item.faxId
                                          )
                                        },
                                        focus: function($event) {
                                          return _vm.focusReferralNotes(
                                            row.item
                                          )
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "select",
                                fn: function(row) {
                                  return [
                                    _vm.selectedInbox === "fax"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.selectedFaxes,
                                              expression: "selectedFaxes"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            value: row.item,
                                            checked: Array.isArray(
                                              _vm.selectedFaxes
                                            )
                                              ? _vm._i(
                                                  _vm.selectedFaxes,
                                                  row.item
                                                ) > -1
                                              : _vm.selectedFaxes
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.selectedFaxes,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = row.item,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.selectedFaxes = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.selectedFaxes = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.selectedFaxes = $$c
                                              }
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.selectedInbox === "burst"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.selectedBursts,
                                              expression: "selectedBursts"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            value: row.item,
                                            checked: Array.isArray(
                                              _vm.selectedBursts
                                            )
                                              ? _vm._i(
                                                  _vm.selectedBursts,
                                                  row.item
                                                ) > -1
                                              : _vm.selectedBursts
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.selectedBursts,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = row.item,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.selectedBursts = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.selectedBursts = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.selectedBursts = $$c
                                              }
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                }
                              },
                              {
                                key: "actions",
                                fn: function(row) {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "faxInboxActionButton",
                                        staticStyle: { padding: "10px" },
                                        attrs: {
                                          href: row.item.url,
                                          target: "_blank",
                                          title: "Download"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.inboxDownloadButtonPressed(
                                              row.item.faxId
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-cloud-download",
                                          staticStyle: { color: "lightgreen" }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "faxInboxActionButton",
                                        staticStyle: { padding: "10px" },
                                        attrs: {
                                          title: "Attach To Job",
                                          href: "javascript:void(0)"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.inboxAttachButtonPressed(
                                              row.item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-paperclip",
                                          staticStyle: { color: "darkgray" }
                                        })
                                      ]
                                    ),
                                    _vm.selectedInbox === "fax"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "faxInboxActionButton",
                                            staticStyle: { padding: "10px" },
                                            attrs: {
                                              title: "Delete",
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.inboxDeleteButtonPressed(
                                                  row.item.faxId,
                                                  row.item.dateTime
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times-circle",
                                              staticStyle: { color: "red" }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.selectedInbox === "burst"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "faxInboxActionButton",
                                            staticStyle: { padding: "10px" },
                                            attrs: {
                                              title: "Delete",
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.burstDeleteButtonPressed(
                                                  row.item.burstId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times-circle",
                                              staticStyle: { color: "red" }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1819305252
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTable === "inbox",
                      expression: "currentTable === 'inbox'"
                    }
                  ],
                  staticStyle: { "margin-bottom": "10px" }
                },
                [
                  _c("b-col", [
                    _vm.selectedInbox !== "burst"
                      ? _c("div", { staticClass: "input-group mt-1 mt-lg-0" }, [
                          _c(
                            "div",
                            {
                              staticClass: "input-group-append",
                              staticStyle: {
                                "margin-right": "10px",
                                "margin-top": "12px"
                              }
                            },
                            [_c("strong", [_vm._v("With Selected: ")])]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "input-group-append",
                              staticStyle: {
                                "margin-right": "10px",
                                "margin-top": "9px"
                              }
                            },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedStatusToChangeTo,
                                      expression: "selectedStatusToChangeTo"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  staticStyle: { width: "250px" },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.selectedStatusToChangeTo = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function($event) {
                                        return _vm.selectedStatusChanged()
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Choose Status...")
                                  ]),
                                  _c("option", { attrs: { value: "unread" } }, [
                                    _vm._v("Unread")
                                  ]),
                                  _c("option", { attrs: { value: "read" } }, [
                                    _vm._v("Read")
                                  ]),
                                  _c("option", { attrs: { value: "burst" } }, [
                                    _vm._v("Burst")
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c("div", { staticClass: "input-group-append" }, [
                            _vm.selectedInbox !== "burst"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "faxInboxActionButton",
                                    staticStyle: { padding: "10px" },
                                    attrs: {
                                      title: "Burst all selected faxes",
                                      href: "javascript:void(0)"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectedBurstFaxes()
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-scissors",
                                      staticStyle: { color: "dodgerblue" }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm.selectedInbox === "fax"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "faxInboxActionButton",
                                    staticStyle: { padding: "10px" },
                                    attrs: {
                                      title: "Delete all selected faxes",
                                      href: "javascript:void(0)"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectedDeleteFaxes()
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-times-circle",
                                      staticStyle: { color: "red" }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      : _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-1",
                                attrs: {
                                  disabled: _vm.selectedBursts.length === 0,
                                  variant: "danger"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selectedDeleteBursts()
                                  }
                                }
                              },
                              [_vm._v("Delete Selected")]
                            )
                          ],
                          1
                        )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { md: "6" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.inboxTableResultsText))])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _vm.selectedInbox === "fax" &&
                      _vm.currentTable === "inbox"
                        ? _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.inboxTotalRows,
                              "per-page": _vm.inboxPerPage,
                              align: "right"
                            },
                            model: {
                              value: _vm.inboxCurrentPage,
                              callback: function($$v) {
                                _vm.inboxCurrentPage = $$v
                              },
                              expression: "inboxCurrentPage"
                            }
                          })
                        : _vm._e(),
                      _vm.selectedInbox === "burst"
                        ? _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.burstTotalRows,
                              "per-page": _vm.burstPerPage,
                              align: "right"
                            },
                            model: {
                              value: _vm.burstCurrentPage,
                              callback: function($$v) {
                                _vm.burstCurrentPage = $$v
                              },
                              expression: "burstCurrentPage"
                            }
                          })
                        : _vm._e(),
                      _vm.selectedInbox === "fax" &&
                      _vm.currentTable === "outbox"
                        ? _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.outboxTotalRows,
                              "per-page": _vm.outboxPerPage,
                              align: "right"
                            },
                            model: {
                              value: _vm.outboxCurrentPage,
                              callback: function($$v) {
                                _vm.outboxCurrentPage = $$v
                              },
                              expression: "outboxCurrentPage"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTable === "outbox",
                  expression: "currentTable === 'outbox'"
                }
              ]
            },
            [
              _c("div", { staticClass: "tableHolder row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("b-table", {
                      ref: "faxOutboxTable",
                      staticClass: "text-left",
                      attrs: {
                        stacked: "md",
                        items: _vm.outboxProvider,
                        fields: _vm.outboxFields,
                        "current-page": _vm.outboxCurrentPage,
                        "per-page": _vm.outboxPerPage,
                        "sort-by": _vm.outboxSortBy,
                        "sort-desc": _vm.outboxSortDesc,
                        busy: _vm.outboxIsBusy,
                        "show-empty": true,
                        "empty-text": _vm.outboxIsBusy
                          ? "Loading, please wait..."
                          : "There are no records to show",
                        "empty-filtered-text": _vm.outboxIsBusy
                          ? "Loading, please wait..."
                          : "There are no records matching your request"
                      },
                      on: {
                        "update:sortBy": function($event) {
                          _vm.outboxSortBy = $event
                        },
                        "update:sort-by": function($event) {
                          _vm.outboxSortBy = $event
                        },
                        "update:sortDesc": function($event) {
                          _vm.outboxSortDesc = $event
                        },
                        "update:sort-desc": function($event) {
                          _vm.outboxSortDesc = $event
                        },
                        "update:busy": function($event) {
                          _vm.outboxIsBusy = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "file",
                          fn: function(row) {
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href: row.item.fileurl
                                  }
                                },
                                [_vm._v(" " + _vm._s(row.item.file))]
                              )
                            ]
                          }
                        },
                        {
                          key: "HEAD_date",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.outboxDateTimeFilter,
                                    expression: "outboxDateTimeFilter"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Date/Time"
                                },
                                domProps: { value: _vm.outboxDateTimeFilter },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.outboxDateTimeFilter =
                                      $event.target.value
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_file",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.outboxFileFilter,
                                    expression: "outboxFileFilter"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "File Name"
                                },
                                domProps: { value: _vm.outboxFileFilter },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.outboxFileFilter = $event.target.value
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_sender",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.outboxSenderFilter,
                                    expression: "outboxSenderFilter"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Sender"
                                },
                                domProps: { value: _vm.outboxSenderFilter },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.outboxSenderFilter = $event.target.value
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_receiver",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.outboxReceiverFilter,
                                    expression: "outboxReceiverFilter"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Receiver"
                                },
                                domProps: { value: _vm.outboxReceiverFilter },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.outboxReceiverFilter =
                                      $event.target.value
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_pages",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.outboxPagesFilter,
                                    expression: "outboxPagesFilter"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Pages"
                                },
                                domProps: { value: _vm.outboxPagesFilter },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.outboxPagesFilter = $event.target.value
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "HEAD_status",
                          fn: function(row) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.outboxStatusFilter,
                                    expression: "outboxStatusFilter"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  "data-id": row.id,
                                  type: "text",
                                  placeholder: "Status"
                                },
                                domProps: { value: _vm.outboxStatusFilter },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.outboxStatusFilter = $event.target.value
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "confirmation",
                          fn: function(row) {
                            return [
                              row.item.status === "SENT"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "faxInboxActionButton",
                                      staticStyle: { padding: "10px" },
                                      attrs: {
                                        title: "Download Transmittal Page",
                                        href: "javascript:void(0)"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.outboxDownloadTransmittalButtonPressed(
                                            row.item.jobid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-cloud-download",
                                        staticStyle: { color: "green" }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              row.item.status !== "SENT"
                                ? _c(
                                    "a",
                                    {
                                      staticStyle: { padding: "10px" },
                                      attrs: {
                                        title: "No Transmittal Page Available",
                                        href: "javascript:void(0)"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-cloud-download",
                                        staticStyle: { color: "white" }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ]),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { md: "6" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.inboxTableResultsText))])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _vm.selectedInbox === "fax" &&
                      _vm.currentTable === "inbox"
                        ? _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.inboxTotalRows,
                              "per-page": _vm.inboxPerPage,
                              align: "right"
                            },
                            model: {
                              value: _vm.inboxCurrentPage,
                              callback: function($$v) {
                                _vm.inboxCurrentPage = $$v
                              },
                              expression: "inboxCurrentPage"
                            }
                          })
                        : _vm._e(),
                      _vm.selectedInbox === "burst"
                        ? _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.burstTotalRows,
                              "per-page": _vm.burstPerPage,
                              align: "right"
                            },
                            model: {
                              value: _vm.burstCurrentPage,
                              callback: function($$v) {
                                _vm.burstCurrentPage = $$v
                              },
                              expression: "burstCurrentPage"
                            }
                          })
                        : _vm._e(),
                      _vm.selectedInbox === "fax" &&
                      _vm.currentTable === "outbox"
                        ? _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.outboxTotalRows,
                              "per-page": _vm.outboxPerPage,
                              align: "right"
                            },
                            model: {
                              value: _vm.outboxCurrentPage,
                              callback: function($$v) {
                                _vm.outboxCurrentPage = $$v
                              },
                              expression: "outboxCurrentPage"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }