<template>
  <el-popover placement="right" v-model="open" trigger="manual">
    <div class="scheduler-route-tech-stop" v-loading="loading">
      <div>Name</div>
      <el-input
        v-model="name"
        label="Name"
        placeholder="Custom location name"
      />
      <div>Address</div>
      <address-input v-model="address" popperFix />
      <div class="tech-stop-button">
        <el-button
          size="small"
          @click="save"
          :disabled="!name || !(address && address.google_place_id)"
        >
          Save
        </el-button>
      </div>
    </div>
    <div slot="reference">
      <v-icon name="map-marker-alt" v-show="!hideIcon" />
    </div>
  </el-popover>
</template>

<script>
import { call } from 'vuex-pathify'
import actions from '@/store/modules/scheduler_v3/types'

export default {
  data() {
    return { name: '', address: null, loading: false }
  },
  props: ['tech_id', 'value', 'open', 'hideIcon'],
  methods: {
    createRouteTechStop: call(actions.createRouteTechStop),
    async save() {
      this.loading = true
      const techStop = await this.createRouteTechStop({
        name: this.name,
        tech_id: this.tech_id,
        g_address: this.address
      })
      this.loading = false
      this.$emit('create', techStop)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerRouteTechStop.scss';
</style>
