<template>
  <div v-if="type === 'textarea'" class="data-row-formatted-value textarea">
    <el-scrollbar wrap-class="scroll-data-row">
      {{ formatted }}
    </el-scrollbar>
  </div>
  <div
    v-else
    :style="hiddenWidth && { width: 'auto', height: 0, opacity: 0 }"
    class="data-row-formatted-value"
    :class="{ pre, clickable, address: type === 'address' }"
    @click="click"
  >
    {{ fullValue }}
    {{ type === "kit-select" && value ? `(${value.type})` : "" }}
    <br v-if="extraValue" />{{ extraValue }}
  </div>
</template>

<script>
import { maskJs } from 'mask-js'
import { humanDateMini } from '@/scripts/helpers/date.helpers'
import * as R from 'ramda'
import { sync } from 'vuex-pathify'

export default {
  props: ['type', 'value', 'suffix', 'prefix', 'hiddenWidth', 'pre'],
  computed: {
    email: sync('jobDetails/actionDialogs@email'),
    defaultEmail: sync('jobDetails/actionDialogsDefaults@email'),
    formatted() {
      switch (this.type) {
        case 'phone':
          return maskJs('(999) 999-9999', this.value)
        case 'boolean':
          return this.value ? 'Yes' : 'No'
        case 'date':
          return this.value ? humanDateMini(this.value) : '-'
        case 'money':
          return this.value || '0.00'
        case 'address':
          return `${this.value?.full_address || '-'}${
            this.value?.unit
              ? `${this.value?.po_box ? '\n PO Box ' : '\n Unit '}` +
                this.value?.unit
              : ''
          }`
        case 'kit-select':
          return `${this.value?.amount || 0} `
        case 'textarea':
          return this.value
        default:
          return this.value
      }
    },
    extraValue() {
      switch (this.type) {
        case 'address':
          return this.value.extra
        default:
          return ''
      }
    },
    clickable() {
      return R.contains(this.type)(['phone', 'address', 'email']) && this.value
    },
    mapDialogAddress: sync('jobDetails/mapDialogAddress'),
    hasAccessToTextMessaging() {
      return this.$store.state.user.has_access_to_twilio_phone_numbers
    },
    fullValue() {
      const value =
        `${this.prefix || ''}${this.formatted}${this.suffix || ''}` || '-'
      return value.replace('$-', '-$')
    }
  },
  methods: {
    click() {
      if (this.type === 'phone') {
        if (this.hasAccessToTextMessaging) {
          this.$root.$emit('start-text-with-consumer', this.value)
        } else {
          window.open(`tel:${this.value}`, '_self')
        }
      } else if (this.type === 'email') {
        // window.open(`mailto:${this.value}`);
        this.defaultEmail = this.value
        this.email = true
      } else if (this.type === 'address') {
        this.mapDialogAddress = this.value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-row-formatted-value {
  &.textarea {
    white-space: pre-line;
    border: 1px solid $grayscale-2;
    background: #fff;
    border-radius: 4px;
    font-size: $font-sm;
    width: 100%;
    margin-bottom: -1px;
    color: $grayscale-7;
    &:hover {
      color: $grayscale-8;
    }
    transition: color 0.2s ease-in;
  }
  &.pre {
    font-family: "Roboto Mono", monospace;
    letter-spacing: 3px;
  }
  &.clickable {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &.address {
    white-space: pre-line;
  }
}
</style>

<style lang="scss">
.scroll-data-row {
  height: 118px;
  padding: $padding-xxs;
}
</style>
