import { format } from 'date-fns'
import { xActionTypes } from '@/constants/reports.configs'

const reportsInitialState = {
  // when a table is selected, config settings get set here from reports.config
  type: {
    label: 'Sales report',
    id: 'sales',
    group: 'sales',
    total_key: 'total_after_taxes'
  },
  filters: {
    shops: [],
    job_types: ['invoice'],
    customer_types: [],
    license_types: [1, 2],
    paid_statuses: ['FP', 'PP', 'UP'],
    date_start: format(new Date(), 'YYYY-MM-DD'),
    date_end: format(new Date(), 'YYYY-MM-DD'),
    date_type: 'invoice_date',
    account_type: 'commercialaccounts',
    sales: {csrs: [], salesreps: [], locations: [], salesources: [], techs: []},
    // mode = grouping
    status: ['invoice'],
    mode: 'None',
    x_action_types: xActionTypes
  },
  data: null,
  shopData: null,
  loading: false,
  step: null
}

export default reportsInitialState
