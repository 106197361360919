import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class GlaxisWorkAssignment extends ObjectTracker {
  agent_first_name = ''
  agent_last_name = ''
  agent_telephone = ''
  alert = ''
  archived = 0
  begin_requested_time = null
  cause_of_loss_code = null
  cause_of_loss_description = null
  claim_type = -1
  claim_type_description = ''
  createdAt = ''
  date_of_loss = ''
  deductible = ''
  destination_pid = -1
  dispatch_create_date = ''
  dispatch_create_time = ''
  dispatch_number = -1
  document_type = -1
  e_schedule_indicator = ''
  end_requested_time = null
  environment_id = ''
  filename = ''
  fixed_bid_amount = ''
  glass_vendor_address = ''
  glass_vendor_city = ''
  glass_vendor_fax = ''
  glass_vendor_name = ''
  glass_vendor_postal_code = ''
  glass_vendor_state_province = ''
  glass_vendor_telephone = ''
  id = -1
  insurance_claim_indicator = ''
  insured_or_claimant_address = ''
  insured_or_claimant_alternate_telephone = ''
  insured_or_claimant_city = ''
  insured_or_claimant_first_name = ''
  insured_or_claimant_last_name = ''
  insured_or_claimant_postal_code = ''
  insured_or_claimant_state_province = ''
  insured_or_claimant_telephone = ''
  insurer_id = -1
  insurer_name = ''
  job_id = null
  loss_identification_numbers = ''
  loss_reference_number = ''
  max_repairs = -1
  mobile_indicator = ''
  mobile_location_description = ''
  origination_address = ''
  origination_city = ''
  origination_company_name = ''
  origination_email = ''
  origination_fax = ''
  origination_pid = -1
  origination_postal_code = ''
  origination_state_province = ''
  origination_telephone = ''
  origination_url = ''
  receiving_company_id = -1
  recreational_vehicle = ''
  requested_appointment_date = ''
  return_code = ''
  return_text = ''
  transaction_date = ''
  transaction_time = ''
  updatedAt = ''
  vehicle_id = ''
  vehicle_id_number = ''
  vehicle_id_source = ''
  vehicle_make = ''
  vehicle_model = ''
  vehicle_style = ''
  vehicle_year = -1
  version_number = ''
  work_location_postal_code = ''

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static countForAllShops (callbackFunction, errorCallbackFunction = null) {
    Api({ url: `/api/glaxis-work-assignments/count-for-all-related-shops` }).then(res => {
      callbackFunction(res.data)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getForShop (
    shopId,
    showArchive,
    { page, limit },
    { field, order },
    { id, referralNumber, lastName, firstName, phone, jobNumber, yearMakeModel, scheduledDate },
    successCallbackFunction,
    errorCallbackFunction
  ) {
    Api.get('/api/shops/' + encodeURIComponent(shopId) + '/glaxis-work-assignments', {
      params: {
        page,
        limit,
        sort: field,
        order,
        id,
        referralNumber,
        lastName,
        firstName,
        phone,
        jobNumber,
        yearMakeModel,
        scheduledDate,
        archived: showArchive
      }
    }).then(res => {
      successCallbackFunction(res)
    }, err => {
      if (errorCallbackFunction) {
        errorCallbackFunction(err)
      }
    })
  }

  static checkGlaxis (successCallbackFunction) {
    Api.get('/api/glaxis-work-assignments/retrieve-from-glaxis-api').then(res => {
      successCallbackFunction(res.data)
    })
  }

  static getCounts (shopId, successCallbackFunction) {
    Api.get(`/api/shops/${shopId}/glaxis-work-assignments/getCounts`).then(res => {
      successCallbackFunction(res.data)
    })
  }

  static view (id, successCallbackFunction) {
    Api.get(`/api/glaxis-work-assignments/${id}`).then(res => {
      successCallbackFunction(res.data)
    })
  }

  static startJob (id, successCallbackFunction, errorCallbackFunction) {
    Api.get(`/api/glaxis-work-assignments/${id}/startJob`).then(res => {
      successCallbackFunction(res.data)
    }, err => {
      errorCallbackFunction(err)
    })
  }

  static archive (id, successCallbackFunction) {
    Api.get(`/api/glaxis-work-assignments/${id}/archive`).then(res => {
      successCallbackFunction()
    })
  }
}
