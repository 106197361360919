<template>
  <div>
    <p>There is no Twilio account connected to this shop.
      Would you like to create one ?</p>
    <el-row>
      <el-col :lg="12" :sm="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :hide-required-asterisk="true"
          label-width="60px"
          size="mini"
          :disabled="disabled"
        >
          <el-form-item prop="name">
            <span slot="label" class="undo-label-styles">Name</span>
            <el-input
              placeholder="Enter a name"
              v-model="form.name"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              plain
              @click="submit"
              :loading="loading"
            >
              Create an account
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TwilioAccount from '@/scripts/objects/twilio_account'

export default {
  props: ['shopId', 'disabled'],
  data: () => ({
    loading: false,
    form: {
      name: ''
    },
    rules: {
      name: { required: true, message: 'Please enter a name', trigger: 'blur' }
    }
  }),
  methods: {
    async submit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          let res = await TwilioAccount.store(this.shopId, this.form)
          this.$emit('created', res.data)
          this.loading = false
          this.$message.success('Twilio account was successfully created.')
        }
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>
