var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "50px",
        width: "800px",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        "custom-class": "create-update-catalog-item-dialog"
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-flex t-items-center t-mt-[-4px] t-text-gray-700",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", {
            staticClass: "t-text-lg",
            class: _vm.isCatalog ? "el-icon-folder" : "el-icon-document"
          }),
          _c("div", { staticClass: "t-ml-3" }, [
            _vm._v(
              " " +
                _vm._s(_vm.id > 0 ? "Edit" : "Create") +
                " " +
                _vm._s(_vm.isCatalog ? "Catalog" : "Item") +
                " Profile "
            )
          ])
        ]
      ),
      _vm.fetching
        ? _c("LoadingSpinner")
        : _c(
            "el-form",
            {
              ref: "form",
              staticClass: "t-py-6",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "top",
                "hide-required-asterisk": "",
                size: "small",
                id: "item-profile-form"
              }
            },
            [
              _vm.isCatalog
                ? _c("CatalogForm", {
                    attrs: {
                      predefinedColors: _vm.predefinedCatalogColors,
                      number: _vm.form.number,
                      color: _vm.form.color,
                      description: _vm.form.description
                    },
                    on: {
                      "update:number": function($event) {
                        return _vm.$set(_vm.form, "number", $event)
                      },
                      "update:color": function($event) {
                        return _vm.$set(_vm.form, "color", $event)
                      },
                      "update:description": function($event) {
                        return _vm.$set(_vm.form, "description", $event)
                      }
                    }
                  })
                : _c("ItemForm", {
                    attrs: {
                      predefinedColors: _vm.predefinedItemColors,
                      id: _vm.id,
                      internalId: _vm.form.internalId,
                      color: _vm.form.color,
                      number: _vm.form.number,
                      nagsId: _vm.form.nagsId,
                      brand: _vm.form.brand,
                      shortCode: _vm.form.shortCode,
                      description: _vm.form.description,
                      zone: _vm.form.zone,
                      listPrice: _vm.form.listPrice,
                      retailListPrice: _vm.form.retailListPrice,
                      retailMaterial: _vm.form.retailMaterial,
                      retailLabor: _vm.form.retailLabor,
                      upc: _vm.form.upc,
                      sku: _vm.form.sku,
                      picture: _vm.form.picture,
                      qrCode: _vm.form.qrCode,
                      isActive: _vm.form.isActive,
                      isInventoryTracked: _vm.form.isInventoryTracked,
                      notes: _vm.form.notes,
                      autoSourceNoBuy: _vm.form.autoSourceNoBuy
                    },
                    on: {
                      "update:color": function($event) {
                        return _vm.$set(_vm.form, "color", $event)
                      },
                      "update:number": function($event) {
                        return _vm.$set(_vm.form, "number", $event)
                      },
                      "update:nagsId": function($event) {
                        return _vm.$set(_vm.form, "nagsId", $event)
                      },
                      "update:nags-id": function($event) {
                        return _vm.$set(_vm.form, "nagsId", $event)
                      },
                      "update:brand": function($event) {
                        return _vm.$set(_vm.form, "brand", $event)
                      },
                      "update:shortCode": function($event) {
                        return _vm.$set(_vm.form, "shortCode", $event)
                      },
                      "update:short-code": function($event) {
                        return _vm.$set(_vm.form, "shortCode", $event)
                      },
                      "update:description": function($event) {
                        return _vm.$set(_vm.form, "description", $event)
                      },
                      "update:zone": function($event) {
                        return _vm.$set(_vm.form, "zone", $event)
                      },
                      "update:listPrice": function($event) {
                        return _vm.$set(_vm.form, "listPrice", $event)
                      },
                      "update:list-price": function($event) {
                        return _vm.$set(_vm.form, "listPrice", $event)
                      },
                      "update:retailListPrice": function($event) {
                        return _vm.$set(_vm.form, "retailListPrice", $event)
                      },
                      "update:retail-list-price": function($event) {
                        return _vm.$set(_vm.form, "retailListPrice", $event)
                      },
                      "update:retailMaterial": function($event) {
                        return _vm.$set(_vm.form, "retailMaterial", $event)
                      },
                      "update:retail-material": function($event) {
                        return _vm.$set(_vm.form, "retailMaterial", $event)
                      },
                      "update:retailLabor": function($event) {
                        return _vm.$set(_vm.form, "retailLabor", $event)
                      },
                      "update:retail-labor": function($event) {
                        return _vm.$set(_vm.form, "retailLabor", $event)
                      },
                      "update:upc": function($event) {
                        return _vm.$set(_vm.form, "upc", $event)
                      },
                      "update:sku": function($event) {
                        return _vm.$set(_vm.form, "sku", $event)
                      },
                      "update:picture": function($event) {
                        return _vm.$set(_vm.form, "picture", $event)
                      },
                      "update:qrCode": function($event) {
                        return _vm.$set(_vm.form, "qrCode", $event)
                      },
                      "update:qr-code": function($event) {
                        return _vm.$set(_vm.form, "qrCode", $event)
                      },
                      "update:isActive": function($event) {
                        return _vm.$set(_vm.form, "isActive", $event)
                      },
                      "update:is-active": function($event) {
                        return _vm.$set(_vm.form, "isActive", $event)
                      },
                      "update:isInventoryTracked": function($event) {
                        return _vm.$set(_vm.form, "isInventoryTracked", $event)
                      },
                      "update:is-inventory-tracked": function($event) {
                        return _vm.$set(_vm.form, "isInventoryTracked", $event)
                      },
                      "update:notes": function($event) {
                        return _vm.$set(_vm.form, "notes", $event)
                      },
                      "update:autoSourceNoBuy": function($event) {
                        return _vm.$set(_vm.form, "autoSourceNoBuy", $event)
                      },
                      "update:auto-source-no-buy": function($event) {
                        return _vm.$set(_vm.form, "autoSourceNoBuy", $event)
                      }
                    }
                  }),
              _c("div", {
                staticClass:
                  "t-border-0 t-border-b t-border-solid t-border-gray-200 t-mt-4\n        t-mb-6"
              }),
              _c(
                "div",
                { staticClass: "t-flex t-px-8 t-justify-between" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-arrow-left",
                        type: "info",
                        size: "small"
                      },
                      on: { click: _vm.handleClose }
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.handleSubmit }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.id > 0 ? "Update" : "Create") + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }