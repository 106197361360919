<template>
  <span v-if='vendorObject && vendorObject.totalAvailableIncludingSubs && vendorObject.totalAvailableIncludingSubs > 0'>
    <span tabindex="0" :class='classes' :disabled='isDisabled' @click.stop='addPart' style='cursor: pointer;'>
      <span style='color: darkblue;'>&nbsp;{{vendorObject.totalAvailableIncludingSubs}} INV <span v-if='!isButton' style='font-style: italic; font-size: small;'>add</span><span v-else>+</span></span>
    </span>
  </span>
</template>

<script>
export default {
  props: ['part', 'vendorObject', 'isButton', 'isDisabled'],
  methods: {
    addPart () {
      // this.$emit('addPart', {detail: {...this.part.detail, inventory_cost: this.vendorObject.cost}}, this.part.children, 'inventory_sub')
      this.$root.$emit('inventoryButtonPressed', { inventoryData: this.vendorObject, partData: this.part }, true)
      this.$emit('addPart', {partData: this.part, inventoryData: this.vendorObject}, this.part.children, 'inventory_sub')
    }
  },
  computed: {
    classes () {
      if (this.isButton) {
        return {'btn':true, 'btn-gray':!this.isDiabled, 'btn-sm':true, 'align-self-center':true, 'ediLocked':this.isDiabled}
      }
    }
  }
}
</script>

<style>

</style>