var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "consumer-settings", attrs: { gutter: 16 } },
    [
      _c(
        "div",
        { staticStyle: { "margin-left": "8px", "margin-right": "8px" } },
        [
          _c(
            "gb-checkbox",
            {
              model: {
                value: _vm.override_allowed_shops,
                callback: function($$v) {
                  _vm.override_allowed_shops = $$v
                },
                expression: "override_allowed_shops"
              }
            },
            [_vm._v("Override Which Shops Included On")]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.override_allowed_shops,
              expression: "override_allowed_shops"
            }
          ],
          staticStyle: { "margin-left": "8px", "margin-right": "8px" }
        },
        [
          _c("input-with-label", {
            attrs: {
              select: { options: _vm.shopsByOrganization, byId: true },
              options: _vm.shopsByOrganization,
              multiple: true,
              label: "Shops To Appear In"
            },
            model: {
              value: _vm.shopsToAppearIn,
              callback: function($$v) {
                _vm.shopsToAppearIn = $$v
              },
              expression: "shopsToAppearIn"
            }
          })
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c("input-with-label", {
            attrs: {
              select: { options: _vm.shopsByOrganization, byId: true },
              options: _vm.shopsByOrganization,
              label: "Shop",
              forceFocus: !!_vm.shopId
            },
            model: {
              value: _vm.shopId,
              callback: function($$v) {
                _vm.shopId = $$v
              },
              expression: "shopId"
            }
          })
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c(
        "el-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shopId,
              expression: "shopId"
            }
          ],
          attrs: { span: 12 }
        },
        [
          _c("input-with-label", {
            attrs: {
              label: "Default Nags Pricing",
              size: "medium",
              select: {
                options: _vm.shopRatesToSelect,
                isLoading: false,
                clearable: true,
                byId: false
              }
            },
            on: {
              add: function($event) {
                _vm.showShopRateDialog = true
                _vm.shopRateModalData = {}
              },
              edit: function($event) {
                _vm.shopRateModalData = _vm.rates
                _vm.showShopRateDialog = true
              }
            },
            model: {
              value: _vm.rates,
              callback: function($$v) {
                _vm.rates = $$v
              },
              expression: "rates"
            }
          })
        ],
        1
      ),
      _c(
        "el-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shopId,
              expression: "shopId"
            }
          ],
          attrs: { span: 12 }
        },
        [
          _c("input-with-label", {
            attrs: {
              label: "Default Price Level",
              size: "medium",
              select: {
                options: _vm.shopPricelevels,
                isLoading: !!_vm.pricelevelsLoading,
                clearable: true,
                byId: true
              }
            },
            model: {
              value: _vm.selectedPL,
              callback: function($$v) {
                _vm.selectedPL = $$v
              },
              expression: "selectedPL"
            }
          })
        ],
        1
      ),
      _c(
        "el-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shopId,
              expression: "shopId"
            }
          ],
          attrs: { span: 24 }
        },
        [
          _c("consumer-taxes", {
            attrs: { value: _vm.value, shopId: _vm.shopId },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          })
        ],
        1
      ),
      _c("shop-rates-dialog", {
        attrs: {
          rate: _vm.shopRateModalData,
          shopRates: _vm.shopRates,
          shop_id: _vm.shopId,
          defaultName: _vm.defaultRateNameToSet,
          applyRateMethod: _vm.applyRateMethod,
          autoApply: true
        },
        model: {
          value: _vm.showShopRateDialog,
          callback: function($$v) {
            _vm.showShopRateDialog = $$v
          },
          expression: "showShopRateDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }