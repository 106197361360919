var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        { attrs: { type: "text" }, on: { click: _vm.fixNumber } },
        [_vm._v("Fix bad phone numbers")]
      ),
      _vm.loading ? _c("i", { staticClass: "el-icon-loading" }) : _vm._e(),
      _vm.result
        ? _c("el-alert", [
            _vm._v("Fixed " + _vm._s(_vm.result) + " phone numbers")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }