<template>
  <div>
    <div class="t-flex t-px-8 t-mb-2 t-space-x-6">
      <el-form-item prop="internalId" label="ID">
        <el-input
          disabled
          class="item-form__disabled-input"
          placeholder="ID is generated automaticaly"
          :value="internalId"
        />
      </el-form-item>
      <el-form-item
        prop="color"
        label="Color"
        :rules="{ required: true, message: 'Item color is required' }"
      >
        <el-color-picker
          ref="test"
          class="t-flex"
          :value="color"
          :predefine="predefinedColors"
          @change="$emit('update:color', $event)"
          @active-change="activeColorChanged"
        />
      </el-form-item>
    </div>

    <div class="t-flex t-px-8 t-mb-2 t-space-x-6">
      <el-form-item
        prop="number"
        label="Item number"
        :rules="{
          required: true,
          message: 'Item number is required',
          trigger: 'blur'
        }"
      >
        <el-input :value="number" @input="$emit('update:number', $event)" />
      </el-form-item>
      <el-form-item prop="nagsId" label="Nags ID">
        <el-input
          class="item-form__disabled-input"
          disabled
          :value="nagsId"
          @input="$emit('update:nagsId', $event)"
        />
      </el-form-item>
    </div>

    <div class="t-flex t-px-8 t-mb-4 t-space-x-6">
      <el-form-item
        prop="description"
        label="Description"
        :rules="{
          required: true,
          message: 'Description is required',
          trigger: 'blur'
        }"
      >
        <el-input
          :value="description"
          @input="$emit('update:description', $event)"
        />
      </el-form-item>
      <el-form-item prop="zone" label="Zone">
        <el-input :value="zone" @input="$emit('update:zone', $event)" />
      </el-form-item>
    </div>

    <div class="t-py-4 t-mb-4 t-border-0 t-border-t t-border-b t-border-solid t-border-gray-200">
      <div class="t-flex t-px-8 t-mb-2 t-space-x-6">
        <el-form-item
          prop="listPrice"
          label="List price"
          :rules="{
            required: true,
            message: 'List price is required',
            trigger: 'blur'
          }"
        >
          <el-input-number
            class="t-w-full item-form__disabled-input"
            :controls="false"
            :disabled="nagsId !== '' && nagsId !== 'CUSTOM'"
            :value="listPrice"
            @input="$emit('update:listPrice', $event)"
          />
        </el-form-item>
        <el-form-item
          prop="retailListPrice"
          label="Retail List price"
          :rules="{
            required: true,
            message: 'Retail price is required',
            trigger: 'blur'
          }"
        >
          <el-input-number
            class="t-w-full"
            :controls="false"
            :value="retailListPrice"
            @input="$emit('update:retailListPrice', $event)"
          />
        </el-form-item>
      </div>

      <div class="t-flex t-px-8 t-space-x-6">
        <el-form-item
          prop="retailMaterial"
          label="Retail material"
          :rules="{
            required: true,
            message: 'Retail material is required',
            trigger: 'blur'
          }"
        >
          <el-input-number
            class="t-w-full"
            :controls="false"
            :value="retailMaterial"
            @input="$emit('update:retailMaterial', $event)"
          />
        </el-form-item>
        <el-form-item
          prop="retailLabor"
          label="Retail labor"
          :rules="{
            required: true,
            message: 'Retail labor is required',
            trigger: 'blur'
          }"
        >
         <el-input-number
            class="t-w-full"
            :controls="false"
            :value="retailLabor"
            @input="$emit('update:retailLabor', $event)"
          />
        </el-form-item>
        <el-form-item
          prop="retailTotal"
          label="Retail total"
        >
          <el-input-number
            class="t-w-full item-form__disabled-input"
            :controls="false"
            :disabled="true"
            :value="retailTotal"
          />
        </el-form-item>
      </div>
    </div>

    <div class="t-flex t-px-8 t-mb-2 t-space-x-6">
      <el-form-item prop="upc" label="UPC">
        <el-input :value="upc" @input="$emit('update:upc', $event)" />
      </el-form-item>
      <el-form-item prop="sku" label="SKU">
        <el-input :value="sku" @input="$emit('update:sku', $event)" />
      </el-form-item>
    </div>

    <div class="t-flex t-px-8 t-mb-2 t-space-x-6">
      <el-form-item prop="picture" label="Image">
        <div v-if="picture" class="t-flex">
          <img
            :src="picture"
            alt="Item image"
            class="t-max-w-32 t-max-h-32 t-object-contain"
          >
          <i
            class="el-icon-remove t-text-red-500 t-text-xl t-ml-[-13px]
              t-mt-[-10px] t-cursor-pointer"
            @click="picture = null"
          />
        </div>
        <el-button
          v-else
          icon="el-icon-upload2"
          size="mini"
          type="primary"
          plain
          @click="addFile('picture')"
        >
          Add
        </el-button>
      </el-form-item>
      <el-form-item prop="qrCode" label="QR code">
        <div v-if="qrCode" class="t-flex">
          <img
            :src="qrCode"
            alt="QR Code"
            class="t-max-w-32 t-max-h-32 t-object-contain"
          >
          <i
            class="el-icon-remove t-text-red-500 t-text-xl t-ml-[-13px]
              t-mt-[-10px] t-cursor-pointer"
            @click="qrCode = null"
          />
        </div>
        <el-button
          v-else
          icon="el-icon-upload2"
          size="mini"
          type="primary"
          plain
          @click="addFile('qrCode')"
        >
          Add
        </el-button>
      </el-form-item>
    </div>

    <div class="t-flex t-px-8 t-mb-2 t-space-x-6">
      <el-form-item v-if="id" prop="isActive" label="Status">
        <el-select
          :value="isActive"
          class="t-w-full"
          placeholder="Select status"
          @change="$emit('update:isActive', $event)"
        >
          <el-option label="Active" :value="true" />
          <el-option label="Disabled" :value="false" />
        </el-select>
      </el-form-item>
      <el-form-item prop="isInventoryTracked" label="Inv tracking">
        <el-switch
          :value="isInventoryTracked"
          active-color="#34D399"
          inactive-color="#F87171"
          @change="$emit('update:isInventoryTracked', $event)"
        />
      </el-form-item>
      <el-form-item prop="autoSourceNoBuy" label="Auto Source No Buy">
        <el-checkbox :value="autoSourceNoBuy" @change="$emit('update:autoSourceNoBuy', $event)"></el-checkbox>
      </el-form-item>
    </div>

    <div class="t-flex t-px-8 t-space-x-6">
      <el-form-item prop="notes" label="Notes" class="t-w-full">
        <el-input
          type="textarea"
          :value="notes"
          :rows="2"
          @input="$emit('update:notes', $event)"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script>
import * as filestack from 'filestack-js'

export default {
  props: {
    predefinedColors: Array,
    id: Number,
    internalId: Number,
    color: String,
    number: String,
    nagsId: String,
    description: String,
    zone: String,
    listPrice: Number,
    retailListPrice: Number,
    retailMaterial: Number,
    retailLabor: Number,
    upc: String,
    sku: String,
    picture: String,
    qrCode: String,
    isActive: Boolean,
    isInventoryTracked: Boolean,
    notes: String,
    autoSourceNoBuy: Boolean
  },
  methods: {
    addFile(field) {
      const options = {
        maxFiles: 1,
        fromSources: [
          'local_file_system',
          'url',
          'facebook',
          'instagram',
          'googledrive',
          'dropbox',
          'gmail',
          'onedrive'
        ],
        onUploadDone: this[field + 'FileUploaded']
      }
      const client = filestack.init('AcGSAppE1TjCSLtiIUYNpz')
      client.picker(options).open()
    },
    pictureFileUploaded({ filesUploaded, filesFailed }) {
      if (filesUploaded && filesUploaded.length) {
        this.$emit('update:picture', filesUploaded[0].url)
      }
    },
    qrCodeFileUploaded({ filesUploaded, filesFailed }) {
      if (filesUploaded && filesUploaded.length) {
        this.$emit('update:qrCode', filesUploaded[0].url)
      }
    },
    activeColorChanged() {
      this.$emit('update:color', this.$refs.test.color.value)
    }
  },
  computed: {
    retailTotal() {
      return ((parseFloat(this.retailLabor) || 0) + (parseFloat(this.retailMaterial) || 0)).toFixed(2)
    }
  }
}
</script>

<style lang="scss">
.item-form__disabled-input {
  .el-input__inner {
    color: #4B5563 !important;
  }
}
</style>
