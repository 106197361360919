import state from './activity.state'
import actions from './activity.actions'
import getters from './activity.getters'
import { make } from 'vuex-pathify'

const schedulerModule = {
  namespaced: true,
  state,
  getters,
  mutations: make.mutations(state),
  actions
}

export default schedulerModule
