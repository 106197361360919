<template>
  <div class="t-w-64 t-bg-gray-100 t-shadow t-flex-shrink-0 t-overflow-y-auto t-pb-10">
    <div
      @click="browseChannels"
      class="t-flex t-h-8 t-mt-6 t-pl-6 t-items-center t-cursor-pointer"
      :class="[
        { 't-bg-blue-500 t-text-blue-100': isListView },
        { 'hover:t-bg-gray-300': !isListView }
      ]"
    >
      <i class="el-icon-thumb t-text-base"></i>
      <div class="t-ml-3">Browse channels</div>
    </div>
    <UserChannelsList/>
    <DirectChannelsList/>
  </div>
</template>

<script>
import UserChannelsList from './UserChannelsList'
import { chatViews } from '@/constants/chat.config'
import DirectChannelsList from './DirectChannelsList'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  computed: {
    isListView: function() {
      return this.$store.state.chat.view === chatViews.list
    }
  },
  methods: {
    browseChannels: function() {
      this.$store.dispatch(
        chatTypes.namespace + chatTypes.actions.changeView,
        chatViews.list
      )
    }
  },
  components: {
    UserChannelsList,
    DirectChannelsList
  }
}
</script>
