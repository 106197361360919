var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "consumer-search-result" },
    [
      _vm.label
        ? _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "consumer-search-result-content",
          class: { active: _vm.active, selected: _vm.selected }
        },
        [
          _c("div", [
            _c("div", { staticClass: "name" }, [
              _vm.phone_number
                ? _c("span", {
                    staticClass: "phone-number",
                    domProps: { innerHTML: _vm._s(_vm.phoneString) }
                  })
                : _vm._e(),
              _c("span", { domProps: { innerHTML: _vm._s(_vm.nameString) } })
            ]),
            _c("div", { staticClass: "result-meta" }, [
              _vm.shop_name
                ? _c("div", { staticClass: "shop-name" }, [
                    _vm._v(_vm._s(_vm.shop_name))
                  ])
                : _vm._e(),
              _vm.trading_partner
                ? _c("div", { staticClass: "trading-partner" }, [
                    _vm._v(" " + _vm._s(_vm.trading_partner) + " ")
                  ])
                : _vm._e()
            ])
          ]),
          _c("consumer-tags", _vm._b({}, "consumer-tags", _vm.$props, false))
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }