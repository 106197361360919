import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default class Location extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  name = ''
  details = ''
  commission = ''
  modified = -1
  shop_id = -1
  commission_flat_or_percent = 'flat'

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getAll (shopId, callbackFunction) {
    
    Api({ url: `/api/shop/${shopId}/location` }).then(res => {
      
      var objects = []
      res.data.forEach(function (val, index) {
        objects.push(new Location(val))
      })
      
      callbackFunction(objects)
    })
  }

  // GET ALL SALE SOURCES BY USER + FILTERS
  static getLocationsByUserShops (page, limit, sortBy, sortDesc, locationIdFilter, locationNameFilter,
    locationShopNameFilter, successCallback, errorCallback) {
    Api({url: `/api/userlocations?page=` + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) +
      '&sortBy=' + encodeURIComponent(sortBy) + '&sortDesc=' + encodeURIComponent(sortDesc) +
      '&locationIdFilter=' + encodeURIComponent(locationIdFilter) + '&locationNameFilter=' + encodeURIComponent(locationNameFilter) +
      '&locationShopNameFilter=' + encodeURIComponent(locationShopNameFilter)
    }).then(res => {
      
      successCallback(res.data)
    }, function (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    })
  }

  createUpdateLocation (shopId, callbackFunction, errorCallbackFunction = null) {
    this.commission = UtilityMixin.methods.formatFloatDecimals(this.commission, 2, 2)
    if (!this.id || this.id <= 0) {
      this.shop_id = shopId
      this.id = -1
    }
    return Api.post(`/api/shop/${this.shop_id}/location/${this.id}`, this).then(res => {
      
      
      callbackFunction(res.data)
    }, function (error) {
      
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
