<template>
  <el-dialog
    append-to-body
    width="900px"
    :visible="visible"
    :before-close="handleClose"
    :title="`${settings.name} Confirmation Settings`"
  >
    <div style="padding:0;">
      <confirmation-settings
        :settings="localSettings"
        :shopId="shopId"
        ref="confirmationSettingsForm"
        @modifySettings="modifySettings"
        @confirmationSettingsInvalid="alertSettingsInvalid"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">Cancel</el-button>
      <el-button type="primary" @click="save">Save</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ConfirmationSettings from '@/components/ConfirmationSettings/Index'

export default {
  props: {
    visible: Boolean,
    settings: Object,
    shopId: Number
  },
  data: () => ({
    localSettings: {}
  }),
  watch: {
    visible: async function(value) {
      if (value) {
        this.localSettings = this.settings
      }
    }
  },
  methods: {
    handleClose: function() {
      this.$emit('close')
    },
    modifySettings(settings) {
      this.localSettings = settings
    },
    alertSettingsInvalid(string) {
      this.$message.warning(`Invalid confirmation settings. ${string}`)
    },
    save: async function() {
      const confirmationSettingsStatus = this.$refs.confirmationSettingsForm.manualValidate()

      if (confirmationSettingsStatus === 'valid') {
        const { data } = await this.$unum.confirmation().post('/shop/createUpdate', {
          shop_id: this.localSettings.shop_id,
          settings: this.localSettings,
          user: this.$store.state.user
        })

        if (data.result === 'success') {
          this.$message.success('Confirmation settings updated')
          this.$emit('setUpdatedSettings')
          this.handleClose()
        } else {
          this.$message.error('Something went wrong')
        }
      }
    }
  },
  components: {
    ConfirmationSettings
  }
}
</script>

<style lang="scss">
</style>
