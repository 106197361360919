var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "vc-saturation",
      style: { background: _vm.bgColor },
      attrs: { role: "saturationPanel" },
      on: {
        mousedown: _vm.handleMouseDown,
        touchmove: _vm.handleChange,
        touchstart: _vm.handleChange
      }
    },
    [
      _c("div", { staticClass: "vc-saturation--white" }),
      _c("div", { staticClass: "vc-saturation--black" }),
      _c(
        "div",
        {
          staticClass: "vc-saturation-pointer",
          style: { top: _vm.pointerTop, left: _vm.pointerLeft },
          attrs: { role: "currentSaturationPointer" }
        },
        [_c("div", { staticClass: "vc-saturation-circle" })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }