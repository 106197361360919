var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticClass: "t-mb-4",
      attrs: {
        border: "",
        "header-cell-class-name":
          "t-bg-gray-100 t-text-gray-700 t-font-medium t-break-normal",
        data: _vm.lines
      }
    },
    [
      _c("el-table-column", {
        attrs: { label: "", width: "50" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("div", { staticClass: "t-flex t-justify-center" }, [
                  _c("i", {
                    staticClass:
                      "el-icon-circle-close t-text-red-400 t-text-base t-cursor-pointer",
                    attrs: { slot: "reference" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("remove-line", {
                          purchaseOrderId: scope.row.purchaseOrderId,
                          purchaseOrderLineNumber:
                            scope.row.purchaseOrderLineNumber
                        })
                      }
                    },
                    slot: "reference"
                  })
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Item & Description" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("div", { staticClass: "t-break-normal" }, [
                  _c("div", { staticClass: "t-font-medium" }, [
                    _vm._v(_vm._s(scope.row.itemName))
                  ]),
                  _c(
                    "div",
                    { staticClass: "t-text-xs t-italic t-text-gray-400" },
                    [_vm._v(" " + _vm._s(scope.row.itemDescription) + " ")]
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Job #", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                scope.row.jobId !== null
                  ? _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href: "/jobs/" + scope.row.jobId
                        }
                      },
                      [_vm._v(" " + _vm._s(scope.row.jobId) + " ")]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Qty", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  { staticClass: "t-flex t-space-x-2" },
                  [
                    _c("div", [_vm._v(_vm._s(scope.row.quantity))]),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "right", trigger: "click" },
                        on: {
                          show: function($event) {
                            _vm.form = {
                              quantity: scope.row.quantity,
                              maxQuantity: scope.row.totalOnPO
                            }
                          },
                          hide: function($event) {
                            _vm.form = {
                              quantity: null,
                              maxQuantity: null
                            }
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "el-icon-edit t-text-sm t-cursor-pointer t-text-orange-500\n              hover:t-text-orange-400",
                          attrs: { slot: "reference" },
                          slot: "reference"
                        }),
                        _c(
                          "div",
                          { staticClass: "t-flex t-space-x-3" },
                          [
                            _c("el-input-number", {
                              attrs: {
                                size: "small",
                                step: 1,
                                "step-strictly": true,
                                min: 1,
                                max: _vm.form.maxQuantity
                              },
                              model: {
                                value: _vm.form.quantity,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "quantity", $$v)
                                },
                                expression: "form.quantity"
                              }
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  plain: "",
                                  size: "small",
                                  type: "primary",
                                  loading: _vm.submitting
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.updateQuantity({
                                      poId: scope.row.purchaseOrderId,
                                      poLineNumber:
                                        scope.row.purchaseOrderLineNumber
                                    })
                                  }
                                }
                              },
                              [_vm._v(" Update ")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "PO #", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("div", { staticClass: "t-flex" }, [
                  scope.row.purchaseOrderId !== null
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "t-px-2 t-rounded t-opacity-80 t-text-white t-cursor-pointer",
                          style:
                            "background-color: " +
                            _vm.getSupplierColor(
                              scope.row.purchaseOrderSupplierId
                            ),
                          on: {
                            click: function($event) {
                              return _vm.openPO(scope.row.purchaseOrderId)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " PO " +
                              _vm._s(scope.row.purchaseOrderInternalId) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }