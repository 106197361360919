// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".vendor-commissions-tab .el-input-group {\n  width: 20rem;\n}\n.vendor-commissions-tab .el-input-group .el-input-group__prepend {\n  width: 3rem;\n  border-top-left-radius: 0.5rem;\n  border-bottom-left-radius: 0.5rem;\n  --tw-bg-opacity: 1;\n  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));\n}\n.vendor-commissions-tab .el-input-group .el-input-group__append {\n  border-top-right-radius: 0.5rem;\n  border-bottom-right-radius: 0.5rem;\n  --tw-bg-opacity: 1;\n  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));\n}\n.vendor-commissions-tab .el-input-group .el-input-group__append .el-input {\n  width: 5rem;\n}", "",{"version":3,"sources":["/app/<no source>","Commissions.vue"],"names":[],"mappings":"AAAA;EAAA,YAAA;ACaA;ADbA;EAAA,WAAA;EAAA,8BAAA;EAAA,iCAAA;EAAA,kBAAA;EAAA,2DAAA;ACoBA;ADpBA;EAAA,+BAAA;EAAA,kCAAA;EAAA,kBAAA;EAAA,2DAAA;AC0BA;AD1BA;EAAA,WAAA;AC6BA","file":"Commissions.vue","sourcesContent":[null,":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.vendor-commissions-tab .el-input-group {\n  width: 20rem;\n}\n.vendor-commissions-tab .el-input-group .el-input-group__prepend {\n  width: 3rem;\n  border-top-left-radius: 0.5rem;\n  border-bottom-left-radius: 0.5rem;\n  --tw-bg-opacity: 1;\n  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));\n}\n.vendor-commissions-tab .el-input-group .el-input-group__append {\n  border-top-right-radius: 0.5rem;\n  border-bottom-right-radius: 0.5rem;\n  --tw-bg-opacity: 1;\n  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));\n}\n.vendor-commissions-tab .el-input-group .el-input-group__append .el-input {\n  width: 5rem;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
