<template>
  <div class="t-flex">
    <el-scrollbar
      class="t-flex-grow t-h-[700px] t-my-4 t-mr-2"
      wrap-class="t-overflow-x-hidden t-flex-shrink-0 t-py-2 t-px-8"
    >
      <div class="block">
        <el-button
          icon="el-icon-refresh-right"
          circle
          @click="$emit('refresh')"></el-button>
        <el-select
          v-model="internalShops"
          multiple
          collapse-tags
          style="margin-left: 20px;"
          placeholder="Select">
          <el-option
            v-for="item in shopOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-date-picker
          style="margin-left: 20px;"
          v-model="internalMonth"
          type="month"
          format="MMM yy"
          placeholder="Pick a month">
        </el-date-picker>
        <i
          class="material-icons md-check_circle qbo-synced-icon"
          style="margin-left: 20px;"
          v-if="monthIsSynced"
        />
      </div>
      <div class="qbo-sync-dialog-body">
        <el-divider class="t-my-6">Invoices</el-divider>
        <in-glassbiller-table />
        <div v-if="notInGlassBiller.length > 0">
          <el-divider class="t-my-6">Not in GlassBiller for selected shop(s)</el-divider>
          <not-in-glassbiller-table />
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useQboSyncStore } from '@/components/modals/QuickBooksSyncDialog/qboSync.store.js'
import NotInGlassbillerTable from './NotInGlassbillerTable.vue'
import InGlassbillerTable from './InGlassbillerTable.vue'

export default {
  props: [
    'month',
    'loading',
    'shopOptions',
    'shops'
  ],
  computed: {
    ...mapState(useQboSyncStore, ['inGlassBiller', 'notInGlassBiller']),
    internalMonth: {
      get () {
        return this.month
      },
      set (val) {
        this.$emit('update:month', val)
      }
    },
    internalShops: {
      get () {
        return this.shops
      },
      set (val) {
        this.$emit('update:shops', val)
      }
    },
    monthIsSynced () {
      const notSynced = this.inGlassBiller.filter(i => i.balancesMatch === false)
      if (this.inGlassBiller.length > 0 && notSynced.length === 0 && this.notInGlassBiller.length === 0) {
        return true
      }
      return false
    }
  },
  components: {
    NotInGlassbillerTable,
    InGlassbillerTable
  }
}
</script>

<style lang="scss">
  .qbo-synced-icon {
    font-size: 20px;
    color: #54a955;
    position: relative;
    top: 5px;
    left: 5px;
  }
</style>
