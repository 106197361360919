<template>
  <b-modal @show="dialogAboutToAppear" @shown="$refs.nameComponent.focus()" no-close-on-backdrop no-close-on-esc v-bind:title="salesource.id > 0 ? 'Edit Sale Source' : 'New Sale Source'" id="salesourceModal"
    header-bg-variant='primary'
    header-text-variant='light'
    size="lg">
    <div class="row">
      <div class="form-group col-6">
        <label>Status</label>
        <select v-model='salesource.status' class="form-control form-control-sm">
          <option value="active">Active</option>
          <option value="disabled">Disabled</option>
        </select>
      </div>
      <div v-if="salesource.id > 0" class="form-group col-6">
        <label></label>
        <p>ID: <span class="idBox">{{salesource.id}}</span></p>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-8">
        <RequiredInput ref="nameComponent" :title="'Name'" :value.sync='salesource.name' :placeholder="''" :capitalizeAll='true' />
      </div>
      <div v-if='checkCommissionPermission' class="form-group col-4">
        <label>Commission</label>
        <FloatDropdown ref="commissionComponent" :dropdownOptions="[{'value':'flat', 'label':'$', 'dropdownLabel':'Flat'}, {'value':'percent', 'label':'%', 'dropdownLabel':'%'}]" :inDisabled="false" :dropdownValue.sync="salesource.commission_flat_or_percent" :value.sync="salesource.commission"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="inputCity">Default Sales Rep</label>
        <select v-model="salesource.default_rep_id" class="form-control form-control-sm">
          <option value="-1">Choose...</option>
          <option v-for="salesrep in salesreps" :key="salesrep.id" v-bind:value="salesrep.id" >{{ salesrep.name }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label>Details</label>
        <textarea class="form-control form-control-sm" v-model='salesource.details'></textarea>
      </div>
    </div>
    <div slot="modal-footer" class='w-100'>
      <div class='float-right'>
        <button type='button' size='lg' class='btn btn-primary' variant="primary" @click="saveSalesource">Save</button>
        <button type='button' class='btn btn-secondary' variant="primary" @click="cancelEdits">Cancel</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Salesource from '@/scripts/objects/salesource'
import RequiredInput from '@/components/Helpers/RequiredInput.vue'
import { ValidateComponents, ResetValidateComponents } from '@/scripts/helpers/helpers.js'
import FloatDropdown from '@/components/Helpers/FloatDropdown.vue'
import { UtilityMixin } from '@/scripts/helpers/utilities'

export default {
  props: ['parentSalesource', 'salesreps', 'shopId'],
  data () {
    return {
      salesource: {},
      validateInputs: []
    }
  },
  mounted: function () {
    this.validateInputs = [this.$refs.nameComponent, this.$refs.commissionComponent]
  },
  methods: {
    dialogAboutToAppear: function () {
      this.salesource = new Salesource(this.parentSalesource)
      ResetValidateComponents(this.validateInputs)
    },
    saveSalesource: function (event) {
      if (ValidateComponents(this.validateInputs)) {
        let self = this
        this.salesource.createUpdateSalesource(this.shopId, function (object) {
          var skipSet = false
          if (self.salesource.objectTrackerInitial.status === 'active' && object.status === 'disabled') {
            skipSet = true
          }
          self.salesource = new Salesource(object)
          self.$root.$emit('updateSelectSalesource', self.salesource, skipSet)
          self.$root.$emit('bv::hide::modal', 'salesourceModal')
        }, function (error) {
          
        })
      }
    },
    cancelEdits: function (event) {
      event.preventDefault()
      this.$root.$emit('bv::hide::modal', 'salesourceModal')
    }
  },
  watch: {
    'parentSalesource': function (val) {
      this.salesource = new Salesource(this.parentSalesource)
    }
  },
  components: {
    RequiredInput,
    FloatDropdown
  },
  mixins: [UtilityMixin]
}
</script>

<style>
.idBox {
  background-color: #EDC9AF;
  padding: 5px;
  border-radius: 3px;
}
</style>
