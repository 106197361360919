var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-collapse",
    {
      staticClass: "primary-fields-selector",
      model: {
        value: _vm.active,
        callback: function($$v) {
          _vm.active = $$v
        },
        expression: "active"
      }
    },
    [
      _c(
        "el-collapse-item",
        { attrs: { name: "1" } },
        [
          _c("template", { slot: "title" }, [
            _c(
              "div",
              { staticClass: "item-title" },
              [
                _c("span", [_vm._v(_vm._s(_vm.title))]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", icon: "el-icon-circle-plus" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.addRow($event)
                      },
                      keydown: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [_vm._v(" Add " + _vm._s(_vm.fieldLabel) + " ")]
                )
              ],
              1
            )
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            _vm._l(_vm.cols, function(col, idx) {
              return _c("el-col", { key: col.id, attrs: { span: _vm.span } }, [
                _c(
                  "div",
                  {
                    staticClass: "field-container",
                    class: {
                      primary: _vm.primaryIndex === idx,
                      secondary: _vm.secondaryIndex === idx
                    }
                  },
                  [
                    _c("gb-form", {
                      ref: "forms",
                      refInFor: true,
                      attrs: {
                        schema: _vm.getSchemaWithLabels(idx),
                        value: col,
                        "auto-focus": idx === _vm.lastAdded
                      },
                      on: {
                        input: function($event) {
                          return _vm.updateField($event, col, idx)
                        }
                      }
                    }),
                    _c("primary-field-action-buttons", {
                      attrs: {
                        type: _vm.type,
                        isPrimary: _vm.primaryIndex === idx,
                        isSecondary: _vm.secondaryIndex === idx
                      },
                      on: {
                        setPrimary: function($event) {
                          return _vm.makePrimary(col)
                        },
                        setSecondary: function($event) {
                          return _vm.makeSecondary(col)
                        },
                        unsetSecondary: function($event) {
                          return _vm.unsetSecondary(col)
                        },
                        clear: function($event) {
                          return _vm.deleteRow(idx)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }