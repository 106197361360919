<template>
  <b-modal
    @show="dialogAboutToAppear"
    @hide="dialogAboutToDisappear"
    no-close-on-backdrop
    no-close-on-esc
    title="NAGS details"
    id="nagsDetailsModal"
    header-bg-variant="primary"
    header-text-variant="light"
    size="lg"
  >
    <div class="row">
      <div class="form-group col-12">
        <div
          class="row"
          v-show="popupPartDetails.dsc && popupPartDetails.dsc !== ''"
        >
          <div class="form-group col-6 text-right">
            <span><b>Description:</b></span>
            <span v-if="saving" style="float:left">Saving&nbsp;&nbsp;</span>
            <span v-if="saving" style="float:left"><loader /></span>
          </div>
          <div class="form-group col-6">
            {{ popupPartDetails.dsc }}
          </div>
        </div>
        <div class="row">
          <div class="form-group col-6 text-right">
            <span><b>Graphic identifier:</b></span>
          </div>
          <div class="form-group col-6">
            {{
              !isNaN(parseInt(popupPartDetails.glassCallout)) &&
              parseInt(popupPartDetails.glassCallout) !== 0
                ? popupPartDetails.glassCallout
                : 'N/A'
            }}
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 text-center">
            <a
              href="javascript:void(0)"
              @click="showMoreDetails = !showMoreDetails"
              style="width:200px"
              class="btn btn-gray mt-2 mb-2"
              >{{ showMoreDetails ? 'Less' : 'More' }} Info</a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-show="showMoreDetails" class="row">
      <div class="form-group col-12">
        <div class="row" v-show="popupPartDetails.name !== ''">
          <div class="form-group col-6 text-right">
            <span><b>Name:</b></span>
          </div>
          <div class="form-group col-6">
            {{ popupPartDetails.name }}
          </div>
        </div>
        <div class="row" v-show="popupPartDetails.nagsid !== ''">
          <div class="form-group col-6 text-right">
            <span><b>NAGS ID:</b></span>
          </div>
          <div class="form-group col-6">
            {{ popupPartDetails.nagsid }}
          </div>
        </div>
        <div class="row" v-show="popupPartDetails.partno !== ''">
          <div class="form-group col-6 text-right">
            <span><b>Part number:</b></span>
          </div>
          <div class="form-group col-6">
            {{ popupPartDetails.partno }}
          </div>
        </div>
        <div class="row" v-show="popupPartDetails.mfCode !== ''">
          <div class="form-group col-6 text-right">
            <span><b>MF part number:</b></span>
          </div>
          <div class="form-group col-6">
            {{ popupPartDetails.mfCode }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            !isNaN(parseFloat(popupPartDetails.partprice)) &&
              parseFloat(popupPartDetails.partprice) > 0.0001
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Price:</b></span>
          </div>
          <div class="form-group col-6">
            {{ formatCurrency(popupPartDetails.partprice, true) }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            !isNaN(parseFloat(popupPartDetails.labor)) &&
              parseFloat(popupPartDetails.labor) > 0.0001
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Labor Hours:</b></span>
          </div>
          <div class="form-group col-6">
            {{ formatFloatDecimals(popupPartDetails.labor, 2, 2, true) }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            !isNaN(parseFloat(popupPartDetails.qty)) &&
              parseFloat(popupPartDetails.qty) > 0.0001
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Quantity:</b></span>
          </div>
          <div class="form-group col-6">
            {{ formatFloatDecimals(popupPartDetails.qty, 2, 2, true) }}
          </div>
        </div>
        <div class="row" v-show="popupPartDetails.isAdhesiveKitPart === true">
          <div class="form-group col-6 text-right">
            <span><b>Adhesive:</b></span>
          </div>
          <div class="form-group col-6">
            True
          </div>
        </div>
        <div class="row" v-show="popupPartDetails.mouldingDiscount === true">
          <div class="form-group col-6 text-right">
            <span><b>Molding:</b></span>
          </div>
          <div class="form-group col-6">
            True
          </div>
        </div>
        <div class="row" v-show="popupPartDetails.isOem === true">
          <div class="form-group col-6 text-right">
            <span><b>OEM:</b></span>
          </div>
          <div class="form-group col-6">
            True
          </div>
        </div>
        <div class="row" v-show="popupPartDetails.isPremium === true">
          <div class="form-group col-6 text-right">
            <span><b>Premium:</b></span>
          </div>
          <div class="form-group col-6">
            True
          </div>
        </div>
        <div class="row" v-show="popupPartDetails.isGlass === true">
          <div class="form-group col-6 text-right">
            <span><b>Glass:</b></span>
          </div>
          <div class="form-group col-6">
            True
          </div>
        </div>
        <div class="row" v-show="popupPartDetails.isChip === true">
          <div class="form-group col-6 text-right">
            <span><b>Chip repair:</b></span>
          </div>
          <div class="form-group col-6">
            True
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.color !== '' &&
              popupPartDetails.color !== 'Not Available'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Color:</b></span>
          </div>
          <div class="form-group col-6">
            {{ popupPartDetails.color }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true && popupPartDetails.PREFIX !== ''
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>NAGS glass category:</b></span>
          </div>
          <div class="form-group col-6">
            {{ popupPartDetails.PREFIX }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ATCHMNT_FLAG === 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Is attachment included:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ATCHMNT_FLAG === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Is attachment included:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.CLIPS_FLAG === 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Are clips required:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.CLIPS_FLAG === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Are clips required:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.MLDING_FLAG === 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Is molding required:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.MLDING_FLAG === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Is molding required:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ATCHMNT_FLAG === 'Y' &&
              popupPartDetails.ATCHMNT_IS_CLIP === 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Is attachment clip included:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ATCHMNT_FLAG === 'Y' &&
              popupPartDetails.ATCHMNT_IS_CLIP === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Is attachment clip included:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ATCHMNT_FLAG === 'Y' &&
              popupPartDetails.ATCHMNT_IS_MLDING === 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Is attachment molding included:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ATCHMNT_FLAG === 'Y' &&
              popupPartDetails.ATCHMNT_IS_MLDING === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Is attachment molding included:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ATCHMNT_FLAG === 'Y' &&
              popupPartDetails.ATCHMNT_DSC !== ''
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Attachment description:</b></span>
          </div>
          <div class="form-group col-6">
            {{ popupPartDetails.ATCHMNT_DSC }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.PART_NUM !== ''
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Internal part number:</b></span>
          </div>
          <div class="form-group col-6">
            {{ popupPartDetails.PART_NUM }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ANT_FLAG === 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Anterior:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ANT_FLAG === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Anterior:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              !isNaN(parseFloat(popupPartDetails.BLK_SIZE1)) &&
              parseFloat(popupPartDetails.BLK_SIZE1) > 0.0001
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Block size 1:</b></span>
          </div>
          <div class="form-group col-6">
            {{ formatFloatDecimals(popupPartDetails.BLK_SIZE1, 2, 2, true) }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              !isNaN(parseFloat(popupPartDetails.BLK_SIZE2)) &&
              parseFloat(popupPartDetails.BLK_SIZE2) > 0.0001
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Block size 2:</b></span>
          </div>
          <div class="form-group col-6">
            {{ formatFloatDecimals(popupPartDetails.BLK_SIZE2, 2, 2, true) }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ENCAP_FLAG === 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Encap:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.ENCAP_FLAG === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Encap:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass == true &&
              popupPartDetails.HDS_UP_DISP_FLAG == 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>HDS UP DISP:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.HDS_UP_DISP_FLAG === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>HDS UP DISP:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.HEATED_FLAG === 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Heated:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.HEATED_FLAG === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Heated:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              !isNaN(parseFloat(popupPartDetails.NUM_HOLES)) &&
              parseFloat(popupPartDetails.NUM_HOLES) > 0.0001
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Number of holes:</b></span>
          </div>
          <div class="form-group col-6">
            {{ formatFloatDecimals(popupPartDetails.NUM_HOLES, 2, 2, true) }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.SLIDER_FLAG === 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Slider window:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.SLIDER_FLAG === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Slider window:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.SOLAR_FLAG === 'Y'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Solar window:</b></span>
          </div>
          <div class="form-group col-6">
            Yes
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass === true &&
              popupPartDetails.SOLAR_FLAG === 'N'
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Solar window:</b></span>
          </div>
          <div class="form-group col-6">
            No
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass == true &&
              !isNaN(parseFloat(popupPartDetails.THICKNESS)) &&
              parseFloat(popupPartDetails.THICKNESS) > 0.0001
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Thickness of glass:</b></span>
          </div>
          <div class="form-group col-6">
            {{ formatFloatDecimals(popupPartDetails.THICKNESS, 2, 2, true) }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass == true &&
              !isNaN(parseFloat(popupPartDetails.WT)) &&
              parseFloat(popupPartDetails.WT) > 0.0001
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Weight of glass:</b></span>
          </div>
          <div class="form-group col-6">
            {{ formatFloatDecimals(popupPartDetails.WT, 2, 2, true) }}
          </div>
        </div>
        <div
          class="row"
          v-show="
            popupPartDetails.isGlass == true &&
              !isNaN(parseFloat(popupPartDetails.TUBE_QTY)) &&
              parseFloat(popupPartDetails.TUBE_QTY) > 0.0001
          "
        >
          <div class="form-group col-6 text-right">
            <span><b>Number of tubes:</b></span>
          </div>
          <div class="form-group col-6">
            {{ formatFloatDecimals(popupPartDetails.TUBE_QTY, 2, 2, true) }}
          </div>
        </div>
        <div class="row mb-2 mt-2 mb-2 ml-3 mr-3 flex-row-reverse" v-if="!localPermissionCheck(localPermissions.TECHCOMMISSION)">
          <div class="form-group col-4 col-md-2">
            <button
              class="btn-sm btn-gray text-right"
              @click="revertButtonPressed()"
            >
              Revert
            </button>
          </div>
          <div class="form-group col-8 col-md-4 text-right">
            <span
              ><span style="font-size:14px">Auto popup</span>
              <input type="checkbox" v-model="shopPartNote.popup"
            /></span>
          </div>
          <div class="form-group col-12 col-md-6 align-bottom">
            <span style="font-size:12px" class="align-bottom"
              >Customer message for this part number</span
            >
          </div>
        </div>
        <div class="row mb-3 ml-3 mr-3">
          <div class="form-group col-12">
            <textarea
              style="height:100px"
              class="form-control form-control-sm"
              v-model="shopPartNote.note"
            />
          </div>
        </div>
      </div>
    </div>
    <InventoryInputs
      v-if="!localPermissionCheck(localPermissions.TECHCOMMISSION)"
      :shopId="shopId"
      :partno="popupPartDetails.partno"
      :avg_cost.sync="partInventory.avg_cost"
      :quantity.sync="partInventory.quantity"
      :zone.sync="partInventory.zone"
    />
    <!-- Compatible Vehicles -->
    <div v-if="popupPartDetails.children" class="row">
      <div class="col-md-6 offset-md-3">
        <div
          style="text-decoration:underline; font-weight: bold; width: 100%; text-align:center"
        >
          <span>Compatible Vehicles:</span>
        </div>
        <ul id="matchingVehiclesDisplay" style="list-style: circle inside;">
          <li
            v-for="(vehicle, vehicleIndex) in matchingVehicles"
            :key="vehicleIndex"
          >
            <u>{{ vehicleIndex }}</u>
            <ul style="list-style: circle inside;">
              <li v-for="(model, modelIndex) in vehicle" :key="modelIndex">
                {{ modelIndex }}:
                <ul style="list-style: circle inside;">
                  <li v-for="(year, yearIndex) in model" :key="yearIndex">
                    {{ yearIndex }}:
                    <ul style="list-style: none inside;">
                      <li v-for="(style, styleIndex) in year" :key="styleIndex">
                        {{ style }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <!-- Auto Add Parts -->
    <div style="text-align:center" v-if="!localPermissionCheck(localPermissions.TECHCOMMISSION)">
      <div v-if="dialogOpen">
        <b-btn class="w-100 mb-2" @click="autoAddPartsClicked"
          >Auto Add Parts</b-btn
        >
        <b-collapse
          v-model="showAutoAdd"
          v-for="(child, index) in popupPartDetails.children"
          :key="index"
          id="autoAddAdhesives"
          class="h-25 mh-25"
        >
          <b-btn
            class="w-100"
            v-b-toggle="child.detail.name"
            :style="{
              'background-image': partCategoryColor(child.detail.name)
            }"
            :aria-controls="child.detail.name"
            :aria-expanded="false"
            >{{ child.detail.name }}</b-btn
          >
          <b-collapse :id="child.detail.name">
            <div class="autoAddCheckList">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" class="w-75">Part</th>
                    <th style="text-align:right" scope="col">Auto-Add</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(childPart, index) in child.children" :key="index">
                    <td>{{ childPart.detail.name }}</td>
                    <td style="text-align:right">
                      <b-form-checkbox
                        v-model="autoAddParts"
                        :value="childPart.detail.partno"
                      >
                      </b-form-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-collapse>
        </b-collapse>
        <b-btn
          v-if="showAutoAdd"
          class="w-100"
          @click="autoAddSavedClicked"
          :style="{ 'background-color': 'brown' }"
          :aria-expanded="false"
          >Saved Parts</b-btn
        >
        <b-collapse id="autoAddSavedCollapse" v-model="showAutoAddSaved">
          <div class="autoAddCheckList">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="w-25">Part Id</th>
                  <th scope="col" class="w-50">Part Description</th>
                  <th style="text-align:right" scope="col" class="w-25">
                    Auto-Add
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(savedPart, index) in savedParts" :key="index">
                  <td scope="col" class="w-25">{{ savedPart.id }}</td>
                  <td v-if="savedPart.description != ''">
                    {{ savedPart.description }}
                  </td>
                  <td v-else>N/A</td>
                  <td style="text-align:right">
                    <b-form-checkbox
                      v-model="autoAddParts"
                      :value="'SAVEDPART-' + savedPart.id"
                    >
                    </b-form-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- Graphic -->
    <div class="row">
      <div class="form-group col-12 text-center">
        <span style="color:red"> {{ glassGraphicLabel }}</span>
        <el-alert
          v-if="popupPartDetails.callouts"
          center
          :closable="false"
          title="Known callouts"
          type="info"
        >
          <template v-for="(val, key) in popupPartDetails.callouts">
            <span
              :style="{fontWeight: popupPartDetails.callout === key ? '600' : '400'}"
              :key="key * 10">
              {{ key }} - {{ val }}
            </span>
            <el-divider
              direction="vertical"
              :key="key"
            />
          </template>
        </el-alert>
        <img
          v-if="image"
          class="img-fluid single"
          v-img="{ src: image }"
          :src="image"
        />
      </div>
    </div>
    <div slot="modal-footer" class="w-100"></div>
  </b-modal>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
import ShopPartNote from '@/scripts/objects/shop_part_note.js'
import ShopInventory from '@/scripts/objects/shop_inventory.js'
import InventoryInputs from '../JobPage/InventoryInputs.vue'
import Nags from '@/scripts/objects/nags'
import AutoAddPart from '@/scripts/objects/auto_add_part'
import Part from '@/scripts/objects/part'
import Loader from '../Helpers/Loader'
import Api from '@/services/Api'
import { permissions, permissionCheck } from '@/scripts/helpers/permissions.helpers'

export default {
  props: ['shopId', 'job', 'popupPartDetails', 'glassGraphics', 'popupId'],
  data() {
    return {
      showMoreDetails: false,
      shopPartNote: new ShopPartNote(),
      partInventory: new ShopInventory(),
      matchingVehicles: {},
      autoAddParts: [],
      partSuffix: '',
      showAutoAdd: false,
      showAutoAddSaved: false,
      dialogOpen: false,
      savedParts: [],
      shouldClose: false,
      saving: false,
      image: null,
      loadingImage: false,
      localPermissions: permissions
    }
  },
  computed: {
    glassGraphicLabel() {
      if (this.loadingImage) {
        return 'Fetching image...'
      }
      if (this.image) {
        return "Click on graphic to expand it. Some images don't increase in size."
      } else {
        return '(No graphic is available)'
      }
    },
    glassGraphicLargeImageTitle() {
      if (
        this.glassGraphicData &&
        this.glassGraphicData.trim().length > 0 &&
        !isNaN(parseInt(this.popupPartDetails.glassGraphicId)) &&
        parseInt(this.popupPartDetails.glassGraphicId) > 0
      ) {
        var dsc = this.popupPartDetails.dsc
        if (
          this.popupPartDetails.name.length > this.popupPartDetails.dsc.length
        ) {
          dsc = this.popupPartDetails.name
        }
        if (
          !isNaN(parseInt(this.popupPartDetails.glassCallout)) &&
          parseInt(this.popupPartDetails.glassCallout) > 0
        ) {
          if (dsc.length > 0) {
            dsc += ' - '
          }
          dsc += 'Callout #'
          dsc += parseInt(this.popupPartDetails.glassCallout)
        }
        return dsc
      } else {
        return ''
      }
    }
  },
  methods: {
    autoAddPartsClicked() {
      if (this.shopId > 0) this.showAutoAdd = !this.showAutoAdd
      else {
        alert(
          'You must first choose a shop for this job before configuring auto add parts.'
        )
      }
      if (this.showAutoAddSaved === true) this.showAutoAddSaved = false
    },
    autoAddSavedClicked() {
      if (this.shopId > 0) this.showAutoAddSaved = !this.showAutoAddSaved
      else {
        alert(
          'You must first choose a shop for this job before configuring auto add parts.'
        )
      }
    },
    revertButtonPressed() {
      let self = this
      ShopPartNote.getShopPartNote(
        self.shopId,
        self.popupPartDetails.partno,
        function(object) {
          self.shopPartNote = object
        }
      )
      ShopInventory.getShopPartInventory(
        self.shopId,
        self.popupPartDetails.partno,
        function(response) {
          self.partInventory = response
        }
      )
    },
    dialogAboutToAppear: function() {
      let self = this
      self.showMoreDetails = false
      this.dialogOpen = true
      this.shouldClose = false
      setTimeout(function() {
        self.revertButtonPressed()
        // #Auto Add Saved (1) - All auto add parts retrieved for parent part
        self.autoAddParts = self.popupPartDetails.autoAddParts
        self.partSuffix = self.popupPartDetails.partno.substring(7, 10)
        self.popupPartDetails.nagsid = self.popupPartDetails.partno.substring(
          0,
          7
        )
        self.getShopParts()
        Nags.getVehiclesByNagsGlassId(self.popupPartDetails.nagsid, function(
          res
        ) {
          let rawVehicleMatches = res
          let finalMatches = {}
          let makeKeys = Object.keys(rawVehicleMatches)
          for (var i = 0; i < makeKeys.length; i++) {
            let currentMake = makeKeys[i]

            finalMatches[currentMake] = {}
            let modelKeys = Object.keys(rawVehicleMatches[currentMake])
            for (var j = 0; j < modelKeys.length; j++) {
              let currentModel = modelKeys[j]
              finalMatches[currentMake][currentModel] = {}

              for (
                var k = 0;
                k < rawVehicleMatches[currentMake][currentModel].years.length;
                k++
              ) {
                let currentYear =
                  rawVehicleMatches[currentMake][currentModel].years[k].year
                let currentStyle =
                  rawVehicleMatches[currentMake][currentModel].years[k].style
                if (
                  !finalMatches[currentMake][currentModel].hasOwnProperty(
                    currentYear
                  )
                ) {
                  finalMatches[currentMake][currentModel][currentYear] = []
                }
                if (
                  !finalMatches[currentMake][currentModel][
                    currentYear
                  ].includes(currentStyle) ||
                  finalMatches[currentMake][currentModel][currentYear]
                    .length === 0
                ) {
                  finalMatches[currentMake][currentModel][currentYear].push(
                    currentStyle
                  )
                }
              }
            }
          }
          // self.matchingVehicles = res
          self.matchingVehicles = finalMatches
        })
      }, 100)
      this.getGlassGraphic()
    },
    dialogAboutToDisappear: async function(bvModalEvt) {
      if (!this.shouldClose) {
        this.saving = true
        bvModalEvt.preventDefault()
        this.shouldClose = true
        var self = this

        if (this.shopId > 0) {
          console.log('create update note 1')
          await this.shopPartNote.createUpdateShopPartNote(
            this.shopId,
            this.popupPartDetails.partno
          )
          var partInventoryReponse = await this.partInventory.createUpdateShopInventory(
            this.shopId,
            function(response) {}
          )
          self.$root.$emit('setPartInventory', partInventoryReponse.data)
          var autoAddPartResponse = await AutoAddPart.createUpdateAutoAddParts(
            self.shopId,
            self.popupPartDetails.nagsid,
            self.partSuffix,
            this.autoAddParts,
            function(res) {}
          )
          self.$root.$emit('updateAutoAddParts', autoAddPartResponse.data)
        }
        this.dialogOpen = false
        this.showAutoAdd = false
        this.showAutoAddSaved = false
        self.$root.$emit('bv::hide::modal', 'nagsDetailsModal')
      } else {
        this.saving = false
      }
      this.glassGraphicData = ''
      this.glassGraphicDataLarge = ''
      this.image = null
      // Todo: only fire this notification if the user did adjust auto-add settings
      // self.$root.$emit('showGlobalNotification', 'Success', 'Updated auto-add parts for ' + this.popupPartDetails.partno)
    },
    partCategoryColor(name) {
      if (name === 'Adhesives') {
        return 'linear-gradient(#DB0000 0%, #B50000 100%)'
      } else if (name === 'Moldings') {
        return 'linear-gradient(#17C200 0%, #118701 100%)'
      } else if (name === 'Miscellaneous') {
        return 'linear-gradient(#B39D9D 0%, #807070 100%)'
      } else if (name === 'OEM Glass') {
        return 'linear-gradient(#83BAD6 0%, #60899E 100%)'
      } else {
        return 'linear-gradient(rgb(81, 81, 81) 0%, rgb(54, 54, 54) 100%)'
      }
    },
    getShopParts() {
      var self = this
      Part.getShopParts(this.shopId, this.job, function(res) {
        self.savedParts = res
      })
    },
    localPermissionCheck (permission) {
      return permissionCheck(permission, this.$store.state)
    },
    async getGlassGraphic() {
      try {
        this.loadingImage = true
        const { data } = await Api.get(
          '/api/nags/glassGraphic/' + this.popupPartDetails.glassGraphicId
        )
        this.image = data.jpg || data.gif
      } catch (error) {
        console.log(error)
      }
      this.loadingImage = false
    }
  },
  mixins: [UtilityMixin],
  components: {
    InventoryInputs,
    Loader
  }
}
</script>

<style scoped>
.autoAddCheckList {
  text-align: left;
}
</style>
