<template>
  <div class="vendor-profile-techside-settings">
    <div class="t-text-gray-400 t-mb-4">
      {{ isSalesRep ? 'Saleside' : 'Techside' }} Username (Case Sensitive)
    </div>

    <el-input
      v-model="username"
      :placeholder="`Enter ${isSalesRep ? 'Saleside' : 'Techside'} Username`"
      @change="updateUsernameHandler"
    />

    <div v-if="invitation_status">
      <el-divider class="t-my-6" />

      <div class="t-text-gray-400 t-mb-4">
        {{ isSalesRep ? 'Saleside' : 'Techside' }} Invitation Status
      </div>

      <el-input
        v-model="invitation_status"
        readonly
        disabled
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  data() {
    return {
      username: null,
      invitation_status: null
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data', 'isSalesRep'])
  },

  created() {
    if (this.data.id) {
      this.username = this.data.username
      this.invitation_status = this.setInvitationStatus(this.data.invitation_status)
    }
  },

  methods: {
    ...mapActions(useVendorStore, ['updateUsername']),

    setInvitationStatus() {
      if (this.data.invitation_status === 'pending') {
        return 'Pending...'
      }

      if (this.data.invitation_status === 'accepted') {
        return 'Accepted'
      }

      if (this.data.invitation_status === 'rejected') {
        return 'Rejected'
      }

      return ''
    },

    async updateUsernameHandler(val) {
      try {
        await this.updateUsername(val)
        this.invitation_status = this.setInvitationStatus()
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
.vendor-profile-techside-settings {
  .el-input {
    @apply t-w-[312px];
  }

  .el-input__inner {
    @apply t-rounded-lg;
  }

  .el-radio__label {
    @apply t-hidden;
  }

  .el-button {
    @apply t-text-blue-500 t-rounded-md;
  }
}
</style>
