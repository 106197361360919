var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-times-row" },
    [
      _vm.item.arrival
        ? _c("job-card-info-block", {
            attrs: {
              icon: "sign-in-alt",
              text: _vm.convert(_vm.item.arrival),
              small: "",
              solid: _vm.solid
            }
          })
        : _vm._e(),
      _vm.item.departure
        ? _c("job-card-info-block", {
            attrs: {
              icon: "sign-out-alt",
              text: _vm.convert(_vm.item.departure),
              small: "",
              solid: _vm.solid
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }