var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      staticClass: "t-pt-4",
      attrs: { type: "card" },
      model: {
        value: _vm.selectedTab,
        callback: function($$v) {
          _vm.selectedTab = $$v
        },
        expression: "selectedTab"
      }
    },
    [
      _c(
        "el-tab-pane",
        {
          staticClass: "t-pt-2",
          attrs: { name: "items", label: "Cart items" }
        },
        [
          _c("item-vendors-tabs"),
          _c(
            "div",
            { staticClass: "t-pl-10 t-pr-6 t-py-6 t-bg-gray-100" },
            [
              _vm.vendor === "pilkington"
                ? _c("div", { staticClass: "t-text-right" }, [
                    _vm.selectedItemIds.length
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass:
                                  "t-bg-blue-500 hover:t-bg-blue-400",
                                attrs: { type: "primary", size: "medium" },
                                on: { click: _vm.openPilkingtonCheckout }
                              },
                              [_vm._v(" Proceed to checkout ")]
                            ),
                            _c("pilkington-checkout")
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm.vendor === "american"
                ? _c("div", { staticClass: "t-text-right" }, [
                    _vm.selectedItemIds.length
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass:
                                  "t-bg-blue-500 hover:t-bg-blue-400",
                                attrs: { type: "primary", size: "medium" },
                                on: { click: _vm.openAmericanCheckout }
                              },
                              [_vm._v(" Proceed to checkout ")]
                            ),
                            _c("american-checkout")
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : ["agd", "nm"].includes(_vm.vendor)
                ? _c("div", { staticClass: "t-text-right" }, [
                    _vm.selectedItemIds.length
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass:
                                  "t-bg-blue-500 hover:t-bg-blue-400",
                                attrs: { type: "primary", size: "medium" },
                                on: { click: _vm.openNielsenMollerCheckout }
                              },
                              [_vm._v(" Proceed to checkout ")]
                            ),
                            _c("nielsen-moller-checkout", {
                              on: {
                                partsUnavailable: _vm.removeUnavailableParts
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _c("place-order-form")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { name: "orders", label: "Orders" } },
        [_c("order-vendors-tabs", { attrs: { suppliers: _vm.suppliers } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }