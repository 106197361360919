var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.data,
        rules: _vm.rules,
        "label-width": "140px",
        size: "small"
      }
    },
    [
      _c("consumer-select", {
        attrs: { consumerToCharge: _vm.data.consumerToCharge },
        on: {
          "update:consumerToCharge": function($event) {
            return _vm.$set(_vm.data, "consumerToCharge", $event)
          },
          "update:consumer-to-charge": function($event) {
            return _vm.$set(_vm.data, "consumerToCharge", $event)
          }
        }
      }),
      _c("amount", {
        attrs: { requestedAmount: _vm.data.amount },
        on: {
          "update:requestedAmount": function($event) {
            return _vm.$set(_vm.data, "amount", $event)
          },
          "update:requested-amount": function($event) {
            return _vm.$set(_vm.data, "amount", $event)
          }
        }
      }),
      _c("el-form-item", { staticStyle: { "word-break": "break-word" } }, [
        _c(
          "span",
          {
            staticClass: "undo-label-styles",
            staticStyle: { color: "#f56c6c" },
            attrs: { slot: "label" },
            slot: "label"
          },
          [_vm._v("NOTE:")]
        ),
        _c("div", [
          _vm._v(
            " When a link is generated, all previous payment links for this job will no longer be valid. "
          )
        ])
      ]),
      _c("el-form-item", [
        _vm.link
          ? _c(
              "div",
              { staticClass: "t-flex" },
              [
                _c("el-input", {
                  attrs: { readonly: "" },
                  model: {
                    value: _vm.link,
                    callback: function($$v) {
                      _vm.link = $$v
                    },
                    expression: "link"
                  }
                }),
                _c(
                  "el-tooltip",
                  { attrs: { content: "Copy link", placement: "top" } },
                  [
                    _c("i", {
                      staticClass:
                        "\n            el-icon-copy-document\n            t-ml-3 t-mr-1 t-mt-2 t-cursor-pointer\n            hover:t-text-blue-500\n          ",
                      on: {
                        click: function($event) {
                          return _vm.copy(_vm.link)
                        }
                      }
                    })
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              staticClass: "mb-2 mr-2",
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v(" Generate Link ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }