import * as R from 'ramda'
import googleMapsConfig from '@/constants/google-maps.config'
import { shedulerColors, warehouseColors } from '@/constants/sheduler.configs'
import { getReadableColor } from '@/scripts/helpers/scheduler.helpers'
import tinycolor from 'tinycolor2'
import throttle from 'lodash.throttle'
import { getTechSideSent, addJobMidTimes } from './job.helpers'
import TechLocationIcon from '@/components/Scheduler/helpers/TechLocationIcon'
import Vue from 'vue'
/* global google */

const LocationIcon = Vue.extend(TechLocationIcon)

export const getGeometryBounds = geometry => {
  if (google) {
    return R.pipe(
      R.propOr({}, 'bounds'),
      ({ northeast, southwest }) =>
        new google.maps.LatLngBounds(southwest, northeast)
    )(geometry)
  }
}

export const decodePolylinePath = geometry => {
  if (google) {
    return R.pipe(
      R.path(['overview_polyline', 'points']),
      google.maps.geometry.encoding.decodePath
    )(geometry)
  }
}

export const getTotalBounds = geometry => {
  if (google) {
    return R.pipe(
      R.map(getGeometryBounds),
      R.reduce(
        (totalBounds, bounds) => totalBounds.union(bounds),
        new google.maps.LatLngBounds()
      )
    )(geometry)
  }
}

export const getStateBounds = state =>
  R.pipe(
    R.filter(R.propEq('state', state)),
    getTotalBounds
  )

export const getMarkersFromGeometry = R.pipe(
  R.map(({ jobsAndWaypoints, id, color }) =>
    jobsAndWaypoints.map(job => ({ ...job, tech_id: id, color }))
  ),
  R.unnest,
  addJobMidTimes
)

export const checkColor = (color, readable) => {
  const newColor =
    R.isEmpty(color) || R.isNil(color) ? shedulerColors.default : color
  if (readable) {
    const readableColor = getReadableColor(newColor)
    if (typeof readableColor === 'string') {
      return readableColor
    }
    return `#${readableColor.toHex()}`
  }
  return newColor
}

export const getPolylineOptions = geometry => ({
  path: decodePolylinePath(geometry),
  options: {
    strokeColor: checkColor(geometry.color),
    strokeOpacity: 1,
    strokeWeight: 4
  }
})

const jobIcon = {
  path: googleMapsConfig.iconPath,
  anchor: { x: 15, y: 40 },
  labelOrigin: { x: 15, y: 17 }
}

const smallJobIcon = {
  path: googleMapsConfig.smallIconPath,
  anchor: { x: 10.5, y: 28 },
  labelOrigin: { x: 10.5, y: 10.5 }
}

const waypointIcon = {
  path: googleMapsConfig.waypointIconPath,
  anchor: { x: 15, y: 30 },
  labelOrigin: { x: 15, y: 10 }
}

const getIcon = R.cond([
  [R.prop('google_place_id'), R.always(waypointIcon)],
  [R.prop('random'), R.always(smallJobIcon)],
  [R.T, R.always(jobIcon)]
])

const getLabel = ({ scheduler_order, random }) => ({
  text: `${scheduler_order + 1}`,
  fontSize: random ? '14px' : '18px',
  color: '#FFF',
  fontWeight: 'bold'
})

export const getMarkerOptions = (marker, index, solid) => {
  return {
    position: marker.location,
    clickable: true,
    icon: {
      ...getIcon(marker),
      fillColor: checkColor(marker.color),
      fillOpacity: 1,
      strokeColor: getTechSideSent(marker)
        ? shedulerColors.success
        : checkColor(marker.color, true),
      strokeWeight: getTechSideSent(marker) ? 3 : 1
    },
    label: getLabel(marker),
    options: {},
    zIndex: index,
    opacity: solid ? 1 : 0.5
  }
}

export const getWarehouseMarkerOptions = warehouse => {
  return {
    position: R.pick(['lat', 'lng'])(warehouse),
    clickable: true,
    icon: {
      path: googleMapsConfig.warehouseIconPath,
      fillColor: warehouseColors[warehouse.type],
      fillOpacity: 1,
      strokeColor: tinycolor(warehouseColors[warehouse.type])
        .darken(20)
        .toString(),
      strokeWeight: 1,
      anchor: { x: 8, y: 8 }
    }
  }
}

export const getRouteStates = R.pipe(
  R.map(R.prop('state')),
  R.uniq,
  R.filter(R.identity)
)

export const getVisibleWarehouses = warehouses => viewport =>
  viewport
    ? warehouses.map(warehouse =>
      R.assoc('visible', viewport.contains(warehouse))(warehouse)
    )
    : []

export const findMarkerToSwap = throttle((markers, latLng, marker) => {
  if (google) {
    let minDistance = Number.MAX_SAFE_INTEGER
    let markerToSwap = null
    markers.forEach((marker, index) => {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(marker.location),
        latLng
      )
      if (distance <= minDistance) {
        minDistance = distance
        markerToSwap = marker
      }
    })
    if (minDistance < 1000) {
      return markerToSwap
    }
  }
}, 200)

export const getUnmappedLocations = R.pipe(
  R.prop('geometry'),
  R.filter(
    R.pipe(
      R.prop('hidden'),
      R.not
    )
  ),
  R.map(
    R.pipe(
      R.prop('unmapped'),
      R.sortBy(R.prop('scheduler_order'))
    )
  ),
  R.unnest
)

export const getColoredIconUrl = color => {
  const iconComponent = new LocationIcon({ propsData: { color } })
  iconComponent.$mount()
  const iconString = new XMLSerializer().serializeToString(iconComponent.$el)
  return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString)
}

export const getTechMarkerOptions = (techLocation, color) => {
  return {
    position: {
      lat: techLocation.latitude,
      lng: techLocation.longitude
    },
    icon: {
      url: getColoredIconUrl(color),
      anchor: { x: 14, y: 14 }
    },
    clickable: true,
    zIndex: 100
  }
}
