var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-h-1/2 t-py-6 t-px-8 t-border-0 t-border-b t-border-solid t-border-gray-200"
    },
    [
      _c(
        "div",
        { staticClass: "t-w-72 t-flex t-flex-col t-items-stretch " },
        [
          _c(
            "div",
            { staticClass: "t-font-medium t-text-gray-400 t-text-xs t-mb-2" },
            [_vm._v(" Inventory ")]
          ),
          _c(
            "el-select",
            {
              staticClass: "t-mt-1 t-mb-2",
              attrs: {
                filterable: "",
                placeholder: "Select shop",
                size: "mini"
              },
              model: {
                value: _vm.selectedShop,
                callback: function($$v) {
                  _vm.selectedShop = $$v
                },
                expression: "selectedShop"
              }
            },
            _vm._l(_vm.shops, function(i) {
              return _c("el-option", {
                key: i.id,
                attrs: { label: i.name, value: i.id }
              })
            }),
            1
          ),
          _vm.selectedShop && _vm.itemId
            ? _c("UnitCounts", {
                attrs: { itemId: _vm.itemId, shopId: _vm.selectedShop }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }