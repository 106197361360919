// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".pilk .el-dialog__header {\n  background-color: #A1D490;\n}\n.mygrant .el-dialog__header {\n  background-color: #568EFC;\n}\n.mygrant .el-dialog__header .el-dialog__title {\n  color: white !important;\n}\n.pgw .el-dialog__header {\n  background-color: #FFAA33;\n}\n.igc .el-dialog__header {\n  background-color: #103f78;\n}\n.igc .el-dialog__header .el-dialog__title {\n  color: white !important;\n}\n.service .el-dialog__header {\n  background-color: #FCBA03;\n}", "",{"version":3,"sources":["/app/src/components/WarehousePage/Suppliers/NationalLookup/Profile.vue"],"names":[],"mappings":"AAiEE;EACE,yBAAA;AArDJ;AAyDE;EACE,yBAAA;AAtDJ;AAuDI;EACE,uBAAA;AArDN;AA0DE;EACE,yBAAA;AAvDJ;AA2DE;EACE,yBAAA;AAxDJ;AAyDI;EACE,uBAAA;AAvDN;AA4DE;EACE,yBAAA;AAzDJ","file":"Profile.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.pilk .el-dialog__header {\n  background-color: #A1D490;\n}\n\n.mygrant .el-dialog__header {\n  background-color: #568EFC;\n}\n.mygrant .el-dialog__header .el-dialog__title {\n  color: white !important;\n}\n\n.pgw .el-dialog__header {\n  background-color: #FFAA33;\n}\n\n.igc .el-dialog__header {\n  background-color: #103f78;\n}\n.igc .el-dialog__header .el-dialog__title {\n  color: white !important;\n}\n\n.service .el-dialog__header {\n  background-color: #FCBA03;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
