<template>
  <div v-if="hasPhoneNumber" class="job-confirmation-details">
    <confirmation-countdown
      v-if="info.type === 'default' && schedulerJobsTimers[job.id]"
      @openConfirmationDialog="openConfirmationDialog"
      :jobId="job.id"
      :scheduler="true"
    />
    <div
      v-else
      class="confirmation-button"
      :class="{ [info.type]: true }"
      @click.stop="openConfirmationDialog"
    >
      <i class="material-icons" :class="{ [info.icon]: true }" />
      <span>{{ info.text }}</span>
    </div>
    <el-tooltip
      :content="info.date"
      v-if="info.date"
      placement="right"
      effect="light"
    >
      <span class="confimation-time">{{ info.time }} ago</span>
    </el-tooltip>
  </div>
</template>

<script>
import { distanceInWordsStrict } from 'date-fns'
import { call } from 'vuex-pathify'
import { humanDateTime } from '@/scripts/helpers/date.helpers'
import ConfirmationCountdown from '@/components/ConfirmationCountdown/Index.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['details', 'job'],
  computed: {
    ...mapGetters('confirmationsSettings', ['schedulerJobsTimers']),
    info () {
      let info = {}
      if (this.details !== null) {
        info = {
          type: 'warning',
          text: 'Sent',
          time: distanceInWordsStrict(new Date(), this.details.created_at),
          date: humanDateTime(this.details.created_at),
          icon: 'md-mark_chat_unread'
        }
        if (this.details.status_id === 2) {
          info.type = 'success'
          info.text = 'Confirmed'
          info.icon = 'md-mark_chat_read'
        } else if (this.details.status_id === 3) {
          info.type = 'danger'
          info.text = 'Declined'
          info.icon = 'md-sms_failed'
        }
      } else {
        info = {
          type: 'default',
          text: 'Send confirmation',
          icon: 'md-sms'
        }
      }
      return info
    },
    hasPhoneNumber () {
      const phone1 = this.job.contact_phone1
      const phone2 = this.job.contact_phone2
      const phone3 = this.job.customer_phone1
      const phone4 = this.job.customer_phone2
      return (phone1 && phone1.replace(/\D+/g, '').length === 10) ||
        (phone2 && phone2.replace(/\D+/g, '').length === 10) ||
        (phone3 && phone3.replace(/\D+/g, '').length === 10) ||
        (phone4 && phone4.replace(/\D+/g, '').length === 10)
    }
  },
  methods: {
    setConfirmationJobId: call('twilio/textMessages/jobConfirmation/setJobId'),
    setConfirmationJob: call('twilio/textMessages/jobConfirmation/setJob'),
    setConfirmationShopId: call('twilio/textMessages/jobConfirmation/setShopId'),
    openConfirmation: call('twilio/textMessages/jobConfirmation/open'),
    openConfirmationDialog () {
      this.setConfirmationJob(this.job)
      this.setConfirmationJobId(this.job.id)
      this.setConfirmationShopId(this.job.shop_id)
      this.openConfirmation()
    }
  },
  components: {
    ConfirmationCountdown
  }
}
</script>

<style lang="scss" scoped>
.job-confirmation-details {
  display: flex;
  align-items: center;
  margin-top: $padding-xxs;
  transform: translateY(10px);
  .confirmation-button {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    margin-right: $padding-xs;
    i {
      font-size: 12px;
      margin-right: $padding-xxs;
    }
    span {
      @include gb-label;
      font-weight: 600;
    }
    &:hover {
      span {
        text-decoration: underline;
      }
    }
    &.default {
      /* background: $sky-blue-bg; */
      i,
      span {
        color: $sky-blue;
      }
    }
    &.warning {
      i,
      span {
        color: $gold-orange;
      }
    }
    &.danger {
      i,
      span {
        color: $crimson-red;
      }
    }
    &.success {
      i,
      span {
        color: $jade-green;
      }
    }
  }
  .confimation-time {
    font-size: $font-xxs;
    color: $scheduler-icon-color;
  }
}
</style>
