<template>
  <base-layout :data="data">
    <div slot="title">Web Lead Received</div>

    <div>
      You have received a new web lead for shop {{shopName}}.
    </div>
  </base-layout>

</template>

<script>
import BaseLayout from './_BaseLayout';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    shopName() {
      const shop = this.$store.state.shops.find(
        obj => obj.id.toString() == this.data.details.shopId);

      return shop?.name;
    }
  },

  components: { BaseLayout }
}
</script>
