var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("collapse-top-transition", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.display,
            expression: "display"
          }
        ],
        staticClass: "global-search-settings-wrapper"
      },
      [
        _c(
          "div",
          { staticClass: "global-search-settings" },
          [
            _c(
              "div",
              { staticClass: "t-flex t-items-center" },
              [
                _c("el-switch", {
                  attrs: {
                    "active-text": "Optimized Search",
                    "inactive-text": "Exact Match",
                    value: _vm.settings.optimized
                  },
                  on: { input: _vm.setMode }
                }),
                _c("el-tooltip", [
                  _c(
                    "div",
                    {
                      staticClass: "global-search-settings-info",
                      attrs: { slot: "content" },
                      slot: "content"
                    },
                    [_vm._v(" " + _vm._s(_vm.info) + " ")]
                  ),
                  _c("div", [
                    _c("i", { staticClass: "material-icons md-info md-18" })
                  ])
                ])
              ],
              1
            ),
            _c("collapse-top-transition", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.settings.optimized,
                      expression: "!settings.optimized"
                    }
                  ],
                  staticClass: "global-search-settings-items"
                },
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "div",
                    { key: item.key },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { value: _vm.settings[item.key] },
                          on: {
                            input: function($event) {
                              return _vm.setValue(item.key, $event)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }