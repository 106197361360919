/* eslint-disable prefer-const */
import debounce from 'lodash.debounce'
import Vue from 'vue'

Vue.directive('focusout', {
  inserted(element, { value }, vnode) {
    let listening = false
    const addListeners = () => {
      if (!listening) {
        const callback = debounce(el => {
          if (!element.contains(document.activeElement) && value) {
            value()
            document.removeEventListener('focusin', callback)
            document.removeEventListener('mousedown', callback)
            listening = false
          }
        }, 300)

        document.addEventListener('focusin', callback)
        document.addEventListener('mousedown', callback)
        listening = true
      }
    }
    element.addEventListener('focusin', addListeners)
  }
})
