var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.added
    ? _c("i", { staticClass: "material-icons md-playlist_add_check md-18" })
    : _c("i", {
        ref: "check",
        staticClass: "material-icons md-playlist_add md-18"
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }