<template>
  <el-popover
    v-if="
      (data.isGlass === '1') &&
      shopHasVendors
    "
    placement="right"
    trigger="click">
    <p v-if="data.jobId"><strong>{{data.jobId}}</strong>: <span style="color: #999">{{data.partNumber}}</span></p>
    <p v-else><span style="color: #999">{{data.partNumber}}</span></p>
    <CollapseLeftTrans>
      <div>
        <Vendor
          v-for="(vendor, vendorName) in vendorData"
          :key="vendorName"
          v-bind="vendor"
          :type="vendorName"
          :part="part"
          :shopId="shopId"
          :warehouse="warehouse"
          @refresh-data="$emit('refresh-data')"
        />
        <new-vendor-available
          v-if="showNewVendorAvailable"
          :shopId="shopId"
        />
      </div>
    </CollapseLeftTrans>
    <template slot="reference">
      <el-tooltip
        v-if="data.isGlass === '1'"
        placement="top"
        class="t-ml-1 t-mr-5"
      >
        <div slot="content">Check Suppliers</div>
        <i
          v-if="data.isGlass"
          class="el-icon-shopping-cart-1 t-text-green-500 t-cursor-pointer
            t-transform t-duration-300 hover:t-scale-125"
          @click="checkVendors(data)"
        />
        <i v-else class="el-icon-loading t-text-green-500" />
      </el-tooltip>
    </template>
  </el-popover>
</template>

<script>
import Vendor from './Vendor.vue'
import CollapseLeftTrans from '@/components/Helpers/transitions/CollapseLeftTrans'
import NewVendorAvailable from './NewVendorAvailable.vue'

export default {
  components: {
    Vendor,
    CollapseLeftTrans,
    NewVendorAvailable
  },
  props: ['shopId', 'data', 'warehouse'],
  data: () => ({
    part: {},
    item: null,
    vendorData: null,
    shopHasVendors: true
  }),
  computed: {
    showNewVendorAvailable() {
      const selectedShop = this.$store.state.shops.filter((shop) => shop.id === this.shopId)

      if (selectedShop[0]?.address_state && this.vendorData) {
        return selectedShop[0]?.address_state === 'AZ' && !Object.hasOwn(this.vendorData, 'nm')
      }

      return false
    }
  },
  methods: {
    async checkVendors(i) {
      const details = await this.$unum.job()
        .post(`/shop/${this.shopId}/part-sourcing/vendor-credentials/`, i)

      if (details?.data?.vendors && Object.keys(details.data.vendors).length > 0) {
        this.vendorData = details.data.vendors

        // when used from job sourcing (warehouse), details returns part by id
        // when used from job page, part does not have an id, use passed part
        this.part = this.warehouse ? details.data.part : i

        const { data } = await this.$unum.warehouse()
          .get('items/inventory-details/by-number', {
            params: {
              shopId: this.shopId,
              number: i.partNumber
            }
          })

        this.item = data.data
      } else {
        this.$notify({
          title: 'No Vendors',
          message: 'You don\'t have any vendor integrations configured.' +
            'Please contact Glassbiller support for more info.',
          type: 'warning'
        })
        this.shopHasVendors = false
      }
    }
  }
}
</script>
