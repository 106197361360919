var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "consumer-taxes"
    },
    [
      _c("div", { staticClass: "taxes-label" }, [_vm._v("Default Taxes")]),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.shopTaxes,
            size: "mini",
            "row-class-name": _vm.rowClass
          },
          on: { "row-click": _vm.rowClick }
        },
        [
          _c("el-table-column", {
            attrs: { width: "38", "class-name": "table-checkbox" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return _vm._t("default", [
                      _c("i", {
                        staticClass: "material-icons md-18",
                        class: {
                          "md-check_box_outline_blank": !_vm.isChecked(row),
                          "md-check_box": _vm.isChecked(row)
                        }
                      })
                    ])
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", { attrs: { prop: "name", label: "Name" } }),
          _c("el-table-column", {
            attrs: { prop: "percentage", label: "Percentage" }
          }),
          _c("el-table-column", {
            attrs: { label: "Materials", width: "90" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return _vm._t("default", [
                      row.for_materials
                        ? _c("i", {
                            staticClass: "material-icons-outline md-18 md-done"
                          })
                        : _vm._e()
                    ])
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", {
            attrs: { label: "Labor", width: "60" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return _vm._t("default", [
                      row.for_labor
                        ? _c("i", {
                            staticClass: "material-icons-outline md-18 md-done"
                          })
                        : _vm._e()
                    ])
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }