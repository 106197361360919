<template>
  <div class="scheduler-team-list-filter">
    <div class="ui-input-label">Selected shops:</div>
    <el-select
      v-model="select"
      multiple
      v-bind="pickerOptions"
      :multiple-limit="cardView === 4 ? 1 : 0"
    >
      <el-option-group :label="org" v-for="(shops, org) in orgShops" :key="org">
        <el-option
          v-for="item in shops"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import * as R from 'ramda'

export default {
  props: ['value'],
  computed: {
    cardView: get('sch_v3/filters@cardView'),
    select: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    orgShops() {
      return R.pipe(R.groupBy(R.path(['organization', 'name'])))(this.shops)
    }
  },
  data() {
    return {
      pickerOptions: {
        placeholder: 'Select shops',
        collapseTags: true,
        filterable: true,
        popperClass: 'el-popper-teams'
      },
      shops: []
    }
  },
  methods: {
    async getShops() {
      const { data } = await this.$unum.sch().get('/data/shops')
      this.shops = data
      const value =
      this.value &&
      this.value.filter(v => this.shops.find(({ id }) => id === v))
      if (!R.equals(value, this.value) && this.shops.length) {
        this.$emit('input', value)
      }
    }
  },
  mounted() {
    this.getShops()
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/SchedulerTeamFilter";
</style>

<style lang="scss">
.el-popper-teams {
  max-width: 200px;
}
.scheduler-team-list-filter {
  .el-select {
    height: 40px;
    input {
      height: 40px !important;
    }
  }
}
</style>
