<template>
  <div class="routing-configuration">
    <h5>{{ title }}</h5>
    <el-form :model="data">
      <el-form-item
        v-for="item in config"
        :key="item.key"
        :label="`${item.label}:`"
        :prop="item.key"
      >
        <el-radio-group v-model="data[item.key]" v-if="item.type === 'radio'">
          <el-radio
            v-for="option in item.options"
            :key="option.value"
            :label="option.value"
          >
            {{ option.label }}
          </el-radio>
        </el-radio-group>
        <el-input-number
          v-bind="item.config"
          v-model="data[item.key]"
          v-else-if="item.type === 'number'"
          :disabled="isDisabled(item)"
        />
        <el-checkbox
          v-model="data[item.key]"
          v-else-if="item.type === 'checkbox'"
          :disabled="isDisabled(item)"
        />
        <div v-else-if="item.type === 'slider'" class="slider-container">
          <span>Minimum driving</span>
          <el-slider
            v-model="data[item.key]"
            v-bind="item.config"
            size="small"
            :disabled="isDisabled(item)"
          />
          <span>Balance jobs per tech</span>
        </div>
        <el-popover trigger="hover" placement="right" width="400" :style="{ verticalAlign: 'top' }">
          <div v-html="item.tooltip" :style="{ wordBreak: 'normal' }" />
          <v-icon name="info-circle" slot="reference" class="config-tooltip" />
        </el-popover>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as R from 'ramda'

export default {
  props: ['value', 'title', 'config'],
  computed: {
    data: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    isDisabled(item) {
      if (item.disable) {
        return R.pipe(
          R.pick(item.disable),
          R.values,
          R.any(R.identity)
        )(this.data)
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/RoutingConfiguration.scss';
</style>

<style lang="scss">
.routing-configuration {
  .el-form-item {
    margin-bottom: $padding-sm;
  }
  .el-form-item__label,
  .el-radio__label {
    text-transform: none;
    font-weight: 400;
  }
  .slider-container {
    position: relative;
    span {
      position: absolute;
      font-size: $font-xs;
      line-height: $font-xs;
      &:first-of-type {
        top: 0;
        left: 0;
      }
      &:last-of-type {
        bottom: 0;
        right: 0;
      }
    }
    display: inline-block;
    .el-slider {
      width: 200px;
    }
  }
}
</style>
