import Api from '@/services/Api'

export default class TwilioAccount {
  static async store (shopId, data) {
    const res = await Api.post(`/api/shops/${shopId}/twilioAccounts`, data)
    return res
  }

  static async update (data) {
    const res = await Api.post(`/api/twilioAccounts/${data.id}`, data)
    return res
  }
}
