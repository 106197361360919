var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        "append-to-body": "",
        "close-on-click-modal": false,
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        title: "Get Your Own Personalized Number",
        top: "5vh",
        "custom-class": "text-ad-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "center",
          staticStyle: { "font-size": "large", "white-space": "wrap" }
        },
        [
          _c("p", { staticStyle: { "word-break": "keep-all" } }, [
            _vm._v(
              " You are currently using the GlassBiller default texting number. You can continue to use this number, but you are missing out on all GlassBiller can do in regards to your shop's communication. Check out just a few of the great new features available with our texting packages: "
            )
          ]),
          _c("ul", [
            _c("li", { staticStyle: { "word-break": "keep-all" } }, [
              _vm._v(
                " Receive text messages from customers, technicians, sales reps, and anybody else you’d like right from GlassBiller. "
              )
            ]),
            _c("li", { staticStyle: { "word-break": "keep-all" } }, [
              _vm._v(
                " Search your database to find customers and others and link the text message thread to that customer, tech, or sales rep. "
              )
            ]),
            _c("li", { staticStyle: { "word-break": "keep-all" } }, [
              _vm._v(
                " Have multiple numbers. You may want one number that you send scheduling messages from, while you may want a different number that you text customers review links to your business, etc. "
              )
            ]),
            _c("li", { staticStyle: { "word-break": "keep-all" } }, [
              _vm._v(
                " Send confirmation texts quickly and easily to your customers so they can confirm your repair/replacement appointments with a simple reply, allowing you to see quickly on the job screen/scheduler who has confirmed. "
              )
            ])
          ]),
          _c("p", { staticStyle: { "word-break": "keep-all" } }, [
            _vm._v(
              " The GlassBiller texting feature will need to be turned on so if you would like to use this feature or to request a demonstration please contact support at "
            ),
            _c("a", { attrs: { href: "mailto:support@glassbiller.com" } }, [
              _vm._v(" support@glassbiller.com ")
            ]),
            _vm._v(" and mention you want to try the texting feature. ")
          ])
        ]
      ),
      _c("br"),
      _c("div", { staticClass: "texting-ad-image" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }