var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "calendar-job-card",
      class: { hidden: _vm.item.hidden },
      style: _vm.cardStyle,
      on: {
        click: _vm.card.action,
        dragstart: function($event) {
          return _vm.itemDrag(_vm.item)
        },
        dragend: function($event) {
          return _vm.itemDrag()
        }
      }
    },
    [
      _vm.color
        ? _c("div", {
            staticClass: "left-border",
            style: { background: _vm.color }
          })
        : _vm._e(),
      _c("div", { staticClass: "content" }, [
        _c("span", [_vm._v(" " + _vm._s(_vm.card.title) + " ")]),
        _vm.card.extra
          ? _c("span", { staticClass: "extra", style: _vm.cardStyle }, [
              _vm._v(" " + _vm._s(_vm.card.extra) + " ")
            ])
          : _vm._e()
      ]),
      _c("v-icon", {
        staticClass: "icon",
        style: _vm.cardStyle,
        attrs: { name: _vm.card.icon, scale: "0.7" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }