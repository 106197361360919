<template>
  <el-upload
    ref="form"
    class="t-flex t-items-start t-flex-wrap t-px-6 t-py-3 t-border-0 t-border-t
           t-border-solid t-border-gray-300"
    action
    :limit="10"
    :multiple="true"
    :on-exceed="onExceed"
    :on-remove="onRemove"
    :on-change="onChange"
    :auto-upload="false"
    accept="image/*,audio/*,video/*,text/*,application/pdf">
    <el-button
      slot="trigger"
      circle
      class="t-mr-2">
      <i class="fa fa-paperclip"></i>
    </el-button>
    <el-button
      @click="emojiPicker = !emojiPicker"
      :disabled="totalAttachmentSize > 0"
      id="emoji-button"
      circle
      class="t-m-0"
    >
      <i class="fa fa-smile-o"></i>
    </el-button>
    <div class="t-relative">
      <Picker
        v-show="emojiPicker"
        v-click-outside="toggleEmojiPicker"
        @select="addEmoji"
        title="Pick your emoji…"
        :style="{
          position: 'absolute',
          bottom: '10px',
          left: '-60px',
          boxShadow: '5px 5px 10px 0 rgb(0, 0, 0, 0.08)',
          'z-index': '9999'
        }"
      />
    </div>
    <div class="t-mx-3 t-flex-grow">
      <el-input
        @keydown.enter.exact.native.prevent
        @keyup.enter.exact.native="submit"
        @keydown.ctrl.enter.exact.native="newline"
        @focus="emojiPicker = false"
        ref="body"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 3 }"
        :placeholder="bodyPlaceholder"
        :disabled="totalAttachmentSize > 0"
        v-model="body"
        resize="none">
      </el-input>
    </div>
    <el-button
      @click="submit"
      :loading="submitting"
      type="primary"
      icon="fa fa-paper-plane"
      class="t-bg-blue-500 hover:t-bg-blue-400 t-border-0"
      circle>
    </el-button>
    <div class="break-row"></div>
  </el-upload>
</template>

<script>
import { Picker } from 'emoji-mart-vue'
import ClickOutside from 'vue-click-outside'
import { ChatService } from '@/services/ChatService'
import { chatTypes } from '@/store/modules/chat/types'

export default {
  directives: {
    ClickOutside
  },
  data: () => ({
    images: {
      total: 0,
      loaded: 0
    },
    body: '',
    emojiPicker: false,
    totalAttachmentSize: 0,
    submitting: false
  }),
  computed: {
    activeChannel: function() {
      return this.$store.state.chat.activeChannel
    },
    bodyPlaceholder: function() {
      if (this.totalAttachmentSize) {
        return 'Message can not be sent along with attachments'
      } else {
        return `Message ${this.channelName}`
      }
    },
    username: function() {
      return this.$store.state.user.username
    },
    channelName: function() {
      if (this.activeChannel.friendlyName !== 'direct') {
        return this.activeChannel.uniqueName
      }
      if (this.activeChannel.uniqueName.split('-')[0] === this.username) {
        return this.activeChannel.uniqueName.split('-')[1]
      }
      return this.activeChannel.uniqueName.split('-')[0]
    }
  },
  watch: {
    body: function(value) {
      if (value) {
        ChatService.typingStarted()
      }
    },
    activeChannel: function(value) {
      if (value) {
        this.body = ''
        this.$refs.form.clearFiles()
        this.totalAttachmentSize = 0
      }
    }
  },
  methods: {
    toggleEmojiPicker: function(event) {
      let emojiButtonClicked = false
      for (const el of event.path) {
        if (el.id === 'emoji-button') {
          emojiButtonClicked = true
        }
      }
      if (!emojiButtonClicked && this.emojiPicker) {
        this.emojiPicker = false
      }
    },
    addEmoji: function(emoji) {
      this.body += emoji.native
      this.emojiPicker = false
      this.$refs.body.focus()
    },
    newline: function() {
      this.body = `${this.body}\n`
    },
    onExceed: function(files, fileList) {
      this.$message.warning('Not more than ten files per message can be attached.')
    },
    onRemove: function(file, fileList) {
      this.totalAttachmentSize -= file.size
    },
    onChange: async function(file, fileList) {
      if (!['image', 'audio', 'video', 'text', 'application'].includes(file.raw.type.split('/')[0])) {
        this.removeFileFromFileList(file, fileList)
        this.$message.warning('Selected filetype is not supported.')
        return
      }

      if (this.alreadyAttached(file, fileList)) {
        this.removeFileFromFileList(file, fileList)
        this.$message.warning('Selected filetype is already attached.')
        return
      }

      if (file.raw.type.split('/')[0] !== 'image' && file.size > 150000000) {
        this.removeFileFromFileList(file, fileList)
        this.$message.warning('Selected file exceeds the maximum allowed size of 150 MB.')
        return
      }

      if (this.totalAttachmentSize + file.size + 4800 > 150000000) {
        this.removeFileFromFileList(file, fileList)
        this.$message.warning('Total message size shouldn\'t exceed 150 MB.')
        return
      }

      if (this.body.length > 0) {
        try {
          await this.$messageBox.confirm(
            `Messages can not be sent along with attachments and will
            be cleared if you add an attachment. Do you wish to proceed ?`,
            {
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              type: 'warning'
            }
          )
          this.body = ''
        } catch {
          this.removeFileFromFileList(file, fileList)
          return
        }
      }

      this.$store.commit(
        chatTypes.namespace + chatTypes.mutations.setScrollBodyDown,
        true
      )
      this.totalAttachmentSize += file.size
    },
    removeFileFromFileList: function(file, fileList) {
      for (const key in fileList) {
        if (file.uid === fileList[key].uid) {
          this.$delete(fileList, key)
        }
      }
    },
    alreadyAttached: function(file, fileList) {
      let numberOfFilesWithSameName = 0
      for (const fileFromList of fileList) {
        if (file.name === fileFromList.name) {
          numberOfFilesWithSameName++
        }
      }
      if (numberOfFilesWithSameName > 1) {
        this.removeFileFromFileList(file, fileList)
      }
    },
    submit: async function() {
      if (this.body === '' && !this.$refs.form.uploadFiles.length) {
        this.$message.warning('Can\'t send an empty message.')
      } else {
        try {
          this.submitting = true
          if (this.body !== '') {
            await ChatService.sendMessage(this.body)
          } else {
            for (const key in this.$refs.form.uploadFiles) {
              let formData = new FormData()
              formData.append('file', this.$refs.form.uploadFiles[key].raw)
              await ChatService.sendMessage(formData)
            }
          }
          this.$refs.form.clearFiles()
          this.body = ''
          this.totalAttachmentSize = 0
          this.submitting = false
        } catch (err) {
          this.submitting = false
        }
      }
    }
  },
  components: {
    Picker
  }
}
</script>

<style lang="scss" scoped>
.break-row {
  flex-basis: 100%;
  height: 0;
}
</style>
