export const chartMasterList = ['Performance', 'Jobs', 'Aging', 'Scheduling']

export const dataSourcePerformance = {
  chart: {
    caption: 'PERFORMANCE',
    xaxisname: 'Month',
    numberPrefix: '$',
    paletteColors: '#7b68ad,#00A591,#6F9FD8',
    usePlotGradientColor: 0,
    showCanvasBorder: 0,
    legendBorderAlpha: 0,
    flatscrollbars: 0,
    scrollheight: 12,
    numvisibleplot: 12,
    plottooltext: '<b>$dataValue</b> in $seriesName in $label',
    showPlotBorder: 0,
    showValues: 0,
    decimals: 2,
    bgColor: '#FFF',
    bgAlpha: 0,
    showAlphaBorder: 0,
    legendShadow: 0,
    valueFontColor: '#ffffff',
    showXAxisLine: 1,
    xAxisLineColor: '#999999',
    divlineColor: '#999999',
    divLineDashed: 1,
    showAlternateHGridColor: 0,
    showBorder: 0,
    outCnvBaseFont: 'Verdana',
    // subcaptionFontBold: 0,
    // subcaptionFontSize: 14
    // plotSpacePercent: 15,
    // canvasbgAlpha: 0,
    // borderAlpha: 20,
    // plotBorderAlpha: 10,
  },
  type: 'scrollstackedcolumn2d',
  width: '100%',
  height: '450',
  dataFormat: 'json',
  categories: [
    {
      category: []
    }
  ],
  dataSet: [
    {
      seriesname: 'Invoices',
      data: [{value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}]
    },
    {
      seriesname: 'WorkOrders',
      data: [{value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}]
    },
    {
      seriesname: 'Quotes',
      data: [{value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}]
    }
  ]
}
export const dataSourceJobs = {
  chart: {
    caption: 'JOBS',
    xaxisname: 'Month',
    // yaxisname: '# of Jobs',
    paletteColors: '#00539C',
    usePlotGradientColor: 0,
    showCanvasBorder: 0,
    legendBorderAlpha: 0,
    flatscrollbars: 0,
    // scrollheight: 12,
    numvisibleplot: 12,
    plottooltext: '<b>$dataValue</b> $seriesName completed in $label',
    showPlotBorder: 0,
    showValues: 0,
    decimals: 2,
    bgColor: '#FFF',
    bgAlpha: 0,
    showAlphaBorder: 0,
    showBorder: 0,
    labelDisplay: 'auto',
    showLegend: 0,
    outCnvBaseFont: 'Verdana'
    // subcaption: 'Monthly - prior 12 months',
    // plotSpacePercent: 15,
    // canvasbgAlpha: 0
    // borderAlpha: 20,
    // plotBorderAlpha: 10,
    // legendShadow: 0,
    // valueFontColor: '#ffffff',
    // showXAxisLine: 1,
    // xAxisLineColor: '#999999',
    // divlineColor: '#999999',
    // divLineDashed: 1,
    // showAlternateHGridColor: 0,
    // subcaptionFontBold: 0,
    // subcaptionFontSize: 14
  },
  type: 'scrollcolumn2d',
  width: '100%',
  height: '250',
  dataFormat: 'json',
  categories: [
    {
      category: []
    }
  ],
  dataSet: [
    {
      seriesname: 'Jobs',
      data: [{value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}]
    }
  ]
}
export const dataSourceAging = {
  chart: {
    caption: 'INVOICE AGING',
    // subcaption: 'For all users in 2017',
    showpercentvalues: 0,
    // defaultcenterlabel: 'AGING INVOICES',
    aligncaptionwithcanvas: 0,
    captionpadding: 0,
    decimals: 1,
    plottooltext: '<b>$percentValue</b> of outstanding invoices are <b>$label</b> overdue',
    centerlabel: '$$value',
    bgColor: '#FFF',
    bgAlpha: 0,
    showAlphaBorder: 0,
    showBorder: 0,
    paletteColors: '#009900,#ffcc00,#ff6600,#cc0000',
    usePlotGradientColor: 0,
    outCnvBaseFont: 'Verdana'
  },
  type: 'doughnut2d',
  width: '100%',
  height: '250',
  dataFormat: 'json',
  data: [
    {
      label: '0-30 Days',
      value: '1000'
    },
    {
      label: '31-60 Days',
      value: '5300'
    },
    {
      label: '61-90 Days',
      value: '10500'
    },
    {
      label: '91+ Days',
      value: '18900'
    }
  ]
}
