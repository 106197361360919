import { mutationTypes } from './types'

const mutations = {
  [mutationTypes.setJobs](state, jobs) {
    state.data.jobs = jobs
  },
  [mutationTypes.setWaypoints](state, waypoints) {
    state.data.waypoints = waypoints
  },
  [mutationTypes.setAppointments](state, appointments) {
    state.data.appointments = appointments
  },
  [mutationTypes.addStatusToConfirmationHistory](state, payload) {
    if (state.data.jobs && state.data.jobs[payload.job_id]) {
      state.data.jobs[payload.job_id].job_confirmation_history.push(payload)
    }
  }
}

export default mutations
