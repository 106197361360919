var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-input",
    {
      directives: [
        {
          name: "currency",
          rawName: "v-currency",
          value: { precision: _vm.precision, allowNegative: _vm.allowNegative },
          expression: "{ precision, allowNegative }"
        }
      ],
      ref: "ref",
      attrs: {
        size: _vm.size,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder
      },
      on: { focus: _vm.onFocus, blur: _vm.onBlur },
      nativeOn: {
        keypress: function($event) {
          return _vm.setNegative($event)
        },
        keydown: function($event) {
          return _vm.handleTab($event)
        }
      },
      model: {
        value: _vm.number,
        callback: function($$v) {
          _vm.number = $$v
        },
        expression: "number"
      }
    },
    [
      _vm._t("append", null, { slot: "append" }),
      _vm._t("prepend", null, { slot: "prepend" }),
      _vm._t("prefix", null, { slot: "prefix" }),
      _vm._t("suffix", null, { slot: "suffix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }