<template>
  <div>
    <b-modal
      lazy
      title="Shop Message"
      header-bg-variant='primary'
      header-text-variant='light'
      size="lg"
      @show="dialogAboutToAppear"
      @shown="dialogAppeared"
      @hidden="dialogDisappeared"
      id="shopMessageModal">
      <div>
        <b-row>
          <b-col>
            <p class="mb-0">{{selectedMessage.created}}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="mb-0"><b>From:</b> {{selectedMessage.salesRep}}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="mb-0"><b>Mobile Job:</b> {{selectedMessage.mobileJobNumber}}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="mb-0"><b>Job:</b> <a v-if="selectedMessage.jobNumber > 0" :href="'/jobs/' + selectedMessage.jobNumber" target='_blank' class="tag" style="background-color: gray; display:inline;" v-on:click.stop>{{selectedMessage.jobNumber}}</a></p>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col>
            <p class="mb-0">{{selectedMessage.message}}</p>
          </b-col>
        </b-row>
      </div>
      <div slot="modal-footer" class="w-100">
        <b-button
          v-if="selectedMessage.message_status === 'unread'"
          variant="primary"
          size="sm"
          class="float-right"
          @click="toggleMessageStatus(selectedMessage)"
        >
          Mark as read
        </b-button>
        <b-button
          v-else
          variant="primary"
          size="sm"
          class="float-right"
          @click="toggleMessageStatus(selectedMessage)"
        >
          Mark as unread
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import Salesidejob from '@/scripts/objects/salesidejob'
import ShopMessage from '@/scripts/objects/shop_message'
import { UtilityMixin } from '@/scripts/helpers/utilities'
// import Moment from 'moment'

// import $ from 'jquery'

export default {
  data () {
    return {
      selectedShop: null
    }
  },
  props: {
    selectedMessage: Object
  },
  mounted () {
    // var self = this
    // self.$root.$emit('bv::show::modal', 'shopMessageModal')
  },
  methods: {
    dialogAboutToAppear: function () {
      // cannot access props with this hook
      
    },
    dialogAppeared: function () {
      // can access props with this hook
      
    },
    dialogDisappeared: function () {
      this.$root.$emit('bv::show::modal', 'mobileInboxModal')
    },
    toggleMessageStatus (message) {
      // Update message status in db
      var self = this
      let newStatus = ''
      if (message.message_status === 'read') {
        newStatus = 'unread'
      } else if (message.message_status === 'unread') {
        newStatus = 'read'
      }
      ShopMessage.updateShopMessageStatus(message, newStatus, function (res) {
        self.$root.$emit('bv::hide::modal', 'shopMessageModal')
        // if (self.currentInboxView === 'jobs') {
        //   // update jobs table
        //   self.$refs.mobileInboxTable.refresh()
        // } else if (self.currentInboxView === 'messages') {
        //   // update messages table
        //   self.$refs.shopMessagesTable.refresh()
        // }
      })
      // this.isBusy = false
    },
    toggleInboxView () {
      if (this.currentInboxView === 'jobs') {
        this.currentInboxView = 'messages'
      } else {
        this.currentInboxView = 'jobs'
      }
    }
  },
  // components: {
  // },
  mixins: [UtilityMixin]
}
</script>

<style scoped>

</style>
