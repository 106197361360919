<template>
  <div
    class="t-flex t-rounded-md t-shadow-sm t-mb-2"
    :class="{
      'hover:t-bg-gray-50 t-cursor-pointer': type === 'search'
    }"
    @click="select"
  >
    <div
      class="t-rounded-l-md t-w-12 t-h-12 t-text-base t-text-gray-600 t-flex
        t-items-center t-justify-center t-flex-shrink-0 t-border t-border-solid
        t-border-gray-200"
      :class="{
        't-bg-gray-200': !item.color && !item.isCatalog,
        't-bg-orange-200': !item.color && item.isCatalog
      }"
      :style="{ backgroundColor: item.color }"
    >
      <div
        class="t-flex t-items-center t-justify-center t-rounded-full
          t-bg-white t-w-8 t-h-8"
      >
        <i :class="item.isCatalog ? 'el-icon-folder' : 'el-icon-document'" />
      </div>
    </div>
    <div
      class="t-flex t-flex-grow t-justify-between t-border t-border-solid
        t-border-gray-200 t-border-l-0 t-rounded-r-md t-min-w-0"
    >
      <div
        class="t-px-4 t-py-1 t-flex t-flex-col t-justify-center t-flex-grow
          t-min-w-0"
      >
        <div class="t-text-gray-700 t-w-full t-truncate">
          {{ item.number }}
        </div>
        <div class="t-text-xs t-text-gray-500 t-mt-[1px] t-w-full t-truncate">
          {{ item.description }}
        </div>
      </div>
      <div v-if="type === 'parent'" class="t-flex t-items-center t-px-4">
        <el-tooltip
          effect="dark"
          content="Unlink"
          placement="top"
        >
          <i
            class="el-icon-circle-close t-text-xl t-cursor-pointer
              t-text-orange-500"
            @click="unassign"
          />
        </el-tooltip>
      </div>
      <div v-if="type === 'assign'" class="t-flex t-items-center t-px-4">
        <el-tooltip
          effect="dark"
          content="Remove"
          placement="top"
        >
          <i
            class="el-icon-delete t-text-base t-cursor-pointer t-text-red-500"
            @click="removeSelected"
          />
        </el-tooltip>
      </div>
      <div
        v-if="!item.isCatalog"
        class="t-min-w-[6rem] t-pr-4 t-flex t-items-center t-justify-end
          t-border-0 t-border-l t-border-solid t-bg-blue-50 t-text-base"
      >
        ${{ item.listPrice }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    type: {
      validator: function (value) {
        return ['search', 'parent', 'assign'].indexOf(value) !== -1
      }
    }
  },
  methods: {
    select: function() {
      this.$emit('selected')
    },
    removeSelected: function() {
      this.$emit('removed')
    },
    unassign: async function() {
      this.$emit('unassigned')
    }
  }
}
</script>
