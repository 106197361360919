var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", { attrs: { data: _vm.data } }, [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("SMS Received")
    ]),
    _c("div", [
      _vm._v(
        " " +
          _vm._s(_vm.shopName) +
          " has received an sms from " +
          _vm._s(_vm.phoneNumber) +
          ". "
      ),
      _c("br"),
      _c("br"),
      _c("p", [
        _c("b", [_vm._v("Message:")]),
        _c("br"),
        _vm._v(_vm._s(_vm.data.details.smsBody))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }