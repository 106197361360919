var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticStyle: { width: "100%" }
    },
    [
      _vm._l(_vm.value, function(suggestion, index) {
        return [
          _c(
            "li",
            {
              key: index,
              ref: "autoComplete:" + index,
              refInFor: true,
              staticClass: "autocomplete-result",
              class: { "is-active": _vm.isActive(index) },
              on: {
                mouseover: function($event) {
                  return _vm.mouseOver(index)
                },
                mousedown: function($event) {
                  return _vm.suggestionClick(index)
                }
              }
            },
            [
              _c(
                "a",
                {
                  class: { "white-text": _vm.isActive(index) },
                  staticStyle: { "text-decoration": "none" },
                  attrs: { href: "javascript:void(0)" }
                },
                [
                  _c(
                    "div",
                    {
                      class: {
                        "label-commercial-customer": suggestion.is_commercial,
                        "label-individual-customer": !suggestion.is_commercial
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          class: { "suggestion-info-block": true, strong: true }
                        },
                        [
                          suggestion.is_commercial
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(suggestion.company_name) +
                                    " Contact (" +
                                    _vm._s(suggestion.full_name) +
                                    ")"
                                )
                              ])
                            : _c("span", [_vm._v(_vm._s(suggestion.full_name))])
                        ]
                      ),
                      _c("div", { staticClass: "secondary" }, [
                        _vm._v(" Small stuff ")
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }