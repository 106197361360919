<template>
  <div class="bay-view">
    <div class="job-list">
      <scheduler-left-card-list />
    </div>
    <div class="bay-view-list-container">
      <bay-job-card-list
        v-for="bay in bays"
        :key="bay.id"
        v-bind="bay"
        :items="getBayJobs(bay.id)"
      />
    </div>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import BayJobCardList from './BayJobCardList'
import SchedulerLeftCardList from '../SchedulerLeftCardList'
import actions from '@/store/modules/scheduler_v3/types'
import * as R from 'ramda'

export default {
  components: { BayJobCardList, SchedulerLeftCardList },
  computed: {
    jobs: get('sch_v3/bayJobs'),
    bays: get('sch_v3/bays'),
    context: sync('sch_v3/filters@context'),
    filters: get('sch_v3/newRequestFilters')
  },
  methods: {
    getBays: call(actions.getBays),
    getBayJobs(bay_id) {
      return R.pipe(R.pickBy(R.propEq('bay_id', bay_id)), R.values)(this.jobs)
    }
  },
  mounted() {
    this.context = 'inShop'
    this.getBays()
  },
  watch: {
    filters(filters, old) {
      if (!R.equals(filters, old)) {
        this.getBays()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './BayView.scss';
</style>
