var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gb-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: { mdi: "timeline", title: "Ticket Open Time" }
    },
    [
      _c("chart", {
        ref: "chart",
        staticClass: "earnings-chart",
        attrs: { "chart-data": _vm.data }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }