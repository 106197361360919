<template>
  <div>
    <b-modal
    id='addEditVendorDialog'
    :title="amEditing ? 'Edit Supplier' : 'Create Supplier'"
    header-bg-variant='primary'
    header-text-variant='light'
    @show='dialogShown'
    no-close-on-backdrop
    no-close-on-esc
    size='xl'
    >
      <VendorEdit :shop='shop' :parentVendor='localVendor' :inModal='true'/>
      <template v-slot:modal-footer>
        <v-icon
          class="loader-tech"
          v-if="saving"
          name="spinner"
          spin
        >{{ amEditing ? 'Editing Supplier' : 'Saving New Supplier' }}</v-icon>
        <b-button :disabled='saving' variant="dark" @click="cancelButton">
          Cancel
        </b-button>
        <b-button :disabled='saving' variant="dark" @click="saveButton">
          Save
        </b-button>
      </template>
    </b-modal>
    <VendorSearchDialog @selectClicked='selectedNationalVendor' @close='closeNationalVendor'/>
  </div>
</template>

<script>
import VendorEdit from '@/components/WarehousePage/VendorEdit.vue'
import Vendor from '@/scripts/objects/vendor'
import VendorSearchDialog from '@/components/modals/VendorSearchDialog.vue'
import { VendorMixin } from '@/scripts/helpers/vendor.helpers.js'
import * as R from 'ramda'

export default {
  props: ['shop', 'isModal', 'parentVendor', 'isMerge'],
  data () {
    return {
      localVendor: new Vendor({}),
      saving: false
    }
  },
  computed: {
    amEditing () {
      return (this.parentVendor !== null)
    }
  },
  methods: {
    cancelButton () {
      const self = this
      this.$root.$emit('bv::hide::modal', 'addEditVendorDialog')
      setTimeout(function() {
        self.$root.$emit('bv::show::modal', 'purchaseOrderViewEditModal')
      }, 100)
    },
    async saveButton () {
      if (this.isMerge) {
        if (!(await this.$messageBox.confirm('Are you sure you want to perform this merge? MERGING CANNOT BE UNDONE!!', 'NOTICE', { type: 'warning' }).catch(() => {}))) {
          return
        }
      }

      var self = this
      self.saving = true
      this.localVendor.createUpdateVendor(this.shop, function (response) {
        self.$root.$emit('bv::hide::modal', 'addEditVendorDialog')
        if (self.isModal && !self.amEditing) {
          self.$root.$emit('bv::show::modal', 'purchaseOrderViewEditModal')
        }
        setTimeout(() => {
          self.$root.$emit('newVendorAdded', response)
        }, 100)
      })
    },
    dialogShown () {
      if (this.parentVendor) {
        this.localVendor = new Vendor(R.clone(this.parentVendor))
      } else {
        this.localVendor = new Vendor({})
      }
      this.saving = false
    },
    selectedNationalVendor (val) {
      this.$root.$emit('bv::hide::modal', 'vendorSearchDialog')
      this.$root.$emit('bv::show::modal', 'addEditVendorDialog')
      const { g_address: g } = val
      var tempObj = {
        ...(g
          ? {
            address: g.address,
            city: g.city,
            state: g.state,
            zip: g.zip,
            g_address: g
          }
          : {
            address: val.address_street,
            city: val.address_city,
            state: val.address_state,
            zip: val.address_zip
          }),
        email: val.email,
        contact: val.manager,
        status: 'active',
        name: this.getVendorTitle(val.type) + ':' + val.name,
        phone: val.phone,
        fax: val.fax,
        shop_id: 4,
        color: this.getVendorColor(val.type),
        national_vendor_id: val.id
      }
      this.localVendor = new Vendor(tempObj)
    },
    closeNationalVendor () {
      this.$root.$emit('bv::hide::modal', 'vendorSearchDialog')
      this.$root.$emit('bv::show::modal', 'addEditVendorDialog')
    }
  },
  components: {
    VendorEdit,
    VendorSearchDialog
  },
  mixins: [VendorMixin]
}
</script>

<style>
  #addEditVendorDialog > div {
    margin-top: 75px !important;
  }
</style>
