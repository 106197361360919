// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".pdf-options-header {\n  width: 100%;\n  text-align: center;\n  overflow-y: hidden;\n  background: #f4f6f8;\n  padding: 6px 0 6px 0;\n}\n.pdf-options-header .title {\n  width: 100%;\n}\n.pdf-options-header .reset-button {\n  position: absolute;\n  right: 0px;\n  margin-right: 15px;\n}", "",{"version":3,"sources":["/app/src/components/AdminPage/PdfOptions/styles/PdfOptionHeader.scss","/app/src/components/AdminPage/PdfOptions/PdfOptionHeader.vue"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,oBAAA;ACYF;ADXE;EACE,WAAA;ACaJ;ADXE;EACE,kBAAA;EACA,UAAA;EACA,kBAAA;ACaJ","file":"PdfOptionHeader.vue","sourcesContent":[".pdf-options-header {\n  width: 100%;\n  text-align: center;\n  overflow-y: hidden;\n  background: #f4f6f8;\n  padding: 6px 0 6px 0;\n  .title {\n    width: 100%;\n  }\n  .reset-button {\n    position: absolute;\n    right: 0px;\n    margin-right: 15px;\n  }\n}",":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.pdf-options-header {\n  width: 100%;\n  text-align: center;\n  overflow-y: hidden;\n  background: #f4f6f8;\n  padding: 6px 0 6px 0;\n}\n.pdf-options-header .title {\n  width: 100%;\n}\n.pdf-options-header .reset-button {\n  position: absolute;\n  right: 0px;\n  margin-right: 15px;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
