var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "t-w-64 t-p-6 t-bg-gray-100 t-pt-12 t-flex-shrink-0 t-rounded-l-xl"
    },
    [
      _c("div", { staticClass: "t-flex t-items-center t-justify-center" }, [
        _c(
          "div",
          {
            staticClass:
              "t-w-36 t-h-36 t-rounded-full t-flex t-items-center t-justify-center",
            class: { "t-bg-white": !_vm.data.color },
            style: { background: _vm.data.color }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "t-w-32 t-h-32 t-rounded-full t-flex t-items-center\n          t-justify-center t-bg-gray-200"
              },
              [
                _vm.data.avatar
                  ? _c("el-avatar", {
                      attrs: {
                        shape: "circle",
                        size: 128,
                        src: _vm.data.avatar
                      }
                    })
                  : _c("span", { staticClass: "t-text-6xl t-text-gray-600" }, [
                      _vm._v(_vm._s(_vm.initials))
                    ])
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "div",
        {
          staticClass:
            "t-text-base t-font-medium t-mt-5 t-text-gray-700 t-text-center t-break-normal"
        },
        [_vm._v(" " + _vm._s(_vm.data.name) + " ")]
      ),
      _vm.isTech && _vm.hasRatings
        ? _c(
            "div",
            { staticClass: "t-mt-6 t-text-center" },
            [
              _c(
                "el-tooltip",
                { attrs: { content: _vm.rating, placement: "bottom" } },
                [
                  _c("el-rate", {
                    attrs: {
                      value: _vm.rating,
                      disabled: "",
                      colors: ["#F59E0B", "#F59E0B", "#F59E0B"],
                      "disabled-void-color": "#D1D5DB"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isTech && _vm.isTechsideConnected && _vm.phoneString
        ? _c(
            "div",
            {
              staticClass:
                "t-mt-6 t-text-center t-cursor-pointer hover:t-underline",
              on: {
                click: function($event) {
                  return _vm.openTextMessagingDialog(_vm.primaryPhone)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.phoneString) + " ")]
          )
        : _vm._e(),
      _c("el-divider", { staticClass: "t-my-6" }),
      !_vm.fetching
        ? _c("div", { staticClass: "t-space-y-6 t-text-sm t-text-gray-700" }, [
            _c(
              "div",
              { staticClass: "t-flex t-space-x-6 t-items-center" },
              [
                _c(
                  "el-tooltip",
                  { attrs: { content: "Vendor ID", placement: "top" } },
                  [_c("i", { staticClass: "material-icons-outline md-badge" })]
                ),
                _c("div", [_vm._v(_vm._s(_vm.data.id || "n/a"))])
              ],
              1
            ),
            _vm.isCSR || _vm.isSupplier || _vm.isSalesRep || _vm.isTech
              ? _c(
                  "div",
                  { staticClass: "t-flex t-space-x-6 t-items-center" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Primary Phone Number",
                          placement: "top"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "material-icons-outline md-phone"
                        })
                      ]
                    ),
                    _vm.primaryPhone
                      ? _c(
                          "div",
                          {
                            staticClass: "t-cursor-pointer hover:t-underline",
                            on: {
                              click: function($event) {
                                return _vm.openTextMessagingDialog(
                                  _vm.primaryPhone
                                )
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.primaryPhone) + " ")]
                        )
                      : _c("div", [_vm._v("n/a")])
                  ],
                  1
                )
              : _vm._e(),
            _vm.isCSR || _vm.isSupplier || _vm.isSalesRep || _vm.isTech
              ? _c(
                  "div",
                  { staticClass: "t-flex t-space-x-6 t-items-center" },
                  [
                    _c(
                      "el-tooltip",
                      { attrs: { content: "Email Address", placement: "top" } },
                      [
                        _c("i", {
                          staticClass: "material-icons-outline md-email"
                        })
                      ]
                    ),
                    _vm.primaryEmail
                      ? _c("div", [
                          _vm._v(" " + _vm._s(_vm.primaryEmail) + " ")
                        ])
                      : _c("div", [_vm._v("n/a")])
                  ],
                  1
                )
              : _vm._e(),
            _vm.isCSR || _vm.isSupplier || _vm.isSalesRep || _vm.isTech
              ? _c(
                  "div",
                  { staticClass: "t-flex t-space-x-6 t-items-center" },
                  [
                    _c(
                      "el-tooltip",
                      { attrs: { content: "Home Address", placement: "top" } },
                      [
                        _c("i", {
                          staticClass: "material-icons-outline md-home"
                        })
                      ]
                    ),
                    _vm.primaryAddress
                      ? _c("div", { staticClass: "t-break-normal" }, [
                          _vm._v(" " + _vm._s(_vm.primaryAddress) + " ")
                        ])
                      : _c("div", [_vm._v("n/a")])
                  ],
                  1
                )
              : _vm._e(),
            _vm.data.id
              ? _c(
                  "div",
                  { staticClass: "t-flex t-space-x-6 t-items-center" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: { content: "Personal Color", placement: "top" }
                      },
                      [
                        _c("i", {
                          staticClass: "material-icons-outline md-palette"
                        })
                      ]
                    ),
                    _c(
                      "el-form",
                      {
                        ref: "colorForm",
                        staticClass: "t-w-full",
                        attrs: { model: _vm.colorForm },
                        nativeOn: {
                          submit: function($event) {
                            $event.preventDefault()
                          }
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "color",
                              rules: {
                                required: true,
                                message: "Please select a color",
                                trigger: "change"
                              }
                            }
                          },
                          [
                            _c("el-color-picker", {
                              staticClass: "t-shadow-md t-rounded",
                              attrs: { size: "small" },
                              on: { change: _vm.colorChanged },
                              model: {
                                value: _vm.colorForm.color,
                                callback: function($$v) {
                                  _vm.$set(_vm.colorForm, "color", $$v)
                                },
                                expression: "colorForm.color"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.data.id
              ? _c(
                  "div",
                  { staticClass: "t-flex t-space-x-6 t-items-center" },
                  [
                    _c(
                      "el-tooltip",
                      { attrs: { content: "Status", placement: "top" } },
                      [
                        _c("i", {
                          staticClass: "material-icons-outline",
                          class: _vm.data.is_disabled
                            ? "md-cancel"
                            : "md-check_circle"
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "t-px-2 t-py-0.5 t-rounded t-text-white t-text-xs t-uppercase t-mr-3 t-cursor-pointer t-shadow-md",
                        class: _vm.data.is_disabled
                          ? "t-bg-red-500"
                          : "t-bg-green-500",
                        on: { click: _vm.toggleStatusHandler }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.data.is_disabled ? "Inactive" : "Active"
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isTech
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isLoading,
                        expression: "isLoading"
                      }
                    ],
                    staticClass:
                      "t-flex t-space-x-6 t-items-center no-padding-spinner",
                    attrs: {
                      "element-loading-background": "rgba(0, 0, 0, 0.0)"
                    }
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: { content: "Techside Account", placement: "top" }
                      },
                      [
                        _c(
                          "el-badge",
                          {
                            staticClass: "item",
                            attrs: { value: "new", type: "primary" }
                          },
                          [
                            _c("i", {
                              staticClass: "material-icons-outline md-link"
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.isTechsidePending && !_vm.isLoading
                      ? _c("div", [_c("techside-pending")], 1)
                      : _vm.isTechsideConnected && !_vm.isLoading
                      ? _c("div", [_c("techside-connected")], 1)
                      : _vm.isTechsideRejected && !_vm.isLoading
                      ? _c("div", [_c("techside-rejected")], 1)
                      : _vm.isTechsideTerminated && !_vm.isLoading
                      ? _c("div", [_c("techside-terminated")], 1)
                      : !_vm.isLoading
                      ? _c("div", [_c("techside-invitation")], 1)
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.isSalesRep
              ? _c(
                  "div",
                  { staticClass: "t-flex t-space-x-6 t-items-center" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: { content: "Techside Account", placement: "top" }
                      },
                      [
                        _c("i", {
                          staticClass: "material-icons-outline md-link"
                        })
                      ]
                    ),
                    _vm.data.invitation_status === "pending"
                      ? _c("div", [_vm._v(" Invite Pending... ")])
                      : _vm.data.invitation_status === "accepted"
                      ? _c("div", [_vm._v(" Invite Accepted ")])
                      : _vm.data.invitation_status === "rejected"
                      ? _c("div", [_vm._v(" Invite Rejected ")])
                      : _c("div", [_vm._v("n/a")])
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }