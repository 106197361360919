import * as R from 'ramda'

const oneValueInList = R.pipe(R.length, R.equals(1))

const zeroOrValue = R.when(R.gt(0), R.always(0))

const defaultModelRow = {
  name: 'Model...',
  model_id: 0,
  sub_model_id: 0
}

export const getVehicleDropdownModelRow = (list, veh_model, veh_sub_model) => {
  if (list) {
    return (
      R.pipe(
        // find the model with matching model name
        R.filter(R.propEq('model_id', veh_model)),
        R.cond([
          // if there's only one value, just set the dropdown to it
          [oneValueInList, R.prop(0)],
          // if there's more than one value, run the zero check and select the dd value
          [
            R.length,
            R.find(R.propEq('sub_model_id', zeroOrValue(veh_sub_model)))
          ]
        ])
      )(list) || defaultModelRow
    )
  }
  return defaultModelRow
}

export const parseCompatibleVehs = R.pipe(
  R.toPairs,
  R.map(
    R.applySpec({
      id: R.prop(0),
      label: R.prop(0),
      children: R.pipe(
        R.prop(1),
        R.toPairs,
        R.map(
          R.applySpec({
            id: R.prop(0),
            label: R.prop(0),
            children: R.pipe(
              R.path([1, 'years']),
              R.groupBy(R.prop('year')),
              R.toPairs,
              R.map(
                R.applySpec({
                  id: R.prop(0),
                  label: R.prop(0),
                  children: R.pipe(
                    R.prop(1),
                    R.map(
                      R.applySpec({
                        id: R.prop('style'),
                        label: R.prop('style')
                      })
                    )
                  )
                })
              )
            )
          })
        )
      )
    })
  )
)
