<template>
  <b-modal @show="dialogAboutToAppear" @hide="dialogAboutToDisappear" no-close-on-backdrop no-close-on-esc
  title="Job Tags" :id="modalID"
    header-bg-variant='primary'
    header-text-variant='light'
    size="lg">
    <div class="row">
      <div class="form-group col-md-2 text-right">
        <button type='button' style="width: 100%;" class='btn btn-primary' variant="primary" @click="openTagDialog(null)">New Tag</button>
      </div>
    </div>
    <hr style="margin-top:20px; margin-bottom:20px;">
    <div class="row">
      <div class="form-group col-md-12">
        <span style="display: inline-block;margin-top:10px;" v-for="(tag, index) in tags" :key="index" v-if="tag.status === 'active' || showInactive">
        <input type="checkbox" :value="tag.id" v-model="checkedTags"/>
        <a href="javascript:void(0)" @click="openTagDialog(tag)" class="tag" v-bind:style="{ 'color': blackOrWhite(tag.color), 'background': tag.color }">
          <font-awesome-icon :icon="faTag"/> {{tag.text}}</a>&nbsp;&nbsp;&nbsp;
        </span>
      </div>
    </div>
    <div slot="modal-footer" class='w-100'>
      <div class='float-left'>
        <input type="checkbox" v-model="showInactive"/> Show Inactive
      </div>
      <div class='float-right'>
        <img v-show="saving" style="width:18px;height:18px;margin-right:3px;" src='./../../assets/img/spinner.gif'>
        <button v-show="!saving" type='button' class='btn btn-secondary' variant="primary" @click="applyTags">Apply Tags</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faTag from '@fortawesome/fontawesome-free-solid/faTag'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import Tag from '@/scripts/objects/tag'
import Jobtag from '@/scripts/objects/jobtag'

export default {
  props: ['tags', 'jobtags', 'editTag', 'job_id', 'shop_id', 'modalId'],
  data () {
    return {
      checkedTags: [],
      showInactive: false,
      saving: false,
      modalID: this.modalId || 'jobtagModal'
    }
  },
  beforeDestroy: function () {
    this.$root.$off('addTemporaryTagToJobTags', this.addTemporaryTagToJobTagsListener)
  },
  created () {
    this.$root.$on('addTemporaryTagToJobTags', this.addTemporaryTagToJobTagsListener)
  },
  methods: {
    addTemporaryTagToJobTagsListener (tag) {
      this.checkedTags.push(tag.id)
    },
    dialogAboutToDisappear: function () {
      if (this.selectedTagToEdit === null) {
        if (this.modalID === 'rejectedJobTagModal' || this.modalID === 'queuedJobTagModal') {
          setTimeout(() => {
            this.$root.$emit('bv::show::modal', 'rejectedInvoicesModal')
          }, 200)
        }
      }
    },
    dialogAboutToAppear: function () {
      if (this.selectedTagToEdit != null) {
        this.selectedTagToEdit = null
      } else {
        this.checkedTags = []
        for (var i = 0; i < this.jobtags.length; i++) {
          this.checkedTags.push(this.jobtags[i].shop_tag_id)
        }
      }
      this.saving = false
    },
    cancelEdits: function (event) {
      event.preventDefault()
      this.$root.$emit('bv::hide::modal', this.modalID)
    },
    openTagDialog: function (editTag) {
      if (!editTag) {
        editTag = new Tag()
      } else {
        editTag = new Tag(editTag)
      }
      this.selectedTagToEdit = editTag

      setTimeout(() => {
        if (this.modalID === 'rejectedJobTagModal') {
          this.$root.$emit('bv::show::modal', 'rejectedNewTagModal')
        } else if (this.modalID === 'queuedJobTagModal') {
          this.$root.$emit('bv::show::modal', 'queuedNewTagModal')
        } else {
          this.$root.$emit('bv::show::modal', 'tagModal')
        }
      }, 200)
    },
    applyTags: function () {
      
      var newJobtags = []
      for (var i = 0; i < this.checkedTags.length; i++) {
        var id = -1
        for (var j = 0; j < this.jobtags.length; j++) {
          if (this.jobtags[j].shop_tag_id === this.checkedTags[i]) {
            id = this.jobtags[j].id
          }
        }
        var jobtag = Jobtag.createTemporaryJobTag(id, this.checkedTags[i], this.job_id)
        newJobtags.push(jobtag)
      }
      

      let self = this
      if (this.job_id && this.job_id > 0) {
        this.saving = true
        Jobtag.updateJobtags(this.shop_id, this.job_id, newJobtags, function (receivedJobTags) {
          self.saving = false
          
          if (self.modalID === 'rejectedJobTagModal') {
            
            self.$root.$emit('updateRejectedTags', receivedJobTags)
          } else if (self.modalID === 'queuedJobTagModal') {
            self.$root.$emit('updateQueuedTags', receivedJobTags)
          } else {
            self.$root.$emit('updateJobtags', receivedJobTags)
          }
          self.$root.$emit('bv::hide::modal', self.modalID)
        }, function (error) {
          
          self.saving = false
        })
      } else {
        this.$root.$emit('updateJobtags', newJobtags)
        this.$root.$emit('bv::hide::modal', this.modalID)
      }
    }
  },
  watch: {
    'jobtags': function (val) {
      
      this.checkedTags = []
      for (var i = 0; i < val.length; i++) {
        this.checkedTags.push(val[i].shop_tag_id)
      }
    }
  },
  computed: {
    selectedTagToEdit: {
      get () {
        return this.editTag
      },
      set (val) {
        
        this.$emit('update:editTag', val)
      }
    },
    SWOColor () {
      return Document.getSWOColor()
    },
    DISColor () {
      return Document.getDISColor()
    },
    PoPColor () {
      return Document.getPoPColor()
    },
    FIVColor () {
      return Document.getFIVColor()
    },
    QUOColor () {
      return Document.getQUOColor()
    },
    DLColor () {
      return Document.getDLColor()
    },
    SSMPicColor () {
      return Document.getSSMPicColor()
    },
    TSMPicColor () {
      return Document.getTSMPicColor()
    },
    OTHColor () {
      return Document.getOTHColor()
    },
    faTag () {
      return faTag
    }
  },
  components: {
    FontAwesomeIcon
  },
  mixins: [UtilityMixin]
}
</script>

<style>

</style>
