var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex" },
    [
      _c(
        "el-scrollbar",
        {
          staticClass: "t-flex-grow t-h-[700px] t-my-4 t-mr-2",
          attrs: {
            "wrap-class": "t-overflow-x-hidden t-flex-shrink-0 t-py-2 t-px-8"
          }
        },
        [
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-button", {
                attrs: { icon: "el-icon-refresh-right", circle: "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("refresh")
                  }
                }
              }),
              _c(
                "el-select",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "Select"
                  },
                  model: {
                    value: _vm.internalShops,
                    callback: function($$v) {
                      _vm.internalShops = $$v
                    },
                    expression: "internalShops"
                  }
                },
                _vm._l(_vm.shopOptions, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              ),
              _c("el-date-picker", {
                staticStyle: { "margin-left": "20px" },
                attrs: {
                  type: "month",
                  format: "MMM yy",
                  placeholder: "Pick a month"
                },
                model: {
                  value: _vm.internalMonth,
                  callback: function($$v) {
                    _vm.internalMonth = $$v
                  },
                  expression: "internalMonth"
                }
              }),
              _vm.monthIsSynced
                ? _c("i", {
                    staticClass:
                      "material-icons md-check_circle qbo-synced-icon",
                    staticStyle: { "margin-left": "20px" }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "qbo-sync-dialog-body" },
            [
              _c("el-divider", { staticClass: "t-my-6" }, [_vm._v("Invoices")]),
              _c("in-glassbiller-table"),
              _vm.notInGlassBiller.length > 0
                ? _c(
                    "div",
                    [
                      _c("el-divider", { staticClass: "t-my-6" }, [
                        _vm._v("Not in GlassBiller for selected shop(s)")
                      ]),
                      _c("not-in-glassbiller-table")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }