var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        "custom-class": "address-map-dialog",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        closed: _vm.clearExtra
      }
    },
    [
      _vm.shop_id
        ? _c(
            "div",
            { staticClass: "address-delivery-instructions" },
            [
              _vm.value && _vm.value.extra
                ? _c("div", [
                    _c("div", [_vm._v(" " + _vm._s(_vm.value.extra) + " ")]),
                    _c(
                      "a",
                      {
                        attrs: { href: "#", tabindex: -1 },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveInstructions(null)
                          }
                        }
                      },
                      [_c("span", [_vm._v("Remove instructions")])]
                    )
                  ])
                : _vm.addExtra
                ? [
                    _c("delivery-instructions-input", {
                      attrs: { address: _vm.value, "auto-focus": "" },
                      model: {
                        value: _vm.extra,
                        callback: function($$v) {
                          _vm.extra = $$v
                        },
                        expression: "extra"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "t-flex t-justify-end t-mt-2" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.clearExtra }
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              loading: _vm.saving
                            },
                            on: {
                              click: function($event) {
                                return _vm.saveInstructions(_vm.extra)
                              }
                            }
                          },
                          [_vm._v(" Save ")]
                        )
                      ],
                      1
                    )
                  ]
                : _c(
                    "a",
                    {
                      attrs: { href: "#", tabindex: -1 },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.addExtra = true
                        }
                      }
                    },
                    [_c("span", [_vm._v("Add delivery instructions")])]
                  )
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "address-map-dialog-container" }, [
        _c(
          "div",
          { staticClass: "address-map-wrapper" },
          [
            _c(
              "gmap-map",
              _vm._b({ ref: "mapRef" }, "gmap-map", _vm.defaultValues, false),
              [
                _vm.latLng
                  ? _c("gmap-marker", {
                      attrs: { position: _vm.latLng, icon: _vm.icon }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }