export var VendorMixin = {
  methods: {
    getVendorColor (vendor) {
      if (vendor === 'pilk') return '#A1D490'
      else if (vendor === 'mygrant') return '#568EFC'
      else if (vendor === 'pgw') return '#FFAA33'
      else if (vendor === 'igc') return '#103f78'
      else if (vendor === 'service') return '#FCBA03'
      else return '#B103FC'
    },
    getVendorTitle (vendor) {
      if (vendor === 'pilk') return 'Pilkington'
      else if (vendor === 'mygrant') return 'Mygrant'
      else if (vendor === 'pgw') return 'PGW'
      else if (vendor === 'igc') return 'IGC'
      else if (vendor === 'service') return 'Service'
      else return 'Other'
    }
  }
}

export const getAmountFromPriceLevel = (selectedPriceLevel, priceLevels, vendor, vendorObject) => {
  // get price level
  let priceLevel = null
  for (var i = 0; i < priceLevels.length; i++) {
    if (selectedPriceLevel === parseInt(priceLevels[i].id)) priceLevel = priceLevels[i]
  }
  if (!priceLevel) return null

  // get tier
  let amount = 0
  if (vendor === 'pilkington') {
    amount = vendorObject.cost
  } else if (vendor === 'mygrant' || vendor === 'pgw' || vendor === 'igc') {
    amount = vendorObject.cost
  } else if (vendor === 'american') {
    amount = vendorObject.cost
  } else if (vendor === 'inventory') {
    amount = vendorObject.cost
  }

  if (amount === 0) return null

  let tier = priceLevel.getPriceLevelTier(amount)

  if (tier === null) return null

  // get amount
  return tier.getAmountFromTier(amount)
}
