<template>
  <div
    class="scheduler-vendors"
  >
    <el-tag
      v-for="vendor in vendors"
      :key="vendor.id"
      :color="vendor.color"
      size="small"
      :style="{
        color: '#FFFF',
        borderColor: vendor.color
      }"
    >
      {{ vendor.name }}
    </el-tag>
  </div>
</template>

<script>
import { getBlackOrWhite } from '@/scripts/helpers/utilities'

export default {
  props: ['vendors'],
}
</script>

<style lang="scss">
.scheduler-vendors {
  display: flex;
  flex-wrap: wrap;
  .el-tag--small {
    font-size: $font-xxs;
    line-height: 10px;
    padding: 2px 4px;
    height: 16px;
    font-weight: 900;
    margin: 0 4px 4px 0;
    display: block;
  }
}

</style>