var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _c(
        "gb-card",
        {
          staticClass: "glaxis",
          attrs: { title: _vm.schema.title, mdi: _vm.schema.icon }
        },
        [
          _c(
            "div",
            {
              ref: "card",
              staticClass:
                "t-border t-border-solid t-border-gray-300 t-rounded-lg t-shadow-md\n        t-flex-shrink-0 t-relative t-bg-white t-w-full t-p-4 t-pb-0"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "t-flex t-flex-col t-space-y-4 md:t-flex-row md:t-space-x-6\n          md:t-space-y-0 "
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md:t-w-48 t-flex t-flex-col t-flex-shrink-0 t-items-center"
                    },
                    [
                      _c("CarImage", {
                        staticClass: "t-w-40 t-h-24 t-self-center",
                        attrs: { vehicle: _vm.vehicle }
                      }),
                      _vm.vehicle.plate_number &&
                      _vm.vehicle.plate_number.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "t-border-2 t-border-solid t-border-gray-300 t-rounded-md\n              t-mt-2 t-flex t-flex-col t-items-center t-px-3 t-py-1 t-shadow\n              t-cursor-pointer t-bg-gray-50",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "t-flex t-items-center" },
                                [
                                  _c("div", {
                                    staticClass:
                                      "t-w-1 t-h-1 t-bg-gray-400 t-rounded-full"
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "t-px-2 t-uppercase t-text-gray-500 t-text-2xs t-flex-grow"
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.selectedState) + " "
                                      )
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass:
                                      "t-w-1 t-h-1 t-bg-gray-400 t-rounded-full"
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "t-text-gray-700 t-tracking-wider"
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.vehicle.plate_number) + " "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "t-flex-grow t-flex t-flex-col md:t-flex-row t-items-center"
                    },
                    [
                      _vm.vehicle && _vm.vehicle.make_id && _vm.logoUrl
                        ? _c("img", {
                            staticClass:
                              "t-w-32 t-h-24 t-mx-2 t-flex-shrink-0 t-object-contain\n              t-inline-block md:t-hidden",
                            attrs: { src: _vm.logoUrl }
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "t-flex t-flex-col t-flex-grow" },
                        [
                          _c("div", { staticClass: "t-flex t-items-center" }, [
                            _c("div", { staticClass: "t-flex-grow" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "t-text-gray-700 t-font-medium"
                                },
                                [_vm._v(_vm._s(_vm.vehicleName))]
                              ),
                              _c(
                                "div",
                                { staticClass: "t-text-gray-600 t-text-sm" },
                                [_vm._v(_vm._s(_vm.vehicleStyle))]
                              )
                            ]),
                            _vm.vehicle && _vm.vehicle.make_id && _vm.logoUrl
                              ? _c("img", {
                                  staticClass:
                                    "t-w-32 t-h-24 t-mx-2 t-flex-shrink-0 t-object-contain\n                  t-hidden md:t-inline-block",
                                  attrs: { src: _vm.logoUrl }
                                })
                              : _vm._e()
                          ]),
                          _c(
                            "div",
                            { staticClass: "t-flex t-items-center" },
                            [
                              _c("gb-editable-data-row", {
                                attrs: {
                                  value: _vm.vin,
                                  label: "VIN",
                                  tooltip:
                                    "A policyholder often has more than one vehicle with the same make/model/yr on a policy, therefore, if available, the VIN may be sent to filter coverage confirmation information.",
                                  loading: false,
                                  type: "vin",
                                  pre: true,
                                  lazy: "",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "vehicle-claim-additional-info" },
                [
                  _c("gb-data-rows", {
                    attrs: { schema: _vm.schema.fields, data: _vm.lossNotice },
                    on: { change: _vm.onChange, input: _vm.onInput }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }