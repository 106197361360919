<template>
  <el-form :model="form" ref="form" @submit.prevent.native>
    <div class="t-text-gray-400 t-mb-6">Name</div>
    <el-form-item
      prop="name"
      :rules="{
        required: true,
        message: 'Please enter name',
        trigger: 'blur'
      }"
    >
      <el-input
        v-model="form.name"
        @change="updateNameHandler"
        @keydown.enter.prevent="yourMethod"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  data() {
    return {
      form: {
        name: ''
      }
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data'])
  },

  created() {
    this.form.name = this.data.name
  },

  methods: {
    ...mapActions(useVendorStore, ['updateName']),

    updateNameHandler(name) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await this.updateName(name)
            this.$root.$emit('vendor-name-updated', this.data.id)
          } catch (e) {
            this.$message.error(e.message)
          }
        }
      })
    },

    yourMethod() {
      console.log('here')
    }
  }
}
</script>
