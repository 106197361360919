// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".chat-image-view-dialog {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  overflow: hidden;\n}\n.chat-image-view-dialog .el-dialog {\n  margin: 0 auto !important;\n  height: 90%;\n  overflow: hidden;\n}\n.chat-image-view-dialog .el-dialog .el-dialog__body {\n  position: absolute;\n  left: 0;\n  top: 54px;\n  bottom: 0;\n  right: 0;\n  padding: 0;\n  z-index: 1;\n  overflow: hidden;\n  overflow-y: auto;\n}", "",{"version":3,"sources":["/app/src/components/Chat/Views/Channel/ViewImageDialog.vue"],"names":[],"mappings":"AAoCA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,wBAAA;MAAA,qBAAA;UAAA,uBAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,mBAAA;EACA,gBAAA;AAxBF;AAyBE;EACI,yBAAA;EACA,WAAA;EACA,gBAAA;AAvBN;AAwBM;EACI,kBAAA;EACA,OAAA;EACA,SAAA;EACA,SAAA;EACA,QAAA;EACA,UAAA;EACA,UAAA;EACA,gBAAA;EACA,gBAAA;AAtBV","file":"ViewImageDialog.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.chat-image-view-dialog {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n}\n.chat-image-view-dialog .el-dialog {\n  margin: 0 auto !important;\n  height: 90%;\n  overflow: hidden;\n}\n.chat-image-view-dialog .el-dialog .el-dialog__body {\n  position: absolute;\n  left: 0;\n  top: 54px;\n  bottom: 0;\n  right: 0;\n  padding: 0;\n  z-index: 1;\n  overflow: hidden;\n  overflow-y: auto;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
