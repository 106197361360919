<template>
  <div>
    <label v-if='title !== ""'>{{title}}</label>
    <Multiselect
      @select="optionSelected"
      v-model="values"
      :options="computedObjects"
      :multiple="singleSelection ? false : true"
      :close-on-select="closeOnSelect ? closeOnSelect : false"
      :clear-on-select="false"
      :hide-selected="false"
      :preserve-search="true"
      placeholder=""
      label="name"
      :limit='showAllSelected ? 99999 : displayLimit && displayLimit > 0 ? displayLimit : 4'
      :limit-text='maxString'
      track-by="id"
      :preselect-first="false"
      :selectLabel="hideSelectDeselectLabels ? '' : (selectLabel && selectLabel.length > 0 ? selectLabel : 'Press enter/click to select')"
      :deselectLabel="hideSelectDeselectLabels ? '' : (deselectLabel && deselectLabel.length > 0 ? deselectLabel : 'Press enter/click to deselect')"
      :selectedLabel="hideSelectDeselectLabels ? '' : (selectedLabel && selectedLabel.length > 0 ? selectedLabel : 'Selected')"
      :showLabels="!hideSelectDeselectLabels"
      @close="closeHandler"
      @open="openHandler"
      :clearOnSelect="clearOnSelect"
      :openDirection="openDirection != null && openDirection.length > 0 ? openDirection : ''"
    >

    <template v-if="clickToShowMore" slot="limit">
      <a href="javascript:void(0)" @click="showAllSelected = !showAllSelected"><strong class="multiselect__strong">{{values.length}} of {{objects.length}} selected</strong></a>
    </template>

    <template v-if="$scopedSlots['optionSlot'] != null" slot="option" slot-scope="props">
      <slot name="optionSlot" :props="props"></slot>
    </template>
    <template v-if="$scopedSlots['tagSlot'] != null" slot="tag" slot-scope="props">
      <slot name="tagSlot" :props="props"></slot>
    </template>

    <span v-if="noResultText && noResultText.length > 0" slot="noResult">{{noResultText}}</span>
    </Multiselect>
    <a v-show="clickToShowMore && showAllSelected && values.length > (displayLimit && displayLimit > 0 ? displayLimit : 4)" href="javascript:void(0)" @click="showAllSelected = !showAllSelected"><strong class="multiselect__strong">Show Less</strong></a>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  props: ['objects', 'selectedValues', 'title', 'selectLabel', 'deselectLabel',
    'hideSelectDeselectLabels', 'displayLimit', 'selectedLabel', 'close', 'open', 'noResultText', 'clearOnSelect',
    'openDirection', 'clickToShowMore', 'customLimitText', 'singleSelection', 'closeOnSelect'],
  data () {
    return {
      selectAll: false,
      showAllSelected: false
    }
  },
  methods: {
    closeHandler () {
      
      if (this.close) {
        this.close()
      }
    },
    openHandler () {
      
      if (this.open) {
        this.open()
      }
    },
    optionSelected (selectedOption, id) {
      let self = this
      self.values = []
      setTimeout(function () {
        if (selectedOption) {
          if (selectedOption.id === 'SELECTALL') {
            self.values = self.objects.slice(0)
          } else if (selectedOption.id === 'DESELECTALL') {
            self.values = []
          }
        }
      }, 100)
    },
    maxString (val) {
      if (this.customLimitText) {
        return this.customLimitText
      }
      return `${this.selectedValues.length} of ${this.objects.length} selected`
    }
  },
  computed: {
    shouldHideSelected () {
      if (this.selectedValues.length > 4) {
        return false
      }

      return true
    },
    areAllSelected () {
      // for (var i = 0; i < this.objects.length; i++) {
      //   var foundSelected = false
      //   for (var j = 0; j < this.values.length; j++) {
      //     try {
      //       if (this.objects[i].id === this.values[j].id) {
      //         foundSelected = true
      //         break
      //       }
      //     } catch (err) {}
      //   }
      //   if (!foundSelected) {
      //     return false
      //   }
      // }
      // return true
      // this is not as clean and precise but it should handle the situation
      var selectedValues = 0
      if (this.values && this.values.length) {
        selectedValues = this.values.length
      }
      return this.objects.length === selectedValues
    },
    computedObjects () {
      var computedObjects = []
      var selectAllOption = {}
      if (!this.singleSelection) {
        if (this.areAllSelected) {
          selectAllOption.name = 'Deselect All'
          selectAllOption.id = 'DESELECTALL'
        } else {
          selectAllOption.name = 'Select All'
          selectAllOption.id = 'SELECTALL'
        }
        computedObjects = [selectAllOption, ...this.objects]
      } else {
        computedObjects = [...this.objects]
      }
      return computedObjects
    },
    values: {
      get: function () {
        return this.selectedValues
      },
      set: function (changedVal) {
        for (var i = 0; i < changedVal.length; i++) {
          if (changedVal[i] && (changedVal[i].id === 'SELECTALL' || changedVal[i].id === 'DESELECTALL')) {
            changedVal.splice(i, 1)
          }
        }
        this.$emit('update:selectedValues', changedVal)
      }
    }
  },
  components: { Multiselect }
}
</script>
<style>
.multiselect__option--selected {
  background: #d4d4d4
}
.multiselect--active .multiselect__input {
  width:100% !important;
}
</style>
