<template>
  <el-dialog
    :visible.sync="visible"
    title='Ticket Details'
    width="750px"
    destroy-on-close
    top="24px"
    custom-class="support-detail-dialog"
    append-to-body
  >
    <corner-ribbon v-if='!loading' :hex="statusColor(data.status)" :label="data.status" position="left" />
    <div v-loading='loading'>
      <div v-if='data.support_ticket_attachments && data.support_ticket_attachments.length' 
        style="right: 10px;">
      </div>
      <el-row>
        <el-col :span='12'>
          <gb-data-row label='Id' :value="data.zoho_ticket_number"/> 
        </el-col>
        <el-col v-if='data.support_ticket_attachments && data.support_ticket_attachments.length' :span='12'>
          <div class="job-documents"
            v-for='(attachment, index) in data.support_ticket_attachments'
            :key='index'
          >
            <div class="document-list-item">
              <document-tag 
                @click.native="openDocPreview({...attachment, document_name: attachment.name, document_type: 'support' })" 
                :doc="{...doc, tag: attachment.name}">{{attachment.name}}</document-tag>
            </div>
          </div>
        </el-col>
      </el-row>
      <gb-data-row label='Current Status' :value="data.status"/> 
      <gb-data-row label='Date' type='date' :value="date(data.created)"/> 
      <gb-data-row label='Subject' :value="data.subject"/> 
      <gb-data-row label='Description' :value="data.description"/>
      <gb-data-row v-if="data.resolution_date" label='Resolved on' :value="date(data.resolution_date)"/>
      <gb-data-row v-if="data.resolution" label='Resolution' :value="data.resolution"/>
      <el-button type='text' @click='showComment = !showComment' icon="el-icon-circle-plus-outline">
        {{showComment ? "Close" : "Add"}} new comment
      </el-button>
      <comment-new v-if='showComment' :ticketId="data.zoho_ticket_id" @addedComment="addedComment" />
      <span v-if='data.support_ticket_events && data.support_ticket_events.length'>
        <el-divider/>
        <comment-card :data="data.support_ticket_events" />
      </span>
      <el-row>
        <div class='float-right'>
          <el-button @click="visible = false">Close</el-button>
        </div>
      </el-row>
    </div>
    <file-preview-dialog v-model="previewDialog" :doc="preview" :noDelete='true' />
  </el-dialog>
</template>

<script>
import moment from 'moment'
import { call, sync } from 'vuex-pathify'
import FilePreviewDialog from '@/components/modals/FilePreviewDialog/Index'
import DocumentTag from '@/components/JobPageV2/JobPageSideDrawer/JobDocuments/DocumentTag'
import { documentsConfig } from '@/constants/documents_v2.config'
import CommentNew from './CommentNew.vue'
import CornerRibbon from '@/components/Helpers/CornerRibbon.vue'
import CommentCard from './CommentCard.vue'
import { statusColor } from '@/constants/support-ticket.config.js'

export default {
  components: {
    FilePreviewDialog,
    DocumentTag,
    CommentNew,
    CommentCard,
    CornerRibbon
  },
  data() {
    return {
      data: {},
      loading: false,
      previewDialog: false,
      preview: null,
      showComment: false
    }
  },
  computed: {
    visible: sync('support/ticketVisible'),
    ticketId: sync('support/ticketId'),
    doc() {
      return documentsConfig['support']
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      const {data} = await this.$unum.support().get(`/ticket/detail?id=${this.ticketId}`) 
      this.data = {...data}
      this.loading = false
    },
    date(d) {
      return moment(d).format('MM/DD/YY hh:mm a')
    },
    openDocPreview(doc) {
      this.preview = doc
      this.previewDialog = true
    },
    addedComment() {
      this.showComment = false
      this.fetchData()
    },
    statusColor
  },
  watch: {
    visible(v) {
      if (v) {
        this.data = {}
        this.showComment = false
        this.fetchData()
      }
    },
    previewDialog(v) {
      if (!v) {
        this.preview = null
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.job-documents {
  display: flex;
  flex-wrap: wrap;
}
.document-list-item {
  margin-bottom: $padding-xss;
  margin-right: $padding-xss;
  cursor: pointer;
  &.expanded {
    padding: $padding-sm $padding-md;
    border: 1px solid $grayscale-3;
    border-radius: 3px;
    font-size: 14px;
    color: $grayscale-8;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 0;
  }
}
</style>
<style lang='scss'>
.support-detail-dialog {
  overflow: hidden;
  .el-dialog__header {
    padding-left: 100px;
  }
}
</style>