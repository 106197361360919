<template>
  <div class="select-parent">
    <span class="select-label">{{label}}</span>

    <el-select
      v-model="selected"
      :filterable="true"
      placeholder="Select"
      @change="updateShopDefaultQboAccountHandler"
      :disabled="disabled"
      :loading="loading"
    >
      <el-option
        key="System Default"
        label="System Default"
        value="System Default">
      </el-option>
      <el-option
        class="qbo-mapping-option"
        v-for="item in options"
        :key="item.Id"
        :label="item.Name"
        :value="item.Id">
          <quick-books-item-option v-bind="item" />
      </el-option>
    </el-select>
    <div class="item-account-name" v-if="selectedItem">
      {{ truncateString(selectedItem.IncomeAccountRef.name) }}
    </div>
  </div>
</template>

<script>
import QuickBooksItemOption from './QuickBooksItemOption.vue'
import { mapActions, mapState } from 'pinia'
import { useQboStore, gbExportItems } from '@/components/modals/QuickBooksAccountMappingDialog/qbo.store.js'

export default {
  components: {
    QuickBooksItemOption
  },

  props: {
    itemType: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      options: [],
      selected: 'System Default',
      loading: false
    }
  },

  computed: {
    ...mapState(useQboStore, ['shopId', 'defaults']),

    selectedItem () {
      return this.options.filter(o => o.Id === this.selected)[0]
    },

    label () {
      if (this.itemType.includes('Materials')) {
        return 'GB Materials'
      }

      if (this.itemType.includes('Labor')) {
        return 'GB Labor'
      }

      return this.itemType
    }
  },

  async created() {
    this.initialize()
  },

  methods: {
    ...mapActions(useQboStore, [
      'updateShopQboMapping',
      'getShopItemsFromQbo',
      'deleteShopQboMapping'
    ]),

    async initialize () {
      try {
        this.loading = true
        const items = await this.getShopItemsFromQbo(this.shopId, this.itemType)
        this.options = items || []

        const itemDefault = this.defaults.filter(
          item => item.gb_export_item === gbExportItems[this.itemType]
        )
        // Todo: handle default not found in qbo
        if (itemDefault && itemDefault.length > 0) {
          this.selected = itemDefault[0].qbo_account_id.toString()
        } else {
          this.selected = 'System Default'
        }
      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.loading = false
      }
    },

    async updateShopDefaultQboAccountHandler (val) {
      if (val === 'System Default') {
        // delete mapping for this type
        try {
          await this.deleteShopQboMapping(this.shopId, gbExportItems[this.itemType])
        } catch (e) {
          this.$message.error(e.message)
        }
      } else {
        // udpate mapping for this type
        try {
          await this.updateShopQboMapping(
            this.shopId,
            {
              gb_export_item: gbExportItems[this.itemType],
              qbo_account_id: val,
              qbo_account_name: this.selectedItem.Name
            }
          )
        } catch (e) {
          this.$message.error(e.message)
        }
      }
    },

    truncateString (name) {
      let formattedName
      if (name.length > 29) {
        formattedName = name.substring(0, 26) + '...'
      } else {
        formattedName = name
      }
      return formattedName
    }
  },

  watch: {
    shopId: function (val, oldVal) {
      if (val && val !== oldVal) {
        this.initialize()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .item-account-name {
    display: inline-block;
    margin-left: 20px;
  }
  .select-label {
    display: inline-block;
    width: 150px;
  }
  .select-parent {
    white-space: nowrap;
  }
</style>
