<template>
  <div :class='classes'>
    <input
      type="text"
      :placeholder="placeholder || ''"
      :ref="inRef || ''"
      v-model="internalSearchValue"
      @keydown.enter="localEnter"
      @keydown.down="localDown"
      @keydown.up="localUp"
      @keydown.tab="localTab"
      @keydown.delete="localDelete"
      @focus="localFocus"
      @blur="localBlur"
      style="width:85%" v-on:click.stop v-on:keydown.space.stop/>
    <span style='float: right;'>
      <v-icon v-if="loading" name="spinner" spin scale="1.2"/>
    </span>
  </div>
</template>

<script>
export default {
  props: ['inClass', 'placeholder', 'disabled', 'inStyle', 'inRef', 'searchValue', 'loading', 'blur'],
  computed: {
    classes: function () {
      var prop = ['form-control', 'form-control-sm', 'autocomplete-off', 'input-spinner']
      if (this.inClass && this.inClass.length > 0) {
        var classes = this.inClass.split(',')
        for (var i = 0; i < classes.length; i++) {
          if (classes[i] && classes[i].trim().length > 0) {
            prop.push(classes[i].trim())
          }
        }
      }
      return prop
    },
    internalSearchValue: {
      get () {
        return this.searchValue
      },
      set (val) {
        this.$emit('update:searchValue', val)
      }
    }
  },
  methods: {
    localEnter: function () {
      this.$emit('enter')
    },
    localDown: function () {
      this.$emit('down')
    },
    localUp: function () {
      this.$emit('up')
    },
    localTab: function () {
      this.$emit('tab')
    },
    localDelete: function () {
      this.$emit('delete')
    },
    localFocus: function () {
      this.$emit('focus')
    },
    localBlur: function () {
      this.$emit('blur')
    }
  }
}
</script>

<style scoped>
  input {
    border: none;
    background-color: transparent;
  }
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }
  v-icon {
    color: gray;
  }

</style>
