var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-p-3 t-w-44 t-h-36" }, [
    _c(
      "div",
      {
        staticClass:
          "t-flex t-flex-col t-items-center t-justify-center t-border t-border-solid t-border-gray-200\n      t-rounded-md t-shadow-sm t-bg-white t-h-full t-cursor-pointer t-px-3 t-relative",
        on: {
          click: function($event) {
            return _vm.$emit("selected")
          }
        }
      },
      [
        _vm.beta
          ? _c(
              "span",
              {
                staticClass:
                  "badge badge-primary t-absolute t-top-[-8px] t-left-[10px]"
              },
              [_vm._v("Beta!")]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "t-flex t-flex-col t-items-center t-justify-center t-space-x-2\n      t-mb-2 t-text-blue-600"
          },
          [
            _vm.label
              ? _c(
                  "div",
                  { staticClass: "t-text-sm t-mb-4 terminal-content" },
                  [_vm._v(_vm._s(_vm.label))]
                )
              : _vm._e(),
            _vm.device
              ? _c("img", {
                  staticClass: "t-text-blue-600 t-h-10 t-w-10",
                  attrs: { src: require("./images/desktop-computer.svg") }
                })
              : _c("img", {
                  staticClass: "t-text-blue-600 t-h-10 t-w-10",
                  attrs: { src: require("./images/credit-card.svg") }
                })
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }