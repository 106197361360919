import { types } from './types'
import { Message } from 'element-ui/packages/message'
import {
  userSettingsEndpoint,
  schedulerDataEndpoint,
  schedulerWaypointUrl,
  schedulerJobDetailsV2Endpoint,
  techsideCompleteLegacyUrl,
  schedulerJobUpdateAddressEndpoint,
  shopTechsUrl,
  schedulerAppointmentUrl,
  techUrl,
  schedulerMapUrl,
  schedulerWarehousesUrl,
  schedulerTechTotalsUrl
} from '@/constants/endpoints'
import {
  getSchedulerRequestParams,
  generateUpdateObject,
  generateBayUpdateObject
} from '@/scripts/helpers/scheduler_v2.helpers'
import * as R from 'ramda'
import { formatToDb } from '@/scripts/helpers/date.helpers'
import { getFormData } from '@/scripts/helpers/scheduler.helpers'
import { fixAppointment } from '@/scripts/helpers/appointment.helpers'
import objectDiff from 'object-diff'
import Api from '@/services/Api'
import LegacyApi from '@/services/LegacyApi'

const actions = {
  async [types.updateUserSettings](context, value) {
    await Api.post(userSettingsEndpoint, value)
  },
  async [types.getSchedulerData]({ state, commit }) {
    commit('SET_LOADING', true)
    const { data } = await Api.get(schedulerDataEndpoint, {
      params: getSchedulerRequestParams(state)
    })
    const { techs, bays, ...rest } = data
    commit('SET_TECHS', techs)
    commit('SET_BAYS', bays)
    commit('SET_DATA', rest)
    commit('SET_LOADING', false)
  },
  [types.mapUpdate]({ state, commit }, route) {
    commit('SET_DATA', route)
    commit(
      'SET_DATA_TO_UPDATE',
      route
    )
  },
  [types.updateSchedulerItems]({ state, commit }, { items, tech, date }) {
    if (tech) {
      const newValues = generateUpdateObject(
        formatToDb(date || state.filters.date),
        tech
      )(items)
      commit('SET_DATA', R.mergeDeepRight(state.data, newValues))
      commit(
        'SET_DATA_TO_UPDATE',
        R.mergeDeepRight(state.dataToUpdate, newValues)
      )
    }
  },
  [types.updateBayItems]({ state, commit }, { items, bay_id }) {
    if (bay_id) {
      const newValues = generateBayUpdateObject(
        formatToDb(state.filters.date),
        bay_id
      )(items)
      commit('SET_DATA', R.mergeDeepRight(state.data, newValues))
      commit(
        'SET_DATA_TO_UPDATE',
        R.mergeDeepRight(state.dataToUpdate, newValues)
      )
    }
  },
  async [types.postSchedulerUpdates]({ state, commit, dispatch }) {
    await Api.post(schedulerDataEndpoint, state.dataToUpdate)
    Message.success({ message: 'Scheduler data synced', duration: 900 })
    commit('SET_DATA_TO_UPDATE', {})
    if (state.filters.view === 'map') dispatch(types.getSchedulerMapData, true)
  },
  async [types.createRouteWaypoint]({ state, commit }, { waypoint, event }) {
    const { data } = await Api.post(schedulerWaypointUrl, waypoint)
    commit('SET_WAYPOINTS', R.assoc(data.id, data, state.data.waypoints))
    if (event) {
      event.to.removeChild(event.item)
    }
  },
  async [types.deleteWaypoint]({ state, commit }, id) {
    await Api.delete(`${schedulerWaypointUrl}/${id}`)
    commit('SET_WAYPOINTS', R.dissoc(id, state.data.waypoints))
  },
  async [types.getJobDetails]({ state, commit }, id) {
    commit('SET_LOADING', true)
    const { data } = await Api(schedulerJobDetailsV2Endpoint(id))
    commit('SET_JOB_DETAILS', data)
    commit('SET_LOADING', false)
  },
  async [types.updateJobDetailsValue]({ state, commit }, { value, key }) {
    const { jobDetails } = state
    commit('SET_JOB_DETAILS', R.assoc(key, value, jobDetails))
    const { id } = jobDetails
    commit('SET_JOBS', R.assocPath([id, key], value)(state.data.jobs))
    commit(
      'SET_DATA_TO_UPDATE',
      R.assocPath(['jobs', `${id}`], { [key]: value, id })(state.dataToUpdate)
    )
  },
  async [types.updateJobDetailsValues]({ state, commit }, values) {
    const { jobDetails } = state
    commit('SET_JOB_DETAILS', R.merge(jobDetails, values))
    const { id } = jobDetails
    commit('SET_JOBS', R.over(R.lensProp(id), R.mergeDeepLeft(values))(state.data.jobs))
    commit(
      'SET_DATA_TO_UPDATE',
      R.assocPath(['jobs', `${id}`], { ...values, id })(state.dataToUpdate)
    )
  },
  async [types.completeTechsideJob]({ commit, state }, id) {
    await LegacyApi.post(
      techsideCompleteLegacyUrl(id),
      getFormData({ isTechsign: true })
    )
    const { jobDetails } = state
    commit(
      'SET_JOB_DETAILS',
      R.assoc('techside_job_completed', true, jobDetails)
    )
  },
  async [types.updateJobAddressByPlaceId]({ commit, state }, { place_id, id }) {
    commit('SET_LOADING', true)
    const { data } = await Api.post(schedulerJobUpdateAddressEndpoint(id), {
      place_id
    })
    commit('SET_JOBS', R.assoc(id, data, state.data.jobs))
    commit('SET_JOB_DETAILS', data)
    commit('SET_LOADING', false)
    Message.success({ message: 'Job updated', duration: 900 })
  },
  async [types.getShopTechs]({ commit }, shopId) {
    const { data } = await Api.get(`${shopTechsUrl(shopId)}`)
    return data
  },
  async [types.saveAppointment]({ commit, state }, appointment) {
    const { data } = await Api.post(
      schedulerAppointmentUrl,
      fixAppointment(appointment)
    )
    commit('SET_APPOINTMENTS', R.assoc(data.id, data, state.data.appointments))
    Message.success({ message: 'Appointment synced', duration: 900 })
  },
  async [types.getTechInfo]({ state, commit }, { id, shop_id }) {
    const { data } = await Api({ url: techUrl(shop_id, id) })
    commit('SET_EDIT_TECH', data)
  },
  async [types.updateTechInList]({ commit, state }, updatedTech) {
    const diff = R.pick(
      [
        'color',
        'name',
        'status',
        'techside_invitation_status',
        'techside_username'
      ],
      objectDiff(state.editTech, updatedTech)
    )
    const index = state.techs.findIndex(R.propEq('id', updatedTech.id))
    commit(
      'SET_TECHS',
      R.over(R.lensIndex(index), tech => ({ ...tech, ...diff }), state.techs)
    )
    commit('SET_EDIT_TECH', null)
  },
  async [types.getSchedulerMapData](
    { state, commit, getters, rootGetters },
    skipBounds
  ) {
    commit('SET_LOADING', true)
    if (!skipBounds) commit('SET_BOUNDS', null)
    try {
      const params = {
        scheduled_date: formatToDb(state.filters.date),
        shops: state.filters.shops,
        combined: rootGetters.getUserCombineTechsValue
      }
      const { data } = await Api.get(schedulerMapUrl, { params })
      commit('SET_GEOMETRY', data)
      if (!skipBounds) commit('SET_BOUNDS', getters.routeStates[0])
    } catch (error) { }
    commit('SET_LOADING', false)
  },
  async [types.getSchedulerWarehouses]({ state, commit }) {
    const { data } = await Api.get(schedulerWarehousesUrl)
    commit('SET_WAREHOUSES', data)
  },
  async [types.getSchedulerTotals]({ state, commit }) {
    commit('SET_LOADING', true)
    const { data } = await Api.get(schedulerTechTotalsUrl, {
      params: getSchedulerRequestParams(state)
    })
    commit('SET_TECH_TOTALS', data)
    commit('SET_LOADING', false)
  },
  [types.addStatusToConfirmationHistory]({ commit }, payload) {
    commit('ADD_STATUS_TO_CONFIRMATION_HISTORY', payload)
  }
}

export default actions
