var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          staticClass: "t-pl-6",
          attrs: { "tab-position": "left" },
          model: {
            value: _vm.selectedVendor,
            callback: function($$v) {
              _vm.selectedVendor = $$v
            },
            expression: "selectedVendor"
          }
        },
        _vm._l(_vm.vendors, function(v) {
          return _c(
            "el-tab-pane",
            { key: v, attrs: { name: v.toLowerCase(), stretch: "" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(_vm._s(v))
              ]),
              v.toLowerCase() === _vm.selectedVendor
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.ordersFetching,
                          expression: "ordersFetching"
                        }
                      ],
                      staticClass: "t-pl-2",
                      staticStyle: {
                        "min-height": "150px",
                        "max-height": "500px",
                        "overflow-y": "auto"
                      }
                    },
                    [
                      !_vm.ordersFetching
                        ? _c("div", [
                            _vm.orders.length === 0
                              ? _c(
                                  "div",
                                  { staticClass: "t-text-center t-mt-4" },
                                  [_vm._v(" You don't have any orders yet ")]
                                )
                              : _c("div", { staticClass: "t-pl-2 t-pr-6" }, [
                                  v.toLowerCase() === "pilkington"
                                    ? _c(
                                        "div",
                                        [
                                          _vm._l(_vm.orders, function(order) {
                                            return _c("pilkington-order", {
                                              key: order.id,
                                              attrs: {
                                                order: order,
                                                supplierColor: _vm.getSupplierColor(
                                                  order.supplierId
                                                )
                                              }
                                            })
                                          }),
                                          _c("pilkington-order-details")
                                        ],
                                        2
                                      )
                                    : v.toLowerCase() === "american"
                                    ? _c(
                                        "div",
                                        [
                                          _vm._l(_vm.orders, function(order) {
                                            return _c("american-order", {
                                              key: order.id,
                                              attrs: {
                                                order: order,
                                                supplierColor: _vm.getSupplierColor(
                                                  order.supplierId
                                                )
                                              }
                                            })
                                          }),
                                          _c("american-order-details")
                                        ],
                                        2
                                      )
                                    : ["agd", "nm"].includes(v.toLowerCase())
                                    ? _c(
                                        "div",
                                        [
                                          _vm._l(_vm.orders, function(order) {
                                            return _c("nielsen-moller-order", {
                                              key: order.id,
                                              attrs: {
                                                order: order,
                                                supplierColor: _vm.getSupplierColor(
                                                  order.supplierId
                                                )
                                              }
                                            })
                                          }),
                                          _c("nielsen-moller-order-details")
                                        ],
                                        2
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _vm._l(_vm.orders, function(order) {
                                            return _c("glaxis-order", {
                                              key: order.id,
                                              attrs: {
                                                order: order,
                                                supplierColor: _vm.getSupplierColor(
                                                  order.supplierId
                                                )
                                              }
                                            })
                                          }),
                                          _c("glaxis-order-details")
                                        ],
                                        2
                                      )
                                ])
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]
          )
        }),
        1
      ),
      _c("div", { staticClass: "t-pl-10 t-pr-6 t-py-6 t-bg-gray-100" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }