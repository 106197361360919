<template>
  <b-modal no-close-on-backdrop no-close-on-esc
    @shown="dialogAppeared"
    @hidden="dialogDisappeared"
    title="EDI Status:"
    :id="modalId"
    header-bg-variant='primary'
    header-text-variant='light'>

    <div v-if="loading" class="row">
      <div style="font-size:22px; font-weight:bold" class="form-group col-6" :style="{'color': (internalEdiqueue.status.toLowerCase() == 'paid' || internalEdiqueue.status.toLowerCase() == 'accepted') ? 'green' : (internalEdiqueue.status.toLowerCase() == 'rejected') ? 'red' : internalEdiqueue.status.toLowerCase() == 'unknown' ? 'blue' : 'black'}">
        Loading Please Wait...
      </div>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div style="font-size:22px; font-weight:bold" class="form-group col-6" :style="{'color': (internalEdiqueue.status.toLowerCase() == 'paid' || internalEdiqueue.status.toLowerCase() == 'accepted') ? 'green' : (internalEdiqueue.status.toLowerCase() == 'rejected') ? 'red' : internalEdiqueue.status.toLowerCase() == 'unknown' ? 'blue' : 'black'}">
          {{ internalEdiqueue.status }}
        </div>
        <div class="form-group col-6">
          <a @click="manuallyChangeStatusButtonPressed" href="javascript:void(0)" class="label label-gray label-sm float-right">Manually Change Status</a>
        </div>
      </div>

      <div style="margin-top:10px" class="row">
        <div class="form-group col-12" v-html="internalEdiqueue.job_edi_message">
        </div>
      </div>
    </div>
    <div slot="modal-footer" style="margin-top:0px;" class='w-100'>
      <div class='float-right'>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Ediqueue from '@/scripts/objects/ediqueue'

export default {
  props: ['modalId', 'modalToReturnTo', 'ediStatusChangeModal', 'ediqueue', 'job_id', 'job_shop_id', 'returnToVariable'],
  data () {
    return {
      loading: false,
      ediqueueData: new Ediqueue(),
      openingStatusChange: false
    }
  },
  computed: {
    internalEdiqueue () {
      if (!this.ediqueue || this.ediqueue.job_id !== this.job_id) {
        
        return this.ediqueueData
      } else {
        
        return this.ediqueue
      }
    }
  },
  methods: {
    dialogAppeared () {
      let self = this
      self.loading = false
      if (!self.ediqueue || self.ediqueue.job_id !== this.job_id) {
        self.loading = true
        Ediqueue.getJobEdiqueue(self.job_shop_id, self.job_id, function (object) {
          
          self.ediqueueData = object
          self.loading = false
        }, function (error) {
          
          self.loading = false
          alert('There was an error loading this status.')
          self.$root.$emit('bv::hide::modal', this.modalId)
        })
      }
    },
    dialogDisappeared: function () {
      if (this.modalToReturnTo && this.modalToReturnTo.length > 0 && !this.openingStatusChange) {
        this.$emit('update:returnToVariable', true)
        this.$root.$emit('bv::show::modal', this.modalToReturnTo)
      } else {
        this.openingStatusChange = false
      }
    },
    manuallyChangeStatusButtonPressed () {
      if (!this.job_id || this.job_id === 'new' || this.job_id <= 0) {
        alert('Please save this job before changing the edi status.')
      } else {
        
        this.openingStatusChange = true
        this.$root.$emit('bv::show::modal', this.ediStatusChangeModal)
      }
    }
  }
}
</script>

<style>

</style>
