export const flags = {
  receive: {
    title: 'Receives',
    color: '#e072d4',
    ref_table: 'receive'
  },
  tpu: {
    title: 'Tech Pickups',
    color: '#72e07f',
    ref_table: 'tech_pickup'
  },
  return: {
    title: 'Returns',
    color: '#ffb442',
    ref_table: 'return'
  },
  partAddOn: {
    title: 'Part Add Ons',
    color: '#b485ff',
    ref_table: 'part_add_on'
  },
  bill: {
    title: 'Bills',
    color: '#ff8717',
    ref_table: 'bill'
  }
}
