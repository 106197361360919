import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'

export default class AutoAddPart extends ObjectTracker {
  id = -1
  shop_id = -1
  part_number = ''
  part_to_add = ''
  is_saved_part = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  // Auto Add Nags and Saved Parts
  static async createUpdateAutoAddParts (shopId, parentPartNo, partSuffix, childPartNos, callbackFunction = null, errorCallbackFunction = null) {

    return Api.post(`/api/shop/${shopId}/autoAddParts/${parentPartNo}`, {partSuffix: partSuffix, childParts: childPartNos})
  }

  static getAutoAddSavedPart (shopId, partId, callbackFunction = null, errorCallbackFunction = null) {
    return Api.get(`/api/shop/${shopId}/getAutoAddSavedPart/${partId}`).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static deleteAutoAddSavedPart (shopId, partId, is_saved_part, callbackFunction = null, errorCallbackFunction = null) {
    return Api.post(`/api/shop/${shopId}/deleteAutoAddSavedPart/${partId}`, {shopId: shopId, partId: partId, is_saved_part: is_saved_part}).then(res => {
      if (callbackFunction) {
        callbackFunction(res.data)
      }
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
