<template>
  <el-dialog
    center
    append-to-body
    width="900px"
    title="Messages"
    custom-class="text-messages-dialog"
    :visible="visible"
    :before-close="handleClose">
    <span>
      <el-row>
        <el-col :span="12">
          <threads
            ref="threads"
            class="text-messages-block"
            :threadsLoading="threadsLoading"
            :shouldReloadThreads="shouldReloadThreads"
            :shopPhoneNumber="shopPhoneNumber"
            :recipientPhoneNumber="recipientPhoneNumber"
            :threadToMarkUnread="threadToMarkUnread"
            @threadsLoading="threadsLoading = $event"
            @threadsReloaded="shouldReloadThreads = false"
            @openCustomerInfoModal="openCustomerInfoModal($event)"
            @setShopPhoneNumber="shopPhoneNumber = $event"
            @setRecipientPhoneNumber="recipientPhoneNumber = $event"
            @threadMarkedUnread="threadToMarkUnread = null"/>
        </el-col>
        <el-col :span="12">
          <thread
            class="text-messages-block"
            @reloadThreads="shouldReloadThreads = true"
            @markThreadUnread="threadToMarkUnread = $event"
            @openCustomerInfoModal="openCustomerInfoModal($event)"/>
        </el-col>
      </el-row>
    </span>
    <CustomerInfoDialog
      :visible="customerInfoVisible"
      :customerId="selectedCustomerId"
      @closed="customerInfoClosed"/>
  </el-dialog>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import Thread from './Thread'
import Threads from './Threads'
import CustomerInfoDialog from '@/components/modals/CustomerInfoDialog'

export default {
  data: () => ({
    threadsLoading: false,
    shouldReloadThreads: false,
    customerInfoVisible: false,
    selectedCustomerId: null,
    threadToMarkUnread: null,
    shopPhoneNumber: '',
    recipientPhoneNumber: ''
  }),
  computed: {
    visible: get('twilio/textMessages/visible'),
    phoneNumbers: get('twilio/phoneNumbers/forUser'),
    selectedThread: sync('twilio/textMessages/selectedThread')
  },
  async created () {
    await this.getPhoneNumbersForUser()
    await this.setDefaultPhoneNumber()
    this.$root.$on('startTextMessagingChat', this.startTextMessagingChat)
  },
  beforeDestroy() {
    this.$root.$off('startTextMessagingChat')
  },
  watch: {
    visible: async function (value) {
      if (value) {
        await this.getPhoneNumbersForUser()
        await this.setDefaultPhoneNumber()
      } else {
        this.selectedThread = {}
        this.recipientPhoneNumber = ''
      }
    }
  },
  methods: {
    open: call('twilio/textMessages/open'),
    close: call('twilio/textMessages/close'),
    getPhoneNumbersForUser: call('twilio/phoneNumbers/getForUser'),
    handleClose () {
      if (!this.threadsLoading) {
        this.close()
      }
    },
    openCustomerInfoModal (customerId) {
      this.selectedCustomerId = customerId
      this.customerInfoVisible = true
    },
    customerInfoClosed () {
      this.customerInfoVisible = false
      this.selectedCustomerId = null
    },
    async setDefaultPhoneNumber () {
      this.threadsLoading = true
      let usingShopDefaultPhone = false

      if (this.$job?.shop?.default_job_confirmation_phone_number_id) {
        // shop has default phone
        const shopDefaultPhoneNumber = this.phoneNumbers.find(x => x.id === this.$job.shop.default_job_confirmation_phone_number_id)

        if (shopDefaultPhoneNumber) {
          // user has access to shop default phone
          this.shopPhoneNumber = shopDefaultPhoneNumber.phone_number
          usingShopDefaultPhone = true
        }
      }
      
      if (!usingShopDefaultPhone) {
        for (const phoneNumber of this.phoneNumbers) {
          if (phoneNumber.default === 1) {
            this.shopPhoneNumber = phoneNumber.phone_number
            break
          }
        }
      }
      this.threadsLoading = false
    },
    async startTextMessagingChat (recipient) {
      recipient.phoneNumber = this.formatPhone(recipient.phoneNumber)
      await this.open()
      this.$refs.threads.startTextMessagingChat(recipient)
    },
    formatPhone (phone) {
      return phone.replace(/\D+/g, '')
    }
  },
  components: {
    Thread,
    Threads,
    CustomerInfoDialog
  }
}
</script>

<style lang="scss">
  .text-messages-dialog {
    @apply t-font-sans;
    .el-dialog__body {
      padding: 0 !important;
      border-top: 1px solid #ebebeb;
    }
  }

  .text-messages-block {
    height: 700px;
    overflow: auto;
  }
</style>
