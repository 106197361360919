<template>
  <div class="t-cursor-pointer">
    <span v-if='!checking && !check && vendor === "inventory" && vendorObject.qty && vendorObject.qty > 0'>
      <button
        :id="vendor + 'Popover-' + part.detail.partno"
        class="t-font-sans t-border-none t-rounded-full t-mt-1 t-text-085
             t-font-medium t-whitespace-no-wrap t-cursor-pointer t-bg-gray-300"
        @click.stop=""
      >
        <span class="t-bg-white t-text-red-600 t-rounded-full t-px-1">
          INV:{{ vendorObject.totalAvailableIncludingSubs }}
        </span>
        <span class="t-ml-1">
          ${{ vendorObject.cost.toFixed(2) }}
        </span>
        <span v-if="getAmountFromPriceLevel(selectedPriceLevel, pricelevels, vendor, vendorObject) !== null" class="t-text-purple-800 t-ml-1">
          ${{ getAmountFromPriceLevel(selectedPriceLevel, pricelevels, vendor, vendorObject) }}
        </span>
      </button>
      <InventoryPopover :id='vendor + "Popover-" + part.detail.partno' :type='vendor.charAt(0).toUpperCase() + vendor.slice(1)' :partDetail='part'  :shopId='shopId' :price='getAmountFromPriceLevel(selectedPriceLevel, pricelevels, vendor, vendorObject)' @addPart='addPartButtonPressed' :inventoryObject='vendorObject'/>
    </span>
  </div>
</template>

<script>
import InventoryPopover from '@/components/PartSearch/InventoryPopover.vue'
import { getAmountFromPriceLevel } from '@/scripts/helpers/vendor.helpers'

export default {
  props: ['vendor', 'part', 'checking', 'check', 'vendorObject', 'pricelevels',
    'shopId', 'mygrantResults', 'selectedPriceLevel', 'pgwResults'],
  data: function () {
    return {
      quantityLabel: '',
      localVendor: this.vendor,
      localPart: this.part
    }
  },
  methods: {
    getAmountFromPriceLevel,
    addPartButtonPressed (nagsPart, children, vendor, adhesiveAt, fromInventory, fromVendor) {
      this.$emit('addPart', nagsPart, children, vendor, undefined, undefined, true)
    }
  },
  components: {
    InventoryPopover
  }
}
</script>

<style>
</style>
