<template>
  <el-tabs v-model="selectedTab" type="card" class="t-pt-4">
    <el-tab-pane name="items" label="Cart items" class="t-pt-2">
      <item-vendors-tabs />
      <div class="t-pl-10 t-pr-6 t-py-6 t-bg-gray-100">
        <div v-if="vendor === 'pilkington'" class="t-text-right">
          <div v-if="selectedItemIds.length">
            <el-button
              @click="openPilkingtonCheckout"
              type="primary"
              size="medium"
              class="t-bg-blue-500 hover:t-bg-blue-400">
              Proceed to checkout
            </el-button>
            <pilkington-checkout />
          </div>
        </div>
        <div v-else-if="vendor === 'american'" class="t-text-right">
          <div v-if="selectedItemIds.length">
            <el-button
              @click="openAmericanCheckout"
              type="primary"
              size="medium"
              class="t-bg-blue-500 hover:t-bg-blue-400">
              Proceed to checkout
            </el-button>
            <american-checkout />
          </div>
        </div>
        <div v-else-if="['agd', 'nm'].includes(vendor)" class="t-text-right">
          <div v-if="selectedItemIds.length">
            <el-button
              @click="openNielsenMollerCheckout"
              type="primary"
              size="medium"
              class="t-bg-blue-500 hover:t-bg-blue-400">
              Proceed to checkout
            </el-button>
            <nielsen-moller-checkout
              @partsUnavailable="removeUnavailableParts"/>
          </div>
        </div>
        <place-order-form v-else />
      </div>
    </el-tab-pane>
    <el-tab-pane name="orders" label="Orders">
      <order-vendors-tabs :suppliers="suppliers" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import {
  shoppingCartActions,
  shoppingCartMutations
} from '@/store/modules/shopping-cart/types'
import PlaceOrderForm from './Items/PlaceOrderForm'
import ItemVendorsTabs from './Items/VendorsTabs'
import OrderVendorsTabs from './Orders/VendorsTabs'
import AmericanCheckout from './Items/AmericanCheckout'
import PilkingtonCheckout from './Items/PilkingtonCheckout'
import NielsenMollerCheckout from './Items/NielsenMollerCheckout'

export default {
  components: {
    PlaceOrderForm,
    ItemVendorsTabs,
    OrderVendorsTabs,
    AmericanCheckout,
    PilkingtonCheckout,
    NielsenMollerCheckout
  },

  props: {
    suppliers: Array
  },

  computed: {
    visible() {
      return this.$store.state.shoppingCart.isOpen
    },
    selectedTab: {
      get() {
        return this.$store.state.shoppingCart.selectedTab
      },
      set(value) {
        return this.$store.commit(shoppingCartMutations.setSelectedTab, value)
      }
    },
    vendor() {
      return this.$store.state.shoppingCart.items.selectedVendor
    },
    selectedItemIds() {
      return this.$store.state.shoppingCart.items.selectedItemIds
    },
    items() {
      return this.$store.state.shoppingCart.items.data
    }
  },

  watch: {
    selectedTab: function (value) {
      if (this.visible) {
        this.clearSelectedItemIds()
        if (value === 'items') {
          this.getItems()
          this.getItemsCount()
        }
        if (value === 'orders') {
          this.getOrders()
        }
      }
    }
  },

  methods: {
    getItems() {
      return this.$store.dispatch(shoppingCartActions.getItems)
    },
    getItemsCount() {
      return this.$store.dispatch(shoppingCartActions.getVendorsItemsCount)
    },
    getOrders() {
      return this.$store.dispatch(shoppingCartActions.getOrders)
    },
    clearSelectedItemIds() {
      return this.$store.dispatch(shoppingCartActions.clearSelectedItemIds)
    },
    openAmericanCheckout() {
      return this.$store.dispatch(shoppingCartActions.openAmericanCheckout)
    },
    openPilkingtonCheckout() {
      return this.$store.dispatch(shoppingCartActions.openPilkingtonCheckout)
    },
    openNielsenMollerCheckout() {
      return this.$store.dispatch(shoppingCartActions.openNielsenMollerCheckout)
    },
    removeUnavailableParts(itemIds) {
      if (itemIds.length === this.selectedItemIds.length) {
        this.$store.dispatch(shoppingCartActions.closeNielsenMollerCheckout)
        this.$store.dispatch(shoppingCartActions.clearSelectedItemIds)

        return this.$messageBox.alert('Selected parts are no longer available in the requested quantity. Try reducing part quantity of selected parts and try again.')
      } else if (itemIds.length) {
        for (var i = 0; i < itemIds.length; i++) {
          this.$store.dispatch(shoppingCartActions.unselectItem, {
            itemId: itemIds[i]
          })
        }

        return this.$messageBox.alert('Some of the selected parts are no longer available in the requested quantity. These parts have been removed from this order. Continue to order remaining parts or return to cart to adjust quantity and try again.')
      }
    }
  }
}
</script>
