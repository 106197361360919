var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        title: "Invalid Fields",
        id: "invalidFieldsModal",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg"
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("p", [
            _vm._v("Please fix the following errors before preceding:")
          ]),
          _c("p", [
            _c("b", [_c("span", { domProps: { innerHTML: _vm._s(_vm.text) } })])
          ])
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", variant: "primary" },
                on: { click: _vm.okButtonPressed }
              },
              [_vm._v("Ok")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }