<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      label-width="120px"
      hide-required-asterisk
      size="medium"
      id="nielsen-moller-credentials-form"
    >
      <el-row>
        <el-col :span="16">
          <el-form-item prop="account">
            <span slot="label" class="undo-label-styles">Account</span>
            <el-select v-model="form.account" placeholder="Select" style="width:300px; margin-right:10px;">
              <el-option
                v-for="item in credentials"
                :key="item.id"
                :label="`${item.name} - ${item.username} ${item.default ? '(default)' : ''}`"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <div class="t-flex">
            <el-tooltip
              content="Add Credentials"
              placement="top"
              :open-delay="300"
            >
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-plus"
                @click="createNew">
              </el-button>
            </el-tooltip>
            <el-tooltip
              content="Edit selected"
              placement="top"
              :open-delay="300"
              v-if="form.account"
            >
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="editSelected"
              />
            </el-tooltip>
            <el-tooltip
              content="Delete selected"
              placement="top"
              :open-delay="300"
              v-if="form.account"
            >
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="deleteSelected"
              />
            </el-tooltip>
            <el-tooltip
              v-if="showSetDefaultButton"
              content="Set selected as default"
              placement="top"
              :open-delay="300"
            >
              <el-button
                size="mini"
                type="success"
                plain
                icon="el-icon-check"
                @click="saveDefault"
              />
            </el-tooltip>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <add-edit-dialog
      v-if="shopId"
      :shopId="shopId"
      :edit="credentialsToEdit"
      vendor="nm"
      :visible.sync="addEditCredentialsDialogVisible"
      @updateCredentials="refreshCredentials"
    />
  </div>
</template>

<script>
import Shop from '@/scripts/objects/shop'
import AddEditDialog from './AddEditDialog'

export default {
  props: ['shopId', 'credentials'],
  data: () => ({
    form: {
      account: '',
      warehouses: [],
      defaultWarehouse: null
    },
    addEditCredentialsDialogVisible: false,
    credentialsToEdit: {},
    submitting: false
  }),
  created: function() {
    this.setDefaultCredentials()
  },
  computed: {
    showSetDefaultButton () {
      if (!this.credentials.length) {
        return false
      }

      const selectedAccountArr = this.credentials.filter(c => c.id === this.form.account)

      if (!selectedAccountArr.length) {
        return false
      }

      return !selectedAccountArr[0].default
    }
  },
  methods: {
    setDefaultCredentials: function () {
      if (!this.credentials.length) {
        return
      }

      const temp = this.credentials.filter(c => c.default === 1)

      this.form.account = (temp.length > 0 && temp[0].id) ? temp[0].id : this.credentials[0].id
    },

    createNew: function() {
      this.credentialsToEdit = {}
      this.addEditCredentialsDialogVisible = true
    },

    editSelected: function() {
      this.credentialsToEdit = this.credentials.filter(c => c.id === this.form.account)[0]
      this.addEditCredentialsDialogVisible = true
    },

    saveDefault: async function() {
      await Shop.setDefaultVendorCredentials({
        id: this.form.account
      })

      const credentials = await Shop.getVendorCredentials({
        shopId: this.shopId
      })

      this.$emit('updateCredentials', credentials)
      this.$message.success('Credentials updated')
    },

    deleteSelected: async function() {
      try {
        await this.$messageBox.confirm(
          'You sure you want to delete these credentials? All associated warehouses will be forgotten.',
          'Warning',
          {
            type: 'warning',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            beforeClose: async (action, instance, done) => {
              if (action !== 'confirm') {
                done()
                return
              }

              await Shop.deleteVendorCredentials({
                id: this.form.account
              })

              const credentials = await Shop.getVendorCredentials({
                shopId: this.shopId
              })

              this.form = {
                account: null,
                warehouses: [],
                defaultWarehouse: null
              }

              this.$message.success('Credentials deleted')

              this.$emit('updateCredentials', credentials)

              this.$nextTick(() => {
                this.setDefaultCredentials()
              })

              done()
            }
          }
        )
      } catch (error) {}
    },

    refreshCredentials: function({credentials, createdUpdatedId}) {
      this.$emit('updateCredentials', credentials)
      this.credentialsToEdit = {}
      this.$nextTick(() => {
        this.form.account = createdUpdatedId
      })
    }
  },
  components: {
    AddEditDialog
  }
}
</script>

<style lang="scss" scoped>
#nielsen-moller-credentials-form {
  .el-checkbox__label {
    display: none !important;
  }

  .el-checkbox__inner, .el-radio__inner {
    @apply t-border-gray-500;
  }

  .el-radio__label {
    display: none !important;
  }
}
</style>
