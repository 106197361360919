<template>
  <div>
    <div v-if="Object.keys(selectedThread).length === 0" style="padding-top: 150px; text-align: center;">
      <p>No conversation selected</p>
    </div>
    <div v-else v-loading="messagesLoading" id="text-message-thread-block">
      <div v-if="!messagesLoading" id="thread-header">
        <div id="customer-block">
          <div v-if="selectedThread.recipient_model" id="recipient-type-section">{{ selectedThread.recipient_model }}:</div>
          <div id="customer-phone-name-section">
            <div>
              <i class="el-icon-phone"></i>{{ formatPhone(selectedThread.recipient_phone_number) }}
            </div>
            <div v-if="selectedThread.recipient_name" class="ml-3">
              <span
                v-if="selectedThread.recipient_model === 'customer'"
                @click="openCustomerInfoModal(selectedThread.recipient_id)"
                class="recipient-customer">
                <i class="el-icon-user-solid"></i>{{ selectedThread.recipient_name }}
              </span>
              <span
                v-else-if="selectedThread.recipient_model === 'salesrep' && salesRep"
                @click="openSalesRepModal"
                class="recipient-customer">
                <i class="el-icon-user-solid"></i>{{ selectedThread.recipient_name }}
              </span>
              <span
                v-else-if="selectedThread.recipient_model === 'tech' && tech"
                @click="openTechModal"
                class="recipient-customer">
                <i class="el-icon-user-solid"></i>{{ selectedThread.recipient_name }}
              </span>
              <span v-else>
                <i class="el-icon-user-solid"></i>{{ selectedThread.recipient_name }}
              </span>
            </div>
          </div>
        </div>
        <div id="mark-unread">
          <el-tooltip class="item" effect="dark" content="Mark unread" placement="top-start">
            <el-button @click.stop="markUnread" circle>
              <span class="material-icons md-18 md-mark_chat_unread"></span>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div id="messages-block" ref="thread">
        <el-row v-for="i in messages" :key="i.id" class="pt-2">
          <el-col
            v-images-loaded.on.done="imagesLoaded"
            :span="20"
            :offset="i.direction === 1 ? 0 : 4"
            :style="i.direction === 2 ? 'text-align: right;' : ''">
            <div v-for="(media, index) in i.media" :key="index">
              <div v-if="media.type.substr(0, 5) === 'image'">
                <a :href="media.url" target="_blank">
                  <img :src="media.url" class="message-image">
                </a>
              </div>
              <div
                v-else
                class="message-media"
                :style="(i.media.length - 1 !== index || (i.media.length - 1 === index && i.body !== '')) ? 'margin-bottom: 5px;' : ''">
                <a :href="media.url" target="_blank">
                  <i class="el-icon-download"></i>
                  {{ media.type }}
                </a>
              </div>
            </div>
            <div class="message-block" :style="i.direction === 1 ? '' : 'justify-content: flex-end;'">
              <el-tooltip
                v-if="i.error_message !== null && i.direction === 2"
                class="message-error-tooltip"
                effect="dark"
                :content="i.error_message"
                placement="top">
                <i class="el-icon-warning mr-2"></i>
              </el-tooltip>
              <el-tooltip
                :disabled="i.user_id === null"
                effect="dark"
                :content="generateSentBy(i)"
                placement="bottom-end">
                <div
                  v-if="i.body !== ''"
                  class="message-body"
                  v-html="modifyBody(i.body)"
                  :class="i.direction === 1 ? 'incoming' : 'outgoing'">
                </div>
              </el-tooltip>
            </div>
            <div class="message-timestamp">
              {{ formatDateTime(i.created_at) }}
            </div>
          </el-col>
        </el-row>
        <div v-if="!messagesLoading && this.messages.length === 0" id="no-messages-yet"><i>Send a first message</i></div>
      </div>
      <div v-if="!messagesLoading" id="controls-block">
        <el-upload
          ref="form"
          class="upload-block"
          action
          :limit="10"
          :multiple="true"
          :on-exceed="onExceed"
          :on-remove="onRemove"
          :on-change="onChange"
          :auto-upload="false"
          accept="image/*,audio/*,video/*,text/*,application/pdf">
          <el-button slot="trigger" @click="emojiPicker = false" icon="el-icon-paperclip" circle></el-button>
          <el-button id="emoji-button" @click="emojiPicker = !emojiPicker" circle>
            <v-icon name="regular/grin"/>
          </el-button>
          <picker
            v-show="emojiPicker"
            v-click-outside="toggleEmojiPicker"
            @select="addEmoji"
            title="Pick your emoji…"
            :style="{ position: 'absolute', bottom: '64px', right: '50px', boxShadow: '5px 5px 10px 0 rgb(0, 0, 0, 0.08)' }" />
          <div id="text-message-input">
            <el-input
              @keydown.enter.exact.native.prevent
              @keyup.enter.exact.native="submit"
              @keydown.ctrl.enter.exact.native="newline"
              @focus="emojiPicker = false"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 3}"
              placeholder="Please enter your message"
              v-model="body">
            </el-input>
            <a href="javascript:void(0)" @click="setSelectTemplateDialogVisible(true)" class="pull-right mt-2">
              Select a template
            </a>
          </div>
          <el-button
            @click="submit"
            :loading="submitLoading"
            type="primary"
            icon="el-icon-position"
            circle>
          </el-button>
        </el-upload>
      </div>
    </div>
    <SelectTemplateDialog
      :name="selectedThread.recipient_name"
      @templateSelected="body = $event"
    />
    <SalesrepDialog
      v-if="salesRep"
      id="salesrepModal"
      :parentSalesrep="salesRep"
      :shopId="selectedThread.shop_id"
      :locations="locations"
      :commercialaccounts="commercialAccounts"
      :salesources="saleSources"
      @updated="findSalesRep($event)"
    />
    <TechDialog
      v-if="tech"
      :parentTech="tech"
      :shopId="selectedThread.shop_id"
      @updated="findTech($event)"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { get, call } from 'vuex-pathify'
import { Picker } from 'emoji-mart-vue'
import ClickOutside from 'vue-click-outside'
import imagesLoaded from 'vue-images-loaded'
import TwilioTextMessageThread from '@/scripts/objects/twilio_text_message_thread'
import SelectTemplateDialog from './Templates/SelectTemplateDialog'
import Salesrep from '@/scripts/objects/salesrep'
import Tech from '@/scripts/objects/tech'
import Location from '@/scripts/objects/location'
import Salesource from '@/scripts/objects/salesource'
import Commercialaccount from '@/scripts/objects/commercialaccount'
import SalesrepDialog from '@/components/modals/SalesrepDialog'
import TechDialog from '@/components/modals/TechDialog'

export default {
  directives: {
    imagesLoaded,
    ClickOutside
  },
  data: () => ({
    messages: [],
    images: {
      total: 0,
      loaded: 0
    },
    body: '',
    emojiPicker: false,
    totalAttachmentSize: 0,
    messagesLoading: false,
    submitLoading: false,
    selectTemplateDialogVisible: false,
    salesRep: null,
    tech: null,
    locations: [],
    commercialAccounts: [],
    saleSources: []
  }),
  computed: {
    textMessagesDialogVisible: get('twilio/textMessages/visible'),
    newMessageThreadId: get('twilio/textMessages/newMessageThreadId'),
    selectedThread: get('twilio/textMessages/selectedThread'),
    undeliveredMessage: get('twilio/textMessages/undeliveredMessage'),
    userId: get('user.id')
  },
  watch: {
    selectedThread: function (thread) {
      this.messages = []
      this.body = ''
      this.totalAttachmentSize = 0
      this.submitLoading = false
      this.salesRep = null
      this.locations = []
      this.commercialAccounts = []
      this.saleSources = []
      if (this.$refs.form) {
        this.$refs.form.clearFiles()
      }
      if (Object.keys(thread).length !== 0) {
        this.get(thread.id)
        if (thread.recipient_id) {
          if (thread.recipient_model === 'salesrep') {
            this.findSalesRep(thread.recipient_id)
          } else if (thread.recipient_model === 'tech') {
            this.findTech(thread.recipient_id)
          }
        }
      }
    },
    newMessageThreadId: async function (value) {
      if (this.textMessagesDialogVisible && value === this.selectedThread.id) {
        this.get(this.selectedThread.id)
      }
    },
    undeliveredMessage: function (value) {
      this.messages.forEach(message => {
        if (message.id === value.id) {
          message.status = value.status
          message.error_code = value.errorCode
          message.error_message = value.errorMessage
        }
      })
    }
  },
  methods: {
    setSelectTemplateDialogVisible: call('twilio/textMessages/templates/setSelectTemplateDialogVisible'),
    async get (threadId) {
      this.messagesLoading = true
      this.messages = []
      this.images.total = 0
      this.images.loaded = 0
      const { data } = await TwilioTextMessageThread.view(threadId)
      this.messages = data
      for (const message of this.messages) {
        for (const media of message.media) {
          if (media.type.substr(0, 5) === 'image') {
            this.images.total++
          }
        }
      }
      this.adjustScroll()
      this.messagesLoading = false
    },
    formatDateTime (datetime) {
      return moment(datetime).format('MMM D, h:mm A')
    },
    adjustScroll () {
      this.$nextTick(() => {
        this.$refs.thread.scrollTop = this.$refs.thread.scrollHeight
        if (this.$refs.thread.firstChild !== null) {
          this.$refs.thread.firstChild.style = 'margin-top: auto;'
        }
      })
    },
    onExceed (files, fileList) {
      this.$message.warning('Not more than ten files per message can be attached.')
    },
    onRemove (file, fileList) {
      this.totalAttachmentSize -= file.size
    },
    onChange (file, fileList) {
      if (
        (!['image', 'audio', 'video', 'text'].includes(file.raw.type.split('/')[0]) &&
        file.raw.type !== 'application/pdf') || this.alreadyAttached(file, fileList)
      ) {
        this.removeFileFromFileList(file, fileList)
        this.$message.warning('Selected filetype is not supported.')
      } else {
        if (file.raw.type.split('/')[0] !== 'image') {
          if (file.size > 600000) {
            this.removeFileFromFileList(file, fileList)
            this.$message.warning('Selected file exceeds the maximum allowed size of 600 KB.')
          }
        }
        if (this.totalAttachmentSize + file.size + 4800 > 5000000) {
          this.removeFileFromFileList(file, fileList)
          this.$message.warning('Total message size shouldn\'t exceed 5 MB.')
        } else {
          this.totalAttachmentSize += file.size
          this.adjustScroll()
        }
      }
    },
    removeFileFromFileList (file, fileList) {
      for (const key in fileList) {
        if (file.uid === fileList[key].uid) {
          this.$delete(fileList, key)
        }
      }
    },
    alreadyAttached (file, fileList) {
      let numberOfFilesWithSameName = 0
      for (const fileFromList of fileList) {
        if (file.name === fileFromList.name) {
          numberOfFilesWithSameName++
        }
      }
      if (numberOfFilesWithSameName > 1) {
        this.removeFileFromFileList(file, fileList)
      }
    },
    async submit () {
      if (this.body === '' && this.$refs.form.uploadFiles.length === 0) {
        this.$message.warning('Can\'t send an empty message.')
      } else {
        try {
          this.submitLoading = true
          const formData = new FormData()
          formData.append('body', this.body)
          for (const key in this.$refs.form.uploadFiles) {
            formData.append('attachments', this.$refs.form.uploadFiles[key].raw)
          }
          await TwilioTextMessageThread.sendMessage(this.selectedThread.id, formData)
          await this.get(this.selectedThread.id)
          this.$refs.form.clearFiles()
          this.body = ''
          this.$emit('reloadThreads')
          this.submitLoading = false
        } catch {
          this.submitLoading = false
        }
      }
    },
    formatPhone (phone) {
      return `${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6)}`
    },
    openCustomerInfoModal (customerId) {
      this.$emit('openCustomerInfoModal', customerId)
    },
    newline () {
      this.body = `${this.body}\n`
    },
    addEmoji (emoji) {
      this.body += emoji.native
      this.emojiPicker = false
    },
    imagesLoaded (instance, img) {
      this.adjustScroll()
    },
    generateSentBy (message) {
      if (message.user_id !== null) {
        return `Sent by ${message.user.username}, id: ${message.user_id}`
      }
    },
    toggleEmojiPicker (event) {
      let emojiButtonClicked = false
      for (const el of event.path) {
        if (el.id === 'emoji-button') {
          emojiButtonClicked = true
        }
      }
      if (!emojiButtonClicked && this.emojiPicker) {
        this.emojiPicker = false
      }
    },
    modifyBody (body) {
      if (!['855', '142', '487'].includes(this.userId)) {
        return body
      }
      const numbers = [...new Set(body.match(/\b(\d+)\b/g))]
      numbers.forEach(number => {
        if (number.length === 6 || number.length === 7) {
          body = body.replace(number, `<a class="job-link" href="/jobs/${number}" target="_blank">${number}</a>`)
        }
      })
      return body
    },
    async findSalesRep (salesrepId) {
      this.salesRep = await Salesrep.getOne(salesrepId)
    },
    async findTech (techId) {
      this.tech = await Tech.getOne(techId)
    },
    async openSalesRepModal () {
      const self = this
      Location.getAll(self.selectedThread.shop_id, function (res) {
        self.locations = res
        Commercialaccount.getAll(self.selectedThread.shop_id, function (res) {
          self.commercialAccounts = res
          Salesource.getAll(self.selectedThread.shop_id, function (res) {
            self.saleSources = res
            self.$root.$emit('bv::show::modal', 'salesrepModal')
          })
        })
      })
    },
    async openTechModal () {
      this.$root.$emit('bv::show::modal', 'techModal')
    },
    async markUnread () {
      const { data } = await TwilioTextMessageThread.markUnread(this.selectedThread.id)
      if (data === 'success') {
        this.$emit('markThreadUnread', this.selectedThread.id)
      } else {
        this.$message.warning('Unable to update thread')
      }
    }
  },
  components: {
    Picker,
    SelectTemplateDialog,
    SalesrepDialog,
    TechDialog
  }
}
</script>

<style lang="scss">
  #text-message-thread-block {
    display: flex;
    height: inherit;
    overflow-y: hidden;
    flex-direction: column;
    #customer-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 70px;
      padding: 0 15px;
      border-bottom: 1px solid #DCDFE6;
      font-size: 15px;
      #recipient-type-section {
        text-transform: capitalize;
        padding-bottom: 5px;
      }
      #customer-phone-name-section {
        display: flex;
        .recipient-type {
          text-transform: capitalize;
          margin-bottom: 5px;
        }
        i {
          margin-right: 5px;
        }
        .recipient-customer {
          cursor: pointer;
          text-decoration: underline;
          color: rgb(38, 95, 151);
        }
      }
    }
    #thread-header {
      display: flex;
      box-shadow: 0 3px 10px 0 rgb(0, 0, 0, 0.08);
      #customer-block {
        flex: 0 0 85%;
      }
      #mark-unread {
        flex: 1;
        display: flex;
        margin: auto;
        width:100%;
        justify-content: center;
      }
    }
    #messages-block {
      display: flex;
      flex-direction: column;
      padding: 10px 15px 5px;
      flex-grow: 1;
      overflow-y: auto;
      box-shadow: inset 5px 1px 10px 0 rgb(0, 0, 0, 0.08);
      #no-messages-yet {
        color: #909399;
        text-align: center;
        padding-bottom: 5px;
        margin-top: auto;
      }
      .message-media {
        display: inline-block;
        padding: 7px 12px;
        border-radius: 15px;
        border: 1px solid #cddef7;
        a {
          text-decoration: none;
        }
      }
      .message-image {
        border-radius: 5px;
        max-width: 300px;
        max-height: 300px;
        margin-bottom: 5px;
      }
      .message-block {
        display: flex;
        .message-error-tooltip {
          align-self: center;
          color: #F56C6C;
        }
        .message-body {
          font-size: 15px;
          border-radius: 15px;
          padding: 7px 12px;
          display: inline-block;
          text-align: justify;
          white-space: pre-line;
          word-break: break-word;
          .job-link {
            color: white;
            text-decoration: underline;
          }
        }
        .incoming {
          color: #303133;
          background-color: #E4E7ED;
        }
        .outgoing {
          color:white;
          background-color: rgb(83, 168, 255);
        }
      }
      .message-timestamp {
        font-size: 10px;
        padding: 0 12px;
      }
    }
    #controls-block {
      padding: 12px 15px 12px;
      border-top: 1px solid #DCDFE6;
      box-shadow: 0 -5px 10px 0 rgb(0, 0, 0, 0.08);
      .upload-block {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        #emoji-button {
          padding: 10px 10px 10px 11px;
          margin-left: 10px;
        }
        #text-message-input {
          width: 290px;
          padding: 0 10px;
          margin-top: 3px;
        }
      }
    }
  }
</style>
